import { ApolloProvider } from "@apollo/client";
import {
  Badge,
  Button,
  Card,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import {
  AccountTreeOutlined,
  AssignmentOutlined,
  AssignmentTurnedInOutlined,
  BusinessCenter,
  CheckBox,
  DriveEta,
  ExitToApp,
  ExpandLess,
  ExpandMore,
  Flag,
  LibraryAddCheckOutlined,
  ListAltOutlined,
  ListOutlined,
  LocalActivityOutlined,
  LocalShipping,
  Notifications,
  Settings,
  Computer
} from "@material-ui/icons";
import AdbIcon from "@material-ui/icons/Adb";
import TwitterIcon from "@material-ui/icons/Twitter";
import BuildIcon from "@material-ui/icons/Build";
import CategoryIcon from "@material-ui/icons/Category";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import MotorcycleIcon from "@material-ui/icons/Motorcycle";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import UserIcon from "@material-ui/icons/Person";
import DevicesIcon from "@material-ui/icons/Devices";
import { Alert } from "@material-ui/lab";
import { SnackbarProvider } from "notistack";
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useRouteMatch,
} from "react-router-dom";
import { AuthProvder, useAuth, useRequireRole } from "src/auth";
import { Resource } from "src/components/Resource";
import { Roles_Enum, useUserQuery } from "src/generated/asgard/graphql";
import { GQLClient, GQLErrorEventHandler } from "src/graphql/apollo";
import { Layout } from "src/Layout";
import {
  NotificationMenu,
  NotificationProvider,
  useNotificationContext,
} from "src/Notification";
import {
  CategoryResource,
  ContextResource,
  FieldResource,
  OptionResource,
  PlanResource,
  TaskResource,
  HypertaskResource,
} from "src/resources/CollectionPlan";
import { TaskGroupsHierarchyResource } from "src/resources/TaskGroup";
import { CustomerResource } from "src/resources/Customer";
import { DeviceResource } from "src/resources/Device";
import { EngineResource } from "src/resources/Engine";
import { DeliverableResource } from "src/resources/Deliverable";
import { ModelResource } from "src/resources/Model";
import {
  IntegrationTestResource,
  SequenceResource,
  InstructionResource,
  ExpectationResource,
} from "src/resources/IntegrationTest";
import { ProjectResource } from "src/resources/Project";
import { ProjectList } from "src/resources/Project/list";
import { ToolResource } from "src/resources/Tool";
import { UserForm, UserResource } from "src/resources/User";
import { MaterialTheme } from "src/themes";
import "typeface-roboto";
import { Gravatar } from "./components";
import { ChirpDesignerResource } from "src/resources/ChirpDesigner";
import {
  DriverResource,
  DriverVersionResource,
  PackageResource,
  ProjectConfigResource,
  MicrosoftShippingLabelResource,
  MicrosoftWHQLSubmissionResource
} from "src/resources/Delivery";
import { BenchmarkPlanResource } from "src/resources/Benchmarking";
import { TargetResource } from "./resources/Delivery/target";
import { ChecklistResource } from "./resources/Bringup";

type ListItemLinkProps = {
  icon?: React.ReactElement;
  primary: string;
  to: string;
  roles?: Roles_Enum[];
};

function Link(props: ListItemLinkProps) {
  const access = useRequireRole(props.roles);
  const { icon, primary, to } = props;
  const exact = useRouteMatch({
    path: props.to,
    exact: true,
  });

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<RouterLinkProps, "to">>((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to],
  );

  if (access === false) return null;
  return (
    <ListItem selected={exact ? true : false} button component={renderLink}>
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      <ListItemText primary={primary} />
    </ListItem>
  );
}

const Drawer = () => (
  <List>
    <Link primary="Projects" to="/projects" icon={<InboxIcon />} />
    <SubMenu
      roles={[Roles_Enum.Asgard]}
      title="Data Collection"
      icon={<AssignmentOutlined />}
    >
      <Link primary="Plans" to="/plans" icon={<ListAltOutlined />} />
      <Link primary="Tasks" to="/tasks" icon={<AssignmentTurnedInOutlined />} />
      <Link
        primary="Hypertasks"
        to="/hypertasks"
        icon={<LibraryAddCheckOutlined />}
      />
      <Link primary="Categories" to="/categories" icon={<CategoryIcon />} />
      <Link primary="Fields" to="/fields" icon={<ListOutlined />} />
      <Link primary="Options" to="/options" icon={<LocalActivityOutlined />} />
      <Link
        primary="Task Groups"
        to="/task-groups"
        icon={<AccountTreeOutlined />}
      />
      <Link
        primary="Benchmarking"
        to="/benchmark-plans"
        icon={<ListAltOutlined />}
      />
    </SubMenu>
    <SubMenu
      roles={[Roles_Enum.Asgard]}
      title="Project Bringup"
      icon={<Flag />}
    >
      <Link primary="Checklists" to="/checklists" icon={<CheckBox />} />
    </SubMenu>
    <SubMenu
      roles={[Roles_Enum.Asgard]}
      title="Deliveries"
      icon={<LocalShipping />}
    >
      <Link primary="Drivers" to="/drivers" icon={<DriveEta />} />
      <Link primary="Packages" to="/packages" icon={<BusinessCenter />} />
      <Link
        primary="Project Configs"
        to="/project_configs"
        icon={<Settings />}
      />
      <Link
        primary="Targets"
        to="/targets"
        icon={<Computer />}
      />
    </SubMenu>
    <Link primary="Users" to="/users" icon={<UserIcon />} />
    <Link primary="Customers" to="/customers" icon={<AdbIcon />} />
    <Link
      primary="Models"
      to="/models"
      icon={<DevicesIcon />}
      roles={[Roles_Enum.Asgard]}
    />
    <Link
      primary="Deliverables"
      to="/deliverables"
      icon={<LoyaltyIcon />}
      roles={[Roles_Enum.Asgard]}
    />
    <Link primary="Engines" to="/engines" icon={<MotorcycleIcon />} />
    <SubMenu roles={[Roles_Enum.Asgard]} title="Tools" icon={<BuildIcon />}>
      <Link primary="External Tools" to="/tools" icon={<BuildIcon />} />
      <Link
        primary="Chirp Designer"
        to="/chirpdesigner/create"
        icon={<TwitterIcon />}
      />
    </SubMenu>
    <Link
      roles={[Roles_Enum.Midgard]}
      primary="Tools"
      to="/tools"
      icon={<BuildIcon />}
    />
  </List>
);

type SubMenuProps = {
  children?: JSX.Element | JSX.Element[];
  icon?: JSX.Element | JSX.Element[];
  title: string;
  roles?: Roles_Enum[];
};

const SubMenu = (props: SubMenuProps) => {
  const access = useRequireRole(props.roles);
  const [open, setOpen] = useState(false);

  if (access === false) {
    return null;
  }

  return (
    <>
      <ListItem button onClick={() => setOpen((o) => !o)}>
        <ListItemIcon>{props.icon}</ListItemIcon>
        <ListItemText primary={props.title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          style={{
            borderLeftStyle: "solid",
            marginLeft: 15,
            borderColor: "gray",
          }}
        >
          {props.children}
        </List>
      </Collapse>
    </>
  );
};

function App() {
  const theme = MaterialTheme["light"];

  return (
    <AuthProvder
      login={(login) => (
        <Button variant="contained" onClick={login}>
          Login
        </Button>
      )}
    >
      <ApolloProvider client={GQLClient}>
        <SnackbarProvider maxSnack={3}>
          <ThemeProvider theme={theme}>
            <AuthorizedApp />
          </ThemeProvider>
        </SnackbarProvider>
      </ApolloProvider>
    </AuthProvder>
  );
}

const AuthorizedApp = () => {
  const auth = useAuth();
  const [anchorElem, setAnchorElem] = useState(null);
  const [menu, setMenu] = useState<"notification" | "user" | null>(null);
  const notificationContext = useNotificationContext();

  const user = useUserQuery({
    variables: { id: auth.user.id },
  });

  const handleOpenNotificationMenu = (event: any) => {
    setMenu("notification");
    setAnchorElem(event.currentTarget);
  };

  const handleOpenUserMenu = (event: any) => {
    setMenu("user");
    setAnchorElem(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElem(null);
  };

  // TODO(jonas) show loading indicator when waiting for user
  if (user.loading === true) {
    return <div>loading...</div>;
  }

  if (
    user.data?.users_by_pk === null ||
    user.data?.users_by_pk?.role_users.length === 0
  ) {
    const u = user.data?.users_by_pk;
    auth.user.email = auth.user.email ?? u?.email;
    auth.user.name = auth.user.name ?? u?.name;

    return (
      <Card
        elevation={10}
        style={{
          maxWidth: 800,
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 100,
        }}
      >
        {/* TODO(jonas) Write proper signup instructions */}
        <Alert severity="warning">
          Hey! It looks like this is the first time you are using the Elliptic
          Manager...
        </Alert>
        <UserForm
          action="register"
          user={auth.user}
          onCreateSuccess={() => user.refetch()}
        />
      </Card>
    );
  }

  const toolbarMenu = (
    <>
      <IconButton onClick={handleOpenNotificationMenu} color="inherit">
        <Badge
          badgeContent={notificationContext.notifications.length}
          color="secondary"
        >
          <Notifications />
        </Badge>
      </IconButton>
      <ListItem button onClick={handleOpenUserMenu}>
        {/* <AccountCircle style={{ color: "white", marginRight: 10 }} /> */}
        <Gravatar
          style={{ width: 28, height: 28, marginRight: 10 }}
          email={user.data?.users_by_pk?.email ?? ""}
        />
        <ListItemText primary={user.data?.users_by_pk?.name} />
      </ListItem>
    </>
  );

  return (
    <NotificationProvider value={notificationContext}>
      <Router>
        <GQLErrorEventHandler />
        <Layout drawer={<Drawer />} toolbarRight={toolbarMenu}>
          <Resource path="/" list={() => <ProjectList />} />
          <CustomerResource />
          <DeviceResource />
          <SequenceResource />
          <InstructionResource />
          <ExpectationResource />
          <IntegrationTestResource />
          <ProjectResource />
          <UserResource />
          <EngineResource />
          <DeliverableResource />
          <ModelResource />
          <FieldResource />
          <OptionResource />
          <CategoryResource />
          <ContextResource />
          <TaskResource />
          <TaskGroupsHierarchyResource />
          <HypertaskResource />
          <PlanResource />
          <ToolResource />
          <ChirpDesignerResource />
          <DriverResource />
          <DriverVersionResource />
          <MicrosoftWHQLSubmissionResource />
          <MicrosoftShippingLabelResource />
          <PackageResource />
          <ProjectConfigResource />
          <TargetResource />
          <BenchmarkPlanResource />
          <ChecklistResource />
        </Layout>
      </Router>
      <Menu
        anchorEl={anchorElem}
        onClose={handleClose}
        open={anchorElem !== null && menu === "user"}
        style={{ marginTop: 33 }}
      >
        <MenuItem onClick={auth.logout}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <Typography variant="inherit">Logout</Typography>
        </MenuItem>
      </Menu>
      <NotificationMenu
        anchorEl={anchorElem}
        open={anchorElem !== null && menu === "notification"}
        onClose={handleClose}
      />
    </NotificationProvider>
  );
};

export default App;
