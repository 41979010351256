import React, { useRef } from "react";
import { Card, Tooltip, Typography } from "@material-ui/core";
import {
  ProjectOverviewQuery,
  Roles_Enum,
  useIsProjectManagerSubscription,
  useProjectOverviewQuery,
  useArchiveProjectMutation,
} from "src/generated/asgard/graphql";
import { Alert } from "@material-ui/lab";
import moment from "moment";
import { useProjectNav } from "src/resources/Project";
import { AllRecordingsTable } from "src/resources/Recording";
import { OverviewTab } from "./OverviewTab";
import { ProjectCollectionEditor } from "./ProjectCollectionEditor";
import { useAuth, useRequireRole } from "src/auth";
import { DataCollectionTab } from "./DataCollectionTab";
import { ShowResourceView, TabLabel, Tablature } from "src/Layout";
import Moment from "react-moment";
import { ProjectPerformanceReport } from "src/resources/Report";
import { BringupChecklistTab } from "./BringupChecklistTab";
import { ProjectBenchmarkingTab } from "./ProjectBenchmarkingTab";

type ProjectOverviewProps = {
  id: string;
};

function checkQueryDateEnd(sourceQuery?: string): string | null {
  if (!sourceQuery) {
    return null;
  }

  const dateEndString = JSON.parse(sourceQuery)?.params?.date_end;

  if (!dateEndString) {
    return null;
  }

  const dateEnd = new Date(dateEndString);
  const currentDate = new Date();

  return dateEnd < currentDate ? dateEndString : null;
}

export function ProjectOverview({ id }: ProjectOverviewProps) {
  const projectNav = useProjectNav();
  const ag = useRequireRole([Roles_Enum.Asgard]);
  const auth = useAuth();

  const projectCache = useRef<ProjectOverviewQuery | undefined>(undefined);
  const projectQuery = useProjectOverviewQuery({
    variables: { id: id },
  });

  const [archiveProject] = useArchiveProjectMutation();

  const isManagerQuery = useIsProjectManagerSubscription({
    variables: { project_id: id, user_id: auth.user.id },
  });
  const isManager = isManagerQuery.data?.user?.manager ?? false;

  if (projectQuery.data) projectCache.current = projectQuery.data;

  const project = projectCache.current?.project;
  const queryDateEnd = checkQueryDateEnd(project?.mongo_source_query);
  return (
    <ShowResourceView
      title={project?.project_name?.display_name ?? "unknown"}
      loading={projectQuery.loading}
      subtitle={
        <>
          <Typography variant="subtitle1" className="hide-print">
            {!!project?.created_at ? (
              <>
                <Tooltip interactive title={project.created_at}>
                  <div style={{ display: "inline-block" }}>
                    created <Moment fromNow>{project.created_at}</Moment>
                  </div>
                </Tooltip>
              </>
            ) : (
              "unknown"
            )}
          </Typography>
          {queryDateEnd && (
            <Alert severity="warning">
              The recording query end date is set earlier than the current date.
              All recordings made after{" "}
              {moment(queryDateEnd).format("YYYY-MM-DD")} are excluded. Update
              the end date to include all available recordings.
            </Alert>
          )}
          {project && !project?.simulation_opmode && (
            <Alert severity="warning">
              This project does not have a simulation opmode.
            </Alert>
          )}
        </>
      }
      onEditAction={ag ? () => projectNav.edit(id) : undefined}
      archivedAt={project?.deleted_at}
      onArchiveAction={
        ag
          ? () => {
              archiveProject({
                variables: {
                  id: id,
                  deleted_at: new Date().toISOString(),
                },
              });
            }
          : undefined
      }
      onUnarchiveAction={
        ag
          ? () => {
              archiveProject({
                variables: {
                  id: id,
                  deleted_at: null,
                },
              });
            }
          : undefined
      }
    >
      <Tablature
        useUrlParams
        tabs={[
          {
            name: "overview",
            label: "Overview",
            content: project && (
              <OverviewTab
                project={project}
                isManager={isManager}
                downloadButton={ag ? true : false}
              />
            ),
          },
          {
            name: "bringup",
            label: "Bringup",
            content: (
              <Card style={{ marginTop: 20 }}>
                <BringupChecklistTab projectId={id} />
              </Card>
            ),
            loading: projectQuery.loading,
          },
          {
            name: "collection status",
            label: "Collection Status",
            content: (
              <Card style={{ marginTop: 20 }}>
                <DataCollectionTab id={id} />
              </Card>
            ),
            loading: projectQuery.loading,
          },
          {
            name: "recordings",
            label: (
              <TabLabel
                label="Recordings"
                count={project?.project_dataset_aggregate?.aggregate?.count}
              />
            ),
            content: (
              <Card>
                <AllRecordingsTable
                  projectId={id}
                  hideColumns={{ project: true }}
                  selectable="none"
                />
              </Card>
            ),
          },
          {
            name: "tasks",
            hide: !ag,
            label: (
              <TabLabel
                label="Tasks/Hypertasks"
                count={
                  (project?.project_tasks_aggregate?.aggregate?.count ?? 0) +
                  (project?.project_hypertasks_aggregate?.aggregate?.count ?? 0)
                }
              />
            ),
            content: <ProjectCollectionEditor projectId={id} />,
          },
          {
            name: "benchmarking",
            label: (
              <TabLabel
                label="Benchmarking"
                count={
                  project?.rabbit_test_instances_aggregate?.aggregate?.count
                }
              />
            ),
            content: <ProjectBenchmarkingTab projectId={id} />,
          },
          {
            name: "performance",
            label: <TabLabel label="Performance" />,
            content: (
              <ProjectPerformanceReport
                projectId={id}
                deliverableId={project?.deliverable.id ?? ""}
              />
            ),
          },
        ]}
      />
    </ShowResourceView>
  );
}
