import { useState } from "react";
import {
  Box,
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { createTable, DialogColumn, TextColumn } from "src/components/table";
import { ProgressBar } from "src/components/ProgressBar";
import {
  Order_By,
  ProjectCollectionStatusQuery,
  Schema_Project_Task_Count_Order_By,
  useProjectCollectionStatusQuery,
  useProjectTaskCountByDeviceQuery,
  ProjectTaskCountByDeviceQuery,
  Schema_Project_Task_Count_Order_By as Project_Task_Count_Order_By,
  Tasks_Bool_Exp,
  useListLanguagesQuery,
  Roles_Enum,
} from "src/generated/asgard/graphql";
import { orderBy } from "src/resources/Utils";
import { useRequireRole } from "src/auth";
import { OndemandVideo, ReportProblemOutlined } from "@material-ui/icons";
import { TaskFieldOptions, TaskLink } from "src/resources/CollectionPlan";
import { Autocomplete } from "@material-ui/lab";

export const ProjectDeviceTaskCountsTable = createTable<
  NonNullable<ProjectTaskCountByDeviceQuery["project_task_count"]>[number]
>()({
  keys: (item) => item.device_id ?? "",
  title: "Category",
  headers: {
    device: { display: "Device" },
    progress: { display: "Progress" },
    num_clean_recordings: { display: "Clean" },
    num_dirty_recordings: { display: "Dirty" },
  },
  columns: (item) => ({
    device: <TextColumn value={item.device?.serialno ?? ""} />,
    progress: (
      <ProgressBar
        hideFraction
        numerator={item.clean_count ?? 0}
        denominator={item.min_per_device ?? 0}
      />
    ),
    num_clean_recordings: (
      <TextColumn
        value={`${item.clean_count ?? 0} / ${item.min_per_device ?? 0}`}
      />
    ),
    num_dirty_recordings: <TextColumn value={item.dirty_count ?? 0} />,
  }),
});

type ProjectDeviceTaskCountsProps = {
  projectId: string;
  taskId: string;
};

const ProjectDeviceTaskCounts = (props: ProjectDeviceTaskCountsProps) => {
  const { data } = useProjectTaskCountByDeviceQuery({
    variables: {
      project_id: props.projectId,
      task_id: props.taskId,
      order_by: [{ device: { serialno: Order_By.Asc } }],
    },
  });
  return (
    <ProjectDeviceTaskCountsTable
      data={data?.project_task_count}
      selectable="none"
    />
  );
};

const AssistTextColumn = (props: { value: string }) => {
  return (
    <Typography variant="body2" style={{ whiteSpace: "pre-wrap" }}>
      {props.value}
    </Typography>
  );
};

const ProjectCollectionStatusTable = createTable<
  ProjectCollectionStatusQuery["status"][number]
>()({
  title: "Projects",
  keys: (status) => status.task?.id ?? "",
  headers: {
    task: { display: "Task" },
    details: { display: "Details" },
    video: { display: "Video" },
    note: { display: "Note" },
    description: { display: "Description" },
    instruction: { display: "Instruction" },
    instruction_translation: { display: "Instruction (Translation)" },
    priority: { display: "Priority" },
    required_per_device: { display: "Required Per Device" },
    progress: { display: "Progress", info: "Includes clean recordings only" },
    recording_count_clean: { display: "Clean" },
    recording_count_dirty: { display: "Dirty" },
  },
  columns: (status) => ({
    task:
      status.task?.id && status.task?.legacy_template_id ? (
        <TaskLink
          id={status.task?.id!}
          number={status.task?.legacy_template_id!}
        />
      ) : (
        <></>
      ),
    details: (
      <DialogColumn
        label="Show"
        content={
          <Box margin={2}>
            <Typography variant="caption">Task</Typography>
            <Typography variant="body2" style={{ marginBottom: 20 }}>
              {status.task?.legacy_template_id}
            </Typography>
            <Typography variant="caption">Instruction</Typography>
            <Typography
              variant="body2"
              style={{ marginBottom: 20, whiteSpace: "pre-wrap" }}
            >
              {status.task?.task_instruction?.content ?? ""}
            </Typography>
            <Typography variant="caption">Description</Typography>
            <Typography
              variant="body2"
              style={{ marginBottom: 20, whiteSpace: "pre-wrap" }}
            >
              {status.task?.description}
            </Typography>
            <Typography variant="caption">Note</Typography>
            <Typography
              variant="body2"
              style={{ marginBottom: 20, whiteSpace: "pre-wrap" }}
            >
              {status.task?.note}
            </Typography>
            <Typography variant="caption">Fields/options</Typography>
            <TaskFieldOptions
              enableFieldTooltips
              taskId={status.task?.id ?? ""}
              categoryTypographyProps={{ variant: "body1" }}
            />
          </Box>
        }
      />
    ),
    note: <AssistTextColumn value={status.task?.note ?? ""} />,
    description: <AssistTextColumn value={status.task?.description ?? ""} />,
    instruction: (
      <AssistTextColumn value={status.task?.task_instruction?.content ?? ""} />
    ),
    instruction_translation: (
      <AssistTextColumn
        value={
          (status.task?.task_instruction?.instruction_translations ?? [])
            .length > 0
            ? status.task!.task_instruction!.instruction_translations![0]
                .content ?? ""
            : ""
        }
      />
    ),
    video: (() => {
      const media = status.task?.task_media;
      return (
        <>
          {media?.map((m) => (
            <IconButton
              key={m.media?.id}
              title={`${m.media?.title}\n${m.media?.url}`}
              target="_blank"
              href={m.media?.url ?? ""}
            >
              <OndemandVideo />
            </IconButton>
          ))}
        </>
      );
    })(),
    priority: <TextColumn value={status.priority} />,
    required_per_device: <TextColumn value={status.min_per_device} />,
    progress: (
      <ProgressBar
        hideFraction
        numerator={status?.progress ?? 0}
        denominator={100}
      />
    ),
    recording_count_clean: (
      <TextColumn
        value={`${status.clean_count ?? 0} / ${status.required_total ?? 0}`}
      />
    ),
    recording_count_dirty: <TextColumn value={status.dirty_count} />,
  }),
});

export type ProjectCollectionStatusProps = {
  id: string;
};

const defaultStatusOrder: Schema_Project_Task_Count_Order_By = {
  priority: Order_By.Desc,
  task: { note: Order_By.Asc },
};

export const ProjectCollectionStatus = ({
  id,
}: ProjectCollectionStatusProps) => {
  const [order, setOrder] =
    useState<Project_Task_Count_Order_By>(defaultStatusOrder);
  const ag = useRequireRole([Roles_Enum.Asgard]);
  // Set up "polarity filter" selections.
  type PolarityFilterKey = "both" | "positive" | "negative";
  type PolarityFilterItem = {
    key: PolarityFilterKey;
    label: string;
    filters: Tasks_Bool_Exp[];
  };
  const polarityFilters: PolarityFilterItem[] = [
    { key: "both", label: "Both", filters: [] },
    {
      key: "positive",
      label: "Positive",
      filters: [
        {
          task_field_options: {
            field_option: {
              field: {
                name: {
                  _eq: "metadata.truth.gesture.polarity",
                },
              },
              option: { value: { _eq: "positive" } },
            },
          },
        },
      ],
    },
    {
      key: "negative",
      label: "Negative",
      filters: [
        {
          task_field_options: {
            field_option: {
              field: {
                name: {
                  _eq: "metadata.truth.gesture.polarity",
                },
              },
              option: { value: { _eq: "negative" } },
            },
          },
        },
      ],
    },
  ];
  const [polarityFilter, setPolarityFilter] = useState<PolarityFilterItem>(
    polarityFilters[0],
  );

  // Setup "assist text" selections.
  type AssistTextKey = "none" | "note" | "instruction" | "description";
  type AssistTextItem = { key: AssistTextKey; label: string };
  const assistTextTypes: AssistTextItem[] = [
    { key: "none", label: "None" },
    { key: "description", label: "Description" },
    { key: "note", label: "Note" },
  ];
  if (ag){
    assistTextTypes.push({
      key: "instruction",
      label: "Instruction (LLM Generated)",
    });
  }
  const [assistTextType, setAssistTextType] = useState<AssistTextItem>(
    assistTextTypes[0],
  );

  // Setup "language" selections.
  type LanguageItem = { id: null | string; label: string };
  const languages: LanguageItem[] = [{ id: null, label: "default" }];
  const languagesQuery = useListLanguagesQuery();
  languagesQuery.data?.languages?.forEach(({ id }) =>
    languages.push({ id, label: id }),
  );
  const [language, setLanguage] = useState<LanguageItem>(languages[0]);

  // Query the collection status.
  const status = useProjectCollectionStatusQuery({
    variables: {
      project_id: id,
      tasks_where: { _and: [...polarityFilter.filters] },
      translation_language: language.id ?? "",
      order_by: order,
    },
  });

  return (
    <ProjectCollectionStatusTable
      data={status.data?.status}
      selectable="none"
      hideColumns={
        assistTextType.key === "description"
          ? {
              note: true,
              // description: true,
              instruction: true,
              instruction_translation: true,
              priority: true,
              recording_count_clean: true,
              recording_count_dirty: true,
            }
          : assistTextType.key === "note"
          ? {
              // note: true,
              description: true,
              instruction: true,
              instruction_translation: true,
              priority: true,
              recording_count_clean: true,
              recording_count_dirty: true,
            }
          : assistTextType.key === "instruction" && language.id === null
          ? {
              note: true,
              description: true,
              // instruction: true,
              instruction_translation: true,
              priority: true,
              recording_count_clean: true,
              recording_count_dirty: true,
            }
          : assistTextType.key === "instruction"
          ? {
              note: true,
              description: true,
              instruction: true,
              // instruction_translation: true,
              priority: true,
              recording_count_clean: true,
              recording_count_dirty: true,
            }
          : {
              note: true,
              description: true,
              instruction: true,
              instruction_translation: true,
              // priority: true,
              // recording_count_clean: true,
              // recording_count_dirty: true,
            }
      }
      tools={
        <>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel>Polarities</FormLabel>
              <RadioGroup
                row
                value={polarityFilter.key}
                onChange={(event) => {
                  const key = event.target.value;
                  const filter = polarityFilters.find((f) => f.key === key);
                  if (!!filter) setPolarityFilter(filter);
                }}
              >
                {polarityFilters.map((f) => (
                  <FormControlLabel
                    key={f.key}
                    value={f.key}
                    control={<Radio />}
                    label={f.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Autocomplete
              disableClearable
              options={assistTextTypes}
              value={assistTextType}
              getOptionLabel={(option) => option.label}
              getOptionSelected={(a, b) => a.key === b.key}
              onChange={(_event, nv) => {
                nv && setAssistTextType(nv);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Assist Text Type"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          {assistTextType.key === "instruction" && (
            <>
              <Grid item sm={2} xs={12}>
                <Autocomplete
                  disableClearable
                  options={languages}
                  value={language}
                  getOptionLabel={(option) => option.label}
                  getOptionSelected={(a, b) => a.id === b.id}
                  onChange={(_event, nv) => {
                    nv && setLanguage(nv);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Language"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item sm={2} xs={12}>
                <Tooltip
                  title={
                    "LLMs may produce mistakes. Please report any mistakes that you find to the slack channel, #collection-task-feedback."
                  }
                >
                  <IconButton>
                    <ReportProblemOutlined />
                  </IconButton>
                </Tooltip>
              </Grid>
            </>
          )}
        </>
      }
      orderColumn={(order) => {
        if (!order) {
          setOrder(defaultStatusOrder);
          return;
        }
        setOrder({
          task: {
            legacy_template_id: orderBy(order.task),
          },
          priority: orderBy(order.priority),
          min_per_device: orderBy(order.required_per_device),
          clean_count: orderBy(order.recording_count_clean),
          progress: orderBy(order.progress),
        });
      }}
      expandedContent={(status) => (
        <ProjectDeviceTaskCounts
          projectId={id}
          taskId={status.task?.id ?? ""}
        />
      )}
    />
  );
};

export type DataCollectionTabProps = {
  id: string;
};

export const DataCollectionTab = ({ id }: DataCollectionTabProps) => {
  return (
    <Card>
      <ProjectCollectionStatus id={id} />
    </Card>
  );
};
