import React, { useEffect, useRef, useState } from "react";
import { Button, ButtonProps } from "@material-ui/core";
import { makeBlobServiceClient } from "../auth/azureProvider";

type BlobDownloadButtonProps = ButtonProps & {
  storageAccountUrl: string;
  containerName: string;
  blobName: string;
  fileName?: string;
};

/**
 * A wrapper around a MaterialUI button that can be used to download a blob from
 * Azure Storage.
 *
 * Note, if no `fileName` is provided, then the filename will be the
 *
 * @param props
 * @returns
 */
export const BlobDownloadButton = (props: BlobDownloadButtonProps) => {
  const {
    storageAccountUrl,
    blobName,
    containerName,
    fileName,
    ...buttonProps
  } = props;
  // As long as there is a blob name, there will be a file name. If blob name
  // is empty, then `blobExists` will never be `true`.
  const fileNameResolved = fileName ?? blobName.split("/").pop();

  const [loading, setLoading] = useState(false);
  const [blobExists, setBlobExists] = useState(false);
  const [double, setDouble] = useState(false);
  // Generate a reference that can be used for an <a/> element.
  const downloadRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    const prerequisites = !!storageAccountUrl && !!blobName && !!containerName;
    if (!prerequisites) {
      return;
    }
    const checkBlob = async () => {
      try {
        const blobServiceClient = makeBlobServiceClient(storageAccountUrl);
        if (blobServiceClient) {
          const containerClient =
            blobServiceClient.getContainerClient(containerName);
          const blobClient = containerClient.getBlobClient(blobName);
          const properties = await blobClient.getProperties();
          if (properties?.contentLength && properties.contentLength > 0) {
            setBlobExists(true);
          } else {
            setBlobExists(false);
          }
        }
      } catch (error) {
        console.error("Error checking blob:", error);
        setBlobExists(false);
      }
    };
    checkBlob();
  }, [storageAccountUrl, blobName, containerName]);

  const downloadBlob = async () => {
    try {
      setLoading(true);
      const blobServiceClient = makeBlobServiceClient(storageAccountUrl);
      if (blobServiceClient) {
        const containerClient =
          blobServiceClient.getContainerClient(containerName);
        const blobClient = containerClient.getBlobClient(blobName);
        const downloadBlockBlobResponse = await blobClient.download();
        if (downloadBlockBlobResponse.blobBody) {
          const url = window.URL.createObjectURL(
            await downloadBlockBlobResponse.blobBody,
          );
          if (downloadRef.current) {
            downloadRef.current.href = url;
            downloadRef.current.download = fileNameResolved ?? "";
            downloadRef.current.click();
          }
        }
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      setBlobExists(false);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Button
      {...buttonProps}
      disabled={double || loading || !blobExists}
      onClick={() => {
        setDouble(true);
        downloadBlob().then(() => setDouble(false));
      }}
    >
      <a
        style={{ display: "none" }}
        href="/" // a valid, but dummy address, will be changed in `downloadBlob`
        download={fileNameResolved}
        ref={downloadRef}
      >
        {" "}
      </a>
      {props.children}
    </Button>
  );
};
