import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import * as XLSX from "xlsx-js-style";
import { saveAs } from "file-saver";
import { useProjectPerformanceDataLazyQuery } from "src/generated/asgard/graphql";
import { MetricResultType, SectionSchema } from "./PerformanceReport";
import { makePivotGroups, PivotGroup, TaskFieldOptionItem } from "./utils";

// Move constant data outside of the component
const scoreColorScale: string[] = [
  "ff3c33",
  "ff5533",
  "ff7433",
  "ff8a33",
  "ff9f33",
  "ffb533",
  "ffce33",
  "ffe633",
  "fffc33",
  "d1ff33",
  "b5ff33",
  "9fff33",
  "7aff33",
  "58ff33",
  "77ff33",
];

// Move helper functions outside
const getColorForScore = (
  score: number | string | null,
  metricInfo: MetricResultType | null,
  criticalLimitLarger: number | null,
  warningLimitLarger: number | null,
  criticalLimitSmaller: number | null,
  warningLimitSmaller: number | null,
) => {
  if (typeof score !== "number" || score === null) {
    return { background: "FFFFFF", text: "000000" }; // Default to white background, black text
  }
  if (
    metricInfo &&
    metricInfo.ratio_metric?.warning_limit &&
    metricInfo.ratio_metric?.critical_limit
  ) {
    let normalizedScore;
    let colorScale;
    const decimalScore = metricInfo.ratio_metric?.display_as_percentage
      ? score / 100
      : score;
    if (metricInfo.ratio_metric?.larger_is_better) {
      const criticalLimitLargerIsBetter = criticalLimitLarger
        ? criticalLimitLarger
        : metricInfo.ratio_metric?.critical_limit;
      const warningLimitLargerIsBetter = warningLimitLarger
        ? warningLimitLarger
        : metricInfo.ratio_metric?.warning_limit;
      normalizedScore = Math.max(
        Math.min(
          (decimalScore - criticalLimitLargerIsBetter) /
            (warningLimitLargerIsBetter - criticalLimitLargerIsBetter),
          1,
        ),
        0,
      );
      colorScale = scoreColorScale;
    } else {
      const criticalLimitSmallerIsBetter = criticalLimitSmaller
        ? criticalLimitSmaller
        : metricInfo.ratio_metric?.critical_limit;
      const warningLimitSmallerIsBetter = warningLimitSmaller
        ? warningLimitSmaller
        : metricInfo.ratio_metric?.warning_limit;
      normalizedScore = Math.max(
        Math.min(
          (criticalLimitSmallerIsBetter - decimalScore) /
            (criticalLimitSmallerIsBetter - warningLimitSmallerIsBetter),
          1,
        ),
        0,
      );
      colorScale = scoreColorScale.reverse();
    }
    const colorScaleIndex = Math.round(
      (colorScale.length - 1) * normalizedScore,
    );
    if (!metricInfo.ratio_metric?.larger_is_better) {
      colorScale = scoreColorScale.reverse();
    }
    return colorScale[colorScaleIndex];
  } else {
    return { background: "FFFFFF", text: "000000" };
  }
};

const s2ab = (s: string) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
};

const calculateAndDisplayScore = (
  metricId: string,
  engineId: string,
  metricResults: MetricResultType[],
  pivotGroup?: PivotGroup,
) => {
  let score: number | null = null;
  const metricInfo =
    metricResults.find((result) => result.metric_id === metricId) ?? null;
  if (!pivotGroup) {
    const results = metricResults.filter(
      (result) =>
        result.metric_id === metricId && result.engine_id === engineId,
    );
    const totalNumerator = results.reduce(
      (sum, result) => sum + (result.numerator ?? 0),
      0,
    );
    const totalDenominator = results.reduce(
      (sum, result) => sum + (result.denominator ?? 0),
      0,
    );
    score = totalDenominator ? totalNumerator / totalDenominator : null;
  } else {
    const pivotGroupResults = metricResults.filter(
      (result) =>
        result.metric_id === metricId &&
        result.engine_id === engineId &&
        pivotGroup?.tasks.some((task) => task.id === result.task_id),
    );
    const totalNumerator = pivotGroupResults.reduce(
      (sum, result) => sum + (result.numerator ?? 0),
      0,
    );
    const totalDenominator = pivotGroupResults.reduce(
      (sum, result) => sum + (result.denominator ?? 0),
      0,
    );
    score = totalDenominator ? totalNumerator / totalDenominator : null;
  }

  let scoreDisplay = "";
  if (typeof score === "number") {
    const numDecimalPlaces = 2;
    const isPercentage = metricInfo?.ratio_metric?.display_as_percentage;
    const multiplier = isPercentage
      ? 10 ** (numDecimalPlaces + 2)
      : 10 ** numDecimalPlaces;
    score = Math.round(score * multiplier) / 10 ** numDecimalPlaces;
    if (isPercentage) {
      scoreDisplay = `${score} %`;
    } else if (metricInfo?.ratio_metric?.numerator_unit) {
      if (metricInfo.ratio_metric?.denominator_unit) {
        scoreDisplay = `${score} ${metricInfo.ratio_metric.numerator_unit} / ${metricInfo.ratio_metric.denominator_unit}`;
      } else {
        scoreDisplay = `${score} ${metricInfo.ratio_metric.numerator_unit}`;
      }
    }
  }
  return {
    score,
    scoreDisplay,
    metricInfo,
  };
};

interface ExcelExportButtonProps {
  projectId: string;
  sections: SectionSchema[];
  engines: { id: string; label: string }[];
  taskFieldOptions: TaskFieldOptionItem[];
}

// The component remains clean and focused
export const ExcelExportButton: React.FC<ExcelExportButtonProps> = ({
  projectId,
  sections,
  engines,
  taskFieldOptions,
}) => {
  const [nFields, setNFields] = useState<number>(2); // User input for 'n' fields
  const [metricResults, setMetricResults] = useState<MetricResultType[]>([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [isDataReady, setIsDataReady] = useState(false);
  const [triggerFetch, setTriggerFetch] = useState(false); //Used to trigger the download if there is no change in the query to Oedipus
  const [showWarningPanel, setShowWarningPanel] = useState(false);
  const [shortenNames, setShortenNames] = useState(false);

  const shortenDisplayName = (name: string) => name.split('.').pop();

  const [projectPerformanceDataQuery, { data, loading, error }] =
    useProjectPerformanceDataLazyQuery();
  const [modalOpen, setModalOpen] = useState(false);

  const [criticalLimitLarger, setCriticalLimitLarger] = useState<number | null>(
    null,
  );
  const [warningLimitLarger, setWarningLimitLarger] = useState<number | null>(
    null,
  );
  const [criticalLimitSmaller, setCriticalLimitSmaller] = useState<
    number | null
  >(null);
  const [warningLimitSmaller, setWarningLimitSmaller] = useState<number | null>(
    null,
  );

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const generateExcelReport = useCallback(() => {
    const workbook = XLSX.utils.book_new();

    sections.forEach((section) => {
      const sectionSheetName = section.title.slice(0, 31); // Sheet name stays the same as section title
      let worksheetData: any[] = [];
      let allMerges: any[] = [];
      let colWidths: any[] = [];

      const borderStyle = {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      };

      // Add section title as the first row
      worksheetData.push([
        {
          v: `${section.title}`,
          s: {
            font: { bold: true, sz: 17 },
            alignment: { horizontal: "center", vertical: "center" },
            fill: { fgColor: { rgb: "ADD8E6" } },
            border: borderStyle,
          },
        },
      ]);
      const pivotFields = section.pivotFields as {
        id: string;
        display: string;
      }[];
      const pivotGroups = makePivotGroups(pivotFields, taskFieldOptions, false);

      // Filter unique combinations of first 'n' fields
      const uniqueCombinations = pivotGroups.reduce((acc, group) => {
        // Create a key for each unique combination of the first 'n' fields
        const combinationKey = group.fieldOptions
          .slice(0, nFields)
          .map(
            ({ field, option }) =>
              `${shortenNames ? shortenDisplayName(field.display) : field.display}: ${option?.display}` || "N/A",
          )
          .join(" & ");

        // If this key is not in the accumulator yet, add it with an empty array
        if (!acc[combinationKey]) {
          acc[combinationKey] = [];
        }

        // Add the current group to the array associated with this key
        acc[combinationKey].push(group);

        return acc;
      }, {} as { [key: string]: PivotGroup[] });

      // Iterate over each unique combination of fields
      Object.entries(uniqueCombinations).forEach(
        ([combinationTitle, pivotGroupsForKey], combinationIndex) => {
          if (combinationIndex > 0) {
            worksheetData.push([]); // Add a blank row between subsections
          }

          // Add subsection title
          worksheetData.push([
            {
              v: `${combinationTitle}`,
              s: {
                font: { bold: true, sz: 15 },
                alignment: { horizontal: "center", vertical: "center" },
                fill: { fgColor: { rgb: "FFE4B5" } }, // Different color for subsections
                border: borderStyle,
              },
            },
          ]);

          // Iterate through each metric for every subsection
          section.ratioMetrics.forEach((metric) => {
            // Add a row with the metric name and engine versions (first row for the metric)
            const metricHeaderRow = [
              {
                v: `${metric.name}`,
                s: {
                  font: { bold: true, sz: 13 },
                  alignment: { horizontal: "center", vertical: "center" },
                  fill: { fgColor: { rgb: "FFE4B5" } },
                  border: borderStyle,
                },
              },
            ];
            worksheetData.push(metricHeaderRow);

            const fieldHeaderRow = [
              ...pivotGroupsForKey[0].fieldOptions
                .slice(nFields)
                .map(({ field }) => ({
                  v: shortenNames ? shortenDisplayName(field.display) : field.display,
                  s: {
                    font: { bold: true, sz: 13 },
                    alignment: { horizontal: "center", vertical: "center" },
                    fill: { fgColor: { rgb: "ADD8E6" } },
                    border: borderStyle,
                  },
                })),
              ...engines.map((engine) => ({
                v: `${engine.label}`,
                s: {
                  font: { bold: true, sz: 13 },
                  alignment: { horizontal: "center", vertical: "center" },
                  fill: { fgColor: { rgb: "ADD8E6" } },
                  border: borderStyle,
                },
              })),
            ];
            worksheetData.push(fieldHeaderRow);

            pivotGroupsForKey.forEach((pivotGroup) => {
              // Add headers for the remaining fields (non-section fields)

              // Iterate over the options for the remaining fields and calculate metric results
              const optionsRow = [
                ...pivotGroup.fieldOptions.slice(nFields).map(({ option }) => ({
                  v: option?.display || "N/A",
                  s: {
                    alignment: { horizontal: "center", vertical: "center" },
                    fill: { fgColor: { rgb: "E0FFFF" } },
                    border: borderStyle,
                  },
                })),
                ...engines.map((engine) => {
                  const { score, scoreDisplay, metricInfo } =
                    calculateAndDisplayScore(
                      metric.id,
                      engine.id,
                      metricResults,
                      pivotGroup,
                    );

                  const scoreColor = getColorForScore(
                    score,
                    metricInfo,
                    criticalLimitLarger,
                    warningLimitLarger,
                    criticalLimitSmaller,
                    warningLimitSmaller,
                  );
                  return {
                    v: scoreDisplay,
                    s: {
                      fill: {
                        fgColor: {
                          rgb:
                            typeof scoreColor === "string"
                              ? scoreColor
                              : scoreColor.background,
                        },
                      },
                      alignment: { horizontal: "center", vertical: "center" },
                      border: borderStyle,
                    },
                  };
                }),
              ];

              worksheetData.push(optionsRow);
            });
          });
        },
      );

      // Adjust column widths for the entire worksheet
      worksheetData.forEach((_: any, i: number) => {
        const maxLength = worksheetData.reduce((max, row) => {
          const cellValue = row[i]?.v ? row[i].v.toString() : "";
          return Math.max(max, cellValue.length);
        }, 0);
        colWidths[i] = { wch: maxLength + 25 }; // Add some padding
      });

      // Only add a section worksheet if it contains data
      if (worksheetData.length > 1) {
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        worksheet["!merges"] = allMerges;
        worksheet["!cols"] = colWidths;

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, sectionSheetName);
      }
    });
    if (workbook.SheetNames.length >= 1) {
      const workbookBlob = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "binary",
      });
      const blob = new Blob([s2ab(workbookBlob)], {
        type: "application/octet-stream",
      });
      saveAs(blob, "performance_report.xlsx");
    } else {
      setShowWarningPanel(true);
    }
  }, [
    sections,
    engines,
    taskFieldOptions,
    nFields,
    criticalLimitLarger,
    criticalLimitSmaller,
    metricResults,
    warningLimitLarger,
    warningLimitSmaller,
    shortenNames
  ]);

  const handlePrepareData = () => {
    setIsDataReady(false);
    setDataLoading(true);
    setTriggerFetch((prev) => !prev);
    // Trigger the lazy query with the necessary variables
    projectPerformanceDataQuery({
      variables: {
        project_id: projectId,
        engine_ids: `{${engines.map((engine) => `"${engine.id}"`).join(",")}}`,
      },
    });
  };

  const handleWarningClose = () => {
    setShowWarningPanel(false);
  };

  // Use effect to handle changes in the query's result (loading and data states)
  useEffect(() => {
    if (!loading && data) {
      if (data.project_engine_metrics) {
        setMetricResults(data.project_engine_metrics);
        setIsDataReady(true);
      } else {
        setDataLoading(false);
      }
    }

    if (!loading && error) {
      setDataLoading(false);
    }
  }, [loading, data, error, triggerFetch]);

  // Automatically trigger the export when the data is ready
  useEffect(() => {
    if (isDataReady) {
      generateExcelReport();
      setIsDataReady(false); // Reset after export
      setDataLoading(false);
    }
  }, [isDataReady, generateExcelReport]);

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          handleModalOpen();
          setShowWarningPanel(false);
        }}
        disabled={dataLoading}
      >
        Configure & Export to Excel
      </Button>
      {/* Modal Dialog for Configuration */}
      <Dialog open={modalOpen} onClose={handleModalClose}>
        <DialogTitle>Configure Export Options</DialogTitle>
        <DialogContent>
          {/* Dropdown to select number of fields */}
          <Select
            value={nFields}
            onChange={(e) => setNFields(Number(e.target.value))}
            style={{ marginBottom: "20px", minWidth: "150px" }}
          >
            <MenuItem value={1}>1 Pivot Field for Report</MenuItem>
            <MenuItem value={2}>2 Pivot Fields for Report</MenuItem>
            <MenuItem value={3}>3 Pivot Fields for Report</MenuItem>
            <MenuItem value={4}>4 Pivot Fields for Report</MenuItem>
          </Select>
          <Tooltip
            title="
            This value can be used to split up each of the tables in each section.
            For instance, if this value is 2, then each table will be split into new tables, 
            one for each of the unique combinations of options assigned to the first 2 pivot fields of each section. 
            Then remaining pivot fields will determine which rows are present in the tables."
            arrow
          >
            <IconButton>
              <Info />
            </IconButton>
          </Tooltip>

          {/* Custom Critical/Warning Thresholds for larger_is_better: True */}
          <TextField
            label="Critical Threshold - Larger is better (Decimal value) "
            type="number"
            value={criticalLimitLarger || ""}
            onChange={(e) => setCriticalLimitLarger(Number(e.target.value))}
            style={{ marginBottom: "20px", width: "400px" }}
            inputProps={{ step: "0.01" }}
            error={
              criticalLimitLarger !== null &&
              warningLimitLarger !== null &&
              criticalLimitLarger > warningLimitLarger
            }
            helperText={
              criticalLimitLarger !== null &&
              warningLimitLarger !== null &&
              criticalLimitLarger > warningLimitLarger
                ? "Critical Threshold must be smaller than Warning Threshold when Larger is better"
                : ""
            }
          />
          <Tooltip
            title="
            This value can be set to customize the critical threshold for metrics where the best value is the largest. If not provided, the default value from the metric will be used"
            arrow
          >
            <IconButton>
              <Info />
            </IconButton>
          </Tooltip>

          <TextField
            label="Warning Threshold - Larger is better (Decimal value)"
            type="number"
            value={warningLimitLarger || ""}
            onChange={(e) => setWarningLimitLarger(Number(e.target.value))}
            style={{ marginBottom: "20px", width: "400px" }}
            inputProps={{ step: "0.01" }}
            error={
              criticalLimitLarger !== null &&
              warningLimitLarger !== null &&
              criticalLimitLarger > warningLimitLarger
            }
            helperText={
              criticalLimitLarger !== null &&
              warningLimitLarger !== null &&
              criticalLimitLarger > warningLimitLarger
                ? "Warning Threshold must be bigger than Critical Threshold when Larger is better"
                : ""
            }
          />
          <Tooltip
            title="
            This value can be set to customize the warning threshold for metrics where the best value is the largest. If not provided, the default value from the metric will be used"
            arrow
          >
            <IconButton>
              <Info />
            </IconButton>
          </Tooltip>

          {/* Custom Critical/Warning Thresholds for larger_is_better: False */}
          <TextField
            label="Critical Threshold - Smaller is better (Decimal value)"
            type="number"
            value={criticalLimitSmaller || ""}
            onChange={(e) => setCriticalLimitSmaller(Number(e.target.value))}
            style={{ marginBottom: "20px", width: "400px" }}
            inputProps={{ step: "0.01" }}
          />
          <Tooltip
            title="
            This value can be set to customize the critical threshold for metrics where the best value is the smallest. If not provided, the default value from the metric will be used"
            arrow
          >
            <IconButton>
              <Info />
            </IconButton>
          </Tooltip>
          <TextField
            label="Warning Threshold - Smaller is better (Decimal value)"
            type="number"
            value={warningLimitSmaller || ""}
            onChange={(e) => setWarningLimitSmaller(Number(e.target.value))}
            style={{ marginBottom: "20px", width: "400px" }}
            inputProps={{ step: "0.01" }}
          />
          <Tooltip
            title="
            This value can be set to customize the warning threshold for metrics where the best value is the smallest. If not provided, the default value from the metric will be used"
            arrow
          >
            <IconButton>
              <Info />
            </IconButton>
          </Tooltip>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => setShortenNames(e.target.checked)}
                color="secondary"
              />
            }
            label="Use short field option display names"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleModalClose();
              handlePrepareData();
            }}
            color="secondary"
          >
            Prepare and Export
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={showWarningPanel}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleWarningClose} severity="warning">
          The Excel report couldn't be fetched because no performance metrics
          for field options were found.
        </Alert>
      </Snackbar>
    </Box>
  );
};
