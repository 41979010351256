import React, { useState } from "react";
import { Button, Card, Checkbox, Grid, Typography } from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import * as Yup from "yup";
import { SearchBar } from "src/components/filters";
import {
  DialogSelect,
  FormContainer,
  FormHeader,
  TextField,
} from "src/components/Form";
import { Resource, useResourceNav } from "src/components/Resource";
import {
  ChipColumn,
  createTable,
  EllipsisColumn,
  TableOptionals,
  TextColumn,
} from "src/components/table";
import { usePagination, like, uuidIsValid } from "src/resources/Utils";
import {
  Breadcrumb,
  Breadcrumbs,
  ShowResourceView,
  Tablature,
  TableView,
  TableViewHeader,
} from "src/Layout";
import { ResourceForm, FormAction } from "src/components/ResourceForm";
import {
  Deliveries_Drivers as Driver,
  Deliveries_Drivers_Bool_Exp as Drivers_Bool_Exp,
  useAllDriversQuery,
  useDriverQuery,
  useCreateDriverMutation,
  useUpdateDriverMutation,
  Order_By,
  Roles_Enum,
  useArchiveDriverMutation,
  useCheckDriverHasVersionQuery,
} from "src/generated/asgard/graphql";
import { AllProjectsTable } from "../Project";
import { AllDriverVersionsTable, useDriverVersionNav } from "./driver_version";
import { AllPackagesTable, usePackageNav } from "./package";
import { useRequireRole } from "src/auth";

// Config table columns from Driver fields
export const DriverTable = createTable<Driver>()({
  keys: (driver) => driver.id ?? "",
  title: "Driver",
  headers: {
    id: { display: "ID" },
    name: { display: "Name" },
    versions: { display: "Versions" },
    project: { display: "Target Project" },
    is_archived: { display: "Archived" },
  },
  columns: (driver) => ({
    id: <EllipsisColumn value={driver.id} />,
    name: <TextColumn value={driver.name} />,
    versions: (
      <ChipColumn
        chips={driver.driver_versions?.map((dv) => ({
          label: dv.version ?? "",
        }))}
      />
    ),
    project: (
      <TextColumn
        value={driver.driver_project?.project?.project_name?.name ?? ""}
      />
    ),
    is_archived: (
      <Checkbox checked={driver.deleted_at ? true : false} disabled={true} />
    ),
  }),
});

// Define a new table component for Drivers
type AllDriversTableProps = TableOptionals<typeof DriverTable> & {
  where?: Drivers_Bool_Exp[];
};

export const AllDriversTable = (props: AllDriversTableProps) => {
  const [pageVars, pageController] = usePagination();
  const [search, setSearch] = useState("");
  const searchFilters: Drivers_Bool_Exp[] = [];
  if (uuidIsValid(search)) {
    searchFilters.push({ id: { _eq: search } });
  } else {
    // Add search terms for individual fields.
    const term = like(search);
    searchFilters.push({
      _or: [
        { name: { _ilike: term } },
        { driver_versions: { version: { _ilike: term } } },
      ],
    });
  }
  const { data } = useAllDriversQuery({
    variables: {
      ...pageVars,
      where: { _and: [{ _and: props.where }, { _or: searchFilters }] },
      order_by: [
        { deleted_at: Order_By.DescNullsFirst },
        { created_at: Order_By.Desc },
      ],
    },
    fetchPolicy: "network-only",
  });

  return (
    <DriverTable
      {...props}
      {...pageController}
      total={data?.drivers_aggregate?.aggregate?.count}
      data={data?.drivers}
      tools={
        <Grid item xs={12}>
          <SearchBar onChange={setSearch} />
        </Grid>
      }
    />
  );
};

// Define content to display in the main Driver resource page
type DriverIndexProps = {
  onAddNew: () => void;
} & TableOptionals<typeof DriverTable>;

export const DriverIndex = (props: DriverIndexProps) => {
  return (
    <TableView>
      <TableViewHeader title={<Typography variant="h5">Drivers</Typography>}>
        <Button
          onClick={props.onAddNew}
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutline />}
          disableElevation
        >
          New Driver
        </Button>
      </TableViewHeader>
      <Card>
        <AllDriversTable {...props} selectable="none" />
      </Card>
    </TableView>
  );
};

// Define form for creating and editing Drivers
const driverSchema = Yup.object({
  name: Yup.string().required("required"),
  description: Yup.string(),
  project: Yup.object({
    id: Yup.string(),
    name: Yup.string(),
  }).required(),
}).required();

export type DriverFormProps = {
  action: FormAction;
  driver?: { id: string };
  onSuccess?: (id: string) => void;
};

export const DriverForm = (props: DriverFormProps) => {
  const [createDriver] = useCreateDriverMutation();
  const [updateDriver] = useUpdateDriverMutation();

  const allDrivers = useAllDriversQuery({
    variables: {
      where: { id: { _nin: props.driver?.id ? [props.driver.id] : [] } },
    },
    fetchPolicy: "network-only",
  });

  const existingDriver = useDriverQuery({
    variables: { id: props.driver?.id ?? "" },
    fetchPolicy: "network-only",
    skip: !props.driver,
  });

  const numExistingDriverVersions =
    existingDriver.data?.driver?.driver_versions?.length ?? 0;

  return (
    <ResourceForm
      action={props.action}
      schema={driverSchema}
      resourceToUpdate={existingDriver.data?.driver}
      transform={(driver) => ({
        name: driver.name,
        description: driver.description ?? "",
        project: {
          id: driver.driver_project?.project?.id ?? "",
          name: driver.driver_project?.project?.project_name?.name ?? "",
        },
      })}
      initialValues={{
        name: "",
        description: "",
        project: {
          id: "",
          name: "",
        },
      }}
      customValidator={(values, errors) => {
        for (let existing of allDrivers.data?.drivers ?? []) {
          if (existing.name === values.name) {
            errors.name = `Driver ${values.name} already exists`;
          }
          if (existing.driver_project?.project?.id === values.project.id) {
            errors.project = {
              name: `Project ${values.project?.name} already has a driver attached`,
            };
          }
          if (errors.name || errors.project) {
            break;
          }
        }
      }}
      onUpdate={async (values) => {
        const { project, ...columns } = values;
        const existing = existingDriver.data?.driver;
        const existingProjectId = existing?.driver_project?.project?.id;
        const changeProject = existingProjectId
          ? existingProjectId !== project.id
          : false;
        const result = await (async () => {
          if (props.driver?.id)
            return await updateDriver({
              variables: {
                id: props.driver.id,
                columns: { ...columns },
                add_driver_projects: changeProject
                  ? [{ driver_id: props.driver.id, project_id: project.id }]
                  : [],
                delete_driver_project_ids:
                  existingProjectId && changeProject ? [existingProjectId] : [],
              },
            });
        })();
        props.onSuccess && props.onSuccess(result?.data?.driver?.id ?? "");
      }}
      onInsert={async (values) => {
        const { project, ...columns } = values;
        const result = await (async () => {
          return await createDriver({
            variables: {
              input: project.id
                ? {
                    ...columns,
                    driver_project: {
                      data: { project_id: project.id },
                    },
                  }
                : { ...columns },
            },
          });
        })();
        props.onSuccess && props.onSuccess(result.data?.driver?.id ?? "");
      }}
      render={({ formik, path }) => (
        <>
          <FormHeader>
            {props.driver ? "Update Driver" : "Create New Driver"}
          </FormHeader>
          <FormContainer>
            <TextField bp={{ xs: 12, sm: 8 }} name={path.name._} label="Name" />
            <TextField
              bp={{ xs: 12, sm: 8 }}
              name={path.description?._ ?? "description"}
              label="Description"
            />
            <DialogSelect
              bp={{ xs: 6 }}
              disabled={numExistingDriverVersions > 0}
              multiple={false}
              onReset={() =>
                formik.setValues({
                  ...formik.values,
                  project: formik.initialValues.project,
                })
              }
              name={path.project.name._}
              label="Target Project (for Topologies)"
              content={(close) => (
                <>
                  <AllProjectsTable
                    selectable="single"
                    onSelect={(project) => {
                      formik.setValues({
                        ...formik.values,
                        project: {
                          id: project.id ?? "",
                          name: project.project_name?.name ?? "",
                        },
                      });
                      close();
                    }}
                  />
                </>
              )}
            />
          </FormContainer>
        </>
      )}
    />
  );
};

// Create a detailed 'show' page.
type DriverShowProps = {
  id: string;
  onEditAction?: (item: DeepPartial<Driver>) => void;
};

const DriverShow = (props: DriverShowProps) => {
  const driverVersionNav = useDriverVersionNav();
  const driverNav = useDriverNav();
  const packageNav = usePackageNav();
  const ag = useRequireRole([Roles_Enum.Asgard]);
  const [archiveDriver] = useArchiveDriverMutation();
  const driverQuery = useDriverQuery({
    variables: { id: props.id },
    fetchPolicy: "network-only",
  });
  const checkDriverHasVersionQuery = useCheckDriverHasVersionQuery({
    variables: { driver_id: props.id },
    fetchPolicy: "network-only",
  });
  const driver = driverQuery.data?.driver;
  if (!driver) return null;

  const properties = [
    { label: "ID", value: props.id },
    { label: "Description", value: driver.description ?? "" },
  ];
  const targetProjectName = driver.driver_project?.project?.project_name?.name;
  if (targetProjectName)
    properties.push({
      label: "Target Project",
      value: targetProjectName ?? "None",
    });

  const versionedDrivers = checkDriverHasVersionQuery.data?.versioned_drivers;
  const hasVersionedDrivers =
    Array.isArray(versionedDrivers) && versionedDrivers.length > 0;
  const canArchiveUnarchive = !hasVersionedDrivers;

  return (
    <ShowResourceView
      title={driver.name ?? ""}
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb label="drivers" onClick={() => driverNav.list()} />
          <Breadcrumb label={driver.name ?? ""} />
        </Breadcrumbs>
      }
      onEditAction={() => props.onEditAction && props.onEditAction(driver)}
      archivedAt={driver?.deleted_at}
      onArchiveAction={
        ag && canArchiveUnarchive
          ? () => {
              archiveDriver({
                variables: {
                  id: driver.id,
                  deleted_at: new Date().toISOString(),
                },
              });
            }
          : undefined
      }
      onUnarchiveAction={
        ag && canArchiveUnarchive
          ? () => {
              archiveDriver({
                variables: {
                  id: driver.id,
                  deleted_at: null,
                },
              });
            }
          : undefined
      }
      properties={properties}
    >
      <Tablature
        useUrlParams
        tabs={[
          {
            name: "versions",
            label: "Versions",
            content: (
              <AllDriverVersionsTable
                selectable="none"
                hideColumns={{ driver: true, target: !targetProjectName }}
                where={[{ driver: { id: { _eq: props.id } } }]}
                showUrl={(item) => item.id && driverVersionNav.showUrl(item.id)}
              />
            ),
          },
          {
            name: "packages",
            label: "Packages",
            content: (
              <AllPackagesTable
                selectable="none"
                hideColumns={{
                  version: true,
                  versions: true,
                  is_archived: true,
                }}
                where={[
                  {
                    driver_version_packages: {
                      package: { spec_package_id: { _is_null: true } },
                      driver_version: { driver_id: { _eq: props.id } },
                    },
                  },
                ]}
                showUrl={(item) => item.id && packageNav.showUrl(item.id)}
              />
            ),
          },
        ]}
      />
    </ShowResourceView>
  );
};

// Finally, combine into full resource UI.
const path = "drivers";
export const useDriverNav = () => useResourceNav(path);
export const DriverResource = () => (
  <Resource
    path={path}
    list={(nav) => (
      <DriverIndex
        editUrl={(item) => item.id && nav.editUrl(item.id)}
        showUrl={(item) => item.id && nav.showUrl(item.id)}
        onAddNew={() => nav.create()}
      />
    )}
    show={(nav, id) => (
      <DriverShow
        id={id ?? ""}
        onEditAction={(item) => item.id && nav.edit(item.id)}
      />
    )}
    create={(nav) => (
      <DriverForm action="insert" onSuccess={(id) => nav.show(id)} />
    )}
    edit={(nav, id) => (
      <DriverForm
        action="update"
        onSuccess={(id) => nav.show(id)}
        driver={{ id: id ?? "" }}
      />
    )}
  />
);
