import React, { useState } from "react";
import {
  Card,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@material-ui/core";
import { ListAltOutlined } from "@material-ui/icons";
import * as Yup from "yup";
import { SearchBar } from "src/components/filters";
import {
  DialogSelect,
  FormContainer,
  FormHeader,
  IncDecField,
  TextField,
} from "src/components/Form";
import { Resource, useResourceNav } from "src/components/Resource";
import {
  createTable,
  EllipsisColumn,
  TableOptionals,
  TextColumn,
} from "src/components/table";
import { usePagination, like, uuidIsValid } from "src/resources/Utils";
import {
  Breadcrumb,
  Breadcrumbs,
  ShowResourceView,
  TableView,
  TableViewHeader,
  Tab,
  Tabs,
  TabContent,
  TabLabel,
  TabView,
} from "src/Layout";
import { ResourceForm, FormAction } from "src/components/ResourceForm";
import {
  Deliverables as Deliverable,
  Deliverables_Bool_Exp,
  useAllDeliverablesQuery,
  useDeliverableQuery,
  useCreateDeliverableMutation,
  useUpdateDeliverableMutation,
  Roles_Enum,
} from "src/generated/asgard/graphql";
import { AllProjectsTable, useProjectNav } from "../Project";
import { AllGroundTruthTypesTable } from "src/resources/GroundTruthType";
import { useRequireRole } from "src/auth";

// Generic project table
export const DeliverableTable = createTable<Deliverable>()({
  keys: (deliverable) => deliverable.id ?? "",
  title: "Deliverables",
  headers: {
    id: { display: "ID" },
    name: { display: "Name" },
    numStates: { display: "# States" },
    gtType: { display: "Ground Truth Type" },
    description: { display: "Description" },
  },
  columns: (deliverable) => ({
    id: <EllipsisColumn value={deliverable.id ?? ""} />,
    name: <TextColumn value={deliverable.name} />,
    numStates: <TextColumn value={deliverable.num_states} />,
    gtType: <TextColumn value={deliverable.ground_truth_type?.name} />,
    description: <TextColumn value={deliverable.description} />,
  }),
});

// Define a new table component for Deliverables
type AllDeliverablesTableProps = TableOptionals<typeof DeliverableTable> & {
  where?: Deliverables_Bool_Exp[];
};

export const AllDeliverablesTable = (props: AllDeliverablesTableProps) => {
  const [pageVars, pageController] = usePagination();
  const [search, setSearch] = useState("");
  const [includeArchived, setIncludeArchived] = useState(false);
  const searchFilters: Deliverables_Bool_Exp[] = [];
  if (uuidIsValid(search)) {
    searchFilters.push({ id: { _eq: search } });
  }
  if (!includeArchived) {
    searchFilters.push({ deleted_at: { _is_null: true } });
  }
  // Add search terms for individual fields.
  const term = like(search);
  searchFilters.push({ name: { _ilike: term } });
  const { data } = useAllDeliverablesQuery({
    variables: {
      ...pageVars,
      where: { _and: [{ _and: props.where }, ...searchFilters] },
    },
  });

  return (
    <DeliverableTable
      {...props}
      {...pageController}
      total={data?.deliverables_aggregate?.aggregate?.count}
      data={data?.deliverables}
      tools={
        <>
          <Grid item xs={10}>
            <SearchBar onChange={setSearch} />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  checked={includeArchived}
                  onChange={(event) => setIncludeArchived(event.target.checked)}
                />
              }
              label="Include Archived Deliverables"
            />
          </Grid>
        </>
      }
    />
  );
};

// Define content to display in the main Deliverable resource page
type DeliverableIndexProps = TableOptionals<typeof DeliverableTable>;

export const DeliverableIndex = (props: DeliverableIndexProps) => {
  return (
    <TableView>
      <TableViewHeader
        title={<Typography variant="h5">Deliverables</Typography>}
      />
      <Card>
        <AllDeliverablesTable {...props} selectable="none" />
      </Card>
    </TableView>
  );
};

// Define form for creating and editing Deliverables
const deliverableSchema = Yup.object({
  name: Yup.string().required("required"),
  description: Yup.string(),
  num_states: Yup.number().min(1, "must be greater than 0").max(100).required(),
  ground_truth_type: Yup.object({
    id: Yup.string().required("required"),
    name: Yup.string().required("required"),
  }).required(),
}).required();

export type DeliverableFormProps = {
  action: FormAction;
  deliverable?: { id: string };
  onSuccess?: (id: string) => void;
};

export const DeliverableForm = (props: DeliverableFormProps) => {
  const [createDeliverable] = useCreateDeliverableMutation();
  const [updateDeliverable] = useUpdateDeliverableMutation();

  const allDeliverables = useAllDeliverablesQuery({
    variables: {
      where: {
        id: { _nin: props.deliverable?.id ? [props.deliverable.id] : [] },
      },
    },
  });

  const existingDeliverable = useDeliverableQuery({
    variables: { id: props.deliverable?.id ?? "" },
    skip: !props.deliverable,
  });

  return (
    <ResourceForm
      action={props.action}
      schema={deliverableSchema}
      resourceToUpdate={existingDeliverable.data?.deliverable}
      transform={(deliverable) => ({
        name: deliverable.name,
        description: deliverable.description ?? "",
        num_states: deliverable.num_states ?? 2,
        ground_truth_type: {
          id: deliverable.ground_truth_type?.id ?? "",
          name: deliverable.ground_truth_type?.name ?? "",
        },
      })}
      initialValues={{
        name: "",
        description: "",
        num_states: 2,
        ground_truth_type: {
          id: "",
          name: "",
        },
      }}
      customValidator={(values, errors) => {
        for (let existing of allDeliverables.data?.deliverables ?? []) {
          if (existing.name === values.name) {
            errors.name = `Deliverable ${values.name} already exists`;
            break;
          }
        }
      }}
      onUpdate={async (values) => {
        const result = await (async () => {
          if (props.deliverable?.id)
            return await updateDeliverable({
              variables: {
                id: props.deliverable.id,
                input: {
                  name: values.name,
                  description: values.description,
                  num_states: values.num_states,
                  ground_truth_type_id: values.ground_truth_type.id,
                },
              },
            });
        })();
        props.onSuccess && props.onSuccess(result?.data?.deliverable?.id ?? "");
      }}
      onInsert={async (values) => {
        const result = await (async () => {
          return await createDeliverable({
            variables: {
              input: {
                name: values.name,
                description: values.description,
                num_states: values.num_states,
                ground_truth_type_id: values.ground_truth_type.id,
              },
            },
          });
        })();
        props.onSuccess && props.onSuccess(result.data?.deliverable?.id ?? "");
      }}
      render={({ path, formik }) => (
        <>
          <FormHeader>
            {props.deliverable
              ? "Update Deliverable"
              : "Create New Deliverable"}
          </FormHeader>
          <FormContainer>
            <TextField bp={{ xs: 12, sm: 8 }} name={path.name._} label="Name" />
            <IncDecField
              bp={{ xs: 12, sm: 4 }}
              name={path.num_states._}
              label="Number of Output States"
            />
            <TextField
              bp={{ xs: 12 }}
              name={path.description?._ ?? ""}
              label="Description"
            />
            <DialogSelect
              bp={{ xs: 12 }}
              name={path.ground_truth_type.name._}
              label="Ground Truth Type"
              onReset={() =>
                formik.setValues({
                  ...formik.values,
                  ground_truth_type: formik.initialValues.ground_truth_type,
                })
              }
              content={(close) => (
                <AllGroundTruthTypesTable
                  hideColumns={{}}
                  selectable="single"
                  onSelect={(gt_type) => {
                    formik.setValues({
                      ...formik.values,
                      ground_truth_type: {
                        id: gt_type.id ?? "",
                        name: gt_type.name ?? "",
                      },
                    });
                    close();
                  }}
                />
              )}
            />
          </FormContainer>
        </>
      )}
    />
  );
};

// Create a detailed 'show' page.
type DeliverableShowProps = {
  id: string;
  onEditAction?: (item: DeepPartial<Deliverable>) => void;
};

const DeliverableShow = (props: DeliverableShowProps) => {
  const ag = useRequireRole([Roles_Enum.Asgard]);
  const deliverableNav = useDeliverableNav();
  const projectNav = useProjectNav();

  const deliverableQuery = useDeliverableQuery({
    variables: { id: props.id },
    fetchPolicy: "network-only",
  });
  const deliverable = deliverableQuery.data?.deliverable;
  const [updateDeliverable] = useUpdateDeliverableMutation();
  if (!deliverable) return null;

  return (
    <ShowResourceView
      title={deliverable.name}
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb
            label="deliverables"
            onClick={() => deliverableNav.list()}
          />
          <Breadcrumb label={deliverable.name ?? ""} />
        </Breadcrumbs>
      }
      properties={[
        { label: "Description", value: deliverable.description ?? "" },
        { label: "Number of States", value: `${deliverable.num_states}` },
        {
          label: "Ground Truth Type",
          value: deliverable.ground_truth_type?.name ?? "",
        },
      ]}
      archivedAt={deliverable.deleted_at}
      onArchiveAction={
        ag
          ? () => {
              updateDeliverable({
                variables: {
                  id: props.id,
                  input: { deleted_at: new Date().toISOString() },
                },
              }).then(() => deliverableQuery.refetch());
            }
          : undefined
      }
      onUnarchiveAction={
        ag
          ? () => {
              updateDeliverable({
                variables: {
                  id: props.id,
                  input: { deleted_at: null },
                },
              }).then(() => deliverableQuery.refetch());
            }
          : undefined
      }
    >
      <TabView
        useUrlParams={true}
        renderTabs={(tabProps) => (
          <Tabs {...tabProps}>
            <Tab
              label={<TabLabel label="Projects" icon={<ListAltOutlined />} />}
            />
          </Tabs>
        )}
        renderContent={(current) => (
          <>
            <TabContent index={0} current={current}>
              <AllProjectsTable
                selectable="none"
                where={[{ deliverable_id: { _eq: props.id } }]}
                showUrl={(item) => item.id && projectNav.showUrl(item.id)}
                hideColumns={{ deliverable: true }}
              />
            </TabContent>
          </>
        )}
      />
    </ShowResourceView>
  );
};

// Finally, combine into full resource UI.
const path = "deliverables";
export const useDeliverableNav = () => useResourceNav(path);
export const DeliverableResource = () => (
  <Resource
    path={path}
    list={(nav) => (
      <DeliverableIndex showUrl={(item) => item.id && nav.showUrl(item.id)} />
    )}
    show={(nav, id) => <DeliverableShow id={id ?? ""} />}
  />
);
