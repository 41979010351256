import {
  Button,
  ButtonGroup,
  ButtonProps,
  Checkbox as MUICheckbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  RadioGroup as MUIRadioGroup,
  Switch as MUISwitch,
  TextField as MUITextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  AddOutlined,
  AddCircle,
  CheckBox as CheckboxIcon,
  CheckBoxOutlineBlank,
  ClearOutlined,
  Info,
  RemoveCircle,
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import {
  FieldAttributes,
  Form,
  useField,
  useFormikContext,
  FieldArray,
} from "formik";
import React, { useState, useEffect, Fragment } from "react";
import { ClosableDrawer, GridBreakpoints } from "src/Layout";
import DeleteIcon from "@material-ui/icons/Delete";

/*
 * value         |0px     600px    960px    1280px   1920px
 * key           |xs      sm       md       lg       xl
 * screen width  |--------|--------|--------|--------|-------->
 * range         |   xs   |   sm   |   md   |   lg   |   xl
 */

export type FormContainerProps = {} & React.ComponentProps<typeof Form>;

export const FormContainer = (props: FormContainerProps) => {
  return (
    <Form {...props} style={{ padding: "20px" }}>
      <Grid container spacing={3}>
        {props.children}
      </Grid>
    </Form>
  );
};

export type FormActionProps = {
  children?: JSX.Element | JSX.Element[];
};

export const FormAction = (props: FormActionProps) => {
  return (
    <Grid item container xs={12} direction="row-reverse">
      <div style={{ display: "grid", gridGap: 10, gridAutoFlow: "column" }}>
        {props.children}
      </div>
    </Grid>
  );
};

type GridFieldProps = {
  bp?: GridBreakpoints;
};

type GridTextFieldProps = GridFieldProps &
  FieldAttributes<TextFieldProps> & {
    tooltip?: React.ReactNode;
  };

export const TextField = ({
  bp,
  label,
  multiline,
  tooltip,
  ...props
}: GridTextFieldProps) => {
  const [field, meta] = useField(props);
  const ctx = useFormikContext();
  const error = !!(meta.error && meta.touched);
  // Ignore tooltip if user has already provided an end adornment.
  // Otherwise, add adornment with tooltip.
  if (tooltip && !props.InputProps?.endAdornment) {
    props.InputProps = props.InputProps ?? {};
    props.InputProps.endAdornment = (
      <InputAdornment position="end">
        <Tooltip title={<>{tooltip}</>}>
          <Info color="disabled" />
        </Tooltip>
      </InputAdornment>
    );
  }

  return (
    <Grid {...bp} item>
      <MUITextField
        variant="outlined"
        fullWidth
        disabled={ctx.isSubmitting}
        InputLabelProps={{ shrink: field.value ? true : false }}
        error={error}
        helperText={error ? meta.error : " "}
        label={label}
        multiline={multiline}
        {...field}
        {...props}
      />
    </Grid>
  );
};

type NumericFieldProps = {
  name: string;
  label: string;
  disabled?: boolean;
} & GridFieldProps;

export const NumericField = ({
  bp,
  name,
  label,
  disabled,
}: NumericFieldProps) => {
  const [field, meta, helper] = useField(name);
  const ctx = useFormikContext();
  const error = !!(meta.error && meta.touched);
  return (
    <Grid {...bp} item>
      <MUITextField
        variant="outlined"
        fullWidth
        disabled={ctx.isSubmitting || !!disabled}
        InputLabelProps={{
          shrink: field.value || field.value === 0 ? true : false,
        }}
        error={error}
        helperText={error ? meta.error : " "}
        label={label}
        {...field}
        value={field.value ?? ""} // Needed to allow null values
        onChange={(event) => {
          if (event.target.value === "") helper.setValue(null);
          else if (!isNaN(Number(event.target.value)))
            helper.setValue(Number(event.target.value));
        }}
      />
    </Grid>
  );
};

export type GridTextFieldArrayProps = {
  name: string;
  label: string;
  bp?: GridBreakpoints;
} & GridTextFieldProps;

export const TextFieldArray = ({
  name,
  label,
  bp,
}: GridTextFieldArrayProps) => {
  const [field] = useField(name);
  return (
    <Grid item {...bp}>
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <Grid container spacing={2}>
            {field.value?.map((_: string, index: number) => (
              <Fragment key={index}>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={`${label} #${index + 1}`}
                    name={`${name}.${index}`}
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton onClick={() => arrayHelpers.remove(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Fragment>
            ))}
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => arrayHelpers.push("")}
              >
                Add {label}
              </Button>
            </Grid>
          </Grid>
        )}
      />
    </Grid>
  );
};

export type SwitchProps = {
  bp?: GridBreakpoints;
  label: string;
  disabled?: boolean;
  name: string;
};

export const Switch = ({ bp, label, disabled, name }: SwitchProps) => {
  const [field, , helpers] = useField({ name, type: "checkbox" });
  const ctx = useFormikContext();
  return (
    <Grid item {...bp}>
      <FormControlLabel
        control={
          <MUISwitch
            disabled={ctx.isSubmitting || disabled}
            checked={field.checked}
            onChange={(event) => helpers.setValue(event.target.checked)}
          />
        }
        label={label}
      />
    </Grid>
  );
};

type SelectProps = GridFieldProps & FieldAttributes<TextFieldProps>;

export const Select = ({ bp, label, children, ...props }: SelectProps) => {
  const [field, meta] = useField(props);
  const ctx = useFormikContext();
  const error = !!(meta.error && meta.touched);

  return (
    <Grid {...bp} item>
      <MUITextField
        select
        variant="outlined"
        fullWidth
        disabled={ctx.isSubmitting}
        error={error}
        helperText={error ? meta.error : " "}
        label={label}
        {...field}
        {...props}
      >
        {children}
      </MUITextField>
    </Grid>
  );
};

export type DialogSelectProps = {
  bp?: GridBreakpoints;
  content?: (close: () => void) => React.ReactElement;
  label?: string;
  onReset?: () => void;
  tooltip?: string;
} & FieldAttributes<object>;

export const DialogSelect = ({
  bp,
  label,
  content,
  onReset,
  ...props
}: DialogSelectProps) => {
  const [show, setShow] = useState(false);
  const [field, meta] = useField(props);
  const ctx = useFormikContext();
  const error = !!(meta.error && meta.touched);
  const disabled = props.disabled || ctx.isSubmitting;
  return (
    <>
      <Grid {...bp} item>
        <MUITextField
          {...field}
          error={error}
          helperText={error ? meta.error : " "}
          label={label}
          fullWidth
          variant="outlined"
          disabled={disabled}
          InputProps={{
            readOnly: true,
            endAdornment: !disabled && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="add"
                  disabled={disabled}
                  onClick={() =>
                    field.value ? onReset && onReset() : setShow(true)
                  }
                >
                  {field.value ? (
                    onReset ? (
                      <ClearOutlined />
                    ) : null
                  ) : (
                    <AddOutlined />
                  )}
                </IconButton>
                {props.tooltip && (
                  <Tooltip title={props.tooltip}>
                    <Info color="disabled" />
                  </Tooltip>
                )}
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <ClosableDrawer show={show} setShow={setShow}>
        {content && content(() => setShow(false))}
      </ClosableDrawer>
    </>
  );
};

export type DrawerButtonProps = {
  bp?: GridBreakpoints;
  label?: React.ReactNode;
  disabled?: boolean;
  content?: (close: () => void) => React.ReactElement;
  tooltip?: string;
} & ButtonProps;

export const DrawerButton = ({
  bp,
  content,
  label,
  disabled,
  ...props
}: DrawerButtonProps) => {
  const [show, setShow] = useState(false);
  const display = (
    <Button {...props} disabled={disabled} onClick={() => setShow(true)}>
      {label}
    </Button>
  );
  if (!!props.tooltip) {
    return (
      <>
        <Grid {...bp} item>
          <Tooltip title={props.tooltip} placement="bottom" arrow>
            <span>{display}</span>
          </Tooltip>
        </Grid>
        <ClosableDrawer show={show} setShow={setShow}>
          {content && content(() => setShow(false))}
        </ClosableDrawer>
      </>
    );
  } else {
    return (
      <>
        <Grid {...bp} item>
          {display}
        </Grid>
        <ClosableDrawer show={show} setShow={setShow}>
          {content && content(() => setShow(false))}
        </ClosableDrawer>
      </>
    );
  }
};

export type RadioGroupProps = {
  children?: React.ReactElement[] | React.ReactElement;
  label: string;
  bp?: GridBreakpoints;
  row?: boolean;
} & FieldAttributes<object>;

export const RadioGroup = ({ row = true, ...props }: RadioGroupProps) => {
  const [field, meta] = useField(props);
  const ctx = useFormikContext();
  const error = !!(meta.error && meta.touched);

  return (
    <Grid {...props.bp} style={{ paddingLeft: 20 }} item>
      <FormControl
        component="fieldset"
        error={error}
        disabled={ctx.isSubmitting}
      >
        <FormLabel component="legend">{props.label}</FormLabel>
        <MUIRadioGroup {...field} row={row}>
          {props.children}
        </MUIRadioGroup>
        <FormHelperText>{error ? meta.error : " "}</FormHelperText>
      </FormControl>
    </Grid>
  );
};

type MultiSelectProps<T> = {
  options?: Array<T>;
  getOptionLabel: (opt: T) => string;
  label?: string;
  getOptionSelected?: (o: T, v: T) => boolean;
  bp?: GridBreakpoints;
  multiple?: boolean; // Defaults to multiple.
} & FieldAttributes<object>;

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckboxIcon fontSize="small" />;

export function MultiSelect<T>(props: MultiSelectProps<T>) {
  const [{ value, ...field }, meta] = useField(props);
  const ctx = useFormikContext();
  const error = !!(meta.error && meta.touched);
  const errorMessage = JSON.stringify(meta.error);

  return (
    <Grid {...props.bp} item>
      <Autocomplete
        {...field}
        value={value as T[]} // Autocomplete is always controlled
        options={props.options ?? []}
        getOptionLabel={props.getOptionLabel}
        getOptionSelected={props.getOptionSelected}
        disabled={ctx.isSubmitting || props.disabled || !props.options}
        onChange={({ target, ...e }, nv) => {
          // This is a slightly hacky solution to make Formik understand the
          // event, since the event from the Autocomplete component is not
          // compatible, and is missing name, value etc...
          const ev: React.ChangeEvent<{}> = {
            ...e,
            target: {
              name: field.name,
              value: nv,
            } as any,
          };
          field.onChange(ev);
        }}
        fullWidth
        multiple={props.multiple ?? true}
        disableCloseOnSelect={props.multiple ?? true}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            {(props.multiple ?? true) && (
              <MUICheckbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
            )}
            {props.getOptionLabel(option)}
          </React.Fragment>
        )}
        renderInput={(params) => (
          <MUITextField
            {...params}
            name={field.name}
            fullWidth={true}
            variant="outlined"
            label={props.label}
            error={error}
            helperText={error ? errorMessage : " "}
            placeholder={props.placeholder}
          />
        )}
      />
    </Grid>
  );
}

type FormHeaderProps = {
  children: React.ReactNode | React.ReactNode[];
};

export const FormHeader = ({ children }: FormHeaderProps) => (
  <Typography variant="h6" style={{ margin: 15, marginLeft: 23 }}>
    {children}
  </Typography>
);

export type ActionInfo<TAction extends symbol | string | number, TInfo> = {
  [key in TAction]: TInfo;
};

export type FieldPath<P, S> = {
  _: string & { _BRAND_: P & S };
};

export type PathProxy<P, S> = FieldPath<P, S> & {
  [K in keyof S]: PathProxy<P, S[K]>;
};

const IdPath = { _: "" } as FieldPath<any, any>;

/**
 * Construct a string path out of an object
 */
export function pathProxy<S, P = S>(
  parent: FieldPath<P, S> = IdPath as any,
): PathProxy<P, S> {
  return new Proxy(parent as any, {
    get(target: any, key: any) {
      if (key in target) return target[key];
      return pathProxy<any, any>({
        _: `${parent._ && parent._ + "."}${key}`,
      } as any);
    },
  });
}

/**
 * Create a means of doing something with Formik errors when submission fails.
 */
type FormikSubmitFailureEffectProps = {
  onSubmitFailure: (errors: { [field: string]: string }) => void;
};
export const FormikSubmitFailureEffect = ({
  onSubmitFailure,
}: FormikSubmitFailureEffectProps) => {
  const { submitCount, isSubmitting, errors } = useFormikContext();
  const [lastHandled, setLastHandled] = useState(0);
  useEffect(() => {
    // Discover if Formik has attempted the submission process.
    if (submitCount > lastHandled) {
      if (!isSubmitting && Object.keys(errors).length > 0) {
        setLastHandled(submitCount);
        onSubmitFailure(errors);
      }
    }
  }, [submitCount, isSubmitting, errors, lastHandled, onSubmitFailure]);
  return null;
};

type IncDecFieldProps = {
  name: string;
  label: string;
  disabled?: boolean;
  bp?: GridBreakpoints;
};

export const IncDecField = ({ disabled, bp, ...props }: IncDecFieldProps) => {
  const [field, meta, helpers] = useField(props);
  const inc = (inc: number) => helpers.setValue(+field.value + inc);
  // helpers.setValue(Math.max(+field.value + inc, 1));
  const ctx = useFormikContext();
  const error = !!(meta.error && meta.touched);
  return (
    <Grid item {...bp}>
      <FormGroup>
        <MUITextField
          {...field}
          {...props}
          fullWidth={true}
          error={error}
          helperText={error ? meta.error : " "}
          disabled={ctx.isSubmitting || disabled}
          InputProps={{
            endAdornment: !disabled && (
              <InputAdornment position="end">
                <ButtonGroup size="small" color="primary">
                  <Button
                    variant="text"
                    disabled={ctx.isSubmitting || disabled}
                    onClick={() => inc(-1)}
                  >
                    <RemoveCircle />
                  </Button>
                  <Button
                    variant="text"
                    disabled={ctx.isSubmitting || disabled}
                    onClick={() => inc(1)}
                  >
                    <AddCircle />
                  </Button>
                </ButtonGroup>
              </InputAdornment>
            ),
          }}
        />
      </FormGroup>
    </Grid>
  );
};

export type GridCheckboxProps = {
  bp?: GridBreakpoints;
  label: string;
  name: string;
};

export const Checkbox = ({ bp, label, ...props }: GridCheckboxProps) => {
  const [field, meta] = useField(props);
  const ctx = useFormikContext();
  const error = !!(meta.error && meta.touched);

  return (
    <Grid {...bp} item>
      <FormControl
        component="fieldset"
        error={error}
        disabled={ctx.isSubmitting}
      >
        <FormControlLabel
          control={<MUICheckbox checked={field.value === true} {...field} />}
          label={label}
        />
        <FormHelperText>{error ? meta.error : " "}</FormHelperText>
      </FormControl>
    </Grid>
  );
};

export type GridTextFieldWithOptionsProps = {
  bp?: GridBreakpoints;
  label: string;
  name: string;
  options: string[];
  disabled?: boolean;
};

export const TextFieldWithOptions = ({
  bp,
  label,
  disabled,
  ...props
}: GridTextFieldWithOptionsProps) => {
  const [{ value, ...field }, meta] = useField(props);
  const ctx = useFormikContext();
  const error = !!(meta.error && meta.touched);
  const errorMessage = JSON.stringify(meta.error);
  return (
    <Grid item {...bp}>
      <Autocomplete
        freeSolo
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        disableClearable
        {...field}
        value={value}
        disabled={ctx.isSubmitting || disabled}
        options={props.options}
        onChange={({ target, ...e }, nv) => {
          // Reuse the "slightly hacky" solution from above
          const ev: React.ChangeEvent<{}> = {
            ...e,
            target: {
              name: field.name,
              value: nv,
            } as any,
          };
          field.onChange(ev);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            name={field.name}
            error={error}
            helperText={error ? errorMessage : " "}
            variant="outlined"
          />
        )}
      />
    </Grid>
  );
};
