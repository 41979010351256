import React, { useCallback, useEffect, useState } from "react";
import {
  FormikContextType,
  FormikErrors,
  FormikProvider,
  useFormik,
} from "formik";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
} from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import * as Yup from "yup";
import { pathProxy, PathProxy } from "src/components/Form";
import { useNotification } from "src/Notification";
import { md } from "src/resources/Utils";

export type FormAction = "update" | "insert";

type ResourceFormProps<TData, TSchema, TValues = Yup.InferType<TSchema>> = {
  action: FormAction;
  /** The `Yup` schema used to validate the form fields. */
  schema: TSchema;
  /**
   * The initial values for the form fields.
   * Do not use to hydrate the form, as changes to this property will not
   * trigger a re-render.
   **/
  initialValues: TValues;
  /** Required to hydrate the form fields when updating an existing resource. */
  transform: (data: TData) => TValues;
  /** The resource to update. */
  resourceToUpdate?: TData | undefined | null;
  /**
   * Use to modify the form manually. Will only fire once if defined. To fire
   * subsequent times, the property must be undefined first, then redefined.
   **/
  modifyForm?: (
    path: PathProxy<TValues, TValues>,
    formik: FormikContextType<TValues>,
  ) => void;
  /**
   * Will fire whenever the fields are changed. Beware using state `set`
   * functions in this callback without a proper guarding condition
   * (see https://react.dev/reference/react/useState#storing-information-from-previous-renders).
   */
  onValueChange?: (values: TValues) => void;
  /**
   * Use to detect and supply errors based on more complex conditions than are
   * possible with the schema alone. Will fire whenever the fields are changed.
   **/
  customValidator?: (values: TValues, errors: FormikErrors<TValues>) => void;
  /** Disable the submit button, based on current form values. */
  disableSubmit?: (values: TValues) => boolean;
  /** Fires when submitting the form when configured to "update" */
  onUpdate: (values: TValues) => void;
  /** Fires when submitting the form when configured to "insert" */
  onInsert: (values: TValues) => void;
  /** Use to render a confirmation page as a separate dialog component */
  renderConfirmationDialog?: (helpers: {
    path: PathProxy<TValues, TValues>;
    formik: FormikContextType<TValues>;
  }) => React.ReactNode;
  /** The form content. */
  render: (helpers: {
    path: PathProxy<TValues, TValues>;
    formik: FormikContextType<TValues>;
  }) => React.ReactNode;
  /** Use for long forms to avoid validating the entire form each time a field is changed. */
  skipValidateOnChange?: boolean;
};

export function ResourceForm<
  TData extends object,
  TSchema extends Yup.ObjectSchema,
>(props: ResourceFormProps<TData, TSchema>) {
  const notification = useNotification();
  type TValues = Yup.InferType<TSchema>;
  const path = pathProxy<TValues>();

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const customValidate = props.customValidator
    ? (values: TValues) => {
        const errors: FormikErrors<TValues> = {};
        props.customValidator && props.customValidator(values, errors);
        return errors;
      }
    : undefined;

  // Setup formik.
  const formik = useFormik<TValues>({
    validationSchema: props.schema,
    validate: customValidate,
    initialValues: props.initialValues,
    validateOnChange: !props.skipValidateOnChange,
    onSubmit: (values) => {
      props.action === "update"
        ? props.onUpdate(values)
        : props.onInsert(values);
    },
  });

  // Setup effect for hydrating form when updating existing.
  const { values, setValues, setSubmitting, errors } = formik;
  const {
    action,
    resourceToUpdate,
    onUpdate,
    onValueChange,
    modifyForm,
    transform,
  } = props;
  const [resourceToUpdateLoaded, setResourceToUpdateLoaded] = useState(false);
  useEffect(() => {
    if (action === "update") {
      if (!resourceToUpdate) {
        setSubmitting(true);
      } else if (!resourceToUpdateLoaded) {
        setValues(transform(resourceToUpdate));
        setSubmitting(false);
        setResourceToUpdateLoaded(true);
      }
    }
  }, [
    setValues,
    action,
    resourceToUpdate,
    onUpdate,
    transform,
    setSubmitting,
    resourceToUpdateLoaded,
    setResourceToUpdateLoaded,
  ]);

  useEffect(() => {
    onValueChange && onValueChange(values);
  }, [onValueChange, values]);

  const [updateApplied, setUpdateApplied] = useState(false);
  useEffect(() => {
    if (modifyForm && !updateApplied) {
      modifyForm(path, formik);
      setUpdateApplied(true);
    } else if (!modifyForm && updateApplied) {
      setUpdateApplied(false);
    }
  }, [modifyForm, updateApplied, setUpdateApplied, formik, path]);

  const notifyErrors = useCallback(() => {
    if (Object.keys(errors).length) {
      notification.create({
        severity: "error",
        title: `Form Error`,
        body: md()
          .syntax("json", JSON.stringify({ errors, values }, null, 2))
          .get(),
      });
    }
  }, [values, errors, notification]);

  return (
    <FormikProvider value={formik}>
      <div style={{ marginBottom: 30 }}>{props.render({ path, formik })}</div>
      <div
        style={{
          position: "fixed",
          top: "auto",
          right: 30,
          bottom: 30,
          left: "auto",
        }}
      >
        <Fab
          size="large"
          color="primary"
          variant="extended"
          disabled={formik.isSubmitting}
          onClick={() => {
            formik.resetForm();
            setValues(
              props.resourceToUpdate
                ? props.transform(props.resourceToUpdate)
                : props.initialValues,
            );
          }}
        >
          <Refresh />
        </Fab>
        <Fab
          style={{ marginLeft: 4 }}
          size="large"
          color="primary"
          variant="extended"
          disabled={
            formik.isSubmitting ||
            (props.disableSubmit ? props.disableSubmit(values) : false)
          }
          onClick={() => {
            if (props.renderConfirmationDialog) {
              setConfirmationDialogOpen(true);
            } else {
              formik.handleSubmit();
              notifyErrors();
            }
          }}
        >
          {props.action}
        </Fab>
      </div>
      {props.renderConfirmationDialog && (
        <Dialog
          open={confirmationDialogOpen}
          onClose={() => setConfirmationDialogOpen(false)}
        >
          <DialogContent>
            {props.renderConfirmationDialog({ path, formik })}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              disableElevation
              onClick={() => setConfirmationDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              disabled={
                formik.isSubmitting ||
                (props.disableSubmit ? props.disableSubmit(values) : false)
              }
              onClick={() => {
                formik.handleSubmit();
                notifyErrors();
              }}
            >
              {props.action}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </FormikProvider>
  );
}
