import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Checkbox,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Radio,
  RadioGroup,
  Switch,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  Check,
  Close,
  CloudDownload,
  EditOutlined,
  Save,
} from "@material-ui/icons";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { DefaultTheme } from "@material-ui/styles";
import Markdown from "react-markdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import { RequireRole, useRequireRole } from "src/auth";
import {
  ProjectOverviewQuery,
  Roles_Enum,
  useAgmg_AddUserToProjectMutation,
  useAgmg_RemoveUserFromProjectMutation,
  useAg_UpdateProjectMutation,
  useSlackChannelsQuery,
  Users,
  useSetProjectGreatnessEnabledMutation,
  useSetProjectTagGroundTruthEnabledMutation,
  useSetProjectArcadiaEnabledMutation,
  useUpdateSlackChannelMutation,
  useProjectDataCollectionProgressQuery,
  useProjectChecklistProgressQuery,
} from "src/generated/asgard/graphql";
import { useNotification } from "src/Notification";
import { UserList } from "src/resources/User";
import { MarkdownEditor } from "src/components/MarkdownEditor";
import {
  DeviceRecordingsChart,
  RecordingsChart,
  RecordingsChartProps,
  RoomRecordingsChart,
} from "./RecordingsChart";
import {
  RecordingsQualityChart,
  TagDisplayGroups,
  TagPlotTypes,
} from "./RecordingsQualityChart";
import { UserListItem } from "./UserListItem";
import { AggDevicesTable, DeviceForm } from "src/resources/Device";
import { ClosableDrawer } from "src/Layout";
import { OpmodeChip, OpMode } from "src/components/table/OpmodeChip";
import { green, grey, red } from "@material-ui/core/colors";
import { makeBlobServiceClient } from "src/auth/azureProvider";
import { Autocomplete } from "@material-ui/lab";
import { MultiProgressBar } from "src/components/MultiProgressBar";
import { Tablature } from "src/Layout";

type NonNullable<T> = Exclude<T, null | undefined>;

const storage_url = "https://storage1ellipticlabs.blob.core.windows.net";

export const DownloadButton = (props: {
  customer: string;
  model: string;
  variant: string;
  deliverable: string;
  children: React.ReactNode;
}) => {
  const [double, setDouble] = useState(false);
  const [filename, setFilename] = useState<string | undefined>(undefined);
  const [urlExists, setUrlExists] = useState(false);
  const [loading, setLoading] = useState(false);
  const downloadRef = useRef<HTMLAnchorElement>(null);
  const { customer, model, variant, deliverable } = props;
  const projectName = `${customer}_${model}_${variant}_${deliverable}`;
  useEffect(() => {
    const checkUrl = async () => {
      try {
        const blobServiceClient = makeBlobServiceClient(storage_url);
        if (blobServiceClient) {
          const containerClient =
            blobServiceClient?.getContainerClient("project-statistics");
          const blobClient = containerClient?.getBlobClient(
            "ProjectsByName/" + projectName + ".csv",
          );
          const properties = await blobClient?.getProperties();
          if (
            properties &&
            properties.contentLength &&
            properties.contentLength > 0
          ) {
            setUrlExists(true);
          } else {
            setUrlExists(false);
          }
        }
      } catch (error) {
        setUrlExists(false);
      }
    };
    checkUrl();
  }, [projectName]);
  const downloadCSV = async (projectName: string) => {
    try {
      setLoading(true);
      const blobServiceClient = makeBlobServiceClient(storage_url);
      if (blobServiceClient) {
        const containerClient =
          blobServiceClient?.getContainerClient("project-statistics");
        const blobClient = containerClient?.getBlobClient(
          "ProjectsByName/" + projectName + ".csv",
        );
        const downloadBlockBlobResponse = await blobClient?.download();
        if (downloadBlockBlobResponse.blobBody) {
          const url = window.URL.createObjectURL(
            await downloadBlockBlobResponse.blobBody,
          );
          setFilename(projectName + "_stats.csv");
          if (downloadRef != null && downloadRef.current != null) {
            downloadRef.current.href = url;
            downloadRef.current.click();
          }
        }
      }
    } catch (error) {
      setUrlExists(false);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Button
      style={{ marginBottom: 20 }}
      variant="contained"
      color="primary"
      disableElevation
      fullWidth={true}
      startIcon={<CloudDownload />}
      disabled={double || loading || !urlExists}
      onClick={() => {
        setDouble(true);
        downloadCSV(projectName).finally(() => setDouble(false));
      }}
    >
      {" "}
      <a style={{ display: "none" }} download={filename} ref={downloadRef} />
      {props.children}
    </Button>
  );
};

export type OverviewTabProps = {
  project: NonNullable<ProjectOverviewQuery["project"]>;
  isManager: boolean;
  downloadButton?: boolean;
};

export function OverviewTab({
  project,
  isManager,
  downloadButton,
}: OverviewTabProps) {
  const ag = useRequireRole([Roles_Enum.Asgard]);
  const name = project.project_name?.display_name ?? "unknown";

  const notification = useNotification();
  const classes = useStyles();

  // Sepparate users into lists of managers and data collectors
  const managers = project.project_users.filter((u) => u.manager === true);
  const collectors = project.project_users.filter((u) => u.manager === false);

  const [addUserToProject] = useAgmg_AddUserToProjectMutation();
  const [removeUserFromProject] = useAgmg_RemoveUserFromProjectMutation();
  const [updateProject] = useAg_UpdateProjectMutation();
  const [updateSlackChannel] = useUpdateSlackChannelMutation();
  const validSlackChannels = useSlackChannelsQuery();

  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const handleEditDescription = () => {
    setIsEditingDescription(true);
  };

  const [isUpdatingSlack, setIsUpdatingSlack] = useState<
    "done" | "updating" | "success"
  >("done");
  const [slackChannel, setSlackChannel] = useState(project.slack_channel ?? "");
  useEffect(() => {
    setSlackChannel(project.slack_channel ?? "");
  }, [project.slack_channel]);

  const [isUpdatingDevices, setIsUpdatingDevices] = useState<
    "done" | "updating" | "success"
  >("done");
  const [devices, setDevices] = useState(project.min_devices_per_task ?? "");
  useEffect(() => {
    setDevices(project.min_devices_per_task ?? "");
  }, [project.min_devices_per_task]);

  const handleRemoveUser = async (id: string) => {
    await removeUserFromProject({
      variables: { project_id: project.id, user_id: id },
    });
  };
  const handleUpdateDescription = async (md: string) => {
    setIsEditingDescription(false);
    await updateProject({
      variables: {
        id: project.id,
        input: {
          description: md,
        },
        add_opmodes: [],
        remove_opmode_project_ids: [],
        add_combo_projects: [],
        remove_combo_project_ids: [],
        add_modes: [],
        remove_mode_ids: [],
      },
    });
  };

  const handleSaveSlackChannel = async (sc: string) => {
    setIsUpdatingSlack("updating");
    // Add # to the beginning of the slack channel in case it's missing
    if (sc.lastIndexOf("#") !== 0) {
      sc = `#${sc}`;
      console.log(sc);
    }
    await updateSlackChannel({
      variables: {
        project_id: project.id,
        slack_channel: sc,
      },
    });
    setIsUpdatingSlack("success");
    setTimeout(() => {
      setIsUpdatingSlack("done");
    }, 1000);
  };

  const handleSaveDevices = async (num: number) => {
    if (isNaN(num)) return;
    setIsUpdatingDevices("updating");
    await updateProject({
      variables: {
        id: project.id,
        input: {
          min_devices_per_task: num,
        },
        add_opmodes: [],
        remove_opmode_project_ids: [],
        add_combo_projects: [],
        remove_combo_project_ids: [],
        add_modes: [],
        remove_mode_ids: [],
      },
    });
    setIsUpdatingDevices("success");
    setTimeout(() => {
      setIsUpdatingDevices("done");
    }, 1000);
  };

  // Handler for adding user to a project
  // If user is successfully added to the project, flash a "success" message
  const handleAddUser = async (user: DeepPartial<Users>, manager: boolean) => {
    try {
      await addUserToProject({
        variables: {
          object: { project_id: project.id, manager, user_id: user.id },
        },
      });
      notification.create({
        severity: "success",
        title: `Added user ${user.name} to project ${name}`,
        flashOnly: true,
      });
    } catch (e) {}
  };

  const headsUpProjectDescription = (() => {
    if (isEditingDescription) {
      return (
        <MarkdownEditor
          data={project.description ?? ""}
          onSave={handleUpdateDescription}
          onClose={() => setIsEditingDescription(false)}
        />
      );
    }

    return (
      <Fragment>
        <CardHeader
          style={{ padding: 10, paddingBottom: 0 }}
          subheader="Description"
          action={
            ag ? (
              <IconButton onClick={handleEditDescription}>
                <EditOutlined />
              </IconButton>
            ) : null
          }
        />
        <CardContent style={{ maxHeight: 500, overflowY: "auto", padding: 3 }}>
          <div className="mde-preview mde-preview-content">
            <div className="mde-preview-content">
              <Markdown>{project.description ?? ""}</Markdown>
            </div>
          </div>
        </CardContent>
      </Fragment>
    );
  })();

  const opmodes = project.opmode_projects.map(
    (o): OpMode => ({
      num: o.opmode.number,
      simOpMode: false,
      calibOpMode: false,
    }),
  );
  if (project.simulation_opmode) {
    opmodes.push({
      num: project.simulation_opmode.number,
      simOpMode: true,
      calibOpMode: false,
    });
  }
  if (project.calibration_opmode) {
    opmodes.push({
      num: project.calibration_opmode.number,
      simOpMode: false,
      calibOpMode: true,
    });
  }
  const headsUpCard = (
    <Card style={{ marginBottom: 20 }}>
      <div style={{ position: "relative" }}>
        <CardMedia
          image={`https://picsum.photos/seed/${project?.id}/800/400`}
          title="cover"
          className={classes.media}
        />
        <div className={classes.cardLayer}>
          {opmodes.map((o) => (
            <OpmodeChip
              opMode={o}
              key={`${o.num}_${o.simOpMode}_${o.calibOpMode}`}
            />
          ))}
        </div>
      </div>
      {headsUpProjectDescription}
    </Card>
  );

  const [include, setInclude] = useState<RecordingsChartProps["include"]>({
    clean: true,
    dirty: false,
    untagged: false,
  });
  const [valueToPlot, setValueToPlot] = useState<"count" | "duration">("count");
  const recordingsBarChartCard = (
    <Card style={{ marginBottom: 20 }}>
      <CardHeader title="Recordings" />
      <CardContent
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <FormControl>
          <FormLabel>Value</FormLabel>
          <RadioGroup
            row
            value={valueToPlot}
            onChange={(e) => {
              const value = e.target.value;
              switch (value) {
                case "count":
                case "duration":
                  setValueToPlot(value);
                  break;
                default:
                  setValueToPlot("count");
              }
            }}
          >
            <FormControlLabel value="count" control={<Radio />} label="Count" />
            <FormControlLabel
              value="duration"
              control={<Radio />}
              label="Duration"
            />
          </RadioGroup>
        </FormControl>
        <FormControl>
          <FormLabel>Include</FormLabel>
          <Grid container>
            <FormControlLabel
              control={
                <Checkbox
                  style={{ margin: 0 }}
                  checked={include.clean}
                  onChange={(event) =>
                    setInclude((s) => ({ ...s, clean: event.target.checked }))
                  }
                />
              }
              label="Clean"
            />
            <FormControlLabel
              control={
                <Checkbox
                  style={{ margin: 0 }}
                  checked={include.dirty}
                  onChange={(event) =>
                    setInclude((s) => ({ ...s, dirty: event.target.checked }))
                  }
                />
              }
              label="Dirty"
            />
            <FormControlLabel
              control={
                <Checkbox
                  style={{ margin: 0 }}
                  checked={include.untagged}
                  onChange={(event) =>
                    setInclude((s) => ({
                      ...s,
                      untagged: event.target.checked,
                    }))
                  }
                />
              }
              label="Untagged"
            />
          </Grid>
        </FormControl>
      </CardContent>
      <div style={{ height: 500 }}>
        <RecordingsChart
          project_id={project.id}
          value_to_plot={valueToPlot}
          include={include}
        />
      </div>
    </Card>
  );

  const [qualityChartPlotType, setQualityChartPlotType] =
    useState<TagPlotTypes>("pie");
  const [qualityChartTagOptions, setQualityChartTagOptions] =
    useState<TagDisplayGroups>("show_highlighted");
  const recordingsQualityChartCard = (
    <Card style={{ marginBottom: 20 }}>
      <CardHeader title="Quality tags" />
      <CardContent
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <FormControl>
          {/* <FormLabel>Chart type</FormLabel> */}
          <Tooltip title="Toggle bar/pie chart">
            <FormControlLabel
              control={
                <Switch
                  defaultChecked={false}
                  onChange={(e) => {
                    const is_checked = e.target.checked;
                    if (is_checked) {
                      setQualityChartPlotType("bar");
                    } else {
                      setQualityChartPlotType("pie");
                    }
                  }}
                />
              }
              label="View by day"
            />
          </Tooltip>
        </FormControl>
        <Tooltip title="Select tags to plot">
          <Select
            style={{ margin: 0 }}
            value={qualityChartTagOptions}
            label="Select tags to plot"
            native={true}
            onChange={(e) => {
              // I don't understand why e.target.value's type is unknown
              // so I have to use switch here
              const selected_value = e.target.value;
              switch (selected_value) {
                case "show_all":
                  setQualityChartTagOptions(selected_value);
                  break;
                case "show_dirty":
                  setQualityChartTagOptions(selected_value);
                  break;
                case "show_highlighted":
                  setQualityChartTagOptions(selected_value);
                  break;
                default:
                  setQualityChartTagOptions("show_all");
                  break;
              }
            }}
          >
            <option value={"show_highlighted"}>show highlighted tags</option>
            <option value={"show_all"}>show all tags</option>
            <option value={"show_dirty"}>show dirty tags</option>
          </Select>
        </Tooltip>
      </CardContent>
      <div style={{ height: 500 }}>
        <RecordingsQualityChart
          project_ids={project.id}
          plot_type={qualityChartPlotType}
          tag_options={qualityChartTagOptions}
        />
      </div>
    </Card>
  );

  const managersCard = (
    <Card style={{ marginBottom: 20 }}>
      <CardContent>
        <Typography>Managers</Typography>
        <List>
          {managers.map((m) => (
            <UserListItem
              key={m.user.id}
              id={m.user.id}
              name={m.user.name ?? "anon"}
              email={m.user.email ?? m.user.name ?? "anon"}
              recordings={m.user?.project_dataset_aggregate?.aggregate?.count}
              disableAction={!ag}
              onRemoveUser={handleRemoveUser}
              deactivated={m.user.deleted_at !== null}
            />
          ))}
        </List>
      </CardContent>
      <RequireRole roles={[Roles_Enum.Asgard]}>
        <Divider />
        <CardActions>
          <DrawerButton
            label="Add manager"
            content={(close) => (
              <UserList
                selectable="single"
                exclude={project.project_users.map((u) => u.user.id)}
                onSelect={(u) => {
                  handleAddUser(u, true);
                  close();
                }}
              />
            )}
          />
        </CardActions>
      </RequireRole>
    </Card>
  );

  const dataCollectorsCard = (
    <Card style={{ marginBottom: 20 }}>
      <CardContent>
        <Typography>Members</Typography>
        <List>
          {collectors?.map((c) => (
            <UserListItem
              key={c.user.id}
              id={c.user.id}
              name={c.user.name ?? "anon"}
              email={c.user.email ?? c.user.name ?? "anon"}
              disableAction={!isManager && !ag}
              recordings={c.user?.project_dataset_aggregate?.aggregate?.count}
              onRemoveUser={handleRemoveUser}
              deactivated={c.user.deleted_at !== null}
            />
          ))}
        </List>
      </CardContent>
      <RequireRole roles={[Roles_Enum.Asgard, Roles_Enum.Midgard]}>
        {isManager || ag ? (
          <>
            <Divider />
            <CardActions>
              <DrawerButton
                label="Add member"
                content={(close) => (
                  <UserList
                    selectable="single"
                    exclude={project.project_users.map((u) => u.user.id)}
                    onSelect={(u) => {
                      handleAddUser(u, false);
                      close();
                    }}
                  />
                )}
              />
            </CardActions>
          </>
        ) : null}
      </RequireRole>
    </Card>
  );

  const [deviceToEdit, setDeviceToEdit] = useState<string | null>(null);
  const devicesCard = (
    <>
      <Card style={{ marginBottom: 20 }}>
        <CardHeader title="Device overview" />
        <CardContent>
          <Tablature
            tabs={[
              {
                name: "table",
                label: "Table",
                content: (
                  <AggDevicesTable
                    project_id={project.id}
                    selectable="none"
                    hideColumns={{ id: true }}
                    onEditAction={(item) =>
                      item.device?.id && setDeviceToEdit(item.device?.id)
                    }
                  />
                ),
              },
              {
                name: "chart",
                label: "Chart",
                content: (
                  <div style={{ height: 400 }}>
                    <DeviceRecordingsChart project_id={project.id} />
                  </div>
                ),
              },
            ]}
          />
        </CardContent>
      </Card>
      <ClosableDrawer
        show={deviceToEdit !== null}
        setShow={(show) => show === false && setDeviceToEdit(null)}
      >
        <div style={{ width: "500px" }}>
          <DeviceForm
            action="update"
            onSuccess={() => setDeviceToEdit(null)}
            device={deviceToEdit ? { id: deviceToEdit } : undefined}
          />
        </div>
      </ClosableDrawer>
    </>
  );

  const roomCard = (
    <>
      <Card>
        <CardHeader title="Room overview" />
        <CardContent>
          <div style={{ height: 400 }}>
            <RoomRecordingsChart project_id={project.id} />
          </div>
        </CardContent>
      </Card>
    </>
  );

  const slackCard = (
    <Card style={{ marginBottom: 20 }}>
      <CardContent>
        <div style={{ display: "flex" }}>
          <Autocomplete
            id="slack-channel-selection"
            disabled={validSlackChannels.loading}
            value={slackChannel}
            options={
              validSlackChannels.data?.slack_channels?.map(
                (s) => `#${s?.name.slice(0, 99)}`,
              ) ?? []
            }
            getOptionLabel={(option) => option ?? ""}
            fullWidth
            onChange={(e, v) => {
              if (v == null) {
                return;
              }
              setSlackChannel(v);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Slack channel"
                fullWidth
              />
            )}
          />
          <IconButton
            disabled={isUpdatingSlack !== "done"}
            aria-label="Save slack channel"
            onClick={() => handleSaveSlackChannel(slackChannel)}
          >
            {(() => {
              if (isUpdatingSlack !== "success") return <Save />;
              if (isUpdatingSlack === "success")
                return <Check color="secondary" />;
            })()}
          </IconButton>
        </div>
      </CardContent>
      {ag ? (
        <CardContent>
          <TextField
            variant="outlined"
            label="Minimum number of devices per task"
            type="number"
            fullWidth
            value={devices}
            onChange={(e) => setDevices(Math.max(parseInt(e.target.value), 1))}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    disabled={isUpdatingDevices !== "done"}
                    aria-label="toggle password visibility"
                    onClick={() => handleSaveDevices(devices)}
                  >
                    {(() => {
                      if (isUpdatingDevices !== "success") return <Save />;
                      if (isUpdatingDevices === "success")
                        return <Check color="secondary" />;
                    })()}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </CardContent>
      ) : null}
    </Card>
  );

  const continuousIntegrationCard = (
    <Card style={{ marginBottom: 20 }}>
      <CardContent>
        <Typography>Continuous Integration</Typography>
        <List>
          <ListItem>
            <ToggleGreatness
              label="Process Greatness"
              project={{ id: project.id }}
              value={project.enable_greatness_processing}
              readOnly={!ag && !isManager}
            />
          </ListItem>
          <ListItem>
            <ToggleTagGroundTruth
              label="Process Ground Truth"
              project={{ id: project.id }}
              value={project.enable_ground_truth_processing}
              readOnly={!ag && !isManager}
            />
          </ListItem>
          <ListItem>
            <ToggleArcadia
              label="Process Arcadia"
              project={{ id: project.id }}
              value={project.enable_arcadia_processing}
              readOnly={!ag && !isManager}
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );

  const dependenciesCard = (
    <Card style={{ marginBottom: 20 }}>
      <CardContent>
        <Typography>Dependencies</Typography>
        <List>
          <ListItem>
            <ListItemText
              primary={
                project.simulation_opmode?.opmode_profiles.map(
                  (t) => t.profile.name,
                )[0] ?? "No Profile Defined"
              }
              secondary="Configuration Profile"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={project.deliverable.name}
              secondary="Deliverable"
            />
          </ListItem>
          <ListItem>
            <ListItemText primary={project.model.codename} secondary="Model" />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );

  const recordingStatusCard = (
    <Grid container spacing={2} style={{ marginBottom: 10 }}>
      <BringupProgressBar projectId={project.id} />
      <RecordingsProgressBar projectId={project.id} />
      <Grid md={4} xs={4} item>
        <StatusCard
          title="Clean recordings"
          count={project.clean_dataset_aggregate.aggregate?.count}
          icon={<OpenInNewIcon />}
          color={green[600]}
          href={`https://arcadia.elas.ai/redirect_to_project/?project_id=${project.id}&dataset=Clean Recordings`}
        />
      </Grid>
      <Grid md={4} xs={4} item>
        <StatusCard
          title="Dirty recordings"
          count={project.dirty_dataset_aggregate.aggregate?.count}
          icon={<OpenInNewIcon />}
          color={red[600]}
          href={`https://arcadia.elas.ai/redirect_to_project/?project_id=${project.id}&dataset=Dirty Recordings`}
        />
      </Grid>
      <Grid md={4} xs={4} item>
        <StatusCard
          title="Untagged recordings"
          count={project.untagged_dataset_aggregate.aggregate?.count}
          icon={<OpenInNewIcon />}
          color={grey[600]}
          href={`https://arcadia.elas.ai/redirect_to_project/?project_id=${project.id}&dataset=Needs Cleaning`}
        />
      </Grid>
    </Grid>
  );

  return (
    <Grid container spacing={3}>
      <Grid md={8} xs={12} item>
        {headsUpCard}
        {recordingStatusCard}
        {recordingsBarChartCard}
        {recordingsQualityChartCard}
        {devicesCard}
        {roomCard}
      </Grid>
      <Grid item md={4} xs={12}>
        {downloadButton && (
          <DownloadButton
            customer={project.customer.codename}
            model={project.model.codename}
            variant={project.variant}
            deliverable={project.deliverable.name}
          >
            Download project stats
          </DownloadButton>
        )}
        {slackCard}
        {continuousIntegrationCard}
        {managersCard}
        {dataCollectorsCard}
        {dependenciesCard}
      </Grid>
    </Grid>
  );
}

const RecordingsProgressBar = (props: { projectId: string }) => {
  const query = useProjectDataCollectionProgressQuery({
    variables: { project_id: props.projectId },
  });
  const data = query?.data?.project_task_count_aggregate;
  const clean_count = data?.aggregate?.sum?.clean_count_constrained ?? 0;
  const untagged_count = data?.aggregate?.sum?.untagged_count ?? 0;
  const required_total = data?.aggregate?.sum?.required_total ?? 0;
  const progress = query.loading
    ? "Loading..."
    : `${((clean_count / required_total) * 100).toFixed(2)}%`;

  return (
    <Grid md={12} xs={12} item>
      <FormLabel>
        Data collection progress: <strong>{progress}</strong>
      </FormLabel>
      {query.loading ? (
        <MultiProgressBar denominator={required_total} numerators={[]} />
      ) : (
        <MultiProgressBar
          denominator={required_total}
          numerators={[
            { value: clean_count, label: "Clean", color: "green" },
            { value: untagged_count, label: "Untagged", color: "grey" },
          ]}
        />
      )}
    </Grid>
  );
};

const BringupProgressBar = (props: { projectId: string }) => {
  const query = useProjectChecklistProgressQuery({
    variables: { project_id: props.projectId },
    fetchPolicy: "network-only",
  });
  const completedItemCount =
    query.data?.endorsed_items_aggregate?.aggregate?.count ?? 0;
  const totalItemCount = query.data?.all_items_aggregate?.aggregate?.count ?? 0;
  const percentage = Math.min((completedItemCount / totalItemCount) * 100, 100);
  const progress = query.loading ? "Loading..." : `${percentage.toFixed(2)}%`;

  return (
    <Grid xs={12} item>
      <FormLabel>
        Bringup Progress: <strong>{progress}</strong>
      </FormLabel>
      <MultiProgressBar
        denominator={totalItemCount}
        numerators={[
          { value: completedItemCount, label: "Completed", color: "blue" },
        ]}
      />
    </Grid>
  );
};

type StatusCardProps = {
  title: string;
  count?: number;
  color: string;
  icon: JSX.Element;
  href: string;
};

const StatusCard = (props: StatusCardProps) => {
  const cardClass = useCardStyles(props);
  return (
    <Card className={`${cardClass.card} ${cardClass.button}`}>
      <CardActionArea
        href={props.href}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Grid container>
          <Grid item xs={8}>
            <div
              style={{
                fontWeight: 600,
                fontSize: 30,
              }}
            >
              {props.count}
            </div>
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              display: "flex",
              justifyContent: "right",
            }}
          >
            {props.icon}
          </Grid>
          <Grid item xs={12}>
            {props.title}
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
};

// Create some components for toggling CI fields.
type ToggleProps = {
  label: string;
  value: boolean;
  onChange?: (value: boolean) => void;
};

const Toggle = (props: ToggleProps) => {
  return (
    <FormControlLabel
      control={
        props.onChange ? (
          <Switch
            checked={props.value}
            onChange={props.onChange && ((_, c) => props.onChange!(c))}
          />
        ) : props.value ? (
          <Check />
        ) : (
          <Close />
        )
      }
      label={props.label}
    />
  );
};

type ProjectPropertyToggleProps = Omit<ToggleProps, "onChange"> & {
  project: { id: string };
  readOnly: boolean;
};

/**
 * Need to render totally different elements for users without editing
 * privileges, otherwise, they will get graphql errors.
 */
type ProjectPropertyToggleEditableProps = ToggleProps &
  Pick<ProjectPropertyToggleProps, "project">;

// Toggle switch for "ground truth processing"
const ToggleTagGroundTruthEditable = ({
  project,
  value,
  ...props
}: ProjectPropertyToggleEditableProps) => {
  const [val, setVal] = useState(value);
  const [update] = useSetProjectTagGroundTruthEnabledMutation();
  return (
    <Toggle
      {...props}
      value={val}
      onChange={(v) => {
        setVal(v);
        update({ variables: { project_id: project.id, enabled: v } });
      }}
    />
  );
};
const ToggleTagGroundTruth = ({
  readOnly,
  ...props
}: ProjectPropertyToggleProps) => {
  return readOnly ? (
    <Toggle {...props} />
  ) : (
    <ToggleTagGroundTruthEditable {...props} />
  );
};

// Toggle switch for "greatness processing"
const ToggleGreatnessEditable = ({
  project,
  value,
  ...props
}: ProjectPropertyToggleEditableProps) => {
  const [val, setVal] = useState(value);
  const [update] = useSetProjectGreatnessEnabledMutation();
  return (
    <Toggle
      {...props}
      value={val}
      onChange={(v) => {
        setVal(v);
        update({ variables: { project_id: project.id, enabled: v } });
      }}
    />
  );
};
const ToggleGreatness = ({
  readOnly,
  ...props
}: ProjectPropertyToggleProps) => {
  return readOnly ? (
    <Toggle {...props} />
  ) : (
    <ToggleGreatnessEditable {...props} />
  );
};

// Toggle switch for "enable arcadia processing"
const ToggleArcadiaEditable = ({
  project,
  value,
  ...props
}: ProjectPropertyToggleEditableProps) => {
  const [val, setVal] = useState(value);
  const [update] = useSetProjectArcadiaEnabledMutation();
  const notificationReference = useNotification();
  return (
    <Toggle
      {...props}
      value={val}
      onChange={(v) => {
        setVal(v);
        update({ variables: { project_id: project.id, enabled: v } });
        if (v) {
          notificationReference.create({
            severity: "warning",
            title: "Arcadia enabled, action needed",
            body: "You might need to trigger arcadia backend (Elliptic.ML.ReviewGroundTruth.Feature) to process existing recordings",
            flashOnly: false,
          });
        }
      }}
    />
  );
};
const ToggleArcadia = ({ readOnly, ...props }: ProjectPropertyToggleProps) => {
  return readOnly ? (
    <Toggle {...props} />
  ) : (
    <ToggleArcadiaEditable {...props} />
  );
};

type DrawerButtonProps = {
  label: string;
  content: (close: () => void) => JSX.Element;
};

function DrawerButton(props: DrawerButtonProps) {
  const [show, setShow] = useState(false);

  return (
    <Fragment>
      <Button fullWidth color="primary" onClick={() => setShow(true)}>
        {props.label}
      </Button>
      <Drawer anchor={"right"} open={show} onClose={() => setShow(false)}>
        <div style={{ width: "100vw", maxWidth: "1000px" }}>
          {props.content(() => setShow(false))}
        </div>
      </Drawer>
    </Fragment>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 200,
  },
  cardLayer: {
    margin: 5,
    position: "absolute",
    bottom: 0,
    right: 0,
  },
}));

const useCardStyles = makeStyles<DefaultTheme, StatusCardProps>({
  card: {
    boxSizing: "border-box",
    padding: 10,
    color: "white",
    backgroundColor: (props) => props.color,
  },
  button: {
    cursor: "pointer",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.12)",
    transition: "box-shadow 0.3s ease-in-out",
    margin: "10px",
    "&:hover": {
      boxShadow: "0 4px 10px rgba(0,0,0,0.8)",
    },
  },
});
