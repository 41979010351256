import { useState } from "react";
import {
  Box,
  Card,
  Chip,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { SearchBar } from "src/components/filters";
import { Resource, useResourceNav } from "src/components/Resource";
import {
  createTable,
  EllipsisColumn,
  TableOptionals,
  TextColumn,
} from "src/components/table";
import { usePagination, like, uuidIsValid } from "src/resources/Utils";
import {
  Breadcrumb,
  Breadcrumbs,
  ShowResourceView,
  TableView,
  TableViewHeader,
} from "src/Layout";
import {
  Deliveries_Microsoft_Shipping_Labels as ShippingLabels,
  Deliveries_Microsoft_Shipping_Labels_Bool_Exp as Shipping_Labels_Bool_Exp,
  Order_By,
  useAllMicrosoftShippingLabelsQuery,
  useMicrosoftShippingLabelQuery,
} from "src/generated/asgard/graphql";

// Config table columns from DriverVersion fields
export const MicrosoftShippingLabelTable = createTable<ShippingLabels>()({
  keys: (shipping_label) => shipping_label.id ?? "",
  title: "ShippingLabel",
  headers: {
    id: { display: "ID" },
    microsoft_partner_center_shipping_label_id: {
      display: "Partner Center Shipping Label ID",
    },
    shipping_label_name: { display: "Partner Center Shipping Label Name" },
    target: { display: "Target" },
    created_at: { display: "Created at" },
  },
  columns: (shipping_label) => {
    return {
      id: <EllipsisColumn value={shipping_label.id} />,
      microsoft_partner_center_shipping_label_id: (
        <TextColumn
          value={
            shipping_label.microsoft_partner_center_shipping_label_id ?? ""
          }
        />
      ),
      shipping_label_name: (
        <TextColumn value={shipping_label.shipping_label_name} />
      ),
      target: (
        <TextColumn
          value={
            shipping_label.target?.customer?.codename +
            "-" +
            shipping_label.target?.model?.codename
          }
        />
      ),
      created_at: (
        <TextColumn
          value={
            shipping_label.created_at
              ? new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                }).format(new Date(shipping_label.created_at))
              : "N/A"
          }
        />
      ),
    };
  },
});

// Define a new table component for DriverVersions
type AllMicrosoftShippingLabelsTableProps = TableOptionals<
  typeof MicrosoftShippingLabelTable
> & {
  where?: Shipping_Labels_Bool_Exp[];
};

export const AllMicrosoftShippingLabelsTable = (
  props: AllMicrosoftShippingLabelsTableProps,
) => {
  const [pageVars, pageController] = usePagination();
  const [search, setSearch] = useState("");
  const searchFilters: Shipping_Labels_Bool_Exp[] = [];
  if (uuidIsValid(search)) {
    searchFilters.push({ id: { _eq: search } });
  } else {
    // Add search terms for individual fields.
    const term = like(search);
    searchFilters.push({ shipping_label_name: { _ilike: term } });
  }
  const { data } = useAllMicrosoftShippingLabelsQuery({
    variables: {
      ...pageVars,
      where: { _and: [{ _and: props.where }, { _or: searchFilters }] },
      order_by: [{ created_at: Order_By.Desc }],
    },
    fetchPolicy: "network-only",
  });

  return (
    <MicrosoftShippingLabelTable
      {...props}
      {...pageController}
      total={data?.microsoft_shipping_labels_aggregate?.aggregate?.count}
      data={data?.microsoft_shipping_labels}
      tools={
        <Grid item xs={12}>
          <SearchBar onChange={setSearch} />
        </Grid>
      }
    />
  );
};

// Define content to display in the main MicrosoftShippingLabel resource page
type MicrosoftShippingLabelIndexProps = {
} & TableOptionals<typeof MicrosoftShippingLabelTable>;

export const MicrosoftShippingLabelIndex = (
  props: MicrosoftShippingLabelIndexProps,
) => {
  return (
    <TableView>
      <TableViewHeader
        title={<Typography variant="h5">ShippingLabel</Typography>}
      >
      </TableViewHeader>
      <Card>
        <AllMicrosoftShippingLabelsTable {...props} selectable="none" />
      </Card>
    </TableView>
  );
};

// Create a detailed 'show' page.
type MicrosoftShippingLabelShowProps = {
  id: string;
  onEditAction?: (item: DeepPartial<ShippingLabels>) => void;
};

interface ShippingLabelInfos {
  os?: string[];
  hwids?: string[];
  customer?: string;
  chids?: string[];
}

const MicrosoftShippingLabelShow = (props: MicrosoftShippingLabelShowProps) => {
  const microsoftShippingLabelNav = useMicrosoftShippingLabelNav();

  const microsoftShippingLabelQuery = useMicrosoftShippingLabelQuery({
    variables: { id: props.id },
    fetchPolicy: "network-only",
  });
  const microsoft_shipping_label =
    microsoftShippingLabelQuery.data?.microsoft_shipping_label;
  if (!microsoft_shipping_label) return null;
  const shippingLabelInfos = (microsoft_shipping_label.shipping_label_infos ||
    {}) as ShippingLabelInfos;

  const properties = [
    {
      label: "Partner Center Shipping Label Name",
      value: microsoft_shipping_label.shipping_label_name,
    },
    {
      label: "Partner Center Shipping Label ID",
      value:
        microsoft_shipping_label.microsoft_partner_center_shipping_label_id ??
        "N/A",
    },
    {
      label: "Target",
      value:
        microsoft_shipping_label.target.customer?.codename +
          "-" +
          microsoft_shipping_label.target?.model?.codename ?? "N/A",
    },
    {
      label: "Shipping Label Status",
      value: microsoft_shipping_label.shipping_label_status ?? "N/A",
    },
    {
      label: "Created At",
      value: (
        <TextColumn
          value={
            microsoft_shipping_label.created_at
              ? new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                }).format(new Date(microsoft_shipping_label.created_at))
              : "N/A"
          }
        />
      ),
    },
    {
      label: "Created By",
      value: microsoft_shipping_label.user?.name ?? "N/A",
    },
    {
      label: "Updated At",
      value: (
        <TextColumn
          value={
            microsoft_shipping_label.updated_at
              ? new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                }).format(new Date(microsoft_shipping_label.created_at))
              : "N/A"
          }
        />
      ),
    },
    {
      label: "Updated By",
      value: microsoft_shipping_label.user_update?.name ?? "N/A",
    },
    {
      label: "Review Status",
      value:
        microsoft_shipping_label.microsoft_shipping_label_review?.status ??
        "N/A",
    },
    {
      label: "Reviewed At",
      value: (
        <TextColumn
          value={
            microsoft_shipping_label.microsoft_shipping_label_review?.created_at
              ? new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                }).format(new Date(microsoft_shipping_label.created_at))
              : "N/A"
          }
        />
      ),
    },
    {
      label: "Reviewed By",
      value:
        microsoft_shipping_label.microsoft_shipping_label_review?.user.name ??
        "N/A",
    },
  ];

  return (
    <ShowResourceView
      title={microsoft_shipping_label.shipping_label_name ?? ""}
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb
            label="Shipping labels"
            onClick={() => microsoftShippingLabelNav.list()}
          />
          <Breadcrumb
            label={microsoft_shipping_label.shipping_label_name ?? ""}
          />
        </Breadcrumbs>
      }
    >
      <Paper style={{ padding: "20px", marginBottom: "20px" }}>
        <Typography variant="h5" gutterBottom>
          General Information
        </Typography>
        <Divider />
        <Grid container spacing={3} style={{ marginTop: "10px" }}>
          {properties.map((prop, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Box>
                <Typography variant="subtitle2" color="textSecondary">
                  {prop.label}:
                </Typography>
                <Typography variant="body1">{prop.value}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Paper>

      <Paper style={{ padding: "20px", marginBottom: "20px" }}>
        <Typography variant="h5" gutterBottom>
          Shipping Label Details
        </Typography>
        <Divider />

        {/* Operating Systems */}
        <Box mt={3}>
          <Typography variant="subtitle1" gutterBottom>
            Operating Systems:
          </Typography>
          <Box display="flex" flexWrap="wrap">
            {(shippingLabelInfos.os || []).map((os, index) => (
              <Chip key={index} label={os} variant="outlined" />
            ))}
          </Box>
        </Box>

        {/* Hardware IDs */}
        <Box mt={3}>
          <Typography variant="subtitle1" gutterBottom>
            Hardware IDs:
          </Typography>
          <Box display="flex" flexWrap="wrap">
            {(shippingLabelInfos.hwids || []).map((hwid, index) => (
              <Chip key={index} label={hwid} variant="outlined" />
            ))}
          </Box>
        </Box>

        {/* Customer */}
        <Box mt={3}>
          <Typography variant="subtitle1" gutterBottom>
            Customer:
          </Typography>
          <Box display="flex" flexWrap="wrap">
            <Chip
              label={shippingLabelInfos.customer || "N/A"}
              variant="outlined"
            />
          </Box>
        </Box>

        {/* CHIDs */}
        <Box mt={3}>
          <Typography variant="subtitle1" gutterBottom>
            CHIDs:
          </Typography>
          <Box display="flex" flexWrap="wrap">
            {(shippingLabelInfos.chids || []).map((chid, index) => (
              <Chip key={index} label={chid} variant="outlined" />
            ))}
          </Box>
        </Box>
      </Paper>
    </ShowResourceView>
  );
};

// Finally, combine into full resource UI.
const path = "microsoftshippinglabels";
export const useMicrosoftShippingLabelNav = () => useResourceNav(path);
export const MicrosoftShippingLabelResource = () => (
  <Resource
    path={path}
    list={(nav) => (
      <MicrosoftShippingLabelIndex />
    )}
    show={(nav, id) => <MicrosoftShippingLabelShow id={id ?? ""} />}
  />
);
