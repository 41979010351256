export type TaskItem = {
  id: string;
  number: string;
};
export type TaskSetItem = TaskItem & {
  hypertasks: TaskItem[];
};
export type FieldItem = { id: string; display: string };
export type OptionItem = FieldItem | null;
export type TaskFieldOptionItem = {
  field: FieldItem;
  option: OptionItem;
  tasks: TaskItem[];
  hypertasks: TaskItem[];
};
export type PivotGroup = {
  fieldOptions: {
    field: FieldItem;
    option: OptionItem;
  }[];
  hypertasks: TaskItem[];
  tasks: TaskItem[];
};

/**
 * A utility function used to gather all the hypertasks from a "task set"
 *
 * @param tasks
 * @returns
 */
function taskSetToHypertaskSet(tasks: TaskSetItem[]): TaskItem[] {
  return tasks.reduce((acc, task) => {
    const existing = acc.map(({ id }) => id);
    return [...acc, ...task.hypertasks.filter((h) => !existing.includes(h.id))];
  }, [] as TaskItem[]);
}

/**
 * Use to generate a set of "pivot groups" from a set of "pivot fields".
 *
 * A "pivot group" consists of:
 * - a unique set of field-options (this defines the group)
 * - a set of tasks that contain the exact set of field options
 * - a set of hypertasks that contain the exact set of field options
 *
 * As such, requires a mapping between field-options and tasks/hypertasks.
 *
 * If no tasks match the unique set of field-options that define the group,
 * then it will not be returned.
 *
 * @param pivotFields
 * @param taskFieldOptions
 * @param includeMissingFields
 * @param taskSet
 * @returns
 */
export function makePivotGroups(
  pivotFields: FieldItem[],
  taskFieldOptions: TaskFieldOptionItem[],
  includeMissingFields: boolean = false,
  taskSet?: TaskSetItem[],
): PivotGroup[] {
  const groups: PivotGroup[] = [];
  pivotFields?.forEach((pf) => {
    const matchingFieldOptions = taskFieldOptions.filter(
      ({ field }) => field.id === pf.id,
    );
    // Keep track of which tasks contain this field at all.
    const tasksWithField: string[] = matchingFieldOptions.reduce(
      (acc, fo) => [...acc, ...fo.tasks.map(({ id }) => id)],
      [] as string[],
    );
    const fieldOptionGroups: PivotGroup[] = matchingFieldOptions.map((fo) => ({
      fieldOptions: [{ field: fo.field, option: fo.option }],
      tasks: fo.tasks,
      hypertasks: fo.hypertasks,
    }));
    if (includeMissingFields && taskSet) {
      const tasksWithoutField = taskSet.filter(
        ({ id }) => !tasksWithField.includes(id),
      );
      fieldOptionGroups.push({
        fieldOptions: [
          {
            field: { id: pf.id ?? "", display: pf.display ?? "" },
            option: {
              id: "undefined", // Dummy id is OK.
              display: "Undefined",
            },
          },
        ],
        tasks: tasksWithoutField,
        hypertasks: taskSetToHypertaskSet(tasksWithoutField),
      });
    }
    if (groups.length === 0) {
      groups.push(...fieldOptionGroups);
    } else {
      [...groups].forEach((existingGroup, i) => {
        const newGroups = fieldOptionGroups.map((newGroup) => ({
          fieldOptions: [
            ...existingGroup.fieldOptions,
            ...newGroup.fieldOptions,
          ],
          tasks: existingGroup.tasks.filter(
            ({ id }) => newGroup.tasks.findIndex((t) => t.id === id) !== -1,
          ),
          hypertasks: existingGroup.hypertasks.filter(
            ({ id }) =>
              newGroup.hypertasks.findIndex((t) => t.id === id) !== -1,
          ),
        }));
        const spliceIndex = i * fieldOptionGroups.length;
        groups.splice(spliceIndex, 1, ...newGroups);
      });
    }
  });
  return groups.filter((group) => group.tasks.length > 0);
}
