import React, { createContext, useState, useContext } from "react";
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  useInsertChecklistItemCommentMutation,
  useInsertChecklistItemEndorsementMutation,
  Bringup_Items,
  Bringup_Endorsements,
  useProjectChecklistItemCommentsSubscription,
  useDeleteChecklistItemUserEndorsementsMutation,
  useDeleteChecklistItemEndorsementsMutation,
  Roles_Enum,
  useDeleteChecklistItemCommentMutation,
  useUpdateChecklistItemCommentMutation,
} from "src/generated/asgard/graphql";
import {
  Comment as CommentIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  ExpandLess,
  ExpandMore,
} from "@material-ui/icons";
import { ClosableDrawer } from "src/Layout";
import { useAuth, useRequireRole } from "src/auth";
import Markdown from "react-markdown";
import { MarkdownEditor } from "src/components/MarkdownEditor";
import { useNotification } from "src/Notification";
import { UserAvatar } from "src/resources/User";

const ChecklistTableContext = createContext<{ projectId: string | null }>({
  projectId: null,
});

function matchUser(a: string, b: string): boolean {
  return a.trim().toLowerCase() === b.trim().toLowerCase();
}

type BringupChecklistTableProps = {
  projectId?: string;
  children: JSX.Element[];
};

export const BringupChecklistTable = (props: BringupChecklistTableProps) => {
  if (props.projectId?.length) {
    return (
      <ChecklistTableContext.Provider value={{ projectId: props.projectId }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {/* Empty column for collapse/expand button */}
                <TableCell />
                <TableCell>Name</TableCell>
                <TableCell>Objective</TableCell>
                <TableCell>Personnel</TableCell>
                <TableCell>Endorsements</TableCell>
                <TableCell>Comments</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{props.children}</TableBody>
          </Table>
        </TableContainer>
      </ChecklistTableContext.Provider>
    );
  } else {
    return (
      <ChecklistTableContext.Provider value={{ projectId: null }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {/* Empty column for collapse/expand button */}
                <TableCell />
                <TableCell>Name</TableCell>
                <TableCell>Objective</TableCell>
                <TableCell>Personnel</TableCell>
                <TableCell>Depends On</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{props.children}</TableBody>
          </Table>
        </TableContainer>
      </ChecklistTableContext.Provider>
    );
  }
};

type CommentProps = {
  content: string;
  user: string;
  date: string;
  update: (content: string) => void;
  delete: () => void;
  disableEdit: boolean;
  disableDelete: boolean;
  avatar: React.ReactNode;
  editedAt: string | null;
};

const Comment = (props: CommentProps) => {
  const [editing, setEditing] = useState(false);
  const hasActions = !props.disableDelete || !props.disableDelete;
  return (
    <>
      <Card elevation={4} style={{ padding: 10 }}>
        <CardHeader
          avatar={props.avatar}
          title={props.user}
          subheader={props.date}
          action={
            hasActions ? (
              <ButtonGroup>
                {!editing && !props.disableEdit && (
                  <IconButton onClick={() => setEditing(true)}>
                    <EditIcon />
                  </IconButton>
                )}
                {!props.disableDelete && (
                  <IconButton onClick={() => props.delete()}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </ButtonGroup>
            ) : undefined
          }
        />
        <CardContent>
          {editing ? (
            <MarkdownEditor
              data={props.content}
              onSave={(md) => {
                props.update(md);
                setEditing(false);
              }}
              onClose={() => setEditing(false)}
            />
          ) : (
            <Markdown>{props.content}</Markdown>
          )}
          {props.editedAt !== null && (
            <Typography variant="caption">{`edited @ ${props.editedAt}`}</Typography>
          )}
        </CardContent>
      </Card>
    </>
  );
};

/** */
type CommentsCellProps = {
  itemId: string;
  projectId: string;
};

const CommentsCell = (props: CommentsCellProps) => {
  const auth = useAuth();
  const { data } = useProjectChecklistItemCommentsSubscription({
    variables: { item_id: props.itemId, project_id: props.projectId },
    fetchPolicy: "network-only",
  });
  const count = data?.comments?.length ?? 0;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [insertComment] = useInsertChecklistItemCommentMutation();
  const [deleteComment] = useDeleteChecklistItemCommentMutation();
  const [updateComment] = useUpdateChecklistItemCommentMutation();
  return (
    <>
      <TableCell>
        <Badge
          badgeContent={
            count > 0 ? <Chip color="primary" label={count} /> : undefined
          }
        >
          <IconButton color="primary" onClick={() => setDrawerOpen(true)}>
            <CommentIcon />
          </IconButton>
        </Badge>
      </TableCell>
      <ClosableDrawer show={drawerOpen} setShow={setDrawerOpen}>
        <Grid container spacing={2} style={{ width: 600 }}>
          {data?.comments?.map((comment, i) => {
            const createdAt = comment.created_at
              ? comment.created_at.split(".")[0]
              : "unknown";
            const isAuthor = matchUser(auth.user.id, comment.created_by);
            const isEdited =
              !!comment.updated_at &&
              !!comment.created_at &&
              comment.updated_at !== comment.created_at;
            const editedAt = isEdited
              ? comment.updated_at!.split(".")[0]
              : null;
            return (
              <Grid item xs={12} key={comment.created_at ?? i}>
                <Comment
                  content={comment.content}
                  user={comment.user.name}
                  avatar={<UserAvatar user={comment.user} />}
                  date={createdAt}
                  update={(content) => {
                    updateComment({
                      variables: {
                        project_id: props.projectId,
                        item_id: props.itemId,
                        created_at: comment.created_at,
                        content: content,
                      },
                    });
                  }}
                  delete={() => {
                    deleteComment({
                      variables: {
                        project_id: props.projectId,
                        item_id: props.itemId,
                        created_at: comment.created_at,
                        deleted_at: new Date().toISOString(),
                      },
                    });
                  }}
                  editedAt={editedAt}
                  disableEdit={!isAuthor}
                  disableDelete={!isAuthor}
                />
              </Grid>
            );
          })}
          <Grid item xs={12}>
            {editing ? (
              <MarkdownEditor
                data={""}
                onSave={(md) => {
                  insertComment({
                    variables: {
                      obj: {
                        item_id: props.itemId,
                        project_id: props.projectId,
                        created_by: auth.user.id,
                        content: md,
                      },
                    },
                  });
                  setEditing(false);
                }}
                onClose={() => setEditing(false)}
              />
            ) : (
              <Button
                fullWidth
                color="primary"
                variant="contained"
                startIcon={<CommentIcon />}
                onClick={() => setEditing(true)}
              >
                Add Comment
              </Button>
            )}
          </Grid>
        </Grid>
      </ClosableDrawer>
    </>
  );
};

/** */
type EndorsementsCellProps = {
  itemId: string;
  projectId: string;
  endorsements: DeepPartial<Bringup_Endorsements>[];
  dependsOn: DeepPartial<Bringup_Items> | null;
  dependents: DeepPartial<Bringup_Items>[];
  onChange: () => void;
};

const EndorsementsCell = (props: EndorsementsCellProps) => {
  const auth = useAuth();
  const ag = useRequireRole([Roles_Enum.Asgard]);
  const [addEndorsement] = useInsertChecklistItemEndorsementMutation();
  const [clearUserEndorsements] =
    useDeleteChecklistItemUserEndorsementsMutation();
  const [clearAllEndorsements] = useDeleteChecklistItemEndorsementsMutation();

  const notification = useNotification();

  // Render different contents depending on whether or not this item is
  // endorsed, and whether or not its dependencies are endorsed.
  const hasEndorsement = props.endorsements.length > 0;
  const hasDependency = props.dependsOn !== null;
  const dependencyMet =
    (props.dependsOn?.endorsements_aggregate?.aggregate?.count ?? 0) > 0;
  const disableMarkComplete =
    !hasEndorsement && hasDependency && !dependencyMet;
  const dependencyHint = `depends on "${props.dependsOn?.name}" in ${
    props.dependsOn?.checklist?.name ?? "unknown"
  }`;

  const completedDependents = props.dependents.filter(
    (dep) => (dep.endorsements_aggregate?.aggregate?.count ?? 0) > 0,
  );
  const userEndorsements = props.endorsements.filter((e) =>
    matchUser(e?.user?.id ?? "", auth.user.id),
  );
  // Asgard members can clear anyone's endorsement.
  // Midgard users can only clear their own.
  const handleClearEndorsements = ag
    ? () => {
        clearAllEndorsements({
          variables: {
            item_id: props.itemId,
            project_id: props.projectId,
          },
        }).finally(props.onChange);
      }
    : userEndorsements.length > 0
    ? () => {
        clearUserEndorsements({
          variables: {
            item_id: props.itemId,
            project_id: props.projectId,
            user_id: auth.user.id,
          },
        }).finally(props.onChange);
      }
    : undefined;

  const markCompleteChip = (
    <Chip
      style={{ margin: 2 }}
      disabled={disableMarkComplete}
      color="primary"
      label="mark complete"
      onClick={() => {
        addEndorsement({
          variables: {
            user_id: auth.user.id,
            item_id: props.itemId,
            project_id: props.projectId,
          },
        })
          .catch((e) =>
            notification.create({
              severity: "warning",
              flashOnly: true,
              title: `${e}`,
            }),
          )
          .finally(props.onChange);
      }}
    />
  );

  return (
    <TableCell>
      {hasEndorsement ? (
        props.endorsements.map(({ created_at, user }) => {
          const date = created_at ? created_at.split("T")[0] : "N/A";
          return (
            <Chip
              key={created_at}
              style={{ margin: 2 }}
              label={`${user?.name} @ ${date}`}
              onDelete={
                completedDependents.length === 0
                  ? handleClearEndorsements
                  : undefined
              }
            />
          );
        })
      ) : disableMarkComplete ? (
        <Tooltip title={dependencyHint}>
          <span>{markCompleteChip}</span>
        </Tooltip>
      ) : (
        markCompleteChip
      )}
    </TableCell>
  );
};

type BringupChecklistRowProps = {
  item: DeepPartial<Bringup_Items>;
  onChange: () => void;
};

export const BringupChecklistRow = (props: BringupChecklistRowProps) => {
  const { projectId } = useContext(ChecklistTableContext);
  const { item } = props;
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <TableRow hover>
        <TableCell>
          <IconButton onClick={() => setExpanded((e) => !e)}>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </TableCell>
        <TableCell>{item.name}</TableCell>
        <TableCell>
          <Markdown>{item.objective ?? ""}</Markdown>
        </TableCell>
        <TableCell>
          <Markdown>{item.personnel ?? ""}</Markdown>
        </TableCell>
        {projectId !== null ? (
          <>
            <EndorsementsCell
              itemId={item.id ?? ""}
              projectId={projectId}
              endorsements={item.endorsements ?? []}
              dependsOn={item.depends_on_item ?? null}
              dependents={item.dependent_items ?? []}
              onChange={props.onChange}
            />
            <CommentsCell itemId={item.id ?? ""} projectId={projectId} />
          </>
        ) : (
          <TableCell>
            {!!item.depends_on_item ? `"${item.depends_on_item.name}"` : ""}
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Markdown>{item.description ?? ""}</Markdown>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {item.milestone && (
        <TableRow style={{ backgroundColor: "#EEEEEE" }}>
          <TableCell colSpan={8}>
            <Typography variant="body1">{item.milestone.name}</Typography>
            {!!item.milestone.description && (
              <Typography variant="caption">
                {item.milestone.description}
              </Typography>
            )}
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
