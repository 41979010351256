import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  InputAdornment,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  AddCircleOutline,
  Close,
  Info,
  ListAltOutlined,
  TranslateOutlined,
} from "@material-ui/icons";
import { FormikProvider, useFormik, FieldArray, FormikErrors } from "formik";
import { useLocation } from "react-router-dom";
import qs from "query-string";
import * as Yup from "yup";
import {
  ActionInfo,
  FormContainer,
  FormHeader,
  FormAction,
  TextField,
  DialogSelect,
  pathProxy,
  FormikSubmitFailureEffect,
  DrawerButton,
} from "src/components/Form";
import { Resource, useResourceNav } from "src/components/Resource";
import {
  createTable,
  EllipsisColumn,
  TableOptionals,
  TextColumn,
} from "src/components/table";
import {
  usePagination,
  like,
  orderBy,
  uuidIsValid,
  md,
} from "src/resources/Utils";
import {
  Breadcrumbs,
  Breadcrumb,
  ShowResourceView,
  Tab,
  Tabs,
  TabContent,
  TabLabel,
  TabView,
  TableView,
  TableViewHeader,
} from "src/Layout";
import {
  AllCategoriesQuery,
  AllContextsQuery,
  Contexts_Bool_Exp,
  Fields as Field,
  Fields_Order_By,
  Fields_Bool_Exp,
  Order_By,
  useAllCategoriesQuery,
  useAllContextsQuery,
  useAllFieldsQuery,
  useContextQuery,
  useCreateFieldMutation,
  useFieldQuery,
  useFieldTranslationsSubscription,
  useInsertFieldOptionsMutation,
  useUpdateFieldMutation,
  useUpsertFieldTranslationMutation,
} from "src/generated/asgard/graphql";
import { SearchBar } from "src/components/filters";
import { MultiSelectFilter } from "src/components/filters/SearchBar";
import { useNotification } from "src/Notification";
import { optionDescription, AllOptionsTable, useOptionNav } from "./option";
import { AllTypesTable } from "./type";
import { useContextNav } from "./context";
import { useCategoryNav } from "./category";
import { useTaskNav, AllTasksTable, FieldOptionsTable } from "./task";
import { TranslationTable } from "src/resources/Translation";

// Create a detailed description of the Field resource
export const fieldDescription = (
  <Typography variant="body2">
    <b>Fields</b> are used to identify all the individual details that describe
    a <b>task</b>.
    <br />
    <br />
    <b>Fields</b> are like variables. They have a <b>type</b>, and may be{" "}
    assigned a value, called an <b>option</b>. When a <b>field</b> is used in a{" "}
    <b>task</b>, it <i>must</i> be assigned an <b>option</b>. The resulting{" "}
    <b>field-option</b> pair represents a single detail that is used to describe
    the <b>task</b>.
    <br />
    <br />
    Each <b>field</b> must belong to a <b>context</b>, within a <b>category</b>.
    Two separate <b>fields</b> in the same <b>category</b> may describe a
    similar detail, however, these <b>fields</b> must belong to different{" "}
    <b>contexts</b>, have unique text, and their own set of possible{" "}
    <b>options</b>.
  </Typography>
);

export const FieldTable = createTable<Field>()({
  keys: (field) => field.id ?? "",
  title: "Field",
  headers: {
    id: { display: "ID" },
    name: { display: "Name" },
    description: { display: "Description" },
    display: { display: "Display" },
    context: { display: "Context" },
  },
  columns: (field) => ({
    id: <EllipsisColumn value={field.id} />,
    display: <TextColumn value={field.display} />,
    name: <EllipsisColumn value={field.name} />,
    description: field.description?.length ? (
      <EllipsisColumn value={field.description ?? ""} />
    ) : (
      <></>
    ),
    context: (
      <TextColumn
        value={`${field.context?.category?.name}: ${field?.context?.name}`}
      />
    ),
  }),
});

export type FieldSearchToolsProps = {
  onChange: (filters: Fields_Bool_Exp[]) => void;
};

export const FieldSearchTools = ({ onChange }: FieldSearchToolsProps) => {
  // Search bar can add filter terms for name or display.
  const [search, setSearch] = useState("");
  const searchFilter: Fields_Bool_Exp[] = useMemo(() => {
    if (uuidIsValid(search)) {
      return [{ id: { _eq: search } }];
    } else {
      const term = like(search);
      return [{ name: { _ilike: term } }, { display: { _ilike: term } }];
    }
  }, [search]);

  // Can also filter on category/context.
  const [categoryFilter, setCategoryFilter] = useState<
    AllCategoriesQuery["categories"] | undefined
  >(undefined);
  const [contextFilter, setContextFilter] = useState<
    AllContextsQuery["contexts"] | undefined
  >(undefined);

  const categories = useAllCategoriesQuery({});

  const allContextsFilter: Contexts_Bool_Exp[] = useMemo(() => {
    return categoryFilter?.length
      ? [{ category: { id: { _in: categoryFilter.map((cat) => cat.id) } } }]
      : [];
  }, [categoryFilter]);

  const contexts = useAllContextsQuery({
    variables: {
      where: { _and: allContextsFilter },
      order_by: [{ category: { name: Order_By.Asc } }],
    },
  });

  const categoryContextFilters: Fields_Bool_Exp[] = useMemo(() => {
    const filters: Fields_Bool_Exp[] = [];
    if (categoryFilter?.length) {
      filters.push({
        context: {
          category: { id: { _in: categoryFilter.map((cat) => cat.id) } },
        },
      });
    }
    if (contextFilter?.length) {
      filters.push({
        context: { id: { _in: contextFilter.map((ctx) => ctx.id) } },
      });
    }
    return filters;
  }, [categoryFilter, contextFilter]);

  // Fire the callback whenever a search term changes.
  useEffect(() => {
    onChange([{ _or: searchFilter }, ...categoryContextFilters]);
  }, [onChange, searchFilter, categoryContextFilters]);

  return (
    <>
      <Grid item xs={12}>
        <SearchBar onChange={setSearch} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MultiSelectFilter
          label="Categories"
          onChange={(v) => setCategoryFilter(v)}
          placeholder="Category"
          options={categories?.data?.categories}
          getOptionLabel={(o) => o.name}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MultiSelectFilter
          label="Contexts"
          placeholder="Context"
          groupBy={(v) => v.category.name}
          onChange={(v) => setContextFilter(v)}
          options={contexts?.data?.contexts}
          getOptionLabel={(o) => `${o.name}`}
        />
      </Grid>
    </>
  );
};

type AllFieldsTableProps = TableOptionals<typeof FieldTable> & {
  where?: Fields_Bool_Exp[];
};

export const AllFieldsTable = (props: AllFieldsTableProps) => {
  const [pageVars, pageController] = usePagination();
  const [order, setOrder] = useState<Fields_Order_By>({
    updated_at: Order_By.Desc,
  });
  const [filters, setFilters] = useState<Fields_Bool_Exp[]>([]);
  const allFilters = props.where ? [...filters, ...props.where] : filters;

  const { data } = useAllFieldsQuery({
    variables: {
      ...pageVars,
      order_by: [order],
      where: { _and: allFilters },
    },
  });

  return (
    <FieldTable
      {...props}
      {...pageController}
      total={data?.fields_aggregate?.aggregate?.count}
      data={data?.fields}
      tools={<FieldSearchTools onChange={setFilters} />}
      orderColumn={(order) => {
        if (!order) {
          setOrder({ updated_at: Order_By.Desc });
          return;
        }
        setOrder({
          id: orderBy(order.id),
          name: orderBy(order.name),
          display: orderBy(order.display),
        });
      }}
    />
  );
};

type FieldIndexProps = {
  // onAddNew: () => void;
} & TableOptionals<typeof FieldTable>;

export const FieldIndex = (props: FieldIndexProps) => {
  return (
    <TableView>
      <TableViewHeader
        title={<Typography variant="h5">Fields</Typography>}
        info={fieldDescription}
      ></TableViewHeader>
      <Card>
        <AllFieldsTable {...props} selectable="none" />
      </Card>
    </TableView>
  );
};

const fieldSchema = Yup.object({
  // For as long as we're still using MongoDB, the field `name` must correspond
  // to fields in MongoDB documents. So, ensure that they are properly
  // formatted, and are prefixed with the appropriate parent objects.
  name: Yup.string()
    .required()
    .matches(
      /^(metadata.truth.gesture|metadata.environment|metadata.device|metadata.user.body)./,
      {
        message: `
          Field names must use prefixes 'metadata.truth.gesture', 
          'metadata.environment', 'metadata.user.body', or 'metadata.device'.
        `,
      },
    )
    .matches(/^([^.]+)([.][^.]+)+$/, {
      message: `Individual keys (separated by dots) cannot be empty`,
    })
    .matches(/^([a-z_]+)([.][a-z_]+)+$/, {
      message: `
          Individual keys (separated by dots) must contain only lowercase letters and underscores.
        `,
    }),
  display: Yup.string().required(),
  description: Yup.string(),
  type: Yup.object({
    id: Yup.string().required(),
    name: Yup.string().required(),
  }).required(),
  context: Yup.object({
    id: Yup.string().required(),
    name: Yup.string(),
  }).required(),
  options: Yup.array(
    Yup.object({
      key: Yup.number().required(),
      new: Yup.boolean().required(),
      removable: Yup.boolean().required(),
      option: Yup.object({
        id: Yup.string().required(),
        display: Yup.string().required(),
      }).nullable(),
    }).required(),
  ),
}).required();

type FieldFormSchema = Yup.InferType<typeof fieldSchema>;

type FieldFormAction = "insert" | "update";

type FieldFormInfo = {
  title: string;
  submitButtonTitle: string;
  notification: {
    success: (id: string) => {
      title: string;
      description: string;
    };
    error: (id: string) => {
      title: string;
      description: string;
    };
  };
};

export type FieldFormProps = {
  field?: { id: string };
  context?: { id: string };
  action: FieldFormAction;
  onSuccess?: (id: string) => void;
};

export const FieldForm = (props: FieldFormProps) => {
  const formInfo: ActionInfo<FieldFormAction, FieldFormInfo> = {
    insert: {
      title: "Create new Field",
      submitButtonTitle: "Create Field",
      notification: {
        success: (id) => ({
          title: "",
          description: "",
        }),
        error: (id) => ({
          title: "",
          description: "",
        }),
      },
    },
    update: {
      title: "Update Field",
      submitButtonTitle: "Update Field",
      notification: {
        success: (id) => ({
          title: "",
          description: "",
        }),
        error: (id) => ({
          title: "",
          description: "",
        }),
      },
    },
  };

  const info = formInfo[props.action];

  const notification = useNotification();

  const [createField] = useCreateFieldMutation();
  const [updateField] = useUpdateFieldMutation();
  const [insertFieldOptions] = useInsertFieldOptionsMutation();

  // Create form-level validator for uniqueness constraints.
  const existingFields = useAllFieldsQuery({ fetchPolicy: "network-only" });
  const validateField = (values: FieldFormSchema) => {
    const errors: FormikErrors<FieldFormSchema> = {};
    if (existingFields.data?.fields?.length) {
      for (let field of existingFields.data?.fields) {
        // Two fields in the same context may not have the same name.
        if (
          field.name === values.name &&
          field.context.id === values.context.id &&
          field.id !== props.field?.id
        ) {
          errors.name = `Field '${values.name}' already exists for context '${values.context.name}'`;
          break;
        }
        // Two fields in the same context may not have the same display.
        if (
          field.display === values.display &&
          field.context.id === values.context.id &&
          field.id !== props.field?.id
        ) {
          errors.display = `Field '${values.display} already exists for context '${values.context.name}'`;
          break;
        }
        // Two fields with the same name should not have different types.
        if (
          field.name === values.name &&
          field.type.name !== values.type.name
        ) {
          errors.name = `Field '${values.name}' already exists with a different type ('${field.type.name}')`;
          break;
        }
        // Field cannot be a sub-field of an existing field.
        if (values.name.startsWith(field.name.concat("."))) {
          errors.name = `Field '${field.name}' already exists, and cannot have sub fields`;
          break;
        }
        // Field cannot be created if sub-fields already exist.1
        if (field.name.startsWith(values.name.concat("."))) {
          errors.name = `Field '${values.name}' has subfields already (e.g. '${field.name}')`;
          break;
        }
      }
    }
    existingFields.data?.fields.forEach((field) => {});
    return errors;
  };

  const formik = useFormik<FieldFormSchema>({
    validationSchema: fieldSchema,
    validate: validateField,
    initialValues: {
      name: "",
      display: "",
      description: "",
      type: {
        id: "",
        name: "",
      },
      context: {
        id: "",
        name: "",
      },
      options: [],
    },
    onSubmit: async (values) => {
      try {
        const { type, context, options, ...vals } = values;
        await (async () => {
          if (
            props.action === "update" &&
            props.field?.id &&
            type?.id &&
            options?.length
          ) {
            const insertFieldOptionInput = options
              .filter((o) => o.new && o.option?.id.length)
              .map((o) => ({
                field_id: props.field?.id,
                type_id: type?.id,
                option_id: o.option?.id,
              }));
            return await insertFieldOptions({
              variables: {
                input: insertFieldOptionInput,
              },
            });
          }
        })();
        const result = await (async () => {
          if (props.action === "update" && props.field?.id) {
            return await updateField({
              variables: {
                id: props.field.id,
                input: {
                  ...vals,
                },
              },
            });
          } else {
            return await createField({
              variables: {
                input: {
                  ...vals,
                  context_id: context.id,
                  type_id: type.id,
                },
              },
            });
          }
        })();
        props.onSuccess && props.onSuccess(result.data?.field?.id ?? "");
      } catch (e) {
        formik.setSubmitting(false);
      }
    },
  });

  const { setValues, setFieldValue, setSubmitting } = formik;

  // If updating an existing field, prevent submission until loaded.
  const fieldQuery = useFieldQuery({
    variables: { id: props.field?.id ?? "" },
    skip: !props.field,
  });
  const field = fieldQuery.data?.field;
  useEffect(() => {
    if (props.action === "update") {
      if (!field) setSubmitting(true);
      else {
        setValues({
          name: field.name ?? "",
          display: field.display ?? "",
          description: field.description ?? "",
          type: {
            id: field.type?.id ?? "",
            name: field.type?.name ?? "",
          },
          context: {
            id: field.context?.id ?? "",
            name: field.context?.name ?? "",
          },
          options:
            field.field_options?.map((fo, index) => ({
              key: index,
              new: false,
              // Cannot remove options already attached to field.
              removable: false,
              option: {
                id: fo.option?.id || "",
                display: fo.option?.display || "",
              },
            })) ?? [],
        });
        setSubmitting(false);
      }
    }
  }, [props.action, field, setSubmitting, setValues]);

  // If inserting to an existing context, prevent submission until loaded.
  const loc = useLocation();
  const query = qs.parse(loc.search);
  const contextQuery = useContextQuery({
    variables: { id: (query.context as string) ?? "" },
    skip: !query.context,
  });
  const context = contextQuery.data?.context;
  useEffect(() => {
    if (props.action === "insert") {
      if (!context) setSubmitting(true);
      else {
        setFieldValue("context", {
          id: context.id ?? "",
          name: context.name ?? "",
        });
        setSubmitting(false);
      }
    }
  }, [props.action, context, setSubmitting, setFieldValue]);

  const path = pathProxy<typeof formik.values>();

  return (
    <FormikProvider value={formik}>
      <FormHeader>
        {info.title}
        {!!context &&
          formik.values.context.name &&
          ` (for "${formik.values.context.name}" context)`}
      </FormHeader>
      <TabView
        useUrlParams={false}
        renderTabs={(tabProps) => (
          <Tabs {...tabProps}>
            <Tab label={<TabLabel label="General" icon={<Info />} />} />
            {/**
             * Only show option selector for existing fields.
             * Type should be "set in stone" when created,
             * to avoid attaching options with wrong type.
             */}
            <Tab
              disabled={props.action !== "update" || !props.field}
              label={<TabLabel label="Options" icon={<ListAltOutlined />} />}
            />
          </Tabs>
        )}
        renderContent={(current) => (
          <>
            <TabContent index={0} current={current}>
              <FormContainer>
                {/**
                 * Only enable type selector for brand new fields.
                 * Changing the type of an existing field will result in errors
                 * if it already has options attached to it.
                 */}
                <DialogSelect
                  bp={{ xs: 12 }}
                  name={path.type.name._}
                  disabled={props.action === "update"}
                  label="Type"
                  onReset={() =>
                    formik.setValues({
                      ...formik.values,
                      type: formik.initialValues.type,
                    })
                  }
                  content={(close) => (
                    <AllTypesTable
                      selectable="single"
                      onSelect={(type) => {
                        formik.setValues({
                          ...formik.values,
                          type: {
                            id: type.id ?? "",
                            name: type.name ?? "",
                          },
                        });
                        close();
                      }}
                    />
                  )}
                />
                <TextField
                  bp={{ xs: 12, sm: 6 }}
                  name={path.name._}
                  label="Name"
                />
                <TextField
                  bp={{ xs: 12, sm: 6 }}
                  name={path.display._}
                  label="Display"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="The display should be a short and human-readable.">
                          <Info color="disabled" />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  bp={{ xs: 12 }}
                  name={path.description?._ ?? "description"}
                  label="Description"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="A long-form description is recommended for details as to the fields meaning.">
                          <Info color="disabled" />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormContainer>
            </TabContent>
            <TabContent index={1} current={current}>
              <FormContainer>
                <FieldArray
                  name={path.options!._}
                  render={(helper) => (
                    <>
                      <Grid container item xs={12} spacing={2}>
                        <DrawerButton
                          bp={{ xs: 4 }}
                          variant="contained"
                          color="primary"
                          label="Add Option"
                          startIcon={<AddCircleOutline />}
                          disableElevation
                          fullWidth={true}
                          content={(close) => (
                            <AllOptionsTable
                              where={[
                                { type_id: { _eq: field?.type_id ?? "" } },
                                {
                                  id: {
                                    _nin:
                                      formik.values.options
                                        ?.filter((fo) => fo.option?.id)
                                        ?.map((fo) => fo.option!.id) ?? [],
                                  },
                                },
                              ]}
                              selectable="single"
                              onSelect={(option) => {
                                helper.insert(0, {
                                  key: Date.now(),
                                  new: true,
                                  removable: true,
                                  option: {
                                    id: option.id ?? "",
                                    display: option.display ?? "",
                                  },
                                });
                                close();
                              }}
                            />
                          )}
                        />
                      </Grid>
                      {formik.values.options!.map((option, index) => (
                        <Grid
                          container
                          item
                          xs={12}
                          spacing={2}
                          key={option.key}
                        >
                          <Grid container item xs={11} spacing={4}>
                            <Grid item xs={6}>
                              <Typography variant="caption">ID</Typography>
                              <Typography variant="body1">
                                {option.option?.id}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="caption">Display</Typography>
                              <Typography variant="body1">
                                {option.option?.display}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item xs={1}>
                            {option.removable && (
                              <Button
                                size="small"
                                color="primary"
                                variant="text"
                                onClick={() => helper.remove(index)}
                              >
                                <Close />
                              </Button>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        </Grid>
                      ))}
                    </>
                  )}
                />
              </FormContainer>
            </TabContent>
          </>
        )}
      />
      <FormContainer>
        <FormAction>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            disabled={formik.isSubmitting}
            onClick={() => formik.handleSubmit()}
          >
            {info.submitButtonTitle}
          </Button>
          <FormikSubmitFailureEffect
            onSubmitFailure={(errors) =>
              notification.create({
                severity: "error",
                title: `Field Form Error`,
                body: md()
                  .syntax("json", JSON.stringify(errors, null, 2))
                  .get(),
              })
            }
          />
        </FormAction>
      </FormContainer>
    </FormikProvider>
  );
};

// Translations of field displays and descriptions.
type FieldTranslationTableProps = {
  field: { id: string };
};

const FieldTranslationTable = (props: FieldTranslationTableProps) => {
  const fieldTranslations = useFieldTranslationsSubscription({
    variables: { id: props.field.id },
  });
  const [upsertTranslation] = useUpsertFieldTranslationMutation({});
  return (
    <TranslationTable
      data={fieldTranslations.data?.translations ?? []}
      schema={Yup.object({
        display: Yup.string().required(),
        description: Yup.string().nullable(),
      }).required()}
      getValues={(data) => ({
        display: data.display ?? "",
        description: data.description ?? "",
      })}
      initialValues={{ display: "", description: "" }}
      getLanguage={(data) => data.language}
      labels={{ display: "Display", description: "Description" }}
      columns={(data) => ({
        display: data.display ?? "",
        description: data.description ?? "",
      })}
      onUpdate={(data, values) =>
        upsertTranslation({
          variables: {
            object: {
              field_id: props.field.id,
              language: data.language.id,
              description: values.description,
              display: values.display,
            },
          },
        })
      }
      onCreate={(language, values) =>
        upsertTranslation({
          variables: {
            object: {
              field_id: props.field.id,
              language: language,
              ...values,
            },
          },
        })
      }
    />
  );
};

// Detailed content for a single field "show" page.
type FieldShowProps = {
  id: string;
  onEditAction?: (item: DeepPartial<Field>) => void;
};

const FieldShow = (props: FieldShowProps) => {
  const contextNav = useContextNav();
  const categoryNav = useCategoryNav();
  const taskNav = useTaskNav();
  const optionNav = useOptionNav();

  const fieldQuery = useFieldQuery({
    variables: { id: props.id },
    fetchPolicy: "network-only",
  });
  const field = fieldQuery.data?.field;
  if (!field) return null;

  return (
    <ShowResourceView
      title={field.display ?? ""}
      properties={[
        { label: "Name", value: field.name ?? "" },
        { label: "Type", value: field.type?.name ?? "" },
        { label: "Display", value: field.display ?? "" },
        { label: "Description", value: field.description ?? "" },
      ]}
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb label="categories" onClick={() => categoryNav.list()} />
          <Breadcrumb
            label={field.context.category.name}
            onClick={() => categoryNav.show(field.context.category.id)}
          />
          <Breadcrumb
            label={field.context.name}
            onClick={() => contextNav.show(field.context.id)}
          />
          <Breadcrumb label={field.display} />
        </Breadcrumbs>
      }
      onEditAction={() => props.onEditAction && props.onEditAction(field)}
    >
      <TabView
        useUrlParams={true}
        renderTabs={(tabProps) => (
          <Tabs {...tabProps}>
            <Tab
              label={<TabLabel label="Options" icon={<ListAltOutlined />} />}
            />
            <Tab
              label={<TabLabel label="Tasks" icon={<ListAltOutlined />} />}
            />
            <Tab
              label={
                <TabLabel label="Translations" icon={<TranslateOutlined />} />
              }
            />
          </Tabs>
        )}
        renderContent={(current) => (
          <>
            <TabContent index={0} current={current}>
              <Card style={{ marginTop: 20 }}>
                <CardContent>
                  <TableView>
                    <TableViewHeader info={optionDescription}></TableViewHeader>
                    <FieldOptionsTable
                      data={field.field_options}
                      selectable="none"
                      showUrl={(item) =>
                        item.option?.id && optionNav.showUrl(item.option.id)
                      }
                      hideColumns={{
                        field_id: true,
                        field_name: true,
                        category: true,
                        type: true,
                      }}
                    />
                  </TableView>
                </CardContent>
              </Card>
            </TabContent>
            <TabContent index={1} current={current}>
              <Card style={{ marginTop: 20 }}>
                <CardContent>
                  <TableView>
                    <AllTasksTable
                      where={[
                        {
                          task_field_options: {
                            field_option: { field_id: { _eq: props.id } },
                          },
                        },
                      ]}
                      hideColumns={{ num_recordings: true }}
                      showUrl={(item) => item.id && taskNav.showUrl(item.id)}
                      selectable="none"
                    />
                  </TableView>
                </CardContent>
              </Card>
            </TabContent>
            <TabContent index={2} current={current}>
              <Card style={{ marginTop: 20 }}>
                <CardContent>
                  <FieldTranslationTable field={{ id: props.id }} />
                </CardContent>
              </Card>
            </TabContent>
          </>
        )}
      />
    </ShowResourceView>
  );
};

// Finally, gather all components for the field resource.
export const useFieldNav = () => useResourceNav("fields");
export const FieldResource = () => (
  <Resource
    path="fields"
    list={(nav) => (
      <FieldIndex
        showUrl={(item) => item.id && nav.showUrl(item.id)}
        editUrl={(item) => item.id && nav.editUrl(item.id)}
      />
    )}
    show={(nav, id) => (
      <FieldShow
        id={id ?? ""}
        onEditAction={(item) => item.id && nav.edit(item.id)}
      />
    )}
    create={(nav) => (
      <FieldForm action="insert" onSuccess={(id) => nav.show(id)} />
    )}
    edit={(nav, id) => (
      <FieldForm
        action="update"
        onSuccess={(id) => nav.show(id)}
        field={{ id: id ?? "" }}
      />
    )}
  />
);
