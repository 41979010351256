import React, { Fragment } from "react";
import { Box, Typography } from "@material-ui/core";
import { useProjectChecklistsQuery } from "src/generated/asgard/graphql";
import { ProgressBar } from "src/components/ProgressBar";
import {
  BringupChecklistRow,
  BringupChecklistTable,
} from "src/resources/Bringup";

type BringupChecklistTabProps = {
  projectId: string;
};

export const BringupChecklistTab = (props: BringupChecklistTabProps) => {
  const q = useProjectChecklistsQuery({
    variables: { project_id: props.projectId },
    fetchPolicy: "network-only",
  });
  const checklists =
    q.data?.projects_by_pk?.deliverable?.checklist_deliverables?.map(
      ({ checklist }) => checklist,
    );
  return (
    <>
      {checklists?.map((checklist) => (
        <Fragment key={checklist.id}>
          <Box margin={3}>
            <Typography variant="h6">{checklist.name}</Typography>
            <Typography variant="caption">{checklist.description}</Typography>
          </Box>
          <Box margin={3}>
            <ProgressBar
              numerator={
                checklist.completed_items_aggregate?.aggregate?.count ?? 0
              }
              denominator={checklist.all_items_aggregate?.aggregate?.count ?? 0}
              hideFraction
            />
          </Box>
          <BringupChecklistTable projectId={props.projectId}>
            {checklist.items.map((item) => (
              <BringupChecklistRow
                key={item.id}
                item={item}
                onChange={() => q?.refetch && q.refetch()}
              />
            ))}
          </BringupChecklistTable>
        </Fragment>
      ))}
    </>
  );
};
