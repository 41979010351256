import React, { Fragment, useState } from "react";
import { Button } from "@material-ui/core";
import Markdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";

type MarkdownEditorProps = {
  onSave: (md: string) => void;
  onClose: () => void;
  data: string;
};

export function MarkdownEditor(props: MarkdownEditorProps) {
  const [md, setMD] = useState(props.data);
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");

  return (
    <Fragment>
      <ReactMde
        value={md}
        onChange={(val) => setMD(val)}
        selectedTab={selectedTab}
        onTabChange={(tab) => setSelectedTab(tab)}
        generateMarkdownPreview={async (md) => <Markdown>{md}</Markdown>}
      />
      <div
        style={{
          backgroundColor: "#f9f9f9",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button onClick={props.onClose}>Close</Button>
        <Button color="primary" onClick={() => props.onSave(md)}>
          Save
        </Button>
      </div>
    </Fragment>
  );
}
