import React, { useState } from "react";
import { Button, Card, Grid, Typography } from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import * as Yup from "yup";
import { SearchBar } from "src/components/filters";
import {
  DialogSelect,
  TextFieldArray,
  FormContainer,
  FormHeader,
  TextField,
} from "src/components/Form";
import { Resource, useResourceNav } from "src/components/Resource";
import { createTable, TableOptionals, TextColumn } from "src/components/table";
import {
  usePagination,
  like,
  strArrToPostgresLiteral,
} from "src/resources/Utils";
import {
  Breadcrumb,
  Breadcrumbs,
  ShowResourceView,
  TableView,
  TableViewHeader,
} from "src/Layout";
import { ResourceForm, FormAction } from "src/components/ResourceForm";
import {
  Deliveries_Project_Configs as Project_Config,
  Deliveries_Project_Configs_Bool_Exp as Project_Config_Bool_Exp,
  useAllProjectConfigsQuery,
  useProjectConfigQuery,
  useCreateProjectConfigMutation,
  useUpdateProjectConfigMutation,
  Deliveries_Config_Id_Types_Enum,
  Order_By,
} from "src/generated/asgard/graphql";
import { AllProjectsTable } from "../Project";
import { Autocomplete } from "@material-ui/lab";

// Config table columns from Project Config fields
export const ProjectConfigTable = createTable<Project_Config>()({
  keys: (project_config) => project_config.project_id ?? "",
  title: "Project Config",
  headers: {
    project_name: { display: "Project Name" },
    config_id_type: { display: "ID Type" },
    config_id: { display: "ID Value" },
  },
  columns: (project_config) => ({
    project_name: (
      <TextColumn value={project_config.project?.project_name?.name ?? ""} />
    ),
    config_id_type: (
      <TextColumn
        value={
          project_config.config_id_type === "CHID"
            ? project_config.config_id_type + "-" + project_config.chid_level
            : project_config.config_id_type
        }
      />
    ),
    config_id: <TextColumn value={project_config.config_id} />,
  }),
});

// Define a new table component for Project Configs
type AllProjectConfigTableProps = TableOptionals<typeof ProjectConfigTable> & {
  where?: Project_Config_Bool_Exp[];
};

export const AllProjectConfigTable = (props: AllProjectConfigTableProps) => {
  const [pageVars, pageController] = usePagination();
  const [search, setSearch] = useState("");
  const searchFilters: Project_Config_Bool_Exp[] = [];
  // Add search terms for individual fields.
  const term = like(search);
  searchFilters.push({ project: { project_name: { name: { _ilike: term } } } });
  const { data } = useAllProjectConfigsQuery({
    variables: {
      ...pageVars,
      where: { _and: [{ _and: props.where }, { _or: searchFilters }] },
      order_by: [{ project: { project_name: { name: Order_By.Asc } } }],
    },
    fetchPolicy: "network-only",
  });

  return (
    <ProjectConfigTable
      {...props}
      {...pageController}
      total={data?.project_configs_aggregate?.aggregate?.count}
      data={data?.project_configs}
      tools={
        <Grid item xs={12}>
          <SearchBar onChange={setSearch} />
        </Grid>
      }
    />
  );
};

// Define content to display in the main Project Config resource page
type ProjectConfigIndexProps = {
  onAddNew: () => void;
} & TableOptionals<typeof ProjectConfigTable>;

export const ProjectConfigIndex = (props: ProjectConfigIndexProps) => {
  return (
    <TableView>
      <TableViewHeader
        title={<Typography variant="h5">Project Configs</Typography>}
      >
        <Button
          onClick={props.onAddNew}
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutline />}
          disableElevation
        >
          New Project Config
        </Button>
      </TableViewHeader>
      <Card>
        <AllProjectConfigTable {...props} selectable="none" />
      </Card>
    </TableView>
  );
};

// Define form for creating and editing Project Configs
const projectConfigSchema = Yup.object({
  project: Yup.object({
    id: Yup.string(),
    name: Yup.string(),
  }).required(),
  project_id: Yup.string().required(),
  config_id_type: Yup.string()
    .oneOf(Object.values(Deliveries_Config_Id_Types_Enum))
    .required(),
  config_id: Yup.string().required(),
  chid_level: Yup.string().matches(/^[0-9]+$/, "must be a positive integer"),
  controller_updates: Yup.array(
    Yup.string().matches(
      /^\d+,\d+,\d+$/,
      "must be a comma-separated list of exactly three integers",
    ),
  ),
}).required();

export type ProjectConfigFormProps = {
  action: FormAction;
  project_config?: { id: string };
  onSuccess?: (id: string) => void;
};

export const ProjectConfigForm = (props: ProjectConfigFormProps) => {
  const [createProjectConfig] = useCreateProjectConfigMutation();
  const [updateProjectConfig] = useUpdateProjectConfigMutation();

  const allProjectConfigs = useAllProjectConfigsQuery({
    variables: {
      where: {
        project_id: {
          _nin: props.project_config?.id ? [props.project_config.id] : [],
        },
      },
    },
    fetchPolicy: "network-only",
  });

  const existingProjectConfig = useProjectConfigQuery({
    variables: { id: props.project_config?.id ?? "" },
    fetchPolicy: "network-only",
    skip: !props.project_config,
  });

  return (
    <ResourceForm
      action={props.action}
      schema={projectConfigSchema}
      resourceToUpdate={existingProjectConfig.data?.project_config}
      transform={(project_config) => ({
        project: {
          id: project_config.project?.id ?? "",
          name: project_config.project?.project_name?.name ?? "",
        },
        project_id: project_config.project_id ?? "",
        config_id_type: project_config.config_id_type,
        config_id: project_config.config_id,
        chid_level: project_config.chid_level
          ? String(project_config.chid_level)
          : "",
        controller_updates: project_config.controller_updates ?? [],
      })}
      initialValues={{
        project: {
          id: "",
          name: "",
        },
        project_id: "",
        config_id_type: Deliveries_Config_Id_Types_Enum.Ssid,
        config_id: "",
        chid_level: "",
        controller_updates: [],
      }}
      customValidator={(values, errors) => {
        for (let existing of allProjectConfigs.data?.project_configs ?? []) {
          if (
            existing.project.id === values.project.id &&
            existing.config_id_type === values.config_id_type &&
            existing.config_id === values.config_id &&
            existing.chid_level === values.chid_level
          ) {
            errors.project = {
              name: `Project ${values.project?.name} already has a config attached with the same values`,
            };
          }
          if (values.config_id_type === "SSID") {
            const ssidRegex = /^[A-Z0-9]{8}$/; // Regex for 8 characters: uppercase letters and numbers
            if (!ssidRegex.test(values.config_id)) {
              errors.config_id =
                "Invalid SSID. It should be 8 characters long, consisting of uppercase letters and numbers.";
            }
          }

          // Validate CHID
          if (values.config_id_type === "CHID") {
            const chidRegex =
              /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i; // Regex for UUID
            if (!chidRegex.test(values.config_id)) {
              errors.config_id = "Invalid CHID. It should be in UUID format.";
            }
            if (!values.chid_level) {
              errors.chid_level = "For CHID, you must provide the chid level";
            }
          } else {
            if (!!values.chid_level) {
              errors.chid_level = "Leave blank if not CHID";
            }
          }

          if (errors.project || errors.config_id || errors.chid_level) {
            break;
          }
        }
      }}
      onUpdate={async (values) => {
        const {
          project,
          chid_level,
          controller_updates = [],
          ...cols
        } = values;
        const formattedControllerUpdates = strArrToPostgresLiteral(
          controller_updates.filter(
            (item: string | undefined): item is string => item !== undefined,
          ),
          true,
          true,
        );
        const columns = {
          ...cols,
          // The CHID level needs to be null if the user leaves the field empty.
          chid_level: !!chid_level ? parseInt(chid_level) : null,
          controller_updates: formattedControllerUpdates,
        };
        const result = await (async () => {
          if (props.project_config?.id)
            return await updateProjectConfig({
              variables: {
                id: props.project_config.id,
                columns: { ...columns },
              },
            });
        })();
        props.onSuccess &&
          props.onSuccess(result?.data?.project_config?.id ?? "");
      }}
      onInsert={async (values) => {
        const {
          project,
          chid_level,
          controller_updates = [],
          ...cols
        } = values;
        const formattedControllerUpdates = strArrToPostgresLiteral(
          controller_updates.filter(
            (item: string | undefined): item is string => item !== undefined,
          ),
          true,
          true,
        );
        const columns = {
          ...cols,
          chid_level: !!chid_level ? parseInt(chid_level) : null,
          controller_updates: formattedControllerUpdates,
        };
        const result = await (async () => {
          return await createProjectConfig({
            variables: {
              input: { ...columns },
            },
          });
        })();
        props.onSuccess &&
          props.onSuccess(result.data?.project_config?.id ?? "");
      }}
      render={({ formik, path }) => (
        <>
          <FormHeader>
            {props.project_config
              ? "Update Project Config"
              : "Create New Project Config"}
          </FormHeader>
          <FormContainer>
            <DialogSelect
              bp={{ xs: 6 }}
              disabled={props.action === "update"}
              multiple={false}
              onReset={() =>
                formik.setValues({
                  ...formik.values,
                  project: formik.initialValues.project,
                })
              }
              name={path.project.name._}
              label="Project"
              content={(close) => (
                <>
                  <AllProjectsTable
                    selectable="single"
                    onSelect={(project) => {
                      formik.setValues({
                        ...formik.values,
                        project: {
                          id: project.id ?? "",
                          name: project.project_name?.name ?? "",
                        },
                        project_id: project.id ?? "",
                      });
                      close();
                    }}
                  />
                </>
              )}
            />
            <Grid item xs={12} sm={3}>
              <Autocomplete
                style={{ marginRight: "1px" }}
                options={["SSID", "CHID", "default"]}
                value={formik.values.config_id_type}
                getOptionLabel={(option) => option || ""}
                getOptionSelected={(option, value) => option === value}
                onChange={(_event, newValue) => {
                  formik.setFieldValue("config_id_type", newValue ?? "");
                  if (newValue === "default") {
                    formik.setFieldValue("config_id", "default");
                  } else {
                    formik.setFieldValue("config_id", "");
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Config ID Type"
                    name={"config_id_type"}
                    bp={{ xs: 12, sm: 12 }}
                  />
                )}
              />
            </Grid>

            <TextField
              bp={{ xs: 12, sm: 3 }}
              name={path.chid_level?._ ?? "chid_level"}
              label="CHID level (leave empty for SSID)"
            />
            <TextField
              bp={{ xs: 12, sm: 6 }}
              name={path.config_id._}
              label="Config ID"
            />
            <TextFieldArray
              name="controller_updates"
              label="Controller Update"
              bp={{ xs: 12, sm: 12 }}
            />
          </FormContainer>
        </>
      )}
    />
  );
};

// Create a detailed 'show' page.
type ProjectConfigShowProps = {
  id: string;
  onEditAction?: (item: DeepPartial<Project_Config>) => void;
};

const ProjectConfigShow = (props: ProjectConfigShowProps) => {
  const projectConfigNav = useProjectConfigNav();
  const projectConfigQuery = useProjectConfigQuery({
    variables: { id: props.id },
    fetchPolicy: "network-only",
  });
  const project_config = projectConfigQuery.data?.project_config;
  if (!project_config) return null;

  const properties = [
    { label: "Project ID", value: project_config.project_id },
  ];
  const targetProjectName = project_config?.project?.project_name?.name;
  if (targetProjectName)
    properties.push({
      label: "Project Name",
      value: targetProjectName ?? "None",
    });
  const configIdType = project_config?.config_id_type;
  const chidLevel = project_config?.chid_level;
  if (configIdType === "CHID" && chidLevel)
    properties.push({
      label: "Config Id Type",
      value: configIdType + "-" + chidLevel ?? "None",
    });
  if (configIdType === "SSID" || !chidLevel)
    properties.push({
      label: "Config Id Type",
      value: configIdType ?? "None",
    });
  const configId = project_config?.config_id;
  if (configId)
    properties.push({
      label: "Config Id",
      value: configId ?? "None",
    });
  const controllerUpdates = project_config?.controller_updates;
  const controllerUpdatesCommaSep =
    "[" + controllerUpdates.map((item: string) => '"' + item + '"') + "]";
  if (controllerUpdates)
    properties.push({
      label: "Controller Updates",
      value: controllerUpdatesCommaSep ?? "None",
    });

  return (
    <ShowResourceView
      title={project_config.project?.project_name?.name ?? ""}
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb
            label="Project Config"
            onClick={() => projectConfigNav.list()}
          />
          <Breadcrumb
            label={project_config.project?.project_name?.name ?? ""}
          />
        </Breadcrumbs>
      }
      onEditAction={() =>
        props.onEditAction && props.onEditAction(project_config)
      }
      properties={properties}
    ></ShowResourceView>
  );
};

// Finally, combine into full resource UI.
const path = "project_configs";
export const useProjectConfigNav = () => useResourceNav(path);
export const ProjectConfigResource = () => (
  <Resource
    path={path}
    list={(nav) => (
      <ProjectConfigIndex
        editUrl={(item) => item.id && nav.editUrl(item.id)}
        showUrl={(item) => item.id && nav.showUrl(item.id)}
        onAddNew={() => nav.create()}
      />
    )}
    show={(nav, id) => (
      <ProjectConfigShow
        id={id ?? ""}
        onEditAction={(item) => item.id && nav.edit(item.id)}
      />
    )}
    create={(nav) => (
      <ProjectConfigForm action="insert" onSuccess={(id) => nav.show(id)} />
    )}
    edit={(nav, id) => (
      <ProjectConfigForm
        action="update"
        onSuccess={(id) => nav.show(id)}
        project_config={{ id: id ?? "" }}
      />
    )}
  />
);
