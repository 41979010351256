import { useState } from "react";
import { Button, Grid, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CopyToClipboard from "react-copy-to-clipboard";
import { BlobDownloadButton } from "src/components/BlobDownloadButton";
import { CloudDownload, FileCopyOutlined } from "@material-ui/icons";

const parseBlobUrl = (url: string) => {
  const urlObj = new URL(url);

  const storageAccountUrl = `${urlObj.protocol}//${urlObj.host}`;
  const pathParts = urlObj.pathname.split("/").filter(Boolean);
  const containerName = decodeURIComponent(pathParts[0]);
  const blobName = decodeURIComponent(pathParts.slice(1).join("/"));

  return { storageAccountUrl, containerName, blobName };
};

export const BlobDownloadLink = (props: { fullUrl: string }) => {
  const { fullUrl } = props;
  const [copied, setCopied] = useState(false);
  if (!fullUrl) {
    return <div>None</div>;
  }
  return (
    <Grid container spacing={2} alignItems="center">
      {/* Copy to Clipboard Button */}
      <Grid item>
        <CopyToClipboard text={fullUrl} onCopy={() => setCopied(true)}>
          <Button size="small" variant="contained" color="primary">
            <FileCopyOutlined />
          </Button>
        </CopyToClipboard>
      </Grid>

      {/* Snackbar Notification for Copy Action */}
      <Snackbar
        open={copied}
        autoHideDuration={2000}
        onClose={() => setCopied(false)}
      >
        <Alert severity="success" onClose={() => setCopied(false)}>
          Successfully copied to clipboard
        </Alert>
      </Snackbar>
      <BlobDownloadButton
        size="small"
        variant="contained"
        color="secondary"
        {...parseBlobUrl(fullUrl)}
      >
        <CloudDownload />
      </BlobDownloadButton>
    </Grid>
  );
};
