import { useState } from "react";
import { Card, Grid, Typography } from "@material-ui/core";
import { SearchBar } from "src/components/filters";
import { Resource, useResourceNav } from "src/components/Resource";
import {
  createTable,
  EllipsisColumn,
  TableOptionals,
  TextColumn,
} from "src/components/table";
import { usePagination, like, uuidIsValid } from "src/resources/Utils";
import {
  Breadcrumb,
  Breadcrumbs,
  ShowResourceView,
  TableView,
  TableViewHeader,
} from "src/Layout";
import {
  Deliveries_Driver_Versions as DriverVersion,
  Deliveries_Driver_Versions_Bool_Exp as Driver_Versions_Bool_Exp,
  Order_By,
  useAllDriverVersionsQuery,
  useDriverVersionQuery,
} from "src/generated/asgard/graphql";
import { BlobDownloadLink } from "./utils";

// Config table columns from DriverVersion fields
export const DriverVersionTable = createTable<DriverVersion>()({
  keys: (driver_version) => driver_version.id ?? "",
  title: "DriverVersion",
  headers: {
    id: { display: "ID" },
    driver: { display: "Driver" },
    version: { display: "Version" },
    name: { display: "Name" },
    blob: { display: "Blob" },
    sbom: { display: "SBOM" },
    target: { display: "Target" },
  },
  columns: (driver_version) => {
    const targetEngine =
      driver_version.driver_version_engine?.engine?.build_number;
    const targetProject =
      driver_version.driver?.driver_project?.project?.project_name?.name;
    return {
      id: <EllipsisColumn value={driver_version.id} />,
      driver: <TextColumn value={driver_version.driver?.name ?? ""} />,
      version: <TextColumn value={driver_version.version} />,
      name: <TextColumn value={driver_version.name} />,
      blob: (
        <BlobDownloadLink
          fullUrl={driver_version.storage_blob_url ?? ""}
        />
      ),
      sbom: (
        <BlobDownloadLink
          fullUrl={driver_version.sbom_blob_url ?? ""}
        />
      ),
      target: (
        <TextColumn
          value={
            targetEngine && targetProject
              ? `${targetProject} / ${targetEngine}`
              : "N/A"
          }
        />
      ),
    };
  },
});

// Define a new table component for DriverVersions
type AllDriverVersionsTableProps = TableOptionals<typeof DriverVersionTable> & {
  where?: Driver_Versions_Bool_Exp[];
};

export const AllDriverVersionsTable = (props: AllDriverVersionsTableProps) => {
  const [pageVars, pageController] = usePagination();
  const [search, setSearch] = useState("");
  const searchFilters: Driver_Versions_Bool_Exp[] = [];
  if (uuidIsValid(search)) {
    searchFilters.push({ id: { _eq: search } });
  } else {
    // Add search terms for individual fields.
    const term = like(search);
    searchFilters.push({ name: { _ilike: term } });
    searchFilters.push({ version: { _ilike: term } });
    searchFilters.push({ storage_blob_url: { _ilike: term } });
    searchFilters.push({ sbom_blob_url: { _ilike: term } });
  }
  const { data } = useAllDriverVersionsQuery({
    variables: {
      ...pageVars,
      where: { _and: [{ _and: props.where }, { _or: searchFilters }] },
      order_by: [{ created_at: Order_By.Desc }],
    },
    fetchPolicy: "network-only",
  });

  return (
    <DriverVersionTable
      {...props}
      {...pageController}
      total={data?.driver_versions_aggregate?.aggregate?.count}
      data={data?.driver_versions}
      tools={
        <Grid item xs={12}>
          <SearchBar onChange={setSearch} />
        </Grid>
      }
    />
  );
};

// Define content to display in the main DriverVersion resource page
type DriverVersionIndexProps = {
} & TableOptionals<typeof DriverVersionTable>;

export const DriverVersionIndex = (props: DriverVersionIndexProps) => {
  return (
    <TableView>
      <TableViewHeader
        title={<Typography variant="h5">DriverVersions</Typography>}
      >
      </TableViewHeader>
      <Card>
        <AllDriverVersionsTable {...props} selectable="none" />
      </Card>
    </TableView>
  );
};

// Create a detailed 'show' page.
type DriverVersionShowProps = {
  id: string;
  onEditAction?: (item: DeepPartial<DriverVersion>) => void;
};

const DriverVersionShow = (props: DriverVersionShowProps) => {
  const driverVersionNav = useDriverVersionNav();

  const driverversionQuery = useDriverVersionQuery({
    variables: { id: props.id },
    fetchPolicy: "network-only",
  });
  const driver_version = driverversionQuery.data?.driver_version;
  if (!driver_version) return null;

  const properties = [
    {
      label: "Blob Url",
      value: driver_version.storage_blob_url,
    },
  ];
  const targetEngine =
    driver_version.driver_version_engine?.engine?.build_number;
  if (targetEngine)
    properties.push({
      label: "Target Engine",
      value:
        driver_version.driver_version_engine?.engine?.build_number ?? "N/A",
    });

  return (
    <ShowResourceView
      title={driver_version.version ?? ""}
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb
            label="driver versions"
            onClick={() => driverVersionNav.list()}
          />
          <Breadcrumb label={driver_version.name ?? ""} />
        </Breadcrumbs>
      }
      properties={properties}
    />
  );
};

// Finally, combine into full resource UI.
const path = "driverversions";
export const useDriverVersionNav = () => useResourceNav(path);
export const DriverVersionResource = () => (
  <Resource
    path={path}
    list={(nav) => <DriverVersionIndex />}
    show={(nav, id) => <DriverVersionShow id={id ?? ""} />}
  />
);
