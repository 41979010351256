import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _int2: any;
  _int4: any;
  _text: any;
  _uuid: any;
  bigint: number;
  date: any;
  float8: number;
  json: any;
  jsonb: object;
  numeric: any;
  smallint: number;
  timestamp: any;
  timestamptz: string;
  uuid: string;
};


/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type ElapiSlackChannel = {
  __typename?: 'ElapiSlackChannel';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** Boolean expression to compare columns of type "Float". All fields are combined with logical 'AND'. */
export type Float_Comparison_Exp = {
  _eq?: Maybe<Scalars['Float']>;
  _gt?: Maybe<Scalars['Float']>;
  _gte?: Maybe<Scalars['Float']>;
  _in?: Maybe<Array<Scalars['Float']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Float']>;
  _lte?: Maybe<Scalars['Float']>;
  _neq?: Maybe<Scalars['Float']>;
  _nin?: Maybe<Array<Scalars['Float']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};


/** Boolean expression to compare columns of type "_int2". All fields are combined with logical 'AND'. */
export type _Int2_Comparison_Exp = {
  _eq?: Maybe<Scalars['_int2']>;
  _gt?: Maybe<Scalars['_int2']>;
  _gte?: Maybe<Scalars['_int2']>;
  _in?: Maybe<Array<Scalars['_int2']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['_int2']>;
  _lte?: Maybe<Scalars['_int2']>;
  _neq?: Maybe<Scalars['_int2']>;
  _nin?: Maybe<Array<Scalars['_int2']>>;
};


/** Boolean expression to compare columns of type "_int4". All fields are combined with logical 'AND'. */
export type _Int4_Comparison_Exp = {
  _eq?: Maybe<Scalars['_int4']>;
  _gt?: Maybe<Scalars['_int4']>;
  _gte?: Maybe<Scalars['_int4']>;
  _in?: Maybe<Array<Scalars['_int4']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['_int4']>;
  _lte?: Maybe<Scalars['_int4']>;
  _neq?: Maybe<Scalars['_int4']>;
  _nin?: Maybe<Array<Scalars['_int4']>>;
};


/** Boolean expression to compare columns of type "_text". All fields are combined with logical 'AND'. */
export type _Text_Comparison_Exp = {
  _eq?: Maybe<Scalars['_text']>;
  _gt?: Maybe<Scalars['_text']>;
  _gte?: Maybe<Scalars['_text']>;
  _in?: Maybe<Array<Scalars['_text']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['_text']>;
  _lte?: Maybe<Scalars['_text']>;
  _neq?: Maybe<Scalars['_text']>;
  _nin?: Maybe<Array<Scalars['_text']>>;
};


/** columns and relationships of "audio_driver" */
export type Audio_Driver = {
  __typename?: 'audio_driver';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  recording_audio_drivers: Array<Recording_Audio_Driver>;
  updated_at: Scalars['timestamptz'];
  version: Scalars['String'];
};


/** columns and relationships of "audio_driver" */
export type Audio_DriverRecording_Audio_DriversArgs = {
  distinct_on?: Maybe<Array<Recording_Audio_Driver_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Audio_Driver_Order_By>>;
  where?: Maybe<Recording_Audio_Driver_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "audio_driver". All fields are combined with a logical 'AND'. */
export type Audio_Driver_Bool_Exp = {
  _and?: Maybe<Array<Audio_Driver_Bool_Exp>>;
  _not?: Maybe<Audio_Driver_Bool_Exp>;
  _or?: Maybe<Array<Audio_Driver_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  recording_audio_drivers?: Maybe<Recording_Audio_Driver_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  version?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "audio_driver" */
export enum Audio_Driver_Constraint {
  /** unique or primary key constraint on columns "name", "version" */
  AudioDriverNameVersionKey = 'audio_driver_name_version_key',
  /** unique or primary key constraint on columns "id" */
  AudioDriverPkey = 'audio_driver_pkey'
}

/** input type for inserting data into table "audio_driver" */
export type Audio_Driver_Insert_Input = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  recording_audio_drivers?: Maybe<Recording_Audio_Driver_Arr_Rel_Insert_Input>;
  version?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "audio_driver" */
export type Audio_Driver_Mutation_Response = {
  __typename?: 'audio_driver_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Audio_Driver>;
};

/** input type for inserting object relation for remote table "audio_driver" */
export type Audio_Driver_Obj_Rel_Insert_Input = {
  data: Audio_Driver_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Audio_Driver_On_Conflict>;
};

/** on_conflict condition type for table "audio_driver" */
export type Audio_Driver_On_Conflict = {
  constraint: Audio_Driver_Constraint;
  update_columns?: Array<Audio_Driver_Update_Column>;
  where?: Maybe<Audio_Driver_Bool_Exp>;
};

/** Ordering options when selecting data from "audio_driver". */
export type Audio_Driver_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  recording_audio_drivers_aggregate?: Maybe<Recording_Audio_Driver_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
};

/** primary key columns input for table: audio_driver */
export type Audio_Driver_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "audio_driver" */
export enum Audio_Driver_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "audio_driver" */
export type Audio_Driver_Set_Input = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "audio_driver" */
export type Audio_Driver_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audio_Driver_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audio_Driver_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['String']>;
};

/** update columns of table "audio_driver" */
export enum Audio_Driver_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Name = 'name',
  /** column name */
  Version = 'version'
}

export type Audio_Driver_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Audio_Driver_Set_Input>;
  where: Audio_Driver_Bool_Exp;
};

/** columns and relationships of "audio_measurements.components" */
export type Audio_Measurements_Components = {
  __typename?: 'audio_measurements_components';
  /** An array relationship */
  configurations: Array<Audio_Measurements_Configurations>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  model: Scalars['String'];
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "audio_measurements.components" */
export type Audio_Measurements_ComponentsConfigurationsArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Configurations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Configurations_Order_By>>;
  where?: Maybe<Audio_Measurements_Configurations_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "audio_measurements.components". All fields are combined with a logical 'AND'. */
export type Audio_Measurements_Components_Bool_Exp = {
  _and?: Maybe<Array<Audio_Measurements_Components_Bool_Exp>>;
  _not?: Maybe<Audio_Measurements_Components_Bool_Exp>;
  _or?: Maybe<Array<Audio_Measurements_Components_Bool_Exp>>;
  configurations?: Maybe<Audio_Measurements_Configurations_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  model?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "audio_measurements.components" */
export enum Audio_Measurements_Components_Constraint {
  /** unique or primary key constraint on columns "type", "model" */
  ComponentsModelTypeKey = 'components_model_type_key',
  /** unique or primary key constraint on columns "id" */
  ComponentsPkey = 'components_pkey'
}

/** input type for inserting data into table "audio_measurements.components" */
export type Audio_Measurements_Components_Insert_Input = {
  configurations?: Maybe<Audio_Measurements_Configurations_Arr_Rel_Insert_Input>;
  model?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "audio_measurements.components" */
export type Audio_Measurements_Components_Mutation_Response = {
  __typename?: 'audio_measurements_components_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Audio_Measurements_Components>;
};

/** input type for inserting object relation for remote table "audio_measurements.components" */
export type Audio_Measurements_Components_Obj_Rel_Insert_Input = {
  data: Audio_Measurements_Components_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Audio_Measurements_Components_On_Conflict>;
};

/** on_conflict condition type for table "audio_measurements.components" */
export type Audio_Measurements_Components_On_Conflict = {
  constraint: Audio_Measurements_Components_Constraint;
  update_columns?: Array<Audio_Measurements_Components_Update_Column>;
  where?: Maybe<Audio_Measurements_Components_Bool_Exp>;
};

/** Ordering options when selecting data from "audio_measurements.components". */
export type Audio_Measurements_Components_Order_By = {
  configurations_aggregate?: Maybe<Audio_Measurements_Configurations_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: audio_measurements.components */
export type Audio_Measurements_Components_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "audio_measurements.components" */
export enum Audio_Measurements_Components_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Model = 'model',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "audio_measurements.components" */
export type Audio_Measurements_Components_Set_Input = {
  model?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "audio_measurements_components" */
export type Audio_Measurements_Components_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audio_Measurements_Components_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audio_Measurements_Components_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  model?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "audio_measurements.components" */
export enum Audio_Measurements_Components_Update_Column {
  /** column name */
  Model = 'model',
  /** column name */
  Type = 'type'
}

export type Audio_Measurements_Components_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Audio_Measurements_Components_Set_Input>;
  where: Audio_Measurements_Components_Bool_Exp;
};

/** columns and relationships of "audio_measurements.configurations" */
export type Audio_Measurements_Configurations = {
  __typename?: 'audio_measurements_configurations';
  /** An object relationship */
  component: Audio_Measurements_Components;
  component_id: Scalars['uuid'];
  /** An array relationship */
  devices: Array<Devices>;
  /** An aggregate relationship */
  devices_aggregate: Devices_Aggregate;
  id: Scalars['uuid'];
  /** An object relationship */
  model: Models;
  model_id: Scalars['uuid'];
  /** An object relationship */
  orientation: Audio_Measurements_Orientations;
  orientation_id: Scalars['uuid'];
  /** An object relationship */
  placement: Audio_Measurements_Placements;
  placement_id: Scalars['uuid'];
  screen_size: Scalars['String'];
};


/** columns and relationships of "audio_measurements.configurations" */
export type Audio_Measurements_ConfigurationsDevicesArgs = {
  distinct_on?: Maybe<Array<Devices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Devices_Order_By>>;
  where?: Maybe<Devices_Bool_Exp>;
};


/** columns and relationships of "audio_measurements.configurations" */
export type Audio_Measurements_ConfigurationsDevices_AggregateArgs = {
  distinct_on?: Maybe<Array<Devices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Devices_Order_By>>;
  where?: Maybe<Devices_Bool_Exp>;
};

/** order by aggregate values of table "audio_measurements.configurations" */
export type Audio_Measurements_Configurations_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Audio_Measurements_Configurations_Max_Order_By>;
  min?: Maybe<Audio_Measurements_Configurations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "audio_measurements.configurations" */
export type Audio_Measurements_Configurations_Arr_Rel_Insert_Input = {
  data: Array<Audio_Measurements_Configurations_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Audio_Measurements_Configurations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "audio_measurements.configurations". All fields are combined with a logical 'AND'. */
export type Audio_Measurements_Configurations_Bool_Exp = {
  _and?: Maybe<Array<Audio_Measurements_Configurations_Bool_Exp>>;
  _not?: Maybe<Audio_Measurements_Configurations_Bool_Exp>;
  _or?: Maybe<Array<Audio_Measurements_Configurations_Bool_Exp>>;
  component?: Maybe<Audio_Measurements_Components_Bool_Exp>;
  component_id?: Maybe<Uuid_Comparison_Exp>;
  devices?: Maybe<Devices_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  model?: Maybe<Models_Bool_Exp>;
  model_id?: Maybe<Uuid_Comparison_Exp>;
  orientation?: Maybe<Audio_Measurements_Orientations_Bool_Exp>;
  orientation_id?: Maybe<Uuid_Comparison_Exp>;
  placement?: Maybe<Audio_Measurements_Placements_Bool_Exp>;
  placement_id?: Maybe<Uuid_Comparison_Exp>;
  screen_size?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "audio_measurements.configurations" */
export enum Audio_Measurements_Configurations_Constraint {
  /** unique or primary key constraint on columns "id" */
  ConfigurationsPkey = 'configurations_pkey'
}

/** input type for inserting data into table "audio_measurements.configurations" */
export type Audio_Measurements_Configurations_Insert_Input = {
  component?: Maybe<Audio_Measurements_Components_Obj_Rel_Insert_Input>;
  component_id?: Maybe<Scalars['uuid']>;
  devices?: Maybe<Devices_Arr_Rel_Insert_Input>;
  model?: Maybe<Models_Obj_Rel_Insert_Input>;
  model_id?: Maybe<Scalars['uuid']>;
  orientation?: Maybe<Audio_Measurements_Orientations_Obj_Rel_Insert_Input>;
  orientation_id?: Maybe<Scalars['uuid']>;
  placement?: Maybe<Audio_Measurements_Placements_Obj_Rel_Insert_Input>;
  placement_id?: Maybe<Scalars['uuid']>;
  screen_size?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "audio_measurements.configurations" */
export type Audio_Measurements_Configurations_Max_Order_By = {
  component_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  model_id?: Maybe<Order_By>;
  orientation_id?: Maybe<Order_By>;
  placement_id?: Maybe<Order_By>;
  screen_size?: Maybe<Order_By>;
};

/** order by min() on columns of table "audio_measurements.configurations" */
export type Audio_Measurements_Configurations_Min_Order_By = {
  component_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  model_id?: Maybe<Order_By>;
  orientation_id?: Maybe<Order_By>;
  placement_id?: Maybe<Order_By>;
  screen_size?: Maybe<Order_By>;
};

/** response of any mutation on the table "audio_measurements.configurations" */
export type Audio_Measurements_Configurations_Mutation_Response = {
  __typename?: 'audio_measurements_configurations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Audio_Measurements_Configurations>;
};

/** input type for inserting object relation for remote table "audio_measurements.configurations" */
export type Audio_Measurements_Configurations_Obj_Rel_Insert_Input = {
  data: Audio_Measurements_Configurations_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Audio_Measurements_Configurations_On_Conflict>;
};

/** on_conflict condition type for table "audio_measurements.configurations" */
export type Audio_Measurements_Configurations_On_Conflict = {
  constraint: Audio_Measurements_Configurations_Constraint;
  update_columns?: Array<Audio_Measurements_Configurations_Update_Column>;
  where?: Maybe<Audio_Measurements_Configurations_Bool_Exp>;
};

/** Ordering options when selecting data from "audio_measurements.configurations". */
export type Audio_Measurements_Configurations_Order_By = {
  component?: Maybe<Audio_Measurements_Components_Order_By>;
  component_id?: Maybe<Order_By>;
  devices_aggregate?: Maybe<Devices_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  model?: Maybe<Models_Order_By>;
  model_id?: Maybe<Order_By>;
  orientation?: Maybe<Audio_Measurements_Orientations_Order_By>;
  orientation_id?: Maybe<Order_By>;
  placement?: Maybe<Audio_Measurements_Placements_Order_By>;
  placement_id?: Maybe<Order_By>;
  screen_size?: Maybe<Order_By>;
};

/** primary key columns input for table: audio_measurements.configurations */
export type Audio_Measurements_Configurations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "audio_measurements.configurations" */
export enum Audio_Measurements_Configurations_Select_Column {
  /** column name */
  ComponentId = 'component_id',
  /** column name */
  Id = 'id',
  /** column name */
  ModelId = 'model_id',
  /** column name */
  OrientationId = 'orientation_id',
  /** column name */
  PlacementId = 'placement_id',
  /** column name */
  ScreenSize = 'screen_size'
}

/** input type for updating data in table "audio_measurements.configurations" */
export type Audio_Measurements_Configurations_Set_Input = {
  component_id?: Maybe<Scalars['uuid']>;
  model_id?: Maybe<Scalars['uuid']>;
  orientation_id?: Maybe<Scalars['uuid']>;
  placement_id?: Maybe<Scalars['uuid']>;
  screen_size?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "audio_measurements_configurations" */
export type Audio_Measurements_Configurations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audio_Measurements_Configurations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audio_Measurements_Configurations_Stream_Cursor_Value_Input = {
  component_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  model_id?: Maybe<Scalars['uuid']>;
  orientation_id?: Maybe<Scalars['uuid']>;
  placement_id?: Maybe<Scalars['uuid']>;
  screen_size?: Maybe<Scalars['String']>;
};

/** update columns of table "audio_measurements.configurations" */
export enum Audio_Measurements_Configurations_Update_Column {
  /** column name */
  ComponentId = 'component_id',
  /** column name */
  ModelId = 'model_id',
  /** column name */
  OrientationId = 'orientation_id',
  /** column name */
  PlacementId = 'placement_id',
  /** column name */
  ScreenSize = 'screen_size'
}

export type Audio_Measurements_Configurations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Audio_Measurements_Configurations_Set_Input>;
  where: Audio_Measurements_Configurations_Bool_Exp;
};

/** columns and relationships of "audio_measurements.data_types" */
export type Audio_Measurements_Data_Types = {
  __typename?: 'audio_measurements_data_types';
  comment: Scalars['String'];
  id: Scalars['String'];
  /** An array relationship */
  master_files: Array<Audio_Measurements_Master_Files>;
  /** An aggregate relationship */
  master_files_aggregate: Audio_Measurements_Master_Files_Aggregate;
};


/** columns and relationships of "audio_measurements.data_types" */
export type Audio_Measurements_Data_TypesMaster_FilesArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Master_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Master_Files_Order_By>>;
  where?: Maybe<Audio_Measurements_Master_Files_Bool_Exp>;
};


/** columns and relationships of "audio_measurements.data_types" */
export type Audio_Measurements_Data_TypesMaster_Files_AggregateArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Master_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Master_Files_Order_By>>;
  where?: Maybe<Audio_Measurements_Master_Files_Bool_Exp>;
};

/** aggregated selection of "audio_measurements.data_types" */
export type Audio_Measurements_Data_Types_Aggregate = {
  __typename?: 'audio_measurements_data_types_aggregate';
  aggregate?: Maybe<Audio_Measurements_Data_Types_Aggregate_Fields>;
  nodes: Array<Audio_Measurements_Data_Types>;
};

/** aggregate fields of "audio_measurements.data_types" */
export type Audio_Measurements_Data_Types_Aggregate_Fields = {
  __typename?: 'audio_measurements_data_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Audio_Measurements_Data_Types_Max_Fields>;
  min?: Maybe<Audio_Measurements_Data_Types_Min_Fields>;
};


/** aggregate fields of "audio_measurements.data_types" */
export type Audio_Measurements_Data_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Audio_Measurements_Data_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "audio_measurements.data_types". All fields are combined with a logical 'AND'. */
export type Audio_Measurements_Data_Types_Bool_Exp = {
  _and?: Maybe<Array<Audio_Measurements_Data_Types_Bool_Exp>>;
  _not?: Maybe<Audio_Measurements_Data_Types_Bool_Exp>;
  _or?: Maybe<Array<Audio_Measurements_Data_Types_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  master_files?: Maybe<Audio_Measurements_Master_Files_Bool_Exp>;
};

/** aggregate max on columns */
export type Audio_Measurements_Data_Types_Max_Fields = {
  __typename?: 'audio_measurements_data_types_max_fields';
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Audio_Measurements_Data_Types_Min_Fields = {
  __typename?: 'audio_measurements_data_types_min_fields';
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "audio_measurements.data_types". */
export type Audio_Measurements_Data_Types_Order_By = {
  comment?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  master_files_aggregate?: Maybe<Audio_Measurements_Master_Files_Aggregate_Order_By>;
};

/** select columns of table "audio_measurements.data_types" */
export enum Audio_Measurements_Data_Types_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Id = 'id'
}

/** Streaming cursor of the table "audio_measurements_data_types" */
export type Audio_Measurements_Data_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audio_Measurements_Data_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audio_Measurements_Data_Types_Stream_Cursor_Value_Input = {
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** stores important metadata about audio master files */
export type Audio_Measurements_Master_Files = {
  __typename?: 'audio_measurements_master_files';
  chirp_start_freq: Scalars['Int'];
  chirp_stop_freq: Scalars['Int'];
  class_name: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  data_type: Audio_Measurements_Data_Types;
  data_type_id: Scalars['String'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  num_channels: Scalars['smallint'];
  package_version: Scalars['String'];
  /** An array relationship */
  recordings: Array<Audio_Measurements_Recordings>;
  /** An aggregate relationship */
  recordings_aggregate: Audio_Measurements_Recordings_Aggregate;
  sample_freq: Scalars['Int'];
  signal_amplitude: Scalars['numeric'];
  single_chirp_duration: Scalars['Int'];
  total_chirp_duration: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  zero_padding_head_duration: Scalars['Int'];
  zero_padding_tail_duration: Scalars['Int'];
};


/** stores important metadata about audio master files */
export type Audio_Measurements_Master_FilesRecordingsArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Recordings_Order_By>>;
  where?: Maybe<Audio_Measurements_Recordings_Bool_Exp>;
};


/** stores important metadata about audio master files */
export type Audio_Measurements_Master_FilesRecordings_AggregateArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Recordings_Order_By>>;
  where?: Maybe<Audio_Measurements_Recordings_Bool_Exp>;
};

/** aggregated selection of "audio_measurements.master_files" */
export type Audio_Measurements_Master_Files_Aggregate = {
  __typename?: 'audio_measurements_master_files_aggregate';
  aggregate?: Maybe<Audio_Measurements_Master_Files_Aggregate_Fields>;
  nodes: Array<Audio_Measurements_Master_Files>;
};

/** aggregate fields of "audio_measurements.master_files" */
export type Audio_Measurements_Master_Files_Aggregate_Fields = {
  __typename?: 'audio_measurements_master_files_aggregate_fields';
  avg?: Maybe<Audio_Measurements_Master_Files_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Audio_Measurements_Master_Files_Max_Fields>;
  min?: Maybe<Audio_Measurements_Master_Files_Min_Fields>;
  stddev?: Maybe<Audio_Measurements_Master_Files_Stddev_Fields>;
  stddev_pop?: Maybe<Audio_Measurements_Master_Files_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Audio_Measurements_Master_Files_Stddev_Samp_Fields>;
  sum?: Maybe<Audio_Measurements_Master_Files_Sum_Fields>;
  var_pop?: Maybe<Audio_Measurements_Master_Files_Var_Pop_Fields>;
  var_samp?: Maybe<Audio_Measurements_Master_Files_Var_Samp_Fields>;
  variance?: Maybe<Audio_Measurements_Master_Files_Variance_Fields>;
};


/** aggregate fields of "audio_measurements.master_files" */
export type Audio_Measurements_Master_Files_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Audio_Measurements_Master_Files_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "audio_measurements.master_files" */
export type Audio_Measurements_Master_Files_Aggregate_Order_By = {
  avg?: Maybe<Audio_Measurements_Master_Files_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Audio_Measurements_Master_Files_Max_Order_By>;
  min?: Maybe<Audio_Measurements_Master_Files_Min_Order_By>;
  stddev?: Maybe<Audio_Measurements_Master_Files_Stddev_Order_By>;
  stddev_pop?: Maybe<Audio_Measurements_Master_Files_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Audio_Measurements_Master_Files_Stddev_Samp_Order_By>;
  sum?: Maybe<Audio_Measurements_Master_Files_Sum_Order_By>;
  var_pop?: Maybe<Audio_Measurements_Master_Files_Var_Pop_Order_By>;
  var_samp?: Maybe<Audio_Measurements_Master_Files_Var_Samp_Order_By>;
  variance?: Maybe<Audio_Measurements_Master_Files_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Audio_Measurements_Master_Files_Avg_Fields = {
  __typename?: 'audio_measurements_master_files_avg_fields';
  chirp_start_freq?: Maybe<Scalars['Float']>;
  chirp_stop_freq?: Maybe<Scalars['Float']>;
  num_channels?: Maybe<Scalars['Float']>;
  sample_freq?: Maybe<Scalars['Float']>;
  signal_amplitude?: Maybe<Scalars['Float']>;
  single_chirp_duration?: Maybe<Scalars['Float']>;
  total_chirp_duration?: Maybe<Scalars['Float']>;
  zero_padding_head_duration?: Maybe<Scalars['Float']>;
  zero_padding_tail_duration?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "audio_measurements.master_files" */
export type Audio_Measurements_Master_Files_Avg_Order_By = {
  chirp_start_freq?: Maybe<Order_By>;
  chirp_stop_freq?: Maybe<Order_By>;
  num_channels?: Maybe<Order_By>;
  sample_freq?: Maybe<Order_By>;
  signal_amplitude?: Maybe<Order_By>;
  single_chirp_duration?: Maybe<Order_By>;
  total_chirp_duration?: Maybe<Order_By>;
  zero_padding_head_duration?: Maybe<Order_By>;
  zero_padding_tail_duration?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "audio_measurements.master_files". All fields are combined with a logical 'AND'. */
export type Audio_Measurements_Master_Files_Bool_Exp = {
  _and?: Maybe<Array<Audio_Measurements_Master_Files_Bool_Exp>>;
  _not?: Maybe<Audio_Measurements_Master_Files_Bool_Exp>;
  _or?: Maybe<Array<Audio_Measurements_Master_Files_Bool_Exp>>;
  chirp_start_freq?: Maybe<Int_Comparison_Exp>;
  chirp_stop_freq?: Maybe<Int_Comparison_Exp>;
  class_name?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  data_type?: Maybe<Audio_Measurements_Data_Types_Bool_Exp>;
  data_type_id?: Maybe<String_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  num_channels?: Maybe<Smallint_Comparison_Exp>;
  package_version?: Maybe<String_Comparison_Exp>;
  recordings?: Maybe<Audio_Measurements_Recordings_Bool_Exp>;
  sample_freq?: Maybe<Int_Comparison_Exp>;
  signal_amplitude?: Maybe<Numeric_Comparison_Exp>;
  single_chirp_duration?: Maybe<Int_Comparison_Exp>;
  total_chirp_duration?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  zero_padding_head_duration?: Maybe<Int_Comparison_Exp>;
  zero_padding_tail_duration?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "audio_measurements.master_files" */
export enum Audio_Measurements_Master_Files_Constraint {
  /** unique or primary key constraint on columns "num_channels", "package_version", "zero_padding_tail_duration", "chirp_stop_freq", "single_chirp_duration", "class_name", "chirp_start_freq", "sample_freq", "total_chirp_duration", "data_type_id", "zero_padding_head_duration", "signal_amplitude" */
  AudioMeasurementsMasterFilesUnique = 'audio_measurements_master_files_unique',
  /** unique or primary key constraint on columns "id" */
  MasterFilesPkey = 'master_files_pkey'
}

/** input type for incrementing numeric columns in table "audio_measurements.master_files" */
export type Audio_Measurements_Master_Files_Inc_Input = {
  chirp_start_freq?: Maybe<Scalars['Int']>;
  chirp_stop_freq?: Maybe<Scalars['Int']>;
  num_channels?: Maybe<Scalars['smallint']>;
  sample_freq?: Maybe<Scalars['Int']>;
  signal_amplitude?: Maybe<Scalars['numeric']>;
  single_chirp_duration?: Maybe<Scalars['Int']>;
  total_chirp_duration?: Maybe<Scalars['Int']>;
  zero_padding_head_duration?: Maybe<Scalars['Int']>;
  zero_padding_tail_duration?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "audio_measurements.master_files" */
export type Audio_Measurements_Master_Files_Insert_Input = {
  chirp_start_freq?: Maybe<Scalars['Int']>;
  chirp_stop_freq?: Maybe<Scalars['Int']>;
  class_name?: Maybe<Scalars['String']>;
  data_type_id?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  num_channels?: Maybe<Scalars['smallint']>;
  package_version?: Maybe<Scalars['String']>;
  recordings?: Maybe<Audio_Measurements_Recordings_Arr_Rel_Insert_Input>;
  sample_freq?: Maybe<Scalars['Int']>;
  signal_amplitude?: Maybe<Scalars['numeric']>;
  single_chirp_duration?: Maybe<Scalars['Int']>;
  total_chirp_duration?: Maybe<Scalars['Int']>;
  zero_padding_head_duration?: Maybe<Scalars['Int']>;
  zero_padding_tail_duration?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Audio_Measurements_Master_Files_Max_Fields = {
  __typename?: 'audio_measurements_master_files_max_fields';
  chirp_start_freq?: Maybe<Scalars['Int']>;
  chirp_stop_freq?: Maybe<Scalars['Int']>;
  class_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  data_type_id?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  num_channels?: Maybe<Scalars['smallint']>;
  package_version?: Maybe<Scalars['String']>;
  sample_freq?: Maybe<Scalars['Int']>;
  signal_amplitude?: Maybe<Scalars['numeric']>;
  single_chirp_duration?: Maybe<Scalars['Int']>;
  total_chirp_duration?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zero_padding_head_duration?: Maybe<Scalars['Int']>;
  zero_padding_tail_duration?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "audio_measurements.master_files" */
export type Audio_Measurements_Master_Files_Max_Order_By = {
  chirp_start_freq?: Maybe<Order_By>;
  chirp_stop_freq?: Maybe<Order_By>;
  class_name?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  data_type_id?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  num_channels?: Maybe<Order_By>;
  package_version?: Maybe<Order_By>;
  sample_freq?: Maybe<Order_By>;
  signal_amplitude?: Maybe<Order_By>;
  single_chirp_duration?: Maybe<Order_By>;
  total_chirp_duration?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  zero_padding_head_duration?: Maybe<Order_By>;
  zero_padding_tail_duration?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Audio_Measurements_Master_Files_Min_Fields = {
  __typename?: 'audio_measurements_master_files_min_fields';
  chirp_start_freq?: Maybe<Scalars['Int']>;
  chirp_stop_freq?: Maybe<Scalars['Int']>;
  class_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  data_type_id?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  num_channels?: Maybe<Scalars['smallint']>;
  package_version?: Maybe<Scalars['String']>;
  sample_freq?: Maybe<Scalars['Int']>;
  signal_amplitude?: Maybe<Scalars['numeric']>;
  single_chirp_duration?: Maybe<Scalars['Int']>;
  total_chirp_duration?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zero_padding_head_duration?: Maybe<Scalars['Int']>;
  zero_padding_tail_duration?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "audio_measurements.master_files" */
export type Audio_Measurements_Master_Files_Min_Order_By = {
  chirp_start_freq?: Maybe<Order_By>;
  chirp_stop_freq?: Maybe<Order_By>;
  class_name?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  data_type_id?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  num_channels?: Maybe<Order_By>;
  package_version?: Maybe<Order_By>;
  sample_freq?: Maybe<Order_By>;
  signal_amplitude?: Maybe<Order_By>;
  single_chirp_duration?: Maybe<Order_By>;
  total_chirp_duration?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  zero_padding_head_duration?: Maybe<Order_By>;
  zero_padding_tail_duration?: Maybe<Order_By>;
};

/** response of any mutation on the table "audio_measurements.master_files" */
export type Audio_Measurements_Master_Files_Mutation_Response = {
  __typename?: 'audio_measurements_master_files_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Audio_Measurements_Master_Files>;
};

/** input type for inserting object relation for remote table "audio_measurements.master_files" */
export type Audio_Measurements_Master_Files_Obj_Rel_Insert_Input = {
  data: Audio_Measurements_Master_Files_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Audio_Measurements_Master_Files_On_Conflict>;
};

/** on_conflict condition type for table "audio_measurements.master_files" */
export type Audio_Measurements_Master_Files_On_Conflict = {
  constraint: Audio_Measurements_Master_Files_Constraint;
  update_columns?: Array<Audio_Measurements_Master_Files_Update_Column>;
  where?: Maybe<Audio_Measurements_Master_Files_Bool_Exp>;
};

/** Ordering options when selecting data from "audio_measurements.master_files". */
export type Audio_Measurements_Master_Files_Order_By = {
  chirp_start_freq?: Maybe<Order_By>;
  chirp_stop_freq?: Maybe<Order_By>;
  class_name?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  data_type?: Maybe<Audio_Measurements_Data_Types_Order_By>;
  data_type_id?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  num_channels?: Maybe<Order_By>;
  package_version?: Maybe<Order_By>;
  recordings_aggregate?: Maybe<Audio_Measurements_Recordings_Aggregate_Order_By>;
  sample_freq?: Maybe<Order_By>;
  signal_amplitude?: Maybe<Order_By>;
  single_chirp_duration?: Maybe<Order_By>;
  total_chirp_duration?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  zero_padding_head_duration?: Maybe<Order_By>;
  zero_padding_tail_duration?: Maybe<Order_By>;
};

/** primary key columns input for table: audio_measurements.master_files */
export type Audio_Measurements_Master_Files_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "audio_measurements.master_files" */
export enum Audio_Measurements_Master_Files_Select_Column {
  /** column name */
  ChirpStartFreq = 'chirp_start_freq',
  /** column name */
  ChirpStopFreq = 'chirp_stop_freq',
  /** column name */
  ClassName = 'class_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DataTypeId = 'data_type_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  NumChannels = 'num_channels',
  /** column name */
  PackageVersion = 'package_version',
  /** column name */
  SampleFreq = 'sample_freq',
  /** column name */
  SignalAmplitude = 'signal_amplitude',
  /** column name */
  SingleChirpDuration = 'single_chirp_duration',
  /** column name */
  TotalChirpDuration = 'total_chirp_duration',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZeroPaddingHeadDuration = 'zero_padding_head_duration',
  /** column name */
  ZeroPaddingTailDuration = 'zero_padding_tail_duration'
}

/** input type for updating data in table "audio_measurements.master_files" */
export type Audio_Measurements_Master_Files_Set_Input = {
  chirp_start_freq?: Maybe<Scalars['Int']>;
  chirp_stop_freq?: Maybe<Scalars['Int']>;
  class_name?: Maybe<Scalars['String']>;
  data_type_id?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  num_channels?: Maybe<Scalars['smallint']>;
  package_version?: Maybe<Scalars['String']>;
  sample_freq?: Maybe<Scalars['Int']>;
  signal_amplitude?: Maybe<Scalars['numeric']>;
  single_chirp_duration?: Maybe<Scalars['Int']>;
  total_chirp_duration?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zero_padding_head_duration?: Maybe<Scalars['Int']>;
  zero_padding_tail_duration?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Audio_Measurements_Master_Files_Stddev_Fields = {
  __typename?: 'audio_measurements_master_files_stddev_fields';
  chirp_start_freq?: Maybe<Scalars['Float']>;
  chirp_stop_freq?: Maybe<Scalars['Float']>;
  num_channels?: Maybe<Scalars['Float']>;
  sample_freq?: Maybe<Scalars['Float']>;
  signal_amplitude?: Maybe<Scalars['Float']>;
  single_chirp_duration?: Maybe<Scalars['Float']>;
  total_chirp_duration?: Maybe<Scalars['Float']>;
  zero_padding_head_duration?: Maybe<Scalars['Float']>;
  zero_padding_tail_duration?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "audio_measurements.master_files" */
export type Audio_Measurements_Master_Files_Stddev_Order_By = {
  chirp_start_freq?: Maybe<Order_By>;
  chirp_stop_freq?: Maybe<Order_By>;
  num_channels?: Maybe<Order_By>;
  sample_freq?: Maybe<Order_By>;
  signal_amplitude?: Maybe<Order_By>;
  single_chirp_duration?: Maybe<Order_By>;
  total_chirp_duration?: Maybe<Order_By>;
  zero_padding_head_duration?: Maybe<Order_By>;
  zero_padding_tail_duration?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Audio_Measurements_Master_Files_Stddev_Pop_Fields = {
  __typename?: 'audio_measurements_master_files_stddev_pop_fields';
  chirp_start_freq?: Maybe<Scalars['Float']>;
  chirp_stop_freq?: Maybe<Scalars['Float']>;
  num_channels?: Maybe<Scalars['Float']>;
  sample_freq?: Maybe<Scalars['Float']>;
  signal_amplitude?: Maybe<Scalars['Float']>;
  single_chirp_duration?: Maybe<Scalars['Float']>;
  total_chirp_duration?: Maybe<Scalars['Float']>;
  zero_padding_head_duration?: Maybe<Scalars['Float']>;
  zero_padding_tail_duration?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "audio_measurements.master_files" */
export type Audio_Measurements_Master_Files_Stddev_Pop_Order_By = {
  chirp_start_freq?: Maybe<Order_By>;
  chirp_stop_freq?: Maybe<Order_By>;
  num_channels?: Maybe<Order_By>;
  sample_freq?: Maybe<Order_By>;
  signal_amplitude?: Maybe<Order_By>;
  single_chirp_duration?: Maybe<Order_By>;
  total_chirp_duration?: Maybe<Order_By>;
  zero_padding_head_duration?: Maybe<Order_By>;
  zero_padding_tail_duration?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Audio_Measurements_Master_Files_Stddev_Samp_Fields = {
  __typename?: 'audio_measurements_master_files_stddev_samp_fields';
  chirp_start_freq?: Maybe<Scalars['Float']>;
  chirp_stop_freq?: Maybe<Scalars['Float']>;
  num_channels?: Maybe<Scalars['Float']>;
  sample_freq?: Maybe<Scalars['Float']>;
  signal_amplitude?: Maybe<Scalars['Float']>;
  single_chirp_duration?: Maybe<Scalars['Float']>;
  total_chirp_duration?: Maybe<Scalars['Float']>;
  zero_padding_head_duration?: Maybe<Scalars['Float']>;
  zero_padding_tail_duration?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "audio_measurements.master_files" */
export type Audio_Measurements_Master_Files_Stddev_Samp_Order_By = {
  chirp_start_freq?: Maybe<Order_By>;
  chirp_stop_freq?: Maybe<Order_By>;
  num_channels?: Maybe<Order_By>;
  sample_freq?: Maybe<Order_By>;
  signal_amplitude?: Maybe<Order_By>;
  single_chirp_duration?: Maybe<Order_By>;
  total_chirp_duration?: Maybe<Order_By>;
  zero_padding_head_duration?: Maybe<Order_By>;
  zero_padding_tail_duration?: Maybe<Order_By>;
};

/** Streaming cursor of the table "audio_measurements_master_files" */
export type Audio_Measurements_Master_Files_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audio_Measurements_Master_Files_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audio_Measurements_Master_Files_Stream_Cursor_Value_Input = {
  chirp_start_freq?: Maybe<Scalars['Int']>;
  chirp_stop_freq?: Maybe<Scalars['Int']>;
  class_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  data_type_id?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  num_channels?: Maybe<Scalars['smallint']>;
  package_version?: Maybe<Scalars['String']>;
  sample_freq?: Maybe<Scalars['Int']>;
  signal_amplitude?: Maybe<Scalars['numeric']>;
  single_chirp_duration?: Maybe<Scalars['Int']>;
  total_chirp_duration?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zero_padding_head_duration?: Maybe<Scalars['Int']>;
  zero_padding_tail_duration?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Audio_Measurements_Master_Files_Sum_Fields = {
  __typename?: 'audio_measurements_master_files_sum_fields';
  chirp_start_freq?: Maybe<Scalars['Int']>;
  chirp_stop_freq?: Maybe<Scalars['Int']>;
  num_channels?: Maybe<Scalars['smallint']>;
  sample_freq?: Maybe<Scalars['Int']>;
  signal_amplitude?: Maybe<Scalars['numeric']>;
  single_chirp_duration?: Maybe<Scalars['Int']>;
  total_chirp_duration?: Maybe<Scalars['Int']>;
  zero_padding_head_duration?: Maybe<Scalars['Int']>;
  zero_padding_tail_duration?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "audio_measurements.master_files" */
export type Audio_Measurements_Master_Files_Sum_Order_By = {
  chirp_start_freq?: Maybe<Order_By>;
  chirp_stop_freq?: Maybe<Order_By>;
  num_channels?: Maybe<Order_By>;
  sample_freq?: Maybe<Order_By>;
  signal_amplitude?: Maybe<Order_By>;
  single_chirp_duration?: Maybe<Order_By>;
  total_chirp_duration?: Maybe<Order_By>;
  zero_padding_head_duration?: Maybe<Order_By>;
  zero_padding_tail_duration?: Maybe<Order_By>;
};

/** update columns of table "audio_measurements.master_files" */
export enum Audio_Measurements_Master_Files_Update_Column {
  /** column name */
  ChirpStartFreq = 'chirp_start_freq',
  /** column name */
  ChirpStopFreq = 'chirp_stop_freq',
  /** column name */
  ClassName = 'class_name',
  /** column name */
  DataTypeId = 'data_type_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  NumChannels = 'num_channels',
  /** column name */
  PackageVersion = 'package_version',
  /** column name */
  SampleFreq = 'sample_freq',
  /** column name */
  SignalAmplitude = 'signal_amplitude',
  /** column name */
  SingleChirpDuration = 'single_chirp_duration',
  /** column name */
  TotalChirpDuration = 'total_chirp_duration',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZeroPaddingHeadDuration = 'zero_padding_head_duration',
  /** column name */
  ZeroPaddingTailDuration = 'zero_padding_tail_duration'
}

export type Audio_Measurements_Master_Files_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Audio_Measurements_Master_Files_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Audio_Measurements_Master_Files_Set_Input>;
  where: Audio_Measurements_Master_Files_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Audio_Measurements_Master_Files_Var_Pop_Fields = {
  __typename?: 'audio_measurements_master_files_var_pop_fields';
  chirp_start_freq?: Maybe<Scalars['Float']>;
  chirp_stop_freq?: Maybe<Scalars['Float']>;
  num_channels?: Maybe<Scalars['Float']>;
  sample_freq?: Maybe<Scalars['Float']>;
  signal_amplitude?: Maybe<Scalars['Float']>;
  single_chirp_duration?: Maybe<Scalars['Float']>;
  total_chirp_duration?: Maybe<Scalars['Float']>;
  zero_padding_head_duration?: Maybe<Scalars['Float']>;
  zero_padding_tail_duration?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "audio_measurements.master_files" */
export type Audio_Measurements_Master_Files_Var_Pop_Order_By = {
  chirp_start_freq?: Maybe<Order_By>;
  chirp_stop_freq?: Maybe<Order_By>;
  num_channels?: Maybe<Order_By>;
  sample_freq?: Maybe<Order_By>;
  signal_amplitude?: Maybe<Order_By>;
  single_chirp_duration?: Maybe<Order_By>;
  total_chirp_duration?: Maybe<Order_By>;
  zero_padding_head_duration?: Maybe<Order_By>;
  zero_padding_tail_duration?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Audio_Measurements_Master_Files_Var_Samp_Fields = {
  __typename?: 'audio_measurements_master_files_var_samp_fields';
  chirp_start_freq?: Maybe<Scalars['Float']>;
  chirp_stop_freq?: Maybe<Scalars['Float']>;
  num_channels?: Maybe<Scalars['Float']>;
  sample_freq?: Maybe<Scalars['Float']>;
  signal_amplitude?: Maybe<Scalars['Float']>;
  single_chirp_duration?: Maybe<Scalars['Float']>;
  total_chirp_duration?: Maybe<Scalars['Float']>;
  zero_padding_head_duration?: Maybe<Scalars['Float']>;
  zero_padding_tail_duration?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "audio_measurements.master_files" */
export type Audio_Measurements_Master_Files_Var_Samp_Order_By = {
  chirp_start_freq?: Maybe<Order_By>;
  chirp_stop_freq?: Maybe<Order_By>;
  num_channels?: Maybe<Order_By>;
  sample_freq?: Maybe<Order_By>;
  signal_amplitude?: Maybe<Order_By>;
  single_chirp_duration?: Maybe<Order_By>;
  total_chirp_duration?: Maybe<Order_By>;
  zero_padding_head_duration?: Maybe<Order_By>;
  zero_padding_tail_duration?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Audio_Measurements_Master_Files_Variance_Fields = {
  __typename?: 'audio_measurements_master_files_variance_fields';
  chirp_start_freq?: Maybe<Scalars['Float']>;
  chirp_stop_freq?: Maybe<Scalars['Float']>;
  num_channels?: Maybe<Scalars['Float']>;
  sample_freq?: Maybe<Scalars['Float']>;
  signal_amplitude?: Maybe<Scalars['Float']>;
  single_chirp_duration?: Maybe<Scalars['Float']>;
  total_chirp_duration?: Maybe<Scalars['Float']>;
  zero_padding_head_duration?: Maybe<Scalars['Float']>;
  zero_padding_tail_duration?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "audio_measurements.master_files" */
export type Audio_Measurements_Master_Files_Variance_Order_By = {
  chirp_start_freq?: Maybe<Order_By>;
  chirp_stop_freq?: Maybe<Order_By>;
  num_channels?: Maybe<Order_By>;
  sample_freq?: Maybe<Order_By>;
  signal_amplitude?: Maybe<Order_By>;
  single_chirp_duration?: Maybe<Order_By>;
  total_chirp_duration?: Maybe<Order_By>;
  zero_padding_head_duration?: Maybe<Order_By>;
  zero_padding_tail_duration?: Maybe<Order_By>;
};

/** columns and relationships of "audio_measurements.orientations" */
export type Audio_Measurements_Orientations = {
  __typename?: 'audio_measurements_orientations';
  /** An array relationship */
  configurations: Array<Audio_Measurements_Configurations>;
  description: Scalars['String'];
  id: Scalars['uuid'];
};


/** columns and relationships of "audio_measurements.orientations" */
export type Audio_Measurements_OrientationsConfigurationsArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Configurations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Configurations_Order_By>>;
  where?: Maybe<Audio_Measurements_Configurations_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "audio_measurements.orientations". All fields are combined with a logical 'AND'. */
export type Audio_Measurements_Orientations_Bool_Exp = {
  _and?: Maybe<Array<Audio_Measurements_Orientations_Bool_Exp>>;
  _not?: Maybe<Audio_Measurements_Orientations_Bool_Exp>;
  _or?: Maybe<Array<Audio_Measurements_Orientations_Bool_Exp>>;
  configurations?: Maybe<Audio_Measurements_Configurations_Bool_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "audio_measurements.orientations" */
export enum Audio_Measurements_Orientations_Constraint {
  /** unique or primary key constraint on columns "description" */
  OrientationsDescriptionKey = 'orientations_description_key',
  /** unique or primary key constraint on columns "id" */
  OrientationsPkey = 'orientations_pkey'
}

/** input type for inserting data into table "audio_measurements.orientations" */
export type Audio_Measurements_Orientations_Insert_Input = {
  configurations?: Maybe<Audio_Measurements_Configurations_Arr_Rel_Insert_Input>;
  description?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "audio_measurements.orientations" */
export type Audio_Measurements_Orientations_Mutation_Response = {
  __typename?: 'audio_measurements_orientations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Audio_Measurements_Orientations>;
};

/** input type for inserting object relation for remote table "audio_measurements.orientations" */
export type Audio_Measurements_Orientations_Obj_Rel_Insert_Input = {
  data: Audio_Measurements_Orientations_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Audio_Measurements_Orientations_On_Conflict>;
};

/** on_conflict condition type for table "audio_measurements.orientations" */
export type Audio_Measurements_Orientations_On_Conflict = {
  constraint: Audio_Measurements_Orientations_Constraint;
  update_columns?: Array<Audio_Measurements_Orientations_Update_Column>;
  where?: Maybe<Audio_Measurements_Orientations_Bool_Exp>;
};

/** Ordering options when selecting data from "audio_measurements.orientations". */
export type Audio_Measurements_Orientations_Order_By = {
  configurations_aggregate?: Maybe<Audio_Measurements_Configurations_Aggregate_Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** primary key columns input for table: audio_measurements.orientations */
export type Audio_Measurements_Orientations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "audio_measurements.orientations" */
export enum Audio_Measurements_Orientations_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "audio_measurements.orientations" */
export type Audio_Measurements_Orientations_Set_Input = {
  description?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "audio_measurements_orientations" */
export type Audio_Measurements_Orientations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audio_Measurements_Orientations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audio_Measurements_Orientations_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "audio_measurements.orientations" */
export enum Audio_Measurements_Orientations_Update_Column {
  /** column name */
  Description = 'description'
}

export type Audio_Measurements_Orientations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Audio_Measurements_Orientations_Set_Input>;
  where: Audio_Measurements_Orientations_Bool_Exp;
};

/** columns and relationships of "audio_measurements.payload_recording" */
export type Audio_Measurements_Payload_Recording = {
  __typename?: 'audio_measurements_payload_recording';
  /** An object relationship */
  payload: Payloads;
  payload_id: Scalars['uuid'];
  /** An object relationship */
  recording: Audio_Measurements_Recordings;
  recording_id: Scalars['uuid'];
};

/** aggregated selection of "audio_measurements.payload_recording" */
export type Audio_Measurements_Payload_Recording_Aggregate = {
  __typename?: 'audio_measurements_payload_recording_aggregate';
  aggregate?: Maybe<Audio_Measurements_Payload_Recording_Aggregate_Fields>;
  nodes: Array<Audio_Measurements_Payload_Recording>;
};

/** aggregate fields of "audio_measurements.payload_recording" */
export type Audio_Measurements_Payload_Recording_Aggregate_Fields = {
  __typename?: 'audio_measurements_payload_recording_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Audio_Measurements_Payload_Recording_Max_Fields>;
  min?: Maybe<Audio_Measurements_Payload_Recording_Min_Fields>;
};


/** aggregate fields of "audio_measurements.payload_recording" */
export type Audio_Measurements_Payload_Recording_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Audio_Measurements_Payload_Recording_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "audio_measurements.payload_recording" */
export type Audio_Measurements_Payload_Recording_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Audio_Measurements_Payload_Recording_Max_Order_By>;
  min?: Maybe<Audio_Measurements_Payload_Recording_Min_Order_By>;
};

/** input type for inserting array relation for remote table "audio_measurements.payload_recording" */
export type Audio_Measurements_Payload_Recording_Arr_Rel_Insert_Input = {
  data: Array<Audio_Measurements_Payload_Recording_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Audio_Measurements_Payload_Recording_On_Conflict>;
};

/** Boolean expression to filter rows from the table "audio_measurements.payload_recording". All fields are combined with a logical 'AND'. */
export type Audio_Measurements_Payload_Recording_Bool_Exp = {
  _and?: Maybe<Array<Audio_Measurements_Payload_Recording_Bool_Exp>>;
  _not?: Maybe<Audio_Measurements_Payload_Recording_Bool_Exp>;
  _or?: Maybe<Array<Audio_Measurements_Payload_Recording_Bool_Exp>>;
  payload?: Maybe<Payloads_Bool_Exp>;
  payload_id?: Maybe<Uuid_Comparison_Exp>;
  recording?: Maybe<Audio_Measurements_Recordings_Bool_Exp>;
  recording_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "audio_measurements.payload_recording" */
export enum Audio_Measurements_Payload_Recording_Constraint {
  /** unique or primary key constraint on columns "payload_id", "recording_id" */
  PayloadRecordingPkey = 'payload_recording_pkey'
}

/** input type for inserting data into table "audio_measurements.payload_recording" */
export type Audio_Measurements_Payload_Recording_Insert_Input = {
  payload?: Maybe<Payloads_Obj_Rel_Insert_Input>;
  payload_id?: Maybe<Scalars['uuid']>;
  recording?: Maybe<Audio_Measurements_Recordings_Obj_Rel_Insert_Input>;
  recording_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Audio_Measurements_Payload_Recording_Max_Fields = {
  __typename?: 'audio_measurements_payload_recording_max_fields';
  payload_id?: Maybe<Scalars['uuid']>;
  recording_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "audio_measurements.payload_recording" */
export type Audio_Measurements_Payload_Recording_Max_Order_By = {
  payload_id?: Maybe<Order_By>;
  recording_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Audio_Measurements_Payload_Recording_Min_Fields = {
  __typename?: 'audio_measurements_payload_recording_min_fields';
  payload_id?: Maybe<Scalars['uuid']>;
  recording_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "audio_measurements.payload_recording" */
export type Audio_Measurements_Payload_Recording_Min_Order_By = {
  payload_id?: Maybe<Order_By>;
  recording_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "audio_measurements.payload_recording" */
export type Audio_Measurements_Payload_Recording_Mutation_Response = {
  __typename?: 'audio_measurements_payload_recording_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Audio_Measurements_Payload_Recording>;
};

/** on_conflict condition type for table "audio_measurements.payload_recording" */
export type Audio_Measurements_Payload_Recording_On_Conflict = {
  constraint: Audio_Measurements_Payload_Recording_Constraint;
  update_columns?: Array<Audio_Measurements_Payload_Recording_Update_Column>;
  where?: Maybe<Audio_Measurements_Payload_Recording_Bool_Exp>;
};

/** Ordering options when selecting data from "audio_measurements.payload_recording". */
export type Audio_Measurements_Payload_Recording_Order_By = {
  payload?: Maybe<Payloads_Order_By>;
  payload_id?: Maybe<Order_By>;
  recording?: Maybe<Audio_Measurements_Recordings_Order_By>;
  recording_id?: Maybe<Order_By>;
};

/** primary key columns input for table: audio_measurements.payload_recording */
export type Audio_Measurements_Payload_Recording_Pk_Columns_Input = {
  payload_id: Scalars['uuid'];
  recording_id: Scalars['uuid'];
};

/** select columns of table "audio_measurements.payload_recording" */
export enum Audio_Measurements_Payload_Recording_Select_Column {
  /** column name */
  PayloadId = 'payload_id',
  /** column name */
  RecordingId = 'recording_id'
}

/** input type for updating data in table "audio_measurements.payload_recording" */
export type Audio_Measurements_Payload_Recording_Set_Input = {
  payload_id?: Maybe<Scalars['uuid']>;
  recording_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "audio_measurements_payload_recording" */
export type Audio_Measurements_Payload_Recording_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audio_Measurements_Payload_Recording_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audio_Measurements_Payload_Recording_Stream_Cursor_Value_Input = {
  payload_id?: Maybe<Scalars['uuid']>;
  recording_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "audio_measurements.payload_recording" */
export enum Audio_Measurements_Payload_Recording_Update_Column {
  /** column name */
  PayloadId = 'payload_id',
  /** column name */
  RecordingId = 'recording_id'
}

export type Audio_Measurements_Payload_Recording_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Audio_Measurements_Payload_Recording_Set_Input>;
  where: Audio_Measurements_Payload_Recording_Bool_Exp;
};

/** columns and relationships of "audio_measurements.placements" */
export type Audio_Measurements_Placements = {
  __typename?: 'audio_measurements_placements';
  /** An array relationship */
  configurations: Array<Audio_Measurements_Configurations>;
  description: Scalars['String'];
  id: Scalars['uuid'];
};


/** columns and relationships of "audio_measurements.placements" */
export type Audio_Measurements_PlacementsConfigurationsArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Configurations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Configurations_Order_By>>;
  where?: Maybe<Audio_Measurements_Configurations_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "audio_measurements.placements". All fields are combined with a logical 'AND'. */
export type Audio_Measurements_Placements_Bool_Exp = {
  _and?: Maybe<Array<Audio_Measurements_Placements_Bool_Exp>>;
  _not?: Maybe<Audio_Measurements_Placements_Bool_Exp>;
  _or?: Maybe<Array<Audio_Measurements_Placements_Bool_Exp>>;
  configurations?: Maybe<Audio_Measurements_Configurations_Bool_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "audio_measurements.placements" */
export enum Audio_Measurements_Placements_Constraint {
  /** unique or primary key constraint on columns "description" */
  PlacementsDescriptionKey = 'placements_description_key',
  /** unique or primary key constraint on columns "id" */
  PlacementsPkey = 'placements_pkey'
}

/** input type for inserting data into table "audio_measurements.placements" */
export type Audio_Measurements_Placements_Insert_Input = {
  configurations?: Maybe<Audio_Measurements_Configurations_Arr_Rel_Insert_Input>;
  description?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "audio_measurements.placements" */
export type Audio_Measurements_Placements_Mutation_Response = {
  __typename?: 'audio_measurements_placements_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Audio_Measurements_Placements>;
};

/** input type for inserting object relation for remote table "audio_measurements.placements" */
export type Audio_Measurements_Placements_Obj_Rel_Insert_Input = {
  data: Audio_Measurements_Placements_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Audio_Measurements_Placements_On_Conflict>;
};

/** on_conflict condition type for table "audio_measurements.placements" */
export type Audio_Measurements_Placements_On_Conflict = {
  constraint: Audio_Measurements_Placements_Constraint;
  update_columns?: Array<Audio_Measurements_Placements_Update_Column>;
  where?: Maybe<Audio_Measurements_Placements_Bool_Exp>;
};

/** Ordering options when selecting data from "audio_measurements.placements". */
export type Audio_Measurements_Placements_Order_By = {
  configurations_aggregate?: Maybe<Audio_Measurements_Configurations_Aggregate_Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** primary key columns input for table: audio_measurements.placements */
export type Audio_Measurements_Placements_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "audio_measurements.placements" */
export enum Audio_Measurements_Placements_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "audio_measurements.placements" */
export type Audio_Measurements_Placements_Set_Input = {
  description?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "audio_measurements_placements" */
export type Audio_Measurements_Placements_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audio_Measurements_Placements_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audio_Measurements_Placements_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "audio_measurements.placements" */
export enum Audio_Measurements_Placements_Update_Column {
  /** column name */
  Description = 'description'
}

export type Audio_Measurements_Placements_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Audio_Measurements_Placements_Set_Input>;
  where: Audio_Measurements_Placements_Bool_Exp;
};

/** used to add tasks to projects, but restricted to the audio measurement context */
export type Audio_Measurements_Project_Tasks = {
  __typename?: 'audio_measurements_project_tasks';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  min_per_device: Scalars['Int'];
  priority: Scalars['Int'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
  /** An object relationship */
  task: Tasks;
  task_id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "audio_measurements.project_tasks" */
export type Audio_Measurements_Project_Tasks_Aggregate = {
  __typename?: 'audio_measurements_project_tasks_aggregate';
  aggregate?: Maybe<Audio_Measurements_Project_Tasks_Aggregate_Fields>;
  nodes: Array<Audio_Measurements_Project_Tasks>;
};

/** aggregate fields of "audio_measurements.project_tasks" */
export type Audio_Measurements_Project_Tasks_Aggregate_Fields = {
  __typename?: 'audio_measurements_project_tasks_aggregate_fields';
  avg?: Maybe<Audio_Measurements_Project_Tasks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Audio_Measurements_Project_Tasks_Max_Fields>;
  min?: Maybe<Audio_Measurements_Project_Tasks_Min_Fields>;
  stddev?: Maybe<Audio_Measurements_Project_Tasks_Stddev_Fields>;
  stddev_pop?: Maybe<Audio_Measurements_Project_Tasks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Audio_Measurements_Project_Tasks_Stddev_Samp_Fields>;
  sum?: Maybe<Audio_Measurements_Project_Tasks_Sum_Fields>;
  var_pop?: Maybe<Audio_Measurements_Project_Tasks_Var_Pop_Fields>;
  var_samp?: Maybe<Audio_Measurements_Project_Tasks_Var_Samp_Fields>;
  variance?: Maybe<Audio_Measurements_Project_Tasks_Variance_Fields>;
};


/** aggregate fields of "audio_measurements.project_tasks" */
export type Audio_Measurements_Project_Tasks_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Audio_Measurements_Project_Tasks_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "audio_measurements.project_tasks" */
export type Audio_Measurements_Project_Tasks_Aggregate_Order_By = {
  avg?: Maybe<Audio_Measurements_Project_Tasks_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Audio_Measurements_Project_Tasks_Max_Order_By>;
  min?: Maybe<Audio_Measurements_Project_Tasks_Min_Order_By>;
  stddev?: Maybe<Audio_Measurements_Project_Tasks_Stddev_Order_By>;
  stddev_pop?: Maybe<Audio_Measurements_Project_Tasks_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Audio_Measurements_Project_Tasks_Stddev_Samp_Order_By>;
  sum?: Maybe<Audio_Measurements_Project_Tasks_Sum_Order_By>;
  var_pop?: Maybe<Audio_Measurements_Project_Tasks_Var_Pop_Order_By>;
  var_samp?: Maybe<Audio_Measurements_Project_Tasks_Var_Samp_Order_By>;
  variance?: Maybe<Audio_Measurements_Project_Tasks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "audio_measurements.project_tasks" */
export type Audio_Measurements_Project_Tasks_Arr_Rel_Insert_Input = {
  data: Array<Audio_Measurements_Project_Tasks_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Audio_Measurements_Project_Tasks_On_Conflict>;
};

/** aggregate avg on columns */
export type Audio_Measurements_Project_Tasks_Avg_Fields = {
  __typename?: 'audio_measurements_project_tasks_avg_fields';
  min_per_device?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "audio_measurements.project_tasks" */
export type Audio_Measurements_Project_Tasks_Avg_Order_By = {
  min_per_device?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "audio_measurements.project_tasks". All fields are combined with a logical 'AND'. */
export type Audio_Measurements_Project_Tasks_Bool_Exp = {
  _and?: Maybe<Array<Audio_Measurements_Project_Tasks_Bool_Exp>>;
  _not?: Maybe<Audio_Measurements_Project_Tasks_Bool_Exp>;
  _or?: Maybe<Array<Audio_Measurements_Project_Tasks_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  min_per_device?: Maybe<Int_Comparison_Exp>;
  priority?: Maybe<Int_Comparison_Exp>;
  project?: Maybe<Projects_Bool_Exp>;
  project_id?: Maybe<Uuid_Comparison_Exp>;
  task?: Maybe<Tasks_Bool_Exp>;
  task_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "audio_measurements.project_tasks" */
export enum Audio_Measurements_Project_Tasks_Constraint {
  /** unique or primary key constraint on columns "project_id", "task_id" */
  ProjectTasksPkey = 'project_tasks_pkey'
}

/** input type for incrementing numeric columns in table "audio_measurements.project_tasks" */
export type Audio_Measurements_Project_Tasks_Inc_Input = {
  min_per_device?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "audio_measurements.project_tasks" */
export type Audio_Measurements_Project_Tasks_Insert_Input = {
  min_per_device?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  project?: Maybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: Maybe<Scalars['uuid']>;
  task?: Maybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Audio_Measurements_Project_Tasks_Max_Fields = {
  __typename?: 'audio_measurements_project_tasks_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  min_per_device?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "audio_measurements.project_tasks" */
export type Audio_Measurements_Project_Tasks_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  min_per_device?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Audio_Measurements_Project_Tasks_Min_Fields = {
  __typename?: 'audio_measurements_project_tasks_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  min_per_device?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "audio_measurements.project_tasks" */
export type Audio_Measurements_Project_Tasks_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  min_per_device?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "audio_measurements.project_tasks" */
export type Audio_Measurements_Project_Tasks_Mutation_Response = {
  __typename?: 'audio_measurements_project_tasks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Audio_Measurements_Project_Tasks>;
};

/** on_conflict condition type for table "audio_measurements.project_tasks" */
export type Audio_Measurements_Project_Tasks_On_Conflict = {
  constraint: Audio_Measurements_Project_Tasks_Constraint;
  update_columns?: Array<Audio_Measurements_Project_Tasks_Update_Column>;
  where?: Maybe<Audio_Measurements_Project_Tasks_Bool_Exp>;
};

/** Ordering options when selecting data from "audio_measurements.project_tasks". */
export type Audio_Measurements_Project_Tasks_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  min_per_device?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  project?: Maybe<Projects_Order_By>;
  project_id?: Maybe<Order_By>;
  task?: Maybe<Tasks_Order_By>;
  task_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: audio_measurements.project_tasks */
export type Audio_Measurements_Project_Tasks_Pk_Columns_Input = {
  project_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};

/** select columns of table "audio_measurements.project_tasks" */
export enum Audio_Measurements_Project_Tasks_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  MinPerDevice = 'min_per_device',
  /** column name */
  Priority = 'priority',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "audio_measurements.project_tasks" */
export type Audio_Measurements_Project_Tasks_Set_Input = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  min_per_device?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Audio_Measurements_Project_Tasks_Stddev_Fields = {
  __typename?: 'audio_measurements_project_tasks_stddev_fields';
  min_per_device?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "audio_measurements.project_tasks" */
export type Audio_Measurements_Project_Tasks_Stddev_Order_By = {
  min_per_device?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Audio_Measurements_Project_Tasks_Stddev_Pop_Fields = {
  __typename?: 'audio_measurements_project_tasks_stddev_pop_fields';
  min_per_device?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "audio_measurements.project_tasks" */
export type Audio_Measurements_Project_Tasks_Stddev_Pop_Order_By = {
  min_per_device?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Audio_Measurements_Project_Tasks_Stddev_Samp_Fields = {
  __typename?: 'audio_measurements_project_tasks_stddev_samp_fields';
  min_per_device?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "audio_measurements.project_tasks" */
export type Audio_Measurements_Project_Tasks_Stddev_Samp_Order_By = {
  min_per_device?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
};

/** Streaming cursor of the table "audio_measurements_project_tasks" */
export type Audio_Measurements_Project_Tasks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audio_Measurements_Project_Tasks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audio_Measurements_Project_Tasks_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  min_per_device?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Audio_Measurements_Project_Tasks_Sum_Fields = {
  __typename?: 'audio_measurements_project_tasks_sum_fields';
  min_per_device?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "audio_measurements.project_tasks" */
export type Audio_Measurements_Project_Tasks_Sum_Order_By = {
  min_per_device?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
};

/** update columns of table "audio_measurements.project_tasks" */
export enum Audio_Measurements_Project_Tasks_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  MinPerDevice = 'min_per_device',
  /** column name */
  Priority = 'priority'
}

export type Audio_Measurements_Project_Tasks_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Audio_Measurements_Project_Tasks_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Audio_Measurements_Project_Tasks_Set_Input>;
  where: Audio_Measurements_Project_Tasks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Audio_Measurements_Project_Tasks_Var_Pop_Fields = {
  __typename?: 'audio_measurements_project_tasks_var_pop_fields';
  min_per_device?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "audio_measurements.project_tasks" */
export type Audio_Measurements_Project_Tasks_Var_Pop_Order_By = {
  min_per_device?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Audio_Measurements_Project_Tasks_Var_Samp_Fields = {
  __typename?: 'audio_measurements_project_tasks_var_samp_fields';
  min_per_device?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "audio_measurements.project_tasks" */
export type Audio_Measurements_Project_Tasks_Var_Samp_Order_By = {
  min_per_device?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Audio_Measurements_Project_Tasks_Variance_Fields = {
  __typename?: 'audio_measurements_project_tasks_variance_fields';
  min_per_device?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "audio_measurements.project_tasks" */
export type Audio_Measurements_Project_Tasks_Variance_Order_By = {
  min_per_device?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
};

/** columns and relationships of "audio_measurements.recordings" */
export type Audio_Measurements_Recordings = {
  __typename?: 'audio_measurements_recordings';
  calibration_hash_codes?: Maybe<Scalars['String']>;
  cmd_arguments: Scalars['json'];
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  device: Devices;
  device_id: Scalars['uuid'];
  /** An object relationship */
  firmware: Firmware;
  firmware_id: Scalars['uuid'];
  id: Scalars['uuid'];
  is_calibration_recording?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  master_file: Audio_Measurements_Master_Files;
  master_file_id: Scalars['uuid'];
  pad_state?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  payload_recordings: Array<Audio_Measurements_Payload_Recording>;
  /** An aggregate relationship */
  payload_recordings_aggregate: Audio_Measurements_Payload_Recording_Aggregate;
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
  recorded_at: Scalars['timestamptz'];
  spl_offset?: Maybe<Scalars['Float']>;
  /** An object relationship */
  stage?: Maybe<Audio_Measurements_Stages>;
  stage_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  task: Tasks;
  task_id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};


/** columns and relationships of "audio_measurements.recordings" */
export type Audio_Measurements_RecordingsCmd_ArgumentsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "audio_measurements.recordings" */
export type Audio_Measurements_RecordingsPayload_RecordingsArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Payload_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Payload_Recording_Order_By>>;
  where?: Maybe<Audio_Measurements_Payload_Recording_Bool_Exp>;
};


/** columns and relationships of "audio_measurements.recordings" */
export type Audio_Measurements_RecordingsPayload_Recordings_AggregateArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Payload_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Payload_Recording_Order_By>>;
  where?: Maybe<Audio_Measurements_Payload_Recording_Bool_Exp>;
};

/** aggregated selection of "audio_measurements.recordings" */
export type Audio_Measurements_Recordings_Aggregate = {
  __typename?: 'audio_measurements_recordings_aggregate';
  aggregate?: Maybe<Audio_Measurements_Recordings_Aggregate_Fields>;
  nodes: Array<Audio_Measurements_Recordings>;
};

/** aggregate fields of "audio_measurements.recordings" */
export type Audio_Measurements_Recordings_Aggregate_Fields = {
  __typename?: 'audio_measurements_recordings_aggregate_fields';
  avg?: Maybe<Audio_Measurements_Recordings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Audio_Measurements_Recordings_Max_Fields>;
  min?: Maybe<Audio_Measurements_Recordings_Min_Fields>;
  stddev?: Maybe<Audio_Measurements_Recordings_Stddev_Fields>;
  stddev_pop?: Maybe<Audio_Measurements_Recordings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Audio_Measurements_Recordings_Stddev_Samp_Fields>;
  sum?: Maybe<Audio_Measurements_Recordings_Sum_Fields>;
  var_pop?: Maybe<Audio_Measurements_Recordings_Var_Pop_Fields>;
  var_samp?: Maybe<Audio_Measurements_Recordings_Var_Samp_Fields>;
  variance?: Maybe<Audio_Measurements_Recordings_Variance_Fields>;
};


/** aggregate fields of "audio_measurements.recordings" */
export type Audio_Measurements_Recordings_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Audio_Measurements_Recordings_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "audio_measurements.recordings" */
export type Audio_Measurements_Recordings_Aggregate_Order_By = {
  avg?: Maybe<Audio_Measurements_Recordings_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Audio_Measurements_Recordings_Max_Order_By>;
  min?: Maybe<Audio_Measurements_Recordings_Min_Order_By>;
  stddev?: Maybe<Audio_Measurements_Recordings_Stddev_Order_By>;
  stddev_pop?: Maybe<Audio_Measurements_Recordings_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Audio_Measurements_Recordings_Stddev_Samp_Order_By>;
  sum?: Maybe<Audio_Measurements_Recordings_Sum_Order_By>;
  var_pop?: Maybe<Audio_Measurements_Recordings_Var_Pop_Order_By>;
  var_samp?: Maybe<Audio_Measurements_Recordings_Var_Samp_Order_By>;
  variance?: Maybe<Audio_Measurements_Recordings_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "audio_measurements.recordings" */
export type Audio_Measurements_Recordings_Arr_Rel_Insert_Input = {
  data: Array<Audio_Measurements_Recordings_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Audio_Measurements_Recordings_On_Conflict>;
};

/** aggregate avg on columns */
export type Audio_Measurements_Recordings_Avg_Fields = {
  __typename?: 'audio_measurements_recordings_avg_fields';
  spl_offset?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "audio_measurements.recordings" */
export type Audio_Measurements_Recordings_Avg_Order_By = {
  spl_offset?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "audio_measurements.recordings". All fields are combined with a logical 'AND'. */
export type Audio_Measurements_Recordings_Bool_Exp = {
  _and?: Maybe<Array<Audio_Measurements_Recordings_Bool_Exp>>;
  _not?: Maybe<Audio_Measurements_Recordings_Bool_Exp>;
  _or?: Maybe<Array<Audio_Measurements_Recordings_Bool_Exp>>;
  calibration_hash_codes?: Maybe<String_Comparison_Exp>;
  cmd_arguments?: Maybe<Json_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  device?: Maybe<Devices_Bool_Exp>;
  device_id?: Maybe<Uuid_Comparison_Exp>;
  firmware?: Maybe<Firmware_Bool_Exp>;
  firmware_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_calibration_recording?: Maybe<Boolean_Comparison_Exp>;
  master_file?: Maybe<Audio_Measurements_Master_Files_Bool_Exp>;
  master_file_id?: Maybe<Uuid_Comparison_Exp>;
  pad_state?: Maybe<Boolean_Comparison_Exp>;
  payload_recordings?: Maybe<Audio_Measurements_Payload_Recording_Bool_Exp>;
  project?: Maybe<Projects_Bool_Exp>;
  project_id?: Maybe<Uuid_Comparison_Exp>;
  recorded_at?: Maybe<Timestamptz_Comparison_Exp>;
  spl_offset?: Maybe<Float_Comparison_Exp>;
  stage?: Maybe<Audio_Measurements_Stages_Bool_Exp>;
  stage_id?: Maybe<Uuid_Comparison_Exp>;
  task?: Maybe<Tasks_Bool_Exp>;
  task_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "audio_measurements.recordings" */
export enum Audio_Measurements_Recordings_Constraint {
  /** unique or primary key constraint on columns "id" */
  RecordingsPkey = 'recordings_pkey',
  /** unique or primary key constraint on columns "recorded_at", "device_id" */
  RecordingsRecordedAtDeviceIdKey = 'recordings_recorded_at_device_id_key'
}

/** input type for incrementing numeric columns in table "audio_measurements.recordings" */
export type Audio_Measurements_Recordings_Inc_Input = {
  spl_offset?: Maybe<Scalars['Float']>;
};

/** input type for inserting data into table "audio_measurements.recordings" */
export type Audio_Measurements_Recordings_Insert_Input = {
  calibration_hash_codes?: Maybe<Scalars['String']>;
  cmd_arguments?: Maybe<Scalars['json']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  device?: Maybe<Devices_Obj_Rel_Insert_Input>;
  device_id?: Maybe<Scalars['uuid']>;
  firmware?: Maybe<Firmware_Obj_Rel_Insert_Input>;
  firmware_id?: Maybe<Scalars['uuid']>;
  is_calibration_recording?: Maybe<Scalars['Boolean']>;
  master_file?: Maybe<Audio_Measurements_Master_Files_Obj_Rel_Insert_Input>;
  master_file_id?: Maybe<Scalars['uuid']>;
  pad_state?: Maybe<Scalars['Boolean']>;
  payload_recordings?: Maybe<Audio_Measurements_Payload_Recording_Arr_Rel_Insert_Input>;
  project?: Maybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: Maybe<Scalars['uuid']>;
  recorded_at?: Maybe<Scalars['timestamptz']>;
  spl_offset?: Maybe<Scalars['Float']>;
  stage?: Maybe<Audio_Measurements_Stages_Obj_Rel_Insert_Input>;
  stage_id?: Maybe<Scalars['uuid']>;
  task?: Maybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: Maybe<Scalars['uuid']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Audio_Measurements_Recordings_Max_Fields = {
  __typename?: 'audio_measurements_recordings_max_fields';
  calibration_hash_codes?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  device_id?: Maybe<Scalars['uuid']>;
  firmware_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  master_file_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  recorded_at?: Maybe<Scalars['timestamptz']>;
  spl_offset?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "audio_measurements.recordings" */
export type Audio_Measurements_Recordings_Max_Order_By = {
  calibration_hash_codes?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  device_id?: Maybe<Order_By>;
  firmware_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  master_file_id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  recorded_at?: Maybe<Order_By>;
  spl_offset?: Maybe<Order_By>;
  stage_id?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Audio_Measurements_Recordings_Min_Fields = {
  __typename?: 'audio_measurements_recordings_min_fields';
  calibration_hash_codes?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  device_id?: Maybe<Scalars['uuid']>;
  firmware_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  master_file_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  recorded_at?: Maybe<Scalars['timestamptz']>;
  spl_offset?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "audio_measurements.recordings" */
export type Audio_Measurements_Recordings_Min_Order_By = {
  calibration_hash_codes?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  device_id?: Maybe<Order_By>;
  firmware_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  master_file_id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  recorded_at?: Maybe<Order_By>;
  spl_offset?: Maybe<Order_By>;
  stage_id?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "audio_measurements.recordings" */
export type Audio_Measurements_Recordings_Mutation_Response = {
  __typename?: 'audio_measurements_recordings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Audio_Measurements_Recordings>;
};

/** input type for inserting object relation for remote table "audio_measurements.recordings" */
export type Audio_Measurements_Recordings_Obj_Rel_Insert_Input = {
  data: Audio_Measurements_Recordings_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Audio_Measurements_Recordings_On_Conflict>;
};

/** on_conflict condition type for table "audio_measurements.recordings" */
export type Audio_Measurements_Recordings_On_Conflict = {
  constraint: Audio_Measurements_Recordings_Constraint;
  update_columns?: Array<Audio_Measurements_Recordings_Update_Column>;
  where?: Maybe<Audio_Measurements_Recordings_Bool_Exp>;
};

/** Ordering options when selecting data from "audio_measurements.recordings". */
export type Audio_Measurements_Recordings_Order_By = {
  calibration_hash_codes?: Maybe<Order_By>;
  cmd_arguments?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  device?: Maybe<Devices_Order_By>;
  device_id?: Maybe<Order_By>;
  firmware?: Maybe<Firmware_Order_By>;
  firmware_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_calibration_recording?: Maybe<Order_By>;
  master_file?: Maybe<Audio_Measurements_Master_Files_Order_By>;
  master_file_id?: Maybe<Order_By>;
  pad_state?: Maybe<Order_By>;
  payload_recordings_aggregate?: Maybe<Audio_Measurements_Payload_Recording_Aggregate_Order_By>;
  project?: Maybe<Projects_Order_By>;
  project_id?: Maybe<Order_By>;
  recorded_at?: Maybe<Order_By>;
  spl_offset?: Maybe<Order_By>;
  stage?: Maybe<Audio_Measurements_Stages_Order_By>;
  stage_id?: Maybe<Order_By>;
  task?: Maybe<Tasks_Order_By>;
  task_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: audio_measurements.recordings */
export type Audio_Measurements_Recordings_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "audio_measurements.recordings" */
export enum Audio_Measurements_Recordings_Select_Column {
  /** column name */
  CalibrationHashCodes = 'calibration_hash_codes',
  /** column name */
  CmdArguments = 'cmd_arguments',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  FirmwareId = 'firmware_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsCalibrationRecording = 'is_calibration_recording',
  /** column name */
  MasterFileId = 'master_file_id',
  /** column name */
  PadState = 'pad_state',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  RecordedAt = 'recorded_at',
  /** column name */
  SplOffset = 'spl_offset',
  /** column name */
  StageId = 'stage_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "audio_measurements.recordings" */
export type Audio_Measurements_Recordings_Set_Input = {
  calibration_hash_codes?: Maybe<Scalars['String']>;
  cmd_arguments?: Maybe<Scalars['json']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  device_id?: Maybe<Scalars['uuid']>;
  firmware_id?: Maybe<Scalars['uuid']>;
  is_calibration_recording?: Maybe<Scalars['Boolean']>;
  master_file_id?: Maybe<Scalars['uuid']>;
  pad_state?: Maybe<Scalars['Boolean']>;
  project_id?: Maybe<Scalars['uuid']>;
  recorded_at?: Maybe<Scalars['timestamptz']>;
  spl_offset?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Audio_Measurements_Recordings_Stddev_Fields = {
  __typename?: 'audio_measurements_recordings_stddev_fields';
  spl_offset?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "audio_measurements.recordings" */
export type Audio_Measurements_Recordings_Stddev_Order_By = {
  spl_offset?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Audio_Measurements_Recordings_Stddev_Pop_Fields = {
  __typename?: 'audio_measurements_recordings_stddev_pop_fields';
  spl_offset?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "audio_measurements.recordings" */
export type Audio_Measurements_Recordings_Stddev_Pop_Order_By = {
  spl_offset?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Audio_Measurements_Recordings_Stddev_Samp_Fields = {
  __typename?: 'audio_measurements_recordings_stddev_samp_fields';
  spl_offset?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "audio_measurements.recordings" */
export type Audio_Measurements_Recordings_Stddev_Samp_Order_By = {
  spl_offset?: Maybe<Order_By>;
};

/** Streaming cursor of the table "audio_measurements_recordings" */
export type Audio_Measurements_Recordings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audio_Measurements_Recordings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audio_Measurements_Recordings_Stream_Cursor_Value_Input = {
  calibration_hash_codes?: Maybe<Scalars['String']>;
  cmd_arguments?: Maybe<Scalars['json']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  device_id?: Maybe<Scalars['uuid']>;
  firmware_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  is_calibration_recording?: Maybe<Scalars['Boolean']>;
  master_file_id?: Maybe<Scalars['uuid']>;
  pad_state?: Maybe<Scalars['Boolean']>;
  project_id?: Maybe<Scalars['uuid']>;
  recorded_at?: Maybe<Scalars['timestamptz']>;
  spl_offset?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Audio_Measurements_Recordings_Sum_Fields = {
  __typename?: 'audio_measurements_recordings_sum_fields';
  spl_offset?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "audio_measurements.recordings" */
export type Audio_Measurements_Recordings_Sum_Order_By = {
  spl_offset?: Maybe<Order_By>;
};

/** update columns of table "audio_measurements.recordings" */
export enum Audio_Measurements_Recordings_Update_Column {
  /** column name */
  CalibrationHashCodes = 'calibration_hash_codes',
  /** column name */
  CmdArguments = 'cmd_arguments',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  FirmwareId = 'firmware_id',
  /** column name */
  IsCalibrationRecording = 'is_calibration_recording',
  /** column name */
  MasterFileId = 'master_file_id',
  /** column name */
  PadState = 'pad_state',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  RecordedAt = 'recorded_at',
  /** column name */
  SplOffset = 'spl_offset',
  /** column name */
  StageId = 'stage_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UserId = 'user_id'
}

export type Audio_Measurements_Recordings_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Audio_Measurements_Recordings_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Audio_Measurements_Recordings_Set_Input>;
  where: Audio_Measurements_Recordings_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Audio_Measurements_Recordings_Var_Pop_Fields = {
  __typename?: 'audio_measurements_recordings_var_pop_fields';
  spl_offset?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "audio_measurements.recordings" */
export type Audio_Measurements_Recordings_Var_Pop_Order_By = {
  spl_offset?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Audio_Measurements_Recordings_Var_Samp_Fields = {
  __typename?: 'audio_measurements_recordings_var_samp_fields';
  spl_offset?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "audio_measurements.recordings" */
export type Audio_Measurements_Recordings_Var_Samp_Order_By = {
  spl_offset?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Audio_Measurements_Recordings_Variance_Fields = {
  __typename?: 'audio_measurements_recordings_variance_fields';
  spl_offset?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "audio_measurements.recordings" */
export type Audio_Measurements_Recordings_Variance_Order_By = {
  spl_offset?: Maybe<Order_By>;
};

/** columns and relationships of "audio_measurements.stages" */
export type Audio_Measurements_Stages = {
  __typename?: 'audio_measurements_stages';
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  recordings: Array<Audio_Measurements_Recordings>;
  /** An aggregate relationship */
  recordings_aggregate: Audio_Measurements_Recordings_Aggregate;
};


/** columns and relationships of "audio_measurements.stages" */
export type Audio_Measurements_StagesRecordingsArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Recordings_Order_By>>;
  where?: Maybe<Audio_Measurements_Recordings_Bool_Exp>;
};


/** columns and relationships of "audio_measurements.stages" */
export type Audio_Measurements_StagesRecordings_AggregateArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Recordings_Order_By>>;
  where?: Maybe<Audio_Measurements_Recordings_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "audio_measurements.stages". All fields are combined with a logical 'AND'. */
export type Audio_Measurements_Stages_Bool_Exp = {
  _and?: Maybe<Array<Audio_Measurements_Stages_Bool_Exp>>;
  _not?: Maybe<Audio_Measurements_Stages_Bool_Exp>;
  _or?: Maybe<Array<Audio_Measurements_Stages_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  recordings?: Maybe<Audio_Measurements_Recordings_Bool_Exp>;
};

/** unique or primary key constraints on table "audio_measurements.stages" */
export enum Audio_Measurements_Stages_Constraint {
  /** unique or primary key constraint on columns "name" */
  StagesNameKey = 'stages_name_key',
  /** unique or primary key constraint on columns "id" */
  StagesPkey = 'stages_pkey'
}

/** input type for inserting data into table "audio_measurements.stages" */
export type Audio_Measurements_Stages_Insert_Input = {
  name?: Maybe<Scalars['String']>;
  recordings?: Maybe<Audio_Measurements_Recordings_Arr_Rel_Insert_Input>;
};

/** response of any mutation on the table "audio_measurements.stages" */
export type Audio_Measurements_Stages_Mutation_Response = {
  __typename?: 'audio_measurements_stages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Audio_Measurements_Stages>;
};

/** input type for inserting object relation for remote table "audio_measurements.stages" */
export type Audio_Measurements_Stages_Obj_Rel_Insert_Input = {
  data: Audio_Measurements_Stages_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Audio_Measurements_Stages_On_Conflict>;
};

/** on_conflict condition type for table "audio_measurements.stages" */
export type Audio_Measurements_Stages_On_Conflict = {
  constraint: Audio_Measurements_Stages_Constraint;
  update_columns?: Array<Audio_Measurements_Stages_Update_Column>;
  where?: Maybe<Audio_Measurements_Stages_Bool_Exp>;
};

/** Ordering options when selecting data from "audio_measurements.stages". */
export type Audio_Measurements_Stages_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  recordings_aggregate?: Maybe<Audio_Measurements_Recordings_Aggregate_Order_By>;
};

/** primary key columns input for table: audio_measurements.stages */
export type Audio_Measurements_Stages_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "audio_measurements.stages" */
export enum Audio_Measurements_Stages_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "audio_measurements.stages" */
export type Audio_Measurements_Stages_Set_Input = {
  name?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "audio_measurements_stages" */
export type Audio_Measurements_Stages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audio_Measurements_Stages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audio_Measurements_Stages_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "audio_measurements.stages" */
export enum Audio_Measurements_Stages_Update_Column {
  /** column name */
  Name = 'name'
}

export type Audio_Measurements_Stages_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Audio_Measurements_Stages_Set_Input>;
  where: Audio_Measurements_Stages_Bool_Exp;
};


/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/** used to associate checklists with deliverables */
export type Bringup_Checklist_Deliverable = {
  __typename?: 'bringup_checklist_deliverable';
  /** An object relationship */
  checklist: Bringup_Checklists;
  checklist_id: Scalars['uuid'];
  /** An object relationship */
  deliverable: Deliverables;
  deliverable_id: Scalars['uuid'];
};

/** aggregated selection of "bringup.checklist_deliverable" */
export type Bringup_Checklist_Deliverable_Aggregate = {
  __typename?: 'bringup_checklist_deliverable_aggregate';
  aggregate?: Maybe<Bringup_Checklist_Deliverable_Aggregate_Fields>;
  nodes: Array<Bringup_Checklist_Deliverable>;
};

/** aggregate fields of "bringup.checklist_deliverable" */
export type Bringup_Checklist_Deliverable_Aggregate_Fields = {
  __typename?: 'bringup_checklist_deliverable_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Bringup_Checklist_Deliverable_Max_Fields>;
  min?: Maybe<Bringup_Checklist_Deliverable_Min_Fields>;
};


/** aggregate fields of "bringup.checklist_deliverable" */
export type Bringup_Checklist_Deliverable_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Bringup_Checklist_Deliverable_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "bringup.checklist_deliverable" */
export type Bringup_Checklist_Deliverable_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Bringup_Checklist_Deliverable_Max_Order_By>;
  min?: Maybe<Bringup_Checklist_Deliverable_Min_Order_By>;
};

/** input type for inserting array relation for remote table "bringup.checklist_deliverable" */
export type Bringup_Checklist_Deliverable_Arr_Rel_Insert_Input = {
  data: Array<Bringup_Checklist_Deliverable_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Bringup_Checklist_Deliverable_On_Conflict>;
};

/** Boolean expression to filter rows from the table "bringup.checklist_deliverable". All fields are combined with a logical 'AND'. */
export type Bringup_Checklist_Deliverable_Bool_Exp = {
  _and?: Maybe<Array<Bringup_Checklist_Deliverable_Bool_Exp>>;
  _not?: Maybe<Bringup_Checklist_Deliverable_Bool_Exp>;
  _or?: Maybe<Array<Bringup_Checklist_Deliverable_Bool_Exp>>;
  checklist?: Maybe<Bringup_Checklists_Bool_Exp>;
  checklist_id?: Maybe<Uuid_Comparison_Exp>;
  deliverable?: Maybe<Deliverables_Bool_Exp>;
  deliverable_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "bringup.checklist_deliverable" */
export enum Bringup_Checklist_Deliverable_Constraint {
  /** unique or primary key constraint on columns "deliverable_id", "checklist_id" */
  ChecklistDeliverablePkey = 'checklist_deliverable_pkey'
}

/** input type for inserting data into table "bringup.checklist_deliverable" */
export type Bringup_Checklist_Deliverable_Insert_Input = {
  checklist?: Maybe<Bringup_Checklists_Obj_Rel_Insert_Input>;
  checklist_id?: Maybe<Scalars['uuid']>;
  deliverable?: Maybe<Deliverables_Obj_Rel_Insert_Input>;
  deliverable_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Bringup_Checklist_Deliverable_Max_Fields = {
  __typename?: 'bringup_checklist_deliverable_max_fields';
  checklist_id?: Maybe<Scalars['uuid']>;
  deliverable_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "bringup.checklist_deliverable" */
export type Bringup_Checklist_Deliverable_Max_Order_By = {
  checklist_id?: Maybe<Order_By>;
  deliverable_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Bringup_Checklist_Deliverable_Min_Fields = {
  __typename?: 'bringup_checklist_deliverable_min_fields';
  checklist_id?: Maybe<Scalars['uuid']>;
  deliverable_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "bringup.checklist_deliverable" */
export type Bringup_Checklist_Deliverable_Min_Order_By = {
  checklist_id?: Maybe<Order_By>;
  deliverable_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "bringup.checklist_deliverable" */
export type Bringup_Checklist_Deliverable_Mutation_Response = {
  __typename?: 'bringup_checklist_deliverable_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bringup_Checklist_Deliverable>;
};

/** on_conflict condition type for table "bringup.checklist_deliverable" */
export type Bringup_Checklist_Deliverable_On_Conflict = {
  constraint: Bringup_Checklist_Deliverable_Constraint;
  update_columns?: Array<Bringup_Checklist_Deliverable_Update_Column>;
  where?: Maybe<Bringup_Checklist_Deliverable_Bool_Exp>;
};

/** Ordering options when selecting data from "bringup.checklist_deliverable". */
export type Bringup_Checklist_Deliverable_Order_By = {
  checklist?: Maybe<Bringup_Checklists_Order_By>;
  checklist_id?: Maybe<Order_By>;
  deliverable?: Maybe<Deliverables_Order_By>;
  deliverable_id?: Maybe<Order_By>;
};

/** primary key columns input for table: bringup.checklist_deliverable */
export type Bringup_Checklist_Deliverable_Pk_Columns_Input = {
  checklist_id: Scalars['uuid'];
  deliverable_id: Scalars['uuid'];
};

/** select columns of table "bringup.checklist_deliverable" */
export enum Bringup_Checklist_Deliverable_Select_Column {
  /** column name */
  ChecklistId = 'checklist_id',
  /** column name */
  DeliverableId = 'deliverable_id'
}

/** input type for updating data in table "bringup.checklist_deliverable" */
export type Bringup_Checklist_Deliverable_Set_Input = {
  checklist_id?: Maybe<Scalars['uuid']>;
  deliverable_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "bringup_checklist_deliverable" */
export type Bringup_Checklist_Deliverable_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bringup_Checklist_Deliverable_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bringup_Checklist_Deliverable_Stream_Cursor_Value_Input = {
  checklist_id?: Maybe<Scalars['uuid']>;
  deliverable_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "bringup.checklist_deliverable" */
export enum Bringup_Checklist_Deliverable_Update_Column {
  /** column name */
  ChecklistId = 'checklist_id',
  /** column name */
  DeliverableId = 'deliverable_id'
}

export type Bringup_Checklist_Deliverable_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Bringup_Checklist_Deliverable_Set_Input>;
  where: Bringup_Checklist_Deliverable_Bool_Exp;
};

/** columns and relationships of "bringup.checklists" */
export type Bringup_Checklists = {
  __typename?: 'bringup_checklists';
  /** An array relationship */
  checklist_deliverables: Array<Bringup_Checklist_Deliverable>;
  /** An aggregate relationship */
  checklist_deliverables_aggregate: Bringup_Checklist_Deliverable_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  id: Scalars['uuid'];
  /** An array relationship */
  items: Array<Bringup_Items>;
  /** An aggregate relationship */
  items_aggregate: Bringup_Items_Aggregate;
  name: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "bringup.checklists" */
export type Bringup_ChecklistsChecklist_DeliverablesArgs = {
  distinct_on?: Maybe<Array<Bringup_Checklist_Deliverable_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Checklist_Deliverable_Order_By>>;
  where?: Maybe<Bringup_Checklist_Deliverable_Bool_Exp>;
};


/** columns and relationships of "bringup.checklists" */
export type Bringup_ChecklistsChecklist_Deliverables_AggregateArgs = {
  distinct_on?: Maybe<Array<Bringup_Checklist_Deliverable_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Checklist_Deliverable_Order_By>>;
  where?: Maybe<Bringup_Checklist_Deliverable_Bool_Exp>;
};


/** columns and relationships of "bringup.checklists" */
export type Bringup_ChecklistsItemsArgs = {
  distinct_on?: Maybe<Array<Bringup_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Items_Order_By>>;
  where?: Maybe<Bringup_Items_Bool_Exp>;
};


/** columns and relationships of "bringup.checklists" */
export type Bringup_ChecklistsItems_AggregateArgs = {
  distinct_on?: Maybe<Array<Bringup_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Items_Order_By>>;
  where?: Maybe<Bringup_Items_Bool_Exp>;
};

/** aggregated selection of "bringup.checklists" */
export type Bringup_Checklists_Aggregate = {
  __typename?: 'bringup_checklists_aggregate';
  aggregate?: Maybe<Bringup_Checklists_Aggregate_Fields>;
  nodes: Array<Bringup_Checklists>;
};

/** aggregate fields of "bringup.checklists" */
export type Bringup_Checklists_Aggregate_Fields = {
  __typename?: 'bringup_checklists_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Bringup_Checklists_Max_Fields>;
  min?: Maybe<Bringup_Checklists_Min_Fields>;
};


/** aggregate fields of "bringup.checklists" */
export type Bringup_Checklists_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Bringup_Checklists_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "bringup.checklists". All fields are combined with a logical 'AND'. */
export type Bringup_Checklists_Bool_Exp = {
  _and?: Maybe<Array<Bringup_Checklists_Bool_Exp>>;
  _not?: Maybe<Bringup_Checklists_Bool_Exp>;
  _or?: Maybe<Array<Bringup_Checklists_Bool_Exp>>;
  checklist_deliverables?: Maybe<Bringup_Checklist_Deliverable_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  items?: Maybe<Bringup_Items_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "bringup.checklists" */
export enum Bringup_Checklists_Constraint {
  /** unique or primary key constraint on columns "name" */
  ChecklistsNameKey = 'checklists_name_key',
  /** unique or primary key constraint on columns "id" */
  ChecklistsPkey = 'checklists_pkey'
}

/** input type for inserting data into table "bringup.checklists" */
export type Bringup_Checklists_Insert_Input = {
  checklist_deliverables?: Maybe<Bringup_Checklist_Deliverable_Arr_Rel_Insert_Input>;
  description?: Maybe<Scalars['String']>;
  items?: Maybe<Bringup_Items_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Bringup_Checklists_Max_Fields = {
  __typename?: 'bringup_checklists_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Bringup_Checklists_Min_Fields = {
  __typename?: 'bringup_checklists_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "bringup.checklists" */
export type Bringup_Checklists_Mutation_Response = {
  __typename?: 'bringup_checklists_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bringup_Checklists>;
};

/** input type for inserting object relation for remote table "bringup.checklists" */
export type Bringup_Checklists_Obj_Rel_Insert_Input = {
  data: Bringup_Checklists_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Bringup_Checklists_On_Conflict>;
};

/** on_conflict condition type for table "bringup.checklists" */
export type Bringup_Checklists_On_Conflict = {
  constraint: Bringup_Checklists_Constraint;
  update_columns?: Array<Bringup_Checklists_Update_Column>;
  where?: Maybe<Bringup_Checklists_Bool_Exp>;
};

/** Ordering options when selecting data from "bringup.checklists". */
export type Bringup_Checklists_Order_By = {
  checklist_deliverables_aggregate?: Maybe<Bringup_Checklist_Deliverable_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  items_aggregate?: Maybe<Bringup_Items_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: bringup.checklists */
export type Bringup_Checklists_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "bringup.checklists" */
export enum Bringup_Checklists_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "bringup.checklists" */
export type Bringup_Checklists_Set_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "bringup_checklists" */
export type Bringup_Checklists_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bringup_Checklists_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bringup_Checklists_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "bringup.checklists" */
export enum Bringup_Checklists_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

export type Bringup_Checklists_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Bringup_Checklists_Set_Input>;
  where: Bringup_Checklists_Bool_Exp;
};

/** use to indicate whether an item was completed for a given project */
export type Bringup_Endorsements = {
  __typename?: 'bringup_endorsements';
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  item: Bringup_Items;
  item_id: Scalars['uuid'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
  /** An object relationship */
  user: Users;
};

/** aggregated selection of "bringup.endorsements" */
export type Bringup_Endorsements_Aggregate = {
  __typename?: 'bringup_endorsements_aggregate';
  aggregate?: Maybe<Bringup_Endorsements_Aggregate_Fields>;
  nodes: Array<Bringup_Endorsements>;
};

/** aggregate fields of "bringup.endorsements" */
export type Bringup_Endorsements_Aggregate_Fields = {
  __typename?: 'bringup_endorsements_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Bringup_Endorsements_Max_Fields>;
  min?: Maybe<Bringup_Endorsements_Min_Fields>;
};


/** aggregate fields of "bringup.endorsements" */
export type Bringup_Endorsements_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Bringup_Endorsements_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "bringup.endorsements" */
export type Bringup_Endorsements_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Bringup_Endorsements_Max_Order_By>;
  min?: Maybe<Bringup_Endorsements_Min_Order_By>;
};

/** input type for inserting array relation for remote table "bringup.endorsements" */
export type Bringup_Endorsements_Arr_Rel_Insert_Input = {
  data: Array<Bringup_Endorsements_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Bringup_Endorsements_On_Conflict>;
};

/** Boolean expression to filter rows from the table "bringup.endorsements". All fields are combined with a logical 'AND'. */
export type Bringup_Endorsements_Bool_Exp = {
  _and?: Maybe<Array<Bringup_Endorsements_Bool_Exp>>;
  _not?: Maybe<Bringup_Endorsements_Bool_Exp>;
  _or?: Maybe<Array<Bringup_Endorsements_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Uuid_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  item?: Maybe<Bringup_Items_Bool_Exp>;
  item_id?: Maybe<Uuid_Comparison_Exp>;
  project?: Maybe<Projects_Bool_Exp>;
  project_id?: Maybe<Uuid_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "bringup.endorsements" */
export enum Bringup_Endorsements_Constraint {
  /** unique or primary key constraint on columns "project_id", "item_id" */
  EndorsementsPkey = 'endorsements_pkey'
}

/** input type for inserting data into table "bringup.endorsements" */
export type Bringup_Endorsements_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  item?: Maybe<Bringup_Items_Obj_Rel_Insert_Input>;
  item_id?: Maybe<Scalars['uuid']>;
  project?: Maybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: Maybe<Scalars['uuid']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Bringup_Endorsements_Max_Fields = {
  __typename?: 'bringup_endorsements_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  item_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "bringup.endorsements" */
export type Bringup_Endorsements_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  item_id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Bringup_Endorsements_Min_Fields = {
  __typename?: 'bringup_endorsements_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  item_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "bringup.endorsements" */
export type Bringup_Endorsements_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  item_id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "bringup.endorsements" */
export type Bringup_Endorsements_Mutation_Response = {
  __typename?: 'bringup_endorsements_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bringup_Endorsements>;
};

/** on_conflict condition type for table "bringup.endorsements" */
export type Bringup_Endorsements_On_Conflict = {
  constraint: Bringup_Endorsements_Constraint;
  update_columns?: Array<Bringup_Endorsements_Update_Column>;
  where?: Maybe<Bringup_Endorsements_Bool_Exp>;
};

/** Ordering options when selecting data from "bringup.endorsements". */
export type Bringup_Endorsements_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  item?: Maybe<Bringup_Items_Order_By>;
  item_id?: Maybe<Order_By>;
  project?: Maybe<Projects_Order_By>;
  project_id?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
};

/** primary key columns input for table: bringup.endorsements */
export type Bringup_Endorsements_Pk_Columns_Input = {
  item_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};

/** select columns of table "bringup.endorsements" */
export enum Bringup_Endorsements_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ProjectId = 'project_id'
}

/** input type for updating data in table "bringup.endorsements" */
export type Bringup_Endorsements_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  item_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "bringup_endorsements" */
export type Bringup_Endorsements_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bringup_Endorsements_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bringup_Endorsements_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  item_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "bringup.endorsements" */
export enum Bringup_Endorsements_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ProjectId = 'project_id'
}

export type Bringup_Endorsements_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Bringup_Endorsements_Set_Input>;
  where: Bringup_Endorsements_Bool_Exp;
};

/** used for items that belong to a checklist */
export type Bringup_Items = {
  __typename?: 'bringup_items';
  /** An object relationship */
  checklist: Bringup_Checklists;
  checklist_id: Scalars['uuid'];
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  dependent_items: Array<Bringup_Items>;
  /** An aggregate relationship */
  dependent_items_aggregate: Bringup_Items_Aggregate;
  depends_on?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  depends_on_item?: Maybe<Bringup_Items>;
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  endorsements: Array<Bringup_Endorsements>;
  /** An aggregate relationship */
  endorsements_aggregate: Bringup_Endorsements_Aggregate;
  id: Scalars['uuid'];
  /** An object relationship */
  milestone?: Maybe<Bringup_Milestones>;
  name: Scalars['String'];
  objective?: Maybe<Scalars['String']>;
  personnel?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  /** An array relationship */
  project_item_comments: Array<Bringup_Project_Item_Comments>;
  /** An aggregate relationship */
  project_item_comments_aggregate: Bringup_Project_Item_Comments_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** used for items that belong to a checklist */
export type Bringup_ItemsDependent_ItemsArgs = {
  distinct_on?: Maybe<Array<Bringup_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Items_Order_By>>;
  where?: Maybe<Bringup_Items_Bool_Exp>;
};


/** used for items that belong to a checklist */
export type Bringup_ItemsDependent_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Bringup_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Items_Order_By>>;
  where?: Maybe<Bringup_Items_Bool_Exp>;
};


/** used for items that belong to a checklist */
export type Bringup_ItemsEndorsementsArgs = {
  distinct_on?: Maybe<Array<Bringup_Endorsements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Endorsements_Order_By>>;
  where?: Maybe<Bringup_Endorsements_Bool_Exp>;
};


/** used for items that belong to a checklist */
export type Bringup_ItemsEndorsements_AggregateArgs = {
  distinct_on?: Maybe<Array<Bringup_Endorsements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Endorsements_Order_By>>;
  where?: Maybe<Bringup_Endorsements_Bool_Exp>;
};


/** used for items that belong to a checklist */
export type Bringup_ItemsProject_Item_CommentsArgs = {
  distinct_on?: Maybe<Array<Bringup_Project_Item_Comments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Project_Item_Comments_Order_By>>;
  where?: Maybe<Bringup_Project_Item_Comments_Bool_Exp>;
};


/** used for items that belong to a checklist */
export type Bringup_ItemsProject_Item_Comments_AggregateArgs = {
  distinct_on?: Maybe<Array<Bringup_Project_Item_Comments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Project_Item_Comments_Order_By>>;
  where?: Maybe<Bringup_Project_Item_Comments_Bool_Exp>;
};

/** aggregated selection of "bringup.items" */
export type Bringup_Items_Aggregate = {
  __typename?: 'bringup_items_aggregate';
  aggregate?: Maybe<Bringup_Items_Aggregate_Fields>;
  nodes: Array<Bringup_Items>;
};

/** aggregate fields of "bringup.items" */
export type Bringup_Items_Aggregate_Fields = {
  __typename?: 'bringup_items_aggregate_fields';
  avg?: Maybe<Bringup_Items_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Bringup_Items_Max_Fields>;
  min?: Maybe<Bringup_Items_Min_Fields>;
  stddev?: Maybe<Bringup_Items_Stddev_Fields>;
  stddev_pop?: Maybe<Bringup_Items_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bringup_Items_Stddev_Samp_Fields>;
  sum?: Maybe<Bringup_Items_Sum_Fields>;
  var_pop?: Maybe<Bringup_Items_Var_Pop_Fields>;
  var_samp?: Maybe<Bringup_Items_Var_Samp_Fields>;
  variance?: Maybe<Bringup_Items_Variance_Fields>;
};


/** aggregate fields of "bringup.items" */
export type Bringup_Items_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Bringup_Items_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "bringup.items" */
export type Bringup_Items_Aggregate_Order_By = {
  avg?: Maybe<Bringup_Items_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Bringup_Items_Max_Order_By>;
  min?: Maybe<Bringup_Items_Min_Order_By>;
  stddev?: Maybe<Bringup_Items_Stddev_Order_By>;
  stddev_pop?: Maybe<Bringup_Items_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Bringup_Items_Stddev_Samp_Order_By>;
  sum?: Maybe<Bringup_Items_Sum_Order_By>;
  var_pop?: Maybe<Bringup_Items_Var_Pop_Order_By>;
  var_samp?: Maybe<Bringup_Items_Var_Samp_Order_By>;
  variance?: Maybe<Bringup_Items_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "bringup.items" */
export type Bringup_Items_Arr_Rel_Insert_Input = {
  data: Array<Bringup_Items_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Bringup_Items_On_Conflict>;
};

/** aggregate avg on columns */
export type Bringup_Items_Avg_Fields = {
  __typename?: 'bringup_items_avg_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "bringup.items" */
export type Bringup_Items_Avg_Order_By = {
  position?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "bringup.items". All fields are combined with a logical 'AND'. */
export type Bringup_Items_Bool_Exp = {
  _and?: Maybe<Array<Bringup_Items_Bool_Exp>>;
  _not?: Maybe<Bringup_Items_Bool_Exp>;
  _or?: Maybe<Array<Bringup_Items_Bool_Exp>>;
  checklist?: Maybe<Bringup_Checklists_Bool_Exp>;
  checklist_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  dependent_items?: Maybe<Bringup_Items_Bool_Exp>;
  depends_on?: Maybe<Uuid_Comparison_Exp>;
  depends_on_item?: Maybe<Bringup_Items_Bool_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  endorsements?: Maybe<Bringup_Endorsements_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  milestone?: Maybe<Bringup_Milestones_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  objective?: Maybe<String_Comparison_Exp>;
  personnel?: Maybe<String_Comparison_Exp>;
  position?: Maybe<Int_Comparison_Exp>;
  project_item_comments?: Maybe<Bringup_Project_Item_Comments_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "bringup.items" */
export enum Bringup_Items_Constraint {
  /** unique or primary key constraint on columns "name", "checklist_id" */
  ItemsChecklistIdNameKey = 'items_checklist_id_name_key',
  /** unique or primary key constraint on columns "position", "checklist_id" */
  ItemsChecklistIdPositionKey = 'items_checklist_id_position_key',
  /** unique or primary key constraint on columns "id" */
  ItemsPkey = 'items_pkey'
}

/** input type for incrementing numeric columns in table "bringup.items" */
export type Bringup_Items_Inc_Input = {
  position?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "bringup.items" */
export type Bringup_Items_Insert_Input = {
  checklist?: Maybe<Bringup_Checklists_Obj_Rel_Insert_Input>;
  checklist_id?: Maybe<Scalars['uuid']>;
  dependent_items?: Maybe<Bringup_Items_Arr_Rel_Insert_Input>;
  depends_on?: Maybe<Scalars['uuid']>;
  depends_on_item?: Maybe<Bringup_Items_Obj_Rel_Insert_Input>;
  description?: Maybe<Scalars['String']>;
  endorsements?: Maybe<Bringup_Endorsements_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  milestone?: Maybe<Bringup_Milestones_Obj_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  objective?: Maybe<Scalars['String']>;
  personnel?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  project_item_comments?: Maybe<Bringup_Project_Item_Comments_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Bringup_Items_Max_Fields = {
  __typename?: 'bringup_items_max_fields';
  checklist_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  depends_on?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  objective?: Maybe<Scalars['String']>;
  personnel?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "bringup.items" */
export type Bringup_Items_Max_Order_By = {
  checklist_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  depends_on?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  objective?: Maybe<Order_By>;
  personnel?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Bringup_Items_Min_Fields = {
  __typename?: 'bringup_items_min_fields';
  checklist_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  depends_on?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  objective?: Maybe<Scalars['String']>;
  personnel?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "bringup.items" */
export type Bringup_Items_Min_Order_By = {
  checklist_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  depends_on?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  objective?: Maybe<Order_By>;
  personnel?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "bringup.items" */
export type Bringup_Items_Mutation_Response = {
  __typename?: 'bringup_items_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bringup_Items>;
};

/** input type for inserting object relation for remote table "bringup.items" */
export type Bringup_Items_Obj_Rel_Insert_Input = {
  data: Bringup_Items_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Bringup_Items_On_Conflict>;
};

/** on_conflict condition type for table "bringup.items" */
export type Bringup_Items_On_Conflict = {
  constraint: Bringup_Items_Constraint;
  update_columns?: Array<Bringup_Items_Update_Column>;
  where?: Maybe<Bringup_Items_Bool_Exp>;
};

/** Ordering options when selecting data from "bringup.items". */
export type Bringup_Items_Order_By = {
  checklist?: Maybe<Bringup_Checklists_Order_By>;
  checklist_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  dependent_items_aggregate?: Maybe<Bringup_Items_Aggregate_Order_By>;
  depends_on?: Maybe<Order_By>;
  depends_on_item?: Maybe<Bringup_Items_Order_By>;
  description?: Maybe<Order_By>;
  endorsements_aggregate?: Maybe<Bringup_Endorsements_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  milestone?: Maybe<Bringup_Milestones_Order_By>;
  name?: Maybe<Order_By>;
  objective?: Maybe<Order_By>;
  personnel?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  project_item_comments_aggregate?: Maybe<Bringup_Project_Item_Comments_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: bringup.items */
export type Bringup_Items_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "bringup.items" */
export enum Bringup_Items_Select_Column {
  /** column name */
  ChecklistId = 'checklist_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DependsOn = 'depends_on',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Objective = 'objective',
  /** column name */
  Personnel = 'personnel',
  /** column name */
  Position = 'position',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "bringup.items" */
export type Bringup_Items_Set_Input = {
  checklist_id?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  depends_on?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  objective?: Maybe<Scalars['String']>;
  personnel?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Bringup_Items_Stddev_Fields = {
  __typename?: 'bringup_items_stddev_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "bringup.items" */
export type Bringup_Items_Stddev_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Bringup_Items_Stddev_Pop_Fields = {
  __typename?: 'bringup_items_stddev_pop_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "bringup.items" */
export type Bringup_Items_Stddev_Pop_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Bringup_Items_Stddev_Samp_Fields = {
  __typename?: 'bringup_items_stddev_samp_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "bringup.items" */
export type Bringup_Items_Stddev_Samp_Order_By = {
  position?: Maybe<Order_By>;
};

/** Streaming cursor of the table "bringup_items" */
export type Bringup_Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bringup_Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bringup_Items_Stream_Cursor_Value_Input = {
  checklist_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  depends_on?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  objective?: Maybe<Scalars['String']>;
  personnel?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Bringup_Items_Sum_Fields = {
  __typename?: 'bringup_items_sum_fields';
  position?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "bringup.items" */
export type Bringup_Items_Sum_Order_By = {
  position?: Maybe<Order_By>;
};

/** update columns of table "bringup.items" */
export enum Bringup_Items_Update_Column {
  /** column name */
  ChecklistId = 'checklist_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DependsOn = 'depends_on',
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
  /** column name */
  Objective = 'objective',
  /** column name */
  Personnel = 'personnel',
  /** column name */
  Position = 'position'
}

export type Bringup_Items_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Bringup_Items_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Bringup_Items_Set_Input>;
  where: Bringup_Items_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Bringup_Items_Var_Pop_Fields = {
  __typename?: 'bringup_items_var_pop_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "bringup.items" */
export type Bringup_Items_Var_Pop_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Bringup_Items_Var_Samp_Fields = {
  __typename?: 'bringup_items_var_samp_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "bringup.items" */
export type Bringup_Items_Var_Samp_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Bringup_Items_Variance_Fields = {
  __typename?: 'bringup_items_variance_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "bringup.items" */
export type Bringup_Items_Variance_Order_By = {
  position?: Maybe<Order_By>;
};

/** use to attach "milestones" to checklist items, effectively grouping the items before them to previous milestones */
export type Bringup_Milestones = {
  __typename?: 'bringup_milestones';
  description?: Maybe<Scalars['String']>;
  /** An object relationship */
  item: Bringup_Items;
  item_id: Scalars['uuid'];
  name: Scalars['String'];
};

/** aggregated selection of "bringup.milestones" */
export type Bringup_Milestones_Aggregate = {
  __typename?: 'bringup_milestones_aggregate';
  aggregate?: Maybe<Bringup_Milestones_Aggregate_Fields>;
  nodes: Array<Bringup_Milestones>;
};

/** aggregate fields of "bringup.milestones" */
export type Bringup_Milestones_Aggregate_Fields = {
  __typename?: 'bringup_milestones_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Bringup_Milestones_Max_Fields>;
  min?: Maybe<Bringup_Milestones_Min_Fields>;
};


/** aggregate fields of "bringup.milestones" */
export type Bringup_Milestones_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Bringup_Milestones_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "bringup.milestones". All fields are combined with a logical 'AND'. */
export type Bringup_Milestones_Bool_Exp = {
  _and?: Maybe<Array<Bringup_Milestones_Bool_Exp>>;
  _not?: Maybe<Bringup_Milestones_Bool_Exp>;
  _or?: Maybe<Array<Bringup_Milestones_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  item?: Maybe<Bringup_Items_Bool_Exp>;
  item_id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "bringup.milestones" */
export enum Bringup_Milestones_Constraint {
  /** unique or primary key constraint on columns "item_id" */
  MilestonesPkey = 'milestones_pkey'
}

/** input type for inserting data into table "bringup.milestones" */
export type Bringup_Milestones_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  item?: Maybe<Bringup_Items_Obj_Rel_Insert_Input>;
  item_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Bringup_Milestones_Max_Fields = {
  __typename?: 'bringup_milestones_max_fields';
  description?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Bringup_Milestones_Min_Fields = {
  __typename?: 'bringup_milestones_min_fields';
  description?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "bringup.milestones" */
export type Bringup_Milestones_Mutation_Response = {
  __typename?: 'bringup_milestones_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bringup_Milestones>;
};

/** input type for inserting object relation for remote table "bringup.milestones" */
export type Bringup_Milestones_Obj_Rel_Insert_Input = {
  data: Bringup_Milestones_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Bringup_Milestones_On_Conflict>;
};

/** on_conflict condition type for table "bringup.milestones" */
export type Bringup_Milestones_On_Conflict = {
  constraint: Bringup_Milestones_Constraint;
  update_columns?: Array<Bringup_Milestones_Update_Column>;
  where?: Maybe<Bringup_Milestones_Bool_Exp>;
};

/** Ordering options when selecting data from "bringup.milestones". */
export type Bringup_Milestones_Order_By = {
  description?: Maybe<Order_By>;
  item?: Maybe<Bringup_Items_Order_By>;
  item_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: bringup.milestones */
export type Bringup_Milestones_Pk_Columns_Input = {
  item_id: Scalars['uuid'];
};

/** select columns of table "bringup.milestones" */
export enum Bringup_Milestones_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "bringup.milestones" */
export type Bringup_Milestones_Set_Input = {
  description?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "bringup_milestones" */
export type Bringup_Milestones_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bringup_Milestones_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bringup_Milestones_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "bringup.milestones" */
export enum Bringup_Milestones_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  Name = 'name'
}

export type Bringup_Milestones_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Bringup_Milestones_Set_Input>;
  where: Bringup_Milestones_Bool_Exp;
};

/** allows users to add comments to items for a given project */
export type Bringup_Project_Item_Comments = {
  __typename?: 'bringup_project_item_comments';
  content: Scalars['String'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  deleted_at?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  item: Bringup_Items;
  item_id: Scalars['uuid'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user: Users;
};

/** aggregated selection of "bringup.project_item_comments" */
export type Bringup_Project_Item_Comments_Aggregate = {
  __typename?: 'bringup_project_item_comments_aggregate';
  aggregate?: Maybe<Bringup_Project_Item_Comments_Aggregate_Fields>;
  nodes: Array<Bringup_Project_Item_Comments>;
};

/** aggregate fields of "bringup.project_item_comments" */
export type Bringup_Project_Item_Comments_Aggregate_Fields = {
  __typename?: 'bringup_project_item_comments_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Bringup_Project_Item_Comments_Max_Fields>;
  min?: Maybe<Bringup_Project_Item_Comments_Min_Fields>;
};


/** aggregate fields of "bringup.project_item_comments" */
export type Bringup_Project_Item_Comments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Bringup_Project_Item_Comments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "bringup.project_item_comments" */
export type Bringup_Project_Item_Comments_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Bringup_Project_Item_Comments_Max_Order_By>;
  min?: Maybe<Bringup_Project_Item_Comments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "bringup.project_item_comments" */
export type Bringup_Project_Item_Comments_Arr_Rel_Insert_Input = {
  data: Array<Bringup_Project_Item_Comments_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Bringup_Project_Item_Comments_On_Conflict>;
};

/** Boolean expression to filter rows from the table "bringup.project_item_comments". All fields are combined with a logical 'AND'. */
export type Bringup_Project_Item_Comments_Bool_Exp = {
  _and?: Maybe<Array<Bringup_Project_Item_Comments_Bool_Exp>>;
  _not?: Maybe<Bringup_Project_Item_Comments_Bool_Exp>;
  _or?: Maybe<Array<Bringup_Project_Item_Comments_Bool_Exp>>;
  content?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Uuid_Comparison_Exp>;
  deleted_at?: Maybe<Timestamp_Comparison_Exp>;
  item?: Maybe<Bringup_Items_Bool_Exp>;
  item_id?: Maybe<Uuid_Comparison_Exp>;
  project?: Maybe<Projects_Bool_Exp>;
  project_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "bringup.project_item_comments" */
export enum Bringup_Project_Item_Comments_Constraint {
  /** unique or primary key constraint on columns "project_id", "created_at", "item_id" */
  ProjectItemCommentsPkey = 'project_item_comments_pkey'
}

/** input type for inserting data into table "bringup.project_item_comments" */
export type Bringup_Project_Item_Comments_Insert_Input = {
  content?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['uuid']>;
  item?: Maybe<Bringup_Items_Obj_Rel_Insert_Input>;
  item_id?: Maybe<Scalars['uuid']>;
  project?: Maybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: Maybe<Scalars['uuid']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Bringup_Project_Item_Comments_Max_Fields = {
  __typename?: 'bringup_project_item_comments_max_fields';
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  item_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "bringup.project_item_comments" */
export type Bringup_Project_Item_Comments_Max_Order_By = {
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  item_id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Bringup_Project_Item_Comments_Min_Fields = {
  __typename?: 'bringup_project_item_comments_min_fields';
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  item_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "bringup.project_item_comments" */
export type Bringup_Project_Item_Comments_Min_Order_By = {
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  item_id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "bringup.project_item_comments" */
export type Bringup_Project_Item_Comments_Mutation_Response = {
  __typename?: 'bringup_project_item_comments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bringup_Project_Item_Comments>;
};

/** on_conflict condition type for table "bringup.project_item_comments" */
export type Bringup_Project_Item_Comments_On_Conflict = {
  constraint: Bringup_Project_Item_Comments_Constraint;
  update_columns?: Array<Bringup_Project_Item_Comments_Update_Column>;
  where?: Maybe<Bringup_Project_Item_Comments_Bool_Exp>;
};

/** Ordering options when selecting data from "bringup.project_item_comments". */
export type Bringup_Project_Item_Comments_Order_By = {
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  item?: Maybe<Bringup_Items_Order_By>;
  item_id?: Maybe<Order_By>;
  project?: Maybe<Projects_Order_By>;
  project_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
};

/** primary key columns input for table: bringup.project_item_comments */
export type Bringup_Project_Item_Comments_Pk_Columns_Input = {
  created_at: Scalars['timestamptz'];
  item_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};

/** select columns of table "bringup.project_item_comments" */
export enum Bringup_Project_Item_Comments_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "bringup.project_item_comments" */
export type Bringup_Project_Item_Comments_Set_Input = {
  content?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  item_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "bringup_project_item_comments" */
export type Bringup_Project_Item_Comments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bringup_Project_Item_Comments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bringup_Project_Item_Comments_Stream_Cursor_Value_Input = {
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  item_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "bringup.project_item_comments" */
export enum Bringup_Project_Item_Comments_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ProjectId = 'project_id'
}

export type Bringup_Project_Item_Comments_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Bringup_Project_Item_Comments_Set_Input>;
  where: Bringup_Project_Item_Comments_Bool_Exp;
};

/** columns and relationships of "categories" */
export type Categories = {
  __typename?: 'categories';
  /** An array relationship */
  contexts: Array<Contexts>;
  /** An aggregate relationship */
  contexts_aggregate: Contexts_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  priority: Scalars['smallint'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "categories" */
export type CategoriesContextsArgs = {
  distinct_on?: Maybe<Array<Contexts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contexts_Order_By>>;
  where?: Maybe<Contexts_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type CategoriesContexts_AggregateArgs = {
  distinct_on?: Maybe<Array<Contexts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contexts_Order_By>>;
  where?: Maybe<Contexts_Bool_Exp>;
};

/** aggregated selection of "categories" */
export type Categories_Aggregate = {
  __typename?: 'categories_aggregate';
  aggregate?: Maybe<Categories_Aggregate_Fields>;
  nodes: Array<Categories>;
};

/** aggregate fields of "categories" */
export type Categories_Aggregate_Fields = {
  __typename?: 'categories_aggregate_fields';
  avg?: Maybe<Categories_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Categories_Max_Fields>;
  min?: Maybe<Categories_Min_Fields>;
  stddev?: Maybe<Categories_Stddev_Fields>;
  stddev_pop?: Maybe<Categories_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Categories_Stddev_Samp_Fields>;
  sum?: Maybe<Categories_Sum_Fields>;
  var_pop?: Maybe<Categories_Var_Pop_Fields>;
  var_samp?: Maybe<Categories_Var_Samp_Fields>;
  variance?: Maybe<Categories_Variance_Fields>;
};


/** aggregate fields of "categories" */
export type Categories_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Categories_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Categories_Avg_Fields = {
  __typename?: 'categories_avg_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "categories". All fields are combined with a logical 'AND'. */
export type Categories_Bool_Exp = {
  _and?: Maybe<Array<Categories_Bool_Exp>>;
  _not?: Maybe<Categories_Bool_Exp>;
  _or?: Maybe<Array<Categories_Bool_Exp>>;
  contexts?: Maybe<Contexts_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  priority?: Maybe<Smallint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "categories" */
export enum Categories_Constraint {
  /** unique or primary key constraint on columns "name" */
  CategoriesNameUnique = 'categories_name_unique',
  /** unique or primary key constraint on columns "id" */
  CategoriesPkey = 'categories_pkey'
}

/** input type for incrementing numeric columns in table "categories" */
export type Categories_Inc_Input = {
  priority?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "categories" */
export type Categories_Insert_Input = {
  contexts?: Maybe<Contexts_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['smallint']>;
};

/** aggregate max on columns */
export type Categories_Max_Fields = {
  __typename?: 'categories_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['smallint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Categories_Min_Fields = {
  __typename?: 'categories_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['smallint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "categories" */
export type Categories_Mutation_Response = {
  __typename?: 'categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Categories>;
};

/** input type for inserting object relation for remote table "categories" */
export type Categories_Obj_Rel_Insert_Input = {
  data: Categories_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Categories_On_Conflict>;
};

/** on_conflict condition type for table "categories" */
export type Categories_On_Conflict = {
  constraint: Categories_Constraint;
  update_columns?: Array<Categories_Update_Column>;
  where?: Maybe<Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "categories". */
export type Categories_Order_By = {
  contexts_aggregate?: Maybe<Contexts_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: categories */
export type Categories_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "categories" */
export enum Categories_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Priority = 'priority',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "categories" */
export type Categories_Set_Input = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type Categories_Stddev_Fields = {
  __typename?: 'categories_stddev_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Categories_Stddev_Pop_Fields = {
  __typename?: 'categories_stddev_pop_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Categories_Stddev_Samp_Fields = {
  __typename?: 'categories_stddev_samp_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "categories" */
export type Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Categories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Categories_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['smallint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Categories_Sum_Fields = {
  __typename?: 'categories_sum_fields';
  priority?: Maybe<Scalars['smallint']>;
};

/** update columns of table "categories" */
export enum Categories_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Name = 'name',
  /** column name */
  Priority = 'priority'
}

export type Categories_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Categories_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Categories_Set_Input>;
  where: Categories_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Categories_Var_Pop_Fields = {
  __typename?: 'categories_var_pop_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Categories_Var_Samp_Fields = {
  __typename?: 'categories_var_samp_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Categories_Variance_Fields = {
  __typename?: 'categories_variance_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "context_display_field" */
export type Context_Display_Field = {
  __typename?: 'context_display_field';
  /** An object relationship */
  context_display: Context_Displays;
  context_display_id: Scalars['uuid'];
  context_id: Scalars['uuid'];
  /** An object relationship */
  field?: Maybe<Fields>;
  field_id: Scalars['uuid'];
  position: Scalars['smallint'];
};

/** aggregated selection of "context_display_field" */
export type Context_Display_Field_Aggregate = {
  __typename?: 'context_display_field_aggregate';
  aggregate?: Maybe<Context_Display_Field_Aggregate_Fields>;
  nodes: Array<Context_Display_Field>;
};

/** aggregate fields of "context_display_field" */
export type Context_Display_Field_Aggregate_Fields = {
  __typename?: 'context_display_field_aggregate_fields';
  avg?: Maybe<Context_Display_Field_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Context_Display_Field_Max_Fields>;
  min?: Maybe<Context_Display_Field_Min_Fields>;
  stddev?: Maybe<Context_Display_Field_Stddev_Fields>;
  stddev_pop?: Maybe<Context_Display_Field_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Context_Display_Field_Stddev_Samp_Fields>;
  sum?: Maybe<Context_Display_Field_Sum_Fields>;
  var_pop?: Maybe<Context_Display_Field_Var_Pop_Fields>;
  var_samp?: Maybe<Context_Display_Field_Var_Samp_Fields>;
  variance?: Maybe<Context_Display_Field_Variance_Fields>;
};


/** aggregate fields of "context_display_field" */
export type Context_Display_Field_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Context_Display_Field_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "context_display_field" */
export type Context_Display_Field_Aggregate_Order_By = {
  avg?: Maybe<Context_Display_Field_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Context_Display_Field_Max_Order_By>;
  min?: Maybe<Context_Display_Field_Min_Order_By>;
  stddev?: Maybe<Context_Display_Field_Stddev_Order_By>;
  stddev_pop?: Maybe<Context_Display_Field_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Context_Display_Field_Stddev_Samp_Order_By>;
  sum?: Maybe<Context_Display_Field_Sum_Order_By>;
  var_pop?: Maybe<Context_Display_Field_Var_Pop_Order_By>;
  var_samp?: Maybe<Context_Display_Field_Var_Samp_Order_By>;
  variance?: Maybe<Context_Display_Field_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "context_display_field" */
export type Context_Display_Field_Arr_Rel_Insert_Input = {
  data: Array<Context_Display_Field_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Context_Display_Field_On_Conflict>;
};

/** aggregate avg on columns */
export type Context_Display_Field_Avg_Fields = {
  __typename?: 'context_display_field_avg_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "context_display_field" */
export type Context_Display_Field_Avg_Order_By = {
  position?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "context_display_field". All fields are combined with a logical 'AND'. */
export type Context_Display_Field_Bool_Exp = {
  _and?: Maybe<Array<Context_Display_Field_Bool_Exp>>;
  _not?: Maybe<Context_Display_Field_Bool_Exp>;
  _or?: Maybe<Array<Context_Display_Field_Bool_Exp>>;
  context_display?: Maybe<Context_Displays_Bool_Exp>;
  context_display_id?: Maybe<Uuid_Comparison_Exp>;
  context_id?: Maybe<Uuid_Comparison_Exp>;
  field?: Maybe<Fields_Bool_Exp>;
  field_id?: Maybe<Uuid_Comparison_Exp>;
  position?: Maybe<Smallint_Comparison_Exp>;
};

/** unique or primary key constraints on table "context_display_field" */
export enum Context_Display_Field_Constraint {
  /** unique or primary key constraint on columns "context_display_id", "position" */
  ContextDisplayFieldContextDisplayIdPositionKey = 'context_display_field_context_display_id_position_key',
  /** unique or primary key constraint on columns "context_display_id", "field_id" */
  ContextDisplayFieldPkey = 'context_display_field_pkey'
}

/** input type for incrementing numeric columns in table "context_display_field" */
export type Context_Display_Field_Inc_Input = {
  position?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "context_display_field" */
export type Context_Display_Field_Insert_Input = {
  context_display?: Maybe<Context_Displays_Obj_Rel_Insert_Input>;
  context_display_id?: Maybe<Scalars['uuid']>;
  context_id?: Maybe<Scalars['uuid']>;
  field?: Maybe<Fields_Obj_Rel_Insert_Input>;
  field_id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['smallint']>;
};

/** aggregate max on columns */
export type Context_Display_Field_Max_Fields = {
  __typename?: 'context_display_field_max_fields';
  context_display_id?: Maybe<Scalars['uuid']>;
  context_id?: Maybe<Scalars['uuid']>;
  field_id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['smallint']>;
};

/** order by max() on columns of table "context_display_field" */
export type Context_Display_Field_Max_Order_By = {
  context_display_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  field_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Context_Display_Field_Min_Fields = {
  __typename?: 'context_display_field_min_fields';
  context_display_id?: Maybe<Scalars['uuid']>;
  context_id?: Maybe<Scalars['uuid']>;
  field_id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['smallint']>;
};

/** order by min() on columns of table "context_display_field" */
export type Context_Display_Field_Min_Order_By = {
  context_display_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  field_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** response of any mutation on the table "context_display_field" */
export type Context_Display_Field_Mutation_Response = {
  __typename?: 'context_display_field_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Context_Display_Field>;
};

/** on_conflict condition type for table "context_display_field" */
export type Context_Display_Field_On_Conflict = {
  constraint: Context_Display_Field_Constraint;
  update_columns?: Array<Context_Display_Field_Update_Column>;
  where?: Maybe<Context_Display_Field_Bool_Exp>;
};

/** Ordering options when selecting data from "context_display_field". */
export type Context_Display_Field_Order_By = {
  context_display?: Maybe<Context_Displays_Order_By>;
  context_display_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  field?: Maybe<Fields_Order_By>;
  field_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** primary key columns input for table: context_display_field */
export type Context_Display_Field_Pk_Columns_Input = {
  context_display_id: Scalars['uuid'];
  field_id: Scalars['uuid'];
};

/** select columns of table "context_display_field" */
export enum Context_Display_Field_Select_Column {
  /** column name */
  ContextDisplayId = 'context_display_id',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  FieldId = 'field_id',
  /** column name */
  Position = 'position'
}

/** input type for updating data in table "context_display_field" */
export type Context_Display_Field_Set_Input = {
  context_display_id?: Maybe<Scalars['uuid']>;
  context_id?: Maybe<Scalars['uuid']>;
  field_id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type Context_Display_Field_Stddev_Fields = {
  __typename?: 'context_display_field_stddev_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "context_display_field" */
export type Context_Display_Field_Stddev_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Context_Display_Field_Stddev_Pop_Fields = {
  __typename?: 'context_display_field_stddev_pop_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "context_display_field" */
export type Context_Display_Field_Stddev_Pop_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Context_Display_Field_Stddev_Samp_Fields = {
  __typename?: 'context_display_field_stddev_samp_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "context_display_field" */
export type Context_Display_Field_Stddev_Samp_Order_By = {
  position?: Maybe<Order_By>;
};

/** Streaming cursor of the table "context_display_field" */
export type Context_Display_Field_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Context_Display_Field_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Context_Display_Field_Stream_Cursor_Value_Input = {
  context_display_id?: Maybe<Scalars['uuid']>;
  context_id?: Maybe<Scalars['uuid']>;
  field_id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['smallint']>;
};

/** aggregate sum on columns */
export type Context_Display_Field_Sum_Fields = {
  __typename?: 'context_display_field_sum_fields';
  position?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "context_display_field" */
export type Context_Display_Field_Sum_Order_By = {
  position?: Maybe<Order_By>;
};

/** update columns of table "context_display_field" */
export enum Context_Display_Field_Update_Column {
  /** column name */
  ContextDisplayId = 'context_display_id',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  FieldId = 'field_id',
  /** column name */
  Position = 'position'
}

export type Context_Display_Field_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Context_Display_Field_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Context_Display_Field_Set_Input>;
  where: Context_Display_Field_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Context_Display_Field_Var_Pop_Fields = {
  __typename?: 'context_display_field_var_pop_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "context_display_field" */
export type Context_Display_Field_Var_Pop_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Context_Display_Field_Var_Samp_Fields = {
  __typename?: 'context_display_field_var_samp_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "context_display_field" */
export type Context_Display_Field_Var_Samp_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Context_Display_Field_Variance_Fields = {
  __typename?: 'context_display_field_variance_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "context_display_field" */
export type Context_Display_Field_Variance_Order_By = {
  position?: Maybe<Order_By>;
};

/** columns and relationships of "context_displays" */
export type Context_Displays = {
  __typename?: 'context_displays';
  /** An object relationship */
  context: Contexts;
  /** An array relationship */
  context_display_fields: Array<Context_Display_Field>;
  /** An aggregate relationship */
  context_display_fields_aggregate: Context_Display_Field_Aggregate;
  context_id: Scalars['uuid'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  position: Scalars['smallint'];
};


/** columns and relationships of "context_displays" */
export type Context_DisplaysContext_Display_FieldsArgs = {
  distinct_on?: Maybe<Array<Context_Display_Field_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Display_Field_Order_By>>;
  where?: Maybe<Context_Display_Field_Bool_Exp>;
};


/** columns and relationships of "context_displays" */
export type Context_DisplaysContext_Display_Fields_AggregateArgs = {
  distinct_on?: Maybe<Array<Context_Display_Field_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Display_Field_Order_By>>;
  where?: Maybe<Context_Display_Field_Bool_Exp>;
};

/** order by aggregate values of table "context_displays" */
export type Context_Displays_Aggregate_Order_By = {
  avg?: Maybe<Context_Displays_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Context_Displays_Max_Order_By>;
  min?: Maybe<Context_Displays_Min_Order_By>;
  stddev?: Maybe<Context_Displays_Stddev_Order_By>;
  stddev_pop?: Maybe<Context_Displays_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Context_Displays_Stddev_Samp_Order_By>;
  sum?: Maybe<Context_Displays_Sum_Order_By>;
  var_pop?: Maybe<Context_Displays_Var_Pop_Order_By>;
  var_samp?: Maybe<Context_Displays_Var_Samp_Order_By>;
  variance?: Maybe<Context_Displays_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "context_displays" */
export type Context_Displays_Arr_Rel_Insert_Input = {
  data: Array<Context_Displays_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Context_Displays_On_Conflict>;
};

/** order by avg() on columns of table "context_displays" */
export type Context_Displays_Avg_Order_By = {
  position?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "context_displays". All fields are combined with a logical 'AND'. */
export type Context_Displays_Bool_Exp = {
  _and?: Maybe<Array<Context_Displays_Bool_Exp>>;
  _not?: Maybe<Context_Displays_Bool_Exp>;
  _or?: Maybe<Array<Context_Displays_Bool_Exp>>;
  context?: Maybe<Contexts_Bool_Exp>;
  context_display_fields?: Maybe<Context_Display_Field_Bool_Exp>;
  context_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  position?: Maybe<Smallint_Comparison_Exp>;
};

/** unique or primary key constraints on table "context_displays" */
export enum Context_Displays_Constraint {
  /** unique or primary key constraint on columns "context_id", "position" */
  ContextDisplaysContextIdPositionKey = 'context_displays_context_id_position_key',
  /** unique or primary key constraint on columns "id" */
  ContextDisplaysPkey = 'context_displays_pkey'
}

/** input type for incrementing numeric columns in table "context_displays" */
export type Context_Displays_Inc_Input = {
  position?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "context_displays" */
export type Context_Displays_Insert_Input = {
  context?: Maybe<Contexts_Obj_Rel_Insert_Input>;
  context_display_fields?: Maybe<Context_Display_Field_Arr_Rel_Insert_Input>;
  context_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['smallint']>;
};

/** order by max() on columns of table "context_displays" */
export type Context_Displays_Max_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** order by min() on columns of table "context_displays" */
export type Context_Displays_Min_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** response of any mutation on the table "context_displays" */
export type Context_Displays_Mutation_Response = {
  __typename?: 'context_displays_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Context_Displays>;
};

/** input type for inserting object relation for remote table "context_displays" */
export type Context_Displays_Obj_Rel_Insert_Input = {
  data: Context_Displays_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Context_Displays_On_Conflict>;
};

/** on_conflict condition type for table "context_displays" */
export type Context_Displays_On_Conflict = {
  constraint: Context_Displays_Constraint;
  update_columns?: Array<Context_Displays_Update_Column>;
  where?: Maybe<Context_Displays_Bool_Exp>;
};

/** Ordering options when selecting data from "context_displays". */
export type Context_Displays_Order_By = {
  context?: Maybe<Contexts_Order_By>;
  context_display_fields_aggregate?: Maybe<Context_Display_Field_Aggregate_Order_By>;
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** primary key columns input for table: context_displays */
export type Context_Displays_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "context_displays" */
export enum Context_Displays_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position'
}

/** input type for updating data in table "context_displays" */
export type Context_Displays_Set_Input = {
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['smallint']>;
};

/** order by stddev() on columns of table "context_displays" */
export type Context_Displays_Stddev_Order_By = {
  position?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "context_displays" */
export type Context_Displays_Stddev_Pop_Order_By = {
  position?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "context_displays" */
export type Context_Displays_Stddev_Samp_Order_By = {
  position?: Maybe<Order_By>;
};

/** Streaming cursor of the table "context_displays" */
export type Context_Displays_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Context_Displays_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Context_Displays_Stream_Cursor_Value_Input = {
  context_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "context_displays" */
export type Context_Displays_Sum_Order_By = {
  position?: Maybe<Order_By>;
};

/** update columns of table "context_displays" */
export enum Context_Displays_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position'
}

export type Context_Displays_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Context_Displays_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Context_Displays_Set_Input>;
  where: Context_Displays_Bool_Exp;
};

/** order by var_pop() on columns of table "context_displays" */
export type Context_Displays_Var_Pop_Order_By = {
  position?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "context_displays" */
export type Context_Displays_Var_Samp_Order_By = {
  position?: Maybe<Order_By>;
};

/** order by variance() on columns of table "context_displays" */
export type Context_Displays_Variance_Order_By = {
  position?: Maybe<Order_By>;
};

/** columns and relationships of "contexts" */
export type Contexts = {
  __typename?: 'contexts';
  /** An object relationship */
  category: Categories;
  category_id: Scalars['uuid'];
  /** An array relationship */
  context_displays: Array<Context_Displays>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  fields: Array<Fields>;
  /** An aggregate relationship */
  fields_aggregate: Fields_Aggregate;
  id: Scalars['uuid'];
  name: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "contexts" */
export type ContextsContext_DisplaysArgs = {
  distinct_on?: Maybe<Array<Context_Displays_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Displays_Order_By>>;
  where?: Maybe<Context_Displays_Bool_Exp>;
};


/** columns and relationships of "contexts" */
export type ContextsFieldsArgs = {
  distinct_on?: Maybe<Array<Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fields_Order_By>>;
  where?: Maybe<Fields_Bool_Exp>;
};


/** columns and relationships of "contexts" */
export type ContextsFields_AggregateArgs = {
  distinct_on?: Maybe<Array<Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fields_Order_By>>;
  where?: Maybe<Fields_Bool_Exp>;
};

/** aggregated selection of "contexts" */
export type Contexts_Aggregate = {
  __typename?: 'contexts_aggregate';
  aggregate?: Maybe<Contexts_Aggregate_Fields>;
  nodes: Array<Contexts>;
};

/** aggregate fields of "contexts" */
export type Contexts_Aggregate_Fields = {
  __typename?: 'contexts_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Contexts_Max_Fields>;
  min?: Maybe<Contexts_Min_Fields>;
};


/** aggregate fields of "contexts" */
export type Contexts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Contexts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contexts" */
export type Contexts_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Contexts_Max_Order_By>;
  min?: Maybe<Contexts_Min_Order_By>;
};

/** input type for inserting array relation for remote table "contexts" */
export type Contexts_Arr_Rel_Insert_Input = {
  data: Array<Contexts_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Contexts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "contexts". All fields are combined with a logical 'AND'. */
export type Contexts_Bool_Exp = {
  _and?: Maybe<Array<Contexts_Bool_Exp>>;
  _not?: Maybe<Contexts_Bool_Exp>;
  _or?: Maybe<Array<Contexts_Bool_Exp>>;
  category?: Maybe<Categories_Bool_Exp>;
  category_id?: Maybe<Uuid_Comparison_Exp>;
  context_displays?: Maybe<Context_Displays_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  fields?: Maybe<Fields_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "contexts" */
export enum Contexts_Constraint {
  /** unique or primary key constraint on columns "name", "category_id" */
  ContextsNameCategoryIdUnique = 'contexts_name_category_id_unique',
  /** unique or primary key constraint on columns "id" */
  ContextsPkey = 'contexts_pkey'
}

/** input type for inserting data into table "contexts" */
export type Contexts_Insert_Input = {
  category?: Maybe<Categories_Obj_Rel_Insert_Input>;
  category_id?: Maybe<Scalars['uuid']>;
  context_displays?: Maybe<Context_Displays_Arr_Rel_Insert_Input>;
  fields?: Maybe<Fields_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Contexts_Max_Fields = {
  __typename?: 'contexts_max_fields';
  category_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "contexts" */
export type Contexts_Max_Order_By = {
  category_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Contexts_Min_Fields = {
  __typename?: 'contexts_min_fields';
  category_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "contexts" */
export type Contexts_Min_Order_By = {
  category_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "contexts" */
export type Contexts_Mutation_Response = {
  __typename?: 'contexts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contexts>;
};

/** input type for inserting object relation for remote table "contexts" */
export type Contexts_Obj_Rel_Insert_Input = {
  data: Contexts_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Contexts_On_Conflict>;
};

/** on_conflict condition type for table "contexts" */
export type Contexts_On_Conflict = {
  constraint: Contexts_Constraint;
  update_columns?: Array<Contexts_Update_Column>;
  where?: Maybe<Contexts_Bool_Exp>;
};

/** Ordering options when selecting data from "contexts". */
export type Contexts_Order_By = {
  category?: Maybe<Categories_Order_By>;
  category_id?: Maybe<Order_By>;
  context_displays_aggregate?: Maybe<Context_Displays_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  fields_aggregate?: Maybe<Fields_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: contexts */
export type Contexts_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "contexts" */
export enum Contexts_Select_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "contexts" */
export type Contexts_Set_Input = {
  category_id?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "contexts" */
export type Contexts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contexts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contexts_Stream_Cursor_Value_Input = {
  category_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "contexts" */
export enum Contexts_Update_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Name = 'name'
}

export type Contexts_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Contexts_Set_Input>;
  where: Contexts_Bool_Exp;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "customers" */
export type Customers = {
  __typename?: 'customers';
  codename: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An array relationship */
  projects: Array<Projects>;
  /** An aggregate relationship */
  projects_aggregate: Projects_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "customers" */
export type CustomersProjectsArgs = {
  distinct_on?: Maybe<Array<Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Projects_Order_By>>;
  where?: Maybe<Projects_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersProjects_AggregateArgs = {
  distinct_on?: Maybe<Array<Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Projects_Order_By>>;
  where?: Maybe<Projects_Bool_Exp>;
};

/** aggregated selection of "customers" */
export type Customers_Aggregate = {
  __typename?: 'customers_aggregate';
  aggregate?: Maybe<Customers_Aggregate_Fields>;
  nodes: Array<Customers>;
};

/** aggregate fields of "customers" */
export type Customers_Aggregate_Fields = {
  __typename?: 'customers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customers_Max_Fields>;
  min?: Maybe<Customers_Min_Fields>;
};


/** aggregate fields of "customers" */
export type Customers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Customers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "customers". All fields are combined with a logical 'AND'. */
export type Customers_Bool_Exp = {
  _and?: Maybe<Array<Customers_Bool_Exp>>;
  _not?: Maybe<Customers_Bool_Exp>;
  _or?: Maybe<Array<Customers_Bool_Exp>>;
  codename?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  projects?: Maybe<Projects_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "customers" */
export enum Customers_Constraint {
  /** unique or primary key constraint on columns "codename" */
  CustomersCodenameUnique = 'customers_codename_unique',
  /** unique or primary key constraint on columns "id" */
  CustomersPkey = 'customers_pkey'
}

/** input type for inserting data into table "customers" */
export type Customers_Insert_Input = {
  codename?: Maybe<Scalars['String']>;
  projects?: Maybe<Projects_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Customers_Max_Fields = {
  __typename?: 'customers_max_fields';
  codename?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Customers_Min_Fields = {
  __typename?: 'customers_min_fields';
  codename?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "customers" */
export type Customers_Mutation_Response = {
  __typename?: 'customers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customers>;
};

/** input type for inserting object relation for remote table "customers" */
export type Customers_Obj_Rel_Insert_Input = {
  data: Customers_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Customers_On_Conflict>;
};

/** on_conflict condition type for table "customers" */
export type Customers_On_Conflict = {
  constraint: Customers_Constraint;
  update_columns?: Array<Customers_Update_Column>;
  where?: Maybe<Customers_Bool_Exp>;
};

/** Ordering options when selecting data from "customers". */
export type Customers_Order_By = {
  codename?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  projects_aggregate?: Maybe<Projects_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: customers */
export type Customers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "customers" */
export enum Customers_Select_Column {
  /** column name */
  Codename = 'codename',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "customers" */
export type Customers_Set_Input = {
  codename?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "customers" */
export type Customers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customers_Stream_Cursor_Value_Input = {
  codename?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "customers" */
export enum Customers_Update_Column {
  /** column name */
  Codename = 'codename',
  /** column name */
  DeletedAt = 'deleted_at'
}

export type Customers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Customers_Set_Input>;
  where: Customers_Bool_Exp;
};


/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

/** columns and relationships of "deliverables" */
export type Deliverables = {
  __typename?: 'deliverables';
  /** An array relationship */
  checklist_deliverables: Array<Bringup_Checklist_Deliverable>;
  /** An aggregate relationship */
  checklist_deliverables_aggregate: Bringup_Checklist_Deliverable_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  deliverable_templates: Array<Performance_Report_Deliverable_Template>;
  /** An aggregate relationship */
  deliverable_templates_aggregate: Performance_Report_Deliverable_Template_Aggregate;
  description?: Maybe<Scalars['String']>;
  /** An object relationship */
  ground_truth_type?: Maybe<Ground_Truth_Types>;
  ground_truth_type_id: Scalars['uuid'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  num_states: Scalars['smallint'];
  /** An array relationship */
  projects: Array<Projects>;
  /** An aggregate relationship */
  projects_aggregate: Projects_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "deliverables" */
export type DeliverablesChecklist_DeliverablesArgs = {
  distinct_on?: Maybe<Array<Bringup_Checklist_Deliverable_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Checklist_Deliverable_Order_By>>;
  where?: Maybe<Bringup_Checklist_Deliverable_Bool_Exp>;
};


/** columns and relationships of "deliverables" */
export type DeliverablesChecklist_Deliverables_AggregateArgs = {
  distinct_on?: Maybe<Array<Bringup_Checklist_Deliverable_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Checklist_Deliverable_Order_By>>;
  where?: Maybe<Bringup_Checklist_Deliverable_Bool_Exp>;
};


/** columns and relationships of "deliverables" */
export type DeliverablesDeliverable_TemplatesArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Deliverable_Template_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Deliverable_Template_Order_By>>;
  where?: Maybe<Performance_Report_Deliverable_Template_Bool_Exp>;
};


/** columns and relationships of "deliverables" */
export type DeliverablesDeliverable_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Deliverable_Template_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Deliverable_Template_Order_By>>;
  where?: Maybe<Performance_Report_Deliverable_Template_Bool_Exp>;
};


/** columns and relationships of "deliverables" */
export type DeliverablesProjectsArgs = {
  distinct_on?: Maybe<Array<Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Projects_Order_By>>;
  where?: Maybe<Projects_Bool_Exp>;
};


/** columns and relationships of "deliverables" */
export type DeliverablesProjects_AggregateArgs = {
  distinct_on?: Maybe<Array<Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Projects_Order_By>>;
  where?: Maybe<Projects_Bool_Exp>;
};

/** aggregated selection of "deliverables" */
export type Deliverables_Aggregate = {
  __typename?: 'deliverables_aggregate';
  aggregate?: Maybe<Deliverables_Aggregate_Fields>;
  nodes: Array<Deliverables>;
};

/** aggregate fields of "deliverables" */
export type Deliverables_Aggregate_Fields = {
  __typename?: 'deliverables_aggregate_fields';
  avg?: Maybe<Deliverables_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Deliverables_Max_Fields>;
  min?: Maybe<Deliverables_Min_Fields>;
  stddev?: Maybe<Deliverables_Stddev_Fields>;
  stddev_pop?: Maybe<Deliverables_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Deliverables_Stddev_Samp_Fields>;
  sum?: Maybe<Deliverables_Sum_Fields>;
  var_pop?: Maybe<Deliverables_Var_Pop_Fields>;
  var_samp?: Maybe<Deliverables_Var_Samp_Fields>;
  variance?: Maybe<Deliverables_Variance_Fields>;
};


/** aggregate fields of "deliverables" */
export type Deliverables_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Deliverables_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Deliverables_Avg_Fields = {
  __typename?: 'deliverables_avg_fields';
  num_states?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "deliverables". All fields are combined with a logical 'AND'. */
export type Deliverables_Bool_Exp = {
  _and?: Maybe<Array<Deliverables_Bool_Exp>>;
  _not?: Maybe<Deliverables_Bool_Exp>;
  _or?: Maybe<Array<Deliverables_Bool_Exp>>;
  checklist_deliverables?: Maybe<Bringup_Checklist_Deliverable_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  deliverable_templates?: Maybe<Performance_Report_Deliverable_Template_Bool_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  ground_truth_type?: Maybe<Ground_Truth_Types_Bool_Exp>;
  ground_truth_type_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  num_states?: Maybe<Smallint_Comparison_Exp>;
  projects?: Maybe<Projects_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "deliverables" */
export enum Deliverables_Constraint {
  /** unique or primary key constraint on columns "name" */
  DeliverablesNameUnique = 'deliverables_name_unique',
  /** unique or primary key constraint on columns "id" */
  DeliverablesPkey = 'deliverables_pkey'
}

/** input type for incrementing numeric columns in table "deliverables" */
export type Deliverables_Inc_Input = {
  num_states?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "deliverables" */
export type Deliverables_Insert_Input = {
  checklist_deliverables?: Maybe<Bringup_Checklist_Deliverable_Arr_Rel_Insert_Input>;
  deliverable_templates?: Maybe<Performance_Report_Deliverable_Template_Arr_Rel_Insert_Input>;
  description?: Maybe<Scalars['String']>;
  ground_truth_type?: Maybe<Ground_Truth_Types_Obj_Rel_Insert_Input>;
  ground_truth_type_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  num_states?: Maybe<Scalars['smallint']>;
  projects?: Maybe<Projects_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Deliverables_Max_Fields = {
  __typename?: 'deliverables_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  ground_truth_type_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  num_states?: Maybe<Scalars['smallint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Deliverables_Min_Fields = {
  __typename?: 'deliverables_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  ground_truth_type_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  num_states?: Maybe<Scalars['smallint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "deliverables" */
export type Deliverables_Mutation_Response = {
  __typename?: 'deliverables_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deliverables>;
};

/** input type for inserting object relation for remote table "deliverables" */
export type Deliverables_Obj_Rel_Insert_Input = {
  data: Deliverables_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Deliverables_On_Conflict>;
};

/** on_conflict condition type for table "deliverables" */
export type Deliverables_On_Conflict = {
  constraint: Deliverables_Constraint;
  update_columns?: Array<Deliverables_Update_Column>;
  where?: Maybe<Deliverables_Bool_Exp>;
};

/** Ordering options when selecting data from "deliverables". */
export type Deliverables_Order_By = {
  checklist_deliverables_aggregate?: Maybe<Bringup_Checklist_Deliverable_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  deliverable_templates_aggregate?: Maybe<Performance_Report_Deliverable_Template_Aggregate_Order_By>;
  description?: Maybe<Order_By>;
  ground_truth_type?: Maybe<Ground_Truth_Types_Order_By>;
  ground_truth_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  num_states?: Maybe<Order_By>;
  projects_aggregate?: Maybe<Projects_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: deliverables */
export type Deliverables_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "deliverables" */
export enum Deliverables_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  GroundTruthTypeId = 'ground_truth_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NumStates = 'num_states',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "deliverables" */
export type Deliverables_Set_Input = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  ground_truth_type_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  num_states?: Maybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type Deliverables_Stddev_Fields = {
  __typename?: 'deliverables_stddev_fields';
  num_states?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Deliverables_Stddev_Pop_Fields = {
  __typename?: 'deliverables_stddev_pop_fields';
  num_states?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Deliverables_Stddev_Samp_Fields = {
  __typename?: 'deliverables_stddev_samp_fields';
  num_states?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "deliverables" */
export type Deliverables_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deliverables_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deliverables_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  ground_truth_type_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  num_states?: Maybe<Scalars['smallint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Deliverables_Sum_Fields = {
  __typename?: 'deliverables_sum_fields';
  num_states?: Maybe<Scalars['smallint']>;
};

/** update columns of table "deliverables" */
export enum Deliverables_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  GroundTruthTypeId = 'ground_truth_type_id',
  /** column name */
  Name = 'name',
  /** column name */
  NumStates = 'num_states'
}

export type Deliverables_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Deliverables_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Deliverables_Set_Input>;
  where: Deliverables_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Deliverables_Var_Pop_Fields = {
  __typename?: 'deliverables_var_pop_fields';
  num_states?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Deliverables_Var_Samp_Fields = {
  __typename?: 'deliverables_var_samp_fields';
  num_states?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Deliverables_Variance_Fields = {
  __typename?: 'deliverables_variance_fields';
  num_states?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "deliveries.architectures" */
export type Deliveries_Architectures = {
  __typename?: 'deliveries_architectures';
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  packages: Array<Deliveries_Packages>;
  /** An aggregate relationship */
  packages_aggregate: Deliveries_Packages_Aggregate;
};


/** columns and relationships of "deliveries.architectures" */
export type Deliveries_ArchitecturesPackagesArgs = {
  distinct_on?: Maybe<Array<Deliveries_Packages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Packages_Order_By>>;
  where?: Maybe<Deliveries_Packages_Bool_Exp>;
};


/** columns and relationships of "deliveries.architectures" */
export type Deliveries_ArchitecturesPackages_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Packages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Packages_Order_By>>;
  where?: Maybe<Deliveries_Packages_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "deliveries.architectures". All fields are combined with a logical 'AND'. */
export type Deliveries_Architectures_Bool_Exp = {
  _and?: Maybe<Array<Deliveries_Architectures_Bool_Exp>>;
  _not?: Maybe<Deliveries_Architectures_Bool_Exp>;
  _or?: Maybe<Array<Deliveries_Architectures_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  packages?: Maybe<Deliveries_Packages_Bool_Exp>;
};

/** unique or primary key constraints on table "deliveries.architectures" */
export enum Deliveries_Architectures_Constraint {
  /** unique or primary key constraint on columns "name" */
  ArchitecturesNameKey = 'architectures_name_key',
  /** unique or primary key constraint on columns "id" */
  ArchitecturesPkey = 'architectures_pkey'
}

/** input type for inserting data into table "deliveries.architectures" */
export type Deliveries_Architectures_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  packages?: Maybe<Deliveries_Packages_Arr_Rel_Insert_Input>;
};

/** response of any mutation on the table "deliveries.architectures" */
export type Deliveries_Architectures_Mutation_Response = {
  __typename?: 'deliveries_architectures_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deliveries_Architectures>;
};

/** input type for inserting object relation for remote table "deliveries.architectures" */
export type Deliveries_Architectures_Obj_Rel_Insert_Input = {
  data: Deliveries_Architectures_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Deliveries_Architectures_On_Conflict>;
};

/** on_conflict condition type for table "deliveries.architectures" */
export type Deliveries_Architectures_On_Conflict = {
  constraint: Deliveries_Architectures_Constraint;
  update_columns?: Array<Deliveries_Architectures_Update_Column>;
  where?: Maybe<Deliveries_Architectures_Bool_Exp>;
};

/** Ordering options when selecting data from "deliveries.architectures". */
export type Deliveries_Architectures_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  packages_aggregate?: Maybe<Deliveries_Packages_Aggregate_Order_By>;
};

/** primary key columns input for table: deliveries.architectures */
export type Deliveries_Architectures_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "deliveries.architectures" */
export enum Deliveries_Architectures_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "deliveries.architectures" */
export type Deliveries_Architectures_Set_Input = {
  name?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "deliveries_architectures" */
export type Deliveries_Architectures_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deliveries_Architectures_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deliveries_Architectures_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "deliveries.architectures" */
export enum Deliveries_Architectures_Update_Column {
  /** column name */
  Name = 'name'
}

export type Deliveries_Architectures_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Deliveries_Architectures_Set_Input>;
  where: Deliveries_Architectures_Bool_Exp;
};

/** columns and relationships of "deliveries.artifact_microsoft_whql_submission" */
export type Deliveries_Artifact_Microsoft_Whql_Submission = {
  __typename?: 'deliveries_artifact_microsoft_whql_submission';
  /** An object relationship */
  artifact: Deliveries_Artifacts;
  artifact_id: Scalars['uuid'];
  /** An object relationship */
  microsoft_whql_submission: Deliveries_Microsoft_Whql_Submissions;
  microsoft_whql_submission_id: Scalars['uuid'];
};

/** aggregated selection of "deliveries.artifact_microsoft_whql_submission" */
export type Deliveries_Artifact_Microsoft_Whql_Submission_Aggregate = {
  __typename?: 'deliveries_artifact_microsoft_whql_submission_aggregate';
  aggregate?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission_Aggregate_Fields>;
  nodes: Array<Deliveries_Artifact_Microsoft_Whql_Submission>;
};

/** aggregate fields of "deliveries.artifact_microsoft_whql_submission" */
export type Deliveries_Artifact_Microsoft_Whql_Submission_Aggregate_Fields = {
  __typename?: 'deliveries_artifact_microsoft_whql_submission_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission_Max_Fields>;
  min?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission_Min_Fields>;
};


/** aggregate fields of "deliveries.artifact_microsoft_whql_submission" */
export type Deliveries_Artifact_Microsoft_Whql_Submission_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Deliveries_Artifact_Microsoft_Whql_Submission_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "deliveries.artifact_microsoft_whql_submission" */
export type Deliveries_Artifact_Microsoft_Whql_Submission_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission_Max_Order_By>;
  min?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission_Min_Order_By>;
};

/** input type for inserting array relation for remote table "deliveries.artifact_microsoft_whql_submission" */
export type Deliveries_Artifact_Microsoft_Whql_Submission_Arr_Rel_Insert_Input = {
  data: Array<Deliveries_Artifact_Microsoft_Whql_Submission_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission_On_Conflict>;
};

/** Boolean expression to filter rows from the table "deliveries.artifact_microsoft_whql_submission". All fields are combined with a logical 'AND'. */
export type Deliveries_Artifact_Microsoft_Whql_Submission_Bool_Exp = {
  _and?: Maybe<Array<Deliveries_Artifact_Microsoft_Whql_Submission_Bool_Exp>>;
  _not?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission_Bool_Exp>;
  _or?: Maybe<Array<Deliveries_Artifact_Microsoft_Whql_Submission_Bool_Exp>>;
  artifact?: Maybe<Deliveries_Artifacts_Bool_Exp>;
  artifact_id?: Maybe<Uuid_Comparison_Exp>;
  microsoft_whql_submission?: Maybe<Deliveries_Microsoft_Whql_Submissions_Bool_Exp>;
  microsoft_whql_submission_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "deliveries.artifact_microsoft_whql_submission" */
export enum Deliveries_Artifact_Microsoft_Whql_Submission_Constraint {
  /** unique or primary key constraint on columns "microsoft_whql_submission_id", "artifact_id" */
  ArtifactMicrosoftWhqlSubmissionPkey = 'artifact_microsoft_whql_submission_pkey'
}

/** input type for inserting data into table "deliveries.artifact_microsoft_whql_submission" */
export type Deliveries_Artifact_Microsoft_Whql_Submission_Insert_Input = {
  artifact?: Maybe<Deliveries_Artifacts_Obj_Rel_Insert_Input>;
  artifact_id?: Maybe<Scalars['uuid']>;
  microsoft_whql_submission?: Maybe<Deliveries_Microsoft_Whql_Submissions_Obj_Rel_Insert_Input>;
  microsoft_whql_submission_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Deliveries_Artifact_Microsoft_Whql_Submission_Max_Fields = {
  __typename?: 'deliveries_artifact_microsoft_whql_submission_max_fields';
  artifact_id?: Maybe<Scalars['uuid']>;
  microsoft_whql_submission_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "deliveries.artifact_microsoft_whql_submission" */
export type Deliveries_Artifact_Microsoft_Whql_Submission_Max_Order_By = {
  artifact_id?: Maybe<Order_By>;
  microsoft_whql_submission_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Deliveries_Artifact_Microsoft_Whql_Submission_Min_Fields = {
  __typename?: 'deliveries_artifact_microsoft_whql_submission_min_fields';
  artifact_id?: Maybe<Scalars['uuid']>;
  microsoft_whql_submission_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "deliveries.artifact_microsoft_whql_submission" */
export type Deliveries_Artifact_Microsoft_Whql_Submission_Min_Order_By = {
  artifact_id?: Maybe<Order_By>;
  microsoft_whql_submission_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "deliveries.artifact_microsoft_whql_submission" */
export type Deliveries_Artifact_Microsoft_Whql_Submission_Mutation_Response = {
  __typename?: 'deliveries_artifact_microsoft_whql_submission_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deliveries_Artifact_Microsoft_Whql_Submission>;
};

/** on_conflict condition type for table "deliveries.artifact_microsoft_whql_submission" */
export type Deliveries_Artifact_Microsoft_Whql_Submission_On_Conflict = {
  constraint: Deliveries_Artifact_Microsoft_Whql_Submission_Constraint;
  update_columns?: Array<Deliveries_Artifact_Microsoft_Whql_Submission_Update_Column>;
  where?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission_Bool_Exp>;
};

/** Ordering options when selecting data from "deliveries.artifact_microsoft_whql_submission". */
export type Deliveries_Artifact_Microsoft_Whql_Submission_Order_By = {
  artifact?: Maybe<Deliveries_Artifacts_Order_By>;
  artifact_id?: Maybe<Order_By>;
  microsoft_whql_submission?: Maybe<Deliveries_Microsoft_Whql_Submissions_Order_By>;
  microsoft_whql_submission_id?: Maybe<Order_By>;
};

/** primary key columns input for table: deliveries.artifact_microsoft_whql_submission */
export type Deliveries_Artifact_Microsoft_Whql_Submission_Pk_Columns_Input = {
  artifact_id: Scalars['uuid'];
  microsoft_whql_submission_id: Scalars['uuid'];
};

/** select columns of table "deliveries.artifact_microsoft_whql_submission" */
export enum Deliveries_Artifact_Microsoft_Whql_Submission_Select_Column {
  /** column name */
  ArtifactId = 'artifact_id',
  /** column name */
  MicrosoftWhqlSubmissionId = 'microsoft_whql_submission_id'
}

/** input type for updating data in table "deliveries.artifact_microsoft_whql_submission" */
export type Deliveries_Artifact_Microsoft_Whql_Submission_Set_Input = {
  artifact_id?: Maybe<Scalars['uuid']>;
  microsoft_whql_submission_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "deliveries_artifact_microsoft_whql_submission" */
export type Deliveries_Artifact_Microsoft_Whql_Submission_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deliveries_Artifact_Microsoft_Whql_Submission_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deliveries_Artifact_Microsoft_Whql_Submission_Stream_Cursor_Value_Input = {
  artifact_id?: Maybe<Scalars['uuid']>;
  microsoft_whql_submission_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "deliveries.artifact_microsoft_whql_submission" */
export enum Deliveries_Artifact_Microsoft_Whql_Submission_Update_Column {
  /** column name */
  ArtifactId = 'artifact_id',
  /** column name */
  MicrosoftWhqlSubmissionId = 'microsoft_whql_submission_id'
}

export type Deliveries_Artifact_Microsoft_Whql_Submission_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission_Set_Input>;
  where: Deliveries_Artifact_Microsoft_Whql_Submission_Bool_Exp;
};

/** Link between an artifact and its Microsoft OS signatures */
export type Deliveries_Artifact_Operating_System = {
  __typename?: 'deliveries_artifact_operating_system';
  /** An object relationship */
  artifact: Deliveries_Artifacts;
  artifact_id: Scalars['uuid'];
  /** An object relationship */
  operating_system: Deliveries_Operating_Systems;
  os_id: Scalars['uuid'];
};

/** order by aggregate values of table "deliveries.artifact_operating_system" */
export type Deliveries_Artifact_Operating_System_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Deliveries_Artifact_Operating_System_Max_Order_By>;
  min?: Maybe<Deliveries_Artifact_Operating_System_Min_Order_By>;
};

/** input type for inserting array relation for remote table "deliveries.artifact_operating_system" */
export type Deliveries_Artifact_Operating_System_Arr_Rel_Insert_Input = {
  data: Array<Deliveries_Artifact_Operating_System_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Deliveries_Artifact_Operating_System_On_Conflict>;
};

/** Boolean expression to filter rows from the table "deliveries.artifact_operating_system". All fields are combined with a logical 'AND'. */
export type Deliveries_Artifact_Operating_System_Bool_Exp = {
  _and?: Maybe<Array<Deliveries_Artifact_Operating_System_Bool_Exp>>;
  _not?: Maybe<Deliveries_Artifact_Operating_System_Bool_Exp>;
  _or?: Maybe<Array<Deliveries_Artifact_Operating_System_Bool_Exp>>;
  artifact?: Maybe<Deliveries_Artifacts_Bool_Exp>;
  artifact_id?: Maybe<Uuid_Comparison_Exp>;
  operating_system?: Maybe<Deliveries_Operating_Systems_Bool_Exp>;
  os_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "deliveries.artifact_operating_system" */
export enum Deliveries_Artifact_Operating_System_Constraint {
  /** unique or primary key constraint on columns "os_id", "artifact_id" */
  ArtifactOperatingSystemPkey = 'artifact_operating_system_pkey'
}

/** input type for inserting data into table "deliveries.artifact_operating_system" */
export type Deliveries_Artifact_Operating_System_Insert_Input = {
  artifact?: Maybe<Deliveries_Artifacts_Obj_Rel_Insert_Input>;
  artifact_id?: Maybe<Scalars['uuid']>;
  operating_system?: Maybe<Deliveries_Operating_Systems_Obj_Rel_Insert_Input>;
  os_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "deliveries.artifact_operating_system" */
export type Deliveries_Artifact_Operating_System_Max_Order_By = {
  artifact_id?: Maybe<Order_By>;
  os_id?: Maybe<Order_By>;
};

/** order by min() on columns of table "deliveries.artifact_operating_system" */
export type Deliveries_Artifact_Operating_System_Min_Order_By = {
  artifact_id?: Maybe<Order_By>;
  os_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "deliveries.artifact_operating_system" */
export type Deliveries_Artifact_Operating_System_Mutation_Response = {
  __typename?: 'deliveries_artifact_operating_system_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deliveries_Artifact_Operating_System>;
};

/** on_conflict condition type for table "deliveries.artifact_operating_system" */
export type Deliveries_Artifact_Operating_System_On_Conflict = {
  constraint: Deliveries_Artifact_Operating_System_Constraint;
  update_columns?: Array<Deliveries_Artifact_Operating_System_Update_Column>;
  where?: Maybe<Deliveries_Artifact_Operating_System_Bool_Exp>;
};

/** Ordering options when selecting data from "deliveries.artifact_operating_system". */
export type Deliveries_Artifact_Operating_System_Order_By = {
  artifact?: Maybe<Deliveries_Artifacts_Order_By>;
  artifact_id?: Maybe<Order_By>;
  operating_system?: Maybe<Deliveries_Operating_Systems_Order_By>;
  os_id?: Maybe<Order_By>;
};

/** primary key columns input for table: deliveries.artifact_operating_system */
export type Deliveries_Artifact_Operating_System_Pk_Columns_Input = {
  artifact_id: Scalars['uuid'];
  os_id: Scalars['uuid'];
};

/** select columns of table "deliveries.artifact_operating_system" */
export enum Deliveries_Artifact_Operating_System_Select_Column {
  /** column name */
  ArtifactId = 'artifact_id',
  /** column name */
  OsId = 'os_id'
}

/** input type for updating data in table "deliveries.artifact_operating_system" */
export type Deliveries_Artifact_Operating_System_Set_Input = {
  artifact_id?: Maybe<Scalars['uuid']>;
  os_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "deliveries_artifact_operating_system" */
export type Deliveries_Artifact_Operating_System_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deliveries_Artifact_Operating_System_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deliveries_Artifact_Operating_System_Stream_Cursor_Value_Input = {
  artifact_id?: Maybe<Scalars['uuid']>;
  os_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "deliveries.artifact_operating_system" */
export enum Deliveries_Artifact_Operating_System_Update_Column {
  /** column name */
  ArtifactId = 'artifact_id',
  /** column name */
  OsId = 'os_id'
}

export type Deliveries_Artifact_Operating_System_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Deliveries_Artifact_Operating_System_Set_Input>;
  where: Deliveries_Artifact_Operating_System_Bool_Exp;
};

/** Stores all the artifacts for a given package version */
export type Deliveries_Artifacts = {
  __typename?: 'deliveries_artifacts';
  /** An array relationship */
  artifact_microsoft_whql_submissions: Array<Deliveries_Artifact_Microsoft_Whql_Submission>;
  /** An aggregate relationship */
  artifact_microsoft_whql_submissions_aggregate: Deliveries_Artifact_Microsoft_Whql_Submission_Aggregate;
  /** An array relationship */
  artifact_operating_systems: Array<Deliveries_Artifact_Operating_System>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  package: Deliveries_Packages;
  package_id: Scalars['uuid'];
  sbom_url?: Maybe<Scalars['String']>;
  storage_blob_url: Scalars['String'];
};


/** Stores all the artifacts for a given package version */
export type Deliveries_ArtifactsArtifact_Microsoft_Whql_SubmissionsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Artifact_Microsoft_Whql_Submission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Artifact_Microsoft_Whql_Submission_Order_By>>;
  where?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission_Bool_Exp>;
};


/** Stores all the artifacts for a given package version */
export type Deliveries_ArtifactsArtifact_Microsoft_Whql_Submissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Artifact_Microsoft_Whql_Submission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Artifact_Microsoft_Whql_Submission_Order_By>>;
  where?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission_Bool_Exp>;
};


/** Stores all the artifacts for a given package version */
export type Deliveries_ArtifactsArtifact_Operating_SystemsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Artifact_Operating_System_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Artifact_Operating_System_Order_By>>;
  where?: Maybe<Deliveries_Artifact_Operating_System_Bool_Exp>;
};

/** aggregated selection of "deliveries.artifacts" */
export type Deliveries_Artifacts_Aggregate = {
  __typename?: 'deliveries_artifacts_aggregate';
  aggregate?: Maybe<Deliveries_Artifacts_Aggregate_Fields>;
  nodes: Array<Deliveries_Artifacts>;
};

/** aggregate fields of "deliveries.artifacts" */
export type Deliveries_Artifacts_Aggregate_Fields = {
  __typename?: 'deliveries_artifacts_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Deliveries_Artifacts_Max_Fields>;
  min?: Maybe<Deliveries_Artifacts_Min_Fields>;
};


/** aggregate fields of "deliveries.artifacts" */
export type Deliveries_Artifacts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Deliveries_Artifacts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "deliveries.artifacts" */
export type Deliveries_Artifacts_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Deliveries_Artifacts_Max_Order_By>;
  min?: Maybe<Deliveries_Artifacts_Min_Order_By>;
};

/** input type for inserting array relation for remote table "deliveries.artifacts" */
export type Deliveries_Artifacts_Arr_Rel_Insert_Input = {
  data: Array<Deliveries_Artifacts_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Deliveries_Artifacts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "deliveries.artifacts". All fields are combined with a logical 'AND'. */
export type Deliveries_Artifacts_Bool_Exp = {
  _and?: Maybe<Array<Deliveries_Artifacts_Bool_Exp>>;
  _not?: Maybe<Deliveries_Artifacts_Bool_Exp>;
  _or?: Maybe<Array<Deliveries_Artifacts_Bool_Exp>>;
  artifact_microsoft_whql_submissions?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission_Bool_Exp>;
  artifact_operating_systems?: Maybe<Deliveries_Artifact_Operating_System_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  package?: Maybe<Deliveries_Packages_Bool_Exp>;
  package_id?: Maybe<Uuid_Comparison_Exp>;
  sbom_url?: Maybe<String_Comparison_Exp>;
  storage_blob_url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "deliveries.artifacts" */
export enum Deliveries_Artifacts_Constraint {
  /** unique or primary key constraint on columns "sbom_url", "storage_blob_url", "package_id" */
  ArtifactsPackageIdStorageBlobUrlSbomUrlKey = 'artifacts_package_id_storage_blob_url_sbom_url_key',
  /** unique or primary key constraint on columns "id" */
  ArtifactsPkey = 'artifacts_pkey'
}

/** input type for inserting data into table "deliveries.artifacts" */
export type Deliveries_Artifacts_Insert_Input = {
  artifact_microsoft_whql_submissions?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission_Arr_Rel_Insert_Input>;
  artifact_operating_systems?: Maybe<Deliveries_Artifact_Operating_System_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  package?: Maybe<Deliveries_Packages_Obj_Rel_Insert_Input>;
  package_id?: Maybe<Scalars['uuid']>;
  sbom_url?: Maybe<Scalars['String']>;
  storage_blob_url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Deliveries_Artifacts_Max_Fields = {
  __typename?: 'deliveries_artifacts_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  package_id?: Maybe<Scalars['uuid']>;
  sbom_url?: Maybe<Scalars['String']>;
  storage_blob_url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "deliveries.artifacts" */
export type Deliveries_Artifacts_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  package_id?: Maybe<Order_By>;
  sbom_url?: Maybe<Order_By>;
  storage_blob_url?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Deliveries_Artifacts_Min_Fields = {
  __typename?: 'deliveries_artifacts_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  package_id?: Maybe<Scalars['uuid']>;
  sbom_url?: Maybe<Scalars['String']>;
  storage_blob_url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "deliveries.artifacts" */
export type Deliveries_Artifacts_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  package_id?: Maybe<Order_By>;
  sbom_url?: Maybe<Order_By>;
  storage_blob_url?: Maybe<Order_By>;
};

/** response of any mutation on the table "deliveries.artifacts" */
export type Deliveries_Artifacts_Mutation_Response = {
  __typename?: 'deliveries_artifacts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deliveries_Artifacts>;
};

/** input type for inserting object relation for remote table "deliveries.artifacts" */
export type Deliveries_Artifacts_Obj_Rel_Insert_Input = {
  data: Deliveries_Artifacts_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Deliveries_Artifacts_On_Conflict>;
};

/** on_conflict condition type for table "deliveries.artifacts" */
export type Deliveries_Artifacts_On_Conflict = {
  constraint: Deliveries_Artifacts_Constraint;
  update_columns?: Array<Deliveries_Artifacts_Update_Column>;
  where?: Maybe<Deliveries_Artifacts_Bool_Exp>;
};

/** Ordering options when selecting data from "deliveries.artifacts". */
export type Deliveries_Artifacts_Order_By = {
  artifact_microsoft_whql_submissions_aggregate?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission_Aggregate_Order_By>;
  artifact_operating_systems_aggregate?: Maybe<Deliveries_Artifact_Operating_System_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  package?: Maybe<Deliveries_Packages_Order_By>;
  package_id?: Maybe<Order_By>;
  sbom_url?: Maybe<Order_By>;
  storage_blob_url?: Maybe<Order_By>;
};

/** primary key columns input for table: deliveries.artifacts */
export type Deliveries_Artifacts_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "deliveries.artifacts" */
export enum Deliveries_Artifacts_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PackageId = 'package_id',
  /** column name */
  SbomUrl = 'sbom_url',
  /** column name */
  StorageBlobUrl = 'storage_blob_url'
}

/** input type for updating data in table "deliveries.artifacts" */
export type Deliveries_Artifacts_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  package_id?: Maybe<Scalars['uuid']>;
  sbom_url?: Maybe<Scalars['String']>;
  storage_blob_url?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "deliveries_artifacts" */
export type Deliveries_Artifacts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deliveries_Artifacts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deliveries_Artifacts_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  package_id?: Maybe<Scalars['uuid']>;
  sbom_url?: Maybe<Scalars['String']>;
  storage_blob_url?: Maybe<Scalars['String']>;
};

/** update columns of table "deliveries.artifacts" */
export enum Deliveries_Artifacts_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  PackageId = 'package_id',
  /** column name */
  SbomUrl = 'sbom_url',
  /** column name */
  StorageBlobUrl = 'storage_blob_url'
}

export type Deliveries_Artifacts_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Deliveries_Artifacts_Set_Input>;
  where: Deliveries_Artifacts_Bool_Exp;
};

export enum Deliveries_Config_Id_Types_Enum {
  Chid = 'CHID',
  Ssid = 'SSID',
  Default = 'default'
}

/** Boolean expression to compare columns of type "deliveries_config_id_types_enum". All fields are combined with logical 'AND'. */
export type Deliveries_Config_Id_Types_Enum_Comparison_Exp = {
  _eq?: Maybe<Deliveries_Config_Id_Types_Enum>;
  _in?: Maybe<Array<Deliveries_Config_Id_Types_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Deliveries_Config_Id_Types_Enum>;
  _nin?: Maybe<Array<Deliveries_Config_Id_Types_Enum>>;
};

/** columns and relationships of "deliveries.device_families" */
export type Deliveries_Device_Families = {
  __typename?: 'deliveries_device_families';
  codename: Scalars['String'];
  id: Scalars['uuid'];
  /** An array relationship */
  microsoft_hlk_artifacts: Array<Deliveries_Microsoft_Hlk_Artifacts>;
  /** An aggregate relationship */
  microsoft_hlk_artifacts_aggregate: Deliveries_Microsoft_Hlk_Artifacts_Aggregate;
  name: Scalars['String'];
};


/** columns and relationships of "deliveries.device_families" */
export type Deliveries_Device_FamiliesMicrosoft_Hlk_ArtifactsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Microsoft_Hlk_Artifacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Microsoft_Hlk_Artifacts_Order_By>>;
  where?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_Bool_Exp>;
};


/** columns and relationships of "deliveries.device_families" */
export type Deliveries_Device_FamiliesMicrosoft_Hlk_Artifacts_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Microsoft_Hlk_Artifacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Microsoft_Hlk_Artifacts_Order_By>>;
  where?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "deliveries.device_families". All fields are combined with a logical 'AND'. */
export type Deliveries_Device_Families_Bool_Exp = {
  _and?: Maybe<Array<Deliveries_Device_Families_Bool_Exp>>;
  _not?: Maybe<Deliveries_Device_Families_Bool_Exp>;
  _or?: Maybe<Array<Deliveries_Device_Families_Bool_Exp>>;
  codename?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  microsoft_hlk_artifacts?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "deliveries.device_families" */
export enum Deliveries_Device_Families_Constraint {
  /** unique or primary key constraint on columns "codename" */
  DeviceFamiliesCodenameKey = 'device_families_codename_key',
  /** unique or primary key constraint on columns "id" */
  DeviceFamiliesPkey = 'device_families_pkey'
}

/** input type for inserting data into table "deliveries.device_families" */
export type Deliveries_Device_Families_Insert_Input = {
  codename?: Maybe<Scalars['String']>;
  microsoft_hlk_artifacts?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "deliveries.device_families" */
export type Deliveries_Device_Families_Mutation_Response = {
  __typename?: 'deliveries_device_families_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deliveries_Device_Families>;
};

/** input type for inserting object relation for remote table "deliveries.device_families" */
export type Deliveries_Device_Families_Obj_Rel_Insert_Input = {
  data: Deliveries_Device_Families_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Deliveries_Device_Families_On_Conflict>;
};

/** on_conflict condition type for table "deliveries.device_families" */
export type Deliveries_Device_Families_On_Conflict = {
  constraint: Deliveries_Device_Families_Constraint;
  update_columns?: Array<Deliveries_Device_Families_Update_Column>;
  where?: Maybe<Deliveries_Device_Families_Bool_Exp>;
};

/** Ordering options when selecting data from "deliveries.device_families". */
export type Deliveries_Device_Families_Order_By = {
  codename?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  microsoft_hlk_artifacts_aggregate?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: deliveries.device_families */
export type Deliveries_Device_Families_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "deliveries.device_families" */
export enum Deliveries_Device_Families_Select_Column {
  /** column name */
  Codename = 'codename',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "deliveries.device_families" */
export type Deliveries_Device_Families_Set_Input = {
  codename?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "deliveries_device_families" */
export type Deliveries_Device_Families_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deliveries_Device_Families_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deliveries_Device_Families_Stream_Cursor_Value_Input = {
  codename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "deliveries.device_families" */
export enum Deliveries_Device_Families_Update_Column {
  /** column name */
  Codename = 'codename',
  /** column name */
  Name = 'name'
}

export type Deliveries_Device_Families_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Deliveries_Device_Families_Set_Input>;
  where: Deliveries_Device_Families_Bool_Exp;
};

/** use to attach a driver to a package; only used to make it easier to populate the package form on manager site */
export type Deliveries_Driver_Package = {
  __typename?: 'deliveries_driver_package';
  /** An object relationship */
  driver: Deliveries_Drivers;
  driver_id: Scalars['uuid'];
  /** An object relationship */
  package: Deliveries_Packages;
  package_id: Scalars['uuid'];
};

/** aggregated selection of "deliveries.driver_package" */
export type Deliveries_Driver_Package_Aggregate = {
  __typename?: 'deliveries_driver_package_aggregate';
  aggregate?: Maybe<Deliveries_Driver_Package_Aggregate_Fields>;
  nodes: Array<Deliveries_Driver_Package>;
};

/** aggregate fields of "deliveries.driver_package" */
export type Deliveries_Driver_Package_Aggregate_Fields = {
  __typename?: 'deliveries_driver_package_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Deliveries_Driver_Package_Max_Fields>;
  min?: Maybe<Deliveries_Driver_Package_Min_Fields>;
};


/** aggregate fields of "deliveries.driver_package" */
export type Deliveries_Driver_Package_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Deliveries_Driver_Package_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "deliveries.driver_package" */
export type Deliveries_Driver_Package_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Deliveries_Driver_Package_Max_Order_By>;
  min?: Maybe<Deliveries_Driver_Package_Min_Order_By>;
};

/** input type for inserting array relation for remote table "deliveries.driver_package" */
export type Deliveries_Driver_Package_Arr_Rel_Insert_Input = {
  data: Array<Deliveries_Driver_Package_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Deliveries_Driver_Package_On_Conflict>;
};

/** Boolean expression to filter rows from the table "deliveries.driver_package". All fields are combined with a logical 'AND'. */
export type Deliveries_Driver_Package_Bool_Exp = {
  _and?: Maybe<Array<Deliveries_Driver_Package_Bool_Exp>>;
  _not?: Maybe<Deliveries_Driver_Package_Bool_Exp>;
  _or?: Maybe<Array<Deliveries_Driver_Package_Bool_Exp>>;
  driver?: Maybe<Deliveries_Drivers_Bool_Exp>;
  driver_id?: Maybe<Uuid_Comparison_Exp>;
  package?: Maybe<Deliveries_Packages_Bool_Exp>;
  package_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "deliveries.driver_package" */
export enum Deliveries_Driver_Package_Constraint {
  /** unique or primary key constraint on columns "driver_id", "package_id" */
  DriverPackagePkey = 'driver_package_pkey'
}

/** input type for inserting data into table "deliveries.driver_package" */
export type Deliveries_Driver_Package_Insert_Input = {
  driver?: Maybe<Deliveries_Drivers_Obj_Rel_Insert_Input>;
  driver_id?: Maybe<Scalars['uuid']>;
  package?: Maybe<Deliveries_Packages_Obj_Rel_Insert_Input>;
  package_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Deliveries_Driver_Package_Max_Fields = {
  __typename?: 'deliveries_driver_package_max_fields';
  driver_id?: Maybe<Scalars['uuid']>;
  package_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "deliveries.driver_package" */
export type Deliveries_Driver_Package_Max_Order_By = {
  driver_id?: Maybe<Order_By>;
  package_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Deliveries_Driver_Package_Min_Fields = {
  __typename?: 'deliveries_driver_package_min_fields';
  driver_id?: Maybe<Scalars['uuid']>;
  package_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "deliveries.driver_package" */
export type Deliveries_Driver_Package_Min_Order_By = {
  driver_id?: Maybe<Order_By>;
  package_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "deliveries.driver_package" */
export type Deliveries_Driver_Package_Mutation_Response = {
  __typename?: 'deliveries_driver_package_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deliveries_Driver_Package>;
};

/** on_conflict condition type for table "deliveries.driver_package" */
export type Deliveries_Driver_Package_On_Conflict = {
  constraint: Deliveries_Driver_Package_Constraint;
  update_columns?: Array<Deliveries_Driver_Package_Update_Column>;
  where?: Maybe<Deliveries_Driver_Package_Bool_Exp>;
};

/** Ordering options when selecting data from "deliveries.driver_package". */
export type Deliveries_Driver_Package_Order_By = {
  driver?: Maybe<Deliveries_Drivers_Order_By>;
  driver_id?: Maybe<Order_By>;
  package?: Maybe<Deliveries_Packages_Order_By>;
  package_id?: Maybe<Order_By>;
};

/** primary key columns input for table: deliveries.driver_package */
export type Deliveries_Driver_Package_Pk_Columns_Input = {
  driver_id: Scalars['uuid'];
  package_id: Scalars['uuid'];
};

/** select columns of table "deliveries.driver_package" */
export enum Deliveries_Driver_Package_Select_Column {
  /** column name */
  DriverId = 'driver_id',
  /** column name */
  PackageId = 'package_id'
}

/** input type for updating data in table "deliveries.driver_package" */
export type Deliveries_Driver_Package_Set_Input = {
  driver_id?: Maybe<Scalars['uuid']>;
  package_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "deliveries_driver_package" */
export type Deliveries_Driver_Package_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deliveries_Driver_Package_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deliveries_Driver_Package_Stream_Cursor_Value_Input = {
  driver_id?: Maybe<Scalars['uuid']>;
  package_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "deliveries.driver_package" */
export enum Deliveries_Driver_Package_Update_Column {
  /** column name */
  DriverId = 'driver_id',
  /** column name */
  PackageId = 'package_id'
}

export type Deliveries_Driver_Package_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Deliveries_Driver_Package_Set_Input>;
  where: Deliveries_Driver_Package_Bool_Exp;
};

/** used to attach a driver to a single project, typically for "topology" type drivers */
export type Deliveries_Driver_Project = {
  __typename?: 'deliveries_driver_project';
  /** An object relationship */
  driver: Deliveries_Drivers;
  driver_id: Scalars['uuid'];
  /** An array relationship */
  driver_version_engines: Array<Deliveries_Driver_Version_Engine>;
  /** An aggregate relationship */
  driver_version_engines_aggregate: Deliveries_Driver_Version_Engine_Aggregate;
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
};


/** used to attach a driver to a single project, typically for "topology" type drivers */
export type Deliveries_Driver_ProjectDriver_Version_EnginesArgs = {
  distinct_on?: Maybe<Array<Deliveries_Driver_Version_Engine_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Driver_Version_Engine_Order_By>>;
  where?: Maybe<Deliveries_Driver_Version_Engine_Bool_Exp>;
};


/** used to attach a driver to a single project, typically for "topology" type drivers */
export type Deliveries_Driver_ProjectDriver_Version_Engines_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Driver_Version_Engine_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Driver_Version_Engine_Order_By>>;
  where?: Maybe<Deliveries_Driver_Version_Engine_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "deliveries.driver_project". All fields are combined with a logical 'AND'. */
export type Deliveries_Driver_Project_Bool_Exp = {
  _and?: Maybe<Array<Deliveries_Driver_Project_Bool_Exp>>;
  _not?: Maybe<Deliveries_Driver_Project_Bool_Exp>;
  _or?: Maybe<Array<Deliveries_Driver_Project_Bool_Exp>>;
  driver?: Maybe<Deliveries_Drivers_Bool_Exp>;
  driver_id?: Maybe<Uuid_Comparison_Exp>;
  driver_version_engines?: Maybe<Deliveries_Driver_Version_Engine_Bool_Exp>;
  project?: Maybe<Projects_Bool_Exp>;
  project_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "deliveries.driver_project" */
export enum Deliveries_Driver_Project_Constraint {
  /** unique or primary key constraint on columns "driver_id" */
  DriverProjectPkey = 'driver_project_pkey',
  /** unique or primary key constraint on columns "project_id" */
  DriverProjectProjectIdKey = 'driver_project_project_id_key'
}

/** input type for inserting data into table "deliveries.driver_project" */
export type Deliveries_Driver_Project_Insert_Input = {
  driver?: Maybe<Deliveries_Drivers_Obj_Rel_Insert_Input>;
  driver_id?: Maybe<Scalars['uuid']>;
  driver_version_engines?: Maybe<Deliveries_Driver_Version_Engine_Arr_Rel_Insert_Input>;
  project?: Maybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "deliveries.driver_project" */
export type Deliveries_Driver_Project_Mutation_Response = {
  __typename?: 'deliveries_driver_project_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deliveries_Driver_Project>;
};

/** input type for inserting object relation for remote table "deliveries.driver_project" */
export type Deliveries_Driver_Project_Obj_Rel_Insert_Input = {
  data: Deliveries_Driver_Project_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Deliveries_Driver_Project_On_Conflict>;
};

/** on_conflict condition type for table "deliveries.driver_project" */
export type Deliveries_Driver_Project_On_Conflict = {
  constraint: Deliveries_Driver_Project_Constraint;
  update_columns?: Array<Deliveries_Driver_Project_Update_Column>;
  where?: Maybe<Deliveries_Driver_Project_Bool_Exp>;
};

/** Ordering options when selecting data from "deliveries.driver_project". */
export type Deliveries_Driver_Project_Order_By = {
  driver?: Maybe<Deliveries_Drivers_Order_By>;
  driver_id?: Maybe<Order_By>;
  driver_version_engines_aggregate?: Maybe<Deliveries_Driver_Version_Engine_Aggregate_Order_By>;
  project?: Maybe<Projects_Order_By>;
  project_id?: Maybe<Order_By>;
};

/** primary key columns input for table: deliveries.driver_project */
export type Deliveries_Driver_Project_Pk_Columns_Input = {
  driver_id: Scalars['uuid'];
};

/** input type for updating data in table "deliveries.driver_project" */
export type Deliveries_Driver_Project_Set_Input = {
  driver_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "deliveries.driver_project" */
export enum Deliveries_Driver_Project_Update_Column {
  /** column name */
  DriverId = 'driver_id',
  /** column name */
  ProjectId = 'project_id'
}

export type Deliveries_Driver_Project_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Deliveries_Driver_Project_Set_Input>;
  where: Deliveries_Driver_Project_Bool_Exp;
};

/** use to attach driver version to a single engine, typically for versions of "typology" type drivers */
export type Deliveries_Driver_Version_Engine = {
  __typename?: 'deliveries_driver_version_engine';
  driver_id: Scalars['uuid'];
  /** An object relationship */
  driver_project: Deliveries_Driver_Project;
  /** An object relationship */
  driver_version?: Maybe<Deliveries_Driver_Versions>;
  driver_version_id: Scalars['uuid'];
  /** An object relationship */
  engine: Engines;
  engine_id: Scalars['uuid'];
};

/** aggregated selection of "deliveries.driver_version_engine" */
export type Deliveries_Driver_Version_Engine_Aggregate = {
  __typename?: 'deliveries_driver_version_engine_aggregate';
  aggregate?: Maybe<Deliveries_Driver_Version_Engine_Aggregate_Fields>;
  nodes: Array<Deliveries_Driver_Version_Engine>;
};

/** aggregate fields of "deliveries.driver_version_engine" */
export type Deliveries_Driver_Version_Engine_Aggregate_Fields = {
  __typename?: 'deliveries_driver_version_engine_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Deliveries_Driver_Version_Engine_Max_Fields>;
  min?: Maybe<Deliveries_Driver_Version_Engine_Min_Fields>;
};


/** aggregate fields of "deliveries.driver_version_engine" */
export type Deliveries_Driver_Version_Engine_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Deliveries_Driver_Version_Engine_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "deliveries.driver_version_engine" */
export type Deliveries_Driver_Version_Engine_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Deliveries_Driver_Version_Engine_Max_Order_By>;
  min?: Maybe<Deliveries_Driver_Version_Engine_Min_Order_By>;
};

/** input type for inserting array relation for remote table "deliveries.driver_version_engine" */
export type Deliveries_Driver_Version_Engine_Arr_Rel_Insert_Input = {
  data: Array<Deliveries_Driver_Version_Engine_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Deliveries_Driver_Version_Engine_On_Conflict>;
};

/** Boolean expression to filter rows from the table "deliveries.driver_version_engine". All fields are combined with a logical 'AND'. */
export type Deliveries_Driver_Version_Engine_Bool_Exp = {
  _and?: Maybe<Array<Deliveries_Driver_Version_Engine_Bool_Exp>>;
  _not?: Maybe<Deliveries_Driver_Version_Engine_Bool_Exp>;
  _or?: Maybe<Array<Deliveries_Driver_Version_Engine_Bool_Exp>>;
  driver_id?: Maybe<Uuid_Comparison_Exp>;
  driver_project?: Maybe<Deliveries_Driver_Project_Bool_Exp>;
  driver_version?: Maybe<Deliveries_Driver_Versions_Bool_Exp>;
  driver_version_id?: Maybe<Uuid_Comparison_Exp>;
  engine?: Maybe<Engines_Bool_Exp>;
  engine_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "deliveries.driver_version_engine" */
export enum Deliveries_Driver_Version_Engine_Constraint {
  /** unique or primary key constraint on columns "driver_id", "driver_version_id" */
  DriverVersionEnginePkey = 'driver_version_engine_pkey'
}

/** input type for inserting data into table "deliveries.driver_version_engine" */
export type Deliveries_Driver_Version_Engine_Insert_Input = {
  driver_id?: Maybe<Scalars['uuid']>;
  driver_project?: Maybe<Deliveries_Driver_Project_Obj_Rel_Insert_Input>;
  driver_version?: Maybe<Deliveries_Driver_Versions_Obj_Rel_Insert_Input>;
  driver_version_id?: Maybe<Scalars['uuid']>;
  engine?: Maybe<Engines_Obj_Rel_Insert_Input>;
  engine_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Deliveries_Driver_Version_Engine_Max_Fields = {
  __typename?: 'deliveries_driver_version_engine_max_fields';
  driver_id?: Maybe<Scalars['uuid']>;
  driver_version_id?: Maybe<Scalars['uuid']>;
  engine_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "deliveries.driver_version_engine" */
export type Deliveries_Driver_Version_Engine_Max_Order_By = {
  driver_id?: Maybe<Order_By>;
  driver_version_id?: Maybe<Order_By>;
  engine_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Deliveries_Driver_Version_Engine_Min_Fields = {
  __typename?: 'deliveries_driver_version_engine_min_fields';
  driver_id?: Maybe<Scalars['uuid']>;
  driver_version_id?: Maybe<Scalars['uuid']>;
  engine_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "deliveries.driver_version_engine" */
export type Deliveries_Driver_Version_Engine_Min_Order_By = {
  driver_id?: Maybe<Order_By>;
  driver_version_id?: Maybe<Order_By>;
  engine_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "deliveries.driver_version_engine" */
export type Deliveries_Driver_Version_Engine_Mutation_Response = {
  __typename?: 'deliveries_driver_version_engine_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deliveries_Driver_Version_Engine>;
};

/** input type for inserting object relation for remote table "deliveries.driver_version_engine" */
export type Deliveries_Driver_Version_Engine_Obj_Rel_Insert_Input = {
  data: Deliveries_Driver_Version_Engine_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Deliveries_Driver_Version_Engine_On_Conflict>;
};

/** on_conflict condition type for table "deliveries.driver_version_engine" */
export type Deliveries_Driver_Version_Engine_On_Conflict = {
  constraint: Deliveries_Driver_Version_Engine_Constraint;
  update_columns?: Array<Deliveries_Driver_Version_Engine_Update_Column>;
  where?: Maybe<Deliveries_Driver_Version_Engine_Bool_Exp>;
};

/** Ordering options when selecting data from "deliveries.driver_version_engine". */
export type Deliveries_Driver_Version_Engine_Order_By = {
  driver_id?: Maybe<Order_By>;
  driver_project?: Maybe<Deliveries_Driver_Project_Order_By>;
  driver_version?: Maybe<Deliveries_Driver_Versions_Order_By>;
  driver_version_id?: Maybe<Order_By>;
  engine?: Maybe<Engines_Order_By>;
  engine_id?: Maybe<Order_By>;
};

/** primary key columns input for table: deliveries.driver_version_engine */
export type Deliveries_Driver_Version_Engine_Pk_Columns_Input = {
  driver_id: Scalars['uuid'];
  driver_version_id: Scalars['uuid'];
};

/** select columns of table "deliveries.driver_version_engine" */
export enum Deliveries_Driver_Version_Engine_Select_Column {
  /** column name */
  DriverId = 'driver_id',
  /** column name */
  DriverVersionId = 'driver_version_id',
  /** column name */
  EngineId = 'engine_id'
}

/** input type for updating data in table "deliveries.driver_version_engine" */
export type Deliveries_Driver_Version_Engine_Set_Input = {
  driver_id?: Maybe<Scalars['uuid']>;
  driver_version_id?: Maybe<Scalars['uuid']>;
  engine_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "deliveries.driver_version_engine" */
export enum Deliveries_Driver_Version_Engine_Update_Column {
  /** column name */
  DriverId = 'driver_id',
  /** column name */
  DriverVersionId = 'driver_version_id',
  /** column name */
  EngineId = 'engine_id'
}

export type Deliveries_Driver_Version_Engine_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Deliveries_Driver_Version_Engine_Set_Input>;
  where: Deliveries_Driver_Version_Engine_Bool_Exp;
};

/** used to attach driver versions to a package */
export type Deliveries_Driver_Version_Package = {
  __typename?: 'deliveries_driver_version_package';
  /** An object relationship */
  driver_version: Deliveries_Driver_Versions;
  driver_version_id: Scalars['uuid'];
  /** An object relationship */
  package: Deliveries_Packages;
  package_id: Scalars['uuid'];
};

/** aggregated selection of "deliveries.driver_version_package" */
export type Deliveries_Driver_Version_Package_Aggregate = {
  __typename?: 'deliveries_driver_version_package_aggregate';
  aggregate?: Maybe<Deliveries_Driver_Version_Package_Aggregate_Fields>;
  nodes: Array<Deliveries_Driver_Version_Package>;
};

/** aggregate fields of "deliveries.driver_version_package" */
export type Deliveries_Driver_Version_Package_Aggregate_Fields = {
  __typename?: 'deliveries_driver_version_package_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Deliveries_Driver_Version_Package_Max_Fields>;
  min?: Maybe<Deliveries_Driver_Version_Package_Min_Fields>;
};


/** aggregate fields of "deliveries.driver_version_package" */
export type Deliveries_Driver_Version_Package_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Deliveries_Driver_Version_Package_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "deliveries.driver_version_package" */
export type Deliveries_Driver_Version_Package_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Deliveries_Driver_Version_Package_Max_Order_By>;
  min?: Maybe<Deliveries_Driver_Version_Package_Min_Order_By>;
};

/** input type for inserting array relation for remote table "deliveries.driver_version_package" */
export type Deliveries_Driver_Version_Package_Arr_Rel_Insert_Input = {
  data: Array<Deliveries_Driver_Version_Package_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Deliveries_Driver_Version_Package_On_Conflict>;
};

/** Boolean expression to filter rows from the table "deliveries.driver_version_package". All fields are combined with a logical 'AND'. */
export type Deliveries_Driver_Version_Package_Bool_Exp = {
  _and?: Maybe<Array<Deliveries_Driver_Version_Package_Bool_Exp>>;
  _not?: Maybe<Deliveries_Driver_Version_Package_Bool_Exp>;
  _or?: Maybe<Array<Deliveries_Driver_Version_Package_Bool_Exp>>;
  driver_version?: Maybe<Deliveries_Driver_Versions_Bool_Exp>;
  driver_version_id?: Maybe<Uuid_Comparison_Exp>;
  package?: Maybe<Deliveries_Packages_Bool_Exp>;
  package_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "deliveries.driver_version_package" */
export enum Deliveries_Driver_Version_Package_Constraint {
  /** unique or primary key constraint on columns "driver_version_id", "package_id" */
  DriverVersionPackagePkey = 'driver_version_package_pkey'
}

/** input type for inserting data into table "deliveries.driver_version_package" */
export type Deliveries_Driver_Version_Package_Insert_Input = {
  driver_version?: Maybe<Deliveries_Driver_Versions_Obj_Rel_Insert_Input>;
  driver_version_id?: Maybe<Scalars['uuid']>;
  package?: Maybe<Deliveries_Packages_Obj_Rel_Insert_Input>;
  package_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Deliveries_Driver_Version_Package_Max_Fields = {
  __typename?: 'deliveries_driver_version_package_max_fields';
  driver_version_id?: Maybe<Scalars['uuid']>;
  package_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "deliveries.driver_version_package" */
export type Deliveries_Driver_Version_Package_Max_Order_By = {
  driver_version_id?: Maybe<Order_By>;
  package_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Deliveries_Driver_Version_Package_Min_Fields = {
  __typename?: 'deliveries_driver_version_package_min_fields';
  driver_version_id?: Maybe<Scalars['uuid']>;
  package_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "deliveries.driver_version_package" */
export type Deliveries_Driver_Version_Package_Min_Order_By = {
  driver_version_id?: Maybe<Order_By>;
  package_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "deliveries.driver_version_package" */
export type Deliveries_Driver_Version_Package_Mutation_Response = {
  __typename?: 'deliveries_driver_version_package_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deliveries_Driver_Version_Package>;
};

/** on_conflict condition type for table "deliveries.driver_version_package" */
export type Deliveries_Driver_Version_Package_On_Conflict = {
  constraint: Deliveries_Driver_Version_Package_Constraint;
  update_columns?: Array<Deliveries_Driver_Version_Package_Update_Column>;
  where?: Maybe<Deliveries_Driver_Version_Package_Bool_Exp>;
};

/** Ordering options when selecting data from "deliveries.driver_version_package". */
export type Deliveries_Driver_Version_Package_Order_By = {
  driver_version?: Maybe<Deliveries_Driver_Versions_Order_By>;
  driver_version_id?: Maybe<Order_By>;
  package?: Maybe<Deliveries_Packages_Order_By>;
  package_id?: Maybe<Order_By>;
};

/** primary key columns input for table: deliveries.driver_version_package */
export type Deliveries_Driver_Version_Package_Pk_Columns_Input = {
  driver_version_id: Scalars['uuid'];
  package_id: Scalars['uuid'];
};

/** select columns of table "deliveries.driver_version_package" */
export enum Deliveries_Driver_Version_Package_Select_Column {
  /** column name */
  DriverVersionId = 'driver_version_id',
  /** column name */
  PackageId = 'package_id'
}

/** input type for updating data in table "deliveries.driver_version_package" */
export type Deliveries_Driver_Version_Package_Set_Input = {
  driver_version_id?: Maybe<Scalars['uuid']>;
  package_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "deliveries_driver_version_package" */
export type Deliveries_Driver_Version_Package_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deliveries_Driver_Version_Package_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deliveries_Driver_Version_Package_Stream_Cursor_Value_Input = {
  driver_version_id?: Maybe<Scalars['uuid']>;
  package_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "deliveries.driver_version_package" */
export enum Deliveries_Driver_Version_Package_Update_Column {
  /** column name */
  DriverVersionId = 'driver_version_id',
  /** column name */
  PackageId = 'package_id'
}

export type Deliveries_Driver_Version_Package_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Deliveries_Driver_Version_Package_Set_Input>;
  where: Deliveries_Driver_Version_Package_Bool_Exp;
};

/** columns and relationships of "deliveries.driver_versions" */
export type Deliveries_Driver_Versions = {
  __typename?: 'deliveries_driver_versions';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  driver: Deliveries_Drivers;
  driver_id: Scalars['uuid'];
  /** An object relationship */
  driver_version_engine?: Maybe<Deliveries_Driver_Version_Engine>;
  /** An array relationship */
  driver_version_packages: Array<Deliveries_Driver_Version_Package>;
  /** An aggregate relationship */
  driver_version_packages_aggregate: Deliveries_Driver_Version_Package_Aggregate;
  id: Scalars['uuid'];
  name: Scalars['String'];
  sbom_blob_url?: Maybe<Scalars['String']>;
  storage_blob_url: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  version: Scalars['String'];
};


/** columns and relationships of "deliveries.driver_versions" */
export type Deliveries_Driver_VersionsDriver_Version_PackagesArgs = {
  distinct_on?: Maybe<Array<Deliveries_Driver_Version_Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Driver_Version_Package_Order_By>>;
  where?: Maybe<Deliveries_Driver_Version_Package_Bool_Exp>;
};


/** columns and relationships of "deliveries.driver_versions" */
export type Deliveries_Driver_VersionsDriver_Version_Packages_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Driver_Version_Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Driver_Version_Package_Order_By>>;
  where?: Maybe<Deliveries_Driver_Version_Package_Bool_Exp>;
};

/** aggregated selection of "deliveries.driver_versions" */
export type Deliveries_Driver_Versions_Aggregate = {
  __typename?: 'deliveries_driver_versions_aggregate';
  aggregate?: Maybe<Deliveries_Driver_Versions_Aggregate_Fields>;
  nodes: Array<Deliveries_Driver_Versions>;
};

/** aggregate fields of "deliveries.driver_versions" */
export type Deliveries_Driver_Versions_Aggregate_Fields = {
  __typename?: 'deliveries_driver_versions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Deliveries_Driver_Versions_Max_Fields>;
  min?: Maybe<Deliveries_Driver_Versions_Min_Fields>;
};


/** aggregate fields of "deliveries.driver_versions" */
export type Deliveries_Driver_Versions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Deliveries_Driver_Versions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "deliveries.driver_versions" */
export type Deliveries_Driver_Versions_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Deliveries_Driver_Versions_Max_Order_By>;
  min?: Maybe<Deliveries_Driver_Versions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "deliveries.driver_versions" */
export type Deliveries_Driver_Versions_Arr_Rel_Insert_Input = {
  data: Array<Deliveries_Driver_Versions_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Deliveries_Driver_Versions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "deliveries.driver_versions". All fields are combined with a logical 'AND'. */
export type Deliveries_Driver_Versions_Bool_Exp = {
  _and?: Maybe<Array<Deliveries_Driver_Versions_Bool_Exp>>;
  _not?: Maybe<Deliveries_Driver_Versions_Bool_Exp>;
  _or?: Maybe<Array<Deliveries_Driver_Versions_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  driver?: Maybe<Deliveries_Drivers_Bool_Exp>;
  driver_id?: Maybe<Uuid_Comparison_Exp>;
  driver_version_engine?: Maybe<Deliveries_Driver_Version_Engine_Bool_Exp>;
  driver_version_packages?: Maybe<Deliveries_Driver_Version_Package_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  sbom_blob_url?: Maybe<String_Comparison_Exp>;
  storage_blob_url?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  version?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "deliveries.driver_versions" */
export enum Deliveries_Driver_Versions_Constraint {
  /** unique or primary key constraint on columns "driver_id", "version" */
  DriverVersionsDriverIdVersionKey = 'driver_versions_driver_id_version_key',
  /** unique or primary key constraint on columns "id", "driver_id" */
  DriverVersionsIdDriverIdKey = 'driver_versions_id_driver_id_key',
  /** unique or primary key constraint on columns "id" */
  DriverVersionsPkey = 'driver_versions_pkey'
}

/** input type for inserting data into table "deliveries.driver_versions" */
export type Deliveries_Driver_Versions_Insert_Input = {
  driver?: Maybe<Deliveries_Drivers_Obj_Rel_Insert_Input>;
  driver_id?: Maybe<Scalars['uuid']>;
  driver_version_engine?: Maybe<Deliveries_Driver_Version_Engine_Obj_Rel_Insert_Input>;
  driver_version_packages?: Maybe<Deliveries_Driver_Version_Package_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  sbom_blob_url?: Maybe<Scalars['String']>;
  storage_blob_url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Deliveries_Driver_Versions_Max_Fields = {
  __typename?: 'deliveries_driver_versions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  driver_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  sbom_blob_url?: Maybe<Scalars['String']>;
  storage_blob_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "deliveries.driver_versions" */
export type Deliveries_Driver_Versions_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  driver_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  sbom_blob_url?: Maybe<Order_By>;
  storage_blob_url?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Deliveries_Driver_Versions_Min_Fields = {
  __typename?: 'deliveries_driver_versions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  driver_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  sbom_blob_url?: Maybe<Scalars['String']>;
  storage_blob_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "deliveries.driver_versions" */
export type Deliveries_Driver_Versions_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  driver_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  sbom_blob_url?: Maybe<Order_By>;
  storage_blob_url?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
};

/** response of any mutation on the table "deliveries.driver_versions" */
export type Deliveries_Driver_Versions_Mutation_Response = {
  __typename?: 'deliveries_driver_versions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deliveries_Driver_Versions>;
};

/** input type for inserting object relation for remote table "deliveries.driver_versions" */
export type Deliveries_Driver_Versions_Obj_Rel_Insert_Input = {
  data: Deliveries_Driver_Versions_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Deliveries_Driver_Versions_On_Conflict>;
};

/** on_conflict condition type for table "deliveries.driver_versions" */
export type Deliveries_Driver_Versions_On_Conflict = {
  constraint: Deliveries_Driver_Versions_Constraint;
  update_columns?: Array<Deliveries_Driver_Versions_Update_Column>;
  where?: Maybe<Deliveries_Driver_Versions_Bool_Exp>;
};

/** Ordering options when selecting data from "deliveries.driver_versions". */
export type Deliveries_Driver_Versions_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  driver?: Maybe<Deliveries_Drivers_Order_By>;
  driver_id?: Maybe<Order_By>;
  driver_version_engine?: Maybe<Deliveries_Driver_Version_Engine_Order_By>;
  driver_version_packages_aggregate?: Maybe<Deliveries_Driver_Version_Package_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  sbom_blob_url?: Maybe<Order_By>;
  storage_blob_url?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
};

/** primary key columns input for table: deliveries.driver_versions */
export type Deliveries_Driver_Versions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "deliveries.driver_versions" */
export enum Deliveries_Driver_Versions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DriverId = 'driver_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SbomBlobUrl = 'sbom_blob_url',
  /** column name */
  StorageBlobUrl = 'storage_blob_url',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "deliveries.driver_versions" */
export type Deliveries_Driver_Versions_Set_Input = {
  driver_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  sbom_blob_url?: Maybe<Scalars['String']>;
  storage_blob_url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "deliveries_driver_versions" */
export type Deliveries_Driver_Versions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deliveries_Driver_Versions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deliveries_Driver_Versions_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  driver_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  sbom_blob_url?: Maybe<Scalars['String']>;
  storage_blob_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['String']>;
};

/** update columns of table "deliveries.driver_versions" */
export enum Deliveries_Driver_Versions_Update_Column {
  /** column name */
  DriverId = 'driver_id',
  /** column name */
  Name = 'name',
  /** column name */
  SbomBlobUrl = 'sbom_blob_url',
  /** column name */
  StorageBlobUrl = 'storage_blob_url',
  /** column name */
  Version = 'version'
}

export type Deliveries_Driver_Versions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Deliveries_Driver_Versions_Set_Input>;
  where: Deliveries_Driver_Versions_Bool_Exp;
};

/** columns and relationships of "deliveries.drivers" */
export type Deliveries_Drivers = {
  __typename?: 'deliveries_drivers';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  driver_packages: Array<Deliveries_Driver_Package>;
  /** An aggregate relationship */
  driver_packages_aggregate: Deliveries_Driver_Package_Aggregate;
  /** An object relationship */
  driver_project?: Maybe<Deliveries_Driver_Project>;
  /** An array relationship */
  driver_versions: Array<Deliveries_Driver_Versions>;
  /** An aggregate relationship */
  driver_versions_aggregate: Deliveries_Driver_Versions_Aggregate;
  id: Scalars['uuid'];
  name: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "deliveries.drivers" */
export type Deliveries_DriversDriver_PackagesArgs = {
  distinct_on?: Maybe<Array<Deliveries_Driver_Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Driver_Package_Order_By>>;
  where?: Maybe<Deliveries_Driver_Package_Bool_Exp>;
};


/** columns and relationships of "deliveries.drivers" */
export type Deliveries_DriversDriver_Packages_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Driver_Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Driver_Package_Order_By>>;
  where?: Maybe<Deliveries_Driver_Package_Bool_Exp>;
};


/** columns and relationships of "deliveries.drivers" */
export type Deliveries_DriversDriver_VersionsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Driver_Versions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Driver_Versions_Order_By>>;
  where?: Maybe<Deliveries_Driver_Versions_Bool_Exp>;
};


/** columns and relationships of "deliveries.drivers" */
export type Deliveries_DriversDriver_Versions_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Driver_Versions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Driver_Versions_Order_By>>;
  where?: Maybe<Deliveries_Driver_Versions_Bool_Exp>;
};

/** aggregated selection of "deliveries.drivers" */
export type Deliveries_Drivers_Aggregate = {
  __typename?: 'deliveries_drivers_aggregate';
  aggregate?: Maybe<Deliveries_Drivers_Aggregate_Fields>;
  nodes: Array<Deliveries_Drivers>;
};

/** aggregate fields of "deliveries.drivers" */
export type Deliveries_Drivers_Aggregate_Fields = {
  __typename?: 'deliveries_drivers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Deliveries_Drivers_Max_Fields>;
  min?: Maybe<Deliveries_Drivers_Min_Fields>;
};


/** aggregate fields of "deliveries.drivers" */
export type Deliveries_Drivers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Deliveries_Drivers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "deliveries.drivers". All fields are combined with a logical 'AND'. */
export type Deliveries_Drivers_Bool_Exp = {
  _and?: Maybe<Array<Deliveries_Drivers_Bool_Exp>>;
  _not?: Maybe<Deliveries_Drivers_Bool_Exp>;
  _or?: Maybe<Array<Deliveries_Drivers_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  driver_packages?: Maybe<Deliveries_Driver_Package_Bool_Exp>;
  driver_project?: Maybe<Deliveries_Driver_Project_Bool_Exp>;
  driver_versions?: Maybe<Deliveries_Driver_Versions_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "deliveries.drivers" */
export enum Deliveries_Drivers_Constraint {
  /** unique or primary key constraint on columns "name" */
  DriversNameKey = 'drivers_name_key',
  /** unique or primary key constraint on columns "id" */
  DriversPkey = 'drivers_pkey'
}

/** input type for inserting data into table "deliveries.drivers" */
export type Deliveries_Drivers_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  driver_packages?: Maybe<Deliveries_Driver_Package_Arr_Rel_Insert_Input>;
  driver_project?: Maybe<Deliveries_Driver_Project_Obj_Rel_Insert_Input>;
  driver_versions?: Maybe<Deliveries_Driver_Versions_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Deliveries_Drivers_Max_Fields = {
  __typename?: 'deliveries_drivers_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Deliveries_Drivers_Min_Fields = {
  __typename?: 'deliveries_drivers_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "deliveries.drivers" */
export type Deliveries_Drivers_Mutation_Response = {
  __typename?: 'deliveries_drivers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deliveries_Drivers>;
};

/** input type for inserting object relation for remote table "deliveries.drivers" */
export type Deliveries_Drivers_Obj_Rel_Insert_Input = {
  data: Deliveries_Drivers_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Deliveries_Drivers_On_Conflict>;
};

/** on_conflict condition type for table "deliveries.drivers" */
export type Deliveries_Drivers_On_Conflict = {
  constraint: Deliveries_Drivers_Constraint;
  update_columns?: Array<Deliveries_Drivers_Update_Column>;
  where?: Maybe<Deliveries_Drivers_Bool_Exp>;
};

/** Ordering options when selecting data from "deliveries.drivers". */
export type Deliveries_Drivers_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  driver_packages_aggregate?: Maybe<Deliveries_Driver_Package_Aggregate_Order_By>;
  driver_project?: Maybe<Deliveries_Driver_Project_Order_By>;
  driver_versions_aggregate?: Maybe<Deliveries_Driver_Versions_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: deliveries.drivers */
export type Deliveries_Drivers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "deliveries.drivers" */
export enum Deliveries_Drivers_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "deliveries.drivers" */
export type Deliveries_Drivers_Set_Input = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "deliveries_drivers" */
export type Deliveries_Drivers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deliveries_Drivers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deliveries_Drivers_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "deliveries.drivers" */
export enum Deliveries_Drivers_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

export type Deliveries_Drivers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Deliveries_Drivers_Set_Input>;
  where: Deliveries_Drivers_Bool_Exp;
};

/** Stores the HLK artifacts for every spec package */
export type Deliveries_Microsoft_Hlk_Artifacts = {
  __typename?: 'deliveries_microsoft_hlk_artifacts';
  base_driver_version: Scalars['String'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  device_family: Deliveries_Device_Families;
  device_family_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  operating_system: Deliveries_Operating_Systems;
  os_id: Scalars['uuid'];
  /** An object relationship */
  package: Deliveries_Packages;
  package_id: Scalars['uuid'];
  storage_blob_url: Scalars['String'];
};

/** aggregated selection of "deliveries.microsoft_hlk_artifacts" */
export type Deliveries_Microsoft_Hlk_Artifacts_Aggregate = {
  __typename?: 'deliveries_microsoft_hlk_artifacts_aggregate';
  aggregate?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_Aggregate_Fields>;
  nodes: Array<Deliveries_Microsoft_Hlk_Artifacts>;
};

/** aggregate fields of "deliveries.microsoft_hlk_artifacts" */
export type Deliveries_Microsoft_Hlk_Artifacts_Aggregate_Fields = {
  __typename?: 'deliveries_microsoft_hlk_artifacts_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_Max_Fields>;
  min?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_Min_Fields>;
};


/** aggregate fields of "deliveries.microsoft_hlk_artifacts" */
export type Deliveries_Microsoft_Hlk_Artifacts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Deliveries_Microsoft_Hlk_Artifacts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "deliveries.microsoft_hlk_artifacts" */
export type Deliveries_Microsoft_Hlk_Artifacts_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_Max_Order_By>;
  min?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_Min_Order_By>;
};

/** input type for inserting array relation for remote table "deliveries.microsoft_hlk_artifacts" */
export type Deliveries_Microsoft_Hlk_Artifacts_Arr_Rel_Insert_Input = {
  data: Array<Deliveries_Microsoft_Hlk_Artifacts_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "deliveries.microsoft_hlk_artifacts". All fields are combined with a logical 'AND'. */
export type Deliveries_Microsoft_Hlk_Artifacts_Bool_Exp = {
  _and?: Maybe<Array<Deliveries_Microsoft_Hlk_Artifacts_Bool_Exp>>;
  _not?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_Bool_Exp>;
  _or?: Maybe<Array<Deliveries_Microsoft_Hlk_Artifacts_Bool_Exp>>;
  base_driver_version?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  device_family?: Maybe<Deliveries_Device_Families_Bool_Exp>;
  device_family_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  operating_system?: Maybe<Deliveries_Operating_Systems_Bool_Exp>;
  os_id?: Maybe<Uuid_Comparison_Exp>;
  package?: Maybe<Deliveries_Packages_Bool_Exp>;
  package_id?: Maybe<Uuid_Comparison_Exp>;
  storage_blob_url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "deliveries.microsoft_hlk_artifacts" */
export enum Deliveries_Microsoft_Hlk_Artifacts_Constraint {
  /** unique or primary key constraint on columns "os_id", "device_family_id", "package_id", "base_driver_version" */
  MicrosoftHlkArtifactPackageOsDeviceFamilyVersion = 'microsoft_hlk_artifact_package_os_device_family_version',
  /** unique or primary key constraint on columns "id" */
  MicrosoftHlkArtifactsPkey = 'microsoft_hlk_artifacts_pkey'
}

/** input type for inserting data into table "deliveries.microsoft_hlk_artifacts" */
export type Deliveries_Microsoft_Hlk_Artifacts_Insert_Input = {
  base_driver_version?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  device_family?: Maybe<Deliveries_Device_Families_Obj_Rel_Insert_Input>;
  device_family_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  operating_system?: Maybe<Deliveries_Operating_Systems_Obj_Rel_Insert_Input>;
  os_id?: Maybe<Scalars['uuid']>;
  package?: Maybe<Deliveries_Packages_Obj_Rel_Insert_Input>;
  package_id?: Maybe<Scalars['uuid']>;
  storage_blob_url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Deliveries_Microsoft_Hlk_Artifacts_Max_Fields = {
  __typename?: 'deliveries_microsoft_hlk_artifacts_max_fields';
  base_driver_version?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  device_family_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  os_id?: Maybe<Scalars['uuid']>;
  package_id?: Maybe<Scalars['uuid']>;
  storage_blob_url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "deliveries.microsoft_hlk_artifacts" */
export type Deliveries_Microsoft_Hlk_Artifacts_Max_Order_By = {
  base_driver_version?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  device_family_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  os_id?: Maybe<Order_By>;
  package_id?: Maybe<Order_By>;
  storage_blob_url?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Deliveries_Microsoft_Hlk_Artifacts_Min_Fields = {
  __typename?: 'deliveries_microsoft_hlk_artifacts_min_fields';
  base_driver_version?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  device_family_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  os_id?: Maybe<Scalars['uuid']>;
  package_id?: Maybe<Scalars['uuid']>;
  storage_blob_url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "deliveries.microsoft_hlk_artifacts" */
export type Deliveries_Microsoft_Hlk_Artifacts_Min_Order_By = {
  base_driver_version?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  device_family_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  os_id?: Maybe<Order_By>;
  package_id?: Maybe<Order_By>;
  storage_blob_url?: Maybe<Order_By>;
};

/** response of any mutation on the table "deliveries.microsoft_hlk_artifacts" */
export type Deliveries_Microsoft_Hlk_Artifacts_Mutation_Response = {
  __typename?: 'deliveries_microsoft_hlk_artifacts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deliveries_Microsoft_Hlk_Artifacts>;
};

/** on_conflict condition type for table "deliveries.microsoft_hlk_artifacts" */
export type Deliveries_Microsoft_Hlk_Artifacts_On_Conflict = {
  constraint: Deliveries_Microsoft_Hlk_Artifacts_Constraint;
  update_columns?: Array<Deliveries_Microsoft_Hlk_Artifacts_Update_Column>;
  where?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_Bool_Exp>;
};

/** Ordering options when selecting data from "deliveries.microsoft_hlk_artifacts". */
export type Deliveries_Microsoft_Hlk_Artifacts_Order_By = {
  base_driver_version?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  device_family?: Maybe<Deliveries_Device_Families_Order_By>;
  device_family_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  operating_system?: Maybe<Deliveries_Operating_Systems_Order_By>;
  os_id?: Maybe<Order_By>;
  package?: Maybe<Deliveries_Packages_Order_By>;
  package_id?: Maybe<Order_By>;
  storage_blob_url?: Maybe<Order_By>;
};

/** primary key columns input for table: deliveries.microsoft_hlk_artifacts */
export type Deliveries_Microsoft_Hlk_Artifacts_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "deliveries.microsoft_hlk_artifacts" */
export enum Deliveries_Microsoft_Hlk_Artifacts_Select_Column {
  /** column name */
  BaseDriverVersion = 'base_driver_version',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeviceFamilyId = 'device_family_id',
  /** column name */
  Id = 'id',
  /** column name */
  OsId = 'os_id',
  /** column name */
  PackageId = 'package_id',
  /** column name */
  StorageBlobUrl = 'storage_blob_url'
}

/** input type for updating data in table "deliveries.microsoft_hlk_artifacts" */
export type Deliveries_Microsoft_Hlk_Artifacts_Set_Input = {
  base_driver_version?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  device_family_id?: Maybe<Scalars['uuid']>;
  os_id?: Maybe<Scalars['uuid']>;
  package_id?: Maybe<Scalars['uuid']>;
  storage_blob_url?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "deliveries_microsoft_hlk_artifacts" */
export type Deliveries_Microsoft_Hlk_Artifacts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deliveries_Microsoft_Hlk_Artifacts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deliveries_Microsoft_Hlk_Artifacts_Stream_Cursor_Value_Input = {
  base_driver_version?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  device_family_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  os_id?: Maybe<Scalars['uuid']>;
  package_id?: Maybe<Scalars['uuid']>;
  storage_blob_url?: Maybe<Scalars['String']>;
};

/** update columns of table "deliveries.microsoft_hlk_artifacts" */
export enum Deliveries_Microsoft_Hlk_Artifacts_Update_Column {
  /** column name */
  BaseDriverVersion = 'base_driver_version',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeviceFamilyId = 'device_family_id',
  /** column name */
  OsId = 'os_id',
  /** column name */
  PackageId = 'package_id',
  /** column name */
  StorageBlobUrl = 'storage_blob_url'
}

export type Deliveries_Microsoft_Hlk_Artifacts_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_Set_Input>;
  where: Deliveries_Microsoft_Hlk_Artifacts_Bool_Exp;
};

/** Reviews of a given shipping label */
export type Deliveries_Microsoft_Shipping_Label_Reviews = {
  __typename?: 'deliveries_microsoft_shipping_label_reviews';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  microsoft_shipping_label: Deliveries_Microsoft_Shipping_Labels;
  microsoft_shipping_label_id: Scalars['uuid'];
  reviewed_by: Scalars['uuid'];
  status: Scalars['String'];
  /** An object relationship */
  user: Users;
};

/** Boolean expression to filter rows from the table "deliveries.microsoft_shipping_label_reviews". All fields are combined with a logical 'AND'. */
export type Deliveries_Microsoft_Shipping_Label_Reviews_Bool_Exp = {
  _and?: Maybe<Array<Deliveries_Microsoft_Shipping_Label_Reviews_Bool_Exp>>;
  _not?: Maybe<Deliveries_Microsoft_Shipping_Label_Reviews_Bool_Exp>;
  _or?: Maybe<Array<Deliveries_Microsoft_Shipping_Label_Reviews_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  microsoft_shipping_label?: Maybe<Deliveries_Microsoft_Shipping_Labels_Bool_Exp>;
  microsoft_shipping_label_id?: Maybe<Uuid_Comparison_Exp>;
  reviewed_by?: Maybe<Uuid_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "deliveries.microsoft_shipping_label_reviews" */
export enum Deliveries_Microsoft_Shipping_Label_Reviews_Constraint {
  /** unique or primary key constraint on columns "microsoft_shipping_label_id" */
  MicrosoftShippingLabelReviewsPkey = 'microsoft_shipping_label_reviews_pkey'
}

/** input type for inserting data into table "deliveries.microsoft_shipping_label_reviews" */
export type Deliveries_Microsoft_Shipping_Label_Reviews_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  microsoft_shipping_label?: Maybe<Deliveries_Microsoft_Shipping_Labels_Obj_Rel_Insert_Input>;
  microsoft_shipping_label_id?: Maybe<Scalars['uuid']>;
  reviewed_by?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
};

/** response of any mutation on the table "deliveries.microsoft_shipping_label_reviews" */
export type Deliveries_Microsoft_Shipping_Label_Reviews_Mutation_Response = {
  __typename?: 'deliveries_microsoft_shipping_label_reviews_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deliveries_Microsoft_Shipping_Label_Reviews>;
};

/** input type for inserting object relation for remote table "deliveries.microsoft_shipping_label_reviews" */
export type Deliveries_Microsoft_Shipping_Label_Reviews_Obj_Rel_Insert_Input = {
  data: Deliveries_Microsoft_Shipping_Label_Reviews_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Deliveries_Microsoft_Shipping_Label_Reviews_On_Conflict>;
};

/** on_conflict condition type for table "deliveries.microsoft_shipping_label_reviews" */
export type Deliveries_Microsoft_Shipping_Label_Reviews_On_Conflict = {
  constraint: Deliveries_Microsoft_Shipping_Label_Reviews_Constraint;
  update_columns?: Array<Deliveries_Microsoft_Shipping_Label_Reviews_Update_Column>;
  where?: Maybe<Deliveries_Microsoft_Shipping_Label_Reviews_Bool_Exp>;
};

/** Ordering options when selecting data from "deliveries.microsoft_shipping_label_reviews". */
export type Deliveries_Microsoft_Shipping_Label_Reviews_Order_By = {
  created_at?: Maybe<Order_By>;
  microsoft_shipping_label?: Maybe<Deliveries_Microsoft_Shipping_Labels_Order_By>;
  microsoft_shipping_label_id?: Maybe<Order_By>;
  reviewed_by?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
};

/** primary key columns input for table: deliveries.microsoft_shipping_label_reviews */
export type Deliveries_Microsoft_Shipping_Label_Reviews_Pk_Columns_Input = {
  microsoft_shipping_label_id: Scalars['uuid'];
};

/** select columns of table "deliveries.microsoft_shipping_label_reviews" */
export enum Deliveries_Microsoft_Shipping_Label_Reviews_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  MicrosoftShippingLabelId = 'microsoft_shipping_label_id',
  /** column name */
  ReviewedBy = 'reviewed_by',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "deliveries.microsoft_shipping_label_reviews" */
export type Deliveries_Microsoft_Shipping_Label_Reviews_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  reviewed_by?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "deliveries_microsoft_shipping_label_reviews" */
export type Deliveries_Microsoft_Shipping_Label_Reviews_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deliveries_Microsoft_Shipping_Label_Reviews_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deliveries_Microsoft_Shipping_Label_Reviews_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  microsoft_shipping_label_id?: Maybe<Scalars['uuid']>;
  reviewed_by?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
};

/** update columns of table "deliveries.microsoft_shipping_label_reviews" */
export enum Deliveries_Microsoft_Shipping_Label_Reviews_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ReviewedBy = 'reviewed_by',
  /** column name */
  Status = 'status'
}

export type Deliveries_Microsoft_Shipping_Label_Reviews_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Deliveries_Microsoft_Shipping_Label_Reviews_Set_Input>;
  where: Deliveries_Microsoft_Shipping_Label_Reviews_Bool_Exp;
};

/** The shipping labels associated to WHQL submissions on the Microsoft Partner Center */
export type Deliveries_Microsoft_Shipping_Labels = {
  __typename?: 'deliveries_microsoft_shipping_labels';
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  id: Scalars['uuid'];
  microsoft_partner_center_shipping_label_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  microsoft_shipping_label_review?: Maybe<Deliveries_Microsoft_Shipping_Label_Reviews>;
  /** An object relationship */
  microsoft_whql_submission: Deliveries_Microsoft_Whql_Submissions;
  microsoft_whql_submission_id: Scalars['uuid'];
  shipping_label_infos: Scalars['jsonb'];
  shipping_label_name: Scalars['String'];
  shipping_label_status: Scalars['String'];
  /** An object relationship */
  target: Deliveries_Targets;
  target_id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user: Users;
  /** An object relationship */
  user_update?: Maybe<Users>;
};


/** The shipping labels associated to WHQL submissions on the Microsoft Partner Center */
export type Deliveries_Microsoft_Shipping_LabelsShipping_Label_InfosArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "deliveries.microsoft_shipping_labels" */
export type Deliveries_Microsoft_Shipping_Labels_Aggregate = {
  __typename?: 'deliveries_microsoft_shipping_labels_aggregate';
  aggregate?: Maybe<Deliveries_Microsoft_Shipping_Labels_Aggregate_Fields>;
  nodes: Array<Deliveries_Microsoft_Shipping_Labels>;
};

/** aggregate fields of "deliveries.microsoft_shipping_labels" */
export type Deliveries_Microsoft_Shipping_Labels_Aggregate_Fields = {
  __typename?: 'deliveries_microsoft_shipping_labels_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Deliveries_Microsoft_Shipping_Labels_Max_Fields>;
  min?: Maybe<Deliveries_Microsoft_Shipping_Labels_Min_Fields>;
};


/** aggregate fields of "deliveries.microsoft_shipping_labels" */
export type Deliveries_Microsoft_Shipping_Labels_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Deliveries_Microsoft_Shipping_Labels_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "deliveries.microsoft_shipping_labels" */
export type Deliveries_Microsoft_Shipping_Labels_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Deliveries_Microsoft_Shipping_Labels_Max_Order_By>;
  min?: Maybe<Deliveries_Microsoft_Shipping_Labels_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Deliveries_Microsoft_Shipping_Labels_Append_Input = {
  shipping_label_infos?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "deliveries.microsoft_shipping_labels" */
export type Deliveries_Microsoft_Shipping_Labels_Arr_Rel_Insert_Input = {
  data: Array<Deliveries_Microsoft_Shipping_Labels_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Deliveries_Microsoft_Shipping_Labels_On_Conflict>;
};

/** Boolean expression to filter rows from the table "deliveries.microsoft_shipping_labels". All fields are combined with a logical 'AND'. */
export type Deliveries_Microsoft_Shipping_Labels_Bool_Exp = {
  _and?: Maybe<Array<Deliveries_Microsoft_Shipping_Labels_Bool_Exp>>;
  _not?: Maybe<Deliveries_Microsoft_Shipping_Labels_Bool_Exp>;
  _or?: Maybe<Array<Deliveries_Microsoft_Shipping_Labels_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  microsoft_partner_center_shipping_label_id?: Maybe<String_Comparison_Exp>;
  microsoft_shipping_label_review?: Maybe<Deliveries_Microsoft_Shipping_Label_Reviews_Bool_Exp>;
  microsoft_whql_submission?: Maybe<Deliveries_Microsoft_Whql_Submissions_Bool_Exp>;
  microsoft_whql_submission_id?: Maybe<Uuid_Comparison_Exp>;
  shipping_label_infos?: Maybe<Jsonb_Comparison_Exp>;
  shipping_label_name?: Maybe<String_Comparison_Exp>;
  shipping_label_status?: Maybe<String_Comparison_Exp>;
  target?: Maybe<Deliveries_Targets_Bool_Exp>;
  target_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_by?: Maybe<Uuid_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_update?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "deliveries.microsoft_shipping_labels" */
export enum Deliveries_Microsoft_Shipping_Labels_Constraint {
  /** unique or primary key constraint on columns "microsoft_partner_center_shipping_label_id", "microsoft_whql_submission_id" */
  MicrosoftShippingLabelsMicrosoftWhqlSubmissionIdMicrosof = 'microsoft_shipping_labels_microsoft_whql_submission_id_microsof',
  /** unique or primary key constraint on columns "id" */
  MicrosoftShippingLabelsPkey = 'microsoft_shipping_labels_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Deliveries_Microsoft_Shipping_Labels_Delete_At_Path_Input = {
  shipping_label_infos?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Deliveries_Microsoft_Shipping_Labels_Delete_Elem_Input = {
  shipping_label_infos?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Deliveries_Microsoft_Shipping_Labels_Delete_Key_Input = {
  shipping_label_infos?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "deliveries.microsoft_shipping_labels" */
export type Deliveries_Microsoft_Shipping_Labels_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  microsoft_partner_center_shipping_label_id?: Maybe<Scalars['String']>;
  microsoft_shipping_label_review?: Maybe<Deliveries_Microsoft_Shipping_Label_Reviews_Obj_Rel_Insert_Input>;
  microsoft_whql_submission?: Maybe<Deliveries_Microsoft_Whql_Submissions_Obj_Rel_Insert_Input>;
  microsoft_whql_submission_id?: Maybe<Scalars['uuid']>;
  shipping_label_infos?: Maybe<Scalars['jsonb']>;
  shipping_label_name?: Maybe<Scalars['String']>;
  shipping_label_status?: Maybe<Scalars['String']>;
  target?: Maybe<Deliveries_Targets_Obj_Rel_Insert_Input>;
  target_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_update?: Maybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Deliveries_Microsoft_Shipping_Labels_Max_Fields = {
  __typename?: 'deliveries_microsoft_shipping_labels_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  microsoft_partner_center_shipping_label_id?: Maybe<Scalars['String']>;
  microsoft_whql_submission_id?: Maybe<Scalars['uuid']>;
  shipping_label_name?: Maybe<Scalars['String']>;
  shipping_label_status?: Maybe<Scalars['String']>;
  target_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "deliveries.microsoft_shipping_labels" */
export type Deliveries_Microsoft_Shipping_Labels_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  microsoft_partner_center_shipping_label_id?: Maybe<Order_By>;
  microsoft_whql_submission_id?: Maybe<Order_By>;
  shipping_label_name?: Maybe<Order_By>;
  shipping_label_status?: Maybe<Order_By>;
  target_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Deliveries_Microsoft_Shipping_Labels_Min_Fields = {
  __typename?: 'deliveries_microsoft_shipping_labels_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  microsoft_partner_center_shipping_label_id?: Maybe<Scalars['String']>;
  microsoft_whql_submission_id?: Maybe<Scalars['uuid']>;
  shipping_label_name?: Maybe<Scalars['String']>;
  shipping_label_status?: Maybe<Scalars['String']>;
  target_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "deliveries.microsoft_shipping_labels" */
export type Deliveries_Microsoft_Shipping_Labels_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  microsoft_partner_center_shipping_label_id?: Maybe<Order_By>;
  microsoft_whql_submission_id?: Maybe<Order_By>;
  shipping_label_name?: Maybe<Order_By>;
  shipping_label_status?: Maybe<Order_By>;
  target_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** response of any mutation on the table "deliveries.microsoft_shipping_labels" */
export type Deliveries_Microsoft_Shipping_Labels_Mutation_Response = {
  __typename?: 'deliveries_microsoft_shipping_labels_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deliveries_Microsoft_Shipping_Labels>;
};

/** input type for inserting object relation for remote table "deliveries.microsoft_shipping_labels" */
export type Deliveries_Microsoft_Shipping_Labels_Obj_Rel_Insert_Input = {
  data: Deliveries_Microsoft_Shipping_Labels_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Deliveries_Microsoft_Shipping_Labels_On_Conflict>;
};

/** on_conflict condition type for table "deliveries.microsoft_shipping_labels" */
export type Deliveries_Microsoft_Shipping_Labels_On_Conflict = {
  constraint: Deliveries_Microsoft_Shipping_Labels_Constraint;
  update_columns?: Array<Deliveries_Microsoft_Shipping_Labels_Update_Column>;
  where?: Maybe<Deliveries_Microsoft_Shipping_Labels_Bool_Exp>;
};

/** Ordering options when selecting data from "deliveries.microsoft_shipping_labels". */
export type Deliveries_Microsoft_Shipping_Labels_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  microsoft_partner_center_shipping_label_id?: Maybe<Order_By>;
  microsoft_shipping_label_review?: Maybe<Deliveries_Microsoft_Shipping_Label_Reviews_Order_By>;
  microsoft_whql_submission?: Maybe<Deliveries_Microsoft_Whql_Submissions_Order_By>;
  microsoft_whql_submission_id?: Maybe<Order_By>;
  shipping_label_infos?: Maybe<Order_By>;
  shipping_label_name?: Maybe<Order_By>;
  shipping_label_status?: Maybe<Order_By>;
  target?: Maybe<Deliveries_Targets_Order_By>;
  target_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_update?: Maybe<Users_Order_By>;
};

/** primary key columns input for table: deliveries.microsoft_shipping_labels */
export type Deliveries_Microsoft_Shipping_Labels_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Deliveries_Microsoft_Shipping_Labels_Prepend_Input = {
  shipping_label_infos?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "deliveries.microsoft_shipping_labels" */
export enum Deliveries_Microsoft_Shipping_Labels_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  MicrosoftPartnerCenterShippingLabelId = 'microsoft_partner_center_shipping_label_id',
  /** column name */
  MicrosoftWhqlSubmissionId = 'microsoft_whql_submission_id',
  /** column name */
  ShippingLabelInfos = 'shipping_label_infos',
  /** column name */
  ShippingLabelName = 'shipping_label_name',
  /** column name */
  ShippingLabelStatus = 'shipping_label_status',
  /** column name */
  TargetId = 'target_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "deliveries.microsoft_shipping_labels" */
export type Deliveries_Microsoft_Shipping_Labels_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  microsoft_partner_center_shipping_label_id?: Maybe<Scalars['String']>;
  microsoft_whql_submission_id?: Maybe<Scalars['uuid']>;
  shipping_label_infos?: Maybe<Scalars['jsonb']>;
  shipping_label_name?: Maybe<Scalars['String']>;
  shipping_label_status?: Maybe<Scalars['String']>;
  target_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "deliveries_microsoft_shipping_labels" */
export type Deliveries_Microsoft_Shipping_Labels_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deliveries_Microsoft_Shipping_Labels_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deliveries_Microsoft_Shipping_Labels_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  microsoft_partner_center_shipping_label_id?: Maybe<Scalars['String']>;
  microsoft_whql_submission_id?: Maybe<Scalars['uuid']>;
  shipping_label_infos?: Maybe<Scalars['jsonb']>;
  shipping_label_name?: Maybe<Scalars['String']>;
  shipping_label_status?: Maybe<Scalars['String']>;
  target_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** update columns of table "deliveries.microsoft_shipping_labels" */
export enum Deliveries_Microsoft_Shipping_Labels_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  MicrosoftPartnerCenterShippingLabelId = 'microsoft_partner_center_shipping_label_id',
  /** column name */
  MicrosoftWhqlSubmissionId = 'microsoft_whql_submission_id',
  /** column name */
  ShippingLabelInfos = 'shipping_label_infos',
  /** column name */
  ShippingLabelName = 'shipping_label_name',
  /** column name */
  ShippingLabelStatus = 'shipping_label_status',
  /** column name */
  TargetId = 'target_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Deliveries_Microsoft_Shipping_Labels_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Deliveries_Microsoft_Shipping_Labels_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Deliveries_Microsoft_Shipping_Labels_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Deliveries_Microsoft_Shipping_Labels_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Deliveries_Microsoft_Shipping_Labels_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Deliveries_Microsoft_Shipping_Labels_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Deliveries_Microsoft_Shipping_Labels_Set_Input>;
  where: Deliveries_Microsoft_Shipping_Labels_Bool_Exp;
};

/** The WHQL submissions on Microsoft Partner Center, for package components of a given package version */
export type Deliveries_Microsoft_Whql_Submissions = {
  __typename?: 'deliveries_microsoft_whql_submissions';
  /** An array relationship */
  artifact_microsoft_whql_submissions: Array<Deliveries_Artifact_Microsoft_Whql_Submission>;
  /** An aggregate relationship */
  artifact_microsoft_whql_submissions_aggregate: Deliveries_Artifact_Microsoft_Whql_Submission_Aggregate;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  microsoft_partner_center_product_id: Scalars['String'];
  microsoft_partner_center_product_name: Scalars['String'];
  microsoft_partner_center_submission_id: Scalars['String'];
  microsoft_partner_center_submission_name: Scalars['String'];
  microsoft_partner_center_submission_type: Scalars['String'];
  /** An array relationship */
  microsoft_shipping_labels: Array<Deliveries_Microsoft_Shipping_Labels>;
  /** An aggregate relationship */
  microsoft_shipping_labels_aggregate: Deliveries_Microsoft_Shipping_Labels_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** The WHQL submissions on Microsoft Partner Center, for package components of a given package version */
export type Deliveries_Microsoft_Whql_SubmissionsArtifact_Microsoft_Whql_SubmissionsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Artifact_Microsoft_Whql_Submission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Artifact_Microsoft_Whql_Submission_Order_By>>;
  where?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission_Bool_Exp>;
};


/** The WHQL submissions on Microsoft Partner Center, for package components of a given package version */
export type Deliveries_Microsoft_Whql_SubmissionsArtifact_Microsoft_Whql_Submissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Artifact_Microsoft_Whql_Submission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Artifact_Microsoft_Whql_Submission_Order_By>>;
  where?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission_Bool_Exp>;
};


/** The WHQL submissions on Microsoft Partner Center, for package components of a given package version */
export type Deliveries_Microsoft_Whql_SubmissionsMicrosoft_Shipping_LabelsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Microsoft_Shipping_Labels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Microsoft_Shipping_Labels_Order_By>>;
  where?: Maybe<Deliveries_Microsoft_Shipping_Labels_Bool_Exp>;
};


/** The WHQL submissions on Microsoft Partner Center, for package components of a given package version */
export type Deliveries_Microsoft_Whql_SubmissionsMicrosoft_Shipping_Labels_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Microsoft_Shipping_Labels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Microsoft_Shipping_Labels_Order_By>>;
  where?: Maybe<Deliveries_Microsoft_Shipping_Labels_Bool_Exp>;
};

/** aggregated selection of "deliveries.microsoft_whql_submissions" */
export type Deliveries_Microsoft_Whql_Submissions_Aggregate = {
  __typename?: 'deliveries_microsoft_whql_submissions_aggregate';
  aggregate?: Maybe<Deliveries_Microsoft_Whql_Submissions_Aggregate_Fields>;
  nodes: Array<Deliveries_Microsoft_Whql_Submissions>;
};

/** aggregate fields of "deliveries.microsoft_whql_submissions" */
export type Deliveries_Microsoft_Whql_Submissions_Aggregate_Fields = {
  __typename?: 'deliveries_microsoft_whql_submissions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Deliveries_Microsoft_Whql_Submissions_Max_Fields>;
  min?: Maybe<Deliveries_Microsoft_Whql_Submissions_Min_Fields>;
};


/** aggregate fields of "deliveries.microsoft_whql_submissions" */
export type Deliveries_Microsoft_Whql_Submissions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Deliveries_Microsoft_Whql_Submissions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "deliveries.microsoft_whql_submissions". All fields are combined with a logical 'AND'. */
export type Deliveries_Microsoft_Whql_Submissions_Bool_Exp = {
  _and?: Maybe<Array<Deliveries_Microsoft_Whql_Submissions_Bool_Exp>>;
  _not?: Maybe<Deliveries_Microsoft_Whql_Submissions_Bool_Exp>;
  _or?: Maybe<Array<Deliveries_Microsoft_Whql_Submissions_Bool_Exp>>;
  artifact_microsoft_whql_submissions?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  microsoft_partner_center_product_id?: Maybe<String_Comparison_Exp>;
  microsoft_partner_center_product_name?: Maybe<String_Comparison_Exp>;
  microsoft_partner_center_submission_id?: Maybe<String_Comparison_Exp>;
  microsoft_partner_center_submission_name?: Maybe<String_Comparison_Exp>;
  microsoft_partner_center_submission_type?: Maybe<String_Comparison_Exp>;
  microsoft_shipping_labels?: Maybe<Deliveries_Microsoft_Shipping_Labels_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "deliveries.microsoft_whql_submissions" */
export enum Deliveries_Microsoft_Whql_Submissions_Constraint {
  /** unique or primary key constraint on columns "id" */
  MicrosoftWhqlSubmissionsPkey = 'microsoft_whql_submissions_pkey'
}

/** input type for inserting data into table "deliveries.microsoft_whql_submissions" */
export type Deliveries_Microsoft_Whql_Submissions_Insert_Input = {
  artifact_microsoft_whql_submissions?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  microsoft_partner_center_product_id?: Maybe<Scalars['String']>;
  microsoft_partner_center_product_name?: Maybe<Scalars['String']>;
  microsoft_partner_center_submission_id?: Maybe<Scalars['String']>;
  microsoft_partner_center_submission_name?: Maybe<Scalars['String']>;
  microsoft_partner_center_submission_type?: Maybe<Scalars['String']>;
  microsoft_shipping_labels?: Maybe<Deliveries_Microsoft_Shipping_Labels_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Deliveries_Microsoft_Whql_Submissions_Max_Fields = {
  __typename?: 'deliveries_microsoft_whql_submissions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  microsoft_partner_center_product_id?: Maybe<Scalars['String']>;
  microsoft_partner_center_product_name?: Maybe<Scalars['String']>;
  microsoft_partner_center_submission_id?: Maybe<Scalars['String']>;
  microsoft_partner_center_submission_name?: Maybe<Scalars['String']>;
  microsoft_partner_center_submission_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Deliveries_Microsoft_Whql_Submissions_Min_Fields = {
  __typename?: 'deliveries_microsoft_whql_submissions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  microsoft_partner_center_product_id?: Maybe<Scalars['String']>;
  microsoft_partner_center_product_name?: Maybe<Scalars['String']>;
  microsoft_partner_center_submission_id?: Maybe<Scalars['String']>;
  microsoft_partner_center_submission_name?: Maybe<Scalars['String']>;
  microsoft_partner_center_submission_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "deliveries.microsoft_whql_submissions" */
export type Deliveries_Microsoft_Whql_Submissions_Mutation_Response = {
  __typename?: 'deliveries_microsoft_whql_submissions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deliveries_Microsoft_Whql_Submissions>;
};

/** input type for inserting object relation for remote table "deliveries.microsoft_whql_submissions" */
export type Deliveries_Microsoft_Whql_Submissions_Obj_Rel_Insert_Input = {
  data: Deliveries_Microsoft_Whql_Submissions_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Deliveries_Microsoft_Whql_Submissions_On_Conflict>;
};

/** on_conflict condition type for table "deliveries.microsoft_whql_submissions" */
export type Deliveries_Microsoft_Whql_Submissions_On_Conflict = {
  constraint: Deliveries_Microsoft_Whql_Submissions_Constraint;
  update_columns?: Array<Deliveries_Microsoft_Whql_Submissions_Update_Column>;
  where?: Maybe<Deliveries_Microsoft_Whql_Submissions_Bool_Exp>;
};

/** Ordering options when selecting data from "deliveries.microsoft_whql_submissions". */
export type Deliveries_Microsoft_Whql_Submissions_Order_By = {
  artifact_microsoft_whql_submissions_aggregate?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  microsoft_partner_center_product_id?: Maybe<Order_By>;
  microsoft_partner_center_product_name?: Maybe<Order_By>;
  microsoft_partner_center_submission_id?: Maybe<Order_By>;
  microsoft_partner_center_submission_name?: Maybe<Order_By>;
  microsoft_partner_center_submission_type?: Maybe<Order_By>;
  microsoft_shipping_labels_aggregate?: Maybe<Deliveries_Microsoft_Shipping_Labels_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: deliveries.microsoft_whql_submissions */
export type Deliveries_Microsoft_Whql_Submissions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "deliveries.microsoft_whql_submissions" */
export enum Deliveries_Microsoft_Whql_Submissions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MicrosoftPartnerCenterProductId = 'microsoft_partner_center_product_id',
  /** column name */
  MicrosoftPartnerCenterProductName = 'microsoft_partner_center_product_name',
  /** column name */
  MicrosoftPartnerCenterSubmissionId = 'microsoft_partner_center_submission_id',
  /** column name */
  MicrosoftPartnerCenterSubmissionName = 'microsoft_partner_center_submission_name',
  /** column name */
  MicrosoftPartnerCenterSubmissionType = 'microsoft_partner_center_submission_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "deliveries.microsoft_whql_submissions" */
export type Deliveries_Microsoft_Whql_Submissions_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  microsoft_partner_center_product_id?: Maybe<Scalars['String']>;
  microsoft_partner_center_product_name?: Maybe<Scalars['String']>;
  microsoft_partner_center_submission_id?: Maybe<Scalars['String']>;
  microsoft_partner_center_submission_name?: Maybe<Scalars['String']>;
  microsoft_partner_center_submission_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "deliveries_microsoft_whql_submissions" */
export type Deliveries_Microsoft_Whql_Submissions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deliveries_Microsoft_Whql_Submissions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deliveries_Microsoft_Whql_Submissions_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  microsoft_partner_center_product_id?: Maybe<Scalars['String']>;
  microsoft_partner_center_product_name?: Maybe<Scalars['String']>;
  microsoft_partner_center_submission_id?: Maybe<Scalars['String']>;
  microsoft_partner_center_submission_name?: Maybe<Scalars['String']>;
  microsoft_partner_center_submission_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "deliveries.microsoft_whql_submissions" */
export enum Deliveries_Microsoft_Whql_Submissions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  MicrosoftPartnerCenterProductId = 'microsoft_partner_center_product_id',
  /** column name */
  MicrosoftPartnerCenterProductName = 'microsoft_partner_center_product_name',
  /** column name */
  MicrosoftPartnerCenterSubmissionId = 'microsoft_partner_center_submission_id',
  /** column name */
  MicrosoftPartnerCenterSubmissionName = 'microsoft_partner_center_submission_name',
  /** column name */
  MicrosoftPartnerCenterSubmissionType = 'microsoft_partner_center_submission_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Deliveries_Microsoft_Whql_Submissions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Deliveries_Microsoft_Whql_Submissions_Set_Input>;
  where: Deliveries_Microsoft_Whql_Submissions_Bool_Exp;
};

/** Store all the different OS */
export type Deliveries_Operating_Systems = {
  __typename?: 'deliveries_operating_systems';
  /** An array relationship */
  artifact_operating_systems: Array<Deliveries_Artifact_Operating_System>;
  codename: Scalars['String'];
  fullname: Scalars['String'];
  id: Scalars['uuid'];
  /** An array relationship */
  microsoft_hlk_artifacts: Array<Deliveries_Microsoft_Hlk_Artifacts>;
  /** An aggregate relationship */
  microsoft_hlk_artifacts_aggregate: Deliveries_Microsoft_Hlk_Artifacts_Aggregate;
  /** An array relationship */
  package_operating_systems: Array<Deliveries_Package_Operating_Systems>;
};


/** Store all the different OS */
export type Deliveries_Operating_SystemsArtifact_Operating_SystemsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Artifact_Operating_System_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Artifact_Operating_System_Order_By>>;
  where?: Maybe<Deliveries_Artifact_Operating_System_Bool_Exp>;
};


/** Store all the different OS */
export type Deliveries_Operating_SystemsMicrosoft_Hlk_ArtifactsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Microsoft_Hlk_Artifacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Microsoft_Hlk_Artifacts_Order_By>>;
  where?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_Bool_Exp>;
};


/** Store all the different OS */
export type Deliveries_Operating_SystemsMicrosoft_Hlk_Artifacts_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Microsoft_Hlk_Artifacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Microsoft_Hlk_Artifacts_Order_By>>;
  where?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_Bool_Exp>;
};


/** Store all the different OS */
export type Deliveries_Operating_SystemsPackage_Operating_SystemsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Package_Operating_Systems_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Package_Operating_Systems_Order_By>>;
  where?: Maybe<Deliveries_Package_Operating_Systems_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "deliveries.operating_systems". All fields are combined with a logical 'AND'. */
export type Deliveries_Operating_Systems_Bool_Exp = {
  _and?: Maybe<Array<Deliveries_Operating_Systems_Bool_Exp>>;
  _not?: Maybe<Deliveries_Operating_Systems_Bool_Exp>;
  _or?: Maybe<Array<Deliveries_Operating_Systems_Bool_Exp>>;
  artifact_operating_systems?: Maybe<Deliveries_Artifact_Operating_System_Bool_Exp>;
  codename?: Maybe<String_Comparison_Exp>;
  fullname?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  microsoft_hlk_artifacts?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_Bool_Exp>;
  package_operating_systems?: Maybe<Deliveries_Package_Operating_Systems_Bool_Exp>;
};

/** unique or primary key constraints on table "deliveries.operating_systems" */
export enum Deliveries_Operating_Systems_Constraint {
  /** unique or primary key constraint on columns "codename" */
  OperatingSystemsCodenameKey = 'operating_systems_codename_key',
  /** unique or primary key constraint on columns "id" */
  OperatingSystemsPkey = 'operating_systems_pkey'
}

/** input type for inserting data into table "deliveries.operating_systems" */
export type Deliveries_Operating_Systems_Insert_Input = {
  artifact_operating_systems?: Maybe<Deliveries_Artifact_Operating_System_Arr_Rel_Insert_Input>;
  codename?: Maybe<Scalars['String']>;
  fullname?: Maybe<Scalars['String']>;
  microsoft_hlk_artifacts?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_Arr_Rel_Insert_Input>;
  package_operating_systems?: Maybe<Deliveries_Package_Operating_Systems_Arr_Rel_Insert_Input>;
};

/** response of any mutation on the table "deliveries.operating_systems" */
export type Deliveries_Operating_Systems_Mutation_Response = {
  __typename?: 'deliveries_operating_systems_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deliveries_Operating_Systems>;
};

/** input type for inserting object relation for remote table "deliveries.operating_systems" */
export type Deliveries_Operating_Systems_Obj_Rel_Insert_Input = {
  data: Deliveries_Operating_Systems_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Deliveries_Operating_Systems_On_Conflict>;
};

/** on_conflict condition type for table "deliveries.operating_systems" */
export type Deliveries_Operating_Systems_On_Conflict = {
  constraint: Deliveries_Operating_Systems_Constraint;
  update_columns?: Array<Deliveries_Operating_Systems_Update_Column>;
  where?: Maybe<Deliveries_Operating_Systems_Bool_Exp>;
};

/** Ordering options when selecting data from "deliveries.operating_systems". */
export type Deliveries_Operating_Systems_Order_By = {
  artifact_operating_systems_aggregate?: Maybe<Deliveries_Artifact_Operating_System_Aggregate_Order_By>;
  codename?: Maybe<Order_By>;
  fullname?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  microsoft_hlk_artifacts_aggregate?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_Aggregate_Order_By>;
  package_operating_systems_aggregate?: Maybe<Deliveries_Package_Operating_Systems_Aggregate_Order_By>;
};

/** primary key columns input for table: deliveries.operating_systems */
export type Deliveries_Operating_Systems_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "deliveries.operating_systems" */
export enum Deliveries_Operating_Systems_Select_Column {
  /** column name */
  Codename = 'codename',
  /** column name */
  Fullname = 'fullname',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "deliveries.operating_systems" */
export type Deliveries_Operating_Systems_Set_Input = {
  codename?: Maybe<Scalars['String']>;
  fullname?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "deliveries_operating_systems" */
export type Deliveries_Operating_Systems_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deliveries_Operating_Systems_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deliveries_Operating_Systems_Stream_Cursor_Value_Input = {
  codename?: Maybe<Scalars['String']>;
  fullname?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "deliveries.operating_systems" */
export enum Deliveries_Operating_Systems_Update_Column {
  /** column name */
  Codename = 'codename',
  /** column name */
  Fullname = 'fullname'
}

export type Deliveries_Operating_Systems_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Deliveries_Operating_Systems_Set_Input>;
  where: Deliveries_Operating_Systems_Bool_Exp;
};

/** Whether a spec package has to be tested for a given OS version */
export type Deliveries_Package_Operating_Systems = {
  __typename?: 'deliveries_package_operating_systems';
  is_tested: Scalars['Boolean'];
  /** An object relationship */
  operating_system: Deliveries_Operating_Systems;
  os_id: Scalars['uuid'];
  /** An object relationship */
  package: Deliveries_Packages;
  package_id: Scalars['uuid'];
};

/** order by aggregate values of table "deliveries.package_operating_systems" */
export type Deliveries_Package_Operating_Systems_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Deliveries_Package_Operating_Systems_Max_Order_By>;
  min?: Maybe<Deliveries_Package_Operating_Systems_Min_Order_By>;
};

/** input type for inserting array relation for remote table "deliveries.package_operating_systems" */
export type Deliveries_Package_Operating_Systems_Arr_Rel_Insert_Input = {
  data: Array<Deliveries_Package_Operating_Systems_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Deliveries_Package_Operating_Systems_On_Conflict>;
};

/** Boolean expression to filter rows from the table "deliveries.package_operating_systems". All fields are combined with a logical 'AND'. */
export type Deliveries_Package_Operating_Systems_Bool_Exp = {
  _and?: Maybe<Array<Deliveries_Package_Operating_Systems_Bool_Exp>>;
  _not?: Maybe<Deliveries_Package_Operating_Systems_Bool_Exp>;
  _or?: Maybe<Array<Deliveries_Package_Operating_Systems_Bool_Exp>>;
  is_tested?: Maybe<Boolean_Comparison_Exp>;
  operating_system?: Maybe<Deliveries_Operating_Systems_Bool_Exp>;
  os_id?: Maybe<Uuid_Comparison_Exp>;
  package?: Maybe<Deliveries_Packages_Bool_Exp>;
  package_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "deliveries.package_operating_systems" */
export enum Deliveries_Package_Operating_Systems_Constraint {
  /** unique or primary key constraint on columns "os_id", "package_id" */
  PackageOperatingSystemsPkey = 'package_operating_systems_pkey'
}

/** input type for inserting data into table "deliveries.package_operating_systems" */
export type Deliveries_Package_Operating_Systems_Insert_Input = {
  is_tested?: Maybe<Scalars['Boolean']>;
  operating_system?: Maybe<Deliveries_Operating_Systems_Obj_Rel_Insert_Input>;
  os_id?: Maybe<Scalars['uuid']>;
  package?: Maybe<Deliveries_Packages_Obj_Rel_Insert_Input>;
  package_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "deliveries.package_operating_systems" */
export type Deliveries_Package_Operating_Systems_Max_Order_By = {
  os_id?: Maybe<Order_By>;
  package_id?: Maybe<Order_By>;
};

/** order by min() on columns of table "deliveries.package_operating_systems" */
export type Deliveries_Package_Operating_Systems_Min_Order_By = {
  os_id?: Maybe<Order_By>;
  package_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "deliveries.package_operating_systems" */
export type Deliveries_Package_Operating_Systems_Mutation_Response = {
  __typename?: 'deliveries_package_operating_systems_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deliveries_Package_Operating_Systems>;
};

/** on_conflict condition type for table "deliveries.package_operating_systems" */
export type Deliveries_Package_Operating_Systems_On_Conflict = {
  constraint: Deliveries_Package_Operating_Systems_Constraint;
  update_columns?: Array<Deliveries_Package_Operating_Systems_Update_Column>;
  where?: Maybe<Deliveries_Package_Operating_Systems_Bool_Exp>;
};

/** Ordering options when selecting data from "deliveries.package_operating_systems". */
export type Deliveries_Package_Operating_Systems_Order_By = {
  is_tested?: Maybe<Order_By>;
  operating_system?: Maybe<Deliveries_Operating_Systems_Order_By>;
  os_id?: Maybe<Order_By>;
  package?: Maybe<Deliveries_Packages_Order_By>;
  package_id?: Maybe<Order_By>;
};

/** primary key columns input for table: deliveries.package_operating_systems */
export type Deliveries_Package_Operating_Systems_Pk_Columns_Input = {
  os_id: Scalars['uuid'];
  package_id: Scalars['uuid'];
};

/** select columns of table "deliveries.package_operating_systems" */
export enum Deliveries_Package_Operating_Systems_Select_Column {
  /** column name */
  IsTested = 'is_tested',
  /** column name */
  OsId = 'os_id',
  /** column name */
  PackageId = 'package_id'
}

/** input type for updating data in table "deliveries.package_operating_systems" */
export type Deliveries_Package_Operating_Systems_Set_Input = {
  is_tested?: Maybe<Scalars['Boolean']>;
  os_id?: Maybe<Scalars['uuid']>;
  package_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "deliveries_package_operating_systems" */
export type Deliveries_Package_Operating_Systems_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deliveries_Package_Operating_Systems_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deliveries_Package_Operating_Systems_Stream_Cursor_Value_Input = {
  is_tested?: Maybe<Scalars['Boolean']>;
  os_id?: Maybe<Scalars['uuid']>;
  package_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "deliveries.package_operating_systems" */
export enum Deliveries_Package_Operating_Systems_Update_Column {
  /** column name */
  IsTested = 'is_tested',
  /** column name */
  OsId = 'os_id',
  /** column name */
  PackageId = 'package_id'
}

export type Deliveries_Package_Operating_Systems_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Deliveries_Package_Operating_Systems_Set_Input>;
  where: Deliveries_Package_Operating_Systems_Bool_Exp;
};

/** columns and relationships of "deliveries.package_target" */
export type Deliveries_Package_Target = {
  __typename?: 'deliveries_package_target';
  /** An object relationship */
  package: Deliveries_Packages;
  package_id: Scalars['uuid'];
  /** An object relationship */
  target: Deliveries_Targets;
  target_id: Scalars['uuid'];
};

/** aggregated selection of "deliveries.package_target" */
export type Deliveries_Package_Target_Aggregate = {
  __typename?: 'deliveries_package_target_aggregate';
  aggregate?: Maybe<Deliveries_Package_Target_Aggregate_Fields>;
  nodes: Array<Deliveries_Package_Target>;
};

/** aggregate fields of "deliveries.package_target" */
export type Deliveries_Package_Target_Aggregate_Fields = {
  __typename?: 'deliveries_package_target_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Deliveries_Package_Target_Max_Fields>;
  min?: Maybe<Deliveries_Package_Target_Min_Fields>;
};


/** aggregate fields of "deliveries.package_target" */
export type Deliveries_Package_Target_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Deliveries_Package_Target_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "deliveries.package_target" */
export type Deliveries_Package_Target_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Deliveries_Package_Target_Max_Order_By>;
  min?: Maybe<Deliveries_Package_Target_Min_Order_By>;
};

/** input type for inserting array relation for remote table "deliveries.package_target" */
export type Deliveries_Package_Target_Arr_Rel_Insert_Input = {
  data: Array<Deliveries_Package_Target_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Deliveries_Package_Target_On_Conflict>;
};

/** Boolean expression to filter rows from the table "deliveries.package_target". All fields are combined with a logical 'AND'. */
export type Deliveries_Package_Target_Bool_Exp = {
  _and?: Maybe<Array<Deliveries_Package_Target_Bool_Exp>>;
  _not?: Maybe<Deliveries_Package_Target_Bool_Exp>;
  _or?: Maybe<Array<Deliveries_Package_Target_Bool_Exp>>;
  package?: Maybe<Deliveries_Packages_Bool_Exp>;
  package_id?: Maybe<Uuid_Comparison_Exp>;
  target?: Maybe<Deliveries_Targets_Bool_Exp>;
  target_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "deliveries.package_target" */
export enum Deliveries_Package_Target_Constraint {
  /** unique or primary key constraint on columns "target_id", "package_id" */
  PackageTargetPkey = 'package_target_pkey'
}

/** input type for inserting data into table "deliveries.package_target" */
export type Deliveries_Package_Target_Insert_Input = {
  package?: Maybe<Deliveries_Packages_Obj_Rel_Insert_Input>;
  package_id?: Maybe<Scalars['uuid']>;
  target?: Maybe<Deliveries_Targets_Obj_Rel_Insert_Input>;
  target_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Deliveries_Package_Target_Max_Fields = {
  __typename?: 'deliveries_package_target_max_fields';
  package_id?: Maybe<Scalars['uuid']>;
  target_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "deliveries.package_target" */
export type Deliveries_Package_Target_Max_Order_By = {
  package_id?: Maybe<Order_By>;
  target_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Deliveries_Package_Target_Min_Fields = {
  __typename?: 'deliveries_package_target_min_fields';
  package_id?: Maybe<Scalars['uuid']>;
  target_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "deliveries.package_target" */
export type Deliveries_Package_Target_Min_Order_By = {
  package_id?: Maybe<Order_By>;
  target_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "deliveries.package_target" */
export type Deliveries_Package_Target_Mutation_Response = {
  __typename?: 'deliveries_package_target_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deliveries_Package_Target>;
};

/** on_conflict condition type for table "deliveries.package_target" */
export type Deliveries_Package_Target_On_Conflict = {
  constraint: Deliveries_Package_Target_Constraint;
  update_columns?: Array<Deliveries_Package_Target_Update_Column>;
  where?: Maybe<Deliveries_Package_Target_Bool_Exp>;
};

/** Ordering options when selecting data from "deliveries.package_target". */
export type Deliveries_Package_Target_Order_By = {
  package?: Maybe<Deliveries_Packages_Order_By>;
  package_id?: Maybe<Order_By>;
  target?: Maybe<Deliveries_Targets_Order_By>;
  target_id?: Maybe<Order_By>;
};

/** primary key columns input for table: deliveries.package_target */
export type Deliveries_Package_Target_Pk_Columns_Input = {
  package_id: Scalars['uuid'];
  target_id: Scalars['uuid'];
};

/** select columns of table "deliveries.package_target" */
export enum Deliveries_Package_Target_Select_Column {
  /** column name */
  PackageId = 'package_id',
  /** column name */
  TargetId = 'target_id'
}

/** input type for updating data in table "deliveries.package_target" */
export type Deliveries_Package_Target_Set_Input = {
  package_id?: Maybe<Scalars['uuid']>;
  target_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "deliveries_package_target" */
export type Deliveries_Package_Target_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deliveries_Package_Target_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deliveries_Package_Target_Stream_Cursor_Value_Input = {
  package_id?: Maybe<Scalars['uuid']>;
  target_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "deliveries.package_target" */
export enum Deliveries_Package_Target_Update_Column {
  /** column name */
  PackageId = 'package_id',
  /** column name */
  TargetId = 'target_id'
}

export type Deliveries_Package_Target_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Deliveries_Package_Target_Set_Input>;
  where: Deliveries_Package_Target_Bool_Exp;
};

/** columns and relationships of "deliveries.packages" */
export type Deliveries_Packages = {
  __typename?: 'deliveries_packages';
  /** An object relationship */
  architecture?: Maybe<Deliveries_Architectures>;
  architecture_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  artifacts: Array<Deliveries_Artifacts>;
  /** An aggregate relationship */
  artifacts_aggregate: Deliveries_Artifacts_Aggregate;
  bundle_struct_version?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  customer: Customers;
  customer_id: Scalars['uuid'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  driver_packages: Array<Deliveries_Driver_Package>;
  /** An aggregate relationship */
  driver_packages_aggregate: Deliveries_Driver_Package_Aggregate;
  /** An array relationship */
  driver_version_packages: Array<Deliveries_Driver_Version_Package>;
  /** An aggregate relationship */
  driver_version_packages_aggregate: Deliveries_Driver_Version_Package_Aggregate;
  /** The package name that will be sent to the customer */
  external_package_name?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  major_version?: Maybe<Scalars['Int']>;
  /** An array relationship */
  microsoft_hlk_artifacts: Array<Deliveries_Microsoft_Hlk_Artifacts>;
  /** An aggregate relationship */
  microsoft_hlk_artifacts_aggregate: Deliveries_Microsoft_Hlk_Artifacts_Aggregate;
  minor_version?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  /** An array relationship */
  package_operating_systems: Array<Deliveries_Package_Operating_Systems>;
  /** An array relationship */
  package_targets: Array<Deliveries_Package_Target>;
  /** An aggregate relationship */
  package_targets_aggregate: Deliveries_Package_Target_Aggregate;
  /** An object relationship */
  platform?: Maybe<Deliveries_Platforms>;
  platform_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  released_packages: Array<Deliveries_Packages>;
  /** An aggregate relationship */
  released_packages_aggregate: Deliveries_Packages_Aggregate;
  sbom_blob_url?: Maybe<Scalars['String']>;
  sharepoint_release_notes_path?: Maybe<Scalars['String']>;
  /** Sharepoint upload path for the package */
  sharepoint_upload_path?: Maybe<Scalars['String']>;
  /** Slack channel to post on the release */
  slack_channel?: Maybe<Scalars['String']>;
  /** An object relationship */
  spec_package?: Maybe<Deliveries_Packages>;
  spec_package_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** URL in Azure Storage of the package */
  url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};


/** columns and relationships of "deliveries.packages" */
export type Deliveries_PackagesArtifactsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Artifacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Artifacts_Order_By>>;
  where?: Maybe<Deliveries_Artifacts_Bool_Exp>;
};


/** columns and relationships of "deliveries.packages" */
export type Deliveries_PackagesArtifacts_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Artifacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Artifacts_Order_By>>;
  where?: Maybe<Deliveries_Artifacts_Bool_Exp>;
};


/** columns and relationships of "deliveries.packages" */
export type Deliveries_PackagesDriver_PackagesArgs = {
  distinct_on?: Maybe<Array<Deliveries_Driver_Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Driver_Package_Order_By>>;
  where?: Maybe<Deliveries_Driver_Package_Bool_Exp>;
};


/** columns and relationships of "deliveries.packages" */
export type Deliveries_PackagesDriver_Packages_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Driver_Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Driver_Package_Order_By>>;
  where?: Maybe<Deliveries_Driver_Package_Bool_Exp>;
};


/** columns and relationships of "deliveries.packages" */
export type Deliveries_PackagesDriver_Version_PackagesArgs = {
  distinct_on?: Maybe<Array<Deliveries_Driver_Version_Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Driver_Version_Package_Order_By>>;
  where?: Maybe<Deliveries_Driver_Version_Package_Bool_Exp>;
};


/** columns and relationships of "deliveries.packages" */
export type Deliveries_PackagesDriver_Version_Packages_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Driver_Version_Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Driver_Version_Package_Order_By>>;
  where?: Maybe<Deliveries_Driver_Version_Package_Bool_Exp>;
};


/** columns and relationships of "deliveries.packages" */
export type Deliveries_PackagesMicrosoft_Hlk_ArtifactsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Microsoft_Hlk_Artifacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Microsoft_Hlk_Artifacts_Order_By>>;
  where?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_Bool_Exp>;
};


/** columns and relationships of "deliveries.packages" */
export type Deliveries_PackagesMicrosoft_Hlk_Artifacts_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Microsoft_Hlk_Artifacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Microsoft_Hlk_Artifacts_Order_By>>;
  where?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_Bool_Exp>;
};


/** columns and relationships of "deliveries.packages" */
export type Deliveries_PackagesPackage_Operating_SystemsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Package_Operating_Systems_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Package_Operating_Systems_Order_By>>;
  where?: Maybe<Deliveries_Package_Operating_Systems_Bool_Exp>;
};


/** columns and relationships of "deliveries.packages" */
export type Deliveries_PackagesPackage_TargetsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Package_Target_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Package_Target_Order_By>>;
  where?: Maybe<Deliveries_Package_Target_Bool_Exp>;
};


/** columns and relationships of "deliveries.packages" */
export type Deliveries_PackagesPackage_Targets_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Package_Target_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Package_Target_Order_By>>;
  where?: Maybe<Deliveries_Package_Target_Bool_Exp>;
};


/** columns and relationships of "deliveries.packages" */
export type Deliveries_PackagesReleased_PackagesArgs = {
  distinct_on?: Maybe<Array<Deliveries_Packages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Packages_Order_By>>;
  where?: Maybe<Deliveries_Packages_Bool_Exp>;
};


/** columns and relationships of "deliveries.packages" */
export type Deliveries_PackagesReleased_Packages_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Packages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Packages_Order_By>>;
  where?: Maybe<Deliveries_Packages_Bool_Exp>;
};

/** aggregated selection of "deliveries.packages" */
export type Deliveries_Packages_Aggregate = {
  __typename?: 'deliveries_packages_aggregate';
  aggregate?: Maybe<Deliveries_Packages_Aggregate_Fields>;
  nodes: Array<Deliveries_Packages>;
};

/** aggregate fields of "deliveries.packages" */
export type Deliveries_Packages_Aggregate_Fields = {
  __typename?: 'deliveries_packages_aggregate_fields';
  avg?: Maybe<Deliveries_Packages_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Deliveries_Packages_Max_Fields>;
  min?: Maybe<Deliveries_Packages_Min_Fields>;
  stddev?: Maybe<Deliveries_Packages_Stddev_Fields>;
  stddev_pop?: Maybe<Deliveries_Packages_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Deliveries_Packages_Stddev_Samp_Fields>;
  sum?: Maybe<Deliveries_Packages_Sum_Fields>;
  var_pop?: Maybe<Deliveries_Packages_Var_Pop_Fields>;
  var_samp?: Maybe<Deliveries_Packages_Var_Samp_Fields>;
  variance?: Maybe<Deliveries_Packages_Variance_Fields>;
};


/** aggregate fields of "deliveries.packages" */
export type Deliveries_Packages_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Deliveries_Packages_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "deliveries.packages" */
export type Deliveries_Packages_Aggregate_Order_By = {
  avg?: Maybe<Deliveries_Packages_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Deliveries_Packages_Max_Order_By>;
  min?: Maybe<Deliveries_Packages_Min_Order_By>;
  stddev?: Maybe<Deliveries_Packages_Stddev_Order_By>;
  stddev_pop?: Maybe<Deliveries_Packages_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Deliveries_Packages_Stddev_Samp_Order_By>;
  sum?: Maybe<Deliveries_Packages_Sum_Order_By>;
  var_pop?: Maybe<Deliveries_Packages_Var_Pop_Order_By>;
  var_samp?: Maybe<Deliveries_Packages_Var_Samp_Order_By>;
  variance?: Maybe<Deliveries_Packages_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "deliveries.packages" */
export type Deliveries_Packages_Arr_Rel_Insert_Input = {
  data: Array<Deliveries_Packages_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Deliveries_Packages_On_Conflict>;
};

/** aggregate avg on columns */
export type Deliveries_Packages_Avg_Fields = {
  __typename?: 'deliveries_packages_avg_fields';
  bundle_struct_version?: Maybe<Scalars['Float']>;
  major_version?: Maybe<Scalars['Float']>;
  minor_version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "deliveries.packages" */
export type Deliveries_Packages_Avg_Order_By = {
  bundle_struct_version?: Maybe<Order_By>;
  major_version?: Maybe<Order_By>;
  minor_version?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "deliveries.packages". All fields are combined with a logical 'AND'. */
export type Deliveries_Packages_Bool_Exp = {
  _and?: Maybe<Array<Deliveries_Packages_Bool_Exp>>;
  _not?: Maybe<Deliveries_Packages_Bool_Exp>;
  _or?: Maybe<Array<Deliveries_Packages_Bool_Exp>>;
  architecture?: Maybe<Deliveries_Architectures_Bool_Exp>;
  architecture_id?: Maybe<Uuid_Comparison_Exp>;
  artifacts?: Maybe<Deliveries_Artifacts_Bool_Exp>;
  bundle_struct_version?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer?: Maybe<Customers_Bool_Exp>;
  customer_id?: Maybe<Uuid_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  driver_packages?: Maybe<Deliveries_Driver_Package_Bool_Exp>;
  driver_version_packages?: Maybe<Deliveries_Driver_Version_Package_Bool_Exp>;
  external_package_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  major_version?: Maybe<Int_Comparison_Exp>;
  microsoft_hlk_artifacts?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_Bool_Exp>;
  minor_version?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  package_operating_systems?: Maybe<Deliveries_Package_Operating_Systems_Bool_Exp>;
  package_targets?: Maybe<Deliveries_Package_Target_Bool_Exp>;
  platform?: Maybe<Deliveries_Platforms_Bool_Exp>;
  platform_id?: Maybe<Uuid_Comparison_Exp>;
  released_packages?: Maybe<Deliveries_Packages_Bool_Exp>;
  sbom_blob_url?: Maybe<String_Comparison_Exp>;
  sharepoint_release_notes_path?: Maybe<String_Comparison_Exp>;
  sharepoint_upload_path?: Maybe<String_Comparison_Exp>;
  slack_channel?: Maybe<String_Comparison_Exp>;
  spec_package?: Maybe<Deliveries_Packages_Bool_Exp>;
  spec_package_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
  version?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "deliveries.packages" */
export enum Deliveries_Packages_Constraint {
  /** unique or primary key constraint on columns "name", "customer_id", "version" */
  PackagesNameVersionKey = 'packages_name_version_key',
  /** unique or primary key constraint on columns "id" */
  PackagesPkey = 'packages_pkey'
}

/** input type for incrementing numeric columns in table "deliveries.packages" */
export type Deliveries_Packages_Inc_Input = {
  bundle_struct_version?: Maybe<Scalars['Int']>;
  major_version?: Maybe<Scalars['Int']>;
  minor_version?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "deliveries.packages" */
export type Deliveries_Packages_Insert_Input = {
  architecture?: Maybe<Deliveries_Architectures_Obj_Rel_Insert_Input>;
  architecture_id?: Maybe<Scalars['uuid']>;
  artifacts?: Maybe<Deliveries_Artifacts_Arr_Rel_Insert_Input>;
  bundle_struct_version?: Maybe<Scalars['Int']>;
  customer?: Maybe<Customers_Obj_Rel_Insert_Input>;
  customer_id?: Maybe<Scalars['uuid']>;
  driver_packages?: Maybe<Deliveries_Driver_Package_Arr_Rel_Insert_Input>;
  driver_version_packages?: Maybe<Deliveries_Driver_Version_Package_Arr_Rel_Insert_Input>;
  /** The package name that will be sent to the customer */
  external_package_name?: Maybe<Scalars['String']>;
  major_version?: Maybe<Scalars['Int']>;
  microsoft_hlk_artifacts?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_Arr_Rel_Insert_Input>;
  minor_version?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  package_operating_systems?: Maybe<Deliveries_Package_Operating_Systems_Arr_Rel_Insert_Input>;
  package_targets?: Maybe<Deliveries_Package_Target_Arr_Rel_Insert_Input>;
  platform?: Maybe<Deliveries_Platforms_Obj_Rel_Insert_Input>;
  platform_id?: Maybe<Scalars['uuid']>;
  released_packages?: Maybe<Deliveries_Packages_Arr_Rel_Insert_Input>;
  sbom_blob_url?: Maybe<Scalars['String']>;
  sharepoint_release_notes_path?: Maybe<Scalars['String']>;
  /** Sharepoint upload path for the package */
  sharepoint_upload_path?: Maybe<Scalars['String']>;
  /** Slack channel to post on the release */
  slack_channel?: Maybe<Scalars['String']>;
  spec_package?: Maybe<Deliveries_Packages_Obj_Rel_Insert_Input>;
  spec_package_id?: Maybe<Scalars['uuid']>;
  /** URL in Azure Storage of the package */
  url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Deliveries_Packages_Max_Fields = {
  __typename?: 'deliveries_packages_max_fields';
  architecture_id?: Maybe<Scalars['uuid']>;
  bundle_struct_version?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** The package name that will be sent to the customer */
  external_package_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  major_version?: Maybe<Scalars['Int']>;
  minor_version?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  platform_id?: Maybe<Scalars['uuid']>;
  sbom_blob_url?: Maybe<Scalars['String']>;
  sharepoint_release_notes_path?: Maybe<Scalars['String']>;
  /** Sharepoint upload path for the package */
  sharepoint_upload_path?: Maybe<Scalars['String']>;
  /** Slack channel to post on the release */
  slack_channel?: Maybe<Scalars['String']>;
  spec_package_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** URL in Azure Storage of the package */
  url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "deliveries.packages" */
export type Deliveries_Packages_Max_Order_By = {
  architecture_id?: Maybe<Order_By>;
  bundle_struct_version?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  /** The package name that will be sent to the customer */
  external_package_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  major_version?: Maybe<Order_By>;
  minor_version?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  platform_id?: Maybe<Order_By>;
  sbom_blob_url?: Maybe<Order_By>;
  sharepoint_release_notes_path?: Maybe<Order_By>;
  /** Sharepoint upload path for the package */
  sharepoint_upload_path?: Maybe<Order_By>;
  /** Slack channel to post on the release */
  slack_channel?: Maybe<Order_By>;
  spec_package_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  /** URL in Azure Storage of the package */
  url?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Deliveries_Packages_Min_Fields = {
  __typename?: 'deliveries_packages_min_fields';
  architecture_id?: Maybe<Scalars['uuid']>;
  bundle_struct_version?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** The package name that will be sent to the customer */
  external_package_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  major_version?: Maybe<Scalars['Int']>;
  minor_version?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  platform_id?: Maybe<Scalars['uuid']>;
  sbom_blob_url?: Maybe<Scalars['String']>;
  sharepoint_release_notes_path?: Maybe<Scalars['String']>;
  /** Sharepoint upload path for the package */
  sharepoint_upload_path?: Maybe<Scalars['String']>;
  /** Slack channel to post on the release */
  slack_channel?: Maybe<Scalars['String']>;
  spec_package_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** URL in Azure Storage of the package */
  url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "deliveries.packages" */
export type Deliveries_Packages_Min_Order_By = {
  architecture_id?: Maybe<Order_By>;
  bundle_struct_version?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  /** The package name that will be sent to the customer */
  external_package_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  major_version?: Maybe<Order_By>;
  minor_version?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  platform_id?: Maybe<Order_By>;
  sbom_blob_url?: Maybe<Order_By>;
  sharepoint_release_notes_path?: Maybe<Order_By>;
  /** Sharepoint upload path for the package */
  sharepoint_upload_path?: Maybe<Order_By>;
  /** Slack channel to post on the release */
  slack_channel?: Maybe<Order_By>;
  spec_package_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  /** URL in Azure Storage of the package */
  url?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
};

/** response of any mutation on the table "deliveries.packages" */
export type Deliveries_Packages_Mutation_Response = {
  __typename?: 'deliveries_packages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deliveries_Packages>;
};

/** input type for inserting object relation for remote table "deliveries.packages" */
export type Deliveries_Packages_Obj_Rel_Insert_Input = {
  data: Deliveries_Packages_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Deliveries_Packages_On_Conflict>;
};

/** on_conflict condition type for table "deliveries.packages" */
export type Deliveries_Packages_On_Conflict = {
  constraint: Deliveries_Packages_Constraint;
  update_columns?: Array<Deliveries_Packages_Update_Column>;
  where?: Maybe<Deliveries_Packages_Bool_Exp>;
};

/** Ordering options when selecting data from "deliveries.packages". */
export type Deliveries_Packages_Order_By = {
  architecture?: Maybe<Deliveries_Architectures_Order_By>;
  architecture_id?: Maybe<Order_By>;
  artifacts_aggregate?: Maybe<Deliveries_Artifacts_Aggregate_Order_By>;
  bundle_struct_version?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer?: Maybe<Customers_Order_By>;
  customer_id?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  driver_packages_aggregate?: Maybe<Deliveries_Driver_Package_Aggregate_Order_By>;
  driver_version_packages_aggregate?: Maybe<Deliveries_Driver_Version_Package_Aggregate_Order_By>;
  external_package_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  major_version?: Maybe<Order_By>;
  microsoft_hlk_artifacts_aggregate?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_Aggregate_Order_By>;
  minor_version?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  package_operating_systems_aggregate?: Maybe<Deliveries_Package_Operating_Systems_Aggregate_Order_By>;
  package_targets_aggregate?: Maybe<Deliveries_Package_Target_Aggregate_Order_By>;
  platform?: Maybe<Deliveries_Platforms_Order_By>;
  platform_id?: Maybe<Order_By>;
  released_packages_aggregate?: Maybe<Deliveries_Packages_Aggregate_Order_By>;
  sbom_blob_url?: Maybe<Order_By>;
  sharepoint_release_notes_path?: Maybe<Order_By>;
  sharepoint_upload_path?: Maybe<Order_By>;
  slack_channel?: Maybe<Order_By>;
  spec_package?: Maybe<Deliveries_Packages_Order_By>;
  spec_package_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
};

/** primary key columns input for table: deliveries.packages */
export type Deliveries_Packages_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "deliveries.packages" */
export enum Deliveries_Packages_Select_Column {
  /** column name */
  ArchitectureId = 'architecture_id',
  /** column name */
  BundleStructVersion = 'bundle_struct_version',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ExternalPackageName = 'external_package_name',
  /** column name */
  Id = 'id',
  /** column name */
  MajorVersion = 'major_version',
  /** column name */
  MinorVersion = 'minor_version',
  /** column name */
  Name = 'name',
  /** column name */
  PlatformId = 'platform_id',
  /** column name */
  SbomBlobUrl = 'sbom_blob_url',
  /** column name */
  SharepointReleaseNotesPath = 'sharepoint_release_notes_path',
  /** column name */
  SharepointUploadPath = 'sharepoint_upload_path',
  /** column name */
  SlackChannel = 'slack_channel',
  /** column name */
  SpecPackageId = 'spec_package_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "deliveries.packages" */
export type Deliveries_Packages_Set_Input = {
  architecture_id?: Maybe<Scalars['uuid']>;
  bundle_struct_version?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** The package name that will be sent to the customer */
  external_package_name?: Maybe<Scalars['String']>;
  major_version?: Maybe<Scalars['Int']>;
  minor_version?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  platform_id?: Maybe<Scalars['uuid']>;
  sbom_blob_url?: Maybe<Scalars['String']>;
  sharepoint_release_notes_path?: Maybe<Scalars['String']>;
  /** Sharepoint upload path for the package */
  sharepoint_upload_path?: Maybe<Scalars['String']>;
  /** Slack channel to post on the release */
  slack_channel?: Maybe<Scalars['String']>;
  /** URL in Azure Storage of the package */
  url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Deliveries_Packages_Stddev_Fields = {
  __typename?: 'deliveries_packages_stddev_fields';
  bundle_struct_version?: Maybe<Scalars['Float']>;
  major_version?: Maybe<Scalars['Float']>;
  minor_version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "deliveries.packages" */
export type Deliveries_Packages_Stddev_Order_By = {
  bundle_struct_version?: Maybe<Order_By>;
  major_version?: Maybe<Order_By>;
  minor_version?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Deliveries_Packages_Stddev_Pop_Fields = {
  __typename?: 'deliveries_packages_stddev_pop_fields';
  bundle_struct_version?: Maybe<Scalars['Float']>;
  major_version?: Maybe<Scalars['Float']>;
  minor_version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "deliveries.packages" */
export type Deliveries_Packages_Stddev_Pop_Order_By = {
  bundle_struct_version?: Maybe<Order_By>;
  major_version?: Maybe<Order_By>;
  minor_version?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Deliveries_Packages_Stddev_Samp_Fields = {
  __typename?: 'deliveries_packages_stddev_samp_fields';
  bundle_struct_version?: Maybe<Scalars['Float']>;
  major_version?: Maybe<Scalars['Float']>;
  minor_version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "deliveries.packages" */
export type Deliveries_Packages_Stddev_Samp_Order_By = {
  bundle_struct_version?: Maybe<Order_By>;
  major_version?: Maybe<Order_By>;
  minor_version?: Maybe<Order_By>;
};

/** Streaming cursor of the table "deliveries_packages" */
export type Deliveries_Packages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deliveries_Packages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deliveries_Packages_Stream_Cursor_Value_Input = {
  architecture_id?: Maybe<Scalars['uuid']>;
  bundle_struct_version?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** The package name that will be sent to the customer */
  external_package_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  major_version?: Maybe<Scalars['Int']>;
  minor_version?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  platform_id?: Maybe<Scalars['uuid']>;
  sbom_blob_url?: Maybe<Scalars['String']>;
  sharepoint_release_notes_path?: Maybe<Scalars['String']>;
  /** Sharepoint upload path for the package */
  sharepoint_upload_path?: Maybe<Scalars['String']>;
  /** Slack channel to post on the release */
  slack_channel?: Maybe<Scalars['String']>;
  spec_package_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** URL in Azure Storage of the package */
  url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Deliveries_Packages_Sum_Fields = {
  __typename?: 'deliveries_packages_sum_fields';
  bundle_struct_version?: Maybe<Scalars['Int']>;
  major_version?: Maybe<Scalars['Int']>;
  minor_version?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "deliveries.packages" */
export type Deliveries_Packages_Sum_Order_By = {
  bundle_struct_version?: Maybe<Order_By>;
  major_version?: Maybe<Order_By>;
  minor_version?: Maybe<Order_By>;
};

/** update columns of table "deliveries.packages" */
export enum Deliveries_Packages_Update_Column {
  /** column name */
  ArchitectureId = 'architecture_id',
  /** column name */
  BundleStructVersion = 'bundle_struct_version',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ExternalPackageName = 'external_package_name',
  /** column name */
  MajorVersion = 'major_version',
  /** column name */
  MinorVersion = 'minor_version',
  /** column name */
  Name = 'name',
  /** column name */
  PlatformId = 'platform_id',
  /** column name */
  SbomBlobUrl = 'sbom_blob_url',
  /** column name */
  SharepointReleaseNotesPath = 'sharepoint_release_notes_path',
  /** column name */
  SharepointUploadPath = 'sharepoint_upload_path',
  /** column name */
  SlackChannel = 'slack_channel',
  /** column name */
  Url = 'url',
  /** column name */
  Version = 'version'
}

export type Deliveries_Packages_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Deliveries_Packages_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Deliveries_Packages_Set_Input>;
  where: Deliveries_Packages_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Deliveries_Packages_Var_Pop_Fields = {
  __typename?: 'deliveries_packages_var_pop_fields';
  bundle_struct_version?: Maybe<Scalars['Float']>;
  major_version?: Maybe<Scalars['Float']>;
  minor_version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "deliveries.packages" */
export type Deliveries_Packages_Var_Pop_Order_By = {
  bundle_struct_version?: Maybe<Order_By>;
  major_version?: Maybe<Order_By>;
  minor_version?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Deliveries_Packages_Var_Samp_Fields = {
  __typename?: 'deliveries_packages_var_samp_fields';
  bundle_struct_version?: Maybe<Scalars['Float']>;
  major_version?: Maybe<Scalars['Float']>;
  minor_version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "deliveries.packages" */
export type Deliveries_Packages_Var_Samp_Order_By = {
  bundle_struct_version?: Maybe<Order_By>;
  major_version?: Maybe<Order_By>;
  minor_version?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Deliveries_Packages_Variance_Fields = {
  __typename?: 'deliveries_packages_variance_fields';
  bundle_struct_version?: Maybe<Scalars['Float']>;
  major_version?: Maybe<Scalars['Float']>;
  minor_version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "deliveries.packages" */
export type Deliveries_Packages_Variance_Order_By = {
  bundle_struct_version?: Maybe<Order_By>;
  major_version?: Maybe<Order_By>;
  minor_version?: Maybe<Order_By>;
};

/** Partners used for Shipping labels */
export type Deliveries_Partners = {
  __typename?: 'deliveries_partners';
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  target_partners: Array<Deliveries_Target_Partner>;
};


/** Partners used for Shipping labels */
export type Deliveries_PartnersTarget_PartnersArgs = {
  distinct_on?: Maybe<Array<Deliveries_Target_Partner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Target_Partner_Order_By>>;
  where?: Maybe<Deliveries_Target_Partner_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "deliveries.partners". All fields are combined with a logical 'AND'. */
export type Deliveries_Partners_Bool_Exp = {
  _and?: Maybe<Array<Deliveries_Partners_Bool_Exp>>;
  _not?: Maybe<Deliveries_Partners_Bool_Exp>;
  _or?: Maybe<Array<Deliveries_Partners_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  target_partners?: Maybe<Deliveries_Target_Partner_Bool_Exp>;
};

/** unique or primary key constraints on table "deliveries.partners" */
export enum Deliveries_Partners_Constraint {
  /** unique or primary key constraint on columns "name" */
  PartnersNameKey = 'partners_name_key',
  /** unique or primary key constraint on columns "id" */
  PartnersPkey = 'partners_pkey'
}

/** input type for inserting data into table "deliveries.partners" */
export type Deliveries_Partners_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  target_partners?: Maybe<Deliveries_Target_Partner_Arr_Rel_Insert_Input>;
};

/** response of any mutation on the table "deliveries.partners" */
export type Deliveries_Partners_Mutation_Response = {
  __typename?: 'deliveries_partners_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deliveries_Partners>;
};

/** input type for inserting object relation for remote table "deliveries.partners" */
export type Deliveries_Partners_Obj_Rel_Insert_Input = {
  data: Deliveries_Partners_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Deliveries_Partners_On_Conflict>;
};

/** on_conflict condition type for table "deliveries.partners" */
export type Deliveries_Partners_On_Conflict = {
  constraint: Deliveries_Partners_Constraint;
  update_columns?: Array<Deliveries_Partners_Update_Column>;
  where?: Maybe<Deliveries_Partners_Bool_Exp>;
};

/** Ordering options when selecting data from "deliveries.partners". */
export type Deliveries_Partners_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  target_partners_aggregate?: Maybe<Deliveries_Target_Partner_Aggregate_Order_By>;
};

/** primary key columns input for table: deliveries.partners */
export type Deliveries_Partners_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "deliveries.partners" */
export enum Deliveries_Partners_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "deliveries.partners" */
export type Deliveries_Partners_Set_Input = {
  name?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "deliveries_partners" */
export type Deliveries_Partners_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deliveries_Partners_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deliveries_Partners_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "deliveries.partners" */
export enum Deliveries_Partners_Update_Column {
  /** column name */
  Name = 'name'
}

export type Deliveries_Partners_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Deliveries_Partners_Set_Input>;
  where: Deliveries_Partners_Bool_Exp;
};

/** columns and relationships of "deliveries.platforms" */
export type Deliveries_Platforms = {
  __typename?: 'deliveries_platforms';
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  packages: Array<Deliveries_Packages>;
  /** An aggregate relationship */
  packages_aggregate: Deliveries_Packages_Aggregate;
};


/** columns and relationships of "deliveries.platforms" */
export type Deliveries_PlatformsPackagesArgs = {
  distinct_on?: Maybe<Array<Deliveries_Packages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Packages_Order_By>>;
  where?: Maybe<Deliveries_Packages_Bool_Exp>;
};


/** columns and relationships of "deliveries.platforms" */
export type Deliveries_PlatformsPackages_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Packages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Packages_Order_By>>;
  where?: Maybe<Deliveries_Packages_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "deliveries.platforms". All fields are combined with a logical 'AND'. */
export type Deliveries_Platforms_Bool_Exp = {
  _and?: Maybe<Array<Deliveries_Platforms_Bool_Exp>>;
  _not?: Maybe<Deliveries_Platforms_Bool_Exp>;
  _or?: Maybe<Array<Deliveries_Platforms_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  packages?: Maybe<Deliveries_Packages_Bool_Exp>;
};

/** unique or primary key constraints on table "deliveries.platforms" */
export enum Deliveries_Platforms_Constraint {
  /** unique or primary key constraint on columns "name" */
  PlatformsNameKey = 'platforms_name_key',
  /** unique or primary key constraint on columns "id" */
  PlatformsPkey = 'platforms_pkey'
}

/** input type for inserting data into table "deliveries.platforms" */
export type Deliveries_Platforms_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  packages?: Maybe<Deliveries_Packages_Arr_Rel_Insert_Input>;
};

/** response of any mutation on the table "deliveries.platforms" */
export type Deliveries_Platforms_Mutation_Response = {
  __typename?: 'deliveries_platforms_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deliveries_Platforms>;
};

/** input type for inserting object relation for remote table "deliveries.platforms" */
export type Deliveries_Platforms_Obj_Rel_Insert_Input = {
  data: Deliveries_Platforms_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Deliveries_Platforms_On_Conflict>;
};

/** on_conflict condition type for table "deliveries.platforms" */
export type Deliveries_Platforms_On_Conflict = {
  constraint: Deliveries_Platforms_Constraint;
  update_columns?: Array<Deliveries_Platforms_Update_Column>;
  where?: Maybe<Deliveries_Platforms_Bool_Exp>;
};

/** Ordering options when selecting data from "deliveries.platforms". */
export type Deliveries_Platforms_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  packages_aggregate?: Maybe<Deliveries_Packages_Aggregate_Order_By>;
};

/** primary key columns input for table: deliveries.platforms */
export type Deliveries_Platforms_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "deliveries.platforms" */
export enum Deliveries_Platforms_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "deliveries.platforms" */
export type Deliveries_Platforms_Set_Input = {
  name?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "deliveries_platforms" */
export type Deliveries_Platforms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deliveries_Platforms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deliveries_Platforms_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "deliveries.platforms" */
export enum Deliveries_Platforms_Update_Column {
  /** column name */
  Name = 'name'
}

export type Deliveries_Platforms_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Deliveries_Platforms_Set_Input>;
  where: Deliveries_Platforms_Bool_Exp;
};

/** Link a config id to a given project */
export type Deliveries_Project_Configs = {
  __typename?: 'deliveries_project_configs';
  chid_level?: Maybe<Scalars['Int']>;
  config_id: Scalars['String'];
  config_id_type: Deliveries_Config_Id_Types_Enum;
  controller_updates?: Maybe<Scalars['_text']>;
  id: Scalars['uuid'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
};

/** aggregated selection of "deliveries.project_configs" */
export type Deliveries_Project_Configs_Aggregate = {
  __typename?: 'deliveries_project_configs_aggregate';
  aggregate?: Maybe<Deliveries_Project_Configs_Aggregate_Fields>;
  nodes: Array<Deliveries_Project_Configs>;
};

/** aggregate fields of "deliveries.project_configs" */
export type Deliveries_Project_Configs_Aggregate_Fields = {
  __typename?: 'deliveries_project_configs_aggregate_fields';
  avg?: Maybe<Deliveries_Project_Configs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Deliveries_Project_Configs_Max_Fields>;
  min?: Maybe<Deliveries_Project_Configs_Min_Fields>;
  stddev?: Maybe<Deliveries_Project_Configs_Stddev_Fields>;
  stddev_pop?: Maybe<Deliveries_Project_Configs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Deliveries_Project_Configs_Stddev_Samp_Fields>;
  sum?: Maybe<Deliveries_Project_Configs_Sum_Fields>;
  var_pop?: Maybe<Deliveries_Project_Configs_Var_Pop_Fields>;
  var_samp?: Maybe<Deliveries_Project_Configs_Var_Samp_Fields>;
  variance?: Maybe<Deliveries_Project_Configs_Variance_Fields>;
};


/** aggregate fields of "deliveries.project_configs" */
export type Deliveries_Project_Configs_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Deliveries_Project_Configs_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Deliveries_Project_Configs_Avg_Fields = {
  __typename?: 'deliveries_project_configs_avg_fields';
  chid_level?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "deliveries.project_configs". All fields are combined with a logical 'AND'. */
export type Deliveries_Project_Configs_Bool_Exp = {
  _and?: Maybe<Array<Deliveries_Project_Configs_Bool_Exp>>;
  _not?: Maybe<Deliveries_Project_Configs_Bool_Exp>;
  _or?: Maybe<Array<Deliveries_Project_Configs_Bool_Exp>>;
  chid_level?: Maybe<Int_Comparison_Exp>;
  config_id?: Maybe<String_Comparison_Exp>;
  config_id_type?: Maybe<Deliveries_Config_Id_Types_Enum_Comparison_Exp>;
  controller_updates?: Maybe<_Text_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  project?: Maybe<Projects_Bool_Exp>;
  project_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "deliveries.project_configs" */
export enum Deliveries_Project_Configs_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectConfigsIdKey = 'project_configs_id_key',
  /** unique or primary key constraint on columns "id" */
  ProjectConfigsPkey = 'project_configs_pkey'
}

/** input type for incrementing numeric columns in table "deliveries.project_configs" */
export type Deliveries_Project_Configs_Inc_Input = {
  chid_level?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "deliveries.project_configs" */
export type Deliveries_Project_Configs_Insert_Input = {
  chid_level?: Maybe<Scalars['Int']>;
  config_id?: Maybe<Scalars['String']>;
  config_id_type?: Maybe<Deliveries_Config_Id_Types_Enum>;
  controller_updates?: Maybe<Scalars['_text']>;
  id?: Maybe<Scalars['uuid']>;
  project?: Maybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Deliveries_Project_Configs_Max_Fields = {
  __typename?: 'deliveries_project_configs_max_fields';
  chid_level?: Maybe<Scalars['Int']>;
  config_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Deliveries_Project_Configs_Min_Fields = {
  __typename?: 'deliveries_project_configs_min_fields';
  chid_level?: Maybe<Scalars['Int']>;
  config_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "deliveries.project_configs" */
export type Deliveries_Project_Configs_Mutation_Response = {
  __typename?: 'deliveries_project_configs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deliveries_Project_Configs>;
};

/** on_conflict condition type for table "deliveries.project_configs" */
export type Deliveries_Project_Configs_On_Conflict = {
  constraint: Deliveries_Project_Configs_Constraint;
  update_columns?: Array<Deliveries_Project_Configs_Update_Column>;
  where?: Maybe<Deliveries_Project_Configs_Bool_Exp>;
};

/** Ordering options when selecting data from "deliveries.project_configs". */
export type Deliveries_Project_Configs_Order_By = {
  chid_level?: Maybe<Order_By>;
  config_id?: Maybe<Order_By>;
  config_id_type?: Maybe<Order_By>;
  controller_updates?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project?: Maybe<Projects_Order_By>;
  project_id?: Maybe<Order_By>;
};

/** primary key columns input for table: deliveries.project_configs */
export type Deliveries_Project_Configs_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "deliveries.project_configs" */
export enum Deliveries_Project_Configs_Select_Column {
  /** column name */
  ChidLevel = 'chid_level',
  /** column name */
  ConfigId = 'config_id',
  /** column name */
  ConfigIdType = 'config_id_type',
  /** column name */
  ControllerUpdates = 'controller_updates',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id'
}

/** input type for updating data in table "deliveries.project_configs" */
export type Deliveries_Project_Configs_Set_Input = {
  chid_level?: Maybe<Scalars['Int']>;
  config_id?: Maybe<Scalars['String']>;
  config_id_type?: Maybe<Deliveries_Config_Id_Types_Enum>;
  controller_updates?: Maybe<Scalars['_text']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Deliveries_Project_Configs_Stddev_Fields = {
  __typename?: 'deliveries_project_configs_stddev_fields';
  chid_level?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Deliveries_Project_Configs_Stddev_Pop_Fields = {
  __typename?: 'deliveries_project_configs_stddev_pop_fields';
  chid_level?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Deliveries_Project_Configs_Stddev_Samp_Fields = {
  __typename?: 'deliveries_project_configs_stddev_samp_fields';
  chid_level?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "deliveries_project_configs" */
export type Deliveries_Project_Configs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deliveries_Project_Configs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deliveries_Project_Configs_Stream_Cursor_Value_Input = {
  chid_level?: Maybe<Scalars['Int']>;
  config_id?: Maybe<Scalars['String']>;
  config_id_type?: Maybe<Deliveries_Config_Id_Types_Enum>;
  controller_updates?: Maybe<Scalars['_text']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Deliveries_Project_Configs_Sum_Fields = {
  __typename?: 'deliveries_project_configs_sum_fields';
  chid_level?: Maybe<Scalars['Int']>;
};

/** update columns of table "deliveries.project_configs" */
export enum Deliveries_Project_Configs_Update_Column {
  /** column name */
  ChidLevel = 'chid_level',
  /** column name */
  ConfigId = 'config_id',
  /** column name */
  ConfigIdType = 'config_id_type',
  /** column name */
  ControllerUpdates = 'controller_updates',
  /** column name */
  ProjectId = 'project_id'
}

export type Deliveries_Project_Configs_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Deliveries_Project_Configs_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Deliveries_Project_Configs_Set_Input>;
  where: Deliveries_Project_Configs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Deliveries_Project_Configs_Var_Pop_Fields = {
  __typename?: 'deliveries_project_configs_var_pop_fields';
  chid_level?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Deliveries_Project_Configs_Var_Samp_Fields = {
  __typename?: 'deliveries_project_configs_var_samp_fields';
  chid_level?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Deliveries_Project_Configs_Variance_Fields = {
  __typename?: 'deliveries_project_configs_variance_fields';
  chid_level?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "deliveries.target_partner" */
export type Deliveries_Target_Partner = {
  __typename?: 'deliveries_target_partner';
  /** An object relationship */
  partner: Deliveries_Partners;
  partner_id: Scalars['uuid'];
  /** An object relationship */
  target: Deliveries_Targets;
  target_id: Scalars['uuid'];
};

/** order by aggregate values of table "deliveries.target_partner" */
export type Deliveries_Target_Partner_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Deliveries_Target_Partner_Max_Order_By>;
  min?: Maybe<Deliveries_Target_Partner_Min_Order_By>;
};

/** input type for inserting array relation for remote table "deliveries.target_partner" */
export type Deliveries_Target_Partner_Arr_Rel_Insert_Input = {
  data: Array<Deliveries_Target_Partner_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Deliveries_Target_Partner_On_Conflict>;
};

/** Boolean expression to filter rows from the table "deliveries.target_partner". All fields are combined with a logical 'AND'. */
export type Deliveries_Target_Partner_Bool_Exp = {
  _and?: Maybe<Array<Deliveries_Target_Partner_Bool_Exp>>;
  _not?: Maybe<Deliveries_Target_Partner_Bool_Exp>;
  _or?: Maybe<Array<Deliveries_Target_Partner_Bool_Exp>>;
  partner?: Maybe<Deliveries_Partners_Bool_Exp>;
  partner_id?: Maybe<Uuid_Comparison_Exp>;
  target?: Maybe<Deliveries_Targets_Bool_Exp>;
  target_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "deliveries.target_partner" */
export enum Deliveries_Target_Partner_Constraint {
  /** unique or primary key constraint on columns "partner_id", "target_id" */
  TargetPartnerPkey = 'target_partner_pkey'
}

/** input type for inserting data into table "deliveries.target_partner" */
export type Deliveries_Target_Partner_Insert_Input = {
  partner?: Maybe<Deliveries_Partners_Obj_Rel_Insert_Input>;
  partner_id?: Maybe<Scalars['uuid']>;
  target?: Maybe<Deliveries_Targets_Obj_Rel_Insert_Input>;
  target_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "deliveries.target_partner" */
export type Deliveries_Target_Partner_Max_Order_By = {
  partner_id?: Maybe<Order_By>;
  target_id?: Maybe<Order_By>;
};

/** order by min() on columns of table "deliveries.target_partner" */
export type Deliveries_Target_Partner_Min_Order_By = {
  partner_id?: Maybe<Order_By>;
  target_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "deliveries.target_partner" */
export type Deliveries_Target_Partner_Mutation_Response = {
  __typename?: 'deliveries_target_partner_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deliveries_Target_Partner>;
};

/** on_conflict condition type for table "deliveries.target_partner" */
export type Deliveries_Target_Partner_On_Conflict = {
  constraint: Deliveries_Target_Partner_Constraint;
  update_columns?: Array<Deliveries_Target_Partner_Update_Column>;
  where?: Maybe<Deliveries_Target_Partner_Bool_Exp>;
};

/** Ordering options when selecting data from "deliveries.target_partner". */
export type Deliveries_Target_Partner_Order_By = {
  partner?: Maybe<Deliveries_Partners_Order_By>;
  partner_id?: Maybe<Order_By>;
  target?: Maybe<Deliveries_Targets_Order_By>;
  target_id?: Maybe<Order_By>;
};

/** primary key columns input for table: deliveries.target_partner */
export type Deliveries_Target_Partner_Pk_Columns_Input = {
  partner_id: Scalars['uuid'];
  target_id: Scalars['uuid'];
};

/** select columns of table "deliveries.target_partner" */
export enum Deliveries_Target_Partner_Select_Column {
  /** column name */
  PartnerId = 'partner_id',
  /** column name */
  TargetId = 'target_id'
}

/** input type for updating data in table "deliveries.target_partner" */
export type Deliveries_Target_Partner_Set_Input = {
  partner_id?: Maybe<Scalars['uuid']>;
  target_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "deliveries_target_partner" */
export type Deliveries_Target_Partner_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deliveries_Target_Partner_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deliveries_Target_Partner_Stream_Cursor_Value_Input = {
  partner_id?: Maybe<Scalars['uuid']>;
  target_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "deliveries.target_partner" */
export enum Deliveries_Target_Partner_Update_Column {
  /** column name */
  PartnerId = 'partner_id',
  /** column name */
  TargetId = 'target_id'
}

export type Deliveries_Target_Partner_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Deliveries_Target_Partner_Set_Input>;
  where: Deliveries_Target_Partner_Bool_Exp;
};

/** The devices for which our packages are shipped to.  */
export type Deliveries_Targets = {
  __typename?: 'deliveries_targets';
  chids: Scalars['_text'];
  /** An object relationship */
  customer: Customers;
  customer_id: Scalars['uuid'];
  hardware_ids?: Maybe<Scalars['_text']>;
  id: Scalars['uuid'];
  /** An array relationship */
  microsoft_shipping_labels: Array<Deliveries_Microsoft_Shipping_Labels>;
  /** An aggregate relationship */
  microsoft_shipping_labels_aggregate: Deliveries_Microsoft_Shipping_Labels_Aggregate;
  /** An object relationship */
  model: Models;
  model_id: Scalars['uuid'];
  /** An array relationship */
  package_targets: Array<Deliveries_Package_Target>;
  /** An aggregate relationship */
  package_targets_aggregate: Deliveries_Package_Target_Aggregate;
  /** An array relationship */
  target_partners: Array<Deliveries_Target_Partner>;
};


/** The devices for which our packages are shipped to.  */
export type Deliveries_TargetsMicrosoft_Shipping_LabelsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Microsoft_Shipping_Labels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Microsoft_Shipping_Labels_Order_By>>;
  where?: Maybe<Deliveries_Microsoft_Shipping_Labels_Bool_Exp>;
};


/** The devices for which our packages are shipped to.  */
export type Deliveries_TargetsMicrosoft_Shipping_Labels_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Microsoft_Shipping_Labels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Microsoft_Shipping_Labels_Order_By>>;
  where?: Maybe<Deliveries_Microsoft_Shipping_Labels_Bool_Exp>;
};


/** The devices for which our packages are shipped to.  */
export type Deliveries_TargetsPackage_TargetsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Package_Target_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Package_Target_Order_By>>;
  where?: Maybe<Deliveries_Package_Target_Bool_Exp>;
};


/** The devices for which our packages are shipped to.  */
export type Deliveries_TargetsPackage_Targets_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Package_Target_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Package_Target_Order_By>>;
  where?: Maybe<Deliveries_Package_Target_Bool_Exp>;
};


/** The devices for which our packages are shipped to.  */
export type Deliveries_TargetsTarget_PartnersArgs = {
  distinct_on?: Maybe<Array<Deliveries_Target_Partner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Target_Partner_Order_By>>;
  where?: Maybe<Deliveries_Target_Partner_Bool_Exp>;
};

/** aggregated selection of "deliveries.targets" */
export type Deliveries_Targets_Aggregate = {
  __typename?: 'deliveries_targets_aggregate';
  aggregate?: Maybe<Deliveries_Targets_Aggregate_Fields>;
  nodes: Array<Deliveries_Targets>;
};

/** aggregate fields of "deliveries.targets" */
export type Deliveries_Targets_Aggregate_Fields = {
  __typename?: 'deliveries_targets_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Deliveries_Targets_Max_Fields>;
  min?: Maybe<Deliveries_Targets_Min_Fields>;
};


/** aggregate fields of "deliveries.targets" */
export type Deliveries_Targets_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Deliveries_Targets_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "deliveries.targets". All fields are combined with a logical 'AND'. */
export type Deliveries_Targets_Bool_Exp = {
  _and?: Maybe<Array<Deliveries_Targets_Bool_Exp>>;
  _not?: Maybe<Deliveries_Targets_Bool_Exp>;
  _or?: Maybe<Array<Deliveries_Targets_Bool_Exp>>;
  chids?: Maybe<_Text_Comparison_Exp>;
  customer?: Maybe<Customers_Bool_Exp>;
  customer_id?: Maybe<Uuid_Comparison_Exp>;
  hardware_ids?: Maybe<_Text_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  microsoft_shipping_labels?: Maybe<Deliveries_Microsoft_Shipping_Labels_Bool_Exp>;
  model?: Maybe<Models_Bool_Exp>;
  model_id?: Maybe<Uuid_Comparison_Exp>;
  package_targets?: Maybe<Deliveries_Package_Target_Bool_Exp>;
  target_partners?: Maybe<Deliveries_Target_Partner_Bool_Exp>;
};

/** unique or primary key constraints on table "deliveries.targets" */
export enum Deliveries_Targets_Constraint {
  /** unique or primary key constraint on columns "model_id", "customer_id" */
  TargetsModelIdCustomerIdKey = 'targets_model_id_customer_id_key',
  /** unique or primary key constraint on columns "id" */
  TargetsPkey = 'targets_pkey'
}

/** input type for inserting data into table "deliveries.targets" */
export type Deliveries_Targets_Insert_Input = {
  chids?: Maybe<Scalars['_text']>;
  customer?: Maybe<Customers_Obj_Rel_Insert_Input>;
  customer_id?: Maybe<Scalars['uuid']>;
  hardware_ids?: Maybe<Scalars['_text']>;
  id?: Maybe<Scalars['uuid']>;
  microsoft_shipping_labels?: Maybe<Deliveries_Microsoft_Shipping_Labels_Arr_Rel_Insert_Input>;
  model?: Maybe<Models_Obj_Rel_Insert_Input>;
  model_id?: Maybe<Scalars['uuid']>;
  package_targets?: Maybe<Deliveries_Package_Target_Arr_Rel_Insert_Input>;
  target_partners?: Maybe<Deliveries_Target_Partner_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Deliveries_Targets_Max_Fields = {
  __typename?: 'deliveries_targets_max_fields';
  customer_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  model_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Deliveries_Targets_Min_Fields = {
  __typename?: 'deliveries_targets_min_fields';
  customer_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  model_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "deliveries.targets" */
export type Deliveries_Targets_Mutation_Response = {
  __typename?: 'deliveries_targets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deliveries_Targets>;
};

/** input type for inserting object relation for remote table "deliveries.targets" */
export type Deliveries_Targets_Obj_Rel_Insert_Input = {
  data: Deliveries_Targets_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Deliveries_Targets_On_Conflict>;
};

/** on_conflict condition type for table "deliveries.targets" */
export type Deliveries_Targets_On_Conflict = {
  constraint: Deliveries_Targets_Constraint;
  update_columns?: Array<Deliveries_Targets_Update_Column>;
  where?: Maybe<Deliveries_Targets_Bool_Exp>;
};

/** Ordering options when selecting data from "deliveries.targets". */
export type Deliveries_Targets_Order_By = {
  chids?: Maybe<Order_By>;
  customer?: Maybe<Customers_Order_By>;
  customer_id?: Maybe<Order_By>;
  hardware_ids?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  microsoft_shipping_labels_aggregate?: Maybe<Deliveries_Microsoft_Shipping_Labels_Aggregate_Order_By>;
  model?: Maybe<Models_Order_By>;
  model_id?: Maybe<Order_By>;
  package_targets_aggregate?: Maybe<Deliveries_Package_Target_Aggregate_Order_By>;
  target_partners_aggregate?: Maybe<Deliveries_Target_Partner_Aggregate_Order_By>;
};

/** primary key columns input for table: deliveries.targets */
export type Deliveries_Targets_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "deliveries.targets" */
export enum Deliveries_Targets_Select_Column {
  /** column name */
  Chids = 'chids',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  HardwareIds = 'hardware_ids',
  /** column name */
  Id = 'id',
  /** column name */
  ModelId = 'model_id'
}

/** input type for updating data in table "deliveries.targets" */
export type Deliveries_Targets_Set_Input = {
  chids?: Maybe<Scalars['_text']>;
  customer_id?: Maybe<Scalars['uuid']>;
  hardware_ids?: Maybe<Scalars['_text']>;
  model_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "deliveries_targets" */
export type Deliveries_Targets_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deliveries_Targets_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deliveries_Targets_Stream_Cursor_Value_Input = {
  chids?: Maybe<Scalars['_text']>;
  customer_id?: Maybe<Scalars['uuid']>;
  hardware_ids?: Maybe<Scalars['_text']>;
  id?: Maybe<Scalars['uuid']>;
  model_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "deliveries.targets" */
export enum Deliveries_Targets_Update_Column {
  /** column name */
  Chids = 'chids',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  HardwareIds = 'hardware_ids',
  /** column name */
  ModelId = 'model_id'
}

export type Deliveries_Targets_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Deliveries_Targets_Set_Input>;
  where: Deliveries_Targets_Bool_Exp;
};

/** columns and relationships of "device_firmware" */
export type Device_Firmware = {
  __typename?: 'device_firmware';
  /** An object relationship */
  device: Devices;
  device_id: Scalars['uuid'];
  /** An object relationship */
  firmware: Firmware;
  firmware_id: Scalars['uuid'];
  /** An array relationship */
  recordings: Array<Recordings>;
  /** An aggregate relationship */
  recordings_aggregate: Recordings_Aggregate;
};


/** columns and relationships of "device_firmware" */
export type Device_FirmwareRecordingsArgs = {
  distinct_on?: Maybe<Array<Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recordings_Order_By>>;
  where?: Maybe<Recordings_Bool_Exp>;
};


/** columns and relationships of "device_firmware" */
export type Device_FirmwareRecordings_AggregateArgs = {
  distinct_on?: Maybe<Array<Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recordings_Order_By>>;
  where?: Maybe<Recordings_Bool_Exp>;
};

/** order by aggregate values of table "device_firmware" */
export type Device_Firmware_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Device_Firmware_Max_Order_By>;
  min?: Maybe<Device_Firmware_Min_Order_By>;
};

/** input type for inserting array relation for remote table "device_firmware" */
export type Device_Firmware_Arr_Rel_Insert_Input = {
  data: Array<Device_Firmware_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Device_Firmware_On_Conflict>;
};

/** Boolean expression to filter rows from the table "device_firmware". All fields are combined with a logical 'AND'. */
export type Device_Firmware_Bool_Exp = {
  _and?: Maybe<Array<Device_Firmware_Bool_Exp>>;
  _not?: Maybe<Device_Firmware_Bool_Exp>;
  _or?: Maybe<Array<Device_Firmware_Bool_Exp>>;
  device?: Maybe<Devices_Bool_Exp>;
  device_id?: Maybe<Uuid_Comparison_Exp>;
  firmware?: Maybe<Firmware_Bool_Exp>;
  firmware_id?: Maybe<Uuid_Comparison_Exp>;
  recordings?: Maybe<Recordings_Bool_Exp>;
};

/** unique or primary key constraints on table "device_firmware" */
export enum Device_Firmware_Constraint {
  /** unique or primary key constraint on columns "firmware_id", "device_id" */
  DeviceFirmwarePkey = 'device_firmware_pkey'
}

/** input type for inserting data into table "device_firmware" */
export type Device_Firmware_Insert_Input = {
  device?: Maybe<Devices_Obj_Rel_Insert_Input>;
  device_id?: Maybe<Scalars['uuid']>;
  firmware?: Maybe<Firmware_Obj_Rel_Insert_Input>;
  firmware_id?: Maybe<Scalars['uuid']>;
  recordings?: Maybe<Recordings_Arr_Rel_Insert_Input>;
};

/** order by max() on columns of table "device_firmware" */
export type Device_Firmware_Max_Order_By = {
  device_id?: Maybe<Order_By>;
  firmware_id?: Maybe<Order_By>;
};

/** order by min() on columns of table "device_firmware" */
export type Device_Firmware_Min_Order_By = {
  device_id?: Maybe<Order_By>;
  firmware_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "device_firmware" */
export type Device_Firmware_Mutation_Response = {
  __typename?: 'device_firmware_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Device_Firmware>;
};

/** input type for inserting object relation for remote table "device_firmware" */
export type Device_Firmware_Obj_Rel_Insert_Input = {
  data: Device_Firmware_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Device_Firmware_On_Conflict>;
};

/** on_conflict condition type for table "device_firmware" */
export type Device_Firmware_On_Conflict = {
  constraint: Device_Firmware_Constraint;
  update_columns?: Array<Device_Firmware_Update_Column>;
  where?: Maybe<Device_Firmware_Bool_Exp>;
};

/** Ordering options when selecting data from "device_firmware". */
export type Device_Firmware_Order_By = {
  device?: Maybe<Devices_Order_By>;
  device_id?: Maybe<Order_By>;
  firmware?: Maybe<Firmware_Order_By>;
  firmware_id?: Maybe<Order_By>;
  recordings_aggregate?: Maybe<Recordings_Aggregate_Order_By>;
};

/** primary key columns input for table: device_firmware */
export type Device_Firmware_Pk_Columns_Input = {
  device_id: Scalars['uuid'];
  firmware_id: Scalars['uuid'];
};

/** select columns of table "device_firmware" */
export enum Device_Firmware_Select_Column {
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  FirmwareId = 'firmware_id'
}

/** input type for updating data in table "device_firmware" */
export type Device_Firmware_Set_Input = {
  device_id?: Maybe<Scalars['uuid']>;
  firmware_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "device_firmware" */
export type Device_Firmware_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Device_Firmware_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Device_Firmware_Stream_Cursor_Value_Input = {
  device_id?: Maybe<Scalars['uuid']>;
  firmware_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "device_firmware" */
export enum Device_Firmware_Update_Column {
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  FirmwareId = 'firmware_id'
}

export type Device_Firmware_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Device_Firmware_Set_Input>;
  where: Device_Firmware_Bool_Exp;
};

/** columns and relationships of "device_project" */
export type Device_Project = {
  __typename?: 'device_project';
  /** An object relationship */
  device: Devices;
  device_id: Scalars['uuid'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
};

/** aggregated selection of "device_project" */
export type Device_Project_Aggregate = {
  __typename?: 'device_project_aggregate';
  aggregate?: Maybe<Device_Project_Aggregate_Fields>;
  nodes: Array<Device_Project>;
};

/** aggregate fields of "device_project" */
export type Device_Project_Aggregate_Fields = {
  __typename?: 'device_project_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Device_Project_Max_Fields>;
  min?: Maybe<Device_Project_Min_Fields>;
};


/** aggregate fields of "device_project" */
export type Device_Project_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Device_Project_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "device_project" */
export type Device_Project_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Device_Project_Max_Order_By>;
  min?: Maybe<Device_Project_Min_Order_By>;
};

/** input type for inserting array relation for remote table "device_project" */
export type Device_Project_Arr_Rel_Insert_Input = {
  data: Array<Device_Project_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Device_Project_On_Conflict>;
};

/** Boolean expression to filter rows from the table "device_project". All fields are combined with a logical 'AND'. */
export type Device_Project_Bool_Exp = {
  _and?: Maybe<Array<Device_Project_Bool_Exp>>;
  _not?: Maybe<Device_Project_Bool_Exp>;
  _or?: Maybe<Array<Device_Project_Bool_Exp>>;
  device?: Maybe<Devices_Bool_Exp>;
  device_id?: Maybe<Uuid_Comparison_Exp>;
  project?: Maybe<Projects_Bool_Exp>;
  project_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "device_project" */
export enum Device_Project_Constraint {
  /** unique or primary key constraint on columns "device_id", "project_id" */
  DeviceProjectPkey = 'device_project_pkey'
}

/** input type for inserting data into table "device_project" */
export type Device_Project_Insert_Input = {
  device?: Maybe<Devices_Obj_Rel_Insert_Input>;
  device_id?: Maybe<Scalars['uuid']>;
  project?: Maybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Device_Project_Max_Fields = {
  __typename?: 'device_project_max_fields';
  device_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "device_project" */
export type Device_Project_Max_Order_By = {
  device_id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Device_Project_Min_Fields = {
  __typename?: 'device_project_min_fields';
  device_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "device_project" */
export type Device_Project_Min_Order_By = {
  device_id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "device_project" */
export type Device_Project_Mutation_Response = {
  __typename?: 'device_project_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Device_Project>;
};

/** on_conflict condition type for table "device_project" */
export type Device_Project_On_Conflict = {
  constraint: Device_Project_Constraint;
  update_columns?: Array<Device_Project_Update_Column>;
  where?: Maybe<Device_Project_Bool_Exp>;
};

/** Ordering options when selecting data from "device_project". */
export type Device_Project_Order_By = {
  device?: Maybe<Devices_Order_By>;
  device_id?: Maybe<Order_By>;
  project?: Maybe<Projects_Order_By>;
  project_id?: Maybe<Order_By>;
};

/** primary key columns input for table: device_project */
export type Device_Project_Pk_Columns_Input = {
  device_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};

/** select columns of table "device_project" */
export enum Device_Project_Select_Column {
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  ProjectId = 'project_id'
}

/** input type for updating data in table "device_project" */
export type Device_Project_Set_Input = {
  device_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "device_project" */
export type Device_Project_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Device_Project_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Device_Project_Stream_Cursor_Value_Input = {
  device_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "device_project" */
export enum Device_Project_Update_Column {
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  ProjectId = 'project_id'
}

export type Device_Project_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Device_Project_Set_Input>;
  where: Device_Project_Bool_Exp;
};

/** columns and relationships of "devices" */
export type Devices = {
  __typename?: 'devices';
  /** An object relationship */
  configuration?: Maybe<Audio_Measurements_Configurations>;
  configuration_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  device_firmwares: Array<Device_Firmware>;
  /** An array relationship */
  device_projects: Array<Device_Project>;
  /** An aggregate relationship */
  device_projects_aggregate: Device_Project_Aggregate;
  id: Scalars['uuid'];
  /** An object relationship */
  model?: Maybe<Models>;
  model_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  project_dataset: Array<Project_Dataset>;
  /** An aggregate relationship */
  project_dataset_aggregate: Project_Dataset_Aggregate;
  /** An array relationship */
  recordings: Array<Recordings>;
  /** An aggregate relationship */
  recordings_aggregate: Recordings_Aggregate;
  serialno: Scalars['String'];
  sku?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "devices" */
export type DevicesDevice_FirmwaresArgs = {
  distinct_on?: Maybe<Array<Device_Firmware_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Device_Firmware_Order_By>>;
  where?: Maybe<Device_Firmware_Bool_Exp>;
};


/** columns and relationships of "devices" */
export type DevicesDevice_ProjectsArgs = {
  distinct_on?: Maybe<Array<Device_Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Device_Project_Order_By>>;
  where?: Maybe<Device_Project_Bool_Exp>;
};


/** columns and relationships of "devices" */
export type DevicesDevice_Projects_AggregateArgs = {
  distinct_on?: Maybe<Array<Device_Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Device_Project_Order_By>>;
  where?: Maybe<Device_Project_Bool_Exp>;
};


/** columns and relationships of "devices" */
export type DevicesProject_DatasetArgs = {
  distinct_on?: Maybe<Array<Project_Dataset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Dataset_Order_By>>;
  where?: Maybe<Project_Dataset_Bool_Exp>;
};


/** columns and relationships of "devices" */
export type DevicesProject_Dataset_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Dataset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Dataset_Order_By>>;
  where?: Maybe<Project_Dataset_Bool_Exp>;
};


/** columns and relationships of "devices" */
export type DevicesRecordingsArgs = {
  distinct_on?: Maybe<Array<Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recordings_Order_By>>;
  where?: Maybe<Recordings_Bool_Exp>;
};


/** columns and relationships of "devices" */
export type DevicesRecordings_AggregateArgs = {
  distinct_on?: Maybe<Array<Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recordings_Order_By>>;
  where?: Maybe<Recordings_Bool_Exp>;
};

/** aggregated selection of "devices" */
export type Devices_Aggregate = {
  __typename?: 'devices_aggregate';
  aggregate?: Maybe<Devices_Aggregate_Fields>;
  nodes: Array<Devices>;
};

/** aggregate fields of "devices" */
export type Devices_Aggregate_Fields = {
  __typename?: 'devices_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Devices_Max_Fields>;
  min?: Maybe<Devices_Min_Fields>;
};


/** aggregate fields of "devices" */
export type Devices_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Devices_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "devices" */
export type Devices_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Devices_Max_Order_By>;
  min?: Maybe<Devices_Min_Order_By>;
};

/** input type for inserting array relation for remote table "devices" */
export type Devices_Arr_Rel_Insert_Input = {
  data: Array<Devices_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Devices_On_Conflict>;
};

/** Boolean expression to filter rows from the table "devices". All fields are combined with a logical 'AND'. */
export type Devices_Bool_Exp = {
  _and?: Maybe<Array<Devices_Bool_Exp>>;
  _not?: Maybe<Devices_Bool_Exp>;
  _or?: Maybe<Array<Devices_Bool_Exp>>;
  configuration?: Maybe<Audio_Measurements_Configurations_Bool_Exp>;
  configuration_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  device_firmwares?: Maybe<Device_Firmware_Bool_Exp>;
  device_projects?: Maybe<Device_Project_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  model?: Maybe<Models_Bool_Exp>;
  model_id?: Maybe<Uuid_Comparison_Exp>;
  project_dataset?: Maybe<Project_Dataset_Bool_Exp>;
  recordings?: Maybe<Recordings_Bool_Exp>;
  serialno?: Maybe<String_Comparison_Exp>;
  sku?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "devices" */
export enum Devices_Constraint {
  /** unique or primary key constraint on columns "id" */
  DevicesPkey = 'devices_pkey',
  /** unique or primary key constraint on columns "serialno" */
  DevicesSerialnoUnique = 'devices_serialno_unique'
}

/** input type for inserting data into table "devices" */
export type Devices_Insert_Input = {
  configuration?: Maybe<Audio_Measurements_Configurations_Obj_Rel_Insert_Input>;
  configuration_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  device_firmwares?: Maybe<Device_Firmware_Arr_Rel_Insert_Input>;
  device_projects?: Maybe<Device_Project_Arr_Rel_Insert_Input>;
  model?: Maybe<Models_Obj_Rel_Insert_Input>;
  model_id?: Maybe<Scalars['uuid']>;
  recordings?: Maybe<Recordings_Arr_Rel_Insert_Input>;
  serialno?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Devices_Max_Fields = {
  __typename?: 'devices_max_fields';
  configuration_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  model_id?: Maybe<Scalars['uuid']>;
  serialno?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "devices" */
export type Devices_Max_Order_By = {
  configuration_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  model_id?: Maybe<Order_By>;
  serialno?: Maybe<Order_By>;
  sku?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Devices_Min_Fields = {
  __typename?: 'devices_min_fields';
  configuration_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  model_id?: Maybe<Scalars['uuid']>;
  serialno?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "devices" */
export type Devices_Min_Order_By = {
  configuration_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  model_id?: Maybe<Order_By>;
  serialno?: Maybe<Order_By>;
  sku?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "devices" */
export type Devices_Mutation_Response = {
  __typename?: 'devices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Devices>;
};

/** input type for inserting object relation for remote table "devices" */
export type Devices_Obj_Rel_Insert_Input = {
  data: Devices_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Devices_On_Conflict>;
};

/** on_conflict condition type for table "devices" */
export type Devices_On_Conflict = {
  constraint: Devices_Constraint;
  update_columns?: Array<Devices_Update_Column>;
  where?: Maybe<Devices_Bool_Exp>;
};

/** Ordering options when selecting data from "devices". */
export type Devices_Order_By = {
  configuration?: Maybe<Audio_Measurements_Configurations_Order_By>;
  configuration_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  device_firmwares_aggregate?: Maybe<Device_Firmware_Aggregate_Order_By>;
  device_projects_aggregate?: Maybe<Device_Project_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  model?: Maybe<Models_Order_By>;
  model_id?: Maybe<Order_By>;
  project_dataset_aggregate?: Maybe<Project_Dataset_Aggregate_Order_By>;
  recordings_aggregate?: Maybe<Recordings_Aggregate_Order_By>;
  serialno?: Maybe<Order_By>;
  sku?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: devices */
export type Devices_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "devices" */
export enum Devices_Select_Column {
  /** column name */
  ConfigurationId = 'configuration_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ModelId = 'model_id',
  /** column name */
  Serialno = 'serialno',
  /** column name */
  Sku = 'sku',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "devices" */
export type Devices_Set_Input = {
  configuration_id?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  model_id?: Maybe<Scalars['uuid']>;
  serialno?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "devices" */
export type Devices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Devices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Devices_Stream_Cursor_Value_Input = {
  configuration_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  model_id?: Maybe<Scalars['uuid']>;
  serialno?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "devices" */
export enum Devices_Update_Column {
  /** column name */
  ConfigurationId = 'configuration_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  ModelId = 'model_id',
  /** column name */
  Serialno = 'serialno',
  /** column name */
  Sku = 'sku',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Devices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Devices_Set_Input>;
  where: Devices_Bool_Exp;
};

/** columns and relationships of "dynamic_scaling" */
export type Dynamic_Scaling = {
  __typename?: 'dynamic_scaling';
  benchmark_scaling?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  max_value: Scalars['Int'];
  min_value: Scalars['Int'];
  /** An array relationship */
  projects: Array<Projects>;
  /** An aggregate relationship */
  projects_aggregate: Projects_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "dynamic_scaling" */
export type Dynamic_ScalingProjectsArgs = {
  distinct_on?: Maybe<Array<Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Projects_Order_By>>;
  where?: Maybe<Projects_Bool_Exp>;
};


/** columns and relationships of "dynamic_scaling" */
export type Dynamic_ScalingProjects_AggregateArgs = {
  distinct_on?: Maybe<Array<Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Projects_Order_By>>;
  where?: Maybe<Projects_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "dynamic_scaling". All fields are combined with a logical 'AND'. */
export type Dynamic_Scaling_Bool_Exp = {
  _and?: Maybe<Array<Dynamic_Scaling_Bool_Exp>>;
  _not?: Maybe<Dynamic_Scaling_Bool_Exp>;
  _or?: Maybe<Array<Dynamic_Scaling_Bool_Exp>>;
  benchmark_scaling?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  max_value?: Maybe<Int_Comparison_Exp>;
  min_value?: Maybe<Int_Comparison_Exp>;
  projects?: Maybe<Projects_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "dynamic_scaling" */
export enum Dynamic_Scaling_Constraint {
  /** unique or primary key constraint on columns "min_value", "benchmark_scaling", "max_value" */
  DynamicScalingMinValueMaxValueBenchmarkScalingKey = 'dynamic_scaling_min_value_max_value_benchmark_scaling_key',
  /** unique or primary key constraint on columns "id" */
  DynamicScalingPkey = 'dynamic_scaling_pkey'
}

/** input type for incrementing numeric columns in table "dynamic_scaling" */
export type Dynamic_Scaling_Inc_Input = {
  benchmark_scaling?: Maybe<Scalars['Int']>;
  max_value?: Maybe<Scalars['Int']>;
  min_value?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "dynamic_scaling" */
export type Dynamic_Scaling_Insert_Input = {
  benchmark_scaling?: Maybe<Scalars['Int']>;
  max_value?: Maybe<Scalars['Int']>;
  min_value?: Maybe<Scalars['Int']>;
  projects?: Maybe<Projects_Arr_Rel_Insert_Input>;
};

/** response of any mutation on the table "dynamic_scaling" */
export type Dynamic_Scaling_Mutation_Response = {
  __typename?: 'dynamic_scaling_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dynamic_Scaling>;
};

/** input type for inserting object relation for remote table "dynamic_scaling" */
export type Dynamic_Scaling_Obj_Rel_Insert_Input = {
  data: Dynamic_Scaling_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Dynamic_Scaling_On_Conflict>;
};

/** on_conflict condition type for table "dynamic_scaling" */
export type Dynamic_Scaling_On_Conflict = {
  constraint: Dynamic_Scaling_Constraint;
  update_columns?: Array<Dynamic_Scaling_Update_Column>;
  where?: Maybe<Dynamic_Scaling_Bool_Exp>;
};

/** Ordering options when selecting data from "dynamic_scaling". */
export type Dynamic_Scaling_Order_By = {
  benchmark_scaling?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  max_value?: Maybe<Order_By>;
  min_value?: Maybe<Order_By>;
  projects_aggregate?: Maybe<Projects_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: dynamic_scaling */
export type Dynamic_Scaling_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "dynamic_scaling" */
export enum Dynamic_Scaling_Select_Column {
  /** column name */
  BenchmarkScaling = 'benchmark_scaling',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  MaxValue = 'max_value',
  /** column name */
  MinValue = 'min_value',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "dynamic_scaling" */
export type Dynamic_Scaling_Set_Input = {
  benchmark_scaling?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  max_value?: Maybe<Scalars['Int']>;
  min_value?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "dynamic_scaling" */
export type Dynamic_Scaling_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dynamic_Scaling_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dynamic_Scaling_Stream_Cursor_Value_Input = {
  benchmark_scaling?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  max_value?: Maybe<Scalars['Int']>;
  min_value?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "dynamic_scaling" */
export enum Dynamic_Scaling_Update_Column {
  /** column name */
  BenchmarkScaling = 'benchmark_scaling',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  MaxValue = 'max_value',
  /** column name */
  MinValue = 'min_value',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Dynamic_Scaling_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Dynamic_Scaling_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Dynamic_Scaling_Set_Input>;
  where: Dynamic_Scaling_Bool_Exp;
};

/** columns and relationships of "engine_opmode" */
export type Engine_Opmode = {
  __typename?: 'engine_opmode';
  /** An object relationship */
  engine: Engines;
  engine_id: Scalars['uuid'];
  /** An array relationship */
  engine_opmode_recordings: Array<Engine_Opmode_Recording>;
  /** An aggregate relationship */
  engine_opmode_recordings_aggregate: Engine_Opmode_Recording_Aggregate;
  /** An object relationship */
  opmode: Opmodes;
  opmode_id: Scalars['uuid'];
};


/** columns and relationships of "engine_opmode" */
export type Engine_OpmodeEngine_Opmode_RecordingsArgs = {
  distinct_on?: Maybe<Array<Engine_Opmode_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Engine_Opmode_Recording_Order_By>>;
  where?: Maybe<Engine_Opmode_Recording_Bool_Exp>;
};


/** columns and relationships of "engine_opmode" */
export type Engine_OpmodeEngine_Opmode_Recordings_AggregateArgs = {
  distinct_on?: Maybe<Array<Engine_Opmode_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Engine_Opmode_Recording_Order_By>>;
  where?: Maybe<Engine_Opmode_Recording_Bool_Exp>;
};

/** aggregated selection of "engine_opmode" */
export type Engine_Opmode_Aggregate = {
  __typename?: 'engine_opmode_aggregate';
  aggregate?: Maybe<Engine_Opmode_Aggregate_Fields>;
  nodes: Array<Engine_Opmode>;
};

/** aggregate fields of "engine_opmode" */
export type Engine_Opmode_Aggregate_Fields = {
  __typename?: 'engine_opmode_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Engine_Opmode_Max_Fields>;
  min?: Maybe<Engine_Opmode_Min_Fields>;
};


/** aggregate fields of "engine_opmode" */
export type Engine_Opmode_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Engine_Opmode_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "engine_opmode" */
export type Engine_Opmode_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Engine_Opmode_Max_Order_By>;
  min?: Maybe<Engine_Opmode_Min_Order_By>;
};

/** input type for inserting array relation for remote table "engine_opmode" */
export type Engine_Opmode_Arr_Rel_Insert_Input = {
  data: Array<Engine_Opmode_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Engine_Opmode_On_Conflict>;
};

/** Boolean expression to filter rows from the table "engine_opmode". All fields are combined with a logical 'AND'. */
export type Engine_Opmode_Bool_Exp = {
  _and?: Maybe<Array<Engine_Opmode_Bool_Exp>>;
  _not?: Maybe<Engine_Opmode_Bool_Exp>;
  _or?: Maybe<Array<Engine_Opmode_Bool_Exp>>;
  engine?: Maybe<Engines_Bool_Exp>;
  engine_id?: Maybe<Uuid_Comparison_Exp>;
  engine_opmode_recordings?: Maybe<Engine_Opmode_Recording_Bool_Exp>;
  opmode?: Maybe<Opmodes_Bool_Exp>;
  opmode_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "engine_opmode" */
export enum Engine_Opmode_Constraint {
  /** unique or primary key constraint on columns "engine_id", "opmode_id" */
  EngineOpmodePkey = 'engine_opmode_pkey'
}

/** input type for inserting data into table "engine_opmode" */
export type Engine_Opmode_Insert_Input = {
  engine?: Maybe<Engines_Obj_Rel_Insert_Input>;
  engine_id?: Maybe<Scalars['uuid']>;
  engine_opmode_recordings?: Maybe<Engine_Opmode_Recording_Arr_Rel_Insert_Input>;
  opmode?: Maybe<Opmodes_Obj_Rel_Insert_Input>;
  opmode_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Engine_Opmode_Max_Fields = {
  __typename?: 'engine_opmode_max_fields';
  engine_id?: Maybe<Scalars['uuid']>;
  opmode_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "engine_opmode" */
export type Engine_Opmode_Max_Order_By = {
  engine_id?: Maybe<Order_By>;
  opmode_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Engine_Opmode_Min_Fields = {
  __typename?: 'engine_opmode_min_fields';
  engine_id?: Maybe<Scalars['uuid']>;
  opmode_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "engine_opmode" */
export type Engine_Opmode_Min_Order_By = {
  engine_id?: Maybe<Order_By>;
  opmode_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "engine_opmode" */
export type Engine_Opmode_Mutation_Response = {
  __typename?: 'engine_opmode_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Engine_Opmode>;
};

/** input type for inserting object relation for remote table "engine_opmode" */
export type Engine_Opmode_Obj_Rel_Insert_Input = {
  data: Engine_Opmode_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Engine_Opmode_On_Conflict>;
};

/** on_conflict condition type for table "engine_opmode" */
export type Engine_Opmode_On_Conflict = {
  constraint: Engine_Opmode_Constraint;
  update_columns?: Array<Engine_Opmode_Update_Column>;
  where?: Maybe<Engine_Opmode_Bool_Exp>;
};

/** Ordering options when selecting data from "engine_opmode". */
export type Engine_Opmode_Order_By = {
  engine?: Maybe<Engines_Order_By>;
  engine_id?: Maybe<Order_By>;
  engine_opmode_recordings_aggregate?: Maybe<Engine_Opmode_Recording_Aggregate_Order_By>;
  opmode?: Maybe<Opmodes_Order_By>;
  opmode_id?: Maybe<Order_By>;
};

/** primary key columns input for table: engine_opmode */
export type Engine_Opmode_Pk_Columns_Input = {
  engine_id: Scalars['uuid'];
  opmode_id: Scalars['uuid'];
};

/** columns and relationships of "engine_opmode_recording" */
export type Engine_Opmode_Recording = {
  __typename?: 'engine_opmode_recording';
  /** An object relationship */
  engine: Engines;
  engine_id: Scalars['uuid'];
  /** An object relationship */
  engine_opmode?: Maybe<Engine_Opmode>;
  /** An object relationship */
  opmode: Opmodes;
  opmode_id: Scalars['uuid'];
  /** An object relationship */
  recording: Recordings;
  recording_id: Scalars['uuid'];
};

/** aggregated selection of "engine_opmode_recording" */
export type Engine_Opmode_Recording_Aggregate = {
  __typename?: 'engine_opmode_recording_aggregate';
  aggregate?: Maybe<Engine_Opmode_Recording_Aggregate_Fields>;
  nodes: Array<Engine_Opmode_Recording>;
};

/** aggregate fields of "engine_opmode_recording" */
export type Engine_Opmode_Recording_Aggregate_Fields = {
  __typename?: 'engine_opmode_recording_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Engine_Opmode_Recording_Max_Fields>;
  min?: Maybe<Engine_Opmode_Recording_Min_Fields>;
};


/** aggregate fields of "engine_opmode_recording" */
export type Engine_Opmode_Recording_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Engine_Opmode_Recording_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "engine_opmode_recording" */
export type Engine_Opmode_Recording_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Engine_Opmode_Recording_Max_Order_By>;
  min?: Maybe<Engine_Opmode_Recording_Min_Order_By>;
};

/** input type for inserting array relation for remote table "engine_opmode_recording" */
export type Engine_Opmode_Recording_Arr_Rel_Insert_Input = {
  data: Array<Engine_Opmode_Recording_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Engine_Opmode_Recording_On_Conflict>;
};

/** Boolean expression to filter rows from the table "engine_opmode_recording". All fields are combined with a logical 'AND'. */
export type Engine_Opmode_Recording_Bool_Exp = {
  _and?: Maybe<Array<Engine_Opmode_Recording_Bool_Exp>>;
  _not?: Maybe<Engine_Opmode_Recording_Bool_Exp>;
  _or?: Maybe<Array<Engine_Opmode_Recording_Bool_Exp>>;
  engine?: Maybe<Engines_Bool_Exp>;
  engine_id?: Maybe<Uuid_Comparison_Exp>;
  engine_opmode?: Maybe<Engine_Opmode_Bool_Exp>;
  opmode?: Maybe<Opmodes_Bool_Exp>;
  opmode_id?: Maybe<Uuid_Comparison_Exp>;
  recording?: Maybe<Recordings_Bool_Exp>;
  recording_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "engine_opmode_recording" */
export enum Engine_Opmode_Recording_Constraint {
  /** unique or primary key constraint on columns "engine_id", "recording_id", "opmode_id" */
  EngineOpmodeRecordingPkey = 'engine_opmode_recording_pkey',
  /** unique or primary key constraint on columns "recording_id" */
  EngineOpmodeRecordingRecordingIdKey = 'engine_opmode_recording_recording_id_key'
}

/** input type for inserting data into table "engine_opmode_recording" */
export type Engine_Opmode_Recording_Insert_Input = {
  engine?: Maybe<Engines_Obj_Rel_Insert_Input>;
  engine_id?: Maybe<Scalars['uuid']>;
  engine_opmode?: Maybe<Engine_Opmode_Obj_Rel_Insert_Input>;
  opmode?: Maybe<Opmodes_Obj_Rel_Insert_Input>;
  opmode_id?: Maybe<Scalars['uuid']>;
  recording?: Maybe<Recordings_Obj_Rel_Insert_Input>;
  recording_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Engine_Opmode_Recording_Max_Fields = {
  __typename?: 'engine_opmode_recording_max_fields';
  engine_id?: Maybe<Scalars['uuid']>;
  opmode_id?: Maybe<Scalars['uuid']>;
  recording_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "engine_opmode_recording" */
export type Engine_Opmode_Recording_Max_Order_By = {
  engine_id?: Maybe<Order_By>;
  opmode_id?: Maybe<Order_By>;
  recording_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Engine_Opmode_Recording_Min_Fields = {
  __typename?: 'engine_opmode_recording_min_fields';
  engine_id?: Maybe<Scalars['uuid']>;
  opmode_id?: Maybe<Scalars['uuid']>;
  recording_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "engine_opmode_recording" */
export type Engine_Opmode_Recording_Min_Order_By = {
  engine_id?: Maybe<Order_By>;
  opmode_id?: Maybe<Order_By>;
  recording_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "engine_opmode_recording" */
export type Engine_Opmode_Recording_Mutation_Response = {
  __typename?: 'engine_opmode_recording_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Engine_Opmode_Recording>;
};

/** on_conflict condition type for table "engine_opmode_recording" */
export type Engine_Opmode_Recording_On_Conflict = {
  constraint: Engine_Opmode_Recording_Constraint;
  update_columns?: Array<Engine_Opmode_Recording_Update_Column>;
  where?: Maybe<Engine_Opmode_Recording_Bool_Exp>;
};

/** Ordering options when selecting data from "engine_opmode_recording". */
export type Engine_Opmode_Recording_Order_By = {
  engine?: Maybe<Engines_Order_By>;
  engine_id?: Maybe<Order_By>;
  engine_opmode?: Maybe<Engine_Opmode_Order_By>;
  opmode?: Maybe<Opmodes_Order_By>;
  opmode_id?: Maybe<Order_By>;
  recording?: Maybe<Recordings_Order_By>;
  recording_id?: Maybe<Order_By>;
};

/** primary key columns input for table: engine_opmode_recording */
export type Engine_Opmode_Recording_Pk_Columns_Input = {
  engine_id: Scalars['uuid'];
  opmode_id: Scalars['uuid'];
  recording_id: Scalars['uuid'];
};

/** select columns of table "engine_opmode_recording" */
export enum Engine_Opmode_Recording_Select_Column {
  /** column name */
  EngineId = 'engine_id',
  /** column name */
  OpmodeId = 'opmode_id',
  /** column name */
  RecordingId = 'recording_id'
}

/** input type for updating data in table "engine_opmode_recording" */
export type Engine_Opmode_Recording_Set_Input = {
  engine_id?: Maybe<Scalars['uuid']>;
  opmode_id?: Maybe<Scalars['uuid']>;
  recording_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "engine_opmode_recording" */
export type Engine_Opmode_Recording_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Engine_Opmode_Recording_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Engine_Opmode_Recording_Stream_Cursor_Value_Input = {
  engine_id?: Maybe<Scalars['uuid']>;
  opmode_id?: Maybe<Scalars['uuid']>;
  recording_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "engine_opmode_recording" */
export enum Engine_Opmode_Recording_Update_Column {
  /** column name */
  EngineId = 'engine_id',
  /** column name */
  OpmodeId = 'opmode_id',
  /** column name */
  RecordingId = 'recording_id'
}

export type Engine_Opmode_Recording_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Engine_Opmode_Recording_Set_Input>;
  where: Engine_Opmode_Recording_Bool_Exp;
};

/** select columns of table "engine_opmode" */
export enum Engine_Opmode_Select_Column {
  /** column name */
  EngineId = 'engine_id',
  /** column name */
  OpmodeId = 'opmode_id'
}

/** input type for updating data in table "engine_opmode" */
export type Engine_Opmode_Set_Input = {
  engine_id?: Maybe<Scalars['uuid']>;
  opmode_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "engine_opmode" */
export type Engine_Opmode_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Engine_Opmode_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Engine_Opmode_Stream_Cursor_Value_Input = {
  engine_id?: Maybe<Scalars['uuid']>;
  opmode_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "engine_opmode" */
export enum Engine_Opmode_Update_Column {
  /** column name */
  EngineId = 'engine_id',
  /** column name */
  OpmodeId = 'opmode_id'
}

export type Engine_Opmode_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Engine_Opmode_Set_Input>;
  where: Engine_Opmode_Bool_Exp;
};

/** Enumerates the different "types" of engines, so as to filter certain engines into different pipelines/workflows */
export type Engine_Types = {
  __typename?: 'engine_types';
  description: Scalars['String'];
  id: Scalars['String'];
};

/** aggregated selection of "engine_types" */
export type Engine_Types_Aggregate = {
  __typename?: 'engine_types_aggregate';
  aggregate?: Maybe<Engine_Types_Aggregate_Fields>;
  nodes: Array<Engine_Types>;
};

/** aggregate fields of "engine_types" */
export type Engine_Types_Aggregate_Fields = {
  __typename?: 'engine_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Engine_Types_Max_Fields>;
  min?: Maybe<Engine_Types_Min_Fields>;
};


/** aggregate fields of "engine_types" */
export type Engine_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Engine_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "engine_types". All fields are combined with a logical 'AND'. */
export type Engine_Types_Bool_Exp = {
  _and?: Maybe<Array<Engine_Types_Bool_Exp>>;
  _not?: Maybe<Engine_Types_Bool_Exp>;
  _or?: Maybe<Array<Engine_Types_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
};

export enum Engine_Types_Enum {
  /** used to mark candidates for release to customer */
  CustomerRelease = 'customer_release',
  /** used for internal debugging */
  Engineering = 'engineering',
  /** used to mark predictions (virtual) */
  ModeEvaluation = 'mode_evaluation'
}

/** Boolean expression to compare columns of type "engine_types_enum". All fields are combined with logical 'AND'. */
export type Engine_Types_Enum_Comparison_Exp = {
  _eq?: Maybe<Engine_Types_Enum>;
  _in?: Maybe<Array<Engine_Types_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Engine_Types_Enum>;
  _nin?: Maybe<Array<Engine_Types_Enum>>;
};

/** aggregate max on columns */
export type Engine_Types_Max_Fields = {
  __typename?: 'engine_types_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Engine_Types_Min_Fields = {
  __typename?: 'engine_types_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "engine_types" */
export type Engine_Types_Mutation_Response = {
  __typename?: 'engine_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Engine_Types>;
};

/** Ordering options when selecting data from "engine_types". */
export type Engine_Types_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** primary key columns input for table: engine_types */
export type Engine_Types_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "engine_types" */
export enum Engine_Types_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "engine_types" */
export type Engine_Types_Set_Input = {
  description?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "engine_types" */
export type Engine_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Engine_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Engine_Types_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type Engine_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Engine_Types_Set_Input>;
  where: Engine_Types_Bool_Exp;
};

/** columns and relationships of "engines" */
export type Engines = {
  __typename?: 'engines';
  /** If built in azure build pipeline, the build ID (build run id) where the engine is built */
  azure_build_ref?: Maybe<Scalars['Int']>;
  /** If release in azure release pipeline, the release ID where the engine is released */
  azure_release_ref?: Maybe<Scalars['Int']>;
  /** The branch name of Elliptic.Engine repository at which the engine is built */
  branch: Scalars['String'];
  /** The build definition id of the azure build in which this engine was built */
  build_definition_id_azure?: Maybe<Scalars['Int']>;
  /** A string representation of the number given to this engine when built; formatted "YYYYMMDD.N". */
  build_number?: Maybe<Scalars['String']>;
  /** DO NOT USE */
  build_ref: Scalars['String'];
  /** The version number of the engine, e.g. 1.5.11011 */
  build_version: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** The commit ID of Elliptic.Engine repository at which the engine is built */
  elliptic_engine_commit_hash?: Maybe<Scalars['String']>;
  /** An array relationship */
  engine_opmode_recordings: Array<Engine_Opmode_Recording>;
  /** An aggregate relationship */
  engine_opmode_recordings_aggregate: Engine_Opmode_Recording_Aggregate;
  /** An array relationship */
  engine_opmodes: Array<Engine_Opmode>;
  /** An aggregate relationship */
  engine_opmodes_aggregate: Engine_Opmode_Aggregate;
  /** An object relationship */
  engine_type?: Maybe<Engine_Types>;
  engine_type_id?: Maybe<Engine_Types_Enum>;
  /** Indicates what graph version the engine is compatible with. Only topology blobs maching this version will work. */
  graph_version?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  /** Human readable name of the engine */
  nickname?: Maybe<Scalars['String']>;
  /** An array relationship */
  observances: Array<Integration_Test_Observances>;
  /** An aggregate relationship */
  observances_aggregate: Integration_Test_Observances_Aggregate;
  /** DO NOT USE */
  path: Scalars['String'];
  /** An array relationship */
  predictions: Array<Recording_Data_Predictions>;
  /** An aggregate relationship */
  predictions_aggregate: Recording_Data_Predictions_Aggregate;
  /** An array relationship */
  ratio_metric_contributions: Array<Performance_Ratio_Metric_Contributions>;
  /** An aggregate relationship */
  ratio_metric_contributions_aggregate: Performance_Ratio_Metric_Contributions_Aggregate;
  /** The definition id of the azure release in which this engine was released */
  release_definition_id_azure?: Maybe<Scalars['Int']>;
  release_number?: Maybe<Scalars['Int']>;
  /** An array relationship */
  scores: Array<Simulations_Scores>;
  /** Type of the engine. <Engineering> internal debugging, <QA> performance evaluation, <MP Candidate> candidate for mass production */
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "engines" */
export type EnginesEngine_Opmode_RecordingsArgs = {
  distinct_on?: Maybe<Array<Engine_Opmode_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Engine_Opmode_Recording_Order_By>>;
  where?: Maybe<Engine_Opmode_Recording_Bool_Exp>;
};


/** columns and relationships of "engines" */
export type EnginesEngine_Opmode_Recordings_AggregateArgs = {
  distinct_on?: Maybe<Array<Engine_Opmode_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Engine_Opmode_Recording_Order_By>>;
  where?: Maybe<Engine_Opmode_Recording_Bool_Exp>;
};


/** columns and relationships of "engines" */
export type EnginesEngine_OpmodesArgs = {
  distinct_on?: Maybe<Array<Engine_Opmode_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Engine_Opmode_Order_By>>;
  where?: Maybe<Engine_Opmode_Bool_Exp>;
};


/** columns and relationships of "engines" */
export type EnginesEngine_Opmodes_AggregateArgs = {
  distinct_on?: Maybe<Array<Engine_Opmode_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Engine_Opmode_Order_By>>;
  where?: Maybe<Engine_Opmode_Bool_Exp>;
};


/** columns and relationships of "engines" */
export type EnginesObservancesArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Observances_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Observances_Order_By>>;
  where?: Maybe<Integration_Test_Observances_Bool_Exp>;
};


/** columns and relationships of "engines" */
export type EnginesObservances_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Observances_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Observances_Order_By>>;
  where?: Maybe<Integration_Test_Observances_Bool_Exp>;
};


/** columns and relationships of "engines" */
export type EnginesPredictionsArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Predictions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Predictions_Order_By>>;
  where?: Maybe<Recording_Data_Predictions_Bool_Exp>;
};


/** columns and relationships of "engines" */
export type EnginesPredictions_AggregateArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Predictions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Predictions_Order_By>>;
  where?: Maybe<Recording_Data_Predictions_Bool_Exp>;
};


/** columns and relationships of "engines" */
export type EnginesRatio_Metric_ContributionsArgs = {
  distinct_on?: Maybe<Array<Performance_Ratio_Metric_Contributions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Ratio_Metric_Contributions_Order_By>>;
  where?: Maybe<Performance_Ratio_Metric_Contributions_Bool_Exp>;
};


/** columns and relationships of "engines" */
export type EnginesRatio_Metric_Contributions_AggregateArgs = {
  distinct_on?: Maybe<Array<Performance_Ratio_Metric_Contributions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Ratio_Metric_Contributions_Order_By>>;
  where?: Maybe<Performance_Ratio_Metric_Contributions_Bool_Exp>;
};


/** columns and relationships of "engines" */
export type EnginesScoresArgs = {
  distinct_on?: Maybe<Array<Simulations_Scores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Simulations_Scores_Order_By>>;
  where?: Maybe<Simulations_Scores_Bool_Exp>;
};

/** aggregated selection of "engines" */
export type Engines_Aggregate = {
  __typename?: 'engines_aggregate';
  aggregate?: Maybe<Engines_Aggregate_Fields>;
  nodes: Array<Engines>;
};

/** aggregate fields of "engines" */
export type Engines_Aggregate_Fields = {
  __typename?: 'engines_aggregate_fields';
  avg?: Maybe<Engines_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Engines_Max_Fields>;
  min?: Maybe<Engines_Min_Fields>;
  stddev?: Maybe<Engines_Stddev_Fields>;
  stddev_pop?: Maybe<Engines_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Engines_Stddev_Samp_Fields>;
  sum?: Maybe<Engines_Sum_Fields>;
  var_pop?: Maybe<Engines_Var_Pop_Fields>;
  var_samp?: Maybe<Engines_Var_Samp_Fields>;
  variance?: Maybe<Engines_Variance_Fields>;
};


/** aggregate fields of "engines" */
export type Engines_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Engines_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Engines_Avg_Fields = {
  __typename?: 'engines_avg_fields';
  /** If built in azure build pipeline, the build ID (build run id) where the engine is built */
  azure_build_ref?: Maybe<Scalars['Float']>;
  /** If release in azure release pipeline, the release ID where the engine is released */
  azure_release_ref?: Maybe<Scalars['Float']>;
  /** The build definition id of the azure build in which this engine was built */
  build_definition_id_azure?: Maybe<Scalars['Float']>;
  /** Indicates what graph version the engine is compatible with. Only topology blobs maching this version will work. */
  graph_version?: Maybe<Scalars['Float']>;
  /** The definition id of the azure release in which this engine was released */
  release_definition_id_azure?: Maybe<Scalars['Float']>;
  release_number?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "engines". All fields are combined with a logical 'AND'. */
export type Engines_Bool_Exp = {
  _and?: Maybe<Array<Engines_Bool_Exp>>;
  _not?: Maybe<Engines_Bool_Exp>;
  _or?: Maybe<Array<Engines_Bool_Exp>>;
  azure_build_ref?: Maybe<Int_Comparison_Exp>;
  azure_release_ref?: Maybe<Int_Comparison_Exp>;
  branch?: Maybe<String_Comparison_Exp>;
  build_definition_id_azure?: Maybe<Int_Comparison_Exp>;
  build_number?: Maybe<String_Comparison_Exp>;
  build_ref?: Maybe<String_Comparison_Exp>;
  build_version?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  elliptic_engine_commit_hash?: Maybe<String_Comparison_Exp>;
  engine_opmode_recordings?: Maybe<Engine_Opmode_Recording_Bool_Exp>;
  engine_opmodes?: Maybe<Engine_Opmode_Bool_Exp>;
  engine_type?: Maybe<Engine_Types_Bool_Exp>;
  engine_type_id?: Maybe<Engine_Types_Enum_Comparison_Exp>;
  graph_version?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  nickname?: Maybe<String_Comparison_Exp>;
  observances?: Maybe<Integration_Test_Observances_Bool_Exp>;
  path?: Maybe<String_Comparison_Exp>;
  predictions?: Maybe<Recording_Data_Predictions_Bool_Exp>;
  ratio_metric_contributions?: Maybe<Performance_Ratio_Metric_Contributions_Bool_Exp>;
  release_definition_id_azure?: Maybe<Int_Comparison_Exp>;
  release_number?: Maybe<Int_Comparison_Exp>;
  scores?: Maybe<Simulations_Scores_Bool_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "engines" */
export enum Engines_Constraint {
  /** unique or primary key constraint on columns "build_version", "azure_release_ref" */
  EnginesAzureReleaseRefBuildVersionKey = 'engines_azure_release_ref_build_version_key',
  /** unique or primary key constraint on columns "id" */
  EnginesPkey = 'engines_pkey',
  /** unique or primary key constraint on columns "build_version" */
  UniqueBuildVersion = 'unique_build_version'
}

/** input type for incrementing numeric columns in table "engines" */
export type Engines_Inc_Input = {
  /** If built in azure build pipeline, the build ID (build run id) where the engine is built */
  azure_build_ref?: Maybe<Scalars['Int']>;
  /** If release in azure release pipeline, the release ID where the engine is released */
  azure_release_ref?: Maybe<Scalars['Int']>;
  /** The build definition id of the azure build in which this engine was built */
  build_definition_id_azure?: Maybe<Scalars['Int']>;
  /** Indicates what graph version the engine is compatible with. Only topology blobs maching this version will work. */
  graph_version?: Maybe<Scalars['Int']>;
  /** The definition id of the azure release in which this engine was released */
  release_definition_id_azure?: Maybe<Scalars['Int']>;
  release_number?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "engines" */
export type Engines_Insert_Input = {
  /** If built in azure build pipeline, the build ID (build run id) where the engine is built */
  azure_build_ref?: Maybe<Scalars['Int']>;
  /** If release in azure release pipeline, the release ID where the engine is released */
  azure_release_ref?: Maybe<Scalars['Int']>;
  /** The branch name of Elliptic.Engine repository at which the engine is built */
  branch?: Maybe<Scalars['String']>;
  /** The build definition id of the azure build in which this engine was built */
  build_definition_id_azure?: Maybe<Scalars['Int']>;
  /** A string representation of the number given to this engine when built; formatted "YYYYMMDD.N". */
  build_number?: Maybe<Scalars['String']>;
  /** DO NOT USE */
  build_ref?: Maybe<Scalars['String']>;
  /** The version number of the engine, e.g. 1.5.11011 */
  build_version?: Maybe<Scalars['String']>;
  /** The commit ID of Elliptic.Engine repository at which the engine is built */
  elliptic_engine_commit_hash?: Maybe<Scalars['String']>;
  engine_opmode_recordings?: Maybe<Engine_Opmode_Recording_Arr_Rel_Insert_Input>;
  engine_opmodes?: Maybe<Engine_Opmode_Arr_Rel_Insert_Input>;
  engine_type_id?: Maybe<Engine_Types_Enum>;
  /** Indicates what graph version the engine is compatible with. Only topology blobs maching this version will work. */
  graph_version?: Maybe<Scalars['Int']>;
  /** Human readable name of the engine */
  nickname?: Maybe<Scalars['String']>;
  observances?: Maybe<Integration_Test_Observances_Arr_Rel_Insert_Input>;
  /** DO NOT USE */
  path?: Maybe<Scalars['String']>;
  predictions?: Maybe<Recording_Data_Predictions_Arr_Rel_Insert_Input>;
  ratio_metric_contributions?: Maybe<Performance_Ratio_Metric_Contributions_Arr_Rel_Insert_Input>;
  /** The definition id of the azure release in which this engine was released */
  release_definition_id_azure?: Maybe<Scalars['Int']>;
  release_number?: Maybe<Scalars['Int']>;
  scores?: Maybe<Simulations_Scores_Arr_Rel_Insert_Input>;
  /** Type of the engine. <Engineering> internal debugging, <QA> performance evaluation, <MP Candidate> candidate for mass production */
  type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Engines_Max_Fields = {
  __typename?: 'engines_max_fields';
  /** If built in azure build pipeline, the build ID (build run id) where the engine is built */
  azure_build_ref?: Maybe<Scalars['Int']>;
  /** If release in azure release pipeline, the release ID where the engine is released */
  azure_release_ref?: Maybe<Scalars['Int']>;
  /** The branch name of Elliptic.Engine repository at which the engine is built */
  branch?: Maybe<Scalars['String']>;
  /** The build definition id of the azure build in which this engine was built */
  build_definition_id_azure?: Maybe<Scalars['Int']>;
  /** A string representation of the number given to this engine when built; formatted "YYYYMMDD.N". */
  build_number?: Maybe<Scalars['String']>;
  /** DO NOT USE */
  build_ref?: Maybe<Scalars['String']>;
  /** The version number of the engine, e.g. 1.5.11011 */
  build_version?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** The commit ID of Elliptic.Engine repository at which the engine is built */
  elliptic_engine_commit_hash?: Maybe<Scalars['String']>;
  /** Indicates what graph version the engine is compatible with. Only topology blobs maching this version will work. */
  graph_version?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  /** Human readable name of the engine */
  nickname?: Maybe<Scalars['String']>;
  /** DO NOT USE */
  path?: Maybe<Scalars['String']>;
  /** The definition id of the azure release in which this engine was released */
  release_definition_id_azure?: Maybe<Scalars['Int']>;
  release_number?: Maybe<Scalars['Int']>;
  /** Type of the engine. <Engineering> internal debugging, <QA> performance evaluation, <MP Candidate> candidate for mass production */
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Engines_Min_Fields = {
  __typename?: 'engines_min_fields';
  /** If built in azure build pipeline, the build ID (build run id) where the engine is built */
  azure_build_ref?: Maybe<Scalars['Int']>;
  /** If release in azure release pipeline, the release ID where the engine is released */
  azure_release_ref?: Maybe<Scalars['Int']>;
  /** The branch name of Elliptic.Engine repository at which the engine is built */
  branch?: Maybe<Scalars['String']>;
  /** The build definition id of the azure build in which this engine was built */
  build_definition_id_azure?: Maybe<Scalars['Int']>;
  /** A string representation of the number given to this engine when built; formatted "YYYYMMDD.N". */
  build_number?: Maybe<Scalars['String']>;
  /** DO NOT USE */
  build_ref?: Maybe<Scalars['String']>;
  /** The version number of the engine, e.g. 1.5.11011 */
  build_version?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** The commit ID of Elliptic.Engine repository at which the engine is built */
  elliptic_engine_commit_hash?: Maybe<Scalars['String']>;
  /** Indicates what graph version the engine is compatible with. Only topology blobs maching this version will work. */
  graph_version?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  /** Human readable name of the engine */
  nickname?: Maybe<Scalars['String']>;
  /** DO NOT USE */
  path?: Maybe<Scalars['String']>;
  /** The definition id of the azure release in which this engine was released */
  release_definition_id_azure?: Maybe<Scalars['Int']>;
  release_number?: Maybe<Scalars['Int']>;
  /** Type of the engine. <Engineering> internal debugging, <QA> performance evaluation, <MP Candidate> candidate for mass production */
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "engines" */
export type Engines_Mutation_Response = {
  __typename?: 'engines_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Engines>;
};

/** input type for inserting object relation for remote table "engines" */
export type Engines_Obj_Rel_Insert_Input = {
  data: Engines_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Engines_On_Conflict>;
};

/** on_conflict condition type for table "engines" */
export type Engines_On_Conflict = {
  constraint: Engines_Constraint;
  update_columns?: Array<Engines_Update_Column>;
  where?: Maybe<Engines_Bool_Exp>;
};

/** Ordering options when selecting data from "engines". */
export type Engines_Order_By = {
  azure_build_ref?: Maybe<Order_By>;
  azure_release_ref?: Maybe<Order_By>;
  branch?: Maybe<Order_By>;
  build_definition_id_azure?: Maybe<Order_By>;
  build_number?: Maybe<Order_By>;
  build_ref?: Maybe<Order_By>;
  build_version?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  elliptic_engine_commit_hash?: Maybe<Order_By>;
  engine_opmode_recordings_aggregate?: Maybe<Engine_Opmode_Recording_Aggregate_Order_By>;
  engine_opmodes_aggregate?: Maybe<Engine_Opmode_Aggregate_Order_By>;
  engine_type?: Maybe<Engine_Types_Order_By>;
  engine_type_id?: Maybe<Order_By>;
  graph_version?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  nickname?: Maybe<Order_By>;
  observances_aggregate?: Maybe<Integration_Test_Observances_Aggregate_Order_By>;
  path?: Maybe<Order_By>;
  predictions_aggregate?: Maybe<Recording_Data_Predictions_Aggregate_Order_By>;
  ratio_metric_contributions_aggregate?: Maybe<Performance_Ratio_Metric_Contributions_Aggregate_Order_By>;
  release_definition_id_azure?: Maybe<Order_By>;
  release_number?: Maybe<Order_By>;
  scores_aggregate?: Maybe<Simulations_Scores_Aggregate_Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: engines */
export type Engines_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "engines" */
export enum Engines_Select_Column {
  /** column name */
  AzureBuildRef = 'azure_build_ref',
  /** column name */
  AzureReleaseRef = 'azure_release_ref',
  /** column name */
  Branch = 'branch',
  /** column name */
  BuildDefinitionIdAzure = 'build_definition_id_azure',
  /** column name */
  BuildNumber = 'build_number',
  /** column name */
  BuildRef = 'build_ref',
  /** column name */
  BuildVersion = 'build_version',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EllipticEngineCommitHash = 'elliptic_engine_commit_hash',
  /** column name */
  EngineTypeId = 'engine_type_id',
  /** column name */
  GraphVersion = 'graph_version',
  /** column name */
  Id = 'id',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  Path = 'path',
  /** column name */
  ReleaseDefinitionIdAzure = 'release_definition_id_azure',
  /** column name */
  ReleaseNumber = 'release_number',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "engines" */
export type Engines_Set_Input = {
  /** If built in azure build pipeline, the build ID (build run id) where the engine is built */
  azure_build_ref?: Maybe<Scalars['Int']>;
  /** If release in azure release pipeline, the release ID where the engine is released */
  azure_release_ref?: Maybe<Scalars['Int']>;
  /** The branch name of Elliptic.Engine repository at which the engine is built */
  branch?: Maybe<Scalars['String']>;
  /** The build definition id of the azure build in which this engine was built */
  build_definition_id_azure?: Maybe<Scalars['Int']>;
  /** A string representation of the number given to this engine when built; formatted "YYYYMMDD.N". */
  build_number?: Maybe<Scalars['String']>;
  /** DO NOT USE */
  build_ref?: Maybe<Scalars['String']>;
  /** The version number of the engine, e.g. 1.5.11011 */
  build_version?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** The commit ID of Elliptic.Engine repository at which the engine is built */
  elliptic_engine_commit_hash?: Maybe<Scalars['String']>;
  engine_type_id?: Maybe<Engine_Types_Enum>;
  /** Indicates what graph version the engine is compatible with. Only topology blobs maching this version will work. */
  graph_version?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  /** Human readable name of the engine */
  nickname?: Maybe<Scalars['String']>;
  /** DO NOT USE */
  path?: Maybe<Scalars['String']>;
  /** The definition id of the azure release in which this engine was released */
  release_definition_id_azure?: Maybe<Scalars['Int']>;
  release_number?: Maybe<Scalars['Int']>;
  /** Type of the engine. <Engineering> internal debugging, <QA> performance evaluation, <MP Candidate> candidate for mass production */
  type?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Engines_Stddev_Fields = {
  __typename?: 'engines_stddev_fields';
  /** If built in azure build pipeline, the build ID (build run id) where the engine is built */
  azure_build_ref?: Maybe<Scalars['Float']>;
  /** If release in azure release pipeline, the release ID where the engine is released */
  azure_release_ref?: Maybe<Scalars['Float']>;
  /** The build definition id of the azure build in which this engine was built */
  build_definition_id_azure?: Maybe<Scalars['Float']>;
  /** Indicates what graph version the engine is compatible with. Only topology blobs maching this version will work. */
  graph_version?: Maybe<Scalars['Float']>;
  /** The definition id of the azure release in which this engine was released */
  release_definition_id_azure?: Maybe<Scalars['Float']>;
  release_number?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Engines_Stddev_Pop_Fields = {
  __typename?: 'engines_stddev_pop_fields';
  /** If built in azure build pipeline, the build ID (build run id) where the engine is built */
  azure_build_ref?: Maybe<Scalars['Float']>;
  /** If release in azure release pipeline, the release ID where the engine is released */
  azure_release_ref?: Maybe<Scalars['Float']>;
  /** The build definition id of the azure build in which this engine was built */
  build_definition_id_azure?: Maybe<Scalars['Float']>;
  /** Indicates what graph version the engine is compatible with. Only topology blobs maching this version will work. */
  graph_version?: Maybe<Scalars['Float']>;
  /** The definition id of the azure release in which this engine was released */
  release_definition_id_azure?: Maybe<Scalars['Float']>;
  release_number?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Engines_Stddev_Samp_Fields = {
  __typename?: 'engines_stddev_samp_fields';
  /** If built in azure build pipeline, the build ID (build run id) where the engine is built */
  azure_build_ref?: Maybe<Scalars['Float']>;
  /** If release in azure release pipeline, the release ID where the engine is released */
  azure_release_ref?: Maybe<Scalars['Float']>;
  /** The build definition id of the azure build in which this engine was built */
  build_definition_id_azure?: Maybe<Scalars['Float']>;
  /** Indicates what graph version the engine is compatible with. Only topology blobs maching this version will work. */
  graph_version?: Maybe<Scalars['Float']>;
  /** The definition id of the azure release in which this engine was released */
  release_definition_id_azure?: Maybe<Scalars['Float']>;
  release_number?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "engines" */
export type Engines_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Engines_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Engines_Stream_Cursor_Value_Input = {
  /** If built in azure build pipeline, the build ID (build run id) where the engine is built */
  azure_build_ref?: Maybe<Scalars['Int']>;
  /** If release in azure release pipeline, the release ID where the engine is released */
  azure_release_ref?: Maybe<Scalars['Int']>;
  /** The branch name of Elliptic.Engine repository at which the engine is built */
  branch?: Maybe<Scalars['String']>;
  /** The build definition id of the azure build in which this engine was built */
  build_definition_id_azure?: Maybe<Scalars['Int']>;
  /** A string representation of the number given to this engine when built; formatted "YYYYMMDD.N". */
  build_number?: Maybe<Scalars['String']>;
  /** DO NOT USE */
  build_ref?: Maybe<Scalars['String']>;
  /** The version number of the engine, e.g. 1.5.11011 */
  build_version?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** The commit ID of Elliptic.Engine repository at which the engine is built */
  elliptic_engine_commit_hash?: Maybe<Scalars['String']>;
  engine_type_id?: Maybe<Engine_Types_Enum>;
  /** Indicates what graph version the engine is compatible with. Only topology blobs maching this version will work. */
  graph_version?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  /** Human readable name of the engine */
  nickname?: Maybe<Scalars['String']>;
  /** DO NOT USE */
  path?: Maybe<Scalars['String']>;
  /** The definition id of the azure release in which this engine was released */
  release_definition_id_azure?: Maybe<Scalars['Int']>;
  release_number?: Maybe<Scalars['Int']>;
  /** Type of the engine. <Engineering> internal debugging, <QA> performance evaluation, <MP Candidate> candidate for mass production */
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Engines_Sum_Fields = {
  __typename?: 'engines_sum_fields';
  /** If built in azure build pipeline, the build ID (build run id) where the engine is built */
  azure_build_ref?: Maybe<Scalars['Int']>;
  /** If release in azure release pipeline, the release ID where the engine is released */
  azure_release_ref?: Maybe<Scalars['Int']>;
  /** The build definition id of the azure build in which this engine was built */
  build_definition_id_azure?: Maybe<Scalars['Int']>;
  /** Indicates what graph version the engine is compatible with. Only topology blobs maching this version will work. */
  graph_version?: Maybe<Scalars['Int']>;
  /** The definition id of the azure release in which this engine was released */
  release_definition_id_azure?: Maybe<Scalars['Int']>;
  release_number?: Maybe<Scalars['Int']>;
};

/** update columns of table "engines" */
export enum Engines_Update_Column {
  /** column name */
  AzureBuildRef = 'azure_build_ref',
  /** column name */
  AzureReleaseRef = 'azure_release_ref',
  /** column name */
  Branch = 'branch',
  /** column name */
  BuildDefinitionIdAzure = 'build_definition_id_azure',
  /** column name */
  BuildNumber = 'build_number',
  /** column name */
  BuildRef = 'build_ref',
  /** column name */
  BuildVersion = 'build_version',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EllipticEngineCommitHash = 'elliptic_engine_commit_hash',
  /** column name */
  EngineTypeId = 'engine_type_id',
  /** column name */
  GraphVersion = 'graph_version',
  /** column name */
  Id = 'id',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  Path = 'path',
  /** column name */
  ReleaseDefinitionIdAzure = 'release_definition_id_azure',
  /** column name */
  ReleaseNumber = 'release_number',
  /** column name */
  Type = 'type'
}

export type Engines_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Engines_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Engines_Set_Input>;
  where: Engines_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Engines_Var_Pop_Fields = {
  __typename?: 'engines_var_pop_fields';
  /** If built in azure build pipeline, the build ID (build run id) where the engine is built */
  azure_build_ref?: Maybe<Scalars['Float']>;
  /** If release in azure release pipeline, the release ID where the engine is released */
  azure_release_ref?: Maybe<Scalars['Float']>;
  /** The build definition id of the azure build in which this engine was built */
  build_definition_id_azure?: Maybe<Scalars['Float']>;
  /** Indicates what graph version the engine is compatible with. Only topology blobs maching this version will work. */
  graph_version?: Maybe<Scalars['Float']>;
  /** The definition id of the azure release in which this engine was released */
  release_definition_id_azure?: Maybe<Scalars['Float']>;
  release_number?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Engines_Var_Samp_Fields = {
  __typename?: 'engines_var_samp_fields';
  /** If built in azure build pipeline, the build ID (build run id) where the engine is built */
  azure_build_ref?: Maybe<Scalars['Float']>;
  /** If release in azure release pipeline, the release ID where the engine is released */
  azure_release_ref?: Maybe<Scalars['Float']>;
  /** The build definition id of the azure build in which this engine was built */
  build_definition_id_azure?: Maybe<Scalars['Float']>;
  /** Indicates what graph version the engine is compatible with. Only topology blobs maching this version will work. */
  graph_version?: Maybe<Scalars['Float']>;
  /** The definition id of the azure release in which this engine was released */
  release_definition_id_azure?: Maybe<Scalars['Float']>;
  release_number?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Engines_Variance_Fields = {
  __typename?: 'engines_variance_fields';
  /** If built in azure build pipeline, the build ID (build run id) where the engine is built */
  azure_build_ref?: Maybe<Scalars['Float']>;
  /** If release in azure release pipeline, the release ID where the engine is released */
  azure_release_ref?: Maybe<Scalars['Float']>;
  /** The build definition id of the azure build in which this engine was built */
  build_definition_id_azure?: Maybe<Scalars['Float']>;
  /** Indicates what graph version the engine is compatible with. Only topology blobs maching this version will work. */
  graph_version?: Maybe<Scalars['Float']>;
  /** The definition id of the azure release in which this engine was released */
  release_definition_id_azure?: Maybe<Scalars['Float']>;
  release_number?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "field_option_media" */
export type Field_Option_Media = {
  __typename?: 'field_option_media';
  /** An object relationship */
  field_option: Field_Options;
  field_option_id: Scalars['uuid'];
  /** An object relationship */
  media: Media;
  media_id: Scalars['uuid'];
};

/** aggregated selection of "field_option_media" */
export type Field_Option_Media_Aggregate = {
  __typename?: 'field_option_media_aggregate';
  aggregate?: Maybe<Field_Option_Media_Aggregate_Fields>;
  nodes: Array<Field_Option_Media>;
};

/** aggregate fields of "field_option_media" */
export type Field_Option_Media_Aggregate_Fields = {
  __typename?: 'field_option_media_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Field_Option_Media_Max_Fields>;
  min?: Maybe<Field_Option_Media_Min_Fields>;
};


/** aggregate fields of "field_option_media" */
export type Field_Option_Media_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Field_Option_Media_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "field_option_media" */
export type Field_Option_Media_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Field_Option_Media_Max_Order_By>;
  min?: Maybe<Field_Option_Media_Min_Order_By>;
};

/** input type for inserting array relation for remote table "field_option_media" */
export type Field_Option_Media_Arr_Rel_Insert_Input = {
  data: Array<Field_Option_Media_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Field_Option_Media_On_Conflict>;
};

/** Boolean expression to filter rows from the table "field_option_media". All fields are combined with a logical 'AND'. */
export type Field_Option_Media_Bool_Exp = {
  _and?: Maybe<Array<Field_Option_Media_Bool_Exp>>;
  _not?: Maybe<Field_Option_Media_Bool_Exp>;
  _or?: Maybe<Array<Field_Option_Media_Bool_Exp>>;
  field_option?: Maybe<Field_Options_Bool_Exp>;
  field_option_id?: Maybe<Uuid_Comparison_Exp>;
  media?: Maybe<Media_Bool_Exp>;
  media_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "field_option_media" */
export enum Field_Option_Media_Constraint {
  /** unique or primary key constraint on columns "field_option_id", "media_id" */
  FieldOptionMediaPkey = 'field_option_media_pkey'
}

/** input type for inserting data into table "field_option_media" */
export type Field_Option_Media_Insert_Input = {
  field_option?: Maybe<Field_Options_Obj_Rel_Insert_Input>;
  field_option_id?: Maybe<Scalars['uuid']>;
  media?: Maybe<Media_Obj_Rel_Insert_Input>;
  media_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Field_Option_Media_Max_Fields = {
  __typename?: 'field_option_media_max_fields';
  field_option_id?: Maybe<Scalars['uuid']>;
  media_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "field_option_media" */
export type Field_Option_Media_Max_Order_By = {
  field_option_id?: Maybe<Order_By>;
  media_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Field_Option_Media_Min_Fields = {
  __typename?: 'field_option_media_min_fields';
  field_option_id?: Maybe<Scalars['uuid']>;
  media_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "field_option_media" */
export type Field_Option_Media_Min_Order_By = {
  field_option_id?: Maybe<Order_By>;
  media_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "field_option_media" */
export type Field_Option_Media_Mutation_Response = {
  __typename?: 'field_option_media_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Field_Option_Media>;
};

/** on_conflict condition type for table "field_option_media" */
export type Field_Option_Media_On_Conflict = {
  constraint: Field_Option_Media_Constraint;
  update_columns?: Array<Field_Option_Media_Update_Column>;
  where?: Maybe<Field_Option_Media_Bool_Exp>;
};

/** Ordering options when selecting data from "field_option_media". */
export type Field_Option_Media_Order_By = {
  field_option?: Maybe<Field_Options_Order_By>;
  field_option_id?: Maybe<Order_By>;
  media?: Maybe<Media_Order_By>;
  media_id?: Maybe<Order_By>;
};

/** primary key columns input for table: field_option_media */
export type Field_Option_Media_Pk_Columns_Input = {
  field_option_id: Scalars['uuid'];
  media_id: Scalars['uuid'];
};

/** select columns of table "field_option_media" */
export enum Field_Option_Media_Select_Column {
  /** column name */
  FieldOptionId = 'field_option_id',
  /** column name */
  MediaId = 'media_id'
}

/** input type for updating data in table "field_option_media" */
export type Field_Option_Media_Set_Input = {
  field_option_id?: Maybe<Scalars['uuid']>;
  media_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "field_option_media" */
export type Field_Option_Media_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Field_Option_Media_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Field_Option_Media_Stream_Cursor_Value_Input = {
  field_option_id?: Maybe<Scalars['uuid']>;
  media_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "field_option_media" */
export enum Field_Option_Media_Update_Column {
  /** column name */
  FieldOptionId = 'field_option_id',
  /** column name */
  MediaId = 'media_id'
}

export type Field_Option_Media_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Field_Option_Media_Set_Input>;
  where: Field_Option_Media_Bool_Exp;
};

/** columns and relationships of "field_options" */
export type Field_Options = {
  __typename?: 'field_options';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  field?: Maybe<Fields>;
  field_id: Scalars['uuid'];
  /** An array relationship */
  field_option_media: Array<Field_Option_Media>;
  /** An aggregate relationship */
  field_option_media_aggregate: Field_Option_Media_Aggregate;
  /** An array relationship */
  hypertask_field_options: Array<Hypertask_Field_Option>;
  /** An aggregate relationship */
  hypertask_field_options_aggregate: Hypertask_Field_Option_Aggregate;
  id: Scalars['uuid'];
  /** An object relationship */
  option?: Maybe<Options>;
  option_id: Scalars['uuid'];
  /** An array relationship */
  task_field_options: Array<Task_Field_Option>;
  /** An aggregate relationship */
  task_field_options_aggregate: Task_Field_Option_Aggregate;
  /** An object relationship */
  type: Types;
  type_id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "field_options" */
export type Field_OptionsField_Option_MediaArgs = {
  distinct_on?: Maybe<Array<Field_Option_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Field_Option_Media_Order_By>>;
  where?: Maybe<Field_Option_Media_Bool_Exp>;
};


/** columns and relationships of "field_options" */
export type Field_OptionsField_Option_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Field_Option_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Field_Option_Media_Order_By>>;
  where?: Maybe<Field_Option_Media_Bool_Exp>;
};


/** columns and relationships of "field_options" */
export type Field_OptionsHypertask_Field_OptionsArgs = {
  distinct_on?: Maybe<Array<Hypertask_Field_Option_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hypertask_Field_Option_Order_By>>;
  where?: Maybe<Hypertask_Field_Option_Bool_Exp>;
};


/** columns and relationships of "field_options" */
export type Field_OptionsHypertask_Field_Options_AggregateArgs = {
  distinct_on?: Maybe<Array<Hypertask_Field_Option_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hypertask_Field_Option_Order_By>>;
  where?: Maybe<Hypertask_Field_Option_Bool_Exp>;
};


/** columns and relationships of "field_options" */
export type Field_OptionsTask_Field_OptionsArgs = {
  distinct_on?: Maybe<Array<Task_Field_Option_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Field_Option_Order_By>>;
  where?: Maybe<Task_Field_Option_Bool_Exp>;
};


/** columns and relationships of "field_options" */
export type Field_OptionsTask_Field_Options_AggregateArgs = {
  distinct_on?: Maybe<Array<Task_Field_Option_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Field_Option_Order_By>>;
  where?: Maybe<Task_Field_Option_Bool_Exp>;
};

/** aggregated selection of "field_options" */
export type Field_Options_Aggregate = {
  __typename?: 'field_options_aggregate';
  aggregate?: Maybe<Field_Options_Aggregate_Fields>;
  nodes: Array<Field_Options>;
};

/** aggregate fields of "field_options" */
export type Field_Options_Aggregate_Fields = {
  __typename?: 'field_options_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Field_Options_Max_Fields>;
  min?: Maybe<Field_Options_Min_Fields>;
};


/** aggregate fields of "field_options" */
export type Field_Options_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Field_Options_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "field_options" */
export type Field_Options_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Field_Options_Max_Order_By>;
  min?: Maybe<Field_Options_Min_Order_By>;
};

/** input type for inserting array relation for remote table "field_options" */
export type Field_Options_Arr_Rel_Insert_Input = {
  data: Array<Field_Options_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Field_Options_On_Conflict>;
};

/** Boolean expression to filter rows from the table "field_options". All fields are combined with a logical 'AND'. */
export type Field_Options_Bool_Exp = {
  _and?: Maybe<Array<Field_Options_Bool_Exp>>;
  _not?: Maybe<Field_Options_Bool_Exp>;
  _or?: Maybe<Array<Field_Options_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  field?: Maybe<Fields_Bool_Exp>;
  field_id?: Maybe<Uuid_Comparison_Exp>;
  field_option_media?: Maybe<Field_Option_Media_Bool_Exp>;
  hypertask_field_options?: Maybe<Hypertask_Field_Option_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  option?: Maybe<Options_Bool_Exp>;
  option_id?: Maybe<Uuid_Comparison_Exp>;
  task_field_options?: Maybe<Task_Field_Option_Bool_Exp>;
  type?: Maybe<Types_Bool_Exp>;
  type_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "field_options" */
export enum Field_Options_Constraint {
  /** unique or primary key constraint on columns "option_id", "field_id" */
  FieldOptionsFieldIdOptionIdUnique = 'field_options_field_id_option_id_unique',
  /** unique or primary key constraint on columns "id" */
  FieldOptionsPkey = 'field_options_pkey'
}

/** input type for inserting data into table "field_options" */
export type Field_Options_Insert_Input = {
  field?: Maybe<Fields_Obj_Rel_Insert_Input>;
  field_id?: Maybe<Scalars['uuid']>;
  field_option_media?: Maybe<Field_Option_Media_Arr_Rel_Insert_Input>;
  hypertask_field_options?: Maybe<Hypertask_Field_Option_Arr_Rel_Insert_Input>;
  option?: Maybe<Options_Obj_Rel_Insert_Input>;
  option_id?: Maybe<Scalars['uuid']>;
  task_field_options?: Maybe<Task_Field_Option_Arr_Rel_Insert_Input>;
  type?: Maybe<Types_Obj_Rel_Insert_Input>;
  type_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Field_Options_Max_Fields = {
  __typename?: 'field_options_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  field_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  option_id?: Maybe<Scalars['uuid']>;
  type_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "field_options" */
export type Field_Options_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  field_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  option_id?: Maybe<Order_By>;
  type_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Field_Options_Min_Fields = {
  __typename?: 'field_options_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  field_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  option_id?: Maybe<Scalars['uuid']>;
  type_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "field_options" */
export type Field_Options_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  field_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  option_id?: Maybe<Order_By>;
  type_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "field_options" */
export type Field_Options_Mutation_Response = {
  __typename?: 'field_options_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Field_Options>;
};

/** input type for inserting object relation for remote table "field_options" */
export type Field_Options_Obj_Rel_Insert_Input = {
  data: Field_Options_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Field_Options_On_Conflict>;
};

/** on_conflict condition type for table "field_options" */
export type Field_Options_On_Conflict = {
  constraint: Field_Options_Constraint;
  update_columns?: Array<Field_Options_Update_Column>;
  where?: Maybe<Field_Options_Bool_Exp>;
};

/** Ordering options when selecting data from "field_options". */
export type Field_Options_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  field?: Maybe<Fields_Order_By>;
  field_id?: Maybe<Order_By>;
  field_option_media_aggregate?: Maybe<Field_Option_Media_Aggregate_Order_By>;
  hypertask_field_options_aggregate?: Maybe<Hypertask_Field_Option_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  option?: Maybe<Options_Order_By>;
  option_id?: Maybe<Order_By>;
  task_field_options_aggregate?: Maybe<Task_Field_Option_Aggregate_Order_By>;
  type?: Maybe<Types_Order_By>;
  type_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: field_options */
export type Field_Options_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "field_options" */
export enum Field_Options_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FieldId = 'field_id',
  /** column name */
  Id = 'id',
  /** column name */
  OptionId = 'option_id',
  /** column name */
  TypeId = 'type_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "field_options" */
export type Field_Options_Set_Input = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  field_id?: Maybe<Scalars['uuid']>;
  option_id?: Maybe<Scalars['uuid']>;
  type_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "field_options" */
export type Field_Options_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Field_Options_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Field_Options_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  field_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  option_id?: Maybe<Scalars['uuid']>;
  type_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "field_options" */
export enum Field_Options_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FieldId = 'field_id',
  /** column name */
  OptionId = 'option_id',
  /** column name */
  TypeId = 'type_id'
}

export type Field_Options_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Field_Options_Set_Input>;
  where: Field_Options_Bool_Exp;
};

/** columns and relationships of "field_translation" */
export type Field_Translation = {
  __typename?: 'field_translation';
  description?: Maybe<Scalars['String']>;
  display: Scalars['String'];
  /** An object relationship */
  field: Fields;
  field_id: Scalars['uuid'];
  /** An object relationship */
  field_translation_language: Languages;
  language: Scalars['String'];
};

/** aggregated selection of "field_translation" */
export type Field_Translation_Aggregate = {
  __typename?: 'field_translation_aggregate';
  aggregate?: Maybe<Field_Translation_Aggregate_Fields>;
  nodes: Array<Field_Translation>;
};

/** aggregate fields of "field_translation" */
export type Field_Translation_Aggregate_Fields = {
  __typename?: 'field_translation_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Field_Translation_Max_Fields>;
  min?: Maybe<Field_Translation_Min_Fields>;
};


/** aggregate fields of "field_translation" */
export type Field_Translation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Field_Translation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "field_translation" */
export type Field_Translation_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Field_Translation_Max_Order_By>;
  min?: Maybe<Field_Translation_Min_Order_By>;
};

/** input type for inserting array relation for remote table "field_translation" */
export type Field_Translation_Arr_Rel_Insert_Input = {
  data: Array<Field_Translation_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Field_Translation_On_Conflict>;
};

/** Boolean expression to filter rows from the table "field_translation". All fields are combined with a logical 'AND'. */
export type Field_Translation_Bool_Exp = {
  _and?: Maybe<Array<Field_Translation_Bool_Exp>>;
  _not?: Maybe<Field_Translation_Bool_Exp>;
  _or?: Maybe<Array<Field_Translation_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  display?: Maybe<String_Comparison_Exp>;
  field?: Maybe<Fields_Bool_Exp>;
  field_id?: Maybe<Uuid_Comparison_Exp>;
  field_translation_language?: Maybe<Languages_Bool_Exp>;
  language?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "field_translation" */
export enum Field_Translation_Constraint {
  /** unique or primary key constraint on columns "display", "field_id" */
  FieldTranslationFieldIdDisplayKey = 'field_translation_field_id_display_key',
  /** unique or primary key constraint on columns "field_id", "language" */
  FieldTranslationPkey = 'field_translation_pkey'
}

/** input type for inserting data into table "field_translation" */
export type Field_Translation_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  field?: Maybe<Fields_Obj_Rel_Insert_Input>;
  field_id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Field_Translation_Max_Fields = {
  __typename?: 'field_translation_max_fields';
  description?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  field_id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "field_translation" */
export type Field_Translation_Max_Order_By = {
  description?: Maybe<Order_By>;
  display?: Maybe<Order_By>;
  field_id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Field_Translation_Min_Fields = {
  __typename?: 'field_translation_min_fields';
  description?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  field_id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "field_translation" */
export type Field_Translation_Min_Order_By = {
  description?: Maybe<Order_By>;
  display?: Maybe<Order_By>;
  field_id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
};

/** response of any mutation on the table "field_translation" */
export type Field_Translation_Mutation_Response = {
  __typename?: 'field_translation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Field_Translation>;
};

/** on_conflict condition type for table "field_translation" */
export type Field_Translation_On_Conflict = {
  constraint: Field_Translation_Constraint;
  update_columns?: Array<Field_Translation_Update_Column>;
  where?: Maybe<Field_Translation_Bool_Exp>;
};

/** Ordering options when selecting data from "field_translation". */
export type Field_Translation_Order_By = {
  description?: Maybe<Order_By>;
  display?: Maybe<Order_By>;
  field?: Maybe<Fields_Order_By>;
  field_id?: Maybe<Order_By>;
  field_translation_language?: Maybe<Languages_Order_By>;
  language?: Maybe<Order_By>;
};

/** primary key columns input for table: field_translation */
export type Field_Translation_Pk_Columns_Input = {
  field_id: Scalars['uuid'];
  language: Scalars['String'];
};

/** select columns of table "field_translation" */
export enum Field_Translation_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Display = 'display',
  /** column name */
  FieldId = 'field_id',
  /** column name */
  Language = 'language'
}

/** input type for updating data in table "field_translation" */
export type Field_Translation_Set_Input = {
  description?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  field_id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "field_translation" */
export type Field_Translation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Field_Translation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Field_Translation_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  field_id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
};

/** update columns of table "field_translation" */
export enum Field_Translation_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Display = 'display',
  /** column name */
  FieldId = 'field_id',
  /** column name */
  Language = 'language'
}

export type Field_Translation_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Field_Translation_Set_Input>;
  where: Field_Translation_Bool_Exp;
};

/** columns and relationships of "fields" */
export type Fields = {
  __typename?: 'fields';
  /** An object relationship */
  context: Contexts;
  /** An array relationship */
  context_display_fields: Array<Context_Display_Field>;
  /** An aggregate relationship */
  context_display_fields_aggregate: Context_Display_Field_Aggregate;
  context_id: Scalars['uuid'];
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  display: Scalars['String'];
  /** An array relationship */
  field_options: Array<Field_Options>;
  /** An aggregate relationship */
  field_options_aggregate: Field_Options_Aggregate;
  /** An array relationship */
  hypertask_field_options: Array<Hypertask_Field_Option>;
  /** An aggregate relationship */
  hypertask_field_options_aggregate: Hypertask_Field_Option_Aggregate;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  translations: Array<Field_Translation>;
  /** An aggregate relationship */
  translations_aggregate: Field_Translation_Aggregate;
  /** An object relationship */
  type: Types;
  type_id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "fields" */
export type FieldsContext_Display_FieldsArgs = {
  distinct_on?: Maybe<Array<Context_Display_Field_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Display_Field_Order_By>>;
  where?: Maybe<Context_Display_Field_Bool_Exp>;
};


/** columns and relationships of "fields" */
export type FieldsContext_Display_Fields_AggregateArgs = {
  distinct_on?: Maybe<Array<Context_Display_Field_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Display_Field_Order_By>>;
  where?: Maybe<Context_Display_Field_Bool_Exp>;
};


/** columns and relationships of "fields" */
export type FieldsField_OptionsArgs = {
  distinct_on?: Maybe<Array<Field_Options_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Field_Options_Order_By>>;
  where?: Maybe<Field_Options_Bool_Exp>;
};


/** columns and relationships of "fields" */
export type FieldsField_Options_AggregateArgs = {
  distinct_on?: Maybe<Array<Field_Options_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Field_Options_Order_By>>;
  where?: Maybe<Field_Options_Bool_Exp>;
};


/** columns and relationships of "fields" */
export type FieldsHypertask_Field_OptionsArgs = {
  distinct_on?: Maybe<Array<Hypertask_Field_Option_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hypertask_Field_Option_Order_By>>;
  where?: Maybe<Hypertask_Field_Option_Bool_Exp>;
};


/** columns and relationships of "fields" */
export type FieldsHypertask_Field_Options_AggregateArgs = {
  distinct_on?: Maybe<Array<Hypertask_Field_Option_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hypertask_Field_Option_Order_By>>;
  where?: Maybe<Hypertask_Field_Option_Bool_Exp>;
};


/** columns and relationships of "fields" */
export type FieldsTranslationsArgs = {
  distinct_on?: Maybe<Array<Field_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Field_Translation_Order_By>>;
  where?: Maybe<Field_Translation_Bool_Exp>;
};


/** columns and relationships of "fields" */
export type FieldsTranslations_AggregateArgs = {
  distinct_on?: Maybe<Array<Field_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Field_Translation_Order_By>>;
  where?: Maybe<Field_Translation_Bool_Exp>;
};

/** aggregated selection of "fields" */
export type Fields_Aggregate = {
  __typename?: 'fields_aggregate';
  aggregate?: Maybe<Fields_Aggregate_Fields>;
  nodes: Array<Fields>;
};

/** aggregate fields of "fields" */
export type Fields_Aggregate_Fields = {
  __typename?: 'fields_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Fields_Max_Fields>;
  min?: Maybe<Fields_Min_Fields>;
};


/** aggregate fields of "fields" */
export type Fields_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Fields_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "fields" */
export type Fields_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Fields_Max_Order_By>;
  min?: Maybe<Fields_Min_Order_By>;
};

/** input type for inserting array relation for remote table "fields" */
export type Fields_Arr_Rel_Insert_Input = {
  data: Array<Fields_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Fields_On_Conflict>;
};

/** Boolean expression to filter rows from the table "fields". All fields are combined with a logical 'AND'. */
export type Fields_Bool_Exp = {
  _and?: Maybe<Array<Fields_Bool_Exp>>;
  _not?: Maybe<Fields_Bool_Exp>;
  _or?: Maybe<Array<Fields_Bool_Exp>>;
  context?: Maybe<Contexts_Bool_Exp>;
  context_display_fields?: Maybe<Context_Display_Field_Bool_Exp>;
  context_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  display?: Maybe<String_Comparison_Exp>;
  field_options?: Maybe<Field_Options_Bool_Exp>;
  hypertask_field_options?: Maybe<Hypertask_Field_Option_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  translations?: Maybe<Field_Translation_Bool_Exp>;
  type?: Maybe<Types_Bool_Exp>;
  type_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "fields" */
export enum Fields_Constraint {
  /** unique or primary key constraint on columns "context_id", "display" */
  FieldsContextIdDisplayKey = 'fields_context_id_display_key',
  /** unique or primary key constraint on columns "context_id", "id" */
  FieldsIdContextIdKey = 'fields_id_context_id_key',
  /** unique or primary key constraint on columns "id", "type_id" */
  FieldsIdTypeIdUnique = 'fields_id_type_id_unique',
  /** unique or primary key constraint on columns "name", "context_id" */
  FieldsNameContextIdUnique = 'fields_name_context_id_unique',
  /** unique or primary key constraint on columns "id" */
  FieldsPkey = 'fields_pkey'
}

/** input type for inserting data into table "fields" */
export type Fields_Insert_Input = {
  context?: Maybe<Contexts_Obj_Rel_Insert_Input>;
  context_display_fields?: Maybe<Context_Display_Field_Arr_Rel_Insert_Input>;
  context_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  field_options?: Maybe<Field_Options_Arr_Rel_Insert_Input>;
  hypertask_field_options?: Maybe<Hypertask_Field_Option_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  translations?: Maybe<Field_Translation_Arr_Rel_Insert_Input>;
  type?: Maybe<Types_Obj_Rel_Insert_Input>;
  type_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Fields_Max_Fields = {
  __typename?: 'fields_max_fields';
  context_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  type_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "fields" */
export type Fields_Max_Order_By = {
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  display?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  type_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Fields_Min_Fields = {
  __typename?: 'fields_min_fields';
  context_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  type_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "fields" */
export type Fields_Min_Order_By = {
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  display?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  type_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "fields" */
export type Fields_Mutation_Response = {
  __typename?: 'fields_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Fields>;
};

/** input type for inserting object relation for remote table "fields" */
export type Fields_Obj_Rel_Insert_Input = {
  data: Fields_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Fields_On_Conflict>;
};

/** on_conflict condition type for table "fields" */
export type Fields_On_Conflict = {
  constraint: Fields_Constraint;
  update_columns?: Array<Fields_Update_Column>;
  where?: Maybe<Fields_Bool_Exp>;
};

/** Ordering options when selecting data from "fields". */
export type Fields_Order_By = {
  context?: Maybe<Contexts_Order_By>;
  context_display_fields_aggregate?: Maybe<Context_Display_Field_Aggregate_Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  display?: Maybe<Order_By>;
  field_options_aggregate?: Maybe<Field_Options_Aggregate_Order_By>;
  hypertask_field_options_aggregate?: Maybe<Hypertask_Field_Option_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  translations_aggregate?: Maybe<Field_Translation_Aggregate_Order_By>;
  type?: Maybe<Types_Order_By>;
  type_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: fields */
export type Fields_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "fields" */
export enum Fields_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Display = 'display',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  TypeId = 'type_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "fields" */
export type Fields_Set_Input = {
  context_id?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "fields" */
export type Fields_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Fields_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Fields_Stream_Cursor_Value_Input = {
  context_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  type_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "fields" */
export enum Fields_Update_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Display = 'display',
  /** column name */
  Name = 'name',
  /** column name */
  TypeId = 'type_id'
}

export type Fields_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Fields_Set_Input>;
  where: Fields_Bool_Exp;
};

/** columns and relationships of "firmware" */
export type Firmware = {
  __typename?: 'firmware';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  device_firmwares: Array<Device_Firmware>;
  id: Scalars['uuid'];
  properties: Scalars['jsonb'];
  /** An array relationship */
  recordings: Array<Recordings>;
  /** An aggregate relationship */
  recordings_aggregate: Recordings_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "firmware" */
export type FirmwareDevice_FirmwaresArgs = {
  distinct_on?: Maybe<Array<Device_Firmware_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Device_Firmware_Order_By>>;
  where?: Maybe<Device_Firmware_Bool_Exp>;
};


/** columns and relationships of "firmware" */
export type FirmwarePropertiesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "firmware" */
export type FirmwareRecordingsArgs = {
  distinct_on?: Maybe<Array<Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recordings_Order_By>>;
  where?: Maybe<Recordings_Bool_Exp>;
};


/** columns and relationships of "firmware" */
export type FirmwareRecordings_AggregateArgs = {
  distinct_on?: Maybe<Array<Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recordings_Order_By>>;
  where?: Maybe<Recordings_Bool_Exp>;
};

/** aggregated selection of "firmware" */
export type Firmware_Aggregate = {
  __typename?: 'firmware_aggregate';
  aggregate?: Maybe<Firmware_Aggregate_Fields>;
  nodes: Array<Firmware>;
};

/** aggregate fields of "firmware" */
export type Firmware_Aggregate_Fields = {
  __typename?: 'firmware_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Firmware_Max_Fields>;
  min?: Maybe<Firmware_Min_Fields>;
};


/** aggregate fields of "firmware" */
export type Firmware_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Firmware_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Firmware_Append_Input = {
  properties?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "firmware". All fields are combined with a logical 'AND'. */
export type Firmware_Bool_Exp = {
  _and?: Maybe<Array<Firmware_Bool_Exp>>;
  _not?: Maybe<Firmware_Bool_Exp>;
  _or?: Maybe<Array<Firmware_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  device_firmwares?: Maybe<Device_Firmware_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  properties?: Maybe<Jsonb_Comparison_Exp>;
  recordings?: Maybe<Recordings_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "firmware" */
export enum Firmware_Constraint {
  /** unique or primary key constraint on columns "id" */
  FirmwarePkey = 'firmware_pkey',
  /** unique or primary key constraint on columns "properties" */
  FirmwarePropertiesKey = 'firmware_properties_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Firmware_Delete_At_Path_Input = {
  properties?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Firmware_Delete_Elem_Input = {
  properties?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Firmware_Delete_Key_Input = {
  properties?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "firmware" */
export type Firmware_Insert_Input = {
  device_firmwares?: Maybe<Device_Firmware_Arr_Rel_Insert_Input>;
  properties?: Maybe<Scalars['jsonb']>;
  recordings?: Maybe<Recordings_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Firmware_Max_Fields = {
  __typename?: 'firmware_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Firmware_Min_Fields = {
  __typename?: 'firmware_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "firmware" */
export type Firmware_Mutation_Response = {
  __typename?: 'firmware_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Firmware>;
};

/** input type for inserting object relation for remote table "firmware" */
export type Firmware_Obj_Rel_Insert_Input = {
  data: Firmware_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Firmware_On_Conflict>;
};

/** on_conflict condition type for table "firmware" */
export type Firmware_On_Conflict = {
  constraint: Firmware_Constraint;
  update_columns?: Array<Firmware_Update_Column>;
  where?: Maybe<Firmware_Bool_Exp>;
};

/** Ordering options when selecting data from "firmware". */
export type Firmware_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  device_firmwares_aggregate?: Maybe<Device_Firmware_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  properties?: Maybe<Order_By>;
  recordings_aggregate?: Maybe<Recordings_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: firmware */
export type Firmware_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Firmware_Prepend_Input = {
  properties?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "firmware" */
export enum Firmware_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Properties = 'properties',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "firmware" */
export type Firmware_Set_Input = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  properties?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "firmware" */
export type Firmware_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Firmware_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Firmware_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  properties?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "firmware" */
export enum Firmware_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Properties = 'properties',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Firmware_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Firmware_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Firmware_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Firmware_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Firmware_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Firmware_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Firmware_Set_Input>;
  where: Firmware_Bool_Exp;
};


/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: Maybe<Scalars['float8']>;
  _gt?: Maybe<Scalars['float8']>;
  _gte?: Maybe<Scalars['float8']>;
  _in?: Maybe<Array<Scalars['float8']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['float8']>;
  _lte?: Maybe<Scalars['float8']>;
  _neq?: Maybe<Scalars['float8']>;
  _nin?: Maybe<Array<Scalars['float8']>>;
};

/** use to record a configuration that is used to collect ground truth for one or more recordings */
export type Ground_Truth_Configurations = {
  __typename?: 'ground_truth_configurations';
  device_serialno: Scalars['String'];
  /** An object relationship */
  device_type?: Maybe<Ground_Truth_Device_Types>;
  device_type_id?: Maybe<Ground_Truth_Device_Types_Enum>;
  id: Scalars['uuid'];
  properties?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  recordings: Array<Ground_Truth_Recordings>;
  /** An aggregate relationship */
  recordings_aggregate: Ground_Truth_Recordings_Aggregate;
};


/** use to record a configuration that is used to collect ground truth for one or more recordings */
export type Ground_Truth_ConfigurationsPropertiesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** use to record a configuration that is used to collect ground truth for one or more recordings */
export type Ground_Truth_ConfigurationsRecordingsArgs = {
  distinct_on?: Maybe<Array<Ground_Truth_Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ground_Truth_Recordings_Order_By>>;
  where?: Maybe<Ground_Truth_Recordings_Bool_Exp>;
};


/** use to record a configuration that is used to collect ground truth for one or more recordings */
export type Ground_Truth_ConfigurationsRecordings_AggregateArgs = {
  distinct_on?: Maybe<Array<Ground_Truth_Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ground_Truth_Recordings_Order_By>>;
  where?: Maybe<Ground_Truth_Recordings_Bool_Exp>;
};

/** aggregated selection of "ground_truth.configurations" */
export type Ground_Truth_Configurations_Aggregate = {
  __typename?: 'ground_truth_configurations_aggregate';
  aggregate?: Maybe<Ground_Truth_Configurations_Aggregate_Fields>;
  nodes: Array<Ground_Truth_Configurations>;
};

/** aggregate fields of "ground_truth.configurations" */
export type Ground_Truth_Configurations_Aggregate_Fields = {
  __typename?: 'ground_truth_configurations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ground_Truth_Configurations_Max_Fields>;
  min?: Maybe<Ground_Truth_Configurations_Min_Fields>;
};


/** aggregate fields of "ground_truth.configurations" */
export type Ground_Truth_Configurations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ground_Truth_Configurations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ground_truth.configurations" */
export type Ground_Truth_Configurations_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Ground_Truth_Configurations_Max_Order_By>;
  min?: Maybe<Ground_Truth_Configurations_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Ground_Truth_Configurations_Append_Input = {
  properties?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "ground_truth.configurations". All fields are combined with a logical 'AND'. */
export type Ground_Truth_Configurations_Bool_Exp = {
  _and?: Maybe<Array<Ground_Truth_Configurations_Bool_Exp>>;
  _not?: Maybe<Ground_Truth_Configurations_Bool_Exp>;
  _or?: Maybe<Array<Ground_Truth_Configurations_Bool_Exp>>;
  device_serialno?: Maybe<String_Comparison_Exp>;
  device_type?: Maybe<Ground_Truth_Device_Types_Bool_Exp>;
  device_type_id?: Maybe<Ground_Truth_Device_Types_Enum_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  properties?: Maybe<Jsonb_Comparison_Exp>;
  recordings?: Maybe<Ground_Truth_Recordings_Bool_Exp>;
};

/** unique or primary key constraints on table "ground_truth.configurations" */
export enum Ground_Truth_Configurations_Constraint {
  /** unique or primary key constraint on columns "properties", "device_serialno" */
  ConfigurationsDeviceSerialnoPropertiesKey = 'configurations_device_serialno_properties_key',
  /** unique or primary key constraint on columns "id" */
  ConfigurationsPkey = 'configurations_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Ground_Truth_Configurations_Delete_At_Path_Input = {
  properties?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Ground_Truth_Configurations_Delete_Elem_Input = {
  properties?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Ground_Truth_Configurations_Delete_Key_Input = {
  properties?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "ground_truth.configurations" */
export type Ground_Truth_Configurations_Insert_Input = {
  device_serialno?: Maybe<Scalars['String']>;
  device_type_id?: Maybe<Ground_Truth_Device_Types_Enum>;
  properties?: Maybe<Scalars['jsonb']>;
  recordings?: Maybe<Ground_Truth_Recordings_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Ground_Truth_Configurations_Max_Fields = {
  __typename?: 'ground_truth_configurations_max_fields';
  device_serialno?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "ground_truth.configurations" */
export type Ground_Truth_Configurations_Max_Order_By = {
  device_serialno?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ground_Truth_Configurations_Min_Fields = {
  __typename?: 'ground_truth_configurations_min_fields';
  device_serialno?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "ground_truth.configurations" */
export type Ground_Truth_Configurations_Min_Order_By = {
  device_serialno?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** response of any mutation on the table "ground_truth.configurations" */
export type Ground_Truth_Configurations_Mutation_Response = {
  __typename?: 'ground_truth_configurations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ground_Truth_Configurations>;
};

/** input type for inserting object relation for remote table "ground_truth.configurations" */
export type Ground_Truth_Configurations_Obj_Rel_Insert_Input = {
  data: Ground_Truth_Configurations_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Ground_Truth_Configurations_On_Conflict>;
};

/** on_conflict condition type for table "ground_truth.configurations" */
export type Ground_Truth_Configurations_On_Conflict = {
  constraint: Ground_Truth_Configurations_Constraint;
  update_columns?: Array<Ground_Truth_Configurations_Update_Column>;
  where?: Maybe<Ground_Truth_Configurations_Bool_Exp>;
};

/** Ordering options when selecting data from "ground_truth.configurations". */
export type Ground_Truth_Configurations_Order_By = {
  device_serialno?: Maybe<Order_By>;
  device_type?: Maybe<Ground_Truth_Device_Types_Order_By>;
  device_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  properties?: Maybe<Order_By>;
  recordings_aggregate?: Maybe<Ground_Truth_Recordings_Aggregate_Order_By>;
};

/** primary key columns input for table: ground_truth.configurations */
export type Ground_Truth_Configurations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Ground_Truth_Configurations_Prepend_Input = {
  properties?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "ground_truth.configurations" */
export enum Ground_Truth_Configurations_Select_Column {
  /** column name */
  DeviceSerialno = 'device_serialno',
  /** column name */
  DeviceTypeId = 'device_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  Properties = 'properties'
}

/** input type for updating data in table "ground_truth.configurations" */
export type Ground_Truth_Configurations_Set_Input = {
  device_serialno?: Maybe<Scalars['String']>;
  device_type_id?: Maybe<Ground_Truth_Device_Types_Enum>;
  properties?: Maybe<Scalars['jsonb']>;
};

/** Streaming cursor of the table "ground_truth_configurations" */
export type Ground_Truth_Configurations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ground_Truth_Configurations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ground_Truth_Configurations_Stream_Cursor_Value_Input = {
  device_serialno?: Maybe<Scalars['String']>;
  device_type_id?: Maybe<Ground_Truth_Device_Types_Enum>;
  id?: Maybe<Scalars['uuid']>;
  properties?: Maybe<Scalars['jsonb']>;
};

/** update columns of table "ground_truth.configurations" */
export enum Ground_Truth_Configurations_Update_Column {
  /** column name */
  DeviceSerialno = 'device_serialno',
  /** column name */
  DeviceTypeId = 'device_type_id',
  /** column name */
  Properties = 'properties'
}

export type Ground_Truth_Configurations_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Ground_Truth_Configurations_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Ground_Truth_Configurations_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Ground_Truth_Configurations_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Ground_Truth_Configurations_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Ground_Truth_Configurations_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Ground_Truth_Configurations_Set_Input>;
  where: Ground_Truth_Configurations_Bool_Exp;
};

/** an enum table for defining a restricted set of sensor/device types that can be used to collect ground truth data */
export type Ground_Truth_Device_Types = {
  __typename?: 'ground_truth_device_types';
  /** An array relationship */
  configurations: Array<Ground_Truth_Configurations>;
  /** An aggregate relationship */
  configurations_aggregate: Ground_Truth_Configurations_Aggregate;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};


/** an enum table for defining a restricted set of sensor/device types that can be used to collect ground truth data */
export type Ground_Truth_Device_TypesConfigurationsArgs = {
  distinct_on?: Maybe<Array<Ground_Truth_Configurations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ground_Truth_Configurations_Order_By>>;
  where?: Maybe<Ground_Truth_Configurations_Bool_Exp>;
};


/** an enum table for defining a restricted set of sensor/device types that can be used to collect ground truth data */
export type Ground_Truth_Device_TypesConfigurations_AggregateArgs = {
  distinct_on?: Maybe<Array<Ground_Truth_Configurations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ground_Truth_Configurations_Order_By>>;
  where?: Maybe<Ground_Truth_Configurations_Bool_Exp>;
};

/** aggregated selection of "ground_truth.device_types" */
export type Ground_Truth_Device_Types_Aggregate = {
  __typename?: 'ground_truth_device_types_aggregate';
  aggregate?: Maybe<Ground_Truth_Device_Types_Aggregate_Fields>;
  nodes: Array<Ground_Truth_Device_Types>;
};

/** aggregate fields of "ground_truth.device_types" */
export type Ground_Truth_Device_Types_Aggregate_Fields = {
  __typename?: 'ground_truth_device_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ground_Truth_Device_Types_Max_Fields>;
  min?: Maybe<Ground_Truth_Device_Types_Min_Fields>;
};


/** aggregate fields of "ground_truth.device_types" */
export type Ground_Truth_Device_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ground_Truth_Device_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ground_truth.device_types". All fields are combined with a logical 'AND'. */
export type Ground_Truth_Device_Types_Bool_Exp = {
  _and?: Maybe<Array<Ground_Truth_Device_Types_Bool_Exp>>;
  _not?: Maybe<Ground_Truth_Device_Types_Bool_Exp>;
  _or?: Maybe<Array<Ground_Truth_Device_Types_Bool_Exp>>;
  configurations?: Maybe<Ground_Truth_Configurations_Bool_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
};

export enum Ground_Truth_Device_Types_Enum {
  Camera = 'camera',
  Lidar = 'lidar',
  PressurePad = 'pressure_pad'
}

/** Boolean expression to compare columns of type "ground_truth_device_types_enum". All fields are combined with logical 'AND'. */
export type Ground_Truth_Device_Types_Enum_Comparison_Exp = {
  _eq?: Maybe<Ground_Truth_Device_Types_Enum>;
  _in?: Maybe<Array<Ground_Truth_Device_Types_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Ground_Truth_Device_Types_Enum>;
  _nin?: Maybe<Array<Ground_Truth_Device_Types_Enum>>;
};

/** aggregate max on columns */
export type Ground_Truth_Device_Types_Max_Fields = {
  __typename?: 'ground_truth_device_types_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ground_Truth_Device_Types_Min_Fields = {
  __typename?: 'ground_truth_device_types_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ground_truth.device_types" */
export type Ground_Truth_Device_Types_Mutation_Response = {
  __typename?: 'ground_truth_device_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ground_Truth_Device_Types>;
};

/** Ordering options when selecting data from "ground_truth.device_types". */
export type Ground_Truth_Device_Types_Order_By = {
  configurations_aggregate?: Maybe<Ground_Truth_Configurations_Aggregate_Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** primary key columns input for table: ground_truth.device_types */
export type Ground_Truth_Device_Types_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ground_truth.device_types" */
export enum Ground_Truth_Device_Types_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "ground_truth.device_types" */
export type Ground_Truth_Device_Types_Set_Input = {
  description?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "ground_truth_device_types" */
export type Ground_Truth_Device_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ground_Truth_Device_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ground_Truth_Device_Types_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type Ground_Truth_Device_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Ground_Truth_Device_Types_Set_Input>;
  where: Ground_Truth_Device_Types_Bool_Exp;
};

/** an enum table for defining a restricted set of “processing modes” that can be applied to the ground truth data */
export type Ground_Truth_Processing_Modes = {
  __typename?: 'ground_truth_processing_modes';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** An array relationship */
  recordings: Array<Ground_Truth_Recordings>;
  /** An aggregate relationship */
  recordings_aggregate: Ground_Truth_Recordings_Aggregate;
};


/** an enum table for defining a restricted set of “processing modes” that can be applied to the ground truth data */
export type Ground_Truth_Processing_ModesRecordingsArgs = {
  distinct_on?: Maybe<Array<Ground_Truth_Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ground_Truth_Recordings_Order_By>>;
  where?: Maybe<Ground_Truth_Recordings_Bool_Exp>;
};


/** an enum table for defining a restricted set of “processing modes” that can be applied to the ground truth data */
export type Ground_Truth_Processing_ModesRecordings_AggregateArgs = {
  distinct_on?: Maybe<Array<Ground_Truth_Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ground_Truth_Recordings_Order_By>>;
  where?: Maybe<Ground_Truth_Recordings_Bool_Exp>;
};

/** aggregated selection of "ground_truth.processing_modes" */
export type Ground_Truth_Processing_Modes_Aggregate = {
  __typename?: 'ground_truth_processing_modes_aggregate';
  aggregate?: Maybe<Ground_Truth_Processing_Modes_Aggregate_Fields>;
  nodes: Array<Ground_Truth_Processing_Modes>;
};

/** aggregate fields of "ground_truth.processing_modes" */
export type Ground_Truth_Processing_Modes_Aggregate_Fields = {
  __typename?: 'ground_truth_processing_modes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ground_Truth_Processing_Modes_Max_Fields>;
  min?: Maybe<Ground_Truth_Processing_Modes_Min_Fields>;
};


/** aggregate fields of "ground_truth.processing_modes" */
export type Ground_Truth_Processing_Modes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ground_Truth_Processing_Modes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ground_truth.processing_modes". All fields are combined with a logical 'AND'. */
export type Ground_Truth_Processing_Modes_Bool_Exp = {
  _and?: Maybe<Array<Ground_Truth_Processing_Modes_Bool_Exp>>;
  _not?: Maybe<Ground_Truth_Processing_Modes_Bool_Exp>;
  _or?: Maybe<Array<Ground_Truth_Processing_Modes_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  recordings?: Maybe<Ground_Truth_Recordings_Bool_Exp>;
};

export enum Ground_Truth_Processing_Modes_Enum {
  Cloud = 'cloud',
  PostProcessing = 'post_processing',
  RealTime = 'real_time'
}

/** Boolean expression to compare columns of type "ground_truth_processing_modes_enum". All fields are combined with logical 'AND'. */
export type Ground_Truth_Processing_Modes_Enum_Comparison_Exp = {
  _eq?: Maybe<Ground_Truth_Processing_Modes_Enum>;
  _in?: Maybe<Array<Ground_Truth_Processing_Modes_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Ground_Truth_Processing_Modes_Enum>;
  _nin?: Maybe<Array<Ground_Truth_Processing_Modes_Enum>>;
};

/** aggregate max on columns */
export type Ground_Truth_Processing_Modes_Max_Fields = {
  __typename?: 'ground_truth_processing_modes_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ground_Truth_Processing_Modes_Min_Fields = {
  __typename?: 'ground_truth_processing_modes_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ground_truth.processing_modes" */
export type Ground_Truth_Processing_Modes_Mutation_Response = {
  __typename?: 'ground_truth_processing_modes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ground_Truth_Processing_Modes>;
};

/** Ordering options when selecting data from "ground_truth.processing_modes". */
export type Ground_Truth_Processing_Modes_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  recordings_aggregate?: Maybe<Ground_Truth_Recordings_Aggregate_Order_By>;
};

/** primary key columns input for table: ground_truth.processing_modes */
export type Ground_Truth_Processing_Modes_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ground_truth.processing_modes" */
export enum Ground_Truth_Processing_Modes_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "ground_truth.processing_modes" */
export type Ground_Truth_Processing_Modes_Set_Input = {
  description?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "ground_truth_processing_modes" */
export type Ground_Truth_Processing_Modes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ground_Truth_Processing_Modes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ground_Truth_Processing_Modes_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type Ground_Truth_Processing_Modes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Ground_Truth_Processing_Modes_Set_Input>;
  where: Ground_Truth_Processing_Modes_Bool_Exp;
};

/** use to attach the ground truth device configuration to the recording that the ground truth data was collected for */
export type Ground_Truth_Recordings = {
  __typename?: 'ground_truth_recordings';
  /** An object relationship */
  configuration: Ground_Truth_Configurations;
  configuration_id: Scalars['uuid'];
  /** An object relationship */
  processing_mode?: Maybe<Ground_Truth_Processing_Modes>;
  processing_mode_id?: Maybe<Ground_Truth_Processing_Modes_Enum>;
  /** An object relationship */
  recording: Recordings;
  recording_id: Scalars['uuid'];
  timesync_delta?: Maybe<Scalars['float8']>;
  timesync_start?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "ground_truth.recordings" */
export type Ground_Truth_Recordings_Aggregate = {
  __typename?: 'ground_truth_recordings_aggregate';
  aggregate?: Maybe<Ground_Truth_Recordings_Aggregate_Fields>;
  nodes: Array<Ground_Truth_Recordings>;
};

/** aggregate fields of "ground_truth.recordings" */
export type Ground_Truth_Recordings_Aggregate_Fields = {
  __typename?: 'ground_truth_recordings_aggregate_fields';
  avg?: Maybe<Ground_Truth_Recordings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ground_Truth_Recordings_Max_Fields>;
  min?: Maybe<Ground_Truth_Recordings_Min_Fields>;
  stddev?: Maybe<Ground_Truth_Recordings_Stddev_Fields>;
  stddev_pop?: Maybe<Ground_Truth_Recordings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ground_Truth_Recordings_Stddev_Samp_Fields>;
  sum?: Maybe<Ground_Truth_Recordings_Sum_Fields>;
  var_pop?: Maybe<Ground_Truth_Recordings_Var_Pop_Fields>;
  var_samp?: Maybe<Ground_Truth_Recordings_Var_Samp_Fields>;
  variance?: Maybe<Ground_Truth_Recordings_Variance_Fields>;
};


/** aggregate fields of "ground_truth.recordings" */
export type Ground_Truth_Recordings_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ground_Truth_Recordings_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ground_truth.recordings" */
export type Ground_Truth_Recordings_Aggregate_Order_By = {
  avg?: Maybe<Ground_Truth_Recordings_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ground_Truth_Recordings_Max_Order_By>;
  min?: Maybe<Ground_Truth_Recordings_Min_Order_By>;
  stddev?: Maybe<Ground_Truth_Recordings_Stddev_Order_By>;
  stddev_pop?: Maybe<Ground_Truth_Recordings_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ground_Truth_Recordings_Stddev_Samp_Order_By>;
  sum?: Maybe<Ground_Truth_Recordings_Sum_Order_By>;
  var_pop?: Maybe<Ground_Truth_Recordings_Var_Pop_Order_By>;
  var_samp?: Maybe<Ground_Truth_Recordings_Var_Samp_Order_By>;
  variance?: Maybe<Ground_Truth_Recordings_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ground_truth.recordings" */
export type Ground_Truth_Recordings_Arr_Rel_Insert_Input = {
  data: Array<Ground_Truth_Recordings_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Ground_Truth_Recordings_On_Conflict>;
};

/** aggregate avg on columns */
export type Ground_Truth_Recordings_Avg_Fields = {
  __typename?: 'ground_truth_recordings_avg_fields';
  timesync_delta?: Maybe<Scalars['Float']>;
  timesync_start?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ground_truth.recordings" */
export type Ground_Truth_Recordings_Avg_Order_By = {
  timesync_delta?: Maybe<Order_By>;
  timesync_start?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ground_truth.recordings". All fields are combined with a logical 'AND'. */
export type Ground_Truth_Recordings_Bool_Exp = {
  _and?: Maybe<Array<Ground_Truth_Recordings_Bool_Exp>>;
  _not?: Maybe<Ground_Truth_Recordings_Bool_Exp>;
  _or?: Maybe<Array<Ground_Truth_Recordings_Bool_Exp>>;
  configuration?: Maybe<Ground_Truth_Configurations_Bool_Exp>;
  configuration_id?: Maybe<Uuid_Comparison_Exp>;
  processing_mode?: Maybe<Ground_Truth_Processing_Modes_Bool_Exp>;
  processing_mode_id?: Maybe<Ground_Truth_Processing_Modes_Enum_Comparison_Exp>;
  recording?: Maybe<Recordings_Bool_Exp>;
  recording_id?: Maybe<Uuid_Comparison_Exp>;
  timesync_delta?: Maybe<Float8_Comparison_Exp>;
  timesync_start?: Maybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "ground_truth.recordings" */
export enum Ground_Truth_Recordings_Constraint {
  /** unique or primary key constraint on columns "recording_id" */
  RecordingsPkey = 'recordings_pkey'
}

/** input type for incrementing numeric columns in table "ground_truth.recordings" */
export type Ground_Truth_Recordings_Inc_Input = {
  timesync_delta?: Maybe<Scalars['float8']>;
  timesync_start?: Maybe<Scalars['float8']>;
};

/** input type for inserting data into table "ground_truth.recordings" */
export type Ground_Truth_Recordings_Insert_Input = {
  configuration?: Maybe<Ground_Truth_Configurations_Obj_Rel_Insert_Input>;
  configuration_id?: Maybe<Scalars['uuid']>;
  processing_mode_id?: Maybe<Ground_Truth_Processing_Modes_Enum>;
  recording?: Maybe<Recordings_Obj_Rel_Insert_Input>;
  recording_id?: Maybe<Scalars['uuid']>;
  timesync_delta?: Maybe<Scalars['float8']>;
  timesync_start?: Maybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type Ground_Truth_Recordings_Max_Fields = {
  __typename?: 'ground_truth_recordings_max_fields';
  configuration_id?: Maybe<Scalars['uuid']>;
  recording_id?: Maybe<Scalars['uuid']>;
  timesync_delta?: Maybe<Scalars['float8']>;
  timesync_start?: Maybe<Scalars['float8']>;
};

/** order by max() on columns of table "ground_truth.recordings" */
export type Ground_Truth_Recordings_Max_Order_By = {
  configuration_id?: Maybe<Order_By>;
  recording_id?: Maybe<Order_By>;
  timesync_delta?: Maybe<Order_By>;
  timesync_start?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ground_Truth_Recordings_Min_Fields = {
  __typename?: 'ground_truth_recordings_min_fields';
  configuration_id?: Maybe<Scalars['uuid']>;
  recording_id?: Maybe<Scalars['uuid']>;
  timesync_delta?: Maybe<Scalars['float8']>;
  timesync_start?: Maybe<Scalars['float8']>;
};

/** order by min() on columns of table "ground_truth.recordings" */
export type Ground_Truth_Recordings_Min_Order_By = {
  configuration_id?: Maybe<Order_By>;
  recording_id?: Maybe<Order_By>;
  timesync_delta?: Maybe<Order_By>;
  timesync_start?: Maybe<Order_By>;
};

/** response of any mutation on the table "ground_truth.recordings" */
export type Ground_Truth_Recordings_Mutation_Response = {
  __typename?: 'ground_truth_recordings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ground_Truth_Recordings>;
};

/** input type for inserting object relation for remote table "ground_truth.recordings" */
export type Ground_Truth_Recordings_Obj_Rel_Insert_Input = {
  data: Ground_Truth_Recordings_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Ground_Truth_Recordings_On_Conflict>;
};

/** on_conflict condition type for table "ground_truth.recordings" */
export type Ground_Truth_Recordings_On_Conflict = {
  constraint: Ground_Truth_Recordings_Constraint;
  update_columns?: Array<Ground_Truth_Recordings_Update_Column>;
  where?: Maybe<Ground_Truth_Recordings_Bool_Exp>;
};

/** Ordering options when selecting data from "ground_truth.recordings". */
export type Ground_Truth_Recordings_Order_By = {
  configuration?: Maybe<Ground_Truth_Configurations_Order_By>;
  configuration_id?: Maybe<Order_By>;
  processing_mode?: Maybe<Ground_Truth_Processing_Modes_Order_By>;
  processing_mode_id?: Maybe<Order_By>;
  recording?: Maybe<Recordings_Order_By>;
  recording_id?: Maybe<Order_By>;
  timesync_delta?: Maybe<Order_By>;
  timesync_start?: Maybe<Order_By>;
};

/** primary key columns input for table: ground_truth.recordings */
export type Ground_Truth_Recordings_Pk_Columns_Input = {
  recording_id: Scalars['uuid'];
};

/** select columns of table "ground_truth.recordings" */
export enum Ground_Truth_Recordings_Select_Column {
  /** column name */
  ConfigurationId = 'configuration_id',
  /** column name */
  ProcessingModeId = 'processing_mode_id',
  /** column name */
  RecordingId = 'recording_id',
  /** column name */
  TimesyncDelta = 'timesync_delta',
  /** column name */
  TimesyncStart = 'timesync_start'
}

/** input type for updating data in table "ground_truth.recordings" */
export type Ground_Truth_Recordings_Set_Input = {
  configuration_id?: Maybe<Scalars['uuid']>;
  processing_mode_id?: Maybe<Ground_Truth_Processing_Modes_Enum>;
  timesync_delta?: Maybe<Scalars['float8']>;
  timesync_start?: Maybe<Scalars['float8']>;
};

/** aggregate stddev on columns */
export type Ground_Truth_Recordings_Stddev_Fields = {
  __typename?: 'ground_truth_recordings_stddev_fields';
  timesync_delta?: Maybe<Scalars['Float']>;
  timesync_start?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ground_truth.recordings" */
export type Ground_Truth_Recordings_Stddev_Order_By = {
  timesync_delta?: Maybe<Order_By>;
  timesync_start?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ground_Truth_Recordings_Stddev_Pop_Fields = {
  __typename?: 'ground_truth_recordings_stddev_pop_fields';
  timesync_delta?: Maybe<Scalars['Float']>;
  timesync_start?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ground_truth.recordings" */
export type Ground_Truth_Recordings_Stddev_Pop_Order_By = {
  timesync_delta?: Maybe<Order_By>;
  timesync_start?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ground_Truth_Recordings_Stddev_Samp_Fields = {
  __typename?: 'ground_truth_recordings_stddev_samp_fields';
  timesync_delta?: Maybe<Scalars['Float']>;
  timesync_start?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ground_truth.recordings" */
export type Ground_Truth_Recordings_Stddev_Samp_Order_By = {
  timesync_delta?: Maybe<Order_By>;
  timesync_start?: Maybe<Order_By>;
};

/** Streaming cursor of the table "ground_truth_recordings" */
export type Ground_Truth_Recordings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ground_Truth_Recordings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ground_Truth_Recordings_Stream_Cursor_Value_Input = {
  configuration_id?: Maybe<Scalars['uuid']>;
  processing_mode_id?: Maybe<Ground_Truth_Processing_Modes_Enum>;
  recording_id?: Maybe<Scalars['uuid']>;
  timesync_delta?: Maybe<Scalars['float8']>;
  timesync_start?: Maybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type Ground_Truth_Recordings_Sum_Fields = {
  __typename?: 'ground_truth_recordings_sum_fields';
  timesync_delta?: Maybe<Scalars['float8']>;
  timesync_start?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "ground_truth.recordings" */
export type Ground_Truth_Recordings_Sum_Order_By = {
  timesync_delta?: Maybe<Order_By>;
  timesync_start?: Maybe<Order_By>;
};

/** update columns of table "ground_truth.recordings" */
export enum Ground_Truth_Recordings_Update_Column {
  /** column name */
  ConfigurationId = 'configuration_id',
  /** column name */
  ProcessingModeId = 'processing_mode_id',
  /** column name */
  TimesyncDelta = 'timesync_delta',
  /** column name */
  TimesyncStart = 'timesync_start'
}

export type Ground_Truth_Recordings_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Ground_Truth_Recordings_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Ground_Truth_Recordings_Set_Input>;
  where: Ground_Truth_Recordings_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ground_Truth_Recordings_Var_Pop_Fields = {
  __typename?: 'ground_truth_recordings_var_pop_fields';
  timesync_delta?: Maybe<Scalars['Float']>;
  timesync_start?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ground_truth.recordings" */
export type Ground_Truth_Recordings_Var_Pop_Order_By = {
  timesync_delta?: Maybe<Order_By>;
  timesync_start?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ground_Truth_Recordings_Var_Samp_Fields = {
  __typename?: 'ground_truth_recordings_var_samp_fields';
  timesync_delta?: Maybe<Scalars['Float']>;
  timesync_start?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ground_truth.recordings" */
export type Ground_Truth_Recordings_Var_Samp_Order_By = {
  timesync_delta?: Maybe<Order_By>;
  timesync_start?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ground_Truth_Recordings_Variance_Fields = {
  __typename?: 'ground_truth_recordings_variance_fields';
  timesync_delta?: Maybe<Scalars['Float']>;
  timesync_start?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ground_truth.recordings" */
export type Ground_Truth_Recordings_Variance_Order_By = {
  timesync_delta?: Maybe<Order_By>;
  timesync_start?: Maybe<Order_By>;
};

/** columns and relationships of "ground_truth_types" */
export type Ground_Truth_Types = {
  __typename?: 'ground_truth_types';
  default_far_threshold?: Maybe<Scalars['Int']>;
  default_near_threshold?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  max_far_threshold?: Maybe<Scalars['Int']>;
  max_near_threshold?: Maybe<Scalars['Int']>;
  min_far_threshold?: Maybe<Scalars['Int']>;
  min_near_threshold?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

/** aggregated selection of "ground_truth_types" */
export type Ground_Truth_Types_Aggregate = {
  __typename?: 'ground_truth_types_aggregate';
  aggregate?: Maybe<Ground_Truth_Types_Aggregate_Fields>;
  nodes: Array<Ground_Truth_Types>;
};

/** aggregate fields of "ground_truth_types" */
export type Ground_Truth_Types_Aggregate_Fields = {
  __typename?: 'ground_truth_types_aggregate_fields';
  avg?: Maybe<Ground_Truth_Types_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ground_Truth_Types_Max_Fields>;
  min?: Maybe<Ground_Truth_Types_Min_Fields>;
  stddev?: Maybe<Ground_Truth_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Ground_Truth_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ground_Truth_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Ground_Truth_Types_Sum_Fields>;
  var_pop?: Maybe<Ground_Truth_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Ground_Truth_Types_Var_Samp_Fields>;
  variance?: Maybe<Ground_Truth_Types_Variance_Fields>;
};


/** aggregate fields of "ground_truth_types" */
export type Ground_Truth_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ground_Truth_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Ground_Truth_Types_Avg_Fields = {
  __typename?: 'ground_truth_types_avg_fields';
  default_far_threshold?: Maybe<Scalars['Float']>;
  default_near_threshold?: Maybe<Scalars['Float']>;
  max_far_threshold?: Maybe<Scalars['Float']>;
  max_near_threshold?: Maybe<Scalars['Float']>;
  min_far_threshold?: Maybe<Scalars['Float']>;
  min_near_threshold?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ground_truth_types". All fields are combined with a logical 'AND'. */
export type Ground_Truth_Types_Bool_Exp = {
  _and?: Maybe<Array<Ground_Truth_Types_Bool_Exp>>;
  _not?: Maybe<Ground_Truth_Types_Bool_Exp>;
  _or?: Maybe<Array<Ground_Truth_Types_Bool_Exp>>;
  default_far_threshold?: Maybe<Int_Comparison_Exp>;
  default_near_threshold?: Maybe<Int_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  max_far_threshold?: Maybe<Int_Comparison_Exp>;
  max_near_threshold?: Maybe<Int_Comparison_Exp>;
  min_far_threshold?: Maybe<Int_Comparison_Exp>;
  min_near_threshold?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ground_truth_types" */
export enum Ground_Truth_Types_Constraint {
  /** unique or primary key constraint on columns "name" */
  GroundTruthTypesNameKey = 'ground_truth_types_name_key',
  /** unique or primary key constraint on columns "id" */
  GroundTruthTypesPkey = 'ground_truth_types_pkey'
}

/** input type for incrementing numeric columns in table "ground_truth_types" */
export type Ground_Truth_Types_Inc_Input = {
  default_far_threshold?: Maybe<Scalars['Int']>;
  default_near_threshold?: Maybe<Scalars['Int']>;
  max_far_threshold?: Maybe<Scalars['Int']>;
  max_near_threshold?: Maybe<Scalars['Int']>;
  min_far_threshold?: Maybe<Scalars['Int']>;
  min_near_threshold?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ground_truth_types" */
export type Ground_Truth_Types_Insert_Input = {
  default_far_threshold?: Maybe<Scalars['Int']>;
  default_near_threshold?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  max_far_threshold?: Maybe<Scalars['Int']>;
  max_near_threshold?: Maybe<Scalars['Int']>;
  min_far_threshold?: Maybe<Scalars['Int']>;
  min_near_threshold?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ground_Truth_Types_Max_Fields = {
  __typename?: 'ground_truth_types_max_fields';
  default_far_threshold?: Maybe<Scalars['Int']>;
  default_near_threshold?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  max_far_threshold?: Maybe<Scalars['Int']>;
  max_near_threshold?: Maybe<Scalars['Int']>;
  min_far_threshold?: Maybe<Scalars['Int']>;
  min_near_threshold?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ground_Truth_Types_Min_Fields = {
  __typename?: 'ground_truth_types_min_fields';
  default_far_threshold?: Maybe<Scalars['Int']>;
  default_near_threshold?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  max_far_threshold?: Maybe<Scalars['Int']>;
  max_near_threshold?: Maybe<Scalars['Int']>;
  min_far_threshold?: Maybe<Scalars['Int']>;
  min_near_threshold?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ground_truth_types" */
export type Ground_Truth_Types_Mutation_Response = {
  __typename?: 'ground_truth_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ground_Truth_Types>;
};

/** input type for inserting object relation for remote table "ground_truth_types" */
export type Ground_Truth_Types_Obj_Rel_Insert_Input = {
  data: Ground_Truth_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Ground_Truth_Types_On_Conflict>;
};

/** on_conflict condition type for table "ground_truth_types" */
export type Ground_Truth_Types_On_Conflict = {
  constraint: Ground_Truth_Types_Constraint;
  update_columns?: Array<Ground_Truth_Types_Update_Column>;
  where?: Maybe<Ground_Truth_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "ground_truth_types". */
export type Ground_Truth_Types_Order_By = {
  default_far_threshold?: Maybe<Order_By>;
  default_near_threshold?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  max_far_threshold?: Maybe<Order_By>;
  max_near_threshold?: Maybe<Order_By>;
  min_far_threshold?: Maybe<Order_By>;
  min_near_threshold?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: ground_truth_types */
export type Ground_Truth_Types_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ground_truth_types" */
export enum Ground_Truth_Types_Select_Column {
  /** column name */
  DefaultFarThreshold = 'default_far_threshold',
  /** column name */
  DefaultNearThreshold = 'default_near_threshold',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  MaxFarThreshold = 'max_far_threshold',
  /** column name */
  MaxNearThreshold = 'max_near_threshold',
  /** column name */
  MinFarThreshold = 'min_far_threshold',
  /** column name */
  MinNearThreshold = 'min_near_threshold',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ground_truth_types" */
export type Ground_Truth_Types_Set_Input = {
  default_far_threshold?: Maybe<Scalars['Int']>;
  default_near_threshold?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  max_far_threshold?: Maybe<Scalars['Int']>;
  max_near_threshold?: Maybe<Scalars['Int']>;
  min_far_threshold?: Maybe<Scalars['Int']>;
  min_near_threshold?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Ground_Truth_Types_Stddev_Fields = {
  __typename?: 'ground_truth_types_stddev_fields';
  default_far_threshold?: Maybe<Scalars['Float']>;
  default_near_threshold?: Maybe<Scalars['Float']>;
  max_far_threshold?: Maybe<Scalars['Float']>;
  max_near_threshold?: Maybe<Scalars['Float']>;
  min_far_threshold?: Maybe<Scalars['Float']>;
  min_near_threshold?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Ground_Truth_Types_Stddev_Pop_Fields = {
  __typename?: 'ground_truth_types_stddev_pop_fields';
  default_far_threshold?: Maybe<Scalars['Float']>;
  default_near_threshold?: Maybe<Scalars['Float']>;
  max_far_threshold?: Maybe<Scalars['Float']>;
  max_near_threshold?: Maybe<Scalars['Float']>;
  min_far_threshold?: Maybe<Scalars['Float']>;
  min_near_threshold?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Ground_Truth_Types_Stddev_Samp_Fields = {
  __typename?: 'ground_truth_types_stddev_samp_fields';
  default_far_threshold?: Maybe<Scalars['Float']>;
  default_near_threshold?: Maybe<Scalars['Float']>;
  max_far_threshold?: Maybe<Scalars['Float']>;
  max_near_threshold?: Maybe<Scalars['Float']>;
  min_far_threshold?: Maybe<Scalars['Float']>;
  min_near_threshold?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ground_truth_types" */
export type Ground_Truth_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ground_Truth_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ground_Truth_Types_Stream_Cursor_Value_Input = {
  default_far_threshold?: Maybe<Scalars['Int']>;
  default_near_threshold?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  max_far_threshold?: Maybe<Scalars['Int']>;
  max_near_threshold?: Maybe<Scalars['Int']>;
  min_far_threshold?: Maybe<Scalars['Int']>;
  min_near_threshold?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Ground_Truth_Types_Sum_Fields = {
  __typename?: 'ground_truth_types_sum_fields';
  default_far_threshold?: Maybe<Scalars['Int']>;
  default_near_threshold?: Maybe<Scalars['Int']>;
  max_far_threshold?: Maybe<Scalars['Int']>;
  max_near_threshold?: Maybe<Scalars['Int']>;
  min_far_threshold?: Maybe<Scalars['Int']>;
  min_near_threshold?: Maybe<Scalars['Int']>;
};

/** update columns of table "ground_truth_types" */
export enum Ground_Truth_Types_Update_Column {
  /** column name */
  DefaultFarThreshold = 'default_far_threshold',
  /** column name */
  DefaultNearThreshold = 'default_near_threshold',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  MaxFarThreshold = 'max_far_threshold',
  /** column name */
  MaxNearThreshold = 'max_near_threshold',
  /** column name */
  MinFarThreshold = 'min_far_threshold',
  /** column name */
  MinNearThreshold = 'min_near_threshold',
  /** column name */
  Name = 'name'
}

export type Ground_Truth_Types_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Ground_Truth_Types_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Ground_Truth_Types_Set_Input>;
  where: Ground_Truth_Types_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ground_Truth_Types_Var_Pop_Fields = {
  __typename?: 'ground_truth_types_var_pop_fields';
  default_far_threshold?: Maybe<Scalars['Float']>;
  default_near_threshold?: Maybe<Scalars['Float']>;
  max_far_threshold?: Maybe<Scalars['Float']>;
  max_near_threshold?: Maybe<Scalars['Float']>;
  min_far_threshold?: Maybe<Scalars['Float']>;
  min_near_threshold?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Ground_Truth_Types_Var_Samp_Fields = {
  __typename?: 'ground_truth_types_var_samp_fields';
  default_far_threshold?: Maybe<Scalars['Float']>;
  default_near_threshold?: Maybe<Scalars['Float']>;
  max_far_threshold?: Maybe<Scalars['Float']>;
  max_near_threshold?: Maybe<Scalars['Float']>;
  min_far_threshold?: Maybe<Scalars['Float']>;
  min_near_threshold?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Ground_Truth_Types_Variance_Fields = {
  __typename?: 'ground_truth_types_variance_fields';
  default_far_threshold?: Maybe<Scalars['Float']>;
  default_near_threshold?: Maybe<Scalars['Float']>;
  max_far_threshold?: Maybe<Scalars['Float']>;
  max_near_threshold?: Maybe<Scalars['Float']>;
  min_far_threshold?: Maybe<Scalars['Float']>;
  min_near_threshold?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "hypertask_field_option" */
export type Hypertask_Field_Option = {
  __typename?: 'hypertask_field_option';
  /** An object relationship */
  field: Fields;
  field_id: Scalars['uuid'];
  /** An object relationship */
  field_option?: Maybe<Field_Options>;
  /** An object relationship */
  hypertask: Hypertasks;
  hypertask_id: Scalars['uuid'];
  /** An object relationship */
  option: Options;
  option_id: Scalars['uuid'];
  weight: Scalars['float8'];
};

/** aggregated selection of "hypertask_field_option" */
export type Hypertask_Field_Option_Aggregate = {
  __typename?: 'hypertask_field_option_aggregate';
  aggregate?: Maybe<Hypertask_Field_Option_Aggregate_Fields>;
  nodes: Array<Hypertask_Field_Option>;
};

/** aggregate fields of "hypertask_field_option" */
export type Hypertask_Field_Option_Aggregate_Fields = {
  __typename?: 'hypertask_field_option_aggregate_fields';
  avg?: Maybe<Hypertask_Field_Option_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Hypertask_Field_Option_Max_Fields>;
  min?: Maybe<Hypertask_Field_Option_Min_Fields>;
  stddev?: Maybe<Hypertask_Field_Option_Stddev_Fields>;
  stddev_pop?: Maybe<Hypertask_Field_Option_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Hypertask_Field_Option_Stddev_Samp_Fields>;
  sum?: Maybe<Hypertask_Field_Option_Sum_Fields>;
  var_pop?: Maybe<Hypertask_Field_Option_Var_Pop_Fields>;
  var_samp?: Maybe<Hypertask_Field_Option_Var_Samp_Fields>;
  variance?: Maybe<Hypertask_Field_Option_Variance_Fields>;
};


/** aggregate fields of "hypertask_field_option" */
export type Hypertask_Field_Option_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Hypertask_Field_Option_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hypertask_field_option" */
export type Hypertask_Field_Option_Aggregate_Order_By = {
  avg?: Maybe<Hypertask_Field_Option_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Hypertask_Field_Option_Max_Order_By>;
  min?: Maybe<Hypertask_Field_Option_Min_Order_By>;
  stddev?: Maybe<Hypertask_Field_Option_Stddev_Order_By>;
  stddev_pop?: Maybe<Hypertask_Field_Option_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Hypertask_Field_Option_Stddev_Samp_Order_By>;
  sum?: Maybe<Hypertask_Field_Option_Sum_Order_By>;
  var_pop?: Maybe<Hypertask_Field_Option_Var_Pop_Order_By>;
  var_samp?: Maybe<Hypertask_Field_Option_Var_Samp_Order_By>;
  variance?: Maybe<Hypertask_Field_Option_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "hypertask_field_option" */
export type Hypertask_Field_Option_Arr_Rel_Insert_Input = {
  data: Array<Hypertask_Field_Option_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Hypertask_Field_Option_On_Conflict>;
};

/** aggregate avg on columns */
export type Hypertask_Field_Option_Avg_Fields = {
  __typename?: 'hypertask_field_option_avg_fields';
  weight?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "hypertask_field_option" */
export type Hypertask_Field_Option_Avg_Order_By = {
  weight?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "hypertask_field_option". All fields are combined with a logical 'AND'. */
export type Hypertask_Field_Option_Bool_Exp = {
  _and?: Maybe<Array<Hypertask_Field_Option_Bool_Exp>>;
  _not?: Maybe<Hypertask_Field_Option_Bool_Exp>;
  _or?: Maybe<Array<Hypertask_Field_Option_Bool_Exp>>;
  field?: Maybe<Fields_Bool_Exp>;
  field_id?: Maybe<Uuid_Comparison_Exp>;
  field_option?: Maybe<Field_Options_Bool_Exp>;
  hypertask?: Maybe<Hypertasks_Bool_Exp>;
  hypertask_id?: Maybe<Uuid_Comparison_Exp>;
  option?: Maybe<Options_Bool_Exp>;
  option_id?: Maybe<Uuid_Comparison_Exp>;
  weight?: Maybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "hypertask_field_option" */
export enum Hypertask_Field_Option_Constraint {
  /** unique or primary key constraint on columns "hypertask_id", "option_id", "field_id" */
  HypertaskFieldOptionPkey = 'hypertask_field_option_pkey'
}

/** input type for incrementing numeric columns in table "hypertask_field_option" */
export type Hypertask_Field_Option_Inc_Input = {
  weight?: Maybe<Scalars['float8']>;
};

/** input type for inserting data into table "hypertask_field_option" */
export type Hypertask_Field_Option_Insert_Input = {
  field?: Maybe<Fields_Obj_Rel_Insert_Input>;
  field_id?: Maybe<Scalars['uuid']>;
  field_option?: Maybe<Field_Options_Obj_Rel_Insert_Input>;
  hypertask?: Maybe<Hypertasks_Obj_Rel_Insert_Input>;
  hypertask_id?: Maybe<Scalars['uuid']>;
  option?: Maybe<Options_Obj_Rel_Insert_Input>;
  option_id?: Maybe<Scalars['uuid']>;
  weight?: Maybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type Hypertask_Field_Option_Max_Fields = {
  __typename?: 'hypertask_field_option_max_fields';
  field_id?: Maybe<Scalars['uuid']>;
  hypertask_id?: Maybe<Scalars['uuid']>;
  option_id?: Maybe<Scalars['uuid']>;
  weight?: Maybe<Scalars['float8']>;
};

/** order by max() on columns of table "hypertask_field_option" */
export type Hypertask_Field_Option_Max_Order_By = {
  field_id?: Maybe<Order_By>;
  hypertask_id?: Maybe<Order_By>;
  option_id?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Hypertask_Field_Option_Min_Fields = {
  __typename?: 'hypertask_field_option_min_fields';
  field_id?: Maybe<Scalars['uuid']>;
  hypertask_id?: Maybe<Scalars['uuid']>;
  option_id?: Maybe<Scalars['uuid']>;
  weight?: Maybe<Scalars['float8']>;
};

/** order by min() on columns of table "hypertask_field_option" */
export type Hypertask_Field_Option_Min_Order_By = {
  field_id?: Maybe<Order_By>;
  hypertask_id?: Maybe<Order_By>;
  option_id?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** response of any mutation on the table "hypertask_field_option" */
export type Hypertask_Field_Option_Mutation_Response = {
  __typename?: 'hypertask_field_option_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hypertask_Field_Option>;
};

/** on_conflict condition type for table "hypertask_field_option" */
export type Hypertask_Field_Option_On_Conflict = {
  constraint: Hypertask_Field_Option_Constraint;
  update_columns?: Array<Hypertask_Field_Option_Update_Column>;
  where?: Maybe<Hypertask_Field_Option_Bool_Exp>;
};

/** Ordering options when selecting data from "hypertask_field_option". */
export type Hypertask_Field_Option_Order_By = {
  field?: Maybe<Fields_Order_By>;
  field_id?: Maybe<Order_By>;
  field_option?: Maybe<Field_Options_Order_By>;
  hypertask?: Maybe<Hypertasks_Order_By>;
  hypertask_id?: Maybe<Order_By>;
  option?: Maybe<Options_Order_By>;
  option_id?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** primary key columns input for table: hypertask_field_option */
export type Hypertask_Field_Option_Pk_Columns_Input = {
  field_id: Scalars['uuid'];
  hypertask_id: Scalars['uuid'];
  option_id: Scalars['uuid'];
};

/** select columns of table "hypertask_field_option" */
export enum Hypertask_Field_Option_Select_Column {
  /** column name */
  FieldId = 'field_id',
  /** column name */
  HypertaskId = 'hypertask_id',
  /** column name */
  OptionId = 'option_id',
  /** column name */
  Weight = 'weight'
}

/** input type for updating data in table "hypertask_field_option" */
export type Hypertask_Field_Option_Set_Input = {
  field_id?: Maybe<Scalars['uuid']>;
  hypertask_id?: Maybe<Scalars['uuid']>;
  option_id?: Maybe<Scalars['uuid']>;
  weight?: Maybe<Scalars['float8']>;
};

/** aggregate stddev on columns */
export type Hypertask_Field_Option_Stddev_Fields = {
  __typename?: 'hypertask_field_option_stddev_fields';
  weight?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "hypertask_field_option" */
export type Hypertask_Field_Option_Stddev_Order_By = {
  weight?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Hypertask_Field_Option_Stddev_Pop_Fields = {
  __typename?: 'hypertask_field_option_stddev_pop_fields';
  weight?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "hypertask_field_option" */
export type Hypertask_Field_Option_Stddev_Pop_Order_By = {
  weight?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Hypertask_Field_Option_Stddev_Samp_Fields = {
  __typename?: 'hypertask_field_option_stddev_samp_fields';
  weight?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "hypertask_field_option" */
export type Hypertask_Field_Option_Stddev_Samp_Order_By = {
  weight?: Maybe<Order_By>;
};

/** Streaming cursor of the table "hypertask_field_option" */
export type Hypertask_Field_Option_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Hypertask_Field_Option_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hypertask_Field_Option_Stream_Cursor_Value_Input = {
  field_id?: Maybe<Scalars['uuid']>;
  hypertask_id?: Maybe<Scalars['uuid']>;
  option_id?: Maybe<Scalars['uuid']>;
  weight?: Maybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type Hypertask_Field_Option_Sum_Fields = {
  __typename?: 'hypertask_field_option_sum_fields';
  weight?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "hypertask_field_option" */
export type Hypertask_Field_Option_Sum_Order_By = {
  weight?: Maybe<Order_By>;
};

/** update columns of table "hypertask_field_option" */
export enum Hypertask_Field_Option_Update_Column {
  /** column name */
  FieldId = 'field_id',
  /** column name */
  HypertaskId = 'hypertask_id',
  /** column name */
  OptionId = 'option_id',
  /** column name */
  Weight = 'weight'
}

export type Hypertask_Field_Option_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Hypertask_Field_Option_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Hypertask_Field_Option_Set_Input>;
  where: Hypertask_Field_Option_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Hypertask_Field_Option_Var_Pop_Fields = {
  __typename?: 'hypertask_field_option_var_pop_fields';
  weight?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "hypertask_field_option" */
export type Hypertask_Field_Option_Var_Pop_Order_By = {
  weight?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Hypertask_Field_Option_Var_Samp_Fields = {
  __typename?: 'hypertask_field_option_var_samp_fields';
  weight?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "hypertask_field_option" */
export type Hypertask_Field_Option_Var_Samp_Order_By = {
  weight?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Hypertask_Field_Option_Variance_Fields = {
  __typename?: 'hypertask_field_option_variance_fields';
  weight?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "hypertask_field_option" */
export type Hypertask_Field_Option_Variance_Order_By = {
  weight?: Maybe<Order_By>;
};

/** intended only for tracking the origin of a task when generated from a hypertask */
export type Hypertask_Task = {
  __typename?: 'hypertask_task';
  /** An object relationship */
  hypertask: Hypertasks;
  hypertask_id: Scalars['uuid'];
  /** An object relationship */
  task: Tasks;
  task_id: Scalars['uuid'];
};

/** aggregated selection of "hypertask_task" */
export type Hypertask_Task_Aggregate = {
  __typename?: 'hypertask_task_aggregate';
  aggregate?: Maybe<Hypertask_Task_Aggregate_Fields>;
  nodes: Array<Hypertask_Task>;
};

/** aggregate fields of "hypertask_task" */
export type Hypertask_Task_Aggregate_Fields = {
  __typename?: 'hypertask_task_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Hypertask_Task_Max_Fields>;
  min?: Maybe<Hypertask_Task_Min_Fields>;
};


/** aggregate fields of "hypertask_task" */
export type Hypertask_Task_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Hypertask_Task_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hypertask_task" */
export type Hypertask_Task_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Hypertask_Task_Max_Order_By>;
  min?: Maybe<Hypertask_Task_Min_Order_By>;
};

/** input type for inserting array relation for remote table "hypertask_task" */
export type Hypertask_Task_Arr_Rel_Insert_Input = {
  data: Array<Hypertask_Task_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Hypertask_Task_On_Conflict>;
};

/** Boolean expression to filter rows from the table "hypertask_task". All fields are combined with a logical 'AND'. */
export type Hypertask_Task_Bool_Exp = {
  _and?: Maybe<Array<Hypertask_Task_Bool_Exp>>;
  _not?: Maybe<Hypertask_Task_Bool_Exp>;
  _or?: Maybe<Array<Hypertask_Task_Bool_Exp>>;
  hypertask?: Maybe<Hypertasks_Bool_Exp>;
  hypertask_id?: Maybe<Uuid_Comparison_Exp>;
  task?: Maybe<Tasks_Bool_Exp>;
  task_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "hypertask_task" */
export enum Hypertask_Task_Constraint {
  /** unique or primary key constraint on columns "hypertask_id", "task_id" */
  HypertaskTaskPkey = 'hypertask_task_pkey'
}

/** input type for inserting data into table "hypertask_task" */
export type Hypertask_Task_Insert_Input = {
  hypertask?: Maybe<Hypertasks_Obj_Rel_Insert_Input>;
  hypertask_id?: Maybe<Scalars['uuid']>;
  task?: Maybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Hypertask_Task_Max_Fields = {
  __typename?: 'hypertask_task_max_fields';
  hypertask_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "hypertask_task" */
export type Hypertask_Task_Max_Order_By = {
  hypertask_id?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Hypertask_Task_Min_Fields = {
  __typename?: 'hypertask_task_min_fields';
  hypertask_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "hypertask_task" */
export type Hypertask_Task_Min_Order_By = {
  hypertask_id?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "hypertask_task" */
export type Hypertask_Task_Mutation_Response = {
  __typename?: 'hypertask_task_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hypertask_Task>;
};

/** on_conflict condition type for table "hypertask_task" */
export type Hypertask_Task_On_Conflict = {
  constraint: Hypertask_Task_Constraint;
  update_columns?: Array<Hypertask_Task_Update_Column>;
  where?: Maybe<Hypertask_Task_Bool_Exp>;
};

/** Ordering options when selecting data from "hypertask_task". */
export type Hypertask_Task_Order_By = {
  hypertask?: Maybe<Hypertasks_Order_By>;
  hypertask_id?: Maybe<Order_By>;
  task?: Maybe<Tasks_Order_By>;
  task_id?: Maybe<Order_By>;
};

/** primary key columns input for table: hypertask_task */
export type Hypertask_Task_Pk_Columns_Input = {
  hypertask_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};

/** select columns of table "hypertask_task" */
export enum Hypertask_Task_Select_Column {
  /** column name */
  HypertaskId = 'hypertask_id',
  /** column name */
  TaskId = 'task_id'
}

/** input type for updating data in table "hypertask_task" */
export type Hypertask_Task_Set_Input = {
  hypertask_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "hypertask_task" */
export type Hypertask_Task_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Hypertask_Task_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hypertask_Task_Stream_Cursor_Value_Input = {
  hypertask_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "hypertask_task" */
export enum Hypertask_Task_Update_Column {
  /** column name */
  HypertaskId = 'hypertask_id',
  /** column name */
  TaskId = 'task_id'
}

export type Hypertask_Task_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Hypertask_Task_Set_Input>;
  where: Hypertask_Task_Bool_Exp;
};

/** similar to a task, but used instead as a template from which a varied set of similar tasks can be generated */
export type Hypertasks = {
  __typename?: 'hypertasks';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  hypertask_field_options: Array<Hypertask_Field_Option>;
  /** An aggregate relationship */
  hypertask_field_options_aggregate: Hypertask_Field_Option_Aggregate;
  /** An array relationship */
  hypertask_tasks: Array<Hypertask_Task>;
  /** An aggregate relationship */
  hypertask_tasks_aggregate: Hypertask_Task_Aggregate;
  id: Scalars['uuid'];
  number: Scalars['Int'];
  /** An array relationship */
  plan_hypertasks: Array<Plan_Hypertasks>;
  /** An aggregate relationship */
  plan_hypertasks_aggregate: Plan_Hypertasks_Aggregate;
  /** An array relationship */
  project_dataset: Array<Project_Dataset>;
  /** An aggregate relationship */
  project_dataset_aggregate: Project_Dataset_Aggregate;
  /** An array relationship */
  project_hypertasks: Array<Project_Hypertasks>;
  /** An aggregate relationship */
  project_hypertasks_aggregate: Project_Hypertasks_Aggregate;
  /** An array relationship */
  recordings: Array<Recordings>;
  /** An aggregate relationship */
  recordings_aggregate: Recordings_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** similar to a task, but used instead as a template from which a varied set of similar tasks can be generated */
export type HypertasksHypertask_Field_OptionsArgs = {
  distinct_on?: Maybe<Array<Hypertask_Field_Option_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hypertask_Field_Option_Order_By>>;
  where?: Maybe<Hypertask_Field_Option_Bool_Exp>;
};


/** similar to a task, but used instead as a template from which a varied set of similar tasks can be generated */
export type HypertasksHypertask_Field_Options_AggregateArgs = {
  distinct_on?: Maybe<Array<Hypertask_Field_Option_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hypertask_Field_Option_Order_By>>;
  where?: Maybe<Hypertask_Field_Option_Bool_Exp>;
};


/** similar to a task, but used instead as a template from which a varied set of similar tasks can be generated */
export type HypertasksHypertask_TasksArgs = {
  distinct_on?: Maybe<Array<Hypertask_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hypertask_Task_Order_By>>;
  where?: Maybe<Hypertask_Task_Bool_Exp>;
};


/** similar to a task, but used instead as a template from which a varied set of similar tasks can be generated */
export type HypertasksHypertask_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Hypertask_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hypertask_Task_Order_By>>;
  where?: Maybe<Hypertask_Task_Bool_Exp>;
};


/** similar to a task, but used instead as a template from which a varied set of similar tasks can be generated */
export type HypertasksPlan_HypertasksArgs = {
  distinct_on?: Maybe<Array<Plan_Hypertasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plan_Hypertasks_Order_By>>;
  where?: Maybe<Plan_Hypertasks_Bool_Exp>;
};


/** similar to a task, but used instead as a template from which a varied set of similar tasks can be generated */
export type HypertasksPlan_Hypertasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Plan_Hypertasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plan_Hypertasks_Order_By>>;
  where?: Maybe<Plan_Hypertasks_Bool_Exp>;
};


/** similar to a task, but used instead as a template from which a varied set of similar tasks can be generated */
export type HypertasksProject_DatasetArgs = {
  distinct_on?: Maybe<Array<Project_Dataset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Dataset_Order_By>>;
  where?: Maybe<Project_Dataset_Bool_Exp>;
};


/** similar to a task, but used instead as a template from which a varied set of similar tasks can be generated */
export type HypertasksProject_Dataset_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Dataset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Dataset_Order_By>>;
  where?: Maybe<Project_Dataset_Bool_Exp>;
};


/** similar to a task, but used instead as a template from which a varied set of similar tasks can be generated */
export type HypertasksProject_HypertasksArgs = {
  distinct_on?: Maybe<Array<Project_Hypertasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Hypertasks_Order_By>>;
  where?: Maybe<Project_Hypertasks_Bool_Exp>;
};


/** similar to a task, but used instead as a template from which a varied set of similar tasks can be generated */
export type HypertasksProject_Hypertasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Hypertasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Hypertasks_Order_By>>;
  where?: Maybe<Project_Hypertasks_Bool_Exp>;
};


/** similar to a task, but used instead as a template from which a varied set of similar tasks can be generated */
export type HypertasksRecordingsArgs = {
  distinct_on?: Maybe<Array<Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recordings_Order_By>>;
  where?: Maybe<Recordings_Bool_Exp>;
};


/** similar to a task, but used instead as a template from which a varied set of similar tasks can be generated */
export type HypertasksRecordings_AggregateArgs = {
  distinct_on?: Maybe<Array<Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recordings_Order_By>>;
  where?: Maybe<Recordings_Bool_Exp>;
};

/** aggregated selection of "hypertasks" */
export type Hypertasks_Aggregate = {
  __typename?: 'hypertasks_aggregate';
  aggregate?: Maybe<Hypertasks_Aggregate_Fields>;
  nodes: Array<Hypertasks>;
};

/** aggregate fields of "hypertasks" */
export type Hypertasks_Aggregate_Fields = {
  __typename?: 'hypertasks_aggregate_fields';
  avg?: Maybe<Hypertasks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Hypertasks_Max_Fields>;
  min?: Maybe<Hypertasks_Min_Fields>;
  stddev?: Maybe<Hypertasks_Stddev_Fields>;
  stddev_pop?: Maybe<Hypertasks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Hypertasks_Stddev_Samp_Fields>;
  sum?: Maybe<Hypertasks_Sum_Fields>;
  var_pop?: Maybe<Hypertasks_Var_Pop_Fields>;
  var_samp?: Maybe<Hypertasks_Var_Samp_Fields>;
  variance?: Maybe<Hypertasks_Variance_Fields>;
};


/** aggregate fields of "hypertasks" */
export type Hypertasks_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Hypertasks_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Hypertasks_Avg_Fields = {
  __typename?: 'hypertasks_avg_fields';
  number?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "hypertasks". All fields are combined with a logical 'AND'. */
export type Hypertasks_Bool_Exp = {
  _and?: Maybe<Array<Hypertasks_Bool_Exp>>;
  _not?: Maybe<Hypertasks_Bool_Exp>;
  _or?: Maybe<Array<Hypertasks_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  hypertask_field_options?: Maybe<Hypertask_Field_Option_Bool_Exp>;
  hypertask_tasks?: Maybe<Hypertask_Task_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  number?: Maybe<Int_Comparison_Exp>;
  plan_hypertasks?: Maybe<Plan_Hypertasks_Bool_Exp>;
  project_dataset?: Maybe<Project_Dataset_Bool_Exp>;
  project_hypertasks?: Maybe<Project_Hypertasks_Bool_Exp>;
  recordings?: Maybe<Recordings_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "hypertasks" */
export enum Hypertasks_Constraint {
  /** unique or primary key constraint on columns "number" */
  HypertasksNumberKey = 'hypertasks_number_key',
  /** unique or primary key constraint on columns "id" */
  HypertasksPkey = 'hypertasks_pkey'
}

/** input type for incrementing numeric columns in table "hypertasks" */
export type Hypertasks_Inc_Input = {
  number?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "hypertasks" */
export type Hypertasks_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  hypertask_field_options?: Maybe<Hypertask_Field_Option_Arr_Rel_Insert_Input>;
  hypertask_tasks?: Maybe<Hypertask_Task_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  number?: Maybe<Scalars['Int']>;
  plan_hypertasks?: Maybe<Plan_Hypertasks_Arr_Rel_Insert_Input>;
  project_hypertasks?: Maybe<Project_Hypertasks_Arr_Rel_Insert_Input>;
  recordings?: Maybe<Recordings_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Hypertasks_Max_Fields = {
  __typename?: 'hypertasks_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  number?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Hypertasks_Min_Fields = {
  __typename?: 'hypertasks_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  number?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "hypertasks" */
export type Hypertasks_Mutation_Response = {
  __typename?: 'hypertasks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hypertasks>;
};

/** input type for inserting object relation for remote table "hypertasks" */
export type Hypertasks_Obj_Rel_Insert_Input = {
  data: Hypertasks_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Hypertasks_On_Conflict>;
};

/** on_conflict condition type for table "hypertasks" */
export type Hypertasks_On_Conflict = {
  constraint: Hypertasks_Constraint;
  update_columns?: Array<Hypertasks_Update_Column>;
  where?: Maybe<Hypertasks_Bool_Exp>;
};

/** Ordering options when selecting data from "hypertasks". */
export type Hypertasks_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  hypertask_field_options_aggregate?: Maybe<Hypertask_Field_Option_Aggregate_Order_By>;
  hypertask_tasks_aggregate?: Maybe<Hypertask_Task_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  plan_hypertasks_aggregate?: Maybe<Plan_Hypertasks_Aggregate_Order_By>;
  project_dataset_aggregate?: Maybe<Project_Dataset_Aggregate_Order_By>;
  project_hypertasks_aggregate?: Maybe<Project_Hypertasks_Aggregate_Order_By>;
  recordings_aggregate?: Maybe<Recordings_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: hypertasks */
export type Hypertasks_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "hypertasks" */
export enum Hypertasks_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Number = 'number',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "hypertasks" */
export type Hypertasks_Set_Input = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  number?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Hypertasks_Stddev_Fields = {
  __typename?: 'hypertasks_stddev_fields';
  number?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Hypertasks_Stddev_Pop_Fields = {
  __typename?: 'hypertasks_stddev_pop_fields';
  number?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Hypertasks_Stddev_Samp_Fields = {
  __typename?: 'hypertasks_stddev_samp_fields';
  number?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "hypertasks" */
export type Hypertasks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Hypertasks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hypertasks_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  number?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Hypertasks_Sum_Fields = {
  __typename?: 'hypertasks_sum_fields';
  number?: Maybe<Scalars['Int']>;
};

/** update columns of table "hypertasks" */
export enum Hypertasks_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Number = 'number'
}

export type Hypertasks_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Hypertasks_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Hypertasks_Set_Input>;
  where: Hypertasks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Hypertasks_Var_Pop_Fields = {
  __typename?: 'hypertasks_var_pop_fields';
  number?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Hypertasks_Var_Samp_Fields = {
  __typename?: 'hypertasks_var_samp_fields';
  number?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Hypertasks_Variance_Fields = {
  __typename?: 'hypertasks_variance_fields';
  number?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "instruction_translations" */
export type Instruction_Translations = {
  __typename?: 'instruction_translations';
  content?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  id: Scalars['uuid'];
  language: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  task_instruction_id: Scalars['uuid'];
  /** An object relationship */
  task_instructions: Task_Instructions;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
};

/** order by aggregate values of table "instruction_translations" */
export type Instruction_Translations_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Instruction_Translations_Max_Order_By>;
  min?: Maybe<Instruction_Translations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "instruction_translations" */
export type Instruction_Translations_Arr_Rel_Insert_Input = {
  data: Array<Instruction_Translations_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Instruction_Translations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "instruction_translations". All fields are combined with a logical 'AND'. */
export type Instruction_Translations_Bool_Exp = {
  _and?: Maybe<Array<Instruction_Translations_Bool_Exp>>;
  _not?: Maybe<Instruction_Translations_Bool_Exp>;
  _or?: Maybe<Array<Instruction_Translations_Bool_Exp>>;
  content?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  language?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  task_instruction_id?: Maybe<Uuid_Comparison_Exp>;
  task_instructions?: Maybe<Task_Instructions_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "instruction_translations" */
export enum Instruction_Translations_Constraint {
  /** unique or primary key constraint on columns "task_instruction_id", "language" */
  InstructionTranslationTaskInstructionIdLanguageKey = 'instruction_translation_task_instruction_id_language_key',
  /** unique or primary key constraint on columns "id" */
  InstructionTranslationsPkey = 'instruction_translations_pkey'
}

/** input type for inserting data into table "instruction_translations" */
export type Instruction_Translations_Insert_Input = {
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  task_instruction_id?: Maybe<Scalars['uuid']>;
  task_instructions?: Maybe<Task_Instructions_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
};

/** order by max() on columns of table "instruction_translations" */
export type Instruction_Translations_Max_Order_By = {
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  task_instruction_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** order by min() on columns of table "instruction_translations" */
export type Instruction_Translations_Min_Order_By = {
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  task_instruction_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "instruction_translations" */
export type Instruction_Translations_Mutation_Response = {
  __typename?: 'instruction_translations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Instruction_Translations>;
};

/** on_conflict condition type for table "instruction_translations" */
export type Instruction_Translations_On_Conflict = {
  constraint: Instruction_Translations_Constraint;
  update_columns?: Array<Instruction_Translations_Update_Column>;
  where?: Maybe<Instruction_Translations_Bool_Exp>;
};

/** Ordering options when selecting data from "instruction_translations". */
export type Instruction_Translations_Order_By = {
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  task_instruction_id?: Maybe<Order_By>;
  task_instructions?: Maybe<Task_Instructions_Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
};

/** primary key columns input for table: instruction_translations */
export type Instruction_Translations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "instruction_translations" */
export enum Instruction_Translations_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Status = 'status',
  /** column name */
  TaskInstructionId = 'task_instruction_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "instruction_translations" */
export type Instruction_Translations_Set_Input = {
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  task_instruction_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "instruction_translations" */
export type Instruction_Translations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Instruction_Translations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Instruction_Translations_Stream_Cursor_Value_Input = {
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  task_instruction_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "instruction_translations" */
export enum Instruction_Translations_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Status = 'status',
  /** column name */
  TaskInstructionId = 'task_instruction_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Instruction_Translations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Instruction_Translations_Set_Input>;
  where: Instruction_Translations_Bool_Exp;
};

/** columns and relationships of "integration_test.expectation_media" */
export type Integration_Test_Expectation_Media = {
  __typename?: 'integration_test_expectation_media';
  /** An object relationship */
  expectation: Integration_Test_Expectations;
  expectation_id: Scalars['uuid'];
  media_id: Scalars['uuid'];
  /** An object relationship */
  medium: Media;
};

/** aggregated selection of "integration_test.expectation_media" */
export type Integration_Test_Expectation_Media_Aggregate = {
  __typename?: 'integration_test_expectation_media_aggregate';
  aggregate?: Maybe<Integration_Test_Expectation_Media_Aggregate_Fields>;
  nodes: Array<Integration_Test_Expectation_Media>;
};

/** aggregate fields of "integration_test.expectation_media" */
export type Integration_Test_Expectation_Media_Aggregate_Fields = {
  __typename?: 'integration_test_expectation_media_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Integration_Test_Expectation_Media_Max_Fields>;
  min?: Maybe<Integration_Test_Expectation_Media_Min_Fields>;
};


/** aggregate fields of "integration_test.expectation_media" */
export type Integration_Test_Expectation_Media_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Integration_Test_Expectation_Media_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "integration_test.expectation_media" */
export type Integration_Test_Expectation_Media_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Integration_Test_Expectation_Media_Max_Order_By>;
  min?: Maybe<Integration_Test_Expectation_Media_Min_Order_By>;
};

/** input type for inserting array relation for remote table "integration_test.expectation_media" */
export type Integration_Test_Expectation_Media_Arr_Rel_Insert_Input = {
  data: Array<Integration_Test_Expectation_Media_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Integration_Test_Expectation_Media_On_Conflict>;
};

/** Boolean expression to filter rows from the table "integration_test.expectation_media". All fields are combined with a logical 'AND'. */
export type Integration_Test_Expectation_Media_Bool_Exp = {
  _and?: Maybe<Array<Integration_Test_Expectation_Media_Bool_Exp>>;
  _not?: Maybe<Integration_Test_Expectation_Media_Bool_Exp>;
  _or?: Maybe<Array<Integration_Test_Expectation_Media_Bool_Exp>>;
  expectation?: Maybe<Integration_Test_Expectations_Bool_Exp>;
  expectation_id?: Maybe<Uuid_Comparison_Exp>;
  media_id?: Maybe<Uuid_Comparison_Exp>;
  medium?: Maybe<Media_Bool_Exp>;
};

/** unique or primary key constraints on table "integration_test.expectation_media" */
export enum Integration_Test_Expectation_Media_Constraint {
  /** unique or primary key constraint on columns "expectation_id", "media_id" */
  ExpectationMediaPkey = 'expectation_media_pkey'
}

/** input type for inserting data into table "integration_test.expectation_media" */
export type Integration_Test_Expectation_Media_Insert_Input = {
  expectation?: Maybe<Integration_Test_Expectations_Obj_Rel_Insert_Input>;
  expectation_id?: Maybe<Scalars['uuid']>;
  media_id?: Maybe<Scalars['uuid']>;
  medium?: Maybe<Media_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Integration_Test_Expectation_Media_Max_Fields = {
  __typename?: 'integration_test_expectation_media_max_fields';
  expectation_id?: Maybe<Scalars['uuid']>;
  media_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "integration_test.expectation_media" */
export type Integration_Test_Expectation_Media_Max_Order_By = {
  expectation_id?: Maybe<Order_By>;
  media_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Integration_Test_Expectation_Media_Min_Fields = {
  __typename?: 'integration_test_expectation_media_min_fields';
  expectation_id?: Maybe<Scalars['uuid']>;
  media_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "integration_test.expectation_media" */
export type Integration_Test_Expectation_Media_Min_Order_By = {
  expectation_id?: Maybe<Order_By>;
  media_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "integration_test.expectation_media" */
export type Integration_Test_Expectation_Media_Mutation_Response = {
  __typename?: 'integration_test_expectation_media_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Integration_Test_Expectation_Media>;
};

/** on_conflict condition type for table "integration_test.expectation_media" */
export type Integration_Test_Expectation_Media_On_Conflict = {
  constraint: Integration_Test_Expectation_Media_Constraint;
  update_columns?: Array<Integration_Test_Expectation_Media_Update_Column>;
  where?: Maybe<Integration_Test_Expectation_Media_Bool_Exp>;
};

/** Ordering options when selecting data from "integration_test.expectation_media". */
export type Integration_Test_Expectation_Media_Order_By = {
  expectation?: Maybe<Integration_Test_Expectations_Order_By>;
  expectation_id?: Maybe<Order_By>;
  media_id?: Maybe<Order_By>;
  medium?: Maybe<Media_Order_By>;
};

/** primary key columns input for table: integration_test.expectation_media */
export type Integration_Test_Expectation_Media_Pk_Columns_Input = {
  expectation_id: Scalars['uuid'];
  media_id: Scalars['uuid'];
};

/** select columns of table "integration_test.expectation_media" */
export enum Integration_Test_Expectation_Media_Select_Column {
  /** column name */
  ExpectationId = 'expectation_id',
  /** column name */
  MediaId = 'media_id'
}

/** input type for updating data in table "integration_test.expectation_media" */
export type Integration_Test_Expectation_Media_Set_Input = {
  expectation_id?: Maybe<Scalars['uuid']>;
  media_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "integration_test_expectation_media" */
export type Integration_Test_Expectation_Media_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Integration_Test_Expectation_Media_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Integration_Test_Expectation_Media_Stream_Cursor_Value_Input = {
  expectation_id?: Maybe<Scalars['uuid']>;
  media_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "integration_test.expectation_media" */
export enum Integration_Test_Expectation_Media_Update_Column {
  /** column name */
  ExpectationId = 'expectation_id',
  /** column name */
  MediaId = 'media_id'
}

export type Integration_Test_Expectation_Media_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Integration_Test_Expectation_Media_Set_Input>;
  where: Integration_Test_Expectation_Media_Bool_Exp;
};

/** columns and relationships of "integration_test.expectation_step" */
export type Integration_Test_Expectation_Step = {
  __typename?: 'integration_test_expectation_step';
  /** An object relationship */
  expectation: Integration_Test_Expectations;
  expectation_id: Scalars['uuid'];
  /** An array relationship */
  observations: Array<Integration_Test_Observations>;
  /** An aggregate relationship */
  observations_aggregate: Integration_Test_Observations_Aggregate;
  /** An object relationship */
  step: Integration_Test_Steps;
  step_id: Scalars['uuid'];
};


/** columns and relationships of "integration_test.expectation_step" */
export type Integration_Test_Expectation_StepObservationsArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Observations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Observations_Order_By>>;
  where?: Maybe<Integration_Test_Observations_Bool_Exp>;
};


/** columns and relationships of "integration_test.expectation_step" */
export type Integration_Test_Expectation_StepObservations_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Observations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Observations_Order_By>>;
  where?: Maybe<Integration_Test_Observations_Bool_Exp>;
};

/** aggregated selection of "integration_test.expectation_step" */
export type Integration_Test_Expectation_Step_Aggregate = {
  __typename?: 'integration_test_expectation_step_aggregate';
  aggregate?: Maybe<Integration_Test_Expectation_Step_Aggregate_Fields>;
  nodes: Array<Integration_Test_Expectation_Step>;
};

/** aggregate fields of "integration_test.expectation_step" */
export type Integration_Test_Expectation_Step_Aggregate_Fields = {
  __typename?: 'integration_test_expectation_step_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Integration_Test_Expectation_Step_Max_Fields>;
  min?: Maybe<Integration_Test_Expectation_Step_Min_Fields>;
};


/** aggregate fields of "integration_test.expectation_step" */
export type Integration_Test_Expectation_Step_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Integration_Test_Expectation_Step_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "integration_test.expectation_step" */
export type Integration_Test_Expectation_Step_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Integration_Test_Expectation_Step_Max_Order_By>;
  min?: Maybe<Integration_Test_Expectation_Step_Min_Order_By>;
};

/** input type for inserting array relation for remote table "integration_test.expectation_step" */
export type Integration_Test_Expectation_Step_Arr_Rel_Insert_Input = {
  data: Array<Integration_Test_Expectation_Step_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Integration_Test_Expectation_Step_On_Conflict>;
};

/** Boolean expression to filter rows from the table "integration_test.expectation_step". All fields are combined with a logical 'AND'. */
export type Integration_Test_Expectation_Step_Bool_Exp = {
  _and?: Maybe<Array<Integration_Test_Expectation_Step_Bool_Exp>>;
  _not?: Maybe<Integration_Test_Expectation_Step_Bool_Exp>;
  _or?: Maybe<Array<Integration_Test_Expectation_Step_Bool_Exp>>;
  expectation?: Maybe<Integration_Test_Expectations_Bool_Exp>;
  expectation_id?: Maybe<Uuid_Comparison_Exp>;
  observations?: Maybe<Integration_Test_Observations_Bool_Exp>;
  step?: Maybe<Integration_Test_Steps_Bool_Exp>;
  step_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "integration_test.expectation_step" */
export enum Integration_Test_Expectation_Step_Constraint {
  /** unique or primary key constraint on columns "expectation_id", "step_id" */
  ExpectationStepPkey = 'expectation_step_pkey'
}

/** input type for inserting data into table "integration_test.expectation_step" */
export type Integration_Test_Expectation_Step_Insert_Input = {
  expectation?: Maybe<Integration_Test_Expectations_Obj_Rel_Insert_Input>;
  expectation_id?: Maybe<Scalars['uuid']>;
  observations?: Maybe<Integration_Test_Observations_Arr_Rel_Insert_Input>;
  step?: Maybe<Integration_Test_Steps_Obj_Rel_Insert_Input>;
  step_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Integration_Test_Expectation_Step_Max_Fields = {
  __typename?: 'integration_test_expectation_step_max_fields';
  expectation_id?: Maybe<Scalars['uuid']>;
  step_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "integration_test.expectation_step" */
export type Integration_Test_Expectation_Step_Max_Order_By = {
  expectation_id?: Maybe<Order_By>;
  step_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Integration_Test_Expectation_Step_Min_Fields = {
  __typename?: 'integration_test_expectation_step_min_fields';
  expectation_id?: Maybe<Scalars['uuid']>;
  step_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "integration_test.expectation_step" */
export type Integration_Test_Expectation_Step_Min_Order_By = {
  expectation_id?: Maybe<Order_By>;
  step_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "integration_test.expectation_step" */
export type Integration_Test_Expectation_Step_Mutation_Response = {
  __typename?: 'integration_test_expectation_step_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Integration_Test_Expectation_Step>;
};

/** input type for inserting object relation for remote table "integration_test.expectation_step" */
export type Integration_Test_Expectation_Step_Obj_Rel_Insert_Input = {
  data: Integration_Test_Expectation_Step_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Integration_Test_Expectation_Step_On_Conflict>;
};

/** on_conflict condition type for table "integration_test.expectation_step" */
export type Integration_Test_Expectation_Step_On_Conflict = {
  constraint: Integration_Test_Expectation_Step_Constraint;
  update_columns?: Array<Integration_Test_Expectation_Step_Update_Column>;
  where?: Maybe<Integration_Test_Expectation_Step_Bool_Exp>;
};

/** Ordering options when selecting data from "integration_test.expectation_step". */
export type Integration_Test_Expectation_Step_Order_By = {
  expectation?: Maybe<Integration_Test_Expectations_Order_By>;
  expectation_id?: Maybe<Order_By>;
  observations_aggregate?: Maybe<Integration_Test_Observations_Aggregate_Order_By>;
  step?: Maybe<Integration_Test_Steps_Order_By>;
  step_id?: Maybe<Order_By>;
};

/** select columns of table "integration_test.expectation_step" */
export enum Integration_Test_Expectation_Step_Select_Column {
  /** column name */
  ExpectationId = 'expectation_id',
  /** column name */
  StepId = 'step_id'
}

/** Streaming cursor of the table "integration_test_expectation_step" */
export type Integration_Test_Expectation_Step_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Integration_Test_Expectation_Step_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Integration_Test_Expectation_Step_Stream_Cursor_Value_Input = {
  expectation_id?: Maybe<Scalars['uuid']>;
  step_id?: Maybe<Scalars['uuid']>;
};

/** placeholder for update columns of table "integration_test.expectation_step" (current role has no relevant permissions) */
export enum Integration_Test_Expectation_Step_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** columns and relationships of "integration_test.expectation_translation" */
export type Integration_Test_Expectation_Translation = {
  __typename?: 'integration_test_expectation_translation';
  description: Scalars['String'];
  /** An object relationship */
  expectation: Integration_Test_Expectations;
  expectation_id: Scalars['uuid'];
  /** An object relationship */
  expectation_translation_language: Languages;
  language: Scalars['String'];
};

/** aggregated selection of "integration_test.expectation_translation" */
export type Integration_Test_Expectation_Translation_Aggregate = {
  __typename?: 'integration_test_expectation_translation_aggregate';
  aggregate?: Maybe<Integration_Test_Expectation_Translation_Aggregate_Fields>;
  nodes: Array<Integration_Test_Expectation_Translation>;
};

/** aggregate fields of "integration_test.expectation_translation" */
export type Integration_Test_Expectation_Translation_Aggregate_Fields = {
  __typename?: 'integration_test_expectation_translation_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Integration_Test_Expectation_Translation_Max_Fields>;
  min?: Maybe<Integration_Test_Expectation_Translation_Min_Fields>;
};


/** aggregate fields of "integration_test.expectation_translation" */
export type Integration_Test_Expectation_Translation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Integration_Test_Expectation_Translation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "integration_test.expectation_translation" */
export type Integration_Test_Expectation_Translation_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Integration_Test_Expectation_Translation_Max_Order_By>;
  min?: Maybe<Integration_Test_Expectation_Translation_Min_Order_By>;
};

/** input type for inserting array relation for remote table "integration_test.expectation_translation" */
export type Integration_Test_Expectation_Translation_Arr_Rel_Insert_Input = {
  data: Array<Integration_Test_Expectation_Translation_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Integration_Test_Expectation_Translation_On_Conflict>;
};

/** Boolean expression to filter rows from the table "integration_test.expectation_translation". All fields are combined with a logical 'AND'. */
export type Integration_Test_Expectation_Translation_Bool_Exp = {
  _and?: Maybe<Array<Integration_Test_Expectation_Translation_Bool_Exp>>;
  _not?: Maybe<Integration_Test_Expectation_Translation_Bool_Exp>;
  _or?: Maybe<Array<Integration_Test_Expectation_Translation_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  expectation?: Maybe<Integration_Test_Expectations_Bool_Exp>;
  expectation_id?: Maybe<Uuid_Comparison_Exp>;
  expectation_translation_language?: Maybe<Languages_Bool_Exp>;
  language?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "integration_test.expectation_translation" */
export enum Integration_Test_Expectation_Translation_Constraint {
  /** unique or primary key constraint on columns "expectation_id", "description" */
  ExpectationTranslationExpectationIdDescriptionKey = 'expectation_translation_expectation_id_description_key',
  /** unique or primary key constraint on columns "expectation_id", "language" */
  ExpectationTranslationPkey = 'expectation_translation_pkey'
}

/** input type for inserting data into table "integration_test.expectation_translation" */
export type Integration_Test_Expectation_Translation_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  expectation?: Maybe<Integration_Test_Expectations_Obj_Rel_Insert_Input>;
  expectation_id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Integration_Test_Expectation_Translation_Max_Fields = {
  __typename?: 'integration_test_expectation_translation_max_fields';
  description?: Maybe<Scalars['String']>;
  expectation_id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "integration_test.expectation_translation" */
export type Integration_Test_Expectation_Translation_Max_Order_By = {
  description?: Maybe<Order_By>;
  expectation_id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Integration_Test_Expectation_Translation_Min_Fields = {
  __typename?: 'integration_test_expectation_translation_min_fields';
  description?: Maybe<Scalars['String']>;
  expectation_id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "integration_test.expectation_translation" */
export type Integration_Test_Expectation_Translation_Min_Order_By = {
  description?: Maybe<Order_By>;
  expectation_id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
};

/** response of any mutation on the table "integration_test.expectation_translation" */
export type Integration_Test_Expectation_Translation_Mutation_Response = {
  __typename?: 'integration_test_expectation_translation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Integration_Test_Expectation_Translation>;
};

/** on_conflict condition type for table "integration_test.expectation_translation" */
export type Integration_Test_Expectation_Translation_On_Conflict = {
  constraint: Integration_Test_Expectation_Translation_Constraint;
  update_columns?: Array<Integration_Test_Expectation_Translation_Update_Column>;
  where?: Maybe<Integration_Test_Expectation_Translation_Bool_Exp>;
};

/** Ordering options when selecting data from "integration_test.expectation_translation". */
export type Integration_Test_Expectation_Translation_Order_By = {
  description?: Maybe<Order_By>;
  expectation?: Maybe<Integration_Test_Expectations_Order_By>;
  expectation_id?: Maybe<Order_By>;
  expectation_translation_language?: Maybe<Languages_Order_By>;
  language?: Maybe<Order_By>;
};

/** primary key columns input for table: integration_test.expectation_translation */
export type Integration_Test_Expectation_Translation_Pk_Columns_Input = {
  expectation_id: Scalars['uuid'];
  language: Scalars['String'];
};

/** select columns of table "integration_test.expectation_translation" */
export enum Integration_Test_Expectation_Translation_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  ExpectationId = 'expectation_id',
  /** column name */
  Language = 'language'
}

/** input type for updating data in table "integration_test.expectation_translation" */
export type Integration_Test_Expectation_Translation_Set_Input = {
  description?: Maybe<Scalars['String']>;
  expectation_id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "integration_test_expectation_translation" */
export type Integration_Test_Expectation_Translation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Integration_Test_Expectation_Translation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Integration_Test_Expectation_Translation_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>;
  expectation_id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
};

/** update columns of table "integration_test.expectation_translation" */
export enum Integration_Test_Expectation_Translation_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  ExpectationId = 'expectation_id',
  /** column name */
  Language = 'language'
}

export type Integration_Test_Expectation_Translation_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Integration_Test_Expectation_Translation_Set_Input>;
  where: Integration_Test_Expectation_Translation_Bool_Exp;
};

/** columns and relationships of "integration_test.expectations" */
export type Integration_Test_Expectations = {
  __typename?: 'integration_test_expectations';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  /** An array relationship */
  expectation_media: Array<Integration_Test_Expectation_Media>;
  /** An aggregate relationship */
  expectation_media_aggregate: Integration_Test_Expectation_Media_Aggregate;
  /** An array relationship */
  expectation_steps: Array<Integration_Test_Expectation_Step>;
  /** An aggregate relationship */
  expectation_steps_aggregate: Integration_Test_Expectation_Step_Aggregate;
  /** An array relationship */
  expectation_translations: Array<Integration_Test_Expectation_Translation>;
  /** An aggregate relationship */
  expectation_translations_aggregate: Integration_Test_Expectation_Translation_Aggregate;
  id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "integration_test.expectations" */
export type Integration_Test_ExpectationsExpectation_MediaArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Expectation_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Expectation_Media_Order_By>>;
  where?: Maybe<Integration_Test_Expectation_Media_Bool_Exp>;
};


/** columns and relationships of "integration_test.expectations" */
export type Integration_Test_ExpectationsExpectation_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Expectation_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Expectation_Media_Order_By>>;
  where?: Maybe<Integration_Test_Expectation_Media_Bool_Exp>;
};


/** columns and relationships of "integration_test.expectations" */
export type Integration_Test_ExpectationsExpectation_StepsArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Expectation_Step_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Expectation_Step_Order_By>>;
  where?: Maybe<Integration_Test_Expectation_Step_Bool_Exp>;
};


/** columns and relationships of "integration_test.expectations" */
export type Integration_Test_ExpectationsExpectation_Steps_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Expectation_Step_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Expectation_Step_Order_By>>;
  where?: Maybe<Integration_Test_Expectation_Step_Bool_Exp>;
};


/** columns and relationships of "integration_test.expectations" */
export type Integration_Test_ExpectationsExpectation_TranslationsArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Expectation_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Expectation_Translation_Order_By>>;
  where?: Maybe<Integration_Test_Expectation_Translation_Bool_Exp>;
};


/** columns and relationships of "integration_test.expectations" */
export type Integration_Test_ExpectationsExpectation_Translations_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Expectation_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Expectation_Translation_Order_By>>;
  where?: Maybe<Integration_Test_Expectation_Translation_Bool_Exp>;
};

/** aggregated selection of "integration_test.expectations" */
export type Integration_Test_Expectations_Aggregate = {
  __typename?: 'integration_test_expectations_aggregate';
  aggregate?: Maybe<Integration_Test_Expectations_Aggregate_Fields>;
  nodes: Array<Integration_Test_Expectations>;
};

/** aggregate fields of "integration_test.expectations" */
export type Integration_Test_Expectations_Aggregate_Fields = {
  __typename?: 'integration_test_expectations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Integration_Test_Expectations_Max_Fields>;
  min?: Maybe<Integration_Test_Expectations_Min_Fields>;
};


/** aggregate fields of "integration_test.expectations" */
export type Integration_Test_Expectations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Integration_Test_Expectations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "integration_test.expectations". All fields are combined with a logical 'AND'. */
export type Integration_Test_Expectations_Bool_Exp = {
  _and?: Maybe<Array<Integration_Test_Expectations_Bool_Exp>>;
  _not?: Maybe<Integration_Test_Expectations_Bool_Exp>;
  _or?: Maybe<Array<Integration_Test_Expectations_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  expectation_media?: Maybe<Integration_Test_Expectation_Media_Bool_Exp>;
  expectation_steps?: Maybe<Integration_Test_Expectation_Step_Bool_Exp>;
  expectation_translations?: Maybe<Integration_Test_Expectation_Translation_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "integration_test.expectations" */
export enum Integration_Test_Expectations_Constraint {
  /** unique or primary key constraint on columns "description" */
  ExpectationsDescriptionKey = 'expectations_description_key',
  /** unique or primary key constraint on columns "id" */
  ExpectationsPkey = 'expectations_pkey'
}

/** input type for inserting data into table "integration_test.expectations" */
export type Integration_Test_Expectations_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  expectation_media?: Maybe<Integration_Test_Expectation_Media_Arr_Rel_Insert_Input>;
  expectation_steps?: Maybe<Integration_Test_Expectation_Step_Arr_Rel_Insert_Input>;
  expectation_translations?: Maybe<Integration_Test_Expectation_Translation_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Integration_Test_Expectations_Max_Fields = {
  __typename?: 'integration_test_expectations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Integration_Test_Expectations_Min_Fields = {
  __typename?: 'integration_test_expectations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "integration_test.expectations" */
export type Integration_Test_Expectations_Mutation_Response = {
  __typename?: 'integration_test_expectations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Integration_Test_Expectations>;
};

/** input type for inserting object relation for remote table "integration_test.expectations" */
export type Integration_Test_Expectations_Obj_Rel_Insert_Input = {
  data: Integration_Test_Expectations_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Integration_Test_Expectations_On_Conflict>;
};

/** on_conflict condition type for table "integration_test.expectations" */
export type Integration_Test_Expectations_On_Conflict = {
  constraint: Integration_Test_Expectations_Constraint;
  update_columns?: Array<Integration_Test_Expectations_Update_Column>;
  where?: Maybe<Integration_Test_Expectations_Bool_Exp>;
};

/** Ordering options when selecting data from "integration_test.expectations". */
export type Integration_Test_Expectations_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  expectation_media_aggregate?: Maybe<Integration_Test_Expectation_Media_Aggregate_Order_By>;
  expectation_steps_aggregate?: Maybe<Integration_Test_Expectation_Step_Aggregate_Order_By>;
  expectation_translations_aggregate?: Maybe<Integration_Test_Expectation_Translation_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: integration_test.expectations */
export type Integration_Test_Expectations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "integration_test.expectations" */
export enum Integration_Test_Expectations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "integration_test.expectations" */
export type Integration_Test_Expectations_Set_Input = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "integration_test_expectations" */
export type Integration_Test_Expectations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Integration_Test_Expectations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Integration_Test_Expectations_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "integration_test.expectations" */
export enum Integration_Test_Expectations_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description'
}

export type Integration_Test_Expectations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Integration_Test_Expectations_Set_Input>;
  where: Integration_Test_Expectations_Bool_Exp;
};

/** columns and relationships of "integration_test.instruction_media" */
export type Integration_Test_Instruction_Media = {
  __typename?: 'integration_test_instruction_media';
  /** An object relationship */
  instruction: Integration_Test_Instructions;
  instruction_id: Scalars['uuid'];
  media_id: Scalars['uuid'];
  /** An object relationship */
  medium: Media;
};

/** aggregated selection of "integration_test.instruction_media" */
export type Integration_Test_Instruction_Media_Aggregate = {
  __typename?: 'integration_test_instruction_media_aggregate';
  aggregate?: Maybe<Integration_Test_Instruction_Media_Aggregate_Fields>;
  nodes: Array<Integration_Test_Instruction_Media>;
};

/** aggregate fields of "integration_test.instruction_media" */
export type Integration_Test_Instruction_Media_Aggregate_Fields = {
  __typename?: 'integration_test_instruction_media_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Integration_Test_Instruction_Media_Max_Fields>;
  min?: Maybe<Integration_Test_Instruction_Media_Min_Fields>;
};


/** aggregate fields of "integration_test.instruction_media" */
export type Integration_Test_Instruction_Media_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Integration_Test_Instruction_Media_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "integration_test.instruction_media" */
export type Integration_Test_Instruction_Media_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Integration_Test_Instruction_Media_Max_Order_By>;
  min?: Maybe<Integration_Test_Instruction_Media_Min_Order_By>;
};

/** input type for inserting array relation for remote table "integration_test.instruction_media" */
export type Integration_Test_Instruction_Media_Arr_Rel_Insert_Input = {
  data: Array<Integration_Test_Instruction_Media_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Integration_Test_Instruction_Media_On_Conflict>;
};

/** Boolean expression to filter rows from the table "integration_test.instruction_media". All fields are combined with a logical 'AND'. */
export type Integration_Test_Instruction_Media_Bool_Exp = {
  _and?: Maybe<Array<Integration_Test_Instruction_Media_Bool_Exp>>;
  _not?: Maybe<Integration_Test_Instruction_Media_Bool_Exp>;
  _or?: Maybe<Array<Integration_Test_Instruction_Media_Bool_Exp>>;
  instruction?: Maybe<Integration_Test_Instructions_Bool_Exp>;
  instruction_id?: Maybe<Uuid_Comparison_Exp>;
  media_id?: Maybe<Uuid_Comparison_Exp>;
  medium?: Maybe<Media_Bool_Exp>;
};

/** unique or primary key constraints on table "integration_test.instruction_media" */
export enum Integration_Test_Instruction_Media_Constraint {
  /** unique or primary key constraint on columns "media_id", "instruction_id" */
  InstructionMediaPkey = 'instruction_media_pkey'
}

/** input type for inserting data into table "integration_test.instruction_media" */
export type Integration_Test_Instruction_Media_Insert_Input = {
  instruction?: Maybe<Integration_Test_Instructions_Obj_Rel_Insert_Input>;
  instruction_id?: Maybe<Scalars['uuid']>;
  media_id?: Maybe<Scalars['uuid']>;
  medium?: Maybe<Media_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Integration_Test_Instruction_Media_Max_Fields = {
  __typename?: 'integration_test_instruction_media_max_fields';
  instruction_id?: Maybe<Scalars['uuid']>;
  media_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "integration_test.instruction_media" */
export type Integration_Test_Instruction_Media_Max_Order_By = {
  instruction_id?: Maybe<Order_By>;
  media_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Integration_Test_Instruction_Media_Min_Fields = {
  __typename?: 'integration_test_instruction_media_min_fields';
  instruction_id?: Maybe<Scalars['uuid']>;
  media_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "integration_test.instruction_media" */
export type Integration_Test_Instruction_Media_Min_Order_By = {
  instruction_id?: Maybe<Order_By>;
  media_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "integration_test.instruction_media" */
export type Integration_Test_Instruction_Media_Mutation_Response = {
  __typename?: 'integration_test_instruction_media_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Integration_Test_Instruction_Media>;
};

/** on_conflict condition type for table "integration_test.instruction_media" */
export type Integration_Test_Instruction_Media_On_Conflict = {
  constraint: Integration_Test_Instruction_Media_Constraint;
  update_columns?: Array<Integration_Test_Instruction_Media_Update_Column>;
  where?: Maybe<Integration_Test_Instruction_Media_Bool_Exp>;
};

/** Ordering options when selecting data from "integration_test.instruction_media". */
export type Integration_Test_Instruction_Media_Order_By = {
  instruction?: Maybe<Integration_Test_Instructions_Order_By>;
  instruction_id?: Maybe<Order_By>;
  media_id?: Maybe<Order_By>;
  medium?: Maybe<Media_Order_By>;
};

/** primary key columns input for table: integration_test.instruction_media */
export type Integration_Test_Instruction_Media_Pk_Columns_Input = {
  instruction_id: Scalars['uuid'];
  media_id: Scalars['uuid'];
};

/** select columns of table "integration_test.instruction_media" */
export enum Integration_Test_Instruction_Media_Select_Column {
  /** column name */
  InstructionId = 'instruction_id',
  /** column name */
  MediaId = 'media_id'
}

/** input type for updating data in table "integration_test.instruction_media" */
export type Integration_Test_Instruction_Media_Set_Input = {
  instruction_id?: Maybe<Scalars['uuid']>;
  media_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "integration_test_instruction_media" */
export type Integration_Test_Instruction_Media_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Integration_Test_Instruction_Media_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Integration_Test_Instruction_Media_Stream_Cursor_Value_Input = {
  instruction_id?: Maybe<Scalars['uuid']>;
  media_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "integration_test.instruction_media" */
export enum Integration_Test_Instruction_Media_Update_Column {
  /** column name */
  InstructionId = 'instruction_id',
  /** column name */
  MediaId = 'media_id'
}

export type Integration_Test_Instruction_Media_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Integration_Test_Instruction_Media_Set_Input>;
  where: Integration_Test_Instruction_Media_Bool_Exp;
};

/** columns and relationships of "integration_test.instruction_translation" */
export type Integration_Test_Instruction_Translation = {
  __typename?: 'integration_test_instruction_translation';
  description: Scalars['String'];
  /** An object relationship */
  instruction: Integration_Test_Instructions;
  instruction_id: Scalars['uuid'];
  /** An object relationship */
  instruction_translation_language: Languages;
  language: Scalars['String'];
};

/** aggregated selection of "integration_test.instruction_translation" */
export type Integration_Test_Instruction_Translation_Aggregate = {
  __typename?: 'integration_test_instruction_translation_aggregate';
  aggregate?: Maybe<Integration_Test_Instruction_Translation_Aggregate_Fields>;
  nodes: Array<Integration_Test_Instruction_Translation>;
};

/** aggregate fields of "integration_test.instruction_translation" */
export type Integration_Test_Instruction_Translation_Aggregate_Fields = {
  __typename?: 'integration_test_instruction_translation_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Integration_Test_Instruction_Translation_Max_Fields>;
  min?: Maybe<Integration_Test_Instruction_Translation_Min_Fields>;
};


/** aggregate fields of "integration_test.instruction_translation" */
export type Integration_Test_Instruction_Translation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Integration_Test_Instruction_Translation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "integration_test.instruction_translation" */
export type Integration_Test_Instruction_Translation_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Integration_Test_Instruction_Translation_Max_Order_By>;
  min?: Maybe<Integration_Test_Instruction_Translation_Min_Order_By>;
};

/** input type for inserting array relation for remote table "integration_test.instruction_translation" */
export type Integration_Test_Instruction_Translation_Arr_Rel_Insert_Input = {
  data: Array<Integration_Test_Instruction_Translation_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Integration_Test_Instruction_Translation_On_Conflict>;
};

/** Boolean expression to filter rows from the table "integration_test.instruction_translation". All fields are combined with a logical 'AND'. */
export type Integration_Test_Instruction_Translation_Bool_Exp = {
  _and?: Maybe<Array<Integration_Test_Instruction_Translation_Bool_Exp>>;
  _not?: Maybe<Integration_Test_Instruction_Translation_Bool_Exp>;
  _or?: Maybe<Array<Integration_Test_Instruction_Translation_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  instruction?: Maybe<Integration_Test_Instructions_Bool_Exp>;
  instruction_id?: Maybe<Uuid_Comparison_Exp>;
  instruction_translation_language?: Maybe<Languages_Bool_Exp>;
  language?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "integration_test.instruction_translation" */
export enum Integration_Test_Instruction_Translation_Constraint {
  /** unique or primary key constraint on columns "instruction_id", "language" */
  InstructionTranslationPkey = 'instruction_translation_pkey'
}

/** input type for inserting data into table "integration_test.instruction_translation" */
export type Integration_Test_Instruction_Translation_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  instruction?: Maybe<Integration_Test_Instructions_Obj_Rel_Insert_Input>;
  instruction_id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Integration_Test_Instruction_Translation_Max_Fields = {
  __typename?: 'integration_test_instruction_translation_max_fields';
  description?: Maybe<Scalars['String']>;
  instruction_id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "integration_test.instruction_translation" */
export type Integration_Test_Instruction_Translation_Max_Order_By = {
  description?: Maybe<Order_By>;
  instruction_id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Integration_Test_Instruction_Translation_Min_Fields = {
  __typename?: 'integration_test_instruction_translation_min_fields';
  description?: Maybe<Scalars['String']>;
  instruction_id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "integration_test.instruction_translation" */
export type Integration_Test_Instruction_Translation_Min_Order_By = {
  description?: Maybe<Order_By>;
  instruction_id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
};

/** response of any mutation on the table "integration_test.instruction_translation" */
export type Integration_Test_Instruction_Translation_Mutation_Response = {
  __typename?: 'integration_test_instruction_translation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Integration_Test_Instruction_Translation>;
};

/** on_conflict condition type for table "integration_test.instruction_translation" */
export type Integration_Test_Instruction_Translation_On_Conflict = {
  constraint: Integration_Test_Instruction_Translation_Constraint;
  update_columns?: Array<Integration_Test_Instruction_Translation_Update_Column>;
  where?: Maybe<Integration_Test_Instruction_Translation_Bool_Exp>;
};

/** Ordering options when selecting data from "integration_test.instruction_translation". */
export type Integration_Test_Instruction_Translation_Order_By = {
  description?: Maybe<Order_By>;
  instruction?: Maybe<Integration_Test_Instructions_Order_By>;
  instruction_id?: Maybe<Order_By>;
  instruction_translation_language?: Maybe<Languages_Order_By>;
  language?: Maybe<Order_By>;
};

/** primary key columns input for table: integration_test.instruction_translation */
export type Integration_Test_Instruction_Translation_Pk_Columns_Input = {
  instruction_id: Scalars['uuid'];
  language: Scalars['String'];
};

/** select columns of table "integration_test.instruction_translation" */
export enum Integration_Test_Instruction_Translation_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  InstructionId = 'instruction_id',
  /** column name */
  Language = 'language'
}

/** input type for updating data in table "integration_test.instruction_translation" */
export type Integration_Test_Instruction_Translation_Set_Input = {
  description?: Maybe<Scalars['String']>;
  instruction_id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "integration_test_instruction_translation" */
export type Integration_Test_Instruction_Translation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Integration_Test_Instruction_Translation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Integration_Test_Instruction_Translation_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>;
  instruction_id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
};

/** update columns of table "integration_test.instruction_translation" */
export enum Integration_Test_Instruction_Translation_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  InstructionId = 'instruction_id',
  /** column name */
  Language = 'language'
}

export type Integration_Test_Instruction_Translation_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Integration_Test_Instruction_Translation_Set_Input>;
  where: Integration_Test_Instruction_Translation_Bool_Exp;
};

/** columns and relationships of "integration_test.instructions" */
export type Integration_Test_Instructions = {
  __typename?: 'integration_test_instructions';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  description: Scalars['String'];
  id: Scalars['uuid'];
  /** An array relationship */
  instruction_media: Array<Integration_Test_Instruction_Media>;
  /** An aggregate relationship */
  instruction_media_aggregate: Integration_Test_Instruction_Media_Aggregate;
  /** An array relationship */
  instruction_translations: Array<Integration_Test_Instruction_Translation>;
  /** An aggregate relationship */
  instruction_translations_aggregate: Integration_Test_Instruction_Translation_Aggregate;
  /** An array relationship */
  steps: Array<Integration_Test_Steps>;
  /** An aggregate relationship */
  steps_aggregate: Integration_Test_Steps_Aggregate;
  /** An object relationship */
  task?: Maybe<Tasks>;
  task_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "integration_test.instructions" */
export type Integration_Test_InstructionsInstruction_MediaArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Instruction_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Instruction_Media_Order_By>>;
  where?: Maybe<Integration_Test_Instruction_Media_Bool_Exp>;
};


/** columns and relationships of "integration_test.instructions" */
export type Integration_Test_InstructionsInstruction_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Instruction_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Instruction_Media_Order_By>>;
  where?: Maybe<Integration_Test_Instruction_Media_Bool_Exp>;
};


/** columns and relationships of "integration_test.instructions" */
export type Integration_Test_InstructionsInstruction_TranslationsArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Instruction_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Instruction_Translation_Order_By>>;
  where?: Maybe<Integration_Test_Instruction_Translation_Bool_Exp>;
};


/** columns and relationships of "integration_test.instructions" */
export type Integration_Test_InstructionsInstruction_Translations_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Instruction_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Instruction_Translation_Order_By>>;
  where?: Maybe<Integration_Test_Instruction_Translation_Bool_Exp>;
};


/** columns and relationships of "integration_test.instructions" */
export type Integration_Test_InstructionsStepsArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Steps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Steps_Order_By>>;
  where?: Maybe<Integration_Test_Steps_Bool_Exp>;
};


/** columns and relationships of "integration_test.instructions" */
export type Integration_Test_InstructionsSteps_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Steps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Steps_Order_By>>;
  where?: Maybe<Integration_Test_Steps_Bool_Exp>;
};

/** aggregated selection of "integration_test.instructions" */
export type Integration_Test_Instructions_Aggregate = {
  __typename?: 'integration_test_instructions_aggregate';
  aggregate?: Maybe<Integration_Test_Instructions_Aggregate_Fields>;
  nodes: Array<Integration_Test_Instructions>;
};

/** aggregate fields of "integration_test.instructions" */
export type Integration_Test_Instructions_Aggregate_Fields = {
  __typename?: 'integration_test_instructions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Integration_Test_Instructions_Max_Fields>;
  min?: Maybe<Integration_Test_Instructions_Min_Fields>;
};


/** aggregate fields of "integration_test.instructions" */
export type Integration_Test_Instructions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Integration_Test_Instructions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "integration_test.instructions" */
export type Integration_Test_Instructions_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Integration_Test_Instructions_Max_Order_By>;
  min?: Maybe<Integration_Test_Instructions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "integration_test.instructions" */
export type Integration_Test_Instructions_Arr_Rel_Insert_Input = {
  data: Array<Integration_Test_Instructions_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Integration_Test_Instructions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "integration_test.instructions". All fields are combined with a logical 'AND'. */
export type Integration_Test_Instructions_Bool_Exp = {
  _and?: Maybe<Array<Integration_Test_Instructions_Bool_Exp>>;
  _not?: Maybe<Integration_Test_Instructions_Bool_Exp>;
  _or?: Maybe<Array<Integration_Test_Instructions_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamp_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  instruction_media?: Maybe<Integration_Test_Instruction_Media_Bool_Exp>;
  instruction_translations?: Maybe<Integration_Test_Instruction_Translation_Bool_Exp>;
  steps?: Maybe<Integration_Test_Steps_Bool_Exp>;
  task?: Maybe<Tasks_Bool_Exp>;
  task_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "integration_test.instructions" */
export enum Integration_Test_Instructions_Constraint {
  /** unique or primary key constraint on columns "description" */
  InstructionsDescriptionKey = 'instructions_description_key',
  /** unique or primary key constraint on columns "id" */
  InstructionsPkey = 'instructions_pkey'
}

/** input type for inserting data into table "integration_test.instructions" */
export type Integration_Test_Instructions_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  instruction_media?: Maybe<Integration_Test_Instruction_Media_Arr_Rel_Insert_Input>;
  instruction_translations?: Maybe<Integration_Test_Instruction_Translation_Arr_Rel_Insert_Input>;
  steps?: Maybe<Integration_Test_Steps_Arr_Rel_Insert_Input>;
  task?: Maybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Integration_Test_Instructions_Max_Fields = {
  __typename?: 'integration_test_instructions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "integration_test.instructions" */
export type Integration_Test_Instructions_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Integration_Test_Instructions_Min_Fields = {
  __typename?: 'integration_test_instructions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "integration_test.instructions" */
export type Integration_Test_Instructions_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "integration_test.instructions" */
export type Integration_Test_Instructions_Mutation_Response = {
  __typename?: 'integration_test_instructions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Integration_Test_Instructions>;
};

/** input type for inserting object relation for remote table "integration_test.instructions" */
export type Integration_Test_Instructions_Obj_Rel_Insert_Input = {
  data: Integration_Test_Instructions_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Integration_Test_Instructions_On_Conflict>;
};

/** on_conflict condition type for table "integration_test.instructions" */
export type Integration_Test_Instructions_On_Conflict = {
  constraint: Integration_Test_Instructions_Constraint;
  update_columns?: Array<Integration_Test_Instructions_Update_Column>;
  where?: Maybe<Integration_Test_Instructions_Bool_Exp>;
};

/** Ordering options when selecting data from "integration_test.instructions". */
export type Integration_Test_Instructions_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  instruction_media_aggregate?: Maybe<Integration_Test_Instruction_Media_Aggregate_Order_By>;
  instruction_translations_aggregate?: Maybe<Integration_Test_Instruction_Translation_Aggregate_Order_By>;
  steps_aggregate?: Maybe<Integration_Test_Steps_Aggregate_Order_By>;
  task?: Maybe<Tasks_Order_By>;
  task_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: integration_test.instructions */
export type Integration_Test_Instructions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "integration_test.instructions" */
export enum Integration_Test_Instructions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "integration_test.instructions" */
export type Integration_Test_Instructions_Set_Input = {
  deleted_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "integration_test_instructions" */
export type Integration_Test_Instructions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Integration_Test_Instructions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Integration_Test_Instructions_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "integration_test.instructions" */
export enum Integration_Test_Instructions_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  TaskId = 'task_id'
}

export type Integration_Test_Instructions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Integration_Test_Instructions_Set_Input>;
  where: Integration_Test_Instructions_Bool_Exp;
};

/** columns and relationships of "integration_test.observances" */
export type Integration_Test_Observances = {
  __typename?: 'integration_test_observances';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  engine?: Maybe<Engines>;
  engine_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  /** An array relationship */
  observations: Array<Integration_Test_Observations>;
  /** An aggregate relationship */
  observations_aggregate: Integration_Test_Observations_Aggregate;
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
  /** An object relationship */
  sequence: Integration_Test_Sequences;
  sequence_id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};


/** columns and relationships of "integration_test.observances" */
export type Integration_Test_ObservancesObservationsArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Observations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Observations_Order_By>>;
  where?: Maybe<Integration_Test_Observations_Bool_Exp>;
};


/** columns and relationships of "integration_test.observances" */
export type Integration_Test_ObservancesObservations_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Observations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Observations_Order_By>>;
  where?: Maybe<Integration_Test_Observations_Bool_Exp>;
};

/** aggregated selection of "integration_test.observances" */
export type Integration_Test_Observances_Aggregate = {
  __typename?: 'integration_test_observances_aggregate';
  aggregate?: Maybe<Integration_Test_Observances_Aggregate_Fields>;
  nodes: Array<Integration_Test_Observances>;
};

/** aggregate fields of "integration_test.observances" */
export type Integration_Test_Observances_Aggregate_Fields = {
  __typename?: 'integration_test_observances_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Integration_Test_Observances_Max_Fields>;
  min?: Maybe<Integration_Test_Observances_Min_Fields>;
};


/** aggregate fields of "integration_test.observances" */
export type Integration_Test_Observances_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Integration_Test_Observances_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "integration_test.observances" */
export type Integration_Test_Observances_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Integration_Test_Observances_Max_Order_By>;
  min?: Maybe<Integration_Test_Observances_Min_Order_By>;
};

/** input type for inserting array relation for remote table "integration_test.observances" */
export type Integration_Test_Observances_Arr_Rel_Insert_Input = {
  data: Array<Integration_Test_Observances_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Integration_Test_Observances_On_Conflict>;
};

/** Boolean expression to filter rows from the table "integration_test.observances". All fields are combined with a logical 'AND'. */
export type Integration_Test_Observances_Bool_Exp = {
  _and?: Maybe<Array<Integration_Test_Observances_Bool_Exp>>;
  _not?: Maybe<Integration_Test_Observances_Bool_Exp>;
  _or?: Maybe<Array<Integration_Test_Observances_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  engine?: Maybe<Engines_Bool_Exp>;
  engine_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  observations?: Maybe<Integration_Test_Observations_Bool_Exp>;
  project?: Maybe<Projects_Bool_Exp>;
  project_id?: Maybe<Uuid_Comparison_Exp>;
  sequence?: Maybe<Integration_Test_Sequences_Bool_Exp>;
  sequence_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "integration_test.observances" */
export enum Integration_Test_Observances_Constraint {
  /** unique or primary key constraint on columns "id" */
  ObservancesPkey = 'observances_pkey'
}

/** input type for inserting data into table "integration_test.observances" */
export type Integration_Test_Observances_Insert_Input = {
  engine?: Maybe<Engines_Obj_Rel_Insert_Input>;
  engine_id?: Maybe<Scalars['uuid']>;
  observations?: Maybe<Integration_Test_Observations_Arr_Rel_Insert_Input>;
  project?: Maybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Integration_Test_Sequences_Obj_Rel_Insert_Input>;
  sequence_id?: Maybe<Scalars['uuid']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Integration_Test_Observances_Max_Fields = {
  __typename?: 'integration_test_observances_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  engine_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  sequence_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "integration_test.observances" */
export type Integration_Test_Observances_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  engine_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Integration_Test_Observances_Min_Fields = {
  __typename?: 'integration_test_observances_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  engine_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  sequence_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "integration_test.observances" */
export type Integration_Test_Observances_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  engine_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "integration_test.observances" */
export type Integration_Test_Observances_Mutation_Response = {
  __typename?: 'integration_test_observances_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Integration_Test_Observances>;
};

/** input type for inserting object relation for remote table "integration_test.observances" */
export type Integration_Test_Observances_Obj_Rel_Insert_Input = {
  data: Integration_Test_Observances_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Integration_Test_Observances_On_Conflict>;
};

/** on_conflict condition type for table "integration_test.observances" */
export type Integration_Test_Observances_On_Conflict = {
  constraint: Integration_Test_Observances_Constraint;
  update_columns?: Array<Integration_Test_Observances_Update_Column>;
  where?: Maybe<Integration_Test_Observances_Bool_Exp>;
};

/** Ordering options when selecting data from "integration_test.observances". */
export type Integration_Test_Observances_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  engine?: Maybe<Engines_Order_By>;
  engine_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  observations_aggregate?: Maybe<Integration_Test_Observations_Aggregate_Order_By>;
  project?: Maybe<Projects_Order_By>;
  project_id?: Maybe<Order_By>;
  sequence?: Maybe<Integration_Test_Sequences_Order_By>;
  sequence_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: integration_test.observances */
export type Integration_Test_Observances_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "integration_test.observances" */
export enum Integration_Test_Observances_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EngineId = 'engine_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  SequenceId = 'sequence_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "integration_test.observances" */
export type Integration_Test_Observances_Set_Input = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  engine_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  sequence_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "integration_test_observances" */
export type Integration_Test_Observances_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Integration_Test_Observances_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Integration_Test_Observances_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  engine_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  sequence_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "integration_test.observances" */
export enum Integration_Test_Observances_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EngineId = 'engine_id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  SequenceId = 'sequence_id',
  /** column name */
  UserId = 'user_id'
}

export type Integration_Test_Observances_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Integration_Test_Observances_Set_Input>;
  where: Integration_Test_Observances_Bool_Exp;
};

/** columns and relationships of "integration_test.observations" */
export type Integration_Test_Observations = {
  __typename?: 'integration_test_observations';
  expectation_id: Scalars['uuid'];
  /** An object relationship */
  expectation_step?: Maybe<Integration_Test_Expectation_Step>;
  /** An object relationship */
  observance: Integration_Test_Observances;
  observance_id: Scalars['uuid'];
  result: Scalars['Boolean'];
  step_id: Scalars['uuid'];
};

/** aggregated selection of "integration_test.observations" */
export type Integration_Test_Observations_Aggregate = {
  __typename?: 'integration_test_observations_aggregate';
  aggregate?: Maybe<Integration_Test_Observations_Aggregate_Fields>;
  nodes: Array<Integration_Test_Observations>;
};

/** aggregate fields of "integration_test.observations" */
export type Integration_Test_Observations_Aggregate_Fields = {
  __typename?: 'integration_test_observations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Integration_Test_Observations_Max_Fields>;
  min?: Maybe<Integration_Test_Observations_Min_Fields>;
};


/** aggregate fields of "integration_test.observations" */
export type Integration_Test_Observations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Integration_Test_Observations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "integration_test.observations" */
export type Integration_Test_Observations_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Integration_Test_Observations_Max_Order_By>;
  min?: Maybe<Integration_Test_Observations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "integration_test.observations" */
export type Integration_Test_Observations_Arr_Rel_Insert_Input = {
  data: Array<Integration_Test_Observations_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Integration_Test_Observations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "integration_test.observations". All fields are combined with a logical 'AND'. */
export type Integration_Test_Observations_Bool_Exp = {
  _and?: Maybe<Array<Integration_Test_Observations_Bool_Exp>>;
  _not?: Maybe<Integration_Test_Observations_Bool_Exp>;
  _or?: Maybe<Array<Integration_Test_Observations_Bool_Exp>>;
  expectation_id?: Maybe<Uuid_Comparison_Exp>;
  expectation_step?: Maybe<Integration_Test_Expectation_Step_Bool_Exp>;
  observance?: Maybe<Integration_Test_Observances_Bool_Exp>;
  observance_id?: Maybe<Uuid_Comparison_Exp>;
  result?: Maybe<Boolean_Comparison_Exp>;
  step_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "integration_test.observations" */
export enum Integration_Test_Observations_Constraint {
  /** unique or primary key constraint on columns "expectation_id", "observance_id", "step_id" */
  ObservationsPkey = 'observations_pkey'
}

/** input type for inserting data into table "integration_test.observations" */
export type Integration_Test_Observations_Insert_Input = {
  expectation_id?: Maybe<Scalars['uuid']>;
  expectation_step?: Maybe<Integration_Test_Expectation_Step_Obj_Rel_Insert_Input>;
  observance?: Maybe<Integration_Test_Observances_Obj_Rel_Insert_Input>;
  observance_id?: Maybe<Scalars['uuid']>;
  result?: Maybe<Scalars['Boolean']>;
  step_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Integration_Test_Observations_Max_Fields = {
  __typename?: 'integration_test_observations_max_fields';
  expectation_id?: Maybe<Scalars['uuid']>;
  observance_id?: Maybe<Scalars['uuid']>;
  step_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "integration_test.observations" */
export type Integration_Test_Observations_Max_Order_By = {
  expectation_id?: Maybe<Order_By>;
  observance_id?: Maybe<Order_By>;
  step_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Integration_Test_Observations_Min_Fields = {
  __typename?: 'integration_test_observations_min_fields';
  expectation_id?: Maybe<Scalars['uuid']>;
  observance_id?: Maybe<Scalars['uuid']>;
  step_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "integration_test.observations" */
export type Integration_Test_Observations_Min_Order_By = {
  expectation_id?: Maybe<Order_By>;
  observance_id?: Maybe<Order_By>;
  step_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "integration_test.observations" */
export type Integration_Test_Observations_Mutation_Response = {
  __typename?: 'integration_test_observations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Integration_Test_Observations>;
};

/** on_conflict condition type for table "integration_test.observations" */
export type Integration_Test_Observations_On_Conflict = {
  constraint: Integration_Test_Observations_Constraint;
  update_columns?: Array<Integration_Test_Observations_Update_Column>;
  where?: Maybe<Integration_Test_Observations_Bool_Exp>;
};

/** Ordering options when selecting data from "integration_test.observations". */
export type Integration_Test_Observations_Order_By = {
  expectation_id?: Maybe<Order_By>;
  expectation_step?: Maybe<Integration_Test_Expectation_Step_Order_By>;
  observance?: Maybe<Integration_Test_Observances_Order_By>;
  observance_id?: Maybe<Order_By>;
  result?: Maybe<Order_By>;
  step_id?: Maybe<Order_By>;
};

/** select columns of table "integration_test.observations" */
export enum Integration_Test_Observations_Select_Column {
  /** column name */
  ExpectationId = 'expectation_id',
  /** column name */
  ObservanceId = 'observance_id',
  /** column name */
  Result = 'result',
  /** column name */
  StepId = 'step_id'
}

/** Streaming cursor of the table "integration_test_observations" */
export type Integration_Test_Observations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Integration_Test_Observations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Integration_Test_Observations_Stream_Cursor_Value_Input = {
  expectation_id?: Maybe<Scalars['uuid']>;
  observance_id?: Maybe<Scalars['uuid']>;
  result?: Maybe<Scalars['Boolean']>;
  step_id?: Maybe<Scalars['uuid']>;
};

/** placeholder for update columns of table "integration_test.observations" (current role has no relevant permissions) */
export enum Integration_Test_Observations_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** columns and relationships of "integration_test.project_sequence" */
export type Integration_Test_Project_Sequence = {
  __typename?: 'integration_test_project_sequence';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
  /** An object relationship */
  sequence: Integration_Test_Sequences;
  sequence_id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "integration_test.project_sequence" */
export type Integration_Test_Project_Sequence_Aggregate = {
  __typename?: 'integration_test_project_sequence_aggregate';
  aggregate?: Maybe<Integration_Test_Project_Sequence_Aggregate_Fields>;
  nodes: Array<Integration_Test_Project_Sequence>;
};

/** aggregate fields of "integration_test.project_sequence" */
export type Integration_Test_Project_Sequence_Aggregate_Fields = {
  __typename?: 'integration_test_project_sequence_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Integration_Test_Project_Sequence_Max_Fields>;
  min?: Maybe<Integration_Test_Project_Sequence_Min_Fields>;
};


/** aggregate fields of "integration_test.project_sequence" */
export type Integration_Test_Project_Sequence_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Integration_Test_Project_Sequence_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "integration_test.project_sequence" */
export type Integration_Test_Project_Sequence_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Integration_Test_Project_Sequence_Max_Order_By>;
  min?: Maybe<Integration_Test_Project_Sequence_Min_Order_By>;
};

/** input type for inserting array relation for remote table "integration_test.project_sequence" */
export type Integration_Test_Project_Sequence_Arr_Rel_Insert_Input = {
  data: Array<Integration_Test_Project_Sequence_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Integration_Test_Project_Sequence_On_Conflict>;
};

/** Boolean expression to filter rows from the table "integration_test.project_sequence". All fields are combined with a logical 'AND'. */
export type Integration_Test_Project_Sequence_Bool_Exp = {
  _and?: Maybe<Array<Integration_Test_Project_Sequence_Bool_Exp>>;
  _not?: Maybe<Integration_Test_Project_Sequence_Bool_Exp>;
  _or?: Maybe<Array<Integration_Test_Project_Sequence_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  project?: Maybe<Projects_Bool_Exp>;
  project_id?: Maybe<Uuid_Comparison_Exp>;
  sequence?: Maybe<Integration_Test_Sequences_Bool_Exp>;
  sequence_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "integration_test.project_sequence" */
export enum Integration_Test_Project_Sequence_Constraint {
  /** unique or primary key constraint on columns "project_id", "sequence_id" */
  ProjectSequencePkey = 'project_sequence_pkey'
}

/** input type for inserting data into table "integration_test.project_sequence" */
export type Integration_Test_Project_Sequence_Insert_Input = {
  project?: Maybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Integration_Test_Sequences_Obj_Rel_Insert_Input>;
  sequence_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Integration_Test_Project_Sequence_Max_Fields = {
  __typename?: 'integration_test_project_sequence_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  project_id?: Maybe<Scalars['uuid']>;
  sequence_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "integration_test.project_sequence" */
export type Integration_Test_Project_Sequence_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Integration_Test_Project_Sequence_Min_Fields = {
  __typename?: 'integration_test_project_sequence_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  project_id?: Maybe<Scalars['uuid']>;
  sequence_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "integration_test.project_sequence" */
export type Integration_Test_Project_Sequence_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "integration_test.project_sequence" */
export type Integration_Test_Project_Sequence_Mutation_Response = {
  __typename?: 'integration_test_project_sequence_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Integration_Test_Project_Sequence>;
};

/** on_conflict condition type for table "integration_test.project_sequence" */
export type Integration_Test_Project_Sequence_On_Conflict = {
  constraint: Integration_Test_Project_Sequence_Constraint;
  update_columns?: Array<Integration_Test_Project_Sequence_Update_Column>;
  where?: Maybe<Integration_Test_Project_Sequence_Bool_Exp>;
};

/** Ordering options when selecting data from "integration_test.project_sequence". */
export type Integration_Test_Project_Sequence_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  project?: Maybe<Projects_Order_By>;
  project_id?: Maybe<Order_By>;
  sequence?: Maybe<Integration_Test_Sequences_Order_By>;
  sequence_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: integration_test.project_sequence */
export type Integration_Test_Project_Sequence_Pk_Columns_Input = {
  project_id: Scalars['uuid'];
  sequence_id: Scalars['uuid'];
};

/** select columns of table "integration_test.project_sequence" */
export enum Integration_Test_Project_Sequence_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  SequenceId = 'sequence_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "integration_test.project_sequence" */
export type Integration_Test_Project_Sequence_Set_Input = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  project_id?: Maybe<Scalars['uuid']>;
  sequence_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "integration_test_project_sequence" */
export type Integration_Test_Project_Sequence_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Integration_Test_Project_Sequence_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Integration_Test_Project_Sequence_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  project_id?: Maybe<Scalars['uuid']>;
  sequence_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "integration_test.project_sequence" */
export enum Integration_Test_Project_Sequence_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  SequenceId = 'sequence_id'
}

export type Integration_Test_Project_Sequence_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Integration_Test_Project_Sequence_Set_Input>;
  where: Integration_Test_Project_Sequence_Bool_Exp;
};

/** columns and relationships of "integration_test.sequences" */
export type Integration_Test_Sequences = {
  __typename?: 'integration_test_sequences';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  id: Scalars['uuid'];
  /** An array relationship */
  observances: Array<Integration_Test_Observances>;
  /** An aggregate relationship */
  observances_aggregate: Integration_Test_Observances_Aggregate;
  /** An array relationship */
  project_sequences: Array<Integration_Test_Project_Sequence>;
  /** An aggregate relationship */
  project_sequences_aggregate: Integration_Test_Project_Sequence_Aggregate;
  /** An array relationship */
  steps: Array<Integration_Test_Steps>;
  /** An aggregate relationship */
  steps_aggregate: Integration_Test_Steps_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "integration_test.sequences" */
export type Integration_Test_SequencesObservancesArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Observances_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Observances_Order_By>>;
  where?: Maybe<Integration_Test_Observances_Bool_Exp>;
};


/** columns and relationships of "integration_test.sequences" */
export type Integration_Test_SequencesObservances_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Observances_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Observances_Order_By>>;
  where?: Maybe<Integration_Test_Observances_Bool_Exp>;
};


/** columns and relationships of "integration_test.sequences" */
export type Integration_Test_SequencesProject_SequencesArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Project_Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Project_Sequence_Order_By>>;
  where?: Maybe<Integration_Test_Project_Sequence_Bool_Exp>;
};


/** columns and relationships of "integration_test.sequences" */
export type Integration_Test_SequencesProject_Sequences_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Project_Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Project_Sequence_Order_By>>;
  where?: Maybe<Integration_Test_Project_Sequence_Bool_Exp>;
};


/** columns and relationships of "integration_test.sequences" */
export type Integration_Test_SequencesStepsArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Steps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Steps_Order_By>>;
  where?: Maybe<Integration_Test_Steps_Bool_Exp>;
};


/** columns and relationships of "integration_test.sequences" */
export type Integration_Test_SequencesSteps_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Steps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Steps_Order_By>>;
  where?: Maybe<Integration_Test_Steps_Bool_Exp>;
};

/** aggregated selection of "integration_test.sequences" */
export type Integration_Test_Sequences_Aggregate = {
  __typename?: 'integration_test_sequences_aggregate';
  aggregate?: Maybe<Integration_Test_Sequences_Aggregate_Fields>;
  nodes: Array<Integration_Test_Sequences>;
};

/** aggregate fields of "integration_test.sequences" */
export type Integration_Test_Sequences_Aggregate_Fields = {
  __typename?: 'integration_test_sequences_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Integration_Test_Sequences_Max_Fields>;
  min?: Maybe<Integration_Test_Sequences_Min_Fields>;
};


/** aggregate fields of "integration_test.sequences" */
export type Integration_Test_Sequences_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Integration_Test_Sequences_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "integration_test.sequences". All fields are combined with a logical 'AND'. */
export type Integration_Test_Sequences_Bool_Exp = {
  _and?: Maybe<Array<Integration_Test_Sequences_Bool_Exp>>;
  _not?: Maybe<Integration_Test_Sequences_Bool_Exp>;
  _or?: Maybe<Array<Integration_Test_Sequences_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  observances?: Maybe<Integration_Test_Observances_Bool_Exp>;
  project_sequences?: Maybe<Integration_Test_Project_Sequence_Bool_Exp>;
  steps?: Maybe<Integration_Test_Steps_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "integration_test.sequences" */
export enum Integration_Test_Sequences_Constraint {
  /** unique or primary key constraint on columns "description" */
  SequencesDescriptionKey = 'sequences_description_key',
  /** unique or primary key constraint on columns "id" */
  SequencesPkey = 'sequences_pkey'
}

/** input type for inserting data into table "integration_test.sequences" */
export type Integration_Test_Sequences_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  observances?: Maybe<Integration_Test_Observances_Arr_Rel_Insert_Input>;
  project_sequences?: Maybe<Integration_Test_Project_Sequence_Arr_Rel_Insert_Input>;
  steps?: Maybe<Integration_Test_Steps_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Integration_Test_Sequences_Max_Fields = {
  __typename?: 'integration_test_sequences_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Integration_Test_Sequences_Min_Fields = {
  __typename?: 'integration_test_sequences_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "integration_test.sequences" */
export type Integration_Test_Sequences_Mutation_Response = {
  __typename?: 'integration_test_sequences_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Integration_Test_Sequences>;
};

/** input type for inserting object relation for remote table "integration_test.sequences" */
export type Integration_Test_Sequences_Obj_Rel_Insert_Input = {
  data: Integration_Test_Sequences_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Integration_Test_Sequences_On_Conflict>;
};

/** on_conflict condition type for table "integration_test.sequences" */
export type Integration_Test_Sequences_On_Conflict = {
  constraint: Integration_Test_Sequences_Constraint;
  update_columns?: Array<Integration_Test_Sequences_Update_Column>;
  where?: Maybe<Integration_Test_Sequences_Bool_Exp>;
};

/** Ordering options when selecting data from "integration_test.sequences". */
export type Integration_Test_Sequences_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  observances_aggregate?: Maybe<Integration_Test_Observances_Aggregate_Order_By>;
  project_sequences_aggregate?: Maybe<Integration_Test_Project_Sequence_Aggregate_Order_By>;
  steps_aggregate?: Maybe<Integration_Test_Steps_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: integration_test.sequences */
export type Integration_Test_Sequences_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "integration_test.sequences" */
export enum Integration_Test_Sequences_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "integration_test.sequences" */
export type Integration_Test_Sequences_Set_Input = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "integration_test_sequences" */
export type Integration_Test_Sequences_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Integration_Test_Sequences_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Integration_Test_Sequences_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "integration_test.sequences" */
export enum Integration_Test_Sequences_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description'
}

export type Integration_Test_Sequences_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Integration_Test_Sequences_Set_Input>;
  where: Integration_Test_Sequences_Bool_Exp;
};

/** columns and relationships of "integration_test.steps" */
export type Integration_Test_Steps = {
  __typename?: 'integration_test_steps';
  /** An array relationship */
  expectation_steps: Array<Integration_Test_Expectation_Step>;
  /** An aggregate relationship */
  expectation_steps_aggregate: Integration_Test_Expectation_Step_Aggregate;
  id: Scalars['uuid'];
  /** An object relationship */
  instruction: Integration_Test_Instructions;
  instruction_id: Scalars['uuid'];
  position: Scalars['smallint'];
  /** An object relationship */
  sequence: Integration_Test_Sequences;
  sequence_id: Scalars['uuid'];
};


/** columns and relationships of "integration_test.steps" */
export type Integration_Test_StepsExpectation_StepsArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Expectation_Step_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Expectation_Step_Order_By>>;
  where?: Maybe<Integration_Test_Expectation_Step_Bool_Exp>;
};


/** columns and relationships of "integration_test.steps" */
export type Integration_Test_StepsExpectation_Steps_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Expectation_Step_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Expectation_Step_Order_By>>;
  where?: Maybe<Integration_Test_Expectation_Step_Bool_Exp>;
};

/** aggregated selection of "integration_test.steps" */
export type Integration_Test_Steps_Aggregate = {
  __typename?: 'integration_test_steps_aggregate';
  aggregate?: Maybe<Integration_Test_Steps_Aggregate_Fields>;
  nodes: Array<Integration_Test_Steps>;
};

/** aggregate fields of "integration_test.steps" */
export type Integration_Test_Steps_Aggregate_Fields = {
  __typename?: 'integration_test_steps_aggregate_fields';
  avg?: Maybe<Integration_Test_Steps_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Integration_Test_Steps_Max_Fields>;
  min?: Maybe<Integration_Test_Steps_Min_Fields>;
  stddev?: Maybe<Integration_Test_Steps_Stddev_Fields>;
  stddev_pop?: Maybe<Integration_Test_Steps_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Integration_Test_Steps_Stddev_Samp_Fields>;
  sum?: Maybe<Integration_Test_Steps_Sum_Fields>;
  var_pop?: Maybe<Integration_Test_Steps_Var_Pop_Fields>;
  var_samp?: Maybe<Integration_Test_Steps_Var_Samp_Fields>;
  variance?: Maybe<Integration_Test_Steps_Variance_Fields>;
};


/** aggregate fields of "integration_test.steps" */
export type Integration_Test_Steps_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Integration_Test_Steps_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "integration_test.steps" */
export type Integration_Test_Steps_Aggregate_Order_By = {
  avg?: Maybe<Integration_Test_Steps_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Integration_Test_Steps_Max_Order_By>;
  min?: Maybe<Integration_Test_Steps_Min_Order_By>;
  stddev?: Maybe<Integration_Test_Steps_Stddev_Order_By>;
  stddev_pop?: Maybe<Integration_Test_Steps_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Integration_Test_Steps_Stddev_Samp_Order_By>;
  sum?: Maybe<Integration_Test_Steps_Sum_Order_By>;
  var_pop?: Maybe<Integration_Test_Steps_Var_Pop_Order_By>;
  var_samp?: Maybe<Integration_Test_Steps_Var_Samp_Order_By>;
  variance?: Maybe<Integration_Test_Steps_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "integration_test.steps" */
export type Integration_Test_Steps_Arr_Rel_Insert_Input = {
  data: Array<Integration_Test_Steps_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Integration_Test_Steps_On_Conflict>;
};

/** aggregate avg on columns */
export type Integration_Test_Steps_Avg_Fields = {
  __typename?: 'integration_test_steps_avg_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "integration_test.steps" */
export type Integration_Test_Steps_Avg_Order_By = {
  position?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "integration_test.steps". All fields are combined with a logical 'AND'. */
export type Integration_Test_Steps_Bool_Exp = {
  _and?: Maybe<Array<Integration_Test_Steps_Bool_Exp>>;
  _not?: Maybe<Integration_Test_Steps_Bool_Exp>;
  _or?: Maybe<Array<Integration_Test_Steps_Bool_Exp>>;
  expectation_steps?: Maybe<Integration_Test_Expectation_Step_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  instruction?: Maybe<Integration_Test_Instructions_Bool_Exp>;
  instruction_id?: Maybe<Uuid_Comparison_Exp>;
  position?: Maybe<Smallint_Comparison_Exp>;
  sequence?: Maybe<Integration_Test_Sequences_Bool_Exp>;
  sequence_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "integration_test.steps" */
export enum Integration_Test_Steps_Constraint {
  /** unique or primary key constraint on columns "id" */
  StepsPkey = 'steps_pkey',
  /** unique or primary key constraint on columns "sequence_id", "instruction_id", "position" */
  StepsPositionInstructionIdSequenceIdKey = 'steps_position_instruction_id_sequence_id_key'
}

/** input type for inserting data into table "integration_test.steps" */
export type Integration_Test_Steps_Insert_Input = {
  expectation_steps?: Maybe<Integration_Test_Expectation_Step_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  instruction?: Maybe<Integration_Test_Instructions_Obj_Rel_Insert_Input>;
  instruction_id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['smallint']>;
  sequence?: Maybe<Integration_Test_Sequences_Obj_Rel_Insert_Input>;
  sequence_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Integration_Test_Steps_Max_Fields = {
  __typename?: 'integration_test_steps_max_fields';
  id?: Maybe<Scalars['uuid']>;
  instruction_id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['smallint']>;
  sequence_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "integration_test.steps" */
export type Integration_Test_Steps_Max_Order_By = {
  id?: Maybe<Order_By>;
  instruction_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Integration_Test_Steps_Min_Fields = {
  __typename?: 'integration_test_steps_min_fields';
  id?: Maybe<Scalars['uuid']>;
  instruction_id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['smallint']>;
  sequence_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "integration_test.steps" */
export type Integration_Test_Steps_Min_Order_By = {
  id?: Maybe<Order_By>;
  instruction_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "integration_test.steps" */
export type Integration_Test_Steps_Mutation_Response = {
  __typename?: 'integration_test_steps_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Integration_Test_Steps>;
};

/** input type for inserting object relation for remote table "integration_test.steps" */
export type Integration_Test_Steps_Obj_Rel_Insert_Input = {
  data: Integration_Test_Steps_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Integration_Test_Steps_On_Conflict>;
};

/** on_conflict condition type for table "integration_test.steps" */
export type Integration_Test_Steps_On_Conflict = {
  constraint: Integration_Test_Steps_Constraint;
  update_columns?: Array<Integration_Test_Steps_Update_Column>;
  where?: Maybe<Integration_Test_Steps_Bool_Exp>;
};

/** Ordering options when selecting data from "integration_test.steps". */
export type Integration_Test_Steps_Order_By = {
  expectation_steps_aggregate?: Maybe<Integration_Test_Expectation_Step_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  instruction?: Maybe<Integration_Test_Instructions_Order_By>;
  instruction_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  sequence?: Maybe<Integration_Test_Sequences_Order_By>;
  sequence_id?: Maybe<Order_By>;
};

/** select columns of table "integration_test.steps" */
export enum Integration_Test_Steps_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  InstructionId = 'instruction_id',
  /** column name */
  Position = 'position',
  /** column name */
  SequenceId = 'sequence_id'
}

/** aggregate stddev on columns */
export type Integration_Test_Steps_Stddev_Fields = {
  __typename?: 'integration_test_steps_stddev_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "integration_test.steps" */
export type Integration_Test_Steps_Stddev_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Integration_Test_Steps_Stddev_Pop_Fields = {
  __typename?: 'integration_test_steps_stddev_pop_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "integration_test.steps" */
export type Integration_Test_Steps_Stddev_Pop_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Integration_Test_Steps_Stddev_Samp_Fields = {
  __typename?: 'integration_test_steps_stddev_samp_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "integration_test.steps" */
export type Integration_Test_Steps_Stddev_Samp_Order_By = {
  position?: Maybe<Order_By>;
};

/** Streaming cursor of the table "integration_test_steps" */
export type Integration_Test_Steps_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Integration_Test_Steps_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Integration_Test_Steps_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['uuid']>;
  instruction_id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['smallint']>;
  sequence_id?: Maybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Integration_Test_Steps_Sum_Fields = {
  __typename?: 'integration_test_steps_sum_fields';
  position?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "integration_test.steps" */
export type Integration_Test_Steps_Sum_Order_By = {
  position?: Maybe<Order_By>;
};

/** placeholder for update columns of table "integration_test.steps" (current role has no relevant permissions) */
export enum Integration_Test_Steps_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** aggregate var_pop on columns */
export type Integration_Test_Steps_Var_Pop_Fields = {
  __typename?: 'integration_test_steps_var_pop_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "integration_test.steps" */
export type Integration_Test_Steps_Var_Pop_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Integration_Test_Steps_Var_Samp_Fields = {
  __typename?: 'integration_test_steps_var_samp_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "integration_test.steps" */
export type Integration_Test_Steps_Var_Samp_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Integration_Test_Steps_Variance_Fields = {
  __typename?: 'integration_test_steps_variance_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "integration_test.steps" */
export type Integration_Test_Steps_Variance_Order_By = {
  position?: Maybe<Order_By>;
};


/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: Maybe<Scalars['json']>;
  _gt?: Maybe<Scalars['json']>;
  _gte?: Maybe<Scalars['json']>;
  _in?: Maybe<Array<Scalars['json']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['json']>;
  _lte?: Maybe<Scalars['json']>;
  _neq?: Maybe<Scalars['json']>;
  _nin?: Maybe<Array<Scalars['json']>>;
};


export type Jsonb_Cast_Exp = {
  String?: Maybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: Maybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** List of supported languages */
export type Languages = {
  __typename?: 'languages';
  description: Scalars['String'];
  /** An array relationship */
  expectation_translations: Array<Integration_Test_Expectation_Translation>;
  /** An aggregate relationship */
  expectation_translations_aggregate: Integration_Test_Expectation_Translation_Aggregate;
  /** An array relationship */
  field_translations: Array<Field_Translation>;
  /** An aggregate relationship */
  field_translations_aggregate: Field_Translation_Aggregate;
  id: Scalars['String'];
  /** An array relationship */
  instruction_translations: Array<Integration_Test_Instruction_Translation>;
  /** An aggregate relationship */
  instruction_translations_aggregate: Integration_Test_Instruction_Translation_Aggregate;
  /** An array relationship */
  option_translations: Array<Option_Translation>;
  /** An aggregate relationship */
  option_translations_aggregate: Option_Translation_Aggregate;
  /** An array relationship */
  task_translations: Array<Task_Translation>;
  /** An aggregate relationship */
  task_translations_aggregate: Task_Translation_Aggregate;
};


/** List of supported languages */
export type LanguagesExpectation_TranslationsArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Expectation_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Expectation_Translation_Order_By>>;
  where?: Maybe<Integration_Test_Expectation_Translation_Bool_Exp>;
};


/** List of supported languages */
export type LanguagesExpectation_Translations_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Expectation_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Expectation_Translation_Order_By>>;
  where?: Maybe<Integration_Test_Expectation_Translation_Bool_Exp>;
};


/** List of supported languages */
export type LanguagesField_TranslationsArgs = {
  distinct_on?: Maybe<Array<Field_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Field_Translation_Order_By>>;
  where?: Maybe<Field_Translation_Bool_Exp>;
};


/** List of supported languages */
export type LanguagesField_Translations_AggregateArgs = {
  distinct_on?: Maybe<Array<Field_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Field_Translation_Order_By>>;
  where?: Maybe<Field_Translation_Bool_Exp>;
};


/** List of supported languages */
export type LanguagesInstruction_TranslationsArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Instruction_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Instruction_Translation_Order_By>>;
  where?: Maybe<Integration_Test_Instruction_Translation_Bool_Exp>;
};


/** List of supported languages */
export type LanguagesInstruction_Translations_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Instruction_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Instruction_Translation_Order_By>>;
  where?: Maybe<Integration_Test_Instruction_Translation_Bool_Exp>;
};


/** List of supported languages */
export type LanguagesOption_TranslationsArgs = {
  distinct_on?: Maybe<Array<Option_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Option_Translation_Order_By>>;
  where?: Maybe<Option_Translation_Bool_Exp>;
};


/** List of supported languages */
export type LanguagesOption_Translations_AggregateArgs = {
  distinct_on?: Maybe<Array<Option_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Option_Translation_Order_By>>;
  where?: Maybe<Option_Translation_Bool_Exp>;
};


/** List of supported languages */
export type LanguagesTask_TranslationsArgs = {
  distinct_on?: Maybe<Array<Task_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Translation_Order_By>>;
  where?: Maybe<Task_Translation_Bool_Exp>;
};


/** List of supported languages */
export type LanguagesTask_Translations_AggregateArgs = {
  distinct_on?: Maybe<Array<Task_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Translation_Order_By>>;
  where?: Maybe<Task_Translation_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "languages". All fields are combined with a logical 'AND'. */
export type Languages_Bool_Exp = {
  _and?: Maybe<Array<Languages_Bool_Exp>>;
  _not?: Maybe<Languages_Bool_Exp>;
  _or?: Maybe<Array<Languages_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  expectation_translations?: Maybe<Integration_Test_Expectation_Translation_Bool_Exp>;
  field_translations?: Maybe<Field_Translation_Bool_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  instruction_translations?: Maybe<Integration_Test_Instruction_Translation_Bool_Exp>;
  option_translations?: Maybe<Option_Translation_Bool_Exp>;
  task_translations?: Maybe<Task_Translation_Bool_Exp>;
};

/** response of any mutation on the table "languages" */
export type Languages_Mutation_Response = {
  __typename?: 'languages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Languages>;
};

/** Ordering options when selecting data from "languages". */
export type Languages_Order_By = {
  description?: Maybe<Order_By>;
  expectation_translations_aggregate?: Maybe<Integration_Test_Expectation_Translation_Aggregate_Order_By>;
  field_translations_aggregate?: Maybe<Field_Translation_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  instruction_translations_aggregate?: Maybe<Integration_Test_Instruction_Translation_Aggregate_Order_By>;
  option_translations_aggregate?: Maybe<Option_Translation_Aggregate_Order_By>;
  task_translations_aggregate?: Maybe<Task_Translation_Aggregate_Order_By>;
};

/** primary key columns input for table: languages */
export type Languages_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "languages" */
export enum Languages_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "languages" */
export type Languages_Set_Input = {
  description?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "languages" */
export type Languages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Languages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Languages_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type Languages_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Languages_Set_Input>;
  where: Languages_Bool_Exp;
};

/** Information about where to locate the blob file in azure blob storage service */
export type Machine_Learning_Blobs = {
  __typename?: 'machine_learning_blobs';
  blob_name: Scalars['String'];
  blob_storage_account_name: Scalars['String'];
  blob_storage_container_name: Scalars['String'];
  /** An object relationship */
  feature?: Maybe<Machine_Learning_Features>;
  id: Scalars['uuid'];
  md5?: Maybe<Scalars['String']>;
  /** An array relationship */
  model_blobs: Array<Machine_Learning_Model_Blob>;
  size_byte?: Maybe<Scalars['Int']>;
};


/** Information about where to locate the blob file in azure blob storage service */
export type Machine_Learning_BlobsModel_BlobsArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Model_Blob_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Model_Blob_Order_By>>;
  where?: Maybe<Machine_Learning_Model_Blob_Bool_Exp>;
};

/** aggregated selection of "machine_learning.blobs" */
export type Machine_Learning_Blobs_Aggregate = {
  __typename?: 'machine_learning_blobs_aggregate';
  aggregate?: Maybe<Machine_Learning_Blobs_Aggregate_Fields>;
  nodes: Array<Machine_Learning_Blobs>;
};

/** aggregate fields of "machine_learning.blobs" */
export type Machine_Learning_Blobs_Aggregate_Fields = {
  __typename?: 'machine_learning_blobs_aggregate_fields';
  avg?: Maybe<Machine_Learning_Blobs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Machine_Learning_Blobs_Max_Fields>;
  min?: Maybe<Machine_Learning_Blobs_Min_Fields>;
  stddev?: Maybe<Machine_Learning_Blobs_Stddev_Fields>;
  stddev_pop?: Maybe<Machine_Learning_Blobs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Machine_Learning_Blobs_Stddev_Samp_Fields>;
  sum?: Maybe<Machine_Learning_Blobs_Sum_Fields>;
  var_pop?: Maybe<Machine_Learning_Blobs_Var_Pop_Fields>;
  var_samp?: Maybe<Machine_Learning_Blobs_Var_Samp_Fields>;
  variance?: Maybe<Machine_Learning_Blobs_Variance_Fields>;
};


/** aggregate fields of "machine_learning.blobs" */
export type Machine_Learning_Blobs_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Machine_Learning_Blobs_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Machine_Learning_Blobs_Avg_Fields = {
  __typename?: 'machine_learning_blobs_avg_fields';
  size_byte?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "machine_learning.blobs". All fields are combined with a logical 'AND'. */
export type Machine_Learning_Blobs_Bool_Exp = {
  _and?: Maybe<Array<Machine_Learning_Blobs_Bool_Exp>>;
  _not?: Maybe<Machine_Learning_Blobs_Bool_Exp>;
  _or?: Maybe<Array<Machine_Learning_Blobs_Bool_Exp>>;
  blob_name?: Maybe<String_Comparison_Exp>;
  blob_storage_account_name?: Maybe<String_Comparison_Exp>;
  blob_storage_container_name?: Maybe<String_Comparison_Exp>;
  feature?: Maybe<Machine_Learning_Features_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  md5?: Maybe<String_Comparison_Exp>;
  model_blobs?: Maybe<Machine_Learning_Model_Blob_Bool_Exp>;
  size_byte?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "machine_learning.blobs" */
export enum Machine_Learning_Blobs_Constraint {
  /** unique or primary key constraint on columns "blob_storage_container_name", "blob_storage_account_name", "blob_name" */
  BlobsBlobStorageAccountNameBlobNameBlobStorageContaine = 'blobs_blob_storage_account_name_blob_name_blob_storage_containe',
  /** unique or primary key constraint on columns "id" */
  BlobsPkey = 'blobs_pkey'
}

/** input type for incrementing numeric columns in table "machine_learning.blobs" */
export type Machine_Learning_Blobs_Inc_Input = {
  size_byte?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "machine_learning.blobs" */
export type Machine_Learning_Blobs_Insert_Input = {
  blob_name?: Maybe<Scalars['String']>;
  blob_storage_account_name?: Maybe<Scalars['String']>;
  blob_storage_container_name?: Maybe<Scalars['String']>;
  feature?: Maybe<Machine_Learning_Features_Obj_Rel_Insert_Input>;
  md5?: Maybe<Scalars['String']>;
  model_blobs?: Maybe<Machine_Learning_Model_Blob_Arr_Rel_Insert_Input>;
  size_byte?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Machine_Learning_Blobs_Max_Fields = {
  __typename?: 'machine_learning_blobs_max_fields';
  blob_name?: Maybe<Scalars['String']>;
  blob_storage_account_name?: Maybe<Scalars['String']>;
  blob_storage_container_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  md5?: Maybe<Scalars['String']>;
  size_byte?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Machine_Learning_Blobs_Min_Fields = {
  __typename?: 'machine_learning_blobs_min_fields';
  blob_name?: Maybe<Scalars['String']>;
  blob_storage_account_name?: Maybe<Scalars['String']>;
  blob_storage_container_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  md5?: Maybe<Scalars['String']>;
  size_byte?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "machine_learning.blobs" */
export type Machine_Learning_Blobs_Mutation_Response = {
  __typename?: 'machine_learning_blobs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Machine_Learning_Blobs>;
};

/** input type for inserting object relation for remote table "machine_learning.blobs" */
export type Machine_Learning_Blobs_Obj_Rel_Insert_Input = {
  data: Machine_Learning_Blobs_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Machine_Learning_Blobs_On_Conflict>;
};

/** on_conflict condition type for table "machine_learning.blobs" */
export type Machine_Learning_Blobs_On_Conflict = {
  constraint: Machine_Learning_Blobs_Constraint;
  update_columns?: Array<Machine_Learning_Blobs_Update_Column>;
  where?: Maybe<Machine_Learning_Blobs_Bool_Exp>;
};

/** Ordering options when selecting data from "machine_learning.blobs". */
export type Machine_Learning_Blobs_Order_By = {
  blob_name?: Maybe<Order_By>;
  blob_storage_account_name?: Maybe<Order_By>;
  blob_storage_container_name?: Maybe<Order_By>;
  feature?: Maybe<Machine_Learning_Features_Order_By>;
  id?: Maybe<Order_By>;
  md5?: Maybe<Order_By>;
  model_blobs_aggregate?: Maybe<Machine_Learning_Model_Blob_Aggregate_Order_By>;
  size_byte?: Maybe<Order_By>;
};

/** primary key columns input for table: machine_learning.blobs */
export type Machine_Learning_Blobs_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "machine_learning.blobs" */
export enum Machine_Learning_Blobs_Select_Column {
  /** column name */
  BlobName = 'blob_name',
  /** column name */
  BlobStorageAccountName = 'blob_storage_account_name',
  /** column name */
  BlobStorageContainerName = 'blob_storage_container_name',
  /** column name */
  Id = 'id',
  /** column name */
  Md5 = 'md5',
  /** column name */
  SizeByte = 'size_byte'
}

/** input type for updating data in table "machine_learning.blobs" */
export type Machine_Learning_Blobs_Set_Input = {
  blob_name?: Maybe<Scalars['String']>;
  blob_storage_account_name?: Maybe<Scalars['String']>;
  blob_storage_container_name?: Maybe<Scalars['String']>;
  md5?: Maybe<Scalars['String']>;
  size_byte?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Machine_Learning_Blobs_Stddev_Fields = {
  __typename?: 'machine_learning_blobs_stddev_fields';
  size_byte?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Machine_Learning_Blobs_Stddev_Pop_Fields = {
  __typename?: 'machine_learning_blobs_stddev_pop_fields';
  size_byte?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Machine_Learning_Blobs_Stddev_Samp_Fields = {
  __typename?: 'machine_learning_blobs_stddev_samp_fields';
  size_byte?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "machine_learning_blobs" */
export type Machine_Learning_Blobs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Machine_Learning_Blobs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Machine_Learning_Blobs_Stream_Cursor_Value_Input = {
  blob_name?: Maybe<Scalars['String']>;
  blob_storage_account_name?: Maybe<Scalars['String']>;
  blob_storage_container_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  md5?: Maybe<Scalars['String']>;
  size_byte?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Machine_Learning_Blobs_Sum_Fields = {
  __typename?: 'machine_learning_blobs_sum_fields';
  size_byte?: Maybe<Scalars['Int']>;
};

/** update columns of table "machine_learning.blobs" */
export enum Machine_Learning_Blobs_Update_Column {
  /** column name */
  BlobName = 'blob_name',
  /** column name */
  BlobStorageAccountName = 'blob_storage_account_name',
  /** column name */
  BlobStorageContainerName = 'blob_storage_container_name',
  /** column name */
  Md5 = 'md5',
  /** column name */
  SizeByte = 'size_byte'
}

export type Machine_Learning_Blobs_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Machine_Learning_Blobs_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Machine_Learning_Blobs_Set_Input>;
  where: Machine_Learning_Blobs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Machine_Learning_Blobs_Var_Pop_Fields = {
  __typename?: 'machine_learning_blobs_var_pop_fields';
  size_byte?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Machine_Learning_Blobs_Var_Samp_Fields = {
  __typename?: 'machine_learning_blobs_var_samp_fields';
  size_byte?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Machine_Learning_Blobs_Variance_Fields = {
  __typename?: 'machine_learning_blobs_variance_fields';
  size_byte?: Maybe<Scalars['Float']>;
};

/** Link table between datasets and features */
export type Machine_Learning_Dataset_Feature = {
  __typename?: 'machine_learning_dataset_feature';
  /** An object relationship */
  dataset: Machine_Learning_Datasets;
  dataset_id: Scalars['uuid'];
  /** An object relationship */
  feature: Machine_Learning_Features;
  feature_id: Scalars['uuid'];
};

/** aggregated selection of "machine_learning.dataset_feature" */
export type Machine_Learning_Dataset_Feature_Aggregate = {
  __typename?: 'machine_learning_dataset_feature_aggregate';
  aggregate?: Maybe<Machine_Learning_Dataset_Feature_Aggregate_Fields>;
  nodes: Array<Machine_Learning_Dataset_Feature>;
};

/** aggregate fields of "machine_learning.dataset_feature" */
export type Machine_Learning_Dataset_Feature_Aggregate_Fields = {
  __typename?: 'machine_learning_dataset_feature_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Machine_Learning_Dataset_Feature_Max_Fields>;
  min?: Maybe<Machine_Learning_Dataset_Feature_Min_Fields>;
};


/** aggregate fields of "machine_learning.dataset_feature" */
export type Machine_Learning_Dataset_Feature_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Machine_Learning_Dataset_Feature_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "machine_learning.dataset_feature" */
export type Machine_Learning_Dataset_Feature_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Machine_Learning_Dataset_Feature_Max_Order_By>;
  min?: Maybe<Machine_Learning_Dataset_Feature_Min_Order_By>;
};

/** input type for inserting array relation for remote table "machine_learning.dataset_feature" */
export type Machine_Learning_Dataset_Feature_Arr_Rel_Insert_Input = {
  data: Array<Machine_Learning_Dataset_Feature_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Machine_Learning_Dataset_Feature_On_Conflict>;
};

/** Boolean expression to filter rows from the table "machine_learning.dataset_feature". All fields are combined with a logical 'AND'. */
export type Machine_Learning_Dataset_Feature_Bool_Exp = {
  _and?: Maybe<Array<Machine_Learning_Dataset_Feature_Bool_Exp>>;
  _not?: Maybe<Machine_Learning_Dataset_Feature_Bool_Exp>;
  _or?: Maybe<Array<Machine_Learning_Dataset_Feature_Bool_Exp>>;
  dataset?: Maybe<Machine_Learning_Datasets_Bool_Exp>;
  dataset_id?: Maybe<Uuid_Comparison_Exp>;
  feature?: Maybe<Machine_Learning_Features_Bool_Exp>;
  feature_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "machine_learning.dataset_feature" */
export enum Machine_Learning_Dataset_Feature_Constraint {
  /** unique or primary key constraint on columns "feature_id", "dataset_id" */
  DatasetFeaturePkey = 'dataset_feature_pkey'
}

/** input type for inserting data into table "machine_learning.dataset_feature" */
export type Machine_Learning_Dataset_Feature_Insert_Input = {
  dataset?: Maybe<Machine_Learning_Datasets_Obj_Rel_Insert_Input>;
  dataset_id?: Maybe<Scalars['uuid']>;
  feature?: Maybe<Machine_Learning_Features_Obj_Rel_Insert_Input>;
  feature_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Machine_Learning_Dataset_Feature_Max_Fields = {
  __typename?: 'machine_learning_dataset_feature_max_fields';
  dataset_id?: Maybe<Scalars['uuid']>;
  feature_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "machine_learning.dataset_feature" */
export type Machine_Learning_Dataset_Feature_Max_Order_By = {
  dataset_id?: Maybe<Order_By>;
  feature_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Machine_Learning_Dataset_Feature_Min_Fields = {
  __typename?: 'machine_learning_dataset_feature_min_fields';
  dataset_id?: Maybe<Scalars['uuid']>;
  feature_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "machine_learning.dataset_feature" */
export type Machine_Learning_Dataset_Feature_Min_Order_By = {
  dataset_id?: Maybe<Order_By>;
  feature_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "machine_learning.dataset_feature" */
export type Machine_Learning_Dataset_Feature_Mutation_Response = {
  __typename?: 'machine_learning_dataset_feature_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Machine_Learning_Dataset_Feature>;
};

/** on_conflict condition type for table "machine_learning.dataset_feature" */
export type Machine_Learning_Dataset_Feature_On_Conflict = {
  constraint: Machine_Learning_Dataset_Feature_Constraint;
  update_columns?: Array<Machine_Learning_Dataset_Feature_Update_Column>;
  where?: Maybe<Machine_Learning_Dataset_Feature_Bool_Exp>;
};

/** Ordering options when selecting data from "machine_learning.dataset_feature". */
export type Machine_Learning_Dataset_Feature_Order_By = {
  dataset?: Maybe<Machine_Learning_Datasets_Order_By>;
  dataset_id?: Maybe<Order_By>;
  feature?: Maybe<Machine_Learning_Features_Order_By>;
  feature_id?: Maybe<Order_By>;
};

/** primary key columns input for table: machine_learning.dataset_feature */
export type Machine_Learning_Dataset_Feature_Pk_Columns_Input = {
  dataset_id: Scalars['uuid'];
  feature_id: Scalars['uuid'];
};

/** select columns of table "machine_learning.dataset_feature" */
export enum Machine_Learning_Dataset_Feature_Select_Column {
  /** column name */
  DatasetId = 'dataset_id',
  /** column name */
  FeatureId = 'feature_id'
}

/** input type for updating data in table "machine_learning.dataset_feature" */
export type Machine_Learning_Dataset_Feature_Set_Input = {
  dataset_id?: Maybe<Scalars['uuid']>;
  feature_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "machine_learning_dataset_feature" */
export type Machine_Learning_Dataset_Feature_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Machine_Learning_Dataset_Feature_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Machine_Learning_Dataset_Feature_Stream_Cursor_Value_Input = {
  dataset_id?: Maybe<Scalars['uuid']>;
  feature_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "machine_learning.dataset_feature" */
export enum Machine_Learning_Dataset_Feature_Update_Column {
  /** column name */
  DatasetId = 'dataset_id',
  /** column name */
  FeatureId = 'feature_id'
}

export type Machine_Learning_Dataset_Feature_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Machine_Learning_Dataset_Feature_Set_Input>;
  where: Machine_Learning_Dataset_Feature_Bool_Exp;
};

/** Metadata of datasets */
export type Machine_Learning_Datasets = {
  __typename?: 'machine_learning_datasets';
  /** The azure build id in which the dataset is generated */
  build_id_azure?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  dataset_features: Array<Machine_Learning_Dataset_Feature>;
  /** An aggregate relationship */
  dataset_features_aggregate: Machine_Learning_Dataset_Feature_Aggregate;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An array relationship */
  model_datasets: Array<Machine_Learning_Model_Dataset>;
  name: Scalars['String'];
  /** The ID of the target project */
  project_id?: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamptz'];
};


/** Metadata of datasets */
export type Machine_Learning_DatasetsDataset_FeaturesArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Dataset_Feature_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Dataset_Feature_Order_By>>;
  where?: Maybe<Machine_Learning_Dataset_Feature_Bool_Exp>;
};


/** Metadata of datasets */
export type Machine_Learning_DatasetsDataset_Features_AggregateArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Dataset_Feature_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Dataset_Feature_Order_By>>;
  where?: Maybe<Machine_Learning_Dataset_Feature_Bool_Exp>;
};


/** Metadata of datasets */
export type Machine_Learning_DatasetsModel_DatasetsArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Model_Dataset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Model_Dataset_Order_By>>;
  where?: Maybe<Machine_Learning_Model_Dataset_Bool_Exp>;
};

/** aggregated selection of "machine_learning.datasets" */
export type Machine_Learning_Datasets_Aggregate = {
  __typename?: 'machine_learning_datasets_aggregate';
  aggregate?: Maybe<Machine_Learning_Datasets_Aggregate_Fields>;
  nodes: Array<Machine_Learning_Datasets>;
};

/** aggregate fields of "machine_learning.datasets" */
export type Machine_Learning_Datasets_Aggregate_Fields = {
  __typename?: 'machine_learning_datasets_aggregate_fields';
  avg?: Maybe<Machine_Learning_Datasets_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Machine_Learning_Datasets_Max_Fields>;
  min?: Maybe<Machine_Learning_Datasets_Min_Fields>;
  stddev?: Maybe<Machine_Learning_Datasets_Stddev_Fields>;
  stddev_pop?: Maybe<Machine_Learning_Datasets_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Machine_Learning_Datasets_Stddev_Samp_Fields>;
  sum?: Maybe<Machine_Learning_Datasets_Sum_Fields>;
  var_pop?: Maybe<Machine_Learning_Datasets_Var_Pop_Fields>;
  var_samp?: Maybe<Machine_Learning_Datasets_Var_Samp_Fields>;
  variance?: Maybe<Machine_Learning_Datasets_Variance_Fields>;
};


/** aggregate fields of "machine_learning.datasets" */
export type Machine_Learning_Datasets_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Machine_Learning_Datasets_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Machine_Learning_Datasets_Avg_Fields = {
  __typename?: 'machine_learning_datasets_avg_fields';
  /** The azure build id in which the dataset is generated */
  build_id_azure?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "machine_learning.datasets". All fields are combined with a logical 'AND'. */
export type Machine_Learning_Datasets_Bool_Exp = {
  _and?: Maybe<Array<Machine_Learning_Datasets_Bool_Exp>>;
  _not?: Maybe<Machine_Learning_Datasets_Bool_Exp>;
  _or?: Maybe<Array<Machine_Learning_Datasets_Bool_Exp>>;
  build_id_azure?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  dataset_features?: Maybe<Machine_Learning_Dataset_Feature_Bool_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  model_datasets?: Maybe<Machine_Learning_Model_Dataset_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  project_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "machine_learning.datasets" */
export enum Machine_Learning_Datasets_Constraint {
  /** unique or primary key constraint on columns "name" */
  DatasetsNameKey = 'datasets_name_key',
  /** unique or primary key constraint on columns "id" */
  DatasetsPkey = 'datasets_pkey'
}

/** input type for incrementing numeric columns in table "machine_learning.datasets" */
export type Machine_Learning_Datasets_Inc_Input = {
  /** The azure build id in which the dataset is generated */
  build_id_azure?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "machine_learning.datasets" */
export type Machine_Learning_Datasets_Insert_Input = {
  /** The azure build id in which the dataset is generated */
  build_id_azure?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dataset_features?: Maybe<Machine_Learning_Dataset_Feature_Arr_Rel_Insert_Input>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  model_datasets?: Maybe<Machine_Learning_Model_Dataset_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  /** The ID of the target project */
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Machine_Learning_Datasets_Max_Fields = {
  __typename?: 'machine_learning_datasets_max_fields';
  /** The azure build id in which the dataset is generated */
  build_id_azure?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  /** The ID of the target project */
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Machine_Learning_Datasets_Min_Fields = {
  __typename?: 'machine_learning_datasets_min_fields';
  /** The azure build id in which the dataset is generated */
  build_id_azure?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  /** The ID of the target project */
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "machine_learning.datasets" */
export type Machine_Learning_Datasets_Mutation_Response = {
  __typename?: 'machine_learning_datasets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Machine_Learning_Datasets>;
};

/** input type for inserting object relation for remote table "machine_learning.datasets" */
export type Machine_Learning_Datasets_Obj_Rel_Insert_Input = {
  data: Machine_Learning_Datasets_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Machine_Learning_Datasets_On_Conflict>;
};

/** on_conflict condition type for table "machine_learning.datasets" */
export type Machine_Learning_Datasets_On_Conflict = {
  constraint: Machine_Learning_Datasets_Constraint;
  update_columns?: Array<Machine_Learning_Datasets_Update_Column>;
  where?: Maybe<Machine_Learning_Datasets_Bool_Exp>;
};

/** Ordering options when selecting data from "machine_learning.datasets". */
export type Machine_Learning_Datasets_Order_By = {
  build_id_azure?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  dataset_features_aggregate?: Maybe<Machine_Learning_Dataset_Feature_Aggregate_Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  model_datasets_aggregate?: Maybe<Machine_Learning_Model_Dataset_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: machine_learning.datasets */
export type Machine_Learning_Datasets_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "machine_learning.datasets" */
export enum Machine_Learning_Datasets_Select_Column {
  /** column name */
  BuildIdAzure = 'build_id_azure',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "machine_learning.datasets" */
export type Machine_Learning_Datasets_Set_Input = {
  /** The azure build id in which the dataset is generated */
  build_id_azure?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Machine_Learning_Datasets_Stddev_Fields = {
  __typename?: 'machine_learning_datasets_stddev_fields';
  /** The azure build id in which the dataset is generated */
  build_id_azure?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Machine_Learning_Datasets_Stddev_Pop_Fields = {
  __typename?: 'machine_learning_datasets_stddev_pop_fields';
  /** The azure build id in which the dataset is generated */
  build_id_azure?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Machine_Learning_Datasets_Stddev_Samp_Fields = {
  __typename?: 'machine_learning_datasets_stddev_samp_fields';
  /** The azure build id in which the dataset is generated */
  build_id_azure?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "machine_learning_datasets" */
export type Machine_Learning_Datasets_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Machine_Learning_Datasets_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Machine_Learning_Datasets_Stream_Cursor_Value_Input = {
  /** The azure build id in which the dataset is generated */
  build_id_azure?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  /** The ID of the target project */
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Machine_Learning_Datasets_Sum_Fields = {
  __typename?: 'machine_learning_datasets_sum_fields';
  /** The azure build id in which the dataset is generated */
  build_id_azure?: Maybe<Scalars['Int']>;
};

/** update columns of table "machine_learning.datasets" */
export enum Machine_Learning_Datasets_Update_Column {
  /** column name */
  BuildIdAzure = 'build_id_azure',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Machine_Learning_Datasets_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Machine_Learning_Datasets_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Machine_Learning_Datasets_Set_Input>;
  where: Machine_Learning_Datasets_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Machine_Learning_Datasets_Var_Pop_Fields = {
  __typename?: 'machine_learning_datasets_var_pop_fields';
  /** The azure build id in which the dataset is generated */
  build_id_azure?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Machine_Learning_Datasets_Var_Samp_Fields = {
  __typename?: 'machine_learning_datasets_var_samp_fields';
  /** The azure build id in which the dataset is generated */
  build_id_azure?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Machine_Learning_Datasets_Variance_Fields = {
  __typename?: 'machine_learning_datasets_variance_fields';
  /** The azure build id in which the dataset is generated */
  build_id_azure?: Maybe<Scalars['Float']>;
};

/** Individual feature files */
export type Machine_Learning_Features = {
  __typename?: 'machine_learning_features';
  /** An object relationship */
  blob: Machine_Learning_Blobs;
  blob_id: Scalars['uuid'];
  /** An array relationship */
  dataset_features: Array<Machine_Learning_Dataset_Feature>;
  /** An aggregate relationship */
  dataset_features_aggregate: Machine_Learning_Dataset_Feature_Aggregate;
  id: Scalars['uuid'];
  /** An object relationship */
  source_recording?: Maybe<Recordings>;
  source_recording_id?: Maybe<Scalars['uuid']>;
};


/** Individual feature files */
export type Machine_Learning_FeaturesDataset_FeaturesArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Dataset_Feature_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Dataset_Feature_Order_By>>;
  where?: Maybe<Machine_Learning_Dataset_Feature_Bool_Exp>;
};


/** Individual feature files */
export type Machine_Learning_FeaturesDataset_Features_AggregateArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Dataset_Feature_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Dataset_Feature_Order_By>>;
  where?: Maybe<Machine_Learning_Dataset_Feature_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "machine_learning.features". All fields are combined with a logical 'AND'. */
export type Machine_Learning_Features_Bool_Exp = {
  _and?: Maybe<Array<Machine_Learning_Features_Bool_Exp>>;
  _not?: Maybe<Machine_Learning_Features_Bool_Exp>;
  _or?: Maybe<Array<Machine_Learning_Features_Bool_Exp>>;
  blob?: Maybe<Machine_Learning_Blobs_Bool_Exp>;
  blob_id?: Maybe<Uuid_Comparison_Exp>;
  dataset_features?: Maybe<Machine_Learning_Dataset_Feature_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  source_recording?: Maybe<Recordings_Bool_Exp>;
  source_recording_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "machine_learning.features" */
export enum Machine_Learning_Features_Constraint {
  /** unique or primary key constraint on columns "blob_id" */
  FeaturesBlobIdKey = 'features_blob_id_key',
  /** unique or primary key constraint on columns "id" */
  FeaturesPkey = 'features_pkey'
}

/** input type for inserting data into table "machine_learning.features" */
export type Machine_Learning_Features_Insert_Input = {
  blob?: Maybe<Machine_Learning_Blobs_Obj_Rel_Insert_Input>;
  blob_id?: Maybe<Scalars['uuid']>;
  dataset_features?: Maybe<Machine_Learning_Dataset_Feature_Arr_Rel_Insert_Input>;
  source_recording?: Maybe<Recordings_Obj_Rel_Insert_Input>;
  source_recording_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "machine_learning.features" */
export type Machine_Learning_Features_Mutation_Response = {
  __typename?: 'machine_learning_features_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Machine_Learning_Features>;
};

/** input type for inserting object relation for remote table "machine_learning.features" */
export type Machine_Learning_Features_Obj_Rel_Insert_Input = {
  data: Machine_Learning_Features_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Machine_Learning_Features_On_Conflict>;
};

/** on_conflict condition type for table "machine_learning.features" */
export type Machine_Learning_Features_On_Conflict = {
  constraint: Machine_Learning_Features_Constraint;
  update_columns?: Array<Machine_Learning_Features_Update_Column>;
  where?: Maybe<Machine_Learning_Features_Bool_Exp>;
};

/** Ordering options when selecting data from "machine_learning.features". */
export type Machine_Learning_Features_Order_By = {
  blob?: Maybe<Machine_Learning_Blobs_Order_By>;
  blob_id?: Maybe<Order_By>;
  dataset_features_aggregate?: Maybe<Machine_Learning_Dataset_Feature_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  source_recording?: Maybe<Recordings_Order_By>;
  source_recording_id?: Maybe<Order_By>;
};

/** primary key columns input for table: machine_learning.features */
export type Machine_Learning_Features_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "machine_learning.features" */
export enum Machine_Learning_Features_Select_Column {
  /** column name */
  BlobId = 'blob_id',
  /** column name */
  Id = 'id',
  /** column name */
  SourceRecordingId = 'source_recording_id'
}

/** input type for updating data in table "machine_learning.features" */
export type Machine_Learning_Features_Set_Input = {
  blob_id?: Maybe<Scalars['uuid']>;
  source_recording_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "machine_learning_features" */
export type Machine_Learning_Features_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Machine_Learning_Features_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Machine_Learning_Features_Stream_Cursor_Value_Input = {
  blob_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  source_recording_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "machine_learning.features" */
export enum Machine_Learning_Features_Update_Column {
  /** column name */
  BlobId = 'blob_id',
  /** column name */
  SourceRecordingId = 'source_recording_id'
}

export type Machine_Learning_Features_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Machine_Learning_Features_Set_Input>;
  where: Machine_Learning_Features_Bool_Exp;
};

/** Link table between model and blobs */
export type Machine_Learning_Model_Blob = {
  __typename?: 'machine_learning_model_blob';
  /** An object relationship */
  blob: Machine_Learning_Blobs;
  blob_id: Scalars['uuid'];
  /** An object relationship */
  model: Machine_Learning_Models;
  model_id: Scalars['uuid'];
};

/** order by aggregate values of table "machine_learning.model_blob" */
export type Machine_Learning_Model_Blob_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Machine_Learning_Model_Blob_Max_Order_By>;
  min?: Maybe<Machine_Learning_Model_Blob_Min_Order_By>;
};

/** input type for inserting array relation for remote table "machine_learning.model_blob" */
export type Machine_Learning_Model_Blob_Arr_Rel_Insert_Input = {
  data: Array<Machine_Learning_Model_Blob_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Machine_Learning_Model_Blob_On_Conflict>;
};

/** Boolean expression to filter rows from the table "machine_learning.model_blob". All fields are combined with a logical 'AND'. */
export type Machine_Learning_Model_Blob_Bool_Exp = {
  _and?: Maybe<Array<Machine_Learning_Model_Blob_Bool_Exp>>;
  _not?: Maybe<Machine_Learning_Model_Blob_Bool_Exp>;
  _or?: Maybe<Array<Machine_Learning_Model_Blob_Bool_Exp>>;
  blob?: Maybe<Machine_Learning_Blobs_Bool_Exp>;
  blob_id?: Maybe<Uuid_Comparison_Exp>;
  model?: Maybe<Machine_Learning_Models_Bool_Exp>;
  model_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "machine_learning.model_blob" */
export enum Machine_Learning_Model_Blob_Constraint {
  /** unique or primary key constraint on columns "model_id", "blob_id" */
  ModelBlobPkey = 'model_blob_pkey'
}

/** input type for inserting data into table "machine_learning.model_blob" */
export type Machine_Learning_Model_Blob_Insert_Input = {
  blob?: Maybe<Machine_Learning_Blobs_Obj_Rel_Insert_Input>;
  blob_id?: Maybe<Scalars['uuid']>;
  model?: Maybe<Machine_Learning_Models_Obj_Rel_Insert_Input>;
  model_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "machine_learning.model_blob" */
export type Machine_Learning_Model_Blob_Max_Order_By = {
  blob_id?: Maybe<Order_By>;
  model_id?: Maybe<Order_By>;
};

/** order by min() on columns of table "machine_learning.model_blob" */
export type Machine_Learning_Model_Blob_Min_Order_By = {
  blob_id?: Maybe<Order_By>;
  model_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "machine_learning.model_blob" */
export type Machine_Learning_Model_Blob_Mutation_Response = {
  __typename?: 'machine_learning_model_blob_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Machine_Learning_Model_Blob>;
};

/** on_conflict condition type for table "machine_learning.model_blob" */
export type Machine_Learning_Model_Blob_On_Conflict = {
  constraint: Machine_Learning_Model_Blob_Constraint;
  update_columns?: Array<Machine_Learning_Model_Blob_Update_Column>;
  where?: Maybe<Machine_Learning_Model_Blob_Bool_Exp>;
};

/** Ordering options when selecting data from "machine_learning.model_blob". */
export type Machine_Learning_Model_Blob_Order_By = {
  blob?: Maybe<Machine_Learning_Blobs_Order_By>;
  blob_id?: Maybe<Order_By>;
  model?: Maybe<Machine_Learning_Models_Order_By>;
  model_id?: Maybe<Order_By>;
};

/** primary key columns input for table: machine_learning.model_blob */
export type Machine_Learning_Model_Blob_Pk_Columns_Input = {
  blob_id: Scalars['uuid'];
  model_id: Scalars['uuid'];
};

/** select columns of table "machine_learning.model_blob" */
export enum Machine_Learning_Model_Blob_Select_Column {
  /** column name */
  BlobId = 'blob_id',
  /** column name */
  ModelId = 'model_id'
}

/** input type for updating data in table "machine_learning.model_blob" */
export type Machine_Learning_Model_Blob_Set_Input = {
  blob_id?: Maybe<Scalars['uuid']>;
  model_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "machine_learning_model_blob" */
export type Machine_Learning_Model_Blob_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Machine_Learning_Model_Blob_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Machine_Learning_Model_Blob_Stream_Cursor_Value_Input = {
  blob_id?: Maybe<Scalars['uuid']>;
  model_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "machine_learning.model_blob" */
export enum Machine_Learning_Model_Blob_Update_Column {
  /** column name */
  BlobId = 'blob_id',
  /** column name */
  ModelId = 'model_id'
}

export type Machine_Learning_Model_Blob_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Machine_Learning_Model_Blob_Set_Input>;
  where: Machine_Learning_Model_Blob_Bool_Exp;
};

/** Link table between model and dataset */
export type Machine_Learning_Model_Dataset = {
  __typename?: 'machine_learning_model_dataset';
  /** An object relationship */
  dataset: Machine_Learning_Datasets;
  dataset_id: Scalars['uuid'];
  /** An object relationship */
  model: Machine_Learning_Models;
  model_id: Scalars['uuid'];
};

/** order by aggregate values of table "machine_learning.model_dataset" */
export type Machine_Learning_Model_Dataset_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Machine_Learning_Model_Dataset_Max_Order_By>;
  min?: Maybe<Machine_Learning_Model_Dataset_Min_Order_By>;
};

/** input type for inserting array relation for remote table "machine_learning.model_dataset" */
export type Machine_Learning_Model_Dataset_Arr_Rel_Insert_Input = {
  data: Array<Machine_Learning_Model_Dataset_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Machine_Learning_Model_Dataset_On_Conflict>;
};

/** Boolean expression to filter rows from the table "machine_learning.model_dataset". All fields are combined with a logical 'AND'. */
export type Machine_Learning_Model_Dataset_Bool_Exp = {
  _and?: Maybe<Array<Machine_Learning_Model_Dataset_Bool_Exp>>;
  _not?: Maybe<Machine_Learning_Model_Dataset_Bool_Exp>;
  _or?: Maybe<Array<Machine_Learning_Model_Dataset_Bool_Exp>>;
  dataset?: Maybe<Machine_Learning_Datasets_Bool_Exp>;
  dataset_id?: Maybe<Uuid_Comparison_Exp>;
  model?: Maybe<Machine_Learning_Models_Bool_Exp>;
  model_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "machine_learning.model_dataset" */
export enum Machine_Learning_Model_Dataset_Constraint {
  /** unique or primary key constraint on columns "model_id", "dataset_id" */
  ModelDatasetPkey = 'model_dataset_pkey'
}

/** input type for inserting data into table "machine_learning.model_dataset" */
export type Machine_Learning_Model_Dataset_Insert_Input = {
  dataset?: Maybe<Machine_Learning_Datasets_Obj_Rel_Insert_Input>;
  dataset_id?: Maybe<Scalars['uuid']>;
  model?: Maybe<Machine_Learning_Models_Obj_Rel_Insert_Input>;
  model_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "machine_learning.model_dataset" */
export type Machine_Learning_Model_Dataset_Max_Order_By = {
  dataset_id?: Maybe<Order_By>;
  model_id?: Maybe<Order_By>;
};

/** order by min() on columns of table "machine_learning.model_dataset" */
export type Machine_Learning_Model_Dataset_Min_Order_By = {
  dataset_id?: Maybe<Order_By>;
  model_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "machine_learning.model_dataset" */
export type Machine_Learning_Model_Dataset_Mutation_Response = {
  __typename?: 'machine_learning_model_dataset_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Machine_Learning_Model_Dataset>;
};

/** on_conflict condition type for table "machine_learning.model_dataset" */
export type Machine_Learning_Model_Dataset_On_Conflict = {
  constraint: Machine_Learning_Model_Dataset_Constraint;
  update_columns?: Array<Machine_Learning_Model_Dataset_Update_Column>;
  where?: Maybe<Machine_Learning_Model_Dataset_Bool_Exp>;
};

/** Ordering options when selecting data from "machine_learning.model_dataset". */
export type Machine_Learning_Model_Dataset_Order_By = {
  dataset?: Maybe<Machine_Learning_Datasets_Order_By>;
  dataset_id?: Maybe<Order_By>;
  model?: Maybe<Machine_Learning_Models_Order_By>;
  model_id?: Maybe<Order_By>;
};

/** primary key columns input for table: machine_learning.model_dataset */
export type Machine_Learning_Model_Dataset_Pk_Columns_Input = {
  dataset_id: Scalars['uuid'];
  model_id: Scalars['uuid'];
};

/** select columns of table "machine_learning.model_dataset" */
export enum Machine_Learning_Model_Dataset_Select_Column {
  /** column name */
  DatasetId = 'dataset_id',
  /** column name */
  ModelId = 'model_id'
}

/** input type for updating data in table "machine_learning.model_dataset" */
export type Machine_Learning_Model_Dataset_Set_Input = {
  dataset_id?: Maybe<Scalars['uuid']>;
  model_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "machine_learning_model_dataset" */
export type Machine_Learning_Model_Dataset_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Machine_Learning_Model_Dataset_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Machine_Learning_Model_Dataset_Stream_Cursor_Value_Input = {
  dataset_id?: Maybe<Scalars['uuid']>;
  model_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "machine_learning.model_dataset" */
export enum Machine_Learning_Model_Dataset_Update_Column {
  /** column name */
  DatasetId = 'dataset_id',
  /** column name */
  ModelId = 'model_id'
}

export type Machine_Learning_Model_Dataset_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Machine_Learning_Model_Dataset_Set_Input>;
  where: Machine_Learning_Model_Dataset_Bool_Exp;
};

/** Keep track of the recordings that was used in the training process of the model */
export type Machine_Learning_Model_Recording = {
  __typename?: 'machine_learning_model_recording';
  /** An object relationship */
  model: Machine_Learning_Models;
  model_id: Scalars['uuid'];
  /** A note to describe the contribution of the recording to the model */
  note?: Maybe<Scalars['String']>;
  /** An object relationship */
  recording: Recordings;
  recording_id: Scalars['uuid'];
};

/** aggregated selection of "machine_learning.model_recording" */
export type Machine_Learning_Model_Recording_Aggregate = {
  __typename?: 'machine_learning_model_recording_aggregate';
  aggregate?: Maybe<Machine_Learning_Model_Recording_Aggregate_Fields>;
  nodes: Array<Machine_Learning_Model_Recording>;
};

/** aggregate fields of "machine_learning.model_recording" */
export type Machine_Learning_Model_Recording_Aggregate_Fields = {
  __typename?: 'machine_learning_model_recording_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Machine_Learning_Model_Recording_Max_Fields>;
  min?: Maybe<Machine_Learning_Model_Recording_Min_Fields>;
};


/** aggregate fields of "machine_learning.model_recording" */
export type Machine_Learning_Model_Recording_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Machine_Learning_Model_Recording_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "machine_learning.model_recording" */
export type Machine_Learning_Model_Recording_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Machine_Learning_Model_Recording_Max_Order_By>;
  min?: Maybe<Machine_Learning_Model_Recording_Min_Order_By>;
};

/** input type for inserting array relation for remote table "machine_learning.model_recording" */
export type Machine_Learning_Model_Recording_Arr_Rel_Insert_Input = {
  data: Array<Machine_Learning_Model_Recording_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Machine_Learning_Model_Recording_On_Conflict>;
};

/** Boolean expression to filter rows from the table "machine_learning.model_recording". All fields are combined with a logical 'AND'. */
export type Machine_Learning_Model_Recording_Bool_Exp = {
  _and?: Maybe<Array<Machine_Learning_Model_Recording_Bool_Exp>>;
  _not?: Maybe<Machine_Learning_Model_Recording_Bool_Exp>;
  _or?: Maybe<Array<Machine_Learning_Model_Recording_Bool_Exp>>;
  model?: Maybe<Machine_Learning_Models_Bool_Exp>;
  model_id?: Maybe<Uuid_Comparison_Exp>;
  note?: Maybe<String_Comparison_Exp>;
  recording?: Maybe<Recordings_Bool_Exp>;
  recording_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "machine_learning.model_recording" */
export enum Machine_Learning_Model_Recording_Constraint {
  /** unique or primary key constraint on columns "model_id", "recording_id" */
  ModelRecordingPkey = 'model_recording_pkey'
}

/** input type for inserting data into table "machine_learning.model_recording" */
export type Machine_Learning_Model_Recording_Insert_Input = {
  model?: Maybe<Machine_Learning_Models_Obj_Rel_Insert_Input>;
  model_id?: Maybe<Scalars['uuid']>;
  /** A note to describe the contribution of the recording to the model */
  note?: Maybe<Scalars['String']>;
  recording?: Maybe<Recordings_Obj_Rel_Insert_Input>;
  recording_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Machine_Learning_Model_Recording_Max_Fields = {
  __typename?: 'machine_learning_model_recording_max_fields';
  model_id?: Maybe<Scalars['uuid']>;
  /** A note to describe the contribution of the recording to the model */
  note?: Maybe<Scalars['String']>;
  recording_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "machine_learning.model_recording" */
export type Machine_Learning_Model_Recording_Max_Order_By = {
  model_id?: Maybe<Order_By>;
  /** A note to describe the contribution of the recording to the model */
  note?: Maybe<Order_By>;
  recording_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Machine_Learning_Model_Recording_Min_Fields = {
  __typename?: 'machine_learning_model_recording_min_fields';
  model_id?: Maybe<Scalars['uuid']>;
  /** A note to describe the contribution of the recording to the model */
  note?: Maybe<Scalars['String']>;
  recording_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "machine_learning.model_recording" */
export type Machine_Learning_Model_Recording_Min_Order_By = {
  model_id?: Maybe<Order_By>;
  /** A note to describe the contribution of the recording to the model */
  note?: Maybe<Order_By>;
  recording_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "machine_learning.model_recording" */
export type Machine_Learning_Model_Recording_Mutation_Response = {
  __typename?: 'machine_learning_model_recording_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Machine_Learning_Model_Recording>;
};

/** on_conflict condition type for table "machine_learning.model_recording" */
export type Machine_Learning_Model_Recording_On_Conflict = {
  constraint: Machine_Learning_Model_Recording_Constraint;
  update_columns?: Array<Machine_Learning_Model_Recording_Update_Column>;
  where?: Maybe<Machine_Learning_Model_Recording_Bool_Exp>;
};

/** Ordering options when selecting data from "machine_learning.model_recording". */
export type Machine_Learning_Model_Recording_Order_By = {
  model?: Maybe<Machine_Learning_Models_Order_By>;
  model_id?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  recording?: Maybe<Recordings_Order_By>;
  recording_id?: Maybe<Order_By>;
};

/** primary key columns input for table: machine_learning.model_recording */
export type Machine_Learning_Model_Recording_Pk_Columns_Input = {
  model_id: Scalars['uuid'];
  recording_id: Scalars['uuid'];
};

/** select columns of table "machine_learning.model_recording" */
export enum Machine_Learning_Model_Recording_Select_Column {
  /** column name */
  ModelId = 'model_id',
  /** column name */
  Note = 'note',
  /** column name */
  RecordingId = 'recording_id'
}

/** input type for updating data in table "machine_learning.model_recording" */
export type Machine_Learning_Model_Recording_Set_Input = {
  model_id?: Maybe<Scalars['uuid']>;
  /** A note to describe the contribution of the recording to the model */
  note?: Maybe<Scalars['String']>;
  recording_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "machine_learning_model_recording" */
export type Machine_Learning_Model_Recording_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Machine_Learning_Model_Recording_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Machine_Learning_Model_Recording_Stream_Cursor_Value_Input = {
  model_id?: Maybe<Scalars['uuid']>;
  /** A note to describe the contribution of the recording to the model */
  note?: Maybe<Scalars['String']>;
  recording_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "machine_learning.model_recording" */
export enum Machine_Learning_Model_Recording_Update_Column {
  /** column name */
  ModelId = 'model_id',
  /** column name */
  Note = 'note',
  /** column name */
  RecordingId = 'recording_id'
}

export type Machine_Learning_Model_Recording_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Machine_Learning_Model_Recording_Set_Input>;
  where: Machine_Learning_Model_Recording_Bool_Exp;
};

/** Enum table for machine_learning.models.type_id */
export type Machine_Learning_Model_Types = {
  __typename?: 'machine_learning_model_types';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

/** Boolean expression to filter rows from the table "machine_learning.model_types". All fields are combined with a logical 'AND'. */
export type Machine_Learning_Model_Types_Bool_Exp = {
  _and?: Maybe<Array<Machine_Learning_Model_Types_Bool_Exp>>;
  _not?: Maybe<Machine_Learning_Model_Types_Bool_Exp>;
  _or?: Maybe<Array<Machine_Learning_Model_Types_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
};

export enum Machine_Learning_Model_Types_Enum {
  /** included so the ""model_types"" could be tracked as an ""enum"" table in the graphql api; do not use this value in production */
  PlaceHolder = '_place_holder'
}

/** Boolean expression to compare columns of type "machine_learning_model_types_enum". All fields are combined with logical 'AND'. */
export type Machine_Learning_Model_Types_Enum_Comparison_Exp = {
  _eq?: Maybe<Machine_Learning_Model_Types_Enum>;
  _in?: Maybe<Array<Machine_Learning_Model_Types_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Machine_Learning_Model_Types_Enum>;
  _nin?: Maybe<Array<Machine_Learning_Model_Types_Enum>>;
};

/** Ordering options when selecting data from "machine_learning.model_types". */
export type Machine_Learning_Model_Types_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** select columns of table "machine_learning.model_types" */
export enum Machine_Learning_Model_Types_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** Streaming cursor of the table "machine_learning_model_types" */
export type Machine_Learning_Model_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Machine_Learning_Model_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Machine_Learning_Model_Types_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** table to store machine learning models metadata */
export type Machine_Learning_Models = {
  __typename?: 'machine_learning_models';
  branch_name?: Maybe<Scalars['String']>;
  /** If the model is built in Azure, this is the build id */
  build_id_azure?: Maybe<Scalars['Int']>;
  commit_id?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An array relationship */
  model_blobs: Array<Machine_Learning_Model_Blob>;
  /** An array relationship */
  model_datasets: Array<Machine_Learning_Model_Dataset>;
  /** An array relationship */
  model_source_recordings: Array<Machine_Learning_Model_Recording>;
  /** An aggregate relationship */
  model_source_recordings_aggregate: Machine_Learning_Model_Recording_Aggregate;
  /** An object relationship */
  model_type?: Maybe<Machine_Learning_Model_Types>;
  name: Scalars['String'];
  /** (Deprecated) use type_id to store the model's type */
  type?: Maybe<Scalars['String']>;
  type_id?: Maybe<Machine_Learning_Model_Types_Enum>;
  updated_at: Scalars['timestamptz'];
};


/** table to store machine learning models metadata */
export type Machine_Learning_ModelsModel_BlobsArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Model_Blob_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Model_Blob_Order_By>>;
  where?: Maybe<Machine_Learning_Model_Blob_Bool_Exp>;
};


/** table to store machine learning models metadata */
export type Machine_Learning_ModelsModel_DatasetsArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Model_Dataset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Model_Dataset_Order_By>>;
  where?: Maybe<Machine_Learning_Model_Dataset_Bool_Exp>;
};


/** table to store machine learning models metadata */
export type Machine_Learning_ModelsModel_Source_RecordingsArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Model_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Model_Recording_Order_By>>;
  where?: Maybe<Machine_Learning_Model_Recording_Bool_Exp>;
};


/** table to store machine learning models metadata */
export type Machine_Learning_ModelsModel_Source_Recordings_AggregateArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Model_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Model_Recording_Order_By>>;
  where?: Maybe<Machine_Learning_Model_Recording_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "machine_learning.models". All fields are combined with a logical 'AND'. */
export type Machine_Learning_Models_Bool_Exp = {
  _and?: Maybe<Array<Machine_Learning_Models_Bool_Exp>>;
  _not?: Maybe<Machine_Learning_Models_Bool_Exp>;
  _or?: Maybe<Array<Machine_Learning_Models_Bool_Exp>>;
  branch_name?: Maybe<String_Comparison_Exp>;
  build_id_azure?: Maybe<Int_Comparison_Exp>;
  commit_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  model_blobs?: Maybe<Machine_Learning_Model_Blob_Bool_Exp>;
  model_datasets?: Maybe<Machine_Learning_Model_Dataset_Bool_Exp>;
  model_source_recordings?: Maybe<Machine_Learning_Model_Recording_Bool_Exp>;
  model_type?: Maybe<Machine_Learning_Model_Types_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  type_id?: Maybe<Machine_Learning_Model_Types_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "machine_learning.models" */
export enum Machine_Learning_Models_Constraint {
  /** unique or primary key constraint on columns "name" */
  ModelsNameKey = 'models_name_key',
  /** unique or primary key constraint on columns "id" */
  ModelsPkey = 'models_pkey'
}

/** input type for incrementing numeric columns in table "machine_learning.models" */
export type Machine_Learning_Models_Inc_Input = {
  /** If the model is built in Azure, this is the build id */
  build_id_azure?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "machine_learning.models" */
export type Machine_Learning_Models_Insert_Input = {
  branch_name?: Maybe<Scalars['String']>;
  /** If the model is built in Azure, this is the build id */
  build_id_azure?: Maybe<Scalars['Int']>;
  commit_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  model_blobs?: Maybe<Machine_Learning_Model_Blob_Arr_Rel_Insert_Input>;
  model_datasets?: Maybe<Machine_Learning_Model_Dataset_Arr_Rel_Insert_Input>;
  model_source_recordings?: Maybe<Machine_Learning_Model_Recording_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  /** (Deprecated) use type_id to store the model's type */
  type?: Maybe<Scalars['String']>;
  type_id?: Maybe<Machine_Learning_Model_Types_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "machine_learning.models" */
export type Machine_Learning_Models_Mutation_Response = {
  __typename?: 'machine_learning_models_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Machine_Learning_Models>;
};

/** input type for inserting object relation for remote table "machine_learning.models" */
export type Machine_Learning_Models_Obj_Rel_Insert_Input = {
  data: Machine_Learning_Models_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Machine_Learning_Models_On_Conflict>;
};

/** on_conflict condition type for table "machine_learning.models" */
export type Machine_Learning_Models_On_Conflict = {
  constraint: Machine_Learning_Models_Constraint;
  update_columns?: Array<Machine_Learning_Models_Update_Column>;
  where?: Maybe<Machine_Learning_Models_Bool_Exp>;
};

/** Ordering options when selecting data from "machine_learning.models". */
export type Machine_Learning_Models_Order_By = {
  branch_name?: Maybe<Order_By>;
  build_id_azure?: Maybe<Order_By>;
  commit_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  model_blobs_aggregate?: Maybe<Machine_Learning_Model_Blob_Aggregate_Order_By>;
  model_datasets_aggregate?: Maybe<Machine_Learning_Model_Dataset_Aggregate_Order_By>;
  model_source_recordings_aggregate?: Maybe<Machine_Learning_Model_Recording_Aggregate_Order_By>;
  model_type?: Maybe<Machine_Learning_Model_Types_Order_By>;
  name?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  type_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: machine_learning.models */
export type Machine_Learning_Models_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "machine_learning.models" */
export enum Machine_Learning_Models_Select_Column {
  /** column name */
  BranchName = 'branch_name',
  /** column name */
  BuildIdAzure = 'build_id_azure',
  /** column name */
  CommitId = 'commit_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  TypeId = 'type_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "machine_learning.models" */
export type Machine_Learning_Models_Set_Input = {
  branch_name?: Maybe<Scalars['String']>;
  /** If the model is built in Azure, this is the build id */
  build_id_azure?: Maybe<Scalars['Int']>;
  commit_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** (Deprecated) use type_id to store the model's type */
  type?: Maybe<Scalars['String']>;
  type_id?: Maybe<Machine_Learning_Model_Types_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "machine_learning_models" */
export type Machine_Learning_Models_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Machine_Learning_Models_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Machine_Learning_Models_Stream_Cursor_Value_Input = {
  branch_name?: Maybe<Scalars['String']>;
  /** If the model is built in Azure, this is the build id */
  build_id_azure?: Maybe<Scalars['Int']>;
  commit_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  /** (Deprecated) use type_id to store the model's type */
  type?: Maybe<Scalars['String']>;
  type_id?: Maybe<Machine_Learning_Model_Types_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "machine_learning.models" */
export enum Machine_Learning_Models_Update_Column {
  /** column name */
  BranchName = 'branch_name',
  /** column name */
  BuildIdAzure = 'build_id_azure',
  /** column name */
  CommitId = 'commit_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  TypeId = 'type_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Machine_Learning_Models_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Machine_Learning_Models_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Machine_Learning_Models_Set_Input>;
  where: Machine_Learning_Models_Bool_Exp;
};

/** columns and relationships of "media" */
export type Media = {
  __typename?: 'media';
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  expectation_media: Array<Integration_Test_Expectation_Media>;
  /** An aggregate relationship */
  expectation_media_aggregate: Integration_Test_Expectation_Media_Aggregate;
  /** An array relationship */
  field_option_media: Array<Field_Option_Media>;
  /** An aggregate relationship */
  field_option_media_aggregate: Field_Option_Media_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  instruction_media: Array<Integration_Test_Instruction_Media>;
  /** An aggregate relationship */
  instruction_media_aggregate: Integration_Test_Instruction_Media_Aggregate;
  /** An object relationship */
  media_type: Media_Type;
  /** An array relationship */
  option_media: Array<Option_Media>;
  /** An aggregate relationship */
  option_media_aggregate: Option_Media_Aggregate;
  /** An array relationship */
  task_media: Array<Task_Media>;
  /** An aggregate relationship */
  task_media_aggregate: Task_Media_Aggregate;
  title: Scalars['String'];
  type: Media_Type_Enum;
  updated_at: Scalars['timestamptz'];
  url: Scalars['String'];
};


/** columns and relationships of "media" */
export type MediaExpectation_MediaArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Expectation_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Expectation_Media_Order_By>>;
  where?: Maybe<Integration_Test_Expectation_Media_Bool_Exp>;
};


/** columns and relationships of "media" */
export type MediaExpectation_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Expectation_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Expectation_Media_Order_By>>;
  where?: Maybe<Integration_Test_Expectation_Media_Bool_Exp>;
};


/** columns and relationships of "media" */
export type MediaField_Option_MediaArgs = {
  distinct_on?: Maybe<Array<Field_Option_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Field_Option_Media_Order_By>>;
  where?: Maybe<Field_Option_Media_Bool_Exp>;
};


/** columns and relationships of "media" */
export type MediaField_Option_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Field_Option_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Field_Option_Media_Order_By>>;
  where?: Maybe<Field_Option_Media_Bool_Exp>;
};


/** columns and relationships of "media" */
export type MediaInstruction_MediaArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Instruction_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Instruction_Media_Order_By>>;
  where?: Maybe<Integration_Test_Instruction_Media_Bool_Exp>;
};


/** columns and relationships of "media" */
export type MediaInstruction_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Instruction_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Instruction_Media_Order_By>>;
  where?: Maybe<Integration_Test_Instruction_Media_Bool_Exp>;
};


/** columns and relationships of "media" */
export type MediaOption_MediaArgs = {
  distinct_on?: Maybe<Array<Option_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Option_Media_Order_By>>;
  where?: Maybe<Option_Media_Bool_Exp>;
};


/** columns and relationships of "media" */
export type MediaOption_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Option_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Option_Media_Order_By>>;
  where?: Maybe<Option_Media_Bool_Exp>;
};


/** columns and relationships of "media" */
export type MediaTask_MediaArgs = {
  distinct_on?: Maybe<Array<Task_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Media_Order_By>>;
  where?: Maybe<Task_Media_Bool_Exp>;
};


/** columns and relationships of "media" */
export type MediaTask_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Task_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Media_Order_By>>;
  where?: Maybe<Task_Media_Bool_Exp>;
};

/** aggregated selection of "media" */
export type Media_Aggregate = {
  __typename?: 'media_aggregate';
  aggregate?: Maybe<Media_Aggregate_Fields>;
  nodes: Array<Media>;
};

/** aggregate fields of "media" */
export type Media_Aggregate_Fields = {
  __typename?: 'media_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Media_Max_Fields>;
  min?: Maybe<Media_Min_Fields>;
};


/** aggregate fields of "media" */
export type Media_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Media_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "media" */
export type Media_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Media_Max_Order_By>;
  min?: Maybe<Media_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "media". All fields are combined with a logical 'AND'. */
export type Media_Bool_Exp = {
  _and?: Maybe<Array<Media_Bool_Exp>>;
  _not?: Maybe<Media_Bool_Exp>;
  _or?: Maybe<Array<Media_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  expectation_media?: Maybe<Integration_Test_Expectation_Media_Bool_Exp>;
  field_option_media?: Maybe<Field_Option_Media_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  instruction_media?: Maybe<Integration_Test_Instruction_Media_Bool_Exp>;
  media_type?: Maybe<Media_Type_Bool_Exp>;
  option_media?: Maybe<Option_Media_Bool_Exp>;
  task_media?: Maybe<Task_Media_Bool_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  type?: Maybe<Media_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "media" */
export enum Media_Constraint {
  /** unique or primary key constraint on columns "id" */
  MediaPkey = 'media_pkey',
  /** unique or primary key constraint on columns "url" */
  MediaUrlKey = 'media_url_key'
}

/** input type for inserting data into table "media" */
export type Media_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  expectation_media?: Maybe<Integration_Test_Expectation_Media_Arr_Rel_Insert_Input>;
  field_option_media?: Maybe<Field_Option_Media_Arr_Rel_Insert_Input>;
  instruction_media?: Maybe<Integration_Test_Instruction_Media_Arr_Rel_Insert_Input>;
  option_media?: Maybe<Option_Media_Arr_Rel_Insert_Input>;
  task_media?: Maybe<Task_Media_Arr_Rel_Insert_Input>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Media_Type_Enum>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Media_Max_Fields = {
  __typename?: 'media_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "media" */
export type Media_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Media_Min_Fields = {
  __typename?: 'media_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "media" */
export type Media_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** response of any mutation on the table "media" */
export type Media_Mutation_Response = {
  __typename?: 'media_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Media>;
};

/** input type for inserting object relation for remote table "media" */
export type Media_Obj_Rel_Insert_Input = {
  data: Media_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Media_On_Conflict>;
};

/** on_conflict condition type for table "media" */
export type Media_On_Conflict = {
  constraint: Media_Constraint;
  update_columns?: Array<Media_Update_Column>;
  where?: Maybe<Media_Bool_Exp>;
};

/** Ordering options when selecting data from "media". */
export type Media_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  expectation_media_aggregate?: Maybe<Integration_Test_Expectation_Media_Aggregate_Order_By>;
  field_option_media_aggregate?: Maybe<Field_Option_Media_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  instruction_media_aggregate?: Maybe<Integration_Test_Instruction_Media_Aggregate_Order_By>;
  media_type?: Maybe<Media_Type_Order_By>;
  option_media_aggregate?: Maybe<Option_Media_Aggregate_Order_By>;
  task_media_aggregate?: Maybe<Task_Media_Aggregate_Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** primary key columns input for table: media */
export type Media_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "media" */
export enum Media_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "media" */
export type Media_Set_Input = {
  description?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Media_Type_Enum>;
  url?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "media" */
export type Media_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Media_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Media_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Media_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** columns and relationships of "media_type" */
export type Media_Type = {
  __typename?: 'media_type';
  description: Scalars['String'];
  id: Scalars['String'];
  /** An array relationship */
  media: Array<Media>;
  /** An aggregate relationship */
  media_aggregate: Media_Aggregate;
};


/** columns and relationships of "media_type" */
export type Media_TypeMediaArgs = {
  distinct_on?: Maybe<Array<Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Media_Order_By>>;
  where?: Maybe<Media_Bool_Exp>;
};


/** columns and relationships of "media_type" */
export type Media_TypeMedia_AggregateArgs = {
  distinct_on?: Maybe<Array<Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Media_Order_By>>;
  where?: Maybe<Media_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "media_type". All fields are combined with a logical 'AND'. */
export type Media_Type_Bool_Exp = {
  _and?: Maybe<Array<Media_Type_Bool_Exp>>;
  _not?: Maybe<Media_Type_Bool_Exp>;
  _or?: Maybe<Array<Media_Type_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  media?: Maybe<Media_Bool_Exp>;
};

export enum Media_Type_Enum {
  /** A direct link to an audio file that can be used with the html audio tag */
  Audio = 'audio',
  /** A direct link to an image that can be used with the html img tag */
  Image = 'image',
  /** A url that can be used with the html a tag */
  Link = 'link',
  /** A url to an unconventional media type */
  Other = 'other',
  /** A direct link to a video that can be used with the html video tag */
  Video = 'video'
}

/** Boolean expression to compare columns of type "media_type_enum". All fields are combined with logical 'AND'. */
export type Media_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Media_Type_Enum>;
  _in?: Maybe<Array<Media_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Media_Type_Enum>;
  _nin?: Maybe<Array<Media_Type_Enum>>;
};

/** Ordering options when selecting data from "media_type". */
export type Media_Type_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  media_aggregate?: Maybe<Media_Aggregate_Order_By>;
};

/** select columns of table "media_type" */
export enum Media_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** Streaming cursor of the table "media_type" */
export type Media_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Media_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Media_Type_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** update columns of table "media" */
export enum Media_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  Url = 'url'
}

export type Media_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Media_Set_Input>;
  where: Media_Bool_Exp;
};

/** columns and relationships of "mode_project" */
export type Mode_Project = {
  __typename?: 'mode_project';
  /** An object relationship */
  mode: Recording_Data_Modes;
  mode_id: Scalars['uuid'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
};

/** aggregated selection of "mode_project" */
export type Mode_Project_Aggregate = {
  __typename?: 'mode_project_aggregate';
  aggregate?: Maybe<Mode_Project_Aggregate_Fields>;
  nodes: Array<Mode_Project>;
};

/** aggregate fields of "mode_project" */
export type Mode_Project_Aggregate_Fields = {
  __typename?: 'mode_project_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Mode_Project_Max_Fields>;
  min?: Maybe<Mode_Project_Min_Fields>;
};


/** aggregate fields of "mode_project" */
export type Mode_Project_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Mode_Project_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "mode_project" */
export type Mode_Project_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Mode_Project_Max_Order_By>;
  min?: Maybe<Mode_Project_Min_Order_By>;
};

/** input type for inserting array relation for remote table "mode_project" */
export type Mode_Project_Arr_Rel_Insert_Input = {
  data: Array<Mode_Project_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Mode_Project_On_Conflict>;
};

/** Boolean expression to filter rows from the table "mode_project". All fields are combined with a logical 'AND'. */
export type Mode_Project_Bool_Exp = {
  _and?: Maybe<Array<Mode_Project_Bool_Exp>>;
  _not?: Maybe<Mode_Project_Bool_Exp>;
  _or?: Maybe<Array<Mode_Project_Bool_Exp>>;
  mode?: Maybe<Recording_Data_Modes_Bool_Exp>;
  mode_id?: Maybe<Uuid_Comparison_Exp>;
  project?: Maybe<Projects_Bool_Exp>;
  project_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "mode_project" */
export enum Mode_Project_Constraint {
  /** unique or primary key constraint on columns "mode_id", "project_id" */
  ModeProjectPkey = 'mode_project_pkey'
}

/** input type for inserting data into table "mode_project" */
export type Mode_Project_Insert_Input = {
  mode?: Maybe<Recording_Data_Modes_Obj_Rel_Insert_Input>;
  mode_id?: Maybe<Scalars['uuid']>;
  project?: Maybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Mode_Project_Max_Fields = {
  __typename?: 'mode_project_max_fields';
  mode_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "mode_project" */
export type Mode_Project_Max_Order_By = {
  mode_id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Mode_Project_Min_Fields = {
  __typename?: 'mode_project_min_fields';
  mode_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "mode_project" */
export type Mode_Project_Min_Order_By = {
  mode_id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "mode_project" */
export type Mode_Project_Mutation_Response = {
  __typename?: 'mode_project_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mode_Project>;
};

/** on_conflict condition type for table "mode_project" */
export type Mode_Project_On_Conflict = {
  constraint: Mode_Project_Constraint;
  update_columns?: Array<Mode_Project_Update_Column>;
  where?: Maybe<Mode_Project_Bool_Exp>;
};

/** Ordering options when selecting data from "mode_project". */
export type Mode_Project_Order_By = {
  mode?: Maybe<Recording_Data_Modes_Order_By>;
  mode_id?: Maybe<Order_By>;
  project?: Maybe<Projects_Order_By>;
  project_id?: Maybe<Order_By>;
};

/** primary key columns input for table: mode_project */
export type Mode_Project_Pk_Columns_Input = {
  mode_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};

/** select columns of table "mode_project" */
export enum Mode_Project_Select_Column {
  /** column name */
  ModeId = 'mode_id',
  /** column name */
  ProjectId = 'project_id'
}

/** input type for updating data in table "mode_project" */
export type Mode_Project_Set_Input = {
  mode_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "mode_project" */
export type Mode_Project_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mode_Project_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mode_Project_Stream_Cursor_Value_Input = {
  mode_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "mode_project" */
export enum Mode_Project_Update_Column {
  /** column name */
  ModeId = 'mode_id',
  /** column name */
  ProjectId = 'project_id'
}

export type Mode_Project_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Mode_Project_Set_Input>;
  where: Mode_Project_Bool_Exp;
};

/** columns and relationships of "model_name_hashes" */
export type Model_Name_Hashes = {
  __typename?: 'model_name_hashes';
  hash: Scalars['String'];
  /** An object relationship */
  model: Models;
  model_id: Scalars['uuid'];
};

/** aggregated selection of "model_name_hashes" */
export type Model_Name_Hashes_Aggregate = {
  __typename?: 'model_name_hashes_aggregate';
  aggregate?: Maybe<Model_Name_Hashes_Aggregate_Fields>;
  nodes: Array<Model_Name_Hashes>;
};

/** aggregate fields of "model_name_hashes" */
export type Model_Name_Hashes_Aggregate_Fields = {
  __typename?: 'model_name_hashes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Model_Name_Hashes_Max_Fields>;
  min?: Maybe<Model_Name_Hashes_Min_Fields>;
};


/** aggregate fields of "model_name_hashes" */
export type Model_Name_Hashes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Model_Name_Hashes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "model_name_hashes" */
export type Model_Name_Hashes_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Model_Name_Hashes_Max_Order_By>;
  min?: Maybe<Model_Name_Hashes_Min_Order_By>;
};

/** input type for inserting array relation for remote table "model_name_hashes" */
export type Model_Name_Hashes_Arr_Rel_Insert_Input = {
  data: Array<Model_Name_Hashes_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Model_Name_Hashes_On_Conflict>;
};

/** Boolean expression to filter rows from the table "model_name_hashes". All fields are combined with a logical 'AND'. */
export type Model_Name_Hashes_Bool_Exp = {
  _and?: Maybe<Array<Model_Name_Hashes_Bool_Exp>>;
  _not?: Maybe<Model_Name_Hashes_Bool_Exp>;
  _or?: Maybe<Array<Model_Name_Hashes_Bool_Exp>>;
  hash?: Maybe<String_Comparison_Exp>;
  model?: Maybe<Models_Bool_Exp>;
  model_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "model_name_hashes" */
export enum Model_Name_Hashes_Constraint {
  /** unique or primary key constraint on columns "hash", "model_id" */
  ModelNameHashesPkey = 'model_name_hashes_pkey'
}

/** input type for inserting data into table "model_name_hashes" */
export type Model_Name_Hashes_Insert_Input = {
  hash?: Maybe<Scalars['String']>;
  model?: Maybe<Models_Obj_Rel_Insert_Input>;
  model_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Model_Name_Hashes_Max_Fields = {
  __typename?: 'model_name_hashes_max_fields';
  hash?: Maybe<Scalars['String']>;
  model_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "model_name_hashes" */
export type Model_Name_Hashes_Max_Order_By = {
  hash?: Maybe<Order_By>;
  model_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Model_Name_Hashes_Min_Fields = {
  __typename?: 'model_name_hashes_min_fields';
  hash?: Maybe<Scalars['String']>;
  model_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "model_name_hashes" */
export type Model_Name_Hashes_Min_Order_By = {
  hash?: Maybe<Order_By>;
  model_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "model_name_hashes" */
export type Model_Name_Hashes_Mutation_Response = {
  __typename?: 'model_name_hashes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Model_Name_Hashes>;
};

/** on_conflict condition type for table "model_name_hashes" */
export type Model_Name_Hashes_On_Conflict = {
  constraint: Model_Name_Hashes_Constraint;
  update_columns?: Array<Model_Name_Hashes_Update_Column>;
  where?: Maybe<Model_Name_Hashes_Bool_Exp>;
};

/** Ordering options when selecting data from "model_name_hashes". */
export type Model_Name_Hashes_Order_By = {
  hash?: Maybe<Order_By>;
  model?: Maybe<Models_Order_By>;
  model_id?: Maybe<Order_By>;
};

/** primary key columns input for table: model_name_hashes */
export type Model_Name_Hashes_Pk_Columns_Input = {
  hash: Scalars['String'];
  model_id: Scalars['uuid'];
};

/** select columns of table "model_name_hashes" */
export enum Model_Name_Hashes_Select_Column {
  /** column name */
  Hash = 'hash',
  /** column name */
  ModelId = 'model_id'
}

/** input type for updating data in table "model_name_hashes" */
export type Model_Name_Hashes_Set_Input = {
  hash?: Maybe<Scalars['String']>;
  model_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "model_name_hashes" */
export type Model_Name_Hashes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Model_Name_Hashes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Model_Name_Hashes_Stream_Cursor_Value_Input = {
  hash?: Maybe<Scalars['String']>;
  model_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "model_name_hashes" */
export enum Model_Name_Hashes_Update_Column {
  /** column name */
  Hash = 'hash',
  /** column name */
  ModelId = 'model_id'
}

export type Model_Name_Hashes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Model_Name_Hashes_Set_Input>;
  where: Model_Name_Hashes_Bool_Exp;
};

/** columns and relationships of "models" */
export type Models = {
  __typename?: 'models';
  codename: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  devices: Array<Devices>;
  /** An aggregate relationship */
  devices_aggregate: Devices_Aggregate;
  /** An array relationship */
  hashes: Array<Model_Name_Hashes>;
  /** An aggregate relationship */
  hashes_aggregate: Model_Name_Hashes_Aggregate;
  id: Scalars['uuid'];
  /** A computed field, executes function "model_codename" */
  name?: Maybe<Scalars['String']>;
  /** An array relationship */
  projects: Array<Projects>;
  /** An aggregate relationship */
  projects_aggregate: Projects_Aggregate;
  suspend_resume_at_transitions: Scalars['Boolean'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "models" */
export type ModelsDevicesArgs = {
  distinct_on?: Maybe<Array<Devices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Devices_Order_By>>;
  where?: Maybe<Devices_Bool_Exp>;
};


/** columns and relationships of "models" */
export type ModelsDevices_AggregateArgs = {
  distinct_on?: Maybe<Array<Devices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Devices_Order_By>>;
  where?: Maybe<Devices_Bool_Exp>;
};


/** columns and relationships of "models" */
export type ModelsHashesArgs = {
  distinct_on?: Maybe<Array<Model_Name_Hashes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Model_Name_Hashes_Order_By>>;
  where?: Maybe<Model_Name_Hashes_Bool_Exp>;
};


/** columns and relationships of "models" */
export type ModelsHashes_AggregateArgs = {
  distinct_on?: Maybe<Array<Model_Name_Hashes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Model_Name_Hashes_Order_By>>;
  where?: Maybe<Model_Name_Hashes_Bool_Exp>;
};


/** columns and relationships of "models" */
export type ModelsProjectsArgs = {
  distinct_on?: Maybe<Array<Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Projects_Order_By>>;
  where?: Maybe<Projects_Bool_Exp>;
};


/** columns and relationships of "models" */
export type ModelsProjects_AggregateArgs = {
  distinct_on?: Maybe<Array<Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Projects_Order_By>>;
  where?: Maybe<Projects_Bool_Exp>;
};

/** aggregated selection of "models" */
export type Models_Aggregate = {
  __typename?: 'models_aggregate';
  aggregate?: Maybe<Models_Aggregate_Fields>;
  nodes: Array<Models>;
};

/** aggregate fields of "models" */
export type Models_Aggregate_Fields = {
  __typename?: 'models_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Models_Max_Fields>;
  min?: Maybe<Models_Min_Fields>;
};


/** aggregate fields of "models" */
export type Models_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Models_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "models". All fields are combined with a logical 'AND'. */
export type Models_Bool_Exp = {
  _and?: Maybe<Array<Models_Bool_Exp>>;
  _not?: Maybe<Models_Bool_Exp>;
  _or?: Maybe<Array<Models_Bool_Exp>>;
  codename?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  devices?: Maybe<Devices_Bool_Exp>;
  hashes?: Maybe<Model_Name_Hashes_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  projects?: Maybe<Projects_Bool_Exp>;
  suspend_resume_at_transitions?: Maybe<Boolean_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "models" */
export enum Models_Constraint {
  /** unique or primary key constraint on columns "codename" */
  ModelsCodenameKey = 'models_codename_key',
  /** unique or primary key constraint on columns "id" */
  ModelsPkey = 'models_pkey'
}

/** input type for inserting data into table "models" */
export type Models_Insert_Input = {
  codename?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  devices?: Maybe<Devices_Arr_Rel_Insert_Input>;
  hashes?: Maybe<Model_Name_Hashes_Arr_Rel_Insert_Input>;
  projects?: Maybe<Projects_Arr_Rel_Insert_Input>;
  suspend_resume_at_transitions?: Maybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Models_Max_Fields = {
  __typename?: 'models_max_fields';
  codename?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Models_Min_Fields = {
  __typename?: 'models_min_fields';
  codename?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "models" */
export type Models_Mutation_Response = {
  __typename?: 'models_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Models>;
};

/** input type for inserting object relation for remote table "models" */
export type Models_Obj_Rel_Insert_Input = {
  data: Models_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Models_On_Conflict>;
};

/** on_conflict condition type for table "models" */
export type Models_On_Conflict = {
  constraint: Models_Constraint;
  update_columns?: Array<Models_Update_Column>;
  where?: Maybe<Models_Bool_Exp>;
};

/** Ordering options when selecting data from "models". */
export type Models_Order_By = {
  codename?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  devices_aggregate?: Maybe<Devices_Aggregate_Order_By>;
  hashes_aggregate?: Maybe<Model_Name_Hashes_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  projects_aggregate?: Maybe<Projects_Aggregate_Order_By>;
  suspend_resume_at_transitions?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: models */
export type Models_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "models" */
export enum Models_Select_Column {
  /** column name */
  Codename = 'codename',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  SuspendResumeAtTransitions = 'suspend_resume_at_transitions',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "models" */
export type Models_Set_Input = {
  codename?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  suspend_resume_at_transitions?: Maybe<Scalars['Boolean']>;
};

/** Streaming cursor of the table "models" */
export type Models_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Models_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Models_Stream_Cursor_Value_Input = {
  codename?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  suspend_resume_at_transitions?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "models" */
export enum Models_Update_Column {
  /** column name */
  Codename = 'codename',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  SuspendResumeAtTransitions = 'suspend_resume_at_transitions'
}

export type Models_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Models_Set_Input>;
  where: Models_Bool_Exp;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "audio_driver" */
  delete_audio_driver?: Maybe<Audio_Driver_Mutation_Response>;
  /** delete single row from the table: "audio_driver" */
  delete_audio_driver_by_pk?: Maybe<Audio_Driver>;
  /** delete data from the table: "audio_measurements.components" */
  delete_audio_measurements_components?: Maybe<Audio_Measurements_Components_Mutation_Response>;
  /** delete single row from the table: "audio_measurements.components" */
  delete_audio_measurements_components_by_pk?: Maybe<Audio_Measurements_Components>;
  /** delete data from the table: "audio_measurements.configurations" */
  delete_audio_measurements_configurations?: Maybe<Audio_Measurements_Configurations_Mutation_Response>;
  /** delete single row from the table: "audio_measurements.configurations" */
  delete_audio_measurements_configurations_by_pk?: Maybe<Audio_Measurements_Configurations>;
  /** delete data from the table: "audio_measurements.master_files" */
  delete_audio_measurements_master_files?: Maybe<Audio_Measurements_Master_Files_Mutation_Response>;
  /** delete single row from the table: "audio_measurements.master_files" */
  delete_audio_measurements_master_files_by_pk?: Maybe<Audio_Measurements_Master_Files>;
  /** delete data from the table: "audio_measurements.orientations" */
  delete_audio_measurements_orientations?: Maybe<Audio_Measurements_Orientations_Mutation_Response>;
  /** delete single row from the table: "audio_measurements.orientations" */
  delete_audio_measurements_orientations_by_pk?: Maybe<Audio_Measurements_Orientations>;
  /** delete data from the table: "audio_measurements.payload_recording" */
  delete_audio_measurements_payload_recording?: Maybe<Audio_Measurements_Payload_Recording_Mutation_Response>;
  /** delete single row from the table: "audio_measurements.payload_recording" */
  delete_audio_measurements_payload_recording_by_pk?: Maybe<Audio_Measurements_Payload_Recording>;
  /** delete data from the table: "audio_measurements.placements" */
  delete_audio_measurements_placements?: Maybe<Audio_Measurements_Placements_Mutation_Response>;
  /** delete single row from the table: "audio_measurements.placements" */
  delete_audio_measurements_placements_by_pk?: Maybe<Audio_Measurements_Placements>;
  /** delete data from the table: "audio_measurements.project_tasks" */
  delete_audio_measurements_project_tasks?: Maybe<Audio_Measurements_Project_Tasks_Mutation_Response>;
  /** delete single row from the table: "audio_measurements.project_tasks" */
  delete_audio_measurements_project_tasks_by_pk?: Maybe<Audio_Measurements_Project_Tasks>;
  /** delete data from the table: "audio_measurements.stages" */
  delete_audio_measurements_stages?: Maybe<Audio_Measurements_Stages_Mutation_Response>;
  /** delete single row from the table: "audio_measurements.stages" */
  delete_audio_measurements_stages_by_pk?: Maybe<Audio_Measurements_Stages>;
  /** delete data from the table: "bringup.checklist_deliverable" */
  delete_bringup_checklist_deliverable?: Maybe<Bringup_Checklist_Deliverable_Mutation_Response>;
  /** delete single row from the table: "bringup.checklist_deliverable" */
  delete_bringup_checklist_deliverable_by_pk?: Maybe<Bringup_Checklist_Deliverable>;
  /** delete data from the table: "bringup.checklists" */
  delete_bringup_checklists?: Maybe<Bringup_Checklists_Mutation_Response>;
  /** delete single row from the table: "bringup.checklists" */
  delete_bringup_checklists_by_pk?: Maybe<Bringup_Checklists>;
  /** delete data from the table: "bringup.endorsements" */
  delete_bringup_endorsements?: Maybe<Bringup_Endorsements_Mutation_Response>;
  /** delete single row from the table: "bringup.endorsements" */
  delete_bringup_endorsements_by_pk?: Maybe<Bringup_Endorsements>;
  /** delete data from the table: "bringup.items" */
  delete_bringup_items?: Maybe<Bringup_Items_Mutation_Response>;
  /** delete single row from the table: "bringup.items" */
  delete_bringup_items_by_pk?: Maybe<Bringup_Items>;
  /** delete data from the table: "bringup.milestones" */
  delete_bringup_milestones?: Maybe<Bringup_Milestones_Mutation_Response>;
  /** delete single row from the table: "bringup.milestones" */
  delete_bringup_milestones_by_pk?: Maybe<Bringup_Milestones>;
  /** delete data from the table: "bringup.project_item_comments" */
  delete_bringup_project_item_comments?: Maybe<Bringup_Project_Item_Comments_Mutation_Response>;
  /** delete single row from the table: "bringup.project_item_comments" */
  delete_bringup_project_item_comments_by_pk?: Maybe<Bringup_Project_Item_Comments>;
  /** delete data from the table: "categories" */
  delete_categories?: Maybe<Categories_Mutation_Response>;
  /** delete single row from the table: "categories" */
  delete_categories_by_pk?: Maybe<Categories>;
  /** delete data from the table: "context_display_field" */
  delete_context_display_field?: Maybe<Context_Display_Field_Mutation_Response>;
  /** delete single row from the table: "context_display_field" */
  delete_context_display_field_by_pk?: Maybe<Context_Display_Field>;
  /** delete data from the table: "context_displays" */
  delete_context_displays?: Maybe<Context_Displays_Mutation_Response>;
  /** delete single row from the table: "context_displays" */
  delete_context_displays_by_pk?: Maybe<Context_Displays>;
  /** delete data from the table: "contexts" */
  delete_contexts?: Maybe<Contexts_Mutation_Response>;
  /** delete single row from the table: "contexts" */
  delete_contexts_by_pk?: Maybe<Contexts>;
  /** delete data from the table: "customers" */
  delete_customers?: Maybe<Customers_Mutation_Response>;
  /** delete single row from the table: "customers" */
  delete_customers_by_pk?: Maybe<Customers>;
  /** delete data from the table: "deliverables" */
  delete_deliverables?: Maybe<Deliverables_Mutation_Response>;
  /** delete single row from the table: "deliverables" */
  delete_deliverables_by_pk?: Maybe<Deliverables>;
  /** delete data from the table: "deliveries.architectures" */
  delete_deliveries_architectures?: Maybe<Deliveries_Architectures_Mutation_Response>;
  /** delete single row from the table: "deliveries.architectures" */
  delete_deliveries_architectures_by_pk?: Maybe<Deliveries_Architectures>;
  /** delete data from the table: "deliveries.artifact_microsoft_whql_submission" */
  delete_deliveries_artifact_microsoft_whql_submission?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission_Mutation_Response>;
  /** delete single row from the table: "deliveries.artifact_microsoft_whql_submission" */
  delete_deliveries_artifact_microsoft_whql_submission_by_pk?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission>;
  /** delete data from the table: "deliveries.artifact_operating_system" */
  delete_deliveries_artifact_operating_system?: Maybe<Deliveries_Artifact_Operating_System_Mutation_Response>;
  /** delete single row from the table: "deliveries.artifact_operating_system" */
  delete_deliveries_artifact_operating_system_by_pk?: Maybe<Deliveries_Artifact_Operating_System>;
  /** delete data from the table: "deliveries.driver_package" */
  delete_deliveries_driver_package?: Maybe<Deliveries_Driver_Package_Mutation_Response>;
  /** delete single row from the table: "deliveries.driver_package" */
  delete_deliveries_driver_package_by_pk?: Maybe<Deliveries_Driver_Package>;
  /** delete data from the table: "deliveries.driver_project" */
  delete_deliveries_driver_project?: Maybe<Deliveries_Driver_Project_Mutation_Response>;
  /** delete single row from the table: "deliveries.driver_project" */
  delete_deliveries_driver_project_by_pk?: Maybe<Deliveries_Driver_Project>;
  /** delete data from the table: "deliveries.driver_version_engine" */
  delete_deliveries_driver_version_engine?: Maybe<Deliveries_Driver_Version_Engine_Mutation_Response>;
  /** delete single row from the table: "deliveries.driver_version_engine" */
  delete_deliveries_driver_version_engine_by_pk?: Maybe<Deliveries_Driver_Version_Engine>;
  /** delete data from the table: "deliveries.driver_version_package" */
  delete_deliveries_driver_version_package?: Maybe<Deliveries_Driver_Version_Package_Mutation_Response>;
  /** delete single row from the table: "deliveries.driver_version_package" */
  delete_deliveries_driver_version_package_by_pk?: Maybe<Deliveries_Driver_Version_Package>;
  /** delete data from the table: "deliveries.driver_versions" */
  delete_deliveries_driver_versions?: Maybe<Deliveries_Driver_Versions_Mutation_Response>;
  /** delete single row from the table: "deliveries.driver_versions" */
  delete_deliveries_driver_versions_by_pk?: Maybe<Deliveries_Driver_Versions>;
  /** delete data from the table: "deliveries.drivers" */
  delete_deliveries_drivers?: Maybe<Deliveries_Drivers_Mutation_Response>;
  /** delete single row from the table: "deliveries.drivers" */
  delete_deliveries_drivers_by_pk?: Maybe<Deliveries_Drivers>;
  /** delete data from the table: "deliveries.microsoft_shipping_labels" */
  delete_deliveries_microsoft_shipping_labels?: Maybe<Deliveries_Microsoft_Shipping_Labels_Mutation_Response>;
  /** delete single row from the table: "deliveries.microsoft_shipping_labels" */
  delete_deliveries_microsoft_shipping_labels_by_pk?: Maybe<Deliveries_Microsoft_Shipping_Labels>;
  /** delete data from the table: "deliveries.microsoft_whql_submissions" */
  delete_deliveries_microsoft_whql_submissions?: Maybe<Deliveries_Microsoft_Whql_Submissions_Mutation_Response>;
  /** delete single row from the table: "deliveries.microsoft_whql_submissions" */
  delete_deliveries_microsoft_whql_submissions_by_pk?: Maybe<Deliveries_Microsoft_Whql_Submissions>;
  /** delete data from the table: "deliveries.package_target" */
  delete_deliveries_package_target?: Maybe<Deliveries_Package_Target_Mutation_Response>;
  /** delete single row from the table: "deliveries.package_target" */
  delete_deliveries_package_target_by_pk?: Maybe<Deliveries_Package_Target>;
  /** delete data from the table: "deliveries.packages" */
  delete_deliveries_packages?: Maybe<Deliveries_Packages_Mutation_Response>;
  /** delete single row from the table: "deliveries.packages" */
  delete_deliveries_packages_by_pk?: Maybe<Deliveries_Packages>;
  /** delete data from the table: "deliveries.partners" */
  delete_deliveries_partners?: Maybe<Deliveries_Partners_Mutation_Response>;
  /** delete single row from the table: "deliveries.partners" */
  delete_deliveries_partners_by_pk?: Maybe<Deliveries_Partners>;
  /** delete data from the table: "deliveries.platforms" */
  delete_deliveries_platforms?: Maybe<Deliveries_Platforms_Mutation_Response>;
  /** delete single row from the table: "deliveries.platforms" */
  delete_deliveries_platforms_by_pk?: Maybe<Deliveries_Platforms>;
  /** delete data from the table: "deliveries.target_partner" */
  delete_deliveries_target_partner?: Maybe<Deliveries_Target_Partner_Mutation_Response>;
  /** delete single row from the table: "deliveries.target_partner" */
  delete_deliveries_target_partner_by_pk?: Maybe<Deliveries_Target_Partner>;
  /** delete data from the table: "deliveries.targets" */
  delete_deliveries_targets?: Maybe<Deliveries_Targets_Mutation_Response>;
  /** delete single row from the table: "deliveries.targets" */
  delete_deliveries_targets_by_pk?: Maybe<Deliveries_Targets>;
  /** delete data from the table: "device_firmware" */
  delete_device_firmware?: Maybe<Device_Firmware_Mutation_Response>;
  /** delete single row from the table: "device_firmware" */
  delete_device_firmware_by_pk?: Maybe<Device_Firmware>;
  /** delete data from the table: "device_project" */
  delete_device_project?: Maybe<Device_Project_Mutation_Response>;
  /** delete single row from the table: "device_project" */
  delete_device_project_by_pk?: Maybe<Device_Project>;
  /** delete data from the table: "devices" */
  delete_devices?: Maybe<Devices_Mutation_Response>;
  /** delete single row from the table: "devices" */
  delete_devices_by_pk?: Maybe<Devices>;
  /** delete data from the table: "dynamic_scaling" */
  delete_dynamic_scaling?: Maybe<Dynamic_Scaling_Mutation_Response>;
  /** delete single row from the table: "dynamic_scaling" */
  delete_dynamic_scaling_by_pk?: Maybe<Dynamic_Scaling>;
  /** delete data from the table: "engine_opmode" */
  delete_engine_opmode?: Maybe<Engine_Opmode_Mutation_Response>;
  /** delete single row from the table: "engine_opmode" */
  delete_engine_opmode_by_pk?: Maybe<Engine_Opmode>;
  /** delete data from the table: "engine_opmode_recording" */
  delete_engine_opmode_recording?: Maybe<Engine_Opmode_Recording_Mutation_Response>;
  /** delete single row from the table: "engine_opmode_recording" */
  delete_engine_opmode_recording_by_pk?: Maybe<Engine_Opmode_Recording>;
  /** delete data from the table: "engines" */
  delete_engines?: Maybe<Engines_Mutation_Response>;
  /** delete single row from the table: "engines" */
  delete_engines_by_pk?: Maybe<Engines>;
  /** delete data from the table: "field_option_media" */
  delete_field_option_media?: Maybe<Field_Option_Media_Mutation_Response>;
  /** delete single row from the table: "field_option_media" */
  delete_field_option_media_by_pk?: Maybe<Field_Option_Media>;
  /** delete data from the table: "field_options" */
  delete_field_options?: Maybe<Field_Options_Mutation_Response>;
  /** delete single row from the table: "field_options" */
  delete_field_options_by_pk?: Maybe<Field_Options>;
  /** delete data from the table: "field_translation" */
  delete_field_translation?: Maybe<Field_Translation_Mutation_Response>;
  /** delete single row from the table: "field_translation" */
  delete_field_translation_by_pk?: Maybe<Field_Translation>;
  /** delete data from the table: "fields" */
  delete_fields?: Maybe<Fields_Mutation_Response>;
  /** delete single row from the table: "fields" */
  delete_fields_by_pk?: Maybe<Fields>;
  /** delete data from the table: "firmware" */
  delete_firmware?: Maybe<Firmware_Mutation_Response>;
  /** delete single row from the table: "firmware" */
  delete_firmware_by_pk?: Maybe<Firmware>;
  /** delete data from the table: "ground_truth.configurations" */
  delete_ground_truth_configurations?: Maybe<Ground_Truth_Configurations_Mutation_Response>;
  /** delete single row from the table: "ground_truth.configurations" */
  delete_ground_truth_configurations_by_pk?: Maybe<Ground_Truth_Configurations>;
  /** delete data from the table: "ground_truth.recordings" */
  delete_ground_truth_recordings?: Maybe<Ground_Truth_Recordings_Mutation_Response>;
  /** delete single row from the table: "ground_truth.recordings" */
  delete_ground_truth_recordings_by_pk?: Maybe<Ground_Truth_Recordings>;
  /** delete data from the table: "hypertask_field_option" */
  delete_hypertask_field_option?: Maybe<Hypertask_Field_Option_Mutation_Response>;
  /** delete single row from the table: "hypertask_field_option" */
  delete_hypertask_field_option_by_pk?: Maybe<Hypertask_Field_Option>;
  /** delete data from the table: "hypertask_task" */
  delete_hypertask_task?: Maybe<Hypertask_Task_Mutation_Response>;
  /** delete single row from the table: "hypertask_task" */
  delete_hypertask_task_by_pk?: Maybe<Hypertask_Task>;
  /** delete data from the table: "hypertasks" */
  delete_hypertasks?: Maybe<Hypertasks_Mutation_Response>;
  /** delete single row from the table: "hypertasks" */
  delete_hypertasks_by_pk?: Maybe<Hypertasks>;
  /** delete data from the table: "instruction_translations" */
  delete_instruction_translations?: Maybe<Instruction_Translations_Mutation_Response>;
  /** delete single row from the table: "instruction_translations" */
  delete_instruction_translations_by_pk?: Maybe<Instruction_Translations>;
  /** delete data from the table: "integration_test.expectation_media" */
  delete_integration_test_expectation_media?: Maybe<Integration_Test_Expectation_Media_Mutation_Response>;
  /** delete single row from the table: "integration_test.expectation_media" */
  delete_integration_test_expectation_media_by_pk?: Maybe<Integration_Test_Expectation_Media>;
  /** delete data from the table: "integration_test.expectation_step" */
  delete_integration_test_expectation_step?: Maybe<Integration_Test_Expectation_Step_Mutation_Response>;
  /** delete single row from the table: "integration_test.expectation_step" */
  delete_integration_test_expectation_step_by_pk?: Maybe<Integration_Test_Expectation_Step>;
  /** delete data from the table: "integration_test.expectation_translation" */
  delete_integration_test_expectation_translation?: Maybe<Integration_Test_Expectation_Translation_Mutation_Response>;
  /** delete single row from the table: "integration_test.expectation_translation" */
  delete_integration_test_expectation_translation_by_pk?: Maybe<Integration_Test_Expectation_Translation>;
  /** delete data from the table: "integration_test.expectations" */
  delete_integration_test_expectations?: Maybe<Integration_Test_Expectations_Mutation_Response>;
  /** delete single row from the table: "integration_test.expectations" */
  delete_integration_test_expectations_by_pk?: Maybe<Integration_Test_Expectations>;
  /** delete data from the table: "integration_test.instruction_media" */
  delete_integration_test_instruction_media?: Maybe<Integration_Test_Instruction_Media_Mutation_Response>;
  /** delete single row from the table: "integration_test.instruction_media" */
  delete_integration_test_instruction_media_by_pk?: Maybe<Integration_Test_Instruction_Media>;
  /** delete data from the table: "integration_test.instruction_translation" */
  delete_integration_test_instruction_translation?: Maybe<Integration_Test_Instruction_Translation_Mutation_Response>;
  /** delete single row from the table: "integration_test.instruction_translation" */
  delete_integration_test_instruction_translation_by_pk?: Maybe<Integration_Test_Instruction_Translation>;
  /** delete data from the table: "integration_test.instructions" */
  delete_integration_test_instructions?: Maybe<Integration_Test_Instructions_Mutation_Response>;
  /** delete single row from the table: "integration_test.instructions" */
  delete_integration_test_instructions_by_pk?: Maybe<Integration_Test_Instructions>;
  /** delete data from the table: "integration_test.observances" */
  delete_integration_test_observances?: Maybe<Integration_Test_Observances_Mutation_Response>;
  /** delete single row from the table: "integration_test.observances" */
  delete_integration_test_observances_by_pk?: Maybe<Integration_Test_Observances>;
  /** delete data from the table: "integration_test.project_sequence" */
  delete_integration_test_project_sequence?: Maybe<Integration_Test_Project_Sequence_Mutation_Response>;
  /** delete single row from the table: "integration_test.project_sequence" */
  delete_integration_test_project_sequence_by_pk?: Maybe<Integration_Test_Project_Sequence>;
  /** delete data from the table: "integration_test.sequences" */
  delete_integration_test_sequences?: Maybe<Integration_Test_Sequences_Mutation_Response>;
  /** delete single row from the table: "integration_test.sequences" */
  delete_integration_test_sequences_by_pk?: Maybe<Integration_Test_Sequences>;
  /** delete data from the table: "integration_test.steps" */
  delete_integration_test_steps?: Maybe<Integration_Test_Steps_Mutation_Response>;
  /** delete single row from the table: "integration_test.steps" */
  delete_integration_test_steps_by_pk?: Maybe<Integration_Test_Steps>;
  /** delete data from the table: "machine_learning.blobs" */
  delete_machine_learning_blobs?: Maybe<Machine_Learning_Blobs_Mutation_Response>;
  /** delete single row from the table: "machine_learning.blobs" */
  delete_machine_learning_blobs_by_pk?: Maybe<Machine_Learning_Blobs>;
  /** delete data from the table: "machine_learning.dataset_feature" */
  delete_machine_learning_dataset_feature?: Maybe<Machine_Learning_Dataset_Feature_Mutation_Response>;
  /** delete single row from the table: "machine_learning.dataset_feature" */
  delete_machine_learning_dataset_feature_by_pk?: Maybe<Machine_Learning_Dataset_Feature>;
  /** delete data from the table: "machine_learning.datasets" */
  delete_machine_learning_datasets?: Maybe<Machine_Learning_Datasets_Mutation_Response>;
  /** delete single row from the table: "machine_learning.datasets" */
  delete_machine_learning_datasets_by_pk?: Maybe<Machine_Learning_Datasets>;
  /** delete data from the table: "machine_learning.features" */
  delete_machine_learning_features?: Maybe<Machine_Learning_Features_Mutation_Response>;
  /** delete single row from the table: "machine_learning.features" */
  delete_machine_learning_features_by_pk?: Maybe<Machine_Learning_Features>;
  /** delete data from the table: "machine_learning.model_blob" */
  delete_machine_learning_model_blob?: Maybe<Machine_Learning_Model_Blob_Mutation_Response>;
  /** delete single row from the table: "machine_learning.model_blob" */
  delete_machine_learning_model_blob_by_pk?: Maybe<Machine_Learning_Model_Blob>;
  /** delete data from the table: "machine_learning.model_dataset" */
  delete_machine_learning_model_dataset?: Maybe<Machine_Learning_Model_Dataset_Mutation_Response>;
  /** delete single row from the table: "machine_learning.model_dataset" */
  delete_machine_learning_model_dataset_by_pk?: Maybe<Machine_Learning_Model_Dataset>;
  /** delete data from the table: "machine_learning.model_recording" */
  delete_machine_learning_model_recording?: Maybe<Machine_Learning_Model_Recording_Mutation_Response>;
  /** delete single row from the table: "machine_learning.model_recording" */
  delete_machine_learning_model_recording_by_pk?: Maybe<Machine_Learning_Model_Recording>;
  /** delete data from the table: "machine_learning.models" */
  delete_machine_learning_models?: Maybe<Machine_Learning_Models_Mutation_Response>;
  /** delete single row from the table: "machine_learning.models" */
  delete_machine_learning_models_by_pk?: Maybe<Machine_Learning_Models>;
  /** delete data from the table: "media" */
  delete_media?: Maybe<Media_Mutation_Response>;
  /** delete single row from the table: "media" */
  delete_media_by_pk?: Maybe<Media>;
  /** delete data from the table: "mode_project" */
  delete_mode_project?: Maybe<Mode_Project_Mutation_Response>;
  /** delete single row from the table: "mode_project" */
  delete_mode_project_by_pk?: Maybe<Mode_Project>;
  /** delete data from the table: "model_name_hashes" */
  delete_model_name_hashes?: Maybe<Model_Name_Hashes_Mutation_Response>;
  /** delete single row from the table: "model_name_hashes" */
  delete_model_name_hashes_by_pk?: Maybe<Model_Name_Hashes>;
  /** delete data from the table: "models" */
  delete_models?: Maybe<Models_Mutation_Response>;
  /** delete single row from the table: "models" */
  delete_models_by_pk?: Maybe<Models>;
  /** delete data from the table: "opmode_profile" */
  delete_opmode_profile?: Maybe<Opmode_Profile_Mutation_Response>;
  /** delete single row from the table: "opmode_profile" */
  delete_opmode_profile_by_pk?: Maybe<Opmode_Profile>;
  /** delete data from the table: "opmode_project" */
  delete_opmode_project?: Maybe<Opmode_Project_Mutation_Response>;
  /** delete single row from the table: "opmode_project" */
  delete_opmode_project_by_pk?: Maybe<Opmode_Project>;
  /** delete data from the table: "opmodes" */
  delete_opmodes?: Maybe<Opmodes_Mutation_Response>;
  /** delete single row from the table: "opmodes" */
  delete_opmodes_by_pk?: Maybe<Opmodes>;
  /** delete data from the table: "option_media" */
  delete_option_media?: Maybe<Option_Media_Mutation_Response>;
  /** delete single row from the table: "option_media" */
  delete_option_media_by_pk?: Maybe<Option_Media>;
  /** delete data from the table: "option_translation" */
  delete_option_translation?: Maybe<Option_Translation_Mutation_Response>;
  /** delete single row from the table: "option_translation" */
  delete_option_translation_by_pk?: Maybe<Option_Translation>;
  /** delete data from the table: "options" */
  delete_options?: Maybe<Options_Mutation_Response>;
  /** delete single row from the table: "options" */
  delete_options_by_pk?: Maybe<Options>;
  /** delete data from the table: "payload_recording" */
  delete_payload_recording?: Maybe<Payload_Recording_Mutation_Response>;
  /** delete single row from the table: "payload_recording" */
  delete_payload_recording_by_pk?: Maybe<Payload_Recording>;
  /** delete data from the table: "payloads" */
  delete_payloads?: Maybe<Payloads_Mutation_Response>;
  /** delete single row from the table: "payloads" */
  delete_payloads_by_pk?: Maybe<Payloads>;
  /** delete data from the table: "performance.ratio_metric_contributions" */
  delete_performance_ratio_metric_contributions?: Maybe<Performance_Ratio_Metric_Contributions_Mutation_Response>;
  /** delete single row from the table: "performance.ratio_metric_contributions" */
  delete_performance_ratio_metric_contributions_by_pk?: Maybe<Performance_Ratio_Metric_Contributions>;
  /** delete data from the table: "performance_report.deliverable_template" */
  delete_performance_report_deliverable_template?: Maybe<Performance_Report_Deliverable_Template_Mutation_Response>;
  /** delete single row from the table: "performance_report.deliverable_template" */
  delete_performance_report_deliverable_template_by_pk?: Maybe<Performance_Report_Deliverable_Template>;
  /** delete data from the table: "performance_report.section_pivot_field" */
  delete_performance_report_section_pivot_field?: Maybe<Performance_Report_Section_Pivot_Field_Mutation_Response>;
  /** delete single row from the table: "performance_report.section_pivot_field" */
  delete_performance_report_section_pivot_field_by_pk?: Maybe<Performance_Report_Section_Pivot_Field>;
  /** delete data from the table: "performance_report.section_ratio_metric" */
  delete_performance_report_section_ratio_metric?: Maybe<Performance_Report_Section_Ratio_Metric_Mutation_Response>;
  /** delete single row from the table: "performance_report.section_ratio_metric" */
  delete_performance_report_section_ratio_metric_by_pk?: Maybe<Performance_Report_Section_Ratio_Metric>;
  /** delete data from the table: "performance_report.section_task_blacklist" */
  delete_performance_report_section_task_blacklist?: Maybe<Performance_Report_Section_Task_Blacklist_Mutation_Response>;
  /** delete single row from the table: "performance_report.section_task_blacklist" */
  delete_performance_report_section_task_blacklist_by_pk?: Maybe<Performance_Report_Section_Task_Blacklist>;
  /** delete data from the table: "performance_report.sections" */
  delete_performance_report_sections?: Maybe<Performance_Report_Sections_Mutation_Response>;
  /** delete single row from the table: "performance_report.sections" */
  delete_performance_report_sections_by_pk?: Maybe<Performance_Report_Sections>;
  /** delete data from the table: "performance_report.templates" */
  delete_performance_report_templates?: Maybe<Performance_Report_Templates_Mutation_Response>;
  /** delete single row from the table: "performance_report.templates" */
  delete_performance_report_templates_by_pk?: Maybe<Performance_Report_Templates>;
  /** delete data from the table: "peripherals" */
  delete_peripherals?: Maybe<Peripherals_Mutation_Response>;
  /** delete single row from the table: "peripherals" */
  delete_peripherals_by_pk?: Maybe<Peripherals>;
  /** delete data from the table: "plan_hypertasks" */
  delete_plan_hypertasks?: Maybe<Plan_Hypertasks_Mutation_Response>;
  /** delete single row from the table: "plan_hypertasks" */
  delete_plan_hypertasks_by_pk?: Maybe<Plan_Hypertasks>;
  /** delete data from the table: "plan_tasks" */
  delete_plan_tasks?: Maybe<Plan_Tasks_Mutation_Response>;
  /** delete single row from the table: "plan_tasks" */
  delete_plan_tasks_by_pk?: Maybe<Plan_Tasks>;
  /** delete data from the table: "plans" */
  delete_plans?: Maybe<Plans_Mutation_Response>;
  /** delete single row from the table: "plans" */
  delete_plans_by_pk?: Maybe<Plans>;
  /** delete data from the table: "profiles" */
  delete_profiles?: Maybe<Profiles_Mutation_Response>;
  /** delete single row from the table: "profiles" */
  delete_profiles_by_pk?: Maybe<Profiles>;
  /** delete data from the table: "project_combo" */
  delete_project_combo?: Maybe<Project_Combo_Mutation_Response>;
  /** delete single row from the table: "project_combo" */
  delete_project_combo_by_pk?: Maybe<Project_Combo>;
  /** delete data from the table: "project_hypertasks" */
  delete_project_hypertasks?: Maybe<Project_Hypertasks_Mutation_Response>;
  /** delete single row from the table: "project_hypertasks" */
  delete_project_hypertasks_by_pk?: Maybe<Project_Hypertasks>;
  /** delete data from the table: "project_tasks" */
  delete_project_tasks?: Maybe<Project_Tasks_Mutation_Response>;
  /** delete single row from the table: "project_tasks" */
  delete_project_tasks_by_pk?: Maybe<Project_Tasks>;
  /** delete data from the table: "project_user" */
  delete_project_user?: Maybe<Project_User_Mutation_Response>;
  /** delete single row from the table: "project_user" */
  delete_project_user_by_pk?: Maybe<Project_User>;
  /** delete data from the table: "projects" */
  delete_projects?: Maybe<Projects_Mutation_Response>;
  /** delete single row from the table: "projects" */
  delete_projects_by_pk?: Maybe<Projects>;
  /** delete data from the table: "rabbit_test.plan_project" */
  delete_rabbit_test_plan_project?: Maybe<Rabbit_Test_Plan_Project_Mutation_Response>;
  /** delete single row from the table: "rabbit_test.plan_project" */
  delete_rabbit_test_plan_project_by_pk?: Maybe<Rabbit_Test_Plan_Project>;
  /** delete data from the table: "rabbit_test.plan_recording" */
  delete_rabbit_test_plan_recording?: Maybe<Rabbit_Test_Plan_Recording_Mutation_Response>;
  /** delete single row from the table: "rabbit_test.plan_recording" */
  delete_rabbit_test_plan_recording_by_pk?: Maybe<Rabbit_Test_Plan_Recording>;
  /** delete data from the table: "rabbit_test.plan_task" */
  delete_rabbit_test_plan_task?: Maybe<Rabbit_Test_Plan_Task_Mutation_Response>;
  /** delete single row from the table: "rabbit_test.plan_task" */
  delete_rabbit_test_plan_task_by_pk?: Maybe<Rabbit_Test_Plan_Task>;
  /** delete data from the table: "rabbit_test.plans" */
  delete_rabbit_test_plans?: Maybe<Rabbit_Test_Plans_Mutation_Response>;
  /** delete single row from the table: "rabbit_test.plans" */
  delete_rabbit_test_plans_by_pk?: Maybe<Rabbit_Test_Plans>;
  /** delete data from the table: "recording_apps" */
  delete_recording_apps?: Maybe<Recording_Apps_Mutation_Response>;
  /** delete single row from the table: "recording_apps" */
  delete_recording_apps_by_pk?: Maybe<Recording_Apps>;
  /** delete data from the table: "recording_audio_driver" */
  delete_recording_audio_driver?: Maybe<Recording_Audio_Driver_Mutation_Response>;
  /** delete single row from the table: "recording_audio_driver" */
  delete_recording_audio_driver_by_pk?: Maybe<Recording_Audio_Driver>;
  /** delete data from the table: "recording_data.ground_truth" */
  delete_recording_data_ground_truth?: Maybe<Recording_Data_Ground_Truth_Mutation_Response>;
  /** delete single row from the table: "recording_data.ground_truth" */
  delete_recording_data_ground_truth_by_pk?: Maybe<Recording_Data_Ground_Truth>;
  /** delete data from the table: "recording_data.labels" */
  delete_recording_data_labels?: Maybe<Recording_Data_Labels_Mutation_Response>;
  /** delete single row from the table: "recording_data.labels" */
  delete_recording_data_labels_by_pk?: Maybe<Recording_Data_Labels>;
  /** delete data from the table: "recording_data.mode_parameter" */
  delete_recording_data_mode_parameter?: Maybe<Recording_Data_Mode_Parameter_Mutation_Response>;
  /** delete single row from the table: "recording_data.mode_parameter" */
  delete_recording_data_mode_parameter_by_pk?: Maybe<Recording_Data_Mode_Parameter>;
  /** delete data from the table: "recording_data.modes" */
  delete_recording_data_modes?: Maybe<Recording_Data_Modes_Mutation_Response>;
  /** delete single row from the table: "recording_data.modes" */
  delete_recording_data_modes_by_pk?: Maybe<Recording_Data_Modes>;
  /** delete data from the table: "recording_data.parameters" */
  delete_recording_data_parameters?: Maybe<Recording_Data_Parameters_Mutation_Response>;
  /** delete single row from the table: "recording_data.parameters" */
  delete_recording_data_parameters_by_pk?: Maybe<Recording_Data_Parameters>;
  /** delete data from the table: "recording_data.payload_pair" */
  delete_recording_data_payload_pair?: Maybe<Recording_Data_Payload_Pair_Mutation_Response>;
  /** delete single row from the table: "recording_data.payload_pair" */
  delete_recording_data_payload_pair_by_pk?: Maybe<Recording_Data_Payload_Pair>;
  /** delete data from the table: "recording_data.predictions" */
  delete_recording_data_predictions?: Maybe<Recording_Data_Predictions_Mutation_Response>;
  /** delete single row from the table: "recording_data.predictions" */
  delete_recording_data_predictions_by_pk?: Maybe<Recording_Data_Predictions>;
  /** delete data from the table: "recording_recording_tag" */
  delete_recording_recording_tag?: Maybe<Recording_Recording_Tag_Mutation_Response>;
  /** delete single row from the table: "recording_recording_tag" */
  delete_recording_recording_tag_by_pk?: Maybe<Recording_Recording_Tag>;
  /** delete data from the table: "recording_tags" */
  delete_recording_tags?: Maybe<Recording_Tags_Mutation_Response>;
  /** delete single row from the table: "recording_tags" */
  delete_recording_tags_by_pk?: Maybe<Recording_Tags>;
  /** delete data from the table: "recordings" */
  delete_recordings?: Maybe<Recordings_Mutation_Response>;
  /** delete single row from the table: "recordings" */
  delete_recordings_by_pk?: Maybe<Recordings>;
  /** delete data from the table: "role_user" */
  delete_role_user?: Maybe<Role_User_Mutation_Response>;
  /** delete single row from the table: "role_user" */
  delete_role_user_by_pk?: Maybe<Role_User>;
  /** delete data from the table: "rooms" */
  delete_rooms?: Maybe<Rooms_Mutation_Response>;
  /** delete single row from the table: "rooms" */
  delete_rooms_by_pk?: Maybe<Rooms>;
  /** delete data from the table: "simulations.scores" */
  delete_simulations_scores?: Maybe<Simulations_Scores_Mutation_Response>;
  /** delete single row from the table: "simulations.scores" */
  delete_simulations_scores_by_pk?: Maybe<Simulations_Scores>;
  /** delete data from the table: "task_field_option" */
  delete_task_field_option?: Maybe<Task_Field_Option_Mutation_Response>;
  /** delete single row from the table: "task_field_option" */
  delete_task_field_option_by_pk?: Maybe<Task_Field_Option>;
  /** delete data from the table: "task_groups.group_fields" */
  delete_task_groups_group_fields?: Maybe<Task_Groups_Group_Fields_Mutation_Response>;
  /** delete single row from the table: "task_groups.group_fields" */
  delete_task_groups_group_fields_by_pk?: Maybe<Task_Groups_Group_Fields>;
  /** delete data from the table: "task_groups.groups" */
  delete_task_groups_groups?: Maybe<Task_Groups_Groups_Mutation_Response>;
  /** delete single row from the table: "task_groups.groups" */
  delete_task_groups_groups_by_pk?: Maybe<Task_Groups_Groups>;
  /** delete data from the table: "task_groups.hierarchies" */
  delete_task_groups_hierarchies?: Maybe<Task_Groups_Hierarchies_Mutation_Response>;
  /** delete single row from the table: "task_groups.hierarchies" */
  delete_task_groups_hierarchies_by_pk?: Maybe<Task_Groups_Hierarchies>;
  /** delete data from the table: "task_groups.hierarchy_groups" */
  delete_task_groups_hierarchy_groups?: Maybe<Task_Groups_Hierarchy_Groups_Mutation_Response>;
  /** delete single row from the table: "task_groups.hierarchy_groups" */
  delete_task_groups_hierarchy_groups_by_pk?: Maybe<Task_Groups_Hierarchy_Groups>;
  /** delete data from the table: "task_instructions" */
  delete_task_instructions?: Maybe<Task_Instructions_Mutation_Response>;
  /** delete single row from the table: "task_instructions" */
  delete_task_instructions_by_pk?: Maybe<Task_Instructions>;
  /** delete data from the table: "task_media" */
  delete_task_media?: Maybe<Task_Media_Mutation_Response>;
  /** delete single row from the table: "task_media" */
  delete_task_media_by_pk?: Maybe<Task_Media>;
  /** delete data from the table: "task_translation" */
  delete_task_translation?: Maybe<Task_Translation_Mutation_Response>;
  /** delete single row from the table: "task_translation" */
  delete_task_translation_by_pk?: Maybe<Task_Translation>;
  /** delete data from the table: "tasks" */
  delete_tasks?: Maybe<Tasks_Mutation_Response>;
  /** delete single row from the table: "tasks" */
  delete_tasks_by_pk?: Maybe<Tasks>;
  /** delete data from the table: "tool_versions" */
  delete_tool_versions?: Maybe<Tool_Versions_Mutation_Response>;
  /** delete single row from the table: "tool_versions" */
  delete_tool_versions_by_pk?: Maybe<Tool_Versions>;
  /** delete data from the table: "tools" */
  delete_tools?: Maybe<Tools_Mutation_Response>;
  /** delete single row from the table: "tools" */
  delete_tools_by_pk?: Maybe<Tools>;
  /** delete data from the table: "types" */
  delete_types?: Maybe<Types_Mutation_Response>;
  /** delete single row from the table: "types" */
  delete_types_by_pk?: Maybe<Types>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "xtensa_configurations" */
  delete_xtensa_configurations?: Maybe<Xtensa_Configurations_Mutation_Response>;
  /** delete single row from the table: "xtensa_configurations" */
  delete_xtensa_configurations_by_pk?: Maybe<Xtensa_Configurations>;
  /** insert data into the table: "audio_driver" */
  insert_audio_driver?: Maybe<Audio_Driver_Mutation_Response>;
  /** insert a single row into the table: "audio_driver" */
  insert_audio_driver_one?: Maybe<Audio_Driver>;
  /** insert data into the table: "audio_measurements.components" */
  insert_audio_measurements_components?: Maybe<Audio_Measurements_Components_Mutation_Response>;
  /** insert a single row into the table: "audio_measurements.components" */
  insert_audio_measurements_components_one?: Maybe<Audio_Measurements_Components>;
  /** insert data into the table: "audio_measurements.configurations" */
  insert_audio_measurements_configurations?: Maybe<Audio_Measurements_Configurations_Mutation_Response>;
  /** insert a single row into the table: "audio_measurements.configurations" */
  insert_audio_measurements_configurations_one?: Maybe<Audio_Measurements_Configurations>;
  /** insert data into the table: "audio_measurements.master_files" */
  insert_audio_measurements_master_files?: Maybe<Audio_Measurements_Master_Files_Mutation_Response>;
  /** insert a single row into the table: "audio_measurements.master_files" */
  insert_audio_measurements_master_files_one?: Maybe<Audio_Measurements_Master_Files>;
  /** insert data into the table: "audio_measurements.orientations" */
  insert_audio_measurements_orientations?: Maybe<Audio_Measurements_Orientations_Mutation_Response>;
  /** insert a single row into the table: "audio_measurements.orientations" */
  insert_audio_measurements_orientations_one?: Maybe<Audio_Measurements_Orientations>;
  /** insert data into the table: "audio_measurements.payload_recording" */
  insert_audio_measurements_payload_recording?: Maybe<Audio_Measurements_Payload_Recording_Mutation_Response>;
  /** insert a single row into the table: "audio_measurements.payload_recording" */
  insert_audio_measurements_payload_recording_one?: Maybe<Audio_Measurements_Payload_Recording>;
  /** insert data into the table: "audio_measurements.placements" */
  insert_audio_measurements_placements?: Maybe<Audio_Measurements_Placements_Mutation_Response>;
  /** insert a single row into the table: "audio_measurements.placements" */
  insert_audio_measurements_placements_one?: Maybe<Audio_Measurements_Placements>;
  /** insert data into the table: "audio_measurements.project_tasks" */
  insert_audio_measurements_project_tasks?: Maybe<Audio_Measurements_Project_Tasks_Mutation_Response>;
  /** insert a single row into the table: "audio_measurements.project_tasks" */
  insert_audio_measurements_project_tasks_one?: Maybe<Audio_Measurements_Project_Tasks>;
  /** insert data into the table: "audio_measurements.recordings" */
  insert_audio_measurements_recordings?: Maybe<Audio_Measurements_Recordings_Mutation_Response>;
  /** insert a single row into the table: "audio_measurements.recordings" */
  insert_audio_measurements_recordings_one?: Maybe<Audio_Measurements_Recordings>;
  /** insert data into the table: "audio_measurements.stages" */
  insert_audio_measurements_stages?: Maybe<Audio_Measurements_Stages_Mutation_Response>;
  /** insert a single row into the table: "audio_measurements.stages" */
  insert_audio_measurements_stages_one?: Maybe<Audio_Measurements_Stages>;
  /** insert data into the table: "bringup.checklist_deliverable" */
  insert_bringup_checklist_deliverable?: Maybe<Bringup_Checklist_Deliverable_Mutation_Response>;
  /** insert a single row into the table: "bringup.checklist_deliverable" */
  insert_bringup_checklist_deliverable_one?: Maybe<Bringup_Checklist_Deliverable>;
  /** insert data into the table: "bringup.checklists" */
  insert_bringup_checklists?: Maybe<Bringup_Checklists_Mutation_Response>;
  /** insert a single row into the table: "bringup.checklists" */
  insert_bringup_checklists_one?: Maybe<Bringup_Checklists>;
  /** insert data into the table: "bringup.endorsements" */
  insert_bringup_endorsements?: Maybe<Bringup_Endorsements_Mutation_Response>;
  /** insert a single row into the table: "bringup.endorsements" */
  insert_bringup_endorsements_one?: Maybe<Bringup_Endorsements>;
  /** insert data into the table: "bringup.items" */
  insert_bringup_items?: Maybe<Bringup_Items_Mutation_Response>;
  /** insert a single row into the table: "bringup.items" */
  insert_bringup_items_one?: Maybe<Bringup_Items>;
  /** insert data into the table: "bringup.milestones" */
  insert_bringup_milestones?: Maybe<Bringup_Milestones_Mutation_Response>;
  /** insert a single row into the table: "bringup.milestones" */
  insert_bringup_milestones_one?: Maybe<Bringup_Milestones>;
  /** insert data into the table: "bringup.project_item_comments" */
  insert_bringup_project_item_comments?: Maybe<Bringup_Project_Item_Comments_Mutation_Response>;
  /** insert a single row into the table: "bringup.project_item_comments" */
  insert_bringup_project_item_comments_one?: Maybe<Bringup_Project_Item_Comments>;
  /** insert data into the table: "categories" */
  insert_categories?: Maybe<Categories_Mutation_Response>;
  /** insert a single row into the table: "categories" */
  insert_categories_one?: Maybe<Categories>;
  /** insert data into the table: "context_display_field" */
  insert_context_display_field?: Maybe<Context_Display_Field_Mutation_Response>;
  /** insert a single row into the table: "context_display_field" */
  insert_context_display_field_one?: Maybe<Context_Display_Field>;
  /** insert data into the table: "context_displays" */
  insert_context_displays?: Maybe<Context_Displays_Mutation_Response>;
  /** insert a single row into the table: "context_displays" */
  insert_context_displays_one?: Maybe<Context_Displays>;
  /** insert data into the table: "contexts" */
  insert_contexts?: Maybe<Contexts_Mutation_Response>;
  /** insert a single row into the table: "contexts" */
  insert_contexts_one?: Maybe<Contexts>;
  /** insert data into the table: "customers" */
  insert_customers?: Maybe<Customers_Mutation_Response>;
  /** insert a single row into the table: "customers" */
  insert_customers_one?: Maybe<Customers>;
  /** insert data into the table: "deliverables" */
  insert_deliverables?: Maybe<Deliverables_Mutation_Response>;
  /** insert a single row into the table: "deliverables" */
  insert_deliverables_one?: Maybe<Deliverables>;
  /** insert data into the table: "deliveries.architectures" */
  insert_deliveries_architectures?: Maybe<Deliveries_Architectures_Mutation_Response>;
  /** insert a single row into the table: "deliveries.architectures" */
  insert_deliveries_architectures_one?: Maybe<Deliveries_Architectures>;
  /** insert data into the table: "deliveries.artifact_microsoft_whql_submission" */
  insert_deliveries_artifact_microsoft_whql_submission?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission_Mutation_Response>;
  /** insert a single row into the table: "deliveries.artifact_microsoft_whql_submission" */
  insert_deliveries_artifact_microsoft_whql_submission_one?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission>;
  /** insert data into the table: "deliveries.artifact_operating_system" */
  insert_deliveries_artifact_operating_system?: Maybe<Deliveries_Artifact_Operating_System_Mutation_Response>;
  /** insert a single row into the table: "deliveries.artifact_operating_system" */
  insert_deliveries_artifact_operating_system_one?: Maybe<Deliveries_Artifact_Operating_System>;
  /** insert data into the table: "deliveries.artifacts" */
  insert_deliveries_artifacts?: Maybe<Deliveries_Artifacts_Mutation_Response>;
  /** insert a single row into the table: "deliveries.artifacts" */
  insert_deliveries_artifacts_one?: Maybe<Deliveries_Artifacts>;
  /** insert data into the table: "deliveries.device_families" */
  insert_deliveries_device_families?: Maybe<Deliveries_Device_Families_Mutation_Response>;
  /** insert a single row into the table: "deliveries.device_families" */
  insert_deliveries_device_families_one?: Maybe<Deliveries_Device_Families>;
  /** insert data into the table: "deliveries.driver_package" */
  insert_deliveries_driver_package?: Maybe<Deliveries_Driver_Package_Mutation_Response>;
  /** insert a single row into the table: "deliveries.driver_package" */
  insert_deliveries_driver_package_one?: Maybe<Deliveries_Driver_Package>;
  /** insert data into the table: "deliveries.driver_project" */
  insert_deliveries_driver_project?: Maybe<Deliveries_Driver_Project_Mutation_Response>;
  /** insert a single row into the table: "deliveries.driver_project" */
  insert_deliveries_driver_project_one?: Maybe<Deliveries_Driver_Project>;
  /** insert data into the table: "deliveries.driver_version_engine" */
  insert_deliveries_driver_version_engine?: Maybe<Deliveries_Driver_Version_Engine_Mutation_Response>;
  /** insert a single row into the table: "deliveries.driver_version_engine" */
  insert_deliveries_driver_version_engine_one?: Maybe<Deliveries_Driver_Version_Engine>;
  /** insert data into the table: "deliveries.driver_version_package" */
  insert_deliveries_driver_version_package?: Maybe<Deliveries_Driver_Version_Package_Mutation_Response>;
  /** insert a single row into the table: "deliveries.driver_version_package" */
  insert_deliveries_driver_version_package_one?: Maybe<Deliveries_Driver_Version_Package>;
  /** insert data into the table: "deliveries.driver_versions" */
  insert_deliveries_driver_versions?: Maybe<Deliveries_Driver_Versions_Mutation_Response>;
  /** insert a single row into the table: "deliveries.driver_versions" */
  insert_deliveries_driver_versions_one?: Maybe<Deliveries_Driver_Versions>;
  /** insert data into the table: "deliveries.drivers" */
  insert_deliveries_drivers?: Maybe<Deliveries_Drivers_Mutation_Response>;
  /** insert a single row into the table: "deliveries.drivers" */
  insert_deliveries_drivers_one?: Maybe<Deliveries_Drivers>;
  /** insert data into the table: "deliveries.microsoft_hlk_artifacts" */
  insert_deliveries_microsoft_hlk_artifacts?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_Mutation_Response>;
  /** insert a single row into the table: "deliveries.microsoft_hlk_artifacts" */
  insert_deliveries_microsoft_hlk_artifacts_one?: Maybe<Deliveries_Microsoft_Hlk_Artifacts>;
  /** insert data into the table: "deliveries.microsoft_shipping_label_reviews" */
  insert_deliveries_microsoft_shipping_label_reviews?: Maybe<Deliveries_Microsoft_Shipping_Label_Reviews_Mutation_Response>;
  /** insert a single row into the table: "deliveries.microsoft_shipping_label_reviews" */
  insert_deliveries_microsoft_shipping_label_reviews_one?: Maybe<Deliveries_Microsoft_Shipping_Label_Reviews>;
  /** insert data into the table: "deliveries.microsoft_shipping_labels" */
  insert_deliveries_microsoft_shipping_labels?: Maybe<Deliveries_Microsoft_Shipping_Labels_Mutation_Response>;
  /** insert a single row into the table: "deliveries.microsoft_shipping_labels" */
  insert_deliveries_microsoft_shipping_labels_one?: Maybe<Deliveries_Microsoft_Shipping_Labels>;
  /** insert data into the table: "deliveries.microsoft_whql_submissions" */
  insert_deliveries_microsoft_whql_submissions?: Maybe<Deliveries_Microsoft_Whql_Submissions_Mutation_Response>;
  /** insert a single row into the table: "deliveries.microsoft_whql_submissions" */
  insert_deliveries_microsoft_whql_submissions_one?: Maybe<Deliveries_Microsoft_Whql_Submissions>;
  /** insert data into the table: "deliveries.operating_systems" */
  insert_deliveries_operating_systems?: Maybe<Deliveries_Operating_Systems_Mutation_Response>;
  /** insert a single row into the table: "deliveries.operating_systems" */
  insert_deliveries_operating_systems_one?: Maybe<Deliveries_Operating_Systems>;
  /** insert data into the table: "deliveries.package_operating_systems" */
  insert_deliveries_package_operating_systems?: Maybe<Deliveries_Package_Operating_Systems_Mutation_Response>;
  /** insert a single row into the table: "deliveries.package_operating_systems" */
  insert_deliveries_package_operating_systems_one?: Maybe<Deliveries_Package_Operating_Systems>;
  /** insert data into the table: "deliveries.package_target" */
  insert_deliveries_package_target?: Maybe<Deliveries_Package_Target_Mutation_Response>;
  /** insert a single row into the table: "deliveries.package_target" */
  insert_deliveries_package_target_one?: Maybe<Deliveries_Package_Target>;
  /** insert data into the table: "deliveries.packages" */
  insert_deliveries_packages?: Maybe<Deliveries_Packages_Mutation_Response>;
  /** insert a single row into the table: "deliveries.packages" */
  insert_deliveries_packages_one?: Maybe<Deliveries_Packages>;
  /** insert data into the table: "deliveries.partners" */
  insert_deliveries_partners?: Maybe<Deliveries_Partners_Mutation_Response>;
  /** insert a single row into the table: "deliveries.partners" */
  insert_deliveries_partners_one?: Maybe<Deliveries_Partners>;
  /** insert data into the table: "deliveries.platforms" */
  insert_deliveries_platforms?: Maybe<Deliveries_Platforms_Mutation_Response>;
  /** insert a single row into the table: "deliveries.platforms" */
  insert_deliveries_platforms_one?: Maybe<Deliveries_Platforms>;
  /** insert data into the table: "deliveries.project_configs" */
  insert_deliveries_project_configs?: Maybe<Deliveries_Project_Configs_Mutation_Response>;
  /** insert a single row into the table: "deliveries.project_configs" */
  insert_deliveries_project_configs_one?: Maybe<Deliveries_Project_Configs>;
  /** insert data into the table: "deliveries.target_partner" */
  insert_deliveries_target_partner?: Maybe<Deliveries_Target_Partner_Mutation_Response>;
  /** insert a single row into the table: "deliveries.target_partner" */
  insert_deliveries_target_partner_one?: Maybe<Deliveries_Target_Partner>;
  /** insert data into the table: "deliveries.targets" */
  insert_deliveries_targets?: Maybe<Deliveries_Targets_Mutation_Response>;
  /** insert a single row into the table: "deliveries.targets" */
  insert_deliveries_targets_one?: Maybe<Deliveries_Targets>;
  /** insert data into the table: "device_firmware" */
  insert_device_firmware?: Maybe<Device_Firmware_Mutation_Response>;
  /** insert a single row into the table: "device_firmware" */
  insert_device_firmware_one?: Maybe<Device_Firmware>;
  /** insert data into the table: "device_project" */
  insert_device_project?: Maybe<Device_Project_Mutation_Response>;
  /** insert a single row into the table: "device_project" */
  insert_device_project_one?: Maybe<Device_Project>;
  /** insert data into the table: "devices" */
  insert_devices?: Maybe<Devices_Mutation_Response>;
  /** insert a single row into the table: "devices" */
  insert_devices_one?: Maybe<Devices>;
  /** insert data into the table: "dynamic_scaling" */
  insert_dynamic_scaling?: Maybe<Dynamic_Scaling_Mutation_Response>;
  /** insert a single row into the table: "dynamic_scaling" */
  insert_dynamic_scaling_one?: Maybe<Dynamic_Scaling>;
  /** insert data into the table: "engine_opmode" */
  insert_engine_opmode?: Maybe<Engine_Opmode_Mutation_Response>;
  /** insert a single row into the table: "engine_opmode" */
  insert_engine_opmode_one?: Maybe<Engine_Opmode>;
  /** insert data into the table: "engine_opmode_recording" */
  insert_engine_opmode_recording?: Maybe<Engine_Opmode_Recording_Mutation_Response>;
  /** insert a single row into the table: "engine_opmode_recording" */
  insert_engine_opmode_recording_one?: Maybe<Engine_Opmode_Recording>;
  /** insert data into the table: "engines" */
  insert_engines?: Maybe<Engines_Mutation_Response>;
  /** insert a single row into the table: "engines" */
  insert_engines_one?: Maybe<Engines>;
  /** insert data into the table: "field_option_media" */
  insert_field_option_media?: Maybe<Field_Option_Media_Mutation_Response>;
  /** insert a single row into the table: "field_option_media" */
  insert_field_option_media_one?: Maybe<Field_Option_Media>;
  /** insert data into the table: "field_options" */
  insert_field_options?: Maybe<Field_Options_Mutation_Response>;
  /** insert a single row into the table: "field_options" */
  insert_field_options_one?: Maybe<Field_Options>;
  /** insert data into the table: "field_translation" */
  insert_field_translation?: Maybe<Field_Translation_Mutation_Response>;
  /** insert a single row into the table: "field_translation" */
  insert_field_translation_one?: Maybe<Field_Translation>;
  /** insert data into the table: "fields" */
  insert_fields?: Maybe<Fields_Mutation_Response>;
  /** insert a single row into the table: "fields" */
  insert_fields_one?: Maybe<Fields>;
  /** insert data into the table: "firmware" */
  insert_firmware?: Maybe<Firmware_Mutation_Response>;
  /** insert a single row into the table: "firmware" */
  insert_firmware_one?: Maybe<Firmware>;
  /** insert data into the table: "ground_truth.configurations" */
  insert_ground_truth_configurations?: Maybe<Ground_Truth_Configurations_Mutation_Response>;
  /** insert a single row into the table: "ground_truth.configurations" */
  insert_ground_truth_configurations_one?: Maybe<Ground_Truth_Configurations>;
  /** insert data into the table: "ground_truth.recordings" */
  insert_ground_truth_recordings?: Maybe<Ground_Truth_Recordings_Mutation_Response>;
  /** insert a single row into the table: "ground_truth.recordings" */
  insert_ground_truth_recordings_one?: Maybe<Ground_Truth_Recordings>;
  /** insert data into the table: "ground_truth_types" */
  insert_ground_truth_types?: Maybe<Ground_Truth_Types_Mutation_Response>;
  /** insert a single row into the table: "ground_truth_types" */
  insert_ground_truth_types_one?: Maybe<Ground_Truth_Types>;
  /** insert data into the table: "hypertask_field_option" */
  insert_hypertask_field_option?: Maybe<Hypertask_Field_Option_Mutation_Response>;
  /** insert a single row into the table: "hypertask_field_option" */
  insert_hypertask_field_option_one?: Maybe<Hypertask_Field_Option>;
  /** insert data into the table: "hypertask_task" */
  insert_hypertask_task?: Maybe<Hypertask_Task_Mutation_Response>;
  /** insert a single row into the table: "hypertask_task" */
  insert_hypertask_task_one?: Maybe<Hypertask_Task>;
  /** insert data into the table: "hypertasks" */
  insert_hypertasks?: Maybe<Hypertasks_Mutation_Response>;
  /** insert a single row into the table: "hypertasks" */
  insert_hypertasks_one?: Maybe<Hypertasks>;
  /** insert data into the table: "instruction_translations" */
  insert_instruction_translations?: Maybe<Instruction_Translations_Mutation_Response>;
  /** insert a single row into the table: "instruction_translations" */
  insert_instruction_translations_one?: Maybe<Instruction_Translations>;
  /** insert data into the table: "integration_test.expectation_media" */
  insert_integration_test_expectation_media?: Maybe<Integration_Test_Expectation_Media_Mutation_Response>;
  /** insert a single row into the table: "integration_test.expectation_media" */
  insert_integration_test_expectation_media_one?: Maybe<Integration_Test_Expectation_Media>;
  /** insert data into the table: "integration_test.expectation_step" */
  insert_integration_test_expectation_step?: Maybe<Integration_Test_Expectation_Step_Mutation_Response>;
  /** insert a single row into the table: "integration_test.expectation_step" */
  insert_integration_test_expectation_step_one?: Maybe<Integration_Test_Expectation_Step>;
  /** insert data into the table: "integration_test.expectation_translation" */
  insert_integration_test_expectation_translation?: Maybe<Integration_Test_Expectation_Translation_Mutation_Response>;
  /** insert a single row into the table: "integration_test.expectation_translation" */
  insert_integration_test_expectation_translation_one?: Maybe<Integration_Test_Expectation_Translation>;
  /** insert data into the table: "integration_test.expectations" */
  insert_integration_test_expectations?: Maybe<Integration_Test_Expectations_Mutation_Response>;
  /** insert a single row into the table: "integration_test.expectations" */
  insert_integration_test_expectations_one?: Maybe<Integration_Test_Expectations>;
  /** insert data into the table: "integration_test.instruction_media" */
  insert_integration_test_instruction_media?: Maybe<Integration_Test_Instruction_Media_Mutation_Response>;
  /** insert a single row into the table: "integration_test.instruction_media" */
  insert_integration_test_instruction_media_one?: Maybe<Integration_Test_Instruction_Media>;
  /** insert data into the table: "integration_test.instruction_translation" */
  insert_integration_test_instruction_translation?: Maybe<Integration_Test_Instruction_Translation_Mutation_Response>;
  /** insert a single row into the table: "integration_test.instruction_translation" */
  insert_integration_test_instruction_translation_one?: Maybe<Integration_Test_Instruction_Translation>;
  /** insert data into the table: "integration_test.instructions" */
  insert_integration_test_instructions?: Maybe<Integration_Test_Instructions_Mutation_Response>;
  /** insert a single row into the table: "integration_test.instructions" */
  insert_integration_test_instructions_one?: Maybe<Integration_Test_Instructions>;
  /** insert data into the table: "integration_test.observances" */
  insert_integration_test_observances?: Maybe<Integration_Test_Observances_Mutation_Response>;
  /** insert a single row into the table: "integration_test.observances" */
  insert_integration_test_observances_one?: Maybe<Integration_Test_Observances>;
  /** insert data into the table: "integration_test.observations" */
  insert_integration_test_observations?: Maybe<Integration_Test_Observations_Mutation_Response>;
  /** insert a single row into the table: "integration_test.observations" */
  insert_integration_test_observations_one?: Maybe<Integration_Test_Observations>;
  /** insert data into the table: "integration_test.project_sequence" */
  insert_integration_test_project_sequence?: Maybe<Integration_Test_Project_Sequence_Mutation_Response>;
  /** insert a single row into the table: "integration_test.project_sequence" */
  insert_integration_test_project_sequence_one?: Maybe<Integration_Test_Project_Sequence>;
  /** insert data into the table: "integration_test.sequences" */
  insert_integration_test_sequences?: Maybe<Integration_Test_Sequences_Mutation_Response>;
  /** insert a single row into the table: "integration_test.sequences" */
  insert_integration_test_sequences_one?: Maybe<Integration_Test_Sequences>;
  /** insert data into the table: "integration_test.steps" */
  insert_integration_test_steps?: Maybe<Integration_Test_Steps_Mutation_Response>;
  /** insert a single row into the table: "integration_test.steps" */
  insert_integration_test_steps_one?: Maybe<Integration_Test_Steps>;
  /** insert data into the table: "machine_learning.blobs" */
  insert_machine_learning_blobs?: Maybe<Machine_Learning_Blobs_Mutation_Response>;
  /** insert a single row into the table: "machine_learning.blobs" */
  insert_machine_learning_blobs_one?: Maybe<Machine_Learning_Blobs>;
  /** insert data into the table: "machine_learning.dataset_feature" */
  insert_machine_learning_dataset_feature?: Maybe<Machine_Learning_Dataset_Feature_Mutation_Response>;
  /** insert a single row into the table: "machine_learning.dataset_feature" */
  insert_machine_learning_dataset_feature_one?: Maybe<Machine_Learning_Dataset_Feature>;
  /** insert data into the table: "machine_learning.datasets" */
  insert_machine_learning_datasets?: Maybe<Machine_Learning_Datasets_Mutation_Response>;
  /** insert a single row into the table: "machine_learning.datasets" */
  insert_machine_learning_datasets_one?: Maybe<Machine_Learning_Datasets>;
  /** insert data into the table: "machine_learning.features" */
  insert_machine_learning_features?: Maybe<Machine_Learning_Features_Mutation_Response>;
  /** insert a single row into the table: "machine_learning.features" */
  insert_machine_learning_features_one?: Maybe<Machine_Learning_Features>;
  /** insert data into the table: "machine_learning.model_blob" */
  insert_machine_learning_model_blob?: Maybe<Machine_Learning_Model_Blob_Mutation_Response>;
  /** insert a single row into the table: "machine_learning.model_blob" */
  insert_machine_learning_model_blob_one?: Maybe<Machine_Learning_Model_Blob>;
  /** insert data into the table: "machine_learning.model_dataset" */
  insert_machine_learning_model_dataset?: Maybe<Machine_Learning_Model_Dataset_Mutation_Response>;
  /** insert a single row into the table: "machine_learning.model_dataset" */
  insert_machine_learning_model_dataset_one?: Maybe<Machine_Learning_Model_Dataset>;
  /** insert data into the table: "machine_learning.model_recording" */
  insert_machine_learning_model_recording?: Maybe<Machine_Learning_Model_Recording_Mutation_Response>;
  /** insert a single row into the table: "machine_learning.model_recording" */
  insert_machine_learning_model_recording_one?: Maybe<Machine_Learning_Model_Recording>;
  /** insert data into the table: "machine_learning.models" */
  insert_machine_learning_models?: Maybe<Machine_Learning_Models_Mutation_Response>;
  /** insert a single row into the table: "machine_learning.models" */
  insert_machine_learning_models_one?: Maybe<Machine_Learning_Models>;
  /** insert data into the table: "media" */
  insert_media?: Maybe<Media_Mutation_Response>;
  /** insert a single row into the table: "media" */
  insert_media_one?: Maybe<Media>;
  /** insert data into the table: "mode_project" */
  insert_mode_project?: Maybe<Mode_Project_Mutation_Response>;
  /** insert a single row into the table: "mode_project" */
  insert_mode_project_one?: Maybe<Mode_Project>;
  /** insert data into the table: "model_name_hashes" */
  insert_model_name_hashes?: Maybe<Model_Name_Hashes_Mutation_Response>;
  /** insert a single row into the table: "model_name_hashes" */
  insert_model_name_hashes_one?: Maybe<Model_Name_Hashes>;
  /** insert data into the table: "models" */
  insert_models?: Maybe<Models_Mutation_Response>;
  /** insert a single row into the table: "models" */
  insert_models_one?: Maybe<Models>;
  /** insert data into the table: "opmode_profile" */
  insert_opmode_profile?: Maybe<Opmode_Profile_Mutation_Response>;
  /** insert a single row into the table: "opmode_profile" */
  insert_opmode_profile_one?: Maybe<Opmode_Profile>;
  /** insert data into the table: "opmode_project" */
  insert_opmode_project?: Maybe<Opmode_Project_Mutation_Response>;
  /** insert a single row into the table: "opmode_project" */
  insert_opmode_project_one?: Maybe<Opmode_Project>;
  /** insert data into the table: "opmodes" */
  insert_opmodes?: Maybe<Opmodes_Mutation_Response>;
  /** insert a single row into the table: "opmodes" */
  insert_opmodes_one?: Maybe<Opmodes>;
  /** insert data into the table: "option_media" */
  insert_option_media?: Maybe<Option_Media_Mutation_Response>;
  /** insert a single row into the table: "option_media" */
  insert_option_media_one?: Maybe<Option_Media>;
  /** insert data into the table: "option_translation" */
  insert_option_translation?: Maybe<Option_Translation_Mutation_Response>;
  /** insert a single row into the table: "option_translation" */
  insert_option_translation_one?: Maybe<Option_Translation>;
  /** insert data into the table: "options" */
  insert_options?: Maybe<Options_Mutation_Response>;
  /** insert a single row into the table: "options" */
  insert_options_one?: Maybe<Options>;
  /** insert data into the table: "payload_recording" */
  insert_payload_recording?: Maybe<Payload_Recording_Mutation_Response>;
  /** insert a single row into the table: "payload_recording" */
  insert_payload_recording_one?: Maybe<Payload_Recording>;
  /** insert data into the table: "payloads" */
  insert_payloads?: Maybe<Payloads_Mutation_Response>;
  /** insert a single row into the table: "payloads" */
  insert_payloads_one?: Maybe<Payloads>;
  /** insert data into the table: "performance.ratio_metric_contributions" */
  insert_performance_ratio_metric_contributions?: Maybe<Performance_Ratio_Metric_Contributions_Mutation_Response>;
  /** insert a single row into the table: "performance.ratio_metric_contributions" */
  insert_performance_ratio_metric_contributions_one?: Maybe<Performance_Ratio_Metric_Contributions>;
  /** insert data into the table: "performance.ratio_metrics" */
  insert_performance_ratio_metrics?: Maybe<Performance_Ratio_Metrics_Mutation_Response>;
  /** insert a single row into the table: "performance.ratio_metrics" */
  insert_performance_ratio_metrics_one?: Maybe<Performance_Ratio_Metrics>;
  /** insert data into the table: "performance_report.deliverable_template" */
  insert_performance_report_deliverable_template?: Maybe<Performance_Report_Deliverable_Template_Mutation_Response>;
  /** insert a single row into the table: "performance_report.deliverable_template" */
  insert_performance_report_deliverable_template_one?: Maybe<Performance_Report_Deliverable_Template>;
  /** insert data into the table: "performance_report.section_pivot_field" */
  insert_performance_report_section_pivot_field?: Maybe<Performance_Report_Section_Pivot_Field_Mutation_Response>;
  /** insert a single row into the table: "performance_report.section_pivot_field" */
  insert_performance_report_section_pivot_field_one?: Maybe<Performance_Report_Section_Pivot_Field>;
  /** insert data into the table: "performance_report.section_ratio_metric" */
  insert_performance_report_section_ratio_metric?: Maybe<Performance_Report_Section_Ratio_Metric_Mutation_Response>;
  /** insert a single row into the table: "performance_report.section_ratio_metric" */
  insert_performance_report_section_ratio_metric_one?: Maybe<Performance_Report_Section_Ratio_Metric>;
  /** insert data into the table: "performance_report.section_task_blacklist" */
  insert_performance_report_section_task_blacklist?: Maybe<Performance_Report_Section_Task_Blacklist_Mutation_Response>;
  /** insert a single row into the table: "performance_report.section_task_blacklist" */
  insert_performance_report_section_task_blacklist_one?: Maybe<Performance_Report_Section_Task_Blacklist>;
  /** insert data into the table: "performance_report.sections" */
  insert_performance_report_sections?: Maybe<Performance_Report_Sections_Mutation_Response>;
  /** insert a single row into the table: "performance_report.sections" */
  insert_performance_report_sections_one?: Maybe<Performance_Report_Sections>;
  /** insert data into the table: "performance_report.templates" */
  insert_performance_report_templates?: Maybe<Performance_Report_Templates_Mutation_Response>;
  /** insert a single row into the table: "performance_report.templates" */
  insert_performance_report_templates_one?: Maybe<Performance_Report_Templates>;
  /** insert data into the table: "peripherals" */
  insert_peripherals?: Maybe<Peripherals_Mutation_Response>;
  /** insert a single row into the table: "peripherals" */
  insert_peripherals_one?: Maybe<Peripherals>;
  /** insert data into the table: "plan_hypertasks" */
  insert_plan_hypertasks?: Maybe<Plan_Hypertasks_Mutation_Response>;
  /** insert a single row into the table: "plan_hypertasks" */
  insert_plan_hypertasks_one?: Maybe<Plan_Hypertasks>;
  /** insert data into the table: "plan_tasks" */
  insert_plan_tasks?: Maybe<Plan_Tasks_Mutation_Response>;
  /** insert a single row into the table: "plan_tasks" */
  insert_plan_tasks_one?: Maybe<Plan_Tasks>;
  /** insert data into the table: "plans" */
  insert_plans?: Maybe<Plans_Mutation_Response>;
  /** insert a single row into the table: "plans" */
  insert_plans_one?: Maybe<Plans>;
  /** insert data into the table: "profiles" */
  insert_profiles?: Maybe<Profiles_Mutation_Response>;
  /** insert a single row into the table: "profiles" */
  insert_profiles_one?: Maybe<Profiles>;
  /** insert data into the table: "project_combo" */
  insert_project_combo?: Maybe<Project_Combo_Mutation_Response>;
  /** insert a single row into the table: "project_combo" */
  insert_project_combo_one?: Maybe<Project_Combo>;
  /** insert data into the table: "project_hypertasks" */
  insert_project_hypertasks?: Maybe<Project_Hypertasks_Mutation_Response>;
  /** insert a single row into the table: "project_hypertasks" */
  insert_project_hypertasks_one?: Maybe<Project_Hypertasks>;
  /** insert data into the table: "project_tasks" */
  insert_project_tasks?: Maybe<Project_Tasks_Mutation_Response>;
  /** insert a single row into the table: "project_tasks" */
  insert_project_tasks_one?: Maybe<Project_Tasks>;
  /** insert data into the table: "project_user" */
  insert_project_user?: Maybe<Project_User_Mutation_Response>;
  /** insert a single row into the table: "project_user" */
  insert_project_user_one?: Maybe<Project_User>;
  /** insert data into the table: "projects" */
  insert_projects?: Maybe<Projects_Mutation_Response>;
  /** insert a single row into the table: "projects" */
  insert_projects_one?: Maybe<Projects>;
  /** insert data into the table: "rabbit_test.plan_project" */
  insert_rabbit_test_plan_project?: Maybe<Rabbit_Test_Plan_Project_Mutation_Response>;
  /** insert a single row into the table: "rabbit_test.plan_project" */
  insert_rabbit_test_plan_project_one?: Maybe<Rabbit_Test_Plan_Project>;
  /** insert data into the table: "rabbit_test.plan_recording" */
  insert_rabbit_test_plan_recording?: Maybe<Rabbit_Test_Plan_Recording_Mutation_Response>;
  /** insert a single row into the table: "rabbit_test.plan_recording" */
  insert_rabbit_test_plan_recording_one?: Maybe<Rabbit_Test_Plan_Recording>;
  /** insert data into the table: "rabbit_test.plan_task" */
  insert_rabbit_test_plan_task?: Maybe<Rabbit_Test_Plan_Task_Mutation_Response>;
  /** insert a single row into the table: "rabbit_test.plan_task" */
  insert_rabbit_test_plan_task_one?: Maybe<Rabbit_Test_Plan_Task>;
  /** insert data into the table: "rabbit_test.plans" */
  insert_rabbit_test_plans?: Maybe<Rabbit_Test_Plans_Mutation_Response>;
  /** insert a single row into the table: "rabbit_test.plans" */
  insert_rabbit_test_plans_one?: Maybe<Rabbit_Test_Plans>;
  /** insert data into the table: "recording_apps" */
  insert_recording_apps?: Maybe<Recording_Apps_Mutation_Response>;
  /** insert a single row into the table: "recording_apps" */
  insert_recording_apps_one?: Maybe<Recording_Apps>;
  /** insert data into the table: "recording_audio_driver" */
  insert_recording_audio_driver?: Maybe<Recording_Audio_Driver_Mutation_Response>;
  /** insert a single row into the table: "recording_audio_driver" */
  insert_recording_audio_driver_one?: Maybe<Recording_Audio_Driver>;
  /** insert data into the table: "recording_data.ground_truth" */
  insert_recording_data_ground_truth?: Maybe<Recording_Data_Ground_Truth_Mutation_Response>;
  /** insert a single row into the table: "recording_data.ground_truth" */
  insert_recording_data_ground_truth_one?: Maybe<Recording_Data_Ground_Truth>;
  /** insert data into the table: "recording_data.labels" */
  insert_recording_data_labels?: Maybe<Recording_Data_Labels_Mutation_Response>;
  /** insert a single row into the table: "recording_data.labels" */
  insert_recording_data_labels_one?: Maybe<Recording_Data_Labels>;
  /** insert data into the table: "recording_data.mode_parameter" */
  insert_recording_data_mode_parameter?: Maybe<Recording_Data_Mode_Parameter_Mutation_Response>;
  /** insert a single row into the table: "recording_data.mode_parameter" */
  insert_recording_data_mode_parameter_one?: Maybe<Recording_Data_Mode_Parameter>;
  /** insert data into the table: "recording_data.modes" */
  insert_recording_data_modes?: Maybe<Recording_Data_Modes_Mutation_Response>;
  /** insert a single row into the table: "recording_data.modes" */
  insert_recording_data_modes_one?: Maybe<Recording_Data_Modes>;
  /** insert data into the table: "recording_data.parameters" */
  insert_recording_data_parameters?: Maybe<Recording_Data_Parameters_Mutation_Response>;
  /** insert a single row into the table: "recording_data.parameters" */
  insert_recording_data_parameters_one?: Maybe<Recording_Data_Parameters>;
  /** insert data into the table: "recording_data.payload_pair" */
  insert_recording_data_payload_pair?: Maybe<Recording_Data_Payload_Pair_Mutation_Response>;
  /** insert a single row into the table: "recording_data.payload_pair" */
  insert_recording_data_payload_pair_one?: Maybe<Recording_Data_Payload_Pair>;
  /** insert data into the table: "recording_data.predictions" */
  insert_recording_data_predictions?: Maybe<Recording_Data_Predictions_Mutation_Response>;
  /** insert a single row into the table: "recording_data.predictions" */
  insert_recording_data_predictions_one?: Maybe<Recording_Data_Predictions>;
  /** insert data into the table: "recording_recording_tag" */
  insert_recording_recording_tag?: Maybe<Recording_Recording_Tag_Mutation_Response>;
  /** insert a single row into the table: "recording_recording_tag" */
  insert_recording_recording_tag_one?: Maybe<Recording_Recording_Tag>;
  /** insert data into the table: "recording_tags" */
  insert_recording_tags?: Maybe<Recording_Tags_Mutation_Response>;
  /** insert a single row into the table: "recording_tags" */
  insert_recording_tags_one?: Maybe<Recording_Tags>;
  /** insert data into the table: "recordings" */
  insert_recordings?: Maybe<Recordings_Mutation_Response>;
  /** insert a single row into the table: "recordings" */
  insert_recordings_one?: Maybe<Recordings>;
  /** insert data into the table: "role_user" */
  insert_role_user?: Maybe<Role_User_Mutation_Response>;
  /** insert a single row into the table: "role_user" */
  insert_role_user_one?: Maybe<Role_User>;
  /** insert data into the table: "rooms" */
  insert_rooms?: Maybe<Rooms_Mutation_Response>;
  /** insert a single row into the table: "rooms" */
  insert_rooms_one?: Maybe<Rooms>;
  /** insert data into the table: "simulations.scores" */
  insert_simulations_scores?: Maybe<Simulations_Scores_Mutation_Response>;
  /** insert a single row into the table: "simulations.scores" */
  insert_simulations_scores_one?: Maybe<Simulations_Scores>;
  /** insert data into the table: "task_field_option" */
  insert_task_field_option?: Maybe<Task_Field_Option_Mutation_Response>;
  /** insert a single row into the table: "task_field_option" */
  insert_task_field_option_one?: Maybe<Task_Field_Option>;
  /** insert data into the table: "task_groups.group_fields" */
  insert_task_groups_group_fields?: Maybe<Task_Groups_Group_Fields_Mutation_Response>;
  /** insert a single row into the table: "task_groups.group_fields" */
  insert_task_groups_group_fields_one?: Maybe<Task_Groups_Group_Fields>;
  /** insert data into the table: "task_groups.groups" */
  insert_task_groups_groups?: Maybe<Task_Groups_Groups_Mutation_Response>;
  /** insert a single row into the table: "task_groups.groups" */
  insert_task_groups_groups_one?: Maybe<Task_Groups_Groups>;
  /** insert data into the table: "task_groups.hierarchies" */
  insert_task_groups_hierarchies?: Maybe<Task_Groups_Hierarchies_Mutation_Response>;
  /** insert a single row into the table: "task_groups.hierarchies" */
  insert_task_groups_hierarchies_one?: Maybe<Task_Groups_Hierarchies>;
  /** insert data into the table: "task_groups.hierarchy_groups" */
  insert_task_groups_hierarchy_groups?: Maybe<Task_Groups_Hierarchy_Groups_Mutation_Response>;
  /** insert a single row into the table: "task_groups.hierarchy_groups" */
  insert_task_groups_hierarchy_groups_one?: Maybe<Task_Groups_Hierarchy_Groups>;
  /** insert data into the table: "task_instructions" */
  insert_task_instructions?: Maybe<Task_Instructions_Mutation_Response>;
  /** insert a single row into the table: "task_instructions" */
  insert_task_instructions_one?: Maybe<Task_Instructions>;
  /** insert data into the table: "task_media" */
  insert_task_media?: Maybe<Task_Media_Mutation_Response>;
  /** insert a single row into the table: "task_media" */
  insert_task_media_one?: Maybe<Task_Media>;
  /** insert data into the table: "task_translation" */
  insert_task_translation?: Maybe<Task_Translation_Mutation_Response>;
  /** insert a single row into the table: "task_translation" */
  insert_task_translation_one?: Maybe<Task_Translation>;
  /** insert data into the table: "tasks" */
  insert_tasks?: Maybe<Tasks_Mutation_Response>;
  /** insert a single row into the table: "tasks" */
  insert_tasks_one?: Maybe<Tasks>;
  /** insert data into the table: "tool_versions" */
  insert_tool_versions?: Maybe<Tool_Versions_Mutation_Response>;
  /** insert a single row into the table: "tool_versions" */
  insert_tool_versions_one?: Maybe<Tool_Versions>;
  /** insert data into the table: "tools" */
  insert_tools?: Maybe<Tools_Mutation_Response>;
  /** insert a single row into the table: "tools" */
  insert_tools_one?: Maybe<Tools>;
  /** insert data into the table: "types" */
  insert_types?: Maybe<Types_Mutation_Response>;
  /** insert a single row into the table: "types" */
  insert_types_one?: Maybe<Types>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "xtensa_configurations" */
  insert_xtensa_configurations?: Maybe<Xtensa_Configurations_Mutation_Response>;
  /** insert a single row into the table: "xtensa_configurations" */
  insert_xtensa_configurations_one?: Maybe<Xtensa_Configurations>;
  /** update data of the table: "audio_driver" */
  update_audio_driver?: Maybe<Audio_Driver_Mutation_Response>;
  /** update single row of the table: "audio_driver" */
  update_audio_driver_by_pk?: Maybe<Audio_Driver>;
  /** update multiples rows of table: "audio_driver" */
  update_audio_driver_many?: Maybe<Array<Maybe<Audio_Driver_Mutation_Response>>>;
  /** update data of the table: "audio_measurements.components" */
  update_audio_measurements_components?: Maybe<Audio_Measurements_Components_Mutation_Response>;
  /** update single row of the table: "audio_measurements.components" */
  update_audio_measurements_components_by_pk?: Maybe<Audio_Measurements_Components>;
  /** update multiples rows of table: "audio_measurements.components" */
  update_audio_measurements_components_many?: Maybe<Array<Maybe<Audio_Measurements_Components_Mutation_Response>>>;
  /** update data of the table: "audio_measurements.configurations" */
  update_audio_measurements_configurations?: Maybe<Audio_Measurements_Configurations_Mutation_Response>;
  /** update single row of the table: "audio_measurements.configurations" */
  update_audio_measurements_configurations_by_pk?: Maybe<Audio_Measurements_Configurations>;
  /** update multiples rows of table: "audio_measurements.configurations" */
  update_audio_measurements_configurations_many?: Maybe<Array<Maybe<Audio_Measurements_Configurations_Mutation_Response>>>;
  /** update data of the table: "audio_measurements.master_files" */
  update_audio_measurements_master_files?: Maybe<Audio_Measurements_Master_Files_Mutation_Response>;
  /** update single row of the table: "audio_measurements.master_files" */
  update_audio_measurements_master_files_by_pk?: Maybe<Audio_Measurements_Master_Files>;
  /** update multiples rows of table: "audio_measurements.master_files" */
  update_audio_measurements_master_files_many?: Maybe<Array<Maybe<Audio_Measurements_Master_Files_Mutation_Response>>>;
  /** update data of the table: "audio_measurements.orientations" */
  update_audio_measurements_orientations?: Maybe<Audio_Measurements_Orientations_Mutation_Response>;
  /** update single row of the table: "audio_measurements.orientations" */
  update_audio_measurements_orientations_by_pk?: Maybe<Audio_Measurements_Orientations>;
  /** update multiples rows of table: "audio_measurements.orientations" */
  update_audio_measurements_orientations_many?: Maybe<Array<Maybe<Audio_Measurements_Orientations_Mutation_Response>>>;
  /** update data of the table: "audio_measurements.payload_recording" */
  update_audio_measurements_payload_recording?: Maybe<Audio_Measurements_Payload_Recording_Mutation_Response>;
  /** update single row of the table: "audio_measurements.payload_recording" */
  update_audio_measurements_payload_recording_by_pk?: Maybe<Audio_Measurements_Payload_Recording>;
  /** update multiples rows of table: "audio_measurements.payload_recording" */
  update_audio_measurements_payload_recording_many?: Maybe<Array<Maybe<Audio_Measurements_Payload_Recording_Mutation_Response>>>;
  /** update data of the table: "audio_measurements.placements" */
  update_audio_measurements_placements?: Maybe<Audio_Measurements_Placements_Mutation_Response>;
  /** update single row of the table: "audio_measurements.placements" */
  update_audio_measurements_placements_by_pk?: Maybe<Audio_Measurements_Placements>;
  /** update multiples rows of table: "audio_measurements.placements" */
  update_audio_measurements_placements_many?: Maybe<Array<Maybe<Audio_Measurements_Placements_Mutation_Response>>>;
  /** update data of the table: "audio_measurements.project_tasks" */
  update_audio_measurements_project_tasks?: Maybe<Audio_Measurements_Project_Tasks_Mutation_Response>;
  /** update single row of the table: "audio_measurements.project_tasks" */
  update_audio_measurements_project_tasks_by_pk?: Maybe<Audio_Measurements_Project_Tasks>;
  /** update multiples rows of table: "audio_measurements.project_tasks" */
  update_audio_measurements_project_tasks_many?: Maybe<Array<Maybe<Audio_Measurements_Project_Tasks_Mutation_Response>>>;
  /** update data of the table: "audio_measurements.recordings" */
  update_audio_measurements_recordings?: Maybe<Audio_Measurements_Recordings_Mutation_Response>;
  /** update single row of the table: "audio_measurements.recordings" */
  update_audio_measurements_recordings_by_pk?: Maybe<Audio_Measurements_Recordings>;
  /** update multiples rows of table: "audio_measurements.recordings" */
  update_audio_measurements_recordings_many?: Maybe<Array<Maybe<Audio_Measurements_Recordings_Mutation_Response>>>;
  /** update data of the table: "audio_measurements.stages" */
  update_audio_measurements_stages?: Maybe<Audio_Measurements_Stages_Mutation_Response>;
  /** update single row of the table: "audio_measurements.stages" */
  update_audio_measurements_stages_by_pk?: Maybe<Audio_Measurements_Stages>;
  /** update multiples rows of table: "audio_measurements.stages" */
  update_audio_measurements_stages_many?: Maybe<Array<Maybe<Audio_Measurements_Stages_Mutation_Response>>>;
  /** update data of the table: "bringup.checklist_deliverable" */
  update_bringup_checklist_deliverable?: Maybe<Bringup_Checklist_Deliverable_Mutation_Response>;
  /** update single row of the table: "bringup.checklist_deliverable" */
  update_bringup_checklist_deliverable_by_pk?: Maybe<Bringup_Checklist_Deliverable>;
  /** update multiples rows of table: "bringup.checklist_deliverable" */
  update_bringup_checklist_deliverable_many?: Maybe<Array<Maybe<Bringup_Checklist_Deliverable_Mutation_Response>>>;
  /** update data of the table: "bringup.checklists" */
  update_bringup_checklists?: Maybe<Bringup_Checklists_Mutation_Response>;
  /** update single row of the table: "bringup.checklists" */
  update_bringup_checklists_by_pk?: Maybe<Bringup_Checklists>;
  /** update multiples rows of table: "bringup.checklists" */
  update_bringup_checklists_many?: Maybe<Array<Maybe<Bringup_Checklists_Mutation_Response>>>;
  /** update data of the table: "bringup.endorsements" */
  update_bringup_endorsements?: Maybe<Bringup_Endorsements_Mutation_Response>;
  /** update single row of the table: "bringup.endorsements" */
  update_bringup_endorsements_by_pk?: Maybe<Bringup_Endorsements>;
  /** update multiples rows of table: "bringup.endorsements" */
  update_bringup_endorsements_many?: Maybe<Array<Maybe<Bringup_Endorsements_Mutation_Response>>>;
  /** update data of the table: "bringup.items" */
  update_bringup_items?: Maybe<Bringup_Items_Mutation_Response>;
  /** update single row of the table: "bringup.items" */
  update_bringup_items_by_pk?: Maybe<Bringup_Items>;
  /** update multiples rows of table: "bringup.items" */
  update_bringup_items_many?: Maybe<Array<Maybe<Bringup_Items_Mutation_Response>>>;
  /** update data of the table: "bringup.milestones" */
  update_bringup_milestones?: Maybe<Bringup_Milestones_Mutation_Response>;
  /** update single row of the table: "bringup.milestones" */
  update_bringup_milestones_by_pk?: Maybe<Bringup_Milestones>;
  /** update multiples rows of table: "bringup.milestones" */
  update_bringup_milestones_many?: Maybe<Array<Maybe<Bringup_Milestones_Mutation_Response>>>;
  /** update data of the table: "bringup.project_item_comments" */
  update_bringup_project_item_comments?: Maybe<Bringup_Project_Item_Comments_Mutation_Response>;
  /** update single row of the table: "bringup.project_item_comments" */
  update_bringup_project_item_comments_by_pk?: Maybe<Bringup_Project_Item_Comments>;
  /** update multiples rows of table: "bringup.project_item_comments" */
  update_bringup_project_item_comments_many?: Maybe<Array<Maybe<Bringup_Project_Item_Comments_Mutation_Response>>>;
  /** update data of the table: "categories" */
  update_categories?: Maybe<Categories_Mutation_Response>;
  /** update single row of the table: "categories" */
  update_categories_by_pk?: Maybe<Categories>;
  /** update multiples rows of table: "categories" */
  update_categories_many?: Maybe<Array<Maybe<Categories_Mutation_Response>>>;
  /** update data of the table: "context_display_field" */
  update_context_display_field?: Maybe<Context_Display_Field_Mutation_Response>;
  /** update single row of the table: "context_display_field" */
  update_context_display_field_by_pk?: Maybe<Context_Display_Field>;
  /** update multiples rows of table: "context_display_field" */
  update_context_display_field_many?: Maybe<Array<Maybe<Context_Display_Field_Mutation_Response>>>;
  /** update data of the table: "context_displays" */
  update_context_displays?: Maybe<Context_Displays_Mutation_Response>;
  /** update single row of the table: "context_displays" */
  update_context_displays_by_pk?: Maybe<Context_Displays>;
  /** update multiples rows of table: "context_displays" */
  update_context_displays_many?: Maybe<Array<Maybe<Context_Displays_Mutation_Response>>>;
  /** update data of the table: "contexts" */
  update_contexts?: Maybe<Contexts_Mutation_Response>;
  /** update single row of the table: "contexts" */
  update_contexts_by_pk?: Maybe<Contexts>;
  /** update multiples rows of table: "contexts" */
  update_contexts_many?: Maybe<Array<Maybe<Contexts_Mutation_Response>>>;
  /** update data of the table: "customers" */
  update_customers?: Maybe<Customers_Mutation_Response>;
  /** update single row of the table: "customers" */
  update_customers_by_pk?: Maybe<Customers>;
  /** update multiples rows of table: "customers" */
  update_customers_many?: Maybe<Array<Maybe<Customers_Mutation_Response>>>;
  /** update data of the table: "deliverables" */
  update_deliverables?: Maybe<Deliverables_Mutation_Response>;
  /** update single row of the table: "deliverables" */
  update_deliverables_by_pk?: Maybe<Deliverables>;
  /** update multiples rows of table: "deliverables" */
  update_deliverables_many?: Maybe<Array<Maybe<Deliverables_Mutation_Response>>>;
  /** update data of the table: "deliveries.architectures" */
  update_deliveries_architectures?: Maybe<Deliveries_Architectures_Mutation_Response>;
  /** update single row of the table: "deliveries.architectures" */
  update_deliveries_architectures_by_pk?: Maybe<Deliveries_Architectures>;
  /** update multiples rows of table: "deliveries.architectures" */
  update_deliveries_architectures_many?: Maybe<Array<Maybe<Deliveries_Architectures_Mutation_Response>>>;
  /** update data of the table: "deliveries.artifact_microsoft_whql_submission" */
  update_deliveries_artifact_microsoft_whql_submission?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission_Mutation_Response>;
  /** update single row of the table: "deliveries.artifact_microsoft_whql_submission" */
  update_deliveries_artifact_microsoft_whql_submission_by_pk?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission>;
  /** update multiples rows of table: "deliveries.artifact_microsoft_whql_submission" */
  update_deliveries_artifact_microsoft_whql_submission_many?: Maybe<Array<Maybe<Deliveries_Artifact_Microsoft_Whql_Submission_Mutation_Response>>>;
  /** update data of the table: "deliveries.artifact_operating_system" */
  update_deliveries_artifact_operating_system?: Maybe<Deliveries_Artifact_Operating_System_Mutation_Response>;
  /** update single row of the table: "deliveries.artifact_operating_system" */
  update_deliveries_artifact_operating_system_by_pk?: Maybe<Deliveries_Artifact_Operating_System>;
  /** update multiples rows of table: "deliveries.artifact_operating_system" */
  update_deliveries_artifact_operating_system_many?: Maybe<Array<Maybe<Deliveries_Artifact_Operating_System_Mutation_Response>>>;
  /** update data of the table: "deliveries.artifacts" */
  update_deliveries_artifacts?: Maybe<Deliveries_Artifacts_Mutation_Response>;
  /** update single row of the table: "deliveries.artifacts" */
  update_deliveries_artifacts_by_pk?: Maybe<Deliveries_Artifacts>;
  /** update multiples rows of table: "deliveries.artifacts" */
  update_deliveries_artifacts_many?: Maybe<Array<Maybe<Deliveries_Artifacts_Mutation_Response>>>;
  /** update data of the table: "deliveries.device_families" */
  update_deliveries_device_families?: Maybe<Deliveries_Device_Families_Mutation_Response>;
  /** update single row of the table: "deliveries.device_families" */
  update_deliveries_device_families_by_pk?: Maybe<Deliveries_Device_Families>;
  /** update multiples rows of table: "deliveries.device_families" */
  update_deliveries_device_families_many?: Maybe<Array<Maybe<Deliveries_Device_Families_Mutation_Response>>>;
  /** update data of the table: "deliveries.driver_package" */
  update_deliveries_driver_package?: Maybe<Deliveries_Driver_Package_Mutation_Response>;
  /** update single row of the table: "deliveries.driver_package" */
  update_deliveries_driver_package_by_pk?: Maybe<Deliveries_Driver_Package>;
  /** update multiples rows of table: "deliveries.driver_package" */
  update_deliveries_driver_package_many?: Maybe<Array<Maybe<Deliveries_Driver_Package_Mutation_Response>>>;
  /** update data of the table: "deliveries.driver_project" */
  update_deliveries_driver_project?: Maybe<Deliveries_Driver_Project_Mutation_Response>;
  /** update single row of the table: "deliveries.driver_project" */
  update_deliveries_driver_project_by_pk?: Maybe<Deliveries_Driver_Project>;
  /** update multiples rows of table: "deliveries.driver_project" */
  update_deliveries_driver_project_many?: Maybe<Array<Maybe<Deliveries_Driver_Project_Mutation_Response>>>;
  /** update data of the table: "deliveries.driver_version_engine" */
  update_deliveries_driver_version_engine?: Maybe<Deliveries_Driver_Version_Engine_Mutation_Response>;
  /** update single row of the table: "deliveries.driver_version_engine" */
  update_deliveries_driver_version_engine_by_pk?: Maybe<Deliveries_Driver_Version_Engine>;
  /** update multiples rows of table: "deliveries.driver_version_engine" */
  update_deliveries_driver_version_engine_many?: Maybe<Array<Maybe<Deliveries_Driver_Version_Engine_Mutation_Response>>>;
  /** update data of the table: "deliveries.driver_version_package" */
  update_deliveries_driver_version_package?: Maybe<Deliveries_Driver_Version_Package_Mutation_Response>;
  /** update single row of the table: "deliveries.driver_version_package" */
  update_deliveries_driver_version_package_by_pk?: Maybe<Deliveries_Driver_Version_Package>;
  /** update multiples rows of table: "deliveries.driver_version_package" */
  update_deliveries_driver_version_package_many?: Maybe<Array<Maybe<Deliveries_Driver_Version_Package_Mutation_Response>>>;
  /** update data of the table: "deliveries.driver_versions" */
  update_deliveries_driver_versions?: Maybe<Deliveries_Driver_Versions_Mutation_Response>;
  /** update single row of the table: "deliveries.driver_versions" */
  update_deliveries_driver_versions_by_pk?: Maybe<Deliveries_Driver_Versions>;
  /** update multiples rows of table: "deliveries.driver_versions" */
  update_deliveries_driver_versions_many?: Maybe<Array<Maybe<Deliveries_Driver_Versions_Mutation_Response>>>;
  /** update data of the table: "deliveries.drivers" */
  update_deliveries_drivers?: Maybe<Deliveries_Drivers_Mutation_Response>;
  /** update single row of the table: "deliveries.drivers" */
  update_deliveries_drivers_by_pk?: Maybe<Deliveries_Drivers>;
  /** update multiples rows of table: "deliveries.drivers" */
  update_deliveries_drivers_many?: Maybe<Array<Maybe<Deliveries_Drivers_Mutation_Response>>>;
  /** update data of the table: "deliveries.microsoft_hlk_artifacts" */
  update_deliveries_microsoft_hlk_artifacts?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_Mutation_Response>;
  /** update single row of the table: "deliveries.microsoft_hlk_artifacts" */
  update_deliveries_microsoft_hlk_artifacts_by_pk?: Maybe<Deliveries_Microsoft_Hlk_Artifacts>;
  /** update multiples rows of table: "deliveries.microsoft_hlk_artifacts" */
  update_deliveries_microsoft_hlk_artifacts_many?: Maybe<Array<Maybe<Deliveries_Microsoft_Hlk_Artifacts_Mutation_Response>>>;
  /** update data of the table: "deliveries.microsoft_shipping_label_reviews" */
  update_deliveries_microsoft_shipping_label_reviews?: Maybe<Deliveries_Microsoft_Shipping_Label_Reviews_Mutation_Response>;
  /** update single row of the table: "deliveries.microsoft_shipping_label_reviews" */
  update_deliveries_microsoft_shipping_label_reviews_by_pk?: Maybe<Deliveries_Microsoft_Shipping_Label_Reviews>;
  /** update multiples rows of table: "deliveries.microsoft_shipping_label_reviews" */
  update_deliveries_microsoft_shipping_label_reviews_many?: Maybe<Array<Maybe<Deliveries_Microsoft_Shipping_Label_Reviews_Mutation_Response>>>;
  /** update data of the table: "deliveries.microsoft_shipping_labels" */
  update_deliveries_microsoft_shipping_labels?: Maybe<Deliveries_Microsoft_Shipping_Labels_Mutation_Response>;
  /** update single row of the table: "deliveries.microsoft_shipping_labels" */
  update_deliveries_microsoft_shipping_labels_by_pk?: Maybe<Deliveries_Microsoft_Shipping_Labels>;
  /** update multiples rows of table: "deliveries.microsoft_shipping_labels" */
  update_deliveries_microsoft_shipping_labels_many?: Maybe<Array<Maybe<Deliveries_Microsoft_Shipping_Labels_Mutation_Response>>>;
  /** update data of the table: "deliveries.microsoft_whql_submissions" */
  update_deliveries_microsoft_whql_submissions?: Maybe<Deliveries_Microsoft_Whql_Submissions_Mutation_Response>;
  /** update single row of the table: "deliveries.microsoft_whql_submissions" */
  update_deliveries_microsoft_whql_submissions_by_pk?: Maybe<Deliveries_Microsoft_Whql_Submissions>;
  /** update multiples rows of table: "deliveries.microsoft_whql_submissions" */
  update_deliveries_microsoft_whql_submissions_many?: Maybe<Array<Maybe<Deliveries_Microsoft_Whql_Submissions_Mutation_Response>>>;
  /** update data of the table: "deliveries.operating_systems" */
  update_deliveries_operating_systems?: Maybe<Deliveries_Operating_Systems_Mutation_Response>;
  /** update single row of the table: "deliveries.operating_systems" */
  update_deliveries_operating_systems_by_pk?: Maybe<Deliveries_Operating_Systems>;
  /** update multiples rows of table: "deliveries.operating_systems" */
  update_deliveries_operating_systems_many?: Maybe<Array<Maybe<Deliveries_Operating_Systems_Mutation_Response>>>;
  /** update data of the table: "deliveries.package_operating_systems" */
  update_deliveries_package_operating_systems?: Maybe<Deliveries_Package_Operating_Systems_Mutation_Response>;
  /** update single row of the table: "deliveries.package_operating_systems" */
  update_deliveries_package_operating_systems_by_pk?: Maybe<Deliveries_Package_Operating_Systems>;
  /** update multiples rows of table: "deliveries.package_operating_systems" */
  update_deliveries_package_operating_systems_many?: Maybe<Array<Maybe<Deliveries_Package_Operating_Systems_Mutation_Response>>>;
  /** update data of the table: "deliveries.package_target" */
  update_deliveries_package_target?: Maybe<Deliveries_Package_Target_Mutation_Response>;
  /** update single row of the table: "deliveries.package_target" */
  update_deliveries_package_target_by_pk?: Maybe<Deliveries_Package_Target>;
  /** update multiples rows of table: "deliveries.package_target" */
  update_deliveries_package_target_many?: Maybe<Array<Maybe<Deliveries_Package_Target_Mutation_Response>>>;
  /** update data of the table: "deliveries.packages" */
  update_deliveries_packages?: Maybe<Deliveries_Packages_Mutation_Response>;
  /** update single row of the table: "deliveries.packages" */
  update_deliveries_packages_by_pk?: Maybe<Deliveries_Packages>;
  /** update multiples rows of table: "deliveries.packages" */
  update_deliveries_packages_many?: Maybe<Array<Maybe<Deliveries_Packages_Mutation_Response>>>;
  /** update data of the table: "deliveries.partners" */
  update_deliveries_partners?: Maybe<Deliveries_Partners_Mutation_Response>;
  /** update single row of the table: "deliveries.partners" */
  update_deliveries_partners_by_pk?: Maybe<Deliveries_Partners>;
  /** update multiples rows of table: "deliveries.partners" */
  update_deliveries_partners_many?: Maybe<Array<Maybe<Deliveries_Partners_Mutation_Response>>>;
  /** update data of the table: "deliveries.platforms" */
  update_deliveries_platforms?: Maybe<Deliveries_Platforms_Mutation_Response>;
  /** update single row of the table: "deliveries.platforms" */
  update_deliveries_platforms_by_pk?: Maybe<Deliveries_Platforms>;
  /** update multiples rows of table: "deliveries.platforms" */
  update_deliveries_platforms_many?: Maybe<Array<Maybe<Deliveries_Platforms_Mutation_Response>>>;
  /** update data of the table: "deliveries.project_configs" */
  update_deliveries_project_configs?: Maybe<Deliveries_Project_Configs_Mutation_Response>;
  /** update single row of the table: "deliveries.project_configs" */
  update_deliveries_project_configs_by_pk?: Maybe<Deliveries_Project_Configs>;
  /** update multiples rows of table: "deliveries.project_configs" */
  update_deliveries_project_configs_many?: Maybe<Array<Maybe<Deliveries_Project_Configs_Mutation_Response>>>;
  /** update data of the table: "deliveries.target_partner" */
  update_deliveries_target_partner?: Maybe<Deliveries_Target_Partner_Mutation_Response>;
  /** update single row of the table: "deliveries.target_partner" */
  update_deliveries_target_partner_by_pk?: Maybe<Deliveries_Target_Partner>;
  /** update multiples rows of table: "deliveries.target_partner" */
  update_deliveries_target_partner_many?: Maybe<Array<Maybe<Deliveries_Target_Partner_Mutation_Response>>>;
  /** update data of the table: "deliveries.targets" */
  update_deliveries_targets?: Maybe<Deliveries_Targets_Mutation_Response>;
  /** update single row of the table: "deliveries.targets" */
  update_deliveries_targets_by_pk?: Maybe<Deliveries_Targets>;
  /** update multiples rows of table: "deliveries.targets" */
  update_deliveries_targets_many?: Maybe<Array<Maybe<Deliveries_Targets_Mutation_Response>>>;
  /** update data of the table: "device_firmware" */
  update_device_firmware?: Maybe<Device_Firmware_Mutation_Response>;
  /** update single row of the table: "device_firmware" */
  update_device_firmware_by_pk?: Maybe<Device_Firmware>;
  /** update multiples rows of table: "device_firmware" */
  update_device_firmware_many?: Maybe<Array<Maybe<Device_Firmware_Mutation_Response>>>;
  /** update data of the table: "device_project" */
  update_device_project?: Maybe<Device_Project_Mutation_Response>;
  /** update single row of the table: "device_project" */
  update_device_project_by_pk?: Maybe<Device_Project>;
  /** update multiples rows of table: "device_project" */
  update_device_project_many?: Maybe<Array<Maybe<Device_Project_Mutation_Response>>>;
  /** update data of the table: "devices" */
  update_devices?: Maybe<Devices_Mutation_Response>;
  /** update single row of the table: "devices" */
  update_devices_by_pk?: Maybe<Devices>;
  /** update multiples rows of table: "devices" */
  update_devices_many?: Maybe<Array<Maybe<Devices_Mutation_Response>>>;
  /** update data of the table: "dynamic_scaling" */
  update_dynamic_scaling?: Maybe<Dynamic_Scaling_Mutation_Response>;
  /** update single row of the table: "dynamic_scaling" */
  update_dynamic_scaling_by_pk?: Maybe<Dynamic_Scaling>;
  /** update multiples rows of table: "dynamic_scaling" */
  update_dynamic_scaling_many?: Maybe<Array<Maybe<Dynamic_Scaling_Mutation_Response>>>;
  /** update data of the table: "engine_opmode" */
  update_engine_opmode?: Maybe<Engine_Opmode_Mutation_Response>;
  /** update single row of the table: "engine_opmode" */
  update_engine_opmode_by_pk?: Maybe<Engine_Opmode>;
  /** update multiples rows of table: "engine_opmode" */
  update_engine_opmode_many?: Maybe<Array<Maybe<Engine_Opmode_Mutation_Response>>>;
  /** update data of the table: "engine_opmode_recording" */
  update_engine_opmode_recording?: Maybe<Engine_Opmode_Recording_Mutation_Response>;
  /** update single row of the table: "engine_opmode_recording" */
  update_engine_opmode_recording_by_pk?: Maybe<Engine_Opmode_Recording>;
  /** update multiples rows of table: "engine_opmode_recording" */
  update_engine_opmode_recording_many?: Maybe<Array<Maybe<Engine_Opmode_Recording_Mutation_Response>>>;
  /** update data of the table: "engine_types" */
  update_engine_types?: Maybe<Engine_Types_Mutation_Response>;
  /** update single row of the table: "engine_types" */
  update_engine_types_by_pk?: Maybe<Engine_Types>;
  /** update multiples rows of table: "engine_types" */
  update_engine_types_many?: Maybe<Array<Maybe<Engine_Types_Mutation_Response>>>;
  /** update data of the table: "engines" */
  update_engines?: Maybe<Engines_Mutation_Response>;
  /** update single row of the table: "engines" */
  update_engines_by_pk?: Maybe<Engines>;
  /** update multiples rows of table: "engines" */
  update_engines_many?: Maybe<Array<Maybe<Engines_Mutation_Response>>>;
  /** update data of the table: "field_option_media" */
  update_field_option_media?: Maybe<Field_Option_Media_Mutation_Response>;
  /** update single row of the table: "field_option_media" */
  update_field_option_media_by_pk?: Maybe<Field_Option_Media>;
  /** update multiples rows of table: "field_option_media" */
  update_field_option_media_many?: Maybe<Array<Maybe<Field_Option_Media_Mutation_Response>>>;
  /** update data of the table: "field_options" */
  update_field_options?: Maybe<Field_Options_Mutation_Response>;
  /** update single row of the table: "field_options" */
  update_field_options_by_pk?: Maybe<Field_Options>;
  /** update multiples rows of table: "field_options" */
  update_field_options_many?: Maybe<Array<Maybe<Field_Options_Mutation_Response>>>;
  /** update data of the table: "field_translation" */
  update_field_translation?: Maybe<Field_Translation_Mutation_Response>;
  /** update single row of the table: "field_translation" */
  update_field_translation_by_pk?: Maybe<Field_Translation>;
  /** update multiples rows of table: "field_translation" */
  update_field_translation_many?: Maybe<Array<Maybe<Field_Translation_Mutation_Response>>>;
  /** update data of the table: "fields" */
  update_fields?: Maybe<Fields_Mutation_Response>;
  /** update single row of the table: "fields" */
  update_fields_by_pk?: Maybe<Fields>;
  /** update multiples rows of table: "fields" */
  update_fields_many?: Maybe<Array<Maybe<Fields_Mutation_Response>>>;
  /** update data of the table: "firmware" */
  update_firmware?: Maybe<Firmware_Mutation_Response>;
  /** update single row of the table: "firmware" */
  update_firmware_by_pk?: Maybe<Firmware>;
  /** update multiples rows of table: "firmware" */
  update_firmware_many?: Maybe<Array<Maybe<Firmware_Mutation_Response>>>;
  /** update data of the table: "ground_truth.configurations" */
  update_ground_truth_configurations?: Maybe<Ground_Truth_Configurations_Mutation_Response>;
  /** update single row of the table: "ground_truth.configurations" */
  update_ground_truth_configurations_by_pk?: Maybe<Ground_Truth_Configurations>;
  /** update multiples rows of table: "ground_truth.configurations" */
  update_ground_truth_configurations_many?: Maybe<Array<Maybe<Ground_Truth_Configurations_Mutation_Response>>>;
  /** update data of the table: "ground_truth.device_types" */
  update_ground_truth_device_types?: Maybe<Ground_Truth_Device_Types_Mutation_Response>;
  /** update single row of the table: "ground_truth.device_types" */
  update_ground_truth_device_types_by_pk?: Maybe<Ground_Truth_Device_Types>;
  /** update multiples rows of table: "ground_truth.device_types" */
  update_ground_truth_device_types_many?: Maybe<Array<Maybe<Ground_Truth_Device_Types_Mutation_Response>>>;
  /** update data of the table: "ground_truth.processing_modes" */
  update_ground_truth_processing_modes?: Maybe<Ground_Truth_Processing_Modes_Mutation_Response>;
  /** update single row of the table: "ground_truth.processing_modes" */
  update_ground_truth_processing_modes_by_pk?: Maybe<Ground_Truth_Processing_Modes>;
  /** update multiples rows of table: "ground_truth.processing_modes" */
  update_ground_truth_processing_modes_many?: Maybe<Array<Maybe<Ground_Truth_Processing_Modes_Mutation_Response>>>;
  /** update data of the table: "ground_truth.recordings" */
  update_ground_truth_recordings?: Maybe<Ground_Truth_Recordings_Mutation_Response>;
  /** update single row of the table: "ground_truth.recordings" */
  update_ground_truth_recordings_by_pk?: Maybe<Ground_Truth_Recordings>;
  /** update multiples rows of table: "ground_truth.recordings" */
  update_ground_truth_recordings_many?: Maybe<Array<Maybe<Ground_Truth_Recordings_Mutation_Response>>>;
  /** update data of the table: "ground_truth_types" */
  update_ground_truth_types?: Maybe<Ground_Truth_Types_Mutation_Response>;
  /** update single row of the table: "ground_truth_types" */
  update_ground_truth_types_by_pk?: Maybe<Ground_Truth_Types>;
  /** update multiples rows of table: "ground_truth_types" */
  update_ground_truth_types_many?: Maybe<Array<Maybe<Ground_Truth_Types_Mutation_Response>>>;
  /** update data of the table: "hypertask_field_option" */
  update_hypertask_field_option?: Maybe<Hypertask_Field_Option_Mutation_Response>;
  /** update single row of the table: "hypertask_field_option" */
  update_hypertask_field_option_by_pk?: Maybe<Hypertask_Field_Option>;
  /** update multiples rows of table: "hypertask_field_option" */
  update_hypertask_field_option_many?: Maybe<Array<Maybe<Hypertask_Field_Option_Mutation_Response>>>;
  /** update data of the table: "hypertask_task" */
  update_hypertask_task?: Maybe<Hypertask_Task_Mutation_Response>;
  /** update single row of the table: "hypertask_task" */
  update_hypertask_task_by_pk?: Maybe<Hypertask_Task>;
  /** update multiples rows of table: "hypertask_task" */
  update_hypertask_task_many?: Maybe<Array<Maybe<Hypertask_Task_Mutation_Response>>>;
  /** update data of the table: "hypertasks" */
  update_hypertasks?: Maybe<Hypertasks_Mutation_Response>;
  /** update single row of the table: "hypertasks" */
  update_hypertasks_by_pk?: Maybe<Hypertasks>;
  /** update multiples rows of table: "hypertasks" */
  update_hypertasks_many?: Maybe<Array<Maybe<Hypertasks_Mutation_Response>>>;
  /** update data of the table: "instruction_translations" */
  update_instruction_translations?: Maybe<Instruction_Translations_Mutation_Response>;
  /** update single row of the table: "instruction_translations" */
  update_instruction_translations_by_pk?: Maybe<Instruction_Translations>;
  /** update multiples rows of table: "instruction_translations" */
  update_instruction_translations_many?: Maybe<Array<Maybe<Instruction_Translations_Mutation_Response>>>;
  /** update data of the table: "integration_test.expectation_media" */
  update_integration_test_expectation_media?: Maybe<Integration_Test_Expectation_Media_Mutation_Response>;
  /** update single row of the table: "integration_test.expectation_media" */
  update_integration_test_expectation_media_by_pk?: Maybe<Integration_Test_Expectation_Media>;
  /** update multiples rows of table: "integration_test.expectation_media" */
  update_integration_test_expectation_media_many?: Maybe<Array<Maybe<Integration_Test_Expectation_Media_Mutation_Response>>>;
  /** update data of the table: "integration_test.expectation_translation" */
  update_integration_test_expectation_translation?: Maybe<Integration_Test_Expectation_Translation_Mutation_Response>;
  /** update single row of the table: "integration_test.expectation_translation" */
  update_integration_test_expectation_translation_by_pk?: Maybe<Integration_Test_Expectation_Translation>;
  /** update multiples rows of table: "integration_test.expectation_translation" */
  update_integration_test_expectation_translation_many?: Maybe<Array<Maybe<Integration_Test_Expectation_Translation_Mutation_Response>>>;
  /** update data of the table: "integration_test.expectations" */
  update_integration_test_expectations?: Maybe<Integration_Test_Expectations_Mutation_Response>;
  /** update single row of the table: "integration_test.expectations" */
  update_integration_test_expectations_by_pk?: Maybe<Integration_Test_Expectations>;
  /** update multiples rows of table: "integration_test.expectations" */
  update_integration_test_expectations_many?: Maybe<Array<Maybe<Integration_Test_Expectations_Mutation_Response>>>;
  /** update data of the table: "integration_test.instruction_media" */
  update_integration_test_instruction_media?: Maybe<Integration_Test_Instruction_Media_Mutation_Response>;
  /** update single row of the table: "integration_test.instruction_media" */
  update_integration_test_instruction_media_by_pk?: Maybe<Integration_Test_Instruction_Media>;
  /** update multiples rows of table: "integration_test.instruction_media" */
  update_integration_test_instruction_media_many?: Maybe<Array<Maybe<Integration_Test_Instruction_Media_Mutation_Response>>>;
  /** update data of the table: "integration_test.instruction_translation" */
  update_integration_test_instruction_translation?: Maybe<Integration_Test_Instruction_Translation_Mutation_Response>;
  /** update single row of the table: "integration_test.instruction_translation" */
  update_integration_test_instruction_translation_by_pk?: Maybe<Integration_Test_Instruction_Translation>;
  /** update multiples rows of table: "integration_test.instruction_translation" */
  update_integration_test_instruction_translation_many?: Maybe<Array<Maybe<Integration_Test_Instruction_Translation_Mutation_Response>>>;
  /** update data of the table: "integration_test.instructions" */
  update_integration_test_instructions?: Maybe<Integration_Test_Instructions_Mutation_Response>;
  /** update single row of the table: "integration_test.instructions" */
  update_integration_test_instructions_by_pk?: Maybe<Integration_Test_Instructions>;
  /** update multiples rows of table: "integration_test.instructions" */
  update_integration_test_instructions_many?: Maybe<Array<Maybe<Integration_Test_Instructions_Mutation_Response>>>;
  /** update data of the table: "integration_test.observances" */
  update_integration_test_observances?: Maybe<Integration_Test_Observances_Mutation_Response>;
  /** update single row of the table: "integration_test.observances" */
  update_integration_test_observances_by_pk?: Maybe<Integration_Test_Observances>;
  /** update multiples rows of table: "integration_test.observances" */
  update_integration_test_observances_many?: Maybe<Array<Maybe<Integration_Test_Observances_Mutation_Response>>>;
  /** update data of the table: "integration_test.project_sequence" */
  update_integration_test_project_sequence?: Maybe<Integration_Test_Project_Sequence_Mutation_Response>;
  /** update single row of the table: "integration_test.project_sequence" */
  update_integration_test_project_sequence_by_pk?: Maybe<Integration_Test_Project_Sequence>;
  /** update multiples rows of table: "integration_test.project_sequence" */
  update_integration_test_project_sequence_many?: Maybe<Array<Maybe<Integration_Test_Project_Sequence_Mutation_Response>>>;
  /** update data of the table: "integration_test.sequences" */
  update_integration_test_sequences?: Maybe<Integration_Test_Sequences_Mutation_Response>;
  /** update single row of the table: "integration_test.sequences" */
  update_integration_test_sequences_by_pk?: Maybe<Integration_Test_Sequences>;
  /** update multiples rows of table: "integration_test.sequences" */
  update_integration_test_sequences_many?: Maybe<Array<Maybe<Integration_Test_Sequences_Mutation_Response>>>;
  /** update data of the table: "languages" */
  update_languages?: Maybe<Languages_Mutation_Response>;
  /** update single row of the table: "languages" */
  update_languages_by_pk?: Maybe<Languages>;
  /** update multiples rows of table: "languages" */
  update_languages_many?: Maybe<Array<Maybe<Languages_Mutation_Response>>>;
  /** update data of the table: "machine_learning.blobs" */
  update_machine_learning_blobs?: Maybe<Machine_Learning_Blobs_Mutation_Response>;
  /** update single row of the table: "machine_learning.blobs" */
  update_machine_learning_blobs_by_pk?: Maybe<Machine_Learning_Blobs>;
  /** update multiples rows of table: "machine_learning.blobs" */
  update_machine_learning_blobs_many?: Maybe<Array<Maybe<Machine_Learning_Blobs_Mutation_Response>>>;
  /** update data of the table: "machine_learning.dataset_feature" */
  update_machine_learning_dataset_feature?: Maybe<Machine_Learning_Dataset_Feature_Mutation_Response>;
  /** update single row of the table: "machine_learning.dataset_feature" */
  update_machine_learning_dataset_feature_by_pk?: Maybe<Machine_Learning_Dataset_Feature>;
  /** update multiples rows of table: "machine_learning.dataset_feature" */
  update_machine_learning_dataset_feature_many?: Maybe<Array<Maybe<Machine_Learning_Dataset_Feature_Mutation_Response>>>;
  /** update data of the table: "machine_learning.datasets" */
  update_machine_learning_datasets?: Maybe<Machine_Learning_Datasets_Mutation_Response>;
  /** update single row of the table: "machine_learning.datasets" */
  update_machine_learning_datasets_by_pk?: Maybe<Machine_Learning_Datasets>;
  /** update multiples rows of table: "machine_learning.datasets" */
  update_machine_learning_datasets_many?: Maybe<Array<Maybe<Machine_Learning_Datasets_Mutation_Response>>>;
  /** update data of the table: "machine_learning.features" */
  update_machine_learning_features?: Maybe<Machine_Learning_Features_Mutation_Response>;
  /** update single row of the table: "machine_learning.features" */
  update_machine_learning_features_by_pk?: Maybe<Machine_Learning_Features>;
  /** update multiples rows of table: "machine_learning.features" */
  update_machine_learning_features_many?: Maybe<Array<Maybe<Machine_Learning_Features_Mutation_Response>>>;
  /** update data of the table: "machine_learning.model_blob" */
  update_machine_learning_model_blob?: Maybe<Machine_Learning_Model_Blob_Mutation_Response>;
  /** update single row of the table: "machine_learning.model_blob" */
  update_machine_learning_model_blob_by_pk?: Maybe<Machine_Learning_Model_Blob>;
  /** update multiples rows of table: "machine_learning.model_blob" */
  update_machine_learning_model_blob_many?: Maybe<Array<Maybe<Machine_Learning_Model_Blob_Mutation_Response>>>;
  /** update data of the table: "machine_learning.model_dataset" */
  update_machine_learning_model_dataset?: Maybe<Machine_Learning_Model_Dataset_Mutation_Response>;
  /** update single row of the table: "machine_learning.model_dataset" */
  update_machine_learning_model_dataset_by_pk?: Maybe<Machine_Learning_Model_Dataset>;
  /** update multiples rows of table: "machine_learning.model_dataset" */
  update_machine_learning_model_dataset_many?: Maybe<Array<Maybe<Machine_Learning_Model_Dataset_Mutation_Response>>>;
  /** update data of the table: "machine_learning.model_recording" */
  update_machine_learning_model_recording?: Maybe<Machine_Learning_Model_Recording_Mutation_Response>;
  /** update single row of the table: "machine_learning.model_recording" */
  update_machine_learning_model_recording_by_pk?: Maybe<Machine_Learning_Model_Recording>;
  /** update multiples rows of table: "machine_learning.model_recording" */
  update_machine_learning_model_recording_many?: Maybe<Array<Maybe<Machine_Learning_Model_Recording_Mutation_Response>>>;
  /** update data of the table: "machine_learning.models" */
  update_machine_learning_models?: Maybe<Machine_Learning_Models_Mutation_Response>;
  /** update single row of the table: "machine_learning.models" */
  update_machine_learning_models_by_pk?: Maybe<Machine_Learning_Models>;
  /** update multiples rows of table: "machine_learning.models" */
  update_machine_learning_models_many?: Maybe<Array<Maybe<Machine_Learning_Models_Mutation_Response>>>;
  /** update data of the table: "media" */
  update_media?: Maybe<Media_Mutation_Response>;
  /** update single row of the table: "media" */
  update_media_by_pk?: Maybe<Media>;
  /** update multiples rows of table: "media" */
  update_media_many?: Maybe<Array<Maybe<Media_Mutation_Response>>>;
  /** update data of the table: "mode_project" */
  update_mode_project?: Maybe<Mode_Project_Mutation_Response>;
  /** update single row of the table: "mode_project" */
  update_mode_project_by_pk?: Maybe<Mode_Project>;
  /** update multiples rows of table: "mode_project" */
  update_mode_project_many?: Maybe<Array<Maybe<Mode_Project_Mutation_Response>>>;
  /** update data of the table: "model_name_hashes" */
  update_model_name_hashes?: Maybe<Model_Name_Hashes_Mutation_Response>;
  /** update single row of the table: "model_name_hashes" */
  update_model_name_hashes_by_pk?: Maybe<Model_Name_Hashes>;
  /** update multiples rows of table: "model_name_hashes" */
  update_model_name_hashes_many?: Maybe<Array<Maybe<Model_Name_Hashes_Mutation_Response>>>;
  /** update data of the table: "models" */
  update_models?: Maybe<Models_Mutation_Response>;
  /** update single row of the table: "models" */
  update_models_by_pk?: Maybe<Models>;
  /** update multiples rows of table: "models" */
  update_models_many?: Maybe<Array<Maybe<Models_Mutation_Response>>>;
  /** update data of the table: "opmode_profile" */
  update_opmode_profile?: Maybe<Opmode_Profile_Mutation_Response>;
  /** update single row of the table: "opmode_profile" */
  update_opmode_profile_by_pk?: Maybe<Opmode_Profile>;
  /** update multiples rows of table: "opmode_profile" */
  update_opmode_profile_many?: Maybe<Array<Maybe<Opmode_Profile_Mutation_Response>>>;
  /** update data of the table: "opmode_project" */
  update_opmode_project?: Maybe<Opmode_Project_Mutation_Response>;
  /** update single row of the table: "opmode_project" */
  update_opmode_project_by_pk?: Maybe<Opmode_Project>;
  /** update multiples rows of table: "opmode_project" */
  update_opmode_project_many?: Maybe<Array<Maybe<Opmode_Project_Mutation_Response>>>;
  /** update data of the table: "opmodes" */
  update_opmodes?: Maybe<Opmodes_Mutation_Response>;
  /** update single row of the table: "opmodes" */
  update_opmodes_by_pk?: Maybe<Opmodes>;
  /** update multiples rows of table: "opmodes" */
  update_opmodes_many?: Maybe<Array<Maybe<Opmodes_Mutation_Response>>>;
  /** update data of the table: "option_media" */
  update_option_media?: Maybe<Option_Media_Mutation_Response>;
  /** update single row of the table: "option_media" */
  update_option_media_by_pk?: Maybe<Option_Media>;
  /** update multiples rows of table: "option_media" */
  update_option_media_many?: Maybe<Array<Maybe<Option_Media_Mutation_Response>>>;
  /** update data of the table: "option_translation" */
  update_option_translation?: Maybe<Option_Translation_Mutation_Response>;
  /** update single row of the table: "option_translation" */
  update_option_translation_by_pk?: Maybe<Option_Translation>;
  /** update multiples rows of table: "option_translation" */
  update_option_translation_many?: Maybe<Array<Maybe<Option_Translation_Mutation_Response>>>;
  /** update data of the table: "options" */
  update_options?: Maybe<Options_Mutation_Response>;
  /** update single row of the table: "options" */
  update_options_by_pk?: Maybe<Options>;
  /** update multiples rows of table: "options" */
  update_options_many?: Maybe<Array<Maybe<Options_Mutation_Response>>>;
  /** update data of the table: "payload_recording" */
  update_payload_recording?: Maybe<Payload_Recording_Mutation_Response>;
  /** update single row of the table: "payload_recording" */
  update_payload_recording_by_pk?: Maybe<Payload_Recording>;
  /** update multiples rows of table: "payload_recording" */
  update_payload_recording_many?: Maybe<Array<Maybe<Payload_Recording_Mutation_Response>>>;
  /** update data of the table: "payload_types" */
  update_payload_types?: Maybe<Payload_Types_Mutation_Response>;
  /** update single row of the table: "payload_types" */
  update_payload_types_by_pk?: Maybe<Payload_Types>;
  /** update multiples rows of table: "payload_types" */
  update_payload_types_many?: Maybe<Array<Maybe<Payload_Types_Mutation_Response>>>;
  /** update data of the table: "payloads" */
  update_payloads?: Maybe<Payloads_Mutation_Response>;
  /** update single row of the table: "payloads" */
  update_payloads_by_pk?: Maybe<Payloads>;
  /** update multiples rows of table: "payloads" */
  update_payloads_many?: Maybe<Array<Maybe<Payloads_Mutation_Response>>>;
  /** update data of the table: "performance.ratio_metric_contributions" */
  update_performance_ratio_metric_contributions?: Maybe<Performance_Ratio_Metric_Contributions_Mutation_Response>;
  /** update single row of the table: "performance.ratio_metric_contributions" */
  update_performance_ratio_metric_contributions_by_pk?: Maybe<Performance_Ratio_Metric_Contributions>;
  /** update multiples rows of table: "performance.ratio_metric_contributions" */
  update_performance_ratio_metric_contributions_many?: Maybe<Array<Maybe<Performance_Ratio_Metric_Contributions_Mutation_Response>>>;
  /** update data of the table: "performance.ratio_metrics" */
  update_performance_ratio_metrics?: Maybe<Performance_Ratio_Metrics_Mutation_Response>;
  /** update single row of the table: "performance.ratio_metrics" */
  update_performance_ratio_metrics_by_pk?: Maybe<Performance_Ratio_Metrics>;
  /** update multiples rows of table: "performance.ratio_metrics" */
  update_performance_ratio_metrics_many?: Maybe<Array<Maybe<Performance_Ratio_Metrics_Mutation_Response>>>;
  /** update data of the table: "performance_report.deliverable_template" */
  update_performance_report_deliverable_template?: Maybe<Performance_Report_Deliverable_Template_Mutation_Response>;
  /** update single row of the table: "performance_report.deliverable_template" */
  update_performance_report_deliverable_template_by_pk?: Maybe<Performance_Report_Deliverable_Template>;
  /** update multiples rows of table: "performance_report.deliverable_template" */
  update_performance_report_deliverable_template_many?: Maybe<Array<Maybe<Performance_Report_Deliverable_Template_Mutation_Response>>>;
  /** update data of the table: "performance_report.pivot_types" */
  update_performance_report_pivot_types?: Maybe<Performance_Report_Pivot_Types_Mutation_Response>;
  /** update single row of the table: "performance_report.pivot_types" */
  update_performance_report_pivot_types_by_pk?: Maybe<Performance_Report_Pivot_Types>;
  /** update multiples rows of table: "performance_report.pivot_types" */
  update_performance_report_pivot_types_many?: Maybe<Array<Maybe<Performance_Report_Pivot_Types_Mutation_Response>>>;
  /** update data of the table: "performance_report.section_pivot_field" */
  update_performance_report_section_pivot_field?: Maybe<Performance_Report_Section_Pivot_Field_Mutation_Response>;
  /** update single row of the table: "performance_report.section_pivot_field" */
  update_performance_report_section_pivot_field_by_pk?: Maybe<Performance_Report_Section_Pivot_Field>;
  /** update multiples rows of table: "performance_report.section_pivot_field" */
  update_performance_report_section_pivot_field_many?: Maybe<Array<Maybe<Performance_Report_Section_Pivot_Field_Mutation_Response>>>;
  /** update data of the table: "performance_report.section_ratio_metric" */
  update_performance_report_section_ratio_metric?: Maybe<Performance_Report_Section_Ratio_Metric_Mutation_Response>;
  /** update single row of the table: "performance_report.section_ratio_metric" */
  update_performance_report_section_ratio_metric_by_pk?: Maybe<Performance_Report_Section_Ratio_Metric>;
  /** update multiples rows of table: "performance_report.section_ratio_metric" */
  update_performance_report_section_ratio_metric_many?: Maybe<Array<Maybe<Performance_Report_Section_Ratio_Metric_Mutation_Response>>>;
  /** update data of the table: "performance_report.section_task_blacklist" */
  update_performance_report_section_task_blacklist?: Maybe<Performance_Report_Section_Task_Blacklist_Mutation_Response>;
  /** update single row of the table: "performance_report.section_task_blacklist" */
  update_performance_report_section_task_blacklist_by_pk?: Maybe<Performance_Report_Section_Task_Blacklist>;
  /** update multiples rows of table: "performance_report.section_task_blacklist" */
  update_performance_report_section_task_blacklist_many?: Maybe<Array<Maybe<Performance_Report_Section_Task_Blacklist_Mutation_Response>>>;
  /** update data of the table: "performance_report.sections" */
  update_performance_report_sections?: Maybe<Performance_Report_Sections_Mutation_Response>;
  /** update single row of the table: "performance_report.sections" */
  update_performance_report_sections_by_pk?: Maybe<Performance_Report_Sections>;
  /** update multiples rows of table: "performance_report.sections" */
  update_performance_report_sections_many?: Maybe<Array<Maybe<Performance_Report_Sections_Mutation_Response>>>;
  /** update data of the table: "performance_report.templates" */
  update_performance_report_templates?: Maybe<Performance_Report_Templates_Mutation_Response>;
  /** update single row of the table: "performance_report.templates" */
  update_performance_report_templates_by_pk?: Maybe<Performance_Report_Templates>;
  /** update multiples rows of table: "performance_report.templates" */
  update_performance_report_templates_many?: Maybe<Array<Maybe<Performance_Report_Templates_Mutation_Response>>>;
  /** update data of the table: "peripherals" */
  update_peripherals?: Maybe<Peripherals_Mutation_Response>;
  /** update single row of the table: "peripherals" */
  update_peripherals_by_pk?: Maybe<Peripherals>;
  /** update multiples rows of table: "peripherals" */
  update_peripherals_many?: Maybe<Array<Maybe<Peripherals_Mutation_Response>>>;
  /** update data of the table: "plan_hypertasks" */
  update_plan_hypertasks?: Maybe<Plan_Hypertasks_Mutation_Response>;
  /** update single row of the table: "plan_hypertasks" */
  update_plan_hypertasks_by_pk?: Maybe<Plan_Hypertasks>;
  /** update multiples rows of table: "plan_hypertasks" */
  update_plan_hypertasks_many?: Maybe<Array<Maybe<Plan_Hypertasks_Mutation_Response>>>;
  /** update data of the table: "plan_tasks" */
  update_plan_tasks?: Maybe<Plan_Tasks_Mutation_Response>;
  /** update single row of the table: "plan_tasks" */
  update_plan_tasks_by_pk?: Maybe<Plan_Tasks>;
  /** update multiples rows of table: "plan_tasks" */
  update_plan_tasks_many?: Maybe<Array<Maybe<Plan_Tasks_Mutation_Response>>>;
  /** update data of the table: "plans" */
  update_plans?: Maybe<Plans_Mutation_Response>;
  /** update single row of the table: "plans" */
  update_plans_by_pk?: Maybe<Plans>;
  /** update multiples rows of table: "plans" */
  update_plans_many?: Maybe<Array<Maybe<Plans_Mutation_Response>>>;
  /** update data of the table: "profiles" */
  update_profiles?: Maybe<Profiles_Mutation_Response>;
  /** update single row of the table: "profiles" */
  update_profiles_by_pk?: Maybe<Profiles>;
  /** update multiples rows of table: "profiles" */
  update_profiles_many?: Maybe<Array<Maybe<Profiles_Mutation_Response>>>;
  /** update data of the table: "project_combo" */
  update_project_combo?: Maybe<Project_Combo_Mutation_Response>;
  /** update single row of the table: "project_combo" */
  update_project_combo_by_pk?: Maybe<Project_Combo>;
  /** update multiples rows of table: "project_combo" */
  update_project_combo_many?: Maybe<Array<Maybe<Project_Combo_Mutation_Response>>>;
  /** update data of the table: "project_hypertasks" */
  update_project_hypertasks?: Maybe<Project_Hypertasks_Mutation_Response>;
  /** update single row of the table: "project_hypertasks" */
  update_project_hypertasks_by_pk?: Maybe<Project_Hypertasks>;
  /** update multiples rows of table: "project_hypertasks" */
  update_project_hypertasks_many?: Maybe<Array<Maybe<Project_Hypertasks_Mutation_Response>>>;
  /** update data of the table: "project_tasks" */
  update_project_tasks?: Maybe<Project_Tasks_Mutation_Response>;
  /** update single row of the table: "project_tasks" */
  update_project_tasks_by_pk?: Maybe<Project_Tasks>;
  /** update multiples rows of table: "project_tasks" */
  update_project_tasks_many?: Maybe<Array<Maybe<Project_Tasks_Mutation_Response>>>;
  /** update data of the table: "project_user" */
  update_project_user?: Maybe<Project_User_Mutation_Response>;
  /** update single row of the table: "project_user" */
  update_project_user_by_pk?: Maybe<Project_User>;
  /** update multiples rows of table: "project_user" */
  update_project_user_many?: Maybe<Array<Maybe<Project_User_Mutation_Response>>>;
  /** update data of the table: "projects" */
  update_projects?: Maybe<Projects_Mutation_Response>;
  /** update single row of the table: "projects" */
  update_projects_by_pk?: Maybe<Projects>;
  /** update multiples rows of table: "projects" */
  update_projects_many?: Maybe<Array<Maybe<Projects_Mutation_Response>>>;
  /** update data of the table: "rabbit_test.plan_project" */
  update_rabbit_test_plan_project?: Maybe<Rabbit_Test_Plan_Project_Mutation_Response>;
  /** update single row of the table: "rabbit_test.plan_project" */
  update_rabbit_test_plan_project_by_pk?: Maybe<Rabbit_Test_Plan_Project>;
  /** update multiples rows of table: "rabbit_test.plan_project" */
  update_rabbit_test_plan_project_many?: Maybe<Array<Maybe<Rabbit_Test_Plan_Project_Mutation_Response>>>;
  /** update data of the table: "rabbit_test.plan_recording" */
  update_rabbit_test_plan_recording?: Maybe<Rabbit_Test_Plan_Recording_Mutation_Response>;
  /** update single row of the table: "rabbit_test.plan_recording" */
  update_rabbit_test_plan_recording_by_pk?: Maybe<Rabbit_Test_Plan_Recording>;
  /** update multiples rows of table: "rabbit_test.plan_recording" */
  update_rabbit_test_plan_recording_many?: Maybe<Array<Maybe<Rabbit_Test_Plan_Recording_Mutation_Response>>>;
  /** update data of the table: "rabbit_test.plan_task" */
  update_rabbit_test_plan_task?: Maybe<Rabbit_Test_Plan_Task_Mutation_Response>;
  /** update single row of the table: "rabbit_test.plan_task" */
  update_rabbit_test_plan_task_by_pk?: Maybe<Rabbit_Test_Plan_Task>;
  /** update multiples rows of table: "rabbit_test.plan_task" */
  update_rabbit_test_plan_task_many?: Maybe<Array<Maybe<Rabbit_Test_Plan_Task_Mutation_Response>>>;
  /** update data of the table: "rabbit_test.plans" */
  update_rabbit_test_plans?: Maybe<Rabbit_Test_Plans_Mutation_Response>;
  /** update single row of the table: "rabbit_test.plans" */
  update_rabbit_test_plans_by_pk?: Maybe<Rabbit_Test_Plans>;
  /** update multiples rows of table: "rabbit_test.plans" */
  update_rabbit_test_plans_many?: Maybe<Array<Maybe<Rabbit_Test_Plans_Mutation_Response>>>;
  /** update data of the table: "recording_apps" */
  update_recording_apps?: Maybe<Recording_Apps_Mutation_Response>;
  /** update single row of the table: "recording_apps" */
  update_recording_apps_by_pk?: Maybe<Recording_Apps>;
  /** update multiples rows of table: "recording_apps" */
  update_recording_apps_many?: Maybe<Array<Maybe<Recording_Apps_Mutation_Response>>>;
  /** update data of the table: "recording_audio_driver" */
  update_recording_audio_driver?: Maybe<Recording_Audio_Driver_Mutation_Response>;
  /** update single row of the table: "recording_audio_driver" */
  update_recording_audio_driver_by_pk?: Maybe<Recording_Audio_Driver>;
  /** update multiples rows of table: "recording_audio_driver" */
  update_recording_audio_driver_many?: Maybe<Array<Maybe<Recording_Audio_Driver_Mutation_Response>>>;
  /** update data of the table: "recording_data.ground_truth" */
  update_recording_data_ground_truth?: Maybe<Recording_Data_Ground_Truth_Mutation_Response>;
  /** update single row of the table: "recording_data.ground_truth" */
  update_recording_data_ground_truth_by_pk?: Maybe<Recording_Data_Ground_Truth>;
  /** update multiples rows of table: "recording_data.ground_truth" */
  update_recording_data_ground_truth_many?: Maybe<Array<Maybe<Recording_Data_Ground_Truth_Mutation_Response>>>;
  /** update data of the table: "recording_data.labels" */
  update_recording_data_labels?: Maybe<Recording_Data_Labels_Mutation_Response>;
  /** update single row of the table: "recording_data.labels" */
  update_recording_data_labels_by_pk?: Maybe<Recording_Data_Labels>;
  /** update multiples rows of table: "recording_data.labels" */
  update_recording_data_labels_many?: Maybe<Array<Maybe<Recording_Data_Labels_Mutation_Response>>>;
  /** update data of the table: "recording_data.mode_parameter" */
  update_recording_data_mode_parameter?: Maybe<Recording_Data_Mode_Parameter_Mutation_Response>;
  /** update single row of the table: "recording_data.mode_parameter" */
  update_recording_data_mode_parameter_by_pk?: Maybe<Recording_Data_Mode_Parameter>;
  /** update multiples rows of table: "recording_data.mode_parameter" */
  update_recording_data_mode_parameter_many?: Maybe<Array<Maybe<Recording_Data_Mode_Parameter_Mutation_Response>>>;
  /** update data of the table: "recording_data.modes" */
  update_recording_data_modes?: Maybe<Recording_Data_Modes_Mutation_Response>;
  /** update single row of the table: "recording_data.modes" */
  update_recording_data_modes_by_pk?: Maybe<Recording_Data_Modes>;
  /** update multiples rows of table: "recording_data.modes" */
  update_recording_data_modes_many?: Maybe<Array<Maybe<Recording_Data_Modes_Mutation_Response>>>;
  /** update data of the table: "recording_data.parameters" */
  update_recording_data_parameters?: Maybe<Recording_Data_Parameters_Mutation_Response>;
  /** update single row of the table: "recording_data.parameters" */
  update_recording_data_parameters_by_pk?: Maybe<Recording_Data_Parameters>;
  /** update multiples rows of table: "recording_data.parameters" */
  update_recording_data_parameters_many?: Maybe<Array<Maybe<Recording_Data_Parameters_Mutation_Response>>>;
  /** update data of the table: "recording_data.payload_pair" */
  update_recording_data_payload_pair?: Maybe<Recording_Data_Payload_Pair_Mutation_Response>;
  /** update single row of the table: "recording_data.payload_pair" */
  update_recording_data_payload_pair_by_pk?: Maybe<Recording_Data_Payload_Pair>;
  /** update multiples rows of table: "recording_data.payload_pair" */
  update_recording_data_payload_pair_many?: Maybe<Array<Maybe<Recording_Data_Payload_Pair_Mutation_Response>>>;
  /** update data of the table: "recording_data.predictions" */
  update_recording_data_predictions?: Maybe<Recording_Data_Predictions_Mutation_Response>;
  /** update single row of the table: "recording_data.predictions" */
  update_recording_data_predictions_by_pk?: Maybe<Recording_Data_Predictions>;
  /** update multiples rows of table: "recording_data.predictions" */
  update_recording_data_predictions_many?: Maybe<Array<Maybe<Recording_Data_Predictions_Mutation_Response>>>;
  /** update data of the table: "recording_recording_tag" */
  update_recording_recording_tag?: Maybe<Recording_Recording_Tag_Mutation_Response>;
  /** update single row of the table: "recording_recording_tag" */
  update_recording_recording_tag_by_pk?: Maybe<Recording_Recording_Tag>;
  /** update multiples rows of table: "recording_recording_tag" */
  update_recording_recording_tag_many?: Maybe<Array<Maybe<Recording_Recording_Tag_Mutation_Response>>>;
  /** update data of the table: "recording_tags" */
  update_recording_tags?: Maybe<Recording_Tags_Mutation_Response>;
  /** update single row of the table: "recording_tags" */
  update_recording_tags_by_pk?: Maybe<Recording_Tags>;
  /** update multiples rows of table: "recording_tags" */
  update_recording_tags_many?: Maybe<Array<Maybe<Recording_Tags_Mutation_Response>>>;
  /** update data of the table: "recordings" */
  update_recordings?: Maybe<Recordings_Mutation_Response>;
  /** update single row of the table: "recordings" */
  update_recordings_by_pk?: Maybe<Recordings>;
  /** update multiples rows of table: "recordings" */
  update_recordings_many?: Maybe<Array<Maybe<Recordings_Mutation_Response>>>;
  /** update data of the table: "role_user" */
  update_role_user?: Maybe<Role_User_Mutation_Response>;
  /** update single row of the table: "role_user" */
  update_role_user_by_pk?: Maybe<Role_User>;
  /** update multiples rows of table: "role_user" */
  update_role_user_many?: Maybe<Array<Maybe<Role_User_Mutation_Response>>>;
  /** update data of the table: "roles" */
  update_roles?: Maybe<Roles_Mutation_Response>;
  /** update single row of the table: "roles" */
  update_roles_by_pk?: Maybe<Roles>;
  /** update multiples rows of table: "roles" */
  update_roles_many?: Maybe<Array<Maybe<Roles_Mutation_Response>>>;
  /** update data of the table: "rooms" */
  update_rooms?: Maybe<Rooms_Mutation_Response>;
  /** update single row of the table: "rooms" */
  update_rooms_by_pk?: Maybe<Rooms>;
  /** update multiples rows of table: "rooms" */
  update_rooms_many?: Maybe<Array<Maybe<Rooms_Mutation_Response>>>;
  /** update data of the table: "simulations.scores" */
  update_simulations_scores?: Maybe<Simulations_Scores_Mutation_Response>;
  /** update single row of the table: "simulations.scores" */
  update_simulations_scores_by_pk?: Maybe<Simulations_Scores>;
  /** update multiples rows of table: "simulations.scores" */
  update_simulations_scores_many?: Maybe<Array<Maybe<Simulations_Scores_Mutation_Response>>>;
  /** update data of the table: "task_field_option" */
  update_task_field_option?: Maybe<Task_Field_Option_Mutation_Response>;
  /** update single row of the table: "task_field_option" */
  update_task_field_option_by_pk?: Maybe<Task_Field_Option>;
  /** update multiples rows of table: "task_field_option" */
  update_task_field_option_many?: Maybe<Array<Maybe<Task_Field_Option_Mutation_Response>>>;
  /** update data of the table: "task_groups.group_fields" */
  update_task_groups_group_fields?: Maybe<Task_Groups_Group_Fields_Mutation_Response>;
  /** update single row of the table: "task_groups.group_fields" */
  update_task_groups_group_fields_by_pk?: Maybe<Task_Groups_Group_Fields>;
  /** update multiples rows of table: "task_groups.group_fields" */
  update_task_groups_group_fields_many?: Maybe<Array<Maybe<Task_Groups_Group_Fields_Mutation_Response>>>;
  /** update data of the table: "task_groups.groups" */
  update_task_groups_groups?: Maybe<Task_Groups_Groups_Mutation_Response>;
  /** update single row of the table: "task_groups.groups" */
  update_task_groups_groups_by_pk?: Maybe<Task_Groups_Groups>;
  /** update multiples rows of table: "task_groups.groups" */
  update_task_groups_groups_many?: Maybe<Array<Maybe<Task_Groups_Groups_Mutation_Response>>>;
  /** update data of the table: "task_groups.hierarchies" */
  update_task_groups_hierarchies?: Maybe<Task_Groups_Hierarchies_Mutation_Response>;
  /** update single row of the table: "task_groups.hierarchies" */
  update_task_groups_hierarchies_by_pk?: Maybe<Task_Groups_Hierarchies>;
  /** update multiples rows of table: "task_groups.hierarchies" */
  update_task_groups_hierarchies_many?: Maybe<Array<Maybe<Task_Groups_Hierarchies_Mutation_Response>>>;
  /** update data of the table: "task_groups.hierarchy_groups" */
  update_task_groups_hierarchy_groups?: Maybe<Task_Groups_Hierarchy_Groups_Mutation_Response>;
  /** update single row of the table: "task_groups.hierarchy_groups" */
  update_task_groups_hierarchy_groups_by_pk?: Maybe<Task_Groups_Hierarchy_Groups>;
  /** update multiples rows of table: "task_groups.hierarchy_groups" */
  update_task_groups_hierarchy_groups_many?: Maybe<Array<Maybe<Task_Groups_Hierarchy_Groups_Mutation_Response>>>;
  /** update data of the table: "task_instructions" */
  update_task_instructions?: Maybe<Task_Instructions_Mutation_Response>;
  /** update single row of the table: "task_instructions" */
  update_task_instructions_by_pk?: Maybe<Task_Instructions>;
  /** update multiples rows of table: "task_instructions" */
  update_task_instructions_many?: Maybe<Array<Maybe<Task_Instructions_Mutation_Response>>>;
  /** update data of the table: "task_media" */
  update_task_media?: Maybe<Task_Media_Mutation_Response>;
  /** update single row of the table: "task_media" */
  update_task_media_by_pk?: Maybe<Task_Media>;
  /** update multiples rows of table: "task_media" */
  update_task_media_many?: Maybe<Array<Maybe<Task_Media_Mutation_Response>>>;
  /** update data of the table: "task_translation" */
  update_task_translation?: Maybe<Task_Translation_Mutation_Response>;
  /** update single row of the table: "task_translation" */
  update_task_translation_by_pk?: Maybe<Task_Translation>;
  /** update multiples rows of table: "task_translation" */
  update_task_translation_many?: Maybe<Array<Maybe<Task_Translation_Mutation_Response>>>;
  /** update data of the table: "tasks" */
  update_tasks?: Maybe<Tasks_Mutation_Response>;
  /** update single row of the table: "tasks" */
  update_tasks_by_pk?: Maybe<Tasks>;
  /** update multiples rows of table: "tasks" */
  update_tasks_many?: Maybe<Array<Maybe<Tasks_Mutation_Response>>>;
  /** update data of the table: "tool_versions" */
  update_tool_versions?: Maybe<Tool_Versions_Mutation_Response>;
  /** update single row of the table: "tool_versions" */
  update_tool_versions_by_pk?: Maybe<Tool_Versions>;
  /** update multiples rows of table: "tool_versions" */
  update_tool_versions_many?: Maybe<Array<Maybe<Tool_Versions_Mutation_Response>>>;
  /** update data of the table: "tools" */
  update_tools?: Maybe<Tools_Mutation_Response>;
  /** update single row of the table: "tools" */
  update_tools_by_pk?: Maybe<Tools>;
  /** update multiples rows of table: "tools" */
  update_tools_many?: Maybe<Array<Maybe<Tools_Mutation_Response>>>;
  /** update data of the table: "types" */
  update_types?: Maybe<Types_Mutation_Response>;
  /** update single row of the table: "types" */
  update_types_by_pk?: Maybe<Types>;
  /** update multiples rows of table: "types" */
  update_types_many?: Maybe<Array<Maybe<Types_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
  /** update data of the table: "xtensa_configurations" */
  update_xtensa_configurations?: Maybe<Xtensa_Configurations_Mutation_Response>;
  /** update single row of the table: "xtensa_configurations" */
  update_xtensa_configurations_by_pk?: Maybe<Xtensa_Configurations>;
  /** update multiples rows of table: "xtensa_configurations" */
  update_xtensa_configurations_many?: Maybe<Array<Maybe<Xtensa_Configurations_Mutation_Response>>>;
  /** execute VOLATILE function "upsert_task" which returns "schema.upsert_task" */
  upsert_task: Array<Schema_Upsert_Task>;
};


/** mutation root */
export type Mutation_RootDelete_Audio_DriverArgs = {
  where: Audio_Driver_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Audio_Driver_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Audio_Measurements_ComponentsArgs = {
  where: Audio_Measurements_Components_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Audio_Measurements_Components_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Audio_Measurements_ConfigurationsArgs = {
  where: Audio_Measurements_Configurations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Audio_Measurements_Configurations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Audio_Measurements_Master_FilesArgs = {
  where: Audio_Measurements_Master_Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Audio_Measurements_Master_Files_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Audio_Measurements_OrientationsArgs = {
  where: Audio_Measurements_Orientations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Audio_Measurements_Orientations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Audio_Measurements_Payload_RecordingArgs = {
  where: Audio_Measurements_Payload_Recording_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Audio_Measurements_Payload_Recording_By_PkArgs = {
  payload_id: Scalars['uuid'];
  recording_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Audio_Measurements_PlacementsArgs = {
  where: Audio_Measurements_Placements_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Audio_Measurements_Placements_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Audio_Measurements_Project_TasksArgs = {
  where: Audio_Measurements_Project_Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Audio_Measurements_Project_Tasks_By_PkArgs = {
  project_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Audio_Measurements_StagesArgs = {
  where: Audio_Measurements_Stages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Audio_Measurements_Stages_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Bringup_Checklist_DeliverableArgs = {
  where: Bringup_Checklist_Deliverable_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bringup_Checklist_Deliverable_By_PkArgs = {
  checklist_id: Scalars['uuid'];
  deliverable_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Bringup_ChecklistsArgs = {
  where: Bringup_Checklists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bringup_Checklists_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Bringup_EndorsementsArgs = {
  where: Bringup_Endorsements_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bringup_Endorsements_By_PkArgs = {
  item_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Bringup_ItemsArgs = {
  where: Bringup_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bringup_Items_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Bringup_MilestonesArgs = {
  where: Bringup_Milestones_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bringup_Milestones_By_PkArgs = {
  item_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Bringup_Project_Item_CommentsArgs = {
  where: Bringup_Project_Item_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bringup_Project_Item_Comments_By_PkArgs = {
  created_at: Scalars['timestamptz'];
  item_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CategoriesArgs = {
  where: Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Categories_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Context_Display_FieldArgs = {
  where: Context_Display_Field_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Context_Display_Field_By_PkArgs = {
  context_display_id: Scalars['uuid'];
  field_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Context_DisplaysArgs = {
  where: Context_Displays_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Context_Displays_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ContextsArgs = {
  where: Contexts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contexts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CustomersArgs = {
  where: Customers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Customers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_DeliverablesArgs = {
  where: Deliverables_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Deliverables_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_ArchitecturesArgs = {
  where: Deliveries_Architectures_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_Architectures_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_Artifact_Microsoft_Whql_SubmissionArgs = {
  where: Deliveries_Artifact_Microsoft_Whql_Submission_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_Artifact_Microsoft_Whql_Submission_By_PkArgs = {
  artifact_id: Scalars['uuid'];
  microsoft_whql_submission_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_Artifact_Operating_SystemArgs = {
  where: Deliveries_Artifact_Operating_System_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_Artifact_Operating_System_By_PkArgs = {
  artifact_id: Scalars['uuid'];
  os_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_Driver_PackageArgs = {
  where: Deliveries_Driver_Package_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_Driver_Package_By_PkArgs = {
  driver_id: Scalars['uuid'];
  package_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_Driver_ProjectArgs = {
  where: Deliveries_Driver_Project_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_Driver_Project_By_PkArgs = {
  driver_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_Driver_Version_EngineArgs = {
  where: Deliveries_Driver_Version_Engine_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_Driver_Version_Engine_By_PkArgs = {
  driver_id: Scalars['uuid'];
  driver_version_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_Driver_Version_PackageArgs = {
  where: Deliveries_Driver_Version_Package_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_Driver_Version_Package_By_PkArgs = {
  driver_version_id: Scalars['uuid'];
  package_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_Driver_VersionsArgs = {
  where: Deliveries_Driver_Versions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_Driver_Versions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_DriversArgs = {
  where: Deliveries_Drivers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_Drivers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_Microsoft_Shipping_LabelsArgs = {
  where: Deliveries_Microsoft_Shipping_Labels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_Microsoft_Shipping_Labels_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_Microsoft_Whql_SubmissionsArgs = {
  where: Deliveries_Microsoft_Whql_Submissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_Microsoft_Whql_Submissions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_Package_TargetArgs = {
  where: Deliveries_Package_Target_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_Package_Target_By_PkArgs = {
  package_id: Scalars['uuid'];
  target_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_PackagesArgs = {
  where: Deliveries_Packages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_Packages_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_PartnersArgs = {
  where: Deliveries_Partners_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_Partners_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_PlatformsArgs = {
  where: Deliveries_Platforms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_Platforms_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_Target_PartnerArgs = {
  where: Deliveries_Target_Partner_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_Target_Partner_By_PkArgs = {
  partner_id: Scalars['uuid'];
  target_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_TargetsArgs = {
  where: Deliveries_Targets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_Targets_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Device_FirmwareArgs = {
  where: Device_Firmware_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Device_Firmware_By_PkArgs = {
  device_id: Scalars['uuid'];
  firmware_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Device_ProjectArgs = {
  where: Device_Project_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Device_Project_By_PkArgs = {
  device_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_DevicesArgs = {
  where: Devices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Devices_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dynamic_ScalingArgs = {
  where: Dynamic_Scaling_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dynamic_Scaling_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Engine_OpmodeArgs = {
  where: Engine_Opmode_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Engine_Opmode_By_PkArgs = {
  engine_id: Scalars['uuid'];
  opmode_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Engine_Opmode_RecordingArgs = {
  where: Engine_Opmode_Recording_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Engine_Opmode_Recording_By_PkArgs = {
  engine_id: Scalars['uuid'];
  opmode_id: Scalars['uuid'];
  recording_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_EnginesArgs = {
  where: Engines_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Engines_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Field_Option_MediaArgs = {
  where: Field_Option_Media_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Field_Option_Media_By_PkArgs = {
  field_option_id: Scalars['uuid'];
  media_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Field_OptionsArgs = {
  where: Field_Options_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Field_Options_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Field_TranslationArgs = {
  where: Field_Translation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Field_Translation_By_PkArgs = {
  field_id: Scalars['uuid'];
  language: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_FieldsArgs = {
  where: Fields_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Fields_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_FirmwareArgs = {
  where: Firmware_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Firmware_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Ground_Truth_ConfigurationsArgs = {
  where: Ground_Truth_Configurations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ground_Truth_Configurations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Ground_Truth_RecordingsArgs = {
  where: Ground_Truth_Recordings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ground_Truth_Recordings_By_PkArgs = {
  recording_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Hypertask_Field_OptionArgs = {
  where: Hypertask_Field_Option_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Hypertask_Field_Option_By_PkArgs = {
  field_id: Scalars['uuid'];
  hypertask_id: Scalars['uuid'];
  option_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Hypertask_TaskArgs = {
  where: Hypertask_Task_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Hypertask_Task_By_PkArgs = {
  hypertask_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_HypertasksArgs = {
  where: Hypertasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Hypertasks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Instruction_TranslationsArgs = {
  where: Instruction_Translations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Instruction_Translations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Integration_Test_Expectation_MediaArgs = {
  where: Integration_Test_Expectation_Media_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Integration_Test_Expectation_Media_By_PkArgs = {
  expectation_id: Scalars['uuid'];
  media_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Integration_Test_Expectation_StepArgs = {
  where: Integration_Test_Expectation_Step_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Integration_Test_Expectation_Step_By_PkArgs = {
  expectation_id: Scalars['uuid'];
  step_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Integration_Test_Expectation_TranslationArgs = {
  where: Integration_Test_Expectation_Translation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Integration_Test_Expectation_Translation_By_PkArgs = {
  expectation_id: Scalars['uuid'];
  language: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Integration_Test_ExpectationsArgs = {
  where: Integration_Test_Expectations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Integration_Test_Expectations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Integration_Test_Instruction_MediaArgs = {
  where: Integration_Test_Instruction_Media_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Integration_Test_Instruction_Media_By_PkArgs = {
  instruction_id: Scalars['uuid'];
  media_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Integration_Test_Instruction_TranslationArgs = {
  where: Integration_Test_Instruction_Translation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Integration_Test_Instruction_Translation_By_PkArgs = {
  instruction_id: Scalars['uuid'];
  language: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Integration_Test_InstructionsArgs = {
  where: Integration_Test_Instructions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Integration_Test_Instructions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Integration_Test_ObservancesArgs = {
  where: Integration_Test_Observances_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Integration_Test_Observances_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Integration_Test_Project_SequenceArgs = {
  where: Integration_Test_Project_Sequence_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Integration_Test_Project_Sequence_By_PkArgs = {
  project_id: Scalars['uuid'];
  sequence_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Integration_Test_SequencesArgs = {
  where: Integration_Test_Sequences_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Integration_Test_Sequences_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Integration_Test_StepsArgs = {
  where: Integration_Test_Steps_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Integration_Test_Steps_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Machine_Learning_BlobsArgs = {
  where: Machine_Learning_Blobs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Machine_Learning_Blobs_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Machine_Learning_Dataset_FeatureArgs = {
  where: Machine_Learning_Dataset_Feature_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Machine_Learning_Dataset_Feature_By_PkArgs = {
  dataset_id: Scalars['uuid'];
  feature_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Machine_Learning_DatasetsArgs = {
  where: Machine_Learning_Datasets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Machine_Learning_Datasets_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Machine_Learning_FeaturesArgs = {
  where: Machine_Learning_Features_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Machine_Learning_Features_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Machine_Learning_Model_BlobArgs = {
  where: Machine_Learning_Model_Blob_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Machine_Learning_Model_Blob_By_PkArgs = {
  blob_id: Scalars['uuid'];
  model_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Machine_Learning_Model_DatasetArgs = {
  where: Machine_Learning_Model_Dataset_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Machine_Learning_Model_Dataset_By_PkArgs = {
  dataset_id: Scalars['uuid'];
  model_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Machine_Learning_Model_RecordingArgs = {
  where: Machine_Learning_Model_Recording_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Machine_Learning_Model_Recording_By_PkArgs = {
  model_id: Scalars['uuid'];
  recording_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Machine_Learning_ModelsArgs = {
  where: Machine_Learning_Models_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Machine_Learning_Models_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_MediaArgs = {
  where: Media_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Media_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Mode_ProjectArgs = {
  where: Mode_Project_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mode_Project_By_PkArgs = {
  mode_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Model_Name_HashesArgs = {
  where: Model_Name_Hashes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Model_Name_Hashes_By_PkArgs = {
  hash: Scalars['String'];
  model_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ModelsArgs = {
  where: Models_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Models_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Opmode_ProfileArgs = {
  where: Opmode_Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Opmode_Profile_By_PkArgs = {
  opmode_id: Scalars['uuid'];
  profile_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Opmode_ProjectArgs = {
  where: Opmode_Project_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Opmode_Project_By_PkArgs = {
  opmode_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_OpmodesArgs = {
  where: Opmodes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Opmodes_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Option_MediaArgs = {
  where: Option_Media_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Option_Media_By_PkArgs = {
  media_id: Scalars['uuid'];
  option_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Option_TranslationArgs = {
  where: Option_Translation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Option_Translation_By_PkArgs = {
  language: Scalars['String'];
  option_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_OptionsArgs = {
  where: Options_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Options_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Payload_RecordingArgs = {
  where: Payload_Recording_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payload_Recording_By_PkArgs = {
  payload_id: Scalars['uuid'];
  recording_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PayloadsArgs = {
  where: Payloads_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payloads_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Performance_Ratio_Metric_ContributionsArgs = {
  where: Performance_Ratio_Metric_Contributions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Performance_Ratio_Metric_Contributions_By_PkArgs = {
  engine_id: Scalars['uuid'];
  ratio_metric_id: Scalars['uuid'];
  recording_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Performance_Report_Deliverable_TemplateArgs = {
  where: Performance_Report_Deliverable_Template_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Performance_Report_Deliverable_Template_By_PkArgs = {
  deliverable_id: Scalars['uuid'];
  template_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Performance_Report_Section_Pivot_FieldArgs = {
  where: Performance_Report_Section_Pivot_Field_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Performance_Report_Section_Pivot_Field_By_PkArgs = {
  field_id: Scalars['uuid'];
  section_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Performance_Report_Section_Ratio_MetricArgs = {
  where: Performance_Report_Section_Ratio_Metric_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Performance_Report_Section_Ratio_Metric_By_PkArgs = {
  ratio_metric_id: Scalars['uuid'];
  section_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Performance_Report_Section_Task_BlacklistArgs = {
  where: Performance_Report_Section_Task_Blacklist_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Performance_Report_Section_Task_Blacklist_By_PkArgs = {
  section_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Performance_Report_SectionsArgs = {
  where: Performance_Report_Sections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Performance_Report_Sections_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Performance_Report_TemplatesArgs = {
  where: Performance_Report_Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Performance_Report_Templates_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PeripheralsArgs = {
  where: Peripherals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Peripherals_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Plan_HypertasksArgs = {
  where: Plan_Hypertasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Plan_Hypertasks_By_PkArgs = {
  hypertask_id: Scalars['uuid'];
  plan_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Plan_TasksArgs = {
  where: Plan_Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Plan_Tasks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PlansArgs = {
  where: Plans_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Plans_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ProfilesArgs = {
  where: Profiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profiles_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Project_ComboArgs = {
  where: Project_Combo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Project_Combo_By_PkArgs = {
  other_project_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Project_HypertasksArgs = {
  where: Project_Hypertasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Project_Hypertasks_By_PkArgs = {
  hypertask_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Project_TasksArgs = {
  where: Project_Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Project_Tasks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Project_UserArgs = {
  where: Project_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Project_User_By_PkArgs = {
  project_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ProjectsArgs = {
  where: Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Projects_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Rabbit_Test_Plan_ProjectArgs = {
  where: Rabbit_Test_Plan_Project_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rabbit_Test_Plan_Project_By_PkArgs = {
  plan_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Rabbit_Test_Plan_RecordingArgs = {
  where: Rabbit_Test_Plan_Recording_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rabbit_Test_Plan_Recording_By_PkArgs = {
  recording_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Rabbit_Test_Plan_TaskArgs = {
  where: Rabbit_Test_Plan_Task_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rabbit_Test_Plan_Task_By_PkArgs = {
  plan_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Rabbit_Test_PlansArgs = {
  where: Rabbit_Test_Plans_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rabbit_Test_Plans_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Recording_AppsArgs = {
  where: Recording_Apps_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recording_Apps_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Recording_Audio_DriverArgs = {
  where: Recording_Audio_Driver_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recording_Audio_Driver_By_PkArgs = {
  audio_driver_id: Scalars['uuid'];
  recording_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Recording_Data_Ground_TruthArgs = {
  where: Recording_Data_Ground_Truth_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recording_Data_Ground_Truth_By_PkArgs = {
  recording_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Recording_Data_LabelsArgs = {
  where: Recording_Data_Labels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recording_Data_Labels_By_PkArgs = {
  mode_id: Scalars['uuid'];
  recording_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Recording_Data_Mode_ParameterArgs = {
  where: Recording_Data_Mode_Parameter_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recording_Data_Mode_Parameter_By_PkArgs = {
  mode_id: Scalars['uuid'];
  parameter_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Recording_Data_ModesArgs = {
  where: Recording_Data_Modes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recording_Data_Modes_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Recording_Data_ParametersArgs = {
  where: Recording_Data_Parameters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recording_Data_Parameters_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Recording_Data_Payload_PairArgs = {
  where: Recording_Data_Payload_Pair_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recording_Data_Payload_Pair_By_PkArgs = {
  payload_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Recording_Data_PredictionsArgs = {
  where: Recording_Data_Predictions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recording_Data_Predictions_By_PkArgs = {
  engine_id: Scalars['uuid'];
  recording_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Recording_Recording_TagArgs = {
  where: Recording_Recording_Tag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recording_Recording_Tag_By_PkArgs = {
  recording_id: Scalars['uuid'];
  recording_tag_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Recording_TagsArgs = {
  where: Recording_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recording_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_RecordingsArgs = {
  where: Recordings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recordings_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Role_UserArgs = {
  where: Role_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Role_User_By_PkArgs = {
  role_id: Roles_Enum;
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_RoomsArgs = {
  where: Rooms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rooms_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Simulations_ScoresArgs = {
  where: Simulations_Scores_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Simulations_Scores_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Task_Field_OptionArgs = {
  where: Task_Field_Option_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Task_Field_Option_By_PkArgs = {
  field_option_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Task_Groups_Group_FieldsArgs = {
  where: Task_Groups_Group_Fields_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Task_Groups_Group_Fields_By_PkArgs = {
  field_id: Scalars['uuid'];
  group_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Task_Groups_GroupsArgs = {
  where: Task_Groups_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Task_Groups_Groups_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Task_Groups_HierarchiesArgs = {
  where: Task_Groups_Hierarchies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Task_Groups_Hierarchies_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Task_Groups_Hierarchy_GroupsArgs = {
  where: Task_Groups_Hierarchy_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Task_Groups_Hierarchy_Groups_By_PkArgs = {
  group_id: Scalars['uuid'];
  hierarchy_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Task_InstructionsArgs = {
  where: Task_Instructions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Task_Instructions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Task_MediaArgs = {
  where: Task_Media_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Task_Media_By_PkArgs = {
  media_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Task_TranslationArgs = {
  where: Task_Translation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Task_Translation_By_PkArgs = {
  language: Scalars['String'];
  task_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TasksArgs = {
  where: Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tasks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Tool_VersionsArgs = {
  where: Tool_Versions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tool_Versions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ToolsArgs = {
  where: Tools_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tools_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TypesArgs = {
  where: Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Types_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Xtensa_ConfigurationsArgs = {
  where: Xtensa_Configurations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Xtensa_Configurations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootInsert_Audio_DriverArgs = {
  objects: Array<Audio_Driver_Insert_Input>;
  on_conflict?: Maybe<Audio_Driver_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Audio_Driver_OneArgs = {
  object: Audio_Driver_Insert_Input;
  on_conflict?: Maybe<Audio_Driver_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Audio_Measurements_ComponentsArgs = {
  objects: Array<Audio_Measurements_Components_Insert_Input>;
  on_conflict?: Maybe<Audio_Measurements_Components_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Audio_Measurements_Components_OneArgs = {
  object: Audio_Measurements_Components_Insert_Input;
  on_conflict?: Maybe<Audio_Measurements_Components_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Audio_Measurements_ConfigurationsArgs = {
  objects: Array<Audio_Measurements_Configurations_Insert_Input>;
  on_conflict?: Maybe<Audio_Measurements_Configurations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Audio_Measurements_Configurations_OneArgs = {
  object: Audio_Measurements_Configurations_Insert_Input;
  on_conflict?: Maybe<Audio_Measurements_Configurations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Audio_Measurements_Master_FilesArgs = {
  objects: Array<Audio_Measurements_Master_Files_Insert_Input>;
  on_conflict?: Maybe<Audio_Measurements_Master_Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Audio_Measurements_Master_Files_OneArgs = {
  object: Audio_Measurements_Master_Files_Insert_Input;
  on_conflict?: Maybe<Audio_Measurements_Master_Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Audio_Measurements_OrientationsArgs = {
  objects: Array<Audio_Measurements_Orientations_Insert_Input>;
  on_conflict?: Maybe<Audio_Measurements_Orientations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Audio_Measurements_Orientations_OneArgs = {
  object: Audio_Measurements_Orientations_Insert_Input;
  on_conflict?: Maybe<Audio_Measurements_Orientations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Audio_Measurements_Payload_RecordingArgs = {
  objects: Array<Audio_Measurements_Payload_Recording_Insert_Input>;
  on_conflict?: Maybe<Audio_Measurements_Payload_Recording_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Audio_Measurements_Payload_Recording_OneArgs = {
  object: Audio_Measurements_Payload_Recording_Insert_Input;
  on_conflict?: Maybe<Audio_Measurements_Payload_Recording_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Audio_Measurements_PlacementsArgs = {
  objects: Array<Audio_Measurements_Placements_Insert_Input>;
  on_conflict?: Maybe<Audio_Measurements_Placements_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Audio_Measurements_Placements_OneArgs = {
  object: Audio_Measurements_Placements_Insert_Input;
  on_conflict?: Maybe<Audio_Measurements_Placements_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Audio_Measurements_Project_TasksArgs = {
  objects: Array<Audio_Measurements_Project_Tasks_Insert_Input>;
  on_conflict?: Maybe<Audio_Measurements_Project_Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Audio_Measurements_Project_Tasks_OneArgs = {
  object: Audio_Measurements_Project_Tasks_Insert_Input;
  on_conflict?: Maybe<Audio_Measurements_Project_Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Audio_Measurements_RecordingsArgs = {
  objects: Array<Audio_Measurements_Recordings_Insert_Input>;
  on_conflict?: Maybe<Audio_Measurements_Recordings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Audio_Measurements_Recordings_OneArgs = {
  object: Audio_Measurements_Recordings_Insert_Input;
  on_conflict?: Maybe<Audio_Measurements_Recordings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Audio_Measurements_StagesArgs = {
  objects: Array<Audio_Measurements_Stages_Insert_Input>;
  on_conflict?: Maybe<Audio_Measurements_Stages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Audio_Measurements_Stages_OneArgs = {
  object: Audio_Measurements_Stages_Insert_Input;
  on_conflict?: Maybe<Audio_Measurements_Stages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bringup_Checklist_DeliverableArgs = {
  objects: Array<Bringup_Checklist_Deliverable_Insert_Input>;
  on_conflict?: Maybe<Bringup_Checklist_Deliverable_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bringup_Checklist_Deliverable_OneArgs = {
  object: Bringup_Checklist_Deliverable_Insert_Input;
  on_conflict?: Maybe<Bringup_Checklist_Deliverable_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bringup_ChecklistsArgs = {
  objects: Array<Bringup_Checklists_Insert_Input>;
  on_conflict?: Maybe<Bringup_Checklists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bringup_Checklists_OneArgs = {
  object: Bringup_Checklists_Insert_Input;
  on_conflict?: Maybe<Bringup_Checklists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bringup_EndorsementsArgs = {
  objects: Array<Bringup_Endorsements_Insert_Input>;
  on_conflict?: Maybe<Bringup_Endorsements_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bringup_Endorsements_OneArgs = {
  object: Bringup_Endorsements_Insert_Input;
  on_conflict?: Maybe<Bringup_Endorsements_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bringup_ItemsArgs = {
  objects: Array<Bringup_Items_Insert_Input>;
  on_conflict?: Maybe<Bringup_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bringup_Items_OneArgs = {
  object: Bringup_Items_Insert_Input;
  on_conflict?: Maybe<Bringup_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bringup_MilestonesArgs = {
  objects: Array<Bringup_Milestones_Insert_Input>;
  on_conflict?: Maybe<Bringup_Milestones_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bringup_Milestones_OneArgs = {
  object: Bringup_Milestones_Insert_Input;
  on_conflict?: Maybe<Bringup_Milestones_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bringup_Project_Item_CommentsArgs = {
  objects: Array<Bringup_Project_Item_Comments_Insert_Input>;
  on_conflict?: Maybe<Bringup_Project_Item_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bringup_Project_Item_Comments_OneArgs = {
  object: Bringup_Project_Item_Comments_Insert_Input;
  on_conflict?: Maybe<Bringup_Project_Item_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CategoriesArgs = {
  objects: Array<Categories_Insert_Input>;
  on_conflict?: Maybe<Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Categories_OneArgs = {
  object: Categories_Insert_Input;
  on_conflict?: Maybe<Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Context_Display_FieldArgs = {
  objects: Array<Context_Display_Field_Insert_Input>;
  on_conflict?: Maybe<Context_Display_Field_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Context_Display_Field_OneArgs = {
  object: Context_Display_Field_Insert_Input;
  on_conflict?: Maybe<Context_Display_Field_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Context_DisplaysArgs = {
  objects: Array<Context_Displays_Insert_Input>;
  on_conflict?: Maybe<Context_Displays_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Context_Displays_OneArgs = {
  object: Context_Displays_Insert_Input;
  on_conflict?: Maybe<Context_Displays_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContextsArgs = {
  objects: Array<Contexts_Insert_Input>;
  on_conflict?: Maybe<Contexts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contexts_OneArgs = {
  object: Contexts_Insert_Input;
  on_conflict?: Maybe<Contexts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CustomersArgs = {
  objects: Array<Customers_Insert_Input>;
  on_conflict?: Maybe<Customers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customers_OneArgs = {
  object: Customers_Insert_Input;
  on_conflict?: Maybe<Customers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DeliverablesArgs = {
  objects: Array<Deliverables_Insert_Input>;
  on_conflict?: Maybe<Deliverables_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliverables_OneArgs = {
  object: Deliverables_Insert_Input;
  on_conflict?: Maybe<Deliverables_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_ArchitecturesArgs = {
  objects: Array<Deliveries_Architectures_Insert_Input>;
  on_conflict?: Maybe<Deliveries_Architectures_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Architectures_OneArgs = {
  object: Deliveries_Architectures_Insert_Input;
  on_conflict?: Maybe<Deliveries_Architectures_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Artifact_Microsoft_Whql_SubmissionArgs = {
  objects: Array<Deliveries_Artifact_Microsoft_Whql_Submission_Insert_Input>;
  on_conflict?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Artifact_Microsoft_Whql_Submission_OneArgs = {
  object: Deliveries_Artifact_Microsoft_Whql_Submission_Insert_Input;
  on_conflict?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Artifact_Operating_SystemArgs = {
  objects: Array<Deliveries_Artifact_Operating_System_Insert_Input>;
  on_conflict?: Maybe<Deliveries_Artifact_Operating_System_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Artifact_Operating_System_OneArgs = {
  object: Deliveries_Artifact_Operating_System_Insert_Input;
  on_conflict?: Maybe<Deliveries_Artifact_Operating_System_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_ArtifactsArgs = {
  objects: Array<Deliveries_Artifacts_Insert_Input>;
  on_conflict?: Maybe<Deliveries_Artifacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Artifacts_OneArgs = {
  object: Deliveries_Artifacts_Insert_Input;
  on_conflict?: Maybe<Deliveries_Artifacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Device_FamiliesArgs = {
  objects: Array<Deliveries_Device_Families_Insert_Input>;
  on_conflict?: Maybe<Deliveries_Device_Families_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Device_Families_OneArgs = {
  object: Deliveries_Device_Families_Insert_Input;
  on_conflict?: Maybe<Deliveries_Device_Families_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Driver_PackageArgs = {
  objects: Array<Deliveries_Driver_Package_Insert_Input>;
  on_conflict?: Maybe<Deliveries_Driver_Package_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Driver_Package_OneArgs = {
  object: Deliveries_Driver_Package_Insert_Input;
  on_conflict?: Maybe<Deliveries_Driver_Package_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Driver_ProjectArgs = {
  objects: Array<Deliveries_Driver_Project_Insert_Input>;
  on_conflict?: Maybe<Deliveries_Driver_Project_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Driver_Project_OneArgs = {
  object: Deliveries_Driver_Project_Insert_Input;
  on_conflict?: Maybe<Deliveries_Driver_Project_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Driver_Version_EngineArgs = {
  objects: Array<Deliveries_Driver_Version_Engine_Insert_Input>;
  on_conflict?: Maybe<Deliveries_Driver_Version_Engine_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Driver_Version_Engine_OneArgs = {
  object: Deliveries_Driver_Version_Engine_Insert_Input;
  on_conflict?: Maybe<Deliveries_Driver_Version_Engine_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Driver_Version_PackageArgs = {
  objects: Array<Deliveries_Driver_Version_Package_Insert_Input>;
  on_conflict?: Maybe<Deliveries_Driver_Version_Package_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Driver_Version_Package_OneArgs = {
  object: Deliveries_Driver_Version_Package_Insert_Input;
  on_conflict?: Maybe<Deliveries_Driver_Version_Package_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Driver_VersionsArgs = {
  objects: Array<Deliveries_Driver_Versions_Insert_Input>;
  on_conflict?: Maybe<Deliveries_Driver_Versions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Driver_Versions_OneArgs = {
  object: Deliveries_Driver_Versions_Insert_Input;
  on_conflict?: Maybe<Deliveries_Driver_Versions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_DriversArgs = {
  objects: Array<Deliveries_Drivers_Insert_Input>;
  on_conflict?: Maybe<Deliveries_Drivers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Drivers_OneArgs = {
  object: Deliveries_Drivers_Insert_Input;
  on_conflict?: Maybe<Deliveries_Drivers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Microsoft_Hlk_ArtifactsArgs = {
  objects: Array<Deliveries_Microsoft_Hlk_Artifacts_Insert_Input>;
  on_conflict?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Microsoft_Hlk_Artifacts_OneArgs = {
  object: Deliveries_Microsoft_Hlk_Artifacts_Insert_Input;
  on_conflict?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Microsoft_Shipping_Label_ReviewsArgs = {
  objects: Array<Deliveries_Microsoft_Shipping_Label_Reviews_Insert_Input>;
  on_conflict?: Maybe<Deliveries_Microsoft_Shipping_Label_Reviews_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Microsoft_Shipping_Label_Reviews_OneArgs = {
  object: Deliveries_Microsoft_Shipping_Label_Reviews_Insert_Input;
  on_conflict?: Maybe<Deliveries_Microsoft_Shipping_Label_Reviews_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Microsoft_Shipping_LabelsArgs = {
  objects: Array<Deliveries_Microsoft_Shipping_Labels_Insert_Input>;
  on_conflict?: Maybe<Deliveries_Microsoft_Shipping_Labels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Microsoft_Shipping_Labels_OneArgs = {
  object: Deliveries_Microsoft_Shipping_Labels_Insert_Input;
  on_conflict?: Maybe<Deliveries_Microsoft_Shipping_Labels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Microsoft_Whql_SubmissionsArgs = {
  objects: Array<Deliveries_Microsoft_Whql_Submissions_Insert_Input>;
  on_conflict?: Maybe<Deliveries_Microsoft_Whql_Submissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Microsoft_Whql_Submissions_OneArgs = {
  object: Deliveries_Microsoft_Whql_Submissions_Insert_Input;
  on_conflict?: Maybe<Deliveries_Microsoft_Whql_Submissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Operating_SystemsArgs = {
  objects: Array<Deliveries_Operating_Systems_Insert_Input>;
  on_conflict?: Maybe<Deliveries_Operating_Systems_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Operating_Systems_OneArgs = {
  object: Deliveries_Operating_Systems_Insert_Input;
  on_conflict?: Maybe<Deliveries_Operating_Systems_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Package_Operating_SystemsArgs = {
  objects: Array<Deliveries_Package_Operating_Systems_Insert_Input>;
  on_conflict?: Maybe<Deliveries_Package_Operating_Systems_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Package_Operating_Systems_OneArgs = {
  object: Deliveries_Package_Operating_Systems_Insert_Input;
  on_conflict?: Maybe<Deliveries_Package_Operating_Systems_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Package_TargetArgs = {
  objects: Array<Deliveries_Package_Target_Insert_Input>;
  on_conflict?: Maybe<Deliveries_Package_Target_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Package_Target_OneArgs = {
  object: Deliveries_Package_Target_Insert_Input;
  on_conflict?: Maybe<Deliveries_Package_Target_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_PackagesArgs = {
  objects: Array<Deliveries_Packages_Insert_Input>;
  on_conflict?: Maybe<Deliveries_Packages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Packages_OneArgs = {
  object: Deliveries_Packages_Insert_Input;
  on_conflict?: Maybe<Deliveries_Packages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_PartnersArgs = {
  objects: Array<Deliveries_Partners_Insert_Input>;
  on_conflict?: Maybe<Deliveries_Partners_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Partners_OneArgs = {
  object: Deliveries_Partners_Insert_Input;
  on_conflict?: Maybe<Deliveries_Partners_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_PlatformsArgs = {
  objects: Array<Deliveries_Platforms_Insert_Input>;
  on_conflict?: Maybe<Deliveries_Platforms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Platforms_OneArgs = {
  object: Deliveries_Platforms_Insert_Input;
  on_conflict?: Maybe<Deliveries_Platforms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Project_ConfigsArgs = {
  objects: Array<Deliveries_Project_Configs_Insert_Input>;
  on_conflict?: Maybe<Deliveries_Project_Configs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Project_Configs_OneArgs = {
  object: Deliveries_Project_Configs_Insert_Input;
  on_conflict?: Maybe<Deliveries_Project_Configs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Target_PartnerArgs = {
  objects: Array<Deliveries_Target_Partner_Insert_Input>;
  on_conflict?: Maybe<Deliveries_Target_Partner_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Target_Partner_OneArgs = {
  object: Deliveries_Target_Partner_Insert_Input;
  on_conflict?: Maybe<Deliveries_Target_Partner_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_TargetsArgs = {
  objects: Array<Deliveries_Targets_Insert_Input>;
  on_conflict?: Maybe<Deliveries_Targets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_Targets_OneArgs = {
  object: Deliveries_Targets_Insert_Input;
  on_conflict?: Maybe<Deliveries_Targets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Device_FirmwareArgs = {
  objects: Array<Device_Firmware_Insert_Input>;
  on_conflict?: Maybe<Device_Firmware_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Device_Firmware_OneArgs = {
  object: Device_Firmware_Insert_Input;
  on_conflict?: Maybe<Device_Firmware_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Device_ProjectArgs = {
  objects: Array<Device_Project_Insert_Input>;
  on_conflict?: Maybe<Device_Project_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Device_Project_OneArgs = {
  object: Device_Project_Insert_Input;
  on_conflict?: Maybe<Device_Project_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DevicesArgs = {
  objects: Array<Devices_Insert_Input>;
  on_conflict?: Maybe<Devices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Devices_OneArgs = {
  object: Devices_Insert_Input;
  on_conflict?: Maybe<Devices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dynamic_ScalingArgs = {
  objects: Array<Dynamic_Scaling_Insert_Input>;
  on_conflict?: Maybe<Dynamic_Scaling_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dynamic_Scaling_OneArgs = {
  object: Dynamic_Scaling_Insert_Input;
  on_conflict?: Maybe<Dynamic_Scaling_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Engine_OpmodeArgs = {
  objects: Array<Engine_Opmode_Insert_Input>;
  on_conflict?: Maybe<Engine_Opmode_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Engine_Opmode_OneArgs = {
  object: Engine_Opmode_Insert_Input;
  on_conflict?: Maybe<Engine_Opmode_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Engine_Opmode_RecordingArgs = {
  objects: Array<Engine_Opmode_Recording_Insert_Input>;
  on_conflict?: Maybe<Engine_Opmode_Recording_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Engine_Opmode_Recording_OneArgs = {
  object: Engine_Opmode_Recording_Insert_Input;
  on_conflict?: Maybe<Engine_Opmode_Recording_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EnginesArgs = {
  objects: Array<Engines_Insert_Input>;
  on_conflict?: Maybe<Engines_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Engines_OneArgs = {
  object: Engines_Insert_Input;
  on_conflict?: Maybe<Engines_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Field_Option_MediaArgs = {
  objects: Array<Field_Option_Media_Insert_Input>;
  on_conflict?: Maybe<Field_Option_Media_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Field_Option_Media_OneArgs = {
  object: Field_Option_Media_Insert_Input;
  on_conflict?: Maybe<Field_Option_Media_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Field_OptionsArgs = {
  objects: Array<Field_Options_Insert_Input>;
  on_conflict?: Maybe<Field_Options_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Field_Options_OneArgs = {
  object: Field_Options_Insert_Input;
  on_conflict?: Maybe<Field_Options_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Field_TranslationArgs = {
  objects: Array<Field_Translation_Insert_Input>;
  on_conflict?: Maybe<Field_Translation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Field_Translation_OneArgs = {
  object: Field_Translation_Insert_Input;
  on_conflict?: Maybe<Field_Translation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FieldsArgs = {
  objects: Array<Fields_Insert_Input>;
  on_conflict?: Maybe<Fields_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fields_OneArgs = {
  object: Fields_Insert_Input;
  on_conflict?: Maybe<Fields_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FirmwareArgs = {
  objects: Array<Firmware_Insert_Input>;
  on_conflict?: Maybe<Firmware_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Firmware_OneArgs = {
  object: Firmware_Insert_Input;
  on_conflict?: Maybe<Firmware_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ground_Truth_ConfigurationsArgs = {
  objects: Array<Ground_Truth_Configurations_Insert_Input>;
  on_conflict?: Maybe<Ground_Truth_Configurations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ground_Truth_Configurations_OneArgs = {
  object: Ground_Truth_Configurations_Insert_Input;
  on_conflict?: Maybe<Ground_Truth_Configurations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ground_Truth_RecordingsArgs = {
  objects: Array<Ground_Truth_Recordings_Insert_Input>;
  on_conflict?: Maybe<Ground_Truth_Recordings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ground_Truth_Recordings_OneArgs = {
  object: Ground_Truth_Recordings_Insert_Input;
  on_conflict?: Maybe<Ground_Truth_Recordings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ground_Truth_TypesArgs = {
  objects: Array<Ground_Truth_Types_Insert_Input>;
  on_conflict?: Maybe<Ground_Truth_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ground_Truth_Types_OneArgs = {
  object: Ground_Truth_Types_Insert_Input;
  on_conflict?: Maybe<Ground_Truth_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Hypertask_Field_OptionArgs = {
  objects: Array<Hypertask_Field_Option_Insert_Input>;
  on_conflict?: Maybe<Hypertask_Field_Option_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Hypertask_Field_Option_OneArgs = {
  object: Hypertask_Field_Option_Insert_Input;
  on_conflict?: Maybe<Hypertask_Field_Option_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Hypertask_TaskArgs = {
  objects: Array<Hypertask_Task_Insert_Input>;
  on_conflict?: Maybe<Hypertask_Task_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Hypertask_Task_OneArgs = {
  object: Hypertask_Task_Insert_Input;
  on_conflict?: Maybe<Hypertask_Task_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_HypertasksArgs = {
  objects: Array<Hypertasks_Insert_Input>;
  on_conflict?: Maybe<Hypertasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Hypertasks_OneArgs = {
  object: Hypertasks_Insert_Input;
  on_conflict?: Maybe<Hypertasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Instruction_TranslationsArgs = {
  objects: Array<Instruction_Translations_Insert_Input>;
  on_conflict?: Maybe<Instruction_Translations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Instruction_Translations_OneArgs = {
  object: Instruction_Translations_Insert_Input;
  on_conflict?: Maybe<Instruction_Translations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Integration_Test_Expectation_MediaArgs = {
  objects: Array<Integration_Test_Expectation_Media_Insert_Input>;
  on_conflict?: Maybe<Integration_Test_Expectation_Media_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Integration_Test_Expectation_Media_OneArgs = {
  object: Integration_Test_Expectation_Media_Insert_Input;
  on_conflict?: Maybe<Integration_Test_Expectation_Media_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Integration_Test_Expectation_StepArgs = {
  objects: Array<Integration_Test_Expectation_Step_Insert_Input>;
  on_conflict?: Maybe<Integration_Test_Expectation_Step_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Integration_Test_Expectation_Step_OneArgs = {
  object: Integration_Test_Expectation_Step_Insert_Input;
  on_conflict?: Maybe<Integration_Test_Expectation_Step_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Integration_Test_Expectation_TranslationArgs = {
  objects: Array<Integration_Test_Expectation_Translation_Insert_Input>;
  on_conflict?: Maybe<Integration_Test_Expectation_Translation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Integration_Test_Expectation_Translation_OneArgs = {
  object: Integration_Test_Expectation_Translation_Insert_Input;
  on_conflict?: Maybe<Integration_Test_Expectation_Translation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Integration_Test_ExpectationsArgs = {
  objects: Array<Integration_Test_Expectations_Insert_Input>;
  on_conflict?: Maybe<Integration_Test_Expectations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Integration_Test_Expectations_OneArgs = {
  object: Integration_Test_Expectations_Insert_Input;
  on_conflict?: Maybe<Integration_Test_Expectations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Integration_Test_Instruction_MediaArgs = {
  objects: Array<Integration_Test_Instruction_Media_Insert_Input>;
  on_conflict?: Maybe<Integration_Test_Instruction_Media_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Integration_Test_Instruction_Media_OneArgs = {
  object: Integration_Test_Instruction_Media_Insert_Input;
  on_conflict?: Maybe<Integration_Test_Instruction_Media_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Integration_Test_Instruction_TranslationArgs = {
  objects: Array<Integration_Test_Instruction_Translation_Insert_Input>;
  on_conflict?: Maybe<Integration_Test_Instruction_Translation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Integration_Test_Instruction_Translation_OneArgs = {
  object: Integration_Test_Instruction_Translation_Insert_Input;
  on_conflict?: Maybe<Integration_Test_Instruction_Translation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Integration_Test_InstructionsArgs = {
  objects: Array<Integration_Test_Instructions_Insert_Input>;
  on_conflict?: Maybe<Integration_Test_Instructions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Integration_Test_Instructions_OneArgs = {
  object: Integration_Test_Instructions_Insert_Input;
  on_conflict?: Maybe<Integration_Test_Instructions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Integration_Test_ObservancesArgs = {
  objects: Array<Integration_Test_Observances_Insert_Input>;
  on_conflict?: Maybe<Integration_Test_Observances_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Integration_Test_Observances_OneArgs = {
  object: Integration_Test_Observances_Insert_Input;
  on_conflict?: Maybe<Integration_Test_Observances_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Integration_Test_ObservationsArgs = {
  objects: Array<Integration_Test_Observations_Insert_Input>;
  on_conflict?: Maybe<Integration_Test_Observations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Integration_Test_Observations_OneArgs = {
  object: Integration_Test_Observations_Insert_Input;
  on_conflict?: Maybe<Integration_Test_Observations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Integration_Test_Project_SequenceArgs = {
  objects: Array<Integration_Test_Project_Sequence_Insert_Input>;
  on_conflict?: Maybe<Integration_Test_Project_Sequence_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Integration_Test_Project_Sequence_OneArgs = {
  object: Integration_Test_Project_Sequence_Insert_Input;
  on_conflict?: Maybe<Integration_Test_Project_Sequence_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Integration_Test_SequencesArgs = {
  objects: Array<Integration_Test_Sequences_Insert_Input>;
  on_conflict?: Maybe<Integration_Test_Sequences_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Integration_Test_Sequences_OneArgs = {
  object: Integration_Test_Sequences_Insert_Input;
  on_conflict?: Maybe<Integration_Test_Sequences_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Integration_Test_StepsArgs = {
  objects: Array<Integration_Test_Steps_Insert_Input>;
  on_conflict?: Maybe<Integration_Test_Steps_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Integration_Test_Steps_OneArgs = {
  object: Integration_Test_Steps_Insert_Input;
  on_conflict?: Maybe<Integration_Test_Steps_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Machine_Learning_BlobsArgs = {
  objects: Array<Machine_Learning_Blobs_Insert_Input>;
  on_conflict?: Maybe<Machine_Learning_Blobs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Machine_Learning_Blobs_OneArgs = {
  object: Machine_Learning_Blobs_Insert_Input;
  on_conflict?: Maybe<Machine_Learning_Blobs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Machine_Learning_Dataset_FeatureArgs = {
  objects: Array<Machine_Learning_Dataset_Feature_Insert_Input>;
  on_conflict?: Maybe<Machine_Learning_Dataset_Feature_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Machine_Learning_Dataset_Feature_OneArgs = {
  object: Machine_Learning_Dataset_Feature_Insert_Input;
  on_conflict?: Maybe<Machine_Learning_Dataset_Feature_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Machine_Learning_DatasetsArgs = {
  objects: Array<Machine_Learning_Datasets_Insert_Input>;
  on_conflict?: Maybe<Machine_Learning_Datasets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Machine_Learning_Datasets_OneArgs = {
  object: Machine_Learning_Datasets_Insert_Input;
  on_conflict?: Maybe<Machine_Learning_Datasets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Machine_Learning_FeaturesArgs = {
  objects: Array<Machine_Learning_Features_Insert_Input>;
  on_conflict?: Maybe<Machine_Learning_Features_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Machine_Learning_Features_OneArgs = {
  object: Machine_Learning_Features_Insert_Input;
  on_conflict?: Maybe<Machine_Learning_Features_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Machine_Learning_Model_BlobArgs = {
  objects: Array<Machine_Learning_Model_Blob_Insert_Input>;
  on_conflict?: Maybe<Machine_Learning_Model_Blob_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Machine_Learning_Model_Blob_OneArgs = {
  object: Machine_Learning_Model_Blob_Insert_Input;
  on_conflict?: Maybe<Machine_Learning_Model_Blob_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Machine_Learning_Model_DatasetArgs = {
  objects: Array<Machine_Learning_Model_Dataset_Insert_Input>;
  on_conflict?: Maybe<Machine_Learning_Model_Dataset_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Machine_Learning_Model_Dataset_OneArgs = {
  object: Machine_Learning_Model_Dataset_Insert_Input;
  on_conflict?: Maybe<Machine_Learning_Model_Dataset_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Machine_Learning_Model_RecordingArgs = {
  objects: Array<Machine_Learning_Model_Recording_Insert_Input>;
  on_conflict?: Maybe<Machine_Learning_Model_Recording_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Machine_Learning_Model_Recording_OneArgs = {
  object: Machine_Learning_Model_Recording_Insert_Input;
  on_conflict?: Maybe<Machine_Learning_Model_Recording_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Machine_Learning_ModelsArgs = {
  objects: Array<Machine_Learning_Models_Insert_Input>;
  on_conflict?: Maybe<Machine_Learning_Models_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Machine_Learning_Models_OneArgs = {
  object: Machine_Learning_Models_Insert_Input;
  on_conflict?: Maybe<Machine_Learning_Models_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MediaArgs = {
  objects: Array<Media_Insert_Input>;
  on_conflict?: Maybe<Media_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Media_OneArgs = {
  object: Media_Insert_Input;
  on_conflict?: Maybe<Media_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mode_ProjectArgs = {
  objects: Array<Mode_Project_Insert_Input>;
  on_conflict?: Maybe<Mode_Project_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mode_Project_OneArgs = {
  object: Mode_Project_Insert_Input;
  on_conflict?: Maybe<Mode_Project_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Model_Name_HashesArgs = {
  objects: Array<Model_Name_Hashes_Insert_Input>;
  on_conflict?: Maybe<Model_Name_Hashes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Model_Name_Hashes_OneArgs = {
  object: Model_Name_Hashes_Insert_Input;
  on_conflict?: Maybe<Model_Name_Hashes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ModelsArgs = {
  objects: Array<Models_Insert_Input>;
  on_conflict?: Maybe<Models_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Models_OneArgs = {
  object: Models_Insert_Input;
  on_conflict?: Maybe<Models_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Opmode_ProfileArgs = {
  objects: Array<Opmode_Profile_Insert_Input>;
  on_conflict?: Maybe<Opmode_Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Opmode_Profile_OneArgs = {
  object: Opmode_Profile_Insert_Input;
  on_conflict?: Maybe<Opmode_Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Opmode_ProjectArgs = {
  objects: Array<Opmode_Project_Insert_Input>;
  on_conflict?: Maybe<Opmode_Project_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Opmode_Project_OneArgs = {
  object: Opmode_Project_Insert_Input;
  on_conflict?: Maybe<Opmode_Project_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OpmodesArgs = {
  objects: Array<Opmodes_Insert_Input>;
  on_conflict?: Maybe<Opmodes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Opmodes_OneArgs = {
  object: Opmodes_Insert_Input;
  on_conflict?: Maybe<Opmodes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Option_MediaArgs = {
  objects: Array<Option_Media_Insert_Input>;
  on_conflict?: Maybe<Option_Media_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Option_Media_OneArgs = {
  object: Option_Media_Insert_Input;
  on_conflict?: Maybe<Option_Media_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Option_TranslationArgs = {
  objects: Array<Option_Translation_Insert_Input>;
  on_conflict?: Maybe<Option_Translation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Option_Translation_OneArgs = {
  object: Option_Translation_Insert_Input;
  on_conflict?: Maybe<Option_Translation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OptionsArgs = {
  objects: Array<Options_Insert_Input>;
  on_conflict?: Maybe<Options_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Options_OneArgs = {
  object: Options_Insert_Input;
  on_conflict?: Maybe<Options_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payload_RecordingArgs = {
  objects: Array<Payload_Recording_Insert_Input>;
  on_conflict?: Maybe<Payload_Recording_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payload_Recording_OneArgs = {
  object: Payload_Recording_Insert_Input;
  on_conflict?: Maybe<Payload_Recording_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PayloadsArgs = {
  objects: Array<Payloads_Insert_Input>;
  on_conflict?: Maybe<Payloads_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payloads_OneArgs = {
  object: Payloads_Insert_Input;
  on_conflict?: Maybe<Payloads_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Performance_Ratio_Metric_ContributionsArgs = {
  objects: Array<Performance_Ratio_Metric_Contributions_Insert_Input>;
  on_conflict?: Maybe<Performance_Ratio_Metric_Contributions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Performance_Ratio_Metric_Contributions_OneArgs = {
  object: Performance_Ratio_Metric_Contributions_Insert_Input;
  on_conflict?: Maybe<Performance_Ratio_Metric_Contributions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Performance_Ratio_MetricsArgs = {
  objects: Array<Performance_Ratio_Metrics_Insert_Input>;
  on_conflict?: Maybe<Performance_Ratio_Metrics_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Performance_Ratio_Metrics_OneArgs = {
  object: Performance_Ratio_Metrics_Insert_Input;
  on_conflict?: Maybe<Performance_Ratio_Metrics_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Performance_Report_Deliverable_TemplateArgs = {
  objects: Array<Performance_Report_Deliverable_Template_Insert_Input>;
  on_conflict?: Maybe<Performance_Report_Deliverable_Template_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Performance_Report_Deliverable_Template_OneArgs = {
  object: Performance_Report_Deliverable_Template_Insert_Input;
  on_conflict?: Maybe<Performance_Report_Deliverable_Template_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Performance_Report_Section_Pivot_FieldArgs = {
  objects: Array<Performance_Report_Section_Pivot_Field_Insert_Input>;
  on_conflict?: Maybe<Performance_Report_Section_Pivot_Field_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Performance_Report_Section_Pivot_Field_OneArgs = {
  object: Performance_Report_Section_Pivot_Field_Insert_Input;
  on_conflict?: Maybe<Performance_Report_Section_Pivot_Field_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Performance_Report_Section_Ratio_MetricArgs = {
  objects: Array<Performance_Report_Section_Ratio_Metric_Insert_Input>;
  on_conflict?: Maybe<Performance_Report_Section_Ratio_Metric_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Performance_Report_Section_Ratio_Metric_OneArgs = {
  object: Performance_Report_Section_Ratio_Metric_Insert_Input;
  on_conflict?: Maybe<Performance_Report_Section_Ratio_Metric_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Performance_Report_Section_Task_BlacklistArgs = {
  objects: Array<Performance_Report_Section_Task_Blacklist_Insert_Input>;
  on_conflict?: Maybe<Performance_Report_Section_Task_Blacklist_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Performance_Report_Section_Task_Blacklist_OneArgs = {
  object: Performance_Report_Section_Task_Blacklist_Insert_Input;
  on_conflict?: Maybe<Performance_Report_Section_Task_Blacklist_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Performance_Report_SectionsArgs = {
  objects: Array<Performance_Report_Sections_Insert_Input>;
  on_conflict?: Maybe<Performance_Report_Sections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Performance_Report_Sections_OneArgs = {
  object: Performance_Report_Sections_Insert_Input;
  on_conflict?: Maybe<Performance_Report_Sections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Performance_Report_TemplatesArgs = {
  objects: Array<Performance_Report_Templates_Insert_Input>;
  on_conflict?: Maybe<Performance_Report_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Performance_Report_Templates_OneArgs = {
  object: Performance_Report_Templates_Insert_Input;
  on_conflict?: Maybe<Performance_Report_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PeripheralsArgs = {
  objects: Array<Peripherals_Insert_Input>;
  on_conflict?: Maybe<Peripherals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Peripherals_OneArgs = {
  object: Peripherals_Insert_Input;
  on_conflict?: Maybe<Peripherals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plan_HypertasksArgs = {
  objects: Array<Plan_Hypertasks_Insert_Input>;
  on_conflict?: Maybe<Plan_Hypertasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plan_Hypertasks_OneArgs = {
  object: Plan_Hypertasks_Insert_Input;
  on_conflict?: Maybe<Plan_Hypertasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plan_TasksArgs = {
  objects: Array<Plan_Tasks_Insert_Input>;
  on_conflict?: Maybe<Plan_Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plan_Tasks_OneArgs = {
  object: Plan_Tasks_Insert_Input;
  on_conflict?: Maybe<Plan_Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PlansArgs = {
  objects: Array<Plans_Insert_Input>;
  on_conflict?: Maybe<Plans_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plans_OneArgs = {
  object: Plans_Insert_Input;
  on_conflict?: Maybe<Plans_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProfilesArgs = {
  objects: Array<Profiles_Insert_Input>;
  on_conflict?: Maybe<Profiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profiles_OneArgs = {
  object: Profiles_Insert_Input;
  on_conflict?: Maybe<Profiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_ComboArgs = {
  objects: Array<Project_Combo_Insert_Input>;
  on_conflict?: Maybe<Project_Combo_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_Combo_OneArgs = {
  object: Project_Combo_Insert_Input;
  on_conflict?: Maybe<Project_Combo_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_HypertasksArgs = {
  objects: Array<Project_Hypertasks_Insert_Input>;
  on_conflict?: Maybe<Project_Hypertasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_Hypertasks_OneArgs = {
  object: Project_Hypertasks_Insert_Input;
  on_conflict?: Maybe<Project_Hypertasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_TasksArgs = {
  objects: Array<Project_Tasks_Insert_Input>;
  on_conflict?: Maybe<Project_Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_Tasks_OneArgs = {
  object: Project_Tasks_Insert_Input;
  on_conflict?: Maybe<Project_Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_UserArgs = {
  objects: Array<Project_User_Insert_Input>;
  on_conflict?: Maybe<Project_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_User_OneArgs = {
  object: Project_User_Insert_Input;
  on_conflict?: Maybe<Project_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProjectsArgs = {
  objects: Array<Projects_Insert_Input>;
  on_conflict?: Maybe<Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Projects_OneArgs = {
  object: Projects_Insert_Input;
  on_conflict?: Maybe<Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rabbit_Test_Plan_ProjectArgs = {
  objects: Array<Rabbit_Test_Plan_Project_Insert_Input>;
  on_conflict?: Maybe<Rabbit_Test_Plan_Project_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rabbit_Test_Plan_Project_OneArgs = {
  object: Rabbit_Test_Plan_Project_Insert_Input;
  on_conflict?: Maybe<Rabbit_Test_Plan_Project_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rabbit_Test_Plan_RecordingArgs = {
  objects: Array<Rabbit_Test_Plan_Recording_Insert_Input>;
  on_conflict?: Maybe<Rabbit_Test_Plan_Recording_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rabbit_Test_Plan_Recording_OneArgs = {
  object: Rabbit_Test_Plan_Recording_Insert_Input;
  on_conflict?: Maybe<Rabbit_Test_Plan_Recording_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rabbit_Test_Plan_TaskArgs = {
  objects: Array<Rabbit_Test_Plan_Task_Insert_Input>;
  on_conflict?: Maybe<Rabbit_Test_Plan_Task_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rabbit_Test_Plan_Task_OneArgs = {
  object: Rabbit_Test_Plan_Task_Insert_Input;
  on_conflict?: Maybe<Rabbit_Test_Plan_Task_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rabbit_Test_PlansArgs = {
  objects: Array<Rabbit_Test_Plans_Insert_Input>;
  on_conflict?: Maybe<Rabbit_Test_Plans_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rabbit_Test_Plans_OneArgs = {
  object: Rabbit_Test_Plans_Insert_Input;
  on_conflict?: Maybe<Rabbit_Test_Plans_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recording_AppsArgs = {
  objects: Array<Recording_Apps_Insert_Input>;
  on_conflict?: Maybe<Recording_Apps_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recording_Apps_OneArgs = {
  object: Recording_Apps_Insert_Input;
  on_conflict?: Maybe<Recording_Apps_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recording_Audio_DriverArgs = {
  objects: Array<Recording_Audio_Driver_Insert_Input>;
  on_conflict?: Maybe<Recording_Audio_Driver_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recording_Audio_Driver_OneArgs = {
  object: Recording_Audio_Driver_Insert_Input;
  on_conflict?: Maybe<Recording_Audio_Driver_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recording_Data_Ground_TruthArgs = {
  objects: Array<Recording_Data_Ground_Truth_Insert_Input>;
  on_conflict?: Maybe<Recording_Data_Ground_Truth_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recording_Data_Ground_Truth_OneArgs = {
  object: Recording_Data_Ground_Truth_Insert_Input;
  on_conflict?: Maybe<Recording_Data_Ground_Truth_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recording_Data_LabelsArgs = {
  objects: Array<Recording_Data_Labels_Insert_Input>;
  on_conflict?: Maybe<Recording_Data_Labels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recording_Data_Labels_OneArgs = {
  object: Recording_Data_Labels_Insert_Input;
  on_conflict?: Maybe<Recording_Data_Labels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recording_Data_Mode_ParameterArgs = {
  objects: Array<Recording_Data_Mode_Parameter_Insert_Input>;
  on_conflict?: Maybe<Recording_Data_Mode_Parameter_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recording_Data_Mode_Parameter_OneArgs = {
  object: Recording_Data_Mode_Parameter_Insert_Input;
  on_conflict?: Maybe<Recording_Data_Mode_Parameter_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recording_Data_ModesArgs = {
  objects: Array<Recording_Data_Modes_Insert_Input>;
  on_conflict?: Maybe<Recording_Data_Modes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recording_Data_Modes_OneArgs = {
  object: Recording_Data_Modes_Insert_Input;
  on_conflict?: Maybe<Recording_Data_Modes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recording_Data_ParametersArgs = {
  objects: Array<Recording_Data_Parameters_Insert_Input>;
  on_conflict?: Maybe<Recording_Data_Parameters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recording_Data_Parameters_OneArgs = {
  object: Recording_Data_Parameters_Insert_Input;
  on_conflict?: Maybe<Recording_Data_Parameters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recording_Data_Payload_PairArgs = {
  objects: Array<Recording_Data_Payload_Pair_Insert_Input>;
  on_conflict?: Maybe<Recording_Data_Payload_Pair_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recording_Data_Payload_Pair_OneArgs = {
  object: Recording_Data_Payload_Pair_Insert_Input;
  on_conflict?: Maybe<Recording_Data_Payload_Pair_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recording_Data_PredictionsArgs = {
  objects: Array<Recording_Data_Predictions_Insert_Input>;
  on_conflict?: Maybe<Recording_Data_Predictions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recording_Data_Predictions_OneArgs = {
  object: Recording_Data_Predictions_Insert_Input;
  on_conflict?: Maybe<Recording_Data_Predictions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recording_Recording_TagArgs = {
  objects: Array<Recording_Recording_Tag_Insert_Input>;
  on_conflict?: Maybe<Recording_Recording_Tag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recording_Recording_Tag_OneArgs = {
  object: Recording_Recording_Tag_Insert_Input;
  on_conflict?: Maybe<Recording_Recording_Tag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recording_TagsArgs = {
  objects: Array<Recording_Tags_Insert_Input>;
  on_conflict?: Maybe<Recording_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recording_Tags_OneArgs = {
  object: Recording_Tags_Insert_Input;
  on_conflict?: Maybe<Recording_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RecordingsArgs = {
  objects: Array<Recordings_Insert_Input>;
  on_conflict?: Maybe<Recordings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recordings_OneArgs = {
  object: Recordings_Insert_Input;
  on_conflict?: Maybe<Recordings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Role_UserArgs = {
  objects: Array<Role_User_Insert_Input>;
  on_conflict?: Maybe<Role_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Role_User_OneArgs = {
  object: Role_User_Insert_Input;
  on_conflict?: Maybe<Role_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RoomsArgs = {
  objects: Array<Rooms_Insert_Input>;
  on_conflict?: Maybe<Rooms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rooms_OneArgs = {
  object: Rooms_Insert_Input;
  on_conflict?: Maybe<Rooms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Simulations_ScoresArgs = {
  objects: Array<Simulations_Scores_Insert_Input>;
  on_conflict?: Maybe<Simulations_Scores_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Simulations_Scores_OneArgs = {
  object: Simulations_Scores_Insert_Input;
  on_conflict?: Maybe<Simulations_Scores_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Task_Field_OptionArgs = {
  objects: Array<Task_Field_Option_Insert_Input>;
  on_conflict?: Maybe<Task_Field_Option_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Task_Field_Option_OneArgs = {
  object: Task_Field_Option_Insert_Input;
  on_conflict?: Maybe<Task_Field_Option_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Task_Groups_Group_FieldsArgs = {
  objects: Array<Task_Groups_Group_Fields_Insert_Input>;
  on_conflict?: Maybe<Task_Groups_Group_Fields_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Task_Groups_Group_Fields_OneArgs = {
  object: Task_Groups_Group_Fields_Insert_Input;
  on_conflict?: Maybe<Task_Groups_Group_Fields_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Task_Groups_GroupsArgs = {
  objects: Array<Task_Groups_Groups_Insert_Input>;
  on_conflict?: Maybe<Task_Groups_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Task_Groups_Groups_OneArgs = {
  object: Task_Groups_Groups_Insert_Input;
  on_conflict?: Maybe<Task_Groups_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Task_Groups_HierarchiesArgs = {
  objects: Array<Task_Groups_Hierarchies_Insert_Input>;
  on_conflict?: Maybe<Task_Groups_Hierarchies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Task_Groups_Hierarchies_OneArgs = {
  object: Task_Groups_Hierarchies_Insert_Input;
  on_conflict?: Maybe<Task_Groups_Hierarchies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Task_Groups_Hierarchy_GroupsArgs = {
  objects: Array<Task_Groups_Hierarchy_Groups_Insert_Input>;
  on_conflict?: Maybe<Task_Groups_Hierarchy_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Task_Groups_Hierarchy_Groups_OneArgs = {
  object: Task_Groups_Hierarchy_Groups_Insert_Input;
  on_conflict?: Maybe<Task_Groups_Hierarchy_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Task_InstructionsArgs = {
  objects: Array<Task_Instructions_Insert_Input>;
  on_conflict?: Maybe<Task_Instructions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Task_Instructions_OneArgs = {
  object: Task_Instructions_Insert_Input;
  on_conflict?: Maybe<Task_Instructions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Task_MediaArgs = {
  objects: Array<Task_Media_Insert_Input>;
  on_conflict?: Maybe<Task_Media_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Task_Media_OneArgs = {
  object: Task_Media_Insert_Input;
  on_conflict?: Maybe<Task_Media_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Task_TranslationArgs = {
  objects: Array<Task_Translation_Insert_Input>;
  on_conflict?: Maybe<Task_Translation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Task_Translation_OneArgs = {
  object: Task_Translation_Insert_Input;
  on_conflict?: Maybe<Task_Translation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TasksArgs = {
  objects: Array<Tasks_Insert_Input>;
  on_conflict?: Maybe<Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tasks_OneArgs = {
  object: Tasks_Insert_Input;
  on_conflict?: Maybe<Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tool_VersionsArgs = {
  objects: Array<Tool_Versions_Insert_Input>;
  on_conflict?: Maybe<Tool_Versions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tool_Versions_OneArgs = {
  object: Tool_Versions_Insert_Input;
  on_conflict?: Maybe<Tool_Versions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ToolsArgs = {
  objects: Array<Tools_Insert_Input>;
  on_conflict?: Maybe<Tools_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tools_OneArgs = {
  object: Tools_Insert_Input;
  on_conflict?: Maybe<Tools_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TypesArgs = {
  objects: Array<Types_Insert_Input>;
  on_conflict?: Maybe<Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Types_OneArgs = {
  object: Types_Insert_Input;
  on_conflict?: Maybe<Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Xtensa_ConfigurationsArgs = {
  objects: Array<Xtensa_Configurations_Insert_Input>;
  on_conflict?: Maybe<Xtensa_Configurations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Xtensa_Configurations_OneArgs = {
  object: Xtensa_Configurations_Insert_Input;
  on_conflict?: Maybe<Xtensa_Configurations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_Audio_DriverArgs = {
  _set?: Maybe<Audio_Driver_Set_Input>;
  where: Audio_Driver_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Audio_Driver_By_PkArgs = {
  _set?: Maybe<Audio_Driver_Set_Input>;
  pk_columns: Audio_Driver_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Audio_Driver_ManyArgs = {
  updates: Array<Audio_Driver_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Audio_Measurements_ComponentsArgs = {
  _set?: Maybe<Audio_Measurements_Components_Set_Input>;
  where: Audio_Measurements_Components_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Audio_Measurements_Components_By_PkArgs = {
  _set?: Maybe<Audio_Measurements_Components_Set_Input>;
  pk_columns: Audio_Measurements_Components_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Audio_Measurements_Components_ManyArgs = {
  updates: Array<Audio_Measurements_Components_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Audio_Measurements_ConfigurationsArgs = {
  _set?: Maybe<Audio_Measurements_Configurations_Set_Input>;
  where: Audio_Measurements_Configurations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Audio_Measurements_Configurations_By_PkArgs = {
  _set?: Maybe<Audio_Measurements_Configurations_Set_Input>;
  pk_columns: Audio_Measurements_Configurations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Audio_Measurements_Configurations_ManyArgs = {
  updates: Array<Audio_Measurements_Configurations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Audio_Measurements_Master_FilesArgs = {
  _inc?: Maybe<Audio_Measurements_Master_Files_Inc_Input>;
  _set?: Maybe<Audio_Measurements_Master_Files_Set_Input>;
  where: Audio_Measurements_Master_Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Audio_Measurements_Master_Files_By_PkArgs = {
  _inc?: Maybe<Audio_Measurements_Master_Files_Inc_Input>;
  _set?: Maybe<Audio_Measurements_Master_Files_Set_Input>;
  pk_columns: Audio_Measurements_Master_Files_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Audio_Measurements_Master_Files_ManyArgs = {
  updates: Array<Audio_Measurements_Master_Files_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Audio_Measurements_OrientationsArgs = {
  _set?: Maybe<Audio_Measurements_Orientations_Set_Input>;
  where: Audio_Measurements_Orientations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Audio_Measurements_Orientations_By_PkArgs = {
  _set?: Maybe<Audio_Measurements_Orientations_Set_Input>;
  pk_columns: Audio_Measurements_Orientations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Audio_Measurements_Orientations_ManyArgs = {
  updates: Array<Audio_Measurements_Orientations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Audio_Measurements_Payload_RecordingArgs = {
  _set?: Maybe<Audio_Measurements_Payload_Recording_Set_Input>;
  where: Audio_Measurements_Payload_Recording_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Audio_Measurements_Payload_Recording_By_PkArgs = {
  _set?: Maybe<Audio_Measurements_Payload_Recording_Set_Input>;
  pk_columns: Audio_Measurements_Payload_Recording_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Audio_Measurements_Payload_Recording_ManyArgs = {
  updates: Array<Audio_Measurements_Payload_Recording_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Audio_Measurements_PlacementsArgs = {
  _set?: Maybe<Audio_Measurements_Placements_Set_Input>;
  where: Audio_Measurements_Placements_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Audio_Measurements_Placements_By_PkArgs = {
  _set?: Maybe<Audio_Measurements_Placements_Set_Input>;
  pk_columns: Audio_Measurements_Placements_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Audio_Measurements_Placements_ManyArgs = {
  updates: Array<Audio_Measurements_Placements_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Audio_Measurements_Project_TasksArgs = {
  _inc?: Maybe<Audio_Measurements_Project_Tasks_Inc_Input>;
  _set?: Maybe<Audio_Measurements_Project_Tasks_Set_Input>;
  where: Audio_Measurements_Project_Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Audio_Measurements_Project_Tasks_By_PkArgs = {
  _inc?: Maybe<Audio_Measurements_Project_Tasks_Inc_Input>;
  _set?: Maybe<Audio_Measurements_Project_Tasks_Set_Input>;
  pk_columns: Audio_Measurements_Project_Tasks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Audio_Measurements_Project_Tasks_ManyArgs = {
  updates: Array<Audio_Measurements_Project_Tasks_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Audio_Measurements_RecordingsArgs = {
  _inc?: Maybe<Audio_Measurements_Recordings_Inc_Input>;
  _set?: Maybe<Audio_Measurements_Recordings_Set_Input>;
  where: Audio_Measurements_Recordings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Audio_Measurements_Recordings_By_PkArgs = {
  _inc?: Maybe<Audio_Measurements_Recordings_Inc_Input>;
  _set?: Maybe<Audio_Measurements_Recordings_Set_Input>;
  pk_columns: Audio_Measurements_Recordings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Audio_Measurements_Recordings_ManyArgs = {
  updates: Array<Audio_Measurements_Recordings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Audio_Measurements_StagesArgs = {
  _set?: Maybe<Audio_Measurements_Stages_Set_Input>;
  where: Audio_Measurements_Stages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Audio_Measurements_Stages_By_PkArgs = {
  _set?: Maybe<Audio_Measurements_Stages_Set_Input>;
  pk_columns: Audio_Measurements_Stages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Audio_Measurements_Stages_ManyArgs = {
  updates: Array<Audio_Measurements_Stages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bringup_Checklist_DeliverableArgs = {
  _set?: Maybe<Bringup_Checklist_Deliverable_Set_Input>;
  where: Bringup_Checklist_Deliverable_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bringup_Checklist_Deliverable_By_PkArgs = {
  _set?: Maybe<Bringup_Checklist_Deliverable_Set_Input>;
  pk_columns: Bringup_Checklist_Deliverable_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bringup_Checklist_Deliverable_ManyArgs = {
  updates: Array<Bringup_Checklist_Deliverable_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bringup_ChecklistsArgs = {
  _set?: Maybe<Bringup_Checklists_Set_Input>;
  where: Bringup_Checklists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bringup_Checklists_By_PkArgs = {
  _set?: Maybe<Bringup_Checklists_Set_Input>;
  pk_columns: Bringup_Checklists_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bringup_Checklists_ManyArgs = {
  updates: Array<Bringup_Checklists_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bringup_EndorsementsArgs = {
  _set?: Maybe<Bringup_Endorsements_Set_Input>;
  where: Bringup_Endorsements_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bringup_Endorsements_By_PkArgs = {
  _set?: Maybe<Bringup_Endorsements_Set_Input>;
  pk_columns: Bringup_Endorsements_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bringup_Endorsements_ManyArgs = {
  updates: Array<Bringup_Endorsements_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bringup_ItemsArgs = {
  _inc?: Maybe<Bringup_Items_Inc_Input>;
  _set?: Maybe<Bringup_Items_Set_Input>;
  where: Bringup_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bringup_Items_By_PkArgs = {
  _inc?: Maybe<Bringup_Items_Inc_Input>;
  _set?: Maybe<Bringup_Items_Set_Input>;
  pk_columns: Bringup_Items_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bringup_Items_ManyArgs = {
  updates: Array<Bringup_Items_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bringup_MilestonesArgs = {
  _set?: Maybe<Bringup_Milestones_Set_Input>;
  where: Bringup_Milestones_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bringup_Milestones_By_PkArgs = {
  _set?: Maybe<Bringup_Milestones_Set_Input>;
  pk_columns: Bringup_Milestones_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bringup_Milestones_ManyArgs = {
  updates: Array<Bringup_Milestones_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bringup_Project_Item_CommentsArgs = {
  _set?: Maybe<Bringup_Project_Item_Comments_Set_Input>;
  where: Bringup_Project_Item_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bringup_Project_Item_Comments_By_PkArgs = {
  _set?: Maybe<Bringup_Project_Item_Comments_Set_Input>;
  pk_columns: Bringup_Project_Item_Comments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bringup_Project_Item_Comments_ManyArgs = {
  updates: Array<Bringup_Project_Item_Comments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CategoriesArgs = {
  _inc?: Maybe<Categories_Inc_Input>;
  _set?: Maybe<Categories_Set_Input>;
  where: Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Categories_By_PkArgs = {
  _inc?: Maybe<Categories_Inc_Input>;
  _set?: Maybe<Categories_Set_Input>;
  pk_columns: Categories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Categories_ManyArgs = {
  updates: Array<Categories_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Context_Display_FieldArgs = {
  _inc?: Maybe<Context_Display_Field_Inc_Input>;
  _set?: Maybe<Context_Display_Field_Set_Input>;
  where: Context_Display_Field_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Context_Display_Field_By_PkArgs = {
  _inc?: Maybe<Context_Display_Field_Inc_Input>;
  _set?: Maybe<Context_Display_Field_Set_Input>;
  pk_columns: Context_Display_Field_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Context_Display_Field_ManyArgs = {
  updates: Array<Context_Display_Field_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Context_DisplaysArgs = {
  _inc?: Maybe<Context_Displays_Inc_Input>;
  _set?: Maybe<Context_Displays_Set_Input>;
  where: Context_Displays_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Context_Displays_By_PkArgs = {
  _inc?: Maybe<Context_Displays_Inc_Input>;
  _set?: Maybe<Context_Displays_Set_Input>;
  pk_columns: Context_Displays_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Context_Displays_ManyArgs = {
  updates: Array<Context_Displays_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContextsArgs = {
  _set?: Maybe<Contexts_Set_Input>;
  where: Contexts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contexts_By_PkArgs = {
  _set?: Maybe<Contexts_Set_Input>;
  pk_columns: Contexts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contexts_ManyArgs = {
  updates: Array<Contexts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CustomersArgs = {
  _set?: Maybe<Customers_Set_Input>;
  where: Customers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Customers_By_PkArgs = {
  _set?: Maybe<Customers_Set_Input>;
  pk_columns: Customers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Customers_ManyArgs = {
  updates: Array<Customers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DeliverablesArgs = {
  _inc?: Maybe<Deliverables_Inc_Input>;
  _set?: Maybe<Deliverables_Set_Input>;
  where: Deliverables_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deliverables_By_PkArgs = {
  _inc?: Maybe<Deliverables_Inc_Input>;
  _set?: Maybe<Deliverables_Set_Input>;
  pk_columns: Deliverables_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Deliverables_ManyArgs = {
  updates: Array<Deliverables_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_ArchitecturesArgs = {
  _set?: Maybe<Deliveries_Architectures_Set_Input>;
  where: Deliveries_Architectures_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Architectures_By_PkArgs = {
  _set?: Maybe<Deliveries_Architectures_Set_Input>;
  pk_columns: Deliveries_Architectures_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Architectures_ManyArgs = {
  updates: Array<Deliveries_Architectures_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Artifact_Microsoft_Whql_SubmissionArgs = {
  _set?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission_Set_Input>;
  where: Deliveries_Artifact_Microsoft_Whql_Submission_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Artifact_Microsoft_Whql_Submission_By_PkArgs = {
  _set?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission_Set_Input>;
  pk_columns: Deliveries_Artifact_Microsoft_Whql_Submission_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Artifact_Microsoft_Whql_Submission_ManyArgs = {
  updates: Array<Deliveries_Artifact_Microsoft_Whql_Submission_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Artifact_Operating_SystemArgs = {
  _set?: Maybe<Deliveries_Artifact_Operating_System_Set_Input>;
  where: Deliveries_Artifact_Operating_System_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Artifact_Operating_System_By_PkArgs = {
  _set?: Maybe<Deliveries_Artifact_Operating_System_Set_Input>;
  pk_columns: Deliveries_Artifact_Operating_System_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Artifact_Operating_System_ManyArgs = {
  updates: Array<Deliveries_Artifact_Operating_System_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_ArtifactsArgs = {
  _set?: Maybe<Deliveries_Artifacts_Set_Input>;
  where: Deliveries_Artifacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Artifacts_By_PkArgs = {
  _set?: Maybe<Deliveries_Artifacts_Set_Input>;
  pk_columns: Deliveries_Artifacts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Artifacts_ManyArgs = {
  updates: Array<Deliveries_Artifacts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Device_FamiliesArgs = {
  _set?: Maybe<Deliveries_Device_Families_Set_Input>;
  where: Deliveries_Device_Families_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Device_Families_By_PkArgs = {
  _set?: Maybe<Deliveries_Device_Families_Set_Input>;
  pk_columns: Deliveries_Device_Families_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Device_Families_ManyArgs = {
  updates: Array<Deliveries_Device_Families_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Driver_PackageArgs = {
  _set?: Maybe<Deliveries_Driver_Package_Set_Input>;
  where: Deliveries_Driver_Package_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Driver_Package_By_PkArgs = {
  _set?: Maybe<Deliveries_Driver_Package_Set_Input>;
  pk_columns: Deliveries_Driver_Package_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Driver_Package_ManyArgs = {
  updates: Array<Deliveries_Driver_Package_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Driver_ProjectArgs = {
  _set?: Maybe<Deliveries_Driver_Project_Set_Input>;
  where: Deliveries_Driver_Project_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Driver_Project_By_PkArgs = {
  _set?: Maybe<Deliveries_Driver_Project_Set_Input>;
  pk_columns: Deliveries_Driver_Project_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Driver_Project_ManyArgs = {
  updates: Array<Deliveries_Driver_Project_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Driver_Version_EngineArgs = {
  _set?: Maybe<Deliveries_Driver_Version_Engine_Set_Input>;
  where: Deliveries_Driver_Version_Engine_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Driver_Version_Engine_By_PkArgs = {
  _set?: Maybe<Deliveries_Driver_Version_Engine_Set_Input>;
  pk_columns: Deliveries_Driver_Version_Engine_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Driver_Version_Engine_ManyArgs = {
  updates: Array<Deliveries_Driver_Version_Engine_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Driver_Version_PackageArgs = {
  _set?: Maybe<Deliveries_Driver_Version_Package_Set_Input>;
  where: Deliveries_Driver_Version_Package_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Driver_Version_Package_By_PkArgs = {
  _set?: Maybe<Deliveries_Driver_Version_Package_Set_Input>;
  pk_columns: Deliveries_Driver_Version_Package_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Driver_Version_Package_ManyArgs = {
  updates: Array<Deliveries_Driver_Version_Package_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Driver_VersionsArgs = {
  _set?: Maybe<Deliveries_Driver_Versions_Set_Input>;
  where: Deliveries_Driver_Versions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Driver_Versions_By_PkArgs = {
  _set?: Maybe<Deliveries_Driver_Versions_Set_Input>;
  pk_columns: Deliveries_Driver_Versions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Driver_Versions_ManyArgs = {
  updates: Array<Deliveries_Driver_Versions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_DriversArgs = {
  _set?: Maybe<Deliveries_Drivers_Set_Input>;
  where: Deliveries_Drivers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Drivers_By_PkArgs = {
  _set?: Maybe<Deliveries_Drivers_Set_Input>;
  pk_columns: Deliveries_Drivers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Drivers_ManyArgs = {
  updates: Array<Deliveries_Drivers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Microsoft_Hlk_ArtifactsArgs = {
  _set?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_Set_Input>;
  where: Deliveries_Microsoft_Hlk_Artifacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Microsoft_Hlk_Artifacts_By_PkArgs = {
  _set?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_Set_Input>;
  pk_columns: Deliveries_Microsoft_Hlk_Artifacts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Microsoft_Hlk_Artifacts_ManyArgs = {
  updates: Array<Deliveries_Microsoft_Hlk_Artifacts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Microsoft_Shipping_Label_ReviewsArgs = {
  _set?: Maybe<Deliveries_Microsoft_Shipping_Label_Reviews_Set_Input>;
  where: Deliveries_Microsoft_Shipping_Label_Reviews_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Microsoft_Shipping_Label_Reviews_By_PkArgs = {
  _set?: Maybe<Deliveries_Microsoft_Shipping_Label_Reviews_Set_Input>;
  pk_columns: Deliveries_Microsoft_Shipping_Label_Reviews_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Microsoft_Shipping_Label_Reviews_ManyArgs = {
  updates: Array<Deliveries_Microsoft_Shipping_Label_Reviews_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Microsoft_Shipping_LabelsArgs = {
  _append?: Maybe<Deliveries_Microsoft_Shipping_Labels_Append_Input>;
  _delete_at_path?: Maybe<Deliveries_Microsoft_Shipping_Labels_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Deliveries_Microsoft_Shipping_Labels_Delete_Elem_Input>;
  _delete_key?: Maybe<Deliveries_Microsoft_Shipping_Labels_Delete_Key_Input>;
  _prepend?: Maybe<Deliveries_Microsoft_Shipping_Labels_Prepend_Input>;
  _set?: Maybe<Deliveries_Microsoft_Shipping_Labels_Set_Input>;
  where: Deliveries_Microsoft_Shipping_Labels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Microsoft_Shipping_Labels_By_PkArgs = {
  _append?: Maybe<Deliveries_Microsoft_Shipping_Labels_Append_Input>;
  _delete_at_path?: Maybe<Deliveries_Microsoft_Shipping_Labels_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Deliveries_Microsoft_Shipping_Labels_Delete_Elem_Input>;
  _delete_key?: Maybe<Deliveries_Microsoft_Shipping_Labels_Delete_Key_Input>;
  _prepend?: Maybe<Deliveries_Microsoft_Shipping_Labels_Prepend_Input>;
  _set?: Maybe<Deliveries_Microsoft_Shipping_Labels_Set_Input>;
  pk_columns: Deliveries_Microsoft_Shipping_Labels_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Microsoft_Shipping_Labels_ManyArgs = {
  updates: Array<Deliveries_Microsoft_Shipping_Labels_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Microsoft_Whql_SubmissionsArgs = {
  _set?: Maybe<Deliveries_Microsoft_Whql_Submissions_Set_Input>;
  where: Deliveries_Microsoft_Whql_Submissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Microsoft_Whql_Submissions_By_PkArgs = {
  _set?: Maybe<Deliveries_Microsoft_Whql_Submissions_Set_Input>;
  pk_columns: Deliveries_Microsoft_Whql_Submissions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Microsoft_Whql_Submissions_ManyArgs = {
  updates: Array<Deliveries_Microsoft_Whql_Submissions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Operating_SystemsArgs = {
  _set?: Maybe<Deliveries_Operating_Systems_Set_Input>;
  where: Deliveries_Operating_Systems_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Operating_Systems_By_PkArgs = {
  _set?: Maybe<Deliveries_Operating_Systems_Set_Input>;
  pk_columns: Deliveries_Operating_Systems_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Operating_Systems_ManyArgs = {
  updates: Array<Deliveries_Operating_Systems_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Package_Operating_SystemsArgs = {
  _set?: Maybe<Deliveries_Package_Operating_Systems_Set_Input>;
  where: Deliveries_Package_Operating_Systems_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Package_Operating_Systems_By_PkArgs = {
  _set?: Maybe<Deliveries_Package_Operating_Systems_Set_Input>;
  pk_columns: Deliveries_Package_Operating_Systems_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Package_Operating_Systems_ManyArgs = {
  updates: Array<Deliveries_Package_Operating_Systems_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Package_TargetArgs = {
  _set?: Maybe<Deliveries_Package_Target_Set_Input>;
  where: Deliveries_Package_Target_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Package_Target_By_PkArgs = {
  _set?: Maybe<Deliveries_Package_Target_Set_Input>;
  pk_columns: Deliveries_Package_Target_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Package_Target_ManyArgs = {
  updates: Array<Deliveries_Package_Target_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_PackagesArgs = {
  _inc?: Maybe<Deliveries_Packages_Inc_Input>;
  _set?: Maybe<Deliveries_Packages_Set_Input>;
  where: Deliveries_Packages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Packages_By_PkArgs = {
  _inc?: Maybe<Deliveries_Packages_Inc_Input>;
  _set?: Maybe<Deliveries_Packages_Set_Input>;
  pk_columns: Deliveries_Packages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Packages_ManyArgs = {
  updates: Array<Deliveries_Packages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_PartnersArgs = {
  _set?: Maybe<Deliveries_Partners_Set_Input>;
  where: Deliveries_Partners_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Partners_By_PkArgs = {
  _set?: Maybe<Deliveries_Partners_Set_Input>;
  pk_columns: Deliveries_Partners_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Partners_ManyArgs = {
  updates: Array<Deliveries_Partners_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_PlatformsArgs = {
  _set?: Maybe<Deliveries_Platforms_Set_Input>;
  where: Deliveries_Platforms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Platforms_By_PkArgs = {
  _set?: Maybe<Deliveries_Platforms_Set_Input>;
  pk_columns: Deliveries_Platforms_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Platforms_ManyArgs = {
  updates: Array<Deliveries_Platforms_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Project_ConfigsArgs = {
  _inc?: Maybe<Deliveries_Project_Configs_Inc_Input>;
  _set?: Maybe<Deliveries_Project_Configs_Set_Input>;
  where: Deliveries_Project_Configs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Project_Configs_By_PkArgs = {
  _inc?: Maybe<Deliveries_Project_Configs_Inc_Input>;
  _set?: Maybe<Deliveries_Project_Configs_Set_Input>;
  pk_columns: Deliveries_Project_Configs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Project_Configs_ManyArgs = {
  updates: Array<Deliveries_Project_Configs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Target_PartnerArgs = {
  _set?: Maybe<Deliveries_Target_Partner_Set_Input>;
  where: Deliveries_Target_Partner_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Target_Partner_By_PkArgs = {
  _set?: Maybe<Deliveries_Target_Partner_Set_Input>;
  pk_columns: Deliveries_Target_Partner_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Target_Partner_ManyArgs = {
  updates: Array<Deliveries_Target_Partner_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_TargetsArgs = {
  _set?: Maybe<Deliveries_Targets_Set_Input>;
  where: Deliveries_Targets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Targets_By_PkArgs = {
  _set?: Maybe<Deliveries_Targets_Set_Input>;
  pk_columns: Deliveries_Targets_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_Targets_ManyArgs = {
  updates: Array<Deliveries_Targets_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Device_FirmwareArgs = {
  _set?: Maybe<Device_Firmware_Set_Input>;
  where: Device_Firmware_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Device_Firmware_By_PkArgs = {
  _set?: Maybe<Device_Firmware_Set_Input>;
  pk_columns: Device_Firmware_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Device_Firmware_ManyArgs = {
  updates: Array<Device_Firmware_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Device_ProjectArgs = {
  _set?: Maybe<Device_Project_Set_Input>;
  where: Device_Project_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Device_Project_By_PkArgs = {
  _set?: Maybe<Device_Project_Set_Input>;
  pk_columns: Device_Project_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Device_Project_ManyArgs = {
  updates: Array<Device_Project_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DevicesArgs = {
  _set?: Maybe<Devices_Set_Input>;
  where: Devices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Devices_By_PkArgs = {
  _set?: Maybe<Devices_Set_Input>;
  pk_columns: Devices_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Devices_ManyArgs = {
  updates: Array<Devices_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Dynamic_ScalingArgs = {
  _inc?: Maybe<Dynamic_Scaling_Inc_Input>;
  _set?: Maybe<Dynamic_Scaling_Set_Input>;
  where: Dynamic_Scaling_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dynamic_Scaling_By_PkArgs = {
  _inc?: Maybe<Dynamic_Scaling_Inc_Input>;
  _set?: Maybe<Dynamic_Scaling_Set_Input>;
  pk_columns: Dynamic_Scaling_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dynamic_Scaling_ManyArgs = {
  updates: Array<Dynamic_Scaling_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Engine_OpmodeArgs = {
  _set?: Maybe<Engine_Opmode_Set_Input>;
  where: Engine_Opmode_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Engine_Opmode_By_PkArgs = {
  _set?: Maybe<Engine_Opmode_Set_Input>;
  pk_columns: Engine_Opmode_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Engine_Opmode_ManyArgs = {
  updates: Array<Engine_Opmode_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Engine_Opmode_RecordingArgs = {
  _set?: Maybe<Engine_Opmode_Recording_Set_Input>;
  where: Engine_Opmode_Recording_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Engine_Opmode_Recording_By_PkArgs = {
  _set?: Maybe<Engine_Opmode_Recording_Set_Input>;
  pk_columns: Engine_Opmode_Recording_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Engine_Opmode_Recording_ManyArgs = {
  updates: Array<Engine_Opmode_Recording_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Engine_TypesArgs = {
  _set?: Maybe<Engine_Types_Set_Input>;
  where: Engine_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Engine_Types_By_PkArgs = {
  _set?: Maybe<Engine_Types_Set_Input>;
  pk_columns: Engine_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Engine_Types_ManyArgs = {
  updates: Array<Engine_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EnginesArgs = {
  _inc?: Maybe<Engines_Inc_Input>;
  _set?: Maybe<Engines_Set_Input>;
  where: Engines_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Engines_By_PkArgs = {
  _inc?: Maybe<Engines_Inc_Input>;
  _set?: Maybe<Engines_Set_Input>;
  pk_columns: Engines_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Engines_ManyArgs = {
  updates: Array<Engines_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Field_Option_MediaArgs = {
  _set?: Maybe<Field_Option_Media_Set_Input>;
  where: Field_Option_Media_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Field_Option_Media_By_PkArgs = {
  _set?: Maybe<Field_Option_Media_Set_Input>;
  pk_columns: Field_Option_Media_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Field_Option_Media_ManyArgs = {
  updates: Array<Field_Option_Media_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Field_OptionsArgs = {
  _set?: Maybe<Field_Options_Set_Input>;
  where: Field_Options_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Field_Options_By_PkArgs = {
  _set?: Maybe<Field_Options_Set_Input>;
  pk_columns: Field_Options_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Field_Options_ManyArgs = {
  updates: Array<Field_Options_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Field_TranslationArgs = {
  _set?: Maybe<Field_Translation_Set_Input>;
  where: Field_Translation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Field_Translation_By_PkArgs = {
  _set?: Maybe<Field_Translation_Set_Input>;
  pk_columns: Field_Translation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Field_Translation_ManyArgs = {
  updates: Array<Field_Translation_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FieldsArgs = {
  _set?: Maybe<Fields_Set_Input>;
  where: Fields_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Fields_By_PkArgs = {
  _set?: Maybe<Fields_Set_Input>;
  pk_columns: Fields_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Fields_ManyArgs = {
  updates: Array<Fields_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FirmwareArgs = {
  _append?: Maybe<Firmware_Append_Input>;
  _delete_at_path?: Maybe<Firmware_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Firmware_Delete_Elem_Input>;
  _delete_key?: Maybe<Firmware_Delete_Key_Input>;
  _prepend?: Maybe<Firmware_Prepend_Input>;
  _set?: Maybe<Firmware_Set_Input>;
  where: Firmware_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Firmware_By_PkArgs = {
  _append?: Maybe<Firmware_Append_Input>;
  _delete_at_path?: Maybe<Firmware_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Firmware_Delete_Elem_Input>;
  _delete_key?: Maybe<Firmware_Delete_Key_Input>;
  _prepend?: Maybe<Firmware_Prepend_Input>;
  _set?: Maybe<Firmware_Set_Input>;
  pk_columns: Firmware_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Firmware_ManyArgs = {
  updates: Array<Firmware_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ground_Truth_ConfigurationsArgs = {
  _append?: Maybe<Ground_Truth_Configurations_Append_Input>;
  _delete_at_path?: Maybe<Ground_Truth_Configurations_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Ground_Truth_Configurations_Delete_Elem_Input>;
  _delete_key?: Maybe<Ground_Truth_Configurations_Delete_Key_Input>;
  _prepend?: Maybe<Ground_Truth_Configurations_Prepend_Input>;
  _set?: Maybe<Ground_Truth_Configurations_Set_Input>;
  where: Ground_Truth_Configurations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ground_Truth_Configurations_By_PkArgs = {
  _append?: Maybe<Ground_Truth_Configurations_Append_Input>;
  _delete_at_path?: Maybe<Ground_Truth_Configurations_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Ground_Truth_Configurations_Delete_Elem_Input>;
  _delete_key?: Maybe<Ground_Truth_Configurations_Delete_Key_Input>;
  _prepend?: Maybe<Ground_Truth_Configurations_Prepend_Input>;
  _set?: Maybe<Ground_Truth_Configurations_Set_Input>;
  pk_columns: Ground_Truth_Configurations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ground_Truth_Configurations_ManyArgs = {
  updates: Array<Ground_Truth_Configurations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ground_Truth_Device_TypesArgs = {
  _set?: Maybe<Ground_Truth_Device_Types_Set_Input>;
  where: Ground_Truth_Device_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ground_Truth_Device_Types_By_PkArgs = {
  _set?: Maybe<Ground_Truth_Device_Types_Set_Input>;
  pk_columns: Ground_Truth_Device_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ground_Truth_Device_Types_ManyArgs = {
  updates: Array<Ground_Truth_Device_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ground_Truth_Processing_ModesArgs = {
  _set?: Maybe<Ground_Truth_Processing_Modes_Set_Input>;
  where: Ground_Truth_Processing_Modes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ground_Truth_Processing_Modes_By_PkArgs = {
  _set?: Maybe<Ground_Truth_Processing_Modes_Set_Input>;
  pk_columns: Ground_Truth_Processing_Modes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ground_Truth_Processing_Modes_ManyArgs = {
  updates: Array<Ground_Truth_Processing_Modes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ground_Truth_RecordingsArgs = {
  _inc?: Maybe<Ground_Truth_Recordings_Inc_Input>;
  _set?: Maybe<Ground_Truth_Recordings_Set_Input>;
  where: Ground_Truth_Recordings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ground_Truth_Recordings_By_PkArgs = {
  _inc?: Maybe<Ground_Truth_Recordings_Inc_Input>;
  _set?: Maybe<Ground_Truth_Recordings_Set_Input>;
  pk_columns: Ground_Truth_Recordings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ground_Truth_Recordings_ManyArgs = {
  updates: Array<Ground_Truth_Recordings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ground_Truth_TypesArgs = {
  _inc?: Maybe<Ground_Truth_Types_Inc_Input>;
  _set?: Maybe<Ground_Truth_Types_Set_Input>;
  where: Ground_Truth_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ground_Truth_Types_By_PkArgs = {
  _inc?: Maybe<Ground_Truth_Types_Inc_Input>;
  _set?: Maybe<Ground_Truth_Types_Set_Input>;
  pk_columns: Ground_Truth_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ground_Truth_Types_ManyArgs = {
  updates: Array<Ground_Truth_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Hypertask_Field_OptionArgs = {
  _inc?: Maybe<Hypertask_Field_Option_Inc_Input>;
  _set?: Maybe<Hypertask_Field_Option_Set_Input>;
  where: Hypertask_Field_Option_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Hypertask_Field_Option_By_PkArgs = {
  _inc?: Maybe<Hypertask_Field_Option_Inc_Input>;
  _set?: Maybe<Hypertask_Field_Option_Set_Input>;
  pk_columns: Hypertask_Field_Option_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Hypertask_Field_Option_ManyArgs = {
  updates: Array<Hypertask_Field_Option_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Hypertask_TaskArgs = {
  _set?: Maybe<Hypertask_Task_Set_Input>;
  where: Hypertask_Task_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Hypertask_Task_By_PkArgs = {
  _set?: Maybe<Hypertask_Task_Set_Input>;
  pk_columns: Hypertask_Task_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Hypertask_Task_ManyArgs = {
  updates: Array<Hypertask_Task_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_HypertasksArgs = {
  _inc?: Maybe<Hypertasks_Inc_Input>;
  _set?: Maybe<Hypertasks_Set_Input>;
  where: Hypertasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Hypertasks_By_PkArgs = {
  _inc?: Maybe<Hypertasks_Inc_Input>;
  _set?: Maybe<Hypertasks_Set_Input>;
  pk_columns: Hypertasks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Hypertasks_ManyArgs = {
  updates: Array<Hypertasks_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Instruction_TranslationsArgs = {
  _set?: Maybe<Instruction_Translations_Set_Input>;
  where: Instruction_Translations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Instruction_Translations_By_PkArgs = {
  _set?: Maybe<Instruction_Translations_Set_Input>;
  pk_columns: Instruction_Translations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Instruction_Translations_ManyArgs = {
  updates: Array<Instruction_Translations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Integration_Test_Expectation_MediaArgs = {
  _set?: Maybe<Integration_Test_Expectation_Media_Set_Input>;
  where: Integration_Test_Expectation_Media_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Integration_Test_Expectation_Media_By_PkArgs = {
  _set?: Maybe<Integration_Test_Expectation_Media_Set_Input>;
  pk_columns: Integration_Test_Expectation_Media_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Integration_Test_Expectation_Media_ManyArgs = {
  updates: Array<Integration_Test_Expectation_Media_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Integration_Test_Expectation_TranslationArgs = {
  _set?: Maybe<Integration_Test_Expectation_Translation_Set_Input>;
  where: Integration_Test_Expectation_Translation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Integration_Test_Expectation_Translation_By_PkArgs = {
  _set?: Maybe<Integration_Test_Expectation_Translation_Set_Input>;
  pk_columns: Integration_Test_Expectation_Translation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Integration_Test_Expectation_Translation_ManyArgs = {
  updates: Array<Integration_Test_Expectation_Translation_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Integration_Test_ExpectationsArgs = {
  _set?: Maybe<Integration_Test_Expectations_Set_Input>;
  where: Integration_Test_Expectations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Integration_Test_Expectations_By_PkArgs = {
  _set?: Maybe<Integration_Test_Expectations_Set_Input>;
  pk_columns: Integration_Test_Expectations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Integration_Test_Expectations_ManyArgs = {
  updates: Array<Integration_Test_Expectations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Integration_Test_Instruction_MediaArgs = {
  _set?: Maybe<Integration_Test_Instruction_Media_Set_Input>;
  where: Integration_Test_Instruction_Media_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Integration_Test_Instruction_Media_By_PkArgs = {
  _set?: Maybe<Integration_Test_Instruction_Media_Set_Input>;
  pk_columns: Integration_Test_Instruction_Media_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Integration_Test_Instruction_Media_ManyArgs = {
  updates: Array<Integration_Test_Instruction_Media_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Integration_Test_Instruction_TranslationArgs = {
  _set?: Maybe<Integration_Test_Instruction_Translation_Set_Input>;
  where: Integration_Test_Instruction_Translation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Integration_Test_Instruction_Translation_By_PkArgs = {
  _set?: Maybe<Integration_Test_Instruction_Translation_Set_Input>;
  pk_columns: Integration_Test_Instruction_Translation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Integration_Test_Instruction_Translation_ManyArgs = {
  updates: Array<Integration_Test_Instruction_Translation_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Integration_Test_InstructionsArgs = {
  _set?: Maybe<Integration_Test_Instructions_Set_Input>;
  where: Integration_Test_Instructions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Integration_Test_Instructions_By_PkArgs = {
  _set?: Maybe<Integration_Test_Instructions_Set_Input>;
  pk_columns: Integration_Test_Instructions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Integration_Test_Instructions_ManyArgs = {
  updates: Array<Integration_Test_Instructions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Integration_Test_ObservancesArgs = {
  _set?: Maybe<Integration_Test_Observances_Set_Input>;
  where: Integration_Test_Observances_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Integration_Test_Observances_By_PkArgs = {
  _set?: Maybe<Integration_Test_Observances_Set_Input>;
  pk_columns: Integration_Test_Observances_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Integration_Test_Observances_ManyArgs = {
  updates: Array<Integration_Test_Observances_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Integration_Test_Project_SequenceArgs = {
  _set?: Maybe<Integration_Test_Project_Sequence_Set_Input>;
  where: Integration_Test_Project_Sequence_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Integration_Test_Project_Sequence_By_PkArgs = {
  _set?: Maybe<Integration_Test_Project_Sequence_Set_Input>;
  pk_columns: Integration_Test_Project_Sequence_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Integration_Test_Project_Sequence_ManyArgs = {
  updates: Array<Integration_Test_Project_Sequence_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Integration_Test_SequencesArgs = {
  _set?: Maybe<Integration_Test_Sequences_Set_Input>;
  where: Integration_Test_Sequences_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Integration_Test_Sequences_By_PkArgs = {
  _set?: Maybe<Integration_Test_Sequences_Set_Input>;
  pk_columns: Integration_Test_Sequences_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Integration_Test_Sequences_ManyArgs = {
  updates: Array<Integration_Test_Sequences_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LanguagesArgs = {
  _set?: Maybe<Languages_Set_Input>;
  where: Languages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Languages_By_PkArgs = {
  _set?: Maybe<Languages_Set_Input>;
  pk_columns: Languages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Languages_ManyArgs = {
  updates: Array<Languages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Machine_Learning_BlobsArgs = {
  _inc?: Maybe<Machine_Learning_Blobs_Inc_Input>;
  _set?: Maybe<Machine_Learning_Blobs_Set_Input>;
  where: Machine_Learning_Blobs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Machine_Learning_Blobs_By_PkArgs = {
  _inc?: Maybe<Machine_Learning_Blobs_Inc_Input>;
  _set?: Maybe<Machine_Learning_Blobs_Set_Input>;
  pk_columns: Machine_Learning_Blobs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Machine_Learning_Blobs_ManyArgs = {
  updates: Array<Machine_Learning_Blobs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Machine_Learning_Dataset_FeatureArgs = {
  _set?: Maybe<Machine_Learning_Dataset_Feature_Set_Input>;
  where: Machine_Learning_Dataset_Feature_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Machine_Learning_Dataset_Feature_By_PkArgs = {
  _set?: Maybe<Machine_Learning_Dataset_Feature_Set_Input>;
  pk_columns: Machine_Learning_Dataset_Feature_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Machine_Learning_Dataset_Feature_ManyArgs = {
  updates: Array<Machine_Learning_Dataset_Feature_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Machine_Learning_DatasetsArgs = {
  _inc?: Maybe<Machine_Learning_Datasets_Inc_Input>;
  _set?: Maybe<Machine_Learning_Datasets_Set_Input>;
  where: Machine_Learning_Datasets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Machine_Learning_Datasets_By_PkArgs = {
  _inc?: Maybe<Machine_Learning_Datasets_Inc_Input>;
  _set?: Maybe<Machine_Learning_Datasets_Set_Input>;
  pk_columns: Machine_Learning_Datasets_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Machine_Learning_Datasets_ManyArgs = {
  updates: Array<Machine_Learning_Datasets_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Machine_Learning_FeaturesArgs = {
  _set?: Maybe<Machine_Learning_Features_Set_Input>;
  where: Machine_Learning_Features_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Machine_Learning_Features_By_PkArgs = {
  _set?: Maybe<Machine_Learning_Features_Set_Input>;
  pk_columns: Machine_Learning_Features_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Machine_Learning_Features_ManyArgs = {
  updates: Array<Machine_Learning_Features_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Machine_Learning_Model_BlobArgs = {
  _set?: Maybe<Machine_Learning_Model_Blob_Set_Input>;
  where: Machine_Learning_Model_Blob_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Machine_Learning_Model_Blob_By_PkArgs = {
  _set?: Maybe<Machine_Learning_Model_Blob_Set_Input>;
  pk_columns: Machine_Learning_Model_Blob_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Machine_Learning_Model_Blob_ManyArgs = {
  updates: Array<Machine_Learning_Model_Blob_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Machine_Learning_Model_DatasetArgs = {
  _set?: Maybe<Machine_Learning_Model_Dataset_Set_Input>;
  where: Machine_Learning_Model_Dataset_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Machine_Learning_Model_Dataset_By_PkArgs = {
  _set?: Maybe<Machine_Learning_Model_Dataset_Set_Input>;
  pk_columns: Machine_Learning_Model_Dataset_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Machine_Learning_Model_Dataset_ManyArgs = {
  updates: Array<Machine_Learning_Model_Dataset_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Machine_Learning_Model_RecordingArgs = {
  _set?: Maybe<Machine_Learning_Model_Recording_Set_Input>;
  where: Machine_Learning_Model_Recording_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Machine_Learning_Model_Recording_By_PkArgs = {
  _set?: Maybe<Machine_Learning_Model_Recording_Set_Input>;
  pk_columns: Machine_Learning_Model_Recording_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Machine_Learning_Model_Recording_ManyArgs = {
  updates: Array<Machine_Learning_Model_Recording_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Machine_Learning_ModelsArgs = {
  _inc?: Maybe<Machine_Learning_Models_Inc_Input>;
  _set?: Maybe<Machine_Learning_Models_Set_Input>;
  where: Machine_Learning_Models_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Machine_Learning_Models_By_PkArgs = {
  _inc?: Maybe<Machine_Learning_Models_Inc_Input>;
  _set?: Maybe<Machine_Learning_Models_Set_Input>;
  pk_columns: Machine_Learning_Models_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Machine_Learning_Models_ManyArgs = {
  updates: Array<Machine_Learning_Models_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_MediaArgs = {
  _set?: Maybe<Media_Set_Input>;
  where: Media_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Media_By_PkArgs = {
  _set?: Maybe<Media_Set_Input>;
  pk_columns: Media_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Media_ManyArgs = {
  updates: Array<Media_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mode_ProjectArgs = {
  _set?: Maybe<Mode_Project_Set_Input>;
  where: Mode_Project_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mode_Project_By_PkArgs = {
  _set?: Maybe<Mode_Project_Set_Input>;
  pk_columns: Mode_Project_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mode_Project_ManyArgs = {
  updates: Array<Mode_Project_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Model_Name_HashesArgs = {
  _set?: Maybe<Model_Name_Hashes_Set_Input>;
  where: Model_Name_Hashes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Model_Name_Hashes_By_PkArgs = {
  _set?: Maybe<Model_Name_Hashes_Set_Input>;
  pk_columns: Model_Name_Hashes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Model_Name_Hashes_ManyArgs = {
  updates: Array<Model_Name_Hashes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ModelsArgs = {
  _set?: Maybe<Models_Set_Input>;
  where: Models_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Models_By_PkArgs = {
  _set?: Maybe<Models_Set_Input>;
  pk_columns: Models_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Models_ManyArgs = {
  updates: Array<Models_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Opmode_ProfileArgs = {
  _set?: Maybe<Opmode_Profile_Set_Input>;
  where: Opmode_Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Opmode_Profile_By_PkArgs = {
  _set?: Maybe<Opmode_Profile_Set_Input>;
  pk_columns: Opmode_Profile_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Opmode_Profile_ManyArgs = {
  updates: Array<Opmode_Profile_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Opmode_ProjectArgs = {
  _set?: Maybe<Opmode_Project_Set_Input>;
  where: Opmode_Project_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Opmode_Project_By_PkArgs = {
  _set?: Maybe<Opmode_Project_Set_Input>;
  pk_columns: Opmode_Project_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Opmode_Project_ManyArgs = {
  updates: Array<Opmode_Project_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_OpmodesArgs = {
  _inc?: Maybe<Opmodes_Inc_Input>;
  _set?: Maybe<Opmodes_Set_Input>;
  where: Opmodes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Opmodes_By_PkArgs = {
  _inc?: Maybe<Opmodes_Inc_Input>;
  _set?: Maybe<Opmodes_Set_Input>;
  pk_columns: Opmodes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Opmodes_ManyArgs = {
  updates: Array<Opmodes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Option_MediaArgs = {
  _set?: Maybe<Option_Media_Set_Input>;
  where: Option_Media_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Option_Media_By_PkArgs = {
  _set?: Maybe<Option_Media_Set_Input>;
  pk_columns: Option_Media_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Option_Media_ManyArgs = {
  updates: Array<Option_Media_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Option_TranslationArgs = {
  _set?: Maybe<Option_Translation_Set_Input>;
  where: Option_Translation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Option_Translation_By_PkArgs = {
  _set?: Maybe<Option_Translation_Set_Input>;
  pk_columns: Option_Translation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Option_Translation_ManyArgs = {
  updates: Array<Option_Translation_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_OptionsArgs = {
  _set?: Maybe<Options_Set_Input>;
  where: Options_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Options_By_PkArgs = {
  _set?: Maybe<Options_Set_Input>;
  pk_columns: Options_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Options_ManyArgs = {
  updates: Array<Options_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Payload_RecordingArgs = {
  _set?: Maybe<Payload_Recording_Set_Input>;
  where: Payload_Recording_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payload_Recording_By_PkArgs = {
  _set?: Maybe<Payload_Recording_Set_Input>;
  pk_columns: Payload_Recording_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payload_Recording_ManyArgs = {
  updates: Array<Payload_Recording_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Payload_TypesArgs = {
  _set?: Maybe<Payload_Types_Set_Input>;
  where: Payload_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payload_Types_By_PkArgs = {
  _set?: Maybe<Payload_Types_Set_Input>;
  pk_columns: Payload_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payload_Types_ManyArgs = {
  updates: Array<Payload_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PayloadsArgs = {
  _inc?: Maybe<Payloads_Inc_Input>;
  _set?: Maybe<Payloads_Set_Input>;
  where: Payloads_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payloads_By_PkArgs = {
  _inc?: Maybe<Payloads_Inc_Input>;
  _set?: Maybe<Payloads_Set_Input>;
  pk_columns: Payloads_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payloads_ManyArgs = {
  updates: Array<Payloads_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Performance_Ratio_Metric_ContributionsArgs = {
  _inc?: Maybe<Performance_Ratio_Metric_Contributions_Inc_Input>;
  _set?: Maybe<Performance_Ratio_Metric_Contributions_Set_Input>;
  where: Performance_Ratio_Metric_Contributions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Performance_Ratio_Metric_Contributions_By_PkArgs = {
  _inc?: Maybe<Performance_Ratio_Metric_Contributions_Inc_Input>;
  _set?: Maybe<Performance_Ratio_Metric_Contributions_Set_Input>;
  pk_columns: Performance_Ratio_Metric_Contributions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Performance_Ratio_Metric_Contributions_ManyArgs = {
  updates: Array<Performance_Ratio_Metric_Contributions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Performance_Ratio_MetricsArgs = {
  _inc?: Maybe<Performance_Ratio_Metrics_Inc_Input>;
  _set?: Maybe<Performance_Ratio_Metrics_Set_Input>;
  where: Performance_Ratio_Metrics_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Performance_Ratio_Metrics_By_PkArgs = {
  _inc?: Maybe<Performance_Ratio_Metrics_Inc_Input>;
  _set?: Maybe<Performance_Ratio_Metrics_Set_Input>;
  pk_columns: Performance_Ratio_Metrics_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Performance_Ratio_Metrics_ManyArgs = {
  updates: Array<Performance_Ratio_Metrics_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Performance_Report_Deliverable_TemplateArgs = {
  _inc?: Maybe<Performance_Report_Deliverable_Template_Inc_Input>;
  _set?: Maybe<Performance_Report_Deliverable_Template_Set_Input>;
  where: Performance_Report_Deliverable_Template_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Performance_Report_Deliverable_Template_By_PkArgs = {
  _inc?: Maybe<Performance_Report_Deliverable_Template_Inc_Input>;
  _set?: Maybe<Performance_Report_Deliverable_Template_Set_Input>;
  pk_columns: Performance_Report_Deliverable_Template_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Performance_Report_Deliverable_Template_ManyArgs = {
  updates: Array<Performance_Report_Deliverable_Template_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Performance_Report_Pivot_TypesArgs = {
  _set?: Maybe<Performance_Report_Pivot_Types_Set_Input>;
  where: Performance_Report_Pivot_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Performance_Report_Pivot_Types_By_PkArgs = {
  _set?: Maybe<Performance_Report_Pivot_Types_Set_Input>;
  pk_columns: Performance_Report_Pivot_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Performance_Report_Pivot_Types_ManyArgs = {
  updates: Array<Performance_Report_Pivot_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Performance_Report_Section_Pivot_FieldArgs = {
  _inc?: Maybe<Performance_Report_Section_Pivot_Field_Inc_Input>;
  _set?: Maybe<Performance_Report_Section_Pivot_Field_Set_Input>;
  where: Performance_Report_Section_Pivot_Field_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Performance_Report_Section_Pivot_Field_By_PkArgs = {
  _inc?: Maybe<Performance_Report_Section_Pivot_Field_Inc_Input>;
  _set?: Maybe<Performance_Report_Section_Pivot_Field_Set_Input>;
  pk_columns: Performance_Report_Section_Pivot_Field_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Performance_Report_Section_Pivot_Field_ManyArgs = {
  updates: Array<Performance_Report_Section_Pivot_Field_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Performance_Report_Section_Ratio_MetricArgs = {
  _inc?: Maybe<Performance_Report_Section_Ratio_Metric_Inc_Input>;
  _set?: Maybe<Performance_Report_Section_Ratio_Metric_Set_Input>;
  where: Performance_Report_Section_Ratio_Metric_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Performance_Report_Section_Ratio_Metric_By_PkArgs = {
  _inc?: Maybe<Performance_Report_Section_Ratio_Metric_Inc_Input>;
  _set?: Maybe<Performance_Report_Section_Ratio_Metric_Set_Input>;
  pk_columns: Performance_Report_Section_Ratio_Metric_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Performance_Report_Section_Ratio_Metric_ManyArgs = {
  updates: Array<Performance_Report_Section_Ratio_Metric_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Performance_Report_Section_Task_BlacklistArgs = {
  _set?: Maybe<Performance_Report_Section_Task_Blacklist_Set_Input>;
  where: Performance_Report_Section_Task_Blacklist_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Performance_Report_Section_Task_Blacklist_By_PkArgs = {
  _set?: Maybe<Performance_Report_Section_Task_Blacklist_Set_Input>;
  pk_columns: Performance_Report_Section_Task_Blacklist_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Performance_Report_Section_Task_Blacklist_ManyArgs = {
  updates: Array<Performance_Report_Section_Task_Blacklist_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Performance_Report_SectionsArgs = {
  _inc?: Maybe<Performance_Report_Sections_Inc_Input>;
  _set?: Maybe<Performance_Report_Sections_Set_Input>;
  where: Performance_Report_Sections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Performance_Report_Sections_By_PkArgs = {
  _inc?: Maybe<Performance_Report_Sections_Inc_Input>;
  _set?: Maybe<Performance_Report_Sections_Set_Input>;
  pk_columns: Performance_Report_Sections_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Performance_Report_Sections_ManyArgs = {
  updates: Array<Performance_Report_Sections_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Performance_Report_TemplatesArgs = {
  _set?: Maybe<Performance_Report_Templates_Set_Input>;
  where: Performance_Report_Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Performance_Report_Templates_By_PkArgs = {
  _set?: Maybe<Performance_Report_Templates_Set_Input>;
  pk_columns: Performance_Report_Templates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Performance_Report_Templates_ManyArgs = {
  updates: Array<Performance_Report_Templates_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PeripheralsArgs = {
  _set?: Maybe<Peripherals_Set_Input>;
  where: Peripherals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Peripherals_By_PkArgs = {
  _set?: Maybe<Peripherals_Set_Input>;
  pk_columns: Peripherals_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Peripherals_ManyArgs = {
  updates: Array<Peripherals_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Plan_HypertasksArgs = {
  _inc?: Maybe<Plan_Hypertasks_Inc_Input>;
  _set?: Maybe<Plan_Hypertasks_Set_Input>;
  where: Plan_Hypertasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Plan_Hypertasks_By_PkArgs = {
  _inc?: Maybe<Plan_Hypertasks_Inc_Input>;
  _set?: Maybe<Plan_Hypertasks_Set_Input>;
  pk_columns: Plan_Hypertasks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Plan_Hypertasks_ManyArgs = {
  updates: Array<Plan_Hypertasks_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Plan_TasksArgs = {
  _inc?: Maybe<Plan_Tasks_Inc_Input>;
  _set?: Maybe<Plan_Tasks_Set_Input>;
  where: Plan_Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Plan_Tasks_By_PkArgs = {
  _inc?: Maybe<Plan_Tasks_Inc_Input>;
  _set?: Maybe<Plan_Tasks_Set_Input>;
  pk_columns: Plan_Tasks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Plan_Tasks_ManyArgs = {
  updates: Array<Plan_Tasks_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PlansArgs = {
  _set?: Maybe<Plans_Set_Input>;
  where: Plans_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Plans_By_PkArgs = {
  _set?: Maybe<Plans_Set_Input>;
  pk_columns: Plans_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Plans_ManyArgs = {
  updates: Array<Plans_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProfilesArgs = {
  _set?: Maybe<Profiles_Set_Input>;
  where: Profiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profiles_By_PkArgs = {
  _set?: Maybe<Profiles_Set_Input>;
  pk_columns: Profiles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Profiles_ManyArgs = {
  updates: Array<Profiles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Project_ComboArgs = {
  _set?: Maybe<Project_Combo_Set_Input>;
  where: Project_Combo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Project_Combo_By_PkArgs = {
  _set?: Maybe<Project_Combo_Set_Input>;
  pk_columns: Project_Combo_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Project_Combo_ManyArgs = {
  updates: Array<Project_Combo_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Project_HypertasksArgs = {
  _inc?: Maybe<Project_Hypertasks_Inc_Input>;
  _set?: Maybe<Project_Hypertasks_Set_Input>;
  where: Project_Hypertasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Project_Hypertasks_By_PkArgs = {
  _inc?: Maybe<Project_Hypertasks_Inc_Input>;
  _set?: Maybe<Project_Hypertasks_Set_Input>;
  pk_columns: Project_Hypertasks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Project_Hypertasks_ManyArgs = {
  updates: Array<Project_Hypertasks_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Project_TasksArgs = {
  _inc?: Maybe<Project_Tasks_Inc_Input>;
  _set?: Maybe<Project_Tasks_Set_Input>;
  where: Project_Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Project_Tasks_By_PkArgs = {
  _inc?: Maybe<Project_Tasks_Inc_Input>;
  _set?: Maybe<Project_Tasks_Set_Input>;
  pk_columns: Project_Tasks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Project_Tasks_ManyArgs = {
  updates: Array<Project_Tasks_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Project_UserArgs = {
  _set?: Maybe<Project_User_Set_Input>;
  where: Project_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Project_User_By_PkArgs = {
  _set?: Maybe<Project_User_Set_Input>;
  pk_columns: Project_User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Project_User_ManyArgs = {
  updates: Array<Project_User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProjectsArgs = {
  _inc?: Maybe<Projects_Inc_Input>;
  _set?: Maybe<Projects_Set_Input>;
  where: Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_By_PkArgs = {
  _inc?: Maybe<Projects_Inc_Input>;
  _set?: Maybe<Projects_Set_Input>;
  pk_columns: Projects_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_ManyArgs = {
  updates: Array<Projects_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Rabbit_Test_Plan_ProjectArgs = {
  _set?: Maybe<Rabbit_Test_Plan_Project_Set_Input>;
  where: Rabbit_Test_Plan_Project_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rabbit_Test_Plan_Project_By_PkArgs = {
  _set?: Maybe<Rabbit_Test_Plan_Project_Set_Input>;
  pk_columns: Rabbit_Test_Plan_Project_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rabbit_Test_Plan_Project_ManyArgs = {
  updates: Array<Rabbit_Test_Plan_Project_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Rabbit_Test_Plan_RecordingArgs = {
  _set?: Maybe<Rabbit_Test_Plan_Recording_Set_Input>;
  where: Rabbit_Test_Plan_Recording_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rabbit_Test_Plan_Recording_By_PkArgs = {
  _set?: Maybe<Rabbit_Test_Plan_Recording_Set_Input>;
  pk_columns: Rabbit_Test_Plan_Recording_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rabbit_Test_Plan_Recording_ManyArgs = {
  updates: Array<Rabbit_Test_Plan_Recording_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Rabbit_Test_Plan_TaskArgs = {
  _set?: Maybe<Rabbit_Test_Plan_Task_Set_Input>;
  where: Rabbit_Test_Plan_Task_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rabbit_Test_Plan_Task_By_PkArgs = {
  _set?: Maybe<Rabbit_Test_Plan_Task_Set_Input>;
  pk_columns: Rabbit_Test_Plan_Task_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rabbit_Test_Plan_Task_ManyArgs = {
  updates: Array<Rabbit_Test_Plan_Task_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Rabbit_Test_PlansArgs = {
  _set?: Maybe<Rabbit_Test_Plans_Set_Input>;
  where: Rabbit_Test_Plans_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rabbit_Test_Plans_By_PkArgs = {
  _set?: Maybe<Rabbit_Test_Plans_Set_Input>;
  pk_columns: Rabbit_Test_Plans_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rabbit_Test_Plans_ManyArgs = {
  updates: Array<Rabbit_Test_Plans_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_AppsArgs = {
  _set?: Maybe<Recording_Apps_Set_Input>;
  where: Recording_Apps_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_Apps_By_PkArgs = {
  _set?: Maybe<Recording_Apps_Set_Input>;
  pk_columns: Recording_Apps_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_Apps_ManyArgs = {
  updates: Array<Recording_Apps_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_Audio_DriverArgs = {
  _set?: Maybe<Recording_Audio_Driver_Set_Input>;
  where: Recording_Audio_Driver_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_Audio_Driver_By_PkArgs = {
  _set?: Maybe<Recording_Audio_Driver_Set_Input>;
  pk_columns: Recording_Audio_Driver_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_Audio_Driver_ManyArgs = {
  updates: Array<Recording_Audio_Driver_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_Data_Ground_TruthArgs = {
  _inc?: Maybe<Recording_Data_Ground_Truth_Inc_Input>;
  _set?: Maybe<Recording_Data_Ground_Truth_Set_Input>;
  where: Recording_Data_Ground_Truth_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_Data_Ground_Truth_By_PkArgs = {
  _inc?: Maybe<Recording_Data_Ground_Truth_Inc_Input>;
  _set?: Maybe<Recording_Data_Ground_Truth_Set_Input>;
  pk_columns: Recording_Data_Ground_Truth_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_Data_Ground_Truth_ManyArgs = {
  updates: Array<Recording_Data_Ground_Truth_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_Data_LabelsArgs = {
  _inc?: Maybe<Recording_Data_Labels_Inc_Input>;
  _set?: Maybe<Recording_Data_Labels_Set_Input>;
  where: Recording_Data_Labels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_Data_Labels_By_PkArgs = {
  _inc?: Maybe<Recording_Data_Labels_Inc_Input>;
  _set?: Maybe<Recording_Data_Labels_Set_Input>;
  pk_columns: Recording_Data_Labels_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_Data_Labels_ManyArgs = {
  updates: Array<Recording_Data_Labels_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_Data_Mode_ParameterArgs = {
  _set?: Maybe<Recording_Data_Mode_Parameter_Set_Input>;
  where: Recording_Data_Mode_Parameter_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_Data_Mode_Parameter_By_PkArgs = {
  _set?: Maybe<Recording_Data_Mode_Parameter_Set_Input>;
  pk_columns: Recording_Data_Mode_Parameter_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_Data_Mode_Parameter_ManyArgs = {
  updates: Array<Recording_Data_Mode_Parameter_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_Data_ModesArgs = {
  _set?: Maybe<Recording_Data_Modes_Set_Input>;
  where: Recording_Data_Modes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_Data_Modes_By_PkArgs = {
  _set?: Maybe<Recording_Data_Modes_Set_Input>;
  pk_columns: Recording_Data_Modes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_Data_Modes_ManyArgs = {
  updates: Array<Recording_Data_Modes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_Data_ParametersArgs = {
  _set?: Maybe<Recording_Data_Parameters_Set_Input>;
  where: Recording_Data_Parameters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_Data_Parameters_By_PkArgs = {
  _set?: Maybe<Recording_Data_Parameters_Set_Input>;
  pk_columns: Recording_Data_Parameters_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_Data_Parameters_ManyArgs = {
  updates: Array<Recording_Data_Parameters_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_Data_Payload_PairArgs = {
  _inc?: Maybe<Recording_Data_Payload_Pair_Inc_Input>;
  _set?: Maybe<Recording_Data_Payload_Pair_Set_Input>;
  where: Recording_Data_Payload_Pair_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_Data_Payload_Pair_By_PkArgs = {
  _inc?: Maybe<Recording_Data_Payload_Pair_Inc_Input>;
  _set?: Maybe<Recording_Data_Payload_Pair_Set_Input>;
  pk_columns: Recording_Data_Payload_Pair_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_Data_Payload_Pair_ManyArgs = {
  updates: Array<Recording_Data_Payload_Pair_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_Data_PredictionsArgs = {
  _set?: Maybe<Recording_Data_Predictions_Set_Input>;
  where: Recording_Data_Predictions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_Data_Predictions_By_PkArgs = {
  _set?: Maybe<Recording_Data_Predictions_Set_Input>;
  pk_columns: Recording_Data_Predictions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_Data_Predictions_ManyArgs = {
  updates: Array<Recording_Data_Predictions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_Recording_TagArgs = {
  _inc?: Maybe<Recording_Recording_Tag_Inc_Input>;
  _set?: Maybe<Recording_Recording_Tag_Set_Input>;
  where: Recording_Recording_Tag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_Recording_Tag_By_PkArgs = {
  _inc?: Maybe<Recording_Recording_Tag_Inc_Input>;
  _set?: Maybe<Recording_Recording_Tag_Set_Input>;
  pk_columns: Recording_Recording_Tag_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_Recording_Tag_ManyArgs = {
  updates: Array<Recording_Recording_Tag_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_TagsArgs = {
  _set?: Maybe<Recording_Tags_Set_Input>;
  where: Recording_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_Tags_By_PkArgs = {
  _set?: Maybe<Recording_Tags_Set_Input>;
  pk_columns: Recording_Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_Tags_ManyArgs = {
  updates: Array<Recording_Tags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RecordingsArgs = {
  _inc?: Maybe<Recordings_Inc_Input>;
  _set?: Maybe<Recordings_Set_Input>;
  where: Recordings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recordings_By_PkArgs = {
  _inc?: Maybe<Recordings_Inc_Input>;
  _set?: Maybe<Recordings_Set_Input>;
  pk_columns: Recordings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recordings_ManyArgs = {
  updates: Array<Recordings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Role_UserArgs = {
  _set?: Maybe<Role_User_Set_Input>;
  where: Role_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Role_User_By_PkArgs = {
  _set?: Maybe<Role_User_Set_Input>;
  pk_columns: Role_User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Role_User_ManyArgs = {
  updates: Array<Role_User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RolesArgs = {
  _set?: Maybe<Roles_Set_Input>;
  where: Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Roles_By_PkArgs = {
  _set?: Maybe<Roles_Set_Input>;
  pk_columns: Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Roles_ManyArgs = {
  updates: Array<Roles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RoomsArgs = {
  _set?: Maybe<Rooms_Set_Input>;
  where: Rooms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rooms_By_PkArgs = {
  _set?: Maybe<Rooms_Set_Input>;
  pk_columns: Rooms_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rooms_ManyArgs = {
  updates: Array<Rooms_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Simulations_ScoresArgs = {
  _inc?: Maybe<Simulations_Scores_Inc_Input>;
  _set?: Maybe<Simulations_Scores_Set_Input>;
  where: Simulations_Scores_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Simulations_Scores_By_PkArgs = {
  _inc?: Maybe<Simulations_Scores_Inc_Input>;
  _set?: Maybe<Simulations_Scores_Set_Input>;
  pk_columns: Simulations_Scores_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Simulations_Scores_ManyArgs = {
  updates: Array<Simulations_Scores_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Task_Field_OptionArgs = {
  _set?: Maybe<Task_Field_Option_Set_Input>;
  where: Task_Field_Option_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Task_Field_Option_By_PkArgs = {
  _set?: Maybe<Task_Field_Option_Set_Input>;
  pk_columns: Task_Field_Option_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Task_Field_Option_ManyArgs = {
  updates: Array<Task_Field_Option_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Task_Groups_Group_FieldsArgs = {
  _inc?: Maybe<Task_Groups_Group_Fields_Inc_Input>;
  _set?: Maybe<Task_Groups_Group_Fields_Set_Input>;
  where: Task_Groups_Group_Fields_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Task_Groups_Group_Fields_By_PkArgs = {
  _inc?: Maybe<Task_Groups_Group_Fields_Inc_Input>;
  _set?: Maybe<Task_Groups_Group_Fields_Set_Input>;
  pk_columns: Task_Groups_Group_Fields_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Task_Groups_Group_Fields_ManyArgs = {
  updates: Array<Task_Groups_Group_Fields_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Task_Groups_GroupsArgs = {
  _set?: Maybe<Task_Groups_Groups_Set_Input>;
  where: Task_Groups_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Task_Groups_Groups_By_PkArgs = {
  _set?: Maybe<Task_Groups_Groups_Set_Input>;
  pk_columns: Task_Groups_Groups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Task_Groups_Groups_ManyArgs = {
  updates: Array<Task_Groups_Groups_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Task_Groups_HierarchiesArgs = {
  _set?: Maybe<Task_Groups_Hierarchies_Set_Input>;
  where: Task_Groups_Hierarchies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Task_Groups_Hierarchies_By_PkArgs = {
  _set?: Maybe<Task_Groups_Hierarchies_Set_Input>;
  pk_columns: Task_Groups_Hierarchies_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Task_Groups_Hierarchies_ManyArgs = {
  updates: Array<Task_Groups_Hierarchies_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Task_Groups_Hierarchy_GroupsArgs = {
  _inc?: Maybe<Task_Groups_Hierarchy_Groups_Inc_Input>;
  _set?: Maybe<Task_Groups_Hierarchy_Groups_Set_Input>;
  where: Task_Groups_Hierarchy_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Task_Groups_Hierarchy_Groups_By_PkArgs = {
  _inc?: Maybe<Task_Groups_Hierarchy_Groups_Inc_Input>;
  _set?: Maybe<Task_Groups_Hierarchy_Groups_Set_Input>;
  pk_columns: Task_Groups_Hierarchy_Groups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Task_Groups_Hierarchy_Groups_ManyArgs = {
  updates: Array<Task_Groups_Hierarchy_Groups_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Task_InstructionsArgs = {
  _set?: Maybe<Task_Instructions_Set_Input>;
  where: Task_Instructions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Task_Instructions_By_PkArgs = {
  _set?: Maybe<Task_Instructions_Set_Input>;
  pk_columns: Task_Instructions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Task_Instructions_ManyArgs = {
  updates: Array<Task_Instructions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Task_MediaArgs = {
  _set?: Maybe<Task_Media_Set_Input>;
  where: Task_Media_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Task_Media_By_PkArgs = {
  _set?: Maybe<Task_Media_Set_Input>;
  pk_columns: Task_Media_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Task_Media_ManyArgs = {
  updates: Array<Task_Media_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Task_TranslationArgs = {
  _set?: Maybe<Task_Translation_Set_Input>;
  where: Task_Translation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Task_Translation_By_PkArgs = {
  _set?: Maybe<Task_Translation_Set_Input>;
  pk_columns: Task_Translation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Task_Translation_ManyArgs = {
  updates: Array<Task_Translation_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TasksArgs = {
  _inc?: Maybe<Tasks_Inc_Input>;
  _set?: Maybe<Tasks_Set_Input>;
  where: Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tasks_By_PkArgs = {
  _inc?: Maybe<Tasks_Inc_Input>;
  _set?: Maybe<Tasks_Set_Input>;
  pk_columns: Tasks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tasks_ManyArgs = {
  updates: Array<Tasks_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tool_VersionsArgs = {
  _set?: Maybe<Tool_Versions_Set_Input>;
  where: Tool_Versions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tool_Versions_By_PkArgs = {
  _set?: Maybe<Tool_Versions_Set_Input>;
  pk_columns: Tool_Versions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tool_Versions_ManyArgs = {
  updates: Array<Tool_Versions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ToolsArgs = {
  _set?: Maybe<Tools_Set_Input>;
  where: Tools_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tools_By_PkArgs = {
  _set?: Maybe<Tools_Set_Input>;
  pk_columns: Tools_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tools_ManyArgs = {
  updates: Array<Tools_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TypesArgs = {
  _set?: Maybe<Types_Set_Input>;
  where: Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Types_By_PkArgs = {
  _set?: Maybe<Types_Set_Input>;
  pk_columns: Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Types_ManyArgs = {
  updates: Array<Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: Maybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: Maybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Xtensa_ConfigurationsArgs = {
  _set?: Maybe<Xtensa_Configurations_Set_Input>;
  where: Xtensa_Configurations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Xtensa_Configurations_By_PkArgs = {
  _set?: Maybe<Xtensa_Configurations_Set_Input>;
  pk_columns: Xtensa_Configurations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Xtensa_Configurations_ManyArgs = {
  updates: Array<Xtensa_Configurations_Updates>;
};


/** mutation root */
export type Mutation_RootUpsert_TaskArgs = {
  args: Upsert_Task_Args;
  distinct_on?: Maybe<Array<Schema_Upsert_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Upsert_Task_Order_By>>;
  where?: Maybe<Schema_Upsert_Task_Bool_Exp>;
};


/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** columns and relationships of "opmode_profile" */
export type Opmode_Profile = {
  __typename?: 'opmode_profile';
  /** An object relationship */
  opmode: Opmodes;
  opmode_id: Scalars['uuid'];
  /** An object relationship */
  profile: Profiles;
  profile_id: Scalars['uuid'];
};

/** order by aggregate values of table "opmode_profile" */
export type Opmode_Profile_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Opmode_Profile_Max_Order_By>;
  min?: Maybe<Opmode_Profile_Min_Order_By>;
};

/** input type for inserting array relation for remote table "opmode_profile" */
export type Opmode_Profile_Arr_Rel_Insert_Input = {
  data: Array<Opmode_Profile_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Opmode_Profile_On_Conflict>;
};

/** Boolean expression to filter rows from the table "opmode_profile". All fields are combined with a logical 'AND'. */
export type Opmode_Profile_Bool_Exp = {
  _and?: Maybe<Array<Opmode_Profile_Bool_Exp>>;
  _not?: Maybe<Opmode_Profile_Bool_Exp>;
  _or?: Maybe<Array<Opmode_Profile_Bool_Exp>>;
  opmode?: Maybe<Opmodes_Bool_Exp>;
  opmode_id?: Maybe<Uuid_Comparison_Exp>;
  profile?: Maybe<Profiles_Bool_Exp>;
  profile_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "opmode_profile" */
export enum Opmode_Profile_Constraint {
  /** unique or primary key constraint on columns "profile_id", "opmode_id" */
  OpmodeProfilePkey = 'opmode_profile_pkey'
}

/** input type for inserting data into table "opmode_profile" */
export type Opmode_Profile_Insert_Input = {
  opmode?: Maybe<Opmodes_Obj_Rel_Insert_Input>;
  opmode_id?: Maybe<Scalars['uuid']>;
  profile?: Maybe<Profiles_Obj_Rel_Insert_Input>;
  profile_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "opmode_profile" */
export type Opmode_Profile_Max_Order_By = {
  opmode_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** order by min() on columns of table "opmode_profile" */
export type Opmode_Profile_Min_Order_By = {
  opmode_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "opmode_profile" */
export type Opmode_Profile_Mutation_Response = {
  __typename?: 'opmode_profile_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Opmode_Profile>;
};

/** on_conflict condition type for table "opmode_profile" */
export type Opmode_Profile_On_Conflict = {
  constraint: Opmode_Profile_Constraint;
  update_columns?: Array<Opmode_Profile_Update_Column>;
  where?: Maybe<Opmode_Profile_Bool_Exp>;
};

/** Ordering options when selecting data from "opmode_profile". */
export type Opmode_Profile_Order_By = {
  opmode?: Maybe<Opmodes_Order_By>;
  opmode_id?: Maybe<Order_By>;
  profile?: Maybe<Profiles_Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** primary key columns input for table: opmode_profile */
export type Opmode_Profile_Pk_Columns_Input = {
  opmode_id: Scalars['uuid'];
  profile_id: Scalars['uuid'];
};

/** select columns of table "opmode_profile" */
export enum Opmode_Profile_Select_Column {
  /** column name */
  OpmodeId = 'opmode_id',
  /** column name */
  ProfileId = 'profile_id'
}

/** input type for updating data in table "opmode_profile" */
export type Opmode_Profile_Set_Input = {
  opmode_id?: Maybe<Scalars['uuid']>;
  profile_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "opmode_profile" */
export type Opmode_Profile_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Opmode_Profile_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Opmode_Profile_Stream_Cursor_Value_Input = {
  opmode_id?: Maybe<Scalars['uuid']>;
  profile_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "opmode_profile" */
export enum Opmode_Profile_Update_Column {
  /** column name */
  OpmodeId = 'opmode_id',
  /** column name */
  ProfileId = 'profile_id'
}

export type Opmode_Profile_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Opmode_Profile_Set_Input>;
  where: Opmode_Profile_Bool_Exp;
};

/** columns and relationships of "opmode_project" */
export type Opmode_Project = {
  __typename?: 'opmode_project';
  /** An object relationship */
  opmode: Opmodes;
  opmode_id: Scalars['uuid'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
};

/** aggregated selection of "opmode_project" */
export type Opmode_Project_Aggregate = {
  __typename?: 'opmode_project_aggregate';
  aggregate?: Maybe<Opmode_Project_Aggregate_Fields>;
  nodes: Array<Opmode_Project>;
};

/** aggregate fields of "opmode_project" */
export type Opmode_Project_Aggregate_Fields = {
  __typename?: 'opmode_project_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Opmode_Project_Max_Fields>;
  min?: Maybe<Opmode_Project_Min_Fields>;
};


/** aggregate fields of "opmode_project" */
export type Opmode_Project_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Opmode_Project_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "opmode_project" */
export type Opmode_Project_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Opmode_Project_Max_Order_By>;
  min?: Maybe<Opmode_Project_Min_Order_By>;
};

/** input type for inserting array relation for remote table "opmode_project" */
export type Opmode_Project_Arr_Rel_Insert_Input = {
  data: Array<Opmode_Project_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Opmode_Project_On_Conflict>;
};

/** Boolean expression to filter rows from the table "opmode_project". All fields are combined with a logical 'AND'. */
export type Opmode_Project_Bool_Exp = {
  _and?: Maybe<Array<Opmode_Project_Bool_Exp>>;
  _not?: Maybe<Opmode_Project_Bool_Exp>;
  _or?: Maybe<Array<Opmode_Project_Bool_Exp>>;
  opmode?: Maybe<Opmodes_Bool_Exp>;
  opmode_id?: Maybe<Uuid_Comparison_Exp>;
  project?: Maybe<Projects_Bool_Exp>;
  project_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "opmode_project" */
export enum Opmode_Project_Constraint {
  /** unique or primary key constraint on columns "project_id", "opmode_id" */
  OpmodeProjectPkey = 'opmode_project_pkey'
}

/** input type for inserting data into table "opmode_project" */
export type Opmode_Project_Insert_Input = {
  opmode?: Maybe<Opmodes_Obj_Rel_Insert_Input>;
  opmode_id?: Maybe<Scalars['uuid']>;
  project?: Maybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Opmode_Project_Max_Fields = {
  __typename?: 'opmode_project_max_fields';
  opmode_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "opmode_project" */
export type Opmode_Project_Max_Order_By = {
  opmode_id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Opmode_Project_Min_Fields = {
  __typename?: 'opmode_project_min_fields';
  opmode_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "opmode_project" */
export type Opmode_Project_Min_Order_By = {
  opmode_id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "opmode_project" */
export type Opmode_Project_Mutation_Response = {
  __typename?: 'opmode_project_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Opmode_Project>;
};

/** on_conflict condition type for table "opmode_project" */
export type Opmode_Project_On_Conflict = {
  constraint: Opmode_Project_Constraint;
  update_columns?: Array<Opmode_Project_Update_Column>;
  where?: Maybe<Opmode_Project_Bool_Exp>;
};

/** Ordering options when selecting data from "opmode_project". */
export type Opmode_Project_Order_By = {
  opmode?: Maybe<Opmodes_Order_By>;
  opmode_id?: Maybe<Order_By>;
  project?: Maybe<Projects_Order_By>;
  project_id?: Maybe<Order_By>;
};

/** primary key columns input for table: opmode_project */
export type Opmode_Project_Pk_Columns_Input = {
  opmode_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};

/** select columns of table "opmode_project" */
export enum Opmode_Project_Select_Column {
  /** column name */
  OpmodeId = 'opmode_id',
  /** column name */
  ProjectId = 'project_id'
}

/** input type for updating data in table "opmode_project" */
export type Opmode_Project_Set_Input = {
  opmode_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "opmode_project" */
export type Opmode_Project_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Opmode_Project_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Opmode_Project_Stream_Cursor_Value_Input = {
  opmode_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "opmode_project" */
export enum Opmode_Project_Update_Column {
  /** column name */
  OpmodeId = 'opmode_id',
  /** column name */
  ProjectId = 'project_id'
}

export type Opmode_Project_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Opmode_Project_Set_Input>;
  where: Opmode_Project_Bool_Exp;
};

/** columns and relationships of "opmodes" */
export type Opmodes = {
  __typename?: 'opmodes';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  engine_opmode_recordings: Array<Engine_Opmode_Recording>;
  /** An aggregate relationship */
  engine_opmode_recordings_aggregate: Engine_Opmode_Recording_Aggregate;
  /** An array relationship */
  engine_opmodes: Array<Engine_Opmode>;
  /** An aggregate relationship */
  engine_opmodes_aggregate: Engine_Opmode_Aggregate;
  id: Scalars['uuid'];
  number: Scalars['Int'];
  /** An array relationship */
  opmode_profiles: Array<Opmode_Profile>;
  /** An array relationship */
  opmode_projects: Array<Opmode_Project>;
  /** An aggregate relationship */
  opmode_projects_aggregate: Opmode_Project_Aggregate;
  /** An object relationship */
  project?: Maybe<Projects>;
  uname?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "opmodes" */
export type OpmodesEngine_Opmode_RecordingsArgs = {
  distinct_on?: Maybe<Array<Engine_Opmode_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Engine_Opmode_Recording_Order_By>>;
  where?: Maybe<Engine_Opmode_Recording_Bool_Exp>;
};


/** columns and relationships of "opmodes" */
export type OpmodesEngine_Opmode_Recordings_AggregateArgs = {
  distinct_on?: Maybe<Array<Engine_Opmode_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Engine_Opmode_Recording_Order_By>>;
  where?: Maybe<Engine_Opmode_Recording_Bool_Exp>;
};


/** columns and relationships of "opmodes" */
export type OpmodesEngine_OpmodesArgs = {
  distinct_on?: Maybe<Array<Engine_Opmode_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Engine_Opmode_Order_By>>;
  where?: Maybe<Engine_Opmode_Bool_Exp>;
};


/** columns and relationships of "opmodes" */
export type OpmodesEngine_Opmodes_AggregateArgs = {
  distinct_on?: Maybe<Array<Engine_Opmode_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Engine_Opmode_Order_By>>;
  where?: Maybe<Engine_Opmode_Bool_Exp>;
};


/** columns and relationships of "opmodes" */
export type OpmodesOpmode_ProfilesArgs = {
  distinct_on?: Maybe<Array<Opmode_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Opmode_Profile_Order_By>>;
  where?: Maybe<Opmode_Profile_Bool_Exp>;
};


/** columns and relationships of "opmodes" */
export type OpmodesOpmode_ProjectsArgs = {
  distinct_on?: Maybe<Array<Opmode_Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Opmode_Project_Order_By>>;
  where?: Maybe<Opmode_Project_Bool_Exp>;
};


/** columns and relationships of "opmodes" */
export type OpmodesOpmode_Projects_AggregateArgs = {
  distinct_on?: Maybe<Array<Opmode_Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Opmode_Project_Order_By>>;
  where?: Maybe<Opmode_Project_Bool_Exp>;
};

/** aggregated selection of "opmodes" */
export type Opmodes_Aggregate = {
  __typename?: 'opmodes_aggregate';
  aggregate?: Maybe<Opmodes_Aggregate_Fields>;
  nodes: Array<Opmodes>;
};

/** aggregate fields of "opmodes" */
export type Opmodes_Aggregate_Fields = {
  __typename?: 'opmodes_aggregate_fields';
  avg?: Maybe<Opmodes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Opmodes_Max_Fields>;
  min?: Maybe<Opmodes_Min_Fields>;
  stddev?: Maybe<Opmodes_Stddev_Fields>;
  stddev_pop?: Maybe<Opmodes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Opmodes_Stddev_Samp_Fields>;
  sum?: Maybe<Opmodes_Sum_Fields>;
  var_pop?: Maybe<Opmodes_Var_Pop_Fields>;
  var_samp?: Maybe<Opmodes_Var_Samp_Fields>;
  variance?: Maybe<Opmodes_Variance_Fields>;
};


/** aggregate fields of "opmodes" */
export type Opmodes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Opmodes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Opmodes_Avg_Fields = {
  __typename?: 'opmodes_avg_fields';
  number?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "opmodes". All fields are combined with a logical 'AND'. */
export type Opmodes_Bool_Exp = {
  _and?: Maybe<Array<Opmodes_Bool_Exp>>;
  _not?: Maybe<Opmodes_Bool_Exp>;
  _or?: Maybe<Array<Opmodes_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  engine_opmode_recordings?: Maybe<Engine_Opmode_Recording_Bool_Exp>;
  engine_opmodes?: Maybe<Engine_Opmode_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  number?: Maybe<Int_Comparison_Exp>;
  opmode_profiles?: Maybe<Opmode_Profile_Bool_Exp>;
  opmode_projects?: Maybe<Opmode_Project_Bool_Exp>;
  project?: Maybe<Projects_Bool_Exp>;
  uname?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "opmodes" */
export enum Opmodes_Constraint {
  /** unique or primary key constraint on columns "number" */
  OpmodesNumberUnique = 'opmodes_number_unique',
  /** unique or primary key constraint on columns "id" */
  OpmodesPkey = 'opmodes_pkey'
}

/** input type for incrementing numeric columns in table "opmodes" */
export type Opmodes_Inc_Input = {
  number?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "opmodes" */
export type Opmodes_Insert_Input = {
  engine_opmode_recordings?: Maybe<Engine_Opmode_Recording_Arr_Rel_Insert_Input>;
  engine_opmodes?: Maybe<Engine_Opmode_Arr_Rel_Insert_Input>;
  number?: Maybe<Scalars['Int']>;
  opmode_profiles?: Maybe<Opmode_Profile_Arr_Rel_Insert_Input>;
  opmode_projects?: Maybe<Opmode_Project_Arr_Rel_Insert_Input>;
  project?: Maybe<Projects_Obj_Rel_Insert_Input>;
  uname?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Opmodes_Max_Fields = {
  __typename?: 'opmodes_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  number?: Maybe<Scalars['Int']>;
  uname?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Opmodes_Min_Fields = {
  __typename?: 'opmodes_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  number?: Maybe<Scalars['Int']>;
  uname?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "opmodes" */
export type Opmodes_Mutation_Response = {
  __typename?: 'opmodes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Opmodes>;
};

/** input type for inserting object relation for remote table "opmodes" */
export type Opmodes_Obj_Rel_Insert_Input = {
  data: Opmodes_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Opmodes_On_Conflict>;
};

/** on_conflict condition type for table "opmodes" */
export type Opmodes_On_Conflict = {
  constraint: Opmodes_Constraint;
  update_columns?: Array<Opmodes_Update_Column>;
  where?: Maybe<Opmodes_Bool_Exp>;
};

/** Ordering options when selecting data from "opmodes". */
export type Opmodes_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  engine_opmode_recordings_aggregate?: Maybe<Engine_Opmode_Recording_Aggregate_Order_By>;
  engine_opmodes_aggregate?: Maybe<Engine_Opmode_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  opmode_profiles_aggregate?: Maybe<Opmode_Profile_Aggregate_Order_By>;
  opmode_projects_aggregate?: Maybe<Opmode_Project_Aggregate_Order_By>;
  project?: Maybe<Projects_Order_By>;
  uname?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: opmodes */
export type Opmodes_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "opmodes" */
export enum Opmodes_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Number = 'number',
  /** column name */
  Uname = 'uname',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "opmodes" */
export type Opmodes_Set_Input = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  number?: Maybe<Scalars['Int']>;
  uname?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Opmodes_Stddev_Fields = {
  __typename?: 'opmodes_stddev_fields';
  number?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Opmodes_Stddev_Pop_Fields = {
  __typename?: 'opmodes_stddev_pop_fields';
  number?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Opmodes_Stddev_Samp_Fields = {
  __typename?: 'opmodes_stddev_samp_fields';
  number?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "opmodes" */
export type Opmodes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Opmodes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Opmodes_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  number?: Maybe<Scalars['Int']>;
  uname?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Opmodes_Sum_Fields = {
  __typename?: 'opmodes_sum_fields';
  number?: Maybe<Scalars['Int']>;
};

/** update columns of table "opmodes" */
export enum Opmodes_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Number = 'number',
  /** column name */
  Uname = 'uname'
}

export type Opmodes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Opmodes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Opmodes_Set_Input>;
  where: Opmodes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Opmodes_Var_Pop_Fields = {
  __typename?: 'opmodes_var_pop_fields';
  number?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Opmodes_Var_Samp_Fields = {
  __typename?: 'opmodes_var_samp_fields';
  number?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Opmodes_Variance_Fields = {
  __typename?: 'opmodes_variance_fields';
  number?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "option_media" */
export type Option_Media = {
  __typename?: 'option_media';
  /** An object relationship */
  media: Media;
  media_id: Scalars['uuid'];
  /** An object relationship */
  option: Options;
  option_id: Scalars['uuid'];
};

/** aggregated selection of "option_media" */
export type Option_Media_Aggregate = {
  __typename?: 'option_media_aggregate';
  aggregate?: Maybe<Option_Media_Aggregate_Fields>;
  nodes: Array<Option_Media>;
};

/** aggregate fields of "option_media" */
export type Option_Media_Aggregate_Fields = {
  __typename?: 'option_media_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Option_Media_Max_Fields>;
  min?: Maybe<Option_Media_Min_Fields>;
};


/** aggregate fields of "option_media" */
export type Option_Media_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Option_Media_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "option_media" */
export type Option_Media_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Option_Media_Max_Order_By>;
  min?: Maybe<Option_Media_Min_Order_By>;
};

/** input type for inserting array relation for remote table "option_media" */
export type Option_Media_Arr_Rel_Insert_Input = {
  data: Array<Option_Media_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Option_Media_On_Conflict>;
};

/** Boolean expression to filter rows from the table "option_media". All fields are combined with a logical 'AND'. */
export type Option_Media_Bool_Exp = {
  _and?: Maybe<Array<Option_Media_Bool_Exp>>;
  _not?: Maybe<Option_Media_Bool_Exp>;
  _or?: Maybe<Array<Option_Media_Bool_Exp>>;
  media?: Maybe<Media_Bool_Exp>;
  media_id?: Maybe<Uuid_Comparison_Exp>;
  option?: Maybe<Options_Bool_Exp>;
  option_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "option_media" */
export enum Option_Media_Constraint {
  /** unique or primary key constraint on columns "option_id", "media_id" */
  OptionMediaPkey = 'option_media_pkey'
}

/** input type for inserting data into table "option_media" */
export type Option_Media_Insert_Input = {
  media?: Maybe<Media_Obj_Rel_Insert_Input>;
  media_id?: Maybe<Scalars['uuid']>;
  option?: Maybe<Options_Obj_Rel_Insert_Input>;
  option_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Option_Media_Max_Fields = {
  __typename?: 'option_media_max_fields';
  media_id?: Maybe<Scalars['uuid']>;
  option_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "option_media" */
export type Option_Media_Max_Order_By = {
  media_id?: Maybe<Order_By>;
  option_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Option_Media_Min_Fields = {
  __typename?: 'option_media_min_fields';
  media_id?: Maybe<Scalars['uuid']>;
  option_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "option_media" */
export type Option_Media_Min_Order_By = {
  media_id?: Maybe<Order_By>;
  option_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "option_media" */
export type Option_Media_Mutation_Response = {
  __typename?: 'option_media_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Option_Media>;
};

/** on_conflict condition type for table "option_media" */
export type Option_Media_On_Conflict = {
  constraint: Option_Media_Constraint;
  update_columns?: Array<Option_Media_Update_Column>;
  where?: Maybe<Option_Media_Bool_Exp>;
};

/** Ordering options when selecting data from "option_media". */
export type Option_Media_Order_By = {
  media?: Maybe<Media_Order_By>;
  media_id?: Maybe<Order_By>;
  option?: Maybe<Options_Order_By>;
  option_id?: Maybe<Order_By>;
};

/** primary key columns input for table: option_media */
export type Option_Media_Pk_Columns_Input = {
  media_id: Scalars['uuid'];
  option_id: Scalars['uuid'];
};

/** select columns of table "option_media" */
export enum Option_Media_Select_Column {
  /** column name */
  MediaId = 'media_id',
  /** column name */
  OptionId = 'option_id'
}

/** input type for updating data in table "option_media" */
export type Option_Media_Set_Input = {
  media_id?: Maybe<Scalars['uuid']>;
  option_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "option_media" */
export type Option_Media_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Option_Media_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Option_Media_Stream_Cursor_Value_Input = {
  media_id?: Maybe<Scalars['uuid']>;
  option_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "option_media" */
export enum Option_Media_Update_Column {
  /** column name */
  MediaId = 'media_id',
  /** column name */
  OptionId = 'option_id'
}

export type Option_Media_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Option_Media_Set_Input>;
  where: Option_Media_Bool_Exp;
};

/** columns and relationships of "option_translation" */
export type Option_Translation = {
  __typename?: 'option_translation';
  display: Scalars['String'];
  language: Scalars['String'];
  /** An object relationship */
  option: Options;
  option_id: Scalars['uuid'];
  /** An object relationship */
  option_translation_language: Languages;
};

/** aggregated selection of "option_translation" */
export type Option_Translation_Aggregate = {
  __typename?: 'option_translation_aggregate';
  aggregate?: Maybe<Option_Translation_Aggregate_Fields>;
  nodes: Array<Option_Translation>;
};

/** aggregate fields of "option_translation" */
export type Option_Translation_Aggregate_Fields = {
  __typename?: 'option_translation_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Option_Translation_Max_Fields>;
  min?: Maybe<Option_Translation_Min_Fields>;
};


/** aggregate fields of "option_translation" */
export type Option_Translation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Option_Translation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "option_translation" */
export type Option_Translation_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Option_Translation_Max_Order_By>;
  min?: Maybe<Option_Translation_Min_Order_By>;
};

/** input type for inserting array relation for remote table "option_translation" */
export type Option_Translation_Arr_Rel_Insert_Input = {
  data: Array<Option_Translation_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Option_Translation_On_Conflict>;
};

/** Boolean expression to filter rows from the table "option_translation". All fields are combined with a logical 'AND'. */
export type Option_Translation_Bool_Exp = {
  _and?: Maybe<Array<Option_Translation_Bool_Exp>>;
  _not?: Maybe<Option_Translation_Bool_Exp>;
  _or?: Maybe<Array<Option_Translation_Bool_Exp>>;
  display?: Maybe<String_Comparison_Exp>;
  language?: Maybe<String_Comparison_Exp>;
  option?: Maybe<Options_Bool_Exp>;
  option_id?: Maybe<Uuid_Comparison_Exp>;
  option_translation_language?: Maybe<Languages_Bool_Exp>;
};

/** unique or primary key constraints on table "option_translation" */
export enum Option_Translation_Constraint {
  /** unique or primary key constraint on columns "option_id", "display" */
  OptionTranslationOptionIdDisplayKey = 'option_translation_option_id_display_key',
  /** unique or primary key constraint on columns "option_id", "language" */
  OptionTranslationPkey = 'option_translation_pkey'
}

/** input type for inserting data into table "option_translation" */
export type Option_Translation_Insert_Input = {
  display?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  option?: Maybe<Options_Obj_Rel_Insert_Input>;
  option_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Option_Translation_Max_Fields = {
  __typename?: 'option_translation_max_fields';
  display?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  option_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "option_translation" */
export type Option_Translation_Max_Order_By = {
  display?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  option_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Option_Translation_Min_Fields = {
  __typename?: 'option_translation_min_fields';
  display?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  option_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "option_translation" */
export type Option_Translation_Min_Order_By = {
  display?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  option_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "option_translation" */
export type Option_Translation_Mutation_Response = {
  __typename?: 'option_translation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Option_Translation>;
};

/** on_conflict condition type for table "option_translation" */
export type Option_Translation_On_Conflict = {
  constraint: Option_Translation_Constraint;
  update_columns?: Array<Option_Translation_Update_Column>;
  where?: Maybe<Option_Translation_Bool_Exp>;
};

/** Ordering options when selecting data from "option_translation". */
export type Option_Translation_Order_By = {
  display?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  option?: Maybe<Options_Order_By>;
  option_id?: Maybe<Order_By>;
  option_translation_language?: Maybe<Languages_Order_By>;
};

/** primary key columns input for table: option_translation */
export type Option_Translation_Pk_Columns_Input = {
  language: Scalars['String'];
  option_id: Scalars['uuid'];
};

/** select columns of table "option_translation" */
export enum Option_Translation_Select_Column {
  /** column name */
  Display = 'display',
  /** column name */
  Language = 'language',
  /** column name */
  OptionId = 'option_id'
}

/** input type for updating data in table "option_translation" */
export type Option_Translation_Set_Input = {
  display?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  option_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "option_translation" */
export type Option_Translation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Option_Translation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Option_Translation_Stream_Cursor_Value_Input = {
  display?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  option_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "option_translation" */
export enum Option_Translation_Update_Column {
  /** column name */
  Display = 'display',
  /** column name */
  Language = 'language',
  /** column name */
  OptionId = 'option_id'
}

export type Option_Translation_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Option_Translation_Set_Input>;
  where: Option_Translation_Bool_Exp;
};

/** columns and relationships of "options" */
export type Options = {
  __typename?: 'options';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  display: Scalars['String'];
  /** An array relationship */
  field_options: Array<Field_Options>;
  /** An aggregate relationship */
  field_options_aggregate: Field_Options_Aggregate;
  /** An array relationship */
  hypertask_field_options: Array<Hypertask_Field_Option>;
  /** An aggregate relationship */
  hypertask_field_options_aggregate: Hypertask_Field_Option_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  option_media: Array<Option_Media>;
  /** An aggregate relationship */
  option_media_aggregate: Option_Media_Aggregate;
  /** An array relationship */
  translations: Array<Option_Translation>;
  /** An aggregate relationship */
  translations_aggregate: Option_Translation_Aggregate;
  /** An object relationship */
  type: Types;
  type_id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user?: Maybe<Users>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "options" */
export type OptionsField_OptionsArgs = {
  distinct_on?: Maybe<Array<Field_Options_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Field_Options_Order_By>>;
  where?: Maybe<Field_Options_Bool_Exp>;
};


/** columns and relationships of "options" */
export type OptionsField_Options_AggregateArgs = {
  distinct_on?: Maybe<Array<Field_Options_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Field_Options_Order_By>>;
  where?: Maybe<Field_Options_Bool_Exp>;
};


/** columns and relationships of "options" */
export type OptionsHypertask_Field_OptionsArgs = {
  distinct_on?: Maybe<Array<Hypertask_Field_Option_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hypertask_Field_Option_Order_By>>;
  where?: Maybe<Hypertask_Field_Option_Bool_Exp>;
};


/** columns and relationships of "options" */
export type OptionsHypertask_Field_Options_AggregateArgs = {
  distinct_on?: Maybe<Array<Hypertask_Field_Option_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hypertask_Field_Option_Order_By>>;
  where?: Maybe<Hypertask_Field_Option_Bool_Exp>;
};


/** columns and relationships of "options" */
export type OptionsOption_MediaArgs = {
  distinct_on?: Maybe<Array<Option_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Option_Media_Order_By>>;
  where?: Maybe<Option_Media_Bool_Exp>;
};


/** columns and relationships of "options" */
export type OptionsOption_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Option_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Option_Media_Order_By>>;
  where?: Maybe<Option_Media_Bool_Exp>;
};


/** columns and relationships of "options" */
export type OptionsTranslationsArgs = {
  distinct_on?: Maybe<Array<Option_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Option_Translation_Order_By>>;
  where?: Maybe<Option_Translation_Bool_Exp>;
};


/** columns and relationships of "options" */
export type OptionsTranslations_AggregateArgs = {
  distinct_on?: Maybe<Array<Option_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Option_Translation_Order_By>>;
  where?: Maybe<Option_Translation_Bool_Exp>;
};


/** columns and relationships of "options" */
export type OptionsUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** columns and relationships of "options" */
export type OptionsUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};

/** aggregated selection of "options" */
export type Options_Aggregate = {
  __typename?: 'options_aggregate';
  aggregate?: Maybe<Options_Aggregate_Fields>;
  nodes: Array<Options>;
};

/** aggregate fields of "options" */
export type Options_Aggregate_Fields = {
  __typename?: 'options_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Options_Max_Fields>;
  min?: Maybe<Options_Min_Fields>;
};


/** aggregate fields of "options" */
export type Options_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Options_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "options" */
export type Options_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Options_Max_Order_By>;
  min?: Maybe<Options_Min_Order_By>;
};

/** input type for inserting array relation for remote table "options" */
export type Options_Arr_Rel_Insert_Input = {
  data: Array<Options_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Options_On_Conflict>;
};

/** Boolean expression to filter rows from the table "options". All fields are combined with a logical 'AND'. */
export type Options_Bool_Exp = {
  _and?: Maybe<Array<Options_Bool_Exp>>;
  _not?: Maybe<Options_Bool_Exp>;
  _or?: Maybe<Array<Options_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  display?: Maybe<String_Comparison_Exp>;
  field_options?: Maybe<Field_Options_Bool_Exp>;
  hypertask_field_options?: Maybe<Hypertask_Field_Option_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  option_media?: Maybe<Option_Media_Bool_Exp>;
  translations?: Maybe<Option_Translation_Bool_Exp>;
  type?: Maybe<Types_Bool_Exp>;
  type_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  users?: Maybe<Users_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "options" */
export enum Options_Constraint {
  /** unique or primary key constraint on columns "id", "type_id" */
  OptionsIdTypeIdUnique = 'options_id_type_id_unique',
  /** unique or primary key constraint on columns "id" */
  OptionsPkey = 'options_pkey',
  /** unique or primary key constraint on columns "display", "value" */
  OptionsValueDisplayUnique = 'options_value_display_unique'
}

/** input type for inserting data into table "options" */
export type Options_Insert_Input = {
  display?: Maybe<Scalars['String']>;
  field_options?: Maybe<Field_Options_Arr_Rel_Insert_Input>;
  hypertask_field_options?: Maybe<Hypertask_Field_Option_Arr_Rel_Insert_Input>;
  option_media?: Maybe<Option_Media_Arr_Rel_Insert_Input>;
  translations?: Maybe<Option_Translation_Arr_Rel_Insert_Input>;
  type?: Maybe<Types_Obj_Rel_Insert_Input>;
  type_id?: Maybe<Scalars['uuid']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  users?: Maybe<Users_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Options_Max_Fields = {
  __typename?: 'options_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  display?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  type_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "options" */
export type Options_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  display?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  type_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Options_Min_Fields = {
  __typename?: 'options_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  display?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  type_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "options" */
export type Options_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  display?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  type_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "options" */
export type Options_Mutation_Response = {
  __typename?: 'options_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Options>;
};

/** input type for inserting object relation for remote table "options" */
export type Options_Obj_Rel_Insert_Input = {
  data: Options_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Options_On_Conflict>;
};

/** on_conflict condition type for table "options" */
export type Options_On_Conflict = {
  constraint: Options_Constraint;
  update_columns?: Array<Options_Update_Column>;
  where?: Maybe<Options_Bool_Exp>;
};

/** Ordering options when selecting data from "options". */
export type Options_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  display?: Maybe<Order_By>;
  field_options_aggregate?: Maybe<Field_Options_Aggregate_Order_By>;
  hypertask_field_options_aggregate?: Maybe<Hypertask_Field_Option_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  option_media_aggregate?: Maybe<Option_Media_Aggregate_Order_By>;
  translations_aggregate?: Maybe<Option_Translation_Aggregate_Order_By>;
  type?: Maybe<Types_Order_By>;
  type_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  users_aggregate?: Maybe<Users_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: options */
export type Options_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "options" */
export enum Options_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Display = 'display',
  /** column name */
  Id = 'id',
  /** column name */
  TypeId = 'type_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "options" */
export type Options_Set_Input = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  display?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  type_id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "options" */
export type Options_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Options_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Options_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  display?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  type_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "options" */
export enum Options_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Display = 'display',
  /** column name */
  Id = 'id',
  /** column name */
  TypeId = 'type_id',
  /** column name */
  Value = 'value'
}

export type Options_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Options_Set_Input>;
  where: Options_Bool_Exp;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "payload_recording" */
export type Payload_Recording = {
  __typename?: 'payload_recording';
  /** An object relationship */
  payload: Payloads;
  payload_id: Scalars['uuid'];
  /** An object relationship */
  recording: Recordings;
  recording_id: Scalars['uuid'];
};

/** aggregated selection of "payload_recording" */
export type Payload_Recording_Aggregate = {
  __typename?: 'payload_recording_aggregate';
  aggregate?: Maybe<Payload_Recording_Aggregate_Fields>;
  nodes: Array<Payload_Recording>;
};

/** aggregate fields of "payload_recording" */
export type Payload_Recording_Aggregate_Fields = {
  __typename?: 'payload_recording_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Payload_Recording_Max_Fields>;
  min?: Maybe<Payload_Recording_Min_Fields>;
};


/** aggregate fields of "payload_recording" */
export type Payload_Recording_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Payload_Recording_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "payload_recording" */
export type Payload_Recording_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Payload_Recording_Max_Order_By>;
  min?: Maybe<Payload_Recording_Min_Order_By>;
};

/** input type for inserting array relation for remote table "payload_recording" */
export type Payload_Recording_Arr_Rel_Insert_Input = {
  data: Array<Payload_Recording_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Payload_Recording_On_Conflict>;
};

/** Boolean expression to filter rows from the table "payload_recording". All fields are combined with a logical 'AND'. */
export type Payload_Recording_Bool_Exp = {
  _and?: Maybe<Array<Payload_Recording_Bool_Exp>>;
  _not?: Maybe<Payload_Recording_Bool_Exp>;
  _or?: Maybe<Array<Payload_Recording_Bool_Exp>>;
  payload?: Maybe<Payloads_Bool_Exp>;
  payload_id?: Maybe<Uuid_Comparison_Exp>;
  recording?: Maybe<Recordings_Bool_Exp>;
  recording_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "payload_recording" */
export enum Payload_Recording_Constraint {
  /** unique or primary key constraint on columns "payload_id", "recording_id" */
  PayloadRecordingPkey = 'payload_recording_pkey'
}

/** input type for inserting data into table "payload_recording" */
export type Payload_Recording_Insert_Input = {
  payload?: Maybe<Payloads_Obj_Rel_Insert_Input>;
  payload_id?: Maybe<Scalars['uuid']>;
  recording?: Maybe<Recordings_Obj_Rel_Insert_Input>;
  recording_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Payload_Recording_Max_Fields = {
  __typename?: 'payload_recording_max_fields';
  payload_id?: Maybe<Scalars['uuid']>;
  recording_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "payload_recording" */
export type Payload_Recording_Max_Order_By = {
  payload_id?: Maybe<Order_By>;
  recording_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Payload_Recording_Min_Fields = {
  __typename?: 'payload_recording_min_fields';
  payload_id?: Maybe<Scalars['uuid']>;
  recording_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "payload_recording" */
export type Payload_Recording_Min_Order_By = {
  payload_id?: Maybe<Order_By>;
  recording_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "payload_recording" */
export type Payload_Recording_Mutation_Response = {
  __typename?: 'payload_recording_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payload_Recording>;
};

/** on_conflict condition type for table "payload_recording" */
export type Payload_Recording_On_Conflict = {
  constraint: Payload_Recording_Constraint;
  update_columns?: Array<Payload_Recording_Update_Column>;
  where?: Maybe<Payload_Recording_Bool_Exp>;
};

/** Ordering options when selecting data from "payload_recording". */
export type Payload_Recording_Order_By = {
  payload?: Maybe<Payloads_Order_By>;
  payload_id?: Maybe<Order_By>;
  recording?: Maybe<Recordings_Order_By>;
  recording_id?: Maybe<Order_By>;
};

/** primary key columns input for table: payload_recording */
export type Payload_Recording_Pk_Columns_Input = {
  payload_id: Scalars['uuid'];
  recording_id: Scalars['uuid'];
};

/** select columns of table "payload_recording" */
export enum Payload_Recording_Select_Column {
  /** column name */
  PayloadId = 'payload_id',
  /** column name */
  RecordingId = 'recording_id'
}

/** input type for updating data in table "payload_recording" */
export type Payload_Recording_Set_Input = {
  payload_id?: Maybe<Scalars['uuid']>;
  recording_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "payload_recording" */
export type Payload_Recording_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payload_Recording_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payload_Recording_Stream_Cursor_Value_Input = {
  payload_id?: Maybe<Scalars['uuid']>;
  recording_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "payload_recording" */
export enum Payload_Recording_Update_Column {
  /** column name */
  PayloadId = 'payload_id',
  /** column name */
  RecordingId = 'recording_id'
}

export type Payload_Recording_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Payload_Recording_Set_Input>;
  where: Payload_Recording_Bool_Exp;
};

/** columns and relationships of "payload_types" */
export type Payload_Types = {
  __typename?: 'payload_types';
  comment: Scalars['String'];
  id: Scalars['String'];
  /** An array relationship */
  payloads: Array<Payloads>;
  /** An aggregate relationship */
  payloads_aggregate: Payloads_Aggregate;
};


/** columns and relationships of "payload_types" */
export type Payload_TypesPayloadsArgs = {
  distinct_on?: Maybe<Array<Payloads_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payloads_Order_By>>;
  where?: Maybe<Payloads_Bool_Exp>;
};


/** columns and relationships of "payload_types" */
export type Payload_TypesPayloads_AggregateArgs = {
  distinct_on?: Maybe<Array<Payloads_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payloads_Order_By>>;
  where?: Maybe<Payloads_Bool_Exp>;
};

/** aggregated selection of "payload_types" */
export type Payload_Types_Aggregate = {
  __typename?: 'payload_types_aggregate';
  aggregate?: Maybe<Payload_Types_Aggregate_Fields>;
  nodes: Array<Payload_Types>;
};

/** aggregate fields of "payload_types" */
export type Payload_Types_Aggregate_Fields = {
  __typename?: 'payload_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Payload_Types_Max_Fields>;
  min?: Maybe<Payload_Types_Min_Fields>;
};


/** aggregate fields of "payload_types" */
export type Payload_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Payload_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "payload_types". All fields are combined with a logical 'AND'. */
export type Payload_Types_Bool_Exp = {
  _and?: Maybe<Array<Payload_Types_Bool_Exp>>;
  _not?: Maybe<Payload_Types_Bool_Exp>;
  _or?: Maybe<Array<Payload_Types_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  payloads?: Maybe<Payloads_Bool_Exp>;
};

export enum Payload_Types_Enum {
  /** Alethea distance data */
  AletheaDistance = 'alethea_distance',
  /** processed payload data for display in Arcadia */
  ArcadiaFeatures = 'arcadia_features',
  /** Argus camera data */
  ArgusCamera = 'argus_camera',
  /** Unprocessed Argus camera data */
  ArgusCameraUnprocessed = 'argus_camera_unprocessed',
  /** Argus depth data */
  ArgusDepth = 'argus_depth',
  /** Argus distance data */
  ArgusDistance = 'argus_distance',
  /** data from audio measurement recording */
  AudioMeasurementData = 'audio_measurement_data',
  /** Data output by Elliptic engines */
  BinpayloadData = 'binpayload_data',
  /** data collected from device under test */
  OnDevice = 'on_device',
  /** Recorder webcam stream */
  RecorderCamera = 'recorder_camera',
  /** Windows Diagnostic log */
  WindowsDiagnosticLog = 'windows_diagnostic_log'
}

/** Boolean expression to compare columns of type "payload_types_enum". All fields are combined with logical 'AND'. */
export type Payload_Types_Enum_Comparison_Exp = {
  _eq?: Maybe<Payload_Types_Enum>;
  _in?: Maybe<Array<Payload_Types_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Payload_Types_Enum>;
  _nin?: Maybe<Array<Payload_Types_Enum>>;
};

/** aggregate max on columns */
export type Payload_Types_Max_Fields = {
  __typename?: 'payload_types_max_fields';
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Payload_Types_Min_Fields = {
  __typename?: 'payload_types_min_fields';
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "payload_types" */
export type Payload_Types_Mutation_Response = {
  __typename?: 'payload_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payload_Types>;
};

/** Ordering options when selecting data from "payload_types". */
export type Payload_Types_Order_By = {
  comment?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payloads_aggregate?: Maybe<Payloads_Aggregate_Order_By>;
};

/** primary key columns input for table: payload_types */
export type Payload_Types_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "payload_types" */
export enum Payload_Types_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "payload_types" */
export type Payload_Types_Set_Input = {
  comment?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "payload_types" */
export type Payload_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payload_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payload_Types_Stream_Cursor_Value_Input = {
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type Payload_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Payload_Types_Set_Input>;
  where: Payload_Types_Bool_Exp;
};

/** columns and relationships of "payloads" */
export type Payloads = {
  __typename?: 'payloads';
  /** An array relationship */
  audio_measurements_payload_recordings: Array<Audio_Measurements_Payload_Recording>;
  /** An aggregate relationship */
  audio_measurements_payload_recordings_aggregate: Audio_Measurements_Payload_Recording_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  filename: Scalars['String'];
  first_payload: Scalars['bigint'];
  hash: Scalars['String'];
  id: Scalars['uuid'];
  last_payload: Scalars['bigint'];
  length: Scalars['Int'];
  /** An array relationship */
  payload_recordings: Array<Payload_Recording>;
  /** An aggregate relationship */
  payload_recordings_aggregate: Payload_Recording_Aggregate;
  /** An object relationship */
  payload_type?: Maybe<Payload_Types>;
  payload_type_id?: Maybe<Payload_Types_Enum>;
  storage_container?: Maybe<Scalars['String']>;
  synced: Scalars['Boolean'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "payloads" */
export type PayloadsAudio_Measurements_Payload_RecordingsArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Payload_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Payload_Recording_Order_By>>;
  where?: Maybe<Audio_Measurements_Payload_Recording_Bool_Exp>;
};


/** columns and relationships of "payloads" */
export type PayloadsAudio_Measurements_Payload_Recordings_AggregateArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Payload_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Payload_Recording_Order_By>>;
  where?: Maybe<Audio_Measurements_Payload_Recording_Bool_Exp>;
};


/** columns and relationships of "payloads" */
export type PayloadsPayload_RecordingsArgs = {
  distinct_on?: Maybe<Array<Payload_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payload_Recording_Order_By>>;
  where?: Maybe<Payload_Recording_Bool_Exp>;
};


/** columns and relationships of "payloads" */
export type PayloadsPayload_Recordings_AggregateArgs = {
  distinct_on?: Maybe<Array<Payload_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payload_Recording_Order_By>>;
  where?: Maybe<Payload_Recording_Bool_Exp>;
};

/** aggregated selection of "payloads" */
export type Payloads_Aggregate = {
  __typename?: 'payloads_aggregate';
  aggregate?: Maybe<Payloads_Aggregate_Fields>;
  nodes: Array<Payloads>;
};

/** aggregate fields of "payloads" */
export type Payloads_Aggregate_Fields = {
  __typename?: 'payloads_aggregate_fields';
  avg?: Maybe<Payloads_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Payloads_Max_Fields>;
  min?: Maybe<Payloads_Min_Fields>;
  stddev?: Maybe<Payloads_Stddev_Fields>;
  stddev_pop?: Maybe<Payloads_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Payloads_Stddev_Samp_Fields>;
  sum?: Maybe<Payloads_Sum_Fields>;
  var_pop?: Maybe<Payloads_Var_Pop_Fields>;
  var_samp?: Maybe<Payloads_Var_Samp_Fields>;
  variance?: Maybe<Payloads_Variance_Fields>;
};


/** aggregate fields of "payloads" */
export type Payloads_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Payloads_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "payloads" */
export type Payloads_Aggregate_Order_By = {
  avg?: Maybe<Payloads_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Payloads_Max_Order_By>;
  min?: Maybe<Payloads_Min_Order_By>;
  stddev?: Maybe<Payloads_Stddev_Order_By>;
  stddev_pop?: Maybe<Payloads_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Payloads_Stddev_Samp_Order_By>;
  sum?: Maybe<Payloads_Sum_Order_By>;
  var_pop?: Maybe<Payloads_Var_Pop_Order_By>;
  var_samp?: Maybe<Payloads_Var_Samp_Order_By>;
  variance?: Maybe<Payloads_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Payloads_Avg_Fields = {
  __typename?: 'payloads_avg_fields';
  first_payload?: Maybe<Scalars['Float']>;
  last_payload?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "payloads" */
export type Payloads_Avg_Order_By = {
  first_payload?: Maybe<Order_By>;
  last_payload?: Maybe<Order_By>;
  length?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "payloads". All fields are combined with a logical 'AND'. */
export type Payloads_Bool_Exp = {
  _and?: Maybe<Array<Payloads_Bool_Exp>>;
  _not?: Maybe<Payloads_Bool_Exp>;
  _or?: Maybe<Array<Payloads_Bool_Exp>>;
  audio_measurements_payload_recordings?: Maybe<Audio_Measurements_Payload_Recording_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  filename?: Maybe<String_Comparison_Exp>;
  first_payload?: Maybe<Bigint_Comparison_Exp>;
  hash?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  last_payload?: Maybe<Bigint_Comparison_Exp>;
  length?: Maybe<Int_Comparison_Exp>;
  payload_recordings?: Maybe<Payload_Recording_Bool_Exp>;
  payload_type?: Maybe<Payload_Types_Bool_Exp>;
  payload_type_id?: Maybe<Payload_Types_Enum_Comparison_Exp>;
  storage_container?: Maybe<String_Comparison_Exp>;
  synced?: Maybe<Boolean_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "payloads" */
export enum Payloads_Constraint {
  /** unique or primary key constraint on columns "filename" */
  PayloadsFilenameUnique = 'payloads_filename_unique',
  /** unique or primary key constraint on columns "id" */
  PayloadsPkey = 'payloads_pkey'
}

/** input type for incrementing numeric columns in table "payloads" */
export type Payloads_Inc_Input = {
  first_payload?: Maybe<Scalars['bigint']>;
  last_payload?: Maybe<Scalars['bigint']>;
  length?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "payloads" */
export type Payloads_Insert_Input = {
  audio_measurements_payload_recordings?: Maybe<Audio_Measurements_Payload_Recording_Arr_Rel_Insert_Input>;
  filename?: Maybe<Scalars['String']>;
  first_payload?: Maybe<Scalars['bigint']>;
  hash?: Maybe<Scalars['String']>;
  last_payload?: Maybe<Scalars['bigint']>;
  length?: Maybe<Scalars['Int']>;
  payload_recordings?: Maybe<Payload_Recording_Arr_Rel_Insert_Input>;
  payload_type_id?: Maybe<Payload_Types_Enum>;
  storage_container?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Payloads_Max_Fields = {
  __typename?: 'payloads_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  filename?: Maybe<Scalars['String']>;
  first_payload?: Maybe<Scalars['bigint']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_payload?: Maybe<Scalars['bigint']>;
  length?: Maybe<Scalars['Int']>;
  storage_container?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "payloads" */
export type Payloads_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  first_payload?: Maybe<Order_By>;
  hash?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_payload?: Maybe<Order_By>;
  length?: Maybe<Order_By>;
  storage_container?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Payloads_Min_Fields = {
  __typename?: 'payloads_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  filename?: Maybe<Scalars['String']>;
  first_payload?: Maybe<Scalars['bigint']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_payload?: Maybe<Scalars['bigint']>;
  length?: Maybe<Scalars['Int']>;
  storage_container?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "payloads" */
export type Payloads_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  first_payload?: Maybe<Order_By>;
  hash?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_payload?: Maybe<Order_By>;
  length?: Maybe<Order_By>;
  storage_container?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "payloads" */
export type Payloads_Mutation_Response = {
  __typename?: 'payloads_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payloads>;
};

/** input type for inserting object relation for remote table "payloads" */
export type Payloads_Obj_Rel_Insert_Input = {
  data: Payloads_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Payloads_On_Conflict>;
};

/** on_conflict condition type for table "payloads" */
export type Payloads_On_Conflict = {
  constraint: Payloads_Constraint;
  update_columns?: Array<Payloads_Update_Column>;
  where?: Maybe<Payloads_Bool_Exp>;
};

/** Ordering options when selecting data from "payloads". */
export type Payloads_Order_By = {
  audio_measurements_payload_recordings_aggregate?: Maybe<Audio_Measurements_Payload_Recording_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  first_payload?: Maybe<Order_By>;
  hash?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_payload?: Maybe<Order_By>;
  length?: Maybe<Order_By>;
  payload_recordings_aggregate?: Maybe<Payload_Recording_Aggregate_Order_By>;
  payload_type?: Maybe<Payload_Types_Order_By>;
  payload_type_id?: Maybe<Order_By>;
  storage_container?: Maybe<Order_By>;
  synced?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: payloads */
export type Payloads_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "payloads" */
export enum Payloads_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Filename = 'filename',
  /** column name */
  FirstPayload = 'first_payload',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  LastPayload = 'last_payload',
  /** column name */
  Length = 'length',
  /** column name */
  PayloadTypeId = 'payload_type_id',
  /** column name */
  StorageContainer = 'storage_container',
  /** column name */
  Synced = 'synced',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "payloads" */
export type Payloads_Set_Input = {
  filename?: Maybe<Scalars['String']>;
  first_payload?: Maybe<Scalars['bigint']>;
  hash?: Maybe<Scalars['String']>;
  last_payload?: Maybe<Scalars['bigint']>;
  length?: Maybe<Scalars['Int']>;
  payload_type_id?: Maybe<Payload_Types_Enum>;
  storage_container?: Maybe<Scalars['String']>;
  synced?: Maybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Payloads_Stddev_Fields = {
  __typename?: 'payloads_stddev_fields';
  first_payload?: Maybe<Scalars['Float']>;
  last_payload?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "payloads" */
export type Payloads_Stddev_Order_By = {
  first_payload?: Maybe<Order_By>;
  last_payload?: Maybe<Order_By>;
  length?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Payloads_Stddev_Pop_Fields = {
  __typename?: 'payloads_stddev_pop_fields';
  first_payload?: Maybe<Scalars['Float']>;
  last_payload?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "payloads" */
export type Payloads_Stddev_Pop_Order_By = {
  first_payload?: Maybe<Order_By>;
  last_payload?: Maybe<Order_By>;
  length?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Payloads_Stddev_Samp_Fields = {
  __typename?: 'payloads_stddev_samp_fields';
  first_payload?: Maybe<Scalars['Float']>;
  last_payload?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "payloads" */
export type Payloads_Stddev_Samp_Order_By = {
  first_payload?: Maybe<Order_By>;
  last_payload?: Maybe<Order_By>;
  length?: Maybe<Order_By>;
};

/** Streaming cursor of the table "payloads" */
export type Payloads_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payloads_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payloads_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  filename?: Maybe<Scalars['String']>;
  first_payload?: Maybe<Scalars['bigint']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_payload?: Maybe<Scalars['bigint']>;
  length?: Maybe<Scalars['Int']>;
  payload_type_id?: Maybe<Payload_Types_Enum>;
  storage_container?: Maybe<Scalars['String']>;
  synced?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Payloads_Sum_Fields = {
  __typename?: 'payloads_sum_fields';
  first_payload?: Maybe<Scalars['bigint']>;
  last_payload?: Maybe<Scalars['bigint']>;
  length?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "payloads" */
export type Payloads_Sum_Order_By = {
  first_payload?: Maybe<Order_By>;
  last_payload?: Maybe<Order_By>;
  length?: Maybe<Order_By>;
};

/** update columns of table "payloads" */
export enum Payloads_Update_Column {
  /** column name */
  Filename = 'filename',
  /** column name */
  FirstPayload = 'first_payload',
  /** column name */
  Hash = 'hash',
  /** column name */
  LastPayload = 'last_payload',
  /** column name */
  Length = 'length',
  /** column name */
  PayloadTypeId = 'payload_type_id',
  /** column name */
  StorageContainer = 'storage_container',
  /** column name */
  Synced = 'synced'
}

export type Payloads_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Payloads_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Payloads_Set_Input>;
  where: Payloads_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Payloads_Var_Pop_Fields = {
  __typename?: 'payloads_var_pop_fields';
  first_payload?: Maybe<Scalars['Float']>;
  last_payload?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "payloads" */
export type Payloads_Var_Pop_Order_By = {
  first_payload?: Maybe<Order_By>;
  last_payload?: Maybe<Order_By>;
  length?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Payloads_Var_Samp_Fields = {
  __typename?: 'payloads_var_samp_fields';
  first_payload?: Maybe<Scalars['Float']>;
  last_payload?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "payloads" */
export type Payloads_Var_Samp_Order_By = {
  first_payload?: Maybe<Order_By>;
  last_payload?: Maybe<Order_By>;
  length?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Payloads_Variance_Fields = {
  __typename?: 'payloads_variance_fields';
  first_payload?: Maybe<Scalars['Float']>;
  last_payload?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "payloads" */
export type Payloads_Variance_Order_By = {
  first_payload?: Maybe<Order_By>;
  last_payload?: Maybe<Order_By>;
  length?: Maybe<Order_By>;
};

/** Intermediate results of applying a ratio metric to the predictions of a single recording. */
export type Performance_Ratio_Metric_Contributions = {
  __typename?: 'performance_ratio_metric_contributions';
  denominator?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  engine: Engines;
  engine_id: Scalars['uuid'];
  num_samples: Scalars['Int'];
  numerator: Scalars['numeric'];
  /** An object relationship */
  ratio_metric: Performance_Ratio_Metrics;
  ratio_metric_id: Scalars['uuid'];
  ratio_metric_source_version: Scalars['String'];
  /** An object relationship */
  recording: Recordings;
  recording_id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "performance.ratio_metric_contributions" */
export type Performance_Ratio_Metric_Contributions_Aggregate = {
  __typename?: 'performance_ratio_metric_contributions_aggregate';
  aggregate?: Maybe<Performance_Ratio_Metric_Contributions_Aggregate_Fields>;
  nodes: Array<Performance_Ratio_Metric_Contributions>;
};

/** aggregate fields of "performance.ratio_metric_contributions" */
export type Performance_Ratio_Metric_Contributions_Aggregate_Fields = {
  __typename?: 'performance_ratio_metric_contributions_aggregate_fields';
  avg?: Maybe<Performance_Ratio_Metric_Contributions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Performance_Ratio_Metric_Contributions_Max_Fields>;
  min?: Maybe<Performance_Ratio_Metric_Contributions_Min_Fields>;
  stddev?: Maybe<Performance_Ratio_Metric_Contributions_Stddev_Fields>;
  stddev_pop?: Maybe<Performance_Ratio_Metric_Contributions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Performance_Ratio_Metric_Contributions_Stddev_Samp_Fields>;
  sum?: Maybe<Performance_Ratio_Metric_Contributions_Sum_Fields>;
  var_pop?: Maybe<Performance_Ratio_Metric_Contributions_Var_Pop_Fields>;
  var_samp?: Maybe<Performance_Ratio_Metric_Contributions_Var_Samp_Fields>;
  variance?: Maybe<Performance_Ratio_Metric_Contributions_Variance_Fields>;
};


/** aggregate fields of "performance.ratio_metric_contributions" */
export type Performance_Ratio_Metric_Contributions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Performance_Ratio_Metric_Contributions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "performance.ratio_metric_contributions" */
export type Performance_Ratio_Metric_Contributions_Aggregate_Order_By = {
  avg?: Maybe<Performance_Ratio_Metric_Contributions_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Performance_Ratio_Metric_Contributions_Max_Order_By>;
  min?: Maybe<Performance_Ratio_Metric_Contributions_Min_Order_By>;
  stddev?: Maybe<Performance_Ratio_Metric_Contributions_Stddev_Order_By>;
  stddev_pop?: Maybe<Performance_Ratio_Metric_Contributions_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Performance_Ratio_Metric_Contributions_Stddev_Samp_Order_By>;
  sum?: Maybe<Performance_Ratio_Metric_Contributions_Sum_Order_By>;
  var_pop?: Maybe<Performance_Ratio_Metric_Contributions_Var_Pop_Order_By>;
  var_samp?: Maybe<Performance_Ratio_Metric_Contributions_Var_Samp_Order_By>;
  variance?: Maybe<Performance_Ratio_Metric_Contributions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "performance.ratio_metric_contributions" */
export type Performance_Ratio_Metric_Contributions_Arr_Rel_Insert_Input = {
  data: Array<Performance_Ratio_Metric_Contributions_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Performance_Ratio_Metric_Contributions_On_Conflict>;
};

/** aggregate avg on columns */
export type Performance_Ratio_Metric_Contributions_Avg_Fields = {
  __typename?: 'performance_ratio_metric_contributions_avg_fields';
  denominator?: Maybe<Scalars['Float']>;
  num_samples?: Maybe<Scalars['Float']>;
  numerator?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "performance.ratio_metric_contributions" */
export type Performance_Ratio_Metric_Contributions_Avg_Order_By = {
  denominator?: Maybe<Order_By>;
  num_samples?: Maybe<Order_By>;
  numerator?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "performance.ratio_metric_contributions". All fields are combined with a logical 'AND'. */
export type Performance_Ratio_Metric_Contributions_Bool_Exp = {
  _and?: Maybe<Array<Performance_Ratio_Metric_Contributions_Bool_Exp>>;
  _not?: Maybe<Performance_Ratio_Metric_Contributions_Bool_Exp>;
  _or?: Maybe<Array<Performance_Ratio_Metric_Contributions_Bool_Exp>>;
  denominator?: Maybe<Numeric_Comparison_Exp>;
  engine?: Maybe<Engines_Bool_Exp>;
  engine_id?: Maybe<Uuid_Comparison_Exp>;
  num_samples?: Maybe<Int_Comparison_Exp>;
  numerator?: Maybe<Numeric_Comparison_Exp>;
  ratio_metric?: Maybe<Performance_Ratio_Metrics_Bool_Exp>;
  ratio_metric_id?: Maybe<Uuid_Comparison_Exp>;
  ratio_metric_source_version?: Maybe<String_Comparison_Exp>;
  recording?: Maybe<Recordings_Bool_Exp>;
  recording_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "performance.ratio_metric_contributions" */
export enum Performance_Ratio_Metric_Contributions_Constraint {
  /** unique or primary key constraint on columns "engine_id", "ratio_metric_id", "recording_id" */
  RatioMetricContributionsPkey = 'ratio_metric_contributions_pkey'
}

/** input type for incrementing numeric columns in table "performance.ratio_metric_contributions" */
export type Performance_Ratio_Metric_Contributions_Inc_Input = {
  denominator?: Maybe<Scalars['numeric']>;
  num_samples?: Maybe<Scalars['Int']>;
  numerator?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "performance.ratio_metric_contributions" */
export type Performance_Ratio_Metric_Contributions_Insert_Input = {
  denominator?: Maybe<Scalars['numeric']>;
  engine?: Maybe<Engines_Obj_Rel_Insert_Input>;
  engine_id?: Maybe<Scalars['uuid']>;
  num_samples?: Maybe<Scalars['Int']>;
  numerator?: Maybe<Scalars['numeric']>;
  ratio_metric?: Maybe<Performance_Ratio_Metrics_Obj_Rel_Insert_Input>;
  ratio_metric_id?: Maybe<Scalars['uuid']>;
  ratio_metric_source_version?: Maybe<Scalars['String']>;
  recording?: Maybe<Recordings_Obj_Rel_Insert_Input>;
  recording_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Performance_Ratio_Metric_Contributions_Max_Fields = {
  __typename?: 'performance_ratio_metric_contributions_max_fields';
  denominator?: Maybe<Scalars['numeric']>;
  engine_id?: Maybe<Scalars['uuid']>;
  num_samples?: Maybe<Scalars['Int']>;
  numerator?: Maybe<Scalars['numeric']>;
  ratio_metric_id?: Maybe<Scalars['uuid']>;
  ratio_metric_source_version?: Maybe<Scalars['String']>;
  recording_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "performance.ratio_metric_contributions" */
export type Performance_Ratio_Metric_Contributions_Max_Order_By = {
  denominator?: Maybe<Order_By>;
  engine_id?: Maybe<Order_By>;
  num_samples?: Maybe<Order_By>;
  numerator?: Maybe<Order_By>;
  ratio_metric_id?: Maybe<Order_By>;
  ratio_metric_source_version?: Maybe<Order_By>;
  recording_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Performance_Ratio_Metric_Contributions_Min_Fields = {
  __typename?: 'performance_ratio_metric_contributions_min_fields';
  denominator?: Maybe<Scalars['numeric']>;
  engine_id?: Maybe<Scalars['uuid']>;
  num_samples?: Maybe<Scalars['Int']>;
  numerator?: Maybe<Scalars['numeric']>;
  ratio_metric_id?: Maybe<Scalars['uuid']>;
  ratio_metric_source_version?: Maybe<Scalars['String']>;
  recording_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "performance.ratio_metric_contributions" */
export type Performance_Ratio_Metric_Contributions_Min_Order_By = {
  denominator?: Maybe<Order_By>;
  engine_id?: Maybe<Order_By>;
  num_samples?: Maybe<Order_By>;
  numerator?: Maybe<Order_By>;
  ratio_metric_id?: Maybe<Order_By>;
  ratio_metric_source_version?: Maybe<Order_By>;
  recording_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "performance.ratio_metric_contributions" */
export type Performance_Ratio_Metric_Contributions_Mutation_Response = {
  __typename?: 'performance_ratio_metric_contributions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Performance_Ratio_Metric_Contributions>;
};

/** on_conflict condition type for table "performance.ratio_metric_contributions" */
export type Performance_Ratio_Metric_Contributions_On_Conflict = {
  constraint: Performance_Ratio_Metric_Contributions_Constraint;
  update_columns?: Array<Performance_Ratio_Metric_Contributions_Update_Column>;
  where?: Maybe<Performance_Ratio_Metric_Contributions_Bool_Exp>;
};

/** Ordering options when selecting data from "performance.ratio_metric_contributions". */
export type Performance_Ratio_Metric_Contributions_Order_By = {
  denominator?: Maybe<Order_By>;
  engine?: Maybe<Engines_Order_By>;
  engine_id?: Maybe<Order_By>;
  num_samples?: Maybe<Order_By>;
  numerator?: Maybe<Order_By>;
  ratio_metric?: Maybe<Performance_Ratio_Metrics_Order_By>;
  ratio_metric_id?: Maybe<Order_By>;
  ratio_metric_source_version?: Maybe<Order_By>;
  recording?: Maybe<Recordings_Order_By>;
  recording_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: performance.ratio_metric_contributions */
export type Performance_Ratio_Metric_Contributions_Pk_Columns_Input = {
  engine_id: Scalars['uuid'];
  ratio_metric_id: Scalars['uuid'];
  recording_id: Scalars['uuid'];
};

/** select columns of table "performance.ratio_metric_contributions" */
export enum Performance_Ratio_Metric_Contributions_Select_Column {
  /** column name */
  Denominator = 'denominator',
  /** column name */
  EngineId = 'engine_id',
  /** column name */
  NumSamples = 'num_samples',
  /** column name */
  Numerator = 'numerator',
  /** column name */
  RatioMetricId = 'ratio_metric_id',
  /** column name */
  RatioMetricSourceVersion = 'ratio_metric_source_version',
  /** column name */
  RecordingId = 'recording_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "performance.ratio_metric_contributions" */
export type Performance_Ratio_Metric_Contributions_Set_Input = {
  denominator?: Maybe<Scalars['numeric']>;
  engine_id?: Maybe<Scalars['uuid']>;
  num_samples?: Maybe<Scalars['Int']>;
  numerator?: Maybe<Scalars['numeric']>;
  ratio_metric_id?: Maybe<Scalars['uuid']>;
  ratio_metric_source_version?: Maybe<Scalars['String']>;
  recording_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Performance_Ratio_Metric_Contributions_Stddev_Fields = {
  __typename?: 'performance_ratio_metric_contributions_stddev_fields';
  denominator?: Maybe<Scalars['Float']>;
  num_samples?: Maybe<Scalars['Float']>;
  numerator?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "performance.ratio_metric_contributions" */
export type Performance_Ratio_Metric_Contributions_Stddev_Order_By = {
  denominator?: Maybe<Order_By>;
  num_samples?: Maybe<Order_By>;
  numerator?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Performance_Ratio_Metric_Contributions_Stddev_Pop_Fields = {
  __typename?: 'performance_ratio_metric_contributions_stddev_pop_fields';
  denominator?: Maybe<Scalars['Float']>;
  num_samples?: Maybe<Scalars['Float']>;
  numerator?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "performance.ratio_metric_contributions" */
export type Performance_Ratio_Metric_Contributions_Stddev_Pop_Order_By = {
  denominator?: Maybe<Order_By>;
  num_samples?: Maybe<Order_By>;
  numerator?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Performance_Ratio_Metric_Contributions_Stddev_Samp_Fields = {
  __typename?: 'performance_ratio_metric_contributions_stddev_samp_fields';
  denominator?: Maybe<Scalars['Float']>;
  num_samples?: Maybe<Scalars['Float']>;
  numerator?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "performance.ratio_metric_contributions" */
export type Performance_Ratio_Metric_Contributions_Stddev_Samp_Order_By = {
  denominator?: Maybe<Order_By>;
  num_samples?: Maybe<Order_By>;
  numerator?: Maybe<Order_By>;
};

/** Streaming cursor of the table "performance_ratio_metric_contributions" */
export type Performance_Ratio_Metric_Contributions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Performance_Ratio_Metric_Contributions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Performance_Ratio_Metric_Contributions_Stream_Cursor_Value_Input = {
  denominator?: Maybe<Scalars['numeric']>;
  engine_id?: Maybe<Scalars['uuid']>;
  num_samples?: Maybe<Scalars['Int']>;
  numerator?: Maybe<Scalars['numeric']>;
  ratio_metric_id?: Maybe<Scalars['uuid']>;
  ratio_metric_source_version?: Maybe<Scalars['String']>;
  recording_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Performance_Ratio_Metric_Contributions_Sum_Fields = {
  __typename?: 'performance_ratio_metric_contributions_sum_fields';
  denominator?: Maybe<Scalars['numeric']>;
  num_samples?: Maybe<Scalars['Int']>;
  numerator?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "performance.ratio_metric_contributions" */
export type Performance_Ratio_Metric_Contributions_Sum_Order_By = {
  denominator?: Maybe<Order_By>;
  num_samples?: Maybe<Order_By>;
  numerator?: Maybe<Order_By>;
};

/** update columns of table "performance.ratio_metric_contributions" */
export enum Performance_Ratio_Metric_Contributions_Update_Column {
  /** column name */
  Denominator = 'denominator',
  /** column name */
  EngineId = 'engine_id',
  /** column name */
  NumSamples = 'num_samples',
  /** column name */
  Numerator = 'numerator',
  /** column name */
  RatioMetricId = 'ratio_metric_id',
  /** column name */
  RatioMetricSourceVersion = 'ratio_metric_source_version',
  /** column name */
  RecordingId = 'recording_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Performance_Ratio_Metric_Contributions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Performance_Ratio_Metric_Contributions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Performance_Ratio_Metric_Contributions_Set_Input>;
  where: Performance_Ratio_Metric_Contributions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Performance_Ratio_Metric_Contributions_Var_Pop_Fields = {
  __typename?: 'performance_ratio_metric_contributions_var_pop_fields';
  denominator?: Maybe<Scalars['Float']>;
  num_samples?: Maybe<Scalars['Float']>;
  numerator?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "performance.ratio_metric_contributions" */
export type Performance_Ratio_Metric_Contributions_Var_Pop_Order_By = {
  denominator?: Maybe<Order_By>;
  num_samples?: Maybe<Order_By>;
  numerator?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Performance_Ratio_Metric_Contributions_Var_Samp_Fields = {
  __typename?: 'performance_ratio_metric_contributions_var_samp_fields';
  denominator?: Maybe<Scalars['Float']>;
  num_samples?: Maybe<Scalars['Float']>;
  numerator?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "performance.ratio_metric_contributions" */
export type Performance_Ratio_Metric_Contributions_Var_Samp_Order_By = {
  denominator?: Maybe<Order_By>;
  num_samples?: Maybe<Order_By>;
  numerator?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Performance_Ratio_Metric_Contributions_Variance_Fields = {
  __typename?: 'performance_ratio_metric_contributions_variance_fields';
  denominator?: Maybe<Scalars['Float']>;
  num_samples?: Maybe<Scalars['Float']>;
  numerator?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "performance.ratio_metric_contributions" */
export type Performance_Ratio_Metric_Contributions_Variance_Order_By = {
  denominator?: Maybe<Order_By>;
  num_samples?: Maybe<Order_By>;
  numerator?: Maybe<Order_By>;
};

/** Definitions of various metrics that can be aggregated by summing numerator and denominator components, then dividing the sums. */
export type Performance_Ratio_Metrics = {
  __typename?: 'performance_ratio_metrics';
  created_at?: Maybe<Scalars['timestamptz']>;
  critical_limit?: Maybe<Scalars['numeric']>;
  denominator_unit?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  display_as_percentage: Scalars['Boolean'];
  id: Scalars['uuid'];
  larger_is_better: Scalars['Boolean'];
  min_num_samples: Scalars['Int'];
  name: Scalars['String'];
  numerator_unit: Scalars['String'];
  sample_unit?: Maybe<Scalars['String']>;
  source_version: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  warning_limit?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "performance.ratio_metrics" */
export type Performance_Ratio_Metrics_Aggregate = {
  __typename?: 'performance_ratio_metrics_aggregate';
  aggregate?: Maybe<Performance_Ratio_Metrics_Aggregate_Fields>;
  nodes: Array<Performance_Ratio_Metrics>;
};

/** aggregate fields of "performance.ratio_metrics" */
export type Performance_Ratio_Metrics_Aggregate_Fields = {
  __typename?: 'performance_ratio_metrics_aggregate_fields';
  avg?: Maybe<Performance_Ratio_Metrics_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Performance_Ratio_Metrics_Max_Fields>;
  min?: Maybe<Performance_Ratio_Metrics_Min_Fields>;
  stddev?: Maybe<Performance_Ratio_Metrics_Stddev_Fields>;
  stddev_pop?: Maybe<Performance_Ratio_Metrics_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Performance_Ratio_Metrics_Stddev_Samp_Fields>;
  sum?: Maybe<Performance_Ratio_Metrics_Sum_Fields>;
  var_pop?: Maybe<Performance_Ratio_Metrics_Var_Pop_Fields>;
  var_samp?: Maybe<Performance_Ratio_Metrics_Var_Samp_Fields>;
  variance?: Maybe<Performance_Ratio_Metrics_Variance_Fields>;
};


/** aggregate fields of "performance.ratio_metrics" */
export type Performance_Ratio_Metrics_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Performance_Ratio_Metrics_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Performance_Ratio_Metrics_Avg_Fields = {
  __typename?: 'performance_ratio_metrics_avg_fields';
  critical_limit?: Maybe<Scalars['Float']>;
  min_num_samples?: Maybe<Scalars['Float']>;
  warning_limit?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "performance.ratio_metrics". All fields are combined with a logical 'AND'. */
export type Performance_Ratio_Metrics_Bool_Exp = {
  _and?: Maybe<Array<Performance_Ratio_Metrics_Bool_Exp>>;
  _not?: Maybe<Performance_Ratio_Metrics_Bool_Exp>;
  _or?: Maybe<Array<Performance_Ratio_Metrics_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  critical_limit?: Maybe<Numeric_Comparison_Exp>;
  denominator_unit?: Maybe<String_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  display_as_percentage?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  larger_is_better?: Maybe<Boolean_Comparison_Exp>;
  min_num_samples?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  numerator_unit?: Maybe<String_Comparison_Exp>;
  sample_unit?: Maybe<String_Comparison_Exp>;
  source_version?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  warning_limit?: Maybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "performance.ratio_metrics" */
export enum Performance_Ratio_Metrics_Constraint {
  /** unique or primary key constraint on columns "name" */
  RatioMetricsNameKey = 'ratio_metrics_name_key',
  /** unique or primary key constraint on columns "id" */
  RatioMetricsPkey = 'ratio_metrics_pkey'
}

/** input type for incrementing numeric columns in table "performance.ratio_metrics" */
export type Performance_Ratio_Metrics_Inc_Input = {
  critical_limit?: Maybe<Scalars['numeric']>;
  min_num_samples?: Maybe<Scalars['Int']>;
  warning_limit?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "performance.ratio_metrics" */
export type Performance_Ratio_Metrics_Insert_Input = {
  critical_limit?: Maybe<Scalars['numeric']>;
  denominator_unit?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  display_as_percentage?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  larger_is_better?: Maybe<Scalars['Boolean']>;
  min_num_samples?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  numerator_unit?: Maybe<Scalars['String']>;
  sample_unit?: Maybe<Scalars['String']>;
  source_version?: Maybe<Scalars['String']>;
  warning_limit?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Performance_Ratio_Metrics_Max_Fields = {
  __typename?: 'performance_ratio_metrics_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  critical_limit?: Maybe<Scalars['numeric']>;
  denominator_unit?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  min_num_samples?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  numerator_unit?: Maybe<Scalars['String']>;
  sample_unit?: Maybe<Scalars['String']>;
  source_version?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  warning_limit?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Performance_Ratio_Metrics_Min_Fields = {
  __typename?: 'performance_ratio_metrics_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  critical_limit?: Maybe<Scalars['numeric']>;
  denominator_unit?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  min_num_samples?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  numerator_unit?: Maybe<Scalars['String']>;
  sample_unit?: Maybe<Scalars['String']>;
  source_version?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  warning_limit?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "performance.ratio_metrics" */
export type Performance_Ratio_Metrics_Mutation_Response = {
  __typename?: 'performance_ratio_metrics_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Performance_Ratio_Metrics>;
};

/** input type for inserting object relation for remote table "performance.ratio_metrics" */
export type Performance_Ratio_Metrics_Obj_Rel_Insert_Input = {
  data: Performance_Ratio_Metrics_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Performance_Ratio_Metrics_On_Conflict>;
};

/** on_conflict condition type for table "performance.ratio_metrics" */
export type Performance_Ratio_Metrics_On_Conflict = {
  constraint: Performance_Ratio_Metrics_Constraint;
  update_columns?: Array<Performance_Ratio_Metrics_Update_Column>;
  where?: Maybe<Performance_Ratio_Metrics_Bool_Exp>;
};

/** Ordering options when selecting data from "performance.ratio_metrics". */
export type Performance_Ratio_Metrics_Order_By = {
  created_at?: Maybe<Order_By>;
  critical_limit?: Maybe<Order_By>;
  denominator_unit?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  display_as_percentage?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  larger_is_better?: Maybe<Order_By>;
  min_num_samples?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  numerator_unit?: Maybe<Order_By>;
  sample_unit?: Maybe<Order_By>;
  source_version?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  warning_limit?: Maybe<Order_By>;
};

/** primary key columns input for table: performance.ratio_metrics */
export type Performance_Ratio_Metrics_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "performance.ratio_metrics" */
export enum Performance_Ratio_Metrics_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CriticalLimit = 'critical_limit',
  /** column name */
  DenominatorUnit = 'denominator_unit',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayAsPercentage = 'display_as_percentage',
  /** column name */
  Id = 'id',
  /** column name */
  LargerIsBetter = 'larger_is_better',
  /** column name */
  MinNumSamples = 'min_num_samples',
  /** column name */
  Name = 'name',
  /** column name */
  NumeratorUnit = 'numerator_unit',
  /** column name */
  SampleUnit = 'sample_unit',
  /** column name */
  SourceVersion = 'source_version',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WarningLimit = 'warning_limit'
}

/** input type for updating data in table "performance.ratio_metrics" */
export type Performance_Ratio_Metrics_Set_Input = {
  critical_limit?: Maybe<Scalars['numeric']>;
  denominator_unit?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  display_as_percentage?: Maybe<Scalars['Boolean']>;
  larger_is_better?: Maybe<Scalars['Boolean']>;
  min_num_samples?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  numerator_unit?: Maybe<Scalars['String']>;
  sample_unit?: Maybe<Scalars['String']>;
  source_version?: Maybe<Scalars['String']>;
  warning_limit?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Performance_Ratio_Metrics_Stddev_Fields = {
  __typename?: 'performance_ratio_metrics_stddev_fields';
  critical_limit?: Maybe<Scalars['Float']>;
  min_num_samples?: Maybe<Scalars['Float']>;
  warning_limit?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Performance_Ratio_Metrics_Stddev_Pop_Fields = {
  __typename?: 'performance_ratio_metrics_stddev_pop_fields';
  critical_limit?: Maybe<Scalars['Float']>;
  min_num_samples?: Maybe<Scalars['Float']>;
  warning_limit?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Performance_Ratio_Metrics_Stddev_Samp_Fields = {
  __typename?: 'performance_ratio_metrics_stddev_samp_fields';
  critical_limit?: Maybe<Scalars['Float']>;
  min_num_samples?: Maybe<Scalars['Float']>;
  warning_limit?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "performance_ratio_metrics" */
export type Performance_Ratio_Metrics_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Performance_Ratio_Metrics_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Performance_Ratio_Metrics_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  critical_limit?: Maybe<Scalars['numeric']>;
  denominator_unit?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  display_as_percentage?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  larger_is_better?: Maybe<Scalars['Boolean']>;
  min_num_samples?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  numerator_unit?: Maybe<Scalars['String']>;
  sample_unit?: Maybe<Scalars['String']>;
  source_version?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  warning_limit?: Maybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Performance_Ratio_Metrics_Sum_Fields = {
  __typename?: 'performance_ratio_metrics_sum_fields';
  critical_limit?: Maybe<Scalars['numeric']>;
  min_num_samples?: Maybe<Scalars['Int']>;
  warning_limit?: Maybe<Scalars['numeric']>;
};

/** update columns of table "performance.ratio_metrics" */
export enum Performance_Ratio_Metrics_Update_Column {
  /** column name */
  CriticalLimit = 'critical_limit',
  /** column name */
  DenominatorUnit = 'denominator_unit',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayAsPercentage = 'display_as_percentage',
  /** column name */
  LargerIsBetter = 'larger_is_better',
  /** column name */
  MinNumSamples = 'min_num_samples',
  /** column name */
  Name = 'name',
  /** column name */
  NumeratorUnit = 'numerator_unit',
  /** column name */
  SampleUnit = 'sample_unit',
  /** column name */
  SourceVersion = 'source_version',
  /** column name */
  WarningLimit = 'warning_limit'
}

export type Performance_Ratio_Metrics_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Performance_Ratio_Metrics_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Performance_Ratio_Metrics_Set_Input>;
  where: Performance_Ratio_Metrics_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Performance_Ratio_Metrics_Var_Pop_Fields = {
  __typename?: 'performance_ratio_metrics_var_pop_fields';
  critical_limit?: Maybe<Scalars['Float']>;
  min_num_samples?: Maybe<Scalars['Float']>;
  warning_limit?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Performance_Ratio_Metrics_Var_Samp_Fields = {
  __typename?: 'performance_ratio_metrics_var_samp_fields';
  critical_limit?: Maybe<Scalars['Float']>;
  min_num_samples?: Maybe<Scalars['Float']>;
  warning_limit?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Performance_Ratio_Metrics_Variance_Fields = {
  __typename?: 'performance_ratio_metrics_variance_fields';
  critical_limit?: Maybe<Scalars['Float']>;
  min_num_samples?: Maybe<Scalars['Float']>;
  warning_limit?: Maybe<Scalars['Float']>;
};

/** use to attach a report template to a deliverable */
export type Performance_Report_Deliverable_Template = {
  __typename?: 'performance_report_deliverable_template';
  /** An object relationship */
  deliverable: Deliverables;
  deliverable_id: Scalars['uuid'];
  priority: Scalars['smallint'];
  /** An object relationship */
  template: Performance_Report_Templates;
  template_id: Scalars['uuid'];
};

/** aggregated selection of "performance_report.deliverable_template" */
export type Performance_Report_Deliverable_Template_Aggregate = {
  __typename?: 'performance_report_deliverable_template_aggregate';
  aggregate?: Maybe<Performance_Report_Deliverable_Template_Aggregate_Fields>;
  nodes: Array<Performance_Report_Deliverable_Template>;
};

/** aggregate fields of "performance_report.deliverable_template" */
export type Performance_Report_Deliverable_Template_Aggregate_Fields = {
  __typename?: 'performance_report_deliverable_template_aggregate_fields';
  avg?: Maybe<Performance_Report_Deliverable_Template_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Performance_Report_Deliverable_Template_Max_Fields>;
  min?: Maybe<Performance_Report_Deliverable_Template_Min_Fields>;
  stddev?: Maybe<Performance_Report_Deliverable_Template_Stddev_Fields>;
  stddev_pop?: Maybe<Performance_Report_Deliverable_Template_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Performance_Report_Deliverable_Template_Stddev_Samp_Fields>;
  sum?: Maybe<Performance_Report_Deliverable_Template_Sum_Fields>;
  var_pop?: Maybe<Performance_Report_Deliverable_Template_Var_Pop_Fields>;
  var_samp?: Maybe<Performance_Report_Deliverable_Template_Var_Samp_Fields>;
  variance?: Maybe<Performance_Report_Deliverable_Template_Variance_Fields>;
};


/** aggregate fields of "performance_report.deliverable_template" */
export type Performance_Report_Deliverable_Template_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Performance_Report_Deliverable_Template_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "performance_report.deliverable_template" */
export type Performance_Report_Deliverable_Template_Aggregate_Order_By = {
  avg?: Maybe<Performance_Report_Deliverable_Template_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Performance_Report_Deliverable_Template_Max_Order_By>;
  min?: Maybe<Performance_Report_Deliverable_Template_Min_Order_By>;
  stddev?: Maybe<Performance_Report_Deliverable_Template_Stddev_Order_By>;
  stddev_pop?: Maybe<Performance_Report_Deliverable_Template_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Performance_Report_Deliverable_Template_Stddev_Samp_Order_By>;
  sum?: Maybe<Performance_Report_Deliverable_Template_Sum_Order_By>;
  var_pop?: Maybe<Performance_Report_Deliverable_Template_Var_Pop_Order_By>;
  var_samp?: Maybe<Performance_Report_Deliverable_Template_Var_Samp_Order_By>;
  variance?: Maybe<Performance_Report_Deliverable_Template_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "performance_report.deliverable_template" */
export type Performance_Report_Deliverable_Template_Arr_Rel_Insert_Input = {
  data: Array<Performance_Report_Deliverable_Template_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Performance_Report_Deliverable_Template_On_Conflict>;
};

/** aggregate avg on columns */
export type Performance_Report_Deliverable_Template_Avg_Fields = {
  __typename?: 'performance_report_deliverable_template_avg_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "performance_report.deliverable_template" */
export type Performance_Report_Deliverable_Template_Avg_Order_By = {
  priority?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "performance_report.deliverable_template". All fields are combined with a logical 'AND'. */
export type Performance_Report_Deliverable_Template_Bool_Exp = {
  _and?: Maybe<Array<Performance_Report_Deliverable_Template_Bool_Exp>>;
  _not?: Maybe<Performance_Report_Deliverable_Template_Bool_Exp>;
  _or?: Maybe<Array<Performance_Report_Deliverable_Template_Bool_Exp>>;
  deliverable?: Maybe<Deliverables_Bool_Exp>;
  deliverable_id?: Maybe<Uuid_Comparison_Exp>;
  priority?: Maybe<Smallint_Comparison_Exp>;
  template?: Maybe<Performance_Report_Templates_Bool_Exp>;
  template_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "performance_report.deliverable_template" */
export enum Performance_Report_Deliverable_Template_Constraint {
  /** unique or primary key constraint on columns "deliverable_id", "template_id" */
  DeliverableTemplatePkey = 'deliverable_template_pkey'
}

/** input type for incrementing numeric columns in table "performance_report.deliverable_template" */
export type Performance_Report_Deliverable_Template_Inc_Input = {
  priority?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "performance_report.deliverable_template" */
export type Performance_Report_Deliverable_Template_Insert_Input = {
  deliverable?: Maybe<Deliverables_Obj_Rel_Insert_Input>;
  deliverable_id?: Maybe<Scalars['uuid']>;
  priority?: Maybe<Scalars['smallint']>;
  template?: Maybe<Performance_Report_Templates_Obj_Rel_Insert_Input>;
  template_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Performance_Report_Deliverable_Template_Max_Fields = {
  __typename?: 'performance_report_deliverable_template_max_fields';
  deliverable_id?: Maybe<Scalars['uuid']>;
  priority?: Maybe<Scalars['smallint']>;
  template_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "performance_report.deliverable_template" */
export type Performance_Report_Deliverable_Template_Max_Order_By = {
  deliverable_id?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  template_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Performance_Report_Deliverable_Template_Min_Fields = {
  __typename?: 'performance_report_deliverable_template_min_fields';
  deliverable_id?: Maybe<Scalars['uuid']>;
  priority?: Maybe<Scalars['smallint']>;
  template_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "performance_report.deliverable_template" */
export type Performance_Report_Deliverable_Template_Min_Order_By = {
  deliverable_id?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  template_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "performance_report.deliverable_template" */
export type Performance_Report_Deliverable_Template_Mutation_Response = {
  __typename?: 'performance_report_deliverable_template_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Performance_Report_Deliverable_Template>;
};

/** on_conflict condition type for table "performance_report.deliverable_template" */
export type Performance_Report_Deliverable_Template_On_Conflict = {
  constraint: Performance_Report_Deliverable_Template_Constraint;
  update_columns?: Array<Performance_Report_Deliverable_Template_Update_Column>;
  where?: Maybe<Performance_Report_Deliverable_Template_Bool_Exp>;
};

/** Ordering options when selecting data from "performance_report.deliverable_template". */
export type Performance_Report_Deliverable_Template_Order_By = {
  deliverable?: Maybe<Deliverables_Order_By>;
  deliverable_id?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  template?: Maybe<Performance_Report_Templates_Order_By>;
  template_id?: Maybe<Order_By>;
};

/** primary key columns input for table: performance_report.deliverable_template */
export type Performance_Report_Deliverable_Template_Pk_Columns_Input = {
  deliverable_id: Scalars['uuid'];
  template_id: Scalars['uuid'];
};

/** select columns of table "performance_report.deliverable_template" */
export enum Performance_Report_Deliverable_Template_Select_Column {
  /** column name */
  DeliverableId = 'deliverable_id',
  /** column name */
  Priority = 'priority',
  /** column name */
  TemplateId = 'template_id'
}

/** input type for updating data in table "performance_report.deliverable_template" */
export type Performance_Report_Deliverable_Template_Set_Input = {
  deliverable_id?: Maybe<Scalars['uuid']>;
  priority?: Maybe<Scalars['smallint']>;
  template_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Performance_Report_Deliverable_Template_Stddev_Fields = {
  __typename?: 'performance_report_deliverable_template_stddev_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "performance_report.deliverable_template" */
export type Performance_Report_Deliverable_Template_Stddev_Order_By = {
  priority?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Performance_Report_Deliverable_Template_Stddev_Pop_Fields = {
  __typename?: 'performance_report_deliverable_template_stddev_pop_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "performance_report.deliverable_template" */
export type Performance_Report_Deliverable_Template_Stddev_Pop_Order_By = {
  priority?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Performance_Report_Deliverable_Template_Stddev_Samp_Fields = {
  __typename?: 'performance_report_deliverable_template_stddev_samp_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "performance_report.deliverable_template" */
export type Performance_Report_Deliverable_Template_Stddev_Samp_Order_By = {
  priority?: Maybe<Order_By>;
};

/** Streaming cursor of the table "performance_report_deliverable_template" */
export type Performance_Report_Deliverable_Template_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Performance_Report_Deliverable_Template_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Performance_Report_Deliverable_Template_Stream_Cursor_Value_Input = {
  deliverable_id?: Maybe<Scalars['uuid']>;
  priority?: Maybe<Scalars['smallint']>;
  template_id?: Maybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Performance_Report_Deliverable_Template_Sum_Fields = {
  __typename?: 'performance_report_deliverable_template_sum_fields';
  priority?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "performance_report.deliverable_template" */
export type Performance_Report_Deliverable_Template_Sum_Order_By = {
  priority?: Maybe<Order_By>;
};

/** update columns of table "performance_report.deliverable_template" */
export enum Performance_Report_Deliverable_Template_Update_Column {
  /** column name */
  DeliverableId = 'deliverable_id',
  /** column name */
  Priority = 'priority',
  /** column name */
  TemplateId = 'template_id'
}

export type Performance_Report_Deliverable_Template_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Performance_Report_Deliverable_Template_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Performance_Report_Deliverable_Template_Set_Input>;
  where: Performance_Report_Deliverable_Template_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Performance_Report_Deliverable_Template_Var_Pop_Fields = {
  __typename?: 'performance_report_deliverable_template_var_pop_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "performance_report.deliverable_template" */
export type Performance_Report_Deliverable_Template_Var_Pop_Order_By = {
  priority?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Performance_Report_Deliverable_Template_Var_Samp_Fields = {
  __typename?: 'performance_report_deliverable_template_var_samp_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "performance_report.deliverable_template" */
export type Performance_Report_Deliverable_Template_Var_Samp_Order_By = {
  priority?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Performance_Report_Deliverable_Template_Variance_Fields = {
  __typename?: 'performance_report_deliverable_template_variance_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "performance_report.deliverable_template" */
export type Performance_Report_Deliverable_Template_Variance_Order_By = {
  priority?: Maybe<Order_By>;
};

/** used to enumerate the different ways to group the data in a section of a report */
export type Performance_Report_Pivot_Types = {
  __typename?: 'performance_report_pivot_types';
  description: Scalars['String'];
  id: Scalars['String'];
};

/** aggregated selection of "performance_report.pivot_types" */
export type Performance_Report_Pivot_Types_Aggregate = {
  __typename?: 'performance_report_pivot_types_aggregate';
  aggregate?: Maybe<Performance_Report_Pivot_Types_Aggregate_Fields>;
  nodes: Array<Performance_Report_Pivot_Types>;
};

/** aggregate fields of "performance_report.pivot_types" */
export type Performance_Report_Pivot_Types_Aggregate_Fields = {
  __typename?: 'performance_report_pivot_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Performance_Report_Pivot_Types_Max_Fields>;
  min?: Maybe<Performance_Report_Pivot_Types_Min_Fields>;
};


/** aggregate fields of "performance_report.pivot_types" */
export type Performance_Report_Pivot_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Performance_Report_Pivot_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "performance_report.pivot_types". All fields are combined with a logical 'AND'. */
export type Performance_Report_Pivot_Types_Bool_Exp = {
  _and?: Maybe<Array<Performance_Report_Pivot_Types_Bool_Exp>>;
  _not?: Maybe<Performance_Report_Pivot_Types_Bool_Exp>;
  _or?: Maybe<Array<Performance_Report_Pivot_Types_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
};

export enum Performance_Report_Pivot_Types_Enum {
  /** indicates that recordings should be grouped by the fields in their tasks */
  Fields = 'fields',
  /** indicates that recordings should be grouped by their associated hypertasks */
  Hypertasks = 'hypertasks',
  /** indicates that recordings should be grouped by their associated tasks */
  Tasks = 'tasks'
}

/** Boolean expression to compare columns of type "performance_report_pivot_types_enum". All fields are combined with logical 'AND'. */
export type Performance_Report_Pivot_Types_Enum_Comparison_Exp = {
  _eq?: Maybe<Performance_Report_Pivot_Types_Enum>;
  _in?: Maybe<Array<Performance_Report_Pivot_Types_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Performance_Report_Pivot_Types_Enum>;
  _nin?: Maybe<Array<Performance_Report_Pivot_Types_Enum>>;
};

/** aggregate max on columns */
export type Performance_Report_Pivot_Types_Max_Fields = {
  __typename?: 'performance_report_pivot_types_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Performance_Report_Pivot_Types_Min_Fields = {
  __typename?: 'performance_report_pivot_types_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "performance_report.pivot_types" */
export type Performance_Report_Pivot_Types_Mutation_Response = {
  __typename?: 'performance_report_pivot_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Performance_Report_Pivot_Types>;
};

/** Ordering options when selecting data from "performance_report.pivot_types". */
export type Performance_Report_Pivot_Types_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** primary key columns input for table: performance_report.pivot_types */
export type Performance_Report_Pivot_Types_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "performance_report.pivot_types" */
export enum Performance_Report_Pivot_Types_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "performance_report.pivot_types" */
export type Performance_Report_Pivot_Types_Set_Input = {
  description?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "performance_report_pivot_types" */
export type Performance_Report_Pivot_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Performance_Report_Pivot_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Performance_Report_Pivot_Types_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type Performance_Report_Pivot_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Performance_Report_Pivot_Types_Set_Input>;
  where: Performance_Report_Pivot_Types_Bool_Exp;
};

/** use to define which fields to use to create groups within the data for comparison */
export type Performance_Report_Section_Pivot_Field = {
  __typename?: 'performance_report_section_pivot_field';
  /** An object relationship */
  field: Fields;
  field_id: Scalars['uuid'];
  position: Scalars['smallint'];
  /** An object relationship */
  section: Performance_Report_Sections;
  section_id: Scalars['uuid'];
};

/** aggregated selection of "performance_report.section_pivot_field" */
export type Performance_Report_Section_Pivot_Field_Aggregate = {
  __typename?: 'performance_report_section_pivot_field_aggregate';
  aggregate?: Maybe<Performance_Report_Section_Pivot_Field_Aggregate_Fields>;
  nodes: Array<Performance_Report_Section_Pivot_Field>;
};

/** aggregate fields of "performance_report.section_pivot_field" */
export type Performance_Report_Section_Pivot_Field_Aggregate_Fields = {
  __typename?: 'performance_report_section_pivot_field_aggregate_fields';
  avg?: Maybe<Performance_Report_Section_Pivot_Field_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Performance_Report_Section_Pivot_Field_Max_Fields>;
  min?: Maybe<Performance_Report_Section_Pivot_Field_Min_Fields>;
  stddev?: Maybe<Performance_Report_Section_Pivot_Field_Stddev_Fields>;
  stddev_pop?: Maybe<Performance_Report_Section_Pivot_Field_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Performance_Report_Section_Pivot_Field_Stddev_Samp_Fields>;
  sum?: Maybe<Performance_Report_Section_Pivot_Field_Sum_Fields>;
  var_pop?: Maybe<Performance_Report_Section_Pivot_Field_Var_Pop_Fields>;
  var_samp?: Maybe<Performance_Report_Section_Pivot_Field_Var_Samp_Fields>;
  variance?: Maybe<Performance_Report_Section_Pivot_Field_Variance_Fields>;
};


/** aggregate fields of "performance_report.section_pivot_field" */
export type Performance_Report_Section_Pivot_Field_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Performance_Report_Section_Pivot_Field_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "performance_report.section_pivot_field" */
export type Performance_Report_Section_Pivot_Field_Aggregate_Order_By = {
  avg?: Maybe<Performance_Report_Section_Pivot_Field_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Performance_Report_Section_Pivot_Field_Max_Order_By>;
  min?: Maybe<Performance_Report_Section_Pivot_Field_Min_Order_By>;
  stddev?: Maybe<Performance_Report_Section_Pivot_Field_Stddev_Order_By>;
  stddev_pop?: Maybe<Performance_Report_Section_Pivot_Field_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Performance_Report_Section_Pivot_Field_Stddev_Samp_Order_By>;
  sum?: Maybe<Performance_Report_Section_Pivot_Field_Sum_Order_By>;
  var_pop?: Maybe<Performance_Report_Section_Pivot_Field_Var_Pop_Order_By>;
  var_samp?: Maybe<Performance_Report_Section_Pivot_Field_Var_Samp_Order_By>;
  variance?: Maybe<Performance_Report_Section_Pivot_Field_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "performance_report.section_pivot_field" */
export type Performance_Report_Section_Pivot_Field_Arr_Rel_Insert_Input = {
  data: Array<Performance_Report_Section_Pivot_Field_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Performance_Report_Section_Pivot_Field_On_Conflict>;
};

/** aggregate avg on columns */
export type Performance_Report_Section_Pivot_Field_Avg_Fields = {
  __typename?: 'performance_report_section_pivot_field_avg_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "performance_report.section_pivot_field" */
export type Performance_Report_Section_Pivot_Field_Avg_Order_By = {
  position?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "performance_report.section_pivot_field". All fields are combined with a logical 'AND'. */
export type Performance_Report_Section_Pivot_Field_Bool_Exp = {
  _and?: Maybe<Array<Performance_Report_Section_Pivot_Field_Bool_Exp>>;
  _not?: Maybe<Performance_Report_Section_Pivot_Field_Bool_Exp>;
  _or?: Maybe<Array<Performance_Report_Section_Pivot_Field_Bool_Exp>>;
  field?: Maybe<Fields_Bool_Exp>;
  field_id?: Maybe<Uuid_Comparison_Exp>;
  position?: Maybe<Smallint_Comparison_Exp>;
  section?: Maybe<Performance_Report_Sections_Bool_Exp>;
  section_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "performance_report.section_pivot_field" */
export enum Performance_Report_Section_Pivot_Field_Constraint {
  /** unique or primary key constraint on columns "section_id", "field_id" */
  SectionPivotFieldPkey = 'section_pivot_field_pkey',
  /** unique or primary key constraint on columns "section_id", "position" */
  SectionPivotFieldSectionIdPositionKey = 'section_pivot_field_section_id_position_key'
}

/** input type for incrementing numeric columns in table "performance_report.section_pivot_field" */
export type Performance_Report_Section_Pivot_Field_Inc_Input = {
  position?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "performance_report.section_pivot_field" */
export type Performance_Report_Section_Pivot_Field_Insert_Input = {
  field?: Maybe<Fields_Obj_Rel_Insert_Input>;
  field_id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['smallint']>;
  section?: Maybe<Performance_Report_Sections_Obj_Rel_Insert_Input>;
  section_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Performance_Report_Section_Pivot_Field_Max_Fields = {
  __typename?: 'performance_report_section_pivot_field_max_fields';
  field_id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['smallint']>;
  section_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "performance_report.section_pivot_field" */
export type Performance_Report_Section_Pivot_Field_Max_Order_By = {
  field_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  section_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Performance_Report_Section_Pivot_Field_Min_Fields = {
  __typename?: 'performance_report_section_pivot_field_min_fields';
  field_id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['smallint']>;
  section_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "performance_report.section_pivot_field" */
export type Performance_Report_Section_Pivot_Field_Min_Order_By = {
  field_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  section_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "performance_report.section_pivot_field" */
export type Performance_Report_Section_Pivot_Field_Mutation_Response = {
  __typename?: 'performance_report_section_pivot_field_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Performance_Report_Section_Pivot_Field>;
};

/** on_conflict condition type for table "performance_report.section_pivot_field" */
export type Performance_Report_Section_Pivot_Field_On_Conflict = {
  constraint: Performance_Report_Section_Pivot_Field_Constraint;
  update_columns?: Array<Performance_Report_Section_Pivot_Field_Update_Column>;
  where?: Maybe<Performance_Report_Section_Pivot_Field_Bool_Exp>;
};

/** Ordering options when selecting data from "performance_report.section_pivot_field". */
export type Performance_Report_Section_Pivot_Field_Order_By = {
  field?: Maybe<Fields_Order_By>;
  field_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  section?: Maybe<Performance_Report_Sections_Order_By>;
  section_id?: Maybe<Order_By>;
};

/** primary key columns input for table: performance_report.section_pivot_field */
export type Performance_Report_Section_Pivot_Field_Pk_Columns_Input = {
  field_id: Scalars['uuid'];
  section_id: Scalars['uuid'];
};

/** select columns of table "performance_report.section_pivot_field" */
export enum Performance_Report_Section_Pivot_Field_Select_Column {
  /** column name */
  FieldId = 'field_id',
  /** column name */
  Position = 'position',
  /** column name */
  SectionId = 'section_id'
}

/** input type for updating data in table "performance_report.section_pivot_field" */
export type Performance_Report_Section_Pivot_Field_Set_Input = {
  field_id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['smallint']>;
  section_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Performance_Report_Section_Pivot_Field_Stddev_Fields = {
  __typename?: 'performance_report_section_pivot_field_stddev_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "performance_report.section_pivot_field" */
export type Performance_Report_Section_Pivot_Field_Stddev_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Performance_Report_Section_Pivot_Field_Stddev_Pop_Fields = {
  __typename?: 'performance_report_section_pivot_field_stddev_pop_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "performance_report.section_pivot_field" */
export type Performance_Report_Section_Pivot_Field_Stddev_Pop_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Performance_Report_Section_Pivot_Field_Stddev_Samp_Fields = {
  __typename?: 'performance_report_section_pivot_field_stddev_samp_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "performance_report.section_pivot_field" */
export type Performance_Report_Section_Pivot_Field_Stddev_Samp_Order_By = {
  position?: Maybe<Order_By>;
};

/** Streaming cursor of the table "performance_report_section_pivot_field" */
export type Performance_Report_Section_Pivot_Field_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Performance_Report_Section_Pivot_Field_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Performance_Report_Section_Pivot_Field_Stream_Cursor_Value_Input = {
  field_id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['smallint']>;
  section_id?: Maybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Performance_Report_Section_Pivot_Field_Sum_Fields = {
  __typename?: 'performance_report_section_pivot_field_sum_fields';
  position?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "performance_report.section_pivot_field" */
export type Performance_Report_Section_Pivot_Field_Sum_Order_By = {
  position?: Maybe<Order_By>;
};

/** update columns of table "performance_report.section_pivot_field" */
export enum Performance_Report_Section_Pivot_Field_Update_Column {
  /** column name */
  FieldId = 'field_id',
  /** column name */
  Position = 'position',
  /** column name */
  SectionId = 'section_id'
}

export type Performance_Report_Section_Pivot_Field_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Performance_Report_Section_Pivot_Field_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Performance_Report_Section_Pivot_Field_Set_Input>;
  where: Performance_Report_Section_Pivot_Field_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Performance_Report_Section_Pivot_Field_Var_Pop_Fields = {
  __typename?: 'performance_report_section_pivot_field_var_pop_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "performance_report.section_pivot_field" */
export type Performance_Report_Section_Pivot_Field_Var_Pop_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Performance_Report_Section_Pivot_Field_Var_Samp_Fields = {
  __typename?: 'performance_report_section_pivot_field_var_samp_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "performance_report.section_pivot_field" */
export type Performance_Report_Section_Pivot_Field_Var_Samp_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Performance_Report_Section_Pivot_Field_Variance_Fields = {
  __typename?: 'performance_report_section_pivot_field_variance_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "performance_report.section_pivot_field" */
export type Performance_Report_Section_Pivot_Field_Variance_Order_By = {
  position?: Maybe<Order_By>;
};

/** use to add metrics to compare within a report section */
export type Performance_Report_Section_Ratio_Metric = {
  __typename?: 'performance_report_section_ratio_metric';
  position: Scalars['smallint'];
  /** An object relationship */
  ratio_metric: Performance_Ratio_Metrics;
  ratio_metric_id: Scalars['uuid'];
  /** An object relationship */
  section: Performance_Report_Sections;
  section_id: Scalars['uuid'];
};

/** aggregated selection of "performance_report.section_ratio_metric" */
export type Performance_Report_Section_Ratio_Metric_Aggregate = {
  __typename?: 'performance_report_section_ratio_metric_aggregate';
  aggregate?: Maybe<Performance_Report_Section_Ratio_Metric_Aggregate_Fields>;
  nodes: Array<Performance_Report_Section_Ratio_Metric>;
};

/** aggregate fields of "performance_report.section_ratio_metric" */
export type Performance_Report_Section_Ratio_Metric_Aggregate_Fields = {
  __typename?: 'performance_report_section_ratio_metric_aggregate_fields';
  avg?: Maybe<Performance_Report_Section_Ratio_Metric_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Performance_Report_Section_Ratio_Metric_Max_Fields>;
  min?: Maybe<Performance_Report_Section_Ratio_Metric_Min_Fields>;
  stddev?: Maybe<Performance_Report_Section_Ratio_Metric_Stddev_Fields>;
  stddev_pop?: Maybe<Performance_Report_Section_Ratio_Metric_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Performance_Report_Section_Ratio_Metric_Stddev_Samp_Fields>;
  sum?: Maybe<Performance_Report_Section_Ratio_Metric_Sum_Fields>;
  var_pop?: Maybe<Performance_Report_Section_Ratio_Metric_Var_Pop_Fields>;
  var_samp?: Maybe<Performance_Report_Section_Ratio_Metric_Var_Samp_Fields>;
  variance?: Maybe<Performance_Report_Section_Ratio_Metric_Variance_Fields>;
};


/** aggregate fields of "performance_report.section_ratio_metric" */
export type Performance_Report_Section_Ratio_Metric_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Performance_Report_Section_Ratio_Metric_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "performance_report.section_ratio_metric" */
export type Performance_Report_Section_Ratio_Metric_Aggregate_Order_By = {
  avg?: Maybe<Performance_Report_Section_Ratio_Metric_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Performance_Report_Section_Ratio_Metric_Max_Order_By>;
  min?: Maybe<Performance_Report_Section_Ratio_Metric_Min_Order_By>;
  stddev?: Maybe<Performance_Report_Section_Ratio_Metric_Stddev_Order_By>;
  stddev_pop?: Maybe<Performance_Report_Section_Ratio_Metric_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Performance_Report_Section_Ratio_Metric_Stddev_Samp_Order_By>;
  sum?: Maybe<Performance_Report_Section_Ratio_Metric_Sum_Order_By>;
  var_pop?: Maybe<Performance_Report_Section_Ratio_Metric_Var_Pop_Order_By>;
  var_samp?: Maybe<Performance_Report_Section_Ratio_Metric_Var_Samp_Order_By>;
  variance?: Maybe<Performance_Report_Section_Ratio_Metric_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "performance_report.section_ratio_metric" */
export type Performance_Report_Section_Ratio_Metric_Arr_Rel_Insert_Input = {
  data: Array<Performance_Report_Section_Ratio_Metric_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Performance_Report_Section_Ratio_Metric_On_Conflict>;
};

/** aggregate avg on columns */
export type Performance_Report_Section_Ratio_Metric_Avg_Fields = {
  __typename?: 'performance_report_section_ratio_metric_avg_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "performance_report.section_ratio_metric" */
export type Performance_Report_Section_Ratio_Metric_Avg_Order_By = {
  position?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "performance_report.section_ratio_metric". All fields are combined with a logical 'AND'. */
export type Performance_Report_Section_Ratio_Metric_Bool_Exp = {
  _and?: Maybe<Array<Performance_Report_Section_Ratio_Metric_Bool_Exp>>;
  _not?: Maybe<Performance_Report_Section_Ratio_Metric_Bool_Exp>;
  _or?: Maybe<Array<Performance_Report_Section_Ratio_Metric_Bool_Exp>>;
  position?: Maybe<Smallint_Comparison_Exp>;
  ratio_metric?: Maybe<Performance_Ratio_Metrics_Bool_Exp>;
  ratio_metric_id?: Maybe<Uuid_Comparison_Exp>;
  section?: Maybe<Performance_Report_Sections_Bool_Exp>;
  section_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "performance_report.section_ratio_metric" */
export enum Performance_Report_Section_Ratio_Metric_Constraint {
  /** unique or primary key constraint on columns "section_id", "ratio_metric_id" */
  SectionRatioMetricPkey = 'section_ratio_metric_pkey',
  /** unique or primary key constraint on columns "section_id", "position" */
  SectionRatioMetricSectionIdPositionKey = 'section_ratio_metric_section_id_position_key'
}

/** input type for incrementing numeric columns in table "performance_report.section_ratio_metric" */
export type Performance_Report_Section_Ratio_Metric_Inc_Input = {
  position?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "performance_report.section_ratio_metric" */
export type Performance_Report_Section_Ratio_Metric_Insert_Input = {
  position?: Maybe<Scalars['smallint']>;
  ratio_metric?: Maybe<Performance_Ratio_Metrics_Obj_Rel_Insert_Input>;
  ratio_metric_id?: Maybe<Scalars['uuid']>;
  section?: Maybe<Performance_Report_Sections_Obj_Rel_Insert_Input>;
  section_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Performance_Report_Section_Ratio_Metric_Max_Fields = {
  __typename?: 'performance_report_section_ratio_metric_max_fields';
  position?: Maybe<Scalars['smallint']>;
  ratio_metric_id?: Maybe<Scalars['uuid']>;
  section_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "performance_report.section_ratio_metric" */
export type Performance_Report_Section_Ratio_Metric_Max_Order_By = {
  position?: Maybe<Order_By>;
  ratio_metric_id?: Maybe<Order_By>;
  section_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Performance_Report_Section_Ratio_Metric_Min_Fields = {
  __typename?: 'performance_report_section_ratio_metric_min_fields';
  position?: Maybe<Scalars['smallint']>;
  ratio_metric_id?: Maybe<Scalars['uuid']>;
  section_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "performance_report.section_ratio_metric" */
export type Performance_Report_Section_Ratio_Metric_Min_Order_By = {
  position?: Maybe<Order_By>;
  ratio_metric_id?: Maybe<Order_By>;
  section_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "performance_report.section_ratio_metric" */
export type Performance_Report_Section_Ratio_Metric_Mutation_Response = {
  __typename?: 'performance_report_section_ratio_metric_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Performance_Report_Section_Ratio_Metric>;
};

/** on_conflict condition type for table "performance_report.section_ratio_metric" */
export type Performance_Report_Section_Ratio_Metric_On_Conflict = {
  constraint: Performance_Report_Section_Ratio_Metric_Constraint;
  update_columns?: Array<Performance_Report_Section_Ratio_Metric_Update_Column>;
  where?: Maybe<Performance_Report_Section_Ratio_Metric_Bool_Exp>;
};

/** Ordering options when selecting data from "performance_report.section_ratio_metric". */
export type Performance_Report_Section_Ratio_Metric_Order_By = {
  position?: Maybe<Order_By>;
  ratio_metric?: Maybe<Performance_Ratio_Metrics_Order_By>;
  ratio_metric_id?: Maybe<Order_By>;
  section?: Maybe<Performance_Report_Sections_Order_By>;
  section_id?: Maybe<Order_By>;
};

/** primary key columns input for table: performance_report.section_ratio_metric */
export type Performance_Report_Section_Ratio_Metric_Pk_Columns_Input = {
  ratio_metric_id: Scalars['uuid'];
  section_id: Scalars['uuid'];
};

/** select columns of table "performance_report.section_ratio_metric" */
export enum Performance_Report_Section_Ratio_Metric_Select_Column {
  /** column name */
  Position = 'position',
  /** column name */
  RatioMetricId = 'ratio_metric_id',
  /** column name */
  SectionId = 'section_id'
}

/** input type for updating data in table "performance_report.section_ratio_metric" */
export type Performance_Report_Section_Ratio_Metric_Set_Input = {
  position?: Maybe<Scalars['smallint']>;
  ratio_metric_id?: Maybe<Scalars['uuid']>;
  section_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Performance_Report_Section_Ratio_Metric_Stddev_Fields = {
  __typename?: 'performance_report_section_ratio_metric_stddev_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "performance_report.section_ratio_metric" */
export type Performance_Report_Section_Ratio_Metric_Stddev_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Performance_Report_Section_Ratio_Metric_Stddev_Pop_Fields = {
  __typename?: 'performance_report_section_ratio_metric_stddev_pop_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "performance_report.section_ratio_metric" */
export type Performance_Report_Section_Ratio_Metric_Stddev_Pop_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Performance_Report_Section_Ratio_Metric_Stddev_Samp_Fields = {
  __typename?: 'performance_report_section_ratio_metric_stddev_samp_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "performance_report.section_ratio_metric" */
export type Performance_Report_Section_Ratio_Metric_Stddev_Samp_Order_By = {
  position?: Maybe<Order_By>;
};

/** Streaming cursor of the table "performance_report_section_ratio_metric" */
export type Performance_Report_Section_Ratio_Metric_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Performance_Report_Section_Ratio_Metric_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Performance_Report_Section_Ratio_Metric_Stream_Cursor_Value_Input = {
  position?: Maybe<Scalars['smallint']>;
  ratio_metric_id?: Maybe<Scalars['uuid']>;
  section_id?: Maybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Performance_Report_Section_Ratio_Metric_Sum_Fields = {
  __typename?: 'performance_report_section_ratio_metric_sum_fields';
  position?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "performance_report.section_ratio_metric" */
export type Performance_Report_Section_Ratio_Metric_Sum_Order_By = {
  position?: Maybe<Order_By>;
};

/** update columns of table "performance_report.section_ratio_metric" */
export enum Performance_Report_Section_Ratio_Metric_Update_Column {
  /** column name */
  Position = 'position',
  /** column name */
  RatioMetricId = 'ratio_metric_id',
  /** column name */
  SectionId = 'section_id'
}

export type Performance_Report_Section_Ratio_Metric_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Performance_Report_Section_Ratio_Metric_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Performance_Report_Section_Ratio_Metric_Set_Input>;
  where: Performance_Report_Section_Ratio_Metric_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Performance_Report_Section_Ratio_Metric_Var_Pop_Fields = {
  __typename?: 'performance_report_section_ratio_metric_var_pop_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "performance_report.section_ratio_metric" */
export type Performance_Report_Section_Ratio_Metric_Var_Pop_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Performance_Report_Section_Ratio_Metric_Var_Samp_Fields = {
  __typename?: 'performance_report_section_ratio_metric_var_samp_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "performance_report.section_ratio_metric" */
export type Performance_Report_Section_Ratio_Metric_Var_Samp_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Performance_Report_Section_Ratio_Metric_Variance_Fields = {
  __typename?: 'performance_report_section_ratio_metric_variance_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "performance_report.section_ratio_metric" */
export type Performance_Report_Section_Ratio_Metric_Variance_Order_By = {
  position?: Maybe<Order_By>;
};

/** use to exclude specific tasks from a report section */
export type Performance_Report_Section_Task_Blacklist = {
  __typename?: 'performance_report_section_task_blacklist';
  /** An object relationship */
  section: Performance_Report_Sections;
  section_id: Scalars['uuid'];
  /** An object relationship */
  task: Tasks;
  task_id: Scalars['uuid'];
};

/** aggregated selection of "performance_report.section_task_blacklist" */
export type Performance_Report_Section_Task_Blacklist_Aggregate = {
  __typename?: 'performance_report_section_task_blacklist_aggregate';
  aggregate?: Maybe<Performance_Report_Section_Task_Blacklist_Aggregate_Fields>;
  nodes: Array<Performance_Report_Section_Task_Blacklist>;
};

/** aggregate fields of "performance_report.section_task_blacklist" */
export type Performance_Report_Section_Task_Blacklist_Aggregate_Fields = {
  __typename?: 'performance_report_section_task_blacklist_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Performance_Report_Section_Task_Blacklist_Max_Fields>;
  min?: Maybe<Performance_Report_Section_Task_Blacklist_Min_Fields>;
};


/** aggregate fields of "performance_report.section_task_blacklist" */
export type Performance_Report_Section_Task_Blacklist_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Performance_Report_Section_Task_Blacklist_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "performance_report.section_task_blacklist" */
export type Performance_Report_Section_Task_Blacklist_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Performance_Report_Section_Task_Blacklist_Max_Order_By>;
  min?: Maybe<Performance_Report_Section_Task_Blacklist_Min_Order_By>;
};

/** input type for inserting array relation for remote table "performance_report.section_task_blacklist" */
export type Performance_Report_Section_Task_Blacklist_Arr_Rel_Insert_Input = {
  data: Array<Performance_Report_Section_Task_Blacklist_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Performance_Report_Section_Task_Blacklist_On_Conflict>;
};

/** Boolean expression to filter rows from the table "performance_report.section_task_blacklist". All fields are combined with a logical 'AND'. */
export type Performance_Report_Section_Task_Blacklist_Bool_Exp = {
  _and?: Maybe<Array<Performance_Report_Section_Task_Blacklist_Bool_Exp>>;
  _not?: Maybe<Performance_Report_Section_Task_Blacklist_Bool_Exp>;
  _or?: Maybe<Array<Performance_Report_Section_Task_Blacklist_Bool_Exp>>;
  section?: Maybe<Performance_Report_Sections_Bool_Exp>;
  section_id?: Maybe<Uuid_Comparison_Exp>;
  task?: Maybe<Tasks_Bool_Exp>;
  task_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "performance_report.section_task_blacklist" */
export enum Performance_Report_Section_Task_Blacklist_Constraint {
  /** unique or primary key constraint on columns "section_id", "task_id" */
  SectionTaskBlacklistPkey = 'section_task_blacklist_pkey'
}

/** input type for inserting data into table "performance_report.section_task_blacklist" */
export type Performance_Report_Section_Task_Blacklist_Insert_Input = {
  section?: Maybe<Performance_Report_Sections_Obj_Rel_Insert_Input>;
  section_id?: Maybe<Scalars['uuid']>;
  task?: Maybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Performance_Report_Section_Task_Blacklist_Max_Fields = {
  __typename?: 'performance_report_section_task_blacklist_max_fields';
  section_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "performance_report.section_task_blacklist" */
export type Performance_Report_Section_Task_Blacklist_Max_Order_By = {
  section_id?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Performance_Report_Section_Task_Blacklist_Min_Fields = {
  __typename?: 'performance_report_section_task_blacklist_min_fields';
  section_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "performance_report.section_task_blacklist" */
export type Performance_Report_Section_Task_Blacklist_Min_Order_By = {
  section_id?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "performance_report.section_task_blacklist" */
export type Performance_Report_Section_Task_Blacklist_Mutation_Response = {
  __typename?: 'performance_report_section_task_blacklist_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Performance_Report_Section_Task_Blacklist>;
};

/** on_conflict condition type for table "performance_report.section_task_blacklist" */
export type Performance_Report_Section_Task_Blacklist_On_Conflict = {
  constraint: Performance_Report_Section_Task_Blacklist_Constraint;
  update_columns?: Array<Performance_Report_Section_Task_Blacklist_Update_Column>;
  where?: Maybe<Performance_Report_Section_Task_Blacklist_Bool_Exp>;
};

/** Ordering options when selecting data from "performance_report.section_task_blacklist". */
export type Performance_Report_Section_Task_Blacklist_Order_By = {
  section?: Maybe<Performance_Report_Sections_Order_By>;
  section_id?: Maybe<Order_By>;
  task?: Maybe<Tasks_Order_By>;
  task_id?: Maybe<Order_By>;
};

/** primary key columns input for table: performance_report.section_task_blacklist */
export type Performance_Report_Section_Task_Blacklist_Pk_Columns_Input = {
  section_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};

/** select columns of table "performance_report.section_task_blacklist" */
export enum Performance_Report_Section_Task_Blacklist_Select_Column {
  /** column name */
  SectionId = 'section_id',
  /** column name */
  TaskId = 'task_id'
}

/** input type for updating data in table "performance_report.section_task_blacklist" */
export type Performance_Report_Section_Task_Blacklist_Set_Input = {
  section_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "performance_report_section_task_blacklist" */
export type Performance_Report_Section_Task_Blacklist_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Performance_Report_Section_Task_Blacklist_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Performance_Report_Section_Task_Blacklist_Stream_Cursor_Value_Input = {
  section_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "performance_report.section_task_blacklist" */
export enum Performance_Report_Section_Task_Blacklist_Update_Column {
  /** column name */
  SectionId = 'section_id',
  /** column name */
  TaskId = 'task_id'
}

export type Performance_Report_Section_Task_Blacklist_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Performance_Report_Section_Task_Blacklist_Set_Input>;
  where: Performance_Report_Section_Task_Blacklist_Bool_Exp;
};

/** use to add a section to a template for a single metric */
export type Performance_Report_Sections = {
  __typename?: 'performance_report_sections';
  id: Scalars['uuid'];
  include_missing_pivot_values?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  pivot_fields: Array<Performance_Report_Section_Pivot_Field>;
  /** An aggregate relationship */
  pivot_fields_aggregate: Performance_Report_Section_Pivot_Field_Aggregate;
  /** An object relationship */
  pivot_type: Performance_Report_Pivot_Types;
  pivot_type_id: Performance_Report_Pivot_Types_Enum;
  position: Scalars['smallint'];
  /** An array relationship */
  section_ratio_metrics: Array<Performance_Report_Section_Ratio_Metric>;
  /** An aggregate relationship */
  section_ratio_metrics_aggregate: Performance_Report_Section_Ratio_Metric_Aggregate;
  /** An array relationship */
  section_task_blacklists: Array<Performance_Report_Section_Task_Blacklist>;
  /** An aggregate relationship */
  section_task_blacklists_aggregate: Performance_Report_Section_Task_Blacklist_Aggregate;
  /** An object relationship */
  template: Performance_Report_Templates;
  template_id: Scalars['uuid'];
  title: Scalars['String'];
};


/** use to add a section to a template for a single metric */
export type Performance_Report_SectionsPivot_FieldsArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Section_Pivot_Field_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Section_Pivot_Field_Order_By>>;
  where?: Maybe<Performance_Report_Section_Pivot_Field_Bool_Exp>;
};


/** use to add a section to a template for a single metric */
export type Performance_Report_SectionsPivot_Fields_AggregateArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Section_Pivot_Field_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Section_Pivot_Field_Order_By>>;
  where?: Maybe<Performance_Report_Section_Pivot_Field_Bool_Exp>;
};


/** use to add a section to a template for a single metric */
export type Performance_Report_SectionsSection_Ratio_MetricsArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Section_Ratio_Metric_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Section_Ratio_Metric_Order_By>>;
  where?: Maybe<Performance_Report_Section_Ratio_Metric_Bool_Exp>;
};


/** use to add a section to a template for a single metric */
export type Performance_Report_SectionsSection_Ratio_Metrics_AggregateArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Section_Ratio_Metric_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Section_Ratio_Metric_Order_By>>;
  where?: Maybe<Performance_Report_Section_Ratio_Metric_Bool_Exp>;
};


/** use to add a section to a template for a single metric */
export type Performance_Report_SectionsSection_Task_BlacklistsArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Section_Task_Blacklist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Section_Task_Blacklist_Order_By>>;
  where?: Maybe<Performance_Report_Section_Task_Blacklist_Bool_Exp>;
};


/** use to add a section to a template for a single metric */
export type Performance_Report_SectionsSection_Task_Blacklists_AggregateArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Section_Task_Blacklist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Section_Task_Blacklist_Order_By>>;
  where?: Maybe<Performance_Report_Section_Task_Blacklist_Bool_Exp>;
};

/** aggregated selection of "performance_report.sections" */
export type Performance_Report_Sections_Aggregate = {
  __typename?: 'performance_report_sections_aggregate';
  aggregate?: Maybe<Performance_Report_Sections_Aggregate_Fields>;
  nodes: Array<Performance_Report_Sections>;
};

/** aggregate fields of "performance_report.sections" */
export type Performance_Report_Sections_Aggregate_Fields = {
  __typename?: 'performance_report_sections_aggregate_fields';
  avg?: Maybe<Performance_Report_Sections_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Performance_Report_Sections_Max_Fields>;
  min?: Maybe<Performance_Report_Sections_Min_Fields>;
  stddev?: Maybe<Performance_Report_Sections_Stddev_Fields>;
  stddev_pop?: Maybe<Performance_Report_Sections_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Performance_Report_Sections_Stddev_Samp_Fields>;
  sum?: Maybe<Performance_Report_Sections_Sum_Fields>;
  var_pop?: Maybe<Performance_Report_Sections_Var_Pop_Fields>;
  var_samp?: Maybe<Performance_Report_Sections_Var_Samp_Fields>;
  variance?: Maybe<Performance_Report_Sections_Variance_Fields>;
};


/** aggregate fields of "performance_report.sections" */
export type Performance_Report_Sections_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Performance_Report_Sections_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "performance_report.sections" */
export type Performance_Report_Sections_Aggregate_Order_By = {
  avg?: Maybe<Performance_Report_Sections_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Performance_Report_Sections_Max_Order_By>;
  min?: Maybe<Performance_Report_Sections_Min_Order_By>;
  stddev?: Maybe<Performance_Report_Sections_Stddev_Order_By>;
  stddev_pop?: Maybe<Performance_Report_Sections_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Performance_Report_Sections_Stddev_Samp_Order_By>;
  sum?: Maybe<Performance_Report_Sections_Sum_Order_By>;
  var_pop?: Maybe<Performance_Report_Sections_Var_Pop_Order_By>;
  var_samp?: Maybe<Performance_Report_Sections_Var_Samp_Order_By>;
  variance?: Maybe<Performance_Report_Sections_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "performance_report.sections" */
export type Performance_Report_Sections_Arr_Rel_Insert_Input = {
  data: Array<Performance_Report_Sections_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Performance_Report_Sections_On_Conflict>;
};

/** aggregate avg on columns */
export type Performance_Report_Sections_Avg_Fields = {
  __typename?: 'performance_report_sections_avg_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "performance_report.sections" */
export type Performance_Report_Sections_Avg_Order_By = {
  position?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "performance_report.sections". All fields are combined with a logical 'AND'. */
export type Performance_Report_Sections_Bool_Exp = {
  _and?: Maybe<Array<Performance_Report_Sections_Bool_Exp>>;
  _not?: Maybe<Performance_Report_Sections_Bool_Exp>;
  _or?: Maybe<Array<Performance_Report_Sections_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  include_missing_pivot_values?: Maybe<Boolean_Comparison_Exp>;
  pivot_fields?: Maybe<Performance_Report_Section_Pivot_Field_Bool_Exp>;
  pivot_type?: Maybe<Performance_Report_Pivot_Types_Bool_Exp>;
  pivot_type_id?: Maybe<Performance_Report_Pivot_Types_Enum_Comparison_Exp>;
  position?: Maybe<Smallint_Comparison_Exp>;
  section_ratio_metrics?: Maybe<Performance_Report_Section_Ratio_Metric_Bool_Exp>;
  section_task_blacklists?: Maybe<Performance_Report_Section_Task_Blacklist_Bool_Exp>;
  template?: Maybe<Performance_Report_Templates_Bool_Exp>;
  template_id?: Maybe<Uuid_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "performance_report.sections" */
export enum Performance_Report_Sections_Constraint {
  /** unique or primary key constraint on columns "id" */
  SectionsPkey = 'sections_pkey',
  /** unique or primary key constraint on columns "template_id", "position" */
  SectionsTemplateIdPositionKey = 'sections_template_id_position_key'
}

/** input type for incrementing numeric columns in table "performance_report.sections" */
export type Performance_Report_Sections_Inc_Input = {
  position?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "performance_report.sections" */
export type Performance_Report_Sections_Insert_Input = {
  include_missing_pivot_values?: Maybe<Scalars['Boolean']>;
  pivot_fields?: Maybe<Performance_Report_Section_Pivot_Field_Arr_Rel_Insert_Input>;
  pivot_type_id?: Maybe<Performance_Report_Pivot_Types_Enum>;
  position?: Maybe<Scalars['smallint']>;
  section_ratio_metrics?: Maybe<Performance_Report_Section_Ratio_Metric_Arr_Rel_Insert_Input>;
  section_task_blacklists?: Maybe<Performance_Report_Section_Task_Blacklist_Arr_Rel_Insert_Input>;
  template?: Maybe<Performance_Report_Templates_Obj_Rel_Insert_Input>;
  template_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Performance_Report_Sections_Max_Fields = {
  __typename?: 'performance_report_sections_max_fields';
  id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['smallint']>;
  template_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "performance_report.sections" */
export type Performance_Report_Sections_Max_Order_By = {
  id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  template_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Performance_Report_Sections_Min_Fields = {
  __typename?: 'performance_report_sections_min_fields';
  id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['smallint']>;
  template_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "performance_report.sections" */
export type Performance_Report_Sections_Min_Order_By = {
  id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  template_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** response of any mutation on the table "performance_report.sections" */
export type Performance_Report_Sections_Mutation_Response = {
  __typename?: 'performance_report_sections_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Performance_Report_Sections>;
};

/** input type for inserting object relation for remote table "performance_report.sections" */
export type Performance_Report_Sections_Obj_Rel_Insert_Input = {
  data: Performance_Report_Sections_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Performance_Report_Sections_On_Conflict>;
};

/** on_conflict condition type for table "performance_report.sections" */
export type Performance_Report_Sections_On_Conflict = {
  constraint: Performance_Report_Sections_Constraint;
  update_columns?: Array<Performance_Report_Sections_Update_Column>;
  where?: Maybe<Performance_Report_Sections_Bool_Exp>;
};

/** Ordering options when selecting data from "performance_report.sections". */
export type Performance_Report_Sections_Order_By = {
  id?: Maybe<Order_By>;
  include_missing_pivot_values?: Maybe<Order_By>;
  pivot_fields_aggregate?: Maybe<Performance_Report_Section_Pivot_Field_Aggregate_Order_By>;
  pivot_type?: Maybe<Performance_Report_Pivot_Types_Order_By>;
  pivot_type_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  section_ratio_metrics_aggregate?: Maybe<Performance_Report_Section_Ratio_Metric_Aggregate_Order_By>;
  section_task_blacklists_aggregate?: Maybe<Performance_Report_Section_Task_Blacklist_Aggregate_Order_By>;
  template?: Maybe<Performance_Report_Templates_Order_By>;
  template_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** primary key columns input for table: performance_report.sections */
export type Performance_Report_Sections_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "performance_report.sections" */
export enum Performance_Report_Sections_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IncludeMissingPivotValues = 'include_missing_pivot_values',
  /** column name */
  PivotTypeId = 'pivot_type_id',
  /** column name */
  Position = 'position',
  /** column name */
  TemplateId = 'template_id',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "performance_report.sections" */
export type Performance_Report_Sections_Set_Input = {
  include_missing_pivot_values?: Maybe<Scalars['Boolean']>;
  pivot_type_id?: Maybe<Performance_Report_Pivot_Types_Enum>;
  position?: Maybe<Scalars['smallint']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Performance_Report_Sections_Stddev_Fields = {
  __typename?: 'performance_report_sections_stddev_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "performance_report.sections" */
export type Performance_Report_Sections_Stddev_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Performance_Report_Sections_Stddev_Pop_Fields = {
  __typename?: 'performance_report_sections_stddev_pop_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "performance_report.sections" */
export type Performance_Report_Sections_Stddev_Pop_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Performance_Report_Sections_Stddev_Samp_Fields = {
  __typename?: 'performance_report_sections_stddev_samp_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "performance_report.sections" */
export type Performance_Report_Sections_Stddev_Samp_Order_By = {
  position?: Maybe<Order_By>;
};

/** Streaming cursor of the table "performance_report_sections" */
export type Performance_Report_Sections_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Performance_Report_Sections_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Performance_Report_Sections_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['uuid']>;
  include_missing_pivot_values?: Maybe<Scalars['Boolean']>;
  pivot_type_id?: Maybe<Performance_Report_Pivot_Types_Enum>;
  position?: Maybe<Scalars['smallint']>;
  template_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Performance_Report_Sections_Sum_Fields = {
  __typename?: 'performance_report_sections_sum_fields';
  position?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "performance_report.sections" */
export type Performance_Report_Sections_Sum_Order_By = {
  position?: Maybe<Order_By>;
};

/** update columns of table "performance_report.sections" */
export enum Performance_Report_Sections_Update_Column {
  /** column name */
  IncludeMissingPivotValues = 'include_missing_pivot_values',
  /** column name */
  PivotTypeId = 'pivot_type_id',
  /** column name */
  Position = 'position',
  /** column name */
  Title = 'title'
}

export type Performance_Report_Sections_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Performance_Report_Sections_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Performance_Report_Sections_Set_Input>;
  where: Performance_Report_Sections_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Performance_Report_Sections_Var_Pop_Fields = {
  __typename?: 'performance_report_sections_var_pop_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "performance_report.sections" */
export type Performance_Report_Sections_Var_Pop_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Performance_Report_Sections_Var_Samp_Fields = {
  __typename?: 'performance_report_sections_var_samp_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "performance_report.sections" */
export type Performance_Report_Sections_Var_Samp_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Performance_Report_Sections_Variance_Fields = {
  __typename?: 'performance_report_sections_variance_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "performance_report.sections" */
export type Performance_Report_Sections_Variance_Order_By = {
  position?: Maybe<Order_By>;
};

/** columns and relationships of "performance_report.templates" */
export type Performance_Report_Templates = {
  __typename?: 'performance_report_templates';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  deliverable_templates: Array<Performance_Report_Deliverable_Template>;
  /** An aggregate relationship */
  deliverable_templates_aggregate: Performance_Report_Deliverable_Template_Aggregate;
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  sections: Array<Performance_Report_Sections>;
  /** An aggregate relationship */
  sections_aggregate: Performance_Report_Sections_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
};


/** columns and relationships of "performance_report.templates" */
export type Performance_Report_TemplatesDeliverable_TemplatesArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Deliverable_Template_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Deliverable_Template_Order_By>>;
  where?: Maybe<Performance_Report_Deliverable_Template_Bool_Exp>;
};


/** columns and relationships of "performance_report.templates" */
export type Performance_Report_TemplatesDeliverable_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Deliverable_Template_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Deliverable_Template_Order_By>>;
  where?: Maybe<Performance_Report_Deliverable_Template_Bool_Exp>;
};


/** columns and relationships of "performance_report.templates" */
export type Performance_Report_TemplatesSectionsArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Sections_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Sections_Order_By>>;
  where?: Maybe<Performance_Report_Sections_Bool_Exp>;
};


/** columns and relationships of "performance_report.templates" */
export type Performance_Report_TemplatesSections_AggregateArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Sections_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Sections_Order_By>>;
  where?: Maybe<Performance_Report_Sections_Bool_Exp>;
};

/** aggregated selection of "performance_report.templates" */
export type Performance_Report_Templates_Aggregate = {
  __typename?: 'performance_report_templates_aggregate';
  aggregate?: Maybe<Performance_Report_Templates_Aggregate_Fields>;
  nodes: Array<Performance_Report_Templates>;
};

/** aggregate fields of "performance_report.templates" */
export type Performance_Report_Templates_Aggregate_Fields = {
  __typename?: 'performance_report_templates_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Performance_Report_Templates_Max_Fields>;
  min?: Maybe<Performance_Report_Templates_Min_Fields>;
};


/** aggregate fields of "performance_report.templates" */
export type Performance_Report_Templates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Performance_Report_Templates_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "performance_report.templates". All fields are combined with a logical 'AND'. */
export type Performance_Report_Templates_Bool_Exp = {
  _and?: Maybe<Array<Performance_Report_Templates_Bool_Exp>>;
  _not?: Maybe<Performance_Report_Templates_Bool_Exp>;
  _or?: Maybe<Array<Performance_Report_Templates_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Uuid_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  deliverable_templates?: Maybe<Performance_Report_Deliverable_Template_Bool_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  sections?: Maybe<Performance_Report_Sections_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "performance_report.templates" */
export enum Performance_Report_Templates_Constraint {
  /** unique or primary key constraint on columns "name" */
  TemplatesNameKey = 'templates_name_key',
  /** unique or primary key constraint on columns "id" */
  TemplatesPkey = 'templates_pkey'
}

/** input type for inserting data into table "performance_report.templates" */
export type Performance_Report_Templates_Insert_Input = {
  created_by?: Maybe<Scalars['uuid']>;
  deliverable_templates?: Maybe<Performance_Report_Deliverable_Template_Arr_Rel_Insert_Input>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sections?: Maybe<Performance_Report_Sections_Arr_Rel_Insert_Input>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Performance_Report_Templates_Max_Fields = {
  __typename?: 'performance_report_templates_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Performance_Report_Templates_Min_Fields = {
  __typename?: 'performance_report_templates_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "performance_report.templates" */
export type Performance_Report_Templates_Mutation_Response = {
  __typename?: 'performance_report_templates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Performance_Report_Templates>;
};

/** input type for inserting object relation for remote table "performance_report.templates" */
export type Performance_Report_Templates_Obj_Rel_Insert_Input = {
  data: Performance_Report_Templates_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Performance_Report_Templates_On_Conflict>;
};

/** on_conflict condition type for table "performance_report.templates" */
export type Performance_Report_Templates_On_Conflict = {
  constraint: Performance_Report_Templates_Constraint;
  update_columns?: Array<Performance_Report_Templates_Update_Column>;
  where?: Maybe<Performance_Report_Templates_Bool_Exp>;
};

/** Ordering options when selecting data from "performance_report.templates". */
export type Performance_Report_Templates_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  deliverable_templates_aggregate?: Maybe<Performance_Report_Deliverable_Template_Aggregate_Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  sections_aggregate?: Maybe<Performance_Report_Sections_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
};

/** primary key columns input for table: performance_report.templates */
export type Performance_Report_Templates_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "performance_report.templates" */
export enum Performance_Report_Templates_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "performance_report.templates" */
export type Performance_Report_Templates_Set_Input = {
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "performance_report_templates" */
export type Performance_Report_Templates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Performance_Report_Templates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Performance_Report_Templates_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "performance_report.templates" */
export enum Performance_Report_Templates_Update_Column {
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

export type Performance_Report_Templates_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Performance_Report_Templates_Set_Input>;
  where: Performance_Report_Templates_Bool_Exp;
};

/** Table storing information about peripheral devices, including their model and type for classification purposes. */
export type Peripherals = {
  __typename?: 'peripherals';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  model: Scalars['String'];
  /** An array relationship */
  recordings: Array<Recordings>;
  /** An aggregate relationship */
  recordings_aggregate: Recordings_Aggregate;
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** Table storing information about peripheral devices, including their model and type for classification purposes. */
export type PeripheralsRecordingsArgs = {
  distinct_on?: Maybe<Array<Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recordings_Order_By>>;
  where?: Maybe<Recordings_Bool_Exp>;
};


/** Table storing information about peripheral devices, including their model and type for classification purposes. */
export type PeripheralsRecordings_AggregateArgs = {
  distinct_on?: Maybe<Array<Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recordings_Order_By>>;
  where?: Maybe<Recordings_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "peripherals". All fields are combined with a logical 'AND'. */
export type Peripherals_Bool_Exp = {
  _and?: Maybe<Array<Peripherals_Bool_Exp>>;
  _not?: Maybe<Peripherals_Bool_Exp>;
  _or?: Maybe<Array<Peripherals_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  model?: Maybe<String_Comparison_Exp>;
  recordings?: Maybe<Recordings_Bool_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "peripherals" */
export enum Peripherals_Constraint {
  /** unique or primary key constraint on columns "type", "model" */
  PeripheralsModelTypeKey = 'peripherals_model_type_key',
  /** unique or primary key constraint on columns "id" */
  PeripheralsPkey = 'peripherals_pkey'
}

/** input type for inserting data into table "peripherals" */
export type Peripherals_Insert_Input = {
  model?: Maybe<Scalars['String']>;
  recordings?: Maybe<Recordings_Arr_Rel_Insert_Input>;
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "peripherals" */
export type Peripherals_Mutation_Response = {
  __typename?: 'peripherals_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Peripherals>;
};

/** on_conflict condition type for table "peripherals" */
export type Peripherals_On_Conflict = {
  constraint: Peripherals_Constraint;
  update_columns?: Array<Peripherals_Update_Column>;
  where?: Maybe<Peripherals_Bool_Exp>;
};

/** Ordering options when selecting data from "peripherals". */
export type Peripherals_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  recordings_aggregate?: Maybe<Recordings_Aggregate_Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: peripherals */
export type Peripherals_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "peripherals" */
export enum Peripherals_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Model = 'model',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "peripherals" */
export type Peripherals_Set_Input = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  model?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "peripherals" */
export type Peripherals_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Peripherals_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Peripherals_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  model?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "peripherals" */
export enum Peripherals_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Model = 'model',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Peripherals_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Peripherals_Set_Input>;
  where: Peripherals_Bool_Exp;
};

/** columns and relationships of "plan_hypertasks" */
export type Plan_Hypertasks = {
  __typename?: 'plan_hypertasks';
  /** An object relationship */
  hypertask: Hypertasks;
  hypertask_id: Scalars['uuid'];
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device: Scalars['Int'];
  /** An object relationship */
  plan: Plans;
  plan_id: Scalars['uuid'];
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority: Scalars['Int'];
};

/** aggregated selection of "plan_hypertasks" */
export type Plan_Hypertasks_Aggregate = {
  __typename?: 'plan_hypertasks_aggregate';
  aggregate?: Maybe<Plan_Hypertasks_Aggregate_Fields>;
  nodes: Array<Plan_Hypertasks>;
};

/** aggregate fields of "plan_hypertasks" */
export type Plan_Hypertasks_Aggregate_Fields = {
  __typename?: 'plan_hypertasks_aggregate_fields';
  avg?: Maybe<Plan_Hypertasks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Plan_Hypertasks_Max_Fields>;
  min?: Maybe<Plan_Hypertasks_Min_Fields>;
  stddev?: Maybe<Plan_Hypertasks_Stddev_Fields>;
  stddev_pop?: Maybe<Plan_Hypertasks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Plan_Hypertasks_Stddev_Samp_Fields>;
  sum?: Maybe<Plan_Hypertasks_Sum_Fields>;
  var_pop?: Maybe<Plan_Hypertasks_Var_Pop_Fields>;
  var_samp?: Maybe<Plan_Hypertasks_Var_Samp_Fields>;
  variance?: Maybe<Plan_Hypertasks_Variance_Fields>;
};


/** aggregate fields of "plan_hypertasks" */
export type Plan_Hypertasks_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Plan_Hypertasks_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "plan_hypertasks" */
export type Plan_Hypertasks_Aggregate_Order_By = {
  avg?: Maybe<Plan_Hypertasks_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Plan_Hypertasks_Max_Order_By>;
  min?: Maybe<Plan_Hypertasks_Min_Order_By>;
  stddev?: Maybe<Plan_Hypertasks_Stddev_Order_By>;
  stddev_pop?: Maybe<Plan_Hypertasks_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Plan_Hypertasks_Stddev_Samp_Order_By>;
  sum?: Maybe<Plan_Hypertasks_Sum_Order_By>;
  var_pop?: Maybe<Plan_Hypertasks_Var_Pop_Order_By>;
  var_samp?: Maybe<Plan_Hypertasks_Var_Samp_Order_By>;
  variance?: Maybe<Plan_Hypertasks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "plan_hypertasks" */
export type Plan_Hypertasks_Arr_Rel_Insert_Input = {
  data: Array<Plan_Hypertasks_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Plan_Hypertasks_On_Conflict>;
};

/** aggregate avg on columns */
export type Plan_Hypertasks_Avg_Fields = {
  __typename?: 'plan_hypertasks_avg_fields';
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Scalars['Float']>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "plan_hypertasks" */
export type Plan_Hypertasks_Avg_Order_By = {
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Order_By>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "plan_hypertasks". All fields are combined with a logical 'AND'. */
export type Plan_Hypertasks_Bool_Exp = {
  _and?: Maybe<Array<Plan_Hypertasks_Bool_Exp>>;
  _not?: Maybe<Plan_Hypertasks_Bool_Exp>;
  _or?: Maybe<Array<Plan_Hypertasks_Bool_Exp>>;
  hypertask?: Maybe<Hypertasks_Bool_Exp>;
  hypertask_id?: Maybe<Uuid_Comparison_Exp>;
  min_per_device?: Maybe<Int_Comparison_Exp>;
  plan?: Maybe<Plans_Bool_Exp>;
  plan_id?: Maybe<Uuid_Comparison_Exp>;
  priority?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "plan_hypertasks" */
export enum Plan_Hypertasks_Constraint {
  /** unique or primary key constraint on columns "hypertask_id", "plan_id" */
  PlanHypertasksPkey = 'plan_hypertasks_pkey'
}

/** input type for incrementing numeric columns in table "plan_hypertasks" */
export type Plan_Hypertasks_Inc_Input = {
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Scalars['Int']>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "plan_hypertasks" */
export type Plan_Hypertasks_Insert_Input = {
  hypertask?: Maybe<Hypertasks_Obj_Rel_Insert_Input>;
  hypertask_id?: Maybe<Scalars['uuid']>;
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Scalars['Int']>;
  plan?: Maybe<Plans_Obj_Rel_Insert_Input>;
  plan_id?: Maybe<Scalars['uuid']>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Plan_Hypertasks_Max_Fields = {
  __typename?: 'plan_hypertasks_max_fields';
  hypertask_id?: Maybe<Scalars['uuid']>;
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Scalars['Int']>;
  plan_id?: Maybe<Scalars['uuid']>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "plan_hypertasks" */
export type Plan_Hypertasks_Max_Order_By = {
  hypertask_id?: Maybe<Order_By>;
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Order_By>;
  plan_id?: Maybe<Order_By>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Plan_Hypertasks_Min_Fields = {
  __typename?: 'plan_hypertasks_min_fields';
  hypertask_id?: Maybe<Scalars['uuid']>;
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Scalars['Int']>;
  plan_id?: Maybe<Scalars['uuid']>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "plan_hypertasks" */
export type Plan_Hypertasks_Min_Order_By = {
  hypertask_id?: Maybe<Order_By>;
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Order_By>;
  plan_id?: Maybe<Order_By>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Order_By>;
};

/** response of any mutation on the table "plan_hypertasks" */
export type Plan_Hypertasks_Mutation_Response = {
  __typename?: 'plan_hypertasks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Plan_Hypertasks>;
};

/** on_conflict condition type for table "plan_hypertasks" */
export type Plan_Hypertasks_On_Conflict = {
  constraint: Plan_Hypertasks_Constraint;
  update_columns?: Array<Plan_Hypertasks_Update_Column>;
  where?: Maybe<Plan_Hypertasks_Bool_Exp>;
};

/** Ordering options when selecting data from "plan_hypertasks". */
export type Plan_Hypertasks_Order_By = {
  hypertask?: Maybe<Hypertasks_Order_By>;
  hypertask_id?: Maybe<Order_By>;
  min_per_device?: Maybe<Order_By>;
  plan?: Maybe<Plans_Order_By>;
  plan_id?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
};

/** primary key columns input for table: plan_hypertasks */
export type Plan_Hypertasks_Pk_Columns_Input = {
  hypertask_id: Scalars['uuid'];
  plan_id: Scalars['uuid'];
};

/** select columns of table "plan_hypertasks" */
export enum Plan_Hypertasks_Select_Column {
  /** column name */
  HypertaskId = 'hypertask_id',
  /** column name */
  MinPerDevice = 'min_per_device',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  Priority = 'priority'
}

/** input type for updating data in table "plan_hypertasks" */
export type Plan_Hypertasks_Set_Input = {
  hypertask_id?: Maybe<Scalars['uuid']>;
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Scalars['Int']>;
  plan_id?: Maybe<Scalars['uuid']>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Plan_Hypertasks_Stddev_Fields = {
  __typename?: 'plan_hypertasks_stddev_fields';
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Scalars['Float']>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "plan_hypertasks" */
export type Plan_Hypertasks_Stddev_Order_By = {
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Order_By>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Plan_Hypertasks_Stddev_Pop_Fields = {
  __typename?: 'plan_hypertasks_stddev_pop_fields';
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Scalars['Float']>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "plan_hypertasks" */
export type Plan_Hypertasks_Stddev_Pop_Order_By = {
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Order_By>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Plan_Hypertasks_Stddev_Samp_Fields = {
  __typename?: 'plan_hypertasks_stddev_samp_fields';
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Scalars['Float']>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "plan_hypertasks" */
export type Plan_Hypertasks_Stddev_Samp_Order_By = {
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Order_By>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Order_By>;
};

/** Streaming cursor of the table "plan_hypertasks" */
export type Plan_Hypertasks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Plan_Hypertasks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Plan_Hypertasks_Stream_Cursor_Value_Input = {
  hypertask_id?: Maybe<Scalars['uuid']>;
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Scalars['Int']>;
  plan_id?: Maybe<Scalars['uuid']>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Plan_Hypertasks_Sum_Fields = {
  __typename?: 'plan_hypertasks_sum_fields';
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Scalars['Int']>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "plan_hypertasks" */
export type Plan_Hypertasks_Sum_Order_By = {
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Order_By>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Order_By>;
};

/** update columns of table "plan_hypertasks" */
export enum Plan_Hypertasks_Update_Column {
  /** column name */
  HypertaskId = 'hypertask_id',
  /** column name */
  MinPerDevice = 'min_per_device',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  Priority = 'priority'
}

export type Plan_Hypertasks_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Plan_Hypertasks_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Plan_Hypertasks_Set_Input>;
  where: Plan_Hypertasks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Plan_Hypertasks_Var_Pop_Fields = {
  __typename?: 'plan_hypertasks_var_pop_fields';
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Scalars['Float']>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "plan_hypertasks" */
export type Plan_Hypertasks_Var_Pop_Order_By = {
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Order_By>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Plan_Hypertasks_Var_Samp_Fields = {
  __typename?: 'plan_hypertasks_var_samp_fields';
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Scalars['Float']>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "plan_hypertasks" */
export type Plan_Hypertasks_Var_Samp_Order_By = {
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Order_By>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Plan_Hypertasks_Variance_Fields = {
  __typename?: 'plan_hypertasks_variance_fields';
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Scalars['Float']>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "plan_hypertasks" */
export type Plan_Hypertasks_Variance_Order_By = {
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Order_By>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Order_By>;
};

/** columns and relationships of "plan_tasks" */
export type Plan_Tasks = {
  __typename?: 'plan_tasks';
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** The minimum number of times the task should be performed on each of the distinct devices in the given data collection phase */
  min_per_device: Scalars['Int'];
  /** An object relationship */
  plan: Plans;
  plan_id: Scalars['uuid'];
  /** Priority of the task in the given data collection phase */
  priority: Scalars['Int'];
  /** An object relationship */
  task: Tasks;
  task_id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "plan_tasks" */
export type Plan_Tasks_Aggregate = {
  __typename?: 'plan_tasks_aggregate';
  aggregate?: Maybe<Plan_Tasks_Aggregate_Fields>;
  nodes: Array<Plan_Tasks>;
};

/** aggregate fields of "plan_tasks" */
export type Plan_Tasks_Aggregate_Fields = {
  __typename?: 'plan_tasks_aggregate_fields';
  avg?: Maybe<Plan_Tasks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Plan_Tasks_Max_Fields>;
  min?: Maybe<Plan_Tasks_Min_Fields>;
  stddev?: Maybe<Plan_Tasks_Stddev_Fields>;
  stddev_pop?: Maybe<Plan_Tasks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Plan_Tasks_Stddev_Samp_Fields>;
  sum?: Maybe<Plan_Tasks_Sum_Fields>;
  var_pop?: Maybe<Plan_Tasks_Var_Pop_Fields>;
  var_samp?: Maybe<Plan_Tasks_Var_Samp_Fields>;
  variance?: Maybe<Plan_Tasks_Variance_Fields>;
};


/** aggregate fields of "plan_tasks" */
export type Plan_Tasks_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Plan_Tasks_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "plan_tasks" */
export type Plan_Tasks_Aggregate_Order_By = {
  avg?: Maybe<Plan_Tasks_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Plan_Tasks_Max_Order_By>;
  min?: Maybe<Plan_Tasks_Min_Order_By>;
  stddev?: Maybe<Plan_Tasks_Stddev_Order_By>;
  stddev_pop?: Maybe<Plan_Tasks_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Plan_Tasks_Stddev_Samp_Order_By>;
  sum?: Maybe<Plan_Tasks_Sum_Order_By>;
  var_pop?: Maybe<Plan_Tasks_Var_Pop_Order_By>;
  var_samp?: Maybe<Plan_Tasks_Var_Samp_Order_By>;
  variance?: Maybe<Plan_Tasks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "plan_tasks" */
export type Plan_Tasks_Arr_Rel_Insert_Input = {
  data: Array<Plan_Tasks_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Plan_Tasks_On_Conflict>;
};

/** aggregate avg on columns */
export type Plan_Tasks_Avg_Fields = {
  __typename?: 'plan_tasks_avg_fields';
  /** The minimum number of times the task should be performed on each of the distinct devices in the given data collection phase */
  min_per_device?: Maybe<Scalars['Float']>;
  /** Priority of the task in the given data collection phase */
  priority?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "plan_tasks" */
export type Plan_Tasks_Avg_Order_By = {
  /** The minimum number of times the task should be performed on each of the distinct devices in the given data collection phase */
  min_per_device?: Maybe<Order_By>;
  /** Priority of the task in the given data collection phase */
  priority?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "plan_tasks". All fields are combined with a logical 'AND'. */
export type Plan_Tasks_Bool_Exp = {
  _and?: Maybe<Array<Plan_Tasks_Bool_Exp>>;
  _not?: Maybe<Plan_Tasks_Bool_Exp>;
  _or?: Maybe<Array<Plan_Tasks_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  min_per_device?: Maybe<Int_Comparison_Exp>;
  plan?: Maybe<Plans_Bool_Exp>;
  plan_id?: Maybe<Uuid_Comparison_Exp>;
  priority?: Maybe<Int_Comparison_Exp>;
  task?: Maybe<Tasks_Bool_Exp>;
  task_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "plan_tasks" */
export enum Plan_Tasks_Constraint {
  /** unique or primary key constraint on columns "id" */
  PlanTasksPkey = 'plan_tasks_pkey',
  /** unique or primary key constraint on columns "plan_id", "task_id" */
  PlanTasksPlanIdTaskIdKey = 'plan_tasks_plan_id_task_id_key'
}

/** input type for incrementing numeric columns in table "plan_tasks" */
export type Plan_Tasks_Inc_Input = {
  /** The minimum number of times the task should be performed on each of the distinct devices in the given data collection phase */
  min_per_device?: Maybe<Scalars['Int']>;
  /** Priority of the task in the given data collection phase */
  priority?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "plan_tasks" */
export type Plan_Tasks_Insert_Input = {
  /** The minimum number of times the task should be performed on each of the distinct devices in the given data collection phase */
  min_per_device?: Maybe<Scalars['Int']>;
  plan?: Maybe<Plans_Obj_Rel_Insert_Input>;
  plan_id?: Maybe<Scalars['uuid']>;
  /** Priority of the task in the given data collection phase */
  priority?: Maybe<Scalars['Int']>;
  task?: Maybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Plan_Tasks_Max_Fields = {
  __typename?: 'plan_tasks_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  /** The minimum number of times the task should be performed on each of the distinct devices in the given data collection phase */
  min_per_device?: Maybe<Scalars['Int']>;
  plan_id?: Maybe<Scalars['uuid']>;
  /** Priority of the task in the given data collection phase */
  priority?: Maybe<Scalars['Int']>;
  task_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "plan_tasks" */
export type Plan_Tasks_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** The minimum number of times the task should be performed on each of the distinct devices in the given data collection phase */
  min_per_device?: Maybe<Order_By>;
  plan_id?: Maybe<Order_By>;
  /** Priority of the task in the given data collection phase */
  priority?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Plan_Tasks_Min_Fields = {
  __typename?: 'plan_tasks_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  /** The minimum number of times the task should be performed on each of the distinct devices in the given data collection phase */
  min_per_device?: Maybe<Scalars['Int']>;
  plan_id?: Maybe<Scalars['uuid']>;
  /** Priority of the task in the given data collection phase */
  priority?: Maybe<Scalars['Int']>;
  task_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "plan_tasks" */
export type Plan_Tasks_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** The minimum number of times the task should be performed on each of the distinct devices in the given data collection phase */
  min_per_device?: Maybe<Order_By>;
  plan_id?: Maybe<Order_By>;
  /** Priority of the task in the given data collection phase */
  priority?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "plan_tasks" */
export type Plan_Tasks_Mutation_Response = {
  __typename?: 'plan_tasks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Plan_Tasks>;
};

/** on_conflict condition type for table "plan_tasks" */
export type Plan_Tasks_On_Conflict = {
  constraint: Plan_Tasks_Constraint;
  update_columns?: Array<Plan_Tasks_Update_Column>;
  where?: Maybe<Plan_Tasks_Bool_Exp>;
};

/** Ordering options when selecting data from "plan_tasks". */
export type Plan_Tasks_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  min_per_device?: Maybe<Order_By>;
  plan?: Maybe<Plans_Order_By>;
  plan_id?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  task?: Maybe<Tasks_Order_By>;
  task_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: plan_tasks */
export type Plan_Tasks_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "plan_tasks" */
export enum Plan_Tasks_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MinPerDevice = 'min_per_device',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  Priority = 'priority',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "plan_tasks" */
export type Plan_Tasks_Set_Input = {
  /** The minimum number of times the task should be performed on each of the distinct devices in the given data collection phase */
  min_per_device?: Maybe<Scalars['Int']>;
  plan_id?: Maybe<Scalars['uuid']>;
  /** Priority of the task in the given data collection phase */
  priority?: Maybe<Scalars['Int']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Plan_Tasks_Stddev_Fields = {
  __typename?: 'plan_tasks_stddev_fields';
  /** The minimum number of times the task should be performed on each of the distinct devices in the given data collection phase */
  min_per_device?: Maybe<Scalars['Float']>;
  /** Priority of the task in the given data collection phase */
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "plan_tasks" */
export type Plan_Tasks_Stddev_Order_By = {
  /** The minimum number of times the task should be performed on each of the distinct devices in the given data collection phase */
  min_per_device?: Maybe<Order_By>;
  /** Priority of the task in the given data collection phase */
  priority?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Plan_Tasks_Stddev_Pop_Fields = {
  __typename?: 'plan_tasks_stddev_pop_fields';
  /** The minimum number of times the task should be performed on each of the distinct devices in the given data collection phase */
  min_per_device?: Maybe<Scalars['Float']>;
  /** Priority of the task in the given data collection phase */
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "plan_tasks" */
export type Plan_Tasks_Stddev_Pop_Order_By = {
  /** The minimum number of times the task should be performed on each of the distinct devices in the given data collection phase */
  min_per_device?: Maybe<Order_By>;
  /** Priority of the task in the given data collection phase */
  priority?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Plan_Tasks_Stddev_Samp_Fields = {
  __typename?: 'plan_tasks_stddev_samp_fields';
  /** The minimum number of times the task should be performed on each of the distinct devices in the given data collection phase */
  min_per_device?: Maybe<Scalars['Float']>;
  /** Priority of the task in the given data collection phase */
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "plan_tasks" */
export type Plan_Tasks_Stddev_Samp_Order_By = {
  /** The minimum number of times the task should be performed on each of the distinct devices in the given data collection phase */
  min_per_device?: Maybe<Order_By>;
  /** Priority of the task in the given data collection phase */
  priority?: Maybe<Order_By>;
};

/** Streaming cursor of the table "plan_tasks" */
export type Plan_Tasks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Plan_Tasks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Plan_Tasks_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  /** The minimum number of times the task should be performed on each of the distinct devices in the given data collection phase */
  min_per_device?: Maybe<Scalars['Int']>;
  plan_id?: Maybe<Scalars['uuid']>;
  /** Priority of the task in the given data collection phase */
  priority?: Maybe<Scalars['Int']>;
  task_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Plan_Tasks_Sum_Fields = {
  __typename?: 'plan_tasks_sum_fields';
  /** The minimum number of times the task should be performed on each of the distinct devices in the given data collection phase */
  min_per_device?: Maybe<Scalars['Int']>;
  /** Priority of the task in the given data collection phase */
  priority?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "plan_tasks" */
export type Plan_Tasks_Sum_Order_By = {
  /** The minimum number of times the task should be performed on each of the distinct devices in the given data collection phase */
  min_per_device?: Maybe<Order_By>;
  /** Priority of the task in the given data collection phase */
  priority?: Maybe<Order_By>;
};

/** update columns of table "plan_tasks" */
export enum Plan_Tasks_Update_Column {
  /** column name */
  MinPerDevice = 'min_per_device',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  Priority = 'priority',
  /** column name */
  TaskId = 'task_id'
}

export type Plan_Tasks_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Plan_Tasks_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Plan_Tasks_Set_Input>;
  where: Plan_Tasks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Plan_Tasks_Var_Pop_Fields = {
  __typename?: 'plan_tasks_var_pop_fields';
  /** The minimum number of times the task should be performed on each of the distinct devices in the given data collection phase */
  min_per_device?: Maybe<Scalars['Float']>;
  /** Priority of the task in the given data collection phase */
  priority?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "plan_tasks" */
export type Plan_Tasks_Var_Pop_Order_By = {
  /** The minimum number of times the task should be performed on each of the distinct devices in the given data collection phase */
  min_per_device?: Maybe<Order_By>;
  /** Priority of the task in the given data collection phase */
  priority?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Plan_Tasks_Var_Samp_Fields = {
  __typename?: 'plan_tasks_var_samp_fields';
  /** The minimum number of times the task should be performed on each of the distinct devices in the given data collection phase */
  min_per_device?: Maybe<Scalars['Float']>;
  /** Priority of the task in the given data collection phase */
  priority?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "plan_tasks" */
export type Plan_Tasks_Var_Samp_Order_By = {
  /** The minimum number of times the task should be performed on each of the distinct devices in the given data collection phase */
  min_per_device?: Maybe<Order_By>;
  /** Priority of the task in the given data collection phase */
  priority?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Plan_Tasks_Variance_Fields = {
  __typename?: 'plan_tasks_variance_fields';
  /** The minimum number of times the task should be performed on each of the distinct devices in the given data collection phase */
  min_per_device?: Maybe<Scalars['Float']>;
  /** Priority of the task in the given data collection phase */
  priority?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "plan_tasks" */
export type Plan_Tasks_Variance_Order_By = {
  /** The minimum number of times the task should be performed on each of the distinct devices in the given data collection phase */
  min_per_device?: Maybe<Order_By>;
  /** Priority of the task in the given data collection phase */
  priority?: Maybe<Order_By>;
};

/** columns and relationships of "plans" */
export type Plans = {
  __typename?: 'plans';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  plan_hypertasks: Array<Plan_Hypertasks>;
  /** An aggregate relationship */
  plan_hypertasks_aggregate: Plan_Hypertasks_Aggregate;
  /** An array relationship */
  plan_tasks: Array<Plan_Tasks>;
  /** An aggregate relationship */
  plan_tasks_aggregate: Plan_Tasks_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "plans" */
export type PlansPlan_HypertasksArgs = {
  distinct_on?: Maybe<Array<Plan_Hypertasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plan_Hypertasks_Order_By>>;
  where?: Maybe<Plan_Hypertasks_Bool_Exp>;
};


/** columns and relationships of "plans" */
export type PlansPlan_Hypertasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Plan_Hypertasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plan_Hypertasks_Order_By>>;
  where?: Maybe<Plan_Hypertasks_Bool_Exp>;
};


/** columns and relationships of "plans" */
export type PlansPlan_TasksArgs = {
  distinct_on?: Maybe<Array<Plan_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plan_Tasks_Order_By>>;
  where?: Maybe<Plan_Tasks_Bool_Exp>;
};


/** columns and relationships of "plans" */
export type PlansPlan_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Plan_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plan_Tasks_Order_By>>;
  where?: Maybe<Plan_Tasks_Bool_Exp>;
};

/** aggregated selection of "plans" */
export type Plans_Aggregate = {
  __typename?: 'plans_aggregate';
  aggregate?: Maybe<Plans_Aggregate_Fields>;
  nodes: Array<Plans>;
};

/** aggregate fields of "plans" */
export type Plans_Aggregate_Fields = {
  __typename?: 'plans_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Plans_Max_Fields>;
  min?: Maybe<Plans_Min_Fields>;
};


/** aggregate fields of "plans" */
export type Plans_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Plans_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "plans". All fields are combined with a logical 'AND'. */
export type Plans_Bool_Exp = {
  _and?: Maybe<Array<Plans_Bool_Exp>>;
  _not?: Maybe<Plans_Bool_Exp>;
  _or?: Maybe<Array<Plans_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  plan_hypertasks?: Maybe<Plan_Hypertasks_Bool_Exp>;
  plan_tasks?: Maybe<Plan_Tasks_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "plans" */
export enum Plans_Constraint {
  /** unique or primary key constraint on columns "name" */
  PlansNameKey = 'plans_name_key',
  /** unique or primary key constraint on columns "id" */
  PlansPkey = 'plans_pkey'
}

/** input type for inserting data into table "plans" */
export type Plans_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  plan_hypertasks?: Maybe<Plan_Hypertasks_Arr_Rel_Insert_Input>;
  plan_tasks?: Maybe<Plan_Tasks_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Plans_Max_Fields = {
  __typename?: 'plans_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Plans_Min_Fields = {
  __typename?: 'plans_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "plans" */
export type Plans_Mutation_Response = {
  __typename?: 'plans_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Plans>;
};

/** input type for inserting object relation for remote table "plans" */
export type Plans_Obj_Rel_Insert_Input = {
  data: Plans_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Plans_On_Conflict>;
};

/** on_conflict condition type for table "plans" */
export type Plans_On_Conflict = {
  constraint: Plans_Constraint;
  update_columns?: Array<Plans_Update_Column>;
  where?: Maybe<Plans_Bool_Exp>;
};

/** Ordering options when selecting data from "plans". */
export type Plans_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  plan_hypertasks_aggregate?: Maybe<Plan_Hypertasks_Aggregate_Order_By>;
  plan_tasks_aggregate?: Maybe<Plan_Tasks_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: plans */
export type Plans_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "plans" */
export enum Plans_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "plans" */
export type Plans_Set_Input = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "plans" */
export type Plans_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Plans_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Plans_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "plans" */
export enum Plans_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

export type Plans_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Plans_Set_Input>;
  where: Plans_Bool_Exp;
};

/** columns and relationships of "profiles" */
export type Profiles = {
  __typename?: 'profiles';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  opmode_profiles: Array<Opmode_Profile>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "profiles" */
export type ProfilesOpmode_ProfilesArgs = {
  distinct_on?: Maybe<Array<Opmode_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Opmode_Profile_Order_By>>;
  where?: Maybe<Opmode_Profile_Bool_Exp>;
};

/** aggregated selection of "profiles" */
export type Profiles_Aggregate = {
  __typename?: 'profiles_aggregate';
  aggregate?: Maybe<Profiles_Aggregate_Fields>;
  nodes: Array<Profiles>;
};

/** aggregate fields of "profiles" */
export type Profiles_Aggregate_Fields = {
  __typename?: 'profiles_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Profiles_Max_Fields>;
  min?: Maybe<Profiles_Min_Fields>;
};


/** aggregate fields of "profiles" */
export type Profiles_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profiles_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "profiles". All fields are combined with a logical 'AND'. */
export type Profiles_Bool_Exp = {
  _and?: Maybe<Array<Profiles_Bool_Exp>>;
  _not?: Maybe<Profiles_Bool_Exp>;
  _or?: Maybe<Array<Profiles_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  opmode_profiles?: Maybe<Opmode_Profile_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "profiles" */
export enum Profiles_Constraint {
  /** unique or primary key constraint on columns "name" */
  ProfilesNameKey = 'profiles_name_key',
  /** unique or primary key constraint on columns "id" */
  ProfilesPkey = 'profiles_pkey'
}

/** input type for inserting data into table "profiles" */
export type Profiles_Insert_Input = {
  name?: Maybe<Scalars['String']>;
  opmode_profiles?: Maybe<Opmode_Profile_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Profiles_Max_Fields = {
  __typename?: 'profiles_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Profiles_Min_Fields = {
  __typename?: 'profiles_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "profiles" */
export type Profiles_Mutation_Response = {
  __typename?: 'profiles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profiles>;
};

/** input type for inserting object relation for remote table "profiles" */
export type Profiles_Obj_Rel_Insert_Input = {
  data: Profiles_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Profiles_On_Conflict>;
};

/** on_conflict condition type for table "profiles" */
export type Profiles_On_Conflict = {
  constraint: Profiles_Constraint;
  update_columns?: Array<Profiles_Update_Column>;
  where?: Maybe<Profiles_Bool_Exp>;
};

/** Ordering options when selecting data from "profiles". */
export type Profiles_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  opmode_profiles_aggregate?: Maybe<Opmode_Profile_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: profiles */
export type Profiles_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "profiles" */
export enum Profiles_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "profiles" */
export type Profiles_Set_Input = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "profiles" */
export type Profiles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Profiles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Profiles_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "profiles" */
export enum Profiles_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Name = 'name'
}

export type Profiles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Profiles_Set_Input>;
  where: Profiles_Bool_Exp;
};

/** Used to attach a common project to any number of other projects, in order to combine their recordings for training */
export type Project_Combo = {
  __typename?: 'project_combo';
  /** An object relationship */
  other_project: Projects;
  other_project_id: Scalars['uuid'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
};

/** aggregated selection of "project_combo" */
export type Project_Combo_Aggregate = {
  __typename?: 'project_combo_aggregate';
  aggregate?: Maybe<Project_Combo_Aggregate_Fields>;
  nodes: Array<Project_Combo>;
};

/** aggregate fields of "project_combo" */
export type Project_Combo_Aggregate_Fields = {
  __typename?: 'project_combo_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Project_Combo_Max_Fields>;
  min?: Maybe<Project_Combo_Min_Fields>;
};


/** aggregate fields of "project_combo" */
export type Project_Combo_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Project_Combo_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_combo" */
export type Project_Combo_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Project_Combo_Max_Order_By>;
  min?: Maybe<Project_Combo_Min_Order_By>;
};

/** input type for inserting array relation for remote table "project_combo" */
export type Project_Combo_Arr_Rel_Insert_Input = {
  data: Array<Project_Combo_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Project_Combo_On_Conflict>;
};

/** Boolean expression to filter rows from the table "project_combo". All fields are combined with a logical 'AND'. */
export type Project_Combo_Bool_Exp = {
  _and?: Maybe<Array<Project_Combo_Bool_Exp>>;
  _not?: Maybe<Project_Combo_Bool_Exp>;
  _or?: Maybe<Array<Project_Combo_Bool_Exp>>;
  other_project?: Maybe<Projects_Bool_Exp>;
  other_project_id?: Maybe<Uuid_Comparison_Exp>;
  project?: Maybe<Projects_Bool_Exp>;
  project_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_combo" */
export enum Project_Combo_Constraint {
  /** unique or primary key constraint on columns "project_id", "other_project_id" */
  ProjectComboPkey = 'project_combo_pkey'
}

/** input type for inserting data into table "project_combo" */
export type Project_Combo_Insert_Input = {
  other_project?: Maybe<Projects_Obj_Rel_Insert_Input>;
  other_project_id?: Maybe<Scalars['uuid']>;
  project?: Maybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Project_Combo_Max_Fields = {
  __typename?: 'project_combo_max_fields';
  other_project_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "project_combo" */
export type Project_Combo_Max_Order_By = {
  other_project_id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Combo_Min_Fields = {
  __typename?: 'project_combo_min_fields';
  other_project_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "project_combo" */
export type Project_Combo_Min_Order_By = {
  other_project_id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "project_combo" */
export type Project_Combo_Mutation_Response = {
  __typename?: 'project_combo_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Combo>;
};

/** on_conflict condition type for table "project_combo" */
export type Project_Combo_On_Conflict = {
  constraint: Project_Combo_Constraint;
  update_columns?: Array<Project_Combo_Update_Column>;
  where?: Maybe<Project_Combo_Bool_Exp>;
};

/** Ordering options when selecting data from "project_combo". */
export type Project_Combo_Order_By = {
  other_project?: Maybe<Projects_Order_By>;
  other_project_id?: Maybe<Order_By>;
  project?: Maybe<Projects_Order_By>;
  project_id?: Maybe<Order_By>;
};

/** primary key columns input for table: project_combo */
export type Project_Combo_Pk_Columns_Input = {
  other_project_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};

/** select columns of table "project_combo" */
export enum Project_Combo_Select_Column {
  /** column name */
  OtherProjectId = 'other_project_id',
  /** column name */
  ProjectId = 'project_id'
}

/** input type for updating data in table "project_combo" */
export type Project_Combo_Set_Input = {
  other_project_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "project_combo" */
export type Project_Combo_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Combo_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Combo_Stream_Cursor_Value_Input = {
  other_project_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "project_combo" */
export enum Project_Combo_Update_Column {
  /** column name */
  OtherProjectId = 'other_project_id'
}

export type Project_Combo_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Project_Combo_Set_Input>;
  where: Project_Combo_Bool_Exp;
};

/** columns and relationships of "project_dataset" */
export type Project_Dataset = {
  __typename?: 'project_dataset';
  /** An object relationship */
  device?: Maybe<Devices>;
  device_id?: Maybe<Scalars['uuid']>;
  dirty?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  project?: Maybe<Projects>;
  project_id?: Maybe<Scalars['uuid']>;
  recorded_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  recording?: Maybe<Recordings>;
  recording_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  room?: Maybe<Rooms>;
  room_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  task?: Maybe<Tasks>;
  task_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "project_dataset" */
export type Project_Dataset_Aggregate = {
  __typename?: 'project_dataset_aggregate';
  aggregate?: Maybe<Project_Dataset_Aggregate_Fields>;
  nodes: Array<Project_Dataset>;
};

/** aggregate fields of "project_dataset" */
export type Project_Dataset_Aggregate_Fields = {
  __typename?: 'project_dataset_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Project_Dataset_Max_Fields>;
  min?: Maybe<Project_Dataset_Min_Fields>;
};


/** aggregate fields of "project_dataset" */
export type Project_Dataset_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Project_Dataset_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_dataset" */
export type Project_Dataset_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Project_Dataset_Max_Order_By>;
  min?: Maybe<Project_Dataset_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "project_dataset". All fields are combined with a logical 'AND'. */
export type Project_Dataset_Bool_Exp = {
  _and?: Maybe<Array<Project_Dataset_Bool_Exp>>;
  _not?: Maybe<Project_Dataset_Bool_Exp>;
  _or?: Maybe<Array<Project_Dataset_Bool_Exp>>;
  device?: Maybe<Devices_Bool_Exp>;
  device_id?: Maybe<Uuid_Comparison_Exp>;
  dirty?: Maybe<Boolean_Comparison_Exp>;
  project?: Maybe<Projects_Bool_Exp>;
  project_id?: Maybe<Uuid_Comparison_Exp>;
  recorded_at?: Maybe<Timestamptz_Comparison_Exp>;
  recording?: Maybe<Recordings_Bool_Exp>;
  recording_id?: Maybe<Uuid_Comparison_Exp>;
  room?: Maybe<Rooms_Bool_Exp>;
  room_id?: Maybe<Uuid_Comparison_Exp>;
  task?: Maybe<Tasks_Bool_Exp>;
  task_id?: Maybe<Uuid_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Project_Dataset_Max_Fields = {
  __typename?: 'project_dataset_max_fields';
  device_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  recorded_at?: Maybe<Scalars['timestamptz']>;
  recording_id?: Maybe<Scalars['uuid']>;
  room_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "project_dataset" */
export type Project_Dataset_Max_Order_By = {
  device_id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  recorded_at?: Maybe<Order_By>;
  recording_id?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Dataset_Min_Fields = {
  __typename?: 'project_dataset_min_fields';
  device_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  recorded_at?: Maybe<Scalars['timestamptz']>;
  recording_id?: Maybe<Scalars['uuid']>;
  room_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "project_dataset" */
export type Project_Dataset_Min_Order_By = {
  device_id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  recorded_at?: Maybe<Order_By>;
  recording_id?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "project_dataset". */
export type Project_Dataset_Order_By = {
  device?: Maybe<Devices_Order_By>;
  device_id?: Maybe<Order_By>;
  dirty?: Maybe<Order_By>;
  project?: Maybe<Projects_Order_By>;
  project_id?: Maybe<Order_By>;
  recorded_at?: Maybe<Order_By>;
  recording?: Maybe<Recordings_Order_By>;
  recording_id?: Maybe<Order_By>;
  room?: Maybe<Rooms_Order_By>;
  room_id?: Maybe<Order_By>;
  task?: Maybe<Tasks_Order_By>;
  task_id?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** select columns of table "project_dataset" */
export enum Project_Dataset_Select_Column {
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  Dirty = 'dirty',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  RecordedAt = 'recorded_at',
  /** column name */
  RecordingId = 'recording_id',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UserId = 'user_id'
}

/** Streaming cursor of the table "project_dataset" */
export type Project_Dataset_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Dataset_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Dataset_Stream_Cursor_Value_Input = {
  device_id?: Maybe<Scalars['uuid']>;
  dirty?: Maybe<Scalars['Boolean']>;
  project_id?: Maybe<Scalars['uuid']>;
  recorded_at?: Maybe<Scalars['timestamptz']>;
  recording_id?: Maybe<Scalars['uuid']>;
  room_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

export type Project_Engine_Metrics_Args = {
  engine_ids?: Maybe<Scalars['_uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

export type Project_Engine_Ratio_Metrics_Args = {
  engine_ids?: Maybe<Scalars['_uuid']>;
  metric_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  task_ids?: Maybe<Scalars['_uuid']>;
};

export type Project_Fields_Args = {
  project_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "project_hypertasks" */
export type Project_Hypertasks = {
  __typename?: 'project_hypertasks';
  /** An object relationship */
  hypertask: Hypertasks;
  hypertask_id: Scalars['uuid'];
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device: Scalars['Int'];
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority: Scalars['Int'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
};

/** aggregated selection of "project_hypertasks" */
export type Project_Hypertasks_Aggregate = {
  __typename?: 'project_hypertasks_aggregate';
  aggregate?: Maybe<Project_Hypertasks_Aggregate_Fields>;
  nodes: Array<Project_Hypertasks>;
};

/** aggregate fields of "project_hypertasks" */
export type Project_Hypertasks_Aggregate_Fields = {
  __typename?: 'project_hypertasks_aggregate_fields';
  avg?: Maybe<Project_Hypertasks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Hypertasks_Max_Fields>;
  min?: Maybe<Project_Hypertasks_Min_Fields>;
  stddev?: Maybe<Project_Hypertasks_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Hypertasks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Hypertasks_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Hypertasks_Sum_Fields>;
  var_pop?: Maybe<Project_Hypertasks_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Hypertasks_Var_Samp_Fields>;
  variance?: Maybe<Project_Hypertasks_Variance_Fields>;
};


/** aggregate fields of "project_hypertasks" */
export type Project_Hypertasks_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Project_Hypertasks_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_hypertasks" */
export type Project_Hypertasks_Aggregate_Order_By = {
  avg?: Maybe<Project_Hypertasks_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Project_Hypertasks_Max_Order_By>;
  min?: Maybe<Project_Hypertasks_Min_Order_By>;
  stddev?: Maybe<Project_Hypertasks_Stddev_Order_By>;
  stddev_pop?: Maybe<Project_Hypertasks_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Project_Hypertasks_Stddev_Samp_Order_By>;
  sum?: Maybe<Project_Hypertasks_Sum_Order_By>;
  var_pop?: Maybe<Project_Hypertasks_Var_Pop_Order_By>;
  var_samp?: Maybe<Project_Hypertasks_Var_Samp_Order_By>;
  variance?: Maybe<Project_Hypertasks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "project_hypertasks" */
export type Project_Hypertasks_Arr_Rel_Insert_Input = {
  data: Array<Project_Hypertasks_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Project_Hypertasks_On_Conflict>;
};

/** aggregate avg on columns */
export type Project_Hypertasks_Avg_Fields = {
  __typename?: 'project_hypertasks_avg_fields';
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Scalars['Float']>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project_hypertasks" */
export type Project_Hypertasks_Avg_Order_By = {
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Order_By>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "project_hypertasks". All fields are combined with a logical 'AND'. */
export type Project_Hypertasks_Bool_Exp = {
  _and?: Maybe<Array<Project_Hypertasks_Bool_Exp>>;
  _not?: Maybe<Project_Hypertasks_Bool_Exp>;
  _or?: Maybe<Array<Project_Hypertasks_Bool_Exp>>;
  hypertask?: Maybe<Hypertasks_Bool_Exp>;
  hypertask_id?: Maybe<Uuid_Comparison_Exp>;
  min_per_device?: Maybe<Int_Comparison_Exp>;
  priority?: Maybe<Int_Comparison_Exp>;
  project?: Maybe<Projects_Bool_Exp>;
  project_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_hypertasks" */
export enum Project_Hypertasks_Constraint {
  /** unique or primary key constraint on columns "hypertask_id", "project_id" */
  ProjectHypertasksPkey = 'project_hypertasks_pkey'
}

/** input type for incrementing numeric columns in table "project_hypertasks" */
export type Project_Hypertasks_Inc_Input = {
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Scalars['Int']>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "project_hypertasks" */
export type Project_Hypertasks_Insert_Input = {
  hypertask?: Maybe<Hypertasks_Obj_Rel_Insert_Input>;
  hypertask_id?: Maybe<Scalars['uuid']>;
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Scalars['Int']>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Scalars['Int']>;
  project?: Maybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Project_Hypertasks_Max_Fields = {
  __typename?: 'project_hypertasks_max_fields';
  hypertask_id?: Maybe<Scalars['uuid']>;
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Scalars['Int']>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "project_hypertasks" */
export type Project_Hypertasks_Max_Order_By = {
  hypertask_id?: Maybe<Order_By>;
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Order_By>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Hypertasks_Min_Fields = {
  __typename?: 'project_hypertasks_min_fields';
  hypertask_id?: Maybe<Scalars['uuid']>;
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Scalars['Int']>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "project_hypertasks" */
export type Project_Hypertasks_Min_Order_By = {
  hypertask_id?: Maybe<Order_By>;
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Order_By>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "project_hypertasks" */
export type Project_Hypertasks_Mutation_Response = {
  __typename?: 'project_hypertasks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Hypertasks>;
};

/** on_conflict condition type for table "project_hypertasks" */
export type Project_Hypertasks_On_Conflict = {
  constraint: Project_Hypertasks_Constraint;
  update_columns?: Array<Project_Hypertasks_Update_Column>;
  where?: Maybe<Project_Hypertasks_Bool_Exp>;
};

/** Ordering options when selecting data from "project_hypertasks". */
export type Project_Hypertasks_Order_By = {
  hypertask?: Maybe<Hypertasks_Order_By>;
  hypertask_id?: Maybe<Order_By>;
  min_per_device?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  project?: Maybe<Projects_Order_By>;
  project_id?: Maybe<Order_By>;
};

/** primary key columns input for table: project_hypertasks */
export type Project_Hypertasks_Pk_Columns_Input = {
  hypertask_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};

/** select columns of table "project_hypertasks" */
export enum Project_Hypertasks_Select_Column {
  /** column name */
  HypertaskId = 'hypertask_id',
  /** column name */
  MinPerDevice = 'min_per_device',
  /** column name */
  Priority = 'priority',
  /** column name */
  ProjectId = 'project_id'
}

/** input type for updating data in table "project_hypertasks" */
export type Project_Hypertasks_Set_Input = {
  hypertask_id?: Maybe<Scalars['uuid']>;
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Scalars['Int']>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Project_Hypertasks_Stddev_Fields = {
  __typename?: 'project_hypertasks_stddev_fields';
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Scalars['Float']>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project_hypertasks" */
export type Project_Hypertasks_Stddev_Order_By = {
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Order_By>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Project_Hypertasks_Stddev_Pop_Fields = {
  __typename?: 'project_hypertasks_stddev_pop_fields';
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Scalars['Float']>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project_hypertasks" */
export type Project_Hypertasks_Stddev_Pop_Order_By = {
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Order_By>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Project_Hypertasks_Stddev_Samp_Fields = {
  __typename?: 'project_hypertasks_stddev_samp_fields';
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Scalars['Float']>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project_hypertasks" */
export type Project_Hypertasks_Stddev_Samp_Order_By = {
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Order_By>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Order_By>;
};

/** Streaming cursor of the table "project_hypertasks" */
export type Project_Hypertasks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Hypertasks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Hypertasks_Stream_Cursor_Value_Input = {
  hypertask_id?: Maybe<Scalars['uuid']>;
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Scalars['Int']>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Project_Hypertasks_Sum_Fields = {
  __typename?: 'project_hypertasks_sum_fields';
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Scalars['Int']>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "project_hypertasks" */
export type Project_Hypertasks_Sum_Order_By = {
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Order_By>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Order_By>;
};

/** update columns of table "project_hypertasks" */
export enum Project_Hypertasks_Update_Column {
  /** column name */
  HypertaskId = 'hypertask_id',
  /** column name */
  MinPerDevice = 'min_per_device',
  /** column name */
  Priority = 'priority',
  /** column name */
  ProjectId = 'project_id'
}

export type Project_Hypertasks_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Project_Hypertasks_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Project_Hypertasks_Set_Input>;
  where: Project_Hypertasks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_Hypertasks_Var_Pop_Fields = {
  __typename?: 'project_hypertasks_var_pop_fields';
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Scalars['Float']>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project_hypertasks" */
export type Project_Hypertasks_Var_Pop_Order_By = {
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Order_By>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Project_Hypertasks_Var_Samp_Fields = {
  __typename?: 'project_hypertasks_var_samp_fields';
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Scalars['Float']>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project_hypertasks" */
export type Project_Hypertasks_Var_Samp_Order_By = {
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Order_By>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Project_Hypertasks_Variance_Fields = {
  __typename?: 'project_hypertasks_variance_fields';
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Scalars['Float']>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project_hypertasks" */
export type Project_Hypertasks_Variance_Order_By = {
  /** Use to set the minimum number of recordings that should be made with the given hypertask. */
  min_per_device?: Maybe<Order_By>;
  /** Use to indicate the urgency of recordings made with the given hypertask. */
  priority?: Maybe<Order_By>;
};

/** columns and relationships of "project_metrics" */
export type Project_Metrics = {
  __typename?: 'project_metrics';
  /** An object relationship */
  project?: Maybe<Projects>;
  project_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  ratio_metric?: Maybe<Performance_Ratio_Metrics>;
  ratio_metric_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "project_metrics" */
export type Project_Metrics_Aggregate = {
  __typename?: 'project_metrics_aggregate';
  aggregate?: Maybe<Project_Metrics_Aggregate_Fields>;
  nodes: Array<Project_Metrics>;
};

/** aggregate fields of "project_metrics" */
export type Project_Metrics_Aggregate_Fields = {
  __typename?: 'project_metrics_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Project_Metrics_Max_Fields>;
  min?: Maybe<Project_Metrics_Min_Fields>;
};


/** aggregate fields of "project_metrics" */
export type Project_Metrics_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Project_Metrics_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "project_metrics". All fields are combined with a logical 'AND'. */
export type Project_Metrics_Bool_Exp = {
  _and?: Maybe<Array<Project_Metrics_Bool_Exp>>;
  _not?: Maybe<Project_Metrics_Bool_Exp>;
  _or?: Maybe<Array<Project_Metrics_Bool_Exp>>;
  project?: Maybe<Projects_Bool_Exp>;
  project_id?: Maybe<Uuid_Comparison_Exp>;
  ratio_metric?: Maybe<Performance_Ratio_Metrics_Bool_Exp>;
  ratio_metric_id?: Maybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Project_Metrics_Max_Fields = {
  __typename?: 'project_metrics_max_fields';
  project_id?: Maybe<Scalars['uuid']>;
  ratio_metric_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Project_Metrics_Min_Fields = {
  __typename?: 'project_metrics_min_fields';
  project_id?: Maybe<Scalars['uuid']>;
  ratio_metric_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "project_metrics". */
export type Project_Metrics_Order_By = {
  project?: Maybe<Projects_Order_By>;
  project_id?: Maybe<Order_By>;
  ratio_metric?: Maybe<Performance_Ratio_Metrics_Order_By>;
  ratio_metric_id?: Maybe<Order_By>;
};

/** select columns of table "project_metrics" */
export enum Project_Metrics_Select_Column {
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  RatioMetricId = 'ratio_metric_id'
}

/** Streaming cursor of the table "project_metrics" */
export type Project_Metrics_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Metrics_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Metrics_Stream_Cursor_Value_Input = {
  project_id?: Maybe<Scalars['uuid']>;
  ratio_metric_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "project_name" */
export type Project_Name = {
  __typename?: 'project_name';
  display_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  project_users: Array<Project_User>;
  /** An aggregate relationship */
  project_users_aggregate: Project_User_Aggregate;
  /** An object relationship */
  projects?: Maybe<Projects>;
};


/** columns and relationships of "project_name" */
export type Project_NameProject_UsersArgs = {
  distinct_on?: Maybe<Array<Project_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_User_Order_By>>;
  where?: Maybe<Project_User_Bool_Exp>;
};


/** columns and relationships of "project_name" */
export type Project_NameProject_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_User_Order_By>>;
  where?: Maybe<Project_User_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "project_name". All fields are combined with a logical 'AND'. */
export type Project_Name_Bool_Exp = {
  _and?: Maybe<Array<Project_Name_Bool_Exp>>;
  _not?: Maybe<Project_Name_Bool_Exp>;
  _or?: Maybe<Array<Project_Name_Bool_Exp>>;
  display_name?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  project_id?: Maybe<Uuid_Comparison_Exp>;
  project_users?: Maybe<Project_User_Bool_Exp>;
  projects?: Maybe<Projects_Bool_Exp>;
};

/** Ordering options when selecting data from "project_name". */
export type Project_Name_Order_By = {
  display_name?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  project_users_aggregate?: Maybe<Project_User_Aggregate_Order_By>;
  projects?: Maybe<Projects_Order_By>;
};

/** select columns of table "project_name" */
export enum Project_Name_Select_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Name = 'name',
  /** column name */
  ProjectId = 'project_id'
}

/** Streaming cursor of the table "project_name" */
export type Project_Name_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Name_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Name_Stream_Cursor_Value_Input = {
  display_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "project_rabbit_test_instances" */
export type Project_Rabbit_Test_Instances = {
  __typename?: 'project_rabbit_test_instances';
  /** An object relationship */
  device?: Maybe<Devices>;
  device_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  engine?: Maybe<Engines>;
  engine_id?: Maybe<Scalars['uuid']>;
  num_distinct_tasks?: Maybe<Scalars['bigint']>;
  num_recordings?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  opmode?: Maybe<Opmodes>;
  opmode_id?: Maybe<Scalars['uuid']>;
  plan_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  project?: Maybe<Projects>;
  project_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  rabbit_test_plan?: Maybe<Rabbit_Test_Plans>;
  started_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "project_rabbit_test_instances" */
export type Project_Rabbit_Test_Instances_Aggregate = {
  __typename?: 'project_rabbit_test_instances_aggregate';
  aggregate?: Maybe<Project_Rabbit_Test_Instances_Aggregate_Fields>;
  nodes: Array<Project_Rabbit_Test_Instances>;
};

/** aggregate fields of "project_rabbit_test_instances" */
export type Project_Rabbit_Test_Instances_Aggregate_Fields = {
  __typename?: 'project_rabbit_test_instances_aggregate_fields';
  avg?: Maybe<Project_Rabbit_Test_Instances_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Rabbit_Test_Instances_Max_Fields>;
  min?: Maybe<Project_Rabbit_Test_Instances_Min_Fields>;
  stddev?: Maybe<Project_Rabbit_Test_Instances_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Rabbit_Test_Instances_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Rabbit_Test_Instances_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Rabbit_Test_Instances_Sum_Fields>;
  var_pop?: Maybe<Project_Rabbit_Test_Instances_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Rabbit_Test_Instances_Var_Samp_Fields>;
  variance?: Maybe<Project_Rabbit_Test_Instances_Variance_Fields>;
};


/** aggregate fields of "project_rabbit_test_instances" */
export type Project_Rabbit_Test_Instances_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Project_Rabbit_Test_Instances_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_rabbit_test_instances" */
export type Project_Rabbit_Test_Instances_Aggregate_Order_By = {
  avg?: Maybe<Project_Rabbit_Test_Instances_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Project_Rabbit_Test_Instances_Max_Order_By>;
  min?: Maybe<Project_Rabbit_Test_Instances_Min_Order_By>;
  stddev?: Maybe<Project_Rabbit_Test_Instances_Stddev_Order_By>;
  stddev_pop?: Maybe<Project_Rabbit_Test_Instances_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Project_Rabbit_Test_Instances_Stddev_Samp_Order_By>;
  sum?: Maybe<Project_Rabbit_Test_Instances_Sum_Order_By>;
  var_pop?: Maybe<Project_Rabbit_Test_Instances_Var_Pop_Order_By>;
  var_samp?: Maybe<Project_Rabbit_Test_Instances_Var_Samp_Order_By>;
  variance?: Maybe<Project_Rabbit_Test_Instances_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Project_Rabbit_Test_Instances_Avg_Fields = {
  __typename?: 'project_rabbit_test_instances_avg_fields';
  num_distinct_tasks?: Maybe<Scalars['Float']>;
  num_recordings?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project_rabbit_test_instances" */
export type Project_Rabbit_Test_Instances_Avg_Order_By = {
  num_distinct_tasks?: Maybe<Order_By>;
  num_recordings?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "project_rabbit_test_instances". All fields are combined with a logical 'AND'. */
export type Project_Rabbit_Test_Instances_Bool_Exp = {
  _and?: Maybe<Array<Project_Rabbit_Test_Instances_Bool_Exp>>;
  _not?: Maybe<Project_Rabbit_Test_Instances_Bool_Exp>;
  _or?: Maybe<Array<Project_Rabbit_Test_Instances_Bool_Exp>>;
  device?: Maybe<Devices_Bool_Exp>;
  device_id?: Maybe<Uuid_Comparison_Exp>;
  engine?: Maybe<Engines_Bool_Exp>;
  engine_id?: Maybe<Uuid_Comparison_Exp>;
  num_distinct_tasks?: Maybe<Bigint_Comparison_Exp>;
  num_recordings?: Maybe<Bigint_Comparison_Exp>;
  opmode?: Maybe<Opmodes_Bool_Exp>;
  opmode_id?: Maybe<Uuid_Comparison_Exp>;
  plan_id?: Maybe<Uuid_Comparison_Exp>;
  project?: Maybe<Projects_Bool_Exp>;
  project_id?: Maybe<Uuid_Comparison_Exp>;
  rabbit_test_plan?: Maybe<Rabbit_Test_Plans_Bool_Exp>;
  started_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Project_Rabbit_Test_Instances_Max_Fields = {
  __typename?: 'project_rabbit_test_instances_max_fields';
  device_id?: Maybe<Scalars['uuid']>;
  engine_id?: Maybe<Scalars['uuid']>;
  num_distinct_tasks?: Maybe<Scalars['bigint']>;
  num_recordings?: Maybe<Scalars['bigint']>;
  opmode_id?: Maybe<Scalars['uuid']>;
  plan_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "project_rabbit_test_instances" */
export type Project_Rabbit_Test_Instances_Max_Order_By = {
  device_id?: Maybe<Order_By>;
  engine_id?: Maybe<Order_By>;
  num_distinct_tasks?: Maybe<Order_By>;
  num_recordings?: Maybe<Order_By>;
  opmode_id?: Maybe<Order_By>;
  plan_id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  started_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Rabbit_Test_Instances_Min_Fields = {
  __typename?: 'project_rabbit_test_instances_min_fields';
  device_id?: Maybe<Scalars['uuid']>;
  engine_id?: Maybe<Scalars['uuid']>;
  num_distinct_tasks?: Maybe<Scalars['bigint']>;
  num_recordings?: Maybe<Scalars['bigint']>;
  opmode_id?: Maybe<Scalars['uuid']>;
  plan_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "project_rabbit_test_instances" */
export type Project_Rabbit_Test_Instances_Min_Order_By = {
  device_id?: Maybe<Order_By>;
  engine_id?: Maybe<Order_By>;
  num_distinct_tasks?: Maybe<Order_By>;
  num_recordings?: Maybe<Order_By>;
  opmode_id?: Maybe<Order_By>;
  plan_id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  started_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "project_rabbit_test_instances". */
export type Project_Rabbit_Test_Instances_Order_By = {
  device?: Maybe<Devices_Order_By>;
  device_id?: Maybe<Order_By>;
  engine?: Maybe<Engines_Order_By>;
  engine_id?: Maybe<Order_By>;
  num_distinct_tasks?: Maybe<Order_By>;
  num_recordings?: Maybe<Order_By>;
  opmode?: Maybe<Opmodes_Order_By>;
  opmode_id?: Maybe<Order_By>;
  plan_id?: Maybe<Order_By>;
  project?: Maybe<Projects_Order_By>;
  project_id?: Maybe<Order_By>;
  rabbit_test_plan?: Maybe<Rabbit_Test_Plans_Order_By>;
  started_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** select columns of table "project_rabbit_test_instances" */
export enum Project_Rabbit_Test_Instances_Select_Column {
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  EngineId = 'engine_id',
  /** column name */
  NumDistinctTasks = 'num_distinct_tasks',
  /** column name */
  NumRecordings = 'num_recordings',
  /** column name */
  OpmodeId = 'opmode_id',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate stddev on columns */
export type Project_Rabbit_Test_Instances_Stddev_Fields = {
  __typename?: 'project_rabbit_test_instances_stddev_fields';
  num_distinct_tasks?: Maybe<Scalars['Float']>;
  num_recordings?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project_rabbit_test_instances" */
export type Project_Rabbit_Test_Instances_Stddev_Order_By = {
  num_distinct_tasks?: Maybe<Order_By>;
  num_recordings?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Project_Rabbit_Test_Instances_Stddev_Pop_Fields = {
  __typename?: 'project_rabbit_test_instances_stddev_pop_fields';
  num_distinct_tasks?: Maybe<Scalars['Float']>;
  num_recordings?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project_rabbit_test_instances" */
export type Project_Rabbit_Test_Instances_Stddev_Pop_Order_By = {
  num_distinct_tasks?: Maybe<Order_By>;
  num_recordings?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Project_Rabbit_Test_Instances_Stddev_Samp_Fields = {
  __typename?: 'project_rabbit_test_instances_stddev_samp_fields';
  num_distinct_tasks?: Maybe<Scalars['Float']>;
  num_recordings?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project_rabbit_test_instances" */
export type Project_Rabbit_Test_Instances_Stddev_Samp_Order_By = {
  num_distinct_tasks?: Maybe<Order_By>;
  num_recordings?: Maybe<Order_By>;
};

/** Streaming cursor of the table "project_rabbit_test_instances" */
export type Project_Rabbit_Test_Instances_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Rabbit_Test_Instances_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Rabbit_Test_Instances_Stream_Cursor_Value_Input = {
  device_id?: Maybe<Scalars['uuid']>;
  engine_id?: Maybe<Scalars['uuid']>;
  num_distinct_tasks?: Maybe<Scalars['bigint']>;
  num_recordings?: Maybe<Scalars['bigint']>;
  opmode_id?: Maybe<Scalars['uuid']>;
  plan_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Project_Rabbit_Test_Instances_Sum_Fields = {
  __typename?: 'project_rabbit_test_instances_sum_fields';
  num_distinct_tasks?: Maybe<Scalars['bigint']>;
  num_recordings?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "project_rabbit_test_instances" */
export type Project_Rabbit_Test_Instances_Sum_Order_By = {
  num_distinct_tasks?: Maybe<Order_By>;
  num_recordings?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Project_Rabbit_Test_Instances_Var_Pop_Fields = {
  __typename?: 'project_rabbit_test_instances_var_pop_fields';
  num_distinct_tasks?: Maybe<Scalars['Float']>;
  num_recordings?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project_rabbit_test_instances" */
export type Project_Rabbit_Test_Instances_Var_Pop_Order_By = {
  num_distinct_tasks?: Maybe<Order_By>;
  num_recordings?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Project_Rabbit_Test_Instances_Var_Samp_Fields = {
  __typename?: 'project_rabbit_test_instances_var_samp_fields';
  num_distinct_tasks?: Maybe<Scalars['Float']>;
  num_recordings?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project_rabbit_test_instances" */
export type Project_Rabbit_Test_Instances_Var_Samp_Order_By = {
  num_distinct_tasks?: Maybe<Order_By>;
  num_recordings?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Project_Rabbit_Test_Instances_Variance_Fields = {
  __typename?: 'project_rabbit_test_instances_variance_fields';
  num_distinct_tasks?: Maybe<Scalars['Float']>;
  num_recordings?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project_rabbit_test_instances" */
export type Project_Rabbit_Test_Instances_Variance_Order_By = {
  num_distinct_tasks?: Maybe<Order_By>;
  num_recordings?: Maybe<Order_By>;
};

export type Project_Recordings_Clean_Args = {
  project_id?: Maybe<Scalars['uuid']>;
};

export type Project_Recordings_Unreviewed_Args = {
  project_id?: Maybe<Scalars['uuid']>;
};

export type Project_Task_Count_Args = {
  input_project_id?: Maybe<Scalars['uuid']>;
  input_task_id?: Maybe<Scalars['uuid']>;
};

export type Project_Task_Count_By_Device_Args = {
  project_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "project_tasks" */
export type Project_Tasks = {
  __typename?: 'project_tasks';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  min_per_device: Scalars['Int'];
  priority: Scalars['Int'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
  /** An object relationship */
  task: Tasks;
  task_id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "project_tasks" */
export type Project_Tasks_Aggregate = {
  __typename?: 'project_tasks_aggregate';
  aggregate?: Maybe<Project_Tasks_Aggregate_Fields>;
  nodes: Array<Project_Tasks>;
};

/** aggregate fields of "project_tasks" */
export type Project_Tasks_Aggregate_Fields = {
  __typename?: 'project_tasks_aggregate_fields';
  avg?: Maybe<Project_Tasks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Tasks_Max_Fields>;
  min?: Maybe<Project_Tasks_Min_Fields>;
  stddev?: Maybe<Project_Tasks_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Tasks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Tasks_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Tasks_Sum_Fields>;
  var_pop?: Maybe<Project_Tasks_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Tasks_Var_Samp_Fields>;
  variance?: Maybe<Project_Tasks_Variance_Fields>;
};


/** aggregate fields of "project_tasks" */
export type Project_Tasks_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Project_Tasks_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_tasks" */
export type Project_Tasks_Aggregate_Order_By = {
  avg?: Maybe<Project_Tasks_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Project_Tasks_Max_Order_By>;
  min?: Maybe<Project_Tasks_Min_Order_By>;
  stddev?: Maybe<Project_Tasks_Stddev_Order_By>;
  stddev_pop?: Maybe<Project_Tasks_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Project_Tasks_Stddev_Samp_Order_By>;
  sum?: Maybe<Project_Tasks_Sum_Order_By>;
  var_pop?: Maybe<Project_Tasks_Var_Pop_Order_By>;
  var_samp?: Maybe<Project_Tasks_Var_Samp_Order_By>;
  variance?: Maybe<Project_Tasks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "project_tasks" */
export type Project_Tasks_Arr_Rel_Insert_Input = {
  data: Array<Project_Tasks_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Project_Tasks_On_Conflict>;
};

/** aggregate avg on columns */
export type Project_Tasks_Avg_Fields = {
  __typename?: 'project_tasks_avg_fields';
  min_per_device?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project_tasks" */
export type Project_Tasks_Avg_Order_By = {
  min_per_device?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "project_tasks". All fields are combined with a logical 'AND'. */
export type Project_Tasks_Bool_Exp = {
  _and?: Maybe<Array<Project_Tasks_Bool_Exp>>;
  _not?: Maybe<Project_Tasks_Bool_Exp>;
  _or?: Maybe<Array<Project_Tasks_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  min_per_device?: Maybe<Int_Comparison_Exp>;
  priority?: Maybe<Int_Comparison_Exp>;
  project?: Maybe<Projects_Bool_Exp>;
  project_id?: Maybe<Uuid_Comparison_Exp>;
  task?: Maybe<Tasks_Bool_Exp>;
  task_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_tasks" */
export enum Project_Tasks_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectTasksPkey = 'project_tasks_pkey',
  /** unique or primary key constraint on columns "project_id", "task_id" */
  ProjectTasksProjectIdTaskIdKey = 'project_tasks_project_id_task_id_key'
}

/** input type for incrementing numeric columns in table "project_tasks" */
export type Project_Tasks_Inc_Input = {
  min_per_device?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "project_tasks" */
export type Project_Tasks_Insert_Input = {
  min_per_device?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  project?: Maybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: Maybe<Scalars['uuid']>;
  task?: Maybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Project_Tasks_Max_Fields = {
  __typename?: 'project_tasks_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  min_per_device?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "project_tasks" */
export type Project_Tasks_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  min_per_device?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Tasks_Min_Fields = {
  __typename?: 'project_tasks_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  min_per_device?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "project_tasks" */
export type Project_Tasks_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  min_per_device?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "project_tasks" */
export type Project_Tasks_Mutation_Response = {
  __typename?: 'project_tasks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Tasks>;
};

/** on_conflict condition type for table "project_tasks" */
export type Project_Tasks_On_Conflict = {
  constraint: Project_Tasks_Constraint;
  update_columns?: Array<Project_Tasks_Update_Column>;
  where?: Maybe<Project_Tasks_Bool_Exp>;
};

/** Ordering options when selecting data from "project_tasks". */
export type Project_Tasks_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  min_per_device?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  project?: Maybe<Projects_Order_By>;
  project_id?: Maybe<Order_By>;
  task?: Maybe<Tasks_Order_By>;
  task_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: project_tasks */
export type Project_Tasks_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "project_tasks" */
export enum Project_Tasks_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  MinPerDevice = 'min_per_device',
  /** column name */
  Priority = 'priority',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "project_tasks" */
export type Project_Tasks_Set_Input = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  min_per_device?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Project_Tasks_Stddev_Fields = {
  __typename?: 'project_tasks_stddev_fields';
  min_per_device?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project_tasks" */
export type Project_Tasks_Stddev_Order_By = {
  min_per_device?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Project_Tasks_Stddev_Pop_Fields = {
  __typename?: 'project_tasks_stddev_pop_fields';
  min_per_device?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project_tasks" */
export type Project_Tasks_Stddev_Pop_Order_By = {
  min_per_device?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Project_Tasks_Stddev_Samp_Fields = {
  __typename?: 'project_tasks_stddev_samp_fields';
  min_per_device?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project_tasks" */
export type Project_Tasks_Stddev_Samp_Order_By = {
  min_per_device?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
};

/** Streaming cursor of the table "project_tasks" */
export type Project_Tasks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Tasks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Tasks_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  min_per_device?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Project_Tasks_Sum_Fields = {
  __typename?: 'project_tasks_sum_fields';
  min_per_device?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "project_tasks" */
export type Project_Tasks_Sum_Order_By = {
  min_per_device?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
};

/** update columns of table "project_tasks" */
export enum Project_Tasks_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  MinPerDevice = 'min_per_device',
  /** column name */
  Priority = 'priority',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  TaskId = 'task_id'
}

export type Project_Tasks_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Project_Tasks_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Project_Tasks_Set_Input>;
  where: Project_Tasks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_Tasks_Var_Pop_Fields = {
  __typename?: 'project_tasks_var_pop_fields';
  min_per_device?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project_tasks" */
export type Project_Tasks_Var_Pop_Order_By = {
  min_per_device?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Project_Tasks_Var_Samp_Fields = {
  __typename?: 'project_tasks_var_samp_fields';
  min_per_device?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project_tasks" */
export type Project_Tasks_Var_Samp_Order_By = {
  min_per_device?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Project_Tasks_Variance_Fields = {
  __typename?: 'project_tasks_variance_fields';
  min_per_device?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project_tasks" */
export type Project_Tasks_Variance_Order_By = {
  min_per_device?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
};

/** columns and relationships of "project_user" */
export type Project_User = {
  __typename?: 'project_user';
  manager: Scalars['Boolean'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "project_user" */
export type Project_User_Aggregate = {
  __typename?: 'project_user_aggregate';
  aggregate?: Maybe<Project_User_Aggregate_Fields>;
  nodes: Array<Project_User>;
};

/** aggregate fields of "project_user" */
export type Project_User_Aggregate_Fields = {
  __typename?: 'project_user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Project_User_Max_Fields>;
  min?: Maybe<Project_User_Min_Fields>;
};


/** aggregate fields of "project_user" */
export type Project_User_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Project_User_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_user" */
export type Project_User_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Project_User_Max_Order_By>;
  min?: Maybe<Project_User_Min_Order_By>;
};

/** input type for inserting array relation for remote table "project_user" */
export type Project_User_Arr_Rel_Insert_Input = {
  data: Array<Project_User_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Project_User_On_Conflict>;
};

/** Boolean expression to filter rows from the table "project_user". All fields are combined with a logical 'AND'. */
export type Project_User_Bool_Exp = {
  _and?: Maybe<Array<Project_User_Bool_Exp>>;
  _not?: Maybe<Project_User_Bool_Exp>;
  _or?: Maybe<Array<Project_User_Bool_Exp>>;
  manager?: Maybe<Boolean_Comparison_Exp>;
  project?: Maybe<Projects_Bool_Exp>;
  project_id?: Maybe<Uuid_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_user" */
export enum Project_User_Constraint {
  /** unique or primary key constraint on columns "project_id", "user_id" */
  ProjectUserPkey = 'project_user_pkey'
}

/** input type for inserting data into table "project_user" */
export type Project_User_Insert_Input = {
  manager?: Maybe<Scalars['Boolean']>;
  project?: Maybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: Maybe<Scalars['uuid']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Project_User_Max_Fields = {
  __typename?: 'project_user_max_fields';
  project_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "project_user" */
export type Project_User_Max_Order_By = {
  project_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Project_User_Min_Fields = {
  __typename?: 'project_user_min_fields';
  project_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "project_user" */
export type Project_User_Min_Order_By = {
  project_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "project_user" */
export type Project_User_Mutation_Response = {
  __typename?: 'project_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_User>;
};

/** on_conflict condition type for table "project_user" */
export type Project_User_On_Conflict = {
  constraint: Project_User_Constraint;
  update_columns?: Array<Project_User_Update_Column>;
  where?: Maybe<Project_User_Bool_Exp>;
};

/** Ordering options when selecting data from "project_user". */
export type Project_User_Order_By = {
  manager?: Maybe<Order_By>;
  project?: Maybe<Projects_Order_By>;
  project_id?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: project_user */
export type Project_User_Pk_Columns_Input = {
  project_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** select columns of table "project_user" */
export enum Project_User_Select_Column {
  /** column name */
  Manager = 'manager',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "project_user" */
export type Project_User_Set_Input = {
  manager?: Maybe<Scalars['Boolean']>;
  project_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "project_user" */
export type Project_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_User_Stream_Cursor_Value_Input = {
  manager?: Maybe<Scalars['Boolean']>;
  project_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "project_user" */
export enum Project_User_Update_Column {
  /** column name */
  Manager = 'manager',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UserId = 'user_id'
}

export type Project_User_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Project_User_Set_Input>;
  where: Project_User_Bool_Exp;
};

/** columns and relationships of "projects" */
export type Projects = {
  __typename?: 'projects';
  /** An array relationship */
  audio_measurements_project_tasks: Array<Audio_Measurements_Project_Tasks>;
  /** An aggregate relationship */
  audio_measurements_project_tasks_aggregate: Audio_Measurements_Project_Tasks_Aggregate;
  /** The Azure `definitionId` that references the pipeline used to build engines for this project. */
  azure_build_ref?: Maybe<Scalars['Int']>;
  /** The Azure `definitionId` that references the release pipeline used to release engines for this project. */
  azure_release_ref?: Maybe<Scalars['Int']>;
  /** An object relationship */
  calibration_opmode?: Maybe<Opmodes>;
  calibration_opmode_id?: Maybe<Scalars['uuid']>;
  completed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  customer: Customers;
  customer_id: Scalars['uuid'];
  date_end?: Maybe<Scalars['timestamptz']>;
  date_start?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  deliverable: Deliverables;
  deliverable_id: Scalars['uuid'];
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  device_projects: Array<Device_Project>;
  /** An aggregate relationship */
  device_projects_aggregate: Device_Project_Aggregate;
  /** An object relationship */
  dynamic_scaling?: Maybe<Dynamic_Scaling>;
  dynamic_scaling_id?: Maybe<Scalars['uuid']>;
  enable_arcadia_processing: Scalars['Boolean'];
  enable_greatness_processing: Scalars['Boolean'];
  enable_ground_truth_processing: Scalars['Boolean'];
  far_threshold?: Maybe<Scalars['Int']>;
  firmware_engine_release_ref?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  min_devices_per_task: Scalars['Int'];
  /** An array relationship */
  mode_projects: Array<Mode_Project>;
  /** An aggregate relationship */
  mode_projects_aggregate: Mode_Project_Aggregate;
  /** An object relationship */
  model: Models;
  model_id: Scalars['uuid'];
  mongo_source_query?: Maybe<Scalars['json']>;
  near_threshold?: Maybe<Scalars['Int']>;
  /** An array relationship */
  observances: Array<Integration_Test_Observances>;
  /** An aggregate relationship */
  observances_aggregate: Integration_Test_Observances_Aggregate;
  /** An array relationship */
  opmode_projects: Array<Opmode_Project>;
  /** An aggregate relationship */
  opmode_projects_aggregate: Opmode_Project_Aggregate;
  /** An array relationship */
  project_combos: Array<Project_Combo>;
  /** An aggregate relationship */
  project_combos_aggregate: Project_Combo_Aggregate;
  /** An array relationship */
  project_dataset: Array<Project_Dataset>;
  /** An aggregate relationship */
  project_dataset_aggregate: Project_Dataset_Aggregate;
  /** An array relationship */
  project_hypertasks: Array<Project_Hypertasks>;
  /** An aggregate relationship */
  project_hypertasks_aggregate: Project_Hypertasks_Aggregate;
  /** An object relationship */
  project_name?: Maybe<Project_Name>;
  /** An array relationship */
  project_rabbit_test_plans: Array<Rabbit_Test_Plan_Project>;
  /** An aggregate relationship */
  project_rabbit_test_plans_aggregate: Rabbit_Test_Plan_Project_Aggregate;
  /** An array relationship */
  project_sequences: Array<Integration_Test_Project_Sequence>;
  /** An aggregate relationship */
  project_sequences_aggregate: Integration_Test_Project_Sequence_Aggregate;
  /** An array relationship */
  project_tasks: Array<Project_Tasks>;
  /** An aggregate relationship */
  project_tasks_aggregate: Project_Tasks_Aggregate;
  /** An array relationship */
  project_users: Array<Project_User>;
  /** An aggregate relationship */
  project_users_aggregate: Project_User_Aggregate;
  /** An array relationship */
  rabbit_test_instances: Array<Project_Rabbit_Test_Instances>;
  /** An aggregate relationship */
  rabbit_test_instances_aggregate: Project_Rabbit_Test_Instances_Aggregate;
  /** An array relationship */
  recordings: Array<Recordings>;
  /** An aggregate relationship */
  recordings_aggregate: Recordings_Aggregate;
  /** An array relationship */
  scores: Array<Simulations_Scores>;
  /** An object relationship */
  simulation_opmode?: Maybe<Opmodes>;
  simulation_opmode_id?: Maybe<Scalars['uuid']>;
  slack_channel?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  user_recordings_by_day_stats: Array<User_Recordings_By_Day_Stats>;
  /** An aggregate relationship */
  user_recordings_by_day_stats_aggregate: User_Recordings_By_Day_Stats_Aggregate;
  variant: Scalars['String'];
  /** An object relationship */
  xtensa_configuration?: Maybe<Xtensa_Configurations>;
  xtensa_configuration_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "projects" */
export type ProjectsAudio_Measurements_Project_TasksArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Project_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Project_Tasks_Order_By>>;
  where?: Maybe<Audio_Measurements_Project_Tasks_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsAudio_Measurements_Project_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Project_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Project_Tasks_Order_By>>;
  where?: Maybe<Audio_Measurements_Project_Tasks_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsDevice_ProjectsArgs = {
  distinct_on?: Maybe<Array<Device_Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Device_Project_Order_By>>;
  where?: Maybe<Device_Project_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsDevice_Projects_AggregateArgs = {
  distinct_on?: Maybe<Array<Device_Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Device_Project_Order_By>>;
  where?: Maybe<Device_Project_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsMode_ProjectsArgs = {
  distinct_on?: Maybe<Array<Mode_Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mode_Project_Order_By>>;
  where?: Maybe<Mode_Project_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsMode_Projects_AggregateArgs = {
  distinct_on?: Maybe<Array<Mode_Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mode_Project_Order_By>>;
  where?: Maybe<Mode_Project_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsMongo_Source_QueryArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "projects" */
export type ProjectsObservancesArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Observances_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Observances_Order_By>>;
  where?: Maybe<Integration_Test_Observances_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsObservances_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Observances_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Observances_Order_By>>;
  where?: Maybe<Integration_Test_Observances_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsOpmode_ProjectsArgs = {
  distinct_on?: Maybe<Array<Opmode_Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Opmode_Project_Order_By>>;
  where?: Maybe<Opmode_Project_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsOpmode_Projects_AggregateArgs = {
  distinct_on?: Maybe<Array<Opmode_Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Opmode_Project_Order_By>>;
  where?: Maybe<Opmode_Project_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsProject_CombosArgs = {
  distinct_on?: Maybe<Array<Project_Combo_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Combo_Order_By>>;
  where?: Maybe<Project_Combo_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsProject_Combos_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Combo_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Combo_Order_By>>;
  where?: Maybe<Project_Combo_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsProject_DatasetArgs = {
  distinct_on?: Maybe<Array<Project_Dataset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Dataset_Order_By>>;
  where?: Maybe<Project_Dataset_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsProject_Dataset_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Dataset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Dataset_Order_By>>;
  where?: Maybe<Project_Dataset_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsProject_HypertasksArgs = {
  distinct_on?: Maybe<Array<Project_Hypertasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Hypertasks_Order_By>>;
  where?: Maybe<Project_Hypertasks_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsProject_Hypertasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Hypertasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Hypertasks_Order_By>>;
  where?: Maybe<Project_Hypertasks_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsProject_Rabbit_Test_PlansArgs = {
  distinct_on?: Maybe<Array<Rabbit_Test_Plan_Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rabbit_Test_Plan_Project_Order_By>>;
  where?: Maybe<Rabbit_Test_Plan_Project_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsProject_Rabbit_Test_Plans_AggregateArgs = {
  distinct_on?: Maybe<Array<Rabbit_Test_Plan_Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rabbit_Test_Plan_Project_Order_By>>;
  where?: Maybe<Rabbit_Test_Plan_Project_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsProject_SequencesArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Project_Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Project_Sequence_Order_By>>;
  where?: Maybe<Integration_Test_Project_Sequence_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsProject_Sequences_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Project_Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Project_Sequence_Order_By>>;
  where?: Maybe<Integration_Test_Project_Sequence_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsProject_TasksArgs = {
  distinct_on?: Maybe<Array<Project_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Tasks_Order_By>>;
  where?: Maybe<Project_Tasks_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsProject_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Tasks_Order_By>>;
  where?: Maybe<Project_Tasks_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsProject_UsersArgs = {
  distinct_on?: Maybe<Array<Project_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_User_Order_By>>;
  where?: Maybe<Project_User_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsProject_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_User_Order_By>>;
  where?: Maybe<Project_User_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsRabbit_Test_InstancesArgs = {
  distinct_on?: Maybe<Array<Project_Rabbit_Test_Instances_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Rabbit_Test_Instances_Order_By>>;
  where?: Maybe<Project_Rabbit_Test_Instances_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsRabbit_Test_Instances_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Rabbit_Test_Instances_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Rabbit_Test_Instances_Order_By>>;
  where?: Maybe<Project_Rabbit_Test_Instances_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsRecordingsArgs = {
  distinct_on?: Maybe<Array<Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recordings_Order_By>>;
  where?: Maybe<Recordings_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsRecordings_AggregateArgs = {
  distinct_on?: Maybe<Array<Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recordings_Order_By>>;
  where?: Maybe<Recordings_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsScoresArgs = {
  distinct_on?: Maybe<Array<Simulations_Scores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Simulations_Scores_Order_By>>;
  where?: Maybe<Simulations_Scores_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsUser_Recordings_By_Day_StatsArgs = {
  distinct_on?: Maybe<Array<User_Recordings_By_Day_Stats_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Recordings_By_Day_Stats_Order_By>>;
  where?: Maybe<User_Recordings_By_Day_Stats_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsUser_Recordings_By_Day_Stats_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Recordings_By_Day_Stats_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Recordings_By_Day_Stats_Order_By>>;
  where?: Maybe<User_Recordings_By_Day_Stats_Bool_Exp>;
};

/** aggregated selection of "projects" */
export type Projects_Aggregate = {
  __typename?: 'projects_aggregate';
  aggregate?: Maybe<Projects_Aggregate_Fields>;
  nodes: Array<Projects>;
};

/** aggregate fields of "projects" */
export type Projects_Aggregate_Fields = {
  __typename?: 'projects_aggregate_fields';
  avg?: Maybe<Projects_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Projects_Max_Fields>;
  min?: Maybe<Projects_Min_Fields>;
  stddev?: Maybe<Projects_Stddev_Fields>;
  stddev_pop?: Maybe<Projects_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Projects_Stddev_Samp_Fields>;
  sum?: Maybe<Projects_Sum_Fields>;
  var_pop?: Maybe<Projects_Var_Pop_Fields>;
  var_samp?: Maybe<Projects_Var_Samp_Fields>;
  variance?: Maybe<Projects_Variance_Fields>;
};


/** aggregate fields of "projects" */
export type Projects_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Projects_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "projects" */
export type Projects_Aggregate_Order_By = {
  avg?: Maybe<Projects_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Projects_Max_Order_By>;
  min?: Maybe<Projects_Min_Order_By>;
  stddev?: Maybe<Projects_Stddev_Order_By>;
  stddev_pop?: Maybe<Projects_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Projects_Stddev_Samp_Order_By>;
  sum?: Maybe<Projects_Sum_Order_By>;
  var_pop?: Maybe<Projects_Var_Pop_Order_By>;
  var_samp?: Maybe<Projects_Var_Samp_Order_By>;
  variance?: Maybe<Projects_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "projects" */
export type Projects_Arr_Rel_Insert_Input = {
  data: Array<Projects_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Projects_On_Conflict>;
};

/** aggregate avg on columns */
export type Projects_Avg_Fields = {
  __typename?: 'projects_avg_fields';
  /** The Azure `definitionId` that references the pipeline used to build engines for this project. */
  azure_build_ref?: Maybe<Scalars['Float']>;
  /** The Azure `definitionId` that references the release pipeline used to release engines for this project. */
  azure_release_ref?: Maybe<Scalars['Float']>;
  far_threshold?: Maybe<Scalars['Float']>;
  firmware_engine_release_ref?: Maybe<Scalars['Float']>;
  min_devices_per_task?: Maybe<Scalars['Float']>;
  near_threshold?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "projects" */
export type Projects_Avg_Order_By = {
  /** The Azure `definitionId` that references the pipeline used to build engines for this project. */
  azure_build_ref?: Maybe<Order_By>;
  /** The Azure `definitionId` that references the release pipeline used to release engines for this project. */
  azure_release_ref?: Maybe<Order_By>;
  far_threshold?: Maybe<Order_By>;
  firmware_engine_release_ref?: Maybe<Order_By>;
  min_devices_per_task?: Maybe<Order_By>;
  near_threshold?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "projects". All fields are combined with a logical 'AND'. */
export type Projects_Bool_Exp = {
  _and?: Maybe<Array<Projects_Bool_Exp>>;
  _not?: Maybe<Projects_Bool_Exp>;
  _or?: Maybe<Array<Projects_Bool_Exp>>;
  audio_measurements_project_tasks?: Maybe<Audio_Measurements_Project_Tasks_Bool_Exp>;
  azure_build_ref?: Maybe<Int_Comparison_Exp>;
  azure_release_ref?: Maybe<Int_Comparison_Exp>;
  calibration_opmode?: Maybe<Opmodes_Bool_Exp>;
  calibration_opmode_id?: Maybe<Uuid_Comparison_Exp>;
  completed_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer?: Maybe<Customers_Bool_Exp>;
  customer_id?: Maybe<Uuid_Comparison_Exp>;
  date_end?: Maybe<Timestamptz_Comparison_Exp>;
  date_start?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  deliverable?: Maybe<Deliverables_Bool_Exp>;
  deliverable_id?: Maybe<Uuid_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  device_projects?: Maybe<Device_Project_Bool_Exp>;
  dynamic_scaling?: Maybe<Dynamic_Scaling_Bool_Exp>;
  dynamic_scaling_id?: Maybe<Uuid_Comparison_Exp>;
  enable_arcadia_processing?: Maybe<Boolean_Comparison_Exp>;
  enable_greatness_processing?: Maybe<Boolean_Comparison_Exp>;
  enable_ground_truth_processing?: Maybe<Boolean_Comparison_Exp>;
  far_threshold?: Maybe<Int_Comparison_Exp>;
  firmware_engine_release_ref?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  min_devices_per_task?: Maybe<Int_Comparison_Exp>;
  mode_projects?: Maybe<Mode_Project_Bool_Exp>;
  model?: Maybe<Models_Bool_Exp>;
  model_id?: Maybe<Uuid_Comparison_Exp>;
  mongo_source_query?: Maybe<Json_Comparison_Exp>;
  near_threshold?: Maybe<Int_Comparison_Exp>;
  observances?: Maybe<Integration_Test_Observances_Bool_Exp>;
  opmode_projects?: Maybe<Opmode_Project_Bool_Exp>;
  project_combos?: Maybe<Project_Combo_Bool_Exp>;
  project_dataset?: Maybe<Project_Dataset_Bool_Exp>;
  project_hypertasks?: Maybe<Project_Hypertasks_Bool_Exp>;
  project_name?: Maybe<Project_Name_Bool_Exp>;
  project_rabbit_test_plans?: Maybe<Rabbit_Test_Plan_Project_Bool_Exp>;
  project_sequences?: Maybe<Integration_Test_Project_Sequence_Bool_Exp>;
  project_tasks?: Maybe<Project_Tasks_Bool_Exp>;
  project_users?: Maybe<Project_User_Bool_Exp>;
  rabbit_test_instances?: Maybe<Project_Rabbit_Test_Instances_Bool_Exp>;
  recordings?: Maybe<Recordings_Bool_Exp>;
  scores?: Maybe<Simulations_Scores_Bool_Exp>;
  simulation_opmode?: Maybe<Opmodes_Bool_Exp>;
  simulation_opmode_id?: Maybe<Uuid_Comparison_Exp>;
  slack_channel?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_recordings_by_day_stats?: Maybe<User_Recordings_By_Day_Stats_Bool_Exp>;
  variant?: Maybe<String_Comparison_Exp>;
  xtensa_configuration?: Maybe<Xtensa_Configurations_Bool_Exp>;
  xtensa_configuration_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "projects" */
export enum Projects_Constraint {
  /** unique or primary key constraint on columns "variant", "model_id", "customer_id" */
  ProjectsCustomerIdModelIdVariantKey = 'projects_customer_id_model_id_variant_key',
  /** unique or primary key constraint on columns "id" */
  ProjectsPkey = 'projects_pkey',
  /** unique or primary key constraint on columns "simulation_opmode_id" */
  ProjectsSimulationOpmodeIdKey = 'projects_simulation_opmode_id_key'
}

/** input type for incrementing numeric columns in table "projects" */
export type Projects_Inc_Input = {
  /** The Azure `definitionId` that references the pipeline used to build engines for this project. */
  azure_build_ref?: Maybe<Scalars['Int']>;
  /** The Azure `definitionId` that references the release pipeline used to release engines for this project. */
  azure_release_ref?: Maybe<Scalars['Int']>;
  far_threshold?: Maybe<Scalars['Int']>;
  firmware_engine_release_ref?: Maybe<Scalars['Int']>;
  min_devices_per_task?: Maybe<Scalars['Int']>;
  near_threshold?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "projects" */
export type Projects_Insert_Input = {
  audio_measurements_project_tasks?: Maybe<Audio_Measurements_Project_Tasks_Arr_Rel_Insert_Input>;
  /** The Azure `definitionId` that references the pipeline used to build engines for this project. */
  azure_build_ref?: Maybe<Scalars['Int']>;
  /** The Azure `definitionId` that references the release pipeline used to release engines for this project. */
  azure_release_ref?: Maybe<Scalars['Int']>;
  calibration_opmode?: Maybe<Opmodes_Obj_Rel_Insert_Input>;
  calibration_opmode_id?: Maybe<Scalars['uuid']>;
  customer?: Maybe<Customers_Obj_Rel_Insert_Input>;
  customer_id?: Maybe<Scalars['uuid']>;
  date_end?: Maybe<Scalars['timestamptz']>;
  date_start?: Maybe<Scalars['timestamptz']>;
  deliverable?: Maybe<Deliverables_Obj_Rel_Insert_Input>;
  deliverable_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  device_projects?: Maybe<Device_Project_Arr_Rel_Insert_Input>;
  dynamic_scaling?: Maybe<Dynamic_Scaling_Obj_Rel_Insert_Input>;
  dynamic_scaling_id?: Maybe<Scalars['uuid']>;
  enable_arcadia_processing?: Maybe<Scalars['Boolean']>;
  enable_greatness_processing?: Maybe<Scalars['Boolean']>;
  enable_ground_truth_processing?: Maybe<Scalars['Boolean']>;
  far_threshold?: Maybe<Scalars['Int']>;
  firmware_engine_release_ref?: Maybe<Scalars['Int']>;
  min_devices_per_task?: Maybe<Scalars['Int']>;
  mode_projects?: Maybe<Mode_Project_Arr_Rel_Insert_Input>;
  model?: Maybe<Models_Obj_Rel_Insert_Input>;
  model_id?: Maybe<Scalars['uuid']>;
  mongo_source_query?: Maybe<Scalars['json']>;
  near_threshold?: Maybe<Scalars['Int']>;
  observances?: Maybe<Integration_Test_Observances_Arr_Rel_Insert_Input>;
  opmode_projects?: Maybe<Opmode_Project_Arr_Rel_Insert_Input>;
  project_combos?: Maybe<Project_Combo_Arr_Rel_Insert_Input>;
  project_hypertasks?: Maybe<Project_Hypertasks_Arr_Rel_Insert_Input>;
  project_rabbit_test_plans?: Maybe<Rabbit_Test_Plan_Project_Arr_Rel_Insert_Input>;
  project_sequences?: Maybe<Integration_Test_Project_Sequence_Arr_Rel_Insert_Input>;
  project_tasks?: Maybe<Project_Tasks_Arr_Rel_Insert_Input>;
  project_users?: Maybe<Project_User_Arr_Rel_Insert_Input>;
  recordings?: Maybe<Recordings_Arr_Rel_Insert_Input>;
  scores?: Maybe<Simulations_Scores_Arr_Rel_Insert_Input>;
  simulation_opmode?: Maybe<Opmodes_Obj_Rel_Insert_Input>;
  simulation_opmode_id?: Maybe<Scalars['uuid']>;
  slack_channel?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  xtensa_configuration?: Maybe<Xtensa_Configurations_Obj_Rel_Insert_Input>;
  xtensa_configuration_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Projects_Max_Fields = {
  __typename?: 'projects_max_fields';
  /** The Azure `definitionId` that references the pipeline used to build engines for this project. */
  azure_build_ref?: Maybe<Scalars['Int']>;
  /** The Azure `definitionId` that references the release pipeline used to release engines for this project. */
  azure_release_ref?: Maybe<Scalars['Int']>;
  calibration_opmode_id?: Maybe<Scalars['uuid']>;
  completed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['uuid']>;
  date_end?: Maybe<Scalars['timestamptz']>;
  date_start?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deliverable_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  dynamic_scaling_id?: Maybe<Scalars['uuid']>;
  far_threshold?: Maybe<Scalars['Int']>;
  firmware_engine_release_ref?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  min_devices_per_task?: Maybe<Scalars['Int']>;
  model_id?: Maybe<Scalars['uuid']>;
  near_threshold?: Maybe<Scalars['Int']>;
  simulation_opmode_id?: Maybe<Scalars['uuid']>;
  slack_channel?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  variant?: Maybe<Scalars['String']>;
  xtensa_configuration_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "projects" */
export type Projects_Max_Order_By = {
  /** The Azure `definitionId` that references the pipeline used to build engines for this project. */
  azure_build_ref?: Maybe<Order_By>;
  /** The Azure `definitionId` that references the release pipeline used to release engines for this project. */
  azure_release_ref?: Maybe<Order_By>;
  calibration_opmode_id?: Maybe<Order_By>;
  completed_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  date_end?: Maybe<Order_By>;
  date_start?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  deliverable_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  dynamic_scaling_id?: Maybe<Order_By>;
  far_threshold?: Maybe<Order_By>;
  firmware_engine_release_ref?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  min_devices_per_task?: Maybe<Order_By>;
  model_id?: Maybe<Order_By>;
  near_threshold?: Maybe<Order_By>;
  simulation_opmode_id?: Maybe<Order_By>;
  slack_channel?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  variant?: Maybe<Order_By>;
  xtensa_configuration_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Projects_Min_Fields = {
  __typename?: 'projects_min_fields';
  /** The Azure `definitionId` that references the pipeline used to build engines for this project. */
  azure_build_ref?: Maybe<Scalars['Int']>;
  /** The Azure `definitionId` that references the release pipeline used to release engines for this project. */
  azure_release_ref?: Maybe<Scalars['Int']>;
  calibration_opmode_id?: Maybe<Scalars['uuid']>;
  completed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['uuid']>;
  date_end?: Maybe<Scalars['timestamptz']>;
  date_start?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deliverable_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  dynamic_scaling_id?: Maybe<Scalars['uuid']>;
  far_threshold?: Maybe<Scalars['Int']>;
  firmware_engine_release_ref?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  min_devices_per_task?: Maybe<Scalars['Int']>;
  model_id?: Maybe<Scalars['uuid']>;
  near_threshold?: Maybe<Scalars['Int']>;
  simulation_opmode_id?: Maybe<Scalars['uuid']>;
  slack_channel?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  variant?: Maybe<Scalars['String']>;
  xtensa_configuration_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "projects" */
export type Projects_Min_Order_By = {
  /** The Azure `definitionId` that references the pipeline used to build engines for this project. */
  azure_build_ref?: Maybe<Order_By>;
  /** The Azure `definitionId` that references the release pipeline used to release engines for this project. */
  azure_release_ref?: Maybe<Order_By>;
  calibration_opmode_id?: Maybe<Order_By>;
  completed_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  date_end?: Maybe<Order_By>;
  date_start?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  deliverable_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  dynamic_scaling_id?: Maybe<Order_By>;
  far_threshold?: Maybe<Order_By>;
  firmware_engine_release_ref?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  min_devices_per_task?: Maybe<Order_By>;
  model_id?: Maybe<Order_By>;
  near_threshold?: Maybe<Order_By>;
  simulation_opmode_id?: Maybe<Order_By>;
  slack_channel?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  variant?: Maybe<Order_By>;
  xtensa_configuration_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "projects" */
export type Projects_Mutation_Response = {
  __typename?: 'projects_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Projects>;
};

/** input type for inserting object relation for remote table "projects" */
export type Projects_Obj_Rel_Insert_Input = {
  data: Projects_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Projects_On_Conflict>;
};

/** on_conflict condition type for table "projects" */
export type Projects_On_Conflict = {
  constraint: Projects_Constraint;
  update_columns?: Array<Projects_Update_Column>;
  where?: Maybe<Projects_Bool_Exp>;
};

/** Ordering options when selecting data from "projects". */
export type Projects_Order_By = {
  audio_measurements_project_tasks_aggregate?: Maybe<Audio_Measurements_Project_Tasks_Aggregate_Order_By>;
  azure_build_ref?: Maybe<Order_By>;
  azure_release_ref?: Maybe<Order_By>;
  calibration_opmode?: Maybe<Opmodes_Order_By>;
  calibration_opmode_id?: Maybe<Order_By>;
  completed_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer?: Maybe<Customers_Order_By>;
  customer_id?: Maybe<Order_By>;
  date_end?: Maybe<Order_By>;
  date_start?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  deliverable?: Maybe<Deliverables_Order_By>;
  deliverable_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  device_projects_aggregate?: Maybe<Device_Project_Aggregate_Order_By>;
  dynamic_scaling?: Maybe<Dynamic_Scaling_Order_By>;
  dynamic_scaling_id?: Maybe<Order_By>;
  enable_arcadia_processing?: Maybe<Order_By>;
  enable_greatness_processing?: Maybe<Order_By>;
  enable_ground_truth_processing?: Maybe<Order_By>;
  far_threshold?: Maybe<Order_By>;
  firmware_engine_release_ref?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  min_devices_per_task?: Maybe<Order_By>;
  mode_projects_aggregate?: Maybe<Mode_Project_Aggregate_Order_By>;
  model?: Maybe<Models_Order_By>;
  model_id?: Maybe<Order_By>;
  mongo_source_query?: Maybe<Order_By>;
  near_threshold?: Maybe<Order_By>;
  observances_aggregate?: Maybe<Integration_Test_Observances_Aggregate_Order_By>;
  opmode_projects_aggregate?: Maybe<Opmode_Project_Aggregate_Order_By>;
  project_combos_aggregate?: Maybe<Project_Combo_Aggregate_Order_By>;
  project_dataset_aggregate?: Maybe<Project_Dataset_Aggregate_Order_By>;
  project_hypertasks_aggregate?: Maybe<Project_Hypertasks_Aggregate_Order_By>;
  project_name?: Maybe<Project_Name_Order_By>;
  project_rabbit_test_plans_aggregate?: Maybe<Rabbit_Test_Plan_Project_Aggregate_Order_By>;
  project_sequences_aggregate?: Maybe<Integration_Test_Project_Sequence_Aggregate_Order_By>;
  project_tasks_aggregate?: Maybe<Project_Tasks_Aggregate_Order_By>;
  project_users_aggregate?: Maybe<Project_User_Aggregate_Order_By>;
  rabbit_test_instances_aggregate?: Maybe<Project_Rabbit_Test_Instances_Aggregate_Order_By>;
  recordings_aggregate?: Maybe<Recordings_Aggregate_Order_By>;
  scores_aggregate?: Maybe<Simulations_Scores_Aggregate_Order_By>;
  simulation_opmode?: Maybe<Opmodes_Order_By>;
  simulation_opmode_id?: Maybe<Order_By>;
  slack_channel?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_recordings_by_day_stats_aggregate?: Maybe<User_Recordings_By_Day_Stats_Aggregate_Order_By>;
  variant?: Maybe<Order_By>;
  xtensa_configuration?: Maybe<Xtensa_Configurations_Order_By>;
  xtensa_configuration_id?: Maybe<Order_By>;
};

/** primary key columns input for table: projects */
export type Projects_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "projects" */
export enum Projects_Select_Column {
  /** column name */
  AzureBuildRef = 'azure_build_ref',
  /** column name */
  AzureReleaseRef = 'azure_release_ref',
  /** column name */
  CalibrationOpmodeId = 'calibration_opmode_id',
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  DateEnd = 'date_end',
  /** column name */
  DateStart = 'date_start',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeliverableId = 'deliverable_id',
  /** column name */
  Description = 'description',
  /** column name */
  DynamicScalingId = 'dynamic_scaling_id',
  /** column name */
  EnableArcadiaProcessing = 'enable_arcadia_processing',
  /** column name */
  EnableGreatnessProcessing = 'enable_greatness_processing',
  /** column name */
  EnableGroundTruthProcessing = 'enable_ground_truth_processing',
  /** column name */
  FarThreshold = 'far_threshold',
  /** column name */
  FirmwareEngineReleaseRef = 'firmware_engine_release_ref',
  /** column name */
  Id = 'id',
  /** column name */
  MinDevicesPerTask = 'min_devices_per_task',
  /** column name */
  ModelId = 'model_id',
  /** column name */
  MongoSourceQuery = 'mongo_source_query',
  /** column name */
  NearThreshold = 'near_threshold',
  /** column name */
  SimulationOpmodeId = 'simulation_opmode_id',
  /** column name */
  SlackChannel = 'slack_channel',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Variant = 'variant',
  /** column name */
  XtensaConfigurationId = 'xtensa_configuration_id'
}

/** input type for updating data in table "projects" */
export type Projects_Set_Input = {
  /** The Azure `definitionId` that references the pipeline used to build engines for this project. */
  azure_build_ref?: Maybe<Scalars['Int']>;
  /** The Azure `definitionId` that references the release pipeline used to release engines for this project. */
  azure_release_ref?: Maybe<Scalars['Int']>;
  calibration_opmode_id?: Maybe<Scalars['uuid']>;
  completed_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['uuid']>;
  date_end?: Maybe<Scalars['timestamptz']>;
  date_start?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deliverable_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  dynamic_scaling_id?: Maybe<Scalars['uuid']>;
  enable_arcadia_processing?: Maybe<Scalars['Boolean']>;
  enable_greatness_processing?: Maybe<Scalars['Boolean']>;
  enable_ground_truth_processing?: Maybe<Scalars['Boolean']>;
  far_threshold?: Maybe<Scalars['Int']>;
  firmware_engine_release_ref?: Maybe<Scalars['Int']>;
  min_devices_per_task?: Maybe<Scalars['Int']>;
  model_id?: Maybe<Scalars['uuid']>;
  mongo_source_query?: Maybe<Scalars['json']>;
  near_threshold?: Maybe<Scalars['Int']>;
  simulation_opmode_id?: Maybe<Scalars['uuid']>;
  slack_channel?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  xtensa_configuration_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Projects_Stddev_Fields = {
  __typename?: 'projects_stddev_fields';
  /** The Azure `definitionId` that references the pipeline used to build engines for this project. */
  azure_build_ref?: Maybe<Scalars['Float']>;
  /** The Azure `definitionId` that references the release pipeline used to release engines for this project. */
  azure_release_ref?: Maybe<Scalars['Float']>;
  far_threshold?: Maybe<Scalars['Float']>;
  firmware_engine_release_ref?: Maybe<Scalars['Float']>;
  min_devices_per_task?: Maybe<Scalars['Float']>;
  near_threshold?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "projects" */
export type Projects_Stddev_Order_By = {
  /** The Azure `definitionId` that references the pipeline used to build engines for this project. */
  azure_build_ref?: Maybe<Order_By>;
  /** The Azure `definitionId` that references the release pipeline used to release engines for this project. */
  azure_release_ref?: Maybe<Order_By>;
  far_threshold?: Maybe<Order_By>;
  firmware_engine_release_ref?: Maybe<Order_By>;
  min_devices_per_task?: Maybe<Order_By>;
  near_threshold?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Projects_Stddev_Pop_Fields = {
  __typename?: 'projects_stddev_pop_fields';
  /** The Azure `definitionId` that references the pipeline used to build engines for this project. */
  azure_build_ref?: Maybe<Scalars['Float']>;
  /** The Azure `definitionId` that references the release pipeline used to release engines for this project. */
  azure_release_ref?: Maybe<Scalars['Float']>;
  far_threshold?: Maybe<Scalars['Float']>;
  firmware_engine_release_ref?: Maybe<Scalars['Float']>;
  min_devices_per_task?: Maybe<Scalars['Float']>;
  near_threshold?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "projects" */
export type Projects_Stddev_Pop_Order_By = {
  /** The Azure `definitionId` that references the pipeline used to build engines for this project. */
  azure_build_ref?: Maybe<Order_By>;
  /** The Azure `definitionId` that references the release pipeline used to release engines for this project. */
  azure_release_ref?: Maybe<Order_By>;
  far_threshold?: Maybe<Order_By>;
  firmware_engine_release_ref?: Maybe<Order_By>;
  min_devices_per_task?: Maybe<Order_By>;
  near_threshold?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Projects_Stddev_Samp_Fields = {
  __typename?: 'projects_stddev_samp_fields';
  /** The Azure `definitionId` that references the pipeline used to build engines for this project. */
  azure_build_ref?: Maybe<Scalars['Float']>;
  /** The Azure `definitionId` that references the release pipeline used to release engines for this project. */
  azure_release_ref?: Maybe<Scalars['Float']>;
  far_threshold?: Maybe<Scalars['Float']>;
  firmware_engine_release_ref?: Maybe<Scalars['Float']>;
  min_devices_per_task?: Maybe<Scalars['Float']>;
  near_threshold?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "projects" */
export type Projects_Stddev_Samp_Order_By = {
  /** The Azure `definitionId` that references the pipeline used to build engines for this project. */
  azure_build_ref?: Maybe<Order_By>;
  /** The Azure `definitionId` that references the release pipeline used to release engines for this project. */
  azure_release_ref?: Maybe<Order_By>;
  far_threshold?: Maybe<Order_By>;
  firmware_engine_release_ref?: Maybe<Order_By>;
  min_devices_per_task?: Maybe<Order_By>;
  near_threshold?: Maybe<Order_By>;
};

/** Streaming cursor of the table "projects" */
export type Projects_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Projects_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Projects_Stream_Cursor_Value_Input = {
  /** The Azure `definitionId` that references the pipeline used to build engines for this project. */
  azure_build_ref?: Maybe<Scalars['Int']>;
  /** The Azure `definitionId` that references the release pipeline used to release engines for this project. */
  azure_release_ref?: Maybe<Scalars['Int']>;
  calibration_opmode_id?: Maybe<Scalars['uuid']>;
  completed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['uuid']>;
  date_end?: Maybe<Scalars['timestamptz']>;
  date_start?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deliverable_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  dynamic_scaling_id?: Maybe<Scalars['uuid']>;
  enable_arcadia_processing?: Maybe<Scalars['Boolean']>;
  enable_greatness_processing?: Maybe<Scalars['Boolean']>;
  enable_ground_truth_processing?: Maybe<Scalars['Boolean']>;
  far_threshold?: Maybe<Scalars['Int']>;
  firmware_engine_release_ref?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  min_devices_per_task?: Maybe<Scalars['Int']>;
  model_id?: Maybe<Scalars['uuid']>;
  mongo_source_query?: Maybe<Scalars['json']>;
  near_threshold?: Maybe<Scalars['Int']>;
  simulation_opmode_id?: Maybe<Scalars['uuid']>;
  slack_channel?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  variant?: Maybe<Scalars['String']>;
  xtensa_configuration_id?: Maybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Projects_Sum_Fields = {
  __typename?: 'projects_sum_fields';
  /** The Azure `definitionId` that references the pipeline used to build engines for this project. */
  azure_build_ref?: Maybe<Scalars['Int']>;
  /** The Azure `definitionId` that references the release pipeline used to release engines for this project. */
  azure_release_ref?: Maybe<Scalars['Int']>;
  far_threshold?: Maybe<Scalars['Int']>;
  firmware_engine_release_ref?: Maybe<Scalars['Int']>;
  min_devices_per_task?: Maybe<Scalars['Int']>;
  near_threshold?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "projects" */
export type Projects_Sum_Order_By = {
  /** The Azure `definitionId` that references the pipeline used to build engines for this project. */
  azure_build_ref?: Maybe<Order_By>;
  /** The Azure `definitionId` that references the release pipeline used to release engines for this project. */
  azure_release_ref?: Maybe<Order_By>;
  far_threshold?: Maybe<Order_By>;
  firmware_engine_release_ref?: Maybe<Order_By>;
  min_devices_per_task?: Maybe<Order_By>;
  near_threshold?: Maybe<Order_By>;
};

/** update columns of table "projects" */
export enum Projects_Update_Column {
  /** column name */
  AzureBuildRef = 'azure_build_ref',
  /** column name */
  AzureReleaseRef = 'azure_release_ref',
  /** column name */
  CalibrationOpmodeId = 'calibration_opmode_id',
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  DateEnd = 'date_end',
  /** column name */
  DateStart = 'date_start',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeliverableId = 'deliverable_id',
  /** column name */
  Description = 'description',
  /** column name */
  DynamicScalingId = 'dynamic_scaling_id',
  /** column name */
  EnableArcadiaProcessing = 'enable_arcadia_processing',
  /** column name */
  EnableGreatnessProcessing = 'enable_greatness_processing',
  /** column name */
  EnableGroundTruthProcessing = 'enable_ground_truth_processing',
  /** column name */
  FarThreshold = 'far_threshold',
  /** column name */
  FirmwareEngineReleaseRef = 'firmware_engine_release_ref',
  /** column name */
  MinDevicesPerTask = 'min_devices_per_task',
  /** column name */
  ModelId = 'model_id',
  /** column name */
  MongoSourceQuery = 'mongo_source_query',
  /** column name */
  NearThreshold = 'near_threshold',
  /** column name */
  SimulationOpmodeId = 'simulation_opmode_id',
  /** column name */
  SlackChannel = 'slack_channel',
  /** column name */
  Variant = 'variant',
  /** column name */
  XtensaConfigurationId = 'xtensa_configuration_id'
}

export type Projects_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Projects_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Projects_Set_Input>;
  where: Projects_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Projects_Var_Pop_Fields = {
  __typename?: 'projects_var_pop_fields';
  /** The Azure `definitionId` that references the pipeline used to build engines for this project. */
  azure_build_ref?: Maybe<Scalars['Float']>;
  /** The Azure `definitionId` that references the release pipeline used to release engines for this project. */
  azure_release_ref?: Maybe<Scalars['Float']>;
  far_threshold?: Maybe<Scalars['Float']>;
  firmware_engine_release_ref?: Maybe<Scalars['Float']>;
  min_devices_per_task?: Maybe<Scalars['Float']>;
  near_threshold?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "projects" */
export type Projects_Var_Pop_Order_By = {
  /** The Azure `definitionId` that references the pipeline used to build engines for this project. */
  azure_build_ref?: Maybe<Order_By>;
  /** The Azure `definitionId` that references the release pipeline used to release engines for this project. */
  azure_release_ref?: Maybe<Order_By>;
  far_threshold?: Maybe<Order_By>;
  firmware_engine_release_ref?: Maybe<Order_By>;
  min_devices_per_task?: Maybe<Order_By>;
  near_threshold?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Projects_Var_Samp_Fields = {
  __typename?: 'projects_var_samp_fields';
  /** The Azure `definitionId` that references the pipeline used to build engines for this project. */
  azure_build_ref?: Maybe<Scalars['Float']>;
  /** The Azure `definitionId` that references the release pipeline used to release engines for this project. */
  azure_release_ref?: Maybe<Scalars['Float']>;
  far_threshold?: Maybe<Scalars['Float']>;
  firmware_engine_release_ref?: Maybe<Scalars['Float']>;
  min_devices_per_task?: Maybe<Scalars['Float']>;
  near_threshold?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "projects" */
export type Projects_Var_Samp_Order_By = {
  /** The Azure `definitionId` that references the pipeline used to build engines for this project. */
  azure_build_ref?: Maybe<Order_By>;
  /** The Azure `definitionId` that references the release pipeline used to release engines for this project. */
  azure_release_ref?: Maybe<Order_By>;
  far_threshold?: Maybe<Order_By>;
  firmware_engine_release_ref?: Maybe<Order_By>;
  min_devices_per_task?: Maybe<Order_By>;
  near_threshold?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Projects_Variance_Fields = {
  __typename?: 'projects_variance_fields';
  /** The Azure `definitionId` that references the pipeline used to build engines for this project. */
  azure_build_ref?: Maybe<Scalars['Float']>;
  /** The Azure `definitionId` that references the release pipeline used to release engines for this project. */
  azure_release_ref?: Maybe<Scalars['Float']>;
  far_threshold?: Maybe<Scalars['Float']>;
  firmware_engine_release_ref?: Maybe<Scalars['Float']>;
  min_devices_per_task?: Maybe<Scalars['Float']>;
  near_threshold?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "projects" */
export type Projects_Variance_Order_By = {
  /** The Azure `definitionId` that references the pipeline used to build engines for this project. */
  azure_build_ref?: Maybe<Order_By>;
  /** The Azure `definitionId` that references the release pipeline used to release engines for this project. */
  azure_release_ref?: Maybe<Order_By>;
  far_threshold?: Maybe<Order_By>;
  firmware_engine_release_ref?: Maybe<Order_By>;
  min_devices_per_task?: Maybe<Order_By>;
  near_threshold?: Maybe<Order_By>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "audio_driver" */
  audio_driver: Array<Audio_Driver>;
  /** fetch data from the table: "audio_driver" using primary key columns */
  audio_driver_by_pk?: Maybe<Audio_Driver>;
  /** fetch data from the table: "audio_measurements.components" */
  audio_measurements_components: Array<Audio_Measurements_Components>;
  /** fetch data from the table: "audio_measurements.components" using primary key columns */
  audio_measurements_components_by_pk?: Maybe<Audio_Measurements_Components>;
  /** fetch data from the table: "audio_measurements.configurations" */
  audio_measurements_configurations: Array<Audio_Measurements_Configurations>;
  /** fetch data from the table: "audio_measurements.configurations" using primary key columns */
  audio_measurements_configurations_by_pk?: Maybe<Audio_Measurements_Configurations>;
  /** fetch data from the table: "audio_measurements.data_types" */
  audio_measurements_data_types: Array<Audio_Measurements_Data_Types>;
  /** fetch aggregated fields from the table: "audio_measurements.data_types" */
  audio_measurements_data_types_aggregate: Audio_Measurements_Data_Types_Aggregate;
  /** fetch data from the table: "audio_measurements.data_types" using primary key columns */
  audio_measurements_data_types_by_pk?: Maybe<Audio_Measurements_Data_Types>;
  /** fetch data from the table: "audio_measurements.master_files" */
  audio_measurements_master_files: Array<Audio_Measurements_Master_Files>;
  /** fetch aggregated fields from the table: "audio_measurements.master_files" */
  audio_measurements_master_files_aggregate: Audio_Measurements_Master_Files_Aggregate;
  /** fetch data from the table: "audio_measurements.master_files" using primary key columns */
  audio_measurements_master_files_by_pk?: Maybe<Audio_Measurements_Master_Files>;
  /** fetch data from the table: "audio_measurements.orientations" */
  audio_measurements_orientations: Array<Audio_Measurements_Orientations>;
  /** fetch data from the table: "audio_measurements.orientations" using primary key columns */
  audio_measurements_orientations_by_pk?: Maybe<Audio_Measurements_Orientations>;
  /** fetch data from the table: "audio_measurements.payload_recording" */
  audio_measurements_payload_recording: Array<Audio_Measurements_Payload_Recording>;
  /** fetch aggregated fields from the table: "audio_measurements.payload_recording" */
  audio_measurements_payload_recording_aggregate: Audio_Measurements_Payload_Recording_Aggregate;
  /** fetch data from the table: "audio_measurements.payload_recording" using primary key columns */
  audio_measurements_payload_recording_by_pk?: Maybe<Audio_Measurements_Payload_Recording>;
  /** fetch data from the table: "audio_measurements.placements" */
  audio_measurements_placements: Array<Audio_Measurements_Placements>;
  /** fetch data from the table: "audio_measurements.placements" using primary key columns */
  audio_measurements_placements_by_pk?: Maybe<Audio_Measurements_Placements>;
  /** An array relationship */
  audio_measurements_project_tasks: Array<Audio_Measurements_Project_Tasks>;
  /** An aggregate relationship */
  audio_measurements_project_tasks_aggregate: Audio_Measurements_Project_Tasks_Aggregate;
  /** fetch data from the table: "audio_measurements.project_tasks" using primary key columns */
  audio_measurements_project_tasks_by_pk?: Maybe<Audio_Measurements_Project_Tasks>;
  /** fetch data from the table: "audio_measurements.recordings" */
  audio_measurements_recordings: Array<Audio_Measurements_Recordings>;
  /** fetch aggregated fields from the table: "audio_measurements.recordings" */
  audio_measurements_recordings_aggregate: Audio_Measurements_Recordings_Aggregate;
  /** fetch data from the table: "audio_measurements.recordings" using primary key columns */
  audio_measurements_recordings_by_pk?: Maybe<Audio_Measurements_Recordings>;
  /** fetch data from the table: "audio_measurements.stages" */
  audio_measurements_stages: Array<Audio_Measurements_Stages>;
  /** fetch data from the table: "audio_measurements.stages" using primary key columns */
  audio_measurements_stages_by_pk?: Maybe<Audio_Measurements_Stages>;
  /** fetch data from the table: "bringup.checklist_deliverable" */
  bringup_checklist_deliverable: Array<Bringup_Checklist_Deliverable>;
  /** fetch aggregated fields from the table: "bringup.checklist_deliverable" */
  bringup_checklist_deliverable_aggregate: Bringup_Checklist_Deliverable_Aggregate;
  /** fetch data from the table: "bringup.checklist_deliverable" using primary key columns */
  bringup_checklist_deliverable_by_pk?: Maybe<Bringup_Checklist_Deliverable>;
  /** fetch data from the table: "bringup.checklists" */
  bringup_checklists: Array<Bringup_Checklists>;
  /** fetch aggregated fields from the table: "bringup.checklists" */
  bringup_checklists_aggregate: Bringup_Checklists_Aggregate;
  /** fetch data from the table: "bringup.checklists" using primary key columns */
  bringup_checklists_by_pk?: Maybe<Bringup_Checklists>;
  /** fetch data from the table: "bringup.endorsements" */
  bringup_endorsements: Array<Bringup_Endorsements>;
  /** fetch aggregated fields from the table: "bringup.endorsements" */
  bringup_endorsements_aggregate: Bringup_Endorsements_Aggregate;
  /** fetch data from the table: "bringup.endorsements" using primary key columns */
  bringup_endorsements_by_pk?: Maybe<Bringup_Endorsements>;
  /** fetch data from the table: "bringup.items" */
  bringup_items: Array<Bringup_Items>;
  /** fetch aggregated fields from the table: "bringup.items" */
  bringup_items_aggregate: Bringup_Items_Aggregate;
  /** fetch data from the table: "bringup.items" using primary key columns */
  bringup_items_by_pk?: Maybe<Bringup_Items>;
  /** fetch data from the table: "bringup.milestones" */
  bringup_milestones: Array<Bringup_Milestones>;
  /** fetch aggregated fields from the table: "bringup.milestones" */
  bringup_milestones_aggregate: Bringup_Milestones_Aggregate;
  /** fetch data from the table: "bringup.milestones" using primary key columns */
  bringup_milestones_by_pk?: Maybe<Bringup_Milestones>;
  /** fetch data from the table: "bringup.project_item_comments" */
  bringup_project_item_comments: Array<Bringup_Project_Item_Comments>;
  /** fetch aggregated fields from the table: "bringup.project_item_comments" */
  bringup_project_item_comments_aggregate: Bringup_Project_Item_Comments_Aggregate;
  /** fetch data from the table: "bringup.project_item_comments" using primary key columns */
  bringup_project_item_comments_by_pk?: Maybe<Bringup_Project_Item_Comments>;
  /** fetch data from the table: "categories" */
  categories: Array<Categories>;
  /** fetch aggregated fields from the table: "categories" */
  categories_aggregate: Categories_Aggregate;
  /** fetch data from the table: "categories" using primary key columns */
  categories_by_pk?: Maybe<Categories>;
  /** fetch data from the table: "context_display_field" */
  context_display_field: Array<Context_Display_Field>;
  /** fetch aggregated fields from the table: "context_display_field" */
  context_display_field_aggregate: Context_Display_Field_Aggregate;
  /** fetch data from the table: "context_display_field" using primary key columns */
  context_display_field_by_pk?: Maybe<Context_Display_Field>;
  /** An array relationship */
  context_displays: Array<Context_Displays>;
  /** fetch data from the table: "context_displays" using primary key columns */
  context_displays_by_pk?: Maybe<Context_Displays>;
  /** An array relationship */
  contexts: Array<Contexts>;
  /** An aggregate relationship */
  contexts_aggregate: Contexts_Aggregate;
  /** fetch data from the table: "contexts" using primary key columns */
  contexts_by_pk?: Maybe<Contexts>;
  /** fetch data from the table: "customers" */
  customers: Array<Customers>;
  /** fetch aggregated fields from the table: "customers" */
  customers_aggregate: Customers_Aggregate;
  /** fetch data from the table: "customers" using primary key columns */
  customers_by_pk?: Maybe<Customers>;
  /** fetch data from the table: "deliverables" */
  deliverables: Array<Deliverables>;
  /** fetch aggregated fields from the table: "deliverables" */
  deliverables_aggregate: Deliverables_Aggregate;
  /** fetch data from the table: "deliverables" using primary key columns */
  deliverables_by_pk?: Maybe<Deliverables>;
  /** fetch data from the table: "deliveries.architectures" */
  deliveries_architectures: Array<Deliveries_Architectures>;
  /** fetch data from the table: "deliveries.architectures" using primary key columns */
  deliveries_architectures_by_pk?: Maybe<Deliveries_Architectures>;
  /** fetch data from the table: "deliveries.artifact_microsoft_whql_submission" */
  deliveries_artifact_microsoft_whql_submission: Array<Deliveries_Artifact_Microsoft_Whql_Submission>;
  /** fetch aggregated fields from the table: "deliveries.artifact_microsoft_whql_submission" */
  deliveries_artifact_microsoft_whql_submission_aggregate: Deliveries_Artifact_Microsoft_Whql_Submission_Aggregate;
  /** fetch data from the table: "deliveries.artifact_microsoft_whql_submission" using primary key columns */
  deliveries_artifact_microsoft_whql_submission_by_pk?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission>;
  /** fetch data from the table: "deliveries.artifact_operating_system" */
  deliveries_artifact_operating_system: Array<Deliveries_Artifact_Operating_System>;
  /** fetch data from the table: "deliveries.artifact_operating_system" using primary key columns */
  deliveries_artifact_operating_system_by_pk?: Maybe<Deliveries_Artifact_Operating_System>;
  /** fetch data from the table: "deliveries.artifacts" */
  deliveries_artifacts: Array<Deliveries_Artifacts>;
  /** fetch aggregated fields from the table: "deliveries.artifacts" */
  deliveries_artifacts_aggregate: Deliveries_Artifacts_Aggregate;
  /** fetch data from the table: "deliveries.artifacts" using primary key columns */
  deliveries_artifacts_by_pk?: Maybe<Deliveries_Artifacts>;
  /** fetch data from the table: "deliveries.device_families" */
  deliveries_device_families: Array<Deliveries_Device_Families>;
  /** fetch data from the table: "deliveries.device_families" using primary key columns */
  deliveries_device_families_by_pk?: Maybe<Deliveries_Device_Families>;
  /** fetch data from the table: "deliveries.driver_package" */
  deliveries_driver_package: Array<Deliveries_Driver_Package>;
  /** fetch aggregated fields from the table: "deliveries.driver_package" */
  deliveries_driver_package_aggregate: Deliveries_Driver_Package_Aggregate;
  /** fetch data from the table: "deliveries.driver_package" using primary key columns */
  deliveries_driver_package_by_pk?: Maybe<Deliveries_Driver_Package>;
  /** fetch data from the table: "deliveries.driver_version_package" */
  deliveries_driver_version_package: Array<Deliveries_Driver_Version_Package>;
  /** fetch aggregated fields from the table: "deliveries.driver_version_package" */
  deliveries_driver_version_package_aggregate: Deliveries_Driver_Version_Package_Aggregate;
  /** fetch data from the table: "deliveries.driver_version_package" using primary key columns */
  deliveries_driver_version_package_by_pk?: Maybe<Deliveries_Driver_Version_Package>;
  /** fetch data from the table: "deliveries.driver_versions" */
  deliveries_driver_versions: Array<Deliveries_Driver_Versions>;
  /** fetch aggregated fields from the table: "deliveries.driver_versions" */
  deliveries_driver_versions_aggregate: Deliveries_Driver_Versions_Aggregate;
  /** fetch data from the table: "deliveries.driver_versions" using primary key columns */
  deliveries_driver_versions_by_pk?: Maybe<Deliveries_Driver_Versions>;
  /** fetch data from the table: "deliveries.drivers" */
  deliveries_drivers: Array<Deliveries_Drivers>;
  /** fetch aggregated fields from the table: "deliveries.drivers" */
  deliveries_drivers_aggregate: Deliveries_Drivers_Aggregate;
  /** fetch data from the table: "deliveries.drivers" using primary key columns */
  deliveries_drivers_by_pk?: Maybe<Deliveries_Drivers>;
  /** fetch data from the table: "deliveries.microsoft_hlk_artifacts" */
  deliveries_microsoft_hlk_artifacts: Array<Deliveries_Microsoft_Hlk_Artifacts>;
  /** fetch aggregated fields from the table: "deliveries.microsoft_hlk_artifacts" */
  deliveries_microsoft_hlk_artifacts_aggregate: Deliveries_Microsoft_Hlk_Artifacts_Aggregate;
  /** fetch data from the table: "deliveries.microsoft_hlk_artifacts" using primary key columns */
  deliveries_microsoft_hlk_artifacts_by_pk?: Maybe<Deliveries_Microsoft_Hlk_Artifacts>;
  /** fetch data from the table: "deliveries.microsoft_shipping_label_reviews" */
  deliveries_microsoft_shipping_label_reviews: Array<Deliveries_Microsoft_Shipping_Label_Reviews>;
  /** fetch data from the table: "deliveries.microsoft_shipping_label_reviews" using primary key columns */
  deliveries_microsoft_shipping_label_reviews_by_pk?: Maybe<Deliveries_Microsoft_Shipping_Label_Reviews>;
  /** fetch data from the table: "deliveries.microsoft_shipping_labels" */
  deliveries_microsoft_shipping_labels: Array<Deliveries_Microsoft_Shipping_Labels>;
  /** fetch aggregated fields from the table: "deliveries.microsoft_shipping_labels" */
  deliveries_microsoft_shipping_labels_aggregate: Deliveries_Microsoft_Shipping_Labels_Aggregate;
  /** fetch data from the table: "deliveries.microsoft_shipping_labels" using primary key columns */
  deliveries_microsoft_shipping_labels_by_pk?: Maybe<Deliveries_Microsoft_Shipping_Labels>;
  /** fetch data from the table: "deliveries.microsoft_whql_submissions" */
  deliveries_microsoft_whql_submissions: Array<Deliveries_Microsoft_Whql_Submissions>;
  /** fetch aggregated fields from the table: "deliveries.microsoft_whql_submissions" */
  deliveries_microsoft_whql_submissions_aggregate: Deliveries_Microsoft_Whql_Submissions_Aggregate;
  /** fetch data from the table: "deliveries.microsoft_whql_submissions" using primary key columns */
  deliveries_microsoft_whql_submissions_by_pk?: Maybe<Deliveries_Microsoft_Whql_Submissions>;
  /** fetch data from the table: "deliveries.operating_systems" */
  deliveries_operating_systems: Array<Deliveries_Operating_Systems>;
  /** fetch data from the table: "deliveries.operating_systems" using primary key columns */
  deliveries_operating_systems_by_pk?: Maybe<Deliveries_Operating_Systems>;
  /** fetch data from the table: "deliveries.package_operating_systems" */
  deliveries_package_operating_systems: Array<Deliveries_Package_Operating_Systems>;
  /** fetch data from the table: "deliveries.package_operating_systems" using primary key columns */
  deliveries_package_operating_systems_by_pk?: Maybe<Deliveries_Package_Operating_Systems>;
  /** fetch data from the table: "deliveries.package_target" */
  deliveries_package_target: Array<Deliveries_Package_Target>;
  /** fetch aggregated fields from the table: "deliveries.package_target" */
  deliveries_package_target_aggregate: Deliveries_Package_Target_Aggregate;
  /** fetch data from the table: "deliveries.package_target" using primary key columns */
  deliveries_package_target_by_pk?: Maybe<Deliveries_Package_Target>;
  /** fetch data from the table: "deliveries.packages" */
  deliveries_packages: Array<Deliveries_Packages>;
  /** fetch aggregated fields from the table: "deliveries.packages" */
  deliveries_packages_aggregate: Deliveries_Packages_Aggregate;
  /** fetch data from the table: "deliveries.packages" using primary key columns */
  deliveries_packages_by_pk?: Maybe<Deliveries_Packages>;
  /** fetch data from the table: "deliveries.partners" */
  deliveries_partners: Array<Deliveries_Partners>;
  /** fetch data from the table: "deliveries.partners" using primary key columns */
  deliveries_partners_by_pk?: Maybe<Deliveries_Partners>;
  /** fetch data from the table: "deliveries.platforms" */
  deliveries_platforms: Array<Deliveries_Platforms>;
  /** fetch data from the table: "deliveries.platforms" using primary key columns */
  deliveries_platforms_by_pk?: Maybe<Deliveries_Platforms>;
  /** fetch data from the table: "deliveries.project_configs" */
  deliveries_project_configs: Array<Deliveries_Project_Configs>;
  /** fetch aggregated fields from the table: "deliveries.project_configs" */
  deliveries_project_configs_aggregate: Deliveries_Project_Configs_Aggregate;
  /** fetch data from the table: "deliveries.project_configs" using primary key columns */
  deliveries_project_configs_by_pk?: Maybe<Deliveries_Project_Configs>;
  /** fetch data from the table: "deliveries.target_partner" */
  deliveries_target_partner: Array<Deliveries_Target_Partner>;
  /** fetch data from the table: "deliveries.target_partner" using primary key columns */
  deliveries_target_partner_by_pk?: Maybe<Deliveries_Target_Partner>;
  /** fetch data from the table: "deliveries.targets" */
  deliveries_targets: Array<Deliveries_Targets>;
  /** fetch aggregated fields from the table: "deliveries.targets" */
  deliveries_targets_aggregate: Deliveries_Targets_Aggregate;
  /** fetch data from the table: "deliveries.targets" using primary key columns */
  deliveries_targets_by_pk?: Maybe<Deliveries_Targets>;
  /** fetch data from the table: "device_firmware" */
  device_firmware: Array<Device_Firmware>;
  /** fetch data from the table: "device_firmware" using primary key columns */
  device_firmware_by_pk?: Maybe<Device_Firmware>;
  /** fetch data from the table: "device_project" */
  device_project: Array<Device_Project>;
  /** fetch aggregated fields from the table: "device_project" */
  device_project_aggregate: Device_Project_Aggregate;
  /** fetch data from the table: "device_project" using primary key columns */
  device_project_by_pk?: Maybe<Device_Project>;
  /** An array relationship */
  devices: Array<Devices>;
  /** An aggregate relationship */
  devices_aggregate: Devices_Aggregate;
  /** fetch data from the table: "devices" using primary key columns */
  devices_by_pk?: Maybe<Devices>;
  /** fetch data from the table: "dynamic_scaling" */
  dynamic_scaling: Array<Dynamic_Scaling>;
  /** fetch data from the table: "dynamic_scaling" using primary key columns */
  dynamic_scaling_by_pk?: Maybe<Dynamic_Scaling>;
  /** elapi slack channels */
  elapi_slack_channels?: Maybe<Array<Maybe<ElapiSlackChannel>>>;
  /** fetch data from the table: "engine_opmode" */
  engine_opmode: Array<Engine_Opmode>;
  /** fetch aggregated fields from the table: "engine_opmode" */
  engine_opmode_aggregate: Engine_Opmode_Aggregate;
  /** fetch data from the table: "engine_opmode" using primary key columns */
  engine_opmode_by_pk?: Maybe<Engine_Opmode>;
  /** fetch data from the table: "engine_opmode_recording" */
  engine_opmode_recording: Array<Engine_Opmode_Recording>;
  /** fetch aggregated fields from the table: "engine_opmode_recording" */
  engine_opmode_recording_aggregate: Engine_Opmode_Recording_Aggregate;
  /** fetch data from the table: "engine_opmode_recording" using primary key columns */
  engine_opmode_recording_by_pk?: Maybe<Engine_Opmode_Recording>;
  /** fetch data from the table: "engine_types" */
  engine_types: Array<Engine_Types>;
  /** fetch aggregated fields from the table: "engine_types" */
  engine_types_aggregate: Engine_Types_Aggregate;
  /** fetch data from the table: "engine_types" using primary key columns */
  engine_types_by_pk?: Maybe<Engine_Types>;
  /** fetch data from the table: "engines" */
  engines: Array<Engines>;
  /** fetch aggregated fields from the table: "engines" */
  engines_aggregate: Engines_Aggregate;
  /** fetch data from the table: "engines" using primary key columns */
  engines_by_pk?: Maybe<Engines>;
  /** An array relationship */
  field_option_media: Array<Field_Option_Media>;
  /** An aggregate relationship */
  field_option_media_aggregate: Field_Option_Media_Aggregate;
  /** fetch data from the table: "field_option_media" using primary key columns */
  field_option_media_by_pk?: Maybe<Field_Option_Media>;
  /** An array relationship */
  field_options: Array<Field_Options>;
  /** An aggregate relationship */
  field_options_aggregate: Field_Options_Aggregate;
  /** fetch data from the table: "field_options" using primary key columns */
  field_options_by_pk?: Maybe<Field_Options>;
  /** fetch data from the table: "field_translation" */
  field_translation: Array<Field_Translation>;
  /** fetch aggregated fields from the table: "field_translation" */
  field_translation_aggregate: Field_Translation_Aggregate;
  /** fetch data from the table: "field_translation" using primary key columns */
  field_translation_by_pk?: Maybe<Field_Translation>;
  /** An array relationship */
  fields: Array<Fields>;
  /** An aggregate relationship */
  fields_aggregate: Fields_Aggregate;
  /** fetch data from the table: "fields" using primary key columns */
  fields_by_pk?: Maybe<Fields>;
  /** fetch data from the table: "firmware" */
  firmware: Array<Firmware>;
  /** fetch aggregated fields from the table: "firmware" */
  firmware_aggregate: Firmware_Aggregate;
  /** fetch data from the table: "firmware" using primary key columns */
  firmware_by_pk?: Maybe<Firmware>;
  /** fetch data from the table: "ground_truth.configurations" */
  ground_truth_configurations: Array<Ground_Truth_Configurations>;
  /** fetch aggregated fields from the table: "ground_truth.configurations" */
  ground_truth_configurations_aggregate: Ground_Truth_Configurations_Aggregate;
  /** fetch data from the table: "ground_truth.configurations" using primary key columns */
  ground_truth_configurations_by_pk?: Maybe<Ground_Truth_Configurations>;
  /** fetch data from the table: "ground_truth.device_types" */
  ground_truth_device_types: Array<Ground_Truth_Device_Types>;
  /** fetch aggregated fields from the table: "ground_truth.device_types" */
  ground_truth_device_types_aggregate: Ground_Truth_Device_Types_Aggregate;
  /** fetch data from the table: "ground_truth.device_types" using primary key columns */
  ground_truth_device_types_by_pk?: Maybe<Ground_Truth_Device_Types>;
  /** fetch data from the table: "ground_truth.processing_modes" */
  ground_truth_processing_modes: Array<Ground_Truth_Processing_Modes>;
  /** fetch aggregated fields from the table: "ground_truth.processing_modes" */
  ground_truth_processing_modes_aggregate: Ground_Truth_Processing_Modes_Aggregate;
  /** fetch data from the table: "ground_truth.processing_modes" using primary key columns */
  ground_truth_processing_modes_by_pk?: Maybe<Ground_Truth_Processing_Modes>;
  /** fetch data from the table: "ground_truth.recordings" */
  ground_truth_recordings: Array<Ground_Truth_Recordings>;
  /** fetch aggregated fields from the table: "ground_truth.recordings" */
  ground_truth_recordings_aggregate: Ground_Truth_Recordings_Aggregate;
  /** fetch data from the table: "ground_truth.recordings" using primary key columns */
  ground_truth_recordings_by_pk?: Maybe<Ground_Truth_Recordings>;
  /** fetch data from the table: "ground_truth_types" */
  ground_truth_types: Array<Ground_Truth_Types>;
  /** fetch aggregated fields from the table: "ground_truth_types" */
  ground_truth_types_aggregate: Ground_Truth_Types_Aggregate;
  /** fetch data from the table: "ground_truth_types" using primary key columns */
  ground_truth_types_by_pk?: Maybe<Ground_Truth_Types>;
  /** fetch data from the table: "hypertask_field_option" */
  hypertask_field_option: Array<Hypertask_Field_Option>;
  /** fetch aggregated fields from the table: "hypertask_field_option" */
  hypertask_field_option_aggregate: Hypertask_Field_Option_Aggregate;
  /** fetch data from the table: "hypertask_field_option" using primary key columns */
  hypertask_field_option_by_pk?: Maybe<Hypertask_Field_Option>;
  /** fetch data from the table: "hypertask_task" */
  hypertask_task: Array<Hypertask_Task>;
  /** fetch aggregated fields from the table: "hypertask_task" */
  hypertask_task_aggregate: Hypertask_Task_Aggregate;
  /** fetch data from the table: "hypertask_task" using primary key columns */
  hypertask_task_by_pk?: Maybe<Hypertask_Task>;
  /** fetch data from the table: "hypertasks" */
  hypertasks: Array<Hypertasks>;
  /** fetch aggregated fields from the table: "hypertasks" */
  hypertasks_aggregate: Hypertasks_Aggregate;
  /** fetch data from the table: "hypertasks" using primary key columns */
  hypertasks_by_pk?: Maybe<Hypertasks>;
  /** An array relationship */
  instruction_translations: Array<Instruction_Translations>;
  /** fetch data from the table: "instruction_translations" using primary key columns */
  instruction_translations_by_pk?: Maybe<Instruction_Translations>;
  /** fetch data from the table: "integration_test.expectation_media" */
  integration_test_expectation_media: Array<Integration_Test_Expectation_Media>;
  /** fetch aggregated fields from the table: "integration_test.expectation_media" */
  integration_test_expectation_media_aggregate: Integration_Test_Expectation_Media_Aggregate;
  /** fetch data from the table: "integration_test.expectation_media" using primary key columns */
  integration_test_expectation_media_by_pk?: Maybe<Integration_Test_Expectation_Media>;
  /** fetch data from the table: "integration_test.expectation_step" */
  integration_test_expectation_step: Array<Integration_Test_Expectation_Step>;
  /** fetch aggregated fields from the table: "integration_test.expectation_step" */
  integration_test_expectation_step_aggregate: Integration_Test_Expectation_Step_Aggregate;
  /** fetch data from the table: "integration_test.expectation_step" using primary key columns */
  integration_test_expectation_step_by_pk?: Maybe<Integration_Test_Expectation_Step>;
  /** fetch data from the table: "integration_test.expectation_translation" */
  integration_test_expectation_translation: Array<Integration_Test_Expectation_Translation>;
  /** fetch aggregated fields from the table: "integration_test.expectation_translation" */
  integration_test_expectation_translation_aggregate: Integration_Test_Expectation_Translation_Aggregate;
  /** fetch data from the table: "integration_test.expectation_translation" using primary key columns */
  integration_test_expectation_translation_by_pk?: Maybe<Integration_Test_Expectation_Translation>;
  /** fetch data from the table: "integration_test.expectations" */
  integration_test_expectations: Array<Integration_Test_Expectations>;
  /** fetch aggregated fields from the table: "integration_test.expectations" */
  integration_test_expectations_aggregate: Integration_Test_Expectations_Aggregate;
  /** fetch data from the table: "integration_test.expectations" using primary key columns */
  integration_test_expectations_by_pk?: Maybe<Integration_Test_Expectations>;
  /** fetch data from the table: "integration_test.instruction_media" */
  integration_test_instruction_media: Array<Integration_Test_Instruction_Media>;
  /** fetch aggregated fields from the table: "integration_test.instruction_media" */
  integration_test_instruction_media_aggregate: Integration_Test_Instruction_Media_Aggregate;
  /** fetch data from the table: "integration_test.instruction_media" using primary key columns */
  integration_test_instruction_media_by_pk?: Maybe<Integration_Test_Instruction_Media>;
  /** fetch data from the table: "integration_test.instruction_translation" */
  integration_test_instruction_translation: Array<Integration_Test_Instruction_Translation>;
  /** fetch aggregated fields from the table: "integration_test.instruction_translation" */
  integration_test_instruction_translation_aggregate: Integration_Test_Instruction_Translation_Aggregate;
  /** fetch data from the table: "integration_test.instruction_translation" using primary key columns */
  integration_test_instruction_translation_by_pk?: Maybe<Integration_Test_Instruction_Translation>;
  /** fetch data from the table: "integration_test.instructions" */
  integration_test_instructions: Array<Integration_Test_Instructions>;
  /** fetch aggregated fields from the table: "integration_test.instructions" */
  integration_test_instructions_aggregate: Integration_Test_Instructions_Aggregate;
  /** fetch data from the table: "integration_test.instructions" using primary key columns */
  integration_test_instructions_by_pk?: Maybe<Integration_Test_Instructions>;
  /** fetch data from the table: "integration_test.observances" */
  integration_test_observances: Array<Integration_Test_Observances>;
  /** fetch aggregated fields from the table: "integration_test.observances" */
  integration_test_observances_aggregate: Integration_Test_Observances_Aggregate;
  /** fetch data from the table: "integration_test.observances" using primary key columns */
  integration_test_observances_by_pk?: Maybe<Integration_Test_Observances>;
  /** fetch data from the table: "integration_test.observations" */
  integration_test_observations: Array<Integration_Test_Observations>;
  /** fetch aggregated fields from the table: "integration_test.observations" */
  integration_test_observations_aggregate: Integration_Test_Observations_Aggregate;
  /** fetch data from the table: "integration_test.observations" using primary key columns */
  integration_test_observations_by_pk?: Maybe<Integration_Test_Observations>;
  /** fetch data from the table: "integration_test.project_sequence" */
  integration_test_project_sequence: Array<Integration_Test_Project_Sequence>;
  /** fetch aggregated fields from the table: "integration_test.project_sequence" */
  integration_test_project_sequence_aggregate: Integration_Test_Project_Sequence_Aggregate;
  /** fetch data from the table: "integration_test.project_sequence" using primary key columns */
  integration_test_project_sequence_by_pk?: Maybe<Integration_Test_Project_Sequence>;
  /** fetch data from the table: "integration_test.sequences" */
  integration_test_sequences: Array<Integration_Test_Sequences>;
  /** fetch aggregated fields from the table: "integration_test.sequences" */
  integration_test_sequences_aggregate: Integration_Test_Sequences_Aggregate;
  /** fetch data from the table: "integration_test.sequences" using primary key columns */
  integration_test_sequences_by_pk?: Maybe<Integration_Test_Sequences>;
  /** fetch data from the table: "integration_test.steps" */
  integration_test_steps: Array<Integration_Test_Steps>;
  /** fetch aggregated fields from the table: "integration_test.steps" */
  integration_test_steps_aggregate: Integration_Test_Steps_Aggregate;
  /** fetch data from the table: "integration_test.steps" using primary key columns */
  integration_test_steps_by_pk?: Maybe<Integration_Test_Steps>;
  /** fetch data from the table: "languages" */
  languages: Array<Languages>;
  /** fetch data from the table: "languages" using primary key columns */
  languages_by_pk?: Maybe<Languages>;
  /** fetch data from the table: "machine_learning.blobs" */
  machine_learning_blobs: Array<Machine_Learning_Blobs>;
  /** fetch aggregated fields from the table: "machine_learning.blobs" */
  machine_learning_blobs_aggregate: Machine_Learning_Blobs_Aggregate;
  /** fetch data from the table: "machine_learning.blobs" using primary key columns */
  machine_learning_blobs_by_pk?: Maybe<Machine_Learning_Blobs>;
  /** fetch data from the table: "machine_learning.dataset_feature" */
  machine_learning_dataset_feature: Array<Machine_Learning_Dataset_Feature>;
  /** fetch aggregated fields from the table: "machine_learning.dataset_feature" */
  machine_learning_dataset_feature_aggregate: Machine_Learning_Dataset_Feature_Aggregate;
  /** fetch data from the table: "machine_learning.dataset_feature" using primary key columns */
  machine_learning_dataset_feature_by_pk?: Maybe<Machine_Learning_Dataset_Feature>;
  /** fetch data from the table: "machine_learning.datasets" */
  machine_learning_datasets: Array<Machine_Learning_Datasets>;
  /** fetch aggregated fields from the table: "machine_learning.datasets" */
  machine_learning_datasets_aggregate: Machine_Learning_Datasets_Aggregate;
  /** fetch data from the table: "machine_learning.datasets" using primary key columns */
  machine_learning_datasets_by_pk?: Maybe<Machine_Learning_Datasets>;
  /** fetch data from the table: "machine_learning.features" */
  machine_learning_features: Array<Machine_Learning_Features>;
  /** fetch data from the table: "machine_learning.features" using primary key columns */
  machine_learning_features_by_pk?: Maybe<Machine_Learning_Features>;
  /** fetch data from the table: "machine_learning.model_blob" */
  machine_learning_model_blob: Array<Machine_Learning_Model_Blob>;
  /** fetch data from the table: "machine_learning.model_blob" using primary key columns */
  machine_learning_model_blob_by_pk?: Maybe<Machine_Learning_Model_Blob>;
  /** fetch data from the table: "machine_learning.model_dataset" */
  machine_learning_model_dataset: Array<Machine_Learning_Model_Dataset>;
  /** fetch data from the table: "machine_learning.model_dataset" using primary key columns */
  machine_learning_model_dataset_by_pk?: Maybe<Machine_Learning_Model_Dataset>;
  /** fetch data from the table: "machine_learning.model_recording" */
  machine_learning_model_recording: Array<Machine_Learning_Model_Recording>;
  /** fetch aggregated fields from the table: "machine_learning.model_recording" */
  machine_learning_model_recording_aggregate: Machine_Learning_Model_Recording_Aggregate;
  /** fetch data from the table: "machine_learning.model_recording" using primary key columns */
  machine_learning_model_recording_by_pk?: Maybe<Machine_Learning_Model_Recording>;
  /** fetch data from the table: "machine_learning.model_types" */
  machine_learning_model_types: Array<Machine_Learning_Model_Types>;
  /** fetch data from the table: "machine_learning.model_types" using primary key columns */
  machine_learning_model_types_by_pk?: Maybe<Machine_Learning_Model_Types>;
  /** fetch data from the table: "machine_learning.models" */
  machine_learning_models: Array<Machine_Learning_Models>;
  /** fetch data from the table: "machine_learning.models" using primary key columns */
  machine_learning_models_by_pk?: Maybe<Machine_Learning_Models>;
  /** An array relationship */
  media: Array<Media>;
  /** An aggregate relationship */
  media_aggregate: Media_Aggregate;
  /** fetch data from the table: "media" using primary key columns */
  media_by_pk?: Maybe<Media>;
  /** fetch data from the table: "media_type" */
  media_type: Array<Media_Type>;
  /** fetch data from the table: "media_type" using primary key columns */
  media_type_by_pk?: Maybe<Media_Type>;
  /** fetch data from the table: "mode_project" */
  mode_project: Array<Mode_Project>;
  /** fetch aggregated fields from the table: "mode_project" */
  mode_project_aggregate: Mode_Project_Aggregate;
  /** fetch data from the table: "mode_project" using primary key columns */
  mode_project_by_pk?: Maybe<Mode_Project>;
  /** fetch data from the table: "model_name_hashes" */
  model_name_hashes: Array<Model_Name_Hashes>;
  /** fetch aggregated fields from the table: "model_name_hashes" */
  model_name_hashes_aggregate: Model_Name_Hashes_Aggregate;
  /** fetch data from the table: "model_name_hashes" using primary key columns */
  model_name_hashes_by_pk?: Maybe<Model_Name_Hashes>;
  /** fetch data from the table: "models" */
  models: Array<Models>;
  /** fetch aggregated fields from the table: "models" */
  models_aggregate: Models_Aggregate;
  /** fetch data from the table: "models" using primary key columns */
  models_by_pk?: Maybe<Models>;
  /** fetch data from the table: "opmode_profile" */
  opmode_profile: Array<Opmode_Profile>;
  /** fetch data from the table: "opmode_profile" using primary key columns */
  opmode_profile_by_pk?: Maybe<Opmode_Profile>;
  /** fetch data from the table: "opmode_project" */
  opmode_project: Array<Opmode_Project>;
  /** fetch aggregated fields from the table: "opmode_project" */
  opmode_project_aggregate: Opmode_Project_Aggregate;
  /** fetch data from the table: "opmode_project" using primary key columns */
  opmode_project_by_pk?: Maybe<Opmode_Project>;
  /** fetch data from the table: "opmodes" */
  opmodes: Array<Opmodes>;
  /** fetch aggregated fields from the table: "opmodes" */
  opmodes_aggregate: Opmodes_Aggregate;
  /** fetch data from the table: "opmodes" using primary key columns */
  opmodes_by_pk?: Maybe<Opmodes>;
  /** An array relationship */
  option_media: Array<Option_Media>;
  /** An aggregate relationship */
  option_media_aggregate: Option_Media_Aggregate;
  /** fetch data from the table: "option_media" using primary key columns */
  option_media_by_pk?: Maybe<Option_Media>;
  /** fetch data from the table: "option_translation" */
  option_translation: Array<Option_Translation>;
  /** fetch aggregated fields from the table: "option_translation" */
  option_translation_aggregate: Option_Translation_Aggregate;
  /** fetch data from the table: "option_translation" using primary key columns */
  option_translation_by_pk?: Maybe<Option_Translation>;
  /** An array relationship */
  options: Array<Options>;
  /** An aggregate relationship */
  options_aggregate: Options_Aggregate;
  /** fetch data from the table: "options" using primary key columns */
  options_by_pk?: Maybe<Options>;
  /** fetch data from the table: "payload_recording" */
  payload_recording: Array<Payload_Recording>;
  /** fetch aggregated fields from the table: "payload_recording" */
  payload_recording_aggregate: Payload_Recording_Aggregate;
  /** fetch data from the table: "payload_recording" using primary key columns */
  payload_recording_by_pk?: Maybe<Payload_Recording>;
  /** fetch data from the table: "payload_types" */
  payload_types: Array<Payload_Types>;
  /** fetch aggregated fields from the table: "payload_types" */
  payload_types_aggregate: Payload_Types_Aggregate;
  /** fetch data from the table: "payload_types" using primary key columns */
  payload_types_by_pk?: Maybe<Payload_Types>;
  /** An array relationship */
  payloads: Array<Payloads>;
  /** An aggregate relationship */
  payloads_aggregate: Payloads_Aggregate;
  /** fetch data from the table: "payloads" using primary key columns */
  payloads_by_pk?: Maybe<Payloads>;
  /** fetch data from the table: "performance.ratio_metric_contributions" */
  performance_ratio_metric_contributions: Array<Performance_Ratio_Metric_Contributions>;
  /** fetch aggregated fields from the table: "performance.ratio_metric_contributions" */
  performance_ratio_metric_contributions_aggregate: Performance_Ratio_Metric_Contributions_Aggregate;
  /** fetch data from the table: "performance.ratio_metric_contributions" using primary key columns */
  performance_ratio_metric_contributions_by_pk?: Maybe<Performance_Ratio_Metric_Contributions>;
  /** fetch data from the table: "performance.ratio_metrics" */
  performance_ratio_metrics: Array<Performance_Ratio_Metrics>;
  /** fetch aggregated fields from the table: "performance.ratio_metrics" */
  performance_ratio_metrics_aggregate: Performance_Ratio_Metrics_Aggregate;
  /** fetch data from the table: "performance.ratio_metrics" using primary key columns */
  performance_ratio_metrics_by_pk?: Maybe<Performance_Ratio_Metrics>;
  /** fetch data from the table: "performance_report.deliverable_template" */
  performance_report_deliverable_template: Array<Performance_Report_Deliverable_Template>;
  /** fetch aggregated fields from the table: "performance_report.deliverable_template" */
  performance_report_deliverable_template_aggregate: Performance_Report_Deliverable_Template_Aggregate;
  /** fetch data from the table: "performance_report.deliverable_template" using primary key columns */
  performance_report_deliverable_template_by_pk?: Maybe<Performance_Report_Deliverable_Template>;
  /** fetch data from the table: "performance_report.pivot_types" */
  performance_report_pivot_types: Array<Performance_Report_Pivot_Types>;
  /** fetch aggregated fields from the table: "performance_report.pivot_types" */
  performance_report_pivot_types_aggregate: Performance_Report_Pivot_Types_Aggregate;
  /** fetch data from the table: "performance_report.pivot_types" using primary key columns */
  performance_report_pivot_types_by_pk?: Maybe<Performance_Report_Pivot_Types>;
  /** fetch data from the table: "performance_report.section_pivot_field" */
  performance_report_section_pivot_field: Array<Performance_Report_Section_Pivot_Field>;
  /** fetch aggregated fields from the table: "performance_report.section_pivot_field" */
  performance_report_section_pivot_field_aggregate: Performance_Report_Section_Pivot_Field_Aggregate;
  /** fetch data from the table: "performance_report.section_pivot_field" using primary key columns */
  performance_report_section_pivot_field_by_pk?: Maybe<Performance_Report_Section_Pivot_Field>;
  /** fetch data from the table: "performance_report.section_ratio_metric" */
  performance_report_section_ratio_metric: Array<Performance_Report_Section_Ratio_Metric>;
  /** fetch aggregated fields from the table: "performance_report.section_ratio_metric" */
  performance_report_section_ratio_metric_aggregate: Performance_Report_Section_Ratio_Metric_Aggregate;
  /** fetch data from the table: "performance_report.section_ratio_metric" using primary key columns */
  performance_report_section_ratio_metric_by_pk?: Maybe<Performance_Report_Section_Ratio_Metric>;
  /** fetch data from the table: "performance_report.section_task_blacklist" */
  performance_report_section_task_blacklist: Array<Performance_Report_Section_Task_Blacklist>;
  /** fetch aggregated fields from the table: "performance_report.section_task_blacklist" */
  performance_report_section_task_blacklist_aggregate: Performance_Report_Section_Task_Blacklist_Aggregate;
  /** fetch data from the table: "performance_report.section_task_blacklist" using primary key columns */
  performance_report_section_task_blacklist_by_pk?: Maybe<Performance_Report_Section_Task_Blacklist>;
  /** fetch data from the table: "performance_report.sections" */
  performance_report_sections: Array<Performance_Report_Sections>;
  /** fetch aggregated fields from the table: "performance_report.sections" */
  performance_report_sections_aggregate: Performance_Report_Sections_Aggregate;
  /** fetch data from the table: "performance_report.sections" using primary key columns */
  performance_report_sections_by_pk?: Maybe<Performance_Report_Sections>;
  /** fetch data from the table: "performance_report.templates" */
  performance_report_templates: Array<Performance_Report_Templates>;
  /** fetch aggregated fields from the table: "performance_report.templates" */
  performance_report_templates_aggregate: Performance_Report_Templates_Aggregate;
  /** fetch data from the table: "performance_report.templates" using primary key columns */
  performance_report_templates_by_pk?: Maybe<Performance_Report_Templates>;
  /** fetch data from the table: "peripherals" */
  peripherals: Array<Peripherals>;
  /** fetch data from the table: "peripherals" using primary key columns */
  peripherals_by_pk?: Maybe<Peripherals>;
  /** An array relationship */
  plan_hypertasks: Array<Plan_Hypertasks>;
  /** An aggregate relationship */
  plan_hypertasks_aggregate: Plan_Hypertasks_Aggregate;
  /** fetch data from the table: "plan_hypertasks" using primary key columns */
  plan_hypertasks_by_pk?: Maybe<Plan_Hypertasks>;
  /** An array relationship */
  plan_tasks: Array<Plan_Tasks>;
  /** An aggregate relationship */
  plan_tasks_aggregate: Plan_Tasks_Aggregate;
  /** fetch data from the table: "plan_tasks" using primary key columns */
  plan_tasks_by_pk?: Maybe<Plan_Tasks>;
  /** fetch data from the table: "plans" */
  plans: Array<Plans>;
  /** fetch aggregated fields from the table: "plans" */
  plans_aggregate: Plans_Aggregate;
  /** fetch data from the table: "plans" using primary key columns */
  plans_by_pk?: Maybe<Plans>;
  /** fetch data from the table: "profiles" */
  profiles: Array<Profiles>;
  /** fetch aggregated fields from the table: "profiles" */
  profiles_aggregate: Profiles_Aggregate;
  /** fetch data from the table: "profiles" using primary key columns */
  profiles_by_pk?: Maybe<Profiles>;
  /** fetch data from the table: "project_combo" */
  project_combo: Array<Project_Combo>;
  /** fetch aggregated fields from the table: "project_combo" */
  project_combo_aggregate: Project_Combo_Aggregate;
  /** fetch data from the table: "project_combo" using primary key columns */
  project_combo_by_pk?: Maybe<Project_Combo>;
  /** An array relationship */
  project_dataset: Array<Project_Dataset>;
  /** An aggregate relationship */
  project_dataset_aggregate: Project_Dataset_Aggregate;
  /** execute function "project_engine_metrics" which returns "schema.project_engine_metrics" */
  project_engine_metrics: Array<Schema_Project_Engine_Metrics>;
  /** execute function "project_engine_ratio_metrics" which returns "schema.project_engine_ratio_metrics" */
  project_engine_ratio_metrics: Array<Schema_Project_Engine_Ratio_Metrics>;
  /** execute function "project_engine_ratio_metrics" and query aggregates on result of table type "schema.project_engine_ratio_metrics" */
  project_engine_ratio_metrics_aggregate: Schema_Project_Engine_Ratio_Metrics_Aggregate;
  /** execute function "project_fields" which returns "fields" */
  project_fields: Array<Fields>;
  /** execute function "project_fields" and query aggregates on result of table type "fields" */
  project_fields_aggregate: Fields_Aggregate;
  /** An array relationship */
  project_hypertasks: Array<Project_Hypertasks>;
  /** An aggregate relationship */
  project_hypertasks_aggregate: Project_Hypertasks_Aggregate;
  /** fetch data from the table: "project_hypertasks" using primary key columns */
  project_hypertasks_by_pk?: Maybe<Project_Hypertasks>;
  /** fetch data from the table: "project_metrics" */
  project_metrics: Array<Project_Metrics>;
  /** fetch aggregated fields from the table: "project_metrics" */
  project_metrics_aggregate: Project_Metrics_Aggregate;
  /** fetch data from the table: "project_name" */
  project_name: Array<Project_Name>;
  /** fetch data from the table: "project_rabbit_test_instances" */
  project_rabbit_test_instances: Array<Project_Rabbit_Test_Instances>;
  /** fetch aggregated fields from the table: "project_rabbit_test_instances" */
  project_rabbit_test_instances_aggregate: Project_Rabbit_Test_Instances_Aggregate;
  /** execute function "project_recordings_clean" which returns "recordings" */
  project_recordings_clean: Array<Recordings>;
  /** execute function "project_recordings_clean" and query aggregates on result of table type "recordings" */
  project_recordings_clean_aggregate: Recordings_Aggregate;
  /** execute function "project_recordings_unreviewed" which returns "recordings" */
  project_recordings_unreviewed: Array<Recordings>;
  /** execute function "project_recordings_unreviewed" and query aggregates on result of table type "recordings" */
  project_recordings_unreviewed_aggregate: Recordings_Aggregate;
  /** execute function "project_task_count" which returns "schema.project_task_count" */
  project_task_count: Array<Schema_Project_Task_Count>;
  /** execute function "project_task_count" and query aggregates on result of table type "schema.project_task_count" */
  project_task_count_aggregate: Schema_Project_Task_Count_Aggregate;
  /** execute function "project_task_count_by_device" which returns "schema.project_task_count_by_device" */
  project_task_count_by_device: Array<Schema_Project_Task_Count_By_Device>;
  /** execute function "project_task_count_by_device" and query aggregates on result of table type "schema.project_task_count_by_device" */
  project_task_count_by_device_aggregate: Schema_Project_Task_Count_By_Device_Aggregate;
  /** An array relationship */
  project_tasks: Array<Project_Tasks>;
  /** An aggregate relationship */
  project_tasks_aggregate: Project_Tasks_Aggregate;
  /** fetch data from the table: "project_tasks" using primary key columns */
  project_tasks_by_pk?: Maybe<Project_Tasks>;
  /** fetch data from the table: "project_user" */
  project_user: Array<Project_User>;
  /** fetch aggregated fields from the table: "project_user" */
  project_user_aggregate: Project_User_Aggregate;
  /** fetch data from the table: "project_user" using primary key columns */
  project_user_by_pk?: Maybe<Project_User>;
  /** An array relationship */
  projects: Array<Projects>;
  /** An aggregate relationship */
  projects_aggregate: Projects_Aggregate;
  /** fetch data from the table: "projects" using primary key columns */
  projects_by_pk?: Maybe<Projects>;
  /** fetch data from the table: "rabbit_test.plan_project" */
  rabbit_test_plan_project: Array<Rabbit_Test_Plan_Project>;
  /** fetch aggregated fields from the table: "rabbit_test.plan_project" */
  rabbit_test_plan_project_aggregate: Rabbit_Test_Plan_Project_Aggregate;
  /** fetch data from the table: "rabbit_test.plan_project" using primary key columns */
  rabbit_test_plan_project_by_pk?: Maybe<Rabbit_Test_Plan_Project>;
  /** fetch data from the table: "rabbit_test.plan_recording" */
  rabbit_test_plan_recording: Array<Rabbit_Test_Plan_Recording>;
  /** fetch aggregated fields from the table: "rabbit_test.plan_recording" */
  rabbit_test_plan_recording_aggregate: Rabbit_Test_Plan_Recording_Aggregate;
  /** fetch data from the table: "rabbit_test.plan_recording" using primary key columns */
  rabbit_test_plan_recording_by_pk?: Maybe<Rabbit_Test_Plan_Recording>;
  /** fetch data from the table: "rabbit_test.plan_task" */
  rabbit_test_plan_task: Array<Rabbit_Test_Plan_Task>;
  /** fetch aggregated fields from the table: "rabbit_test.plan_task" */
  rabbit_test_plan_task_aggregate: Rabbit_Test_Plan_Task_Aggregate;
  /** fetch data from the table: "rabbit_test.plan_task" using primary key columns */
  rabbit_test_plan_task_by_pk?: Maybe<Rabbit_Test_Plan_Task>;
  /** fetch data from the table: "rabbit_test.plans" */
  rabbit_test_plans: Array<Rabbit_Test_Plans>;
  /** fetch aggregated fields from the table: "rabbit_test.plans" */
  rabbit_test_plans_aggregate: Rabbit_Test_Plans_Aggregate;
  /** fetch data from the table: "rabbit_test.plans" using primary key columns */
  rabbit_test_plans_by_pk?: Maybe<Rabbit_Test_Plans>;
  /** fetch data from the table: "recording_apps" */
  recording_apps: Array<Recording_Apps>;
  /** fetch aggregated fields from the table: "recording_apps" */
  recording_apps_aggregate: Recording_Apps_Aggregate;
  /** fetch data from the table: "recording_apps" using primary key columns */
  recording_apps_by_pk?: Maybe<Recording_Apps>;
  /** fetch data from the table: "recording_audio_driver" */
  recording_audio_driver: Array<Recording_Audio_Driver>;
  /** fetch data from the table: "recording_audio_driver" using primary key columns */
  recording_audio_driver_by_pk?: Maybe<Recording_Audio_Driver>;
  /** fetch data from the table: "recording_data.ground_truth" */
  recording_data_ground_truth: Array<Recording_Data_Ground_Truth>;
  /** fetch aggregated fields from the table: "recording_data.ground_truth" */
  recording_data_ground_truth_aggregate: Recording_Data_Ground_Truth_Aggregate;
  /** fetch data from the table: "recording_data.ground_truth" using primary key columns */
  recording_data_ground_truth_by_pk?: Maybe<Recording_Data_Ground_Truth>;
  /** fetch data from the table: "recording_data.labels" */
  recording_data_labels: Array<Recording_Data_Labels>;
  /** fetch aggregated fields from the table: "recording_data.labels" */
  recording_data_labels_aggregate: Recording_Data_Labels_Aggregate;
  /** fetch data from the table: "recording_data.labels" using primary key columns */
  recording_data_labels_by_pk?: Maybe<Recording_Data_Labels>;
  /** fetch data from the table: "recording_data.mode_parameter" */
  recording_data_mode_parameter: Array<Recording_Data_Mode_Parameter>;
  /** fetch aggregated fields from the table: "recording_data.mode_parameter" */
  recording_data_mode_parameter_aggregate: Recording_Data_Mode_Parameter_Aggregate;
  /** fetch data from the table: "recording_data.mode_parameter" using primary key columns */
  recording_data_mode_parameter_by_pk?: Maybe<Recording_Data_Mode_Parameter>;
  /** fetch data from the table: "recording_data.modes" */
  recording_data_modes: Array<Recording_Data_Modes>;
  /** fetch aggregated fields from the table: "recording_data.modes" */
  recording_data_modes_aggregate: Recording_Data_Modes_Aggregate;
  /** fetch data from the table: "recording_data.modes" using primary key columns */
  recording_data_modes_by_pk?: Maybe<Recording_Data_Modes>;
  /** fetch data from the table: "recording_data.parameters" */
  recording_data_parameters: Array<Recording_Data_Parameters>;
  /** fetch aggregated fields from the table: "recording_data.parameters" */
  recording_data_parameters_aggregate: Recording_Data_Parameters_Aggregate;
  /** fetch data from the table: "recording_data.parameters" using primary key columns */
  recording_data_parameters_by_pk?: Maybe<Recording_Data_Parameters>;
  /** fetch data from the table: "recording_data.payload_pair" */
  recording_data_payload_pair: Array<Recording_Data_Payload_Pair>;
  /** fetch data from the table: "recording_data.payload_pair" using primary key columns */
  recording_data_payload_pair_by_pk?: Maybe<Recording_Data_Payload_Pair>;
  /** fetch data from the table: "recording_data.predictions" */
  recording_data_predictions: Array<Recording_Data_Predictions>;
  /** fetch aggregated fields from the table: "recording_data.predictions" */
  recording_data_predictions_aggregate: Recording_Data_Predictions_Aggregate;
  /** fetch data from the table: "recording_data.predictions" using primary key columns */
  recording_data_predictions_by_pk?: Maybe<Recording_Data_Predictions>;
  /** fetch data from the table: "recording_recording_tag" */
  recording_recording_tag: Array<Recording_Recording_Tag>;
  /** fetch data from the table: "recording_recording_tag" using primary key columns */
  recording_recording_tag_by_pk?: Maybe<Recording_Recording_Tag>;
  /** execute function "recording_tag_count_by_day" which returns "schema.recording_tag_count_by_day" */
  recording_tag_count_by_day: Array<Schema_Recording_Tag_Count_By_Day>;
  /** execute function "recording_tag_count_by_day" and query aggregates on result of table type "schema.recording_tag_count_by_day" */
  recording_tag_count_by_day_aggregate: Schema_Recording_Tag_Count_By_Day_Aggregate;
  /** fetch data from the table: "recording_tags" */
  recording_tags: Array<Recording_Tags>;
  /** fetch data from the table: "recording_tags" using primary key columns */
  recording_tags_by_pk?: Maybe<Recording_Tags>;
  /** An array relationship */
  recordings: Array<Recordings>;
  /** An aggregate relationship */
  recordings_aggregate: Recordings_Aggregate;
  /** fetch data from the table: "recordings" using primary key columns */
  recordings_by_pk?: Maybe<Recordings>;
  /** fetch data from the table: "role_user" */
  role_user: Array<Role_User>;
  /** fetch aggregated fields from the table: "role_user" */
  role_user_aggregate: Role_User_Aggregate;
  /** fetch data from the table: "role_user" using primary key columns */
  role_user_by_pk?: Maybe<Role_User>;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** fetch aggregated fields from the table: "roles" */
  roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  /** fetch data from the table: "rooms" */
  rooms: Array<Rooms>;
  /** fetch data from the table: "rooms" using primary key columns */
  rooms_by_pk?: Maybe<Rooms>;
  /** fetch data from the table: "schema.project_engine_metrics" */
  schema_project_engine_metrics: Array<Schema_Project_Engine_Metrics>;
  /** fetch data from the table: "schema.project_engine_ratio_metrics" */
  schema_project_engine_ratio_metrics: Array<Schema_Project_Engine_Ratio_Metrics>;
  /** fetch aggregated fields from the table: "schema.project_engine_ratio_metrics" */
  schema_project_engine_ratio_metrics_aggregate: Schema_Project_Engine_Ratio_Metrics_Aggregate;
  /** fetch data from the table: "schema.project_engine_ratio_metrics" using primary key columns */
  schema_project_engine_ratio_metrics_by_pk?: Maybe<Schema_Project_Engine_Ratio_Metrics>;
  /** fetch data from the table: "schema.project_task_count" */
  schema_project_task_count: Array<Schema_Project_Task_Count>;
  /** fetch aggregated fields from the table: "schema.project_task_count" */
  schema_project_task_count_aggregate: Schema_Project_Task_Count_Aggregate;
  /** fetch data from the table: "schema.project_task_count_by_device" */
  schema_project_task_count_by_device: Array<Schema_Project_Task_Count_By_Device>;
  /** fetch aggregated fields from the table: "schema.project_task_count_by_device" */
  schema_project_task_count_by_device_aggregate: Schema_Project_Task_Count_By_Device_Aggregate;
  /** fetch data from the table: "schema.project_task_count_by_device" using primary key columns */
  schema_project_task_count_by_device_by_pk?: Maybe<Schema_Project_Task_Count_By_Device>;
  /** fetch data from the table: "schema.project_task_count" using primary key columns */
  schema_project_task_count_by_pk?: Maybe<Schema_Project_Task_Count>;
  /** fetch data from the table: "schema.recording_tag_count_by_day" */
  schema_recording_tag_count_by_day: Array<Schema_Recording_Tag_Count_By_Day>;
  /** fetch aggregated fields from the table: "schema.recording_tag_count_by_day" */
  schema_recording_tag_count_by_day_aggregate: Schema_Recording_Tag_Count_By_Day_Aggregate;
  /** fetch data from the table: "schema.recording_tag_count_by_day" using primary key columns */
  schema_recording_tag_count_by_day_by_pk?: Maybe<Schema_Recording_Tag_Count_By_Day>;
  /** fetch data from the table: "simulations.scores" */
  simulations_scores: Array<Simulations_Scores>;
  /** fetch data from the table: "simulations.scores" using primary key columns */
  simulations_scores_by_pk?: Maybe<Simulations_Scores>;
  /** fetch data from the table: "task_field_option" */
  task_field_option: Array<Task_Field_Option>;
  /** fetch aggregated fields from the table: "task_field_option" */
  task_field_option_aggregate: Task_Field_Option_Aggregate;
  /** fetch data from the table: "task_field_option" using primary key columns */
  task_field_option_by_pk?: Maybe<Task_Field_Option>;
  /** fetch data from the table: "task_field_option_hashes" */
  task_field_option_hashes: Array<Task_Field_Option_Hashes>;
  /** fetch aggregated fields from the table: "task_field_option_hashes" */
  task_field_option_hashes_aggregate: Task_Field_Option_Hashes_Aggregate;
  /** fetch data from the table: "task_groups.group_fields" */
  task_groups_group_fields: Array<Task_Groups_Group_Fields>;
  /** fetch aggregated fields from the table: "task_groups.group_fields" */
  task_groups_group_fields_aggregate: Task_Groups_Group_Fields_Aggregate;
  /** fetch data from the table: "task_groups.group_fields" using primary key columns */
  task_groups_group_fields_by_pk?: Maybe<Task_Groups_Group_Fields>;
  /** fetch data from the table: "task_groups.groups" */
  task_groups_groups: Array<Task_Groups_Groups>;
  /** fetch aggregated fields from the table: "task_groups.groups" */
  task_groups_groups_aggregate: Task_Groups_Groups_Aggregate;
  /** fetch data from the table: "task_groups.groups" using primary key columns */
  task_groups_groups_by_pk?: Maybe<Task_Groups_Groups>;
  /** fetch data from the table: "task_groups.hierarchies" */
  task_groups_hierarchies: Array<Task_Groups_Hierarchies>;
  /** fetch aggregated fields from the table: "task_groups.hierarchies" */
  task_groups_hierarchies_aggregate: Task_Groups_Hierarchies_Aggregate;
  /** fetch data from the table: "task_groups.hierarchies" using primary key columns */
  task_groups_hierarchies_by_pk?: Maybe<Task_Groups_Hierarchies>;
  /** fetch data from the table: "task_groups.hierarchy_groups" */
  task_groups_hierarchy_groups: Array<Task_Groups_Hierarchy_Groups>;
  /** fetch aggregated fields from the table: "task_groups.hierarchy_groups" */
  task_groups_hierarchy_groups_aggregate: Task_Groups_Hierarchy_Groups_Aggregate;
  /** fetch data from the table: "task_groups.hierarchy_groups" using primary key columns */
  task_groups_hierarchy_groups_by_pk?: Maybe<Task_Groups_Hierarchy_Groups>;
  /** fetch data from the table: "task_instructions" */
  task_instructions: Array<Task_Instructions>;
  /** fetch data from the table: "task_instructions" using primary key columns */
  task_instructions_by_pk?: Maybe<Task_Instructions>;
  /** An array relationship */
  task_media: Array<Task_Media>;
  /** An aggregate relationship */
  task_media_aggregate: Task_Media_Aggregate;
  /** fetch data from the table: "task_media" using primary key columns */
  task_media_by_pk?: Maybe<Task_Media>;
  /** fetch data from the table: "task_statuses" */
  task_statuses: Array<Task_Statuses>;
  /** fetch data from the table: "task_statuses" using primary key columns */
  task_statuses_by_pk?: Maybe<Task_Statuses>;
  /** fetch data from the table: "task_translation" */
  task_translation: Array<Task_Translation>;
  /** fetch aggregated fields from the table: "task_translation" */
  task_translation_aggregate: Task_Translation_Aggregate;
  /** fetch data from the table: "task_translation" using primary key columns */
  task_translation_by_pk?: Maybe<Task_Translation>;
  /** fetch data from the table: "tasks" */
  tasks: Array<Tasks>;
  /** fetch aggregated fields from the table: "tasks" */
  tasks_aggregate: Tasks_Aggregate;
  /** fetch data from the table: "tasks" using primary key columns */
  tasks_by_pk?: Maybe<Tasks>;
  /** An array relationship */
  tool_versions: Array<Tool_Versions>;
  /** fetch data from the table: "tool_versions" using primary key columns */
  tool_versions_by_pk?: Maybe<Tool_Versions>;
  /** fetch data from the table: "tools" */
  tools: Array<Tools>;
  /** fetch data from the table: "tools" using primary key columns */
  tools_by_pk?: Maybe<Tools>;
  /** fetch data from the table: "types" */
  types: Array<Types>;
  /** fetch aggregated fields from the table: "types" */
  types_aggregate: Types_Aggregate;
  /** fetch data from the table: "types" using primary key columns */
  types_by_pk?: Maybe<Types>;
  /** An array relationship */
  user_recordings_by_day_stats: Array<User_Recordings_By_Day_Stats>;
  /** An aggregate relationship */
  user_recordings_by_day_stats_aggregate: User_Recordings_By_Day_Stats_Aggregate;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "xtensa_configurations" */
  xtensa_configurations: Array<Xtensa_Configurations>;
  /** fetch aggregated fields from the table: "xtensa_configurations" */
  xtensa_configurations_aggregate: Xtensa_Configurations_Aggregate;
  /** fetch data from the table: "xtensa_configurations" using primary key columns */
  xtensa_configurations_by_pk?: Maybe<Xtensa_Configurations>;
};


export type Query_RootAudio_DriverArgs = {
  distinct_on?: Maybe<Array<Audio_Driver_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Driver_Order_By>>;
  where?: Maybe<Audio_Driver_Bool_Exp>;
};


export type Query_RootAudio_Driver_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAudio_Measurements_ComponentsArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Components_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Components_Order_By>>;
  where?: Maybe<Audio_Measurements_Components_Bool_Exp>;
};


export type Query_RootAudio_Measurements_Components_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAudio_Measurements_ConfigurationsArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Configurations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Configurations_Order_By>>;
  where?: Maybe<Audio_Measurements_Configurations_Bool_Exp>;
};


export type Query_RootAudio_Measurements_Configurations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAudio_Measurements_Data_TypesArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Data_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Data_Types_Order_By>>;
  where?: Maybe<Audio_Measurements_Data_Types_Bool_Exp>;
};


export type Query_RootAudio_Measurements_Data_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Data_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Data_Types_Order_By>>;
  where?: Maybe<Audio_Measurements_Data_Types_Bool_Exp>;
};


export type Query_RootAudio_Measurements_Data_Types_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootAudio_Measurements_Master_FilesArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Master_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Master_Files_Order_By>>;
  where?: Maybe<Audio_Measurements_Master_Files_Bool_Exp>;
};


export type Query_RootAudio_Measurements_Master_Files_AggregateArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Master_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Master_Files_Order_By>>;
  where?: Maybe<Audio_Measurements_Master_Files_Bool_Exp>;
};


export type Query_RootAudio_Measurements_Master_Files_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAudio_Measurements_OrientationsArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Orientations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Orientations_Order_By>>;
  where?: Maybe<Audio_Measurements_Orientations_Bool_Exp>;
};


export type Query_RootAudio_Measurements_Orientations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAudio_Measurements_Payload_RecordingArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Payload_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Payload_Recording_Order_By>>;
  where?: Maybe<Audio_Measurements_Payload_Recording_Bool_Exp>;
};


export type Query_RootAudio_Measurements_Payload_Recording_AggregateArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Payload_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Payload_Recording_Order_By>>;
  where?: Maybe<Audio_Measurements_Payload_Recording_Bool_Exp>;
};


export type Query_RootAudio_Measurements_Payload_Recording_By_PkArgs = {
  payload_id: Scalars['uuid'];
  recording_id: Scalars['uuid'];
};


export type Query_RootAudio_Measurements_PlacementsArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Placements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Placements_Order_By>>;
  where?: Maybe<Audio_Measurements_Placements_Bool_Exp>;
};


export type Query_RootAudio_Measurements_Placements_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAudio_Measurements_Project_TasksArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Project_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Project_Tasks_Order_By>>;
  where?: Maybe<Audio_Measurements_Project_Tasks_Bool_Exp>;
};


export type Query_RootAudio_Measurements_Project_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Project_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Project_Tasks_Order_By>>;
  where?: Maybe<Audio_Measurements_Project_Tasks_Bool_Exp>;
};


export type Query_RootAudio_Measurements_Project_Tasks_By_PkArgs = {
  project_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};


export type Query_RootAudio_Measurements_RecordingsArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Recordings_Order_By>>;
  where?: Maybe<Audio_Measurements_Recordings_Bool_Exp>;
};


export type Query_RootAudio_Measurements_Recordings_AggregateArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Recordings_Order_By>>;
  where?: Maybe<Audio_Measurements_Recordings_Bool_Exp>;
};


export type Query_RootAudio_Measurements_Recordings_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAudio_Measurements_StagesArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Stages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Stages_Order_By>>;
  where?: Maybe<Audio_Measurements_Stages_Bool_Exp>;
};


export type Query_RootAudio_Measurements_Stages_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBringup_Checklist_DeliverableArgs = {
  distinct_on?: Maybe<Array<Bringup_Checklist_Deliverable_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Checklist_Deliverable_Order_By>>;
  where?: Maybe<Bringup_Checklist_Deliverable_Bool_Exp>;
};


export type Query_RootBringup_Checklist_Deliverable_AggregateArgs = {
  distinct_on?: Maybe<Array<Bringup_Checklist_Deliverable_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Checklist_Deliverable_Order_By>>;
  where?: Maybe<Bringup_Checklist_Deliverable_Bool_Exp>;
};


export type Query_RootBringup_Checklist_Deliverable_By_PkArgs = {
  checklist_id: Scalars['uuid'];
  deliverable_id: Scalars['uuid'];
};


export type Query_RootBringup_ChecklistsArgs = {
  distinct_on?: Maybe<Array<Bringup_Checklists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Checklists_Order_By>>;
  where?: Maybe<Bringup_Checklists_Bool_Exp>;
};


export type Query_RootBringup_Checklists_AggregateArgs = {
  distinct_on?: Maybe<Array<Bringup_Checklists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Checklists_Order_By>>;
  where?: Maybe<Bringup_Checklists_Bool_Exp>;
};


export type Query_RootBringup_Checklists_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBringup_EndorsementsArgs = {
  distinct_on?: Maybe<Array<Bringup_Endorsements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Endorsements_Order_By>>;
  where?: Maybe<Bringup_Endorsements_Bool_Exp>;
};


export type Query_RootBringup_Endorsements_AggregateArgs = {
  distinct_on?: Maybe<Array<Bringup_Endorsements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Endorsements_Order_By>>;
  where?: Maybe<Bringup_Endorsements_Bool_Exp>;
};


export type Query_RootBringup_Endorsements_By_PkArgs = {
  item_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};


export type Query_RootBringup_ItemsArgs = {
  distinct_on?: Maybe<Array<Bringup_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Items_Order_By>>;
  where?: Maybe<Bringup_Items_Bool_Exp>;
};


export type Query_RootBringup_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Bringup_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Items_Order_By>>;
  where?: Maybe<Bringup_Items_Bool_Exp>;
};


export type Query_RootBringup_Items_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBringup_MilestonesArgs = {
  distinct_on?: Maybe<Array<Bringup_Milestones_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Milestones_Order_By>>;
  where?: Maybe<Bringup_Milestones_Bool_Exp>;
};


export type Query_RootBringup_Milestones_AggregateArgs = {
  distinct_on?: Maybe<Array<Bringup_Milestones_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Milestones_Order_By>>;
  where?: Maybe<Bringup_Milestones_Bool_Exp>;
};


export type Query_RootBringup_Milestones_By_PkArgs = {
  item_id: Scalars['uuid'];
};


export type Query_RootBringup_Project_Item_CommentsArgs = {
  distinct_on?: Maybe<Array<Bringup_Project_Item_Comments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Project_Item_Comments_Order_By>>;
  where?: Maybe<Bringup_Project_Item_Comments_Bool_Exp>;
};


export type Query_RootBringup_Project_Item_Comments_AggregateArgs = {
  distinct_on?: Maybe<Array<Bringup_Project_Item_Comments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Project_Item_Comments_Order_By>>;
  where?: Maybe<Bringup_Project_Item_Comments_Bool_Exp>;
};


export type Query_RootBringup_Project_Item_Comments_By_PkArgs = {
  created_at: Scalars['timestamptz'];
  item_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};


export type Query_RootCategoriesArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Categories_Order_By>>;
  where?: Maybe<Categories_Bool_Exp>;
};


export type Query_RootCategories_AggregateArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Categories_Order_By>>;
  where?: Maybe<Categories_Bool_Exp>;
};


export type Query_RootCategories_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootContext_Display_FieldArgs = {
  distinct_on?: Maybe<Array<Context_Display_Field_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Display_Field_Order_By>>;
  where?: Maybe<Context_Display_Field_Bool_Exp>;
};


export type Query_RootContext_Display_Field_AggregateArgs = {
  distinct_on?: Maybe<Array<Context_Display_Field_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Display_Field_Order_By>>;
  where?: Maybe<Context_Display_Field_Bool_Exp>;
};


export type Query_RootContext_Display_Field_By_PkArgs = {
  context_display_id: Scalars['uuid'];
  field_id: Scalars['uuid'];
};


export type Query_RootContext_DisplaysArgs = {
  distinct_on?: Maybe<Array<Context_Displays_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Displays_Order_By>>;
  where?: Maybe<Context_Displays_Bool_Exp>;
};


export type Query_RootContext_Displays_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootContextsArgs = {
  distinct_on?: Maybe<Array<Contexts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contexts_Order_By>>;
  where?: Maybe<Contexts_Bool_Exp>;
};


export type Query_RootContexts_AggregateArgs = {
  distinct_on?: Maybe<Array<Contexts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contexts_Order_By>>;
  where?: Maybe<Contexts_Bool_Exp>;
};


export type Query_RootContexts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCustomersArgs = {
  distinct_on?: Maybe<Array<Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customers_Order_By>>;
  where?: Maybe<Customers_Bool_Exp>;
};


export type Query_RootCustomers_AggregateArgs = {
  distinct_on?: Maybe<Array<Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customers_Order_By>>;
  where?: Maybe<Customers_Bool_Exp>;
};


export type Query_RootCustomers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDeliverablesArgs = {
  distinct_on?: Maybe<Array<Deliverables_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliverables_Order_By>>;
  where?: Maybe<Deliverables_Bool_Exp>;
};


export type Query_RootDeliverables_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliverables_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliverables_Order_By>>;
  where?: Maybe<Deliverables_Bool_Exp>;
};


export type Query_RootDeliverables_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDeliveries_ArchitecturesArgs = {
  distinct_on?: Maybe<Array<Deliveries_Architectures_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Architectures_Order_By>>;
  where?: Maybe<Deliveries_Architectures_Bool_Exp>;
};


export type Query_RootDeliveries_Architectures_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDeliveries_Artifact_Microsoft_Whql_SubmissionArgs = {
  distinct_on?: Maybe<Array<Deliveries_Artifact_Microsoft_Whql_Submission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Artifact_Microsoft_Whql_Submission_Order_By>>;
  where?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission_Bool_Exp>;
};


export type Query_RootDeliveries_Artifact_Microsoft_Whql_Submission_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Artifact_Microsoft_Whql_Submission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Artifact_Microsoft_Whql_Submission_Order_By>>;
  where?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission_Bool_Exp>;
};


export type Query_RootDeliveries_Artifact_Microsoft_Whql_Submission_By_PkArgs = {
  artifact_id: Scalars['uuid'];
  microsoft_whql_submission_id: Scalars['uuid'];
};


export type Query_RootDeliveries_Artifact_Operating_SystemArgs = {
  distinct_on?: Maybe<Array<Deliveries_Artifact_Operating_System_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Artifact_Operating_System_Order_By>>;
  where?: Maybe<Deliveries_Artifact_Operating_System_Bool_Exp>;
};


export type Query_RootDeliveries_Artifact_Operating_System_By_PkArgs = {
  artifact_id: Scalars['uuid'];
  os_id: Scalars['uuid'];
};


export type Query_RootDeliveries_ArtifactsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Artifacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Artifacts_Order_By>>;
  where?: Maybe<Deliveries_Artifacts_Bool_Exp>;
};


export type Query_RootDeliveries_Artifacts_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Artifacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Artifacts_Order_By>>;
  where?: Maybe<Deliveries_Artifacts_Bool_Exp>;
};


export type Query_RootDeliveries_Artifacts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDeliveries_Device_FamiliesArgs = {
  distinct_on?: Maybe<Array<Deliveries_Device_Families_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Device_Families_Order_By>>;
  where?: Maybe<Deliveries_Device_Families_Bool_Exp>;
};


export type Query_RootDeliveries_Device_Families_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDeliveries_Driver_PackageArgs = {
  distinct_on?: Maybe<Array<Deliveries_Driver_Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Driver_Package_Order_By>>;
  where?: Maybe<Deliveries_Driver_Package_Bool_Exp>;
};


export type Query_RootDeliveries_Driver_Package_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Driver_Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Driver_Package_Order_By>>;
  where?: Maybe<Deliveries_Driver_Package_Bool_Exp>;
};


export type Query_RootDeliveries_Driver_Package_By_PkArgs = {
  driver_id: Scalars['uuid'];
  package_id: Scalars['uuid'];
};


export type Query_RootDeliveries_Driver_Version_PackageArgs = {
  distinct_on?: Maybe<Array<Deliveries_Driver_Version_Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Driver_Version_Package_Order_By>>;
  where?: Maybe<Deliveries_Driver_Version_Package_Bool_Exp>;
};


export type Query_RootDeliveries_Driver_Version_Package_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Driver_Version_Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Driver_Version_Package_Order_By>>;
  where?: Maybe<Deliveries_Driver_Version_Package_Bool_Exp>;
};


export type Query_RootDeliveries_Driver_Version_Package_By_PkArgs = {
  driver_version_id: Scalars['uuid'];
  package_id: Scalars['uuid'];
};


export type Query_RootDeliveries_Driver_VersionsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Driver_Versions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Driver_Versions_Order_By>>;
  where?: Maybe<Deliveries_Driver_Versions_Bool_Exp>;
};


export type Query_RootDeliveries_Driver_Versions_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Driver_Versions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Driver_Versions_Order_By>>;
  where?: Maybe<Deliveries_Driver_Versions_Bool_Exp>;
};


export type Query_RootDeliveries_Driver_Versions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDeliveries_DriversArgs = {
  distinct_on?: Maybe<Array<Deliveries_Drivers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Drivers_Order_By>>;
  where?: Maybe<Deliveries_Drivers_Bool_Exp>;
};


export type Query_RootDeliveries_Drivers_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Drivers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Drivers_Order_By>>;
  where?: Maybe<Deliveries_Drivers_Bool_Exp>;
};


export type Query_RootDeliveries_Drivers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDeliveries_Microsoft_Hlk_ArtifactsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Microsoft_Hlk_Artifacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Microsoft_Hlk_Artifacts_Order_By>>;
  where?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_Bool_Exp>;
};


export type Query_RootDeliveries_Microsoft_Hlk_Artifacts_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Microsoft_Hlk_Artifacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Microsoft_Hlk_Artifacts_Order_By>>;
  where?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_Bool_Exp>;
};


export type Query_RootDeliveries_Microsoft_Hlk_Artifacts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDeliveries_Microsoft_Shipping_Label_ReviewsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Microsoft_Shipping_Label_Reviews_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Microsoft_Shipping_Label_Reviews_Order_By>>;
  where?: Maybe<Deliveries_Microsoft_Shipping_Label_Reviews_Bool_Exp>;
};


export type Query_RootDeliveries_Microsoft_Shipping_Label_Reviews_By_PkArgs = {
  microsoft_shipping_label_id: Scalars['uuid'];
};


export type Query_RootDeliveries_Microsoft_Shipping_LabelsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Microsoft_Shipping_Labels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Microsoft_Shipping_Labels_Order_By>>;
  where?: Maybe<Deliveries_Microsoft_Shipping_Labels_Bool_Exp>;
};


export type Query_RootDeliveries_Microsoft_Shipping_Labels_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Microsoft_Shipping_Labels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Microsoft_Shipping_Labels_Order_By>>;
  where?: Maybe<Deliveries_Microsoft_Shipping_Labels_Bool_Exp>;
};


export type Query_RootDeliveries_Microsoft_Shipping_Labels_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDeliveries_Microsoft_Whql_SubmissionsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Microsoft_Whql_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Microsoft_Whql_Submissions_Order_By>>;
  where?: Maybe<Deliveries_Microsoft_Whql_Submissions_Bool_Exp>;
};


export type Query_RootDeliveries_Microsoft_Whql_Submissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Microsoft_Whql_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Microsoft_Whql_Submissions_Order_By>>;
  where?: Maybe<Deliveries_Microsoft_Whql_Submissions_Bool_Exp>;
};


export type Query_RootDeliveries_Microsoft_Whql_Submissions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDeliveries_Operating_SystemsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Operating_Systems_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Operating_Systems_Order_By>>;
  where?: Maybe<Deliveries_Operating_Systems_Bool_Exp>;
};


export type Query_RootDeliveries_Operating_Systems_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDeliveries_Package_Operating_SystemsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Package_Operating_Systems_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Package_Operating_Systems_Order_By>>;
  where?: Maybe<Deliveries_Package_Operating_Systems_Bool_Exp>;
};


export type Query_RootDeliveries_Package_Operating_Systems_By_PkArgs = {
  os_id: Scalars['uuid'];
  package_id: Scalars['uuid'];
};


export type Query_RootDeliveries_Package_TargetArgs = {
  distinct_on?: Maybe<Array<Deliveries_Package_Target_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Package_Target_Order_By>>;
  where?: Maybe<Deliveries_Package_Target_Bool_Exp>;
};


export type Query_RootDeliveries_Package_Target_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Package_Target_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Package_Target_Order_By>>;
  where?: Maybe<Deliveries_Package_Target_Bool_Exp>;
};


export type Query_RootDeliveries_Package_Target_By_PkArgs = {
  package_id: Scalars['uuid'];
  target_id: Scalars['uuid'];
};


export type Query_RootDeliveries_PackagesArgs = {
  distinct_on?: Maybe<Array<Deliveries_Packages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Packages_Order_By>>;
  where?: Maybe<Deliveries_Packages_Bool_Exp>;
};


export type Query_RootDeliveries_Packages_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Packages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Packages_Order_By>>;
  where?: Maybe<Deliveries_Packages_Bool_Exp>;
};


export type Query_RootDeliveries_Packages_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDeliveries_PartnersArgs = {
  distinct_on?: Maybe<Array<Deliveries_Partners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Partners_Order_By>>;
  where?: Maybe<Deliveries_Partners_Bool_Exp>;
};


export type Query_RootDeliveries_Partners_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDeliveries_PlatformsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Platforms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Platforms_Order_By>>;
  where?: Maybe<Deliveries_Platforms_Bool_Exp>;
};


export type Query_RootDeliveries_Platforms_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDeliveries_Project_ConfigsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Project_Configs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Project_Configs_Order_By>>;
  where?: Maybe<Deliveries_Project_Configs_Bool_Exp>;
};


export type Query_RootDeliveries_Project_Configs_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Project_Configs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Project_Configs_Order_By>>;
  where?: Maybe<Deliveries_Project_Configs_Bool_Exp>;
};


export type Query_RootDeliveries_Project_Configs_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDeliveries_Target_PartnerArgs = {
  distinct_on?: Maybe<Array<Deliveries_Target_Partner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Target_Partner_Order_By>>;
  where?: Maybe<Deliveries_Target_Partner_Bool_Exp>;
};


export type Query_RootDeliveries_Target_Partner_By_PkArgs = {
  partner_id: Scalars['uuid'];
  target_id: Scalars['uuid'];
};


export type Query_RootDeliveries_TargetsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Targets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Targets_Order_By>>;
  where?: Maybe<Deliveries_Targets_Bool_Exp>;
};


export type Query_RootDeliveries_Targets_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Targets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Targets_Order_By>>;
  where?: Maybe<Deliveries_Targets_Bool_Exp>;
};


export type Query_RootDeliveries_Targets_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDevice_FirmwareArgs = {
  distinct_on?: Maybe<Array<Device_Firmware_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Device_Firmware_Order_By>>;
  where?: Maybe<Device_Firmware_Bool_Exp>;
};


export type Query_RootDevice_Firmware_By_PkArgs = {
  device_id: Scalars['uuid'];
  firmware_id: Scalars['uuid'];
};


export type Query_RootDevice_ProjectArgs = {
  distinct_on?: Maybe<Array<Device_Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Device_Project_Order_By>>;
  where?: Maybe<Device_Project_Bool_Exp>;
};


export type Query_RootDevice_Project_AggregateArgs = {
  distinct_on?: Maybe<Array<Device_Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Device_Project_Order_By>>;
  where?: Maybe<Device_Project_Bool_Exp>;
};


export type Query_RootDevice_Project_By_PkArgs = {
  device_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};


export type Query_RootDevicesArgs = {
  distinct_on?: Maybe<Array<Devices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Devices_Order_By>>;
  where?: Maybe<Devices_Bool_Exp>;
};


export type Query_RootDevices_AggregateArgs = {
  distinct_on?: Maybe<Array<Devices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Devices_Order_By>>;
  where?: Maybe<Devices_Bool_Exp>;
};


export type Query_RootDevices_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDynamic_ScalingArgs = {
  distinct_on?: Maybe<Array<Dynamic_Scaling_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Scaling_Order_By>>;
  where?: Maybe<Dynamic_Scaling_Bool_Exp>;
};


export type Query_RootDynamic_Scaling_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootEngine_OpmodeArgs = {
  distinct_on?: Maybe<Array<Engine_Opmode_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Engine_Opmode_Order_By>>;
  where?: Maybe<Engine_Opmode_Bool_Exp>;
};


export type Query_RootEngine_Opmode_AggregateArgs = {
  distinct_on?: Maybe<Array<Engine_Opmode_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Engine_Opmode_Order_By>>;
  where?: Maybe<Engine_Opmode_Bool_Exp>;
};


export type Query_RootEngine_Opmode_By_PkArgs = {
  engine_id: Scalars['uuid'];
  opmode_id: Scalars['uuid'];
};


export type Query_RootEngine_Opmode_RecordingArgs = {
  distinct_on?: Maybe<Array<Engine_Opmode_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Engine_Opmode_Recording_Order_By>>;
  where?: Maybe<Engine_Opmode_Recording_Bool_Exp>;
};


export type Query_RootEngine_Opmode_Recording_AggregateArgs = {
  distinct_on?: Maybe<Array<Engine_Opmode_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Engine_Opmode_Recording_Order_By>>;
  where?: Maybe<Engine_Opmode_Recording_Bool_Exp>;
};


export type Query_RootEngine_Opmode_Recording_By_PkArgs = {
  engine_id: Scalars['uuid'];
  opmode_id: Scalars['uuid'];
  recording_id: Scalars['uuid'];
};


export type Query_RootEngine_TypesArgs = {
  distinct_on?: Maybe<Array<Engine_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Engine_Types_Order_By>>;
  where?: Maybe<Engine_Types_Bool_Exp>;
};


export type Query_RootEngine_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Engine_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Engine_Types_Order_By>>;
  where?: Maybe<Engine_Types_Bool_Exp>;
};


export type Query_RootEngine_Types_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootEnginesArgs = {
  distinct_on?: Maybe<Array<Engines_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Engines_Order_By>>;
  where?: Maybe<Engines_Bool_Exp>;
};


export type Query_RootEngines_AggregateArgs = {
  distinct_on?: Maybe<Array<Engines_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Engines_Order_By>>;
  where?: Maybe<Engines_Bool_Exp>;
};


export type Query_RootEngines_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootField_Option_MediaArgs = {
  distinct_on?: Maybe<Array<Field_Option_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Field_Option_Media_Order_By>>;
  where?: Maybe<Field_Option_Media_Bool_Exp>;
};


export type Query_RootField_Option_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Field_Option_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Field_Option_Media_Order_By>>;
  where?: Maybe<Field_Option_Media_Bool_Exp>;
};


export type Query_RootField_Option_Media_By_PkArgs = {
  field_option_id: Scalars['uuid'];
  media_id: Scalars['uuid'];
};


export type Query_RootField_OptionsArgs = {
  distinct_on?: Maybe<Array<Field_Options_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Field_Options_Order_By>>;
  where?: Maybe<Field_Options_Bool_Exp>;
};


export type Query_RootField_Options_AggregateArgs = {
  distinct_on?: Maybe<Array<Field_Options_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Field_Options_Order_By>>;
  where?: Maybe<Field_Options_Bool_Exp>;
};


export type Query_RootField_Options_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootField_TranslationArgs = {
  distinct_on?: Maybe<Array<Field_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Field_Translation_Order_By>>;
  where?: Maybe<Field_Translation_Bool_Exp>;
};


export type Query_RootField_Translation_AggregateArgs = {
  distinct_on?: Maybe<Array<Field_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Field_Translation_Order_By>>;
  where?: Maybe<Field_Translation_Bool_Exp>;
};


export type Query_RootField_Translation_By_PkArgs = {
  field_id: Scalars['uuid'];
  language: Scalars['String'];
};


export type Query_RootFieldsArgs = {
  distinct_on?: Maybe<Array<Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fields_Order_By>>;
  where?: Maybe<Fields_Bool_Exp>;
};


export type Query_RootFields_AggregateArgs = {
  distinct_on?: Maybe<Array<Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fields_Order_By>>;
  where?: Maybe<Fields_Bool_Exp>;
};


export type Query_RootFields_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFirmwareArgs = {
  distinct_on?: Maybe<Array<Firmware_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Firmware_Order_By>>;
  where?: Maybe<Firmware_Bool_Exp>;
};


export type Query_RootFirmware_AggregateArgs = {
  distinct_on?: Maybe<Array<Firmware_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Firmware_Order_By>>;
  where?: Maybe<Firmware_Bool_Exp>;
};


export type Query_RootFirmware_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGround_Truth_ConfigurationsArgs = {
  distinct_on?: Maybe<Array<Ground_Truth_Configurations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ground_Truth_Configurations_Order_By>>;
  where?: Maybe<Ground_Truth_Configurations_Bool_Exp>;
};


export type Query_RootGround_Truth_Configurations_AggregateArgs = {
  distinct_on?: Maybe<Array<Ground_Truth_Configurations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ground_Truth_Configurations_Order_By>>;
  where?: Maybe<Ground_Truth_Configurations_Bool_Exp>;
};


export type Query_RootGround_Truth_Configurations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGround_Truth_Device_TypesArgs = {
  distinct_on?: Maybe<Array<Ground_Truth_Device_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ground_Truth_Device_Types_Order_By>>;
  where?: Maybe<Ground_Truth_Device_Types_Bool_Exp>;
};


export type Query_RootGround_Truth_Device_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Ground_Truth_Device_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ground_Truth_Device_Types_Order_By>>;
  where?: Maybe<Ground_Truth_Device_Types_Bool_Exp>;
};


export type Query_RootGround_Truth_Device_Types_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootGround_Truth_Processing_ModesArgs = {
  distinct_on?: Maybe<Array<Ground_Truth_Processing_Modes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ground_Truth_Processing_Modes_Order_By>>;
  where?: Maybe<Ground_Truth_Processing_Modes_Bool_Exp>;
};


export type Query_RootGround_Truth_Processing_Modes_AggregateArgs = {
  distinct_on?: Maybe<Array<Ground_Truth_Processing_Modes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ground_Truth_Processing_Modes_Order_By>>;
  where?: Maybe<Ground_Truth_Processing_Modes_Bool_Exp>;
};


export type Query_RootGround_Truth_Processing_Modes_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootGround_Truth_RecordingsArgs = {
  distinct_on?: Maybe<Array<Ground_Truth_Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ground_Truth_Recordings_Order_By>>;
  where?: Maybe<Ground_Truth_Recordings_Bool_Exp>;
};


export type Query_RootGround_Truth_Recordings_AggregateArgs = {
  distinct_on?: Maybe<Array<Ground_Truth_Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ground_Truth_Recordings_Order_By>>;
  where?: Maybe<Ground_Truth_Recordings_Bool_Exp>;
};


export type Query_RootGround_Truth_Recordings_By_PkArgs = {
  recording_id: Scalars['uuid'];
};


export type Query_RootGround_Truth_TypesArgs = {
  distinct_on?: Maybe<Array<Ground_Truth_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ground_Truth_Types_Order_By>>;
  where?: Maybe<Ground_Truth_Types_Bool_Exp>;
};


export type Query_RootGround_Truth_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Ground_Truth_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ground_Truth_Types_Order_By>>;
  where?: Maybe<Ground_Truth_Types_Bool_Exp>;
};


export type Query_RootGround_Truth_Types_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootHypertask_Field_OptionArgs = {
  distinct_on?: Maybe<Array<Hypertask_Field_Option_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hypertask_Field_Option_Order_By>>;
  where?: Maybe<Hypertask_Field_Option_Bool_Exp>;
};


export type Query_RootHypertask_Field_Option_AggregateArgs = {
  distinct_on?: Maybe<Array<Hypertask_Field_Option_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hypertask_Field_Option_Order_By>>;
  where?: Maybe<Hypertask_Field_Option_Bool_Exp>;
};


export type Query_RootHypertask_Field_Option_By_PkArgs = {
  field_id: Scalars['uuid'];
  hypertask_id: Scalars['uuid'];
  option_id: Scalars['uuid'];
};


export type Query_RootHypertask_TaskArgs = {
  distinct_on?: Maybe<Array<Hypertask_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hypertask_Task_Order_By>>;
  where?: Maybe<Hypertask_Task_Bool_Exp>;
};


export type Query_RootHypertask_Task_AggregateArgs = {
  distinct_on?: Maybe<Array<Hypertask_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hypertask_Task_Order_By>>;
  where?: Maybe<Hypertask_Task_Bool_Exp>;
};


export type Query_RootHypertask_Task_By_PkArgs = {
  hypertask_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};


export type Query_RootHypertasksArgs = {
  distinct_on?: Maybe<Array<Hypertasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hypertasks_Order_By>>;
  where?: Maybe<Hypertasks_Bool_Exp>;
};


export type Query_RootHypertasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Hypertasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hypertasks_Order_By>>;
  where?: Maybe<Hypertasks_Bool_Exp>;
};


export type Query_RootHypertasks_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootInstruction_TranslationsArgs = {
  distinct_on?: Maybe<Array<Instruction_Translations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Instruction_Translations_Order_By>>;
  where?: Maybe<Instruction_Translations_Bool_Exp>;
};


export type Query_RootInstruction_Translations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootIntegration_Test_Expectation_MediaArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Expectation_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Expectation_Media_Order_By>>;
  where?: Maybe<Integration_Test_Expectation_Media_Bool_Exp>;
};


export type Query_RootIntegration_Test_Expectation_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Expectation_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Expectation_Media_Order_By>>;
  where?: Maybe<Integration_Test_Expectation_Media_Bool_Exp>;
};


export type Query_RootIntegration_Test_Expectation_Media_By_PkArgs = {
  expectation_id: Scalars['uuid'];
  media_id: Scalars['uuid'];
};


export type Query_RootIntegration_Test_Expectation_StepArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Expectation_Step_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Expectation_Step_Order_By>>;
  where?: Maybe<Integration_Test_Expectation_Step_Bool_Exp>;
};


export type Query_RootIntegration_Test_Expectation_Step_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Expectation_Step_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Expectation_Step_Order_By>>;
  where?: Maybe<Integration_Test_Expectation_Step_Bool_Exp>;
};


export type Query_RootIntegration_Test_Expectation_Step_By_PkArgs = {
  expectation_id: Scalars['uuid'];
  step_id: Scalars['uuid'];
};


export type Query_RootIntegration_Test_Expectation_TranslationArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Expectation_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Expectation_Translation_Order_By>>;
  where?: Maybe<Integration_Test_Expectation_Translation_Bool_Exp>;
};


export type Query_RootIntegration_Test_Expectation_Translation_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Expectation_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Expectation_Translation_Order_By>>;
  where?: Maybe<Integration_Test_Expectation_Translation_Bool_Exp>;
};


export type Query_RootIntegration_Test_Expectation_Translation_By_PkArgs = {
  expectation_id: Scalars['uuid'];
  language: Scalars['String'];
};


export type Query_RootIntegration_Test_ExpectationsArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Expectations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Expectations_Order_By>>;
  where?: Maybe<Integration_Test_Expectations_Bool_Exp>;
};


export type Query_RootIntegration_Test_Expectations_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Expectations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Expectations_Order_By>>;
  where?: Maybe<Integration_Test_Expectations_Bool_Exp>;
};


export type Query_RootIntegration_Test_Expectations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootIntegration_Test_Instruction_MediaArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Instruction_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Instruction_Media_Order_By>>;
  where?: Maybe<Integration_Test_Instruction_Media_Bool_Exp>;
};


export type Query_RootIntegration_Test_Instruction_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Instruction_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Instruction_Media_Order_By>>;
  where?: Maybe<Integration_Test_Instruction_Media_Bool_Exp>;
};


export type Query_RootIntegration_Test_Instruction_Media_By_PkArgs = {
  instruction_id: Scalars['uuid'];
  media_id: Scalars['uuid'];
};


export type Query_RootIntegration_Test_Instruction_TranslationArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Instruction_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Instruction_Translation_Order_By>>;
  where?: Maybe<Integration_Test_Instruction_Translation_Bool_Exp>;
};


export type Query_RootIntegration_Test_Instruction_Translation_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Instruction_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Instruction_Translation_Order_By>>;
  where?: Maybe<Integration_Test_Instruction_Translation_Bool_Exp>;
};


export type Query_RootIntegration_Test_Instruction_Translation_By_PkArgs = {
  instruction_id: Scalars['uuid'];
  language: Scalars['String'];
};


export type Query_RootIntegration_Test_InstructionsArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Instructions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Instructions_Order_By>>;
  where?: Maybe<Integration_Test_Instructions_Bool_Exp>;
};


export type Query_RootIntegration_Test_Instructions_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Instructions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Instructions_Order_By>>;
  where?: Maybe<Integration_Test_Instructions_Bool_Exp>;
};


export type Query_RootIntegration_Test_Instructions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootIntegration_Test_ObservancesArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Observances_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Observances_Order_By>>;
  where?: Maybe<Integration_Test_Observances_Bool_Exp>;
};


export type Query_RootIntegration_Test_Observances_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Observances_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Observances_Order_By>>;
  where?: Maybe<Integration_Test_Observances_Bool_Exp>;
};


export type Query_RootIntegration_Test_Observances_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootIntegration_Test_ObservationsArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Observations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Observations_Order_By>>;
  where?: Maybe<Integration_Test_Observations_Bool_Exp>;
};


export type Query_RootIntegration_Test_Observations_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Observations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Observations_Order_By>>;
  where?: Maybe<Integration_Test_Observations_Bool_Exp>;
};


export type Query_RootIntegration_Test_Observations_By_PkArgs = {
  expectation_id: Scalars['uuid'];
  observance_id: Scalars['uuid'];
  step_id: Scalars['uuid'];
};


export type Query_RootIntegration_Test_Project_SequenceArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Project_Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Project_Sequence_Order_By>>;
  where?: Maybe<Integration_Test_Project_Sequence_Bool_Exp>;
};


export type Query_RootIntegration_Test_Project_Sequence_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Project_Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Project_Sequence_Order_By>>;
  where?: Maybe<Integration_Test_Project_Sequence_Bool_Exp>;
};


export type Query_RootIntegration_Test_Project_Sequence_By_PkArgs = {
  project_id: Scalars['uuid'];
  sequence_id: Scalars['uuid'];
};


export type Query_RootIntegration_Test_SequencesArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Sequences_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Sequences_Order_By>>;
  where?: Maybe<Integration_Test_Sequences_Bool_Exp>;
};


export type Query_RootIntegration_Test_Sequences_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Sequences_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Sequences_Order_By>>;
  where?: Maybe<Integration_Test_Sequences_Bool_Exp>;
};


export type Query_RootIntegration_Test_Sequences_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootIntegration_Test_StepsArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Steps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Steps_Order_By>>;
  where?: Maybe<Integration_Test_Steps_Bool_Exp>;
};


export type Query_RootIntegration_Test_Steps_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Steps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Steps_Order_By>>;
  where?: Maybe<Integration_Test_Steps_Bool_Exp>;
};


export type Query_RootIntegration_Test_Steps_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLanguagesArgs = {
  distinct_on?: Maybe<Array<Languages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Languages_Order_By>>;
  where?: Maybe<Languages_Bool_Exp>;
};


export type Query_RootLanguages_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootMachine_Learning_BlobsArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Blobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Blobs_Order_By>>;
  where?: Maybe<Machine_Learning_Blobs_Bool_Exp>;
};


export type Query_RootMachine_Learning_Blobs_AggregateArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Blobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Blobs_Order_By>>;
  where?: Maybe<Machine_Learning_Blobs_Bool_Exp>;
};


export type Query_RootMachine_Learning_Blobs_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMachine_Learning_Dataset_FeatureArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Dataset_Feature_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Dataset_Feature_Order_By>>;
  where?: Maybe<Machine_Learning_Dataset_Feature_Bool_Exp>;
};


export type Query_RootMachine_Learning_Dataset_Feature_AggregateArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Dataset_Feature_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Dataset_Feature_Order_By>>;
  where?: Maybe<Machine_Learning_Dataset_Feature_Bool_Exp>;
};


export type Query_RootMachine_Learning_Dataset_Feature_By_PkArgs = {
  dataset_id: Scalars['uuid'];
  feature_id: Scalars['uuid'];
};


export type Query_RootMachine_Learning_DatasetsArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Datasets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Datasets_Order_By>>;
  where?: Maybe<Machine_Learning_Datasets_Bool_Exp>;
};


export type Query_RootMachine_Learning_Datasets_AggregateArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Datasets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Datasets_Order_By>>;
  where?: Maybe<Machine_Learning_Datasets_Bool_Exp>;
};


export type Query_RootMachine_Learning_Datasets_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMachine_Learning_FeaturesArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Features_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Features_Order_By>>;
  where?: Maybe<Machine_Learning_Features_Bool_Exp>;
};


export type Query_RootMachine_Learning_Features_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMachine_Learning_Model_BlobArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Model_Blob_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Model_Blob_Order_By>>;
  where?: Maybe<Machine_Learning_Model_Blob_Bool_Exp>;
};


export type Query_RootMachine_Learning_Model_Blob_By_PkArgs = {
  blob_id: Scalars['uuid'];
  model_id: Scalars['uuid'];
};


export type Query_RootMachine_Learning_Model_DatasetArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Model_Dataset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Model_Dataset_Order_By>>;
  where?: Maybe<Machine_Learning_Model_Dataset_Bool_Exp>;
};


export type Query_RootMachine_Learning_Model_Dataset_By_PkArgs = {
  dataset_id: Scalars['uuid'];
  model_id: Scalars['uuid'];
};


export type Query_RootMachine_Learning_Model_RecordingArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Model_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Model_Recording_Order_By>>;
  where?: Maybe<Machine_Learning_Model_Recording_Bool_Exp>;
};


export type Query_RootMachine_Learning_Model_Recording_AggregateArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Model_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Model_Recording_Order_By>>;
  where?: Maybe<Machine_Learning_Model_Recording_Bool_Exp>;
};


export type Query_RootMachine_Learning_Model_Recording_By_PkArgs = {
  model_id: Scalars['uuid'];
  recording_id: Scalars['uuid'];
};


export type Query_RootMachine_Learning_Model_TypesArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Model_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Model_Types_Order_By>>;
  where?: Maybe<Machine_Learning_Model_Types_Bool_Exp>;
};


export type Query_RootMachine_Learning_Model_Types_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootMachine_Learning_ModelsArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Models_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Models_Order_By>>;
  where?: Maybe<Machine_Learning_Models_Bool_Exp>;
};


export type Query_RootMachine_Learning_Models_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMediaArgs = {
  distinct_on?: Maybe<Array<Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Media_Order_By>>;
  where?: Maybe<Media_Bool_Exp>;
};


export type Query_RootMedia_AggregateArgs = {
  distinct_on?: Maybe<Array<Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Media_Order_By>>;
  where?: Maybe<Media_Bool_Exp>;
};


export type Query_RootMedia_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMedia_TypeArgs = {
  distinct_on?: Maybe<Array<Media_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Media_Type_Order_By>>;
  where?: Maybe<Media_Type_Bool_Exp>;
};


export type Query_RootMedia_Type_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootMode_ProjectArgs = {
  distinct_on?: Maybe<Array<Mode_Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mode_Project_Order_By>>;
  where?: Maybe<Mode_Project_Bool_Exp>;
};


export type Query_RootMode_Project_AggregateArgs = {
  distinct_on?: Maybe<Array<Mode_Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mode_Project_Order_By>>;
  where?: Maybe<Mode_Project_Bool_Exp>;
};


export type Query_RootMode_Project_By_PkArgs = {
  mode_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};


export type Query_RootModel_Name_HashesArgs = {
  distinct_on?: Maybe<Array<Model_Name_Hashes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Model_Name_Hashes_Order_By>>;
  where?: Maybe<Model_Name_Hashes_Bool_Exp>;
};


export type Query_RootModel_Name_Hashes_AggregateArgs = {
  distinct_on?: Maybe<Array<Model_Name_Hashes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Model_Name_Hashes_Order_By>>;
  where?: Maybe<Model_Name_Hashes_Bool_Exp>;
};


export type Query_RootModel_Name_Hashes_By_PkArgs = {
  hash: Scalars['String'];
  model_id: Scalars['uuid'];
};


export type Query_RootModelsArgs = {
  distinct_on?: Maybe<Array<Models_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Models_Order_By>>;
  where?: Maybe<Models_Bool_Exp>;
};


export type Query_RootModels_AggregateArgs = {
  distinct_on?: Maybe<Array<Models_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Models_Order_By>>;
  where?: Maybe<Models_Bool_Exp>;
};


export type Query_RootModels_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOpmode_ProfileArgs = {
  distinct_on?: Maybe<Array<Opmode_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Opmode_Profile_Order_By>>;
  where?: Maybe<Opmode_Profile_Bool_Exp>;
};


export type Query_RootOpmode_Profile_By_PkArgs = {
  opmode_id: Scalars['uuid'];
  profile_id: Scalars['uuid'];
};


export type Query_RootOpmode_ProjectArgs = {
  distinct_on?: Maybe<Array<Opmode_Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Opmode_Project_Order_By>>;
  where?: Maybe<Opmode_Project_Bool_Exp>;
};


export type Query_RootOpmode_Project_AggregateArgs = {
  distinct_on?: Maybe<Array<Opmode_Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Opmode_Project_Order_By>>;
  where?: Maybe<Opmode_Project_Bool_Exp>;
};


export type Query_RootOpmode_Project_By_PkArgs = {
  opmode_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};


export type Query_RootOpmodesArgs = {
  distinct_on?: Maybe<Array<Opmodes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Opmodes_Order_By>>;
  where?: Maybe<Opmodes_Bool_Exp>;
};


export type Query_RootOpmodes_AggregateArgs = {
  distinct_on?: Maybe<Array<Opmodes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Opmodes_Order_By>>;
  where?: Maybe<Opmodes_Bool_Exp>;
};


export type Query_RootOpmodes_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOption_MediaArgs = {
  distinct_on?: Maybe<Array<Option_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Option_Media_Order_By>>;
  where?: Maybe<Option_Media_Bool_Exp>;
};


export type Query_RootOption_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Option_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Option_Media_Order_By>>;
  where?: Maybe<Option_Media_Bool_Exp>;
};


export type Query_RootOption_Media_By_PkArgs = {
  media_id: Scalars['uuid'];
  option_id: Scalars['uuid'];
};


export type Query_RootOption_TranslationArgs = {
  distinct_on?: Maybe<Array<Option_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Option_Translation_Order_By>>;
  where?: Maybe<Option_Translation_Bool_Exp>;
};


export type Query_RootOption_Translation_AggregateArgs = {
  distinct_on?: Maybe<Array<Option_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Option_Translation_Order_By>>;
  where?: Maybe<Option_Translation_Bool_Exp>;
};


export type Query_RootOption_Translation_By_PkArgs = {
  language: Scalars['String'];
  option_id: Scalars['uuid'];
};


export type Query_RootOptionsArgs = {
  distinct_on?: Maybe<Array<Options_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Options_Order_By>>;
  where?: Maybe<Options_Bool_Exp>;
};


export type Query_RootOptions_AggregateArgs = {
  distinct_on?: Maybe<Array<Options_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Options_Order_By>>;
  where?: Maybe<Options_Bool_Exp>;
};


export type Query_RootOptions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPayload_RecordingArgs = {
  distinct_on?: Maybe<Array<Payload_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payload_Recording_Order_By>>;
  where?: Maybe<Payload_Recording_Bool_Exp>;
};


export type Query_RootPayload_Recording_AggregateArgs = {
  distinct_on?: Maybe<Array<Payload_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payload_Recording_Order_By>>;
  where?: Maybe<Payload_Recording_Bool_Exp>;
};


export type Query_RootPayload_Recording_By_PkArgs = {
  payload_id: Scalars['uuid'];
  recording_id: Scalars['uuid'];
};


export type Query_RootPayload_TypesArgs = {
  distinct_on?: Maybe<Array<Payload_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payload_Types_Order_By>>;
  where?: Maybe<Payload_Types_Bool_Exp>;
};


export type Query_RootPayload_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Payload_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payload_Types_Order_By>>;
  where?: Maybe<Payload_Types_Bool_Exp>;
};


export type Query_RootPayload_Types_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootPayloadsArgs = {
  distinct_on?: Maybe<Array<Payloads_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payloads_Order_By>>;
  where?: Maybe<Payloads_Bool_Exp>;
};


export type Query_RootPayloads_AggregateArgs = {
  distinct_on?: Maybe<Array<Payloads_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payloads_Order_By>>;
  where?: Maybe<Payloads_Bool_Exp>;
};


export type Query_RootPayloads_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPerformance_Ratio_Metric_ContributionsArgs = {
  distinct_on?: Maybe<Array<Performance_Ratio_Metric_Contributions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Ratio_Metric_Contributions_Order_By>>;
  where?: Maybe<Performance_Ratio_Metric_Contributions_Bool_Exp>;
};


export type Query_RootPerformance_Ratio_Metric_Contributions_AggregateArgs = {
  distinct_on?: Maybe<Array<Performance_Ratio_Metric_Contributions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Ratio_Metric_Contributions_Order_By>>;
  where?: Maybe<Performance_Ratio_Metric_Contributions_Bool_Exp>;
};


export type Query_RootPerformance_Ratio_Metric_Contributions_By_PkArgs = {
  engine_id: Scalars['uuid'];
  ratio_metric_id: Scalars['uuid'];
  recording_id: Scalars['uuid'];
};


export type Query_RootPerformance_Ratio_MetricsArgs = {
  distinct_on?: Maybe<Array<Performance_Ratio_Metrics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Ratio_Metrics_Order_By>>;
  where?: Maybe<Performance_Ratio_Metrics_Bool_Exp>;
};


export type Query_RootPerformance_Ratio_Metrics_AggregateArgs = {
  distinct_on?: Maybe<Array<Performance_Ratio_Metrics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Ratio_Metrics_Order_By>>;
  where?: Maybe<Performance_Ratio_Metrics_Bool_Exp>;
};


export type Query_RootPerformance_Ratio_Metrics_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPerformance_Report_Deliverable_TemplateArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Deliverable_Template_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Deliverable_Template_Order_By>>;
  where?: Maybe<Performance_Report_Deliverable_Template_Bool_Exp>;
};


export type Query_RootPerformance_Report_Deliverable_Template_AggregateArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Deliverable_Template_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Deliverable_Template_Order_By>>;
  where?: Maybe<Performance_Report_Deliverable_Template_Bool_Exp>;
};


export type Query_RootPerformance_Report_Deliverable_Template_By_PkArgs = {
  deliverable_id: Scalars['uuid'];
  template_id: Scalars['uuid'];
};


export type Query_RootPerformance_Report_Pivot_TypesArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Pivot_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Pivot_Types_Order_By>>;
  where?: Maybe<Performance_Report_Pivot_Types_Bool_Exp>;
};


export type Query_RootPerformance_Report_Pivot_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Pivot_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Pivot_Types_Order_By>>;
  where?: Maybe<Performance_Report_Pivot_Types_Bool_Exp>;
};


export type Query_RootPerformance_Report_Pivot_Types_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootPerformance_Report_Section_Pivot_FieldArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Section_Pivot_Field_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Section_Pivot_Field_Order_By>>;
  where?: Maybe<Performance_Report_Section_Pivot_Field_Bool_Exp>;
};


export type Query_RootPerformance_Report_Section_Pivot_Field_AggregateArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Section_Pivot_Field_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Section_Pivot_Field_Order_By>>;
  where?: Maybe<Performance_Report_Section_Pivot_Field_Bool_Exp>;
};


export type Query_RootPerformance_Report_Section_Pivot_Field_By_PkArgs = {
  field_id: Scalars['uuid'];
  section_id: Scalars['uuid'];
};


export type Query_RootPerformance_Report_Section_Ratio_MetricArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Section_Ratio_Metric_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Section_Ratio_Metric_Order_By>>;
  where?: Maybe<Performance_Report_Section_Ratio_Metric_Bool_Exp>;
};


export type Query_RootPerformance_Report_Section_Ratio_Metric_AggregateArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Section_Ratio_Metric_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Section_Ratio_Metric_Order_By>>;
  where?: Maybe<Performance_Report_Section_Ratio_Metric_Bool_Exp>;
};


export type Query_RootPerformance_Report_Section_Ratio_Metric_By_PkArgs = {
  ratio_metric_id: Scalars['uuid'];
  section_id: Scalars['uuid'];
};


export type Query_RootPerformance_Report_Section_Task_BlacklistArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Section_Task_Blacklist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Section_Task_Blacklist_Order_By>>;
  where?: Maybe<Performance_Report_Section_Task_Blacklist_Bool_Exp>;
};


export type Query_RootPerformance_Report_Section_Task_Blacklist_AggregateArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Section_Task_Blacklist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Section_Task_Blacklist_Order_By>>;
  where?: Maybe<Performance_Report_Section_Task_Blacklist_Bool_Exp>;
};


export type Query_RootPerformance_Report_Section_Task_Blacklist_By_PkArgs = {
  section_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};


export type Query_RootPerformance_Report_SectionsArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Sections_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Sections_Order_By>>;
  where?: Maybe<Performance_Report_Sections_Bool_Exp>;
};


export type Query_RootPerformance_Report_Sections_AggregateArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Sections_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Sections_Order_By>>;
  where?: Maybe<Performance_Report_Sections_Bool_Exp>;
};


export type Query_RootPerformance_Report_Sections_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPerformance_Report_TemplatesArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Templates_Order_By>>;
  where?: Maybe<Performance_Report_Templates_Bool_Exp>;
};


export type Query_RootPerformance_Report_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Templates_Order_By>>;
  where?: Maybe<Performance_Report_Templates_Bool_Exp>;
};


export type Query_RootPerformance_Report_Templates_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPeripheralsArgs = {
  distinct_on?: Maybe<Array<Peripherals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Peripherals_Order_By>>;
  where?: Maybe<Peripherals_Bool_Exp>;
};


export type Query_RootPeripherals_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPlan_HypertasksArgs = {
  distinct_on?: Maybe<Array<Plan_Hypertasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plan_Hypertasks_Order_By>>;
  where?: Maybe<Plan_Hypertasks_Bool_Exp>;
};


export type Query_RootPlan_Hypertasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Plan_Hypertasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plan_Hypertasks_Order_By>>;
  where?: Maybe<Plan_Hypertasks_Bool_Exp>;
};


export type Query_RootPlan_Hypertasks_By_PkArgs = {
  hypertask_id: Scalars['uuid'];
  plan_id: Scalars['uuid'];
};


export type Query_RootPlan_TasksArgs = {
  distinct_on?: Maybe<Array<Plan_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plan_Tasks_Order_By>>;
  where?: Maybe<Plan_Tasks_Bool_Exp>;
};


export type Query_RootPlan_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Plan_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plan_Tasks_Order_By>>;
  where?: Maybe<Plan_Tasks_Bool_Exp>;
};


export type Query_RootPlan_Tasks_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPlansArgs = {
  distinct_on?: Maybe<Array<Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plans_Order_By>>;
  where?: Maybe<Plans_Bool_Exp>;
};


export type Query_RootPlans_AggregateArgs = {
  distinct_on?: Maybe<Array<Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plans_Order_By>>;
  where?: Maybe<Plans_Bool_Exp>;
};


export type Query_RootPlans_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProfilesArgs = {
  distinct_on?: Maybe<Array<Profiles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profiles_Order_By>>;
  where?: Maybe<Profiles_Bool_Exp>;
};


export type Query_RootProfiles_AggregateArgs = {
  distinct_on?: Maybe<Array<Profiles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profiles_Order_By>>;
  where?: Maybe<Profiles_Bool_Exp>;
};


export type Query_RootProfiles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProject_ComboArgs = {
  distinct_on?: Maybe<Array<Project_Combo_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Combo_Order_By>>;
  where?: Maybe<Project_Combo_Bool_Exp>;
};


export type Query_RootProject_Combo_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Combo_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Combo_Order_By>>;
  where?: Maybe<Project_Combo_Bool_Exp>;
};


export type Query_RootProject_Combo_By_PkArgs = {
  other_project_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};


export type Query_RootProject_DatasetArgs = {
  distinct_on?: Maybe<Array<Project_Dataset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Dataset_Order_By>>;
  where?: Maybe<Project_Dataset_Bool_Exp>;
};


export type Query_RootProject_Dataset_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Dataset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Dataset_Order_By>>;
  where?: Maybe<Project_Dataset_Bool_Exp>;
};


export type Query_RootProject_Engine_MetricsArgs = {
  args: Project_Engine_Metrics_Args;
  distinct_on?: Maybe<Array<Schema_Project_Engine_Metrics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Project_Engine_Metrics_Order_By>>;
  where?: Maybe<Schema_Project_Engine_Metrics_Bool_Exp>;
};


export type Query_RootProject_Engine_Ratio_MetricsArgs = {
  args: Project_Engine_Ratio_Metrics_Args;
  distinct_on?: Maybe<Array<Schema_Project_Engine_Ratio_Metrics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Project_Engine_Ratio_Metrics_Order_By>>;
  where?: Maybe<Schema_Project_Engine_Ratio_Metrics_Bool_Exp>;
};


export type Query_RootProject_Engine_Ratio_Metrics_AggregateArgs = {
  args: Project_Engine_Ratio_Metrics_Args;
  distinct_on?: Maybe<Array<Schema_Project_Engine_Ratio_Metrics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Project_Engine_Ratio_Metrics_Order_By>>;
  where?: Maybe<Schema_Project_Engine_Ratio_Metrics_Bool_Exp>;
};


export type Query_RootProject_FieldsArgs = {
  args: Project_Fields_Args;
  distinct_on?: Maybe<Array<Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fields_Order_By>>;
  where?: Maybe<Fields_Bool_Exp>;
};


export type Query_RootProject_Fields_AggregateArgs = {
  args: Project_Fields_Args;
  distinct_on?: Maybe<Array<Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fields_Order_By>>;
  where?: Maybe<Fields_Bool_Exp>;
};


export type Query_RootProject_HypertasksArgs = {
  distinct_on?: Maybe<Array<Project_Hypertasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Hypertasks_Order_By>>;
  where?: Maybe<Project_Hypertasks_Bool_Exp>;
};


export type Query_RootProject_Hypertasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Hypertasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Hypertasks_Order_By>>;
  where?: Maybe<Project_Hypertasks_Bool_Exp>;
};


export type Query_RootProject_Hypertasks_By_PkArgs = {
  hypertask_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};


export type Query_RootProject_MetricsArgs = {
  distinct_on?: Maybe<Array<Project_Metrics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Metrics_Order_By>>;
  where?: Maybe<Project_Metrics_Bool_Exp>;
};


export type Query_RootProject_Metrics_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Metrics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Metrics_Order_By>>;
  where?: Maybe<Project_Metrics_Bool_Exp>;
};


export type Query_RootProject_NameArgs = {
  distinct_on?: Maybe<Array<Project_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Name_Order_By>>;
  where?: Maybe<Project_Name_Bool_Exp>;
};


export type Query_RootProject_Rabbit_Test_InstancesArgs = {
  distinct_on?: Maybe<Array<Project_Rabbit_Test_Instances_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Rabbit_Test_Instances_Order_By>>;
  where?: Maybe<Project_Rabbit_Test_Instances_Bool_Exp>;
};


export type Query_RootProject_Rabbit_Test_Instances_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Rabbit_Test_Instances_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Rabbit_Test_Instances_Order_By>>;
  where?: Maybe<Project_Rabbit_Test_Instances_Bool_Exp>;
};


export type Query_RootProject_Recordings_CleanArgs = {
  args: Project_Recordings_Clean_Args;
  distinct_on?: Maybe<Array<Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recordings_Order_By>>;
  where?: Maybe<Recordings_Bool_Exp>;
};


export type Query_RootProject_Recordings_Clean_AggregateArgs = {
  args: Project_Recordings_Clean_Args;
  distinct_on?: Maybe<Array<Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recordings_Order_By>>;
  where?: Maybe<Recordings_Bool_Exp>;
};


export type Query_RootProject_Recordings_UnreviewedArgs = {
  args: Project_Recordings_Unreviewed_Args;
  distinct_on?: Maybe<Array<Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recordings_Order_By>>;
  where?: Maybe<Recordings_Bool_Exp>;
};


export type Query_RootProject_Recordings_Unreviewed_AggregateArgs = {
  args: Project_Recordings_Unreviewed_Args;
  distinct_on?: Maybe<Array<Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recordings_Order_By>>;
  where?: Maybe<Recordings_Bool_Exp>;
};


export type Query_RootProject_Task_CountArgs = {
  args: Project_Task_Count_Args;
  distinct_on?: Maybe<Array<Schema_Project_Task_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Project_Task_Count_Order_By>>;
  where?: Maybe<Schema_Project_Task_Count_Bool_Exp>;
};


export type Query_RootProject_Task_Count_AggregateArgs = {
  args: Project_Task_Count_Args;
  distinct_on?: Maybe<Array<Schema_Project_Task_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Project_Task_Count_Order_By>>;
  where?: Maybe<Schema_Project_Task_Count_Bool_Exp>;
};


export type Query_RootProject_Task_Count_By_DeviceArgs = {
  args: Project_Task_Count_By_Device_Args;
  distinct_on?: Maybe<Array<Schema_Project_Task_Count_By_Device_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Project_Task_Count_By_Device_Order_By>>;
  where?: Maybe<Schema_Project_Task_Count_By_Device_Bool_Exp>;
};


export type Query_RootProject_Task_Count_By_Device_AggregateArgs = {
  args: Project_Task_Count_By_Device_Args;
  distinct_on?: Maybe<Array<Schema_Project_Task_Count_By_Device_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Project_Task_Count_By_Device_Order_By>>;
  where?: Maybe<Schema_Project_Task_Count_By_Device_Bool_Exp>;
};


export type Query_RootProject_TasksArgs = {
  distinct_on?: Maybe<Array<Project_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Tasks_Order_By>>;
  where?: Maybe<Project_Tasks_Bool_Exp>;
};


export type Query_RootProject_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Tasks_Order_By>>;
  where?: Maybe<Project_Tasks_Bool_Exp>;
};


export type Query_RootProject_Tasks_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProject_UserArgs = {
  distinct_on?: Maybe<Array<Project_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_User_Order_By>>;
  where?: Maybe<Project_User_Bool_Exp>;
};


export type Query_RootProject_User_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_User_Order_By>>;
  where?: Maybe<Project_User_Bool_Exp>;
};


export type Query_RootProject_User_By_PkArgs = {
  project_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Query_RootProjectsArgs = {
  distinct_on?: Maybe<Array<Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Projects_Order_By>>;
  where?: Maybe<Projects_Bool_Exp>;
};


export type Query_RootProjects_AggregateArgs = {
  distinct_on?: Maybe<Array<Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Projects_Order_By>>;
  where?: Maybe<Projects_Bool_Exp>;
};


export type Query_RootProjects_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRabbit_Test_Plan_ProjectArgs = {
  distinct_on?: Maybe<Array<Rabbit_Test_Plan_Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rabbit_Test_Plan_Project_Order_By>>;
  where?: Maybe<Rabbit_Test_Plan_Project_Bool_Exp>;
};


export type Query_RootRabbit_Test_Plan_Project_AggregateArgs = {
  distinct_on?: Maybe<Array<Rabbit_Test_Plan_Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rabbit_Test_Plan_Project_Order_By>>;
  where?: Maybe<Rabbit_Test_Plan_Project_Bool_Exp>;
};


export type Query_RootRabbit_Test_Plan_Project_By_PkArgs = {
  plan_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};


export type Query_RootRabbit_Test_Plan_RecordingArgs = {
  distinct_on?: Maybe<Array<Rabbit_Test_Plan_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rabbit_Test_Plan_Recording_Order_By>>;
  where?: Maybe<Rabbit_Test_Plan_Recording_Bool_Exp>;
};


export type Query_RootRabbit_Test_Plan_Recording_AggregateArgs = {
  distinct_on?: Maybe<Array<Rabbit_Test_Plan_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rabbit_Test_Plan_Recording_Order_By>>;
  where?: Maybe<Rabbit_Test_Plan_Recording_Bool_Exp>;
};


export type Query_RootRabbit_Test_Plan_Recording_By_PkArgs = {
  recording_id: Scalars['uuid'];
};


export type Query_RootRabbit_Test_Plan_TaskArgs = {
  distinct_on?: Maybe<Array<Rabbit_Test_Plan_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rabbit_Test_Plan_Task_Order_By>>;
  where?: Maybe<Rabbit_Test_Plan_Task_Bool_Exp>;
};


export type Query_RootRabbit_Test_Plan_Task_AggregateArgs = {
  distinct_on?: Maybe<Array<Rabbit_Test_Plan_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rabbit_Test_Plan_Task_Order_By>>;
  where?: Maybe<Rabbit_Test_Plan_Task_Bool_Exp>;
};


export type Query_RootRabbit_Test_Plan_Task_By_PkArgs = {
  plan_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};


export type Query_RootRabbit_Test_PlansArgs = {
  distinct_on?: Maybe<Array<Rabbit_Test_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rabbit_Test_Plans_Order_By>>;
  where?: Maybe<Rabbit_Test_Plans_Bool_Exp>;
};


export type Query_RootRabbit_Test_Plans_AggregateArgs = {
  distinct_on?: Maybe<Array<Rabbit_Test_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rabbit_Test_Plans_Order_By>>;
  where?: Maybe<Rabbit_Test_Plans_Bool_Exp>;
};


export type Query_RootRabbit_Test_Plans_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRecording_AppsArgs = {
  distinct_on?: Maybe<Array<Recording_Apps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Apps_Order_By>>;
  where?: Maybe<Recording_Apps_Bool_Exp>;
};


export type Query_RootRecording_Apps_AggregateArgs = {
  distinct_on?: Maybe<Array<Recording_Apps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Apps_Order_By>>;
  where?: Maybe<Recording_Apps_Bool_Exp>;
};


export type Query_RootRecording_Apps_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRecording_Audio_DriverArgs = {
  distinct_on?: Maybe<Array<Recording_Audio_Driver_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Audio_Driver_Order_By>>;
  where?: Maybe<Recording_Audio_Driver_Bool_Exp>;
};


export type Query_RootRecording_Audio_Driver_By_PkArgs = {
  audio_driver_id: Scalars['uuid'];
  recording_id: Scalars['uuid'];
};


export type Query_RootRecording_Data_Ground_TruthArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Ground_Truth_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Ground_Truth_Order_By>>;
  where?: Maybe<Recording_Data_Ground_Truth_Bool_Exp>;
};


export type Query_RootRecording_Data_Ground_Truth_AggregateArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Ground_Truth_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Ground_Truth_Order_By>>;
  where?: Maybe<Recording_Data_Ground_Truth_Bool_Exp>;
};


export type Query_RootRecording_Data_Ground_Truth_By_PkArgs = {
  recording_id: Scalars['uuid'];
};


export type Query_RootRecording_Data_LabelsArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Labels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Labels_Order_By>>;
  where?: Maybe<Recording_Data_Labels_Bool_Exp>;
};


export type Query_RootRecording_Data_Labels_AggregateArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Labels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Labels_Order_By>>;
  where?: Maybe<Recording_Data_Labels_Bool_Exp>;
};


export type Query_RootRecording_Data_Labels_By_PkArgs = {
  mode_id: Scalars['uuid'];
  recording_id: Scalars['uuid'];
};


export type Query_RootRecording_Data_Mode_ParameterArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Mode_Parameter_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Mode_Parameter_Order_By>>;
  where?: Maybe<Recording_Data_Mode_Parameter_Bool_Exp>;
};


export type Query_RootRecording_Data_Mode_Parameter_AggregateArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Mode_Parameter_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Mode_Parameter_Order_By>>;
  where?: Maybe<Recording_Data_Mode_Parameter_Bool_Exp>;
};


export type Query_RootRecording_Data_Mode_Parameter_By_PkArgs = {
  mode_id: Scalars['uuid'];
  parameter_id: Scalars['uuid'];
};


export type Query_RootRecording_Data_ModesArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Modes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Modes_Order_By>>;
  where?: Maybe<Recording_Data_Modes_Bool_Exp>;
};


export type Query_RootRecording_Data_Modes_AggregateArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Modes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Modes_Order_By>>;
  where?: Maybe<Recording_Data_Modes_Bool_Exp>;
};


export type Query_RootRecording_Data_Modes_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRecording_Data_ParametersArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Parameters_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Parameters_Order_By>>;
  where?: Maybe<Recording_Data_Parameters_Bool_Exp>;
};


export type Query_RootRecording_Data_Parameters_AggregateArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Parameters_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Parameters_Order_By>>;
  where?: Maybe<Recording_Data_Parameters_Bool_Exp>;
};


export type Query_RootRecording_Data_Parameters_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRecording_Data_Payload_PairArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Payload_Pair_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Payload_Pair_Order_By>>;
  where?: Maybe<Recording_Data_Payload_Pair_Bool_Exp>;
};


export type Query_RootRecording_Data_Payload_Pair_By_PkArgs = {
  payload_id: Scalars['uuid'];
};


export type Query_RootRecording_Data_PredictionsArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Predictions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Predictions_Order_By>>;
  where?: Maybe<Recording_Data_Predictions_Bool_Exp>;
};


export type Query_RootRecording_Data_Predictions_AggregateArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Predictions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Predictions_Order_By>>;
  where?: Maybe<Recording_Data_Predictions_Bool_Exp>;
};


export type Query_RootRecording_Data_Predictions_By_PkArgs = {
  engine_id: Scalars['uuid'];
  recording_id: Scalars['uuid'];
};


export type Query_RootRecording_Recording_TagArgs = {
  distinct_on?: Maybe<Array<Recording_Recording_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Recording_Tag_Order_By>>;
  where?: Maybe<Recording_Recording_Tag_Bool_Exp>;
};


export type Query_RootRecording_Recording_Tag_By_PkArgs = {
  recording_id: Scalars['uuid'];
  recording_tag_id: Scalars['uuid'];
};


export type Query_RootRecording_Tag_Count_By_DayArgs = {
  args: Recording_Tag_Count_By_Day_Args;
  distinct_on?: Maybe<Array<Schema_Recording_Tag_Count_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Recording_Tag_Count_By_Day_Order_By>>;
  where?: Maybe<Schema_Recording_Tag_Count_By_Day_Bool_Exp>;
};


export type Query_RootRecording_Tag_Count_By_Day_AggregateArgs = {
  args: Recording_Tag_Count_By_Day_Args;
  distinct_on?: Maybe<Array<Schema_Recording_Tag_Count_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Recording_Tag_Count_By_Day_Order_By>>;
  where?: Maybe<Schema_Recording_Tag_Count_By_Day_Bool_Exp>;
};


export type Query_RootRecording_TagsArgs = {
  distinct_on?: Maybe<Array<Recording_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Tags_Order_By>>;
  where?: Maybe<Recording_Tags_Bool_Exp>;
};


export type Query_RootRecording_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRecordingsArgs = {
  distinct_on?: Maybe<Array<Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recordings_Order_By>>;
  where?: Maybe<Recordings_Bool_Exp>;
};


export type Query_RootRecordings_AggregateArgs = {
  distinct_on?: Maybe<Array<Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recordings_Order_By>>;
  where?: Maybe<Recordings_Bool_Exp>;
};


export type Query_RootRecordings_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRole_UserArgs = {
  distinct_on?: Maybe<Array<Role_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_User_Order_By>>;
  where?: Maybe<Role_User_Bool_Exp>;
};


export type Query_RootRole_User_AggregateArgs = {
  distinct_on?: Maybe<Array<Role_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_User_Order_By>>;
  where?: Maybe<Role_User_Bool_Exp>;
};


export type Query_RootRole_User_By_PkArgs = {
  role_id: Roles_Enum;
  user_id: Scalars['uuid'];
};


export type Query_RootRolesArgs = {
  distinct_on?: Maybe<Array<Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Roles_Order_By>>;
  where?: Maybe<Roles_Bool_Exp>;
};


export type Query_RootRoles_AggregateArgs = {
  distinct_on?: Maybe<Array<Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Roles_Order_By>>;
  where?: Maybe<Roles_Bool_Exp>;
};


export type Query_RootRoles_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRoomsArgs = {
  distinct_on?: Maybe<Array<Rooms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rooms_Order_By>>;
  where?: Maybe<Rooms_Bool_Exp>;
};


export type Query_RootRooms_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSchema_Project_Engine_MetricsArgs = {
  distinct_on?: Maybe<Array<Schema_Project_Engine_Metrics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Project_Engine_Metrics_Order_By>>;
  where?: Maybe<Schema_Project_Engine_Metrics_Bool_Exp>;
};


export type Query_RootSchema_Project_Engine_Ratio_MetricsArgs = {
  distinct_on?: Maybe<Array<Schema_Project_Engine_Ratio_Metrics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Project_Engine_Ratio_Metrics_Order_By>>;
  where?: Maybe<Schema_Project_Engine_Ratio_Metrics_Bool_Exp>;
};


export type Query_RootSchema_Project_Engine_Ratio_Metrics_AggregateArgs = {
  distinct_on?: Maybe<Array<Schema_Project_Engine_Ratio_Metrics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Project_Engine_Ratio_Metrics_Order_By>>;
  where?: Maybe<Schema_Project_Engine_Ratio_Metrics_Bool_Exp>;
};


export type Query_RootSchema_Project_Engine_Ratio_Metrics_By_PkArgs = {
  engine_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
  ratio_metric_id: Scalars['uuid'];
};


export type Query_RootSchema_Project_Task_CountArgs = {
  distinct_on?: Maybe<Array<Schema_Project_Task_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Project_Task_Count_Order_By>>;
  where?: Maybe<Schema_Project_Task_Count_Bool_Exp>;
};


export type Query_RootSchema_Project_Task_Count_AggregateArgs = {
  distinct_on?: Maybe<Array<Schema_Project_Task_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Project_Task_Count_Order_By>>;
  where?: Maybe<Schema_Project_Task_Count_Bool_Exp>;
};


export type Query_RootSchema_Project_Task_Count_By_DeviceArgs = {
  distinct_on?: Maybe<Array<Schema_Project_Task_Count_By_Device_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Project_Task_Count_By_Device_Order_By>>;
  where?: Maybe<Schema_Project_Task_Count_By_Device_Bool_Exp>;
};


export type Query_RootSchema_Project_Task_Count_By_Device_AggregateArgs = {
  distinct_on?: Maybe<Array<Schema_Project_Task_Count_By_Device_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Project_Task_Count_By_Device_Order_By>>;
  where?: Maybe<Schema_Project_Task_Count_By_Device_Bool_Exp>;
};


export type Query_RootSchema_Project_Task_Count_By_Device_By_PkArgs = {
  device_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};


export type Query_RootSchema_Project_Task_Count_By_PkArgs = {
  project_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};


export type Query_RootSchema_Recording_Tag_Count_By_DayArgs = {
  distinct_on?: Maybe<Array<Schema_Recording_Tag_Count_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Recording_Tag_Count_By_Day_Order_By>>;
  where?: Maybe<Schema_Recording_Tag_Count_By_Day_Bool_Exp>;
};


export type Query_RootSchema_Recording_Tag_Count_By_Day_AggregateArgs = {
  distinct_on?: Maybe<Array<Schema_Recording_Tag_Count_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Recording_Tag_Count_By_Day_Order_By>>;
  where?: Maybe<Schema_Recording_Tag_Count_By_Day_Bool_Exp>;
};


export type Query_RootSchema_Recording_Tag_Count_By_Day_By_PkArgs = {
  day: Scalars['date'];
  project_id: Scalars['uuid'];
  tag_id: Scalars['uuid'];
};


export type Query_RootSimulations_ScoresArgs = {
  distinct_on?: Maybe<Array<Simulations_Scores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Simulations_Scores_Order_By>>;
  where?: Maybe<Simulations_Scores_Bool_Exp>;
};


export type Query_RootSimulations_Scores_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTask_Field_OptionArgs = {
  distinct_on?: Maybe<Array<Task_Field_Option_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Field_Option_Order_By>>;
  where?: Maybe<Task_Field_Option_Bool_Exp>;
};


export type Query_RootTask_Field_Option_AggregateArgs = {
  distinct_on?: Maybe<Array<Task_Field_Option_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Field_Option_Order_By>>;
  where?: Maybe<Task_Field_Option_Bool_Exp>;
};


export type Query_RootTask_Field_Option_By_PkArgs = {
  field_option_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};


export type Query_RootTask_Field_Option_HashesArgs = {
  distinct_on?: Maybe<Array<Task_Field_Option_Hashes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Field_Option_Hashes_Order_By>>;
  where?: Maybe<Task_Field_Option_Hashes_Bool_Exp>;
};


export type Query_RootTask_Field_Option_Hashes_AggregateArgs = {
  distinct_on?: Maybe<Array<Task_Field_Option_Hashes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Field_Option_Hashes_Order_By>>;
  where?: Maybe<Task_Field_Option_Hashes_Bool_Exp>;
};


export type Query_RootTask_Groups_Group_FieldsArgs = {
  distinct_on?: Maybe<Array<Task_Groups_Group_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Groups_Group_Fields_Order_By>>;
  where?: Maybe<Task_Groups_Group_Fields_Bool_Exp>;
};


export type Query_RootTask_Groups_Group_Fields_AggregateArgs = {
  distinct_on?: Maybe<Array<Task_Groups_Group_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Groups_Group_Fields_Order_By>>;
  where?: Maybe<Task_Groups_Group_Fields_Bool_Exp>;
};


export type Query_RootTask_Groups_Group_Fields_By_PkArgs = {
  field_id: Scalars['uuid'];
  group_id: Scalars['uuid'];
};


export type Query_RootTask_Groups_GroupsArgs = {
  distinct_on?: Maybe<Array<Task_Groups_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Groups_Groups_Order_By>>;
  where?: Maybe<Task_Groups_Groups_Bool_Exp>;
};


export type Query_RootTask_Groups_Groups_AggregateArgs = {
  distinct_on?: Maybe<Array<Task_Groups_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Groups_Groups_Order_By>>;
  where?: Maybe<Task_Groups_Groups_Bool_Exp>;
};


export type Query_RootTask_Groups_Groups_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTask_Groups_HierarchiesArgs = {
  distinct_on?: Maybe<Array<Task_Groups_Hierarchies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Groups_Hierarchies_Order_By>>;
  where?: Maybe<Task_Groups_Hierarchies_Bool_Exp>;
};


export type Query_RootTask_Groups_Hierarchies_AggregateArgs = {
  distinct_on?: Maybe<Array<Task_Groups_Hierarchies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Groups_Hierarchies_Order_By>>;
  where?: Maybe<Task_Groups_Hierarchies_Bool_Exp>;
};


export type Query_RootTask_Groups_Hierarchies_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTask_Groups_Hierarchy_GroupsArgs = {
  distinct_on?: Maybe<Array<Task_Groups_Hierarchy_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Groups_Hierarchy_Groups_Order_By>>;
  where?: Maybe<Task_Groups_Hierarchy_Groups_Bool_Exp>;
};


export type Query_RootTask_Groups_Hierarchy_Groups_AggregateArgs = {
  distinct_on?: Maybe<Array<Task_Groups_Hierarchy_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Groups_Hierarchy_Groups_Order_By>>;
  where?: Maybe<Task_Groups_Hierarchy_Groups_Bool_Exp>;
};


export type Query_RootTask_Groups_Hierarchy_Groups_By_PkArgs = {
  group_id: Scalars['uuid'];
  hierarchy_id: Scalars['uuid'];
};


export type Query_RootTask_InstructionsArgs = {
  distinct_on?: Maybe<Array<Task_Instructions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Instructions_Order_By>>;
  where?: Maybe<Task_Instructions_Bool_Exp>;
};


export type Query_RootTask_Instructions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTask_MediaArgs = {
  distinct_on?: Maybe<Array<Task_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Media_Order_By>>;
  where?: Maybe<Task_Media_Bool_Exp>;
};


export type Query_RootTask_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Task_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Media_Order_By>>;
  where?: Maybe<Task_Media_Bool_Exp>;
};


export type Query_RootTask_Media_By_PkArgs = {
  media_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};


export type Query_RootTask_StatusesArgs = {
  distinct_on?: Maybe<Array<Task_Statuses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Statuses_Order_By>>;
  where?: Maybe<Task_Statuses_Bool_Exp>;
};


export type Query_RootTask_Statuses_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootTask_TranslationArgs = {
  distinct_on?: Maybe<Array<Task_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Translation_Order_By>>;
  where?: Maybe<Task_Translation_Bool_Exp>;
};


export type Query_RootTask_Translation_AggregateArgs = {
  distinct_on?: Maybe<Array<Task_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Translation_Order_By>>;
  where?: Maybe<Task_Translation_Bool_Exp>;
};


export type Query_RootTask_Translation_By_PkArgs = {
  language: Scalars['String'];
  task_id: Scalars['uuid'];
};


export type Query_RootTasksArgs = {
  distinct_on?: Maybe<Array<Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tasks_Order_By>>;
  where?: Maybe<Tasks_Bool_Exp>;
};


export type Query_RootTasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tasks_Order_By>>;
  where?: Maybe<Tasks_Bool_Exp>;
};


export type Query_RootTasks_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTool_VersionsArgs = {
  distinct_on?: Maybe<Array<Tool_Versions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tool_Versions_Order_By>>;
  where?: Maybe<Tool_Versions_Bool_Exp>;
};


export type Query_RootTool_Versions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootToolsArgs = {
  distinct_on?: Maybe<Array<Tools_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tools_Order_By>>;
  where?: Maybe<Tools_Bool_Exp>;
};


export type Query_RootTools_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTypesArgs = {
  distinct_on?: Maybe<Array<Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Types_Order_By>>;
  where?: Maybe<Types_Bool_Exp>;
};


export type Query_RootTypes_AggregateArgs = {
  distinct_on?: Maybe<Array<Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Types_Order_By>>;
  where?: Maybe<Types_Bool_Exp>;
};


export type Query_RootTypes_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUser_Recordings_By_Day_StatsArgs = {
  distinct_on?: Maybe<Array<User_Recordings_By_Day_Stats_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Recordings_By_Day_Stats_Order_By>>;
  where?: Maybe<User_Recordings_By_Day_Stats_Bool_Exp>;
};


export type Query_RootUser_Recordings_By_Day_Stats_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Recordings_By_Day_Stats_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Recordings_By_Day_Stats_Order_By>>;
  where?: Maybe<User_Recordings_By_Day_Stats_Bool_Exp>;
};


export type Query_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootXtensa_ConfigurationsArgs = {
  distinct_on?: Maybe<Array<Xtensa_Configurations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Xtensa_Configurations_Order_By>>;
  where?: Maybe<Xtensa_Configurations_Bool_Exp>;
};


export type Query_RootXtensa_Configurations_AggregateArgs = {
  distinct_on?: Maybe<Array<Xtensa_Configurations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Xtensa_Configurations_Order_By>>;
  where?: Maybe<Xtensa_Configurations_Bool_Exp>;
};


export type Query_RootXtensa_Configurations_By_PkArgs = {
  id: Scalars['uuid'];
};

/** used to attach a rabbit test to a project */
export type Rabbit_Test_Plan_Project = {
  __typename?: 'rabbit_test_plan_project';
  /** An object relationship */
  plan: Rabbit_Test_Plans;
  plan_id: Scalars['uuid'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
};

/** aggregated selection of "rabbit_test.plan_project" */
export type Rabbit_Test_Plan_Project_Aggregate = {
  __typename?: 'rabbit_test_plan_project_aggregate';
  aggregate?: Maybe<Rabbit_Test_Plan_Project_Aggregate_Fields>;
  nodes: Array<Rabbit_Test_Plan_Project>;
};

/** aggregate fields of "rabbit_test.plan_project" */
export type Rabbit_Test_Plan_Project_Aggregate_Fields = {
  __typename?: 'rabbit_test_plan_project_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Rabbit_Test_Plan_Project_Max_Fields>;
  min?: Maybe<Rabbit_Test_Plan_Project_Min_Fields>;
};


/** aggregate fields of "rabbit_test.plan_project" */
export type Rabbit_Test_Plan_Project_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rabbit_Test_Plan_Project_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "rabbit_test.plan_project" */
export type Rabbit_Test_Plan_Project_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Rabbit_Test_Plan_Project_Max_Order_By>;
  min?: Maybe<Rabbit_Test_Plan_Project_Min_Order_By>;
};

/** input type for inserting array relation for remote table "rabbit_test.plan_project" */
export type Rabbit_Test_Plan_Project_Arr_Rel_Insert_Input = {
  data: Array<Rabbit_Test_Plan_Project_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Rabbit_Test_Plan_Project_On_Conflict>;
};

/** Boolean expression to filter rows from the table "rabbit_test.plan_project". All fields are combined with a logical 'AND'. */
export type Rabbit_Test_Plan_Project_Bool_Exp = {
  _and?: Maybe<Array<Rabbit_Test_Plan_Project_Bool_Exp>>;
  _not?: Maybe<Rabbit_Test_Plan_Project_Bool_Exp>;
  _or?: Maybe<Array<Rabbit_Test_Plan_Project_Bool_Exp>>;
  plan?: Maybe<Rabbit_Test_Plans_Bool_Exp>;
  plan_id?: Maybe<Uuid_Comparison_Exp>;
  project?: Maybe<Projects_Bool_Exp>;
  project_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "rabbit_test.plan_project" */
export enum Rabbit_Test_Plan_Project_Constraint {
  /** unique or primary key constraint on columns "project_id", "plan_id" */
  PlanProjectPkey = 'plan_project_pkey'
}

/** input type for inserting data into table "rabbit_test.plan_project" */
export type Rabbit_Test_Plan_Project_Insert_Input = {
  plan?: Maybe<Rabbit_Test_Plans_Obj_Rel_Insert_Input>;
  plan_id?: Maybe<Scalars['uuid']>;
  project?: Maybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Rabbit_Test_Plan_Project_Max_Fields = {
  __typename?: 'rabbit_test_plan_project_max_fields';
  plan_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "rabbit_test.plan_project" */
export type Rabbit_Test_Plan_Project_Max_Order_By = {
  plan_id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rabbit_Test_Plan_Project_Min_Fields = {
  __typename?: 'rabbit_test_plan_project_min_fields';
  plan_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "rabbit_test.plan_project" */
export type Rabbit_Test_Plan_Project_Min_Order_By = {
  plan_id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "rabbit_test.plan_project" */
export type Rabbit_Test_Plan_Project_Mutation_Response = {
  __typename?: 'rabbit_test_plan_project_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Rabbit_Test_Plan_Project>;
};

/** on_conflict condition type for table "rabbit_test.plan_project" */
export type Rabbit_Test_Plan_Project_On_Conflict = {
  constraint: Rabbit_Test_Plan_Project_Constraint;
  update_columns?: Array<Rabbit_Test_Plan_Project_Update_Column>;
  where?: Maybe<Rabbit_Test_Plan_Project_Bool_Exp>;
};

/** Ordering options when selecting data from "rabbit_test.plan_project". */
export type Rabbit_Test_Plan_Project_Order_By = {
  plan?: Maybe<Rabbit_Test_Plans_Order_By>;
  plan_id?: Maybe<Order_By>;
  project?: Maybe<Projects_Order_By>;
  project_id?: Maybe<Order_By>;
};

/** primary key columns input for table: rabbit_test.plan_project */
export type Rabbit_Test_Plan_Project_Pk_Columns_Input = {
  plan_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};

/** select columns of table "rabbit_test.plan_project" */
export enum Rabbit_Test_Plan_Project_Select_Column {
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  ProjectId = 'project_id'
}

/** input type for updating data in table "rabbit_test.plan_project" */
export type Rabbit_Test_Plan_Project_Set_Input = {
  plan_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "rabbit_test_plan_project" */
export type Rabbit_Test_Plan_Project_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rabbit_Test_Plan_Project_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rabbit_Test_Plan_Project_Stream_Cursor_Value_Input = {
  plan_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "rabbit_test.plan_project" */
export enum Rabbit_Test_Plan_Project_Update_Column {
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  ProjectId = 'project_id'
}

export type Rabbit_Test_Plan_Project_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Rabbit_Test_Plan_Project_Set_Input>;
  where: Rabbit_Test_Plan_Project_Bool_Exp;
};

/** columns and relationships of "rabbit_test.plan_recording" */
export type Rabbit_Test_Plan_Recording = {
  __typename?: 'rabbit_test_plan_recording';
  /** An object relationship */
  plan: Rabbit_Test_Plans;
  plan_id: Scalars['uuid'];
  /** An object relationship */
  recording: Recordings;
  recording_id: Scalars['uuid'];
};

/** aggregated selection of "rabbit_test.plan_recording" */
export type Rabbit_Test_Plan_Recording_Aggregate = {
  __typename?: 'rabbit_test_plan_recording_aggregate';
  aggregate?: Maybe<Rabbit_Test_Plan_Recording_Aggregate_Fields>;
  nodes: Array<Rabbit_Test_Plan_Recording>;
};

/** aggregate fields of "rabbit_test.plan_recording" */
export type Rabbit_Test_Plan_Recording_Aggregate_Fields = {
  __typename?: 'rabbit_test_plan_recording_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Rabbit_Test_Plan_Recording_Max_Fields>;
  min?: Maybe<Rabbit_Test_Plan_Recording_Min_Fields>;
};


/** aggregate fields of "rabbit_test.plan_recording" */
export type Rabbit_Test_Plan_Recording_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rabbit_Test_Plan_Recording_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "rabbit_test.plan_recording" */
export type Rabbit_Test_Plan_Recording_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Rabbit_Test_Plan_Recording_Max_Order_By>;
  min?: Maybe<Rabbit_Test_Plan_Recording_Min_Order_By>;
};

/** input type for inserting array relation for remote table "rabbit_test.plan_recording" */
export type Rabbit_Test_Plan_Recording_Arr_Rel_Insert_Input = {
  data: Array<Rabbit_Test_Plan_Recording_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Rabbit_Test_Plan_Recording_On_Conflict>;
};

/** Boolean expression to filter rows from the table "rabbit_test.plan_recording". All fields are combined with a logical 'AND'. */
export type Rabbit_Test_Plan_Recording_Bool_Exp = {
  _and?: Maybe<Array<Rabbit_Test_Plan_Recording_Bool_Exp>>;
  _not?: Maybe<Rabbit_Test_Plan_Recording_Bool_Exp>;
  _or?: Maybe<Array<Rabbit_Test_Plan_Recording_Bool_Exp>>;
  plan?: Maybe<Rabbit_Test_Plans_Bool_Exp>;
  plan_id?: Maybe<Uuid_Comparison_Exp>;
  recording?: Maybe<Recordings_Bool_Exp>;
  recording_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "rabbit_test.plan_recording" */
export enum Rabbit_Test_Plan_Recording_Constraint {
  /** unique or primary key constraint on columns "recording_id" */
  PlanRecordingPkey = 'plan_recording_pkey'
}

/** input type for inserting data into table "rabbit_test.plan_recording" */
export type Rabbit_Test_Plan_Recording_Insert_Input = {
  plan?: Maybe<Rabbit_Test_Plans_Obj_Rel_Insert_Input>;
  plan_id?: Maybe<Scalars['uuid']>;
  recording?: Maybe<Recordings_Obj_Rel_Insert_Input>;
  recording_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Rabbit_Test_Plan_Recording_Max_Fields = {
  __typename?: 'rabbit_test_plan_recording_max_fields';
  plan_id?: Maybe<Scalars['uuid']>;
  recording_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "rabbit_test.plan_recording" */
export type Rabbit_Test_Plan_Recording_Max_Order_By = {
  plan_id?: Maybe<Order_By>;
  recording_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rabbit_Test_Plan_Recording_Min_Fields = {
  __typename?: 'rabbit_test_plan_recording_min_fields';
  plan_id?: Maybe<Scalars['uuid']>;
  recording_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "rabbit_test.plan_recording" */
export type Rabbit_Test_Plan_Recording_Min_Order_By = {
  plan_id?: Maybe<Order_By>;
  recording_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "rabbit_test.plan_recording" */
export type Rabbit_Test_Plan_Recording_Mutation_Response = {
  __typename?: 'rabbit_test_plan_recording_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Rabbit_Test_Plan_Recording>;
};

/** input type for inserting object relation for remote table "rabbit_test.plan_recording" */
export type Rabbit_Test_Plan_Recording_Obj_Rel_Insert_Input = {
  data: Rabbit_Test_Plan_Recording_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Rabbit_Test_Plan_Recording_On_Conflict>;
};

/** on_conflict condition type for table "rabbit_test.plan_recording" */
export type Rabbit_Test_Plan_Recording_On_Conflict = {
  constraint: Rabbit_Test_Plan_Recording_Constraint;
  update_columns?: Array<Rabbit_Test_Plan_Recording_Update_Column>;
  where?: Maybe<Rabbit_Test_Plan_Recording_Bool_Exp>;
};

/** Ordering options when selecting data from "rabbit_test.plan_recording". */
export type Rabbit_Test_Plan_Recording_Order_By = {
  plan?: Maybe<Rabbit_Test_Plans_Order_By>;
  plan_id?: Maybe<Order_By>;
  recording?: Maybe<Recordings_Order_By>;
  recording_id?: Maybe<Order_By>;
};

/** primary key columns input for table: rabbit_test.plan_recording */
export type Rabbit_Test_Plan_Recording_Pk_Columns_Input = {
  recording_id: Scalars['uuid'];
};

/** select columns of table "rabbit_test.plan_recording" */
export enum Rabbit_Test_Plan_Recording_Select_Column {
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  RecordingId = 'recording_id'
}

/** input type for updating data in table "rabbit_test.plan_recording" */
export type Rabbit_Test_Plan_Recording_Set_Input = {
  plan_id?: Maybe<Scalars['uuid']>;
  recording_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "rabbit_test_plan_recording" */
export type Rabbit_Test_Plan_Recording_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rabbit_Test_Plan_Recording_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rabbit_Test_Plan_Recording_Stream_Cursor_Value_Input = {
  plan_id?: Maybe<Scalars['uuid']>;
  recording_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "rabbit_test.plan_recording" */
export enum Rabbit_Test_Plan_Recording_Update_Column {
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  RecordingId = 'recording_id'
}

export type Rabbit_Test_Plan_Recording_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Rabbit_Test_Plan_Recording_Set_Input>;
  where: Rabbit_Test_Plan_Recording_Bool_Exp;
};

/** used to attach tasks to rabbit tests */
export type Rabbit_Test_Plan_Task = {
  __typename?: 'rabbit_test_plan_task';
  is_test: Scalars['Boolean'];
  /** An object relationship */
  plan: Rabbit_Test_Plans;
  plan_id: Scalars['uuid'];
  /** An object relationship */
  task?: Maybe<Tasks>;
  task_id: Scalars['uuid'];
};

/** aggregated selection of "rabbit_test.plan_task" */
export type Rabbit_Test_Plan_Task_Aggregate = {
  __typename?: 'rabbit_test_plan_task_aggregate';
  aggregate?: Maybe<Rabbit_Test_Plan_Task_Aggregate_Fields>;
  nodes: Array<Rabbit_Test_Plan_Task>;
};

/** aggregate fields of "rabbit_test.plan_task" */
export type Rabbit_Test_Plan_Task_Aggregate_Fields = {
  __typename?: 'rabbit_test_plan_task_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Rabbit_Test_Plan_Task_Max_Fields>;
  min?: Maybe<Rabbit_Test_Plan_Task_Min_Fields>;
};


/** aggregate fields of "rabbit_test.plan_task" */
export type Rabbit_Test_Plan_Task_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rabbit_Test_Plan_Task_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "rabbit_test.plan_task" */
export type Rabbit_Test_Plan_Task_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Rabbit_Test_Plan_Task_Max_Order_By>;
  min?: Maybe<Rabbit_Test_Plan_Task_Min_Order_By>;
};

/** input type for inserting array relation for remote table "rabbit_test.plan_task" */
export type Rabbit_Test_Plan_Task_Arr_Rel_Insert_Input = {
  data: Array<Rabbit_Test_Plan_Task_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Rabbit_Test_Plan_Task_On_Conflict>;
};

/** Boolean expression to filter rows from the table "rabbit_test.plan_task". All fields are combined with a logical 'AND'. */
export type Rabbit_Test_Plan_Task_Bool_Exp = {
  _and?: Maybe<Array<Rabbit_Test_Plan_Task_Bool_Exp>>;
  _not?: Maybe<Rabbit_Test_Plan_Task_Bool_Exp>;
  _or?: Maybe<Array<Rabbit_Test_Plan_Task_Bool_Exp>>;
  is_test?: Maybe<Boolean_Comparison_Exp>;
  plan?: Maybe<Rabbit_Test_Plans_Bool_Exp>;
  plan_id?: Maybe<Uuid_Comparison_Exp>;
  task?: Maybe<Tasks_Bool_Exp>;
  task_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "rabbit_test.plan_task" */
export enum Rabbit_Test_Plan_Task_Constraint {
  /** unique or primary key constraint on columns "plan_id", "task_id" */
  PlanTaskPkey = 'plan_task_pkey'
}

/** input type for inserting data into table "rabbit_test.plan_task" */
export type Rabbit_Test_Plan_Task_Insert_Input = {
  is_test?: Maybe<Scalars['Boolean']>;
  plan?: Maybe<Rabbit_Test_Plans_Obj_Rel_Insert_Input>;
  plan_id?: Maybe<Scalars['uuid']>;
  task?: Maybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Rabbit_Test_Plan_Task_Max_Fields = {
  __typename?: 'rabbit_test_plan_task_max_fields';
  plan_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "rabbit_test.plan_task" */
export type Rabbit_Test_Plan_Task_Max_Order_By = {
  plan_id?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rabbit_Test_Plan_Task_Min_Fields = {
  __typename?: 'rabbit_test_plan_task_min_fields';
  plan_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "rabbit_test.plan_task" */
export type Rabbit_Test_Plan_Task_Min_Order_By = {
  plan_id?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "rabbit_test.plan_task" */
export type Rabbit_Test_Plan_Task_Mutation_Response = {
  __typename?: 'rabbit_test_plan_task_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Rabbit_Test_Plan_Task>;
};

/** on_conflict condition type for table "rabbit_test.plan_task" */
export type Rabbit_Test_Plan_Task_On_Conflict = {
  constraint: Rabbit_Test_Plan_Task_Constraint;
  update_columns?: Array<Rabbit_Test_Plan_Task_Update_Column>;
  where?: Maybe<Rabbit_Test_Plan_Task_Bool_Exp>;
};

/** Ordering options when selecting data from "rabbit_test.plan_task". */
export type Rabbit_Test_Plan_Task_Order_By = {
  is_test?: Maybe<Order_By>;
  plan?: Maybe<Rabbit_Test_Plans_Order_By>;
  plan_id?: Maybe<Order_By>;
  task?: Maybe<Tasks_Order_By>;
  task_id?: Maybe<Order_By>;
};

/** primary key columns input for table: rabbit_test.plan_task */
export type Rabbit_Test_Plan_Task_Pk_Columns_Input = {
  plan_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};

/** select columns of table "rabbit_test.plan_task" */
export enum Rabbit_Test_Plan_Task_Select_Column {
  /** column name */
  IsTest = 'is_test',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  TaskId = 'task_id'
}

/** input type for updating data in table "rabbit_test.plan_task" */
export type Rabbit_Test_Plan_Task_Set_Input = {
  is_test?: Maybe<Scalars['Boolean']>;
  plan_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "rabbit_test_plan_task" */
export type Rabbit_Test_Plan_Task_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rabbit_Test_Plan_Task_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rabbit_Test_Plan_Task_Stream_Cursor_Value_Input = {
  is_test?: Maybe<Scalars['Boolean']>;
  plan_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "rabbit_test.plan_task" */
export enum Rabbit_Test_Plan_Task_Update_Column {
  /** column name */
  IsTest = 'is_test',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  TaskId = 'task_id'
}

export type Rabbit_Test_Plan_Task_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Rabbit_Test_Plan_Task_Set_Input>;
  where: Rabbit_Test_Plan_Task_Bool_Exp;
};

/** used to define rabbit tests */
export type Rabbit_Test_Plans = {
  __typename?: 'rabbit_test_plans';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  plan_projects: Array<Rabbit_Test_Plan_Project>;
  /** An aggregate relationship */
  plan_projects_aggregate: Rabbit_Test_Plan_Project_Aggregate;
  /** An array relationship */
  plan_recordings: Array<Rabbit_Test_Plan_Recording>;
  /** An aggregate relationship */
  plan_recordings_aggregate: Rabbit_Test_Plan_Recording_Aggregate;
  /** An array relationship */
  plan_tasks: Array<Rabbit_Test_Plan_Task>;
  /** An aggregate relationship */
  plan_tasks_aggregate: Rabbit_Test_Plan_Task_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** used to define rabbit tests */
export type Rabbit_Test_PlansPlan_ProjectsArgs = {
  distinct_on?: Maybe<Array<Rabbit_Test_Plan_Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rabbit_Test_Plan_Project_Order_By>>;
  where?: Maybe<Rabbit_Test_Plan_Project_Bool_Exp>;
};


/** used to define rabbit tests */
export type Rabbit_Test_PlansPlan_Projects_AggregateArgs = {
  distinct_on?: Maybe<Array<Rabbit_Test_Plan_Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rabbit_Test_Plan_Project_Order_By>>;
  where?: Maybe<Rabbit_Test_Plan_Project_Bool_Exp>;
};


/** used to define rabbit tests */
export type Rabbit_Test_PlansPlan_RecordingsArgs = {
  distinct_on?: Maybe<Array<Rabbit_Test_Plan_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rabbit_Test_Plan_Recording_Order_By>>;
  where?: Maybe<Rabbit_Test_Plan_Recording_Bool_Exp>;
};


/** used to define rabbit tests */
export type Rabbit_Test_PlansPlan_Recordings_AggregateArgs = {
  distinct_on?: Maybe<Array<Rabbit_Test_Plan_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rabbit_Test_Plan_Recording_Order_By>>;
  where?: Maybe<Rabbit_Test_Plan_Recording_Bool_Exp>;
};


/** used to define rabbit tests */
export type Rabbit_Test_PlansPlan_TasksArgs = {
  distinct_on?: Maybe<Array<Rabbit_Test_Plan_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rabbit_Test_Plan_Task_Order_By>>;
  where?: Maybe<Rabbit_Test_Plan_Task_Bool_Exp>;
};


/** used to define rabbit tests */
export type Rabbit_Test_PlansPlan_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Rabbit_Test_Plan_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rabbit_Test_Plan_Task_Order_By>>;
  where?: Maybe<Rabbit_Test_Plan_Task_Bool_Exp>;
};

/** aggregated selection of "rabbit_test.plans" */
export type Rabbit_Test_Plans_Aggregate = {
  __typename?: 'rabbit_test_plans_aggregate';
  aggregate?: Maybe<Rabbit_Test_Plans_Aggregate_Fields>;
  nodes: Array<Rabbit_Test_Plans>;
};

/** aggregate fields of "rabbit_test.plans" */
export type Rabbit_Test_Plans_Aggregate_Fields = {
  __typename?: 'rabbit_test_plans_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Rabbit_Test_Plans_Max_Fields>;
  min?: Maybe<Rabbit_Test_Plans_Min_Fields>;
};


/** aggregate fields of "rabbit_test.plans" */
export type Rabbit_Test_Plans_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rabbit_Test_Plans_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "rabbit_test.plans". All fields are combined with a logical 'AND'. */
export type Rabbit_Test_Plans_Bool_Exp = {
  _and?: Maybe<Array<Rabbit_Test_Plans_Bool_Exp>>;
  _not?: Maybe<Rabbit_Test_Plans_Bool_Exp>;
  _or?: Maybe<Array<Rabbit_Test_Plans_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  plan_projects?: Maybe<Rabbit_Test_Plan_Project_Bool_Exp>;
  plan_recordings?: Maybe<Rabbit_Test_Plan_Recording_Bool_Exp>;
  plan_tasks?: Maybe<Rabbit_Test_Plan_Task_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "rabbit_test.plans" */
export enum Rabbit_Test_Plans_Constraint {
  /** unique or primary key constraint on columns "name" */
  PlansNameKey = 'plans_name_key',
  /** unique or primary key constraint on columns "id" */
  PlansPkey = 'plans_pkey'
}

/** input type for inserting data into table "rabbit_test.plans" */
export type Rabbit_Test_Plans_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  plan_projects?: Maybe<Rabbit_Test_Plan_Project_Arr_Rel_Insert_Input>;
  plan_recordings?: Maybe<Rabbit_Test_Plan_Recording_Arr_Rel_Insert_Input>;
  plan_tasks?: Maybe<Rabbit_Test_Plan_Task_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Rabbit_Test_Plans_Max_Fields = {
  __typename?: 'rabbit_test_plans_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Rabbit_Test_Plans_Min_Fields = {
  __typename?: 'rabbit_test_plans_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "rabbit_test.plans" */
export type Rabbit_Test_Plans_Mutation_Response = {
  __typename?: 'rabbit_test_plans_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Rabbit_Test_Plans>;
};

/** input type for inserting object relation for remote table "rabbit_test.plans" */
export type Rabbit_Test_Plans_Obj_Rel_Insert_Input = {
  data: Rabbit_Test_Plans_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Rabbit_Test_Plans_On_Conflict>;
};

/** on_conflict condition type for table "rabbit_test.plans" */
export type Rabbit_Test_Plans_On_Conflict = {
  constraint: Rabbit_Test_Plans_Constraint;
  update_columns?: Array<Rabbit_Test_Plans_Update_Column>;
  where?: Maybe<Rabbit_Test_Plans_Bool_Exp>;
};

/** Ordering options when selecting data from "rabbit_test.plans". */
export type Rabbit_Test_Plans_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  plan_projects_aggregate?: Maybe<Rabbit_Test_Plan_Project_Aggregate_Order_By>;
  plan_recordings_aggregate?: Maybe<Rabbit_Test_Plan_Recording_Aggregate_Order_By>;
  plan_tasks_aggregate?: Maybe<Rabbit_Test_Plan_Task_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: rabbit_test.plans */
export type Rabbit_Test_Plans_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "rabbit_test.plans" */
export enum Rabbit_Test_Plans_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "rabbit_test.plans" */
export type Rabbit_Test_Plans_Set_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "rabbit_test_plans" */
export type Rabbit_Test_Plans_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rabbit_Test_Plans_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rabbit_Test_Plans_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "rabbit_test.plans" */
export enum Rabbit_Test_Plans_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

export type Rabbit_Test_Plans_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Rabbit_Test_Plans_Set_Input>;
  where: Rabbit_Test_Plans_Bool_Exp;
};

/** columns and relationships of "recording_apps" */
export type Recording_Apps = {
  __typename?: 'recording_apps';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  recordings: Array<Recordings>;
  /** An aggregate relationship */
  recordings_aggregate: Recordings_Aggregate;
  updated_at: Scalars['timestamptz'];
  version: Scalars['String'];
};


/** columns and relationships of "recording_apps" */
export type Recording_AppsRecordingsArgs = {
  distinct_on?: Maybe<Array<Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recordings_Order_By>>;
  where?: Maybe<Recordings_Bool_Exp>;
};


/** columns and relationships of "recording_apps" */
export type Recording_AppsRecordings_AggregateArgs = {
  distinct_on?: Maybe<Array<Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recordings_Order_By>>;
  where?: Maybe<Recordings_Bool_Exp>;
};

/** aggregated selection of "recording_apps" */
export type Recording_Apps_Aggregate = {
  __typename?: 'recording_apps_aggregate';
  aggregate?: Maybe<Recording_Apps_Aggregate_Fields>;
  nodes: Array<Recording_Apps>;
};

/** aggregate fields of "recording_apps" */
export type Recording_Apps_Aggregate_Fields = {
  __typename?: 'recording_apps_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Recording_Apps_Max_Fields>;
  min?: Maybe<Recording_Apps_Min_Fields>;
};


/** aggregate fields of "recording_apps" */
export type Recording_Apps_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Recording_Apps_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "recording_apps". All fields are combined with a logical 'AND'. */
export type Recording_Apps_Bool_Exp = {
  _and?: Maybe<Array<Recording_Apps_Bool_Exp>>;
  _not?: Maybe<Recording_Apps_Bool_Exp>;
  _or?: Maybe<Array<Recording_Apps_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  recordings?: Maybe<Recordings_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  version?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "recording_apps" */
export enum Recording_Apps_Constraint {
  /** unique or primary key constraint on columns "name", "version" */
  RecordingAppsNameVersionKey = 'recording_apps_name_version_key',
  /** unique or primary key constraint on columns "id" */
  RecordingAppsPkey = 'recording_apps_pkey'
}

/** input type for inserting data into table "recording_apps" */
export type Recording_Apps_Insert_Input = {
  name?: Maybe<Scalars['String']>;
  recordings?: Maybe<Recordings_Arr_Rel_Insert_Input>;
  version?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Recording_Apps_Max_Fields = {
  __typename?: 'recording_apps_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Recording_Apps_Min_Fields = {
  __typename?: 'recording_apps_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "recording_apps" */
export type Recording_Apps_Mutation_Response = {
  __typename?: 'recording_apps_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Recording_Apps>;
};

/** input type for inserting object relation for remote table "recording_apps" */
export type Recording_Apps_Obj_Rel_Insert_Input = {
  data: Recording_Apps_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Recording_Apps_On_Conflict>;
};

/** on_conflict condition type for table "recording_apps" */
export type Recording_Apps_On_Conflict = {
  constraint: Recording_Apps_Constraint;
  update_columns?: Array<Recording_Apps_Update_Column>;
  where?: Maybe<Recording_Apps_Bool_Exp>;
};

/** Ordering options when selecting data from "recording_apps". */
export type Recording_Apps_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  recordings_aggregate?: Maybe<Recordings_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
};

/** primary key columns input for table: recording_apps */
export type Recording_Apps_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "recording_apps" */
export enum Recording_Apps_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "recording_apps" */
export type Recording_Apps_Set_Input = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "recording_apps" */
export type Recording_Apps_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Recording_Apps_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recording_Apps_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['String']>;
};

/** update columns of table "recording_apps" */
export enum Recording_Apps_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Version = 'version'
}

export type Recording_Apps_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Recording_Apps_Set_Input>;
  where: Recording_Apps_Bool_Exp;
};

/** columns and relationships of "recording_audio_driver" */
export type Recording_Audio_Driver = {
  __typename?: 'recording_audio_driver';
  /** An object relationship */
  audio_driver: Audio_Driver;
  audio_driver_id: Scalars['uuid'];
  /** An object relationship */
  recording: Recordings;
  recording_id: Scalars['uuid'];
};

/** order by aggregate values of table "recording_audio_driver" */
export type Recording_Audio_Driver_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Recording_Audio_Driver_Max_Order_By>;
  min?: Maybe<Recording_Audio_Driver_Min_Order_By>;
};

/** input type for inserting array relation for remote table "recording_audio_driver" */
export type Recording_Audio_Driver_Arr_Rel_Insert_Input = {
  data: Array<Recording_Audio_Driver_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Recording_Audio_Driver_On_Conflict>;
};

/** Boolean expression to filter rows from the table "recording_audio_driver". All fields are combined with a logical 'AND'. */
export type Recording_Audio_Driver_Bool_Exp = {
  _and?: Maybe<Array<Recording_Audio_Driver_Bool_Exp>>;
  _not?: Maybe<Recording_Audio_Driver_Bool_Exp>;
  _or?: Maybe<Array<Recording_Audio_Driver_Bool_Exp>>;
  audio_driver?: Maybe<Audio_Driver_Bool_Exp>;
  audio_driver_id?: Maybe<Uuid_Comparison_Exp>;
  recording?: Maybe<Recordings_Bool_Exp>;
  recording_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "recording_audio_driver" */
export enum Recording_Audio_Driver_Constraint {
  /** unique or primary key constraint on columns "audio_driver_id", "recording_id" */
  RecordingAudioDriverPkey = 'recording_audio_driver_pkey'
}

/** input type for inserting data into table "recording_audio_driver" */
export type Recording_Audio_Driver_Insert_Input = {
  audio_driver?: Maybe<Audio_Driver_Obj_Rel_Insert_Input>;
  audio_driver_id?: Maybe<Scalars['uuid']>;
  recording?: Maybe<Recordings_Obj_Rel_Insert_Input>;
  recording_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "recording_audio_driver" */
export type Recording_Audio_Driver_Max_Order_By = {
  audio_driver_id?: Maybe<Order_By>;
  recording_id?: Maybe<Order_By>;
};

/** order by min() on columns of table "recording_audio_driver" */
export type Recording_Audio_Driver_Min_Order_By = {
  audio_driver_id?: Maybe<Order_By>;
  recording_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "recording_audio_driver" */
export type Recording_Audio_Driver_Mutation_Response = {
  __typename?: 'recording_audio_driver_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Recording_Audio_Driver>;
};

/** on_conflict condition type for table "recording_audio_driver" */
export type Recording_Audio_Driver_On_Conflict = {
  constraint: Recording_Audio_Driver_Constraint;
  update_columns?: Array<Recording_Audio_Driver_Update_Column>;
  where?: Maybe<Recording_Audio_Driver_Bool_Exp>;
};

/** Ordering options when selecting data from "recording_audio_driver". */
export type Recording_Audio_Driver_Order_By = {
  audio_driver?: Maybe<Audio_Driver_Order_By>;
  audio_driver_id?: Maybe<Order_By>;
  recording?: Maybe<Recordings_Order_By>;
  recording_id?: Maybe<Order_By>;
};

/** primary key columns input for table: recording_audio_driver */
export type Recording_Audio_Driver_Pk_Columns_Input = {
  audio_driver_id: Scalars['uuid'];
  recording_id: Scalars['uuid'];
};

/** select columns of table "recording_audio_driver" */
export enum Recording_Audio_Driver_Select_Column {
  /** column name */
  AudioDriverId = 'audio_driver_id',
  /** column name */
  RecordingId = 'recording_id'
}

/** input type for updating data in table "recording_audio_driver" */
export type Recording_Audio_Driver_Set_Input = {
  audio_driver_id?: Maybe<Scalars['uuid']>;
  recording_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "recording_audio_driver" */
export type Recording_Audio_Driver_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Recording_Audio_Driver_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recording_Audio_Driver_Stream_Cursor_Value_Input = {
  audio_driver_id?: Maybe<Scalars['uuid']>;
  recording_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "recording_audio_driver" */
export enum Recording_Audio_Driver_Update_Column {
  /** column name */
  AudioDriverId = 'audio_driver_id',
  /** column name */
  RecordingId = 'recording_id'
}

export type Recording_Audio_Driver_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Recording_Audio_Driver_Set_Input>;
  where: Recording_Audio_Driver_Bool_Exp;
};

/** Contains timestamped transitions between states generated from ground truth data collected during recordings */
export type Recording_Data_Ground_Truth = {
  __typename?: 'recording_data_ground_truth';
  build_id_azure?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  last_time: Scalars['Int'];
  /** An object relationship */
  recording: Recordings;
  recording_id: Scalars['uuid'];
  states: Scalars['_int2'];
  times: Scalars['_int4'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "recording_data.ground_truth" */
export type Recording_Data_Ground_Truth_Aggregate = {
  __typename?: 'recording_data_ground_truth_aggregate';
  aggregate?: Maybe<Recording_Data_Ground_Truth_Aggregate_Fields>;
  nodes: Array<Recording_Data_Ground_Truth>;
};

/** aggregate fields of "recording_data.ground_truth" */
export type Recording_Data_Ground_Truth_Aggregate_Fields = {
  __typename?: 'recording_data_ground_truth_aggregate_fields';
  avg?: Maybe<Recording_Data_Ground_Truth_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Recording_Data_Ground_Truth_Max_Fields>;
  min?: Maybe<Recording_Data_Ground_Truth_Min_Fields>;
  stddev?: Maybe<Recording_Data_Ground_Truth_Stddev_Fields>;
  stddev_pop?: Maybe<Recording_Data_Ground_Truth_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Recording_Data_Ground_Truth_Stddev_Samp_Fields>;
  sum?: Maybe<Recording_Data_Ground_Truth_Sum_Fields>;
  var_pop?: Maybe<Recording_Data_Ground_Truth_Var_Pop_Fields>;
  var_samp?: Maybe<Recording_Data_Ground_Truth_Var_Samp_Fields>;
  variance?: Maybe<Recording_Data_Ground_Truth_Variance_Fields>;
};


/** aggregate fields of "recording_data.ground_truth" */
export type Recording_Data_Ground_Truth_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Recording_Data_Ground_Truth_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Recording_Data_Ground_Truth_Avg_Fields = {
  __typename?: 'recording_data_ground_truth_avg_fields';
  build_id_azure?: Maybe<Scalars['Float']>;
  last_time?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "recording_data.ground_truth". All fields are combined with a logical 'AND'. */
export type Recording_Data_Ground_Truth_Bool_Exp = {
  _and?: Maybe<Array<Recording_Data_Ground_Truth_Bool_Exp>>;
  _not?: Maybe<Recording_Data_Ground_Truth_Bool_Exp>;
  _or?: Maybe<Array<Recording_Data_Ground_Truth_Bool_Exp>>;
  build_id_azure?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  last_time?: Maybe<Int_Comparison_Exp>;
  recording?: Maybe<Recordings_Bool_Exp>;
  recording_id?: Maybe<Uuid_Comparison_Exp>;
  states?: Maybe<_Int2_Comparison_Exp>;
  times?: Maybe<_Int4_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "recording_data.ground_truth" */
export enum Recording_Data_Ground_Truth_Constraint {
  /** unique or primary key constraint on columns "recording_id" */
  GroundTruthPkey = 'ground_truth_pkey'
}

/** input type for incrementing numeric columns in table "recording_data.ground_truth" */
export type Recording_Data_Ground_Truth_Inc_Input = {
  build_id_azure?: Maybe<Scalars['Int']>;
  last_time?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "recording_data.ground_truth" */
export type Recording_Data_Ground_Truth_Insert_Input = {
  build_id_azure?: Maybe<Scalars['Int']>;
  last_time?: Maybe<Scalars['Int']>;
  recording?: Maybe<Recordings_Obj_Rel_Insert_Input>;
  recording_id?: Maybe<Scalars['uuid']>;
  states?: Maybe<Scalars['_int2']>;
  times?: Maybe<Scalars['_int4']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Recording_Data_Ground_Truth_Max_Fields = {
  __typename?: 'recording_data_ground_truth_max_fields';
  build_id_azure?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  last_time?: Maybe<Scalars['Int']>;
  recording_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Recording_Data_Ground_Truth_Min_Fields = {
  __typename?: 'recording_data_ground_truth_min_fields';
  build_id_azure?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  last_time?: Maybe<Scalars['Int']>;
  recording_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "recording_data.ground_truth" */
export type Recording_Data_Ground_Truth_Mutation_Response = {
  __typename?: 'recording_data_ground_truth_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Recording_Data_Ground_Truth>;
};

/** input type for inserting object relation for remote table "recording_data.ground_truth" */
export type Recording_Data_Ground_Truth_Obj_Rel_Insert_Input = {
  data: Recording_Data_Ground_Truth_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Recording_Data_Ground_Truth_On_Conflict>;
};

/** on_conflict condition type for table "recording_data.ground_truth" */
export type Recording_Data_Ground_Truth_On_Conflict = {
  constraint: Recording_Data_Ground_Truth_Constraint;
  update_columns?: Array<Recording_Data_Ground_Truth_Update_Column>;
  where?: Maybe<Recording_Data_Ground_Truth_Bool_Exp>;
};

/** Ordering options when selecting data from "recording_data.ground_truth". */
export type Recording_Data_Ground_Truth_Order_By = {
  build_id_azure?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  last_time?: Maybe<Order_By>;
  recording?: Maybe<Recordings_Order_By>;
  recording_id?: Maybe<Order_By>;
  states?: Maybe<Order_By>;
  times?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: recording_data.ground_truth */
export type Recording_Data_Ground_Truth_Pk_Columns_Input = {
  recording_id: Scalars['uuid'];
};

/** select columns of table "recording_data.ground_truth" */
export enum Recording_Data_Ground_Truth_Select_Column {
  /** column name */
  BuildIdAzure = 'build_id_azure',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LastTime = 'last_time',
  /** column name */
  RecordingId = 'recording_id',
  /** column name */
  States = 'states',
  /** column name */
  Times = 'times',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "recording_data.ground_truth" */
export type Recording_Data_Ground_Truth_Set_Input = {
  build_id_azure?: Maybe<Scalars['Int']>;
  last_time?: Maybe<Scalars['Int']>;
  recording_id?: Maybe<Scalars['uuid']>;
  states?: Maybe<Scalars['_int2']>;
  times?: Maybe<Scalars['_int4']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Recording_Data_Ground_Truth_Stddev_Fields = {
  __typename?: 'recording_data_ground_truth_stddev_fields';
  build_id_azure?: Maybe<Scalars['Float']>;
  last_time?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Recording_Data_Ground_Truth_Stddev_Pop_Fields = {
  __typename?: 'recording_data_ground_truth_stddev_pop_fields';
  build_id_azure?: Maybe<Scalars['Float']>;
  last_time?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Recording_Data_Ground_Truth_Stddev_Samp_Fields = {
  __typename?: 'recording_data_ground_truth_stddev_samp_fields';
  build_id_azure?: Maybe<Scalars['Float']>;
  last_time?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "recording_data_ground_truth" */
export type Recording_Data_Ground_Truth_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Recording_Data_Ground_Truth_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recording_Data_Ground_Truth_Stream_Cursor_Value_Input = {
  build_id_azure?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  last_time?: Maybe<Scalars['Int']>;
  recording_id?: Maybe<Scalars['uuid']>;
  states?: Maybe<Scalars['_int2']>;
  times?: Maybe<Scalars['_int4']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Recording_Data_Ground_Truth_Sum_Fields = {
  __typename?: 'recording_data_ground_truth_sum_fields';
  build_id_azure?: Maybe<Scalars['Int']>;
  last_time?: Maybe<Scalars['Int']>;
};

/** update columns of table "recording_data.ground_truth" */
export enum Recording_Data_Ground_Truth_Update_Column {
  /** column name */
  BuildIdAzure = 'build_id_azure',
  /** column name */
  LastTime = 'last_time',
  /** column name */
  RecordingId = 'recording_id',
  /** column name */
  States = 'states',
  /** column name */
  Times = 'times',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Recording_Data_Ground_Truth_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Recording_Data_Ground_Truth_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Recording_Data_Ground_Truth_Set_Input>;
  where: Recording_Data_Ground_Truth_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Recording_Data_Ground_Truth_Var_Pop_Fields = {
  __typename?: 'recording_data_ground_truth_var_pop_fields';
  build_id_azure?: Maybe<Scalars['Float']>;
  last_time?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Recording_Data_Ground_Truth_Var_Samp_Fields = {
  __typename?: 'recording_data_ground_truth_var_samp_fields';
  build_id_azure?: Maybe<Scalars['Float']>;
  last_time?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Recording_Data_Ground_Truth_Variance_Fields = {
  __typename?: 'recording_data_ground_truth_variance_fields';
  build_id_azure?: Maybe<Scalars['Float']>;
  last_time?: Maybe<Scalars['Float']>;
};

/** the labels (sensor state change) generated from raw ground truth sensor data */
export type Recording_Data_Labels = {
  __typename?: 'recording_data_labels';
  build_id_azure?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  last_time: Scalars['Int'];
  /** An object relationship */
  mode: Recording_Data_Modes;
  mode_id: Scalars['uuid'];
  /** An object relationship */
  recording: Recordings;
  recording_id: Scalars['uuid'];
  states: Scalars['_int2'];
  times: Scalars['_int4'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "recording_data.labels" */
export type Recording_Data_Labels_Aggregate = {
  __typename?: 'recording_data_labels_aggregate';
  aggregate?: Maybe<Recording_Data_Labels_Aggregate_Fields>;
  nodes: Array<Recording_Data_Labels>;
};

/** aggregate fields of "recording_data.labels" */
export type Recording_Data_Labels_Aggregate_Fields = {
  __typename?: 'recording_data_labels_aggregate_fields';
  avg?: Maybe<Recording_Data_Labels_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Recording_Data_Labels_Max_Fields>;
  min?: Maybe<Recording_Data_Labels_Min_Fields>;
  stddev?: Maybe<Recording_Data_Labels_Stddev_Fields>;
  stddev_pop?: Maybe<Recording_Data_Labels_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Recording_Data_Labels_Stddev_Samp_Fields>;
  sum?: Maybe<Recording_Data_Labels_Sum_Fields>;
  var_pop?: Maybe<Recording_Data_Labels_Var_Pop_Fields>;
  var_samp?: Maybe<Recording_Data_Labels_Var_Samp_Fields>;
  variance?: Maybe<Recording_Data_Labels_Variance_Fields>;
};


/** aggregate fields of "recording_data.labels" */
export type Recording_Data_Labels_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Recording_Data_Labels_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "recording_data.labels" */
export type Recording_Data_Labels_Aggregate_Order_By = {
  avg?: Maybe<Recording_Data_Labels_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Recording_Data_Labels_Max_Order_By>;
  min?: Maybe<Recording_Data_Labels_Min_Order_By>;
  stddev?: Maybe<Recording_Data_Labels_Stddev_Order_By>;
  stddev_pop?: Maybe<Recording_Data_Labels_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Recording_Data_Labels_Stddev_Samp_Order_By>;
  sum?: Maybe<Recording_Data_Labels_Sum_Order_By>;
  var_pop?: Maybe<Recording_Data_Labels_Var_Pop_Order_By>;
  var_samp?: Maybe<Recording_Data_Labels_Var_Samp_Order_By>;
  variance?: Maybe<Recording_Data_Labels_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "recording_data.labels" */
export type Recording_Data_Labels_Arr_Rel_Insert_Input = {
  data: Array<Recording_Data_Labels_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Recording_Data_Labels_On_Conflict>;
};

/** aggregate avg on columns */
export type Recording_Data_Labels_Avg_Fields = {
  __typename?: 'recording_data_labels_avg_fields';
  build_id_azure?: Maybe<Scalars['Float']>;
  last_time?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "recording_data.labels" */
export type Recording_Data_Labels_Avg_Order_By = {
  build_id_azure?: Maybe<Order_By>;
  last_time?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "recording_data.labels". All fields are combined with a logical 'AND'. */
export type Recording_Data_Labels_Bool_Exp = {
  _and?: Maybe<Array<Recording_Data_Labels_Bool_Exp>>;
  _not?: Maybe<Recording_Data_Labels_Bool_Exp>;
  _or?: Maybe<Array<Recording_Data_Labels_Bool_Exp>>;
  build_id_azure?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  last_time?: Maybe<Int_Comparison_Exp>;
  mode?: Maybe<Recording_Data_Modes_Bool_Exp>;
  mode_id?: Maybe<Uuid_Comparison_Exp>;
  recording?: Maybe<Recordings_Bool_Exp>;
  recording_id?: Maybe<Uuid_Comparison_Exp>;
  states?: Maybe<_Int2_Comparison_Exp>;
  times?: Maybe<_Int4_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "recording_data.labels" */
export enum Recording_Data_Labels_Constraint {
  /** unique or primary key constraint on columns "mode_id", "recording_id" */
  LabelsPkey = 'labels_pkey'
}

/** input type for incrementing numeric columns in table "recording_data.labels" */
export type Recording_Data_Labels_Inc_Input = {
  build_id_azure?: Maybe<Scalars['Int']>;
  last_time?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "recording_data.labels" */
export type Recording_Data_Labels_Insert_Input = {
  build_id_azure?: Maybe<Scalars['Int']>;
  last_time?: Maybe<Scalars['Int']>;
  mode?: Maybe<Recording_Data_Modes_Obj_Rel_Insert_Input>;
  mode_id?: Maybe<Scalars['uuid']>;
  recording?: Maybe<Recordings_Obj_Rel_Insert_Input>;
  recording_id?: Maybe<Scalars['uuid']>;
  states?: Maybe<Scalars['_int2']>;
  times?: Maybe<Scalars['_int4']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Recording_Data_Labels_Max_Fields = {
  __typename?: 'recording_data_labels_max_fields';
  build_id_azure?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  last_time?: Maybe<Scalars['Int']>;
  mode_id?: Maybe<Scalars['uuid']>;
  recording_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "recording_data.labels" */
export type Recording_Data_Labels_Max_Order_By = {
  build_id_azure?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  last_time?: Maybe<Order_By>;
  mode_id?: Maybe<Order_By>;
  recording_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Recording_Data_Labels_Min_Fields = {
  __typename?: 'recording_data_labels_min_fields';
  build_id_azure?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  last_time?: Maybe<Scalars['Int']>;
  mode_id?: Maybe<Scalars['uuid']>;
  recording_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "recording_data.labels" */
export type Recording_Data_Labels_Min_Order_By = {
  build_id_azure?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  last_time?: Maybe<Order_By>;
  mode_id?: Maybe<Order_By>;
  recording_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "recording_data.labels" */
export type Recording_Data_Labels_Mutation_Response = {
  __typename?: 'recording_data_labels_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Recording_Data_Labels>;
};

/** on_conflict condition type for table "recording_data.labels" */
export type Recording_Data_Labels_On_Conflict = {
  constraint: Recording_Data_Labels_Constraint;
  update_columns?: Array<Recording_Data_Labels_Update_Column>;
  where?: Maybe<Recording_Data_Labels_Bool_Exp>;
};

/** Ordering options when selecting data from "recording_data.labels". */
export type Recording_Data_Labels_Order_By = {
  build_id_azure?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  last_time?: Maybe<Order_By>;
  mode?: Maybe<Recording_Data_Modes_Order_By>;
  mode_id?: Maybe<Order_By>;
  recording?: Maybe<Recordings_Order_By>;
  recording_id?: Maybe<Order_By>;
  states?: Maybe<Order_By>;
  times?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: recording_data.labels */
export type Recording_Data_Labels_Pk_Columns_Input = {
  mode_id: Scalars['uuid'];
  recording_id: Scalars['uuid'];
};

/** select columns of table "recording_data.labels" */
export enum Recording_Data_Labels_Select_Column {
  /** column name */
  BuildIdAzure = 'build_id_azure',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LastTime = 'last_time',
  /** column name */
  ModeId = 'mode_id',
  /** column name */
  RecordingId = 'recording_id',
  /** column name */
  States = 'states',
  /** column name */
  Times = 'times',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "recording_data.labels" */
export type Recording_Data_Labels_Set_Input = {
  build_id_azure?: Maybe<Scalars['Int']>;
  last_time?: Maybe<Scalars['Int']>;
  mode_id?: Maybe<Scalars['uuid']>;
  recording_id?: Maybe<Scalars['uuid']>;
  states?: Maybe<Scalars['_int2']>;
  times?: Maybe<Scalars['_int4']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Recording_Data_Labels_Stddev_Fields = {
  __typename?: 'recording_data_labels_stddev_fields';
  build_id_azure?: Maybe<Scalars['Float']>;
  last_time?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "recording_data.labels" */
export type Recording_Data_Labels_Stddev_Order_By = {
  build_id_azure?: Maybe<Order_By>;
  last_time?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Recording_Data_Labels_Stddev_Pop_Fields = {
  __typename?: 'recording_data_labels_stddev_pop_fields';
  build_id_azure?: Maybe<Scalars['Float']>;
  last_time?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "recording_data.labels" */
export type Recording_Data_Labels_Stddev_Pop_Order_By = {
  build_id_azure?: Maybe<Order_By>;
  last_time?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Recording_Data_Labels_Stddev_Samp_Fields = {
  __typename?: 'recording_data_labels_stddev_samp_fields';
  build_id_azure?: Maybe<Scalars['Float']>;
  last_time?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "recording_data.labels" */
export type Recording_Data_Labels_Stddev_Samp_Order_By = {
  build_id_azure?: Maybe<Order_By>;
  last_time?: Maybe<Order_By>;
};

/** Streaming cursor of the table "recording_data_labels" */
export type Recording_Data_Labels_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Recording_Data_Labels_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recording_Data_Labels_Stream_Cursor_Value_Input = {
  build_id_azure?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  last_time?: Maybe<Scalars['Int']>;
  mode_id?: Maybe<Scalars['uuid']>;
  recording_id?: Maybe<Scalars['uuid']>;
  states?: Maybe<Scalars['_int2']>;
  times?: Maybe<Scalars['_int4']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Recording_Data_Labels_Sum_Fields = {
  __typename?: 'recording_data_labels_sum_fields';
  build_id_azure?: Maybe<Scalars['Int']>;
  last_time?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "recording_data.labels" */
export type Recording_Data_Labels_Sum_Order_By = {
  build_id_azure?: Maybe<Order_By>;
  last_time?: Maybe<Order_By>;
};

/** update columns of table "recording_data.labels" */
export enum Recording_Data_Labels_Update_Column {
  /** column name */
  BuildIdAzure = 'build_id_azure',
  /** column name */
  LastTime = 'last_time',
  /** column name */
  ModeId = 'mode_id',
  /** column name */
  RecordingId = 'recording_id',
  /** column name */
  States = 'states',
  /** column name */
  Times = 'times',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Recording_Data_Labels_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Recording_Data_Labels_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Recording_Data_Labels_Set_Input>;
  where: Recording_Data_Labels_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Recording_Data_Labels_Var_Pop_Fields = {
  __typename?: 'recording_data_labels_var_pop_fields';
  build_id_azure?: Maybe<Scalars['Float']>;
  last_time?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "recording_data.labels" */
export type Recording_Data_Labels_Var_Pop_Order_By = {
  build_id_azure?: Maybe<Order_By>;
  last_time?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Recording_Data_Labels_Var_Samp_Fields = {
  __typename?: 'recording_data_labels_var_samp_fields';
  build_id_azure?: Maybe<Scalars['Float']>;
  last_time?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "recording_data.labels" */
export type Recording_Data_Labels_Var_Samp_Order_By = {
  build_id_azure?: Maybe<Order_By>;
  last_time?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Recording_Data_Labels_Variance_Fields = {
  __typename?: 'recording_data_labels_variance_fields';
  build_id_azure?: Maybe<Scalars['Float']>;
  last_time?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "recording_data.labels" */
export type Recording_Data_Labels_Variance_Order_By = {
  build_id_azure?: Maybe<Order_By>;
  last_time?: Maybe<Order_By>;
};

/** used to connect a parameter to a mode, with a specific value */
export type Recording_Data_Mode_Parameter = {
  __typename?: 'recording_data_mode_parameter';
  /** An object relationship */
  mode: Recording_Data_Modes;
  mode_id: Scalars['uuid'];
  /** An object relationship */
  parameter: Recording_Data_Parameters;
  parameter_id: Scalars['uuid'];
  value: Scalars['String'];
};

/** aggregated selection of "recording_data.mode_parameter" */
export type Recording_Data_Mode_Parameter_Aggregate = {
  __typename?: 'recording_data_mode_parameter_aggregate';
  aggregate?: Maybe<Recording_Data_Mode_Parameter_Aggregate_Fields>;
  nodes: Array<Recording_Data_Mode_Parameter>;
};

/** aggregate fields of "recording_data.mode_parameter" */
export type Recording_Data_Mode_Parameter_Aggregate_Fields = {
  __typename?: 'recording_data_mode_parameter_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Recording_Data_Mode_Parameter_Max_Fields>;
  min?: Maybe<Recording_Data_Mode_Parameter_Min_Fields>;
};


/** aggregate fields of "recording_data.mode_parameter" */
export type Recording_Data_Mode_Parameter_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Recording_Data_Mode_Parameter_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "recording_data.mode_parameter" */
export type Recording_Data_Mode_Parameter_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Recording_Data_Mode_Parameter_Max_Order_By>;
  min?: Maybe<Recording_Data_Mode_Parameter_Min_Order_By>;
};

/** input type for inserting array relation for remote table "recording_data.mode_parameter" */
export type Recording_Data_Mode_Parameter_Arr_Rel_Insert_Input = {
  data: Array<Recording_Data_Mode_Parameter_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Recording_Data_Mode_Parameter_On_Conflict>;
};

/** Boolean expression to filter rows from the table "recording_data.mode_parameter". All fields are combined with a logical 'AND'. */
export type Recording_Data_Mode_Parameter_Bool_Exp = {
  _and?: Maybe<Array<Recording_Data_Mode_Parameter_Bool_Exp>>;
  _not?: Maybe<Recording_Data_Mode_Parameter_Bool_Exp>;
  _or?: Maybe<Array<Recording_Data_Mode_Parameter_Bool_Exp>>;
  mode?: Maybe<Recording_Data_Modes_Bool_Exp>;
  mode_id?: Maybe<Uuid_Comparison_Exp>;
  parameter?: Maybe<Recording_Data_Parameters_Bool_Exp>;
  parameter_id?: Maybe<Uuid_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "recording_data.mode_parameter" */
export enum Recording_Data_Mode_Parameter_Constraint {
  /** unique or primary key constraint on columns "mode_id", "parameter_id" */
  ModeParameterPkey = 'mode_parameter_pkey'
}

/** input type for inserting data into table "recording_data.mode_parameter" */
export type Recording_Data_Mode_Parameter_Insert_Input = {
  mode?: Maybe<Recording_Data_Modes_Obj_Rel_Insert_Input>;
  mode_id?: Maybe<Scalars['uuid']>;
  parameter?: Maybe<Recording_Data_Parameters_Obj_Rel_Insert_Input>;
  parameter_id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Recording_Data_Mode_Parameter_Max_Fields = {
  __typename?: 'recording_data_mode_parameter_max_fields';
  mode_id?: Maybe<Scalars['uuid']>;
  parameter_id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "recording_data.mode_parameter" */
export type Recording_Data_Mode_Parameter_Max_Order_By = {
  mode_id?: Maybe<Order_By>;
  parameter_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Recording_Data_Mode_Parameter_Min_Fields = {
  __typename?: 'recording_data_mode_parameter_min_fields';
  mode_id?: Maybe<Scalars['uuid']>;
  parameter_id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "recording_data.mode_parameter" */
export type Recording_Data_Mode_Parameter_Min_Order_By = {
  mode_id?: Maybe<Order_By>;
  parameter_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "recording_data.mode_parameter" */
export type Recording_Data_Mode_Parameter_Mutation_Response = {
  __typename?: 'recording_data_mode_parameter_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Recording_Data_Mode_Parameter>;
};

/** on_conflict condition type for table "recording_data.mode_parameter" */
export type Recording_Data_Mode_Parameter_On_Conflict = {
  constraint: Recording_Data_Mode_Parameter_Constraint;
  update_columns?: Array<Recording_Data_Mode_Parameter_Update_Column>;
  where?: Maybe<Recording_Data_Mode_Parameter_Bool_Exp>;
};

/** Ordering options when selecting data from "recording_data.mode_parameter". */
export type Recording_Data_Mode_Parameter_Order_By = {
  mode?: Maybe<Recording_Data_Modes_Order_By>;
  mode_id?: Maybe<Order_By>;
  parameter?: Maybe<Recording_Data_Parameters_Order_By>;
  parameter_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: recording_data.mode_parameter */
export type Recording_Data_Mode_Parameter_Pk_Columns_Input = {
  mode_id: Scalars['uuid'];
  parameter_id: Scalars['uuid'];
};

/** select columns of table "recording_data.mode_parameter" */
export enum Recording_Data_Mode_Parameter_Select_Column {
  /** column name */
  ModeId = 'mode_id',
  /** column name */
  ParameterId = 'parameter_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "recording_data.mode_parameter" */
export type Recording_Data_Mode_Parameter_Set_Input = {
  mode_id?: Maybe<Scalars['uuid']>;
  parameter_id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "recording_data_mode_parameter" */
export type Recording_Data_Mode_Parameter_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Recording_Data_Mode_Parameter_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recording_Data_Mode_Parameter_Stream_Cursor_Value_Input = {
  mode_id?: Maybe<Scalars['uuid']>;
  parameter_id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "recording_data.mode_parameter" */
export enum Recording_Data_Mode_Parameter_Update_Column {
  /** column name */
  ModeId = 'mode_id',
  /** column name */
  ParameterId = 'parameter_id',
  /** column name */
  Value = 'value'
}

export type Recording_Data_Mode_Parameter_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Recording_Data_Mode_Parameter_Set_Input>;
  where: Recording_Data_Mode_Parameter_Bool_Exp;
};

/** sets of operating parameters that influence the output of the engine, and thus must also be part of the ground truth labels */
export type Recording_Data_Modes = {
  __typename?: 'recording_data_modes';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An array relationship */
  labels: Array<Recording_Data_Labels>;
  /** An aggregate relationship */
  labels_aggregate: Recording_Data_Labels_Aggregate;
  /** An array relationship */
  mode_parameters: Array<Recording_Data_Mode_Parameter>;
  /** An aggregate relationship */
  mode_parameters_aggregate: Recording_Data_Mode_Parameter_Aggregate;
  /** An array relationship */
  mode_projects: Array<Mode_Project>;
  /** An aggregate relationship */
  mode_projects_aggregate: Mode_Project_Aggregate;
  name: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** sets of operating parameters that influence the output of the engine, and thus must also be part of the ground truth labels */
export type Recording_Data_ModesLabelsArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Labels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Labels_Order_By>>;
  where?: Maybe<Recording_Data_Labels_Bool_Exp>;
};


/** sets of operating parameters that influence the output of the engine, and thus must also be part of the ground truth labels */
export type Recording_Data_ModesLabels_AggregateArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Labels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Labels_Order_By>>;
  where?: Maybe<Recording_Data_Labels_Bool_Exp>;
};


/** sets of operating parameters that influence the output of the engine, and thus must also be part of the ground truth labels */
export type Recording_Data_ModesMode_ParametersArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Mode_Parameter_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Mode_Parameter_Order_By>>;
  where?: Maybe<Recording_Data_Mode_Parameter_Bool_Exp>;
};


/** sets of operating parameters that influence the output of the engine, and thus must also be part of the ground truth labels */
export type Recording_Data_ModesMode_Parameters_AggregateArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Mode_Parameter_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Mode_Parameter_Order_By>>;
  where?: Maybe<Recording_Data_Mode_Parameter_Bool_Exp>;
};


/** sets of operating parameters that influence the output of the engine, and thus must also be part of the ground truth labels */
export type Recording_Data_ModesMode_ProjectsArgs = {
  distinct_on?: Maybe<Array<Mode_Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mode_Project_Order_By>>;
  where?: Maybe<Mode_Project_Bool_Exp>;
};


/** sets of operating parameters that influence the output of the engine, and thus must also be part of the ground truth labels */
export type Recording_Data_ModesMode_Projects_AggregateArgs = {
  distinct_on?: Maybe<Array<Mode_Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mode_Project_Order_By>>;
  where?: Maybe<Mode_Project_Bool_Exp>;
};

/** aggregated selection of "recording_data.modes" */
export type Recording_Data_Modes_Aggregate = {
  __typename?: 'recording_data_modes_aggregate';
  aggregate?: Maybe<Recording_Data_Modes_Aggregate_Fields>;
  nodes: Array<Recording_Data_Modes>;
};

/** aggregate fields of "recording_data.modes" */
export type Recording_Data_Modes_Aggregate_Fields = {
  __typename?: 'recording_data_modes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Recording_Data_Modes_Max_Fields>;
  min?: Maybe<Recording_Data_Modes_Min_Fields>;
};


/** aggregate fields of "recording_data.modes" */
export type Recording_Data_Modes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Recording_Data_Modes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "recording_data.modes". All fields are combined with a logical 'AND'. */
export type Recording_Data_Modes_Bool_Exp = {
  _and?: Maybe<Array<Recording_Data_Modes_Bool_Exp>>;
  _not?: Maybe<Recording_Data_Modes_Bool_Exp>;
  _or?: Maybe<Array<Recording_Data_Modes_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  labels?: Maybe<Recording_Data_Labels_Bool_Exp>;
  mode_parameters?: Maybe<Recording_Data_Mode_Parameter_Bool_Exp>;
  mode_projects?: Maybe<Mode_Project_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "recording_data.modes" */
export enum Recording_Data_Modes_Constraint {
  /** unique or primary key constraint on columns "name" */
  ModesNameKey = 'modes_name_key',
  /** unique or primary key constraint on columns "id" */
  ModesPkey = 'modes_pkey'
}

/** input type for inserting data into table "recording_data.modes" */
export type Recording_Data_Modes_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  labels?: Maybe<Recording_Data_Labels_Arr_Rel_Insert_Input>;
  mode_parameters?: Maybe<Recording_Data_Mode_Parameter_Arr_Rel_Insert_Input>;
  mode_projects?: Maybe<Mode_Project_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Recording_Data_Modes_Max_Fields = {
  __typename?: 'recording_data_modes_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Recording_Data_Modes_Min_Fields = {
  __typename?: 'recording_data_modes_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "recording_data.modes" */
export type Recording_Data_Modes_Mutation_Response = {
  __typename?: 'recording_data_modes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Recording_Data_Modes>;
};

/** input type for inserting object relation for remote table "recording_data.modes" */
export type Recording_Data_Modes_Obj_Rel_Insert_Input = {
  data: Recording_Data_Modes_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Recording_Data_Modes_On_Conflict>;
};

/** on_conflict condition type for table "recording_data.modes" */
export type Recording_Data_Modes_On_Conflict = {
  constraint: Recording_Data_Modes_Constraint;
  update_columns?: Array<Recording_Data_Modes_Update_Column>;
  where?: Maybe<Recording_Data_Modes_Bool_Exp>;
};

/** Ordering options when selecting data from "recording_data.modes". */
export type Recording_Data_Modes_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  labels_aggregate?: Maybe<Recording_Data_Labels_Aggregate_Order_By>;
  mode_parameters_aggregate?: Maybe<Recording_Data_Mode_Parameter_Aggregate_Order_By>;
  mode_projects_aggregate?: Maybe<Mode_Project_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: recording_data.modes */
export type Recording_Data_Modes_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "recording_data.modes" */
export enum Recording_Data_Modes_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "recording_data.modes" */
export type Recording_Data_Modes_Set_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "recording_data_modes" */
export type Recording_Data_Modes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Recording_Data_Modes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recording_Data_Modes_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "recording_data.modes" */
export enum Recording_Data_Modes_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

export type Recording_Data_Modes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Recording_Data_Modes_Set_Input>;
  where: Recording_Data_Modes_Bool_Exp;
};

/** names given to the operating parameters that comprise the different "modes" */
export type Recording_Data_Parameters = {
  __typename?: 'recording_data_parameters';
  id: Scalars['uuid'];
  /** An array relationship */
  mode_parameters: Array<Recording_Data_Mode_Parameter>;
  /** An aggregate relationship */
  mode_parameters_aggregate: Recording_Data_Mode_Parameter_Aggregate;
  name: Scalars['String'];
  /** An object relationship */
  type: Types;
  type_id: Scalars['uuid'];
};


/** names given to the operating parameters that comprise the different "modes" */
export type Recording_Data_ParametersMode_ParametersArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Mode_Parameter_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Mode_Parameter_Order_By>>;
  where?: Maybe<Recording_Data_Mode_Parameter_Bool_Exp>;
};


/** names given to the operating parameters that comprise the different "modes" */
export type Recording_Data_ParametersMode_Parameters_AggregateArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Mode_Parameter_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Mode_Parameter_Order_By>>;
  where?: Maybe<Recording_Data_Mode_Parameter_Bool_Exp>;
};

/** aggregated selection of "recording_data.parameters" */
export type Recording_Data_Parameters_Aggregate = {
  __typename?: 'recording_data_parameters_aggregate';
  aggregate?: Maybe<Recording_Data_Parameters_Aggregate_Fields>;
  nodes: Array<Recording_Data_Parameters>;
};

/** aggregate fields of "recording_data.parameters" */
export type Recording_Data_Parameters_Aggregate_Fields = {
  __typename?: 'recording_data_parameters_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Recording_Data_Parameters_Max_Fields>;
  min?: Maybe<Recording_Data_Parameters_Min_Fields>;
};


/** aggregate fields of "recording_data.parameters" */
export type Recording_Data_Parameters_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Recording_Data_Parameters_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "recording_data.parameters". All fields are combined with a logical 'AND'. */
export type Recording_Data_Parameters_Bool_Exp = {
  _and?: Maybe<Array<Recording_Data_Parameters_Bool_Exp>>;
  _not?: Maybe<Recording_Data_Parameters_Bool_Exp>;
  _or?: Maybe<Array<Recording_Data_Parameters_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  mode_parameters?: Maybe<Recording_Data_Mode_Parameter_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  type?: Maybe<Types_Bool_Exp>;
  type_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "recording_data.parameters" */
export enum Recording_Data_Parameters_Constraint {
  /** unique or primary key constraint on columns "name" */
  ParametersNameKey = 'parameters_name_key',
  /** unique or primary key constraint on columns "id" */
  ParametersPkey = 'parameters_pkey'
}

/** input type for inserting data into table "recording_data.parameters" */
export type Recording_Data_Parameters_Insert_Input = {
  mode_parameters?: Maybe<Recording_Data_Mode_Parameter_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Types_Obj_Rel_Insert_Input>;
  type_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Recording_Data_Parameters_Max_Fields = {
  __typename?: 'recording_data_parameters_max_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  type_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Recording_Data_Parameters_Min_Fields = {
  __typename?: 'recording_data_parameters_min_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  type_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "recording_data.parameters" */
export type Recording_Data_Parameters_Mutation_Response = {
  __typename?: 'recording_data_parameters_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Recording_Data_Parameters>;
};

/** input type for inserting object relation for remote table "recording_data.parameters" */
export type Recording_Data_Parameters_Obj_Rel_Insert_Input = {
  data: Recording_Data_Parameters_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Recording_Data_Parameters_On_Conflict>;
};

/** on_conflict condition type for table "recording_data.parameters" */
export type Recording_Data_Parameters_On_Conflict = {
  constraint: Recording_Data_Parameters_Constraint;
  update_columns?: Array<Recording_Data_Parameters_Update_Column>;
  where?: Maybe<Recording_Data_Parameters_Bool_Exp>;
};

/** Ordering options when selecting data from "recording_data.parameters". */
export type Recording_Data_Parameters_Order_By = {
  id?: Maybe<Order_By>;
  mode_parameters_aggregate?: Maybe<Recording_Data_Mode_Parameter_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  type?: Maybe<Types_Order_By>;
  type_id?: Maybe<Order_By>;
};

/** primary key columns input for table: recording_data.parameters */
export type Recording_Data_Parameters_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "recording_data.parameters" */
export enum Recording_Data_Parameters_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  TypeId = 'type_id'
}

/** input type for updating data in table "recording_data.parameters" */
export type Recording_Data_Parameters_Set_Input = {
  name?: Maybe<Scalars['String']>;
  type_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "recording_data_parameters" */
export type Recording_Data_Parameters_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Recording_Data_Parameters_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recording_Data_Parameters_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  type_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "recording_data.parameters" */
export enum Recording_Data_Parameters_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  TypeId = 'type_id'
}

export type Recording_Data_Parameters_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Recording_Data_Parameters_Set_Input>;
  where: Recording_Data_Parameters_Bool_Exp;
};

/** Link payloads by pair id (UUID) and store payload timestamp offset */
export type Recording_Data_Payload_Pair = {
  __typename?: 'recording_data_payload_pair';
  /** Apply the timestamp offset (unit seconds) to the payload to align with other payloads in the same pair */
  offset?: Maybe<Scalars['float8']>;
  pair_id: Scalars['uuid'];
  /** An object relationship */
  payload: Payloads;
  payload_id: Scalars['uuid'];
};

/** Boolean expression to filter rows from the table "recording_data.payload_pair". All fields are combined with a logical 'AND'. */
export type Recording_Data_Payload_Pair_Bool_Exp = {
  _and?: Maybe<Array<Recording_Data_Payload_Pair_Bool_Exp>>;
  _not?: Maybe<Recording_Data_Payload_Pair_Bool_Exp>;
  _or?: Maybe<Array<Recording_Data_Payload_Pair_Bool_Exp>>;
  offset?: Maybe<Float8_Comparison_Exp>;
  pair_id?: Maybe<Uuid_Comparison_Exp>;
  payload?: Maybe<Payloads_Bool_Exp>;
  payload_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "recording_data.payload_pair" */
export enum Recording_Data_Payload_Pair_Constraint {
  /** unique or primary key constraint on columns "payload_id" */
  PayloadPairPkey = 'payload_pair_pkey'
}

/** input type for incrementing numeric columns in table "recording_data.payload_pair" */
export type Recording_Data_Payload_Pair_Inc_Input = {
  /** Apply the timestamp offset (unit seconds) to the payload to align with other payloads in the same pair */
  offset?: Maybe<Scalars['float8']>;
};

/** input type for inserting data into table "recording_data.payload_pair" */
export type Recording_Data_Payload_Pair_Insert_Input = {
  /** Apply the timestamp offset (unit seconds) to the payload to align with other payloads in the same pair */
  offset?: Maybe<Scalars['float8']>;
  pair_id?: Maybe<Scalars['uuid']>;
  payload?: Maybe<Payloads_Obj_Rel_Insert_Input>;
  payload_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "recording_data.payload_pair" */
export type Recording_Data_Payload_Pair_Mutation_Response = {
  __typename?: 'recording_data_payload_pair_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Recording_Data_Payload_Pair>;
};

/** on_conflict condition type for table "recording_data.payload_pair" */
export type Recording_Data_Payload_Pair_On_Conflict = {
  constraint: Recording_Data_Payload_Pair_Constraint;
  update_columns?: Array<Recording_Data_Payload_Pair_Update_Column>;
  where?: Maybe<Recording_Data_Payload_Pair_Bool_Exp>;
};

/** Ordering options when selecting data from "recording_data.payload_pair". */
export type Recording_Data_Payload_Pair_Order_By = {
  offset?: Maybe<Order_By>;
  pair_id?: Maybe<Order_By>;
  payload?: Maybe<Payloads_Order_By>;
  payload_id?: Maybe<Order_By>;
};

/** primary key columns input for table: recording_data.payload_pair */
export type Recording_Data_Payload_Pair_Pk_Columns_Input = {
  payload_id: Scalars['uuid'];
};

/** select columns of table "recording_data.payload_pair" */
export enum Recording_Data_Payload_Pair_Select_Column {
  /** column name */
  Offset = 'offset',
  /** column name */
  PairId = 'pair_id',
  /** column name */
  PayloadId = 'payload_id'
}

/** input type for updating data in table "recording_data.payload_pair" */
export type Recording_Data_Payload_Pair_Set_Input = {
  /** Apply the timestamp offset (unit seconds) to the payload to align with other payloads in the same pair */
  offset?: Maybe<Scalars['float8']>;
  pair_id?: Maybe<Scalars['uuid']>;
  payload_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "recording_data_payload_pair" */
export type Recording_Data_Payload_Pair_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Recording_Data_Payload_Pair_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recording_Data_Payload_Pair_Stream_Cursor_Value_Input = {
  /** Apply the timestamp offset (unit seconds) to the payload to align with other payloads in the same pair */
  offset?: Maybe<Scalars['float8']>;
  pair_id?: Maybe<Scalars['uuid']>;
  payload_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "recording_data.payload_pair" */
export enum Recording_Data_Payload_Pair_Update_Column {
  /** column name */
  Offset = 'offset',
  /** column name */
  PairId = 'pair_id',
  /** column name */
  PayloadId = 'payload_id'
}

export type Recording_Data_Payload_Pair_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Recording_Data_Payload_Pair_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Recording_Data_Payload_Pair_Set_Input>;
  where: Recording_Data_Payload_Pair_Bool_Exp;
};

/** Contains timestamped transitions between states simulated from engine data collected during recordings */
export type Recording_Data_Predictions = {
  __typename?: 'recording_data_predictions';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  engine: Engines;
  engine_id: Scalars['uuid'];
  /** An object relationship */
  recording: Recordings;
  recording_id: Scalars['uuid'];
  states: Scalars['_int2'];
  times: Scalars['_int4'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "recording_data.predictions" */
export type Recording_Data_Predictions_Aggregate = {
  __typename?: 'recording_data_predictions_aggregate';
  aggregate?: Maybe<Recording_Data_Predictions_Aggregate_Fields>;
  nodes: Array<Recording_Data_Predictions>;
};

/** aggregate fields of "recording_data.predictions" */
export type Recording_Data_Predictions_Aggregate_Fields = {
  __typename?: 'recording_data_predictions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Recording_Data_Predictions_Max_Fields>;
  min?: Maybe<Recording_Data_Predictions_Min_Fields>;
};


/** aggregate fields of "recording_data.predictions" */
export type Recording_Data_Predictions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Recording_Data_Predictions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "recording_data.predictions" */
export type Recording_Data_Predictions_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Recording_Data_Predictions_Max_Order_By>;
  min?: Maybe<Recording_Data_Predictions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "recording_data.predictions" */
export type Recording_Data_Predictions_Arr_Rel_Insert_Input = {
  data: Array<Recording_Data_Predictions_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Recording_Data_Predictions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "recording_data.predictions". All fields are combined with a logical 'AND'. */
export type Recording_Data_Predictions_Bool_Exp = {
  _and?: Maybe<Array<Recording_Data_Predictions_Bool_Exp>>;
  _not?: Maybe<Recording_Data_Predictions_Bool_Exp>;
  _or?: Maybe<Array<Recording_Data_Predictions_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  engine?: Maybe<Engines_Bool_Exp>;
  engine_id?: Maybe<Uuid_Comparison_Exp>;
  recording?: Maybe<Recordings_Bool_Exp>;
  recording_id?: Maybe<Uuid_Comparison_Exp>;
  states?: Maybe<_Int2_Comparison_Exp>;
  times?: Maybe<_Int4_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "recording_data.predictions" */
export enum Recording_Data_Predictions_Constraint {
  /** unique or primary key constraint on columns "engine_id", "recording_id" */
  PredictionsPkey = 'predictions_pkey'
}

/** input type for inserting data into table "recording_data.predictions" */
export type Recording_Data_Predictions_Insert_Input = {
  engine?: Maybe<Engines_Obj_Rel_Insert_Input>;
  engine_id?: Maybe<Scalars['uuid']>;
  recording?: Maybe<Recordings_Obj_Rel_Insert_Input>;
  recording_id?: Maybe<Scalars['uuid']>;
  states?: Maybe<Scalars['_int2']>;
  times?: Maybe<Scalars['_int4']>;
};

/** aggregate max on columns */
export type Recording_Data_Predictions_Max_Fields = {
  __typename?: 'recording_data_predictions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  engine_id?: Maybe<Scalars['uuid']>;
  recording_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "recording_data.predictions" */
export type Recording_Data_Predictions_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  engine_id?: Maybe<Order_By>;
  recording_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Recording_Data_Predictions_Min_Fields = {
  __typename?: 'recording_data_predictions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  engine_id?: Maybe<Scalars['uuid']>;
  recording_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "recording_data.predictions" */
export type Recording_Data_Predictions_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  engine_id?: Maybe<Order_By>;
  recording_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "recording_data.predictions" */
export type Recording_Data_Predictions_Mutation_Response = {
  __typename?: 'recording_data_predictions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Recording_Data_Predictions>;
};

/** on_conflict condition type for table "recording_data.predictions" */
export type Recording_Data_Predictions_On_Conflict = {
  constraint: Recording_Data_Predictions_Constraint;
  update_columns?: Array<Recording_Data_Predictions_Update_Column>;
  where?: Maybe<Recording_Data_Predictions_Bool_Exp>;
};

/** Ordering options when selecting data from "recording_data.predictions". */
export type Recording_Data_Predictions_Order_By = {
  created_at?: Maybe<Order_By>;
  engine?: Maybe<Engines_Order_By>;
  engine_id?: Maybe<Order_By>;
  recording?: Maybe<Recordings_Order_By>;
  recording_id?: Maybe<Order_By>;
  states?: Maybe<Order_By>;
  times?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: recording_data.predictions */
export type Recording_Data_Predictions_Pk_Columns_Input = {
  engine_id: Scalars['uuid'];
  recording_id: Scalars['uuid'];
};

/** select columns of table "recording_data.predictions" */
export enum Recording_Data_Predictions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EngineId = 'engine_id',
  /** column name */
  RecordingId = 'recording_id',
  /** column name */
  States = 'states',
  /** column name */
  Times = 'times',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "recording_data.predictions" */
export type Recording_Data_Predictions_Set_Input = {
  states?: Maybe<Scalars['_int2']>;
  times?: Maybe<Scalars['_int4']>;
};

/** Streaming cursor of the table "recording_data_predictions" */
export type Recording_Data_Predictions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Recording_Data_Predictions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recording_Data_Predictions_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  engine_id?: Maybe<Scalars['uuid']>;
  recording_id?: Maybe<Scalars['uuid']>;
  states?: Maybe<Scalars['_int2']>;
  times?: Maybe<Scalars['_int4']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "recording_data.predictions" */
export enum Recording_Data_Predictions_Update_Column {
  /** column name */
  States = 'states',
  /** column name */
  Times = 'times'
}

export type Recording_Data_Predictions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Recording_Data_Predictions_Set_Input>;
  where: Recording_Data_Predictions_Bool_Exp;
};

/** columns and relationships of "recording_recording_tag" */
export type Recording_Recording_Tag = {
  __typename?: 'recording_recording_tag';
  build_id_azure?: Maybe<Scalars['Int']>;
  /** An object relationship */
  recording: Recordings;
  recording_id: Scalars['uuid'];
  /** An object relationship */
  recording_tag: Recording_Tags;
  recording_tag_id: Scalars['uuid'];
  timestamp: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** order by aggregate values of table "recording_recording_tag" */
export type Recording_Recording_Tag_Aggregate_Order_By = {
  avg?: Maybe<Recording_Recording_Tag_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Recording_Recording_Tag_Max_Order_By>;
  min?: Maybe<Recording_Recording_Tag_Min_Order_By>;
  stddev?: Maybe<Recording_Recording_Tag_Stddev_Order_By>;
  stddev_pop?: Maybe<Recording_Recording_Tag_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Recording_Recording_Tag_Stddev_Samp_Order_By>;
  sum?: Maybe<Recording_Recording_Tag_Sum_Order_By>;
  var_pop?: Maybe<Recording_Recording_Tag_Var_Pop_Order_By>;
  var_samp?: Maybe<Recording_Recording_Tag_Var_Samp_Order_By>;
  variance?: Maybe<Recording_Recording_Tag_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "recording_recording_tag" */
export type Recording_Recording_Tag_Arr_Rel_Insert_Input = {
  data: Array<Recording_Recording_Tag_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Recording_Recording_Tag_On_Conflict>;
};

/** order by avg() on columns of table "recording_recording_tag" */
export type Recording_Recording_Tag_Avg_Order_By = {
  build_id_azure?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "recording_recording_tag". All fields are combined with a logical 'AND'. */
export type Recording_Recording_Tag_Bool_Exp = {
  _and?: Maybe<Array<Recording_Recording_Tag_Bool_Exp>>;
  _not?: Maybe<Recording_Recording_Tag_Bool_Exp>;
  _or?: Maybe<Array<Recording_Recording_Tag_Bool_Exp>>;
  build_id_azure?: Maybe<Int_Comparison_Exp>;
  recording?: Maybe<Recordings_Bool_Exp>;
  recording_id?: Maybe<Uuid_Comparison_Exp>;
  recording_tag?: Maybe<Recording_Tags_Bool_Exp>;
  recording_tag_id?: Maybe<Uuid_Comparison_Exp>;
  timestamp?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "recording_recording_tag" */
export enum Recording_Recording_Tag_Constraint {
  /** unique or primary key constraint on columns "recording_id", "recording_tag_id" */
  RecordingRecordingTagPkey = 'recording_recording_tag_pkey'
}

/** input type for incrementing numeric columns in table "recording_recording_tag" */
export type Recording_Recording_Tag_Inc_Input = {
  build_id_azure?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "recording_recording_tag" */
export type Recording_Recording_Tag_Insert_Input = {
  build_id_azure?: Maybe<Scalars['Int']>;
  recording?: Maybe<Recordings_Obj_Rel_Insert_Input>;
  recording_id?: Maybe<Scalars['uuid']>;
  recording_tag?: Maybe<Recording_Tags_Obj_Rel_Insert_Input>;
  recording_tag_id?: Maybe<Scalars['uuid']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "recording_recording_tag" */
export type Recording_Recording_Tag_Max_Order_By = {
  build_id_azure?: Maybe<Order_By>;
  recording_id?: Maybe<Order_By>;
  recording_tag_id?: Maybe<Order_By>;
  timestamp?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by min() on columns of table "recording_recording_tag" */
export type Recording_Recording_Tag_Min_Order_By = {
  build_id_azure?: Maybe<Order_By>;
  recording_id?: Maybe<Order_By>;
  recording_tag_id?: Maybe<Order_By>;
  timestamp?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "recording_recording_tag" */
export type Recording_Recording_Tag_Mutation_Response = {
  __typename?: 'recording_recording_tag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Recording_Recording_Tag>;
};

/** on_conflict condition type for table "recording_recording_tag" */
export type Recording_Recording_Tag_On_Conflict = {
  constraint: Recording_Recording_Tag_Constraint;
  update_columns?: Array<Recording_Recording_Tag_Update_Column>;
  where?: Maybe<Recording_Recording_Tag_Bool_Exp>;
};

/** Ordering options when selecting data from "recording_recording_tag". */
export type Recording_Recording_Tag_Order_By = {
  build_id_azure?: Maybe<Order_By>;
  recording?: Maybe<Recordings_Order_By>;
  recording_id?: Maybe<Order_By>;
  recording_tag?: Maybe<Recording_Tags_Order_By>;
  recording_tag_id?: Maybe<Order_By>;
  timestamp?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: recording_recording_tag */
export type Recording_Recording_Tag_Pk_Columns_Input = {
  recording_id: Scalars['uuid'];
  recording_tag_id: Scalars['uuid'];
};

/** select columns of table "recording_recording_tag" */
export enum Recording_Recording_Tag_Select_Column {
  /** column name */
  BuildIdAzure = 'build_id_azure',
  /** column name */
  RecordingId = 'recording_id',
  /** column name */
  RecordingTagId = 'recording_tag_id',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "recording_recording_tag" */
export type Recording_Recording_Tag_Set_Input = {
  build_id_azure?: Maybe<Scalars['Int']>;
  recording_id?: Maybe<Scalars['uuid']>;
  recording_tag_id?: Maybe<Scalars['uuid']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "recording_recording_tag" */
export type Recording_Recording_Tag_Stddev_Order_By = {
  build_id_azure?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "recording_recording_tag" */
export type Recording_Recording_Tag_Stddev_Pop_Order_By = {
  build_id_azure?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "recording_recording_tag" */
export type Recording_Recording_Tag_Stddev_Samp_Order_By = {
  build_id_azure?: Maybe<Order_By>;
};

/** Streaming cursor of the table "recording_recording_tag" */
export type Recording_Recording_Tag_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Recording_Recording_Tag_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recording_Recording_Tag_Stream_Cursor_Value_Input = {
  build_id_azure?: Maybe<Scalars['Int']>;
  recording_id?: Maybe<Scalars['uuid']>;
  recording_tag_id?: Maybe<Scalars['uuid']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "recording_recording_tag" */
export type Recording_Recording_Tag_Sum_Order_By = {
  build_id_azure?: Maybe<Order_By>;
};

/** update columns of table "recording_recording_tag" */
export enum Recording_Recording_Tag_Update_Column {
  /** column name */
  BuildIdAzure = 'build_id_azure',
  /** column name */
  RecordingId = 'recording_id',
  /** column name */
  RecordingTagId = 'recording_tag_id',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  UserId = 'user_id'
}

export type Recording_Recording_Tag_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Recording_Recording_Tag_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Recording_Recording_Tag_Set_Input>;
  where: Recording_Recording_Tag_Bool_Exp;
};

/** order by var_pop() on columns of table "recording_recording_tag" */
export type Recording_Recording_Tag_Var_Pop_Order_By = {
  build_id_azure?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "recording_recording_tag" */
export type Recording_Recording_Tag_Var_Samp_Order_By = {
  build_id_azure?: Maybe<Order_By>;
};

/** order by variance() on columns of table "recording_recording_tag" */
export type Recording_Recording_Tag_Variance_Order_By = {
  build_id_azure?: Maybe<Order_By>;
};

export type Recording_Tag_Count_By_Day_Args = {
  exclude_tag_names?: Maybe<Scalars['_text']>;
  project_ids?: Maybe<Scalars['_uuid']>;
};

/** columns and relationships of "recording_tags" */
export type Recording_Tags = {
  __typename?: 'recording_tags';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  dirty: Scalars['Boolean'];
  field?: Maybe<Scalars['String']>;
  highlight: Scalars['Boolean'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  recording_recording_tags: Array<Recording_Recording_Tag>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "recording_tags" */
export type Recording_TagsRecording_Recording_TagsArgs = {
  distinct_on?: Maybe<Array<Recording_Recording_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Recording_Tag_Order_By>>;
  where?: Maybe<Recording_Recording_Tag_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "recording_tags". All fields are combined with a logical 'AND'. */
export type Recording_Tags_Bool_Exp = {
  _and?: Maybe<Array<Recording_Tags_Bool_Exp>>;
  _not?: Maybe<Recording_Tags_Bool_Exp>;
  _or?: Maybe<Array<Recording_Tags_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  dirty?: Maybe<Boolean_Comparison_Exp>;
  field?: Maybe<String_Comparison_Exp>;
  highlight?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  recording_recording_tags?: Maybe<Recording_Recording_Tag_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "recording_tags" */
export enum Recording_Tags_Constraint {
  /** unique or primary key constraint on columns "name" */
  RecordingTagsNameKey = 'recording_tags_name_key',
  /** unique or primary key constraint on columns "id" */
  RecordingTagsPkey = 'recording_tags_pkey'
}

/** input type for inserting data into table "recording_tags" */
export type Recording_Tags_Insert_Input = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  dirty?: Maybe<Scalars['Boolean']>;
  field?: Maybe<Scalars['String']>;
  highlight?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  recording_recording_tags?: Maybe<Recording_Recording_Tag_Arr_Rel_Insert_Input>;
};

/** response of any mutation on the table "recording_tags" */
export type Recording_Tags_Mutation_Response = {
  __typename?: 'recording_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Recording_Tags>;
};

/** input type for inserting object relation for remote table "recording_tags" */
export type Recording_Tags_Obj_Rel_Insert_Input = {
  data: Recording_Tags_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Recording_Tags_On_Conflict>;
};

/** on_conflict condition type for table "recording_tags" */
export type Recording_Tags_On_Conflict = {
  constraint: Recording_Tags_Constraint;
  update_columns?: Array<Recording_Tags_Update_Column>;
  where?: Maybe<Recording_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "recording_tags". */
export type Recording_Tags_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  dirty?: Maybe<Order_By>;
  field?: Maybe<Order_By>;
  highlight?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  recording_recording_tags_aggregate?: Maybe<Recording_Recording_Tag_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: recording_tags */
export type Recording_Tags_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "recording_tags" */
export enum Recording_Tags_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Dirty = 'dirty',
  /** column name */
  Field = 'field',
  /** column name */
  Highlight = 'highlight',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "recording_tags" */
export type Recording_Tags_Set_Input = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  dirty?: Maybe<Scalars['Boolean']>;
  field?: Maybe<Scalars['String']>;
  highlight?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "recording_tags" */
export type Recording_Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Recording_Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recording_Tags_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  dirty?: Maybe<Scalars['Boolean']>;
  field?: Maybe<Scalars['String']>;
  highlight?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "recording_tags" */
export enum Recording_Tags_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Dirty = 'dirty',
  /** column name */
  Field = 'field',
  /** column name */
  Highlight = 'highlight',
  /** column name */
  Name = 'name'
}

export type Recording_Tags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Recording_Tags_Set_Input>;
  where: Recording_Tags_Bool_Exp;
};

/** columns and relationships of "recordings" */
export type Recordings = {
  __typename?: 'recordings';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  device: Devices;
  /** An object relationship */
  device_firmware?: Maybe<Device_Firmware>;
  device_id: Scalars['uuid'];
  /** An array relationship */
  engine_opmode_recordings: Array<Engine_Opmode_Recording>;
  /** An aggregate relationship */
  engine_opmode_recordings_aggregate: Engine_Opmode_Recording_Aggregate;
  /** An object relationship */
  firmware: Firmware;
  firmware_id: Scalars['uuid'];
  /** An object relationship */
  ground_truth?: Maybe<Recording_Data_Ground_Truth>;
  /** An object relationship */
  ground_truth_configuration?: Maybe<Ground_Truth_Recordings>;
  /** An array relationship */
  ground_truth_labels: Array<Recording_Data_Labels>;
  /** An aggregate relationship */
  ground_truth_labels_aggregate: Recording_Data_Labels_Aggregate;
  /** An object relationship */
  hypertask?: Maybe<Hypertasks>;
  hypertask_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  /** An array relationship */
  payload_recordings: Array<Payload_Recording>;
  /** An aggregate relationship */
  payload_recordings_aggregate: Payload_Recording_Aggregate;
  peripheral_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  predictions: Array<Recording_Data_Predictions>;
  /** An aggregate relationship */
  predictions_aggregate: Recording_Data_Predictions_Aggregate;
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
  /** An object relationship */
  rabbit_test?: Maybe<Rabbit_Test_Plan_Recording>;
  random_number: Scalars['float8'];
  /** An array relationship */
  ratio_metric_contributions: Array<Performance_Ratio_Metric_Contributions>;
  /** An aggregate relationship */
  ratio_metric_contributions_aggregate: Performance_Ratio_Metric_Contributions_Aggregate;
  recorded_at: Scalars['timestamptz'];
  /** An object relationship */
  recording_apps?: Maybe<Recording_Apps>;
  /** An array relationship */
  recording_audio_drivers: Array<Recording_Audio_Driver>;
  room_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  rooms?: Maybe<Rooms>;
  scaling_factor?: Maybe<Scalars['Int']>;
  /** An array relationship */
  tags: Array<Recording_Recording_Tag>;
  /** An object relationship */
  task: Tasks;
  task_id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};


/** columns and relationships of "recordings" */
export type RecordingsEngine_Opmode_RecordingsArgs = {
  distinct_on?: Maybe<Array<Engine_Opmode_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Engine_Opmode_Recording_Order_By>>;
  where?: Maybe<Engine_Opmode_Recording_Bool_Exp>;
};


/** columns and relationships of "recordings" */
export type RecordingsEngine_Opmode_Recordings_AggregateArgs = {
  distinct_on?: Maybe<Array<Engine_Opmode_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Engine_Opmode_Recording_Order_By>>;
  where?: Maybe<Engine_Opmode_Recording_Bool_Exp>;
};


/** columns and relationships of "recordings" */
export type RecordingsGround_Truth_LabelsArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Labels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Labels_Order_By>>;
  where?: Maybe<Recording_Data_Labels_Bool_Exp>;
};


/** columns and relationships of "recordings" */
export type RecordingsGround_Truth_Labels_AggregateArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Labels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Labels_Order_By>>;
  where?: Maybe<Recording_Data_Labels_Bool_Exp>;
};


/** columns and relationships of "recordings" */
export type RecordingsPayload_RecordingsArgs = {
  distinct_on?: Maybe<Array<Payload_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payload_Recording_Order_By>>;
  where?: Maybe<Payload_Recording_Bool_Exp>;
};


/** columns and relationships of "recordings" */
export type RecordingsPayload_Recordings_AggregateArgs = {
  distinct_on?: Maybe<Array<Payload_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payload_Recording_Order_By>>;
  where?: Maybe<Payload_Recording_Bool_Exp>;
};


/** columns and relationships of "recordings" */
export type RecordingsPredictionsArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Predictions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Predictions_Order_By>>;
  where?: Maybe<Recording_Data_Predictions_Bool_Exp>;
};


/** columns and relationships of "recordings" */
export type RecordingsPredictions_AggregateArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Predictions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Predictions_Order_By>>;
  where?: Maybe<Recording_Data_Predictions_Bool_Exp>;
};


/** columns and relationships of "recordings" */
export type RecordingsRatio_Metric_ContributionsArgs = {
  distinct_on?: Maybe<Array<Performance_Ratio_Metric_Contributions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Ratio_Metric_Contributions_Order_By>>;
  where?: Maybe<Performance_Ratio_Metric_Contributions_Bool_Exp>;
};


/** columns and relationships of "recordings" */
export type RecordingsRatio_Metric_Contributions_AggregateArgs = {
  distinct_on?: Maybe<Array<Performance_Ratio_Metric_Contributions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Ratio_Metric_Contributions_Order_By>>;
  where?: Maybe<Performance_Ratio_Metric_Contributions_Bool_Exp>;
};


/** columns and relationships of "recordings" */
export type RecordingsRecording_Audio_DriversArgs = {
  distinct_on?: Maybe<Array<Recording_Audio_Driver_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Audio_Driver_Order_By>>;
  where?: Maybe<Recording_Audio_Driver_Bool_Exp>;
};


/** columns and relationships of "recordings" */
export type RecordingsTagsArgs = {
  distinct_on?: Maybe<Array<Recording_Recording_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Recording_Tag_Order_By>>;
  where?: Maybe<Recording_Recording_Tag_Bool_Exp>;
};

/** aggregated selection of "recordings" */
export type Recordings_Aggregate = {
  __typename?: 'recordings_aggregate';
  aggregate?: Maybe<Recordings_Aggregate_Fields>;
  nodes: Array<Recordings>;
};

/** aggregate fields of "recordings" */
export type Recordings_Aggregate_Fields = {
  __typename?: 'recordings_aggregate_fields';
  avg?: Maybe<Recordings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Recordings_Max_Fields>;
  min?: Maybe<Recordings_Min_Fields>;
  stddev?: Maybe<Recordings_Stddev_Fields>;
  stddev_pop?: Maybe<Recordings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Recordings_Stddev_Samp_Fields>;
  sum?: Maybe<Recordings_Sum_Fields>;
  var_pop?: Maybe<Recordings_Var_Pop_Fields>;
  var_samp?: Maybe<Recordings_Var_Samp_Fields>;
  variance?: Maybe<Recordings_Variance_Fields>;
};


/** aggregate fields of "recordings" */
export type Recordings_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Recordings_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "recordings" */
export type Recordings_Aggregate_Order_By = {
  avg?: Maybe<Recordings_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Recordings_Max_Order_By>;
  min?: Maybe<Recordings_Min_Order_By>;
  stddev?: Maybe<Recordings_Stddev_Order_By>;
  stddev_pop?: Maybe<Recordings_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Recordings_Stddev_Samp_Order_By>;
  sum?: Maybe<Recordings_Sum_Order_By>;
  var_pop?: Maybe<Recordings_Var_Pop_Order_By>;
  var_samp?: Maybe<Recordings_Var_Samp_Order_By>;
  variance?: Maybe<Recordings_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "recordings" */
export type Recordings_Arr_Rel_Insert_Input = {
  data: Array<Recordings_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Recordings_On_Conflict>;
};

/** aggregate avg on columns */
export type Recordings_Avg_Fields = {
  __typename?: 'recordings_avg_fields';
  random_number?: Maybe<Scalars['Float']>;
  scaling_factor?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "recordings" */
export type Recordings_Avg_Order_By = {
  random_number?: Maybe<Order_By>;
  scaling_factor?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "recordings". All fields are combined with a logical 'AND'. */
export type Recordings_Bool_Exp = {
  _and?: Maybe<Array<Recordings_Bool_Exp>>;
  _not?: Maybe<Recordings_Bool_Exp>;
  _or?: Maybe<Array<Recordings_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  device?: Maybe<Devices_Bool_Exp>;
  device_firmware?: Maybe<Device_Firmware_Bool_Exp>;
  device_id?: Maybe<Uuid_Comparison_Exp>;
  engine_opmode_recordings?: Maybe<Engine_Opmode_Recording_Bool_Exp>;
  firmware?: Maybe<Firmware_Bool_Exp>;
  firmware_id?: Maybe<Uuid_Comparison_Exp>;
  ground_truth?: Maybe<Recording_Data_Ground_Truth_Bool_Exp>;
  ground_truth_configuration?: Maybe<Ground_Truth_Recordings_Bool_Exp>;
  ground_truth_labels?: Maybe<Recording_Data_Labels_Bool_Exp>;
  hypertask?: Maybe<Hypertasks_Bool_Exp>;
  hypertask_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  payload_recordings?: Maybe<Payload_Recording_Bool_Exp>;
  peripheral_id?: Maybe<Uuid_Comparison_Exp>;
  predictions?: Maybe<Recording_Data_Predictions_Bool_Exp>;
  project?: Maybe<Projects_Bool_Exp>;
  project_id?: Maybe<Uuid_Comparison_Exp>;
  rabbit_test?: Maybe<Rabbit_Test_Plan_Recording_Bool_Exp>;
  random_number?: Maybe<Float8_Comparison_Exp>;
  ratio_metric_contributions?: Maybe<Performance_Ratio_Metric_Contributions_Bool_Exp>;
  recorded_at?: Maybe<Timestamptz_Comparison_Exp>;
  recording_apps?: Maybe<Recording_Apps_Bool_Exp>;
  recording_audio_drivers?: Maybe<Recording_Audio_Driver_Bool_Exp>;
  room_id?: Maybe<Uuid_Comparison_Exp>;
  rooms?: Maybe<Rooms_Bool_Exp>;
  scaling_factor?: Maybe<Int_Comparison_Exp>;
  tags?: Maybe<Recording_Recording_Tag_Bool_Exp>;
  task?: Maybe<Tasks_Bool_Exp>;
  task_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "recordings" */
export enum Recordings_Constraint {
  /** unique or primary key constraint on columns "id" */
  RecordingsPkey = 'recordings_pkey',
  /** unique or primary key constraint on columns "recorded_at", "device_id" */
  RecordingsRecordedAtDeviceIdKey = 'recordings_recorded_at_device_id_key'
}

/** input type for incrementing numeric columns in table "recordings" */
export type Recordings_Inc_Input = {
  random_number?: Maybe<Scalars['float8']>;
  scaling_factor?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "recordings" */
export type Recordings_Insert_Input = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  device?: Maybe<Devices_Obj_Rel_Insert_Input>;
  device_firmware?: Maybe<Device_Firmware_Obj_Rel_Insert_Input>;
  device_id?: Maybe<Scalars['uuid']>;
  engine_opmode_recordings?: Maybe<Engine_Opmode_Recording_Arr_Rel_Insert_Input>;
  firmware?: Maybe<Firmware_Obj_Rel_Insert_Input>;
  firmware_id?: Maybe<Scalars['uuid']>;
  ground_truth?: Maybe<Recording_Data_Ground_Truth_Obj_Rel_Insert_Input>;
  ground_truth_configuration?: Maybe<Ground_Truth_Recordings_Obj_Rel_Insert_Input>;
  ground_truth_labels?: Maybe<Recording_Data_Labels_Arr_Rel_Insert_Input>;
  hypertask?: Maybe<Hypertasks_Obj_Rel_Insert_Input>;
  hypertask_id?: Maybe<Scalars['uuid']>;
  payload_recordings?: Maybe<Payload_Recording_Arr_Rel_Insert_Input>;
  predictions?: Maybe<Recording_Data_Predictions_Arr_Rel_Insert_Input>;
  project?: Maybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: Maybe<Scalars['uuid']>;
  rabbit_test?: Maybe<Rabbit_Test_Plan_Recording_Obj_Rel_Insert_Input>;
  random_number?: Maybe<Scalars['float8']>;
  ratio_metric_contributions?: Maybe<Performance_Ratio_Metric_Contributions_Arr_Rel_Insert_Input>;
  recorded_at?: Maybe<Scalars['timestamptz']>;
  recording_apps?: Maybe<Recording_Apps_Obj_Rel_Insert_Input>;
  recording_audio_drivers?: Maybe<Recording_Audio_Driver_Arr_Rel_Insert_Input>;
  room_id?: Maybe<Scalars['uuid']>;
  rooms?: Maybe<Rooms_Obj_Rel_Insert_Input>;
  scaling_factor?: Maybe<Scalars['Int']>;
  tags?: Maybe<Recording_Recording_Tag_Arr_Rel_Insert_Input>;
  task?: Maybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: Maybe<Scalars['uuid']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Recordings_Max_Fields = {
  __typename?: 'recordings_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  device_id?: Maybe<Scalars['uuid']>;
  firmware_id?: Maybe<Scalars['uuid']>;
  hypertask_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  peripheral_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  random_number?: Maybe<Scalars['float8']>;
  recorded_at?: Maybe<Scalars['timestamptz']>;
  room_id?: Maybe<Scalars['uuid']>;
  scaling_factor?: Maybe<Scalars['Int']>;
  task_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "recordings" */
export type Recordings_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  device_id?: Maybe<Order_By>;
  firmware_id?: Maybe<Order_By>;
  hypertask_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  peripheral_id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  random_number?: Maybe<Order_By>;
  recorded_at?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
  scaling_factor?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Recordings_Min_Fields = {
  __typename?: 'recordings_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  device_id?: Maybe<Scalars['uuid']>;
  firmware_id?: Maybe<Scalars['uuid']>;
  hypertask_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  peripheral_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  random_number?: Maybe<Scalars['float8']>;
  recorded_at?: Maybe<Scalars['timestamptz']>;
  room_id?: Maybe<Scalars['uuid']>;
  scaling_factor?: Maybe<Scalars['Int']>;
  task_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "recordings" */
export type Recordings_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  device_id?: Maybe<Order_By>;
  firmware_id?: Maybe<Order_By>;
  hypertask_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  peripheral_id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  random_number?: Maybe<Order_By>;
  recorded_at?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
  scaling_factor?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "recordings" */
export type Recordings_Mutation_Response = {
  __typename?: 'recordings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Recordings>;
};

/** input type for inserting object relation for remote table "recordings" */
export type Recordings_Obj_Rel_Insert_Input = {
  data: Recordings_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Recordings_On_Conflict>;
};

/** on_conflict condition type for table "recordings" */
export type Recordings_On_Conflict = {
  constraint: Recordings_Constraint;
  update_columns?: Array<Recordings_Update_Column>;
  where?: Maybe<Recordings_Bool_Exp>;
};

/** Ordering options when selecting data from "recordings". */
export type Recordings_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  device?: Maybe<Devices_Order_By>;
  device_firmware?: Maybe<Device_Firmware_Order_By>;
  device_id?: Maybe<Order_By>;
  engine_opmode_recordings_aggregate?: Maybe<Engine_Opmode_Recording_Aggregate_Order_By>;
  firmware?: Maybe<Firmware_Order_By>;
  firmware_id?: Maybe<Order_By>;
  ground_truth?: Maybe<Recording_Data_Ground_Truth_Order_By>;
  ground_truth_configuration?: Maybe<Ground_Truth_Recordings_Order_By>;
  ground_truth_labels_aggregate?: Maybe<Recording_Data_Labels_Aggregate_Order_By>;
  hypertask?: Maybe<Hypertasks_Order_By>;
  hypertask_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payload_recordings_aggregate?: Maybe<Payload_Recording_Aggregate_Order_By>;
  peripheral_id?: Maybe<Order_By>;
  predictions_aggregate?: Maybe<Recording_Data_Predictions_Aggregate_Order_By>;
  project?: Maybe<Projects_Order_By>;
  project_id?: Maybe<Order_By>;
  rabbit_test?: Maybe<Rabbit_Test_Plan_Recording_Order_By>;
  random_number?: Maybe<Order_By>;
  ratio_metric_contributions_aggregate?: Maybe<Performance_Ratio_Metric_Contributions_Aggregate_Order_By>;
  recorded_at?: Maybe<Order_By>;
  recording_apps?: Maybe<Recording_Apps_Order_By>;
  recording_audio_drivers_aggregate?: Maybe<Recording_Audio_Driver_Aggregate_Order_By>;
  room_id?: Maybe<Order_By>;
  rooms?: Maybe<Rooms_Order_By>;
  scaling_factor?: Maybe<Order_By>;
  tags_aggregate?: Maybe<Recording_Recording_Tag_Aggregate_Order_By>;
  task?: Maybe<Tasks_Order_By>;
  task_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: recordings */
export type Recordings_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "recordings" */
export enum Recordings_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  FirmwareId = 'firmware_id',
  /** column name */
  HypertaskId = 'hypertask_id',
  /** column name */
  Id = 'id',
  /** column name */
  PeripheralId = 'peripheral_id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  RandomNumber = 'random_number',
  /** column name */
  RecordedAt = 'recorded_at',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  ScalingFactor = 'scaling_factor',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "recordings" */
export type Recordings_Set_Input = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  device_id?: Maybe<Scalars['uuid']>;
  firmware_id?: Maybe<Scalars['uuid']>;
  hypertask_id?: Maybe<Scalars['uuid']>;
  peripheral_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  random_number?: Maybe<Scalars['float8']>;
  recorded_at?: Maybe<Scalars['timestamptz']>;
  room_id?: Maybe<Scalars['uuid']>;
  scaling_factor?: Maybe<Scalars['Int']>;
  task_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Recordings_Stddev_Fields = {
  __typename?: 'recordings_stddev_fields';
  random_number?: Maybe<Scalars['Float']>;
  scaling_factor?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "recordings" */
export type Recordings_Stddev_Order_By = {
  random_number?: Maybe<Order_By>;
  scaling_factor?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Recordings_Stddev_Pop_Fields = {
  __typename?: 'recordings_stddev_pop_fields';
  random_number?: Maybe<Scalars['Float']>;
  scaling_factor?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "recordings" */
export type Recordings_Stddev_Pop_Order_By = {
  random_number?: Maybe<Order_By>;
  scaling_factor?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Recordings_Stddev_Samp_Fields = {
  __typename?: 'recordings_stddev_samp_fields';
  random_number?: Maybe<Scalars['Float']>;
  scaling_factor?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "recordings" */
export type Recordings_Stddev_Samp_Order_By = {
  random_number?: Maybe<Order_By>;
  scaling_factor?: Maybe<Order_By>;
};

/** Streaming cursor of the table "recordings" */
export type Recordings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Recordings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recordings_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  device_id?: Maybe<Scalars['uuid']>;
  firmware_id?: Maybe<Scalars['uuid']>;
  hypertask_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  peripheral_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  random_number?: Maybe<Scalars['float8']>;
  recorded_at?: Maybe<Scalars['timestamptz']>;
  room_id?: Maybe<Scalars['uuid']>;
  scaling_factor?: Maybe<Scalars['Int']>;
  task_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Recordings_Sum_Fields = {
  __typename?: 'recordings_sum_fields';
  random_number?: Maybe<Scalars['float8']>;
  scaling_factor?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "recordings" */
export type Recordings_Sum_Order_By = {
  random_number?: Maybe<Order_By>;
  scaling_factor?: Maybe<Order_By>;
};

/** update columns of table "recordings" */
export enum Recordings_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  FirmwareId = 'firmware_id',
  /** column name */
  HypertaskId = 'hypertask_id',
  /** column name */
  PeripheralId = 'peripheral_id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  RandomNumber = 'random_number',
  /** column name */
  RecordedAt = 'recorded_at',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  ScalingFactor = 'scaling_factor',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UserId = 'user_id'
}

export type Recordings_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Recordings_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Recordings_Set_Input>;
  where: Recordings_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Recordings_Var_Pop_Fields = {
  __typename?: 'recordings_var_pop_fields';
  random_number?: Maybe<Scalars['Float']>;
  scaling_factor?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "recordings" */
export type Recordings_Var_Pop_Order_By = {
  random_number?: Maybe<Order_By>;
  scaling_factor?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Recordings_Var_Samp_Fields = {
  __typename?: 'recordings_var_samp_fields';
  random_number?: Maybe<Scalars['Float']>;
  scaling_factor?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "recordings" */
export type Recordings_Var_Samp_Order_By = {
  random_number?: Maybe<Order_By>;
  scaling_factor?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Recordings_Variance_Fields = {
  __typename?: 'recordings_variance_fields';
  random_number?: Maybe<Scalars['Float']>;
  scaling_factor?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "recordings" */
export type Recordings_Variance_Order_By = {
  random_number?: Maybe<Order_By>;
  scaling_factor?: Maybe<Order_By>;
};

/** columns and relationships of "role_user" */
export type Role_User = {
  __typename?: 'role_user';
  /** An object relationship */
  role: Roles;
  role_id: Roles_Enum;
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "role_user" */
export type Role_User_Aggregate = {
  __typename?: 'role_user_aggregate';
  aggregate?: Maybe<Role_User_Aggregate_Fields>;
  nodes: Array<Role_User>;
};

/** aggregate fields of "role_user" */
export type Role_User_Aggregate_Fields = {
  __typename?: 'role_user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Role_User_Max_Fields>;
  min?: Maybe<Role_User_Min_Fields>;
};


/** aggregate fields of "role_user" */
export type Role_User_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Role_User_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "role_user" */
export type Role_User_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Role_User_Max_Order_By>;
  min?: Maybe<Role_User_Min_Order_By>;
};

/** input type for inserting array relation for remote table "role_user" */
export type Role_User_Arr_Rel_Insert_Input = {
  data: Array<Role_User_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Role_User_On_Conflict>;
};

/** Boolean expression to filter rows from the table "role_user". All fields are combined with a logical 'AND'. */
export type Role_User_Bool_Exp = {
  _and?: Maybe<Array<Role_User_Bool_Exp>>;
  _not?: Maybe<Role_User_Bool_Exp>;
  _or?: Maybe<Array<Role_User_Bool_Exp>>;
  role?: Maybe<Roles_Bool_Exp>;
  role_id?: Maybe<Roles_Enum_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "role_user" */
export enum Role_User_Constraint {
  /** unique or primary key constraint on columns "role_id", "user_id" */
  RoleUserPkey = 'role_user_pkey'
}

/** input type for inserting data into table "role_user" */
export type Role_User_Insert_Input = {
  role_id?: Maybe<Roles_Enum>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Role_User_Max_Fields = {
  __typename?: 'role_user_max_fields';
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "role_user" */
export type Role_User_Max_Order_By = {
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Role_User_Min_Fields = {
  __typename?: 'role_user_min_fields';
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "role_user" */
export type Role_User_Min_Order_By = {
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "role_user" */
export type Role_User_Mutation_Response = {
  __typename?: 'role_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Role_User>;
};

/** on_conflict condition type for table "role_user" */
export type Role_User_On_Conflict = {
  constraint: Role_User_Constraint;
  update_columns?: Array<Role_User_Update_Column>;
  where?: Maybe<Role_User_Bool_Exp>;
};

/** Ordering options when selecting data from "role_user". */
export type Role_User_Order_By = {
  role?: Maybe<Roles_Order_By>;
  role_id?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: role_user */
export type Role_User_Pk_Columns_Input = {
  role_id: Roles_Enum;
  user_id: Scalars['uuid'];
};

/** select columns of table "role_user" */
export enum Role_User_Select_Column {
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "role_user" */
export type Role_User_Set_Input = {
  role_id?: Maybe<Roles_Enum>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "role_user" */
export type Role_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Role_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Role_User_Stream_Cursor_Value_Input = {
  role_id?: Maybe<Roles_Enum>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "role_user" */
export enum Role_User_Update_Column {
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UserId = 'user_id'
}

export type Role_User_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Role_User_Set_Input>;
  where: Role_User_Bool_Exp;
};

/** columns and relationships of "roles" */
export type Roles = {
  __typename?: 'roles';
  comment: Scalars['String'];
  id: Scalars['String'];
  /** An array relationship */
  role_users: Array<Role_User>;
  /** An aggregate relationship */
  role_users_aggregate: Role_User_Aggregate;
};


/** columns and relationships of "roles" */
export type RolesRole_UsersArgs = {
  distinct_on?: Maybe<Array<Role_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_User_Order_By>>;
  where?: Maybe<Role_User_Bool_Exp>;
};


/** columns and relationships of "roles" */
export type RolesRole_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Role_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_User_Order_By>>;
  where?: Maybe<Role_User_Bool_Exp>;
};

/** aggregated selection of "roles" */
export type Roles_Aggregate = {
  __typename?: 'roles_aggregate';
  aggregate?: Maybe<Roles_Aggregate_Fields>;
  nodes: Array<Roles>;
};

/** aggregate fields of "roles" */
export type Roles_Aggregate_Fields = {
  __typename?: 'roles_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Roles_Max_Fields>;
  min?: Maybe<Roles_Min_Fields>;
};


/** aggregate fields of "roles" */
export type Roles_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Roles_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "roles". All fields are combined with a logical 'AND'. */
export type Roles_Bool_Exp = {
  _and?: Maybe<Array<Roles_Bool_Exp>>;
  _not?: Maybe<Roles_Bool_Exp>;
  _or?: Maybe<Array<Roles_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  role_users?: Maybe<Role_User_Bool_Exp>;
};

export enum Roles_Enum {
  /** can do mostly anything */
  Asgard = 'asgard',
  /** can do almost nothing */
  Bifrost = 'bifrost',
  /** can do a bit less */
  Midgard = 'midgard'
}

/** Boolean expression to compare columns of type "roles_enum". All fields are combined with logical 'AND'. */
export type Roles_Enum_Comparison_Exp = {
  _eq?: Maybe<Roles_Enum>;
  _in?: Maybe<Array<Roles_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Roles_Enum>;
  _nin?: Maybe<Array<Roles_Enum>>;
};

/** aggregate max on columns */
export type Roles_Max_Fields = {
  __typename?: 'roles_max_fields';
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Roles_Min_Fields = {
  __typename?: 'roles_min_fields';
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "roles" */
export type Roles_Mutation_Response = {
  __typename?: 'roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Roles>;
};

/** Ordering options when selecting data from "roles". */
export type Roles_Order_By = {
  comment?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role_users_aggregate?: Maybe<Role_User_Aggregate_Order_By>;
};

/** primary key columns input for table: roles */
export type Roles_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "roles" */
export enum Roles_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "roles" */
export type Roles_Set_Input = {
  comment?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "roles" */
export type Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Roles_Stream_Cursor_Value_Input = {
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type Roles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Roles_Set_Input>;
  where: Roles_Bool_Exp;
};

/** The room location that collected recording */
export type Rooms = {
  __typename?: 'rooms';
  building: Scalars['String'];
  city: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  project_dataset: Array<Project_Dataset>;
  /** An aggregate relationship */
  project_dataset_aggregate: Project_Dataset_Aggregate;
  /** An array relationship */
  recordings: Array<Recordings>;
  /** An aggregate relationship */
  recordings_aggregate: Recordings_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** The room location that collected recording */
export type RoomsProject_DatasetArgs = {
  distinct_on?: Maybe<Array<Project_Dataset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Dataset_Order_By>>;
  where?: Maybe<Project_Dataset_Bool_Exp>;
};


/** The room location that collected recording */
export type RoomsProject_Dataset_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Dataset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Dataset_Order_By>>;
  where?: Maybe<Project_Dataset_Bool_Exp>;
};


/** The room location that collected recording */
export type RoomsRecordingsArgs = {
  distinct_on?: Maybe<Array<Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recordings_Order_By>>;
  where?: Maybe<Recordings_Bool_Exp>;
};


/** The room location that collected recording */
export type RoomsRecordings_AggregateArgs = {
  distinct_on?: Maybe<Array<Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recordings_Order_By>>;
  where?: Maybe<Recordings_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "rooms". All fields are combined with a logical 'AND'. */
export type Rooms_Bool_Exp = {
  _and?: Maybe<Array<Rooms_Bool_Exp>>;
  _not?: Maybe<Rooms_Bool_Exp>;
  _or?: Maybe<Array<Rooms_Bool_Exp>>;
  building?: Maybe<String_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  project_dataset?: Maybe<Project_Dataset_Bool_Exp>;
  recordings?: Maybe<Recordings_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "rooms" */
export enum Rooms_Constraint {
  /** unique or primary key constraint on columns "name", "building", "city" */
  RoomsCityBuildingNameKey = 'rooms_city_building_name_key',
  /** unique or primary key constraint on columns "id" */
  RoomsPkey = 'rooms_pkey'
}

/** input type for inserting data into table "rooms" */
export type Rooms_Insert_Input = {
  building?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  recordings?: Maybe<Recordings_Arr_Rel_Insert_Input>;
};

/** response of any mutation on the table "rooms" */
export type Rooms_Mutation_Response = {
  __typename?: 'rooms_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Rooms>;
};

/** input type for inserting object relation for remote table "rooms" */
export type Rooms_Obj_Rel_Insert_Input = {
  data: Rooms_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Rooms_On_Conflict>;
};

/** on_conflict condition type for table "rooms" */
export type Rooms_On_Conflict = {
  constraint: Rooms_Constraint;
  update_columns?: Array<Rooms_Update_Column>;
  where?: Maybe<Rooms_Bool_Exp>;
};

/** Ordering options when selecting data from "rooms". */
export type Rooms_Order_By = {
  building?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  project_dataset_aggregate?: Maybe<Project_Dataset_Aggregate_Order_By>;
  recordings_aggregate?: Maybe<Recordings_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: rooms */
export type Rooms_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "rooms" */
export enum Rooms_Select_Column {
  /** column name */
  Building = 'building',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "rooms" */
export type Rooms_Set_Input = {
  building?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "rooms" */
export type Rooms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rooms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rooms_Stream_Cursor_Value_Input = {
  building?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "rooms" */
export enum Rooms_Update_Column {
  /** column name */
  Building = 'building',
  /** column name */
  City = 'city',
  /** column name */
  Name = 'name'
}

export type Rooms_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Rooms_Set_Input>;
  where: Rooms_Bool_Exp;
};

/** columns and relationships of "schema.project_engine_metrics" */
export type Schema_Project_Engine_Metrics = {
  __typename?: 'schema_project_engine_metrics';
  denominator?: Maybe<Scalars['float8']>;
  /** An object relationship */
  engine: Engines;
  engine_id: Scalars['uuid'];
  metric_id: Scalars['uuid'];
  num_recordings?: Maybe<Scalars['bigint']>;
  num_samples?: Maybe<Scalars['bigint']>;
  numerator?: Maybe<Scalars['float8']>;
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
  /** An object relationship */
  ratio_metric: Performance_Ratio_Metrics;
  score?: Maybe<Scalars['float8']>;
  /** An object relationship */
  task?: Maybe<Tasks>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** Boolean expression to filter rows from the table "schema.project_engine_metrics". All fields are combined with a logical 'AND'. */
export type Schema_Project_Engine_Metrics_Bool_Exp = {
  _and?: Maybe<Array<Schema_Project_Engine_Metrics_Bool_Exp>>;
  _not?: Maybe<Schema_Project_Engine_Metrics_Bool_Exp>;
  _or?: Maybe<Array<Schema_Project_Engine_Metrics_Bool_Exp>>;
  denominator?: Maybe<Float8_Comparison_Exp>;
  engine?: Maybe<Engines_Bool_Exp>;
  engine_id?: Maybe<Uuid_Comparison_Exp>;
  metric_id?: Maybe<Uuid_Comparison_Exp>;
  num_recordings?: Maybe<Bigint_Comparison_Exp>;
  num_samples?: Maybe<Bigint_Comparison_Exp>;
  numerator?: Maybe<Float8_Comparison_Exp>;
  project?: Maybe<Projects_Bool_Exp>;
  project_id?: Maybe<Uuid_Comparison_Exp>;
  ratio_metric?: Maybe<Performance_Ratio_Metrics_Bool_Exp>;
  score?: Maybe<Float8_Comparison_Exp>;
  task?: Maybe<Tasks_Bool_Exp>;
  task_id?: Maybe<Uuid_Comparison_Exp>;
};

/** Ordering options when selecting data from "schema.project_engine_metrics". */
export type Schema_Project_Engine_Metrics_Order_By = {
  denominator?: Maybe<Order_By>;
  engine?: Maybe<Engines_Order_By>;
  engine_id?: Maybe<Order_By>;
  metric_id?: Maybe<Order_By>;
  num_recordings?: Maybe<Order_By>;
  num_samples?: Maybe<Order_By>;
  numerator?: Maybe<Order_By>;
  project?: Maybe<Projects_Order_By>;
  project_id?: Maybe<Order_By>;
  ratio_metric?: Maybe<Performance_Ratio_Metrics_Order_By>;
  score?: Maybe<Order_By>;
  task?: Maybe<Tasks_Order_By>;
  task_id?: Maybe<Order_By>;
};

/** select columns of table "schema.project_engine_metrics" */
export enum Schema_Project_Engine_Metrics_Select_Column {
  /** column name */
  Denominator = 'denominator',
  /** column name */
  EngineId = 'engine_id',
  /** column name */
  MetricId = 'metric_id',
  /** column name */
  NumRecordings = 'num_recordings',
  /** column name */
  NumSamples = 'num_samples',
  /** column name */
  Numerator = 'numerator',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Score = 'score',
  /** column name */
  TaskId = 'task_id'
}

/** Streaming cursor of the table "schema_project_engine_metrics" */
export type Schema_Project_Engine_Metrics_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Schema_Project_Engine_Metrics_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Schema_Project_Engine_Metrics_Stream_Cursor_Value_Input = {
  denominator?: Maybe<Scalars['float8']>;
  engine_id?: Maybe<Scalars['uuid']>;
  metric_id?: Maybe<Scalars['uuid']>;
  num_recordings?: Maybe<Scalars['bigint']>;
  num_samples?: Maybe<Scalars['bigint']>;
  numerator?: Maybe<Scalars['float8']>;
  project_id?: Maybe<Scalars['uuid']>;
  score?: Maybe<Scalars['float8']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "schema.project_engine_ratio_metrics" */
export type Schema_Project_Engine_Ratio_Metrics = {
  __typename?: 'schema_project_engine_ratio_metrics';
  /** An object relationship */
  engine: Engines;
  engine_id: Scalars['uuid'];
  num_recordings: Scalars['bigint'];
  num_samples: Scalars['bigint'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
  /** An object relationship */
  ratio_metric: Performance_Ratio_Metrics;
  ratio_metric_id: Scalars['uuid'];
  score?: Maybe<Scalars['numeric']>;
};

export type Schema_Project_Engine_Ratio_Metrics_Aggregate = {
  __typename?: 'schema_project_engine_ratio_metrics_aggregate';
  aggregate?: Maybe<Schema_Project_Engine_Ratio_Metrics_Aggregate_Fields>;
  nodes: Array<Schema_Project_Engine_Ratio_Metrics>;
};

/** aggregate fields of "schema.project_engine_ratio_metrics" */
export type Schema_Project_Engine_Ratio_Metrics_Aggregate_Fields = {
  __typename?: 'schema_project_engine_ratio_metrics_aggregate_fields';
  avg?: Maybe<Schema_Project_Engine_Ratio_Metrics_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Schema_Project_Engine_Ratio_Metrics_Max_Fields>;
  min?: Maybe<Schema_Project_Engine_Ratio_Metrics_Min_Fields>;
  stddev?: Maybe<Schema_Project_Engine_Ratio_Metrics_Stddev_Fields>;
  stddev_pop?: Maybe<Schema_Project_Engine_Ratio_Metrics_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Schema_Project_Engine_Ratio_Metrics_Stddev_Samp_Fields>;
  sum?: Maybe<Schema_Project_Engine_Ratio_Metrics_Sum_Fields>;
  var_pop?: Maybe<Schema_Project_Engine_Ratio_Metrics_Var_Pop_Fields>;
  var_samp?: Maybe<Schema_Project_Engine_Ratio_Metrics_Var_Samp_Fields>;
  variance?: Maybe<Schema_Project_Engine_Ratio_Metrics_Variance_Fields>;
};


/** aggregate fields of "schema.project_engine_ratio_metrics" */
export type Schema_Project_Engine_Ratio_Metrics_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Schema_Project_Engine_Ratio_Metrics_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Schema_Project_Engine_Ratio_Metrics_Avg_Fields = {
  __typename?: 'schema_project_engine_ratio_metrics_avg_fields';
  num_recordings?: Maybe<Scalars['Float']>;
  num_samples?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "schema.project_engine_ratio_metrics". All fields are combined with a logical 'AND'. */
export type Schema_Project_Engine_Ratio_Metrics_Bool_Exp = {
  _and?: Maybe<Array<Schema_Project_Engine_Ratio_Metrics_Bool_Exp>>;
  _not?: Maybe<Schema_Project_Engine_Ratio_Metrics_Bool_Exp>;
  _or?: Maybe<Array<Schema_Project_Engine_Ratio_Metrics_Bool_Exp>>;
  engine?: Maybe<Engines_Bool_Exp>;
  engine_id?: Maybe<Uuid_Comparison_Exp>;
  num_recordings?: Maybe<Bigint_Comparison_Exp>;
  num_samples?: Maybe<Bigint_Comparison_Exp>;
  project?: Maybe<Projects_Bool_Exp>;
  project_id?: Maybe<Uuid_Comparison_Exp>;
  ratio_metric?: Maybe<Performance_Ratio_Metrics_Bool_Exp>;
  ratio_metric_id?: Maybe<Uuid_Comparison_Exp>;
  score?: Maybe<Numeric_Comparison_Exp>;
};

/** aggregate max on columns */
export type Schema_Project_Engine_Ratio_Metrics_Max_Fields = {
  __typename?: 'schema_project_engine_ratio_metrics_max_fields';
  engine_id?: Maybe<Scalars['uuid']>;
  num_recordings?: Maybe<Scalars['bigint']>;
  num_samples?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['uuid']>;
  ratio_metric_id?: Maybe<Scalars['uuid']>;
  score?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Schema_Project_Engine_Ratio_Metrics_Min_Fields = {
  __typename?: 'schema_project_engine_ratio_metrics_min_fields';
  engine_id?: Maybe<Scalars['uuid']>;
  num_recordings?: Maybe<Scalars['bigint']>;
  num_samples?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['uuid']>;
  ratio_metric_id?: Maybe<Scalars['uuid']>;
  score?: Maybe<Scalars['numeric']>;
};

/** Ordering options when selecting data from "schema.project_engine_ratio_metrics". */
export type Schema_Project_Engine_Ratio_Metrics_Order_By = {
  engine?: Maybe<Engines_Order_By>;
  engine_id?: Maybe<Order_By>;
  num_recordings?: Maybe<Order_By>;
  num_samples?: Maybe<Order_By>;
  project?: Maybe<Projects_Order_By>;
  project_id?: Maybe<Order_By>;
  ratio_metric?: Maybe<Performance_Ratio_Metrics_Order_By>;
  ratio_metric_id?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
};

/** select columns of table "schema.project_engine_ratio_metrics" */
export enum Schema_Project_Engine_Ratio_Metrics_Select_Column {
  /** column name */
  EngineId = 'engine_id',
  /** column name */
  NumRecordings = 'num_recordings',
  /** column name */
  NumSamples = 'num_samples',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  RatioMetricId = 'ratio_metric_id',
  /** column name */
  Score = 'score'
}

/** aggregate stddev on columns */
export type Schema_Project_Engine_Ratio_Metrics_Stddev_Fields = {
  __typename?: 'schema_project_engine_ratio_metrics_stddev_fields';
  num_recordings?: Maybe<Scalars['Float']>;
  num_samples?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Schema_Project_Engine_Ratio_Metrics_Stddev_Pop_Fields = {
  __typename?: 'schema_project_engine_ratio_metrics_stddev_pop_fields';
  num_recordings?: Maybe<Scalars['Float']>;
  num_samples?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Schema_Project_Engine_Ratio_Metrics_Stddev_Samp_Fields = {
  __typename?: 'schema_project_engine_ratio_metrics_stddev_samp_fields';
  num_recordings?: Maybe<Scalars['Float']>;
  num_samples?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "schema_project_engine_ratio_metrics" */
export type Schema_Project_Engine_Ratio_Metrics_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Schema_Project_Engine_Ratio_Metrics_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Schema_Project_Engine_Ratio_Metrics_Stream_Cursor_Value_Input = {
  engine_id?: Maybe<Scalars['uuid']>;
  num_recordings?: Maybe<Scalars['bigint']>;
  num_samples?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['uuid']>;
  ratio_metric_id?: Maybe<Scalars['uuid']>;
  score?: Maybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Schema_Project_Engine_Ratio_Metrics_Sum_Fields = {
  __typename?: 'schema_project_engine_ratio_metrics_sum_fields';
  num_recordings?: Maybe<Scalars['bigint']>;
  num_samples?: Maybe<Scalars['bigint']>;
  score?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Schema_Project_Engine_Ratio_Metrics_Var_Pop_Fields = {
  __typename?: 'schema_project_engine_ratio_metrics_var_pop_fields';
  num_recordings?: Maybe<Scalars['Float']>;
  num_samples?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Schema_Project_Engine_Ratio_Metrics_Var_Samp_Fields = {
  __typename?: 'schema_project_engine_ratio_metrics_var_samp_fields';
  num_recordings?: Maybe<Scalars['Float']>;
  num_samples?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Schema_Project_Engine_Ratio_Metrics_Variance_Fields = {
  __typename?: 'schema_project_engine_ratio_metrics_variance_fields';
  num_recordings?: Maybe<Scalars['Float']>;
  num_samples?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** This table is only used as return type for the project_task_count function */
export type Schema_Project_Task_Count = {
  __typename?: 'schema_project_task_count';
  clean_count: Scalars['bigint'];
  clean_count_constrained: Scalars['bigint'];
  /** An object relationship */
  device?: Maybe<Devices>;
  device_id: Scalars['uuid'];
  dirty_count: Scalars['bigint'];
  min_per_device: Scalars['Int'];
  priority: Scalars['Int'];
  progress: Scalars['float8'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
  required_total: Scalars['Int'];
  /** An object relationship */
  task: Tasks;
  task_id: Scalars['uuid'];
  total_count: Scalars['bigint'];
  untagged_count: Scalars['bigint'];
};

export type Schema_Project_Task_Count_Aggregate = {
  __typename?: 'schema_project_task_count_aggregate';
  aggregate?: Maybe<Schema_Project_Task_Count_Aggregate_Fields>;
  nodes: Array<Schema_Project_Task_Count>;
};

/** aggregate fields of "schema.project_task_count" */
export type Schema_Project_Task_Count_Aggregate_Fields = {
  __typename?: 'schema_project_task_count_aggregate_fields';
  avg?: Maybe<Schema_Project_Task_Count_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Schema_Project_Task_Count_Max_Fields>;
  min?: Maybe<Schema_Project_Task_Count_Min_Fields>;
  stddev?: Maybe<Schema_Project_Task_Count_Stddev_Fields>;
  stddev_pop?: Maybe<Schema_Project_Task_Count_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Schema_Project_Task_Count_Stddev_Samp_Fields>;
  sum?: Maybe<Schema_Project_Task_Count_Sum_Fields>;
  var_pop?: Maybe<Schema_Project_Task_Count_Var_Pop_Fields>;
  var_samp?: Maybe<Schema_Project_Task_Count_Var_Samp_Fields>;
  variance?: Maybe<Schema_Project_Task_Count_Variance_Fields>;
};


/** aggregate fields of "schema.project_task_count" */
export type Schema_Project_Task_Count_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Schema_Project_Task_Count_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Schema_Project_Task_Count_Avg_Fields = {
  __typename?: 'schema_project_task_count_avg_fields';
  clean_count?: Maybe<Scalars['Float']>;
  clean_count_constrained?: Maybe<Scalars['Float']>;
  dirty_count?: Maybe<Scalars['Float']>;
  min_per_device?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  required_total?: Maybe<Scalars['Float']>;
  total_count?: Maybe<Scalars['Float']>;
  untagged_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "schema.project_task_count". All fields are combined with a logical 'AND'. */
export type Schema_Project_Task_Count_Bool_Exp = {
  _and?: Maybe<Array<Schema_Project_Task_Count_Bool_Exp>>;
  _not?: Maybe<Schema_Project_Task_Count_Bool_Exp>;
  _or?: Maybe<Array<Schema_Project_Task_Count_Bool_Exp>>;
  clean_count?: Maybe<Bigint_Comparison_Exp>;
  clean_count_constrained?: Maybe<Bigint_Comparison_Exp>;
  device?: Maybe<Devices_Bool_Exp>;
  device_id?: Maybe<Uuid_Comparison_Exp>;
  dirty_count?: Maybe<Bigint_Comparison_Exp>;
  min_per_device?: Maybe<Int_Comparison_Exp>;
  priority?: Maybe<Int_Comparison_Exp>;
  progress?: Maybe<Float8_Comparison_Exp>;
  project?: Maybe<Projects_Bool_Exp>;
  project_id?: Maybe<Uuid_Comparison_Exp>;
  required_total?: Maybe<Int_Comparison_Exp>;
  task?: Maybe<Tasks_Bool_Exp>;
  task_id?: Maybe<Uuid_Comparison_Exp>;
  total_count?: Maybe<Bigint_Comparison_Exp>;
  untagged_count?: Maybe<Bigint_Comparison_Exp>;
};

/** This table is only used as return type for the project_task_count_by_device function */
export type Schema_Project_Task_Count_By_Device = {
  __typename?: 'schema_project_task_count_by_device';
  count: Scalars['bigint'];
  count_dirty: Scalars['bigint'];
  /** An object relationship */
  device: Devices;
  device_id: Scalars['uuid'];
  min_per_device: Scalars['Int'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
  /** An object relationship */
  task: Tasks;
  task_id: Scalars['uuid'];
};

export type Schema_Project_Task_Count_By_Device_Aggregate = {
  __typename?: 'schema_project_task_count_by_device_aggregate';
  aggregate?: Maybe<Schema_Project_Task_Count_By_Device_Aggregate_Fields>;
  nodes: Array<Schema_Project_Task_Count_By_Device>;
};

/** aggregate fields of "schema.project_task_count_by_device" */
export type Schema_Project_Task_Count_By_Device_Aggregate_Fields = {
  __typename?: 'schema_project_task_count_by_device_aggregate_fields';
  avg?: Maybe<Schema_Project_Task_Count_By_Device_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Schema_Project_Task_Count_By_Device_Max_Fields>;
  min?: Maybe<Schema_Project_Task_Count_By_Device_Min_Fields>;
  stddev?: Maybe<Schema_Project_Task_Count_By_Device_Stddev_Fields>;
  stddev_pop?: Maybe<Schema_Project_Task_Count_By_Device_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Schema_Project_Task_Count_By_Device_Stddev_Samp_Fields>;
  sum?: Maybe<Schema_Project_Task_Count_By_Device_Sum_Fields>;
  var_pop?: Maybe<Schema_Project_Task_Count_By_Device_Var_Pop_Fields>;
  var_samp?: Maybe<Schema_Project_Task_Count_By_Device_Var_Samp_Fields>;
  variance?: Maybe<Schema_Project_Task_Count_By_Device_Variance_Fields>;
};


/** aggregate fields of "schema.project_task_count_by_device" */
export type Schema_Project_Task_Count_By_Device_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Schema_Project_Task_Count_By_Device_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Schema_Project_Task_Count_By_Device_Avg_Fields = {
  __typename?: 'schema_project_task_count_by_device_avg_fields';
  count?: Maybe<Scalars['Float']>;
  count_dirty?: Maybe<Scalars['Float']>;
  min_per_device?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "schema.project_task_count_by_device". All fields are combined with a logical 'AND'. */
export type Schema_Project_Task_Count_By_Device_Bool_Exp = {
  _and?: Maybe<Array<Schema_Project_Task_Count_By_Device_Bool_Exp>>;
  _not?: Maybe<Schema_Project_Task_Count_By_Device_Bool_Exp>;
  _or?: Maybe<Array<Schema_Project_Task_Count_By_Device_Bool_Exp>>;
  count?: Maybe<Bigint_Comparison_Exp>;
  count_dirty?: Maybe<Bigint_Comparison_Exp>;
  device?: Maybe<Devices_Bool_Exp>;
  device_id?: Maybe<Uuid_Comparison_Exp>;
  min_per_device?: Maybe<Int_Comparison_Exp>;
  project?: Maybe<Projects_Bool_Exp>;
  project_id?: Maybe<Uuid_Comparison_Exp>;
  task?: Maybe<Tasks_Bool_Exp>;
  task_id?: Maybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Schema_Project_Task_Count_By_Device_Max_Fields = {
  __typename?: 'schema_project_task_count_by_device_max_fields';
  count?: Maybe<Scalars['bigint']>;
  count_dirty?: Maybe<Scalars['bigint']>;
  device_id?: Maybe<Scalars['uuid']>;
  min_per_device?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Schema_Project_Task_Count_By_Device_Min_Fields = {
  __typename?: 'schema_project_task_count_by_device_min_fields';
  count?: Maybe<Scalars['bigint']>;
  count_dirty?: Maybe<Scalars['bigint']>;
  device_id?: Maybe<Scalars['uuid']>;
  min_per_device?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "schema.project_task_count_by_device". */
export type Schema_Project_Task_Count_By_Device_Order_By = {
  count?: Maybe<Order_By>;
  count_dirty?: Maybe<Order_By>;
  device?: Maybe<Devices_Order_By>;
  device_id?: Maybe<Order_By>;
  min_per_device?: Maybe<Order_By>;
  project?: Maybe<Projects_Order_By>;
  project_id?: Maybe<Order_By>;
  task?: Maybe<Tasks_Order_By>;
  task_id?: Maybe<Order_By>;
};

/** select columns of table "schema.project_task_count_by_device" */
export enum Schema_Project_Task_Count_By_Device_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  CountDirty = 'count_dirty',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  MinPerDevice = 'min_per_device',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  TaskId = 'task_id'
}

/** aggregate stddev on columns */
export type Schema_Project_Task_Count_By_Device_Stddev_Fields = {
  __typename?: 'schema_project_task_count_by_device_stddev_fields';
  count?: Maybe<Scalars['Float']>;
  count_dirty?: Maybe<Scalars['Float']>;
  min_per_device?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Schema_Project_Task_Count_By_Device_Stddev_Pop_Fields = {
  __typename?: 'schema_project_task_count_by_device_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
  count_dirty?: Maybe<Scalars['Float']>;
  min_per_device?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Schema_Project_Task_Count_By_Device_Stddev_Samp_Fields = {
  __typename?: 'schema_project_task_count_by_device_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
  count_dirty?: Maybe<Scalars['Float']>;
  min_per_device?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "schema_project_task_count_by_device" */
export type Schema_Project_Task_Count_By_Device_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Schema_Project_Task_Count_By_Device_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Schema_Project_Task_Count_By_Device_Stream_Cursor_Value_Input = {
  count?: Maybe<Scalars['bigint']>;
  count_dirty?: Maybe<Scalars['bigint']>;
  device_id?: Maybe<Scalars['uuid']>;
  min_per_device?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Schema_Project_Task_Count_By_Device_Sum_Fields = {
  __typename?: 'schema_project_task_count_by_device_sum_fields';
  count?: Maybe<Scalars['bigint']>;
  count_dirty?: Maybe<Scalars['bigint']>;
  min_per_device?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Schema_Project_Task_Count_By_Device_Var_Pop_Fields = {
  __typename?: 'schema_project_task_count_by_device_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
  count_dirty?: Maybe<Scalars['Float']>;
  min_per_device?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Schema_Project_Task_Count_By_Device_Var_Samp_Fields = {
  __typename?: 'schema_project_task_count_by_device_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
  count_dirty?: Maybe<Scalars['Float']>;
  min_per_device?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Schema_Project_Task_Count_By_Device_Variance_Fields = {
  __typename?: 'schema_project_task_count_by_device_variance_fields';
  count?: Maybe<Scalars['Float']>;
  count_dirty?: Maybe<Scalars['Float']>;
  min_per_device?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Schema_Project_Task_Count_Max_Fields = {
  __typename?: 'schema_project_task_count_max_fields';
  clean_count?: Maybe<Scalars['bigint']>;
  clean_count_constrained?: Maybe<Scalars['bigint']>;
  device_id?: Maybe<Scalars['uuid']>;
  dirty_count?: Maybe<Scalars['bigint']>;
  min_per_device?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  progress?: Maybe<Scalars['float8']>;
  project_id?: Maybe<Scalars['uuid']>;
  required_total?: Maybe<Scalars['Int']>;
  task_id?: Maybe<Scalars['uuid']>;
  total_count?: Maybe<Scalars['bigint']>;
  untagged_count?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Schema_Project_Task_Count_Min_Fields = {
  __typename?: 'schema_project_task_count_min_fields';
  clean_count?: Maybe<Scalars['bigint']>;
  clean_count_constrained?: Maybe<Scalars['bigint']>;
  device_id?: Maybe<Scalars['uuid']>;
  dirty_count?: Maybe<Scalars['bigint']>;
  min_per_device?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  progress?: Maybe<Scalars['float8']>;
  project_id?: Maybe<Scalars['uuid']>;
  required_total?: Maybe<Scalars['Int']>;
  task_id?: Maybe<Scalars['uuid']>;
  total_count?: Maybe<Scalars['bigint']>;
  untagged_count?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "schema.project_task_count". */
export type Schema_Project_Task_Count_Order_By = {
  clean_count?: Maybe<Order_By>;
  clean_count_constrained?: Maybe<Order_By>;
  device?: Maybe<Devices_Order_By>;
  device_id?: Maybe<Order_By>;
  dirty_count?: Maybe<Order_By>;
  min_per_device?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  progress?: Maybe<Order_By>;
  project?: Maybe<Projects_Order_By>;
  project_id?: Maybe<Order_By>;
  required_total?: Maybe<Order_By>;
  task?: Maybe<Tasks_Order_By>;
  task_id?: Maybe<Order_By>;
  total_count?: Maybe<Order_By>;
  untagged_count?: Maybe<Order_By>;
};

/** select columns of table "schema.project_task_count" */
export enum Schema_Project_Task_Count_Select_Column {
  /** column name */
  CleanCount = 'clean_count',
  /** column name */
  CleanCountConstrained = 'clean_count_constrained',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  DirtyCount = 'dirty_count',
  /** column name */
  MinPerDevice = 'min_per_device',
  /** column name */
  Priority = 'priority',
  /** column name */
  Progress = 'progress',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  RequiredTotal = 'required_total',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  TotalCount = 'total_count',
  /** column name */
  UntaggedCount = 'untagged_count'
}

/** aggregate stddev on columns */
export type Schema_Project_Task_Count_Stddev_Fields = {
  __typename?: 'schema_project_task_count_stddev_fields';
  clean_count?: Maybe<Scalars['Float']>;
  clean_count_constrained?: Maybe<Scalars['Float']>;
  dirty_count?: Maybe<Scalars['Float']>;
  min_per_device?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  required_total?: Maybe<Scalars['Float']>;
  total_count?: Maybe<Scalars['Float']>;
  untagged_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Schema_Project_Task_Count_Stddev_Pop_Fields = {
  __typename?: 'schema_project_task_count_stddev_pop_fields';
  clean_count?: Maybe<Scalars['Float']>;
  clean_count_constrained?: Maybe<Scalars['Float']>;
  dirty_count?: Maybe<Scalars['Float']>;
  min_per_device?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  required_total?: Maybe<Scalars['Float']>;
  total_count?: Maybe<Scalars['Float']>;
  untagged_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Schema_Project_Task_Count_Stddev_Samp_Fields = {
  __typename?: 'schema_project_task_count_stddev_samp_fields';
  clean_count?: Maybe<Scalars['Float']>;
  clean_count_constrained?: Maybe<Scalars['Float']>;
  dirty_count?: Maybe<Scalars['Float']>;
  min_per_device?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  required_total?: Maybe<Scalars['Float']>;
  total_count?: Maybe<Scalars['Float']>;
  untagged_count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "schema_project_task_count" */
export type Schema_Project_Task_Count_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Schema_Project_Task_Count_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Schema_Project_Task_Count_Stream_Cursor_Value_Input = {
  clean_count?: Maybe<Scalars['bigint']>;
  clean_count_constrained?: Maybe<Scalars['bigint']>;
  device_id?: Maybe<Scalars['uuid']>;
  dirty_count?: Maybe<Scalars['bigint']>;
  min_per_device?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  progress?: Maybe<Scalars['float8']>;
  project_id?: Maybe<Scalars['uuid']>;
  required_total?: Maybe<Scalars['Int']>;
  task_id?: Maybe<Scalars['uuid']>;
  total_count?: Maybe<Scalars['bigint']>;
  untagged_count?: Maybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Schema_Project_Task_Count_Sum_Fields = {
  __typename?: 'schema_project_task_count_sum_fields';
  clean_count?: Maybe<Scalars['bigint']>;
  clean_count_constrained?: Maybe<Scalars['bigint']>;
  dirty_count?: Maybe<Scalars['bigint']>;
  min_per_device?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  progress?: Maybe<Scalars['float8']>;
  required_total?: Maybe<Scalars['Int']>;
  total_count?: Maybe<Scalars['bigint']>;
  untagged_count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Schema_Project_Task_Count_Var_Pop_Fields = {
  __typename?: 'schema_project_task_count_var_pop_fields';
  clean_count?: Maybe<Scalars['Float']>;
  clean_count_constrained?: Maybe<Scalars['Float']>;
  dirty_count?: Maybe<Scalars['Float']>;
  min_per_device?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  required_total?: Maybe<Scalars['Float']>;
  total_count?: Maybe<Scalars['Float']>;
  untagged_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Schema_Project_Task_Count_Var_Samp_Fields = {
  __typename?: 'schema_project_task_count_var_samp_fields';
  clean_count?: Maybe<Scalars['Float']>;
  clean_count_constrained?: Maybe<Scalars['Float']>;
  dirty_count?: Maybe<Scalars['Float']>;
  min_per_device?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  required_total?: Maybe<Scalars['Float']>;
  total_count?: Maybe<Scalars['Float']>;
  untagged_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Schema_Project_Task_Count_Variance_Fields = {
  __typename?: 'schema_project_task_count_variance_fields';
  clean_count?: Maybe<Scalars['Float']>;
  clean_count_constrained?: Maybe<Scalars['Float']>;
  dirty_count?: Maybe<Scalars['Float']>;
  min_per_device?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  required_total?: Maybe<Scalars['Float']>;
  total_count?: Maybe<Scalars['Float']>;
  untagged_count?: Maybe<Scalars['Float']>;
};

/** Schema for SQL function public.recording_tag_count_by_day */
export type Schema_Recording_Tag_Count_By_Day = {
  __typename?: 'schema_recording_tag_count_by_day';
  count: Scalars['Int'];
  day: Scalars['date'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
  /** An object relationship */
  recording_tag: Recording_Tags;
  tag_description?: Maybe<Scalars['String']>;
  tag_field?: Maybe<Scalars['String']>;
  tag_id: Scalars['uuid'];
  tag_is_dirty: Scalars['Boolean'];
  tag_is_highlighted: Scalars['Boolean'];
  tag_name: Scalars['String'];
};

export type Schema_Recording_Tag_Count_By_Day_Aggregate = {
  __typename?: 'schema_recording_tag_count_by_day_aggregate';
  aggregate?: Maybe<Schema_Recording_Tag_Count_By_Day_Aggregate_Fields>;
  nodes: Array<Schema_Recording_Tag_Count_By_Day>;
};

/** aggregate fields of "schema.recording_tag_count_by_day" */
export type Schema_Recording_Tag_Count_By_Day_Aggregate_Fields = {
  __typename?: 'schema_recording_tag_count_by_day_aggregate_fields';
  avg?: Maybe<Schema_Recording_Tag_Count_By_Day_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Schema_Recording_Tag_Count_By_Day_Max_Fields>;
  min?: Maybe<Schema_Recording_Tag_Count_By_Day_Min_Fields>;
  stddev?: Maybe<Schema_Recording_Tag_Count_By_Day_Stddev_Fields>;
  stddev_pop?: Maybe<Schema_Recording_Tag_Count_By_Day_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Schema_Recording_Tag_Count_By_Day_Stddev_Samp_Fields>;
  sum?: Maybe<Schema_Recording_Tag_Count_By_Day_Sum_Fields>;
  var_pop?: Maybe<Schema_Recording_Tag_Count_By_Day_Var_Pop_Fields>;
  var_samp?: Maybe<Schema_Recording_Tag_Count_By_Day_Var_Samp_Fields>;
  variance?: Maybe<Schema_Recording_Tag_Count_By_Day_Variance_Fields>;
};


/** aggregate fields of "schema.recording_tag_count_by_day" */
export type Schema_Recording_Tag_Count_By_Day_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Schema_Recording_Tag_Count_By_Day_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Schema_Recording_Tag_Count_By_Day_Avg_Fields = {
  __typename?: 'schema_recording_tag_count_by_day_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "schema.recording_tag_count_by_day". All fields are combined with a logical 'AND'. */
export type Schema_Recording_Tag_Count_By_Day_Bool_Exp = {
  _and?: Maybe<Array<Schema_Recording_Tag_Count_By_Day_Bool_Exp>>;
  _not?: Maybe<Schema_Recording_Tag_Count_By_Day_Bool_Exp>;
  _or?: Maybe<Array<Schema_Recording_Tag_Count_By_Day_Bool_Exp>>;
  count?: Maybe<Int_Comparison_Exp>;
  day?: Maybe<Date_Comparison_Exp>;
  project?: Maybe<Projects_Bool_Exp>;
  project_id?: Maybe<Uuid_Comparison_Exp>;
  recording_tag?: Maybe<Recording_Tags_Bool_Exp>;
  tag_description?: Maybe<String_Comparison_Exp>;
  tag_field?: Maybe<String_Comparison_Exp>;
  tag_id?: Maybe<Uuid_Comparison_Exp>;
  tag_is_dirty?: Maybe<Boolean_Comparison_Exp>;
  tag_is_highlighted?: Maybe<Boolean_Comparison_Exp>;
  tag_name?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Schema_Recording_Tag_Count_By_Day_Max_Fields = {
  __typename?: 'schema_recording_tag_count_by_day_max_fields';
  count?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['date']>;
  project_id?: Maybe<Scalars['uuid']>;
  tag_description?: Maybe<Scalars['String']>;
  tag_field?: Maybe<Scalars['String']>;
  tag_id?: Maybe<Scalars['uuid']>;
  tag_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Schema_Recording_Tag_Count_By_Day_Min_Fields = {
  __typename?: 'schema_recording_tag_count_by_day_min_fields';
  count?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['date']>;
  project_id?: Maybe<Scalars['uuid']>;
  tag_description?: Maybe<Scalars['String']>;
  tag_field?: Maybe<Scalars['String']>;
  tag_id?: Maybe<Scalars['uuid']>;
  tag_name?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "schema.recording_tag_count_by_day". */
export type Schema_Recording_Tag_Count_By_Day_Order_By = {
  count?: Maybe<Order_By>;
  day?: Maybe<Order_By>;
  project?: Maybe<Projects_Order_By>;
  project_id?: Maybe<Order_By>;
  recording_tag?: Maybe<Recording_Tags_Order_By>;
  tag_description?: Maybe<Order_By>;
  tag_field?: Maybe<Order_By>;
  tag_id?: Maybe<Order_By>;
  tag_is_dirty?: Maybe<Order_By>;
  tag_is_highlighted?: Maybe<Order_By>;
  tag_name?: Maybe<Order_By>;
};

/** select columns of table "schema.recording_tag_count_by_day" */
export enum Schema_Recording_Tag_Count_By_Day_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  Day = 'day',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  TagDescription = 'tag_description',
  /** column name */
  TagField = 'tag_field',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  TagIsDirty = 'tag_is_dirty',
  /** column name */
  TagIsHighlighted = 'tag_is_highlighted',
  /** column name */
  TagName = 'tag_name'
}

/** aggregate stddev on columns */
export type Schema_Recording_Tag_Count_By_Day_Stddev_Fields = {
  __typename?: 'schema_recording_tag_count_by_day_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Schema_Recording_Tag_Count_By_Day_Stddev_Pop_Fields = {
  __typename?: 'schema_recording_tag_count_by_day_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Schema_Recording_Tag_Count_By_Day_Stddev_Samp_Fields = {
  __typename?: 'schema_recording_tag_count_by_day_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "schema_recording_tag_count_by_day" */
export type Schema_Recording_Tag_Count_By_Day_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Schema_Recording_Tag_Count_By_Day_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Schema_Recording_Tag_Count_By_Day_Stream_Cursor_Value_Input = {
  count?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['date']>;
  project_id?: Maybe<Scalars['uuid']>;
  tag_description?: Maybe<Scalars['String']>;
  tag_field?: Maybe<Scalars['String']>;
  tag_id?: Maybe<Scalars['uuid']>;
  tag_is_dirty?: Maybe<Scalars['Boolean']>;
  tag_is_highlighted?: Maybe<Scalars['Boolean']>;
  tag_name?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Schema_Recording_Tag_Count_By_Day_Sum_Fields = {
  __typename?: 'schema_recording_tag_count_by_day_sum_fields';
  count?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Schema_Recording_Tag_Count_By_Day_Var_Pop_Fields = {
  __typename?: 'schema_recording_tag_count_by_day_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Schema_Recording_Tag_Count_By_Day_Var_Samp_Fields = {
  __typename?: 'schema_recording_tag_count_by_day_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Schema_Recording_Tag_Count_By_Day_Variance_Fields = {
  __typename?: 'schema_recording_tag_count_by_day_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** Establishes return type for "upsert_task" function */
export type Schema_Upsert_Task = {
  __typename?: 'schema_upsert_task';
  task_id: Scalars['uuid'];
  task_number: Scalars['Int'];
};

/** Boolean expression to filter rows from the table "schema.upsert_task". All fields are combined with a logical 'AND'. */
export type Schema_Upsert_Task_Bool_Exp = {
  _and?: Maybe<Array<Schema_Upsert_Task_Bool_Exp>>;
  _not?: Maybe<Schema_Upsert_Task_Bool_Exp>;
  _or?: Maybe<Array<Schema_Upsert_Task_Bool_Exp>>;
  task_id?: Maybe<Uuid_Comparison_Exp>;
  task_number?: Maybe<Int_Comparison_Exp>;
};

/** Ordering options when selecting data from "schema.upsert_task". */
export type Schema_Upsert_Task_Order_By = {
  task_id?: Maybe<Order_By>;
  task_number?: Maybe<Order_By>;
};

/** select columns of table "schema.upsert_task" */
export enum Schema_Upsert_Task_Select_Column {
  /** column name */
  TaskId = 'task_id',
  /** column name */
  TaskNumber = 'task_number'
}

/** columns and relationships of "simulations.scores" */
export type Simulations_Scores = {
  __typename?: 'simulations_scores';
  /** An object relationship */
  engine: Engines;
  engine_id: Scalars['uuid'];
  greatness: Scalars['float8'];
  id: Scalars['uuid'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
  timestamp: Scalars['timestamptz'];
};

/** order by aggregate values of table "simulations.scores" */
export type Simulations_Scores_Aggregate_Order_By = {
  avg?: Maybe<Simulations_Scores_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Simulations_Scores_Max_Order_By>;
  min?: Maybe<Simulations_Scores_Min_Order_By>;
  stddev?: Maybe<Simulations_Scores_Stddev_Order_By>;
  stddev_pop?: Maybe<Simulations_Scores_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Simulations_Scores_Stddev_Samp_Order_By>;
  sum?: Maybe<Simulations_Scores_Sum_Order_By>;
  var_pop?: Maybe<Simulations_Scores_Var_Pop_Order_By>;
  var_samp?: Maybe<Simulations_Scores_Var_Samp_Order_By>;
  variance?: Maybe<Simulations_Scores_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "simulations.scores" */
export type Simulations_Scores_Arr_Rel_Insert_Input = {
  data: Array<Simulations_Scores_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Simulations_Scores_On_Conflict>;
};

/** order by avg() on columns of table "simulations.scores" */
export type Simulations_Scores_Avg_Order_By = {
  greatness?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "simulations.scores". All fields are combined with a logical 'AND'. */
export type Simulations_Scores_Bool_Exp = {
  _and?: Maybe<Array<Simulations_Scores_Bool_Exp>>;
  _not?: Maybe<Simulations_Scores_Bool_Exp>;
  _or?: Maybe<Array<Simulations_Scores_Bool_Exp>>;
  engine?: Maybe<Engines_Bool_Exp>;
  engine_id?: Maybe<Uuid_Comparison_Exp>;
  greatness?: Maybe<Float8_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  project?: Maybe<Projects_Bool_Exp>;
  project_id?: Maybe<Uuid_Comparison_Exp>;
  timestamp?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "simulations.scores" */
export enum Simulations_Scores_Constraint {
  /** unique or primary key constraint on columns "id" */
  ScoresPkey = 'scores_pkey'
}

/** input type for incrementing numeric columns in table "simulations.scores" */
export type Simulations_Scores_Inc_Input = {
  greatness?: Maybe<Scalars['float8']>;
};

/** input type for inserting data into table "simulations.scores" */
export type Simulations_Scores_Insert_Input = {
  engine?: Maybe<Engines_Obj_Rel_Insert_Input>;
  engine_id?: Maybe<Scalars['uuid']>;
  greatness?: Maybe<Scalars['float8']>;
  project?: Maybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: Maybe<Scalars['uuid']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "simulations.scores" */
export type Simulations_Scores_Max_Order_By = {
  engine_id?: Maybe<Order_By>;
  greatness?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  timestamp?: Maybe<Order_By>;
};

/** order by min() on columns of table "simulations.scores" */
export type Simulations_Scores_Min_Order_By = {
  engine_id?: Maybe<Order_By>;
  greatness?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  timestamp?: Maybe<Order_By>;
};

/** response of any mutation on the table "simulations.scores" */
export type Simulations_Scores_Mutation_Response = {
  __typename?: 'simulations_scores_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Simulations_Scores>;
};

/** on_conflict condition type for table "simulations.scores" */
export type Simulations_Scores_On_Conflict = {
  constraint: Simulations_Scores_Constraint;
  update_columns?: Array<Simulations_Scores_Update_Column>;
  where?: Maybe<Simulations_Scores_Bool_Exp>;
};

/** Ordering options when selecting data from "simulations.scores". */
export type Simulations_Scores_Order_By = {
  engine?: Maybe<Engines_Order_By>;
  engine_id?: Maybe<Order_By>;
  greatness?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project?: Maybe<Projects_Order_By>;
  project_id?: Maybe<Order_By>;
  timestamp?: Maybe<Order_By>;
};

/** primary key columns input for table: simulations.scores */
export type Simulations_Scores_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "simulations.scores" */
export enum Simulations_Scores_Select_Column {
  /** column name */
  EngineId = 'engine_id',
  /** column name */
  Greatness = 'greatness',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Timestamp = 'timestamp'
}

/** input type for updating data in table "simulations.scores" */
export type Simulations_Scores_Set_Input = {
  engine_id?: Maybe<Scalars['uuid']>;
  greatness?: Maybe<Scalars['float8']>;
  project_id?: Maybe<Scalars['uuid']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
};

/** order by stddev() on columns of table "simulations.scores" */
export type Simulations_Scores_Stddev_Order_By = {
  greatness?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "simulations.scores" */
export type Simulations_Scores_Stddev_Pop_Order_By = {
  greatness?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "simulations.scores" */
export type Simulations_Scores_Stddev_Samp_Order_By = {
  greatness?: Maybe<Order_By>;
};

/** Streaming cursor of the table "simulations_scores" */
export type Simulations_Scores_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Simulations_Scores_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Simulations_Scores_Stream_Cursor_Value_Input = {
  engine_id?: Maybe<Scalars['uuid']>;
  greatness?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "simulations.scores" */
export type Simulations_Scores_Sum_Order_By = {
  greatness?: Maybe<Order_By>;
};

/** update columns of table "simulations.scores" */
export enum Simulations_Scores_Update_Column {
  /** column name */
  EngineId = 'engine_id',
  /** column name */
  Greatness = 'greatness',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Timestamp = 'timestamp'
}

export type Simulations_Scores_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Simulations_Scores_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Simulations_Scores_Set_Input>;
  where: Simulations_Scores_Bool_Exp;
};

/** order by var_pop() on columns of table "simulations.scores" */
export type Simulations_Scores_Var_Pop_Order_By = {
  greatness?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "simulations.scores" */
export type Simulations_Scores_Var_Samp_Order_By = {
  greatness?: Maybe<Order_By>;
};

/** order by variance() on columns of table "simulations.scores" */
export type Simulations_Scores_Variance_Order_By = {
  greatness?: Maybe<Order_By>;
};


/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _eq?: Maybe<Scalars['smallint']>;
  _gt?: Maybe<Scalars['smallint']>;
  _gte?: Maybe<Scalars['smallint']>;
  _in?: Maybe<Array<Scalars['smallint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['smallint']>;
  _lte?: Maybe<Scalars['smallint']>;
  _neq?: Maybe<Scalars['smallint']>;
  _nin?: Maybe<Array<Scalars['smallint']>>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "audio_driver" */
  audio_driver: Array<Audio_Driver>;
  /** fetch data from the table: "audio_driver" using primary key columns */
  audio_driver_by_pk?: Maybe<Audio_Driver>;
  /** fetch data from the table in a streaming manner : "audio_driver" */
  audio_driver_stream: Array<Audio_Driver>;
  /** fetch data from the table: "audio_measurements.components" */
  audio_measurements_components: Array<Audio_Measurements_Components>;
  /** fetch data from the table: "audio_measurements.components" using primary key columns */
  audio_measurements_components_by_pk?: Maybe<Audio_Measurements_Components>;
  /** fetch data from the table in a streaming manner : "audio_measurements.components" */
  audio_measurements_components_stream: Array<Audio_Measurements_Components>;
  /** fetch data from the table: "audio_measurements.configurations" */
  audio_measurements_configurations: Array<Audio_Measurements_Configurations>;
  /** fetch data from the table: "audio_measurements.configurations" using primary key columns */
  audio_measurements_configurations_by_pk?: Maybe<Audio_Measurements_Configurations>;
  /** fetch data from the table in a streaming manner : "audio_measurements.configurations" */
  audio_measurements_configurations_stream: Array<Audio_Measurements_Configurations>;
  /** fetch data from the table: "audio_measurements.data_types" */
  audio_measurements_data_types: Array<Audio_Measurements_Data_Types>;
  /** fetch aggregated fields from the table: "audio_measurements.data_types" */
  audio_measurements_data_types_aggregate: Audio_Measurements_Data_Types_Aggregate;
  /** fetch data from the table: "audio_measurements.data_types" using primary key columns */
  audio_measurements_data_types_by_pk?: Maybe<Audio_Measurements_Data_Types>;
  /** fetch data from the table in a streaming manner : "audio_measurements.data_types" */
  audio_measurements_data_types_stream: Array<Audio_Measurements_Data_Types>;
  /** fetch data from the table: "audio_measurements.master_files" */
  audio_measurements_master_files: Array<Audio_Measurements_Master_Files>;
  /** fetch aggregated fields from the table: "audio_measurements.master_files" */
  audio_measurements_master_files_aggregate: Audio_Measurements_Master_Files_Aggregate;
  /** fetch data from the table: "audio_measurements.master_files" using primary key columns */
  audio_measurements_master_files_by_pk?: Maybe<Audio_Measurements_Master_Files>;
  /** fetch data from the table in a streaming manner : "audio_measurements.master_files" */
  audio_measurements_master_files_stream: Array<Audio_Measurements_Master_Files>;
  /** fetch data from the table: "audio_measurements.orientations" */
  audio_measurements_orientations: Array<Audio_Measurements_Orientations>;
  /** fetch data from the table: "audio_measurements.orientations" using primary key columns */
  audio_measurements_orientations_by_pk?: Maybe<Audio_Measurements_Orientations>;
  /** fetch data from the table in a streaming manner : "audio_measurements.orientations" */
  audio_measurements_orientations_stream: Array<Audio_Measurements_Orientations>;
  /** fetch data from the table: "audio_measurements.payload_recording" */
  audio_measurements_payload_recording: Array<Audio_Measurements_Payload_Recording>;
  /** fetch aggregated fields from the table: "audio_measurements.payload_recording" */
  audio_measurements_payload_recording_aggregate: Audio_Measurements_Payload_Recording_Aggregate;
  /** fetch data from the table: "audio_measurements.payload_recording" using primary key columns */
  audio_measurements_payload_recording_by_pk?: Maybe<Audio_Measurements_Payload_Recording>;
  /** fetch data from the table in a streaming manner : "audio_measurements.payload_recording" */
  audio_measurements_payload_recording_stream: Array<Audio_Measurements_Payload_Recording>;
  /** fetch data from the table: "audio_measurements.placements" */
  audio_measurements_placements: Array<Audio_Measurements_Placements>;
  /** fetch data from the table: "audio_measurements.placements" using primary key columns */
  audio_measurements_placements_by_pk?: Maybe<Audio_Measurements_Placements>;
  /** fetch data from the table in a streaming manner : "audio_measurements.placements" */
  audio_measurements_placements_stream: Array<Audio_Measurements_Placements>;
  /** An array relationship */
  audio_measurements_project_tasks: Array<Audio_Measurements_Project_Tasks>;
  /** An aggregate relationship */
  audio_measurements_project_tasks_aggregate: Audio_Measurements_Project_Tasks_Aggregate;
  /** fetch data from the table: "audio_measurements.project_tasks" using primary key columns */
  audio_measurements_project_tasks_by_pk?: Maybe<Audio_Measurements_Project_Tasks>;
  /** fetch data from the table in a streaming manner : "audio_measurements.project_tasks" */
  audio_measurements_project_tasks_stream: Array<Audio_Measurements_Project_Tasks>;
  /** fetch data from the table: "audio_measurements.recordings" */
  audio_measurements_recordings: Array<Audio_Measurements_Recordings>;
  /** fetch aggregated fields from the table: "audio_measurements.recordings" */
  audio_measurements_recordings_aggregate: Audio_Measurements_Recordings_Aggregate;
  /** fetch data from the table: "audio_measurements.recordings" using primary key columns */
  audio_measurements_recordings_by_pk?: Maybe<Audio_Measurements_Recordings>;
  /** fetch data from the table in a streaming manner : "audio_measurements.recordings" */
  audio_measurements_recordings_stream: Array<Audio_Measurements_Recordings>;
  /** fetch data from the table: "audio_measurements.stages" */
  audio_measurements_stages: Array<Audio_Measurements_Stages>;
  /** fetch data from the table: "audio_measurements.stages" using primary key columns */
  audio_measurements_stages_by_pk?: Maybe<Audio_Measurements_Stages>;
  /** fetch data from the table in a streaming manner : "audio_measurements.stages" */
  audio_measurements_stages_stream: Array<Audio_Measurements_Stages>;
  /** fetch data from the table: "bringup.checklist_deliverable" */
  bringup_checklist_deliverable: Array<Bringup_Checklist_Deliverable>;
  /** fetch aggregated fields from the table: "bringup.checklist_deliverable" */
  bringup_checklist_deliverable_aggregate: Bringup_Checklist_Deliverable_Aggregate;
  /** fetch data from the table: "bringup.checklist_deliverable" using primary key columns */
  bringup_checklist_deliverable_by_pk?: Maybe<Bringup_Checklist_Deliverable>;
  /** fetch data from the table in a streaming manner : "bringup.checklist_deliverable" */
  bringup_checklist_deliverable_stream: Array<Bringup_Checklist_Deliverable>;
  /** fetch data from the table: "bringup.checklists" */
  bringup_checklists: Array<Bringup_Checklists>;
  /** fetch aggregated fields from the table: "bringup.checklists" */
  bringup_checklists_aggregate: Bringup_Checklists_Aggregate;
  /** fetch data from the table: "bringup.checklists" using primary key columns */
  bringup_checklists_by_pk?: Maybe<Bringup_Checklists>;
  /** fetch data from the table in a streaming manner : "bringup.checklists" */
  bringup_checklists_stream: Array<Bringup_Checklists>;
  /** fetch data from the table: "bringup.endorsements" */
  bringup_endorsements: Array<Bringup_Endorsements>;
  /** fetch aggregated fields from the table: "bringup.endorsements" */
  bringup_endorsements_aggregate: Bringup_Endorsements_Aggregate;
  /** fetch data from the table: "bringup.endorsements" using primary key columns */
  bringup_endorsements_by_pk?: Maybe<Bringup_Endorsements>;
  /** fetch data from the table in a streaming manner : "bringup.endorsements" */
  bringup_endorsements_stream: Array<Bringup_Endorsements>;
  /** fetch data from the table: "bringup.items" */
  bringup_items: Array<Bringup_Items>;
  /** fetch aggregated fields from the table: "bringup.items" */
  bringup_items_aggregate: Bringup_Items_Aggregate;
  /** fetch data from the table: "bringup.items" using primary key columns */
  bringup_items_by_pk?: Maybe<Bringup_Items>;
  /** fetch data from the table in a streaming manner : "bringup.items" */
  bringup_items_stream: Array<Bringup_Items>;
  /** fetch data from the table: "bringup.milestones" */
  bringup_milestones: Array<Bringup_Milestones>;
  /** fetch aggregated fields from the table: "bringup.milestones" */
  bringup_milestones_aggregate: Bringup_Milestones_Aggregate;
  /** fetch data from the table: "bringup.milestones" using primary key columns */
  bringup_milestones_by_pk?: Maybe<Bringup_Milestones>;
  /** fetch data from the table in a streaming manner : "bringup.milestones" */
  bringup_milestones_stream: Array<Bringup_Milestones>;
  /** fetch data from the table: "bringup.project_item_comments" */
  bringup_project_item_comments: Array<Bringup_Project_Item_Comments>;
  /** fetch aggregated fields from the table: "bringup.project_item_comments" */
  bringup_project_item_comments_aggregate: Bringup_Project_Item_Comments_Aggregate;
  /** fetch data from the table: "bringup.project_item_comments" using primary key columns */
  bringup_project_item_comments_by_pk?: Maybe<Bringup_Project_Item_Comments>;
  /** fetch data from the table in a streaming manner : "bringup.project_item_comments" */
  bringup_project_item_comments_stream: Array<Bringup_Project_Item_Comments>;
  /** fetch data from the table: "categories" */
  categories: Array<Categories>;
  /** fetch aggregated fields from the table: "categories" */
  categories_aggregate: Categories_Aggregate;
  /** fetch data from the table: "categories" using primary key columns */
  categories_by_pk?: Maybe<Categories>;
  /** fetch data from the table in a streaming manner : "categories" */
  categories_stream: Array<Categories>;
  /** fetch data from the table: "context_display_field" */
  context_display_field: Array<Context_Display_Field>;
  /** fetch aggregated fields from the table: "context_display_field" */
  context_display_field_aggregate: Context_Display_Field_Aggregate;
  /** fetch data from the table: "context_display_field" using primary key columns */
  context_display_field_by_pk?: Maybe<Context_Display_Field>;
  /** fetch data from the table in a streaming manner : "context_display_field" */
  context_display_field_stream: Array<Context_Display_Field>;
  /** An array relationship */
  context_displays: Array<Context_Displays>;
  /** fetch data from the table: "context_displays" using primary key columns */
  context_displays_by_pk?: Maybe<Context_Displays>;
  /** fetch data from the table in a streaming manner : "context_displays" */
  context_displays_stream: Array<Context_Displays>;
  /** An array relationship */
  contexts: Array<Contexts>;
  /** An aggregate relationship */
  contexts_aggregate: Contexts_Aggregate;
  /** fetch data from the table: "contexts" using primary key columns */
  contexts_by_pk?: Maybe<Contexts>;
  /** fetch data from the table in a streaming manner : "contexts" */
  contexts_stream: Array<Contexts>;
  /** fetch data from the table: "customers" */
  customers: Array<Customers>;
  /** fetch aggregated fields from the table: "customers" */
  customers_aggregate: Customers_Aggregate;
  /** fetch data from the table: "customers" using primary key columns */
  customers_by_pk?: Maybe<Customers>;
  /** fetch data from the table in a streaming manner : "customers" */
  customers_stream: Array<Customers>;
  /** fetch data from the table: "deliverables" */
  deliverables: Array<Deliverables>;
  /** fetch aggregated fields from the table: "deliverables" */
  deliverables_aggregate: Deliverables_Aggregate;
  /** fetch data from the table: "deliverables" using primary key columns */
  deliverables_by_pk?: Maybe<Deliverables>;
  /** fetch data from the table in a streaming manner : "deliverables" */
  deliverables_stream: Array<Deliverables>;
  /** fetch data from the table: "deliveries.architectures" */
  deliveries_architectures: Array<Deliveries_Architectures>;
  /** fetch data from the table: "deliveries.architectures" using primary key columns */
  deliveries_architectures_by_pk?: Maybe<Deliveries_Architectures>;
  /** fetch data from the table in a streaming manner : "deliveries.architectures" */
  deliveries_architectures_stream: Array<Deliveries_Architectures>;
  /** fetch data from the table: "deliveries.artifact_microsoft_whql_submission" */
  deliveries_artifact_microsoft_whql_submission: Array<Deliveries_Artifact_Microsoft_Whql_Submission>;
  /** fetch aggregated fields from the table: "deliveries.artifact_microsoft_whql_submission" */
  deliveries_artifact_microsoft_whql_submission_aggregate: Deliveries_Artifact_Microsoft_Whql_Submission_Aggregate;
  /** fetch data from the table: "deliveries.artifact_microsoft_whql_submission" using primary key columns */
  deliveries_artifact_microsoft_whql_submission_by_pk?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission>;
  /** fetch data from the table in a streaming manner : "deliveries.artifact_microsoft_whql_submission" */
  deliveries_artifact_microsoft_whql_submission_stream: Array<Deliveries_Artifact_Microsoft_Whql_Submission>;
  /** fetch data from the table: "deliveries.artifact_operating_system" */
  deliveries_artifact_operating_system: Array<Deliveries_Artifact_Operating_System>;
  /** fetch data from the table: "deliveries.artifact_operating_system" using primary key columns */
  deliveries_artifact_operating_system_by_pk?: Maybe<Deliveries_Artifact_Operating_System>;
  /** fetch data from the table in a streaming manner : "deliveries.artifact_operating_system" */
  deliveries_artifact_operating_system_stream: Array<Deliveries_Artifact_Operating_System>;
  /** fetch data from the table: "deliveries.artifacts" */
  deliveries_artifacts: Array<Deliveries_Artifacts>;
  /** fetch aggregated fields from the table: "deliveries.artifacts" */
  deliveries_artifacts_aggregate: Deliveries_Artifacts_Aggregate;
  /** fetch data from the table: "deliveries.artifacts" using primary key columns */
  deliveries_artifacts_by_pk?: Maybe<Deliveries_Artifacts>;
  /** fetch data from the table in a streaming manner : "deliveries.artifacts" */
  deliveries_artifacts_stream: Array<Deliveries_Artifacts>;
  /** fetch data from the table: "deliveries.device_families" */
  deliveries_device_families: Array<Deliveries_Device_Families>;
  /** fetch data from the table: "deliveries.device_families" using primary key columns */
  deliveries_device_families_by_pk?: Maybe<Deliveries_Device_Families>;
  /** fetch data from the table in a streaming manner : "deliveries.device_families" */
  deliveries_device_families_stream: Array<Deliveries_Device_Families>;
  /** fetch data from the table: "deliveries.driver_package" */
  deliveries_driver_package: Array<Deliveries_Driver_Package>;
  /** fetch aggregated fields from the table: "deliveries.driver_package" */
  deliveries_driver_package_aggregate: Deliveries_Driver_Package_Aggregate;
  /** fetch data from the table: "deliveries.driver_package" using primary key columns */
  deliveries_driver_package_by_pk?: Maybe<Deliveries_Driver_Package>;
  /** fetch data from the table in a streaming manner : "deliveries.driver_package" */
  deliveries_driver_package_stream: Array<Deliveries_Driver_Package>;
  /** fetch data from the table: "deliveries.driver_version_package" */
  deliveries_driver_version_package: Array<Deliveries_Driver_Version_Package>;
  /** fetch aggregated fields from the table: "deliveries.driver_version_package" */
  deliveries_driver_version_package_aggregate: Deliveries_Driver_Version_Package_Aggregate;
  /** fetch data from the table: "deliveries.driver_version_package" using primary key columns */
  deliveries_driver_version_package_by_pk?: Maybe<Deliveries_Driver_Version_Package>;
  /** fetch data from the table in a streaming manner : "deliveries.driver_version_package" */
  deliveries_driver_version_package_stream: Array<Deliveries_Driver_Version_Package>;
  /** fetch data from the table: "deliveries.driver_versions" */
  deliveries_driver_versions: Array<Deliveries_Driver_Versions>;
  /** fetch aggregated fields from the table: "deliveries.driver_versions" */
  deliveries_driver_versions_aggregate: Deliveries_Driver_Versions_Aggregate;
  /** fetch data from the table: "deliveries.driver_versions" using primary key columns */
  deliveries_driver_versions_by_pk?: Maybe<Deliveries_Driver_Versions>;
  /** fetch data from the table in a streaming manner : "deliveries.driver_versions" */
  deliveries_driver_versions_stream: Array<Deliveries_Driver_Versions>;
  /** fetch data from the table: "deliveries.drivers" */
  deliveries_drivers: Array<Deliveries_Drivers>;
  /** fetch aggregated fields from the table: "deliveries.drivers" */
  deliveries_drivers_aggregate: Deliveries_Drivers_Aggregate;
  /** fetch data from the table: "deliveries.drivers" using primary key columns */
  deliveries_drivers_by_pk?: Maybe<Deliveries_Drivers>;
  /** fetch data from the table in a streaming manner : "deliveries.drivers" */
  deliveries_drivers_stream: Array<Deliveries_Drivers>;
  /** fetch data from the table: "deliveries.microsoft_hlk_artifacts" */
  deliveries_microsoft_hlk_artifacts: Array<Deliveries_Microsoft_Hlk_Artifacts>;
  /** fetch aggregated fields from the table: "deliveries.microsoft_hlk_artifacts" */
  deliveries_microsoft_hlk_artifacts_aggregate: Deliveries_Microsoft_Hlk_Artifacts_Aggregate;
  /** fetch data from the table: "deliveries.microsoft_hlk_artifacts" using primary key columns */
  deliveries_microsoft_hlk_artifacts_by_pk?: Maybe<Deliveries_Microsoft_Hlk_Artifacts>;
  /** fetch data from the table in a streaming manner : "deliveries.microsoft_hlk_artifacts" */
  deliveries_microsoft_hlk_artifacts_stream: Array<Deliveries_Microsoft_Hlk_Artifacts>;
  /** fetch data from the table: "deliveries.microsoft_shipping_label_reviews" */
  deliveries_microsoft_shipping_label_reviews: Array<Deliveries_Microsoft_Shipping_Label_Reviews>;
  /** fetch data from the table: "deliveries.microsoft_shipping_label_reviews" using primary key columns */
  deliveries_microsoft_shipping_label_reviews_by_pk?: Maybe<Deliveries_Microsoft_Shipping_Label_Reviews>;
  /** fetch data from the table in a streaming manner : "deliveries.microsoft_shipping_label_reviews" */
  deliveries_microsoft_shipping_label_reviews_stream: Array<Deliveries_Microsoft_Shipping_Label_Reviews>;
  /** fetch data from the table: "deliveries.microsoft_shipping_labels" */
  deliveries_microsoft_shipping_labels: Array<Deliveries_Microsoft_Shipping_Labels>;
  /** fetch aggregated fields from the table: "deliveries.microsoft_shipping_labels" */
  deliveries_microsoft_shipping_labels_aggregate: Deliveries_Microsoft_Shipping_Labels_Aggregate;
  /** fetch data from the table: "deliveries.microsoft_shipping_labels" using primary key columns */
  deliveries_microsoft_shipping_labels_by_pk?: Maybe<Deliveries_Microsoft_Shipping_Labels>;
  /** fetch data from the table in a streaming manner : "deliveries.microsoft_shipping_labels" */
  deliveries_microsoft_shipping_labels_stream: Array<Deliveries_Microsoft_Shipping_Labels>;
  /** fetch data from the table: "deliveries.microsoft_whql_submissions" */
  deliveries_microsoft_whql_submissions: Array<Deliveries_Microsoft_Whql_Submissions>;
  /** fetch aggregated fields from the table: "deliveries.microsoft_whql_submissions" */
  deliveries_microsoft_whql_submissions_aggregate: Deliveries_Microsoft_Whql_Submissions_Aggregate;
  /** fetch data from the table: "deliveries.microsoft_whql_submissions" using primary key columns */
  deliveries_microsoft_whql_submissions_by_pk?: Maybe<Deliveries_Microsoft_Whql_Submissions>;
  /** fetch data from the table in a streaming manner : "deliveries.microsoft_whql_submissions" */
  deliveries_microsoft_whql_submissions_stream: Array<Deliveries_Microsoft_Whql_Submissions>;
  /** fetch data from the table: "deliveries.operating_systems" */
  deliveries_operating_systems: Array<Deliveries_Operating_Systems>;
  /** fetch data from the table: "deliveries.operating_systems" using primary key columns */
  deliveries_operating_systems_by_pk?: Maybe<Deliveries_Operating_Systems>;
  /** fetch data from the table in a streaming manner : "deliveries.operating_systems" */
  deliveries_operating_systems_stream: Array<Deliveries_Operating_Systems>;
  /** fetch data from the table: "deliveries.package_operating_systems" */
  deliveries_package_operating_systems: Array<Deliveries_Package_Operating_Systems>;
  /** fetch data from the table: "deliveries.package_operating_systems" using primary key columns */
  deliveries_package_operating_systems_by_pk?: Maybe<Deliveries_Package_Operating_Systems>;
  /** fetch data from the table in a streaming manner : "deliveries.package_operating_systems" */
  deliveries_package_operating_systems_stream: Array<Deliveries_Package_Operating_Systems>;
  /** fetch data from the table: "deliveries.package_target" */
  deliveries_package_target: Array<Deliveries_Package_Target>;
  /** fetch aggregated fields from the table: "deliveries.package_target" */
  deliveries_package_target_aggregate: Deliveries_Package_Target_Aggregate;
  /** fetch data from the table: "deliveries.package_target" using primary key columns */
  deliveries_package_target_by_pk?: Maybe<Deliveries_Package_Target>;
  /** fetch data from the table in a streaming manner : "deliveries.package_target" */
  deliveries_package_target_stream: Array<Deliveries_Package_Target>;
  /** fetch data from the table: "deliveries.packages" */
  deliveries_packages: Array<Deliveries_Packages>;
  /** fetch aggregated fields from the table: "deliveries.packages" */
  deliveries_packages_aggregate: Deliveries_Packages_Aggregate;
  /** fetch data from the table: "deliveries.packages" using primary key columns */
  deliveries_packages_by_pk?: Maybe<Deliveries_Packages>;
  /** fetch data from the table in a streaming manner : "deliveries.packages" */
  deliveries_packages_stream: Array<Deliveries_Packages>;
  /** fetch data from the table: "deliveries.partners" */
  deliveries_partners: Array<Deliveries_Partners>;
  /** fetch data from the table: "deliveries.partners" using primary key columns */
  deliveries_partners_by_pk?: Maybe<Deliveries_Partners>;
  /** fetch data from the table in a streaming manner : "deliveries.partners" */
  deliveries_partners_stream: Array<Deliveries_Partners>;
  /** fetch data from the table: "deliveries.platforms" */
  deliveries_platforms: Array<Deliveries_Platforms>;
  /** fetch data from the table: "deliveries.platforms" using primary key columns */
  deliveries_platforms_by_pk?: Maybe<Deliveries_Platforms>;
  /** fetch data from the table in a streaming manner : "deliveries.platforms" */
  deliveries_platforms_stream: Array<Deliveries_Platforms>;
  /** fetch data from the table: "deliveries.project_configs" */
  deliveries_project_configs: Array<Deliveries_Project_Configs>;
  /** fetch aggregated fields from the table: "deliveries.project_configs" */
  deliveries_project_configs_aggregate: Deliveries_Project_Configs_Aggregate;
  /** fetch data from the table: "deliveries.project_configs" using primary key columns */
  deliveries_project_configs_by_pk?: Maybe<Deliveries_Project_Configs>;
  /** fetch data from the table in a streaming manner : "deliveries.project_configs" */
  deliveries_project_configs_stream: Array<Deliveries_Project_Configs>;
  /** fetch data from the table: "deliveries.target_partner" */
  deliveries_target_partner: Array<Deliveries_Target_Partner>;
  /** fetch data from the table: "deliveries.target_partner" using primary key columns */
  deliveries_target_partner_by_pk?: Maybe<Deliveries_Target_Partner>;
  /** fetch data from the table in a streaming manner : "deliveries.target_partner" */
  deliveries_target_partner_stream: Array<Deliveries_Target_Partner>;
  /** fetch data from the table: "deliveries.targets" */
  deliveries_targets: Array<Deliveries_Targets>;
  /** fetch aggregated fields from the table: "deliveries.targets" */
  deliveries_targets_aggregate: Deliveries_Targets_Aggregate;
  /** fetch data from the table: "deliveries.targets" using primary key columns */
  deliveries_targets_by_pk?: Maybe<Deliveries_Targets>;
  /** fetch data from the table in a streaming manner : "deliveries.targets" */
  deliveries_targets_stream: Array<Deliveries_Targets>;
  /** fetch data from the table: "device_firmware" */
  device_firmware: Array<Device_Firmware>;
  /** fetch data from the table: "device_firmware" using primary key columns */
  device_firmware_by_pk?: Maybe<Device_Firmware>;
  /** fetch data from the table in a streaming manner : "device_firmware" */
  device_firmware_stream: Array<Device_Firmware>;
  /** fetch data from the table: "device_project" */
  device_project: Array<Device_Project>;
  /** fetch aggregated fields from the table: "device_project" */
  device_project_aggregate: Device_Project_Aggregate;
  /** fetch data from the table: "device_project" using primary key columns */
  device_project_by_pk?: Maybe<Device_Project>;
  /** fetch data from the table in a streaming manner : "device_project" */
  device_project_stream: Array<Device_Project>;
  /** An array relationship */
  devices: Array<Devices>;
  /** An aggregate relationship */
  devices_aggregate: Devices_Aggregate;
  /** fetch data from the table: "devices" using primary key columns */
  devices_by_pk?: Maybe<Devices>;
  /** fetch data from the table in a streaming manner : "devices" */
  devices_stream: Array<Devices>;
  /** fetch data from the table: "dynamic_scaling" */
  dynamic_scaling: Array<Dynamic_Scaling>;
  /** fetch data from the table: "dynamic_scaling" using primary key columns */
  dynamic_scaling_by_pk?: Maybe<Dynamic_Scaling>;
  /** fetch data from the table in a streaming manner : "dynamic_scaling" */
  dynamic_scaling_stream: Array<Dynamic_Scaling>;
  /** fetch data from the table: "engine_opmode" */
  engine_opmode: Array<Engine_Opmode>;
  /** fetch aggregated fields from the table: "engine_opmode" */
  engine_opmode_aggregate: Engine_Opmode_Aggregate;
  /** fetch data from the table: "engine_opmode" using primary key columns */
  engine_opmode_by_pk?: Maybe<Engine_Opmode>;
  /** fetch data from the table: "engine_opmode_recording" */
  engine_opmode_recording: Array<Engine_Opmode_Recording>;
  /** fetch aggregated fields from the table: "engine_opmode_recording" */
  engine_opmode_recording_aggregate: Engine_Opmode_Recording_Aggregate;
  /** fetch data from the table: "engine_opmode_recording" using primary key columns */
  engine_opmode_recording_by_pk?: Maybe<Engine_Opmode_Recording>;
  /** fetch data from the table in a streaming manner : "engine_opmode_recording" */
  engine_opmode_recording_stream: Array<Engine_Opmode_Recording>;
  /** fetch data from the table in a streaming manner : "engine_opmode" */
  engine_opmode_stream: Array<Engine_Opmode>;
  /** fetch data from the table: "engine_types" */
  engine_types: Array<Engine_Types>;
  /** fetch aggregated fields from the table: "engine_types" */
  engine_types_aggregate: Engine_Types_Aggregate;
  /** fetch data from the table: "engine_types" using primary key columns */
  engine_types_by_pk?: Maybe<Engine_Types>;
  /** fetch data from the table in a streaming manner : "engine_types" */
  engine_types_stream: Array<Engine_Types>;
  /** fetch data from the table: "engines" */
  engines: Array<Engines>;
  /** fetch aggregated fields from the table: "engines" */
  engines_aggregate: Engines_Aggregate;
  /** fetch data from the table: "engines" using primary key columns */
  engines_by_pk?: Maybe<Engines>;
  /** fetch data from the table in a streaming manner : "engines" */
  engines_stream: Array<Engines>;
  /** An array relationship */
  field_option_media: Array<Field_Option_Media>;
  /** An aggregate relationship */
  field_option_media_aggregate: Field_Option_Media_Aggregate;
  /** fetch data from the table: "field_option_media" using primary key columns */
  field_option_media_by_pk?: Maybe<Field_Option_Media>;
  /** fetch data from the table in a streaming manner : "field_option_media" */
  field_option_media_stream: Array<Field_Option_Media>;
  /** An array relationship */
  field_options: Array<Field_Options>;
  /** An aggregate relationship */
  field_options_aggregate: Field_Options_Aggregate;
  /** fetch data from the table: "field_options" using primary key columns */
  field_options_by_pk?: Maybe<Field_Options>;
  /** fetch data from the table in a streaming manner : "field_options" */
  field_options_stream: Array<Field_Options>;
  /** fetch data from the table: "field_translation" */
  field_translation: Array<Field_Translation>;
  /** fetch aggregated fields from the table: "field_translation" */
  field_translation_aggregate: Field_Translation_Aggregate;
  /** fetch data from the table: "field_translation" using primary key columns */
  field_translation_by_pk?: Maybe<Field_Translation>;
  /** fetch data from the table in a streaming manner : "field_translation" */
  field_translation_stream: Array<Field_Translation>;
  /** An array relationship */
  fields: Array<Fields>;
  /** An aggregate relationship */
  fields_aggregate: Fields_Aggregate;
  /** fetch data from the table: "fields" using primary key columns */
  fields_by_pk?: Maybe<Fields>;
  /** fetch data from the table in a streaming manner : "fields" */
  fields_stream: Array<Fields>;
  /** fetch data from the table: "firmware" */
  firmware: Array<Firmware>;
  /** fetch aggregated fields from the table: "firmware" */
  firmware_aggregate: Firmware_Aggregate;
  /** fetch data from the table: "firmware" using primary key columns */
  firmware_by_pk?: Maybe<Firmware>;
  /** fetch data from the table in a streaming manner : "firmware" */
  firmware_stream: Array<Firmware>;
  /** fetch data from the table: "ground_truth.configurations" */
  ground_truth_configurations: Array<Ground_Truth_Configurations>;
  /** fetch aggregated fields from the table: "ground_truth.configurations" */
  ground_truth_configurations_aggregate: Ground_Truth_Configurations_Aggregate;
  /** fetch data from the table: "ground_truth.configurations" using primary key columns */
  ground_truth_configurations_by_pk?: Maybe<Ground_Truth_Configurations>;
  /** fetch data from the table in a streaming manner : "ground_truth.configurations" */
  ground_truth_configurations_stream: Array<Ground_Truth_Configurations>;
  /** fetch data from the table: "ground_truth.device_types" */
  ground_truth_device_types: Array<Ground_Truth_Device_Types>;
  /** fetch aggregated fields from the table: "ground_truth.device_types" */
  ground_truth_device_types_aggregate: Ground_Truth_Device_Types_Aggregate;
  /** fetch data from the table: "ground_truth.device_types" using primary key columns */
  ground_truth_device_types_by_pk?: Maybe<Ground_Truth_Device_Types>;
  /** fetch data from the table in a streaming manner : "ground_truth.device_types" */
  ground_truth_device_types_stream: Array<Ground_Truth_Device_Types>;
  /** fetch data from the table: "ground_truth.processing_modes" */
  ground_truth_processing_modes: Array<Ground_Truth_Processing_Modes>;
  /** fetch aggregated fields from the table: "ground_truth.processing_modes" */
  ground_truth_processing_modes_aggregate: Ground_Truth_Processing_Modes_Aggregate;
  /** fetch data from the table: "ground_truth.processing_modes" using primary key columns */
  ground_truth_processing_modes_by_pk?: Maybe<Ground_Truth_Processing_Modes>;
  /** fetch data from the table in a streaming manner : "ground_truth.processing_modes" */
  ground_truth_processing_modes_stream: Array<Ground_Truth_Processing_Modes>;
  /** fetch data from the table: "ground_truth.recordings" */
  ground_truth_recordings: Array<Ground_Truth_Recordings>;
  /** fetch aggregated fields from the table: "ground_truth.recordings" */
  ground_truth_recordings_aggregate: Ground_Truth_Recordings_Aggregate;
  /** fetch data from the table: "ground_truth.recordings" using primary key columns */
  ground_truth_recordings_by_pk?: Maybe<Ground_Truth_Recordings>;
  /** fetch data from the table in a streaming manner : "ground_truth.recordings" */
  ground_truth_recordings_stream: Array<Ground_Truth_Recordings>;
  /** fetch data from the table: "ground_truth_types" */
  ground_truth_types: Array<Ground_Truth_Types>;
  /** fetch aggregated fields from the table: "ground_truth_types" */
  ground_truth_types_aggregate: Ground_Truth_Types_Aggregate;
  /** fetch data from the table: "ground_truth_types" using primary key columns */
  ground_truth_types_by_pk?: Maybe<Ground_Truth_Types>;
  /** fetch data from the table in a streaming manner : "ground_truth_types" */
  ground_truth_types_stream: Array<Ground_Truth_Types>;
  /** fetch data from the table: "hypertask_field_option" */
  hypertask_field_option: Array<Hypertask_Field_Option>;
  /** fetch aggregated fields from the table: "hypertask_field_option" */
  hypertask_field_option_aggregate: Hypertask_Field_Option_Aggregate;
  /** fetch data from the table: "hypertask_field_option" using primary key columns */
  hypertask_field_option_by_pk?: Maybe<Hypertask_Field_Option>;
  /** fetch data from the table in a streaming manner : "hypertask_field_option" */
  hypertask_field_option_stream: Array<Hypertask_Field_Option>;
  /** fetch data from the table: "hypertask_task" */
  hypertask_task: Array<Hypertask_Task>;
  /** fetch aggregated fields from the table: "hypertask_task" */
  hypertask_task_aggregate: Hypertask_Task_Aggregate;
  /** fetch data from the table: "hypertask_task" using primary key columns */
  hypertask_task_by_pk?: Maybe<Hypertask_Task>;
  /** fetch data from the table in a streaming manner : "hypertask_task" */
  hypertask_task_stream: Array<Hypertask_Task>;
  /** fetch data from the table: "hypertasks" */
  hypertasks: Array<Hypertasks>;
  /** fetch aggregated fields from the table: "hypertasks" */
  hypertasks_aggregate: Hypertasks_Aggregate;
  /** fetch data from the table: "hypertasks" using primary key columns */
  hypertasks_by_pk?: Maybe<Hypertasks>;
  /** fetch data from the table in a streaming manner : "hypertasks" */
  hypertasks_stream: Array<Hypertasks>;
  /** An array relationship */
  instruction_translations: Array<Instruction_Translations>;
  /** fetch data from the table: "instruction_translations" using primary key columns */
  instruction_translations_by_pk?: Maybe<Instruction_Translations>;
  /** fetch data from the table in a streaming manner : "instruction_translations" */
  instruction_translations_stream: Array<Instruction_Translations>;
  /** fetch data from the table: "integration_test.expectation_media" */
  integration_test_expectation_media: Array<Integration_Test_Expectation_Media>;
  /** fetch aggregated fields from the table: "integration_test.expectation_media" */
  integration_test_expectation_media_aggregate: Integration_Test_Expectation_Media_Aggregate;
  /** fetch data from the table: "integration_test.expectation_media" using primary key columns */
  integration_test_expectation_media_by_pk?: Maybe<Integration_Test_Expectation_Media>;
  /** fetch data from the table in a streaming manner : "integration_test.expectation_media" */
  integration_test_expectation_media_stream: Array<Integration_Test_Expectation_Media>;
  /** fetch data from the table: "integration_test.expectation_step" */
  integration_test_expectation_step: Array<Integration_Test_Expectation_Step>;
  /** fetch aggregated fields from the table: "integration_test.expectation_step" */
  integration_test_expectation_step_aggregate: Integration_Test_Expectation_Step_Aggregate;
  /** fetch data from the table: "integration_test.expectation_step" using primary key columns */
  integration_test_expectation_step_by_pk?: Maybe<Integration_Test_Expectation_Step>;
  /** fetch data from the table in a streaming manner : "integration_test.expectation_step" */
  integration_test_expectation_step_stream: Array<Integration_Test_Expectation_Step>;
  /** fetch data from the table: "integration_test.expectation_translation" */
  integration_test_expectation_translation: Array<Integration_Test_Expectation_Translation>;
  /** fetch aggregated fields from the table: "integration_test.expectation_translation" */
  integration_test_expectation_translation_aggregate: Integration_Test_Expectation_Translation_Aggregate;
  /** fetch data from the table: "integration_test.expectation_translation" using primary key columns */
  integration_test_expectation_translation_by_pk?: Maybe<Integration_Test_Expectation_Translation>;
  /** fetch data from the table in a streaming manner : "integration_test.expectation_translation" */
  integration_test_expectation_translation_stream: Array<Integration_Test_Expectation_Translation>;
  /** fetch data from the table: "integration_test.expectations" */
  integration_test_expectations: Array<Integration_Test_Expectations>;
  /** fetch aggregated fields from the table: "integration_test.expectations" */
  integration_test_expectations_aggregate: Integration_Test_Expectations_Aggregate;
  /** fetch data from the table: "integration_test.expectations" using primary key columns */
  integration_test_expectations_by_pk?: Maybe<Integration_Test_Expectations>;
  /** fetch data from the table in a streaming manner : "integration_test.expectations" */
  integration_test_expectations_stream: Array<Integration_Test_Expectations>;
  /** fetch data from the table: "integration_test.instruction_media" */
  integration_test_instruction_media: Array<Integration_Test_Instruction_Media>;
  /** fetch aggregated fields from the table: "integration_test.instruction_media" */
  integration_test_instruction_media_aggregate: Integration_Test_Instruction_Media_Aggregate;
  /** fetch data from the table: "integration_test.instruction_media" using primary key columns */
  integration_test_instruction_media_by_pk?: Maybe<Integration_Test_Instruction_Media>;
  /** fetch data from the table in a streaming manner : "integration_test.instruction_media" */
  integration_test_instruction_media_stream: Array<Integration_Test_Instruction_Media>;
  /** fetch data from the table: "integration_test.instruction_translation" */
  integration_test_instruction_translation: Array<Integration_Test_Instruction_Translation>;
  /** fetch aggregated fields from the table: "integration_test.instruction_translation" */
  integration_test_instruction_translation_aggregate: Integration_Test_Instruction_Translation_Aggregate;
  /** fetch data from the table: "integration_test.instruction_translation" using primary key columns */
  integration_test_instruction_translation_by_pk?: Maybe<Integration_Test_Instruction_Translation>;
  /** fetch data from the table in a streaming manner : "integration_test.instruction_translation" */
  integration_test_instruction_translation_stream: Array<Integration_Test_Instruction_Translation>;
  /** fetch data from the table: "integration_test.instructions" */
  integration_test_instructions: Array<Integration_Test_Instructions>;
  /** fetch aggregated fields from the table: "integration_test.instructions" */
  integration_test_instructions_aggregate: Integration_Test_Instructions_Aggregate;
  /** fetch data from the table: "integration_test.instructions" using primary key columns */
  integration_test_instructions_by_pk?: Maybe<Integration_Test_Instructions>;
  /** fetch data from the table in a streaming manner : "integration_test.instructions" */
  integration_test_instructions_stream: Array<Integration_Test_Instructions>;
  /** fetch data from the table: "integration_test.observances" */
  integration_test_observances: Array<Integration_Test_Observances>;
  /** fetch aggregated fields from the table: "integration_test.observances" */
  integration_test_observances_aggregate: Integration_Test_Observances_Aggregate;
  /** fetch data from the table: "integration_test.observances" using primary key columns */
  integration_test_observances_by_pk?: Maybe<Integration_Test_Observances>;
  /** fetch data from the table in a streaming manner : "integration_test.observances" */
  integration_test_observances_stream: Array<Integration_Test_Observances>;
  /** fetch data from the table: "integration_test.observations" */
  integration_test_observations: Array<Integration_Test_Observations>;
  /** fetch aggregated fields from the table: "integration_test.observations" */
  integration_test_observations_aggregate: Integration_Test_Observations_Aggregate;
  /** fetch data from the table: "integration_test.observations" using primary key columns */
  integration_test_observations_by_pk?: Maybe<Integration_Test_Observations>;
  /** fetch data from the table in a streaming manner : "integration_test.observations" */
  integration_test_observations_stream: Array<Integration_Test_Observations>;
  /** fetch data from the table: "integration_test.project_sequence" */
  integration_test_project_sequence: Array<Integration_Test_Project_Sequence>;
  /** fetch aggregated fields from the table: "integration_test.project_sequence" */
  integration_test_project_sequence_aggregate: Integration_Test_Project_Sequence_Aggregate;
  /** fetch data from the table: "integration_test.project_sequence" using primary key columns */
  integration_test_project_sequence_by_pk?: Maybe<Integration_Test_Project_Sequence>;
  /** fetch data from the table in a streaming manner : "integration_test.project_sequence" */
  integration_test_project_sequence_stream: Array<Integration_Test_Project_Sequence>;
  /** fetch data from the table: "integration_test.sequences" */
  integration_test_sequences: Array<Integration_Test_Sequences>;
  /** fetch aggregated fields from the table: "integration_test.sequences" */
  integration_test_sequences_aggregate: Integration_Test_Sequences_Aggregate;
  /** fetch data from the table: "integration_test.sequences" using primary key columns */
  integration_test_sequences_by_pk?: Maybe<Integration_Test_Sequences>;
  /** fetch data from the table in a streaming manner : "integration_test.sequences" */
  integration_test_sequences_stream: Array<Integration_Test_Sequences>;
  /** fetch data from the table: "integration_test.steps" */
  integration_test_steps: Array<Integration_Test_Steps>;
  /** fetch aggregated fields from the table: "integration_test.steps" */
  integration_test_steps_aggregate: Integration_Test_Steps_Aggregate;
  /** fetch data from the table: "integration_test.steps" using primary key columns */
  integration_test_steps_by_pk?: Maybe<Integration_Test_Steps>;
  /** fetch data from the table in a streaming manner : "integration_test.steps" */
  integration_test_steps_stream: Array<Integration_Test_Steps>;
  /** fetch data from the table: "languages" */
  languages: Array<Languages>;
  /** fetch data from the table: "languages" using primary key columns */
  languages_by_pk?: Maybe<Languages>;
  /** fetch data from the table in a streaming manner : "languages" */
  languages_stream: Array<Languages>;
  /** fetch data from the table: "machine_learning.blobs" */
  machine_learning_blobs: Array<Machine_Learning_Blobs>;
  /** fetch aggregated fields from the table: "machine_learning.blobs" */
  machine_learning_blobs_aggregate: Machine_Learning_Blobs_Aggregate;
  /** fetch data from the table: "machine_learning.blobs" using primary key columns */
  machine_learning_blobs_by_pk?: Maybe<Machine_Learning_Blobs>;
  /** fetch data from the table in a streaming manner : "machine_learning.blobs" */
  machine_learning_blobs_stream: Array<Machine_Learning_Blobs>;
  /** fetch data from the table: "machine_learning.dataset_feature" */
  machine_learning_dataset_feature: Array<Machine_Learning_Dataset_Feature>;
  /** fetch aggregated fields from the table: "machine_learning.dataset_feature" */
  machine_learning_dataset_feature_aggregate: Machine_Learning_Dataset_Feature_Aggregate;
  /** fetch data from the table: "machine_learning.dataset_feature" using primary key columns */
  machine_learning_dataset_feature_by_pk?: Maybe<Machine_Learning_Dataset_Feature>;
  /** fetch data from the table in a streaming manner : "machine_learning.dataset_feature" */
  machine_learning_dataset_feature_stream: Array<Machine_Learning_Dataset_Feature>;
  /** fetch data from the table: "machine_learning.datasets" */
  machine_learning_datasets: Array<Machine_Learning_Datasets>;
  /** fetch aggregated fields from the table: "machine_learning.datasets" */
  machine_learning_datasets_aggregate: Machine_Learning_Datasets_Aggregate;
  /** fetch data from the table: "machine_learning.datasets" using primary key columns */
  machine_learning_datasets_by_pk?: Maybe<Machine_Learning_Datasets>;
  /** fetch data from the table in a streaming manner : "machine_learning.datasets" */
  machine_learning_datasets_stream: Array<Machine_Learning_Datasets>;
  /** fetch data from the table: "machine_learning.features" */
  machine_learning_features: Array<Machine_Learning_Features>;
  /** fetch data from the table: "machine_learning.features" using primary key columns */
  machine_learning_features_by_pk?: Maybe<Machine_Learning_Features>;
  /** fetch data from the table in a streaming manner : "machine_learning.features" */
  machine_learning_features_stream: Array<Machine_Learning_Features>;
  /** fetch data from the table: "machine_learning.model_blob" */
  machine_learning_model_blob: Array<Machine_Learning_Model_Blob>;
  /** fetch data from the table: "machine_learning.model_blob" using primary key columns */
  machine_learning_model_blob_by_pk?: Maybe<Machine_Learning_Model_Blob>;
  /** fetch data from the table in a streaming manner : "machine_learning.model_blob" */
  machine_learning_model_blob_stream: Array<Machine_Learning_Model_Blob>;
  /** fetch data from the table: "machine_learning.model_dataset" */
  machine_learning_model_dataset: Array<Machine_Learning_Model_Dataset>;
  /** fetch data from the table: "machine_learning.model_dataset" using primary key columns */
  machine_learning_model_dataset_by_pk?: Maybe<Machine_Learning_Model_Dataset>;
  /** fetch data from the table in a streaming manner : "machine_learning.model_dataset" */
  machine_learning_model_dataset_stream: Array<Machine_Learning_Model_Dataset>;
  /** fetch data from the table: "machine_learning.model_recording" */
  machine_learning_model_recording: Array<Machine_Learning_Model_Recording>;
  /** fetch aggregated fields from the table: "machine_learning.model_recording" */
  machine_learning_model_recording_aggregate: Machine_Learning_Model_Recording_Aggregate;
  /** fetch data from the table: "machine_learning.model_recording" using primary key columns */
  machine_learning_model_recording_by_pk?: Maybe<Machine_Learning_Model_Recording>;
  /** fetch data from the table in a streaming manner : "machine_learning.model_recording" */
  machine_learning_model_recording_stream: Array<Machine_Learning_Model_Recording>;
  /** fetch data from the table: "machine_learning.model_types" */
  machine_learning_model_types: Array<Machine_Learning_Model_Types>;
  /** fetch data from the table: "machine_learning.model_types" using primary key columns */
  machine_learning_model_types_by_pk?: Maybe<Machine_Learning_Model_Types>;
  /** fetch data from the table in a streaming manner : "machine_learning.model_types" */
  machine_learning_model_types_stream: Array<Machine_Learning_Model_Types>;
  /** fetch data from the table: "machine_learning.models" */
  machine_learning_models: Array<Machine_Learning_Models>;
  /** fetch data from the table: "machine_learning.models" using primary key columns */
  machine_learning_models_by_pk?: Maybe<Machine_Learning_Models>;
  /** fetch data from the table in a streaming manner : "machine_learning.models" */
  machine_learning_models_stream: Array<Machine_Learning_Models>;
  /** An array relationship */
  media: Array<Media>;
  /** An aggregate relationship */
  media_aggregate: Media_Aggregate;
  /** fetch data from the table: "media" using primary key columns */
  media_by_pk?: Maybe<Media>;
  /** fetch data from the table in a streaming manner : "media" */
  media_stream: Array<Media>;
  /** fetch data from the table: "media_type" */
  media_type: Array<Media_Type>;
  /** fetch data from the table: "media_type" using primary key columns */
  media_type_by_pk?: Maybe<Media_Type>;
  /** fetch data from the table in a streaming manner : "media_type" */
  media_type_stream: Array<Media_Type>;
  /** fetch data from the table: "mode_project" */
  mode_project: Array<Mode_Project>;
  /** fetch aggregated fields from the table: "mode_project" */
  mode_project_aggregate: Mode_Project_Aggregate;
  /** fetch data from the table: "mode_project" using primary key columns */
  mode_project_by_pk?: Maybe<Mode_Project>;
  /** fetch data from the table in a streaming manner : "mode_project" */
  mode_project_stream: Array<Mode_Project>;
  /** fetch data from the table: "model_name_hashes" */
  model_name_hashes: Array<Model_Name_Hashes>;
  /** fetch aggregated fields from the table: "model_name_hashes" */
  model_name_hashes_aggregate: Model_Name_Hashes_Aggregate;
  /** fetch data from the table: "model_name_hashes" using primary key columns */
  model_name_hashes_by_pk?: Maybe<Model_Name_Hashes>;
  /** fetch data from the table in a streaming manner : "model_name_hashes" */
  model_name_hashes_stream: Array<Model_Name_Hashes>;
  /** fetch data from the table: "models" */
  models: Array<Models>;
  /** fetch aggregated fields from the table: "models" */
  models_aggregate: Models_Aggregate;
  /** fetch data from the table: "models" using primary key columns */
  models_by_pk?: Maybe<Models>;
  /** fetch data from the table in a streaming manner : "models" */
  models_stream: Array<Models>;
  /** fetch data from the table: "opmode_profile" */
  opmode_profile: Array<Opmode_Profile>;
  /** fetch data from the table: "opmode_profile" using primary key columns */
  opmode_profile_by_pk?: Maybe<Opmode_Profile>;
  /** fetch data from the table in a streaming manner : "opmode_profile" */
  opmode_profile_stream: Array<Opmode_Profile>;
  /** fetch data from the table: "opmode_project" */
  opmode_project: Array<Opmode_Project>;
  /** fetch aggregated fields from the table: "opmode_project" */
  opmode_project_aggregate: Opmode_Project_Aggregate;
  /** fetch data from the table: "opmode_project" using primary key columns */
  opmode_project_by_pk?: Maybe<Opmode_Project>;
  /** fetch data from the table in a streaming manner : "opmode_project" */
  opmode_project_stream: Array<Opmode_Project>;
  /** fetch data from the table: "opmodes" */
  opmodes: Array<Opmodes>;
  /** fetch aggregated fields from the table: "opmodes" */
  opmodes_aggregate: Opmodes_Aggregate;
  /** fetch data from the table: "opmodes" using primary key columns */
  opmodes_by_pk?: Maybe<Opmodes>;
  /** fetch data from the table in a streaming manner : "opmodes" */
  opmodes_stream: Array<Opmodes>;
  /** An array relationship */
  option_media: Array<Option_Media>;
  /** An aggregate relationship */
  option_media_aggregate: Option_Media_Aggregate;
  /** fetch data from the table: "option_media" using primary key columns */
  option_media_by_pk?: Maybe<Option_Media>;
  /** fetch data from the table in a streaming manner : "option_media" */
  option_media_stream: Array<Option_Media>;
  /** fetch data from the table: "option_translation" */
  option_translation: Array<Option_Translation>;
  /** fetch aggregated fields from the table: "option_translation" */
  option_translation_aggregate: Option_Translation_Aggregate;
  /** fetch data from the table: "option_translation" using primary key columns */
  option_translation_by_pk?: Maybe<Option_Translation>;
  /** fetch data from the table in a streaming manner : "option_translation" */
  option_translation_stream: Array<Option_Translation>;
  /** An array relationship */
  options: Array<Options>;
  /** An aggregate relationship */
  options_aggregate: Options_Aggregate;
  /** fetch data from the table: "options" using primary key columns */
  options_by_pk?: Maybe<Options>;
  /** fetch data from the table in a streaming manner : "options" */
  options_stream: Array<Options>;
  /** fetch data from the table: "payload_recording" */
  payload_recording: Array<Payload_Recording>;
  /** fetch aggregated fields from the table: "payload_recording" */
  payload_recording_aggregate: Payload_Recording_Aggregate;
  /** fetch data from the table: "payload_recording" using primary key columns */
  payload_recording_by_pk?: Maybe<Payload_Recording>;
  /** fetch data from the table in a streaming manner : "payload_recording" */
  payload_recording_stream: Array<Payload_Recording>;
  /** fetch data from the table: "payload_types" */
  payload_types: Array<Payload_Types>;
  /** fetch aggregated fields from the table: "payload_types" */
  payload_types_aggregate: Payload_Types_Aggregate;
  /** fetch data from the table: "payload_types" using primary key columns */
  payload_types_by_pk?: Maybe<Payload_Types>;
  /** fetch data from the table in a streaming manner : "payload_types" */
  payload_types_stream: Array<Payload_Types>;
  /** An array relationship */
  payloads: Array<Payloads>;
  /** An aggregate relationship */
  payloads_aggregate: Payloads_Aggregate;
  /** fetch data from the table: "payloads" using primary key columns */
  payloads_by_pk?: Maybe<Payloads>;
  /** fetch data from the table in a streaming manner : "payloads" */
  payloads_stream: Array<Payloads>;
  /** fetch data from the table: "performance.ratio_metric_contributions" */
  performance_ratio_metric_contributions: Array<Performance_Ratio_Metric_Contributions>;
  /** fetch aggregated fields from the table: "performance.ratio_metric_contributions" */
  performance_ratio_metric_contributions_aggregate: Performance_Ratio_Metric_Contributions_Aggregate;
  /** fetch data from the table: "performance.ratio_metric_contributions" using primary key columns */
  performance_ratio_metric_contributions_by_pk?: Maybe<Performance_Ratio_Metric_Contributions>;
  /** fetch data from the table in a streaming manner : "performance.ratio_metric_contributions" */
  performance_ratio_metric_contributions_stream: Array<Performance_Ratio_Metric_Contributions>;
  /** fetch data from the table: "performance.ratio_metrics" */
  performance_ratio_metrics: Array<Performance_Ratio_Metrics>;
  /** fetch aggregated fields from the table: "performance.ratio_metrics" */
  performance_ratio_metrics_aggregate: Performance_Ratio_Metrics_Aggregate;
  /** fetch data from the table: "performance.ratio_metrics" using primary key columns */
  performance_ratio_metrics_by_pk?: Maybe<Performance_Ratio_Metrics>;
  /** fetch data from the table in a streaming manner : "performance.ratio_metrics" */
  performance_ratio_metrics_stream: Array<Performance_Ratio_Metrics>;
  /** fetch data from the table: "performance_report.deliverable_template" */
  performance_report_deliverable_template: Array<Performance_Report_Deliverable_Template>;
  /** fetch aggregated fields from the table: "performance_report.deliverable_template" */
  performance_report_deliverable_template_aggregate: Performance_Report_Deliverable_Template_Aggregate;
  /** fetch data from the table: "performance_report.deliverable_template" using primary key columns */
  performance_report_deliverable_template_by_pk?: Maybe<Performance_Report_Deliverable_Template>;
  /** fetch data from the table in a streaming manner : "performance_report.deliverable_template" */
  performance_report_deliverable_template_stream: Array<Performance_Report_Deliverable_Template>;
  /** fetch data from the table: "performance_report.pivot_types" */
  performance_report_pivot_types: Array<Performance_Report_Pivot_Types>;
  /** fetch aggregated fields from the table: "performance_report.pivot_types" */
  performance_report_pivot_types_aggregate: Performance_Report_Pivot_Types_Aggregate;
  /** fetch data from the table: "performance_report.pivot_types" using primary key columns */
  performance_report_pivot_types_by_pk?: Maybe<Performance_Report_Pivot_Types>;
  /** fetch data from the table in a streaming manner : "performance_report.pivot_types" */
  performance_report_pivot_types_stream: Array<Performance_Report_Pivot_Types>;
  /** fetch data from the table: "performance_report.section_pivot_field" */
  performance_report_section_pivot_field: Array<Performance_Report_Section_Pivot_Field>;
  /** fetch aggregated fields from the table: "performance_report.section_pivot_field" */
  performance_report_section_pivot_field_aggregate: Performance_Report_Section_Pivot_Field_Aggregate;
  /** fetch data from the table: "performance_report.section_pivot_field" using primary key columns */
  performance_report_section_pivot_field_by_pk?: Maybe<Performance_Report_Section_Pivot_Field>;
  /** fetch data from the table in a streaming manner : "performance_report.section_pivot_field" */
  performance_report_section_pivot_field_stream: Array<Performance_Report_Section_Pivot_Field>;
  /** fetch data from the table: "performance_report.section_ratio_metric" */
  performance_report_section_ratio_metric: Array<Performance_Report_Section_Ratio_Metric>;
  /** fetch aggregated fields from the table: "performance_report.section_ratio_metric" */
  performance_report_section_ratio_metric_aggregate: Performance_Report_Section_Ratio_Metric_Aggregate;
  /** fetch data from the table: "performance_report.section_ratio_metric" using primary key columns */
  performance_report_section_ratio_metric_by_pk?: Maybe<Performance_Report_Section_Ratio_Metric>;
  /** fetch data from the table in a streaming manner : "performance_report.section_ratio_metric" */
  performance_report_section_ratio_metric_stream: Array<Performance_Report_Section_Ratio_Metric>;
  /** fetch data from the table: "performance_report.section_task_blacklist" */
  performance_report_section_task_blacklist: Array<Performance_Report_Section_Task_Blacklist>;
  /** fetch aggregated fields from the table: "performance_report.section_task_blacklist" */
  performance_report_section_task_blacklist_aggregate: Performance_Report_Section_Task_Blacklist_Aggregate;
  /** fetch data from the table: "performance_report.section_task_blacklist" using primary key columns */
  performance_report_section_task_blacklist_by_pk?: Maybe<Performance_Report_Section_Task_Blacklist>;
  /** fetch data from the table in a streaming manner : "performance_report.section_task_blacklist" */
  performance_report_section_task_blacklist_stream: Array<Performance_Report_Section_Task_Blacklist>;
  /** fetch data from the table: "performance_report.sections" */
  performance_report_sections: Array<Performance_Report_Sections>;
  /** fetch aggregated fields from the table: "performance_report.sections" */
  performance_report_sections_aggregate: Performance_Report_Sections_Aggregate;
  /** fetch data from the table: "performance_report.sections" using primary key columns */
  performance_report_sections_by_pk?: Maybe<Performance_Report_Sections>;
  /** fetch data from the table in a streaming manner : "performance_report.sections" */
  performance_report_sections_stream: Array<Performance_Report_Sections>;
  /** fetch data from the table: "performance_report.templates" */
  performance_report_templates: Array<Performance_Report_Templates>;
  /** fetch aggregated fields from the table: "performance_report.templates" */
  performance_report_templates_aggregate: Performance_Report_Templates_Aggregate;
  /** fetch data from the table: "performance_report.templates" using primary key columns */
  performance_report_templates_by_pk?: Maybe<Performance_Report_Templates>;
  /** fetch data from the table in a streaming manner : "performance_report.templates" */
  performance_report_templates_stream: Array<Performance_Report_Templates>;
  /** fetch data from the table: "peripherals" */
  peripherals: Array<Peripherals>;
  /** fetch data from the table: "peripherals" using primary key columns */
  peripherals_by_pk?: Maybe<Peripherals>;
  /** fetch data from the table in a streaming manner : "peripherals" */
  peripherals_stream: Array<Peripherals>;
  /** An array relationship */
  plan_hypertasks: Array<Plan_Hypertasks>;
  /** An aggregate relationship */
  plan_hypertasks_aggregate: Plan_Hypertasks_Aggregate;
  /** fetch data from the table: "plan_hypertasks" using primary key columns */
  plan_hypertasks_by_pk?: Maybe<Plan_Hypertasks>;
  /** fetch data from the table in a streaming manner : "plan_hypertasks" */
  plan_hypertasks_stream: Array<Plan_Hypertasks>;
  /** An array relationship */
  plan_tasks: Array<Plan_Tasks>;
  /** An aggregate relationship */
  plan_tasks_aggregate: Plan_Tasks_Aggregate;
  /** fetch data from the table: "plan_tasks" using primary key columns */
  plan_tasks_by_pk?: Maybe<Plan_Tasks>;
  /** fetch data from the table in a streaming manner : "plan_tasks" */
  plan_tasks_stream: Array<Plan_Tasks>;
  /** fetch data from the table: "plans" */
  plans: Array<Plans>;
  /** fetch aggregated fields from the table: "plans" */
  plans_aggregate: Plans_Aggregate;
  /** fetch data from the table: "plans" using primary key columns */
  plans_by_pk?: Maybe<Plans>;
  /** fetch data from the table in a streaming manner : "plans" */
  plans_stream: Array<Plans>;
  /** fetch data from the table: "profiles" */
  profiles: Array<Profiles>;
  /** fetch aggregated fields from the table: "profiles" */
  profiles_aggregate: Profiles_Aggregate;
  /** fetch data from the table: "profiles" using primary key columns */
  profiles_by_pk?: Maybe<Profiles>;
  /** fetch data from the table in a streaming manner : "profiles" */
  profiles_stream: Array<Profiles>;
  /** fetch data from the table: "project_combo" */
  project_combo: Array<Project_Combo>;
  /** fetch aggregated fields from the table: "project_combo" */
  project_combo_aggregate: Project_Combo_Aggregate;
  /** fetch data from the table: "project_combo" using primary key columns */
  project_combo_by_pk?: Maybe<Project_Combo>;
  /** fetch data from the table in a streaming manner : "project_combo" */
  project_combo_stream: Array<Project_Combo>;
  /** An array relationship */
  project_dataset: Array<Project_Dataset>;
  /** An aggregate relationship */
  project_dataset_aggregate: Project_Dataset_Aggregate;
  /** fetch data from the table in a streaming manner : "project_dataset" */
  project_dataset_stream: Array<Project_Dataset>;
  /** execute function "project_engine_metrics" which returns "schema.project_engine_metrics" */
  project_engine_metrics: Array<Schema_Project_Engine_Metrics>;
  /** execute function "project_engine_ratio_metrics" which returns "schema.project_engine_ratio_metrics" */
  project_engine_ratio_metrics: Array<Schema_Project_Engine_Ratio_Metrics>;
  /** execute function "project_engine_ratio_metrics" and query aggregates on result of table type "schema.project_engine_ratio_metrics" */
  project_engine_ratio_metrics_aggregate: Schema_Project_Engine_Ratio_Metrics_Aggregate;
  /** execute function "project_fields" which returns "fields" */
  project_fields: Array<Fields>;
  /** execute function "project_fields" and query aggregates on result of table type "fields" */
  project_fields_aggregate: Fields_Aggregate;
  /** An array relationship */
  project_hypertasks: Array<Project_Hypertasks>;
  /** An aggregate relationship */
  project_hypertasks_aggregate: Project_Hypertasks_Aggregate;
  /** fetch data from the table: "project_hypertasks" using primary key columns */
  project_hypertasks_by_pk?: Maybe<Project_Hypertasks>;
  /** fetch data from the table in a streaming manner : "project_hypertasks" */
  project_hypertasks_stream: Array<Project_Hypertasks>;
  /** fetch data from the table: "project_metrics" */
  project_metrics: Array<Project_Metrics>;
  /** fetch aggregated fields from the table: "project_metrics" */
  project_metrics_aggregate: Project_Metrics_Aggregate;
  /** fetch data from the table in a streaming manner : "project_metrics" */
  project_metrics_stream: Array<Project_Metrics>;
  /** fetch data from the table: "project_name" */
  project_name: Array<Project_Name>;
  /** fetch data from the table in a streaming manner : "project_name" */
  project_name_stream: Array<Project_Name>;
  /** fetch data from the table: "project_rabbit_test_instances" */
  project_rabbit_test_instances: Array<Project_Rabbit_Test_Instances>;
  /** fetch aggregated fields from the table: "project_rabbit_test_instances" */
  project_rabbit_test_instances_aggregate: Project_Rabbit_Test_Instances_Aggregate;
  /** fetch data from the table in a streaming manner : "project_rabbit_test_instances" */
  project_rabbit_test_instances_stream: Array<Project_Rabbit_Test_Instances>;
  /** execute function "project_recordings_clean" which returns "recordings" */
  project_recordings_clean: Array<Recordings>;
  /** execute function "project_recordings_clean" and query aggregates on result of table type "recordings" */
  project_recordings_clean_aggregate: Recordings_Aggregate;
  /** execute function "project_recordings_unreviewed" which returns "recordings" */
  project_recordings_unreviewed: Array<Recordings>;
  /** execute function "project_recordings_unreviewed" and query aggregates on result of table type "recordings" */
  project_recordings_unreviewed_aggregate: Recordings_Aggregate;
  /** execute function "project_task_count" which returns "schema.project_task_count" */
  project_task_count: Array<Schema_Project_Task_Count>;
  /** execute function "project_task_count" and query aggregates on result of table type "schema.project_task_count" */
  project_task_count_aggregate: Schema_Project_Task_Count_Aggregate;
  /** execute function "project_task_count_by_device" which returns "schema.project_task_count_by_device" */
  project_task_count_by_device: Array<Schema_Project_Task_Count_By_Device>;
  /** execute function "project_task_count_by_device" and query aggregates on result of table type "schema.project_task_count_by_device" */
  project_task_count_by_device_aggregate: Schema_Project_Task_Count_By_Device_Aggregate;
  /** An array relationship */
  project_tasks: Array<Project_Tasks>;
  /** An aggregate relationship */
  project_tasks_aggregate: Project_Tasks_Aggregate;
  /** fetch data from the table: "project_tasks" using primary key columns */
  project_tasks_by_pk?: Maybe<Project_Tasks>;
  /** fetch data from the table in a streaming manner : "project_tasks" */
  project_tasks_stream: Array<Project_Tasks>;
  /** fetch data from the table: "project_user" */
  project_user: Array<Project_User>;
  /** fetch aggregated fields from the table: "project_user" */
  project_user_aggregate: Project_User_Aggregate;
  /** fetch data from the table: "project_user" using primary key columns */
  project_user_by_pk?: Maybe<Project_User>;
  /** fetch data from the table in a streaming manner : "project_user" */
  project_user_stream: Array<Project_User>;
  /** An array relationship */
  projects: Array<Projects>;
  /** An aggregate relationship */
  projects_aggregate: Projects_Aggregate;
  /** fetch data from the table: "projects" using primary key columns */
  projects_by_pk?: Maybe<Projects>;
  /** fetch data from the table in a streaming manner : "projects" */
  projects_stream: Array<Projects>;
  /** fetch data from the table: "rabbit_test.plan_project" */
  rabbit_test_plan_project: Array<Rabbit_Test_Plan_Project>;
  /** fetch aggregated fields from the table: "rabbit_test.plan_project" */
  rabbit_test_plan_project_aggregate: Rabbit_Test_Plan_Project_Aggregate;
  /** fetch data from the table: "rabbit_test.plan_project" using primary key columns */
  rabbit_test_plan_project_by_pk?: Maybe<Rabbit_Test_Plan_Project>;
  /** fetch data from the table in a streaming manner : "rabbit_test.plan_project" */
  rabbit_test_plan_project_stream: Array<Rabbit_Test_Plan_Project>;
  /** fetch data from the table: "rabbit_test.plan_recording" */
  rabbit_test_plan_recording: Array<Rabbit_Test_Plan_Recording>;
  /** fetch aggregated fields from the table: "rabbit_test.plan_recording" */
  rabbit_test_plan_recording_aggregate: Rabbit_Test_Plan_Recording_Aggregate;
  /** fetch data from the table: "rabbit_test.plan_recording" using primary key columns */
  rabbit_test_plan_recording_by_pk?: Maybe<Rabbit_Test_Plan_Recording>;
  /** fetch data from the table in a streaming manner : "rabbit_test.plan_recording" */
  rabbit_test_plan_recording_stream: Array<Rabbit_Test_Plan_Recording>;
  /** fetch data from the table: "rabbit_test.plan_task" */
  rabbit_test_plan_task: Array<Rabbit_Test_Plan_Task>;
  /** fetch aggregated fields from the table: "rabbit_test.plan_task" */
  rabbit_test_plan_task_aggregate: Rabbit_Test_Plan_Task_Aggregate;
  /** fetch data from the table: "rabbit_test.plan_task" using primary key columns */
  rabbit_test_plan_task_by_pk?: Maybe<Rabbit_Test_Plan_Task>;
  /** fetch data from the table in a streaming manner : "rabbit_test.plan_task" */
  rabbit_test_plan_task_stream: Array<Rabbit_Test_Plan_Task>;
  /** fetch data from the table: "rabbit_test.plans" */
  rabbit_test_plans: Array<Rabbit_Test_Plans>;
  /** fetch aggregated fields from the table: "rabbit_test.plans" */
  rabbit_test_plans_aggregate: Rabbit_Test_Plans_Aggregate;
  /** fetch data from the table: "rabbit_test.plans" using primary key columns */
  rabbit_test_plans_by_pk?: Maybe<Rabbit_Test_Plans>;
  /** fetch data from the table in a streaming manner : "rabbit_test.plans" */
  rabbit_test_plans_stream: Array<Rabbit_Test_Plans>;
  /** fetch data from the table: "recording_apps" */
  recording_apps: Array<Recording_Apps>;
  /** fetch aggregated fields from the table: "recording_apps" */
  recording_apps_aggregate: Recording_Apps_Aggregate;
  /** fetch data from the table: "recording_apps" using primary key columns */
  recording_apps_by_pk?: Maybe<Recording_Apps>;
  /** fetch data from the table in a streaming manner : "recording_apps" */
  recording_apps_stream: Array<Recording_Apps>;
  /** fetch data from the table: "recording_audio_driver" */
  recording_audio_driver: Array<Recording_Audio_Driver>;
  /** fetch data from the table: "recording_audio_driver" using primary key columns */
  recording_audio_driver_by_pk?: Maybe<Recording_Audio_Driver>;
  /** fetch data from the table in a streaming manner : "recording_audio_driver" */
  recording_audio_driver_stream: Array<Recording_Audio_Driver>;
  /** fetch data from the table: "recording_data.ground_truth" */
  recording_data_ground_truth: Array<Recording_Data_Ground_Truth>;
  /** fetch aggregated fields from the table: "recording_data.ground_truth" */
  recording_data_ground_truth_aggregate: Recording_Data_Ground_Truth_Aggregate;
  /** fetch data from the table: "recording_data.ground_truth" using primary key columns */
  recording_data_ground_truth_by_pk?: Maybe<Recording_Data_Ground_Truth>;
  /** fetch data from the table in a streaming manner : "recording_data.ground_truth" */
  recording_data_ground_truth_stream: Array<Recording_Data_Ground_Truth>;
  /** fetch data from the table: "recording_data.labels" */
  recording_data_labels: Array<Recording_Data_Labels>;
  /** fetch aggregated fields from the table: "recording_data.labels" */
  recording_data_labels_aggregate: Recording_Data_Labels_Aggregate;
  /** fetch data from the table: "recording_data.labels" using primary key columns */
  recording_data_labels_by_pk?: Maybe<Recording_Data_Labels>;
  /** fetch data from the table in a streaming manner : "recording_data.labels" */
  recording_data_labels_stream: Array<Recording_Data_Labels>;
  /** fetch data from the table: "recording_data.mode_parameter" */
  recording_data_mode_parameter: Array<Recording_Data_Mode_Parameter>;
  /** fetch aggregated fields from the table: "recording_data.mode_parameter" */
  recording_data_mode_parameter_aggregate: Recording_Data_Mode_Parameter_Aggregate;
  /** fetch data from the table: "recording_data.mode_parameter" using primary key columns */
  recording_data_mode_parameter_by_pk?: Maybe<Recording_Data_Mode_Parameter>;
  /** fetch data from the table in a streaming manner : "recording_data.mode_parameter" */
  recording_data_mode_parameter_stream: Array<Recording_Data_Mode_Parameter>;
  /** fetch data from the table: "recording_data.modes" */
  recording_data_modes: Array<Recording_Data_Modes>;
  /** fetch aggregated fields from the table: "recording_data.modes" */
  recording_data_modes_aggregate: Recording_Data_Modes_Aggregate;
  /** fetch data from the table: "recording_data.modes" using primary key columns */
  recording_data_modes_by_pk?: Maybe<Recording_Data_Modes>;
  /** fetch data from the table in a streaming manner : "recording_data.modes" */
  recording_data_modes_stream: Array<Recording_Data_Modes>;
  /** fetch data from the table: "recording_data.parameters" */
  recording_data_parameters: Array<Recording_Data_Parameters>;
  /** fetch aggregated fields from the table: "recording_data.parameters" */
  recording_data_parameters_aggregate: Recording_Data_Parameters_Aggregate;
  /** fetch data from the table: "recording_data.parameters" using primary key columns */
  recording_data_parameters_by_pk?: Maybe<Recording_Data_Parameters>;
  /** fetch data from the table in a streaming manner : "recording_data.parameters" */
  recording_data_parameters_stream: Array<Recording_Data_Parameters>;
  /** fetch data from the table: "recording_data.payload_pair" */
  recording_data_payload_pair: Array<Recording_Data_Payload_Pair>;
  /** fetch data from the table: "recording_data.payload_pair" using primary key columns */
  recording_data_payload_pair_by_pk?: Maybe<Recording_Data_Payload_Pair>;
  /** fetch data from the table in a streaming manner : "recording_data.payload_pair" */
  recording_data_payload_pair_stream: Array<Recording_Data_Payload_Pair>;
  /** fetch data from the table: "recording_data.predictions" */
  recording_data_predictions: Array<Recording_Data_Predictions>;
  /** fetch aggregated fields from the table: "recording_data.predictions" */
  recording_data_predictions_aggregate: Recording_Data_Predictions_Aggregate;
  /** fetch data from the table: "recording_data.predictions" using primary key columns */
  recording_data_predictions_by_pk?: Maybe<Recording_Data_Predictions>;
  /** fetch data from the table in a streaming manner : "recording_data.predictions" */
  recording_data_predictions_stream: Array<Recording_Data_Predictions>;
  /** fetch data from the table: "recording_recording_tag" */
  recording_recording_tag: Array<Recording_Recording_Tag>;
  /** fetch data from the table: "recording_recording_tag" using primary key columns */
  recording_recording_tag_by_pk?: Maybe<Recording_Recording_Tag>;
  /** fetch data from the table in a streaming manner : "recording_recording_tag" */
  recording_recording_tag_stream: Array<Recording_Recording_Tag>;
  /** execute function "recording_tag_count_by_day" which returns "schema.recording_tag_count_by_day" */
  recording_tag_count_by_day: Array<Schema_Recording_Tag_Count_By_Day>;
  /** execute function "recording_tag_count_by_day" and query aggregates on result of table type "schema.recording_tag_count_by_day" */
  recording_tag_count_by_day_aggregate: Schema_Recording_Tag_Count_By_Day_Aggregate;
  /** fetch data from the table: "recording_tags" */
  recording_tags: Array<Recording_Tags>;
  /** fetch data from the table: "recording_tags" using primary key columns */
  recording_tags_by_pk?: Maybe<Recording_Tags>;
  /** fetch data from the table in a streaming manner : "recording_tags" */
  recording_tags_stream: Array<Recording_Tags>;
  /** An array relationship */
  recordings: Array<Recordings>;
  /** An aggregate relationship */
  recordings_aggregate: Recordings_Aggregate;
  /** fetch data from the table: "recordings" using primary key columns */
  recordings_by_pk?: Maybe<Recordings>;
  /** fetch data from the table in a streaming manner : "recordings" */
  recordings_stream: Array<Recordings>;
  /** fetch data from the table: "role_user" */
  role_user: Array<Role_User>;
  /** fetch aggregated fields from the table: "role_user" */
  role_user_aggregate: Role_User_Aggregate;
  /** fetch data from the table: "role_user" using primary key columns */
  role_user_by_pk?: Maybe<Role_User>;
  /** fetch data from the table in a streaming manner : "role_user" */
  role_user_stream: Array<Role_User>;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** fetch aggregated fields from the table: "roles" */
  roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  /** fetch data from the table in a streaming manner : "roles" */
  roles_stream: Array<Roles>;
  /** fetch data from the table: "rooms" */
  rooms: Array<Rooms>;
  /** fetch data from the table: "rooms" using primary key columns */
  rooms_by_pk?: Maybe<Rooms>;
  /** fetch data from the table in a streaming manner : "rooms" */
  rooms_stream: Array<Rooms>;
  /** fetch data from the table: "schema.project_engine_metrics" */
  schema_project_engine_metrics: Array<Schema_Project_Engine_Metrics>;
  /** fetch data from the table in a streaming manner : "schema.project_engine_metrics" */
  schema_project_engine_metrics_stream: Array<Schema_Project_Engine_Metrics>;
  /** fetch data from the table: "schema.project_engine_ratio_metrics" */
  schema_project_engine_ratio_metrics: Array<Schema_Project_Engine_Ratio_Metrics>;
  /** fetch aggregated fields from the table: "schema.project_engine_ratio_metrics" */
  schema_project_engine_ratio_metrics_aggregate: Schema_Project_Engine_Ratio_Metrics_Aggregate;
  /** fetch data from the table: "schema.project_engine_ratio_metrics" using primary key columns */
  schema_project_engine_ratio_metrics_by_pk?: Maybe<Schema_Project_Engine_Ratio_Metrics>;
  /** fetch data from the table in a streaming manner : "schema.project_engine_ratio_metrics" */
  schema_project_engine_ratio_metrics_stream: Array<Schema_Project_Engine_Ratio_Metrics>;
  /** fetch data from the table: "schema.project_task_count" */
  schema_project_task_count: Array<Schema_Project_Task_Count>;
  /** fetch aggregated fields from the table: "schema.project_task_count" */
  schema_project_task_count_aggregate: Schema_Project_Task_Count_Aggregate;
  /** fetch data from the table: "schema.project_task_count_by_device" */
  schema_project_task_count_by_device: Array<Schema_Project_Task_Count_By_Device>;
  /** fetch aggregated fields from the table: "schema.project_task_count_by_device" */
  schema_project_task_count_by_device_aggregate: Schema_Project_Task_Count_By_Device_Aggregate;
  /** fetch data from the table: "schema.project_task_count_by_device" using primary key columns */
  schema_project_task_count_by_device_by_pk?: Maybe<Schema_Project_Task_Count_By_Device>;
  /** fetch data from the table in a streaming manner : "schema.project_task_count_by_device" */
  schema_project_task_count_by_device_stream: Array<Schema_Project_Task_Count_By_Device>;
  /** fetch data from the table: "schema.project_task_count" using primary key columns */
  schema_project_task_count_by_pk?: Maybe<Schema_Project_Task_Count>;
  /** fetch data from the table in a streaming manner : "schema.project_task_count" */
  schema_project_task_count_stream: Array<Schema_Project_Task_Count>;
  /** fetch data from the table: "schema.recording_tag_count_by_day" */
  schema_recording_tag_count_by_day: Array<Schema_Recording_Tag_Count_By_Day>;
  /** fetch aggregated fields from the table: "schema.recording_tag_count_by_day" */
  schema_recording_tag_count_by_day_aggregate: Schema_Recording_Tag_Count_By_Day_Aggregate;
  /** fetch data from the table: "schema.recording_tag_count_by_day" using primary key columns */
  schema_recording_tag_count_by_day_by_pk?: Maybe<Schema_Recording_Tag_Count_By_Day>;
  /** fetch data from the table in a streaming manner : "schema.recording_tag_count_by_day" */
  schema_recording_tag_count_by_day_stream: Array<Schema_Recording_Tag_Count_By_Day>;
  /** fetch data from the table: "simulations.scores" */
  simulations_scores: Array<Simulations_Scores>;
  /** fetch data from the table: "simulations.scores" using primary key columns */
  simulations_scores_by_pk?: Maybe<Simulations_Scores>;
  /** fetch data from the table in a streaming manner : "simulations.scores" */
  simulations_scores_stream: Array<Simulations_Scores>;
  /** fetch data from the table: "task_field_option" */
  task_field_option: Array<Task_Field_Option>;
  /** fetch aggregated fields from the table: "task_field_option" */
  task_field_option_aggregate: Task_Field_Option_Aggregate;
  /** fetch data from the table: "task_field_option" using primary key columns */
  task_field_option_by_pk?: Maybe<Task_Field_Option>;
  /** fetch data from the table: "task_field_option_hashes" */
  task_field_option_hashes: Array<Task_Field_Option_Hashes>;
  /** fetch aggregated fields from the table: "task_field_option_hashes" */
  task_field_option_hashes_aggregate: Task_Field_Option_Hashes_Aggregate;
  /** fetch data from the table in a streaming manner : "task_field_option_hashes" */
  task_field_option_hashes_stream: Array<Task_Field_Option_Hashes>;
  /** fetch data from the table in a streaming manner : "task_field_option" */
  task_field_option_stream: Array<Task_Field_Option>;
  /** fetch data from the table: "task_groups.group_fields" */
  task_groups_group_fields: Array<Task_Groups_Group_Fields>;
  /** fetch aggregated fields from the table: "task_groups.group_fields" */
  task_groups_group_fields_aggregate: Task_Groups_Group_Fields_Aggregate;
  /** fetch data from the table: "task_groups.group_fields" using primary key columns */
  task_groups_group_fields_by_pk?: Maybe<Task_Groups_Group_Fields>;
  /** fetch data from the table in a streaming manner : "task_groups.group_fields" */
  task_groups_group_fields_stream: Array<Task_Groups_Group_Fields>;
  /** fetch data from the table: "task_groups.groups" */
  task_groups_groups: Array<Task_Groups_Groups>;
  /** fetch aggregated fields from the table: "task_groups.groups" */
  task_groups_groups_aggregate: Task_Groups_Groups_Aggregate;
  /** fetch data from the table: "task_groups.groups" using primary key columns */
  task_groups_groups_by_pk?: Maybe<Task_Groups_Groups>;
  /** fetch data from the table in a streaming manner : "task_groups.groups" */
  task_groups_groups_stream: Array<Task_Groups_Groups>;
  /** fetch data from the table: "task_groups.hierarchies" */
  task_groups_hierarchies: Array<Task_Groups_Hierarchies>;
  /** fetch aggregated fields from the table: "task_groups.hierarchies" */
  task_groups_hierarchies_aggregate: Task_Groups_Hierarchies_Aggregate;
  /** fetch data from the table: "task_groups.hierarchies" using primary key columns */
  task_groups_hierarchies_by_pk?: Maybe<Task_Groups_Hierarchies>;
  /** fetch data from the table in a streaming manner : "task_groups.hierarchies" */
  task_groups_hierarchies_stream: Array<Task_Groups_Hierarchies>;
  /** fetch data from the table: "task_groups.hierarchy_groups" */
  task_groups_hierarchy_groups: Array<Task_Groups_Hierarchy_Groups>;
  /** fetch aggregated fields from the table: "task_groups.hierarchy_groups" */
  task_groups_hierarchy_groups_aggregate: Task_Groups_Hierarchy_Groups_Aggregate;
  /** fetch data from the table: "task_groups.hierarchy_groups" using primary key columns */
  task_groups_hierarchy_groups_by_pk?: Maybe<Task_Groups_Hierarchy_Groups>;
  /** fetch data from the table in a streaming manner : "task_groups.hierarchy_groups" */
  task_groups_hierarchy_groups_stream: Array<Task_Groups_Hierarchy_Groups>;
  /** fetch data from the table: "task_instructions" */
  task_instructions: Array<Task_Instructions>;
  /** fetch data from the table: "task_instructions" using primary key columns */
  task_instructions_by_pk?: Maybe<Task_Instructions>;
  /** fetch data from the table in a streaming manner : "task_instructions" */
  task_instructions_stream: Array<Task_Instructions>;
  /** An array relationship */
  task_media: Array<Task_Media>;
  /** An aggregate relationship */
  task_media_aggregate: Task_Media_Aggregate;
  /** fetch data from the table: "task_media" using primary key columns */
  task_media_by_pk?: Maybe<Task_Media>;
  /** fetch data from the table in a streaming manner : "task_media" */
  task_media_stream: Array<Task_Media>;
  /** fetch data from the table: "task_statuses" */
  task_statuses: Array<Task_Statuses>;
  /** fetch data from the table: "task_statuses" using primary key columns */
  task_statuses_by_pk?: Maybe<Task_Statuses>;
  /** fetch data from the table in a streaming manner : "task_statuses" */
  task_statuses_stream: Array<Task_Statuses>;
  /** fetch data from the table: "task_translation" */
  task_translation: Array<Task_Translation>;
  /** fetch aggregated fields from the table: "task_translation" */
  task_translation_aggregate: Task_Translation_Aggregate;
  /** fetch data from the table: "task_translation" using primary key columns */
  task_translation_by_pk?: Maybe<Task_Translation>;
  /** fetch data from the table in a streaming manner : "task_translation" */
  task_translation_stream: Array<Task_Translation>;
  /** fetch data from the table: "tasks" */
  tasks: Array<Tasks>;
  /** fetch aggregated fields from the table: "tasks" */
  tasks_aggregate: Tasks_Aggregate;
  /** fetch data from the table: "tasks" using primary key columns */
  tasks_by_pk?: Maybe<Tasks>;
  /** fetch data from the table in a streaming manner : "tasks" */
  tasks_stream: Array<Tasks>;
  /** An array relationship */
  tool_versions: Array<Tool_Versions>;
  /** fetch data from the table: "tool_versions" using primary key columns */
  tool_versions_by_pk?: Maybe<Tool_Versions>;
  /** fetch data from the table in a streaming manner : "tool_versions" */
  tool_versions_stream: Array<Tool_Versions>;
  /** fetch data from the table: "tools" */
  tools: Array<Tools>;
  /** fetch data from the table: "tools" using primary key columns */
  tools_by_pk?: Maybe<Tools>;
  /** fetch data from the table in a streaming manner : "tools" */
  tools_stream: Array<Tools>;
  /** fetch data from the table: "types" */
  types: Array<Types>;
  /** fetch aggregated fields from the table: "types" */
  types_aggregate: Types_Aggregate;
  /** fetch data from the table: "types" using primary key columns */
  types_by_pk?: Maybe<Types>;
  /** fetch data from the table in a streaming manner : "types" */
  types_stream: Array<Types>;
  /** An array relationship */
  user_recordings_by_day_stats: Array<User_Recordings_By_Day_Stats>;
  /** An aggregate relationship */
  user_recordings_by_day_stats_aggregate: User_Recordings_By_Day_Stats_Aggregate;
  /** fetch data from the table in a streaming manner : "user_recordings_by_day_stats" */
  user_recordings_by_day_stats_stream: Array<User_Recordings_By_Day_Stats>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner : "users" */
  users_stream: Array<Users>;
  /** fetch data from the table: "xtensa_configurations" */
  xtensa_configurations: Array<Xtensa_Configurations>;
  /** fetch aggregated fields from the table: "xtensa_configurations" */
  xtensa_configurations_aggregate: Xtensa_Configurations_Aggregate;
  /** fetch data from the table: "xtensa_configurations" using primary key columns */
  xtensa_configurations_by_pk?: Maybe<Xtensa_Configurations>;
  /** fetch data from the table in a streaming manner : "xtensa_configurations" */
  xtensa_configurations_stream: Array<Xtensa_Configurations>;
};


export type Subscription_RootAudio_DriverArgs = {
  distinct_on?: Maybe<Array<Audio_Driver_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Driver_Order_By>>;
  where?: Maybe<Audio_Driver_Bool_Exp>;
};


export type Subscription_RootAudio_Driver_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAudio_Driver_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Audio_Driver_Stream_Cursor_Input>>;
  where?: Maybe<Audio_Driver_Bool_Exp>;
};


export type Subscription_RootAudio_Measurements_ComponentsArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Components_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Components_Order_By>>;
  where?: Maybe<Audio_Measurements_Components_Bool_Exp>;
};


export type Subscription_RootAudio_Measurements_Components_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAudio_Measurements_Components_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Audio_Measurements_Components_Stream_Cursor_Input>>;
  where?: Maybe<Audio_Measurements_Components_Bool_Exp>;
};


export type Subscription_RootAudio_Measurements_ConfigurationsArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Configurations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Configurations_Order_By>>;
  where?: Maybe<Audio_Measurements_Configurations_Bool_Exp>;
};


export type Subscription_RootAudio_Measurements_Configurations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAudio_Measurements_Configurations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Audio_Measurements_Configurations_Stream_Cursor_Input>>;
  where?: Maybe<Audio_Measurements_Configurations_Bool_Exp>;
};


export type Subscription_RootAudio_Measurements_Data_TypesArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Data_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Data_Types_Order_By>>;
  where?: Maybe<Audio_Measurements_Data_Types_Bool_Exp>;
};


export type Subscription_RootAudio_Measurements_Data_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Data_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Data_Types_Order_By>>;
  where?: Maybe<Audio_Measurements_Data_Types_Bool_Exp>;
};


export type Subscription_RootAudio_Measurements_Data_Types_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootAudio_Measurements_Data_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Audio_Measurements_Data_Types_Stream_Cursor_Input>>;
  where?: Maybe<Audio_Measurements_Data_Types_Bool_Exp>;
};


export type Subscription_RootAudio_Measurements_Master_FilesArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Master_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Master_Files_Order_By>>;
  where?: Maybe<Audio_Measurements_Master_Files_Bool_Exp>;
};


export type Subscription_RootAudio_Measurements_Master_Files_AggregateArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Master_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Master_Files_Order_By>>;
  where?: Maybe<Audio_Measurements_Master_Files_Bool_Exp>;
};


export type Subscription_RootAudio_Measurements_Master_Files_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAudio_Measurements_Master_Files_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Audio_Measurements_Master_Files_Stream_Cursor_Input>>;
  where?: Maybe<Audio_Measurements_Master_Files_Bool_Exp>;
};


export type Subscription_RootAudio_Measurements_OrientationsArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Orientations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Orientations_Order_By>>;
  where?: Maybe<Audio_Measurements_Orientations_Bool_Exp>;
};


export type Subscription_RootAudio_Measurements_Orientations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAudio_Measurements_Orientations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Audio_Measurements_Orientations_Stream_Cursor_Input>>;
  where?: Maybe<Audio_Measurements_Orientations_Bool_Exp>;
};


export type Subscription_RootAudio_Measurements_Payload_RecordingArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Payload_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Payload_Recording_Order_By>>;
  where?: Maybe<Audio_Measurements_Payload_Recording_Bool_Exp>;
};


export type Subscription_RootAudio_Measurements_Payload_Recording_AggregateArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Payload_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Payload_Recording_Order_By>>;
  where?: Maybe<Audio_Measurements_Payload_Recording_Bool_Exp>;
};


export type Subscription_RootAudio_Measurements_Payload_Recording_By_PkArgs = {
  payload_id: Scalars['uuid'];
  recording_id: Scalars['uuid'];
};


export type Subscription_RootAudio_Measurements_Payload_Recording_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Audio_Measurements_Payload_Recording_Stream_Cursor_Input>>;
  where?: Maybe<Audio_Measurements_Payload_Recording_Bool_Exp>;
};


export type Subscription_RootAudio_Measurements_PlacementsArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Placements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Placements_Order_By>>;
  where?: Maybe<Audio_Measurements_Placements_Bool_Exp>;
};


export type Subscription_RootAudio_Measurements_Placements_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAudio_Measurements_Placements_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Audio_Measurements_Placements_Stream_Cursor_Input>>;
  where?: Maybe<Audio_Measurements_Placements_Bool_Exp>;
};


export type Subscription_RootAudio_Measurements_Project_TasksArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Project_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Project_Tasks_Order_By>>;
  where?: Maybe<Audio_Measurements_Project_Tasks_Bool_Exp>;
};


export type Subscription_RootAudio_Measurements_Project_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Project_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Project_Tasks_Order_By>>;
  where?: Maybe<Audio_Measurements_Project_Tasks_Bool_Exp>;
};


export type Subscription_RootAudio_Measurements_Project_Tasks_By_PkArgs = {
  project_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};


export type Subscription_RootAudio_Measurements_Project_Tasks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Audio_Measurements_Project_Tasks_Stream_Cursor_Input>>;
  where?: Maybe<Audio_Measurements_Project_Tasks_Bool_Exp>;
};


export type Subscription_RootAudio_Measurements_RecordingsArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Recordings_Order_By>>;
  where?: Maybe<Audio_Measurements_Recordings_Bool_Exp>;
};


export type Subscription_RootAudio_Measurements_Recordings_AggregateArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Recordings_Order_By>>;
  where?: Maybe<Audio_Measurements_Recordings_Bool_Exp>;
};


export type Subscription_RootAudio_Measurements_Recordings_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAudio_Measurements_Recordings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Audio_Measurements_Recordings_Stream_Cursor_Input>>;
  where?: Maybe<Audio_Measurements_Recordings_Bool_Exp>;
};


export type Subscription_RootAudio_Measurements_StagesArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Stages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Stages_Order_By>>;
  where?: Maybe<Audio_Measurements_Stages_Bool_Exp>;
};


export type Subscription_RootAudio_Measurements_Stages_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAudio_Measurements_Stages_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Audio_Measurements_Stages_Stream_Cursor_Input>>;
  where?: Maybe<Audio_Measurements_Stages_Bool_Exp>;
};


export type Subscription_RootBringup_Checklist_DeliverableArgs = {
  distinct_on?: Maybe<Array<Bringup_Checklist_Deliverable_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Checklist_Deliverable_Order_By>>;
  where?: Maybe<Bringup_Checklist_Deliverable_Bool_Exp>;
};


export type Subscription_RootBringup_Checklist_Deliverable_AggregateArgs = {
  distinct_on?: Maybe<Array<Bringup_Checklist_Deliverable_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Checklist_Deliverable_Order_By>>;
  where?: Maybe<Bringup_Checklist_Deliverable_Bool_Exp>;
};


export type Subscription_RootBringup_Checklist_Deliverable_By_PkArgs = {
  checklist_id: Scalars['uuid'];
  deliverable_id: Scalars['uuid'];
};


export type Subscription_RootBringup_Checklist_Deliverable_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Bringup_Checklist_Deliverable_Stream_Cursor_Input>>;
  where?: Maybe<Bringup_Checklist_Deliverable_Bool_Exp>;
};


export type Subscription_RootBringup_ChecklistsArgs = {
  distinct_on?: Maybe<Array<Bringup_Checklists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Checklists_Order_By>>;
  where?: Maybe<Bringup_Checklists_Bool_Exp>;
};


export type Subscription_RootBringup_Checklists_AggregateArgs = {
  distinct_on?: Maybe<Array<Bringup_Checklists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Checklists_Order_By>>;
  where?: Maybe<Bringup_Checklists_Bool_Exp>;
};


export type Subscription_RootBringup_Checklists_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBringup_Checklists_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Bringup_Checklists_Stream_Cursor_Input>>;
  where?: Maybe<Bringup_Checklists_Bool_Exp>;
};


export type Subscription_RootBringup_EndorsementsArgs = {
  distinct_on?: Maybe<Array<Bringup_Endorsements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Endorsements_Order_By>>;
  where?: Maybe<Bringup_Endorsements_Bool_Exp>;
};


export type Subscription_RootBringup_Endorsements_AggregateArgs = {
  distinct_on?: Maybe<Array<Bringup_Endorsements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Endorsements_Order_By>>;
  where?: Maybe<Bringup_Endorsements_Bool_Exp>;
};


export type Subscription_RootBringup_Endorsements_By_PkArgs = {
  item_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};


export type Subscription_RootBringup_Endorsements_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Bringup_Endorsements_Stream_Cursor_Input>>;
  where?: Maybe<Bringup_Endorsements_Bool_Exp>;
};


export type Subscription_RootBringup_ItemsArgs = {
  distinct_on?: Maybe<Array<Bringup_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Items_Order_By>>;
  where?: Maybe<Bringup_Items_Bool_Exp>;
};


export type Subscription_RootBringup_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Bringup_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Items_Order_By>>;
  where?: Maybe<Bringup_Items_Bool_Exp>;
};


export type Subscription_RootBringup_Items_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBringup_Items_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Bringup_Items_Stream_Cursor_Input>>;
  where?: Maybe<Bringup_Items_Bool_Exp>;
};


export type Subscription_RootBringup_MilestonesArgs = {
  distinct_on?: Maybe<Array<Bringup_Milestones_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Milestones_Order_By>>;
  where?: Maybe<Bringup_Milestones_Bool_Exp>;
};


export type Subscription_RootBringup_Milestones_AggregateArgs = {
  distinct_on?: Maybe<Array<Bringup_Milestones_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Milestones_Order_By>>;
  where?: Maybe<Bringup_Milestones_Bool_Exp>;
};


export type Subscription_RootBringup_Milestones_By_PkArgs = {
  item_id: Scalars['uuid'];
};


export type Subscription_RootBringup_Milestones_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Bringup_Milestones_Stream_Cursor_Input>>;
  where?: Maybe<Bringup_Milestones_Bool_Exp>;
};


export type Subscription_RootBringup_Project_Item_CommentsArgs = {
  distinct_on?: Maybe<Array<Bringup_Project_Item_Comments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Project_Item_Comments_Order_By>>;
  where?: Maybe<Bringup_Project_Item_Comments_Bool_Exp>;
};


export type Subscription_RootBringup_Project_Item_Comments_AggregateArgs = {
  distinct_on?: Maybe<Array<Bringup_Project_Item_Comments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bringup_Project_Item_Comments_Order_By>>;
  where?: Maybe<Bringup_Project_Item_Comments_Bool_Exp>;
};


export type Subscription_RootBringup_Project_Item_Comments_By_PkArgs = {
  created_at: Scalars['timestamptz'];
  item_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};


export type Subscription_RootBringup_Project_Item_Comments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Bringup_Project_Item_Comments_Stream_Cursor_Input>>;
  where?: Maybe<Bringup_Project_Item_Comments_Bool_Exp>;
};


export type Subscription_RootCategoriesArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Categories_Order_By>>;
  where?: Maybe<Categories_Bool_Exp>;
};


export type Subscription_RootCategories_AggregateArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Categories_Order_By>>;
  where?: Maybe<Categories_Bool_Exp>;
};


export type Subscription_RootCategories_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCategories_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Categories_Stream_Cursor_Input>>;
  where?: Maybe<Categories_Bool_Exp>;
};


export type Subscription_RootContext_Display_FieldArgs = {
  distinct_on?: Maybe<Array<Context_Display_Field_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Display_Field_Order_By>>;
  where?: Maybe<Context_Display_Field_Bool_Exp>;
};


export type Subscription_RootContext_Display_Field_AggregateArgs = {
  distinct_on?: Maybe<Array<Context_Display_Field_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Display_Field_Order_By>>;
  where?: Maybe<Context_Display_Field_Bool_Exp>;
};


export type Subscription_RootContext_Display_Field_By_PkArgs = {
  context_display_id: Scalars['uuid'];
  field_id: Scalars['uuid'];
};


export type Subscription_RootContext_Display_Field_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Context_Display_Field_Stream_Cursor_Input>>;
  where?: Maybe<Context_Display_Field_Bool_Exp>;
};


export type Subscription_RootContext_DisplaysArgs = {
  distinct_on?: Maybe<Array<Context_Displays_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Displays_Order_By>>;
  where?: Maybe<Context_Displays_Bool_Exp>;
};


export type Subscription_RootContext_Displays_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootContext_Displays_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Context_Displays_Stream_Cursor_Input>>;
  where?: Maybe<Context_Displays_Bool_Exp>;
};


export type Subscription_RootContextsArgs = {
  distinct_on?: Maybe<Array<Contexts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contexts_Order_By>>;
  where?: Maybe<Contexts_Bool_Exp>;
};


export type Subscription_RootContexts_AggregateArgs = {
  distinct_on?: Maybe<Array<Contexts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contexts_Order_By>>;
  where?: Maybe<Contexts_Bool_Exp>;
};


export type Subscription_RootContexts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootContexts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Contexts_Stream_Cursor_Input>>;
  where?: Maybe<Contexts_Bool_Exp>;
};


export type Subscription_RootCustomersArgs = {
  distinct_on?: Maybe<Array<Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customers_Order_By>>;
  where?: Maybe<Customers_Bool_Exp>;
};


export type Subscription_RootCustomers_AggregateArgs = {
  distinct_on?: Maybe<Array<Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customers_Order_By>>;
  where?: Maybe<Customers_Bool_Exp>;
};


export type Subscription_RootCustomers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCustomers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Customers_Stream_Cursor_Input>>;
  where?: Maybe<Customers_Bool_Exp>;
};


export type Subscription_RootDeliverablesArgs = {
  distinct_on?: Maybe<Array<Deliverables_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliverables_Order_By>>;
  where?: Maybe<Deliverables_Bool_Exp>;
};


export type Subscription_RootDeliverables_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliverables_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliverables_Order_By>>;
  where?: Maybe<Deliverables_Bool_Exp>;
};


export type Subscription_RootDeliverables_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDeliverables_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Deliverables_Stream_Cursor_Input>>;
  where?: Maybe<Deliverables_Bool_Exp>;
};


export type Subscription_RootDeliveries_ArchitecturesArgs = {
  distinct_on?: Maybe<Array<Deliveries_Architectures_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Architectures_Order_By>>;
  where?: Maybe<Deliveries_Architectures_Bool_Exp>;
};


export type Subscription_RootDeliveries_Architectures_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDeliveries_Architectures_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Deliveries_Architectures_Stream_Cursor_Input>>;
  where?: Maybe<Deliveries_Architectures_Bool_Exp>;
};


export type Subscription_RootDeliveries_Artifact_Microsoft_Whql_SubmissionArgs = {
  distinct_on?: Maybe<Array<Deliveries_Artifact_Microsoft_Whql_Submission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Artifact_Microsoft_Whql_Submission_Order_By>>;
  where?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission_Bool_Exp>;
};


export type Subscription_RootDeliveries_Artifact_Microsoft_Whql_Submission_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Artifact_Microsoft_Whql_Submission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Artifact_Microsoft_Whql_Submission_Order_By>>;
  where?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission_Bool_Exp>;
};


export type Subscription_RootDeliveries_Artifact_Microsoft_Whql_Submission_By_PkArgs = {
  artifact_id: Scalars['uuid'];
  microsoft_whql_submission_id: Scalars['uuid'];
};


export type Subscription_RootDeliveries_Artifact_Microsoft_Whql_Submission_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Deliveries_Artifact_Microsoft_Whql_Submission_Stream_Cursor_Input>>;
  where?: Maybe<Deliveries_Artifact_Microsoft_Whql_Submission_Bool_Exp>;
};


export type Subscription_RootDeliveries_Artifact_Operating_SystemArgs = {
  distinct_on?: Maybe<Array<Deliveries_Artifact_Operating_System_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Artifact_Operating_System_Order_By>>;
  where?: Maybe<Deliveries_Artifact_Operating_System_Bool_Exp>;
};


export type Subscription_RootDeliveries_Artifact_Operating_System_By_PkArgs = {
  artifact_id: Scalars['uuid'];
  os_id: Scalars['uuid'];
};


export type Subscription_RootDeliveries_Artifact_Operating_System_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Deliveries_Artifact_Operating_System_Stream_Cursor_Input>>;
  where?: Maybe<Deliveries_Artifact_Operating_System_Bool_Exp>;
};


export type Subscription_RootDeliveries_ArtifactsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Artifacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Artifacts_Order_By>>;
  where?: Maybe<Deliveries_Artifacts_Bool_Exp>;
};


export type Subscription_RootDeliveries_Artifacts_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Artifacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Artifacts_Order_By>>;
  where?: Maybe<Deliveries_Artifacts_Bool_Exp>;
};


export type Subscription_RootDeliveries_Artifacts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDeliveries_Artifacts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Deliveries_Artifacts_Stream_Cursor_Input>>;
  where?: Maybe<Deliveries_Artifacts_Bool_Exp>;
};


export type Subscription_RootDeliveries_Device_FamiliesArgs = {
  distinct_on?: Maybe<Array<Deliveries_Device_Families_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Device_Families_Order_By>>;
  where?: Maybe<Deliveries_Device_Families_Bool_Exp>;
};


export type Subscription_RootDeliveries_Device_Families_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDeliveries_Device_Families_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Deliveries_Device_Families_Stream_Cursor_Input>>;
  where?: Maybe<Deliveries_Device_Families_Bool_Exp>;
};


export type Subscription_RootDeliveries_Driver_PackageArgs = {
  distinct_on?: Maybe<Array<Deliveries_Driver_Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Driver_Package_Order_By>>;
  where?: Maybe<Deliveries_Driver_Package_Bool_Exp>;
};


export type Subscription_RootDeliveries_Driver_Package_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Driver_Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Driver_Package_Order_By>>;
  where?: Maybe<Deliveries_Driver_Package_Bool_Exp>;
};


export type Subscription_RootDeliveries_Driver_Package_By_PkArgs = {
  driver_id: Scalars['uuid'];
  package_id: Scalars['uuid'];
};


export type Subscription_RootDeliveries_Driver_Package_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Deliveries_Driver_Package_Stream_Cursor_Input>>;
  where?: Maybe<Deliveries_Driver_Package_Bool_Exp>;
};


export type Subscription_RootDeliveries_Driver_Version_PackageArgs = {
  distinct_on?: Maybe<Array<Deliveries_Driver_Version_Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Driver_Version_Package_Order_By>>;
  where?: Maybe<Deliveries_Driver_Version_Package_Bool_Exp>;
};


export type Subscription_RootDeliveries_Driver_Version_Package_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Driver_Version_Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Driver_Version_Package_Order_By>>;
  where?: Maybe<Deliveries_Driver_Version_Package_Bool_Exp>;
};


export type Subscription_RootDeliveries_Driver_Version_Package_By_PkArgs = {
  driver_version_id: Scalars['uuid'];
  package_id: Scalars['uuid'];
};


export type Subscription_RootDeliveries_Driver_Version_Package_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Deliveries_Driver_Version_Package_Stream_Cursor_Input>>;
  where?: Maybe<Deliveries_Driver_Version_Package_Bool_Exp>;
};


export type Subscription_RootDeliveries_Driver_VersionsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Driver_Versions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Driver_Versions_Order_By>>;
  where?: Maybe<Deliveries_Driver_Versions_Bool_Exp>;
};


export type Subscription_RootDeliveries_Driver_Versions_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Driver_Versions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Driver_Versions_Order_By>>;
  where?: Maybe<Deliveries_Driver_Versions_Bool_Exp>;
};


export type Subscription_RootDeliveries_Driver_Versions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDeliveries_Driver_Versions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Deliveries_Driver_Versions_Stream_Cursor_Input>>;
  where?: Maybe<Deliveries_Driver_Versions_Bool_Exp>;
};


export type Subscription_RootDeliveries_DriversArgs = {
  distinct_on?: Maybe<Array<Deliveries_Drivers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Drivers_Order_By>>;
  where?: Maybe<Deliveries_Drivers_Bool_Exp>;
};


export type Subscription_RootDeliveries_Drivers_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Drivers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Drivers_Order_By>>;
  where?: Maybe<Deliveries_Drivers_Bool_Exp>;
};


export type Subscription_RootDeliveries_Drivers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDeliveries_Drivers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Deliveries_Drivers_Stream_Cursor_Input>>;
  where?: Maybe<Deliveries_Drivers_Bool_Exp>;
};


export type Subscription_RootDeliveries_Microsoft_Hlk_ArtifactsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Microsoft_Hlk_Artifacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Microsoft_Hlk_Artifacts_Order_By>>;
  where?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_Bool_Exp>;
};


export type Subscription_RootDeliveries_Microsoft_Hlk_Artifacts_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Microsoft_Hlk_Artifacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Microsoft_Hlk_Artifacts_Order_By>>;
  where?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_Bool_Exp>;
};


export type Subscription_RootDeliveries_Microsoft_Hlk_Artifacts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDeliveries_Microsoft_Hlk_Artifacts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Deliveries_Microsoft_Hlk_Artifacts_Stream_Cursor_Input>>;
  where?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_Bool_Exp>;
};


export type Subscription_RootDeliveries_Microsoft_Shipping_Label_ReviewsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Microsoft_Shipping_Label_Reviews_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Microsoft_Shipping_Label_Reviews_Order_By>>;
  where?: Maybe<Deliveries_Microsoft_Shipping_Label_Reviews_Bool_Exp>;
};


export type Subscription_RootDeliveries_Microsoft_Shipping_Label_Reviews_By_PkArgs = {
  microsoft_shipping_label_id: Scalars['uuid'];
};


export type Subscription_RootDeliveries_Microsoft_Shipping_Label_Reviews_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Deliveries_Microsoft_Shipping_Label_Reviews_Stream_Cursor_Input>>;
  where?: Maybe<Deliveries_Microsoft_Shipping_Label_Reviews_Bool_Exp>;
};


export type Subscription_RootDeliveries_Microsoft_Shipping_LabelsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Microsoft_Shipping_Labels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Microsoft_Shipping_Labels_Order_By>>;
  where?: Maybe<Deliveries_Microsoft_Shipping_Labels_Bool_Exp>;
};


export type Subscription_RootDeliveries_Microsoft_Shipping_Labels_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Microsoft_Shipping_Labels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Microsoft_Shipping_Labels_Order_By>>;
  where?: Maybe<Deliveries_Microsoft_Shipping_Labels_Bool_Exp>;
};


export type Subscription_RootDeliveries_Microsoft_Shipping_Labels_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDeliveries_Microsoft_Shipping_Labels_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Deliveries_Microsoft_Shipping_Labels_Stream_Cursor_Input>>;
  where?: Maybe<Deliveries_Microsoft_Shipping_Labels_Bool_Exp>;
};


export type Subscription_RootDeliveries_Microsoft_Whql_SubmissionsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Microsoft_Whql_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Microsoft_Whql_Submissions_Order_By>>;
  where?: Maybe<Deliveries_Microsoft_Whql_Submissions_Bool_Exp>;
};


export type Subscription_RootDeliveries_Microsoft_Whql_Submissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Microsoft_Whql_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Microsoft_Whql_Submissions_Order_By>>;
  where?: Maybe<Deliveries_Microsoft_Whql_Submissions_Bool_Exp>;
};


export type Subscription_RootDeliveries_Microsoft_Whql_Submissions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDeliveries_Microsoft_Whql_Submissions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Deliveries_Microsoft_Whql_Submissions_Stream_Cursor_Input>>;
  where?: Maybe<Deliveries_Microsoft_Whql_Submissions_Bool_Exp>;
};


export type Subscription_RootDeliveries_Operating_SystemsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Operating_Systems_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Operating_Systems_Order_By>>;
  where?: Maybe<Deliveries_Operating_Systems_Bool_Exp>;
};


export type Subscription_RootDeliveries_Operating_Systems_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDeliveries_Operating_Systems_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Deliveries_Operating_Systems_Stream_Cursor_Input>>;
  where?: Maybe<Deliveries_Operating_Systems_Bool_Exp>;
};


export type Subscription_RootDeliveries_Package_Operating_SystemsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Package_Operating_Systems_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Package_Operating_Systems_Order_By>>;
  where?: Maybe<Deliveries_Package_Operating_Systems_Bool_Exp>;
};


export type Subscription_RootDeliveries_Package_Operating_Systems_By_PkArgs = {
  os_id: Scalars['uuid'];
  package_id: Scalars['uuid'];
};


export type Subscription_RootDeliveries_Package_Operating_Systems_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Deliveries_Package_Operating_Systems_Stream_Cursor_Input>>;
  where?: Maybe<Deliveries_Package_Operating_Systems_Bool_Exp>;
};


export type Subscription_RootDeliveries_Package_TargetArgs = {
  distinct_on?: Maybe<Array<Deliveries_Package_Target_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Package_Target_Order_By>>;
  where?: Maybe<Deliveries_Package_Target_Bool_Exp>;
};


export type Subscription_RootDeliveries_Package_Target_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Package_Target_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Package_Target_Order_By>>;
  where?: Maybe<Deliveries_Package_Target_Bool_Exp>;
};


export type Subscription_RootDeliveries_Package_Target_By_PkArgs = {
  package_id: Scalars['uuid'];
  target_id: Scalars['uuid'];
};


export type Subscription_RootDeliveries_Package_Target_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Deliveries_Package_Target_Stream_Cursor_Input>>;
  where?: Maybe<Deliveries_Package_Target_Bool_Exp>;
};


export type Subscription_RootDeliveries_PackagesArgs = {
  distinct_on?: Maybe<Array<Deliveries_Packages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Packages_Order_By>>;
  where?: Maybe<Deliveries_Packages_Bool_Exp>;
};


export type Subscription_RootDeliveries_Packages_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Packages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Packages_Order_By>>;
  where?: Maybe<Deliveries_Packages_Bool_Exp>;
};


export type Subscription_RootDeliveries_Packages_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDeliveries_Packages_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Deliveries_Packages_Stream_Cursor_Input>>;
  where?: Maybe<Deliveries_Packages_Bool_Exp>;
};


export type Subscription_RootDeliveries_PartnersArgs = {
  distinct_on?: Maybe<Array<Deliveries_Partners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Partners_Order_By>>;
  where?: Maybe<Deliveries_Partners_Bool_Exp>;
};


export type Subscription_RootDeliveries_Partners_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDeliveries_Partners_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Deliveries_Partners_Stream_Cursor_Input>>;
  where?: Maybe<Deliveries_Partners_Bool_Exp>;
};


export type Subscription_RootDeliveries_PlatformsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Platforms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Platforms_Order_By>>;
  where?: Maybe<Deliveries_Platforms_Bool_Exp>;
};


export type Subscription_RootDeliveries_Platforms_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDeliveries_Platforms_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Deliveries_Platforms_Stream_Cursor_Input>>;
  where?: Maybe<Deliveries_Platforms_Bool_Exp>;
};


export type Subscription_RootDeliveries_Project_ConfigsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Project_Configs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Project_Configs_Order_By>>;
  where?: Maybe<Deliveries_Project_Configs_Bool_Exp>;
};


export type Subscription_RootDeliveries_Project_Configs_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Project_Configs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Project_Configs_Order_By>>;
  where?: Maybe<Deliveries_Project_Configs_Bool_Exp>;
};


export type Subscription_RootDeliveries_Project_Configs_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDeliveries_Project_Configs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Deliveries_Project_Configs_Stream_Cursor_Input>>;
  where?: Maybe<Deliveries_Project_Configs_Bool_Exp>;
};


export type Subscription_RootDeliveries_Target_PartnerArgs = {
  distinct_on?: Maybe<Array<Deliveries_Target_Partner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Target_Partner_Order_By>>;
  where?: Maybe<Deliveries_Target_Partner_Bool_Exp>;
};


export type Subscription_RootDeliveries_Target_Partner_By_PkArgs = {
  partner_id: Scalars['uuid'];
  target_id: Scalars['uuid'];
};


export type Subscription_RootDeliveries_Target_Partner_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Deliveries_Target_Partner_Stream_Cursor_Input>>;
  where?: Maybe<Deliveries_Target_Partner_Bool_Exp>;
};


export type Subscription_RootDeliveries_TargetsArgs = {
  distinct_on?: Maybe<Array<Deliveries_Targets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Targets_Order_By>>;
  where?: Maybe<Deliveries_Targets_Bool_Exp>;
};


export type Subscription_RootDeliveries_Targets_AggregateArgs = {
  distinct_on?: Maybe<Array<Deliveries_Targets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Targets_Order_By>>;
  where?: Maybe<Deliveries_Targets_Bool_Exp>;
};


export type Subscription_RootDeliveries_Targets_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDeliveries_Targets_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Deliveries_Targets_Stream_Cursor_Input>>;
  where?: Maybe<Deliveries_Targets_Bool_Exp>;
};


export type Subscription_RootDevice_FirmwareArgs = {
  distinct_on?: Maybe<Array<Device_Firmware_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Device_Firmware_Order_By>>;
  where?: Maybe<Device_Firmware_Bool_Exp>;
};


export type Subscription_RootDevice_Firmware_By_PkArgs = {
  device_id: Scalars['uuid'];
  firmware_id: Scalars['uuid'];
};


export type Subscription_RootDevice_Firmware_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Device_Firmware_Stream_Cursor_Input>>;
  where?: Maybe<Device_Firmware_Bool_Exp>;
};


export type Subscription_RootDevice_ProjectArgs = {
  distinct_on?: Maybe<Array<Device_Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Device_Project_Order_By>>;
  where?: Maybe<Device_Project_Bool_Exp>;
};


export type Subscription_RootDevice_Project_AggregateArgs = {
  distinct_on?: Maybe<Array<Device_Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Device_Project_Order_By>>;
  where?: Maybe<Device_Project_Bool_Exp>;
};


export type Subscription_RootDevice_Project_By_PkArgs = {
  device_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};


export type Subscription_RootDevice_Project_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Device_Project_Stream_Cursor_Input>>;
  where?: Maybe<Device_Project_Bool_Exp>;
};


export type Subscription_RootDevicesArgs = {
  distinct_on?: Maybe<Array<Devices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Devices_Order_By>>;
  where?: Maybe<Devices_Bool_Exp>;
};


export type Subscription_RootDevices_AggregateArgs = {
  distinct_on?: Maybe<Array<Devices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Devices_Order_By>>;
  where?: Maybe<Devices_Bool_Exp>;
};


export type Subscription_RootDevices_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDevices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Devices_Stream_Cursor_Input>>;
  where?: Maybe<Devices_Bool_Exp>;
};


export type Subscription_RootDynamic_ScalingArgs = {
  distinct_on?: Maybe<Array<Dynamic_Scaling_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Scaling_Order_By>>;
  where?: Maybe<Dynamic_Scaling_Bool_Exp>;
};


export type Subscription_RootDynamic_Scaling_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDynamic_Scaling_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Dynamic_Scaling_Stream_Cursor_Input>>;
  where?: Maybe<Dynamic_Scaling_Bool_Exp>;
};


export type Subscription_RootEngine_OpmodeArgs = {
  distinct_on?: Maybe<Array<Engine_Opmode_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Engine_Opmode_Order_By>>;
  where?: Maybe<Engine_Opmode_Bool_Exp>;
};


export type Subscription_RootEngine_Opmode_AggregateArgs = {
  distinct_on?: Maybe<Array<Engine_Opmode_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Engine_Opmode_Order_By>>;
  where?: Maybe<Engine_Opmode_Bool_Exp>;
};


export type Subscription_RootEngine_Opmode_By_PkArgs = {
  engine_id: Scalars['uuid'];
  opmode_id: Scalars['uuid'];
};


export type Subscription_RootEngine_Opmode_RecordingArgs = {
  distinct_on?: Maybe<Array<Engine_Opmode_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Engine_Opmode_Recording_Order_By>>;
  where?: Maybe<Engine_Opmode_Recording_Bool_Exp>;
};


export type Subscription_RootEngine_Opmode_Recording_AggregateArgs = {
  distinct_on?: Maybe<Array<Engine_Opmode_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Engine_Opmode_Recording_Order_By>>;
  where?: Maybe<Engine_Opmode_Recording_Bool_Exp>;
};


export type Subscription_RootEngine_Opmode_Recording_By_PkArgs = {
  engine_id: Scalars['uuid'];
  opmode_id: Scalars['uuid'];
  recording_id: Scalars['uuid'];
};


export type Subscription_RootEngine_Opmode_Recording_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Engine_Opmode_Recording_Stream_Cursor_Input>>;
  where?: Maybe<Engine_Opmode_Recording_Bool_Exp>;
};


export type Subscription_RootEngine_Opmode_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Engine_Opmode_Stream_Cursor_Input>>;
  where?: Maybe<Engine_Opmode_Bool_Exp>;
};


export type Subscription_RootEngine_TypesArgs = {
  distinct_on?: Maybe<Array<Engine_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Engine_Types_Order_By>>;
  where?: Maybe<Engine_Types_Bool_Exp>;
};


export type Subscription_RootEngine_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Engine_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Engine_Types_Order_By>>;
  where?: Maybe<Engine_Types_Bool_Exp>;
};


export type Subscription_RootEngine_Types_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootEngine_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Engine_Types_Stream_Cursor_Input>>;
  where?: Maybe<Engine_Types_Bool_Exp>;
};


export type Subscription_RootEnginesArgs = {
  distinct_on?: Maybe<Array<Engines_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Engines_Order_By>>;
  where?: Maybe<Engines_Bool_Exp>;
};


export type Subscription_RootEngines_AggregateArgs = {
  distinct_on?: Maybe<Array<Engines_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Engines_Order_By>>;
  where?: Maybe<Engines_Bool_Exp>;
};


export type Subscription_RootEngines_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootEngines_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Engines_Stream_Cursor_Input>>;
  where?: Maybe<Engines_Bool_Exp>;
};


export type Subscription_RootField_Option_MediaArgs = {
  distinct_on?: Maybe<Array<Field_Option_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Field_Option_Media_Order_By>>;
  where?: Maybe<Field_Option_Media_Bool_Exp>;
};


export type Subscription_RootField_Option_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Field_Option_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Field_Option_Media_Order_By>>;
  where?: Maybe<Field_Option_Media_Bool_Exp>;
};


export type Subscription_RootField_Option_Media_By_PkArgs = {
  field_option_id: Scalars['uuid'];
  media_id: Scalars['uuid'];
};


export type Subscription_RootField_Option_Media_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Field_Option_Media_Stream_Cursor_Input>>;
  where?: Maybe<Field_Option_Media_Bool_Exp>;
};


export type Subscription_RootField_OptionsArgs = {
  distinct_on?: Maybe<Array<Field_Options_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Field_Options_Order_By>>;
  where?: Maybe<Field_Options_Bool_Exp>;
};


export type Subscription_RootField_Options_AggregateArgs = {
  distinct_on?: Maybe<Array<Field_Options_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Field_Options_Order_By>>;
  where?: Maybe<Field_Options_Bool_Exp>;
};


export type Subscription_RootField_Options_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootField_Options_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Field_Options_Stream_Cursor_Input>>;
  where?: Maybe<Field_Options_Bool_Exp>;
};


export type Subscription_RootField_TranslationArgs = {
  distinct_on?: Maybe<Array<Field_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Field_Translation_Order_By>>;
  where?: Maybe<Field_Translation_Bool_Exp>;
};


export type Subscription_RootField_Translation_AggregateArgs = {
  distinct_on?: Maybe<Array<Field_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Field_Translation_Order_By>>;
  where?: Maybe<Field_Translation_Bool_Exp>;
};


export type Subscription_RootField_Translation_By_PkArgs = {
  field_id: Scalars['uuid'];
  language: Scalars['String'];
};


export type Subscription_RootField_Translation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Field_Translation_Stream_Cursor_Input>>;
  where?: Maybe<Field_Translation_Bool_Exp>;
};


export type Subscription_RootFieldsArgs = {
  distinct_on?: Maybe<Array<Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fields_Order_By>>;
  where?: Maybe<Fields_Bool_Exp>;
};


export type Subscription_RootFields_AggregateArgs = {
  distinct_on?: Maybe<Array<Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fields_Order_By>>;
  where?: Maybe<Fields_Bool_Exp>;
};


export type Subscription_RootFields_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFields_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Fields_Stream_Cursor_Input>>;
  where?: Maybe<Fields_Bool_Exp>;
};


export type Subscription_RootFirmwareArgs = {
  distinct_on?: Maybe<Array<Firmware_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Firmware_Order_By>>;
  where?: Maybe<Firmware_Bool_Exp>;
};


export type Subscription_RootFirmware_AggregateArgs = {
  distinct_on?: Maybe<Array<Firmware_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Firmware_Order_By>>;
  where?: Maybe<Firmware_Bool_Exp>;
};


export type Subscription_RootFirmware_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFirmware_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Firmware_Stream_Cursor_Input>>;
  where?: Maybe<Firmware_Bool_Exp>;
};


export type Subscription_RootGround_Truth_ConfigurationsArgs = {
  distinct_on?: Maybe<Array<Ground_Truth_Configurations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ground_Truth_Configurations_Order_By>>;
  where?: Maybe<Ground_Truth_Configurations_Bool_Exp>;
};


export type Subscription_RootGround_Truth_Configurations_AggregateArgs = {
  distinct_on?: Maybe<Array<Ground_Truth_Configurations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ground_Truth_Configurations_Order_By>>;
  where?: Maybe<Ground_Truth_Configurations_Bool_Exp>;
};


export type Subscription_RootGround_Truth_Configurations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGround_Truth_Configurations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Ground_Truth_Configurations_Stream_Cursor_Input>>;
  where?: Maybe<Ground_Truth_Configurations_Bool_Exp>;
};


export type Subscription_RootGround_Truth_Device_TypesArgs = {
  distinct_on?: Maybe<Array<Ground_Truth_Device_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ground_Truth_Device_Types_Order_By>>;
  where?: Maybe<Ground_Truth_Device_Types_Bool_Exp>;
};


export type Subscription_RootGround_Truth_Device_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Ground_Truth_Device_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ground_Truth_Device_Types_Order_By>>;
  where?: Maybe<Ground_Truth_Device_Types_Bool_Exp>;
};


export type Subscription_RootGround_Truth_Device_Types_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootGround_Truth_Device_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Ground_Truth_Device_Types_Stream_Cursor_Input>>;
  where?: Maybe<Ground_Truth_Device_Types_Bool_Exp>;
};


export type Subscription_RootGround_Truth_Processing_ModesArgs = {
  distinct_on?: Maybe<Array<Ground_Truth_Processing_Modes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ground_Truth_Processing_Modes_Order_By>>;
  where?: Maybe<Ground_Truth_Processing_Modes_Bool_Exp>;
};


export type Subscription_RootGround_Truth_Processing_Modes_AggregateArgs = {
  distinct_on?: Maybe<Array<Ground_Truth_Processing_Modes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ground_Truth_Processing_Modes_Order_By>>;
  where?: Maybe<Ground_Truth_Processing_Modes_Bool_Exp>;
};


export type Subscription_RootGround_Truth_Processing_Modes_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootGround_Truth_Processing_Modes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Ground_Truth_Processing_Modes_Stream_Cursor_Input>>;
  where?: Maybe<Ground_Truth_Processing_Modes_Bool_Exp>;
};


export type Subscription_RootGround_Truth_RecordingsArgs = {
  distinct_on?: Maybe<Array<Ground_Truth_Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ground_Truth_Recordings_Order_By>>;
  where?: Maybe<Ground_Truth_Recordings_Bool_Exp>;
};


export type Subscription_RootGround_Truth_Recordings_AggregateArgs = {
  distinct_on?: Maybe<Array<Ground_Truth_Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ground_Truth_Recordings_Order_By>>;
  where?: Maybe<Ground_Truth_Recordings_Bool_Exp>;
};


export type Subscription_RootGround_Truth_Recordings_By_PkArgs = {
  recording_id: Scalars['uuid'];
};


export type Subscription_RootGround_Truth_Recordings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Ground_Truth_Recordings_Stream_Cursor_Input>>;
  where?: Maybe<Ground_Truth_Recordings_Bool_Exp>;
};


export type Subscription_RootGround_Truth_TypesArgs = {
  distinct_on?: Maybe<Array<Ground_Truth_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ground_Truth_Types_Order_By>>;
  where?: Maybe<Ground_Truth_Types_Bool_Exp>;
};


export type Subscription_RootGround_Truth_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Ground_Truth_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ground_Truth_Types_Order_By>>;
  where?: Maybe<Ground_Truth_Types_Bool_Exp>;
};


export type Subscription_RootGround_Truth_Types_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGround_Truth_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Ground_Truth_Types_Stream_Cursor_Input>>;
  where?: Maybe<Ground_Truth_Types_Bool_Exp>;
};


export type Subscription_RootHypertask_Field_OptionArgs = {
  distinct_on?: Maybe<Array<Hypertask_Field_Option_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hypertask_Field_Option_Order_By>>;
  where?: Maybe<Hypertask_Field_Option_Bool_Exp>;
};


export type Subscription_RootHypertask_Field_Option_AggregateArgs = {
  distinct_on?: Maybe<Array<Hypertask_Field_Option_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hypertask_Field_Option_Order_By>>;
  where?: Maybe<Hypertask_Field_Option_Bool_Exp>;
};


export type Subscription_RootHypertask_Field_Option_By_PkArgs = {
  field_id: Scalars['uuid'];
  hypertask_id: Scalars['uuid'];
  option_id: Scalars['uuid'];
};


export type Subscription_RootHypertask_Field_Option_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Hypertask_Field_Option_Stream_Cursor_Input>>;
  where?: Maybe<Hypertask_Field_Option_Bool_Exp>;
};


export type Subscription_RootHypertask_TaskArgs = {
  distinct_on?: Maybe<Array<Hypertask_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hypertask_Task_Order_By>>;
  where?: Maybe<Hypertask_Task_Bool_Exp>;
};


export type Subscription_RootHypertask_Task_AggregateArgs = {
  distinct_on?: Maybe<Array<Hypertask_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hypertask_Task_Order_By>>;
  where?: Maybe<Hypertask_Task_Bool_Exp>;
};


export type Subscription_RootHypertask_Task_By_PkArgs = {
  hypertask_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};


export type Subscription_RootHypertask_Task_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Hypertask_Task_Stream_Cursor_Input>>;
  where?: Maybe<Hypertask_Task_Bool_Exp>;
};


export type Subscription_RootHypertasksArgs = {
  distinct_on?: Maybe<Array<Hypertasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hypertasks_Order_By>>;
  where?: Maybe<Hypertasks_Bool_Exp>;
};


export type Subscription_RootHypertasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Hypertasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hypertasks_Order_By>>;
  where?: Maybe<Hypertasks_Bool_Exp>;
};


export type Subscription_RootHypertasks_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootHypertasks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Hypertasks_Stream_Cursor_Input>>;
  where?: Maybe<Hypertasks_Bool_Exp>;
};


export type Subscription_RootInstruction_TranslationsArgs = {
  distinct_on?: Maybe<Array<Instruction_Translations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Instruction_Translations_Order_By>>;
  where?: Maybe<Instruction_Translations_Bool_Exp>;
};


export type Subscription_RootInstruction_Translations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInstruction_Translations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Instruction_Translations_Stream_Cursor_Input>>;
  where?: Maybe<Instruction_Translations_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_Expectation_MediaArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Expectation_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Expectation_Media_Order_By>>;
  where?: Maybe<Integration_Test_Expectation_Media_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_Expectation_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Expectation_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Expectation_Media_Order_By>>;
  where?: Maybe<Integration_Test_Expectation_Media_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_Expectation_Media_By_PkArgs = {
  expectation_id: Scalars['uuid'];
  media_id: Scalars['uuid'];
};


export type Subscription_RootIntegration_Test_Expectation_Media_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Integration_Test_Expectation_Media_Stream_Cursor_Input>>;
  where?: Maybe<Integration_Test_Expectation_Media_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_Expectation_StepArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Expectation_Step_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Expectation_Step_Order_By>>;
  where?: Maybe<Integration_Test_Expectation_Step_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_Expectation_Step_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Expectation_Step_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Expectation_Step_Order_By>>;
  where?: Maybe<Integration_Test_Expectation_Step_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_Expectation_Step_By_PkArgs = {
  expectation_id: Scalars['uuid'];
  step_id: Scalars['uuid'];
};


export type Subscription_RootIntegration_Test_Expectation_Step_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Integration_Test_Expectation_Step_Stream_Cursor_Input>>;
  where?: Maybe<Integration_Test_Expectation_Step_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_Expectation_TranslationArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Expectation_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Expectation_Translation_Order_By>>;
  where?: Maybe<Integration_Test_Expectation_Translation_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_Expectation_Translation_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Expectation_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Expectation_Translation_Order_By>>;
  where?: Maybe<Integration_Test_Expectation_Translation_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_Expectation_Translation_By_PkArgs = {
  expectation_id: Scalars['uuid'];
  language: Scalars['String'];
};


export type Subscription_RootIntegration_Test_Expectation_Translation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Integration_Test_Expectation_Translation_Stream_Cursor_Input>>;
  where?: Maybe<Integration_Test_Expectation_Translation_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_ExpectationsArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Expectations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Expectations_Order_By>>;
  where?: Maybe<Integration_Test_Expectations_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_Expectations_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Expectations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Expectations_Order_By>>;
  where?: Maybe<Integration_Test_Expectations_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_Expectations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootIntegration_Test_Expectations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Integration_Test_Expectations_Stream_Cursor_Input>>;
  where?: Maybe<Integration_Test_Expectations_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_Instruction_MediaArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Instruction_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Instruction_Media_Order_By>>;
  where?: Maybe<Integration_Test_Instruction_Media_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_Instruction_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Instruction_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Instruction_Media_Order_By>>;
  where?: Maybe<Integration_Test_Instruction_Media_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_Instruction_Media_By_PkArgs = {
  instruction_id: Scalars['uuid'];
  media_id: Scalars['uuid'];
};


export type Subscription_RootIntegration_Test_Instruction_Media_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Integration_Test_Instruction_Media_Stream_Cursor_Input>>;
  where?: Maybe<Integration_Test_Instruction_Media_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_Instruction_TranslationArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Instruction_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Instruction_Translation_Order_By>>;
  where?: Maybe<Integration_Test_Instruction_Translation_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_Instruction_Translation_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Instruction_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Instruction_Translation_Order_By>>;
  where?: Maybe<Integration_Test_Instruction_Translation_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_Instruction_Translation_By_PkArgs = {
  instruction_id: Scalars['uuid'];
  language: Scalars['String'];
};


export type Subscription_RootIntegration_Test_Instruction_Translation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Integration_Test_Instruction_Translation_Stream_Cursor_Input>>;
  where?: Maybe<Integration_Test_Instruction_Translation_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_InstructionsArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Instructions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Instructions_Order_By>>;
  where?: Maybe<Integration_Test_Instructions_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_Instructions_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Instructions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Instructions_Order_By>>;
  where?: Maybe<Integration_Test_Instructions_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_Instructions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootIntegration_Test_Instructions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Integration_Test_Instructions_Stream_Cursor_Input>>;
  where?: Maybe<Integration_Test_Instructions_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_ObservancesArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Observances_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Observances_Order_By>>;
  where?: Maybe<Integration_Test_Observances_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_Observances_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Observances_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Observances_Order_By>>;
  where?: Maybe<Integration_Test_Observances_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_Observances_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootIntegration_Test_Observances_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Integration_Test_Observances_Stream_Cursor_Input>>;
  where?: Maybe<Integration_Test_Observances_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_ObservationsArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Observations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Observations_Order_By>>;
  where?: Maybe<Integration_Test_Observations_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_Observations_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Observations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Observations_Order_By>>;
  where?: Maybe<Integration_Test_Observations_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_Observations_By_PkArgs = {
  expectation_id: Scalars['uuid'];
  observance_id: Scalars['uuid'];
  step_id: Scalars['uuid'];
};


export type Subscription_RootIntegration_Test_Observations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Integration_Test_Observations_Stream_Cursor_Input>>;
  where?: Maybe<Integration_Test_Observations_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_Project_SequenceArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Project_Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Project_Sequence_Order_By>>;
  where?: Maybe<Integration_Test_Project_Sequence_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_Project_Sequence_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Project_Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Project_Sequence_Order_By>>;
  where?: Maybe<Integration_Test_Project_Sequence_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_Project_Sequence_By_PkArgs = {
  project_id: Scalars['uuid'];
  sequence_id: Scalars['uuid'];
};


export type Subscription_RootIntegration_Test_Project_Sequence_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Integration_Test_Project_Sequence_Stream_Cursor_Input>>;
  where?: Maybe<Integration_Test_Project_Sequence_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_SequencesArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Sequences_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Sequences_Order_By>>;
  where?: Maybe<Integration_Test_Sequences_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_Sequences_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Sequences_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Sequences_Order_By>>;
  where?: Maybe<Integration_Test_Sequences_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_Sequences_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootIntegration_Test_Sequences_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Integration_Test_Sequences_Stream_Cursor_Input>>;
  where?: Maybe<Integration_Test_Sequences_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_StepsArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Steps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Steps_Order_By>>;
  where?: Maybe<Integration_Test_Steps_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_Steps_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Steps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Steps_Order_By>>;
  where?: Maybe<Integration_Test_Steps_Bool_Exp>;
};


export type Subscription_RootIntegration_Test_Steps_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootIntegration_Test_Steps_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Integration_Test_Steps_Stream_Cursor_Input>>;
  where?: Maybe<Integration_Test_Steps_Bool_Exp>;
};


export type Subscription_RootLanguagesArgs = {
  distinct_on?: Maybe<Array<Languages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Languages_Order_By>>;
  where?: Maybe<Languages_Bool_Exp>;
};


export type Subscription_RootLanguages_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootLanguages_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Languages_Stream_Cursor_Input>>;
  where?: Maybe<Languages_Bool_Exp>;
};


export type Subscription_RootMachine_Learning_BlobsArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Blobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Blobs_Order_By>>;
  where?: Maybe<Machine_Learning_Blobs_Bool_Exp>;
};


export type Subscription_RootMachine_Learning_Blobs_AggregateArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Blobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Blobs_Order_By>>;
  where?: Maybe<Machine_Learning_Blobs_Bool_Exp>;
};


export type Subscription_RootMachine_Learning_Blobs_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMachine_Learning_Blobs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Machine_Learning_Blobs_Stream_Cursor_Input>>;
  where?: Maybe<Machine_Learning_Blobs_Bool_Exp>;
};


export type Subscription_RootMachine_Learning_Dataset_FeatureArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Dataset_Feature_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Dataset_Feature_Order_By>>;
  where?: Maybe<Machine_Learning_Dataset_Feature_Bool_Exp>;
};


export type Subscription_RootMachine_Learning_Dataset_Feature_AggregateArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Dataset_Feature_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Dataset_Feature_Order_By>>;
  where?: Maybe<Machine_Learning_Dataset_Feature_Bool_Exp>;
};


export type Subscription_RootMachine_Learning_Dataset_Feature_By_PkArgs = {
  dataset_id: Scalars['uuid'];
  feature_id: Scalars['uuid'];
};


export type Subscription_RootMachine_Learning_Dataset_Feature_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Machine_Learning_Dataset_Feature_Stream_Cursor_Input>>;
  where?: Maybe<Machine_Learning_Dataset_Feature_Bool_Exp>;
};


export type Subscription_RootMachine_Learning_DatasetsArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Datasets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Datasets_Order_By>>;
  where?: Maybe<Machine_Learning_Datasets_Bool_Exp>;
};


export type Subscription_RootMachine_Learning_Datasets_AggregateArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Datasets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Datasets_Order_By>>;
  where?: Maybe<Machine_Learning_Datasets_Bool_Exp>;
};


export type Subscription_RootMachine_Learning_Datasets_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMachine_Learning_Datasets_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Machine_Learning_Datasets_Stream_Cursor_Input>>;
  where?: Maybe<Machine_Learning_Datasets_Bool_Exp>;
};


export type Subscription_RootMachine_Learning_FeaturesArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Features_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Features_Order_By>>;
  where?: Maybe<Machine_Learning_Features_Bool_Exp>;
};


export type Subscription_RootMachine_Learning_Features_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMachine_Learning_Features_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Machine_Learning_Features_Stream_Cursor_Input>>;
  where?: Maybe<Machine_Learning_Features_Bool_Exp>;
};


export type Subscription_RootMachine_Learning_Model_BlobArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Model_Blob_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Model_Blob_Order_By>>;
  where?: Maybe<Machine_Learning_Model_Blob_Bool_Exp>;
};


export type Subscription_RootMachine_Learning_Model_Blob_By_PkArgs = {
  blob_id: Scalars['uuid'];
  model_id: Scalars['uuid'];
};


export type Subscription_RootMachine_Learning_Model_Blob_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Machine_Learning_Model_Blob_Stream_Cursor_Input>>;
  where?: Maybe<Machine_Learning_Model_Blob_Bool_Exp>;
};


export type Subscription_RootMachine_Learning_Model_DatasetArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Model_Dataset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Model_Dataset_Order_By>>;
  where?: Maybe<Machine_Learning_Model_Dataset_Bool_Exp>;
};


export type Subscription_RootMachine_Learning_Model_Dataset_By_PkArgs = {
  dataset_id: Scalars['uuid'];
  model_id: Scalars['uuid'];
};


export type Subscription_RootMachine_Learning_Model_Dataset_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Machine_Learning_Model_Dataset_Stream_Cursor_Input>>;
  where?: Maybe<Machine_Learning_Model_Dataset_Bool_Exp>;
};


export type Subscription_RootMachine_Learning_Model_RecordingArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Model_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Model_Recording_Order_By>>;
  where?: Maybe<Machine_Learning_Model_Recording_Bool_Exp>;
};


export type Subscription_RootMachine_Learning_Model_Recording_AggregateArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Model_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Model_Recording_Order_By>>;
  where?: Maybe<Machine_Learning_Model_Recording_Bool_Exp>;
};


export type Subscription_RootMachine_Learning_Model_Recording_By_PkArgs = {
  model_id: Scalars['uuid'];
  recording_id: Scalars['uuid'];
};


export type Subscription_RootMachine_Learning_Model_Recording_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Machine_Learning_Model_Recording_Stream_Cursor_Input>>;
  where?: Maybe<Machine_Learning_Model_Recording_Bool_Exp>;
};


export type Subscription_RootMachine_Learning_Model_TypesArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Model_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Model_Types_Order_By>>;
  where?: Maybe<Machine_Learning_Model_Types_Bool_Exp>;
};


export type Subscription_RootMachine_Learning_Model_Types_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootMachine_Learning_Model_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Machine_Learning_Model_Types_Stream_Cursor_Input>>;
  where?: Maybe<Machine_Learning_Model_Types_Bool_Exp>;
};


export type Subscription_RootMachine_Learning_ModelsArgs = {
  distinct_on?: Maybe<Array<Machine_Learning_Models_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Machine_Learning_Models_Order_By>>;
  where?: Maybe<Machine_Learning_Models_Bool_Exp>;
};


export type Subscription_RootMachine_Learning_Models_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMachine_Learning_Models_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Machine_Learning_Models_Stream_Cursor_Input>>;
  where?: Maybe<Machine_Learning_Models_Bool_Exp>;
};


export type Subscription_RootMediaArgs = {
  distinct_on?: Maybe<Array<Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Media_Order_By>>;
  where?: Maybe<Media_Bool_Exp>;
};


export type Subscription_RootMedia_AggregateArgs = {
  distinct_on?: Maybe<Array<Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Media_Order_By>>;
  where?: Maybe<Media_Bool_Exp>;
};


export type Subscription_RootMedia_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMedia_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Media_Stream_Cursor_Input>>;
  where?: Maybe<Media_Bool_Exp>;
};


export type Subscription_RootMedia_TypeArgs = {
  distinct_on?: Maybe<Array<Media_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Media_Type_Order_By>>;
  where?: Maybe<Media_Type_Bool_Exp>;
};


export type Subscription_RootMedia_Type_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootMedia_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Media_Type_Stream_Cursor_Input>>;
  where?: Maybe<Media_Type_Bool_Exp>;
};


export type Subscription_RootMode_ProjectArgs = {
  distinct_on?: Maybe<Array<Mode_Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mode_Project_Order_By>>;
  where?: Maybe<Mode_Project_Bool_Exp>;
};


export type Subscription_RootMode_Project_AggregateArgs = {
  distinct_on?: Maybe<Array<Mode_Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mode_Project_Order_By>>;
  where?: Maybe<Mode_Project_Bool_Exp>;
};


export type Subscription_RootMode_Project_By_PkArgs = {
  mode_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};


export type Subscription_RootMode_Project_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Mode_Project_Stream_Cursor_Input>>;
  where?: Maybe<Mode_Project_Bool_Exp>;
};


export type Subscription_RootModel_Name_HashesArgs = {
  distinct_on?: Maybe<Array<Model_Name_Hashes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Model_Name_Hashes_Order_By>>;
  where?: Maybe<Model_Name_Hashes_Bool_Exp>;
};


export type Subscription_RootModel_Name_Hashes_AggregateArgs = {
  distinct_on?: Maybe<Array<Model_Name_Hashes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Model_Name_Hashes_Order_By>>;
  where?: Maybe<Model_Name_Hashes_Bool_Exp>;
};


export type Subscription_RootModel_Name_Hashes_By_PkArgs = {
  hash: Scalars['String'];
  model_id: Scalars['uuid'];
};


export type Subscription_RootModel_Name_Hashes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Model_Name_Hashes_Stream_Cursor_Input>>;
  where?: Maybe<Model_Name_Hashes_Bool_Exp>;
};


export type Subscription_RootModelsArgs = {
  distinct_on?: Maybe<Array<Models_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Models_Order_By>>;
  where?: Maybe<Models_Bool_Exp>;
};


export type Subscription_RootModels_AggregateArgs = {
  distinct_on?: Maybe<Array<Models_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Models_Order_By>>;
  where?: Maybe<Models_Bool_Exp>;
};


export type Subscription_RootModels_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootModels_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Models_Stream_Cursor_Input>>;
  where?: Maybe<Models_Bool_Exp>;
};


export type Subscription_RootOpmode_ProfileArgs = {
  distinct_on?: Maybe<Array<Opmode_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Opmode_Profile_Order_By>>;
  where?: Maybe<Opmode_Profile_Bool_Exp>;
};


export type Subscription_RootOpmode_Profile_By_PkArgs = {
  opmode_id: Scalars['uuid'];
  profile_id: Scalars['uuid'];
};


export type Subscription_RootOpmode_Profile_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Opmode_Profile_Stream_Cursor_Input>>;
  where?: Maybe<Opmode_Profile_Bool_Exp>;
};


export type Subscription_RootOpmode_ProjectArgs = {
  distinct_on?: Maybe<Array<Opmode_Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Opmode_Project_Order_By>>;
  where?: Maybe<Opmode_Project_Bool_Exp>;
};


export type Subscription_RootOpmode_Project_AggregateArgs = {
  distinct_on?: Maybe<Array<Opmode_Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Opmode_Project_Order_By>>;
  where?: Maybe<Opmode_Project_Bool_Exp>;
};


export type Subscription_RootOpmode_Project_By_PkArgs = {
  opmode_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};


export type Subscription_RootOpmode_Project_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Opmode_Project_Stream_Cursor_Input>>;
  where?: Maybe<Opmode_Project_Bool_Exp>;
};


export type Subscription_RootOpmodesArgs = {
  distinct_on?: Maybe<Array<Opmodes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Opmodes_Order_By>>;
  where?: Maybe<Opmodes_Bool_Exp>;
};


export type Subscription_RootOpmodes_AggregateArgs = {
  distinct_on?: Maybe<Array<Opmodes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Opmodes_Order_By>>;
  where?: Maybe<Opmodes_Bool_Exp>;
};


export type Subscription_RootOpmodes_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOpmodes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Opmodes_Stream_Cursor_Input>>;
  where?: Maybe<Opmodes_Bool_Exp>;
};


export type Subscription_RootOption_MediaArgs = {
  distinct_on?: Maybe<Array<Option_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Option_Media_Order_By>>;
  where?: Maybe<Option_Media_Bool_Exp>;
};


export type Subscription_RootOption_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Option_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Option_Media_Order_By>>;
  where?: Maybe<Option_Media_Bool_Exp>;
};


export type Subscription_RootOption_Media_By_PkArgs = {
  media_id: Scalars['uuid'];
  option_id: Scalars['uuid'];
};


export type Subscription_RootOption_Media_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Option_Media_Stream_Cursor_Input>>;
  where?: Maybe<Option_Media_Bool_Exp>;
};


export type Subscription_RootOption_TranslationArgs = {
  distinct_on?: Maybe<Array<Option_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Option_Translation_Order_By>>;
  where?: Maybe<Option_Translation_Bool_Exp>;
};


export type Subscription_RootOption_Translation_AggregateArgs = {
  distinct_on?: Maybe<Array<Option_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Option_Translation_Order_By>>;
  where?: Maybe<Option_Translation_Bool_Exp>;
};


export type Subscription_RootOption_Translation_By_PkArgs = {
  language: Scalars['String'];
  option_id: Scalars['uuid'];
};


export type Subscription_RootOption_Translation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Option_Translation_Stream_Cursor_Input>>;
  where?: Maybe<Option_Translation_Bool_Exp>;
};


export type Subscription_RootOptionsArgs = {
  distinct_on?: Maybe<Array<Options_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Options_Order_By>>;
  where?: Maybe<Options_Bool_Exp>;
};


export type Subscription_RootOptions_AggregateArgs = {
  distinct_on?: Maybe<Array<Options_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Options_Order_By>>;
  where?: Maybe<Options_Bool_Exp>;
};


export type Subscription_RootOptions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOptions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Options_Stream_Cursor_Input>>;
  where?: Maybe<Options_Bool_Exp>;
};


export type Subscription_RootPayload_RecordingArgs = {
  distinct_on?: Maybe<Array<Payload_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payload_Recording_Order_By>>;
  where?: Maybe<Payload_Recording_Bool_Exp>;
};


export type Subscription_RootPayload_Recording_AggregateArgs = {
  distinct_on?: Maybe<Array<Payload_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payload_Recording_Order_By>>;
  where?: Maybe<Payload_Recording_Bool_Exp>;
};


export type Subscription_RootPayload_Recording_By_PkArgs = {
  payload_id: Scalars['uuid'];
  recording_id: Scalars['uuid'];
};


export type Subscription_RootPayload_Recording_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Payload_Recording_Stream_Cursor_Input>>;
  where?: Maybe<Payload_Recording_Bool_Exp>;
};


export type Subscription_RootPayload_TypesArgs = {
  distinct_on?: Maybe<Array<Payload_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payload_Types_Order_By>>;
  where?: Maybe<Payload_Types_Bool_Exp>;
};


export type Subscription_RootPayload_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Payload_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payload_Types_Order_By>>;
  where?: Maybe<Payload_Types_Bool_Exp>;
};


export type Subscription_RootPayload_Types_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootPayload_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Payload_Types_Stream_Cursor_Input>>;
  where?: Maybe<Payload_Types_Bool_Exp>;
};


export type Subscription_RootPayloadsArgs = {
  distinct_on?: Maybe<Array<Payloads_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payloads_Order_By>>;
  where?: Maybe<Payloads_Bool_Exp>;
};


export type Subscription_RootPayloads_AggregateArgs = {
  distinct_on?: Maybe<Array<Payloads_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payloads_Order_By>>;
  where?: Maybe<Payloads_Bool_Exp>;
};


export type Subscription_RootPayloads_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPayloads_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Payloads_Stream_Cursor_Input>>;
  where?: Maybe<Payloads_Bool_Exp>;
};


export type Subscription_RootPerformance_Ratio_Metric_ContributionsArgs = {
  distinct_on?: Maybe<Array<Performance_Ratio_Metric_Contributions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Ratio_Metric_Contributions_Order_By>>;
  where?: Maybe<Performance_Ratio_Metric_Contributions_Bool_Exp>;
};


export type Subscription_RootPerformance_Ratio_Metric_Contributions_AggregateArgs = {
  distinct_on?: Maybe<Array<Performance_Ratio_Metric_Contributions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Ratio_Metric_Contributions_Order_By>>;
  where?: Maybe<Performance_Ratio_Metric_Contributions_Bool_Exp>;
};


export type Subscription_RootPerformance_Ratio_Metric_Contributions_By_PkArgs = {
  engine_id: Scalars['uuid'];
  ratio_metric_id: Scalars['uuid'];
  recording_id: Scalars['uuid'];
};


export type Subscription_RootPerformance_Ratio_Metric_Contributions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Performance_Ratio_Metric_Contributions_Stream_Cursor_Input>>;
  where?: Maybe<Performance_Ratio_Metric_Contributions_Bool_Exp>;
};


export type Subscription_RootPerformance_Ratio_MetricsArgs = {
  distinct_on?: Maybe<Array<Performance_Ratio_Metrics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Ratio_Metrics_Order_By>>;
  where?: Maybe<Performance_Ratio_Metrics_Bool_Exp>;
};


export type Subscription_RootPerformance_Ratio_Metrics_AggregateArgs = {
  distinct_on?: Maybe<Array<Performance_Ratio_Metrics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Ratio_Metrics_Order_By>>;
  where?: Maybe<Performance_Ratio_Metrics_Bool_Exp>;
};


export type Subscription_RootPerformance_Ratio_Metrics_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPerformance_Ratio_Metrics_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Performance_Ratio_Metrics_Stream_Cursor_Input>>;
  where?: Maybe<Performance_Ratio_Metrics_Bool_Exp>;
};


export type Subscription_RootPerformance_Report_Deliverable_TemplateArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Deliverable_Template_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Deliverable_Template_Order_By>>;
  where?: Maybe<Performance_Report_Deliverable_Template_Bool_Exp>;
};


export type Subscription_RootPerformance_Report_Deliverable_Template_AggregateArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Deliverable_Template_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Deliverable_Template_Order_By>>;
  where?: Maybe<Performance_Report_Deliverable_Template_Bool_Exp>;
};


export type Subscription_RootPerformance_Report_Deliverable_Template_By_PkArgs = {
  deliverable_id: Scalars['uuid'];
  template_id: Scalars['uuid'];
};


export type Subscription_RootPerformance_Report_Deliverable_Template_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Performance_Report_Deliverable_Template_Stream_Cursor_Input>>;
  where?: Maybe<Performance_Report_Deliverable_Template_Bool_Exp>;
};


export type Subscription_RootPerformance_Report_Pivot_TypesArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Pivot_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Pivot_Types_Order_By>>;
  where?: Maybe<Performance_Report_Pivot_Types_Bool_Exp>;
};


export type Subscription_RootPerformance_Report_Pivot_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Pivot_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Pivot_Types_Order_By>>;
  where?: Maybe<Performance_Report_Pivot_Types_Bool_Exp>;
};


export type Subscription_RootPerformance_Report_Pivot_Types_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootPerformance_Report_Pivot_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Performance_Report_Pivot_Types_Stream_Cursor_Input>>;
  where?: Maybe<Performance_Report_Pivot_Types_Bool_Exp>;
};


export type Subscription_RootPerformance_Report_Section_Pivot_FieldArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Section_Pivot_Field_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Section_Pivot_Field_Order_By>>;
  where?: Maybe<Performance_Report_Section_Pivot_Field_Bool_Exp>;
};


export type Subscription_RootPerformance_Report_Section_Pivot_Field_AggregateArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Section_Pivot_Field_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Section_Pivot_Field_Order_By>>;
  where?: Maybe<Performance_Report_Section_Pivot_Field_Bool_Exp>;
};


export type Subscription_RootPerformance_Report_Section_Pivot_Field_By_PkArgs = {
  field_id: Scalars['uuid'];
  section_id: Scalars['uuid'];
};


export type Subscription_RootPerformance_Report_Section_Pivot_Field_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Performance_Report_Section_Pivot_Field_Stream_Cursor_Input>>;
  where?: Maybe<Performance_Report_Section_Pivot_Field_Bool_Exp>;
};


export type Subscription_RootPerformance_Report_Section_Ratio_MetricArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Section_Ratio_Metric_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Section_Ratio_Metric_Order_By>>;
  where?: Maybe<Performance_Report_Section_Ratio_Metric_Bool_Exp>;
};


export type Subscription_RootPerformance_Report_Section_Ratio_Metric_AggregateArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Section_Ratio_Metric_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Section_Ratio_Metric_Order_By>>;
  where?: Maybe<Performance_Report_Section_Ratio_Metric_Bool_Exp>;
};


export type Subscription_RootPerformance_Report_Section_Ratio_Metric_By_PkArgs = {
  ratio_metric_id: Scalars['uuid'];
  section_id: Scalars['uuid'];
};


export type Subscription_RootPerformance_Report_Section_Ratio_Metric_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Performance_Report_Section_Ratio_Metric_Stream_Cursor_Input>>;
  where?: Maybe<Performance_Report_Section_Ratio_Metric_Bool_Exp>;
};


export type Subscription_RootPerformance_Report_Section_Task_BlacklistArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Section_Task_Blacklist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Section_Task_Blacklist_Order_By>>;
  where?: Maybe<Performance_Report_Section_Task_Blacklist_Bool_Exp>;
};


export type Subscription_RootPerformance_Report_Section_Task_Blacklist_AggregateArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Section_Task_Blacklist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Section_Task_Blacklist_Order_By>>;
  where?: Maybe<Performance_Report_Section_Task_Blacklist_Bool_Exp>;
};


export type Subscription_RootPerformance_Report_Section_Task_Blacklist_By_PkArgs = {
  section_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};


export type Subscription_RootPerformance_Report_Section_Task_Blacklist_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Performance_Report_Section_Task_Blacklist_Stream_Cursor_Input>>;
  where?: Maybe<Performance_Report_Section_Task_Blacklist_Bool_Exp>;
};


export type Subscription_RootPerformance_Report_SectionsArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Sections_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Sections_Order_By>>;
  where?: Maybe<Performance_Report_Sections_Bool_Exp>;
};


export type Subscription_RootPerformance_Report_Sections_AggregateArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Sections_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Sections_Order_By>>;
  where?: Maybe<Performance_Report_Sections_Bool_Exp>;
};


export type Subscription_RootPerformance_Report_Sections_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPerformance_Report_Sections_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Performance_Report_Sections_Stream_Cursor_Input>>;
  where?: Maybe<Performance_Report_Sections_Bool_Exp>;
};


export type Subscription_RootPerformance_Report_TemplatesArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Templates_Order_By>>;
  where?: Maybe<Performance_Report_Templates_Bool_Exp>;
};


export type Subscription_RootPerformance_Report_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Performance_Report_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Performance_Report_Templates_Order_By>>;
  where?: Maybe<Performance_Report_Templates_Bool_Exp>;
};


export type Subscription_RootPerformance_Report_Templates_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPerformance_Report_Templates_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Performance_Report_Templates_Stream_Cursor_Input>>;
  where?: Maybe<Performance_Report_Templates_Bool_Exp>;
};


export type Subscription_RootPeripheralsArgs = {
  distinct_on?: Maybe<Array<Peripherals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Peripherals_Order_By>>;
  where?: Maybe<Peripherals_Bool_Exp>;
};


export type Subscription_RootPeripherals_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPeripherals_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Peripherals_Stream_Cursor_Input>>;
  where?: Maybe<Peripherals_Bool_Exp>;
};


export type Subscription_RootPlan_HypertasksArgs = {
  distinct_on?: Maybe<Array<Plan_Hypertasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plan_Hypertasks_Order_By>>;
  where?: Maybe<Plan_Hypertasks_Bool_Exp>;
};


export type Subscription_RootPlan_Hypertasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Plan_Hypertasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plan_Hypertasks_Order_By>>;
  where?: Maybe<Plan_Hypertasks_Bool_Exp>;
};


export type Subscription_RootPlan_Hypertasks_By_PkArgs = {
  hypertask_id: Scalars['uuid'];
  plan_id: Scalars['uuid'];
};


export type Subscription_RootPlan_Hypertasks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Plan_Hypertasks_Stream_Cursor_Input>>;
  where?: Maybe<Plan_Hypertasks_Bool_Exp>;
};


export type Subscription_RootPlan_TasksArgs = {
  distinct_on?: Maybe<Array<Plan_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plan_Tasks_Order_By>>;
  where?: Maybe<Plan_Tasks_Bool_Exp>;
};


export type Subscription_RootPlan_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Plan_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plan_Tasks_Order_By>>;
  where?: Maybe<Plan_Tasks_Bool_Exp>;
};


export type Subscription_RootPlan_Tasks_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPlan_Tasks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Plan_Tasks_Stream_Cursor_Input>>;
  where?: Maybe<Plan_Tasks_Bool_Exp>;
};


export type Subscription_RootPlansArgs = {
  distinct_on?: Maybe<Array<Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plans_Order_By>>;
  where?: Maybe<Plans_Bool_Exp>;
};


export type Subscription_RootPlans_AggregateArgs = {
  distinct_on?: Maybe<Array<Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plans_Order_By>>;
  where?: Maybe<Plans_Bool_Exp>;
};


export type Subscription_RootPlans_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPlans_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Plans_Stream_Cursor_Input>>;
  where?: Maybe<Plans_Bool_Exp>;
};


export type Subscription_RootProfilesArgs = {
  distinct_on?: Maybe<Array<Profiles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profiles_Order_By>>;
  where?: Maybe<Profiles_Bool_Exp>;
};


export type Subscription_RootProfiles_AggregateArgs = {
  distinct_on?: Maybe<Array<Profiles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profiles_Order_By>>;
  where?: Maybe<Profiles_Bool_Exp>;
};


export type Subscription_RootProfiles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProfiles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Profiles_Stream_Cursor_Input>>;
  where?: Maybe<Profiles_Bool_Exp>;
};


export type Subscription_RootProject_ComboArgs = {
  distinct_on?: Maybe<Array<Project_Combo_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Combo_Order_By>>;
  where?: Maybe<Project_Combo_Bool_Exp>;
};


export type Subscription_RootProject_Combo_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Combo_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Combo_Order_By>>;
  where?: Maybe<Project_Combo_Bool_Exp>;
};


export type Subscription_RootProject_Combo_By_PkArgs = {
  other_project_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};


export type Subscription_RootProject_Combo_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Project_Combo_Stream_Cursor_Input>>;
  where?: Maybe<Project_Combo_Bool_Exp>;
};


export type Subscription_RootProject_DatasetArgs = {
  distinct_on?: Maybe<Array<Project_Dataset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Dataset_Order_By>>;
  where?: Maybe<Project_Dataset_Bool_Exp>;
};


export type Subscription_RootProject_Dataset_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Dataset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Dataset_Order_By>>;
  where?: Maybe<Project_Dataset_Bool_Exp>;
};


export type Subscription_RootProject_Dataset_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Project_Dataset_Stream_Cursor_Input>>;
  where?: Maybe<Project_Dataset_Bool_Exp>;
};


export type Subscription_RootProject_Engine_MetricsArgs = {
  args: Project_Engine_Metrics_Args;
  distinct_on?: Maybe<Array<Schema_Project_Engine_Metrics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Project_Engine_Metrics_Order_By>>;
  where?: Maybe<Schema_Project_Engine_Metrics_Bool_Exp>;
};


export type Subscription_RootProject_Engine_Ratio_MetricsArgs = {
  args: Project_Engine_Ratio_Metrics_Args;
  distinct_on?: Maybe<Array<Schema_Project_Engine_Ratio_Metrics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Project_Engine_Ratio_Metrics_Order_By>>;
  where?: Maybe<Schema_Project_Engine_Ratio_Metrics_Bool_Exp>;
};


export type Subscription_RootProject_Engine_Ratio_Metrics_AggregateArgs = {
  args: Project_Engine_Ratio_Metrics_Args;
  distinct_on?: Maybe<Array<Schema_Project_Engine_Ratio_Metrics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Project_Engine_Ratio_Metrics_Order_By>>;
  where?: Maybe<Schema_Project_Engine_Ratio_Metrics_Bool_Exp>;
};


export type Subscription_RootProject_FieldsArgs = {
  args: Project_Fields_Args;
  distinct_on?: Maybe<Array<Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fields_Order_By>>;
  where?: Maybe<Fields_Bool_Exp>;
};


export type Subscription_RootProject_Fields_AggregateArgs = {
  args: Project_Fields_Args;
  distinct_on?: Maybe<Array<Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fields_Order_By>>;
  where?: Maybe<Fields_Bool_Exp>;
};


export type Subscription_RootProject_HypertasksArgs = {
  distinct_on?: Maybe<Array<Project_Hypertasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Hypertasks_Order_By>>;
  where?: Maybe<Project_Hypertasks_Bool_Exp>;
};


export type Subscription_RootProject_Hypertasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Hypertasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Hypertasks_Order_By>>;
  where?: Maybe<Project_Hypertasks_Bool_Exp>;
};


export type Subscription_RootProject_Hypertasks_By_PkArgs = {
  hypertask_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};


export type Subscription_RootProject_Hypertasks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Project_Hypertasks_Stream_Cursor_Input>>;
  where?: Maybe<Project_Hypertasks_Bool_Exp>;
};


export type Subscription_RootProject_MetricsArgs = {
  distinct_on?: Maybe<Array<Project_Metrics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Metrics_Order_By>>;
  where?: Maybe<Project_Metrics_Bool_Exp>;
};


export type Subscription_RootProject_Metrics_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Metrics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Metrics_Order_By>>;
  where?: Maybe<Project_Metrics_Bool_Exp>;
};


export type Subscription_RootProject_Metrics_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Project_Metrics_Stream_Cursor_Input>>;
  where?: Maybe<Project_Metrics_Bool_Exp>;
};


export type Subscription_RootProject_NameArgs = {
  distinct_on?: Maybe<Array<Project_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Name_Order_By>>;
  where?: Maybe<Project_Name_Bool_Exp>;
};


export type Subscription_RootProject_Name_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Project_Name_Stream_Cursor_Input>>;
  where?: Maybe<Project_Name_Bool_Exp>;
};


export type Subscription_RootProject_Rabbit_Test_InstancesArgs = {
  distinct_on?: Maybe<Array<Project_Rabbit_Test_Instances_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Rabbit_Test_Instances_Order_By>>;
  where?: Maybe<Project_Rabbit_Test_Instances_Bool_Exp>;
};


export type Subscription_RootProject_Rabbit_Test_Instances_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Rabbit_Test_Instances_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Rabbit_Test_Instances_Order_By>>;
  where?: Maybe<Project_Rabbit_Test_Instances_Bool_Exp>;
};


export type Subscription_RootProject_Rabbit_Test_Instances_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Project_Rabbit_Test_Instances_Stream_Cursor_Input>>;
  where?: Maybe<Project_Rabbit_Test_Instances_Bool_Exp>;
};


export type Subscription_RootProject_Recordings_CleanArgs = {
  args: Project_Recordings_Clean_Args;
  distinct_on?: Maybe<Array<Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recordings_Order_By>>;
  where?: Maybe<Recordings_Bool_Exp>;
};


export type Subscription_RootProject_Recordings_Clean_AggregateArgs = {
  args: Project_Recordings_Clean_Args;
  distinct_on?: Maybe<Array<Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recordings_Order_By>>;
  where?: Maybe<Recordings_Bool_Exp>;
};


export type Subscription_RootProject_Recordings_UnreviewedArgs = {
  args: Project_Recordings_Unreviewed_Args;
  distinct_on?: Maybe<Array<Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recordings_Order_By>>;
  where?: Maybe<Recordings_Bool_Exp>;
};


export type Subscription_RootProject_Recordings_Unreviewed_AggregateArgs = {
  args: Project_Recordings_Unreviewed_Args;
  distinct_on?: Maybe<Array<Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recordings_Order_By>>;
  where?: Maybe<Recordings_Bool_Exp>;
};


export type Subscription_RootProject_Task_CountArgs = {
  args: Project_Task_Count_Args;
  distinct_on?: Maybe<Array<Schema_Project_Task_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Project_Task_Count_Order_By>>;
  where?: Maybe<Schema_Project_Task_Count_Bool_Exp>;
};


export type Subscription_RootProject_Task_Count_AggregateArgs = {
  args: Project_Task_Count_Args;
  distinct_on?: Maybe<Array<Schema_Project_Task_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Project_Task_Count_Order_By>>;
  where?: Maybe<Schema_Project_Task_Count_Bool_Exp>;
};


export type Subscription_RootProject_Task_Count_By_DeviceArgs = {
  args: Project_Task_Count_By_Device_Args;
  distinct_on?: Maybe<Array<Schema_Project_Task_Count_By_Device_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Project_Task_Count_By_Device_Order_By>>;
  where?: Maybe<Schema_Project_Task_Count_By_Device_Bool_Exp>;
};


export type Subscription_RootProject_Task_Count_By_Device_AggregateArgs = {
  args: Project_Task_Count_By_Device_Args;
  distinct_on?: Maybe<Array<Schema_Project_Task_Count_By_Device_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Project_Task_Count_By_Device_Order_By>>;
  where?: Maybe<Schema_Project_Task_Count_By_Device_Bool_Exp>;
};


export type Subscription_RootProject_TasksArgs = {
  distinct_on?: Maybe<Array<Project_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Tasks_Order_By>>;
  where?: Maybe<Project_Tasks_Bool_Exp>;
};


export type Subscription_RootProject_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Tasks_Order_By>>;
  where?: Maybe<Project_Tasks_Bool_Exp>;
};


export type Subscription_RootProject_Tasks_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProject_Tasks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Project_Tasks_Stream_Cursor_Input>>;
  where?: Maybe<Project_Tasks_Bool_Exp>;
};


export type Subscription_RootProject_UserArgs = {
  distinct_on?: Maybe<Array<Project_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_User_Order_By>>;
  where?: Maybe<Project_User_Bool_Exp>;
};


export type Subscription_RootProject_User_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_User_Order_By>>;
  where?: Maybe<Project_User_Bool_Exp>;
};


export type Subscription_RootProject_User_By_PkArgs = {
  project_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Subscription_RootProject_User_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Project_User_Stream_Cursor_Input>>;
  where?: Maybe<Project_User_Bool_Exp>;
};


export type Subscription_RootProjectsArgs = {
  distinct_on?: Maybe<Array<Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Projects_Order_By>>;
  where?: Maybe<Projects_Bool_Exp>;
};


export type Subscription_RootProjects_AggregateArgs = {
  distinct_on?: Maybe<Array<Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Projects_Order_By>>;
  where?: Maybe<Projects_Bool_Exp>;
};


export type Subscription_RootProjects_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProjects_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Projects_Stream_Cursor_Input>>;
  where?: Maybe<Projects_Bool_Exp>;
};


export type Subscription_RootRabbit_Test_Plan_ProjectArgs = {
  distinct_on?: Maybe<Array<Rabbit_Test_Plan_Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rabbit_Test_Plan_Project_Order_By>>;
  where?: Maybe<Rabbit_Test_Plan_Project_Bool_Exp>;
};


export type Subscription_RootRabbit_Test_Plan_Project_AggregateArgs = {
  distinct_on?: Maybe<Array<Rabbit_Test_Plan_Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rabbit_Test_Plan_Project_Order_By>>;
  where?: Maybe<Rabbit_Test_Plan_Project_Bool_Exp>;
};


export type Subscription_RootRabbit_Test_Plan_Project_By_PkArgs = {
  plan_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};


export type Subscription_RootRabbit_Test_Plan_Project_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Rabbit_Test_Plan_Project_Stream_Cursor_Input>>;
  where?: Maybe<Rabbit_Test_Plan_Project_Bool_Exp>;
};


export type Subscription_RootRabbit_Test_Plan_RecordingArgs = {
  distinct_on?: Maybe<Array<Rabbit_Test_Plan_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rabbit_Test_Plan_Recording_Order_By>>;
  where?: Maybe<Rabbit_Test_Plan_Recording_Bool_Exp>;
};


export type Subscription_RootRabbit_Test_Plan_Recording_AggregateArgs = {
  distinct_on?: Maybe<Array<Rabbit_Test_Plan_Recording_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rabbit_Test_Plan_Recording_Order_By>>;
  where?: Maybe<Rabbit_Test_Plan_Recording_Bool_Exp>;
};


export type Subscription_RootRabbit_Test_Plan_Recording_By_PkArgs = {
  recording_id: Scalars['uuid'];
};


export type Subscription_RootRabbit_Test_Plan_Recording_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Rabbit_Test_Plan_Recording_Stream_Cursor_Input>>;
  where?: Maybe<Rabbit_Test_Plan_Recording_Bool_Exp>;
};


export type Subscription_RootRabbit_Test_Plan_TaskArgs = {
  distinct_on?: Maybe<Array<Rabbit_Test_Plan_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rabbit_Test_Plan_Task_Order_By>>;
  where?: Maybe<Rabbit_Test_Plan_Task_Bool_Exp>;
};


export type Subscription_RootRabbit_Test_Plan_Task_AggregateArgs = {
  distinct_on?: Maybe<Array<Rabbit_Test_Plan_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rabbit_Test_Plan_Task_Order_By>>;
  where?: Maybe<Rabbit_Test_Plan_Task_Bool_Exp>;
};


export type Subscription_RootRabbit_Test_Plan_Task_By_PkArgs = {
  plan_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};


export type Subscription_RootRabbit_Test_Plan_Task_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Rabbit_Test_Plan_Task_Stream_Cursor_Input>>;
  where?: Maybe<Rabbit_Test_Plan_Task_Bool_Exp>;
};


export type Subscription_RootRabbit_Test_PlansArgs = {
  distinct_on?: Maybe<Array<Rabbit_Test_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rabbit_Test_Plans_Order_By>>;
  where?: Maybe<Rabbit_Test_Plans_Bool_Exp>;
};


export type Subscription_RootRabbit_Test_Plans_AggregateArgs = {
  distinct_on?: Maybe<Array<Rabbit_Test_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rabbit_Test_Plans_Order_By>>;
  where?: Maybe<Rabbit_Test_Plans_Bool_Exp>;
};


export type Subscription_RootRabbit_Test_Plans_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRabbit_Test_Plans_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Rabbit_Test_Plans_Stream_Cursor_Input>>;
  where?: Maybe<Rabbit_Test_Plans_Bool_Exp>;
};


export type Subscription_RootRecording_AppsArgs = {
  distinct_on?: Maybe<Array<Recording_Apps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Apps_Order_By>>;
  where?: Maybe<Recording_Apps_Bool_Exp>;
};


export type Subscription_RootRecording_Apps_AggregateArgs = {
  distinct_on?: Maybe<Array<Recording_Apps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Apps_Order_By>>;
  where?: Maybe<Recording_Apps_Bool_Exp>;
};


export type Subscription_RootRecording_Apps_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRecording_Apps_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Recording_Apps_Stream_Cursor_Input>>;
  where?: Maybe<Recording_Apps_Bool_Exp>;
};


export type Subscription_RootRecording_Audio_DriverArgs = {
  distinct_on?: Maybe<Array<Recording_Audio_Driver_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Audio_Driver_Order_By>>;
  where?: Maybe<Recording_Audio_Driver_Bool_Exp>;
};


export type Subscription_RootRecording_Audio_Driver_By_PkArgs = {
  audio_driver_id: Scalars['uuid'];
  recording_id: Scalars['uuid'];
};


export type Subscription_RootRecording_Audio_Driver_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Recording_Audio_Driver_Stream_Cursor_Input>>;
  where?: Maybe<Recording_Audio_Driver_Bool_Exp>;
};


export type Subscription_RootRecording_Data_Ground_TruthArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Ground_Truth_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Ground_Truth_Order_By>>;
  where?: Maybe<Recording_Data_Ground_Truth_Bool_Exp>;
};


export type Subscription_RootRecording_Data_Ground_Truth_AggregateArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Ground_Truth_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Ground_Truth_Order_By>>;
  where?: Maybe<Recording_Data_Ground_Truth_Bool_Exp>;
};


export type Subscription_RootRecording_Data_Ground_Truth_By_PkArgs = {
  recording_id: Scalars['uuid'];
};


export type Subscription_RootRecording_Data_Ground_Truth_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Recording_Data_Ground_Truth_Stream_Cursor_Input>>;
  where?: Maybe<Recording_Data_Ground_Truth_Bool_Exp>;
};


export type Subscription_RootRecording_Data_LabelsArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Labels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Labels_Order_By>>;
  where?: Maybe<Recording_Data_Labels_Bool_Exp>;
};


export type Subscription_RootRecording_Data_Labels_AggregateArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Labels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Labels_Order_By>>;
  where?: Maybe<Recording_Data_Labels_Bool_Exp>;
};


export type Subscription_RootRecording_Data_Labels_By_PkArgs = {
  mode_id: Scalars['uuid'];
  recording_id: Scalars['uuid'];
};


export type Subscription_RootRecording_Data_Labels_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Recording_Data_Labels_Stream_Cursor_Input>>;
  where?: Maybe<Recording_Data_Labels_Bool_Exp>;
};


export type Subscription_RootRecording_Data_Mode_ParameterArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Mode_Parameter_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Mode_Parameter_Order_By>>;
  where?: Maybe<Recording_Data_Mode_Parameter_Bool_Exp>;
};


export type Subscription_RootRecording_Data_Mode_Parameter_AggregateArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Mode_Parameter_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Mode_Parameter_Order_By>>;
  where?: Maybe<Recording_Data_Mode_Parameter_Bool_Exp>;
};


export type Subscription_RootRecording_Data_Mode_Parameter_By_PkArgs = {
  mode_id: Scalars['uuid'];
  parameter_id: Scalars['uuid'];
};


export type Subscription_RootRecording_Data_Mode_Parameter_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Recording_Data_Mode_Parameter_Stream_Cursor_Input>>;
  where?: Maybe<Recording_Data_Mode_Parameter_Bool_Exp>;
};


export type Subscription_RootRecording_Data_ModesArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Modes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Modes_Order_By>>;
  where?: Maybe<Recording_Data_Modes_Bool_Exp>;
};


export type Subscription_RootRecording_Data_Modes_AggregateArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Modes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Modes_Order_By>>;
  where?: Maybe<Recording_Data_Modes_Bool_Exp>;
};


export type Subscription_RootRecording_Data_Modes_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRecording_Data_Modes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Recording_Data_Modes_Stream_Cursor_Input>>;
  where?: Maybe<Recording_Data_Modes_Bool_Exp>;
};


export type Subscription_RootRecording_Data_ParametersArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Parameters_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Parameters_Order_By>>;
  where?: Maybe<Recording_Data_Parameters_Bool_Exp>;
};


export type Subscription_RootRecording_Data_Parameters_AggregateArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Parameters_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Parameters_Order_By>>;
  where?: Maybe<Recording_Data_Parameters_Bool_Exp>;
};


export type Subscription_RootRecording_Data_Parameters_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRecording_Data_Parameters_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Recording_Data_Parameters_Stream_Cursor_Input>>;
  where?: Maybe<Recording_Data_Parameters_Bool_Exp>;
};


export type Subscription_RootRecording_Data_Payload_PairArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Payload_Pair_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Payload_Pair_Order_By>>;
  where?: Maybe<Recording_Data_Payload_Pair_Bool_Exp>;
};


export type Subscription_RootRecording_Data_Payload_Pair_By_PkArgs = {
  payload_id: Scalars['uuid'];
};


export type Subscription_RootRecording_Data_Payload_Pair_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Recording_Data_Payload_Pair_Stream_Cursor_Input>>;
  where?: Maybe<Recording_Data_Payload_Pair_Bool_Exp>;
};


export type Subscription_RootRecording_Data_PredictionsArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Predictions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Predictions_Order_By>>;
  where?: Maybe<Recording_Data_Predictions_Bool_Exp>;
};


export type Subscription_RootRecording_Data_Predictions_AggregateArgs = {
  distinct_on?: Maybe<Array<Recording_Data_Predictions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Data_Predictions_Order_By>>;
  where?: Maybe<Recording_Data_Predictions_Bool_Exp>;
};


export type Subscription_RootRecording_Data_Predictions_By_PkArgs = {
  engine_id: Scalars['uuid'];
  recording_id: Scalars['uuid'];
};


export type Subscription_RootRecording_Data_Predictions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Recording_Data_Predictions_Stream_Cursor_Input>>;
  where?: Maybe<Recording_Data_Predictions_Bool_Exp>;
};


export type Subscription_RootRecording_Recording_TagArgs = {
  distinct_on?: Maybe<Array<Recording_Recording_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Recording_Tag_Order_By>>;
  where?: Maybe<Recording_Recording_Tag_Bool_Exp>;
};


export type Subscription_RootRecording_Recording_Tag_By_PkArgs = {
  recording_id: Scalars['uuid'];
  recording_tag_id: Scalars['uuid'];
};


export type Subscription_RootRecording_Recording_Tag_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Recording_Recording_Tag_Stream_Cursor_Input>>;
  where?: Maybe<Recording_Recording_Tag_Bool_Exp>;
};


export type Subscription_RootRecording_Tag_Count_By_DayArgs = {
  args: Recording_Tag_Count_By_Day_Args;
  distinct_on?: Maybe<Array<Schema_Recording_Tag_Count_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Recording_Tag_Count_By_Day_Order_By>>;
  where?: Maybe<Schema_Recording_Tag_Count_By_Day_Bool_Exp>;
};


export type Subscription_RootRecording_Tag_Count_By_Day_AggregateArgs = {
  args: Recording_Tag_Count_By_Day_Args;
  distinct_on?: Maybe<Array<Schema_Recording_Tag_Count_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Recording_Tag_Count_By_Day_Order_By>>;
  where?: Maybe<Schema_Recording_Tag_Count_By_Day_Bool_Exp>;
};


export type Subscription_RootRecording_TagsArgs = {
  distinct_on?: Maybe<Array<Recording_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Tags_Order_By>>;
  where?: Maybe<Recording_Tags_Bool_Exp>;
};


export type Subscription_RootRecording_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRecording_Tags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Recording_Tags_Stream_Cursor_Input>>;
  where?: Maybe<Recording_Tags_Bool_Exp>;
};


export type Subscription_RootRecordingsArgs = {
  distinct_on?: Maybe<Array<Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recordings_Order_By>>;
  where?: Maybe<Recordings_Bool_Exp>;
};


export type Subscription_RootRecordings_AggregateArgs = {
  distinct_on?: Maybe<Array<Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recordings_Order_By>>;
  where?: Maybe<Recordings_Bool_Exp>;
};


export type Subscription_RootRecordings_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRecordings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Recordings_Stream_Cursor_Input>>;
  where?: Maybe<Recordings_Bool_Exp>;
};


export type Subscription_RootRole_UserArgs = {
  distinct_on?: Maybe<Array<Role_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_User_Order_By>>;
  where?: Maybe<Role_User_Bool_Exp>;
};


export type Subscription_RootRole_User_AggregateArgs = {
  distinct_on?: Maybe<Array<Role_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_User_Order_By>>;
  where?: Maybe<Role_User_Bool_Exp>;
};


export type Subscription_RootRole_User_By_PkArgs = {
  role_id: Roles_Enum;
  user_id: Scalars['uuid'];
};


export type Subscription_RootRole_User_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Role_User_Stream_Cursor_Input>>;
  where?: Maybe<Role_User_Bool_Exp>;
};


export type Subscription_RootRolesArgs = {
  distinct_on?: Maybe<Array<Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Roles_Order_By>>;
  where?: Maybe<Roles_Bool_Exp>;
};


export type Subscription_RootRoles_AggregateArgs = {
  distinct_on?: Maybe<Array<Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Roles_Order_By>>;
  where?: Maybe<Roles_Bool_Exp>;
};


export type Subscription_RootRoles_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRoles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Roles_Stream_Cursor_Input>>;
  where?: Maybe<Roles_Bool_Exp>;
};


export type Subscription_RootRoomsArgs = {
  distinct_on?: Maybe<Array<Rooms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rooms_Order_By>>;
  where?: Maybe<Rooms_Bool_Exp>;
};


export type Subscription_RootRooms_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRooms_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Rooms_Stream_Cursor_Input>>;
  where?: Maybe<Rooms_Bool_Exp>;
};


export type Subscription_RootSchema_Project_Engine_MetricsArgs = {
  distinct_on?: Maybe<Array<Schema_Project_Engine_Metrics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Project_Engine_Metrics_Order_By>>;
  where?: Maybe<Schema_Project_Engine_Metrics_Bool_Exp>;
};


export type Subscription_RootSchema_Project_Engine_Metrics_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Schema_Project_Engine_Metrics_Stream_Cursor_Input>>;
  where?: Maybe<Schema_Project_Engine_Metrics_Bool_Exp>;
};


export type Subscription_RootSchema_Project_Engine_Ratio_MetricsArgs = {
  distinct_on?: Maybe<Array<Schema_Project_Engine_Ratio_Metrics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Project_Engine_Ratio_Metrics_Order_By>>;
  where?: Maybe<Schema_Project_Engine_Ratio_Metrics_Bool_Exp>;
};


export type Subscription_RootSchema_Project_Engine_Ratio_Metrics_AggregateArgs = {
  distinct_on?: Maybe<Array<Schema_Project_Engine_Ratio_Metrics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Project_Engine_Ratio_Metrics_Order_By>>;
  where?: Maybe<Schema_Project_Engine_Ratio_Metrics_Bool_Exp>;
};


export type Subscription_RootSchema_Project_Engine_Ratio_Metrics_By_PkArgs = {
  engine_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
  ratio_metric_id: Scalars['uuid'];
};


export type Subscription_RootSchema_Project_Engine_Ratio_Metrics_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Schema_Project_Engine_Ratio_Metrics_Stream_Cursor_Input>>;
  where?: Maybe<Schema_Project_Engine_Ratio_Metrics_Bool_Exp>;
};


export type Subscription_RootSchema_Project_Task_CountArgs = {
  distinct_on?: Maybe<Array<Schema_Project_Task_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Project_Task_Count_Order_By>>;
  where?: Maybe<Schema_Project_Task_Count_Bool_Exp>;
};


export type Subscription_RootSchema_Project_Task_Count_AggregateArgs = {
  distinct_on?: Maybe<Array<Schema_Project_Task_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Project_Task_Count_Order_By>>;
  where?: Maybe<Schema_Project_Task_Count_Bool_Exp>;
};


export type Subscription_RootSchema_Project_Task_Count_By_DeviceArgs = {
  distinct_on?: Maybe<Array<Schema_Project_Task_Count_By_Device_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Project_Task_Count_By_Device_Order_By>>;
  where?: Maybe<Schema_Project_Task_Count_By_Device_Bool_Exp>;
};


export type Subscription_RootSchema_Project_Task_Count_By_Device_AggregateArgs = {
  distinct_on?: Maybe<Array<Schema_Project_Task_Count_By_Device_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Project_Task_Count_By_Device_Order_By>>;
  where?: Maybe<Schema_Project_Task_Count_By_Device_Bool_Exp>;
};


export type Subscription_RootSchema_Project_Task_Count_By_Device_By_PkArgs = {
  device_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};


export type Subscription_RootSchema_Project_Task_Count_By_Device_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Schema_Project_Task_Count_By_Device_Stream_Cursor_Input>>;
  where?: Maybe<Schema_Project_Task_Count_By_Device_Bool_Exp>;
};


export type Subscription_RootSchema_Project_Task_Count_By_PkArgs = {
  project_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};


export type Subscription_RootSchema_Project_Task_Count_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Schema_Project_Task_Count_Stream_Cursor_Input>>;
  where?: Maybe<Schema_Project_Task_Count_Bool_Exp>;
};


export type Subscription_RootSchema_Recording_Tag_Count_By_DayArgs = {
  distinct_on?: Maybe<Array<Schema_Recording_Tag_Count_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Recording_Tag_Count_By_Day_Order_By>>;
  where?: Maybe<Schema_Recording_Tag_Count_By_Day_Bool_Exp>;
};


export type Subscription_RootSchema_Recording_Tag_Count_By_Day_AggregateArgs = {
  distinct_on?: Maybe<Array<Schema_Recording_Tag_Count_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Schema_Recording_Tag_Count_By_Day_Order_By>>;
  where?: Maybe<Schema_Recording_Tag_Count_By_Day_Bool_Exp>;
};


export type Subscription_RootSchema_Recording_Tag_Count_By_Day_By_PkArgs = {
  day: Scalars['date'];
  project_id: Scalars['uuid'];
  tag_id: Scalars['uuid'];
};


export type Subscription_RootSchema_Recording_Tag_Count_By_Day_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Schema_Recording_Tag_Count_By_Day_Stream_Cursor_Input>>;
  where?: Maybe<Schema_Recording_Tag_Count_By_Day_Bool_Exp>;
};


export type Subscription_RootSimulations_ScoresArgs = {
  distinct_on?: Maybe<Array<Simulations_Scores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Simulations_Scores_Order_By>>;
  where?: Maybe<Simulations_Scores_Bool_Exp>;
};


export type Subscription_RootSimulations_Scores_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSimulations_Scores_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Simulations_Scores_Stream_Cursor_Input>>;
  where?: Maybe<Simulations_Scores_Bool_Exp>;
};


export type Subscription_RootTask_Field_OptionArgs = {
  distinct_on?: Maybe<Array<Task_Field_Option_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Field_Option_Order_By>>;
  where?: Maybe<Task_Field_Option_Bool_Exp>;
};


export type Subscription_RootTask_Field_Option_AggregateArgs = {
  distinct_on?: Maybe<Array<Task_Field_Option_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Field_Option_Order_By>>;
  where?: Maybe<Task_Field_Option_Bool_Exp>;
};


export type Subscription_RootTask_Field_Option_By_PkArgs = {
  field_option_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};


export type Subscription_RootTask_Field_Option_HashesArgs = {
  distinct_on?: Maybe<Array<Task_Field_Option_Hashes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Field_Option_Hashes_Order_By>>;
  where?: Maybe<Task_Field_Option_Hashes_Bool_Exp>;
};


export type Subscription_RootTask_Field_Option_Hashes_AggregateArgs = {
  distinct_on?: Maybe<Array<Task_Field_Option_Hashes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Field_Option_Hashes_Order_By>>;
  where?: Maybe<Task_Field_Option_Hashes_Bool_Exp>;
};


export type Subscription_RootTask_Field_Option_Hashes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Task_Field_Option_Hashes_Stream_Cursor_Input>>;
  where?: Maybe<Task_Field_Option_Hashes_Bool_Exp>;
};


export type Subscription_RootTask_Field_Option_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Task_Field_Option_Stream_Cursor_Input>>;
  where?: Maybe<Task_Field_Option_Bool_Exp>;
};


export type Subscription_RootTask_Groups_Group_FieldsArgs = {
  distinct_on?: Maybe<Array<Task_Groups_Group_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Groups_Group_Fields_Order_By>>;
  where?: Maybe<Task_Groups_Group_Fields_Bool_Exp>;
};


export type Subscription_RootTask_Groups_Group_Fields_AggregateArgs = {
  distinct_on?: Maybe<Array<Task_Groups_Group_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Groups_Group_Fields_Order_By>>;
  where?: Maybe<Task_Groups_Group_Fields_Bool_Exp>;
};


export type Subscription_RootTask_Groups_Group_Fields_By_PkArgs = {
  field_id: Scalars['uuid'];
  group_id: Scalars['uuid'];
};


export type Subscription_RootTask_Groups_Group_Fields_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Task_Groups_Group_Fields_Stream_Cursor_Input>>;
  where?: Maybe<Task_Groups_Group_Fields_Bool_Exp>;
};


export type Subscription_RootTask_Groups_GroupsArgs = {
  distinct_on?: Maybe<Array<Task_Groups_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Groups_Groups_Order_By>>;
  where?: Maybe<Task_Groups_Groups_Bool_Exp>;
};


export type Subscription_RootTask_Groups_Groups_AggregateArgs = {
  distinct_on?: Maybe<Array<Task_Groups_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Groups_Groups_Order_By>>;
  where?: Maybe<Task_Groups_Groups_Bool_Exp>;
};


export type Subscription_RootTask_Groups_Groups_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTask_Groups_Groups_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Task_Groups_Groups_Stream_Cursor_Input>>;
  where?: Maybe<Task_Groups_Groups_Bool_Exp>;
};


export type Subscription_RootTask_Groups_HierarchiesArgs = {
  distinct_on?: Maybe<Array<Task_Groups_Hierarchies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Groups_Hierarchies_Order_By>>;
  where?: Maybe<Task_Groups_Hierarchies_Bool_Exp>;
};


export type Subscription_RootTask_Groups_Hierarchies_AggregateArgs = {
  distinct_on?: Maybe<Array<Task_Groups_Hierarchies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Groups_Hierarchies_Order_By>>;
  where?: Maybe<Task_Groups_Hierarchies_Bool_Exp>;
};


export type Subscription_RootTask_Groups_Hierarchies_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTask_Groups_Hierarchies_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Task_Groups_Hierarchies_Stream_Cursor_Input>>;
  where?: Maybe<Task_Groups_Hierarchies_Bool_Exp>;
};


export type Subscription_RootTask_Groups_Hierarchy_GroupsArgs = {
  distinct_on?: Maybe<Array<Task_Groups_Hierarchy_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Groups_Hierarchy_Groups_Order_By>>;
  where?: Maybe<Task_Groups_Hierarchy_Groups_Bool_Exp>;
};


export type Subscription_RootTask_Groups_Hierarchy_Groups_AggregateArgs = {
  distinct_on?: Maybe<Array<Task_Groups_Hierarchy_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Groups_Hierarchy_Groups_Order_By>>;
  where?: Maybe<Task_Groups_Hierarchy_Groups_Bool_Exp>;
};


export type Subscription_RootTask_Groups_Hierarchy_Groups_By_PkArgs = {
  group_id: Scalars['uuid'];
  hierarchy_id: Scalars['uuid'];
};


export type Subscription_RootTask_Groups_Hierarchy_Groups_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Task_Groups_Hierarchy_Groups_Stream_Cursor_Input>>;
  where?: Maybe<Task_Groups_Hierarchy_Groups_Bool_Exp>;
};


export type Subscription_RootTask_InstructionsArgs = {
  distinct_on?: Maybe<Array<Task_Instructions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Instructions_Order_By>>;
  where?: Maybe<Task_Instructions_Bool_Exp>;
};


export type Subscription_RootTask_Instructions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTask_Instructions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Task_Instructions_Stream_Cursor_Input>>;
  where?: Maybe<Task_Instructions_Bool_Exp>;
};


export type Subscription_RootTask_MediaArgs = {
  distinct_on?: Maybe<Array<Task_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Media_Order_By>>;
  where?: Maybe<Task_Media_Bool_Exp>;
};


export type Subscription_RootTask_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Task_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Media_Order_By>>;
  where?: Maybe<Task_Media_Bool_Exp>;
};


export type Subscription_RootTask_Media_By_PkArgs = {
  media_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};


export type Subscription_RootTask_Media_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Task_Media_Stream_Cursor_Input>>;
  where?: Maybe<Task_Media_Bool_Exp>;
};


export type Subscription_RootTask_StatusesArgs = {
  distinct_on?: Maybe<Array<Task_Statuses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Statuses_Order_By>>;
  where?: Maybe<Task_Statuses_Bool_Exp>;
};


export type Subscription_RootTask_Statuses_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootTask_Statuses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Task_Statuses_Stream_Cursor_Input>>;
  where?: Maybe<Task_Statuses_Bool_Exp>;
};


export type Subscription_RootTask_TranslationArgs = {
  distinct_on?: Maybe<Array<Task_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Translation_Order_By>>;
  where?: Maybe<Task_Translation_Bool_Exp>;
};


export type Subscription_RootTask_Translation_AggregateArgs = {
  distinct_on?: Maybe<Array<Task_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Translation_Order_By>>;
  where?: Maybe<Task_Translation_Bool_Exp>;
};


export type Subscription_RootTask_Translation_By_PkArgs = {
  language: Scalars['String'];
  task_id: Scalars['uuid'];
};


export type Subscription_RootTask_Translation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Task_Translation_Stream_Cursor_Input>>;
  where?: Maybe<Task_Translation_Bool_Exp>;
};


export type Subscription_RootTasksArgs = {
  distinct_on?: Maybe<Array<Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tasks_Order_By>>;
  where?: Maybe<Tasks_Bool_Exp>;
};


export type Subscription_RootTasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tasks_Order_By>>;
  where?: Maybe<Tasks_Bool_Exp>;
};


export type Subscription_RootTasks_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTasks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Tasks_Stream_Cursor_Input>>;
  where?: Maybe<Tasks_Bool_Exp>;
};


export type Subscription_RootTool_VersionsArgs = {
  distinct_on?: Maybe<Array<Tool_Versions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tool_Versions_Order_By>>;
  where?: Maybe<Tool_Versions_Bool_Exp>;
};


export type Subscription_RootTool_Versions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTool_Versions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Tool_Versions_Stream_Cursor_Input>>;
  where?: Maybe<Tool_Versions_Bool_Exp>;
};


export type Subscription_RootToolsArgs = {
  distinct_on?: Maybe<Array<Tools_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tools_Order_By>>;
  where?: Maybe<Tools_Bool_Exp>;
};


export type Subscription_RootTools_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTools_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Tools_Stream_Cursor_Input>>;
  where?: Maybe<Tools_Bool_Exp>;
};


export type Subscription_RootTypesArgs = {
  distinct_on?: Maybe<Array<Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Types_Order_By>>;
  where?: Maybe<Types_Bool_Exp>;
};


export type Subscription_RootTypes_AggregateArgs = {
  distinct_on?: Maybe<Array<Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Types_Order_By>>;
  where?: Maybe<Types_Bool_Exp>;
};


export type Subscription_RootTypes_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTypes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Types_Stream_Cursor_Input>>;
  where?: Maybe<Types_Bool_Exp>;
};


export type Subscription_RootUser_Recordings_By_Day_StatsArgs = {
  distinct_on?: Maybe<Array<User_Recordings_By_Day_Stats_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Recordings_By_Day_Stats_Order_By>>;
  where?: Maybe<User_Recordings_By_Day_Stats_Bool_Exp>;
};


export type Subscription_RootUser_Recordings_By_Day_Stats_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Recordings_By_Day_Stats_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Recordings_By_Day_Stats_Order_By>>;
  where?: Maybe<User_Recordings_By_Day_Stats_Bool_Exp>;
};


export type Subscription_RootUser_Recordings_By_Day_Stats_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<User_Recordings_By_Day_Stats_Stream_Cursor_Input>>;
  where?: Maybe<User_Recordings_By_Day_Stats_Bool_Exp>;
};


export type Subscription_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Users_Stream_Cursor_Input>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Subscription_RootXtensa_ConfigurationsArgs = {
  distinct_on?: Maybe<Array<Xtensa_Configurations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Xtensa_Configurations_Order_By>>;
  where?: Maybe<Xtensa_Configurations_Bool_Exp>;
};


export type Subscription_RootXtensa_Configurations_AggregateArgs = {
  distinct_on?: Maybe<Array<Xtensa_Configurations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Xtensa_Configurations_Order_By>>;
  where?: Maybe<Xtensa_Configurations_Bool_Exp>;
};


export type Subscription_RootXtensa_Configurations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootXtensa_Configurations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Xtensa_Configurations_Stream_Cursor_Input>>;
  where?: Maybe<Xtensa_Configurations_Bool_Exp>;
};

/** columns and relationships of "task_field_option" */
export type Task_Field_Option = {
  __typename?: 'task_field_option';
  /** An object relationship */
  field_option: Field_Options;
  field_option_id: Scalars['uuid'];
  highlight: Scalars['Boolean'];
  /** An object relationship */
  task: Tasks;
  task_id: Scalars['uuid'];
};

/** aggregated selection of "task_field_option" */
export type Task_Field_Option_Aggregate = {
  __typename?: 'task_field_option_aggregate';
  aggregate?: Maybe<Task_Field_Option_Aggregate_Fields>;
  nodes: Array<Task_Field_Option>;
};

/** aggregate fields of "task_field_option" */
export type Task_Field_Option_Aggregate_Fields = {
  __typename?: 'task_field_option_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Task_Field_Option_Max_Fields>;
  min?: Maybe<Task_Field_Option_Min_Fields>;
};


/** aggregate fields of "task_field_option" */
export type Task_Field_Option_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Task_Field_Option_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "task_field_option" */
export type Task_Field_Option_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Task_Field_Option_Max_Order_By>;
  min?: Maybe<Task_Field_Option_Min_Order_By>;
};

/** input type for inserting array relation for remote table "task_field_option" */
export type Task_Field_Option_Arr_Rel_Insert_Input = {
  data: Array<Task_Field_Option_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Task_Field_Option_On_Conflict>;
};

/** Boolean expression to filter rows from the table "task_field_option". All fields are combined with a logical 'AND'. */
export type Task_Field_Option_Bool_Exp = {
  _and?: Maybe<Array<Task_Field_Option_Bool_Exp>>;
  _not?: Maybe<Task_Field_Option_Bool_Exp>;
  _or?: Maybe<Array<Task_Field_Option_Bool_Exp>>;
  field_option?: Maybe<Field_Options_Bool_Exp>;
  field_option_id?: Maybe<Uuid_Comparison_Exp>;
  highlight?: Maybe<Boolean_Comparison_Exp>;
  task?: Maybe<Tasks_Bool_Exp>;
  task_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "task_field_option" */
export enum Task_Field_Option_Constraint {
  /** unique or primary key constraint on columns "field_option_id", "task_id" */
  TaskFieldOptionPkey = 'task_field_option_pkey'
}

/** columns and relationships of "task_field_option_hashes" */
export type Task_Field_Option_Hashes = {
  __typename?: 'task_field_option_hashes';
  md5?: Maybe<Scalars['String']>;
  /** An object relationship */
  task?: Maybe<Tasks>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "task_field_option_hashes" */
export type Task_Field_Option_Hashes_Aggregate = {
  __typename?: 'task_field_option_hashes_aggregate';
  aggregate?: Maybe<Task_Field_Option_Hashes_Aggregate_Fields>;
  nodes: Array<Task_Field_Option_Hashes>;
};

/** aggregate fields of "task_field_option_hashes" */
export type Task_Field_Option_Hashes_Aggregate_Fields = {
  __typename?: 'task_field_option_hashes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Task_Field_Option_Hashes_Max_Fields>;
  min?: Maybe<Task_Field_Option_Hashes_Min_Fields>;
};


/** aggregate fields of "task_field_option_hashes" */
export type Task_Field_Option_Hashes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Task_Field_Option_Hashes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "task_field_option_hashes". All fields are combined with a logical 'AND'. */
export type Task_Field_Option_Hashes_Bool_Exp = {
  _and?: Maybe<Array<Task_Field_Option_Hashes_Bool_Exp>>;
  _not?: Maybe<Task_Field_Option_Hashes_Bool_Exp>;
  _or?: Maybe<Array<Task_Field_Option_Hashes_Bool_Exp>>;
  md5?: Maybe<String_Comparison_Exp>;
  task?: Maybe<Tasks_Bool_Exp>;
  task_id?: Maybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Task_Field_Option_Hashes_Max_Fields = {
  __typename?: 'task_field_option_hashes_max_fields';
  md5?: Maybe<Scalars['String']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Task_Field_Option_Hashes_Min_Fields = {
  __typename?: 'task_field_option_hashes_min_fields';
  md5?: Maybe<Scalars['String']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "task_field_option_hashes". */
export type Task_Field_Option_Hashes_Order_By = {
  md5?: Maybe<Order_By>;
  task?: Maybe<Tasks_Order_By>;
  task_id?: Maybe<Order_By>;
};

/** select columns of table "task_field_option_hashes" */
export enum Task_Field_Option_Hashes_Select_Column {
  /** column name */
  Md5 = 'md5',
  /** column name */
  TaskId = 'task_id'
}

/** Streaming cursor of the table "task_field_option_hashes" */
export type Task_Field_Option_Hashes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Field_Option_Hashes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Field_Option_Hashes_Stream_Cursor_Value_Input = {
  md5?: Maybe<Scalars['String']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** input type for inserting data into table "task_field_option" */
export type Task_Field_Option_Insert_Input = {
  field_option?: Maybe<Field_Options_Obj_Rel_Insert_Input>;
  field_option_id?: Maybe<Scalars['uuid']>;
  highlight?: Maybe<Scalars['Boolean']>;
  task?: Maybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Task_Field_Option_Max_Fields = {
  __typename?: 'task_field_option_max_fields';
  field_option_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "task_field_option" */
export type Task_Field_Option_Max_Order_By = {
  field_option_id?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Task_Field_Option_Min_Fields = {
  __typename?: 'task_field_option_min_fields';
  field_option_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "task_field_option" */
export type Task_Field_Option_Min_Order_By = {
  field_option_id?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "task_field_option" */
export type Task_Field_Option_Mutation_Response = {
  __typename?: 'task_field_option_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Field_Option>;
};

/** on_conflict condition type for table "task_field_option" */
export type Task_Field_Option_On_Conflict = {
  constraint: Task_Field_Option_Constraint;
  update_columns?: Array<Task_Field_Option_Update_Column>;
  where?: Maybe<Task_Field_Option_Bool_Exp>;
};

/** Ordering options when selecting data from "task_field_option". */
export type Task_Field_Option_Order_By = {
  field_option?: Maybe<Field_Options_Order_By>;
  field_option_id?: Maybe<Order_By>;
  highlight?: Maybe<Order_By>;
  task?: Maybe<Tasks_Order_By>;
  task_id?: Maybe<Order_By>;
};

/** primary key columns input for table: task_field_option */
export type Task_Field_Option_Pk_Columns_Input = {
  field_option_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};

/** select columns of table "task_field_option" */
export enum Task_Field_Option_Select_Column {
  /** column name */
  FieldOptionId = 'field_option_id',
  /** column name */
  Highlight = 'highlight',
  /** column name */
  TaskId = 'task_id'
}

/** input type for updating data in table "task_field_option" */
export type Task_Field_Option_Set_Input = {
  field_option_id?: Maybe<Scalars['uuid']>;
  highlight?: Maybe<Scalars['Boolean']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "task_field_option" */
export type Task_Field_Option_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Field_Option_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Field_Option_Stream_Cursor_Value_Input = {
  field_option_id?: Maybe<Scalars['uuid']>;
  highlight?: Maybe<Scalars['Boolean']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "task_field_option" */
export enum Task_Field_Option_Update_Column {
  /** column name */
  FieldOptionId = 'field_option_id',
  /** column name */
  Highlight = 'highlight',
  /** column name */
  TaskId = 'task_id'
}

export type Task_Field_Option_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Task_Field_Option_Set_Input>;
  where: Task_Field_Option_Bool_Exp;
};

/** columns and relationships of "task_groups.group_fields" */
export type Task_Groups_Group_Fields = {
  __typename?: 'task_groups_group_fields';
  /** An object relationship */
  field: Fields;
  field_id: Scalars['uuid'];
  /** An object relationship */
  group: Task_Groups_Groups;
  group_id: Scalars['uuid'];
  position: Scalars['smallint'];
};

/** aggregated selection of "task_groups.group_fields" */
export type Task_Groups_Group_Fields_Aggregate = {
  __typename?: 'task_groups_group_fields_aggregate';
  aggregate?: Maybe<Task_Groups_Group_Fields_Aggregate_Fields>;
  nodes: Array<Task_Groups_Group_Fields>;
};

/** aggregate fields of "task_groups.group_fields" */
export type Task_Groups_Group_Fields_Aggregate_Fields = {
  __typename?: 'task_groups_group_fields_aggregate_fields';
  avg?: Maybe<Task_Groups_Group_Fields_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Task_Groups_Group_Fields_Max_Fields>;
  min?: Maybe<Task_Groups_Group_Fields_Min_Fields>;
  stddev?: Maybe<Task_Groups_Group_Fields_Stddev_Fields>;
  stddev_pop?: Maybe<Task_Groups_Group_Fields_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Task_Groups_Group_Fields_Stddev_Samp_Fields>;
  sum?: Maybe<Task_Groups_Group_Fields_Sum_Fields>;
  var_pop?: Maybe<Task_Groups_Group_Fields_Var_Pop_Fields>;
  var_samp?: Maybe<Task_Groups_Group_Fields_Var_Samp_Fields>;
  variance?: Maybe<Task_Groups_Group_Fields_Variance_Fields>;
};


/** aggregate fields of "task_groups.group_fields" */
export type Task_Groups_Group_Fields_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Task_Groups_Group_Fields_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "task_groups.group_fields" */
export type Task_Groups_Group_Fields_Aggregate_Order_By = {
  avg?: Maybe<Task_Groups_Group_Fields_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Task_Groups_Group_Fields_Max_Order_By>;
  min?: Maybe<Task_Groups_Group_Fields_Min_Order_By>;
  stddev?: Maybe<Task_Groups_Group_Fields_Stddev_Order_By>;
  stddev_pop?: Maybe<Task_Groups_Group_Fields_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Task_Groups_Group_Fields_Stddev_Samp_Order_By>;
  sum?: Maybe<Task_Groups_Group_Fields_Sum_Order_By>;
  var_pop?: Maybe<Task_Groups_Group_Fields_Var_Pop_Order_By>;
  var_samp?: Maybe<Task_Groups_Group_Fields_Var_Samp_Order_By>;
  variance?: Maybe<Task_Groups_Group_Fields_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "task_groups.group_fields" */
export type Task_Groups_Group_Fields_Arr_Rel_Insert_Input = {
  data: Array<Task_Groups_Group_Fields_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Task_Groups_Group_Fields_On_Conflict>;
};

/** aggregate avg on columns */
export type Task_Groups_Group_Fields_Avg_Fields = {
  __typename?: 'task_groups_group_fields_avg_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "task_groups.group_fields" */
export type Task_Groups_Group_Fields_Avg_Order_By = {
  position?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "task_groups.group_fields". All fields are combined with a logical 'AND'. */
export type Task_Groups_Group_Fields_Bool_Exp = {
  _and?: Maybe<Array<Task_Groups_Group_Fields_Bool_Exp>>;
  _not?: Maybe<Task_Groups_Group_Fields_Bool_Exp>;
  _or?: Maybe<Array<Task_Groups_Group_Fields_Bool_Exp>>;
  field?: Maybe<Fields_Bool_Exp>;
  field_id?: Maybe<Uuid_Comparison_Exp>;
  group?: Maybe<Task_Groups_Groups_Bool_Exp>;
  group_id?: Maybe<Uuid_Comparison_Exp>;
  position?: Maybe<Smallint_Comparison_Exp>;
};

/** unique or primary key constraints on table "task_groups.group_fields" */
export enum Task_Groups_Group_Fields_Constraint {
  /** unique or primary key constraint on columns "group_id", "field_id", "position" */
  GroupFieldsGroupIdFieldIdPositionKey = 'group_fields_group_id_field_id_position_key',
  /** unique or primary key constraint on columns "group_id", "field_id" */
  GroupFieldsPkey = 'group_fields_pkey'
}

/** input type for incrementing numeric columns in table "task_groups.group_fields" */
export type Task_Groups_Group_Fields_Inc_Input = {
  position?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "task_groups.group_fields" */
export type Task_Groups_Group_Fields_Insert_Input = {
  field?: Maybe<Fields_Obj_Rel_Insert_Input>;
  field_id?: Maybe<Scalars['uuid']>;
  group?: Maybe<Task_Groups_Groups_Obj_Rel_Insert_Input>;
  group_id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['smallint']>;
};

/** aggregate max on columns */
export type Task_Groups_Group_Fields_Max_Fields = {
  __typename?: 'task_groups_group_fields_max_fields';
  field_id?: Maybe<Scalars['uuid']>;
  group_id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['smallint']>;
};

/** order by max() on columns of table "task_groups.group_fields" */
export type Task_Groups_Group_Fields_Max_Order_By = {
  field_id?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Task_Groups_Group_Fields_Min_Fields = {
  __typename?: 'task_groups_group_fields_min_fields';
  field_id?: Maybe<Scalars['uuid']>;
  group_id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['smallint']>;
};

/** order by min() on columns of table "task_groups.group_fields" */
export type Task_Groups_Group_Fields_Min_Order_By = {
  field_id?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** response of any mutation on the table "task_groups.group_fields" */
export type Task_Groups_Group_Fields_Mutation_Response = {
  __typename?: 'task_groups_group_fields_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Groups_Group_Fields>;
};

/** on_conflict condition type for table "task_groups.group_fields" */
export type Task_Groups_Group_Fields_On_Conflict = {
  constraint: Task_Groups_Group_Fields_Constraint;
  update_columns?: Array<Task_Groups_Group_Fields_Update_Column>;
  where?: Maybe<Task_Groups_Group_Fields_Bool_Exp>;
};

/** Ordering options when selecting data from "task_groups.group_fields". */
export type Task_Groups_Group_Fields_Order_By = {
  field?: Maybe<Fields_Order_By>;
  field_id?: Maybe<Order_By>;
  group?: Maybe<Task_Groups_Groups_Order_By>;
  group_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** primary key columns input for table: task_groups.group_fields */
export type Task_Groups_Group_Fields_Pk_Columns_Input = {
  field_id: Scalars['uuid'];
  group_id: Scalars['uuid'];
};

/** select columns of table "task_groups.group_fields" */
export enum Task_Groups_Group_Fields_Select_Column {
  /** column name */
  FieldId = 'field_id',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Position = 'position'
}

/** input type for updating data in table "task_groups.group_fields" */
export type Task_Groups_Group_Fields_Set_Input = {
  field_id?: Maybe<Scalars['uuid']>;
  group_id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type Task_Groups_Group_Fields_Stddev_Fields = {
  __typename?: 'task_groups_group_fields_stddev_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "task_groups.group_fields" */
export type Task_Groups_Group_Fields_Stddev_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Task_Groups_Group_Fields_Stddev_Pop_Fields = {
  __typename?: 'task_groups_group_fields_stddev_pop_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "task_groups.group_fields" */
export type Task_Groups_Group_Fields_Stddev_Pop_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Task_Groups_Group_Fields_Stddev_Samp_Fields = {
  __typename?: 'task_groups_group_fields_stddev_samp_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "task_groups.group_fields" */
export type Task_Groups_Group_Fields_Stddev_Samp_Order_By = {
  position?: Maybe<Order_By>;
};

/** Streaming cursor of the table "task_groups_group_fields" */
export type Task_Groups_Group_Fields_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Groups_Group_Fields_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Groups_Group_Fields_Stream_Cursor_Value_Input = {
  field_id?: Maybe<Scalars['uuid']>;
  group_id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['smallint']>;
};

/** aggregate sum on columns */
export type Task_Groups_Group_Fields_Sum_Fields = {
  __typename?: 'task_groups_group_fields_sum_fields';
  position?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "task_groups.group_fields" */
export type Task_Groups_Group_Fields_Sum_Order_By = {
  position?: Maybe<Order_By>;
};

/** update columns of table "task_groups.group_fields" */
export enum Task_Groups_Group_Fields_Update_Column {
  /** column name */
  FieldId = 'field_id',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Position = 'position'
}

export type Task_Groups_Group_Fields_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Task_Groups_Group_Fields_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Task_Groups_Group_Fields_Set_Input>;
  where: Task_Groups_Group_Fields_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Task_Groups_Group_Fields_Var_Pop_Fields = {
  __typename?: 'task_groups_group_fields_var_pop_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "task_groups.group_fields" */
export type Task_Groups_Group_Fields_Var_Pop_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Task_Groups_Group_Fields_Var_Samp_Fields = {
  __typename?: 'task_groups_group_fields_var_samp_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "task_groups.group_fields" */
export type Task_Groups_Group_Fields_Var_Samp_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Task_Groups_Group_Fields_Variance_Fields = {
  __typename?: 'task_groups_group_fields_variance_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "task_groups.group_fields" */
export type Task_Groups_Group_Fields_Variance_Order_By = {
  position?: Maybe<Order_By>;
};

/** columns and relationships of "task_groups.groups" */
export type Task_Groups_Groups = {
  __typename?: 'task_groups_groups';
  /** An array relationship */
  group_fields: Array<Task_Groups_Group_Fields>;
  /** An aggregate relationship */
  group_fields_aggregate: Task_Groups_Group_Fields_Aggregate;
  /** An array relationship */
  hierarchy_groups: Array<Task_Groups_Hierarchy_Groups>;
  /** An aggregate relationship */
  hierarchy_groups_aggregate: Task_Groups_Hierarchy_Groups_Aggregate;
  id: Scalars['uuid'];
  name: Scalars['String'];
};


/** columns and relationships of "task_groups.groups" */
export type Task_Groups_GroupsGroup_FieldsArgs = {
  distinct_on?: Maybe<Array<Task_Groups_Group_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Groups_Group_Fields_Order_By>>;
  where?: Maybe<Task_Groups_Group_Fields_Bool_Exp>;
};


/** columns and relationships of "task_groups.groups" */
export type Task_Groups_GroupsGroup_Fields_AggregateArgs = {
  distinct_on?: Maybe<Array<Task_Groups_Group_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Groups_Group_Fields_Order_By>>;
  where?: Maybe<Task_Groups_Group_Fields_Bool_Exp>;
};


/** columns and relationships of "task_groups.groups" */
export type Task_Groups_GroupsHierarchy_GroupsArgs = {
  distinct_on?: Maybe<Array<Task_Groups_Hierarchy_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Groups_Hierarchy_Groups_Order_By>>;
  where?: Maybe<Task_Groups_Hierarchy_Groups_Bool_Exp>;
};


/** columns and relationships of "task_groups.groups" */
export type Task_Groups_GroupsHierarchy_Groups_AggregateArgs = {
  distinct_on?: Maybe<Array<Task_Groups_Hierarchy_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Groups_Hierarchy_Groups_Order_By>>;
  where?: Maybe<Task_Groups_Hierarchy_Groups_Bool_Exp>;
};

/** aggregated selection of "task_groups.groups" */
export type Task_Groups_Groups_Aggregate = {
  __typename?: 'task_groups_groups_aggregate';
  aggregate?: Maybe<Task_Groups_Groups_Aggregate_Fields>;
  nodes: Array<Task_Groups_Groups>;
};

/** aggregate fields of "task_groups.groups" */
export type Task_Groups_Groups_Aggregate_Fields = {
  __typename?: 'task_groups_groups_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Task_Groups_Groups_Max_Fields>;
  min?: Maybe<Task_Groups_Groups_Min_Fields>;
};


/** aggregate fields of "task_groups.groups" */
export type Task_Groups_Groups_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Task_Groups_Groups_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "task_groups.groups". All fields are combined with a logical 'AND'. */
export type Task_Groups_Groups_Bool_Exp = {
  _and?: Maybe<Array<Task_Groups_Groups_Bool_Exp>>;
  _not?: Maybe<Task_Groups_Groups_Bool_Exp>;
  _or?: Maybe<Array<Task_Groups_Groups_Bool_Exp>>;
  group_fields?: Maybe<Task_Groups_Group_Fields_Bool_Exp>;
  hierarchy_groups?: Maybe<Task_Groups_Hierarchy_Groups_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "task_groups.groups" */
export enum Task_Groups_Groups_Constraint {
  /** unique or primary key constraint on columns "id" */
  GroupsPkey = 'groups_pkey'
}

/** input type for inserting data into table "task_groups.groups" */
export type Task_Groups_Groups_Insert_Input = {
  group_fields?: Maybe<Task_Groups_Group_Fields_Arr_Rel_Insert_Input>;
  hierarchy_groups?: Maybe<Task_Groups_Hierarchy_Groups_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Task_Groups_Groups_Max_Fields = {
  __typename?: 'task_groups_groups_max_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Task_Groups_Groups_Min_Fields = {
  __typename?: 'task_groups_groups_min_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "task_groups.groups" */
export type Task_Groups_Groups_Mutation_Response = {
  __typename?: 'task_groups_groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Groups_Groups>;
};

/** input type for inserting object relation for remote table "task_groups.groups" */
export type Task_Groups_Groups_Obj_Rel_Insert_Input = {
  data: Task_Groups_Groups_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Task_Groups_Groups_On_Conflict>;
};

/** on_conflict condition type for table "task_groups.groups" */
export type Task_Groups_Groups_On_Conflict = {
  constraint: Task_Groups_Groups_Constraint;
  update_columns?: Array<Task_Groups_Groups_Update_Column>;
  where?: Maybe<Task_Groups_Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "task_groups.groups". */
export type Task_Groups_Groups_Order_By = {
  group_fields_aggregate?: Maybe<Task_Groups_Group_Fields_Aggregate_Order_By>;
  hierarchy_groups_aggregate?: Maybe<Task_Groups_Hierarchy_Groups_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: task_groups.groups */
export type Task_Groups_Groups_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "task_groups.groups" */
export enum Task_Groups_Groups_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "task_groups.groups" */
export type Task_Groups_Groups_Set_Input = {
  name?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "task_groups_groups" */
export type Task_Groups_Groups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Groups_Groups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Groups_Groups_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "task_groups.groups" */
export enum Task_Groups_Groups_Update_Column {
  /** column name */
  Name = 'name'
}

export type Task_Groups_Groups_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Task_Groups_Groups_Set_Input>;
  where: Task_Groups_Groups_Bool_Exp;
};

/** columns and relationships of "task_groups.hierarchies" */
export type Task_Groups_Hierarchies = {
  __typename?: 'task_groups_hierarchies';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  hierarchy_groups: Array<Task_Groups_Hierarchy_Groups>;
  /** An aggregate relationship */
  hierarchy_groups_aggregate: Task_Groups_Hierarchy_Groups_Aggregate;
  id: Scalars['uuid'];
  name: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "task_groups.hierarchies" */
export type Task_Groups_HierarchiesHierarchy_GroupsArgs = {
  distinct_on?: Maybe<Array<Task_Groups_Hierarchy_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Groups_Hierarchy_Groups_Order_By>>;
  where?: Maybe<Task_Groups_Hierarchy_Groups_Bool_Exp>;
};


/** columns and relationships of "task_groups.hierarchies" */
export type Task_Groups_HierarchiesHierarchy_Groups_AggregateArgs = {
  distinct_on?: Maybe<Array<Task_Groups_Hierarchy_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Groups_Hierarchy_Groups_Order_By>>;
  where?: Maybe<Task_Groups_Hierarchy_Groups_Bool_Exp>;
};

/** aggregated selection of "task_groups.hierarchies" */
export type Task_Groups_Hierarchies_Aggregate = {
  __typename?: 'task_groups_hierarchies_aggregate';
  aggregate?: Maybe<Task_Groups_Hierarchies_Aggregate_Fields>;
  nodes: Array<Task_Groups_Hierarchies>;
};

/** aggregate fields of "task_groups.hierarchies" */
export type Task_Groups_Hierarchies_Aggregate_Fields = {
  __typename?: 'task_groups_hierarchies_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Task_Groups_Hierarchies_Max_Fields>;
  min?: Maybe<Task_Groups_Hierarchies_Min_Fields>;
};


/** aggregate fields of "task_groups.hierarchies" */
export type Task_Groups_Hierarchies_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Task_Groups_Hierarchies_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "task_groups.hierarchies". All fields are combined with a logical 'AND'. */
export type Task_Groups_Hierarchies_Bool_Exp = {
  _and?: Maybe<Array<Task_Groups_Hierarchies_Bool_Exp>>;
  _not?: Maybe<Task_Groups_Hierarchies_Bool_Exp>;
  _or?: Maybe<Array<Task_Groups_Hierarchies_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  hierarchy_groups?: Maybe<Task_Groups_Hierarchy_Groups_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "task_groups.hierarchies" */
export enum Task_Groups_Hierarchies_Constraint {
  /** unique or primary key constraint on columns "name" */
  HierarchiesNameKey = 'hierarchies_name_key',
  /** unique or primary key constraint on columns "id" */
  HierarchiesPkey = 'hierarchies_pkey'
}

/** input type for inserting data into table "task_groups.hierarchies" */
export type Task_Groups_Hierarchies_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  hierarchy_groups?: Maybe<Task_Groups_Hierarchy_Groups_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Task_Groups_Hierarchies_Max_Fields = {
  __typename?: 'task_groups_hierarchies_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Task_Groups_Hierarchies_Min_Fields = {
  __typename?: 'task_groups_hierarchies_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "task_groups.hierarchies" */
export type Task_Groups_Hierarchies_Mutation_Response = {
  __typename?: 'task_groups_hierarchies_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Groups_Hierarchies>;
};

/** input type for inserting object relation for remote table "task_groups.hierarchies" */
export type Task_Groups_Hierarchies_Obj_Rel_Insert_Input = {
  data: Task_Groups_Hierarchies_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Task_Groups_Hierarchies_On_Conflict>;
};

/** on_conflict condition type for table "task_groups.hierarchies" */
export type Task_Groups_Hierarchies_On_Conflict = {
  constraint: Task_Groups_Hierarchies_Constraint;
  update_columns?: Array<Task_Groups_Hierarchies_Update_Column>;
  where?: Maybe<Task_Groups_Hierarchies_Bool_Exp>;
};

/** Ordering options when selecting data from "task_groups.hierarchies". */
export type Task_Groups_Hierarchies_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  hierarchy_groups_aggregate?: Maybe<Task_Groups_Hierarchy_Groups_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: task_groups.hierarchies */
export type Task_Groups_Hierarchies_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "task_groups.hierarchies" */
export enum Task_Groups_Hierarchies_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "task_groups.hierarchies" */
export type Task_Groups_Hierarchies_Set_Input = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "task_groups_hierarchies" */
export type Task_Groups_Hierarchies_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Groups_Hierarchies_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Groups_Hierarchies_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "task_groups.hierarchies" */
export enum Task_Groups_Hierarchies_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

export type Task_Groups_Hierarchies_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Task_Groups_Hierarchies_Set_Input>;
  where: Task_Groups_Hierarchies_Bool_Exp;
};

/** columns and relationships of "task_groups.hierarchy_groups" */
export type Task_Groups_Hierarchy_Groups = {
  __typename?: 'task_groups_hierarchy_groups';
  /** An object relationship */
  group: Task_Groups_Groups;
  group_id: Scalars['uuid'];
  /** An object relationship */
  hierarchy: Task_Groups_Hierarchies;
  hierarchy_id: Scalars['uuid'];
  position: Scalars['smallint'];
};

/** aggregated selection of "task_groups.hierarchy_groups" */
export type Task_Groups_Hierarchy_Groups_Aggregate = {
  __typename?: 'task_groups_hierarchy_groups_aggregate';
  aggregate?: Maybe<Task_Groups_Hierarchy_Groups_Aggregate_Fields>;
  nodes: Array<Task_Groups_Hierarchy_Groups>;
};

/** aggregate fields of "task_groups.hierarchy_groups" */
export type Task_Groups_Hierarchy_Groups_Aggregate_Fields = {
  __typename?: 'task_groups_hierarchy_groups_aggregate_fields';
  avg?: Maybe<Task_Groups_Hierarchy_Groups_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Task_Groups_Hierarchy_Groups_Max_Fields>;
  min?: Maybe<Task_Groups_Hierarchy_Groups_Min_Fields>;
  stddev?: Maybe<Task_Groups_Hierarchy_Groups_Stddev_Fields>;
  stddev_pop?: Maybe<Task_Groups_Hierarchy_Groups_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Task_Groups_Hierarchy_Groups_Stddev_Samp_Fields>;
  sum?: Maybe<Task_Groups_Hierarchy_Groups_Sum_Fields>;
  var_pop?: Maybe<Task_Groups_Hierarchy_Groups_Var_Pop_Fields>;
  var_samp?: Maybe<Task_Groups_Hierarchy_Groups_Var_Samp_Fields>;
  variance?: Maybe<Task_Groups_Hierarchy_Groups_Variance_Fields>;
};


/** aggregate fields of "task_groups.hierarchy_groups" */
export type Task_Groups_Hierarchy_Groups_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Task_Groups_Hierarchy_Groups_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "task_groups.hierarchy_groups" */
export type Task_Groups_Hierarchy_Groups_Aggregate_Order_By = {
  avg?: Maybe<Task_Groups_Hierarchy_Groups_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Task_Groups_Hierarchy_Groups_Max_Order_By>;
  min?: Maybe<Task_Groups_Hierarchy_Groups_Min_Order_By>;
  stddev?: Maybe<Task_Groups_Hierarchy_Groups_Stddev_Order_By>;
  stddev_pop?: Maybe<Task_Groups_Hierarchy_Groups_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Task_Groups_Hierarchy_Groups_Stddev_Samp_Order_By>;
  sum?: Maybe<Task_Groups_Hierarchy_Groups_Sum_Order_By>;
  var_pop?: Maybe<Task_Groups_Hierarchy_Groups_Var_Pop_Order_By>;
  var_samp?: Maybe<Task_Groups_Hierarchy_Groups_Var_Samp_Order_By>;
  variance?: Maybe<Task_Groups_Hierarchy_Groups_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "task_groups.hierarchy_groups" */
export type Task_Groups_Hierarchy_Groups_Arr_Rel_Insert_Input = {
  data: Array<Task_Groups_Hierarchy_Groups_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Task_Groups_Hierarchy_Groups_On_Conflict>;
};

/** aggregate avg on columns */
export type Task_Groups_Hierarchy_Groups_Avg_Fields = {
  __typename?: 'task_groups_hierarchy_groups_avg_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "task_groups.hierarchy_groups" */
export type Task_Groups_Hierarchy_Groups_Avg_Order_By = {
  position?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "task_groups.hierarchy_groups". All fields are combined with a logical 'AND'. */
export type Task_Groups_Hierarchy_Groups_Bool_Exp = {
  _and?: Maybe<Array<Task_Groups_Hierarchy_Groups_Bool_Exp>>;
  _not?: Maybe<Task_Groups_Hierarchy_Groups_Bool_Exp>;
  _or?: Maybe<Array<Task_Groups_Hierarchy_Groups_Bool_Exp>>;
  group?: Maybe<Task_Groups_Groups_Bool_Exp>;
  group_id?: Maybe<Uuid_Comparison_Exp>;
  hierarchy?: Maybe<Task_Groups_Hierarchies_Bool_Exp>;
  hierarchy_id?: Maybe<Uuid_Comparison_Exp>;
  position?: Maybe<Smallint_Comparison_Exp>;
};

/** unique or primary key constraints on table "task_groups.hierarchy_groups" */
export enum Task_Groups_Hierarchy_Groups_Constraint {
  /** unique or primary key constraint on columns "hierarchy_id", "position" */
  HierarchyGroupsHierarchyIdPositionKey = 'hierarchy_groups_hierarchy_id_position_key',
  /** unique or primary key constraint on columns "group_id", "hierarchy_id" */
  HierarchyGroupsPkey = 'hierarchy_groups_pkey'
}

/** input type for incrementing numeric columns in table "task_groups.hierarchy_groups" */
export type Task_Groups_Hierarchy_Groups_Inc_Input = {
  position?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "task_groups.hierarchy_groups" */
export type Task_Groups_Hierarchy_Groups_Insert_Input = {
  group?: Maybe<Task_Groups_Groups_Obj_Rel_Insert_Input>;
  group_id?: Maybe<Scalars['uuid']>;
  hierarchy?: Maybe<Task_Groups_Hierarchies_Obj_Rel_Insert_Input>;
  hierarchy_id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['smallint']>;
};

/** aggregate max on columns */
export type Task_Groups_Hierarchy_Groups_Max_Fields = {
  __typename?: 'task_groups_hierarchy_groups_max_fields';
  group_id?: Maybe<Scalars['uuid']>;
  hierarchy_id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['smallint']>;
};

/** order by max() on columns of table "task_groups.hierarchy_groups" */
export type Task_Groups_Hierarchy_Groups_Max_Order_By = {
  group_id?: Maybe<Order_By>;
  hierarchy_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Task_Groups_Hierarchy_Groups_Min_Fields = {
  __typename?: 'task_groups_hierarchy_groups_min_fields';
  group_id?: Maybe<Scalars['uuid']>;
  hierarchy_id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['smallint']>;
};

/** order by min() on columns of table "task_groups.hierarchy_groups" */
export type Task_Groups_Hierarchy_Groups_Min_Order_By = {
  group_id?: Maybe<Order_By>;
  hierarchy_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** response of any mutation on the table "task_groups.hierarchy_groups" */
export type Task_Groups_Hierarchy_Groups_Mutation_Response = {
  __typename?: 'task_groups_hierarchy_groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Groups_Hierarchy_Groups>;
};

/** on_conflict condition type for table "task_groups.hierarchy_groups" */
export type Task_Groups_Hierarchy_Groups_On_Conflict = {
  constraint: Task_Groups_Hierarchy_Groups_Constraint;
  update_columns?: Array<Task_Groups_Hierarchy_Groups_Update_Column>;
  where?: Maybe<Task_Groups_Hierarchy_Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "task_groups.hierarchy_groups". */
export type Task_Groups_Hierarchy_Groups_Order_By = {
  group?: Maybe<Task_Groups_Groups_Order_By>;
  group_id?: Maybe<Order_By>;
  hierarchy?: Maybe<Task_Groups_Hierarchies_Order_By>;
  hierarchy_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** primary key columns input for table: task_groups.hierarchy_groups */
export type Task_Groups_Hierarchy_Groups_Pk_Columns_Input = {
  group_id: Scalars['uuid'];
  hierarchy_id: Scalars['uuid'];
};

/** select columns of table "task_groups.hierarchy_groups" */
export enum Task_Groups_Hierarchy_Groups_Select_Column {
  /** column name */
  GroupId = 'group_id',
  /** column name */
  HierarchyId = 'hierarchy_id',
  /** column name */
  Position = 'position'
}

/** input type for updating data in table "task_groups.hierarchy_groups" */
export type Task_Groups_Hierarchy_Groups_Set_Input = {
  group_id?: Maybe<Scalars['uuid']>;
  hierarchy_id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type Task_Groups_Hierarchy_Groups_Stddev_Fields = {
  __typename?: 'task_groups_hierarchy_groups_stddev_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "task_groups.hierarchy_groups" */
export type Task_Groups_Hierarchy_Groups_Stddev_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Task_Groups_Hierarchy_Groups_Stddev_Pop_Fields = {
  __typename?: 'task_groups_hierarchy_groups_stddev_pop_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "task_groups.hierarchy_groups" */
export type Task_Groups_Hierarchy_Groups_Stddev_Pop_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Task_Groups_Hierarchy_Groups_Stddev_Samp_Fields = {
  __typename?: 'task_groups_hierarchy_groups_stddev_samp_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "task_groups.hierarchy_groups" */
export type Task_Groups_Hierarchy_Groups_Stddev_Samp_Order_By = {
  position?: Maybe<Order_By>;
};

/** Streaming cursor of the table "task_groups_hierarchy_groups" */
export type Task_Groups_Hierarchy_Groups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Groups_Hierarchy_Groups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Groups_Hierarchy_Groups_Stream_Cursor_Value_Input = {
  group_id?: Maybe<Scalars['uuid']>;
  hierarchy_id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['smallint']>;
};

/** aggregate sum on columns */
export type Task_Groups_Hierarchy_Groups_Sum_Fields = {
  __typename?: 'task_groups_hierarchy_groups_sum_fields';
  position?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "task_groups.hierarchy_groups" */
export type Task_Groups_Hierarchy_Groups_Sum_Order_By = {
  position?: Maybe<Order_By>;
};

/** update columns of table "task_groups.hierarchy_groups" */
export enum Task_Groups_Hierarchy_Groups_Update_Column {
  /** column name */
  GroupId = 'group_id',
  /** column name */
  HierarchyId = 'hierarchy_id',
  /** column name */
  Position = 'position'
}

export type Task_Groups_Hierarchy_Groups_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Task_Groups_Hierarchy_Groups_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Task_Groups_Hierarchy_Groups_Set_Input>;
  where: Task_Groups_Hierarchy_Groups_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Task_Groups_Hierarchy_Groups_Var_Pop_Fields = {
  __typename?: 'task_groups_hierarchy_groups_var_pop_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "task_groups.hierarchy_groups" */
export type Task_Groups_Hierarchy_Groups_Var_Pop_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Task_Groups_Hierarchy_Groups_Var_Samp_Fields = {
  __typename?: 'task_groups_hierarchy_groups_var_samp_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "task_groups.hierarchy_groups" */
export type Task_Groups_Hierarchy_Groups_Var_Samp_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Task_Groups_Hierarchy_Groups_Variance_Fields = {
  __typename?: 'task_groups_hierarchy_groups_variance_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "task_groups.hierarchy_groups" */
export type Task_Groups_Hierarchy_Groups_Variance_Order_By = {
  position?: Maybe<Order_By>;
};

/** columns and relationships of "task_instructions" */
export type Task_Instructions = {
  __typename?: 'task_instructions';
  content?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An array relationship */
  instruction_translations: Array<Instruction_Translations>;
  status?: Maybe<Scalars['String']>;
  /** An object relationship */
  task: Tasks;
  task_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
};


/** columns and relationships of "task_instructions" */
export type Task_InstructionsInstruction_TranslationsArgs = {
  distinct_on?: Maybe<Array<Instruction_Translations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Instruction_Translations_Order_By>>;
  where?: Maybe<Instruction_Translations_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "task_instructions". All fields are combined with a logical 'AND'. */
export type Task_Instructions_Bool_Exp = {
  _and?: Maybe<Array<Task_Instructions_Bool_Exp>>;
  _not?: Maybe<Task_Instructions_Bool_Exp>;
  _or?: Maybe<Array<Task_Instructions_Bool_Exp>>;
  content?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  instruction_translations?: Maybe<Instruction_Translations_Bool_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  task?: Maybe<Tasks_Bool_Exp>;
  task_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "task_instructions" */
export enum Task_Instructions_Constraint {
  /** unique or primary key constraint on columns "id" */
  TaskInstructionsPkey = 'task_instructions_pkey',
  /** unique or primary key constraint on columns "task_id" */
  TaskInstructionsTaskIdKey = 'task_instructions_task_id_key'
}

/** input type for inserting data into table "task_instructions" */
export type Task_Instructions_Insert_Input = {
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  instruction_translations?: Maybe<Instruction_Translations_Arr_Rel_Insert_Input>;
  status?: Maybe<Scalars['String']>;
  task?: Maybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
};

/** response of any mutation on the table "task_instructions" */
export type Task_Instructions_Mutation_Response = {
  __typename?: 'task_instructions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Instructions>;
};

/** input type for inserting object relation for remote table "task_instructions" */
export type Task_Instructions_Obj_Rel_Insert_Input = {
  data: Task_Instructions_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Task_Instructions_On_Conflict>;
};

/** on_conflict condition type for table "task_instructions" */
export type Task_Instructions_On_Conflict = {
  constraint: Task_Instructions_Constraint;
  update_columns?: Array<Task_Instructions_Update_Column>;
  where?: Maybe<Task_Instructions_Bool_Exp>;
};

/** Ordering options when selecting data from "task_instructions". */
export type Task_Instructions_Order_By = {
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  instruction_translations_aggregate?: Maybe<Instruction_Translations_Aggregate_Order_By>;
  status?: Maybe<Order_By>;
  task?: Maybe<Tasks_Order_By>;
  task_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
};

/** primary key columns input for table: task_instructions */
export type Task_Instructions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "task_instructions" */
export enum Task_Instructions_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "task_instructions" */
export type Task_Instructions_Set_Input = {
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  task_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "task_instructions" */
export type Task_Instructions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Instructions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Instructions_Stream_Cursor_Value_Input = {
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  task_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "task_instructions" */
export enum Task_Instructions_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Task_Instructions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Task_Instructions_Set_Input>;
  where: Task_Instructions_Bool_Exp;
};

/** columns and relationships of "task_media" */
export type Task_Media = {
  __typename?: 'task_media';
  /** An object relationship */
  media: Media;
  media_id: Scalars['uuid'];
  /** An object relationship */
  task: Tasks;
  task_id: Scalars['uuid'];
};

/** aggregated selection of "task_media" */
export type Task_Media_Aggregate = {
  __typename?: 'task_media_aggregate';
  aggregate?: Maybe<Task_Media_Aggregate_Fields>;
  nodes: Array<Task_Media>;
};

/** aggregate fields of "task_media" */
export type Task_Media_Aggregate_Fields = {
  __typename?: 'task_media_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Task_Media_Max_Fields>;
  min?: Maybe<Task_Media_Min_Fields>;
};


/** aggregate fields of "task_media" */
export type Task_Media_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Task_Media_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "task_media" */
export type Task_Media_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Task_Media_Max_Order_By>;
  min?: Maybe<Task_Media_Min_Order_By>;
};

/** input type for inserting array relation for remote table "task_media" */
export type Task_Media_Arr_Rel_Insert_Input = {
  data: Array<Task_Media_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Task_Media_On_Conflict>;
};

/** Boolean expression to filter rows from the table "task_media". All fields are combined with a logical 'AND'. */
export type Task_Media_Bool_Exp = {
  _and?: Maybe<Array<Task_Media_Bool_Exp>>;
  _not?: Maybe<Task_Media_Bool_Exp>;
  _or?: Maybe<Array<Task_Media_Bool_Exp>>;
  media?: Maybe<Media_Bool_Exp>;
  media_id?: Maybe<Uuid_Comparison_Exp>;
  task?: Maybe<Tasks_Bool_Exp>;
  task_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "task_media" */
export enum Task_Media_Constraint {
  /** unique or primary key constraint on columns "media_id", "task_id" */
  TaskMediaPkey = 'task_media_pkey'
}

/** input type for inserting data into table "task_media" */
export type Task_Media_Insert_Input = {
  media?: Maybe<Media_Obj_Rel_Insert_Input>;
  media_id?: Maybe<Scalars['uuid']>;
  task?: Maybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Task_Media_Max_Fields = {
  __typename?: 'task_media_max_fields';
  media_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "task_media" */
export type Task_Media_Max_Order_By = {
  media_id?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Task_Media_Min_Fields = {
  __typename?: 'task_media_min_fields';
  media_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "task_media" */
export type Task_Media_Min_Order_By = {
  media_id?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "task_media" */
export type Task_Media_Mutation_Response = {
  __typename?: 'task_media_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Media>;
};

/** on_conflict condition type for table "task_media" */
export type Task_Media_On_Conflict = {
  constraint: Task_Media_Constraint;
  update_columns?: Array<Task_Media_Update_Column>;
  where?: Maybe<Task_Media_Bool_Exp>;
};

/** Ordering options when selecting data from "task_media". */
export type Task_Media_Order_By = {
  media?: Maybe<Media_Order_By>;
  media_id?: Maybe<Order_By>;
  task?: Maybe<Tasks_Order_By>;
  task_id?: Maybe<Order_By>;
};

/** primary key columns input for table: task_media */
export type Task_Media_Pk_Columns_Input = {
  media_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};

/** select columns of table "task_media" */
export enum Task_Media_Select_Column {
  /** column name */
  MediaId = 'media_id',
  /** column name */
  TaskId = 'task_id'
}

/** input type for updating data in table "task_media" */
export type Task_Media_Set_Input = {
  media_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "task_media" */
export type Task_Media_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Media_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Media_Stream_Cursor_Value_Input = {
  media_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "task_media" */
export enum Task_Media_Update_Column {
  /** column name */
  MediaId = 'media_id',
  /** column name */
  TaskId = 'task_id'
}

export type Task_Media_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Task_Media_Set_Input>;
  where: Task_Media_Bool_Exp;
};

/** Describe the status of an external task (such as an API call), not a collection task. */
export type Task_Statuses = {
  __typename?: 'task_statuses';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

/** Boolean expression to filter rows from the table "task_statuses". All fields are combined with a logical 'AND'. */
export type Task_Statuses_Bool_Exp = {
  _and?: Maybe<Array<Task_Statuses_Bool_Exp>>;
  _not?: Maybe<Task_Statuses_Bool_Exp>;
  _or?: Maybe<Array<Task_Statuses_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "task_statuses". */
export type Task_Statuses_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** select columns of table "task_statuses" */
export enum Task_Statuses_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** Streaming cursor of the table "task_statuses" */
export type Task_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Statuses_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** columns and relationships of "task_translation" */
export type Task_Translation = {
  __typename?: 'task_translation';
  language: Scalars['String'];
  note: Scalars['String'];
  /** An object relationship */
  task: Tasks;
  task_id: Scalars['uuid'];
  /** An object relationship */
  task_translation_language: Languages;
};

/** aggregated selection of "task_translation" */
export type Task_Translation_Aggregate = {
  __typename?: 'task_translation_aggregate';
  aggregate?: Maybe<Task_Translation_Aggregate_Fields>;
  nodes: Array<Task_Translation>;
};

/** aggregate fields of "task_translation" */
export type Task_Translation_Aggregate_Fields = {
  __typename?: 'task_translation_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Task_Translation_Max_Fields>;
  min?: Maybe<Task_Translation_Min_Fields>;
};


/** aggregate fields of "task_translation" */
export type Task_Translation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Task_Translation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "task_translation" */
export type Task_Translation_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Task_Translation_Max_Order_By>;
  min?: Maybe<Task_Translation_Min_Order_By>;
};

/** input type for inserting array relation for remote table "task_translation" */
export type Task_Translation_Arr_Rel_Insert_Input = {
  data: Array<Task_Translation_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Task_Translation_On_Conflict>;
};

/** Boolean expression to filter rows from the table "task_translation". All fields are combined with a logical 'AND'. */
export type Task_Translation_Bool_Exp = {
  _and?: Maybe<Array<Task_Translation_Bool_Exp>>;
  _not?: Maybe<Task_Translation_Bool_Exp>;
  _or?: Maybe<Array<Task_Translation_Bool_Exp>>;
  language?: Maybe<String_Comparison_Exp>;
  note?: Maybe<String_Comparison_Exp>;
  task?: Maybe<Tasks_Bool_Exp>;
  task_id?: Maybe<Uuid_Comparison_Exp>;
  task_translation_language?: Maybe<Languages_Bool_Exp>;
};

/** unique or primary key constraints on table "task_translation" */
export enum Task_Translation_Constraint {
  /** unique or primary key constraint on columns "language", "task_id" */
  TaskTranslationPkey = 'task_translation_pkey'
}

/** input type for inserting data into table "task_translation" */
export type Task_Translation_Insert_Input = {
  language?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  task?: Maybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Task_Translation_Max_Fields = {
  __typename?: 'task_translation_max_fields';
  language?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "task_translation" */
export type Task_Translation_Max_Order_By = {
  language?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Task_Translation_Min_Fields = {
  __typename?: 'task_translation_min_fields';
  language?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "task_translation" */
export type Task_Translation_Min_Order_By = {
  language?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "task_translation" */
export type Task_Translation_Mutation_Response = {
  __typename?: 'task_translation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Translation>;
};

/** on_conflict condition type for table "task_translation" */
export type Task_Translation_On_Conflict = {
  constraint: Task_Translation_Constraint;
  update_columns?: Array<Task_Translation_Update_Column>;
  where?: Maybe<Task_Translation_Bool_Exp>;
};

/** Ordering options when selecting data from "task_translation". */
export type Task_Translation_Order_By = {
  language?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  task?: Maybe<Tasks_Order_By>;
  task_id?: Maybe<Order_By>;
  task_translation_language?: Maybe<Languages_Order_By>;
};

/** primary key columns input for table: task_translation */
export type Task_Translation_Pk_Columns_Input = {
  language: Scalars['String'];
  task_id: Scalars['uuid'];
};

/** select columns of table "task_translation" */
export enum Task_Translation_Select_Column {
  /** column name */
  Language = 'language',
  /** column name */
  Note = 'note',
  /** column name */
  TaskId = 'task_id'
}

/** input type for updating data in table "task_translation" */
export type Task_Translation_Set_Input = {
  language?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "task_translation" */
export type Task_Translation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Translation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Translation_Stream_Cursor_Value_Input = {
  language?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "task_translation" */
export enum Task_Translation_Update_Column {
  /** column name */
  Language = 'language',
  /** column name */
  Note = 'note',
  /** column name */
  TaskId = 'task_id'
}

export type Task_Translation_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Task_Translation_Set_Input>;
  where: Task_Translation_Bool_Exp;
};

/** columns and relationships of "tasks" */
export type Tasks = {
  __typename?: 'tasks';
  /** An array relationship */
  audio_measurements_project_tasks: Array<Audio_Measurements_Project_Tasks>;
  /** An aggregate relationship */
  audio_measurements_project_tasks_aggregate: Audio_Measurements_Project_Tasks_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  demo_url?: Maybe<Scalars['String']>;
  /** A computed description based on highlighted fields */
  description?: Maybe<Scalars['String']>;
  force_ground_truth_to_negative: Scalars['Boolean'];
  /** An array relationship */
  hypertask_tasks: Array<Hypertask_Task>;
  /** An aggregate relationship */
  hypertask_tasks_aggregate: Hypertask_Task_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  instructions: Array<Integration_Test_Instructions>;
  /** An aggregate relationship */
  instructions_aggregate: Integration_Test_Instructions_Aggregate;
  is_template?: Maybe<Scalars['Boolean']>;
  is_test?: Maybe<Scalars['Boolean']>;
  legacy_template_id: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  /** An array relationship */
  plan_tasks: Array<Plan_Tasks>;
  /** An aggregate relationship */
  plan_tasks_aggregate: Plan_Tasks_Aggregate;
  /** An array relationship */
  project_dataset: Array<Project_Dataset>;
  /** An aggregate relationship */
  project_dataset_aggregate: Project_Dataset_Aggregate;
  /** An array relationship */
  project_tasks: Array<Project_Tasks>;
  /** An aggregate relationship */
  project_tasks_aggregate: Project_Tasks_Aggregate;
  /** An array relationship */
  rabbit_test_tasks: Array<Rabbit_Test_Plan_Task>;
  /** An aggregate relationship */
  rabbit_test_tasks_aggregate: Rabbit_Test_Plan_Task_Aggregate;
  /** An array relationship */
  recordings: Array<Recordings>;
  /** An aggregate relationship */
  recordings_aggregate: Recordings_Aggregate;
  /** An array relationship */
  task_field_options: Array<Task_Field_Option>;
  /** An aggregate relationship */
  task_field_options_aggregate: Task_Field_Option_Aggregate;
  /** An object relationship */
  task_instruction?: Maybe<Task_Instructions>;
  /** An array relationship */
  task_media: Array<Task_Media>;
  /** An aggregate relationship */
  task_media_aggregate: Task_Media_Aggregate;
  /** An array relationship */
  translations: Array<Task_Translation>;
  /** An aggregate relationship */
  translations_aggregate: Task_Translation_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "tasks" */
export type TasksAudio_Measurements_Project_TasksArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Project_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Project_Tasks_Order_By>>;
  where?: Maybe<Audio_Measurements_Project_Tasks_Bool_Exp>;
};


/** columns and relationships of "tasks" */
export type TasksAudio_Measurements_Project_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Audio_Measurements_Project_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Measurements_Project_Tasks_Order_By>>;
  where?: Maybe<Audio_Measurements_Project_Tasks_Bool_Exp>;
};


/** columns and relationships of "tasks" */
export type TasksHypertask_TasksArgs = {
  distinct_on?: Maybe<Array<Hypertask_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hypertask_Task_Order_By>>;
  where?: Maybe<Hypertask_Task_Bool_Exp>;
};


/** columns and relationships of "tasks" */
export type TasksHypertask_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Hypertask_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hypertask_Task_Order_By>>;
  where?: Maybe<Hypertask_Task_Bool_Exp>;
};


/** columns and relationships of "tasks" */
export type TasksInstructionsArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Instructions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Instructions_Order_By>>;
  where?: Maybe<Integration_Test_Instructions_Bool_Exp>;
};


/** columns and relationships of "tasks" */
export type TasksInstructions_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Instructions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Instructions_Order_By>>;
  where?: Maybe<Integration_Test_Instructions_Bool_Exp>;
};


/** columns and relationships of "tasks" */
export type TasksPlan_TasksArgs = {
  distinct_on?: Maybe<Array<Plan_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plan_Tasks_Order_By>>;
  where?: Maybe<Plan_Tasks_Bool_Exp>;
};


/** columns and relationships of "tasks" */
export type TasksPlan_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Plan_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plan_Tasks_Order_By>>;
  where?: Maybe<Plan_Tasks_Bool_Exp>;
};


/** columns and relationships of "tasks" */
export type TasksProject_DatasetArgs = {
  distinct_on?: Maybe<Array<Project_Dataset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Dataset_Order_By>>;
  where?: Maybe<Project_Dataset_Bool_Exp>;
};


/** columns and relationships of "tasks" */
export type TasksProject_Dataset_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Dataset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Dataset_Order_By>>;
  where?: Maybe<Project_Dataset_Bool_Exp>;
};


/** columns and relationships of "tasks" */
export type TasksProject_TasksArgs = {
  distinct_on?: Maybe<Array<Project_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Tasks_Order_By>>;
  where?: Maybe<Project_Tasks_Bool_Exp>;
};


/** columns and relationships of "tasks" */
export type TasksProject_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Tasks_Order_By>>;
  where?: Maybe<Project_Tasks_Bool_Exp>;
};


/** columns and relationships of "tasks" */
export type TasksRabbit_Test_TasksArgs = {
  distinct_on?: Maybe<Array<Rabbit_Test_Plan_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rabbit_Test_Plan_Task_Order_By>>;
  where?: Maybe<Rabbit_Test_Plan_Task_Bool_Exp>;
};


/** columns and relationships of "tasks" */
export type TasksRabbit_Test_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Rabbit_Test_Plan_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rabbit_Test_Plan_Task_Order_By>>;
  where?: Maybe<Rabbit_Test_Plan_Task_Bool_Exp>;
};


/** columns and relationships of "tasks" */
export type TasksRecordingsArgs = {
  distinct_on?: Maybe<Array<Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recordings_Order_By>>;
  where?: Maybe<Recordings_Bool_Exp>;
};


/** columns and relationships of "tasks" */
export type TasksRecordings_AggregateArgs = {
  distinct_on?: Maybe<Array<Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recordings_Order_By>>;
  where?: Maybe<Recordings_Bool_Exp>;
};


/** columns and relationships of "tasks" */
export type TasksTask_Field_OptionsArgs = {
  distinct_on?: Maybe<Array<Task_Field_Option_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Field_Option_Order_By>>;
  where?: Maybe<Task_Field_Option_Bool_Exp>;
};


/** columns and relationships of "tasks" */
export type TasksTask_Field_Options_AggregateArgs = {
  distinct_on?: Maybe<Array<Task_Field_Option_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Field_Option_Order_By>>;
  where?: Maybe<Task_Field_Option_Bool_Exp>;
};


/** columns and relationships of "tasks" */
export type TasksTask_MediaArgs = {
  distinct_on?: Maybe<Array<Task_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Media_Order_By>>;
  where?: Maybe<Task_Media_Bool_Exp>;
};


/** columns and relationships of "tasks" */
export type TasksTask_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Task_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Media_Order_By>>;
  where?: Maybe<Task_Media_Bool_Exp>;
};


/** columns and relationships of "tasks" */
export type TasksTranslationsArgs = {
  distinct_on?: Maybe<Array<Task_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Translation_Order_By>>;
  where?: Maybe<Task_Translation_Bool_Exp>;
};


/** columns and relationships of "tasks" */
export type TasksTranslations_AggregateArgs = {
  distinct_on?: Maybe<Array<Task_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Translation_Order_By>>;
  where?: Maybe<Task_Translation_Bool_Exp>;
};

/** aggregated selection of "tasks" */
export type Tasks_Aggregate = {
  __typename?: 'tasks_aggregate';
  aggregate?: Maybe<Tasks_Aggregate_Fields>;
  nodes: Array<Tasks>;
};

/** aggregate fields of "tasks" */
export type Tasks_Aggregate_Fields = {
  __typename?: 'tasks_aggregate_fields';
  avg?: Maybe<Tasks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tasks_Max_Fields>;
  min?: Maybe<Tasks_Min_Fields>;
  stddev?: Maybe<Tasks_Stddev_Fields>;
  stddev_pop?: Maybe<Tasks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tasks_Stddev_Samp_Fields>;
  sum?: Maybe<Tasks_Sum_Fields>;
  var_pop?: Maybe<Tasks_Var_Pop_Fields>;
  var_samp?: Maybe<Tasks_Var_Samp_Fields>;
  variance?: Maybe<Tasks_Variance_Fields>;
};


/** aggregate fields of "tasks" */
export type Tasks_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tasks_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Tasks_Avg_Fields = {
  __typename?: 'tasks_avg_fields';
  legacy_template_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "tasks". All fields are combined with a logical 'AND'. */
export type Tasks_Bool_Exp = {
  _and?: Maybe<Array<Tasks_Bool_Exp>>;
  _not?: Maybe<Tasks_Bool_Exp>;
  _or?: Maybe<Array<Tasks_Bool_Exp>>;
  audio_measurements_project_tasks?: Maybe<Audio_Measurements_Project_Tasks_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  demo_url?: Maybe<String_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  force_ground_truth_to_negative?: Maybe<Boolean_Comparison_Exp>;
  hypertask_tasks?: Maybe<Hypertask_Task_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  instructions?: Maybe<Integration_Test_Instructions_Bool_Exp>;
  is_template?: Maybe<Boolean_Comparison_Exp>;
  is_test?: Maybe<Boolean_Comparison_Exp>;
  legacy_template_id?: Maybe<Int_Comparison_Exp>;
  note?: Maybe<String_Comparison_Exp>;
  plan_tasks?: Maybe<Plan_Tasks_Bool_Exp>;
  project_dataset?: Maybe<Project_Dataset_Bool_Exp>;
  project_tasks?: Maybe<Project_Tasks_Bool_Exp>;
  rabbit_test_tasks?: Maybe<Rabbit_Test_Plan_Task_Bool_Exp>;
  recordings?: Maybe<Recordings_Bool_Exp>;
  task_field_options?: Maybe<Task_Field_Option_Bool_Exp>;
  task_instruction?: Maybe<Task_Instructions_Bool_Exp>;
  task_media?: Maybe<Task_Media_Bool_Exp>;
  translations?: Maybe<Task_Translation_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tasks" */
export enum Tasks_Constraint {
  /** unique or primary key constraint on columns "id", "is_test" */
  TasksIdIsTestKey = 'tasks_id_is_test_key',
  /** unique or primary key constraint on columns "legacy_template_id" */
  TasksLegacyTemplateIdKey = 'tasks_legacy_template_id_key',
  /** unique or primary key constraint on columns "id" */
  TasksPkey = 'tasks_pkey'
}

/** input type for incrementing numeric columns in table "tasks" */
export type Tasks_Inc_Input = {
  legacy_template_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "tasks" */
export type Tasks_Insert_Input = {
  audio_measurements_project_tasks?: Maybe<Audio_Measurements_Project_Tasks_Arr_Rel_Insert_Input>;
  demo_url?: Maybe<Scalars['String']>;
  force_ground_truth_to_negative?: Maybe<Scalars['Boolean']>;
  hypertask_tasks?: Maybe<Hypertask_Task_Arr_Rel_Insert_Input>;
  instructions?: Maybe<Integration_Test_Instructions_Arr_Rel_Insert_Input>;
  is_template?: Maybe<Scalars['Boolean']>;
  is_test?: Maybe<Scalars['Boolean']>;
  legacy_template_id?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  plan_tasks?: Maybe<Plan_Tasks_Arr_Rel_Insert_Input>;
  project_tasks?: Maybe<Project_Tasks_Arr_Rel_Insert_Input>;
  rabbit_test_tasks?: Maybe<Rabbit_Test_Plan_Task_Arr_Rel_Insert_Input>;
  recordings?: Maybe<Recordings_Arr_Rel_Insert_Input>;
  task_field_options?: Maybe<Task_Field_Option_Arr_Rel_Insert_Input>;
  task_instruction?: Maybe<Task_Instructions_Obj_Rel_Insert_Input>;
  task_media?: Maybe<Task_Media_Arr_Rel_Insert_Input>;
  translations?: Maybe<Task_Translation_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Tasks_Max_Fields = {
  __typename?: 'tasks_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  demo_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  legacy_template_id?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Tasks_Min_Fields = {
  __typename?: 'tasks_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  demo_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  legacy_template_id?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "tasks" */
export type Tasks_Mutation_Response = {
  __typename?: 'tasks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tasks>;
};

/** input type for inserting object relation for remote table "tasks" */
export type Tasks_Obj_Rel_Insert_Input = {
  data: Tasks_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Tasks_On_Conflict>;
};

/** on_conflict condition type for table "tasks" */
export type Tasks_On_Conflict = {
  constraint: Tasks_Constraint;
  update_columns?: Array<Tasks_Update_Column>;
  where?: Maybe<Tasks_Bool_Exp>;
};

/** Ordering options when selecting data from "tasks". */
export type Tasks_Order_By = {
  audio_measurements_project_tasks_aggregate?: Maybe<Audio_Measurements_Project_Tasks_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  demo_url?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  force_ground_truth_to_negative?: Maybe<Order_By>;
  hypertask_tasks_aggregate?: Maybe<Hypertask_Task_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  instructions_aggregate?: Maybe<Integration_Test_Instructions_Aggregate_Order_By>;
  is_template?: Maybe<Order_By>;
  is_test?: Maybe<Order_By>;
  legacy_template_id?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  plan_tasks_aggregate?: Maybe<Plan_Tasks_Aggregate_Order_By>;
  project_dataset_aggregate?: Maybe<Project_Dataset_Aggregate_Order_By>;
  project_tasks_aggregate?: Maybe<Project_Tasks_Aggregate_Order_By>;
  rabbit_test_tasks_aggregate?: Maybe<Rabbit_Test_Plan_Task_Aggregate_Order_By>;
  recordings_aggregate?: Maybe<Recordings_Aggregate_Order_By>;
  task_field_options_aggregate?: Maybe<Task_Field_Option_Aggregate_Order_By>;
  task_instruction?: Maybe<Task_Instructions_Order_By>;
  task_media_aggregate?: Maybe<Task_Media_Aggregate_Order_By>;
  translations_aggregate?: Maybe<Task_Translation_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: tasks */
export type Tasks_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "tasks" */
export enum Tasks_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DemoUrl = 'demo_url',
  /** column name */
  ForceGroundTruthToNegative = 'force_ground_truth_to_negative',
  /** column name */
  Id = 'id',
  /** column name */
  IsTemplate = 'is_template',
  /** column name */
  IsTest = 'is_test',
  /** column name */
  LegacyTemplateId = 'legacy_template_id',
  /** column name */
  Note = 'note',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "tasks" */
export type Tasks_Set_Input = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  demo_url?: Maybe<Scalars['String']>;
  force_ground_truth_to_negative?: Maybe<Scalars['Boolean']>;
  is_template?: Maybe<Scalars['Boolean']>;
  is_test?: Maybe<Scalars['Boolean']>;
  legacy_template_id?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Tasks_Stddev_Fields = {
  __typename?: 'tasks_stddev_fields';
  legacy_template_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Tasks_Stddev_Pop_Fields = {
  __typename?: 'tasks_stddev_pop_fields';
  legacy_template_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Tasks_Stddev_Samp_Fields = {
  __typename?: 'tasks_stddev_samp_fields';
  legacy_template_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "tasks" */
export type Tasks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tasks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tasks_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  demo_url?: Maybe<Scalars['String']>;
  force_ground_truth_to_negative?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  is_template?: Maybe<Scalars['Boolean']>;
  is_test?: Maybe<Scalars['Boolean']>;
  legacy_template_id?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Tasks_Sum_Fields = {
  __typename?: 'tasks_sum_fields';
  legacy_template_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "tasks" */
export enum Tasks_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DemoUrl = 'demo_url',
  /** column name */
  ForceGroundTruthToNegative = 'force_ground_truth_to_negative',
  /** column name */
  IsTemplate = 'is_template',
  /** column name */
  IsTest = 'is_test',
  /** column name */
  LegacyTemplateId = 'legacy_template_id',
  /** column name */
  Note = 'note'
}

export type Tasks_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Tasks_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Tasks_Set_Input>;
  where: Tasks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tasks_Var_Pop_Fields = {
  __typename?: 'tasks_var_pop_fields';
  legacy_template_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Tasks_Var_Samp_Fields = {
  __typename?: 'tasks_var_samp_fields';
  legacy_template_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Tasks_Variance_Fields = {
  __typename?: 'tasks_variance_fields';
  legacy_template_id?: Maybe<Scalars['Float']>;
};


/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "tool_versions" */
export type Tool_Versions = {
  __typename?: 'tool_versions';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  filename: Scalars['String'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  released_at: Scalars['timestamptz'];
  storage_path: Scalars['String'];
  /** An object relationship */
  tool?: Maybe<Tools>;
  tool_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by aggregate values of table "tool_versions" */
export type Tool_Versions_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Tool_Versions_Max_Order_By>;
  min?: Maybe<Tool_Versions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tool_versions" */
export type Tool_Versions_Arr_Rel_Insert_Input = {
  data: Array<Tool_Versions_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Tool_Versions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tool_versions". All fields are combined with a logical 'AND'. */
export type Tool_Versions_Bool_Exp = {
  _and?: Maybe<Array<Tool_Versions_Bool_Exp>>;
  _not?: Maybe<Tool_Versions_Bool_Exp>;
  _or?: Maybe<Array<Tool_Versions_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  filename?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  released_at?: Maybe<Timestamptz_Comparison_Exp>;
  storage_path?: Maybe<String_Comparison_Exp>;
  tool?: Maybe<Tools_Bool_Exp>;
  tool_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tool_versions" */
export enum Tool_Versions_Constraint {
  /** unique or primary key constraint on columns "id" */
  ToolVersionsPkey = 'tool_versions_pkey'
}

/** input type for inserting data into table "tool_versions" */
export type Tool_Versions_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  filename?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  released_at?: Maybe<Scalars['timestamptz']>;
  storage_path?: Maybe<Scalars['String']>;
  tool?: Maybe<Tools_Obj_Rel_Insert_Input>;
  tool_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "tool_versions" */
export type Tool_Versions_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  released_at?: Maybe<Order_By>;
  storage_path?: Maybe<Order_By>;
  tool_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** order by min() on columns of table "tool_versions" */
export type Tool_Versions_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  released_at?: Maybe<Order_By>;
  storage_path?: Maybe<Order_By>;
  tool_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "tool_versions" */
export type Tool_Versions_Mutation_Response = {
  __typename?: 'tool_versions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tool_Versions>;
};

/** on_conflict condition type for table "tool_versions" */
export type Tool_Versions_On_Conflict = {
  constraint: Tool_Versions_Constraint;
  update_columns?: Array<Tool_Versions_Update_Column>;
  where?: Maybe<Tool_Versions_Bool_Exp>;
};

/** Ordering options when selecting data from "tool_versions". */
export type Tool_Versions_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  released_at?: Maybe<Order_By>;
  storage_path?: Maybe<Order_By>;
  tool?: Maybe<Tools_Order_By>;
  tool_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: tool_versions */
export type Tool_Versions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "tool_versions" */
export enum Tool_Versions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ReleasedAt = 'released_at',
  /** column name */
  StoragePath = 'storage_path',
  /** column name */
  ToolId = 'tool_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "tool_versions" */
export type Tool_Versions_Set_Input = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  filename?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  released_at?: Maybe<Scalars['timestamptz']>;
  storage_path?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "tool_versions" */
export type Tool_Versions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tool_Versions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tool_Versions_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  released_at?: Maybe<Scalars['timestamptz']>;
  storage_path?: Maybe<Scalars['String']>;
  tool_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "tool_versions" */
export enum Tool_Versions_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Filename = 'filename',
  /** column name */
  Name = 'name',
  /** column name */
  ReleasedAt = 'released_at',
  /** column name */
  StoragePath = 'storage_path',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Tool_Versions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Tool_Versions_Set_Input>;
  where: Tool_Versions_Bool_Exp;
};

/** Table used to store the internal tools, every release of those tools is stored in tool_versions */
export type Tools = {
  __typename?: 'tools';
  asgard_only: Scalars['Boolean'];
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  documentation_url?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  sas?: Maybe<Scalars['String']>;
  storage_account?: Maybe<Scalars['String']>;
  storage_container?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  /** An array relationship */
  tool_versions: Array<Tool_Versions>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};


/** Table used to store the internal tools, every release of those tools is stored in tool_versions */
export type ToolsTool_VersionsArgs = {
  distinct_on?: Maybe<Array<Tool_Versions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tool_Versions_Order_By>>;
  where?: Maybe<Tool_Versions_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "tools". All fields are combined with a logical 'AND'. */
export type Tools_Bool_Exp = {
  _and?: Maybe<Array<Tools_Bool_Exp>>;
  _not?: Maybe<Tools_Bool_Exp>;
  _or?: Maybe<Array<Tools_Bool_Exp>>;
  asgard_only?: Maybe<Boolean_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  documentation_url?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  sas?: Maybe<String_Comparison_Exp>;
  storage_account?: Maybe<String_Comparison_Exp>;
  storage_container?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  tool_versions?: Maybe<Tool_Versions_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tools" */
export enum Tools_Constraint {
  /** unique or primary key constraint on columns "id" */
  ToolsPkey = 'tools_pkey',
  /** unique or primary key constraint on columns "title" */
  ToolsTitleKey = 'tools_title_key'
}

/** input type for inserting data into table "tools" */
export type Tools_Insert_Input = {
  asgard_only?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  documentation_url?: Maybe<Scalars['String']>;
  sas?: Maybe<Scalars['String']>;
  storage_account?: Maybe<Scalars['String']>;
  storage_container?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tool_versions?: Maybe<Tool_Versions_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "tools" */
export type Tools_Mutation_Response = {
  __typename?: 'tools_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tools>;
};

/** input type for inserting object relation for remote table "tools" */
export type Tools_Obj_Rel_Insert_Input = {
  data: Tools_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Tools_On_Conflict>;
};

/** on_conflict condition type for table "tools" */
export type Tools_On_Conflict = {
  constraint: Tools_Constraint;
  update_columns?: Array<Tools_Update_Column>;
  where?: Maybe<Tools_Bool_Exp>;
};

/** Ordering options when selecting data from "tools". */
export type Tools_Order_By = {
  asgard_only?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  documentation_url?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  sas?: Maybe<Order_By>;
  storage_account?: Maybe<Order_By>;
  storage_container?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  tool_versions_aggregate?: Maybe<Tool_Versions_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** primary key columns input for table: tools */
export type Tools_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "tools" */
export enum Tools_Select_Column {
  /** column name */
  AsgardOnly = 'asgard_only',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  DocumentationUrl = 'documentation_url',
  /** column name */
  Id = 'id',
  /** column name */
  Sas = 'sas',
  /** column name */
  StorageAccount = 'storage_account',
  /** column name */
  StorageContainer = 'storage_container',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "tools" */
export type Tools_Set_Input = {
  asgard_only?: Maybe<Scalars['Boolean']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  documentation_url?: Maybe<Scalars['String']>;
  sas?: Maybe<Scalars['String']>;
  storage_account?: Maybe<Scalars['String']>;
  storage_container?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "tools" */
export type Tools_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tools_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tools_Stream_Cursor_Value_Input = {
  asgard_only?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  documentation_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  sas?: Maybe<Scalars['String']>;
  storage_account?: Maybe<Scalars['String']>;
  storage_container?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** update columns of table "tools" */
export enum Tools_Update_Column {
  /** column name */
  AsgardOnly = 'asgard_only',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  DocumentationUrl = 'documentation_url',
  /** column name */
  Sas = 'sas',
  /** column name */
  StorageAccount = 'storage_account',
  /** column name */
  StorageContainer = 'storage_container',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

export type Tools_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Tools_Set_Input>;
  where: Tools_Bool_Exp;
};

/** columns and relationships of "types" */
export type Types = {
  __typename?: 'types';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  field_options: Array<Field_Options>;
  /** An aggregate relationship */
  field_options_aggregate: Field_Options_Aggregate;
  /** An array relationship */
  fields: Array<Fields>;
  /** An aggregate relationship */
  fields_aggregate: Fields_Aggregate;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  options: Array<Options>;
  /** An aggregate relationship */
  options_aggregate: Options_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "types" */
export type TypesField_OptionsArgs = {
  distinct_on?: Maybe<Array<Field_Options_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Field_Options_Order_By>>;
  where?: Maybe<Field_Options_Bool_Exp>;
};


/** columns and relationships of "types" */
export type TypesField_Options_AggregateArgs = {
  distinct_on?: Maybe<Array<Field_Options_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Field_Options_Order_By>>;
  where?: Maybe<Field_Options_Bool_Exp>;
};


/** columns and relationships of "types" */
export type TypesFieldsArgs = {
  distinct_on?: Maybe<Array<Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fields_Order_By>>;
  where?: Maybe<Fields_Bool_Exp>;
};


/** columns and relationships of "types" */
export type TypesFields_AggregateArgs = {
  distinct_on?: Maybe<Array<Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fields_Order_By>>;
  where?: Maybe<Fields_Bool_Exp>;
};


/** columns and relationships of "types" */
export type TypesOptionsArgs = {
  distinct_on?: Maybe<Array<Options_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Options_Order_By>>;
  where?: Maybe<Options_Bool_Exp>;
};


/** columns and relationships of "types" */
export type TypesOptions_AggregateArgs = {
  distinct_on?: Maybe<Array<Options_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Options_Order_By>>;
  where?: Maybe<Options_Bool_Exp>;
};

/** aggregated selection of "types" */
export type Types_Aggregate = {
  __typename?: 'types_aggregate';
  aggregate?: Maybe<Types_Aggregate_Fields>;
  nodes: Array<Types>;
};

/** aggregate fields of "types" */
export type Types_Aggregate_Fields = {
  __typename?: 'types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Types_Max_Fields>;
  min?: Maybe<Types_Min_Fields>;
};


/** aggregate fields of "types" */
export type Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "types". All fields are combined with a logical 'AND'. */
export type Types_Bool_Exp = {
  _and?: Maybe<Array<Types_Bool_Exp>>;
  _not?: Maybe<Types_Bool_Exp>;
  _or?: Maybe<Array<Types_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  field_options?: Maybe<Field_Options_Bool_Exp>;
  fields?: Maybe<Fields_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  options?: Maybe<Options_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "types" */
export enum Types_Constraint {
  /** unique or primary key constraint on columns "name" */
  TypesNameUnique = 'types_name_unique',
  /** unique or primary key constraint on columns "id" */
  TypesPkey = 'types_pkey'
}

/** input type for inserting data into table "types" */
export type Types_Insert_Input = {
  field_options?: Maybe<Field_Options_Arr_Rel_Insert_Input>;
  fields?: Maybe<Fields_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Options_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Types_Max_Fields = {
  __typename?: 'types_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Types_Min_Fields = {
  __typename?: 'types_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "types" */
export type Types_Mutation_Response = {
  __typename?: 'types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Types>;
};

/** input type for inserting object relation for remote table "types" */
export type Types_Obj_Rel_Insert_Input = {
  data: Types_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Types_On_Conflict>;
};

/** on_conflict condition type for table "types" */
export type Types_On_Conflict = {
  constraint: Types_Constraint;
  update_columns?: Array<Types_Update_Column>;
  where?: Maybe<Types_Bool_Exp>;
};

/** Ordering options when selecting data from "types". */
export type Types_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  field_options_aggregate?: Maybe<Field_Options_Aggregate_Order_By>;
  fields_aggregate?: Maybe<Fields_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  options_aggregate?: Maybe<Options_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: types */
export type Types_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "types" */
export enum Types_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "types" */
export type Types_Set_Input = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "types" */
export type Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Types_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "types" */
export enum Types_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Name = 'name'
}

export type Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Types_Set_Input>;
  where: Types_Bool_Exp;
};

export type Upsert_Task_Args = {
  default_note?: Maybe<Scalars['String']>;
  field_option_pairs?: Maybe<Scalars['jsonb']>;
  from_hypertask_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "user_recordings_by_day_stats" */
export type User_Recordings_By_Day_Stats = {
  __typename?: 'user_recordings_by_day_stats';
  clean_duration?: Maybe<Scalars['numeric']>;
  clean_recordings?: Maybe<Scalars['bigint']>;
  day?: Maybe<Scalars['timestamptz']>;
  dirty_duration?: Maybe<Scalars['numeric']>;
  dirty_recordings?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  project?: Maybe<Projects>;
  project_id?: Maybe<Scalars['uuid']>;
  total_duration?: Maybe<Scalars['numeric']>;
  total_recordings?: Maybe<Scalars['bigint']>;
  untagged_duration?: Maybe<Scalars['numeric']>;
  untagged_recordings?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "user_recordings_by_day_stats" */
export type User_Recordings_By_Day_Stats_Aggregate = {
  __typename?: 'user_recordings_by_day_stats_aggregate';
  aggregate?: Maybe<User_Recordings_By_Day_Stats_Aggregate_Fields>;
  nodes: Array<User_Recordings_By_Day_Stats>;
};

/** aggregate fields of "user_recordings_by_day_stats" */
export type User_Recordings_By_Day_Stats_Aggregate_Fields = {
  __typename?: 'user_recordings_by_day_stats_aggregate_fields';
  avg?: Maybe<User_Recordings_By_Day_Stats_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Recordings_By_Day_Stats_Max_Fields>;
  min?: Maybe<User_Recordings_By_Day_Stats_Min_Fields>;
  stddev?: Maybe<User_Recordings_By_Day_Stats_Stddev_Fields>;
  stddev_pop?: Maybe<User_Recordings_By_Day_Stats_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Recordings_By_Day_Stats_Stddev_Samp_Fields>;
  sum?: Maybe<User_Recordings_By_Day_Stats_Sum_Fields>;
  var_pop?: Maybe<User_Recordings_By_Day_Stats_Var_Pop_Fields>;
  var_samp?: Maybe<User_Recordings_By_Day_Stats_Var_Samp_Fields>;
  variance?: Maybe<User_Recordings_By_Day_Stats_Variance_Fields>;
};


/** aggregate fields of "user_recordings_by_day_stats" */
export type User_Recordings_By_Day_Stats_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Recordings_By_Day_Stats_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_recordings_by_day_stats" */
export type User_Recordings_By_Day_Stats_Aggregate_Order_By = {
  avg?: Maybe<User_Recordings_By_Day_Stats_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<User_Recordings_By_Day_Stats_Max_Order_By>;
  min?: Maybe<User_Recordings_By_Day_Stats_Min_Order_By>;
  stddev?: Maybe<User_Recordings_By_Day_Stats_Stddev_Order_By>;
  stddev_pop?: Maybe<User_Recordings_By_Day_Stats_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<User_Recordings_By_Day_Stats_Stddev_Samp_Order_By>;
  sum?: Maybe<User_Recordings_By_Day_Stats_Sum_Order_By>;
  var_pop?: Maybe<User_Recordings_By_Day_Stats_Var_Pop_Order_By>;
  var_samp?: Maybe<User_Recordings_By_Day_Stats_Var_Samp_Order_By>;
  variance?: Maybe<User_Recordings_By_Day_Stats_Variance_Order_By>;
};

/** aggregate avg on columns */
export type User_Recordings_By_Day_Stats_Avg_Fields = {
  __typename?: 'user_recordings_by_day_stats_avg_fields';
  clean_duration?: Maybe<Scalars['Float']>;
  clean_recordings?: Maybe<Scalars['Float']>;
  dirty_duration?: Maybe<Scalars['Float']>;
  dirty_recordings?: Maybe<Scalars['Float']>;
  total_duration?: Maybe<Scalars['Float']>;
  total_recordings?: Maybe<Scalars['Float']>;
  untagged_duration?: Maybe<Scalars['Float']>;
  untagged_recordings?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_recordings_by_day_stats" */
export type User_Recordings_By_Day_Stats_Avg_Order_By = {
  clean_duration?: Maybe<Order_By>;
  clean_recordings?: Maybe<Order_By>;
  dirty_duration?: Maybe<Order_By>;
  dirty_recordings?: Maybe<Order_By>;
  total_duration?: Maybe<Order_By>;
  total_recordings?: Maybe<Order_By>;
  untagged_duration?: Maybe<Order_By>;
  untagged_recordings?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_recordings_by_day_stats". All fields are combined with a logical 'AND'. */
export type User_Recordings_By_Day_Stats_Bool_Exp = {
  _and?: Maybe<Array<User_Recordings_By_Day_Stats_Bool_Exp>>;
  _not?: Maybe<User_Recordings_By_Day_Stats_Bool_Exp>;
  _or?: Maybe<Array<User_Recordings_By_Day_Stats_Bool_Exp>>;
  clean_duration?: Maybe<Numeric_Comparison_Exp>;
  clean_recordings?: Maybe<Bigint_Comparison_Exp>;
  day?: Maybe<Timestamptz_Comparison_Exp>;
  dirty_duration?: Maybe<Numeric_Comparison_Exp>;
  dirty_recordings?: Maybe<Bigint_Comparison_Exp>;
  project?: Maybe<Projects_Bool_Exp>;
  project_id?: Maybe<Uuid_Comparison_Exp>;
  total_duration?: Maybe<Numeric_Comparison_Exp>;
  total_recordings?: Maybe<Bigint_Comparison_Exp>;
  untagged_duration?: Maybe<Numeric_Comparison_Exp>;
  untagged_recordings?: Maybe<Bigint_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type User_Recordings_By_Day_Stats_Max_Fields = {
  __typename?: 'user_recordings_by_day_stats_max_fields';
  clean_duration?: Maybe<Scalars['numeric']>;
  clean_recordings?: Maybe<Scalars['bigint']>;
  day?: Maybe<Scalars['timestamptz']>;
  dirty_duration?: Maybe<Scalars['numeric']>;
  dirty_recordings?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['uuid']>;
  total_duration?: Maybe<Scalars['numeric']>;
  total_recordings?: Maybe<Scalars['bigint']>;
  untagged_duration?: Maybe<Scalars['numeric']>;
  untagged_recordings?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_recordings_by_day_stats" */
export type User_Recordings_By_Day_Stats_Max_Order_By = {
  clean_duration?: Maybe<Order_By>;
  clean_recordings?: Maybe<Order_By>;
  day?: Maybe<Order_By>;
  dirty_duration?: Maybe<Order_By>;
  dirty_recordings?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  total_duration?: Maybe<Order_By>;
  total_recordings?: Maybe<Order_By>;
  untagged_duration?: Maybe<Order_By>;
  untagged_recordings?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Recordings_By_Day_Stats_Min_Fields = {
  __typename?: 'user_recordings_by_day_stats_min_fields';
  clean_duration?: Maybe<Scalars['numeric']>;
  clean_recordings?: Maybe<Scalars['bigint']>;
  day?: Maybe<Scalars['timestamptz']>;
  dirty_duration?: Maybe<Scalars['numeric']>;
  dirty_recordings?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['uuid']>;
  total_duration?: Maybe<Scalars['numeric']>;
  total_recordings?: Maybe<Scalars['bigint']>;
  untagged_duration?: Maybe<Scalars['numeric']>;
  untagged_recordings?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_recordings_by_day_stats" */
export type User_Recordings_By_Day_Stats_Min_Order_By = {
  clean_duration?: Maybe<Order_By>;
  clean_recordings?: Maybe<Order_By>;
  day?: Maybe<Order_By>;
  dirty_duration?: Maybe<Order_By>;
  dirty_recordings?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  total_duration?: Maybe<Order_By>;
  total_recordings?: Maybe<Order_By>;
  untagged_duration?: Maybe<Order_By>;
  untagged_recordings?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "user_recordings_by_day_stats". */
export type User_Recordings_By_Day_Stats_Order_By = {
  clean_duration?: Maybe<Order_By>;
  clean_recordings?: Maybe<Order_By>;
  day?: Maybe<Order_By>;
  dirty_duration?: Maybe<Order_By>;
  dirty_recordings?: Maybe<Order_By>;
  project?: Maybe<Projects_Order_By>;
  project_id?: Maybe<Order_By>;
  total_duration?: Maybe<Order_By>;
  total_recordings?: Maybe<Order_By>;
  untagged_duration?: Maybe<Order_By>;
  untagged_recordings?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** select columns of table "user_recordings_by_day_stats" */
export enum User_Recordings_By_Day_Stats_Select_Column {
  /** column name */
  CleanDuration = 'clean_duration',
  /** column name */
  CleanRecordings = 'clean_recordings',
  /** column name */
  Day = 'day',
  /** column name */
  DirtyDuration = 'dirty_duration',
  /** column name */
  DirtyRecordings = 'dirty_recordings',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  TotalDuration = 'total_duration',
  /** column name */
  TotalRecordings = 'total_recordings',
  /** column name */
  UntaggedDuration = 'untagged_duration',
  /** column name */
  UntaggedRecordings = 'untagged_recordings',
  /** column name */
  UserId = 'user_id'
}

/** aggregate stddev on columns */
export type User_Recordings_By_Day_Stats_Stddev_Fields = {
  __typename?: 'user_recordings_by_day_stats_stddev_fields';
  clean_duration?: Maybe<Scalars['Float']>;
  clean_recordings?: Maybe<Scalars['Float']>;
  dirty_duration?: Maybe<Scalars['Float']>;
  dirty_recordings?: Maybe<Scalars['Float']>;
  total_duration?: Maybe<Scalars['Float']>;
  total_recordings?: Maybe<Scalars['Float']>;
  untagged_duration?: Maybe<Scalars['Float']>;
  untagged_recordings?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_recordings_by_day_stats" */
export type User_Recordings_By_Day_Stats_Stddev_Order_By = {
  clean_duration?: Maybe<Order_By>;
  clean_recordings?: Maybe<Order_By>;
  dirty_duration?: Maybe<Order_By>;
  dirty_recordings?: Maybe<Order_By>;
  total_duration?: Maybe<Order_By>;
  total_recordings?: Maybe<Order_By>;
  untagged_duration?: Maybe<Order_By>;
  untagged_recordings?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Recordings_By_Day_Stats_Stddev_Pop_Fields = {
  __typename?: 'user_recordings_by_day_stats_stddev_pop_fields';
  clean_duration?: Maybe<Scalars['Float']>;
  clean_recordings?: Maybe<Scalars['Float']>;
  dirty_duration?: Maybe<Scalars['Float']>;
  dirty_recordings?: Maybe<Scalars['Float']>;
  total_duration?: Maybe<Scalars['Float']>;
  total_recordings?: Maybe<Scalars['Float']>;
  untagged_duration?: Maybe<Scalars['Float']>;
  untagged_recordings?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_recordings_by_day_stats" */
export type User_Recordings_By_Day_Stats_Stddev_Pop_Order_By = {
  clean_duration?: Maybe<Order_By>;
  clean_recordings?: Maybe<Order_By>;
  dirty_duration?: Maybe<Order_By>;
  dirty_recordings?: Maybe<Order_By>;
  total_duration?: Maybe<Order_By>;
  total_recordings?: Maybe<Order_By>;
  untagged_duration?: Maybe<Order_By>;
  untagged_recordings?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Recordings_By_Day_Stats_Stddev_Samp_Fields = {
  __typename?: 'user_recordings_by_day_stats_stddev_samp_fields';
  clean_duration?: Maybe<Scalars['Float']>;
  clean_recordings?: Maybe<Scalars['Float']>;
  dirty_duration?: Maybe<Scalars['Float']>;
  dirty_recordings?: Maybe<Scalars['Float']>;
  total_duration?: Maybe<Scalars['Float']>;
  total_recordings?: Maybe<Scalars['Float']>;
  untagged_duration?: Maybe<Scalars['Float']>;
  untagged_recordings?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_recordings_by_day_stats" */
export type User_Recordings_By_Day_Stats_Stddev_Samp_Order_By = {
  clean_duration?: Maybe<Order_By>;
  clean_recordings?: Maybe<Order_By>;
  dirty_duration?: Maybe<Order_By>;
  dirty_recordings?: Maybe<Order_By>;
  total_duration?: Maybe<Order_By>;
  total_recordings?: Maybe<Order_By>;
  untagged_duration?: Maybe<Order_By>;
  untagged_recordings?: Maybe<Order_By>;
};

/** Streaming cursor of the table "user_recordings_by_day_stats" */
export type User_Recordings_By_Day_Stats_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Recordings_By_Day_Stats_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Recordings_By_Day_Stats_Stream_Cursor_Value_Input = {
  clean_duration?: Maybe<Scalars['numeric']>;
  clean_recordings?: Maybe<Scalars['bigint']>;
  day?: Maybe<Scalars['timestamptz']>;
  dirty_duration?: Maybe<Scalars['numeric']>;
  dirty_recordings?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['uuid']>;
  total_duration?: Maybe<Scalars['numeric']>;
  total_recordings?: Maybe<Scalars['bigint']>;
  untagged_duration?: Maybe<Scalars['numeric']>;
  untagged_recordings?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type User_Recordings_By_Day_Stats_Sum_Fields = {
  __typename?: 'user_recordings_by_day_stats_sum_fields';
  clean_duration?: Maybe<Scalars['numeric']>;
  clean_recordings?: Maybe<Scalars['bigint']>;
  dirty_duration?: Maybe<Scalars['numeric']>;
  dirty_recordings?: Maybe<Scalars['bigint']>;
  total_duration?: Maybe<Scalars['numeric']>;
  total_recordings?: Maybe<Scalars['bigint']>;
  untagged_duration?: Maybe<Scalars['numeric']>;
  untagged_recordings?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "user_recordings_by_day_stats" */
export type User_Recordings_By_Day_Stats_Sum_Order_By = {
  clean_duration?: Maybe<Order_By>;
  clean_recordings?: Maybe<Order_By>;
  dirty_duration?: Maybe<Order_By>;
  dirty_recordings?: Maybe<Order_By>;
  total_duration?: Maybe<Order_By>;
  total_recordings?: Maybe<Order_By>;
  untagged_duration?: Maybe<Order_By>;
  untagged_recordings?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type User_Recordings_By_Day_Stats_Var_Pop_Fields = {
  __typename?: 'user_recordings_by_day_stats_var_pop_fields';
  clean_duration?: Maybe<Scalars['Float']>;
  clean_recordings?: Maybe<Scalars['Float']>;
  dirty_duration?: Maybe<Scalars['Float']>;
  dirty_recordings?: Maybe<Scalars['Float']>;
  total_duration?: Maybe<Scalars['Float']>;
  total_recordings?: Maybe<Scalars['Float']>;
  untagged_duration?: Maybe<Scalars['Float']>;
  untagged_recordings?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_recordings_by_day_stats" */
export type User_Recordings_By_Day_Stats_Var_Pop_Order_By = {
  clean_duration?: Maybe<Order_By>;
  clean_recordings?: Maybe<Order_By>;
  dirty_duration?: Maybe<Order_By>;
  dirty_recordings?: Maybe<Order_By>;
  total_duration?: Maybe<Order_By>;
  total_recordings?: Maybe<Order_By>;
  untagged_duration?: Maybe<Order_By>;
  untagged_recordings?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Recordings_By_Day_Stats_Var_Samp_Fields = {
  __typename?: 'user_recordings_by_day_stats_var_samp_fields';
  clean_duration?: Maybe<Scalars['Float']>;
  clean_recordings?: Maybe<Scalars['Float']>;
  dirty_duration?: Maybe<Scalars['Float']>;
  dirty_recordings?: Maybe<Scalars['Float']>;
  total_duration?: Maybe<Scalars['Float']>;
  total_recordings?: Maybe<Scalars['Float']>;
  untagged_duration?: Maybe<Scalars['Float']>;
  untagged_recordings?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_recordings_by_day_stats" */
export type User_Recordings_By_Day_Stats_Var_Samp_Order_By = {
  clean_duration?: Maybe<Order_By>;
  clean_recordings?: Maybe<Order_By>;
  dirty_duration?: Maybe<Order_By>;
  dirty_recordings?: Maybe<Order_By>;
  total_duration?: Maybe<Order_By>;
  total_recordings?: Maybe<Order_By>;
  untagged_duration?: Maybe<Order_By>;
  untagged_recordings?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Recordings_By_Day_Stats_Variance_Fields = {
  __typename?: 'user_recordings_by_day_stats_variance_fields';
  clean_duration?: Maybe<Scalars['Float']>;
  clean_recordings?: Maybe<Scalars['Float']>;
  dirty_duration?: Maybe<Scalars['Float']>;
  dirty_recordings?: Maybe<Scalars['Float']>;
  total_duration?: Maybe<Scalars['Float']>;
  total_recordings?: Maybe<Scalars['Float']>;
  untagged_duration?: Maybe<Scalars['Float']>;
  untagged_recordings?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_recordings_by_day_stats" */
export type User_Recordings_By_Day_Stats_Variance_Order_By = {
  clean_duration?: Maybe<Order_By>;
  clean_recordings?: Maybe<Order_By>;
  dirty_duration?: Maybe<Order_By>;
  dirty_recordings?: Maybe<Order_By>;
  total_duration?: Maybe<Order_By>;
  total_recordings?: Maybe<Order_By>;
  untagged_duration?: Maybe<Order_By>;
  untagged_recordings?: Maybe<Order_By>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  observances: Array<Integration_Test_Observances>;
  /** An aggregate relationship */
  observances_aggregate: Integration_Test_Observances_Aggregate;
  /** An object relationship */
  option?: Maybe<Options>;
  /** An array relationship */
  project_dataset: Array<Project_Dataset>;
  /** An aggregate relationship */
  project_dataset_aggregate: Project_Dataset_Aggregate;
  /** An array relationship */
  project_users: Array<Project_User>;
  /** An aggregate relationship */
  project_users_aggregate: Project_User_Aggregate;
  /** An array relationship */
  recording_recording_tags: Array<Recording_Recording_Tag>;
  /** An array relationship */
  recordings: Array<Recordings>;
  /** An aggregate relationship */
  recordings_aggregate: Recordings_Aggregate;
  /** An array relationship */
  role_users: Array<Role_User>;
  /** An aggregate relationship */
  role_users_aggregate: Role_User_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_option_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "users" */
export type UsersObservancesArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Observances_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Observances_Order_By>>;
  where?: Maybe<Integration_Test_Observances_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersObservances_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Test_Observances_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Observances_Order_By>>;
  where?: Maybe<Integration_Test_Observances_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersProject_DatasetArgs = {
  distinct_on?: Maybe<Array<Project_Dataset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Dataset_Order_By>>;
  where?: Maybe<Project_Dataset_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersProject_Dataset_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Dataset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Dataset_Order_By>>;
  where?: Maybe<Project_Dataset_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersProject_UsersArgs = {
  distinct_on?: Maybe<Array<Project_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_User_Order_By>>;
  where?: Maybe<Project_User_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersProject_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_User_Order_By>>;
  where?: Maybe<Project_User_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersRecording_Recording_TagsArgs = {
  distinct_on?: Maybe<Array<Recording_Recording_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Recording_Tag_Order_By>>;
  where?: Maybe<Recording_Recording_Tag_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersRecordingsArgs = {
  distinct_on?: Maybe<Array<Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recordings_Order_By>>;
  where?: Maybe<Recordings_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersRecordings_AggregateArgs = {
  distinct_on?: Maybe<Array<Recordings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recordings_Order_By>>;
  where?: Maybe<Recordings_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersRole_UsersArgs = {
  distinct_on?: Maybe<Array<Role_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_User_Order_By>>;
  where?: Maybe<Role_User_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersRole_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Role_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_User_Order_By>>;
  where?: Maybe<Role_User_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Users_Max_Order_By>;
  min?: Maybe<Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: Maybe<Array<Users_Bool_Exp>>;
  _not?: Maybe<Users_Bool_Exp>;
  _or?: Maybe<Array<Users_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  observances?: Maybe<Integration_Test_Observances_Bool_Exp>;
  option?: Maybe<Options_Bool_Exp>;
  project_dataset?: Maybe<Project_Dataset_Bool_Exp>;
  project_users?: Maybe<Project_User_Bool_Exp>;
  recording_recording_tags?: Maybe<Recording_Recording_Tag_Bool_Exp>;
  recordings?: Maybe<Recordings_Bool_Exp>;
  role_users?: Maybe<Role_User_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_option_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey',
  /** unique or primary key constraint on columns "user_option_id" */
  UsersUserOptionIdKey = 'users_user_option_id_key'
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  observances?: Maybe<Integration_Test_Observances_Arr_Rel_Insert_Input>;
  option?: Maybe<Options_Obj_Rel_Insert_Input>;
  project_users?: Maybe<Project_User_Arr_Rel_Insert_Input>;
  recording_recording_tags?: Maybe<Recording_Recording_Tag_Arr_Rel_Insert_Input>;
  recordings?: Maybe<Recordings_Arr_Rel_Insert_Input>;
  role_users?: Maybe<Role_User_Arr_Rel_Insert_Input>;
  user_option_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_option_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_option_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_option_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_option_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: Maybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  observances_aggregate?: Maybe<Integration_Test_Observances_Aggregate_Order_By>;
  option?: Maybe<Options_Order_By>;
  project_dataset_aggregate?: Maybe<Project_Dataset_Aggregate_Order_By>;
  project_users_aggregate?: Maybe<Project_User_Aggregate_Order_By>;
  recording_recording_tags_aggregate?: Maybe<Recording_Recording_Tag_Aggregate_Order_By>;
  recordings_aggregate?: Maybe<Recordings_Aggregate_Order_By>;
  role_users_aggregate?: Maybe<Role_User_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  user_option_id?: Maybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserOptionId = 'user_option_id'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  user_option_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_option_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UserOptionId = 'user_option_id'
}

export type Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "xtensa_configurations" */
export type Xtensa_Configurations = {
  __typename?: 'xtensa_configurations';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  project?: Maybe<Projects>;
  updated_at: Scalars['timestamptz'];
  value?: Maybe<Scalars['String']>;
};

/** aggregated selection of "xtensa_configurations" */
export type Xtensa_Configurations_Aggregate = {
  __typename?: 'xtensa_configurations_aggregate';
  aggregate?: Maybe<Xtensa_Configurations_Aggregate_Fields>;
  nodes: Array<Xtensa_Configurations>;
};

/** aggregate fields of "xtensa_configurations" */
export type Xtensa_Configurations_Aggregate_Fields = {
  __typename?: 'xtensa_configurations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Xtensa_Configurations_Max_Fields>;
  min?: Maybe<Xtensa_Configurations_Min_Fields>;
};


/** aggregate fields of "xtensa_configurations" */
export type Xtensa_Configurations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Xtensa_Configurations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "xtensa_configurations". All fields are combined with a logical 'AND'. */
export type Xtensa_Configurations_Bool_Exp = {
  _and?: Maybe<Array<Xtensa_Configurations_Bool_Exp>>;
  _not?: Maybe<Xtensa_Configurations_Bool_Exp>;
  _or?: Maybe<Array<Xtensa_Configurations_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  project?: Maybe<Projects_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "xtensa_configurations" */
export enum Xtensa_Configurations_Constraint {
  /** unique or primary key constraint on columns "id" */
  XtensaConfigurationsPkey = 'xtensa_configurations_pkey'
}

/** input type for inserting data into table "xtensa_configurations" */
export type Xtensa_Configurations_Insert_Input = {
  project?: Maybe<Projects_Obj_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Xtensa_Configurations_Max_Fields = {
  __typename?: 'xtensa_configurations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Xtensa_Configurations_Min_Fields = {
  __typename?: 'xtensa_configurations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "xtensa_configurations" */
export type Xtensa_Configurations_Mutation_Response = {
  __typename?: 'xtensa_configurations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Xtensa_Configurations>;
};

/** input type for inserting object relation for remote table "xtensa_configurations" */
export type Xtensa_Configurations_Obj_Rel_Insert_Input = {
  data: Xtensa_Configurations_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Xtensa_Configurations_On_Conflict>;
};

/** on_conflict condition type for table "xtensa_configurations" */
export type Xtensa_Configurations_On_Conflict = {
  constraint: Xtensa_Configurations_Constraint;
  update_columns?: Array<Xtensa_Configurations_Update_Column>;
  where?: Maybe<Xtensa_Configurations_Bool_Exp>;
};

/** Ordering options when selecting data from "xtensa_configurations". */
export type Xtensa_Configurations_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project?: Maybe<Projects_Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: xtensa_configurations */
export type Xtensa_Configurations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "xtensa_configurations" */
export enum Xtensa_Configurations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "xtensa_configurations" */
export type Xtensa_Configurations_Set_Input = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "xtensa_configurations" */
export type Xtensa_Configurations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Xtensa_Configurations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Xtensa_Configurations_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "xtensa_configurations" */
export enum Xtensa_Configurations_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Value = 'value'
}

export type Xtensa_Configurations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Xtensa_Configurations_Set_Input>;
  where: Xtensa_Configurations_Bool_Exp;
};

export type BenchmarkPlanFieldsFragment = (
  { __typename?: 'rabbit_test_plans' }
  & Pick<Rabbit_Test_Plans, 'id' | 'name' | 'description' | 'created_at' | 'updated_at' | 'deleted_at'>
);

export type AllBenchmarkPlansQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  where?: Maybe<Rabbit_Test_Plans_Bool_Exp>;
  order_by?: Maybe<Array<Rabbit_Test_Plans_Order_By> | Rabbit_Test_Plans_Order_By>;
}>;


export type AllBenchmarkPlansQuery = (
  { __typename?: 'query_root' }
  & { rabbit_test_plans_aggregate: (
    { __typename?: 'rabbit_test_plans_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'rabbit_test_plans_aggregate_fields' }
      & Pick<Rabbit_Test_Plans_Aggregate_Fields, 'count'>
    )> }
  ), rabbit_test_plans: Array<(
    { __typename?: 'rabbit_test_plans' }
    & { plan_projects: Array<(
      { __typename?: 'rabbit_test_plan_project' }
      & { project: (
        { __typename?: 'projects' }
        & { project_name?: Maybe<(
          { __typename?: 'project_name' }
          & Pick<Project_Name, 'name'>
        )> }
      ) }
    )> }
    & BenchmarkPlanFieldsFragment
  )> }
);

export type BenchmarkPlanQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type BenchmarkPlanQuery = (
  { __typename?: 'query_root' }
  & { rabbit_test_plan?: Maybe<(
    { __typename?: 'rabbit_test_plans' }
    & { plan_tasks: Array<(
      { __typename?: 'rabbit_test_plan_task' }
      & { task?: Maybe<(
        { __typename?: 'tasks' }
        & Pick<Tasks, 'id' | 'legacy_template_id'>
      )> }
    )> }
    & BenchmarkPlanFieldsFragment
  )> }
);

export type CreateBenchmarkPlanMutationVariables = Exact<{
  input: Rabbit_Test_Plans_Insert_Input;
}>;


export type CreateBenchmarkPlanMutation = (
  { __typename?: 'mutation_root' }
  & { rabbit_test_plan?: Maybe<(
    { __typename?: 'rabbit_test_plans' }
    & BenchmarkPlanFieldsFragment
  )> }
);

export type UpdateBenchmarkPlanMutationVariables = Exact<{
  id: Scalars['uuid'];
  input: Rabbit_Test_Plans_Set_Input;
  tasks_to_add: Array<Rabbit_Test_Plan_Task_Insert_Input> | Rabbit_Test_Plan_Task_Insert_Input;
  task_ids_to_remove: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type UpdateBenchmarkPlanMutation = (
  { __typename?: 'mutation_root' }
  & { rabbit_test_plan?: Maybe<(
    { __typename?: 'rabbit_test_plans' }
    & BenchmarkPlanFieldsFragment
  )>, add_tasks?: Maybe<(
    { __typename?: 'rabbit_test_plan_task_mutation_response' }
    & Pick<Rabbit_Test_Plan_Task_Mutation_Response, 'affected_rows'>
  )>, delete_tasks?: Maybe<(
    { __typename?: 'rabbit_test_plan_task_mutation_response' }
    & Pick<Rabbit_Test_Plan_Task_Mutation_Response, 'affected_rows'>
  )> }
);

export type AddProjectBenchmarkPlanMutationVariables = Exact<{
  projectId: Scalars['uuid'];
  planId: Scalars['uuid'];
}>;


export type AddProjectBenchmarkPlanMutation = (
  { __typename?: 'mutation_root' }
  & { insert_rabbit_test_plan_project?: Maybe<(
    { __typename?: 'rabbit_test_plan_project_mutation_response' }
    & Pick<Rabbit_Test_Plan_Project_Mutation_Response, 'affected_rows'>
  )> }
);

export type RemoveProjectBenchmarkPlanMutationVariables = Exact<{
  projectId: Scalars['uuid'];
  planId: Scalars['uuid'];
}>;


export type RemoveProjectBenchmarkPlanMutation = (
  { __typename?: 'mutation_root' }
  & { delete_rabbit_test_plan_project?: Maybe<(
    { __typename?: 'rabbit_test_plan_project_mutation_response' }
    & Pick<Rabbit_Test_Plan_Project_Mutation_Response, 'affected_rows'>
  )> }
);

export type ChecklistFieldsFragment = (
  { __typename?: 'bringup_checklists' }
  & Pick<Bringup_Checklists, 'id' | 'name' | 'description' | 'created_at' | 'updated_at' | 'deleted_at'>
);

export type AllChecklistsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  where?: Maybe<Bringup_Checklists_Bool_Exp>;
  order_by?: Maybe<Array<Bringup_Checklists_Order_By> | Bringup_Checklists_Order_By>;
}>;


export type AllChecklistsQuery = (
  { __typename?: 'query_root' }
  & { checklists_aggregate: (
    { __typename?: 'bringup_checklists_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'bringup_checklists_aggregate_fields' }
      & Pick<Bringup_Checklists_Aggregate_Fields, 'count'>
    )> }
  ), checklists: Array<(
    { __typename?: 'bringup_checklists' }
    & { checklist_deliverables: Array<(
      { __typename?: 'bringup_checklist_deliverable' }
      & { deliverable: (
        { __typename?: 'deliverables' }
        & Pick<Deliverables, 'id' | 'name'>
      ) }
    )> }
    & ChecklistFieldsFragment
  )> }
);

export type ChecklistQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type ChecklistQuery = (
  { __typename?: 'query_root' }
  & { checklist?: Maybe<(
    { __typename?: 'bringup_checklists' }
    & { checklist_deliverables: Array<(
      { __typename?: 'bringup_checklist_deliverable' }
      & { deliverable: (
        { __typename?: 'deliverables' }
        & Pick<Deliverables, 'id' | 'name'>
      ) }
    )>, items: Array<(
      { __typename?: 'bringup_items' }
      & Pick<Bringup_Items, 'id' | 'position' | 'name' | 'description' | 'objective' | 'personnel'>
      & { depends_on_item?: Maybe<(
        { __typename?: 'bringup_items' }
        & Pick<Bringup_Items, 'id' | 'name'>
      )>, milestone?: Maybe<(
        { __typename?: 'bringup_milestones' }
        & Pick<Bringup_Milestones, 'name' | 'description'>
      )>, endorsements_aggregate: (
        { __typename?: 'bringup_endorsements_aggregate' }
        & { aggregate?: Maybe<(
          { __typename?: 'bringup_endorsements_aggregate_fields' }
          & Pick<Bringup_Endorsements_Aggregate_Fields, 'count'>
        )> }
      ) }
    )> }
    & ChecklistFieldsFragment
  )> }
);

export type CreateChecklistMutationVariables = Exact<{
  input: Bringup_Checklists_Insert_Input;
}>;


export type CreateChecklistMutation = (
  { __typename?: 'mutation_root' }
  & { checklist?: Maybe<(
    { __typename?: 'bringup_checklists' }
    & ChecklistFieldsFragment
  )> }
);

export type UpdateChecklistMutationVariables = Exact<{
  id: Scalars['uuid'];
  columns: Bringup_Checklists_Set_Input;
  items: Array<Bringup_Items_Insert_Input> | Bringup_Items_Insert_Input;
  item_ids_to_delete: Array<Scalars['uuid']> | Scalars['uuid'];
  item_ids_without_milestones: Array<Scalars['uuid']> | Scalars['uuid'];
  milestones: Array<Bringup_Milestones_Insert_Input> | Bringup_Milestones_Insert_Input;
}>;


export type UpdateChecklistMutation = (
  { __typename?: 'mutation_root' }
  & { delete_bringup_items?: Maybe<(
    { __typename?: 'bringup_items_mutation_response' }
    & Pick<Bringup_Items_Mutation_Response, 'affected_rows'>
  )>, update_bringup_items?: Maybe<(
    { __typename?: 'bringup_items_mutation_response' }
    & Pick<Bringup_Items_Mutation_Response, 'affected_rows'>
  )>, items?: Maybe<(
    { __typename?: 'bringup_items_mutation_response' }
    & Pick<Bringup_Items_Mutation_Response, 'affected_rows'>
  )>, inserted_milestones?: Maybe<(
    { __typename?: 'bringup_milestones_mutation_response' }
    & Pick<Bringup_Milestones_Mutation_Response, 'affected_rows'>
  )>, deleted_milestones?: Maybe<(
    { __typename?: 'bringup_milestones_mutation_response' }
    & Pick<Bringup_Milestones_Mutation_Response, 'affected_rows'>
  )>, checklist?: Maybe<(
    { __typename?: 'bringup_checklists' }
    & ChecklistFieldsFragment
  )> }
);

export type ChecklistItemFragment = (
  { __typename?: 'bringup_items' }
  & Pick<Bringup_Items, 'id' | 'checklist_id' | 'position' | 'name' | 'description' | 'objective' | 'personnel'>
);

export type InsertChecklistItemEndorsementMutationVariables = Exact<{
  project_id: Scalars['uuid'];
  item_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
}>;


export type InsertChecklistItemEndorsementMutation = (
  { __typename?: 'mutation_root' }
  & { insert_bringup_endorsements_one?: Maybe<(
    { __typename?: 'bringup_endorsements' }
    & Pick<Bringup_Endorsements, 'project_id' | 'item_id' | 'created_by'>
  )> }
);

export type DeleteChecklistItemEndorsementsMutationVariables = Exact<{
  project_id: Scalars['uuid'];
  item_id: Scalars['uuid'];
}>;


export type DeleteChecklistItemEndorsementsMutation = (
  { __typename?: 'mutation_root' }
  & { delete_bringup_endorsements?: Maybe<(
    { __typename?: 'bringup_endorsements_mutation_response' }
    & { returning: Array<(
      { __typename?: 'bringup_endorsements' }
      & Pick<Bringup_Endorsements, 'project_id' | 'item_id'>
    )> }
  )> }
);

export type DeleteChecklistItemUserEndorsementsMutationVariables = Exact<{
  project_id: Scalars['uuid'];
  item_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
}>;


export type DeleteChecklistItemUserEndorsementsMutation = (
  { __typename?: 'mutation_root' }
  & { delete_bringup_endorsements?: Maybe<(
    { __typename?: 'bringup_endorsements_mutation_response' }
    & { returning: Array<(
      { __typename?: 'bringup_endorsements' }
      & Pick<Bringup_Endorsements, 'project_id' | 'item_id' | 'created_by'>
    )> }
  )> }
);

export type InsertChecklistItemCommentMutationVariables = Exact<{
  obj: Bringup_Project_Item_Comments_Insert_Input;
}>;


export type InsertChecklistItemCommentMutation = (
  { __typename?: 'mutation_root' }
  & { insert_bringup_project_item_comments_one?: Maybe<(
    { __typename?: 'bringup_project_item_comments' }
    & Pick<Bringup_Project_Item_Comments, 'project_id' | 'item_id' | 'created_at'>
  )> }
);

export type DeleteChecklistItemCommentMutationVariables = Exact<{
  project_id: Scalars['uuid'];
  item_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  deleted_at: Scalars['timestamp'];
}>;


export type DeleteChecklistItemCommentMutation = (
  { __typename?: 'mutation_root' }
  & { update_bringup_project_item_comments_by_pk?: Maybe<(
    { __typename?: 'bringup_project_item_comments' }
    & Pick<Bringup_Project_Item_Comments, 'project_id' | 'item_id' | 'created_at' | 'deleted_at'>
  )> }
);

export type UpdateChecklistItemCommentMutationVariables = Exact<{
  project_id: Scalars['uuid'];
  item_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  content: Scalars['String'];
}>;


export type UpdateChecklistItemCommentMutation = (
  { __typename?: 'mutation_root' }
  & { update_bringup_project_item_comments_by_pk?: Maybe<(
    { __typename?: 'bringup_project_item_comments' }
    & Pick<Bringup_Project_Item_Comments, 'project_id' | 'item_id' | 'created_at' | 'content'>
  )> }
);

export type CategoryFieldsFragment = (
  { __typename?: 'categories' }
  & Pick<Categories, 'id' | 'name' | 'priority' | 'created_at' | 'updated_at' | 'deleted_at'>
);

export type AllCategoriesQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  where?: Maybe<Categories_Bool_Exp>;
  order_by?: Maybe<Array<Categories_Order_By> | Categories_Order_By>;
}>;


export type AllCategoriesQuery = (
  { __typename?: 'query_root' }
  & { categories_aggregate: (
    { __typename?: 'categories_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'categories_aggregate_fields' }
      & Pick<Categories_Aggregate_Fields, 'count'>
    )> }
  ), categories: Array<(
    { __typename?: 'categories' }
    & { contexts_aggregate: (
      { __typename?: 'contexts_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'contexts_aggregate_fields' }
        & Pick<Contexts_Aggregate_Fields, 'count'>
      )> }
    ), contexts: Array<(
      { __typename?: 'contexts' }
      & ContextFieldsFragment
    )> }
    & CategoryFieldsFragment
  )> }
);

export type CategoryQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type CategoryQuery = (
  { __typename?: 'query_root' }
  & { category?: Maybe<(
    { __typename?: 'categories' }
    & { contexts: Array<(
      { __typename?: 'contexts' }
      & ContextFieldsFragment
    )> }
    & CategoryFieldsFragment
  )> }
);

export type CreateCategoryMutationVariables = Exact<{
  input: Categories_Insert_Input;
}>;


export type CreateCategoryMutation = (
  { __typename?: 'mutation_root' }
  & { category?: Maybe<(
    { __typename?: 'categories' }
    & CategoryFieldsFragment
  )> }
);

export type UpdateCategoryMutationVariables = Exact<{
  id: Scalars['uuid'];
  input: Categories_Set_Input;
}>;


export type UpdateCategoryMutation = (
  { __typename?: 'mutation_root' }
  & { category?: Maybe<(
    { __typename?: 'categories' }
    & CategoryFieldsFragment
  )> }
);

export type ContextFieldsFragment = (
  { __typename?: 'contexts' }
  & Pick<Contexts, 'id' | 'created_at' | 'updated_at' | 'deleted_at' | 'name'>
  & { category: (
    { __typename?: 'categories' }
    & CategoryFieldsFragment
  ) }
);

export type AllContextsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  where?: Maybe<Contexts_Bool_Exp>;
  order_by?: Maybe<Array<Contexts_Order_By> | Contexts_Order_By>;
}>;


export type AllContextsQuery = (
  { __typename?: 'query_root' }
  & { contexts_aggregate: (
    { __typename?: 'contexts_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'contexts_aggregate_fields' }
      & Pick<Contexts_Aggregate_Fields, 'count'>
    )> }
  ), contexts: Array<(
    { __typename?: 'contexts' }
    & { fields_aggregate: (
      { __typename?: 'fields_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'fields_aggregate_fields' }
        & Pick<Fields_Aggregate_Fields, 'count'>
      )> }
    ) }
    & ContextFieldsFragment
  )> }
);

export type ContextQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type ContextQuery = (
  { __typename?: 'query_root' }
  & { context?: Maybe<(
    { __typename?: 'contexts' }
    & { fields: Array<(
      { __typename?: 'fields' }
      & FieldFieldsFragment
    )>, context_displays: Array<(
      { __typename?: 'context_displays' }
      & Pick<Context_Displays, 'id' | 'name' | 'position'>
      & { context_display_fields: Array<(
        { __typename?: 'context_display_field' }
        & Pick<Context_Display_Field, 'field_id' | 'position'>
        & { field?: Maybe<(
          { __typename?: 'fields' }
          & Pick<Fields, 'id' | 'display'>
        )> }
      )> }
    )> }
    & ContextFieldsFragment
  )> }
);

export type CreateContextMutationVariables = Exact<{
  input: Contexts_Insert_Input;
}>;


export type CreateContextMutation = (
  { __typename?: 'mutation_root' }
  & { context?: Maybe<(
    { __typename?: 'contexts' }
    & ContextFieldsFragment
  )> }
);

export type UpdateContextMutationVariables = Exact<{
  id: Scalars['uuid'];
  input: Contexts_Set_Input;
}>;


export type UpdateContextMutation = (
  { __typename?: 'mutation_root' }
  & { context?: Maybe<(
    { __typename?: 'contexts' }
    & ContextFieldsFragment
  )> }
);

export type UpdateContextDisplaysMutationVariables = Exact<{
  context_id: Scalars['uuid'];
  insert_context_displays: Array<Context_Displays_Insert_Input> | Context_Displays_Insert_Input;
}>;


export type UpdateContextDisplaysMutation = (
  { __typename?: 'mutation_root' }
  & { delete_context_display_field?: Maybe<(
    { __typename?: 'context_display_field_mutation_response' }
    & Pick<Context_Display_Field_Mutation_Response, 'affected_rows'>
  )>, delete_context_displays?: Maybe<(
    { __typename?: 'context_displays_mutation_response' }
    & Pick<Context_Displays_Mutation_Response, 'affected_rows'>
  )>, insert_context_displays?: Maybe<(
    { __typename?: 'context_displays_mutation_response' }
    & Pick<Context_Displays_Mutation_Response, 'affected_rows'>
  )> }
);

export type FieldFieldsFragment = (
  { __typename?: 'fields' }
  & Pick<Fields, 'id' | 'display' | 'name' | 'description' | 'type_id' | 'created_at' | 'updated_at' | 'deleted_at'>
);

export type AllFieldsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fields_Order_By> | Fields_Order_By>;
  where?: Maybe<Fields_Bool_Exp>;
}>;


export type AllFieldsQuery = (
  { __typename?: 'query_root' }
  & { fields_aggregate: (
    { __typename?: 'fields_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'fields_aggregate_fields' }
      & Pick<Fields_Aggregate_Fields, 'count'>
    )> }
  ), fields: Array<(
    { __typename?: 'fields' }
    & { field_options_aggregate: (
      { __typename?: 'field_options_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'field_options_aggregate_fields' }
        & Pick<Field_Options_Aggregate_Fields, 'count'>
      )> }
    ), field_options: Array<(
      { __typename?: 'field_options' }
      & Pick<Field_Options, 'id'>
      & { option?: Maybe<(
        { __typename?: 'options' }
        & Pick<Options, 'id' | 'display'>
      )> }
    )>, context: (
      { __typename?: 'contexts' }
      & { category: (
        { __typename?: 'categories' }
        & CategoryFieldsFragment
      ) }
      & ContextFieldsFragment
    ), type: (
      { __typename?: 'types' }
      & TypeFieldsFragment
    ) }
    & FieldFieldsFragment
  )> }
);

export type FieldQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type FieldQuery = (
  { __typename?: 'query_root' }
  & { field?: Maybe<(
    { __typename?: 'fields' }
    & { context: (
      { __typename?: 'contexts' }
      & { category: (
        { __typename?: 'categories' }
        & CategoryFieldsFragment
      ) }
      & ContextFieldsFragment
    ), field_options: Array<(
      { __typename?: 'field_options' }
      & FieldOptionFieldsFragment
    )>, type: (
      { __typename?: 'types' }
      & TypeFieldsFragment
    ) }
    & FieldFieldsFragment
  )> }
);

export type CreateFieldMutationVariables = Exact<{
  input: Fields_Insert_Input;
}>;


export type CreateFieldMutation = (
  { __typename?: 'mutation_root' }
  & { field?: Maybe<(
    { __typename?: 'fields' }
    & FieldFieldsFragment
  )> }
);

export type UpdateFieldMutationVariables = Exact<{
  id: Scalars['uuid'];
  input: Fields_Set_Input;
}>;


export type UpdateFieldMutation = (
  { __typename?: 'mutation_root' }
  & { field?: Maybe<(
    { __typename?: 'fields' }
    & FieldFieldsFragment
  )> }
);

export type FieldTranslationsSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
  order_by?: Maybe<Array<Field_Translation_Order_By> | Field_Translation_Order_By>;
}>;


export type FieldTranslationsSubscription = (
  { __typename?: 'subscription_root' }
  & { translations: Array<(
    { __typename?: 'field_translation' }
    & Pick<Field_Translation, 'description' | 'display'>
    & { language: (
      { __typename?: 'languages' }
      & Pick<Languages, 'id' | 'description'>
    ) }
  )> }
);

export type UpsertFieldTranslationMutationVariables = Exact<{
  object: Field_Translation_Insert_Input;
}>;


export type UpsertFieldTranslationMutation = (
  { __typename?: 'mutation_root' }
  & { translation?: Maybe<(
    { __typename?: 'field_translation' }
    & Pick<Field_Translation, 'description' | 'display' | 'field_id' | 'language'>
  )> }
);

export type TypeFieldsFragment = (
  { __typename?: 'types' }
  & Pick<Types, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted_at'>
);

export type AllTypesQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Types_Order_By> | Types_Order_By>;
  where?: Maybe<Types_Bool_Exp>;
}>;


export type AllTypesQuery = (
  { __typename?: 'query_root' }
  & { types: Array<(
    { __typename?: 'types' }
    & TypeFieldsFragment
  )>, types_aggregate: (
    { __typename?: 'types_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'types_aggregate_fields' }
      & Pick<Types_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type OptionFieldsFragment = (
  { __typename?: 'options' }
  & Pick<Options, 'id' | 'display' | 'value' | 'created_at' | 'updated_at' | 'deleted_at'>
  & { type: (
    { __typename?: 'types' }
    & Pick<Types, 'id' | 'name'>
  ) }
);

export type AllOptionsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Options_Order_By> | Options_Order_By>;
  where?: Maybe<Options_Bool_Exp>;
}>;


export type AllOptionsQuery = (
  { __typename?: 'query_root' }
  & { options_aggregate: (
    { __typename?: 'options_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'options_aggregate_fields' }
      & Pick<Options_Aggregate_Fields, 'count'>
    )> }
  ), options: Array<(
    { __typename?: 'options' }
    & OptionFieldsFragment
  )> }
);

export type OptionsQueryVariables = Exact<{
  order_by?: Maybe<Array<Options_Order_By> | Options_Order_By>;
  where?: Maybe<Options_Bool_Exp>;
}>;


export type OptionsQuery = (
  { __typename?: 'query_root' }
  & { options: Array<(
    { __typename?: 'options' }
    & OptionFieldsFragment
  )> }
);

export type OptionQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type OptionQuery = (
  { __typename?: 'query_root' }
  & { option?: Maybe<(
    { __typename?: 'options' }
    & { type: (
      { __typename?: 'types' }
      & TypeFieldsFragment
    ) }
    & OptionFieldsFragment
  )> }
);

export type InsertOptionMutationVariables = Exact<{
  input: Options_Insert_Input;
}>;


export type InsertOptionMutation = (
  { __typename?: 'mutation_root' }
  & { option?: Maybe<(
    { __typename?: 'options' }
    & OptionFieldsFragment
  )> }
);

export type CreateOptionMutationVariables = Exact<{
  input: Options_Insert_Input;
}>;


export type CreateOptionMutation = (
  { __typename?: 'mutation_root' }
  & { option?: Maybe<(
    { __typename?: 'options' }
    & OptionFieldsFragment
  )> }
);

export type UpdateOptionMutationVariables = Exact<{
  id: Scalars['uuid'];
  input: Options_Set_Input;
}>;


export type UpdateOptionMutation = (
  { __typename?: 'mutation_root' }
  & { option?: Maybe<(
    { __typename?: 'options' }
    & OptionFieldsFragment
  )> }
);

export type OptionTranslationsSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
  order_by?: Maybe<Array<Option_Translation_Order_By> | Option_Translation_Order_By>;
}>;


export type OptionTranslationsSubscription = (
  { __typename?: 'subscription_root' }
  & { translations: Array<(
    { __typename?: 'option_translation' }
    & Pick<Option_Translation, 'display'>
    & { language: (
      { __typename?: 'languages' }
      & Pick<Languages, 'id' | 'description'>
    ) }
  )> }
);

export type UpsertOptionTranslationMutationVariables = Exact<{
  object: Option_Translation_Insert_Input;
}>;


export type UpsertOptionTranslationMutation = (
  { __typename?: 'mutation_root' }
  & { translation?: Maybe<(
    { __typename?: 'option_translation' }
    & Pick<Option_Translation, 'option_id' | 'language' | 'display'>
  )> }
);

export type TaskFieldsFragment = (
  { __typename?: 'tasks' }
  & Pick<Tasks, 'id' | 'note' | 'force_ground_truth_to_negative' | 'legacy_template_id' | 'is_template' | 'is_test' | 'description' | 'created_at' | 'updated_at' | 'deleted_at'>
  & { task_media: Array<(
    { __typename?: 'task_media' }
    & TaskMediaFragment
  )>, task_instruction?: Maybe<(
    { __typename?: 'task_instructions' }
    & Pick<Task_Instructions, 'id' | 'content' | 'status'>
  )> }
);

export type AllTasksQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  order_by?: Maybe<Array<Tasks_Order_By> | Tasks_Order_By>;
  where?: Maybe<Tasks_Bool_Exp>;
}>;


export type AllTasksQuery = (
  { __typename?: 'query_root' }
  & { tasks_aggregate: (
    { __typename?: 'tasks_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'tasks_aggregate_fields' }
      & Pick<Tasks_Aggregate_Fields, 'count'>
    )> }
  ), tasks: Array<(
    { __typename?: 'tasks' }
    & { task_field_options_aggregate: (
      { __typename?: 'task_field_option_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'task_field_option_aggregate_fields' }
        & Pick<Task_Field_Option_Aggregate_Fields, 'count'>
      )> }
    ), plan_tasks: Array<(
      { __typename?: 'plan_tasks' }
      & Pick<Plan_Tasks, 'plan_id'>
    )>, recordings_aggregate: (
      { __typename?: 'recordings_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'recordings_aggregate_fields' }
        & Pick<Recordings_Aggregate_Fields, 'count'>
      )> }
    ) }
    & TaskFieldsFragment
  )> }
);

export type TaskQueryVariables = Exact<{
  id: Scalars['uuid'];
  order_field_options_by?: Maybe<Array<Task_Field_Option_Order_By> | Task_Field_Option_Order_By>;
}>;


export type TaskQuery = (
  { __typename?: 'query_root' }
  & { task?: Maybe<(
    { __typename?: 'tasks' }
    & { task_field_options: Array<(
      { __typename?: 'task_field_option' }
      & Pick<Task_Field_Option, 'task_id' | 'field_option_id' | 'highlight'>
      & { field_option: (
        { __typename?: 'field_options' }
        & Pick<Field_Options, 'id'>
        & { field?: Maybe<(
          { __typename?: 'fields' }
          & Pick<Fields, 'id' | 'type_id' | 'name' | 'display'>
          & { context: (
            { __typename?: 'contexts' }
            & Pick<Contexts, 'id' | 'name'>
            & { category: (
              { __typename?: 'categories' }
              & Pick<Categories, 'id' | 'name'>
            ) }
          ) }
        )>, option?: Maybe<(
          { __typename?: 'options' }
          & Pick<Options, 'id' | 'display'>
        )> }
      ) }
    )>, project_tasks_aggregate: (
      { __typename?: 'project_tasks_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'project_tasks_aggregate_fields' }
        & Pick<Project_Tasks_Aggregate_Fields, 'count'>
      )> }
    ), plan_tasks_aggregate: (
      { __typename?: 'plan_tasks_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'plan_tasks_aggregate_fields' }
        & Pick<Plan_Tasks_Aggregate_Fields, 'count'>
      )> }
    ), recordings_aggregate: (
      { __typename?: 'recordings_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'recordings_aggregate_fields' }
        & Pick<Recordings_Aggregate_Fields, 'count'>
      )> }
    ) }
    & TaskFieldsFragment
  )> }
);

export type TaskSimpleQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type TaskSimpleQuery = (
  { __typename?: 'query_root' }
  & { task?: Maybe<(
    { __typename?: 'tasks' }
    & TaskFieldsFragment
  )> }
);

export type TaskOptionDisplaySubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type TaskOptionDisplaySubscription = (
  { __typename?: 'subscription_root' }
  & { categories: Array<(
    { __typename?: 'categories' }
    & { contexts: Array<(
      { __typename?: 'contexts' }
      & { context_displays: Array<(
        { __typename?: 'context_displays' }
        & Pick<Context_Displays, 'name' | 'position'>
        & { context_display_fields: Array<(
          { __typename?: 'context_display_field' }
          & { field?: Maybe<(
            { __typename?: 'fields' }
            & { field_options: Array<(
              { __typename?: 'field_options' }
              & { option?: Maybe<(
                { __typename?: 'options' }
                & OptionFieldsFragment
              )> }
            )> }
            & FieldFieldsFragment
          )> }
        )> }
      )> }
      & ContextFieldsFragment
    )> }
    & CategoryFieldsFragment
  )> }
);

export type TaskFieldOptionHashesQueryVariables = Exact<{
  where?: Maybe<Task_Field_Option_Hashes_Bool_Exp>;
}>;


export type TaskFieldOptionHashesQuery = (
  { __typename?: 'query_root' }
  & { task_field_option_hashes: Array<(
    { __typename?: 'task_field_option_hashes' }
    & Pick<Task_Field_Option_Hashes, 'md5'>
    & { task?: Maybe<(
      { __typename?: 'tasks' }
      & TaskFieldsFragment
    )> }
  )>, task_field_option_hashes_aggregate: (
    { __typename?: 'task_field_option_hashes_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'task_field_option_hashes_aggregate_fields' }
      & Pick<Task_Field_Option_Hashes_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type UpsertTaskMutationVariables = Exact<{
  update_task: Scalars['Boolean'];
  task_id?: Maybe<Scalars['uuid']>;
  input: Tasks_Insert_Input;
  field_option_ids_to_remove?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  media_ids_to_remove?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;


export type UpsertTaskMutation = (
  { __typename?: 'mutation_root' }
  & { task?: Maybe<(
    { __typename?: 'tasks' }
    & TaskFieldsFragment
  )>, remove_task_field_option?: Maybe<(
    { __typename?: 'task_field_option_mutation_response' }
    & { returning: Array<(
      { __typename?: 'task_field_option' }
      & Pick<Task_Field_Option, 'field_option_id'>
    )> }
  )>, remove_media?: Maybe<(
    { __typename?: 'task_media_mutation_response' }
    & { returning: Array<(
      { __typename?: 'task_media' }
      & Pick<Task_Media, 'media_id'>
    )> }
  )> }
);

export type UpdateTaskMutationVariables = Exact<{
  id: Scalars['uuid'];
  input: Tasks_Set_Input;
}>;


export type UpdateTaskMutation = (
  { __typename?: 'mutation_root' }
  & { task?: Maybe<(
    { __typename?: 'tasks' }
    & TaskFieldsFragment
  )> }
);

export type InsertTasksMutationVariables = Exact<{
  tasks: Array<Tasks_Insert_Input> | Tasks_Insert_Input;
}>;


export type InsertTasksMutation = (
  { __typename?: 'mutation_root' }
  & { tasks?: Maybe<(
    { __typename?: 'tasks_mutation_response' }
    & { returning: Array<(
      { __typename?: 'tasks' }
      & TaskFieldsFragment
    )> }
  )> }
);

export type TaskTranslationsSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
  order_by?: Maybe<Array<Task_Translation_Order_By> | Task_Translation_Order_By>;
}>;


export type TaskTranslationsSubscription = (
  { __typename?: 'subscription_root' }
  & { translations: Array<(
    { __typename?: 'task_translation' }
    & Pick<Task_Translation, 'note'>
    & { language: (
      { __typename?: 'languages' }
      & Pick<Languages, 'id' | 'description'>
    ) }
  )> }
);

export type UpsertTaskTranslationMutationVariables = Exact<{
  object: Task_Translation_Insert_Input;
}>;


export type UpsertTaskTranslationMutation = (
  { __typename?: 'mutation_root' }
  & { translation?: Maybe<(
    { __typename?: 'task_translation' }
    & Pick<Task_Translation, 'task_id' | 'language' | 'note'>
  )> }
);

export type FieldOptionFieldsFragment = (
  { __typename?: 'field_options' }
  & Pick<Field_Options, 'id' | 'created_at' | 'updated_at' | 'deleted_at'>
  & { field?: Maybe<(
    { __typename?: 'fields' }
    & Pick<Fields, 'id' | 'display'>
    & { context: (
      { __typename?: 'contexts' }
      & Pick<Contexts, 'name'>
      & { category: (
        { __typename?: 'categories' }
        & Pick<Categories, 'name'>
      ) }
    ) }
  )>, option?: Maybe<(
    { __typename?: 'options' }
    & Pick<Options, 'id' | 'display'>
  )>, type: (
    { __typename?: 'types' }
    & Pick<Types, 'id' | 'name'>
  ) }
);

export type TaskFieldOptionFieldsFragment = (
  { __typename?: 'task_field_option' }
  & Pick<Task_Field_Option, 'task_id' | 'field_option_id' | 'highlight'>
  & { field_option: (
    { __typename?: 'field_options' }
    & FieldOptionFieldsFragment
  ) }
);

export type AllFieldOptionsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Field_Options_Order_By> | Field_Options_Order_By>;
  where?: Maybe<Field_Options_Bool_Exp>;
}>;


export type AllFieldOptionsQuery = (
  { __typename?: 'query_root' }
  & { fo_aggregate: (
    { __typename?: 'field_options_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'field_options_aggregate_fields' }
      & Pick<Field_Options_Aggregate_Fields, 'count'>
    )> }
  ), fo: Array<(
    { __typename?: 'field_options' }
    & FieldOptionFieldsFragment
  )> }
);

export type InsertFieldOptionsMutationVariables = Exact<{
  input: Array<Field_Options_Insert_Input> | Field_Options_Insert_Input;
}>;


export type InsertFieldOptionsMutation = (
  { __typename?: 'mutation_root' }
  & { fos?: Maybe<(
    { __typename?: 'field_options_mutation_response' }
    & { returning: Array<(
      { __typename?: 'field_options' }
      & FieldOptionFieldsFragment
    )> }
  )> }
);

export type AllTaskFieldOptionsQueryVariables = Exact<{
  task_id: Scalars['uuid'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Field_Option_Order_By> | Task_Field_Option_Order_By>;
  where?: Maybe<Task_Field_Option_Bool_Exp>;
}>;


export type AllTaskFieldOptionsQuery = (
  { __typename?: 'query_root' }
  & { task?: Maybe<(
    { __typename?: 'tasks' }
    & { task_field_options_aggregate: (
      { __typename?: 'task_field_option_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'task_field_option_aggregate_fields' }
        & Pick<Task_Field_Option_Aggregate_Fields, 'count'>
      )> }
    ), task_field_options: Array<(
      { __typename?: 'task_field_option' }
      & TaskFieldOptionFieldsFragment
    )> }
  )> }
);

export type SetTaskFieldOptionHighlightMutationVariables = Exact<{
  task_id: Scalars['uuid'];
  field_option_id: Scalars['uuid'];
  highlight: Scalars['Boolean'];
}>;


export type SetTaskFieldOptionHighlightMutation = (
  { __typename?: 'mutation_root' }
  & { tfo?: Maybe<(
    { __typename?: 'task_field_option' }
    & Pick<Task_Field_Option, 'task_id' | 'field_option_id' | 'highlight'>
    & { task: (
      { __typename?: 'tasks' }
      & Pick<Tasks, 'id' | 'description'>
    ) }
  )> }
);

export type PlanFieldsFragment = (
  { __typename?: 'plans' }
  & Pick<Plans, 'id' | 'name' | 'description' | 'created_at' | 'updated_at' | 'deleted_at'>
);

export type AllPlansQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plans_Order_By> | Plans_Order_By>;
  where?: Maybe<Plans_Bool_Exp>;
}>;


export type AllPlansQuery = (
  { __typename?: 'query_root' }
  & { plans_aggregate: (
    { __typename?: 'plans_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'plans_aggregate_fields' }
      & Pick<Plans_Aggregate_Fields, 'count'>
    )> }
  ), plans: Array<(
    { __typename?: 'plans' }
    & { plan_tasks_aggregate: (
      { __typename?: 'plan_tasks_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'plan_tasks_aggregate_fields' }
        & Pick<Plan_Tasks_Aggregate_Fields, 'count'>
      )> }
    ) }
    & PlanFieldsFragment
  )> }
);

export type PlanQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type PlanQuery = (
  { __typename?: 'query_root' }
  & { plan?: Maybe<(
    { __typename?: 'plans' }
    & { plan_tasks_aggregate: (
      { __typename?: 'plan_tasks_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'plan_tasks_aggregate_fields' }
        & Pick<Plan_Tasks_Aggregate_Fields, 'count'>
      )> }
    ) }
    & PlanFieldsFragment
  )> }
);

export type PlanOverviewSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type PlanOverviewSubscription = (
  { __typename?: 'subscription_root' }
  & { plan?: Maybe<(
    { __typename?: 'plans' }
    & { plan_tasks_aggregate: (
      { __typename?: 'plan_tasks_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'plan_tasks_aggregate_fields' }
        & Pick<Plan_Tasks_Aggregate_Fields, 'count'>
      )> }
    ) }
    & PlanFieldsFragment
  )> }
);

export type CreatePlanMutationVariables = Exact<{
  input: Plans_Insert_Input;
}>;


export type CreatePlanMutation = (
  { __typename?: 'mutation_root' }
  & { plan?: Maybe<(
    { __typename?: 'plans' }
    & PlanFieldsFragment
  )> }
);

export type UpdatePlanMutationVariables = Exact<{
  id: Scalars['uuid'];
  plan: Plans_Set_Input;
  add_plan_tasks: Array<Plan_Tasks_Insert_Input> | Plan_Tasks_Insert_Input;
  remove_plan_task_ids: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type UpdatePlanMutation = (
  { __typename?: 'mutation_root' }
  & { plan?: Maybe<(
    { __typename?: 'plans' }
    & PlanFieldsFragment
  )>, added_plan_tasks?: Maybe<(
    { __typename?: 'plan_tasks_mutation_response' }
    & { returning: Array<(
      { __typename?: 'plan_tasks' }
      & PlanTaskFieldsFragment
    )> }
  )>, removed_plan_tasks?: Maybe<(
    { __typename?: 'plan_tasks_mutation_response' }
    & { returning: Array<(
      { __typename?: 'plan_tasks' }
      & PlanTaskFieldsFragment
    )> }
  )> }
);

export type PlanTaskFieldsFragment = (
  { __typename?: 'plan_tasks' }
  & Pick<Plan_Tasks, 'id' | 'priority' | 'min_per_device' | 'created_at' | 'updated_at'>
);

export type AllPlanTasksQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plan_Tasks_Order_By> | Plan_Tasks_Order_By>;
  where?: Maybe<Plan_Tasks_Bool_Exp>;
}>;


export type AllPlanTasksQuery = (
  { __typename?: 'query_root' }
  & { plan_tasks_aggregate: (
    { __typename?: 'plan_tasks_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'plan_tasks_aggregate_fields' }
      & Pick<Plan_Tasks_Aggregate_Fields, 'count'>
    )> }
  ), plan_tasks: Array<(
    { __typename?: 'plan_tasks' }
    & { task: (
      { __typename?: 'tasks' }
      & { task_field_options_aggregate: (
        { __typename?: 'task_field_option_aggregate' }
        & { aggregate?: Maybe<(
          { __typename?: 'task_field_option_aggregate_fields' }
          & Pick<Task_Field_Option_Aggregate_Fields, 'count'>
        )> }
      ) }
      & TaskFieldsFragment
    ) }
    & PlanTaskFieldsFragment
  )> }
);

export type UpdatePlanTaskMutationVariables = Exact<{
  id: Scalars['uuid'];
  input: Plan_Tasks_Set_Input;
}>;


export type UpdatePlanTaskMutation = (
  { __typename?: 'mutation_root' }
  & { plan_task?: Maybe<(
    { __typename?: 'plan_tasks' }
    & PlanTaskFieldsFragment
  )> }
);

export type MediaFieldsFragment = (
  { __typename?: 'media' }
  & Pick<Media, 'id' | 'created_at' | 'updated_at' | 'url' | 'title' | 'description' | 'type'>
);

export type AllMediaQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  where?: Maybe<Media_Bool_Exp>;
  order_by?: Maybe<Array<Media_Order_By> | Media_Order_By>;
}>;


export type AllMediaQuery = (
  { __typename?: 'query_root' }
  & { media_aggregate: (
    { __typename?: 'media_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'media_aggregate_fields' }
      & Pick<Media_Aggregate_Fields, 'count'>
    )> }
  ), media: Array<(
    { __typename?: 'media' }
    & MediaFieldsFragment
  )> }
);

export type MediaQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type MediaQuery = (
  { __typename?: 'query_root' }
  & { media?: Maybe<(
    { __typename?: 'media' }
    & MediaFieldsFragment
  )> }
);

export type CreateMediaMutationVariables = Exact<{
  input: Media_Insert_Input;
}>;


export type CreateMediaMutation = (
  { __typename?: 'mutation_root' }
  & { media?: Maybe<(
    { __typename?: 'media' }
    & MediaFieldsFragment
  )> }
);

export type UpdateMediaMutationVariables = Exact<{
  id: Scalars['uuid'];
  input: Media_Set_Input;
}>;


export type UpdateMediaMutation = (
  { __typename?: 'mutation_root' }
  & { media?: Maybe<(
    { __typename?: 'media' }
    & MediaFieldsFragment
  )> }
);

export type TaskMediaFragment = (
  { __typename?: 'task_media' }
  & Pick<Task_Media, 'task_id' | 'media_id'>
  & { media: (
    { __typename?: 'media' }
    & MediaFieldsFragment
  ) }
);

export type AllTaskMediaQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Media_Order_By> | Task_Media_Order_By>;
  where?: Maybe<Task_Media_Bool_Exp>;
}>;


export type AllTaskMediaQuery = (
  { __typename?: 'query_root' }
  & { tm_aggregate: (
    { __typename?: 'task_media_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'task_media_aggregate_fields' }
      & Pick<Task_Media_Aggregate_Fields, 'count'>
    )> }
  ), tm: Array<(
    { __typename?: 'task_media' }
    & TaskMediaFragment
  )> }
);

export type HypertaskFieldsFragment = (
  { __typename?: 'hypertasks' }
  & Pick<Hypertasks, 'id' | 'number' | 'description' | 'created_at' | 'updated_at' | 'deleted_at'>
);

export type AllHypertasksQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  where?: Maybe<Hypertasks_Bool_Exp>;
  order_by?: Maybe<Array<Hypertasks_Order_By> | Hypertasks_Order_By>;
}>;


export type AllHypertasksQuery = (
  { __typename?: 'query_root' }
  & { hypertasks_aggregate: (
    { __typename?: 'hypertasks_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'hypertasks_aggregate_fields' }
      & Pick<Hypertasks_Aggregate_Fields, 'count'>
    )> }
  ), hypertasks: Array<(
    { __typename?: 'hypertasks' }
    & { recordings_aggregate: (
      { __typename?: 'recordings_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'recordings_aggregate_fields' }
        & Pick<Recordings_Aggregate_Fields, 'count'>
      )> }
    ) }
    & HypertaskFieldsFragment
  )> }
);

export type HypertaskQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type HypertaskQuery = (
  { __typename?: 'query_root' }
  & { hypertask?: Maybe<(
    { __typename?: 'hypertasks' }
    & { hypertask_field_options: Array<(
      { __typename?: 'hypertask_field_option' }
      & { field: (
        { __typename?: 'fields' }
        & Pick<Fields, 'id' | 'name' | 'type_id'>
        & { context: (
          { __typename?: 'contexts' }
          & Pick<Contexts, 'id' | 'name'>
          & { category: (
            { __typename?: 'categories' }
            & Pick<Categories, 'id' | 'name'>
          ) }
        ) }
      ), option: (
        { __typename?: 'options' }
        & Pick<Options, 'id' | 'display'>
      ) }
    )> }
    & HypertaskFieldsFragment
  )> }
);

export type CreateHypertaskMutationVariables = Exact<{
  input: Hypertasks_Insert_Input;
}>;


export type CreateHypertaskMutation = (
  { __typename?: 'mutation_root' }
  & { hypertask?: Maybe<(
    { __typename?: 'hypertasks' }
    & HypertaskFieldsFragment
  )> }
);

export type UpdateHypertaskMutationVariables = Exact<{
  id: Scalars['uuid'];
  columns: Hypertasks_Set_Input;
  fieldOptions: Array<Hypertask_Field_Option_Insert_Input> | Hypertask_Field_Option_Insert_Input;
}>;


export type UpdateHypertaskMutation = (
  { __typename?: 'mutation_root' }
  & { hypertask?: Maybe<(
    { __typename?: 'hypertasks' }
    & HypertaskFieldsFragment
  )>, insert_hypertask_field_option?: Maybe<(
    { __typename?: 'hypertask_field_option_mutation_response' }
    & Pick<Hypertask_Field_Option_Mutation_Response, 'affected_rows'>
  )> }
);

export type CustomerFieldsFragment = (
  { __typename?: 'customers' }
  & Pick<Customers, 'id' | 'codename' | 'created_at' | 'updated_at' | 'deleted_at'>
);

export type AllCustomersQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customers_Order_By> | Customers_Order_By>;
  where?: Maybe<Customers_Bool_Exp>;
}>;


export type AllCustomersQuery = (
  { __typename?: 'query_root' }
  & { customers_aggregate: (
    { __typename?: 'customers_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'customers_aggregate_fields' }
      & Pick<Customers_Aggregate_Fields, 'count'>
    )> }
  ), customers: Array<(
    { __typename?: 'customers' }
    & { projects: Array<(
      { __typename?: 'projects' }
      & Pick<Projects, 'id'>
      & { project_name?: Maybe<(
        { __typename?: 'project_name' }
        & Pick<Project_Name, 'display_name'>
      )> }
    )> }
    & CustomerFieldsFragment
  )> }
);

export type CustomerQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type CustomerQuery = (
  { __typename?: 'query_root' }
  & { customer?: Maybe<(
    { __typename?: 'customers' }
    & CustomerFieldsFragment
  )> }
);

export type CreateCustomerMutationVariables = Exact<{
  codename: Scalars['String'];
}>;


export type CreateCustomerMutation = (
  { __typename?: 'mutation_root' }
  & { customer?: Maybe<(
    { __typename?: 'customers' }
    & CustomerFieldsFragment
  )> }
);

export type UpdateCustomerMutationVariables = Exact<{
  id: Scalars['uuid'];
  input: Customers_Set_Input;
}>;


export type UpdateCustomerMutation = (
  { __typename?: 'mutation_root' }
  & { customer?: Maybe<(
    { __typename?: 'customers' }
    & CustomerFieldsFragment
  )> }
);

export type DeliverableFieldsFragment = (
  { __typename?: 'deliverables' }
  & Pick<Deliverables, 'id' | 'name' | 'description' | 'num_states' | 'ground_truth_type_id' | 'deleted_at'>
  & { ground_truth_type?: Maybe<(
    { __typename?: 'ground_truth_types' }
    & Pick<Ground_Truth_Types, 'id' | 'name' | 'default_near_threshold' | 'default_far_threshold' | 'min_near_threshold' | 'max_near_threshold' | 'min_far_threshold' | 'max_far_threshold'>
  )> }
);

export type AllDeliverablesQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliverables_Order_By> | Deliverables_Order_By>;
  where?: Maybe<Deliverables_Bool_Exp>;
}>;


export type AllDeliverablesQuery = (
  { __typename?: 'query_root' }
  & { deliverables_aggregate: (
    { __typename?: 'deliverables_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'deliverables_aggregate_fields' }
      & Pick<Deliverables_Aggregate_Fields, 'count'>
    )> }
  ), deliverables: Array<(
    { __typename?: 'deliverables' }
    & { projects: Array<(
      { __typename?: 'projects' }
      & Pick<Projects, 'id'>
      & ProjectCmdFragment
    )> }
    & DeliverableFieldsFragment
  )> }
);

export type DeliverableQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeliverableQuery = (
  { __typename?: 'query_root' }
  & { deliverable?: Maybe<(
    { __typename?: 'deliverables' }
    & { projects: Array<(
      { __typename?: 'projects' }
      & Pick<Projects, 'id'>
      & ProjectCmdFragment
    )> }
    & DeliverableFieldsFragment
  )> }
);

export type CreateDeliverableMutationVariables = Exact<{
  input: Deliverables_Insert_Input;
}>;


export type CreateDeliverableMutation = (
  { __typename?: 'mutation_root' }
  & { deliverable?: Maybe<(
    { __typename?: 'deliverables' }
    & DeliverableFieldsFragment
  )> }
);

export type UpdateDeliverableMutationVariables = Exact<{
  id: Scalars['uuid'];
  input: Deliverables_Set_Input;
}>;


export type UpdateDeliverableMutation = (
  { __typename?: 'mutation_root' }
  & { deliverable?: Maybe<(
    { __typename?: 'deliverables' }
    & DeliverableFieldsFragment
  )> }
);

export type DriverVersionFieldsFragment = (
  { __typename?: 'deliveries_driver_versions' }
  & Pick<Deliveries_Driver_Versions, 'id' | 'name' | 'version' | 'storage_blob_url' | 'sbom_blob_url' | 'created_at' | 'updated_at' | 'deleted_at'>
  & { driver_version_engine?: Maybe<(
    { __typename?: 'deliveries_driver_version_engine' }
    & { engine: (
      { __typename?: 'engines' }
      & EngineFieldsFragment
    ) }
  )>, driver: (
    { __typename?: 'deliveries_drivers' }
    & { driver_project?: Maybe<(
      { __typename?: 'deliveries_driver_project' }
      & { project: (
        { __typename?: 'projects' }
        & { project_name?: Maybe<(
          { __typename?: 'project_name' }
          & Pick<Project_Name, 'name'>
        )> }
      ) }
    )> }
  ) }
);

export type AllDriverVersionsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  where?: Maybe<Deliveries_Driver_Versions_Bool_Exp>;
  order_by?: Maybe<Array<Deliveries_Driver_Versions_Order_By> | Deliveries_Driver_Versions_Order_By>;
}>;


export type AllDriverVersionsQuery = (
  { __typename?: 'query_root' }
  & { driver_versions_aggregate: (
    { __typename?: 'deliveries_driver_versions_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'deliveries_driver_versions_aggregate_fields' }
      & Pick<Deliveries_Driver_Versions_Aggregate_Fields, 'count'>
    )> }
  ), driver_versions: Array<(
    { __typename?: 'deliveries_driver_versions' }
    & { driver: (
      { __typename?: 'deliveries_drivers' }
      & Pick<Deliveries_Drivers, 'name'>
    ) }
    & DriverVersionFieldsFragment
  )> }
);

export type DriverVersionQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DriverVersionQuery = (
  { __typename?: 'query_root' }
  & { driver_version?: Maybe<(
    { __typename?: 'deliveries_driver_versions' }
    & DriverVersionFieldsFragment
  )> }
);

export type CreateDriverVersionMutationVariables = Exact<{
  input: Deliveries_Driver_Versions_Insert_Input;
}>;


export type CreateDriverVersionMutation = (
  { __typename?: 'mutation_root' }
  & { driver_version?: Maybe<(
    { __typename?: 'deliveries_driver_versions' }
    & DriverVersionFieldsFragment
  )> }
);

export type UpdateDriverVersionMutationVariables = Exact<{
  id: Scalars['uuid'];
  input: Deliveries_Driver_Versions_Set_Input;
}>;


export type UpdateDriverVersionMutation = (
  { __typename?: 'mutation_root' }
  & { driver_version?: Maybe<(
    { __typename?: 'deliveries_driver_versions' }
    & DriverVersionFieldsFragment
  )> }
);

export type DriverFieldsFragment = (
  { __typename?: 'deliveries_drivers' }
  & Pick<Deliveries_Drivers, 'id' | 'name' | 'description' | 'deleted_at'>
  & { driver_versions: Array<(
    { __typename?: 'deliveries_driver_versions' }
    & DriverVersionFieldsFragment
  )>, driver_project?: Maybe<(
    { __typename?: 'deliveries_driver_project' }
    & { project: (
      { __typename?: 'projects' }
      & Pick<Projects, 'id'>
      & { project_name?: Maybe<(
        { __typename?: 'project_name' }
        & Pick<Project_Name, 'name'>
      )> }
    ) }
  )> }
);

export type AllDriversQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  where?: Maybe<Deliveries_Drivers_Bool_Exp>;
  order_by?: Maybe<Array<Deliveries_Drivers_Order_By> | Deliveries_Drivers_Order_By>;
}>;


export type AllDriversQuery = (
  { __typename?: 'query_root' }
  & { drivers_aggregate: (
    { __typename?: 'deliveries_drivers_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'deliveries_drivers_aggregate_fields' }
      & Pick<Deliveries_Drivers_Aggregate_Fields, 'count'>
    )> }
  ), drivers: Array<(
    { __typename?: 'deliveries_drivers' }
    & DriverFieldsFragment
  )> }
);

export type DriverQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DriverQuery = (
  { __typename?: 'query_root' }
  & { driver?: Maybe<(
    { __typename?: 'deliveries_drivers' }
    & DriverFieldsFragment
  )> }
);

export type CreateDriverMutationVariables = Exact<{
  input: Deliveries_Drivers_Insert_Input;
}>;


export type CreateDriverMutation = (
  { __typename?: 'mutation_root' }
  & { driver?: Maybe<(
    { __typename?: 'deliveries_drivers' }
    & DriverFieldsFragment
  )> }
);

export type UpdateDriverMutationVariables = Exact<{
  id: Scalars['uuid'];
  columns: Deliveries_Drivers_Set_Input;
  add_driver_projects: Array<Deliveries_Driver_Project_Insert_Input> | Deliveries_Driver_Project_Insert_Input;
  delete_driver_project_ids: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type UpdateDriverMutation = (
  { __typename?: 'mutation_root' }
  & { driver?: Maybe<(
    { __typename?: 'deliveries_drivers' }
    & DriverFieldsFragment
  )>, delete_driver_project?: Maybe<(
    { __typename?: 'deliveries_driver_project_mutation_response' }
    & Pick<Deliveries_Driver_Project_Mutation_Response, 'affected_rows'>
  )>, add_driver_projects?: Maybe<(
    { __typename?: 'deliveries_driver_project_mutation_response' }
    & Pick<Deliveries_Driver_Project_Mutation_Response, 'affected_rows'>
  )> }
);

export type PackageFieldsFragment = (
  { __typename?: 'deliveries_packages' }
  & Pick<Deliveries_Packages, 'id' | 'name' | 'version' | 'spec_package_id' | 'external_package_name' | 'slack_channel' | 'sharepoint_upload_path' | 'sharepoint_release_notes_path' | 'bundle_struct_version' | 'url' | 'sbom_blob_url' | 'created_at' | 'updated_at' | 'deleted_at' | 'major_version' | 'minor_version'>
  & { customer: (
    { __typename?: 'customers' }
    & Pick<Customers, 'id' | 'codename'>
  ), architecture?: Maybe<(
    { __typename?: 'deliveries_architectures' }
    & Pick<Deliveries_Architectures, 'id' | 'name'>
  )>, platform?: Maybe<(
    { __typename?: 'deliveries_platforms' }
    & Pick<Deliveries_Platforms, 'id' | 'name'>
  )> }
);

export type AllArchitecturesQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  where?: Maybe<Deliveries_Architectures_Bool_Exp>;
  order_by?: Maybe<Array<Deliveries_Architectures_Order_By> | Deliveries_Architectures_Order_By>;
}>;


export type AllArchitecturesQuery = (
  { __typename?: 'query_root' }
  & { architectures: Array<(
    { __typename?: 'deliveries_architectures' }
    & Pick<Deliveries_Architectures, 'id' | 'name'>
  )> }
);

export type AllPlatformsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  where?: Maybe<Deliveries_Platforms_Bool_Exp>;
  order_by?: Maybe<Array<Deliveries_Platforms_Order_By> | Deliveries_Platforms_Order_By>;
}>;


export type AllPlatformsQuery = (
  { __typename?: 'query_root' }
  & { platforms: Array<(
    { __typename?: 'deliveries_platforms' }
    & Pick<Deliveries_Platforms, 'id' | 'name'>
  )> }
);

export type AllPackagesQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  where?: Maybe<Deliveries_Packages_Bool_Exp>;
  order_by?: Maybe<Array<Deliveries_Packages_Order_By> | Deliveries_Packages_Order_By>;
}>;


export type AllPackagesQuery = (
  { __typename?: 'query_root' }
  & { packages_aggregate: (
    { __typename?: 'deliveries_packages_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'deliveries_packages_aggregate_fields' }
      & Pick<Deliveries_Packages_Aggregate_Fields, 'count'>
    )> }
  ), packages: Array<(
    { __typename?: 'deliveries_packages' }
    & { released_packages: Array<(
      { __typename?: 'deliveries_packages' }
      & Pick<Deliveries_Packages, 'version'>
    )> }
    & PackageFieldsFragment
  )> }
);

export type PackageQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type PackageQuery = (
  { __typename?: 'query_root' }
  & { package?: Maybe<(
    { __typename?: 'deliveries_packages' }
    & { driver_packages: Array<(
      { __typename?: 'deliveries_driver_package' }
      & { driver: (
        { __typename?: 'deliveries_drivers' }
        & DriverFieldsFragment
      ) }
    )>, driver_version_packages: Array<(
      { __typename?: 'deliveries_driver_version_package' }
      & { driver_version: (
        { __typename?: 'deliveries_driver_versions' }
        & Pick<Deliveries_Driver_Versions, 'id' | 'name' | 'version' | 'storage_blob_url' | 'sbom_blob_url'>
        & { driver_version_engine?: Maybe<(
          { __typename?: 'deliveries_driver_version_engine' }
          & { engine: (
            { __typename?: 'engines' }
            & EngineFieldsFragment
          ) }
        )>, driver: (
          { __typename?: 'deliveries_drivers' }
          & Pick<Deliveries_Drivers, 'id' | 'name'>
          & { driver_project?: Maybe<(
            { __typename?: 'deliveries_driver_project' }
            & { project: (
              { __typename?: 'projects' }
              & Pick<Projects, 'id'>
              & { project_name?: Maybe<(
                { __typename?: 'project_name' }
                & Pick<Project_Name, 'name'>
              )> }
            ) }
          )> }
        ) }
      ) }
    )>, package_operating_systems: Array<(
      { __typename?: 'deliveries_package_operating_systems' }
      & Pick<Deliveries_Package_Operating_Systems, 'os_id' | 'is_tested'>
    )>, package_targets: Array<(
      { __typename?: 'deliveries_package_target' }
      & { target: (
        { __typename?: 'deliveries_targets' }
        & Pick<Deliveries_Targets, 'id'>
        & { model: (
          { __typename?: 'models' }
          & Pick<Models, 'codename'>
        ), customer: (
          { __typename?: 'customers' }
          & Pick<Customers, 'codename'>
        ) }
      ) }
    )>, targets: Array<(
      { __typename?: 'deliveries_driver_version_package' }
      & { driver_version: (
        { __typename?: 'deliveries_driver_versions' }
        & Pick<Deliveries_Driver_Versions, 'id' | 'name' | 'version'>
        & { driver_version_engine?: Maybe<(
          { __typename?: 'deliveries_driver_version_engine' }
          & { engine: (
            { __typename?: 'engines' }
            & Pick<Engines, 'id' | 'build_number'>
          ) }
        )>, driver: (
          { __typename?: 'deliveries_drivers' }
          & { driver_project?: Maybe<(
            { __typename?: 'deliveries_driver_project' }
            & { project: (
              { __typename?: 'projects' }
              & Pick<Projects, 'id'>
              & { project_name?: Maybe<(
                { __typename?: 'project_name' }
                & Pick<Project_Name, 'name'>
              )> }
            ) }
          )> }
        ) }
      ) }
    )> }
    & PackageFieldsFragment
  )> }
);

export type CreatePackageMutationVariables = Exact<{
  input: Deliveries_Packages_Insert_Input;
}>;


export type CreatePackageMutation = (
  { __typename?: 'mutation_root' }
  & { package?: Maybe<(
    { __typename?: 'deliveries_packages' }
    & PackageFieldsFragment
  )> }
);

export type UpdatePackageMutationVariables = Exact<{
  id: Scalars['uuid'];
  input: Deliveries_Packages_Set_Input;
  delete_driver_version_ids: Array<Scalars['uuid']> | Scalars['uuid'];
  delete_driver_ids: Array<Scalars['uuid']> | Scalars['uuid'];
  add_driver_versions: Array<Deliveries_Driver_Version_Package_Insert_Input> | Deliveries_Driver_Version_Package_Insert_Input;
  add_drivers: Array<Deliveries_Driver_Package_Insert_Input> | Deliveries_Driver_Package_Insert_Input;
  package_os_tests: Array<Deliveries_Package_Operating_Systems_Insert_Input> | Deliveries_Package_Operating_Systems_Insert_Input;
  delete_target_ids: Array<Scalars['uuid']> | Scalars['uuid'];
  add_targets: Array<Deliveries_Package_Target_Insert_Input> | Deliveries_Package_Target_Insert_Input;
}>;


export type UpdatePackageMutation = (
  { __typename?: 'mutation_root' }
  & { package?: Maybe<(
    { __typename?: 'deliveries_packages' }
    & PackageFieldsFragment
  )>, delete_driver_versions?: Maybe<(
    { __typename?: 'deliveries_driver_version_package_mutation_response' }
    & Pick<Deliveries_Driver_Version_Package_Mutation_Response, 'affected_rows'>
  )>, delete_drivers?: Maybe<(
    { __typename?: 'deliveries_driver_package_mutation_response' }
    & Pick<Deliveries_Driver_Package_Mutation_Response, 'affected_rows'>
  )>, add_driver_versions?: Maybe<(
    { __typename?: 'deliveries_driver_version_package_mutation_response' }
    & Pick<Deliveries_Driver_Version_Package_Mutation_Response, 'affected_rows'>
  )>, add_drivers?: Maybe<(
    { __typename?: 'deliveries_driver_package_mutation_response' }
    & Pick<Deliveries_Driver_Package_Mutation_Response, 'affected_rows'>
  )>, upsert_package_os_tests?: Maybe<(
    { __typename?: 'deliveries_package_operating_systems_mutation_response' }
    & Pick<Deliveries_Package_Operating_Systems_Mutation_Response, 'affected_rows'>
  )>, delete_targets?: Maybe<(
    { __typename?: 'deliveries_package_target_mutation_response' }
    & Pick<Deliveries_Package_Target_Mutation_Response, 'affected_rows'>
  )>, add_targets?: Maybe<(
    { __typename?: 'deliveries_package_target_mutation_response' }
    & Pick<Deliveries_Package_Target_Mutation_Response, 'affected_rows'>
  )> }
);

export type ArchivePackageMutationVariables = Exact<{
  id: Scalars['uuid'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
}>;


export type ArchivePackageMutation = (
  { __typename?: 'mutation_root' }
  & { update_deliveries_packages_by_pk?: Maybe<(
    { __typename?: 'deliveries_packages' }
    & Pick<Deliveries_Packages, 'id'>
  )> }
);

export type ArchiveDriverMutationVariables = Exact<{
  id: Scalars['uuid'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
}>;


export type ArchiveDriverMutation = (
  { __typename?: 'mutation_root' }
  & { update_deliveries_drivers_by_pk?: Maybe<(
    { __typename?: 'deliveries_drivers' }
    & Pick<Deliveries_Drivers, 'id'>
  )> }
);

export type CheckPackageHasVersionQueryVariables = Exact<{
  spec_package_id: Scalars['uuid'];
}>;


export type CheckPackageHasVersionQuery = (
  { __typename?: 'query_root' }
  & { versioned_packages: Array<(
    { __typename?: 'deliveries_packages' }
    & Pick<Deliveries_Packages, 'id'>
  )> }
);

export type CheckDriverHasVersionQueryVariables = Exact<{
  driver_id: Scalars['uuid'];
}>;


export type CheckDriverHasVersionQuery = (
  { __typename?: 'query_root' }
  & { versioned_drivers: Array<(
    { __typename?: 'deliveries_driver_versions' }
    & Pick<Deliveries_Driver_Versions, 'id'>
  )> }
);

export type ArtifactFieldsFragment = (
  { __typename?: 'deliveries_artifacts' }
  & Pick<Deliveries_Artifacts, 'id' | 'storage_blob_url' | 'sbom_url' | 'created_at'>
  & { package: (
    { __typename?: 'deliveries_packages' }
    & Pick<Deliveries_Packages, 'id' | 'name' | 'version'>
  ), artifact_operating_systems: Array<(
    { __typename?: 'deliveries_artifact_operating_system' }
    & { operating_system: (
      { __typename?: 'deliveries_operating_systems' }
      & Pick<Deliveries_Operating_Systems, 'id' | 'fullname'>
    ) }
  )> }
);

export type AllArtifactsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  where?: Maybe<Deliveries_Artifacts_Bool_Exp>;
  order_by?: Maybe<Array<Deliveries_Artifacts_Order_By> | Deliveries_Artifacts_Order_By>;
}>;


export type AllArtifactsQuery = (
  { __typename?: 'query_root' }
  & { artifacts_aggregate: (
    { __typename?: 'deliveries_artifacts_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'deliveries_artifacts_aggregate_fields' }
      & Pick<Deliveries_Artifacts_Aggregate_Fields, 'count'>
    )> }
  ), artifacts: Array<(
    { __typename?: 'deliveries_artifacts' }
    & ArtifactFieldsFragment
  )> }
);

export type MicrosoftWhqlSubmissionFieldsFragment = (
  { __typename?: 'deliveries_microsoft_whql_submissions' }
  & Pick<Deliveries_Microsoft_Whql_Submissions, 'id' | 'microsoft_partner_center_product_id' | 'microsoft_partner_center_product_name' | 'microsoft_partner_center_submission_id' | 'microsoft_partner_center_submission_name' | 'microsoft_partner_center_submission_type' | 'created_at'>
  & { artifact_microsoft_whql_submissions: Array<(
    { __typename?: 'deliveries_artifact_microsoft_whql_submission' }
    & { artifact: (
      { __typename?: 'deliveries_artifacts' }
      & Pick<Deliveries_Artifacts, 'id'>
      & { artifact_operating_systems: Array<(
        { __typename?: 'deliveries_artifact_operating_system' }
        & { operating_system: (
          { __typename?: 'deliveries_operating_systems' }
          & Pick<Deliveries_Operating_Systems, 'id' | 'fullname'>
        ) }
      )>, package: (
        { __typename?: 'deliveries_packages' }
        & Pick<Deliveries_Packages, 'version' | 'name'>
      ) }
    ) }
  )> }
);

export type CreateMicrosoftWhqlSubmissionMutationVariables = Exact<{
  input: Deliveries_Microsoft_Whql_Submissions_Insert_Input;
}>;


export type CreateMicrosoftWhqlSubmissionMutation = (
  { __typename?: 'mutation_root' }
  & { microsoft_whql_submission?: Maybe<(
    { __typename?: 'deliveries_microsoft_whql_submissions' }
    & MicrosoftWhqlSubmissionFieldsFragment
  )> }
);

export type AllMicrosoftWhqlSubmissionsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  where?: Maybe<Deliveries_Microsoft_Whql_Submissions_Bool_Exp>;
  order_by?: Maybe<Array<Deliveries_Microsoft_Whql_Submissions_Order_By> | Deliveries_Microsoft_Whql_Submissions_Order_By>;
}>;


export type AllMicrosoftWhqlSubmissionsQuery = (
  { __typename?: 'query_root' }
  & { microsoft_whql_submissions_aggregate: (
    { __typename?: 'deliveries_microsoft_whql_submissions_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'deliveries_microsoft_whql_submissions_aggregate_fields' }
      & Pick<Deliveries_Microsoft_Whql_Submissions_Aggregate_Fields, 'count'>
    )> }
  ), microsoft_whql_submissions: Array<(
    { __typename?: 'deliveries_microsoft_whql_submissions' }
    & MicrosoftWhqlSubmissionFieldsFragment
  )> }
);

export type MicrosoftWhqlSubmissionQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type MicrosoftWhqlSubmissionQuery = (
  { __typename?: 'query_root' }
  & { microsoft_whql_submission?: Maybe<(
    { __typename?: 'deliveries_microsoft_whql_submissions' }
    & MicrosoftWhqlSubmissionFieldsFragment
  )> }
);

export type PackageMicrosoftWhqlSubmissionsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  package_id: Scalars['uuid'];
}>;


export type PackageMicrosoftWhqlSubmissionsQuery = (
  { __typename?: 'query_root' }
  & { packageMicrosoftWHQLSubmissions_aggregate: (
    { __typename?: 'deliveries_microsoft_whql_submissions_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'deliveries_microsoft_whql_submissions_aggregate_fields' }
      & Pick<Deliveries_Microsoft_Whql_Submissions_Aggregate_Fields, 'count'>
    )> }
  ), packageMicrosoftWHQLSubmissions: Array<(
    { __typename?: 'deliveries_microsoft_whql_submissions' }
    & Pick<Deliveries_Microsoft_Whql_Submissions, 'id' | 'microsoft_partner_center_product_id' | 'microsoft_partner_center_product_name' | 'microsoft_partner_center_submission_id' | 'microsoft_partner_center_submission_name' | 'microsoft_partner_center_submission_type' | 'created_at'>
    & { artifact_microsoft_whql_submissions: Array<(
      { __typename?: 'deliveries_artifact_microsoft_whql_submission' }
      & { artifact: (
        { __typename?: 'deliveries_artifacts' }
        & Pick<Deliveries_Artifacts, 'id'>
        & { artifact_operating_systems: Array<(
          { __typename?: 'deliveries_artifact_operating_system' }
          & { operating_system: (
            { __typename?: 'deliveries_operating_systems' }
            & Pick<Deliveries_Operating_Systems, 'fullname'>
          ) }
        )> }
      ) }
    )> }
  )> }
);

export type UpdateMicrosoftWhqlSubmissionMutationVariables = Exact<{
  id: Scalars['uuid'];
  columns: Deliveries_Microsoft_Whql_Submissions_Set_Input;
}>;


export type UpdateMicrosoftWhqlSubmissionMutation = (
  { __typename?: 'mutation_root' }
  & { microsoft_whql_submission?: Maybe<(
    { __typename?: 'deliveries_microsoft_whql_submissions' }
    & MicrosoftWhqlSubmissionFieldsFragment
  )> }
);

export type MicrosoftShippingLabelFieldsFragment = (
  { __typename?: 'deliveries_microsoft_shipping_labels' }
  & Pick<Deliveries_Microsoft_Shipping_Labels, 'id' | 'microsoft_whql_submission_id' | 'microsoft_partner_center_shipping_label_id' | 'shipping_label_infos' | 'shipping_label_status' | 'shipping_label_name' | 'created_by' | 'target_id' | 'created_at' | 'updated_at' | 'updated_by'>
  & { user: (
    { __typename?: 'users' }
    & Pick<Users, 'name'>
  ), user_update?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'name'>
  )>, target: (
    { __typename?: 'deliveries_targets' }
    & { customer: (
      { __typename?: 'customers' }
      & Pick<Customers, 'codename'>
    ), model: (
      { __typename?: 'models' }
      & Pick<Models, 'codename'>
    ) }
  ), microsoft_shipping_label_review?: Maybe<(
    { __typename?: 'deliveries_microsoft_shipping_label_reviews' }
    & Pick<Deliveries_Microsoft_Shipping_Label_Reviews, 'status' | 'reviewed_by' | 'created_at'>
    & { user: (
      { __typename?: 'users' }
      & Pick<Users, 'name'>
    ) }
  )> }
);

export type CreateMicrosoftShippingLabelMutationVariables = Exact<{
  input: Deliveries_Microsoft_Shipping_Labels_Insert_Input;
}>;


export type CreateMicrosoftShippingLabelMutation = (
  { __typename?: 'mutation_root' }
  & { microsoft_shipping_label?: Maybe<(
    { __typename?: 'deliveries_microsoft_shipping_labels' }
    & MicrosoftShippingLabelFieldsFragment
  )> }
);

export type MicrosoftWhqlSubmissionsShippingLabelsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  microsoft_whql_submission_id: Scalars['uuid'];
}>;


export type MicrosoftWhqlSubmissionsShippingLabelsQuery = (
  { __typename?: 'query_root' }
  & { packageMicrosoftShippingLabels_aggregate: (
    { __typename?: 'deliveries_microsoft_shipping_labels_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'deliveries_microsoft_shipping_labels_aggregate_fields' }
      & Pick<Deliveries_Microsoft_Shipping_Labels_Aggregate_Fields, 'count'>
    )> }
  ), packageMicrosoftShippingLabels: Array<(
    { __typename?: 'deliveries_microsoft_shipping_labels' }
    & Pick<Deliveries_Microsoft_Shipping_Labels, 'id' | 'microsoft_partner_center_shipping_label_id' | 'shipping_label_infos' | 'shipping_label_status' | 'shipping_label_name' | 'created_at' | 'updated_at'>
    & { user: (
      { __typename?: 'users' }
      & Pick<Users, 'name'>
    ), user_update?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'name'>
    )>, target: (
      { __typename?: 'deliveries_targets' }
      & Pick<Deliveries_Targets, 'id'>
      & { model: (
        { __typename?: 'models' }
        & Pick<Models, 'codename'>
      ), customer: (
        { __typename?: 'customers' }
        & Pick<Customers, 'codename'>
      ) }
    ), microsoft_shipping_label_review?: Maybe<(
      { __typename?: 'deliveries_microsoft_shipping_label_reviews' }
      & Pick<Deliveries_Microsoft_Shipping_Label_Reviews, 'status'>
      & { user: (
        { __typename?: 'users' }
        & Pick<Users, 'name'>
      ) }
    )> }
  )> }
);

export type AllMicrosoftShippingLabelsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  where?: Maybe<Deliveries_Microsoft_Shipping_Labels_Bool_Exp>;
  order_by?: Maybe<Array<Deliveries_Microsoft_Shipping_Labels_Order_By> | Deliveries_Microsoft_Shipping_Labels_Order_By>;
}>;


export type AllMicrosoftShippingLabelsQuery = (
  { __typename?: 'query_root' }
  & { microsoft_shipping_labels_aggregate: (
    { __typename?: 'deliveries_microsoft_shipping_labels_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'deliveries_microsoft_shipping_labels_aggregate_fields' }
      & Pick<Deliveries_Microsoft_Shipping_Labels_Aggregate_Fields, 'count'>
    )> }
  ), microsoft_shipping_labels: Array<(
    { __typename?: 'deliveries_microsoft_shipping_labels' }
    & MicrosoftShippingLabelFieldsFragment
  )> }
);

export type MicrosoftShippingLabelQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type MicrosoftShippingLabelQuery = (
  { __typename?: 'query_root' }
  & { microsoft_shipping_label?: Maybe<(
    { __typename?: 'deliveries_microsoft_shipping_labels' }
    & MicrosoftShippingLabelFieldsFragment
  )> }
);

export type UpdateMicrosoftShippingLabelMutationVariables = Exact<{
  id: Scalars['uuid'];
  columns: Deliveries_Microsoft_Shipping_Labels_Set_Input;
}>;


export type UpdateMicrosoftShippingLabelMutation = (
  { __typename?: 'mutation_root' }
  & { microsoft_shipping_label?: Maybe<(
    { __typename?: 'deliveries_microsoft_shipping_labels' }
    & MicrosoftShippingLabelFieldsFragment
  )> }
);

export type AllMicrosoftHlkArtifactsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  where?: Maybe<Deliveries_Microsoft_Hlk_Artifacts_Bool_Exp>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deliveries_Microsoft_Hlk_Artifacts_Order_By> | Deliveries_Microsoft_Hlk_Artifacts_Order_By>;
}>;


export type AllMicrosoftHlkArtifactsQuery = (
  { __typename?: 'query_root' }
  & { microsoft_hlk_artifacts_aggregate: (
    { __typename?: 'deliveries_microsoft_hlk_artifacts_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'deliveries_microsoft_hlk_artifacts_aggregate_fields' }
      & Pick<Deliveries_Microsoft_Hlk_Artifacts_Aggregate_Fields, 'count'>
    )> }
  ), microsoft_hlk_artifacts: Array<(
    { __typename?: 'deliveries_microsoft_hlk_artifacts' }
    & Pick<Deliveries_Microsoft_Hlk_Artifacts, 'id' | 'storage_blob_url' | 'base_driver_version' | 'created_at'>
    & { operating_system: (
      { __typename?: 'deliveries_operating_systems' }
      & Pick<Deliveries_Operating_Systems, 'fullname'>
    ), device_family: (
      { __typename?: 'deliveries_device_families' }
      & Pick<Deliveries_Device_Families, 'codename'>
    ) }
  )> }
);

export type OperatingSystemsQueryVariables = Exact<{ [key: string]: never; }>;


export type OperatingSystemsQuery = (
  { __typename?: 'query_root' }
  & { operating_systems: Array<(
    { __typename?: 'deliveries_operating_systems' }
    & Pick<Deliveries_Operating_Systems, 'id' | 'codename' | 'fullname'>
  )> }
);

export type ProjectConfigFieldsFragment = (
  { __typename?: 'deliveries_project_configs' }
  & Pick<Deliveries_Project_Configs, 'id' | 'project_id' | 'config_id_type' | 'config_id' | 'chid_level' | 'controller_updates'>
  & { project: (
    { __typename?: 'projects' }
    & Pick<Projects, 'id'>
    & { project_name?: Maybe<(
      { __typename?: 'project_name' }
      & Pick<Project_Name, 'name'>
    )> }
  ) }
);

export type AllProjectConfigsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  where?: Maybe<Deliveries_Project_Configs_Bool_Exp>;
  order_by?: Maybe<Array<Deliveries_Project_Configs_Order_By> | Deliveries_Project_Configs_Order_By>;
}>;


export type AllProjectConfigsQuery = (
  { __typename?: 'query_root' }
  & { project_configs_aggregate: (
    { __typename?: 'deliveries_project_configs_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'deliveries_project_configs_aggregate_fields' }
      & Pick<Deliveries_Project_Configs_Aggregate_Fields, 'count'>
    )> }
  ), project_configs: Array<(
    { __typename?: 'deliveries_project_configs' }
    & ProjectConfigFieldsFragment
  )> }
);

export type ProjectConfigQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type ProjectConfigQuery = (
  { __typename?: 'query_root' }
  & { project_config?: Maybe<(
    { __typename?: 'deliveries_project_configs' }
    & ProjectConfigFieldsFragment
  )> }
);

export type CreateProjectConfigMutationVariables = Exact<{
  input: Deliveries_Project_Configs_Insert_Input;
}>;


export type CreateProjectConfigMutation = (
  { __typename?: 'mutation_root' }
  & { project_config?: Maybe<(
    { __typename?: 'deliveries_project_configs' }
    & ProjectConfigFieldsFragment
  )> }
);

export type UpdateProjectConfigMutationVariables = Exact<{
  id: Scalars['uuid'];
  columns: Deliveries_Project_Configs_Set_Input;
}>;


export type UpdateProjectConfigMutation = (
  { __typename?: 'mutation_root' }
  & { project_config?: Maybe<(
    { __typename?: 'deliveries_project_configs' }
    & ProjectConfigFieldsFragment
  )> }
);

export type TargetFieldsFragment = (
  { __typename?: 'deliveries_targets' }
  & Pick<Deliveries_Targets, 'id' | 'chids' | 'hardware_ids'>
  & { model: (
    { __typename?: 'models' }
    & Pick<Models, 'id' | 'codename'>
  ), customer: (
    { __typename?: 'customers' }
    & Pick<Customers, 'id' | 'codename'>
  ), target_partners: Array<(
    { __typename?: 'deliveries_target_partner' }
    & { partner: (
      { __typename?: 'deliveries_partners' }
      & Pick<Deliveries_Partners, 'id' | 'name'>
    ) }
  )> }
);

export type TargetQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type TargetQuery = (
  { __typename?: 'query_root' }
  & { target?: Maybe<(
    { __typename?: 'deliveries_targets' }
    & TargetFieldsFragment
  )> }
);

export type AllTargetsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  where?: Maybe<Deliveries_Targets_Bool_Exp>;
  order_by?: Maybe<Array<Deliveries_Targets_Order_By> | Deliveries_Targets_Order_By>;
}>;


export type AllTargetsQuery = (
  { __typename?: 'query_root' }
  & { targets_aggregate: (
    { __typename?: 'deliveries_targets_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'deliveries_targets_aggregate_fields' }
      & Pick<Deliveries_Targets_Aggregate_Fields, 'count'>
    )> }
  ), targets: Array<(
    { __typename?: 'deliveries_targets' }
    & TargetFieldsFragment
  )> }
);

export type CreateTargetMutationVariables = Exact<{
  input: Deliveries_Targets_Insert_Input;
}>;


export type CreateTargetMutation = (
  { __typename?: 'mutation_root' }
  & { target?: Maybe<(
    { __typename?: 'deliveries_targets' }
    & TargetFieldsFragment
  )> }
);

export type UpdateTargetMutationVariables = Exact<{
  id: Scalars['uuid'];
  columns: Deliveries_Targets_Set_Input;
  delete_partner_ids: Array<Scalars['uuid']> | Scalars['uuid'];
  add_partners: Array<Deliveries_Target_Partner_Insert_Input> | Deliveries_Target_Partner_Insert_Input;
}>;


export type UpdateTargetMutation = (
  { __typename?: 'mutation_root' }
  & { target?: Maybe<(
    { __typename?: 'deliveries_targets' }
    & TargetFieldsFragment
  )>, delete_target_partners?: Maybe<(
    { __typename?: 'deliveries_target_partner_mutation_response' }
    & Pick<Deliveries_Target_Partner_Mutation_Response, 'affected_rows'>
  )>, add_partners?: Maybe<(
    { __typename?: 'deliveries_target_partner_mutation_response' }
    & Pick<Deliveries_Target_Partner_Mutation_Response, 'affected_rows'>
  )> }
);

export type DeleteTargetMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteTargetMutation = (
  { __typename?: 'mutation_root' }
  & { delete_deliveries_targets_by_pk?: Maybe<(
    { __typename?: 'deliveries_targets' }
    & Pick<Deliveries_Targets, 'id'>
  )> }
);

export type AllPartnersQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  where?: Maybe<Deliveries_Partners_Bool_Exp>;
  order_by?: Maybe<Array<Deliveries_Partners_Order_By> | Deliveries_Partners_Order_By>;
}>;


export type AllPartnersQuery = (
  { __typename?: 'query_root' }
  & { partners: Array<(
    { __typename?: 'deliveries_partners' }
    & Pick<Deliveries_Partners, 'id' | 'name'>
  )> }
);

export type DeviceFieldsFragment = (
  { __typename?: 'devices' }
  & Pick<Devices, 'id' | 'serialno' | 'description'>
  & { model?: Maybe<(
    { __typename?: 'models' }
    & ModelFieldsFragment
  )>, recordings_aggregate: (
    { __typename?: 'recordings_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'recordings_aggregate_fields' }
      & Pick<Recordings_Aggregate_Fields, 'count'>
      & { max?: Maybe<(
        { __typename?: 'recordings_max_fields' }
        & Pick<Recordings_Max_Fields, 'recorded_at'>
      )> }
    )> }
  ) }
);

export type AllDevicesQueryVariables = Exact<{
  where?: Maybe<Devices_Bool_Exp>;
  order_by?: Maybe<Array<Devices_Order_By> | Devices_Order_By>;
}>;


export type AllDevicesQuery = (
  { __typename?: 'query_root' }
  & { devices_aggregate: (
    { __typename?: 'devices_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'devices_aggregate_fields' }
      & Pick<Devices_Aggregate_Fields, 'count'>
    )> }
  ), devices: Array<(
    { __typename?: 'devices' }
    & DeviceFieldsFragment
  )> }
);

export type DeviceQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeviceQuery = (
  { __typename?: 'query_root' }
  & { device?: Maybe<(
    { __typename?: 'devices' }
    & DeviceFieldsFragment
  )> }
);

export type CreateDeviceMutationVariables = Exact<{
  input: Devices_Insert_Input;
}>;


export type CreateDeviceMutation = (
  { __typename?: 'mutation_root' }
  & { device?: Maybe<(
    { __typename?: 'devices' }
    & DeviceFieldsFragment
  )> }
);

export type UpdateDeviceMutationVariables = Exact<{
  id: Scalars['uuid'];
  input: Devices_Set_Input;
}>;


export type UpdateDeviceMutation = (
  { __typename?: 'mutation_root' }
  & { device?: Maybe<(
    { __typename?: 'devices' }
    & DeviceFieldsFragment
  )> }
);

export type AggDevicesByProjectQueryVariables = Exact<{
  project_id: Scalars['uuid'];
}>;


export type AggDevicesByProjectQuery = (
  { __typename?: 'query_root' }
  & { project?: Maybe<(
    { __typename?: 'projects' }
    & { project_dataset: Array<(
      { __typename?: 'project_dataset' }
      & { device?: Maybe<(
        { __typename?: 'devices' }
        & Pick<Devices, 'id' | 'serialno' | 'description'>
        & { clean: (
          { __typename?: 'project_dataset_aggregate' }
          & { aggregate?: Maybe<(
            { __typename?: 'project_dataset_aggregate_fields' }
            & Pick<Project_Dataset_Aggregate_Fields, 'count'>
          )> }
        ), dirty: (
          { __typename?: 'project_dataset_aggregate' }
          & { aggregate?: Maybe<(
            { __typename?: 'project_dataset_aggregate_fields' }
            & Pick<Project_Dataset_Aggregate_Fields, 'count'>
          )> }
        ), untagged: (
          { __typename?: 'project_dataset_aggregate' }
          & { aggregate?: Maybe<(
            { __typename?: 'project_dataset_aggregate_fields' }
            & Pick<Project_Dataset_Aggregate_Fields, 'count'>
          )> }
        ) }
      )> }
    )> }
  )> }
);

export type EngineFieldsFragment = (
  { __typename?: 'engines' }
  & Pick<Engines, 'id' | 'azure_build_ref' | 'azure_release_ref' | 'branch' | 'build_definition_id_azure' | 'build_number' | 'build_version' | 'graph_version' | 'elliptic_engine_commit_hash' | 'nickname' | 'release_definition_id_azure' | 'type' | 'build_ref' | 'release_number'>
  & { engine_opmodes: Array<(
    { __typename?: 'engine_opmode' }
    & { opmode: (
      { __typename?: 'opmodes' }
      & Pick<Opmodes, 'id' | 'number'>
    ) }
  )> }
);

export type AllEnginesQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  where?: Maybe<Engines_Bool_Exp>;
  order_by?: Maybe<Array<Engines_Order_By> | Engines_Order_By>;
}>;


export type AllEnginesQuery = (
  { __typename?: 'query_root' }
  & { engines_aggregate: (
    { __typename?: 'engines_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'engines_aggregate_fields' }
      & Pick<Engines_Aggregate_Fields, 'count'>
    )> }
  ), engines: Array<(
    { __typename?: 'engines' }
    & EngineFieldsFragment
  )> }
);

export type CreateEngineMutationVariables = Exact<{
  input: Engines_Insert_Input;
}>;


export type CreateEngineMutation = (
  { __typename?: 'mutation_root' }
  & { insert_engines_one?: Maybe<(
    { __typename?: 'engines' }
    & EngineFieldsFragment
  )> }
);

export type GroundTruthTypeFieldsFragment = (
  { __typename?: 'ground_truth_types' }
  & Pick<Ground_Truth_Types, 'id' | 'name' | 'description' | 'default_near_threshold' | 'default_far_threshold'>
);

export type AllGroundTruthTypesQueryVariables = Exact<{
  where?: Maybe<Ground_Truth_Types_Bool_Exp>;
  order_by?: Maybe<Array<Ground_Truth_Types_Order_By> | Ground_Truth_Types_Order_By>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type AllGroundTruthTypesQuery = (
  { __typename?: 'query_root' }
  & { ground_truth_types_aggregate: (
    { __typename?: 'ground_truth_types_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'ground_truth_types_aggregate_fields' }
      & Pick<Ground_Truth_Types_Aggregate_Fields, 'count'>
    )> }
  ), ground_truth_types: Array<(
    { __typename?: 'ground_truth_types' }
    & GroundTruthTypeFieldsFragment
  )> }
);

export type GroundTruthTypeQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GroundTruthTypeQuery = (
  { __typename?: 'query_root' }
  & { ground_truth_type?: Maybe<(
    { __typename?: 'ground_truth_types' }
    & GroundTruthTypeFieldsFragment
  )> }
);

export type CreateGroundTruthTypeMutationVariables = Exact<{
  input: Ground_Truth_Types_Insert_Input;
}>;


export type CreateGroundTruthTypeMutation = (
  { __typename?: 'mutation_root' }
  & { ground_truth_type?: Maybe<(
    { __typename?: 'ground_truth_types' }
    & GroundTruthTypeFieldsFragment
  )> }
);

export type UpdateGroundTruthTypeMutationVariables = Exact<{
  id: Scalars['uuid'];
  input: Ground_Truth_Types_Set_Input;
}>;


export type UpdateGroundTruthTypeMutation = (
  { __typename?: 'mutation_root' }
  & { ground_truth_type?: Maybe<(
    { __typename?: 'ground_truth_types' }
    & GroundTruthTypeFieldsFragment
  )> }
);

export type InstructionFieldsFragment = (
  { __typename?: 'integration_test_instructions' }
  & Pick<Integration_Test_Instructions, 'id' | 'description'>
  & { instruction_translations: Array<(
    { __typename?: 'integration_test_instruction_translation' }
    & Pick<Integration_Test_Instruction_Translation, 'language' | 'description'>
  )>, instruction_media: Array<(
    { __typename?: 'integration_test_instruction_media' }
    & { medium: (
      { __typename?: 'media' }
      & Pick<Media, 'id' | 'title' | 'description' | 'type' | 'url'>
    ) }
  )> }
);

export type AllIntegrationTestInstructionsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Instructions_Order_By> | Integration_Test_Instructions_Order_By>;
  where?: Maybe<Integration_Test_Instructions_Bool_Exp>;
}>;


export type AllIntegrationTestInstructionsQuery = (
  { __typename?: 'query_root' }
  & { instructions_aggregate: (
    { __typename?: 'integration_test_instructions_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'integration_test_instructions_aggregate_fields' }
      & Pick<Integration_Test_Instructions_Aggregate_Fields, 'count'>
    )> }
  ), instructions: Array<(
    { __typename?: 'integration_test_instructions' }
    & InstructionFieldsFragment
  )> }
);

export type IntegrationTestInstructionQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type IntegrationTestInstructionQuery = (
  { __typename?: 'query_root' }
  & { instruction?: Maybe<(
    { __typename?: 'integration_test_instructions' }
    & InstructionFieldsFragment
  )> }
);

export type IntegrationTestInstructionTranslationsSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
  order_by?: Maybe<Array<Integration_Test_Instruction_Translation_Order_By> | Integration_Test_Instruction_Translation_Order_By>;
}>;


export type IntegrationTestInstructionTranslationsSubscription = (
  { __typename?: 'subscription_root' }
  & { translations: Array<(
    { __typename?: 'integration_test_instruction_translation' }
    & Pick<Integration_Test_Instruction_Translation, 'description'>
    & { language: (
      { __typename?: 'languages' }
      & Pick<Languages, 'id' | 'description'>
    ) }
  )> }
);

export type UpsertIntegrationTestInstructionTranslationMutationVariables = Exact<{
  object: Integration_Test_Instruction_Translation_Insert_Input;
}>;


export type UpsertIntegrationTestInstructionTranslationMutation = (
  { __typename?: 'mutation_root' }
  & { translation?: Maybe<(
    { __typename?: 'integration_test_instruction_translation' }
    & Pick<Integration_Test_Instruction_Translation, 'description' | 'instruction_id' | 'language'>
  )> }
);

export type UpdateIntegrationTestInstructionMutationVariables = Exact<{
  id: Scalars['uuid'];
  input: Integration_Test_Instructions_Set_Input;
}>;


export type UpdateIntegrationTestInstructionMutation = (
  { __typename?: 'mutation_root' }
  & { instruction?: Maybe<(
    { __typename?: 'integration_test_instructions' }
    & Pick<Integration_Test_Instructions, 'id'>
  )> }
);

export type ExpectationFieldsFragment = (
  { __typename?: 'integration_test_expectations' }
  & Pick<Integration_Test_Expectations, 'id' | 'description'>
  & { expectation_translations: Array<(
    { __typename?: 'integration_test_expectation_translation' }
    & Pick<Integration_Test_Expectation_Translation, 'language' | 'description'>
  )>, expectation_media: Array<(
    { __typename?: 'integration_test_expectation_media' }
    & { medium: (
      { __typename?: 'media' }
      & Pick<Media, 'id' | 'title' | 'description' | 'type' | 'url'>
    ) }
  )> }
);

export type AllIntegrationTestExpectationsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Expectations_Order_By> | Integration_Test_Expectations_Order_By>;
  where?: Maybe<Integration_Test_Expectations_Bool_Exp>;
}>;


export type AllIntegrationTestExpectationsQuery = (
  { __typename?: 'query_root' }
  & { expectations_aggregate: (
    { __typename?: 'integration_test_expectations_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'integration_test_expectations_aggregate_fields' }
      & Pick<Integration_Test_Expectations_Aggregate_Fields, 'count'>
    )> }
  ), expectations: Array<(
    { __typename?: 'integration_test_expectations' }
    & ExpectationFieldsFragment
  )> }
);

export type IntegrationTestExpectationQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type IntegrationTestExpectationQuery = (
  { __typename?: 'query_root' }
  & { expectation?: Maybe<(
    { __typename?: 'integration_test_expectations' }
    & ExpectationFieldsFragment
  )> }
);

export type IntegrationTestExpectationTranslationsSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
  order_by?: Maybe<Array<Integration_Test_Expectation_Translation_Order_By> | Integration_Test_Expectation_Translation_Order_By>;
}>;


export type IntegrationTestExpectationTranslationsSubscription = (
  { __typename?: 'subscription_root' }
  & { translations: Array<(
    { __typename?: 'integration_test_expectation_translation' }
    & Pick<Integration_Test_Expectation_Translation, 'description'>
    & { language: (
      { __typename?: 'languages' }
      & Pick<Languages, 'id' | 'description'>
    ) }
  )> }
);

export type UpsertIntegrationTestExpectationTranslationMutationVariables = Exact<{
  object: Integration_Test_Expectation_Translation_Insert_Input;
}>;


export type UpsertIntegrationTestExpectationTranslationMutation = (
  { __typename?: 'mutation_root' }
  & { translation?: Maybe<(
    { __typename?: 'integration_test_expectation_translation' }
    & Pick<Integration_Test_Expectation_Translation, 'description' | 'expectation_id' | 'language'>
  )> }
);

export type UpdateIntegrationTestExpectationMutationVariables = Exact<{
  id: Scalars['uuid'];
  input: Integration_Test_Expectations_Set_Input;
}>;


export type UpdateIntegrationTestExpectationMutation = (
  { __typename?: 'mutation_root' }
  & { expectation?: Maybe<(
    { __typename?: 'integration_test_expectations' }
    & Pick<Integration_Test_Expectations, 'id'>
  )> }
);

export type IntegrationTestSequenceFieldsFragment = (
  { __typename?: 'integration_test_sequences' }
  & Pick<Integration_Test_Sequences, 'id' | 'description'>
);

export type AllIntegrationTestSequencesQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Test_Sequences_Order_By> | Integration_Test_Sequences_Order_By>;
  where?: Maybe<Integration_Test_Sequences_Bool_Exp>;
}>;


export type AllIntegrationTestSequencesQuery = (
  { __typename?: 'query_root' }
  & { sequences_aggregate: (
    { __typename?: 'integration_test_sequences_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'integration_test_sequences_aggregate_fields' }
      & Pick<Integration_Test_Sequences_Aggregate_Fields, 'count'>
    )> }
  ), sequences: Array<(
    { __typename?: 'integration_test_sequences' }
    & { steps_aggregate: (
      { __typename?: 'integration_test_steps_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'integration_test_steps_aggregate_fields' }
        & Pick<Integration_Test_Steps_Aggregate_Fields, 'count'>
      )> }
    ) }
    & IntegrationTestSequenceFieldsFragment
  )> }
);

export type IntegrationTestSequenceQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type IntegrationTestSequenceQuery = (
  { __typename?: 'query_root' }
  & { sequence?: Maybe<(
    { __typename?: 'integration_test_sequences' }
    & { steps: Array<(
      { __typename?: 'integration_test_steps' }
      & Pick<Integration_Test_Steps, 'id' | 'position'>
      & { instruction: (
        { __typename?: 'integration_test_instructions' }
        & InstructionFieldsFragment
      ), expectation_steps: Array<(
        { __typename?: 'integration_test_expectation_step' }
        & { expectation: (
          { __typename?: 'integration_test_expectations' }
          & ExpectationFieldsFragment
        ) }
      )> }
    )> }
    & IntegrationTestSequenceFieldsFragment
  )> }
);

export type AllIntegrationTestStepsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  where?: Maybe<Integration_Test_Steps_Bool_Exp>;
  order_by?: Maybe<Array<Integration_Test_Steps_Order_By> | Integration_Test_Steps_Order_By>;
}>;


export type AllIntegrationTestStepsQuery = (
  { __typename?: 'query_root' }
  & { steps: Array<(
    { __typename?: 'integration_test_steps' }
    & Pick<Integration_Test_Steps, 'id' | 'position'>
    & { instruction: (
      { __typename?: 'integration_test_instructions' }
      & Pick<Integration_Test_Instructions, 'id' | 'description'>
    ), expectation_steps: Array<(
      { __typename?: 'integration_test_expectation_step' }
      & { expectation: (
        { __typename?: 'integration_test_expectations' }
        & Pick<Integration_Test_Expectations, 'description'>
      ) }
    )>, expectation_steps_aggregate: (
      { __typename?: 'integration_test_expectation_step_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'integration_test_expectation_step_aggregate_fields' }
        & Pick<Integration_Test_Expectation_Step_Aggregate_Fields, 'count'>
      )> }
    ) }
  )>, steps_aggregate: (
    { __typename?: 'integration_test_steps_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'integration_test_steps_aggregate_fields' }
      & Pick<Integration_Test_Steps_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type InsertIntegrationTestSequenceMutationVariables = Exact<{
  input: Integration_Test_Sequences_Insert_Input;
}>;


export type InsertIntegrationTestSequenceMutation = (
  { __typename?: 'mutation_root' }
  & { sequence?: Maybe<(
    { __typename?: 'integration_test_sequences' }
    & IntegrationTestSequenceFieldsFragment
  )> }
);

export type UpdateIntegrationTestSequenceMutationVariables = Exact<{
  id: Scalars['uuid'];
  sequence: Integration_Test_Sequences_Set_Input;
  steps: Array<Integration_Test_Steps_Insert_Input> | Integration_Test_Steps_Insert_Input;
}>;


export type UpdateIntegrationTestSequenceMutation = (
  { __typename?: 'mutation_root' }
  & { sequence?: Maybe<(
    { __typename?: 'integration_test_sequences' }
    & IntegrationTestSequenceFieldsFragment
  )>, deleted_steps?: Maybe<(
    { __typename?: 'integration_test_steps_mutation_response' }
    & Pick<Integration_Test_Steps_Mutation_Response, 'affected_rows'>
  )>, inserted_steps?: Maybe<(
    { __typename?: 'integration_test_steps_mutation_response' }
    & Pick<Integration_Test_Steps_Mutation_Response, 'affected_rows'>
  )> }
);

export type IntegrationTestProjectSequencesSubscriptionVariables = Exact<{
  project_id: Scalars['uuid'];
  order_by?: Maybe<Array<Integration_Test_Sequences_Order_By> | Integration_Test_Sequences_Order_By>;
}>;


export type IntegrationTestProjectSequencesSubscription = (
  { __typename?: 'subscription_root' }
  & { sequences: Array<(
    { __typename?: 'integration_test_sequences' }
    & Pick<Integration_Test_Sequences, 'id' | 'description'>
    & { observances_aggregate: (
      { __typename?: 'integration_test_observances_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'integration_test_observances_aggregate_fields' }
        & Pick<Integration_Test_Observances_Aggregate_Fields, 'count'>
        & { max?: Maybe<(
          { __typename?: 'integration_test_observances_max_fields' }
          & Pick<Integration_Test_Observances_Max_Fields, 'created_at'>
        )> }
      )> }
    ), observances: Array<(
      { __typename?: 'integration_test_observances' }
      & Pick<Integration_Test_Observances, 'id' | 'created_at'>
      & { user: (
        { __typename?: 'users' }
        & Pick<Users, 'name'>
      ), engine?: Maybe<(
        { __typename?: 'engines' }
        & Pick<Engines, 'build_version' | 'release_number'>
      )>, observations: Array<(
        { __typename?: 'integration_test_observations' }
        & Pick<Integration_Test_Observations, 'result'>
      )> }
    )> }
  )> }
);

export type InsertIntegrationTestObservanceMutationVariables = Exact<{
  input: Integration_Test_Observances_Insert_Input;
}>;


export type InsertIntegrationTestObservanceMutation = (
  { __typename?: 'mutation_root' }
  & { observance?: Maybe<(
    { __typename?: 'integration_test_observances' }
    & Pick<Integration_Test_Observances, 'id'>
  )> }
);

export type IntegrationTestObservanceResultsSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type IntegrationTestObservanceResultsSubscription = (
  { __typename?: 'subscription_root' }
  & { observance?: Maybe<(
    { __typename?: 'integration_test_observances' }
    & Pick<Integration_Test_Observances, 'id'>
    & { observations: Array<(
      { __typename?: 'integration_test_observations' }
      & Pick<Integration_Test_Observations, 'result'>
      & { expectation_step?: Maybe<(
        { __typename?: 'integration_test_expectation_step' }
        & { step: (
          { __typename?: 'integration_test_steps' }
          & Pick<Integration_Test_Steps, 'id'>
        ), expectation: (
          { __typename?: 'integration_test_expectations' }
          & Pick<Integration_Test_Expectations, 'id' | 'description'>
        ) }
      )> }
    )>, sequence: (
      { __typename?: 'integration_test_sequences' }
      & { steps: Array<(
        { __typename?: 'integration_test_steps' }
        & Pick<Integration_Test_Steps, 'id'>
        & { instruction: (
          { __typename?: 'integration_test_instructions' }
          & Pick<Integration_Test_Instructions, 'id' | 'description'>
        ) }
      )> }
    ) }
  )> }
);

export type ModeFieldsFragment = (
  { __typename?: 'recording_data_modes' }
  & Pick<Recording_Data_Modes, 'id' | 'name' | 'description' | 'created_at' | 'updated_at'>
);

export type AllModesQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  where?: Maybe<Recording_Data_Modes_Bool_Exp>;
  order_by?: Maybe<Array<Recording_Data_Modes_Order_By> | Recording_Data_Modes_Order_By>;
}>;


export type AllModesQuery = (
  { __typename?: 'query_root' }
  & { modes_aggregate: (
    { __typename?: 'recording_data_modes_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'recording_data_modes_aggregate_fields' }
      & Pick<Recording_Data_Modes_Aggregate_Fields, 'count'>
    )> }
  ), modes: Array<(
    { __typename?: 'recording_data_modes' }
    & { mode_parameters: Array<(
      { __typename?: 'recording_data_mode_parameter' }
      & Pick<Recording_Data_Mode_Parameter, 'value'>
      & { parameter: (
        { __typename?: 'recording_data_parameters' }
        & Pick<Recording_Data_Parameters, 'name'>
      ) }
    )> }
    & ModeFieldsFragment
  )> }
);

export type ModeQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type ModeQuery = (
  { __typename?: 'query_root' }
  & { mode?: Maybe<(
    { __typename?: 'recording_data_modes' }
    & ModeFieldsFragment
  )> }
);

export type CreateModeMutationVariables = Exact<{
  input: Recording_Data_Modes_Insert_Input;
}>;


export type CreateModeMutation = (
  { __typename?: 'mutation_root' }
  & { mode?: Maybe<(
    { __typename?: 'recording_data_modes' }
    & ModeFieldsFragment
  )> }
);

export type UpdateModeMutationVariables = Exact<{
  id: Scalars['uuid'];
  input: Recording_Data_Modes_Set_Input;
}>;


export type UpdateModeMutation = (
  { __typename?: 'mutation_root' }
  & { mode?: Maybe<(
    { __typename?: 'recording_data_modes' }
    & ModeFieldsFragment
  )> }
);

export type ModelFieldsFragment = (
  { __typename?: 'models' }
  & Pick<Models, 'id' | 'codename' | 'description' | 'suspend_resume_at_transitions'>
  & { projects: Array<(
    { __typename?: 'projects' }
    & Pick<Projects, 'id'>
    & { project_name?: Maybe<(
      { __typename?: 'project_name' }
      & Pick<Project_Name, 'name' | 'display_name'>
    )> }
    & ProjectCmdFragment
  )> }
);

export type AllModelsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Models_Order_By> | Models_Order_By>;
  where?: Maybe<Models_Bool_Exp>;
}>;


export type AllModelsQuery = (
  { __typename?: 'query_root' }
  & { models_aggregate: (
    { __typename?: 'models_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'models_aggregate_fields' }
      & Pick<Models_Aggregate_Fields, 'count'>
    )> }
  ), models: Array<(
    { __typename?: 'models' }
    & ModelFieldsFragment
  )> }
);

export type ModelQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type ModelQuery = (
  { __typename?: 'query_root' }
  & { model?: Maybe<(
    { __typename?: 'models' }
    & ModelFieldsFragment
  )> }
);

export type CreateModelMutationVariables = Exact<{
  input: Models_Insert_Input;
}>;


export type CreateModelMutation = (
  { __typename?: 'mutation_root' }
  & { model?: Maybe<(
    { __typename?: 'models' }
    & ModelFieldsFragment
  )> }
);

export type UpdateModelMutationVariables = Exact<{
  id: Scalars['uuid'];
  input: Models_Set_Input;
}>;


export type UpdateModelMutation = (
  { __typename?: 'mutation_root' }
  & { model?: Maybe<(
    { __typename?: 'models' }
    & ModelFieldsFragment
  )> }
);

export type OpmodeFieldsFragment = (
  { __typename?: 'opmodes' }
  & Pick<Opmodes, 'id' | 'number' | 'created_at' | 'updated_at' | 'deleted_at'>
);

export type AvailableOpmodesQueryVariables = Exact<{ [key: string]: never; }>;


export type AvailableOpmodesQuery = (
  { __typename?: 'query_root' }
  & { opmodes: Array<(
    { __typename?: 'opmodes' }
    & Pick<Opmodes, 'id' | 'number'>
  )> }
);

export type AllOpmodesQueryVariables = Exact<{
  where?: Maybe<Opmodes_Bool_Exp>;
  order_by?: Maybe<Array<Opmodes_Order_By> | Opmodes_Order_By>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type AllOpmodesQuery = (
  { __typename?: 'query_root' }
  & { opmodes_aggregate: (
    { __typename?: 'opmodes_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'opmodes_aggregate_fields' }
      & Pick<Opmodes_Aggregate_Fields, 'count'>
    )> }
  ), opmodes: Array<(
    { __typename?: 'opmodes' }
    & OpmodeFieldsFragment
  )> }
);

export type OpmodeQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type OpmodeQuery = (
  { __typename?: 'query_root' }
  & { opmode?: Maybe<(
    { __typename?: 'opmodes' }
    & OpmodeFieldsFragment
  )> }
);

export type CreateOpmodeMutationVariables = Exact<{
  input: Opmodes_Insert_Input;
}>;


export type CreateOpmodeMutation = (
  { __typename?: 'mutation_root' }
  & { opmode?: Maybe<(
    { __typename?: 'opmodes' }
    & OpmodeFieldsFragment
  )> }
);

export type UpdateOpmodeMutationVariables = Exact<{
  id: Scalars['uuid'];
  input: Opmodes_Set_Input;
}>;


export type UpdateOpmodeMutation = (
  { __typename?: 'mutation_root' }
  & { opmode?: Maybe<(
    { __typename?: 'opmodes' }
    & OpmodeFieldsFragment
  )> }
);

export type RatioMetricFieldsFragment = (
  { __typename?: 'performance_ratio_metrics' }
  & Pick<Performance_Ratio_Metrics, 'id' | 'name' | 'description' | 'numerator_unit' | 'denominator_unit' | 'display_as_percentage' | 'sample_unit' | 'min_num_samples' | 'source_version' | 'larger_is_better' | 'warning_limit' | 'critical_limit'>
);

export type AllRatioMetricsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  where?: Maybe<Performance_Ratio_Metrics_Bool_Exp>;
  order_by?: Maybe<Array<Performance_Ratio_Metrics_Order_By> | Performance_Ratio_Metrics_Order_By>;
}>;


export type AllRatioMetricsQuery = (
  { __typename?: 'query_root' }
  & { ratio_metrics_aggregate: (
    { __typename?: 'performance_ratio_metrics_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'performance_ratio_metrics_aggregate_fields' }
      & Pick<Performance_Ratio_Metrics_Aggregate_Fields, 'count'>
    )> }
  ), ratio_metrics: Array<(
    { __typename?: 'performance_ratio_metrics' }
    & RatioMetricFieldsFragment
  )> }
);

export type ProjectEngineRatioMetricsQueryVariables = Exact<{
  project_id: Scalars['uuid'];
  metric_id: Scalars['uuid'];
  engine_ids: Scalars['_uuid'];
  task_ids: Scalars['_uuid'];
}>;


export type ProjectEngineRatioMetricsQuery = (
  { __typename?: 'query_root' }
  & { project_engine_ratio_metrics: Array<(
    { __typename?: 'schema_project_engine_ratio_metrics' }
    & Pick<Schema_Project_Engine_Ratio_Metrics, 'score' | 'engine_id' | 'num_samples' | 'num_recordings'>
    & { ratio_metric: (
      { __typename?: 'performance_ratio_metrics' }
      & RatioMetricFieldsFragment
    ) }
  )> }
);

export type ProjectPerformanceDataQueryVariables = Exact<{
  project_id: Scalars['uuid'];
  engine_ids: Scalars['_uuid'];
}>;


export type ProjectPerformanceDataQuery = (
  { __typename?: 'query_root' }
  & { project_engine_metrics: Array<(
    { __typename?: 'schema_project_engine_metrics' }
    & Pick<Schema_Project_Engine_Metrics, 'project_id' | 'metric_id' | 'engine_id' | 'score' | 'num_samples' | 'num_recordings' | 'numerator' | 'denominator' | 'task_id'>
    & { task?: Maybe<(
      { __typename?: 'tasks' }
      & Pick<Tasks, 'legacy_template_id'>
    )>, ratio_metric: (
      { __typename?: 'performance_ratio_metrics' }
      & Pick<Performance_Ratio_Metrics, 'name' | 'display_as_percentage' | 'numerator_unit' | 'denominator_unit' | 'larger_is_better' | 'warning_limit' | 'critical_limit'>
    ) }
  )> }
);

export type Ag_UpsertProjectMutationVariables = Exact<{
  input: Projects_Insert_Input;
  on_conflict?: Maybe<Projects_On_Conflict>;
}>;


export type Ag_UpsertProjectMutation = (
  { __typename?: 'mutation_root' }
  & { project?: Maybe<(
    { __typename?: 'projects' }
    & ProjectFieldsFragment
  )> }
);

export type Ag_CreateProjectMutationVariables = Exact<{
  input: Projects_Insert_Input;
}>;


export type Ag_CreateProjectMutation = (
  { __typename?: 'mutation_root' }
  & { project?: Maybe<(
    { __typename?: 'projects' }
    & ProjectFieldsFragment
  )> }
);

export type Ag_UpdateProjectMutationVariables = Exact<{
  id: Scalars['uuid'];
  input: Projects_Set_Input;
  add_opmodes: Array<Opmode_Project_Insert_Input> | Opmode_Project_Insert_Input;
  remove_opmode_project_ids?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  add_combo_projects: Array<Project_Combo_Insert_Input> | Project_Combo_Insert_Input;
  remove_combo_project_ids: Array<Scalars['uuid']> | Scalars['uuid'];
  add_modes: Array<Mode_Project_Insert_Input> | Mode_Project_Insert_Input;
  remove_mode_ids: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type Ag_UpdateProjectMutation = (
  { __typename?: 'mutation_root' }
  & { project?: Maybe<(
    { __typename?: 'projects' }
    & ProjectFieldsFragment
  )>, add_opmodes?: Maybe<(
    { __typename?: 'opmode_project_mutation_response' }
    & { returning: Array<(
      { __typename?: 'opmode_project' }
      & OpmodeProjectFieldsFragment
    )> }
  )>, remove_opmodes?: Maybe<(
    { __typename?: 'opmode_project_mutation_response' }
    & { returning: Array<(
      { __typename?: 'opmode_project' }
      & OpmodeProjectFieldsFragment
    )> }
  )>, add_combo_projects?: Maybe<(
    { __typename?: 'project_combo_mutation_response' }
    & Pick<Project_Combo_Mutation_Response, 'affected_rows'>
  )>, remove_combo_projects?: Maybe<(
    { __typename?: 'project_combo_mutation_response' }
    & Pick<Project_Combo_Mutation_Response, 'affected_rows'>
  )>, add_modes?: Maybe<(
    { __typename?: 'mode_project_mutation_response' }
    & Pick<Mode_Project_Mutation_Response, 'affected_rows'>
  )>, remove_modes?: Maybe<(
    { __typename?: 'mode_project_mutation_response' }
    & Pick<Mode_Project_Mutation_Response, 'affected_rows'>
  )> }
);

export type UpdateSlackChannelMutationVariables = Exact<{
  project_id: Scalars['uuid'];
  slack_channel: Scalars['String'];
}>;


export type UpdateSlackChannelMutation = (
  { __typename?: 'mutation_root' }
  & { update_projects_by_pk?: Maybe<(
    { __typename?: 'projects' }
    & Pick<Projects, 'id' | 'slack_channel'>
  )> }
);

export type OpmodeProjectFieldsFragment = (
  { __typename?: 'opmode_project' }
  & Pick<Opmode_Project, 'opmode_id' | 'project_id'>
  & { opmode: (
    { __typename?: 'opmodes' }
    & Pick<Opmodes, 'number'>
  ) }
);

export type UpdateProjectTasksMutationVariables = Exact<{
  upsert_project_tasks: Array<Project_Tasks_Insert_Input> | Project_Tasks_Insert_Input;
  remove_project_task_ids: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type UpdateProjectTasksMutation = (
  { __typename?: 'mutation_root' }
  & { insert_project_tasks?: Maybe<(
    { __typename?: 'project_tasks_mutation_response' }
    & { returning: Array<(
      { __typename?: 'project_tasks' }
      & Pick<Project_Tasks, 'id'>
    )> }
  )>, delete_project_tasks?: Maybe<(
    { __typename?: 'project_tasks_mutation_response' }
    & { returning: Array<(
      { __typename?: 'project_tasks' }
      & Pick<Project_Tasks, 'id'>
    )> }
  )> }
);

export type PlanProjectTasksQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tasks_Order_By> | Tasks_Order_By>;
  tasks_where?: Maybe<Tasks_Bool_Exp>;
  plan_tasks_where?: Maybe<Plan_Tasks_Bool_Exp>;
  project_tasks_where?: Maybe<Project_Tasks_Bool_Exp>;
}>;


export type PlanProjectTasksQuery = (
  { __typename?: 'query_root' }
  & { tasks: Array<(
    { __typename?: 'tasks' }
    & Pick<Tasks, 'id' | 'note' | 'force_ground_truth_to_negative' | 'legacy_template_id' | 'description'>
    & { plan_tasks: Array<(
      { __typename?: 'plan_tasks' }
      & Pick<Plan_Tasks, 'id' | 'min_per_device' | 'priority'>
    )>, project_tasks: Array<(
      { __typename?: 'project_tasks' }
      & Pick<Project_Tasks, 'id' | 'min_per_device' | 'priority'>
    )> }
  )>, tasks_aggregate: (
    { __typename?: 'tasks_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'tasks_aggregate_fields' }
      & Pick<Tasks_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type UpdateProjectHypertasksMutationVariables = Exact<{
  upsert_project_hypertasks: Array<Project_Hypertasks_Insert_Input> | Project_Hypertasks_Insert_Input;
  project_id: Scalars['uuid'];
  remove_hypertask_ids: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type UpdateProjectHypertasksMutation = (
  { __typename?: 'mutation_root' }
  & { insert_project_hypertasks?: Maybe<(
    { __typename?: 'project_hypertasks_mutation_response' }
    & { returning: Array<(
      { __typename?: 'project_hypertasks' }
      & Pick<Project_Hypertasks, 'project_id' | 'hypertask_id'>
    )> }
  )>, delete_project_hypertasks?: Maybe<(
    { __typename?: 'project_hypertasks_mutation_response' }
    & { returning: Array<(
      { __typename?: 'project_hypertasks' }
      & Pick<Project_Hypertasks, 'project_id' | 'hypertask_id'>
    )> }
  )> }
);

export type PlanProjectHypertasksQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hypertasks_Order_By> | Hypertasks_Order_By>;
  hypertasks_where?: Maybe<Hypertasks_Bool_Exp>;
  plan_hypertasks_where?: Maybe<Plan_Hypertasks_Bool_Exp>;
  project_hypertasks_where?: Maybe<Project_Hypertasks_Bool_Exp>;
}>;


export type PlanProjectHypertasksQuery = (
  { __typename?: 'query_root' }
  & { hypertasks: Array<(
    { __typename?: 'hypertasks' }
    & Pick<Hypertasks, 'id' | 'number' | 'description'>
    & { plan_hypertasks: Array<(
      { __typename?: 'plan_hypertasks' }
      & Pick<Plan_Hypertasks, 'plan_id' | 'hypertask_id' | 'min_per_device' | 'priority'>
    )>, project_hypertasks: Array<(
      { __typename?: 'project_hypertasks' }
      & Pick<Project_Hypertasks, 'project_id' | 'hypertask_id' | 'min_per_device' | 'priority'>
    )> }
  )>, hypertasks_aggregate: (
    { __typename?: 'hypertasks_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'hypertasks_aggregate_fields' }
      & Pick<Hypertasks_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type SetProjectGreatnessEnabledMutationVariables = Exact<{
  project_id: Scalars['uuid'];
  enabled: Scalars['Boolean'];
}>;


export type SetProjectGreatnessEnabledMutation = (
  { __typename?: 'mutation_root' }
  & { update_projects_by_pk?: Maybe<(
    { __typename?: 'projects' }
    & Pick<Projects, 'id'>
  )> }
);

export type SetProjectTagGroundTruthEnabledMutationVariables = Exact<{
  project_id: Scalars['uuid'];
  enabled: Scalars['Boolean'];
}>;


export type SetProjectTagGroundTruthEnabledMutation = (
  { __typename?: 'mutation_root' }
  & { update_projects_by_pk?: Maybe<(
    { __typename?: 'projects' }
    & Pick<Projects, 'id'>
  )> }
);

export type SetProjectArcadiaEnabledMutationVariables = Exact<{
  project_id: Scalars['uuid'];
  enabled: Scalars['Boolean'];
}>;


export type SetProjectArcadiaEnabledMutation = (
  { __typename?: 'mutation_root' }
  & { update_projects_by_pk?: Maybe<(
    { __typename?: 'projects' }
    & Pick<Projects, 'id'>
  )> }
);

export type ArchiveProjectMutationVariables = Exact<{
  id: Scalars['uuid'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
}>;


export type ArchiveProjectMutation = (
  { __typename?: 'mutation_root' }
  & { update_projects_by_pk?: Maybe<(
    { __typename?: 'projects' }
    & Pick<Projects, 'id'>
  )> }
);

export type UpsertDynamicScalingMutationVariables = Exact<{
  min_value: Scalars['Int'];
  max_value: Scalars['Int'];
  benchmark_scaling: Scalars['Int'];
}>;


export type UpsertDynamicScalingMutation = (
  { __typename?: 'mutation_root' }
  & { insert_dynamic_scaling?: Maybe<(
    { __typename?: 'dynamic_scaling_mutation_response' }
    & { returning: Array<(
      { __typename?: 'dynamic_scaling' }
      & Pick<Dynamic_Scaling, 'id'>
    )> }
  )> }
);

export type ProjectCmdFragment = (
  { __typename?: 'projects' }
  & Pick<Projects, 'variant'>
  & { customer: (
    { __typename?: 'customers' }
    & Pick<Customers, 'id' | 'codename'>
  ), model: (
    { __typename?: 'models' }
    & Pick<Models, 'id' | 'codename'>
  ), deliverable: (
    { __typename?: 'deliverables' }
    & Pick<Deliverables, 'id' | 'name'>
  ) }
);

export type ProjectFieldsFragment = (
  { __typename?: 'projects' }
  & Pick<Projects, 'id' | 'description' | 'created_at' | 'updated_at' | 'deleted_at' | 'completed_at' | 'min_devices_per_task' | 'slack_channel' | 'mongo_source_query' | 'enable_ground_truth_processing' | 'enable_greatness_processing' | 'enable_arcadia_processing' | 'azure_build_ref' | 'azure_release_ref' | 'near_threshold' | 'far_threshold' | 'firmware_engine_release_ref' | 'variant'>
  & { calibration_opmode?: Maybe<(
    { __typename?: 'opmodes' }
    & Pick<Opmodes, 'id' | 'number'>
    & { opmode_profiles: Array<(
      { __typename?: 'opmode_profile' }
      & { profile: (
        { __typename?: 'profiles' }
        & Pick<Profiles, 'id' | 'name'>
      ) }
    )> }
  )>, xtensa_configuration?: Maybe<(
    { __typename?: 'xtensa_configurations' }
    & Pick<Xtensa_Configurations, 'id' | 'value'>
  )>, simulation_opmode?: Maybe<(
    { __typename?: 'opmodes' }
    & Pick<Opmodes, 'id' | 'number'>
    & { opmode_profiles: Array<(
      { __typename?: 'opmode_profile' }
      & { profile: (
        { __typename?: 'profiles' }
        & Pick<Profiles, 'id' | 'name'>
      ) }
    )> }
  )>, opmode_projects: Array<(
    { __typename?: 'opmode_project' }
    & { opmode: (
      { __typename?: 'opmodes' }
      & Pick<Opmodes, 'id' | 'number'>
    ) }
  )>, deliverable: (
    { __typename?: 'deliverables' }
    & Pick<Deliverables, 'id' | 'name' | 'description' | 'num_states'>
    & { ground_truth_type?: Maybe<(
      { __typename?: 'ground_truth_types' }
      & Pick<Ground_Truth_Types, 'name' | 'default_near_threshold' | 'default_far_threshold' | 'min_near_threshold' | 'max_near_threshold' | 'min_far_threshold' | 'max_far_threshold'>
    )> }
  ), project_dataset_aggregate: (
    { __typename?: 'project_dataset_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'project_dataset_aggregate_fields' }
      & Pick<Project_Dataset_Aggregate_Fields, 'count'>
    )> }
  ), scores: Array<(
    { __typename?: 'simulations_scores' }
    & Pick<Simulations_Scores, 'greatness'>
  )>, project_users: Array<(
    { __typename?: 'project_user' }
    & Pick<Project_User, 'manager'>
    & { user: (
      { __typename?: 'users' }
      & Pick<Users, 'id' | 'email' | 'name'>
    ) }
  )>, project_name?: Maybe<(
    { __typename?: 'project_name' }
    & Pick<Project_Name, 'name' | 'display_name'>
  )>, dynamic_scaling?: Maybe<(
    { __typename?: 'dynamic_scaling' }
    & Pick<Dynamic_Scaling, 'min_value' | 'max_value' | 'benchmark_scaling'>
  )> }
  & ProjectCmdFragment
);

export type AllProjectsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Projects_Order_By> | Projects_Order_By>;
  where?: Maybe<Projects_Bool_Exp>;
}>;


export type AllProjectsQuery = (
  { __typename?: 'query_root' }
  & { projects_aggregate: (
    { __typename?: 'projects_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'projects_aggregate_fields' }
      & Pick<Projects_Aggregate_Fields, 'count'>
    )> }
  ), projects: Array<(
    { __typename?: 'projects' }
    & { project_name?: Maybe<(
      { __typename?: 'project_name' }
      & Pick<Project_Name, 'name'>
    )>, project_sequences_aggregate: (
      { __typename?: 'integration_test_project_sequence_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'integration_test_project_sequence_aggregate_fields' }
        & Pick<Integration_Test_Project_Sequence_Aggregate_Fields, 'count'>
      )> }
    ) }
    & ProjectFieldsFragment
  )> }
);

export type AllSearchableProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllSearchableProjectsQuery = (
  { __typename?: 'query_root' }
  & { projects: Array<(
    { __typename?: 'projects' }
    & Pick<Projects, 'id' | 'created_at' | 'deleted_at' | 'variant'>
    & { project_users: Array<(
      { __typename?: 'project_user' }
      & Pick<Project_User, 'project_id' | 'user_id'>
      & { user: (
        { __typename?: 'users' }
        & Pick<Users, 'id' | 'name' | 'email'>
      ) }
    )>, project_name?: Maybe<(
      { __typename?: 'project_name' }
      & Pick<Project_Name, 'name' | 'display_name'>
    )>, scores: Array<(
      { __typename?: 'simulations_scores' }
      & Pick<Simulations_Scores, 'id' | 'greatness'>
    )>, simulation_opmode?: Maybe<(
      { __typename?: 'opmodes' }
      & Pick<Opmodes, 'id' | 'number'>
    )>, calibration_opmode?: Maybe<(
      { __typename?: 'opmodes' }
      & Pick<Opmodes, 'id' | 'number'>
    )>, opmode_projects: Array<(
      { __typename?: 'opmode_project' }
      & { opmode: (
        { __typename?: 'opmodes' }
        & Pick<Opmodes, 'id' | 'number'>
      ) }
    )>, deliverable: (
      { __typename?: 'deliverables' }
      & Pick<Deliverables, 'id' | 'name'>
    ) }
  )> }
);

export type ProjectCompareSubscriptionVariables = Exact<{
  where?: Maybe<Projects_Bool_Exp>;
}>;


export type ProjectCompareSubscription = (
  { __typename?: 'subscription_root' }
  & { projects: Array<(
    { __typename?: 'projects' }
    & Pick<Projects, 'id'>
    & { scores: Array<(
      { __typename?: 'simulations_scores' }
      & Pick<Simulations_Scores, 'id' | 'greatness'>
    )>, project_name?: Maybe<(
      { __typename?: 'project_name' }
      & Pick<Project_Name, 'display_name'>
    )> }
  )> }
);

export type SingleProjectFieldsFragment = (
  { __typename?: 'projects' }
  & { mode_projects: Array<(
    { __typename?: 'mode_project' }
    & Pick<Mode_Project, 'mode_id'>
    & { mode: (
      { __typename?: 'recording_data_modes' }
      & Pick<Recording_Data_Modes, 'id' | 'name'>
      & { mode_parameters: Array<(
        { __typename?: 'recording_data_mode_parameter' }
        & Pick<Recording_Data_Mode_Parameter, 'value'>
        & { parameter: (
          { __typename?: 'recording_data_parameters' }
          & Pick<Recording_Data_Parameters, 'name'>
        ) }
      )> }
    ) }
  )>, recordings_aggregate: (
    { __typename?: 'recordings_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'recordings_aggregate_fields' }
      & Pick<Recordings_Aggregate_Fields, 'count'>
    )> }
  ), project_users: Array<(
    { __typename?: 'project_user' }
    & Pick<Project_User, 'user_id' | 'project_id' | 'manager'>
    & { user: (
      { __typename?: 'users' }
      & Pick<Users, 'id' | 'email' | 'name' | 'deleted_at'>
      & { project_dataset_aggregate: (
        { __typename?: 'project_dataset_aggregate' }
        & { aggregate?: Maybe<(
          { __typename?: 'project_dataset_aggregate_fields' }
          & Pick<Project_Dataset_Aggregate_Fields, 'count'>
        )> }
      ) }
    ) }
  )>, project_tasks_aggregate: (
    { __typename?: 'project_tasks_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'project_tasks_aggregate_fields' }
      & Pick<Project_Tasks_Aggregate_Fields, 'count'>
    )> }
  ), project_hypertasks_aggregate: (
    { __typename?: 'project_hypertasks_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'project_hypertasks_aggregate_fields' }
      & Pick<Project_Hypertasks_Aggregate_Fields, 'count'>
    )> }
  ), project_sequences_aggregate: (
    { __typename?: 'integration_test_project_sequence_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'integration_test_project_sequence_aggregate_fields' }
      & Pick<Integration_Test_Project_Sequence_Aggregate_Fields, 'count'>
    )> }
  ), observances_aggregate: (
    { __typename?: 'integration_test_observances_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'integration_test_observances_aggregate_fields' }
      & Pick<Integration_Test_Observances_Aggregate_Fields, 'count'>
    )> }
  ) }
  & ProjectFieldsFragment
);

export type ProjectQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type ProjectQuery = (
  { __typename?: 'query_root' }
  & { project?: Maybe<(
    { __typename?: 'projects' }
    & { project_name?: Maybe<(
      { __typename?: 'project_name' }
      & Pick<Project_Name, 'name'>
    )>, project_combos: Array<(
      { __typename?: 'project_combo' }
      & { other_project: (
        { __typename?: 'projects' }
        & Pick<Projects, 'id'>
        & { project_name?: Maybe<(
          { __typename?: 'project_name' }
          & Pick<Project_Name, 'name'>
        )> }
      ) }
    )> }
    & SingleProjectFieldsFragment
  )> }
);

export type ProjectCollectionTasksQueryVariables = Exact<{
  project_id: Scalars['uuid'];
}>;


export type ProjectCollectionTasksQuery = (
  { __typename?: 'query_root' }
  & { tasks: Array<(
    { __typename?: 'tasks' }
    & Pick<Tasks, 'id' | 'legacy_template_id'>
    & { hypertask_tasks: Array<(
      { __typename?: 'hypertask_task' }
      & { hypertask: (
        { __typename?: 'hypertasks' }
        & Pick<Hypertasks, 'id' | 'number'>
      ) }
    )> }
  )> }
);

export type ProjectBenchmarkTasksQueryVariables = Exact<{
  project_id: Scalars['uuid'];
}>;


export type ProjectBenchmarkTasksQuery = (
  { __typename?: 'query_root' }
  & { tasks: Array<(
    { __typename?: 'tasks' }
    & Pick<Tasks, 'id' | 'legacy_template_id'>
  )> }
);

export type ProjectTaskFieldOptionsQueryVariables = Exact<{
  project_id: Scalars['uuid'];
}>;


export type ProjectTaskFieldOptionsQuery = (
  { __typename?: 'query_root' }
  & { tasks: Array<(
    { __typename?: 'tasks' }
    & Pick<Tasks, 'id' | 'legacy_template_id'>
    & { hypertask_tasks: Array<(
      { __typename?: 'hypertask_task' }
      & { hypertask: (
        { __typename?: 'hypertasks' }
        & Pick<Hypertasks, 'id' | 'number'>
      ) }
    )>, task_field_options: Array<(
      { __typename?: 'task_field_option' }
      & Pick<Task_Field_Option, 'task_id' | 'field_option_id'>
      & { field_option: (
        { __typename?: 'field_options' }
        & { field?: Maybe<(
          { __typename?: 'fields' }
          & Pick<Fields, 'id' | 'name'>
        )>, option?: Maybe<(
          { __typename?: 'options' }
          & Pick<Options, 'id' | 'display'>
        )> }
      ) }
    )> }
  )> }
);

export type ProjectDatasetAggregateQueryVariables = Exact<{
  ids?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;


export type ProjectDatasetAggregateQuery = (
  { __typename?: 'query_root' }
  & { projects: Array<(
    { __typename?: 'projects' }
    & Pick<Projects, 'id'>
    & { project_dataset_aggregate: (
      { __typename?: 'project_dataset_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'project_dataset_aggregate_fields' }
        & Pick<Project_Dataset_Aggregate_Fields, 'count'>
      )> }
    ) }
  )> }
);

export type ProjectCollectionStatusQueryVariables = Exact<{
  project_id: Scalars['uuid'];
  tasks_where?: Maybe<Tasks_Bool_Exp>;
  order_by?: Maybe<Array<Schema_Project_Task_Count_Order_By> | Schema_Project_Task_Count_Order_By>;
  translation_language?: Maybe<Scalars['String']>;
}>;


export type ProjectCollectionStatusQuery = (
  { __typename?: 'query_root' }
  & { project?: Maybe<(
    { __typename?: 'projects' }
    & Pick<Projects, 'id' | 'min_devices_per_task'>
  )>, status: Array<(
    { __typename?: 'schema_project_task_count' }
    & Pick<Schema_Project_Task_Count, 'clean_count' | 'dirty_count' | 'required_total' | 'min_per_device' | 'priority' | 'progress'>
    & { task: (
      { __typename?: 'tasks' }
      & Pick<Tasks, 'id' | 'legacy_template_id' | 'note' | 'description'>
      & { task_media: Array<(
        { __typename?: 'task_media' }
        & Pick<Task_Media, 'task_id' | 'media_id'>
        & { media: (
          { __typename?: 'media' }
          & Pick<Media, 'id' | 'title' | 'description' | 'url' | 'type'>
        ) }
      )>, task_instruction?: Maybe<(
        { __typename?: 'task_instructions' }
        & Pick<Task_Instructions, 'content'>
        & { instruction_translations: Array<(
          { __typename?: 'instruction_translations' }
          & Pick<Instruction_Translations, 'language' | 'content'>
        )> }
      )> }
    ) }
  )> }
);

export type ListLanguagesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListLanguagesQuery = (
  { __typename?: 'query_root' }
  & { languages: Array<(
    { __typename?: 'languages' }
    & Pick<Languages, 'id' | 'description'>
  )> }
);

export type ProjectOverviewQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type ProjectOverviewQuery = (
  { __typename?: 'query_root' }
  & { project?: Maybe<(
    { __typename?: 'projects' }
    & { dirty_dataset_aggregate: (
      { __typename?: 'project_dataset_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'project_dataset_aggregate_fields' }
        & Pick<Project_Dataset_Aggregate_Fields, 'count'>
      )> }
    ), clean_dataset_aggregate: (
      { __typename?: 'project_dataset_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'project_dataset_aggregate_fields' }
        & Pick<Project_Dataset_Aggregate_Fields, 'count'>
      )> }
    ), untagged_dataset_aggregate: (
      { __typename?: 'project_dataset_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'project_dataset_aggregate_fields' }
        & Pick<Project_Dataset_Aggregate_Fields, 'count'>
      )> }
    ), rabbit_test_instances_aggregate: (
      { __typename?: 'project_rabbit_test_instances_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'project_rabbit_test_instances_aggregate_fields' }
        & Pick<Project_Rabbit_Test_Instances_Aggregate_Fields, 'count'>
      )> }
    ) }
    & SingleProjectFieldsFragment
  )> }
);

export type ProjectDataCollectionProgressQueryVariables = Exact<{
  project_id: Scalars['uuid'];
}>;


export type ProjectDataCollectionProgressQuery = (
  { __typename?: 'query_root' }
  & { project_task_count_aggregate: (
    { __typename?: 'schema_project_task_count_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'schema_project_task_count_aggregate_fields' }
      & { sum?: Maybe<(
        { __typename?: 'schema_project_task_count_sum_fields' }
        & Pick<Schema_Project_Task_Count_Sum_Fields, 'clean_count' | 'clean_count_constrained' | 'untagged_count' | 'required_total'>
      )> }
    )> }
  ) }
);

export type IsProjectManagerSubscriptionVariables = Exact<{
  project_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
}>;


export type IsProjectManagerSubscription = (
  { __typename?: 'subscription_root' }
  & { user?: Maybe<(
    { __typename?: 'project_user' }
    & Pick<Project_User, 'manager'>
  )> }
);

export type UserRecordingsByDayQueryVariables = Exact<{
  project_id: Scalars['uuid'];
  order_by?: Maybe<Array<User_Recordings_By_Day_Stats_Order_By> | User_Recordings_By_Day_Stats_Order_By>;
}>;


export type UserRecordingsByDayQuery = (
  { __typename?: 'query_root' }
  & { project?: Maybe<(
    { __typename?: 'projects' }
    & { user_recordings_by_day_stats: Array<(
      { __typename?: 'user_recordings_by_day_stats' }
      & Pick<User_Recordings_By_Day_Stats, 'clean_recordings' | 'dirty_recordings' | 'untagged_recordings' | 'clean_duration' | 'dirty_duration' | 'untagged_duration' | 'day'>
      & { project?: Maybe<(
        { __typename?: 'projects' }
        & Pick<Projects, 'id'>
      )>, user?: Maybe<(
        { __typename?: 'users' }
        & Pick<Users, 'id' | 'name' | 'email'>
      )> }
    )> }
  )> }
);

export type RecordingCountByOpmodeQueryVariables = Exact<{
  opmodes: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type RecordingCountByOpmodeQuery = (
  { __typename?: 'query_root' }
  & { recordings_aggregate: (
    { __typename?: 'recordings_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'recordings_aggregate_fields' }
      & Pick<Recordings_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type RecordingTagCountByDayQueryVariables = Exact<{
  exclude_tag_names: Scalars['_text'];
  project_ids: Scalars['_uuid'];
}>;


export type RecordingTagCountByDayQuery = (
  { __typename?: 'query_root' }
  & { recording_tag_count_by_day: Array<(
    { __typename?: 'schema_recording_tag_count_by_day' }
    & Pick<Schema_Recording_Tag_Count_By_Day, 'count' | 'day' | 'project_id' | 'tag_description' | 'tag_field' | 'tag_id' | 'tag_is_dirty' | 'tag_is_highlighted' | 'tag_name'>
  )> }
);

export type RecordingTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type RecordingTagsQuery = (
  { __typename?: 'query_root' }
  & { recording_tags: Array<(
    { __typename?: 'recording_tags' }
    & Pick<Recording_Tags, 'id' | 'name' | 'field' | 'dirty' | 'description'>
  )> }
);

export type Agmg_AddUserToProjectMutationVariables = Exact<{
  object: Project_User_Insert_Input;
}>;


export type Agmg_AddUserToProjectMutation = (
  { __typename?: 'mutation_root' }
  & { insert_project_user_one?: Maybe<(
    { __typename?: 'project_user' }
    & Pick<Project_User, 'project_id' | 'user_id' | 'manager'>
    & { project: (
      { __typename?: 'projects' }
      & Pick<Projects, 'id'>
      & { project_users: Array<(
        { __typename?: 'project_user' }
        & Pick<Project_User, 'user_id' | 'project_id' | 'manager'>
        & { user: (
          { __typename?: 'users' }
          & Pick<Users, 'id' | 'email'>
        ) }
      )> }
    ) }
  )> }
);

export type SlackChannelsQueryVariables = Exact<{ [key: string]: never; }>;


export type SlackChannelsQuery = (
  { __typename?: 'query_root' }
  & { slack_channels?: Maybe<Array<Maybe<(
    { __typename?: 'ElapiSlackChannel' }
    & Pick<ElapiSlackChannel, 'id' | 'name'>
  )>>> }
);

export type Agmg_RemoveUserFromProjectMutationVariables = Exact<{
  project_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
}>;


export type Agmg_RemoveUserFromProjectMutation = (
  { __typename?: 'mutation_root' }
  & { delete_project_user_by_pk?: Maybe<(
    { __typename?: 'project_user' }
    & Pick<Project_User, 'project_id' | 'user_id' | 'manager'>
    & { project: (
      { __typename?: 'projects' }
      & Pick<Projects, 'id'>
      & { project_users: Array<(
        { __typename?: 'project_user' }
        & Pick<Project_User, 'user_id' | 'project_id' | 'manager'>
        & { user: (
          { __typename?: 'users' }
          & Pick<Users, 'id' | 'email'>
        ) }
      )> }
    ) }
  )> }
);

export type CompareProjectSequencesQueryVariables = Exact<{
  sequences_where: Integration_Test_Sequences_Bool_Exp;
  source_project_sequence_where: Integration_Test_Project_Sequence_Bool_Exp;
  dest_project_sequence_where: Integration_Test_Project_Sequence_Bool_Exp;
}>;


export type CompareProjectSequencesQuery = (
  { __typename?: 'query_root' }
  & { sequences: Array<(
    { __typename?: 'integration_test_sequences' }
    & Pick<Integration_Test_Sequences, 'id' | 'description'>
    & { source_project_sequences: Array<(
      { __typename?: 'integration_test_project_sequence' }
      & Pick<Integration_Test_Project_Sequence, 'created_at'>
    )>, dest_project_sequences: Array<(
      { __typename?: 'integration_test_project_sequence' }
      & Pick<Integration_Test_Project_Sequence, 'created_at'>
    )> }
  )> }
);

export type UpdateProjectSequencesMutationVariables = Exact<{
  upsert_project_sequences: Array<Integration_Test_Project_Sequence_Insert_Input> | Integration_Test_Project_Sequence_Insert_Input;
  remove_project_sequences?: Maybe<Array<Integration_Test_Project_Sequence_Bool_Exp> | Integration_Test_Project_Sequence_Bool_Exp>;
}>;


export type UpdateProjectSequencesMutation = (
  { __typename?: 'mutation_root' }
  & { insert?: Maybe<(
    { __typename?: 'integration_test_project_sequence_mutation_response' }
    & { returning: Array<(
      { __typename?: 'integration_test_project_sequence' }
      & Pick<Integration_Test_Project_Sequence, 'project_id' | 'sequence_id' | 'created_at' | 'updated_at'>
    )> }
  )>, delete?: Maybe<(
    { __typename?: 'integration_test_project_sequence_mutation_response' }
    & { returning: Array<(
      { __typename?: 'integration_test_project_sequence' }
      & Pick<Integration_Test_Project_Sequence, 'project_id' | 'sequence_id'>
    )> }
  )> }
);

export type ProjectTaskCountByDeviceQueryVariables = Exact<{
  project_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
  order_by?: Maybe<Array<Schema_Project_Task_Count_Order_By> | Schema_Project_Task_Count_Order_By>;
}>;


export type ProjectTaskCountByDeviceQuery = (
  { __typename?: 'query_root' }
  & { project_task_count: Array<(
    { __typename?: 'schema_project_task_count' }
    & Pick<Schema_Project_Task_Count, 'device_id' | 'clean_count' | 'dirty_count' | 'required_total' | 'min_per_device' | 'priority' | 'progress'>
    & { device?: Maybe<(
      { __typename?: 'devices' }
      & Pick<Devices, 'id' | 'serialno'>
    )> }
  )> }
);

export type XtensaConfigurationFieldsFragment = (
  { __typename?: 'xtensa_configurations' }
  & Pick<Xtensa_Configurations, 'id' | 'value'>
);

export type AllXtensaConfigurationsQueryVariables = Exact<{
  where?: Maybe<Xtensa_Configurations_Bool_Exp>;
  order_by?: Maybe<Array<Xtensa_Configurations_Order_By> | Xtensa_Configurations_Order_By>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type AllXtensaConfigurationsQuery = (
  { __typename?: 'query_root' }
  & { xtensa_configurations: Array<(
    { __typename?: 'xtensa_configurations' }
    & XtensaConfigurationFieldsFragment
  )> }
);

export type ProjectBenchmarkPlansQueryVariables = Exact<{
  projectId: Scalars['uuid'];
}>;


export type ProjectBenchmarkPlansQuery = (
  { __typename?: 'query_root' }
  & { rabbit_test_plans: Array<(
    { __typename?: 'rabbit_test_plans' }
    & Pick<Rabbit_Test_Plans, 'id' | 'name' | 'description'>
  )> }
);

export type ProjectBenchmarkInstancesQueryVariables = Exact<{
  projectId: Scalars['uuid'];
}>;


export type ProjectBenchmarkInstancesQuery = (
  { __typename?: 'query_root' }
  & { project_rabbit_test_instances: Array<(
    { __typename?: 'project_rabbit_test_instances' }
    & Pick<Project_Rabbit_Test_Instances, 'started_at' | 'project_id' | 'user_id' | 'device_id' | 'engine_id' | 'opmode_id' | 'plan_id' | 'num_recordings' | 'num_distinct_tasks'>
    & { user?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'id' | 'name'>
    )>, device?: Maybe<(
      { __typename?: 'devices' }
      & Pick<Devices, 'id' | 'serialno'>
    )>, engine?: Maybe<(
      { __typename?: 'engines' }
      & Pick<Engines, 'id' | 'build_number'>
    )>, opmode?: Maybe<(
      { __typename?: 'opmodes' }
      & Pick<Opmodes, 'id' | 'number'>
    )>, rabbit_test_plan?: Maybe<(
      { __typename?: 'rabbit_test_plans' }
      & Pick<Rabbit_Test_Plans, 'id' | 'name' | 'description'>
      & { plan_tasks_aggregate: (
        { __typename?: 'rabbit_test_plan_task_aggregate' }
        & { aggregate?: Maybe<(
          { __typename?: 'rabbit_test_plan_task_aggregate_fields' }
          & Pick<Rabbit_Test_Plan_Task_Aggregate_Fields, 'count'>
        )> }
      ), plan_tasks: Array<(
        { __typename?: 'rabbit_test_plan_task' }
        & { task?: Maybe<(
          { __typename?: 'tasks' }
          & Pick<Tasks, 'id' | 'legacy_template_id'>
        )> }
      )> }
    )> }
  )> }
);

export type BenchmarkInstanceRecordingsQueryVariables = Exact<{
  project_id: Scalars['uuid'];
  device_id: Scalars['uuid'];
  engine_id: Scalars['uuid'];
  opmode_id: Scalars['uuid'];
  plan_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
}>;


export type BenchmarkInstanceRecordingsQuery = (
  { __typename?: 'query_root' }
  & { recordings: Array<(
    { __typename?: 'recordings' }
    & Pick<Recordings, 'id' | 'recorded_at'>
    & { task: (
      { __typename?: 'tasks' }
      & Pick<Tasks, 'id' | 'legacy_template_id'>
    ) }
  )> }
);

export type ProjectTemplatesQueryVariables = Exact<{
  project_id: Scalars['uuid'];
}>;


export type ProjectTemplatesQuery = (
  { __typename?: 'query_root' }
  & { project?: Maybe<(
    { __typename?: 'projects' }
    & { deliverable: (
      { __typename?: 'deliverables' }
      & { deliverable_templates: Array<(
        { __typename?: 'performance_report_deliverable_template' }
        & { template: (
          { __typename?: 'performance_report_templates' }
          & Pick<Performance_Report_Templates, 'id' | 'name' | 'description'>
        ) }
      )> }
    ) }
  )> }
);

export type TaskInstructionAndTranslationByPkQueryVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;


export type TaskInstructionAndTranslationByPkQuery = (
  { __typename?: 'query_root' }
  & { tasks_by_pk?: Maybe<(
    { __typename?: 'tasks' }
    & { task_instruction?: Maybe<(
      { __typename?: 'task_instructions' }
      & Pick<Task_Instructions, 'id' | 'content' | 'created_at' | 'status' | 'updated_at'>
      & { user: (
        { __typename?: 'users' }
        & Pick<Users, 'name'>
      ), instruction_translations: Array<(
        { __typename?: 'instruction_translations' }
        & Pick<Instruction_Translations, 'id' | 'content' | 'created_at' | 'language' | 'status' | 'updated_at'>
        & { user: (
          { __typename?: 'users' }
          & Pick<Users, 'name'>
        ) }
      )> }
    )> }
  )> }
);

export type ProjectChecklistProgressQueryVariables = Exact<{
  project_id: Scalars['uuid'];
}>;


export type ProjectChecklistProgressQuery = (
  { __typename?: 'query_root' }
  & { all_items_aggregate: (
    { __typename?: 'bringup_items_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'bringup_items_aggregate_fields' }
      & Pick<Bringup_Items_Aggregate_Fields, 'count'>
    )> }
  ), endorsed_items_aggregate: (
    { __typename?: 'bringup_endorsements_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'bringup_endorsements_aggregate_fields' }
      & Pick<Bringup_Endorsements_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type ProjectChecklistsQueryVariables = Exact<{
  project_id: Scalars['uuid'];
}>;


export type ProjectChecklistsQuery = (
  { __typename?: 'query_root' }
  & { projects_by_pk?: Maybe<(
    { __typename?: 'projects' }
    & { deliverable: (
      { __typename?: 'deliverables' }
      & { checklist_deliverables: Array<(
        { __typename?: 'bringup_checklist_deliverable' }
        & { checklist: (
          { __typename?: 'bringup_checklists' }
          & Pick<Bringup_Checklists, 'id' | 'name' | 'description'>
          & { items: Array<(
            { __typename?: 'bringup_items' }
            & { dependent_items: Array<(
              { __typename?: 'bringup_items' }
              & { checklist: (
                { __typename?: 'bringup_checklists' }
                & Pick<Bringup_Checklists, 'name'>
              ), endorsements_aggregate: (
                { __typename?: 'bringup_endorsements_aggregate' }
                & { aggregate?: Maybe<(
                  { __typename?: 'bringup_endorsements_aggregate_fields' }
                  & Pick<Bringup_Endorsements_Aggregate_Fields, 'count'>
                )> }
              ) }
              & ChecklistItemFragment
            )>, depends_on_item?: Maybe<(
              { __typename?: 'bringup_items' }
              & { checklist: (
                { __typename?: 'bringup_checklists' }
                & Pick<Bringup_Checklists, 'name'>
              ), endorsements_aggregate: (
                { __typename?: 'bringup_endorsements_aggregate' }
                & { aggregate?: Maybe<(
                  { __typename?: 'bringup_endorsements_aggregate_fields' }
                  & Pick<Bringup_Endorsements_Aggregate_Fields, 'count'>
                )> }
              ) }
              & ChecklistItemFragment
            )>, endorsements: Array<(
              { __typename?: 'bringup_endorsements' }
              & Pick<Bringup_Endorsements, 'created_at'>
              & { user: (
                { __typename?: 'users' }
                & Pick<Users, 'id' | 'name'>
              ) }
            )>, milestone?: Maybe<(
              { __typename?: 'bringup_milestones' }
              & Pick<Bringup_Milestones, 'name' | 'description'>
            )> }
            & ChecklistItemFragment
          )>, all_items_aggregate: (
            { __typename?: 'bringup_items_aggregate' }
            & { aggregate?: Maybe<(
              { __typename?: 'bringup_items_aggregate_fields' }
              & Pick<Bringup_Items_Aggregate_Fields, 'count'>
            )> }
          ), completed_items_aggregate: (
            { __typename?: 'bringup_items_aggregate' }
            & { aggregate?: Maybe<(
              { __typename?: 'bringup_items_aggregate_fields' }
              & Pick<Bringup_Items_Aggregate_Fields, 'count'>
            )> }
          ) }
        ) }
      )> }
    ) }
  )> }
);

export type UpsertTaskInstructionContentMutationVariables = Exact<{
  task_id: Scalars['uuid'];
  status: Scalars['String'];
  created_by: Scalars['uuid'];
  content: Scalars['String'];
}>;


export type UpsertTaskInstructionContentMutation = (
  { __typename?: 'mutation_root' }
  & { insert_task_instructions_one?: Maybe<(
    { __typename?: 'task_instructions' }
    & Pick<Task_Instructions, 'id'>
  )> }
);

export type UpsertTaskInstructionStatusMutationVariables = Exact<{
  task_id: Scalars['uuid'];
  status: Scalars['String'];
  created_by: Scalars['uuid'];
}>;


export type UpsertTaskInstructionStatusMutation = (
  { __typename?: 'mutation_root' }
  & { insert_task_instructions_one?: Maybe<(
    { __typename?: 'task_instructions' }
    & Pick<Task_Instructions, 'id'>
  )> }
);

export type UpsertInstructionTranslationMutationMutationVariables = Exact<{
  content: Scalars['String'];
  created_by: Scalars['uuid'];
  language: Scalars['String'];
  status: Scalars['String'];
  task_instruction_id: Scalars['uuid'];
}>;


export type UpsertInstructionTranslationMutationMutation = (
  { __typename?: 'mutation_root' }
  & { insert_instruction_translations_one?: Maybe<(
    { __typename?: 'instruction_translations' }
    & Pick<Instruction_Translations, 'id'>
  )> }
);

export type ProjectChecklistItemCommentsSubscriptionVariables = Exact<{
  project_id: Scalars['uuid'];
  item_id: Scalars['uuid'];
}>;


export type ProjectChecklistItemCommentsSubscription = (
  { __typename?: 'subscription_root' }
  & { comments: Array<(
    { __typename?: 'bringup_project_item_comments' }
    & Pick<Bringup_Project_Item_Comments, 'item_id' | 'project_id' | 'created_at' | 'updated_at' | 'deleted_at' | 'created_by' | 'content'>
    & { user: (
      { __typename?: 'users' }
      & Pick<Users, 'name'>
    ) }
  )> }
);

export type RecordingTagFieldsFragment = (
  { __typename?: 'recording_tags' }
  & Pick<Recording_Tags, 'id' | 'name' | 'description' | 'field' | 'dirty'>
);

export type RecordingFieldsFragment = (
  { __typename?: 'recordings' }
  & Pick<Recordings, 'id' | 'recorded_at'>
  & { task: (
    { __typename?: 'tasks' }
    & Pick<Tasks, 'id' | 'note' | 'description' | 'legacy_template_id'>
  ), device: (
    { __typename?: 'devices' }
    & Pick<Devices, 'id' | 'serialno'>
  ), firmware: (
    { __typename?: 'firmware' }
    & Pick<Firmware, 'id' | 'properties'>
  ), user: (
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'email'>
  ), project: (
    { __typename?: 'projects' }
    & Pick<Projects, 'id'>
    & ProjectCmdFragment
  ), engine_opmode_recordings: Array<(
    { __typename?: 'engine_opmode_recording' }
    & { engine: (
      { __typename?: 'engines' }
      & Pick<Engines, 'id' | 'branch' | 'build_ref' | 'build_version'>
    ), opmode: (
      { __typename?: 'opmodes' }
      & Pick<Opmodes, 'id' | 'number'>
    ) }
  )>, tags: Array<(
    { __typename?: 'recording_recording_tag' }
    & Pick<Recording_Recording_Tag, 'timestamp'>
    & { user: (
      { __typename?: 'users' }
      & Pick<Users, 'id' | 'name'>
    ), recording_tag: (
      { __typename?: 'recording_tags' }
      & RecordingTagFieldsFragment
    ) }
  )> }
);

export type ProjectDatasetQueryVariables = Exact<{
  project_id: Scalars['uuid'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  order_by?: Maybe<Array<Project_Dataset_Order_By> | Project_Dataset_Order_By>;
  where?: Maybe<Project_Dataset_Bool_Exp>;
}>;


export type ProjectDatasetQuery = (
  { __typename?: 'query_root' }
  & { project?: Maybe<(
    { __typename?: 'projects' }
    & { project_dataset_aggregate: (
      { __typename?: 'project_dataset_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'project_dataset_aggregate_fields' }
        & Pick<Project_Dataset_Aggregate_Fields, 'count'>
      )> }
    ), project_dataset: Array<(
      { __typename?: 'project_dataset' }
      & { recording?: Maybe<(
        { __typename?: 'recordings' }
        & RecordingFieldsFragment
      )> }
    )> }
  )> }
);

export type AllRecordingTagsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Tags_Order_By> | Recording_Tags_Order_By>;
  where?: Maybe<Recording_Tags_Bool_Exp>;
}>;


export type AllRecordingTagsQuery = (
  { __typename?: 'query_root' }
  & { recording_tags: Array<(
    { __typename?: 'recording_tags' }
    & RecordingTagFieldsFragment
  )> }
);

export type TemplateFieldsFragment = (
  { __typename?: 'performance_report_templates' }
  & Pick<Performance_Report_Templates, 'id' | 'name' | 'description' | 'created_at' | 'updated_at' | 'deleted_at'>
  & { user?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'name'>
  )> }
);

export type AllTemplatesQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  where?: Maybe<Performance_Report_Templates_Bool_Exp>;
  order_by?: Maybe<Array<Performance_Report_Templates_Order_By> | Performance_Report_Templates_Order_By>;
}>;


export type AllTemplatesQuery = (
  { __typename?: 'query_root' }
  & { templates_aggregate: (
    { __typename?: 'performance_report_templates_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'performance_report_templates_aggregate_fields' }
      & Pick<Performance_Report_Templates_Aggregate_Fields, 'count'>
    )> }
  ), templates: Array<(
    { __typename?: 'performance_report_templates' }
    & TemplateFieldsFragment
  )> }
);

export type TemplateQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type TemplateQuery = (
  { __typename?: 'query_root' }
  & { template?: Maybe<(
    { __typename?: 'performance_report_templates' }
    & { sections: Array<(
      { __typename?: 'performance_report_sections' }
      & Pick<Performance_Report_Sections, 'id' | 'position' | 'title' | 'include_missing_pivot_values' | 'pivot_type_id'>
      & { section_ratio_metrics: Array<(
        { __typename?: 'performance_report_section_ratio_metric' }
        & Pick<Performance_Report_Section_Ratio_Metric, 'position'>
        & { ratio_metric: (
          { __typename?: 'performance_ratio_metrics' }
          & Pick<Performance_Ratio_Metrics, 'id' | 'name' | 'description'>
        ) }
      )>, pivot_fields: Array<(
        { __typename?: 'performance_report_section_pivot_field' }
        & Pick<Performance_Report_Section_Pivot_Field, 'position'>
        & { field: (
          { __typename?: 'fields' }
          & Pick<Fields, 'id' | 'name' | 'display'>
        ) }
      )> }
    )> }
    & TemplateFieldsFragment
  )> }
);

export type CreateTemplateMutationVariables = Exact<{
  input: Performance_Report_Templates_Insert_Input;
}>;


export type CreateTemplateMutation = (
  { __typename?: 'mutation_root' }
  & { template?: Maybe<(
    { __typename?: 'performance_report_templates' }
    & Pick<Performance_Report_Templates, 'id'>
  )> }
);

export type UpdateTemplateMutationVariables = Exact<{
  template_id: Scalars['uuid'];
  columns: Performance_Report_Templates_Set_Input;
  sections: Array<Performance_Report_Sections_Insert_Input> | Performance_Report_Sections_Insert_Input;
}>;


export type UpdateTemplateMutation = (
  { __typename?: 'mutation_root' }
  & { delete_sections?: Maybe<(
    { __typename?: 'performance_report_sections_mutation_response' }
    & Pick<Performance_Report_Sections_Mutation_Response, 'affected_rows'>
  )>, template?: Maybe<(
    { __typename?: 'performance_report_templates' }
    & Pick<Performance_Report_Templates, 'id'>
  )>, insert_sections?: Maybe<(
    { __typename?: 'performance_report_sections_mutation_response' }
    & Pick<Performance_Report_Sections_Mutation_Response, 'affected_rows'>
  )> }
);

export type AggRoomByProjectQueryVariables = Exact<{
  project_id: Scalars['uuid'];
}>;


export type AggRoomByProjectQuery = (
  { __typename?: 'query_root' }
  & { project?: Maybe<(
    { __typename?: 'projects' }
    & { project_dataset: Array<(
      { __typename?: 'project_dataset' }
      & { room?: Maybe<(
        { __typename?: 'rooms' }
        & Pick<Rooms, 'id' | 'name' | 'building' | 'city'>
        & { clean: (
          { __typename?: 'project_dataset_aggregate' }
          & { aggregate?: Maybe<(
            { __typename?: 'project_dataset_aggregate_fields' }
            & Pick<Project_Dataset_Aggregate_Fields, 'count'>
          )> }
        ), dirty: (
          { __typename?: 'project_dataset_aggregate' }
          & { aggregate?: Maybe<(
            { __typename?: 'project_dataset_aggregate_fields' }
            & Pick<Project_Dataset_Aggregate_Fields, 'count'>
          )> }
        ), untagged: (
          { __typename?: 'project_dataset_aggregate' }
          & { aggregate?: Maybe<(
            { __typename?: 'project_dataset_aggregate_fields' }
            & Pick<Project_Dataset_Aggregate_Fields, 'count'>
          )> }
        ) }
      )> }
    )> }
  )> }
);

export type TaskGroupsHierarchyFieldsFragment = (
  { __typename?: 'task_groups_hierarchies' }
  & Pick<Task_Groups_Hierarchies, 'id' | 'name' | 'description' | 'created_at' | 'updated_at' | 'deleted_at'>
);

export type AllTaskGroupsHierarchiesQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  where?: Maybe<Task_Groups_Hierarchies_Bool_Exp>;
  order_by?: Maybe<Array<Task_Groups_Hierarchies_Order_By> | Task_Groups_Hierarchies_Order_By>;
}>;


export type AllTaskGroupsHierarchiesQuery = (
  { __typename?: 'query_root' }
  & { hierarchies_aggregate: (
    { __typename?: 'task_groups_hierarchies_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'task_groups_hierarchies_aggregate_fields' }
      & Pick<Task_Groups_Hierarchies_Aggregate_Fields, 'count'>
    )> }
  ), hierarchies: Array<(
    { __typename?: 'task_groups_hierarchies' }
    & TaskGroupsHierarchyFieldsFragment
  )> }
);

export type TaskGroupsHierarchyQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type TaskGroupsHierarchyQuery = (
  { __typename?: 'query_root' }
  & { hierarchy?: Maybe<(
    { __typename?: 'task_groups_hierarchies' }
    & { hierarchy_groups: Array<(
      { __typename?: 'task_groups_hierarchy_groups' }
      & Pick<Task_Groups_Hierarchy_Groups, 'group_id' | 'position'>
      & { group: (
        { __typename?: 'task_groups_groups' }
        & Pick<Task_Groups_Groups, 'id' | 'name'>
        & { group_fields: Array<(
          { __typename?: 'task_groups_group_fields' }
          & Pick<Task_Groups_Group_Fields, 'field_id'>
          & { field: (
            { __typename?: 'fields' }
            & Pick<Fields, 'id' | 'name' | 'display' | 'description'>
          ) }
        )> }
      ) }
    )> }
    & TaskGroupsHierarchyFieldsFragment
  )> }
);

export type CreateTaskGroupsHierarchyMutationVariables = Exact<{
  input: Task_Groups_Hierarchies_Insert_Input;
}>;


export type CreateTaskGroupsHierarchyMutation = (
  { __typename?: 'mutation_root' }
  & { hierarchy?: Maybe<(
    { __typename?: 'task_groups_hierarchies' }
    & TaskGroupsHierarchyFieldsFragment
  )> }
);

export type UpdateTaskGroupsHierarchyMutationVariables = Exact<{
  hierarchy_id: Scalars['uuid'];
  hierarchy_fields: Task_Groups_Hierarchies_Set_Input;
  hierarchy_groups: Array<Task_Groups_Hierarchy_Groups_Insert_Input> | Task_Groups_Hierarchy_Groups_Insert_Input;
}>;


export type UpdateTaskGroupsHierarchyMutation = (
  { __typename?: 'mutation_root' }
  & { hierarchy?: Maybe<(
    { __typename?: 'task_groups_hierarchies' }
    & TaskGroupsHierarchyFieldsFragment
  )>, deleted_groups?: Maybe<(
    { __typename?: 'task_groups_groups_mutation_response' }
    & Pick<Task_Groups_Groups_Mutation_Response, 'affected_rows'>
  )>, inserted_hierarchy_groups?: Maybe<(
    { __typename?: 'task_groups_hierarchy_groups_mutation_response' }
    & Pick<Task_Groups_Hierarchy_Groups_Mutation_Response, 'affected_rows'>
  )> }
);

export type GroupedProjectTasksQueryVariables = Exact<{
  hierarchy_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
}>;


export type GroupedProjectTasksQuery = (
  { __typename?: 'query_root' }
  & { hierarchy?: Maybe<(
    { __typename?: 'task_groups_hierarchies' }
    & { hierarchy_groups: Array<(
      { __typename?: 'task_groups_hierarchy_groups' }
      & Pick<Task_Groups_Hierarchy_Groups, 'group_id' | 'position'>
      & { group: (
        { __typename?: 'task_groups_groups' }
        & Pick<Task_Groups_Groups, 'id' | 'name'>
        & { group_fields: Array<(
          { __typename?: 'task_groups_group_fields' }
          & Pick<Task_Groups_Group_Fields, 'position' | 'field_id'>
          & { field: (
            { __typename?: 'fields' }
            & Pick<Fields, 'id' | 'name' | 'display' | 'description'>
            & { field_options: Array<(
              { __typename?: 'field_options' }
              & Pick<Field_Options, 'option_id'>
              & { option?: Maybe<(
                { __typename?: 'options' }
                & OptionFieldsFragment
              )>, task_field_options: Array<(
                { __typename?: 'task_field_option' }
                & Pick<Task_Field_Option, 'task_id'>
                & { task: (
                  { __typename?: 'tasks' }
                  & TaskFieldsFragment
                ) }
              )> }
            )> }
          ) }
        )> }
      ) }
    )> }
    & TaskGroupsHierarchyFieldsFragment
  )> }
);

export type ToolsQueryVariables = Exact<{ [key: string]: never; }>;


export type ToolsQuery = (
  { __typename?: 'query_root' }
  & { tools: Array<(
    { __typename?: 'tools' }
    & Pick<Tools, 'id' | 'title' | 'description' | 'storage_container' | 'storage_account' | 'documentation_url' | 'url' | 'sas'>
    & { tool_versions: Array<(
      { __typename?: 'tool_versions' }
      & Pick<Tool_Versions, 'id' | 'filename' | 'name' | 'storage_path' | 'released_at'>
    )> }
  )> }
);

export type AllLanguagesQueryVariables = Exact<{
  where?: Maybe<Languages_Bool_Exp>;
}>;


export type AllLanguagesQuery = (
  { __typename?: 'query_root' }
  & { languages: Array<(
    { __typename?: 'languages' }
    & Pick<Languages, 'id' | 'description'>
  )> }
);

export type UserFieldsFragment = (
  { __typename?: 'users' }
  & Pick<Users, 'id' | 'email' | 'name' | 'description' | 'created_at' | 'updated_at'>
  & UserRolesFieldFragment
);

export type UserRolesFieldFragment = (
  { __typename?: 'users' }
  & { role_users: Array<(
    { __typename?: 'role_user' }
    & Pick<Role_User, 'role_id'>
  )> }
);

export type AllUsersQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By> | Users_Order_By>;
  where?: Maybe<Users_Bool_Exp>;
}>;


export type AllUsersQuery = (
  { __typename?: 'query_root' }
  & { users_aggregate: (
    { __typename?: 'users_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'users_aggregate_fields' }
      & Pick<Users_Aggregate_Fields, 'count'>
    )> }
  ), users: Array<(
    { __typename?: 'users' }
    & { project_users_aggregate: (
      { __typename?: 'project_user_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'project_user_aggregate_fields' }
        & Pick<Project_User_Aggregate_Fields, 'count'>
      )> }
    ) }
    & UserFieldsFragment
    & UserRolesFieldFragment
  )> }
);

export type UserQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type UserQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & UserFieldsFragment
  )> }
);

export type CreateUserMutationVariables = Exact<{
  input: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
}>;


export type CreateUserMutation = (
  { __typename?: 'mutation_root' }
  & { insert_users_one?: Maybe<(
    { __typename?: 'users' }
    & UserFieldsFragment
  )> }
);



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Boolean_comparison_exp: Boolean_Comparison_Exp;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  ElapiSlackChannel: ResolverTypeWrapper<ElapiSlackChannel>;
  String: ResolverTypeWrapper<Scalars['String']>;
  Float_comparison_exp: Float_Comparison_Exp;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  Int_comparison_exp: Int_Comparison_Exp;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  String_comparison_exp: String_Comparison_Exp;
  _int2: ResolverTypeWrapper<Scalars['_int2']>;
  _int2_comparison_exp: _Int2_Comparison_Exp;
  _int4: ResolverTypeWrapper<Scalars['_int4']>;
  _int4_comparison_exp: _Int4_Comparison_Exp;
  _text: ResolverTypeWrapper<Scalars['_text']>;
  _text_comparison_exp: _Text_Comparison_Exp;
  _uuid: ResolverTypeWrapper<Scalars['_uuid']>;
  audio_driver: ResolverTypeWrapper<Audio_Driver>;
  audio_driver_bool_exp: Audio_Driver_Bool_Exp;
  audio_driver_constraint: Audio_Driver_Constraint;
  audio_driver_insert_input: Audio_Driver_Insert_Input;
  audio_driver_mutation_response: ResolverTypeWrapper<Audio_Driver_Mutation_Response>;
  audio_driver_obj_rel_insert_input: Audio_Driver_Obj_Rel_Insert_Input;
  audio_driver_on_conflict: Audio_Driver_On_Conflict;
  audio_driver_order_by: Audio_Driver_Order_By;
  audio_driver_pk_columns_input: Audio_Driver_Pk_Columns_Input;
  audio_driver_select_column: Audio_Driver_Select_Column;
  audio_driver_set_input: Audio_Driver_Set_Input;
  audio_driver_stream_cursor_input: Audio_Driver_Stream_Cursor_Input;
  audio_driver_stream_cursor_value_input: Audio_Driver_Stream_Cursor_Value_Input;
  audio_driver_update_column: Audio_Driver_Update_Column;
  audio_driver_updates: Audio_Driver_Updates;
  audio_measurements_components: ResolverTypeWrapper<Audio_Measurements_Components>;
  audio_measurements_components_bool_exp: Audio_Measurements_Components_Bool_Exp;
  audio_measurements_components_constraint: Audio_Measurements_Components_Constraint;
  audio_measurements_components_insert_input: Audio_Measurements_Components_Insert_Input;
  audio_measurements_components_mutation_response: ResolverTypeWrapper<Audio_Measurements_Components_Mutation_Response>;
  audio_measurements_components_obj_rel_insert_input: Audio_Measurements_Components_Obj_Rel_Insert_Input;
  audio_measurements_components_on_conflict: Audio_Measurements_Components_On_Conflict;
  audio_measurements_components_order_by: Audio_Measurements_Components_Order_By;
  audio_measurements_components_pk_columns_input: Audio_Measurements_Components_Pk_Columns_Input;
  audio_measurements_components_select_column: Audio_Measurements_Components_Select_Column;
  audio_measurements_components_set_input: Audio_Measurements_Components_Set_Input;
  audio_measurements_components_stream_cursor_input: Audio_Measurements_Components_Stream_Cursor_Input;
  audio_measurements_components_stream_cursor_value_input: Audio_Measurements_Components_Stream_Cursor_Value_Input;
  audio_measurements_components_update_column: Audio_Measurements_Components_Update_Column;
  audio_measurements_components_updates: Audio_Measurements_Components_Updates;
  audio_measurements_configurations: ResolverTypeWrapper<Audio_Measurements_Configurations>;
  audio_measurements_configurations_aggregate_order_by: Audio_Measurements_Configurations_Aggregate_Order_By;
  audio_measurements_configurations_arr_rel_insert_input: Audio_Measurements_Configurations_Arr_Rel_Insert_Input;
  audio_measurements_configurations_bool_exp: Audio_Measurements_Configurations_Bool_Exp;
  audio_measurements_configurations_constraint: Audio_Measurements_Configurations_Constraint;
  audio_measurements_configurations_insert_input: Audio_Measurements_Configurations_Insert_Input;
  audio_measurements_configurations_max_order_by: Audio_Measurements_Configurations_Max_Order_By;
  audio_measurements_configurations_min_order_by: Audio_Measurements_Configurations_Min_Order_By;
  audio_measurements_configurations_mutation_response: ResolverTypeWrapper<Audio_Measurements_Configurations_Mutation_Response>;
  audio_measurements_configurations_obj_rel_insert_input: Audio_Measurements_Configurations_Obj_Rel_Insert_Input;
  audio_measurements_configurations_on_conflict: Audio_Measurements_Configurations_On_Conflict;
  audio_measurements_configurations_order_by: Audio_Measurements_Configurations_Order_By;
  audio_measurements_configurations_pk_columns_input: Audio_Measurements_Configurations_Pk_Columns_Input;
  audio_measurements_configurations_select_column: Audio_Measurements_Configurations_Select_Column;
  audio_measurements_configurations_set_input: Audio_Measurements_Configurations_Set_Input;
  audio_measurements_configurations_stream_cursor_input: Audio_Measurements_Configurations_Stream_Cursor_Input;
  audio_measurements_configurations_stream_cursor_value_input: Audio_Measurements_Configurations_Stream_Cursor_Value_Input;
  audio_measurements_configurations_update_column: Audio_Measurements_Configurations_Update_Column;
  audio_measurements_configurations_updates: Audio_Measurements_Configurations_Updates;
  audio_measurements_data_types: ResolverTypeWrapper<Audio_Measurements_Data_Types>;
  audio_measurements_data_types_aggregate: ResolverTypeWrapper<Audio_Measurements_Data_Types_Aggregate>;
  audio_measurements_data_types_aggregate_fields: ResolverTypeWrapper<Audio_Measurements_Data_Types_Aggregate_Fields>;
  audio_measurements_data_types_bool_exp: Audio_Measurements_Data_Types_Bool_Exp;
  audio_measurements_data_types_max_fields: ResolverTypeWrapper<Audio_Measurements_Data_Types_Max_Fields>;
  audio_measurements_data_types_min_fields: ResolverTypeWrapper<Audio_Measurements_Data_Types_Min_Fields>;
  audio_measurements_data_types_order_by: Audio_Measurements_Data_Types_Order_By;
  audio_measurements_data_types_select_column: Audio_Measurements_Data_Types_Select_Column;
  audio_measurements_data_types_stream_cursor_input: Audio_Measurements_Data_Types_Stream_Cursor_Input;
  audio_measurements_data_types_stream_cursor_value_input: Audio_Measurements_Data_Types_Stream_Cursor_Value_Input;
  audio_measurements_master_files: ResolverTypeWrapper<Audio_Measurements_Master_Files>;
  audio_measurements_master_files_aggregate: ResolverTypeWrapper<Audio_Measurements_Master_Files_Aggregate>;
  audio_measurements_master_files_aggregate_fields: ResolverTypeWrapper<Audio_Measurements_Master_Files_Aggregate_Fields>;
  audio_measurements_master_files_aggregate_order_by: Audio_Measurements_Master_Files_Aggregate_Order_By;
  audio_measurements_master_files_avg_fields: ResolverTypeWrapper<Audio_Measurements_Master_Files_Avg_Fields>;
  audio_measurements_master_files_avg_order_by: Audio_Measurements_Master_Files_Avg_Order_By;
  audio_measurements_master_files_bool_exp: Audio_Measurements_Master_Files_Bool_Exp;
  audio_measurements_master_files_constraint: Audio_Measurements_Master_Files_Constraint;
  audio_measurements_master_files_inc_input: Audio_Measurements_Master_Files_Inc_Input;
  audio_measurements_master_files_insert_input: Audio_Measurements_Master_Files_Insert_Input;
  audio_measurements_master_files_max_fields: ResolverTypeWrapper<Audio_Measurements_Master_Files_Max_Fields>;
  audio_measurements_master_files_max_order_by: Audio_Measurements_Master_Files_Max_Order_By;
  audio_measurements_master_files_min_fields: ResolverTypeWrapper<Audio_Measurements_Master_Files_Min_Fields>;
  audio_measurements_master_files_min_order_by: Audio_Measurements_Master_Files_Min_Order_By;
  audio_measurements_master_files_mutation_response: ResolverTypeWrapper<Audio_Measurements_Master_Files_Mutation_Response>;
  audio_measurements_master_files_obj_rel_insert_input: Audio_Measurements_Master_Files_Obj_Rel_Insert_Input;
  audio_measurements_master_files_on_conflict: Audio_Measurements_Master_Files_On_Conflict;
  audio_measurements_master_files_order_by: Audio_Measurements_Master_Files_Order_By;
  audio_measurements_master_files_pk_columns_input: Audio_Measurements_Master_Files_Pk_Columns_Input;
  audio_measurements_master_files_select_column: Audio_Measurements_Master_Files_Select_Column;
  audio_measurements_master_files_set_input: Audio_Measurements_Master_Files_Set_Input;
  audio_measurements_master_files_stddev_fields: ResolverTypeWrapper<Audio_Measurements_Master_Files_Stddev_Fields>;
  audio_measurements_master_files_stddev_order_by: Audio_Measurements_Master_Files_Stddev_Order_By;
  audio_measurements_master_files_stddev_pop_fields: ResolverTypeWrapper<Audio_Measurements_Master_Files_Stddev_Pop_Fields>;
  audio_measurements_master_files_stddev_pop_order_by: Audio_Measurements_Master_Files_Stddev_Pop_Order_By;
  audio_measurements_master_files_stddev_samp_fields: ResolverTypeWrapper<Audio_Measurements_Master_Files_Stddev_Samp_Fields>;
  audio_measurements_master_files_stddev_samp_order_by: Audio_Measurements_Master_Files_Stddev_Samp_Order_By;
  audio_measurements_master_files_stream_cursor_input: Audio_Measurements_Master_Files_Stream_Cursor_Input;
  audio_measurements_master_files_stream_cursor_value_input: Audio_Measurements_Master_Files_Stream_Cursor_Value_Input;
  audio_measurements_master_files_sum_fields: ResolverTypeWrapper<Audio_Measurements_Master_Files_Sum_Fields>;
  audio_measurements_master_files_sum_order_by: Audio_Measurements_Master_Files_Sum_Order_By;
  audio_measurements_master_files_update_column: Audio_Measurements_Master_Files_Update_Column;
  audio_measurements_master_files_updates: Audio_Measurements_Master_Files_Updates;
  audio_measurements_master_files_var_pop_fields: ResolverTypeWrapper<Audio_Measurements_Master_Files_Var_Pop_Fields>;
  audio_measurements_master_files_var_pop_order_by: Audio_Measurements_Master_Files_Var_Pop_Order_By;
  audio_measurements_master_files_var_samp_fields: ResolverTypeWrapper<Audio_Measurements_Master_Files_Var_Samp_Fields>;
  audio_measurements_master_files_var_samp_order_by: Audio_Measurements_Master_Files_Var_Samp_Order_By;
  audio_measurements_master_files_variance_fields: ResolverTypeWrapper<Audio_Measurements_Master_Files_Variance_Fields>;
  audio_measurements_master_files_variance_order_by: Audio_Measurements_Master_Files_Variance_Order_By;
  audio_measurements_orientations: ResolverTypeWrapper<Audio_Measurements_Orientations>;
  audio_measurements_orientations_bool_exp: Audio_Measurements_Orientations_Bool_Exp;
  audio_measurements_orientations_constraint: Audio_Measurements_Orientations_Constraint;
  audio_measurements_orientations_insert_input: Audio_Measurements_Orientations_Insert_Input;
  audio_measurements_orientations_mutation_response: ResolverTypeWrapper<Audio_Measurements_Orientations_Mutation_Response>;
  audio_measurements_orientations_obj_rel_insert_input: Audio_Measurements_Orientations_Obj_Rel_Insert_Input;
  audio_measurements_orientations_on_conflict: Audio_Measurements_Orientations_On_Conflict;
  audio_measurements_orientations_order_by: Audio_Measurements_Orientations_Order_By;
  audio_measurements_orientations_pk_columns_input: Audio_Measurements_Orientations_Pk_Columns_Input;
  audio_measurements_orientations_select_column: Audio_Measurements_Orientations_Select_Column;
  audio_measurements_orientations_set_input: Audio_Measurements_Orientations_Set_Input;
  audio_measurements_orientations_stream_cursor_input: Audio_Measurements_Orientations_Stream_Cursor_Input;
  audio_measurements_orientations_stream_cursor_value_input: Audio_Measurements_Orientations_Stream_Cursor_Value_Input;
  audio_measurements_orientations_update_column: Audio_Measurements_Orientations_Update_Column;
  audio_measurements_orientations_updates: Audio_Measurements_Orientations_Updates;
  audio_measurements_payload_recording: ResolverTypeWrapper<Audio_Measurements_Payload_Recording>;
  audio_measurements_payload_recording_aggregate: ResolverTypeWrapper<Audio_Measurements_Payload_Recording_Aggregate>;
  audio_measurements_payload_recording_aggregate_fields: ResolverTypeWrapper<Audio_Measurements_Payload_Recording_Aggregate_Fields>;
  audio_measurements_payload_recording_aggregate_order_by: Audio_Measurements_Payload_Recording_Aggregate_Order_By;
  audio_measurements_payload_recording_arr_rel_insert_input: Audio_Measurements_Payload_Recording_Arr_Rel_Insert_Input;
  audio_measurements_payload_recording_bool_exp: Audio_Measurements_Payload_Recording_Bool_Exp;
  audio_measurements_payload_recording_constraint: Audio_Measurements_Payload_Recording_Constraint;
  audio_measurements_payload_recording_insert_input: Audio_Measurements_Payload_Recording_Insert_Input;
  audio_measurements_payload_recording_max_fields: ResolverTypeWrapper<Audio_Measurements_Payload_Recording_Max_Fields>;
  audio_measurements_payload_recording_max_order_by: Audio_Measurements_Payload_Recording_Max_Order_By;
  audio_measurements_payload_recording_min_fields: ResolverTypeWrapper<Audio_Measurements_Payload_Recording_Min_Fields>;
  audio_measurements_payload_recording_min_order_by: Audio_Measurements_Payload_Recording_Min_Order_By;
  audio_measurements_payload_recording_mutation_response: ResolverTypeWrapper<Audio_Measurements_Payload_Recording_Mutation_Response>;
  audio_measurements_payload_recording_on_conflict: Audio_Measurements_Payload_Recording_On_Conflict;
  audio_measurements_payload_recording_order_by: Audio_Measurements_Payload_Recording_Order_By;
  audio_measurements_payload_recording_pk_columns_input: Audio_Measurements_Payload_Recording_Pk_Columns_Input;
  audio_measurements_payload_recording_select_column: Audio_Measurements_Payload_Recording_Select_Column;
  audio_measurements_payload_recording_set_input: Audio_Measurements_Payload_Recording_Set_Input;
  audio_measurements_payload_recording_stream_cursor_input: Audio_Measurements_Payload_Recording_Stream_Cursor_Input;
  audio_measurements_payload_recording_stream_cursor_value_input: Audio_Measurements_Payload_Recording_Stream_Cursor_Value_Input;
  audio_measurements_payload_recording_update_column: Audio_Measurements_Payload_Recording_Update_Column;
  audio_measurements_payload_recording_updates: Audio_Measurements_Payload_Recording_Updates;
  audio_measurements_placements: ResolverTypeWrapper<Audio_Measurements_Placements>;
  audio_measurements_placements_bool_exp: Audio_Measurements_Placements_Bool_Exp;
  audio_measurements_placements_constraint: Audio_Measurements_Placements_Constraint;
  audio_measurements_placements_insert_input: Audio_Measurements_Placements_Insert_Input;
  audio_measurements_placements_mutation_response: ResolverTypeWrapper<Audio_Measurements_Placements_Mutation_Response>;
  audio_measurements_placements_obj_rel_insert_input: Audio_Measurements_Placements_Obj_Rel_Insert_Input;
  audio_measurements_placements_on_conflict: Audio_Measurements_Placements_On_Conflict;
  audio_measurements_placements_order_by: Audio_Measurements_Placements_Order_By;
  audio_measurements_placements_pk_columns_input: Audio_Measurements_Placements_Pk_Columns_Input;
  audio_measurements_placements_select_column: Audio_Measurements_Placements_Select_Column;
  audio_measurements_placements_set_input: Audio_Measurements_Placements_Set_Input;
  audio_measurements_placements_stream_cursor_input: Audio_Measurements_Placements_Stream_Cursor_Input;
  audio_measurements_placements_stream_cursor_value_input: Audio_Measurements_Placements_Stream_Cursor_Value_Input;
  audio_measurements_placements_update_column: Audio_Measurements_Placements_Update_Column;
  audio_measurements_placements_updates: Audio_Measurements_Placements_Updates;
  audio_measurements_project_tasks: ResolverTypeWrapper<Audio_Measurements_Project_Tasks>;
  audio_measurements_project_tasks_aggregate: ResolverTypeWrapper<Audio_Measurements_Project_Tasks_Aggregate>;
  audio_measurements_project_tasks_aggregate_fields: ResolverTypeWrapper<Audio_Measurements_Project_Tasks_Aggregate_Fields>;
  audio_measurements_project_tasks_aggregate_order_by: Audio_Measurements_Project_Tasks_Aggregate_Order_By;
  audio_measurements_project_tasks_arr_rel_insert_input: Audio_Measurements_Project_Tasks_Arr_Rel_Insert_Input;
  audio_measurements_project_tasks_avg_fields: ResolverTypeWrapper<Audio_Measurements_Project_Tasks_Avg_Fields>;
  audio_measurements_project_tasks_avg_order_by: Audio_Measurements_Project_Tasks_Avg_Order_By;
  audio_measurements_project_tasks_bool_exp: Audio_Measurements_Project_Tasks_Bool_Exp;
  audio_measurements_project_tasks_constraint: Audio_Measurements_Project_Tasks_Constraint;
  audio_measurements_project_tasks_inc_input: Audio_Measurements_Project_Tasks_Inc_Input;
  audio_measurements_project_tasks_insert_input: Audio_Measurements_Project_Tasks_Insert_Input;
  audio_measurements_project_tasks_max_fields: ResolverTypeWrapper<Audio_Measurements_Project_Tasks_Max_Fields>;
  audio_measurements_project_tasks_max_order_by: Audio_Measurements_Project_Tasks_Max_Order_By;
  audio_measurements_project_tasks_min_fields: ResolverTypeWrapper<Audio_Measurements_Project_Tasks_Min_Fields>;
  audio_measurements_project_tasks_min_order_by: Audio_Measurements_Project_Tasks_Min_Order_By;
  audio_measurements_project_tasks_mutation_response: ResolverTypeWrapper<Audio_Measurements_Project_Tasks_Mutation_Response>;
  audio_measurements_project_tasks_on_conflict: Audio_Measurements_Project_Tasks_On_Conflict;
  audio_measurements_project_tasks_order_by: Audio_Measurements_Project_Tasks_Order_By;
  audio_measurements_project_tasks_pk_columns_input: Audio_Measurements_Project_Tasks_Pk_Columns_Input;
  audio_measurements_project_tasks_select_column: Audio_Measurements_Project_Tasks_Select_Column;
  audio_measurements_project_tasks_set_input: Audio_Measurements_Project_Tasks_Set_Input;
  audio_measurements_project_tasks_stddev_fields: ResolverTypeWrapper<Audio_Measurements_Project_Tasks_Stddev_Fields>;
  audio_measurements_project_tasks_stddev_order_by: Audio_Measurements_Project_Tasks_Stddev_Order_By;
  audio_measurements_project_tasks_stddev_pop_fields: ResolverTypeWrapper<Audio_Measurements_Project_Tasks_Stddev_Pop_Fields>;
  audio_measurements_project_tasks_stddev_pop_order_by: Audio_Measurements_Project_Tasks_Stddev_Pop_Order_By;
  audio_measurements_project_tasks_stddev_samp_fields: ResolverTypeWrapper<Audio_Measurements_Project_Tasks_Stddev_Samp_Fields>;
  audio_measurements_project_tasks_stddev_samp_order_by: Audio_Measurements_Project_Tasks_Stddev_Samp_Order_By;
  audio_measurements_project_tasks_stream_cursor_input: Audio_Measurements_Project_Tasks_Stream_Cursor_Input;
  audio_measurements_project_tasks_stream_cursor_value_input: Audio_Measurements_Project_Tasks_Stream_Cursor_Value_Input;
  audio_measurements_project_tasks_sum_fields: ResolverTypeWrapper<Audio_Measurements_Project_Tasks_Sum_Fields>;
  audio_measurements_project_tasks_sum_order_by: Audio_Measurements_Project_Tasks_Sum_Order_By;
  audio_measurements_project_tasks_update_column: Audio_Measurements_Project_Tasks_Update_Column;
  audio_measurements_project_tasks_updates: Audio_Measurements_Project_Tasks_Updates;
  audio_measurements_project_tasks_var_pop_fields: ResolverTypeWrapper<Audio_Measurements_Project_Tasks_Var_Pop_Fields>;
  audio_measurements_project_tasks_var_pop_order_by: Audio_Measurements_Project_Tasks_Var_Pop_Order_By;
  audio_measurements_project_tasks_var_samp_fields: ResolverTypeWrapper<Audio_Measurements_Project_Tasks_Var_Samp_Fields>;
  audio_measurements_project_tasks_var_samp_order_by: Audio_Measurements_Project_Tasks_Var_Samp_Order_By;
  audio_measurements_project_tasks_variance_fields: ResolverTypeWrapper<Audio_Measurements_Project_Tasks_Variance_Fields>;
  audio_measurements_project_tasks_variance_order_by: Audio_Measurements_Project_Tasks_Variance_Order_By;
  audio_measurements_recordings: ResolverTypeWrapper<Audio_Measurements_Recordings>;
  audio_measurements_recordings_aggregate: ResolverTypeWrapper<Audio_Measurements_Recordings_Aggregate>;
  audio_measurements_recordings_aggregate_fields: ResolverTypeWrapper<Audio_Measurements_Recordings_Aggregate_Fields>;
  audio_measurements_recordings_aggregate_order_by: Audio_Measurements_Recordings_Aggregate_Order_By;
  audio_measurements_recordings_arr_rel_insert_input: Audio_Measurements_Recordings_Arr_Rel_Insert_Input;
  audio_measurements_recordings_avg_fields: ResolverTypeWrapper<Audio_Measurements_Recordings_Avg_Fields>;
  audio_measurements_recordings_avg_order_by: Audio_Measurements_Recordings_Avg_Order_By;
  audio_measurements_recordings_bool_exp: Audio_Measurements_Recordings_Bool_Exp;
  audio_measurements_recordings_constraint: Audio_Measurements_Recordings_Constraint;
  audio_measurements_recordings_inc_input: Audio_Measurements_Recordings_Inc_Input;
  audio_measurements_recordings_insert_input: Audio_Measurements_Recordings_Insert_Input;
  audio_measurements_recordings_max_fields: ResolverTypeWrapper<Audio_Measurements_Recordings_Max_Fields>;
  audio_measurements_recordings_max_order_by: Audio_Measurements_Recordings_Max_Order_By;
  audio_measurements_recordings_min_fields: ResolverTypeWrapper<Audio_Measurements_Recordings_Min_Fields>;
  audio_measurements_recordings_min_order_by: Audio_Measurements_Recordings_Min_Order_By;
  audio_measurements_recordings_mutation_response: ResolverTypeWrapper<Audio_Measurements_Recordings_Mutation_Response>;
  audio_measurements_recordings_obj_rel_insert_input: Audio_Measurements_Recordings_Obj_Rel_Insert_Input;
  audio_measurements_recordings_on_conflict: Audio_Measurements_Recordings_On_Conflict;
  audio_measurements_recordings_order_by: Audio_Measurements_Recordings_Order_By;
  audio_measurements_recordings_pk_columns_input: Audio_Measurements_Recordings_Pk_Columns_Input;
  audio_measurements_recordings_select_column: Audio_Measurements_Recordings_Select_Column;
  audio_measurements_recordings_set_input: Audio_Measurements_Recordings_Set_Input;
  audio_measurements_recordings_stddev_fields: ResolverTypeWrapper<Audio_Measurements_Recordings_Stddev_Fields>;
  audio_measurements_recordings_stddev_order_by: Audio_Measurements_Recordings_Stddev_Order_By;
  audio_measurements_recordings_stddev_pop_fields: ResolverTypeWrapper<Audio_Measurements_Recordings_Stddev_Pop_Fields>;
  audio_measurements_recordings_stddev_pop_order_by: Audio_Measurements_Recordings_Stddev_Pop_Order_By;
  audio_measurements_recordings_stddev_samp_fields: ResolverTypeWrapper<Audio_Measurements_Recordings_Stddev_Samp_Fields>;
  audio_measurements_recordings_stddev_samp_order_by: Audio_Measurements_Recordings_Stddev_Samp_Order_By;
  audio_measurements_recordings_stream_cursor_input: Audio_Measurements_Recordings_Stream_Cursor_Input;
  audio_measurements_recordings_stream_cursor_value_input: Audio_Measurements_Recordings_Stream_Cursor_Value_Input;
  audio_measurements_recordings_sum_fields: ResolverTypeWrapper<Audio_Measurements_Recordings_Sum_Fields>;
  audio_measurements_recordings_sum_order_by: Audio_Measurements_Recordings_Sum_Order_By;
  audio_measurements_recordings_update_column: Audio_Measurements_Recordings_Update_Column;
  audio_measurements_recordings_updates: Audio_Measurements_Recordings_Updates;
  audio_measurements_recordings_var_pop_fields: ResolverTypeWrapper<Audio_Measurements_Recordings_Var_Pop_Fields>;
  audio_measurements_recordings_var_pop_order_by: Audio_Measurements_Recordings_Var_Pop_Order_By;
  audio_measurements_recordings_var_samp_fields: ResolverTypeWrapper<Audio_Measurements_Recordings_Var_Samp_Fields>;
  audio_measurements_recordings_var_samp_order_by: Audio_Measurements_Recordings_Var_Samp_Order_By;
  audio_measurements_recordings_variance_fields: ResolverTypeWrapper<Audio_Measurements_Recordings_Variance_Fields>;
  audio_measurements_recordings_variance_order_by: Audio_Measurements_Recordings_Variance_Order_By;
  audio_measurements_stages: ResolverTypeWrapper<Audio_Measurements_Stages>;
  audio_measurements_stages_bool_exp: Audio_Measurements_Stages_Bool_Exp;
  audio_measurements_stages_constraint: Audio_Measurements_Stages_Constraint;
  audio_measurements_stages_insert_input: Audio_Measurements_Stages_Insert_Input;
  audio_measurements_stages_mutation_response: ResolverTypeWrapper<Audio_Measurements_Stages_Mutation_Response>;
  audio_measurements_stages_obj_rel_insert_input: Audio_Measurements_Stages_Obj_Rel_Insert_Input;
  audio_measurements_stages_on_conflict: Audio_Measurements_Stages_On_Conflict;
  audio_measurements_stages_order_by: Audio_Measurements_Stages_Order_By;
  audio_measurements_stages_pk_columns_input: Audio_Measurements_Stages_Pk_Columns_Input;
  audio_measurements_stages_select_column: Audio_Measurements_Stages_Select_Column;
  audio_measurements_stages_set_input: Audio_Measurements_Stages_Set_Input;
  audio_measurements_stages_stream_cursor_input: Audio_Measurements_Stages_Stream_Cursor_Input;
  audio_measurements_stages_stream_cursor_value_input: Audio_Measurements_Stages_Stream_Cursor_Value_Input;
  audio_measurements_stages_update_column: Audio_Measurements_Stages_Update_Column;
  audio_measurements_stages_updates: Audio_Measurements_Stages_Updates;
  bigint: ResolverTypeWrapper<Scalars['bigint']>;
  bigint_comparison_exp: Bigint_Comparison_Exp;
  bringup_checklist_deliverable: ResolverTypeWrapper<Bringup_Checklist_Deliverable>;
  bringup_checklist_deliverable_aggregate: ResolverTypeWrapper<Bringup_Checklist_Deliverable_Aggregate>;
  bringup_checklist_deliverable_aggregate_fields: ResolverTypeWrapper<Bringup_Checklist_Deliverable_Aggregate_Fields>;
  bringup_checklist_deliverable_aggregate_order_by: Bringup_Checklist_Deliverable_Aggregate_Order_By;
  bringup_checklist_deliverable_arr_rel_insert_input: Bringup_Checklist_Deliverable_Arr_Rel_Insert_Input;
  bringup_checklist_deliverable_bool_exp: Bringup_Checklist_Deliverable_Bool_Exp;
  bringup_checklist_deliverable_constraint: Bringup_Checklist_Deliverable_Constraint;
  bringup_checklist_deliverable_insert_input: Bringup_Checklist_Deliverable_Insert_Input;
  bringup_checklist_deliverable_max_fields: ResolverTypeWrapper<Bringup_Checklist_Deliverable_Max_Fields>;
  bringup_checklist_deliverable_max_order_by: Bringup_Checklist_Deliverable_Max_Order_By;
  bringup_checklist_deliverable_min_fields: ResolverTypeWrapper<Bringup_Checklist_Deliverable_Min_Fields>;
  bringup_checklist_deliverable_min_order_by: Bringup_Checklist_Deliverable_Min_Order_By;
  bringup_checklist_deliverable_mutation_response: ResolverTypeWrapper<Bringup_Checklist_Deliverable_Mutation_Response>;
  bringup_checklist_deliverable_on_conflict: Bringup_Checklist_Deliverable_On_Conflict;
  bringup_checklist_deliverable_order_by: Bringup_Checklist_Deliverable_Order_By;
  bringup_checklist_deliverable_pk_columns_input: Bringup_Checklist_Deliverable_Pk_Columns_Input;
  bringup_checklist_deliverable_select_column: Bringup_Checklist_Deliverable_Select_Column;
  bringup_checklist_deliverable_set_input: Bringup_Checklist_Deliverable_Set_Input;
  bringup_checklist_deliverable_stream_cursor_input: Bringup_Checklist_Deliverable_Stream_Cursor_Input;
  bringup_checklist_deliverable_stream_cursor_value_input: Bringup_Checklist_Deliverable_Stream_Cursor_Value_Input;
  bringup_checklist_deliverable_update_column: Bringup_Checklist_Deliverable_Update_Column;
  bringup_checklist_deliverable_updates: Bringup_Checklist_Deliverable_Updates;
  bringup_checklists: ResolverTypeWrapper<Bringup_Checklists>;
  bringup_checklists_aggregate: ResolverTypeWrapper<Bringup_Checklists_Aggregate>;
  bringup_checklists_aggregate_fields: ResolverTypeWrapper<Bringup_Checklists_Aggregate_Fields>;
  bringup_checklists_bool_exp: Bringup_Checklists_Bool_Exp;
  bringup_checklists_constraint: Bringup_Checklists_Constraint;
  bringup_checklists_insert_input: Bringup_Checklists_Insert_Input;
  bringup_checklists_max_fields: ResolverTypeWrapper<Bringup_Checklists_Max_Fields>;
  bringup_checklists_min_fields: ResolverTypeWrapper<Bringup_Checklists_Min_Fields>;
  bringup_checklists_mutation_response: ResolverTypeWrapper<Bringup_Checklists_Mutation_Response>;
  bringup_checklists_obj_rel_insert_input: Bringup_Checklists_Obj_Rel_Insert_Input;
  bringup_checklists_on_conflict: Bringup_Checklists_On_Conflict;
  bringup_checklists_order_by: Bringup_Checklists_Order_By;
  bringup_checklists_pk_columns_input: Bringup_Checklists_Pk_Columns_Input;
  bringup_checklists_select_column: Bringup_Checklists_Select_Column;
  bringup_checklists_set_input: Bringup_Checklists_Set_Input;
  bringup_checklists_stream_cursor_input: Bringup_Checklists_Stream_Cursor_Input;
  bringup_checklists_stream_cursor_value_input: Bringup_Checklists_Stream_Cursor_Value_Input;
  bringup_checklists_update_column: Bringup_Checklists_Update_Column;
  bringup_checklists_updates: Bringup_Checklists_Updates;
  bringup_endorsements: ResolverTypeWrapper<Bringup_Endorsements>;
  bringup_endorsements_aggregate: ResolverTypeWrapper<Bringup_Endorsements_Aggregate>;
  bringup_endorsements_aggregate_fields: ResolverTypeWrapper<Bringup_Endorsements_Aggregate_Fields>;
  bringup_endorsements_aggregate_order_by: Bringup_Endorsements_Aggregate_Order_By;
  bringup_endorsements_arr_rel_insert_input: Bringup_Endorsements_Arr_Rel_Insert_Input;
  bringup_endorsements_bool_exp: Bringup_Endorsements_Bool_Exp;
  bringup_endorsements_constraint: Bringup_Endorsements_Constraint;
  bringup_endorsements_insert_input: Bringup_Endorsements_Insert_Input;
  bringup_endorsements_max_fields: ResolverTypeWrapper<Bringup_Endorsements_Max_Fields>;
  bringup_endorsements_max_order_by: Bringup_Endorsements_Max_Order_By;
  bringup_endorsements_min_fields: ResolverTypeWrapper<Bringup_Endorsements_Min_Fields>;
  bringup_endorsements_min_order_by: Bringup_Endorsements_Min_Order_By;
  bringup_endorsements_mutation_response: ResolverTypeWrapper<Bringup_Endorsements_Mutation_Response>;
  bringup_endorsements_on_conflict: Bringup_Endorsements_On_Conflict;
  bringup_endorsements_order_by: Bringup_Endorsements_Order_By;
  bringup_endorsements_pk_columns_input: Bringup_Endorsements_Pk_Columns_Input;
  bringup_endorsements_select_column: Bringup_Endorsements_Select_Column;
  bringup_endorsements_set_input: Bringup_Endorsements_Set_Input;
  bringup_endorsements_stream_cursor_input: Bringup_Endorsements_Stream_Cursor_Input;
  bringup_endorsements_stream_cursor_value_input: Bringup_Endorsements_Stream_Cursor_Value_Input;
  bringup_endorsements_update_column: Bringup_Endorsements_Update_Column;
  bringup_endorsements_updates: Bringup_Endorsements_Updates;
  bringup_items: ResolverTypeWrapper<Bringup_Items>;
  bringup_items_aggregate: ResolverTypeWrapper<Bringup_Items_Aggregate>;
  bringup_items_aggregate_fields: ResolverTypeWrapper<Bringup_Items_Aggregate_Fields>;
  bringup_items_aggregate_order_by: Bringup_Items_Aggregate_Order_By;
  bringup_items_arr_rel_insert_input: Bringup_Items_Arr_Rel_Insert_Input;
  bringup_items_avg_fields: ResolverTypeWrapper<Bringup_Items_Avg_Fields>;
  bringup_items_avg_order_by: Bringup_Items_Avg_Order_By;
  bringup_items_bool_exp: Bringup_Items_Bool_Exp;
  bringup_items_constraint: Bringup_Items_Constraint;
  bringup_items_inc_input: Bringup_Items_Inc_Input;
  bringup_items_insert_input: Bringup_Items_Insert_Input;
  bringup_items_max_fields: ResolverTypeWrapper<Bringup_Items_Max_Fields>;
  bringup_items_max_order_by: Bringup_Items_Max_Order_By;
  bringup_items_min_fields: ResolverTypeWrapper<Bringup_Items_Min_Fields>;
  bringup_items_min_order_by: Bringup_Items_Min_Order_By;
  bringup_items_mutation_response: ResolverTypeWrapper<Bringup_Items_Mutation_Response>;
  bringup_items_obj_rel_insert_input: Bringup_Items_Obj_Rel_Insert_Input;
  bringup_items_on_conflict: Bringup_Items_On_Conflict;
  bringup_items_order_by: Bringup_Items_Order_By;
  bringup_items_pk_columns_input: Bringup_Items_Pk_Columns_Input;
  bringup_items_select_column: Bringup_Items_Select_Column;
  bringup_items_set_input: Bringup_Items_Set_Input;
  bringup_items_stddev_fields: ResolverTypeWrapper<Bringup_Items_Stddev_Fields>;
  bringup_items_stddev_order_by: Bringup_Items_Stddev_Order_By;
  bringup_items_stddev_pop_fields: ResolverTypeWrapper<Bringup_Items_Stddev_Pop_Fields>;
  bringup_items_stddev_pop_order_by: Bringup_Items_Stddev_Pop_Order_By;
  bringup_items_stddev_samp_fields: ResolverTypeWrapper<Bringup_Items_Stddev_Samp_Fields>;
  bringup_items_stddev_samp_order_by: Bringup_Items_Stddev_Samp_Order_By;
  bringup_items_stream_cursor_input: Bringup_Items_Stream_Cursor_Input;
  bringup_items_stream_cursor_value_input: Bringup_Items_Stream_Cursor_Value_Input;
  bringup_items_sum_fields: ResolverTypeWrapper<Bringup_Items_Sum_Fields>;
  bringup_items_sum_order_by: Bringup_Items_Sum_Order_By;
  bringup_items_update_column: Bringup_Items_Update_Column;
  bringup_items_updates: Bringup_Items_Updates;
  bringup_items_var_pop_fields: ResolverTypeWrapper<Bringup_Items_Var_Pop_Fields>;
  bringup_items_var_pop_order_by: Bringup_Items_Var_Pop_Order_By;
  bringup_items_var_samp_fields: ResolverTypeWrapper<Bringup_Items_Var_Samp_Fields>;
  bringup_items_var_samp_order_by: Bringup_Items_Var_Samp_Order_By;
  bringup_items_variance_fields: ResolverTypeWrapper<Bringup_Items_Variance_Fields>;
  bringup_items_variance_order_by: Bringup_Items_Variance_Order_By;
  bringup_milestones: ResolverTypeWrapper<Bringup_Milestones>;
  bringup_milestones_aggregate: ResolverTypeWrapper<Bringup_Milestones_Aggregate>;
  bringup_milestones_aggregate_fields: ResolverTypeWrapper<Bringup_Milestones_Aggregate_Fields>;
  bringup_milestones_bool_exp: Bringup_Milestones_Bool_Exp;
  bringup_milestones_constraint: Bringup_Milestones_Constraint;
  bringup_milestones_insert_input: Bringup_Milestones_Insert_Input;
  bringup_milestones_max_fields: ResolverTypeWrapper<Bringup_Milestones_Max_Fields>;
  bringup_milestones_min_fields: ResolverTypeWrapper<Bringup_Milestones_Min_Fields>;
  bringup_milestones_mutation_response: ResolverTypeWrapper<Bringup_Milestones_Mutation_Response>;
  bringup_milestones_obj_rel_insert_input: Bringup_Milestones_Obj_Rel_Insert_Input;
  bringup_milestones_on_conflict: Bringup_Milestones_On_Conflict;
  bringup_milestones_order_by: Bringup_Milestones_Order_By;
  bringup_milestones_pk_columns_input: Bringup_Milestones_Pk_Columns_Input;
  bringup_milestones_select_column: Bringup_Milestones_Select_Column;
  bringup_milestones_set_input: Bringup_Milestones_Set_Input;
  bringup_milestones_stream_cursor_input: Bringup_Milestones_Stream_Cursor_Input;
  bringup_milestones_stream_cursor_value_input: Bringup_Milestones_Stream_Cursor_Value_Input;
  bringup_milestones_update_column: Bringup_Milestones_Update_Column;
  bringup_milestones_updates: Bringup_Milestones_Updates;
  bringup_project_item_comments: ResolverTypeWrapper<Bringup_Project_Item_Comments>;
  bringup_project_item_comments_aggregate: ResolverTypeWrapper<Bringup_Project_Item_Comments_Aggregate>;
  bringup_project_item_comments_aggregate_fields: ResolverTypeWrapper<Bringup_Project_Item_Comments_Aggregate_Fields>;
  bringup_project_item_comments_aggregate_order_by: Bringup_Project_Item_Comments_Aggregate_Order_By;
  bringup_project_item_comments_arr_rel_insert_input: Bringup_Project_Item_Comments_Arr_Rel_Insert_Input;
  bringup_project_item_comments_bool_exp: Bringup_Project_Item_Comments_Bool_Exp;
  bringup_project_item_comments_constraint: Bringup_Project_Item_Comments_Constraint;
  bringup_project_item_comments_insert_input: Bringup_Project_Item_Comments_Insert_Input;
  bringup_project_item_comments_max_fields: ResolverTypeWrapper<Bringup_Project_Item_Comments_Max_Fields>;
  bringup_project_item_comments_max_order_by: Bringup_Project_Item_Comments_Max_Order_By;
  bringup_project_item_comments_min_fields: ResolverTypeWrapper<Bringup_Project_Item_Comments_Min_Fields>;
  bringup_project_item_comments_min_order_by: Bringup_Project_Item_Comments_Min_Order_By;
  bringup_project_item_comments_mutation_response: ResolverTypeWrapper<Bringup_Project_Item_Comments_Mutation_Response>;
  bringup_project_item_comments_on_conflict: Bringup_Project_Item_Comments_On_Conflict;
  bringup_project_item_comments_order_by: Bringup_Project_Item_Comments_Order_By;
  bringup_project_item_comments_pk_columns_input: Bringup_Project_Item_Comments_Pk_Columns_Input;
  bringup_project_item_comments_select_column: Bringup_Project_Item_Comments_Select_Column;
  bringup_project_item_comments_set_input: Bringup_Project_Item_Comments_Set_Input;
  bringup_project_item_comments_stream_cursor_input: Bringup_Project_Item_Comments_Stream_Cursor_Input;
  bringup_project_item_comments_stream_cursor_value_input: Bringup_Project_Item_Comments_Stream_Cursor_Value_Input;
  bringup_project_item_comments_update_column: Bringup_Project_Item_Comments_Update_Column;
  bringup_project_item_comments_updates: Bringup_Project_Item_Comments_Updates;
  categories: ResolverTypeWrapper<Categories>;
  categories_aggregate: ResolverTypeWrapper<Categories_Aggregate>;
  categories_aggregate_fields: ResolverTypeWrapper<Categories_Aggregate_Fields>;
  categories_avg_fields: ResolverTypeWrapper<Categories_Avg_Fields>;
  categories_bool_exp: Categories_Bool_Exp;
  categories_constraint: Categories_Constraint;
  categories_inc_input: Categories_Inc_Input;
  categories_insert_input: Categories_Insert_Input;
  categories_max_fields: ResolverTypeWrapper<Categories_Max_Fields>;
  categories_min_fields: ResolverTypeWrapper<Categories_Min_Fields>;
  categories_mutation_response: ResolverTypeWrapper<Categories_Mutation_Response>;
  categories_obj_rel_insert_input: Categories_Obj_Rel_Insert_Input;
  categories_on_conflict: Categories_On_Conflict;
  categories_order_by: Categories_Order_By;
  categories_pk_columns_input: Categories_Pk_Columns_Input;
  categories_select_column: Categories_Select_Column;
  categories_set_input: Categories_Set_Input;
  categories_stddev_fields: ResolverTypeWrapper<Categories_Stddev_Fields>;
  categories_stddev_pop_fields: ResolverTypeWrapper<Categories_Stddev_Pop_Fields>;
  categories_stddev_samp_fields: ResolverTypeWrapper<Categories_Stddev_Samp_Fields>;
  categories_stream_cursor_input: Categories_Stream_Cursor_Input;
  categories_stream_cursor_value_input: Categories_Stream_Cursor_Value_Input;
  categories_sum_fields: ResolverTypeWrapper<Categories_Sum_Fields>;
  categories_update_column: Categories_Update_Column;
  categories_updates: Categories_Updates;
  categories_var_pop_fields: ResolverTypeWrapper<Categories_Var_Pop_Fields>;
  categories_var_samp_fields: ResolverTypeWrapper<Categories_Var_Samp_Fields>;
  categories_variance_fields: ResolverTypeWrapper<Categories_Variance_Fields>;
  context_display_field: ResolverTypeWrapper<Context_Display_Field>;
  context_display_field_aggregate: ResolverTypeWrapper<Context_Display_Field_Aggregate>;
  context_display_field_aggregate_fields: ResolverTypeWrapper<Context_Display_Field_Aggregate_Fields>;
  context_display_field_aggregate_order_by: Context_Display_Field_Aggregate_Order_By;
  context_display_field_arr_rel_insert_input: Context_Display_Field_Arr_Rel_Insert_Input;
  context_display_field_avg_fields: ResolverTypeWrapper<Context_Display_Field_Avg_Fields>;
  context_display_field_avg_order_by: Context_Display_Field_Avg_Order_By;
  context_display_field_bool_exp: Context_Display_Field_Bool_Exp;
  context_display_field_constraint: Context_Display_Field_Constraint;
  context_display_field_inc_input: Context_Display_Field_Inc_Input;
  context_display_field_insert_input: Context_Display_Field_Insert_Input;
  context_display_field_max_fields: ResolverTypeWrapper<Context_Display_Field_Max_Fields>;
  context_display_field_max_order_by: Context_Display_Field_Max_Order_By;
  context_display_field_min_fields: ResolverTypeWrapper<Context_Display_Field_Min_Fields>;
  context_display_field_min_order_by: Context_Display_Field_Min_Order_By;
  context_display_field_mutation_response: ResolverTypeWrapper<Context_Display_Field_Mutation_Response>;
  context_display_field_on_conflict: Context_Display_Field_On_Conflict;
  context_display_field_order_by: Context_Display_Field_Order_By;
  context_display_field_pk_columns_input: Context_Display_Field_Pk_Columns_Input;
  context_display_field_select_column: Context_Display_Field_Select_Column;
  context_display_field_set_input: Context_Display_Field_Set_Input;
  context_display_field_stddev_fields: ResolverTypeWrapper<Context_Display_Field_Stddev_Fields>;
  context_display_field_stddev_order_by: Context_Display_Field_Stddev_Order_By;
  context_display_field_stddev_pop_fields: ResolverTypeWrapper<Context_Display_Field_Stddev_Pop_Fields>;
  context_display_field_stddev_pop_order_by: Context_Display_Field_Stddev_Pop_Order_By;
  context_display_field_stddev_samp_fields: ResolverTypeWrapper<Context_Display_Field_Stddev_Samp_Fields>;
  context_display_field_stddev_samp_order_by: Context_Display_Field_Stddev_Samp_Order_By;
  context_display_field_stream_cursor_input: Context_Display_Field_Stream_Cursor_Input;
  context_display_field_stream_cursor_value_input: Context_Display_Field_Stream_Cursor_Value_Input;
  context_display_field_sum_fields: ResolverTypeWrapper<Context_Display_Field_Sum_Fields>;
  context_display_field_sum_order_by: Context_Display_Field_Sum_Order_By;
  context_display_field_update_column: Context_Display_Field_Update_Column;
  context_display_field_updates: Context_Display_Field_Updates;
  context_display_field_var_pop_fields: ResolverTypeWrapper<Context_Display_Field_Var_Pop_Fields>;
  context_display_field_var_pop_order_by: Context_Display_Field_Var_Pop_Order_By;
  context_display_field_var_samp_fields: ResolverTypeWrapper<Context_Display_Field_Var_Samp_Fields>;
  context_display_field_var_samp_order_by: Context_Display_Field_Var_Samp_Order_By;
  context_display_field_variance_fields: ResolverTypeWrapper<Context_Display_Field_Variance_Fields>;
  context_display_field_variance_order_by: Context_Display_Field_Variance_Order_By;
  context_displays: ResolverTypeWrapper<Context_Displays>;
  context_displays_aggregate_order_by: Context_Displays_Aggregate_Order_By;
  context_displays_arr_rel_insert_input: Context_Displays_Arr_Rel_Insert_Input;
  context_displays_avg_order_by: Context_Displays_Avg_Order_By;
  context_displays_bool_exp: Context_Displays_Bool_Exp;
  context_displays_constraint: Context_Displays_Constraint;
  context_displays_inc_input: Context_Displays_Inc_Input;
  context_displays_insert_input: Context_Displays_Insert_Input;
  context_displays_max_order_by: Context_Displays_Max_Order_By;
  context_displays_min_order_by: Context_Displays_Min_Order_By;
  context_displays_mutation_response: ResolverTypeWrapper<Context_Displays_Mutation_Response>;
  context_displays_obj_rel_insert_input: Context_Displays_Obj_Rel_Insert_Input;
  context_displays_on_conflict: Context_Displays_On_Conflict;
  context_displays_order_by: Context_Displays_Order_By;
  context_displays_pk_columns_input: Context_Displays_Pk_Columns_Input;
  context_displays_select_column: Context_Displays_Select_Column;
  context_displays_set_input: Context_Displays_Set_Input;
  context_displays_stddev_order_by: Context_Displays_Stddev_Order_By;
  context_displays_stddev_pop_order_by: Context_Displays_Stddev_Pop_Order_By;
  context_displays_stddev_samp_order_by: Context_Displays_Stddev_Samp_Order_By;
  context_displays_stream_cursor_input: Context_Displays_Stream_Cursor_Input;
  context_displays_stream_cursor_value_input: Context_Displays_Stream_Cursor_Value_Input;
  context_displays_sum_order_by: Context_Displays_Sum_Order_By;
  context_displays_update_column: Context_Displays_Update_Column;
  context_displays_updates: Context_Displays_Updates;
  context_displays_var_pop_order_by: Context_Displays_Var_Pop_Order_By;
  context_displays_var_samp_order_by: Context_Displays_Var_Samp_Order_By;
  context_displays_variance_order_by: Context_Displays_Variance_Order_By;
  contexts: ResolverTypeWrapper<Contexts>;
  contexts_aggregate: ResolverTypeWrapper<Contexts_Aggregate>;
  contexts_aggregate_fields: ResolverTypeWrapper<Contexts_Aggregate_Fields>;
  contexts_aggregate_order_by: Contexts_Aggregate_Order_By;
  contexts_arr_rel_insert_input: Contexts_Arr_Rel_Insert_Input;
  contexts_bool_exp: Contexts_Bool_Exp;
  contexts_constraint: Contexts_Constraint;
  contexts_insert_input: Contexts_Insert_Input;
  contexts_max_fields: ResolverTypeWrapper<Contexts_Max_Fields>;
  contexts_max_order_by: Contexts_Max_Order_By;
  contexts_min_fields: ResolverTypeWrapper<Contexts_Min_Fields>;
  contexts_min_order_by: Contexts_Min_Order_By;
  contexts_mutation_response: ResolverTypeWrapper<Contexts_Mutation_Response>;
  contexts_obj_rel_insert_input: Contexts_Obj_Rel_Insert_Input;
  contexts_on_conflict: Contexts_On_Conflict;
  contexts_order_by: Contexts_Order_By;
  contexts_pk_columns_input: Contexts_Pk_Columns_Input;
  contexts_select_column: Contexts_Select_Column;
  contexts_set_input: Contexts_Set_Input;
  contexts_stream_cursor_input: Contexts_Stream_Cursor_Input;
  contexts_stream_cursor_value_input: Contexts_Stream_Cursor_Value_Input;
  contexts_update_column: Contexts_Update_Column;
  contexts_updates: Contexts_Updates;
  cursor_ordering: Cursor_Ordering;
  customers: ResolverTypeWrapper<Customers>;
  customers_aggregate: ResolverTypeWrapper<Customers_Aggregate>;
  customers_aggregate_fields: ResolverTypeWrapper<Customers_Aggregate_Fields>;
  customers_bool_exp: Customers_Bool_Exp;
  customers_constraint: Customers_Constraint;
  customers_insert_input: Customers_Insert_Input;
  customers_max_fields: ResolverTypeWrapper<Customers_Max_Fields>;
  customers_min_fields: ResolverTypeWrapper<Customers_Min_Fields>;
  customers_mutation_response: ResolverTypeWrapper<Customers_Mutation_Response>;
  customers_obj_rel_insert_input: Customers_Obj_Rel_Insert_Input;
  customers_on_conflict: Customers_On_Conflict;
  customers_order_by: Customers_Order_By;
  customers_pk_columns_input: Customers_Pk_Columns_Input;
  customers_select_column: Customers_Select_Column;
  customers_set_input: Customers_Set_Input;
  customers_stream_cursor_input: Customers_Stream_Cursor_Input;
  customers_stream_cursor_value_input: Customers_Stream_Cursor_Value_Input;
  customers_update_column: Customers_Update_Column;
  customers_updates: Customers_Updates;
  date: ResolverTypeWrapper<Scalars['date']>;
  date_comparison_exp: Date_Comparison_Exp;
  deliverables: ResolverTypeWrapper<Deliverables>;
  deliverables_aggregate: ResolverTypeWrapper<Deliverables_Aggregate>;
  deliverables_aggregate_fields: ResolverTypeWrapper<Deliverables_Aggregate_Fields>;
  deliverables_avg_fields: ResolverTypeWrapper<Deliverables_Avg_Fields>;
  deliverables_bool_exp: Deliverables_Bool_Exp;
  deliverables_constraint: Deliverables_Constraint;
  deliverables_inc_input: Deliverables_Inc_Input;
  deliverables_insert_input: Deliverables_Insert_Input;
  deliverables_max_fields: ResolverTypeWrapper<Deliverables_Max_Fields>;
  deliverables_min_fields: ResolverTypeWrapper<Deliverables_Min_Fields>;
  deliverables_mutation_response: ResolverTypeWrapper<Deliverables_Mutation_Response>;
  deliverables_obj_rel_insert_input: Deliverables_Obj_Rel_Insert_Input;
  deliverables_on_conflict: Deliverables_On_Conflict;
  deliverables_order_by: Deliverables_Order_By;
  deliverables_pk_columns_input: Deliverables_Pk_Columns_Input;
  deliverables_select_column: Deliverables_Select_Column;
  deliverables_set_input: Deliverables_Set_Input;
  deliverables_stddev_fields: ResolverTypeWrapper<Deliverables_Stddev_Fields>;
  deliverables_stddev_pop_fields: ResolverTypeWrapper<Deliverables_Stddev_Pop_Fields>;
  deliverables_stddev_samp_fields: ResolverTypeWrapper<Deliverables_Stddev_Samp_Fields>;
  deliverables_stream_cursor_input: Deliverables_Stream_Cursor_Input;
  deliverables_stream_cursor_value_input: Deliverables_Stream_Cursor_Value_Input;
  deliverables_sum_fields: ResolverTypeWrapper<Deliverables_Sum_Fields>;
  deliverables_update_column: Deliverables_Update_Column;
  deliverables_updates: Deliverables_Updates;
  deliverables_var_pop_fields: ResolverTypeWrapper<Deliverables_Var_Pop_Fields>;
  deliverables_var_samp_fields: ResolverTypeWrapper<Deliverables_Var_Samp_Fields>;
  deliverables_variance_fields: ResolverTypeWrapper<Deliverables_Variance_Fields>;
  deliveries_architectures: ResolverTypeWrapper<Deliveries_Architectures>;
  deliveries_architectures_bool_exp: Deliveries_Architectures_Bool_Exp;
  deliveries_architectures_constraint: Deliveries_Architectures_Constraint;
  deliveries_architectures_insert_input: Deliveries_Architectures_Insert_Input;
  deliveries_architectures_mutation_response: ResolverTypeWrapper<Deliveries_Architectures_Mutation_Response>;
  deliveries_architectures_obj_rel_insert_input: Deliveries_Architectures_Obj_Rel_Insert_Input;
  deliveries_architectures_on_conflict: Deliveries_Architectures_On_Conflict;
  deliveries_architectures_order_by: Deliveries_Architectures_Order_By;
  deliveries_architectures_pk_columns_input: Deliveries_Architectures_Pk_Columns_Input;
  deliveries_architectures_select_column: Deliveries_Architectures_Select_Column;
  deliveries_architectures_set_input: Deliveries_Architectures_Set_Input;
  deliveries_architectures_stream_cursor_input: Deliveries_Architectures_Stream_Cursor_Input;
  deliveries_architectures_stream_cursor_value_input: Deliveries_Architectures_Stream_Cursor_Value_Input;
  deliveries_architectures_update_column: Deliveries_Architectures_Update_Column;
  deliveries_architectures_updates: Deliveries_Architectures_Updates;
  deliveries_artifact_microsoft_whql_submission: ResolverTypeWrapper<Deliveries_Artifact_Microsoft_Whql_Submission>;
  deliveries_artifact_microsoft_whql_submission_aggregate: ResolverTypeWrapper<Deliveries_Artifact_Microsoft_Whql_Submission_Aggregate>;
  deliveries_artifact_microsoft_whql_submission_aggregate_fields: ResolverTypeWrapper<Deliveries_Artifact_Microsoft_Whql_Submission_Aggregate_Fields>;
  deliveries_artifact_microsoft_whql_submission_aggregate_order_by: Deliveries_Artifact_Microsoft_Whql_Submission_Aggregate_Order_By;
  deliveries_artifact_microsoft_whql_submission_arr_rel_insert_input: Deliveries_Artifact_Microsoft_Whql_Submission_Arr_Rel_Insert_Input;
  deliveries_artifact_microsoft_whql_submission_bool_exp: Deliveries_Artifact_Microsoft_Whql_Submission_Bool_Exp;
  deliveries_artifact_microsoft_whql_submission_constraint: Deliveries_Artifact_Microsoft_Whql_Submission_Constraint;
  deliveries_artifact_microsoft_whql_submission_insert_input: Deliveries_Artifact_Microsoft_Whql_Submission_Insert_Input;
  deliveries_artifact_microsoft_whql_submission_max_fields: ResolverTypeWrapper<Deliveries_Artifact_Microsoft_Whql_Submission_Max_Fields>;
  deliveries_artifact_microsoft_whql_submission_max_order_by: Deliveries_Artifact_Microsoft_Whql_Submission_Max_Order_By;
  deliveries_artifact_microsoft_whql_submission_min_fields: ResolverTypeWrapper<Deliveries_Artifact_Microsoft_Whql_Submission_Min_Fields>;
  deliveries_artifact_microsoft_whql_submission_min_order_by: Deliveries_Artifact_Microsoft_Whql_Submission_Min_Order_By;
  deliveries_artifact_microsoft_whql_submission_mutation_response: ResolverTypeWrapper<Deliveries_Artifact_Microsoft_Whql_Submission_Mutation_Response>;
  deliveries_artifact_microsoft_whql_submission_on_conflict: Deliveries_Artifact_Microsoft_Whql_Submission_On_Conflict;
  deliveries_artifact_microsoft_whql_submission_order_by: Deliveries_Artifact_Microsoft_Whql_Submission_Order_By;
  deliveries_artifact_microsoft_whql_submission_pk_columns_input: Deliveries_Artifact_Microsoft_Whql_Submission_Pk_Columns_Input;
  deliveries_artifact_microsoft_whql_submission_select_column: Deliveries_Artifact_Microsoft_Whql_Submission_Select_Column;
  deliveries_artifact_microsoft_whql_submission_set_input: Deliveries_Artifact_Microsoft_Whql_Submission_Set_Input;
  deliveries_artifact_microsoft_whql_submission_stream_cursor_input: Deliveries_Artifact_Microsoft_Whql_Submission_Stream_Cursor_Input;
  deliveries_artifact_microsoft_whql_submission_stream_cursor_value_input: Deliveries_Artifact_Microsoft_Whql_Submission_Stream_Cursor_Value_Input;
  deliveries_artifact_microsoft_whql_submission_update_column: Deliveries_Artifact_Microsoft_Whql_Submission_Update_Column;
  deliveries_artifact_microsoft_whql_submission_updates: Deliveries_Artifact_Microsoft_Whql_Submission_Updates;
  deliveries_artifact_operating_system: ResolverTypeWrapper<Deliveries_Artifact_Operating_System>;
  deliveries_artifact_operating_system_aggregate_order_by: Deliveries_Artifact_Operating_System_Aggregate_Order_By;
  deliveries_artifact_operating_system_arr_rel_insert_input: Deliveries_Artifact_Operating_System_Arr_Rel_Insert_Input;
  deliveries_artifact_operating_system_bool_exp: Deliveries_Artifact_Operating_System_Bool_Exp;
  deliveries_artifact_operating_system_constraint: Deliveries_Artifact_Operating_System_Constraint;
  deliveries_artifact_operating_system_insert_input: Deliveries_Artifact_Operating_System_Insert_Input;
  deliveries_artifact_operating_system_max_order_by: Deliveries_Artifact_Operating_System_Max_Order_By;
  deliveries_artifact_operating_system_min_order_by: Deliveries_Artifact_Operating_System_Min_Order_By;
  deliveries_artifact_operating_system_mutation_response: ResolverTypeWrapper<Deliveries_Artifact_Operating_System_Mutation_Response>;
  deliveries_artifact_operating_system_on_conflict: Deliveries_Artifact_Operating_System_On_Conflict;
  deliveries_artifact_operating_system_order_by: Deliveries_Artifact_Operating_System_Order_By;
  deliveries_artifact_operating_system_pk_columns_input: Deliveries_Artifact_Operating_System_Pk_Columns_Input;
  deliveries_artifact_operating_system_select_column: Deliveries_Artifact_Operating_System_Select_Column;
  deliveries_artifact_operating_system_set_input: Deliveries_Artifact_Operating_System_Set_Input;
  deliveries_artifact_operating_system_stream_cursor_input: Deliveries_Artifact_Operating_System_Stream_Cursor_Input;
  deliveries_artifact_operating_system_stream_cursor_value_input: Deliveries_Artifact_Operating_System_Stream_Cursor_Value_Input;
  deliveries_artifact_operating_system_update_column: Deliveries_Artifact_Operating_System_Update_Column;
  deliveries_artifact_operating_system_updates: Deliveries_Artifact_Operating_System_Updates;
  deliveries_artifacts: ResolverTypeWrapper<Deliveries_Artifacts>;
  deliveries_artifacts_aggregate: ResolverTypeWrapper<Deliveries_Artifacts_Aggregate>;
  deliveries_artifacts_aggregate_fields: ResolverTypeWrapper<Deliveries_Artifacts_Aggregate_Fields>;
  deliveries_artifacts_aggregate_order_by: Deliveries_Artifacts_Aggregate_Order_By;
  deliveries_artifacts_arr_rel_insert_input: Deliveries_Artifacts_Arr_Rel_Insert_Input;
  deliveries_artifacts_bool_exp: Deliveries_Artifacts_Bool_Exp;
  deliveries_artifacts_constraint: Deliveries_Artifacts_Constraint;
  deliveries_artifacts_insert_input: Deliveries_Artifacts_Insert_Input;
  deliveries_artifacts_max_fields: ResolverTypeWrapper<Deliveries_Artifacts_Max_Fields>;
  deliveries_artifacts_max_order_by: Deliveries_Artifacts_Max_Order_By;
  deliveries_artifacts_min_fields: ResolverTypeWrapper<Deliveries_Artifacts_Min_Fields>;
  deliveries_artifacts_min_order_by: Deliveries_Artifacts_Min_Order_By;
  deliveries_artifacts_mutation_response: ResolverTypeWrapper<Deliveries_Artifacts_Mutation_Response>;
  deliveries_artifacts_obj_rel_insert_input: Deliveries_Artifacts_Obj_Rel_Insert_Input;
  deliveries_artifacts_on_conflict: Deliveries_Artifacts_On_Conflict;
  deliveries_artifacts_order_by: Deliveries_Artifacts_Order_By;
  deliveries_artifacts_pk_columns_input: Deliveries_Artifacts_Pk_Columns_Input;
  deliveries_artifacts_select_column: Deliveries_Artifacts_Select_Column;
  deliveries_artifacts_set_input: Deliveries_Artifacts_Set_Input;
  deliveries_artifacts_stream_cursor_input: Deliveries_Artifacts_Stream_Cursor_Input;
  deliveries_artifacts_stream_cursor_value_input: Deliveries_Artifacts_Stream_Cursor_Value_Input;
  deliveries_artifacts_update_column: Deliveries_Artifacts_Update_Column;
  deliveries_artifacts_updates: Deliveries_Artifacts_Updates;
  deliveries_config_id_types_enum: Deliveries_Config_Id_Types_Enum;
  deliveries_config_id_types_enum_comparison_exp: Deliveries_Config_Id_Types_Enum_Comparison_Exp;
  deliveries_device_families: ResolverTypeWrapper<Deliveries_Device_Families>;
  deliveries_device_families_bool_exp: Deliveries_Device_Families_Bool_Exp;
  deliveries_device_families_constraint: Deliveries_Device_Families_Constraint;
  deliveries_device_families_insert_input: Deliveries_Device_Families_Insert_Input;
  deliveries_device_families_mutation_response: ResolverTypeWrapper<Deliveries_Device_Families_Mutation_Response>;
  deliveries_device_families_obj_rel_insert_input: Deliveries_Device_Families_Obj_Rel_Insert_Input;
  deliveries_device_families_on_conflict: Deliveries_Device_Families_On_Conflict;
  deliveries_device_families_order_by: Deliveries_Device_Families_Order_By;
  deliveries_device_families_pk_columns_input: Deliveries_Device_Families_Pk_Columns_Input;
  deliveries_device_families_select_column: Deliveries_Device_Families_Select_Column;
  deliveries_device_families_set_input: Deliveries_Device_Families_Set_Input;
  deliveries_device_families_stream_cursor_input: Deliveries_Device_Families_Stream_Cursor_Input;
  deliveries_device_families_stream_cursor_value_input: Deliveries_Device_Families_Stream_Cursor_Value_Input;
  deliveries_device_families_update_column: Deliveries_Device_Families_Update_Column;
  deliveries_device_families_updates: Deliveries_Device_Families_Updates;
  deliveries_driver_package: ResolverTypeWrapper<Deliveries_Driver_Package>;
  deliveries_driver_package_aggregate: ResolverTypeWrapper<Deliveries_Driver_Package_Aggregate>;
  deliveries_driver_package_aggregate_fields: ResolverTypeWrapper<Deliveries_Driver_Package_Aggregate_Fields>;
  deliveries_driver_package_aggregate_order_by: Deliveries_Driver_Package_Aggregate_Order_By;
  deliveries_driver_package_arr_rel_insert_input: Deliveries_Driver_Package_Arr_Rel_Insert_Input;
  deliveries_driver_package_bool_exp: Deliveries_Driver_Package_Bool_Exp;
  deliveries_driver_package_constraint: Deliveries_Driver_Package_Constraint;
  deliveries_driver_package_insert_input: Deliveries_Driver_Package_Insert_Input;
  deliveries_driver_package_max_fields: ResolverTypeWrapper<Deliveries_Driver_Package_Max_Fields>;
  deliveries_driver_package_max_order_by: Deliveries_Driver_Package_Max_Order_By;
  deliveries_driver_package_min_fields: ResolverTypeWrapper<Deliveries_Driver_Package_Min_Fields>;
  deliveries_driver_package_min_order_by: Deliveries_Driver_Package_Min_Order_By;
  deliveries_driver_package_mutation_response: ResolverTypeWrapper<Deliveries_Driver_Package_Mutation_Response>;
  deliveries_driver_package_on_conflict: Deliveries_Driver_Package_On_Conflict;
  deliveries_driver_package_order_by: Deliveries_Driver_Package_Order_By;
  deliveries_driver_package_pk_columns_input: Deliveries_Driver_Package_Pk_Columns_Input;
  deliveries_driver_package_select_column: Deliveries_Driver_Package_Select_Column;
  deliveries_driver_package_set_input: Deliveries_Driver_Package_Set_Input;
  deliveries_driver_package_stream_cursor_input: Deliveries_Driver_Package_Stream_Cursor_Input;
  deliveries_driver_package_stream_cursor_value_input: Deliveries_Driver_Package_Stream_Cursor_Value_Input;
  deliveries_driver_package_update_column: Deliveries_Driver_Package_Update_Column;
  deliveries_driver_package_updates: Deliveries_Driver_Package_Updates;
  deliveries_driver_project: ResolverTypeWrapper<Deliveries_Driver_Project>;
  deliveries_driver_project_bool_exp: Deliveries_Driver_Project_Bool_Exp;
  deliveries_driver_project_constraint: Deliveries_Driver_Project_Constraint;
  deliveries_driver_project_insert_input: Deliveries_Driver_Project_Insert_Input;
  deliveries_driver_project_mutation_response: ResolverTypeWrapper<Deliveries_Driver_Project_Mutation_Response>;
  deliveries_driver_project_obj_rel_insert_input: Deliveries_Driver_Project_Obj_Rel_Insert_Input;
  deliveries_driver_project_on_conflict: Deliveries_Driver_Project_On_Conflict;
  deliveries_driver_project_order_by: Deliveries_Driver_Project_Order_By;
  deliveries_driver_project_pk_columns_input: Deliveries_Driver_Project_Pk_Columns_Input;
  deliveries_driver_project_set_input: Deliveries_Driver_Project_Set_Input;
  deliveries_driver_project_update_column: Deliveries_Driver_Project_Update_Column;
  deliveries_driver_project_updates: Deliveries_Driver_Project_Updates;
  deliveries_driver_version_engine: ResolverTypeWrapper<Deliveries_Driver_Version_Engine>;
  deliveries_driver_version_engine_aggregate: ResolverTypeWrapper<Deliveries_Driver_Version_Engine_Aggregate>;
  deliveries_driver_version_engine_aggregate_fields: ResolverTypeWrapper<Deliveries_Driver_Version_Engine_Aggregate_Fields>;
  deliveries_driver_version_engine_aggregate_order_by: Deliveries_Driver_Version_Engine_Aggregate_Order_By;
  deliveries_driver_version_engine_arr_rel_insert_input: Deliveries_Driver_Version_Engine_Arr_Rel_Insert_Input;
  deliveries_driver_version_engine_bool_exp: Deliveries_Driver_Version_Engine_Bool_Exp;
  deliveries_driver_version_engine_constraint: Deliveries_Driver_Version_Engine_Constraint;
  deliveries_driver_version_engine_insert_input: Deliveries_Driver_Version_Engine_Insert_Input;
  deliveries_driver_version_engine_max_fields: ResolverTypeWrapper<Deliveries_Driver_Version_Engine_Max_Fields>;
  deliveries_driver_version_engine_max_order_by: Deliveries_Driver_Version_Engine_Max_Order_By;
  deliveries_driver_version_engine_min_fields: ResolverTypeWrapper<Deliveries_Driver_Version_Engine_Min_Fields>;
  deliveries_driver_version_engine_min_order_by: Deliveries_Driver_Version_Engine_Min_Order_By;
  deliveries_driver_version_engine_mutation_response: ResolverTypeWrapper<Deliveries_Driver_Version_Engine_Mutation_Response>;
  deliveries_driver_version_engine_obj_rel_insert_input: Deliveries_Driver_Version_Engine_Obj_Rel_Insert_Input;
  deliveries_driver_version_engine_on_conflict: Deliveries_Driver_Version_Engine_On_Conflict;
  deliveries_driver_version_engine_order_by: Deliveries_Driver_Version_Engine_Order_By;
  deliveries_driver_version_engine_pk_columns_input: Deliveries_Driver_Version_Engine_Pk_Columns_Input;
  deliveries_driver_version_engine_select_column: Deliveries_Driver_Version_Engine_Select_Column;
  deliveries_driver_version_engine_set_input: Deliveries_Driver_Version_Engine_Set_Input;
  deliveries_driver_version_engine_update_column: Deliveries_Driver_Version_Engine_Update_Column;
  deliveries_driver_version_engine_updates: Deliveries_Driver_Version_Engine_Updates;
  deliveries_driver_version_package: ResolverTypeWrapper<Deliveries_Driver_Version_Package>;
  deliveries_driver_version_package_aggregate: ResolverTypeWrapper<Deliveries_Driver_Version_Package_Aggregate>;
  deliveries_driver_version_package_aggregate_fields: ResolverTypeWrapper<Deliveries_Driver_Version_Package_Aggregate_Fields>;
  deliveries_driver_version_package_aggregate_order_by: Deliveries_Driver_Version_Package_Aggregate_Order_By;
  deliveries_driver_version_package_arr_rel_insert_input: Deliveries_Driver_Version_Package_Arr_Rel_Insert_Input;
  deliveries_driver_version_package_bool_exp: Deliveries_Driver_Version_Package_Bool_Exp;
  deliveries_driver_version_package_constraint: Deliveries_Driver_Version_Package_Constraint;
  deliveries_driver_version_package_insert_input: Deliveries_Driver_Version_Package_Insert_Input;
  deliveries_driver_version_package_max_fields: ResolverTypeWrapper<Deliveries_Driver_Version_Package_Max_Fields>;
  deliveries_driver_version_package_max_order_by: Deliveries_Driver_Version_Package_Max_Order_By;
  deliveries_driver_version_package_min_fields: ResolverTypeWrapper<Deliveries_Driver_Version_Package_Min_Fields>;
  deliveries_driver_version_package_min_order_by: Deliveries_Driver_Version_Package_Min_Order_By;
  deliveries_driver_version_package_mutation_response: ResolverTypeWrapper<Deliveries_Driver_Version_Package_Mutation_Response>;
  deliveries_driver_version_package_on_conflict: Deliveries_Driver_Version_Package_On_Conflict;
  deliveries_driver_version_package_order_by: Deliveries_Driver_Version_Package_Order_By;
  deliveries_driver_version_package_pk_columns_input: Deliveries_Driver_Version_Package_Pk_Columns_Input;
  deliveries_driver_version_package_select_column: Deliveries_Driver_Version_Package_Select_Column;
  deliveries_driver_version_package_set_input: Deliveries_Driver_Version_Package_Set_Input;
  deliveries_driver_version_package_stream_cursor_input: Deliveries_Driver_Version_Package_Stream_Cursor_Input;
  deliveries_driver_version_package_stream_cursor_value_input: Deliveries_Driver_Version_Package_Stream_Cursor_Value_Input;
  deliveries_driver_version_package_update_column: Deliveries_Driver_Version_Package_Update_Column;
  deliveries_driver_version_package_updates: Deliveries_Driver_Version_Package_Updates;
  deliveries_driver_versions: ResolverTypeWrapper<Deliveries_Driver_Versions>;
  deliveries_driver_versions_aggregate: ResolverTypeWrapper<Deliveries_Driver_Versions_Aggregate>;
  deliveries_driver_versions_aggregate_fields: ResolverTypeWrapper<Deliveries_Driver_Versions_Aggregate_Fields>;
  deliveries_driver_versions_aggregate_order_by: Deliveries_Driver_Versions_Aggregate_Order_By;
  deliveries_driver_versions_arr_rel_insert_input: Deliveries_Driver_Versions_Arr_Rel_Insert_Input;
  deliveries_driver_versions_bool_exp: Deliveries_Driver_Versions_Bool_Exp;
  deliveries_driver_versions_constraint: Deliveries_Driver_Versions_Constraint;
  deliveries_driver_versions_insert_input: Deliveries_Driver_Versions_Insert_Input;
  deliveries_driver_versions_max_fields: ResolverTypeWrapper<Deliveries_Driver_Versions_Max_Fields>;
  deliveries_driver_versions_max_order_by: Deliveries_Driver_Versions_Max_Order_By;
  deliveries_driver_versions_min_fields: ResolverTypeWrapper<Deliveries_Driver_Versions_Min_Fields>;
  deliveries_driver_versions_min_order_by: Deliveries_Driver_Versions_Min_Order_By;
  deliveries_driver_versions_mutation_response: ResolverTypeWrapper<Deliveries_Driver_Versions_Mutation_Response>;
  deliveries_driver_versions_obj_rel_insert_input: Deliveries_Driver_Versions_Obj_Rel_Insert_Input;
  deliveries_driver_versions_on_conflict: Deliveries_Driver_Versions_On_Conflict;
  deliveries_driver_versions_order_by: Deliveries_Driver_Versions_Order_By;
  deliveries_driver_versions_pk_columns_input: Deliveries_Driver_Versions_Pk_Columns_Input;
  deliveries_driver_versions_select_column: Deliveries_Driver_Versions_Select_Column;
  deliveries_driver_versions_set_input: Deliveries_Driver_Versions_Set_Input;
  deliveries_driver_versions_stream_cursor_input: Deliveries_Driver_Versions_Stream_Cursor_Input;
  deliveries_driver_versions_stream_cursor_value_input: Deliveries_Driver_Versions_Stream_Cursor_Value_Input;
  deliveries_driver_versions_update_column: Deliveries_Driver_Versions_Update_Column;
  deliveries_driver_versions_updates: Deliveries_Driver_Versions_Updates;
  deliveries_drivers: ResolverTypeWrapper<Deliveries_Drivers>;
  deliveries_drivers_aggregate: ResolverTypeWrapper<Deliveries_Drivers_Aggregate>;
  deliveries_drivers_aggregate_fields: ResolverTypeWrapper<Deliveries_Drivers_Aggregate_Fields>;
  deliveries_drivers_bool_exp: Deliveries_Drivers_Bool_Exp;
  deliveries_drivers_constraint: Deliveries_Drivers_Constraint;
  deliveries_drivers_insert_input: Deliveries_Drivers_Insert_Input;
  deliveries_drivers_max_fields: ResolverTypeWrapper<Deliveries_Drivers_Max_Fields>;
  deliveries_drivers_min_fields: ResolverTypeWrapper<Deliveries_Drivers_Min_Fields>;
  deliveries_drivers_mutation_response: ResolverTypeWrapper<Deliveries_Drivers_Mutation_Response>;
  deliveries_drivers_obj_rel_insert_input: Deliveries_Drivers_Obj_Rel_Insert_Input;
  deliveries_drivers_on_conflict: Deliveries_Drivers_On_Conflict;
  deliveries_drivers_order_by: Deliveries_Drivers_Order_By;
  deliveries_drivers_pk_columns_input: Deliveries_Drivers_Pk_Columns_Input;
  deliveries_drivers_select_column: Deliveries_Drivers_Select_Column;
  deliveries_drivers_set_input: Deliveries_Drivers_Set_Input;
  deliveries_drivers_stream_cursor_input: Deliveries_Drivers_Stream_Cursor_Input;
  deliveries_drivers_stream_cursor_value_input: Deliveries_Drivers_Stream_Cursor_Value_Input;
  deliveries_drivers_update_column: Deliveries_Drivers_Update_Column;
  deliveries_drivers_updates: Deliveries_Drivers_Updates;
  deliveries_microsoft_hlk_artifacts: ResolverTypeWrapper<Deliveries_Microsoft_Hlk_Artifacts>;
  deliveries_microsoft_hlk_artifacts_aggregate: ResolverTypeWrapper<Deliveries_Microsoft_Hlk_Artifacts_Aggregate>;
  deliveries_microsoft_hlk_artifacts_aggregate_fields: ResolverTypeWrapper<Deliveries_Microsoft_Hlk_Artifacts_Aggregate_Fields>;
  deliveries_microsoft_hlk_artifacts_aggregate_order_by: Deliveries_Microsoft_Hlk_Artifacts_Aggregate_Order_By;
  deliveries_microsoft_hlk_artifacts_arr_rel_insert_input: Deliveries_Microsoft_Hlk_Artifacts_Arr_Rel_Insert_Input;
  deliveries_microsoft_hlk_artifacts_bool_exp: Deliveries_Microsoft_Hlk_Artifacts_Bool_Exp;
  deliveries_microsoft_hlk_artifacts_constraint: Deliveries_Microsoft_Hlk_Artifacts_Constraint;
  deliveries_microsoft_hlk_artifacts_insert_input: Deliveries_Microsoft_Hlk_Artifacts_Insert_Input;
  deliveries_microsoft_hlk_artifacts_max_fields: ResolverTypeWrapper<Deliveries_Microsoft_Hlk_Artifacts_Max_Fields>;
  deliveries_microsoft_hlk_artifacts_max_order_by: Deliveries_Microsoft_Hlk_Artifacts_Max_Order_By;
  deliveries_microsoft_hlk_artifacts_min_fields: ResolverTypeWrapper<Deliveries_Microsoft_Hlk_Artifacts_Min_Fields>;
  deliveries_microsoft_hlk_artifacts_min_order_by: Deliveries_Microsoft_Hlk_Artifacts_Min_Order_By;
  deliveries_microsoft_hlk_artifacts_mutation_response: ResolverTypeWrapper<Deliveries_Microsoft_Hlk_Artifacts_Mutation_Response>;
  deliveries_microsoft_hlk_artifacts_on_conflict: Deliveries_Microsoft_Hlk_Artifacts_On_Conflict;
  deliveries_microsoft_hlk_artifacts_order_by: Deliveries_Microsoft_Hlk_Artifacts_Order_By;
  deliveries_microsoft_hlk_artifacts_pk_columns_input: Deliveries_Microsoft_Hlk_Artifacts_Pk_Columns_Input;
  deliveries_microsoft_hlk_artifacts_select_column: Deliveries_Microsoft_Hlk_Artifacts_Select_Column;
  deliveries_microsoft_hlk_artifacts_set_input: Deliveries_Microsoft_Hlk_Artifacts_Set_Input;
  deliveries_microsoft_hlk_artifacts_stream_cursor_input: Deliveries_Microsoft_Hlk_Artifacts_Stream_Cursor_Input;
  deliveries_microsoft_hlk_artifacts_stream_cursor_value_input: Deliveries_Microsoft_Hlk_Artifacts_Stream_Cursor_Value_Input;
  deliveries_microsoft_hlk_artifacts_update_column: Deliveries_Microsoft_Hlk_Artifacts_Update_Column;
  deliveries_microsoft_hlk_artifacts_updates: Deliveries_Microsoft_Hlk_Artifacts_Updates;
  deliveries_microsoft_shipping_label_reviews: ResolverTypeWrapper<Deliveries_Microsoft_Shipping_Label_Reviews>;
  deliveries_microsoft_shipping_label_reviews_bool_exp: Deliveries_Microsoft_Shipping_Label_Reviews_Bool_Exp;
  deliveries_microsoft_shipping_label_reviews_constraint: Deliveries_Microsoft_Shipping_Label_Reviews_Constraint;
  deliveries_microsoft_shipping_label_reviews_insert_input: Deliveries_Microsoft_Shipping_Label_Reviews_Insert_Input;
  deliveries_microsoft_shipping_label_reviews_mutation_response: ResolverTypeWrapper<Deliveries_Microsoft_Shipping_Label_Reviews_Mutation_Response>;
  deliveries_microsoft_shipping_label_reviews_obj_rel_insert_input: Deliveries_Microsoft_Shipping_Label_Reviews_Obj_Rel_Insert_Input;
  deliveries_microsoft_shipping_label_reviews_on_conflict: Deliveries_Microsoft_Shipping_Label_Reviews_On_Conflict;
  deliveries_microsoft_shipping_label_reviews_order_by: Deliveries_Microsoft_Shipping_Label_Reviews_Order_By;
  deliveries_microsoft_shipping_label_reviews_pk_columns_input: Deliveries_Microsoft_Shipping_Label_Reviews_Pk_Columns_Input;
  deliveries_microsoft_shipping_label_reviews_select_column: Deliveries_Microsoft_Shipping_Label_Reviews_Select_Column;
  deliveries_microsoft_shipping_label_reviews_set_input: Deliveries_Microsoft_Shipping_Label_Reviews_Set_Input;
  deliveries_microsoft_shipping_label_reviews_stream_cursor_input: Deliveries_Microsoft_Shipping_Label_Reviews_Stream_Cursor_Input;
  deliveries_microsoft_shipping_label_reviews_stream_cursor_value_input: Deliveries_Microsoft_Shipping_Label_Reviews_Stream_Cursor_Value_Input;
  deliveries_microsoft_shipping_label_reviews_update_column: Deliveries_Microsoft_Shipping_Label_Reviews_Update_Column;
  deliveries_microsoft_shipping_label_reviews_updates: Deliveries_Microsoft_Shipping_Label_Reviews_Updates;
  deliveries_microsoft_shipping_labels: ResolverTypeWrapper<Deliveries_Microsoft_Shipping_Labels>;
  deliveries_microsoft_shipping_labels_aggregate: ResolverTypeWrapper<Deliveries_Microsoft_Shipping_Labels_Aggregate>;
  deliveries_microsoft_shipping_labels_aggregate_fields: ResolverTypeWrapper<Deliveries_Microsoft_Shipping_Labels_Aggregate_Fields>;
  deliveries_microsoft_shipping_labels_aggregate_order_by: Deliveries_Microsoft_Shipping_Labels_Aggregate_Order_By;
  deliveries_microsoft_shipping_labels_append_input: Deliveries_Microsoft_Shipping_Labels_Append_Input;
  deliveries_microsoft_shipping_labels_arr_rel_insert_input: Deliveries_Microsoft_Shipping_Labels_Arr_Rel_Insert_Input;
  deliveries_microsoft_shipping_labels_bool_exp: Deliveries_Microsoft_Shipping_Labels_Bool_Exp;
  deliveries_microsoft_shipping_labels_constraint: Deliveries_Microsoft_Shipping_Labels_Constraint;
  deliveries_microsoft_shipping_labels_delete_at_path_input: Deliveries_Microsoft_Shipping_Labels_Delete_At_Path_Input;
  deliveries_microsoft_shipping_labels_delete_elem_input: Deliveries_Microsoft_Shipping_Labels_Delete_Elem_Input;
  deliveries_microsoft_shipping_labels_delete_key_input: Deliveries_Microsoft_Shipping_Labels_Delete_Key_Input;
  deliveries_microsoft_shipping_labels_insert_input: Deliveries_Microsoft_Shipping_Labels_Insert_Input;
  deliveries_microsoft_shipping_labels_max_fields: ResolverTypeWrapper<Deliveries_Microsoft_Shipping_Labels_Max_Fields>;
  deliveries_microsoft_shipping_labels_max_order_by: Deliveries_Microsoft_Shipping_Labels_Max_Order_By;
  deliveries_microsoft_shipping_labels_min_fields: ResolverTypeWrapper<Deliveries_Microsoft_Shipping_Labels_Min_Fields>;
  deliveries_microsoft_shipping_labels_min_order_by: Deliveries_Microsoft_Shipping_Labels_Min_Order_By;
  deliveries_microsoft_shipping_labels_mutation_response: ResolverTypeWrapper<Deliveries_Microsoft_Shipping_Labels_Mutation_Response>;
  deliveries_microsoft_shipping_labels_obj_rel_insert_input: Deliveries_Microsoft_Shipping_Labels_Obj_Rel_Insert_Input;
  deliveries_microsoft_shipping_labels_on_conflict: Deliveries_Microsoft_Shipping_Labels_On_Conflict;
  deliveries_microsoft_shipping_labels_order_by: Deliveries_Microsoft_Shipping_Labels_Order_By;
  deliveries_microsoft_shipping_labels_pk_columns_input: Deliveries_Microsoft_Shipping_Labels_Pk_Columns_Input;
  deliveries_microsoft_shipping_labels_prepend_input: Deliveries_Microsoft_Shipping_Labels_Prepend_Input;
  deliveries_microsoft_shipping_labels_select_column: Deliveries_Microsoft_Shipping_Labels_Select_Column;
  deliveries_microsoft_shipping_labels_set_input: Deliveries_Microsoft_Shipping_Labels_Set_Input;
  deliveries_microsoft_shipping_labels_stream_cursor_input: Deliveries_Microsoft_Shipping_Labels_Stream_Cursor_Input;
  deliveries_microsoft_shipping_labels_stream_cursor_value_input: Deliveries_Microsoft_Shipping_Labels_Stream_Cursor_Value_Input;
  deliveries_microsoft_shipping_labels_update_column: Deliveries_Microsoft_Shipping_Labels_Update_Column;
  deliveries_microsoft_shipping_labels_updates: Deliveries_Microsoft_Shipping_Labels_Updates;
  deliveries_microsoft_whql_submissions: ResolverTypeWrapper<Deliveries_Microsoft_Whql_Submissions>;
  deliveries_microsoft_whql_submissions_aggregate: ResolverTypeWrapper<Deliveries_Microsoft_Whql_Submissions_Aggregate>;
  deliveries_microsoft_whql_submissions_aggregate_fields: ResolverTypeWrapper<Deliveries_Microsoft_Whql_Submissions_Aggregate_Fields>;
  deliveries_microsoft_whql_submissions_bool_exp: Deliveries_Microsoft_Whql_Submissions_Bool_Exp;
  deliveries_microsoft_whql_submissions_constraint: Deliveries_Microsoft_Whql_Submissions_Constraint;
  deliveries_microsoft_whql_submissions_insert_input: Deliveries_Microsoft_Whql_Submissions_Insert_Input;
  deliveries_microsoft_whql_submissions_max_fields: ResolverTypeWrapper<Deliveries_Microsoft_Whql_Submissions_Max_Fields>;
  deliveries_microsoft_whql_submissions_min_fields: ResolverTypeWrapper<Deliveries_Microsoft_Whql_Submissions_Min_Fields>;
  deliveries_microsoft_whql_submissions_mutation_response: ResolverTypeWrapper<Deliveries_Microsoft_Whql_Submissions_Mutation_Response>;
  deliveries_microsoft_whql_submissions_obj_rel_insert_input: Deliveries_Microsoft_Whql_Submissions_Obj_Rel_Insert_Input;
  deliveries_microsoft_whql_submissions_on_conflict: Deliveries_Microsoft_Whql_Submissions_On_Conflict;
  deliveries_microsoft_whql_submissions_order_by: Deliveries_Microsoft_Whql_Submissions_Order_By;
  deliveries_microsoft_whql_submissions_pk_columns_input: Deliveries_Microsoft_Whql_Submissions_Pk_Columns_Input;
  deliveries_microsoft_whql_submissions_select_column: Deliveries_Microsoft_Whql_Submissions_Select_Column;
  deliveries_microsoft_whql_submissions_set_input: Deliveries_Microsoft_Whql_Submissions_Set_Input;
  deliveries_microsoft_whql_submissions_stream_cursor_input: Deliveries_Microsoft_Whql_Submissions_Stream_Cursor_Input;
  deliveries_microsoft_whql_submissions_stream_cursor_value_input: Deliveries_Microsoft_Whql_Submissions_Stream_Cursor_Value_Input;
  deliveries_microsoft_whql_submissions_update_column: Deliveries_Microsoft_Whql_Submissions_Update_Column;
  deliveries_microsoft_whql_submissions_updates: Deliveries_Microsoft_Whql_Submissions_Updates;
  deliveries_operating_systems: ResolverTypeWrapper<Deliveries_Operating_Systems>;
  deliveries_operating_systems_bool_exp: Deliveries_Operating_Systems_Bool_Exp;
  deliveries_operating_systems_constraint: Deliveries_Operating_Systems_Constraint;
  deliveries_operating_systems_insert_input: Deliveries_Operating_Systems_Insert_Input;
  deliveries_operating_systems_mutation_response: ResolverTypeWrapper<Deliveries_Operating_Systems_Mutation_Response>;
  deliveries_operating_systems_obj_rel_insert_input: Deliveries_Operating_Systems_Obj_Rel_Insert_Input;
  deliveries_operating_systems_on_conflict: Deliveries_Operating_Systems_On_Conflict;
  deliveries_operating_systems_order_by: Deliveries_Operating_Systems_Order_By;
  deliveries_operating_systems_pk_columns_input: Deliveries_Operating_Systems_Pk_Columns_Input;
  deliveries_operating_systems_select_column: Deliveries_Operating_Systems_Select_Column;
  deliveries_operating_systems_set_input: Deliveries_Operating_Systems_Set_Input;
  deliveries_operating_systems_stream_cursor_input: Deliveries_Operating_Systems_Stream_Cursor_Input;
  deliveries_operating_systems_stream_cursor_value_input: Deliveries_Operating_Systems_Stream_Cursor_Value_Input;
  deliveries_operating_systems_update_column: Deliveries_Operating_Systems_Update_Column;
  deliveries_operating_systems_updates: Deliveries_Operating_Systems_Updates;
  deliveries_package_operating_systems: ResolverTypeWrapper<Deliveries_Package_Operating_Systems>;
  deliveries_package_operating_systems_aggregate_order_by: Deliveries_Package_Operating_Systems_Aggregate_Order_By;
  deliveries_package_operating_systems_arr_rel_insert_input: Deliveries_Package_Operating_Systems_Arr_Rel_Insert_Input;
  deliveries_package_operating_systems_bool_exp: Deliveries_Package_Operating_Systems_Bool_Exp;
  deliveries_package_operating_systems_constraint: Deliveries_Package_Operating_Systems_Constraint;
  deliveries_package_operating_systems_insert_input: Deliveries_Package_Operating_Systems_Insert_Input;
  deliveries_package_operating_systems_max_order_by: Deliveries_Package_Operating_Systems_Max_Order_By;
  deliveries_package_operating_systems_min_order_by: Deliveries_Package_Operating_Systems_Min_Order_By;
  deliveries_package_operating_systems_mutation_response: ResolverTypeWrapper<Deliveries_Package_Operating_Systems_Mutation_Response>;
  deliveries_package_operating_systems_on_conflict: Deliveries_Package_Operating_Systems_On_Conflict;
  deliveries_package_operating_systems_order_by: Deliveries_Package_Operating_Systems_Order_By;
  deliveries_package_operating_systems_pk_columns_input: Deliveries_Package_Operating_Systems_Pk_Columns_Input;
  deliveries_package_operating_systems_select_column: Deliveries_Package_Operating_Systems_Select_Column;
  deliveries_package_operating_systems_set_input: Deliveries_Package_Operating_Systems_Set_Input;
  deliveries_package_operating_systems_stream_cursor_input: Deliveries_Package_Operating_Systems_Stream_Cursor_Input;
  deliveries_package_operating_systems_stream_cursor_value_input: Deliveries_Package_Operating_Systems_Stream_Cursor_Value_Input;
  deliveries_package_operating_systems_update_column: Deliveries_Package_Operating_Systems_Update_Column;
  deliveries_package_operating_systems_updates: Deliveries_Package_Operating_Systems_Updates;
  deliveries_package_target: ResolverTypeWrapper<Deliveries_Package_Target>;
  deliveries_package_target_aggregate: ResolverTypeWrapper<Deliveries_Package_Target_Aggregate>;
  deliveries_package_target_aggregate_fields: ResolverTypeWrapper<Deliveries_Package_Target_Aggregate_Fields>;
  deliveries_package_target_aggregate_order_by: Deliveries_Package_Target_Aggregate_Order_By;
  deliveries_package_target_arr_rel_insert_input: Deliveries_Package_Target_Arr_Rel_Insert_Input;
  deliveries_package_target_bool_exp: Deliveries_Package_Target_Bool_Exp;
  deliveries_package_target_constraint: Deliveries_Package_Target_Constraint;
  deliveries_package_target_insert_input: Deliveries_Package_Target_Insert_Input;
  deliveries_package_target_max_fields: ResolverTypeWrapper<Deliveries_Package_Target_Max_Fields>;
  deliveries_package_target_max_order_by: Deliveries_Package_Target_Max_Order_By;
  deliveries_package_target_min_fields: ResolverTypeWrapper<Deliveries_Package_Target_Min_Fields>;
  deliveries_package_target_min_order_by: Deliveries_Package_Target_Min_Order_By;
  deliveries_package_target_mutation_response: ResolverTypeWrapper<Deliveries_Package_Target_Mutation_Response>;
  deliveries_package_target_on_conflict: Deliveries_Package_Target_On_Conflict;
  deliveries_package_target_order_by: Deliveries_Package_Target_Order_By;
  deliveries_package_target_pk_columns_input: Deliveries_Package_Target_Pk_Columns_Input;
  deliveries_package_target_select_column: Deliveries_Package_Target_Select_Column;
  deliveries_package_target_set_input: Deliveries_Package_Target_Set_Input;
  deliveries_package_target_stream_cursor_input: Deliveries_Package_Target_Stream_Cursor_Input;
  deliveries_package_target_stream_cursor_value_input: Deliveries_Package_Target_Stream_Cursor_Value_Input;
  deliveries_package_target_update_column: Deliveries_Package_Target_Update_Column;
  deliveries_package_target_updates: Deliveries_Package_Target_Updates;
  deliveries_packages: ResolverTypeWrapper<Deliveries_Packages>;
  deliveries_packages_aggregate: ResolverTypeWrapper<Deliveries_Packages_Aggregate>;
  deliveries_packages_aggregate_fields: ResolverTypeWrapper<Deliveries_Packages_Aggregate_Fields>;
  deliveries_packages_aggregate_order_by: Deliveries_Packages_Aggregate_Order_By;
  deliveries_packages_arr_rel_insert_input: Deliveries_Packages_Arr_Rel_Insert_Input;
  deliveries_packages_avg_fields: ResolverTypeWrapper<Deliveries_Packages_Avg_Fields>;
  deliveries_packages_avg_order_by: Deliveries_Packages_Avg_Order_By;
  deliveries_packages_bool_exp: Deliveries_Packages_Bool_Exp;
  deliveries_packages_constraint: Deliveries_Packages_Constraint;
  deliveries_packages_inc_input: Deliveries_Packages_Inc_Input;
  deliveries_packages_insert_input: Deliveries_Packages_Insert_Input;
  deliveries_packages_max_fields: ResolverTypeWrapper<Deliveries_Packages_Max_Fields>;
  deliveries_packages_max_order_by: Deliveries_Packages_Max_Order_By;
  deliveries_packages_min_fields: ResolverTypeWrapper<Deliveries_Packages_Min_Fields>;
  deliveries_packages_min_order_by: Deliveries_Packages_Min_Order_By;
  deliveries_packages_mutation_response: ResolverTypeWrapper<Deliveries_Packages_Mutation_Response>;
  deliveries_packages_obj_rel_insert_input: Deliveries_Packages_Obj_Rel_Insert_Input;
  deliveries_packages_on_conflict: Deliveries_Packages_On_Conflict;
  deliveries_packages_order_by: Deliveries_Packages_Order_By;
  deliveries_packages_pk_columns_input: Deliveries_Packages_Pk_Columns_Input;
  deliveries_packages_select_column: Deliveries_Packages_Select_Column;
  deliveries_packages_set_input: Deliveries_Packages_Set_Input;
  deliveries_packages_stddev_fields: ResolverTypeWrapper<Deliveries_Packages_Stddev_Fields>;
  deliveries_packages_stddev_order_by: Deliveries_Packages_Stddev_Order_By;
  deliveries_packages_stddev_pop_fields: ResolverTypeWrapper<Deliveries_Packages_Stddev_Pop_Fields>;
  deliveries_packages_stddev_pop_order_by: Deliveries_Packages_Stddev_Pop_Order_By;
  deliveries_packages_stddev_samp_fields: ResolverTypeWrapper<Deliveries_Packages_Stddev_Samp_Fields>;
  deliveries_packages_stddev_samp_order_by: Deliveries_Packages_Stddev_Samp_Order_By;
  deliveries_packages_stream_cursor_input: Deliveries_Packages_Stream_Cursor_Input;
  deliveries_packages_stream_cursor_value_input: Deliveries_Packages_Stream_Cursor_Value_Input;
  deliveries_packages_sum_fields: ResolverTypeWrapper<Deliveries_Packages_Sum_Fields>;
  deliveries_packages_sum_order_by: Deliveries_Packages_Sum_Order_By;
  deliveries_packages_update_column: Deliveries_Packages_Update_Column;
  deliveries_packages_updates: Deliveries_Packages_Updates;
  deliveries_packages_var_pop_fields: ResolverTypeWrapper<Deliveries_Packages_Var_Pop_Fields>;
  deliveries_packages_var_pop_order_by: Deliveries_Packages_Var_Pop_Order_By;
  deliveries_packages_var_samp_fields: ResolverTypeWrapper<Deliveries_Packages_Var_Samp_Fields>;
  deliveries_packages_var_samp_order_by: Deliveries_Packages_Var_Samp_Order_By;
  deliveries_packages_variance_fields: ResolverTypeWrapper<Deliveries_Packages_Variance_Fields>;
  deliveries_packages_variance_order_by: Deliveries_Packages_Variance_Order_By;
  deliveries_partners: ResolverTypeWrapper<Deliveries_Partners>;
  deliveries_partners_bool_exp: Deliveries_Partners_Bool_Exp;
  deliveries_partners_constraint: Deliveries_Partners_Constraint;
  deliveries_partners_insert_input: Deliveries_Partners_Insert_Input;
  deliveries_partners_mutation_response: ResolverTypeWrapper<Deliveries_Partners_Mutation_Response>;
  deliveries_partners_obj_rel_insert_input: Deliveries_Partners_Obj_Rel_Insert_Input;
  deliveries_partners_on_conflict: Deliveries_Partners_On_Conflict;
  deliveries_partners_order_by: Deliveries_Partners_Order_By;
  deliveries_partners_pk_columns_input: Deliveries_Partners_Pk_Columns_Input;
  deliveries_partners_select_column: Deliveries_Partners_Select_Column;
  deliveries_partners_set_input: Deliveries_Partners_Set_Input;
  deliveries_partners_stream_cursor_input: Deliveries_Partners_Stream_Cursor_Input;
  deliveries_partners_stream_cursor_value_input: Deliveries_Partners_Stream_Cursor_Value_Input;
  deliveries_partners_update_column: Deliveries_Partners_Update_Column;
  deliveries_partners_updates: Deliveries_Partners_Updates;
  deliveries_platforms: ResolverTypeWrapper<Deliveries_Platforms>;
  deliveries_platforms_bool_exp: Deliveries_Platforms_Bool_Exp;
  deliveries_platforms_constraint: Deliveries_Platforms_Constraint;
  deliveries_platforms_insert_input: Deliveries_Platforms_Insert_Input;
  deliveries_platforms_mutation_response: ResolverTypeWrapper<Deliveries_Platforms_Mutation_Response>;
  deliveries_platforms_obj_rel_insert_input: Deliveries_Platforms_Obj_Rel_Insert_Input;
  deliveries_platforms_on_conflict: Deliveries_Platforms_On_Conflict;
  deliveries_platforms_order_by: Deliveries_Platforms_Order_By;
  deliveries_platforms_pk_columns_input: Deliveries_Platforms_Pk_Columns_Input;
  deliveries_platforms_select_column: Deliveries_Platforms_Select_Column;
  deliveries_platforms_set_input: Deliveries_Platforms_Set_Input;
  deliveries_platforms_stream_cursor_input: Deliveries_Platforms_Stream_Cursor_Input;
  deliveries_platforms_stream_cursor_value_input: Deliveries_Platforms_Stream_Cursor_Value_Input;
  deliveries_platforms_update_column: Deliveries_Platforms_Update_Column;
  deliveries_platforms_updates: Deliveries_Platforms_Updates;
  deliveries_project_configs: ResolverTypeWrapper<Deliveries_Project_Configs>;
  deliveries_project_configs_aggregate: ResolverTypeWrapper<Deliveries_Project_Configs_Aggregate>;
  deliveries_project_configs_aggregate_fields: ResolverTypeWrapper<Deliveries_Project_Configs_Aggregate_Fields>;
  deliveries_project_configs_avg_fields: ResolverTypeWrapper<Deliveries_Project_Configs_Avg_Fields>;
  deliveries_project_configs_bool_exp: Deliveries_Project_Configs_Bool_Exp;
  deliveries_project_configs_constraint: Deliveries_Project_Configs_Constraint;
  deliveries_project_configs_inc_input: Deliveries_Project_Configs_Inc_Input;
  deliveries_project_configs_insert_input: Deliveries_Project_Configs_Insert_Input;
  deliveries_project_configs_max_fields: ResolverTypeWrapper<Deliveries_Project_Configs_Max_Fields>;
  deliveries_project_configs_min_fields: ResolverTypeWrapper<Deliveries_Project_Configs_Min_Fields>;
  deliveries_project_configs_mutation_response: ResolverTypeWrapper<Deliveries_Project_Configs_Mutation_Response>;
  deliveries_project_configs_on_conflict: Deliveries_Project_Configs_On_Conflict;
  deliveries_project_configs_order_by: Deliveries_Project_Configs_Order_By;
  deliveries_project_configs_pk_columns_input: Deliveries_Project_Configs_Pk_Columns_Input;
  deliveries_project_configs_select_column: Deliveries_Project_Configs_Select_Column;
  deliveries_project_configs_set_input: Deliveries_Project_Configs_Set_Input;
  deliveries_project_configs_stddev_fields: ResolverTypeWrapper<Deliveries_Project_Configs_Stddev_Fields>;
  deliveries_project_configs_stddev_pop_fields: ResolverTypeWrapper<Deliveries_Project_Configs_Stddev_Pop_Fields>;
  deliveries_project_configs_stddev_samp_fields: ResolverTypeWrapper<Deliveries_Project_Configs_Stddev_Samp_Fields>;
  deliveries_project_configs_stream_cursor_input: Deliveries_Project_Configs_Stream_Cursor_Input;
  deliveries_project_configs_stream_cursor_value_input: Deliveries_Project_Configs_Stream_Cursor_Value_Input;
  deliveries_project_configs_sum_fields: ResolverTypeWrapper<Deliveries_Project_Configs_Sum_Fields>;
  deliveries_project_configs_update_column: Deliveries_Project_Configs_Update_Column;
  deliveries_project_configs_updates: Deliveries_Project_Configs_Updates;
  deliveries_project_configs_var_pop_fields: ResolverTypeWrapper<Deliveries_Project_Configs_Var_Pop_Fields>;
  deliveries_project_configs_var_samp_fields: ResolverTypeWrapper<Deliveries_Project_Configs_Var_Samp_Fields>;
  deliveries_project_configs_variance_fields: ResolverTypeWrapper<Deliveries_Project_Configs_Variance_Fields>;
  deliveries_target_partner: ResolverTypeWrapper<Deliveries_Target_Partner>;
  deliveries_target_partner_aggregate_order_by: Deliveries_Target_Partner_Aggregate_Order_By;
  deliveries_target_partner_arr_rel_insert_input: Deliveries_Target_Partner_Arr_Rel_Insert_Input;
  deliveries_target_partner_bool_exp: Deliveries_Target_Partner_Bool_Exp;
  deliveries_target_partner_constraint: Deliveries_Target_Partner_Constraint;
  deliveries_target_partner_insert_input: Deliveries_Target_Partner_Insert_Input;
  deliveries_target_partner_max_order_by: Deliveries_Target_Partner_Max_Order_By;
  deliveries_target_partner_min_order_by: Deliveries_Target_Partner_Min_Order_By;
  deliveries_target_partner_mutation_response: ResolverTypeWrapper<Deliveries_Target_Partner_Mutation_Response>;
  deliveries_target_partner_on_conflict: Deliveries_Target_Partner_On_Conflict;
  deliveries_target_partner_order_by: Deliveries_Target_Partner_Order_By;
  deliveries_target_partner_pk_columns_input: Deliveries_Target_Partner_Pk_Columns_Input;
  deliveries_target_partner_select_column: Deliveries_Target_Partner_Select_Column;
  deliveries_target_partner_set_input: Deliveries_Target_Partner_Set_Input;
  deliveries_target_partner_stream_cursor_input: Deliveries_Target_Partner_Stream_Cursor_Input;
  deliveries_target_partner_stream_cursor_value_input: Deliveries_Target_Partner_Stream_Cursor_Value_Input;
  deliveries_target_partner_update_column: Deliveries_Target_Partner_Update_Column;
  deliveries_target_partner_updates: Deliveries_Target_Partner_Updates;
  deliveries_targets: ResolverTypeWrapper<Deliveries_Targets>;
  deliveries_targets_aggregate: ResolverTypeWrapper<Deliveries_Targets_Aggregate>;
  deliveries_targets_aggregate_fields: ResolverTypeWrapper<Deliveries_Targets_Aggregate_Fields>;
  deliveries_targets_bool_exp: Deliveries_Targets_Bool_Exp;
  deliveries_targets_constraint: Deliveries_Targets_Constraint;
  deliveries_targets_insert_input: Deliveries_Targets_Insert_Input;
  deliveries_targets_max_fields: ResolverTypeWrapper<Deliveries_Targets_Max_Fields>;
  deliveries_targets_min_fields: ResolverTypeWrapper<Deliveries_Targets_Min_Fields>;
  deliveries_targets_mutation_response: ResolverTypeWrapper<Deliveries_Targets_Mutation_Response>;
  deliveries_targets_obj_rel_insert_input: Deliveries_Targets_Obj_Rel_Insert_Input;
  deliveries_targets_on_conflict: Deliveries_Targets_On_Conflict;
  deliveries_targets_order_by: Deliveries_Targets_Order_By;
  deliveries_targets_pk_columns_input: Deliveries_Targets_Pk_Columns_Input;
  deliveries_targets_select_column: Deliveries_Targets_Select_Column;
  deliveries_targets_set_input: Deliveries_Targets_Set_Input;
  deliveries_targets_stream_cursor_input: Deliveries_Targets_Stream_Cursor_Input;
  deliveries_targets_stream_cursor_value_input: Deliveries_Targets_Stream_Cursor_Value_Input;
  deliveries_targets_update_column: Deliveries_Targets_Update_Column;
  deliveries_targets_updates: Deliveries_Targets_Updates;
  device_firmware: ResolverTypeWrapper<Device_Firmware>;
  device_firmware_aggregate_order_by: Device_Firmware_Aggregate_Order_By;
  device_firmware_arr_rel_insert_input: Device_Firmware_Arr_Rel_Insert_Input;
  device_firmware_bool_exp: Device_Firmware_Bool_Exp;
  device_firmware_constraint: Device_Firmware_Constraint;
  device_firmware_insert_input: Device_Firmware_Insert_Input;
  device_firmware_max_order_by: Device_Firmware_Max_Order_By;
  device_firmware_min_order_by: Device_Firmware_Min_Order_By;
  device_firmware_mutation_response: ResolverTypeWrapper<Device_Firmware_Mutation_Response>;
  device_firmware_obj_rel_insert_input: Device_Firmware_Obj_Rel_Insert_Input;
  device_firmware_on_conflict: Device_Firmware_On_Conflict;
  device_firmware_order_by: Device_Firmware_Order_By;
  device_firmware_pk_columns_input: Device_Firmware_Pk_Columns_Input;
  device_firmware_select_column: Device_Firmware_Select_Column;
  device_firmware_set_input: Device_Firmware_Set_Input;
  device_firmware_stream_cursor_input: Device_Firmware_Stream_Cursor_Input;
  device_firmware_stream_cursor_value_input: Device_Firmware_Stream_Cursor_Value_Input;
  device_firmware_update_column: Device_Firmware_Update_Column;
  device_firmware_updates: Device_Firmware_Updates;
  device_project: ResolverTypeWrapper<Device_Project>;
  device_project_aggregate: ResolverTypeWrapper<Device_Project_Aggregate>;
  device_project_aggregate_fields: ResolverTypeWrapper<Device_Project_Aggregate_Fields>;
  device_project_aggregate_order_by: Device_Project_Aggregate_Order_By;
  device_project_arr_rel_insert_input: Device_Project_Arr_Rel_Insert_Input;
  device_project_bool_exp: Device_Project_Bool_Exp;
  device_project_constraint: Device_Project_Constraint;
  device_project_insert_input: Device_Project_Insert_Input;
  device_project_max_fields: ResolverTypeWrapper<Device_Project_Max_Fields>;
  device_project_max_order_by: Device_Project_Max_Order_By;
  device_project_min_fields: ResolverTypeWrapper<Device_Project_Min_Fields>;
  device_project_min_order_by: Device_Project_Min_Order_By;
  device_project_mutation_response: ResolverTypeWrapper<Device_Project_Mutation_Response>;
  device_project_on_conflict: Device_Project_On_Conflict;
  device_project_order_by: Device_Project_Order_By;
  device_project_pk_columns_input: Device_Project_Pk_Columns_Input;
  device_project_select_column: Device_Project_Select_Column;
  device_project_set_input: Device_Project_Set_Input;
  device_project_stream_cursor_input: Device_Project_Stream_Cursor_Input;
  device_project_stream_cursor_value_input: Device_Project_Stream_Cursor_Value_Input;
  device_project_update_column: Device_Project_Update_Column;
  device_project_updates: Device_Project_Updates;
  devices: ResolverTypeWrapper<Devices>;
  devices_aggregate: ResolverTypeWrapper<Devices_Aggregate>;
  devices_aggregate_fields: ResolverTypeWrapper<Devices_Aggregate_Fields>;
  devices_aggregate_order_by: Devices_Aggregate_Order_By;
  devices_arr_rel_insert_input: Devices_Arr_Rel_Insert_Input;
  devices_bool_exp: Devices_Bool_Exp;
  devices_constraint: Devices_Constraint;
  devices_insert_input: Devices_Insert_Input;
  devices_max_fields: ResolverTypeWrapper<Devices_Max_Fields>;
  devices_max_order_by: Devices_Max_Order_By;
  devices_min_fields: ResolverTypeWrapper<Devices_Min_Fields>;
  devices_min_order_by: Devices_Min_Order_By;
  devices_mutation_response: ResolverTypeWrapper<Devices_Mutation_Response>;
  devices_obj_rel_insert_input: Devices_Obj_Rel_Insert_Input;
  devices_on_conflict: Devices_On_Conflict;
  devices_order_by: Devices_Order_By;
  devices_pk_columns_input: Devices_Pk_Columns_Input;
  devices_select_column: Devices_Select_Column;
  devices_set_input: Devices_Set_Input;
  devices_stream_cursor_input: Devices_Stream_Cursor_Input;
  devices_stream_cursor_value_input: Devices_Stream_Cursor_Value_Input;
  devices_update_column: Devices_Update_Column;
  devices_updates: Devices_Updates;
  dynamic_scaling: ResolverTypeWrapper<Dynamic_Scaling>;
  dynamic_scaling_bool_exp: Dynamic_Scaling_Bool_Exp;
  dynamic_scaling_constraint: Dynamic_Scaling_Constraint;
  dynamic_scaling_inc_input: Dynamic_Scaling_Inc_Input;
  dynamic_scaling_insert_input: Dynamic_Scaling_Insert_Input;
  dynamic_scaling_mutation_response: ResolverTypeWrapper<Dynamic_Scaling_Mutation_Response>;
  dynamic_scaling_obj_rel_insert_input: Dynamic_Scaling_Obj_Rel_Insert_Input;
  dynamic_scaling_on_conflict: Dynamic_Scaling_On_Conflict;
  dynamic_scaling_order_by: Dynamic_Scaling_Order_By;
  dynamic_scaling_pk_columns_input: Dynamic_Scaling_Pk_Columns_Input;
  dynamic_scaling_select_column: Dynamic_Scaling_Select_Column;
  dynamic_scaling_set_input: Dynamic_Scaling_Set_Input;
  dynamic_scaling_stream_cursor_input: Dynamic_Scaling_Stream_Cursor_Input;
  dynamic_scaling_stream_cursor_value_input: Dynamic_Scaling_Stream_Cursor_Value_Input;
  dynamic_scaling_update_column: Dynamic_Scaling_Update_Column;
  dynamic_scaling_updates: Dynamic_Scaling_Updates;
  engine_opmode: ResolverTypeWrapper<Engine_Opmode>;
  engine_opmode_aggregate: ResolverTypeWrapper<Engine_Opmode_Aggregate>;
  engine_opmode_aggregate_fields: ResolverTypeWrapper<Engine_Opmode_Aggregate_Fields>;
  engine_opmode_aggregate_order_by: Engine_Opmode_Aggregate_Order_By;
  engine_opmode_arr_rel_insert_input: Engine_Opmode_Arr_Rel_Insert_Input;
  engine_opmode_bool_exp: Engine_Opmode_Bool_Exp;
  engine_opmode_constraint: Engine_Opmode_Constraint;
  engine_opmode_insert_input: Engine_Opmode_Insert_Input;
  engine_opmode_max_fields: ResolverTypeWrapper<Engine_Opmode_Max_Fields>;
  engine_opmode_max_order_by: Engine_Opmode_Max_Order_By;
  engine_opmode_min_fields: ResolverTypeWrapper<Engine_Opmode_Min_Fields>;
  engine_opmode_min_order_by: Engine_Opmode_Min_Order_By;
  engine_opmode_mutation_response: ResolverTypeWrapper<Engine_Opmode_Mutation_Response>;
  engine_opmode_obj_rel_insert_input: Engine_Opmode_Obj_Rel_Insert_Input;
  engine_opmode_on_conflict: Engine_Opmode_On_Conflict;
  engine_opmode_order_by: Engine_Opmode_Order_By;
  engine_opmode_pk_columns_input: Engine_Opmode_Pk_Columns_Input;
  engine_opmode_recording: ResolverTypeWrapper<Engine_Opmode_Recording>;
  engine_opmode_recording_aggregate: ResolverTypeWrapper<Engine_Opmode_Recording_Aggregate>;
  engine_opmode_recording_aggregate_fields: ResolverTypeWrapper<Engine_Opmode_Recording_Aggregate_Fields>;
  engine_opmode_recording_aggregate_order_by: Engine_Opmode_Recording_Aggregate_Order_By;
  engine_opmode_recording_arr_rel_insert_input: Engine_Opmode_Recording_Arr_Rel_Insert_Input;
  engine_opmode_recording_bool_exp: Engine_Opmode_Recording_Bool_Exp;
  engine_opmode_recording_constraint: Engine_Opmode_Recording_Constraint;
  engine_opmode_recording_insert_input: Engine_Opmode_Recording_Insert_Input;
  engine_opmode_recording_max_fields: ResolverTypeWrapper<Engine_Opmode_Recording_Max_Fields>;
  engine_opmode_recording_max_order_by: Engine_Opmode_Recording_Max_Order_By;
  engine_opmode_recording_min_fields: ResolverTypeWrapper<Engine_Opmode_Recording_Min_Fields>;
  engine_opmode_recording_min_order_by: Engine_Opmode_Recording_Min_Order_By;
  engine_opmode_recording_mutation_response: ResolverTypeWrapper<Engine_Opmode_Recording_Mutation_Response>;
  engine_opmode_recording_on_conflict: Engine_Opmode_Recording_On_Conflict;
  engine_opmode_recording_order_by: Engine_Opmode_Recording_Order_By;
  engine_opmode_recording_pk_columns_input: Engine_Opmode_Recording_Pk_Columns_Input;
  engine_opmode_recording_select_column: Engine_Opmode_Recording_Select_Column;
  engine_opmode_recording_set_input: Engine_Opmode_Recording_Set_Input;
  engine_opmode_recording_stream_cursor_input: Engine_Opmode_Recording_Stream_Cursor_Input;
  engine_opmode_recording_stream_cursor_value_input: Engine_Opmode_Recording_Stream_Cursor_Value_Input;
  engine_opmode_recording_update_column: Engine_Opmode_Recording_Update_Column;
  engine_opmode_recording_updates: Engine_Opmode_Recording_Updates;
  engine_opmode_select_column: Engine_Opmode_Select_Column;
  engine_opmode_set_input: Engine_Opmode_Set_Input;
  engine_opmode_stream_cursor_input: Engine_Opmode_Stream_Cursor_Input;
  engine_opmode_stream_cursor_value_input: Engine_Opmode_Stream_Cursor_Value_Input;
  engine_opmode_update_column: Engine_Opmode_Update_Column;
  engine_opmode_updates: Engine_Opmode_Updates;
  engine_types: ResolverTypeWrapper<Engine_Types>;
  engine_types_aggregate: ResolverTypeWrapper<Engine_Types_Aggregate>;
  engine_types_aggregate_fields: ResolverTypeWrapper<Engine_Types_Aggregate_Fields>;
  engine_types_bool_exp: Engine_Types_Bool_Exp;
  engine_types_enum: Engine_Types_Enum;
  engine_types_enum_comparison_exp: Engine_Types_Enum_Comparison_Exp;
  engine_types_max_fields: ResolverTypeWrapper<Engine_Types_Max_Fields>;
  engine_types_min_fields: ResolverTypeWrapper<Engine_Types_Min_Fields>;
  engine_types_mutation_response: ResolverTypeWrapper<Engine_Types_Mutation_Response>;
  engine_types_order_by: Engine_Types_Order_By;
  engine_types_pk_columns_input: Engine_Types_Pk_Columns_Input;
  engine_types_select_column: Engine_Types_Select_Column;
  engine_types_set_input: Engine_Types_Set_Input;
  engine_types_stream_cursor_input: Engine_Types_Stream_Cursor_Input;
  engine_types_stream_cursor_value_input: Engine_Types_Stream_Cursor_Value_Input;
  engine_types_updates: Engine_Types_Updates;
  engines: ResolverTypeWrapper<Engines>;
  engines_aggregate: ResolverTypeWrapper<Engines_Aggregate>;
  engines_aggregate_fields: ResolverTypeWrapper<Engines_Aggregate_Fields>;
  engines_avg_fields: ResolverTypeWrapper<Engines_Avg_Fields>;
  engines_bool_exp: Engines_Bool_Exp;
  engines_constraint: Engines_Constraint;
  engines_inc_input: Engines_Inc_Input;
  engines_insert_input: Engines_Insert_Input;
  engines_max_fields: ResolverTypeWrapper<Engines_Max_Fields>;
  engines_min_fields: ResolverTypeWrapper<Engines_Min_Fields>;
  engines_mutation_response: ResolverTypeWrapper<Engines_Mutation_Response>;
  engines_obj_rel_insert_input: Engines_Obj_Rel_Insert_Input;
  engines_on_conflict: Engines_On_Conflict;
  engines_order_by: Engines_Order_By;
  engines_pk_columns_input: Engines_Pk_Columns_Input;
  engines_select_column: Engines_Select_Column;
  engines_set_input: Engines_Set_Input;
  engines_stddev_fields: ResolverTypeWrapper<Engines_Stddev_Fields>;
  engines_stddev_pop_fields: ResolverTypeWrapper<Engines_Stddev_Pop_Fields>;
  engines_stddev_samp_fields: ResolverTypeWrapper<Engines_Stddev_Samp_Fields>;
  engines_stream_cursor_input: Engines_Stream_Cursor_Input;
  engines_stream_cursor_value_input: Engines_Stream_Cursor_Value_Input;
  engines_sum_fields: ResolverTypeWrapper<Engines_Sum_Fields>;
  engines_update_column: Engines_Update_Column;
  engines_updates: Engines_Updates;
  engines_var_pop_fields: ResolverTypeWrapper<Engines_Var_Pop_Fields>;
  engines_var_samp_fields: ResolverTypeWrapper<Engines_Var_Samp_Fields>;
  engines_variance_fields: ResolverTypeWrapper<Engines_Variance_Fields>;
  field_option_media: ResolverTypeWrapper<Field_Option_Media>;
  field_option_media_aggregate: ResolverTypeWrapper<Field_Option_Media_Aggregate>;
  field_option_media_aggregate_fields: ResolverTypeWrapper<Field_Option_Media_Aggregate_Fields>;
  field_option_media_aggregate_order_by: Field_Option_Media_Aggregate_Order_By;
  field_option_media_arr_rel_insert_input: Field_Option_Media_Arr_Rel_Insert_Input;
  field_option_media_bool_exp: Field_Option_Media_Bool_Exp;
  field_option_media_constraint: Field_Option_Media_Constraint;
  field_option_media_insert_input: Field_Option_Media_Insert_Input;
  field_option_media_max_fields: ResolverTypeWrapper<Field_Option_Media_Max_Fields>;
  field_option_media_max_order_by: Field_Option_Media_Max_Order_By;
  field_option_media_min_fields: ResolverTypeWrapper<Field_Option_Media_Min_Fields>;
  field_option_media_min_order_by: Field_Option_Media_Min_Order_By;
  field_option_media_mutation_response: ResolverTypeWrapper<Field_Option_Media_Mutation_Response>;
  field_option_media_on_conflict: Field_Option_Media_On_Conflict;
  field_option_media_order_by: Field_Option_Media_Order_By;
  field_option_media_pk_columns_input: Field_Option_Media_Pk_Columns_Input;
  field_option_media_select_column: Field_Option_Media_Select_Column;
  field_option_media_set_input: Field_Option_Media_Set_Input;
  field_option_media_stream_cursor_input: Field_Option_Media_Stream_Cursor_Input;
  field_option_media_stream_cursor_value_input: Field_Option_Media_Stream_Cursor_Value_Input;
  field_option_media_update_column: Field_Option_Media_Update_Column;
  field_option_media_updates: Field_Option_Media_Updates;
  field_options: ResolverTypeWrapper<Field_Options>;
  field_options_aggregate: ResolverTypeWrapper<Field_Options_Aggregate>;
  field_options_aggregate_fields: ResolverTypeWrapper<Field_Options_Aggregate_Fields>;
  field_options_aggregate_order_by: Field_Options_Aggregate_Order_By;
  field_options_arr_rel_insert_input: Field_Options_Arr_Rel_Insert_Input;
  field_options_bool_exp: Field_Options_Bool_Exp;
  field_options_constraint: Field_Options_Constraint;
  field_options_insert_input: Field_Options_Insert_Input;
  field_options_max_fields: ResolverTypeWrapper<Field_Options_Max_Fields>;
  field_options_max_order_by: Field_Options_Max_Order_By;
  field_options_min_fields: ResolverTypeWrapper<Field_Options_Min_Fields>;
  field_options_min_order_by: Field_Options_Min_Order_By;
  field_options_mutation_response: ResolverTypeWrapper<Field_Options_Mutation_Response>;
  field_options_obj_rel_insert_input: Field_Options_Obj_Rel_Insert_Input;
  field_options_on_conflict: Field_Options_On_Conflict;
  field_options_order_by: Field_Options_Order_By;
  field_options_pk_columns_input: Field_Options_Pk_Columns_Input;
  field_options_select_column: Field_Options_Select_Column;
  field_options_set_input: Field_Options_Set_Input;
  field_options_stream_cursor_input: Field_Options_Stream_Cursor_Input;
  field_options_stream_cursor_value_input: Field_Options_Stream_Cursor_Value_Input;
  field_options_update_column: Field_Options_Update_Column;
  field_options_updates: Field_Options_Updates;
  field_translation: ResolverTypeWrapper<Field_Translation>;
  field_translation_aggregate: ResolverTypeWrapper<Field_Translation_Aggregate>;
  field_translation_aggregate_fields: ResolverTypeWrapper<Field_Translation_Aggregate_Fields>;
  field_translation_aggregate_order_by: Field_Translation_Aggregate_Order_By;
  field_translation_arr_rel_insert_input: Field_Translation_Arr_Rel_Insert_Input;
  field_translation_bool_exp: Field_Translation_Bool_Exp;
  field_translation_constraint: Field_Translation_Constraint;
  field_translation_insert_input: Field_Translation_Insert_Input;
  field_translation_max_fields: ResolverTypeWrapper<Field_Translation_Max_Fields>;
  field_translation_max_order_by: Field_Translation_Max_Order_By;
  field_translation_min_fields: ResolverTypeWrapper<Field_Translation_Min_Fields>;
  field_translation_min_order_by: Field_Translation_Min_Order_By;
  field_translation_mutation_response: ResolverTypeWrapper<Field_Translation_Mutation_Response>;
  field_translation_on_conflict: Field_Translation_On_Conflict;
  field_translation_order_by: Field_Translation_Order_By;
  field_translation_pk_columns_input: Field_Translation_Pk_Columns_Input;
  field_translation_select_column: Field_Translation_Select_Column;
  field_translation_set_input: Field_Translation_Set_Input;
  field_translation_stream_cursor_input: Field_Translation_Stream_Cursor_Input;
  field_translation_stream_cursor_value_input: Field_Translation_Stream_Cursor_Value_Input;
  field_translation_update_column: Field_Translation_Update_Column;
  field_translation_updates: Field_Translation_Updates;
  fields: ResolverTypeWrapper<Fields>;
  fields_aggregate: ResolverTypeWrapper<Fields_Aggregate>;
  fields_aggregate_fields: ResolverTypeWrapper<Fields_Aggregate_Fields>;
  fields_aggregate_order_by: Fields_Aggregate_Order_By;
  fields_arr_rel_insert_input: Fields_Arr_Rel_Insert_Input;
  fields_bool_exp: Fields_Bool_Exp;
  fields_constraint: Fields_Constraint;
  fields_insert_input: Fields_Insert_Input;
  fields_max_fields: ResolverTypeWrapper<Fields_Max_Fields>;
  fields_max_order_by: Fields_Max_Order_By;
  fields_min_fields: ResolverTypeWrapper<Fields_Min_Fields>;
  fields_min_order_by: Fields_Min_Order_By;
  fields_mutation_response: ResolverTypeWrapper<Fields_Mutation_Response>;
  fields_obj_rel_insert_input: Fields_Obj_Rel_Insert_Input;
  fields_on_conflict: Fields_On_Conflict;
  fields_order_by: Fields_Order_By;
  fields_pk_columns_input: Fields_Pk_Columns_Input;
  fields_select_column: Fields_Select_Column;
  fields_set_input: Fields_Set_Input;
  fields_stream_cursor_input: Fields_Stream_Cursor_Input;
  fields_stream_cursor_value_input: Fields_Stream_Cursor_Value_Input;
  fields_update_column: Fields_Update_Column;
  fields_updates: Fields_Updates;
  firmware: ResolverTypeWrapper<Firmware>;
  firmware_aggregate: ResolverTypeWrapper<Firmware_Aggregate>;
  firmware_aggregate_fields: ResolverTypeWrapper<Firmware_Aggregate_Fields>;
  firmware_append_input: Firmware_Append_Input;
  firmware_bool_exp: Firmware_Bool_Exp;
  firmware_constraint: Firmware_Constraint;
  firmware_delete_at_path_input: Firmware_Delete_At_Path_Input;
  firmware_delete_elem_input: Firmware_Delete_Elem_Input;
  firmware_delete_key_input: Firmware_Delete_Key_Input;
  firmware_insert_input: Firmware_Insert_Input;
  firmware_max_fields: ResolverTypeWrapper<Firmware_Max_Fields>;
  firmware_min_fields: ResolverTypeWrapper<Firmware_Min_Fields>;
  firmware_mutation_response: ResolverTypeWrapper<Firmware_Mutation_Response>;
  firmware_obj_rel_insert_input: Firmware_Obj_Rel_Insert_Input;
  firmware_on_conflict: Firmware_On_Conflict;
  firmware_order_by: Firmware_Order_By;
  firmware_pk_columns_input: Firmware_Pk_Columns_Input;
  firmware_prepend_input: Firmware_Prepend_Input;
  firmware_select_column: Firmware_Select_Column;
  firmware_set_input: Firmware_Set_Input;
  firmware_stream_cursor_input: Firmware_Stream_Cursor_Input;
  firmware_stream_cursor_value_input: Firmware_Stream_Cursor_Value_Input;
  firmware_update_column: Firmware_Update_Column;
  firmware_updates: Firmware_Updates;
  float8: ResolverTypeWrapper<Scalars['float8']>;
  float8_comparison_exp: Float8_Comparison_Exp;
  ground_truth_configurations: ResolverTypeWrapper<Ground_Truth_Configurations>;
  ground_truth_configurations_aggregate: ResolverTypeWrapper<Ground_Truth_Configurations_Aggregate>;
  ground_truth_configurations_aggregate_fields: ResolverTypeWrapper<Ground_Truth_Configurations_Aggregate_Fields>;
  ground_truth_configurations_aggregate_order_by: Ground_Truth_Configurations_Aggregate_Order_By;
  ground_truth_configurations_append_input: Ground_Truth_Configurations_Append_Input;
  ground_truth_configurations_bool_exp: Ground_Truth_Configurations_Bool_Exp;
  ground_truth_configurations_constraint: Ground_Truth_Configurations_Constraint;
  ground_truth_configurations_delete_at_path_input: Ground_Truth_Configurations_Delete_At_Path_Input;
  ground_truth_configurations_delete_elem_input: Ground_Truth_Configurations_Delete_Elem_Input;
  ground_truth_configurations_delete_key_input: Ground_Truth_Configurations_Delete_Key_Input;
  ground_truth_configurations_insert_input: Ground_Truth_Configurations_Insert_Input;
  ground_truth_configurations_max_fields: ResolverTypeWrapper<Ground_Truth_Configurations_Max_Fields>;
  ground_truth_configurations_max_order_by: Ground_Truth_Configurations_Max_Order_By;
  ground_truth_configurations_min_fields: ResolverTypeWrapper<Ground_Truth_Configurations_Min_Fields>;
  ground_truth_configurations_min_order_by: Ground_Truth_Configurations_Min_Order_By;
  ground_truth_configurations_mutation_response: ResolverTypeWrapper<Ground_Truth_Configurations_Mutation_Response>;
  ground_truth_configurations_obj_rel_insert_input: Ground_Truth_Configurations_Obj_Rel_Insert_Input;
  ground_truth_configurations_on_conflict: Ground_Truth_Configurations_On_Conflict;
  ground_truth_configurations_order_by: Ground_Truth_Configurations_Order_By;
  ground_truth_configurations_pk_columns_input: Ground_Truth_Configurations_Pk_Columns_Input;
  ground_truth_configurations_prepend_input: Ground_Truth_Configurations_Prepend_Input;
  ground_truth_configurations_select_column: Ground_Truth_Configurations_Select_Column;
  ground_truth_configurations_set_input: Ground_Truth_Configurations_Set_Input;
  ground_truth_configurations_stream_cursor_input: Ground_Truth_Configurations_Stream_Cursor_Input;
  ground_truth_configurations_stream_cursor_value_input: Ground_Truth_Configurations_Stream_Cursor_Value_Input;
  ground_truth_configurations_update_column: Ground_Truth_Configurations_Update_Column;
  ground_truth_configurations_updates: Ground_Truth_Configurations_Updates;
  ground_truth_device_types: ResolverTypeWrapper<Ground_Truth_Device_Types>;
  ground_truth_device_types_aggregate: ResolverTypeWrapper<Ground_Truth_Device_Types_Aggregate>;
  ground_truth_device_types_aggregate_fields: ResolverTypeWrapper<Ground_Truth_Device_Types_Aggregate_Fields>;
  ground_truth_device_types_bool_exp: Ground_Truth_Device_Types_Bool_Exp;
  ground_truth_device_types_enum: Ground_Truth_Device_Types_Enum;
  ground_truth_device_types_enum_comparison_exp: Ground_Truth_Device_Types_Enum_Comparison_Exp;
  ground_truth_device_types_max_fields: ResolverTypeWrapper<Ground_Truth_Device_Types_Max_Fields>;
  ground_truth_device_types_min_fields: ResolverTypeWrapper<Ground_Truth_Device_Types_Min_Fields>;
  ground_truth_device_types_mutation_response: ResolverTypeWrapper<Ground_Truth_Device_Types_Mutation_Response>;
  ground_truth_device_types_order_by: Ground_Truth_Device_Types_Order_By;
  ground_truth_device_types_pk_columns_input: Ground_Truth_Device_Types_Pk_Columns_Input;
  ground_truth_device_types_select_column: Ground_Truth_Device_Types_Select_Column;
  ground_truth_device_types_set_input: Ground_Truth_Device_Types_Set_Input;
  ground_truth_device_types_stream_cursor_input: Ground_Truth_Device_Types_Stream_Cursor_Input;
  ground_truth_device_types_stream_cursor_value_input: Ground_Truth_Device_Types_Stream_Cursor_Value_Input;
  ground_truth_device_types_updates: Ground_Truth_Device_Types_Updates;
  ground_truth_processing_modes: ResolverTypeWrapper<Ground_Truth_Processing_Modes>;
  ground_truth_processing_modes_aggregate: ResolverTypeWrapper<Ground_Truth_Processing_Modes_Aggregate>;
  ground_truth_processing_modes_aggregate_fields: ResolverTypeWrapper<Ground_Truth_Processing_Modes_Aggregate_Fields>;
  ground_truth_processing_modes_bool_exp: Ground_Truth_Processing_Modes_Bool_Exp;
  ground_truth_processing_modes_enum: Ground_Truth_Processing_Modes_Enum;
  ground_truth_processing_modes_enum_comparison_exp: Ground_Truth_Processing_Modes_Enum_Comparison_Exp;
  ground_truth_processing_modes_max_fields: ResolverTypeWrapper<Ground_Truth_Processing_Modes_Max_Fields>;
  ground_truth_processing_modes_min_fields: ResolverTypeWrapper<Ground_Truth_Processing_Modes_Min_Fields>;
  ground_truth_processing_modes_mutation_response: ResolverTypeWrapper<Ground_Truth_Processing_Modes_Mutation_Response>;
  ground_truth_processing_modes_order_by: Ground_Truth_Processing_Modes_Order_By;
  ground_truth_processing_modes_pk_columns_input: Ground_Truth_Processing_Modes_Pk_Columns_Input;
  ground_truth_processing_modes_select_column: Ground_Truth_Processing_Modes_Select_Column;
  ground_truth_processing_modes_set_input: Ground_Truth_Processing_Modes_Set_Input;
  ground_truth_processing_modes_stream_cursor_input: Ground_Truth_Processing_Modes_Stream_Cursor_Input;
  ground_truth_processing_modes_stream_cursor_value_input: Ground_Truth_Processing_Modes_Stream_Cursor_Value_Input;
  ground_truth_processing_modes_updates: Ground_Truth_Processing_Modes_Updates;
  ground_truth_recordings: ResolverTypeWrapper<Ground_Truth_Recordings>;
  ground_truth_recordings_aggregate: ResolverTypeWrapper<Ground_Truth_Recordings_Aggregate>;
  ground_truth_recordings_aggregate_fields: ResolverTypeWrapper<Ground_Truth_Recordings_Aggregate_Fields>;
  ground_truth_recordings_aggregate_order_by: Ground_Truth_Recordings_Aggregate_Order_By;
  ground_truth_recordings_arr_rel_insert_input: Ground_Truth_Recordings_Arr_Rel_Insert_Input;
  ground_truth_recordings_avg_fields: ResolverTypeWrapper<Ground_Truth_Recordings_Avg_Fields>;
  ground_truth_recordings_avg_order_by: Ground_Truth_Recordings_Avg_Order_By;
  ground_truth_recordings_bool_exp: Ground_Truth_Recordings_Bool_Exp;
  ground_truth_recordings_constraint: Ground_Truth_Recordings_Constraint;
  ground_truth_recordings_inc_input: Ground_Truth_Recordings_Inc_Input;
  ground_truth_recordings_insert_input: Ground_Truth_Recordings_Insert_Input;
  ground_truth_recordings_max_fields: ResolverTypeWrapper<Ground_Truth_Recordings_Max_Fields>;
  ground_truth_recordings_max_order_by: Ground_Truth_Recordings_Max_Order_By;
  ground_truth_recordings_min_fields: ResolverTypeWrapper<Ground_Truth_Recordings_Min_Fields>;
  ground_truth_recordings_min_order_by: Ground_Truth_Recordings_Min_Order_By;
  ground_truth_recordings_mutation_response: ResolverTypeWrapper<Ground_Truth_Recordings_Mutation_Response>;
  ground_truth_recordings_obj_rel_insert_input: Ground_Truth_Recordings_Obj_Rel_Insert_Input;
  ground_truth_recordings_on_conflict: Ground_Truth_Recordings_On_Conflict;
  ground_truth_recordings_order_by: Ground_Truth_Recordings_Order_By;
  ground_truth_recordings_pk_columns_input: Ground_Truth_Recordings_Pk_Columns_Input;
  ground_truth_recordings_select_column: Ground_Truth_Recordings_Select_Column;
  ground_truth_recordings_set_input: Ground_Truth_Recordings_Set_Input;
  ground_truth_recordings_stddev_fields: ResolverTypeWrapper<Ground_Truth_Recordings_Stddev_Fields>;
  ground_truth_recordings_stddev_order_by: Ground_Truth_Recordings_Stddev_Order_By;
  ground_truth_recordings_stddev_pop_fields: ResolverTypeWrapper<Ground_Truth_Recordings_Stddev_Pop_Fields>;
  ground_truth_recordings_stddev_pop_order_by: Ground_Truth_Recordings_Stddev_Pop_Order_By;
  ground_truth_recordings_stddev_samp_fields: ResolverTypeWrapper<Ground_Truth_Recordings_Stddev_Samp_Fields>;
  ground_truth_recordings_stddev_samp_order_by: Ground_Truth_Recordings_Stddev_Samp_Order_By;
  ground_truth_recordings_stream_cursor_input: Ground_Truth_Recordings_Stream_Cursor_Input;
  ground_truth_recordings_stream_cursor_value_input: Ground_Truth_Recordings_Stream_Cursor_Value_Input;
  ground_truth_recordings_sum_fields: ResolverTypeWrapper<Ground_Truth_Recordings_Sum_Fields>;
  ground_truth_recordings_sum_order_by: Ground_Truth_Recordings_Sum_Order_By;
  ground_truth_recordings_update_column: Ground_Truth_Recordings_Update_Column;
  ground_truth_recordings_updates: Ground_Truth_Recordings_Updates;
  ground_truth_recordings_var_pop_fields: ResolverTypeWrapper<Ground_Truth_Recordings_Var_Pop_Fields>;
  ground_truth_recordings_var_pop_order_by: Ground_Truth_Recordings_Var_Pop_Order_By;
  ground_truth_recordings_var_samp_fields: ResolverTypeWrapper<Ground_Truth_Recordings_Var_Samp_Fields>;
  ground_truth_recordings_var_samp_order_by: Ground_Truth_Recordings_Var_Samp_Order_By;
  ground_truth_recordings_variance_fields: ResolverTypeWrapper<Ground_Truth_Recordings_Variance_Fields>;
  ground_truth_recordings_variance_order_by: Ground_Truth_Recordings_Variance_Order_By;
  ground_truth_types: ResolverTypeWrapper<Ground_Truth_Types>;
  ground_truth_types_aggregate: ResolverTypeWrapper<Ground_Truth_Types_Aggregate>;
  ground_truth_types_aggregate_fields: ResolverTypeWrapper<Ground_Truth_Types_Aggregate_Fields>;
  ground_truth_types_avg_fields: ResolverTypeWrapper<Ground_Truth_Types_Avg_Fields>;
  ground_truth_types_bool_exp: Ground_Truth_Types_Bool_Exp;
  ground_truth_types_constraint: Ground_Truth_Types_Constraint;
  ground_truth_types_inc_input: Ground_Truth_Types_Inc_Input;
  ground_truth_types_insert_input: Ground_Truth_Types_Insert_Input;
  ground_truth_types_max_fields: ResolverTypeWrapper<Ground_Truth_Types_Max_Fields>;
  ground_truth_types_min_fields: ResolverTypeWrapper<Ground_Truth_Types_Min_Fields>;
  ground_truth_types_mutation_response: ResolverTypeWrapper<Ground_Truth_Types_Mutation_Response>;
  ground_truth_types_obj_rel_insert_input: Ground_Truth_Types_Obj_Rel_Insert_Input;
  ground_truth_types_on_conflict: Ground_Truth_Types_On_Conflict;
  ground_truth_types_order_by: Ground_Truth_Types_Order_By;
  ground_truth_types_pk_columns_input: Ground_Truth_Types_Pk_Columns_Input;
  ground_truth_types_select_column: Ground_Truth_Types_Select_Column;
  ground_truth_types_set_input: Ground_Truth_Types_Set_Input;
  ground_truth_types_stddev_fields: ResolverTypeWrapper<Ground_Truth_Types_Stddev_Fields>;
  ground_truth_types_stddev_pop_fields: ResolverTypeWrapper<Ground_Truth_Types_Stddev_Pop_Fields>;
  ground_truth_types_stddev_samp_fields: ResolverTypeWrapper<Ground_Truth_Types_Stddev_Samp_Fields>;
  ground_truth_types_stream_cursor_input: Ground_Truth_Types_Stream_Cursor_Input;
  ground_truth_types_stream_cursor_value_input: Ground_Truth_Types_Stream_Cursor_Value_Input;
  ground_truth_types_sum_fields: ResolverTypeWrapper<Ground_Truth_Types_Sum_Fields>;
  ground_truth_types_update_column: Ground_Truth_Types_Update_Column;
  ground_truth_types_updates: Ground_Truth_Types_Updates;
  ground_truth_types_var_pop_fields: ResolverTypeWrapper<Ground_Truth_Types_Var_Pop_Fields>;
  ground_truth_types_var_samp_fields: ResolverTypeWrapper<Ground_Truth_Types_Var_Samp_Fields>;
  ground_truth_types_variance_fields: ResolverTypeWrapper<Ground_Truth_Types_Variance_Fields>;
  hypertask_field_option: ResolverTypeWrapper<Hypertask_Field_Option>;
  hypertask_field_option_aggregate: ResolverTypeWrapper<Hypertask_Field_Option_Aggregate>;
  hypertask_field_option_aggregate_fields: ResolverTypeWrapper<Hypertask_Field_Option_Aggregate_Fields>;
  hypertask_field_option_aggregate_order_by: Hypertask_Field_Option_Aggregate_Order_By;
  hypertask_field_option_arr_rel_insert_input: Hypertask_Field_Option_Arr_Rel_Insert_Input;
  hypertask_field_option_avg_fields: ResolverTypeWrapper<Hypertask_Field_Option_Avg_Fields>;
  hypertask_field_option_avg_order_by: Hypertask_Field_Option_Avg_Order_By;
  hypertask_field_option_bool_exp: Hypertask_Field_Option_Bool_Exp;
  hypertask_field_option_constraint: Hypertask_Field_Option_Constraint;
  hypertask_field_option_inc_input: Hypertask_Field_Option_Inc_Input;
  hypertask_field_option_insert_input: Hypertask_Field_Option_Insert_Input;
  hypertask_field_option_max_fields: ResolverTypeWrapper<Hypertask_Field_Option_Max_Fields>;
  hypertask_field_option_max_order_by: Hypertask_Field_Option_Max_Order_By;
  hypertask_field_option_min_fields: ResolverTypeWrapper<Hypertask_Field_Option_Min_Fields>;
  hypertask_field_option_min_order_by: Hypertask_Field_Option_Min_Order_By;
  hypertask_field_option_mutation_response: ResolverTypeWrapper<Hypertask_Field_Option_Mutation_Response>;
  hypertask_field_option_on_conflict: Hypertask_Field_Option_On_Conflict;
  hypertask_field_option_order_by: Hypertask_Field_Option_Order_By;
  hypertask_field_option_pk_columns_input: Hypertask_Field_Option_Pk_Columns_Input;
  hypertask_field_option_select_column: Hypertask_Field_Option_Select_Column;
  hypertask_field_option_set_input: Hypertask_Field_Option_Set_Input;
  hypertask_field_option_stddev_fields: ResolverTypeWrapper<Hypertask_Field_Option_Stddev_Fields>;
  hypertask_field_option_stddev_order_by: Hypertask_Field_Option_Stddev_Order_By;
  hypertask_field_option_stddev_pop_fields: ResolverTypeWrapper<Hypertask_Field_Option_Stddev_Pop_Fields>;
  hypertask_field_option_stddev_pop_order_by: Hypertask_Field_Option_Stddev_Pop_Order_By;
  hypertask_field_option_stddev_samp_fields: ResolverTypeWrapper<Hypertask_Field_Option_Stddev_Samp_Fields>;
  hypertask_field_option_stddev_samp_order_by: Hypertask_Field_Option_Stddev_Samp_Order_By;
  hypertask_field_option_stream_cursor_input: Hypertask_Field_Option_Stream_Cursor_Input;
  hypertask_field_option_stream_cursor_value_input: Hypertask_Field_Option_Stream_Cursor_Value_Input;
  hypertask_field_option_sum_fields: ResolverTypeWrapper<Hypertask_Field_Option_Sum_Fields>;
  hypertask_field_option_sum_order_by: Hypertask_Field_Option_Sum_Order_By;
  hypertask_field_option_update_column: Hypertask_Field_Option_Update_Column;
  hypertask_field_option_updates: Hypertask_Field_Option_Updates;
  hypertask_field_option_var_pop_fields: ResolverTypeWrapper<Hypertask_Field_Option_Var_Pop_Fields>;
  hypertask_field_option_var_pop_order_by: Hypertask_Field_Option_Var_Pop_Order_By;
  hypertask_field_option_var_samp_fields: ResolverTypeWrapper<Hypertask_Field_Option_Var_Samp_Fields>;
  hypertask_field_option_var_samp_order_by: Hypertask_Field_Option_Var_Samp_Order_By;
  hypertask_field_option_variance_fields: ResolverTypeWrapper<Hypertask_Field_Option_Variance_Fields>;
  hypertask_field_option_variance_order_by: Hypertask_Field_Option_Variance_Order_By;
  hypertask_task: ResolverTypeWrapper<Hypertask_Task>;
  hypertask_task_aggregate: ResolverTypeWrapper<Hypertask_Task_Aggregate>;
  hypertask_task_aggregate_fields: ResolverTypeWrapper<Hypertask_Task_Aggregate_Fields>;
  hypertask_task_aggregate_order_by: Hypertask_Task_Aggregate_Order_By;
  hypertask_task_arr_rel_insert_input: Hypertask_Task_Arr_Rel_Insert_Input;
  hypertask_task_bool_exp: Hypertask_Task_Bool_Exp;
  hypertask_task_constraint: Hypertask_Task_Constraint;
  hypertask_task_insert_input: Hypertask_Task_Insert_Input;
  hypertask_task_max_fields: ResolverTypeWrapper<Hypertask_Task_Max_Fields>;
  hypertask_task_max_order_by: Hypertask_Task_Max_Order_By;
  hypertask_task_min_fields: ResolverTypeWrapper<Hypertask_Task_Min_Fields>;
  hypertask_task_min_order_by: Hypertask_Task_Min_Order_By;
  hypertask_task_mutation_response: ResolverTypeWrapper<Hypertask_Task_Mutation_Response>;
  hypertask_task_on_conflict: Hypertask_Task_On_Conflict;
  hypertask_task_order_by: Hypertask_Task_Order_By;
  hypertask_task_pk_columns_input: Hypertask_Task_Pk_Columns_Input;
  hypertask_task_select_column: Hypertask_Task_Select_Column;
  hypertask_task_set_input: Hypertask_Task_Set_Input;
  hypertask_task_stream_cursor_input: Hypertask_Task_Stream_Cursor_Input;
  hypertask_task_stream_cursor_value_input: Hypertask_Task_Stream_Cursor_Value_Input;
  hypertask_task_update_column: Hypertask_Task_Update_Column;
  hypertask_task_updates: Hypertask_Task_Updates;
  hypertasks: ResolverTypeWrapper<Hypertasks>;
  hypertasks_aggregate: ResolverTypeWrapper<Hypertasks_Aggregate>;
  hypertasks_aggregate_fields: ResolverTypeWrapper<Hypertasks_Aggregate_Fields>;
  hypertasks_avg_fields: ResolverTypeWrapper<Hypertasks_Avg_Fields>;
  hypertasks_bool_exp: Hypertasks_Bool_Exp;
  hypertasks_constraint: Hypertasks_Constraint;
  hypertasks_inc_input: Hypertasks_Inc_Input;
  hypertasks_insert_input: Hypertasks_Insert_Input;
  hypertasks_max_fields: ResolverTypeWrapper<Hypertasks_Max_Fields>;
  hypertasks_min_fields: ResolverTypeWrapper<Hypertasks_Min_Fields>;
  hypertasks_mutation_response: ResolverTypeWrapper<Hypertasks_Mutation_Response>;
  hypertasks_obj_rel_insert_input: Hypertasks_Obj_Rel_Insert_Input;
  hypertasks_on_conflict: Hypertasks_On_Conflict;
  hypertasks_order_by: Hypertasks_Order_By;
  hypertasks_pk_columns_input: Hypertasks_Pk_Columns_Input;
  hypertasks_select_column: Hypertasks_Select_Column;
  hypertasks_set_input: Hypertasks_Set_Input;
  hypertasks_stddev_fields: ResolverTypeWrapper<Hypertasks_Stddev_Fields>;
  hypertasks_stddev_pop_fields: ResolverTypeWrapper<Hypertasks_Stddev_Pop_Fields>;
  hypertasks_stddev_samp_fields: ResolverTypeWrapper<Hypertasks_Stddev_Samp_Fields>;
  hypertasks_stream_cursor_input: Hypertasks_Stream_Cursor_Input;
  hypertasks_stream_cursor_value_input: Hypertasks_Stream_Cursor_Value_Input;
  hypertasks_sum_fields: ResolverTypeWrapper<Hypertasks_Sum_Fields>;
  hypertasks_update_column: Hypertasks_Update_Column;
  hypertasks_updates: Hypertasks_Updates;
  hypertasks_var_pop_fields: ResolverTypeWrapper<Hypertasks_Var_Pop_Fields>;
  hypertasks_var_samp_fields: ResolverTypeWrapper<Hypertasks_Var_Samp_Fields>;
  hypertasks_variance_fields: ResolverTypeWrapper<Hypertasks_Variance_Fields>;
  instruction_translations: ResolverTypeWrapper<Instruction_Translations>;
  instruction_translations_aggregate_order_by: Instruction_Translations_Aggregate_Order_By;
  instruction_translations_arr_rel_insert_input: Instruction_Translations_Arr_Rel_Insert_Input;
  instruction_translations_bool_exp: Instruction_Translations_Bool_Exp;
  instruction_translations_constraint: Instruction_Translations_Constraint;
  instruction_translations_insert_input: Instruction_Translations_Insert_Input;
  instruction_translations_max_order_by: Instruction_Translations_Max_Order_By;
  instruction_translations_min_order_by: Instruction_Translations_Min_Order_By;
  instruction_translations_mutation_response: ResolverTypeWrapper<Instruction_Translations_Mutation_Response>;
  instruction_translations_on_conflict: Instruction_Translations_On_Conflict;
  instruction_translations_order_by: Instruction_Translations_Order_By;
  instruction_translations_pk_columns_input: Instruction_Translations_Pk_Columns_Input;
  instruction_translations_select_column: Instruction_Translations_Select_Column;
  instruction_translations_set_input: Instruction_Translations_Set_Input;
  instruction_translations_stream_cursor_input: Instruction_Translations_Stream_Cursor_Input;
  instruction_translations_stream_cursor_value_input: Instruction_Translations_Stream_Cursor_Value_Input;
  instruction_translations_update_column: Instruction_Translations_Update_Column;
  instruction_translations_updates: Instruction_Translations_Updates;
  integration_test_expectation_media: ResolverTypeWrapper<Integration_Test_Expectation_Media>;
  integration_test_expectation_media_aggregate: ResolverTypeWrapper<Integration_Test_Expectation_Media_Aggregate>;
  integration_test_expectation_media_aggregate_fields: ResolverTypeWrapper<Integration_Test_Expectation_Media_Aggregate_Fields>;
  integration_test_expectation_media_aggregate_order_by: Integration_Test_Expectation_Media_Aggregate_Order_By;
  integration_test_expectation_media_arr_rel_insert_input: Integration_Test_Expectation_Media_Arr_Rel_Insert_Input;
  integration_test_expectation_media_bool_exp: Integration_Test_Expectation_Media_Bool_Exp;
  integration_test_expectation_media_constraint: Integration_Test_Expectation_Media_Constraint;
  integration_test_expectation_media_insert_input: Integration_Test_Expectation_Media_Insert_Input;
  integration_test_expectation_media_max_fields: ResolverTypeWrapper<Integration_Test_Expectation_Media_Max_Fields>;
  integration_test_expectation_media_max_order_by: Integration_Test_Expectation_Media_Max_Order_By;
  integration_test_expectation_media_min_fields: ResolverTypeWrapper<Integration_Test_Expectation_Media_Min_Fields>;
  integration_test_expectation_media_min_order_by: Integration_Test_Expectation_Media_Min_Order_By;
  integration_test_expectation_media_mutation_response: ResolverTypeWrapper<Integration_Test_Expectation_Media_Mutation_Response>;
  integration_test_expectation_media_on_conflict: Integration_Test_Expectation_Media_On_Conflict;
  integration_test_expectation_media_order_by: Integration_Test_Expectation_Media_Order_By;
  integration_test_expectation_media_pk_columns_input: Integration_Test_Expectation_Media_Pk_Columns_Input;
  integration_test_expectation_media_select_column: Integration_Test_Expectation_Media_Select_Column;
  integration_test_expectation_media_set_input: Integration_Test_Expectation_Media_Set_Input;
  integration_test_expectation_media_stream_cursor_input: Integration_Test_Expectation_Media_Stream_Cursor_Input;
  integration_test_expectation_media_stream_cursor_value_input: Integration_Test_Expectation_Media_Stream_Cursor_Value_Input;
  integration_test_expectation_media_update_column: Integration_Test_Expectation_Media_Update_Column;
  integration_test_expectation_media_updates: Integration_Test_Expectation_Media_Updates;
  integration_test_expectation_step: ResolverTypeWrapper<Integration_Test_Expectation_Step>;
  integration_test_expectation_step_aggregate: ResolverTypeWrapper<Integration_Test_Expectation_Step_Aggregate>;
  integration_test_expectation_step_aggregate_fields: ResolverTypeWrapper<Integration_Test_Expectation_Step_Aggregate_Fields>;
  integration_test_expectation_step_aggregate_order_by: Integration_Test_Expectation_Step_Aggregate_Order_By;
  integration_test_expectation_step_arr_rel_insert_input: Integration_Test_Expectation_Step_Arr_Rel_Insert_Input;
  integration_test_expectation_step_bool_exp: Integration_Test_Expectation_Step_Bool_Exp;
  integration_test_expectation_step_constraint: Integration_Test_Expectation_Step_Constraint;
  integration_test_expectation_step_insert_input: Integration_Test_Expectation_Step_Insert_Input;
  integration_test_expectation_step_max_fields: ResolverTypeWrapper<Integration_Test_Expectation_Step_Max_Fields>;
  integration_test_expectation_step_max_order_by: Integration_Test_Expectation_Step_Max_Order_By;
  integration_test_expectation_step_min_fields: ResolverTypeWrapper<Integration_Test_Expectation_Step_Min_Fields>;
  integration_test_expectation_step_min_order_by: Integration_Test_Expectation_Step_Min_Order_By;
  integration_test_expectation_step_mutation_response: ResolverTypeWrapper<Integration_Test_Expectation_Step_Mutation_Response>;
  integration_test_expectation_step_obj_rel_insert_input: Integration_Test_Expectation_Step_Obj_Rel_Insert_Input;
  integration_test_expectation_step_on_conflict: Integration_Test_Expectation_Step_On_Conflict;
  integration_test_expectation_step_order_by: Integration_Test_Expectation_Step_Order_By;
  integration_test_expectation_step_select_column: Integration_Test_Expectation_Step_Select_Column;
  integration_test_expectation_step_stream_cursor_input: Integration_Test_Expectation_Step_Stream_Cursor_Input;
  integration_test_expectation_step_stream_cursor_value_input: Integration_Test_Expectation_Step_Stream_Cursor_Value_Input;
  integration_test_expectation_step_update_column: Integration_Test_Expectation_Step_Update_Column;
  integration_test_expectation_translation: ResolverTypeWrapper<Integration_Test_Expectation_Translation>;
  integration_test_expectation_translation_aggregate: ResolverTypeWrapper<Integration_Test_Expectation_Translation_Aggregate>;
  integration_test_expectation_translation_aggregate_fields: ResolverTypeWrapper<Integration_Test_Expectation_Translation_Aggregate_Fields>;
  integration_test_expectation_translation_aggregate_order_by: Integration_Test_Expectation_Translation_Aggregate_Order_By;
  integration_test_expectation_translation_arr_rel_insert_input: Integration_Test_Expectation_Translation_Arr_Rel_Insert_Input;
  integration_test_expectation_translation_bool_exp: Integration_Test_Expectation_Translation_Bool_Exp;
  integration_test_expectation_translation_constraint: Integration_Test_Expectation_Translation_Constraint;
  integration_test_expectation_translation_insert_input: Integration_Test_Expectation_Translation_Insert_Input;
  integration_test_expectation_translation_max_fields: ResolverTypeWrapper<Integration_Test_Expectation_Translation_Max_Fields>;
  integration_test_expectation_translation_max_order_by: Integration_Test_Expectation_Translation_Max_Order_By;
  integration_test_expectation_translation_min_fields: ResolverTypeWrapper<Integration_Test_Expectation_Translation_Min_Fields>;
  integration_test_expectation_translation_min_order_by: Integration_Test_Expectation_Translation_Min_Order_By;
  integration_test_expectation_translation_mutation_response: ResolverTypeWrapper<Integration_Test_Expectation_Translation_Mutation_Response>;
  integration_test_expectation_translation_on_conflict: Integration_Test_Expectation_Translation_On_Conflict;
  integration_test_expectation_translation_order_by: Integration_Test_Expectation_Translation_Order_By;
  integration_test_expectation_translation_pk_columns_input: Integration_Test_Expectation_Translation_Pk_Columns_Input;
  integration_test_expectation_translation_select_column: Integration_Test_Expectation_Translation_Select_Column;
  integration_test_expectation_translation_set_input: Integration_Test_Expectation_Translation_Set_Input;
  integration_test_expectation_translation_stream_cursor_input: Integration_Test_Expectation_Translation_Stream_Cursor_Input;
  integration_test_expectation_translation_stream_cursor_value_input: Integration_Test_Expectation_Translation_Stream_Cursor_Value_Input;
  integration_test_expectation_translation_update_column: Integration_Test_Expectation_Translation_Update_Column;
  integration_test_expectation_translation_updates: Integration_Test_Expectation_Translation_Updates;
  integration_test_expectations: ResolverTypeWrapper<Integration_Test_Expectations>;
  integration_test_expectations_aggregate: ResolverTypeWrapper<Integration_Test_Expectations_Aggregate>;
  integration_test_expectations_aggregate_fields: ResolverTypeWrapper<Integration_Test_Expectations_Aggregate_Fields>;
  integration_test_expectations_bool_exp: Integration_Test_Expectations_Bool_Exp;
  integration_test_expectations_constraint: Integration_Test_Expectations_Constraint;
  integration_test_expectations_insert_input: Integration_Test_Expectations_Insert_Input;
  integration_test_expectations_max_fields: ResolverTypeWrapper<Integration_Test_Expectations_Max_Fields>;
  integration_test_expectations_min_fields: ResolverTypeWrapper<Integration_Test_Expectations_Min_Fields>;
  integration_test_expectations_mutation_response: ResolverTypeWrapper<Integration_Test_Expectations_Mutation_Response>;
  integration_test_expectations_obj_rel_insert_input: Integration_Test_Expectations_Obj_Rel_Insert_Input;
  integration_test_expectations_on_conflict: Integration_Test_Expectations_On_Conflict;
  integration_test_expectations_order_by: Integration_Test_Expectations_Order_By;
  integration_test_expectations_pk_columns_input: Integration_Test_Expectations_Pk_Columns_Input;
  integration_test_expectations_select_column: Integration_Test_Expectations_Select_Column;
  integration_test_expectations_set_input: Integration_Test_Expectations_Set_Input;
  integration_test_expectations_stream_cursor_input: Integration_Test_Expectations_Stream_Cursor_Input;
  integration_test_expectations_stream_cursor_value_input: Integration_Test_Expectations_Stream_Cursor_Value_Input;
  integration_test_expectations_update_column: Integration_Test_Expectations_Update_Column;
  integration_test_expectations_updates: Integration_Test_Expectations_Updates;
  integration_test_instruction_media: ResolverTypeWrapper<Integration_Test_Instruction_Media>;
  integration_test_instruction_media_aggregate: ResolverTypeWrapper<Integration_Test_Instruction_Media_Aggregate>;
  integration_test_instruction_media_aggregate_fields: ResolverTypeWrapper<Integration_Test_Instruction_Media_Aggregate_Fields>;
  integration_test_instruction_media_aggregate_order_by: Integration_Test_Instruction_Media_Aggregate_Order_By;
  integration_test_instruction_media_arr_rel_insert_input: Integration_Test_Instruction_Media_Arr_Rel_Insert_Input;
  integration_test_instruction_media_bool_exp: Integration_Test_Instruction_Media_Bool_Exp;
  integration_test_instruction_media_constraint: Integration_Test_Instruction_Media_Constraint;
  integration_test_instruction_media_insert_input: Integration_Test_Instruction_Media_Insert_Input;
  integration_test_instruction_media_max_fields: ResolverTypeWrapper<Integration_Test_Instruction_Media_Max_Fields>;
  integration_test_instruction_media_max_order_by: Integration_Test_Instruction_Media_Max_Order_By;
  integration_test_instruction_media_min_fields: ResolverTypeWrapper<Integration_Test_Instruction_Media_Min_Fields>;
  integration_test_instruction_media_min_order_by: Integration_Test_Instruction_Media_Min_Order_By;
  integration_test_instruction_media_mutation_response: ResolverTypeWrapper<Integration_Test_Instruction_Media_Mutation_Response>;
  integration_test_instruction_media_on_conflict: Integration_Test_Instruction_Media_On_Conflict;
  integration_test_instruction_media_order_by: Integration_Test_Instruction_Media_Order_By;
  integration_test_instruction_media_pk_columns_input: Integration_Test_Instruction_Media_Pk_Columns_Input;
  integration_test_instruction_media_select_column: Integration_Test_Instruction_Media_Select_Column;
  integration_test_instruction_media_set_input: Integration_Test_Instruction_Media_Set_Input;
  integration_test_instruction_media_stream_cursor_input: Integration_Test_Instruction_Media_Stream_Cursor_Input;
  integration_test_instruction_media_stream_cursor_value_input: Integration_Test_Instruction_Media_Stream_Cursor_Value_Input;
  integration_test_instruction_media_update_column: Integration_Test_Instruction_Media_Update_Column;
  integration_test_instruction_media_updates: Integration_Test_Instruction_Media_Updates;
  integration_test_instruction_translation: ResolverTypeWrapper<Integration_Test_Instruction_Translation>;
  integration_test_instruction_translation_aggregate: ResolverTypeWrapper<Integration_Test_Instruction_Translation_Aggregate>;
  integration_test_instruction_translation_aggregate_fields: ResolverTypeWrapper<Integration_Test_Instruction_Translation_Aggregate_Fields>;
  integration_test_instruction_translation_aggregate_order_by: Integration_Test_Instruction_Translation_Aggregate_Order_By;
  integration_test_instruction_translation_arr_rel_insert_input: Integration_Test_Instruction_Translation_Arr_Rel_Insert_Input;
  integration_test_instruction_translation_bool_exp: Integration_Test_Instruction_Translation_Bool_Exp;
  integration_test_instruction_translation_constraint: Integration_Test_Instruction_Translation_Constraint;
  integration_test_instruction_translation_insert_input: Integration_Test_Instruction_Translation_Insert_Input;
  integration_test_instruction_translation_max_fields: ResolverTypeWrapper<Integration_Test_Instruction_Translation_Max_Fields>;
  integration_test_instruction_translation_max_order_by: Integration_Test_Instruction_Translation_Max_Order_By;
  integration_test_instruction_translation_min_fields: ResolverTypeWrapper<Integration_Test_Instruction_Translation_Min_Fields>;
  integration_test_instruction_translation_min_order_by: Integration_Test_Instruction_Translation_Min_Order_By;
  integration_test_instruction_translation_mutation_response: ResolverTypeWrapper<Integration_Test_Instruction_Translation_Mutation_Response>;
  integration_test_instruction_translation_on_conflict: Integration_Test_Instruction_Translation_On_Conflict;
  integration_test_instruction_translation_order_by: Integration_Test_Instruction_Translation_Order_By;
  integration_test_instruction_translation_pk_columns_input: Integration_Test_Instruction_Translation_Pk_Columns_Input;
  integration_test_instruction_translation_select_column: Integration_Test_Instruction_Translation_Select_Column;
  integration_test_instruction_translation_set_input: Integration_Test_Instruction_Translation_Set_Input;
  integration_test_instruction_translation_stream_cursor_input: Integration_Test_Instruction_Translation_Stream_Cursor_Input;
  integration_test_instruction_translation_stream_cursor_value_input: Integration_Test_Instruction_Translation_Stream_Cursor_Value_Input;
  integration_test_instruction_translation_update_column: Integration_Test_Instruction_Translation_Update_Column;
  integration_test_instruction_translation_updates: Integration_Test_Instruction_Translation_Updates;
  integration_test_instructions: ResolverTypeWrapper<Integration_Test_Instructions>;
  integration_test_instructions_aggregate: ResolverTypeWrapper<Integration_Test_Instructions_Aggregate>;
  integration_test_instructions_aggregate_fields: ResolverTypeWrapper<Integration_Test_Instructions_Aggregate_Fields>;
  integration_test_instructions_aggregate_order_by: Integration_Test_Instructions_Aggregate_Order_By;
  integration_test_instructions_arr_rel_insert_input: Integration_Test_Instructions_Arr_Rel_Insert_Input;
  integration_test_instructions_bool_exp: Integration_Test_Instructions_Bool_Exp;
  integration_test_instructions_constraint: Integration_Test_Instructions_Constraint;
  integration_test_instructions_insert_input: Integration_Test_Instructions_Insert_Input;
  integration_test_instructions_max_fields: ResolverTypeWrapper<Integration_Test_Instructions_Max_Fields>;
  integration_test_instructions_max_order_by: Integration_Test_Instructions_Max_Order_By;
  integration_test_instructions_min_fields: ResolverTypeWrapper<Integration_Test_Instructions_Min_Fields>;
  integration_test_instructions_min_order_by: Integration_Test_Instructions_Min_Order_By;
  integration_test_instructions_mutation_response: ResolverTypeWrapper<Integration_Test_Instructions_Mutation_Response>;
  integration_test_instructions_obj_rel_insert_input: Integration_Test_Instructions_Obj_Rel_Insert_Input;
  integration_test_instructions_on_conflict: Integration_Test_Instructions_On_Conflict;
  integration_test_instructions_order_by: Integration_Test_Instructions_Order_By;
  integration_test_instructions_pk_columns_input: Integration_Test_Instructions_Pk_Columns_Input;
  integration_test_instructions_select_column: Integration_Test_Instructions_Select_Column;
  integration_test_instructions_set_input: Integration_Test_Instructions_Set_Input;
  integration_test_instructions_stream_cursor_input: Integration_Test_Instructions_Stream_Cursor_Input;
  integration_test_instructions_stream_cursor_value_input: Integration_Test_Instructions_Stream_Cursor_Value_Input;
  integration_test_instructions_update_column: Integration_Test_Instructions_Update_Column;
  integration_test_instructions_updates: Integration_Test_Instructions_Updates;
  integration_test_observances: ResolverTypeWrapper<Integration_Test_Observances>;
  integration_test_observances_aggregate: ResolverTypeWrapper<Integration_Test_Observances_Aggregate>;
  integration_test_observances_aggregate_fields: ResolverTypeWrapper<Integration_Test_Observances_Aggregate_Fields>;
  integration_test_observances_aggregate_order_by: Integration_Test_Observances_Aggregate_Order_By;
  integration_test_observances_arr_rel_insert_input: Integration_Test_Observances_Arr_Rel_Insert_Input;
  integration_test_observances_bool_exp: Integration_Test_Observances_Bool_Exp;
  integration_test_observances_constraint: Integration_Test_Observances_Constraint;
  integration_test_observances_insert_input: Integration_Test_Observances_Insert_Input;
  integration_test_observances_max_fields: ResolverTypeWrapper<Integration_Test_Observances_Max_Fields>;
  integration_test_observances_max_order_by: Integration_Test_Observances_Max_Order_By;
  integration_test_observances_min_fields: ResolverTypeWrapper<Integration_Test_Observances_Min_Fields>;
  integration_test_observances_min_order_by: Integration_Test_Observances_Min_Order_By;
  integration_test_observances_mutation_response: ResolverTypeWrapper<Integration_Test_Observances_Mutation_Response>;
  integration_test_observances_obj_rel_insert_input: Integration_Test_Observances_Obj_Rel_Insert_Input;
  integration_test_observances_on_conflict: Integration_Test_Observances_On_Conflict;
  integration_test_observances_order_by: Integration_Test_Observances_Order_By;
  integration_test_observances_pk_columns_input: Integration_Test_Observances_Pk_Columns_Input;
  integration_test_observances_select_column: Integration_Test_Observances_Select_Column;
  integration_test_observances_set_input: Integration_Test_Observances_Set_Input;
  integration_test_observances_stream_cursor_input: Integration_Test_Observances_Stream_Cursor_Input;
  integration_test_observances_stream_cursor_value_input: Integration_Test_Observances_Stream_Cursor_Value_Input;
  integration_test_observances_update_column: Integration_Test_Observances_Update_Column;
  integration_test_observances_updates: Integration_Test_Observances_Updates;
  integration_test_observations: ResolverTypeWrapper<Integration_Test_Observations>;
  integration_test_observations_aggregate: ResolverTypeWrapper<Integration_Test_Observations_Aggregate>;
  integration_test_observations_aggregate_fields: ResolverTypeWrapper<Integration_Test_Observations_Aggregate_Fields>;
  integration_test_observations_aggregate_order_by: Integration_Test_Observations_Aggregate_Order_By;
  integration_test_observations_arr_rel_insert_input: Integration_Test_Observations_Arr_Rel_Insert_Input;
  integration_test_observations_bool_exp: Integration_Test_Observations_Bool_Exp;
  integration_test_observations_constraint: Integration_Test_Observations_Constraint;
  integration_test_observations_insert_input: Integration_Test_Observations_Insert_Input;
  integration_test_observations_max_fields: ResolverTypeWrapper<Integration_Test_Observations_Max_Fields>;
  integration_test_observations_max_order_by: Integration_Test_Observations_Max_Order_By;
  integration_test_observations_min_fields: ResolverTypeWrapper<Integration_Test_Observations_Min_Fields>;
  integration_test_observations_min_order_by: Integration_Test_Observations_Min_Order_By;
  integration_test_observations_mutation_response: ResolverTypeWrapper<Integration_Test_Observations_Mutation_Response>;
  integration_test_observations_on_conflict: Integration_Test_Observations_On_Conflict;
  integration_test_observations_order_by: Integration_Test_Observations_Order_By;
  integration_test_observations_select_column: Integration_Test_Observations_Select_Column;
  integration_test_observations_stream_cursor_input: Integration_Test_Observations_Stream_Cursor_Input;
  integration_test_observations_stream_cursor_value_input: Integration_Test_Observations_Stream_Cursor_Value_Input;
  integration_test_observations_update_column: Integration_Test_Observations_Update_Column;
  integration_test_project_sequence: ResolverTypeWrapper<Integration_Test_Project_Sequence>;
  integration_test_project_sequence_aggregate: ResolverTypeWrapper<Integration_Test_Project_Sequence_Aggregate>;
  integration_test_project_sequence_aggregate_fields: ResolverTypeWrapper<Integration_Test_Project_Sequence_Aggregate_Fields>;
  integration_test_project_sequence_aggregate_order_by: Integration_Test_Project_Sequence_Aggregate_Order_By;
  integration_test_project_sequence_arr_rel_insert_input: Integration_Test_Project_Sequence_Arr_Rel_Insert_Input;
  integration_test_project_sequence_bool_exp: Integration_Test_Project_Sequence_Bool_Exp;
  integration_test_project_sequence_constraint: Integration_Test_Project_Sequence_Constraint;
  integration_test_project_sequence_insert_input: Integration_Test_Project_Sequence_Insert_Input;
  integration_test_project_sequence_max_fields: ResolverTypeWrapper<Integration_Test_Project_Sequence_Max_Fields>;
  integration_test_project_sequence_max_order_by: Integration_Test_Project_Sequence_Max_Order_By;
  integration_test_project_sequence_min_fields: ResolverTypeWrapper<Integration_Test_Project_Sequence_Min_Fields>;
  integration_test_project_sequence_min_order_by: Integration_Test_Project_Sequence_Min_Order_By;
  integration_test_project_sequence_mutation_response: ResolverTypeWrapper<Integration_Test_Project_Sequence_Mutation_Response>;
  integration_test_project_sequence_on_conflict: Integration_Test_Project_Sequence_On_Conflict;
  integration_test_project_sequence_order_by: Integration_Test_Project_Sequence_Order_By;
  integration_test_project_sequence_pk_columns_input: Integration_Test_Project_Sequence_Pk_Columns_Input;
  integration_test_project_sequence_select_column: Integration_Test_Project_Sequence_Select_Column;
  integration_test_project_sequence_set_input: Integration_Test_Project_Sequence_Set_Input;
  integration_test_project_sequence_stream_cursor_input: Integration_Test_Project_Sequence_Stream_Cursor_Input;
  integration_test_project_sequence_stream_cursor_value_input: Integration_Test_Project_Sequence_Stream_Cursor_Value_Input;
  integration_test_project_sequence_update_column: Integration_Test_Project_Sequence_Update_Column;
  integration_test_project_sequence_updates: Integration_Test_Project_Sequence_Updates;
  integration_test_sequences: ResolverTypeWrapper<Integration_Test_Sequences>;
  integration_test_sequences_aggregate: ResolverTypeWrapper<Integration_Test_Sequences_Aggregate>;
  integration_test_sequences_aggregate_fields: ResolverTypeWrapper<Integration_Test_Sequences_Aggregate_Fields>;
  integration_test_sequences_bool_exp: Integration_Test_Sequences_Bool_Exp;
  integration_test_sequences_constraint: Integration_Test_Sequences_Constraint;
  integration_test_sequences_insert_input: Integration_Test_Sequences_Insert_Input;
  integration_test_sequences_max_fields: ResolverTypeWrapper<Integration_Test_Sequences_Max_Fields>;
  integration_test_sequences_min_fields: ResolverTypeWrapper<Integration_Test_Sequences_Min_Fields>;
  integration_test_sequences_mutation_response: ResolverTypeWrapper<Integration_Test_Sequences_Mutation_Response>;
  integration_test_sequences_obj_rel_insert_input: Integration_Test_Sequences_Obj_Rel_Insert_Input;
  integration_test_sequences_on_conflict: Integration_Test_Sequences_On_Conflict;
  integration_test_sequences_order_by: Integration_Test_Sequences_Order_By;
  integration_test_sequences_pk_columns_input: Integration_Test_Sequences_Pk_Columns_Input;
  integration_test_sequences_select_column: Integration_Test_Sequences_Select_Column;
  integration_test_sequences_set_input: Integration_Test_Sequences_Set_Input;
  integration_test_sequences_stream_cursor_input: Integration_Test_Sequences_Stream_Cursor_Input;
  integration_test_sequences_stream_cursor_value_input: Integration_Test_Sequences_Stream_Cursor_Value_Input;
  integration_test_sequences_update_column: Integration_Test_Sequences_Update_Column;
  integration_test_sequences_updates: Integration_Test_Sequences_Updates;
  integration_test_steps: ResolverTypeWrapper<Integration_Test_Steps>;
  integration_test_steps_aggregate: ResolverTypeWrapper<Integration_Test_Steps_Aggregate>;
  integration_test_steps_aggregate_fields: ResolverTypeWrapper<Integration_Test_Steps_Aggregate_Fields>;
  integration_test_steps_aggregate_order_by: Integration_Test_Steps_Aggregate_Order_By;
  integration_test_steps_arr_rel_insert_input: Integration_Test_Steps_Arr_Rel_Insert_Input;
  integration_test_steps_avg_fields: ResolverTypeWrapper<Integration_Test_Steps_Avg_Fields>;
  integration_test_steps_avg_order_by: Integration_Test_Steps_Avg_Order_By;
  integration_test_steps_bool_exp: Integration_Test_Steps_Bool_Exp;
  integration_test_steps_constraint: Integration_Test_Steps_Constraint;
  integration_test_steps_insert_input: Integration_Test_Steps_Insert_Input;
  integration_test_steps_max_fields: ResolverTypeWrapper<Integration_Test_Steps_Max_Fields>;
  integration_test_steps_max_order_by: Integration_Test_Steps_Max_Order_By;
  integration_test_steps_min_fields: ResolverTypeWrapper<Integration_Test_Steps_Min_Fields>;
  integration_test_steps_min_order_by: Integration_Test_Steps_Min_Order_By;
  integration_test_steps_mutation_response: ResolverTypeWrapper<Integration_Test_Steps_Mutation_Response>;
  integration_test_steps_obj_rel_insert_input: Integration_Test_Steps_Obj_Rel_Insert_Input;
  integration_test_steps_on_conflict: Integration_Test_Steps_On_Conflict;
  integration_test_steps_order_by: Integration_Test_Steps_Order_By;
  integration_test_steps_select_column: Integration_Test_Steps_Select_Column;
  integration_test_steps_stddev_fields: ResolverTypeWrapper<Integration_Test_Steps_Stddev_Fields>;
  integration_test_steps_stddev_order_by: Integration_Test_Steps_Stddev_Order_By;
  integration_test_steps_stddev_pop_fields: ResolverTypeWrapper<Integration_Test_Steps_Stddev_Pop_Fields>;
  integration_test_steps_stddev_pop_order_by: Integration_Test_Steps_Stddev_Pop_Order_By;
  integration_test_steps_stddev_samp_fields: ResolverTypeWrapper<Integration_Test_Steps_Stddev_Samp_Fields>;
  integration_test_steps_stddev_samp_order_by: Integration_Test_Steps_Stddev_Samp_Order_By;
  integration_test_steps_stream_cursor_input: Integration_Test_Steps_Stream_Cursor_Input;
  integration_test_steps_stream_cursor_value_input: Integration_Test_Steps_Stream_Cursor_Value_Input;
  integration_test_steps_sum_fields: ResolverTypeWrapper<Integration_Test_Steps_Sum_Fields>;
  integration_test_steps_sum_order_by: Integration_Test_Steps_Sum_Order_By;
  integration_test_steps_update_column: Integration_Test_Steps_Update_Column;
  integration_test_steps_var_pop_fields: ResolverTypeWrapper<Integration_Test_Steps_Var_Pop_Fields>;
  integration_test_steps_var_pop_order_by: Integration_Test_Steps_Var_Pop_Order_By;
  integration_test_steps_var_samp_fields: ResolverTypeWrapper<Integration_Test_Steps_Var_Samp_Fields>;
  integration_test_steps_var_samp_order_by: Integration_Test_Steps_Var_Samp_Order_By;
  integration_test_steps_variance_fields: ResolverTypeWrapper<Integration_Test_Steps_Variance_Fields>;
  integration_test_steps_variance_order_by: Integration_Test_Steps_Variance_Order_By;
  json: ResolverTypeWrapper<Scalars['json']>;
  json_comparison_exp: Json_Comparison_Exp;
  jsonb: ResolverTypeWrapper<Scalars['jsonb']>;
  jsonb_cast_exp: Jsonb_Cast_Exp;
  jsonb_comparison_exp: Jsonb_Comparison_Exp;
  languages: ResolverTypeWrapper<Languages>;
  languages_bool_exp: Languages_Bool_Exp;
  languages_mutation_response: ResolverTypeWrapper<Languages_Mutation_Response>;
  languages_order_by: Languages_Order_By;
  languages_pk_columns_input: Languages_Pk_Columns_Input;
  languages_select_column: Languages_Select_Column;
  languages_set_input: Languages_Set_Input;
  languages_stream_cursor_input: Languages_Stream_Cursor_Input;
  languages_stream_cursor_value_input: Languages_Stream_Cursor_Value_Input;
  languages_updates: Languages_Updates;
  machine_learning_blobs: ResolverTypeWrapper<Machine_Learning_Blobs>;
  machine_learning_blobs_aggregate: ResolverTypeWrapper<Machine_Learning_Blobs_Aggregate>;
  machine_learning_blobs_aggregate_fields: ResolverTypeWrapper<Machine_Learning_Blobs_Aggregate_Fields>;
  machine_learning_blobs_avg_fields: ResolverTypeWrapper<Machine_Learning_Blobs_Avg_Fields>;
  machine_learning_blobs_bool_exp: Machine_Learning_Blobs_Bool_Exp;
  machine_learning_blobs_constraint: Machine_Learning_Blobs_Constraint;
  machine_learning_blobs_inc_input: Machine_Learning_Blobs_Inc_Input;
  machine_learning_blobs_insert_input: Machine_Learning_Blobs_Insert_Input;
  machine_learning_blobs_max_fields: ResolverTypeWrapper<Machine_Learning_Blobs_Max_Fields>;
  machine_learning_blobs_min_fields: ResolverTypeWrapper<Machine_Learning_Blobs_Min_Fields>;
  machine_learning_blobs_mutation_response: ResolverTypeWrapper<Machine_Learning_Blobs_Mutation_Response>;
  machine_learning_blobs_obj_rel_insert_input: Machine_Learning_Blobs_Obj_Rel_Insert_Input;
  machine_learning_blobs_on_conflict: Machine_Learning_Blobs_On_Conflict;
  machine_learning_blobs_order_by: Machine_Learning_Blobs_Order_By;
  machine_learning_blobs_pk_columns_input: Machine_Learning_Blobs_Pk_Columns_Input;
  machine_learning_blobs_select_column: Machine_Learning_Blobs_Select_Column;
  machine_learning_blobs_set_input: Machine_Learning_Blobs_Set_Input;
  machine_learning_blobs_stddev_fields: ResolverTypeWrapper<Machine_Learning_Blobs_Stddev_Fields>;
  machine_learning_blobs_stddev_pop_fields: ResolverTypeWrapper<Machine_Learning_Blobs_Stddev_Pop_Fields>;
  machine_learning_blobs_stddev_samp_fields: ResolverTypeWrapper<Machine_Learning_Blobs_Stddev_Samp_Fields>;
  machine_learning_blobs_stream_cursor_input: Machine_Learning_Blobs_Stream_Cursor_Input;
  machine_learning_blobs_stream_cursor_value_input: Machine_Learning_Blobs_Stream_Cursor_Value_Input;
  machine_learning_blobs_sum_fields: ResolverTypeWrapper<Machine_Learning_Blobs_Sum_Fields>;
  machine_learning_blobs_update_column: Machine_Learning_Blobs_Update_Column;
  machine_learning_blobs_updates: Machine_Learning_Blobs_Updates;
  machine_learning_blobs_var_pop_fields: ResolverTypeWrapper<Machine_Learning_Blobs_Var_Pop_Fields>;
  machine_learning_blobs_var_samp_fields: ResolverTypeWrapper<Machine_Learning_Blobs_Var_Samp_Fields>;
  machine_learning_blobs_variance_fields: ResolverTypeWrapper<Machine_Learning_Blobs_Variance_Fields>;
  machine_learning_dataset_feature: ResolverTypeWrapper<Machine_Learning_Dataset_Feature>;
  machine_learning_dataset_feature_aggregate: ResolverTypeWrapper<Machine_Learning_Dataset_Feature_Aggregate>;
  machine_learning_dataset_feature_aggregate_fields: ResolverTypeWrapper<Machine_Learning_Dataset_Feature_Aggregate_Fields>;
  machine_learning_dataset_feature_aggregate_order_by: Machine_Learning_Dataset_Feature_Aggregate_Order_By;
  machine_learning_dataset_feature_arr_rel_insert_input: Machine_Learning_Dataset_Feature_Arr_Rel_Insert_Input;
  machine_learning_dataset_feature_bool_exp: Machine_Learning_Dataset_Feature_Bool_Exp;
  machine_learning_dataset_feature_constraint: Machine_Learning_Dataset_Feature_Constraint;
  machine_learning_dataset_feature_insert_input: Machine_Learning_Dataset_Feature_Insert_Input;
  machine_learning_dataset_feature_max_fields: ResolverTypeWrapper<Machine_Learning_Dataset_Feature_Max_Fields>;
  machine_learning_dataset_feature_max_order_by: Machine_Learning_Dataset_Feature_Max_Order_By;
  machine_learning_dataset_feature_min_fields: ResolverTypeWrapper<Machine_Learning_Dataset_Feature_Min_Fields>;
  machine_learning_dataset_feature_min_order_by: Machine_Learning_Dataset_Feature_Min_Order_By;
  machine_learning_dataset_feature_mutation_response: ResolverTypeWrapper<Machine_Learning_Dataset_Feature_Mutation_Response>;
  machine_learning_dataset_feature_on_conflict: Machine_Learning_Dataset_Feature_On_Conflict;
  machine_learning_dataset_feature_order_by: Machine_Learning_Dataset_Feature_Order_By;
  machine_learning_dataset_feature_pk_columns_input: Machine_Learning_Dataset_Feature_Pk_Columns_Input;
  machine_learning_dataset_feature_select_column: Machine_Learning_Dataset_Feature_Select_Column;
  machine_learning_dataset_feature_set_input: Machine_Learning_Dataset_Feature_Set_Input;
  machine_learning_dataset_feature_stream_cursor_input: Machine_Learning_Dataset_Feature_Stream_Cursor_Input;
  machine_learning_dataset_feature_stream_cursor_value_input: Machine_Learning_Dataset_Feature_Stream_Cursor_Value_Input;
  machine_learning_dataset_feature_update_column: Machine_Learning_Dataset_Feature_Update_Column;
  machine_learning_dataset_feature_updates: Machine_Learning_Dataset_Feature_Updates;
  machine_learning_datasets: ResolverTypeWrapper<Machine_Learning_Datasets>;
  machine_learning_datasets_aggregate: ResolverTypeWrapper<Machine_Learning_Datasets_Aggregate>;
  machine_learning_datasets_aggregate_fields: ResolverTypeWrapper<Machine_Learning_Datasets_Aggregate_Fields>;
  machine_learning_datasets_avg_fields: ResolverTypeWrapper<Machine_Learning_Datasets_Avg_Fields>;
  machine_learning_datasets_bool_exp: Machine_Learning_Datasets_Bool_Exp;
  machine_learning_datasets_constraint: Machine_Learning_Datasets_Constraint;
  machine_learning_datasets_inc_input: Machine_Learning_Datasets_Inc_Input;
  machine_learning_datasets_insert_input: Machine_Learning_Datasets_Insert_Input;
  machine_learning_datasets_max_fields: ResolverTypeWrapper<Machine_Learning_Datasets_Max_Fields>;
  machine_learning_datasets_min_fields: ResolverTypeWrapper<Machine_Learning_Datasets_Min_Fields>;
  machine_learning_datasets_mutation_response: ResolverTypeWrapper<Machine_Learning_Datasets_Mutation_Response>;
  machine_learning_datasets_obj_rel_insert_input: Machine_Learning_Datasets_Obj_Rel_Insert_Input;
  machine_learning_datasets_on_conflict: Machine_Learning_Datasets_On_Conflict;
  machine_learning_datasets_order_by: Machine_Learning_Datasets_Order_By;
  machine_learning_datasets_pk_columns_input: Machine_Learning_Datasets_Pk_Columns_Input;
  machine_learning_datasets_select_column: Machine_Learning_Datasets_Select_Column;
  machine_learning_datasets_set_input: Machine_Learning_Datasets_Set_Input;
  machine_learning_datasets_stddev_fields: ResolverTypeWrapper<Machine_Learning_Datasets_Stddev_Fields>;
  machine_learning_datasets_stddev_pop_fields: ResolverTypeWrapper<Machine_Learning_Datasets_Stddev_Pop_Fields>;
  machine_learning_datasets_stddev_samp_fields: ResolverTypeWrapper<Machine_Learning_Datasets_Stddev_Samp_Fields>;
  machine_learning_datasets_stream_cursor_input: Machine_Learning_Datasets_Stream_Cursor_Input;
  machine_learning_datasets_stream_cursor_value_input: Machine_Learning_Datasets_Stream_Cursor_Value_Input;
  machine_learning_datasets_sum_fields: ResolverTypeWrapper<Machine_Learning_Datasets_Sum_Fields>;
  machine_learning_datasets_update_column: Machine_Learning_Datasets_Update_Column;
  machine_learning_datasets_updates: Machine_Learning_Datasets_Updates;
  machine_learning_datasets_var_pop_fields: ResolverTypeWrapper<Machine_Learning_Datasets_Var_Pop_Fields>;
  machine_learning_datasets_var_samp_fields: ResolverTypeWrapper<Machine_Learning_Datasets_Var_Samp_Fields>;
  machine_learning_datasets_variance_fields: ResolverTypeWrapper<Machine_Learning_Datasets_Variance_Fields>;
  machine_learning_features: ResolverTypeWrapper<Machine_Learning_Features>;
  machine_learning_features_bool_exp: Machine_Learning_Features_Bool_Exp;
  machine_learning_features_constraint: Machine_Learning_Features_Constraint;
  machine_learning_features_insert_input: Machine_Learning_Features_Insert_Input;
  machine_learning_features_mutation_response: ResolverTypeWrapper<Machine_Learning_Features_Mutation_Response>;
  machine_learning_features_obj_rel_insert_input: Machine_Learning_Features_Obj_Rel_Insert_Input;
  machine_learning_features_on_conflict: Machine_Learning_Features_On_Conflict;
  machine_learning_features_order_by: Machine_Learning_Features_Order_By;
  machine_learning_features_pk_columns_input: Machine_Learning_Features_Pk_Columns_Input;
  machine_learning_features_select_column: Machine_Learning_Features_Select_Column;
  machine_learning_features_set_input: Machine_Learning_Features_Set_Input;
  machine_learning_features_stream_cursor_input: Machine_Learning_Features_Stream_Cursor_Input;
  machine_learning_features_stream_cursor_value_input: Machine_Learning_Features_Stream_Cursor_Value_Input;
  machine_learning_features_update_column: Machine_Learning_Features_Update_Column;
  machine_learning_features_updates: Machine_Learning_Features_Updates;
  machine_learning_model_blob: ResolverTypeWrapper<Machine_Learning_Model_Blob>;
  machine_learning_model_blob_aggregate_order_by: Machine_Learning_Model_Blob_Aggregate_Order_By;
  machine_learning_model_blob_arr_rel_insert_input: Machine_Learning_Model_Blob_Arr_Rel_Insert_Input;
  machine_learning_model_blob_bool_exp: Machine_Learning_Model_Blob_Bool_Exp;
  machine_learning_model_blob_constraint: Machine_Learning_Model_Blob_Constraint;
  machine_learning_model_blob_insert_input: Machine_Learning_Model_Blob_Insert_Input;
  machine_learning_model_blob_max_order_by: Machine_Learning_Model_Blob_Max_Order_By;
  machine_learning_model_blob_min_order_by: Machine_Learning_Model_Blob_Min_Order_By;
  machine_learning_model_blob_mutation_response: ResolverTypeWrapper<Machine_Learning_Model_Blob_Mutation_Response>;
  machine_learning_model_blob_on_conflict: Machine_Learning_Model_Blob_On_Conflict;
  machine_learning_model_blob_order_by: Machine_Learning_Model_Blob_Order_By;
  machine_learning_model_blob_pk_columns_input: Machine_Learning_Model_Blob_Pk_Columns_Input;
  machine_learning_model_blob_select_column: Machine_Learning_Model_Blob_Select_Column;
  machine_learning_model_blob_set_input: Machine_Learning_Model_Blob_Set_Input;
  machine_learning_model_blob_stream_cursor_input: Machine_Learning_Model_Blob_Stream_Cursor_Input;
  machine_learning_model_blob_stream_cursor_value_input: Machine_Learning_Model_Blob_Stream_Cursor_Value_Input;
  machine_learning_model_blob_update_column: Machine_Learning_Model_Blob_Update_Column;
  machine_learning_model_blob_updates: Machine_Learning_Model_Blob_Updates;
  machine_learning_model_dataset: ResolverTypeWrapper<Machine_Learning_Model_Dataset>;
  machine_learning_model_dataset_aggregate_order_by: Machine_Learning_Model_Dataset_Aggregate_Order_By;
  machine_learning_model_dataset_arr_rel_insert_input: Machine_Learning_Model_Dataset_Arr_Rel_Insert_Input;
  machine_learning_model_dataset_bool_exp: Machine_Learning_Model_Dataset_Bool_Exp;
  machine_learning_model_dataset_constraint: Machine_Learning_Model_Dataset_Constraint;
  machine_learning_model_dataset_insert_input: Machine_Learning_Model_Dataset_Insert_Input;
  machine_learning_model_dataset_max_order_by: Machine_Learning_Model_Dataset_Max_Order_By;
  machine_learning_model_dataset_min_order_by: Machine_Learning_Model_Dataset_Min_Order_By;
  machine_learning_model_dataset_mutation_response: ResolverTypeWrapper<Machine_Learning_Model_Dataset_Mutation_Response>;
  machine_learning_model_dataset_on_conflict: Machine_Learning_Model_Dataset_On_Conflict;
  machine_learning_model_dataset_order_by: Machine_Learning_Model_Dataset_Order_By;
  machine_learning_model_dataset_pk_columns_input: Machine_Learning_Model_Dataset_Pk_Columns_Input;
  machine_learning_model_dataset_select_column: Machine_Learning_Model_Dataset_Select_Column;
  machine_learning_model_dataset_set_input: Machine_Learning_Model_Dataset_Set_Input;
  machine_learning_model_dataset_stream_cursor_input: Machine_Learning_Model_Dataset_Stream_Cursor_Input;
  machine_learning_model_dataset_stream_cursor_value_input: Machine_Learning_Model_Dataset_Stream_Cursor_Value_Input;
  machine_learning_model_dataset_update_column: Machine_Learning_Model_Dataset_Update_Column;
  machine_learning_model_dataset_updates: Machine_Learning_Model_Dataset_Updates;
  machine_learning_model_recording: ResolverTypeWrapper<Machine_Learning_Model_Recording>;
  machine_learning_model_recording_aggregate: ResolverTypeWrapper<Machine_Learning_Model_Recording_Aggregate>;
  machine_learning_model_recording_aggregate_fields: ResolverTypeWrapper<Machine_Learning_Model_Recording_Aggregate_Fields>;
  machine_learning_model_recording_aggregate_order_by: Machine_Learning_Model_Recording_Aggregate_Order_By;
  machine_learning_model_recording_arr_rel_insert_input: Machine_Learning_Model_Recording_Arr_Rel_Insert_Input;
  machine_learning_model_recording_bool_exp: Machine_Learning_Model_Recording_Bool_Exp;
  machine_learning_model_recording_constraint: Machine_Learning_Model_Recording_Constraint;
  machine_learning_model_recording_insert_input: Machine_Learning_Model_Recording_Insert_Input;
  machine_learning_model_recording_max_fields: ResolverTypeWrapper<Machine_Learning_Model_Recording_Max_Fields>;
  machine_learning_model_recording_max_order_by: Machine_Learning_Model_Recording_Max_Order_By;
  machine_learning_model_recording_min_fields: ResolverTypeWrapper<Machine_Learning_Model_Recording_Min_Fields>;
  machine_learning_model_recording_min_order_by: Machine_Learning_Model_Recording_Min_Order_By;
  machine_learning_model_recording_mutation_response: ResolverTypeWrapper<Machine_Learning_Model_Recording_Mutation_Response>;
  machine_learning_model_recording_on_conflict: Machine_Learning_Model_Recording_On_Conflict;
  machine_learning_model_recording_order_by: Machine_Learning_Model_Recording_Order_By;
  machine_learning_model_recording_pk_columns_input: Machine_Learning_Model_Recording_Pk_Columns_Input;
  machine_learning_model_recording_select_column: Machine_Learning_Model_Recording_Select_Column;
  machine_learning_model_recording_set_input: Machine_Learning_Model_Recording_Set_Input;
  machine_learning_model_recording_stream_cursor_input: Machine_Learning_Model_Recording_Stream_Cursor_Input;
  machine_learning_model_recording_stream_cursor_value_input: Machine_Learning_Model_Recording_Stream_Cursor_Value_Input;
  machine_learning_model_recording_update_column: Machine_Learning_Model_Recording_Update_Column;
  machine_learning_model_recording_updates: Machine_Learning_Model_Recording_Updates;
  machine_learning_model_types: ResolverTypeWrapper<Machine_Learning_Model_Types>;
  machine_learning_model_types_bool_exp: Machine_Learning_Model_Types_Bool_Exp;
  machine_learning_model_types_enum: Machine_Learning_Model_Types_Enum;
  machine_learning_model_types_enum_comparison_exp: Machine_Learning_Model_Types_Enum_Comparison_Exp;
  machine_learning_model_types_order_by: Machine_Learning_Model_Types_Order_By;
  machine_learning_model_types_select_column: Machine_Learning_Model_Types_Select_Column;
  machine_learning_model_types_stream_cursor_input: Machine_Learning_Model_Types_Stream_Cursor_Input;
  machine_learning_model_types_stream_cursor_value_input: Machine_Learning_Model_Types_Stream_Cursor_Value_Input;
  machine_learning_models: ResolverTypeWrapper<Machine_Learning_Models>;
  machine_learning_models_bool_exp: Machine_Learning_Models_Bool_Exp;
  machine_learning_models_constraint: Machine_Learning_Models_Constraint;
  machine_learning_models_inc_input: Machine_Learning_Models_Inc_Input;
  machine_learning_models_insert_input: Machine_Learning_Models_Insert_Input;
  machine_learning_models_mutation_response: ResolverTypeWrapper<Machine_Learning_Models_Mutation_Response>;
  machine_learning_models_obj_rel_insert_input: Machine_Learning_Models_Obj_Rel_Insert_Input;
  machine_learning_models_on_conflict: Machine_Learning_Models_On_Conflict;
  machine_learning_models_order_by: Machine_Learning_Models_Order_By;
  machine_learning_models_pk_columns_input: Machine_Learning_Models_Pk_Columns_Input;
  machine_learning_models_select_column: Machine_Learning_Models_Select_Column;
  machine_learning_models_set_input: Machine_Learning_Models_Set_Input;
  machine_learning_models_stream_cursor_input: Machine_Learning_Models_Stream_Cursor_Input;
  machine_learning_models_stream_cursor_value_input: Machine_Learning_Models_Stream_Cursor_Value_Input;
  machine_learning_models_update_column: Machine_Learning_Models_Update_Column;
  machine_learning_models_updates: Machine_Learning_Models_Updates;
  media: ResolverTypeWrapper<Media>;
  media_aggregate: ResolverTypeWrapper<Media_Aggregate>;
  media_aggregate_fields: ResolverTypeWrapper<Media_Aggregate_Fields>;
  media_aggregate_order_by: Media_Aggregate_Order_By;
  media_bool_exp: Media_Bool_Exp;
  media_constraint: Media_Constraint;
  media_insert_input: Media_Insert_Input;
  media_max_fields: ResolverTypeWrapper<Media_Max_Fields>;
  media_max_order_by: Media_Max_Order_By;
  media_min_fields: ResolverTypeWrapper<Media_Min_Fields>;
  media_min_order_by: Media_Min_Order_By;
  media_mutation_response: ResolverTypeWrapper<Media_Mutation_Response>;
  media_obj_rel_insert_input: Media_Obj_Rel_Insert_Input;
  media_on_conflict: Media_On_Conflict;
  media_order_by: Media_Order_By;
  media_pk_columns_input: Media_Pk_Columns_Input;
  media_select_column: Media_Select_Column;
  media_set_input: Media_Set_Input;
  media_stream_cursor_input: Media_Stream_Cursor_Input;
  media_stream_cursor_value_input: Media_Stream_Cursor_Value_Input;
  media_type: ResolverTypeWrapper<Media_Type>;
  media_type_bool_exp: Media_Type_Bool_Exp;
  media_type_enum: Media_Type_Enum;
  media_type_enum_comparison_exp: Media_Type_Enum_Comparison_Exp;
  media_type_order_by: Media_Type_Order_By;
  media_type_select_column: Media_Type_Select_Column;
  media_type_stream_cursor_input: Media_Type_Stream_Cursor_Input;
  media_type_stream_cursor_value_input: Media_Type_Stream_Cursor_Value_Input;
  media_update_column: Media_Update_Column;
  media_updates: Media_Updates;
  mode_project: ResolverTypeWrapper<Mode_Project>;
  mode_project_aggregate: ResolverTypeWrapper<Mode_Project_Aggregate>;
  mode_project_aggregate_fields: ResolverTypeWrapper<Mode_Project_Aggregate_Fields>;
  mode_project_aggregate_order_by: Mode_Project_Aggregate_Order_By;
  mode_project_arr_rel_insert_input: Mode_Project_Arr_Rel_Insert_Input;
  mode_project_bool_exp: Mode_Project_Bool_Exp;
  mode_project_constraint: Mode_Project_Constraint;
  mode_project_insert_input: Mode_Project_Insert_Input;
  mode_project_max_fields: ResolverTypeWrapper<Mode_Project_Max_Fields>;
  mode_project_max_order_by: Mode_Project_Max_Order_By;
  mode_project_min_fields: ResolverTypeWrapper<Mode_Project_Min_Fields>;
  mode_project_min_order_by: Mode_Project_Min_Order_By;
  mode_project_mutation_response: ResolverTypeWrapper<Mode_Project_Mutation_Response>;
  mode_project_on_conflict: Mode_Project_On_Conflict;
  mode_project_order_by: Mode_Project_Order_By;
  mode_project_pk_columns_input: Mode_Project_Pk_Columns_Input;
  mode_project_select_column: Mode_Project_Select_Column;
  mode_project_set_input: Mode_Project_Set_Input;
  mode_project_stream_cursor_input: Mode_Project_Stream_Cursor_Input;
  mode_project_stream_cursor_value_input: Mode_Project_Stream_Cursor_Value_Input;
  mode_project_update_column: Mode_Project_Update_Column;
  mode_project_updates: Mode_Project_Updates;
  model_name_hashes: ResolverTypeWrapper<Model_Name_Hashes>;
  model_name_hashes_aggregate: ResolverTypeWrapper<Model_Name_Hashes_Aggregate>;
  model_name_hashes_aggregate_fields: ResolverTypeWrapper<Model_Name_Hashes_Aggregate_Fields>;
  model_name_hashes_aggregate_order_by: Model_Name_Hashes_Aggregate_Order_By;
  model_name_hashes_arr_rel_insert_input: Model_Name_Hashes_Arr_Rel_Insert_Input;
  model_name_hashes_bool_exp: Model_Name_Hashes_Bool_Exp;
  model_name_hashes_constraint: Model_Name_Hashes_Constraint;
  model_name_hashes_insert_input: Model_Name_Hashes_Insert_Input;
  model_name_hashes_max_fields: ResolverTypeWrapper<Model_Name_Hashes_Max_Fields>;
  model_name_hashes_max_order_by: Model_Name_Hashes_Max_Order_By;
  model_name_hashes_min_fields: ResolverTypeWrapper<Model_Name_Hashes_Min_Fields>;
  model_name_hashes_min_order_by: Model_Name_Hashes_Min_Order_By;
  model_name_hashes_mutation_response: ResolverTypeWrapper<Model_Name_Hashes_Mutation_Response>;
  model_name_hashes_on_conflict: Model_Name_Hashes_On_Conflict;
  model_name_hashes_order_by: Model_Name_Hashes_Order_By;
  model_name_hashes_pk_columns_input: Model_Name_Hashes_Pk_Columns_Input;
  model_name_hashes_select_column: Model_Name_Hashes_Select_Column;
  model_name_hashes_set_input: Model_Name_Hashes_Set_Input;
  model_name_hashes_stream_cursor_input: Model_Name_Hashes_Stream_Cursor_Input;
  model_name_hashes_stream_cursor_value_input: Model_Name_Hashes_Stream_Cursor_Value_Input;
  model_name_hashes_update_column: Model_Name_Hashes_Update_Column;
  model_name_hashes_updates: Model_Name_Hashes_Updates;
  models: ResolverTypeWrapper<Models>;
  models_aggregate: ResolverTypeWrapper<Models_Aggregate>;
  models_aggregate_fields: ResolverTypeWrapper<Models_Aggregate_Fields>;
  models_bool_exp: Models_Bool_Exp;
  models_constraint: Models_Constraint;
  models_insert_input: Models_Insert_Input;
  models_max_fields: ResolverTypeWrapper<Models_Max_Fields>;
  models_min_fields: ResolverTypeWrapper<Models_Min_Fields>;
  models_mutation_response: ResolverTypeWrapper<Models_Mutation_Response>;
  models_obj_rel_insert_input: Models_Obj_Rel_Insert_Input;
  models_on_conflict: Models_On_Conflict;
  models_order_by: Models_Order_By;
  models_pk_columns_input: Models_Pk_Columns_Input;
  models_select_column: Models_Select_Column;
  models_set_input: Models_Set_Input;
  models_stream_cursor_input: Models_Stream_Cursor_Input;
  models_stream_cursor_value_input: Models_Stream_Cursor_Value_Input;
  models_update_column: Models_Update_Column;
  models_updates: Models_Updates;
  mutation_root: ResolverTypeWrapper<{}>;
  numeric: ResolverTypeWrapper<Scalars['numeric']>;
  numeric_comparison_exp: Numeric_Comparison_Exp;
  opmode_profile: ResolverTypeWrapper<Opmode_Profile>;
  opmode_profile_aggregate_order_by: Opmode_Profile_Aggregate_Order_By;
  opmode_profile_arr_rel_insert_input: Opmode_Profile_Arr_Rel_Insert_Input;
  opmode_profile_bool_exp: Opmode_Profile_Bool_Exp;
  opmode_profile_constraint: Opmode_Profile_Constraint;
  opmode_profile_insert_input: Opmode_Profile_Insert_Input;
  opmode_profile_max_order_by: Opmode_Profile_Max_Order_By;
  opmode_profile_min_order_by: Opmode_Profile_Min_Order_By;
  opmode_profile_mutation_response: ResolverTypeWrapper<Opmode_Profile_Mutation_Response>;
  opmode_profile_on_conflict: Opmode_Profile_On_Conflict;
  opmode_profile_order_by: Opmode_Profile_Order_By;
  opmode_profile_pk_columns_input: Opmode_Profile_Pk_Columns_Input;
  opmode_profile_select_column: Opmode_Profile_Select_Column;
  opmode_profile_set_input: Opmode_Profile_Set_Input;
  opmode_profile_stream_cursor_input: Opmode_Profile_Stream_Cursor_Input;
  opmode_profile_stream_cursor_value_input: Opmode_Profile_Stream_Cursor_Value_Input;
  opmode_profile_update_column: Opmode_Profile_Update_Column;
  opmode_profile_updates: Opmode_Profile_Updates;
  opmode_project: ResolverTypeWrapper<Opmode_Project>;
  opmode_project_aggregate: ResolverTypeWrapper<Opmode_Project_Aggregate>;
  opmode_project_aggregate_fields: ResolverTypeWrapper<Opmode_Project_Aggregate_Fields>;
  opmode_project_aggregate_order_by: Opmode_Project_Aggregate_Order_By;
  opmode_project_arr_rel_insert_input: Opmode_Project_Arr_Rel_Insert_Input;
  opmode_project_bool_exp: Opmode_Project_Bool_Exp;
  opmode_project_constraint: Opmode_Project_Constraint;
  opmode_project_insert_input: Opmode_Project_Insert_Input;
  opmode_project_max_fields: ResolverTypeWrapper<Opmode_Project_Max_Fields>;
  opmode_project_max_order_by: Opmode_Project_Max_Order_By;
  opmode_project_min_fields: ResolverTypeWrapper<Opmode_Project_Min_Fields>;
  opmode_project_min_order_by: Opmode_Project_Min_Order_By;
  opmode_project_mutation_response: ResolverTypeWrapper<Opmode_Project_Mutation_Response>;
  opmode_project_on_conflict: Opmode_Project_On_Conflict;
  opmode_project_order_by: Opmode_Project_Order_By;
  opmode_project_pk_columns_input: Opmode_Project_Pk_Columns_Input;
  opmode_project_select_column: Opmode_Project_Select_Column;
  opmode_project_set_input: Opmode_Project_Set_Input;
  opmode_project_stream_cursor_input: Opmode_Project_Stream_Cursor_Input;
  opmode_project_stream_cursor_value_input: Opmode_Project_Stream_Cursor_Value_Input;
  opmode_project_update_column: Opmode_Project_Update_Column;
  opmode_project_updates: Opmode_Project_Updates;
  opmodes: ResolverTypeWrapper<Opmodes>;
  opmodes_aggregate: ResolverTypeWrapper<Opmodes_Aggregate>;
  opmodes_aggregate_fields: ResolverTypeWrapper<Opmodes_Aggregate_Fields>;
  opmodes_avg_fields: ResolverTypeWrapper<Opmodes_Avg_Fields>;
  opmodes_bool_exp: Opmodes_Bool_Exp;
  opmodes_constraint: Opmodes_Constraint;
  opmodes_inc_input: Opmodes_Inc_Input;
  opmodes_insert_input: Opmodes_Insert_Input;
  opmodes_max_fields: ResolverTypeWrapper<Opmodes_Max_Fields>;
  opmodes_min_fields: ResolverTypeWrapper<Opmodes_Min_Fields>;
  opmodes_mutation_response: ResolverTypeWrapper<Opmodes_Mutation_Response>;
  opmodes_obj_rel_insert_input: Opmodes_Obj_Rel_Insert_Input;
  opmodes_on_conflict: Opmodes_On_Conflict;
  opmodes_order_by: Opmodes_Order_By;
  opmodes_pk_columns_input: Opmodes_Pk_Columns_Input;
  opmodes_select_column: Opmodes_Select_Column;
  opmodes_set_input: Opmodes_Set_Input;
  opmodes_stddev_fields: ResolverTypeWrapper<Opmodes_Stddev_Fields>;
  opmodes_stddev_pop_fields: ResolverTypeWrapper<Opmodes_Stddev_Pop_Fields>;
  opmodes_stddev_samp_fields: ResolverTypeWrapper<Opmodes_Stddev_Samp_Fields>;
  opmodes_stream_cursor_input: Opmodes_Stream_Cursor_Input;
  opmodes_stream_cursor_value_input: Opmodes_Stream_Cursor_Value_Input;
  opmodes_sum_fields: ResolverTypeWrapper<Opmodes_Sum_Fields>;
  opmodes_update_column: Opmodes_Update_Column;
  opmodes_updates: Opmodes_Updates;
  opmodes_var_pop_fields: ResolverTypeWrapper<Opmodes_Var_Pop_Fields>;
  opmodes_var_samp_fields: ResolverTypeWrapper<Opmodes_Var_Samp_Fields>;
  opmodes_variance_fields: ResolverTypeWrapper<Opmodes_Variance_Fields>;
  option_media: ResolverTypeWrapper<Option_Media>;
  option_media_aggregate: ResolverTypeWrapper<Option_Media_Aggregate>;
  option_media_aggregate_fields: ResolverTypeWrapper<Option_Media_Aggregate_Fields>;
  option_media_aggregate_order_by: Option_Media_Aggregate_Order_By;
  option_media_arr_rel_insert_input: Option_Media_Arr_Rel_Insert_Input;
  option_media_bool_exp: Option_Media_Bool_Exp;
  option_media_constraint: Option_Media_Constraint;
  option_media_insert_input: Option_Media_Insert_Input;
  option_media_max_fields: ResolverTypeWrapper<Option_Media_Max_Fields>;
  option_media_max_order_by: Option_Media_Max_Order_By;
  option_media_min_fields: ResolverTypeWrapper<Option_Media_Min_Fields>;
  option_media_min_order_by: Option_Media_Min_Order_By;
  option_media_mutation_response: ResolverTypeWrapper<Option_Media_Mutation_Response>;
  option_media_on_conflict: Option_Media_On_Conflict;
  option_media_order_by: Option_Media_Order_By;
  option_media_pk_columns_input: Option_Media_Pk_Columns_Input;
  option_media_select_column: Option_Media_Select_Column;
  option_media_set_input: Option_Media_Set_Input;
  option_media_stream_cursor_input: Option_Media_Stream_Cursor_Input;
  option_media_stream_cursor_value_input: Option_Media_Stream_Cursor_Value_Input;
  option_media_update_column: Option_Media_Update_Column;
  option_media_updates: Option_Media_Updates;
  option_translation: ResolverTypeWrapper<Option_Translation>;
  option_translation_aggregate: ResolverTypeWrapper<Option_Translation_Aggregate>;
  option_translation_aggregate_fields: ResolverTypeWrapper<Option_Translation_Aggregate_Fields>;
  option_translation_aggregate_order_by: Option_Translation_Aggregate_Order_By;
  option_translation_arr_rel_insert_input: Option_Translation_Arr_Rel_Insert_Input;
  option_translation_bool_exp: Option_Translation_Bool_Exp;
  option_translation_constraint: Option_Translation_Constraint;
  option_translation_insert_input: Option_Translation_Insert_Input;
  option_translation_max_fields: ResolverTypeWrapper<Option_Translation_Max_Fields>;
  option_translation_max_order_by: Option_Translation_Max_Order_By;
  option_translation_min_fields: ResolverTypeWrapper<Option_Translation_Min_Fields>;
  option_translation_min_order_by: Option_Translation_Min_Order_By;
  option_translation_mutation_response: ResolverTypeWrapper<Option_Translation_Mutation_Response>;
  option_translation_on_conflict: Option_Translation_On_Conflict;
  option_translation_order_by: Option_Translation_Order_By;
  option_translation_pk_columns_input: Option_Translation_Pk_Columns_Input;
  option_translation_select_column: Option_Translation_Select_Column;
  option_translation_set_input: Option_Translation_Set_Input;
  option_translation_stream_cursor_input: Option_Translation_Stream_Cursor_Input;
  option_translation_stream_cursor_value_input: Option_Translation_Stream_Cursor_Value_Input;
  option_translation_update_column: Option_Translation_Update_Column;
  option_translation_updates: Option_Translation_Updates;
  options: ResolverTypeWrapper<Options>;
  options_aggregate: ResolverTypeWrapper<Options_Aggregate>;
  options_aggregate_fields: ResolverTypeWrapper<Options_Aggregate_Fields>;
  options_aggregate_order_by: Options_Aggregate_Order_By;
  options_arr_rel_insert_input: Options_Arr_Rel_Insert_Input;
  options_bool_exp: Options_Bool_Exp;
  options_constraint: Options_Constraint;
  options_insert_input: Options_Insert_Input;
  options_max_fields: ResolverTypeWrapper<Options_Max_Fields>;
  options_max_order_by: Options_Max_Order_By;
  options_min_fields: ResolverTypeWrapper<Options_Min_Fields>;
  options_min_order_by: Options_Min_Order_By;
  options_mutation_response: ResolverTypeWrapper<Options_Mutation_Response>;
  options_obj_rel_insert_input: Options_Obj_Rel_Insert_Input;
  options_on_conflict: Options_On_Conflict;
  options_order_by: Options_Order_By;
  options_pk_columns_input: Options_Pk_Columns_Input;
  options_select_column: Options_Select_Column;
  options_set_input: Options_Set_Input;
  options_stream_cursor_input: Options_Stream_Cursor_Input;
  options_stream_cursor_value_input: Options_Stream_Cursor_Value_Input;
  options_update_column: Options_Update_Column;
  options_updates: Options_Updates;
  order_by: Order_By;
  payload_recording: ResolverTypeWrapper<Payload_Recording>;
  payload_recording_aggregate: ResolverTypeWrapper<Payload_Recording_Aggregate>;
  payload_recording_aggregate_fields: ResolverTypeWrapper<Payload_Recording_Aggregate_Fields>;
  payload_recording_aggregate_order_by: Payload_Recording_Aggregate_Order_By;
  payload_recording_arr_rel_insert_input: Payload_Recording_Arr_Rel_Insert_Input;
  payload_recording_bool_exp: Payload_Recording_Bool_Exp;
  payload_recording_constraint: Payload_Recording_Constraint;
  payload_recording_insert_input: Payload_Recording_Insert_Input;
  payload_recording_max_fields: ResolverTypeWrapper<Payload_Recording_Max_Fields>;
  payload_recording_max_order_by: Payload_Recording_Max_Order_By;
  payload_recording_min_fields: ResolverTypeWrapper<Payload_Recording_Min_Fields>;
  payload_recording_min_order_by: Payload_Recording_Min_Order_By;
  payload_recording_mutation_response: ResolverTypeWrapper<Payload_Recording_Mutation_Response>;
  payload_recording_on_conflict: Payload_Recording_On_Conflict;
  payload_recording_order_by: Payload_Recording_Order_By;
  payload_recording_pk_columns_input: Payload_Recording_Pk_Columns_Input;
  payload_recording_select_column: Payload_Recording_Select_Column;
  payload_recording_set_input: Payload_Recording_Set_Input;
  payload_recording_stream_cursor_input: Payload_Recording_Stream_Cursor_Input;
  payload_recording_stream_cursor_value_input: Payload_Recording_Stream_Cursor_Value_Input;
  payload_recording_update_column: Payload_Recording_Update_Column;
  payload_recording_updates: Payload_Recording_Updates;
  payload_types: ResolverTypeWrapper<Payload_Types>;
  payload_types_aggregate: ResolverTypeWrapper<Payload_Types_Aggregate>;
  payload_types_aggregate_fields: ResolverTypeWrapper<Payload_Types_Aggregate_Fields>;
  payload_types_bool_exp: Payload_Types_Bool_Exp;
  payload_types_enum: Payload_Types_Enum;
  payload_types_enum_comparison_exp: Payload_Types_Enum_Comparison_Exp;
  payload_types_max_fields: ResolverTypeWrapper<Payload_Types_Max_Fields>;
  payload_types_min_fields: ResolverTypeWrapper<Payload_Types_Min_Fields>;
  payload_types_mutation_response: ResolverTypeWrapper<Payload_Types_Mutation_Response>;
  payload_types_order_by: Payload_Types_Order_By;
  payload_types_pk_columns_input: Payload_Types_Pk_Columns_Input;
  payload_types_select_column: Payload_Types_Select_Column;
  payload_types_set_input: Payload_Types_Set_Input;
  payload_types_stream_cursor_input: Payload_Types_Stream_Cursor_Input;
  payload_types_stream_cursor_value_input: Payload_Types_Stream_Cursor_Value_Input;
  payload_types_updates: Payload_Types_Updates;
  payloads: ResolverTypeWrapper<Payloads>;
  payloads_aggregate: ResolverTypeWrapper<Payloads_Aggregate>;
  payloads_aggregate_fields: ResolverTypeWrapper<Payloads_Aggregate_Fields>;
  payloads_aggregate_order_by: Payloads_Aggregate_Order_By;
  payloads_avg_fields: ResolverTypeWrapper<Payloads_Avg_Fields>;
  payloads_avg_order_by: Payloads_Avg_Order_By;
  payloads_bool_exp: Payloads_Bool_Exp;
  payloads_constraint: Payloads_Constraint;
  payloads_inc_input: Payloads_Inc_Input;
  payloads_insert_input: Payloads_Insert_Input;
  payloads_max_fields: ResolverTypeWrapper<Payloads_Max_Fields>;
  payloads_max_order_by: Payloads_Max_Order_By;
  payloads_min_fields: ResolverTypeWrapper<Payloads_Min_Fields>;
  payloads_min_order_by: Payloads_Min_Order_By;
  payloads_mutation_response: ResolverTypeWrapper<Payloads_Mutation_Response>;
  payloads_obj_rel_insert_input: Payloads_Obj_Rel_Insert_Input;
  payloads_on_conflict: Payloads_On_Conflict;
  payloads_order_by: Payloads_Order_By;
  payloads_pk_columns_input: Payloads_Pk_Columns_Input;
  payloads_select_column: Payloads_Select_Column;
  payloads_set_input: Payloads_Set_Input;
  payloads_stddev_fields: ResolverTypeWrapper<Payloads_Stddev_Fields>;
  payloads_stddev_order_by: Payloads_Stddev_Order_By;
  payloads_stddev_pop_fields: ResolverTypeWrapper<Payloads_Stddev_Pop_Fields>;
  payloads_stddev_pop_order_by: Payloads_Stddev_Pop_Order_By;
  payloads_stddev_samp_fields: ResolverTypeWrapper<Payloads_Stddev_Samp_Fields>;
  payloads_stddev_samp_order_by: Payloads_Stddev_Samp_Order_By;
  payloads_stream_cursor_input: Payloads_Stream_Cursor_Input;
  payloads_stream_cursor_value_input: Payloads_Stream_Cursor_Value_Input;
  payloads_sum_fields: ResolverTypeWrapper<Payloads_Sum_Fields>;
  payloads_sum_order_by: Payloads_Sum_Order_By;
  payloads_update_column: Payloads_Update_Column;
  payloads_updates: Payloads_Updates;
  payloads_var_pop_fields: ResolverTypeWrapper<Payloads_Var_Pop_Fields>;
  payloads_var_pop_order_by: Payloads_Var_Pop_Order_By;
  payloads_var_samp_fields: ResolverTypeWrapper<Payloads_Var_Samp_Fields>;
  payloads_var_samp_order_by: Payloads_Var_Samp_Order_By;
  payloads_variance_fields: ResolverTypeWrapper<Payloads_Variance_Fields>;
  payloads_variance_order_by: Payloads_Variance_Order_By;
  performance_ratio_metric_contributions: ResolverTypeWrapper<Performance_Ratio_Metric_Contributions>;
  performance_ratio_metric_contributions_aggregate: ResolverTypeWrapper<Performance_Ratio_Metric_Contributions_Aggregate>;
  performance_ratio_metric_contributions_aggregate_fields: ResolverTypeWrapper<Performance_Ratio_Metric_Contributions_Aggregate_Fields>;
  performance_ratio_metric_contributions_aggregate_order_by: Performance_Ratio_Metric_Contributions_Aggregate_Order_By;
  performance_ratio_metric_contributions_arr_rel_insert_input: Performance_Ratio_Metric_Contributions_Arr_Rel_Insert_Input;
  performance_ratio_metric_contributions_avg_fields: ResolverTypeWrapper<Performance_Ratio_Metric_Contributions_Avg_Fields>;
  performance_ratio_metric_contributions_avg_order_by: Performance_Ratio_Metric_Contributions_Avg_Order_By;
  performance_ratio_metric_contributions_bool_exp: Performance_Ratio_Metric_Contributions_Bool_Exp;
  performance_ratio_metric_contributions_constraint: Performance_Ratio_Metric_Contributions_Constraint;
  performance_ratio_metric_contributions_inc_input: Performance_Ratio_Metric_Contributions_Inc_Input;
  performance_ratio_metric_contributions_insert_input: Performance_Ratio_Metric_Contributions_Insert_Input;
  performance_ratio_metric_contributions_max_fields: ResolverTypeWrapper<Performance_Ratio_Metric_Contributions_Max_Fields>;
  performance_ratio_metric_contributions_max_order_by: Performance_Ratio_Metric_Contributions_Max_Order_By;
  performance_ratio_metric_contributions_min_fields: ResolverTypeWrapper<Performance_Ratio_Metric_Contributions_Min_Fields>;
  performance_ratio_metric_contributions_min_order_by: Performance_Ratio_Metric_Contributions_Min_Order_By;
  performance_ratio_metric_contributions_mutation_response: ResolverTypeWrapper<Performance_Ratio_Metric_Contributions_Mutation_Response>;
  performance_ratio_metric_contributions_on_conflict: Performance_Ratio_Metric_Contributions_On_Conflict;
  performance_ratio_metric_contributions_order_by: Performance_Ratio_Metric_Contributions_Order_By;
  performance_ratio_metric_contributions_pk_columns_input: Performance_Ratio_Metric_Contributions_Pk_Columns_Input;
  performance_ratio_metric_contributions_select_column: Performance_Ratio_Metric_Contributions_Select_Column;
  performance_ratio_metric_contributions_set_input: Performance_Ratio_Metric_Contributions_Set_Input;
  performance_ratio_metric_contributions_stddev_fields: ResolverTypeWrapper<Performance_Ratio_Metric_Contributions_Stddev_Fields>;
  performance_ratio_metric_contributions_stddev_order_by: Performance_Ratio_Metric_Contributions_Stddev_Order_By;
  performance_ratio_metric_contributions_stddev_pop_fields: ResolverTypeWrapper<Performance_Ratio_Metric_Contributions_Stddev_Pop_Fields>;
  performance_ratio_metric_contributions_stddev_pop_order_by: Performance_Ratio_Metric_Contributions_Stddev_Pop_Order_By;
  performance_ratio_metric_contributions_stddev_samp_fields: ResolverTypeWrapper<Performance_Ratio_Metric_Contributions_Stddev_Samp_Fields>;
  performance_ratio_metric_contributions_stddev_samp_order_by: Performance_Ratio_Metric_Contributions_Stddev_Samp_Order_By;
  performance_ratio_metric_contributions_stream_cursor_input: Performance_Ratio_Metric_Contributions_Stream_Cursor_Input;
  performance_ratio_metric_contributions_stream_cursor_value_input: Performance_Ratio_Metric_Contributions_Stream_Cursor_Value_Input;
  performance_ratio_metric_contributions_sum_fields: ResolverTypeWrapper<Performance_Ratio_Metric_Contributions_Sum_Fields>;
  performance_ratio_metric_contributions_sum_order_by: Performance_Ratio_Metric_Contributions_Sum_Order_By;
  performance_ratio_metric_contributions_update_column: Performance_Ratio_Metric_Contributions_Update_Column;
  performance_ratio_metric_contributions_updates: Performance_Ratio_Metric_Contributions_Updates;
  performance_ratio_metric_contributions_var_pop_fields: ResolverTypeWrapper<Performance_Ratio_Metric_Contributions_Var_Pop_Fields>;
  performance_ratio_metric_contributions_var_pop_order_by: Performance_Ratio_Metric_Contributions_Var_Pop_Order_By;
  performance_ratio_metric_contributions_var_samp_fields: ResolverTypeWrapper<Performance_Ratio_Metric_Contributions_Var_Samp_Fields>;
  performance_ratio_metric_contributions_var_samp_order_by: Performance_Ratio_Metric_Contributions_Var_Samp_Order_By;
  performance_ratio_metric_contributions_variance_fields: ResolverTypeWrapper<Performance_Ratio_Metric_Contributions_Variance_Fields>;
  performance_ratio_metric_contributions_variance_order_by: Performance_Ratio_Metric_Contributions_Variance_Order_By;
  performance_ratio_metrics: ResolverTypeWrapper<Performance_Ratio_Metrics>;
  performance_ratio_metrics_aggregate: ResolverTypeWrapper<Performance_Ratio_Metrics_Aggregate>;
  performance_ratio_metrics_aggregate_fields: ResolverTypeWrapper<Performance_Ratio_Metrics_Aggregate_Fields>;
  performance_ratio_metrics_avg_fields: ResolverTypeWrapper<Performance_Ratio_Metrics_Avg_Fields>;
  performance_ratio_metrics_bool_exp: Performance_Ratio_Metrics_Bool_Exp;
  performance_ratio_metrics_constraint: Performance_Ratio_Metrics_Constraint;
  performance_ratio_metrics_inc_input: Performance_Ratio_Metrics_Inc_Input;
  performance_ratio_metrics_insert_input: Performance_Ratio_Metrics_Insert_Input;
  performance_ratio_metrics_max_fields: ResolverTypeWrapper<Performance_Ratio_Metrics_Max_Fields>;
  performance_ratio_metrics_min_fields: ResolverTypeWrapper<Performance_Ratio_Metrics_Min_Fields>;
  performance_ratio_metrics_mutation_response: ResolverTypeWrapper<Performance_Ratio_Metrics_Mutation_Response>;
  performance_ratio_metrics_obj_rel_insert_input: Performance_Ratio_Metrics_Obj_Rel_Insert_Input;
  performance_ratio_metrics_on_conflict: Performance_Ratio_Metrics_On_Conflict;
  performance_ratio_metrics_order_by: Performance_Ratio_Metrics_Order_By;
  performance_ratio_metrics_pk_columns_input: Performance_Ratio_Metrics_Pk_Columns_Input;
  performance_ratio_metrics_select_column: Performance_Ratio_Metrics_Select_Column;
  performance_ratio_metrics_set_input: Performance_Ratio_Metrics_Set_Input;
  performance_ratio_metrics_stddev_fields: ResolverTypeWrapper<Performance_Ratio_Metrics_Stddev_Fields>;
  performance_ratio_metrics_stddev_pop_fields: ResolverTypeWrapper<Performance_Ratio_Metrics_Stddev_Pop_Fields>;
  performance_ratio_metrics_stddev_samp_fields: ResolverTypeWrapper<Performance_Ratio_Metrics_Stddev_Samp_Fields>;
  performance_ratio_metrics_stream_cursor_input: Performance_Ratio_Metrics_Stream_Cursor_Input;
  performance_ratio_metrics_stream_cursor_value_input: Performance_Ratio_Metrics_Stream_Cursor_Value_Input;
  performance_ratio_metrics_sum_fields: ResolverTypeWrapper<Performance_Ratio_Metrics_Sum_Fields>;
  performance_ratio_metrics_update_column: Performance_Ratio_Metrics_Update_Column;
  performance_ratio_metrics_updates: Performance_Ratio_Metrics_Updates;
  performance_ratio_metrics_var_pop_fields: ResolverTypeWrapper<Performance_Ratio_Metrics_Var_Pop_Fields>;
  performance_ratio_metrics_var_samp_fields: ResolverTypeWrapper<Performance_Ratio_Metrics_Var_Samp_Fields>;
  performance_ratio_metrics_variance_fields: ResolverTypeWrapper<Performance_Ratio_Metrics_Variance_Fields>;
  performance_report_deliverable_template: ResolverTypeWrapper<Performance_Report_Deliverable_Template>;
  performance_report_deliverable_template_aggregate: ResolverTypeWrapper<Performance_Report_Deliverable_Template_Aggregate>;
  performance_report_deliverable_template_aggregate_fields: ResolverTypeWrapper<Performance_Report_Deliverable_Template_Aggregate_Fields>;
  performance_report_deliverable_template_aggregate_order_by: Performance_Report_Deliverable_Template_Aggregate_Order_By;
  performance_report_deliverable_template_arr_rel_insert_input: Performance_Report_Deliverable_Template_Arr_Rel_Insert_Input;
  performance_report_deliverable_template_avg_fields: ResolverTypeWrapper<Performance_Report_Deliverable_Template_Avg_Fields>;
  performance_report_deliverable_template_avg_order_by: Performance_Report_Deliverable_Template_Avg_Order_By;
  performance_report_deliverable_template_bool_exp: Performance_Report_Deliverable_Template_Bool_Exp;
  performance_report_deliverable_template_constraint: Performance_Report_Deliverable_Template_Constraint;
  performance_report_deliverable_template_inc_input: Performance_Report_Deliverable_Template_Inc_Input;
  performance_report_deliverable_template_insert_input: Performance_Report_Deliverable_Template_Insert_Input;
  performance_report_deliverable_template_max_fields: ResolverTypeWrapper<Performance_Report_Deliverable_Template_Max_Fields>;
  performance_report_deliverable_template_max_order_by: Performance_Report_Deliverable_Template_Max_Order_By;
  performance_report_deliverable_template_min_fields: ResolverTypeWrapper<Performance_Report_Deliverable_Template_Min_Fields>;
  performance_report_deliverable_template_min_order_by: Performance_Report_Deliverable_Template_Min_Order_By;
  performance_report_deliverable_template_mutation_response: ResolverTypeWrapper<Performance_Report_Deliverable_Template_Mutation_Response>;
  performance_report_deliverable_template_on_conflict: Performance_Report_Deliverable_Template_On_Conflict;
  performance_report_deliverable_template_order_by: Performance_Report_Deliverable_Template_Order_By;
  performance_report_deliverable_template_pk_columns_input: Performance_Report_Deliverable_Template_Pk_Columns_Input;
  performance_report_deliverable_template_select_column: Performance_Report_Deliverable_Template_Select_Column;
  performance_report_deliverable_template_set_input: Performance_Report_Deliverable_Template_Set_Input;
  performance_report_deliverable_template_stddev_fields: ResolverTypeWrapper<Performance_Report_Deliverable_Template_Stddev_Fields>;
  performance_report_deliverable_template_stddev_order_by: Performance_Report_Deliverable_Template_Stddev_Order_By;
  performance_report_deliverable_template_stddev_pop_fields: ResolverTypeWrapper<Performance_Report_Deliverable_Template_Stddev_Pop_Fields>;
  performance_report_deliverable_template_stddev_pop_order_by: Performance_Report_Deliverable_Template_Stddev_Pop_Order_By;
  performance_report_deliverable_template_stddev_samp_fields: ResolverTypeWrapper<Performance_Report_Deliverable_Template_Stddev_Samp_Fields>;
  performance_report_deliverable_template_stddev_samp_order_by: Performance_Report_Deliverable_Template_Stddev_Samp_Order_By;
  performance_report_deliverable_template_stream_cursor_input: Performance_Report_Deliverable_Template_Stream_Cursor_Input;
  performance_report_deliverable_template_stream_cursor_value_input: Performance_Report_Deliverable_Template_Stream_Cursor_Value_Input;
  performance_report_deliverable_template_sum_fields: ResolverTypeWrapper<Performance_Report_Deliverable_Template_Sum_Fields>;
  performance_report_deliverable_template_sum_order_by: Performance_Report_Deliverable_Template_Sum_Order_By;
  performance_report_deliverable_template_update_column: Performance_Report_Deliverable_Template_Update_Column;
  performance_report_deliverable_template_updates: Performance_Report_Deliverable_Template_Updates;
  performance_report_deliverable_template_var_pop_fields: ResolverTypeWrapper<Performance_Report_Deliverable_Template_Var_Pop_Fields>;
  performance_report_deliverable_template_var_pop_order_by: Performance_Report_Deliverable_Template_Var_Pop_Order_By;
  performance_report_deliverable_template_var_samp_fields: ResolverTypeWrapper<Performance_Report_Deliverable_Template_Var_Samp_Fields>;
  performance_report_deliverable_template_var_samp_order_by: Performance_Report_Deliverable_Template_Var_Samp_Order_By;
  performance_report_deliverable_template_variance_fields: ResolverTypeWrapper<Performance_Report_Deliverable_Template_Variance_Fields>;
  performance_report_deliverable_template_variance_order_by: Performance_Report_Deliverable_Template_Variance_Order_By;
  performance_report_pivot_types: ResolverTypeWrapper<Performance_Report_Pivot_Types>;
  performance_report_pivot_types_aggregate: ResolverTypeWrapper<Performance_Report_Pivot_Types_Aggregate>;
  performance_report_pivot_types_aggregate_fields: ResolverTypeWrapper<Performance_Report_Pivot_Types_Aggregate_Fields>;
  performance_report_pivot_types_bool_exp: Performance_Report_Pivot_Types_Bool_Exp;
  performance_report_pivot_types_enum: Performance_Report_Pivot_Types_Enum;
  performance_report_pivot_types_enum_comparison_exp: Performance_Report_Pivot_Types_Enum_Comparison_Exp;
  performance_report_pivot_types_max_fields: ResolverTypeWrapper<Performance_Report_Pivot_Types_Max_Fields>;
  performance_report_pivot_types_min_fields: ResolverTypeWrapper<Performance_Report_Pivot_Types_Min_Fields>;
  performance_report_pivot_types_mutation_response: ResolverTypeWrapper<Performance_Report_Pivot_Types_Mutation_Response>;
  performance_report_pivot_types_order_by: Performance_Report_Pivot_Types_Order_By;
  performance_report_pivot_types_pk_columns_input: Performance_Report_Pivot_Types_Pk_Columns_Input;
  performance_report_pivot_types_select_column: Performance_Report_Pivot_Types_Select_Column;
  performance_report_pivot_types_set_input: Performance_Report_Pivot_Types_Set_Input;
  performance_report_pivot_types_stream_cursor_input: Performance_Report_Pivot_Types_Stream_Cursor_Input;
  performance_report_pivot_types_stream_cursor_value_input: Performance_Report_Pivot_Types_Stream_Cursor_Value_Input;
  performance_report_pivot_types_updates: Performance_Report_Pivot_Types_Updates;
  performance_report_section_pivot_field: ResolverTypeWrapper<Performance_Report_Section_Pivot_Field>;
  performance_report_section_pivot_field_aggregate: ResolverTypeWrapper<Performance_Report_Section_Pivot_Field_Aggregate>;
  performance_report_section_pivot_field_aggregate_fields: ResolverTypeWrapper<Performance_Report_Section_Pivot_Field_Aggregate_Fields>;
  performance_report_section_pivot_field_aggregate_order_by: Performance_Report_Section_Pivot_Field_Aggregate_Order_By;
  performance_report_section_pivot_field_arr_rel_insert_input: Performance_Report_Section_Pivot_Field_Arr_Rel_Insert_Input;
  performance_report_section_pivot_field_avg_fields: ResolverTypeWrapper<Performance_Report_Section_Pivot_Field_Avg_Fields>;
  performance_report_section_pivot_field_avg_order_by: Performance_Report_Section_Pivot_Field_Avg_Order_By;
  performance_report_section_pivot_field_bool_exp: Performance_Report_Section_Pivot_Field_Bool_Exp;
  performance_report_section_pivot_field_constraint: Performance_Report_Section_Pivot_Field_Constraint;
  performance_report_section_pivot_field_inc_input: Performance_Report_Section_Pivot_Field_Inc_Input;
  performance_report_section_pivot_field_insert_input: Performance_Report_Section_Pivot_Field_Insert_Input;
  performance_report_section_pivot_field_max_fields: ResolverTypeWrapper<Performance_Report_Section_Pivot_Field_Max_Fields>;
  performance_report_section_pivot_field_max_order_by: Performance_Report_Section_Pivot_Field_Max_Order_By;
  performance_report_section_pivot_field_min_fields: ResolverTypeWrapper<Performance_Report_Section_Pivot_Field_Min_Fields>;
  performance_report_section_pivot_field_min_order_by: Performance_Report_Section_Pivot_Field_Min_Order_By;
  performance_report_section_pivot_field_mutation_response: ResolverTypeWrapper<Performance_Report_Section_Pivot_Field_Mutation_Response>;
  performance_report_section_pivot_field_on_conflict: Performance_Report_Section_Pivot_Field_On_Conflict;
  performance_report_section_pivot_field_order_by: Performance_Report_Section_Pivot_Field_Order_By;
  performance_report_section_pivot_field_pk_columns_input: Performance_Report_Section_Pivot_Field_Pk_Columns_Input;
  performance_report_section_pivot_field_select_column: Performance_Report_Section_Pivot_Field_Select_Column;
  performance_report_section_pivot_field_set_input: Performance_Report_Section_Pivot_Field_Set_Input;
  performance_report_section_pivot_field_stddev_fields: ResolverTypeWrapper<Performance_Report_Section_Pivot_Field_Stddev_Fields>;
  performance_report_section_pivot_field_stddev_order_by: Performance_Report_Section_Pivot_Field_Stddev_Order_By;
  performance_report_section_pivot_field_stddev_pop_fields: ResolverTypeWrapper<Performance_Report_Section_Pivot_Field_Stddev_Pop_Fields>;
  performance_report_section_pivot_field_stddev_pop_order_by: Performance_Report_Section_Pivot_Field_Stddev_Pop_Order_By;
  performance_report_section_pivot_field_stddev_samp_fields: ResolverTypeWrapper<Performance_Report_Section_Pivot_Field_Stddev_Samp_Fields>;
  performance_report_section_pivot_field_stddev_samp_order_by: Performance_Report_Section_Pivot_Field_Stddev_Samp_Order_By;
  performance_report_section_pivot_field_stream_cursor_input: Performance_Report_Section_Pivot_Field_Stream_Cursor_Input;
  performance_report_section_pivot_field_stream_cursor_value_input: Performance_Report_Section_Pivot_Field_Stream_Cursor_Value_Input;
  performance_report_section_pivot_field_sum_fields: ResolverTypeWrapper<Performance_Report_Section_Pivot_Field_Sum_Fields>;
  performance_report_section_pivot_field_sum_order_by: Performance_Report_Section_Pivot_Field_Sum_Order_By;
  performance_report_section_pivot_field_update_column: Performance_Report_Section_Pivot_Field_Update_Column;
  performance_report_section_pivot_field_updates: Performance_Report_Section_Pivot_Field_Updates;
  performance_report_section_pivot_field_var_pop_fields: ResolverTypeWrapper<Performance_Report_Section_Pivot_Field_Var_Pop_Fields>;
  performance_report_section_pivot_field_var_pop_order_by: Performance_Report_Section_Pivot_Field_Var_Pop_Order_By;
  performance_report_section_pivot_field_var_samp_fields: ResolverTypeWrapper<Performance_Report_Section_Pivot_Field_Var_Samp_Fields>;
  performance_report_section_pivot_field_var_samp_order_by: Performance_Report_Section_Pivot_Field_Var_Samp_Order_By;
  performance_report_section_pivot_field_variance_fields: ResolverTypeWrapper<Performance_Report_Section_Pivot_Field_Variance_Fields>;
  performance_report_section_pivot_field_variance_order_by: Performance_Report_Section_Pivot_Field_Variance_Order_By;
  performance_report_section_ratio_metric: ResolverTypeWrapper<Performance_Report_Section_Ratio_Metric>;
  performance_report_section_ratio_metric_aggregate: ResolverTypeWrapper<Performance_Report_Section_Ratio_Metric_Aggregate>;
  performance_report_section_ratio_metric_aggregate_fields: ResolverTypeWrapper<Performance_Report_Section_Ratio_Metric_Aggregate_Fields>;
  performance_report_section_ratio_metric_aggregate_order_by: Performance_Report_Section_Ratio_Metric_Aggregate_Order_By;
  performance_report_section_ratio_metric_arr_rel_insert_input: Performance_Report_Section_Ratio_Metric_Arr_Rel_Insert_Input;
  performance_report_section_ratio_metric_avg_fields: ResolverTypeWrapper<Performance_Report_Section_Ratio_Metric_Avg_Fields>;
  performance_report_section_ratio_metric_avg_order_by: Performance_Report_Section_Ratio_Metric_Avg_Order_By;
  performance_report_section_ratio_metric_bool_exp: Performance_Report_Section_Ratio_Metric_Bool_Exp;
  performance_report_section_ratio_metric_constraint: Performance_Report_Section_Ratio_Metric_Constraint;
  performance_report_section_ratio_metric_inc_input: Performance_Report_Section_Ratio_Metric_Inc_Input;
  performance_report_section_ratio_metric_insert_input: Performance_Report_Section_Ratio_Metric_Insert_Input;
  performance_report_section_ratio_metric_max_fields: ResolverTypeWrapper<Performance_Report_Section_Ratio_Metric_Max_Fields>;
  performance_report_section_ratio_metric_max_order_by: Performance_Report_Section_Ratio_Metric_Max_Order_By;
  performance_report_section_ratio_metric_min_fields: ResolverTypeWrapper<Performance_Report_Section_Ratio_Metric_Min_Fields>;
  performance_report_section_ratio_metric_min_order_by: Performance_Report_Section_Ratio_Metric_Min_Order_By;
  performance_report_section_ratio_metric_mutation_response: ResolverTypeWrapper<Performance_Report_Section_Ratio_Metric_Mutation_Response>;
  performance_report_section_ratio_metric_on_conflict: Performance_Report_Section_Ratio_Metric_On_Conflict;
  performance_report_section_ratio_metric_order_by: Performance_Report_Section_Ratio_Metric_Order_By;
  performance_report_section_ratio_metric_pk_columns_input: Performance_Report_Section_Ratio_Metric_Pk_Columns_Input;
  performance_report_section_ratio_metric_select_column: Performance_Report_Section_Ratio_Metric_Select_Column;
  performance_report_section_ratio_metric_set_input: Performance_Report_Section_Ratio_Metric_Set_Input;
  performance_report_section_ratio_metric_stddev_fields: ResolverTypeWrapper<Performance_Report_Section_Ratio_Metric_Stddev_Fields>;
  performance_report_section_ratio_metric_stddev_order_by: Performance_Report_Section_Ratio_Metric_Stddev_Order_By;
  performance_report_section_ratio_metric_stddev_pop_fields: ResolverTypeWrapper<Performance_Report_Section_Ratio_Metric_Stddev_Pop_Fields>;
  performance_report_section_ratio_metric_stddev_pop_order_by: Performance_Report_Section_Ratio_Metric_Stddev_Pop_Order_By;
  performance_report_section_ratio_metric_stddev_samp_fields: ResolverTypeWrapper<Performance_Report_Section_Ratio_Metric_Stddev_Samp_Fields>;
  performance_report_section_ratio_metric_stddev_samp_order_by: Performance_Report_Section_Ratio_Metric_Stddev_Samp_Order_By;
  performance_report_section_ratio_metric_stream_cursor_input: Performance_Report_Section_Ratio_Metric_Stream_Cursor_Input;
  performance_report_section_ratio_metric_stream_cursor_value_input: Performance_Report_Section_Ratio_Metric_Stream_Cursor_Value_Input;
  performance_report_section_ratio_metric_sum_fields: ResolverTypeWrapper<Performance_Report_Section_Ratio_Metric_Sum_Fields>;
  performance_report_section_ratio_metric_sum_order_by: Performance_Report_Section_Ratio_Metric_Sum_Order_By;
  performance_report_section_ratio_metric_update_column: Performance_Report_Section_Ratio_Metric_Update_Column;
  performance_report_section_ratio_metric_updates: Performance_Report_Section_Ratio_Metric_Updates;
  performance_report_section_ratio_metric_var_pop_fields: ResolverTypeWrapper<Performance_Report_Section_Ratio_Metric_Var_Pop_Fields>;
  performance_report_section_ratio_metric_var_pop_order_by: Performance_Report_Section_Ratio_Metric_Var_Pop_Order_By;
  performance_report_section_ratio_metric_var_samp_fields: ResolverTypeWrapper<Performance_Report_Section_Ratio_Metric_Var_Samp_Fields>;
  performance_report_section_ratio_metric_var_samp_order_by: Performance_Report_Section_Ratio_Metric_Var_Samp_Order_By;
  performance_report_section_ratio_metric_variance_fields: ResolverTypeWrapper<Performance_Report_Section_Ratio_Metric_Variance_Fields>;
  performance_report_section_ratio_metric_variance_order_by: Performance_Report_Section_Ratio_Metric_Variance_Order_By;
  performance_report_section_task_blacklist: ResolverTypeWrapper<Performance_Report_Section_Task_Blacklist>;
  performance_report_section_task_blacklist_aggregate: ResolverTypeWrapper<Performance_Report_Section_Task_Blacklist_Aggregate>;
  performance_report_section_task_blacklist_aggregate_fields: ResolverTypeWrapper<Performance_Report_Section_Task_Blacklist_Aggregate_Fields>;
  performance_report_section_task_blacklist_aggregate_order_by: Performance_Report_Section_Task_Blacklist_Aggregate_Order_By;
  performance_report_section_task_blacklist_arr_rel_insert_input: Performance_Report_Section_Task_Blacklist_Arr_Rel_Insert_Input;
  performance_report_section_task_blacklist_bool_exp: Performance_Report_Section_Task_Blacklist_Bool_Exp;
  performance_report_section_task_blacklist_constraint: Performance_Report_Section_Task_Blacklist_Constraint;
  performance_report_section_task_blacklist_insert_input: Performance_Report_Section_Task_Blacklist_Insert_Input;
  performance_report_section_task_blacklist_max_fields: ResolverTypeWrapper<Performance_Report_Section_Task_Blacklist_Max_Fields>;
  performance_report_section_task_blacklist_max_order_by: Performance_Report_Section_Task_Blacklist_Max_Order_By;
  performance_report_section_task_blacklist_min_fields: ResolverTypeWrapper<Performance_Report_Section_Task_Blacklist_Min_Fields>;
  performance_report_section_task_blacklist_min_order_by: Performance_Report_Section_Task_Blacklist_Min_Order_By;
  performance_report_section_task_blacklist_mutation_response: ResolverTypeWrapper<Performance_Report_Section_Task_Blacklist_Mutation_Response>;
  performance_report_section_task_blacklist_on_conflict: Performance_Report_Section_Task_Blacklist_On_Conflict;
  performance_report_section_task_blacklist_order_by: Performance_Report_Section_Task_Blacklist_Order_By;
  performance_report_section_task_blacklist_pk_columns_input: Performance_Report_Section_Task_Blacklist_Pk_Columns_Input;
  performance_report_section_task_blacklist_select_column: Performance_Report_Section_Task_Blacklist_Select_Column;
  performance_report_section_task_blacklist_set_input: Performance_Report_Section_Task_Blacklist_Set_Input;
  performance_report_section_task_blacklist_stream_cursor_input: Performance_Report_Section_Task_Blacklist_Stream_Cursor_Input;
  performance_report_section_task_blacklist_stream_cursor_value_input: Performance_Report_Section_Task_Blacklist_Stream_Cursor_Value_Input;
  performance_report_section_task_blacklist_update_column: Performance_Report_Section_Task_Blacklist_Update_Column;
  performance_report_section_task_blacklist_updates: Performance_Report_Section_Task_Blacklist_Updates;
  performance_report_sections: ResolverTypeWrapper<Performance_Report_Sections>;
  performance_report_sections_aggregate: ResolverTypeWrapper<Performance_Report_Sections_Aggregate>;
  performance_report_sections_aggregate_fields: ResolverTypeWrapper<Performance_Report_Sections_Aggregate_Fields>;
  performance_report_sections_aggregate_order_by: Performance_Report_Sections_Aggregate_Order_By;
  performance_report_sections_arr_rel_insert_input: Performance_Report_Sections_Arr_Rel_Insert_Input;
  performance_report_sections_avg_fields: ResolverTypeWrapper<Performance_Report_Sections_Avg_Fields>;
  performance_report_sections_avg_order_by: Performance_Report_Sections_Avg_Order_By;
  performance_report_sections_bool_exp: Performance_Report_Sections_Bool_Exp;
  performance_report_sections_constraint: Performance_Report_Sections_Constraint;
  performance_report_sections_inc_input: Performance_Report_Sections_Inc_Input;
  performance_report_sections_insert_input: Performance_Report_Sections_Insert_Input;
  performance_report_sections_max_fields: ResolverTypeWrapper<Performance_Report_Sections_Max_Fields>;
  performance_report_sections_max_order_by: Performance_Report_Sections_Max_Order_By;
  performance_report_sections_min_fields: ResolverTypeWrapper<Performance_Report_Sections_Min_Fields>;
  performance_report_sections_min_order_by: Performance_Report_Sections_Min_Order_By;
  performance_report_sections_mutation_response: ResolverTypeWrapper<Performance_Report_Sections_Mutation_Response>;
  performance_report_sections_obj_rel_insert_input: Performance_Report_Sections_Obj_Rel_Insert_Input;
  performance_report_sections_on_conflict: Performance_Report_Sections_On_Conflict;
  performance_report_sections_order_by: Performance_Report_Sections_Order_By;
  performance_report_sections_pk_columns_input: Performance_Report_Sections_Pk_Columns_Input;
  performance_report_sections_select_column: Performance_Report_Sections_Select_Column;
  performance_report_sections_set_input: Performance_Report_Sections_Set_Input;
  performance_report_sections_stddev_fields: ResolverTypeWrapper<Performance_Report_Sections_Stddev_Fields>;
  performance_report_sections_stddev_order_by: Performance_Report_Sections_Stddev_Order_By;
  performance_report_sections_stddev_pop_fields: ResolverTypeWrapper<Performance_Report_Sections_Stddev_Pop_Fields>;
  performance_report_sections_stddev_pop_order_by: Performance_Report_Sections_Stddev_Pop_Order_By;
  performance_report_sections_stddev_samp_fields: ResolverTypeWrapper<Performance_Report_Sections_Stddev_Samp_Fields>;
  performance_report_sections_stddev_samp_order_by: Performance_Report_Sections_Stddev_Samp_Order_By;
  performance_report_sections_stream_cursor_input: Performance_Report_Sections_Stream_Cursor_Input;
  performance_report_sections_stream_cursor_value_input: Performance_Report_Sections_Stream_Cursor_Value_Input;
  performance_report_sections_sum_fields: ResolverTypeWrapper<Performance_Report_Sections_Sum_Fields>;
  performance_report_sections_sum_order_by: Performance_Report_Sections_Sum_Order_By;
  performance_report_sections_update_column: Performance_Report_Sections_Update_Column;
  performance_report_sections_updates: Performance_Report_Sections_Updates;
  performance_report_sections_var_pop_fields: ResolverTypeWrapper<Performance_Report_Sections_Var_Pop_Fields>;
  performance_report_sections_var_pop_order_by: Performance_Report_Sections_Var_Pop_Order_By;
  performance_report_sections_var_samp_fields: ResolverTypeWrapper<Performance_Report_Sections_Var_Samp_Fields>;
  performance_report_sections_var_samp_order_by: Performance_Report_Sections_Var_Samp_Order_By;
  performance_report_sections_variance_fields: ResolverTypeWrapper<Performance_Report_Sections_Variance_Fields>;
  performance_report_sections_variance_order_by: Performance_Report_Sections_Variance_Order_By;
  performance_report_templates: ResolverTypeWrapper<Performance_Report_Templates>;
  performance_report_templates_aggregate: ResolverTypeWrapper<Performance_Report_Templates_Aggregate>;
  performance_report_templates_aggregate_fields: ResolverTypeWrapper<Performance_Report_Templates_Aggregate_Fields>;
  performance_report_templates_bool_exp: Performance_Report_Templates_Bool_Exp;
  performance_report_templates_constraint: Performance_Report_Templates_Constraint;
  performance_report_templates_insert_input: Performance_Report_Templates_Insert_Input;
  performance_report_templates_max_fields: ResolverTypeWrapper<Performance_Report_Templates_Max_Fields>;
  performance_report_templates_min_fields: ResolverTypeWrapper<Performance_Report_Templates_Min_Fields>;
  performance_report_templates_mutation_response: ResolverTypeWrapper<Performance_Report_Templates_Mutation_Response>;
  performance_report_templates_obj_rel_insert_input: Performance_Report_Templates_Obj_Rel_Insert_Input;
  performance_report_templates_on_conflict: Performance_Report_Templates_On_Conflict;
  performance_report_templates_order_by: Performance_Report_Templates_Order_By;
  performance_report_templates_pk_columns_input: Performance_Report_Templates_Pk_Columns_Input;
  performance_report_templates_select_column: Performance_Report_Templates_Select_Column;
  performance_report_templates_set_input: Performance_Report_Templates_Set_Input;
  performance_report_templates_stream_cursor_input: Performance_Report_Templates_Stream_Cursor_Input;
  performance_report_templates_stream_cursor_value_input: Performance_Report_Templates_Stream_Cursor_Value_Input;
  performance_report_templates_update_column: Performance_Report_Templates_Update_Column;
  performance_report_templates_updates: Performance_Report_Templates_Updates;
  peripherals: ResolverTypeWrapper<Peripherals>;
  peripherals_bool_exp: Peripherals_Bool_Exp;
  peripherals_constraint: Peripherals_Constraint;
  peripherals_insert_input: Peripherals_Insert_Input;
  peripherals_mutation_response: ResolverTypeWrapper<Peripherals_Mutation_Response>;
  peripherals_on_conflict: Peripherals_On_Conflict;
  peripherals_order_by: Peripherals_Order_By;
  peripherals_pk_columns_input: Peripherals_Pk_Columns_Input;
  peripherals_select_column: Peripherals_Select_Column;
  peripherals_set_input: Peripherals_Set_Input;
  peripherals_stream_cursor_input: Peripherals_Stream_Cursor_Input;
  peripherals_stream_cursor_value_input: Peripherals_Stream_Cursor_Value_Input;
  peripherals_update_column: Peripherals_Update_Column;
  peripherals_updates: Peripherals_Updates;
  plan_hypertasks: ResolverTypeWrapper<Plan_Hypertasks>;
  plan_hypertasks_aggregate: ResolverTypeWrapper<Plan_Hypertasks_Aggregate>;
  plan_hypertasks_aggregate_fields: ResolverTypeWrapper<Plan_Hypertasks_Aggregate_Fields>;
  plan_hypertasks_aggregate_order_by: Plan_Hypertasks_Aggregate_Order_By;
  plan_hypertasks_arr_rel_insert_input: Plan_Hypertasks_Arr_Rel_Insert_Input;
  plan_hypertasks_avg_fields: ResolverTypeWrapper<Plan_Hypertasks_Avg_Fields>;
  plan_hypertasks_avg_order_by: Plan_Hypertasks_Avg_Order_By;
  plan_hypertasks_bool_exp: Plan_Hypertasks_Bool_Exp;
  plan_hypertasks_constraint: Plan_Hypertasks_Constraint;
  plan_hypertasks_inc_input: Plan_Hypertasks_Inc_Input;
  plan_hypertasks_insert_input: Plan_Hypertasks_Insert_Input;
  plan_hypertasks_max_fields: ResolverTypeWrapper<Plan_Hypertasks_Max_Fields>;
  plan_hypertasks_max_order_by: Plan_Hypertasks_Max_Order_By;
  plan_hypertasks_min_fields: ResolverTypeWrapper<Plan_Hypertasks_Min_Fields>;
  plan_hypertasks_min_order_by: Plan_Hypertasks_Min_Order_By;
  plan_hypertasks_mutation_response: ResolverTypeWrapper<Plan_Hypertasks_Mutation_Response>;
  plan_hypertasks_on_conflict: Plan_Hypertasks_On_Conflict;
  plan_hypertasks_order_by: Plan_Hypertasks_Order_By;
  plan_hypertasks_pk_columns_input: Plan_Hypertasks_Pk_Columns_Input;
  plan_hypertasks_select_column: Plan_Hypertasks_Select_Column;
  plan_hypertasks_set_input: Plan_Hypertasks_Set_Input;
  plan_hypertasks_stddev_fields: ResolverTypeWrapper<Plan_Hypertasks_Stddev_Fields>;
  plan_hypertasks_stddev_order_by: Plan_Hypertasks_Stddev_Order_By;
  plan_hypertasks_stddev_pop_fields: ResolverTypeWrapper<Plan_Hypertasks_Stddev_Pop_Fields>;
  plan_hypertasks_stddev_pop_order_by: Plan_Hypertasks_Stddev_Pop_Order_By;
  plan_hypertasks_stddev_samp_fields: ResolverTypeWrapper<Plan_Hypertasks_Stddev_Samp_Fields>;
  plan_hypertasks_stddev_samp_order_by: Plan_Hypertasks_Stddev_Samp_Order_By;
  plan_hypertasks_stream_cursor_input: Plan_Hypertasks_Stream_Cursor_Input;
  plan_hypertasks_stream_cursor_value_input: Plan_Hypertasks_Stream_Cursor_Value_Input;
  plan_hypertasks_sum_fields: ResolverTypeWrapper<Plan_Hypertasks_Sum_Fields>;
  plan_hypertasks_sum_order_by: Plan_Hypertasks_Sum_Order_By;
  plan_hypertasks_update_column: Plan_Hypertasks_Update_Column;
  plan_hypertasks_updates: Plan_Hypertasks_Updates;
  plan_hypertasks_var_pop_fields: ResolverTypeWrapper<Plan_Hypertasks_Var_Pop_Fields>;
  plan_hypertasks_var_pop_order_by: Plan_Hypertasks_Var_Pop_Order_By;
  plan_hypertasks_var_samp_fields: ResolverTypeWrapper<Plan_Hypertasks_Var_Samp_Fields>;
  plan_hypertasks_var_samp_order_by: Plan_Hypertasks_Var_Samp_Order_By;
  plan_hypertasks_variance_fields: ResolverTypeWrapper<Plan_Hypertasks_Variance_Fields>;
  plan_hypertasks_variance_order_by: Plan_Hypertasks_Variance_Order_By;
  plan_tasks: ResolverTypeWrapper<Plan_Tasks>;
  plan_tasks_aggregate: ResolverTypeWrapper<Plan_Tasks_Aggregate>;
  plan_tasks_aggregate_fields: ResolverTypeWrapper<Plan_Tasks_Aggregate_Fields>;
  plan_tasks_aggregate_order_by: Plan_Tasks_Aggregate_Order_By;
  plan_tasks_arr_rel_insert_input: Plan_Tasks_Arr_Rel_Insert_Input;
  plan_tasks_avg_fields: ResolverTypeWrapper<Plan_Tasks_Avg_Fields>;
  plan_tasks_avg_order_by: Plan_Tasks_Avg_Order_By;
  plan_tasks_bool_exp: Plan_Tasks_Bool_Exp;
  plan_tasks_constraint: Plan_Tasks_Constraint;
  plan_tasks_inc_input: Plan_Tasks_Inc_Input;
  plan_tasks_insert_input: Plan_Tasks_Insert_Input;
  plan_tasks_max_fields: ResolverTypeWrapper<Plan_Tasks_Max_Fields>;
  plan_tasks_max_order_by: Plan_Tasks_Max_Order_By;
  plan_tasks_min_fields: ResolverTypeWrapper<Plan_Tasks_Min_Fields>;
  plan_tasks_min_order_by: Plan_Tasks_Min_Order_By;
  plan_tasks_mutation_response: ResolverTypeWrapper<Plan_Tasks_Mutation_Response>;
  plan_tasks_on_conflict: Plan_Tasks_On_Conflict;
  plan_tasks_order_by: Plan_Tasks_Order_By;
  plan_tasks_pk_columns_input: Plan_Tasks_Pk_Columns_Input;
  plan_tasks_select_column: Plan_Tasks_Select_Column;
  plan_tasks_set_input: Plan_Tasks_Set_Input;
  plan_tasks_stddev_fields: ResolverTypeWrapper<Plan_Tasks_Stddev_Fields>;
  plan_tasks_stddev_order_by: Plan_Tasks_Stddev_Order_By;
  plan_tasks_stddev_pop_fields: ResolverTypeWrapper<Plan_Tasks_Stddev_Pop_Fields>;
  plan_tasks_stddev_pop_order_by: Plan_Tasks_Stddev_Pop_Order_By;
  plan_tasks_stddev_samp_fields: ResolverTypeWrapper<Plan_Tasks_Stddev_Samp_Fields>;
  plan_tasks_stddev_samp_order_by: Plan_Tasks_Stddev_Samp_Order_By;
  plan_tasks_stream_cursor_input: Plan_Tasks_Stream_Cursor_Input;
  plan_tasks_stream_cursor_value_input: Plan_Tasks_Stream_Cursor_Value_Input;
  plan_tasks_sum_fields: ResolverTypeWrapper<Plan_Tasks_Sum_Fields>;
  plan_tasks_sum_order_by: Plan_Tasks_Sum_Order_By;
  plan_tasks_update_column: Plan_Tasks_Update_Column;
  plan_tasks_updates: Plan_Tasks_Updates;
  plan_tasks_var_pop_fields: ResolverTypeWrapper<Plan_Tasks_Var_Pop_Fields>;
  plan_tasks_var_pop_order_by: Plan_Tasks_Var_Pop_Order_By;
  plan_tasks_var_samp_fields: ResolverTypeWrapper<Plan_Tasks_Var_Samp_Fields>;
  plan_tasks_var_samp_order_by: Plan_Tasks_Var_Samp_Order_By;
  plan_tasks_variance_fields: ResolverTypeWrapper<Plan_Tasks_Variance_Fields>;
  plan_tasks_variance_order_by: Plan_Tasks_Variance_Order_By;
  plans: ResolverTypeWrapper<Plans>;
  plans_aggregate: ResolverTypeWrapper<Plans_Aggregate>;
  plans_aggregate_fields: ResolverTypeWrapper<Plans_Aggregate_Fields>;
  plans_bool_exp: Plans_Bool_Exp;
  plans_constraint: Plans_Constraint;
  plans_insert_input: Plans_Insert_Input;
  plans_max_fields: ResolverTypeWrapper<Plans_Max_Fields>;
  plans_min_fields: ResolverTypeWrapper<Plans_Min_Fields>;
  plans_mutation_response: ResolverTypeWrapper<Plans_Mutation_Response>;
  plans_obj_rel_insert_input: Plans_Obj_Rel_Insert_Input;
  plans_on_conflict: Plans_On_Conflict;
  plans_order_by: Plans_Order_By;
  plans_pk_columns_input: Plans_Pk_Columns_Input;
  plans_select_column: Plans_Select_Column;
  plans_set_input: Plans_Set_Input;
  plans_stream_cursor_input: Plans_Stream_Cursor_Input;
  plans_stream_cursor_value_input: Plans_Stream_Cursor_Value_Input;
  plans_update_column: Plans_Update_Column;
  plans_updates: Plans_Updates;
  profiles: ResolverTypeWrapper<Profiles>;
  profiles_aggregate: ResolverTypeWrapper<Profiles_Aggregate>;
  profiles_aggregate_fields: ResolverTypeWrapper<Profiles_Aggregate_Fields>;
  profiles_bool_exp: Profiles_Bool_Exp;
  profiles_constraint: Profiles_Constraint;
  profiles_insert_input: Profiles_Insert_Input;
  profiles_max_fields: ResolverTypeWrapper<Profiles_Max_Fields>;
  profiles_min_fields: ResolverTypeWrapper<Profiles_Min_Fields>;
  profiles_mutation_response: ResolverTypeWrapper<Profiles_Mutation_Response>;
  profiles_obj_rel_insert_input: Profiles_Obj_Rel_Insert_Input;
  profiles_on_conflict: Profiles_On_Conflict;
  profiles_order_by: Profiles_Order_By;
  profiles_pk_columns_input: Profiles_Pk_Columns_Input;
  profiles_select_column: Profiles_Select_Column;
  profiles_set_input: Profiles_Set_Input;
  profiles_stream_cursor_input: Profiles_Stream_Cursor_Input;
  profiles_stream_cursor_value_input: Profiles_Stream_Cursor_Value_Input;
  profiles_update_column: Profiles_Update_Column;
  profiles_updates: Profiles_Updates;
  project_combo: ResolverTypeWrapper<Project_Combo>;
  project_combo_aggregate: ResolverTypeWrapper<Project_Combo_Aggregate>;
  project_combo_aggregate_fields: ResolverTypeWrapper<Project_Combo_Aggregate_Fields>;
  project_combo_aggregate_order_by: Project_Combo_Aggregate_Order_By;
  project_combo_arr_rel_insert_input: Project_Combo_Arr_Rel_Insert_Input;
  project_combo_bool_exp: Project_Combo_Bool_Exp;
  project_combo_constraint: Project_Combo_Constraint;
  project_combo_insert_input: Project_Combo_Insert_Input;
  project_combo_max_fields: ResolverTypeWrapper<Project_Combo_Max_Fields>;
  project_combo_max_order_by: Project_Combo_Max_Order_By;
  project_combo_min_fields: ResolverTypeWrapper<Project_Combo_Min_Fields>;
  project_combo_min_order_by: Project_Combo_Min_Order_By;
  project_combo_mutation_response: ResolverTypeWrapper<Project_Combo_Mutation_Response>;
  project_combo_on_conflict: Project_Combo_On_Conflict;
  project_combo_order_by: Project_Combo_Order_By;
  project_combo_pk_columns_input: Project_Combo_Pk_Columns_Input;
  project_combo_select_column: Project_Combo_Select_Column;
  project_combo_set_input: Project_Combo_Set_Input;
  project_combo_stream_cursor_input: Project_Combo_Stream_Cursor_Input;
  project_combo_stream_cursor_value_input: Project_Combo_Stream_Cursor_Value_Input;
  project_combo_update_column: Project_Combo_Update_Column;
  project_combo_updates: Project_Combo_Updates;
  project_dataset: ResolverTypeWrapper<Project_Dataset>;
  project_dataset_aggregate: ResolverTypeWrapper<Project_Dataset_Aggregate>;
  project_dataset_aggregate_fields: ResolverTypeWrapper<Project_Dataset_Aggregate_Fields>;
  project_dataset_aggregate_order_by: Project_Dataset_Aggregate_Order_By;
  project_dataset_bool_exp: Project_Dataset_Bool_Exp;
  project_dataset_max_fields: ResolverTypeWrapper<Project_Dataset_Max_Fields>;
  project_dataset_max_order_by: Project_Dataset_Max_Order_By;
  project_dataset_min_fields: ResolverTypeWrapper<Project_Dataset_Min_Fields>;
  project_dataset_min_order_by: Project_Dataset_Min_Order_By;
  project_dataset_order_by: Project_Dataset_Order_By;
  project_dataset_select_column: Project_Dataset_Select_Column;
  project_dataset_stream_cursor_input: Project_Dataset_Stream_Cursor_Input;
  project_dataset_stream_cursor_value_input: Project_Dataset_Stream_Cursor_Value_Input;
  project_engine_metrics_args: Project_Engine_Metrics_Args;
  project_engine_ratio_metrics_args: Project_Engine_Ratio_Metrics_Args;
  project_fields_args: Project_Fields_Args;
  project_hypertasks: ResolverTypeWrapper<Project_Hypertasks>;
  project_hypertasks_aggregate: ResolverTypeWrapper<Project_Hypertasks_Aggregate>;
  project_hypertasks_aggregate_fields: ResolverTypeWrapper<Project_Hypertasks_Aggregate_Fields>;
  project_hypertasks_aggregate_order_by: Project_Hypertasks_Aggregate_Order_By;
  project_hypertasks_arr_rel_insert_input: Project_Hypertasks_Arr_Rel_Insert_Input;
  project_hypertasks_avg_fields: ResolverTypeWrapper<Project_Hypertasks_Avg_Fields>;
  project_hypertasks_avg_order_by: Project_Hypertasks_Avg_Order_By;
  project_hypertasks_bool_exp: Project_Hypertasks_Bool_Exp;
  project_hypertasks_constraint: Project_Hypertasks_Constraint;
  project_hypertasks_inc_input: Project_Hypertasks_Inc_Input;
  project_hypertasks_insert_input: Project_Hypertasks_Insert_Input;
  project_hypertasks_max_fields: ResolverTypeWrapper<Project_Hypertasks_Max_Fields>;
  project_hypertasks_max_order_by: Project_Hypertasks_Max_Order_By;
  project_hypertasks_min_fields: ResolverTypeWrapper<Project_Hypertasks_Min_Fields>;
  project_hypertasks_min_order_by: Project_Hypertasks_Min_Order_By;
  project_hypertasks_mutation_response: ResolverTypeWrapper<Project_Hypertasks_Mutation_Response>;
  project_hypertasks_on_conflict: Project_Hypertasks_On_Conflict;
  project_hypertasks_order_by: Project_Hypertasks_Order_By;
  project_hypertasks_pk_columns_input: Project_Hypertasks_Pk_Columns_Input;
  project_hypertasks_select_column: Project_Hypertasks_Select_Column;
  project_hypertasks_set_input: Project_Hypertasks_Set_Input;
  project_hypertasks_stddev_fields: ResolverTypeWrapper<Project_Hypertasks_Stddev_Fields>;
  project_hypertasks_stddev_order_by: Project_Hypertasks_Stddev_Order_By;
  project_hypertasks_stddev_pop_fields: ResolverTypeWrapper<Project_Hypertasks_Stddev_Pop_Fields>;
  project_hypertasks_stddev_pop_order_by: Project_Hypertasks_Stddev_Pop_Order_By;
  project_hypertasks_stddev_samp_fields: ResolverTypeWrapper<Project_Hypertasks_Stddev_Samp_Fields>;
  project_hypertasks_stddev_samp_order_by: Project_Hypertasks_Stddev_Samp_Order_By;
  project_hypertasks_stream_cursor_input: Project_Hypertasks_Stream_Cursor_Input;
  project_hypertasks_stream_cursor_value_input: Project_Hypertasks_Stream_Cursor_Value_Input;
  project_hypertasks_sum_fields: ResolverTypeWrapper<Project_Hypertasks_Sum_Fields>;
  project_hypertasks_sum_order_by: Project_Hypertasks_Sum_Order_By;
  project_hypertasks_update_column: Project_Hypertasks_Update_Column;
  project_hypertasks_updates: Project_Hypertasks_Updates;
  project_hypertasks_var_pop_fields: ResolverTypeWrapper<Project_Hypertasks_Var_Pop_Fields>;
  project_hypertasks_var_pop_order_by: Project_Hypertasks_Var_Pop_Order_By;
  project_hypertasks_var_samp_fields: ResolverTypeWrapper<Project_Hypertasks_Var_Samp_Fields>;
  project_hypertasks_var_samp_order_by: Project_Hypertasks_Var_Samp_Order_By;
  project_hypertasks_variance_fields: ResolverTypeWrapper<Project_Hypertasks_Variance_Fields>;
  project_hypertasks_variance_order_by: Project_Hypertasks_Variance_Order_By;
  project_metrics: ResolverTypeWrapper<Project_Metrics>;
  project_metrics_aggregate: ResolverTypeWrapper<Project_Metrics_Aggregate>;
  project_metrics_aggregate_fields: ResolverTypeWrapper<Project_Metrics_Aggregate_Fields>;
  project_metrics_bool_exp: Project_Metrics_Bool_Exp;
  project_metrics_max_fields: ResolverTypeWrapper<Project_Metrics_Max_Fields>;
  project_metrics_min_fields: ResolverTypeWrapper<Project_Metrics_Min_Fields>;
  project_metrics_order_by: Project_Metrics_Order_By;
  project_metrics_select_column: Project_Metrics_Select_Column;
  project_metrics_stream_cursor_input: Project_Metrics_Stream_Cursor_Input;
  project_metrics_stream_cursor_value_input: Project_Metrics_Stream_Cursor_Value_Input;
  project_name: ResolverTypeWrapper<Project_Name>;
  project_name_bool_exp: Project_Name_Bool_Exp;
  project_name_order_by: Project_Name_Order_By;
  project_name_select_column: Project_Name_Select_Column;
  project_name_stream_cursor_input: Project_Name_Stream_Cursor_Input;
  project_name_stream_cursor_value_input: Project_Name_Stream_Cursor_Value_Input;
  project_rabbit_test_instances: ResolverTypeWrapper<Project_Rabbit_Test_Instances>;
  project_rabbit_test_instances_aggregate: ResolverTypeWrapper<Project_Rabbit_Test_Instances_Aggregate>;
  project_rabbit_test_instances_aggregate_fields: ResolverTypeWrapper<Project_Rabbit_Test_Instances_Aggregate_Fields>;
  project_rabbit_test_instances_aggregate_order_by: Project_Rabbit_Test_Instances_Aggregate_Order_By;
  project_rabbit_test_instances_avg_fields: ResolverTypeWrapper<Project_Rabbit_Test_Instances_Avg_Fields>;
  project_rabbit_test_instances_avg_order_by: Project_Rabbit_Test_Instances_Avg_Order_By;
  project_rabbit_test_instances_bool_exp: Project_Rabbit_Test_Instances_Bool_Exp;
  project_rabbit_test_instances_max_fields: ResolverTypeWrapper<Project_Rabbit_Test_Instances_Max_Fields>;
  project_rabbit_test_instances_max_order_by: Project_Rabbit_Test_Instances_Max_Order_By;
  project_rabbit_test_instances_min_fields: ResolverTypeWrapper<Project_Rabbit_Test_Instances_Min_Fields>;
  project_rabbit_test_instances_min_order_by: Project_Rabbit_Test_Instances_Min_Order_By;
  project_rabbit_test_instances_order_by: Project_Rabbit_Test_Instances_Order_By;
  project_rabbit_test_instances_select_column: Project_Rabbit_Test_Instances_Select_Column;
  project_rabbit_test_instances_stddev_fields: ResolverTypeWrapper<Project_Rabbit_Test_Instances_Stddev_Fields>;
  project_rabbit_test_instances_stddev_order_by: Project_Rabbit_Test_Instances_Stddev_Order_By;
  project_rabbit_test_instances_stddev_pop_fields: ResolverTypeWrapper<Project_Rabbit_Test_Instances_Stddev_Pop_Fields>;
  project_rabbit_test_instances_stddev_pop_order_by: Project_Rabbit_Test_Instances_Stddev_Pop_Order_By;
  project_rabbit_test_instances_stddev_samp_fields: ResolverTypeWrapper<Project_Rabbit_Test_Instances_Stddev_Samp_Fields>;
  project_rabbit_test_instances_stddev_samp_order_by: Project_Rabbit_Test_Instances_Stddev_Samp_Order_By;
  project_rabbit_test_instances_stream_cursor_input: Project_Rabbit_Test_Instances_Stream_Cursor_Input;
  project_rabbit_test_instances_stream_cursor_value_input: Project_Rabbit_Test_Instances_Stream_Cursor_Value_Input;
  project_rabbit_test_instances_sum_fields: ResolverTypeWrapper<Project_Rabbit_Test_Instances_Sum_Fields>;
  project_rabbit_test_instances_sum_order_by: Project_Rabbit_Test_Instances_Sum_Order_By;
  project_rabbit_test_instances_var_pop_fields: ResolverTypeWrapper<Project_Rabbit_Test_Instances_Var_Pop_Fields>;
  project_rabbit_test_instances_var_pop_order_by: Project_Rabbit_Test_Instances_Var_Pop_Order_By;
  project_rabbit_test_instances_var_samp_fields: ResolverTypeWrapper<Project_Rabbit_Test_Instances_Var_Samp_Fields>;
  project_rabbit_test_instances_var_samp_order_by: Project_Rabbit_Test_Instances_Var_Samp_Order_By;
  project_rabbit_test_instances_variance_fields: ResolverTypeWrapper<Project_Rabbit_Test_Instances_Variance_Fields>;
  project_rabbit_test_instances_variance_order_by: Project_Rabbit_Test_Instances_Variance_Order_By;
  project_recordings_clean_args: Project_Recordings_Clean_Args;
  project_recordings_unreviewed_args: Project_Recordings_Unreviewed_Args;
  project_task_count_args: Project_Task_Count_Args;
  project_task_count_by_device_args: Project_Task_Count_By_Device_Args;
  project_tasks: ResolverTypeWrapper<Project_Tasks>;
  project_tasks_aggregate: ResolverTypeWrapper<Project_Tasks_Aggregate>;
  project_tasks_aggregate_fields: ResolverTypeWrapper<Project_Tasks_Aggregate_Fields>;
  project_tasks_aggregate_order_by: Project_Tasks_Aggregate_Order_By;
  project_tasks_arr_rel_insert_input: Project_Tasks_Arr_Rel_Insert_Input;
  project_tasks_avg_fields: ResolverTypeWrapper<Project_Tasks_Avg_Fields>;
  project_tasks_avg_order_by: Project_Tasks_Avg_Order_By;
  project_tasks_bool_exp: Project_Tasks_Bool_Exp;
  project_tasks_constraint: Project_Tasks_Constraint;
  project_tasks_inc_input: Project_Tasks_Inc_Input;
  project_tasks_insert_input: Project_Tasks_Insert_Input;
  project_tasks_max_fields: ResolverTypeWrapper<Project_Tasks_Max_Fields>;
  project_tasks_max_order_by: Project_Tasks_Max_Order_By;
  project_tasks_min_fields: ResolverTypeWrapper<Project_Tasks_Min_Fields>;
  project_tasks_min_order_by: Project_Tasks_Min_Order_By;
  project_tasks_mutation_response: ResolverTypeWrapper<Project_Tasks_Mutation_Response>;
  project_tasks_on_conflict: Project_Tasks_On_Conflict;
  project_tasks_order_by: Project_Tasks_Order_By;
  project_tasks_pk_columns_input: Project_Tasks_Pk_Columns_Input;
  project_tasks_select_column: Project_Tasks_Select_Column;
  project_tasks_set_input: Project_Tasks_Set_Input;
  project_tasks_stddev_fields: ResolverTypeWrapper<Project_Tasks_Stddev_Fields>;
  project_tasks_stddev_order_by: Project_Tasks_Stddev_Order_By;
  project_tasks_stddev_pop_fields: ResolverTypeWrapper<Project_Tasks_Stddev_Pop_Fields>;
  project_tasks_stddev_pop_order_by: Project_Tasks_Stddev_Pop_Order_By;
  project_tasks_stddev_samp_fields: ResolverTypeWrapper<Project_Tasks_Stddev_Samp_Fields>;
  project_tasks_stddev_samp_order_by: Project_Tasks_Stddev_Samp_Order_By;
  project_tasks_stream_cursor_input: Project_Tasks_Stream_Cursor_Input;
  project_tasks_stream_cursor_value_input: Project_Tasks_Stream_Cursor_Value_Input;
  project_tasks_sum_fields: ResolverTypeWrapper<Project_Tasks_Sum_Fields>;
  project_tasks_sum_order_by: Project_Tasks_Sum_Order_By;
  project_tasks_update_column: Project_Tasks_Update_Column;
  project_tasks_updates: Project_Tasks_Updates;
  project_tasks_var_pop_fields: ResolverTypeWrapper<Project_Tasks_Var_Pop_Fields>;
  project_tasks_var_pop_order_by: Project_Tasks_Var_Pop_Order_By;
  project_tasks_var_samp_fields: ResolverTypeWrapper<Project_Tasks_Var_Samp_Fields>;
  project_tasks_var_samp_order_by: Project_Tasks_Var_Samp_Order_By;
  project_tasks_variance_fields: ResolverTypeWrapper<Project_Tasks_Variance_Fields>;
  project_tasks_variance_order_by: Project_Tasks_Variance_Order_By;
  project_user: ResolverTypeWrapper<Project_User>;
  project_user_aggregate: ResolverTypeWrapper<Project_User_Aggregate>;
  project_user_aggregate_fields: ResolverTypeWrapper<Project_User_Aggregate_Fields>;
  project_user_aggregate_order_by: Project_User_Aggregate_Order_By;
  project_user_arr_rel_insert_input: Project_User_Arr_Rel_Insert_Input;
  project_user_bool_exp: Project_User_Bool_Exp;
  project_user_constraint: Project_User_Constraint;
  project_user_insert_input: Project_User_Insert_Input;
  project_user_max_fields: ResolverTypeWrapper<Project_User_Max_Fields>;
  project_user_max_order_by: Project_User_Max_Order_By;
  project_user_min_fields: ResolverTypeWrapper<Project_User_Min_Fields>;
  project_user_min_order_by: Project_User_Min_Order_By;
  project_user_mutation_response: ResolverTypeWrapper<Project_User_Mutation_Response>;
  project_user_on_conflict: Project_User_On_Conflict;
  project_user_order_by: Project_User_Order_By;
  project_user_pk_columns_input: Project_User_Pk_Columns_Input;
  project_user_select_column: Project_User_Select_Column;
  project_user_set_input: Project_User_Set_Input;
  project_user_stream_cursor_input: Project_User_Stream_Cursor_Input;
  project_user_stream_cursor_value_input: Project_User_Stream_Cursor_Value_Input;
  project_user_update_column: Project_User_Update_Column;
  project_user_updates: Project_User_Updates;
  projects: ResolverTypeWrapper<Projects>;
  projects_aggregate: ResolverTypeWrapper<Projects_Aggregate>;
  projects_aggregate_fields: ResolverTypeWrapper<Projects_Aggregate_Fields>;
  projects_aggregate_order_by: Projects_Aggregate_Order_By;
  projects_arr_rel_insert_input: Projects_Arr_Rel_Insert_Input;
  projects_avg_fields: ResolverTypeWrapper<Projects_Avg_Fields>;
  projects_avg_order_by: Projects_Avg_Order_By;
  projects_bool_exp: Projects_Bool_Exp;
  projects_constraint: Projects_Constraint;
  projects_inc_input: Projects_Inc_Input;
  projects_insert_input: Projects_Insert_Input;
  projects_max_fields: ResolverTypeWrapper<Projects_Max_Fields>;
  projects_max_order_by: Projects_Max_Order_By;
  projects_min_fields: ResolverTypeWrapper<Projects_Min_Fields>;
  projects_min_order_by: Projects_Min_Order_By;
  projects_mutation_response: ResolverTypeWrapper<Projects_Mutation_Response>;
  projects_obj_rel_insert_input: Projects_Obj_Rel_Insert_Input;
  projects_on_conflict: Projects_On_Conflict;
  projects_order_by: Projects_Order_By;
  projects_pk_columns_input: Projects_Pk_Columns_Input;
  projects_select_column: Projects_Select_Column;
  projects_set_input: Projects_Set_Input;
  projects_stddev_fields: ResolverTypeWrapper<Projects_Stddev_Fields>;
  projects_stddev_order_by: Projects_Stddev_Order_By;
  projects_stddev_pop_fields: ResolverTypeWrapper<Projects_Stddev_Pop_Fields>;
  projects_stddev_pop_order_by: Projects_Stddev_Pop_Order_By;
  projects_stddev_samp_fields: ResolverTypeWrapper<Projects_Stddev_Samp_Fields>;
  projects_stddev_samp_order_by: Projects_Stddev_Samp_Order_By;
  projects_stream_cursor_input: Projects_Stream_Cursor_Input;
  projects_stream_cursor_value_input: Projects_Stream_Cursor_Value_Input;
  projects_sum_fields: ResolverTypeWrapper<Projects_Sum_Fields>;
  projects_sum_order_by: Projects_Sum_Order_By;
  projects_update_column: Projects_Update_Column;
  projects_updates: Projects_Updates;
  projects_var_pop_fields: ResolverTypeWrapper<Projects_Var_Pop_Fields>;
  projects_var_pop_order_by: Projects_Var_Pop_Order_By;
  projects_var_samp_fields: ResolverTypeWrapper<Projects_Var_Samp_Fields>;
  projects_var_samp_order_by: Projects_Var_Samp_Order_By;
  projects_variance_fields: ResolverTypeWrapper<Projects_Variance_Fields>;
  projects_variance_order_by: Projects_Variance_Order_By;
  query_root: ResolverTypeWrapper<{}>;
  rabbit_test_plan_project: ResolverTypeWrapper<Rabbit_Test_Plan_Project>;
  rabbit_test_plan_project_aggregate: ResolverTypeWrapper<Rabbit_Test_Plan_Project_Aggregate>;
  rabbit_test_plan_project_aggregate_fields: ResolverTypeWrapper<Rabbit_Test_Plan_Project_Aggregate_Fields>;
  rabbit_test_plan_project_aggregate_order_by: Rabbit_Test_Plan_Project_Aggregate_Order_By;
  rabbit_test_plan_project_arr_rel_insert_input: Rabbit_Test_Plan_Project_Arr_Rel_Insert_Input;
  rabbit_test_plan_project_bool_exp: Rabbit_Test_Plan_Project_Bool_Exp;
  rabbit_test_plan_project_constraint: Rabbit_Test_Plan_Project_Constraint;
  rabbit_test_plan_project_insert_input: Rabbit_Test_Plan_Project_Insert_Input;
  rabbit_test_plan_project_max_fields: ResolverTypeWrapper<Rabbit_Test_Plan_Project_Max_Fields>;
  rabbit_test_plan_project_max_order_by: Rabbit_Test_Plan_Project_Max_Order_By;
  rabbit_test_plan_project_min_fields: ResolverTypeWrapper<Rabbit_Test_Plan_Project_Min_Fields>;
  rabbit_test_plan_project_min_order_by: Rabbit_Test_Plan_Project_Min_Order_By;
  rabbit_test_plan_project_mutation_response: ResolverTypeWrapper<Rabbit_Test_Plan_Project_Mutation_Response>;
  rabbit_test_plan_project_on_conflict: Rabbit_Test_Plan_Project_On_Conflict;
  rabbit_test_plan_project_order_by: Rabbit_Test_Plan_Project_Order_By;
  rabbit_test_plan_project_pk_columns_input: Rabbit_Test_Plan_Project_Pk_Columns_Input;
  rabbit_test_plan_project_select_column: Rabbit_Test_Plan_Project_Select_Column;
  rabbit_test_plan_project_set_input: Rabbit_Test_Plan_Project_Set_Input;
  rabbit_test_plan_project_stream_cursor_input: Rabbit_Test_Plan_Project_Stream_Cursor_Input;
  rabbit_test_plan_project_stream_cursor_value_input: Rabbit_Test_Plan_Project_Stream_Cursor_Value_Input;
  rabbit_test_plan_project_update_column: Rabbit_Test_Plan_Project_Update_Column;
  rabbit_test_plan_project_updates: Rabbit_Test_Plan_Project_Updates;
  rabbit_test_plan_recording: ResolverTypeWrapper<Rabbit_Test_Plan_Recording>;
  rabbit_test_plan_recording_aggregate: ResolverTypeWrapper<Rabbit_Test_Plan_Recording_Aggregate>;
  rabbit_test_plan_recording_aggregate_fields: ResolverTypeWrapper<Rabbit_Test_Plan_Recording_Aggregate_Fields>;
  rabbit_test_plan_recording_aggregate_order_by: Rabbit_Test_Plan_Recording_Aggregate_Order_By;
  rabbit_test_plan_recording_arr_rel_insert_input: Rabbit_Test_Plan_Recording_Arr_Rel_Insert_Input;
  rabbit_test_plan_recording_bool_exp: Rabbit_Test_Plan_Recording_Bool_Exp;
  rabbit_test_plan_recording_constraint: Rabbit_Test_Plan_Recording_Constraint;
  rabbit_test_plan_recording_insert_input: Rabbit_Test_Plan_Recording_Insert_Input;
  rabbit_test_plan_recording_max_fields: ResolverTypeWrapper<Rabbit_Test_Plan_Recording_Max_Fields>;
  rabbit_test_plan_recording_max_order_by: Rabbit_Test_Plan_Recording_Max_Order_By;
  rabbit_test_plan_recording_min_fields: ResolverTypeWrapper<Rabbit_Test_Plan_Recording_Min_Fields>;
  rabbit_test_plan_recording_min_order_by: Rabbit_Test_Plan_Recording_Min_Order_By;
  rabbit_test_plan_recording_mutation_response: ResolverTypeWrapper<Rabbit_Test_Plan_Recording_Mutation_Response>;
  rabbit_test_plan_recording_obj_rel_insert_input: Rabbit_Test_Plan_Recording_Obj_Rel_Insert_Input;
  rabbit_test_plan_recording_on_conflict: Rabbit_Test_Plan_Recording_On_Conflict;
  rabbit_test_plan_recording_order_by: Rabbit_Test_Plan_Recording_Order_By;
  rabbit_test_plan_recording_pk_columns_input: Rabbit_Test_Plan_Recording_Pk_Columns_Input;
  rabbit_test_plan_recording_select_column: Rabbit_Test_Plan_Recording_Select_Column;
  rabbit_test_plan_recording_set_input: Rabbit_Test_Plan_Recording_Set_Input;
  rabbit_test_plan_recording_stream_cursor_input: Rabbit_Test_Plan_Recording_Stream_Cursor_Input;
  rabbit_test_plan_recording_stream_cursor_value_input: Rabbit_Test_Plan_Recording_Stream_Cursor_Value_Input;
  rabbit_test_plan_recording_update_column: Rabbit_Test_Plan_Recording_Update_Column;
  rabbit_test_plan_recording_updates: Rabbit_Test_Plan_Recording_Updates;
  rabbit_test_plan_task: ResolverTypeWrapper<Rabbit_Test_Plan_Task>;
  rabbit_test_plan_task_aggregate: ResolverTypeWrapper<Rabbit_Test_Plan_Task_Aggregate>;
  rabbit_test_plan_task_aggregate_fields: ResolverTypeWrapper<Rabbit_Test_Plan_Task_Aggregate_Fields>;
  rabbit_test_plan_task_aggregate_order_by: Rabbit_Test_Plan_Task_Aggregate_Order_By;
  rabbit_test_plan_task_arr_rel_insert_input: Rabbit_Test_Plan_Task_Arr_Rel_Insert_Input;
  rabbit_test_plan_task_bool_exp: Rabbit_Test_Plan_Task_Bool_Exp;
  rabbit_test_plan_task_constraint: Rabbit_Test_Plan_Task_Constraint;
  rabbit_test_plan_task_insert_input: Rabbit_Test_Plan_Task_Insert_Input;
  rabbit_test_plan_task_max_fields: ResolverTypeWrapper<Rabbit_Test_Plan_Task_Max_Fields>;
  rabbit_test_plan_task_max_order_by: Rabbit_Test_Plan_Task_Max_Order_By;
  rabbit_test_plan_task_min_fields: ResolverTypeWrapper<Rabbit_Test_Plan_Task_Min_Fields>;
  rabbit_test_plan_task_min_order_by: Rabbit_Test_Plan_Task_Min_Order_By;
  rabbit_test_plan_task_mutation_response: ResolverTypeWrapper<Rabbit_Test_Plan_Task_Mutation_Response>;
  rabbit_test_plan_task_on_conflict: Rabbit_Test_Plan_Task_On_Conflict;
  rabbit_test_plan_task_order_by: Rabbit_Test_Plan_Task_Order_By;
  rabbit_test_plan_task_pk_columns_input: Rabbit_Test_Plan_Task_Pk_Columns_Input;
  rabbit_test_plan_task_select_column: Rabbit_Test_Plan_Task_Select_Column;
  rabbit_test_plan_task_set_input: Rabbit_Test_Plan_Task_Set_Input;
  rabbit_test_plan_task_stream_cursor_input: Rabbit_Test_Plan_Task_Stream_Cursor_Input;
  rabbit_test_plan_task_stream_cursor_value_input: Rabbit_Test_Plan_Task_Stream_Cursor_Value_Input;
  rabbit_test_plan_task_update_column: Rabbit_Test_Plan_Task_Update_Column;
  rabbit_test_plan_task_updates: Rabbit_Test_Plan_Task_Updates;
  rabbit_test_plans: ResolverTypeWrapper<Rabbit_Test_Plans>;
  rabbit_test_plans_aggregate: ResolverTypeWrapper<Rabbit_Test_Plans_Aggregate>;
  rabbit_test_plans_aggregate_fields: ResolverTypeWrapper<Rabbit_Test_Plans_Aggregate_Fields>;
  rabbit_test_plans_bool_exp: Rabbit_Test_Plans_Bool_Exp;
  rabbit_test_plans_constraint: Rabbit_Test_Plans_Constraint;
  rabbit_test_plans_insert_input: Rabbit_Test_Plans_Insert_Input;
  rabbit_test_plans_max_fields: ResolverTypeWrapper<Rabbit_Test_Plans_Max_Fields>;
  rabbit_test_plans_min_fields: ResolverTypeWrapper<Rabbit_Test_Plans_Min_Fields>;
  rabbit_test_plans_mutation_response: ResolverTypeWrapper<Rabbit_Test_Plans_Mutation_Response>;
  rabbit_test_plans_obj_rel_insert_input: Rabbit_Test_Plans_Obj_Rel_Insert_Input;
  rabbit_test_plans_on_conflict: Rabbit_Test_Plans_On_Conflict;
  rabbit_test_plans_order_by: Rabbit_Test_Plans_Order_By;
  rabbit_test_plans_pk_columns_input: Rabbit_Test_Plans_Pk_Columns_Input;
  rabbit_test_plans_select_column: Rabbit_Test_Plans_Select_Column;
  rabbit_test_plans_set_input: Rabbit_Test_Plans_Set_Input;
  rabbit_test_plans_stream_cursor_input: Rabbit_Test_Plans_Stream_Cursor_Input;
  rabbit_test_plans_stream_cursor_value_input: Rabbit_Test_Plans_Stream_Cursor_Value_Input;
  rabbit_test_plans_update_column: Rabbit_Test_Plans_Update_Column;
  rabbit_test_plans_updates: Rabbit_Test_Plans_Updates;
  recording_apps: ResolverTypeWrapper<Recording_Apps>;
  recording_apps_aggregate: ResolverTypeWrapper<Recording_Apps_Aggregate>;
  recording_apps_aggregate_fields: ResolverTypeWrapper<Recording_Apps_Aggregate_Fields>;
  recording_apps_bool_exp: Recording_Apps_Bool_Exp;
  recording_apps_constraint: Recording_Apps_Constraint;
  recording_apps_insert_input: Recording_Apps_Insert_Input;
  recording_apps_max_fields: ResolverTypeWrapper<Recording_Apps_Max_Fields>;
  recording_apps_min_fields: ResolverTypeWrapper<Recording_Apps_Min_Fields>;
  recording_apps_mutation_response: ResolverTypeWrapper<Recording_Apps_Mutation_Response>;
  recording_apps_obj_rel_insert_input: Recording_Apps_Obj_Rel_Insert_Input;
  recording_apps_on_conflict: Recording_Apps_On_Conflict;
  recording_apps_order_by: Recording_Apps_Order_By;
  recording_apps_pk_columns_input: Recording_Apps_Pk_Columns_Input;
  recording_apps_select_column: Recording_Apps_Select_Column;
  recording_apps_set_input: Recording_Apps_Set_Input;
  recording_apps_stream_cursor_input: Recording_Apps_Stream_Cursor_Input;
  recording_apps_stream_cursor_value_input: Recording_Apps_Stream_Cursor_Value_Input;
  recording_apps_update_column: Recording_Apps_Update_Column;
  recording_apps_updates: Recording_Apps_Updates;
  recording_audio_driver: ResolverTypeWrapper<Recording_Audio_Driver>;
  recording_audio_driver_aggregate_order_by: Recording_Audio_Driver_Aggregate_Order_By;
  recording_audio_driver_arr_rel_insert_input: Recording_Audio_Driver_Arr_Rel_Insert_Input;
  recording_audio_driver_bool_exp: Recording_Audio_Driver_Bool_Exp;
  recording_audio_driver_constraint: Recording_Audio_Driver_Constraint;
  recording_audio_driver_insert_input: Recording_Audio_Driver_Insert_Input;
  recording_audio_driver_max_order_by: Recording_Audio_Driver_Max_Order_By;
  recording_audio_driver_min_order_by: Recording_Audio_Driver_Min_Order_By;
  recording_audio_driver_mutation_response: ResolverTypeWrapper<Recording_Audio_Driver_Mutation_Response>;
  recording_audio_driver_on_conflict: Recording_Audio_Driver_On_Conflict;
  recording_audio_driver_order_by: Recording_Audio_Driver_Order_By;
  recording_audio_driver_pk_columns_input: Recording_Audio_Driver_Pk_Columns_Input;
  recording_audio_driver_select_column: Recording_Audio_Driver_Select_Column;
  recording_audio_driver_set_input: Recording_Audio_Driver_Set_Input;
  recording_audio_driver_stream_cursor_input: Recording_Audio_Driver_Stream_Cursor_Input;
  recording_audio_driver_stream_cursor_value_input: Recording_Audio_Driver_Stream_Cursor_Value_Input;
  recording_audio_driver_update_column: Recording_Audio_Driver_Update_Column;
  recording_audio_driver_updates: Recording_Audio_Driver_Updates;
  recording_data_ground_truth: ResolverTypeWrapper<Recording_Data_Ground_Truth>;
  recording_data_ground_truth_aggregate: ResolverTypeWrapper<Recording_Data_Ground_Truth_Aggregate>;
  recording_data_ground_truth_aggregate_fields: ResolverTypeWrapper<Recording_Data_Ground_Truth_Aggregate_Fields>;
  recording_data_ground_truth_avg_fields: ResolverTypeWrapper<Recording_Data_Ground_Truth_Avg_Fields>;
  recording_data_ground_truth_bool_exp: Recording_Data_Ground_Truth_Bool_Exp;
  recording_data_ground_truth_constraint: Recording_Data_Ground_Truth_Constraint;
  recording_data_ground_truth_inc_input: Recording_Data_Ground_Truth_Inc_Input;
  recording_data_ground_truth_insert_input: Recording_Data_Ground_Truth_Insert_Input;
  recording_data_ground_truth_max_fields: ResolverTypeWrapper<Recording_Data_Ground_Truth_Max_Fields>;
  recording_data_ground_truth_min_fields: ResolverTypeWrapper<Recording_Data_Ground_Truth_Min_Fields>;
  recording_data_ground_truth_mutation_response: ResolverTypeWrapper<Recording_Data_Ground_Truth_Mutation_Response>;
  recording_data_ground_truth_obj_rel_insert_input: Recording_Data_Ground_Truth_Obj_Rel_Insert_Input;
  recording_data_ground_truth_on_conflict: Recording_Data_Ground_Truth_On_Conflict;
  recording_data_ground_truth_order_by: Recording_Data_Ground_Truth_Order_By;
  recording_data_ground_truth_pk_columns_input: Recording_Data_Ground_Truth_Pk_Columns_Input;
  recording_data_ground_truth_select_column: Recording_Data_Ground_Truth_Select_Column;
  recording_data_ground_truth_set_input: Recording_Data_Ground_Truth_Set_Input;
  recording_data_ground_truth_stddev_fields: ResolverTypeWrapper<Recording_Data_Ground_Truth_Stddev_Fields>;
  recording_data_ground_truth_stddev_pop_fields: ResolverTypeWrapper<Recording_Data_Ground_Truth_Stddev_Pop_Fields>;
  recording_data_ground_truth_stddev_samp_fields: ResolverTypeWrapper<Recording_Data_Ground_Truth_Stddev_Samp_Fields>;
  recording_data_ground_truth_stream_cursor_input: Recording_Data_Ground_Truth_Stream_Cursor_Input;
  recording_data_ground_truth_stream_cursor_value_input: Recording_Data_Ground_Truth_Stream_Cursor_Value_Input;
  recording_data_ground_truth_sum_fields: ResolverTypeWrapper<Recording_Data_Ground_Truth_Sum_Fields>;
  recording_data_ground_truth_update_column: Recording_Data_Ground_Truth_Update_Column;
  recording_data_ground_truth_updates: Recording_Data_Ground_Truth_Updates;
  recording_data_ground_truth_var_pop_fields: ResolverTypeWrapper<Recording_Data_Ground_Truth_Var_Pop_Fields>;
  recording_data_ground_truth_var_samp_fields: ResolverTypeWrapper<Recording_Data_Ground_Truth_Var_Samp_Fields>;
  recording_data_ground_truth_variance_fields: ResolverTypeWrapper<Recording_Data_Ground_Truth_Variance_Fields>;
  recording_data_labels: ResolverTypeWrapper<Recording_Data_Labels>;
  recording_data_labels_aggregate: ResolverTypeWrapper<Recording_Data_Labels_Aggregate>;
  recording_data_labels_aggregate_fields: ResolverTypeWrapper<Recording_Data_Labels_Aggregate_Fields>;
  recording_data_labels_aggregate_order_by: Recording_Data_Labels_Aggregate_Order_By;
  recording_data_labels_arr_rel_insert_input: Recording_Data_Labels_Arr_Rel_Insert_Input;
  recording_data_labels_avg_fields: ResolverTypeWrapper<Recording_Data_Labels_Avg_Fields>;
  recording_data_labels_avg_order_by: Recording_Data_Labels_Avg_Order_By;
  recording_data_labels_bool_exp: Recording_Data_Labels_Bool_Exp;
  recording_data_labels_constraint: Recording_Data_Labels_Constraint;
  recording_data_labels_inc_input: Recording_Data_Labels_Inc_Input;
  recording_data_labels_insert_input: Recording_Data_Labels_Insert_Input;
  recording_data_labels_max_fields: ResolverTypeWrapper<Recording_Data_Labels_Max_Fields>;
  recording_data_labels_max_order_by: Recording_Data_Labels_Max_Order_By;
  recording_data_labels_min_fields: ResolverTypeWrapper<Recording_Data_Labels_Min_Fields>;
  recording_data_labels_min_order_by: Recording_Data_Labels_Min_Order_By;
  recording_data_labels_mutation_response: ResolverTypeWrapper<Recording_Data_Labels_Mutation_Response>;
  recording_data_labels_on_conflict: Recording_Data_Labels_On_Conflict;
  recording_data_labels_order_by: Recording_Data_Labels_Order_By;
  recording_data_labels_pk_columns_input: Recording_Data_Labels_Pk_Columns_Input;
  recording_data_labels_select_column: Recording_Data_Labels_Select_Column;
  recording_data_labels_set_input: Recording_Data_Labels_Set_Input;
  recording_data_labels_stddev_fields: ResolverTypeWrapper<Recording_Data_Labels_Stddev_Fields>;
  recording_data_labels_stddev_order_by: Recording_Data_Labels_Stddev_Order_By;
  recording_data_labels_stddev_pop_fields: ResolverTypeWrapper<Recording_Data_Labels_Stddev_Pop_Fields>;
  recording_data_labels_stddev_pop_order_by: Recording_Data_Labels_Stddev_Pop_Order_By;
  recording_data_labels_stddev_samp_fields: ResolverTypeWrapper<Recording_Data_Labels_Stddev_Samp_Fields>;
  recording_data_labels_stddev_samp_order_by: Recording_Data_Labels_Stddev_Samp_Order_By;
  recording_data_labels_stream_cursor_input: Recording_Data_Labels_Stream_Cursor_Input;
  recording_data_labels_stream_cursor_value_input: Recording_Data_Labels_Stream_Cursor_Value_Input;
  recording_data_labels_sum_fields: ResolverTypeWrapper<Recording_Data_Labels_Sum_Fields>;
  recording_data_labels_sum_order_by: Recording_Data_Labels_Sum_Order_By;
  recording_data_labels_update_column: Recording_Data_Labels_Update_Column;
  recording_data_labels_updates: Recording_Data_Labels_Updates;
  recording_data_labels_var_pop_fields: ResolverTypeWrapper<Recording_Data_Labels_Var_Pop_Fields>;
  recording_data_labels_var_pop_order_by: Recording_Data_Labels_Var_Pop_Order_By;
  recording_data_labels_var_samp_fields: ResolverTypeWrapper<Recording_Data_Labels_Var_Samp_Fields>;
  recording_data_labels_var_samp_order_by: Recording_Data_Labels_Var_Samp_Order_By;
  recording_data_labels_variance_fields: ResolverTypeWrapper<Recording_Data_Labels_Variance_Fields>;
  recording_data_labels_variance_order_by: Recording_Data_Labels_Variance_Order_By;
  recording_data_mode_parameter: ResolverTypeWrapper<Recording_Data_Mode_Parameter>;
  recording_data_mode_parameter_aggregate: ResolverTypeWrapper<Recording_Data_Mode_Parameter_Aggregate>;
  recording_data_mode_parameter_aggregate_fields: ResolverTypeWrapper<Recording_Data_Mode_Parameter_Aggregate_Fields>;
  recording_data_mode_parameter_aggregate_order_by: Recording_Data_Mode_Parameter_Aggregate_Order_By;
  recording_data_mode_parameter_arr_rel_insert_input: Recording_Data_Mode_Parameter_Arr_Rel_Insert_Input;
  recording_data_mode_parameter_bool_exp: Recording_Data_Mode_Parameter_Bool_Exp;
  recording_data_mode_parameter_constraint: Recording_Data_Mode_Parameter_Constraint;
  recording_data_mode_parameter_insert_input: Recording_Data_Mode_Parameter_Insert_Input;
  recording_data_mode_parameter_max_fields: ResolverTypeWrapper<Recording_Data_Mode_Parameter_Max_Fields>;
  recording_data_mode_parameter_max_order_by: Recording_Data_Mode_Parameter_Max_Order_By;
  recording_data_mode_parameter_min_fields: ResolverTypeWrapper<Recording_Data_Mode_Parameter_Min_Fields>;
  recording_data_mode_parameter_min_order_by: Recording_Data_Mode_Parameter_Min_Order_By;
  recording_data_mode_parameter_mutation_response: ResolverTypeWrapper<Recording_Data_Mode_Parameter_Mutation_Response>;
  recording_data_mode_parameter_on_conflict: Recording_Data_Mode_Parameter_On_Conflict;
  recording_data_mode_parameter_order_by: Recording_Data_Mode_Parameter_Order_By;
  recording_data_mode_parameter_pk_columns_input: Recording_Data_Mode_Parameter_Pk_Columns_Input;
  recording_data_mode_parameter_select_column: Recording_Data_Mode_Parameter_Select_Column;
  recording_data_mode_parameter_set_input: Recording_Data_Mode_Parameter_Set_Input;
  recording_data_mode_parameter_stream_cursor_input: Recording_Data_Mode_Parameter_Stream_Cursor_Input;
  recording_data_mode_parameter_stream_cursor_value_input: Recording_Data_Mode_Parameter_Stream_Cursor_Value_Input;
  recording_data_mode_parameter_update_column: Recording_Data_Mode_Parameter_Update_Column;
  recording_data_mode_parameter_updates: Recording_Data_Mode_Parameter_Updates;
  recording_data_modes: ResolverTypeWrapper<Recording_Data_Modes>;
  recording_data_modes_aggregate: ResolverTypeWrapper<Recording_Data_Modes_Aggregate>;
  recording_data_modes_aggregate_fields: ResolverTypeWrapper<Recording_Data_Modes_Aggregate_Fields>;
  recording_data_modes_bool_exp: Recording_Data_Modes_Bool_Exp;
  recording_data_modes_constraint: Recording_Data_Modes_Constraint;
  recording_data_modes_insert_input: Recording_Data_Modes_Insert_Input;
  recording_data_modes_max_fields: ResolverTypeWrapper<Recording_Data_Modes_Max_Fields>;
  recording_data_modes_min_fields: ResolverTypeWrapper<Recording_Data_Modes_Min_Fields>;
  recording_data_modes_mutation_response: ResolverTypeWrapper<Recording_Data_Modes_Mutation_Response>;
  recording_data_modes_obj_rel_insert_input: Recording_Data_Modes_Obj_Rel_Insert_Input;
  recording_data_modes_on_conflict: Recording_Data_Modes_On_Conflict;
  recording_data_modes_order_by: Recording_Data_Modes_Order_By;
  recording_data_modes_pk_columns_input: Recording_Data_Modes_Pk_Columns_Input;
  recording_data_modes_select_column: Recording_Data_Modes_Select_Column;
  recording_data_modes_set_input: Recording_Data_Modes_Set_Input;
  recording_data_modes_stream_cursor_input: Recording_Data_Modes_Stream_Cursor_Input;
  recording_data_modes_stream_cursor_value_input: Recording_Data_Modes_Stream_Cursor_Value_Input;
  recording_data_modes_update_column: Recording_Data_Modes_Update_Column;
  recording_data_modes_updates: Recording_Data_Modes_Updates;
  recording_data_parameters: ResolverTypeWrapper<Recording_Data_Parameters>;
  recording_data_parameters_aggregate: ResolverTypeWrapper<Recording_Data_Parameters_Aggregate>;
  recording_data_parameters_aggregate_fields: ResolverTypeWrapper<Recording_Data_Parameters_Aggregate_Fields>;
  recording_data_parameters_bool_exp: Recording_Data_Parameters_Bool_Exp;
  recording_data_parameters_constraint: Recording_Data_Parameters_Constraint;
  recording_data_parameters_insert_input: Recording_Data_Parameters_Insert_Input;
  recording_data_parameters_max_fields: ResolverTypeWrapper<Recording_Data_Parameters_Max_Fields>;
  recording_data_parameters_min_fields: ResolverTypeWrapper<Recording_Data_Parameters_Min_Fields>;
  recording_data_parameters_mutation_response: ResolverTypeWrapper<Recording_Data_Parameters_Mutation_Response>;
  recording_data_parameters_obj_rel_insert_input: Recording_Data_Parameters_Obj_Rel_Insert_Input;
  recording_data_parameters_on_conflict: Recording_Data_Parameters_On_Conflict;
  recording_data_parameters_order_by: Recording_Data_Parameters_Order_By;
  recording_data_parameters_pk_columns_input: Recording_Data_Parameters_Pk_Columns_Input;
  recording_data_parameters_select_column: Recording_Data_Parameters_Select_Column;
  recording_data_parameters_set_input: Recording_Data_Parameters_Set_Input;
  recording_data_parameters_stream_cursor_input: Recording_Data_Parameters_Stream_Cursor_Input;
  recording_data_parameters_stream_cursor_value_input: Recording_Data_Parameters_Stream_Cursor_Value_Input;
  recording_data_parameters_update_column: Recording_Data_Parameters_Update_Column;
  recording_data_parameters_updates: Recording_Data_Parameters_Updates;
  recording_data_payload_pair: ResolverTypeWrapper<Recording_Data_Payload_Pair>;
  recording_data_payload_pair_bool_exp: Recording_Data_Payload_Pair_Bool_Exp;
  recording_data_payload_pair_constraint: Recording_Data_Payload_Pair_Constraint;
  recording_data_payload_pair_inc_input: Recording_Data_Payload_Pair_Inc_Input;
  recording_data_payload_pair_insert_input: Recording_Data_Payload_Pair_Insert_Input;
  recording_data_payload_pair_mutation_response: ResolverTypeWrapper<Recording_Data_Payload_Pair_Mutation_Response>;
  recording_data_payload_pair_on_conflict: Recording_Data_Payload_Pair_On_Conflict;
  recording_data_payload_pair_order_by: Recording_Data_Payload_Pair_Order_By;
  recording_data_payload_pair_pk_columns_input: Recording_Data_Payload_Pair_Pk_Columns_Input;
  recording_data_payload_pair_select_column: Recording_Data_Payload_Pair_Select_Column;
  recording_data_payload_pair_set_input: Recording_Data_Payload_Pair_Set_Input;
  recording_data_payload_pair_stream_cursor_input: Recording_Data_Payload_Pair_Stream_Cursor_Input;
  recording_data_payload_pair_stream_cursor_value_input: Recording_Data_Payload_Pair_Stream_Cursor_Value_Input;
  recording_data_payload_pair_update_column: Recording_Data_Payload_Pair_Update_Column;
  recording_data_payload_pair_updates: Recording_Data_Payload_Pair_Updates;
  recording_data_predictions: ResolverTypeWrapper<Recording_Data_Predictions>;
  recording_data_predictions_aggregate: ResolverTypeWrapper<Recording_Data_Predictions_Aggregate>;
  recording_data_predictions_aggregate_fields: ResolverTypeWrapper<Recording_Data_Predictions_Aggregate_Fields>;
  recording_data_predictions_aggregate_order_by: Recording_Data_Predictions_Aggregate_Order_By;
  recording_data_predictions_arr_rel_insert_input: Recording_Data_Predictions_Arr_Rel_Insert_Input;
  recording_data_predictions_bool_exp: Recording_Data_Predictions_Bool_Exp;
  recording_data_predictions_constraint: Recording_Data_Predictions_Constraint;
  recording_data_predictions_insert_input: Recording_Data_Predictions_Insert_Input;
  recording_data_predictions_max_fields: ResolverTypeWrapper<Recording_Data_Predictions_Max_Fields>;
  recording_data_predictions_max_order_by: Recording_Data_Predictions_Max_Order_By;
  recording_data_predictions_min_fields: ResolverTypeWrapper<Recording_Data_Predictions_Min_Fields>;
  recording_data_predictions_min_order_by: Recording_Data_Predictions_Min_Order_By;
  recording_data_predictions_mutation_response: ResolverTypeWrapper<Recording_Data_Predictions_Mutation_Response>;
  recording_data_predictions_on_conflict: Recording_Data_Predictions_On_Conflict;
  recording_data_predictions_order_by: Recording_Data_Predictions_Order_By;
  recording_data_predictions_pk_columns_input: Recording_Data_Predictions_Pk_Columns_Input;
  recording_data_predictions_select_column: Recording_Data_Predictions_Select_Column;
  recording_data_predictions_set_input: Recording_Data_Predictions_Set_Input;
  recording_data_predictions_stream_cursor_input: Recording_Data_Predictions_Stream_Cursor_Input;
  recording_data_predictions_stream_cursor_value_input: Recording_Data_Predictions_Stream_Cursor_Value_Input;
  recording_data_predictions_update_column: Recording_Data_Predictions_Update_Column;
  recording_data_predictions_updates: Recording_Data_Predictions_Updates;
  recording_recording_tag: ResolverTypeWrapper<Recording_Recording_Tag>;
  recording_recording_tag_aggregate_order_by: Recording_Recording_Tag_Aggregate_Order_By;
  recording_recording_tag_arr_rel_insert_input: Recording_Recording_Tag_Arr_Rel_Insert_Input;
  recording_recording_tag_avg_order_by: Recording_Recording_Tag_Avg_Order_By;
  recording_recording_tag_bool_exp: Recording_Recording_Tag_Bool_Exp;
  recording_recording_tag_constraint: Recording_Recording_Tag_Constraint;
  recording_recording_tag_inc_input: Recording_Recording_Tag_Inc_Input;
  recording_recording_tag_insert_input: Recording_Recording_Tag_Insert_Input;
  recording_recording_tag_max_order_by: Recording_Recording_Tag_Max_Order_By;
  recording_recording_tag_min_order_by: Recording_Recording_Tag_Min_Order_By;
  recording_recording_tag_mutation_response: ResolverTypeWrapper<Recording_Recording_Tag_Mutation_Response>;
  recording_recording_tag_on_conflict: Recording_Recording_Tag_On_Conflict;
  recording_recording_tag_order_by: Recording_Recording_Tag_Order_By;
  recording_recording_tag_pk_columns_input: Recording_Recording_Tag_Pk_Columns_Input;
  recording_recording_tag_select_column: Recording_Recording_Tag_Select_Column;
  recording_recording_tag_set_input: Recording_Recording_Tag_Set_Input;
  recording_recording_tag_stddev_order_by: Recording_Recording_Tag_Stddev_Order_By;
  recording_recording_tag_stddev_pop_order_by: Recording_Recording_Tag_Stddev_Pop_Order_By;
  recording_recording_tag_stddev_samp_order_by: Recording_Recording_Tag_Stddev_Samp_Order_By;
  recording_recording_tag_stream_cursor_input: Recording_Recording_Tag_Stream_Cursor_Input;
  recording_recording_tag_stream_cursor_value_input: Recording_Recording_Tag_Stream_Cursor_Value_Input;
  recording_recording_tag_sum_order_by: Recording_Recording_Tag_Sum_Order_By;
  recording_recording_tag_update_column: Recording_Recording_Tag_Update_Column;
  recording_recording_tag_updates: Recording_Recording_Tag_Updates;
  recording_recording_tag_var_pop_order_by: Recording_Recording_Tag_Var_Pop_Order_By;
  recording_recording_tag_var_samp_order_by: Recording_Recording_Tag_Var_Samp_Order_By;
  recording_recording_tag_variance_order_by: Recording_Recording_Tag_Variance_Order_By;
  recording_tag_count_by_day_args: Recording_Tag_Count_By_Day_Args;
  recording_tags: ResolverTypeWrapper<Recording_Tags>;
  recording_tags_bool_exp: Recording_Tags_Bool_Exp;
  recording_tags_constraint: Recording_Tags_Constraint;
  recording_tags_insert_input: Recording_Tags_Insert_Input;
  recording_tags_mutation_response: ResolverTypeWrapper<Recording_Tags_Mutation_Response>;
  recording_tags_obj_rel_insert_input: Recording_Tags_Obj_Rel_Insert_Input;
  recording_tags_on_conflict: Recording_Tags_On_Conflict;
  recording_tags_order_by: Recording_Tags_Order_By;
  recording_tags_pk_columns_input: Recording_Tags_Pk_Columns_Input;
  recording_tags_select_column: Recording_Tags_Select_Column;
  recording_tags_set_input: Recording_Tags_Set_Input;
  recording_tags_stream_cursor_input: Recording_Tags_Stream_Cursor_Input;
  recording_tags_stream_cursor_value_input: Recording_Tags_Stream_Cursor_Value_Input;
  recording_tags_update_column: Recording_Tags_Update_Column;
  recording_tags_updates: Recording_Tags_Updates;
  recordings: ResolverTypeWrapper<Recordings>;
  recordings_aggregate: ResolverTypeWrapper<Recordings_Aggregate>;
  recordings_aggregate_fields: ResolverTypeWrapper<Recordings_Aggregate_Fields>;
  recordings_aggregate_order_by: Recordings_Aggregate_Order_By;
  recordings_arr_rel_insert_input: Recordings_Arr_Rel_Insert_Input;
  recordings_avg_fields: ResolverTypeWrapper<Recordings_Avg_Fields>;
  recordings_avg_order_by: Recordings_Avg_Order_By;
  recordings_bool_exp: Recordings_Bool_Exp;
  recordings_constraint: Recordings_Constraint;
  recordings_inc_input: Recordings_Inc_Input;
  recordings_insert_input: Recordings_Insert_Input;
  recordings_max_fields: ResolverTypeWrapper<Recordings_Max_Fields>;
  recordings_max_order_by: Recordings_Max_Order_By;
  recordings_min_fields: ResolverTypeWrapper<Recordings_Min_Fields>;
  recordings_min_order_by: Recordings_Min_Order_By;
  recordings_mutation_response: ResolverTypeWrapper<Recordings_Mutation_Response>;
  recordings_obj_rel_insert_input: Recordings_Obj_Rel_Insert_Input;
  recordings_on_conflict: Recordings_On_Conflict;
  recordings_order_by: Recordings_Order_By;
  recordings_pk_columns_input: Recordings_Pk_Columns_Input;
  recordings_select_column: Recordings_Select_Column;
  recordings_set_input: Recordings_Set_Input;
  recordings_stddev_fields: ResolverTypeWrapper<Recordings_Stddev_Fields>;
  recordings_stddev_order_by: Recordings_Stddev_Order_By;
  recordings_stddev_pop_fields: ResolverTypeWrapper<Recordings_Stddev_Pop_Fields>;
  recordings_stddev_pop_order_by: Recordings_Stddev_Pop_Order_By;
  recordings_stddev_samp_fields: ResolverTypeWrapper<Recordings_Stddev_Samp_Fields>;
  recordings_stddev_samp_order_by: Recordings_Stddev_Samp_Order_By;
  recordings_stream_cursor_input: Recordings_Stream_Cursor_Input;
  recordings_stream_cursor_value_input: Recordings_Stream_Cursor_Value_Input;
  recordings_sum_fields: ResolverTypeWrapper<Recordings_Sum_Fields>;
  recordings_sum_order_by: Recordings_Sum_Order_By;
  recordings_update_column: Recordings_Update_Column;
  recordings_updates: Recordings_Updates;
  recordings_var_pop_fields: ResolverTypeWrapper<Recordings_Var_Pop_Fields>;
  recordings_var_pop_order_by: Recordings_Var_Pop_Order_By;
  recordings_var_samp_fields: ResolverTypeWrapper<Recordings_Var_Samp_Fields>;
  recordings_var_samp_order_by: Recordings_Var_Samp_Order_By;
  recordings_variance_fields: ResolverTypeWrapper<Recordings_Variance_Fields>;
  recordings_variance_order_by: Recordings_Variance_Order_By;
  role_user: ResolverTypeWrapper<Role_User>;
  role_user_aggregate: ResolverTypeWrapper<Role_User_Aggregate>;
  role_user_aggregate_fields: ResolverTypeWrapper<Role_User_Aggregate_Fields>;
  role_user_aggregate_order_by: Role_User_Aggregate_Order_By;
  role_user_arr_rel_insert_input: Role_User_Arr_Rel_Insert_Input;
  role_user_bool_exp: Role_User_Bool_Exp;
  role_user_constraint: Role_User_Constraint;
  role_user_insert_input: Role_User_Insert_Input;
  role_user_max_fields: ResolverTypeWrapper<Role_User_Max_Fields>;
  role_user_max_order_by: Role_User_Max_Order_By;
  role_user_min_fields: ResolverTypeWrapper<Role_User_Min_Fields>;
  role_user_min_order_by: Role_User_Min_Order_By;
  role_user_mutation_response: ResolverTypeWrapper<Role_User_Mutation_Response>;
  role_user_on_conflict: Role_User_On_Conflict;
  role_user_order_by: Role_User_Order_By;
  role_user_pk_columns_input: Role_User_Pk_Columns_Input;
  role_user_select_column: Role_User_Select_Column;
  role_user_set_input: Role_User_Set_Input;
  role_user_stream_cursor_input: Role_User_Stream_Cursor_Input;
  role_user_stream_cursor_value_input: Role_User_Stream_Cursor_Value_Input;
  role_user_update_column: Role_User_Update_Column;
  role_user_updates: Role_User_Updates;
  roles: ResolverTypeWrapper<Roles>;
  roles_aggregate: ResolverTypeWrapper<Roles_Aggregate>;
  roles_aggregate_fields: ResolverTypeWrapper<Roles_Aggregate_Fields>;
  roles_bool_exp: Roles_Bool_Exp;
  roles_enum: Roles_Enum;
  roles_enum_comparison_exp: Roles_Enum_Comparison_Exp;
  roles_max_fields: ResolverTypeWrapper<Roles_Max_Fields>;
  roles_min_fields: ResolverTypeWrapper<Roles_Min_Fields>;
  roles_mutation_response: ResolverTypeWrapper<Roles_Mutation_Response>;
  roles_order_by: Roles_Order_By;
  roles_pk_columns_input: Roles_Pk_Columns_Input;
  roles_select_column: Roles_Select_Column;
  roles_set_input: Roles_Set_Input;
  roles_stream_cursor_input: Roles_Stream_Cursor_Input;
  roles_stream_cursor_value_input: Roles_Stream_Cursor_Value_Input;
  roles_updates: Roles_Updates;
  rooms: ResolverTypeWrapper<Rooms>;
  rooms_bool_exp: Rooms_Bool_Exp;
  rooms_constraint: Rooms_Constraint;
  rooms_insert_input: Rooms_Insert_Input;
  rooms_mutation_response: ResolverTypeWrapper<Rooms_Mutation_Response>;
  rooms_obj_rel_insert_input: Rooms_Obj_Rel_Insert_Input;
  rooms_on_conflict: Rooms_On_Conflict;
  rooms_order_by: Rooms_Order_By;
  rooms_pk_columns_input: Rooms_Pk_Columns_Input;
  rooms_select_column: Rooms_Select_Column;
  rooms_set_input: Rooms_Set_Input;
  rooms_stream_cursor_input: Rooms_Stream_Cursor_Input;
  rooms_stream_cursor_value_input: Rooms_Stream_Cursor_Value_Input;
  rooms_update_column: Rooms_Update_Column;
  rooms_updates: Rooms_Updates;
  schema_project_engine_metrics: ResolverTypeWrapper<Schema_Project_Engine_Metrics>;
  schema_project_engine_metrics_bool_exp: Schema_Project_Engine_Metrics_Bool_Exp;
  schema_project_engine_metrics_order_by: Schema_Project_Engine_Metrics_Order_By;
  schema_project_engine_metrics_select_column: Schema_Project_Engine_Metrics_Select_Column;
  schema_project_engine_metrics_stream_cursor_input: Schema_Project_Engine_Metrics_Stream_Cursor_Input;
  schema_project_engine_metrics_stream_cursor_value_input: Schema_Project_Engine_Metrics_Stream_Cursor_Value_Input;
  schema_project_engine_ratio_metrics: ResolverTypeWrapper<Schema_Project_Engine_Ratio_Metrics>;
  schema_project_engine_ratio_metrics_aggregate: ResolverTypeWrapper<Schema_Project_Engine_Ratio_Metrics_Aggregate>;
  schema_project_engine_ratio_metrics_aggregate_fields: ResolverTypeWrapper<Schema_Project_Engine_Ratio_Metrics_Aggregate_Fields>;
  schema_project_engine_ratio_metrics_avg_fields: ResolverTypeWrapper<Schema_Project_Engine_Ratio_Metrics_Avg_Fields>;
  schema_project_engine_ratio_metrics_bool_exp: Schema_Project_Engine_Ratio_Metrics_Bool_Exp;
  schema_project_engine_ratio_metrics_max_fields: ResolverTypeWrapper<Schema_Project_Engine_Ratio_Metrics_Max_Fields>;
  schema_project_engine_ratio_metrics_min_fields: ResolverTypeWrapper<Schema_Project_Engine_Ratio_Metrics_Min_Fields>;
  schema_project_engine_ratio_metrics_order_by: Schema_Project_Engine_Ratio_Metrics_Order_By;
  schema_project_engine_ratio_metrics_select_column: Schema_Project_Engine_Ratio_Metrics_Select_Column;
  schema_project_engine_ratio_metrics_stddev_fields: ResolverTypeWrapper<Schema_Project_Engine_Ratio_Metrics_Stddev_Fields>;
  schema_project_engine_ratio_metrics_stddev_pop_fields: ResolverTypeWrapper<Schema_Project_Engine_Ratio_Metrics_Stddev_Pop_Fields>;
  schema_project_engine_ratio_metrics_stddev_samp_fields: ResolverTypeWrapper<Schema_Project_Engine_Ratio_Metrics_Stddev_Samp_Fields>;
  schema_project_engine_ratio_metrics_stream_cursor_input: Schema_Project_Engine_Ratio_Metrics_Stream_Cursor_Input;
  schema_project_engine_ratio_metrics_stream_cursor_value_input: Schema_Project_Engine_Ratio_Metrics_Stream_Cursor_Value_Input;
  schema_project_engine_ratio_metrics_sum_fields: ResolverTypeWrapper<Schema_Project_Engine_Ratio_Metrics_Sum_Fields>;
  schema_project_engine_ratio_metrics_var_pop_fields: ResolverTypeWrapper<Schema_Project_Engine_Ratio_Metrics_Var_Pop_Fields>;
  schema_project_engine_ratio_metrics_var_samp_fields: ResolverTypeWrapper<Schema_Project_Engine_Ratio_Metrics_Var_Samp_Fields>;
  schema_project_engine_ratio_metrics_variance_fields: ResolverTypeWrapper<Schema_Project_Engine_Ratio_Metrics_Variance_Fields>;
  schema_project_task_count: ResolverTypeWrapper<Schema_Project_Task_Count>;
  schema_project_task_count_aggregate: ResolverTypeWrapper<Schema_Project_Task_Count_Aggregate>;
  schema_project_task_count_aggregate_fields: ResolverTypeWrapper<Schema_Project_Task_Count_Aggregate_Fields>;
  schema_project_task_count_avg_fields: ResolverTypeWrapper<Schema_Project_Task_Count_Avg_Fields>;
  schema_project_task_count_bool_exp: Schema_Project_Task_Count_Bool_Exp;
  schema_project_task_count_by_device: ResolverTypeWrapper<Schema_Project_Task_Count_By_Device>;
  schema_project_task_count_by_device_aggregate: ResolverTypeWrapper<Schema_Project_Task_Count_By_Device_Aggregate>;
  schema_project_task_count_by_device_aggregate_fields: ResolverTypeWrapper<Schema_Project_Task_Count_By_Device_Aggregate_Fields>;
  schema_project_task_count_by_device_avg_fields: ResolverTypeWrapper<Schema_Project_Task_Count_By_Device_Avg_Fields>;
  schema_project_task_count_by_device_bool_exp: Schema_Project_Task_Count_By_Device_Bool_Exp;
  schema_project_task_count_by_device_max_fields: ResolverTypeWrapper<Schema_Project_Task_Count_By_Device_Max_Fields>;
  schema_project_task_count_by_device_min_fields: ResolverTypeWrapper<Schema_Project_Task_Count_By_Device_Min_Fields>;
  schema_project_task_count_by_device_order_by: Schema_Project_Task_Count_By_Device_Order_By;
  schema_project_task_count_by_device_select_column: Schema_Project_Task_Count_By_Device_Select_Column;
  schema_project_task_count_by_device_stddev_fields: ResolverTypeWrapper<Schema_Project_Task_Count_By_Device_Stddev_Fields>;
  schema_project_task_count_by_device_stddev_pop_fields: ResolverTypeWrapper<Schema_Project_Task_Count_By_Device_Stddev_Pop_Fields>;
  schema_project_task_count_by_device_stddev_samp_fields: ResolverTypeWrapper<Schema_Project_Task_Count_By_Device_Stddev_Samp_Fields>;
  schema_project_task_count_by_device_stream_cursor_input: Schema_Project_Task_Count_By_Device_Stream_Cursor_Input;
  schema_project_task_count_by_device_stream_cursor_value_input: Schema_Project_Task_Count_By_Device_Stream_Cursor_Value_Input;
  schema_project_task_count_by_device_sum_fields: ResolverTypeWrapper<Schema_Project_Task_Count_By_Device_Sum_Fields>;
  schema_project_task_count_by_device_var_pop_fields: ResolverTypeWrapper<Schema_Project_Task_Count_By_Device_Var_Pop_Fields>;
  schema_project_task_count_by_device_var_samp_fields: ResolverTypeWrapper<Schema_Project_Task_Count_By_Device_Var_Samp_Fields>;
  schema_project_task_count_by_device_variance_fields: ResolverTypeWrapper<Schema_Project_Task_Count_By_Device_Variance_Fields>;
  schema_project_task_count_max_fields: ResolverTypeWrapper<Schema_Project_Task_Count_Max_Fields>;
  schema_project_task_count_min_fields: ResolverTypeWrapper<Schema_Project_Task_Count_Min_Fields>;
  schema_project_task_count_order_by: Schema_Project_Task_Count_Order_By;
  schema_project_task_count_select_column: Schema_Project_Task_Count_Select_Column;
  schema_project_task_count_stddev_fields: ResolverTypeWrapper<Schema_Project_Task_Count_Stddev_Fields>;
  schema_project_task_count_stddev_pop_fields: ResolverTypeWrapper<Schema_Project_Task_Count_Stddev_Pop_Fields>;
  schema_project_task_count_stddev_samp_fields: ResolverTypeWrapper<Schema_Project_Task_Count_Stddev_Samp_Fields>;
  schema_project_task_count_stream_cursor_input: Schema_Project_Task_Count_Stream_Cursor_Input;
  schema_project_task_count_stream_cursor_value_input: Schema_Project_Task_Count_Stream_Cursor_Value_Input;
  schema_project_task_count_sum_fields: ResolverTypeWrapper<Schema_Project_Task_Count_Sum_Fields>;
  schema_project_task_count_var_pop_fields: ResolverTypeWrapper<Schema_Project_Task_Count_Var_Pop_Fields>;
  schema_project_task_count_var_samp_fields: ResolverTypeWrapper<Schema_Project_Task_Count_Var_Samp_Fields>;
  schema_project_task_count_variance_fields: ResolverTypeWrapper<Schema_Project_Task_Count_Variance_Fields>;
  schema_recording_tag_count_by_day: ResolverTypeWrapper<Schema_Recording_Tag_Count_By_Day>;
  schema_recording_tag_count_by_day_aggregate: ResolverTypeWrapper<Schema_Recording_Tag_Count_By_Day_Aggregate>;
  schema_recording_tag_count_by_day_aggregate_fields: ResolverTypeWrapper<Schema_Recording_Tag_Count_By_Day_Aggregate_Fields>;
  schema_recording_tag_count_by_day_avg_fields: ResolverTypeWrapper<Schema_Recording_Tag_Count_By_Day_Avg_Fields>;
  schema_recording_tag_count_by_day_bool_exp: Schema_Recording_Tag_Count_By_Day_Bool_Exp;
  schema_recording_tag_count_by_day_max_fields: ResolverTypeWrapper<Schema_Recording_Tag_Count_By_Day_Max_Fields>;
  schema_recording_tag_count_by_day_min_fields: ResolverTypeWrapper<Schema_Recording_Tag_Count_By_Day_Min_Fields>;
  schema_recording_tag_count_by_day_order_by: Schema_Recording_Tag_Count_By_Day_Order_By;
  schema_recording_tag_count_by_day_select_column: Schema_Recording_Tag_Count_By_Day_Select_Column;
  schema_recording_tag_count_by_day_stddev_fields: ResolverTypeWrapper<Schema_Recording_Tag_Count_By_Day_Stddev_Fields>;
  schema_recording_tag_count_by_day_stddev_pop_fields: ResolverTypeWrapper<Schema_Recording_Tag_Count_By_Day_Stddev_Pop_Fields>;
  schema_recording_tag_count_by_day_stddev_samp_fields: ResolverTypeWrapper<Schema_Recording_Tag_Count_By_Day_Stddev_Samp_Fields>;
  schema_recording_tag_count_by_day_stream_cursor_input: Schema_Recording_Tag_Count_By_Day_Stream_Cursor_Input;
  schema_recording_tag_count_by_day_stream_cursor_value_input: Schema_Recording_Tag_Count_By_Day_Stream_Cursor_Value_Input;
  schema_recording_tag_count_by_day_sum_fields: ResolverTypeWrapper<Schema_Recording_Tag_Count_By_Day_Sum_Fields>;
  schema_recording_tag_count_by_day_var_pop_fields: ResolverTypeWrapper<Schema_Recording_Tag_Count_By_Day_Var_Pop_Fields>;
  schema_recording_tag_count_by_day_var_samp_fields: ResolverTypeWrapper<Schema_Recording_Tag_Count_By_Day_Var_Samp_Fields>;
  schema_recording_tag_count_by_day_variance_fields: ResolverTypeWrapper<Schema_Recording_Tag_Count_By_Day_Variance_Fields>;
  schema_upsert_task: ResolverTypeWrapper<Schema_Upsert_Task>;
  schema_upsert_task_bool_exp: Schema_Upsert_Task_Bool_Exp;
  schema_upsert_task_order_by: Schema_Upsert_Task_Order_By;
  schema_upsert_task_select_column: Schema_Upsert_Task_Select_Column;
  simulations_scores: ResolverTypeWrapper<Simulations_Scores>;
  simulations_scores_aggregate_order_by: Simulations_Scores_Aggregate_Order_By;
  simulations_scores_arr_rel_insert_input: Simulations_Scores_Arr_Rel_Insert_Input;
  simulations_scores_avg_order_by: Simulations_Scores_Avg_Order_By;
  simulations_scores_bool_exp: Simulations_Scores_Bool_Exp;
  simulations_scores_constraint: Simulations_Scores_Constraint;
  simulations_scores_inc_input: Simulations_Scores_Inc_Input;
  simulations_scores_insert_input: Simulations_Scores_Insert_Input;
  simulations_scores_max_order_by: Simulations_Scores_Max_Order_By;
  simulations_scores_min_order_by: Simulations_Scores_Min_Order_By;
  simulations_scores_mutation_response: ResolverTypeWrapper<Simulations_Scores_Mutation_Response>;
  simulations_scores_on_conflict: Simulations_Scores_On_Conflict;
  simulations_scores_order_by: Simulations_Scores_Order_By;
  simulations_scores_pk_columns_input: Simulations_Scores_Pk_Columns_Input;
  simulations_scores_select_column: Simulations_Scores_Select_Column;
  simulations_scores_set_input: Simulations_Scores_Set_Input;
  simulations_scores_stddev_order_by: Simulations_Scores_Stddev_Order_By;
  simulations_scores_stddev_pop_order_by: Simulations_Scores_Stddev_Pop_Order_By;
  simulations_scores_stddev_samp_order_by: Simulations_Scores_Stddev_Samp_Order_By;
  simulations_scores_stream_cursor_input: Simulations_Scores_Stream_Cursor_Input;
  simulations_scores_stream_cursor_value_input: Simulations_Scores_Stream_Cursor_Value_Input;
  simulations_scores_sum_order_by: Simulations_Scores_Sum_Order_By;
  simulations_scores_update_column: Simulations_Scores_Update_Column;
  simulations_scores_updates: Simulations_Scores_Updates;
  simulations_scores_var_pop_order_by: Simulations_Scores_Var_Pop_Order_By;
  simulations_scores_var_samp_order_by: Simulations_Scores_Var_Samp_Order_By;
  simulations_scores_variance_order_by: Simulations_Scores_Variance_Order_By;
  smallint: ResolverTypeWrapper<Scalars['smallint']>;
  smallint_comparison_exp: Smallint_Comparison_Exp;
  subscription_root: ResolverTypeWrapper<{}>;
  task_field_option: ResolverTypeWrapper<Task_Field_Option>;
  task_field_option_aggregate: ResolverTypeWrapper<Task_Field_Option_Aggregate>;
  task_field_option_aggregate_fields: ResolverTypeWrapper<Task_Field_Option_Aggregate_Fields>;
  task_field_option_aggregate_order_by: Task_Field_Option_Aggregate_Order_By;
  task_field_option_arr_rel_insert_input: Task_Field_Option_Arr_Rel_Insert_Input;
  task_field_option_bool_exp: Task_Field_Option_Bool_Exp;
  task_field_option_constraint: Task_Field_Option_Constraint;
  task_field_option_hashes: ResolverTypeWrapper<Task_Field_Option_Hashes>;
  task_field_option_hashes_aggregate: ResolverTypeWrapper<Task_Field_Option_Hashes_Aggregate>;
  task_field_option_hashes_aggregate_fields: ResolverTypeWrapper<Task_Field_Option_Hashes_Aggregate_Fields>;
  task_field_option_hashes_bool_exp: Task_Field_Option_Hashes_Bool_Exp;
  task_field_option_hashes_max_fields: ResolverTypeWrapper<Task_Field_Option_Hashes_Max_Fields>;
  task_field_option_hashes_min_fields: ResolverTypeWrapper<Task_Field_Option_Hashes_Min_Fields>;
  task_field_option_hashes_order_by: Task_Field_Option_Hashes_Order_By;
  task_field_option_hashes_select_column: Task_Field_Option_Hashes_Select_Column;
  task_field_option_hashes_stream_cursor_input: Task_Field_Option_Hashes_Stream_Cursor_Input;
  task_field_option_hashes_stream_cursor_value_input: Task_Field_Option_Hashes_Stream_Cursor_Value_Input;
  task_field_option_insert_input: Task_Field_Option_Insert_Input;
  task_field_option_max_fields: ResolverTypeWrapper<Task_Field_Option_Max_Fields>;
  task_field_option_max_order_by: Task_Field_Option_Max_Order_By;
  task_field_option_min_fields: ResolverTypeWrapper<Task_Field_Option_Min_Fields>;
  task_field_option_min_order_by: Task_Field_Option_Min_Order_By;
  task_field_option_mutation_response: ResolverTypeWrapper<Task_Field_Option_Mutation_Response>;
  task_field_option_on_conflict: Task_Field_Option_On_Conflict;
  task_field_option_order_by: Task_Field_Option_Order_By;
  task_field_option_pk_columns_input: Task_Field_Option_Pk_Columns_Input;
  task_field_option_select_column: Task_Field_Option_Select_Column;
  task_field_option_set_input: Task_Field_Option_Set_Input;
  task_field_option_stream_cursor_input: Task_Field_Option_Stream_Cursor_Input;
  task_field_option_stream_cursor_value_input: Task_Field_Option_Stream_Cursor_Value_Input;
  task_field_option_update_column: Task_Field_Option_Update_Column;
  task_field_option_updates: Task_Field_Option_Updates;
  task_groups_group_fields: ResolverTypeWrapper<Task_Groups_Group_Fields>;
  task_groups_group_fields_aggregate: ResolverTypeWrapper<Task_Groups_Group_Fields_Aggregate>;
  task_groups_group_fields_aggregate_fields: ResolverTypeWrapper<Task_Groups_Group_Fields_Aggregate_Fields>;
  task_groups_group_fields_aggregate_order_by: Task_Groups_Group_Fields_Aggregate_Order_By;
  task_groups_group_fields_arr_rel_insert_input: Task_Groups_Group_Fields_Arr_Rel_Insert_Input;
  task_groups_group_fields_avg_fields: ResolverTypeWrapper<Task_Groups_Group_Fields_Avg_Fields>;
  task_groups_group_fields_avg_order_by: Task_Groups_Group_Fields_Avg_Order_By;
  task_groups_group_fields_bool_exp: Task_Groups_Group_Fields_Bool_Exp;
  task_groups_group_fields_constraint: Task_Groups_Group_Fields_Constraint;
  task_groups_group_fields_inc_input: Task_Groups_Group_Fields_Inc_Input;
  task_groups_group_fields_insert_input: Task_Groups_Group_Fields_Insert_Input;
  task_groups_group_fields_max_fields: ResolverTypeWrapper<Task_Groups_Group_Fields_Max_Fields>;
  task_groups_group_fields_max_order_by: Task_Groups_Group_Fields_Max_Order_By;
  task_groups_group_fields_min_fields: ResolverTypeWrapper<Task_Groups_Group_Fields_Min_Fields>;
  task_groups_group_fields_min_order_by: Task_Groups_Group_Fields_Min_Order_By;
  task_groups_group_fields_mutation_response: ResolverTypeWrapper<Task_Groups_Group_Fields_Mutation_Response>;
  task_groups_group_fields_on_conflict: Task_Groups_Group_Fields_On_Conflict;
  task_groups_group_fields_order_by: Task_Groups_Group_Fields_Order_By;
  task_groups_group_fields_pk_columns_input: Task_Groups_Group_Fields_Pk_Columns_Input;
  task_groups_group_fields_select_column: Task_Groups_Group_Fields_Select_Column;
  task_groups_group_fields_set_input: Task_Groups_Group_Fields_Set_Input;
  task_groups_group_fields_stddev_fields: ResolverTypeWrapper<Task_Groups_Group_Fields_Stddev_Fields>;
  task_groups_group_fields_stddev_order_by: Task_Groups_Group_Fields_Stddev_Order_By;
  task_groups_group_fields_stddev_pop_fields: ResolverTypeWrapper<Task_Groups_Group_Fields_Stddev_Pop_Fields>;
  task_groups_group_fields_stddev_pop_order_by: Task_Groups_Group_Fields_Stddev_Pop_Order_By;
  task_groups_group_fields_stddev_samp_fields: ResolverTypeWrapper<Task_Groups_Group_Fields_Stddev_Samp_Fields>;
  task_groups_group_fields_stddev_samp_order_by: Task_Groups_Group_Fields_Stddev_Samp_Order_By;
  task_groups_group_fields_stream_cursor_input: Task_Groups_Group_Fields_Stream_Cursor_Input;
  task_groups_group_fields_stream_cursor_value_input: Task_Groups_Group_Fields_Stream_Cursor_Value_Input;
  task_groups_group_fields_sum_fields: ResolverTypeWrapper<Task_Groups_Group_Fields_Sum_Fields>;
  task_groups_group_fields_sum_order_by: Task_Groups_Group_Fields_Sum_Order_By;
  task_groups_group_fields_update_column: Task_Groups_Group_Fields_Update_Column;
  task_groups_group_fields_updates: Task_Groups_Group_Fields_Updates;
  task_groups_group_fields_var_pop_fields: ResolverTypeWrapper<Task_Groups_Group_Fields_Var_Pop_Fields>;
  task_groups_group_fields_var_pop_order_by: Task_Groups_Group_Fields_Var_Pop_Order_By;
  task_groups_group_fields_var_samp_fields: ResolverTypeWrapper<Task_Groups_Group_Fields_Var_Samp_Fields>;
  task_groups_group_fields_var_samp_order_by: Task_Groups_Group_Fields_Var_Samp_Order_By;
  task_groups_group_fields_variance_fields: ResolverTypeWrapper<Task_Groups_Group_Fields_Variance_Fields>;
  task_groups_group_fields_variance_order_by: Task_Groups_Group_Fields_Variance_Order_By;
  task_groups_groups: ResolverTypeWrapper<Task_Groups_Groups>;
  task_groups_groups_aggregate: ResolverTypeWrapper<Task_Groups_Groups_Aggregate>;
  task_groups_groups_aggregate_fields: ResolverTypeWrapper<Task_Groups_Groups_Aggregate_Fields>;
  task_groups_groups_bool_exp: Task_Groups_Groups_Bool_Exp;
  task_groups_groups_constraint: Task_Groups_Groups_Constraint;
  task_groups_groups_insert_input: Task_Groups_Groups_Insert_Input;
  task_groups_groups_max_fields: ResolverTypeWrapper<Task_Groups_Groups_Max_Fields>;
  task_groups_groups_min_fields: ResolverTypeWrapper<Task_Groups_Groups_Min_Fields>;
  task_groups_groups_mutation_response: ResolverTypeWrapper<Task_Groups_Groups_Mutation_Response>;
  task_groups_groups_obj_rel_insert_input: Task_Groups_Groups_Obj_Rel_Insert_Input;
  task_groups_groups_on_conflict: Task_Groups_Groups_On_Conflict;
  task_groups_groups_order_by: Task_Groups_Groups_Order_By;
  task_groups_groups_pk_columns_input: Task_Groups_Groups_Pk_Columns_Input;
  task_groups_groups_select_column: Task_Groups_Groups_Select_Column;
  task_groups_groups_set_input: Task_Groups_Groups_Set_Input;
  task_groups_groups_stream_cursor_input: Task_Groups_Groups_Stream_Cursor_Input;
  task_groups_groups_stream_cursor_value_input: Task_Groups_Groups_Stream_Cursor_Value_Input;
  task_groups_groups_update_column: Task_Groups_Groups_Update_Column;
  task_groups_groups_updates: Task_Groups_Groups_Updates;
  task_groups_hierarchies: ResolverTypeWrapper<Task_Groups_Hierarchies>;
  task_groups_hierarchies_aggregate: ResolverTypeWrapper<Task_Groups_Hierarchies_Aggregate>;
  task_groups_hierarchies_aggregate_fields: ResolverTypeWrapper<Task_Groups_Hierarchies_Aggregate_Fields>;
  task_groups_hierarchies_bool_exp: Task_Groups_Hierarchies_Bool_Exp;
  task_groups_hierarchies_constraint: Task_Groups_Hierarchies_Constraint;
  task_groups_hierarchies_insert_input: Task_Groups_Hierarchies_Insert_Input;
  task_groups_hierarchies_max_fields: ResolverTypeWrapper<Task_Groups_Hierarchies_Max_Fields>;
  task_groups_hierarchies_min_fields: ResolverTypeWrapper<Task_Groups_Hierarchies_Min_Fields>;
  task_groups_hierarchies_mutation_response: ResolverTypeWrapper<Task_Groups_Hierarchies_Mutation_Response>;
  task_groups_hierarchies_obj_rel_insert_input: Task_Groups_Hierarchies_Obj_Rel_Insert_Input;
  task_groups_hierarchies_on_conflict: Task_Groups_Hierarchies_On_Conflict;
  task_groups_hierarchies_order_by: Task_Groups_Hierarchies_Order_By;
  task_groups_hierarchies_pk_columns_input: Task_Groups_Hierarchies_Pk_Columns_Input;
  task_groups_hierarchies_select_column: Task_Groups_Hierarchies_Select_Column;
  task_groups_hierarchies_set_input: Task_Groups_Hierarchies_Set_Input;
  task_groups_hierarchies_stream_cursor_input: Task_Groups_Hierarchies_Stream_Cursor_Input;
  task_groups_hierarchies_stream_cursor_value_input: Task_Groups_Hierarchies_Stream_Cursor_Value_Input;
  task_groups_hierarchies_update_column: Task_Groups_Hierarchies_Update_Column;
  task_groups_hierarchies_updates: Task_Groups_Hierarchies_Updates;
  task_groups_hierarchy_groups: ResolverTypeWrapper<Task_Groups_Hierarchy_Groups>;
  task_groups_hierarchy_groups_aggregate: ResolverTypeWrapper<Task_Groups_Hierarchy_Groups_Aggregate>;
  task_groups_hierarchy_groups_aggregate_fields: ResolverTypeWrapper<Task_Groups_Hierarchy_Groups_Aggregate_Fields>;
  task_groups_hierarchy_groups_aggregate_order_by: Task_Groups_Hierarchy_Groups_Aggregate_Order_By;
  task_groups_hierarchy_groups_arr_rel_insert_input: Task_Groups_Hierarchy_Groups_Arr_Rel_Insert_Input;
  task_groups_hierarchy_groups_avg_fields: ResolverTypeWrapper<Task_Groups_Hierarchy_Groups_Avg_Fields>;
  task_groups_hierarchy_groups_avg_order_by: Task_Groups_Hierarchy_Groups_Avg_Order_By;
  task_groups_hierarchy_groups_bool_exp: Task_Groups_Hierarchy_Groups_Bool_Exp;
  task_groups_hierarchy_groups_constraint: Task_Groups_Hierarchy_Groups_Constraint;
  task_groups_hierarchy_groups_inc_input: Task_Groups_Hierarchy_Groups_Inc_Input;
  task_groups_hierarchy_groups_insert_input: Task_Groups_Hierarchy_Groups_Insert_Input;
  task_groups_hierarchy_groups_max_fields: ResolverTypeWrapper<Task_Groups_Hierarchy_Groups_Max_Fields>;
  task_groups_hierarchy_groups_max_order_by: Task_Groups_Hierarchy_Groups_Max_Order_By;
  task_groups_hierarchy_groups_min_fields: ResolverTypeWrapper<Task_Groups_Hierarchy_Groups_Min_Fields>;
  task_groups_hierarchy_groups_min_order_by: Task_Groups_Hierarchy_Groups_Min_Order_By;
  task_groups_hierarchy_groups_mutation_response: ResolverTypeWrapper<Task_Groups_Hierarchy_Groups_Mutation_Response>;
  task_groups_hierarchy_groups_on_conflict: Task_Groups_Hierarchy_Groups_On_Conflict;
  task_groups_hierarchy_groups_order_by: Task_Groups_Hierarchy_Groups_Order_By;
  task_groups_hierarchy_groups_pk_columns_input: Task_Groups_Hierarchy_Groups_Pk_Columns_Input;
  task_groups_hierarchy_groups_select_column: Task_Groups_Hierarchy_Groups_Select_Column;
  task_groups_hierarchy_groups_set_input: Task_Groups_Hierarchy_Groups_Set_Input;
  task_groups_hierarchy_groups_stddev_fields: ResolverTypeWrapper<Task_Groups_Hierarchy_Groups_Stddev_Fields>;
  task_groups_hierarchy_groups_stddev_order_by: Task_Groups_Hierarchy_Groups_Stddev_Order_By;
  task_groups_hierarchy_groups_stddev_pop_fields: ResolverTypeWrapper<Task_Groups_Hierarchy_Groups_Stddev_Pop_Fields>;
  task_groups_hierarchy_groups_stddev_pop_order_by: Task_Groups_Hierarchy_Groups_Stddev_Pop_Order_By;
  task_groups_hierarchy_groups_stddev_samp_fields: ResolverTypeWrapper<Task_Groups_Hierarchy_Groups_Stddev_Samp_Fields>;
  task_groups_hierarchy_groups_stddev_samp_order_by: Task_Groups_Hierarchy_Groups_Stddev_Samp_Order_By;
  task_groups_hierarchy_groups_stream_cursor_input: Task_Groups_Hierarchy_Groups_Stream_Cursor_Input;
  task_groups_hierarchy_groups_stream_cursor_value_input: Task_Groups_Hierarchy_Groups_Stream_Cursor_Value_Input;
  task_groups_hierarchy_groups_sum_fields: ResolverTypeWrapper<Task_Groups_Hierarchy_Groups_Sum_Fields>;
  task_groups_hierarchy_groups_sum_order_by: Task_Groups_Hierarchy_Groups_Sum_Order_By;
  task_groups_hierarchy_groups_update_column: Task_Groups_Hierarchy_Groups_Update_Column;
  task_groups_hierarchy_groups_updates: Task_Groups_Hierarchy_Groups_Updates;
  task_groups_hierarchy_groups_var_pop_fields: ResolverTypeWrapper<Task_Groups_Hierarchy_Groups_Var_Pop_Fields>;
  task_groups_hierarchy_groups_var_pop_order_by: Task_Groups_Hierarchy_Groups_Var_Pop_Order_By;
  task_groups_hierarchy_groups_var_samp_fields: ResolverTypeWrapper<Task_Groups_Hierarchy_Groups_Var_Samp_Fields>;
  task_groups_hierarchy_groups_var_samp_order_by: Task_Groups_Hierarchy_Groups_Var_Samp_Order_By;
  task_groups_hierarchy_groups_variance_fields: ResolverTypeWrapper<Task_Groups_Hierarchy_Groups_Variance_Fields>;
  task_groups_hierarchy_groups_variance_order_by: Task_Groups_Hierarchy_Groups_Variance_Order_By;
  task_instructions: ResolverTypeWrapper<Task_Instructions>;
  task_instructions_bool_exp: Task_Instructions_Bool_Exp;
  task_instructions_constraint: Task_Instructions_Constraint;
  task_instructions_insert_input: Task_Instructions_Insert_Input;
  task_instructions_mutation_response: ResolverTypeWrapper<Task_Instructions_Mutation_Response>;
  task_instructions_obj_rel_insert_input: Task_Instructions_Obj_Rel_Insert_Input;
  task_instructions_on_conflict: Task_Instructions_On_Conflict;
  task_instructions_order_by: Task_Instructions_Order_By;
  task_instructions_pk_columns_input: Task_Instructions_Pk_Columns_Input;
  task_instructions_select_column: Task_Instructions_Select_Column;
  task_instructions_set_input: Task_Instructions_Set_Input;
  task_instructions_stream_cursor_input: Task_Instructions_Stream_Cursor_Input;
  task_instructions_stream_cursor_value_input: Task_Instructions_Stream_Cursor_Value_Input;
  task_instructions_update_column: Task_Instructions_Update_Column;
  task_instructions_updates: Task_Instructions_Updates;
  task_media: ResolverTypeWrapper<Task_Media>;
  task_media_aggregate: ResolverTypeWrapper<Task_Media_Aggregate>;
  task_media_aggregate_fields: ResolverTypeWrapper<Task_Media_Aggregate_Fields>;
  task_media_aggregate_order_by: Task_Media_Aggregate_Order_By;
  task_media_arr_rel_insert_input: Task_Media_Arr_Rel_Insert_Input;
  task_media_bool_exp: Task_Media_Bool_Exp;
  task_media_constraint: Task_Media_Constraint;
  task_media_insert_input: Task_Media_Insert_Input;
  task_media_max_fields: ResolverTypeWrapper<Task_Media_Max_Fields>;
  task_media_max_order_by: Task_Media_Max_Order_By;
  task_media_min_fields: ResolverTypeWrapper<Task_Media_Min_Fields>;
  task_media_min_order_by: Task_Media_Min_Order_By;
  task_media_mutation_response: ResolverTypeWrapper<Task_Media_Mutation_Response>;
  task_media_on_conflict: Task_Media_On_Conflict;
  task_media_order_by: Task_Media_Order_By;
  task_media_pk_columns_input: Task_Media_Pk_Columns_Input;
  task_media_select_column: Task_Media_Select_Column;
  task_media_set_input: Task_Media_Set_Input;
  task_media_stream_cursor_input: Task_Media_Stream_Cursor_Input;
  task_media_stream_cursor_value_input: Task_Media_Stream_Cursor_Value_Input;
  task_media_update_column: Task_Media_Update_Column;
  task_media_updates: Task_Media_Updates;
  task_statuses: ResolverTypeWrapper<Task_Statuses>;
  task_statuses_bool_exp: Task_Statuses_Bool_Exp;
  task_statuses_order_by: Task_Statuses_Order_By;
  task_statuses_select_column: Task_Statuses_Select_Column;
  task_statuses_stream_cursor_input: Task_Statuses_Stream_Cursor_Input;
  task_statuses_stream_cursor_value_input: Task_Statuses_Stream_Cursor_Value_Input;
  task_translation: ResolverTypeWrapper<Task_Translation>;
  task_translation_aggregate: ResolverTypeWrapper<Task_Translation_Aggregate>;
  task_translation_aggregate_fields: ResolverTypeWrapper<Task_Translation_Aggregate_Fields>;
  task_translation_aggregate_order_by: Task_Translation_Aggregate_Order_By;
  task_translation_arr_rel_insert_input: Task_Translation_Arr_Rel_Insert_Input;
  task_translation_bool_exp: Task_Translation_Bool_Exp;
  task_translation_constraint: Task_Translation_Constraint;
  task_translation_insert_input: Task_Translation_Insert_Input;
  task_translation_max_fields: ResolverTypeWrapper<Task_Translation_Max_Fields>;
  task_translation_max_order_by: Task_Translation_Max_Order_By;
  task_translation_min_fields: ResolverTypeWrapper<Task_Translation_Min_Fields>;
  task_translation_min_order_by: Task_Translation_Min_Order_By;
  task_translation_mutation_response: ResolverTypeWrapper<Task_Translation_Mutation_Response>;
  task_translation_on_conflict: Task_Translation_On_Conflict;
  task_translation_order_by: Task_Translation_Order_By;
  task_translation_pk_columns_input: Task_Translation_Pk_Columns_Input;
  task_translation_select_column: Task_Translation_Select_Column;
  task_translation_set_input: Task_Translation_Set_Input;
  task_translation_stream_cursor_input: Task_Translation_Stream_Cursor_Input;
  task_translation_stream_cursor_value_input: Task_Translation_Stream_Cursor_Value_Input;
  task_translation_update_column: Task_Translation_Update_Column;
  task_translation_updates: Task_Translation_Updates;
  tasks: ResolverTypeWrapper<Tasks>;
  tasks_aggregate: ResolverTypeWrapper<Tasks_Aggregate>;
  tasks_aggregate_fields: ResolverTypeWrapper<Tasks_Aggregate_Fields>;
  tasks_avg_fields: ResolverTypeWrapper<Tasks_Avg_Fields>;
  tasks_bool_exp: Tasks_Bool_Exp;
  tasks_constraint: Tasks_Constraint;
  tasks_inc_input: Tasks_Inc_Input;
  tasks_insert_input: Tasks_Insert_Input;
  tasks_max_fields: ResolverTypeWrapper<Tasks_Max_Fields>;
  tasks_min_fields: ResolverTypeWrapper<Tasks_Min_Fields>;
  tasks_mutation_response: ResolverTypeWrapper<Tasks_Mutation_Response>;
  tasks_obj_rel_insert_input: Tasks_Obj_Rel_Insert_Input;
  tasks_on_conflict: Tasks_On_Conflict;
  tasks_order_by: Tasks_Order_By;
  tasks_pk_columns_input: Tasks_Pk_Columns_Input;
  tasks_select_column: Tasks_Select_Column;
  tasks_set_input: Tasks_Set_Input;
  tasks_stddev_fields: ResolverTypeWrapper<Tasks_Stddev_Fields>;
  tasks_stddev_pop_fields: ResolverTypeWrapper<Tasks_Stddev_Pop_Fields>;
  tasks_stddev_samp_fields: ResolverTypeWrapper<Tasks_Stddev_Samp_Fields>;
  tasks_stream_cursor_input: Tasks_Stream_Cursor_Input;
  tasks_stream_cursor_value_input: Tasks_Stream_Cursor_Value_Input;
  tasks_sum_fields: ResolverTypeWrapper<Tasks_Sum_Fields>;
  tasks_update_column: Tasks_Update_Column;
  tasks_updates: Tasks_Updates;
  tasks_var_pop_fields: ResolverTypeWrapper<Tasks_Var_Pop_Fields>;
  tasks_var_samp_fields: ResolverTypeWrapper<Tasks_Var_Samp_Fields>;
  tasks_variance_fields: ResolverTypeWrapper<Tasks_Variance_Fields>;
  timestamp: ResolverTypeWrapper<Scalars['timestamp']>;
  timestamp_comparison_exp: Timestamp_Comparison_Exp;
  timestamptz: ResolverTypeWrapper<Scalars['timestamptz']>;
  timestamptz_comparison_exp: Timestamptz_Comparison_Exp;
  tool_versions: ResolverTypeWrapper<Tool_Versions>;
  tool_versions_aggregate_order_by: Tool_Versions_Aggregate_Order_By;
  tool_versions_arr_rel_insert_input: Tool_Versions_Arr_Rel_Insert_Input;
  tool_versions_bool_exp: Tool_Versions_Bool_Exp;
  tool_versions_constraint: Tool_Versions_Constraint;
  tool_versions_insert_input: Tool_Versions_Insert_Input;
  tool_versions_max_order_by: Tool_Versions_Max_Order_By;
  tool_versions_min_order_by: Tool_Versions_Min_Order_By;
  tool_versions_mutation_response: ResolverTypeWrapper<Tool_Versions_Mutation_Response>;
  tool_versions_on_conflict: Tool_Versions_On_Conflict;
  tool_versions_order_by: Tool_Versions_Order_By;
  tool_versions_pk_columns_input: Tool_Versions_Pk_Columns_Input;
  tool_versions_select_column: Tool_Versions_Select_Column;
  tool_versions_set_input: Tool_Versions_Set_Input;
  tool_versions_stream_cursor_input: Tool_Versions_Stream_Cursor_Input;
  tool_versions_stream_cursor_value_input: Tool_Versions_Stream_Cursor_Value_Input;
  tool_versions_update_column: Tool_Versions_Update_Column;
  tool_versions_updates: Tool_Versions_Updates;
  tools: ResolverTypeWrapper<Tools>;
  tools_bool_exp: Tools_Bool_Exp;
  tools_constraint: Tools_Constraint;
  tools_insert_input: Tools_Insert_Input;
  tools_mutation_response: ResolverTypeWrapper<Tools_Mutation_Response>;
  tools_obj_rel_insert_input: Tools_Obj_Rel_Insert_Input;
  tools_on_conflict: Tools_On_Conflict;
  tools_order_by: Tools_Order_By;
  tools_pk_columns_input: Tools_Pk_Columns_Input;
  tools_select_column: Tools_Select_Column;
  tools_set_input: Tools_Set_Input;
  tools_stream_cursor_input: Tools_Stream_Cursor_Input;
  tools_stream_cursor_value_input: Tools_Stream_Cursor_Value_Input;
  tools_update_column: Tools_Update_Column;
  tools_updates: Tools_Updates;
  types: ResolverTypeWrapper<Types>;
  types_aggregate: ResolverTypeWrapper<Types_Aggregate>;
  types_aggregate_fields: ResolverTypeWrapper<Types_Aggregate_Fields>;
  types_bool_exp: Types_Bool_Exp;
  types_constraint: Types_Constraint;
  types_insert_input: Types_Insert_Input;
  types_max_fields: ResolverTypeWrapper<Types_Max_Fields>;
  types_min_fields: ResolverTypeWrapper<Types_Min_Fields>;
  types_mutation_response: ResolverTypeWrapper<Types_Mutation_Response>;
  types_obj_rel_insert_input: Types_Obj_Rel_Insert_Input;
  types_on_conflict: Types_On_Conflict;
  types_order_by: Types_Order_By;
  types_pk_columns_input: Types_Pk_Columns_Input;
  types_select_column: Types_Select_Column;
  types_set_input: Types_Set_Input;
  types_stream_cursor_input: Types_Stream_Cursor_Input;
  types_stream_cursor_value_input: Types_Stream_Cursor_Value_Input;
  types_update_column: Types_Update_Column;
  types_updates: Types_Updates;
  upsert_task_args: Upsert_Task_Args;
  user_recordings_by_day_stats: ResolverTypeWrapper<User_Recordings_By_Day_Stats>;
  user_recordings_by_day_stats_aggregate: ResolverTypeWrapper<User_Recordings_By_Day_Stats_Aggregate>;
  user_recordings_by_day_stats_aggregate_fields: ResolverTypeWrapper<User_Recordings_By_Day_Stats_Aggregate_Fields>;
  user_recordings_by_day_stats_aggregate_order_by: User_Recordings_By_Day_Stats_Aggregate_Order_By;
  user_recordings_by_day_stats_avg_fields: ResolverTypeWrapper<User_Recordings_By_Day_Stats_Avg_Fields>;
  user_recordings_by_day_stats_avg_order_by: User_Recordings_By_Day_Stats_Avg_Order_By;
  user_recordings_by_day_stats_bool_exp: User_Recordings_By_Day_Stats_Bool_Exp;
  user_recordings_by_day_stats_max_fields: ResolverTypeWrapper<User_Recordings_By_Day_Stats_Max_Fields>;
  user_recordings_by_day_stats_max_order_by: User_Recordings_By_Day_Stats_Max_Order_By;
  user_recordings_by_day_stats_min_fields: ResolverTypeWrapper<User_Recordings_By_Day_Stats_Min_Fields>;
  user_recordings_by_day_stats_min_order_by: User_Recordings_By_Day_Stats_Min_Order_By;
  user_recordings_by_day_stats_order_by: User_Recordings_By_Day_Stats_Order_By;
  user_recordings_by_day_stats_select_column: User_Recordings_By_Day_Stats_Select_Column;
  user_recordings_by_day_stats_stddev_fields: ResolverTypeWrapper<User_Recordings_By_Day_Stats_Stddev_Fields>;
  user_recordings_by_day_stats_stddev_order_by: User_Recordings_By_Day_Stats_Stddev_Order_By;
  user_recordings_by_day_stats_stddev_pop_fields: ResolverTypeWrapper<User_Recordings_By_Day_Stats_Stddev_Pop_Fields>;
  user_recordings_by_day_stats_stddev_pop_order_by: User_Recordings_By_Day_Stats_Stddev_Pop_Order_By;
  user_recordings_by_day_stats_stddev_samp_fields: ResolverTypeWrapper<User_Recordings_By_Day_Stats_Stddev_Samp_Fields>;
  user_recordings_by_day_stats_stddev_samp_order_by: User_Recordings_By_Day_Stats_Stddev_Samp_Order_By;
  user_recordings_by_day_stats_stream_cursor_input: User_Recordings_By_Day_Stats_Stream_Cursor_Input;
  user_recordings_by_day_stats_stream_cursor_value_input: User_Recordings_By_Day_Stats_Stream_Cursor_Value_Input;
  user_recordings_by_day_stats_sum_fields: ResolverTypeWrapper<User_Recordings_By_Day_Stats_Sum_Fields>;
  user_recordings_by_day_stats_sum_order_by: User_Recordings_By_Day_Stats_Sum_Order_By;
  user_recordings_by_day_stats_var_pop_fields: ResolverTypeWrapper<User_Recordings_By_Day_Stats_Var_Pop_Fields>;
  user_recordings_by_day_stats_var_pop_order_by: User_Recordings_By_Day_Stats_Var_Pop_Order_By;
  user_recordings_by_day_stats_var_samp_fields: ResolverTypeWrapper<User_Recordings_By_Day_Stats_Var_Samp_Fields>;
  user_recordings_by_day_stats_var_samp_order_by: User_Recordings_By_Day_Stats_Var_Samp_Order_By;
  user_recordings_by_day_stats_variance_fields: ResolverTypeWrapper<User_Recordings_By_Day_Stats_Variance_Fields>;
  user_recordings_by_day_stats_variance_order_by: User_Recordings_By_Day_Stats_Variance_Order_By;
  users: ResolverTypeWrapper<Users>;
  users_aggregate: ResolverTypeWrapper<Users_Aggregate>;
  users_aggregate_fields: ResolverTypeWrapper<Users_Aggregate_Fields>;
  users_aggregate_order_by: Users_Aggregate_Order_By;
  users_arr_rel_insert_input: Users_Arr_Rel_Insert_Input;
  users_bool_exp: Users_Bool_Exp;
  users_constraint: Users_Constraint;
  users_insert_input: Users_Insert_Input;
  users_max_fields: ResolverTypeWrapper<Users_Max_Fields>;
  users_max_order_by: Users_Max_Order_By;
  users_min_fields: ResolverTypeWrapper<Users_Min_Fields>;
  users_min_order_by: Users_Min_Order_By;
  users_mutation_response: ResolverTypeWrapper<Users_Mutation_Response>;
  users_obj_rel_insert_input: Users_Obj_Rel_Insert_Input;
  users_on_conflict: Users_On_Conflict;
  users_order_by: Users_Order_By;
  users_pk_columns_input: Users_Pk_Columns_Input;
  users_select_column: Users_Select_Column;
  users_set_input: Users_Set_Input;
  users_stream_cursor_input: Users_Stream_Cursor_Input;
  users_stream_cursor_value_input: Users_Stream_Cursor_Value_Input;
  users_update_column: Users_Update_Column;
  users_updates: Users_Updates;
  uuid: ResolverTypeWrapper<Scalars['uuid']>;
  uuid_comparison_exp: Uuid_Comparison_Exp;
  xtensa_configurations: ResolverTypeWrapper<Xtensa_Configurations>;
  xtensa_configurations_aggregate: ResolverTypeWrapper<Xtensa_Configurations_Aggregate>;
  xtensa_configurations_aggregate_fields: ResolverTypeWrapper<Xtensa_Configurations_Aggregate_Fields>;
  xtensa_configurations_bool_exp: Xtensa_Configurations_Bool_Exp;
  xtensa_configurations_constraint: Xtensa_Configurations_Constraint;
  xtensa_configurations_insert_input: Xtensa_Configurations_Insert_Input;
  xtensa_configurations_max_fields: ResolverTypeWrapper<Xtensa_Configurations_Max_Fields>;
  xtensa_configurations_min_fields: ResolverTypeWrapper<Xtensa_Configurations_Min_Fields>;
  xtensa_configurations_mutation_response: ResolverTypeWrapper<Xtensa_Configurations_Mutation_Response>;
  xtensa_configurations_obj_rel_insert_input: Xtensa_Configurations_Obj_Rel_Insert_Input;
  xtensa_configurations_on_conflict: Xtensa_Configurations_On_Conflict;
  xtensa_configurations_order_by: Xtensa_Configurations_Order_By;
  xtensa_configurations_pk_columns_input: Xtensa_Configurations_Pk_Columns_Input;
  xtensa_configurations_select_column: Xtensa_Configurations_Select_Column;
  xtensa_configurations_set_input: Xtensa_Configurations_Set_Input;
  xtensa_configurations_stream_cursor_input: Xtensa_Configurations_Stream_Cursor_Input;
  xtensa_configurations_stream_cursor_value_input: Xtensa_Configurations_Stream_Cursor_Value_Input;
  xtensa_configurations_update_column: Xtensa_Configurations_Update_Column;
  xtensa_configurations_updates: Xtensa_Configurations_Updates;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Boolean_comparison_exp: Boolean_Comparison_Exp;
  Boolean: Scalars['Boolean'];
  ElapiSlackChannel: ElapiSlackChannel;
  String: Scalars['String'];
  Float_comparison_exp: Float_Comparison_Exp;
  Float: Scalars['Float'];
  Int_comparison_exp: Int_Comparison_Exp;
  Int: Scalars['Int'];
  String_comparison_exp: String_Comparison_Exp;
  _int2: Scalars['_int2'];
  _int2_comparison_exp: _Int2_Comparison_Exp;
  _int4: Scalars['_int4'];
  _int4_comparison_exp: _Int4_Comparison_Exp;
  _text: Scalars['_text'];
  _text_comparison_exp: _Text_Comparison_Exp;
  _uuid: Scalars['_uuid'];
  audio_driver: Audio_Driver;
  audio_driver_bool_exp: Audio_Driver_Bool_Exp;
  audio_driver_insert_input: Audio_Driver_Insert_Input;
  audio_driver_mutation_response: Audio_Driver_Mutation_Response;
  audio_driver_obj_rel_insert_input: Audio_Driver_Obj_Rel_Insert_Input;
  audio_driver_on_conflict: Audio_Driver_On_Conflict;
  audio_driver_order_by: Audio_Driver_Order_By;
  audio_driver_pk_columns_input: Audio_Driver_Pk_Columns_Input;
  audio_driver_set_input: Audio_Driver_Set_Input;
  audio_driver_stream_cursor_input: Audio_Driver_Stream_Cursor_Input;
  audio_driver_stream_cursor_value_input: Audio_Driver_Stream_Cursor_Value_Input;
  audio_driver_updates: Audio_Driver_Updates;
  audio_measurements_components: Audio_Measurements_Components;
  audio_measurements_components_bool_exp: Audio_Measurements_Components_Bool_Exp;
  audio_measurements_components_insert_input: Audio_Measurements_Components_Insert_Input;
  audio_measurements_components_mutation_response: Audio_Measurements_Components_Mutation_Response;
  audio_measurements_components_obj_rel_insert_input: Audio_Measurements_Components_Obj_Rel_Insert_Input;
  audio_measurements_components_on_conflict: Audio_Measurements_Components_On_Conflict;
  audio_measurements_components_order_by: Audio_Measurements_Components_Order_By;
  audio_measurements_components_pk_columns_input: Audio_Measurements_Components_Pk_Columns_Input;
  audio_measurements_components_set_input: Audio_Measurements_Components_Set_Input;
  audio_measurements_components_stream_cursor_input: Audio_Measurements_Components_Stream_Cursor_Input;
  audio_measurements_components_stream_cursor_value_input: Audio_Measurements_Components_Stream_Cursor_Value_Input;
  audio_measurements_components_updates: Audio_Measurements_Components_Updates;
  audio_measurements_configurations: Audio_Measurements_Configurations;
  audio_measurements_configurations_aggregate_order_by: Audio_Measurements_Configurations_Aggregate_Order_By;
  audio_measurements_configurations_arr_rel_insert_input: Audio_Measurements_Configurations_Arr_Rel_Insert_Input;
  audio_measurements_configurations_bool_exp: Audio_Measurements_Configurations_Bool_Exp;
  audio_measurements_configurations_insert_input: Audio_Measurements_Configurations_Insert_Input;
  audio_measurements_configurations_max_order_by: Audio_Measurements_Configurations_Max_Order_By;
  audio_measurements_configurations_min_order_by: Audio_Measurements_Configurations_Min_Order_By;
  audio_measurements_configurations_mutation_response: Audio_Measurements_Configurations_Mutation_Response;
  audio_measurements_configurations_obj_rel_insert_input: Audio_Measurements_Configurations_Obj_Rel_Insert_Input;
  audio_measurements_configurations_on_conflict: Audio_Measurements_Configurations_On_Conflict;
  audio_measurements_configurations_order_by: Audio_Measurements_Configurations_Order_By;
  audio_measurements_configurations_pk_columns_input: Audio_Measurements_Configurations_Pk_Columns_Input;
  audio_measurements_configurations_set_input: Audio_Measurements_Configurations_Set_Input;
  audio_measurements_configurations_stream_cursor_input: Audio_Measurements_Configurations_Stream_Cursor_Input;
  audio_measurements_configurations_stream_cursor_value_input: Audio_Measurements_Configurations_Stream_Cursor_Value_Input;
  audio_measurements_configurations_updates: Audio_Measurements_Configurations_Updates;
  audio_measurements_data_types: Audio_Measurements_Data_Types;
  audio_measurements_data_types_aggregate: Audio_Measurements_Data_Types_Aggregate;
  audio_measurements_data_types_aggregate_fields: Audio_Measurements_Data_Types_Aggregate_Fields;
  audio_measurements_data_types_bool_exp: Audio_Measurements_Data_Types_Bool_Exp;
  audio_measurements_data_types_max_fields: Audio_Measurements_Data_Types_Max_Fields;
  audio_measurements_data_types_min_fields: Audio_Measurements_Data_Types_Min_Fields;
  audio_measurements_data_types_order_by: Audio_Measurements_Data_Types_Order_By;
  audio_measurements_data_types_stream_cursor_input: Audio_Measurements_Data_Types_Stream_Cursor_Input;
  audio_measurements_data_types_stream_cursor_value_input: Audio_Measurements_Data_Types_Stream_Cursor_Value_Input;
  audio_measurements_master_files: Audio_Measurements_Master_Files;
  audio_measurements_master_files_aggregate: Audio_Measurements_Master_Files_Aggregate;
  audio_measurements_master_files_aggregate_fields: Audio_Measurements_Master_Files_Aggregate_Fields;
  audio_measurements_master_files_aggregate_order_by: Audio_Measurements_Master_Files_Aggregate_Order_By;
  audio_measurements_master_files_avg_fields: Audio_Measurements_Master_Files_Avg_Fields;
  audio_measurements_master_files_avg_order_by: Audio_Measurements_Master_Files_Avg_Order_By;
  audio_measurements_master_files_bool_exp: Audio_Measurements_Master_Files_Bool_Exp;
  audio_measurements_master_files_inc_input: Audio_Measurements_Master_Files_Inc_Input;
  audio_measurements_master_files_insert_input: Audio_Measurements_Master_Files_Insert_Input;
  audio_measurements_master_files_max_fields: Audio_Measurements_Master_Files_Max_Fields;
  audio_measurements_master_files_max_order_by: Audio_Measurements_Master_Files_Max_Order_By;
  audio_measurements_master_files_min_fields: Audio_Measurements_Master_Files_Min_Fields;
  audio_measurements_master_files_min_order_by: Audio_Measurements_Master_Files_Min_Order_By;
  audio_measurements_master_files_mutation_response: Audio_Measurements_Master_Files_Mutation_Response;
  audio_measurements_master_files_obj_rel_insert_input: Audio_Measurements_Master_Files_Obj_Rel_Insert_Input;
  audio_measurements_master_files_on_conflict: Audio_Measurements_Master_Files_On_Conflict;
  audio_measurements_master_files_order_by: Audio_Measurements_Master_Files_Order_By;
  audio_measurements_master_files_pk_columns_input: Audio_Measurements_Master_Files_Pk_Columns_Input;
  audio_measurements_master_files_set_input: Audio_Measurements_Master_Files_Set_Input;
  audio_measurements_master_files_stddev_fields: Audio_Measurements_Master_Files_Stddev_Fields;
  audio_measurements_master_files_stddev_order_by: Audio_Measurements_Master_Files_Stddev_Order_By;
  audio_measurements_master_files_stddev_pop_fields: Audio_Measurements_Master_Files_Stddev_Pop_Fields;
  audio_measurements_master_files_stddev_pop_order_by: Audio_Measurements_Master_Files_Stddev_Pop_Order_By;
  audio_measurements_master_files_stddev_samp_fields: Audio_Measurements_Master_Files_Stddev_Samp_Fields;
  audio_measurements_master_files_stddev_samp_order_by: Audio_Measurements_Master_Files_Stddev_Samp_Order_By;
  audio_measurements_master_files_stream_cursor_input: Audio_Measurements_Master_Files_Stream_Cursor_Input;
  audio_measurements_master_files_stream_cursor_value_input: Audio_Measurements_Master_Files_Stream_Cursor_Value_Input;
  audio_measurements_master_files_sum_fields: Audio_Measurements_Master_Files_Sum_Fields;
  audio_measurements_master_files_sum_order_by: Audio_Measurements_Master_Files_Sum_Order_By;
  audio_measurements_master_files_updates: Audio_Measurements_Master_Files_Updates;
  audio_measurements_master_files_var_pop_fields: Audio_Measurements_Master_Files_Var_Pop_Fields;
  audio_measurements_master_files_var_pop_order_by: Audio_Measurements_Master_Files_Var_Pop_Order_By;
  audio_measurements_master_files_var_samp_fields: Audio_Measurements_Master_Files_Var_Samp_Fields;
  audio_measurements_master_files_var_samp_order_by: Audio_Measurements_Master_Files_Var_Samp_Order_By;
  audio_measurements_master_files_variance_fields: Audio_Measurements_Master_Files_Variance_Fields;
  audio_measurements_master_files_variance_order_by: Audio_Measurements_Master_Files_Variance_Order_By;
  audio_measurements_orientations: Audio_Measurements_Orientations;
  audio_measurements_orientations_bool_exp: Audio_Measurements_Orientations_Bool_Exp;
  audio_measurements_orientations_insert_input: Audio_Measurements_Orientations_Insert_Input;
  audio_measurements_orientations_mutation_response: Audio_Measurements_Orientations_Mutation_Response;
  audio_measurements_orientations_obj_rel_insert_input: Audio_Measurements_Orientations_Obj_Rel_Insert_Input;
  audio_measurements_orientations_on_conflict: Audio_Measurements_Orientations_On_Conflict;
  audio_measurements_orientations_order_by: Audio_Measurements_Orientations_Order_By;
  audio_measurements_orientations_pk_columns_input: Audio_Measurements_Orientations_Pk_Columns_Input;
  audio_measurements_orientations_set_input: Audio_Measurements_Orientations_Set_Input;
  audio_measurements_orientations_stream_cursor_input: Audio_Measurements_Orientations_Stream_Cursor_Input;
  audio_measurements_orientations_stream_cursor_value_input: Audio_Measurements_Orientations_Stream_Cursor_Value_Input;
  audio_measurements_orientations_updates: Audio_Measurements_Orientations_Updates;
  audio_measurements_payload_recording: Audio_Measurements_Payload_Recording;
  audio_measurements_payload_recording_aggregate: Audio_Measurements_Payload_Recording_Aggregate;
  audio_measurements_payload_recording_aggregate_fields: Audio_Measurements_Payload_Recording_Aggregate_Fields;
  audio_measurements_payload_recording_aggregate_order_by: Audio_Measurements_Payload_Recording_Aggregate_Order_By;
  audio_measurements_payload_recording_arr_rel_insert_input: Audio_Measurements_Payload_Recording_Arr_Rel_Insert_Input;
  audio_measurements_payload_recording_bool_exp: Audio_Measurements_Payload_Recording_Bool_Exp;
  audio_measurements_payload_recording_insert_input: Audio_Measurements_Payload_Recording_Insert_Input;
  audio_measurements_payload_recording_max_fields: Audio_Measurements_Payload_Recording_Max_Fields;
  audio_measurements_payload_recording_max_order_by: Audio_Measurements_Payload_Recording_Max_Order_By;
  audio_measurements_payload_recording_min_fields: Audio_Measurements_Payload_Recording_Min_Fields;
  audio_measurements_payload_recording_min_order_by: Audio_Measurements_Payload_Recording_Min_Order_By;
  audio_measurements_payload_recording_mutation_response: Audio_Measurements_Payload_Recording_Mutation_Response;
  audio_measurements_payload_recording_on_conflict: Audio_Measurements_Payload_Recording_On_Conflict;
  audio_measurements_payload_recording_order_by: Audio_Measurements_Payload_Recording_Order_By;
  audio_measurements_payload_recording_pk_columns_input: Audio_Measurements_Payload_Recording_Pk_Columns_Input;
  audio_measurements_payload_recording_set_input: Audio_Measurements_Payload_Recording_Set_Input;
  audio_measurements_payload_recording_stream_cursor_input: Audio_Measurements_Payload_Recording_Stream_Cursor_Input;
  audio_measurements_payload_recording_stream_cursor_value_input: Audio_Measurements_Payload_Recording_Stream_Cursor_Value_Input;
  audio_measurements_payload_recording_updates: Audio_Measurements_Payload_Recording_Updates;
  audio_measurements_placements: Audio_Measurements_Placements;
  audio_measurements_placements_bool_exp: Audio_Measurements_Placements_Bool_Exp;
  audio_measurements_placements_insert_input: Audio_Measurements_Placements_Insert_Input;
  audio_measurements_placements_mutation_response: Audio_Measurements_Placements_Mutation_Response;
  audio_measurements_placements_obj_rel_insert_input: Audio_Measurements_Placements_Obj_Rel_Insert_Input;
  audio_measurements_placements_on_conflict: Audio_Measurements_Placements_On_Conflict;
  audio_measurements_placements_order_by: Audio_Measurements_Placements_Order_By;
  audio_measurements_placements_pk_columns_input: Audio_Measurements_Placements_Pk_Columns_Input;
  audio_measurements_placements_set_input: Audio_Measurements_Placements_Set_Input;
  audio_measurements_placements_stream_cursor_input: Audio_Measurements_Placements_Stream_Cursor_Input;
  audio_measurements_placements_stream_cursor_value_input: Audio_Measurements_Placements_Stream_Cursor_Value_Input;
  audio_measurements_placements_updates: Audio_Measurements_Placements_Updates;
  audio_measurements_project_tasks: Audio_Measurements_Project_Tasks;
  audio_measurements_project_tasks_aggregate: Audio_Measurements_Project_Tasks_Aggregate;
  audio_measurements_project_tasks_aggregate_fields: Audio_Measurements_Project_Tasks_Aggregate_Fields;
  audio_measurements_project_tasks_aggregate_order_by: Audio_Measurements_Project_Tasks_Aggregate_Order_By;
  audio_measurements_project_tasks_arr_rel_insert_input: Audio_Measurements_Project_Tasks_Arr_Rel_Insert_Input;
  audio_measurements_project_tasks_avg_fields: Audio_Measurements_Project_Tasks_Avg_Fields;
  audio_measurements_project_tasks_avg_order_by: Audio_Measurements_Project_Tasks_Avg_Order_By;
  audio_measurements_project_tasks_bool_exp: Audio_Measurements_Project_Tasks_Bool_Exp;
  audio_measurements_project_tasks_inc_input: Audio_Measurements_Project_Tasks_Inc_Input;
  audio_measurements_project_tasks_insert_input: Audio_Measurements_Project_Tasks_Insert_Input;
  audio_measurements_project_tasks_max_fields: Audio_Measurements_Project_Tasks_Max_Fields;
  audio_measurements_project_tasks_max_order_by: Audio_Measurements_Project_Tasks_Max_Order_By;
  audio_measurements_project_tasks_min_fields: Audio_Measurements_Project_Tasks_Min_Fields;
  audio_measurements_project_tasks_min_order_by: Audio_Measurements_Project_Tasks_Min_Order_By;
  audio_measurements_project_tasks_mutation_response: Audio_Measurements_Project_Tasks_Mutation_Response;
  audio_measurements_project_tasks_on_conflict: Audio_Measurements_Project_Tasks_On_Conflict;
  audio_measurements_project_tasks_order_by: Audio_Measurements_Project_Tasks_Order_By;
  audio_measurements_project_tasks_pk_columns_input: Audio_Measurements_Project_Tasks_Pk_Columns_Input;
  audio_measurements_project_tasks_set_input: Audio_Measurements_Project_Tasks_Set_Input;
  audio_measurements_project_tasks_stddev_fields: Audio_Measurements_Project_Tasks_Stddev_Fields;
  audio_measurements_project_tasks_stddev_order_by: Audio_Measurements_Project_Tasks_Stddev_Order_By;
  audio_measurements_project_tasks_stddev_pop_fields: Audio_Measurements_Project_Tasks_Stddev_Pop_Fields;
  audio_measurements_project_tasks_stddev_pop_order_by: Audio_Measurements_Project_Tasks_Stddev_Pop_Order_By;
  audio_measurements_project_tasks_stddev_samp_fields: Audio_Measurements_Project_Tasks_Stddev_Samp_Fields;
  audio_measurements_project_tasks_stddev_samp_order_by: Audio_Measurements_Project_Tasks_Stddev_Samp_Order_By;
  audio_measurements_project_tasks_stream_cursor_input: Audio_Measurements_Project_Tasks_Stream_Cursor_Input;
  audio_measurements_project_tasks_stream_cursor_value_input: Audio_Measurements_Project_Tasks_Stream_Cursor_Value_Input;
  audio_measurements_project_tasks_sum_fields: Audio_Measurements_Project_Tasks_Sum_Fields;
  audio_measurements_project_tasks_sum_order_by: Audio_Measurements_Project_Tasks_Sum_Order_By;
  audio_measurements_project_tasks_updates: Audio_Measurements_Project_Tasks_Updates;
  audio_measurements_project_tasks_var_pop_fields: Audio_Measurements_Project_Tasks_Var_Pop_Fields;
  audio_measurements_project_tasks_var_pop_order_by: Audio_Measurements_Project_Tasks_Var_Pop_Order_By;
  audio_measurements_project_tasks_var_samp_fields: Audio_Measurements_Project_Tasks_Var_Samp_Fields;
  audio_measurements_project_tasks_var_samp_order_by: Audio_Measurements_Project_Tasks_Var_Samp_Order_By;
  audio_measurements_project_tasks_variance_fields: Audio_Measurements_Project_Tasks_Variance_Fields;
  audio_measurements_project_tasks_variance_order_by: Audio_Measurements_Project_Tasks_Variance_Order_By;
  audio_measurements_recordings: Audio_Measurements_Recordings;
  audio_measurements_recordings_aggregate: Audio_Measurements_Recordings_Aggregate;
  audio_measurements_recordings_aggregate_fields: Audio_Measurements_Recordings_Aggregate_Fields;
  audio_measurements_recordings_aggregate_order_by: Audio_Measurements_Recordings_Aggregate_Order_By;
  audio_measurements_recordings_arr_rel_insert_input: Audio_Measurements_Recordings_Arr_Rel_Insert_Input;
  audio_measurements_recordings_avg_fields: Audio_Measurements_Recordings_Avg_Fields;
  audio_measurements_recordings_avg_order_by: Audio_Measurements_Recordings_Avg_Order_By;
  audio_measurements_recordings_bool_exp: Audio_Measurements_Recordings_Bool_Exp;
  audio_measurements_recordings_inc_input: Audio_Measurements_Recordings_Inc_Input;
  audio_measurements_recordings_insert_input: Audio_Measurements_Recordings_Insert_Input;
  audio_measurements_recordings_max_fields: Audio_Measurements_Recordings_Max_Fields;
  audio_measurements_recordings_max_order_by: Audio_Measurements_Recordings_Max_Order_By;
  audio_measurements_recordings_min_fields: Audio_Measurements_Recordings_Min_Fields;
  audio_measurements_recordings_min_order_by: Audio_Measurements_Recordings_Min_Order_By;
  audio_measurements_recordings_mutation_response: Audio_Measurements_Recordings_Mutation_Response;
  audio_measurements_recordings_obj_rel_insert_input: Audio_Measurements_Recordings_Obj_Rel_Insert_Input;
  audio_measurements_recordings_on_conflict: Audio_Measurements_Recordings_On_Conflict;
  audio_measurements_recordings_order_by: Audio_Measurements_Recordings_Order_By;
  audio_measurements_recordings_pk_columns_input: Audio_Measurements_Recordings_Pk_Columns_Input;
  audio_measurements_recordings_set_input: Audio_Measurements_Recordings_Set_Input;
  audio_measurements_recordings_stddev_fields: Audio_Measurements_Recordings_Stddev_Fields;
  audio_measurements_recordings_stddev_order_by: Audio_Measurements_Recordings_Stddev_Order_By;
  audio_measurements_recordings_stddev_pop_fields: Audio_Measurements_Recordings_Stddev_Pop_Fields;
  audio_measurements_recordings_stddev_pop_order_by: Audio_Measurements_Recordings_Stddev_Pop_Order_By;
  audio_measurements_recordings_stddev_samp_fields: Audio_Measurements_Recordings_Stddev_Samp_Fields;
  audio_measurements_recordings_stddev_samp_order_by: Audio_Measurements_Recordings_Stddev_Samp_Order_By;
  audio_measurements_recordings_stream_cursor_input: Audio_Measurements_Recordings_Stream_Cursor_Input;
  audio_measurements_recordings_stream_cursor_value_input: Audio_Measurements_Recordings_Stream_Cursor_Value_Input;
  audio_measurements_recordings_sum_fields: Audio_Measurements_Recordings_Sum_Fields;
  audio_measurements_recordings_sum_order_by: Audio_Measurements_Recordings_Sum_Order_By;
  audio_measurements_recordings_updates: Audio_Measurements_Recordings_Updates;
  audio_measurements_recordings_var_pop_fields: Audio_Measurements_Recordings_Var_Pop_Fields;
  audio_measurements_recordings_var_pop_order_by: Audio_Measurements_Recordings_Var_Pop_Order_By;
  audio_measurements_recordings_var_samp_fields: Audio_Measurements_Recordings_Var_Samp_Fields;
  audio_measurements_recordings_var_samp_order_by: Audio_Measurements_Recordings_Var_Samp_Order_By;
  audio_measurements_recordings_variance_fields: Audio_Measurements_Recordings_Variance_Fields;
  audio_measurements_recordings_variance_order_by: Audio_Measurements_Recordings_Variance_Order_By;
  audio_measurements_stages: Audio_Measurements_Stages;
  audio_measurements_stages_bool_exp: Audio_Measurements_Stages_Bool_Exp;
  audio_measurements_stages_insert_input: Audio_Measurements_Stages_Insert_Input;
  audio_measurements_stages_mutation_response: Audio_Measurements_Stages_Mutation_Response;
  audio_measurements_stages_obj_rel_insert_input: Audio_Measurements_Stages_Obj_Rel_Insert_Input;
  audio_measurements_stages_on_conflict: Audio_Measurements_Stages_On_Conflict;
  audio_measurements_stages_order_by: Audio_Measurements_Stages_Order_By;
  audio_measurements_stages_pk_columns_input: Audio_Measurements_Stages_Pk_Columns_Input;
  audio_measurements_stages_set_input: Audio_Measurements_Stages_Set_Input;
  audio_measurements_stages_stream_cursor_input: Audio_Measurements_Stages_Stream_Cursor_Input;
  audio_measurements_stages_stream_cursor_value_input: Audio_Measurements_Stages_Stream_Cursor_Value_Input;
  audio_measurements_stages_updates: Audio_Measurements_Stages_Updates;
  bigint: Scalars['bigint'];
  bigint_comparison_exp: Bigint_Comparison_Exp;
  bringup_checklist_deliverable: Bringup_Checklist_Deliverable;
  bringup_checklist_deliverable_aggregate: Bringup_Checklist_Deliverable_Aggregate;
  bringup_checklist_deliverable_aggregate_fields: Bringup_Checklist_Deliverable_Aggregate_Fields;
  bringup_checklist_deliverable_aggregate_order_by: Bringup_Checklist_Deliverable_Aggregate_Order_By;
  bringup_checklist_deliverable_arr_rel_insert_input: Bringup_Checklist_Deliverable_Arr_Rel_Insert_Input;
  bringup_checklist_deliverable_bool_exp: Bringup_Checklist_Deliverable_Bool_Exp;
  bringup_checklist_deliverable_insert_input: Bringup_Checklist_Deliverable_Insert_Input;
  bringup_checklist_deliverable_max_fields: Bringup_Checklist_Deliverable_Max_Fields;
  bringup_checklist_deliverable_max_order_by: Bringup_Checklist_Deliverable_Max_Order_By;
  bringup_checklist_deliverable_min_fields: Bringup_Checklist_Deliverable_Min_Fields;
  bringup_checklist_deliverable_min_order_by: Bringup_Checklist_Deliverable_Min_Order_By;
  bringup_checklist_deliverable_mutation_response: Bringup_Checklist_Deliverable_Mutation_Response;
  bringup_checklist_deliverable_on_conflict: Bringup_Checklist_Deliverable_On_Conflict;
  bringup_checklist_deliverable_order_by: Bringup_Checklist_Deliverable_Order_By;
  bringup_checklist_deliverable_pk_columns_input: Bringup_Checklist_Deliverable_Pk_Columns_Input;
  bringup_checklist_deliverable_set_input: Bringup_Checklist_Deliverable_Set_Input;
  bringup_checklist_deliverable_stream_cursor_input: Bringup_Checklist_Deliverable_Stream_Cursor_Input;
  bringup_checklist_deliverable_stream_cursor_value_input: Bringup_Checklist_Deliverable_Stream_Cursor_Value_Input;
  bringup_checklist_deliverable_updates: Bringup_Checklist_Deliverable_Updates;
  bringup_checklists: Bringup_Checklists;
  bringup_checklists_aggregate: Bringup_Checklists_Aggregate;
  bringup_checklists_aggregate_fields: Bringup_Checklists_Aggregate_Fields;
  bringup_checklists_bool_exp: Bringup_Checklists_Bool_Exp;
  bringup_checklists_insert_input: Bringup_Checklists_Insert_Input;
  bringup_checklists_max_fields: Bringup_Checklists_Max_Fields;
  bringup_checklists_min_fields: Bringup_Checklists_Min_Fields;
  bringup_checklists_mutation_response: Bringup_Checklists_Mutation_Response;
  bringup_checklists_obj_rel_insert_input: Bringup_Checklists_Obj_Rel_Insert_Input;
  bringup_checklists_on_conflict: Bringup_Checklists_On_Conflict;
  bringup_checklists_order_by: Bringup_Checklists_Order_By;
  bringup_checklists_pk_columns_input: Bringup_Checklists_Pk_Columns_Input;
  bringup_checklists_set_input: Bringup_Checklists_Set_Input;
  bringup_checklists_stream_cursor_input: Bringup_Checklists_Stream_Cursor_Input;
  bringup_checklists_stream_cursor_value_input: Bringup_Checklists_Stream_Cursor_Value_Input;
  bringup_checklists_updates: Bringup_Checklists_Updates;
  bringup_endorsements: Bringup_Endorsements;
  bringup_endorsements_aggregate: Bringup_Endorsements_Aggregate;
  bringup_endorsements_aggregate_fields: Bringup_Endorsements_Aggregate_Fields;
  bringup_endorsements_aggregate_order_by: Bringup_Endorsements_Aggregate_Order_By;
  bringup_endorsements_arr_rel_insert_input: Bringup_Endorsements_Arr_Rel_Insert_Input;
  bringup_endorsements_bool_exp: Bringup_Endorsements_Bool_Exp;
  bringup_endorsements_insert_input: Bringup_Endorsements_Insert_Input;
  bringup_endorsements_max_fields: Bringup_Endorsements_Max_Fields;
  bringup_endorsements_max_order_by: Bringup_Endorsements_Max_Order_By;
  bringup_endorsements_min_fields: Bringup_Endorsements_Min_Fields;
  bringup_endorsements_min_order_by: Bringup_Endorsements_Min_Order_By;
  bringup_endorsements_mutation_response: Bringup_Endorsements_Mutation_Response;
  bringup_endorsements_on_conflict: Bringup_Endorsements_On_Conflict;
  bringup_endorsements_order_by: Bringup_Endorsements_Order_By;
  bringup_endorsements_pk_columns_input: Bringup_Endorsements_Pk_Columns_Input;
  bringup_endorsements_set_input: Bringup_Endorsements_Set_Input;
  bringup_endorsements_stream_cursor_input: Bringup_Endorsements_Stream_Cursor_Input;
  bringup_endorsements_stream_cursor_value_input: Bringup_Endorsements_Stream_Cursor_Value_Input;
  bringup_endorsements_updates: Bringup_Endorsements_Updates;
  bringup_items: Bringup_Items;
  bringup_items_aggregate: Bringup_Items_Aggregate;
  bringup_items_aggregate_fields: Bringup_Items_Aggregate_Fields;
  bringup_items_aggregate_order_by: Bringup_Items_Aggregate_Order_By;
  bringup_items_arr_rel_insert_input: Bringup_Items_Arr_Rel_Insert_Input;
  bringup_items_avg_fields: Bringup_Items_Avg_Fields;
  bringup_items_avg_order_by: Bringup_Items_Avg_Order_By;
  bringup_items_bool_exp: Bringup_Items_Bool_Exp;
  bringup_items_inc_input: Bringup_Items_Inc_Input;
  bringup_items_insert_input: Bringup_Items_Insert_Input;
  bringup_items_max_fields: Bringup_Items_Max_Fields;
  bringup_items_max_order_by: Bringup_Items_Max_Order_By;
  bringup_items_min_fields: Bringup_Items_Min_Fields;
  bringup_items_min_order_by: Bringup_Items_Min_Order_By;
  bringup_items_mutation_response: Bringup_Items_Mutation_Response;
  bringup_items_obj_rel_insert_input: Bringup_Items_Obj_Rel_Insert_Input;
  bringup_items_on_conflict: Bringup_Items_On_Conflict;
  bringup_items_order_by: Bringup_Items_Order_By;
  bringup_items_pk_columns_input: Bringup_Items_Pk_Columns_Input;
  bringup_items_set_input: Bringup_Items_Set_Input;
  bringup_items_stddev_fields: Bringup_Items_Stddev_Fields;
  bringup_items_stddev_order_by: Bringup_Items_Stddev_Order_By;
  bringup_items_stddev_pop_fields: Bringup_Items_Stddev_Pop_Fields;
  bringup_items_stddev_pop_order_by: Bringup_Items_Stddev_Pop_Order_By;
  bringup_items_stddev_samp_fields: Bringup_Items_Stddev_Samp_Fields;
  bringup_items_stddev_samp_order_by: Bringup_Items_Stddev_Samp_Order_By;
  bringup_items_stream_cursor_input: Bringup_Items_Stream_Cursor_Input;
  bringup_items_stream_cursor_value_input: Bringup_Items_Stream_Cursor_Value_Input;
  bringup_items_sum_fields: Bringup_Items_Sum_Fields;
  bringup_items_sum_order_by: Bringup_Items_Sum_Order_By;
  bringup_items_updates: Bringup_Items_Updates;
  bringup_items_var_pop_fields: Bringup_Items_Var_Pop_Fields;
  bringup_items_var_pop_order_by: Bringup_Items_Var_Pop_Order_By;
  bringup_items_var_samp_fields: Bringup_Items_Var_Samp_Fields;
  bringup_items_var_samp_order_by: Bringup_Items_Var_Samp_Order_By;
  bringup_items_variance_fields: Bringup_Items_Variance_Fields;
  bringup_items_variance_order_by: Bringup_Items_Variance_Order_By;
  bringup_milestones: Bringup_Milestones;
  bringup_milestones_aggregate: Bringup_Milestones_Aggregate;
  bringup_milestones_aggregate_fields: Bringup_Milestones_Aggregate_Fields;
  bringup_milestones_bool_exp: Bringup_Milestones_Bool_Exp;
  bringup_milestones_insert_input: Bringup_Milestones_Insert_Input;
  bringup_milestones_max_fields: Bringup_Milestones_Max_Fields;
  bringup_milestones_min_fields: Bringup_Milestones_Min_Fields;
  bringup_milestones_mutation_response: Bringup_Milestones_Mutation_Response;
  bringup_milestones_obj_rel_insert_input: Bringup_Milestones_Obj_Rel_Insert_Input;
  bringup_milestones_on_conflict: Bringup_Milestones_On_Conflict;
  bringup_milestones_order_by: Bringup_Milestones_Order_By;
  bringup_milestones_pk_columns_input: Bringup_Milestones_Pk_Columns_Input;
  bringup_milestones_set_input: Bringup_Milestones_Set_Input;
  bringup_milestones_stream_cursor_input: Bringup_Milestones_Stream_Cursor_Input;
  bringup_milestones_stream_cursor_value_input: Bringup_Milestones_Stream_Cursor_Value_Input;
  bringup_milestones_updates: Bringup_Milestones_Updates;
  bringup_project_item_comments: Bringup_Project_Item_Comments;
  bringup_project_item_comments_aggregate: Bringup_Project_Item_Comments_Aggregate;
  bringup_project_item_comments_aggregate_fields: Bringup_Project_Item_Comments_Aggregate_Fields;
  bringup_project_item_comments_aggregate_order_by: Bringup_Project_Item_Comments_Aggregate_Order_By;
  bringup_project_item_comments_arr_rel_insert_input: Bringup_Project_Item_Comments_Arr_Rel_Insert_Input;
  bringup_project_item_comments_bool_exp: Bringup_Project_Item_Comments_Bool_Exp;
  bringup_project_item_comments_insert_input: Bringup_Project_Item_Comments_Insert_Input;
  bringup_project_item_comments_max_fields: Bringup_Project_Item_Comments_Max_Fields;
  bringup_project_item_comments_max_order_by: Bringup_Project_Item_Comments_Max_Order_By;
  bringup_project_item_comments_min_fields: Bringup_Project_Item_Comments_Min_Fields;
  bringup_project_item_comments_min_order_by: Bringup_Project_Item_Comments_Min_Order_By;
  bringup_project_item_comments_mutation_response: Bringup_Project_Item_Comments_Mutation_Response;
  bringup_project_item_comments_on_conflict: Bringup_Project_Item_Comments_On_Conflict;
  bringup_project_item_comments_order_by: Bringup_Project_Item_Comments_Order_By;
  bringup_project_item_comments_pk_columns_input: Bringup_Project_Item_Comments_Pk_Columns_Input;
  bringup_project_item_comments_set_input: Bringup_Project_Item_Comments_Set_Input;
  bringup_project_item_comments_stream_cursor_input: Bringup_Project_Item_Comments_Stream_Cursor_Input;
  bringup_project_item_comments_stream_cursor_value_input: Bringup_Project_Item_Comments_Stream_Cursor_Value_Input;
  bringup_project_item_comments_updates: Bringup_Project_Item_Comments_Updates;
  categories: Categories;
  categories_aggregate: Categories_Aggregate;
  categories_aggregate_fields: Categories_Aggregate_Fields;
  categories_avg_fields: Categories_Avg_Fields;
  categories_bool_exp: Categories_Bool_Exp;
  categories_inc_input: Categories_Inc_Input;
  categories_insert_input: Categories_Insert_Input;
  categories_max_fields: Categories_Max_Fields;
  categories_min_fields: Categories_Min_Fields;
  categories_mutation_response: Categories_Mutation_Response;
  categories_obj_rel_insert_input: Categories_Obj_Rel_Insert_Input;
  categories_on_conflict: Categories_On_Conflict;
  categories_order_by: Categories_Order_By;
  categories_pk_columns_input: Categories_Pk_Columns_Input;
  categories_set_input: Categories_Set_Input;
  categories_stddev_fields: Categories_Stddev_Fields;
  categories_stddev_pop_fields: Categories_Stddev_Pop_Fields;
  categories_stddev_samp_fields: Categories_Stddev_Samp_Fields;
  categories_stream_cursor_input: Categories_Stream_Cursor_Input;
  categories_stream_cursor_value_input: Categories_Stream_Cursor_Value_Input;
  categories_sum_fields: Categories_Sum_Fields;
  categories_updates: Categories_Updates;
  categories_var_pop_fields: Categories_Var_Pop_Fields;
  categories_var_samp_fields: Categories_Var_Samp_Fields;
  categories_variance_fields: Categories_Variance_Fields;
  context_display_field: Context_Display_Field;
  context_display_field_aggregate: Context_Display_Field_Aggregate;
  context_display_field_aggregate_fields: Context_Display_Field_Aggregate_Fields;
  context_display_field_aggregate_order_by: Context_Display_Field_Aggregate_Order_By;
  context_display_field_arr_rel_insert_input: Context_Display_Field_Arr_Rel_Insert_Input;
  context_display_field_avg_fields: Context_Display_Field_Avg_Fields;
  context_display_field_avg_order_by: Context_Display_Field_Avg_Order_By;
  context_display_field_bool_exp: Context_Display_Field_Bool_Exp;
  context_display_field_inc_input: Context_Display_Field_Inc_Input;
  context_display_field_insert_input: Context_Display_Field_Insert_Input;
  context_display_field_max_fields: Context_Display_Field_Max_Fields;
  context_display_field_max_order_by: Context_Display_Field_Max_Order_By;
  context_display_field_min_fields: Context_Display_Field_Min_Fields;
  context_display_field_min_order_by: Context_Display_Field_Min_Order_By;
  context_display_field_mutation_response: Context_Display_Field_Mutation_Response;
  context_display_field_on_conflict: Context_Display_Field_On_Conflict;
  context_display_field_order_by: Context_Display_Field_Order_By;
  context_display_field_pk_columns_input: Context_Display_Field_Pk_Columns_Input;
  context_display_field_set_input: Context_Display_Field_Set_Input;
  context_display_field_stddev_fields: Context_Display_Field_Stddev_Fields;
  context_display_field_stddev_order_by: Context_Display_Field_Stddev_Order_By;
  context_display_field_stddev_pop_fields: Context_Display_Field_Stddev_Pop_Fields;
  context_display_field_stddev_pop_order_by: Context_Display_Field_Stddev_Pop_Order_By;
  context_display_field_stddev_samp_fields: Context_Display_Field_Stddev_Samp_Fields;
  context_display_field_stddev_samp_order_by: Context_Display_Field_Stddev_Samp_Order_By;
  context_display_field_stream_cursor_input: Context_Display_Field_Stream_Cursor_Input;
  context_display_field_stream_cursor_value_input: Context_Display_Field_Stream_Cursor_Value_Input;
  context_display_field_sum_fields: Context_Display_Field_Sum_Fields;
  context_display_field_sum_order_by: Context_Display_Field_Sum_Order_By;
  context_display_field_updates: Context_Display_Field_Updates;
  context_display_field_var_pop_fields: Context_Display_Field_Var_Pop_Fields;
  context_display_field_var_pop_order_by: Context_Display_Field_Var_Pop_Order_By;
  context_display_field_var_samp_fields: Context_Display_Field_Var_Samp_Fields;
  context_display_field_var_samp_order_by: Context_Display_Field_Var_Samp_Order_By;
  context_display_field_variance_fields: Context_Display_Field_Variance_Fields;
  context_display_field_variance_order_by: Context_Display_Field_Variance_Order_By;
  context_displays: Context_Displays;
  context_displays_aggregate_order_by: Context_Displays_Aggregate_Order_By;
  context_displays_arr_rel_insert_input: Context_Displays_Arr_Rel_Insert_Input;
  context_displays_avg_order_by: Context_Displays_Avg_Order_By;
  context_displays_bool_exp: Context_Displays_Bool_Exp;
  context_displays_inc_input: Context_Displays_Inc_Input;
  context_displays_insert_input: Context_Displays_Insert_Input;
  context_displays_max_order_by: Context_Displays_Max_Order_By;
  context_displays_min_order_by: Context_Displays_Min_Order_By;
  context_displays_mutation_response: Context_Displays_Mutation_Response;
  context_displays_obj_rel_insert_input: Context_Displays_Obj_Rel_Insert_Input;
  context_displays_on_conflict: Context_Displays_On_Conflict;
  context_displays_order_by: Context_Displays_Order_By;
  context_displays_pk_columns_input: Context_Displays_Pk_Columns_Input;
  context_displays_set_input: Context_Displays_Set_Input;
  context_displays_stddev_order_by: Context_Displays_Stddev_Order_By;
  context_displays_stddev_pop_order_by: Context_Displays_Stddev_Pop_Order_By;
  context_displays_stddev_samp_order_by: Context_Displays_Stddev_Samp_Order_By;
  context_displays_stream_cursor_input: Context_Displays_Stream_Cursor_Input;
  context_displays_stream_cursor_value_input: Context_Displays_Stream_Cursor_Value_Input;
  context_displays_sum_order_by: Context_Displays_Sum_Order_By;
  context_displays_updates: Context_Displays_Updates;
  context_displays_var_pop_order_by: Context_Displays_Var_Pop_Order_By;
  context_displays_var_samp_order_by: Context_Displays_Var_Samp_Order_By;
  context_displays_variance_order_by: Context_Displays_Variance_Order_By;
  contexts: Contexts;
  contexts_aggregate: Contexts_Aggregate;
  contexts_aggregate_fields: Contexts_Aggregate_Fields;
  contexts_aggregate_order_by: Contexts_Aggregate_Order_By;
  contexts_arr_rel_insert_input: Contexts_Arr_Rel_Insert_Input;
  contexts_bool_exp: Contexts_Bool_Exp;
  contexts_insert_input: Contexts_Insert_Input;
  contexts_max_fields: Contexts_Max_Fields;
  contexts_max_order_by: Contexts_Max_Order_By;
  contexts_min_fields: Contexts_Min_Fields;
  contexts_min_order_by: Contexts_Min_Order_By;
  contexts_mutation_response: Contexts_Mutation_Response;
  contexts_obj_rel_insert_input: Contexts_Obj_Rel_Insert_Input;
  contexts_on_conflict: Contexts_On_Conflict;
  contexts_order_by: Contexts_Order_By;
  contexts_pk_columns_input: Contexts_Pk_Columns_Input;
  contexts_set_input: Contexts_Set_Input;
  contexts_stream_cursor_input: Contexts_Stream_Cursor_Input;
  contexts_stream_cursor_value_input: Contexts_Stream_Cursor_Value_Input;
  contexts_updates: Contexts_Updates;
  customers: Customers;
  customers_aggregate: Customers_Aggregate;
  customers_aggregate_fields: Customers_Aggregate_Fields;
  customers_bool_exp: Customers_Bool_Exp;
  customers_insert_input: Customers_Insert_Input;
  customers_max_fields: Customers_Max_Fields;
  customers_min_fields: Customers_Min_Fields;
  customers_mutation_response: Customers_Mutation_Response;
  customers_obj_rel_insert_input: Customers_Obj_Rel_Insert_Input;
  customers_on_conflict: Customers_On_Conflict;
  customers_order_by: Customers_Order_By;
  customers_pk_columns_input: Customers_Pk_Columns_Input;
  customers_set_input: Customers_Set_Input;
  customers_stream_cursor_input: Customers_Stream_Cursor_Input;
  customers_stream_cursor_value_input: Customers_Stream_Cursor_Value_Input;
  customers_updates: Customers_Updates;
  date: Scalars['date'];
  date_comparison_exp: Date_Comparison_Exp;
  deliverables: Deliverables;
  deliverables_aggregate: Deliverables_Aggregate;
  deliverables_aggregate_fields: Deliverables_Aggregate_Fields;
  deliverables_avg_fields: Deliverables_Avg_Fields;
  deliverables_bool_exp: Deliverables_Bool_Exp;
  deliverables_inc_input: Deliverables_Inc_Input;
  deliverables_insert_input: Deliverables_Insert_Input;
  deliverables_max_fields: Deliverables_Max_Fields;
  deliverables_min_fields: Deliverables_Min_Fields;
  deliverables_mutation_response: Deliverables_Mutation_Response;
  deliverables_obj_rel_insert_input: Deliverables_Obj_Rel_Insert_Input;
  deliverables_on_conflict: Deliverables_On_Conflict;
  deliverables_order_by: Deliverables_Order_By;
  deliverables_pk_columns_input: Deliverables_Pk_Columns_Input;
  deliverables_set_input: Deliverables_Set_Input;
  deliverables_stddev_fields: Deliverables_Stddev_Fields;
  deliverables_stddev_pop_fields: Deliverables_Stddev_Pop_Fields;
  deliverables_stddev_samp_fields: Deliverables_Stddev_Samp_Fields;
  deliverables_stream_cursor_input: Deliverables_Stream_Cursor_Input;
  deliverables_stream_cursor_value_input: Deliverables_Stream_Cursor_Value_Input;
  deliverables_sum_fields: Deliverables_Sum_Fields;
  deliverables_updates: Deliverables_Updates;
  deliverables_var_pop_fields: Deliverables_Var_Pop_Fields;
  deliverables_var_samp_fields: Deliverables_Var_Samp_Fields;
  deliverables_variance_fields: Deliverables_Variance_Fields;
  deliveries_architectures: Deliveries_Architectures;
  deliveries_architectures_bool_exp: Deliveries_Architectures_Bool_Exp;
  deliveries_architectures_insert_input: Deliveries_Architectures_Insert_Input;
  deliveries_architectures_mutation_response: Deliveries_Architectures_Mutation_Response;
  deliveries_architectures_obj_rel_insert_input: Deliveries_Architectures_Obj_Rel_Insert_Input;
  deliveries_architectures_on_conflict: Deliveries_Architectures_On_Conflict;
  deliveries_architectures_order_by: Deliveries_Architectures_Order_By;
  deliveries_architectures_pk_columns_input: Deliveries_Architectures_Pk_Columns_Input;
  deliveries_architectures_set_input: Deliveries_Architectures_Set_Input;
  deliveries_architectures_stream_cursor_input: Deliveries_Architectures_Stream_Cursor_Input;
  deliveries_architectures_stream_cursor_value_input: Deliveries_Architectures_Stream_Cursor_Value_Input;
  deliveries_architectures_updates: Deliveries_Architectures_Updates;
  deliveries_artifact_microsoft_whql_submission: Deliveries_Artifact_Microsoft_Whql_Submission;
  deliveries_artifact_microsoft_whql_submission_aggregate: Deliveries_Artifact_Microsoft_Whql_Submission_Aggregate;
  deliveries_artifact_microsoft_whql_submission_aggregate_fields: Deliveries_Artifact_Microsoft_Whql_Submission_Aggregate_Fields;
  deliveries_artifact_microsoft_whql_submission_aggregate_order_by: Deliveries_Artifact_Microsoft_Whql_Submission_Aggregate_Order_By;
  deliveries_artifact_microsoft_whql_submission_arr_rel_insert_input: Deliveries_Artifact_Microsoft_Whql_Submission_Arr_Rel_Insert_Input;
  deliveries_artifact_microsoft_whql_submission_bool_exp: Deliveries_Artifact_Microsoft_Whql_Submission_Bool_Exp;
  deliveries_artifact_microsoft_whql_submission_insert_input: Deliveries_Artifact_Microsoft_Whql_Submission_Insert_Input;
  deliveries_artifact_microsoft_whql_submission_max_fields: Deliveries_Artifact_Microsoft_Whql_Submission_Max_Fields;
  deliveries_artifact_microsoft_whql_submission_max_order_by: Deliveries_Artifact_Microsoft_Whql_Submission_Max_Order_By;
  deliveries_artifact_microsoft_whql_submission_min_fields: Deliveries_Artifact_Microsoft_Whql_Submission_Min_Fields;
  deliveries_artifact_microsoft_whql_submission_min_order_by: Deliveries_Artifact_Microsoft_Whql_Submission_Min_Order_By;
  deliveries_artifact_microsoft_whql_submission_mutation_response: Deliveries_Artifact_Microsoft_Whql_Submission_Mutation_Response;
  deliveries_artifact_microsoft_whql_submission_on_conflict: Deliveries_Artifact_Microsoft_Whql_Submission_On_Conflict;
  deliveries_artifact_microsoft_whql_submission_order_by: Deliveries_Artifact_Microsoft_Whql_Submission_Order_By;
  deliveries_artifact_microsoft_whql_submission_pk_columns_input: Deliveries_Artifact_Microsoft_Whql_Submission_Pk_Columns_Input;
  deliveries_artifact_microsoft_whql_submission_set_input: Deliveries_Artifact_Microsoft_Whql_Submission_Set_Input;
  deliveries_artifact_microsoft_whql_submission_stream_cursor_input: Deliveries_Artifact_Microsoft_Whql_Submission_Stream_Cursor_Input;
  deliveries_artifact_microsoft_whql_submission_stream_cursor_value_input: Deliveries_Artifact_Microsoft_Whql_Submission_Stream_Cursor_Value_Input;
  deliveries_artifact_microsoft_whql_submission_updates: Deliveries_Artifact_Microsoft_Whql_Submission_Updates;
  deliveries_artifact_operating_system: Deliveries_Artifact_Operating_System;
  deliveries_artifact_operating_system_aggregate_order_by: Deliveries_Artifact_Operating_System_Aggregate_Order_By;
  deliveries_artifact_operating_system_arr_rel_insert_input: Deliveries_Artifact_Operating_System_Arr_Rel_Insert_Input;
  deliveries_artifact_operating_system_bool_exp: Deliveries_Artifact_Operating_System_Bool_Exp;
  deliveries_artifact_operating_system_insert_input: Deliveries_Artifact_Operating_System_Insert_Input;
  deliveries_artifact_operating_system_max_order_by: Deliveries_Artifact_Operating_System_Max_Order_By;
  deliveries_artifact_operating_system_min_order_by: Deliveries_Artifact_Operating_System_Min_Order_By;
  deliveries_artifact_operating_system_mutation_response: Deliveries_Artifact_Operating_System_Mutation_Response;
  deliveries_artifact_operating_system_on_conflict: Deliveries_Artifact_Operating_System_On_Conflict;
  deliveries_artifact_operating_system_order_by: Deliveries_Artifact_Operating_System_Order_By;
  deliveries_artifact_operating_system_pk_columns_input: Deliveries_Artifact_Operating_System_Pk_Columns_Input;
  deliveries_artifact_operating_system_set_input: Deliveries_Artifact_Operating_System_Set_Input;
  deliveries_artifact_operating_system_stream_cursor_input: Deliveries_Artifact_Operating_System_Stream_Cursor_Input;
  deliveries_artifact_operating_system_stream_cursor_value_input: Deliveries_Artifact_Operating_System_Stream_Cursor_Value_Input;
  deliveries_artifact_operating_system_updates: Deliveries_Artifact_Operating_System_Updates;
  deliveries_artifacts: Deliveries_Artifacts;
  deliveries_artifacts_aggregate: Deliveries_Artifacts_Aggregate;
  deliveries_artifacts_aggregate_fields: Deliveries_Artifacts_Aggregate_Fields;
  deliveries_artifacts_aggregate_order_by: Deliveries_Artifacts_Aggregate_Order_By;
  deliveries_artifacts_arr_rel_insert_input: Deliveries_Artifacts_Arr_Rel_Insert_Input;
  deliveries_artifacts_bool_exp: Deliveries_Artifacts_Bool_Exp;
  deliveries_artifacts_insert_input: Deliveries_Artifacts_Insert_Input;
  deliveries_artifacts_max_fields: Deliveries_Artifacts_Max_Fields;
  deliveries_artifacts_max_order_by: Deliveries_Artifacts_Max_Order_By;
  deliveries_artifacts_min_fields: Deliveries_Artifacts_Min_Fields;
  deliveries_artifacts_min_order_by: Deliveries_Artifacts_Min_Order_By;
  deliveries_artifacts_mutation_response: Deliveries_Artifacts_Mutation_Response;
  deliveries_artifacts_obj_rel_insert_input: Deliveries_Artifacts_Obj_Rel_Insert_Input;
  deliveries_artifacts_on_conflict: Deliveries_Artifacts_On_Conflict;
  deliveries_artifacts_order_by: Deliveries_Artifacts_Order_By;
  deliveries_artifacts_pk_columns_input: Deliveries_Artifacts_Pk_Columns_Input;
  deliveries_artifacts_set_input: Deliveries_Artifacts_Set_Input;
  deliveries_artifacts_stream_cursor_input: Deliveries_Artifacts_Stream_Cursor_Input;
  deliveries_artifacts_stream_cursor_value_input: Deliveries_Artifacts_Stream_Cursor_Value_Input;
  deliveries_artifacts_updates: Deliveries_Artifacts_Updates;
  deliveries_config_id_types_enum_comparison_exp: Deliveries_Config_Id_Types_Enum_Comparison_Exp;
  deliveries_device_families: Deliveries_Device_Families;
  deliveries_device_families_bool_exp: Deliveries_Device_Families_Bool_Exp;
  deliveries_device_families_insert_input: Deliveries_Device_Families_Insert_Input;
  deliveries_device_families_mutation_response: Deliveries_Device_Families_Mutation_Response;
  deliveries_device_families_obj_rel_insert_input: Deliveries_Device_Families_Obj_Rel_Insert_Input;
  deliveries_device_families_on_conflict: Deliveries_Device_Families_On_Conflict;
  deliveries_device_families_order_by: Deliveries_Device_Families_Order_By;
  deliveries_device_families_pk_columns_input: Deliveries_Device_Families_Pk_Columns_Input;
  deliveries_device_families_set_input: Deliveries_Device_Families_Set_Input;
  deliveries_device_families_stream_cursor_input: Deliveries_Device_Families_Stream_Cursor_Input;
  deliveries_device_families_stream_cursor_value_input: Deliveries_Device_Families_Stream_Cursor_Value_Input;
  deliveries_device_families_updates: Deliveries_Device_Families_Updates;
  deliveries_driver_package: Deliveries_Driver_Package;
  deliveries_driver_package_aggregate: Deliveries_Driver_Package_Aggregate;
  deliveries_driver_package_aggregate_fields: Deliveries_Driver_Package_Aggregate_Fields;
  deliveries_driver_package_aggregate_order_by: Deliveries_Driver_Package_Aggregate_Order_By;
  deliveries_driver_package_arr_rel_insert_input: Deliveries_Driver_Package_Arr_Rel_Insert_Input;
  deliveries_driver_package_bool_exp: Deliveries_Driver_Package_Bool_Exp;
  deliveries_driver_package_insert_input: Deliveries_Driver_Package_Insert_Input;
  deliveries_driver_package_max_fields: Deliveries_Driver_Package_Max_Fields;
  deliveries_driver_package_max_order_by: Deliveries_Driver_Package_Max_Order_By;
  deliveries_driver_package_min_fields: Deliveries_Driver_Package_Min_Fields;
  deliveries_driver_package_min_order_by: Deliveries_Driver_Package_Min_Order_By;
  deliveries_driver_package_mutation_response: Deliveries_Driver_Package_Mutation_Response;
  deliveries_driver_package_on_conflict: Deliveries_Driver_Package_On_Conflict;
  deliveries_driver_package_order_by: Deliveries_Driver_Package_Order_By;
  deliveries_driver_package_pk_columns_input: Deliveries_Driver_Package_Pk_Columns_Input;
  deliveries_driver_package_set_input: Deliveries_Driver_Package_Set_Input;
  deliveries_driver_package_stream_cursor_input: Deliveries_Driver_Package_Stream_Cursor_Input;
  deliveries_driver_package_stream_cursor_value_input: Deliveries_Driver_Package_Stream_Cursor_Value_Input;
  deliveries_driver_package_updates: Deliveries_Driver_Package_Updates;
  deliveries_driver_project: Deliveries_Driver_Project;
  deliveries_driver_project_bool_exp: Deliveries_Driver_Project_Bool_Exp;
  deliveries_driver_project_insert_input: Deliveries_Driver_Project_Insert_Input;
  deliveries_driver_project_mutation_response: Deliveries_Driver_Project_Mutation_Response;
  deliveries_driver_project_obj_rel_insert_input: Deliveries_Driver_Project_Obj_Rel_Insert_Input;
  deliveries_driver_project_on_conflict: Deliveries_Driver_Project_On_Conflict;
  deliveries_driver_project_order_by: Deliveries_Driver_Project_Order_By;
  deliveries_driver_project_pk_columns_input: Deliveries_Driver_Project_Pk_Columns_Input;
  deliveries_driver_project_set_input: Deliveries_Driver_Project_Set_Input;
  deliveries_driver_project_updates: Deliveries_Driver_Project_Updates;
  deliveries_driver_version_engine: Deliveries_Driver_Version_Engine;
  deliveries_driver_version_engine_aggregate: Deliveries_Driver_Version_Engine_Aggregate;
  deliveries_driver_version_engine_aggregate_fields: Deliveries_Driver_Version_Engine_Aggregate_Fields;
  deliveries_driver_version_engine_aggregate_order_by: Deliveries_Driver_Version_Engine_Aggregate_Order_By;
  deliveries_driver_version_engine_arr_rel_insert_input: Deliveries_Driver_Version_Engine_Arr_Rel_Insert_Input;
  deliveries_driver_version_engine_bool_exp: Deliveries_Driver_Version_Engine_Bool_Exp;
  deliveries_driver_version_engine_insert_input: Deliveries_Driver_Version_Engine_Insert_Input;
  deliveries_driver_version_engine_max_fields: Deliveries_Driver_Version_Engine_Max_Fields;
  deliveries_driver_version_engine_max_order_by: Deliveries_Driver_Version_Engine_Max_Order_By;
  deliveries_driver_version_engine_min_fields: Deliveries_Driver_Version_Engine_Min_Fields;
  deliveries_driver_version_engine_min_order_by: Deliveries_Driver_Version_Engine_Min_Order_By;
  deliveries_driver_version_engine_mutation_response: Deliveries_Driver_Version_Engine_Mutation_Response;
  deliveries_driver_version_engine_obj_rel_insert_input: Deliveries_Driver_Version_Engine_Obj_Rel_Insert_Input;
  deliveries_driver_version_engine_on_conflict: Deliveries_Driver_Version_Engine_On_Conflict;
  deliveries_driver_version_engine_order_by: Deliveries_Driver_Version_Engine_Order_By;
  deliveries_driver_version_engine_pk_columns_input: Deliveries_Driver_Version_Engine_Pk_Columns_Input;
  deliveries_driver_version_engine_set_input: Deliveries_Driver_Version_Engine_Set_Input;
  deliveries_driver_version_engine_updates: Deliveries_Driver_Version_Engine_Updates;
  deliveries_driver_version_package: Deliveries_Driver_Version_Package;
  deliveries_driver_version_package_aggregate: Deliveries_Driver_Version_Package_Aggregate;
  deliveries_driver_version_package_aggregate_fields: Deliveries_Driver_Version_Package_Aggregate_Fields;
  deliveries_driver_version_package_aggregate_order_by: Deliveries_Driver_Version_Package_Aggregate_Order_By;
  deliveries_driver_version_package_arr_rel_insert_input: Deliveries_Driver_Version_Package_Arr_Rel_Insert_Input;
  deliveries_driver_version_package_bool_exp: Deliveries_Driver_Version_Package_Bool_Exp;
  deliveries_driver_version_package_insert_input: Deliveries_Driver_Version_Package_Insert_Input;
  deliveries_driver_version_package_max_fields: Deliveries_Driver_Version_Package_Max_Fields;
  deliveries_driver_version_package_max_order_by: Deliveries_Driver_Version_Package_Max_Order_By;
  deliveries_driver_version_package_min_fields: Deliveries_Driver_Version_Package_Min_Fields;
  deliveries_driver_version_package_min_order_by: Deliveries_Driver_Version_Package_Min_Order_By;
  deliveries_driver_version_package_mutation_response: Deliveries_Driver_Version_Package_Mutation_Response;
  deliveries_driver_version_package_on_conflict: Deliveries_Driver_Version_Package_On_Conflict;
  deliveries_driver_version_package_order_by: Deliveries_Driver_Version_Package_Order_By;
  deliveries_driver_version_package_pk_columns_input: Deliveries_Driver_Version_Package_Pk_Columns_Input;
  deliveries_driver_version_package_set_input: Deliveries_Driver_Version_Package_Set_Input;
  deliveries_driver_version_package_stream_cursor_input: Deliveries_Driver_Version_Package_Stream_Cursor_Input;
  deliveries_driver_version_package_stream_cursor_value_input: Deliveries_Driver_Version_Package_Stream_Cursor_Value_Input;
  deliveries_driver_version_package_updates: Deliveries_Driver_Version_Package_Updates;
  deliveries_driver_versions: Deliveries_Driver_Versions;
  deliveries_driver_versions_aggregate: Deliveries_Driver_Versions_Aggregate;
  deliveries_driver_versions_aggregate_fields: Deliveries_Driver_Versions_Aggregate_Fields;
  deliveries_driver_versions_aggregate_order_by: Deliveries_Driver_Versions_Aggregate_Order_By;
  deliveries_driver_versions_arr_rel_insert_input: Deliveries_Driver_Versions_Arr_Rel_Insert_Input;
  deliveries_driver_versions_bool_exp: Deliveries_Driver_Versions_Bool_Exp;
  deliveries_driver_versions_insert_input: Deliveries_Driver_Versions_Insert_Input;
  deliveries_driver_versions_max_fields: Deliveries_Driver_Versions_Max_Fields;
  deliveries_driver_versions_max_order_by: Deliveries_Driver_Versions_Max_Order_By;
  deliveries_driver_versions_min_fields: Deliveries_Driver_Versions_Min_Fields;
  deliveries_driver_versions_min_order_by: Deliveries_Driver_Versions_Min_Order_By;
  deliveries_driver_versions_mutation_response: Deliveries_Driver_Versions_Mutation_Response;
  deliveries_driver_versions_obj_rel_insert_input: Deliveries_Driver_Versions_Obj_Rel_Insert_Input;
  deliveries_driver_versions_on_conflict: Deliveries_Driver_Versions_On_Conflict;
  deliveries_driver_versions_order_by: Deliveries_Driver_Versions_Order_By;
  deliveries_driver_versions_pk_columns_input: Deliveries_Driver_Versions_Pk_Columns_Input;
  deliveries_driver_versions_set_input: Deliveries_Driver_Versions_Set_Input;
  deliveries_driver_versions_stream_cursor_input: Deliveries_Driver_Versions_Stream_Cursor_Input;
  deliveries_driver_versions_stream_cursor_value_input: Deliveries_Driver_Versions_Stream_Cursor_Value_Input;
  deliveries_driver_versions_updates: Deliveries_Driver_Versions_Updates;
  deliveries_drivers: Deliveries_Drivers;
  deliveries_drivers_aggregate: Deliveries_Drivers_Aggregate;
  deliveries_drivers_aggregate_fields: Deliveries_Drivers_Aggregate_Fields;
  deliveries_drivers_bool_exp: Deliveries_Drivers_Bool_Exp;
  deliveries_drivers_insert_input: Deliveries_Drivers_Insert_Input;
  deliveries_drivers_max_fields: Deliveries_Drivers_Max_Fields;
  deliveries_drivers_min_fields: Deliveries_Drivers_Min_Fields;
  deliveries_drivers_mutation_response: Deliveries_Drivers_Mutation_Response;
  deliveries_drivers_obj_rel_insert_input: Deliveries_Drivers_Obj_Rel_Insert_Input;
  deliveries_drivers_on_conflict: Deliveries_Drivers_On_Conflict;
  deliveries_drivers_order_by: Deliveries_Drivers_Order_By;
  deliveries_drivers_pk_columns_input: Deliveries_Drivers_Pk_Columns_Input;
  deliveries_drivers_set_input: Deliveries_Drivers_Set_Input;
  deliveries_drivers_stream_cursor_input: Deliveries_Drivers_Stream_Cursor_Input;
  deliveries_drivers_stream_cursor_value_input: Deliveries_Drivers_Stream_Cursor_Value_Input;
  deliveries_drivers_updates: Deliveries_Drivers_Updates;
  deliveries_microsoft_hlk_artifacts: Deliveries_Microsoft_Hlk_Artifacts;
  deliveries_microsoft_hlk_artifacts_aggregate: Deliveries_Microsoft_Hlk_Artifacts_Aggregate;
  deliveries_microsoft_hlk_artifacts_aggregate_fields: Deliveries_Microsoft_Hlk_Artifacts_Aggregate_Fields;
  deliveries_microsoft_hlk_artifacts_aggregate_order_by: Deliveries_Microsoft_Hlk_Artifacts_Aggregate_Order_By;
  deliveries_microsoft_hlk_artifacts_arr_rel_insert_input: Deliveries_Microsoft_Hlk_Artifacts_Arr_Rel_Insert_Input;
  deliveries_microsoft_hlk_artifacts_bool_exp: Deliveries_Microsoft_Hlk_Artifacts_Bool_Exp;
  deliveries_microsoft_hlk_artifacts_insert_input: Deliveries_Microsoft_Hlk_Artifacts_Insert_Input;
  deliveries_microsoft_hlk_artifacts_max_fields: Deliveries_Microsoft_Hlk_Artifacts_Max_Fields;
  deliveries_microsoft_hlk_artifacts_max_order_by: Deliveries_Microsoft_Hlk_Artifacts_Max_Order_By;
  deliveries_microsoft_hlk_artifacts_min_fields: Deliveries_Microsoft_Hlk_Artifacts_Min_Fields;
  deliveries_microsoft_hlk_artifacts_min_order_by: Deliveries_Microsoft_Hlk_Artifacts_Min_Order_By;
  deliveries_microsoft_hlk_artifacts_mutation_response: Deliveries_Microsoft_Hlk_Artifacts_Mutation_Response;
  deliveries_microsoft_hlk_artifacts_on_conflict: Deliveries_Microsoft_Hlk_Artifacts_On_Conflict;
  deliveries_microsoft_hlk_artifacts_order_by: Deliveries_Microsoft_Hlk_Artifacts_Order_By;
  deliveries_microsoft_hlk_artifacts_pk_columns_input: Deliveries_Microsoft_Hlk_Artifacts_Pk_Columns_Input;
  deliveries_microsoft_hlk_artifacts_set_input: Deliveries_Microsoft_Hlk_Artifacts_Set_Input;
  deliveries_microsoft_hlk_artifacts_stream_cursor_input: Deliveries_Microsoft_Hlk_Artifacts_Stream_Cursor_Input;
  deliveries_microsoft_hlk_artifacts_stream_cursor_value_input: Deliveries_Microsoft_Hlk_Artifacts_Stream_Cursor_Value_Input;
  deliveries_microsoft_hlk_artifacts_updates: Deliveries_Microsoft_Hlk_Artifacts_Updates;
  deliveries_microsoft_shipping_label_reviews: Deliveries_Microsoft_Shipping_Label_Reviews;
  deliveries_microsoft_shipping_label_reviews_bool_exp: Deliveries_Microsoft_Shipping_Label_Reviews_Bool_Exp;
  deliveries_microsoft_shipping_label_reviews_insert_input: Deliveries_Microsoft_Shipping_Label_Reviews_Insert_Input;
  deliveries_microsoft_shipping_label_reviews_mutation_response: Deliveries_Microsoft_Shipping_Label_Reviews_Mutation_Response;
  deliveries_microsoft_shipping_label_reviews_obj_rel_insert_input: Deliveries_Microsoft_Shipping_Label_Reviews_Obj_Rel_Insert_Input;
  deliveries_microsoft_shipping_label_reviews_on_conflict: Deliveries_Microsoft_Shipping_Label_Reviews_On_Conflict;
  deliveries_microsoft_shipping_label_reviews_order_by: Deliveries_Microsoft_Shipping_Label_Reviews_Order_By;
  deliveries_microsoft_shipping_label_reviews_pk_columns_input: Deliveries_Microsoft_Shipping_Label_Reviews_Pk_Columns_Input;
  deliveries_microsoft_shipping_label_reviews_set_input: Deliveries_Microsoft_Shipping_Label_Reviews_Set_Input;
  deliveries_microsoft_shipping_label_reviews_stream_cursor_input: Deliveries_Microsoft_Shipping_Label_Reviews_Stream_Cursor_Input;
  deliveries_microsoft_shipping_label_reviews_stream_cursor_value_input: Deliveries_Microsoft_Shipping_Label_Reviews_Stream_Cursor_Value_Input;
  deliveries_microsoft_shipping_label_reviews_updates: Deliveries_Microsoft_Shipping_Label_Reviews_Updates;
  deliveries_microsoft_shipping_labels: Deliveries_Microsoft_Shipping_Labels;
  deliveries_microsoft_shipping_labels_aggregate: Deliveries_Microsoft_Shipping_Labels_Aggregate;
  deliveries_microsoft_shipping_labels_aggregate_fields: Deliveries_Microsoft_Shipping_Labels_Aggregate_Fields;
  deliveries_microsoft_shipping_labels_aggregate_order_by: Deliveries_Microsoft_Shipping_Labels_Aggregate_Order_By;
  deliveries_microsoft_shipping_labels_append_input: Deliveries_Microsoft_Shipping_Labels_Append_Input;
  deliveries_microsoft_shipping_labels_arr_rel_insert_input: Deliveries_Microsoft_Shipping_Labels_Arr_Rel_Insert_Input;
  deliveries_microsoft_shipping_labels_bool_exp: Deliveries_Microsoft_Shipping_Labels_Bool_Exp;
  deliveries_microsoft_shipping_labels_delete_at_path_input: Deliveries_Microsoft_Shipping_Labels_Delete_At_Path_Input;
  deliveries_microsoft_shipping_labels_delete_elem_input: Deliveries_Microsoft_Shipping_Labels_Delete_Elem_Input;
  deliveries_microsoft_shipping_labels_delete_key_input: Deliveries_Microsoft_Shipping_Labels_Delete_Key_Input;
  deliveries_microsoft_shipping_labels_insert_input: Deliveries_Microsoft_Shipping_Labels_Insert_Input;
  deliveries_microsoft_shipping_labels_max_fields: Deliveries_Microsoft_Shipping_Labels_Max_Fields;
  deliveries_microsoft_shipping_labels_max_order_by: Deliveries_Microsoft_Shipping_Labels_Max_Order_By;
  deliveries_microsoft_shipping_labels_min_fields: Deliveries_Microsoft_Shipping_Labels_Min_Fields;
  deliveries_microsoft_shipping_labels_min_order_by: Deliveries_Microsoft_Shipping_Labels_Min_Order_By;
  deliveries_microsoft_shipping_labels_mutation_response: Deliveries_Microsoft_Shipping_Labels_Mutation_Response;
  deliveries_microsoft_shipping_labels_obj_rel_insert_input: Deliveries_Microsoft_Shipping_Labels_Obj_Rel_Insert_Input;
  deliveries_microsoft_shipping_labels_on_conflict: Deliveries_Microsoft_Shipping_Labels_On_Conflict;
  deliveries_microsoft_shipping_labels_order_by: Deliveries_Microsoft_Shipping_Labels_Order_By;
  deliveries_microsoft_shipping_labels_pk_columns_input: Deliveries_Microsoft_Shipping_Labels_Pk_Columns_Input;
  deliveries_microsoft_shipping_labels_prepend_input: Deliveries_Microsoft_Shipping_Labels_Prepend_Input;
  deliveries_microsoft_shipping_labels_set_input: Deliveries_Microsoft_Shipping_Labels_Set_Input;
  deliveries_microsoft_shipping_labels_stream_cursor_input: Deliveries_Microsoft_Shipping_Labels_Stream_Cursor_Input;
  deliveries_microsoft_shipping_labels_stream_cursor_value_input: Deliveries_Microsoft_Shipping_Labels_Stream_Cursor_Value_Input;
  deliveries_microsoft_shipping_labels_updates: Deliveries_Microsoft_Shipping_Labels_Updates;
  deliveries_microsoft_whql_submissions: Deliveries_Microsoft_Whql_Submissions;
  deliveries_microsoft_whql_submissions_aggregate: Deliveries_Microsoft_Whql_Submissions_Aggregate;
  deliveries_microsoft_whql_submissions_aggregate_fields: Deliveries_Microsoft_Whql_Submissions_Aggregate_Fields;
  deliveries_microsoft_whql_submissions_bool_exp: Deliveries_Microsoft_Whql_Submissions_Bool_Exp;
  deliveries_microsoft_whql_submissions_insert_input: Deliveries_Microsoft_Whql_Submissions_Insert_Input;
  deliveries_microsoft_whql_submissions_max_fields: Deliveries_Microsoft_Whql_Submissions_Max_Fields;
  deliveries_microsoft_whql_submissions_min_fields: Deliveries_Microsoft_Whql_Submissions_Min_Fields;
  deliveries_microsoft_whql_submissions_mutation_response: Deliveries_Microsoft_Whql_Submissions_Mutation_Response;
  deliveries_microsoft_whql_submissions_obj_rel_insert_input: Deliveries_Microsoft_Whql_Submissions_Obj_Rel_Insert_Input;
  deliveries_microsoft_whql_submissions_on_conflict: Deliveries_Microsoft_Whql_Submissions_On_Conflict;
  deliveries_microsoft_whql_submissions_order_by: Deliveries_Microsoft_Whql_Submissions_Order_By;
  deliveries_microsoft_whql_submissions_pk_columns_input: Deliveries_Microsoft_Whql_Submissions_Pk_Columns_Input;
  deliveries_microsoft_whql_submissions_set_input: Deliveries_Microsoft_Whql_Submissions_Set_Input;
  deliveries_microsoft_whql_submissions_stream_cursor_input: Deliveries_Microsoft_Whql_Submissions_Stream_Cursor_Input;
  deliveries_microsoft_whql_submissions_stream_cursor_value_input: Deliveries_Microsoft_Whql_Submissions_Stream_Cursor_Value_Input;
  deliveries_microsoft_whql_submissions_updates: Deliveries_Microsoft_Whql_Submissions_Updates;
  deliveries_operating_systems: Deliveries_Operating_Systems;
  deliveries_operating_systems_bool_exp: Deliveries_Operating_Systems_Bool_Exp;
  deliveries_operating_systems_insert_input: Deliveries_Operating_Systems_Insert_Input;
  deliveries_operating_systems_mutation_response: Deliveries_Operating_Systems_Mutation_Response;
  deliveries_operating_systems_obj_rel_insert_input: Deliveries_Operating_Systems_Obj_Rel_Insert_Input;
  deliveries_operating_systems_on_conflict: Deliveries_Operating_Systems_On_Conflict;
  deliveries_operating_systems_order_by: Deliveries_Operating_Systems_Order_By;
  deliveries_operating_systems_pk_columns_input: Deliveries_Operating_Systems_Pk_Columns_Input;
  deliveries_operating_systems_set_input: Deliveries_Operating_Systems_Set_Input;
  deliveries_operating_systems_stream_cursor_input: Deliveries_Operating_Systems_Stream_Cursor_Input;
  deliveries_operating_systems_stream_cursor_value_input: Deliveries_Operating_Systems_Stream_Cursor_Value_Input;
  deliveries_operating_systems_updates: Deliveries_Operating_Systems_Updates;
  deliveries_package_operating_systems: Deliveries_Package_Operating_Systems;
  deliveries_package_operating_systems_aggregate_order_by: Deliveries_Package_Operating_Systems_Aggregate_Order_By;
  deliveries_package_operating_systems_arr_rel_insert_input: Deliveries_Package_Operating_Systems_Arr_Rel_Insert_Input;
  deliveries_package_operating_systems_bool_exp: Deliveries_Package_Operating_Systems_Bool_Exp;
  deliveries_package_operating_systems_insert_input: Deliveries_Package_Operating_Systems_Insert_Input;
  deliveries_package_operating_systems_max_order_by: Deliveries_Package_Operating_Systems_Max_Order_By;
  deliveries_package_operating_systems_min_order_by: Deliveries_Package_Operating_Systems_Min_Order_By;
  deliveries_package_operating_systems_mutation_response: Deliveries_Package_Operating_Systems_Mutation_Response;
  deliveries_package_operating_systems_on_conflict: Deliveries_Package_Operating_Systems_On_Conflict;
  deliveries_package_operating_systems_order_by: Deliveries_Package_Operating_Systems_Order_By;
  deliveries_package_operating_systems_pk_columns_input: Deliveries_Package_Operating_Systems_Pk_Columns_Input;
  deliveries_package_operating_systems_set_input: Deliveries_Package_Operating_Systems_Set_Input;
  deliveries_package_operating_systems_stream_cursor_input: Deliveries_Package_Operating_Systems_Stream_Cursor_Input;
  deliveries_package_operating_systems_stream_cursor_value_input: Deliveries_Package_Operating_Systems_Stream_Cursor_Value_Input;
  deliveries_package_operating_systems_updates: Deliveries_Package_Operating_Systems_Updates;
  deliveries_package_target: Deliveries_Package_Target;
  deliveries_package_target_aggregate: Deliveries_Package_Target_Aggregate;
  deliveries_package_target_aggregate_fields: Deliveries_Package_Target_Aggregate_Fields;
  deliveries_package_target_aggregate_order_by: Deliveries_Package_Target_Aggregate_Order_By;
  deliveries_package_target_arr_rel_insert_input: Deliveries_Package_Target_Arr_Rel_Insert_Input;
  deliveries_package_target_bool_exp: Deliveries_Package_Target_Bool_Exp;
  deliveries_package_target_insert_input: Deliveries_Package_Target_Insert_Input;
  deliveries_package_target_max_fields: Deliveries_Package_Target_Max_Fields;
  deliveries_package_target_max_order_by: Deliveries_Package_Target_Max_Order_By;
  deliveries_package_target_min_fields: Deliveries_Package_Target_Min_Fields;
  deliveries_package_target_min_order_by: Deliveries_Package_Target_Min_Order_By;
  deliveries_package_target_mutation_response: Deliveries_Package_Target_Mutation_Response;
  deliveries_package_target_on_conflict: Deliveries_Package_Target_On_Conflict;
  deliveries_package_target_order_by: Deliveries_Package_Target_Order_By;
  deliveries_package_target_pk_columns_input: Deliveries_Package_Target_Pk_Columns_Input;
  deliveries_package_target_set_input: Deliveries_Package_Target_Set_Input;
  deliveries_package_target_stream_cursor_input: Deliveries_Package_Target_Stream_Cursor_Input;
  deliveries_package_target_stream_cursor_value_input: Deliveries_Package_Target_Stream_Cursor_Value_Input;
  deliveries_package_target_updates: Deliveries_Package_Target_Updates;
  deliveries_packages: Deliveries_Packages;
  deliveries_packages_aggregate: Deliveries_Packages_Aggregate;
  deliveries_packages_aggregate_fields: Deliveries_Packages_Aggregate_Fields;
  deliveries_packages_aggregate_order_by: Deliveries_Packages_Aggregate_Order_By;
  deliveries_packages_arr_rel_insert_input: Deliveries_Packages_Arr_Rel_Insert_Input;
  deliveries_packages_avg_fields: Deliveries_Packages_Avg_Fields;
  deliveries_packages_avg_order_by: Deliveries_Packages_Avg_Order_By;
  deliveries_packages_bool_exp: Deliveries_Packages_Bool_Exp;
  deliveries_packages_inc_input: Deliveries_Packages_Inc_Input;
  deliveries_packages_insert_input: Deliveries_Packages_Insert_Input;
  deliveries_packages_max_fields: Deliveries_Packages_Max_Fields;
  deliveries_packages_max_order_by: Deliveries_Packages_Max_Order_By;
  deliveries_packages_min_fields: Deliveries_Packages_Min_Fields;
  deliveries_packages_min_order_by: Deliveries_Packages_Min_Order_By;
  deliveries_packages_mutation_response: Deliveries_Packages_Mutation_Response;
  deliveries_packages_obj_rel_insert_input: Deliveries_Packages_Obj_Rel_Insert_Input;
  deliveries_packages_on_conflict: Deliveries_Packages_On_Conflict;
  deliveries_packages_order_by: Deliveries_Packages_Order_By;
  deliveries_packages_pk_columns_input: Deliveries_Packages_Pk_Columns_Input;
  deliveries_packages_set_input: Deliveries_Packages_Set_Input;
  deliveries_packages_stddev_fields: Deliveries_Packages_Stddev_Fields;
  deliveries_packages_stddev_order_by: Deliveries_Packages_Stddev_Order_By;
  deliveries_packages_stddev_pop_fields: Deliveries_Packages_Stddev_Pop_Fields;
  deliveries_packages_stddev_pop_order_by: Deliveries_Packages_Stddev_Pop_Order_By;
  deliveries_packages_stddev_samp_fields: Deliveries_Packages_Stddev_Samp_Fields;
  deliveries_packages_stddev_samp_order_by: Deliveries_Packages_Stddev_Samp_Order_By;
  deliveries_packages_stream_cursor_input: Deliveries_Packages_Stream_Cursor_Input;
  deliveries_packages_stream_cursor_value_input: Deliveries_Packages_Stream_Cursor_Value_Input;
  deliveries_packages_sum_fields: Deliveries_Packages_Sum_Fields;
  deliveries_packages_sum_order_by: Deliveries_Packages_Sum_Order_By;
  deliveries_packages_updates: Deliveries_Packages_Updates;
  deliveries_packages_var_pop_fields: Deliveries_Packages_Var_Pop_Fields;
  deliveries_packages_var_pop_order_by: Deliveries_Packages_Var_Pop_Order_By;
  deliveries_packages_var_samp_fields: Deliveries_Packages_Var_Samp_Fields;
  deliveries_packages_var_samp_order_by: Deliveries_Packages_Var_Samp_Order_By;
  deliveries_packages_variance_fields: Deliveries_Packages_Variance_Fields;
  deliveries_packages_variance_order_by: Deliveries_Packages_Variance_Order_By;
  deliveries_partners: Deliveries_Partners;
  deliveries_partners_bool_exp: Deliveries_Partners_Bool_Exp;
  deliveries_partners_insert_input: Deliveries_Partners_Insert_Input;
  deliveries_partners_mutation_response: Deliveries_Partners_Mutation_Response;
  deliveries_partners_obj_rel_insert_input: Deliveries_Partners_Obj_Rel_Insert_Input;
  deliveries_partners_on_conflict: Deliveries_Partners_On_Conflict;
  deliveries_partners_order_by: Deliveries_Partners_Order_By;
  deliveries_partners_pk_columns_input: Deliveries_Partners_Pk_Columns_Input;
  deliveries_partners_set_input: Deliveries_Partners_Set_Input;
  deliveries_partners_stream_cursor_input: Deliveries_Partners_Stream_Cursor_Input;
  deliveries_partners_stream_cursor_value_input: Deliveries_Partners_Stream_Cursor_Value_Input;
  deliveries_partners_updates: Deliveries_Partners_Updates;
  deliveries_platforms: Deliveries_Platforms;
  deliveries_platforms_bool_exp: Deliveries_Platforms_Bool_Exp;
  deliveries_platforms_insert_input: Deliveries_Platforms_Insert_Input;
  deliveries_platforms_mutation_response: Deliveries_Platforms_Mutation_Response;
  deliveries_platforms_obj_rel_insert_input: Deliveries_Platforms_Obj_Rel_Insert_Input;
  deliveries_platforms_on_conflict: Deliveries_Platforms_On_Conflict;
  deliveries_platforms_order_by: Deliveries_Platforms_Order_By;
  deliveries_platforms_pk_columns_input: Deliveries_Platforms_Pk_Columns_Input;
  deliveries_platforms_set_input: Deliveries_Platforms_Set_Input;
  deliveries_platforms_stream_cursor_input: Deliveries_Platforms_Stream_Cursor_Input;
  deliveries_platforms_stream_cursor_value_input: Deliveries_Platforms_Stream_Cursor_Value_Input;
  deliveries_platforms_updates: Deliveries_Platforms_Updates;
  deliveries_project_configs: Deliveries_Project_Configs;
  deliveries_project_configs_aggregate: Deliveries_Project_Configs_Aggregate;
  deliveries_project_configs_aggregate_fields: Deliveries_Project_Configs_Aggregate_Fields;
  deliveries_project_configs_avg_fields: Deliveries_Project_Configs_Avg_Fields;
  deliveries_project_configs_bool_exp: Deliveries_Project_Configs_Bool_Exp;
  deliveries_project_configs_inc_input: Deliveries_Project_Configs_Inc_Input;
  deliveries_project_configs_insert_input: Deliveries_Project_Configs_Insert_Input;
  deliveries_project_configs_max_fields: Deliveries_Project_Configs_Max_Fields;
  deliveries_project_configs_min_fields: Deliveries_Project_Configs_Min_Fields;
  deliveries_project_configs_mutation_response: Deliveries_Project_Configs_Mutation_Response;
  deliveries_project_configs_on_conflict: Deliveries_Project_Configs_On_Conflict;
  deliveries_project_configs_order_by: Deliveries_Project_Configs_Order_By;
  deliveries_project_configs_pk_columns_input: Deliveries_Project_Configs_Pk_Columns_Input;
  deliveries_project_configs_set_input: Deliveries_Project_Configs_Set_Input;
  deliveries_project_configs_stddev_fields: Deliveries_Project_Configs_Stddev_Fields;
  deliveries_project_configs_stddev_pop_fields: Deliveries_Project_Configs_Stddev_Pop_Fields;
  deliveries_project_configs_stddev_samp_fields: Deliveries_Project_Configs_Stddev_Samp_Fields;
  deliveries_project_configs_stream_cursor_input: Deliveries_Project_Configs_Stream_Cursor_Input;
  deliveries_project_configs_stream_cursor_value_input: Deliveries_Project_Configs_Stream_Cursor_Value_Input;
  deliveries_project_configs_sum_fields: Deliveries_Project_Configs_Sum_Fields;
  deliveries_project_configs_updates: Deliveries_Project_Configs_Updates;
  deliveries_project_configs_var_pop_fields: Deliveries_Project_Configs_Var_Pop_Fields;
  deliveries_project_configs_var_samp_fields: Deliveries_Project_Configs_Var_Samp_Fields;
  deliveries_project_configs_variance_fields: Deliveries_Project_Configs_Variance_Fields;
  deliveries_target_partner: Deliveries_Target_Partner;
  deliveries_target_partner_aggregate_order_by: Deliveries_Target_Partner_Aggregate_Order_By;
  deliveries_target_partner_arr_rel_insert_input: Deliveries_Target_Partner_Arr_Rel_Insert_Input;
  deliveries_target_partner_bool_exp: Deliveries_Target_Partner_Bool_Exp;
  deliveries_target_partner_insert_input: Deliveries_Target_Partner_Insert_Input;
  deliveries_target_partner_max_order_by: Deliveries_Target_Partner_Max_Order_By;
  deliveries_target_partner_min_order_by: Deliveries_Target_Partner_Min_Order_By;
  deliveries_target_partner_mutation_response: Deliveries_Target_Partner_Mutation_Response;
  deliveries_target_partner_on_conflict: Deliveries_Target_Partner_On_Conflict;
  deliveries_target_partner_order_by: Deliveries_Target_Partner_Order_By;
  deliveries_target_partner_pk_columns_input: Deliveries_Target_Partner_Pk_Columns_Input;
  deliveries_target_partner_set_input: Deliveries_Target_Partner_Set_Input;
  deliveries_target_partner_stream_cursor_input: Deliveries_Target_Partner_Stream_Cursor_Input;
  deliveries_target_partner_stream_cursor_value_input: Deliveries_Target_Partner_Stream_Cursor_Value_Input;
  deliveries_target_partner_updates: Deliveries_Target_Partner_Updates;
  deliveries_targets: Deliveries_Targets;
  deliveries_targets_aggregate: Deliveries_Targets_Aggregate;
  deliveries_targets_aggregate_fields: Deliveries_Targets_Aggregate_Fields;
  deliveries_targets_bool_exp: Deliveries_Targets_Bool_Exp;
  deliveries_targets_insert_input: Deliveries_Targets_Insert_Input;
  deliveries_targets_max_fields: Deliveries_Targets_Max_Fields;
  deliveries_targets_min_fields: Deliveries_Targets_Min_Fields;
  deliveries_targets_mutation_response: Deliveries_Targets_Mutation_Response;
  deliveries_targets_obj_rel_insert_input: Deliveries_Targets_Obj_Rel_Insert_Input;
  deliveries_targets_on_conflict: Deliveries_Targets_On_Conflict;
  deliveries_targets_order_by: Deliveries_Targets_Order_By;
  deliveries_targets_pk_columns_input: Deliveries_Targets_Pk_Columns_Input;
  deliveries_targets_set_input: Deliveries_Targets_Set_Input;
  deliveries_targets_stream_cursor_input: Deliveries_Targets_Stream_Cursor_Input;
  deliveries_targets_stream_cursor_value_input: Deliveries_Targets_Stream_Cursor_Value_Input;
  deliveries_targets_updates: Deliveries_Targets_Updates;
  device_firmware: Device_Firmware;
  device_firmware_aggregate_order_by: Device_Firmware_Aggregate_Order_By;
  device_firmware_arr_rel_insert_input: Device_Firmware_Arr_Rel_Insert_Input;
  device_firmware_bool_exp: Device_Firmware_Bool_Exp;
  device_firmware_insert_input: Device_Firmware_Insert_Input;
  device_firmware_max_order_by: Device_Firmware_Max_Order_By;
  device_firmware_min_order_by: Device_Firmware_Min_Order_By;
  device_firmware_mutation_response: Device_Firmware_Mutation_Response;
  device_firmware_obj_rel_insert_input: Device_Firmware_Obj_Rel_Insert_Input;
  device_firmware_on_conflict: Device_Firmware_On_Conflict;
  device_firmware_order_by: Device_Firmware_Order_By;
  device_firmware_pk_columns_input: Device_Firmware_Pk_Columns_Input;
  device_firmware_set_input: Device_Firmware_Set_Input;
  device_firmware_stream_cursor_input: Device_Firmware_Stream_Cursor_Input;
  device_firmware_stream_cursor_value_input: Device_Firmware_Stream_Cursor_Value_Input;
  device_firmware_updates: Device_Firmware_Updates;
  device_project: Device_Project;
  device_project_aggregate: Device_Project_Aggregate;
  device_project_aggregate_fields: Device_Project_Aggregate_Fields;
  device_project_aggregate_order_by: Device_Project_Aggregate_Order_By;
  device_project_arr_rel_insert_input: Device_Project_Arr_Rel_Insert_Input;
  device_project_bool_exp: Device_Project_Bool_Exp;
  device_project_insert_input: Device_Project_Insert_Input;
  device_project_max_fields: Device_Project_Max_Fields;
  device_project_max_order_by: Device_Project_Max_Order_By;
  device_project_min_fields: Device_Project_Min_Fields;
  device_project_min_order_by: Device_Project_Min_Order_By;
  device_project_mutation_response: Device_Project_Mutation_Response;
  device_project_on_conflict: Device_Project_On_Conflict;
  device_project_order_by: Device_Project_Order_By;
  device_project_pk_columns_input: Device_Project_Pk_Columns_Input;
  device_project_set_input: Device_Project_Set_Input;
  device_project_stream_cursor_input: Device_Project_Stream_Cursor_Input;
  device_project_stream_cursor_value_input: Device_Project_Stream_Cursor_Value_Input;
  device_project_updates: Device_Project_Updates;
  devices: Devices;
  devices_aggregate: Devices_Aggregate;
  devices_aggregate_fields: Devices_Aggregate_Fields;
  devices_aggregate_order_by: Devices_Aggregate_Order_By;
  devices_arr_rel_insert_input: Devices_Arr_Rel_Insert_Input;
  devices_bool_exp: Devices_Bool_Exp;
  devices_insert_input: Devices_Insert_Input;
  devices_max_fields: Devices_Max_Fields;
  devices_max_order_by: Devices_Max_Order_By;
  devices_min_fields: Devices_Min_Fields;
  devices_min_order_by: Devices_Min_Order_By;
  devices_mutation_response: Devices_Mutation_Response;
  devices_obj_rel_insert_input: Devices_Obj_Rel_Insert_Input;
  devices_on_conflict: Devices_On_Conflict;
  devices_order_by: Devices_Order_By;
  devices_pk_columns_input: Devices_Pk_Columns_Input;
  devices_set_input: Devices_Set_Input;
  devices_stream_cursor_input: Devices_Stream_Cursor_Input;
  devices_stream_cursor_value_input: Devices_Stream_Cursor_Value_Input;
  devices_updates: Devices_Updates;
  dynamic_scaling: Dynamic_Scaling;
  dynamic_scaling_bool_exp: Dynamic_Scaling_Bool_Exp;
  dynamic_scaling_inc_input: Dynamic_Scaling_Inc_Input;
  dynamic_scaling_insert_input: Dynamic_Scaling_Insert_Input;
  dynamic_scaling_mutation_response: Dynamic_Scaling_Mutation_Response;
  dynamic_scaling_obj_rel_insert_input: Dynamic_Scaling_Obj_Rel_Insert_Input;
  dynamic_scaling_on_conflict: Dynamic_Scaling_On_Conflict;
  dynamic_scaling_order_by: Dynamic_Scaling_Order_By;
  dynamic_scaling_pk_columns_input: Dynamic_Scaling_Pk_Columns_Input;
  dynamic_scaling_set_input: Dynamic_Scaling_Set_Input;
  dynamic_scaling_stream_cursor_input: Dynamic_Scaling_Stream_Cursor_Input;
  dynamic_scaling_stream_cursor_value_input: Dynamic_Scaling_Stream_Cursor_Value_Input;
  dynamic_scaling_updates: Dynamic_Scaling_Updates;
  engine_opmode: Engine_Opmode;
  engine_opmode_aggregate: Engine_Opmode_Aggregate;
  engine_opmode_aggregate_fields: Engine_Opmode_Aggregate_Fields;
  engine_opmode_aggregate_order_by: Engine_Opmode_Aggregate_Order_By;
  engine_opmode_arr_rel_insert_input: Engine_Opmode_Arr_Rel_Insert_Input;
  engine_opmode_bool_exp: Engine_Opmode_Bool_Exp;
  engine_opmode_insert_input: Engine_Opmode_Insert_Input;
  engine_opmode_max_fields: Engine_Opmode_Max_Fields;
  engine_opmode_max_order_by: Engine_Opmode_Max_Order_By;
  engine_opmode_min_fields: Engine_Opmode_Min_Fields;
  engine_opmode_min_order_by: Engine_Opmode_Min_Order_By;
  engine_opmode_mutation_response: Engine_Opmode_Mutation_Response;
  engine_opmode_obj_rel_insert_input: Engine_Opmode_Obj_Rel_Insert_Input;
  engine_opmode_on_conflict: Engine_Opmode_On_Conflict;
  engine_opmode_order_by: Engine_Opmode_Order_By;
  engine_opmode_pk_columns_input: Engine_Opmode_Pk_Columns_Input;
  engine_opmode_recording: Engine_Opmode_Recording;
  engine_opmode_recording_aggregate: Engine_Opmode_Recording_Aggregate;
  engine_opmode_recording_aggregate_fields: Engine_Opmode_Recording_Aggregate_Fields;
  engine_opmode_recording_aggregate_order_by: Engine_Opmode_Recording_Aggregate_Order_By;
  engine_opmode_recording_arr_rel_insert_input: Engine_Opmode_Recording_Arr_Rel_Insert_Input;
  engine_opmode_recording_bool_exp: Engine_Opmode_Recording_Bool_Exp;
  engine_opmode_recording_insert_input: Engine_Opmode_Recording_Insert_Input;
  engine_opmode_recording_max_fields: Engine_Opmode_Recording_Max_Fields;
  engine_opmode_recording_max_order_by: Engine_Opmode_Recording_Max_Order_By;
  engine_opmode_recording_min_fields: Engine_Opmode_Recording_Min_Fields;
  engine_opmode_recording_min_order_by: Engine_Opmode_Recording_Min_Order_By;
  engine_opmode_recording_mutation_response: Engine_Opmode_Recording_Mutation_Response;
  engine_opmode_recording_on_conflict: Engine_Opmode_Recording_On_Conflict;
  engine_opmode_recording_order_by: Engine_Opmode_Recording_Order_By;
  engine_opmode_recording_pk_columns_input: Engine_Opmode_Recording_Pk_Columns_Input;
  engine_opmode_recording_set_input: Engine_Opmode_Recording_Set_Input;
  engine_opmode_recording_stream_cursor_input: Engine_Opmode_Recording_Stream_Cursor_Input;
  engine_opmode_recording_stream_cursor_value_input: Engine_Opmode_Recording_Stream_Cursor_Value_Input;
  engine_opmode_recording_updates: Engine_Opmode_Recording_Updates;
  engine_opmode_set_input: Engine_Opmode_Set_Input;
  engine_opmode_stream_cursor_input: Engine_Opmode_Stream_Cursor_Input;
  engine_opmode_stream_cursor_value_input: Engine_Opmode_Stream_Cursor_Value_Input;
  engine_opmode_updates: Engine_Opmode_Updates;
  engine_types: Engine_Types;
  engine_types_aggregate: Engine_Types_Aggregate;
  engine_types_aggregate_fields: Engine_Types_Aggregate_Fields;
  engine_types_bool_exp: Engine_Types_Bool_Exp;
  engine_types_enum_comparison_exp: Engine_Types_Enum_Comparison_Exp;
  engine_types_max_fields: Engine_Types_Max_Fields;
  engine_types_min_fields: Engine_Types_Min_Fields;
  engine_types_mutation_response: Engine_Types_Mutation_Response;
  engine_types_order_by: Engine_Types_Order_By;
  engine_types_pk_columns_input: Engine_Types_Pk_Columns_Input;
  engine_types_set_input: Engine_Types_Set_Input;
  engine_types_stream_cursor_input: Engine_Types_Stream_Cursor_Input;
  engine_types_stream_cursor_value_input: Engine_Types_Stream_Cursor_Value_Input;
  engine_types_updates: Engine_Types_Updates;
  engines: Engines;
  engines_aggregate: Engines_Aggregate;
  engines_aggregate_fields: Engines_Aggregate_Fields;
  engines_avg_fields: Engines_Avg_Fields;
  engines_bool_exp: Engines_Bool_Exp;
  engines_inc_input: Engines_Inc_Input;
  engines_insert_input: Engines_Insert_Input;
  engines_max_fields: Engines_Max_Fields;
  engines_min_fields: Engines_Min_Fields;
  engines_mutation_response: Engines_Mutation_Response;
  engines_obj_rel_insert_input: Engines_Obj_Rel_Insert_Input;
  engines_on_conflict: Engines_On_Conflict;
  engines_order_by: Engines_Order_By;
  engines_pk_columns_input: Engines_Pk_Columns_Input;
  engines_set_input: Engines_Set_Input;
  engines_stddev_fields: Engines_Stddev_Fields;
  engines_stddev_pop_fields: Engines_Stddev_Pop_Fields;
  engines_stddev_samp_fields: Engines_Stddev_Samp_Fields;
  engines_stream_cursor_input: Engines_Stream_Cursor_Input;
  engines_stream_cursor_value_input: Engines_Stream_Cursor_Value_Input;
  engines_sum_fields: Engines_Sum_Fields;
  engines_updates: Engines_Updates;
  engines_var_pop_fields: Engines_Var_Pop_Fields;
  engines_var_samp_fields: Engines_Var_Samp_Fields;
  engines_variance_fields: Engines_Variance_Fields;
  field_option_media: Field_Option_Media;
  field_option_media_aggregate: Field_Option_Media_Aggregate;
  field_option_media_aggregate_fields: Field_Option_Media_Aggregate_Fields;
  field_option_media_aggregate_order_by: Field_Option_Media_Aggregate_Order_By;
  field_option_media_arr_rel_insert_input: Field_Option_Media_Arr_Rel_Insert_Input;
  field_option_media_bool_exp: Field_Option_Media_Bool_Exp;
  field_option_media_insert_input: Field_Option_Media_Insert_Input;
  field_option_media_max_fields: Field_Option_Media_Max_Fields;
  field_option_media_max_order_by: Field_Option_Media_Max_Order_By;
  field_option_media_min_fields: Field_Option_Media_Min_Fields;
  field_option_media_min_order_by: Field_Option_Media_Min_Order_By;
  field_option_media_mutation_response: Field_Option_Media_Mutation_Response;
  field_option_media_on_conflict: Field_Option_Media_On_Conflict;
  field_option_media_order_by: Field_Option_Media_Order_By;
  field_option_media_pk_columns_input: Field_Option_Media_Pk_Columns_Input;
  field_option_media_set_input: Field_Option_Media_Set_Input;
  field_option_media_stream_cursor_input: Field_Option_Media_Stream_Cursor_Input;
  field_option_media_stream_cursor_value_input: Field_Option_Media_Stream_Cursor_Value_Input;
  field_option_media_updates: Field_Option_Media_Updates;
  field_options: Field_Options;
  field_options_aggregate: Field_Options_Aggregate;
  field_options_aggregate_fields: Field_Options_Aggregate_Fields;
  field_options_aggregate_order_by: Field_Options_Aggregate_Order_By;
  field_options_arr_rel_insert_input: Field_Options_Arr_Rel_Insert_Input;
  field_options_bool_exp: Field_Options_Bool_Exp;
  field_options_insert_input: Field_Options_Insert_Input;
  field_options_max_fields: Field_Options_Max_Fields;
  field_options_max_order_by: Field_Options_Max_Order_By;
  field_options_min_fields: Field_Options_Min_Fields;
  field_options_min_order_by: Field_Options_Min_Order_By;
  field_options_mutation_response: Field_Options_Mutation_Response;
  field_options_obj_rel_insert_input: Field_Options_Obj_Rel_Insert_Input;
  field_options_on_conflict: Field_Options_On_Conflict;
  field_options_order_by: Field_Options_Order_By;
  field_options_pk_columns_input: Field_Options_Pk_Columns_Input;
  field_options_set_input: Field_Options_Set_Input;
  field_options_stream_cursor_input: Field_Options_Stream_Cursor_Input;
  field_options_stream_cursor_value_input: Field_Options_Stream_Cursor_Value_Input;
  field_options_updates: Field_Options_Updates;
  field_translation: Field_Translation;
  field_translation_aggregate: Field_Translation_Aggregate;
  field_translation_aggregate_fields: Field_Translation_Aggregate_Fields;
  field_translation_aggregate_order_by: Field_Translation_Aggregate_Order_By;
  field_translation_arr_rel_insert_input: Field_Translation_Arr_Rel_Insert_Input;
  field_translation_bool_exp: Field_Translation_Bool_Exp;
  field_translation_insert_input: Field_Translation_Insert_Input;
  field_translation_max_fields: Field_Translation_Max_Fields;
  field_translation_max_order_by: Field_Translation_Max_Order_By;
  field_translation_min_fields: Field_Translation_Min_Fields;
  field_translation_min_order_by: Field_Translation_Min_Order_By;
  field_translation_mutation_response: Field_Translation_Mutation_Response;
  field_translation_on_conflict: Field_Translation_On_Conflict;
  field_translation_order_by: Field_Translation_Order_By;
  field_translation_pk_columns_input: Field_Translation_Pk_Columns_Input;
  field_translation_set_input: Field_Translation_Set_Input;
  field_translation_stream_cursor_input: Field_Translation_Stream_Cursor_Input;
  field_translation_stream_cursor_value_input: Field_Translation_Stream_Cursor_Value_Input;
  field_translation_updates: Field_Translation_Updates;
  fields: Fields;
  fields_aggregate: Fields_Aggregate;
  fields_aggregate_fields: Fields_Aggregate_Fields;
  fields_aggregate_order_by: Fields_Aggregate_Order_By;
  fields_arr_rel_insert_input: Fields_Arr_Rel_Insert_Input;
  fields_bool_exp: Fields_Bool_Exp;
  fields_insert_input: Fields_Insert_Input;
  fields_max_fields: Fields_Max_Fields;
  fields_max_order_by: Fields_Max_Order_By;
  fields_min_fields: Fields_Min_Fields;
  fields_min_order_by: Fields_Min_Order_By;
  fields_mutation_response: Fields_Mutation_Response;
  fields_obj_rel_insert_input: Fields_Obj_Rel_Insert_Input;
  fields_on_conflict: Fields_On_Conflict;
  fields_order_by: Fields_Order_By;
  fields_pk_columns_input: Fields_Pk_Columns_Input;
  fields_set_input: Fields_Set_Input;
  fields_stream_cursor_input: Fields_Stream_Cursor_Input;
  fields_stream_cursor_value_input: Fields_Stream_Cursor_Value_Input;
  fields_updates: Fields_Updates;
  firmware: Firmware;
  firmware_aggregate: Firmware_Aggregate;
  firmware_aggregate_fields: Firmware_Aggregate_Fields;
  firmware_append_input: Firmware_Append_Input;
  firmware_bool_exp: Firmware_Bool_Exp;
  firmware_delete_at_path_input: Firmware_Delete_At_Path_Input;
  firmware_delete_elem_input: Firmware_Delete_Elem_Input;
  firmware_delete_key_input: Firmware_Delete_Key_Input;
  firmware_insert_input: Firmware_Insert_Input;
  firmware_max_fields: Firmware_Max_Fields;
  firmware_min_fields: Firmware_Min_Fields;
  firmware_mutation_response: Firmware_Mutation_Response;
  firmware_obj_rel_insert_input: Firmware_Obj_Rel_Insert_Input;
  firmware_on_conflict: Firmware_On_Conflict;
  firmware_order_by: Firmware_Order_By;
  firmware_pk_columns_input: Firmware_Pk_Columns_Input;
  firmware_prepend_input: Firmware_Prepend_Input;
  firmware_set_input: Firmware_Set_Input;
  firmware_stream_cursor_input: Firmware_Stream_Cursor_Input;
  firmware_stream_cursor_value_input: Firmware_Stream_Cursor_Value_Input;
  firmware_updates: Firmware_Updates;
  float8: Scalars['float8'];
  float8_comparison_exp: Float8_Comparison_Exp;
  ground_truth_configurations: Ground_Truth_Configurations;
  ground_truth_configurations_aggregate: Ground_Truth_Configurations_Aggregate;
  ground_truth_configurations_aggregate_fields: Ground_Truth_Configurations_Aggregate_Fields;
  ground_truth_configurations_aggregate_order_by: Ground_Truth_Configurations_Aggregate_Order_By;
  ground_truth_configurations_append_input: Ground_Truth_Configurations_Append_Input;
  ground_truth_configurations_bool_exp: Ground_Truth_Configurations_Bool_Exp;
  ground_truth_configurations_delete_at_path_input: Ground_Truth_Configurations_Delete_At_Path_Input;
  ground_truth_configurations_delete_elem_input: Ground_Truth_Configurations_Delete_Elem_Input;
  ground_truth_configurations_delete_key_input: Ground_Truth_Configurations_Delete_Key_Input;
  ground_truth_configurations_insert_input: Ground_Truth_Configurations_Insert_Input;
  ground_truth_configurations_max_fields: Ground_Truth_Configurations_Max_Fields;
  ground_truth_configurations_max_order_by: Ground_Truth_Configurations_Max_Order_By;
  ground_truth_configurations_min_fields: Ground_Truth_Configurations_Min_Fields;
  ground_truth_configurations_min_order_by: Ground_Truth_Configurations_Min_Order_By;
  ground_truth_configurations_mutation_response: Ground_Truth_Configurations_Mutation_Response;
  ground_truth_configurations_obj_rel_insert_input: Ground_Truth_Configurations_Obj_Rel_Insert_Input;
  ground_truth_configurations_on_conflict: Ground_Truth_Configurations_On_Conflict;
  ground_truth_configurations_order_by: Ground_Truth_Configurations_Order_By;
  ground_truth_configurations_pk_columns_input: Ground_Truth_Configurations_Pk_Columns_Input;
  ground_truth_configurations_prepend_input: Ground_Truth_Configurations_Prepend_Input;
  ground_truth_configurations_set_input: Ground_Truth_Configurations_Set_Input;
  ground_truth_configurations_stream_cursor_input: Ground_Truth_Configurations_Stream_Cursor_Input;
  ground_truth_configurations_stream_cursor_value_input: Ground_Truth_Configurations_Stream_Cursor_Value_Input;
  ground_truth_configurations_updates: Ground_Truth_Configurations_Updates;
  ground_truth_device_types: Ground_Truth_Device_Types;
  ground_truth_device_types_aggregate: Ground_Truth_Device_Types_Aggregate;
  ground_truth_device_types_aggregate_fields: Ground_Truth_Device_Types_Aggregate_Fields;
  ground_truth_device_types_bool_exp: Ground_Truth_Device_Types_Bool_Exp;
  ground_truth_device_types_enum_comparison_exp: Ground_Truth_Device_Types_Enum_Comparison_Exp;
  ground_truth_device_types_max_fields: Ground_Truth_Device_Types_Max_Fields;
  ground_truth_device_types_min_fields: Ground_Truth_Device_Types_Min_Fields;
  ground_truth_device_types_mutation_response: Ground_Truth_Device_Types_Mutation_Response;
  ground_truth_device_types_order_by: Ground_Truth_Device_Types_Order_By;
  ground_truth_device_types_pk_columns_input: Ground_Truth_Device_Types_Pk_Columns_Input;
  ground_truth_device_types_set_input: Ground_Truth_Device_Types_Set_Input;
  ground_truth_device_types_stream_cursor_input: Ground_Truth_Device_Types_Stream_Cursor_Input;
  ground_truth_device_types_stream_cursor_value_input: Ground_Truth_Device_Types_Stream_Cursor_Value_Input;
  ground_truth_device_types_updates: Ground_Truth_Device_Types_Updates;
  ground_truth_processing_modes: Ground_Truth_Processing_Modes;
  ground_truth_processing_modes_aggregate: Ground_Truth_Processing_Modes_Aggregate;
  ground_truth_processing_modes_aggregate_fields: Ground_Truth_Processing_Modes_Aggregate_Fields;
  ground_truth_processing_modes_bool_exp: Ground_Truth_Processing_Modes_Bool_Exp;
  ground_truth_processing_modes_enum_comparison_exp: Ground_Truth_Processing_Modes_Enum_Comparison_Exp;
  ground_truth_processing_modes_max_fields: Ground_Truth_Processing_Modes_Max_Fields;
  ground_truth_processing_modes_min_fields: Ground_Truth_Processing_Modes_Min_Fields;
  ground_truth_processing_modes_mutation_response: Ground_Truth_Processing_Modes_Mutation_Response;
  ground_truth_processing_modes_order_by: Ground_Truth_Processing_Modes_Order_By;
  ground_truth_processing_modes_pk_columns_input: Ground_Truth_Processing_Modes_Pk_Columns_Input;
  ground_truth_processing_modes_set_input: Ground_Truth_Processing_Modes_Set_Input;
  ground_truth_processing_modes_stream_cursor_input: Ground_Truth_Processing_Modes_Stream_Cursor_Input;
  ground_truth_processing_modes_stream_cursor_value_input: Ground_Truth_Processing_Modes_Stream_Cursor_Value_Input;
  ground_truth_processing_modes_updates: Ground_Truth_Processing_Modes_Updates;
  ground_truth_recordings: Ground_Truth_Recordings;
  ground_truth_recordings_aggregate: Ground_Truth_Recordings_Aggregate;
  ground_truth_recordings_aggregate_fields: Ground_Truth_Recordings_Aggregate_Fields;
  ground_truth_recordings_aggregate_order_by: Ground_Truth_Recordings_Aggregate_Order_By;
  ground_truth_recordings_arr_rel_insert_input: Ground_Truth_Recordings_Arr_Rel_Insert_Input;
  ground_truth_recordings_avg_fields: Ground_Truth_Recordings_Avg_Fields;
  ground_truth_recordings_avg_order_by: Ground_Truth_Recordings_Avg_Order_By;
  ground_truth_recordings_bool_exp: Ground_Truth_Recordings_Bool_Exp;
  ground_truth_recordings_inc_input: Ground_Truth_Recordings_Inc_Input;
  ground_truth_recordings_insert_input: Ground_Truth_Recordings_Insert_Input;
  ground_truth_recordings_max_fields: Ground_Truth_Recordings_Max_Fields;
  ground_truth_recordings_max_order_by: Ground_Truth_Recordings_Max_Order_By;
  ground_truth_recordings_min_fields: Ground_Truth_Recordings_Min_Fields;
  ground_truth_recordings_min_order_by: Ground_Truth_Recordings_Min_Order_By;
  ground_truth_recordings_mutation_response: Ground_Truth_Recordings_Mutation_Response;
  ground_truth_recordings_obj_rel_insert_input: Ground_Truth_Recordings_Obj_Rel_Insert_Input;
  ground_truth_recordings_on_conflict: Ground_Truth_Recordings_On_Conflict;
  ground_truth_recordings_order_by: Ground_Truth_Recordings_Order_By;
  ground_truth_recordings_pk_columns_input: Ground_Truth_Recordings_Pk_Columns_Input;
  ground_truth_recordings_set_input: Ground_Truth_Recordings_Set_Input;
  ground_truth_recordings_stddev_fields: Ground_Truth_Recordings_Stddev_Fields;
  ground_truth_recordings_stddev_order_by: Ground_Truth_Recordings_Stddev_Order_By;
  ground_truth_recordings_stddev_pop_fields: Ground_Truth_Recordings_Stddev_Pop_Fields;
  ground_truth_recordings_stddev_pop_order_by: Ground_Truth_Recordings_Stddev_Pop_Order_By;
  ground_truth_recordings_stddev_samp_fields: Ground_Truth_Recordings_Stddev_Samp_Fields;
  ground_truth_recordings_stddev_samp_order_by: Ground_Truth_Recordings_Stddev_Samp_Order_By;
  ground_truth_recordings_stream_cursor_input: Ground_Truth_Recordings_Stream_Cursor_Input;
  ground_truth_recordings_stream_cursor_value_input: Ground_Truth_Recordings_Stream_Cursor_Value_Input;
  ground_truth_recordings_sum_fields: Ground_Truth_Recordings_Sum_Fields;
  ground_truth_recordings_sum_order_by: Ground_Truth_Recordings_Sum_Order_By;
  ground_truth_recordings_updates: Ground_Truth_Recordings_Updates;
  ground_truth_recordings_var_pop_fields: Ground_Truth_Recordings_Var_Pop_Fields;
  ground_truth_recordings_var_pop_order_by: Ground_Truth_Recordings_Var_Pop_Order_By;
  ground_truth_recordings_var_samp_fields: Ground_Truth_Recordings_Var_Samp_Fields;
  ground_truth_recordings_var_samp_order_by: Ground_Truth_Recordings_Var_Samp_Order_By;
  ground_truth_recordings_variance_fields: Ground_Truth_Recordings_Variance_Fields;
  ground_truth_recordings_variance_order_by: Ground_Truth_Recordings_Variance_Order_By;
  ground_truth_types: Ground_Truth_Types;
  ground_truth_types_aggregate: Ground_Truth_Types_Aggregate;
  ground_truth_types_aggregate_fields: Ground_Truth_Types_Aggregate_Fields;
  ground_truth_types_avg_fields: Ground_Truth_Types_Avg_Fields;
  ground_truth_types_bool_exp: Ground_Truth_Types_Bool_Exp;
  ground_truth_types_inc_input: Ground_Truth_Types_Inc_Input;
  ground_truth_types_insert_input: Ground_Truth_Types_Insert_Input;
  ground_truth_types_max_fields: Ground_Truth_Types_Max_Fields;
  ground_truth_types_min_fields: Ground_Truth_Types_Min_Fields;
  ground_truth_types_mutation_response: Ground_Truth_Types_Mutation_Response;
  ground_truth_types_obj_rel_insert_input: Ground_Truth_Types_Obj_Rel_Insert_Input;
  ground_truth_types_on_conflict: Ground_Truth_Types_On_Conflict;
  ground_truth_types_order_by: Ground_Truth_Types_Order_By;
  ground_truth_types_pk_columns_input: Ground_Truth_Types_Pk_Columns_Input;
  ground_truth_types_set_input: Ground_Truth_Types_Set_Input;
  ground_truth_types_stddev_fields: Ground_Truth_Types_Stddev_Fields;
  ground_truth_types_stddev_pop_fields: Ground_Truth_Types_Stddev_Pop_Fields;
  ground_truth_types_stddev_samp_fields: Ground_Truth_Types_Stddev_Samp_Fields;
  ground_truth_types_stream_cursor_input: Ground_Truth_Types_Stream_Cursor_Input;
  ground_truth_types_stream_cursor_value_input: Ground_Truth_Types_Stream_Cursor_Value_Input;
  ground_truth_types_sum_fields: Ground_Truth_Types_Sum_Fields;
  ground_truth_types_updates: Ground_Truth_Types_Updates;
  ground_truth_types_var_pop_fields: Ground_Truth_Types_Var_Pop_Fields;
  ground_truth_types_var_samp_fields: Ground_Truth_Types_Var_Samp_Fields;
  ground_truth_types_variance_fields: Ground_Truth_Types_Variance_Fields;
  hypertask_field_option: Hypertask_Field_Option;
  hypertask_field_option_aggregate: Hypertask_Field_Option_Aggregate;
  hypertask_field_option_aggregate_fields: Hypertask_Field_Option_Aggregate_Fields;
  hypertask_field_option_aggregate_order_by: Hypertask_Field_Option_Aggregate_Order_By;
  hypertask_field_option_arr_rel_insert_input: Hypertask_Field_Option_Arr_Rel_Insert_Input;
  hypertask_field_option_avg_fields: Hypertask_Field_Option_Avg_Fields;
  hypertask_field_option_avg_order_by: Hypertask_Field_Option_Avg_Order_By;
  hypertask_field_option_bool_exp: Hypertask_Field_Option_Bool_Exp;
  hypertask_field_option_inc_input: Hypertask_Field_Option_Inc_Input;
  hypertask_field_option_insert_input: Hypertask_Field_Option_Insert_Input;
  hypertask_field_option_max_fields: Hypertask_Field_Option_Max_Fields;
  hypertask_field_option_max_order_by: Hypertask_Field_Option_Max_Order_By;
  hypertask_field_option_min_fields: Hypertask_Field_Option_Min_Fields;
  hypertask_field_option_min_order_by: Hypertask_Field_Option_Min_Order_By;
  hypertask_field_option_mutation_response: Hypertask_Field_Option_Mutation_Response;
  hypertask_field_option_on_conflict: Hypertask_Field_Option_On_Conflict;
  hypertask_field_option_order_by: Hypertask_Field_Option_Order_By;
  hypertask_field_option_pk_columns_input: Hypertask_Field_Option_Pk_Columns_Input;
  hypertask_field_option_set_input: Hypertask_Field_Option_Set_Input;
  hypertask_field_option_stddev_fields: Hypertask_Field_Option_Stddev_Fields;
  hypertask_field_option_stddev_order_by: Hypertask_Field_Option_Stddev_Order_By;
  hypertask_field_option_stddev_pop_fields: Hypertask_Field_Option_Stddev_Pop_Fields;
  hypertask_field_option_stddev_pop_order_by: Hypertask_Field_Option_Stddev_Pop_Order_By;
  hypertask_field_option_stddev_samp_fields: Hypertask_Field_Option_Stddev_Samp_Fields;
  hypertask_field_option_stddev_samp_order_by: Hypertask_Field_Option_Stddev_Samp_Order_By;
  hypertask_field_option_stream_cursor_input: Hypertask_Field_Option_Stream_Cursor_Input;
  hypertask_field_option_stream_cursor_value_input: Hypertask_Field_Option_Stream_Cursor_Value_Input;
  hypertask_field_option_sum_fields: Hypertask_Field_Option_Sum_Fields;
  hypertask_field_option_sum_order_by: Hypertask_Field_Option_Sum_Order_By;
  hypertask_field_option_updates: Hypertask_Field_Option_Updates;
  hypertask_field_option_var_pop_fields: Hypertask_Field_Option_Var_Pop_Fields;
  hypertask_field_option_var_pop_order_by: Hypertask_Field_Option_Var_Pop_Order_By;
  hypertask_field_option_var_samp_fields: Hypertask_Field_Option_Var_Samp_Fields;
  hypertask_field_option_var_samp_order_by: Hypertask_Field_Option_Var_Samp_Order_By;
  hypertask_field_option_variance_fields: Hypertask_Field_Option_Variance_Fields;
  hypertask_field_option_variance_order_by: Hypertask_Field_Option_Variance_Order_By;
  hypertask_task: Hypertask_Task;
  hypertask_task_aggregate: Hypertask_Task_Aggregate;
  hypertask_task_aggregate_fields: Hypertask_Task_Aggregate_Fields;
  hypertask_task_aggregate_order_by: Hypertask_Task_Aggregate_Order_By;
  hypertask_task_arr_rel_insert_input: Hypertask_Task_Arr_Rel_Insert_Input;
  hypertask_task_bool_exp: Hypertask_Task_Bool_Exp;
  hypertask_task_insert_input: Hypertask_Task_Insert_Input;
  hypertask_task_max_fields: Hypertask_Task_Max_Fields;
  hypertask_task_max_order_by: Hypertask_Task_Max_Order_By;
  hypertask_task_min_fields: Hypertask_Task_Min_Fields;
  hypertask_task_min_order_by: Hypertask_Task_Min_Order_By;
  hypertask_task_mutation_response: Hypertask_Task_Mutation_Response;
  hypertask_task_on_conflict: Hypertask_Task_On_Conflict;
  hypertask_task_order_by: Hypertask_Task_Order_By;
  hypertask_task_pk_columns_input: Hypertask_Task_Pk_Columns_Input;
  hypertask_task_set_input: Hypertask_Task_Set_Input;
  hypertask_task_stream_cursor_input: Hypertask_Task_Stream_Cursor_Input;
  hypertask_task_stream_cursor_value_input: Hypertask_Task_Stream_Cursor_Value_Input;
  hypertask_task_updates: Hypertask_Task_Updates;
  hypertasks: Hypertasks;
  hypertasks_aggregate: Hypertasks_Aggregate;
  hypertasks_aggregate_fields: Hypertasks_Aggregate_Fields;
  hypertasks_avg_fields: Hypertasks_Avg_Fields;
  hypertasks_bool_exp: Hypertasks_Bool_Exp;
  hypertasks_inc_input: Hypertasks_Inc_Input;
  hypertasks_insert_input: Hypertasks_Insert_Input;
  hypertasks_max_fields: Hypertasks_Max_Fields;
  hypertasks_min_fields: Hypertasks_Min_Fields;
  hypertasks_mutation_response: Hypertasks_Mutation_Response;
  hypertasks_obj_rel_insert_input: Hypertasks_Obj_Rel_Insert_Input;
  hypertasks_on_conflict: Hypertasks_On_Conflict;
  hypertasks_order_by: Hypertasks_Order_By;
  hypertasks_pk_columns_input: Hypertasks_Pk_Columns_Input;
  hypertasks_set_input: Hypertasks_Set_Input;
  hypertasks_stddev_fields: Hypertasks_Stddev_Fields;
  hypertasks_stddev_pop_fields: Hypertasks_Stddev_Pop_Fields;
  hypertasks_stddev_samp_fields: Hypertasks_Stddev_Samp_Fields;
  hypertasks_stream_cursor_input: Hypertasks_Stream_Cursor_Input;
  hypertasks_stream_cursor_value_input: Hypertasks_Stream_Cursor_Value_Input;
  hypertasks_sum_fields: Hypertasks_Sum_Fields;
  hypertasks_updates: Hypertasks_Updates;
  hypertasks_var_pop_fields: Hypertasks_Var_Pop_Fields;
  hypertasks_var_samp_fields: Hypertasks_Var_Samp_Fields;
  hypertasks_variance_fields: Hypertasks_Variance_Fields;
  instruction_translations: Instruction_Translations;
  instruction_translations_aggregate_order_by: Instruction_Translations_Aggregate_Order_By;
  instruction_translations_arr_rel_insert_input: Instruction_Translations_Arr_Rel_Insert_Input;
  instruction_translations_bool_exp: Instruction_Translations_Bool_Exp;
  instruction_translations_insert_input: Instruction_Translations_Insert_Input;
  instruction_translations_max_order_by: Instruction_Translations_Max_Order_By;
  instruction_translations_min_order_by: Instruction_Translations_Min_Order_By;
  instruction_translations_mutation_response: Instruction_Translations_Mutation_Response;
  instruction_translations_on_conflict: Instruction_Translations_On_Conflict;
  instruction_translations_order_by: Instruction_Translations_Order_By;
  instruction_translations_pk_columns_input: Instruction_Translations_Pk_Columns_Input;
  instruction_translations_set_input: Instruction_Translations_Set_Input;
  instruction_translations_stream_cursor_input: Instruction_Translations_Stream_Cursor_Input;
  instruction_translations_stream_cursor_value_input: Instruction_Translations_Stream_Cursor_Value_Input;
  instruction_translations_updates: Instruction_Translations_Updates;
  integration_test_expectation_media: Integration_Test_Expectation_Media;
  integration_test_expectation_media_aggregate: Integration_Test_Expectation_Media_Aggregate;
  integration_test_expectation_media_aggregate_fields: Integration_Test_Expectation_Media_Aggregate_Fields;
  integration_test_expectation_media_aggregate_order_by: Integration_Test_Expectation_Media_Aggregate_Order_By;
  integration_test_expectation_media_arr_rel_insert_input: Integration_Test_Expectation_Media_Arr_Rel_Insert_Input;
  integration_test_expectation_media_bool_exp: Integration_Test_Expectation_Media_Bool_Exp;
  integration_test_expectation_media_insert_input: Integration_Test_Expectation_Media_Insert_Input;
  integration_test_expectation_media_max_fields: Integration_Test_Expectation_Media_Max_Fields;
  integration_test_expectation_media_max_order_by: Integration_Test_Expectation_Media_Max_Order_By;
  integration_test_expectation_media_min_fields: Integration_Test_Expectation_Media_Min_Fields;
  integration_test_expectation_media_min_order_by: Integration_Test_Expectation_Media_Min_Order_By;
  integration_test_expectation_media_mutation_response: Integration_Test_Expectation_Media_Mutation_Response;
  integration_test_expectation_media_on_conflict: Integration_Test_Expectation_Media_On_Conflict;
  integration_test_expectation_media_order_by: Integration_Test_Expectation_Media_Order_By;
  integration_test_expectation_media_pk_columns_input: Integration_Test_Expectation_Media_Pk_Columns_Input;
  integration_test_expectation_media_set_input: Integration_Test_Expectation_Media_Set_Input;
  integration_test_expectation_media_stream_cursor_input: Integration_Test_Expectation_Media_Stream_Cursor_Input;
  integration_test_expectation_media_stream_cursor_value_input: Integration_Test_Expectation_Media_Stream_Cursor_Value_Input;
  integration_test_expectation_media_updates: Integration_Test_Expectation_Media_Updates;
  integration_test_expectation_step: Integration_Test_Expectation_Step;
  integration_test_expectation_step_aggregate: Integration_Test_Expectation_Step_Aggregate;
  integration_test_expectation_step_aggregate_fields: Integration_Test_Expectation_Step_Aggregate_Fields;
  integration_test_expectation_step_aggregate_order_by: Integration_Test_Expectation_Step_Aggregate_Order_By;
  integration_test_expectation_step_arr_rel_insert_input: Integration_Test_Expectation_Step_Arr_Rel_Insert_Input;
  integration_test_expectation_step_bool_exp: Integration_Test_Expectation_Step_Bool_Exp;
  integration_test_expectation_step_insert_input: Integration_Test_Expectation_Step_Insert_Input;
  integration_test_expectation_step_max_fields: Integration_Test_Expectation_Step_Max_Fields;
  integration_test_expectation_step_max_order_by: Integration_Test_Expectation_Step_Max_Order_By;
  integration_test_expectation_step_min_fields: Integration_Test_Expectation_Step_Min_Fields;
  integration_test_expectation_step_min_order_by: Integration_Test_Expectation_Step_Min_Order_By;
  integration_test_expectation_step_mutation_response: Integration_Test_Expectation_Step_Mutation_Response;
  integration_test_expectation_step_obj_rel_insert_input: Integration_Test_Expectation_Step_Obj_Rel_Insert_Input;
  integration_test_expectation_step_on_conflict: Integration_Test_Expectation_Step_On_Conflict;
  integration_test_expectation_step_order_by: Integration_Test_Expectation_Step_Order_By;
  integration_test_expectation_step_stream_cursor_input: Integration_Test_Expectation_Step_Stream_Cursor_Input;
  integration_test_expectation_step_stream_cursor_value_input: Integration_Test_Expectation_Step_Stream_Cursor_Value_Input;
  integration_test_expectation_translation: Integration_Test_Expectation_Translation;
  integration_test_expectation_translation_aggregate: Integration_Test_Expectation_Translation_Aggregate;
  integration_test_expectation_translation_aggregate_fields: Integration_Test_Expectation_Translation_Aggregate_Fields;
  integration_test_expectation_translation_aggregate_order_by: Integration_Test_Expectation_Translation_Aggregate_Order_By;
  integration_test_expectation_translation_arr_rel_insert_input: Integration_Test_Expectation_Translation_Arr_Rel_Insert_Input;
  integration_test_expectation_translation_bool_exp: Integration_Test_Expectation_Translation_Bool_Exp;
  integration_test_expectation_translation_insert_input: Integration_Test_Expectation_Translation_Insert_Input;
  integration_test_expectation_translation_max_fields: Integration_Test_Expectation_Translation_Max_Fields;
  integration_test_expectation_translation_max_order_by: Integration_Test_Expectation_Translation_Max_Order_By;
  integration_test_expectation_translation_min_fields: Integration_Test_Expectation_Translation_Min_Fields;
  integration_test_expectation_translation_min_order_by: Integration_Test_Expectation_Translation_Min_Order_By;
  integration_test_expectation_translation_mutation_response: Integration_Test_Expectation_Translation_Mutation_Response;
  integration_test_expectation_translation_on_conflict: Integration_Test_Expectation_Translation_On_Conflict;
  integration_test_expectation_translation_order_by: Integration_Test_Expectation_Translation_Order_By;
  integration_test_expectation_translation_pk_columns_input: Integration_Test_Expectation_Translation_Pk_Columns_Input;
  integration_test_expectation_translation_set_input: Integration_Test_Expectation_Translation_Set_Input;
  integration_test_expectation_translation_stream_cursor_input: Integration_Test_Expectation_Translation_Stream_Cursor_Input;
  integration_test_expectation_translation_stream_cursor_value_input: Integration_Test_Expectation_Translation_Stream_Cursor_Value_Input;
  integration_test_expectation_translation_updates: Integration_Test_Expectation_Translation_Updates;
  integration_test_expectations: Integration_Test_Expectations;
  integration_test_expectations_aggregate: Integration_Test_Expectations_Aggregate;
  integration_test_expectations_aggregate_fields: Integration_Test_Expectations_Aggregate_Fields;
  integration_test_expectations_bool_exp: Integration_Test_Expectations_Bool_Exp;
  integration_test_expectations_insert_input: Integration_Test_Expectations_Insert_Input;
  integration_test_expectations_max_fields: Integration_Test_Expectations_Max_Fields;
  integration_test_expectations_min_fields: Integration_Test_Expectations_Min_Fields;
  integration_test_expectations_mutation_response: Integration_Test_Expectations_Mutation_Response;
  integration_test_expectations_obj_rel_insert_input: Integration_Test_Expectations_Obj_Rel_Insert_Input;
  integration_test_expectations_on_conflict: Integration_Test_Expectations_On_Conflict;
  integration_test_expectations_order_by: Integration_Test_Expectations_Order_By;
  integration_test_expectations_pk_columns_input: Integration_Test_Expectations_Pk_Columns_Input;
  integration_test_expectations_set_input: Integration_Test_Expectations_Set_Input;
  integration_test_expectations_stream_cursor_input: Integration_Test_Expectations_Stream_Cursor_Input;
  integration_test_expectations_stream_cursor_value_input: Integration_Test_Expectations_Stream_Cursor_Value_Input;
  integration_test_expectations_updates: Integration_Test_Expectations_Updates;
  integration_test_instruction_media: Integration_Test_Instruction_Media;
  integration_test_instruction_media_aggregate: Integration_Test_Instruction_Media_Aggregate;
  integration_test_instruction_media_aggregate_fields: Integration_Test_Instruction_Media_Aggregate_Fields;
  integration_test_instruction_media_aggregate_order_by: Integration_Test_Instruction_Media_Aggregate_Order_By;
  integration_test_instruction_media_arr_rel_insert_input: Integration_Test_Instruction_Media_Arr_Rel_Insert_Input;
  integration_test_instruction_media_bool_exp: Integration_Test_Instruction_Media_Bool_Exp;
  integration_test_instruction_media_insert_input: Integration_Test_Instruction_Media_Insert_Input;
  integration_test_instruction_media_max_fields: Integration_Test_Instruction_Media_Max_Fields;
  integration_test_instruction_media_max_order_by: Integration_Test_Instruction_Media_Max_Order_By;
  integration_test_instruction_media_min_fields: Integration_Test_Instruction_Media_Min_Fields;
  integration_test_instruction_media_min_order_by: Integration_Test_Instruction_Media_Min_Order_By;
  integration_test_instruction_media_mutation_response: Integration_Test_Instruction_Media_Mutation_Response;
  integration_test_instruction_media_on_conflict: Integration_Test_Instruction_Media_On_Conflict;
  integration_test_instruction_media_order_by: Integration_Test_Instruction_Media_Order_By;
  integration_test_instruction_media_pk_columns_input: Integration_Test_Instruction_Media_Pk_Columns_Input;
  integration_test_instruction_media_set_input: Integration_Test_Instruction_Media_Set_Input;
  integration_test_instruction_media_stream_cursor_input: Integration_Test_Instruction_Media_Stream_Cursor_Input;
  integration_test_instruction_media_stream_cursor_value_input: Integration_Test_Instruction_Media_Stream_Cursor_Value_Input;
  integration_test_instruction_media_updates: Integration_Test_Instruction_Media_Updates;
  integration_test_instruction_translation: Integration_Test_Instruction_Translation;
  integration_test_instruction_translation_aggregate: Integration_Test_Instruction_Translation_Aggregate;
  integration_test_instruction_translation_aggregate_fields: Integration_Test_Instruction_Translation_Aggregate_Fields;
  integration_test_instruction_translation_aggregate_order_by: Integration_Test_Instruction_Translation_Aggregate_Order_By;
  integration_test_instruction_translation_arr_rel_insert_input: Integration_Test_Instruction_Translation_Arr_Rel_Insert_Input;
  integration_test_instruction_translation_bool_exp: Integration_Test_Instruction_Translation_Bool_Exp;
  integration_test_instruction_translation_insert_input: Integration_Test_Instruction_Translation_Insert_Input;
  integration_test_instruction_translation_max_fields: Integration_Test_Instruction_Translation_Max_Fields;
  integration_test_instruction_translation_max_order_by: Integration_Test_Instruction_Translation_Max_Order_By;
  integration_test_instruction_translation_min_fields: Integration_Test_Instruction_Translation_Min_Fields;
  integration_test_instruction_translation_min_order_by: Integration_Test_Instruction_Translation_Min_Order_By;
  integration_test_instruction_translation_mutation_response: Integration_Test_Instruction_Translation_Mutation_Response;
  integration_test_instruction_translation_on_conflict: Integration_Test_Instruction_Translation_On_Conflict;
  integration_test_instruction_translation_order_by: Integration_Test_Instruction_Translation_Order_By;
  integration_test_instruction_translation_pk_columns_input: Integration_Test_Instruction_Translation_Pk_Columns_Input;
  integration_test_instruction_translation_set_input: Integration_Test_Instruction_Translation_Set_Input;
  integration_test_instruction_translation_stream_cursor_input: Integration_Test_Instruction_Translation_Stream_Cursor_Input;
  integration_test_instruction_translation_stream_cursor_value_input: Integration_Test_Instruction_Translation_Stream_Cursor_Value_Input;
  integration_test_instruction_translation_updates: Integration_Test_Instruction_Translation_Updates;
  integration_test_instructions: Integration_Test_Instructions;
  integration_test_instructions_aggregate: Integration_Test_Instructions_Aggregate;
  integration_test_instructions_aggregate_fields: Integration_Test_Instructions_Aggregate_Fields;
  integration_test_instructions_aggregate_order_by: Integration_Test_Instructions_Aggregate_Order_By;
  integration_test_instructions_arr_rel_insert_input: Integration_Test_Instructions_Arr_Rel_Insert_Input;
  integration_test_instructions_bool_exp: Integration_Test_Instructions_Bool_Exp;
  integration_test_instructions_insert_input: Integration_Test_Instructions_Insert_Input;
  integration_test_instructions_max_fields: Integration_Test_Instructions_Max_Fields;
  integration_test_instructions_max_order_by: Integration_Test_Instructions_Max_Order_By;
  integration_test_instructions_min_fields: Integration_Test_Instructions_Min_Fields;
  integration_test_instructions_min_order_by: Integration_Test_Instructions_Min_Order_By;
  integration_test_instructions_mutation_response: Integration_Test_Instructions_Mutation_Response;
  integration_test_instructions_obj_rel_insert_input: Integration_Test_Instructions_Obj_Rel_Insert_Input;
  integration_test_instructions_on_conflict: Integration_Test_Instructions_On_Conflict;
  integration_test_instructions_order_by: Integration_Test_Instructions_Order_By;
  integration_test_instructions_pk_columns_input: Integration_Test_Instructions_Pk_Columns_Input;
  integration_test_instructions_set_input: Integration_Test_Instructions_Set_Input;
  integration_test_instructions_stream_cursor_input: Integration_Test_Instructions_Stream_Cursor_Input;
  integration_test_instructions_stream_cursor_value_input: Integration_Test_Instructions_Stream_Cursor_Value_Input;
  integration_test_instructions_updates: Integration_Test_Instructions_Updates;
  integration_test_observances: Integration_Test_Observances;
  integration_test_observances_aggregate: Integration_Test_Observances_Aggregate;
  integration_test_observances_aggregate_fields: Integration_Test_Observances_Aggregate_Fields;
  integration_test_observances_aggregate_order_by: Integration_Test_Observances_Aggregate_Order_By;
  integration_test_observances_arr_rel_insert_input: Integration_Test_Observances_Arr_Rel_Insert_Input;
  integration_test_observances_bool_exp: Integration_Test_Observances_Bool_Exp;
  integration_test_observances_insert_input: Integration_Test_Observances_Insert_Input;
  integration_test_observances_max_fields: Integration_Test_Observances_Max_Fields;
  integration_test_observances_max_order_by: Integration_Test_Observances_Max_Order_By;
  integration_test_observances_min_fields: Integration_Test_Observances_Min_Fields;
  integration_test_observances_min_order_by: Integration_Test_Observances_Min_Order_By;
  integration_test_observances_mutation_response: Integration_Test_Observances_Mutation_Response;
  integration_test_observances_obj_rel_insert_input: Integration_Test_Observances_Obj_Rel_Insert_Input;
  integration_test_observances_on_conflict: Integration_Test_Observances_On_Conflict;
  integration_test_observances_order_by: Integration_Test_Observances_Order_By;
  integration_test_observances_pk_columns_input: Integration_Test_Observances_Pk_Columns_Input;
  integration_test_observances_set_input: Integration_Test_Observances_Set_Input;
  integration_test_observances_stream_cursor_input: Integration_Test_Observances_Stream_Cursor_Input;
  integration_test_observances_stream_cursor_value_input: Integration_Test_Observances_Stream_Cursor_Value_Input;
  integration_test_observances_updates: Integration_Test_Observances_Updates;
  integration_test_observations: Integration_Test_Observations;
  integration_test_observations_aggregate: Integration_Test_Observations_Aggregate;
  integration_test_observations_aggregate_fields: Integration_Test_Observations_Aggregate_Fields;
  integration_test_observations_aggregate_order_by: Integration_Test_Observations_Aggregate_Order_By;
  integration_test_observations_arr_rel_insert_input: Integration_Test_Observations_Arr_Rel_Insert_Input;
  integration_test_observations_bool_exp: Integration_Test_Observations_Bool_Exp;
  integration_test_observations_insert_input: Integration_Test_Observations_Insert_Input;
  integration_test_observations_max_fields: Integration_Test_Observations_Max_Fields;
  integration_test_observations_max_order_by: Integration_Test_Observations_Max_Order_By;
  integration_test_observations_min_fields: Integration_Test_Observations_Min_Fields;
  integration_test_observations_min_order_by: Integration_Test_Observations_Min_Order_By;
  integration_test_observations_mutation_response: Integration_Test_Observations_Mutation_Response;
  integration_test_observations_on_conflict: Integration_Test_Observations_On_Conflict;
  integration_test_observations_order_by: Integration_Test_Observations_Order_By;
  integration_test_observations_stream_cursor_input: Integration_Test_Observations_Stream_Cursor_Input;
  integration_test_observations_stream_cursor_value_input: Integration_Test_Observations_Stream_Cursor_Value_Input;
  integration_test_project_sequence: Integration_Test_Project_Sequence;
  integration_test_project_sequence_aggregate: Integration_Test_Project_Sequence_Aggregate;
  integration_test_project_sequence_aggregate_fields: Integration_Test_Project_Sequence_Aggregate_Fields;
  integration_test_project_sequence_aggregate_order_by: Integration_Test_Project_Sequence_Aggregate_Order_By;
  integration_test_project_sequence_arr_rel_insert_input: Integration_Test_Project_Sequence_Arr_Rel_Insert_Input;
  integration_test_project_sequence_bool_exp: Integration_Test_Project_Sequence_Bool_Exp;
  integration_test_project_sequence_insert_input: Integration_Test_Project_Sequence_Insert_Input;
  integration_test_project_sequence_max_fields: Integration_Test_Project_Sequence_Max_Fields;
  integration_test_project_sequence_max_order_by: Integration_Test_Project_Sequence_Max_Order_By;
  integration_test_project_sequence_min_fields: Integration_Test_Project_Sequence_Min_Fields;
  integration_test_project_sequence_min_order_by: Integration_Test_Project_Sequence_Min_Order_By;
  integration_test_project_sequence_mutation_response: Integration_Test_Project_Sequence_Mutation_Response;
  integration_test_project_sequence_on_conflict: Integration_Test_Project_Sequence_On_Conflict;
  integration_test_project_sequence_order_by: Integration_Test_Project_Sequence_Order_By;
  integration_test_project_sequence_pk_columns_input: Integration_Test_Project_Sequence_Pk_Columns_Input;
  integration_test_project_sequence_set_input: Integration_Test_Project_Sequence_Set_Input;
  integration_test_project_sequence_stream_cursor_input: Integration_Test_Project_Sequence_Stream_Cursor_Input;
  integration_test_project_sequence_stream_cursor_value_input: Integration_Test_Project_Sequence_Stream_Cursor_Value_Input;
  integration_test_project_sequence_updates: Integration_Test_Project_Sequence_Updates;
  integration_test_sequences: Integration_Test_Sequences;
  integration_test_sequences_aggregate: Integration_Test_Sequences_Aggregate;
  integration_test_sequences_aggregate_fields: Integration_Test_Sequences_Aggregate_Fields;
  integration_test_sequences_bool_exp: Integration_Test_Sequences_Bool_Exp;
  integration_test_sequences_insert_input: Integration_Test_Sequences_Insert_Input;
  integration_test_sequences_max_fields: Integration_Test_Sequences_Max_Fields;
  integration_test_sequences_min_fields: Integration_Test_Sequences_Min_Fields;
  integration_test_sequences_mutation_response: Integration_Test_Sequences_Mutation_Response;
  integration_test_sequences_obj_rel_insert_input: Integration_Test_Sequences_Obj_Rel_Insert_Input;
  integration_test_sequences_on_conflict: Integration_Test_Sequences_On_Conflict;
  integration_test_sequences_order_by: Integration_Test_Sequences_Order_By;
  integration_test_sequences_pk_columns_input: Integration_Test_Sequences_Pk_Columns_Input;
  integration_test_sequences_set_input: Integration_Test_Sequences_Set_Input;
  integration_test_sequences_stream_cursor_input: Integration_Test_Sequences_Stream_Cursor_Input;
  integration_test_sequences_stream_cursor_value_input: Integration_Test_Sequences_Stream_Cursor_Value_Input;
  integration_test_sequences_updates: Integration_Test_Sequences_Updates;
  integration_test_steps: Integration_Test_Steps;
  integration_test_steps_aggregate: Integration_Test_Steps_Aggregate;
  integration_test_steps_aggregate_fields: Integration_Test_Steps_Aggregate_Fields;
  integration_test_steps_aggregate_order_by: Integration_Test_Steps_Aggregate_Order_By;
  integration_test_steps_arr_rel_insert_input: Integration_Test_Steps_Arr_Rel_Insert_Input;
  integration_test_steps_avg_fields: Integration_Test_Steps_Avg_Fields;
  integration_test_steps_avg_order_by: Integration_Test_Steps_Avg_Order_By;
  integration_test_steps_bool_exp: Integration_Test_Steps_Bool_Exp;
  integration_test_steps_insert_input: Integration_Test_Steps_Insert_Input;
  integration_test_steps_max_fields: Integration_Test_Steps_Max_Fields;
  integration_test_steps_max_order_by: Integration_Test_Steps_Max_Order_By;
  integration_test_steps_min_fields: Integration_Test_Steps_Min_Fields;
  integration_test_steps_min_order_by: Integration_Test_Steps_Min_Order_By;
  integration_test_steps_mutation_response: Integration_Test_Steps_Mutation_Response;
  integration_test_steps_obj_rel_insert_input: Integration_Test_Steps_Obj_Rel_Insert_Input;
  integration_test_steps_on_conflict: Integration_Test_Steps_On_Conflict;
  integration_test_steps_order_by: Integration_Test_Steps_Order_By;
  integration_test_steps_stddev_fields: Integration_Test_Steps_Stddev_Fields;
  integration_test_steps_stddev_order_by: Integration_Test_Steps_Stddev_Order_By;
  integration_test_steps_stddev_pop_fields: Integration_Test_Steps_Stddev_Pop_Fields;
  integration_test_steps_stddev_pop_order_by: Integration_Test_Steps_Stddev_Pop_Order_By;
  integration_test_steps_stddev_samp_fields: Integration_Test_Steps_Stddev_Samp_Fields;
  integration_test_steps_stddev_samp_order_by: Integration_Test_Steps_Stddev_Samp_Order_By;
  integration_test_steps_stream_cursor_input: Integration_Test_Steps_Stream_Cursor_Input;
  integration_test_steps_stream_cursor_value_input: Integration_Test_Steps_Stream_Cursor_Value_Input;
  integration_test_steps_sum_fields: Integration_Test_Steps_Sum_Fields;
  integration_test_steps_sum_order_by: Integration_Test_Steps_Sum_Order_By;
  integration_test_steps_var_pop_fields: Integration_Test_Steps_Var_Pop_Fields;
  integration_test_steps_var_pop_order_by: Integration_Test_Steps_Var_Pop_Order_By;
  integration_test_steps_var_samp_fields: Integration_Test_Steps_Var_Samp_Fields;
  integration_test_steps_var_samp_order_by: Integration_Test_Steps_Var_Samp_Order_By;
  integration_test_steps_variance_fields: Integration_Test_Steps_Variance_Fields;
  integration_test_steps_variance_order_by: Integration_Test_Steps_Variance_Order_By;
  json: Scalars['json'];
  json_comparison_exp: Json_Comparison_Exp;
  jsonb: Scalars['jsonb'];
  jsonb_cast_exp: Jsonb_Cast_Exp;
  jsonb_comparison_exp: Jsonb_Comparison_Exp;
  languages: Languages;
  languages_bool_exp: Languages_Bool_Exp;
  languages_mutation_response: Languages_Mutation_Response;
  languages_order_by: Languages_Order_By;
  languages_pk_columns_input: Languages_Pk_Columns_Input;
  languages_set_input: Languages_Set_Input;
  languages_stream_cursor_input: Languages_Stream_Cursor_Input;
  languages_stream_cursor_value_input: Languages_Stream_Cursor_Value_Input;
  languages_updates: Languages_Updates;
  machine_learning_blobs: Machine_Learning_Blobs;
  machine_learning_blobs_aggregate: Machine_Learning_Blobs_Aggregate;
  machine_learning_blobs_aggregate_fields: Machine_Learning_Blobs_Aggregate_Fields;
  machine_learning_blobs_avg_fields: Machine_Learning_Blobs_Avg_Fields;
  machine_learning_blobs_bool_exp: Machine_Learning_Blobs_Bool_Exp;
  machine_learning_blobs_inc_input: Machine_Learning_Blobs_Inc_Input;
  machine_learning_blobs_insert_input: Machine_Learning_Blobs_Insert_Input;
  machine_learning_blobs_max_fields: Machine_Learning_Blobs_Max_Fields;
  machine_learning_blobs_min_fields: Machine_Learning_Blobs_Min_Fields;
  machine_learning_blobs_mutation_response: Machine_Learning_Blobs_Mutation_Response;
  machine_learning_blobs_obj_rel_insert_input: Machine_Learning_Blobs_Obj_Rel_Insert_Input;
  machine_learning_blobs_on_conflict: Machine_Learning_Blobs_On_Conflict;
  machine_learning_blobs_order_by: Machine_Learning_Blobs_Order_By;
  machine_learning_blobs_pk_columns_input: Machine_Learning_Blobs_Pk_Columns_Input;
  machine_learning_blobs_set_input: Machine_Learning_Blobs_Set_Input;
  machine_learning_blobs_stddev_fields: Machine_Learning_Blobs_Stddev_Fields;
  machine_learning_blobs_stddev_pop_fields: Machine_Learning_Blobs_Stddev_Pop_Fields;
  machine_learning_blobs_stddev_samp_fields: Machine_Learning_Blobs_Stddev_Samp_Fields;
  machine_learning_blobs_stream_cursor_input: Machine_Learning_Blobs_Stream_Cursor_Input;
  machine_learning_blobs_stream_cursor_value_input: Machine_Learning_Blobs_Stream_Cursor_Value_Input;
  machine_learning_blobs_sum_fields: Machine_Learning_Blobs_Sum_Fields;
  machine_learning_blobs_updates: Machine_Learning_Blobs_Updates;
  machine_learning_blobs_var_pop_fields: Machine_Learning_Blobs_Var_Pop_Fields;
  machine_learning_blobs_var_samp_fields: Machine_Learning_Blobs_Var_Samp_Fields;
  machine_learning_blobs_variance_fields: Machine_Learning_Blobs_Variance_Fields;
  machine_learning_dataset_feature: Machine_Learning_Dataset_Feature;
  machine_learning_dataset_feature_aggregate: Machine_Learning_Dataset_Feature_Aggregate;
  machine_learning_dataset_feature_aggregate_fields: Machine_Learning_Dataset_Feature_Aggregate_Fields;
  machine_learning_dataset_feature_aggregate_order_by: Machine_Learning_Dataset_Feature_Aggregate_Order_By;
  machine_learning_dataset_feature_arr_rel_insert_input: Machine_Learning_Dataset_Feature_Arr_Rel_Insert_Input;
  machine_learning_dataset_feature_bool_exp: Machine_Learning_Dataset_Feature_Bool_Exp;
  machine_learning_dataset_feature_insert_input: Machine_Learning_Dataset_Feature_Insert_Input;
  machine_learning_dataset_feature_max_fields: Machine_Learning_Dataset_Feature_Max_Fields;
  machine_learning_dataset_feature_max_order_by: Machine_Learning_Dataset_Feature_Max_Order_By;
  machine_learning_dataset_feature_min_fields: Machine_Learning_Dataset_Feature_Min_Fields;
  machine_learning_dataset_feature_min_order_by: Machine_Learning_Dataset_Feature_Min_Order_By;
  machine_learning_dataset_feature_mutation_response: Machine_Learning_Dataset_Feature_Mutation_Response;
  machine_learning_dataset_feature_on_conflict: Machine_Learning_Dataset_Feature_On_Conflict;
  machine_learning_dataset_feature_order_by: Machine_Learning_Dataset_Feature_Order_By;
  machine_learning_dataset_feature_pk_columns_input: Machine_Learning_Dataset_Feature_Pk_Columns_Input;
  machine_learning_dataset_feature_set_input: Machine_Learning_Dataset_Feature_Set_Input;
  machine_learning_dataset_feature_stream_cursor_input: Machine_Learning_Dataset_Feature_Stream_Cursor_Input;
  machine_learning_dataset_feature_stream_cursor_value_input: Machine_Learning_Dataset_Feature_Stream_Cursor_Value_Input;
  machine_learning_dataset_feature_updates: Machine_Learning_Dataset_Feature_Updates;
  machine_learning_datasets: Machine_Learning_Datasets;
  machine_learning_datasets_aggregate: Machine_Learning_Datasets_Aggregate;
  machine_learning_datasets_aggregate_fields: Machine_Learning_Datasets_Aggregate_Fields;
  machine_learning_datasets_avg_fields: Machine_Learning_Datasets_Avg_Fields;
  machine_learning_datasets_bool_exp: Machine_Learning_Datasets_Bool_Exp;
  machine_learning_datasets_inc_input: Machine_Learning_Datasets_Inc_Input;
  machine_learning_datasets_insert_input: Machine_Learning_Datasets_Insert_Input;
  machine_learning_datasets_max_fields: Machine_Learning_Datasets_Max_Fields;
  machine_learning_datasets_min_fields: Machine_Learning_Datasets_Min_Fields;
  machine_learning_datasets_mutation_response: Machine_Learning_Datasets_Mutation_Response;
  machine_learning_datasets_obj_rel_insert_input: Machine_Learning_Datasets_Obj_Rel_Insert_Input;
  machine_learning_datasets_on_conflict: Machine_Learning_Datasets_On_Conflict;
  machine_learning_datasets_order_by: Machine_Learning_Datasets_Order_By;
  machine_learning_datasets_pk_columns_input: Machine_Learning_Datasets_Pk_Columns_Input;
  machine_learning_datasets_set_input: Machine_Learning_Datasets_Set_Input;
  machine_learning_datasets_stddev_fields: Machine_Learning_Datasets_Stddev_Fields;
  machine_learning_datasets_stddev_pop_fields: Machine_Learning_Datasets_Stddev_Pop_Fields;
  machine_learning_datasets_stddev_samp_fields: Machine_Learning_Datasets_Stddev_Samp_Fields;
  machine_learning_datasets_stream_cursor_input: Machine_Learning_Datasets_Stream_Cursor_Input;
  machine_learning_datasets_stream_cursor_value_input: Machine_Learning_Datasets_Stream_Cursor_Value_Input;
  machine_learning_datasets_sum_fields: Machine_Learning_Datasets_Sum_Fields;
  machine_learning_datasets_updates: Machine_Learning_Datasets_Updates;
  machine_learning_datasets_var_pop_fields: Machine_Learning_Datasets_Var_Pop_Fields;
  machine_learning_datasets_var_samp_fields: Machine_Learning_Datasets_Var_Samp_Fields;
  machine_learning_datasets_variance_fields: Machine_Learning_Datasets_Variance_Fields;
  machine_learning_features: Machine_Learning_Features;
  machine_learning_features_bool_exp: Machine_Learning_Features_Bool_Exp;
  machine_learning_features_insert_input: Machine_Learning_Features_Insert_Input;
  machine_learning_features_mutation_response: Machine_Learning_Features_Mutation_Response;
  machine_learning_features_obj_rel_insert_input: Machine_Learning_Features_Obj_Rel_Insert_Input;
  machine_learning_features_on_conflict: Machine_Learning_Features_On_Conflict;
  machine_learning_features_order_by: Machine_Learning_Features_Order_By;
  machine_learning_features_pk_columns_input: Machine_Learning_Features_Pk_Columns_Input;
  machine_learning_features_set_input: Machine_Learning_Features_Set_Input;
  machine_learning_features_stream_cursor_input: Machine_Learning_Features_Stream_Cursor_Input;
  machine_learning_features_stream_cursor_value_input: Machine_Learning_Features_Stream_Cursor_Value_Input;
  machine_learning_features_updates: Machine_Learning_Features_Updates;
  machine_learning_model_blob: Machine_Learning_Model_Blob;
  machine_learning_model_blob_aggregate_order_by: Machine_Learning_Model_Blob_Aggregate_Order_By;
  machine_learning_model_blob_arr_rel_insert_input: Machine_Learning_Model_Blob_Arr_Rel_Insert_Input;
  machine_learning_model_blob_bool_exp: Machine_Learning_Model_Blob_Bool_Exp;
  machine_learning_model_blob_insert_input: Machine_Learning_Model_Blob_Insert_Input;
  machine_learning_model_blob_max_order_by: Machine_Learning_Model_Blob_Max_Order_By;
  machine_learning_model_blob_min_order_by: Machine_Learning_Model_Blob_Min_Order_By;
  machine_learning_model_blob_mutation_response: Machine_Learning_Model_Blob_Mutation_Response;
  machine_learning_model_blob_on_conflict: Machine_Learning_Model_Blob_On_Conflict;
  machine_learning_model_blob_order_by: Machine_Learning_Model_Blob_Order_By;
  machine_learning_model_blob_pk_columns_input: Machine_Learning_Model_Blob_Pk_Columns_Input;
  machine_learning_model_blob_set_input: Machine_Learning_Model_Blob_Set_Input;
  machine_learning_model_blob_stream_cursor_input: Machine_Learning_Model_Blob_Stream_Cursor_Input;
  machine_learning_model_blob_stream_cursor_value_input: Machine_Learning_Model_Blob_Stream_Cursor_Value_Input;
  machine_learning_model_blob_updates: Machine_Learning_Model_Blob_Updates;
  machine_learning_model_dataset: Machine_Learning_Model_Dataset;
  machine_learning_model_dataset_aggregate_order_by: Machine_Learning_Model_Dataset_Aggregate_Order_By;
  machine_learning_model_dataset_arr_rel_insert_input: Machine_Learning_Model_Dataset_Arr_Rel_Insert_Input;
  machine_learning_model_dataset_bool_exp: Machine_Learning_Model_Dataset_Bool_Exp;
  machine_learning_model_dataset_insert_input: Machine_Learning_Model_Dataset_Insert_Input;
  machine_learning_model_dataset_max_order_by: Machine_Learning_Model_Dataset_Max_Order_By;
  machine_learning_model_dataset_min_order_by: Machine_Learning_Model_Dataset_Min_Order_By;
  machine_learning_model_dataset_mutation_response: Machine_Learning_Model_Dataset_Mutation_Response;
  machine_learning_model_dataset_on_conflict: Machine_Learning_Model_Dataset_On_Conflict;
  machine_learning_model_dataset_order_by: Machine_Learning_Model_Dataset_Order_By;
  machine_learning_model_dataset_pk_columns_input: Machine_Learning_Model_Dataset_Pk_Columns_Input;
  machine_learning_model_dataset_set_input: Machine_Learning_Model_Dataset_Set_Input;
  machine_learning_model_dataset_stream_cursor_input: Machine_Learning_Model_Dataset_Stream_Cursor_Input;
  machine_learning_model_dataset_stream_cursor_value_input: Machine_Learning_Model_Dataset_Stream_Cursor_Value_Input;
  machine_learning_model_dataset_updates: Machine_Learning_Model_Dataset_Updates;
  machine_learning_model_recording: Machine_Learning_Model_Recording;
  machine_learning_model_recording_aggregate: Machine_Learning_Model_Recording_Aggregate;
  machine_learning_model_recording_aggregate_fields: Machine_Learning_Model_Recording_Aggregate_Fields;
  machine_learning_model_recording_aggregate_order_by: Machine_Learning_Model_Recording_Aggregate_Order_By;
  machine_learning_model_recording_arr_rel_insert_input: Machine_Learning_Model_Recording_Arr_Rel_Insert_Input;
  machine_learning_model_recording_bool_exp: Machine_Learning_Model_Recording_Bool_Exp;
  machine_learning_model_recording_insert_input: Machine_Learning_Model_Recording_Insert_Input;
  machine_learning_model_recording_max_fields: Machine_Learning_Model_Recording_Max_Fields;
  machine_learning_model_recording_max_order_by: Machine_Learning_Model_Recording_Max_Order_By;
  machine_learning_model_recording_min_fields: Machine_Learning_Model_Recording_Min_Fields;
  machine_learning_model_recording_min_order_by: Machine_Learning_Model_Recording_Min_Order_By;
  machine_learning_model_recording_mutation_response: Machine_Learning_Model_Recording_Mutation_Response;
  machine_learning_model_recording_on_conflict: Machine_Learning_Model_Recording_On_Conflict;
  machine_learning_model_recording_order_by: Machine_Learning_Model_Recording_Order_By;
  machine_learning_model_recording_pk_columns_input: Machine_Learning_Model_Recording_Pk_Columns_Input;
  machine_learning_model_recording_set_input: Machine_Learning_Model_Recording_Set_Input;
  machine_learning_model_recording_stream_cursor_input: Machine_Learning_Model_Recording_Stream_Cursor_Input;
  machine_learning_model_recording_stream_cursor_value_input: Machine_Learning_Model_Recording_Stream_Cursor_Value_Input;
  machine_learning_model_recording_updates: Machine_Learning_Model_Recording_Updates;
  machine_learning_model_types: Machine_Learning_Model_Types;
  machine_learning_model_types_bool_exp: Machine_Learning_Model_Types_Bool_Exp;
  machine_learning_model_types_enum_comparison_exp: Machine_Learning_Model_Types_Enum_Comparison_Exp;
  machine_learning_model_types_order_by: Machine_Learning_Model_Types_Order_By;
  machine_learning_model_types_stream_cursor_input: Machine_Learning_Model_Types_Stream_Cursor_Input;
  machine_learning_model_types_stream_cursor_value_input: Machine_Learning_Model_Types_Stream_Cursor_Value_Input;
  machine_learning_models: Machine_Learning_Models;
  machine_learning_models_bool_exp: Machine_Learning_Models_Bool_Exp;
  machine_learning_models_inc_input: Machine_Learning_Models_Inc_Input;
  machine_learning_models_insert_input: Machine_Learning_Models_Insert_Input;
  machine_learning_models_mutation_response: Machine_Learning_Models_Mutation_Response;
  machine_learning_models_obj_rel_insert_input: Machine_Learning_Models_Obj_Rel_Insert_Input;
  machine_learning_models_on_conflict: Machine_Learning_Models_On_Conflict;
  machine_learning_models_order_by: Machine_Learning_Models_Order_By;
  machine_learning_models_pk_columns_input: Machine_Learning_Models_Pk_Columns_Input;
  machine_learning_models_set_input: Machine_Learning_Models_Set_Input;
  machine_learning_models_stream_cursor_input: Machine_Learning_Models_Stream_Cursor_Input;
  machine_learning_models_stream_cursor_value_input: Machine_Learning_Models_Stream_Cursor_Value_Input;
  machine_learning_models_updates: Machine_Learning_Models_Updates;
  media: Media;
  media_aggregate: Media_Aggregate;
  media_aggregate_fields: Media_Aggregate_Fields;
  media_aggregate_order_by: Media_Aggregate_Order_By;
  media_bool_exp: Media_Bool_Exp;
  media_insert_input: Media_Insert_Input;
  media_max_fields: Media_Max_Fields;
  media_max_order_by: Media_Max_Order_By;
  media_min_fields: Media_Min_Fields;
  media_min_order_by: Media_Min_Order_By;
  media_mutation_response: Media_Mutation_Response;
  media_obj_rel_insert_input: Media_Obj_Rel_Insert_Input;
  media_on_conflict: Media_On_Conflict;
  media_order_by: Media_Order_By;
  media_pk_columns_input: Media_Pk_Columns_Input;
  media_set_input: Media_Set_Input;
  media_stream_cursor_input: Media_Stream_Cursor_Input;
  media_stream_cursor_value_input: Media_Stream_Cursor_Value_Input;
  media_type: Media_Type;
  media_type_bool_exp: Media_Type_Bool_Exp;
  media_type_enum_comparison_exp: Media_Type_Enum_Comparison_Exp;
  media_type_order_by: Media_Type_Order_By;
  media_type_stream_cursor_input: Media_Type_Stream_Cursor_Input;
  media_type_stream_cursor_value_input: Media_Type_Stream_Cursor_Value_Input;
  media_updates: Media_Updates;
  mode_project: Mode_Project;
  mode_project_aggregate: Mode_Project_Aggregate;
  mode_project_aggregate_fields: Mode_Project_Aggregate_Fields;
  mode_project_aggregate_order_by: Mode_Project_Aggregate_Order_By;
  mode_project_arr_rel_insert_input: Mode_Project_Arr_Rel_Insert_Input;
  mode_project_bool_exp: Mode_Project_Bool_Exp;
  mode_project_insert_input: Mode_Project_Insert_Input;
  mode_project_max_fields: Mode_Project_Max_Fields;
  mode_project_max_order_by: Mode_Project_Max_Order_By;
  mode_project_min_fields: Mode_Project_Min_Fields;
  mode_project_min_order_by: Mode_Project_Min_Order_By;
  mode_project_mutation_response: Mode_Project_Mutation_Response;
  mode_project_on_conflict: Mode_Project_On_Conflict;
  mode_project_order_by: Mode_Project_Order_By;
  mode_project_pk_columns_input: Mode_Project_Pk_Columns_Input;
  mode_project_set_input: Mode_Project_Set_Input;
  mode_project_stream_cursor_input: Mode_Project_Stream_Cursor_Input;
  mode_project_stream_cursor_value_input: Mode_Project_Stream_Cursor_Value_Input;
  mode_project_updates: Mode_Project_Updates;
  model_name_hashes: Model_Name_Hashes;
  model_name_hashes_aggregate: Model_Name_Hashes_Aggregate;
  model_name_hashes_aggregate_fields: Model_Name_Hashes_Aggregate_Fields;
  model_name_hashes_aggregate_order_by: Model_Name_Hashes_Aggregate_Order_By;
  model_name_hashes_arr_rel_insert_input: Model_Name_Hashes_Arr_Rel_Insert_Input;
  model_name_hashes_bool_exp: Model_Name_Hashes_Bool_Exp;
  model_name_hashes_insert_input: Model_Name_Hashes_Insert_Input;
  model_name_hashes_max_fields: Model_Name_Hashes_Max_Fields;
  model_name_hashes_max_order_by: Model_Name_Hashes_Max_Order_By;
  model_name_hashes_min_fields: Model_Name_Hashes_Min_Fields;
  model_name_hashes_min_order_by: Model_Name_Hashes_Min_Order_By;
  model_name_hashes_mutation_response: Model_Name_Hashes_Mutation_Response;
  model_name_hashes_on_conflict: Model_Name_Hashes_On_Conflict;
  model_name_hashes_order_by: Model_Name_Hashes_Order_By;
  model_name_hashes_pk_columns_input: Model_Name_Hashes_Pk_Columns_Input;
  model_name_hashes_set_input: Model_Name_Hashes_Set_Input;
  model_name_hashes_stream_cursor_input: Model_Name_Hashes_Stream_Cursor_Input;
  model_name_hashes_stream_cursor_value_input: Model_Name_Hashes_Stream_Cursor_Value_Input;
  model_name_hashes_updates: Model_Name_Hashes_Updates;
  models: Models;
  models_aggregate: Models_Aggregate;
  models_aggregate_fields: Models_Aggregate_Fields;
  models_bool_exp: Models_Bool_Exp;
  models_insert_input: Models_Insert_Input;
  models_max_fields: Models_Max_Fields;
  models_min_fields: Models_Min_Fields;
  models_mutation_response: Models_Mutation_Response;
  models_obj_rel_insert_input: Models_Obj_Rel_Insert_Input;
  models_on_conflict: Models_On_Conflict;
  models_order_by: Models_Order_By;
  models_pk_columns_input: Models_Pk_Columns_Input;
  models_set_input: Models_Set_Input;
  models_stream_cursor_input: Models_Stream_Cursor_Input;
  models_stream_cursor_value_input: Models_Stream_Cursor_Value_Input;
  models_updates: Models_Updates;
  mutation_root: {};
  numeric: Scalars['numeric'];
  numeric_comparison_exp: Numeric_Comparison_Exp;
  opmode_profile: Opmode_Profile;
  opmode_profile_aggregate_order_by: Opmode_Profile_Aggregate_Order_By;
  opmode_profile_arr_rel_insert_input: Opmode_Profile_Arr_Rel_Insert_Input;
  opmode_profile_bool_exp: Opmode_Profile_Bool_Exp;
  opmode_profile_insert_input: Opmode_Profile_Insert_Input;
  opmode_profile_max_order_by: Opmode_Profile_Max_Order_By;
  opmode_profile_min_order_by: Opmode_Profile_Min_Order_By;
  opmode_profile_mutation_response: Opmode_Profile_Mutation_Response;
  opmode_profile_on_conflict: Opmode_Profile_On_Conflict;
  opmode_profile_order_by: Opmode_Profile_Order_By;
  opmode_profile_pk_columns_input: Opmode_Profile_Pk_Columns_Input;
  opmode_profile_set_input: Opmode_Profile_Set_Input;
  opmode_profile_stream_cursor_input: Opmode_Profile_Stream_Cursor_Input;
  opmode_profile_stream_cursor_value_input: Opmode_Profile_Stream_Cursor_Value_Input;
  opmode_profile_updates: Opmode_Profile_Updates;
  opmode_project: Opmode_Project;
  opmode_project_aggregate: Opmode_Project_Aggregate;
  opmode_project_aggregate_fields: Opmode_Project_Aggregate_Fields;
  opmode_project_aggregate_order_by: Opmode_Project_Aggregate_Order_By;
  opmode_project_arr_rel_insert_input: Opmode_Project_Arr_Rel_Insert_Input;
  opmode_project_bool_exp: Opmode_Project_Bool_Exp;
  opmode_project_insert_input: Opmode_Project_Insert_Input;
  opmode_project_max_fields: Opmode_Project_Max_Fields;
  opmode_project_max_order_by: Opmode_Project_Max_Order_By;
  opmode_project_min_fields: Opmode_Project_Min_Fields;
  opmode_project_min_order_by: Opmode_Project_Min_Order_By;
  opmode_project_mutation_response: Opmode_Project_Mutation_Response;
  opmode_project_on_conflict: Opmode_Project_On_Conflict;
  opmode_project_order_by: Opmode_Project_Order_By;
  opmode_project_pk_columns_input: Opmode_Project_Pk_Columns_Input;
  opmode_project_set_input: Opmode_Project_Set_Input;
  opmode_project_stream_cursor_input: Opmode_Project_Stream_Cursor_Input;
  opmode_project_stream_cursor_value_input: Opmode_Project_Stream_Cursor_Value_Input;
  opmode_project_updates: Opmode_Project_Updates;
  opmodes: Opmodes;
  opmodes_aggregate: Opmodes_Aggregate;
  opmodes_aggregate_fields: Opmodes_Aggregate_Fields;
  opmodes_avg_fields: Opmodes_Avg_Fields;
  opmodes_bool_exp: Opmodes_Bool_Exp;
  opmodes_inc_input: Opmodes_Inc_Input;
  opmodes_insert_input: Opmodes_Insert_Input;
  opmodes_max_fields: Opmodes_Max_Fields;
  opmodes_min_fields: Opmodes_Min_Fields;
  opmodes_mutation_response: Opmodes_Mutation_Response;
  opmodes_obj_rel_insert_input: Opmodes_Obj_Rel_Insert_Input;
  opmodes_on_conflict: Opmodes_On_Conflict;
  opmodes_order_by: Opmodes_Order_By;
  opmodes_pk_columns_input: Opmodes_Pk_Columns_Input;
  opmodes_set_input: Opmodes_Set_Input;
  opmodes_stddev_fields: Opmodes_Stddev_Fields;
  opmodes_stddev_pop_fields: Opmodes_Stddev_Pop_Fields;
  opmodes_stddev_samp_fields: Opmodes_Stddev_Samp_Fields;
  opmodes_stream_cursor_input: Opmodes_Stream_Cursor_Input;
  opmodes_stream_cursor_value_input: Opmodes_Stream_Cursor_Value_Input;
  opmodes_sum_fields: Opmodes_Sum_Fields;
  opmodes_updates: Opmodes_Updates;
  opmodes_var_pop_fields: Opmodes_Var_Pop_Fields;
  opmodes_var_samp_fields: Opmodes_Var_Samp_Fields;
  opmodes_variance_fields: Opmodes_Variance_Fields;
  option_media: Option_Media;
  option_media_aggregate: Option_Media_Aggregate;
  option_media_aggregate_fields: Option_Media_Aggregate_Fields;
  option_media_aggregate_order_by: Option_Media_Aggregate_Order_By;
  option_media_arr_rel_insert_input: Option_Media_Arr_Rel_Insert_Input;
  option_media_bool_exp: Option_Media_Bool_Exp;
  option_media_insert_input: Option_Media_Insert_Input;
  option_media_max_fields: Option_Media_Max_Fields;
  option_media_max_order_by: Option_Media_Max_Order_By;
  option_media_min_fields: Option_Media_Min_Fields;
  option_media_min_order_by: Option_Media_Min_Order_By;
  option_media_mutation_response: Option_Media_Mutation_Response;
  option_media_on_conflict: Option_Media_On_Conflict;
  option_media_order_by: Option_Media_Order_By;
  option_media_pk_columns_input: Option_Media_Pk_Columns_Input;
  option_media_set_input: Option_Media_Set_Input;
  option_media_stream_cursor_input: Option_Media_Stream_Cursor_Input;
  option_media_stream_cursor_value_input: Option_Media_Stream_Cursor_Value_Input;
  option_media_updates: Option_Media_Updates;
  option_translation: Option_Translation;
  option_translation_aggregate: Option_Translation_Aggregate;
  option_translation_aggregate_fields: Option_Translation_Aggregate_Fields;
  option_translation_aggregate_order_by: Option_Translation_Aggregate_Order_By;
  option_translation_arr_rel_insert_input: Option_Translation_Arr_Rel_Insert_Input;
  option_translation_bool_exp: Option_Translation_Bool_Exp;
  option_translation_insert_input: Option_Translation_Insert_Input;
  option_translation_max_fields: Option_Translation_Max_Fields;
  option_translation_max_order_by: Option_Translation_Max_Order_By;
  option_translation_min_fields: Option_Translation_Min_Fields;
  option_translation_min_order_by: Option_Translation_Min_Order_By;
  option_translation_mutation_response: Option_Translation_Mutation_Response;
  option_translation_on_conflict: Option_Translation_On_Conflict;
  option_translation_order_by: Option_Translation_Order_By;
  option_translation_pk_columns_input: Option_Translation_Pk_Columns_Input;
  option_translation_set_input: Option_Translation_Set_Input;
  option_translation_stream_cursor_input: Option_Translation_Stream_Cursor_Input;
  option_translation_stream_cursor_value_input: Option_Translation_Stream_Cursor_Value_Input;
  option_translation_updates: Option_Translation_Updates;
  options: Options;
  options_aggregate: Options_Aggregate;
  options_aggregate_fields: Options_Aggregate_Fields;
  options_aggregate_order_by: Options_Aggregate_Order_By;
  options_arr_rel_insert_input: Options_Arr_Rel_Insert_Input;
  options_bool_exp: Options_Bool_Exp;
  options_insert_input: Options_Insert_Input;
  options_max_fields: Options_Max_Fields;
  options_max_order_by: Options_Max_Order_By;
  options_min_fields: Options_Min_Fields;
  options_min_order_by: Options_Min_Order_By;
  options_mutation_response: Options_Mutation_Response;
  options_obj_rel_insert_input: Options_Obj_Rel_Insert_Input;
  options_on_conflict: Options_On_Conflict;
  options_order_by: Options_Order_By;
  options_pk_columns_input: Options_Pk_Columns_Input;
  options_set_input: Options_Set_Input;
  options_stream_cursor_input: Options_Stream_Cursor_Input;
  options_stream_cursor_value_input: Options_Stream_Cursor_Value_Input;
  options_updates: Options_Updates;
  payload_recording: Payload_Recording;
  payload_recording_aggregate: Payload_Recording_Aggregate;
  payload_recording_aggregate_fields: Payload_Recording_Aggregate_Fields;
  payload_recording_aggregate_order_by: Payload_Recording_Aggregate_Order_By;
  payload_recording_arr_rel_insert_input: Payload_Recording_Arr_Rel_Insert_Input;
  payload_recording_bool_exp: Payload_Recording_Bool_Exp;
  payload_recording_insert_input: Payload_Recording_Insert_Input;
  payload_recording_max_fields: Payload_Recording_Max_Fields;
  payload_recording_max_order_by: Payload_Recording_Max_Order_By;
  payload_recording_min_fields: Payload_Recording_Min_Fields;
  payload_recording_min_order_by: Payload_Recording_Min_Order_By;
  payload_recording_mutation_response: Payload_Recording_Mutation_Response;
  payload_recording_on_conflict: Payload_Recording_On_Conflict;
  payload_recording_order_by: Payload_Recording_Order_By;
  payload_recording_pk_columns_input: Payload_Recording_Pk_Columns_Input;
  payload_recording_set_input: Payload_Recording_Set_Input;
  payload_recording_stream_cursor_input: Payload_Recording_Stream_Cursor_Input;
  payload_recording_stream_cursor_value_input: Payload_Recording_Stream_Cursor_Value_Input;
  payload_recording_updates: Payload_Recording_Updates;
  payload_types: Payload_Types;
  payload_types_aggregate: Payload_Types_Aggregate;
  payload_types_aggregate_fields: Payload_Types_Aggregate_Fields;
  payload_types_bool_exp: Payload_Types_Bool_Exp;
  payload_types_enum_comparison_exp: Payload_Types_Enum_Comparison_Exp;
  payload_types_max_fields: Payload_Types_Max_Fields;
  payload_types_min_fields: Payload_Types_Min_Fields;
  payload_types_mutation_response: Payload_Types_Mutation_Response;
  payload_types_order_by: Payload_Types_Order_By;
  payload_types_pk_columns_input: Payload_Types_Pk_Columns_Input;
  payload_types_set_input: Payload_Types_Set_Input;
  payload_types_stream_cursor_input: Payload_Types_Stream_Cursor_Input;
  payload_types_stream_cursor_value_input: Payload_Types_Stream_Cursor_Value_Input;
  payload_types_updates: Payload_Types_Updates;
  payloads: Payloads;
  payloads_aggregate: Payloads_Aggregate;
  payloads_aggregate_fields: Payloads_Aggregate_Fields;
  payloads_aggregate_order_by: Payloads_Aggregate_Order_By;
  payloads_avg_fields: Payloads_Avg_Fields;
  payloads_avg_order_by: Payloads_Avg_Order_By;
  payloads_bool_exp: Payloads_Bool_Exp;
  payloads_inc_input: Payloads_Inc_Input;
  payloads_insert_input: Payloads_Insert_Input;
  payloads_max_fields: Payloads_Max_Fields;
  payloads_max_order_by: Payloads_Max_Order_By;
  payloads_min_fields: Payloads_Min_Fields;
  payloads_min_order_by: Payloads_Min_Order_By;
  payloads_mutation_response: Payloads_Mutation_Response;
  payloads_obj_rel_insert_input: Payloads_Obj_Rel_Insert_Input;
  payloads_on_conflict: Payloads_On_Conflict;
  payloads_order_by: Payloads_Order_By;
  payloads_pk_columns_input: Payloads_Pk_Columns_Input;
  payloads_set_input: Payloads_Set_Input;
  payloads_stddev_fields: Payloads_Stddev_Fields;
  payloads_stddev_order_by: Payloads_Stddev_Order_By;
  payloads_stddev_pop_fields: Payloads_Stddev_Pop_Fields;
  payloads_stddev_pop_order_by: Payloads_Stddev_Pop_Order_By;
  payloads_stddev_samp_fields: Payloads_Stddev_Samp_Fields;
  payloads_stddev_samp_order_by: Payloads_Stddev_Samp_Order_By;
  payloads_stream_cursor_input: Payloads_Stream_Cursor_Input;
  payloads_stream_cursor_value_input: Payloads_Stream_Cursor_Value_Input;
  payloads_sum_fields: Payloads_Sum_Fields;
  payloads_sum_order_by: Payloads_Sum_Order_By;
  payloads_updates: Payloads_Updates;
  payloads_var_pop_fields: Payloads_Var_Pop_Fields;
  payloads_var_pop_order_by: Payloads_Var_Pop_Order_By;
  payloads_var_samp_fields: Payloads_Var_Samp_Fields;
  payloads_var_samp_order_by: Payloads_Var_Samp_Order_By;
  payloads_variance_fields: Payloads_Variance_Fields;
  payloads_variance_order_by: Payloads_Variance_Order_By;
  performance_ratio_metric_contributions: Performance_Ratio_Metric_Contributions;
  performance_ratio_metric_contributions_aggregate: Performance_Ratio_Metric_Contributions_Aggregate;
  performance_ratio_metric_contributions_aggregate_fields: Performance_Ratio_Metric_Contributions_Aggregate_Fields;
  performance_ratio_metric_contributions_aggregate_order_by: Performance_Ratio_Metric_Contributions_Aggregate_Order_By;
  performance_ratio_metric_contributions_arr_rel_insert_input: Performance_Ratio_Metric_Contributions_Arr_Rel_Insert_Input;
  performance_ratio_metric_contributions_avg_fields: Performance_Ratio_Metric_Contributions_Avg_Fields;
  performance_ratio_metric_contributions_avg_order_by: Performance_Ratio_Metric_Contributions_Avg_Order_By;
  performance_ratio_metric_contributions_bool_exp: Performance_Ratio_Metric_Contributions_Bool_Exp;
  performance_ratio_metric_contributions_inc_input: Performance_Ratio_Metric_Contributions_Inc_Input;
  performance_ratio_metric_contributions_insert_input: Performance_Ratio_Metric_Contributions_Insert_Input;
  performance_ratio_metric_contributions_max_fields: Performance_Ratio_Metric_Contributions_Max_Fields;
  performance_ratio_metric_contributions_max_order_by: Performance_Ratio_Metric_Contributions_Max_Order_By;
  performance_ratio_metric_contributions_min_fields: Performance_Ratio_Metric_Contributions_Min_Fields;
  performance_ratio_metric_contributions_min_order_by: Performance_Ratio_Metric_Contributions_Min_Order_By;
  performance_ratio_metric_contributions_mutation_response: Performance_Ratio_Metric_Contributions_Mutation_Response;
  performance_ratio_metric_contributions_on_conflict: Performance_Ratio_Metric_Contributions_On_Conflict;
  performance_ratio_metric_contributions_order_by: Performance_Ratio_Metric_Contributions_Order_By;
  performance_ratio_metric_contributions_pk_columns_input: Performance_Ratio_Metric_Contributions_Pk_Columns_Input;
  performance_ratio_metric_contributions_set_input: Performance_Ratio_Metric_Contributions_Set_Input;
  performance_ratio_metric_contributions_stddev_fields: Performance_Ratio_Metric_Contributions_Stddev_Fields;
  performance_ratio_metric_contributions_stddev_order_by: Performance_Ratio_Metric_Contributions_Stddev_Order_By;
  performance_ratio_metric_contributions_stddev_pop_fields: Performance_Ratio_Metric_Contributions_Stddev_Pop_Fields;
  performance_ratio_metric_contributions_stddev_pop_order_by: Performance_Ratio_Metric_Contributions_Stddev_Pop_Order_By;
  performance_ratio_metric_contributions_stddev_samp_fields: Performance_Ratio_Metric_Contributions_Stddev_Samp_Fields;
  performance_ratio_metric_contributions_stddev_samp_order_by: Performance_Ratio_Metric_Contributions_Stddev_Samp_Order_By;
  performance_ratio_metric_contributions_stream_cursor_input: Performance_Ratio_Metric_Contributions_Stream_Cursor_Input;
  performance_ratio_metric_contributions_stream_cursor_value_input: Performance_Ratio_Metric_Contributions_Stream_Cursor_Value_Input;
  performance_ratio_metric_contributions_sum_fields: Performance_Ratio_Metric_Contributions_Sum_Fields;
  performance_ratio_metric_contributions_sum_order_by: Performance_Ratio_Metric_Contributions_Sum_Order_By;
  performance_ratio_metric_contributions_updates: Performance_Ratio_Metric_Contributions_Updates;
  performance_ratio_metric_contributions_var_pop_fields: Performance_Ratio_Metric_Contributions_Var_Pop_Fields;
  performance_ratio_metric_contributions_var_pop_order_by: Performance_Ratio_Metric_Contributions_Var_Pop_Order_By;
  performance_ratio_metric_contributions_var_samp_fields: Performance_Ratio_Metric_Contributions_Var_Samp_Fields;
  performance_ratio_metric_contributions_var_samp_order_by: Performance_Ratio_Metric_Contributions_Var_Samp_Order_By;
  performance_ratio_metric_contributions_variance_fields: Performance_Ratio_Metric_Contributions_Variance_Fields;
  performance_ratio_metric_contributions_variance_order_by: Performance_Ratio_Metric_Contributions_Variance_Order_By;
  performance_ratio_metrics: Performance_Ratio_Metrics;
  performance_ratio_metrics_aggregate: Performance_Ratio_Metrics_Aggregate;
  performance_ratio_metrics_aggregate_fields: Performance_Ratio_Metrics_Aggregate_Fields;
  performance_ratio_metrics_avg_fields: Performance_Ratio_Metrics_Avg_Fields;
  performance_ratio_metrics_bool_exp: Performance_Ratio_Metrics_Bool_Exp;
  performance_ratio_metrics_inc_input: Performance_Ratio_Metrics_Inc_Input;
  performance_ratio_metrics_insert_input: Performance_Ratio_Metrics_Insert_Input;
  performance_ratio_metrics_max_fields: Performance_Ratio_Metrics_Max_Fields;
  performance_ratio_metrics_min_fields: Performance_Ratio_Metrics_Min_Fields;
  performance_ratio_metrics_mutation_response: Performance_Ratio_Metrics_Mutation_Response;
  performance_ratio_metrics_obj_rel_insert_input: Performance_Ratio_Metrics_Obj_Rel_Insert_Input;
  performance_ratio_metrics_on_conflict: Performance_Ratio_Metrics_On_Conflict;
  performance_ratio_metrics_order_by: Performance_Ratio_Metrics_Order_By;
  performance_ratio_metrics_pk_columns_input: Performance_Ratio_Metrics_Pk_Columns_Input;
  performance_ratio_metrics_set_input: Performance_Ratio_Metrics_Set_Input;
  performance_ratio_metrics_stddev_fields: Performance_Ratio_Metrics_Stddev_Fields;
  performance_ratio_metrics_stddev_pop_fields: Performance_Ratio_Metrics_Stddev_Pop_Fields;
  performance_ratio_metrics_stddev_samp_fields: Performance_Ratio_Metrics_Stddev_Samp_Fields;
  performance_ratio_metrics_stream_cursor_input: Performance_Ratio_Metrics_Stream_Cursor_Input;
  performance_ratio_metrics_stream_cursor_value_input: Performance_Ratio_Metrics_Stream_Cursor_Value_Input;
  performance_ratio_metrics_sum_fields: Performance_Ratio_Metrics_Sum_Fields;
  performance_ratio_metrics_updates: Performance_Ratio_Metrics_Updates;
  performance_ratio_metrics_var_pop_fields: Performance_Ratio_Metrics_Var_Pop_Fields;
  performance_ratio_metrics_var_samp_fields: Performance_Ratio_Metrics_Var_Samp_Fields;
  performance_ratio_metrics_variance_fields: Performance_Ratio_Metrics_Variance_Fields;
  performance_report_deliverable_template: Performance_Report_Deliverable_Template;
  performance_report_deliverable_template_aggregate: Performance_Report_Deliverable_Template_Aggregate;
  performance_report_deliverable_template_aggregate_fields: Performance_Report_Deliverable_Template_Aggregate_Fields;
  performance_report_deliverable_template_aggregate_order_by: Performance_Report_Deliverable_Template_Aggregate_Order_By;
  performance_report_deliverable_template_arr_rel_insert_input: Performance_Report_Deliverable_Template_Arr_Rel_Insert_Input;
  performance_report_deliverable_template_avg_fields: Performance_Report_Deliverable_Template_Avg_Fields;
  performance_report_deliverable_template_avg_order_by: Performance_Report_Deliverable_Template_Avg_Order_By;
  performance_report_deliverable_template_bool_exp: Performance_Report_Deliverable_Template_Bool_Exp;
  performance_report_deliverable_template_inc_input: Performance_Report_Deliverable_Template_Inc_Input;
  performance_report_deliverable_template_insert_input: Performance_Report_Deliverable_Template_Insert_Input;
  performance_report_deliverable_template_max_fields: Performance_Report_Deliverable_Template_Max_Fields;
  performance_report_deliverable_template_max_order_by: Performance_Report_Deliverable_Template_Max_Order_By;
  performance_report_deliverable_template_min_fields: Performance_Report_Deliverable_Template_Min_Fields;
  performance_report_deliverable_template_min_order_by: Performance_Report_Deliverable_Template_Min_Order_By;
  performance_report_deliverable_template_mutation_response: Performance_Report_Deliverable_Template_Mutation_Response;
  performance_report_deliverable_template_on_conflict: Performance_Report_Deliverable_Template_On_Conflict;
  performance_report_deliverable_template_order_by: Performance_Report_Deliverable_Template_Order_By;
  performance_report_deliverable_template_pk_columns_input: Performance_Report_Deliverable_Template_Pk_Columns_Input;
  performance_report_deliverable_template_set_input: Performance_Report_Deliverable_Template_Set_Input;
  performance_report_deliverable_template_stddev_fields: Performance_Report_Deliverable_Template_Stddev_Fields;
  performance_report_deliverable_template_stddev_order_by: Performance_Report_Deliverable_Template_Stddev_Order_By;
  performance_report_deliverable_template_stddev_pop_fields: Performance_Report_Deliverable_Template_Stddev_Pop_Fields;
  performance_report_deliverable_template_stddev_pop_order_by: Performance_Report_Deliverable_Template_Stddev_Pop_Order_By;
  performance_report_deliverable_template_stddev_samp_fields: Performance_Report_Deliverable_Template_Stddev_Samp_Fields;
  performance_report_deliverable_template_stddev_samp_order_by: Performance_Report_Deliverable_Template_Stddev_Samp_Order_By;
  performance_report_deliverable_template_stream_cursor_input: Performance_Report_Deliverable_Template_Stream_Cursor_Input;
  performance_report_deliverable_template_stream_cursor_value_input: Performance_Report_Deliverable_Template_Stream_Cursor_Value_Input;
  performance_report_deliverable_template_sum_fields: Performance_Report_Deliverable_Template_Sum_Fields;
  performance_report_deliverable_template_sum_order_by: Performance_Report_Deliverable_Template_Sum_Order_By;
  performance_report_deliverable_template_updates: Performance_Report_Deliverable_Template_Updates;
  performance_report_deliverable_template_var_pop_fields: Performance_Report_Deliverable_Template_Var_Pop_Fields;
  performance_report_deliverable_template_var_pop_order_by: Performance_Report_Deliverable_Template_Var_Pop_Order_By;
  performance_report_deliverable_template_var_samp_fields: Performance_Report_Deliverable_Template_Var_Samp_Fields;
  performance_report_deliverable_template_var_samp_order_by: Performance_Report_Deliverable_Template_Var_Samp_Order_By;
  performance_report_deliverable_template_variance_fields: Performance_Report_Deliverable_Template_Variance_Fields;
  performance_report_deliverable_template_variance_order_by: Performance_Report_Deliverable_Template_Variance_Order_By;
  performance_report_pivot_types: Performance_Report_Pivot_Types;
  performance_report_pivot_types_aggregate: Performance_Report_Pivot_Types_Aggregate;
  performance_report_pivot_types_aggregate_fields: Performance_Report_Pivot_Types_Aggregate_Fields;
  performance_report_pivot_types_bool_exp: Performance_Report_Pivot_Types_Bool_Exp;
  performance_report_pivot_types_enum_comparison_exp: Performance_Report_Pivot_Types_Enum_Comparison_Exp;
  performance_report_pivot_types_max_fields: Performance_Report_Pivot_Types_Max_Fields;
  performance_report_pivot_types_min_fields: Performance_Report_Pivot_Types_Min_Fields;
  performance_report_pivot_types_mutation_response: Performance_Report_Pivot_Types_Mutation_Response;
  performance_report_pivot_types_order_by: Performance_Report_Pivot_Types_Order_By;
  performance_report_pivot_types_pk_columns_input: Performance_Report_Pivot_Types_Pk_Columns_Input;
  performance_report_pivot_types_set_input: Performance_Report_Pivot_Types_Set_Input;
  performance_report_pivot_types_stream_cursor_input: Performance_Report_Pivot_Types_Stream_Cursor_Input;
  performance_report_pivot_types_stream_cursor_value_input: Performance_Report_Pivot_Types_Stream_Cursor_Value_Input;
  performance_report_pivot_types_updates: Performance_Report_Pivot_Types_Updates;
  performance_report_section_pivot_field: Performance_Report_Section_Pivot_Field;
  performance_report_section_pivot_field_aggregate: Performance_Report_Section_Pivot_Field_Aggregate;
  performance_report_section_pivot_field_aggregate_fields: Performance_Report_Section_Pivot_Field_Aggregate_Fields;
  performance_report_section_pivot_field_aggregate_order_by: Performance_Report_Section_Pivot_Field_Aggregate_Order_By;
  performance_report_section_pivot_field_arr_rel_insert_input: Performance_Report_Section_Pivot_Field_Arr_Rel_Insert_Input;
  performance_report_section_pivot_field_avg_fields: Performance_Report_Section_Pivot_Field_Avg_Fields;
  performance_report_section_pivot_field_avg_order_by: Performance_Report_Section_Pivot_Field_Avg_Order_By;
  performance_report_section_pivot_field_bool_exp: Performance_Report_Section_Pivot_Field_Bool_Exp;
  performance_report_section_pivot_field_inc_input: Performance_Report_Section_Pivot_Field_Inc_Input;
  performance_report_section_pivot_field_insert_input: Performance_Report_Section_Pivot_Field_Insert_Input;
  performance_report_section_pivot_field_max_fields: Performance_Report_Section_Pivot_Field_Max_Fields;
  performance_report_section_pivot_field_max_order_by: Performance_Report_Section_Pivot_Field_Max_Order_By;
  performance_report_section_pivot_field_min_fields: Performance_Report_Section_Pivot_Field_Min_Fields;
  performance_report_section_pivot_field_min_order_by: Performance_Report_Section_Pivot_Field_Min_Order_By;
  performance_report_section_pivot_field_mutation_response: Performance_Report_Section_Pivot_Field_Mutation_Response;
  performance_report_section_pivot_field_on_conflict: Performance_Report_Section_Pivot_Field_On_Conflict;
  performance_report_section_pivot_field_order_by: Performance_Report_Section_Pivot_Field_Order_By;
  performance_report_section_pivot_field_pk_columns_input: Performance_Report_Section_Pivot_Field_Pk_Columns_Input;
  performance_report_section_pivot_field_set_input: Performance_Report_Section_Pivot_Field_Set_Input;
  performance_report_section_pivot_field_stddev_fields: Performance_Report_Section_Pivot_Field_Stddev_Fields;
  performance_report_section_pivot_field_stddev_order_by: Performance_Report_Section_Pivot_Field_Stddev_Order_By;
  performance_report_section_pivot_field_stddev_pop_fields: Performance_Report_Section_Pivot_Field_Stddev_Pop_Fields;
  performance_report_section_pivot_field_stddev_pop_order_by: Performance_Report_Section_Pivot_Field_Stddev_Pop_Order_By;
  performance_report_section_pivot_field_stddev_samp_fields: Performance_Report_Section_Pivot_Field_Stddev_Samp_Fields;
  performance_report_section_pivot_field_stddev_samp_order_by: Performance_Report_Section_Pivot_Field_Stddev_Samp_Order_By;
  performance_report_section_pivot_field_stream_cursor_input: Performance_Report_Section_Pivot_Field_Stream_Cursor_Input;
  performance_report_section_pivot_field_stream_cursor_value_input: Performance_Report_Section_Pivot_Field_Stream_Cursor_Value_Input;
  performance_report_section_pivot_field_sum_fields: Performance_Report_Section_Pivot_Field_Sum_Fields;
  performance_report_section_pivot_field_sum_order_by: Performance_Report_Section_Pivot_Field_Sum_Order_By;
  performance_report_section_pivot_field_updates: Performance_Report_Section_Pivot_Field_Updates;
  performance_report_section_pivot_field_var_pop_fields: Performance_Report_Section_Pivot_Field_Var_Pop_Fields;
  performance_report_section_pivot_field_var_pop_order_by: Performance_Report_Section_Pivot_Field_Var_Pop_Order_By;
  performance_report_section_pivot_field_var_samp_fields: Performance_Report_Section_Pivot_Field_Var_Samp_Fields;
  performance_report_section_pivot_field_var_samp_order_by: Performance_Report_Section_Pivot_Field_Var_Samp_Order_By;
  performance_report_section_pivot_field_variance_fields: Performance_Report_Section_Pivot_Field_Variance_Fields;
  performance_report_section_pivot_field_variance_order_by: Performance_Report_Section_Pivot_Field_Variance_Order_By;
  performance_report_section_ratio_metric: Performance_Report_Section_Ratio_Metric;
  performance_report_section_ratio_metric_aggregate: Performance_Report_Section_Ratio_Metric_Aggregate;
  performance_report_section_ratio_metric_aggregate_fields: Performance_Report_Section_Ratio_Metric_Aggregate_Fields;
  performance_report_section_ratio_metric_aggregate_order_by: Performance_Report_Section_Ratio_Metric_Aggregate_Order_By;
  performance_report_section_ratio_metric_arr_rel_insert_input: Performance_Report_Section_Ratio_Metric_Arr_Rel_Insert_Input;
  performance_report_section_ratio_metric_avg_fields: Performance_Report_Section_Ratio_Metric_Avg_Fields;
  performance_report_section_ratio_metric_avg_order_by: Performance_Report_Section_Ratio_Metric_Avg_Order_By;
  performance_report_section_ratio_metric_bool_exp: Performance_Report_Section_Ratio_Metric_Bool_Exp;
  performance_report_section_ratio_metric_inc_input: Performance_Report_Section_Ratio_Metric_Inc_Input;
  performance_report_section_ratio_metric_insert_input: Performance_Report_Section_Ratio_Metric_Insert_Input;
  performance_report_section_ratio_metric_max_fields: Performance_Report_Section_Ratio_Metric_Max_Fields;
  performance_report_section_ratio_metric_max_order_by: Performance_Report_Section_Ratio_Metric_Max_Order_By;
  performance_report_section_ratio_metric_min_fields: Performance_Report_Section_Ratio_Metric_Min_Fields;
  performance_report_section_ratio_metric_min_order_by: Performance_Report_Section_Ratio_Metric_Min_Order_By;
  performance_report_section_ratio_metric_mutation_response: Performance_Report_Section_Ratio_Metric_Mutation_Response;
  performance_report_section_ratio_metric_on_conflict: Performance_Report_Section_Ratio_Metric_On_Conflict;
  performance_report_section_ratio_metric_order_by: Performance_Report_Section_Ratio_Metric_Order_By;
  performance_report_section_ratio_metric_pk_columns_input: Performance_Report_Section_Ratio_Metric_Pk_Columns_Input;
  performance_report_section_ratio_metric_set_input: Performance_Report_Section_Ratio_Metric_Set_Input;
  performance_report_section_ratio_metric_stddev_fields: Performance_Report_Section_Ratio_Metric_Stddev_Fields;
  performance_report_section_ratio_metric_stddev_order_by: Performance_Report_Section_Ratio_Metric_Stddev_Order_By;
  performance_report_section_ratio_metric_stddev_pop_fields: Performance_Report_Section_Ratio_Metric_Stddev_Pop_Fields;
  performance_report_section_ratio_metric_stddev_pop_order_by: Performance_Report_Section_Ratio_Metric_Stddev_Pop_Order_By;
  performance_report_section_ratio_metric_stddev_samp_fields: Performance_Report_Section_Ratio_Metric_Stddev_Samp_Fields;
  performance_report_section_ratio_metric_stddev_samp_order_by: Performance_Report_Section_Ratio_Metric_Stddev_Samp_Order_By;
  performance_report_section_ratio_metric_stream_cursor_input: Performance_Report_Section_Ratio_Metric_Stream_Cursor_Input;
  performance_report_section_ratio_metric_stream_cursor_value_input: Performance_Report_Section_Ratio_Metric_Stream_Cursor_Value_Input;
  performance_report_section_ratio_metric_sum_fields: Performance_Report_Section_Ratio_Metric_Sum_Fields;
  performance_report_section_ratio_metric_sum_order_by: Performance_Report_Section_Ratio_Metric_Sum_Order_By;
  performance_report_section_ratio_metric_updates: Performance_Report_Section_Ratio_Metric_Updates;
  performance_report_section_ratio_metric_var_pop_fields: Performance_Report_Section_Ratio_Metric_Var_Pop_Fields;
  performance_report_section_ratio_metric_var_pop_order_by: Performance_Report_Section_Ratio_Metric_Var_Pop_Order_By;
  performance_report_section_ratio_metric_var_samp_fields: Performance_Report_Section_Ratio_Metric_Var_Samp_Fields;
  performance_report_section_ratio_metric_var_samp_order_by: Performance_Report_Section_Ratio_Metric_Var_Samp_Order_By;
  performance_report_section_ratio_metric_variance_fields: Performance_Report_Section_Ratio_Metric_Variance_Fields;
  performance_report_section_ratio_metric_variance_order_by: Performance_Report_Section_Ratio_Metric_Variance_Order_By;
  performance_report_section_task_blacklist: Performance_Report_Section_Task_Blacklist;
  performance_report_section_task_blacklist_aggregate: Performance_Report_Section_Task_Blacklist_Aggregate;
  performance_report_section_task_blacklist_aggregate_fields: Performance_Report_Section_Task_Blacklist_Aggregate_Fields;
  performance_report_section_task_blacklist_aggregate_order_by: Performance_Report_Section_Task_Blacklist_Aggregate_Order_By;
  performance_report_section_task_blacklist_arr_rel_insert_input: Performance_Report_Section_Task_Blacklist_Arr_Rel_Insert_Input;
  performance_report_section_task_blacklist_bool_exp: Performance_Report_Section_Task_Blacklist_Bool_Exp;
  performance_report_section_task_blacklist_insert_input: Performance_Report_Section_Task_Blacklist_Insert_Input;
  performance_report_section_task_blacklist_max_fields: Performance_Report_Section_Task_Blacklist_Max_Fields;
  performance_report_section_task_blacklist_max_order_by: Performance_Report_Section_Task_Blacklist_Max_Order_By;
  performance_report_section_task_blacklist_min_fields: Performance_Report_Section_Task_Blacklist_Min_Fields;
  performance_report_section_task_blacklist_min_order_by: Performance_Report_Section_Task_Blacklist_Min_Order_By;
  performance_report_section_task_blacklist_mutation_response: Performance_Report_Section_Task_Blacklist_Mutation_Response;
  performance_report_section_task_blacklist_on_conflict: Performance_Report_Section_Task_Blacklist_On_Conflict;
  performance_report_section_task_blacklist_order_by: Performance_Report_Section_Task_Blacklist_Order_By;
  performance_report_section_task_blacklist_pk_columns_input: Performance_Report_Section_Task_Blacklist_Pk_Columns_Input;
  performance_report_section_task_blacklist_set_input: Performance_Report_Section_Task_Blacklist_Set_Input;
  performance_report_section_task_blacklist_stream_cursor_input: Performance_Report_Section_Task_Blacklist_Stream_Cursor_Input;
  performance_report_section_task_blacklist_stream_cursor_value_input: Performance_Report_Section_Task_Blacklist_Stream_Cursor_Value_Input;
  performance_report_section_task_blacklist_updates: Performance_Report_Section_Task_Blacklist_Updates;
  performance_report_sections: Performance_Report_Sections;
  performance_report_sections_aggregate: Performance_Report_Sections_Aggregate;
  performance_report_sections_aggregate_fields: Performance_Report_Sections_Aggregate_Fields;
  performance_report_sections_aggregate_order_by: Performance_Report_Sections_Aggregate_Order_By;
  performance_report_sections_arr_rel_insert_input: Performance_Report_Sections_Arr_Rel_Insert_Input;
  performance_report_sections_avg_fields: Performance_Report_Sections_Avg_Fields;
  performance_report_sections_avg_order_by: Performance_Report_Sections_Avg_Order_By;
  performance_report_sections_bool_exp: Performance_Report_Sections_Bool_Exp;
  performance_report_sections_inc_input: Performance_Report_Sections_Inc_Input;
  performance_report_sections_insert_input: Performance_Report_Sections_Insert_Input;
  performance_report_sections_max_fields: Performance_Report_Sections_Max_Fields;
  performance_report_sections_max_order_by: Performance_Report_Sections_Max_Order_By;
  performance_report_sections_min_fields: Performance_Report_Sections_Min_Fields;
  performance_report_sections_min_order_by: Performance_Report_Sections_Min_Order_By;
  performance_report_sections_mutation_response: Performance_Report_Sections_Mutation_Response;
  performance_report_sections_obj_rel_insert_input: Performance_Report_Sections_Obj_Rel_Insert_Input;
  performance_report_sections_on_conflict: Performance_Report_Sections_On_Conflict;
  performance_report_sections_order_by: Performance_Report_Sections_Order_By;
  performance_report_sections_pk_columns_input: Performance_Report_Sections_Pk_Columns_Input;
  performance_report_sections_set_input: Performance_Report_Sections_Set_Input;
  performance_report_sections_stddev_fields: Performance_Report_Sections_Stddev_Fields;
  performance_report_sections_stddev_order_by: Performance_Report_Sections_Stddev_Order_By;
  performance_report_sections_stddev_pop_fields: Performance_Report_Sections_Stddev_Pop_Fields;
  performance_report_sections_stddev_pop_order_by: Performance_Report_Sections_Stddev_Pop_Order_By;
  performance_report_sections_stddev_samp_fields: Performance_Report_Sections_Stddev_Samp_Fields;
  performance_report_sections_stddev_samp_order_by: Performance_Report_Sections_Stddev_Samp_Order_By;
  performance_report_sections_stream_cursor_input: Performance_Report_Sections_Stream_Cursor_Input;
  performance_report_sections_stream_cursor_value_input: Performance_Report_Sections_Stream_Cursor_Value_Input;
  performance_report_sections_sum_fields: Performance_Report_Sections_Sum_Fields;
  performance_report_sections_sum_order_by: Performance_Report_Sections_Sum_Order_By;
  performance_report_sections_updates: Performance_Report_Sections_Updates;
  performance_report_sections_var_pop_fields: Performance_Report_Sections_Var_Pop_Fields;
  performance_report_sections_var_pop_order_by: Performance_Report_Sections_Var_Pop_Order_By;
  performance_report_sections_var_samp_fields: Performance_Report_Sections_Var_Samp_Fields;
  performance_report_sections_var_samp_order_by: Performance_Report_Sections_Var_Samp_Order_By;
  performance_report_sections_variance_fields: Performance_Report_Sections_Variance_Fields;
  performance_report_sections_variance_order_by: Performance_Report_Sections_Variance_Order_By;
  performance_report_templates: Performance_Report_Templates;
  performance_report_templates_aggregate: Performance_Report_Templates_Aggregate;
  performance_report_templates_aggregate_fields: Performance_Report_Templates_Aggregate_Fields;
  performance_report_templates_bool_exp: Performance_Report_Templates_Bool_Exp;
  performance_report_templates_insert_input: Performance_Report_Templates_Insert_Input;
  performance_report_templates_max_fields: Performance_Report_Templates_Max_Fields;
  performance_report_templates_min_fields: Performance_Report_Templates_Min_Fields;
  performance_report_templates_mutation_response: Performance_Report_Templates_Mutation_Response;
  performance_report_templates_obj_rel_insert_input: Performance_Report_Templates_Obj_Rel_Insert_Input;
  performance_report_templates_on_conflict: Performance_Report_Templates_On_Conflict;
  performance_report_templates_order_by: Performance_Report_Templates_Order_By;
  performance_report_templates_pk_columns_input: Performance_Report_Templates_Pk_Columns_Input;
  performance_report_templates_set_input: Performance_Report_Templates_Set_Input;
  performance_report_templates_stream_cursor_input: Performance_Report_Templates_Stream_Cursor_Input;
  performance_report_templates_stream_cursor_value_input: Performance_Report_Templates_Stream_Cursor_Value_Input;
  performance_report_templates_updates: Performance_Report_Templates_Updates;
  peripherals: Peripherals;
  peripherals_bool_exp: Peripherals_Bool_Exp;
  peripherals_insert_input: Peripherals_Insert_Input;
  peripherals_mutation_response: Peripherals_Mutation_Response;
  peripherals_on_conflict: Peripherals_On_Conflict;
  peripherals_order_by: Peripherals_Order_By;
  peripherals_pk_columns_input: Peripherals_Pk_Columns_Input;
  peripherals_set_input: Peripherals_Set_Input;
  peripherals_stream_cursor_input: Peripherals_Stream_Cursor_Input;
  peripherals_stream_cursor_value_input: Peripherals_Stream_Cursor_Value_Input;
  peripherals_updates: Peripherals_Updates;
  plan_hypertasks: Plan_Hypertasks;
  plan_hypertasks_aggregate: Plan_Hypertasks_Aggregate;
  plan_hypertasks_aggregate_fields: Plan_Hypertasks_Aggregate_Fields;
  plan_hypertasks_aggregate_order_by: Plan_Hypertasks_Aggregate_Order_By;
  plan_hypertasks_arr_rel_insert_input: Plan_Hypertasks_Arr_Rel_Insert_Input;
  plan_hypertasks_avg_fields: Plan_Hypertasks_Avg_Fields;
  plan_hypertasks_avg_order_by: Plan_Hypertasks_Avg_Order_By;
  plan_hypertasks_bool_exp: Plan_Hypertasks_Bool_Exp;
  plan_hypertasks_inc_input: Plan_Hypertasks_Inc_Input;
  plan_hypertasks_insert_input: Plan_Hypertasks_Insert_Input;
  plan_hypertasks_max_fields: Plan_Hypertasks_Max_Fields;
  plan_hypertasks_max_order_by: Plan_Hypertasks_Max_Order_By;
  plan_hypertasks_min_fields: Plan_Hypertasks_Min_Fields;
  plan_hypertasks_min_order_by: Plan_Hypertasks_Min_Order_By;
  plan_hypertasks_mutation_response: Plan_Hypertasks_Mutation_Response;
  plan_hypertasks_on_conflict: Plan_Hypertasks_On_Conflict;
  plan_hypertasks_order_by: Plan_Hypertasks_Order_By;
  plan_hypertasks_pk_columns_input: Plan_Hypertasks_Pk_Columns_Input;
  plan_hypertasks_set_input: Plan_Hypertasks_Set_Input;
  plan_hypertasks_stddev_fields: Plan_Hypertasks_Stddev_Fields;
  plan_hypertasks_stddev_order_by: Plan_Hypertasks_Stddev_Order_By;
  plan_hypertasks_stddev_pop_fields: Plan_Hypertasks_Stddev_Pop_Fields;
  plan_hypertasks_stddev_pop_order_by: Plan_Hypertasks_Stddev_Pop_Order_By;
  plan_hypertasks_stddev_samp_fields: Plan_Hypertasks_Stddev_Samp_Fields;
  plan_hypertasks_stddev_samp_order_by: Plan_Hypertasks_Stddev_Samp_Order_By;
  plan_hypertasks_stream_cursor_input: Plan_Hypertasks_Stream_Cursor_Input;
  plan_hypertasks_stream_cursor_value_input: Plan_Hypertasks_Stream_Cursor_Value_Input;
  plan_hypertasks_sum_fields: Plan_Hypertasks_Sum_Fields;
  plan_hypertasks_sum_order_by: Plan_Hypertasks_Sum_Order_By;
  plan_hypertasks_updates: Plan_Hypertasks_Updates;
  plan_hypertasks_var_pop_fields: Plan_Hypertasks_Var_Pop_Fields;
  plan_hypertasks_var_pop_order_by: Plan_Hypertasks_Var_Pop_Order_By;
  plan_hypertasks_var_samp_fields: Plan_Hypertasks_Var_Samp_Fields;
  plan_hypertasks_var_samp_order_by: Plan_Hypertasks_Var_Samp_Order_By;
  plan_hypertasks_variance_fields: Plan_Hypertasks_Variance_Fields;
  plan_hypertasks_variance_order_by: Plan_Hypertasks_Variance_Order_By;
  plan_tasks: Plan_Tasks;
  plan_tasks_aggregate: Plan_Tasks_Aggregate;
  plan_tasks_aggregate_fields: Plan_Tasks_Aggregate_Fields;
  plan_tasks_aggregate_order_by: Plan_Tasks_Aggregate_Order_By;
  plan_tasks_arr_rel_insert_input: Plan_Tasks_Arr_Rel_Insert_Input;
  plan_tasks_avg_fields: Plan_Tasks_Avg_Fields;
  plan_tasks_avg_order_by: Plan_Tasks_Avg_Order_By;
  plan_tasks_bool_exp: Plan_Tasks_Bool_Exp;
  plan_tasks_inc_input: Plan_Tasks_Inc_Input;
  plan_tasks_insert_input: Plan_Tasks_Insert_Input;
  plan_tasks_max_fields: Plan_Tasks_Max_Fields;
  plan_tasks_max_order_by: Plan_Tasks_Max_Order_By;
  plan_tasks_min_fields: Plan_Tasks_Min_Fields;
  plan_tasks_min_order_by: Plan_Tasks_Min_Order_By;
  plan_tasks_mutation_response: Plan_Tasks_Mutation_Response;
  plan_tasks_on_conflict: Plan_Tasks_On_Conflict;
  plan_tasks_order_by: Plan_Tasks_Order_By;
  plan_tasks_pk_columns_input: Plan_Tasks_Pk_Columns_Input;
  plan_tasks_set_input: Plan_Tasks_Set_Input;
  plan_tasks_stddev_fields: Plan_Tasks_Stddev_Fields;
  plan_tasks_stddev_order_by: Plan_Tasks_Stddev_Order_By;
  plan_tasks_stddev_pop_fields: Plan_Tasks_Stddev_Pop_Fields;
  plan_tasks_stddev_pop_order_by: Plan_Tasks_Stddev_Pop_Order_By;
  plan_tasks_stddev_samp_fields: Plan_Tasks_Stddev_Samp_Fields;
  plan_tasks_stddev_samp_order_by: Plan_Tasks_Stddev_Samp_Order_By;
  plan_tasks_stream_cursor_input: Plan_Tasks_Stream_Cursor_Input;
  plan_tasks_stream_cursor_value_input: Plan_Tasks_Stream_Cursor_Value_Input;
  plan_tasks_sum_fields: Plan_Tasks_Sum_Fields;
  plan_tasks_sum_order_by: Plan_Tasks_Sum_Order_By;
  plan_tasks_updates: Plan_Tasks_Updates;
  plan_tasks_var_pop_fields: Plan_Tasks_Var_Pop_Fields;
  plan_tasks_var_pop_order_by: Plan_Tasks_Var_Pop_Order_By;
  plan_tasks_var_samp_fields: Plan_Tasks_Var_Samp_Fields;
  plan_tasks_var_samp_order_by: Plan_Tasks_Var_Samp_Order_By;
  plan_tasks_variance_fields: Plan_Tasks_Variance_Fields;
  plan_tasks_variance_order_by: Plan_Tasks_Variance_Order_By;
  plans: Plans;
  plans_aggregate: Plans_Aggregate;
  plans_aggregate_fields: Plans_Aggregate_Fields;
  plans_bool_exp: Plans_Bool_Exp;
  plans_insert_input: Plans_Insert_Input;
  plans_max_fields: Plans_Max_Fields;
  plans_min_fields: Plans_Min_Fields;
  plans_mutation_response: Plans_Mutation_Response;
  plans_obj_rel_insert_input: Plans_Obj_Rel_Insert_Input;
  plans_on_conflict: Plans_On_Conflict;
  plans_order_by: Plans_Order_By;
  plans_pk_columns_input: Plans_Pk_Columns_Input;
  plans_set_input: Plans_Set_Input;
  plans_stream_cursor_input: Plans_Stream_Cursor_Input;
  plans_stream_cursor_value_input: Plans_Stream_Cursor_Value_Input;
  plans_updates: Plans_Updates;
  profiles: Profiles;
  profiles_aggregate: Profiles_Aggregate;
  profiles_aggregate_fields: Profiles_Aggregate_Fields;
  profiles_bool_exp: Profiles_Bool_Exp;
  profiles_insert_input: Profiles_Insert_Input;
  profiles_max_fields: Profiles_Max_Fields;
  profiles_min_fields: Profiles_Min_Fields;
  profiles_mutation_response: Profiles_Mutation_Response;
  profiles_obj_rel_insert_input: Profiles_Obj_Rel_Insert_Input;
  profiles_on_conflict: Profiles_On_Conflict;
  profiles_order_by: Profiles_Order_By;
  profiles_pk_columns_input: Profiles_Pk_Columns_Input;
  profiles_set_input: Profiles_Set_Input;
  profiles_stream_cursor_input: Profiles_Stream_Cursor_Input;
  profiles_stream_cursor_value_input: Profiles_Stream_Cursor_Value_Input;
  profiles_updates: Profiles_Updates;
  project_combo: Project_Combo;
  project_combo_aggregate: Project_Combo_Aggregate;
  project_combo_aggregate_fields: Project_Combo_Aggregate_Fields;
  project_combo_aggregate_order_by: Project_Combo_Aggregate_Order_By;
  project_combo_arr_rel_insert_input: Project_Combo_Arr_Rel_Insert_Input;
  project_combo_bool_exp: Project_Combo_Bool_Exp;
  project_combo_insert_input: Project_Combo_Insert_Input;
  project_combo_max_fields: Project_Combo_Max_Fields;
  project_combo_max_order_by: Project_Combo_Max_Order_By;
  project_combo_min_fields: Project_Combo_Min_Fields;
  project_combo_min_order_by: Project_Combo_Min_Order_By;
  project_combo_mutation_response: Project_Combo_Mutation_Response;
  project_combo_on_conflict: Project_Combo_On_Conflict;
  project_combo_order_by: Project_Combo_Order_By;
  project_combo_pk_columns_input: Project_Combo_Pk_Columns_Input;
  project_combo_set_input: Project_Combo_Set_Input;
  project_combo_stream_cursor_input: Project_Combo_Stream_Cursor_Input;
  project_combo_stream_cursor_value_input: Project_Combo_Stream_Cursor_Value_Input;
  project_combo_updates: Project_Combo_Updates;
  project_dataset: Project_Dataset;
  project_dataset_aggregate: Project_Dataset_Aggregate;
  project_dataset_aggregate_fields: Project_Dataset_Aggregate_Fields;
  project_dataset_aggregate_order_by: Project_Dataset_Aggregate_Order_By;
  project_dataset_bool_exp: Project_Dataset_Bool_Exp;
  project_dataset_max_fields: Project_Dataset_Max_Fields;
  project_dataset_max_order_by: Project_Dataset_Max_Order_By;
  project_dataset_min_fields: Project_Dataset_Min_Fields;
  project_dataset_min_order_by: Project_Dataset_Min_Order_By;
  project_dataset_order_by: Project_Dataset_Order_By;
  project_dataset_stream_cursor_input: Project_Dataset_Stream_Cursor_Input;
  project_dataset_stream_cursor_value_input: Project_Dataset_Stream_Cursor_Value_Input;
  project_engine_metrics_args: Project_Engine_Metrics_Args;
  project_engine_ratio_metrics_args: Project_Engine_Ratio_Metrics_Args;
  project_fields_args: Project_Fields_Args;
  project_hypertasks: Project_Hypertasks;
  project_hypertasks_aggregate: Project_Hypertasks_Aggregate;
  project_hypertasks_aggregate_fields: Project_Hypertasks_Aggregate_Fields;
  project_hypertasks_aggregate_order_by: Project_Hypertasks_Aggregate_Order_By;
  project_hypertasks_arr_rel_insert_input: Project_Hypertasks_Arr_Rel_Insert_Input;
  project_hypertasks_avg_fields: Project_Hypertasks_Avg_Fields;
  project_hypertasks_avg_order_by: Project_Hypertasks_Avg_Order_By;
  project_hypertasks_bool_exp: Project_Hypertasks_Bool_Exp;
  project_hypertasks_inc_input: Project_Hypertasks_Inc_Input;
  project_hypertasks_insert_input: Project_Hypertasks_Insert_Input;
  project_hypertasks_max_fields: Project_Hypertasks_Max_Fields;
  project_hypertasks_max_order_by: Project_Hypertasks_Max_Order_By;
  project_hypertasks_min_fields: Project_Hypertasks_Min_Fields;
  project_hypertasks_min_order_by: Project_Hypertasks_Min_Order_By;
  project_hypertasks_mutation_response: Project_Hypertasks_Mutation_Response;
  project_hypertasks_on_conflict: Project_Hypertasks_On_Conflict;
  project_hypertasks_order_by: Project_Hypertasks_Order_By;
  project_hypertasks_pk_columns_input: Project_Hypertasks_Pk_Columns_Input;
  project_hypertasks_set_input: Project_Hypertasks_Set_Input;
  project_hypertasks_stddev_fields: Project_Hypertasks_Stddev_Fields;
  project_hypertasks_stddev_order_by: Project_Hypertasks_Stddev_Order_By;
  project_hypertasks_stddev_pop_fields: Project_Hypertasks_Stddev_Pop_Fields;
  project_hypertasks_stddev_pop_order_by: Project_Hypertasks_Stddev_Pop_Order_By;
  project_hypertasks_stddev_samp_fields: Project_Hypertasks_Stddev_Samp_Fields;
  project_hypertasks_stddev_samp_order_by: Project_Hypertasks_Stddev_Samp_Order_By;
  project_hypertasks_stream_cursor_input: Project_Hypertasks_Stream_Cursor_Input;
  project_hypertasks_stream_cursor_value_input: Project_Hypertasks_Stream_Cursor_Value_Input;
  project_hypertasks_sum_fields: Project_Hypertasks_Sum_Fields;
  project_hypertasks_sum_order_by: Project_Hypertasks_Sum_Order_By;
  project_hypertasks_updates: Project_Hypertasks_Updates;
  project_hypertasks_var_pop_fields: Project_Hypertasks_Var_Pop_Fields;
  project_hypertasks_var_pop_order_by: Project_Hypertasks_Var_Pop_Order_By;
  project_hypertasks_var_samp_fields: Project_Hypertasks_Var_Samp_Fields;
  project_hypertasks_var_samp_order_by: Project_Hypertasks_Var_Samp_Order_By;
  project_hypertasks_variance_fields: Project_Hypertasks_Variance_Fields;
  project_hypertasks_variance_order_by: Project_Hypertasks_Variance_Order_By;
  project_metrics: Project_Metrics;
  project_metrics_aggregate: Project_Metrics_Aggregate;
  project_metrics_aggregate_fields: Project_Metrics_Aggregate_Fields;
  project_metrics_bool_exp: Project_Metrics_Bool_Exp;
  project_metrics_max_fields: Project_Metrics_Max_Fields;
  project_metrics_min_fields: Project_Metrics_Min_Fields;
  project_metrics_order_by: Project_Metrics_Order_By;
  project_metrics_stream_cursor_input: Project_Metrics_Stream_Cursor_Input;
  project_metrics_stream_cursor_value_input: Project_Metrics_Stream_Cursor_Value_Input;
  project_name: Project_Name;
  project_name_bool_exp: Project_Name_Bool_Exp;
  project_name_order_by: Project_Name_Order_By;
  project_name_stream_cursor_input: Project_Name_Stream_Cursor_Input;
  project_name_stream_cursor_value_input: Project_Name_Stream_Cursor_Value_Input;
  project_rabbit_test_instances: Project_Rabbit_Test_Instances;
  project_rabbit_test_instances_aggregate: Project_Rabbit_Test_Instances_Aggregate;
  project_rabbit_test_instances_aggregate_fields: Project_Rabbit_Test_Instances_Aggregate_Fields;
  project_rabbit_test_instances_aggregate_order_by: Project_Rabbit_Test_Instances_Aggregate_Order_By;
  project_rabbit_test_instances_avg_fields: Project_Rabbit_Test_Instances_Avg_Fields;
  project_rabbit_test_instances_avg_order_by: Project_Rabbit_Test_Instances_Avg_Order_By;
  project_rabbit_test_instances_bool_exp: Project_Rabbit_Test_Instances_Bool_Exp;
  project_rabbit_test_instances_max_fields: Project_Rabbit_Test_Instances_Max_Fields;
  project_rabbit_test_instances_max_order_by: Project_Rabbit_Test_Instances_Max_Order_By;
  project_rabbit_test_instances_min_fields: Project_Rabbit_Test_Instances_Min_Fields;
  project_rabbit_test_instances_min_order_by: Project_Rabbit_Test_Instances_Min_Order_By;
  project_rabbit_test_instances_order_by: Project_Rabbit_Test_Instances_Order_By;
  project_rabbit_test_instances_stddev_fields: Project_Rabbit_Test_Instances_Stddev_Fields;
  project_rabbit_test_instances_stddev_order_by: Project_Rabbit_Test_Instances_Stddev_Order_By;
  project_rabbit_test_instances_stddev_pop_fields: Project_Rabbit_Test_Instances_Stddev_Pop_Fields;
  project_rabbit_test_instances_stddev_pop_order_by: Project_Rabbit_Test_Instances_Stddev_Pop_Order_By;
  project_rabbit_test_instances_stddev_samp_fields: Project_Rabbit_Test_Instances_Stddev_Samp_Fields;
  project_rabbit_test_instances_stddev_samp_order_by: Project_Rabbit_Test_Instances_Stddev_Samp_Order_By;
  project_rabbit_test_instances_stream_cursor_input: Project_Rabbit_Test_Instances_Stream_Cursor_Input;
  project_rabbit_test_instances_stream_cursor_value_input: Project_Rabbit_Test_Instances_Stream_Cursor_Value_Input;
  project_rabbit_test_instances_sum_fields: Project_Rabbit_Test_Instances_Sum_Fields;
  project_rabbit_test_instances_sum_order_by: Project_Rabbit_Test_Instances_Sum_Order_By;
  project_rabbit_test_instances_var_pop_fields: Project_Rabbit_Test_Instances_Var_Pop_Fields;
  project_rabbit_test_instances_var_pop_order_by: Project_Rabbit_Test_Instances_Var_Pop_Order_By;
  project_rabbit_test_instances_var_samp_fields: Project_Rabbit_Test_Instances_Var_Samp_Fields;
  project_rabbit_test_instances_var_samp_order_by: Project_Rabbit_Test_Instances_Var_Samp_Order_By;
  project_rabbit_test_instances_variance_fields: Project_Rabbit_Test_Instances_Variance_Fields;
  project_rabbit_test_instances_variance_order_by: Project_Rabbit_Test_Instances_Variance_Order_By;
  project_recordings_clean_args: Project_Recordings_Clean_Args;
  project_recordings_unreviewed_args: Project_Recordings_Unreviewed_Args;
  project_task_count_args: Project_Task_Count_Args;
  project_task_count_by_device_args: Project_Task_Count_By_Device_Args;
  project_tasks: Project_Tasks;
  project_tasks_aggregate: Project_Tasks_Aggregate;
  project_tasks_aggregate_fields: Project_Tasks_Aggregate_Fields;
  project_tasks_aggregate_order_by: Project_Tasks_Aggregate_Order_By;
  project_tasks_arr_rel_insert_input: Project_Tasks_Arr_Rel_Insert_Input;
  project_tasks_avg_fields: Project_Tasks_Avg_Fields;
  project_tasks_avg_order_by: Project_Tasks_Avg_Order_By;
  project_tasks_bool_exp: Project_Tasks_Bool_Exp;
  project_tasks_inc_input: Project_Tasks_Inc_Input;
  project_tasks_insert_input: Project_Tasks_Insert_Input;
  project_tasks_max_fields: Project_Tasks_Max_Fields;
  project_tasks_max_order_by: Project_Tasks_Max_Order_By;
  project_tasks_min_fields: Project_Tasks_Min_Fields;
  project_tasks_min_order_by: Project_Tasks_Min_Order_By;
  project_tasks_mutation_response: Project_Tasks_Mutation_Response;
  project_tasks_on_conflict: Project_Tasks_On_Conflict;
  project_tasks_order_by: Project_Tasks_Order_By;
  project_tasks_pk_columns_input: Project_Tasks_Pk_Columns_Input;
  project_tasks_set_input: Project_Tasks_Set_Input;
  project_tasks_stddev_fields: Project_Tasks_Stddev_Fields;
  project_tasks_stddev_order_by: Project_Tasks_Stddev_Order_By;
  project_tasks_stddev_pop_fields: Project_Tasks_Stddev_Pop_Fields;
  project_tasks_stddev_pop_order_by: Project_Tasks_Stddev_Pop_Order_By;
  project_tasks_stddev_samp_fields: Project_Tasks_Stddev_Samp_Fields;
  project_tasks_stddev_samp_order_by: Project_Tasks_Stddev_Samp_Order_By;
  project_tasks_stream_cursor_input: Project_Tasks_Stream_Cursor_Input;
  project_tasks_stream_cursor_value_input: Project_Tasks_Stream_Cursor_Value_Input;
  project_tasks_sum_fields: Project_Tasks_Sum_Fields;
  project_tasks_sum_order_by: Project_Tasks_Sum_Order_By;
  project_tasks_updates: Project_Tasks_Updates;
  project_tasks_var_pop_fields: Project_Tasks_Var_Pop_Fields;
  project_tasks_var_pop_order_by: Project_Tasks_Var_Pop_Order_By;
  project_tasks_var_samp_fields: Project_Tasks_Var_Samp_Fields;
  project_tasks_var_samp_order_by: Project_Tasks_Var_Samp_Order_By;
  project_tasks_variance_fields: Project_Tasks_Variance_Fields;
  project_tasks_variance_order_by: Project_Tasks_Variance_Order_By;
  project_user: Project_User;
  project_user_aggregate: Project_User_Aggregate;
  project_user_aggregate_fields: Project_User_Aggregate_Fields;
  project_user_aggregate_order_by: Project_User_Aggregate_Order_By;
  project_user_arr_rel_insert_input: Project_User_Arr_Rel_Insert_Input;
  project_user_bool_exp: Project_User_Bool_Exp;
  project_user_insert_input: Project_User_Insert_Input;
  project_user_max_fields: Project_User_Max_Fields;
  project_user_max_order_by: Project_User_Max_Order_By;
  project_user_min_fields: Project_User_Min_Fields;
  project_user_min_order_by: Project_User_Min_Order_By;
  project_user_mutation_response: Project_User_Mutation_Response;
  project_user_on_conflict: Project_User_On_Conflict;
  project_user_order_by: Project_User_Order_By;
  project_user_pk_columns_input: Project_User_Pk_Columns_Input;
  project_user_set_input: Project_User_Set_Input;
  project_user_stream_cursor_input: Project_User_Stream_Cursor_Input;
  project_user_stream_cursor_value_input: Project_User_Stream_Cursor_Value_Input;
  project_user_updates: Project_User_Updates;
  projects: Projects;
  projects_aggregate: Projects_Aggregate;
  projects_aggregate_fields: Projects_Aggregate_Fields;
  projects_aggregate_order_by: Projects_Aggregate_Order_By;
  projects_arr_rel_insert_input: Projects_Arr_Rel_Insert_Input;
  projects_avg_fields: Projects_Avg_Fields;
  projects_avg_order_by: Projects_Avg_Order_By;
  projects_bool_exp: Projects_Bool_Exp;
  projects_inc_input: Projects_Inc_Input;
  projects_insert_input: Projects_Insert_Input;
  projects_max_fields: Projects_Max_Fields;
  projects_max_order_by: Projects_Max_Order_By;
  projects_min_fields: Projects_Min_Fields;
  projects_min_order_by: Projects_Min_Order_By;
  projects_mutation_response: Projects_Mutation_Response;
  projects_obj_rel_insert_input: Projects_Obj_Rel_Insert_Input;
  projects_on_conflict: Projects_On_Conflict;
  projects_order_by: Projects_Order_By;
  projects_pk_columns_input: Projects_Pk_Columns_Input;
  projects_set_input: Projects_Set_Input;
  projects_stddev_fields: Projects_Stddev_Fields;
  projects_stddev_order_by: Projects_Stddev_Order_By;
  projects_stddev_pop_fields: Projects_Stddev_Pop_Fields;
  projects_stddev_pop_order_by: Projects_Stddev_Pop_Order_By;
  projects_stddev_samp_fields: Projects_Stddev_Samp_Fields;
  projects_stddev_samp_order_by: Projects_Stddev_Samp_Order_By;
  projects_stream_cursor_input: Projects_Stream_Cursor_Input;
  projects_stream_cursor_value_input: Projects_Stream_Cursor_Value_Input;
  projects_sum_fields: Projects_Sum_Fields;
  projects_sum_order_by: Projects_Sum_Order_By;
  projects_updates: Projects_Updates;
  projects_var_pop_fields: Projects_Var_Pop_Fields;
  projects_var_pop_order_by: Projects_Var_Pop_Order_By;
  projects_var_samp_fields: Projects_Var_Samp_Fields;
  projects_var_samp_order_by: Projects_Var_Samp_Order_By;
  projects_variance_fields: Projects_Variance_Fields;
  projects_variance_order_by: Projects_Variance_Order_By;
  query_root: {};
  rabbit_test_plan_project: Rabbit_Test_Plan_Project;
  rabbit_test_plan_project_aggregate: Rabbit_Test_Plan_Project_Aggregate;
  rabbit_test_plan_project_aggregate_fields: Rabbit_Test_Plan_Project_Aggregate_Fields;
  rabbit_test_plan_project_aggregate_order_by: Rabbit_Test_Plan_Project_Aggregate_Order_By;
  rabbit_test_plan_project_arr_rel_insert_input: Rabbit_Test_Plan_Project_Arr_Rel_Insert_Input;
  rabbit_test_plan_project_bool_exp: Rabbit_Test_Plan_Project_Bool_Exp;
  rabbit_test_plan_project_insert_input: Rabbit_Test_Plan_Project_Insert_Input;
  rabbit_test_plan_project_max_fields: Rabbit_Test_Plan_Project_Max_Fields;
  rabbit_test_plan_project_max_order_by: Rabbit_Test_Plan_Project_Max_Order_By;
  rabbit_test_plan_project_min_fields: Rabbit_Test_Plan_Project_Min_Fields;
  rabbit_test_plan_project_min_order_by: Rabbit_Test_Plan_Project_Min_Order_By;
  rabbit_test_plan_project_mutation_response: Rabbit_Test_Plan_Project_Mutation_Response;
  rabbit_test_plan_project_on_conflict: Rabbit_Test_Plan_Project_On_Conflict;
  rabbit_test_plan_project_order_by: Rabbit_Test_Plan_Project_Order_By;
  rabbit_test_plan_project_pk_columns_input: Rabbit_Test_Plan_Project_Pk_Columns_Input;
  rabbit_test_plan_project_set_input: Rabbit_Test_Plan_Project_Set_Input;
  rabbit_test_plan_project_stream_cursor_input: Rabbit_Test_Plan_Project_Stream_Cursor_Input;
  rabbit_test_plan_project_stream_cursor_value_input: Rabbit_Test_Plan_Project_Stream_Cursor_Value_Input;
  rabbit_test_plan_project_updates: Rabbit_Test_Plan_Project_Updates;
  rabbit_test_plan_recording: Rabbit_Test_Plan_Recording;
  rabbit_test_plan_recording_aggregate: Rabbit_Test_Plan_Recording_Aggregate;
  rabbit_test_plan_recording_aggregate_fields: Rabbit_Test_Plan_Recording_Aggregate_Fields;
  rabbit_test_plan_recording_aggregate_order_by: Rabbit_Test_Plan_Recording_Aggregate_Order_By;
  rabbit_test_plan_recording_arr_rel_insert_input: Rabbit_Test_Plan_Recording_Arr_Rel_Insert_Input;
  rabbit_test_plan_recording_bool_exp: Rabbit_Test_Plan_Recording_Bool_Exp;
  rabbit_test_plan_recording_insert_input: Rabbit_Test_Plan_Recording_Insert_Input;
  rabbit_test_plan_recording_max_fields: Rabbit_Test_Plan_Recording_Max_Fields;
  rabbit_test_plan_recording_max_order_by: Rabbit_Test_Plan_Recording_Max_Order_By;
  rabbit_test_plan_recording_min_fields: Rabbit_Test_Plan_Recording_Min_Fields;
  rabbit_test_plan_recording_min_order_by: Rabbit_Test_Plan_Recording_Min_Order_By;
  rabbit_test_plan_recording_mutation_response: Rabbit_Test_Plan_Recording_Mutation_Response;
  rabbit_test_plan_recording_obj_rel_insert_input: Rabbit_Test_Plan_Recording_Obj_Rel_Insert_Input;
  rabbit_test_plan_recording_on_conflict: Rabbit_Test_Plan_Recording_On_Conflict;
  rabbit_test_plan_recording_order_by: Rabbit_Test_Plan_Recording_Order_By;
  rabbit_test_plan_recording_pk_columns_input: Rabbit_Test_Plan_Recording_Pk_Columns_Input;
  rabbit_test_plan_recording_set_input: Rabbit_Test_Plan_Recording_Set_Input;
  rabbit_test_plan_recording_stream_cursor_input: Rabbit_Test_Plan_Recording_Stream_Cursor_Input;
  rabbit_test_plan_recording_stream_cursor_value_input: Rabbit_Test_Plan_Recording_Stream_Cursor_Value_Input;
  rabbit_test_plan_recording_updates: Rabbit_Test_Plan_Recording_Updates;
  rabbit_test_plan_task: Rabbit_Test_Plan_Task;
  rabbit_test_plan_task_aggregate: Rabbit_Test_Plan_Task_Aggregate;
  rabbit_test_plan_task_aggregate_fields: Rabbit_Test_Plan_Task_Aggregate_Fields;
  rabbit_test_plan_task_aggregate_order_by: Rabbit_Test_Plan_Task_Aggregate_Order_By;
  rabbit_test_plan_task_arr_rel_insert_input: Rabbit_Test_Plan_Task_Arr_Rel_Insert_Input;
  rabbit_test_plan_task_bool_exp: Rabbit_Test_Plan_Task_Bool_Exp;
  rabbit_test_plan_task_insert_input: Rabbit_Test_Plan_Task_Insert_Input;
  rabbit_test_plan_task_max_fields: Rabbit_Test_Plan_Task_Max_Fields;
  rabbit_test_plan_task_max_order_by: Rabbit_Test_Plan_Task_Max_Order_By;
  rabbit_test_plan_task_min_fields: Rabbit_Test_Plan_Task_Min_Fields;
  rabbit_test_plan_task_min_order_by: Rabbit_Test_Plan_Task_Min_Order_By;
  rabbit_test_plan_task_mutation_response: Rabbit_Test_Plan_Task_Mutation_Response;
  rabbit_test_plan_task_on_conflict: Rabbit_Test_Plan_Task_On_Conflict;
  rabbit_test_plan_task_order_by: Rabbit_Test_Plan_Task_Order_By;
  rabbit_test_plan_task_pk_columns_input: Rabbit_Test_Plan_Task_Pk_Columns_Input;
  rabbit_test_plan_task_set_input: Rabbit_Test_Plan_Task_Set_Input;
  rabbit_test_plan_task_stream_cursor_input: Rabbit_Test_Plan_Task_Stream_Cursor_Input;
  rabbit_test_plan_task_stream_cursor_value_input: Rabbit_Test_Plan_Task_Stream_Cursor_Value_Input;
  rabbit_test_plan_task_updates: Rabbit_Test_Plan_Task_Updates;
  rabbit_test_plans: Rabbit_Test_Plans;
  rabbit_test_plans_aggregate: Rabbit_Test_Plans_Aggregate;
  rabbit_test_plans_aggregate_fields: Rabbit_Test_Plans_Aggregate_Fields;
  rabbit_test_plans_bool_exp: Rabbit_Test_Plans_Bool_Exp;
  rabbit_test_plans_insert_input: Rabbit_Test_Plans_Insert_Input;
  rabbit_test_plans_max_fields: Rabbit_Test_Plans_Max_Fields;
  rabbit_test_plans_min_fields: Rabbit_Test_Plans_Min_Fields;
  rabbit_test_plans_mutation_response: Rabbit_Test_Plans_Mutation_Response;
  rabbit_test_plans_obj_rel_insert_input: Rabbit_Test_Plans_Obj_Rel_Insert_Input;
  rabbit_test_plans_on_conflict: Rabbit_Test_Plans_On_Conflict;
  rabbit_test_plans_order_by: Rabbit_Test_Plans_Order_By;
  rabbit_test_plans_pk_columns_input: Rabbit_Test_Plans_Pk_Columns_Input;
  rabbit_test_plans_set_input: Rabbit_Test_Plans_Set_Input;
  rabbit_test_plans_stream_cursor_input: Rabbit_Test_Plans_Stream_Cursor_Input;
  rabbit_test_plans_stream_cursor_value_input: Rabbit_Test_Plans_Stream_Cursor_Value_Input;
  rabbit_test_plans_updates: Rabbit_Test_Plans_Updates;
  recording_apps: Recording_Apps;
  recording_apps_aggregate: Recording_Apps_Aggregate;
  recording_apps_aggregate_fields: Recording_Apps_Aggregate_Fields;
  recording_apps_bool_exp: Recording_Apps_Bool_Exp;
  recording_apps_insert_input: Recording_Apps_Insert_Input;
  recording_apps_max_fields: Recording_Apps_Max_Fields;
  recording_apps_min_fields: Recording_Apps_Min_Fields;
  recording_apps_mutation_response: Recording_Apps_Mutation_Response;
  recording_apps_obj_rel_insert_input: Recording_Apps_Obj_Rel_Insert_Input;
  recording_apps_on_conflict: Recording_Apps_On_Conflict;
  recording_apps_order_by: Recording_Apps_Order_By;
  recording_apps_pk_columns_input: Recording_Apps_Pk_Columns_Input;
  recording_apps_set_input: Recording_Apps_Set_Input;
  recording_apps_stream_cursor_input: Recording_Apps_Stream_Cursor_Input;
  recording_apps_stream_cursor_value_input: Recording_Apps_Stream_Cursor_Value_Input;
  recording_apps_updates: Recording_Apps_Updates;
  recording_audio_driver: Recording_Audio_Driver;
  recording_audio_driver_aggregate_order_by: Recording_Audio_Driver_Aggregate_Order_By;
  recording_audio_driver_arr_rel_insert_input: Recording_Audio_Driver_Arr_Rel_Insert_Input;
  recording_audio_driver_bool_exp: Recording_Audio_Driver_Bool_Exp;
  recording_audio_driver_insert_input: Recording_Audio_Driver_Insert_Input;
  recording_audio_driver_max_order_by: Recording_Audio_Driver_Max_Order_By;
  recording_audio_driver_min_order_by: Recording_Audio_Driver_Min_Order_By;
  recording_audio_driver_mutation_response: Recording_Audio_Driver_Mutation_Response;
  recording_audio_driver_on_conflict: Recording_Audio_Driver_On_Conflict;
  recording_audio_driver_order_by: Recording_Audio_Driver_Order_By;
  recording_audio_driver_pk_columns_input: Recording_Audio_Driver_Pk_Columns_Input;
  recording_audio_driver_set_input: Recording_Audio_Driver_Set_Input;
  recording_audio_driver_stream_cursor_input: Recording_Audio_Driver_Stream_Cursor_Input;
  recording_audio_driver_stream_cursor_value_input: Recording_Audio_Driver_Stream_Cursor_Value_Input;
  recording_audio_driver_updates: Recording_Audio_Driver_Updates;
  recording_data_ground_truth: Recording_Data_Ground_Truth;
  recording_data_ground_truth_aggregate: Recording_Data_Ground_Truth_Aggregate;
  recording_data_ground_truth_aggregate_fields: Recording_Data_Ground_Truth_Aggregate_Fields;
  recording_data_ground_truth_avg_fields: Recording_Data_Ground_Truth_Avg_Fields;
  recording_data_ground_truth_bool_exp: Recording_Data_Ground_Truth_Bool_Exp;
  recording_data_ground_truth_inc_input: Recording_Data_Ground_Truth_Inc_Input;
  recording_data_ground_truth_insert_input: Recording_Data_Ground_Truth_Insert_Input;
  recording_data_ground_truth_max_fields: Recording_Data_Ground_Truth_Max_Fields;
  recording_data_ground_truth_min_fields: Recording_Data_Ground_Truth_Min_Fields;
  recording_data_ground_truth_mutation_response: Recording_Data_Ground_Truth_Mutation_Response;
  recording_data_ground_truth_obj_rel_insert_input: Recording_Data_Ground_Truth_Obj_Rel_Insert_Input;
  recording_data_ground_truth_on_conflict: Recording_Data_Ground_Truth_On_Conflict;
  recording_data_ground_truth_order_by: Recording_Data_Ground_Truth_Order_By;
  recording_data_ground_truth_pk_columns_input: Recording_Data_Ground_Truth_Pk_Columns_Input;
  recording_data_ground_truth_set_input: Recording_Data_Ground_Truth_Set_Input;
  recording_data_ground_truth_stddev_fields: Recording_Data_Ground_Truth_Stddev_Fields;
  recording_data_ground_truth_stddev_pop_fields: Recording_Data_Ground_Truth_Stddev_Pop_Fields;
  recording_data_ground_truth_stddev_samp_fields: Recording_Data_Ground_Truth_Stddev_Samp_Fields;
  recording_data_ground_truth_stream_cursor_input: Recording_Data_Ground_Truth_Stream_Cursor_Input;
  recording_data_ground_truth_stream_cursor_value_input: Recording_Data_Ground_Truth_Stream_Cursor_Value_Input;
  recording_data_ground_truth_sum_fields: Recording_Data_Ground_Truth_Sum_Fields;
  recording_data_ground_truth_updates: Recording_Data_Ground_Truth_Updates;
  recording_data_ground_truth_var_pop_fields: Recording_Data_Ground_Truth_Var_Pop_Fields;
  recording_data_ground_truth_var_samp_fields: Recording_Data_Ground_Truth_Var_Samp_Fields;
  recording_data_ground_truth_variance_fields: Recording_Data_Ground_Truth_Variance_Fields;
  recording_data_labels: Recording_Data_Labels;
  recording_data_labels_aggregate: Recording_Data_Labels_Aggregate;
  recording_data_labels_aggregate_fields: Recording_Data_Labels_Aggregate_Fields;
  recording_data_labels_aggregate_order_by: Recording_Data_Labels_Aggregate_Order_By;
  recording_data_labels_arr_rel_insert_input: Recording_Data_Labels_Arr_Rel_Insert_Input;
  recording_data_labels_avg_fields: Recording_Data_Labels_Avg_Fields;
  recording_data_labels_avg_order_by: Recording_Data_Labels_Avg_Order_By;
  recording_data_labels_bool_exp: Recording_Data_Labels_Bool_Exp;
  recording_data_labels_inc_input: Recording_Data_Labels_Inc_Input;
  recording_data_labels_insert_input: Recording_Data_Labels_Insert_Input;
  recording_data_labels_max_fields: Recording_Data_Labels_Max_Fields;
  recording_data_labels_max_order_by: Recording_Data_Labels_Max_Order_By;
  recording_data_labels_min_fields: Recording_Data_Labels_Min_Fields;
  recording_data_labels_min_order_by: Recording_Data_Labels_Min_Order_By;
  recording_data_labels_mutation_response: Recording_Data_Labels_Mutation_Response;
  recording_data_labels_on_conflict: Recording_Data_Labels_On_Conflict;
  recording_data_labels_order_by: Recording_Data_Labels_Order_By;
  recording_data_labels_pk_columns_input: Recording_Data_Labels_Pk_Columns_Input;
  recording_data_labels_set_input: Recording_Data_Labels_Set_Input;
  recording_data_labels_stddev_fields: Recording_Data_Labels_Stddev_Fields;
  recording_data_labels_stddev_order_by: Recording_Data_Labels_Stddev_Order_By;
  recording_data_labels_stddev_pop_fields: Recording_Data_Labels_Stddev_Pop_Fields;
  recording_data_labels_stddev_pop_order_by: Recording_Data_Labels_Stddev_Pop_Order_By;
  recording_data_labels_stddev_samp_fields: Recording_Data_Labels_Stddev_Samp_Fields;
  recording_data_labels_stddev_samp_order_by: Recording_Data_Labels_Stddev_Samp_Order_By;
  recording_data_labels_stream_cursor_input: Recording_Data_Labels_Stream_Cursor_Input;
  recording_data_labels_stream_cursor_value_input: Recording_Data_Labels_Stream_Cursor_Value_Input;
  recording_data_labels_sum_fields: Recording_Data_Labels_Sum_Fields;
  recording_data_labels_sum_order_by: Recording_Data_Labels_Sum_Order_By;
  recording_data_labels_updates: Recording_Data_Labels_Updates;
  recording_data_labels_var_pop_fields: Recording_Data_Labels_Var_Pop_Fields;
  recording_data_labels_var_pop_order_by: Recording_Data_Labels_Var_Pop_Order_By;
  recording_data_labels_var_samp_fields: Recording_Data_Labels_Var_Samp_Fields;
  recording_data_labels_var_samp_order_by: Recording_Data_Labels_Var_Samp_Order_By;
  recording_data_labels_variance_fields: Recording_Data_Labels_Variance_Fields;
  recording_data_labels_variance_order_by: Recording_Data_Labels_Variance_Order_By;
  recording_data_mode_parameter: Recording_Data_Mode_Parameter;
  recording_data_mode_parameter_aggregate: Recording_Data_Mode_Parameter_Aggregate;
  recording_data_mode_parameter_aggregate_fields: Recording_Data_Mode_Parameter_Aggregate_Fields;
  recording_data_mode_parameter_aggregate_order_by: Recording_Data_Mode_Parameter_Aggregate_Order_By;
  recording_data_mode_parameter_arr_rel_insert_input: Recording_Data_Mode_Parameter_Arr_Rel_Insert_Input;
  recording_data_mode_parameter_bool_exp: Recording_Data_Mode_Parameter_Bool_Exp;
  recording_data_mode_parameter_insert_input: Recording_Data_Mode_Parameter_Insert_Input;
  recording_data_mode_parameter_max_fields: Recording_Data_Mode_Parameter_Max_Fields;
  recording_data_mode_parameter_max_order_by: Recording_Data_Mode_Parameter_Max_Order_By;
  recording_data_mode_parameter_min_fields: Recording_Data_Mode_Parameter_Min_Fields;
  recording_data_mode_parameter_min_order_by: Recording_Data_Mode_Parameter_Min_Order_By;
  recording_data_mode_parameter_mutation_response: Recording_Data_Mode_Parameter_Mutation_Response;
  recording_data_mode_parameter_on_conflict: Recording_Data_Mode_Parameter_On_Conflict;
  recording_data_mode_parameter_order_by: Recording_Data_Mode_Parameter_Order_By;
  recording_data_mode_parameter_pk_columns_input: Recording_Data_Mode_Parameter_Pk_Columns_Input;
  recording_data_mode_parameter_set_input: Recording_Data_Mode_Parameter_Set_Input;
  recording_data_mode_parameter_stream_cursor_input: Recording_Data_Mode_Parameter_Stream_Cursor_Input;
  recording_data_mode_parameter_stream_cursor_value_input: Recording_Data_Mode_Parameter_Stream_Cursor_Value_Input;
  recording_data_mode_parameter_updates: Recording_Data_Mode_Parameter_Updates;
  recording_data_modes: Recording_Data_Modes;
  recording_data_modes_aggregate: Recording_Data_Modes_Aggregate;
  recording_data_modes_aggregate_fields: Recording_Data_Modes_Aggregate_Fields;
  recording_data_modes_bool_exp: Recording_Data_Modes_Bool_Exp;
  recording_data_modes_insert_input: Recording_Data_Modes_Insert_Input;
  recording_data_modes_max_fields: Recording_Data_Modes_Max_Fields;
  recording_data_modes_min_fields: Recording_Data_Modes_Min_Fields;
  recording_data_modes_mutation_response: Recording_Data_Modes_Mutation_Response;
  recording_data_modes_obj_rel_insert_input: Recording_Data_Modes_Obj_Rel_Insert_Input;
  recording_data_modes_on_conflict: Recording_Data_Modes_On_Conflict;
  recording_data_modes_order_by: Recording_Data_Modes_Order_By;
  recording_data_modes_pk_columns_input: Recording_Data_Modes_Pk_Columns_Input;
  recording_data_modes_set_input: Recording_Data_Modes_Set_Input;
  recording_data_modes_stream_cursor_input: Recording_Data_Modes_Stream_Cursor_Input;
  recording_data_modes_stream_cursor_value_input: Recording_Data_Modes_Stream_Cursor_Value_Input;
  recording_data_modes_updates: Recording_Data_Modes_Updates;
  recording_data_parameters: Recording_Data_Parameters;
  recording_data_parameters_aggregate: Recording_Data_Parameters_Aggregate;
  recording_data_parameters_aggregate_fields: Recording_Data_Parameters_Aggregate_Fields;
  recording_data_parameters_bool_exp: Recording_Data_Parameters_Bool_Exp;
  recording_data_parameters_insert_input: Recording_Data_Parameters_Insert_Input;
  recording_data_parameters_max_fields: Recording_Data_Parameters_Max_Fields;
  recording_data_parameters_min_fields: Recording_Data_Parameters_Min_Fields;
  recording_data_parameters_mutation_response: Recording_Data_Parameters_Mutation_Response;
  recording_data_parameters_obj_rel_insert_input: Recording_Data_Parameters_Obj_Rel_Insert_Input;
  recording_data_parameters_on_conflict: Recording_Data_Parameters_On_Conflict;
  recording_data_parameters_order_by: Recording_Data_Parameters_Order_By;
  recording_data_parameters_pk_columns_input: Recording_Data_Parameters_Pk_Columns_Input;
  recording_data_parameters_set_input: Recording_Data_Parameters_Set_Input;
  recording_data_parameters_stream_cursor_input: Recording_Data_Parameters_Stream_Cursor_Input;
  recording_data_parameters_stream_cursor_value_input: Recording_Data_Parameters_Stream_Cursor_Value_Input;
  recording_data_parameters_updates: Recording_Data_Parameters_Updates;
  recording_data_payload_pair: Recording_Data_Payload_Pair;
  recording_data_payload_pair_bool_exp: Recording_Data_Payload_Pair_Bool_Exp;
  recording_data_payload_pair_inc_input: Recording_Data_Payload_Pair_Inc_Input;
  recording_data_payload_pair_insert_input: Recording_Data_Payload_Pair_Insert_Input;
  recording_data_payload_pair_mutation_response: Recording_Data_Payload_Pair_Mutation_Response;
  recording_data_payload_pair_on_conflict: Recording_Data_Payload_Pair_On_Conflict;
  recording_data_payload_pair_order_by: Recording_Data_Payload_Pair_Order_By;
  recording_data_payload_pair_pk_columns_input: Recording_Data_Payload_Pair_Pk_Columns_Input;
  recording_data_payload_pair_set_input: Recording_Data_Payload_Pair_Set_Input;
  recording_data_payload_pair_stream_cursor_input: Recording_Data_Payload_Pair_Stream_Cursor_Input;
  recording_data_payload_pair_stream_cursor_value_input: Recording_Data_Payload_Pair_Stream_Cursor_Value_Input;
  recording_data_payload_pair_updates: Recording_Data_Payload_Pair_Updates;
  recording_data_predictions: Recording_Data_Predictions;
  recording_data_predictions_aggregate: Recording_Data_Predictions_Aggregate;
  recording_data_predictions_aggregate_fields: Recording_Data_Predictions_Aggregate_Fields;
  recording_data_predictions_aggregate_order_by: Recording_Data_Predictions_Aggregate_Order_By;
  recording_data_predictions_arr_rel_insert_input: Recording_Data_Predictions_Arr_Rel_Insert_Input;
  recording_data_predictions_bool_exp: Recording_Data_Predictions_Bool_Exp;
  recording_data_predictions_insert_input: Recording_Data_Predictions_Insert_Input;
  recording_data_predictions_max_fields: Recording_Data_Predictions_Max_Fields;
  recording_data_predictions_max_order_by: Recording_Data_Predictions_Max_Order_By;
  recording_data_predictions_min_fields: Recording_Data_Predictions_Min_Fields;
  recording_data_predictions_min_order_by: Recording_Data_Predictions_Min_Order_By;
  recording_data_predictions_mutation_response: Recording_Data_Predictions_Mutation_Response;
  recording_data_predictions_on_conflict: Recording_Data_Predictions_On_Conflict;
  recording_data_predictions_order_by: Recording_Data_Predictions_Order_By;
  recording_data_predictions_pk_columns_input: Recording_Data_Predictions_Pk_Columns_Input;
  recording_data_predictions_set_input: Recording_Data_Predictions_Set_Input;
  recording_data_predictions_stream_cursor_input: Recording_Data_Predictions_Stream_Cursor_Input;
  recording_data_predictions_stream_cursor_value_input: Recording_Data_Predictions_Stream_Cursor_Value_Input;
  recording_data_predictions_updates: Recording_Data_Predictions_Updates;
  recording_recording_tag: Recording_Recording_Tag;
  recording_recording_tag_aggregate_order_by: Recording_Recording_Tag_Aggregate_Order_By;
  recording_recording_tag_arr_rel_insert_input: Recording_Recording_Tag_Arr_Rel_Insert_Input;
  recording_recording_tag_avg_order_by: Recording_Recording_Tag_Avg_Order_By;
  recording_recording_tag_bool_exp: Recording_Recording_Tag_Bool_Exp;
  recording_recording_tag_inc_input: Recording_Recording_Tag_Inc_Input;
  recording_recording_tag_insert_input: Recording_Recording_Tag_Insert_Input;
  recording_recording_tag_max_order_by: Recording_Recording_Tag_Max_Order_By;
  recording_recording_tag_min_order_by: Recording_Recording_Tag_Min_Order_By;
  recording_recording_tag_mutation_response: Recording_Recording_Tag_Mutation_Response;
  recording_recording_tag_on_conflict: Recording_Recording_Tag_On_Conflict;
  recording_recording_tag_order_by: Recording_Recording_Tag_Order_By;
  recording_recording_tag_pk_columns_input: Recording_Recording_Tag_Pk_Columns_Input;
  recording_recording_tag_set_input: Recording_Recording_Tag_Set_Input;
  recording_recording_tag_stddev_order_by: Recording_Recording_Tag_Stddev_Order_By;
  recording_recording_tag_stddev_pop_order_by: Recording_Recording_Tag_Stddev_Pop_Order_By;
  recording_recording_tag_stddev_samp_order_by: Recording_Recording_Tag_Stddev_Samp_Order_By;
  recording_recording_tag_stream_cursor_input: Recording_Recording_Tag_Stream_Cursor_Input;
  recording_recording_tag_stream_cursor_value_input: Recording_Recording_Tag_Stream_Cursor_Value_Input;
  recording_recording_tag_sum_order_by: Recording_Recording_Tag_Sum_Order_By;
  recording_recording_tag_updates: Recording_Recording_Tag_Updates;
  recording_recording_tag_var_pop_order_by: Recording_Recording_Tag_Var_Pop_Order_By;
  recording_recording_tag_var_samp_order_by: Recording_Recording_Tag_Var_Samp_Order_By;
  recording_recording_tag_variance_order_by: Recording_Recording_Tag_Variance_Order_By;
  recording_tag_count_by_day_args: Recording_Tag_Count_By_Day_Args;
  recording_tags: Recording_Tags;
  recording_tags_bool_exp: Recording_Tags_Bool_Exp;
  recording_tags_insert_input: Recording_Tags_Insert_Input;
  recording_tags_mutation_response: Recording_Tags_Mutation_Response;
  recording_tags_obj_rel_insert_input: Recording_Tags_Obj_Rel_Insert_Input;
  recording_tags_on_conflict: Recording_Tags_On_Conflict;
  recording_tags_order_by: Recording_Tags_Order_By;
  recording_tags_pk_columns_input: Recording_Tags_Pk_Columns_Input;
  recording_tags_set_input: Recording_Tags_Set_Input;
  recording_tags_stream_cursor_input: Recording_Tags_Stream_Cursor_Input;
  recording_tags_stream_cursor_value_input: Recording_Tags_Stream_Cursor_Value_Input;
  recording_tags_updates: Recording_Tags_Updates;
  recordings: Recordings;
  recordings_aggregate: Recordings_Aggregate;
  recordings_aggregate_fields: Recordings_Aggregate_Fields;
  recordings_aggregate_order_by: Recordings_Aggregate_Order_By;
  recordings_arr_rel_insert_input: Recordings_Arr_Rel_Insert_Input;
  recordings_avg_fields: Recordings_Avg_Fields;
  recordings_avg_order_by: Recordings_Avg_Order_By;
  recordings_bool_exp: Recordings_Bool_Exp;
  recordings_inc_input: Recordings_Inc_Input;
  recordings_insert_input: Recordings_Insert_Input;
  recordings_max_fields: Recordings_Max_Fields;
  recordings_max_order_by: Recordings_Max_Order_By;
  recordings_min_fields: Recordings_Min_Fields;
  recordings_min_order_by: Recordings_Min_Order_By;
  recordings_mutation_response: Recordings_Mutation_Response;
  recordings_obj_rel_insert_input: Recordings_Obj_Rel_Insert_Input;
  recordings_on_conflict: Recordings_On_Conflict;
  recordings_order_by: Recordings_Order_By;
  recordings_pk_columns_input: Recordings_Pk_Columns_Input;
  recordings_set_input: Recordings_Set_Input;
  recordings_stddev_fields: Recordings_Stddev_Fields;
  recordings_stddev_order_by: Recordings_Stddev_Order_By;
  recordings_stddev_pop_fields: Recordings_Stddev_Pop_Fields;
  recordings_stddev_pop_order_by: Recordings_Stddev_Pop_Order_By;
  recordings_stddev_samp_fields: Recordings_Stddev_Samp_Fields;
  recordings_stddev_samp_order_by: Recordings_Stddev_Samp_Order_By;
  recordings_stream_cursor_input: Recordings_Stream_Cursor_Input;
  recordings_stream_cursor_value_input: Recordings_Stream_Cursor_Value_Input;
  recordings_sum_fields: Recordings_Sum_Fields;
  recordings_sum_order_by: Recordings_Sum_Order_By;
  recordings_updates: Recordings_Updates;
  recordings_var_pop_fields: Recordings_Var_Pop_Fields;
  recordings_var_pop_order_by: Recordings_Var_Pop_Order_By;
  recordings_var_samp_fields: Recordings_Var_Samp_Fields;
  recordings_var_samp_order_by: Recordings_Var_Samp_Order_By;
  recordings_variance_fields: Recordings_Variance_Fields;
  recordings_variance_order_by: Recordings_Variance_Order_By;
  role_user: Role_User;
  role_user_aggregate: Role_User_Aggregate;
  role_user_aggregate_fields: Role_User_Aggregate_Fields;
  role_user_aggregate_order_by: Role_User_Aggregate_Order_By;
  role_user_arr_rel_insert_input: Role_User_Arr_Rel_Insert_Input;
  role_user_bool_exp: Role_User_Bool_Exp;
  role_user_insert_input: Role_User_Insert_Input;
  role_user_max_fields: Role_User_Max_Fields;
  role_user_max_order_by: Role_User_Max_Order_By;
  role_user_min_fields: Role_User_Min_Fields;
  role_user_min_order_by: Role_User_Min_Order_By;
  role_user_mutation_response: Role_User_Mutation_Response;
  role_user_on_conflict: Role_User_On_Conflict;
  role_user_order_by: Role_User_Order_By;
  role_user_pk_columns_input: Role_User_Pk_Columns_Input;
  role_user_set_input: Role_User_Set_Input;
  role_user_stream_cursor_input: Role_User_Stream_Cursor_Input;
  role_user_stream_cursor_value_input: Role_User_Stream_Cursor_Value_Input;
  role_user_updates: Role_User_Updates;
  roles: Roles;
  roles_aggregate: Roles_Aggregate;
  roles_aggregate_fields: Roles_Aggregate_Fields;
  roles_bool_exp: Roles_Bool_Exp;
  roles_enum_comparison_exp: Roles_Enum_Comparison_Exp;
  roles_max_fields: Roles_Max_Fields;
  roles_min_fields: Roles_Min_Fields;
  roles_mutation_response: Roles_Mutation_Response;
  roles_order_by: Roles_Order_By;
  roles_pk_columns_input: Roles_Pk_Columns_Input;
  roles_set_input: Roles_Set_Input;
  roles_stream_cursor_input: Roles_Stream_Cursor_Input;
  roles_stream_cursor_value_input: Roles_Stream_Cursor_Value_Input;
  roles_updates: Roles_Updates;
  rooms: Rooms;
  rooms_bool_exp: Rooms_Bool_Exp;
  rooms_insert_input: Rooms_Insert_Input;
  rooms_mutation_response: Rooms_Mutation_Response;
  rooms_obj_rel_insert_input: Rooms_Obj_Rel_Insert_Input;
  rooms_on_conflict: Rooms_On_Conflict;
  rooms_order_by: Rooms_Order_By;
  rooms_pk_columns_input: Rooms_Pk_Columns_Input;
  rooms_set_input: Rooms_Set_Input;
  rooms_stream_cursor_input: Rooms_Stream_Cursor_Input;
  rooms_stream_cursor_value_input: Rooms_Stream_Cursor_Value_Input;
  rooms_updates: Rooms_Updates;
  schema_project_engine_metrics: Schema_Project_Engine_Metrics;
  schema_project_engine_metrics_bool_exp: Schema_Project_Engine_Metrics_Bool_Exp;
  schema_project_engine_metrics_order_by: Schema_Project_Engine_Metrics_Order_By;
  schema_project_engine_metrics_stream_cursor_input: Schema_Project_Engine_Metrics_Stream_Cursor_Input;
  schema_project_engine_metrics_stream_cursor_value_input: Schema_Project_Engine_Metrics_Stream_Cursor_Value_Input;
  schema_project_engine_ratio_metrics: Schema_Project_Engine_Ratio_Metrics;
  schema_project_engine_ratio_metrics_aggregate: Schema_Project_Engine_Ratio_Metrics_Aggregate;
  schema_project_engine_ratio_metrics_aggregate_fields: Schema_Project_Engine_Ratio_Metrics_Aggregate_Fields;
  schema_project_engine_ratio_metrics_avg_fields: Schema_Project_Engine_Ratio_Metrics_Avg_Fields;
  schema_project_engine_ratio_metrics_bool_exp: Schema_Project_Engine_Ratio_Metrics_Bool_Exp;
  schema_project_engine_ratio_metrics_max_fields: Schema_Project_Engine_Ratio_Metrics_Max_Fields;
  schema_project_engine_ratio_metrics_min_fields: Schema_Project_Engine_Ratio_Metrics_Min_Fields;
  schema_project_engine_ratio_metrics_order_by: Schema_Project_Engine_Ratio_Metrics_Order_By;
  schema_project_engine_ratio_metrics_stddev_fields: Schema_Project_Engine_Ratio_Metrics_Stddev_Fields;
  schema_project_engine_ratio_metrics_stddev_pop_fields: Schema_Project_Engine_Ratio_Metrics_Stddev_Pop_Fields;
  schema_project_engine_ratio_metrics_stddev_samp_fields: Schema_Project_Engine_Ratio_Metrics_Stddev_Samp_Fields;
  schema_project_engine_ratio_metrics_stream_cursor_input: Schema_Project_Engine_Ratio_Metrics_Stream_Cursor_Input;
  schema_project_engine_ratio_metrics_stream_cursor_value_input: Schema_Project_Engine_Ratio_Metrics_Stream_Cursor_Value_Input;
  schema_project_engine_ratio_metrics_sum_fields: Schema_Project_Engine_Ratio_Metrics_Sum_Fields;
  schema_project_engine_ratio_metrics_var_pop_fields: Schema_Project_Engine_Ratio_Metrics_Var_Pop_Fields;
  schema_project_engine_ratio_metrics_var_samp_fields: Schema_Project_Engine_Ratio_Metrics_Var_Samp_Fields;
  schema_project_engine_ratio_metrics_variance_fields: Schema_Project_Engine_Ratio_Metrics_Variance_Fields;
  schema_project_task_count: Schema_Project_Task_Count;
  schema_project_task_count_aggregate: Schema_Project_Task_Count_Aggregate;
  schema_project_task_count_aggregate_fields: Schema_Project_Task_Count_Aggregate_Fields;
  schema_project_task_count_avg_fields: Schema_Project_Task_Count_Avg_Fields;
  schema_project_task_count_bool_exp: Schema_Project_Task_Count_Bool_Exp;
  schema_project_task_count_by_device: Schema_Project_Task_Count_By_Device;
  schema_project_task_count_by_device_aggregate: Schema_Project_Task_Count_By_Device_Aggregate;
  schema_project_task_count_by_device_aggregate_fields: Schema_Project_Task_Count_By_Device_Aggregate_Fields;
  schema_project_task_count_by_device_avg_fields: Schema_Project_Task_Count_By_Device_Avg_Fields;
  schema_project_task_count_by_device_bool_exp: Schema_Project_Task_Count_By_Device_Bool_Exp;
  schema_project_task_count_by_device_max_fields: Schema_Project_Task_Count_By_Device_Max_Fields;
  schema_project_task_count_by_device_min_fields: Schema_Project_Task_Count_By_Device_Min_Fields;
  schema_project_task_count_by_device_order_by: Schema_Project_Task_Count_By_Device_Order_By;
  schema_project_task_count_by_device_stddev_fields: Schema_Project_Task_Count_By_Device_Stddev_Fields;
  schema_project_task_count_by_device_stddev_pop_fields: Schema_Project_Task_Count_By_Device_Stddev_Pop_Fields;
  schema_project_task_count_by_device_stddev_samp_fields: Schema_Project_Task_Count_By_Device_Stddev_Samp_Fields;
  schema_project_task_count_by_device_stream_cursor_input: Schema_Project_Task_Count_By_Device_Stream_Cursor_Input;
  schema_project_task_count_by_device_stream_cursor_value_input: Schema_Project_Task_Count_By_Device_Stream_Cursor_Value_Input;
  schema_project_task_count_by_device_sum_fields: Schema_Project_Task_Count_By_Device_Sum_Fields;
  schema_project_task_count_by_device_var_pop_fields: Schema_Project_Task_Count_By_Device_Var_Pop_Fields;
  schema_project_task_count_by_device_var_samp_fields: Schema_Project_Task_Count_By_Device_Var_Samp_Fields;
  schema_project_task_count_by_device_variance_fields: Schema_Project_Task_Count_By_Device_Variance_Fields;
  schema_project_task_count_max_fields: Schema_Project_Task_Count_Max_Fields;
  schema_project_task_count_min_fields: Schema_Project_Task_Count_Min_Fields;
  schema_project_task_count_order_by: Schema_Project_Task_Count_Order_By;
  schema_project_task_count_stddev_fields: Schema_Project_Task_Count_Stddev_Fields;
  schema_project_task_count_stddev_pop_fields: Schema_Project_Task_Count_Stddev_Pop_Fields;
  schema_project_task_count_stddev_samp_fields: Schema_Project_Task_Count_Stddev_Samp_Fields;
  schema_project_task_count_stream_cursor_input: Schema_Project_Task_Count_Stream_Cursor_Input;
  schema_project_task_count_stream_cursor_value_input: Schema_Project_Task_Count_Stream_Cursor_Value_Input;
  schema_project_task_count_sum_fields: Schema_Project_Task_Count_Sum_Fields;
  schema_project_task_count_var_pop_fields: Schema_Project_Task_Count_Var_Pop_Fields;
  schema_project_task_count_var_samp_fields: Schema_Project_Task_Count_Var_Samp_Fields;
  schema_project_task_count_variance_fields: Schema_Project_Task_Count_Variance_Fields;
  schema_recording_tag_count_by_day: Schema_Recording_Tag_Count_By_Day;
  schema_recording_tag_count_by_day_aggregate: Schema_Recording_Tag_Count_By_Day_Aggregate;
  schema_recording_tag_count_by_day_aggregate_fields: Schema_Recording_Tag_Count_By_Day_Aggregate_Fields;
  schema_recording_tag_count_by_day_avg_fields: Schema_Recording_Tag_Count_By_Day_Avg_Fields;
  schema_recording_tag_count_by_day_bool_exp: Schema_Recording_Tag_Count_By_Day_Bool_Exp;
  schema_recording_tag_count_by_day_max_fields: Schema_Recording_Tag_Count_By_Day_Max_Fields;
  schema_recording_tag_count_by_day_min_fields: Schema_Recording_Tag_Count_By_Day_Min_Fields;
  schema_recording_tag_count_by_day_order_by: Schema_Recording_Tag_Count_By_Day_Order_By;
  schema_recording_tag_count_by_day_stddev_fields: Schema_Recording_Tag_Count_By_Day_Stddev_Fields;
  schema_recording_tag_count_by_day_stddev_pop_fields: Schema_Recording_Tag_Count_By_Day_Stddev_Pop_Fields;
  schema_recording_tag_count_by_day_stddev_samp_fields: Schema_Recording_Tag_Count_By_Day_Stddev_Samp_Fields;
  schema_recording_tag_count_by_day_stream_cursor_input: Schema_Recording_Tag_Count_By_Day_Stream_Cursor_Input;
  schema_recording_tag_count_by_day_stream_cursor_value_input: Schema_Recording_Tag_Count_By_Day_Stream_Cursor_Value_Input;
  schema_recording_tag_count_by_day_sum_fields: Schema_Recording_Tag_Count_By_Day_Sum_Fields;
  schema_recording_tag_count_by_day_var_pop_fields: Schema_Recording_Tag_Count_By_Day_Var_Pop_Fields;
  schema_recording_tag_count_by_day_var_samp_fields: Schema_Recording_Tag_Count_By_Day_Var_Samp_Fields;
  schema_recording_tag_count_by_day_variance_fields: Schema_Recording_Tag_Count_By_Day_Variance_Fields;
  schema_upsert_task: Schema_Upsert_Task;
  schema_upsert_task_bool_exp: Schema_Upsert_Task_Bool_Exp;
  schema_upsert_task_order_by: Schema_Upsert_Task_Order_By;
  simulations_scores: Simulations_Scores;
  simulations_scores_aggregate_order_by: Simulations_Scores_Aggregate_Order_By;
  simulations_scores_arr_rel_insert_input: Simulations_Scores_Arr_Rel_Insert_Input;
  simulations_scores_avg_order_by: Simulations_Scores_Avg_Order_By;
  simulations_scores_bool_exp: Simulations_Scores_Bool_Exp;
  simulations_scores_inc_input: Simulations_Scores_Inc_Input;
  simulations_scores_insert_input: Simulations_Scores_Insert_Input;
  simulations_scores_max_order_by: Simulations_Scores_Max_Order_By;
  simulations_scores_min_order_by: Simulations_Scores_Min_Order_By;
  simulations_scores_mutation_response: Simulations_Scores_Mutation_Response;
  simulations_scores_on_conflict: Simulations_Scores_On_Conflict;
  simulations_scores_order_by: Simulations_Scores_Order_By;
  simulations_scores_pk_columns_input: Simulations_Scores_Pk_Columns_Input;
  simulations_scores_set_input: Simulations_Scores_Set_Input;
  simulations_scores_stddev_order_by: Simulations_Scores_Stddev_Order_By;
  simulations_scores_stddev_pop_order_by: Simulations_Scores_Stddev_Pop_Order_By;
  simulations_scores_stddev_samp_order_by: Simulations_Scores_Stddev_Samp_Order_By;
  simulations_scores_stream_cursor_input: Simulations_Scores_Stream_Cursor_Input;
  simulations_scores_stream_cursor_value_input: Simulations_Scores_Stream_Cursor_Value_Input;
  simulations_scores_sum_order_by: Simulations_Scores_Sum_Order_By;
  simulations_scores_updates: Simulations_Scores_Updates;
  simulations_scores_var_pop_order_by: Simulations_Scores_Var_Pop_Order_By;
  simulations_scores_var_samp_order_by: Simulations_Scores_Var_Samp_Order_By;
  simulations_scores_variance_order_by: Simulations_Scores_Variance_Order_By;
  smallint: Scalars['smallint'];
  smallint_comparison_exp: Smallint_Comparison_Exp;
  subscription_root: {};
  task_field_option: Task_Field_Option;
  task_field_option_aggregate: Task_Field_Option_Aggregate;
  task_field_option_aggregate_fields: Task_Field_Option_Aggregate_Fields;
  task_field_option_aggregate_order_by: Task_Field_Option_Aggregate_Order_By;
  task_field_option_arr_rel_insert_input: Task_Field_Option_Arr_Rel_Insert_Input;
  task_field_option_bool_exp: Task_Field_Option_Bool_Exp;
  task_field_option_hashes: Task_Field_Option_Hashes;
  task_field_option_hashes_aggregate: Task_Field_Option_Hashes_Aggregate;
  task_field_option_hashes_aggregate_fields: Task_Field_Option_Hashes_Aggregate_Fields;
  task_field_option_hashes_bool_exp: Task_Field_Option_Hashes_Bool_Exp;
  task_field_option_hashes_max_fields: Task_Field_Option_Hashes_Max_Fields;
  task_field_option_hashes_min_fields: Task_Field_Option_Hashes_Min_Fields;
  task_field_option_hashes_order_by: Task_Field_Option_Hashes_Order_By;
  task_field_option_hashes_stream_cursor_input: Task_Field_Option_Hashes_Stream_Cursor_Input;
  task_field_option_hashes_stream_cursor_value_input: Task_Field_Option_Hashes_Stream_Cursor_Value_Input;
  task_field_option_insert_input: Task_Field_Option_Insert_Input;
  task_field_option_max_fields: Task_Field_Option_Max_Fields;
  task_field_option_max_order_by: Task_Field_Option_Max_Order_By;
  task_field_option_min_fields: Task_Field_Option_Min_Fields;
  task_field_option_min_order_by: Task_Field_Option_Min_Order_By;
  task_field_option_mutation_response: Task_Field_Option_Mutation_Response;
  task_field_option_on_conflict: Task_Field_Option_On_Conflict;
  task_field_option_order_by: Task_Field_Option_Order_By;
  task_field_option_pk_columns_input: Task_Field_Option_Pk_Columns_Input;
  task_field_option_set_input: Task_Field_Option_Set_Input;
  task_field_option_stream_cursor_input: Task_Field_Option_Stream_Cursor_Input;
  task_field_option_stream_cursor_value_input: Task_Field_Option_Stream_Cursor_Value_Input;
  task_field_option_updates: Task_Field_Option_Updates;
  task_groups_group_fields: Task_Groups_Group_Fields;
  task_groups_group_fields_aggregate: Task_Groups_Group_Fields_Aggregate;
  task_groups_group_fields_aggregate_fields: Task_Groups_Group_Fields_Aggregate_Fields;
  task_groups_group_fields_aggregate_order_by: Task_Groups_Group_Fields_Aggregate_Order_By;
  task_groups_group_fields_arr_rel_insert_input: Task_Groups_Group_Fields_Arr_Rel_Insert_Input;
  task_groups_group_fields_avg_fields: Task_Groups_Group_Fields_Avg_Fields;
  task_groups_group_fields_avg_order_by: Task_Groups_Group_Fields_Avg_Order_By;
  task_groups_group_fields_bool_exp: Task_Groups_Group_Fields_Bool_Exp;
  task_groups_group_fields_inc_input: Task_Groups_Group_Fields_Inc_Input;
  task_groups_group_fields_insert_input: Task_Groups_Group_Fields_Insert_Input;
  task_groups_group_fields_max_fields: Task_Groups_Group_Fields_Max_Fields;
  task_groups_group_fields_max_order_by: Task_Groups_Group_Fields_Max_Order_By;
  task_groups_group_fields_min_fields: Task_Groups_Group_Fields_Min_Fields;
  task_groups_group_fields_min_order_by: Task_Groups_Group_Fields_Min_Order_By;
  task_groups_group_fields_mutation_response: Task_Groups_Group_Fields_Mutation_Response;
  task_groups_group_fields_on_conflict: Task_Groups_Group_Fields_On_Conflict;
  task_groups_group_fields_order_by: Task_Groups_Group_Fields_Order_By;
  task_groups_group_fields_pk_columns_input: Task_Groups_Group_Fields_Pk_Columns_Input;
  task_groups_group_fields_set_input: Task_Groups_Group_Fields_Set_Input;
  task_groups_group_fields_stddev_fields: Task_Groups_Group_Fields_Stddev_Fields;
  task_groups_group_fields_stddev_order_by: Task_Groups_Group_Fields_Stddev_Order_By;
  task_groups_group_fields_stddev_pop_fields: Task_Groups_Group_Fields_Stddev_Pop_Fields;
  task_groups_group_fields_stddev_pop_order_by: Task_Groups_Group_Fields_Stddev_Pop_Order_By;
  task_groups_group_fields_stddev_samp_fields: Task_Groups_Group_Fields_Stddev_Samp_Fields;
  task_groups_group_fields_stddev_samp_order_by: Task_Groups_Group_Fields_Stddev_Samp_Order_By;
  task_groups_group_fields_stream_cursor_input: Task_Groups_Group_Fields_Stream_Cursor_Input;
  task_groups_group_fields_stream_cursor_value_input: Task_Groups_Group_Fields_Stream_Cursor_Value_Input;
  task_groups_group_fields_sum_fields: Task_Groups_Group_Fields_Sum_Fields;
  task_groups_group_fields_sum_order_by: Task_Groups_Group_Fields_Sum_Order_By;
  task_groups_group_fields_updates: Task_Groups_Group_Fields_Updates;
  task_groups_group_fields_var_pop_fields: Task_Groups_Group_Fields_Var_Pop_Fields;
  task_groups_group_fields_var_pop_order_by: Task_Groups_Group_Fields_Var_Pop_Order_By;
  task_groups_group_fields_var_samp_fields: Task_Groups_Group_Fields_Var_Samp_Fields;
  task_groups_group_fields_var_samp_order_by: Task_Groups_Group_Fields_Var_Samp_Order_By;
  task_groups_group_fields_variance_fields: Task_Groups_Group_Fields_Variance_Fields;
  task_groups_group_fields_variance_order_by: Task_Groups_Group_Fields_Variance_Order_By;
  task_groups_groups: Task_Groups_Groups;
  task_groups_groups_aggregate: Task_Groups_Groups_Aggregate;
  task_groups_groups_aggregate_fields: Task_Groups_Groups_Aggregate_Fields;
  task_groups_groups_bool_exp: Task_Groups_Groups_Bool_Exp;
  task_groups_groups_insert_input: Task_Groups_Groups_Insert_Input;
  task_groups_groups_max_fields: Task_Groups_Groups_Max_Fields;
  task_groups_groups_min_fields: Task_Groups_Groups_Min_Fields;
  task_groups_groups_mutation_response: Task_Groups_Groups_Mutation_Response;
  task_groups_groups_obj_rel_insert_input: Task_Groups_Groups_Obj_Rel_Insert_Input;
  task_groups_groups_on_conflict: Task_Groups_Groups_On_Conflict;
  task_groups_groups_order_by: Task_Groups_Groups_Order_By;
  task_groups_groups_pk_columns_input: Task_Groups_Groups_Pk_Columns_Input;
  task_groups_groups_set_input: Task_Groups_Groups_Set_Input;
  task_groups_groups_stream_cursor_input: Task_Groups_Groups_Stream_Cursor_Input;
  task_groups_groups_stream_cursor_value_input: Task_Groups_Groups_Stream_Cursor_Value_Input;
  task_groups_groups_updates: Task_Groups_Groups_Updates;
  task_groups_hierarchies: Task_Groups_Hierarchies;
  task_groups_hierarchies_aggregate: Task_Groups_Hierarchies_Aggregate;
  task_groups_hierarchies_aggregate_fields: Task_Groups_Hierarchies_Aggregate_Fields;
  task_groups_hierarchies_bool_exp: Task_Groups_Hierarchies_Bool_Exp;
  task_groups_hierarchies_insert_input: Task_Groups_Hierarchies_Insert_Input;
  task_groups_hierarchies_max_fields: Task_Groups_Hierarchies_Max_Fields;
  task_groups_hierarchies_min_fields: Task_Groups_Hierarchies_Min_Fields;
  task_groups_hierarchies_mutation_response: Task_Groups_Hierarchies_Mutation_Response;
  task_groups_hierarchies_obj_rel_insert_input: Task_Groups_Hierarchies_Obj_Rel_Insert_Input;
  task_groups_hierarchies_on_conflict: Task_Groups_Hierarchies_On_Conflict;
  task_groups_hierarchies_order_by: Task_Groups_Hierarchies_Order_By;
  task_groups_hierarchies_pk_columns_input: Task_Groups_Hierarchies_Pk_Columns_Input;
  task_groups_hierarchies_set_input: Task_Groups_Hierarchies_Set_Input;
  task_groups_hierarchies_stream_cursor_input: Task_Groups_Hierarchies_Stream_Cursor_Input;
  task_groups_hierarchies_stream_cursor_value_input: Task_Groups_Hierarchies_Stream_Cursor_Value_Input;
  task_groups_hierarchies_updates: Task_Groups_Hierarchies_Updates;
  task_groups_hierarchy_groups: Task_Groups_Hierarchy_Groups;
  task_groups_hierarchy_groups_aggregate: Task_Groups_Hierarchy_Groups_Aggregate;
  task_groups_hierarchy_groups_aggregate_fields: Task_Groups_Hierarchy_Groups_Aggregate_Fields;
  task_groups_hierarchy_groups_aggregate_order_by: Task_Groups_Hierarchy_Groups_Aggregate_Order_By;
  task_groups_hierarchy_groups_arr_rel_insert_input: Task_Groups_Hierarchy_Groups_Arr_Rel_Insert_Input;
  task_groups_hierarchy_groups_avg_fields: Task_Groups_Hierarchy_Groups_Avg_Fields;
  task_groups_hierarchy_groups_avg_order_by: Task_Groups_Hierarchy_Groups_Avg_Order_By;
  task_groups_hierarchy_groups_bool_exp: Task_Groups_Hierarchy_Groups_Bool_Exp;
  task_groups_hierarchy_groups_inc_input: Task_Groups_Hierarchy_Groups_Inc_Input;
  task_groups_hierarchy_groups_insert_input: Task_Groups_Hierarchy_Groups_Insert_Input;
  task_groups_hierarchy_groups_max_fields: Task_Groups_Hierarchy_Groups_Max_Fields;
  task_groups_hierarchy_groups_max_order_by: Task_Groups_Hierarchy_Groups_Max_Order_By;
  task_groups_hierarchy_groups_min_fields: Task_Groups_Hierarchy_Groups_Min_Fields;
  task_groups_hierarchy_groups_min_order_by: Task_Groups_Hierarchy_Groups_Min_Order_By;
  task_groups_hierarchy_groups_mutation_response: Task_Groups_Hierarchy_Groups_Mutation_Response;
  task_groups_hierarchy_groups_on_conflict: Task_Groups_Hierarchy_Groups_On_Conflict;
  task_groups_hierarchy_groups_order_by: Task_Groups_Hierarchy_Groups_Order_By;
  task_groups_hierarchy_groups_pk_columns_input: Task_Groups_Hierarchy_Groups_Pk_Columns_Input;
  task_groups_hierarchy_groups_set_input: Task_Groups_Hierarchy_Groups_Set_Input;
  task_groups_hierarchy_groups_stddev_fields: Task_Groups_Hierarchy_Groups_Stddev_Fields;
  task_groups_hierarchy_groups_stddev_order_by: Task_Groups_Hierarchy_Groups_Stddev_Order_By;
  task_groups_hierarchy_groups_stddev_pop_fields: Task_Groups_Hierarchy_Groups_Stddev_Pop_Fields;
  task_groups_hierarchy_groups_stddev_pop_order_by: Task_Groups_Hierarchy_Groups_Stddev_Pop_Order_By;
  task_groups_hierarchy_groups_stddev_samp_fields: Task_Groups_Hierarchy_Groups_Stddev_Samp_Fields;
  task_groups_hierarchy_groups_stddev_samp_order_by: Task_Groups_Hierarchy_Groups_Stddev_Samp_Order_By;
  task_groups_hierarchy_groups_stream_cursor_input: Task_Groups_Hierarchy_Groups_Stream_Cursor_Input;
  task_groups_hierarchy_groups_stream_cursor_value_input: Task_Groups_Hierarchy_Groups_Stream_Cursor_Value_Input;
  task_groups_hierarchy_groups_sum_fields: Task_Groups_Hierarchy_Groups_Sum_Fields;
  task_groups_hierarchy_groups_sum_order_by: Task_Groups_Hierarchy_Groups_Sum_Order_By;
  task_groups_hierarchy_groups_updates: Task_Groups_Hierarchy_Groups_Updates;
  task_groups_hierarchy_groups_var_pop_fields: Task_Groups_Hierarchy_Groups_Var_Pop_Fields;
  task_groups_hierarchy_groups_var_pop_order_by: Task_Groups_Hierarchy_Groups_Var_Pop_Order_By;
  task_groups_hierarchy_groups_var_samp_fields: Task_Groups_Hierarchy_Groups_Var_Samp_Fields;
  task_groups_hierarchy_groups_var_samp_order_by: Task_Groups_Hierarchy_Groups_Var_Samp_Order_By;
  task_groups_hierarchy_groups_variance_fields: Task_Groups_Hierarchy_Groups_Variance_Fields;
  task_groups_hierarchy_groups_variance_order_by: Task_Groups_Hierarchy_Groups_Variance_Order_By;
  task_instructions: Task_Instructions;
  task_instructions_bool_exp: Task_Instructions_Bool_Exp;
  task_instructions_insert_input: Task_Instructions_Insert_Input;
  task_instructions_mutation_response: Task_Instructions_Mutation_Response;
  task_instructions_obj_rel_insert_input: Task_Instructions_Obj_Rel_Insert_Input;
  task_instructions_on_conflict: Task_Instructions_On_Conflict;
  task_instructions_order_by: Task_Instructions_Order_By;
  task_instructions_pk_columns_input: Task_Instructions_Pk_Columns_Input;
  task_instructions_set_input: Task_Instructions_Set_Input;
  task_instructions_stream_cursor_input: Task_Instructions_Stream_Cursor_Input;
  task_instructions_stream_cursor_value_input: Task_Instructions_Stream_Cursor_Value_Input;
  task_instructions_updates: Task_Instructions_Updates;
  task_media: Task_Media;
  task_media_aggregate: Task_Media_Aggregate;
  task_media_aggregate_fields: Task_Media_Aggregate_Fields;
  task_media_aggregate_order_by: Task_Media_Aggregate_Order_By;
  task_media_arr_rel_insert_input: Task_Media_Arr_Rel_Insert_Input;
  task_media_bool_exp: Task_Media_Bool_Exp;
  task_media_insert_input: Task_Media_Insert_Input;
  task_media_max_fields: Task_Media_Max_Fields;
  task_media_max_order_by: Task_Media_Max_Order_By;
  task_media_min_fields: Task_Media_Min_Fields;
  task_media_min_order_by: Task_Media_Min_Order_By;
  task_media_mutation_response: Task_Media_Mutation_Response;
  task_media_on_conflict: Task_Media_On_Conflict;
  task_media_order_by: Task_Media_Order_By;
  task_media_pk_columns_input: Task_Media_Pk_Columns_Input;
  task_media_set_input: Task_Media_Set_Input;
  task_media_stream_cursor_input: Task_Media_Stream_Cursor_Input;
  task_media_stream_cursor_value_input: Task_Media_Stream_Cursor_Value_Input;
  task_media_updates: Task_Media_Updates;
  task_statuses: Task_Statuses;
  task_statuses_bool_exp: Task_Statuses_Bool_Exp;
  task_statuses_order_by: Task_Statuses_Order_By;
  task_statuses_stream_cursor_input: Task_Statuses_Stream_Cursor_Input;
  task_statuses_stream_cursor_value_input: Task_Statuses_Stream_Cursor_Value_Input;
  task_translation: Task_Translation;
  task_translation_aggregate: Task_Translation_Aggregate;
  task_translation_aggregate_fields: Task_Translation_Aggregate_Fields;
  task_translation_aggregate_order_by: Task_Translation_Aggregate_Order_By;
  task_translation_arr_rel_insert_input: Task_Translation_Arr_Rel_Insert_Input;
  task_translation_bool_exp: Task_Translation_Bool_Exp;
  task_translation_insert_input: Task_Translation_Insert_Input;
  task_translation_max_fields: Task_Translation_Max_Fields;
  task_translation_max_order_by: Task_Translation_Max_Order_By;
  task_translation_min_fields: Task_Translation_Min_Fields;
  task_translation_min_order_by: Task_Translation_Min_Order_By;
  task_translation_mutation_response: Task_Translation_Mutation_Response;
  task_translation_on_conflict: Task_Translation_On_Conflict;
  task_translation_order_by: Task_Translation_Order_By;
  task_translation_pk_columns_input: Task_Translation_Pk_Columns_Input;
  task_translation_set_input: Task_Translation_Set_Input;
  task_translation_stream_cursor_input: Task_Translation_Stream_Cursor_Input;
  task_translation_stream_cursor_value_input: Task_Translation_Stream_Cursor_Value_Input;
  task_translation_updates: Task_Translation_Updates;
  tasks: Tasks;
  tasks_aggregate: Tasks_Aggregate;
  tasks_aggregate_fields: Tasks_Aggregate_Fields;
  tasks_avg_fields: Tasks_Avg_Fields;
  tasks_bool_exp: Tasks_Bool_Exp;
  tasks_inc_input: Tasks_Inc_Input;
  tasks_insert_input: Tasks_Insert_Input;
  tasks_max_fields: Tasks_Max_Fields;
  tasks_min_fields: Tasks_Min_Fields;
  tasks_mutation_response: Tasks_Mutation_Response;
  tasks_obj_rel_insert_input: Tasks_Obj_Rel_Insert_Input;
  tasks_on_conflict: Tasks_On_Conflict;
  tasks_order_by: Tasks_Order_By;
  tasks_pk_columns_input: Tasks_Pk_Columns_Input;
  tasks_set_input: Tasks_Set_Input;
  tasks_stddev_fields: Tasks_Stddev_Fields;
  tasks_stddev_pop_fields: Tasks_Stddev_Pop_Fields;
  tasks_stddev_samp_fields: Tasks_Stddev_Samp_Fields;
  tasks_stream_cursor_input: Tasks_Stream_Cursor_Input;
  tasks_stream_cursor_value_input: Tasks_Stream_Cursor_Value_Input;
  tasks_sum_fields: Tasks_Sum_Fields;
  tasks_updates: Tasks_Updates;
  tasks_var_pop_fields: Tasks_Var_Pop_Fields;
  tasks_var_samp_fields: Tasks_Var_Samp_Fields;
  tasks_variance_fields: Tasks_Variance_Fields;
  timestamp: Scalars['timestamp'];
  timestamp_comparison_exp: Timestamp_Comparison_Exp;
  timestamptz: Scalars['timestamptz'];
  timestamptz_comparison_exp: Timestamptz_Comparison_Exp;
  tool_versions: Tool_Versions;
  tool_versions_aggregate_order_by: Tool_Versions_Aggregate_Order_By;
  tool_versions_arr_rel_insert_input: Tool_Versions_Arr_Rel_Insert_Input;
  tool_versions_bool_exp: Tool_Versions_Bool_Exp;
  tool_versions_insert_input: Tool_Versions_Insert_Input;
  tool_versions_max_order_by: Tool_Versions_Max_Order_By;
  tool_versions_min_order_by: Tool_Versions_Min_Order_By;
  tool_versions_mutation_response: Tool_Versions_Mutation_Response;
  tool_versions_on_conflict: Tool_Versions_On_Conflict;
  tool_versions_order_by: Tool_Versions_Order_By;
  tool_versions_pk_columns_input: Tool_Versions_Pk_Columns_Input;
  tool_versions_set_input: Tool_Versions_Set_Input;
  tool_versions_stream_cursor_input: Tool_Versions_Stream_Cursor_Input;
  tool_versions_stream_cursor_value_input: Tool_Versions_Stream_Cursor_Value_Input;
  tool_versions_updates: Tool_Versions_Updates;
  tools: Tools;
  tools_bool_exp: Tools_Bool_Exp;
  tools_insert_input: Tools_Insert_Input;
  tools_mutation_response: Tools_Mutation_Response;
  tools_obj_rel_insert_input: Tools_Obj_Rel_Insert_Input;
  tools_on_conflict: Tools_On_Conflict;
  tools_order_by: Tools_Order_By;
  tools_pk_columns_input: Tools_Pk_Columns_Input;
  tools_set_input: Tools_Set_Input;
  tools_stream_cursor_input: Tools_Stream_Cursor_Input;
  tools_stream_cursor_value_input: Tools_Stream_Cursor_Value_Input;
  tools_updates: Tools_Updates;
  types: Types;
  types_aggregate: Types_Aggregate;
  types_aggregate_fields: Types_Aggregate_Fields;
  types_bool_exp: Types_Bool_Exp;
  types_insert_input: Types_Insert_Input;
  types_max_fields: Types_Max_Fields;
  types_min_fields: Types_Min_Fields;
  types_mutation_response: Types_Mutation_Response;
  types_obj_rel_insert_input: Types_Obj_Rel_Insert_Input;
  types_on_conflict: Types_On_Conflict;
  types_order_by: Types_Order_By;
  types_pk_columns_input: Types_Pk_Columns_Input;
  types_set_input: Types_Set_Input;
  types_stream_cursor_input: Types_Stream_Cursor_Input;
  types_stream_cursor_value_input: Types_Stream_Cursor_Value_Input;
  types_updates: Types_Updates;
  upsert_task_args: Upsert_Task_Args;
  user_recordings_by_day_stats: User_Recordings_By_Day_Stats;
  user_recordings_by_day_stats_aggregate: User_Recordings_By_Day_Stats_Aggregate;
  user_recordings_by_day_stats_aggregate_fields: User_Recordings_By_Day_Stats_Aggregate_Fields;
  user_recordings_by_day_stats_aggregate_order_by: User_Recordings_By_Day_Stats_Aggregate_Order_By;
  user_recordings_by_day_stats_avg_fields: User_Recordings_By_Day_Stats_Avg_Fields;
  user_recordings_by_day_stats_avg_order_by: User_Recordings_By_Day_Stats_Avg_Order_By;
  user_recordings_by_day_stats_bool_exp: User_Recordings_By_Day_Stats_Bool_Exp;
  user_recordings_by_day_stats_max_fields: User_Recordings_By_Day_Stats_Max_Fields;
  user_recordings_by_day_stats_max_order_by: User_Recordings_By_Day_Stats_Max_Order_By;
  user_recordings_by_day_stats_min_fields: User_Recordings_By_Day_Stats_Min_Fields;
  user_recordings_by_day_stats_min_order_by: User_Recordings_By_Day_Stats_Min_Order_By;
  user_recordings_by_day_stats_order_by: User_Recordings_By_Day_Stats_Order_By;
  user_recordings_by_day_stats_stddev_fields: User_Recordings_By_Day_Stats_Stddev_Fields;
  user_recordings_by_day_stats_stddev_order_by: User_Recordings_By_Day_Stats_Stddev_Order_By;
  user_recordings_by_day_stats_stddev_pop_fields: User_Recordings_By_Day_Stats_Stddev_Pop_Fields;
  user_recordings_by_day_stats_stddev_pop_order_by: User_Recordings_By_Day_Stats_Stddev_Pop_Order_By;
  user_recordings_by_day_stats_stddev_samp_fields: User_Recordings_By_Day_Stats_Stddev_Samp_Fields;
  user_recordings_by_day_stats_stddev_samp_order_by: User_Recordings_By_Day_Stats_Stddev_Samp_Order_By;
  user_recordings_by_day_stats_stream_cursor_input: User_Recordings_By_Day_Stats_Stream_Cursor_Input;
  user_recordings_by_day_stats_stream_cursor_value_input: User_Recordings_By_Day_Stats_Stream_Cursor_Value_Input;
  user_recordings_by_day_stats_sum_fields: User_Recordings_By_Day_Stats_Sum_Fields;
  user_recordings_by_day_stats_sum_order_by: User_Recordings_By_Day_Stats_Sum_Order_By;
  user_recordings_by_day_stats_var_pop_fields: User_Recordings_By_Day_Stats_Var_Pop_Fields;
  user_recordings_by_day_stats_var_pop_order_by: User_Recordings_By_Day_Stats_Var_Pop_Order_By;
  user_recordings_by_day_stats_var_samp_fields: User_Recordings_By_Day_Stats_Var_Samp_Fields;
  user_recordings_by_day_stats_var_samp_order_by: User_Recordings_By_Day_Stats_Var_Samp_Order_By;
  user_recordings_by_day_stats_variance_fields: User_Recordings_By_Day_Stats_Variance_Fields;
  user_recordings_by_day_stats_variance_order_by: User_Recordings_By_Day_Stats_Variance_Order_By;
  users: Users;
  users_aggregate: Users_Aggregate;
  users_aggregate_fields: Users_Aggregate_Fields;
  users_aggregate_order_by: Users_Aggregate_Order_By;
  users_arr_rel_insert_input: Users_Arr_Rel_Insert_Input;
  users_bool_exp: Users_Bool_Exp;
  users_insert_input: Users_Insert_Input;
  users_max_fields: Users_Max_Fields;
  users_max_order_by: Users_Max_Order_By;
  users_min_fields: Users_Min_Fields;
  users_min_order_by: Users_Min_Order_By;
  users_mutation_response: Users_Mutation_Response;
  users_obj_rel_insert_input: Users_Obj_Rel_Insert_Input;
  users_on_conflict: Users_On_Conflict;
  users_order_by: Users_Order_By;
  users_pk_columns_input: Users_Pk_Columns_Input;
  users_set_input: Users_Set_Input;
  users_stream_cursor_input: Users_Stream_Cursor_Input;
  users_stream_cursor_value_input: Users_Stream_Cursor_Value_Input;
  users_updates: Users_Updates;
  uuid: Scalars['uuid'];
  uuid_comparison_exp: Uuid_Comparison_Exp;
  xtensa_configurations: Xtensa_Configurations;
  xtensa_configurations_aggregate: Xtensa_Configurations_Aggregate;
  xtensa_configurations_aggregate_fields: Xtensa_Configurations_Aggregate_Fields;
  xtensa_configurations_bool_exp: Xtensa_Configurations_Bool_Exp;
  xtensa_configurations_insert_input: Xtensa_Configurations_Insert_Input;
  xtensa_configurations_max_fields: Xtensa_Configurations_Max_Fields;
  xtensa_configurations_min_fields: Xtensa_Configurations_Min_Fields;
  xtensa_configurations_mutation_response: Xtensa_Configurations_Mutation_Response;
  xtensa_configurations_obj_rel_insert_input: Xtensa_Configurations_Obj_Rel_Insert_Input;
  xtensa_configurations_on_conflict: Xtensa_Configurations_On_Conflict;
  xtensa_configurations_order_by: Xtensa_Configurations_Order_By;
  xtensa_configurations_pk_columns_input: Xtensa_Configurations_Pk_Columns_Input;
  xtensa_configurations_set_input: Xtensa_Configurations_Set_Input;
  xtensa_configurations_stream_cursor_input: Xtensa_Configurations_Stream_Cursor_Input;
  xtensa_configurations_stream_cursor_value_input: Xtensa_Configurations_Stream_Cursor_Value_Input;
  xtensa_configurations_updates: Xtensa_Configurations_Updates;
};

export type CachedDirectiveArgs = {   refresh?: Scalars['Boolean'];
  ttl?: Scalars['Int']; };

export type CachedDirectiveResolver<Result, Parent, ContextType = any, Args = CachedDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type ElapiSlackChannelResolvers<ContextType = any, ParentType extends ResolversParentTypes['ElapiSlackChannel'] = ResolversParentTypes['ElapiSlackChannel']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface _Int2ScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['_int2'], any> {
  name: '_int2';
}

export interface _Int4ScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['_int4'], any> {
  name: '_int4';
}

export interface _TextScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['_text'], any> {
  name: '_text';
}

export interface _UuidScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['_uuid'], any> {
  name: '_uuid';
}

export type Audio_DriverResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_driver'] = ResolversParentTypes['audio_driver']> = {
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recording_audio_drivers?: Resolver<Array<ResolversTypes['recording_audio_driver']>, ParentType, ContextType, RequireFields<Audio_DriverRecording_Audio_DriversArgs, never>>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Driver_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_driver_mutation_response'] = ResolversParentTypes['audio_driver_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['audio_driver']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_ComponentsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_components'] = ResolversParentTypes['audio_measurements_components']> = {
  configurations?: Resolver<Array<ResolversTypes['audio_measurements_configurations']>, ParentType, ContextType, RequireFields<Audio_Measurements_ComponentsConfigurationsArgs, never>>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  model?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Components_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_components_mutation_response'] = ResolversParentTypes['audio_measurements_components_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['audio_measurements_components']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_ConfigurationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_configurations'] = ResolversParentTypes['audio_measurements_configurations']> = {
  component?: Resolver<ResolversTypes['audio_measurements_components'], ParentType, ContextType>;
  component_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  devices?: Resolver<Array<ResolversTypes['devices']>, ParentType, ContextType, RequireFields<Audio_Measurements_ConfigurationsDevicesArgs, never>>;
  devices_aggregate?: Resolver<ResolversTypes['devices_aggregate'], ParentType, ContextType, RequireFields<Audio_Measurements_ConfigurationsDevices_AggregateArgs, never>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  model?: Resolver<ResolversTypes['models'], ParentType, ContextType>;
  model_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  orientation?: Resolver<ResolversTypes['audio_measurements_orientations'], ParentType, ContextType>;
  orientation_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  placement?: Resolver<ResolversTypes['audio_measurements_placements'], ParentType, ContextType>;
  placement_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  screen_size?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Configurations_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_configurations_mutation_response'] = ResolversParentTypes['audio_measurements_configurations_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['audio_measurements_configurations']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Data_TypesResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_data_types'] = ResolversParentTypes['audio_measurements_data_types']> = {
  comment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  master_files?: Resolver<Array<ResolversTypes['audio_measurements_master_files']>, ParentType, ContextType, RequireFields<Audio_Measurements_Data_TypesMaster_FilesArgs, never>>;
  master_files_aggregate?: Resolver<ResolversTypes['audio_measurements_master_files_aggregate'], ParentType, ContextType, RequireFields<Audio_Measurements_Data_TypesMaster_Files_AggregateArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Data_Types_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_data_types_aggregate'] = ResolversParentTypes['audio_measurements_data_types_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['audio_measurements_data_types_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['audio_measurements_data_types']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Data_Types_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_data_types_aggregate_fields'] = ResolversParentTypes['audio_measurements_data_types_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Audio_Measurements_Data_Types_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['audio_measurements_data_types_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['audio_measurements_data_types_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Data_Types_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_data_types_max_fields'] = ResolversParentTypes['audio_measurements_data_types_max_fields']> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Data_Types_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_data_types_min_fields'] = ResolversParentTypes['audio_measurements_data_types_min_fields']> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Master_FilesResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_master_files'] = ResolversParentTypes['audio_measurements_master_files']> = {
  chirp_start_freq?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  chirp_stop_freq?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  class_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  data_type?: Resolver<ResolversTypes['audio_measurements_data_types'], ParentType, ContextType>;
  data_type_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  num_channels?: Resolver<ResolversTypes['smallint'], ParentType, ContextType>;
  package_version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recordings?: Resolver<Array<ResolversTypes['audio_measurements_recordings']>, ParentType, ContextType, RequireFields<Audio_Measurements_Master_FilesRecordingsArgs, never>>;
  recordings_aggregate?: Resolver<ResolversTypes['audio_measurements_recordings_aggregate'], ParentType, ContextType, RequireFields<Audio_Measurements_Master_FilesRecordings_AggregateArgs, never>>;
  sample_freq?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  signal_amplitude?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  single_chirp_duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  total_chirp_duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  zero_padding_head_duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  zero_padding_tail_duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Master_Files_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_master_files_aggregate'] = ResolversParentTypes['audio_measurements_master_files_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['audio_measurements_master_files_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['audio_measurements_master_files']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Master_Files_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_master_files_aggregate_fields'] = ResolversParentTypes['audio_measurements_master_files_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['audio_measurements_master_files_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Audio_Measurements_Master_Files_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['audio_measurements_master_files_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['audio_measurements_master_files_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['audio_measurements_master_files_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['audio_measurements_master_files_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['audio_measurements_master_files_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['audio_measurements_master_files_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['audio_measurements_master_files_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['audio_measurements_master_files_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['audio_measurements_master_files_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Master_Files_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_master_files_avg_fields'] = ResolversParentTypes['audio_measurements_master_files_avg_fields']> = {
  chirp_start_freq?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  chirp_stop_freq?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_channels?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sample_freq?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  signal_amplitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  single_chirp_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_chirp_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zero_padding_head_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zero_padding_tail_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Master_Files_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_master_files_max_fields'] = ResolversParentTypes['audio_measurements_master_files_max_fields']> = {
  chirp_start_freq?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  chirp_stop_freq?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  class_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  data_type_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  num_channels?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  package_version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sample_freq?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  signal_amplitude?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  single_chirp_duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_chirp_duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  zero_padding_head_duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  zero_padding_tail_duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Master_Files_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_master_files_min_fields'] = ResolversParentTypes['audio_measurements_master_files_min_fields']> = {
  chirp_start_freq?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  chirp_stop_freq?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  class_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  data_type_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  num_channels?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  package_version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sample_freq?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  signal_amplitude?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  single_chirp_duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_chirp_duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  zero_padding_head_duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  zero_padding_tail_duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Master_Files_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_master_files_mutation_response'] = ResolversParentTypes['audio_measurements_master_files_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['audio_measurements_master_files']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Master_Files_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_master_files_stddev_fields'] = ResolversParentTypes['audio_measurements_master_files_stddev_fields']> = {
  chirp_start_freq?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  chirp_stop_freq?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_channels?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sample_freq?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  signal_amplitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  single_chirp_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_chirp_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zero_padding_head_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zero_padding_tail_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Master_Files_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_master_files_stddev_pop_fields'] = ResolversParentTypes['audio_measurements_master_files_stddev_pop_fields']> = {
  chirp_start_freq?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  chirp_stop_freq?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_channels?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sample_freq?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  signal_amplitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  single_chirp_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_chirp_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zero_padding_head_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zero_padding_tail_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Master_Files_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_master_files_stddev_samp_fields'] = ResolversParentTypes['audio_measurements_master_files_stddev_samp_fields']> = {
  chirp_start_freq?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  chirp_stop_freq?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_channels?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sample_freq?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  signal_amplitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  single_chirp_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_chirp_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zero_padding_head_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zero_padding_tail_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Master_Files_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_master_files_sum_fields'] = ResolversParentTypes['audio_measurements_master_files_sum_fields']> = {
  chirp_start_freq?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  chirp_stop_freq?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  num_channels?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  sample_freq?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  signal_amplitude?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  single_chirp_duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_chirp_duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  zero_padding_head_duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  zero_padding_tail_duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Master_Files_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_master_files_var_pop_fields'] = ResolversParentTypes['audio_measurements_master_files_var_pop_fields']> = {
  chirp_start_freq?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  chirp_stop_freq?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_channels?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sample_freq?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  signal_amplitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  single_chirp_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_chirp_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zero_padding_head_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zero_padding_tail_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Master_Files_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_master_files_var_samp_fields'] = ResolversParentTypes['audio_measurements_master_files_var_samp_fields']> = {
  chirp_start_freq?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  chirp_stop_freq?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_channels?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sample_freq?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  signal_amplitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  single_chirp_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_chirp_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zero_padding_head_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zero_padding_tail_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Master_Files_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_master_files_variance_fields'] = ResolversParentTypes['audio_measurements_master_files_variance_fields']> = {
  chirp_start_freq?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  chirp_stop_freq?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_channels?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sample_freq?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  signal_amplitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  single_chirp_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_chirp_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zero_padding_head_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zero_padding_tail_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_OrientationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_orientations'] = ResolversParentTypes['audio_measurements_orientations']> = {
  configurations?: Resolver<Array<ResolversTypes['audio_measurements_configurations']>, ParentType, ContextType, RequireFields<Audio_Measurements_OrientationsConfigurationsArgs, never>>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Orientations_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_orientations_mutation_response'] = ResolversParentTypes['audio_measurements_orientations_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['audio_measurements_orientations']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Payload_RecordingResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_payload_recording'] = ResolversParentTypes['audio_measurements_payload_recording']> = {
  payload?: Resolver<ResolversTypes['payloads'], ParentType, ContextType>;
  payload_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  recording?: Resolver<ResolversTypes['audio_measurements_recordings'], ParentType, ContextType>;
  recording_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Payload_Recording_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_payload_recording_aggregate'] = ResolversParentTypes['audio_measurements_payload_recording_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['audio_measurements_payload_recording_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['audio_measurements_payload_recording']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Payload_Recording_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_payload_recording_aggregate_fields'] = ResolversParentTypes['audio_measurements_payload_recording_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Audio_Measurements_Payload_Recording_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['audio_measurements_payload_recording_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['audio_measurements_payload_recording_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Payload_Recording_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_payload_recording_max_fields'] = ResolversParentTypes['audio_measurements_payload_recording_max_fields']> = {
  payload_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  recording_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Payload_Recording_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_payload_recording_min_fields'] = ResolversParentTypes['audio_measurements_payload_recording_min_fields']> = {
  payload_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  recording_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Payload_Recording_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_payload_recording_mutation_response'] = ResolversParentTypes['audio_measurements_payload_recording_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['audio_measurements_payload_recording']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_PlacementsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_placements'] = ResolversParentTypes['audio_measurements_placements']> = {
  configurations?: Resolver<Array<ResolversTypes['audio_measurements_configurations']>, ParentType, ContextType, RequireFields<Audio_Measurements_PlacementsConfigurationsArgs, never>>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Placements_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_placements_mutation_response'] = ResolversParentTypes['audio_measurements_placements_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['audio_measurements_placements']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Project_TasksResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_project_tasks'] = ResolversParentTypes['audio_measurements_project_tasks']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  min_per_device?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['projects'], ParentType, ContextType>;
  project_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  task?: Resolver<ResolversTypes['tasks'], ParentType, ContextType>;
  task_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Project_Tasks_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_project_tasks_aggregate'] = ResolversParentTypes['audio_measurements_project_tasks_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['audio_measurements_project_tasks_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['audio_measurements_project_tasks']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Project_Tasks_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_project_tasks_aggregate_fields'] = ResolversParentTypes['audio_measurements_project_tasks_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['audio_measurements_project_tasks_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Audio_Measurements_Project_Tasks_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['audio_measurements_project_tasks_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['audio_measurements_project_tasks_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['audio_measurements_project_tasks_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['audio_measurements_project_tasks_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['audio_measurements_project_tasks_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['audio_measurements_project_tasks_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['audio_measurements_project_tasks_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['audio_measurements_project_tasks_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['audio_measurements_project_tasks_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Project_Tasks_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_project_tasks_avg_fields'] = ResolversParentTypes['audio_measurements_project_tasks_avg_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Project_Tasks_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_project_tasks_max_fields'] = ResolversParentTypes['audio_measurements_project_tasks_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  min_per_device?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Project_Tasks_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_project_tasks_min_fields'] = ResolversParentTypes['audio_measurements_project_tasks_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  min_per_device?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Project_Tasks_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_project_tasks_mutation_response'] = ResolversParentTypes['audio_measurements_project_tasks_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['audio_measurements_project_tasks']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Project_Tasks_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_project_tasks_stddev_fields'] = ResolversParentTypes['audio_measurements_project_tasks_stddev_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Project_Tasks_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_project_tasks_stddev_pop_fields'] = ResolversParentTypes['audio_measurements_project_tasks_stddev_pop_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Project_Tasks_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_project_tasks_stddev_samp_fields'] = ResolversParentTypes['audio_measurements_project_tasks_stddev_samp_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Project_Tasks_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_project_tasks_sum_fields'] = ResolversParentTypes['audio_measurements_project_tasks_sum_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Project_Tasks_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_project_tasks_var_pop_fields'] = ResolversParentTypes['audio_measurements_project_tasks_var_pop_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Project_Tasks_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_project_tasks_var_samp_fields'] = ResolversParentTypes['audio_measurements_project_tasks_var_samp_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Project_Tasks_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_project_tasks_variance_fields'] = ResolversParentTypes['audio_measurements_project_tasks_variance_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_RecordingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_recordings'] = ResolversParentTypes['audio_measurements_recordings']> = {
  calibration_hash_codes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cmd_arguments?: Resolver<ResolversTypes['json'], ParentType, ContextType, RequireFields<Audio_Measurements_RecordingsCmd_ArgumentsArgs, never>>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  device?: Resolver<ResolversTypes['devices'], ParentType, ContextType>;
  device_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  firmware?: Resolver<ResolversTypes['firmware'], ParentType, ContextType>;
  firmware_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  is_calibration_recording?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  master_file?: Resolver<ResolversTypes['audio_measurements_master_files'], ParentType, ContextType>;
  master_file_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  pad_state?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  payload_recordings?: Resolver<Array<ResolversTypes['audio_measurements_payload_recording']>, ParentType, ContextType, RequireFields<Audio_Measurements_RecordingsPayload_RecordingsArgs, never>>;
  payload_recordings_aggregate?: Resolver<ResolversTypes['audio_measurements_payload_recording_aggregate'], ParentType, ContextType, RequireFields<Audio_Measurements_RecordingsPayload_Recordings_AggregateArgs, never>>;
  project?: Resolver<ResolversTypes['projects'], ParentType, ContextType>;
  project_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  recorded_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  spl_offset?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stage?: Resolver<Maybe<ResolversTypes['audio_measurements_stages']>, ParentType, ContextType>;
  stage_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  task?: Resolver<ResolversTypes['tasks'], ParentType, ContextType>;
  task_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['users'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Recordings_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_recordings_aggregate'] = ResolversParentTypes['audio_measurements_recordings_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['audio_measurements_recordings_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['audio_measurements_recordings']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Recordings_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_recordings_aggregate_fields'] = ResolversParentTypes['audio_measurements_recordings_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['audio_measurements_recordings_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Audio_Measurements_Recordings_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['audio_measurements_recordings_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['audio_measurements_recordings_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['audio_measurements_recordings_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['audio_measurements_recordings_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['audio_measurements_recordings_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['audio_measurements_recordings_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['audio_measurements_recordings_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['audio_measurements_recordings_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['audio_measurements_recordings_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Recordings_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_recordings_avg_fields'] = ResolversParentTypes['audio_measurements_recordings_avg_fields']> = {
  spl_offset?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Recordings_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_recordings_max_fields'] = ResolversParentTypes['audio_measurements_recordings_max_fields']> = {
  calibration_hash_codes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  device_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  firmware_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  master_file_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  recorded_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  spl_offset?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stage_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Recordings_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_recordings_min_fields'] = ResolversParentTypes['audio_measurements_recordings_min_fields']> = {
  calibration_hash_codes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  device_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  firmware_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  master_file_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  recorded_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  spl_offset?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stage_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Recordings_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_recordings_mutation_response'] = ResolversParentTypes['audio_measurements_recordings_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['audio_measurements_recordings']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Recordings_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_recordings_stddev_fields'] = ResolversParentTypes['audio_measurements_recordings_stddev_fields']> = {
  spl_offset?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Recordings_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_recordings_stddev_pop_fields'] = ResolversParentTypes['audio_measurements_recordings_stddev_pop_fields']> = {
  spl_offset?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Recordings_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_recordings_stddev_samp_fields'] = ResolversParentTypes['audio_measurements_recordings_stddev_samp_fields']> = {
  spl_offset?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Recordings_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_recordings_sum_fields'] = ResolversParentTypes['audio_measurements_recordings_sum_fields']> = {
  spl_offset?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Recordings_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_recordings_var_pop_fields'] = ResolversParentTypes['audio_measurements_recordings_var_pop_fields']> = {
  spl_offset?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Recordings_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_recordings_var_samp_fields'] = ResolversParentTypes['audio_measurements_recordings_var_samp_fields']> = {
  spl_offset?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Recordings_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_recordings_variance_fields'] = ResolversParentTypes['audio_measurements_recordings_variance_fields']> = {
  spl_offset?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_StagesResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_stages'] = ResolversParentTypes['audio_measurements_stages']> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recordings?: Resolver<Array<ResolversTypes['audio_measurements_recordings']>, ParentType, ContextType, RequireFields<Audio_Measurements_StagesRecordingsArgs, never>>;
  recordings_aggregate?: Resolver<ResolversTypes['audio_measurements_recordings_aggregate'], ParentType, ContextType, RequireFields<Audio_Measurements_StagesRecordings_AggregateArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audio_Measurements_Stages_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['audio_measurements_stages_mutation_response'] = ResolversParentTypes['audio_measurements_stages_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['audio_measurements_stages']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface BigintScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['bigint'], any> {
  name: 'bigint';
}

export type Bringup_Checklist_DeliverableResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_checklist_deliverable'] = ResolversParentTypes['bringup_checklist_deliverable']> = {
  checklist?: Resolver<ResolversTypes['bringup_checklists'], ParentType, ContextType>;
  checklist_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  deliverable?: Resolver<ResolversTypes['deliverables'], ParentType, ContextType>;
  deliverable_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Checklist_Deliverable_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_checklist_deliverable_aggregate'] = ResolversParentTypes['bringup_checklist_deliverable_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['bringup_checklist_deliverable_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['bringup_checklist_deliverable']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Checklist_Deliverable_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_checklist_deliverable_aggregate_fields'] = ResolversParentTypes['bringup_checklist_deliverable_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Bringup_Checklist_Deliverable_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['bringup_checklist_deliverable_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['bringup_checklist_deliverable_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Checklist_Deliverable_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_checklist_deliverable_max_fields'] = ResolversParentTypes['bringup_checklist_deliverable_max_fields']> = {
  checklist_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deliverable_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Checklist_Deliverable_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_checklist_deliverable_min_fields'] = ResolversParentTypes['bringup_checklist_deliverable_min_fields']> = {
  checklist_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deliverable_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Checklist_Deliverable_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_checklist_deliverable_mutation_response'] = ResolversParentTypes['bringup_checklist_deliverable_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['bringup_checklist_deliverable']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_ChecklistsResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_checklists'] = ResolversParentTypes['bringup_checklists']> = {
  checklist_deliverables?: Resolver<Array<ResolversTypes['bringup_checklist_deliverable']>, ParentType, ContextType, RequireFields<Bringup_ChecklistsChecklist_DeliverablesArgs, never>>;
  checklist_deliverables_aggregate?: Resolver<ResolversTypes['bringup_checklist_deliverable_aggregate'], ParentType, ContextType, RequireFields<Bringup_ChecklistsChecklist_Deliverables_AggregateArgs, never>>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['bringup_items']>, ParentType, ContextType, RequireFields<Bringup_ChecklistsItemsArgs, never>>;
  items_aggregate?: Resolver<ResolversTypes['bringup_items_aggregate'], ParentType, ContextType, RequireFields<Bringup_ChecklistsItems_AggregateArgs, never>>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Checklists_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_checklists_aggregate'] = ResolversParentTypes['bringup_checklists_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['bringup_checklists_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['bringup_checklists']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Checklists_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_checklists_aggregate_fields'] = ResolversParentTypes['bringup_checklists_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Bringup_Checklists_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['bringup_checklists_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['bringup_checklists_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Checklists_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_checklists_max_fields'] = ResolversParentTypes['bringup_checklists_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Checklists_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_checklists_min_fields'] = ResolversParentTypes['bringup_checklists_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Checklists_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_checklists_mutation_response'] = ResolversParentTypes['bringup_checklists_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['bringup_checklists']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_EndorsementsResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_endorsements'] = ResolversParentTypes['bringup_endorsements']> = {
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  item?: Resolver<ResolversTypes['bringup_items'], ParentType, ContextType>;
  item_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['projects'], ParentType, ContextType>;
  project_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['users'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Endorsements_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_endorsements_aggregate'] = ResolversParentTypes['bringup_endorsements_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['bringup_endorsements_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['bringup_endorsements']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Endorsements_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_endorsements_aggregate_fields'] = ResolversParentTypes['bringup_endorsements_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Bringup_Endorsements_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['bringup_endorsements_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['bringup_endorsements_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Endorsements_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_endorsements_max_fields'] = ResolversParentTypes['bringup_endorsements_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  item_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Endorsements_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_endorsements_min_fields'] = ResolversParentTypes['bringup_endorsements_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  item_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Endorsements_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_endorsements_mutation_response'] = ResolversParentTypes['bringup_endorsements_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['bringup_endorsements']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_ItemsResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_items'] = ResolversParentTypes['bringup_items']> = {
  checklist?: Resolver<ResolversTypes['bringup_checklists'], ParentType, ContextType>;
  checklist_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  dependent_items?: Resolver<Array<ResolversTypes['bringup_items']>, ParentType, ContextType, RequireFields<Bringup_ItemsDependent_ItemsArgs, never>>;
  dependent_items_aggregate?: Resolver<ResolversTypes['bringup_items_aggregate'], ParentType, ContextType, RequireFields<Bringup_ItemsDependent_Items_AggregateArgs, never>>;
  depends_on?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  depends_on_item?: Resolver<Maybe<ResolversTypes['bringup_items']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endorsements?: Resolver<Array<ResolversTypes['bringup_endorsements']>, ParentType, ContextType, RequireFields<Bringup_ItemsEndorsementsArgs, never>>;
  endorsements_aggregate?: Resolver<ResolversTypes['bringup_endorsements_aggregate'], ParentType, ContextType, RequireFields<Bringup_ItemsEndorsements_AggregateArgs, never>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  milestone?: Resolver<Maybe<ResolversTypes['bringup_milestones']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  objective?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  personnel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  project_item_comments?: Resolver<Array<ResolversTypes['bringup_project_item_comments']>, ParentType, ContextType, RequireFields<Bringup_ItemsProject_Item_CommentsArgs, never>>;
  project_item_comments_aggregate?: Resolver<ResolversTypes['bringup_project_item_comments_aggregate'], ParentType, ContextType, RequireFields<Bringup_ItemsProject_Item_Comments_AggregateArgs, never>>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Items_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_items_aggregate'] = ResolversParentTypes['bringup_items_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['bringup_items_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['bringup_items']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Items_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_items_aggregate_fields'] = ResolversParentTypes['bringup_items_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['bringup_items_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Bringup_Items_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['bringup_items_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['bringup_items_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['bringup_items_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['bringup_items_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['bringup_items_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['bringup_items_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['bringup_items_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['bringup_items_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['bringup_items_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Items_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_items_avg_fields'] = ResolversParentTypes['bringup_items_avg_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Items_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_items_max_fields'] = ResolversParentTypes['bringup_items_max_fields']> = {
  checklist_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  depends_on?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  objective?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  personnel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Items_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_items_min_fields'] = ResolversParentTypes['bringup_items_min_fields']> = {
  checklist_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  depends_on?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  objective?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  personnel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Items_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_items_mutation_response'] = ResolversParentTypes['bringup_items_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['bringup_items']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Items_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_items_stddev_fields'] = ResolversParentTypes['bringup_items_stddev_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Items_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_items_stddev_pop_fields'] = ResolversParentTypes['bringup_items_stddev_pop_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Items_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_items_stddev_samp_fields'] = ResolversParentTypes['bringup_items_stddev_samp_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Items_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_items_sum_fields'] = ResolversParentTypes['bringup_items_sum_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Items_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_items_var_pop_fields'] = ResolversParentTypes['bringup_items_var_pop_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Items_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_items_var_samp_fields'] = ResolversParentTypes['bringup_items_var_samp_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Items_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_items_variance_fields'] = ResolversParentTypes['bringup_items_variance_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_MilestonesResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_milestones'] = ResolversParentTypes['bringup_milestones']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  item?: Resolver<ResolversTypes['bringup_items'], ParentType, ContextType>;
  item_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Milestones_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_milestones_aggregate'] = ResolversParentTypes['bringup_milestones_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['bringup_milestones_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['bringup_milestones']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Milestones_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_milestones_aggregate_fields'] = ResolversParentTypes['bringup_milestones_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Bringup_Milestones_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['bringup_milestones_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['bringup_milestones_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Milestones_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_milestones_max_fields'] = ResolversParentTypes['bringup_milestones_max_fields']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  item_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Milestones_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_milestones_min_fields'] = ResolversParentTypes['bringup_milestones_min_fields']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  item_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Milestones_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_milestones_mutation_response'] = ResolversParentTypes['bringup_milestones_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['bringup_milestones']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Project_Item_CommentsResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_project_item_comments'] = ResolversParentTypes['bringup_project_item_comments']> = {
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  item?: Resolver<ResolversTypes['bringup_items'], ParentType, ContextType>;
  item_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['projects'], ParentType, ContextType>;
  project_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['users'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Project_Item_Comments_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_project_item_comments_aggregate'] = ResolversParentTypes['bringup_project_item_comments_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['bringup_project_item_comments_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['bringup_project_item_comments']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Project_Item_Comments_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_project_item_comments_aggregate_fields'] = ResolversParentTypes['bringup_project_item_comments_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Bringup_Project_Item_Comments_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['bringup_project_item_comments_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['bringup_project_item_comments_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Project_Item_Comments_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_project_item_comments_max_fields'] = ResolversParentTypes['bringup_project_item_comments_max_fields']> = {
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  item_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Project_Item_Comments_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_project_item_comments_min_fields'] = ResolversParentTypes['bringup_project_item_comments_min_fields']> = {
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  item_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bringup_Project_Item_Comments_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['bringup_project_item_comments_mutation_response'] = ResolversParentTypes['bringup_project_item_comments_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['bringup_project_item_comments']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['categories'] = ResolversParentTypes['categories']> = {
  contexts?: Resolver<Array<ResolversTypes['contexts']>, ParentType, ContextType, RequireFields<CategoriesContextsArgs, never>>;
  contexts_aggregate?: Resolver<ResolversTypes['contexts_aggregate'], ParentType, ContextType, RequireFields<CategoriesContexts_AggregateArgs, never>>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['smallint'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Categories_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['categories_aggregate'] = ResolversParentTypes['categories_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['categories_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['categories']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Categories_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['categories_aggregate_fields'] = ResolversParentTypes['categories_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['categories_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Categories_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['categories_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['categories_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['categories_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['categories_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['categories_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['categories_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['categories_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['categories_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['categories_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Categories_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['categories_avg_fields'] = ResolversParentTypes['categories_avg_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Categories_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['categories_max_fields'] = ResolversParentTypes['categories_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Categories_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['categories_min_fields'] = ResolversParentTypes['categories_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Categories_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['categories_mutation_response'] = ResolversParentTypes['categories_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['categories']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Categories_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['categories_stddev_fields'] = ResolversParentTypes['categories_stddev_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Categories_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['categories_stddev_pop_fields'] = ResolversParentTypes['categories_stddev_pop_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Categories_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['categories_stddev_samp_fields'] = ResolversParentTypes['categories_stddev_samp_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Categories_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['categories_sum_fields'] = ResolversParentTypes['categories_sum_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Categories_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['categories_var_pop_fields'] = ResolversParentTypes['categories_var_pop_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Categories_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['categories_var_samp_fields'] = ResolversParentTypes['categories_var_samp_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Categories_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['categories_variance_fields'] = ResolversParentTypes['categories_variance_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Context_Display_FieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['context_display_field'] = ResolversParentTypes['context_display_field']> = {
  context_display?: Resolver<ResolversTypes['context_displays'], ParentType, ContextType>;
  context_display_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  context_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  field?: Resolver<Maybe<ResolversTypes['fields']>, ParentType, ContextType>;
  field_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  position?: Resolver<ResolversTypes['smallint'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Context_Display_Field_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['context_display_field_aggregate'] = ResolversParentTypes['context_display_field_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['context_display_field_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['context_display_field']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Context_Display_Field_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['context_display_field_aggregate_fields'] = ResolversParentTypes['context_display_field_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['context_display_field_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Context_Display_Field_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['context_display_field_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['context_display_field_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['context_display_field_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['context_display_field_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['context_display_field_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['context_display_field_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['context_display_field_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['context_display_field_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['context_display_field_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Context_Display_Field_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['context_display_field_avg_fields'] = ResolversParentTypes['context_display_field_avg_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Context_Display_Field_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['context_display_field_max_fields'] = ResolversParentTypes['context_display_field_max_fields']> = {
  context_display_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  context_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  field_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Context_Display_Field_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['context_display_field_min_fields'] = ResolversParentTypes['context_display_field_min_fields']> = {
  context_display_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  context_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  field_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Context_Display_Field_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['context_display_field_mutation_response'] = ResolversParentTypes['context_display_field_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['context_display_field']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Context_Display_Field_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['context_display_field_stddev_fields'] = ResolversParentTypes['context_display_field_stddev_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Context_Display_Field_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['context_display_field_stddev_pop_fields'] = ResolversParentTypes['context_display_field_stddev_pop_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Context_Display_Field_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['context_display_field_stddev_samp_fields'] = ResolversParentTypes['context_display_field_stddev_samp_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Context_Display_Field_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['context_display_field_sum_fields'] = ResolversParentTypes['context_display_field_sum_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Context_Display_Field_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['context_display_field_var_pop_fields'] = ResolversParentTypes['context_display_field_var_pop_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Context_Display_Field_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['context_display_field_var_samp_fields'] = ResolversParentTypes['context_display_field_var_samp_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Context_Display_Field_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['context_display_field_variance_fields'] = ResolversParentTypes['context_display_field_variance_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Context_DisplaysResolvers<ContextType = any, ParentType extends ResolversParentTypes['context_displays'] = ResolversParentTypes['context_displays']> = {
  context?: Resolver<ResolversTypes['contexts'], ParentType, ContextType>;
  context_display_fields?: Resolver<Array<ResolversTypes['context_display_field']>, ParentType, ContextType, RequireFields<Context_DisplaysContext_Display_FieldsArgs, never>>;
  context_display_fields_aggregate?: Resolver<ResolversTypes['context_display_field_aggregate'], ParentType, ContextType, RequireFields<Context_DisplaysContext_Display_Fields_AggregateArgs, never>>;
  context_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  position?: Resolver<ResolversTypes['smallint'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Context_Displays_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['context_displays_mutation_response'] = ResolversParentTypes['context_displays_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['context_displays']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContextsResolvers<ContextType = any, ParentType extends ResolversParentTypes['contexts'] = ResolversParentTypes['contexts']> = {
  category?: Resolver<ResolversTypes['categories'], ParentType, ContextType>;
  category_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  context_displays?: Resolver<Array<ResolversTypes['context_displays']>, ParentType, ContextType, RequireFields<ContextsContext_DisplaysArgs, never>>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  fields?: Resolver<Array<ResolversTypes['fields']>, ParentType, ContextType, RequireFields<ContextsFieldsArgs, never>>;
  fields_aggregate?: Resolver<ResolversTypes['fields_aggregate'], ParentType, ContextType, RequireFields<ContextsFields_AggregateArgs, never>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Contexts_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['contexts_aggregate'] = ResolversParentTypes['contexts_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['contexts_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['contexts']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Contexts_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['contexts_aggregate_fields'] = ResolversParentTypes['contexts_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Contexts_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['contexts_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['contexts_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Contexts_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['contexts_max_fields'] = ResolversParentTypes['contexts_max_fields']> = {
  category_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Contexts_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['contexts_min_fields'] = ResolversParentTypes['contexts_min_fields']> = {
  category_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Contexts_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['contexts_mutation_response'] = ResolversParentTypes['contexts_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['contexts']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomersResolvers<ContextType = any, ParentType extends ResolversParentTypes['customers'] = ResolversParentTypes['customers']> = {
  codename?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  projects?: Resolver<Array<ResolversTypes['projects']>, ParentType, ContextType, RequireFields<CustomersProjectsArgs, never>>;
  projects_aggregate?: Resolver<ResolversTypes['projects_aggregate'], ParentType, ContextType, RequireFields<CustomersProjects_AggregateArgs, never>>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Customers_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['customers_aggregate'] = ResolversParentTypes['customers_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['customers_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['customers']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Customers_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['customers_aggregate_fields'] = ResolversParentTypes['customers_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Customers_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['customers_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['customers_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Customers_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['customers_max_fields'] = ResolversParentTypes['customers_max_fields']> = {
  codename?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Customers_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['customers_min_fields'] = ResolversParentTypes['customers_min_fields']> = {
  codename?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Customers_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['customers_mutation_response'] = ResolversParentTypes['customers_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['customers']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['date'], any> {
  name: 'date';
}

export type DeliverablesResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliverables'] = ResolversParentTypes['deliverables']> = {
  checklist_deliverables?: Resolver<Array<ResolversTypes['bringup_checklist_deliverable']>, ParentType, ContextType, RequireFields<DeliverablesChecklist_DeliverablesArgs, never>>;
  checklist_deliverables_aggregate?: Resolver<ResolversTypes['bringup_checklist_deliverable_aggregate'], ParentType, ContextType, RequireFields<DeliverablesChecklist_Deliverables_AggregateArgs, never>>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deliverable_templates?: Resolver<Array<ResolversTypes['performance_report_deliverable_template']>, ParentType, ContextType, RequireFields<DeliverablesDeliverable_TemplatesArgs, never>>;
  deliverable_templates_aggregate?: Resolver<ResolversTypes['performance_report_deliverable_template_aggregate'], ParentType, ContextType, RequireFields<DeliverablesDeliverable_Templates_AggregateArgs, never>>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ground_truth_type?: Resolver<Maybe<ResolversTypes['ground_truth_types']>, ParentType, ContextType>;
  ground_truth_type_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  num_states?: Resolver<ResolversTypes['smallint'], ParentType, ContextType>;
  projects?: Resolver<Array<ResolversTypes['projects']>, ParentType, ContextType, RequireFields<DeliverablesProjectsArgs, never>>;
  projects_aggregate?: Resolver<ResolversTypes['projects_aggregate'], ParentType, ContextType, RequireFields<DeliverablesProjects_AggregateArgs, never>>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliverables_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliverables_aggregate'] = ResolversParentTypes['deliverables_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['deliverables_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['deliverables']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliverables_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliverables_aggregate_fields'] = ResolversParentTypes['deliverables_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['deliverables_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Deliverables_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['deliverables_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['deliverables_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['deliverables_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['deliverables_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['deliverables_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['deliverables_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['deliverables_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['deliverables_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['deliverables_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliverables_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliverables_avg_fields'] = ResolversParentTypes['deliverables_avg_fields']> = {
  num_states?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliverables_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliverables_max_fields'] = ResolversParentTypes['deliverables_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ground_truth_type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  num_states?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliverables_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliverables_min_fields'] = ResolversParentTypes['deliverables_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ground_truth_type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  num_states?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliverables_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliverables_mutation_response'] = ResolversParentTypes['deliverables_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['deliverables']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliverables_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliverables_stddev_fields'] = ResolversParentTypes['deliverables_stddev_fields']> = {
  num_states?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliverables_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliverables_stddev_pop_fields'] = ResolversParentTypes['deliverables_stddev_pop_fields']> = {
  num_states?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliverables_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliverables_stddev_samp_fields'] = ResolversParentTypes['deliverables_stddev_samp_fields']> = {
  num_states?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliverables_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliverables_sum_fields'] = ResolversParentTypes['deliverables_sum_fields']> = {
  num_states?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliverables_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliverables_var_pop_fields'] = ResolversParentTypes['deliverables_var_pop_fields']> = {
  num_states?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliverables_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliverables_var_samp_fields'] = ResolversParentTypes['deliverables_var_samp_fields']> = {
  num_states?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliverables_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliverables_variance_fields'] = ResolversParentTypes['deliverables_variance_fields']> = {
  num_states?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_ArchitecturesResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_architectures'] = ResolversParentTypes['deliveries_architectures']> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  packages?: Resolver<Array<ResolversTypes['deliveries_packages']>, ParentType, ContextType, RequireFields<Deliveries_ArchitecturesPackagesArgs, never>>;
  packages_aggregate?: Resolver<ResolversTypes['deliveries_packages_aggregate'], ParentType, ContextType, RequireFields<Deliveries_ArchitecturesPackages_AggregateArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Architectures_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_architectures_mutation_response'] = ResolversParentTypes['deliveries_architectures_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['deliveries_architectures']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Artifact_Microsoft_Whql_SubmissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_artifact_microsoft_whql_submission'] = ResolversParentTypes['deliveries_artifact_microsoft_whql_submission']> = {
  artifact?: Resolver<ResolversTypes['deliveries_artifacts'], ParentType, ContextType>;
  artifact_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  microsoft_whql_submission?: Resolver<ResolversTypes['deliveries_microsoft_whql_submissions'], ParentType, ContextType>;
  microsoft_whql_submission_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Artifact_Microsoft_Whql_Submission_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_artifact_microsoft_whql_submission_aggregate'] = ResolversParentTypes['deliveries_artifact_microsoft_whql_submission_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['deliveries_artifact_microsoft_whql_submission_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['deliveries_artifact_microsoft_whql_submission']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Artifact_Microsoft_Whql_Submission_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_artifact_microsoft_whql_submission_aggregate_fields'] = ResolversParentTypes['deliveries_artifact_microsoft_whql_submission_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Deliveries_Artifact_Microsoft_Whql_Submission_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['deliveries_artifact_microsoft_whql_submission_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['deliveries_artifact_microsoft_whql_submission_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Artifact_Microsoft_Whql_Submission_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_artifact_microsoft_whql_submission_max_fields'] = ResolversParentTypes['deliveries_artifact_microsoft_whql_submission_max_fields']> = {
  artifact_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  microsoft_whql_submission_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Artifact_Microsoft_Whql_Submission_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_artifact_microsoft_whql_submission_min_fields'] = ResolversParentTypes['deliveries_artifact_microsoft_whql_submission_min_fields']> = {
  artifact_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  microsoft_whql_submission_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Artifact_Microsoft_Whql_Submission_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_artifact_microsoft_whql_submission_mutation_response'] = ResolversParentTypes['deliveries_artifact_microsoft_whql_submission_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['deliveries_artifact_microsoft_whql_submission']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Artifact_Operating_SystemResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_artifact_operating_system'] = ResolversParentTypes['deliveries_artifact_operating_system']> = {
  artifact?: Resolver<ResolversTypes['deliveries_artifacts'], ParentType, ContextType>;
  artifact_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  operating_system?: Resolver<ResolversTypes['deliveries_operating_systems'], ParentType, ContextType>;
  os_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Artifact_Operating_System_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_artifact_operating_system_mutation_response'] = ResolversParentTypes['deliveries_artifact_operating_system_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['deliveries_artifact_operating_system']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_ArtifactsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_artifacts'] = ResolversParentTypes['deliveries_artifacts']> = {
  artifact_microsoft_whql_submissions?: Resolver<Array<ResolversTypes['deliveries_artifact_microsoft_whql_submission']>, ParentType, ContextType, RequireFields<Deliveries_ArtifactsArtifact_Microsoft_Whql_SubmissionsArgs, never>>;
  artifact_microsoft_whql_submissions_aggregate?: Resolver<ResolversTypes['deliveries_artifact_microsoft_whql_submission_aggregate'], ParentType, ContextType, RequireFields<Deliveries_ArtifactsArtifact_Microsoft_Whql_Submissions_AggregateArgs, never>>;
  artifact_operating_systems?: Resolver<Array<ResolversTypes['deliveries_artifact_operating_system']>, ParentType, ContextType, RequireFields<Deliveries_ArtifactsArtifact_Operating_SystemsArgs, never>>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  package?: Resolver<ResolversTypes['deliveries_packages'], ParentType, ContextType>;
  package_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  sbom_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  storage_blob_url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Artifacts_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_artifacts_aggregate'] = ResolversParentTypes['deliveries_artifacts_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['deliveries_artifacts_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['deliveries_artifacts']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Artifacts_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_artifacts_aggregate_fields'] = ResolversParentTypes['deliveries_artifacts_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Deliveries_Artifacts_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['deliveries_artifacts_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['deliveries_artifacts_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Artifacts_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_artifacts_max_fields'] = ResolversParentTypes['deliveries_artifacts_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  package_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  sbom_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  storage_blob_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Artifacts_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_artifacts_min_fields'] = ResolversParentTypes['deliveries_artifacts_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  package_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  sbom_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  storage_blob_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Artifacts_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_artifacts_mutation_response'] = ResolversParentTypes['deliveries_artifacts_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['deliveries_artifacts']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Device_FamiliesResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_device_families'] = ResolversParentTypes['deliveries_device_families']> = {
  codename?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  microsoft_hlk_artifacts?: Resolver<Array<ResolversTypes['deliveries_microsoft_hlk_artifacts']>, ParentType, ContextType, RequireFields<Deliveries_Device_FamiliesMicrosoft_Hlk_ArtifactsArgs, never>>;
  microsoft_hlk_artifacts_aggregate?: Resolver<ResolversTypes['deliveries_microsoft_hlk_artifacts_aggregate'], ParentType, ContextType, RequireFields<Deliveries_Device_FamiliesMicrosoft_Hlk_Artifacts_AggregateArgs, never>>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Device_Families_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_device_families_mutation_response'] = ResolversParentTypes['deliveries_device_families_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['deliveries_device_families']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Driver_PackageResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_driver_package'] = ResolversParentTypes['deliveries_driver_package']> = {
  driver?: Resolver<ResolversTypes['deliveries_drivers'], ParentType, ContextType>;
  driver_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  package?: Resolver<ResolversTypes['deliveries_packages'], ParentType, ContextType>;
  package_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Driver_Package_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_driver_package_aggregate'] = ResolversParentTypes['deliveries_driver_package_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['deliveries_driver_package_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['deliveries_driver_package']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Driver_Package_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_driver_package_aggregate_fields'] = ResolversParentTypes['deliveries_driver_package_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Deliveries_Driver_Package_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['deliveries_driver_package_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['deliveries_driver_package_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Driver_Package_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_driver_package_max_fields'] = ResolversParentTypes['deliveries_driver_package_max_fields']> = {
  driver_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  package_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Driver_Package_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_driver_package_min_fields'] = ResolversParentTypes['deliveries_driver_package_min_fields']> = {
  driver_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  package_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Driver_Package_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_driver_package_mutation_response'] = ResolversParentTypes['deliveries_driver_package_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['deliveries_driver_package']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Driver_ProjectResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_driver_project'] = ResolversParentTypes['deliveries_driver_project']> = {
  driver?: Resolver<ResolversTypes['deliveries_drivers'], ParentType, ContextType>;
  driver_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  driver_version_engines?: Resolver<Array<ResolversTypes['deliveries_driver_version_engine']>, ParentType, ContextType, RequireFields<Deliveries_Driver_ProjectDriver_Version_EnginesArgs, never>>;
  driver_version_engines_aggregate?: Resolver<ResolversTypes['deliveries_driver_version_engine_aggregate'], ParentType, ContextType, RequireFields<Deliveries_Driver_ProjectDriver_Version_Engines_AggregateArgs, never>>;
  project?: Resolver<ResolversTypes['projects'], ParentType, ContextType>;
  project_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Driver_Project_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_driver_project_mutation_response'] = ResolversParentTypes['deliveries_driver_project_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['deliveries_driver_project']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Driver_Version_EngineResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_driver_version_engine'] = ResolversParentTypes['deliveries_driver_version_engine']> = {
  driver_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  driver_project?: Resolver<ResolversTypes['deliveries_driver_project'], ParentType, ContextType>;
  driver_version?: Resolver<Maybe<ResolversTypes['deliveries_driver_versions']>, ParentType, ContextType>;
  driver_version_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  engine?: Resolver<ResolversTypes['engines'], ParentType, ContextType>;
  engine_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Driver_Version_Engine_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_driver_version_engine_aggregate'] = ResolversParentTypes['deliveries_driver_version_engine_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['deliveries_driver_version_engine_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['deliveries_driver_version_engine']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Driver_Version_Engine_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_driver_version_engine_aggregate_fields'] = ResolversParentTypes['deliveries_driver_version_engine_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Deliveries_Driver_Version_Engine_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['deliveries_driver_version_engine_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['deliveries_driver_version_engine_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Driver_Version_Engine_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_driver_version_engine_max_fields'] = ResolversParentTypes['deliveries_driver_version_engine_max_fields']> = {
  driver_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  driver_version_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  engine_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Driver_Version_Engine_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_driver_version_engine_min_fields'] = ResolversParentTypes['deliveries_driver_version_engine_min_fields']> = {
  driver_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  driver_version_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  engine_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Driver_Version_Engine_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_driver_version_engine_mutation_response'] = ResolversParentTypes['deliveries_driver_version_engine_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['deliveries_driver_version_engine']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Driver_Version_PackageResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_driver_version_package'] = ResolversParentTypes['deliveries_driver_version_package']> = {
  driver_version?: Resolver<ResolversTypes['deliveries_driver_versions'], ParentType, ContextType>;
  driver_version_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  package?: Resolver<ResolversTypes['deliveries_packages'], ParentType, ContextType>;
  package_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Driver_Version_Package_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_driver_version_package_aggregate'] = ResolversParentTypes['deliveries_driver_version_package_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['deliveries_driver_version_package_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['deliveries_driver_version_package']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Driver_Version_Package_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_driver_version_package_aggregate_fields'] = ResolversParentTypes['deliveries_driver_version_package_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Deliveries_Driver_Version_Package_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['deliveries_driver_version_package_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['deliveries_driver_version_package_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Driver_Version_Package_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_driver_version_package_max_fields'] = ResolversParentTypes['deliveries_driver_version_package_max_fields']> = {
  driver_version_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  package_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Driver_Version_Package_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_driver_version_package_min_fields'] = ResolversParentTypes['deliveries_driver_version_package_min_fields']> = {
  driver_version_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  package_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Driver_Version_Package_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_driver_version_package_mutation_response'] = ResolversParentTypes['deliveries_driver_version_package_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['deliveries_driver_version_package']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Driver_VersionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_driver_versions'] = ResolversParentTypes['deliveries_driver_versions']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  driver?: Resolver<ResolversTypes['deliveries_drivers'], ParentType, ContextType>;
  driver_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  driver_version_engine?: Resolver<Maybe<ResolversTypes['deliveries_driver_version_engine']>, ParentType, ContextType>;
  driver_version_packages?: Resolver<Array<ResolversTypes['deliveries_driver_version_package']>, ParentType, ContextType, RequireFields<Deliveries_Driver_VersionsDriver_Version_PackagesArgs, never>>;
  driver_version_packages_aggregate?: Resolver<ResolversTypes['deliveries_driver_version_package_aggregate'], ParentType, ContextType, RequireFields<Deliveries_Driver_VersionsDriver_Version_Packages_AggregateArgs, never>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sbom_blob_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  storage_blob_url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Driver_Versions_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_driver_versions_aggregate'] = ResolversParentTypes['deliveries_driver_versions_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['deliveries_driver_versions_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['deliveries_driver_versions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Driver_Versions_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_driver_versions_aggregate_fields'] = ResolversParentTypes['deliveries_driver_versions_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Deliveries_Driver_Versions_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['deliveries_driver_versions_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['deliveries_driver_versions_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Driver_Versions_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_driver_versions_max_fields'] = ResolversParentTypes['deliveries_driver_versions_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  driver_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sbom_blob_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  storage_blob_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Driver_Versions_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_driver_versions_min_fields'] = ResolversParentTypes['deliveries_driver_versions_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  driver_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sbom_blob_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  storage_blob_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Driver_Versions_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_driver_versions_mutation_response'] = ResolversParentTypes['deliveries_driver_versions_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['deliveries_driver_versions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_DriversResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_drivers'] = ResolversParentTypes['deliveries_drivers']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  driver_packages?: Resolver<Array<ResolversTypes['deliveries_driver_package']>, ParentType, ContextType, RequireFields<Deliveries_DriversDriver_PackagesArgs, never>>;
  driver_packages_aggregate?: Resolver<ResolversTypes['deliveries_driver_package_aggregate'], ParentType, ContextType, RequireFields<Deliveries_DriversDriver_Packages_AggregateArgs, never>>;
  driver_project?: Resolver<Maybe<ResolversTypes['deliveries_driver_project']>, ParentType, ContextType>;
  driver_versions?: Resolver<Array<ResolversTypes['deliveries_driver_versions']>, ParentType, ContextType, RequireFields<Deliveries_DriversDriver_VersionsArgs, never>>;
  driver_versions_aggregate?: Resolver<ResolversTypes['deliveries_driver_versions_aggregate'], ParentType, ContextType, RequireFields<Deliveries_DriversDriver_Versions_AggregateArgs, never>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Drivers_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_drivers_aggregate'] = ResolversParentTypes['deliveries_drivers_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['deliveries_drivers_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['deliveries_drivers']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Drivers_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_drivers_aggregate_fields'] = ResolversParentTypes['deliveries_drivers_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Deliveries_Drivers_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['deliveries_drivers_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['deliveries_drivers_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Drivers_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_drivers_max_fields'] = ResolversParentTypes['deliveries_drivers_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Drivers_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_drivers_min_fields'] = ResolversParentTypes['deliveries_drivers_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Drivers_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_drivers_mutation_response'] = ResolversParentTypes['deliveries_drivers_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['deliveries_drivers']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Microsoft_Hlk_ArtifactsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_microsoft_hlk_artifacts'] = ResolversParentTypes['deliveries_microsoft_hlk_artifacts']> = {
  base_driver_version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  device_family?: Resolver<ResolversTypes['deliveries_device_families'], ParentType, ContextType>;
  device_family_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  operating_system?: Resolver<ResolversTypes['deliveries_operating_systems'], ParentType, ContextType>;
  os_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  package?: Resolver<ResolversTypes['deliveries_packages'], ParentType, ContextType>;
  package_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  storage_blob_url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Microsoft_Hlk_Artifacts_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_microsoft_hlk_artifacts_aggregate'] = ResolversParentTypes['deliveries_microsoft_hlk_artifacts_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_hlk_artifacts_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['deliveries_microsoft_hlk_artifacts']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Microsoft_Hlk_Artifacts_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_microsoft_hlk_artifacts_aggregate_fields'] = ResolversParentTypes['deliveries_microsoft_hlk_artifacts_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Deliveries_Microsoft_Hlk_Artifacts_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_hlk_artifacts_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_hlk_artifacts_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Microsoft_Hlk_Artifacts_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_microsoft_hlk_artifacts_max_fields'] = ResolversParentTypes['deliveries_microsoft_hlk_artifacts_max_fields']> = {
  base_driver_version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  device_family_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  os_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  package_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  storage_blob_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Microsoft_Hlk_Artifacts_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_microsoft_hlk_artifacts_min_fields'] = ResolversParentTypes['deliveries_microsoft_hlk_artifacts_min_fields']> = {
  base_driver_version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  device_family_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  os_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  package_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  storage_blob_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Microsoft_Hlk_Artifacts_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_microsoft_hlk_artifacts_mutation_response'] = ResolversParentTypes['deliveries_microsoft_hlk_artifacts_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['deliveries_microsoft_hlk_artifacts']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Microsoft_Shipping_Label_ReviewsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_microsoft_shipping_label_reviews'] = ResolversParentTypes['deliveries_microsoft_shipping_label_reviews']> = {
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  microsoft_shipping_label?: Resolver<ResolversTypes['deliveries_microsoft_shipping_labels'], ParentType, ContextType>;
  microsoft_shipping_label_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  reviewed_by?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['users'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Microsoft_Shipping_Label_Reviews_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_microsoft_shipping_label_reviews_mutation_response'] = ResolversParentTypes['deliveries_microsoft_shipping_label_reviews_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['deliveries_microsoft_shipping_label_reviews']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Microsoft_Shipping_LabelsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_microsoft_shipping_labels'] = ResolversParentTypes['deliveries_microsoft_shipping_labels']> = {
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  microsoft_partner_center_shipping_label_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  microsoft_shipping_label_review?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_shipping_label_reviews']>, ParentType, ContextType>;
  microsoft_whql_submission?: Resolver<ResolversTypes['deliveries_microsoft_whql_submissions'], ParentType, ContextType>;
  microsoft_whql_submission_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shipping_label_infos?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType, RequireFields<Deliveries_Microsoft_Shipping_LabelsShipping_Label_InfosArgs, never>>;
  shipping_label_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shipping_label_status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  target?: Resolver<ResolversTypes['deliveries_targets'], ParentType, ContextType>;
  target_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['users'], ParentType, ContextType>;
  user_update?: Resolver<Maybe<ResolversTypes['users']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Microsoft_Shipping_Labels_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_microsoft_shipping_labels_aggregate'] = ResolversParentTypes['deliveries_microsoft_shipping_labels_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_shipping_labels_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['deliveries_microsoft_shipping_labels']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Microsoft_Shipping_Labels_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_microsoft_shipping_labels_aggregate_fields'] = ResolversParentTypes['deliveries_microsoft_shipping_labels_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Deliveries_Microsoft_Shipping_Labels_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_shipping_labels_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_shipping_labels_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Microsoft_Shipping_Labels_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_microsoft_shipping_labels_max_fields'] = ResolversParentTypes['deliveries_microsoft_shipping_labels_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  microsoft_partner_center_shipping_label_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  microsoft_whql_submission_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  shipping_label_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shipping_label_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  target_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Microsoft_Shipping_Labels_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_microsoft_shipping_labels_min_fields'] = ResolversParentTypes['deliveries_microsoft_shipping_labels_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  microsoft_partner_center_shipping_label_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  microsoft_whql_submission_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  shipping_label_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shipping_label_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  target_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Microsoft_Shipping_Labels_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_microsoft_shipping_labels_mutation_response'] = ResolversParentTypes['deliveries_microsoft_shipping_labels_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['deliveries_microsoft_shipping_labels']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Microsoft_Whql_SubmissionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_microsoft_whql_submissions'] = ResolversParentTypes['deliveries_microsoft_whql_submissions']> = {
  artifact_microsoft_whql_submissions?: Resolver<Array<ResolversTypes['deliveries_artifact_microsoft_whql_submission']>, ParentType, ContextType, RequireFields<Deliveries_Microsoft_Whql_SubmissionsArtifact_Microsoft_Whql_SubmissionsArgs, never>>;
  artifact_microsoft_whql_submissions_aggregate?: Resolver<ResolversTypes['deliveries_artifact_microsoft_whql_submission_aggregate'], ParentType, ContextType, RequireFields<Deliveries_Microsoft_Whql_SubmissionsArtifact_Microsoft_Whql_Submissions_AggregateArgs, never>>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  microsoft_partner_center_product_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  microsoft_partner_center_product_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  microsoft_partner_center_submission_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  microsoft_partner_center_submission_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  microsoft_partner_center_submission_type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  microsoft_shipping_labels?: Resolver<Array<ResolversTypes['deliveries_microsoft_shipping_labels']>, ParentType, ContextType, RequireFields<Deliveries_Microsoft_Whql_SubmissionsMicrosoft_Shipping_LabelsArgs, never>>;
  microsoft_shipping_labels_aggregate?: Resolver<ResolversTypes['deliveries_microsoft_shipping_labels_aggregate'], ParentType, ContextType, RequireFields<Deliveries_Microsoft_Whql_SubmissionsMicrosoft_Shipping_Labels_AggregateArgs, never>>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Microsoft_Whql_Submissions_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_microsoft_whql_submissions_aggregate'] = ResolversParentTypes['deliveries_microsoft_whql_submissions_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_whql_submissions_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['deliveries_microsoft_whql_submissions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Microsoft_Whql_Submissions_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_microsoft_whql_submissions_aggregate_fields'] = ResolversParentTypes['deliveries_microsoft_whql_submissions_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Deliveries_Microsoft_Whql_Submissions_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_whql_submissions_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_whql_submissions_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Microsoft_Whql_Submissions_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_microsoft_whql_submissions_max_fields'] = ResolversParentTypes['deliveries_microsoft_whql_submissions_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  microsoft_partner_center_product_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  microsoft_partner_center_product_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  microsoft_partner_center_submission_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  microsoft_partner_center_submission_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  microsoft_partner_center_submission_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Microsoft_Whql_Submissions_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_microsoft_whql_submissions_min_fields'] = ResolversParentTypes['deliveries_microsoft_whql_submissions_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  microsoft_partner_center_product_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  microsoft_partner_center_product_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  microsoft_partner_center_submission_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  microsoft_partner_center_submission_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  microsoft_partner_center_submission_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Microsoft_Whql_Submissions_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_microsoft_whql_submissions_mutation_response'] = ResolversParentTypes['deliveries_microsoft_whql_submissions_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['deliveries_microsoft_whql_submissions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Operating_SystemsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_operating_systems'] = ResolversParentTypes['deliveries_operating_systems']> = {
  artifact_operating_systems?: Resolver<Array<ResolversTypes['deliveries_artifact_operating_system']>, ParentType, ContextType, RequireFields<Deliveries_Operating_SystemsArtifact_Operating_SystemsArgs, never>>;
  codename?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fullname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  microsoft_hlk_artifacts?: Resolver<Array<ResolversTypes['deliveries_microsoft_hlk_artifacts']>, ParentType, ContextType, RequireFields<Deliveries_Operating_SystemsMicrosoft_Hlk_ArtifactsArgs, never>>;
  microsoft_hlk_artifacts_aggregate?: Resolver<ResolversTypes['deliveries_microsoft_hlk_artifacts_aggregate'], ParentType, ContextType, RequireFields<Deliveries_Operating_SystemsMicrosoft_Hlk_Artifacts_AggregateArgs, never>>;
  package_operating_systems?: Resolver<Array<ResolversTypes['deliveries_package_operating_systems']>, ParentType, ContextType, RequireFields<Deliveries_Operating_SystemsPackage_Operating_SystemsArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Operating_Systems_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_operating_systems_mutation_response'] = ResolversParentTypes['deliveries_operating_systems_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['deliveries_operating_systems']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Package_Operating_SystemsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_package_operating_systems'] = ResolversParentTypes['deliveries_package_operating_systems']> = {
  is_tested?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  operating_system?: Resolver<ResolversTypes['deliveries_operating_systems'], ParentType, ContextType>;
  os_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  package?: Resolver<ResolversTypes['deliveries_packages'], ParentType, ContextType>;
  package_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Package_Operating_Systems_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_package_operating_systems_mutation_response'] = ResolversParentTypes['deliveries_package_operating_systems_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['deliveries_package_operating_systems']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Package_TargetResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_package_target'] = ResolversParentTypes['deliveries_package_target']> = {
  package?: Resolver<ResolversTypes['deliveries_packages'], ParentType, ContextType>;
  package_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  target?: Resolver<ResolversTypes['deliveries_targets'], ParentType, ContextType>;
  target_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Package_Target_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_package_target_aggregate'] = ResolversParentTypes['deliveries_package_target_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['deliveries_package_target_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['deliveries_package_target']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Package_Target_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_package_target_aggregate_fields'] = ResolversParentTypes['deliveries_package_target_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Deliveries_Package_Target_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['deliveries_package_target_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['deliveries_package_target_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Package_Target_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_package_target_max_fields'] = ResolversParentTypes['deliveries_package_target_max_fields']> = {
  package_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  target_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Package_Target_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_package_target_min_fields'] = ResolversParentTypes['deliveries_package_target_min_fields']> = {
  package_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  target_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Package_Target_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_package_target_mutation_response'] = ResolversParentTypes['deliveries_package_target_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['deliveries_package_target']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_PackagesResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_packages'] = ResolversParentTypes['deliveries_packages']> = {
  architecture?: Resolver<Maybe<ResolversTypes['deliveries_architectures']>, ParentType, ContextType>;
  architecture_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  artifacts?: Resolver<Array<ResolversTypes['deliveries_artifacts']>, ParentType, ContextType, RequireFields<Deliveries_PackagesArtifactsArgs, never>>;
  artifacts_aggregate?: Resolver<ResolversTypes['deliveries_artifacts_aggregate'], ParentType, ContextType, RequireFields<Deliveries_PackagesArtifacts_AggregateArgs, never>>;
  bundle_struct_version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['customers'], ParentType, ContextType>;
  customer_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  driver_packages?: Resolver<Array<ResolversTypes['deliveries_driver_package']>, ParentType, ContextType, RequireFields<Deliveries_PackagesDriver_PackagesArgs, never>>;
  driver_packages_aggregate?: Resolver<ResolversTypes['deliveries_driver_package_aggregate'], ParentType, ContextType, RequireFields<Deliveries_PackagesDriver_Packages_AggregateArgs, never>>;
  driver_version_packages?: Resolver<Array<ResolversTypes['deliveries_driver_version_package']>, ParentType, ContextType, RequireFields<Deliveries_PackagesDriver_Version_PackagesArgs, never>>;
  driver_version_packages_aggregate?: Resolver<ResolversTypes['deliveries_driver_version_package_aggregate'], ParentType, ContextType, RequireFields<Deliveries_PackagesDriver_Version_Packages_AggregateArgs, never>>;
  external_package_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  major_version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  microsoft_hlk_artifacts?: Resolver<Array<ResolversTypes['deliveries_microsoft_hlk_artifacts']>, ParentType, ContextType, RequireFields<Deliveries_PackagesMicrosoft_Hlk_ArtifactsArgs, never>>;
  microsoft_hlk_artifacts_aggregate?: Resolver<ResolversTypes['deliveries_microsoft_hlk_artifacts_aggregate'], ParentType, ContextType, RequireFields<Deliveries_PackagesMicrosoft_Hlk_Artifacts_AggregateArgs, never>>;
  minor_version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  package_operating_systems?: Resolver<Array<ResolversTypes['deliveries_package_operating_systems']>, ParentType, ContextType, RequireFields<Deliveries_PackagesPackage_Operating_SystemsArgs, never>>;
  package_targets?: Resolver<Array<ResolversTypes['deliveries_package_target']>, ParentType, ContextType, RequireFields<Deliveries_PackagesPackage_TargetsArgs, never>>;
  package_targets_aggregate?: Resolver<ResolversTypes['deliveries_package_target_aggregate'], ParentType, ContextType, RequireFields<Deliveries_PackagesPackage_Targets_AggregateArgs, never>>;
  platform?: Resolver<Maybe<ResolversTypes['deliveries_platforms']>, ParentType, ContextType>;
  platform_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  released_packages?: Resolver<Array<ResolversTypes['deliveries_packages']>, ParentType, ContextType, RequireFields<Deliveries_PackagesReleased_PackagesArgs, never>>;
  released_packages_aggregate?: Resolver<ResolversTypes['deliveries_packages_aggregate'], ParentType, ContextType, RequireFields<Deliveries_PackagesReleased_Packages_AggregateArgs, never>>;
  sbom_blob_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sharepoint_release_notes_path?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sharepoint_upload_path?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slack_channel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  spec_package?: Resolver<Maybe<ResolversTypes['deliveries_packages']>, ParentType, ContextType>;
  spec_package_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Packages_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_packages_aggregate'] = ResolversParentTypes['deliveries_packages_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['deliveries_packages_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['deliveries_packages']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Packages_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_packages_aggregate_fields'] = ResolversParentTypes['deliveries_packages_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['deliveries_packages_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Deliveries_Packages_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['deliveries_packages_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['deliveries_packages_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['deliveries_packages_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['deliveries_packages_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['deliveries_packages_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['deliveries_packages_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['deliveries_packages_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['deliveries_packages_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['deliveries_packages_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Packages_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_packages_avg_fields'] = ResolversParentTypes['deliveries_packages_avg_fields']> = {
  bundle_struct_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  major_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minor_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Packages_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_packages_max_fields'] = ResolversParentTypes['deliveries_packages_max_fields']> = {
  architecture_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  bundle_struct_version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  customer_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  external_package_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  major_version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minor_version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  platform_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  sbom_blob_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sharepoint_release_notes_path?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sharepoint_upload_path?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slack_channel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  spec_package_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Packages_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_packages_min_fields'] = ResolversParentTypes['deliveries_packages_min_fields']> = {
  architecture_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  bundle_struct_version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  customer_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  external_package_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  major_version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minor_version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  platform_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  sbom_blob_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sharepoint_release_notes_path?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sharepoint_upload_path?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slack_channel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  spec_package_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Packages_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_packages_mutation_response'] = ResolversParentTypes['deliveries_packages_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['deliveries_packages']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Packages_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_packages_stddev_fields'] = ResolversParentTypes['deliveries_packages_stddev_fields']> = {
  bundle_struct_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  major_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minor_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Packages_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_packages_stddev_pop_fields'] = ResolversParentTypes['deliveries_packages_stddev_pop_fields']> = {
  bundle_struct_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  major_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minor_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Packages_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_packages_stddev_samp_fields'] = ResolversParentTypes['deliveries_packages_stddev_samp_fields']> = {
  bundle_struct_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  major_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minor_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Packages_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_packages_sum_fields'] = ResolversParentTypes['deliveries_packages_sum_fields']> = {
  bundle_struct_version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  major_version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minor_version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Packages_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_packages_var_pop_fields'] = ResolversParentTypes['deliveries_packages_var_pop_fields']> = {
  bundle_struct_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  major_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minor_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Packages_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_packages_var_samp_fields'] = ResolversParentTypes['deliveries_packages_var_samp_fields']> = {
  bundle_struct_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  major_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minor_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Packages_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_packages_variance_fields'] = ResolversParentTypes['deliveries_packages_variance_fields']> = {
  bundle_struct_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  major_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minor_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_PartnersResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_partners'] = ResolversParentTypes['deliveries_partners']> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  target_partners?: Resolver<Array<ResolversTypes['deliveries_target_partner']>, ParentType, ContextType, RequireFields<Deliveries_PartnersTarget_PartnersArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Partners_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_partners_mutation_response'] = ResolversParentTypes['deliveries_partners_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['deliveries_partners']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_PlatformsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_platforms'] = ResolversParentTypes['deliveries_platforms']> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  packages?: Resolver<Array<ResolversTypes['deliveries_packages']>, ParentType, ContextType, RequireFields<Deliveries_PlatformsPackagesArgs, never>>;
  packages_aggregate?: Resolver<ResolversTypes['deliveries_packages_aggregate'], ParentType, ContextType, RequireFields<Deliveries_PlatformsPackages_AggregateArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Platforms_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_platforms_mutation_response'] = ResolversParentTypes['deliveries_platforms_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['deliveries_platforms']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Project_ConfigsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_project_configs'] = ResolversParentTypes['deliveries_project_configs']> = {
  chid_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  config_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  config_id_type?: Resolver<ResolversTypes['deliveries_config_id_types_enum'], ParentType, ContextType>;
  controller_updates?: Resolver<Maybe<ResolversTypes['_text']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['projects'], ParentType, ContextType>;
  project_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Project_Configs_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_project_configs_aggregate'] = ResolversParentTypes['deliveries_project_configs_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['deliveries_project_configs_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['deliveries_project_configs']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Project_Configs_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_project_configs_aggregate_fields'] = ResolversParentTypes['deliveries_project_configs_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['deliveries_project_configs_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Deliveries_Project_Configs_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['deliveries_project_configs_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['deliveries_project_configs_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['deliveries_project_configs_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['deliveries_project_configs_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['deliveries_project_configs_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['deliveries_project_configs_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['deliveries_project_configs_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['deliveries_project_configs_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['deliveries_project_configs_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Project_Configs_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_project_configs_avg_fields'] = ResolversParentTypes['deliveries_project_configs_avg_fields']> = {
  chid_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Project_Configs_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_project_configs_max_fields'] = ResolversParentTypes['deliveries_project_configs_max_fields']> = {
  chid_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  config_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Project_Configs_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_project_configs_min_fields'] = ResolversParentTypes['deliveries_project_configs_min_fields']> = {
  chid_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  config_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Project_Configs_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_project_configs_mutation_response'] = ResolversParentTypes['deliveries_project_configs_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['deliveries_project_configs']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Project_Configs_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_project_configs_stddev_fields'] = ResolversParentTypes['deliveries_project_configs_stddev_fields']> = {
  chid_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Project_Configs_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_project_configs_stddev_pop_fields'] = ResolversParentTypes['deliveries_project_configs_stddev_pop_fields']> = {
  chid_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Project_Configs_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_project_configs_stddev_samp_fields'] = ResolversParentTypes['deliveries_project_configs_stddev_samp_fields']> = {
  chid_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Project_Configs_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_project_configs_sum_fields'] = ResolversParentTypes['deliveries_project_configs_sum_fields']> = {
  chid_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Project_Configs_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_project_configs_var_pop_fields'] = ResolversParentTypes['deliveries_project_configs_var_pop_fields']> = {
  chid_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Project_Configs_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_project_configs_var_samp_fields'] = ResolversParentTypes['deliveries_project_configs_var_samp_fields']> = {
  chid_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Project_Configs_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_project_configs_variance_fields'] = ResolversParentTypes['deliveries_project_configs_variance_fields']> = {
  chid_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Target_PartnerResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_target_partner'] = ResolversParentTypes['deliveries_target_partner']> = {
  partner?: Resolver<ResolversTypes['deliveries_partners'], ParentType, ContextType>;
  partner_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  target?: Resolver<ResolversTypes['deliveries_targets'], ParentType, ContextType>;
  target_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Target_Partner_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_target_partner_mutation_response'] = ResolversParentTypes['deliveries_target_partner_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['deliveries_target_partner']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_TargetsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_targets'] = ResolversParentTypes['deliveries_targets']> = {
  chids?: Resolver<ResolversTypes['_text'], ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['customers'], ParentType, ContextType>;
  customer_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  hardware_ids?: Resolver<Maybe<ResolversTypes['_text']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  microsoft_shipping_labels?: Resolver<Array<ResolversTypes['deliveries_microsoft_shipping_labels']>, ParentType, ContextType, RequireFields<Deliveries_TargetsMicrosoft_Shipping_LabelsArgs, never>>;
  microsoft_shipping_labels_aggregate?: Resolver<ResolversTypes['deliveries_microsoft_shipping_labels_aggregate'], ParentType, ContextType, RequireFields<Deliveries_TargetsMicrosoft_Shipping_Labels_AggregateArgs, never>>;
  model?: Resolver<ResolversTypes['models'], ParentType, ContextType>;
  model_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  package_targets?: Resolver<Array<ResolversTypes['deliveries_package_target']>, ParentType, ContextType, RequireFields<Deliveries_TargetsPackage_TargetsArgs, never>>;
  package_targets_aggregate?: Resolver<ResolversTypes['deliveries_package_target_aggregate'], ParentType, ContextType, RequireFields<Deliveries_TargetsPackage_Targets_AggregateArgs, never>>;
  target_partners?: Resolver<Array<ResolversTypes['deliveries_target_partner']>, ParentType, ContextType, RequireFields<Deliveries_TargetsTarget_PartnersArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Targets_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_targets_aggregate'] = ResolversParentTypes['deliveries_targets_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['deliveries_targets_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['deliveries_targets']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Targets_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_targets_aggregate_fields'] = ResolversParentTypes['deliveries_targets_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Deliveries_Targets_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['deliveries_targets_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['deliveries_targets_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Targets_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_targets_max_fields'] = ResolversParentTypes['deliveries_targets_max_fields']> = {
  customer_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  model_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Targets_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_targets_min_fields'] = ResolversParentTypes['deliveries_targets_min_fields']> = {
  customer_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  model_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Deliveries_Targets_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['deliveries_targets_mutation_response'] = ResolversParentTypes['deliveries_targets_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['deliveries_targets']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Device_FirmwareResolvers<ContextType = any, ParentType extends ResolversParentTypes['device_firmware'] = ResolversParentTypes['device_firmware']> = {
  device?: Resolver<ResolversTypes['devices'], ParentType, ContextType>;
  device_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  firmware?: Resolver<ResolversTypes['firmware'], ParentType, ContextType>;
  firmware_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  recordings?: Resolver<Array<ResolversTypes['recordings']>, ParentType, ContextType, RequireFields<Device_FirmwareRecordingsArgs, never>>;
  recordings_aggregate?: Resolver<ResolversTypes['recordings_aggregate'], ParentType, ContextType, RequireFields<Device_FirmwareRecordings_AggregateArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Device_Firmware_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['device_firmware_mutation_response'] = ResolversParentTypes['device_firmware_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['device_firmware']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Device_ProjectResolvers<ContextType = any, ParentType extends ResolversParentTypes['device_project'] = ResolversParentTypes['device_project']> = {
  device?: Resolver<ResolversTypes['devices'], ParentType, ContextType>;
  device_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['projects'], ParentType, ContextType>;
  project_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Device_Project_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['device_project_aggregate'] = ResolversParentTypes['device_project_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['device_project_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['device_project']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Device_Project_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['device_project_aggregate_fields'] = ResolversParentTypes['device_project_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Device_Project_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['device_project_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['device_project_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Device_Project_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['device_project_max_fields'] = ResolversParentTypes['device_project_max_fields']> = {
  device_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Device_Project_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['device_project_min_fields'] = ResolversParentTypes['device_project_min_fields']> = {
  device_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Device_Project_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['device_project_mutation_response'] = ResolversParentTypes['device_project_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['device_project']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DevicesResolvers<ContextType = any, ParentType extends ResolversParentTypes['devices'] = ResolversParentTypes['devices']> = {
  configuration?: Resolver<Maybe<ResolversTypes['audio_measurements_configurations']>, ParentType, ContextType>;
  configuration_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  device_firmwares?: Resolver<Array<ResolversTypes['device_firmware']>, ParentType, ContextType, RequireFields<DevicesDevice_FirmwaresArgs, never>>;
  device_projects?: Resolver<Array<ResolversTypes['device_project']>, ParentType, ContextType, RequireFields<DevicesDevice_ProjectsArgs, never>>;
  device_projects_aggregate?: Resolver<ResolversTypes['device_project_aggregate'], ParentType, ContextType, RequireFields<DevicesDevice_Projects_AggregateArgs, never>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  model?: Resolver<Maybe<ResolversTypes['models']>, ParentType, ContextType>;
  model_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  project_dataset?: Resolver<Array<ResolversTypes['project_dataset']>, ParentType, ContextType, RequireFields<DevicesProject_DatasetArgs, never>>;
  project_dataset_aggregate?: Resolver<ResolversTypes['project_dataset_aggregate'], ParentType, ContextType, RequireFields<DevicesProject_Dataset_AggregateArgs, never>>;
  recordings?: Resolver<Array<ResolversTypes['recordings']>, ParentType, ContextType, RequireFields<DevicesRecordingsArgs, never>>;
  recordings_aggregate?: Resolver<ResolversTypes['recordings_aggregate'], ParentType, ContextType, RequireFields<DevicesRecordings_AggregateArgs, never>>;
  serialno?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sku?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Devices_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['devices_aggregate'] = ResolversParentTypes['devices_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['devices_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['devices']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Devices_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['devices_aggregate_fields'] = ResolversParentTypes['devices_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Devices_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['devices_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['devices_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Devices_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['devices_max_fields'] = ResolversParentTypes['devices_max_fields']> = {
  configuration_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  model_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  serialno?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sku?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Devices_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['devices_min_fields'] = ResolversParentTypes['devices_min_fields']> = {
  configuration_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  model_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  serialno?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sku?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Devices_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['devices_mutation_response'] = ResolversParentTypes['devices_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['devices']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Dynamic_ScalingResolvers<ContextType = any, ParentType extends ResolversParentTypes['dynamic_scaling'] = ResolversParentTypes['dynamic_scaling']> = {
  benchmark_scaling?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  max_value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  min_value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  projects?: Resolver<Array<ResolversTypes['projects']>, ParentType, ContextType, RequireFields<Dynamic_ScalingProjectsArgs, never>>;
  projects_aggregate?: Resolver<ResolversTypes['projects_aggregate'], ParentType, ContextType, RequireFields<Dynamic_ScalingProjects_AggregateArgs, never>>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Dynamic_Scaling_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['dynamic_scaling_mutation_response'] = ResolversParentTypes['dynamic_scaling_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['dynamic_scaling']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Engine_OpmodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['engine_opmode'] = ResolversParentTypes['engine_opmode']> = {
  engine?: Resolver<ResolversTypes['engines'], ParentType, ContextType>;
  engine_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  engine_opmode_recordings?: Resolver<Array<ResolversTypes['engine_opmode_recording']>, ParentType, ContextType, RequireFields<Engine_OpmodeEngine_Opmode_RecordingsArgs, never>>;
  engine_opmode_recordings_aggregate?: Resolver<ResolversTypes['engine_opmode_recording_aggregate'], ParentType, ContextType, RequireFields<Engine_OpmodeEngine_Opmode_Recordings_AggregateArgs, never>>;
  opmode?: Resolver<ResolversTypes['opmodes'], ParentType, ContextType>;
  opmode_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Engine_Opmode_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['engine_opmode_aggregate'] = ResolversParentTypes['engine_opmode_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['engine_opmode_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['engine_opmode']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Engine_Opmode_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['engine_opmode_aggregate_fields'] = ResolversParentTypes['engine_opmode_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Engine_Opmode_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['engine_opmode_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['engine_opmode_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Engine_Opmode_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['engine_opmode_max_fields'] = ResolversParentTypes['engine_opmode_max_fields']> = {
  engine_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  opmode_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Engine_Opmode_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['engine_opmode_min_fields'] = ResolversParentTypes['engine_opmode_min_fields']> = {
  engine_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  opmode_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Engine_Opmode_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['engine_opmode_mutation_response'] = ResolversParentTypes['engine_opmode_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['engine_opmode']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Engine_Opmode_RecordingResolvers<ContextType = any, ParentType extends ResolversParentTypes['engine_opmode_recording'] = ResolversParentTypes['engine_opmode_recording']> = {
  engine?: Resolver<ResolversTypes['engines'], ParentType, ContextType>;
  engine_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  engine_opmode?: Resolver<Maybe<ResolversTypes['engine_opmode']>, ParentType, ContextType>;
  opmode?: Resolver<ResolversTypes['opmodes'], ParentType, ContextType>;
  opmode_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  recording?: Resolver<ResolversTypes['recordings'], ParentType, ContextType>;
  recording_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Engine_Opmode_Recording_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['engine_opmode_recording_aggregate'] = ResolversParentTypes['engine_opmode_recording_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['engine_opmode_recording_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['engine_opmode_recording']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Engine_Opmode_Recording_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['engine_opmode_recording_aggregate_fields'] = ResolversParentTypes['engine_opmode_recording_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Engine_Opmode_Recording_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['engine_opmode_recording_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['engine_opmode_recording_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Engine_Opmode_Recording_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['engine_opmode_recording_max_fields'] = ResolversParentTypes['engine_opmode_recording_max_fields']> = {
  engine_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  opmode_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  recording_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Engine_Opmode_Recording_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['engine_opmode_recording_min_fields'] = ResolversParentTypes['engine_opmode_recording_min_fields']> = {
  engine_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  opmode_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  recording_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Engine_Opmode_Recording_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['engine_opmode_recording_mutation_response'] = ResolversParentTypes['engine_opmode_recording_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['engine_opmode_recording']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Engine_TypesResolvers<ContextType = any, ParentType extends ResolversParentTypes['engine_types'] = ResolversParentTypes['engine_types']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Engine_Types_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['engine_types_aggregate'] = ResolversParentTypes['engine_types_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['engine_types_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['engine_types']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Engine_Types_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['engine_types_aggregate_fields'] = ResolversParentTypes['engine_types_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Engine_Types_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['engine_types_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['engine_types_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Engine_Types_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['engine_types_max_fields'] = ResolversParentTypes['engine_types_max_fields']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Engine_Types_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['engine_types_min_fields'] = ResolversParentTypes['engine_types_min_fields']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Engine_Types_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['engine_types_mutation_response'] = ResolversParentTypes['engine_types_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['engine_types']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EnginesResolvers<ContextType = any, ParentType extends ResolversParentTypes['engines'] = ResolversParentTypes['engines']> = {
  azure_build_ref?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  azure_release_ref?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  branch?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  build_definition_id_azure?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  build_number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  build_ref?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  build_version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  elliptic_engine_commit_hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  engine_opmode_recordings?: Resolver<Array<ResolversTypes['engine_opmode_recording']>, ParentType, ContextType, RequireFields<EnginesEngine_Opmode_RecordingsArgs, never>>;
  engine_opmode_recordings_aggregate?: Resolver<ResolversTypes['engine_opmode_recording_aggregate'], ParentType, ContextType, RequireFields<EnginesEngine_Opmode_Recordings_AggregateArgs, never>>;
  engine_opmodes?: Resolver<Array<ResolversTypes['engine_opmode']>, ParentType, ContextType, RequireFields<EnginesEngine_OpmodesArgs, never>>;
  engine_opmodes_aggregate?: Resolver<ResolversTypes['engine_opmode_aggregate'], ParentType, ContextType, RequireFields<EnginesEngine_Opmodes_AggregateArgs, never>>;
  engine_type?: Resolver<Maybe<ResolversTypes['engine_types']>, ParentType, ContextType>;
  engine_type_id?: Resolver<Maybe<ResolversTypes['engine_types_enum']>, ParentType, ContextType>;
  graph_version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  nickname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  observances?: Resolver<Array<ResolversTypes['integration_test_observances']>, ParentType, ContextType, RequireFields<EnginesObservancesArgs, never>>;
  observances_aggregate?: Resolver<ResolversTypes['integration_test_observances_aggregate'], ParentType, ContextType, RequireFields<EnginesObservances_AggregateArgs, never>>;
  path?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  predictions?: Resolver<Array<ResolversTypes['recording_data_predictions']>, ParentType, ContextType, RequireFields<EnginesPredictionsArgs, never>>;
  predictions_aggregate?: Resolver<ResolversTypes['recording_data_predictions_aggregate'], ParentType, ContextType, RequireFields<EnginesPredictions_AggregateArgs, never>>;
  ratio_metric_contributions?: Resolver<Array<ResolversTypes['performance_ratio_metric_contributions']>, ParentType, ContextType, RequireFields<EnginesRatio_Metric_ContributionsArgs, never>>;
  ratio_metric_contributions_aggregate?: Resolver<ResolversTypes['performance_ratio_metric_contributions_aggregate'], ParentType, ContextType, RequireFields<EnginesRatio_Metric_Contributions_AggregateArgs, never>>;
  release_definition_id_azure?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  release_number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  scores?: Resolver<Array<ResolversTypes['simulations_scores']>, ParentType, ContextType, RequireFields<EnginesScoresArgs, never>>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Engines_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['engines_aggregate'] = ResolversParentTypes['engines_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['engines_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['engines']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Engines_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['engines_aggregate_fields'] = ResolversParentTypes['engines_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['engines_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Engines_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['engines_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['engines_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['engines_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['engines_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['engines_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['engines_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['engines_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['engines_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['engines_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Engines_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['engines_avg_fields'] = ResolversParentTypes['engines_avg_fields']> = {
  azure_build_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  azure_release_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  build_definition_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  graph_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  release_definition_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  release_number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Engines_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['engines_max_fields'] = ResolversParentTypes['engines_max_fields']> = {
  azure_build_ref?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  azure_release_ref?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  branch?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  build_definition_id_azure?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  build_number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  build_ref?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  build_version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  elliptic_engine_commit_hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  graph_version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  nickname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  path?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  release_definition_id_azure?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  release_number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Engines_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['engines_min_fields'] = ResolversParentTypes['engines_min_fields']> = {
  azure_build_ref?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  azure_release_ref?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  branch?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  build_definition_id_azure?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  build_number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  build_ref?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  build_version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  elliptic_engine_commit_hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  graph_version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  nickname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  path?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  release_definition_id_azure?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  release_number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Engines_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['engines_mutation_response'] = ResolversParentTypes['engines_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['engines']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Engines_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['engines_stddev_fields'] = ResolversParentTypes['engines_stddev_fields']> = {
  azure_build_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  azure_release_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  build_definition_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  graph_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  release_definition_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  release_number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Engines_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['engines_stddev_pop_fields'] = ResolversParentTypes['engines_stddev_pop_fields']> = {
  azure_build_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  azure_release_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  build_definition_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  graph_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  release_definition_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  release_number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Engines_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['engines_stddev_samp_fields'] = ResolversParentTypes['engines_stddev_samp_fields']> = {
  azure_build_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  azure_release_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  build_definition_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  graph_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  release_definition_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  release_number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Engines_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['engines_sum_fields'] = ResolversParentTypes['engines_sum_fields']> = {
  azure_build_ref?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  azure_release_ref?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  build_definition_id_azure?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  graph_version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  release_definition_id_azure?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  release_number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Engines_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['engines_var_pop_fields'] = ResolversParentTypes['engines_var_pop_fields']> = {
  azure_build_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  azure_release_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  build_definition_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  graph_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  release_definition_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  release_number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Engines_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['engines_var_samp_fields'] = ResolversParentTypes['engines_var_samp_fields']> = {
  azure_build_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  azure_release_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  build_definition_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  graph_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  release_definition_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  release_number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Engines_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['engines_variance_fields'] = ResolversParentTypes['engines_variance_fields']> = {
  azure_build_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  azure_release_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  build_definition_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  graph_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  release_definition_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  release_number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Field_Option_MediaResolvers<ContextType = any, ParentType extends ResolversParentTypes['field_option_media'] = ResolversParentTypes['field_option_media']> = {
  field_option?: Resolver<ResolversTypes['field_options'], ParentType, ContextType>;
  field_option_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  media?: Resolver<ResolversTypes['media'], ParentType, ContextType>;
  media_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Field_Option_Media_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['field_option_media_aggregate'] = ResolversParentTypes['field_option_media_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['field_option_media_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['field_option_media']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Field_Option_Media_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['field_option_media_aggregate_fields'] = ResolversParentTypes['field_option_media_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Field_Option_Media_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['field_option_media_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['field_option_media_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Field_Option_Media_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['field_option_media_max_fields'] = ResolversParentTypes['field_option_media_max_fields']> = {
  field_option_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  media_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Field_Option_Media_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['field_option_media_min_fields'] = ResolversParentTypes['field_option_media_min_fields']> = {
  field_option_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  media_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Field_Option_Media_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['field_option_media_mutation_response'] = ResolversParentTypes['field_option_media_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['field_option_media']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Field_OptionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['field_options'] = ResolversParentTypes['field_options']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  field?: Resolver<Maybe<ResolversTypes['fields']>, ParentType, ContextType>;
  field_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  field_option_media?: Resolver<Array<ResolversTypes['field_option_media']>, ParentType, ContextType, RequireFields<Field_OptionsField_Option_MediaArgs, never>>;
  field_option_media_aggregate?: Resolver<ResolversTypes['field_option_media_aggregate'], ParentType, ContextType, RequireFields<Field_OptionsField_Option_Media_AggregateArgs, never>>;
  hypertask_field_options?: Resolver<Array<ResolversTypes['hypertask_field_option']>, ParentType, ContextType, RequireFields<Field_OptionsHypertask_Field_OptionsArgs, never>>;
  hypertask_field_options_aggregate?: Resolver<ResolversTypes['hypertask_field_option_aggregate'], ParentType, ContextType, RequireFields<Field_OptionsHypertask_Field_Options_AggregateArgs, never>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  option?: Resolver<Maybe<ResolversTypes['options']>, ParentType, ContextType>;
  option_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  task_field_options?: Resolver<Array<ResolversTypes['task_field_option']>, ParentType, ContextType, RequireFields<Field_OptionsTask_Field_OptionsArgs, never>>;
  task_field_options_aggregate?: Resolver<ResolversTypes['task_field_option_aggregate'], ParentType, ContextType, RequireFields<Field_OptionsTask_Field_Options_AggregateArgs, never>>;
  type?: Resolver<ResolversTypes['types'], ParentType, ContextType>;
  type_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Field_Options_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['field_options_aggregate'] = ResolversParentTypes['field_options_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['field_options_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['field_options']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Field_Options_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['field_options_aggregate_fields'] = ResolversParentTypes['field_options_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Field_Options_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['field_options_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['field_options_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Field_Options_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['field_options_max_fields'] = ResolversParentTypes['field_options_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  field_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  option_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Field_Options_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['field_options_min_fields'] = ResolversParentTypes['field_options_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  field_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  option_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Field_Options_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['field_options_mutation_response'] = ResolversParentTypes['field_options_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['field_options']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Field_TranslationResolvers<ContextType = any, ParentType extends ResolversParentTypes['field_translation'] = ResolversParentTypes['field_translation']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  display?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  field?: Resolver<ResolversTypes['fields'], ParentType, ContextType>;
  field_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  field_translation_language?: Resolver<ResolversTypes['languages'], ParentType, ContextType>;
  language?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Field_Translation_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['field_translation_aggregate'] = ResolversParentTypes['field_translation_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['field_translation_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['field_translation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Field_Translation_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['field_translation_aggregate_fields'] = ResolversParentTypes['field_translation_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Field_Translation_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['field_translation_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['field_translation_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Field_Translation_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['field_translation_max_fields'] = ResolversParentTypes['field_translation_max_fields']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  display?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  field_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Field_Translation_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['field_translation_min_fields'] = ResolversParentTypes['field_translation_min_fields']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  display?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  field_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Field_Translation_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['field_translation_mutation_response'] = ResolversParentTypes['field_translation_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['field_translation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['fields'] = ResolversParentTypes['fields']> = {
  context?: Resolver<ResolversTypes['contexts'], ParentType, ContextType>;
  context_display_fields?: Resolver<Array<ResolversTypes['context_display_field']>, ParentType, ContextType, RequireFields<FieldsContext_Display_FieldsArgs, never>>;
  context_display_fields_aggregate?: Resolver<ResolversTypes['context_display_field_aggregate'], ParentType, ContextType, RequireFields<FieldsContext_Display_Fields_AggregateArgs, never>>;
  context_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  display?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  field_options?: Resolver<Array<ResolversTypes['field_options']>, ParentType, ContextType, RequireFields<FieldsField_OptionsArgs, never>>;
  field_options_aggregate?: Resolver<ResolversTypes['field_options_aggregate'], ParentType, ContextType, RequireFields<FieldsField_Options_AggregateArgs, never>>;
  hypertask_field_options?: Resolver<Array<ResolversTypes['hypertask_field_option']>, ParentType, ContextType, RequireFields<FieldsHypertask_Field_OptionsArgs, never>>;
  hypertask_field_options_aggregate?: Resolver<ResolversTypes['hypertask_field_option_aggregate'], ParentType, ContextType, RequireFields<FieldsHypertask_Field_Options_AggregateArgs, never>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  translations?: Resolver<Array<ResolversTypes['field_translation']>, ParentType, ContextType, RequireFields<FieldsTranslationsArgs, never>>;
  translations_aggregate?: Resolver<ResolversTypes['field_translation_aggregate'], ParentType, ContextType, RequireFields<FieldsTranslations_AggregateArgs, never>>;
  type?: Resolver<ResolversTypes['types'], ParentType, ContextType>;
  type_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Fields_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['fields_aggregate'] = ResolversParentTypes['fields_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['fields_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Fields_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['fields_aggregate_fields'] = ResolversParentTypes['fields_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Fields_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['fields_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['fields_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Fields_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['fields_max_fields'] = ResolversParentTypes['fields_max_fields']> = {
  context_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  display?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Fields_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['fields_min_fields'] = ResolversParentTypes['fields_min_fields']> = {
  context_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  display?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Fields_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['fields_mutation_response'] = ResolversParentTypes['fields_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FirmwareResolvers<ContextType = any, ParentType extends ResolversParentTypes['firmware'] = ResolversParentTypes['firmware']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  device_firmwares?: Resolver<Array<ResolversTypes['device_firmware']>, ParentType, ContextType, RequireFields<FirmwareDevice_FirmwaresArgs, never>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  properties?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType, RequireFields<FirmwarePropertiesArgs, never>>;
  recordings?: Resolver<Array<ResolversTypes['recordings']>, ParentType, ContextType, RequireFields<FirmwareRecordingsArgs, never>>;
  recordings_aggregate?: Resolver<ResolversTypes['recordings_aggregate'], ParentType, ContextType, RequireFields<FirmwareRecordings_AggregateArgs, never>>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Firmware_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['firmware_aggregate'] = ResolversParentTypes['firmware_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['firmware_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['firmware']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Firmware_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['firmware_aggregate_fields'] = ResolversParentTypes['firmware_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Firmware_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['firmware_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['firmware_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Firmware_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['firmware_max_fields'] = ResolversParentTypes['firmware_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Firmware_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['firmware_min_fields'] = ResolversParentTypes['firmware_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Firmware_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['firmware_mutation_response'] = ResolversParentTypes['firmware_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['firmware']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface Float8ScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['float8'], any> {
  name: 'float8';
}

export type Ground_Truth_ConfigurationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_configurations'] = ResolversParentTypes['ground_truth_configurations']> = {
  device_serialno?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  device_type?: Resolver<Maybe<ResolversTypes['ground_truth_device_types']>, ParentType, ContextType>;
  device_type_id?: Resolver<Maybe<ResolversTypes['ground_truth_device_types_enum']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  properties?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, RequireFields<Ground_Truth_ConfigurationsPropertiesArgs, never>>;
  recordings?: Resolver<Array<ResolversTypes['ground_truth_recordings']>, ParentType, ContextType, RequireFields<Ground_Truth_ConfigurationsRecordingsArgs, never>>;
  recordings_aggregate?: Resolver<ResolversTypes['ground_truth_recordings_aggregate'], ParentType, ContextType, RequireFields<Ground_Truth_ConfigurationsRecordings_AggregateArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Configurations_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_configurations_aggregate'] = ResolversParentTypes['ground_truth_configurations_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['ground_truth_configurations_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['ground_truth_configurations']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Configurations_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_configurations_aggregate_fields'] = ResolversParentTypes['ground_truth_configurations_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Ground_Truth_Configurations_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['ground_truth_configurations_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ground_truth_configurations_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Configurations_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_configurations_max_fields'] = ResolversParentTypes['ground_truth_configurations_max_fields']> = {
  device_serialno?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Configurations_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_configurations_min_fields'] = ResolversParentTypes['ground_truth_configurations_min_fields']> = {
  device_serialno?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Configurations_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_configurations_mutation_response'] = ResolversParentTypes['ground_truth_configurations_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ground_truth_configurations']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Device_TypesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_device_types'] = ResolversParentTypes['ground_truth_device_types']> = {
  configurations?: Resolver<Array<ResolversTypes['ground_truth_configurations']>, ParentType, ContextType, RequireFields<Ground_Truth_Device_TypesConfigurationsArgs, never>>;
  configurations_aggregate?: Resolver<ResolversTypes['ground_truth_configurations_aggregate'], ParentType, ContextType, RequireFields<Ground_Truth_Device_TypesConfigurations_AggregateArgs, never>>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Device_Types_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_device_types_aggregate'] = ResolversParentTypes['ground_truth_device_types_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['ground_truth_device_types_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['ground_truth_device_types']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Device_Types_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_device_types_aggregate_fields'] = ResolversParentTypes['ground_truth_device_types_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Ground_Truth_Device_Types_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['ground_truth_device_types_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ground_truth_device_types_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Device_Types_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_device_types_max_fields'] = ResolversParentTypes['ground_truth_device_types_max_fields']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Device_Types_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_device_types_min_fields'] = ResolversParentTypes['ground_truth_device_types_min_fields']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Device_Types_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_device_types_mutation_response'] = ResolversParentTypes['ground_truth_device_types_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ground_truth_device_types']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Processing_ModesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_processing_modes'] = ResolversParentTypes['ground_truth_processing_modes']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recordings?: Resolver<Array<ResolversTypes['ground_truth_recordings']>, ParentType, ContextType, RequireFields<Ground_Truth_Processing_ModesRecordingsArgs, never>>;
  recordings_aggregate?: Resolver<ResolversTypes['ground_truth_recordings_aggregate'], ParentType, ContextType, RequireFields<Ground_Truth_Processing_ModesRecordings_AggregateArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Processing_Modes_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_processing_modes_aggregate'] = ResolversParentTypes['ground_truth_processing_modes_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['ground_truth_processing_modes_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['ground_truth_processing_modes']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Processing_Modes_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_processing_modes_aggregate_fields'] = ResolversParentTypes['ground_truth_processing_modes_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Ground_Truth_Processing_Modes_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['ground_truth_processing_modes_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ground_truth_processing_modes_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Processing_Modes_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_processing_modes_max_fields'] = ResolversParentTypes['ground_truth_processing_modes_max_fields']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Processing_Modes_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_processing_modes_min_fields'] = ResolversParentTypes['ground_truth_processing_modes_min_fields']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Processing_Modes_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_processing_modes_mutation_response'] = ResolversParentTypes['ground_truth_processing_modes_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ground_truth_processing_modes']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_RecordingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_recordings'] = ResolversParentTypes['ground_truth_recordings']> = {
  configuration?: Resolver<ResolversTypes['ground_truth_configurations'], ParentType, ContextType>;
  configuration_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  processing_mode?: Resolver<Maybe<ResolversTypes['ground_truth_processing_modes']>, ParentType, ContextType>;
  processing_mode_id?: Resolver<Maybe<ResolversTypes['ground_truth_processing_modes_enum']>, ParentType, ContextType>;
  recording?: Resolver<ResolversTypes['recordings'], ParentType, ContextType>;
  recording_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  timesync_delta?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  timesync_start?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Recordings_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_recordings_aggregate'] = ResolversParentTypes['ground_truth_recordings_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['ground_truth_recordings_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['ground_truth_recordings']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Recordings_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_recordings_aggregate_fields'] = ResolversParentTypes['ground_truth_recordings_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['ground_truth_recordings_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Ground_Truth_Recordings_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['ground_truth_recordings_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ground_truth_recordings_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['ground_truth_recordings_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['ground_truth_recordings_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['ground_truth_recordings_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['ground_truth_recordings_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['ground_truth_recordings_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['ground_truth_recordings_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['ground_truth_recordings_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Recordings_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_recordings_avg_fields'] = ResolversParentTypes['ground_truth_recordings_avg_fields']> = {
  timesync_delta?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timesync_start?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Recordings_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_recordings_max_fields'] = ResolversParentTypes['ground_truth_recordings_max_fields']> = {
  configuration_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  recording_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  timesync_delta?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  timesync_start?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Recordings_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_recordings_min_fields'] = ResolversParentTypes['ground_truth_recordings_min_fields']> = {
  configuration_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  recording_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  timesync_delta?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  timesync_start?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Recordings_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_recordings_mutation_response'] = ResolversParentTypes['ground_truth_recordings_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ground_truth_recordings']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Recordings_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_recordings_stddev_fields'] = ResolversParentTypes['ground_truth_recordings_stddev_fields']> = {
  timesync_delta?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timesync_start?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Recordings_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_recordings_stddev_pop_fields'] = ResolversParentTypes['ground_truth_recordings_stddev_pop_fields']> = {
  timesync_delta?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timesync_start?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Recordings_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_recordings_stddev_samp_fields'] = ResolversParentTypes['ground_truth_recordings_stddev_samp_fields']> = {
  timesync_delta?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timesync_start?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Recordings_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_recordings_sum_fields'] = ResolversParentTypes['ground_truth_recordings_sum_fields']> = {
  timesync_delta?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  timesync_start?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Recordings_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_recordings_var_pop_fields'] = ResolversParentTypes['ground_truth_recordings_var_pop_fields']> = {
  timesync_delta?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timesync_start?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Recordings_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_recordings_var_samp_fields'] = ResolversParentTypes['ground_truth_recordings_var_samp_fields']> = {
  timesync_delta?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timesync_start?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Recordings_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_recordings_variance_fields'] = ResolversParentTypes['ground_truth_recordings_variance_fields']> = {
  timesync_delta?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timesync_start?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_TypesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_types'] = ResolversParentTypes['ground_truth_types']> = {
  default_far_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  default_near_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  max_far_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  max_near_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  min_far_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  min_near_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Types_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_types_aggregate'] = ResolversParentTypes['ground_truth_types_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['ground_truth_types_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['ground_truth_types']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Types_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_types_aggregate_fields'] = ResolversParentTypes['ground_truth_types_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['ground_truth_types_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Ground_Truth_Types_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['ground_truth_types_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ground_truth_types_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['ground_truth_types_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['ground_truth_types_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['ground_truth_types_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['ground_truth_types_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['ground_truth_types_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['ground_truth_types_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['ground_truth_types_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Types_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_types_avg_fields'] = ResolversParentTypes['ground_truth_types_avg_fields']> = {
  default_far_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  default_near_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_far_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_near_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_far_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_near_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Types_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_types_max_fields'] = ResolversParentTypes['ground_truth_types_max_fields']> = {
  default_far_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  default_near_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  max_far_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  max_near_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  min_far_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  min_near_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Types_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_types_min_fields'] = ResolversParentTypes['ground_truth_types_min_fields']> = {
  default_far_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  default_near_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  max_far_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  max_near_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  min_far_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  min_near_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Types_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_types_mutation_response'] = ResolversParentTypes['ground_truth_types_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ground_truth_types']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Types_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_types_stddev_fields'] = ResolversParentTypes['ground_truth_types_stddev_fields']> = {
  default_far_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  default_near_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_far_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_near_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_far_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_near_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Types_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_types_stddev_pop_fields'] = ResolversParentTypes['ground_truth_types_stddev_pop_fields']> = {
  default_far_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  default_near_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_far_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_near_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_far_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_near_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Types_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_types_stddev_samp_fields'] = ResolversParentTypes['ground_truth_types_stddev_samp_fields']> = {
  default_far_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  default_near_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_far_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_near_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_far_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_near_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Types_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_types_sum_fields'] = ResolversParentTypes['ground_truth_types_sum_fields']> = {
  default_far_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  default_near_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  max_far_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  max_near_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  min_far_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  min_near_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Types_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_types_var_pop_fields'] = ResolversParentTypes['ground_truth_types_var_pop_fields']> = {
  default_far_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  default_near_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_far_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_near_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_far_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_near_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Types_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_types_var_samp_fields'] = ResolversParentTypes['ground_truth_types_var_samp_fields']> = {
  default_far_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  default_near_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_far_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_near_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_far_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_near_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ground_Truth_Types_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ground_truth_types_variance_fields'] = ResolversParentTypes['ground_truth_types_variance_fields']> = {
  default_far_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  default_near_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_far_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_near_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_far_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_near_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Hypertask_Field_OptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertask_field_option'] = ResolversParentTypes['hypertask_field_option']> = {
  field?: Resolver<ResolversTypes['fields'], ParentType, ContextType>;
  field_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  field_option?: Resolver<Maybe<ResolversTypes['field_options']>, ParentType, ContextType>;
  hypertask?: Resolver<ResolversTypes['hypertasks'], ParentType, ContextType>;
  hypertask_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  option?: Resolver<ResolversTypes['options'], ParentType, ContextType>;
  option_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  weight?: Resolver<ResolversTypes['float8'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Hypertask_Field_Option_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertask_field_option_aggregate'] = ResolversParentTypes['hypertask_field_option_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['hypertask_field_option_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['hypertask_field_option']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Hypertask_Field_Option_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertask_field_option_aggregate_fields'] = ResolversParentTypes['hypertask_field_option_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['hypertask_field_option_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Hypertask_Field_Option_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['hypertask_field_option_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['hypertask_field_option_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['hypertask_field_option_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['hypertask_field_option_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['hypertask_field_option_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['hypertask_field_option_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['hypertask_field_option_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['hypertask_field_option_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['hypertask_field_option_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Hypertask_Field_Option_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertask_field_option_avg_fields'] = ResolversParentTypes['hypertask_field_option_avg_fields']> = {
  weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Hypertask_Field_Option_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertask_field_option_max_fields'] = ResolversParentTypes['hypertask_field_option_max_fields']> = {
  field_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  hypertask_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  option_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Hypertask_Field_Option_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertask_field_option_min_fields'] = ResolversParentTypes['hypertask_field_option_min_fields']> = {
  field_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  hypertask_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  option_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Hypertask_Field_Option_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertask_field_option_mutation_response'] = ResolversParentTypes['hypertask_field_option_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['hypertask_field_option']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Hypertask_Field_Option_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertask_field_option_stddev_fields'] = ResolversParentTypes['hypertask_field_option_stddev_fields']> = {
  weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Hypertask_Field_Option_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertask_field_option_stddev_pop_fields'] = ResolversParentTypes['hypertask_field_option_stddev_pop_fields']> = {
  weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Hypertask_Field_Option_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertask_field_option_stddev_samp_fields'] = ResolversParentTypes['hypertask_field_option_stddev_samp_fields']> = {
  weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Hypertask_Field_Option_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertask_field_option_sum_fields'] = ResolversParentTypes['hypertask_field_option_sum_fields']> = {
  weight?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Hypertask_Field_Option_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertask_field_option_var_pop_fields'] = ResolversParentTypes['hypertask_field_option_var_pop_fields']> = {
  weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Hypertask_Field_Option_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertask_field_option_var_samp_fields'] = ResolversParentTypes['hypertask_field_option_var_samp_fields']> = {
  weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Hypertask_Field_Option_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertask_field_option_variance_fields'] = ResolversParentTypes['hypertask_field_option_variance_fields']> = {
  weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Hypertask_TaskResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertask_task'] = ResolversParentTypes['hypertask_task']> = {
  hypertask?: Resolver<ResolversTypes['hypertasks'], ParentType, ContextType>;
  hypertask_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  task?: Resolver<ResolversTypes['tasks'], ParentType, ContextType>;
  task_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Hypertask_Task_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertask_task_aggregate'] = ResolversParentTypes['hypertask_task_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['hypertask_task_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['hypertask_task']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Hypertask_Task_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertask_task_aggregate_fields'] = ResolversParentTypes['hypertask_task_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Hypertask_Task_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['hypertask_task_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['hypertask_task_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Hypertask_Task_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertask_task_max_fields'] = ResolversParentTypes['hypertask_task_max_fields']> = {
  hypertask_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Hypertask_Task_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertask_task_min_fields'] = ResolversParentTypes['hypertask_task_min_fields']> = {
  hypertask_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Hypertask_Task_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertask_task_mutation_response'] = ResolversParentTypes['hypertask_task_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['hypertask_task']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HypertasksResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertasks'] = ResolversParentTypes['hypertasks']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hypertask_field_options?: Resolver<Array<ResolversTypes['hypertask_field_option']>, ParentType, ContextType, RequireFields<HypertasksHypertask_Field_OptionsArgs, never>>;
  hypertask_field_options_aggregate?: Resolver<ResolversTypes['hypertask_field_option_aggregate'], ParentType, ContextType, RequireFields<HypertasksHypertask_Field_Options_AggregateArgs, never>>;
  hypertask_tasks?: Resolver<Array<ResolversTypes['hypertask_task']>, ParentType, ContextType, RequireFields<HypertasksHypertask_TasksArgs, never>>;
  hypertask_tasks_aggregate?: Resolver<ResolversTypes['hypertask_task_aggregate'], ParentType, ContextType, RequireFields<HypertasksHypertask_Tasks_AggregateArgs, never>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  number?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  plan_hypertasks?: Resolver<Array<ResolversTypes['plan_hypertasks']>, ParentType, ContextType, RequireFields<HypertasksPlan_HypertasksArgs, never>>;
  plan_hypertasks_aggregate?: Resolver<ResolversTypes['plan_hypertasks_aggregate'], ParentType, ContextType, RequireFields<HypertasksPlan_Hypertasks_AggregateArgs, never>>;
  project_dataset?: Resolver<Array<ResolversTypes['project_dataset']>, ParentType, ContextType, RequireFields<HypertasksProject_DatasetArgs, never>>;
  project_dataset_aggregate?: Resolver<ResolversTypes['project_dataset_aggregate'], ParentType, ContextType, RequireFields<HypertasksProject_Dataset_AggregateArgs, never>>;
  project_hypertasks?: Resolver<Array<ResolversTypes['project_hypertasks']>, ParentType, ContextType, RequireFields<HypertasksProject_HypertasksArgs, never>>;
  project_hypertasks_aggregate?: Resolver<ResolversTypes['project_hypertasks_aggregate'], ParentType, ContextType, RequireFields<HypertasksProject_Hypertasks_AggregateArgs, never>>;
  recordings?: Resolver<Array<ResolversTypes['recordings']>, ParentType, ContextType, RequireFields<HypertasksRecordingsArgs, never>>;
  recordings_aggregate?: Resolver<ResolversTypes['recordings_aggregate'], ParentType, ContextType, RequireFields<HypertasksRecordings_AggregateArgs, never>>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Hypertasks_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertasks_aggregate'] = ResolversParentTypes['hypertasks_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['hypertasks_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['hypertasks']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Hypertasks_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertasks_aggregate_fields'] = ResolversParentTypes['hypertasks_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['hypertasks_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Hypertasks_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['hypertasks_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['hypertasks_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['hypertasks_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['hypertasks_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['hypertasks_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['hypertasks_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['hypertasks_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['hypertasks_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['hypertasks_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Hypertasks_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertasks_avg_fields'] = ResolversParentTypes['hypertasks_avg_fields']> = {
  number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Hypertasks_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertasks_max_fields'] = ResolversParentTypes['hypertasks_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Hypertasks_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertasks_min_fields'] = ResolversParentTypes['hypertasks_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Hypertasks_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertasks_mutation_response'] = ResolversParentTypes['hypertasks_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['hypertasks']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Hypertasks_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertasks_stddev_fields'] = ResolversParentTypes['hypertasks_stddev_fields']> = {
  number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Hypertasks_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertasks_stddev_pop_fields'] = ResolversParentTypes['hypertasks_stddev_pop_fields']> = {
  number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Hypertasks_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertasks_stddev_samp_fields'] = ResolversParentTypes['hypertasks_stddev_samp_fields']> = {
  number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Hypertasks_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertasks_sum_fields'] = ResolversParentTypes['hypertasks_sum_fields']> = {
  number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Hypertasks_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertasks_var_pop_fields'] = ResolversParentTypes['hypertasks_var_pop_fields']> = {
  number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Hypertasks_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertasks_var_samp_fields'] = ResolversParentTypes['hypertasks_var_samp_fields']> = {
  number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Hypertasks_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['hypertasks_variance_fields'] = ResolversParentTypes['hypertasks_variance_fields']> = {
  number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Instruction_TranslationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['instruction_translations'] = ResolversParentTypes['instruction_translations']> = {
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  language?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  task_instruction_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  task_instructions?: Resolver<ResolversTypes['task_instructions'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['users'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Instruction_Translations_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['instruction_translations_mutation_response'] = ResolversParentTypes['instruction_translations_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['instruction_translations']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Expectation_MediaResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_expectation_media'] = ResolversParentTypes['integration_test_expectation_media']> = {
  expectation?: Resolver<ResolversTypes['integration_test_expectations'], ParentType, ContextType>;
  expectation_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  media_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  medium?: Resolver<ResolversTypes['media'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Expectation_Media_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_expectation_media_aggregate'] = ResolversParentTypes['integration_test_expectation_media_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['integration_test_expectation_media_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['integration_test_expectation_media']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Expectation_Media_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_expectation_media_aggregate_fields'] = ResolversParentTypes['integration_test_expectation_media_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Integration_Test_Expectation_Media_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['integration_test_expectation_media_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['integration_test_expectation_media_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Expectation_Media_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_expectation_media_max_fields'] = ResolversParentTypes['integration_test_expectation_media_max_fields']> = {
  expectation_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  media_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Expectation_Media_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_expectation_media_min_fields'] = ResolversParentTypes['integration_test_expectation_media_min_fields']> = {
  expectation_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  media_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Expectation_Media_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_expectation_media_mutation_response'] = ResolversParentTypes['integration_test_expectation_media_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['integration_test_expectation_media']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Expectation_StepResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_expectation_step'] = ResolversParentTypes['integration_test_expectation_step']> = {
  expectation?: Resolver<ResolversTypes['integration_test_expectations'], ParentType, ContextType>;
  expectation_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  observations?: Resolver<Array<ResolversTypes['integration_test_observations']>, ParentType, ContextType, RequireFields<Integration_Test_Expectation_StepObservationsArgs, never>>;
  observations_aggregate?: Resolver<ResolversTypes['integration_test_observations_aggregate'], ParentType, ContextType, RequireFields<Integration_Test_Expectation_StepObservations_AggregateArgs, never>>;
  step?: Resolver<ResolversTypes['integration_test_steps'], ParentType, ContextType>;
  step_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Expectation_Step_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_expectation_step_aggregate'] = ResolversParentTypes['integration_test_expectation_step_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['integration_test_expectation_step_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['integration_test_expectation_step']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Expectation_Step_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_expectation_step_aggregate_fields'] = ResolversParentTypes['integration_test_expectation_step_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Integration_Test_Expectation_Step_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['integration_test_expectation_step_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['integration_test_expectation_step_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Expectation_Step_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_expectation_step_max_fields'] = ResolversParentTypes['integration_test_expectation_step_max_fields']> = {
  expectation_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  step_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Expectation_Step_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_expectation_step_min_fields'] = ResolversParentTypes['integration_test_expectation_step_min_fields']> = {
  expectation_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  step_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Expectation_Step_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_expectation_step_mutation_response'] = ResolversParentTypes['integration_test_expectation_step_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['integration_test_expectation_step']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Expectation_TranslationResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_expectation_translation'] = ResolversParentTypes['integration_test_expectation_translation']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expectation?: Resolver<ResolversTypes['integration_test_expectations'], ParentType, ContextType>;
  expectation_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  expectation_translation_language?: Resolver<ResolversTypes['languages'], ParentType, ContextType>;
  language?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Expectation_Translation_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_expectation_translation_aggregate'] = ResolversParentTypes['integration_test_expectation_translation_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['integration_test_expectation_translation_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['integration_test_expectation_translation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Expectation_Translation_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_expectation_translation_aggregate_fields'] = ResolversParentTypes['integration_test_expectation_translation_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Integration_Test_Expectation_Translation_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['integration_test_expectation_translation_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['integration_test_expectation_translation_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Expectation_Translation_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_expectation_translation_max_fields'] = ResolversParentTypes['integration_test_expectation_translation_max_fields']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expectation_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Expectation_Translation_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_expectation_translation_min_fields'] = ResolversParentTypes['integration_test_expectation_translation_min_fields']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expectation_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Expectation_Translation_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_expectation_translation_mutation_response'] = ResolversParentTypes['integration_test_expectation_translation_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['integration_test_expectation_translation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_ExpectationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_expectations'] = ResolversParentTypes['integration_test_expectations']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expectation_media?: Resolver<Array<ResolversTypes['integration_test_expectation_media']>, ParentType, ContextType, RequireFields<Integration_Test_ExpectationsExpectation_MediaArgs, never>>;
  expectation_media_aggregate?: Resolver<ResolversTypes['integration_test_expectation_media_aggregate'], ParentType, ContextType, RequireFields<Integration_Test_ExpectationsExpectation_Media_AggregateArgs, never>>;
  expectation_steps?: Resolver<Array<ResolversTypes['integration_test_expectation_step']>, ParentType, ContextType, RequireFields<Integration_Test_ExpectationsExpectation_StepsArgs, never>>;
  expectation_steps_aggregate?: Resolver<ResolversTypes['integration_test_expectation_step_aggregate'], ParentType, ContextType, RequireFields<Integration_Test_ExpectationsExpectation_Steps_AggregateArgs, never>>;
  expectation_translations?: Resolver<Array<ResolversTypes['integration_test_expectation_translation']>, ParentType, ContextType, RequireFields<Integration_Test_ExpectationsExpectation_TranslationsArgs, never>>;
  expectation_translations_aggregate?: Resolver<ResolversTypes['integration_test_expectation_translation_aggregate'], ParentType, ContextType, RequireFields<Integration_Test_ExpectationsExpectation_Translations_AggregateArgs, never>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Expectations_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_expectations_aggregate'] = ResolversParentTypes['integration_test_expectations_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['integration_test_expectations_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['integration_test_expectations']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Expectations_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_expectations_aggregate_fields'] = ResolversParentTypes['integration_test_expectations_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Integration_Test_Expectations_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['integration_test_expectations_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['integration_test_expectations_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Expectations_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_expectations_max_fields'] = ResolversParentTypes['integration_test_expectations_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Expectations_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_expectations_min_fields'] = ResolversParentTypes['integration_test_expectations_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Expectations_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_expectations_mutation_response'] = ResolversParentTypes['integration_test_expectations_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['integration_test_expectations']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Instruction_MediaResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_instruction_media'] = ResolversParentTypes['integration_test_instruction_media']> = {
  instruction?: Resolver<ResolversTypes['integration_test_instructions'], ParentType, ContextType>;
  instruction_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  media_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  medium?: Resolver<ResolversTypes['media'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Instruction_Media_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_instruction_media_aggregate'] = ResolversParentTypes['integration_test_instruction_media_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['integration_test_instruction_media_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['integration_test_instruction_media']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Instruction_Media_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_instruction_media_aggregate_fields'] = ResolversParentTypes['integration_test_instruction_media_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Integration_Test_Instruction_Media_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['integration_test_instruction_media_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['integration_test_instruction_media_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Instruction_Media_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_instruction_media_max_fields'] = ResolversParentTypes['integration_test_instruction_media_max_fields']> = {
  instruction_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  media_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Instruction_Media_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_instruction_media_min_fields'] = ResolversParentTypes['integration_test_instruction_media_min_fields']> = {
  instruction_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  media_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Instruction_Media_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_instruction_media_mutation_response'] = ResolversParentTypes['integration_test_instruction_media_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['integration_test_instruction_media']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Instruction_TranslationResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_instruction_translation'] = ResolversParentTypes['integration_test_instruction_translation']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  instruction?: Resolver<ResolversTypes['integration_test_instructions'], ParentType, ContextType>;
  instruction_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  instruction_translation_language?: Resolver<ResolversTypes['languages'], ParentType, ContextType>;
  language?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Instruction_Translation_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_instruction_translation_aggregate'] = ResolversParentTypes['integration_test_instruction_translation_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['integration_test_instruction_translation_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['integration_test_instruction_translation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Instruction_Translation_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_instruction_translation_aggregate_fields'] = ResolversParentTypes['integration_test_instruction_translation_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Integration_Test_Instruction_Translation_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['integration_test_instruction_translation_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['integration_test_instruction_translation_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Instruction_Translation_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_instruction_translation_max_fields'] = ResolversParentTypes['integration_test_instruction_translation_max_fields']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  instruction_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Instruction_Translation_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_instruction_translation_min_fields'] = ResolversParentTypes['integration_test_instruction_translation_min_fields']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  instruction_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Instruction_Translation_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_instruction_translation_mutation_response'] = ResolversParentTypes['integration_test_instruction_translation_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['integration_test_instruction_translation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_InstructionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_instructions'] = ResolversParentTypes['integration_test_instructions']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  instruction_media?: Resolver<Array<ResolversTypes['integration_test_instruction_media']>, ParentType, ContextType, RequireFields<Integration_Test_InstructionsInstruction_MediaArgs, never>>;
  instruction_media_aggregate?: Resolver<ResolversTypes['integration_test_instruction_media_aggregate'], ParentType, ContextType, RequireFields<Integration_Test_InstructionsInstruction_Media_AggregateArgs, never>>;
  instruction_translations?: Resolver<Array<ResolversTypes['integration_test_instruction_translation']>, ParentType, ContextType, RequireFields<Integration_Test_InstructionsInstruction_TranslationsArgs, never>>;
  instruction_translations_aggregate?: Resolver<ResolversTypes['integration_test_instruction_translation_aggregate'], ParentType, ContextType, RequireFields<Integration_Test_InstructionsInstruction_Translations_AggregateArgs, never>>;
  steps?: Resolver<Array<ResolversTypes['integration_test_steps']>, ParentType, ContextType, RequireFields<Integration_Test_InstructionsStepsArgs, never>>;
  steps_aggregate?: Resolver<ResolversTypes['integration_test_steps_aggregate'], ParentType, ContextType, RequireFields<Integration_Test_InstructionsSteps_AggregateArgs, never>>;
  task?: Resolver<Maybe<ResolversTypes['tasks']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Instructions_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_instructions_aggregate'] = ResolversParentTypes['integration_test_instructions_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['integration_test_instructions_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['integration_test_instructions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Instructions_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_instructions_aggregate_fields'] = ResolversParentTypes['integration_test_instructions_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Integration_Test_Instructions_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['integration_test_instructions_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['integration_test_instructions_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Instructions_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_instructions_max_fields'] = ResolversParentTypes['integration_test_instructions_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Instructions_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_instructions_min_fields'] = ResolversParentTypes['integration_test_instructions_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Instructions_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_instructions_mutation_response'] = ResolversParentTypes['integration_test_instructions_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['integration_test_instructions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_ObservancesResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_observances'] = ResolversParentTypes['integration_test_observances']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  engine?: Resolver<Maybe<ResolversTypes['engines']>, ParentType, ContextType>;
  engine_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  observations?: Resolver<Array<ResolversTypes['integration_test_observations']>, ParentType, ContextType, RequireFields<Integration_Test_ObservancesObservationsArgs, never>>;
  observations_aggregate?: Resolver<ResolversTypes['integration_test_observations_aggregate'], ParentType, ContextType, RequireFields<Integration_Test_ObservancesObservations_AggregateArgs, never>>;
  project?: Resolver<ResolversTypes['projects'], ParentType, ContextType>;
  project_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  sequence?: Resolver<ResolversTypes['integration_test_sequences'], ParentType, ContextType>;
  sequence_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['users'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Observances_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_observances_aggregate'] = ResolversParentTypes['integration_test_observances_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['integration_test_observances_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['integration_test_observances']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Observances_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_observances_aggregate_fields'] = ResolversParentTypes['integration_test_observances_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Integration_Test_Observances_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['integration_test_observances_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['integration_test_observances_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Observances_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_observances_max_fields'] = ResolversParentTypes['integration_test_observances_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  engine_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  sequence_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Observances_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_observances_min_fields'] = ResolversParentTypes['integration_test_observances_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  engine_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  sequence_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Observances_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_observances_mutation_response'] = ResolversParentTypes['integration_test_observances_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['integration_test_observances']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_ObservationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_observations'] = ResolversParentTypes['integration_test_observations']> = {
  expectation_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  expectation_step?: Resolver<Maybe<ResolversTypes['integration_test_expectation_step']>, ParentType, ContextType>;
  observance?: Resolver<ResolversTypes['integration_test_observances'], ParentType, ContextType>;
  observance_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  step_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Observations_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_observations_aggregate'] = ResolversParentTypes['integration_test_observations_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['integration_test_observations_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['integration_test_observations']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Observations_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_observations_aggregate_fields'] = ResolversParentTypes['integration_test_observations_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Integration_Test_Observations_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['integration_test_observations_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['integration_test_observations_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Observations_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_observations_max_fields'] = ResolversParentTypes['integration_test_observations_max_fields']> = {
  expectation_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  observance_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  step_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Observations_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_observations_min_fields'] = ResolversParentTypes['integration_test_observations_min_fields']> = {
  expectation_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  observance_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  step_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Observations_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_observations_mutation_response'] = ResolversParentTypes['integration_test_observations_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['integration_test_observations']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Project_SequenceResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_project_sequence'] = ResolversParentTypes['integration_test_project_sequence']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  project?: Resolver<ResolversTypes['projects'], ParentType, ContextType>;
  project_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  sequence?: Resolver<ResolversTypes['integration_test_sequences'], ParentType, ContextType>;
  sequence_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Project_Sequence_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_project_sequence_aggregate'] = ResolversParentTypes['integration_test_project_sequence_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['integration_test_project_sequence_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['integration_test_project_sequence']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Project_Sequence_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_project_sequence_aggregate_fields'] = ResolversParentTypes['integration_test_project_sequence_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Integration_Test_Project_Sequence_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['integration_test_project_sequence_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['integration_test_project_sequence_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Project_Sequence_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_project_sequence_max_fields'] = ResolversParentTypes['integration_test_project_sequence_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  sequence_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Project_Sequence_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_project_sequence_min_fields'] = ResolversParentTypes['integration_test_project_sequence_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  sequence_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Project_Sequence_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_project_sequence_mutation_response'] = ResolversParentTypes['integration_test_project_sequence_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['integration_test_project_sequence']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_SequencesResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_sequences'] = ResolversParentTypes['integration_test_sequences']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  observances?: Resolver<Array<ResolversTypes['integration_test_observances']>, ParentType, ContextType, RequireFields<Integration_Test_SequencesObservancesArgs, never>>;
  observances_aggregate?: Resolver<ResolversTypes['integration_test_observances_aggregate'], ParentType, ContextType, RequireFields<Integration_Test_SequencesObservances_AggregateArgs, never>>;
  project_sequences?: Resolver<Array<ResolversTypes['integration_test_project_sequence']>, ParentType, ContextType, RequireFields<Integration_Test_SequencesProject_SequencesArgs, never>>;
  project_sequences_aggregate?: Resolver<ResolversTypes['integration_test_project_sequence_aggregate'], ParentType, ContextType, RequireFields<Integration_Test_SequencesProject_Sequences_AggregateArgs, never>>;
  steps?: Resolver<Array<ResolversTypes['integration_test_steps']>, ParentType, ContextType, RequireFields<Integration_Test_SequencesStepsArgs, never>>;
  steps_aggregate?: Resolver<ResolversTypes['integration_test_steps_aggregate'], ParentType, ContextType, RequireFields<Integration_Test_SequencesSteps_AggregateArgs, never>>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Sequences_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_sequences_aggregate'] = ResolversParentTypes['integration_test_sequences_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['integration_test_sequences_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['integration_test_sequences']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Sequences_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_sequences_aggregate_fields'] = ResolversParentTypes['integration_test_sequences_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Integration_Test_Sequences_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['integration_test_sequences_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['integration_test_sequences_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Sequences_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_sequences_max_fields'] = ResolversParentTypes['integration_test_sequences_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Sequences_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_sequences_min_fields'] = ResolversParentTypes['integration_test_sequences_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Sequences_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_sequences_mutation_response'] = ResolversParentTypes['integration_test_sequences_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['integration_test_sequences']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_StepsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_steps'] = ResolversParentTypes['integration_test_steps']> = {
  expectation_steps?: Resolver<Array<ResolversTypes['integration_test_expectation_step']>, ParentType, ContextType, RequireFields<Integration_Test_StepsExpectation_StepsArgs, never>>;
  expectation_steps_aggregate?: Resolver<ResolversTypes['integration_test_expectation_step_aggregate'], ParentType, ContextType, RequireFields<Integration_Test_StepsExpectation_Steps_AggregateArgs, never>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  instruction?: Resolver<ResolversTypes['integration_test_instructions'], ParentType, ContextType>;
  instruction_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  position?: Resolver<ResolversTypes['smallint'], ParentType, ContextType>;
  sequence?: Resolver<ResolversTypes['integration_test_sequences'], ParentType, ContextType>;
  sequence_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Steps_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_steps_aggregate'] = ResolversParentTypes['integration_test_steps_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['integration_test_steps_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['integration_test_steps']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Steps_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_steps_aggregate_fields'] = ResolversParentTypes['integration_test_steps_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['integration_test_steps_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Integration_Test_Steps_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['integration_test_steps_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['integration_test_steps_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['integration_test_steps_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['integration_test_steps_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['integration_test_steps_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['integration_test_steps_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['integration_test_steps_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['integration_test_steps_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['integration_test_steps_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Steps_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_steps_avg_fields'] = ResolversParentTypes['integration_test_steps_avg_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Steps_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_steps_max_fields'] = ResolversParentTypes['integration_test_steps_max_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  instruction_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  sequence_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Steps_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_steps_min_fields'] = ResolversParentTypes['integration_test_steps_min_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  instruction_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  sequence_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Steps_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_steps_mutation_response'] = ResolversParentTypes['integration_test_steps_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['integration_test_steps']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Steps_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_steps_stddev_fields'] = ResolversParentTypes['integration_test_steps_stddev_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Steps_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_steps_stddev_pop_fields'] = ResolversParentTypes['integration_test_steps_stddev_pop_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Steps_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_steps_stddev_samp_fields'] = ResolversParentTypes['integration_test_steps_stddev_samp_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Steps_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_steps_sum_fields'] = ResolversParentTypes['integration_test_steps_sum_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Steps_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_steps_var_pop_fields'] = ResolversParentTypes['integration_test_steps_var_pop_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Steps_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_steps_var_samp_fields'] = ResolversParentTypes['integration_test_steps_var_samp_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Integration_Test_Steps_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['integration_test_steps_variance_fields'] = ResolversParentTypes['integration_test_steps_variance_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['json'], any> {
  name: 'json';
}

export interface JsonbScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['jsonb'], any> {
  name: 'jsonb';
}

export type LanguagesResolvers<ContextType = any, ParentType extends ResolversParentTypes['languages'] = ResolversParentTypes['languages']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expectation_translations?: Resolver<Array<ResolversTypes['integration_test_expectation_translation']>, ParentType, ContextType, RequireFields<LanguagesExpectation_TranslationsArgs, never>>;
  expectation_translations_aggregate?: Resolver<ResolversTypes['integration_test_expectation_translation_aggregate'], ParentType, ContextType, RequireFields<LanguagesExpectation_Translations_AggregateArgs, never>>;
  field_translations?: Resolver<Array<ResolversTypes['field_translation']>, ParentType, ContextType, RequireFields<LanguagesField_TranslationsArgs, never>>;
  field_translations_aggregate?: Resolver<ResolversTypes['field_translation_aggregate'], ParentType, ContextType, RequireFields<LanguagesField_Translations_AggregateArgs, never>>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  instruction_translations?: Resolver<Array<ResolversTypes['integration_test_instruction_translation']>, ParentType, ContextType, RequireFields<LanguagesInstruction_TranslationsArgs, never>>;
  instruction_translations_aggregate?: Resolver<ResolversTypes['integration_test_instruction_translation_aggregate'], ParentType, ContextType, RequireFields<LanguagesInstruction_Translations_AggregateArgs, never>>;
  option_translations?: Resolver<Array<ResolversTypes['option_translation']>, ParentType, ContextType, RequireFields<LanguagesOption_TranslationsArgs, never>>;
  option_translations_aggregate?: Resolver<ResolversTypes['option_translation_aggregate'], ParentType, ContextType, RequireFields<LanguagesOption_Translations_AggregateArgs, never>>;
  task_translations?: Resolver<Array<ResolversTypes['task_translation']>, ParentType, ContextType, RequireFields<LanguagesTask_TranslationsArgs, never>>;
  task_translations_aggregate?: Resolver<ResolversTypes['task_translation_aggregate'], ParentType, ContextType, RequireFields<LanguagesTask_Translations_AggregateArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Languages_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['languages_mutation_response'] = ResolversParentTypes['languages_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['languages']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_BlobsResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_blobs'] = ResolversParentTypes['machine_learning_blobs']> = {
  blob_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  blob_storage_account_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  blob_storage_container_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  feature?: Resolver<Maybe<ResolversTypes['machine_learning_features']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  md5?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  model_blobs?: Resolver<Array<ResolversTypes['machine_learning_model_blob']>, ParentType, ContextType, RequireFields<Machine_Learning_BlobsModel_BlobsArgs, never>>;
  size_byte?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Blobs_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_blobs_aggregate'] = ResolversParentTypes['machine_learning_blobs_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['machine_learning_blobs_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['machine_learning_blobs']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Blobs_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_blobs_aggregate_fields'] = ResolversParentTypes['machine_learning_blobs_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['machine_learning_blobs_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Machine_Learning_Blobs_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['machine_learning_blobs_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['machine_learning_blobs_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['machine_learning_blobs_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['machine_learning_blobs_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['machine_learning_blobs_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['machine_learning_blobs_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['machine_learning_blobs_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['machine_learning_blobs_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['machine_learning_blobs_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Blobs_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_blobs_avg_fields'] = ResolversParentTypes['machine_learning_blobs_avg_fields']> = {
  size_byte?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Blobs_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_blobs_max_fields'] = ResolversParentTypes['machine_learning_blobs_max_fields']> = {
  blob_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blob_storage_account_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blob_storage_container_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  md5?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  size_byte?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Blobs_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_blobs_min_fields'] = ResolversParentTypes['machine_learning_blobs_min_fields']> = {
  blob_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blob_storage_account_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blob_storage_container_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  md5?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  size_byte?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Blobs_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_blobs_mutation_response'] = ResolversParentTypes['machine_learning_blobs_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['machine_learning_blobs']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Blobs_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_blobs_stddev_fields'] = ResolversParentTypes['machine_learning_blobs_stddev_fields']> = {
  size_byte?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Blobs_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_blobs_stddev_pop_fields'] = ResolversParentTypes['machine_learning_blobs_stddev_pop_fields']> = {
  size_byte?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Blobs_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_blobs_stddev_samp_fields'] = ResolversParentTypes['machine_learning_blobs_stddev_samp_fields']> = {
  size_byte?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Blobs_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_blobs_sum_fields'] = ResolversParentTypes['machine_learning_blobs_sum_fields']> = {
  size_byte?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Blobs_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_blobs_var_pop_fields'] = ResolversParentTypes['machine_learning_blobs_var_pop_fields']> = {
  size_byte?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Blobs_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_blobs_var_samp_fields'] = ResolversParentTypes['machine_learning_blobs_var_samp_fields']> = {
  size_byte?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Blobs_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_blobs_variance_fields'] = ResolversParentTypes['machine_learning_blobs_variance_fields']> = {
  size_byte?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Dataset_FeatureResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_dataset_feature'] = ResolversParentTypes['machine_learning_dataset_feature']> = {
  dataset?: Resolver<ResolversTypes['machine_learning_datasets'], ParentType, ContextType>;
  dataset_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  feature?: Resolver<ResolversTypes['machine_learning_features'], ParentType, ContextType>;
  feature_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Dataset_Feature_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_dataset_feature_aggregate'] = ResolversParentTypes['machine_learning_dataset_feature_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['machine_learning_dataset_feature_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['machine_learning_dataset_feature']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Dataset_Feature_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_dataset_feature_aggregate_fields'] = ResolversParentTypes['machine_learning_dataset_feature_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Machine_Learning_Dataset_Feature_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['machine_learning_dataset_feature_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['machine_learning_dataset_feature_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Dataset_Feature_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_dataset_feature_max_fields'] = ResolversParentTypes['machine_learning_dataset_feature_max_fields']> = {
  dataset_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  feature_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Dataset_Feature_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_dataset_feature_min_fields'] = ResolversParentTypes['machine_learning_dataset_feature_min_fields']> = {
  dataset_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  feature_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Dataset_Feature_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_dataset_feature_mutation_response'] = ResolversParentTypes['machine_learning_dataset_feature_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['machine_learning_dataset_feature']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_DatasetsResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_datasets'] = ResolversParentTypes['machine_learning_datasets']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  dataset_features?: Resolver<Array<ResolversTypes['machine_learning_dataset_feature']>, ParentType, ContextType, RequireFields<Machine_Learning_DatasetsDataset_FeaturesArgs, never>>;
  dataset_features_aggregate?: Resolver<ResolversTypes['machine_learning_dataset_feature_aggregate'], ParentType, ContextType, RequireFields<Machine_Learning_DatasetsDataset_Features_AggregateArgs, never>>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  model_datasets?: Resolver<Array<ResolversTypes['machine_learning_model_dataset']>, ParentType, ContextType, RequireFields<Machine_Learning_DatasetsModel_DatasetsArgs, never>>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Datasets_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_datasets_aggregate'] = ResolversParentTypes['machine_learning_datasets_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['machine_learning_datasets_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['machine_learning_datasets']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Datasets_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_datasets_aggregate_fields'] = ResolversParentTypes['machine_learning_datasets_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['machine_learning_datasets_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Machine_Learning_Datasets_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['machine_learning_datasets_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['machine_learning_datasets_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['machine_learning_datasets_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['machine_learning_datasets_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['machine_learning_datasets_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['machine_learning_datasets_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['machine_learning_datasets_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['machine_learning_datasets_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['machine_learning_datasets_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Datasets_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_datasets_avg_fields'] = ResolversParentTypes['machine_learning_datasets_avg_fields']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Datasets_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_datasets_max_fields'] = ResolversParentTypes['machine_learning_datasets_max_fields']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Datasets_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_datasets_min_fields'] = ResolversParentTypes['machine_learning_datasets_min_fields']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Datasets_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_datasets_mutation_response'] = ResolversParentTypes['machine_learning_datasets_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['machine_learning_datasets']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Datasets_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_datasets_stddev_fields'] = ResolversParentTypes['machine_learning_datasets_stddev_fields']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Datasets_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_datasets_stddev_pop_fields'] = ResolversParentTypes['machine_learning_datasets_stddev_pop_fields']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Datasets_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_datasets_stddev_samp_fields'] = ResolversParentTypes['machine_learning_datasets_stddev_samp_fields']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Datasets_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_datasets_sum_fields'] = ResolversParentTypes['machine_learning_datasets_sum_fields']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Datasets_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_datasets_var_pop_fields'] = ResolversParentTypes['machine_learning_datasets_var_pop_fields']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Datasets_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_datasets_var_samp_fields'] = ResolversParentTypes['machine_learning_datasets_var_samp_fields']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Datasets_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_datasets_variance_fields'] = ResolversParentTypes['machine_learning_datasets_variance_fields']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_FeaturesResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_features'] = ResolversParentTypes['machine_learning_features']> = {
  blob?: Resolver<ResolversTypes['machine_learning_blobs'], ParentType, ContextType>;
  blob_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  dataset_features?: Resolver<Array<ResolversTypes['machine_learning_dataset_feature']>, ParentType, ContextType, RequireFields<Machine_Learning_FeaturesDataset_FeaturesArgs, never>>;
  dataset_features_aggregate?: Resolver<ResolversTypes['machine_learning_dataset_feature_aggregate'], ParentType, ContextType, RequireFields<Machine_Learning_FeaturesDataset_Features_AggregateArgs, never>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  source_recording?: Resolver<Maybe<ResolversTypes['recordings']>, ParentType, ContextType>;
  source_recording_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Features_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_features_mutation_response'] = ResolversParentTypes['machine_learning_features_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['machine_learning_features']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Model_BlobResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_model_blob'] = ResolversParentTypes['machine_learning_model_blob']> = {
  blob?: Resolver<ResolversTypes['machine_learning_blobs'], ParentType, ContextType>;
  blob_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  model?: Resolver<ResolversTypes['machine_learning_models'], ParentType, ContextType>;
  model_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Model_Blob_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_model_blob_mutation_response'] = ResolversParentTypes['machine_learning_model_blob_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['machine_learning_model_blob']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Model_DatasetResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_model_dataset'] = ResolversParentTypes['machine_learning_model_dataset']> = {
  dataset?: Resolver<ResolversTypes['machine_learning_datasets'], ParentType, ContextType>;
  dataset_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  model?: Resolver<ResolversTypes['machine_learning_models'], ParentType, ContextType>;
  model_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Model_Dataset_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_model_dataset_mutation_response'] = ResolversParentTypes['machine_learning_model_dataset_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['machine_learning_model_dataset']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Model_RecordingResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_model_recording'] = ResolversParentTypes['machine_learning_model_recording']> = {
  model?: Resolver<ResolversTypes['machine_learning_models'], ParentType, ContextType>;
  model_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recording?: Resolver<ResolversTypes['recordings'], ParentType, ContextType>;
  recording_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Model_Recording_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_model_recording_aggregate'] = ResolversParentTypes['machine_learning_model_recording_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['machine_learning_model_recording_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['machine_learning_model_recording']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Model_Recording_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_model_recording_aggregate_fields'] = ResolversParentTypes['machine_learning_model_recording_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Machine_Learning_Model_Recording_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['machine_learning_model_recording_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['machine_learning_model_recording_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Model_Recording_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_model_recording_max_fields'] = ResolversParentTypes['machine_learning_model_recording_max_fields']> = {
  model_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recording_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Model_Recording_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_model_recording_min_fields'] = ResolversParentTypes['machine_learning_model_recording_min_fields']> = {
  model_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recording_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Model_Recording_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_model_recording_mutation_response'] = ResolversParentTypes['machine_learning_model_recording_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['machine_learning_model_recording']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Model_TypesResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_model_types'] = ResolversParentTypes['machine_learning_model_types']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_ModelsResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_models'] = ResolversParentTypes['machine_learning_models']> = {
  branch_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  build_id_azure?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  commit_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  model_blobs?: Resolver<Array<ResolversTypes['machine_learning_model_blob']>, ParentType, ContextType, RequireFields<Machine_Learning_ModelsModel_BlobsArgs, never>>;
  model_datasets?: Resolver<Array<ResolversTypes['machine_learning_model_dataset']>, ParentType, ContextType, RequireFields<Machine_Learning_ModelsModel_DatasetsArgs, never>>;
  model_source_recordings?: Resolver<Array<ResolversTypes['machine_learning_model_recording']>, ParentType, ContextType, RequireFields<Machine_Learning_ModelsModel_Source_RecordingsArgs, never>>;
  model_source_recordings_aggregate?: Resolver<ResolversTypes['machine_learning_model_recording_aggregate'], ParentType, ContextType, RequireFields<Machine_Learning_ModelsModel_Source_Recordings_AggregateArgs, never>>;
  model_type?: Resolver<Maybe<ResolversTypes['machine_learning_model_types']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type_id?: Resolver<Maybe<ResolversTypes['machine_learning_model_types_enum']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Machine_Learning_Models_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['machine_learning_models_mutation_response'] = ResolversParentTypes['machine_learning_models_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['machine_learning_models']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaResolvers<ContextType = any, ParentType extends ResolversParentTypes['media'] = ResolversParentTypes['media']> = {
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expectation_media?: Resolver<Array<ResolversTypes['integration_test_expectation_media']>, ParentType, ContextType, RequireFields<MediaExpectation_MediaArgs, never>>;
  expectation_media_aggregate?: Resolver<ResolversTypes['integration_test_expectation_media_aggregate'], ParentType, ContextType, RequireFields<MediaExpectation_Media_AggregateArgs, never>>;
  field_option_media?: Resolver<Array<ResolversTypes['field_option_media']>, ParentType, ContextType, RequireFields<MediaField_Option_MediaArgs, never>>;
  field_option_media_aggregate?: Resolver<ResolversTypes['field_option_media_aggregate'], ParentType, ContextType, RequireFields<MediaField_Option_Media_AggregateArgs, never>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  instruction_media?: Resolver<Array<ResolversTypes['integration_test_instruction_media']>, ParentType, ContextType, RequireFields<MediaInstruction_MediaArgs, never>>;
  instruction_media_aggregate?: Resolver<ResolversTypes['integration_test_instruction_media_aggregate'], ParentType, ContextType, RequireFields<MediaInstruction_Media_AggregateArgs, never>>;
  media_type?: Resolver<ResolversTypes['media_type'], ParentType, ContextType>;
  option_media?: Resolver<Array<ResolversTypes['option_media']>, ParentType, ContextType, RequireFields<MediaOption_MediaArgs, never>>;
  option_media_aggregate?: Resolver<ResolversTypes['option_media_aggregate'], ParentType, ContextType, RequireFields<MediaOption_Media_AggregateArgs, never>>;
  task_media?: Resolver<Array<ResolversTypes['task_media']>, ParentType, ContextType, RequireFields<MediaTask_MediaArgs, never>>;
  task_media_aggregate?: Resolver<ResolversTypes['task_media_aggregate'], ParentType, ContextType, RequireFields<MediaTask_Media_AggregateArgs, never>>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['media_type_enum'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Media_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['media_aggregate'] = ResolversParentTypes['media_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['media_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['media']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Media_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['media_aggregate_fields'] = ResolversParentTypes['media_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Media_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['media_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['media_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Media_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['media_max_fields'] = ResolversParentTypes['media_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Media_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['media_min_fields'] = ResolversParentTypes['media_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Media_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['media_mutation_response'] = ResolversParentTypes['media_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['media']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Media_TypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['media_type'] = ResolversParentTypes['media_type']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  media?: Resolver<Array<ResolversTypes['media']>, ParentType, ContextType, RequireFields<Media_TypeMediaArgs, never>>;
  media_aggregate?: Resolver<ResolversTypes['media_aggregate'], ParentType, ContextType, RequireFields<Media_TypeMedia_AggregateArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Mode_ProjectResolvers<ContextType = any, ParentType extends ResolversParentTypes['mode_project'] = ResolversParentTypes['mode_project']> = {
  mode?: Resolver<ResolversTypes['recording_data_modes'], ParentType, ContextType>;
  mode_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['projects'], ParentType, ContextType>;
  project_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Mode_Project_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['mode_project_aggregate'] = ResolversParentTypes['mode_project_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['mode_project_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['mode_project']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Mode_Project_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['mode_project_aggregate_fields'] = ResolversParentTypes['mode_project_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Mode_Project_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['mode_project_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['mode_project_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Mode_Project_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['mode_project_max_fields'] = ResolversParentTypes['mode_project_max_fields']> = {
  mode_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Mode_Project_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['mode_project_min_fields'] = ResolversParentTypes['mode_project_min_fields']> = {
  mode_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Mode_Project_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['mode_project_mutation_response'] = ResolversParentTypes['mode_project_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['mode_project']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Model_Name_HashesResolvers<ContextType = any, ParentType extends ResolversParentTypes['model_name_hashes'] = ResolversParentTypes['model_name_hashes']> = {
  hash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  model?: Resolver<ResolversTypes['models'], ParentType, ContextType>;
  model_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Model_Name_Hashes_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['model_name_hashes_aggregate'] = ResolversParentTypes['model_name_hashes_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['model_name_hashes_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['model_name_hashes']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Model_Name_Hashes_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['model_name_hashes_aggregate_fields'] = ResolversParentTypes['model_name_hashes_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Model_Name_Hashes_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['model_name_hashes_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['model_name_hashes_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Model_Name_Hashes_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['model_name_hashes_max_fields'] = ResolversParentTypes['model_name_hashes_max_fields']> = {
  hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  model_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Model_Name_Hashes_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['model_name_hashes_min_fields'] = ResolversParentTypes['model_name_hashes_min_fields']> = {
  hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  model_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Model_Name_Hashes_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['model_name_hashes_mutation_response'] = ResolversParentTypes['model_name_hashes_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['model_name_hashes']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ModelsResolvers<ContextType = any, ParentType extends ResolversParentTypes['models'] = ResolversParentTypes['models']> = {
  codename?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  devices?: Resolver<Array<ResolversTypes['devices']>, ParentType, ContextType, RequireFields<ModelsDevicesArgs, never>>;
  devices_aggregate?: Resolver<ResolversTypes['devices_aggregate'], ParentType, ContextType, RequireFields<ModelsDevices_AggregateArgs, never>>;
  hashes?: Resolver<Array<ResolversTypes['model_name_hashes']>, ParentType, ContextType, RequireFields<ModelsHashesArgs, never>>;
  hashes_aggregate?: Resolver<ResolversTypes['model_name_hashes_aggregate'], ParentType, ContextType, RequireFields<ModelsHashes_AggregateArgs, never>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  projects?: Resolver<Array<ResolversTypes['projects']>, ParentType, ContextType, RequireFields<ModelsProjectsArgs, never>>;
  projects_aggregate?: Resolver<ResolversTypes['projects_aggregate'], ParentType, ContextType, RequireFields<ModelsProjects_AggregateArgs, never>>;
  suspend_resume_at_transitions?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Models_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['models_aggregate'] = ResolversParentTypes['models_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['models_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['models']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Models_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['models_aggregate_fields'] = ResolversParentTypes['models_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Models_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['models_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['models_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Models_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['models_max_fields'] = ResolversParentTypes['models_max_fields']> = {
  codename?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Models_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['models_min_fields'] = ResolversParentTypes['models_min_fields']> = {
  codename?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Models_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['models_mutation_response'] = ResolversParentTypes['models_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['models']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Mutation_RootResolvers<ContextType = any, ParentType extends ResolversParentTypes['mutation_root'] = ResolversParentTypes['mutation_root']> = {
  delete_audio_driver?: Resolver<Maybe<ResolversTypes['audio_driver_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Audio_DriverArgs, 'where'>>;
  delete_audio_driver_by_pk?: Resolver<Maybe<ResolversTypes['audio_driver']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Audio_Driver_By_PkArgs, 'id'>>;
  delete_audio_measurements_components?: Resolver<Maybe<ResolversTypes['audio_measurements_components_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Audio_Measurements_ComponentsArgs, 'where'>>;
  delete_audio_measurements_components_by_pk?: Resolver<Maybe<ResolversTypes['audio_measurements_components']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Audio_Measurements_Components_By_PkArgs, 'id'>>;
  delete_audio_measurements_configurations?: Resolver<Maybe<ResolversTypes['audio_measurements_configurations_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Audio_Measurements_ConfigurationsArgs, 'where'>>;
  delete_audio_measurements_configurations_by_pk?: Resolver<Maybe<ResolversTypes['audio_measurements_configurations']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Audio_Measurements_Configurations_By_PkArgs, 'id'>>;
  delete_audio_measurements_master_files?: Resolver<Maybe<ResolversTypes['audio_measurements_master_files_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Audio_Measurements_Master_FilesArgs, 'where'>>;
  delete_audio_measurements_master_files_by_pk?: Resolver<Maybe<ResolversTypes['audio_measurements_master_files']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Audio_Measurements_Master_Files_By_PkArgs, 'id'>>;
  delete_audio_measurements_orientations?: Resolver<Maybe<ResolversTypes['audio_measurements_orientations_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Audio_Measurements_OrientationsArgs, 'where'>>;
  delete_audio_measurements_orientations_by_pk?: Resolver<Maybe<ResolversTypes['audio_measurements_orientations']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Audio_Measurements_Orientations_By_PkArgs, 'id'>>;
  delete_audio_measurements_payload_recording?: Resolver<Maybe<ResolversTypes['audio_measurements_payload_recording_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Audio_Measurements_Payload_RecordingArgs, 'where'>>;
  delete_audio_measurements_payload_recording_by_pk?: Resolver<Maybe<ResolversTypes['audio_measurements_payload_recording']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Audio_Measurements_Payload_Recording_By_PkArgs, 'payload_id' | 'recording_id'>>;
  delete_audio_measurements_placements?: Resolver<Maybe<ResolversTypes['audio_measurements_placements_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Audio_Measurements_PlacementsArgs, 'where'>>;
  delete_audio_measurements_placements_by_pk?: Resolver<Maybe<ResolversTypes['audio_measurements_placements']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Audio_Measurements_Placements_By_PkArgs, 'id'>>;
  delete_audio_measurements_project_tasks?: Resolver<Maybe<ResolversTypes['audio_measurements_project_tasks_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Audio_Measurements_Project_TasksArgs, 'where'>>;
  delete_audio_measurements_project_tasks_by_pk?: Resolver<Maybe<ResolversTypes['audio_measurements_project_tasks']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Audio_Measurements_Project_Tasks_By_PkArgs, 'project_id' | 'task_id'>>;
  delete_audio_measurements_stages?: Resolver<Maybe<ResolversTypes['audio_measurements_stages_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Audio_Measurements_StagesArgs, 'where'>>;
  delete_audio_measurements_stages_by_pk?: Resolver<Maybe<ResolversTypes['audio_measurements_stages']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Audio_Measurements_Stages_By_PkArgs, 'id'>>;
  delete_bringup_checklist_deliverable?: Resolver<Maybe<ResolversTypes['bringup_checklist_deliverable_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Bringup_Checklist_DeliverableArgs, 'where'>>;
  delete_bringup_checklist_deliverable_by_pk?: Resolver<Maybe<ResolversTypes['bringup_checklist_deliverable']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Bringup_Checklist_Deliverable_By_PkArgs, 'checklist_id' | 'deliverable_id'>>;
  delete_bringup_checklists?: Resolver<Maybe<ResolversTypes['bringup_checklists_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Bringup_ChecklistsArgs, 'where'>>;
  delete_bringup_checklists_by_pk?: Resolver<Maybe<ResolversTypes['bringup_checklists']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Bringup_Checklists_By_PkArgs, 'id'>>;
  delete_bringup_endorsements?: Resolver<Maybe<ResolversTypes['bringup_endorsements_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Bringup_EndorsementsArgs, 'where'>>;
  delete_bringup_endorsements_by_pk?: Resolver<Maybe<ResolversTypes['bringup_endorsements']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Bringup_Endorsements_By_PkArgs, 'item_id' | 'project_id'>>;
  delete_bringup_items?: Resolver<Maybe<ResolversTypes['bringup_items_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Bringup_ItemsArgs, 'where'>>;
  delete_bringup_items_by_pk?: Resolver<Maybe<ResolversTypes['bringup_items']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Bringup_Items_By_PkArgs, 'id'>>;
  delete_bringup_milestones?: Resolver<Maybe<ResolversTypes['bringup_milestones_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Bringup_MilestonesArgs, 'where'>>;
  delete_bringup_milestones_by_pk?: Resolver<Maybe<ResolversTypes['bringup_milestones']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Bringup_Milestones_By_PkArgs, 'item_id'>>;
  delete_bringup_project_item_comments?: Resolver<Maybe<ResolversTypes['bringup_project_item_comments_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Bringup_Project_Item_CommentsArgs, 'where'>>;
  delete_bringup_project_item_comments_by_pk?: Resolver<Maybe<ResolversTypes['bringup_project_item_comments']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Bringup_Project_Item_Comments_By_PkArgs, 'created_at' | 'item_id' | 'project_id'>>;
  delete_categories?: Resolver<Maybe<ResolversTypes['categories_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_CategoriesArgs, 'where'>>;
  delete_categories_by_pk?: Resolver<Maybe<ResolversTypes['categories']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Categories_By_PkArgs, 'id'>>;
  delete_context_display_field?: Resolver<Maybe<ResolversTypes['context_display_field_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Context_Display_FieldArgs, 'where'>>;
  delete_context_display_field_by_pk?: Resolver<Maybe<ResolversTypes['context_display_field']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Context_Display_Field_By_PkArgs, 'context_display_id' | 'field_id'>>;
  delete_context_displays?: Resolver<Maybe<ResolversTypes['context_displays_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Context_DisplaysArgs, 'where'>>;
  delete_context_displays_by_pk?: Resolver<Maybe<ResolversTypes['context_displays']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Context_Displays_By_PkArgs, 'id'>>;
  delete_contexts?: Resolver<Maybe<ResolversTypes['contexts_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ContextsArgs, 'where'>>;
  delete_contexts_by_pk?: Resolver<Maybe<ResolversTypes['contexts']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Contexts_By_PkArgs, 'id'>>;
  delete_customers?: Resolver<Maybe<ResolversTypes['customers_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_CustomersArgs, 'where'>>;
  delete_customers_by_pk?: Resolver<Maybe<ResolversTypes['customers']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Customers_By_PkArgs, 'id'>>;
  delete_deliverables?: Resolver<Maybe<ResolversTypes['deliverables_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_DeliverablesArgs, 'where'>>;
  delete_deliverables_by_pk?: Resolver<Maybe<ResolversTypes['deliverables']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliverables_By_PkArgs, 'id'>>;
  delete_deliveries_architectures?: Resolver<Maybe<ResolversTypes['deliveries_architectures_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_ArchitecturesArgs, 'where'>>;
  delete_deliveries_architectures_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_architectures']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_Architectures_By_PkArgs, 'id'>>;
  delete_deliveries_artifact_microsoft_whql_submission?: Resolver<Maybe<ResolversTypes['deliveries_artifact_microsoft_whql_submission_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_Artifact_Microsoft_Whql_SubmissionArgs, 'where'>>;
  delete_deliveries_artifact_microsoft_whql_submission_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_artifact_microsoft_whql_submission']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_Artifact_Microsoft_Whql_Submission_By_PkArgs, 'artifact_id' | 'microsoft_whql_submission_id'>>;
  delete_deliveries_artifact_operating_system?: Resolver<Maybe<ResolversTypes['deliveries_artifact_operating_system_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_Artifact_Operating_SystemArgs, 'where'>>;
  delete_deliveries_artifact_operating_system_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_artifact_operating_system']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_Artifact_Operating_System_By_PkArgs, 'artifact_id' | 'os_id'>>;
  delete_deliveries_driver_package?: Resolver<Maybe<ResolversTypes['deliveries_driver_package_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_Driver_PackageArgs, 'where'>>;
  delete_deliveries_driver_package_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_driver_package']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_Driver_Package_By_PkArgs, 'driver_id' | 'package_id'>>;
  delete_deliveries_driver_project?: Resolver<Maybe<ResolversTypes['deliveries_driver_project_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_Driver_ProjectArgs, 'where'>>;
  delete_deliveries_driver_project_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_driver_project']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_Driver_Project_By_PkArgs, 'driver_id'>>;
  delete_deliveries_driver_version_engine?: Resolver<Maybe<ResolversTypes['deliveries_driver_version_engine_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_Driver_Version_EngineArgs, 'where'>>;
  delete_deliveries_driver_version_engine_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_driver_version_engine']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_Driver_Version_Engine_By_PkArgs, 'driver_id' | 'driver_version_id'>>;
  delete_deliveries_driver_version_package?: Resolver<Maybe<ResolversTypes['deliveries_driver_version_package_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_Driver_Version_PackageArgs, 'where'>>;
  delete_deliveries_driver_version_package_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_driver_version_package']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_Driver_Version_Package_By_PkArgs, 'driver_version_id' | 'package_id'>>;
  delete_deliveries_driver_versions?: Resolver<Maybe<ResolversTypes['deliveries_driver_versions_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_Driver_VersionsArgs, 'where'>>;
  delete_deliveries_driver_versions_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_driver_versions']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_Driver_Versions_By_PkArgs, 'id'>>;
  delete_deliveries_drivers?: Resolver<Maybe<ResolversTypes['deliveries_drivers_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_DriversArgs, 'where'>>;
  delete_deliveries_drivers_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_drivers']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_Drivers_By_PkArgs, 'id'>>;
  delete_deliveries_microsoft_shipping_labels?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_shipping_labels_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_Microsoft_Shipping_LabelsArgs, 'where'>>;
  delete_deliveries_microsoft_shipping_labels_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_shipping_labels']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_Microsoft_Shipping_Labels_By_PkArgs, 'id'>>;
  delete_deliveries_microsoft_whql_submissions?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_whql_submissions_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_Microsoft_Whql_SubmissionsArgs, 'where'>>;
  delete_deliveries_microsoft_whql_submissions_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_whql_submissions']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_Microsoft_Whql_Submissions_By_PkArgs, 'id'>>;
  delete_deliveries_package_target?: Resolver<Maybe<ResolversTypes['deliveries_package_target_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_Package_TargetArgs, 'where'>>;
  delete_deliveries_package_target_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_package_target']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_Package_Target_By_PkArgs, 'package_id' | 'target_id'>>;
  delete_deliveries_packages?: Resolver<Maybe<ResolversTypes['deliveries_packages_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_PackagesArgs, 'where'>>;
  delete_deliveries_packages_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_packages']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_Packages_By_PkArgs, 'id'>>;
  delete_deliveries_partners?: Resolver<Maybe<ResolversTypes['deliveries_partners_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_PartnersArgs, 'where'>>;
  delete_deliveries_partners_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_partners']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_Partners_By_PkArgs, 'id'>>;
  delete_deliveries_platforms?: Resolver<Maybe<ResolversTypes['deliveries_platforms_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_PlatformsArgs, 'where'>>;
  delete_deliveries_platforms_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_platforms']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_Platforms_By_PkArgs, 'id'>>;
  delete_deliveries_target_partner?: Resolver<Maybe<ResolversTypes['deliveries_target_partner_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_Target_PartnerArgs, 'where'>>;
  delete_deliveries_target_partner_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_target_partner']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_Target_Partner_By_PkArgs, 'partner_id' | 'target_id'>>;
  delete_deliveries_targets?: Resolver<Maybe<ResolversTypes['deliveries_targets_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_TargetsArgs, 'where'>>;
  delete_deliveries_targets_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_targets']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Deliveries_Targets_By_PkArgs, 'id'>>;
  delete_device_firmware?: Resolver<Maybe<ResolversTypes['device_firmware_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Device_FirmwareArgs, 'where'>>;
  delete_device_firmware_by_pk?: Resolver<Maybe<ResolversTypes['device_firmware']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Device_Firmware_By_PkArgs, 'device_id' | 'firmware_id'>>;
  delete_device_project?: Resolver<Maybe<ResolversTypes['device_project_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Device_ProjectArgs, 'where'>>;
  delete_device_project_by_pk?: Resolver<Maybe<ResolversTypes['device_project']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Device_Project_By_PkArgs, 'device_id' | 'project_id'>>;
  delete_devices?: Resolver<Maybe<ResolversTypes['devices_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_DevicesArgs, 'where'>>;
  delete_devices_by_pk?: Resolver<Maybe<ResolversTypes['devices']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Devices_By_PkArgs, 'id'>>;
  delete_dynamic_scaling?: Resolver<Maybe<ResolversTypes['dynamic_scaling_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Dynamic_ScalingArgs, 'where'>>;
  delete_dynamic_scaling_by_pk?: Resolver<Maybe<ResolversTypes['dynamic_scaling']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Dynamic_Scaling_By_PkArgs, 'id'>>;
  delete_engine_opmode?: Resolver<Maybe<ResolversTypes['engine_opmode_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Engine_OpmodeArgs, 'where'>>;
  delete_engine_opmode_by_pk?: Resolver<Maybe<ResolversTypes['engine_opmode']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Engine_Opmode_By_PkArgs, 'engine_id' | 'opmode_id'>>;
  delete_engine_opmode_recording?: Resolver<Maybe<ResolversTypes['engine_opmode_recording_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Engine_Opmode_RecordingArgs, 'where'>>;
  delete_engine_opmode_recording_by_pk?: Resolver<Maybe<ResolversTypes['engine_opmode_recording']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Engine_Opmode_Recording_By_PkArgs, 'engine_id' | 'opmode_id' | 'recording_id'>>;
  delete_engines?: Resolver<Maybe<ResolversTypes['engines_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_EnginesArgs, 'where'>>;
  delete_engines_by_pk?: Resolver<Maybe<ResolversTypes['engines']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Engines_By_PkArgs, 'id'>>;
  delete_field_option_media?: Resolver<Maybe<ResolversTypes['field_option_media_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Field_Option_MediaArgs, 'where'>>;
  delete_field_option_media_by_pk?: Resolver<Maybe<ResolversTypes['field_option_media']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Field_Option_Media_By_PkArgs, 'field_option_id' | 'media_id'>>;
  delete_field_options?: Resolver<Maybe<ResolversTypes['field_options_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Field_OptionsArgs, 'where'>>;
  delete_field_options_by_pk?: Resolver<Maybe<ResolversTypes['field_options']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Field_Options_By_PkArgs, 'id'>>;
  delete_field_translation?: Resolver<Maybe<ResolversTypes['field_translation_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Field_TranslationArgs, 'where'>>;
  delete_field_translation_by_pk?: Resolver<Maybe<ResolversTypes['field_translation']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Field_Translation_By_PkArgs, 'field_id' | 'language'>>;
  delete_fields?: Resolver<Maybe<ResolversTypes['fields_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_FieldsArgs, 'where'>>;
  delete_fields_by_pk?: Resolver<Maybe<ResolversTypes['fields']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Fields_By_PkArgs, 'id'>>;
  delete_firmware?: Resolver<Maybe<ResolversTypes['firmware_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_FirmwareArgs, 'where'>>;
  delete_firmware_by_pk?: Resolver<Maybe<ResolversTypes['firmware']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Firmware_By_PkArgs, 'id'>>;
  delete_ground_truth_configurations?: Resolver<Maybe<ResolversTypes['ground_truth_configurations_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Ground_Truth_ConfigurationsArgs, 'where'>>;
  delete_ground_truth_configurations_by_pk?: Resolver<Maybe<ResolversTypes['ground_truth_configurations']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Ground_Truth_Configurations_By_PkArgs, 'id'>>;
  delete_ground_truth_recordings?: Resolver<Maybe<ResolversTypes['ground_truth_recordings_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Ground_Truth_RecordingsArgs, 'where'>>;
  delete_ground_truth_recordings_by_pk?: Resolver<Maybe<ResolversTypes['ground_truth_recordings']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Ground_Truth_Recordings_By_PkArgs, 'recording_id'>>;
  delete_hypertask_field_option?: Resolver<Maybe<ResolversTypes['hypertask_field_option_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Hypertask_Field_OptionArgs, 'where'>>;
  delete_hypertask_field_option_by_pk?: Resolver<Maybe<ResolversTypes['hypertask_field_option']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Hypertask_Field_Option_By_PkArgs, 'field_id' | 'hypertask_id' | 'option_id'>>;
  delete_hypertask_task?: Resolver<Maybe<ResolversTypes['hypertask_task_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Hypertask_TaskArgs, 'where'>>;
  delete_hypertask_task_by_pk?: Resolver<Maybe<ResolversTypes['hypertask_task']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Hypertask_Task_By_PkArgs, 'hypertask_id' | 'task_id'>>;
  delete_hypertasks?: Resolver<Maybe<ResolversTypes['hypertasks_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_HypertasksArgs, 'where'>>;
  delete_hypertasks_by_pk?: Resolver<Maybe<ResolversTypes['hypertasks']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Hypertasks_By_PkArgs, 'id'>>;
  delete_instruction_translations?: Resolver<Maybe<ResolversTypes['instruction_translations_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Instruction_TranslationsArgs, 'where'>>;
  delete_instruction_translations_by_pk?: Resolver<Maybe<ResolversTypes['instruction_translations']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Instruction_Translations_By_PkArgs, 'id'>>;
  delete_integration_test_expectation_media?: Resolver<Maybe<ResolversTypes['integration_test_expectation_media_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Integration_Test_Expectation_MediaArgs, 'where'>>;
  delete_integration_test_expectation_media_by_pk?: Resolver<Maybe<ResolversTypes['integration_test_expectation_media']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Integration_Test_Expectation_Media_By_PkArgs, 'expectation_id' | 'media_id'>>;
  delete_integration_test_expectation_step?: Resolver<Maybe<ResolversTypes['integration_test_expectation_step_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Integration_Test_Expectation_StepArgs, 'where'>>;
  delete_integration_test_expectation_step_by_pk?: Resolver<Maybe<ResolversTypes['integration_test_expectation_step']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Integration_Test_Expectation_Step_By_PkArgs, 'expectation_id' | 'step_id'>>;
  delete_integration_test_expectation_translation?: Resolver<Maybe<ResolversTypes['integration_test_expectation_translation_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Integration_Test_Expectation_TranslationArgs, 'where'>>;
  delete_integration_test_expectation_translation_by_pk?: Resolver<Maybe<ResolversTypes['integration_test_expectation_translation']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Integration_Test_Expectation_Translation_By_PkArgs, 'expectation_id' | 'language'>>;
  delete_integration_test_expectations?: Resolver<Maybe<ResolversTypes['integration_test_expectations_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Integration_Test_ExpectationsArgs, 'where'>>;
  delete_integration_test_expectations_by_pk?: Resolver<Maybe<ResolversTypes['integration_test_expectations']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Integration_Test_Expectations_By_PkArgs, 'id'>>;
  delete_integration_test_instruction_media?: Resolver<Maybe<ResolversTypes['integration_test_instruction_media_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Integration_Test_Instruction_MediaArgs, 'where'>>;
  delete_integration_test_instruction_media_by_pk?: Resolver<Maybe<ResolversTypes['integration_test_instruction_media']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Integration_Test_Instruction_Media_By_PkArgs, 'instruction_id' | 'media_id'>>;
  delete_integration_test_instruction_translation?: Resolver<Maybe<ResolversTypes['integration_test_instruction_translation_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Integration_Test_Instruction_TranslationArgs, 'where'>>;
  delete_integration_test_instruction_translation_by_pk?: Resolver<Maybe<ResolversTypes['integration_test_instruction_translation']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Integration_Test_Instruction_Translation_By_PkArgs, 'instruction_id' | 'language'>>;
  delete_integration_test_instructions?: Resolver<Maybe<ResolversTypes['integration_test_instructions_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Integration_Test_InstructionsArgs, 'where'>>;
  delete_integration_test_instructions_by_pk?: Resolver<Maybe<ResolversTypes['integration_test_instructions']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Integration_Test_Instructions_By_PkArgs, 'id'>>;
  delete_integration_test_observances?: Resolver<Maybe<ResolversTypes['integration_test_observances_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Integration_Test_ObservancesArgs, 'where'>>;
  delete_integration_test_observances_by_pk?: Resolver<Maybe<ResolversTypes['integration_test_observances']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Integration_Test_Observances_By_PkArgs, 'id'>>;
  delete_integration_test_project_sequence?: Resolver<Maybe<ResolversTypes['integration_test_project_sequence_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Integration_Test_Project_SequenceArgs, 'where'>>;
  delete_integration_test_project_sequence_by_pk?: Resolver<Maybe<ResolversTypes['integration_test_project_sequence']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Integration_Test_Project_Sequence_By_PkArgs, 'project_id' | 'sequence_id'>>;
  delete_integration_test_sequences?: Resolver<Maybe<ResolversTypes['integration_test_sequences_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Integration_Test_SequencesArgs, 'where'>>;
  delete_integration_test_sequences_by_pk?: Resolver<Maybe<ResolversTypes['integration_test_sequences']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Integration_Test_Sequences_By_PkArgs, 'id'>>;
  delete_integration_test_steps?: Resolver<Maybe<ResolversTypes['integration_test_steps_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Integration_Test_StepsArgs, 'where'>>;
  delete_integration_test_steps_by_pk?: Resolver<Maybe<ResolversTypes['integration_test_steps']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Integration_Test_Steps_By_PkArgs, 'id'>>;
  delete_machine_learning_blobs?: Resolver<Maybe<ResolversTypes['machine_learning_blobs_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Machine_Learning_BlobsArgs, 'where'>>;
  delete_machine_learning_blobs_by_pk?: Resolver<Maybe<ResolversTypes['machine_learning_blobs']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Machine_Learning_Blobs_By_PkArgs, 'id'>>;
  delete_machine_learning_dataset_feature?: Resolver<Maybe<ResolversTypes['machine_learning_dataset_feature_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Machine_Learning_Dataset_FeatureArgs, 'where'>>;
  delete_machine_learning_dataset_feature_by_pk?: Resolver<Maybe<ResolversTypes['machine_learning_dataset_feature']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Machine_Learning_Dataset_Feature_By_PkArgs, 'dataset_id' | 'feature_id'>>;
  delete_machine_learning_datasets?: Resolver<Maybe<ResolversTypes['machine_learning_datasets_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Machine_Learning_DatasetsArgs, 'where'>>;
  delete_machine_learning_datasets_by_pk?: Resolver<Maybe<ResolversTypes['machine_learning_datasets']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Machine_Learning_Datasets_By_PkArgs, 'id'>>;
  delete_machine_learning_features?: Resolver<Maybe<ResolversTypes['machine_learning_features_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Machine_Learning_FeaturesArgs, 'where'>>;
  delete_machine_learning_features_by_pk?: Resolver<Maybe<ResolversTypes['machine_learning_features']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Machine_Learning_Features_By_PkArgs, 'id'>>;
  delete_machine_learning_model_blob?: Resolver<Maybe<ResolversTypes['machine_learning_model_blob_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Machine_Learning_Model_BlobArgs, 'where'>>;
  delete_machine_learning_model_blob_by_pk?: Resolver<Maybe<ResolversTypes['machine_learning_model_blob']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Machine_Learning_Model_Blob_By_PkArgs, 'blob_id' | 'model_id'>>;
  delete_machine_learning_model_dataset?: Resolver<Maybe<ResolversTypes['machine_learning_model_dataset_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Machine_Learning_Model_DatasetArgs, 'where'>>;
  delete_machine_learning_model_dataset_by_pk?: Resolver<Maybe<ResolversTypes['machine_learning_model_dataset']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Machine_Learning_Model_Dataset_By_PkArgs, 'dataset_id' | 'model_id'>>;
  delete_machine_learning_model_recording?: Resolver<Maybe<ResolversTypes['machine_learning_model_recording_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Machine_Learning_Model_RecordingArgs, 'where'>>;
  delete_machine_learning_model_recording_by_pk?: Resolver<Maybe<ResolversTypes['machine_learning_model_recording']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Machine_Learning_Model_Recording_By_PkArgs, 'model_id' | 'recording_id'>>;
  delete_machine_learning_models?: Resolver<Maybe<ResolversTypes['machine_learning_models_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Machine_Learning_ModelsArgs, 'where'>>;
  delete_machine_learning_models_by_pk?: Resolver<Maybe<ResolversTypes['machine_learning_models']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Machine_Learning_Models_By_PkArgs, 'id'>>;
  delete_media?: Resolver<Maybe<ResolversTypes['media_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_MediaArgs, 'where'>>;
  delete_media_by_pk?: Resolver<Maybe<ResolversTypes['media']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Media_By_PkArgs, 'id'>>;
  delete_mode_project?: Resolver<Maybe<ResolversTypes['mode_project_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Mode_ProjectArgs, 'where'>>;
  delete_mode_project_by_pk?: Resolver<Maybe<ResolversTypes['mode_project']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Mode_Project_By_PkArgs, 'mode_id' | 'project_id'>>;
  delete_model_name_hashes?: Resolver<Maybe<ResolversTypes['model_name_hashes_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Model_Name_HashesArgs, 'where'>>;
  delete_model_name_hashes_by_pk?: Resolver<Maybe<ResolversTypes['model_name_hashes']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Model_Name_Hashes_By_PkArgs, 'hash' | 'model_id'>>;
  delete_models?: Resolver<Maybe<ResolversTypes['models_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ModelsArgs, 'where'>>;
  delete_models_by_pk?: Resolver<Maybe<ResolversTypes['models']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Models_By_PkArgs, 'id'>>;
  delete_opmode_profile?: Resolver<Maybe<ResolversTypes['opmode_profile_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Opmode_ProfileArgs, 'where'>>;
  delete_opmode_profile_by_pk?: Resolver<Maybe<ResolversTypes['opmode_profile']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Opmode_Profile_By_PkArgs, 'opmode_id' | 'profile_id'>>;
  delete_opmode_project?: Resolver<Maybe<ResolversTypes['opmode_project_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Opmode_ProjectArgs, 'where'>>;
  delete_opmode_project_by_pk?: Resolver<Maybe<ResolversTypes['opmode_project']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Opmode_Project_By_PkArgs, 'opmode_id' | 'project_id'>>;
  delete_opmodes?: Resolver<Maybe<ResolversTypes['opmodes_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_OpmodesArgs, 'where'>>;
  delete_opmodes_by_pk?: Resolver<Maybe<ResolversTypes['opmodes']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Opmodes_By_PkArgs, 'id'>>;
  delete_option_media?: Resolver<Maybe<ResolversTypes['option_media_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Option_MediaArgs, 'where'>>;
  delete_option_media_by_pk?: Resolver<Maybe<ResolversTypes['option_media']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Option_Media_By_PkArgs, 'media_id' | 'option_id'>>;
  delete_option_translation?: Resolver<Maybe<ResolversTypes['option_translation_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Option_TranslationArgs, 'where'>>;
  delete_option_translation_by_pk?: Resolver<Maybe<ResolversTypes['option_translation']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Option_Translation_By_PkArgs, 'language' | 'option_id'>>;
  delete_options?: Resolver<Maybe<ResolversTypes['options_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_OptionsArgs, 'where'>>;
  delete_options_by_pk?: Resolver<Maybe<ResolversTypes['options']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Options_By_PkArgs, 'id'>>;
  delete_payload_recording?: Resolver<Maybe<ResolversTypes['payload_recording_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Payload_RecordingArgs, 'where'>>;
  delete_payload_recording_by_pk?: Resolver<Maybe<ResolversTypes['payload_recording']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Payload_Recording_By_PkArgs, 'payload_id' | 'recording_id'>>;
  delete_payloads?: Resolver<Maybe<ResolversTypes['payloads_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_PayloadsArgs, 'where'>>;
  delete_payloads_by_pk?: Resolver<Maybe<ResolversTypes['payloads']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Payloads_By_PkArgs, 'id'>>;
  delete_performance_ratio_metric_contributions?: Resolver<Maybe<ResolversTypes['performance_ratio_metric_contributions_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Performance_Ratio_Metric_ContributionsArgs, 'where'>>;
  delete_performance_ratio_metric_contributions_by_pk?: Resolver<Maybe<ResolversTypes['performance_ratio_metric_contributions']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Performance_Ratio_Metric_Contributions_By_PkArgs, 'engine_id' | 'ratio_metric_id' | 'recording_id'>>;
  delete_performance_report_deliverable_template?: Resolver<Maybe<ResolversTypes['performance_report_deliverable_template_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Performance_Report_Deliverable_TemplateArgs, 'where'>>;
  delete_performance_report_deliverable_template_by_pk?: Resolver<Maybe<ResolversTypes['performance_report_deliverable_template']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Performance_Report_Deliverable_Template_By_PkArgs, 'deliverable_id' | 'template_id'>>;
  delete_performance_report_section_pivot_field?: Resolver<Maybe<ResolversTypes['performance_report_section_pivot_field_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Performance_Report_Section_Pivot_FieldArgs, 'where'>>;
  delete_performance_report_section_pivot_field_by_pk?: Resolver<Maybe<ResolversTypes['performance_report_section_pivot_field']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Performance_Report_Section_Pivot_Field_By_PkArgs, 'field_id' | 'section_id'>>;
  delete_performance_report_section_ratio_metric?: Resolver<Maybe<ResolversTypes['performance_report_section_ratio_metric_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Performance_Report_Section_Ratio_MetricArgs, 'where'>>;
  delete_performance_report_section_ratio_metric_by_pk?: Resolver<Maybe<ResolversTypes['performance_report_section_ratio_metric']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Performance_Report_Section_Ratio_Metric_By_PkArgs, 'ratio_metric_id' | 'section_id'>>;
  delete_performance_report_section_task_blacklist?: Resolver<Maybe<ResolversTypes['performance_report_section_task_blacklist_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Performance_Report_Section_Task_BlacklistArgs, 'where'>>;
  delete_performance_report_section_task_blacklist_by_pk?: Resolver<Maybe<ResolversTypes['performance_report_section_task_blacklist']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Performance_Report_Section_Task_Blacklist_By_PkArgs, 'section_id' | 'task_id'>>;
  delete_performance_report_sections?: Resolver<Maybe<ResolversTypes['performance_report_sections_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Performance_Report_SectionsArgs, 'where'>>;
  delete_performance_report_sections_by_pk?: Resolver<Maybe<ResolversTypes['performance_report_sections']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Performance_Report_Sections_By_PkArgs, 'id'>>;
  delete_performance_report_templates?: Resolver<Maybe<ResolversTypes['performance_report_templates_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Performance_Report_TemplatesArgs, 'where'>>;
  delete_performance_report_templates_by_pk?: Resolver<Maybe<ResolversTypes['performance_report_templates']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Performance_Report_Templates_By_PkArgs, 'id'>>;
  delete_peripherals?: Resolver<Maybe<ResolversTypes['peripherals_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_PeripheralsArgs, 'where'>>;
  delete_peripherals_by_pk?: Resolver<Maybe<ResolversTypes['peripherals']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Peripherals_By_PkArgs, 'id'>>;
  delete_plan_hypertasks?: Resolver<Maybe<ResolversTypes['plan_hypertasks_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Plan_HypertasksArgs, 'where'>>;
  delete_plan_hypertasks_by_pk?: Resolver<Maybe<ResolversTypes['plan_hypertasks']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Plan_Hypertasks_By_PkArgs, 'hypertask_id' | 'plan_id'>>;
  delete_plan_tasks?: Resolver<Maybe<ResolversTypes['plan_tasks_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Plan_TasksArgs, 'where'>>;
  delete_plan_tasks_by_pk?: Resolver<Maybe<ResolversTypes['plan_tasks']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Plan_Tasks_By_PkArgs, 'id'>>;
  delete_plans?: Resolver<Maybe<ResolversTypes['plans_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_PlansArgs, 'where'>>;
  delete_plans_by_pk?: Resolver<Maybe<ResolversTypes['plans']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Plans_By_PkArgs, 'id'>>;
  delete_profiles?: Resolver<Maybe<ResolversTypes['profiles_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ProfilesArgs, 'where'>>;
  delete_profiles_by_pk?: Resolver<Maybe<ResolversTypes['profiles']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Profiles_By_PkArgs, 'id'>>;
  delete_project_combo?: Resolver<Maybe<ResolversTypes['project_combo_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Project_ComboArgs, 'where'>>;
  delete_project_combo_by_pk?: Resolver<Maybe<ResolversTypes['project_combo']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Project_Combo_By_PkArgs, 'other_project_id' | 'project_id'>>;
  delete_project_hypertasks?: Resolver<Maybe<ResolversTypes['project_hypertasks_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Project_HypertasksArgs, 'where'>>;
  delete_project_hypertasks_by_pk?: Resolver<Maybe<ResolversTypes['project_hypertasks']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Project_Hypertasks_By_PkArgs, 'hypertask_id' | 'project_id'>>;
  delete_project_tasks?: Resolver<Maybe<ResolversTypes['project_tasks_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Project_TasksArgs, 'where'>>;
  delete_project_tasks_by_pk?: Resolver<Maybe<ResolversTypes['project_tasks']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Project_Tasks_By_PkArgs, 'id'>>;
  delete_project_user?: Resolver<Maybe<ResolversTypes['project_user_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Project_UserArgs, 'where'>>;
  delete_project_user_by_pk?: Resolver<Maybe<ResolversTypes['project_user']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Project_User_By_PkArgs, 'project_id' | 'user_id'>>;
  delete_projects?: Resolver<Maybe<ResolversTypes['projects_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ProjectsArgs, 'where'>>;
  delete_projects_by_pk?: Resolver<Maybe<ResolversTypes['projects']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Projects_By_PkArgs, 'id'>>;
  delete_rabbit_test_plan_project?: Resolver<Maybe<ResolversTypes['rabbit_test_plan_project_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Rabbit_Test_Plan_ProjectArgs, 'where'>>;
  delete_rabbit_test_plan_project_by_pk?: Resolver<Maybe<ResolversTypes['rabbit_test_plan_project']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Rabbit_Test_Plan_Project_By_PkArgs, 'plan_id' | 'project_id'>>;
  delete_rabbit_test_plan_recording?: Resolver<Maybe<ResolversTypes['rabbit_test_plan_recording_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Rabbit_Test_Plan_RecordingArgs, 'where'>>;
  delete_rabbit_test_plan_recording_by_pk?: Resolver<Maybe<ResolversTypes['rabbit_test_plan_recording']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Rabbit_Test_Plan_Recording_By_PkArgs, 'recording_id'>>;
  delete_rabbit_test_plan_task?: Resolver<Maybe<ResolversTypes['rabbit_test_plan_task_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Rabbit_Test_Plan_TaskArgs, 'where'>>;
  delete_rabbit_test_plan_task_by_pk?: Resolver<Maybe<ResolversTypes['rabbit_test_plan_task']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Rabbit_Test_Plan_Task_By_PkArgs, 'plan_id' | 'task_id'>>;
  delete_rabbit_test_plans?: Resolver<Maybe<ResolversTypes['rabbit_test_plans_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Rabbit_Test_PlansArgs, 'where'>>;
  delete_rabbit_test_plans_by_pk?: Resolver<Maybe<ResolversTypes['rabbit_test_plans']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Rabbit_Test_Plans_By_PkArgs, 'id'>>;
  delete_recording_apps?: Resolver<Maybe<ResolversTypes['recording_apps_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Recording_AppsArgs, 'where'>>;
  delete_recording_apps_by_pk?: Resolver<Maybe<ResolversTypes['recording_apps']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Recording_Apps_By_PkArgs, 'id'>>;
  delete_recording_audio_driver?: Resolver<Maybe<ResolversTypes['recording_audio_driver_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Recording_Audio_DriverArgs, 'where'>>;
  delete_recording_audio_driver_by_pk?: Resolver<Maybe<ResolversTypes['recording_audio_driver']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Recording_Audio_Driver_By_PkArgs, 'audio_driver_id' | 'recording_id'>>;
  delete_recording_data_ground_truth?: Resolver<Maybe<ResolversTypes['recording_data_ground_truth_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Recording_Data_Ground_TruthArgs, 'where'>>;
  delete_recording_data_ground_truth_by_pk?: Resolver<Maybe<ResolversTypes['recording_data_ground_truth']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Recording_Data_Ground_Truth_By_PkArgs, 'recording_id'>>;
  delete_recording_data_labels?: Resolver<Maybe<ResolversTypes['recording_data_labels_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Recording_Data_LabelsArgs, 'where'>>;
  delete_recording_data_labels_by_pk?: Resolver<Maybe<ResolversTypes['recording_data_labels']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Recording_Data_Labels_By_PkArgs, 'mode_id' | 'recording_id'>>;
  delete_recording_data_mode_parameter?: Resolver<Maybe<ResolversTypes['recording_data_mode_parameter_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Recording_Data_Mode_ParameterArgs, 'where'>>;
  delete_recording_data_mode_parameter_by_pk?: Resolver<Maybe<ResolversTypes['recording_data_mode_parameter']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Recording_Data_Mode_Parameter_By_PkArgs, 'mode_id' | 'parameter_id'>>;
  delete_recording_data_modes?: Resolver<Maybe<ResolversTypes['recording_data_modes_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Recording_Data_ModesArgs, 'where'>>;
  delete_recording_data_modes_by_pk?: Resolver<Maybe<ResolversTypes['recording_data_modes']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Recording_Data_Modes_By_PkArgs, 'id'>>;
  delete_recording_data_parameters?: Resolver<Maybe<ResolversTypes['recording_data_parameters_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Recording_Data_ParametersArgs, 'where'>>;
  delete_recording_data_parameters_by_pk?: Resolver<Maybe<ResolversTypes['recording_data_parameters']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Recording_Data_Parameters_By_PkArgs, 'id'>>;
  delete_recording_data_payload_pair?: Resolver<Maybe<ResolversTypes['recording_data_payload_pair_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Recording_Data_Payload_PairArgs, 'where'>>;
  delete_recording_data_payload_pair_by_pk?: Resolver<Maybe<ResolversTypes['recording_data_payload_pair']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Recording_Data_Payload_Pair_By_PkArgs, 'payload_id'>>;
  delete_recording_data_predictions?: Resolver<Maybe<ResolversTypes['recording_data_predictions_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Recording_Data_PredictionsArgs, 'where'>>;
  delete_recording_data_predictions_by_pk?: Resolver<Maybe<ResolversTypes['recording_data_predictions']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Recording_Data_Predictions_By_PkArgs, 'engine_id' | 'recording_id'>>;
  delete_recording_recording_tag?: Resolver<Maybe<ResolversTypes['recording_recording_tag_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Recording_Recording_TagArgs, 'where'>>;
  delete_recording_recording_tag_by_pk?: Resolver<Maybe<ResolversTypes['recording_recording_tag']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Recording_Recording_Tag_By_PkArgs, 'recording_id' | 'recording_tag_id'>>;
  delete_recording_tags?: Resolver<Maybe<ResolversTypes['recording_tags_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Recording_TagsArgs, 'where'>>;
  delete_recording_tags_by_pk?: Resolver<Maybe<ResolversTypes['recording_tags']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Recording_Tags_By_PkArgs, 'id'>>;
  delete_recordings?: Resolver<Maybe<ResolversTypes['recordings_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_RecordingsArgs, 'where'>>;
  delete_recordings_by_pk?: Resolver<Maybe<ResolversTypes['recordings']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Recordings_By_PkArgs, 'id'>>;
  delete_role_user?: Resolver<Maybe<ResolversTypes['role_user_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Role_UserArgs, 'where'>>;
  delete_role_user_by_pk?: Resolver<Maybe<ResolversTypes['role_user']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Role_User_By_PkArgs, 'role_id' | 'user_id'>>;
  delete_rooms?: Resolver<Maybe<ResolversTypes['rooms_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_RoomsArgs, 'where'>>;
  delete_rooms_by_pk?: Resolver<Maybe<ResolversTypes['rooms']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Rooms_By_PkArgs, 'id'>>;
  delete_simulations_scores?: Resolver<Maybe<ResolversTypes['simulations_scores_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Simulations_ScoresArgs, 'where'>>;
  delete_simulations_scores_by_pk?: Resolver<Maybe<ResolversTypes['simulations_scores']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Simulations_Scores_By_PkArgs, 'id'>>;
  delete_task_field_option?: Resolver<Maybe<ResolversTypes['task_field_option_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Task_Field_OptionArgs, 'where'>>;
  delete_task_field_option_by_pk?: Resolver<Maybe<ResolversTypes['task_field_option']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Task_Field_Option_By_PkArgs, 'field_option_id' | 'task_id'>>;
  delete_task_groups_group_fields?: Resolver<Maybe<ResolversTypes['task_groups_group_fields_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Task_Groups_Group_FieldsArgs, 'where'>>;
  delete_task_groups_group_fields_by_pk?: Resolver<Maybe<ResolversTypes['task_groups_group_fields']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Task_Groups_Group_Fields_By_PkArgs, 'field_id' | 'group_id'>>;
  delete_task_groups_groups?: Resolver<Maybe<ResolversTypes['task_groups_groups_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Task_Groups_GroupsArgs, 'where'>>;
  delete_task_groups_groups_by_pk?: Resolver<Maybe<ResolversTypes['task_groups_groups']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Task_Groups_Groups_By_PkArgs, 'id'>>;
  delete_task_groups_hierarchies?: Resolver<Maybe<ResolversTypes['task_groups_hierarchies_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Task_Groups_HierarchiesArgs, 'where'>>;
  delete_task_groups_hierarchies_by_pk?: Resolver<Maybe<ResolversTypes['task_groups_hierarchies']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Task_Groups_Hierarchies_By_PkArgs, 'id'>>;
  delete_task_groups_hierarchy_groups?: Resolver<Maybe<ResolversTypes['task_groups_hierarchy_groups_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Task_Groups_Hierarchy_GroupsArgs, 'where'>>;
  delete_task_groups_hierarchy_groups_by_pk?: Resolver<Maybe<ResolversTypes['task_groups_hierarchy_groups']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Task_Groups_Hierarchy_Groups_By_PkArgs, 'group_id' | 'hierarchy_id'>>;
  delete_task_instructions?: Resolver<Maybe<ResolversTypes['task_instructions_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Task_InstructionsArgs, 'where'>>;
  delete_task_instructions_by_pk?: Resolver<Maybe<ResolversTypes['task_instructions']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Task_Instructions_By_PkArgs, 'id'>>;
  delete_task_media?: Resolver<Maybe<ResolversTypes['task_media_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Task_MediaArgs, 'where'>>;
  delete_task_media_by_pk?: Resolver<Maybe<ResolversTypes['task_media']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Task_Media_By_PkArgs, 'media_id' | 'task_id'>>;
  delete_task_translation?: Resolver<Maybe<ResolversTypes['task_translation_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Task_TranslationArgs, 'where'>>;
  delete_task_translation_by_pk?: Resolver<Maybe<ResolversTypes['task_translation']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Task_Translation_By_PkArgs, 'language' | 'task_id'>>;
  delete_tasks?: Resolver<Maybe<ResolversTypes['tasks_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_TasksArgs, 'where'>>;
  delete_tasks_by_pk?: Resolver<Maybe<ResolversTypes['tasks']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Tasks_By_PkArgs, 'id'>>;
  delete_tool_versions?: Resolver<Maybe<ResolversTypes['tool_versions_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Tool_VersionsArgs, 'where'>>;
  delete_tool_versions_by_pk?: Resolver<Maybe<ResolversTypes['tool_versions']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Tool_Versions_By_PkArgs, 'id'>>;
  delete_tools?: Resolver<Maybe<ResolversTypes['tools_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ToolsArgs, 'where'>>;
  delete_tools_by_pk?: Resolver<Maybe<ResolversTypes['tools']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Tools_By_PkArgs, 'id'>>;
  delete_types?: Resolver<Maybe<ResolversTypes['types_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_TypesArgs, 'where'>>;
  delete_types_by_pk?: Resolver<Maybe<ResolversTypes['types']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Types_By_PkArgs, 'id'>>;
  delete_users?: Resolver<Maybe<ResolversTypes['users_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UsersArgs, 'where'>>;
  delete_users_by_pk?: Resolver<Maybe<ResolversTypes['users']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Users_By_PkArgs, 'id'>>;
  delete_xtensa_configurations?: Resolver<Maybe<ResolversTypes['xtensa_configurations_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Xtensa_ConfigurationsArgs, 'where'>>;
  delete_xtensa_configurations_by_pk?: Resolver<Maybe<ResolversTypes['xtensa_configurations']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Xtensa_Configurations_By_PkArgs, 'id'>>;
  insert_audio_driver?: Resolver<Maybe<ResolversTypes['audio_driver_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Audio_DriverArgs, 'objects'>>;
  insert_audio_driver_one?: Resolver<Maybe<ResolversTypes['audio_driver']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Audio_Driver_OneArgs, 'object'>>;
  insert_audio_measurements_components?: Resolver<Maybe<ResolversTypes['audio_measurements_components_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Audio_Measurements_ComponentsArgs, 'objects'>>;
  insert_audio_measurements_components_one?: Resolver<Maybe<ResolversTypes['audio_measurements_components']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Audio_Measurements_Components_OneArgs, 'object'>>;
  insert_audio_measurements_configurations?: Resolver<Maybe<ResolversTypes['audio_measurements_configurations_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Audio_Measurements_ConfigurationsArgs, 'objects'>>;
  insert_audio_measurements_configurations_one?: Resolver<Maybe<ResolversTypes['audio_measurements_configurations']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Audio_Measurements_Configurations_OneArgs, 'object'>>;
  insert_audio_measurements_master_files?: Resolver<Maybe<ResolversTypes['audio_measurements_master_files_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Audio_Measurements_Master_FilesArgs, 'objects'>>;
  insert_audio_measurements_master_files_one?: Resolver<Maybe<ResolversTypes['audio_measurements_master_files']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Audio_Measurements_Master_Files_OneArgs, 'object'>>;
  insert_audio_measurements_orientations?: Resolver<Maybe<ResolversTypes['audio_measurements_orientations_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Audio_Measurements_OrientationsArgs, 'objects'>>;
  insert_audio_measurements_orientations_one?: Resolver<Maybe<ResolversTypes['audio_measurements_orientations']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Audio_Measurements_Orientations_OneArgs, 'object'>>;
  insert_audio_measurements_payload_recording?: Resolver<Maybe<ResolversTypes['audio_measurements_payload_recording_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Audio_Measurements_Payload_RecordingArgs, 'objects'>>;
  insert_audio_measurements_payload_recording_one?: Resolver<Maybe<ResolversTypes['audio_measurements_payload_recording']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Audio_Measurements_Payload_Recording_OneArgs, 'object'>>;
  insert_audio_measurements_placements?: Resolver<Maybe<ResolversTypes['audio_measurements_placements_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Audio_Measurements_PlacementsArgs, 'objects'>>;
  insert_audio_measurements_placements_one?: Resolver<Maybe<ResolversTypes['audio_measurements_placements']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Audio_Measurements_Placements_OneArgs, 'object'>>;
  insert_audio_measurements_project_tasks?: Resolver<Maybe<ResolversTypes['audio_measurements_project_tasks_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Audio_Measurements_Project_TasksArgs, 'objects'>>;
  insert_audio_measurements_project_tasks_one?: Resolver<Maybe<ResolversTypes['audio_measurements_project_tasks']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Audio_Measurements_Project_Tasks_OneArgs, 'object'>>;
  insert_audio_measurements_recordings?: Resolver<Maybe<ResolversTypes['audio_measurements_recordings_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Audio_Measurements_RecordingsArgs, 'objects'>>;
  insert_audio_measurements_recordings_one?: Resolver<Maybe<ResolversTypes['audio_measurements_recordings']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Audio_Measurements_Recordings_OneArgs, 'object'>>;
  insert_audio_measurements_stages?: Resolver<Maybe<ResolversTypes['audio_measurements_stages_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Audio_Measurements_StagesArgs, 'objects'>>;
  insert_audio_measurements_stages_one?: Resolver<Maybe<ResolversTypes['audio_measurements_stages']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Audio_Measurements_Stages_OneArgs, 'object'>>;
  insert_bringup_checklist_deliverable?: Resolver<Maybe<ResolversTypes['bringup_checklist_deliverable_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Bringup_Checklist_DeliverableArgs, 'objects'>>;
  insert_bringup_checklist_deliverable_one?: Resolver<Maybe<ResolversTypes['bringup_checklist_deliverable']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Bringup_Checklist_Deliverable_OneArgs, 'object'>>;
  insert_bringup_checklists?: Resolver<Maybe<ResolversTypes['bringup_checklists_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Bringup_ChecklistsArgs, 'objects'>>;
  insert_bringup_checklists_one?: Resolver<Maybe<ResolversTypes['bringup_checklists']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Bringup_Checklists_OneArgs, 'object'>>;
  insert_bringup_endorsements?: Resolver<Maybe<ResolversTypes['bringup_endorsements_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Bringup_EndorsementsArgs, 'objects'>>;
  insert_bringup_endorsements_one?: Resolver<Maybe<ResolversTypes['bringup_endorsements']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Bringup_Endorsements_OneArgs, 'object'>>;
  insert_bringup_items?: Resolver<Maybe<ResolversTypes['bringup_items_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Bringup_ItemsArgs, 'objects'>>;
  insert_bringup_items_one?: Resolver<Maybe<ResolversTypes['bringup_items']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Bringup_Items_OneArgs, 'object'>>;
  insert_bringup_milestones?: Resolver<Maybe<ResolversTypes['bringup_milestones_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Bringup_MilestonesArgs, 'objects'>>;
  insert_bringup_milestones_one?: Resolver<Maybe<ResolversTypes['bringup_milestones']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Bringup_Milestones_OneArgs, 'object'>>;
  insert_bringup_project_item_comments?: Resolver<Maybe<ResolversTypes['bringup_project_item_comments_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Bringup_Project_Item_CommentsArgs, 'objects'>>;
  insert_bringup_project_item_comments_one?: Resolver<Maybe<ResolversTypes['bringup_project_item_comments']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Bringup_Project_Item_Comments_OneArgs, 'object'>>;
  insert_categories?: Resolver<Maybe<ResolversTypes['categories_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_CategoriesArgs, 'objects'>>;
  insert_categories_one?: Resolver<Maybe<ResolversTypes['categories']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Categories_OneArgs, 'object'>>;
  insert_context_display_field?: Resolver<Maybe<ResolversTypes['context_display_field_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Context_Display_FieldArgs, 'objects'>>;
  insert_context_display_field_one?: Resolver<Maybe<ResolversTypes['context_display_field']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Context_Display_Field_OneArgs, 'object'>>;
  insert_context_displays?: Resolver<Maybe<ResolversTypes['context_displays_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Context_DisplaysArgs, 'objects'>>;
  insert_context_displays_one?: Resolver<Maybe<ResolversTypes['context_displays']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Context_Displays_OneArgs, 'object'>>;
  insert_contexts?: Resolver<Maybe<ResolversTypes['contexts_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ContextsArgs, 'objects'>>;
  insert_contexts_one?: Resolver<Maybe<ResolversTypes['contexts']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Contexts_OneArgs, 'object'>>;
  insert_customers?: Resolver<Maybe<ResolversTypes['customers_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_CustomersArgs, 'objects'>>;
  insert_customers_one?: Resolver<Maybe<ResolversTypes['customers']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Customers_OneArgs, 'object'>>;
  insert_deliverables?: Resolver<Maybe<ResolversTypes['deliverables_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_DeliverablesArgs, 'objects'>>;
  insert_deliverables_one?: Resolver<Maybe<ResolversTypes['deliverables']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliverables_OneArgs, 'object'>>;
  insert_deliveries_architectures?: Resolver<Maybe<ResolversTypes['deliveries_architectures_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_ArchitecturesArgs, 'objects'>>;
  insert_deliveries_architectures_one?: Resolver<Maybe<ResolversTypes['deliveries_architectures']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Architectures_OneArgs, 'object'>>;
  insert_deliveries_artifact_microsoft_whql_submission?: Resolver<Maybe<ResolversTypes['deliveries_artifact_microsoft_whql_submission_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Artifact_Microsoft_Whql_SubmissionArgs, 'objects'>>;
  insert_deliveries_artifact_microsoft_whql_submission_one?: Resolver<Maybe<ResolversTypes['deliveries_artifact_microsoft_whql_submission']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Artifact_Microsoft_Whql_Submission_OneArgs, 'object'>>;
  insert_deliveries_artifact_operating_system?: Resolver<Maybe<ResolversTypes['deliveries_artifact_operating_system_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Artifact_Operating_SystemArgs, 'objects'>>;
  insert_deliveries_artifact_operating_system_one?: Resolver<Maybe<ResolversTypes['deliveries_artifact_operating_system']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Artifact_Operating_System_OneArgs, 'object'>>;
  insert_deliveries_artifacts?: Resolver<Maybe<ResolversTypes['deliveries_artifacts_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_ArtifactsArgs, 'objects'>>;
  insert_deliveries_artifacts_one?: Resolver<Maybe<ResolversTypes['deliveries_artifacts']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Artifacts_OneArgs, 'object'>>;
  insert_deliveries_device_families?: Resolver<Maybe<ResolversTypes['deliveries_device_families_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Device_FamiliesArgs, 'objects'>>;
  insert_deliveries_device_families_one?: Resolver<Maybe<ResolversTypes['deliveries_device_families']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Device_Families_OneArgs, 'object'>>;
  insert_deliveries_driver_package?: Resolver<Maybe<ResolversTypes['deliveries_driver_package_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Driver_PackageArgs, 'objects'>>;
  insert_deliveries_driver_package_one?: Resolver<Maybe<ResolversTypes['deliveries_driver_package']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Driver_Package_OneArgs, 'object'>>;
  insert_deliveries_driver_project?: Resolver<Maybe<ResolversTypes['deliveries_driver_project_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Driver_ProjectArgs, 'objects'>>;
  insert_deliveries_driver_project_one?: Resolver<Maybe<ResolversTypes['deliveries_driver_project']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Driver_Project_OneArgs, 'object'>>;
  insert_deliveries_driver_version_engine?: Resolver<Maybe<ResolversTypes['deliveries_driver_version_engine_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Driver_Version_EngineArgs, 'objects'>>;
  insert_deliveries_driver_version_engine_one?: Resolver<Maybe<ResolversTypes['deliveries_driver_version_engine']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Driver_Version_Engine_OneArgs, 'object'>>;
  insert_deliveries_driver_version_package?: Resolver<Maybe<ResolversTypes['deliveries_driver_version_package_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Driver_Version_PackageArgs, 'objects'>>;
  insert_deliveries_driver_version_package_one?: Resolver<Maybe<ResolversTypes['deliveries_driver_version_package']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Driver_Version_Package_OneArgs, 'object'>>;
  insert_deliveries_driver_versions?: Resolver<Maybe<ResolversTypes['deliveries_driver_versions_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Driver_VersionsArgs, 'objects'>>;
  insert_deliveries_driver_versions_one?: Resolver<Maybe<ResolversTypes['deliveries_driver_versions']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Driver_Versions_OneArgs, 'object'>>;
  insert_deliveries_drivers?: Resolver<Maybe<ResolversTypes['deliveries_drivers_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_DriversArgs, 'objects'>>;
  insert_deliveries_drivers_one?: Resolver<Maybe<ResolversTypes['deliveries_drivers']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Drivers_OneArgs, 'object'>>;
  insert_deliveries_microsoft_hlk_artifacts?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_hlk_artifacts_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Microsoft_Hlk_ArtifactsArgs, 'objects'>>;
  insert_deliveries_microsoft_hlk_artifacts_one?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_hlk_artifacts']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Microsoft_Hlk_Artifacts_OneArgs, 'object'>>;
  insert_deliveries_microsoft_shipping_label_reviews?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_shipping_label_reviews_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Microsoft_Shipping_Label_ReviewsArgs, 'objects'>>;
  insert_deliveries_microsoft_shipping_label_reviews_one?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_shipping_label_reviews']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Microsoft_Shipping_Label_Reviews_OneArgs, 'object'>>;
  insert_deliveries_microsoft_shipping_labels?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_shipping_labels_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Microsoft_Shipping_LabelsArgs, 'objects'>>;
  insert_deliveries_microsoft_shipping_labels_one?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_shipping_labels']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Microsoft_Shipping_Labels_OneArgs, 'object'>>;
  insert_deliveries_microsoft_whql_submissions?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_whql_submissions_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Microsoft_Whql_SubmissionsArgs, 'objects'>>;
  insert_deliveries_microsoft_whql_submissions_one?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_whql_submissions']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Microsoft_Whql_Submissions_OneArgs, 'object'>>;
  insert_deliveries_operating_systems?: Resolver<Maybe<ResolversTypes['deliveries_operating_systems_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Operating_SystemsArgs, 'objects'>>;
  insert_deliveries_operating_systems_one?: Resolver<Maybe<ResolversTypes['deliveries_operating_systems']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Operating_Systems_OneArgs, 'object'>>;
  insert_deliveries_package_operating_systems?: Resolver<Maybe<ResolversTypes['deliveries_package_operating_systems_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Package_Operating_SystemsArgs, 'objects'>>;
  insert_deliveries_package_operating_systems_one?: Resolver<Maybe<ResolversTypes['deliveries_package_operating_systems']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Package_Operating_Systems_OneArgs, 'object'>>;
  insert_deliveries_package_target?: Resolver<Maybe<ResolversTypes['deliveries_package_target_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Package_TargetArgs, 'objects'>>;
  insert_deliveries_package_target_one?: Resolver<Maybe<ResolversTypes['deliveries_package_target']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Package_Target_OneArgs, 'object'>>;
  insert_deliveries_packages?: Resolver<Maybe<ResolversTypes['deliveries_packages_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_PackagesArgs, 'objects'>>;
  insert_deliveries_packages_one?: Resolver<Maybe<ResolversTypes['deliveries_packages']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Packages_OneArgs, 'object'>>;
  insert_deliveries_partners?: Resolver<Maybe<ResolversTypes['deliveries_partners_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_PartnersArgs, 'objects'>>;
  insert_deliveries_partners_one?: Resolver<Maybe<ResolversTypes['deliveries_partners']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Partners_OneArgs, 'object'>>;
  insert_deliveries_platforms?: Resolver<Maybe<ResolversTypes['deliveries_platforms_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_PlatformsArgs, 'objects'>>;
  insert_deliveries_platforms_one?: Resolver<Maybe<ResolversTypes['deliveries_platforms']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Platforms_OneArgs, 'object'>>;
  insert_deliveries_project_configs?: Resolver<Maybe<ResolversTypes['deliveries_project_configs_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Project_ConfigsArgs, 'objects'>>;
  insert_deliveries_project_configs_one?: Resolver<Maybe<ResolversTypes['deliveries_project_configs']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Project_Configs_OneArgs, 'object'>>;
  insert_deliveries_target_partner?: Resolver<Maybe<ResolversTypes['deliveries_target_partner_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Target_PartnerArgs, 'objects'>>;
  insert_deliveries_target_partner_one?: Resolver<Maybe<ResolversTypes['deliveries_target_partner']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Target_Partner_OneArgs, 'object'>>;
  insert_deliveries_targets?: Resolver<Maybe<ResolversTypes['deliveries_targets_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_TargetsArgs, 'objects'>>;
  insert_deliveries_targets_one?: Resolver<Maybe<ResolversTypes['deliveries_targets']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Deliveries_Targets_OneArgs, 'object'>>;
  insert_device_firmware?: Resolver<Maybe<ResolversTypes['device_firmware_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Device_FirmwareArgs, 'objects'>>;
  insert_device_firmware_one?: Resolver<Maybe<ResolversTypes['device_firmware']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Device_Firmware_OneArgs, 'object'>>;
  insert_device_project?: Resolver<Maybe<ResolversTypes['device_project_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Device_ProjectArgs, 'objects'>>;
  insert_device_project_one?: Resolver<Maybe<ResolversTypes['device_project']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Device_Project_OneArgs, 'object'>>;
  insert_devices?: Resolver<Maybe<ResolversTypes['devices_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_DevicesArgs, 'objects'>>;
  insert_devices_one?: Resolver<Maybe<ResolversTypes['devices']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Devices_OneArgs, 'object'>>;
  insert_dynamic_scaling?: Resolver<Maybe<ResolversTypes['dynamic_scaling_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Dynamic_ScalingArgs, 'objects'>>;
  insert_dynamic_scaling_one?: Resolver<Maybe<ResolversTypes['dynamic_scaling']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Dynamic_Scaling_OneArgs, 'object'>>;
  insert_engine_opmode?: Resolver<Maybe<ResolversTypes['engine_opmode_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Engine_OpmodeArgs, 'objects'>>;
  insert_engine_opmode_one?: Resolver<Maybe<ResolversTypes['engine_opmode']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Engine_Opmode_OneArgs, 'object'>>;
  insert_engine_opmode_recording?: Resolver<Maybe<ResolversTypes['engine_opmode_recording_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Engine_Opmode_RecordingArgs, 'objects'>>;
  insert_engine_opmode_recording_one?: Resolver<Maybe<ResolversTypes['engine_opmode_recording']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Engine_Opmode_Recording_OneArgs, 'object'>>;
  insert_engines?: Resolver<Maybe<ResolversTypes['engines_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_EnginesArgs, 'objects'>>;
  insert_engines_one?: Resolver<Maybe<ResolversTypes['engines']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Engines_OneArgs, 'object'>>;
  insert_field_option_media?: Resolver<Maybe<ResolversTypes['field_option_media_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Field_Option_MediaArgs, 'objects'>>;
  insert_field_option_media_one?: Resolver<Maybe<ResolversTypes['field_option_media']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Field_Option_Media_OneArgs, 'object'>>;
  insert_field_options?: Resolver<Maybe<ResolversTypes['field_options_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Field_OptionsArgs, 'objects'>>;
  insert_field_options_one?: Resolver<Maybe<ResolversTypes['field_options']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Field_Options_OneArgs, 'object'>>;
  insert_field_translation?: Resolver<Maybe<ResolversTypes['field_translation_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Field_TranslationArgs, 'objects'>>;
  insert_field_translation_one?: Resolver<Maybe<ResolversTypes['field_translation']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Field_Translation_OneArgs, 'object'>>;
  insert_fields?: Resolver<Maybe<ResolversTypes['fields_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_FieldsArgs, 'objects'>>;
  insert_fields_one?: Resolver<Maybe<ResolversTypes['fields']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Fields_OneArgs, 'object'>>;
  insert_firmware?: Resolver<Maybe<ResolversTypes['firmware_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_FirmwareArgs, 'objects'>>;
  insert_firmware_one?: Resolver<Maybe<ResolversTypes['firmware']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Firmware_OneArgs, 'object'>>;
  insert_ground_truth_configurations?: Resolver<Maybe<ResolversTypes['ground_truth_configurations_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Ground_Truth_ConfigurationsArgs, 'objects'>>;
  insert_ground_truth_configurations_one?: Resolver<Maybe<ResolversTypes['ground_truth_configurations']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Ground_Truth_Configurations_OneArgs, 'object'>>;
  insert_ground_truth_recordings?: Resolver<Maybe<ResolversTypes['ground_truth_recordings_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Ground_Truth_RecordingsArgs, 'objects'>>;
  insert_ground_truth_recordings_one?: Resolver<Maybe<ResolversTypes['ground_truth_recordings']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Ground_Truth_Recordings_OneArgs, 'object'>>;
  insert_ground_truth_types?: Resolver<Maybe<ResolversTypes['ground_truth_types_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Ground_Truth_TypesArgs, 'objects'>>;
  insert_ground_truth_types_one?: Resolver<Maybe<ResolversTypes['ground_truth_types']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Ground_Truth_Types_OneArgs, 'object'>>;
  insert_hypertask_field_option?: Resolver<Maybe<ResolversTypes['hypertask_field_option_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Hypertask_Field_OptionArgs, 'objects'>>;
  insert_hypertask_field_option_one?: Resolver<Maybe<ResolversTypes['hypertask_field_option']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Hypertask_Field_Option_OneArgs, 'object'>>;
  insert_hypertask_task?: Resolver<Maybe<ResolversTypes['hypertask_task_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Hypertask_TaskArgs, 'objects'>>;
  insert_hypertask_task_one?: Resolver<Maybe<ResolversTypes['hypertask_task']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Hypertask_Task_OneArgs, 'object'>>;
  insert_hypertasks?: Resolver<Maybe<ResolversTypes['hypertasks_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_HypertasksArgs, 'objects'>>;
  insert_hypertasks_one?: Resolver<Maybe<ResolversTypes['hypertasks']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Hypertasks_OneArgs, 'object'>>;
  insert_instruction_translations?: Resolver<Maybe<ResolversTypes['instruction_translations_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Instruction_TranslationsArgs, 'objects'>>;
  insert_instruction_translations_one?: Resolver<Maybe<ResolversTypes['instruction_translations']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Instruction_Translations_OneArgs, 'object'>>;
  insert_integration_test_expectation_media?: Resolver<Maybe<ResolversTypes['integration_test_expectation_media_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Integration_Test_Expectation_MediaArgs, 'objects'>>;
  insert_integration_test_expectation_media_one?: Resolver<Maybe<ResolversTypes['integration_test_expectation_media']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Integration_Test_Expectation_Media_OneArgs, 'object'>>;
  insert_integration_test_expectation_step?: Resolver<Maybe<ResolversTypes['integration_test_expectation_step_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Integration_Test_Expectation_StepArgs, 'objects'>>;
  insert_integration_test_expectation_step_one?: Resolver<Maybe<ResolversTypes['integration_test_expectation_step']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Integration_Test_Expectation_Step_OneArgs, 'object'>>;
  insert_integration_test_expectation_translation?: Resolver<Maybe<ResolversTypes['integration_test_expectation_translation_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Integration_Test_Expectation_TranslationArgs, 'objects'>>;
  insert_integration_test_expectation_translation_one?: Resolver<Maybe<ResolversTypes['integration_test_expectation_translation']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Integration_Test_Expectation_Translation_OneArgs, 'object'>>;
  insert_integration_test_expectations?: Resolver<Maybe<ResolversTypes['integration_test_expectations_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Integration_Test_ExpectationsArgs, 'objects'>>;
  insert_integration_test_expectations_one?: Resolver<Maybe<ResolversTypes['integration_test_expectations']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Integration_Test_Expectations_OneArgs, 'object'>>;
  insert_integration_test_instruction_media?: Resolver<Maybe<ResolversTypes['integration_test_instruction_media_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Integration_Test_Instruction_MediaArgs, 'objects'>>;
  insert_integration_test_instruction_media_one?: Resolver<Maybe<ResolversTypes['integration_test_instruction_media']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Integration_Test_Instruction_Media_OneArgs, 'object'>>;
  insert_integration_test_instruction_translation?: Resolver<Maybe<ResolversTypes['integration_test_instruction_translation_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Integration_Test_Instruction_TranslationArgs, 'objects'>>;
  insert_integration_test_instruction_translation_one?: Resolver<Maybe<ResolversTypes['integration_test_instruction_translation']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Integration_Test_Instruction_Translation_OneArgs, 'object'>>;
  insert_integration_test_instructions?: Resolver<Maybe<ResolversTypes['integration_test_instructions_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Integration_Test_InstructionsArgs, 'objects'>>;
  insert_integration_test_instructions_one?: Resolver<Maybe<ResolversTypes['integration_test_instructions']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Integration_Test_Instructions_OneArgs, 'object'>>;
  insert_integration_test_observances?: Resolver<Maybe<ResolversTypes['integration_test_observances_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Integration_Test_ObservancesArgs, 'objects'>>;
  insert_integration_test_observances_one?: Resolver<Maybe<ResolversTypes['integration_test_observances']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Integration_Test_Observances_OneArgs, 'object'>>;
  insert_integration_test_observations?: Resolver<Maybe<ResolversTypes['integration_test_observations_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Integration_Test_ObservationsArgs, 'objects'>>;
  insert_integration_test_observations_one?: Resolver<Maybe<ResolversTypes['integration_test_observations']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Integration_Test_Observations_OneArgs, 'object'>>;
  insert_integration_test_project_sequence?: Resolver<Maybe<ResolversTypes['integration_test_project_sequence_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Integration_Test_Project_SequenceArgs, 'objects'>>;
  insert_integration_test_project_sequence_one?: Resolver<Maybe<ResolversTypes['integration_test_project_sequence']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Integration_Test_Project_Sequence_OneArgs, 'object'>>;
  insert_integration_test_sequences?: Resolver<Maybe<ResolversTypes['integration_test_sequences_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Integration_Test_SequencesArgs, 'objects'>>;
  insert_integration_test_sequences_one?: Resolver<Maybe<ResolversTypes['integration_test_sequences']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Integration_Test_Sequences_OneArgs, 'object'>>;
  insert_integration_test_steps?: Resolver<Maybe<ResolversTypes['integration_test_steps_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Integration_Test_StepsArgs, 'objects'>>;
  insert_integration_test_steps_one?: Resolver<Maybe<ResolversTypes['integration_test_steps']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Integration_Test_Steps_OneArgs, 'object'>>;
  insert_machine_learning_blobs?: Resolver<Maybe<ResolversTypes['machine_learning_blobs_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Machine_Learning_BlobsArgs, 'objects'>>;
  insert_machine_learning_blobs_one?: Resolver<Maybe<ResolversTypes['machine_learning_blobs']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Machine_Learning_Blobs_OneArgs, 'object'>>;
  insert_machine_learning_dataset_feature?: Resolver<Maybe<ResolversTypes['machine_learning_dataset_feature_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Machine_Learning_Dataset_FeatureArgs, 'objects'>>;
  insert_machine_learning_dataset_feature_one?: Resolver<Maybe<ResolversTypes['machine_learning_dataset_feature']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Machine_Learning_Dataset_Feature_OneArgs, 'object'>>;
  insert_machine_learning_datasets?: Resolver<Maybe<ResolversTypes['machine_learning_datasets_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Machine_Learning_DatasetsArgs, 'objects'>>;
  insert_machine_learning_datasets_one?: Resolver<Maybe<ResolversTypes['machine_learning_datasets']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Machine_Learning_Datasets_OneArgs, 'object'>>;
  insert_machine_learning_features?: Resolver<Maybe<ResolversTypes['machine_learning_features_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Machine_Learning_FeaturesArgs, 'objects'>>;
  insert_machine_learning_features_one?: Resolver<Maybe<ResolversTypes['machine_learning_features']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Machine_Learning_Features_OneArgs, 'object'>>;
  insert_machine_learning_model_blob?: Resolver<Maybe<ResolversTypes['machine_learning_model_blob_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Machine_Learning_Model_BlobArgs, 'objects'>>;
  insert_machine_learning_model_blob_one?: Resolver<Maybe<ResolversTypes['machine_learning_model_blob']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Machine_Learning_Model_Blob_OneArgs, 'object'>>;
  insert_machine_learning_model_dataset?: Resolver<Maybe<ResolversTypes['machine_learning_model_dataset_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Machine_Learning_Model_DatasetArgs, 'objects'>>;
  insert_machine_learning_model_dataset_one?: Resolver<Maybe<ResolversTypes['machine_learning_model_dataset']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Machine_Learning_Model_Dataset_OneArgs, 'object'>>;
  insert_machine_learning_model_recording?: Resolver<Maybe<ResolversTypes['machine_learning_model_recording_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Machine_Learning_Model_RecordingArgs, 'objects'>>;
  insert_machine_learning_model_recording_one?: Resolver<Maybe<ResolversTypes['machine_learning_model_recording']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Machine_Learning_Model_Recording_OneArgs, 'object'>>;
  insert_machine_learning_models?: Resolver<Maybe<ResolversTypes['machine_learning_models_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Machine_Learning_ModelsArgs, 'objects'>>;
  insert_machine_learning_models_one?: Resolver<Maybe<ResolversTypes['machine_learning_models']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Machine_Learning_Models_OneArgs, 'object'>>;
  insert_media?: Resolver<Maybe<ResolversTypes['media_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_MediaArgs, 'objects'>>;
  insert_media_one?: Resolver<Maybe<ResolversTypes['media']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Media_OneArgs, 'object'>>;
  insert_mode_project?: Resolver<Maybe<ResolversTypes['mode_project_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Mode_ProjectArgs, 'objects'>>;
  insert_mode_project_one?: Resolver<Maybe<ResolversTypes['mode_project']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Mode_Project_OneArgs, 'object'>>;
  insert_model_name_hashes?: Resolver<Maybe<ResolversTypes['model_name_hashes_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Model_Name_HashesArgs, 'objects'>>;
  insert_model_name_hashes_one?: Resolver<Maybe<ResolversTypes['model_name_hashes']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Model_Name_Hashes_OneArgs, 'object'>>;
  insert_models?: Resolver<Maybe<ResolversTypes['models_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ModelsArgs, 'objects'>>;
  insert_models_one?: Resolver<Maybe<ResolversTypes['models']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Models_OneArgs, 'object'>>;
  insert_opmode_profile?: Resolver<Maybe<ResolversTypes['opmode_profile_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Opmode_ProfileArgs, 'objects'>>;
  insert_opmode_profile_one?: Resolver<Maybe<ResolversTypes['opmode_profile']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Opmode_Profile_OneArgs, 'object'>>;
  insert_opmode_project?: Resolver<Maybe<ResolversTypes['opmode_project_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Opmode_ProjectArgs, 'objects'>>;
  insert_opmode_project_one?: Resolver<Maybe<ResolversTypes['opmode_project']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Opmode_Project_OneArgs, 'object'>>;
  insert_opmodes?: Resolver<Maybe<ResolversTypes['opmodes_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_OpmodesArgs, 'objects'>>;
  insert_opmodes_one?: Resolver<Maybe<ResolversTypes['opmodes']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Opmodes_OneArgs, 'object'>>;
  insert_option_media?: Resolver<Maybe<ResolversTypes['option_media_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Option_MediaArgs, 'objects'>>;
  insert_option_media_one?: Resolver<Maybe<ResolversTypes['option_media']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Option_Media_OneArgs, 'object'>>;
  insert_option_translation?: Resolver<Maybe<ResolversTypes['option_translation_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Option_TranslationArgs, 'objects'>>;
  insert_option_translation_one?: Resolver<Maybe<ResolversTypes['option_translation']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Option_Translation_OneArgs, 'object'>>;
  insert_options?: Resolver<Maybe<ResolversTypes['options_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_OptionsArgs, 'objects'>>;
  insert_options_one?: Resolver<Maybe<ResolversTypes['options']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Options_OneArgs, 'object'>>;
  insert_payload_recording?: Resolver<Maybe<ResolversTypes['payload_recording_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Payload_RecordingArgs, 'objects'>>;
  insert_payload_recording_one?: Resolver<Maybe<ResolversTypes['payload_recording']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Payload_Recording_OneArgs, 'object'>>;
  insert_payloads?: Resolver<Maybe<ResolversTypes['payloads_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_PayloadsArgs, 'objects'>>;
  insert_payloads_one?: Resolver<Maybe<ResolversTypes['payloads']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Payloads_OneArgs, 'object'>>;
  insert_performance_ratio_metric_contributions?: Resolver<Maybe<ResolversTypes['performance_ratio_metric_contributions_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Performance_Ratio_Metric_ContributionsArgs, 'objects'>>;
  insert_performance_ratio_metric_contributions_one?: Resolver<Maybe<ResolversTypes['performance_ratio_metric_contributions']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Performance_Ratio_Metric_Contributions_OneArgs, 'object'>>;
  insert_performance_ratio_metrics?: Resolver<Maybe<ResolversTypes['performance_ratio_metrics_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Performance_Ratio_MetricsArgs, 'objects'>>;
  insert_performance_ratio_metrics_one?: Resolver<Maybe<ResolversTypes['performance_ratio_metrics']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Performance_Ratio_Metrics_OneArgs, 'object'>>;
  insert_performance_report_deliverable_template?: Resolver<Maybe<ResolversTypes['performance_report_deliverable_template_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Performance_Report_Deliverable_TemplateArgs, 'objects'>>;
  insert_performance_report_deliverable_template_one?: Resolver<Maybe<ResolversTypes['performance_report_deliverable_template']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Performance_Report_Deliverable_Template_OneArgs, 'object'>>;
  insert_performance_report_section_pivot_field?: Resolver<Maybe<ResolversTypes['performance_report_section_pivot_field_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Performance_Report_Section_Pivot_FieldArgs, 'objects'>>;
  insert_performance_report_section_pivot_field_one?: Resolver<Maybe<ResolversTypes['performance_report_section_pivot_field']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Performance_Report_Section_Pivot_Field_OneArgs, 'object'>>;
  insert_performance_report_section_ratio_metric?: Resolver<Maybe<ResolversTypes['performance_report_section_ratio_metric_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Performance_Report_Section_Ratio_MetricArgs, 'objects'>>;
  insert_performance_report_section_ratio_metric_one?: Resolver<Maybe<ResolversTypes['performance_report_section_ratio_metric']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Performance_Report_Section_Ratio_Metric_OneArgs, 'object'>>;
  insert_performance_report_section_task_blacklist?: Resolver<Maybe<ResolversTypes['performance_report_section_task_blacklist_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Performance_Report_Section_Task_BlacklistArgs, 'objects'>>;
  insert_performance_report_section_task_blacklist_one?: Resolver<Maybe<ResolversTypes['performance_report_section_task_blacklist']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Performance_Report_Section_Task_Blacklist_OneArgs, 'object'>>;
  insert_performance_report_sections?: Resolver<Maybe<ResolversTypes['performance_report_sections_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Performance_Report_SectionsArgs, 'objects'>>;
  insert_performance_report_sections_one?: Resolver<Maybe<ResolversTypes['performance_report_sections']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Performance_Report_Sections_OneArgs, 'object'>>;
  insert_performance_report_templates?: Resolver<Maybe<ResolversTypes['performance_report_templates_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Performance_Report_TemplatesArgs, 'objects'>>;
  insert_performance_report_templates_one?: Resolver<Maybe<ResolversTypes['performance_report_templates']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Performance_Report_Templates_OneArgs, 'object'>>;
  insert_peripherals?: Resolver<Maybe<ResolversTypes['peripherals_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_PeripheralsArgs, 'objects'>>;
  insert_peripherals_one?: Resolver<Maybe<ResolversTypes['peripherals']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Peripherals_OneArgs, 'object'>>;
  insert_plan_hypertasks?: Resolver<Maybe<ResolversTypes['plan_hypertasks_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Plan_HypertasksArgs, 'objects'>>;
  insert_plan_hypertasks_one?: Resolver<Maybe<ResolversTypes['plan_hypertasks']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Plan_Hypertasks_OneArgs, 'object'>>;
  insert_plan_tasks?: Resolver<Maybe<ResolversTypes['plan_tasks_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Plan_TasksArgs, 'objects'>>;
  insert_plan_tasks_one?: Resolver<Maybe<ResolversTypes['plan_tasks']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Plan_Tasks_OneArgs, 'object'>>;
  insert_plans?: Resolver<Maybe<ResolversTypes['plans_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_PlansArgs, 'objects'>>;
  insert_plans_one?: Resolver<Maybe<ResolversTypes['plans']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Plans_OneArgs, 'object'>>;
  insert_profiles?: Resolver<Maybe<ResolversTypes['profiles_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ProfilesArgs, 'objects'>>;
  insert_profiles_one?: Resolver<Maybe<ResolversTypes['profiles']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Profiles_OneArgs, 'object'>>;
  insert_project_combo?: Resolver<Maybe<ResolversTypes['project_combo_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Project_ComboArgs, 'objects'>>;
  insert_project_combo_one?: Resolver<Maybe<ResolversTypes['project_combo']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Project_Combo_OneArgs, 'object'>>;
  insert_project_hypertasks?: Resolver<Maybe<ResolversTypes['project_hypertasks_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Project_HypertasksArgs, 'objects'>>;
  insert_project_hypertasks_one?: Resolver<Maybe<ResolversTypes['project_hypertasks']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Project_Hypertasks_OneArgs, 'object'>>;
  insert_project_tasks?: Resolver<Maybe<ResolversTypes['project_tasks_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Project_TasksArgs, 'objects'>>;
  insert_project_tasks_one?: Resolver<Maybe<ResolversTypes['project_tasks']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Project_Tasks_OneArgs, 'object'>>;
  insert_project_user?: Resolver<Maybe<ResolversTypes['project_user_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Project_UserArgs, 'objects'>>;
  insert_project_user_one?: Resolver<Maybe<ResolversTypes['project_user']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Project_User_OneArgs, 'object'>>;
  insert_projects?: Resolver<Maybe<ResolversTypes['projects_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ProjectsArgs, 'objects'>>;
  insert_projects_one?: Resolver<Maybe<ResolversTypes['projects']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Projects_OneArgs, 'object'>>;
  insert_rabbit_test_plan_project?: Resolver<Maybe<ResolversTypes['rabbit_test_plan_project_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Rabbit_Test_Plan_ProjectArgs, 'objects'>>;
  insert_rabbit_test_plan_project_one?: Resolver<Maybe<ResolversTypes['rabbit_test_plan_project']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Rabbit_Test_Plan_Project_OneArgs, 'object'>>;
  insert_rabbit_test_plan_recording?: Resolver<Maybe<ResolversTypes['rabbit_test_plan_recording_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Rabbit_Test_Plan_RecordingArgs, 'objects'>>;
  insert_rabbit_test_plan_recording_one?: Resolver<Maybe<ResolversTypes['rabbit_test_plan_recording']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Rabbit_Test_Plan_Recording_OneArgs, 'object'>>;
  insert_rabbit_test_plan_task?: Resolver<Maybe<ResolversTypes['rabbit_test_plan_task_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Rabbit_Test_Plan_TaskArgs, 'objects'>>;
  insert_rabbit_test_plan_task_one?: Resolver<Maybe<ResolversTypes['rabbit_test_plan_task']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Rabbit_Test_Plan_Task_OneArgs, 'object'>>;
  insert_rabbit_test_plans?: Resolver<Maybe<ResolversTypes['rabbit_test_plans_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Rabbit_Test_PlansArgs, 'objects'>>;
  insert_rabbit_test_plans_one?: Resolver<Maybe<ResolversTypes['rabbit_test_plans']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Rabbit_Test_Plans_OneArgs, 'object'>>;
  insert_recording_apps?: Resolver<Maybe<ResolversTypes['recording_apps_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Recording_AppsArgs, 'objects'>>;
  insert_recording_apps_one?: Resolver<Maybe<ResolversTypes['recording_apps']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Recording_Apps_OneArgs, 'object'>>;
  insert_recording_audio_driver?: Resolver<Maybe<ResolversTypes['recording_audio_driver_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Recording_Audio_DriverArgs, 'objects'>>;
  insert_recording_audio_driver_one?: Resolver<Maybe<ResolversTypes['recording_audio_driver']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Recording_Audio_Driver_OneArgs, 'object'>>;
  insert_recording_data_ground_truth?: Resolver<Maybe<ResolversTypes['recording_data_ground_truth_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Recording_Data_Ground_TruthArgs, 'objects'>>;
  insert_recording_data_ground_truth_one?: Resolver<Maybe<ResolversTypes['recording_data_ground_truth']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Recording_Data_Ground_Truth_OneArgs, 'object'>>;
  insert_recording_data_labels?: Resolver<Maybe<ResolversTypes['recording_data_labels_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Recording_Data_LabelsArgs, 'objects'>>;
  insert_recording_data_labels_one?: Resolver<Maybe<ResolversTypes['recording_data_labels']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Recording_Data_Labels_OneArgs, 'object'>>;
  insert_recording_data_mode_parameter?: Resolver<Maybe<ResolversTypes['recording_data_mode_parameter_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Recording_Data_Mode_ParameterArgs, 'objects'>>;
  insert_recording_data_mode_parameter_one?: Resolver<Maybe<ResolversTypes['recording_data_mode_parameter']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Recording_Data_Mode_Parameter_OneArgs, 'object'>>;
  insert_recording_data_modes?: Resolver<Maybe<ResolversTypes['recording_data_modes_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Recording_Data_ModesArgs, 'objects'>>;
  insert_recording_data_modes_one?: Resolver<Maybe<ResolversTypes['recording_data_modes']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Recording_Data_Modes_OneArgs, 'object'>>;
  insert_recording_data_parameters?: Resolver<Maybe<ResolversTypes['recording_data_parameters_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Recording_Data_ParametersArgs, 'objects'>>;
  insert_recording_data_parameters_one?: Resolver<Maybe<ResolversTypes['recording_data_parameters']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Recording_Data_Parameters_OneArgs, 'object'>>;
  insert_recording_data_payload_pair?: Resolver<Maybe<ResolversTypes['recording_data_payload_pair_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Recording_Data_Payload_PairArgs, 'objects'>>;
  insert_recording_data_payload_pair_one?: Resolver<Maybe<ResolversTypes['recording_data_payload_pair']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Recording_Data_Payload_Pair_OneArgs, 'object'>>;
  insert_recording_data_predictions?: Resolver<Maybe<ResolversTypes['recording_data_predictions_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Recording_Data_PredictionsArgs, 'objects'>>;
  insert_recording_data_predictions_one?: Resolver<Maybe<ResolversTypes['recording_data_predictions']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Recording_Data_Predictions_OneArgs, 'object'>>;
  insert_recording_recording_tag?: Resolver<Maybe<ResolversTypes['recording_recording_tag_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Recording_Recording_TagArgs, 'objects'>>;
  insert_recording_recording_tag_one?: Resolver<Maybe<ResolversTypes['recording_recording_tag']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Recording_Recording_Tag_OneArgs, 'object'>>;
  insert_recording_tags?: Resolver<Maybe<ResolversTypes['recording_tags_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Recording_TagsArgs, 'objects'>>;
  insert_recording_tags_one?: Resolver<Maybe<ResolversTypes['recording_tags']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Recording_Tags_OneArgs, 'object'>>;
  insert_recordings?: Resolver<Maybe<ResolversTypes['recordings_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_RecordingsArgs, 'objects'>>;
  insert_recordings_one?: Resolver<Maybe<ResolversTypes['recordings']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Recordings_OneArgs, 'object'>>;
  insert_role_user?: Resolver<Maybe<ResolversTypes['role_user_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Role_UserArgs, 'objects'>>;
  insert_role_user_one?: Resolver<Maybe<ResolversTypes['role_user']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Role_User_OneArgs, 'object'>>;
  insert_rooms?: Resolver<Maybe<ResolversTypes['rooms_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_RoomsArgs, 'objects'>>;
  insert_rooms_one?: Resolver<Maybe<ResolversTypes['rooms']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Rooms_OneArgs, 'object'>>;
  insert_simulations_scores?: Resolver<Maybe<ResolversTypes['simulations_scores_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Simulations_ScoresArgs, 'objects'>>;
  insert_simulations_scores_one?: Resolver<Maybe<ResolversTypes['simulations_scores']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Simulations_Scores_OneArgs, 'object'>>;
  insert_task_field_option?: Resolver<Maybe<ResolversTypes['task_field_option_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Task_Field_OptionArgs, 'objects'>>;
  insert_task_field_option_one?: Resolver<Maybe<ResolversTypes['task_field_option']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Task_Field_Option_OneArgs, 'object'>>;
  insert_task_groups_group_fields?: Resolver<Maybe<ResolversTypes['task_groups_group_fields_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Task_Groups_Group_FieldsArgs, 'objects'>>;
  insert_task_groups_group_fields_one?: Resolver<Maybe<ResolversTypes['task_groups_group_fields']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Task_Groups_Group_Fields_OneArgs, 'object'>>;
  insert_task_groups_groups?: Resolver<Maybe<ResolversTypes['task_groups_groups_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Task_Groups_GroupsArgs, 'objects'>>;
  insert_task_groups_groups_one?: Resolver<Maybe<ResolversTypes['task_groups_groups']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Task_Groups_Groups_OneArgs, 'object'>>;
  insert_task_groups_hierarchies?: Resolver<Maybe<ResolversTypes['task_groups_hierarchies_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Task_Groups_HierarchiesArgs, 'objects'>>;
  insert_task_groups_hierarchies_one?: Resolver<Maybe<ResolversTypes['task_groups_hierarchies']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Task_Groups_Hierarchies_OneArgs, 'object'>>;
  insert_task_groups_hierarchy_groups?: Resolver<Maybe<ResolversTypes['task_groups_hierarchy_groups_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Task_Groups_Hierarchy_GroupsArgs, 'objects'>>;
  insert_task_groups_hierarchy_groups_one?: Resolver<Maybe<ResolversTypes['task_groups_hierarchy_groups']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Task_Groups_Hierarchy_Groups_OneArgs, 'object'>>;
  insert_task_instructions?: Resolver<Maybe<ResolversTypes['task_instructions_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Task_InstructionsArgs, 'objects'>>;
  insert_task_instructions_one?: Resolver<Maybe<ResolversTypes['task_instructions']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Task_Instructions_OneArgs, 'object'>>;
  insert_task_media?: Resolver<Maybe<ResolversTypes['task_media_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Task_MediaArgs, 'objects'>>;
  insert_task_media_one?: Resolver<Maybe<ResolversTypes['task_media']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Task_Media_OneArgs, 'object'>>;
  insert_task_translation?: Resolver<Maybe<ResolversTypes['task_translation_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Task_TranslationArgs, 'objects'>>;
  insert_task_translation_one?: Resolver<Maybe<ResolversTypes['task_translation']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Task_Translation_OneArgs, 'object'>>;
  insert_tasks?: Resolver<Maybe<ResolversTypes['tasks_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_TasksArgs, 'objects'>>;
  insert_tasks_one?: Resolver<Maybe<ResolversTypes['tasks']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Tasks_OneArgs, 'object'>>;
  insert_tool_versions?: Resolver<Maybe<ResolversTypes['tool_versions_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Tool_VersionsArgs, 'objects'>>;
  insert_tool_versions_one?: Resolver<Maybe<ResolversTypes['tool_versions']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Tool_Versions_OneArgs, 'object'>>;
  insert_tools?: Resolver<Maybe<ResolversTypes['tools_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ToolsArgs, 'objects'>>;
  insert_tools_one?: Resolver<Maybe<ResolversTypes['tools']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Tools_OneArgs, 'object'>>;
  insert_types?: Resolver<Maybe<ResolversTypes['types_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_TypesArgs, 'objects'>>;
  insert_types_one?: Resolver<Maybe<ResolversTypes['types']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Types_OneArgs, 'object'>>;
  insert_users?: Resolver<Maybe<ResolversTypes['users_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UsersArgs, 'objects'>>;
  insert_users_one?: Resolver<Maybe<ResolversTypes['users']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Users_OneArgs, 'object'>>;
  insert_xtensa_configurations?: Resolver<Maybe<ResolversTypes['xtensa_configurations_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Xtensa_ConfigurationsArgs, 'objects'>>;
  insert_xtensa_configurations_one?: Resolver<Maybe<ResolversTypes['xtensa_configurations']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Xtensa_Configurations_OneArgs, 'object'>>;
  update_audio_driver?: Resolver<Maybe<ResolversTypes['audio_driver_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Audio_DriverArgs, 'where'>>;
  update_audio_driver_by_pk?: Resolver<Maybe<ResolversTypes['audio_driver']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Audio_Driver_By_PkArgs, 'pk_columns'>>;
  update_audio_driver_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['audio_driver_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Audio_Driver_ManyArgs, 'updates'>>;
  update_audio_measurements_components?: Resolver<Maybe<ResolversTypes['audio_measurements_components_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Audio_Measurements_ComponentsArgs, 'where'>>;
  update_audio_measurements_components_by_pk?: Resolver<Maybe<ResolversTypes['audio_measurements_components']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Audio_Measurements_Components_By_PkArgs, 'pk_columns'>>;
  update_audio_measurements_components_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['audio_measurements_components_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Audio_Measurements_Components_ManyArgs, 'updates'>>;
  update_audio_measurements_configurations?: Resolver<Maybe<ResolversTypes['audio_measurements_configurations_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Audio_Measurements_ConfigurationsArgs, 'where'>>;
  update_audio_measurements_configurations_by_pk?: Resolver<Maybe<ResolversTypes['audio_measurements_configurations']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Audio_Measurements_Configurations_By_PkArgs, 'pk_columns'>>;
  update_audio_measurements_configurations_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['audio_measurements_configurations_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Audio_Measurements_Configurations_ManyArgs, 'updates'>>;
  update_audio_measurements_master_files?: Resolver<Maybe<ResolversTypes['audio_measurements_master_files_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Audio_Measurements_Master_FilesArgs, 'where'>>;
  update_audio_measurements_master_files_by_pk?: Resolver<Maybe<ResolversTypes['audio_measurements_master_files']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Audio_Measurements_Master_Files_By_PkArgs, 'pk_columns'>>;
  update_audio_measurements_master_files_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['audio_measurements_master_files_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Audio_Measurements_Master_Files_ManyArgs, 'updates'>>;
  update_audio_measurements_orientations?: Resolver<Maybe<ResolversTypes['audio_measurements_orientations_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Audio_Measurements_OrientationsArgs, 'where'>>;
  update_audio_measurements_orientations_by_pk?: Resolver<Maybe<ResolversTypes['audio_measurements_orientations']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Audio_Measurements_Orientations_By_PkArgs, 'pk_columns'>>;
  update_audio_measurements_orientations_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['audio_measurements_orientations_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Audio_Measurements_Orientations_ManyArgs, 'updates'>>;
  update_audio_measurements_payload_recording?: Resolver<Maybe<ResolversTypes['audio_measurements_payload_recording_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Audio_Measurements_Payload_RecordingArgs, 'where'>>;
  update_audio_measurements_payload_recording_by_pk?: Resolver<Maybe<ResolversTypes['audio_measurements_payload_recording']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Audio_Measurements_Payload_Recording_By_PkArgs, 'pk_columns'>>;
  update_audio_measurements_payload_recording_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['audio_measurements_payload_recording_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Audio_Measurements_Payload_Recording_ManyArgs, 'updates'>>;
  update_audio_measurements_placements?: Resolver<Maybe<ResolversTypes['audio_measurements_placements_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Audio_Measurements_PlacementsArgs, 'where'>>;
  update_audio_measurements_placements_by_pk?: Resolver<Maybe<ResolversTypes['audio_measurements_placements']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Audio_Measurements_Placements_By_PkArgs, 'pk_columns'>>;
  update_audio_measurements_placements_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['audio_measurements_placements_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Audio_Measurements_Placements_ManyArgs, 'updates'>>;
  update_audio_measurements_project_tasks?: Resolver<Maybe<ResolversTypes['audio_measurements_project_tasks_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Audio_Measurements_Project_TasksArgs, 'where'>>;
  update_audio_measurements_project_tasks_by_pk?: Resolver<Maybe<ResolversTypes['audio_measurements_project_tasks']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Audio_Measurements_Project_Tasks_By_PkArgs, 'pk_columns'>>;
  update_audio_measurements_project_tasks_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['audio_measurements_project_tasks_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Audio_Measurements_Project_Tasks_ManyArgs, 'updates'>>;
  update_audio_measurements_recordings?: Resolver<Maybe<ResolversTypes['audio_measurements_recordings_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Audio_Measurements_RecordingsArgs, 'where'>>;
  update_audio_measurements_recordings_by_pk?: Resolver<Maybe<ResolversTypes['audio_measurements_recordings']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Audio_Measurements_Recordings_By_PkArgs, 'pk_columns'>>;
  update_audio_measurements_recordings_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['audio_measurements_recordings_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Audio_Measurements_Recordings_ManyArgs, 'updates'>>;
  update_audio_measurements_stages?: Resolver<Maybe<ResolversTypes['audio_measurements_stages_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Audio_Measurements_StagesArgs, 'where'>>;
  update_audio_measurements_stages_by_pk?: Resolver<Maybe<ResolversTypes['audio_measurements_stages']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Audio_Measurements_Stages_By_PkArgs, 'pk_columns'>>;
  update_audio_measurements_stages_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['audio_measurements_stages_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Audio_Measurements_Stages_ManyArgs, 'updates'>>;
  update_bringup_checklist_deliverable?: Resolver<Maybe<ResolversTypes['bringup_checklist_deliverable_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Bringup_Checklist_DeliverableArgs, 'where'>>;
  update_bringup_checklist_deliverable_by_pk?: Resolver<Maybe<ResolversTypes['bringup_checklist_deliverable']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Bringup_Checklist_Deliverable_By_PkArgs, 'pk_columns'>>;
  update_bringup_checklist_deliverable_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['bringup_checklist_deliverable_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Bringup_Checklist_Deliverable_ManyArgs, 'updates'>>;
  update_bringup_checklists?: Resolver<Maybe<ResolversTypes['bringup_checklists_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Bringup_ChecklistsArgs, 'where'>>;
  update_bringup_checklists_by_pk?: Resolver<Maybe<ResolversTypes['bringup_checklists']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Bringup_Checklists_By_PkArgs, 'pk_columns'>>;
  update_bringup_checklists_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['bringup_checklists_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Bringup_Checklists_ManyArgs, 'updates'>>;
  update_bringup_endorsements?: Resolver<Maybe<ResolversTypes['bringup_endorsements_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Bringup_EndorsementsArgs, 'where'>>;
  update_bringup_endorsements_by_pk?: Resolver<Maybe<ResolversTypes['bringup_endorsements']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Bringup_Endorsements_By_PkArgs, 'pk_columns'>>;
  update_bringup_endorsements_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['bringup_endorsements_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Bringup_Endorsements_ManyArgs, 'updates'>>;
  update_bringup_items?: Resolver<Maybe<ResolversTypes['bringup_items_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Bringup_ItemsArgs, 'where'>>;
  update_bringup_items_by_pk?: Resolver<Maybe<ResolversTypes['bringup_items']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Bringup_Items_By_PkArgs, 'pk_columns'>>;
  update_bringup_items_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['bringup_items_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Bringup_Items_ManyArgs, 'updates'>>;
  update_bringup_milestones?: Resolver<Maybe<ResolversTypes['bringup_milestones_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Bringup_MilestonesArgs, 'where'>>;
  update_bringup_milestones_by_pk?: Resolver<Maybe<ResolversTypes['bringup_milestones']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Bringup_Milestones_By_PkArgs, 'pk_columns'>>;
  update_bringup_milestones_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['bringup_milestones_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Bringup_Milestones_ManyArgs, 'updates'>>;
  update_bringup_project_item_comments?: Resolver<Maybe<ResolversTypes['bringup_project_item_comments_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Bringup_Project_Item_CommentsArgs, 'where'>>;
  update_bringup_project_item_comments_by_pk?: Resolver<Maybe<ResolversTypes['bringup_project_item_comments']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Bringup_Project_Item_Comments_By_PkArgs, 'pk_columns'>>;
  update_bringup_project_item_comments_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['bringup_project_item_comments_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Bringup_Project_Item_Comments_ManyArgs, 'updates'>>;
  update_categories?: Resolver<Maybe<ResolversTypes['categories_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_CategoriesArgs, 'where'>>;
  update_categories_by_pk?: Resolver<Maybe<ResolversTypes['categories']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Categories_By_PkArgs, 'pk_columns'>>;
  update_categories_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['categories_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Categories_ManyArgs, 'updates'>>;
  update_context_display_field?: Resolver<Maybe<ResolversTypes['context_display_field_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Context_Display_FieldArgs, 'where'>>;
  update_context_display_field_by_pk?: Resolver<Maybe<ResolversTypes['context_display_field']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Context_Display_Field_By_PkArgs, 'pk_columns'>>;
  update_context_display_field_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['context_display_field_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Context_Display_Field_ManyArgs, 'updates'>>;
  update_context_displays?: Resolver<Maybe<ResolversTypes['context_displays_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Context_DisplaysArgs, 'where'>>;
  update_context_displays_by_pk?: Resolver<Maybe<ResolversTypes['context_displays']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Context_Displays_By_PkArgs, 'pk_columns'>>;
  update_context_displays_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['context_displays_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Context_Displays_ManyArgs, 'updates'>>;
  update_contexts?: Resolver<Maybe<ResolversTypes['contexts_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ContextsArgs, 'where'>>;
  update_contexts_by_pk?: Resolver<Maybe<ResolversTypes['contexts']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Contexts_By_PkArgs, 'pk_columns'>>;
  update_contexts_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['contexts_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Contexts_ManyArgs, 'updates'>>;
  update_customers?: Resolver<Maybe<ResolversTypes['customers_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_CustomersArgs, 'where'>>;
  update_customers_by_pk?: Resolver<Maybe<ResolversTypes['customers']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Customers_By_PkArgs, 'pk_columns'>>;
  update_customers_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['customers_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Customers_ManyArgs, 'updates'>>;
  update_deliverables?: Resolver<Maybe<ResolversTypes['deliverables_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_DeliverablesArgs, 'where'>>;
  update_deliverables_by_pk?: Resolver<Maybe<ResolversTypes['deliverables']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliverables_By_PkArgs, 'pk_columns'>>;
  update_deliverables_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['deliverables_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliverables_ManyArgs, 'updates'>>;
  update_deliveries_architectures?: Resolver<Maybe<ResolversTypes['deliveries_architectures_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_ArchitecturesArgs, 'where'>>;
  update_deliveries_architectures_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_architectures']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Architectures_By_PkArgs, 'pk_columns'>>;
  update_deliveries_architectures_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['deliveries_architectures_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Architectures_ManyArgs, 'updates'>>;
  update_deliveries_artifact_microsoft_whql_submission?: Resolver<Maybe<ResolversTypes['deliveries_artifact_microsoft_whql_submission_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Artifact_Microsoft_Whql_SubmissionArgs, 'where'>>;
  update_deliveries_artifact_microsoft_whql_submission_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_artifact_microsoft_whql_submission']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Artifact_Microsoft_Whql_Submission_By_PkArgs, 'pk_columns'>>;
  update_deliveries_artifact_microsoft_whql_submission_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['deliveries_artifact_microsoft_whql_submission_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Artifact_Microsoft_Whql_Submission_ManyArgs, 'updates'>>;
  update_deliveries_artifact_operating_system?: Resolver<Maybe<ResolversTypes['deliveries_artifact_operating_system_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Artifact_Operating_SystemArgs, 'where'>>;
  update_deliveries_artifact_operating_system_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_artifact_operating_system']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Artifact_Operating_System_By_PkArgs, 'pk_columns'>>;
  update_deliveries_artifact_operating_system_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['deliveries_artifact_operating_system_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Artifact_Operating_System_ManyArgs, 'updates'>>;
  update_deliveries_artifacts?: Resolver<Maybe<ResolversTypes['deliveries_artifacts_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_ArtifactsArgs, 'where'>>;
  update_deliveries_artifacts_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_artifacts']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Artifacts_By_PkArgs, 'pk_columns'>>;
  update_deliveries_artifacts_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['deliveries_artifacts_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Artifacts_ManyArgs, 'updates'>>;
  update_deliveries_device_families?: Resolver<Maybe<ResolversTypes['deliveries_device_families_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Device_FamiliesArgs, 'where'>>;
  update_deliveries_device_families_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_device_families']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Device_Families_By_PkArgs, 'pk_columns'>>;
  update_deliveries_device_families_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['deliveries_device_families_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Device_Families_ManyArgs, 'updates'>>;
  update_deliveries_driver_package?: Resolver<Maybe<ResolversTypes['deliveries_driver_package_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Driver_PackageArgs, 'where'>>;
  update_deliveries_driver_package_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_driver_package']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Driver_Package_By_PkArgs, 'pk_columns'>>;
  update_deliveries_driver_package_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['deliveries_driver_package_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Driver_Package_ManyArgs, 'updates'>>;
  update_deliveries_driver_project?: Resolver<Maybe<ResolversTypes['deliveries_driver_project_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Driver_ProjectArgs, 'where'>>;
  update_deliveries_driver_project_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_driver_project']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Driver_Project_By_PkArgs, 'pk_columns'>>;
  update_deliveries_driver_project_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['deliveries_driver_project_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Driver_Project_ManyArgs, 'updates'>>;
  update_deliveries_driver_version_engine?: Resolver<Maybe<ResolversTypes['deliveries_driver_version_engine_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Driver_Version_EngineArgs, 'where'>>;
  update_deliveries_driver_version_engine_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_driver_version_engine']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Driver_Version_Engine_By_PkArgs, 'pk_columns'>>;
  update_deliveries_driver_version_engine_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['deliveries_driver_version_engine_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Driver_Version_Engine_ManyArgs, 'updates'>>;
  update_deliveries_driver_version_package?: Resolver<Maybe<ResolversTypes['deliveries_driver_version_package_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Driver_Version_PackageArgs, 'where'>>;
  update_deliveries_driver_version_package_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_driver_version_package']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Driver_Version_Package_By_PkArgs, 'pk_columns'>>;
  update_deliveries_driver_version_package_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['deliveries_driver_version_package_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Driver_Version_Package_ManyArgs, 'updates'>>;
  update_deliveries_driver_versions?: Resolver<Maybe<ResolversTypes['deliveries_driver_versions_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Driver_VersionsArgs, 'where'>>;
  update_deliveries_driver_versions_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_driver_versions']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Driver_Versions_By_PkArgs, 'pk_columns'>>;
  update_deliveries_driver_versions_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['deliveries_driver_versions_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Driver_Versions_ManyArgs, 'updates'>>;
  update_deliveries_drivers?: Resolver<Maybe<ResolversTypes['deliveries_drivers_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_DriversArgs, 'where'>>;
  update_deliveries_drivers_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_drivers']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Drivers_By_PkArgs, 'pk_columns'>>;
  update_deliveries_drivers_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['deliveries_drivers_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Drivers_ManyArgs, 'updates'>>;
  update_deliveries_microsoft_hlk_artifacts?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_hlk_artifacts_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Microsoft_Hlk_ArtifactsArgs, 'where'>>;
  update_deliveries_microsoft_hlk_artifacts_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_hlk_artifacts']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Microsoft_Hlk_Artifacts_By_PkArgs, 'pk_columns'>>;
  update_deliveries_microsoft_hlk_artifacts_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['deliveries_microsoft_hlk_artifacts_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Microsoft_Hlk_Artifacts_ManyArgs, 'updates'>>;
  update_deliveries_microsoft_shipping_label_reviews?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_shipping_label_reviews_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Microsoft_Shipping_Label_ReviewsArgs, 'where'>>;
  update_deliveries_microsoft_shipping_label_reviews_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_shipping_label_reviews']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Microsoft_Shipping_Label_Reviews_By_PkArgs, 'pk_columns'>>;
  update_deliveries_microsoft_shipping_label_reviews_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['deliveries_microsoft_shipping_label_reviews_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Microsoft_Shipping_Label_Reviews_ManyArgs, 'updates'>>;
  update_deliveries_microsoft_shipping_labels?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_shipping_labels_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Microsoft_Shipping_LabelsArgs, 'where'>>;
  update_deliveries_microsoft_shipping_labels_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_shipping_labels']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Microsoft_Shipping_Labels_By_PkArgs, 'pk_columns'>>;
  update_deliveries_microsoft_shipping_labels_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['deliveries_microsoft_shipping_labels_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Microsoft_Shipping_Labels_ManyArgs, 'updates'>>;
  update_deliveries_microsoft_whql_submissions?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_whql_submissions_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Microsoft_Whql_SubmissionsArgs, 'where'>>;
  update_deliveries_microsoft_whql_submissions_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_whql_submissions']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Microsoft_Whql_Submissions_By_PkArgs, 'pk_columns'>>;
  update_deliveries_microsoft_whql_submissions_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['deliveries_microsoft_whql_submissions_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Microsoft_Whql_Submissions_ManyArgs, 'updates'>>;
  update_deliveries_operating_systems?: Resolver<Maybe<ResolversTypes['deliveries_operating_systems_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Operating_SystemsArgs, 'where'>>;
  update_deliveries_operating_systems_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_operating_systems']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Operating_Systems_By_PkArgs, 'pk_columns'>>;
  update_deliveries_operating_systems_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['deliveries_operating_systems_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Operating_Systems_ManyArgs, 'updates'>>;
  update_deliveries_package_operating_systems?: Resolver<Maybe<ResolversTypes['deliveries_package_operating_systems_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Package_Operating_SystemsArgs, 'where'>>;
  update_deliveries_package_operating_systems_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_package_operating_systems']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Package_Operating_Systems_By_PkArgs, 'pk_columns'>>;
  update_deliveries_package_operating_systems_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['deliveries_package_operating_systems_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Package_Operating_Systems_ManyArgs, 'updates'>>;
  update_deliveries_package_target?: Resolver<Maybe<ResolversTypes['deliveries_package_target_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Package_TargetArgs, 'where'>>;
  update_deliveries_package_target_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_package_target']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Package_Target_By_PkArgs, 'pk_columns'>>;
  update_deliveries_package_target_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['deliveries_package_target_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Package_Target_ManyArgs, 'updates'>>;
  update_deliveries_packages?: Resolver<Maybe<ResolversTypes['deliveries_packages_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_PackagesArgs, 'where'>>;
  update_deliveries_packages_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_packages']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Packages_By_PkArgs, 'pk_columns'>>;
  update_deliveries_packages_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['deliveries_packages_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Packages_ManyArgs, 'updates'>>;
  update_deliveries_partners?: Resolver<Maybe<ResolversTypes['deliveries_partners_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_PartnersArgs, 'where'>>;
  update_deliveries_partners_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_partners']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Partners_By_PkArgs, 'pk_columns'>>;
  update_deliveries_partners_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['deliveries_partners_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Partners_ManyArgs, 'updates'>>;
  update_deliveries_platforms?: Resolver<Maybe<ResolversTypes['deliveries_platforms_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_PlatformsArgs, 'where'>>;
  update_deliveries_platforms_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_platforms']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Platforms_By_PkArgs, 'pk_columns'>>;
  update_deliveries_platforms_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['deliveries_platforms_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Platforms_ManyArgs, 'updates'>>;
  update_deliveries_project_configs?: Resolver<Maybe<ResolversTypes['deliveries_project_configs_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Project_ConfigsArgs, 'where'>>;
  update_deliveries_project_configs_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_project_configs']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Project_Configs_By_PkArgs, 'pk_columns'>>;
  update_deliveries_project_configs_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['deliveries_project_configs_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Project_Configs_ManyArgs, 'updates'>>;
  update_deliveries_target_partner?: Resolver<Maybe<ResolversTypes['deliveries_target_partner_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Target_PartnerArgs, 'where'>>;
  update_deliveries_target_partner_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_target_partner']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Target_Partner_By_PkArgs, 'pk_columns'>>;
  update_deliveries_target_partner_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['deliveries_target_partner_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Target_Partner_ManyArgs, 'updates'>>;
  update_deliveries_targets?: Resolver<Maybe<ResolversTypes['deliveries_targets_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_TargetsArgs, 'where'>>;
  update_deliveries_targets_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_targets']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Targets_By_PkArgs, 'pk_columns'>>;
  update_deliveries_targets_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['deliveries_targets_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Deliveries_Targets_ManyArgs, 'updates'>>;
  update_device_firmware?: Resolver<Maybe<ResolversTypes['device_firmware_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Device_FirmwareArgs, 'where'>>;
  update_device_firmware_by_pk?: Resolver<Maybe<ResolversTypes['device_firmware']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Device_Firmware_By_PkArgs, 'pk_columns'>>;
  update_device_firmware_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['device_firmware_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Device_Firmware_ManyArgs, 'updates'>>;
  update_device_project?: Resolver<Maybe<ResolversTypes['device_project_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Device_ProjectArgs, 'where'>>;
  update_device_project_by_pk?: Resolver<Maybe<ResolversTypes['device_project']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Device_Project_By_PkArgs, 'pk_columns'>>;
  update_device_project_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['device_project_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Device_Project_ManyArgs, 'updates'>>;
  update_devices?: Resolver<Maybe<ResolversTypes['devices_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_DevicesArgs, 'where'>>;
  update_devices_by_pk?: Resolver<Maybe<ResolversTypes['devices']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Devices_By_PkArgs, 'pk_columns'>>;
  update_devices_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['devices_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Devices_ManyArgs, 'updates'>>;
  update_dynamic_scaling?: Resolver<Maybe<ResolversTypes['dynamic_scaling_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Dynamic_ScalingArgs, 'where'>>;
  update_dynamic_scaling_by_pk?: Resolver<Maybe<ResolversTypes['dynamic_scaling']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Dynamic_Scaling_By_PkArgs, 'pk_columns'>>;
  update_dynamic_scaling_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['dynamic_scaling_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Dynamic_Scaling_ManyArgs, 'updates'>>;
  update_engine_opmode?: Resolver<Maybe<ResolversTypes['engine_opmode_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Engine_OpmodeArgs, 'where'>>;
  update_engine_opmode_by_pk?: Resolver<Maybe<ResolversTypes['engine_opmode']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Engine_Opmode_By_PkArgs, 'pk_columns'>>;
  update_engine_opmode_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['engine_opmode_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Engine_Opmode_ManyArgs, 'updates'>>;
  update_engine_opmode_recording?: Resolver<Maybe<ResolversTypes['engine_opmode_recording_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Engine_Opmode_RecordingArgs, 'where'>>;
  update_engine_opmode_recording_by_pk?: Resolver<Maybe<ResolversTypes['engine_opmode_recording']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Engine_Opmode_Recording_By_PkArgs, 'pk_columns'>>;
  update_engine_opmode_recording_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['engine_opmode_recording_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Engine_Opmode_Recording_ManyArgs, 'updates'>>;
  update_engine_types?: Resolver<Maybe<ResolversTypes['engine_types_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Engine_TypesArgs, 'where'>>;
  update_engine_types_by_pk?: Resolver<Maybe<ResolversTypes['engine_types']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Engine_Types_By_PkArgs, 'pk_columns'>>;
  update_engine_types_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['engine_types_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Engine_Types_ManyArgs, 'updates'>>;
  update_engines?: Resolver<Maybe<ResolversTypes['engines_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_EnginesArgs, 'where'>>;
  update_engines_by_pk?: Resolver<Maybe<ResolversTypes['engines']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Engines_By_PkArgs, 'pk_columns'>>;
  update_engines_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['engines_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Engines_ManyArgs, 'updates'>>;
  update_field_option_media?: Resolver<Maybe<ResolversTypes['field_option_media_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Field_Option_MediaArgs, 'where'>>;
  update_field_option_media_by_pk?: Resolver<Maybe<ResolversTypes['field_option_media']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Field_Option_Media_By_PkArgs, 'pk_columns'>>;
  update_field_option_media_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['field_option_media_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Field_Option_Media_ManyArgs, 'updates'>>;
  update_field_options?: Resolver<Maybe<ResolversTypes['field_options_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Field_OptionsArgs, 'where'>>;
  update_field_options_by_pk?: Resolver<Maybe<ResolversTypes['field_options']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Field_Options_By_PkArgs, 'pk_columns'>>;
  update_field_options_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['field_options_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Field_Options_ManyArgs, 'updates'>>;
  update_field_translation?: Resolver<Maybe<ResolversTypes['field_translation_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Field_TranslationArgs, 'where'>>;
  update_field_translation_by_pk?: Resolver<Maybe<ResolversTypes['field_translation']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Field_Translation_By_PkArgs, 'pk_columns'>>;
  update_field_translation_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['field_translation_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Field_Translation_ManyArgs, 'updates'>>;
  update_fields?: Resolver<Maybe<ResolversTypes['fields_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_FieldsArgs, 'where'>>;
  update_fields_by_pk?: Resolver<Maybe<ResolversTypes['fields']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Fields_By_PkArgs, 'pk_columns'>>;
  update_fields_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['fields_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Fields_ManyArgs, 'updates'>>;
  update_firmware?: Resolver<Maybe<ResolversTypes['firmware_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_FirmwareArgs, 'where'>>;
  update_firmware_by_pk?: Resolver<Maybe<ResolversTypes['firmware']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Firmware_By_PkArgs, 'pk_columns'>>;
  update_firmware_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['firmware_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Firmware_ManyArgs, 'updates'>>;
  update_ground_truth_configurations?: Resolver<Maybe<ResolversTypes['ground_truth_configurations_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Ground_Truth_ConfigurationsArgs, 'where'>>;
  update_ground_truth_configurations_by_pk?: Resolver<Maybe<ResolversTypes['ground_truth_configurations']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Ground_Truth_Configurations_By_PkArgs, 'pk_columns'>>;
  update_ground_truth_configurations_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['ground_truth_configurations_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Ground_Truth_Configurations_ManyArgs, 'updates'>>;
  update_ground_truth_device_types?: Resolver<Maybe<ResolversTypes['ground_truth_device_types_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Ground_Truth_Device_TypesArgs, 'where'>>;
  update_ground_truth_device_types_by_pk?: Resolver<Maybe<ResolversTypes['ground_truth_device_types']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Ground_Truth_Device_Types_By_PkArgs, 'pk_columns'>>;
  update_ground_truth_device_types_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['ground_truth_device_types_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Ground_Truth_Device_Types_ManyArgs, 'updates'>>;
  update_ground_truth_processing_modes?: Resolver<Maybe<ResolversTypes['ground_truth_processing_modes_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Ground_Truth_Processing_ModesArgs, 'where'>>;
  update_ground_truth_processing_modes_by_pk?: Resolver<Maybe<ResolversTypes['ground_truth_processing_modes']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Ground_Truth_Processing_Modes_By_PkArgs, 'pk_columns'>>;
  update_ground_truth_processing_modes_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['ground_truth_processing_modes_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Ground_Truth_Processing_Modes_ManyArgs, 'updates'>>;
  update_ground_truth_recordings?: Resolver<Maybe<ResolversTypes['ground_truth_recordings_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Ground_Truth_RecordingsArgs, 'where'>>;
  update_ground_truth_recordings_by_pk?: Resolver<Maybe<ResolversTypes['ground_truth_recordings']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Ground_Truth_Recordings_By_PkArgs, 'pk_columns'>>;
  update_ground_truth_recordings_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['ground_truth_recordings_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Ground_Truth_Recordings_ManyArgs, 'updates'>>;
  update_ground_truth_types?: Resolver<Maybe<ResolversTypes['ground_truth_types_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Ground_Truth_TypesArgs, 'where'>>;
  update_ground_truth_types_by_pk?: Resolver<Maybe<ResolversTypes['ground_truth_types']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Ground_Truth_Types_By_PkArgs, 'pk_columns'>>;
  update_ground_truth_types_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['ground_truth_types_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Ground_Truth_Types_ManyArgs, 'updates'>>;
  update_hypertask_field_option?: Resolver<Maybe<ResolversTypes['hypertask_field_option_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Hypertask_Field_OptionArgs, 'where'>>;
  update_hypertask_field_option_by_pk?: Resolver<Maybe<ResolversTypes['hypertask_field_option']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Hypertask_Field_Option_By_PkArgs, 'pk_columns'>>;
  update_hypertask_field_option_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['hypertask_field_option_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Hypertask_Field_Option_ManyArgs, 'updates'>>;
  update_hypertask_task?: Resolver<Maybe<ResolversTypes['hypertask_task_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Hypertask_TaskArgs, 'where'>>;
  update_hypertask_task_by_pk?: Resolver<Maybe<ResolversTypes['hypertask_task']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Hypertask_Task_By_PkArgs, 'pk_columns'>>;
  update_hypertask_task_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['hypertask_task_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Hypertask_Task_ManyArgs, 'updates'>>;
  update_hypertasks?: Resolver<Maybe<ResolversTypes['hypertasks_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_HypertasksArgs, 'where'>>;
  update_hypertasks_by_pk?: Resolver<Maybe<ResolversTypes['hypertasks']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Hypertasks_By_PkArgs, 'pk_columns'>>;
  update_hypertasks_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['hypertasks_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Hypertasks_ManyArgs, 'updates'>>;
  update_instruction_translations?: Resolver<Maybe<ResolversTypes['instruction_translations_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Instruction_TranslationsArgs, 'where'>>;
  update_instruction_translations_by_pk?: Resolver<Maybe<ResolversTypes['instruction_translations']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Instruction_Translations_By_PkArgs, 'pk_columns'>>;
  update_instruction_translations_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['instruction_translations_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Instruction_Translations_ManyArgs, 'updates'>>;
  update_integration_test_expectation_media?: Resolver<Maybe<ResolversTypes['integration_test_expectation_media_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Integration_Test_Expectation_MediaArgs, 'where'>>;
  update_integration_test_expectation_media_by_pk?: Resolver<Maybe<ResolversTypes['integration_test_expectation_media']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Integration_Test_Expectation_Media_By_PkArgs, 'pk_columns'>>;
  update_integration_test_expectation_media_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['integration_test_expectation_media_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Integration_Test_Expectation_Media_ManyArgs, 'updates'>>;
  update_integration_test_expectation_translation?: Resolver<Maybe<ResolversTypes['integration_test_expectation_translation_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Integration_Test_Expectation_TranslationArgs, 'where'>>;
  update_integration_test_expectation_translation_by_pk?: Resolver<Maybe<ResolversTypes['integration_test_expectation_translation']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Integration_Test_Expectation_Translation_By_PkArgs, 'pk_columns'>>;
  update_integration_test_expectation_translation_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['integration_test_expectation_translation_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Integration_Test_Expectation_Translation_ManyArgs, 'updates'>>;
  update_integration_test_expectations?: Resolver<Maybe<ResolversTypes['integration_test_expectations_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Integration_Test_ExpectationsArgs, 'where'>>;
  update_integration_test_expectations_by_pk?: Resolver<Maybe<ResolversTypes['integration_test_expectations']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Integration_Test_Expectations_By_PkArgs, 'pk_columns'>>;
  update_integration_test_expectations_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['integration_test_expectations_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Integration_Test_Expectations_ManyArgs, 'updates'>>;
  update_integration_test_instruction_media?: Resolver<Maybe<ResolversTypes['integration_test_instruction_media_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Integration_Test_Instruction_MediaArgs, 'where'>>;
  update_integration_test_instruction_media_by_pk?: Resolver<Maybe<ResolversTypes['integration_test_instruction_media']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Integration_Test_Instruction_Media_By_PkArgs, 'pk_columns'>>;
  update_integration_test_instruction_media_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['integration_test_instruction_media_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Integration_Test_Instruction_Media_ManyArgs, 'updates'>>;
  update_integration_test_instruction_translation?: Resolver<Maybe<ResolversTypes['integration_test_instruction_translation_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Integration_Test_Instruction_TranslationArgs, 'where'>>;
  update_integration_test_instruction_translation_by_pk?: Resolver<Maybe<ResolversTypes['integration_test_instruction_translation']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Integration_Test_Instruction_Translation_By_PkArgs, 'pk_columns'>>;
  update_integration_test_instruction_translation_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['integration_test_instruction_translation_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Integration_Test_Instruction_Translation_ManyArgs, 'updates'>>;
  update_integration_test_instructions?: Resolver<Maybe<ResolversTypes['integration_test_instructions_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Integration_Test_InstructionsArgs, 'where'>>;
  update_integration_test_instructions_by_pk?: Resolver<Maybe<ResolversTypes['integration_test_instructions']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Integration_Test_Instructions_By_PkArgs, 'pk_columns'>>;
  update_integration_test_instructions_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['integration_test_instructions_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Integration_Test_Instructions_ManyArgs, 'updates'>>;
  update_integration_test_observances?: Resolver<Maybe<ResolversTypes['integration_test_observances_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Integration_Test_ObservancesArgs, 'where'>>;
  update_integration_test_observances_by_pk?: Resolver<Maybe<ResolversTypes['integration_test_observances']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Integration_Test_Observances_By_PkArgs, 'pk_columns'>>;
  update_integration_test_observances_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['integration_test_observances_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Integration_Test_Observances_ManyArgs, 'updates'>>;
  update_integration_test_project_sequence?: Resolver<Maybe<ResolversTypes['integration_test_project_sequence_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Integration_Test_Project_SequenceArgs, 'where'>>;
  update_integration_test_project_sequence_by_pk?: Resolver<Maybe<ResolversTypes['integration_test_project_sequence']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Integration_Test_Project_Sequence_By_PkArgs, 'pk_columns'>>;
  update_integration_test_project_sequence_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['integration_test_project_sequence_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Integration_Test_Project_Sequence_ManyArgs, 'updates'>>;
  update_integration_test_sequences?: Resolver<Maybe<ResolversTypes['integration_test_sequences_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Integration_Test_SequencesArgs, 'where'>>;
  update_integration_test_sequences_by_pk?: Resolver<Maybe<ResolversTypes['integration_test_sequences']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Integration_Test_Sequences_By_PkArgs, 'pk_columns'>>;
  update_integration_test_sequences_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['integration_test_sequences_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Integration_Test_Sequences_ManyArgs, 'updates'>>;
  update_languages?: Resolver<Maybe<ResolversTypes['languages_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_LanguagesArgs, 'where'>>;
  update_languages_by_pk?: Resolver<Maybe<ResolversTypes['languages']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Languages_By_PkArgs, 'pk_columns'>>;
  update_languages_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['languages_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Languages_ManyArgs, 'updates'>>;
  update_machine_learning_blobs?: Resolver<Maybe<ResolversTypes['machine_learning_blobs_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Machine_Learning_BlobsArgs, 'where'>>;
  update_machine_learning_blobs_by_pk?: Resolver<Maybe<ResolversTypes['machine_learning_blobs']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Machine_Learning_Blobs_By_PkArgs, 'pk_columns'>>;
  update_machine_learning_blobs_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['machine_learning_blobs_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Machine_Learning_Blobs_ManyArgs, 'updates'>>;
  update_machine_learning_dataset_feature?: Resolver<Maybe<ResolversTypes['machine_learning_dataset_feature_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Machine_Learning_Dataset_FeatureArgs, 'where'>>;
  update_machine_learning_dataset_feature_by_pk?: Resolver<Maybe<ResolversTypes['machine_learning_dataset_feature']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Machine_Learning_Dataset_Feature_By_PkArgs, 'pk_columns'>>;
  update_machine_learning_dataset_feature_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['machine_learning_dataset_feature_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Machine_Learning_Dataset_Feature_ManyArgs, 'updates'>>;
  update_machine_learning_datasets?: Resolver<Maybe<ResolversTypes['machine_learning_datasets_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Machine_Learning_DatasetsArgs, 'where'>>;
  update_machine_learning_datasets_by_pk?: Resolver<Maybe<ResolversTypes['machine_learning_datasets']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Machine_Learning_Datasets_By_PkArgs, 'pk_columns'>>;
  update_machine_learning_datasets_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['machine_learning_datasets_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Machine_Learning_Datasets_ManyArgs, 'updates'>>;
  update_machine_learning_features?: Resolver<Maybe<ResolversTypes['machine_learning_features_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Machine_Learning_FeaturesArgs, 'where'>>;
  update_machine_learning_features_by_pk?: Resolver<Maybe<ResolversTypes['machine_learning_features']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Machine_Learning_Features_By_PkArgs, 'pk_columns'>>;
  update_machine_learning_features_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['machine_learning_features_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Machine_Learning_Features_ManyArgs, 'updates'>>;
  update_machine_learning_model_blob?: Resolver<Maybe<ResolversTypes['machine_learning_model_blob_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Machine_Learning_Model_BlobArgs, 'where'>>;
  update_machine_learning_model_blob_by_pk?: Resolver<Maybe<ResolversTypes['machine_learning_model_blob']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Machine_Learning_Model_Blob_By_PkArgs, 'pk_columns'>>;
  update_machine_learning_model_blob_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['machine_learning_model_blob_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Machine_Learning_Model_Blob_ManyArgs, 'updates'>>;
  update_machine_learning_model_dataset?: Resolver<Maybe<ResolversTypes['machine_learning_model_dataset_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Machine_Learning_Model_DatasetArgs, 'where'>>;
  update_machine_learning_model_dataset_by_pk?: Resolver<Maybe<ResolversTypes['machine_learning_model_dataset']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Machine_Learning_Model_Dataset_By_PkArgs, 'pk_columns'>>;
  update_machine_learning_model_dataset_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['machine_learning_model_dataset_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Machine_Learning_Model_Dataset_ManyArgs, 'updates'>>;
  update_machine_learning_model_recording?: Resolver<Maybe<ResolversTypes['machine_learning_model_recording_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Machine_Learning_Model_RecordingArgs, 'where'>>;
  update_machine_learning_model_recording_by_pk?: Resolver<Maybe<ResolversTypes['machine_learning_model_recording']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Machine_Learning_Model_Recording_By_PkArgs, 'pk_columns'>>;
  update_machine_learning_model_recording_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['machine_learning_model_recording_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Machine_Learning_Model_Recording_ManyArgs, 'updates'>>;
  update_machine_learning_models?: Resolver<Maybe<ResolversTypes['machine_learning_models_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Machine_Learning_ModelsArgs, 'where'>>;
  update_machine_learning_models_by_pk?: Resolver<Maybe<ResolversTypes['machine_learning_models']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Machine_Learning_Models_By_PkArgs, 'pk_columns'>>;
  update_machine_learning_models_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['machine_learning_models_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Machine_Learning_Models_ManyArgs, 'updates'>>;
  update_media?: Resolver<Maybe<ResolversTypes['media_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_MediaArgs, 'where'>>;
  update_media_by_pk?: Resolver<Maybe<ResolversTypes['media']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Media_By_PkArgs, 'pk_columns'>>;
  update_media_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['media_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Media_ManyArgs, 'updates'>>;
  update_mode_project?: Resolver<Maybe<ResolversTypes['mode_project_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Mode_ProjectArgs, 'where'>>;
  update_mode_project_by_pk?: Resolver<Maybe<ResolversTypes['mode_project']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Mode_Project_By_PkArgs, 'pk_columns'>>;
  update_mode_project_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['mode_project_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Mode_Project_ManyArgs, 'updates'>>;
  update_model_name_hashes?: Resolver<Maybe<ResolversTypes['model_name_hashes_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Model_Name_HashesArgs, 'where'>>;
  update_model_name_hashes_by_pk?: Resolver<Maybe<ResolversTypes['model_name_hashes']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Model_Name_Hashes_By_PkArgs, 'pk_columns'>>;
  update_model_name_hashes_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['model_name_hashes_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Model_Name_Hashes_ManyArgs, 'updates'>>;
  update_models?: Resolver<Maybe<ResolversTypes['models_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ModelsArgs, 'where'>>;
  update_models_by_pk?: Resolver<Maybe<ResolversTypes['models']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Models_By_PkArgs, 'pk_columns'>>;
  update_models_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['models_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Models_ManyArgs, 'updates'>>;
  update_opmode_profile?: Resolver<Maybe<ResolversTypes['opmode_profile_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Opmode_ProfileArgs, 'where'>>;
  update_opmode_profile_by_pk?: Resolver<Maybe<ResolversTypes['opmode_profile']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Opmode_Profile_By_PkArgs, 'pk_columns'>>;
  update_opmode_profile_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['opmode_profile_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Opmode_Profile_ManyArgs, 'updates'>>;
  update_opmode_project?: Resolver<Maybe<ResolversTypes['opmode_project_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Opmode_ProjectArgs, 'where'>>;
  update_opmode_project_by_pk?: Resolver<Maybe<ResolversTypes['opmode_project']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Opmode_Project_By_PkArgs, 'pk_columns'>>;
  update_opmode_project_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['opmode_project_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Opmode_Project_ManyArgs, 'updates'>>;
  update_opmodes?: Resolver<Maybe<ResolversTypes['opmodes_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_OpmodesArgs, 'where'>>;
  update_opmodes_by_pk?: Resolver<Maybe<ResolversTypes['opmodes']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Opmodes_By_PkArgs, 'pk_columns'>>;
  update_opmodes_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['opmodes_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Opmodes_ManyArgs, 'updates'>>;
  update_option_media?: Resolver<Maybe<ResolversTypes['option_media_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Option_MediaArgs, 'where'>>;
  update_option_media_by_pk?: Resolver<Maybe<ResolversTypes['option_media']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Option_Media_By_PkArgs, 'pk_columns'>>;
  update_option_media_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['option_media_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Option_Media_ManyArgs, 'updates'>>;
  update_option_translation?: Resolver<Maybe<ResolversTypes['option_translation_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Option_TranslationArgs, 'where'>>;
  update_option_translation_by_pk?: Resolver<Maybe<ResolversTypes['option_translation']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Option_Translation_By_PkArgs, 'pk_columns'>>;
  update_option_translation_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['option_translation_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Option_Translation_ManyArgs, 'updates'>>;
  update_options?: Resolver<Maybe<ResolversTypes['options_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_OptionsArgs, 'where'>>;
  update_options_by_pk?: Resolver<Maybe<ResolversTypes['options']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Options_By_PkArgs, 'pk_columns'>>;
  update_options_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['options_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Options_ManyArgs, 'updates'>>;
  update_payload_recording?: Resolver<Maybe<ResolversTypes['payload_recording_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Payload_RecordingArgs, 'where'>>;
  update_payload_recording_by_pk?: Resolver<Maybe<ResolversTypes['payload_recording']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Payload_Recording_By_PkArgs, 'pk_columns'>>;
  update_payload_recording_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['payload_recording_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Payload_Recording_ManyArgs, 'updates'>>;
  update_payload_types?: Resolver<Maybe<ResolversTypes['payload_types_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Payload_TypesArgs, 'where'>>;
  update_payload_types_by_pk?: Resolver<Maybe<ResolversTypes['payload_types']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Payload_Types_By_PkArgs, 'pk_columns'>>;
  update_payload_types_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['payload_types_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Payload_Types_ManyArgs, 'updates'>>;
  update_payloads?: Resolver<Maybe<ResolversTypes['payloads_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_PayloadsArgs, 'where'>>;
  update_payloads_by_pk?: Resolver<Maybe<ResolversTypes['payloads']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Payloads_By_PkArgs, 'pk_columns'>>;
  update_payloads_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['payloads_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Payloads_ManyArgs, 'updates'>>;
  update_performance_ratio_metric_contributions?: Resolver<Maybe<ResolversTypes['performance_ratio_metric_contributions_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Performance_Ratio_Metric_ContributionsArgs, 'where'>>;
  update_performance_ratio_metric_contributions_by_pk?: Resolver<Maybe<ResolversTypes['performance_ratio_metric_contributions']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Performance_Ratio_Metric_Contributions_By_PkArgs, 'pk_columns'>>;
  update_performance_ratio_metric_contributions_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['performance_ratio_metric_contributions_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Performance_Ratio_Metric_Contributions_ManyArgs, 'updates'>>;
  update_performance_ratio_metrics?: Resolver<Maybe<ResolversTypes['performance_ratio_metrics_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Performance_Ratio_MetricsArgs, 'where'>>;
  update_performance_ratio_metrics_by_pk?: Resolver<Maybe<ResolversTypes['performance_ratio_metrics']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Performance_Ratio_Metrics_By_PkArgs, 'pk_columns'>>;
  update_performance_ratio_metrics_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['performance_ratio_metrics_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Performance_Ratio_Metrics_ManyArgs, 'updates'>>;
  update_performance_report_deliverable_template?: Resolver<Maybe<ResolversTypes['performance_report_deliverable_template_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Performance_Report_Deliverable_TemplateArgs, 'where'>>;
  update_performance_report_deliverable_template_by_pk?: Resolver<Maybe<ResolversTypes['performance_report_deliverable_template']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Performance_Report_Deliverable_Template_By_PkArgs, 'pk_columns'>>;
  update_performance_report_deliverable_template_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['performance_report_deliverable_template_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Performance_Report_Deliverable_Template_ManyArgs, 'updates'>>;
  update_performance_report_pivot_types?: Resolver<Maybe<ResolversTypes['performance_report_pivot_types_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Performance_Report_Pivot_TypesArgs, 'where'>>;
  update_performance_report_pivot_types_by_pk?: Resolver<Maybe<ResolversTypes['performance_report_pivot_types']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Performance_Report_Pivot_Types_By_PkArgs, 'pk_columns'>>;
  update_performance_report_pivot_types_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['performance_report_pivot_types_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Performance_Report_Pivot_Types_ManyArgs, 'updates'>>;
  update_performance_report_section_pivot_field?: Resolver<Maybe<ResolversTypes['performance_report_section_pivot_field_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Performance_Report_Section_Pivot_FieldArgs, 'where'>>;
  update_performance_report_section_pivot_field_by_pk?: Resolver<Maybe<ResolversTypes['performance_report_section_pivot_field']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Performance_Report_Section_Pivot_Field_By_PkArgs, 'pk_columns'>>;
  update_performance_report_section_pivot_field_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['performance_report_section_pivot_field_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Performance_Report_Section_Pivot_Field_ManyArgs, 'updates'>>;
  update_performance_report_section_ratio_metric?: Resolver<Maybe<ResolversTypes['performance_report_section_ratio_metric_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Performance_Report_Section_Ratio_MetricArgs, 'where'>>;
  update_performance_report_section_ratio_metric_by_pk?: Resolver<Maybe<ResolversTypes['performance_report_section_ratio_metric']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Performance_Report_Section_Ratio_Metric_By_PkArgs, 'pk_columns'>>;
  update_performance_report_section_ratio_metric_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['performance_report_section_ratio_metric_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Performance_Report_Section_Ratio_Metric_ManyArgs, 'updates'>>;
  update_performance_report_section_task_blacklist?: Resolver<Maybe<ResolversTypes['performance_report_section_task_blacklist_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Performance_Report_Section_Task_BlacklistArgs, 'where'>>;
  update_performance_report_section_task_blacklist_by_pk?: Resolver<Maybe<ResolversTypes['performance_report_section_task_blacklist']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Performance_Report_Section_Task_Blacklist_By_PkArgs, 'pk_columns'>>;
  update_performance_report_section_task_blacklist_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['performance_report_section_task_blacklist_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Performance_Report_Section_Task_Blacklist_ManyArgs, 'updates'>>;
  update_performance_report_sections?: Resolver<Maybe<ResolversTypes['performance_report_sections_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Performance_Report_SectionsArgs, 'where'>>;
  update_performance_report_sections_by_pk?: Resolver<Maybe<ResolversTypes['performance_report_sections']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Performance_Report_Sections_By_PkArgs, 'pk_columns'>>;
  update_performance_report_sections_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['performance_report_sections_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Performance_Report_Sections_ManyArgs, 'updates'>>;
  update_performance_report_templates?: Resolver<Maybe<ResolversTypes['performance_report_templates_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Performance_Report_TemplatesArgs, 'where'>>;
  update_performance_report_templates_by_pk?: Resolver<Maybe<ResolversTypes['performance_report_templates']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Performance_Report_Templates_By_PkArgs, 'pk_columns'>>;
  update_performance_report_templates_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['performance_report_templates_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Performance_Report_Templates_ManyArgs, 'updates'>>;
  update_peripherals?: Resolver<Maybe<ResolversTypes['peripherals_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_PeripheralsArgs, 'where'>>;
  update_peripherals_by_pk?: Resolver<Maybe<ResolversTypes['peripherals']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Peripherals_By_PkArgs, 'pk_columns'>>;
  update_peripherals_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['peripherals_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Peripherals_ManyArgs, 'updates'>>;
  update_plan_hypertasks?: Resolver<Maybe<ResolversTypes['plan_hypertasks_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Plan_HypertasksArgs, 'where'>>;
  update_plan_hypertasks_by_pk?: Resolver<Maybe<ResolversTypes['plan_hypertasks']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Plan_Hypertasks_By_PkArgs, 'pk_columns'>>;
  update_plan_hypertasks_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['plan_hypertasks_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Plan_Hypertasks_ManyArgs, 'updates'>>;
  update_plan_tasks?: Resolver<Maybe<ResolversTypes['plan_tasks_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Plan_TasksArgs, 'where'>>;
  update_plan_tasks_by_pk?: Resolver<Maybe<ResolversTypes['plan_tasks']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Plan_Tasks_By_PkArgs, 'pk_columns'>>;
  update_plan_tasks_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['plan_tasks_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Plan_Tasks_ManyArgs, 'updates'>>;
  update_plans?: Resolver<Maybe<ResolversTypes['plans_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_PlansArgs, 'where'>>;
  update_plans_by_pk?: Resolver<Maybe<ResolversTypes['plans']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Plans_By_PkArgs, 'pk_columns'>>;
  update_plans_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['plans_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Plans_ManyArgs, 'updates'>>;
  update_profiles?: Resolver<Maybe<ResolversTypes['profiles_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ProfilesArgs, 'where'>>;
  update_profiles_by_pk?: Resolver<Maybe<ResolversTypes['profiles']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Profiles_By_PkArgs, 'pk_columns'>>;
  update_profiles_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['profiles_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Profiles_ManyArgs, 'updates'>>;
  update_project_combo?: Resolver<Maybe<ResolversTypes['project_combo_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Project_ComboArgs, 'where'>>;
  update_project_combo_by_pk?: Resolver<Maybe<ResolversTypes['project_combo']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Project_Combo_By_PkArgs, 'pk_columns'>>;
  update_project_combo_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['project_combo_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Project_Combo_ManyArgs, 'updates'>>;
  update_project_hypertasks?: Resolver<Maybe<ResolversTypes['project_hypertasks_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Project_HypertasksArgs, 'where'>>;
  update_project_hypertasks_by_pk?: Resolver<Maybe<ResolversTypes['project_hypertasks']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Project_Hypertasks_By_PkArgs, 'pk_columns'>>;
  update_project_hypertasks_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['project_hypertasks_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Project_Hypertasks_ManyArgs, 'updates'>>;
  update_project_tasks?: Resolver<Maybe<ResolversTypes['project_tasks_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Project_TasksArgs, 'where'>>;
  update_project_tasks_by_pk?: Resolver<Maybe<ResolversTypes['project_tasks']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Project_Tasks_By_PkArgs, 'pk_columns'>>;
  update_project_tasks_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['project_tasks_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Project_Tasks_ManyArgs, 'updates'>>;
  update_project_user?: Resolver<Maybe<ResolversTypes['project_user_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Project_UserArgs, 'where'>>;
  update_project_user_by_pk?: Resolver<Maybe<ResolversTypes['project_user']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Project_User_By_PkArgs, 'pk_columns'>>;
  update_project_user_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['project_user_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Project_User_ManyArgs, 'updates'>>;
  update_projects?: Resolver<Maybe<ResolversTypes['projects_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ProjectsArgs, 'where'>>;
  update_projects_by_pk?: Resolver<Maybe<ResolversTypes['projects']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Projects_By_PkArgs, 'pk_columns'>>;
  update_projects_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['projects_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Projects_ManyArgs, 'updates'>>;
  update_rabbit_test_plan_project?: Resolver<Maybe<ResolversTypes['rabbit_test_plan_project_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Rabbit_Test_Plan_ProjectArgs, 'where'>>;
  update_rabbit_test_plan_project_by_pk?: Resolver<Maybe<ResolversTypes['rabbit_test_plan_project']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Rabbit_Test_Plan_Project_By_PkArgs, 'pk_columns'>>;
  update_rabbit_test_plan_project_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['rabbit_test_plan_project_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Rabbit_Test_Plan_Project_ManyArgs, 'updates'>>;
  update_rabbit_test_plan_recording?: Resolver<Maybe<ResolversTypes['rabbit_test_plan_recording_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Rabbit_Test_Plan_RecordingArgs, 'where'>>;
  update_rabbit_test_plan_recording_by_pk?: Resolver<Maybe<ResolversTypes['rabbit_test_plan_recording']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Rabbit_Test_Plan_Recording_By_PkArgs, 'pk_columns'>>;
  update_rabbit_test_plan_recording_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['rabbit_test_plan_recording_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Rabbit_Test_Plan_Recording_ManyArgs, 'updates'>>;
  update_rabbit_test_plan_task?: Resolver<Maybe<ResolversTypes['rabbit_test_plan_task_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Rabbit_Test_Plan_TaskArgs, 'where'>>;
  update_rabbit_test_plan_task_by_pk?: Resolver<Maybe<ResolversTypes['rabbit_test_plan_task']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Rabbit_Test_Plan_Task_By_PkArgs, 'pk_columns'>>;
  update_rabbit_test_plan_task_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['rabbit_test_plan_task_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Rabbit_Test_Plan_Task_ManyArgs, 'updates'>>;
  update_rabbit_test_plans?: Resolver<Maybe<ResolversTypes['rabbit_test_plans_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Rabbit_Test_PlansArgs, 'where'>>;
  update_rabbit_test_plans_by_pk?: Resolver<Maybe<ResolversTypes['rabbit_test_plans']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Rabbit_Test_Plans_By_PkArgs, 'pk_columns'>>;
  update_rabbit_test_plans_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['rabbit_test_plans_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Rabbit_Test_Plans_ManyArgs, 'updates'>>;
  update_recording_apps?: Resolver<Maybe<ResolversTypes['recording_apps_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recording_AppsArgs, 'where'>>;
  update_recording_apps_by_pk?: Resolver<Maybe<ResolversTypes['recording_apps']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recording_Apps_By_PkArgs, 'pk_columns'>>;
  update_recording_apps_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['recording_apps_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recording_Apps_ManyArgs, 'updates'>>;
  update_recording_audio_driver?: Resolver<Maybe<ResolversTypes['recording_audio_driver_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recording_Audio_DriverArgs, 'where'>>;
  update_recording_audio_driver_by_pk?: Resolver<Maybe<ResolversTypes['recording_audio_driver']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recording_Audio_Driver_By_PkArgs, 'pk_columns'>>;
  update_recording_audio_driver_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['recording_audio_driver_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recording_Audio_Driver_ManyArgs, 'updates'>>;
  update_recording_data_ground_truth?: Resolver<Maybe<ResolversTypes['recording_data_ground_truth_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recording_Data_Ground_TruthArgs, 'where'>>;
  update_recording_data_ground_truth_by_pk?: Resolver<Maybe<ResolversTypes['recording_data_ground_truth']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recording_Data_Ground_Truth_By_PkArgs, 'pk_columns'>>;
  update_recording_data_ground_truth_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['recording_data_ground_truth_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recording_Data_Ground_Truth_ManyArgs, 'updates'>>;
  update_recording_data_labels?: Resolver<Maybe<ResolversTypes['recording_data_labels_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recording_Data_LabelsArgs, 'where'>>;
  update_recording_data_labels_by_pk?: Resolver<Maybe<ResolversTypes['recording_data_labels']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recording_Data_Labels_By_PkArgs, 'pk_columns'>>;
  update_recording_data_labels_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['recording_data_labels_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recording_Data_Labels_ManyArgs, 'updates'>>;
  update_recording_data_mode_parameter?: Resolver<Maybe<ResolversTypes['recording_data_mode_parameter_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recording_Data_Mode_ParameterArgs, 'where'>>;
  update_recording_data_mode_parameter_by_pk?: Resolver<Maybe<ResolversTypes['recording_data_mode_parameter']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recording_Data_Mode_Parameter_By_PkArgs, 'pk_columns'>>;
  update_recording_data_mode_parameter_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['recording_data_mode_parameter_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recording_Data_Mode_Parameter_ManyArgs, 'updates'>>;
  update_recording_data_modes?: Resolver<Maybe<ResolversTypes['recording_data_modes_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recording_Data_ModesArgs, 'where'>>;
  update_recording_data_modes_by_pk?: Resolver<Maybe<ResolversTypes['recording_data_modes']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recording_Data_Modes_By_PkArgs, 'pk_columns'>>;
  update_recording_data_modes_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['recording_data_modes_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recording_Data_Modes_ManyArgs, 'updates'>>;
  update_recording_data_parameters?: Resolver<Maybe<ResolversTypes['recording_data_parameters_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recording_Data_ParametersArgs, 'where'>>;
  update_recording_data_parameters_by_pk?: Resolver<Maybe<ResolversTypes['recording_data_parameters']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recording_Data_Parameters_By_PkArgs, 'pk_columns'>>;
  update_recording_data_parameters_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['recording_data_parameters_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recording_Data_Parameters_ManyArgs, 'updates'>>;
  update_recording_data_payload_pair?: Resolver<Maybe<ResolversTypes['recording_data_payload_pair_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recording_Data_Payload_PairArgs, 'where'>>;
  update_recording_data_payload_pair_by_pk?: Resolver<Maybe<ResolversTypes['recording_data_payload_pair']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recording_Data_Payload_Pair_By_PkArgs, 'pk_columns'>>;
  update_recording_data_payload_pair_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['recording_data_payload_pair_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recording_Data_Payload_Pair_ManyArgs, 'updates'>>;
  update_recording_data_predictions?: Resolver<Maybe<ResolversTypes['recording_data_predictions_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recording_Data_PredictionsArgs, 'where'>>;
  update_recording_data_predictions_by_pk?: Resolver<Maybe<ResolversTypes['recording_data_predictions']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recording_Data_Predictions_By_PkArgs, 'pk_columns'>>;
  update_recording_data_predictions_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['recording_data_predictions_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recording_Data_Predictions_ManyArgs, 'updates'>>;
  update_recording_recording_tag?: Resolver<Maybe<ResolversTypes['recording_recording_tag_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recording_Recording_TagArgs, 'where'>>;
  update_recording_recording_tag_by_pk?: Resolver<Maybe<ResolversTypes['recording_recording_tag']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recording_Recording_Tag_By_PkArgs, 'pk_columns'>>;
  update_recording_recording_tag_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['recording_recording_tag_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recording_Recording_Tag_ManyArgs, 'updates'>>;
  update_recording_tags?: Resolver<Maybe<ResolversTypes['recording_tags_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recording_TagsArgs, 'where'>>;
  update_recording_tags_by_pk?: Resolver<Maybe<ResolversTypes['recording_tags']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recording_Tags_By_PkArgs, 'pk_columns'>>;
  update_recording_tags_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['recording_tags_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recording_Tags_ManyArgs, 'updates'>>;
  update_recordings?: Resolver<Maybe<ResolversTypes['recordings_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_RecordingsArgs, 'where'>>;
  update_recordings_by_pk?: Resolver<Maybe<ResolversTypes['recordings']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recordings_By_PkArgs, 'pk_columns'>>;
  update_recordings_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['recordings_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Recordings_ManyArgs, 'updates'>>;
  update_role_user?: Resolver<Maybe<ResolversTypes['role_user_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Role_UserArgs, 'where'>>;
  update_role_user_by_pk?: Resolver<Maybe<ResolversTypes['role_user']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Role_User_By_PkArgs, 'pk_columns'>>;
  update_role_user_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['role_user_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Role_User_ManyArgs, 'updates'>>;
  update_roles?: Resolver<Maybe<ResolversTypes['roles_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_RolesArgs, 'where'>>;
  update_roles_by_pk?: Resolver<Maybe<ResolversTypes['roles']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Roles_By_PkArgs, 'pk_columns'>>;
  update_roles_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['roles_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Roles_ManyArgs, 'updates'>>;
  update_rooms?: Resolver<Maybe<ResolversTypes['rooms_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_RoomsArgs, 'where'>>;
  update_rooms_by_pk?: Resolver<Maybe<ResolversTypes['rooms']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Rooms_By_PkArgs, 'pk_columns'>>;
  update_rooms_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['rooms_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Rooms_ManyArgs, 'updates'>>;
  update_simulations_scores?: Resolver<Maybe<ResolversTypes['simulations_scores_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Simulations_ScoresArgs, 'where'>>;
  update_simulations_scores_by_pk?: Resolver<Maybe<ResolversTypes['simulations_scores']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Simulations_Scores_By_PkArgs, 'pk_columns'>>;
  update_simulations_scores_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['simulations_scores_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Simulations_Scores_ManyArgs, 'updates'>>;
  update_task_field_option?: Resolver<Maybe<ResolversTypes['task_field_option_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Task_Field_OptionArgs, 'where'>>;
  update_task_field_option_by_pk?: Resolver<Maybe<ResolversTypes['task_field_option']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Task_Field_Option_By_PkArgs, 'pk_columns'>>;
  update_task_field_option_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['task_field_option_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Task_Field_Option_ManyArgs, 'updates'>>;
  update_task_groups_group_fields?: Resolver<Maybe<ResolversTypes['task_groups_group_fields_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Task_Groups_Group_FieldsArgs, 'where'>>;
  update_task_groups_group_fields_by_pk?: Resolver<Maybe<ResolversTypes['task_groups_group_fields']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Task_Groups_Group_Fields_By_PkArgs, 'pk_columns'>>;
  update_task_groups_group_fields_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['task_groups_group_fields_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Task_Groups_Group_Fields_ManyArgs, 'updates'>>;
  update_task_groups_groups?: Resolver<Maybe<ResolversTypes['task_groups_groups_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Task_Groups_GroupsArgs, 'where'>>;
  update_task_groups_groups_by_pk?: Resolver<Maybe<ResolversTypes['task_groups_groups']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Task_Groups_Groups_By_PkArgs, 'pk_columns'>>;
  update_task_groups_groups_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['task_groups_groups_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Task_Groups_Groups_ManyArgs, 'updates'>>;
  update_task_groups_hierarchies?: Resolver<Maybe<ResolversTypes['task_groups_hierarchies_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Task_Groups_HierarchiesArgs, 'where'>>;
  update_task_groups_hierarchies_by_pk?: Resolver<Maybe<ResolversTypes['task_groups_hierarchies']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Task_Groups_Hierarchies_By_PkArgs, 'pk_columns'>>;
  update_task_groups_hierarchies_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['task_groups_hierarchies_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Task_Groups_Hierarchies_ManyArgs, 'updates'>>;
  update_task_groups_hierarchy_groups?: Resolver<Maybe<ResolversTypes['task_groups_hierarchy_groups_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Task_Groups_Hierarchy_GroupsArgs, 'where'>>;
  update_task_groups_hierarchy_groups_by_pk?: Resolver<Maybe<ResolversTypes['task_groups_hierarchy_groups']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Task_Groups_Hierarchy_Groups_By_PkArgs, 'pk_columns'>>;
  update_task_groups_hierarchy_groups_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['task_groups_hierarchy_groups_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Task_Groups_Hierarchy_Groups_ManyArgs, 'updates'>>;
  update_task_instructions?: Resolver<Maybe<ResolversTypes['task_instructions_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Task_InstructionsArgs, 'where'>>;
  update_task_instructions_by_pk?: Resolver<Maybe<ResolversTypes['task_instructions']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Task_Instructions_By_PkArgs, 'pk_columns'>>;
  update_task_instructions_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['task_instructions_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Task_Instructions_ManyArgs, 'updates'>>;
  update_task_media?: Resolver<Maybe<ResolversTypes['task_media_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Task_MediaArgs, 'where'>>;
  update_task_media_by_pk?: Resolver<Maybe<ResolversTypes['task_media']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Task_Media_By_PkArgs, 'pk_columns'>>;
  update_task_media_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['task_media_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Task_Media_ManyArgs, 'updates'>>;
  update_task_translation?: Resolver<Maybe<ResolversTypes['task_translation_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Task_TranslationArgs, 'where'>>;
  update_task_translation_by_pk?: Resolver<Maybe<ResolversTypes['task_translation']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Task_Translation_By_PkArgs, 'pk_columns'>>;
  update_task_translation_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['task_translation_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Task_Translation_ManyArgs, 'updates'>>;
  update_tasks?: Resolver<Maybe<ResolversTypes['tasks_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_TasksArgs, 'where'>>;
  update_tasks_by_pk?: Resolver<Maybe<ResolversTypes['tasks']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Tasks_By_PkArgs, 'pk_columns'>>;
  update_tasks_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['tasks_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Tasks_ManyArgs, 'updates'>>;
  update_tool_versions?: Resolver<Maybe<ResolversTypes['tool_versions_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Tool_VersionsArgs, 'where'>>;
  update_tool_versions_by_pk?: Resolver<Maybe<ResolversTypes['tool_versions']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Tool_Versions_By_PkArgs, 'pk_columns'>>;
  update_tool_versions_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['tool_versions_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Tool_Versions_ManyArgs, 'updates'>>;
  update_tools?: Resolver<Maybe<ResolversTypes['tools_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ToolsArgs, 'where'>>;
  update_tools_by_pk?: Resolver<Maybe<ResolversTypes['tools']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Tools_By_PkArgs, 'pk_columns'>>;
  update_tools_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['tools_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Tools_ManyArgs, 'updates'>>;
  update_types?: Resolver<Maybe<ResolversTypes['types_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_TypesArgs, 'where'>>;
  update_types_by_pk?: Resolver<Maybe<ResolversTypes['types']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Types_By_PkArgs, 'pk_columns'>>;
  update_types_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['types_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Types_ManyArgs, 'updates'>>;
  update_users?: Resolver<Maybe<ResolversTypes['users_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UsersArgs, 'where'>>;
  update_users_by_pk?: Resolver<Maybe<ResolversTypes['users']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Users_By_PkArgs, 'pk_columns'>>;
  update_users_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['users_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Users_ManyArgs, 'updates'>>;
  update_xtensa_configurations?: Resolver<Maybe<ResolversTypes['xtensa_configurations_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Xtensa_ConfigurationsArgs, 'where'>>;
  update_xtensa_configurations_by_pk?: Resolver<Maybe<ResolversTypes['xtensa_configurations']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Xtensa_Configurations_By_PkArgs, 'pk_columns'>>;
  update_xtensa_configurations_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['xtensa_configurations_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Xtensa_Configurations_ManyArgs, 'updates'>>;
  upsert_task?: Resolver<Array<ResolversTypes['schema_upsert_task']>, ParentType, ContextType, RequireFields<Mutation_RootUpsert_TaskArgs, 'args'>>;
};

export interface NumericScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['numeric'], any> {
  name: 'numeric';
}

export type Opmode_ProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['opmode_profile'] = ResolversParentTypes['opmode_profile']> = {
  opmode?: Resolver<ResolversTypes['opmodes'], ParentType, ContextType>;
  opmode_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  profile?: Resolver<ResolversTypes['profiles'], ParentType, ContextType>;
  profile_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Opmode_Profile_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['opmode_profile_mutation_response'] = ResolversParentTypes['opmode_profile_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['opmode_profile']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Opmode_ProjectResolvers<ContextType = any, ParentType extends ResolversParentTypes['opmode_project'] = ResolversParentTypes['opmode_project']> = {
  opmode?: Resolver<ResolversTypes['opmodes'], ParentType, ContextType>;
  opmode_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['projects'], ParentType, ContextType>;
  project_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Opmode_Project_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['opmode_project_aggregate'] = ResolversParentTypes['opmode_project_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['opmode_project_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['opmode_project']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Opmode_Project_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['opmode_project_aggregate_fields'] = ResolversParentTypes['opmode_project_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Opmode_Project_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['opmode_project_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['opmode_project_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Opmode_Project_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['opmode_project_max_fields'] = ResolversParentTypes['opmode_project_max_fields']> = {
  opmode_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Opmode_Project_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['opmode_project_min_fields'] = ResolversParentTypes['opmode_project_min_fields']> = {
  opmode_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Opmode_Project_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['opmode_project_mutation_response'] = ResolversParentTypes['opmode_project_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['opmode_project']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OpmodesResolvers<ContextType = any, ParentType extends ResolversParentTypes['opmodes'] = ResolversParentTypes['opmodes']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  engine_opmode_recordings?: Resolver<Array<ResolversTypes['engine_opmode_recording']>, ParentType, ContextType, RequireFields<OpmodesEngine_Opmode_RecordingsArgs, never>>;
  engine_opmode_recordings_aggregate?: Resolver<ResolversTypes['engine_opmode_recording_aggregate'], ParentType, ContextType, RequireFields<OpmodesEngine_Opmode_Recordings_AggregateArgs, never>>;
  engine_opmodes?: Resolver<Array<ResolversTypes['engine_opmode']>, ParentType, ContextType, RequireFields<OpmodesEngine_OpmodesArgs, never>>;
  engine_opmodes_aggregate?: Resolver<ResolversTypes['engine_opmode_aggregate'], ParentType, ContextType, RequireFields<OpmodesEngine_Opmodes_AggregateArgs, never>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  number?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  opmode_profiles?: Resolver<Array<ResolversTypes['opmode_profile']>, ParentType, ContextType, RequireFields<OpmodesOpmode_ProfilesArgs, never>>;
  opmode_projects?: Resolver<Array<ResolversTypes['opmode_project']>, ParentType, ContextType, RequireFields<OpmodesOpmode_ProjectsArgs, never>>;
  opmode_projects_aggregate?: Resolver<ResolversTypes['opmode_project_aggregate'], ParentType, ContextType, RequireFields<OpmodesOpmode_Projects_AggregateArgs, never>>;
  project?: Resolver<Maybe<ResolversTypes['projects']>, ParentType, ContextType>;
  uname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Opmodes_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['opmodes_aggregate'] = ResolversParentTypes['opmodes_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['opmodes_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['opmodes']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Opmodes_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['opmodes_aggregate_fields'] = ResolversParentTypes['opmodes_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['opmodes_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Opmodes_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['opmodes_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['opmodes_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['opmodes_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['opmodes_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['opmodes_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['opmodes_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['opmodes_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['opmodes_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['opmodes_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Opmodes_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['opmodes_avg_fields'] = ResolversParentTypes['opmodes_avg_fields']> = {
  number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Opmodes_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['opmodes_max_fields'] = ResolversParentTypes['opmodes_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  uname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Opmodes_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['opmodes_min_fields'] = ResolversParentTypes['opmodes_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  uname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Opmodes_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['opmodes_mutation_response'] = ResolversParentTypes['opmodes_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['opmodes']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Opmodes_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['opmodes_stddev_fields'] = ResolversParentTypes['opmodes_stddev_fields']> = {
  number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Opmodes_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['opmodes_stddev_pop_fields'] = ResolversParentTypes['opmodes_stddev_pop_fields']> = {
  number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Opmodes_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['opmodes_stddev_samp_fields'] = ResolversParentTypes['opmodes_stddev_samp_fields']> = {
  number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Opmodes_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['opmodes_sum_fields'] = ResolversParentTypes['opmodes_sum_fields']> = {
  number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Opmodes_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['opmodes_var_pop_fields'] = ResolversParentTypes['opmodes_var_pop_fields']> = {
  number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Opmodes_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['opmodes_var_samp_fields'] = ResolversParentTypes['opmodes_var_samp_fields']> = {
  number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Opmodes_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['opmodes_variance_fields'] = ResolversParentTypes['opmodes_variance_fields']> = {
  number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Option_MediaResolvers<ContextType = any, ParentType extends ResolversParentTypes['option_media'] = ResolversParentTypes['option_media']> = {
  media?: Resolver<ResolversTypes['media'], ParentType, ContextType>;
  media_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  option?: Resolver<ResolversTypes['options'], ParentType, ContextType>;
  option_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Option_Media_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['option_media_aggregate'] = ResolversParentTypes['option_media_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['option_media_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['option_media']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Option_Media_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['option_media_aggregate_fields'] = ResolversParentTypes['option_media_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Option_Media_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['option_media_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['option_media_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Option_Media_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['option_media_max_fields'] = ResolversParentTypes['option_media_max_fields']> = {
  media_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  option_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Option_Media_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['option_media_min_fields'] = ResolversParentTypes['option_media_min_fields']> = {
  media_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  option_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Option_Media_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['option_media_mutation_response'] = ResolversParentTypes['option_media_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['option_media']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Option_TranslationResolvers<ContextType = any, ParentType extends ResolversParentTypes['option_translation'] = ResolversParentTypes['option_translation']> = {
  display?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  language?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  option?: Resolver<ResolversTypes['options'], ParentType, ContextType>;
  option_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  option_translation_language?: Resolver<ResolversTypes['languages'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Option_Translation_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['option_translation_aggregate'] = ResolversParentTypes['option_translation_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['option_translation_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['option_translation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Option_Translation_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['option_translation_aggregate_fields'] = ResolversParentTypes['option_translation_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Option_Translation_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['option_translation_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['option_translation_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Option_Translation_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['option_translation_max_fields'] = ResolversParentTypes['option_translation_max_fields']> = {
  display?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  option_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Option_Translation_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['option_translation_min_fields'] = ResolversParentTypes['option_translation_min_fields']> = {
  display?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  option_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Option_Translation_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['option_translation_mutation_response'] = ResolversParentTypes['option_translation_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['option_translation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OptionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['options'] = ResolversParentTypes['options']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  display?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  field_options?: Resolver<Array<ResolversTypes['field_options']>, ParentType, ContextType, RequireFields<OptionsField_OptionsArgs, never>>;
  field_options_aggregate?: Resolver<ResolversTypes['field_options_aggregate'], ParentType, ContextType, RequireFields<OptionsField_Options_AggregateArgs, never>>;
  hypertask_field_options?: Resolver<Array<ResolversTypes['hypertask_field_option']>, ParentType, ContextType, RequireFields<OptionsHypertask_Field_OptionsArgs, never>>;
  hypertask_field_options_aggregate?: Resolver<ResolversTypes['hypertask_field_option_aggregate'], ParentType, ContextType, RequireFields<OptionsHypertask_Field_Options_AggregateArgs, never>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  option_media?: Resolver<Array<ResolversTypes['option_media']>, ParentType, ContextType, RequireFields<OptionsOption_MediaArgs, never>>;
  option_media_aggregate?: Resolver<ResolversTypes['option_media_aggregate'], ParentType, ContextType, RequireFields<OptionsOption_Media_AggregateArgs, never>>;
  translations?: Resolver<Array<ResolversTypes['option_translation']>, ParentType, ContextType, RequireFields<OptionsTranslationsArgs, never>>;
  translations_aggregate?: Resolver<ResolversTypes['option_translation_aggregate'], ParentType, ContextType, RequireFields<OptionsTranslations_AggregateArgs, never>>;
  type?: Resolver<ResolversTypes['types'], ParentType, ContextType>;
  type_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['users']>, ParentType, ContextType>;
  users?: Resolver<Array<ResolversTypes['users']>, ParentType, ContextType, RequireFields<OptionsUsersArgs, never>>;
  users_aggregate?: Resolver<ResolversTypes['users_aggregate'], ParentType, ContextType, RequireFields<OptionsUsers_AggregateArgs, never>>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Options_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['options_aggregate'] = ResolversParentTypes['options_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['options_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['options']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Options_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['options_aggregate_fields'] = ResolversParentTypes['options_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Options_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['options_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['options_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Options_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['options_max_fields'] = ResolversParentTypes['options_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  display?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Options_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['options_min_fields'] = ResolversParentTypes['options_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  display?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Options_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['options_mutation_response'] = ResolversParentTypes['options_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['options']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Payload_RecordingResolvers<ContextType = any, ParentType extends ResolversParentTypes['payload_recording'] = ResolversParentTypes['payload_recording']> = {
  payload?: Resolver<ResolversTypes['payloads'], ParentType, ContextType>;
  payload_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  recording?: Resolver<ResolversTypes['recordings'], ParentType, ContextType>;
  recording_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Payload_Recording_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['payload_recording_aggregate'] = ResolversParentTypes['payload_recording_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['payload_recording_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['payload_recording']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Payload_Recording_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['payload_recording_aggregate_fields'] = ResolversParentTypes['payload_recording_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Payload_Recording_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['payload_recording_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['payload_recording_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Payload_Recording_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['payload_recording_max_fields'] = ResolversParentTypes['payload_recording_max_fields']> = {
  payload_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  recording_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Payload_Recording_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['payload_recording_min_fields'] = ResolversParentTypes['payload_recording_min_fields']> = {
  payload_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  recording_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Payload_Recording_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['payload_recording_mutation_response'] = ResolversParentTypes['payload_recording_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['payload_recording']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Payload_TypesResolvers<ContextType = any, ParentType extends ResolversParentTypes['payload_types'] = ResolversParentTypes['payload_types']> = {
  comment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  payloads?: Resolver<Array<ResolversTypes['payloads']>, ParentType, ContextType, RequireFields<Payload_TypesPayloadsArgs, never>>;
  payloads_aggregate?: Resolver<ResolversTypes['payloads_aggregate'], ParentType, ContextType, RequireFields<Payload_TypesPayloads_AggregateArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Payload_Types_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['payload_types_aggregate'] = ResolversParentTypes['payload_types_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['payload_types_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['payload_types']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Payload_Types_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['payload_types_aggregate_fields'] = ResolversParentTypes['payload_types_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Payload_Types_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['payload_types_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['payload_types_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Payload_Types_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['payload_types_max_fields'] = ResolversParentTypes['payload_types_max_fields']> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Payload_Types_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['payload_types_min_fields'] = ResolversParentTypes['payload_types_min_fields']> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Payload_Types_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['payload_types_mutation_response'] = ResolversParentTypes['payload_types_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['payload_types']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadsResolvers<ContextType = any, ParentType extends ResolversParentTypes['payloads'] = ResolversParentTypes['payloads']> = {
  audio_measurements_payload_recordings?: Resolver<Array<ResolversTypes['audio_measurements_payload_recording']>, ParentType, ContextType, RequireFields<PayloadsAudio_Measurements_Payload_RecordingsArgs, never>>;
  audio_measurements_payload_recordings_aggregate?: Resolver<ResolversTypes['audio_measurements_payload_recording_aggregate'], ParentType, ContextType, RequireFields<PayloadsAudio_Measurements_Payload_Recordings_AggregateArgs, never>>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  filename?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  first_payload?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  hash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  last_payload?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  length?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  payload_recordings?: Resolver<Array<ResolversTypes['payload_recording']>, ParentType, ContextType, RequireFields<PayloadsPayload_RecordingsArgs, never>>;
  payload_recordings_aggregate?: Resolver<ResolversTypes['payload_recording_aggregate'], ParentType, ContextType, RequireFields<PayloadsPayload_Recordings_AggregateArgs, never>>;
  payload_type?: Resolver<Maybe<ResolversTypes['payload_types']>, ParentType, ContextType>;
  payload_type_id?: Resolver<Maybe<ResolversTypes['payload_types_enum']>, ParentType, ContextType>;
  storage_container?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  synced?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Payloads_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['payloads_aggregate'] = ResolversParentTypes['payloads_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['payloads_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['payloads']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Payloads_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['payloads_aggregate_fields'] = ResolversParentTypes['payloads_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['payloads_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Payloads_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['payloads_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['payloads_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['payloads_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['payloads_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['payloads_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['payloads_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['payloads_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['payloads_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['payloads_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Payloads_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['payloads_avg_fields'] = ResolversParentTypes['payloads_avg_fields']> = {
  first_payload?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  last_payload?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Payloads_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['payloads_max_fields'] = ResolversParentTypes['payloads_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  filename?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  first_payload?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  last_payload?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  storage_container?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Payloads_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['payloads_min_fields'] = ResolversParentTypes['payloads_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  filename?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  first_payload?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  last_payload?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  storage_container?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Payloads_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['payloads_mutation_response'] = ResolversParentTypes['payloads_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['payloads']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Payloads_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['payloads_stddev_fields'] = ResolversParentTypes['payloads_stddev_fields']> = {
  first_payload?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  last_payload?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Payloads_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['payloads_stddev_pop_fields'] = ResolversParentTypes['payloads_stddev_pop_fields']> = {
  first_payload?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  last_payload?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Payloads_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['payloads_stddev_samp_fields'] = ResolversParentTypes['payloads_stddev_samp_fields']> = {
  first_payload?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  last_payload?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Payloads_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['payloads_sum_fields'] = ResolversParentTypes['payloads_sum_fields']> = {
  first_payload?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  last_payload?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Payloads_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['payloads_var_pop_fields'] = ResolversParentTypes['payloads_var_pop_fields']> = {
  first_payload?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  last_payload?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Payloads_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['payloads_var_samp_fields'] = ResolversParentTypes['payloads_var_samp_fields']> = {
  first_payload?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  last_payload?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Payloads_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['payloads_variance_fields'] = ResolversParentTypes['payloads_variance_fields']> = {
  first_payload?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  last_payload?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Ratio_Metric_ContributionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_ratio_metric_contributions'] = ResolversParentTypes['performance_ratio_metric_contributions']> = {
  denominator?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  engine?: Resolver<ResolversTypes['engines'], ParentType, ContextType>;
  engine_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  num_samples?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  numerator?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  ratio_metric?: Resolver<ResolversTypes['performance_ratio_metrics'], ParentType, ContextType>;
  ratio_metric_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ratio_metric_source_version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recording?: Resolver<ResolversTypes['recordings'], ParentType, ContextType>;
  recording_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Ratio_Metric_Contributions_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_ratio_metric_contributions_aggregate'] = ResolversParentTypes['performance_ratio_metric_contributions_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['performance_ratio_metric_contributions_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['performance_ratio_metric_contributions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Ratio_Metric_Contributions_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_ratio_metric_contributions_aggregate_fields'] = ResolversParentTypes['performance_ratio_metric_contributions_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['performance_ratio_metric_contributions_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Performance_Ratio_Metric_Contributions_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['performance_ratio_metric_contributions_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['performance_ratio_metric_contributions_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['performance_ratio_metric_contributions_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['performance_ratio_metric_contributions_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['performance_ratio_metric_contributions_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['performance_ratio_metric_contributions_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['performance_ratio_metric_contributions_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['performance_ratio_metric_contributions_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['performance_ratio_metric_contributions_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Ratio_Metric_Contributions_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_ratio_metric_contributions_avg_fields'] = ResolversParentTypes['performance_ratio_metric_contributions_avg_fields']> = {
  denominator?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_samples?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numerator?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Ratio_Metric_Contributions_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_ratio_metric_contributions_max_fields'] = ResolversParentTypes['performance_ratio_metric_contributions_max_fields']> = {
  denominator?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  engine_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  num_samples?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  numerator?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  ratio_metric_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ratio_metric_source_version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recording_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Ratio_Metric_Contributions_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_ratio_metric_contributions_min_fields'] = ResolversParentTypes['performance_ratio_metric_contributions_min_fields']> = {
  denominator?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  engine_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  num_samples?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  numerator?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  ratio_metric_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ratio_metric_source_version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recording_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Ratio_Metric_Contributions_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_ratio_metric_contributions_mutation_response'] = ResolversParentTypes['performance_ratio_metric_contributions_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['performance_ratio_metric_contributions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Ratio_Metric_Contributions_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_ratio_metric_contributions_stddev_fields'] = ResolversParentTypes['performance_ratio_metric_contributions_stddev_fields']> = {
  denominator?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_samples?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numerator?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Ratio_Metric_Contributions_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_ratio_metric_contributions_stddev_pop_fields'] = ResolversParentTypes['performance_ratio_metric_contributions_stddev_pop_fields']> = {
  denominator?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_samples?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numerator?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Ratio_Metric_Contributions_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_ratio_metric_contributions_stddev_samp_fields'] = ResolversParentTypes['performance_ratio_metric_contributions_stddev_samp_fields']> = {
  denominator?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_samples?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numerator?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Ratio_Metric_Contributions_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_ratio_metric_contributions_sum_fields'] = ResolversParentTypes['performance_ratio_metric_contributions_sum_fields']> = {
  denominator?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  num_samples?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  numerator?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Ratio_Metric_Contributions_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_ratio_metric_contributions_var_pop_fields'] = ResolversParentTypes['performance_ratio_metric_contributions_var_pop_fields']> = {
  denominator?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_samples?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numerator?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Ratio_Metric_Contributions_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_ratio_metric_contributions_var_samp_fields'] = ResolversParentTypes['performance_ratio_metric_contributions_var_samp_fields']> = {
  denominator?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_samples?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numerator?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Ratio_Metric_Contributions_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_ratio_metric_contributions_variance_fields'] = ResolversParentTypes['performance_ratio_metric_contributions_variance_fields']> = {
  denominator?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_samples?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numerator?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Ratio_MetricsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_ratio_metrics'] = ResolversParentTypes['performance_ratio_metrics']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  critical_limit?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  denominator_unit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  display_as_percentage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  larger_is_better?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  min_num_samples?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numerator_unit?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sample_unit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  source_version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  warning_limit?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Ratio_Metrics_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_ratio_metrics_aggregate'] = ResolversParentTypes['performance_ratio_metrics_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['performance_ratio_metrics_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['performance_ratio_metrics']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Ratio_Metrics_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_ratio_metrics_aggregate_fields'] = ResolversParentTypes['performance_ratio_metrics_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['performance_ratio_metrics_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Performance_Ratio_Metrics_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['performance_ratio_metrics_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['performance_ratio_metrics_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['performance_ratio_metrics_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['performance_ratio_metrics_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['performance_ratio_metrics_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['performance_ratio_metrics_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['performance_ratio_metrics_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['performance_ratio_metrics_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['performance_ratio_metrics_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Ratio_Metrics_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_ratio_metrics_avg_fields'] = ResolversParentTypes['performance_ratio_metrics_avg_fields']> = {
  critical_limit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_num_samples?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  warning_limit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Ratio_Metrics_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_ratio_metrics_max_fields'] = ResolversParentTypes['performance_ratio_metrics_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  critical_limit?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  denominator_unit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  min_num_samples?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  numerator_unit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sample_unit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  source_version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  warning_limit?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Ratio_Metrics_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_ratio_metrics_min_fields'] = ResolversParentTypes['performance_ratio_metrics_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  critical_limit?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  denominator_unit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  min_num_samples?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  numerator_unit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sample_unit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  source_version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  warning_limit?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Ratio_Metrics_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_ratio_metrics_mutation_response'] = ResolversParentTypes['performance_ratio_metrics_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['performance_ratio_metrics']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Ratio_Metrics_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_ratio_metrics_stddev_fields'] = ResolversParentTypes['performance_ratio_metrics_stddev_fields']> = {
  critical_limit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_num_samples?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  warning_limit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Ratio_Metrics_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_ratio_metrics_stddev_pop_fields'] = ResolversParentTypes['performance_ratio_metrics_stddev_pop_fields']> = {
  critical_limit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_num_samples?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  warning_limit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Ratio_Metrics_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_ratio_metrics_stddev_samp_fields'] = ResolversParentTypes['performance_ratio_metrics_stddev_samp_fields']> = {
  critical_limit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_num_samples?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  warning_limit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Ratio_Metrics_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_ratio_metrics_sum_fields'] = ResolversParentTypes['performance_ratio_metrics_sum_fields']> = {
  critical_limit?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  min_num_samples?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  warning_limit?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Ratio_Metrics_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_ratio_metrics_var_pop_fields'] = ResolversParentTypes['performance_ratio_metrics_var_pop_fields']> = {
  critical_limit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_num_samples?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  warning_limit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Ratio_Metrics_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_ratio_metrics_var_samp_fields'] = ResolversParentTypes['performance_ratio_metrics_var_samp_fields']> = {
  critical_limit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_num_samples?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  warning_limit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Ratio_Metrics_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_ratio_metrics_variance_fields'] = ResolversParentTypes['performance_ratio_metrics_variance_fields']> = {
  critical_limit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_num_samples?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  warning_limit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Deliverable_TemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_deliverable_template'] = ResolversParentTypes['performance_report_deliverable_template']> = {
  deliverable?: Resolver<ResolversTypes['deliverables'], ParentType, ContextType>;
  deliverable_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['smallint'], ParentType, ContextType>;
  template?: Resolver<ResolversTypes['performance_report_templates'], ParentType, ContextType>;
  template_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Deliverable_Template_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_deliverable_template_aggregate'] = ResolversParentTypes['performance_report_deliverable_template_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['performance_report_deliverable_template_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['performance_report_deliverable_template']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Deliverable_Template_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_deliverable_template_aggregate_fields'] = ResolversParentTypes['performance_report_deliverable_template_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['performance_report_deliverable_template_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Performance_Report_Deliverable_Template_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['performance_report_deliverable_template_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['performance_report_deliverable_template_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['performance_report_deliverable_template_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['performance_report_deliverable_template_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['performance_report_deliverable_template_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['performance_report_deliverable_template_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['performance_report_deliverable_template_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['performance_report_deliverable_template_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['performance_report_deliverable_template_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Deliverable_Template_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_deliverable_template_avg_fields'] = ResolversParentTypes['performance_report_deliverable_template_avg_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Deliverable_Template_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_deliverable_template_max_fields'] = ResolversParentTypes['performance_report_deliverable_template_max_fields']> = {
  deliverable_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  template_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Deliverable_Template_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_deliverable_template_min_fields'] = ResolversParentTypes['performance_report_deliverable_template_min_fields']> = {
  deliverable_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  template_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Deliverable_Template_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_deliverable_template_mutation_response'] = ResolversParentTypes['performance_report_deliverable_template_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['performance_report_deliverable_template']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Deliverable_Template_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_deliverable_template_stddev_fields'] = ResolversParentTypes['performance_report_deliverable_template_stddev_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Deliverable_Template_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_deliverable_template_stddev_pop_fields'] = ResolversParentTypes['performance_report_deliverable_template_stddev_pop_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Deliverable_Template_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_deliverable_template_stddev_samp_fields'] = ResolversParentTypes['performance_report_deliverable_template_stddev_samp_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Deliverable_Template_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_deliverable_template_sum_fields'] = ResolversParentTypes['performance_report_deliverable_template_sum_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Deliverable_Template_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_deliverable_template_var_pop_fields'] = ResolversParentTypes['performance_report_deliverable_template_var_pop_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Deliverable_Template_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_deliverable_template_var_samp_fields'] = ResolversParentTypes['performance_report_deliverable_template_var_samp_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Deliverable_Template_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_deliverable_template_variance_fields'] = ResolversParentTypes['performance_report_deliverable_template_variance_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Pivot_TypesResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_pivot_types'] = ResolversParentTypes['performance_report_pivot_types']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Pivot_Types_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_pivot_types_aggregate'] = ResolversParentTypes['performance_report_pivot_types_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['performance_report_pivot_types_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['performance_report_pivot_types']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Pivot_Types_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_pivot_types_aggregate_fields'] = ResolversParentTypes['performance_report_pivot_types_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Performance_Report_Pivot_Types_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['performance_report_pivot_types_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['performance_report_pivot_types_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Pivot_Types_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_pivot_types_max_fields'] = ResolversParentTypes['performance_report_pivot_types_max_fields']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Pivot_Types_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_pivot_types_min_fields'] = ResolversParentTypes['performance_report_pivot_types_min_fields']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Pivot_Types_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_pivot_types_mutation_response'] = ResolversParentTypes['performance_report_pivot_types_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['performance_report_pivot_types']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Pivot_FieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_pivot_field'] = ResolversParentTypes['performance_report_section_pivot_field']> = {
  field?: Resolver<ResolversTypes['fields'], ParentType, ContextType>;
  field_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  position?: Resolver<ResolversTypes['smallint'], ParentType, ContextType>;
  section?: Resolver<ResolversTypes['performance_report_sections'], ParentType, ContextType>;
  section_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Pivot_Field_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_pivot_field_aggregate'] = ResolversParentTypes['performance_report_section_pivot_field_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['performance_report_section_pivot_field_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['performance_report_section_pivot_field']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Pivot_Field_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_pivot_field_aggregate_fields'] = ResolversParentTypes['performance_report_section_pivot_field_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['performance_report_section_pivot_field_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Performance_Report_Section_Pivot_Field_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['performance_report_section_pivot_field_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['performance_report_section_pivot_field_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['performance_report_section_pivot_field_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['performance_report_section_pivot_field_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['performance_report_section_pivot_field_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['performance_report_section_pivot_field_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['performance_report_section_pivot_field_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['performance_report_section_pivot_field_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['performance_report_section_pivot_field_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Pivot_Field_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_pivot_field_avg_fields'] = ResolversParentTypes['performance_report_section_pivot_field_avg_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Pivot_Field_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_pivot_field_max_fields'] = ResolversParentTypes['performance_report_section_pivot_field_max_fields']> = {
  field_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  section_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Pivot_Field_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_pivot_field_min_fields'] = ResolversParentTypes['performance_report_section_pivot_field_min_fields']> = {
  field_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  section_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Pivot_Field_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_pivot_field_mutation_response'] = ResolversParentTypes['performance_report_section_pivot_field_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['performance_report_section_pivot_field']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Pivot_Field_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_pivot_field_stddev_fields'] = ResolversParentTypes['performance_report_section_pivot_field_stddev_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Pivot_Field_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_pivot_field_stddev_pop_fields'] = ResolversParentTypes['performance_report_section_pivot_field_stddev_pop_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Pivot_Field_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_pivot_field_stddev_samp_fields'] = ResolversParentTypes['performance_report_section_pivot_field_stddev_samp_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Pivot_Field_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_pivot_field_sum_fields'] = ResolversParentTypes['performance_report_section_pivot_field_sum_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Pivot_Field_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_pivot_field_var_pop_fields'] = ResolversParentTypes['performance_report_section_pivot_field_var_pop_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Pivot_Field_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_pivot_field_var_samp_fields'] = ResolversParentTypes['performance_report_section_pivot_field_var_samp_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Pivot_Field_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_pivot_field_variance_fields'] = ResolversParentTypes['performance_report_section_pivot_field_variance_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Ratio_MetricResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_ratio_metric'] = ResolversParentTypes['performance_report_section_ratio_metric']> = {
  position?: Resolver<ResolversTypes['smallint'], ParentType, ContextType>;
  ratio_metric?: Resolver<ResolversTypes['performance_ratio_metrics'], ParentType, ContextType>;
  ratio_metric_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  section?: Resolver<ResolversTypes['performance_report_sections'], ParentType, ContextType>;
  section_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Ratio_Metric_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_ratio_metric_aggregate'] = ResolversParentTypes['performance_report_section_ratio_metric_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['performance_report_section_ratio_metric_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['performance_report_section_ratio_metric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Ratio_Metric_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_ratio_metric_aggregate_fields'] = ResolversParentTypes['performance_report_section_ratio_metric_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['performance_report_section_ratio_metric_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Performance_Report_Section_Ratio_Metric_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['performance_report_section_ratio_metric_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['performance_report_section_ratio_metric_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['performance_report_section_ratio_metric_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['performance_report_section_ratio_metric_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['performance_report_section_ratio_metric_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['performance_report_section_ratio_metric_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['performance_report_section_ratio_metric_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['performance_report_section_ratio_metric_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['performance_report_section_ratio_metric_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Ratio_Metric_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_ratio_metric_avg_fields'] = ResolversParentTypes['performance_report_section_ratio_metric_avg_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Ratio_Metric_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_ratio_metric_max_fields'] = ResolversParentTypes['performance_report_section_ratio_metric_max_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  ratio_metric_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  section_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Ratio_Metric_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_ratio_metric_min_fields'] = ResolversParentTypes['performance_report_section_ratio_metric_min_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  ratio_metric_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  section_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Ratio_Metric_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_ratio_metric_mutation_response'] = ResolversParentTypes['performance_report_section_ratio_metric_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['performance_report_section_ratio_metric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Ratio_Metric_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_ratio_metric_stddev_fields'] = ResolversParentTypes['performance_report_section_ratio_metric_stddev_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Ratio_Metric_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_ratio_metric_stddev_pop_fields'] = ResolversParentTypes['performance_report_section_ratio_metric_stddev_pop_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Ratio_Metric_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_ratio_metric_stddev_samp_fields'] = ResolversParentTypes['performance_report_section_ratio_metric_stddev_samp_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Ratio_Metric_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_ratio_metric_sum_fields'] = ResolversParentTypes['performance_report_section_ratio_metric_sum_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Ratio_Metric_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_ratio_metric_var_pop_fields'] = ResolversParentTypes['performance_report_section_ratio_metric_var_pop_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Ratio_Metric_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_ratio_metric_var_samp_fields'] = ResolversParentTypes['performance_report_section_ratio_metric_var_samp_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Ratio_Metric_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_ratio_metric_variance_fields'] = ResolversParentTypes['performance_report_section_ratio_metric_variance_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Task_BlacklistResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_task_blacklist'] = ResolversParentTypes['performance_report_section_task_blacklist']> = {
  section?: Resolver<ResolversTypes['performance_report_sections'], ParentType, ContextType>;
  section_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  task?: Resolver<ResolversTypes['tasks'], ParentType, ContextType>;
  task_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Task_Blacklist_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_task_blacklist_aggregate'] = ResolversParentTypes['performance_report_section_task_blacklist_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['performance_report_section_task_blacklist_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['performance_report_section_task_blacklist']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Task_Blacklist_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_task_blacklist_aggregate_fields'] = ResolversParentTypes['performance_report_section_task_blacklist_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Performance_Report_Section_Task_Blacklist_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['performance_report_section_task_blacklist_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['performance_report_section_task_blacklist_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Task_Blacklist_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_task_blacklist_max_fields'] = ResolversParentTypes['performance_report_section_task_blacklist_max_fields']> = {
  section_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Task_Blacklist_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_task_blacklist_min_fields'] = ResolversParentTypes['performance_report_section_task_blacklist_min_fields']> = {
  section_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Section_Task_Blacklist_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_section_task_blacklist_mutation_response'] = ResolversParentTypes['performance_report_section_task_blacklist_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['performance_report_section_task_blacklist']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_SectionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_sections'] = ResolversParentTypes['performance_report_sections']> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  include_missing_pivot_values?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  pivot_fields?: Resolver<Array<ResolversTypes['performance_report_section_pivot_field']>, ParentType, ContextType, RequireFields<Performance_Report_SectionsPivot_FieldsArgs, never>>;
  pivot_fields_aggregate?: Resolver<ResolversTypes['performance_report_section_pivot_field_aggregate'], ParentType, ContextType, RequireFields<Performance_Report_SectionsPivot_Fields_AggregateArgs, never>>;
  pivot_type?: Resolver<ResolversTypes['performance_report_pivot_types'], ParentType, ContextType>;
  pivot_type_id?: Resolver<ResolversTypes['performance_report_pivot_types_enum'], ParentType, ContextType>;
  position?: Resolver<ResolversTypes['smallint'], ParentType, ContextType>;
  section_ratio_metrics?: Resolver<Array<ResolversTypes['performance_report_section_ratio_metric']>, ParentType, ContextType, RequireFields<Performance_Report_SectionsSection_Ratio_MetricsArgs, never>>;
  section_ratio_metrics_aggregate?: Resolver<ResolversTypes['performance_report_section_ratio_metric_aggregate'], ParentType, ContextType, RequireFields<Performance_Report_SectionsSection_Ratio_Metrics_AggregateArgs, never>>;
  section_task_blacklists?: Resolver<Array<ResolversTypes['performance_report_section_task_blacklist']>, ParentType, ContextType, RequireFields<Performance_Report_SectionsSection_Task_BlacklistsArgs, never>>;
  section_task_blacklists_aggregate?: Resolver<ResolversTypes['performance_report_section_task_blacklist_aggregate'], ParentType, ContextType, RequireFields<Performance_Report_SectionsSection_Task_Blacklists_AggregateArgs, never>>;
  template?: Resolver<ResolversTypes['performance_report_templates'], ParentType, ContextType>;
  template_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Sections_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_sections_aggregate'] = ResolversParentTypes['performance_report_sections_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['performance_report_sections_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['performance_report_sections']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Sections_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_sections_aggregate_fields'] = ResolversParentTypes['performance_report_sections_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['performance_report_sections_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Performance_Report_Sections_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['performance_report_sections_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['performance_report_sections_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['performance_report_sections_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['performance_report_sections_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['performance_report_sections_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['performance_report_sections_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['performance_report_sections_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['performance_report_sections_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['performance_report_sections_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Sections_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_sections_avg_fields'] = ResolversParentTypes['performance_report_sections_avg_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Sections_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_sections_max_fields'] = ResolversParentTypes['performance_report_sections_max_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  template_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Sections_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_sections_min_fields'] = ResolversParentTypes['performance_report_sections_min_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  template_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Sections_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_sections_mutation_response'] = ResolversParentTypes['performance_report_sections_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['performance_report_sections']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Sections_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_sections_stddev_fields'] = ResolversParentTypes['performance_report_sections_stddev_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Sections_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_sections_stddev_pop_fields'] = ResolversParentTypes['performance_report_sections_stddev_pop_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Sections_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_sections_stddev_samp_fields'] = ResolversParentTypes['performance_report_sections_stddev_samp_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Sections_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_sections_sum_fields'] = ResolversParentTypes['performance_report_sections_sum_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Sections_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_sections_var_pop_fields'] = ResolversParentTypes['performance_report_sections_var_pop_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Sections_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_sections_var_samp_fields'] = ResolversParentTypes['performance_report_sections_var_samp_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Sections_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_sections_variance_fields'] = ResolversParentTypes['performance_report_sections_variance_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_TemplatesResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_templates'] = ResolversParentTypes['performance_report_templates']> = {
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deliverable_templates?: Resolver<Array<ResolversTypes['performance_report_deliverable_template']>, ParentType, ContextType, RequireFields<Performance_Report_TemplatesDeliverable_TemplatesArgs, never>>;
  deliverable_templates_aggregate?: Resolver<ResolversTypes['performance_report_deliverable_template_aggregate'], ParentType, ContextType, RequireFields<Performance_Report_TemplatesDeliverable_Templates_AggregateArgs, never>>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sections?: Resolver<Array<ResolversTypes['performance_report_sections']>, ParentType, ContextType, RequireFields<Performance_Report_TemplatesSectionsArgs, never>>;
  sections_aggregate?: Resolver<ResolversTypes['performance_report_sections_aggregate'], ParentType, ContextType, RequireFields<Performance_Report_TemplatesSections_AggregateArgs, never>>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['users']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Templates_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_templates_aggregate'] = ResolversParentTypes['performance_report_templates_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['performance_report_templates_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['performance_report_templates']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Templates_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_templates_aggregate_fields'] = ResolversParentTypes['performance_report_templates_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Performance_Report_Templates_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['performance_report_templates_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['performance_report_templates_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Templates_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_templates_max_fields'] = ResolversParentTypes['performance_report_templates_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Templates_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_templates_min_fields'] = ResolversParentTypes['performance_report_templates_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Performance_Report_Templates_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['performance_report_templates_mutation_response'] = ResolversParentTypes['performance_report_templates_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['performance_report_templates']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PeripheralsResolvers<ContextType = any, ParentType extends ResolversParentTypes['peripherals'] = ResolversParentTypes['peripherals']> = {
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  model?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recordings?: Resolver<Array<ResolversTypes['recordings']>, ParentType, ContextType, RequireFields<PeripheralsRecordingsArgs, never>>;
  recordings_aggregate?: Resolver<ResolversTypes['recordings_aggregate'], ParentType, ContextType, RequireFields<PeripheralsRecordings_AggregateArgs, never>>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Peripherals_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['peripherals_mutation_response'] = ResolversParentTypes['peripherals_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['peripherals']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_HypertasksResolvers<ContextType = any, ParentType extends ResolversParentTypes['plan_hypertasks'] = ResolversParentTypes['plan_hypertasks']> = {
  hypertask?: Resolver<ResolversTypes['hypertasks'], ParentType, ContextType>;
  hypertask_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  min_per_device?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  plan?: Resolver<ResolversTypes['plans'], ParentType, ContextType>;
  plan_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Hypertasks_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['plan_hypertasks_aggregate'] = ResolversParentTypes['plan_hypertasks_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['plan_hypertasks_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['plan_hypertasks']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Hypertasks_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['plan_hypertasks_aggregate_fields'] = ResolversParentTypes['plan_hypertasks_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['plan_hypertasks_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Plan_Hypertasks_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['plan_hypertasks_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['plan_hypertasks_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['plan_hypertasks_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['plan_hypertasks_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['plan_hypertasks_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['plan_hypertasks_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['plan_hypertasks_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['plan_hypertasks_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['plan_hypertasks_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Hypertasks_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['plan_hypertasks_avg_fields'] = ResolversParentTypes['plan_hypertasks_avg_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Hypertasks_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['plan_hypertasks_max_fields'] = ResolversParentTypes['plan_hypertasks_max_fields']> = {
  hypertask_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  min_per_device?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Hypertasks_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['plan_hypertasks_min_fields'] = ResolversParentTypes['plan_hypertasks_min_fields']> = {
  hypertask_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  min_per_device?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Hypertasks_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['plan_hypertasks_mutation_response'] = ResolversParentTypes['plan_hypertasks_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['plan_hypertasks']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Hypertasks_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['plan_hypertasks_stddev_fields'] = ResolversParentTypes['plan_hypertasks_stddev_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Hypertasks_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['plan_hypertasks_stddev_pop_fields'] = ResolversParentTypes['plan_hypertasks_stddev_pop_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Hypertasks_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['plan_hypertasks_stddev_samp_fields'] = ResolversParentTypes['plan_hypertasks_stddev_samp_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Hypertasks_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['plan_hypertasks_sum_fields'] = ResolversParentTypes['plan_hypertasks_sum_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Hypertasks_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['plan_hypertasks_var_pop_fields'] = ResolversParentTypes['plan_hypertasks_var_pop_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Hypertasks_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['plan_hypertasks_var_samp_fields'] = ResolversParentTypes['plan_hypertasks_var_samp_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Hypertasks_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['plan_hypertasks_variance_fields'] = ResolversParentTypes['plan_hypertasks_variance_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_TasksResolvers<ContextType = any, ParentType extends ResolversParentTypes['plan_tasks'] = ResolversParentTypes['plan_tasks']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  min_per_device?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  plan?: Resolver<ResolversTypes['plans'], ParentType, ContextType>;
  plan_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  task?: Resolver<ResolversTypes['tasks'], ParentType, ContextType>;
  task_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Tasks_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['plan_tasks_aggregate'] = ResolversParentTypes['plan_tasks_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['plan_tasks_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['plan_tasks']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Tasks_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['plan_tasks_aggregate_fields'] = ResolversParentTypes['plan_tasks_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['plan_tasks_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Plan_Tasks_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['plan_tasks_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['plan_tasks_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['plan_tasks_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['plan_tasks_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['plan_tasks_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['plan_tasks_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['plan_tasks_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['plan_tasks_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['plan_tasks_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Tasks_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['plan_tasks_avg_fields'] = ResolversParentTypes['plan_tasks_avg_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Tasks_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['plan_tasks_max_fields'] = ResolversParentTypes['plan_tasks_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  min_per_device?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Tasks_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['plan_tasks_min_fields'] = ResolversParentTypes['plan_tasks_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  min_per_device?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Tasks_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['plan_tasks_mutation_response'] = ResolversParentTypes['plan_tasks_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['plan_tasks']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Tasks_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['plan_tasks_stddev_fields'] = ResolversParentTypes['plan_tasks_stddev_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Tasks_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['plan_tasks_stddev_pop_fields'] = ResolversParentTypes['plan_tasks_stddev_pop_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Tasks_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['plan_tasks_stddev_samp_fields'] = ResolversParentTypes['plan_tasks_stddev_samp_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Tasks_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['plan_tasks_sum_fields'] = ResolversParentTypes['plan_tasks_sum_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Tasks_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['plan_tasks_var_pop_fields'] = ResolversParentTypes['plan_tasks_var_pop_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Tasks_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['plan_tasks_var_samp_fields'] = ResolversParentTypes['plan_tasks_var_samp_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Tasks_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['plan_tasks_variance_fields'] = ResolversParentTypes['plan_tasks_variance_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlansResolvers<ContextType = any, ParentType extends ResolversParentTypes['plans'] = ResolversParentTypes['plans']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  plan_hypertasks?: Resolver<Array<ResolversTypes['plan_hypertasks']>, ParentType, ContextType, RequireFields<PlansPlan_HypertasksArgs, never>>;
  plan_hypertasks_aggregate?: Resolver<ResolversTypes['plan_hypertasks_aggregate'], ParentType, ContextType, RequireFields<PlansPlan_Hypertasks_AggregateArgs, never>>;
  plan_tasks?: Resolver<Array<ResolversTypes['plan_tasks']>, ParentType, ContextType, RequireFields<PlansPlan_TasksArgs, never>>;
  plan_tasks_aggregate?: Resolver<ResolversTypes['plan_tasks_aggregate'], ParentType, ContextType, RequireFields<PlansPlan_Tasks_AggregateArgs, never>>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plans_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['plans_aggregate'] = ResolversParentTypes['plans_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['plans_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['plans']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plans_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['plans_aggregate_fields'] = ResolversParentTypes['plans_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Plans_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['plans_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['plans_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plans_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['plans_max_fields'] = ResolversParentTypes['plans_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plans_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['plans_min_fields'] = ResolversParentTypes['plans_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plans_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['plans_mutation_response'] = ResolversParentTypes['plans_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['plans']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProfilesResolvers<ContextType = any, ParentType extends ResolversParentTypes['profiles'] = ResolversParentTypes['profiles']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  opmode_profiles?: Resolver<Array<ResolversTypes['opmode_profile']>, ParentType, ContextType, RequireFields<ProfilesOpmode_ProfilesArgs, never>>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Profiles_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['profiles_aggregate'] = ResolversParentTypes['profiles_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['profiles_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['profiles']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Profiles_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['profiles_aggregate_fields'] = ResolversParentTypes['profiles_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Profiles_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['profiles_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['profiles_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Profiles_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['profiles_max_fields'] = ResolversParentTypes['profiles_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Profiles_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['profiles_min_fields'] = ResolversParentTypes['profiles_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Profiles_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['profiles_mutation_response'] = ResolversParentTypes['profiles_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['profiles']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_ComboResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_combo'] = ResolversParentTypes['project_combo']> = {
  other_project?: Resolver<ResolversTypes['projects'], ParentType, ContextType>;
  other_project_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['projects'], ParentType, ContextType>;
  project_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Combo_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_combo_aggregate'] = ResolversParentTypes['project_combo_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['project_combo_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['project_combo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Combo_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_combo_aggregate_fields'] = ResolversParentTypes['project_combo_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Project_Combo_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['project_combo_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['project_combo_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Combo_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_combo_max_fields'] = ResolversParentTypes['project_combo_max_fields']> = {
  other_project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Combo_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_combo_min_fields'] = ResolversParentTypes['project_combo_min_fields']> = {
  other_project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Combo_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_combo_mutation_response'] = ResolversParentTypes['project_combo_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['project_combo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_DatasetResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_dataset'] = ResolversParentTypes['project_dataset']> = {
  device?: Resolver<Maybe<ResolversTypes['devices']>, ParentType, ContextType>;
  device_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  dirty?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  project?: Resolver<Maybe<ResolversTypes['projects']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  recorded_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  recording?: Resolver<Maybe<ResolversTypes['recordings']>, ParentType, ContextType>;
  recording_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  room?: Resolver<Maybe<ResolversTypes['rooms']>, ParentType, ContextType>;
  room_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  task?: Resolver<Maybe<ResolversTypes['tasks']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['users']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Dataset_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_dataset_aggregate'] = ResolversParentTypes['project_dataset_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['project_dataset_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['project_dataset']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Dataset_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_dataset_aggregate_fields'] = ResolversParentTypes['project_dataset_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Project_Dataset_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['project_dataset_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['project_dataset_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Dataset_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_dataset_max_fields'] = ResolversParentTypes['project_dataset_max_fields']> = {
  device_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  recorded_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  recording_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  room_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Dataset_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_dataset_min_fields'] = ResolversParentTypes['project_dataset_min_fields']> = {
  device_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  recorded_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  recording_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  room_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_HypertasksResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_hypertasks'] = ResolversParentTypes['project_hypertasks']> = {
  hypertask?: Resolver<ResolversTypes['hypertasks'], ParentType, ContextType>;
  hypertask_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  min_per_device?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['projects'], ParentType, ContextType>;
  project_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Hypertasks_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_hypertasks_aggregate'] = ResolversParentTypes['project_hypertasks_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['project_hypertasks_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['project_hypertasks']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Hypertasks_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_hypertasks_aggregate_fields'] = ResolversParentTypes['project_hypertasks_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['project_hypertasks_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Project_Hypertasks_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['project_hypertasks_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['project_hypertasks_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['project_hypertasks_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['project_hypertasks_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['project_hypertasks_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['project_hypertasks_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['project_hypertasks_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['project_hypertasks_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['project_hypertasks_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Hypertasks_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_hypertasks_avg_fields'] = ResolversParentTypes['project_hypertasks_avg_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Hypertasks_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_hypertasks_max_fields'] = ResolversParentTypes['project_hypertasks_max_fields']> = {
  hypertask_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  min_per_device?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Hypertasks_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_hypertasks_min_fields'] = ResolversParentTypes['project_hypertasks_min_fields']> = {
  hypertask_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  min_per_device?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Hypertasks_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_hypertasks_mutation_response'] = ResolversParentTypes['project_hypertasks_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['project_hypertasks']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Hypertasks_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_hypertasks_stddev_fields'] = ResolversParentTypes['project_hypertasks_stddev_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Hypertasks_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_hypertasks_stddev_pop_fields'] = ResolversParentTypes['project_hypertasks_stddev_pop_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Hypertasks_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_hypertasks_stddev_samp_fields'] = ResolversParentTypes['project_hypertasks_stddev_samp_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Hypertasks_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_hypertasks_sum_fields'] = ResolversParentTypes['project_hypertasks_sum_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Hypertasks_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_hypertasks_var_pop_fields'] = ResolversParentTypes['project_hypertasks_var_pop_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Hypertasks_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_hypertasks_var_samp_fields'] = ResolversParentTypes['project_hypertasks_var_samp_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Hypertasks_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_hypertasks_variance_fields'] = ResolversParentTypes['project_hypertasks_variance_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_MetricsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_metrics'] = ResolversParentTypes['project_metrics']> = {
  project?: Resolver<Maybe<ResolversTypes['projects']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ratio_metric?: Resolver<Maybe<ResolversTypes['performance_ratio_metrics']>, ParentType, ContextType>;
  ratio_metric_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Metrics_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_metrics_aggregate'] = ResolversParentTypes['project_metrics_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['project_metrics_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['project_metrics']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Metrics_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_metrics_aggregate_fields'] = ResolversParentTypes['project_metrics_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Project_Metrics_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['project_metrics_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['project_metrics_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Metrics_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_metrics_max_fields'] = ResolversParentTypes['project_metrics_max_fields']> = {
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ratio_metric_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Metrics_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_metrics_min_fields'] = ResolversParentTypes['project_metrics_min_fields']> = {
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ratio_metric_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_NameResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_name'] = ResolversParentTypes['project_name']> = {
  display_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  project_users?: Resolver<Array<ResolversTypes['project_user']>, ParentType, ContextType, RequireFields<Project_NameProject_UsersArgs, never>>;
  project_users_aggregate?: Resolver<ResolversTypes['project_user_aggregate'], ParentType, ContextType, RequireFields<Project_NameProject_Users_AggregateArgs, never>>;
  projects?: Resolver<Maybe<ResolversTypes['projects']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Rabbit_Test_InstancesResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_rabbit_test_instances'] = ResolversParentTypes['project_rabbit_test_instances']> = {
  device?: Resolver<Maybe<ResolversTypes['devices']>, ParentType, ContextType>;
  device_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  engine?: Resolver<Maybe<ResolversTypes['engines']>, ParentType, ContextType>;
  engine_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  num_distinct_tasks?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  num_recordings?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  opmode?: Resolver<Maybe<ResolversTypes['opmodes']>, ParentType, ContextType>;
  opmode_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  project?: Resolver<Maybe<ResolversTypes['projects']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  rabbit_test_plan?: Resolver<Maybe<ResolversTypes['rabbit_test_plans']>, ParentType, ContextType>;
  started_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['users']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Rabbit_Test_Instances_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_rabbit_test_instances_aggregate'] = ResolversParentTypes['project_rabbit_test_instances_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['project_rabbit_test_instances_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['project_rabbit_test_instances']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Rabbit_Test_Instances_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_rabbit_test_instances_aggregate_fields'] = ResolversParentTypes['project_rabbit_test_instances_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['project_rabbit_test_instances_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Project_Rabbit_Test_Instances_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['project_rabbit_test_instances_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['project_rabbit_test_instances_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['project_rabbit_test_instances_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['project_rabbit_test_instances_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['project_rabbit_test_instances_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['project_rabbit_test_instances_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['project_rabbit_test_instances_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['project_rabbit_test_instances_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['project_rabbit_test_instances_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Rabbit_Test_Instances_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_rabbit_test_instances_avg_fields'] = ResolversParentTypes['project_rabbit_test_instances_avg_fields']> = {
  num_distinct_tasks?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Rabbit_Test_Instances_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_rabbit_test_instances_max_fields'] = ResolversParentTypes['project_rabbit_test_instances_max_fields']> = {
  device_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  engine_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  num_distinct_tasks?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  num_recordings?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  opmode_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  started_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Rabbit_Test_Instances_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_rabbit_test_instances_min_fields'] = ResolversParentTypes['project_rabbit_test_instances_min_fields']> = {
  device_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  engine_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  num_distinct_tasks?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  num_recordings?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  opmode_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  started_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Rabbit_Test_Instances_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_rabbit_test_instances_stddev_fields'] = ResolversParentTypes['project_rabbit_test_instances_stddev_fields']> = {
  num_distinct_tasks?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Rabbit_Test_Instances_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_rabbit_test_instances_stddev_pop_fields'] = ResolversParentTypes['project_rabbit_test_instances_stddev_pop_fields']> = {
  num_distinct_tasks?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Rabbit_Test_Instances_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_rabbit_test_instances_stddev_samp_fields'] = ResolversParentTypes['project_rabbit_test_instances_stddev_samp_fields']> = {
  num_distinct_tasks?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Rabbit_Test_Instances_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_rabbit_test_instances_sum_fields'] = ResolversParentTypes['project_rabbit_test_instances_sum_fields']> = {
  num_distinct_tasks?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  num_recordings?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Rabbit_Test_Instances_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_rabbit_test_instances_var_pop_fields'] = ResolversParentTypes['project_rabbit_test_instances_var_pop_fields']> = {
  num_distinct_tasks?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Rabbit_Test_Instances_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_rabbit_test_instances_var_samp_fields'] = ResolversParentTypes['project_rabbit_test_instances_var_samp_fields']> = {
  num_distinct_tasks?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Rabbit_Test_Instances_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_rabbit_test_instances_variance_fields'] = ResolversParentTypes['project_rabbit_test_instances_variance_fields']> = {
  num_distinct_tasks?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_TasksResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_tasks'] = ResolversParentTypes['project_tasks']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  min_per_device?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['projects'], ParentType, ContextType>;
  project_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  task?: Resolver<ResolversTypes['tasks'], ParentType, ContextType>;
  task_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Tasks_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_tasks_aggregate'] = ResolversParentTypes['project_tasks_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['project_tasks_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['project_tasks']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Tasks_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_tasks_aggregate_fields'] = ResolversParentTypes['project_tasks_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['project_tasks_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Project_Tasks_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['project_tasks_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['project_tasks_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['project_tasks_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['project_tasks_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['project_tasks_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['project_tasks_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['project_tasks_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['project_tasks_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['project_tasks_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Tasks_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_tasks_avg_fields'] = ResolversParentTypes['project_tasks_avg_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Tasks_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_tasks_max_fields'] = ResolversParentTypes['project_tasks_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  min_per_device?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Tasks_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_tasks_min_fields'] = ResolversParentTypes['project_tasks_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  min_per_device?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Tasks_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_tasks_mutation_response'] = ResolversParentTypes['project_tasks_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['project_tasks']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Tasks_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_tasks_stddev_fields'] = ResolversParentTypes['project_tasks_stddev_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Tasks_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_tasks_stddev_pop_fields'] = ResolversParentTypes['project_tasks_stddev_pop_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Tasks_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_tasks_stddev_samp_fields'] = ResolversParentTypes['project_tasks_stddev_samp_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Tasks_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_tasks_sum_fields'] = ResolversParentTypes['project_tasks_sum_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Tasks_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_tasks_var_pop_fields'] = ResolversParentTypes['project_tasks_var_pop_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Tasks_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_tasks_var_samp_fields'] = ResolversParentTypes['project_tasks_var_samp_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_Tasks_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_tasks_variance_fields'] = ResolversParentTypes['project_tasks_variance_fields']> = {
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_user'] = ResolversParentTypes['project_user']> = {
  manager?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['projects'], ParentType, ContextType>;
  project_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['users'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_User_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_user_aggregate'] = ResolversParentTypes['project_user_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['project_user_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['project_user']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_User_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_user_aggregate_fields'] = ResolversParentTypes['project_user_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Project_User_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['project_user_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['project_user_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_User_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_user_max_fields'] = ResolversParentTypes['project_user_max_fields']> = {
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_User_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_user_min_fields'] = ResolversParentTypes['project_user_min_fields']> = {
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Project_User_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['project_user_mutation_response'] = ResolversParentTypes['project_user_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['project_user']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectsResolvers<ContextType = any, ParentType extends ResolversParentTypes['projects'] = ResolversParentTypes['projects']> = {
  audio_measurements_project_tasks?: Resolver<Array<ResolversTypes['audio_measurements_project_tasks']>, ParentType, ContextType, RequireFields<ProjectsAudio_Measurements_Project_TasksArgs, never>>;
  audio_measurements_project_tasks_aggregate?: Resolver<ResolversTypes['audio_measurements_project_tasks_aggregate'], ParentType, ContextType, RequireFields<ProjectsAudio_Measurements_Project_Tasks_AggregateArgs, never>>;
  azure_build_ref?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  azure_release_ref?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  calibration_opmode?: Resolver<Maybe<ResolversTypes['opmodes']>, ParentType, ContextType>;
  calibration_opmode_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  completed_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['customers'], ParentType, ContextType>;
  customer_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  date_end?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  date_start?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deliverable?: Resolver<ResolversTypes['deliverables'], ParentType, ContextType>;
  deliverable_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  device_projects?: Resolver<Array<ResolversTypes['device_project']>, ParentType, ContextType, RequireFields<ProjectsDevice_ProjectsArgs, never>>;
  device_projects_aggregate?: Resolver<ResolversTypes['device_project_aggregate'], ParentType, ContextType, RequireFields<ProjectsDevice_Projects_AggregateArgs, never>>;
  dynamic_scaling?: Resolver<Maybe<ResolversTypes['dynamic_scaling']>, ParentType, ContextType>;
  dynamic_scaling_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  enable_arcadia_processing?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  enable_greatness_processing?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  enable_ground_truth_processing?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  far_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  firmware_engine_release_ref?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  min_devices_per_task?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  mode_projects?: Resolver<Array<ResolversTypes['mode_project']>, ParentType, ContextType, RequireFields<ProjectsMode_ProjectsArgs, never>>;
  mode_projects_aggregate?: Resolver<ResolversTypes['mode_project_aggregate'], ParentType, ContextType, RequireFields<ProjectsMode_Projects_AggregateArgs, never>>;
  model?: Resolver<ResolversTypes['models'], ParentType, ContextType>;
  model_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  mongo_source_query?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType, RequireFields<ProjectsMongo_Source_QueryArgs, never>>;
  near_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  observances?: Resolver<Array<ResolversTypes['integration_test_observances']>, ParentType, ContextType, RequireFields<ProjectsObservancesArgs, never>>;
  observances_aggregate?: Resolver<ResolversTypes['integration_test_observances_aggregate'], ParentType, ContextType, RequireFields<ProjectsObservances_AggregateArgs, never>>;
  opmode_projects?: Resolver<Array<ResolversTypes['opmode_project']>, ParentType, ContextType, RequireFields<ProjectsOpmode_ProjectsArgs, never>>;
  opmode_projects_aggregate?: Resolver<ResolversTypes['opmode_project_aggregate'], ParentType, ContextType, RequireFields<ProjectsOpmode_Projects_AggregateArgs, never>>;
  project_combos?: Resolver<Array<ResolversTypes['project_combo']>, ParentType, ContextType, RequireFields<ProjectsProject_CombosArgs, never>>;
  project_combos_aggregate?: Resolver<ResolversTypes['project_combo_aggregate'], ParentType, ContextType, RequireFields<ProjectsProject_Combos_AggregateArgs, never>>;
  project_dataset?: Resolver<Array<ResolversTypes['project_dataset']>, ParentType, ContextType, RequireFields<ProjectsProject_DatasetArgs, never>>;
  project_dataset_aggregate?: Resolver<ResolversTypes['project_dataset_aggregate'], ParentType, ContextType, RequireFields<ProjectsProject_Dataset_AggregateArgs, never>>;
  project_hypertasks?: Resolver<Array<ResolversTypes['project_hypertasks']>, ParentType, ContextType, RequireFields<ProjectsProject_HypertasksArgs, never>>;
  project_hypertasks_aggregate?: Resolver<ResolversTypes['project_hypertasks_aggregate'], ParentType, ContextType, RequireFields<ProjectsProject_Hypertasks_AggregateArgs, never>>;
  project_name?: Resolver<Maybe<ResolversTypes['project_name']>, ParentType, ContextType>;
  project_rabbit_test_plans?: Resolver<Array<ResolversTypes['rabbit_test_plan_project']>, ParentType, ContextType, RequireFields<ProjectsProject_Rabbit_Test_PlansArgs, never>>;
  project_rabbit_test_plans_aggregate?: Resolver<ResolversTypes['rabbit_test_plan_project_aggregate'], ParentType, ContextType, RequireFields<ProjectsProject_Rabbit_Test_Plans_AggregateArgs, never>>;
  project_sequences?: Resolver<Array<ResolversTypes['integration_test_project_sequence']>, ParentType, ContextType, RequireFields<ProjectsProject_SequencesArgs, never>>;
  project_sequences_aggregate?: Resolver<ResolversTypes['integration_test_project_sequence_aggregate'], ParentType, ContextType, RequireFields<ProjectsProject_Sequences_AggregateArgs, never>>;
  project_tasks?: Resolver<Array<ResolversTypes['project_tasks']>, ParentType, ContextType, RequireFields<ProjectsProject_TasksArgs, never>>;
  project_tasks_aggregate?: Resolver<ResolversTypes['project_tasks_aggregate'], ParentType, ContextType, RequireFields<ProjectsProject_Tasks_AggregateArgs, never>>;
  project_users?: Resolver<Array<ResolversTypes['project_user']>, ParentType, ContextType, RequireFields<ProjectsProject_UsersArgs, never>>;
  project_users_aggregate?: Resolver<ResolversTypes['project_user_aggregate'], ParentType, ContextType, RequireFields<ProjectsProject_Users_AggregateArgs, never>>;
  rabbit_test_instances?: Resolver<Array<ResolversTypes['project_rabbit_test_instances']>, ParentType, ContextType, RequireFields<ProjectsRabbit_Test_InstancesArgs, never>>;
  rabbit_test_instances_aggregate?: Resolver<ResolversTypes['project_rabbit_test_instances_aggregate'], ParentType, ContextType, RequireFields<ProjectsRabbit_Test_Instances_AggregateArgs, never>>;
  recordings?: Resolver<Array<ResolversTypes['recordings']>, ParentType, ContextType, RequireFields<ProjectsRecordingsArgs, never>>;
  recordings_aggregate?: Resolver<ResolversTypes['recordings_aggregate'], ParentType, ContextType, RequireFields<ProjectsRecordings_AggregateArgs, never>>;
  scores?: Resolver<Array<ResolversTypes['simulations_scores']>, ParentType, ContextType, RequireFields<ProjectsScoresArgs, never>>;
  simulation_opmode?: Resolver<Maybe<ResolversTypes['opmodes']>, ParentType, ContextType>;
  simulation_opmode_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  slack_channel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_recordings_by_day_stats?: Resolver<Array<ResolversTypes['user_recordings_by_day_stats']>, ParentType, ContextType, RequireFields<ProjectsUser_Recordings_By_Day_StatsArgs, never>>;
  user_recordings_by_day_stats_aggregate?: Resolver<ResolversTypes['user_recordings_by_day_stats_aggregate'], ParentType, ContextType, RequireFields<ProjectsUser_Recordings_By_Day_Stats_AggregateArgs, never>>;
  variant?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  xtensa_configuration?: Resolver<Maybe<ResolversTypes['xtensa_configurations']>, ParentType, ContextType>;
  xtensa_configuration_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Projects_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['projects_aggregate'] = ResolversParentTypes['projects_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['projects_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['projects']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Projects_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['projects_aggregate_fields'] = ResolversParentTypes['projects_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['projects_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Projects_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['projects_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['projects_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['projects_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['projects_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['projects_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['projects_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['projects_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['projects_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['projects_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Projects_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['projects_avg_fields'] = ResolversParentTypes['projects_avg_fields']> = {
  azure_build_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  azure_release_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  far_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  firmware_engine_release_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_devices_per_task?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  near_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Projects_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['projects_max_fields'] = ResolversParentTypes['projects_max_fields']> = {
  azure_build_ref?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  azure_release_ref?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  calibration_opmode_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  completed_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  customer_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  date_end?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  date_start?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deliverable_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dynamic_scaling_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  far_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  firmware_engine_release_ref?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  min_devices_per_task?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  model_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  near_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  simulation_opmode_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  slack_channel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  variant?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  xtensa_configuration_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Projects_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['projects_min_fields'] = ResolversParentTypes['projects_min_fields']> = {
  azure_build_ref?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  azure_release_ref?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  calibration_opmode_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  completed_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  customer_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  date_end?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  date_start?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deliverable_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dynamic_scaling_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  far_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  firmware_engine_release_ref?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  min_devices_per_task?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  model_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  near_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  simulation_opmode_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  slack_channel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  variant?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  xtensa_configuration_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Projects_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['projects_mutation_response'] = ResolversParentTypes['projects_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['projects']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Projects_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['projects_stddev_fields'] = ResolversParentTypes['projects_stddev_fields']> = {
  azure_build_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  azure_release_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  far_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  firmware_engine_release_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_devices_per_task?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  near_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Projects_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['projects_stddev_pop_fields'] = ResolversParentTypes['projects_stddev_pop_fields']> = {
  azure_build_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  azure_release_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  far_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  firmware_engine_release_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_devices_per_task?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  near_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Projects_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['projects_stddev_samp_fields'] = ResolversParentTypes['projects_stddev_samp_fields']> = {
  azure_build_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  azure_release_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  far_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  firmware_engine_release_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_devices_per_task?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  near_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Projects_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['projects_sum_fields'] = ResolversParentTypes['projects_sum_fields']> = {
  azure_build_ref?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  azure_release_ref?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  far_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  firmware_engine_release_ref?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  min_devices_per_task?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  near_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Projects_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['projects_var_pop_fields'] = ResolversParentTypes['projects_var_pop_fields']> = {
  azure_build_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  azure_release_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  far_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  firmware_engine_release_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_devices_per_task?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  near_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Projects_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['projects_var_samp_fields'] = ResolversParentTypes['projects_var_samp_fields']> = {
  azure_build_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  azure_release_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  far_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  firmware_engine_release_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_devices_per_task?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  near_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Projects_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['projects_variance_fields'] = ResolversParentTypes['projects_variance_fields']> = {
  azure_build_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  azure_release_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  far_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  firmware_engine_release_ref?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_devices_per_task?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  near_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Query_RootResolvers<ContextType = any, ParentType extends ResolversParentTypes['query_root'] = ResolversParentTypes['query_root']> = {
  audio_driver?: Resolver<Array<ResolversTypes['audio_driver']>, ParentType, ContextType, RequireFields<Query_RootAudio_DriverArgs, never>>;
  audio_driver_by_pk?: Resolver<Maybe<ResolversTypes['audio_driver']>, ParentType, ContextType, RequireFields<Query_RootAudio_Driver_By_PkArgs, 'id'>>;
  audio_measurements_components?: Resolver<Array<ResolversTypes['audio_measurements_components']>, ParentType, ContextType, RequireFields<Query_RootAudio_Measurements_ComponentsArgs, never>>;
  audio_measurements_components_by_pk?: Resolver<Maybe<ResolversTypes['audio_measurements_components']>, ParentType, ContextType, RequireFields<Query_RootAudio_Measurements_Components_By_PkArgs, 'id'>>;
  audio_measurements_configurations?: Resolver<Array<ResolversTypes['audio_measurements_configurations']>, ParentType, ContextType, RequireFields<Query_RootAudio_Measurements_ConfigurationsArgs, never>>;
  audio_measurements_configurations_by_pk?: Resolver<Maybe<ResolversTypes['audio_measurements_configurations']>, ParentType, ContextType, RequireFields<Query_RootAudio_Measurements_Configurations_By_PkArgs, 'id'>>;
  audio_measurements_data_types?: Resolver<Array<ResolversTypes['audio_measurements_data_types']>, ParentType, ContextType, RequireFields<Query_RootAudio_Measurements_Data_TypesArgs, never>>;
  audio_measurements_data_types_aggregate?: Resolver<ResolversTypes['audio_measurements_data_types_aggregate'], ParentType, ContextType, RequireFields<Query_RootAudio_Measurements_Data_Types_AggregateArgs, never>>;
  audio_measurements_data_types_by_pk?: Resolver<Maybe<ResolversTypes['audio_measurements_data_types']>, ParentType, ContextType, RequireFields<Query_RootAudio_Measurements_Data_Types_By_PkArgs, 'id'>>;
  audio_measurements_master_files?: Resolver<Array<ResolversTypes['audio_measurements_master_files']>, ParentType, ContextType, RequireFields<Query_RootAudio_Measurements_Master_FilesArgs, never>>;
  audio_measurements_master_files_aggregate?: Resolver<ResolversTypes['audio_measurements_master_files_aggregate'], ParentType, ContextType, RequireFields<Query_RootAudio_Measurements_Master_Files_AggregateArgs, never>>;
  audio_measurements_master_files_by_pk?: Resolver<Maybe<ResolversTypes['audio_measurements_master_files']>, ParentType, ContextType, RequireFields<Query_RootAudio_Measurements_Master_Files_By_PkArgs, 'id'>>;
  audio_measurements_orientations?: Resolver<Array<ResolversTypes['audio_measurements_orientations']>, ParentType, ContextType, RequireFields<Query_RootAudio_Measurements_OrientationsArgs, never>>;
  audio_measurements_orientations_by_pk?: Resolver<Maybe<ResolversTypes['audio_measurements_orientations']>, ParentType, ContextType, RequireFields<Query_RootAudio_Measurements_Orientations_By_PkArgs, 'id'>>;
  audio_measurements_payload_recording?: Resolver<Array<ResolversTypes['audio_measurements_payload_recording']>, ParentType, ContextType, RequireFields<Query_RootAudio_Measurements_Payload_RecordingArgs, never>>;
  audio_measurements_payload_recording_aggregate?: Resolver<ResolversTypes['audio_measurements_payload_recording_aggregate'], ParentType, ContextType, RequireFields<Query_RootAudio_Measurements_Payload_Recording_AggregateArgs, never>>;
  audio_measurements_payload_recording_by_pk?: Resolver<Maybe<ResolversTypes['audio_measurements_payload_recording']>, ParentType, ContextType, RequireFields<Query_RootAudio_Measurements_Payload_Recording_By_PkArgs, 'payload_id' | 'recording_id'>>;
  audio_measurements_placements?: Resolver<Array<ResolversTypes['audio_measurements_placements']>, ParentType, ContextType, RequireFields<Query_RootAudio_Measurements_PlacementsArgs, never>>;
  audio_measurements_placements_by_pk?: Resolver<Maybe<ResolversTypes['audio_measurements_placements']>, ParentType, ContextType, RequireFields<Query_RootAudio_Measurements_Placements_By_PkArgs, 'id'>>;
  audio_measurements_project_tasks?: Resolver<Array<ResolversTypes['audio_measurements_project_tasks']>, ParentType, ContextType, RequireFields<Query_RootAudio_Measurements_Project_TasksArgs, never>>;
  audio_measurements_project_tasks_aggregate?: Resolver<ResolversTypes['audio_measurements_project_tasks_aggregate'], ParentType, ContextType, RequireFields<Query_RootAudio_Measurements_Project_Tasks_AggregateArgs, never>>;
  audio_measurements_project_tasks_by_pk?: Resolver<Maybe<ResolversTypes['audio_measurements_project_tasks']>, ParentType, ContextType, RequireFields<Query_RootAudio_Measurements_Project_Tasks_By_PkArgs, 'project_id' | 'task_id'>>;
  audio_measurements_recordings?: Resolver<Array<ResolversTypes['audio_measurements_recordings']>, ParentType, ContextType, RequireFields<Query_RootAudio_Measurements_RecordingsArgs, never>>;
  audio_measurements_recordings_aggregate?: Resolver<ResolversTypes['audio_measurements_recordings_aggregate'], ParentType, ContextType, RequireFields<Query_RootAudio_Measurements_Recordings_AggregateArgs, never>>;
  audio_measurements_recordings_by_pk?: Resolver<Maybe<ResolversTypes['audio_measurements_recordings']>, ParentType, ContextType, RequireFields<Query_RootAudio_Measurements_Recordings_By_PkArgs, 'id'>>;
  audio_measurements_stages?: Resolver<Array<ResolversTypes['audio_measurements_stages']>, ParentType, ContextType, RequireFields<Query_RootAudio_Measurements_StagesArgs, never>>;
  audio_measurements_stages_by_pk?: Resolver<Maybe<ResolversTypes['audio_measurements_stages']>, ParentType, ContextType, RequireFields<Query_RootAudio_Measurements_Stages_By_PkArgs, 'id'>>;
  bringup_checklist_deliverable?: Resolver<Array<ResolversTypes['bringup_checklist_deliverable']>, ParentType, ContextType, RequireFields<Query_RootBringup_Checklist_DeliverableArgs, never>>;
  bringup_checklist_deliverable_aggregate?: Resolver<ResolversTypes['bringup_checklist_deliverable_aggregate'], ParentType, ContextType, RequireFields<Query_RootBringup_Checklist_Deliverable_AggregateArgs, never>>;
  bringup_checklist_deliverable_by_pk?: Resolver<Maybe<ResolversTypes['bringup_checklist_deliverable']>, ParentType, ContextType, RequireFields<Query_RootBringup_Checklist_Deliverable_By_PkArgs, 'checklist_id' | 'deliverable_id'>>;
  bringup_checklists?: Resolver<Array<ResolversTypes['bringup_checklists']>, ParentType, ContextType, RequireFields<Query_RootBringup_ChecklistsArgs, never>>;
  bringup_checklists_aggregate?: Resolver<ResolversTypes['bringup_checklists_aggregate'], ParentType, ContextType, RequireFields<Query_RootBringup_Checklists_AggregateArgs, never>>;
  bringup_checklists_by_pk?: Resolver<Maybe<ResolversTypes['bringup_checklists']>, ParentType, ContextType, RequireFields<Query_RootBringup_Checklists_By_PkArgs, 'id'>>;
  bringup_endorsements?: Resolver<Array<ResolversTypes['bringup_endorsements']>, ParentType, ContextType, RequireFields<Query_RootBringup_EndorsementsArgs, never>>;
  bringup_endorsements_aggregate?: Resolver<ResolversTypes['bringup_endorsements_aggregate'], ParentType, ContextType, RequireFields<Query_RootBringup_Endorsements_AggregateArgs, never>>;
  bringup_endorsements_by_pk?: Resolver<Maybe<ResolversTypes['bringup_endorsements']>, ParentType, ContextType, RequireFields<Query_RootBringup_Endorsements_By_PkArgs, 'item_id' | 'project_id'>>;
  bringup_items?: Resolver<Array<ResolversTypes['bringup_items']>, ParentType, ContextType, RequireFields<Query_RootBringup_ItemsArgs, never>>;
  bringup_items_aggregate?: Resolver<ResolversTypes['bringup_items_aggregate'], ParentType, ContextType, RequireFields<Query_RootBringup_Items_AggregateArgs, never>>;
  bringup_items_by_pk?: Resolver<Maybe<ResolversTypes['bringup_items']>, ParentType, ContextType, RequireFields<Query_RootBringup_Items_By_PkArgs, 'id'>>;
  bringup_milestones?: Resolver<Array<ResolversTypes['bringup_milestones']>, ParentType, ContextType, RequireFields<Query_RootBringup_MilestonesArgs, never>>;
  bringup_milestones_aggregate?: Resolver<ResolversTypes['bringup_milestones_aggregate'], ParentType, ContextType, RequireFields<Query_RootBringup_Milestones_AggregateArgs, never>>;
  bringup_milestones_by_pk?: Resolver<Maybe<ResolversTypes['bringup_milestones']>, ParentType, ContextType, RequireFields<Query_RootBringup_Milestones_By_PkArgs, 'item_id'>>;
  bringup_project_item_comments?: Resolver<Array<ResolversTypes['bringup_project_item_comments']>, ParentType, ContextType, RequireFields<Query_RootBringup_Project_Item_CommentsArgs, never>>;
  bringup_project_item_comments_aggregate?: Resolver<ResolversTypes['bringup_project_item_comments_aggregate'], ParentType, ContextType, RequireFields<Query_RootBringup_Project_Item_Comments_AggregateArgs, never>>;
  bringup_project_item_comments_by_pk?: Resolver<Maybe<ResolversTypes['bringup_project_item_comments']>, ParentType, ContextType, RequireFields<Query_RootBringup_Project_Item_Comments_By_PkArgs, 'created_at' | 'item_id' | 'project_id'>>;
  categories?: Resolver<Array<ResolversTypes['categories']>, ParentType, ContextType, RequireFields<Query_RootCategoriesArgs, never>>;
  categories_aggregate?: Resolver<ResolversTypes['categories_aggregate'], ParentType, ContextType, RequireFields<Query_RootCategories_AggregateArgs, never>>;
  categories_by_pk?: Resolver<Maybe<ResolversTypes['categories']>, ParentType, ContextType, RequireFields<Query_RootCategories_By_PkArgs, 'id'>>;
  context_display_field?: Resolver<Array<ResolversTypes['context_display_field']>, ParentType, ContextType, RequireFields<Query_RootContext_Display_FieldArgs, never>>;
  context_display_field_aggregate?: Resolver<ResolversTypes['context_display_field_aggregate'], ParentType, ContextType, RequireFields<Query_RootContext_Display_Field_AggregateArgs, never>>;
  context_display_field_by_pk?: Resolver<Maybe<ResolversTypes['context_display_field']>, ParentType, ContextType, RequireFields<Query_RootContext_Display_Field_By_PkArgs, 'context_display_id' | 'field_id'>>;
  context_displays?: Resolver<Array<ResolversTypes['context_displays']>, ParentType, ContextType, RequireFields<Query_RootContext_DisplaysArgs, never>>;
  context_displays_by_pk?: Resolver<Maybe<ResolversTypes['context_displays']>, ParentType, ContextType, RequireFields<Query_RootContext_Displays_By_PkArgs, 'id'>>;
  contexts?: Resolver<Array<ResolversTypes['contexts']>, ParentType, ContextType, RequireFields<Query_RootContextsArgs, never>>;
  contexts_aggregate?: Resolver<ResolversTypes['contexts_aggregate'], ParentType, ContextType, RequireFields<Query_RootContexts_AggregateArgs, never>>;
  contexts_by_pk?: Resolver<Maybe<ResolversTypes['contexts']>, ParentType, ContextType, RequireFields<Query_RootContexts_By_PkArgs, 'id'>>;
  customers?: Resolver<Array<ResolversTypes['customers']>, ParentType, ContextType, RequireFields<Query_RootCustomersArgs, never>>;
  customers_aggregate?: Resolver<ResolversTypes['customers_aggregate'], ParentType, ContextType, RequireFields<Query_RootCustomers_AggregateArgs, never>>;
  customers_by_pk?: Resolver<Maybe<ResolversTypes['customers']>, ParentType, ContextType, RequireFields<Query_RootCustomers_By_PkArgs, 'id'>>;
  deliverables?: Resolver<Array<ResolversTypes['deliverables']>, ParentType, ContextType, RequireFields<Query_RootDeliverablesArgs, never>>;
  deliverables_aggregate?: Resolver<ResolversTypes['deliverables_aggregate'], ParentType, ContextType, RequireFields<Query_RootDeliverables_AggregateArgs, never>>;
  deliverables_by_pk?: Resolver<Maybe<ResolversTypes['deliverables']>, ParentType, ContextType, RequireFields<Query_RootDeliverables_By_PkArgs, 'id'>>;
  deliveries_architectures?: Resolver<Array<ResolversTypes['deliveries_architectures']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_ArchitecturesArgs, never>>;
  deliveries_architectures_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_architectures']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Architectures_By_PkArgs, 'id'>>;
  deliveries_artifact_microsoft_whql_submission?: Resolver<Array<ResolversTypes['deliveries_artifact_microsoft_whql_submission']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Artifact_Microsoft_Whql_SubmissionArgs, never>>;
  deliveries_artifact_microsoft_whql_submission_aggregate?: Resolver<ResolversTypes['deliveries_artifact_microsoft_whql_submission_aggregate'], ParentType, ContextType, RequireFields<Query_RootDeliveries_Artifact_Microsoft_Whql_Submission_AggregateArgs, never>>;
  deliveries_artifact_microsoft_whql_submission_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_artifact_microsoft_whql_submission']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Artifact_Microsoft_Whql_Submission_By_PkArgs, 'artifact_id' | 'microsoft_whql_submission_id'>>;
  deliveries_artifact_operating_system?: Resolver<Array<ResolversTypes['deliveries_artifact_operating_system']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Artifact_Operating_SystemArgs, never>>;
  deliveries_artifact_operating_system_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_artifact_operating_system']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Artifact_Operating_System_By_PkArgs, 'artifact_id' | 'os_id'>>;
  deliveries_artifacts?: Resolver<Array<ResolversTypes['deliveries_artifacts']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_ArtifactsArgs, never>>;
  deliveries_artifacts_aggregate?: Resolver<ResolversTypes['deliveries_artifacts_aggregate'], ParentType, ContextType, RequireFields<Query_RootDeliveries_Artifacts_AggregateArgs, never>>;
  deliveries_artifacts_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_artifacts']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Artifacts_By_PkArgs, 'id'>>;
  deliveries_device_families?: Resolver<Array<ResolversTypes['deliveries_device_families']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Device_FamiliesArgs, never>>;
  deliveries_device_families_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_device_families']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Device_Families_By_PkArgs, 'id'>>;
  deliveries_driver_package?: Resolver<Array<ResolversTypes['deliveries_driver_package']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Driver_PackageArgs, never>>;
  deliveries_driver_package_aggregate?: Resolver<ResolversTypes['deliveries_driver_package_aggregate'], ParentType, ContextType, RequireFields<Query_RootDeliveries_Driver_Package_AggregateArgs, never>>;
  deliveries_driver_package_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_driver_package']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Driver_Package_By_PkArgs, 'driver_id' | 'package_id'>>;
  deliveries_driver_version_package?: Resolver<Array<ResolversTypes['deliveries_driver_version_package']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Driver_Version_PackageArgs, never>>;
  deliveries_driver_version_package_aggregate?: Resolver<ResolversTypes['deliveries_driver_version_package_aggregate'], ParentType, ContextType, RequireFields<Query_RootDeliveries_Driver_Version_Package_AggregateArgs, never>>;
  deliveries_driver_version_package_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_driver_version_package']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Driver_Version_Package_By_PkArgs, 'driver_version_id' | 'package_id'>>;
  deliveries_driver_versions?: Resolver<Array<ResolversTypes['deliveries_driver_versions']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Driver_VersionsArgs, never>>;
  deliveries_driver_versions_aggregate?: Resolver<ResolversTypes['deliveries_driver_versions_aggregate'], ParentType, ContextType, RequireFields<Query_RootDeliveries_Driver_Versions_AggregateArgs, never>>;
  deliveries_driver_versions_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_driver_versions']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Driver_Versions_By_PkArgs, 'id'>>;
  deliveries_drivers?: Resolver<Array<ResolversTypes['deliveries_drivers']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_DriversArgs, never>>;
  deliveries_drivers_aggregate?: Resolver<ResolversTypes['deliveries_drivers_aggregate'], ParentType, ContextType, RequireFields<Query_RootDeliveries_Drivers_AggregateArgs, never>>;
  deliveries_drivers_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_drivers']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Drivers_By_PkArgs, 'id'>>;
  deliveries_microsoft_hlk_artifacts?: Resolver<Array<ResolversTypes['deliveries_microsoft_hlk_artifacts']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Microsoft_Hlk_ArtifactsArgs, never>>;
  deliveries_microsoft_hlk_artifacts_aggregate?: Resolver<ResolversTypes['deliveries_microsoft_hlk_artifacts_aggregate'], ParentType, ContextType, RequireFields<Query_RootDeliveries_Microsoft_Hlk_Artifacts_AggregateArgs, never>>;
  deliveries_microsoft_hlk_artifacts_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_hlk_artifacts']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Microsoft_Hlk_Artifacts_By_PkArgs, 'id'>>;
  deliveries_microsoft_shipping_label_reviews?: Resolver<Array<ResolversTypes['deliveries_microsoft_shipping_label_reviews']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Microsoft_Shipping_Label_ReviewsArgs, never>>;
  deliveries_microsoft_shipping_label_reviews_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_shipping_label_reviews']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Microsoft_Shipping_Label_Reviews_By_PkArgs, 'microsoft_shipping_label_id'>>;
  deliveries_microsoft_shipping_labels?: Resolver<Array<ResolversTypes['deliveries_microsoft_shipping_labels']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Microsoft_Shipping_LabelsArgs, never>>;
  deliveries_microsoft_shipping_labels_aggregate?: Resolver<ResolversTypes['deliveries_microsoft_shipping_labels_aggregate'], ParentType, ContextType, RequireFields<Query_RootDeliveries_Microsoft_Shipping_Labels_AggregateArgs, never>>;
  deliveries_microsoft_shipping_labels_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_shipping_labels']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Microsoft_Shipping_Labels_By_PkArgs, 'id'>>;
  deliveries_microsoft_whql_submissions?: Resolver<Array<ResolversTypes['deliveries_microsoft_whql_submissions']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Microsoft_Whql_SubmissionsArgs, never>>;
  deliveries_microsoft_whql_submissions_aggregate?: Resolver<ResolversTypes['deliveries_microsoft_whql_submissions_aggregate'], ParentType, ContextType, RequireFields<Query_RootDeliveries_Microsoft_Whql_Submissions_AggregateArgs, never>>;
  deliveries_microsoft_whql_submissions_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_microsoft_whql_submissions']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Microsoft_Whql_Submissions_By_PkArgs, 'id'>>;
  deliveries_operating_systems?: Resolver<Array<ResolversTypes['deliveries_operating_systems']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Operating_SystemsArgs, never>>;
  deliveries_operating_systems_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_operating_systems']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Operating_Systems_By_PkArgs, 'id'>>;
  deliveries_package_operating_systems?: Resolver<Array<ResolversTypes['deliveries_package_operating_systems']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Package_Operating_SystemsArgs, never>>;
  deliveries_package_operating_systems_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_package_operating_systems']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Package_Operating_Systems_By_PkArgs, 'os_id' | 'package_id'>>;
  deliveries_package_target?: Resolver<Array<ResolversTypes['deliveries_package_target']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Package_TargetArgs, never>>;
  deliveries_package_target_aggregate?: Resolver<ResolversTypes['deliveries_package_target_aggregate'], ParentType, ContextType, RequireFields<Query_RootDeliveries_Package_Target_AggregateArgs, never>>;
  deliveries_package_target_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_package_target']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Package_Target_By_PkArgs, 'package_id' | 'target_id'>>;
  deliveries_packages?: Resolver<Array<ResolversTypes['deliveries_packages']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_PackagesArgs, never>>;
  deliveries_packages_aggregate?: Resolver<ResolversTypes['deliveries_packages_aggregate'], ParentType, ContextType, RequireFields<Query_RootDeliveries_Packages_AggregateArgs, never>>;
  deliveries_packages_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_packages']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Packages_By_PkArgs, 'id'>>;
  deliveries_partners?: Resolver<Array<ResolversTypes['deliveries_partners']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_PartnersArgs, never>>;
  deliveries_partners_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_partners']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Partners_By_PkArgs, 'id'>>;
  deliveries_platforms?: Resolver<Array<ResolversTypes['deliveries_platforms']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_PlatformsArgs, never>>;
  deliveries_platforms_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_platforms']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Platforms_By_PkArgs, 'id'>>;
  deliveries_project_configs?: Resolver<Array<ResolversTypes['deliveries_project_configs']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Project_ConfigsArgs, never>>;
  deliveries_project_configs_aggregate?: Resolver<ResolversTypes['deliveries_project_configs_aggregate'], ParentType, ContextType, RequireFields<Query_RootDeliveries_Project_Configs_AggregateArgs, never>>;
  deliveries_project_configs_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_project_configs']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Project_Configs_By_PkArgs, 'id'>>;
  deliveries_target_partner?: Resolver<Array<ResolversTypes['deliveries_target_partner']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Target_PartnerArgs, never>>;
  deliveries_target_partner_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_target_partner']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Target_Partner_By_PkArgs, 'partner_id' | 'target_id'>>;
  deliveries_targets?: Resolver<Array<ResolversTypes['deliveries_targets']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_TargetsArgs, never>>;
  deliveries_targets_aggregate?: Resolver<ResolversTypes['deliveries_targets_aggregate'], ParentType, ContextType, RequireFields<Query_RootDeliveries_Targets_AggregateArgs, never>>;
  deliveries_targets_by_pk?: Resolver<Maybe<ResolversTypes['deliveries_targets']>, ParentType, ContextType, RequireFields<Query_RootDeliveries_Targets_By_PkArgs, 'id'>>;
  device_firmware?: Resolver<Array<ResolversTypes['device_firmware']>, ParentType, ContextType, RequireFields<Query_RootDevice_FirmwareArgs, never>>;
  device_firmware_by_pk?: Resolver<Maybe<ResolversTypes['device_firmware']>, ParentType, ContextType, RequireFields<Query_RootDevice_Firmware_By_PkArgs, 'device_id' | 'firmware_id'>>;
  device_project?: Resolver<Array<ResolversTypes['device_project']>, ParentType, ContextType, RequireFields<Query_RootDevice_ProjectArgs, never>>;
  device_project_aggregate?: Resolver<ResolversTypes['device_project_aggregate'], ParentType, ContextType, RequireFields<Query_RootDevice_Project_AggregateArgs, never>>;
  device_project_by_pk?: Resolver<Maybe<ResolversTypes['device_project']>, ParentType, ContextType, RequireFields<Query_RootDevice_Project_By_PkArgs, 'device_id' | 'project_id'>>;
  devices?: Resolver<Array<ResolversTypes['devices']>, ParentType, ContextType, RequireFields<Query_RootDevicesArgs, never>>;
  devices_aggregate?: Resolver<ResolversTypes['devices_aggregate'], ParentType, ContextType, RequireFields<Query_RootDevices_AggregateArgs, never>>;
  devices_by_pk?: Resolver<Maybe<ResolversTypes['devices']>, ParentType, ContextType, RequireFields<Query_RootDevices_By_PkArgs, 'id'>>;
  dynamic_scaling?: Resolver<Array<ResolversTypes['dynamic_scaling']>, ParentType, ContextType, RequireFields<Query_RootDynamic_ScalingArgs, never>>;
  dynamic_scaling_by_pk?: Resolver<Maybe<ResolversTypes['dynamic_scaling']>, ParentType, ContextType, RequireFields<Query_RootDynamic_Scaling_By_PkArgs, 'id'>>;
  elapi_slack_channels?: Resolver<Maybe<Array<Maybe<ResolversTypes['ElapiSlackChannel']>>>, ParentType, ContextType>;
  engine_opmode?: Resolver<Array<ResolversTypes['engine_opmode']>, ParentType, ContextType, RequireFields<Query_RootEngine_OpmodeArgs, never>>;
  engine_opmode_aggregate?: Resolver<ResolversTypes['engine_opmode_aggregate'], ParentType, ContextType, RequireFields<Query_RootEngine_Opmode_AggregateArgs, never>>;
  engine_opmode_by_pk?: Resolver<Maybe<ResolversTypes['engine_opmode']>, ParentType, ContextType, RequireFields<Query_RootEngine_Opmode_By_PkArgs, 'engine_id' | 'opmode_id'>>;
  engine_opmode_recording?: Resolver<Array<ResolversTypes['engine_opmode_recording']>, ParentType, ContextType, RequireFields<Query_RootEngine_Opmode_RecordingArgs, never>>;
  engine_opmode_recording_aggregate?: Resolver<ResolversTypes['engine_opmode_recording_aggregate'], ParentType, ContextType, RequireFields<Query_RootEngine_Opmode_Recording_AggregateArgs, never>>;
  engine_opmode_recording_by_pk?: Resolver<Maybe<ResolversTypes['engine_opmode_recording']>, ParentType, ContextType, RequireFields<Query_RootEngine_Opmode_Recording_By_PkArgs, 'engine_id' | 'opmode_id' | 'recording_id'>>;
  engine_types?: Resolver<Array<ResolversTypes['engine_types']>, ParentType, ContextType, RequireFields<Query_RootEngine_TypesArgs, never>>;
  engine_types_aggregate?: Resolver<ResolversTypes['engine_types_aggregate'], ParentType, ContextType, RequireFields<Query_RootEngine_Types_AggregateArgs, never>>;
  engine_types_by_pk?: Resolver<Maybe<ResolversTypes['engine_types']>, ParentType, ContextType, RequireFields<Query_RootEngine_Types_By_PkArgs, 'id'>>;
  engines?: Resolver<Array<ResolversTypes['engines']>, ParentType, ContextType, RequireFields<Query_RootEnginesArgs, never>>;
  engines_aggregate?: Resolver<ResolversTypes['engines_aggregate'], ParentType, ContextType, RequireFields<Query_RootEngines_AggregateArgs, never>>;
  engines_by_pk?: Resolver<Maybe<ResolversTypes['engines']>, ParentType, ContextType, RequireFields<Query_RootEngines_By_PkArgs, 'id'>>;
  field_option_media?: Resolver<Array<ResolversTypes['field_option_media']>, ParentType, ContextType, RequireFields<Query_RootField_Option_MediaArgs, never>>;
  field_option_media_aggregate?: Resolver<ResolversTypes['field_option_media_aggregate'], ParentType, ContextType, RequireFields<Query_RootField_Option_Media_AggregateArgs, never>>;
  field_option_media_by_pk?: Resolver<Maybe<ResolversTypes['field_option_media']>, ParentType, ContextType, RequireFields<Query_RootField_Option_Media_By_PkArgs, 'field_option_id' | 'media_id'>>;
  field_options?: Resolver<Array<ResolversTypes['field_options']>, ParentType, ContextType, RequireFields<Query_RootField_OptionsArgs, never>>;
  field_options_aggregate?: Resolver<ResolversTypes['field_options_aggregate'], ParentType, ContextType, RequireFields<Query_RootField_Options_AggregateArgs, never>>;
  field_options_by_pk?: Resolver<Maybe<ResolversTypes['field_options']>, ParentType, ContextType, RequireFields<Query_RootField_Options_By_PkArgs, 'id'>>;
  field_translation?: Resolver<Array<ResolversTypes['field_translation']>, ParentType, ContextType, RequireFields<Query_RootField_TranslationArgs, never>>;
  field_translation_aggregate?: Resolver<ResolversTypes['field_translation_aggregate'], ParentType, ContextType, RequireFields<Query_RootField_Translation_AggregateArgs, never>>;
  field_translation_by_pk?: Resolver<Maybe<ResolversTypes['field_translation']>, ParentType, ContextType, RequireFields<Query_RootField_Translation_By_PkArgs, 'field_id' | 'language'>>;
  fields?: Resolver<Array<ResolversTypes['fields']>, ParentType, ContextType, RequireFields<Query_RootFieldsArgs, never>>;
  fields_aggregate?: Resolver<ResolversTypes['fields_aggregate'], ParentType, ContextType, RequireFields<Query_RootFields_AggregateArgs, never>>;
  fields_by_pk?: Resolver<Maybe<ResolversTypes['fields']>, ParentType, ContextType, RequireFields<Query_RootFields_By_PkArgs, 'id'>>;
  firmware?: Resolver<Array<ResolversTypes['firmware']>, ParentType, ContextType, RequireFields<Query_RootFirmwareArgs, never>>;
  firmware_aggregate?: Resolver<ResolversTypes['firmware_aggregate'], ParentType, ContextType, RequireFields<Query_RootFirmware_AggregateArgs, never>>;
  firmware_by_pk?: Resolver<Maybe<ResolversTypes['firmware']>, ParentType, ContextType, RequireFields<Query_RootFirmware_By_PkArgs, 'id'>>;
  ground_truth_configurations?: Resolver<Array<ResolversTypes['ground_truth_configurations']>, ParentType, ContextType, RequireFields<Query_RootGround_Truth_ConfigurationsArgs, never>>;
  ground_truth_configurations_aggregate?: Resolver<ResolversTypes['ground_truth_configurations_aggregate'], ParentType, ContextType, RequireFields<Query_RootGround_Truth_Configurations_AggregateArgs, never>>;
  ground_truth_configurations_by_pk?: Resolver<Maybe<ResolversTypes['ground_truth_configurations']>, ParentType, ContextType, RequireFields<Query_RootGround_Truth_Configurations_By_PkArgs, 'id'>>;
  ground_truth_device_types?: Resolver<Array<ResolversTypes['ground_truth_device_types']>, ParentType, ContextType, RequireFields<Query_RootGround_Truth_Device_TypesArgs, never>>;
  ground_truth_device_types_aggregate?: Resolver<ResolversTypes['ground_truth_device_types_aggregate'], ParentType, ContextType, RequireFields<Query_RootGround_Truth_Device_Types_AggregateArgs, never>>;
  ground_truth_device_types_by_pk?: Resolver<Maybe<ResolversTypes['ground_truth_device_types']>, ParentType, ContextType, RequireFields<Query_RootGround_Truth_Device_Types_By_PkArgs, 'id'>>;
  ground_truth_processing_modes?: Resolver<Array<ResolversTypes['ground_truth_processing_modes']>, ParentType, ContextType, RequireFields<Query_RootGround_Truth_Processing_ModesArgs, never>>;
  ground_truth_processing_modes_aggregate?: Resolver<ResolversTypes['ground_truth_processing_modes_aggregate'], ParentType, ContextType, RequireFields<Query_RootGround_Truth_Processing_Modes_AggregateArgs, never>>;
  ground_truth_processing_modes_by_pk?: Resolver<Maybe<ResolversTypes['ground_truth_processing_modes']>, ParentType, ContextType, RequireFields<Query_RootGround_Truth_Processing_Modes_By_PkArgs, 'id'>>;
  ground_truth_recordings?: Resolver<Array<ResolversTypes['ground_truth_recordings']>, ParentType, ContextType, RequireFields<Query_RootGround_Truth_RecordingsArgs, never>>;
  ground_truth_recordings_aggregate?: Resolver<ResolversTypes['ground_truth_recordings_aggregate'], ParentType, ContextType, RequireFields<Query_RootGround_Truth_Recordings_AggregateArgs, never>>;
  ground_truth_recordings_by_pk?: Resolver<Maybe<ResolversTypes['ground_truth_recordings']>, ParentType, ContextType, RequireFields<Query_RootGround_Truth_Recordings_By_PkArgs, 'recording_id'>>;
  ground_truth_types?: Resolver<Array<ResolversTypes['ground_truth_types']>, ParentType, ContextType, RequireFields<Query_RootGround_Truth_TypesArgs, never>>;
  ground_truth_types_aggregate?: Resolver<ResolversTypes['ground_truth_types_aggregate'], ParentType, ContextType, RequireFields<Query_RootGround_Truth_Types_AggregateArgs, never>>;
  ground_truth_types_by_pk?: Resolver<Maybe<ResolversTypes['ground_truth_types']>, ParentType, ContextType, RequireFields<Query_RootGround_Truth_Types_By_PkArgs, 'id'>>;
  hypertask_field_option?: Resolver<Array<ResolversTypes['hypertask_field_option']>, ParentType, ContextType, RequireFields<Query_RootHypertask_Field_OptionArgs, never>>;
  hypertask_field_option_aggregate?: Resolver<ResolversTypes['hypertask_field_option_aggregate'], ParentType, ContextType, RequireFields<Query_RootHypertask_Field_Option_AggregateArgs, never>>;
  hypertask_field_option_by_pk?: Resolver<Maybe<ResolversTypes['hypertask_field_option']>, ParentType, ContextType, RequireFields<Query_RootHypertask_Field_Option_By_PkArgs, 'field_id' | 'hypertask_id' | 'option_id'>>;
  hypertask_task?: Resolver<Array<ResolversTypes['hypertask_task']>, ParentType, ContextType, RequireFields<Query_RootHypertask_TaskArgs, never>>;
  hypertask_task_aggregate?: Resolver<ResolversTypes['hypertask_task_aggregate'], ParentType, ContextType, RequireFields<Query_RootHypertask_Task_AggregateArgs, never>>;
  hypertask_task_by_pk?: Resolver<Maybe<ResolversTypes['hypertask_task']>, ParentType, ContextType, RequireFields<Query_RootHypertask_Task_By_PkArgs, 'hypertask_id' | 'task_id'>>;
  hypertasks?: Resolver<Array<ResolversTypes['hypertasks']>, ParentType, ContextType, RequireFields<Query_RootHypertasksArgs, never>>;
  hypertasks_aggregate?: Resolver<ResolversTypes['hypertasks_aggregate'], ParentType, ContextType, RequireFields<Query_RootHypertasks_AggregateArgs, never>>;
  hypertasks_by_pk?: Resolver<Maybe<ResolversTypes['hypertasks']>, ParentType, ContextType, RequireFields<Query_RootHypertasks_By_PkArgs, 'id'>>;
  instruction_translations?: Resolver<Array<ResolversTypes['instruction_translations']>, ParentType, ContextType, RequireFields<Query_RootInstruction_TranslationsArgs, never>>;
  instruction_translations_by_pk?: Resolver<Maybe<ResolversTypes['instruction_translations']>, ParentType, ContextType, RequireFields<Query_RootInstruction_Translations_By_PkArgs, 'id'>>;
  integration_test_expectation_media?: Resolver<Array<ResolversTypes['integration_test_expectation_media']>, ParentType, ContextType, RequireFields<Query_RootIntegration_Test_Expectation_MediaArgs, never>>;
  integration_test_expectation_media_aggregate?: Resolver<ResolversTypes['integration_test_expectation_media_aggregate'], ParentType, ContextType, RequireFields<Query_RootIntegration_Test_Expectation_Media_AggregateArgs, never>>;
  integration_test_expectation_media_by_pk?: Resolver<Maybe<ResolversTypes['integration_test_expectation_media']>, ParentType, ContextType, RequireFields<Query_RootIntegration_Test_Expectation_Media_By_PkArgs, 'expectation_id' | 'media_id'>>;
  integration_test_expectation_step?: Resolver<Array<ResolversTypes['integration_test_expectation_step']>, ParentType, ContextType, RequireFields<Query_RootIntegration_Test_Expectation_StepArgs, never>>;
  integration_test_expectation_step_aggregate?: Resolver<ResolversTypes['integration_test_expectation_step_aggregate'], ParentType, ContextType, RequireFields<Query_RootIntegration_Test_Expectation_Step_AggregateArgs, never>>;
  integration_test_expectation_step_by_pk?: Resolver<Maybe<ResolversTypes['integration_test_expectation_step']>, ParentType, ContextType, RequireFields<Query_RootIntegration_Test_Expectation_Step_By_PkArgs, 'expectation_id' | 'step_id'>>;
  integration_test_expectation_translation?: Resolver<Array<ResolversTypes['integration_test_expectation_translation']>, ParentType, ContextType, RequireFields<Query_RootIntegration_Test_Expectation_TranslationArgs, never>>;
  integration_test_expectation_translation_aggregate?: Resolver<ResolversTypes['integration_test_expectation_translation_aggregate'], ParentType, ContextType, RequireFields<Query_RootIntegration_Test_Expectation_Translation_AggregateArgs, never>>;
  integration_test_expectation_translation_by_pk?: Resolver<Maybe<ResolversTypes['integration_test_expectation_translation']>, ParentType, ContextType, RequireFields<Query_RootIntegration_Test_Expectation_Translation_By_PkArgs, 'expectation_id' | 'language'>>;
  integration_test_expectations?: Resolver<Array<ResolversTypes['integration_test_expectations']>, ParentType, ContextType, RequireFields<Query_RootIntegration_Test_ExpectationsArgs, never>>;
  integration_test_expectations_aggregate?: Resolver<ResolversTypes['integration_test_expectations_aggregate'], ParentType, ContextType, RequireFields<Query_RootIntegration_Test_Expectations_AggregateArgs, never>>;
  integration_test_expectations_by_pk?: Resolver<Maybe<ResolversTypes['integration_test_expectations']>, ParentType, ContextType, RequireFields<Query_RootIntegration_Test_Expectations_By_PkArgs, 'id'>>;
  integration_test_instruction_media?: Resolver<Array<ResolversTypes['integration_test_instruction_media']>, ParentType, ContextType, RequireFields<Query_RootIntegration_Test_Instruction_MediaArgs, never>>;
  integration_test_instruction_media_aggregate?: Resolver<ResolversTypes['integration_test_instruction_media_aggregate'], ParentType, ContextType, RequireFields<Query_RootIntegration_Test_Instruction_Media_AggregateArgs, never>>;
  integration_test_instruction_media_by_pk?: Resolver<Maybe<ResolversTypes['integration_test_instruction_media']>, ParentType, ContextType, RequireFields<Query_RootIntegration_Test_Instruction_Media_By_PkArgs, 'instruction_id' | 'media_id'>>;
  integration_test_instruction_translation?: Resolver<Array<ResolversTypes['integration_test_instruction_translation']>, ParentType, ContextType, RequireFields<Query_RootIntegration_Test_Instruction_TranslationArgs, never>>;
  integration_test_instruction_translation_aggregate?: Resolver<ResolversTypes['integration_test_instruction_translation_aggregate'], ParentType, ContextType, RequireFields<Query_RootIntegration_Test_Instruction_Translation_AggregateArgs, never>>;
  integration_test_instruction_translation_by_pk?: Resolver<Maybe<ResolversTypes['integration_test_instruction_translation']>, ParentType, ContextType, RequireFields<Query_RootIntegration_Test_Instruction_Translation_By_PkArgs, 'instruction_id' | 'language'>>;
  integration_test_instructions?: Resolver<Array<ResolversTypes['integration_test_instructions']>, ParentType, ContextType, RequireFields<Query_RootIntegration_Test_InstructionsArgs, never>>;
  integration_test_instructions_aggregate?: Resolver<ResolversTypes['integration_test_instructions_aggregate'], ParentType, ContextType, RequireFields<Query_RootIntegration_Test_Instructions_AggregateArgs, never>>;
  integration_test_instructions_by_pk?: Resolver<Maybe<ResolversTypes['integration_test_instructions']>, ParentType, ContextType, RequireFields<Query_RootIntegration_Test_Instructions_By_PkArgs, 'id'>>;
  integration_test_observances?: Resolver<Array<ResolversTypes['integration_test_observances']>, ParentType, ContextType, RequireFields<Query_RootIntegration_Test_ObservancesArgs, never>>;
  integration_test_observances_aggregate?: Resolver<ResolversTypes['integration_test_observances_aggregate'], ParentType, ContextType, RequireFields<Query_RootIntegration_Test_Observances_AggregateArgs, never>>;
  integration_test_observances_by_pk?: Resolver<Maybe<ResolversTypes['integration_test_observances']>, ParentType, ContextType, RequireFields<Query_RootIntegration_Test_Observances_By_PkArgs, 'id'>>;
  integration_test_observations?: Resolver<Array<ResolversTypes['integration_test_observations']>, ParentType, ContextType, RequireFields<Query_RootIntegration_Test_ObservationsArgs, never>>;
  integration_test_observations_aggregate?: Resolver<ResolversTypes['integration_test_observations_aggregate'], ParentType, ContextType, RequireFields<Query_RootIntegration_Test_Observations_AggregateArgs, never>>;
  integration_test_observations_by_pk?: Resolver<Maybe<ResolversTypes['integration_test_observations']>, ParentType, ContextType, RequireFields<Query_RootIntegration_Test_Observations_By_PkArgs, 'expectation_id' | 'observance_id' | 'step_id'>>;
  integration_test_project_sequence?: Resolver<Array<ResolversTypes['integration_test_project_sequence']>, ParentType, ContextType, RequireFields<Query_RootIntegration_Test_Project_SequenceArgs, never>>;
  integration_test_project_sequence_aggregate?: Resolver<ResolversTypes['integration_test_project_sequence_aggregate'], ParentType, ContextType, RequireFields<Query_RootIntegration_Test_Project_Sequence_AggregateArgs, never>>;
  integration_test_project_sequence_by_pk?: Resolver<Maybe<ResolversTypes['integration_test_project_sequence']>, ParentType, ContextType, RequireFields<Query_RootIntegration_Test_Project_Sequence_By_PkArgs, 'project_id' | 'sequence_id'>>;
  integration_test_sequences?: Resolver<Array<ResolversTypes['integration_test_sequences']>, ParentType, ContextType, RequireFields<Query_RootIntegration_Test_SequencesArgs, never>>;
  integration_test_sequences_aggregate?: Resolver<ResolversTypes['integration_test_sequences_aggregate'], ParentType, ContextType, RequireFields<Query_RootIntegration_Test_Sequences_AggregateArgs, never>>;
  integration_test_sequences_by_pk?: Resolver<Maybe<ResolversTypes['integration_test_sequences']>, ParentType, ContextType, RequireFields<Query_RootIntegration_Test_Sequences_By_PkArgs, 'id'>>;
  integration_test_steps?: Resolver<Array<ResolversTypes['integration_test_steps']>, ParentType, ContextType, RequireFields<Query_RootIntegration_Test_StepsArgs, never>>;
  integration_test_steps_aggregate?: Resolver<ResolversTypes['integration_test_steps_aggregate'], ParentType, ContextType, RequireFields<Query_RootIntegration_Test_Steps_AggregateArgs, never>>;
  integration_test_steps_by_pk?: Resolver<Maybe<ResolversTypes['integration_test_steps']>, ParentType, ContextType, RequireFields<Query_RootIntegration_Test_Steps_By_PkArgs, 'id'>>;
  languages?: Resolver<Array<ResolversTypes['languages']>, ParentType, ContextType, RequireFields<Query_RootLanguagesArgs, never>>;
  languages_by_pk?: Resolver<Maybe<ResolversTypes['languages']>, ParentType, ContextType, RequireFields<Query_RootLanguages_By_PkArgs, 'id'>>;
  machine_learning_blobs?: Resolver<Array<ResolversTypes['machine_learning_blobs']>, ParentType, ContextType, RequireFields<Query_RootMachine_Learning_BlobsArgs, never>>;
  machine_learning_blobs_aggregate?: Resolver<ResolversTypes['machine_learning_blobs_aggregate'], ParentType, ContextType, RequireFields<Query_RootMachine_Learning_Blobs_AggregateArgs, never>>;
  machine_learning_blobs_by_pk?: Resolver<Maybe<ResolversTypes['machine_learning_blobs']>, ParentType, ContextType, RequireFields<Query_RootMachine_Learning_Blobs_By_PkArgs, 'id'>>;
  machine_learning_dataset_feature?: Resolver<Array<ResolversTypes['machine_learning_dataset_feature']>, ParentType, ContextType, RequireFields<Query_RootMachine_Learning_Dataset_FeatureArgs, never>>;
  machine_learning_dataset_feature_aggregate?: Resolver<ResolversTypes['machine_learning_dataset_feature_aggregate'], ParentType, ContextType, RequireFields<Query_RootMachine_Learning_Dataset_Feature_AggregateArgs, never>>;
  machine_learning_dataset_feature_by_pk?: Resolver<Maybe<ResolversTypes['machine_learning_dataset_feature']>, ParentType, ContextType, RequireFields<Query_RootMachine_Learning_Dataset_Feature_By_PkArgs, 'dataset_id' | 'feature_id'>>;
  machine_learning_datasets?: Resolver<Array<ResolversTypes['machine_learning_datasets']>, ParentType, ContextType, RequireFields<Query_RootMachine_Learning_DatasetsArgs, never>>;
  machine_learning_datasets_aggregate?: Resolver<ResolversTypes['machine_learning_datasets_aggregate'], ParentType, ContextType, RequireFields<Query_RootMachine_Learning_Datasets_AggregateArgs, never>>;
  machine_learning_datasets_by_pk?: Resolver<Maybe<ResolversTypes['machine_learning_datasets']>, ParentType, ContextType, RequireFields<Query_RootMachine_Learning_Datasets_By_PkArgs, 'id'>>;
  machine_learning_features?: Resolver<Array<ResolversTypes['machine_learning_features']>, ParentType, ContextType, RequireFields<Query_RootMachine_Learning_FeaturesArgs, never>>;
  machine_learning_features_by_pk?: Resolver<Maybe<ResolversTypes['machine_learning_features']>, ParentType, ContextType, RequireFields<Query_RootMachine_Learning_Features_By_PkArgs, 'id'>>;
  machine_learning_model_blob?: Resolver<Array<ResolversTypes['machine_learning_model_blob']>, ParentType, ContextType, RequireFields<Query_RootMachine_Learning_Model_BlobArgs, never>>;
  machine_learning_model_blob_by_pk?: Resolver<Maybe<ResolversTypes['machine_learning_model_blob']>, ParentType, ContextType, RequireFields<Query_RootMachine_Learning_Model_Blob_By_PkArgs, 'blob_id' | 'model_id'>>;
  machine_learning_model_dataset?: Resolver<Array<ResolversTypes['machine_learning_model_dataset']>, ParentType, ContextType, RequireFields<Query_RootMachine_Learning_Model_DatasetArgs, never>>;
  machine_learning_model_dataset_by_pk?: Resolver<Maybe<ResolversTypes['machine_learning_model_dataset']>, ParentType, ContextType, RequireFields<Query_RootMachine_Learning_Model_Dataset_By_PkArgs, 'dataset_id' | 'model_id'>>;
  machine_learning_model_recording?: Resolver<Array<ResolversTypes['machine_learning_model_recording']>, ParentType, ContextType, RequireFields<Query_RootMachine_Learning_Model_RecordingArgs, never>>;
  machine_learning_model_recording_aggregate?: Resolver<ResolversTypes['machine_learning_model_recording_aggregate'], ParentType, ContextType, RequireFields<Query_RootMachine_Learning_Model_Recording_AggregateArgs, never>>;
  machine_learning_model_recording_by_pk?: Resolver<Maybe<ResolversTypes['machine_learning_model_recording']>, ParentType, ContextType, RequireFields<Query_RootMachine_Learning_Model_Recording_By_PkArgs, 'model_id' | 'recording_id'>>;
  machine_learning_model_types?: Resolver<Array<ResolversTypes['machine_learning_model_types']>, ParentType, ContextType, RequireFields<Query_RootMachine_Learning_Model_TypesArgs, never>>;
  machine_learning_model_types_by_pk?: Resolver<Maybe<ResolversTypes['machine_learning_model_types']>, ParentType, ContextType, RequireFields<Query_RootMachine_Learning_Model_Types_By_PkArgs, 'id'>>;
  machine_learning_models?: Resolver<Array<ResolversTypes['machine_learning_models']>, ParentType, ContextType, RequireFields<Query_RootMachine_Learning_ModelsArgs, never>>;
  machine_learning_models_by_pk?: Resolver<Maybe<ResolversTypes['machine_learning_models']>, ParentType, ContextType, RequireFields<Query_RootMachine_Learning_Models_By_PkArgs, 'id'>>;
  media?: Resolver<Array<ResolversTypes['media']>, ParentType, ContextType, RequireFields<Query_RootMediaArgs, never>>;
  media_aggregate?: Resolver<ResolversTypes['media_aggregate'], ParentType, ContextType, RequireFields<Query_RootMedia_AggregateArgs, never>>;
  media_by_pk?: Resolver<Maybe<ResolversTypes['media']>, ParentType, ContextType, RequireFields<Query_RootMedia_By_PkArgs, 'id'>>;
  media_type?: Resolver<Array<ResolversTypes['media_type']>, ParentType, ContextType, RequireFields<Query_RootMedia_TypeArgs, never>>;
  media_type_by_pk?: Resolver<Maybe<ResolversTypes['media_type']>, ParentType, ContextType, RequireFields<Query_RootMedia_Type_By_PkArgs, 'id'>>;
  mode_project?: Resolver<Array<ResolversTypes['mode_project']>, ParentType, ContextType, RequireFields<Query_RootMode_ProjectArgs, never>>;
  mode_project_aggregate?: Resolver<ResolversTypes['mode_project_aggregate'], ParentType, ContextType, RequireFields<Query_RootMode_Project_AggregateArgs, never>>;
  mode_project_by_pk?: Resolver<Maybe<ResolversTypes['mode_project']>, ParentType, ContextType, RequireFields<Query_RootMode_Project_By_PkArgs, 'mode_id' | 'project_id'>>;
  model_name_hashes?: Resolver<Array<ResolversTypes['model_name_hashes']>, ParentType, ContextType, RequireFields<Query_RootModel_Name_HashesArgs, never>>;
  model_name_hashes_aggregate?: Resolver<ResolversTypes['model_name_hashes_aggregate'], ParentType, ContextType, RequireFields<Query_RootModel_Name_Hashes_AggregateArgs, never>>;
  model_name_hashes_by_pk?: Resolver<Maybe<ResolversTypes['model_name_hashes']>, ParentType, ContextType, RequireFields<Query_RootModel_Name_Hashes_By_PkArgs, 'hash' | 'model_id'>>;
  models?: Resolver<Array<ResolversTypes['models']>, ParentType, ContextType, RequireFields<Query_RootModelsArgs, never>>;
  models_aggregate?: Resolver<ResolversTypes['models_aggregate'], ParentType, ContextType, RequireFields<Query_RootModels_AggregateArgs, never>>;
  models_by_pk?: Resolver<Maybe<ResolversTypes['models']>, ParentType, ContextType, RequireFields<Query_RootModels_By_PkArgs, 'id'>>;
  opmode_profile?: Resolver<Array<ResolversTypes['opmode_profile']>, ParentType, ContextType, RequireFields<Query_RootOpmode_ProfileArgs, never>>;
  opmode_profile_by_pk?: Resolver<Maybe<ResolversTypes['opmode_profile']>, ParentType, ContextType, RequireFields<Query_RootOpmode_Profile_By_PkArgs, 'opmode_id' | 'profile_id'>>;
  opmode_project?: Resolver<Array<ResolversTypes['opmode_project']>, ParentType, ContextType, RequireFields<Query_RootOpmode_ProjectArgs, never>>;
  opmode_project_aggregate?: Resolver<ResolversTypes['opmode_project_aggregate'], ParentType, ContextType, RequireFields<Query_RootOpmode_Project_AggregateArgs, never>>;
  opmode_project_by_pk?: Resolver<Maybe<ResolversTypes['opmode_project']>, ParentType, ContextType, RequireFields<Query_RootOpmode_Project_By_PkArgs, 'opmode_id' | 'project_id'>>;
  opmodes?: Resolver<Array<ResolversTypes['opmodes']>, ParentType, ContextType, RequireFields<Query_RootOpmodesArgs, never>>;
  opmodes_aggregate?: Resolver<ResolversTypes['opmodes_aggregate'], ParentType, ContextType, RequireFields<Query_RootOpmodes_AggregateArgs, never>>;
  opmodes_by_pk?: Resolver<Maybe<ResolversTypes['opmodes']>, ParentType, ContextType, RequireFields<Query_RootOpmodes_By_PkArgs, 'id'>>;
  option_media?: Resolver<Array<ResolversTypes['option_media']>, ParentType, ContextType, RequireFields<Query_RootOption_MediaArgs, never>>;
  option_media_aggregate?: Resolver<ResolversTypes['option_media_aggregate'], ParentType, ContextType, RequireFields<Query_RootOption_Media_AggregateArgs, never>>;
  option_media_by_pk?: Resolver<Maybe<ResolversTypes['option_media']>, ParentType, ContextType, RequireFields<Query_RootOption_Media_By_PkArgs, 'media_id' | 'option_id'>>;
  option_translation?: Resolver<Array<ResolversTypes['option_translation']>, ParentType, ContextType, RequireFields<Query_RootOption_TranslationArgs, never>>;
  option_translation_aggregate?: Resolver<ResolversTypes['option_translation_aggregate'], ParentType, ContextType, RequireFields<Query_RootOption_Translation_AggregateArgs, never>>;
  option_translation_by_pk?: Resolver<Maybe<ResolversTypes['option_translation']>, ParentType, ContextType, RequireFields<Query_RootOption_Translation_By_PkArgs, 'language' | 'option_id'>>;
  options?: Resolver<Array<ResolversTypes['options']>, ParentType, ContextType, RequireFields<Query_RootOptionsArgs, never>>;
  options_aggregate?: Resolver<ResolversTypes['options_aggregate'], ParentType, ContextType, RequireFields<Query_RootOptions_AggregateArgs, never>>;
  options_by_pk?: Resolver<Maybe<ResolversTypes['options']>, ParentType, ContextType, RequireFields<Query_RootOptions_By_PkArgs, 'id'>>;
  payload_recording?: Resolver<Array<ResolversTypes['payload_recording']>, ParentType, ContextType, RequireFields<Query_RootPayload_RecordingArgs, never>>;
  payload_recording_aggregate?: Resolver<ResolversTypes['payload_recording_aggregate'], ParentType, ContextType, RequireFields<Query_RootPayload_Recording_AggregateArgs, never>>;
  payload_recording_by_pk?: Resolver<Maybe<ResolversTypes['payload_recording']>, ParentType, ContextType, RequireFields<Query_RootPayload_Recording_By_PkArgs, 'payload_id' | 'recording_id'>>;
  payload_types?: Resolver<Array<ResolversTypes['payload_types']>, ParentType, ContextType, RequireFields<Query_RootPayload_TypesArgs, never>>;
  payload_types_aggregate?: Resolver<ResolversTypes['payload_types_aggregate'], ParentType, ContextType, RequireFields<Query_RootPayload_Types_AggregateArgs, never>>;
  payload_types_by_pk?: Resolver<Maybe<ResolversTypes['payload_types']>, ParentType, ContextType, RequireFields<Query_RootPayload_Types_By_PkArgs, 'id'>>;
  payloads?: Resolver<Array<ResolversTypes['payloads']>, ParentType, ContextType, RequireFields<Query_RootPayloadsArgs, never>>;
  payloads_aggregate?: Resolver<ResolversTypes['payloads_aggregate'], ParentType, ContextType, RequireFields<Query_RootPayloads_AggregateArgs, never>>;
  payloads_by_pk?: Resolver<Maybe<ResolversTypes['payloads']>, ParentType, ContextType, RequireFields<Query_RootPayloads_By_PkArgs, 'id'>>;
  performance_ratio_metric_contributions?: Resolver<Array<ResolversTypes['performance_ratio_metric_contributions']>, ParentType, ContextType, RequireFields<Query_RootPerformance_Ratio_Metric_ContributionsArgs, never>>;
  performance_ratio_metric_contributions_aggregate?: Resolver<ResolversTypes['performance_ratio_metric_contributions_aggregate'], ParentType, ContextType, RequireFields<Query_RootPerformance_Ratio_Metric_Contributions_AggregateArgs, never>>;
  performance_ratio_metric_contributions_by_pk?: Resolver<Maybe<ResolversTypes['performance_ratio_metric_contributions']>, ParentType, ContextType, RequireFields<Query_RootPerformance_Ratio_Metric_Contributions_By_PkArgs, 'engine_id' | 'ratio_metric_id' | 'recording_id'>>;
  performance_ratio_metrics?: Resolver<Array<ResolversTypes['performance_ratio_metrics']>, ParentType, ContextType, RequireFields<Query_RootPerformance_Ratio_MetricsArgs, never>>;
  performance_ratio_metrics_aggregate?: Resolver<ResolversTypes['performance_ratio_metrics_aggregate'], ParentType, ContextType, RequireFields<Query_RootPerformance_Ratio_Metrics_AggregateArgs, never>>;
  performance_ratio_metrics_by_pk?: Resolver<Maybe<ResolversTypes['performance_ratio_metrics']>, ParentType, ContextType, RequireFields<Query_RootPerformance_Ratio_Metrics_By_PkArgs, 'id'>>;
  performance_report_deliverable_template?: Resolver<Array<ResolversTypes['performance_report_deliverable_template']>, ParentType, ContextType, RequireFields<Query_RootPerformance_Report_Deliverable_TemplateArgs, never>>;
  performance_report_deliverable_template_aggregate?: Resolver<ResolversTypes['performance_report_deliverable_template_aggregate'], ParentType, ContextType, RequireFields<Query_RootPerformance_Report_Deliverable_Template_AggregateArgs, never>>;
  performance_report_deliverable_template_by_pk?: Resolver<Maybe<ResolversTypes['performance_report_deliverable_template']>, ParentType, ContextType, RequireFields<Query_RootPerformance_Report_Deliverable_Template_By_PkArgs, 'deliverable_id' | 'template_id'>>;
  performance_report_pivot_types?: Resolver<Array<ResolversTypes['performance_report_pivot_types']>, ParentType, ContextType, RequireFields<Query_RootPerformance_Report_Pivot_TypesArgs, never>>;
  performance_report_pivot_types_aggregate?: Resolver<ResolversTypes['performance_report_pivot_types_aggregate'], ParentType, ContextType, RequireFields<Query_RootPerformance_Report_Pivot_Types_AggregateArgs, never>>;
  performance_report_pivot_types_by_pk?: Resolver<Maybe<ResolversTypes['performance_report_pivot_types']>, ParentType, ContextType, RequireFields<Query_RootPerformance_Report_Pivot_Types_By_PkArgs, 'id'>>;
  performance_report_section_pivot_field?: Resolver<Array<ResolversTypes['performance_report_section_pivot_field']>, ParentType, ContextType, RequireFields<Query_RootPerformance_Report_Section_Pivot_FieldArgs, never>>;
  performance_report_section_pivot_field_aggregate?: Resolver<ResolversTypes['performance_report_section_pivot_field_aggregate'], ParentType, ContextType, RequireFields<Query_RootPerformance_Report_Section_Pivot_Field_AggregateArgs, never>>;
  performance_report_section_pivot_field_by_pk?: Resolver<Maybe<ResolversTypes['performance_report_section_pivot_field']>, ParentType, ContextType, RequireFields<Query_RootPerformance_Report_Section_Pivot_Field_By_PkArgs, 'field_id' | 'section_id'>>;
  performance_report_section_ratio_metric?: Resolver<Array<ResolversTypes['performance_report_section_ratio_metric']>, ParentType, ContextType, RequireFields<Query_RootPerformance_Report_Section_Ratio_MetricArgs, never>>;
  performance_report_section_ratio_metric_aggregate?: Resolver<ResolversTypes['performance_report_section_ratio_metric_aggregate'], ParentType, ContextType, RequireFields<Query_RootPerformance_Report_Section_Ratio_Metric_AggregateArgs, never>>;
  performance_report_section_ratio_metric_by_pk?: Resolver<Maybe<ResolversTypes['performance_report_section_ratio_metric']>, ParentType, ContextType, RequireFields<Query_RootPerformance_Report_Section_Ratio_Metric_By_PkArgs, 'ratio_metric_id' | 'section_id'>>;
  performance_report_section_task_blacklist?: Resolver<Array<ResolversTypes['performance_report_section_task_blacklist']>, ParentType, ContextType, RequireFields<Query_RootPerformance_Report_Section_Task_BlacklistArgs, never>>;
  performance_report_section_task_blacklist_aggregate?: Resolver<ResolversTypes['performance_report_section_task_blacklist_aggregate'], ParentType, ContextType, RequireFields<Query_RootPerformance_Report_Section_Task_Blacklist_AggregateArgs, never>>;
  performance_report_section_task_blacklist_by_pk?: Resolver<Maybe<ResolversTypes['performance_report_section_task_blacklist']>, ParentType, ContextType, RequireFields<Query_RootPerformance_Report_Section_Task_Blacklist_By_PkArgs, 'section_id' | 'task_id'>>;
  performance_report_sections?: Resolver<Array<ResolversTypes['performance_report_sections']>, ParentType, ContextType, RequireFields<Query_RootPerformance_Report_SectionsArgs, never>>;
  performance_report_sections_aggregate?: Resolver<ResolversTypes['performance_report_sections_aggregate'], ParentType, ContextType, RequireFields<Query_RootPerformance_Report_Sections_AggregateArgs, never>>;
  performance_report_sections_by_pk?: Resolver<Maybe<ResolversTypes['performance_report_sections']>, ParentType, ContextType, RequireFields<Query_RootPerformance_Report_Sections_By_PkArgs, 'id'>>;
  performance_report_templates?: Resolver<Array<ResolversTypes['performance_report_templates']>, ParentType, ContextType, RequireFields<Query_RootPerformance_Report_TemplatesArgs, never>>;
  performance_report_templates_aggregate?: Resolver<ResolversTypes['performance_report_templates_aggregate'], ParentType, ContextType, RequireFields<Query_RootPerformance_Report_Templates_AggregateArgs, never>>;
  performance_report_templates_by_pk?: Resolver<Maybe<ResolversTypes['performance_report_templates']>, ParentType, ContextType, RequireFields<Query_RootPerformance_Report_Templates_By_PkArgs, 'id'>>;
  peripherals?: Resolver<Array<ResolversTypes['peripherals']>, ParentType, ContextType, RequireFields<Query_RootPeripheralsArgs, never>>;
  peripherals_by_pk?: Resolver<Maybe<ResolversTypes['peripherals']>, ParentType, ContextType, RequireFields<Query_RootPeripherals_By_PkArgs, 'id'>>;
  plan_hypertasks?: Resolver<Array<ResolversTypes['plan_hypertasks']>, ParentType, ContextType, RequireFields<Query_RootPlan_HypertasksArgs, never>>;
  plan_hypertasks_aggregate?: Resolver<ResolversTypes['plan_hypertasks_aggregate'], ParentType, ContextType, RequireFields<Query_RootPlan_Hypertasks_AggregateArgs, never>>;
  plan_hypertasks_by_pk?: Resolver<Maybe<ResolversTypes['plan_hypertasks']>, ParentType, ContextType, RequireFields<Query_RootPlan_Hypertasks_By_PkArgs, 'hypertask_id' | 'plan_id'>>;
  plan_tasks?: Resolver<Array<ResolversTypes['plan_tasks']>, ParentType, ContextType, RequireFields<Query_RootPlan_TasksArgs, never>>;
  plan_tasks_aggregate?: Resolver<ResolversTypes['plan_tasks_aggregate'], ParentType, ContextType, RequireFields<Query_RootPlan_Tasks_AggregateArgs, never>>;
  plan_tasks_by_pk?: Resolver<Maybe<ResolversTypes['plan_tasks']>, ParentType, ContextType, RequireFields<Query_RootPlan_Tasks_By_PkArgs, 'id'>>;
  plans?: Resolver<Array<ResolversTypes['plans']>, ParentType, ContextType, RequireFields<Query_RootPlansArgs, never>>;
  plans_aggregate?: Resolver<ResolversTypes['plans_aggregate'], ParentType, ContextType, RequireFields<Query_RootPlans_AggregateArgs, never>>;
  plans_by_pk?: Resolver<Maybe<ResolversTypes['plans']>, ParentType, ContextType, RequireFields<Query_RootPlans_By_PkArgs, 'id'>>;
  profiles?: Resolver<Array<ResolversTypes['profiles']>, ParentType, ContextType, RequireFields<Query_RootProfilesArgs, never>>;
  profiles_aggregate?: Resolver<ResolversTypes['profiles_aggregate'], ParentType, ContextType, RequireFields<Query_RootProfiles_AggregateArgs, never>>;
  profiles_by_pk?: Resolver<Maybe<ResolversTypes['profiles']>, ParentType, ContextType, RequireFields<Query_RootProfiles_By_PkArgs, 'id'>>;
  project_combo?: Resolver<Array<ResolversTypes['project_combo']>, ParentType, ContextType, RequireFields<Query_RootProject_ComboArgs, never>>;
  project_combo_aggregate?: Resolver<ResolversTypes['project_combo_aggregate'], ParentType, ContextType, RequireFields<Query_RootProject_Combo_AggregateArgs, never>>;
  project_combo_by_pk?: Resolver<Maybe<ResolversTypes['project_combo']>, ParentType, ContextType, RequireFields<Query_RootProject_Combo_By_PkArgs, 'other_project_id' | 'project_id'>>;
  project_dataset?: Resolver<Array<ResolversTypes['project_dataset']>, ParentType, ContextType, RequireFields<Query_RootProject_DatasetArgs, never>>;
  project_dataset_aggregate?: Resolver<ResolversTypes['project_dataset_aggregate'], ParentType, ContextType, RequireFields<Query_RootProject_Dataset_AggregateArgs, never>>;
  project_engine_metrics?: Resolver<Array<ResolversTypes['schema_project_engine_metrics']>, ParentType, ContextType, RequireFields<Query_RootProject_Engine_MetricsArgs, 'args'>>;
  project_engine_ratio_metrics?: Resolver<Array<ResolversTypes['schema_project_engine_ratio_metrics']>, ParentType, ContextType, RequireFields<Query_RootProject_Engine_Ratio_MetricsArgs, 'args'>>;
  project_engine_ratio_metrics_aggregate?: Resolver<ResolversTypes['schema_project_engine_ratio_metrics_aggregate'], ParentType, ContextType, RequireFields<Query_RootProject_Engine_Ratio_Metrics_AggregateArgs, 'args'>>;
  project_fields?: Resolver<Array<ResolversTypes['fields']>, ParentType, ContextType, RequireFields<Query_RootProject_FieldsArgs, 'args'>>;
  project_fields_aggregate?: Resolver<ResolversTypes['fields_aggregate'], ParentType, ContextType, RequireFields<Query_RootProject_Fields_AggregateArgs, 'args'>>;
  project_hypertasks?: Resolver<Array<ResolversTypes['project_hypertasks']>, ParentType, ContextType, RequireFields<Query_RootProject_HypertasksArgs, never>>;
  project_hypertasks_aggregate?: Resolver<ResolversTypes['project_hypertasks_aggregate'], ParentType, ContextType, RequireFields<Query_RootProject_Hypertasks_AggregateArgs, never>>;
  project_hypertasks_by_pk?: Resolver<Maybe<ResolversTypes['project_hypertasks']>, ParentType, ContextType, RequireFields<Query_RootProject_Hypertasks_By_PkArgs, 'hypertask_id' | 'project_id'>>;
  project_metrics?: Resolver<Array<ResolversTypes['project_metrics']>, ParentType, ContextType, RequireFields<Query_RootProject_MetricsArgs, never>>;
  project_metrics_aggregate?: Resolver<ResolversTypes['project_metrics_aggregate'], ParentType, ContextType, RequireFields<Query_RootProject_Metrics_AggregateArgs, never>>;
  project_name?: Resolver<Array<ResolversTypes['project_name']>, ParentType, ContextType, RequireFields<Query_RootProject_NameArgs, never>>;
  project_rabbit_test_instances?: Resolver<Array<ResolversTypes['project_rabbit_test_instances']>, ParentType, ContextType, RequireFields<Query_RootProject_Rabbit_Test_InstancesArgs, never>>;
  project_rabbit_test_instances_aggregate?: Resolver<ResolversTypes['project_rabbit_test_instances_aggregate'], ParentType, ContextType, RequireFields<Query_RootProject_Rabbit_Test_Instances_AggregateArgs, never>>;
  project_recordings_clean?: Resolver<Array<ResolversTypes['recordings']>, ParentType, ContextType, RequireFields<Query_RootProject_Recordings_CleanArgs, 'args'>>;
  project_recordings_clean_aggregate?: Resolver<ResolversTypes['recordings_aggregate'], ParentType, ContextType, RequireFields<Query_RootProject_Recordings_Clean_AggregateArgs, 'args'>>;
  project_recordings_unreviewed?: Resolver<Array<ResolversTypes['recordings']>, ParentType, ContextType, RequireFields<Query_RootProject_Recordings_UnreviewedArgs, 'args'>>;
  project_recordings_unreviewed_aggregate?: Resolver<ResolversTypes['recordings_aggregate'], ParentType, ContextType, RequireFields<Query_RootProject_Recordings_Unreviewed_AggregateArgs, 'args'>>;
  project_task_count?: Resolver<Array<ResolversTypes['schema_project_task_count']>, ParentType, ContextType, RequireFields<Query_RootProject_Task_CountArgs, 'args'>>;
  project_task_count_aggregate?: Resolver<ResolversTypes['schema_project_task_count_aggregate'], ParentType, ContextType, RequireFields<Query_RootProject_Task_Count_AggregateArgs, 'args'>>;
  project_task_count_by_device?: Resolver<Array<ResolversTypes['schema_project_task_count_by_device']>, ParentType, ContextType, RequireFields<Query_RootProject_Task_Count_By_DeviceArgs, 'args'>>;
  project_task_count_by_device_aggregate?: Resolver<ResolversTypes['schema_project_task_count_by_device_aggregate'], ParentType, ContextType, RequireFields<Query_RootProject_Task_Count_By_Device_AggregateArgs, 'args'>>;
  project_tasks?: Resolver<Array<ResolversTypes['project_tasks']>, ParentType, ContextType, RequireFields<Query_RootProject_TasksArgs, never>>;
  project_tasks_aggregate?: Resolver<ResolversTypes['project_tasks_aggregate'], ParentType, ContextType, RequireFields<Query_RootProject_Tasks_AggregateArgs, never>>;
  project_tasks_by_pk?: Resolver<Maybe<ResolversTypes['project_tasks']>, ParentType, ContextType, RequireFields<Query_RootProject_Tasks_By_PkArgs, 'id'>>;
  project_user?: Resolver<Array<ResolversTypes['project_user']>, ParentType, ContextType, RequireFields<Query_RootProject_UserArgs, never>>;
  project_user_aggregate?: Resolver<ResolversTypes['project_user_aggregate'], ParentType, ContextType, RequireFields<Query_RootProject_User_AggregateArgs, never>>;
  project_user_by_pk?: Resolver<Maybe<ResolversTypes['project_user']>, ParentType, ContextType, RequireFields<Query_RootProject_User_By_PkArgs, 'project_id' | 'user_id'>>;
  projects?: Resolver<Array<ResolversTypes['projects']>, ParentType, ContextType, RequireFields<Query_RootProjectsArgs, never>>;
  projects_aggregate?: Resolver<ResolversTypes['projects_aggregate'], ParentType, ContextType, RequireFields<Query_RootProjects_AggregateArgs, never>>;
  projects_by_pk?: Resolver<Maybe<ResolversTypes['projects']>, ParentType, ContextType, RequireFields<Query_RootProjects_By_PkArgs, 'id'>>;
  rabbit_test_plan_project?: Resolver<Array<ResolversTypes['rabbit_test_plan_project']>, ParentType, ContextType, RequireFields<Query_RootRabbit_Test_Plan_ProjectArgs, never>>;
  rabbit_test_plan_project_aggregate?: Resolver<ResolversTypes['rabbit_test_plan_project_aggregate'], ParentType, ContextType, RequireFields<Query_RootRabbit_Test_Plan_Project_AggregateArgs, never>>;
  rabbit_test_plan_project_by_pk?: Resolver<Maybe<ResolversTypes['rabbit_test_plan_project']>, ParentType, ContextType, RequireFields<Query_RootRabbit_Test_Plan_Project_By_PkArgs, 'plan_id' | 'project_id'>>;
  rabbit_test_plan_recording?: Resolver<Array<ResolversTypes['rabbit_test_plan_recording']>, ParentType, ContextType, RequireFields<Query_RootRabbit_Test_Plan_RecordingArgs, never>>;
  rabbit_test_plan_recording_aggregate?: Resolver<ResolversTypes['rabbit_test_plan_recording_aggregate'], ParentType, ContextType, RequireFields<Query_RootRabbit_Test_Plan_Recording_AggregateArgs, never>>;
  rabbit_test_plan_recording_by_pk?: Resolver<Maybe<ResolversTypes['rabbit_test_plan_recording']>, ParentType, ContextType, RequireFields<Query_RootRabbit_Test_Plan_Recording_By_PkArgs, 'recording_id'>>;
  rabbit_test_plan_task?: Resolver<Array<ResolversTypes['rabbit_test_plan_task']>, ParentType, ContextType, RequireFields<Query_RootRabbit_Test_Plan_TaskArgs, never>>;
  rabbit_test_plan_task_aggregate?: Resolver<ResolversTypes['rabbit_test_plan_task_aggregate'], ParentType, ContextType, RequireFields<Query_RootRabbit_Test_Plan_Task_AggregateArgs, never>>;
  rabbit_test_plan_task_by_pk?: Resolver<Maybe<ResolversTypes['rabbit_test_plan_task']>, ParentType, ContextType, RequireFields<Query_RootRabbit_Test_Plan_Task_By_PkArgs, 'plan_id' | 'task_id'>>;
  rabbit_test_plans?: Resolver<Array<ResolversTypes['rabbit_test_plans']>, ParentType, ContextType, RequireFields<Query_RootRabbit_Test_PlansArgs, never>>;
  rabbit_test_plans_aggregate?: Resolver<ResolversTypes['rabbit_test_plans_aggregate'], ParentType, ContextType, RequireFields<Query_RootRabbit_Test_Plans_AggregateArgs, never>>;
  rabbit_test_plans_by_pk?: Resolver<Maybe<ResolversTypes['rabbit_test_plans']>, ParentType, ContextType, RequireFields<Query_RootRabbit_Test_Plans_By_PkArgs, 'id'>>;
  recording_apps?: Resolver<Array<ResolversTypes['recording_apps']>, ParentType, ContextType, RequireFields<Query_RootRecording_AppsArgs, never>>;
  recording_apps_aggregate?: Resolver<ResolversTypes['recording_apps_aggregate'], ParentType, ContextType, RequireFields<Query_RootRecording_Apps_AggregateArgs, never>>;
  recording_apps_by_pk?: Resolver<Maybe<ResolversTypes['recording_apps']>, ParentType, ContextType, RequireFields<Query_RootRecording_Apps_By_PkArgs, 'id'>>;
  recording_audio_driver?: Resolver<Array<ResolversTypes['recording_audio_driver']>, ParentType, ContextType, RequireFields<Query_RootRecording_Audio_DriverArgs, never>>;
  recording_audio_driver_by_pk?: Resolver<Maybe<ResolversTypes['recording_audio_driver']>, ParentType, ContextType, RequireFields<Query_RootRecording_Audio_Driver_By_PkArgs, 'audio_driver_id' | 'recording_id'>>;
  recording_data_ground_truth?: Resolver<Array<ResolversTypes['recording_data_ground_truth']>, ParentType, ContextType, RequireFields<Query_RootRecording_Data_Ground_TruthArgs, never>>;
  recording_data_ground_truth_aggregate?: Resolver<ResolversTypes['recording_data_ground_truth_aggregate'], ParentType, ContextType, RequireFields<Query_RootRecording_Data_Ground_Truth_AggregateArgs, never>>;
  recording_data_ground_truth_by_pk?: Resolver<Maybe<ResolversTypes['recording_data_ground_truth']>, ParentType, ContextType, RequireFields<Query_RootRecording_Data_Ground_Truth_By_PkArgs, 'recording_id'>>;
  recording_data_labels?: Resolver<Array<ResolversTypes['recording_data_labels']>, ParentType, ContextType, RequireFields<Query_RootRecording_Data_LabelsArgs, never>>;
  recording_data_labels_aggregate?: Resolver<ResolversTypes['recording_data_labels_aggregate'], ParentType, ContextType, RequireFields<Query_RootRecording_Data_Labels_AggregateArgs, never>>;
  recording_data_labels_by_pk?: Resolver<Maybe<ResolversTypes['recording_data_labels']>, ParentType, ContextType, RequireFields<Query_RootRecording_Data_Labels_By_PkArgs, 'mode_id' | 'recording_id'>>;
  recording_data_mode_parameter?: Resolver<Array<ResolversTypes['recording_data_mode_parameter']>, ParentType, ContextType, RequireFields<Query_RootRecording_Data_Mode_ParameterArgs, never>>;
  recording_data_mode_parameter_aggregate?: Resolver<ResolversTypes['recording_data_mode_parameter_aggregate'], ParentType, ContextType, RequireFields<Query_RootRecording_Data_Mode_Parameter_AggregateArgs, never>>;
  recording_data_mode_parameter_by_pk?: Resolver<Maybe<ResolversTypes['recording_data_mode_parameter']>, ParentType, ContextType, RequireFields<Query_RootRecording_Data_Mode_Parameter_By_PkArgs, 'mode_id' | 'parameter_id'>>;
  recording_data_modes?: Resolver<Array<ResolversTypes['recording_data_modes']>, ParentType, ContextType, RequireFields<Query_RootRecording_Data_ModesArgs, never>>;
  recording_data_modes_aggregate?: Resolver<ResolversTypes['recording_data_modes_aggregate'], ParentType, ContextType, RequireFields<Query_RootRecording_Data_Modes_AggregateArgs, never>>;
  recording_data_modes_by_pk?: Resolver<Maybe<ResolversTypes['recording_data_modes']>, ParentType, ContextType, RequireFields<Query_RootRecording_Data_Modes_By_PkArgs, 'id'>>;
  recording_data_parameters?: Resolver<Array<ResolversTypes['recording_data_parameters']>, ParentType, ContextType, RequireFields<Query_RootRecording_Data_ParametersArgs, never>>;
  recording_data_parameters_aggregate?: Resolver<ResolversTypes['recording_data_parameters_aggregate'], ParentType, ContextType, RequireFields<Query_RootRecording_Data_Parameters_AggregateArgs, never>>;
  recording_data_parameters_by_pk?: Resolver<Maybe<ResolversTypes['recording_data_parameters']>, ParentType, ContextType, RequireFields<Query_RootRecording_Data_Parameters_By_PkArgs, 'id'>>;
  recording_data_payload_pair?: Resolver<Array<ResolversTypes['recording_data_payload_pair']>, ParentType, ContextType, RequireFields<Query_RootRecording_Data_Payload_PairArgs, never>>;
  recording_data_payload_pair_by_pk?: Resolver<Maybe<ResolversTypes['recording_data_payload_pair']>, ParentType, ContextType, RequireFields<Query_RootRecording_Data_Payload_Pair_By_PkArgs, 'payload_id'>>;
  recording_data_predictions?: Resolver<Array<ResolversTypes['recording_data_predictions']>, ParentType, ContextType, RequireFields<Query_RootRecording_Data_PredictionsArgs, never>>;
  recording_data_predictions_aggregate?: Resolver<ResolversTypes['recording_data_predictions_aggregate'], ParentType, ContextType, RequireFields<Query_RootRecording_Data_Predictions_AggregateArgs, never>>;
  recording_data_predictions_by_pk?: Resolver<Maybe<ResolversTypes['recording_data_predictions']>, ParentType, ContextType, RequireFields<Query_RootRecording_Data_Predictions_By_PkArgs, 'engine_id' | 'recording_id'>>;
  recording_recording_tag?: Resolver<Array<ResolversTypes['recording_recording_tag']>, ParentType, ContextType, RequireFields<Query_RootRecording_Recording_TagArgs, never>>;
  recording_recording_tag_by_pk?: Resolver<Maybe<ResolversTypes['recording_recording_tag']>, ParentType, ContextType, RequireFields<Query_RootRecording_Recording_Tag_By_PkArgs, 'recording_id' | 'recording_tag_id'>>;
  recording_tag_count_by_day?: Resolver<Array<ResolversTypes['schema_recording_tag_count_by_day']>, ParentType, ContextType, RequireFields<Query_RootRecording_Tag_Count_By_DayArgs, 'args'>>;
  recording_tag_count_by_day_aggregate?: Resolver<ResolversTypes['schema_recording_tag_count_by_day_aggregate'], ParentType, ContextType, RequireFields<Query_RootRecording_Tag_Count_By_Day_AggregateArgs, 'args'>>;
  recording_tags?: Resolver<Array<ResolversTypes['recording_tags']>, ParentType, ContextType, RequireFields<Query_RootRecording_TagsArgs, never>>;
  recording_tags_by_pk?: Resolver<Maybe<ResolversTypes['recording_tags']>, ParentType, ContextType, RequireFields<Query_RootRecording_Tags_By_PkArgs, 'id'>>;
  recordings?: Resolver<Array<ResolversTypes['recordings']>, ParentType, ContextType, RequireFields<Query_RootRecordingsArgs, never>>;
  recordings_aggregate?: Resolver<ResolversTypes['recordings_aggregate'], ParentType, ContextType, RequireFields<Query_RootRecordings_AggregateArgs, never>>;
  recordings_by_pk?: Resolver<Maybe<ResolversTypes['recordings']>, ParentType, ContextType, RequireFields<Query_RootRecordings_By_PkArgs, 'id'>>;
  role_user?: Resolver<Array<ResolversTypes['role_user']>, ParentType, ContextType, RequireFields<Query_RootRole_UserArgs, never>>;
  role_user_aggregate?: Resolver<ResolversTypes['role_user_aggregate'], ParentType, ContextType, RequireFields<Query_RootRole_User_AggregateArgs, never>>;
  role_user_by_pk?: Resolver<Maybe<ResolversTypes['role_user']>, ParentType, ContextType, RequireFields<Query_RootRole_User_By_PkArgs, 'role_id' | 'user_id'>>;
  roles?: Resolver<Array<ResolversTypes['roles']>, ParentType, ContextType, RequireFields<Query_RootRolesArgs, never>>;
  roles_aggregate?: Resolver<ResolversTypes['roles_aggregate'], ParentType, ContextType, RequireFields<Query_RootRoles_AggregateArgs, never>>;
  roles_by_pk?: Resolver<Maybe<ResolversTypes['roles']>, ParentType, ContextType, RequireFields<Query_RootRoles_By_PkArgs, 'id'>>;
  rooms?: Resolver<Array<ResolversTypes['rooms']>, ParentType, ContextType, RequireFields<Query_RootRoomsArgs, never>>;
  rooms_by_pk?: Resolver<Maybe<ResolversTypes['rooms']>, ParentType, ContextType, RequireFields<Query_RootRooms_By_PkArgs, 'id'>>;
  schema_project_engine_metrics?: Resolver<Array<ResolversTypes['schema_project_engine_metrics']>, ParentType, ContextType, RequireFields<Query_RootSchema_Project_Engine_MetricsArgs, never>>;
  schema_project_engine_ratio_metrics?: Resolver<Array<ResolversTypes['schema_project_engine_ratio_metrics']>, ParentType, ContextType, RequireFields<Query_RootSchema_Project_Engine_Ratio_MetricsArgs, never>>;
  schema_project_engine_ratio_metrics_aggregate?: Resolver<ResolversTypes['schema_project_engine_ratio_metrics_aggregate'], ParentType, ContextType, RequireFields<Query_RootSchema_Project_Engine_Ratio_Metrics_AggregateArgs, never>>;
  schema_project_engine_ratio_metrics_by_pk?: Resolver<Maybe<ResolversTypes['schema_project_engine_ratio_metrics']>, ParentType, ContextType, RequireFields<Query_RootSchema_Project_Engine_Ratio_Metrics_By_PkArgs, 'engine_id' | 'project_id' | 'ratio_metric_id'>>;
  schema_project_task_count?: Resolver<Array<ResolversTypes['schema_project_task_count']>, ParentType, ContextType, RequireFields<Query_RootSchema_Project_Task_CountArgs, never>>;
  schema_project_task_count_aggregate?: Resolver<ResolversTypes['schema_project_task_count_aggregate'], ParentType, ContextType, RequireFields<Query_RootSchema_Project_Task_Count_AggregateArgs, never>>;
  schema_project_task_count_by_device?: Resolver<Array<ResolversTypes['schema_project_task_count_by_device']>, ParentType, ContextType, RequireFields<Query_RootSchema_Project_Task_Count_By_DeviceArgs, never>>;
  schema_project_task_count_by_device_aggregate?: Resolver<ResolversTypes['schema_project_task_count_by_device_aggregate'], ParentType, ContextType, RequireFields<Query_RootSchema_Project_Task_Count_By_Device_AggregateArgs, never>>;
  schema_project_task_count_by_device_by_pk?: Resolver<Maybe<ResolversTypes['schema_project_task_count_by_device']>, ParentType, ContextType, RequireFields<Query_RootSchema_Project_Task_Count_By_Device_By_PkArgs, 'device_id' | 'project_id' | 'task_id'>>;
  schema_project_task_count_by_pk?: Resolver<Maybe<ResolversTypes['schema_project_task_count']>, ParentType, ContextType, RequireFields<Query_RootSchema_Project_Task_Count_By_PkArgs, 'project_id' | 'task_id'>>;
  schema_recording_tag_count_by_day?: Resolver<Array<ResolversTypes['schema_recording_tag_count_by_day']>, ParentType, ContextType, RequireFields<Query_RootSchema_Recording_Tag_Count_By_DayArgs, never>>;
  schema_recording_tag_count_by_day_aggregate?: Resolver<ResolversTypes['schema_recording_tag_count_by_day_aggregate'], ParentType, ContextType, RequireFields<Query_RootSchema_Recording_Tag_Count_By_Day_AggregateArgs, never>>;
  schema_recording_tag_count_by_day_by_pk?: Resolver<Maybe<ResolversTypes['schema_recording_tag_count_by_day']>, ParentType, ContextType, RequireFields<Query_RootSchema_Recording_Tag_Count_By_Day_By_PkArgs, 'day' | 'project_id' | 'tag_id'>>;
  simulations_scores?: Resolver<Array<ResolversTypes['simulations_scores']>, ParentType, ContextType, RequireFields<Query_RootSimulations_ScoresArgs, never>>;
  simulations_scores_by_pk?: Resolver<Maybe<ResolversTypes['simulations_scores']>, ParentType, ContextType, RequireFields<Query_RootSimulations_Scores_By_PkArgs, 'id'>>;
  task_field_option?: Resolver<Array<ResolversTypes['task_field_option']>, ParentType, ContextType, RequireFields<Query_RootTask_Field_OptionArgs, never>>;
  task_field_option_aggregate?: Resolver<ResolversTypes['task_field_option_aggregate'], ParentType, ContextType, RequireFields<Query_RootTask_Field_Option_AggregateArgs, never>>;
  task_field_option_by_pk?: Resolver<Maybe<ResolversTypes['task_field_option']>, ParentType, ContextType, RequireFields<Query_RootTask_Field_Option_By_PkArgs, 'field_option_id' | 'task_id'>>;
  task_field_option_hashes?: Resolver<Array<ResolversTypes['task_field_option_hashes']>, ParentType, ContextType, RequireFields<Query_RootTask_Field_Option_HashesArgs, never>>;
  task_field_option_hashes_aggregate?: Resolver<ResolversTypes['task_field_option_hashes_aggregate'], ParentType, ContextType, RequireFields<Query_RootTask_Field_Option_Hashes_AggregateArgs, never>>;
  task_groups_group_fields?: Resolver<Array<ResolversTypes['task_groups_group_fields']>, ParentType, ContextType, RequireFields<Query_RootTask_Groups_Group_FieldsArgs, never>>;
  task_groups_group_fields_aggregate?: Resolver<ResolversTypes['task_groups_group_fields_aggregate'], ParentType, ContextType, RequireFields<Query_RootTask_Groups_Group_Fields_AggregateArgs, never>>;
  task_groups_group_fields_by_pk?: Resolver<Maybe<ResolversTypes['task_groups_group_fields']>, ParentType, ContextType, RequireFields<Query_RootTask_Groups_Group_Fields_By_PkArgs, 'field_id' | 'group_id'>>;
  task_groups_groups?: Resolver<Array<ResolversTypes['task_groups_groups']>, ParentType, ContextType, RequireFields<Query_RootTask_Groups_GroupsArgs, never>>;
  task_groups_groups_aggregate?: Resolver<ResolversTypes['task_groups_groups_aggregate'], ParentType, ContextType, RequireFields<Query_RootTask_Groups_Groups_AggregateArgs, never>>;
  task_groups_groups_by_pk?: Resolver<Maybe<ResolversTypes['task_groups_groups']>, ParentType, ContextType, RequireFields<Query_RootTask_Groups_Groups_By_PkArgs, 'id'>>;
  task_groups_hierarchies?: Resolver<Array<ResolversTypes['task_groups_hierarchies']>, ParentType, ContextType, RequireFields<Query_RootTask_Groups_HierarchiesArgs, never>>;
  task_groups_hierarchies_aggregate?: Resolver<ResolversTypes['task_groups_hierarchies_aggregate'], ParentType, ContextType, RequireFields<Query_RootTask_Groups_Hierarchies_AggregateArgs, never>>;
  task_groups_hierarchies_by_pk?: Resolver<Maybe<ResolversTypes['task_groups_hierarchies']>, ParentType, ContextType, RequireFields<Query_RootTask_Groups_Hierarchies_By_PkArgs, 'id'>>;
  task_groups_hierarchy_groups?: Resolver<Array<ResolversTypes['task_groups_hierarchy_groups']>, ParentType, ContextType, RequireFields<Query_RootTask_Groups_Hierarchy_GroupsArgs, never>>;
  task_groups_hierarchy_groups_aggregate?: Resolver<ResolversTypes['task_groups_hierarchy_groups_aggregate'], ParentType, ContextType, RequireFields<Query_RootTask_Groups_Hierarchy_Groups_AggregateArgs, never>>;
  task_groups_hierarchy_groups_by_pk?: Resolver<Maybe<ResolversTypes['task_groups_hierarchy_groups']>, ParentType, ContextType, RequireFields<Query_RootTask_Groups_Hierarchy_Groups_By_PkArgs, 'group_id' | 'hierarchy_id'>>;
  task_instructions?: Resolver<Array<ResolversTypes['task_instructions']>, ParentType, ContextType, RequireFields<Query_RootTask_InstructionsArgs, never>>;
  task_instructions_by_pk?: Resolver<Maybe<ResolversTypes['task_instructions']>, ParentType, ContextType, RequireFields<Query_RootTask_Instructions_By_PkArgs, 'id'>>;
  task_media?: Resolver<Array<ResolversTypes['task_media']>, ParentType, ContextType, RequireFields<Query_RootTask_MediaArgs, never>>;
  task_media_aggregate?: Resolver<ResolversTypes['task_media_aggregate'], ParentType, ContextType, RequireFields<Query_RootTask_Media_AggregateArgs, never>>;
  task_media_by_pk?: Resolver<Maybe<ResolversTypes['task_media']>, ParentType, ContextType, RequireFields<Query_RootTask_Media_By_PkArgs, 'media_id' | 'task_id'>>;
  task_statuses?: Resolver<Array<ResolversTypes['task_statuses']>, ParentType, ContextType, RequireFields<Query_RootTask_StatusesArgs, never>>;
  task_statuses_by_pk?: Resolver<Maybe<ResolversTypes['task_statuses']>, ParentType, ContextType, RequireFields<Query_RootTask_Statuses_By_PkArgs, 'id'>>;
  task_translation?: Resolver<Array<ResolversTypes['task_translation']>, ParentType, ContextType, RequireFields<Query_RootTask_TranslationArgs, never>>;
  task_translation_aggregate?: Resolver<ResolversTypes['task_translation_aggregate'], ParentType, ContextType, RequireFields<Query_RootTask_Translation_AggregateArgs, never>>;
  task_translation_by_pk?: Resolver<Maybe<ResolversTypes['task_translation']>, ParentType, ContextType, RequireFields<Query_RootTask_Translation_By_PkArgs, 'language' | 'task_id'>>;
  tasks?: Resolver<Array<ResolversTypes['tasks']>, ParentType, ContextType, RequireFields<Query_RootTasksArgs, never>>;
  tasks_aggregate?: Resolver<ResolversTypes['tasks_aggregate'], ParentType, ContextType, RequireFields<Query_RootTasks_AggregateArgs, never>>;
  tasks_by_pk?: Resolver<Maybe<ResolversTypes['tasks']>, ParentType, ContextType, RequireFields<Query_RootTasks_By_PkArgs, 'id'>>;
  tool_versions?: Resolver<Array<ResolversTypes['tool_versions']>, ParentType, ContextType, RequireFields<Query_RootTool_VersionsArgs, never>>;
  tool_versions_by_pk?: Resolver<Maybe<ResolversTypes['tool_versions']>, ParentType, ContextType, RequireFields<Query_RootTool_Versions_By_PkArgs, 'id'>>;
  tools?: Resolver<Array<ResolversTypes['tools']>, ParentType, ContextType, RequireFields<Query_RootToolsArgs, never>>;
  tools_by_pk?: Resolver<Maybe<ResolversTypes['tools']>, ParentType, ContextType, RequireFields<Query_RootTools_By_PkArgs, 'id'>>;
  types?: Resolver<Array<ResolversTypes['types']>, ParentType, ContextType, RequireFields<Query_RootTypesArgs, never>>;
  types_aggregate?: Resolver<ResolversTypes['types_aggregate'], ParentType, ContextType, RequireFields<Query_RootTypes_AggregateArgs, never>>;
  types_by_pk?: Resolver<Maybe<ResolversTypes['types']>, ParentType, ContextType, RequireFields<Query_RootTypes_By_PkArgs, 'id'>>;
  user_recordings_by_day_stats?: Resolver<Array<ResolversTypes['user_recordings_by_day_stats']>, ParentType, ContextType, RequireFields<Query_RootUser_Recordings_By_Day_StatsArgs, never>>;
  user_recordings_by_day_stats_aggregate?: Resolver<ResolversTypes['user_recordings_by_day_stats_aggregate'], ParentType, ContextType, RequireFields<Query_RootUser_Recordings_By_Day_Stats_AggregateArgs, never>>;
  users?: Resolver<Array<ResolversTypes['users']>, ParentType, ContextType, RequireFields<Query_RootUsersArgs, never>>;
  users_aggregate?: Resolver<ResolversTypes['users_aggregate'], ParentType, ContextType, RequireFields<Query_RootUsers_AggregateArgs, never>>;
  users_by_pk?: Resolver<Maybe<ResolversTypes['users']>, ParentType, ContextType, RequireFields<Query_RootUsers_By_PkArgs, 'id'>>;
  xtensa_configurations?: Resolver<Array<ResolversTypes['xtensa_configurations']>, ParentType, ContextType, RequireFields<Query_RootXtensa_ConfigurationsArgs, never>>;
  xtensa_configurations_aggregate?: Resolver<ResolversTypes['xtensa_configurations_aggregate'], ParentType, ContextType, RequireFields<Query_RootXtensa_Configurations_AggregateArgs, never>>;
  xtensa_configurations_by_pk?: Resolver<Maybe<ResolversTypes['xtensa_configurations']>, ParentType, ContextType, RequireFields<Query_RootXtensa_Configurations_By_PkArgs, 'id'>>;
};

export type Rabbit_Test_Plan_ProjectResolvers<ContextType = any, ParentType extends ResolversParentTypes['rabbit_test_plan_project'] = ResolversParentTypes['rabbit_test_plan_project']> = {
  plan?: Resolver<ResolversTypes['rabbit_test_plans'], ParentType, ContextType>;
  plan_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['projects'], ParentType, ContextType>;
  project_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Rabbit_Test_Plan_Project_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['rabbit_test_plan_project_aggregate'] = ResolversParentTypes['rabbit_test_plan_project_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['rabbit_test_plan_project_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['rabbit_test_plan_project']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Rabbit_Test_Plan_Project_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['rabbit_test_plan_project_aggregate_fields'] = ResolversParentTypes['rabbit_test_plan_project_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Rabbit_Test_Plan_Project_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['rabbit_test_plan_project_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['rabbit_test_plan_project_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Rabbit_Test_Plan_Project_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['rabbit_test_plan_project_max_fields'] = ResolversParentTypes['rabbit_test_plan_project_max_fields']> = {
  plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Rabbit_Test_Plan_Project_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['rabbit_test_plan_project_min_fields'] = ResolversParentTypes['rabbit_test_plan_project_min_fields']> = {
  plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Rabbit_Test_Plan_Project_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['rabbit_test_plan_project_mutation_response'] = ResolversParentTypes['rabbit_test_plan_project_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['rabbit_test_plan_project']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Rabbit_Test_Plan_RecordingResolvers<ContextType = any, ParentType extends ResolversParentTypes['rabbit_test_plan_recording'] = ResolversParentTypes['rabbit_test_plan_recording']> = {
  plan?: Resolver<ResolversTypes['rabbit_test_plans'], ParentType, ContextType>;
  plan_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  recording?: Resolver<ResolversTypes['recordings'], ParentType, ContextType>;
  recording_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Rabbit_Test_Plan_Recording_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['rabbit_test_plan_recording_aggregate'] = ResolversParentTypes['rabbit_test_plan_recording_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['rabbit_test_plan_recording_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['rabbit_test_plan_recording']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Rabbit_Test_Plan_Recording_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['rabbit_test_plan_recording_aggregate_fields'] = ResolversParentTypes['rabbit_test_plan_recording_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Rabbit_Test_Plan_Recording_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['rabbit_test_plan_recording_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['rabbit_test_plan_recording_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Rabbit_Test_Plan_Recording_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['rabbit_test_plan_recording_max_fields'] = ResolversParentTypes['rabbit_test_plan_recording_max_fields']> = {
  plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  recording_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Rabbit_Test_Plan_Recording_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['rabbit_test_plan_recording_min_fields'] = ResolversParentTypes['rabbit_test_plan_recording_min_fields']> = {
  plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  recording_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Rabbit_Test_Plan_Recording_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['rabbit_test_plan_recording_mutation_response'] = ResolversParentTypes['rabbit_test_plan_recording_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['rabbit_test_plan_recording']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Rabbit_Test_Plan_TaskResolvers<ContextType = any, ParentType extends ResolversParentTypes['rabbit_test_plan_task'] = ResolversParentTypes['rabbit_test_plan_task']> = {
  is_test?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  plan?: Resolver<ResolversTypes['rabbit_test_plans'], ParentType, ContextType>;
  plan_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  task?: Resolver<Maybe<ResolversTypes['tasks']>, ParentType, ContextType>;
  task_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Rabbit_Test_Plan_Task_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['rabbit_test_plan_task_aggregate'] = ResolversParentTypes['rabbit_test_plan_task_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['rabbit_test_plan_task_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['rabbit_test_plan_task']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Rabbit_Test_Plan_Task_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['rabbit_test_plan_task_aggregate_fields'] = ResolversParentTypes['rabbit_test_plan_task_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Rabbit_Test_Plan_Task_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['rabbit_test_plan_task_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['rabbit_test_plan_task_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Rabbit_Test_Plan_Task_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['rabbit_test_plan_task_max_fields'] = ResolversParentTypes['rabbit_test_plan_task_max_fields']> = {
  plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Rabbit_Test_Plan_Task_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['rabbit_test_plan_task_min_fields'] = ResolversParentTypes['rabbit_test_plan_task_min_fields']> = {
  plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Rabbit_Test_Plan_Task_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['rabbit_test_plan_task_mutation_response'] = ResolversParentTypes['rabbit_test_plan_task_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['rabbit_test_plan_task']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Rabbit_Test_PlansResolvers<ContextType = any, ParentType extends ResolversParentTypes['rabbit_test_plans'] = ResolversParentTypes['rabbit_test_plans']> = {
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  plan_projects?: Resolver<Array<ResolversTypes['rabbit_test_plan_project']>, ParentType, ContextType, RequireFields<Rabbit_Test_PlansPlan_ProjectsArgs, never>>;
  plan_projects_aggregate?: Resolver<ResolversTypes['rabbit_test_plan_project_aggregate'], ParentType, ContextType, RequireFields<Rabbit_Test_PlansPlan_Projects_AggregateArgs, never>>;
  plan_recordings?: Resolver<Array<ResolversTypes['rabbit_test_plan_recording']>, ParentType, ContextType, RequireFields<Rabbit_Test_PlansPlan_RecordingsArgs, never>>;
  plan_recordings_aggregate?: Resolver<ResolversTypes['rabbit_test_plan_recording_aggregate'], ParentType, ContextType, RequireFields<Rabbit_Test_PlansPlan_Recordings_AggregateArgs, never>>;
  plan_tasks?: Resolver<Array<ResolversTypes['rabbit_test_plan_task']>, ParentType, ContextType, RequireFields<Rabbit_Test_PlansPlan_TasksArgs, never>>;
  plan_tasks_aggregate?: Resolver<ResolversTypes['rabbit_test_plan_task_aggregate'], ParentType, ContextType, RequireFields<Rabbit_Test_PlansPlan_Tasks_AggregateArgs, never>>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Rabbit_Test_Plans_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['rabbit_test_plans_aggregate'] = ResolversParentTypes['rabbit_test_plans_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['rabbit_test_plans_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['rabbit_test_plans']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Rabbit_Test_Plans_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['rabbit_test_plans_aggregate_fields'] = ResolversParentTypes['rabbit_test_plans_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Rabbit_Test_Plans_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['rabbit_test_plans_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['rabbit_test_plans_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Rabbit_Test_Plans_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['rabbit_test_plans_max_fields'] = ResolversParentTypes['rabbit_test_plans_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Rabbit_Test_Plans_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['rabbit_test_plans_min_fields'] = ResolversParentTypes['rabbit_test_plans_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Rabbit_Test_Plans_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['rabbit_test_plans_mutation_response'] = ResolversParentTypes['rabbit_test_plans_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['rabbit_test_plans']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_AppsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_apps'] = ResolversParentTypes['recording_apps']> = {
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recordings?: Resolver<Array<ResolversTypes['recordings']>, ParentType, ContextType, RequireFields<Recording_AppsRecordingsArgs, never>>;
  recordings_aggregate?: Resolver<ResolversTypes['recordings_aggregate'], ParentType, ContextType, RequireFields<Recording_AppsRecordings_AggregateArgs, never>>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Apps_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_apps_aggregate'] = ResolversParentTypes['recording_apps_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['recording_apps_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['recording_apps']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Apps_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_apps_aggregate_fields'] = ResolversParentTypes['recording_apps_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Recording_Apps_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['recording_apps_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['recording_apps_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Apps_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_apps_max_fields'] = ResolversParentTypes['recording_apps_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Apps_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_apps_min_fields'] = ResolversParentTypes['recording_apps_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Apps_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_apps_mutation_response'] = ResolversParentTypes['recording_apps_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['recording_apps']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Audio_DriverResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_audio_driver'] = ResolversParentTypes['recording_audio_driver']> = {
  audio_driver?: Resolver<ResolversTypes['audio_driver'], ParentType, ContextType>;
  audio_driver_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  recording?: Resolver<ResolversTypes['recordings'], ParentType, ContextType>;
  recording_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Audio_Driver_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_audio_driver_mutation_response'] = ResolversParentTypes['recording_audio_driver_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['recording_audio_driver']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Ground_TruthResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_ground_truth'] = ResolversParentTypes['recording_data_ground_truth']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  last_time?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  recording?: Resolver<ResolversTypes['recordings'], ParentType, ContextType>;
  recording_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  states?: Resolver<ResolversTypes['_int2'], ParentType, ContextType>;
  times?: Resolver<ResolversTypes['_int4'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['users'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Ground_Truth_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_ground_truth_aggregate'] = ResolversParentTypes['recording_data_ground_truth_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['recording_data_ground_truth_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['recording_data_ground_truth']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Ground_Truth_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_ground_truth_aggregate_fields'] = ResolversParentTypes['recording_data_ground_truth_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['recording_data_ground_truth_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Recording_Data_Ground_Truth_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['recording_data_ground_truth_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['recording_data_ground_truth_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['recording_data_ground_truth_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['recording_data_ground_truth_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['recording_data_ground_truth_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['recording_data_ground_truth_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['recording_data_ground_truth_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['recording_data_ground_truth_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['recording_data_ground_truth_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Ground_Truth_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_ground_truth_avg_fields'] = ResolversParentTypes['recording_data_ground_truth_avg_fields']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  last_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Ground_Truth_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_ground_truth_max_fields'] = ResolversParentTypes['recording_data_ground_truth_max_fields']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  last_time?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  recording_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Ground_Truth_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_ground_truth_min_fields'] = ResolversParentTypes['recording_data_ground_truth_min_fields']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  last_time?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  recording_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Ground_Truth_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_ground_truth_mutation_response'] = ResolversParentTypes['recording_data_ground_truth_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['recording_data_ground_truth']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Ground_Truth_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_ground_truth_stddev_fields'] = ResolversParentTypes['recording_data_ground_truth_stddev_fields']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  last_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Ground_Truth_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_ground_truth_stddev_pop_fields'] = ResolversParentTypes['recording_data_ground_truth_stddev_pop_fields']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  last_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Ground_Truth_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_ground_truth_stddev_samp_fields'] = ResolversParentTypes['recording_data_ground_truth_stddev_samp_fields']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  last_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Ground_Truth_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_ground_truth_sum_fields'] = ResolversParentTypes['recording_data_ground_truth_sum_fields']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  last_time?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Ground_Truth_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_ground_truth_var_pop_fields'] = ResolversParentTypes['recording_data_ground_truth_var_pop_fields']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  last_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Ground_Truth_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_ground_truth_var_samp_fields'] = ResolversParentTypes['recording_data_ground_truth_var_samp_fields']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  last_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Ground_Truth_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_ground_truth_variance_fields'] = ResolversParentTypes['recording_data_ground_truth_variance_fields']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  last_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_LabelsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_labels'] = ResolversParentTypes['recording_data_labels']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  last_time?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  mode?: Resolver<ResolversTypes['recording_data_modes'], ParentType, ContextType>;
  mode_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  recording?: Resolver<ResolversTypes['recordings'], ParentType, ContextType>;
  recording_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  states?: Resolver<ResolversTypes['_int2'], ParentType, ContextType>;
  times?: Resolver<ResolversTypes['_int4'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Labels_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_labels_aggregate'] = ResolversParentTypes['recording_data_labels_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['recording_data_labels_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['recording_data_labels']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Labels_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_labels_aggregate_fields'] = ResolversParentTypes['recording_data_labels_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['recording_data_labels_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Recording_Data_Labels_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['recording_data_labels_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['recording_data_labels_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['recording_data_labels_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['recording_data_labels_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['recording_data_labels_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['recording_data_labels_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['recording_data_labels_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['recording_data_labels_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['recording_data_labels_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Labels_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_labels_avg_fields'] = ResolversParentTypes['recording_data_labels_avg_fields']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  last_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Labels_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_labels_max_fields'] = ResolversParentTypes['recording_data_labels_max_fields']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  last_time?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  mode_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  recording_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Labels_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_labels_min_fields'] = ResolversParentTypes['recording_data_labels_min_fields']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  last_time?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  mode_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  recording_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Labels_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_labels_mutation_response'] = ResolversParentTypes['recording_data_labels_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['recording_data_labels']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Labels_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_labels_stddev_fields'] = ResolversParentTypes['recording_data_labels_stddev_fields']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  last_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Labels_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_labels_stddev_pop_fields'] = ResolversParentTypes['recording_data_labels_stddev_pop_fields']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  last_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Labels_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_labels_stddev_samp_fields'] = ResolversParentTypes['recording_data_labels_stddev_samp_fields']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  last_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Labels_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_labels_sum_fields'] = ResolversParentTypes['recording_data_labels_sum_fields']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  last_time?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Labels_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_labels_var_pop_fields'] = ResolversParentTypes['recording_data_labels_var_pop_fields']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  last_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Labels_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_labels_var_samp_fields'] = ResolversParentTypes['recording_data_labels_var_samp_fields']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  last_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Labels_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_labels_variance_fields'] = ResolversParentTypes['recording_data_labels_variance_fields']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  last_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Mode_ParameterResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_mode_parameter'] = ResolversParentTypes['recording_data_mode_parameter']> = {
  mode?: Resolver<ResolversTypes['recording_data_modes'], ParentType, ContextType>;
  mode_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  parameter?: Resolver<ResolversTypes['recording_data_parameters'], ParentType, ContextType>;
  parameter_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Mode_Parameter_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_mode_parameter_aggregate'] = ResolversParentTypes['recording_data_mode_parameter_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['recording_data_mode_parameter_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['recording_data_mode_parameter']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Mode_Parameter_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_mode_parameter_aggregate_fields'] = ResolversParentTypes['recording_data_mode_parameter_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Recording_Data_Mode_Parameter_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['recording_data_mode_parameter_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['recording_data_mode_parameter_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Mode_Parameter_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_mode_parameter_max_fields'] = ResolversParentTypes['recording_data_mode_parameter_max_fields']> = {
  mode_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  parameter_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Mode_Parameter_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_mode_parameter_min_fields'] = ResolversParentTypes['recording_data_mode_parameter_min_fields']> = {
  mode_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  parameter_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Mode_Parameter_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_mode_parameter_mutation_response'] = ResolversParentTypes['recording_data_mode_parameter_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['recording_data_mode_parameter']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_ModesResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_modes'] = ResolversParentTypes['recording_data_modes']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  labels?: Resolver<Array<ResolversTypes['recording_data_labels']>, ParentType, ContextType, RequireFields<Recording_Data_ModesLabelsArgs, never>>;
  labels_aggregate?: Resolver<ResolversTypes['recording_data_labels_aggregate'], ParentType, ContextType, RequireFields<Recording_Data_ModesLabels_AggregateArgs, never>>;
  mode_parameters?: Resolver<Array<ResolversTypes['recording_data_mode_parameter']>, ParentType, ContextType, RequireFields<Recording_Data_ModesMode_ParametersArgs, never>>;
  mode_parameters_aggregate?: Resolver<ResolversTypes['recording_data_mode_parameter_aggregate'], ParentType, ContextType, RequireFields<Recording_Data_ModesMode_Parameters_AggregateArgs, never>>;
  mode_projects?: Resolver<Array<ResolversTypes['mode_project']>, ParentType, ContextType, RequireFields<Recording_Data_ModesMode_ProjectsArgs, never>>;
  mode_projects_aggregate?: Resolver<ResolversTypes['mode_project_aggregate'], ParentType, ContextType, RequireFields<Recording_Data_ModesMode_Projects_AggregateArgs, never>>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Modes_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_modes_aggregate'] = ResolversParentTypes['recording_data_modes_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['recording_data_modes_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['recording_data_modes']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Modes_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_modes_aggregate_fields'] = ResolversParentTypes['recording_data_modes_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Recording_Data_Modes_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['recording_data_modes_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['recording_data_modes_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Modes_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_modes_max_fields'] = ResolversParentTypes['recording_data_modes_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Modes_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_modes_min_fields'] = ResolversParentTypes['recording_data_modes_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Modes_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_modes_mutation_response'] = ResolversParentTypes['recording_data_modes_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['recording_data_modes']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_ParametersResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_parameters'] = ResolversParentTypes['recording_data_parameters']> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  mode_parameters?: Resolver<Array<ResolversTypes['recording_data_mode_parameter']>, ParentType, ContextType, RequireFields<Recording_Data_ParametersMode_ParametersArgs, never>>;
  mode_parameters_aggregate?: Resolver<ResolversTypes['recording_data_mode_parameter_aggregate'], ParentType, ContextType, RequireFields<Recording_Data_ParametersMode_Parameters_AggregateArgs, never>>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['types'], ParentType, ContextType>;
  type_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Parameters_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_parameters_aggregate'] = ResolversParentTypes['recording_data_parameters_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['recording_data_parameters_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['recording_data_parameters']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Parameters_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_parameters_aggregate_fields'] = ResolversParentTypes['recording_data_parameters_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Recording_Data_Parameters_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['recording_data_parameters_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['recording_data_parameters_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Parameters_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_parameters_max_fields'] = ResolversParentTypes['recording_data_parameters_max_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Parameters_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_parameters_min_fields'] = ResolversParentTypes['recording_data_parameters_min_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Parameters_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_parameters_mutation_response'] = ResolversParentTypes['recording_data_parameters_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['recording_data_parameters']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Payload_PairResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_payload_pair'] = ResolversParentTypes['recording_data_payload_pair']> = {
  offset?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  pair_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  payload?: Resolver<ResolversTypes['payloads'], ParentType, ContextType>;
  payload_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Payload_Pair_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_payload_pair_mutation_response'] = ResolversParentTypes['recording_data_payload_pair_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['recording_data_payload_pair']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_PredictionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_predictions'] = ResolversParentTypes['recording_data_predictions']> = {
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  engine?: Resolver<ResolversTypes['engines'], ParentType, ContextType>;
  engine_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  recording?: Resolver<ResolversTypes['recordings'], ParentType, ContextType>;
  recording_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  states?: Resolver<ResolversTypes['_int2'], ParentType, ContextType>;
  times?: Resolver<ResolversTypes['_int4'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Predictions_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_predictions_aggregate'] = ResolversParentTypes['recording_data_predictions_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['recording_data_predictions_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['recording_data_predictions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Predictions_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_predictions_aggregate_fields'] = ResolversParentTypes['recording_data_predictions_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Recording_Data_Predictions_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['recording_data_predictions_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['recording_data_predictions_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Predictions_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_predictions_max_fields'] = ResolversParentTypes['recording_data_predictions_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  engine_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  recording_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Predictions_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_predictions_min_fields'] = ResolversParentTypes['recording_data_predictions_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  engine_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  recording_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Data_Predictions_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_data_predictions_mutation_response'] = ResolversParentTypes['recording_data_predictions_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['recording_data_predictions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Recording_TagResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_recording_tag'] = ResolversParentTypes['recording_recording_tag']> = {
  build_id_azure?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  recording?: Resolver<ResolversTypes['recordings'], ParentType, ContextType>;
  recording_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  recording_tag?: Resolver<ResolversTypes['recording_tags'], ParentType, ContextType>;
  recording_tag_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['users'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Recording_Tag_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_recording_tag_mutation_response'] = ResolversParentTypes['recording_recording_tag_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['recording_recording_tag']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_TagsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_tags'] = ResolversParentTypes['recording_tags']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dirty?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  field?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  highlight?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recording_recording_tags?: Resolver<Array<ResolversTypes['recording_recording_tag']>, ParentType, ContextType, RequireFields<Recording_TagsRecording_Recording_TagsArgs, never>>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recording_Tags_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['recording_tags_mutation_response'] = ResolversParentTypes['recording_tags_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['recording_tags']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recordings'] = ResolversParentTypes['recordings']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  device?: Resolver<ResolversTypes['devices'], ParentType, ContextType>;
  device_firmware?: Resolver<Maybe<ResolversTypes['device_firmware']>, ParentType, ContextType>;
  device_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  engine_opmode_recordings?: Resolver<Array<ResolversTypes['engine_opmode_recording']>, ParentType, ContextType, RequireFields<RecordingsEngine_Opmode_RecordingsArgs, never>>;
  engine_opmode_recordings_aggregate?: Resolver<ResolversTypes['engine_opmode_recording_aggregate'], ParentType, ContextType, RequireFields<RecordingsEngine_Opmode_Recordings_AggregateArgs, never>>;
  firmware?: Resolver<ResolversTypes['firmware'], ParentType, ContextType>;
  firmware_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ground_truth?: Resolver<Maybe<ResolversTypes['recording_data_ground_truth']>, ParentType, ContextType>;
  ground_truth_configuration?: Resolver<Maybe<ResolversTypes['ground_truth_recordings']>, ParentType, ContextType>;
  ground_truth_labels?: Resolver<Array<ResolversTypes['recording_data_labels']>, ParentType, ContextType, RequireFields<RecordingsGround_Truth_LabelsArgs, never>>;
  ground_truth_labels_aggregate?: Resolver<ResolversTypes['recording_data_labels_aggregate'], ParentType, ContextType, RequireFields<RecordingsGround_Truth_Labels_AggregateArgs, never>>;
  hypertask?: Resolver<Maybe<ResolversTypes['hypertasks']>, ParentType, ContextType>;
  hypertask_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  payload_recordings?: Resolver<Array<ResolversTypes['payload_recording']>, ParentType, ContextType, RequireFields<RecordingsPayload_RecordingsArgs, never>>;
  payload_recordings_aggregate?: Resolver<ResolversTypes['payload_recording_aggregate'], ParentType, ContextType, RequireFields<RecordingsPayload_Recordings_AggregateArgs, never>>;
  peripheral_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  predictions?: Resolver<Array<ResolversTypes['recording_data_predictions']>, ParentType, ContextType, RequireFields<RecordingsPredictionsArgs, never>>;
  predictions_aggregate?: Resolver<ResolversTypes['recording_data_predictions_aggregate'], ParentType, ContextType, RequireFields<RecordingsPredictions_AggregateArgs, never>>;
  project?: Resolver<ResolversTypes['projects'], ParentType, ContextType>;
  project_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  rabbit_test?: Resolver<Maybe<ResolversTypes['rabbit_test_plan_recording']>, ParentType, ContextType>;
  random_number?: Resolver<ResolversTypes['float8'], ParentType, ContextType>;
  ratio_metric_contributions?: Resolver<Array<ResolversTypes['performance_ratio_metric_contributions']>, ParentType, ContextType, RequireFields<RecordingsRatio_Metric_ContributionsArgs, never>>;
  ratio_metric_contributions_aggregate?: Resolver<ResolversTypes['performance_ratio_metric_contributions_aggregate'], ParentType, ContextType, RequireFields<RecordingsRatio_Metric_Contributions_AggregateArgs, never>>;
  recorded_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  recording_apps?: Resolver<Maybe<ResolversTypes['recording_apps']>, ParentType, ContextType>;
  recording_audio_drivers?: Resolver<Array<ResolversTypes['recording_audio_driver']>, ParentType, ContextType, RequireFields<RecordingsRecording_Audio_DriversArgs, never>>;
  room_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  rooms?: Resolver<Maybe<ResolversTypes['rooms']>, ParentType, ContextType>;
  scaling_factor?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tags?: Resolver<Array<ResolversTypes['recording_recording_tag']>, ParentType, ContextType, RequireFields<RecordingsTagsArgs, never>>;
  task?: Resolver<ResolversTypes['tasks'], ParentType, ContextType>;
  task_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['users'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recordings_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['recordings_aggregate'] = ResolversParentTypes['recordings_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['recordings_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['recordings']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recordings_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recordings_aggregate_fields'] = ResolversParentTypes['recordings_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['recordings_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Recordings_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['recordings_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['recordings_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['recordings_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['recordings_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['recordings_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['recordings_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['recordings_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['recordings_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['recordings_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recordings_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recordings_avg_fields'] = ResolversParentTypes['recordings_avg_fields']> = {
  random_number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  scaling_factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recordings_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recordings_max_fields'] = ResolversParentTypes['recordings_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  device_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  firmware_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  hypertask_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  peripheral_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  random_number?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  recorded_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  room_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  scaling_factor?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recordings_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recordings_min_fields'] = ResolversParentTypes['recordings_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  device_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  firmware_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  hypertask_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  peripheral_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  random_number?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  recorded_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  room_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  scaling_factor?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recordings_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['recordings_mutation_response'] = ResolversParentTypes['recordings_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['recordings']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recordings_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recordings_stddev_fields'] = ResolversParentTypes['recordings_stddev_fields']> = {
  random_number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  scaling_factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recordings_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recordings_stddev_pop_fields'] = ResolversParentTypes['recordings_stddev_pop_fields']> = {
  random_number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  scaling_factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recordings_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recordings_stddev_samp_fields'] = ResolversParentTypes['recordings_stddev_samp_fields']> = {
  random_number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  scaling_factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recordings_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recordings_sum_fields'] = ResolversParentTypes['recordings_sum_fields']> = {
  random_number?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  scaling_factor?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recordings_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recordings_var_pop_fields'] = ResolversParentTypes['recordings_var_pop_fields']> = {
  random_number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  scaling_factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recordings_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recordings_var_samp_fields'] = ResolversParentTypes['recordings_var_samp_fields']> = {
  random_number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  scaling_factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Recordings_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['recordings_variance_fields'] = ResolversParentTypes['recordings_variance_fields']> = {
  random_number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  scaling_factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Role_UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['role_user'] = ResolversParentTypes['role_user']> = {
  role?: Resolver<ResolversTypes['roles'], ParentType, ContextType>;
  role_id?: Resolver<ResolversTypes['roles_enum'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['users'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Role_User_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['role_user_aggregate'] = ResolversParentTypes['role_user_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['role_user_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['role_user']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Role_User_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['role_user_aggregate_fields'] = ResolversParentTypes['role_user_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Role_User_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['role_user_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['role_user_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Role_User_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['role_user_max_fields'] = ResolversParentTypes['role_user_max_fields']> = {
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Role_User_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['role_user_min_fields'] = ResolversParentTypes['role_user_min_fields']> = {
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Role_User_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['role_user_mutation_response'] = ResolversParentTypes['role_user_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['role_user']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RolesResolvers<ContextType = any, ParentType extends ResolversParentTypes['roles'] = ResolversParentTypes['roles']> = {
  comment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role_users?: Resolver<Array<ResolversTypes['role_user']>, ParentType, ContextType, RequireFields<RolesRole_UsersArgs, never>>;
  role_users_aggregate?: Resolver<ResolversTypes['role_user_aggregate'], ParentType, ContextType, RequireFields<RolesRole_Users_AggregateArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Roles_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['roles_aggregate'] = ResolversParentTypes['roles_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['roles_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['roles']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Roles_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['roles_aggregate_fields'] = ResolversParentTypes['roles_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Roles_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['roles_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['roles_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Roles_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['roles_max_fields'] = ResolversParentTypes['roles_max_fields']> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Roles_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['roles_min_fields'] = ResolversParentTypes['roles_min_fields']> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Roles_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['roles_mutation_response'] = ResolversParentTypes['roles_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['roles']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoomsResolvers<ContextType = any, ParentType extends ResolversParentTypes['rooms'] = ResolversParentTypes['rooms']> = {
  building?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  project_dataset?: Resolver<Array<ResolversTypes['project_dataset']>, ParentType, ContextType, RequireFields<RoomsProject_DatasetArgs, never>>;
  project_dataset_aggregate?: Resolver<ResolversTypes['project_dataset_aggregate'], ParentType, ContextType, RequireFields<RoomsProject_Dataset_AggregateArgs, never>>;
  recordings?: Resolver<Array<ResolversTypes['recordings']>, ParentType, ContextType, RequireFields<RoomsRecordingsArgs, never>>;
  recordings_aggregate?: Resolver<ResolversTypes['recordings_aggregate'], ParentType, ContextType, RequireFields<RoomsRecordings_AggregateArgs, never>>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Rooms_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['rooms_mutation_response'] = ResolversParentTypes['rooms_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['rooms']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Engine_MetricsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_engine_metrics'] = ResolversParentTypes['schema_project_engine_metrics']> = {
  denominator?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  engine?: Resolver<ResolversTypes['engines'], ParentType, ContextType>;
  engine_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  metric_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  num_recordings?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  num_samples?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  numerator?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  project?: Resolver<ResolversTypes['projects'], ParentType, ContextType>;
  project_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ratio_metric?: Resolver<ResolversTypes['performance_ratio_metrics'], ParentType, ContextType>;
  score?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  task?: Resolver<Maybe<ResolversTypes['tasks']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Engine_Ratio_MetricsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_engine_ratio_metrics'] = ResolversParentTypes['schema_project_engine_ratio_metrics']> = {
  engine?: Resolver<ResolversTypes['engines'], ParentType, ContextType>;
  engine_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  num_recordings?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  num_samples?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['projects'], ParentType, ContextType>;
  project_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ratio_metric?: Resolver<ResolversTypes['performance_ratio_metrics'], ParentType, ContextType>;
  ratio_metric_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  score?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Engine_Ratio_Metrics_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_engine_ratio_metrics_aggregate'] = ResolversParentTypes['schema_project_engine_ratio_metrics_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['schema_project_engine_ratio_metrics_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['schema_project_engine_ratio_metrics']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Engine_Ratio_Metrics_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_engine_ratio_metrics_aggregate_fields'] = ResolversParentTypes['schema_project_engine_ratio_metrics_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['schema_project_engine_ratio_metrics_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Schema_Project_Engine_Ratio_Metrics_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['schema_project_engine_ratio_metrics_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['schema_project_engine_ratio_metrics_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['schema_project_engine_ratio_metrics_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['schema_project_engine_ratio_metrics_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['schema_project_engine_ratio_metrics_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['schema_project_engine_ratio_metrics_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['schema_project_engine_ratio_metrics_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['schema_project_engine_ratio_metrics_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['schema_project_engine_ratio_metrics_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Engine_Ratio_Metrics_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_engine_ratio_metrics_avg_fields'] = ResolversParentTypes['schema_project_engine_ratio_metrics_avg_fields']> = {
  num_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_samples?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Engine_Ratio_Metrics_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_engine_ratio_metrics_max_fields'] = ResolversParentTypes['schema_project_engine_ratio_metrics_max_fields']> = {
  engine_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  num_recordings?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  num_samples?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ratio_metric_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  score?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Engine_Ratio_Metrics_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_engine_ratio_metrics_min_fields'] = ResolversParentTypes['schema_project_engine_ratio_metrics_min_fields']> = {
  engine_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  num_recordings?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  num_samples?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ratio_metric_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  score?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Engine_Ratio_Metrics_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_engine_ratio_metrics_stddev_fields'] = ResolversParentTypes['schema_project_engine_ratio_metrics_stddev_fields']> = {
  num_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_samples?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Engine_Ratio_Metrics_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_engine_ratio_metrics_stddev_pop_fields'] = ResolversParentTypes['schema_project_engine_ratio_metrics_stddev_pop_fields']> = {
  num_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_samples?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Engine_Ratio_Metrics_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_engine_ratio_metrics_stddev_samp_fields'] = ResolversParentTypes['schema_project_engine_ratio_metrics_stddev_samp_fields']> = {
  num_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_samples?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Engine_Ratio_Metrics_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_engine_ratio_metrics_sum_fields'] = ResolversParentTypes['schema_project_engine_ratio_metrics_sum_fields']> = {
  num_recordings?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  num_samples?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  score?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Engine_Ratio_Metrics_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_engine_ratio_metrics_var_pop_fields'] = ResolversParentTypes['schema_project_engine_ratio_metrics_var_pop_fields']> = {
  num_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_samples?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Engine_Ratio_Metrics_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_engine_ratio_metrics_var_samp_fields'] = ResolversParentTypes['schema_project_engine_ratio_metrics_var_samp_fields']> = {
  num_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_samples?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Engine_Ratio_Metrics_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_engine_ratio_metrics_variance_fields'] = ResolversParentTypes['schema_project_engine_ratio_metrics_variance_fields']> = {
  num_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_samples?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Task_CountResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_task_count'] = ResolversParentTypes['schema_project_task_count']> = {
  clean_count?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  clean_count_constrained?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  device?: Resolver<Maybe<ResolversTypes['devices']>, ParentType, ContextType>;
  device_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  dirty_count?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  min_per_device?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  progress?: Resolver<ResolversTypes['float8'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['projects'], ParentType, ContextType>;
  project_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  required_total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  task?: Resolver<ResolversTypes['tasks'], ParentType, ContextType>;
  task_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  total_count?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  untagged_count?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Task_Count_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_task_count_aggregate'] = ResolversParentTypes['schema_project_task_count_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['schema_project_task_count_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['schema_project_task_count']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Task_Count_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_task_count_aggregate_fields'] = ResolversParentTypes['schema_project_task_count_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['schema_project_task_count_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Schema_Project_Task_Count_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['schema_project_task_count_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['schema_project_task_count_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['schema_project_task_count_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['schema_project_task_count_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['schema_project_task_count_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['schema_project_task_count_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['schema_project_task_count_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['schema_project_task_count_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['schema_project_task_count_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Task_Count_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_task_count_avg_fields'] = ResolversParentTypes['schema_project_task_count_avg_fields']> = {
  clean_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  clean_count_constrained?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dirty_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  required_total?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  untagged_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Task_Count_By_DeviceResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_task_count_by_device'] = ResolversParentTypes['schema_project_task_count_by_device']> = {
  count?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  count_dirty?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  device?: Resolver<ResolversTypes['devices'], ParentType, ContextType>;
  device_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  min_per_device?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['projects'], ParentType, ContextType>;
  project_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  task?: Resolver<ResolversTypes['tasks'], ParentType, ContextType>;
  task_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Task_Count_By_Device_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_task_count_by_device_aggregate'] = ResolversParentTypes['schema_project_task_count_by_device_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['schema_project_task_count_by_device_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['schema_project_task_count_by_device']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Task_Count_By_Device_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_task_count_by_device_aggregate_fields'] = ResolversParentTypes['schema_project_task_count_by_device_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['schema_project_task_count_by_device_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Schema_Project_Task_Count_By_Device_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['schema_project_task_count_by_device_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['schema_project_task_count_by_device_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['schema_project_task_count_by_device_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['schema_project_task_count_by_device_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['schema_project_task_count_by_device_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['schema_project_task_count_by_device_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['schema_project_task_count_by_device_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['schema_project_task_count_by_device_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['schema_project_task_count_by_device_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Task_Count_By_Device_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_task_count_by_device_avg_fields'] = ResolversParentTypes['schema_project_task_count_by_device_avg_fields']> = {
  count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  count_dirty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Task_Count_By_Device_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_task_count_by_device_max_fields'] = ResolversParentTypes['schema_project_task_count_by_device_max_fields']> = {
  count?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  count_dirty?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  device_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  min_per_device?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Task_Count_By_Device_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_task_count_by_device_min_fields'] = ResolversParentTypes['schema_project_task_count_by_device_min_fields']> = {
  count?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  count_dirty?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  device_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  min_per_device?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Task_Count_By_Device_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_task_count_by_device_stddev_fields'] = ResolversParentTypes['schema_project_task_count_by_device_stddev_fields']> = {
  count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  count_dirty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Task_Count_By_Device_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_task_count_by_device_stddev_pop_fields'] = ResolversParentTypes['schema_project_task_count_by_device_stddev_pop_fields']> = {
  count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  count_dirty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Task_Count_By_Device_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_task_count_by_device_stddev_samp_fields'] = ResolversParentTypes['schema_project_task_count_by_device_stddev_samp_fields']> = {
  count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  count_dirty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Task_Count_By_Device_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_task_count_by_device_sum_fields'] = ResolversParentTypes['schema_project_task_count_by_device_sum_fields']> = {
  count?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  count_dirty?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  min_per_device?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Task_Count_By_Device_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_task_count_by_device_var_pop_fields'] = ResolversParentTypes['schema_project_task_count_by_device_var_pop_fields']> = {
  count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  count_dirty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Task_Count_By_Device_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_task_count_by_device_var_samp_fields'] = ResolversParentTypes['schema_project_task_count_by_device_var_samp_fields']> = {
  count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  count_dirty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Task_Count_By_Device_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_task_count_by_device_variance_fields'] = ResolversParentTypes['schema_project_task_count_by_device_variance_fields']> = {
  count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  count_dirty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Task_Count_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_task_count_max_fields'] = ResolversParentTypes['schema_project_task_count_max_fields']> = {
  clean_count?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  clean_count_constrained?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  device_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  dirty_count?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  min_per_device?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  required_total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  total_count?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  untagged_count?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Task_Count_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_task_count_min_fields'] = ResolversParentTypes['schema_project_task_count_min_fields']> = {
  clean_count?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  clean_count_constrained?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  device_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  dirty_count?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  min_per_device?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  required_total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  total_count?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  untagged_count?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Task_Count_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_task_count_stddev_fields'] = ResolversParentTypes['schema_project_task_count_stddev_fields']> = {
  clean_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  clean_count_constrained?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dirty_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  required_total?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  untagged_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Task_Count_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_task_count_stddev_pop_fields'] = ResolversParentTypes['schema_project_task_count_stddev_pop_fields']> = {
  clean_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  clean_count_constrained?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dirty_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  required_total?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  untagged_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Task_Count_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_task_count_stddev_samp_fields'] = ResolversParentTypes['schema_project_task_count_stddev_samp_fields']> = {
  clean_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  clean_count_constrained?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dirty_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  required_total?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  untagged_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Task_Count_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_task_count_sum_fields'] = ResolversParentTypes['schema_project_task_count_sum_fields']> = {
  clean_count?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  clean_count_constrained?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  dirty_count?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  min_per_device?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  required_total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_count?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  untagged_count?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Task_Count_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_task_count_var_pop_fields'] = ResolversParentTypes['schema_project_task_count_var_pop_fields']> = {
  clean_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  clean_count_constrained?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dirty_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  required_total?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  untagged_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Task_Count_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_task_count_var_samp_fields'] = ResolversParentTypes['schema_project_task_count_var_samp_fields']> = {
  clean_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  clean_count_constrained?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dirty_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  required_total?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  untagged_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Project_Task_Count_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_project_task_count_variance_fields'] = ResolversParentTypes['schema_project_task_count_variance_fields']> = {
  clean_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  clean_count_constrained?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dirty_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_per_device?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  required_total?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  untagged_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Recording_Tag_Count_By_DayResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_recording_tag_count_by_day'] = ResolversParentTypes['schema_recording_tag_count_by_day']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  day?: Resolver<ResolversTypes['date'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['projects'], ParentType, ContextType>;
  project_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  recording_tag?: Resolver<ResolversTypes['recording_tags'], ParentType, ContextType>;
  tag_description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tag_field?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tag_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  tag_is_dirty?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  tag_is_highlighted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  tag_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Recording_Tag_Count_By_Day_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_recording_tag_count_by_day_aggregate'] = ResolversParentTypes['schema_recording_tag_count_by_day_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['schema_recording_tag_count_by_day_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['schema_recording_tag_count_by_day']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Recording_Tag_Count_By_Day_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_recording_tag_count_by_day_aggregate_fields'] = ResolversParentTypes['schema_recording_tag_count_by_day_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['schema_recording_tag_count_by_day_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Schema_Recording_Tag_Count_By_Day_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['schema_recording_tag_count_by_day_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['schema_recording_tag_count_by_day_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['schema_recording_tag_count_by_day_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['schema_recording_tag_count_by_day_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['schema_recording_tag_count_by_day_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['schema_recording_tag_count_by_day_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['schema_recording_tag_count_by_day_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['schema_recording_tag_count_by_day_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['schema_recording_tag_count_by_day_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Recording_Tag_Count_By_Day_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_recording_tag_count_by_day_avg_fields'] = ResolversParentTypes['schema_recording_tag_count_by_day_avg_fields']> = {
  count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Recording_Tag_Count_By_Day_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_recording_tag_count_by_day_max_fields'] = ResolversParentTypes['schema_recording_tag_count_by_day_max_fields']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  day?: Resolver<Maybe<ResolversTypes['date']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  tag_description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tag_field?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tag_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  tag_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Recording_Tag_Count_By_Day_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_recording_tag_count_by_day_min_fields'] = ResolversParentTypes['schema_recording_tag_count_by_day_min_fields']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  day?: Resolver<Maybe<ResolversTypes['date']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  tag_description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tag_field?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tag_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  tag_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Recording_Tag_Count_By_Day_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_recording_tag_count_by_day_stddev_fields'] = ResolversParentTypes['schema_recording_tag_count_by_day_stddev_fields']> = {
  count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Recording_Tag_Count_By_Day_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_recording_tag_count_by_day_stddev_pop_fields'] = ResolversParentTypes['schema_recording_tag_count_by_day_stddev_pop_fields']> = {
  count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Recording_Tag_Count_By_Day_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_recording_tag_count_by_day_stddev_samp_fields'] = ResolversParentTypes['schema_recording_tag_count_by_day_stddev_samp_fields']> = {
  count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Recording_Tag_Count_By_Day_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_recording_tag_count_by_day_sum_fields'] = ResolversParentTypes['schema_recording_tag_count_by_day_sum_fields']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Recording_Tag_Count_By_Day_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_recording_tag_count_by_day_var_pop_fields'] = ResolversParentTypes['schema_recording_tag_count_by_day_var_pop_fields']> = {
  count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Recording_Tag_Count_By_Day_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_recording_tag_count_by_day_var_samp_fields'] = ResolversParentTypes['schema_recording_tag_count_by_day_var_samp_fields']> = {
  count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Recording_Tag_Count_By_Day_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_recording_tag_count_by_day_variance_fields'] = ResolversParentTypes['schema_recording_tag_count_by_day_variance_fields']> = {
  count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schema_Upsert_TaskResolvers<ContextType = any, ParentType extends ResolversParentTypes['schema_upsert_task'] = ResolversParentTypes['schema_upsert_task']> = {
  task_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  task_number?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Simulations_ScoresResolvers<ContextType = any, ParentType extends ResolversParentTypes['simulations_scores'] = ResolversParentTypes['simulations_scores']> = {
  engine?: Resolver<ResolversTypes['engines'], ParentType, ContextType>;
  engine_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  greatness?: Resolver<ResolversTypes['float8'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['projects'], ParentType, ContextType>;
  project_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Simulations_Scores_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['simulations_scores_mutation_response'] = ResolversParentTypes['simulations_scores_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['simulations_scores']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface SmallintScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['smallint'], any> {
  name: 'smallint';
}

export type Subscription_RootResolvers<ContextType = any, ParentType extends ResolversParentTypes['subscription_root'] = ResolversParentTypes['subscription_root']> = {
  audio_driver?: SubscriptionResolver<Array<ResolversTypes['audio_driver']>, "audio_driver", ParentType, ContextType, RequireFields<Subscription_RootAudio_DriverArgs, never>>;
  audio_driver_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['audio_driver']>, "audio_driver_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAudio_Driver_By_PkArgs, 'id'>>;
  audio_driver_stream?: SubscriptionResolver<Array<ResolversTypes['audio_driver']>, "audio_driver_stream", ParentType, ContextType, RequireFields<Subscription_RootAudio_Driver_StreamArgs, 'batch_size' | 'cursor'>>;
  audio_measurements_components?: SubscriptionResolver<Array<ResolversTypes['audio_measurements_components']>, "audio_measurements_components", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_ComponentsArgs, never>>;
  audio_measurements_components_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['audio_measurements_components']>, "audio_measurements_components_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_Components_By_PkArgs, 'id'>>;
  audio_measurements_components_stream?: SubscriptionResolver<Array<ResolversTypes['audio_measurements_components']>, "audio_measurements_components_stream", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_Components_StreamArgs, 'batch_size' | 'cursor'>>;
  audio_measurements_configurations?: SubscriptionResolver<Array<ResolversTypes['audio_measurements_configurations']>, "audio_measurements_configurations", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_ConfigurationsArgs, never>>;
  audio_measurements_configurations_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['audio_measurements_configurations']>, "audio_measurements_configurations_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_Configurations_By_PkArgs, 'id'>>;
  audio_measurements_configurations_stream?: SubscriptionResolver<Array<ResolversTypes['audio_measurements_configurations']>, "audio_measurements_configurations_stream", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_Configurations_StreamArgs, 'batch_size' | 'cursor'>>;
  audio_measurements_data_types?: SubscriptionResolver<Array<ResolversTypes['audio_measurements_data_types']>, "audio_measurements_data_types", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_Data_TypesArgs, never>>;
  audio_measurements_data_types_aggregate?: SubscriptionResolver<ResolversTypes['audio_measurements_data_types_aggregate'], "audio_measurements_data_types_aggregate", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_Data_Types_AggregateArgs, never>>;
  audio_measurements_data_types_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['audio_measurements_data_types']>, "audio_measurements_data_types_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_Data_Types_By_PkArgs, 'id'>>;
  audio_measurements_data_types_stream?: SubscriptionResolver<Array<ResolversTypes['audio_measurements_data_types']>, "audio_measurements_data_types_stream", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_Data_Types_StreamArgs, 'batch_size' | 'cursor'>>;
  audio_measurements_master_files?: SubscriptionResolver<Array<ResolversTypes['audio_measurements_master_files']>, "audio_measurements_master_files", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_Master_FilesArgs, never>>;
  audio_measurements_master_files_aggregate?: SubscriptionResolver<ResolversTypes['audio_measurements_master_files_aggregate'], "audio_measurements_master_files_aggregate", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_Master_Files_AggregateArgs, never>>;
  audio_measurements_master_files_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['audio_measurements_master_files']>, "audio_measurements_master_files_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_Master_Files_By_PkArgs, 'id'>>;
  audio_measurements_master_files_stream?: SubscriptionResolver<Array<ResolversTypes['audio_measurements_master_files']>, "audio_measurements_master_files_stream", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_Master_Files_StreamArgs, 'batch_size' | 'cursor'>>;
  audio_measurements_orientations?: SubscriptionResolver<Array<ResolversTypes['audio_measurements_orientations']>, "audio_measurements_orientations", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_OrientationsArgs, never>>;
  audio_measurements_orientations_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['audio_measurements_orientations']>, "audio_measurements_orientations_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_Orientations_By_PkArgs, 'id'>>;
  audio_measurements_orientations_stream?: SubscriptionResolver<Array<ResolversTypes['audio_measurements_orientations']>, "audio_measurements_orientations_stream", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_Orientations_StreamArgs, 'batch_size' | 'cursor'>>;
  audio_measurements_payload_recording?: SubscriptionResolver<Array<ResolversTypes['audio_measurements_payload_recording']>, "audio_measurements_payload_recording", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_Payload_RecordingArgs, never>>;
  audio_measurements_payload_recording_aggregate?: SubscriptionResolver<ResolversTypes['audio_measurements_payload_recording_aggregate'], "audio_measurements_payload_recording_aggregate", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_Payload_Recording_AggregateArgs, never>>;
  audio_measurements_payload_recording_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['audio_measurements_payload_recording']>, "audio_measurements_payload_recording_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_Payload_Recording_By_PkArgs, 'payload_id' | 'recording_id'>>;
  audio_measurements_payload_recording_stream?: SubscriptionResolver<Array<ResolversTypes['audio_measurements_payload_recording']>, "audio_measurements_payload_recording_stream", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_Payload_Recording_StreamArgs, 'batch_size' | 'cursor'>>;
  audio_measurements_placements?: SubscriptionResolver<Array<ResolversTypes['audio_measurements_placements']>, "audio_measurements_placements", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_PlacementsArgs, never>>;
  audio_measurements_placements_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['audio_measurements_placements']>, "audio_measurements_placements_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_Placements_By_PkArgs, 'id'>>;
  audio_measurements_placements_stream?: SubscriptionResolver<Array<ResolversTypes['audio_measurements_placements']>, "audio_measurements_placements_stream", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_Placements_StreamArgs, 'batch_size' | 'cursor'>>;
  audio_measurements_project_tasks?: SubscriptionResolver<Array<ResolversTypes['audio_measurements_project_tasks']>, "audio_measurements_project_tasks", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_Project_TasksArgs, never>>;
  audio_measurements_project_tasks_aggregate?: SubscriptionResolver<ResolversTypes['audio_measurements_project_tasks_aggregate'], "audio_measurements_project_tasks_aggregate", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_Project_Tasks_AggregateArgs, never>>;
  audio_measurements_project_tasks_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['audio_measurements_project_tasks']>, "audio_measurements_project_tasks_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_Project_Tasks_By_PkArgs, 'project_id' | 'task_id'>>;
  audio_measurements_project_tasks_stream?: SubscriptionResolver<Array<ResolversTypes['audio_measurements_project_tasks']>, "audio_measurements_project_tasks_stream", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_Project_Tasks_StreamArgs, 'batch_size' | 'cursor'>>;
  audio_measurements_recordings?: SubscriptionResolver<Array<ResolversTypes['audio_measurements_recordings']>, "audio_measurements_recordings", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_RecordingsArgs, never>>;
  audio_measurements_recordings_aggregate?: SubscriptionResolver<ResolversTypes['audio_measurements_recordings_aggregate'], "audio_measurements_recordings_aggregate", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_Recordings_AggregateArgs, never>>;
  audio_measurements_recordings_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['audio_measurements_recordings']>, "audio_measurements_recordings_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_Recordings_By_PkArgs, 'id'>>;
  audio_measurements_recordings_stream?: SubscriptionResolver<Array<ResolversTypes['audio_measurements_recordings']>, "audio_measurements_recordings_stream", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_Recordings_StreamArgs, 'batch_size' | 'cursor'>>;
  audio_measurements_stages?: SubscriptionResolver<Array<ResolversTypes['audio_measurements_stages']>, "audio_measurements_stages", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_StagesArgs, never>>;
  audio_measurements_stages_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['audio_measurements_stages']>, "audio_measurements_stages_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_Stages_By_PkArgs, 'id'>>;
  audio_measurements_stages_stream?: SubscriptionResolver<Array<ResolversTypes['audio_measurements_stages']>, "audio_measurements_stages_stream", ParentType, ContextType, RequireFields<Subscription_RootAudio_Measurements_Stages_StreamArgs, 'batch_size' | 'cursor'>>;
  bringup_checklist_deliverable?: SubscriptionResolver<Array<ResolversTypes['bringup_checklist_deliverable']>, "bringup_checklist_deliverable", ParentType, ContextType, RequireFields<Subscription_RootBringup_Checklist_DeliverableArgs, never>>;
  bringup_checklist_deliverable_aggregate?: SubscriptionResolver<ResolversTypes['bringup_checklist_deliverable_aggregate'], "bringup_checklist_deliverable_aggregate", ParentType, ContextType, RequireFields<Subscription_RootBringup_Checklist_Deliverable_AggregateArgs, never>>;
  bringup_checklist_deliverable_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['bringup_checklist_deliverable']>, "bringup_checklist_deliverable_by_pk", ParentType, ContextType, RequireFields<Subscription_RootBringup_Checklist_Deliverable_By_PkArgs, 'checklist_id' | 'deliverable_id'>>;
  bringup_checklist_deliverable_stream?: SubscriptionResolver<Array<ResolversTypes['bringup_checklist_deliverable']>, "bringup_checklist_deliverable_stream", ParentType, ContextType, RequireFields<Subscription_RootBringup_Checklist_Deliverable_StreamArgs, 'batch_size' | 'cursor'>>;
  bringup_checklists?: SubscriptionResolver<Array<ResolversTypes['bringup_checklists']>, "bringup_checklists", ParentType, ContextType, RequireFields<Subscription_RootBringup_ChecklistsArgs, never>>;
  bringup_checklists_aggregate?: SubscriptionResolver<ResolversTypes['bringup_checklists_aggregate'], "bringup_checklists_aggregate", ParentType, ContextType, RequireFields<Subscription_RootBringup_Checklists_AggregateArgs, never>>;
  bringup_checklists_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['bringup_checklists']>, "bringup_checklists_by_pk", ParentType, ContextType, RequireFields<Subscription_RootBringup_Checklists_By_PkArgs, 'id'>>;
  bringup_checklists_stream?: SubscriptionResolver<Array<ResolversTypes['bringup_checklists']>, "bringup_checklists_stream", ParentType, ContextType, RequireFields<Subscription_RootBringup_Checklists_StreamArgs, 'batch_size' | 'cursor'>>;
  bringup_endorsements?: SubscriptionResolver<Array<ResolversTypes['bringup_endorsements']>, "bringup_endorsements", ParentType, ContextType, RequireFields<Subscription_RootBringup_EndorsementsArgs, never>>;
  bringup_endorsements_aggregate?: SubscriptionResolver<ResolversTypes['bringup_endorsements_aggregate'], "bringup_endorsements_aggregate", ParentType, ContextType, RequireFields<Subscription_RootBringup_Endorsements_AggregateArgs, never>>;
  bringup_endorsements_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['bringup_endorsements']>, "bringup_endorsements_by_pk", ParentType, ContextType, RequireFields<Subscription_RootBringup_Endorsements_By_PkArgs, 'item_id' | 'project_id'>>;
  bringup_endorsements_stream?: SubscriptionResolver<Array<ResolversTypes['bringup_endorsements']>, "bringup_endorsements_stream", ParentType, ContextType, RequireFields<Subscription_RootBringup_Endorsements_StreamArgs, 'batch_size' | 'cursor'>>;
  bringup_items?: SubscriptionResolver<Array<ResolversTypes['bringup_items']>, "bringup_items", ParentType, ContextType, RequireFields<Subscription_RootBringup_ItemsArgs, never>>;
  bringup_items_aggregate?: SubscriptionResolver<ResolversTypes['bringup_items_aggregate'], "bringup_items_aggregate", ParentType, ContextType, RequireFields<Subscription_RootBringup_Items_AggregateArgs, never>>;
  bringup_items_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['bringup_items']>, "bringup_items_by_pk", ParentType, ContextType, RequireFields<Subscription_RootBringup_Items_By_PkArgs, 'id'>>;
  bringup_items_stream?: SubscriptionResolver<Array<ResolversTypes['bringup_items']>, "bringup_items_stream", ParentType, ContextType, RequireFields<Subscription_RootBringup_Items_StreamArgs, 'batch_size' | 'cursor'>>;
  bringup_milestones?: SubscriptionResolver<Array<ResolversTypes['bringup_milestones']>, "bringup_milestones", ParentType, ContextType, RequireFields<Subscription_RootBringup_MilestonesArgs, never>>;
  bringup_milestones_aggregate?: SubscriptionResolver<ResolversTypes['bringup_milestones_aggregate'], "bringup_milestones_aggregate", ParentType, ContextType, RequireFields<Subscription_RootBringup_Milestones_AggregateArgs, never>>;
  bringup_milestones_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['bringup_milestones']>, "bringup_milestones_by_pk", ParentType, ContextType, RequireFields<Subscription_RootBringup_Milestones_By_PkArgs, 'item_id'>>;
  bringup_milestones_stream?: SubscriptionResolver<Array<ResolversTypes['bringup_milestones']>, "bringup_milestones_stream", ParentType, ContextType, RequireFields<Subscription_RootBringup_Milestones_StreamArgs, 'batch_size' | 'cursor'>>;
  bringup_project_item_comments?: SubscriptionResolver<Array<ResolversTypes['bringup_project_item_comments']>, "bringup_project_item_comments", ParentType, ContextType, RequireFields<Subscription_RootBringup_Project_Item_CommentsArgs, never>>;
  bringup_project_item_comments_aggregate?: SubscriptionResolver<ResolversTypes['bringup_project_item_comments_aggregate'], "bringup_project_item_comments_aggregate", ParentType, ContextType, RequireFields<Subscription_RootBringup_Project_Item_Comments_AggregateArgs, never>>;
  bringup_project_item_comments_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['bringup_project_item_comments']>, "bringup_project_item_comments_by_pk", ParentType, ContextType, RequireFields<Subscription_RootBringup_Project_Item_Comments_By_PkArgs, 'created_at' | 'item_id' | 'project_id'>>;
  bringup_project_item_comments_stream?: SubscriptionResolver<Array<ResolversTypes['bringup_project_item_comments']>, "bringup_project_item_comments_stream", ParentType, ContextType, RequireFields<Subscription_RootBringup_Project_Item_Comments_StreamArgs, 'batch_size' | 'cursor'>>;
  categories?: SubscriptionResolver<Array<ResolversTypes['categories']>, "categories", ParentType, ContextType, RequireFields<Subscription_RootCategoriesArgs, never>>;
  categories_aggregate?: SubscriptionResolver<ResolversTypes['categories_aggregate'], "categories_aggregate", ParentType, ContextType, RequireFields<Subscription_RootCategories_AggregateArgs, never>>;
  categories_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['categories']>, "categories_by_pk", ParentType, ContextType, RequireFields<Subscription_RootCategories_By_PkArgs, 'id'>>;
  categories_stream?: SubscriptionResolver<Array<ResolversTypes['categories']>, "categories_stream", ParentType, ContextType, RequireFields<Subscription_RootCategories_StreamArgs, 'batch_size' | 'cursor'>>;
  context_display_field?: SubscriptionResolver<Array<ResolversTypes['context_display_field']>, "context_display_field", ParentType, ContextType, RequireFields<Subscription_RootContext_Display_FieldArgs, never>>;
  context_display_field_aggregate?: SubscriptionResolver<ResolversTypes['context_display_field_aggregate'], "context_display_field_aggregate", ParentType, ContextType, RequireFields<Subscription_RootContext_Display_Field_AggregateArgs, never>>;
  context_display_field_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['context_display_field']>, "context_display_field_by_pk", ParentType, ContextType, RequireFields<Subscription_RootContext_Display_Field_By_PkArgs, 'context_display_id' | 'field_id'>>;
  context_display_field_stream?: SubscriptionResolver<Array<ResolversTypes['context_display_field']>, "context_display_field_stream", ParentType, ContextType, RequireFields<Subscription_RootContext_Display_Field_StreamArgs, 'batch_size' | 'cursor'>>;
  context_displays?: SubscriptionResolver<Array<ResolversTypes['context_displays']>, "context_displays", ParentType, ContextType, RequireFields<Subscription_RootContext_DisplaysArgs, never>>;
  context_displays_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['context_displays']>, "context_displays_by_pk", ParentType, ContextType, RequireFields<Subscription_RootContext_Displays_By_PkArgs, 'id'>>;
  context_displays_stream?: SubscriptionResolver<Array<ResolversTypes['context_displays']>, "context_displays_stream", ParentType, ContextType, RequireFields<Subscription_RootContext_Displays_StreamArgs, 'batch_size' | 'cursor'>>;
  contexts?: SubscriptionResolver<Array<ResolversTypes['contexts']>, "contexts", ParentType, ContextType, RequireFields<Subscription_RootContextsArgs, never>>;
  contexts_aggregate?: SubscriptionResolver<ResolversTypes['contexts_aggregate'], "contexts_aggregate", ParentType, ContextType, RequireFields<Subscription_RootContexts_AggregateArgs, never>>;
  contexts_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['contexts']>, "contexts_by_pk", ParentType, ContextType, RequireFields<Subscription_RootContexts_By_PkArgs, 'id'>>;
  contexts_stream?: SubscriptionResolver<Array<ResolversTypes['contexts']>, "contexts_stream", ParentType, ContextType, RequireFields<Subscription_RootContexts_StreamArgs, 'batch_size' | 'cursor'>>;
  customers?: SubscriptionResolver<Array<ResolversTypes['customers']>, "customers", ParentType, ContextType, RequireFields<Subscription_RootCustomersArgs, never>>;
  customers_aggregate?: SubscriptionResolver<ResolversTypes['customers_aggregate'], "customers_aggregate", ParentType, ContextType, RequireFields<Subscription_RootCustomers_AggregateArgs, never>>;
  customers_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['customers']>, "customers_by_pk", ParentType, ContextType, RequireFields<Subscription_RootCustomers_By_PkArgs, 'id'>>;
  customers_stream?: SubscriptionResolver<Array<ResolversTypes['customers']>, "customers_stream", ParentType, ContextType, RequireFields<Subscription_RootCustomers_StreamArgs, 'batch_size' | 'cursor'>>;
  deliverables?: SubscriptionResolver<Array<ResolversTypes['deliverables']>, "deliverables", ParentType, ContextType, RequireFields<Subscription_RootDeliverablesArgs, never>>;
  deliverables_aggregate?: SubscriptionResolver<ResolversTypes['deliverables_aggregate'], "deliverables_aggregate", ParentType, ContextType, RequireFields<Subscription_RootDeliverables_AggregateArgs, never>>;
  deliverables_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['deliverables']>, "deliverables_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDeliverables_By_PkArgs, 'id'>>;
  deliverables_stream?: SubscriptionResolver<Array<ResolversTypes['deliverables']>, "deliverables_stream", ParentType, ContextType, RequireFields<Subscription_RootDeliverables_StreamArgs, 'batch_size' | 'cursor'>>;
  deliveries_architectures?: SubscriptionResolver<Array<ResolversTypes['deliveries_architectures']>, "deliveries_architectures", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_ArchitecturesArgs, never>>;
  deliveries_architectures_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['deliveries_architectures']>, "deliveries_architectures_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Architectures_By_PkArgs, 'id'>>;
  deliveries_architectures_stream?: SubscriptionResolver<Array<ResolversTypes['deliveries_architectures']>, "deliveries_architectures_stream", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Architectures_StreamArgs, 'batch_size' | 'cursor'>>;
  deliveries_artifact_microsoft_whql_submission?: SubscriptionResolver<Array<ResolversTypes['deliveries_artifact_microsoft_whql_submission']>, "deliveries_artifact_microsoft_whql_submission", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Artifact_Microsoft_Whql_SubmissionArgs, never>>;
  deliveries_artifact_microsoft_whql_submission_aggregate?: SubscriptionResolver<ResolversTypes['deliveries_artifact_microsoft_whql_submission_aggregate'], "deliveries_artifact_microsoft_whql_submission_aggregate", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Artifact_Microsoft_Whql_Submission_AggregateArgs, never>>;
  deliveries_artifact_microsoft_whql_submission_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['deliveries_artifact_microsoft_whql_submission']>, "deliveries_artifact_microsoft_whql_submission_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Artifact_Microsoft_Whql_Submission_By_PkArgs, 'artifact_id' | 'microsoft_whql_submission_id'>>;
  deliveries_artifact_microsoft_whql_submission_stream?: SubscriptionResolver<Array<ResolversTypes['deliveries_artifact_microsoft_whql_submission']>, "deliveries_artifact_microsoft_whql_submission_stream", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Artifact_Microsoft_Whql_Submission_StreamArgs, 'batch_size' | 'cursor'>>;
  deliveries_artifact_operating_system?: SubscriptionResolver<Array<ResolversTypes['deliveries_artifact_operating_system']>, "deliveries_artifact_operating_system", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Artifact_Operating_SystemArgs, never>>;
  deliveries_artifact_operating_system_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['deliveries_artifact_operating_system']>, "deliveries_artifact_operating_system_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Artifact_Operating_System_By_PkArgs, 'artifact_id' | 'os_id'>>;
  deliveries_artifact_operating_system_stream?: SubscriptionResolver<Array<ResolversTypes['deliveries_artifact_operating_system']>, "deliveries_artifact_operating_system_stream", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Artifact_Operating_System_StreamArgs, 'batch_size' | 'cursor'>>;
  deliveries_artifacts?: SubscriptionResolver<Array<ResolversTypes['deliveries_artifacts']>, "deliveries_artifacts", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_ArtifactsArgs, never>>;
  deliveries_artifacts_aggregate?: SubscriptionResolver<ResolversTypes['deliveries_artifacts_aggregate'], "deliveries_artifacts_aggregate", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Artifacts_AggregateArgs, never>>;
  deliveries_artifacts_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['deliveries_artifacts']>, "deliveries_artifacts_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Artifacts_By_PkArgs, 'id'>>;
  deliveries_artifacts_stream?: SubscriptionResolver<Array<ResolversTypes['deliveries_artifacts']>, "deliveries_artifacts_stream", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Artifacts_StreamArgs, 'batch_size' | 'cursor'>>;
  deliveries_device_families?: SubscriptionResolver<Array<ResolversTypes['deliveries_device_families']>, "deliveries_device_families", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Device_FamiliesArgs, never>>;
  deliveries_device_families_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['deliveries_device_families']>, "deliveries_device_families_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Device_Families_By_PkArgs, 'id'>>;
  deliveries_device_families_stream?: SubscriptionResolver<Array<ResolversTypes['deliveries_device_families']>, "deliveries_device_families_stream", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Device_Families_StreamArgs, 'batch_size' | 'cursor'>>;
  deliveries_driver_package?: SubscriptionResolver<Array<ResolversTypes['deliveries_driver_package']>, "deliveries_driver_package", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Driver_PackageArgs, never>>;
  deliveries_driver_package_aggregate?: SubscriptionResolver<ResolversTypes['deliveries_driver_package_aggregate'], "deliveries_driver_package_aggregate", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Driver_Package_AggregateArgs, never>>;
  deliveries_driver_package_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['deliveries_driver_package']>, "deliveries_driver_package_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Driver_Package_By_PkArgs, 'driver_id' | 'package_id'>>;
  deliveries_driver_package_stream?: SubscriptionResolver<Array<ResolversTypes['deliveries_driver_package']>, "deliveries_driver_package_stream", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Driver_Package_StreamArgs, 'batch_size' | 'cursor'>>;
  deliveries_driver_version_package?: SubscriptionResolver<Array<ResolversTypes['deliveries_driver_version_package']>, "deliveries_driver_version_package", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Driver_Version_PackageArgs, never>>;
  deliveries_driver_version_package_aggregate?: SubscriptionResolver<ResolversTypes['deliveries_driver_version_package_aggregate'], "deliveries_driver_version_package_aggregate", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Driver_Version_Package_AggregateArgs, never>>;
  deliveries_driver_version_package_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['deliveries_driver_version_package']>, "deliveries_driver_version_package_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Driver_Version_Package_By_PkArgs, 'driver_version_id' | 'package_id'>>;
  deliveries_driver_version_package_stream?: SubscriptionResolver<Array<ResolversTypes['deliveries_driver_version_package']>, "deliveries_driver_version_package_stream", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Driver_Version_Package_StreamArgs, 'batch_size' | 'cursor'>>;
  deliveries_driver_versions?: SubscriptionResolver<Array<ResolversTypes['deliveries_driver_versions']>, "deliveries_driver_versions", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Driver_VersionsArgs, never>>;
  deliveries_driver_versions_aggregate?: SubscriptionResolver<ResolversTypes['deliveries_driver_versions_aggregate'], "deliveries_driver_versions_aggregate", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Driver_Versions_AggregateArgs, never>>;
  deliveries_driver_versions_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['deliveries_driver_versions']>, "deliveries_driver_versions_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Driver_Versions_By_PkArgs, 'id'>>;
  deliveries_driver_versions_stream?: SubscriptionResolver<Array<ResolversTypes['deliveries_driver_versions']>, "deliveries_driver_versions_stream", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Driver_Versions_StreamArgs, 'batch_size' | 'cursor'>>;
  deliveries_drivers?: SubscriptionResolver<Array<ResolversTypes['deliveries_drivers']>, "deliveries_drivers", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_DriversArgs, never>>;
  deliveries_drivers_aggregate?: SubscriptionResolver<ResolversTypes['deliveries_drivers_aggregate'], "deliveries_drivers_aggregate", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Drivers_AggregateArgs, never>>;
  deliveries_drivers_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['deliveries_drivers']>, "deliveries_drivers_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Drivers_By_PkArgs, 'id'>>;
  deliveries_drivers_stream?: SubscriptionResolver<Array<ResolversTypes['deliveries_drivers']>, "deliveries_drivers_stream", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Drivers_StreamArgs, 'batch_size' | 'cursor'>>;
  deliveries_microsoft_hlk_artifacts?: SubscriptionResolver<Array<ResolversTypes['deliveries_microsoft_hlk_artifacts']>, "deliveries_microsoft_hlk_artifacts", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Microsoft_Hlk_ArtifactsArgs, never>>;
  deliveries_microsoft_hlk_artifacts_aggregate?: SubscriptionResolver<ResolversTypes['deliveries_microsoft_hlk_artifacts_aggregate'], "deliveries_microsoft_hlk_artifacts_aggregate", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Microsoft_Hlk_Artifacts_AggregateArgs, never>>;
  deliveries_microsoft_hlk_artifacts_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['deliveries_microsoft_hlk_artifacts']>, "deliveries_microsoft_hlk_artifacts_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Microsoft_Hlk_Artifacts_By_PkArgs, 'id'>>;
  deliveries_microsoft_hlk_artifacts_stream?: SubscriptionResolver<Array<ResolversTypes['deliveries_microsoft_hlk_artifacts']>, "deliveries_microsoft_hlk_artifacts_stream", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Microsoft_Hlk_Artifacts_StreamArgs, 'batch_size' | 'cursor'>>;
  deliveries_microsoft_shipping_label_reviews?: SubscriptionResolver<Array<ResolversTypes['deliveries_microsoft_shipping_label_reviews']>, "deliveries_microsoft_shipping_label_reviews", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Microsoft_Shipping_Label_ReviewsArgs, never>>;
  deliveries_microsoft_shipping_label_reviews_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['deliveries_microsoft_shipping_label_reviews']>, "deliveries_microsoft_shipping_label_reviews_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Microsoft_Shipping_Label_Reviews_By_PkArgs, 'microsoft_shipping_label_id'>>;
  deliveries_microsoft_shipping_label_reviews_stream?: SubscriptionResolver<Array<ResolversTypes['deliveries_microsoft_shipping_label_reviews']>, "deliveries_microsoft_shipping_label_reviews_stream", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Microsoft_Shipping_Label_Reviews_StreamArgs, 'batch_size' | 'cursor'>>;
  deliveries_microsoft_shipping_labels?: SubscriptionResolver<Array<ResolversTypes['deliveries_microsoft_shipping_labels']>, "deliveries_microsoft_shipping_labels", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Microsoft_Shipping_LabelsArgs, never>>;
  deliveries_microsoft_shipping_labels_aggregate?: SubscriptionResolver<ResolversTypes['deliveries_microsoft_shipping_labels_aggregate'], "deliveries_microsoft_shipping_labels_aggregate", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Microsoft_Shipping_Labels_AggregateArgs, never>>;
  deliveries_microsoft_shipping_labels_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['deliveries_microsoft_shipping_labels']>, "deliveries_microsoft_shipping_labels_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Microsoft_Shipping_Labels_By_PkArgs, 'id'>>;
  deliveries_microsoft_shipping_labels_stream?: SubscriptionResolver<Array<ResolversTypes['deliveries_microsoft_shipping_labels']>, "deliveries_microsoft_shipping_labels_stream", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Microsoft_Shipping_Labels_StreamArgs, 'batch_size' | 'cursor'>>;
  deliveries_microsoft_whql_submissions?: SubscriptionResolver<Array<ResolversTypes['deliveries_microsoft_whql_submissions']>, "deliveries_microsoft_whql_submissions", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Microsoft_Whql_SubmissionsArgs, never>>;
  deliveries_microsoft_whql_submissions_aggregate?: SubscriptionResolver<ResolversTypes['deliveries_microsoft_whql_submissions_aggregate'], "deliveries_microsoft_whql_submissions_aggregate", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Microsoft_Whql_Submissions_AggregateArgs, never>>;
  deliveries_microsoft_whql_submissions_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['deliveries_microsoft_whql_submissions']>, "deliveries_microsoft_whql_submissions_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Microsoft_Whql_Submissions_By_PkArgs, 'id'>>;
  deliveries_microsoft_whql_submissions_stream?: SubscriptionResolver<Array<ResolversTypes['deliveries_microsoft_whql_submissions']>, "deliveries_microsoft_whql_submissions_stream", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Microsoft_Whql_Submissions_StreamArgs, 'batch_size' | 'cursor'>>;
  deliveries_operating_systems?: SubscriptionResolver<Array<ResolversTypes['deliveries_operating_systems']>, "deliveries_operating_systems", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Operating_SystemsArgs, never>>;
  deliveries_operating_systems_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['deliveries_operating_systems']>, "deliveries_operating_systems_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Operating_Systems_By_PkArgs, 'id'>>;
  deliveries_operating_systems_stream?: SubscriptionResolver<Array<ResolversTypes['deliveries_operating_systems']>, "deliveries_operating_systems_stream", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Operating_Systems_StreamArgs, 'batch_size' | 'cursor'>>;
  deliveries_package_operating_systems?: SubscriptionResolver<Array<ResolversTypes['deliveries_package_operating_systems']>, "deliveries_package_operating_systems", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Package_Operating_SystemsArgs, never>>;
  deliveries_package_operating_systems_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['deliveries_package_operating_systems']>, "deliveries_package_operating_systems_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Package_Operating_Systems_By_PkArgs, 'os_id' | 'package_id'>>;
  deliveries_package_operating_systems_stream?: SubscriptionResolver<Array<ResolversTypes['deliveries_package_operating_systems']>, "deliveries_package_operating_systems_stream", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Package_Operating_Systems_StreamArgs, 'batch_size' | 'cursor'>>;
  deliveries_package_target?: SubscriptionResolver<Array<ResolversTypes['deliveries_package_target']>, "deliveries_package_target", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Package_TargetArgs, never>>;
  deliveries_package_target_aggregate?: SubscriptionResolver<ResolversTypes['deliveries_package_target_aggregate'], "deliveries_package_target_aggregate", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Package_Target_AggregateArgs, never>>;
  deliveries_package_target_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['deliveries_package_target']>, "deliveries_package_target_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Package_Target_By_PkArgs, 'package_id' | 'target_id'>>;
  deliveries_package_target_stream?: SubscriptionResolver<Array<ResolversTypes['deliveries_package_target']>, "deliveries_package_target_stream", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Package_Target_StreamArgs, 'batch_size' | 'cursor'>>;
  deliveries_packages?: SubscriptionResolver<Array<ResolversTypes['deliveries_packages']>, "deliveries_packages", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_PackagesArgs, never>>;
  deliveries_packages_aggregate?: SubscriptionResolver<ResolversTypes['deliveries_packages_aggregate'], "deliveries_packages_aggregate", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Packages_AggregateArgs, never>>;
  deliveries_packages_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['deliveries_packages']>, "deliveries_packages_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Packages_By_PkArgs, 'id'>>;
  deliveries_packages_stream?: SubscriptionResolver<Array<ResolversTypes['deliveries_packages']>, "deliveries_packages_stream", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Packages_StreamArgs, 'batch_size' | 'cursor'>>;
  deliveries_partners?: SubscriptionResolver<Array<ResolversTypes['deliveries_partners']>, "deliveries_partners", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_PartnersArgs, never>>;
  deliveries_partners_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['deliveries_partners']>, "deliveries_partners_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Partners_By_PkArgs, 'id'>>;
  deliveries_partners_stream?: SubscriptionResolver<Array<ResolversTypes['deliveries_partners']>, "deliveries_partners_stream", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Partners_StreamArgs, 'batch_size' | 'cursor'>>;
  deliveries_platforms?: SubscriptionResolver<Array<ResolversTypes['deliveries_platforms']>, "deliveries_platforms", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_PlatformsArgs, never>>;
  deliveries_platforms_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['deliveries_platforms']>, "deliveries_platforms_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Platforms_By_PkArgs, 'id'>>;
  deliveries_platforms_stream?: SubscriptionResolver<Array<ResolversTypes['deliveries_platforms']>, "deliveries_platforms_stream", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Platforms_StreamArgs, 'batch_size' | 'cursor'>>;
  deliveries_project_configs?: SubscriptionResolver<Array<ResolversTypes['deliveries_project_configs']>, "deliveries_project_configs", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Project_ConfigsArgs, never>>;
  deliveries_project_configs_aggregate?: SubscriptionResolver<ResolversTypes['deliveries_project_configs_aggregate'], "deliveries_project_configs_aggregate", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Project_Configs_AggregateArgs, never>>;
  deliveries_project_configs_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['deliveries_project_configs']>, "deliveries_project_configs_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Project_Configs_By_PkArgs, 'id'>>;
  deliveries_project_configs_stream?: SubscriptionResolver<Array<ResolversTypes['deliveries_project_configs']>, "deliveries_project_configs_stream", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Project_Configs_StreamArgs, 'batch_size' | 'cursor'>>;
  deliveries_target_partner?: SubscriptionResolver<Array<ResolversTypes['deliveries_target_partner']>, "deliveries_target_partner", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Target_PartnerArgs, never>>;
  deliveries_target_partner_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['deliveries_target_partner']>, "deliveries_target_partner_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Target_Partner_By_PkArgs, 'partner_id' | 'target_id'>>;
  deliveries_target_partner_stream?: SubscriptionResolver<Array<ResolversTypes['deliveries_target_partner']>, "deliveries_target_partner_stream", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Target_Partner_StreamArgs, 'batch_size' | 'cursor'>>;
  deliveries_targets?: SubscriptionResolver<Array<ResolversTypes['deliveries_targets']>, "deliveries_targets", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_TargetsArgs, never>>;
  deliveries_targets_aggregate?: SubscriptionResolver<ResolversTypes['deliveries_targets_aggregate'], "deliveries_targets_aggregate", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Targets_AggregateArgs, never>>;
  deliveries_targets_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['deliveries_targets']>, "deliveries_targets_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Targets_By_PkArgs, 'id'>>;
  deliveries_targets_stream?: SubscriptionResolver<Array<ResolversTypes['deliveries_targets']>, "deliveries_targets_stream", ParentType, ContextType, RequireFields<Subscription_RootDeliveries_Targets_StreamArgs, 'batch_size' | 'cursor'>>;
  device_firmware?: SubscriptionResolver<Array<ResolversTypes['device_firmware']>, "device_firmware", ParentType, ContextType, RequireFields<Subscription_RootDevice_FirmwareArgs, never>>;
  device_firmware_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['device_firmware']>, "device_firmware_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDevice_Firmware_By_PkArgs, 'device_id' | 'firmware_id'>>;
  device_firmware_stream?: SubscriptionResolver<Array<ResolversTypes['device_firmware']>, "device_firmware_stream", ParentType, ContextType, RequireFields<Subscription_RootDevice_Firmware_StreamArgs, 'batch_size' | 'cursor'>>;
  device_project?: SubscriptionResolver<Array<ResolversTypes['device_project']>, "device_project", ParentType, ContextType, RequireFields<Subscription_RootDevice_ProjectArgs, never>>;
  device_project_aggregate?: SubscriptionResolver<ResolversTypes['device_project_aggregate'], "device_project_aggregate", ParentType, ContextType, RequireFields<Subscription_RootDevice_Project_AggregateArgs, never>>;
  device_project_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['device_project']>, "device_project_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDevice_Project_By_PkArgs, 'device_id' | 'project_id'>>;
  device_project_stream?: SubscriptionResolver<Array<ResolversTypes['device_project']>, "device_project_stream", ParentType, ContextType, RequireFields<Subscription_RootDevice_Project_StreamArgs, 'batch_size' | 'cursor'>>;
  devices?: SubscriptionResolver<Array<ResolversTypes['devices']>, "devices", ParentType, ContextType, RequireFields<Subscription_RootDevicesArgs, never>>;
  devices_aggregate?: SubscriptionResolver<ResolversTypes['devices_aggregate'], "devices_aggregate", ParentType, ContextType, RequireFields<Subscription_RootDevices_AggregateArgs, never>>;
  devices_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['devices']>, "devices_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDevices_By_PkArgs, 'id'>>;
  devices_stream?: SubscriptionResolver<Array<ResolversTypes['devices']>, "devices_stream", ParentType, ContextType, RequireFields<Subscription_RootDevices_StreamArgs, 'batch_size' | 'cursor'>>;
  dynamic_scaling?: SubscriptionResolver<Array<ResolversTypes['dynamic_scaling']>, "dynamic_scaling", ParentType, ContextType, RequireFields<Subscription_RootDynamic_ScalingArgs, never>>;
  dynamic_scaling_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['dynamic_scaling']>, "dynamic_scaling_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDynamic_Scaling_By_PkArgs, 'id'>>;
  dynamic_scaling_stream?: SubscriptionResolver<Array<ResolversTypes['dynamic_scaling']>, "dynamic_scaling_stream", ParentType, ContextType, RequireFields<Subscription_RootDynamic_Scaling_StreamArgs, 'batch_size' | 'cursor'>>;
  engine_opmode?: SubscriptionResolver<Array<ResolversTypes['engine_opmode']>, "engine_opmode", ParentType, ContextType, RequireFields<Subscription_RootEngine_OpmodeArgs, never>>;
  engine_opmode_aggregate?: SubscriptionResolver<ResolversTypes['engine_opmode_aggregate'], "engine_opmode_aggregate", ParentType, ContextType, RequireFields<Subscription_RootEngine_Opmode_AggregateArgs, never>>;
  engine_opmode_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['engine_opmode']>, "engine_opmode_by_pk", ParentType, ContextType, RequireFields<Subscription_RootEngine_Opmode_By_PkArgs, 'engine_id' | 'opmode_id'>>;
  engine_opmode_recording?: SubscriptionResolver<Array<ResolversTypes['engine_opmode_recording']>, "engine_opmode_recording", ParentType, ContextType, RequireFields<Subscription_RootEngine_Opmode_RecordingArgs, never>>;
  engine_opmode_recording_aggregate?: SubscriptionResolver<ResolversTypes['engine_opmode_recording_aggregate'], "engine_opmode_recording_aggregate", ParentType, ContextType, RequireFields<Subscription_RootEngine_Opmode_Recording_AggregateArgs, never>>;
  engine_opmode_recording_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['engine_opmode_recording']>, "engine_opmode_recording_by_pk", ParentType, ContextType, RequireFields<Subscription_RootEngine_Opmode_Recording_By_PkArgs, 'engine_id' | 'opmode_id' | 'recording_id'>>;
  engine_opmode_recording_stream?: SubscriptionResolver<Array<ResolversTypes['engine_opmode_recording']>, "engine_opmode_recording_stream", ParentType, ContextType, RequireFields<Subscription_RootEngine_Opmode_Recording_StreamArgs, 'batch_size' | 'cursor'>>;
  engine_opmode_stream?: SubscriptionResolver<Array<ResolversTypes['engine_opmode']>, "engine_opmode_stream", ParentType, ContextType, RequireFields<Subscription_RootEngine_Opmode_StreamArgs, 'batch_size' | 'cursor'>>;
  engine_types?: SubscriptionResolver<Array<ResolversTypes['engine_types']>, "engine_types", ParentType, ContextType, RequireFields<Subscription_RootEngine_TypesArgs, never>>;
  engine_types_aggregate?: SubscriptionResolver<ResolversTypes['engine_types_aggregate'], "engine_types_aggregate", ParentType, ContextType, RequireFields<Subscription_RootEngine_Types_AggregateArgs, never>>;
  engine_types_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['engine_types']>, "engine_types_by_pk", ParentType, ContextType, RequireFields<Subscription_RootEngine_Types_By_PkArgs, 'id'>>;
  engine_types_stream?: SubscriptionResolver<Array<ResolversTypes['engine_types']>, "engine_types_stream", ParentType, ContextType, RequireFields<Subscription_RootEngine_Types_StreamArgs, 'batch_size' | 'cursor'>>;
  engines?: SubscriptionResolver<Array<ResolversTypes['engines']>, "engines", ParentType, ContextType, RequireFields<Subscription_RootEnginesArgs, never>>;
  engines_aggregate?: SubscriptionResolver<ResolversTypes['engines_aggregate'], "engines_aggregate", ParentType, ContextType, RequireFields<Subscription_RootEngines_AggregateArgs, never>>;
  engines_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['engines']>, "engines_by_pk", ParentType, ContextType, RequireFields<Subscription_RootEngines_By_PkArgs, 'id'>>;
  engines_stream?: SubscriptionResolver<Array<ResolversTypes['engines']>, "engines_stream", ParentType, ContextType, RequireFields<Subscription_RootEngines_StreamArgs, 'batch_size' | 'cursor'>>;
  field_option_media?: SubscriptionResolver<Array<ResolversTypes['field_option_media']>, "field_option_media", ParentType, ContextType, RequireFields<Subscription_RootField_Option_MediaArgs, never>>;
  field_option_media_aggregate?: SubscriptionResolver<ResolversTypes['field_option_media_aggregate'], "field_option_media_aggregate", ParentType, ContextType, RequireFields<Subscription_RootField_Option_Media_AggregateArgs, never>>;
  field_option_media_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['field_option_media']>, "field_option_media_by_pk", ParentType, ContextType, RequireFields<Subscription_RootField_Option_Media_By_PkArgs, 'field_option_id' | 'media_id'>>;
  field_option_media_stream?: SubscriptionResolver<Array<ResolversTypes['field_option_media']>, "field_option_media_stream", ParentType, ContextType, RequireFields<Subscription_RootField_Option_Media_StreamArgs, 'batch_size' | 'cursor'>>;
  field_options?: SubscriptionResolver<Array<ResolversTypes['field_options']>, "field_options", ParentType, ContextType, RequireFields<Subscription_RootField_OptionsArgs, never>>;
  field_options_aggregate?: SubscriptionResolver<ResolversTypes['field_options_aggregate'], "field_options_aggregate", ParentType, ContextType, RequireFields<Subscription_RootField_Options_AggregateArgs, never>>;
  field_options_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['field_options']>, "field_options_by_pk", ParentType, ContextType, RequireFields<Subscription_RootField_Options_By_PkArgs, 'id'>>;
  field_options_stream?: SubscriptionResolver<Array<ResolversTypes['field_options']>, "field_options_stream", ParentType, ContextType, RequireFields<Subscription_RootField_Options_StreamArgs, 'batch_size' | 'cursor'>>;
  field_translation?: SubscriptionResolver<Array<ResolversTypes['field_translation']>, "field_translation", ParentType, ContextType, RequireFields<Subscription_RootField_TranslationArgs, never>>;
  field_translation_aggregate?: SubscriptionResolver<ResolversTypes['field_translation_aggregate'], "field_translation_aggregate", ParentType, ContextType, RequireFields<Subscription_RootField_Translation_AggregateArgs, never>>;
  field_translation_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['field_translation']>, "field_translation_by_pk", ParentType, ContextType, RequireFields<Subscription_RootField_Translation_By_PkArgs, 'field_id' | 'language'>>;
  field_translation_stream?: SubscriptionResolver<Array<ResolversTypes['field_translation']>, "field_translation_stream", ParentType, ContextType, RequireFields<Subscription_RootField_Translation_StreamArgs, 'batch_size' | 'cursor'>>;
  fields?: SubscriptionResolver<Array<ResolversTypes['fields']>, "fields", ParentType, ContextType, RequireFields<Subscription_RootFieldsArgs, never>>;
  fields_aggregate?: SubscriptionResolver<ResolversTypes['fields_aggregate'], "fields_aggregate", ParentType, ContextType, RequireFields<Subscription_RootFields_AggregateArgs, never>>;
  fields_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['fields']>, "fields_by_pk", ParentType, ContextType, RequireFields<Subscription_RootFields_By_PkArgs, 'id'>>;
  fields_stream?: SubscriptionResolver<Array<ResolversTypes['fields']>, "fields_stream", ParentType, ContextType, RequireFields<Subscription_RootFields_StreamArgs, 'batch_size' | 'cursor'>>;
  firmware?: SubscriptionResolver<Array<ResolversTypes['firmware']>, "firmware", ParentType, ContextType, RequireFields<Subscription_RootFirmwareArgs, never>>;
  firmware_aggregate?: SubscriptionResolver<ResolversTypes['firmware_aggregate'], "firmware_aggregate", ParentType, ContextType, RequireFields<Subscription_RootFirmware_AggregateArgs, never>>;
  firmware_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['firmware']>, "firmware_by_pk", ParentType, ContextType, RequireFields<Subscription_RootFirmware_By_PkArgs, 'id'>>;
  firmware_stream?: SubscriptionResolver<Array<ResolversTypes['firmware']>, "firmware_stream", ParentType, ContextType, RequireFields<Subscription_RootFirmware_StreamArgs, 'batch_size' | 'cursor'>>;
  ground_truth_configurations?: SubscriptionResolver<Array<ResolversTypes['ground_truth_configurations']>, "ground_truth_configurations", ParentType, ContextType, RequireFields<Subscription_RootGround_Truth_ConfigurationsArgs, never>>;
  ground_truth_configurations_aggregate?: SubscriptionResolver<ResolversTypes['ground_truth_configurations_aggregate'], "ground_truth_configurations_aggregate", ParentType, ContextType, RequireFields<Subscription_RootGround_Truth_Configurations_AggregateArgs, never>>;
  ground_truth_configurations_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['ground_truth_configurations']>, "ground_truth_configurations_by_pk", ParentType, ContextType, RequireFields<Subscription_RootGround_Truth_Configurations_By_PkArgs, 'id'>>;
  ground_truth_configurations_stream?: SubscriptionResolver<Array<ResolversTypes['ground_truth_configurations']>, "ground_truth_configurations_stream", ParentType, ContextType, RequireFields<Subscription_RootGround_Truth_Configurations_StreamArgs, 'batch_size' | 'cursor'>>;
  ground_truth_device_types?: SubscriptionResolver<Array<ResolversTypes['ground_truth_device_types']>, "ground_truth_device_types", ParentType, ContextType, RequireFields<Subscription_RootGround_Truth_Device_TypesArgs, never>>;
  ground_truth_device_types_aggregate?: SubscriptionResolver<ResolversTypes['ground_truth_device_types_aggregate'], "ground_truth_device_types_aggregate", ParentType, ContextType, RequireFields<Subscription_RootGround_Truth_Device_Types_AggregateArgs, never>>;
  ground_truth_device_types_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['ground_truth_device_types']>, "ground_truth_device_types_by_pk", ParentType, ContextType, RequireFields<Subscription_RootGround_Truth_Device_Types_By_PkArgs, 'id'>>;
  ground_truth_device_types_stream?: SubscriptionResolver<Array<ResolversTypes['ground_truth_device_types']>, "ground_truth_device_types_stream", ParentType, ContextType, RequireFields<Subscription_RootGround_Truth_Device_Types_StreamArgs, 'batch_size' | 'cursor'>>;
  ground_truth_processing_modes?: SubscriptionResolver<Array<ResolversTypes['ground_truth_processing_modes']>, "ground_truth_processing_modes", ParentType, ContextType, RequireFields<Subscription_RootGround_Truth_Processing_ModesArgs, never>>;
  ground_truth_processing_modes_aggregate?: SubscriptionResolver<ResolversTypes['ground_truth_processing_modes_aggregate'], "ground_truth_processing_modes_aggregate", ParentType, ContextType, RequireFields<Subscription_RootGround_Truth_Processing_Modes_AggregateArgs, never>>;
  ground_truth_processing_modes_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['ground_truth_processing_modes']>, "ground_truth_processing_modes_by_pk", ParentType, ContextType, RequireFields<Subscription_RootGround_Truth_Processing_Modes_By_PkArgs, 'id'>>;
  ground_truth_processing_modes_stream?: SubscriptionResolver<Array<ResolversTypes['ground_truth_processing_modes']>, "ground_truth_processing_modes_stream", ParentType, ContextType, RequireFields<Subscription_RootGround_Truth_Processing_Modes_StreamArgs, 'batch_size' | 'cursor'>>;
  ground_truth_recordings?: SubscriptionResolver<Array<ResolversTypes['ground_truth_recordings']>, "ground_truth_recordings", ParentType, ContextType, RequireFields<Subscription_RootGround_Truth_RecordingsArgs, never>>;
  ground_truth_recordings_aggregate?: SubscriptionResolver<ResolversTypes['ground_truth_recordings_aggregate'], "ground_truth_recordings_aggregate", ParentType, ContextType, RequireFields<Subscription_RootGround_Truth_Recordings_AggregateArgs, never>>;
  ground_truth_recordings_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['ground_truth_recordings']>, "ground_truth_recordings_by_pk", ParentType, ContextType, RequireFields<Subscription_RootGround_Truth_Recordings_By_PkArgs, 'recording_id'>>;
  ground_truth_recordings_stream?: SubscriptionResolver<Array<ResolversTypes['ground_truth_recordings']>, "ground_truth_recordings_stream", ParentType, ContextType, RequireFields<Subscription_RootGround_Truth_Recordings_StreamArgs, 'batch_size' | 'cursor'>>;
  ground_truth_types?: SubscriptionResolver<Array<ResolversTypes['ground_truth_types']>, "ground_truth_types", ParentType, ContextType, RequireFields<Subscription_RootGround_Truth_TypesArgs, never>>;
  ground_truth_types_aggregate?: SubscriptionResolver<ResolversTypes['ground_truth_types_aggregate'], "ground_truth_types_aggregate", ParentType, ContextType, RequireFields<Subscription_RootGround_Truth_Types_AggregateArgs, never>>;
  ground_truth_types_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['ground_truth_types']>, "ground_truth_types_by_pk", ParentType, ContextType, RequireFields<Subscription_RootGround_Truth_Types_By_PkArgs, 'id'>>;
  ground_truth_types_stream?: SubscriptionResolver<Array<ResolversTypes['ground_truth_types']>, "ground_truth_types_stream", ParentType, ContextType, RequireFields<Subscription_RootGround_Truth_Types_StreamArgs, 'batch_size' | 'cursor'>>;
  hypertask_field_option?: SubscriptionResolver<Array<ResolversTypes['hypertask_field_option']>, "hypertask_field_option", ParentType, ContextType, RequireFields<Subscription_RootHypertask_Field_OptionArgs, never>>;
  hypertask_field_option_aggregate?: SubscriptionResolver<ResolversTypes['hypertask_field_option_aggregate'], "hypertask_field_option_aggregate", ParentType, ContextType, RequireFields<Subscription_RootHypertask_Field_Option_AggregateArgs, never>>;
  hypertask_field_option_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['hypertask_field_option']>, "hypertask_field_option_by_pk", ParentType, ContextType, RequireFields<Subscription_RootHypertask_Field_Option_By_PkArgs, 'field_id' | 'hypertask_id' | 'option_id'>>;
  hypertask_field_option_stream?: SubscriptionResolver<Array<ResolversTypes['hypertask_field_option']>, "hypertask_field_option_stream", ParentType, ContextType, RequireFields<Subscription_RootHypertask_Field_Option_StreamArgs, 'batch_size' | 'cursor'>>;
  hypertask_task?: SubscriptionResolver<Array<ResolversTypes['hypertask_task']>, "hypertask_task", ParentType, ContextType, RequireFields<Subscription_RootHypertask_TaskArgs, never>>;
  hypertask_task_aggregate?: SubscriptionResolver<ResolversTypes['hypertask_task_aggregate'], "hypertask_task_aggregate", ParentType, ContextType, RequireFields<Subscription_RootHypertask_Task_AggregateArgs, never>>;
  hypertask_task_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['hypertask_task']>, "hypertask_task_by_pk", ParentType, ContextType, RequireFields<Subscription_RootHypertask_Task_By_PkArgs, 'hypertask_id' | 'task_id'>>;
  hypertask_task_stream?: SubscriptionResolver<Array<ResolversTypes['hypertask_task']>, "hypertask_task_stream", ParentType, ContextType, RequireFields<Subscription_RootHypertask_Task_StreamArgs, 'batch_size' | 'cursor'>>;
  hypertasks?: SubscriptionResolver<Array<ResolversTypes['hypertasks']>, "hypertasks", ParentType, ContextType, RequireFields<Subscription_RootHypertasksArgs, never>>;
  hypertasks_aggregate?: SubscriptionResolver<ResolversTypes['hypertasks_aggregate'], "hypertasks_aggregate", ParentType, ContextType, RequireFields<Subscription_RootHypertasks_AggregateArgs, never>>;
  hypertasks_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['hypertasks']>, "hypertasks_by_pk", ParentType, ContextType, RequireFields<Subscription_RootHypertasks_By_PkArgs, 'id'>>;
  hypertasks_stream?: SubscriptionResolver<Array<ResolversTypes['hypertasks']>, "hypertasks_stream", ParentType, ContextType, RequireFields<Subscription_RootHypertasks_StreamArgs, 'batch_size' | 'cursor'>>;
  instruction_translations?: SubscriptionResolver<Array<ResolversTypes['instruction_translations']>, "instruction_translations", ParentType, ContextType, RequireFields<Subscription_RootInstruction_TranslationsArgs, never>>;
  instruction_translations_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['instruction_translations']>, "instruction_translations_by_pk", ParentType, ContextType, RequireFields<Subscription_RootInstruction_Translations_By_PkArgs, 'id'>>;
  instruction_translations_stream?: SubscriptionResolver<Array<ResolversTypes['instruction_translations']>, "instruction_translations_stream", ParentType, ContextType, RequireFields<Subscription_RootInstruction_Translations_StreamArgs, 'batch_size' | 'cursor'>>;
  integration_test_expectation_media?: SubscriptionResolver<Array<ResolversTypes['integration_test_expectation_media']>, "integration_test_expectation_media", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Expectation_MediaArgs, never>>;
  integration_test_expectation_media_aggregate?: SubscriptionResolver<ResolversTypes['integration_test_expectation_media_aggregate'], "integration_test_expectation_media_aggregate", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Expectation_Media_AggregateArgs, never>>;
  integration_test_expectation_media_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['integration_test_expectation_media']>, "integration_test_expectation_media_by_pk", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Expectation_Media_By_PkArgs, 'expectation_id' | 'media_id'>>;
  integration_test_expectation_media_stream?: SubscriptionResolver<Array<ResolversTypes['integration_test_expectation_media']>, "integration_test_expectation_media_stream", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Expectation_Media_StreamArgs, 'batch_size' | 'cursor'>>;
  integration_test_expectation_step?: SubscriptionResolver<Array<ResolversTypes['integration_test_expectation_step']>, "integration_test_expectation_step", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Expectation_StepArgs, never>>;
  integration_test_expectation_step_aggregate?: SubscriptionResolver<ResolversTypes['integration_test_expectation_step_aggregate'], "integration_test_expectation_step_aggregate", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Expectation_Step_AggregateArgs, never>>;
  integration_test_expectation_step_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['integration_test_expectation_step']>, "integration_test_expectation_step_by_pk", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Expectation_Step_By_PkArgs, 'expectation_id' | 'step_id'>>;
  integration_test_expectation_step_stream?: SubscriptionResolver<Array<ResolversTypes['integration_test_expectation_step']>, "integration_test_expectation_step_stream", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Expectation_Step_StreamArgs, 'batch_size' | 'cursor'>>;
  integration_test_expectation_translation?: SubscriptionResolver<Array<ResolversTypes['integration_test_expectation_translation']>, "integration_test_expectation_translation", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Expectation_TranslationArgs, never>>;
  integration_test_expectation_translation_aggregate?: SubscriptionResolver<ResolversTypes['integration_test_expectation_translation_aggregate'], "integration_test_expectation_translation_aggregate", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Expectation_Translation_AggregateArgs, never>>;
  integration_test_expectation_translation_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['integration_test_expectation_translation']>, "integration_test_expectation_translation_by_pk", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Expectation_Translation_By_PkArgs, 'expectation_id' | 'language'>>;
  integration_test_expectation_translation_stream?: SubscriptionResolver<Array<ResolversTypes['integration_test_expectation_translation']>, "integration_test_expectation_translation_stream", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Expectation_Translation_StreamArgs, 'batch_size' | 'cursor'>>;
  integration_test_expectations?: SubscriptionResolver<Array<ResolversTypes['integration_test_expectations']>, "integration_test_expectations", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_ExpectationsArgs, never>>;
  integration_test_expectations_aggregate?: SubscriptionResolver<ResolversTypes['integration_test_expectations_aggregate'], "integration_test_expectations_aggregate", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Expectations_AggregateArgs, never>>;
  integration_test_expectations_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['integration_test_expectations']>, "integration_test_expectations_by_pk", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Expectations_By_PkArgs, 'id'>>;
  integration_test_expectations_stream?: SubscriptionResolver<Array<ResolversTypes['integration_test_expectations']>, "integration_test_expectations_stream", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Expectations_StreamArgs, 'batch_size' | 'cursor'>>;
  integration_test_instruction_media?: SubscriptionResolver<Array<ResolversTypes['integration_test_instruction_media']>, "integration_test_instruction_media", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Instruction_MediaArgs, never>>;
  integration_test_instruction_media_aggregate?: SubscriptionResolver<ResolversTypes['integration_test_instruction_media_aggregate'], "integration_test_instruction_media_aggregate", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Instruction_Media_AggregateArgs, never>>;
  integration_test_instruction_media_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['integration_test_instruction_media']>, "integration_test_instruction_media_by_pk", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Instruction_Media_By_PkArgs, 'instruction_id' | 'media_id'>>;
  integration_test_instruction_media_stream?: SubscriptionResolver<Array<ResolversTypes['integration_test_instruction_media']>, "integration_test_instruction_media_stream", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Instruction_Media_StreamArgs, 'batch_size' | 'cursor'>>;
  integration_test_instruction_translation?: SubscriptionResolver<Array<ResolversTypes['integration_test_instruction_translation']>, "integration_test_instruction_translation", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Instruction_TranslationArgs, never>>;
  integration_test_instruction_translation_aggregate?: SubscriptionResolver<ResolversTypes['integration_test_instruction_translation_aggregate'], "integration_test_instruction_translation_aggregate", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Instruction_Translation_AggregateArgs, never>>;
  integration_test_instruction_translation_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['integration_test_instruction_translation']>, "integration_test_instruction_translation_by_pk", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Instruction_Translation_By_PkArgs, 'instruction_id' | 'language'>>;
  integration_test_instruction_translation_stream?: SubscriptionResolver<Array<ResolversTypes['integration_test_instruction_translation']>, "integration_test_instruction_translation_stream", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Instruction_Translation_StreamArgs, 'batch_size' | 'cursor'>>;
  integration_test_instructions?: SubscriptionResolver<Array<ResolversTypes['integration_test_instructions']>, "integration_test_instructions", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_InstructionsArgs, never>>;
  integration_test_instructions_aggregate?: SubscriptionResolver<ResolversTypes['integration_test_instructions_aggregate'], "integration_test_instructions_aggregate", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Instructions_AggregateArgs, never>>;
  integration_test_instructions_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['integration_test_instructions']>, "integration_test_instructions_by_pk", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Instructions_By_PkArgs, 'id'>>;
  integration_test_instructions_stream?: SubscriptionResolver<Array<ResolversTypes['integration_test_instructions']>, "integration_test_instructions_stream", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Instructions_StreamArgs, 'batch_size' | 'cursor'>>;
  integration_test_observances?: SubscriptionResolver<Array<ResolversTypes['integration_test_observances']>, "integration_test_observances", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_ObservancesArgs, never>>;
  integration_test_observances_aggregate?: SubscriptionResolver<ResolversTypes['integration_test_observances_aggregate'], "integration_test_observances_aggregate", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Observances_AggregateArgs, never>>;
  integration_test_observances_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['integration_test_observances']>, "integration_test_observances_by_pk", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Observances_By_PkArgs, 'id'>>;
  integration_test_observances_stream?: SubscriptionResolver<Array<ResolversTypes['integration_test_observances']>, "integration_test_observances_stream", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Observances_StreamArgs, 'batch_size' | 'cursor'>>;
  integration_test_observations?: SubscriptionResolver<Array<ResolversTypes['integration_test_observations']>, "integration_test_observations", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_ObservationsArgs, never>>;
  integration_test_observations_aggregate?: SubscriptionResolver<ResolversTypes['integration_test_observations_aggregate'], "integration_test_observations_aggregate", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Observations_AggregateArgs, never>>;
  integration_test_observations_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['integration_test_observations']>, "integration_test_observations_by_pk", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Observations_By_PkArgs, 'expectation_id' | 'observance_id' | 'step_id'>>;
  integration_test_observations_stream?: SubscriptionResolver<Array<ResolversTypes['integration_test_observations']>, "integration_test_observations_stream", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Observations_StreamArgs, 'batch_size' | 'cursor'>>;
  integration_test_project_sequence?: SubscriptionResolver<Array<ResolversTypes['integration_test_project_sequence']>, "integration_test_project_sequence", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Project_SequenceArgs, never>>;
  integration_test_project_sequence_aggregate?: SubscriptionResolver<ResolversTypes['integration_test_project_sequence_aggregate'], "integration_test_project_sequence_aggregate", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Project_Sequence_AggregateArgs, never>>;
  integration_test_project_sequence_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['integration_test_project_sequence']>, "integration_test_project_sequence_by_pk", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Project_Sequence_By_PkArgs, 'project_id' | 'sequence_id'>>;
  integration_test_project_sequence_stream?: SubscriptionResolver<Array<ResolversTypes['integration_test_project_sequence']>, "integration_test_project_sequence_stream", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Project_Sequence_StreamArgs, 'batch_size' | 'cursor'>>;
  integration_test_sequences?: SubscriptionResolver<Array<ResolversTypes['integration_test_sequences']>, "integration_test_sequences", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_SequencesArgs, never>>;
  integration_test_sequences_aggregate?: SubscriptionResolver<ResolversTypes['integration_test_sequences_aggregate'], "integration_test_sequences_aggregate", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Sequences_AggregateArgs, never>>;
  integration_test_sequences_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['integration_test_sequences']>, "integration_test_sequences_by_pk", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Sequences_By_PkArgs, 'id'>>;
  integration_test_sequences_stream?: SubscriptionResolver<Array<ResolversTypes['integration_test_sequences']>, "integration_test_sequences_stream", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Sequences_StreamArgs, 'batch_size' | 'cursor'>>;
  integration_test_steps?: SubscriptionResolver<Array<ResolversTypes['integration_test_steps']>, "integration_test_steps", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_StepsArgs, never>>;
  integration_test_steps_aggregate?: SubscriptionResolver<ResolversTypes['integration_test_steps_aggregate'], "integration_test_steps_aggregate", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Steps_AggregateArgs, never>>;
  integration_test_steps_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['integration_test_steps']>, "integration_test_steps_by_pk", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Steps_By_PkArgs, 'id'>>;
  integration_test_steps_stream?: SubscriptionResolver<Array<ResolversTypes['integration_test_steps']>, "integration_test_steps_stream", ParentType, ContextType, RequireFields<Subscription_RootIntegration_Test_Steps_StreamArgs, 'batch_size' | 'cursor'>>;
  languages?: SubscriptionResolver<Array<ResolversTypes['languages']>, "languages", ParentType, ContextType, RequireFields<Subscription_RootLanguagesArgs, never>>;
  languages_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['languages']>, "languages_by_pk", ParentType, ContextType, RequireFields<Subscription_RootLanguages_By_PkArgs, 'id'>>;
  languages_stream?: SubscriptionResolver<Array<ResolversTypes['languages']>, "languages_stream", ParentType, ContextType, RequireFields<Subscription_RootLanguages_StreamArgs, 'batch_size' | 'cursor'>>;
  machine_learning_blobs?: SubscriptionResolver<Array<ResolversTypes['machine_learning_blobs']>, "machine_learning_blobs", ParentType, ContextType, RequireFields<Subscription_RootMachine_Learning_BlobsArgs, never>>;
  machine_learning_blobs_aggregate?: SubscriptionResolver<ResolversTypes['machine_learning_blobs_aggregate'], "machine_learning_blobs_aggregate", ParentType, ContextType, RequireFields<Subscription_RootMachine_Learning_Blobs_AggregateArgs, never>>;
  machine_learning_blobs_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['machine_learning_blobs']>, "machine_learning_blobs_by_pk", ParentType, ContextType, RequireFields<Subscription_RootMachine_Learning_Blobs_By_PkArgs, 'id'>>;
  machine_learning_blobs_stream?: SubscriptionResolver<Array<ResolversTypes['machine_learning_blobs']>, "machine_learning_blobs_stream", ParentType, ContextType, RequireFields<Subscription_RootMachine_Learning_Blobs_StreamArgs, 'batch_size' | 'cursor'>>;
  machine_learning_dataset_feature?: SubscriptionResolver<Array<ResolversTypes['machine_learning_dataset_feature']>, "machine_learning_dataset_feature", ParentType, ContextType, RequireFields<Subscription_RootMachine_Learning_Dataset_FeatureArgs, never>>;
  machine_learning_dataset_feature_aggregate?: SubscriptionResolver<ResolversTypes['machine_learning_dataset_feature_aggregate'], "machine_learning_dataset_feature_aggregate", ParentType, ContextType, RequireFields<Subscription_RootMachine_Learning_Dataset_Feature_AggregateArgs, never>>;
  machine_learning_dataset_feature_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['machine_learning_dataset_feature']>, "machine_learning_dataset_feature_by_pk", ParentType, ContextType, RequireFields<Subscription_RootMachine_Learning_Dataset_Feature_By_PkArgs, 'dataset_id' | 'feature_id'>>;
  machine_learning_dataset_feature_stream?: SubscriptionResolver<Array<ResolversTypes['machine_learning_dataset_feature']>, "machine_learning_dataset_feature_stream", ParentType, ContextType, RequireFields<Subscription_RootMachine_Learning_Dataset_Feature_StreamArgs, 'batch_size' | 'cursor'>>;
  machine_learning_datasets?: SubscriptionResolver<Array<ResolversTypes['machine_learning_datasets']>, "machine_learning_datasets", ParentType, ContextType, RequireFields<Subscription_RootMachine_Learning_DatasetsArgs, never>>;
  machine_learning_datasets_aggregate?: SubscriptionResolver<ResolversTypes['machine_learning_datasets_aggregate'], "machine_learning_datasets_aggregate", ParentType, ContextType, RequireFields<Subscription_RootMachine_Learning_Datasets_AggregateArgs, never>>;
  machine_learning_datasets_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['machine_learning_datasets']>, "machine_learning_datasets_by_pk", ParentType, ContextType, RequireFields<Subscription_RootMachine_Learning_Datasets_By_PkArgs, 'id'>>;
  machine_learning_datasets_stream?: SubscriptionResolver<Array<ResolversTypes['machine_learning_datasets']>, "machine_learning_datasets_stream", ParentType, ContextType, RequireFields<Subscription_RootMachine_Learning_Datasets_StreamArgs, 'batch_size' | 'cursor'>>;
  machine_learning_features?: SubscriptionResolver<Array<ResolversTypes['machine_learning_features']>, "machine_learning_features", ParentType, ContextType, RequireFields<Subscription_RootMachine_Learning_FeaturesArgs, never>>;
  machine_learning_features_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['machine_learning_features']>, "machine_learning_features_by_pk", ParentType, ContextType, RequireFields<Subscription_RootMachine_Learning_Features_By_PkArgs, 'id'>>;
  machine_learning_features_stream?: SubscriptionResolver<Array<ResolversTypes['machine_learning_features']>, "machine_learning_features_stream", ParentType, ContextType, RequireFields<Subscription_RootMachine_Learning_Features_StreamArgs, 'batch_size' | 'cursor'>>;
  machine_learning_model_blob?: SubscriptionResolver<Array<ResolversTypes['machine_learning_model_blob']>, "machine_learning_model_blob", ParentType, ContextType, RequireFields<Subscription_RootMachine_Learning_Model_BlobArgs, never>>;
  machine_learning_model_blob_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['machine_learning_model_blob']>, "machine_learning_model_blob_by_pk", ParentType, ContextType, RequireFields<Subscription_RootMachine_Learning_Model_Blob_By_PkArgs, 'blob_id' | 'model_id'>>;
  machine_learning_model_blob_stream?: SubscriptionResolver<Array<ResolversTypes['machine_learning_model_blob']>, "machine_learning_model_blob_stream", ParentType, ContextType, RequireFields<Subscription_RootMachine_Learning_Model_Blob_StreamArgs, 'batch_size' | 'cursor'>>;
  machine_learning_model_dataset?: SubscriptionResolver<Array<ResolversTypes['machine_learning_model_dataset']>, "machine_learning_model_dataset", ParentType, ContextType, RequireFields<Subscription_RootMachine_Learning_Model_DatasetArgs, never>>;
  machine_learning_model_dataset_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['machine_learning_model_dataset']>, "machine_learning_model_dataset_by_pk", ParentType, ContextType, RequireFields<Subscription_RootMachine_Learning_Model_Dataset_By_PkArgs, 'dataset_id' | 'model_id'>>;
  machine_learning_model_dataset_stream?: SubscriptionResolver<Array<ResolversTypes['machine_learning_model_dataset']>, "machine_learning_model_dataset_stream", ParentType, ContextType, RequireFields<Subscription_RootMachine_Learning_Model_Dataset_StreamArgs, 'batch_size' | 'cursor'>>;
  machine_learning_model_recording?: SubscriptionResolver<Array<ResolversTypes['machine_learning_model_recording']>, "machine_learning_model_recording", ParentType, ContextType, RequireFields<Subscription_RootMachine_Learning_Model_RecordingArgs, never>>;
  machine_learning_model_recording_aggregate?: SubscriptionResolver<ResolversTypes['machine_learning_model_recording_aggregate'], "machine_learning_model_recording_aggregate", ParentType, ContextType, RequireFields<Subscription_RootMachine_Learning_Model_Recording_AggregateArgs, never>>;
  machine_learning_model_recording_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['machine_learning_model_recording']>, "machine_learning_model_recording_by_pk", ParentType, ContextType, RequireFields<Subscription_RootMachine_Learning_Model_Recording_By_PkArgs, 'model_id' | 'recording_id'>>;
  machine_learning_model_recording_stream?: SubscriptionResolver<Array<ResolversTypes['machine_learning_model_recording']>, "machine_learning_model_recording_stream", ParentType, ContextType, RequireFields<Subscription_RootMachine_Learning_Model_Recording_StreamArgs, 'batch_size' | 'cursor'>>;
  machine_learning_model_types?: SubscriptionResolver<Array<ResolversTypes['machine_learning_model_types']>, "machine_learning_model_types", ParentType, ContextType, RequireFields<Subscription_RootMachine_Learning_Model_TypesArgs, never>>;
  machine_learning_model_types_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['machine_learning_model_types']>, "machine_learning_model_types_by_pk", ParentType, ContextType, RequireFields<Subscription_RootMachine_Learning_Model_Types_By_PkArgs, 'id'>>;
  machine_learning_model_types_stream?: SubscriptionResolver<Array<ResolversTypes['machine_learning_model_types']>, "machine_learning_model_types_stream", ParentType, ContextType, RequireFields<Subscription_RootMachine_Learning_Model_Types_StreamArgs, 'batch_size' | 'cursor'>>;
  machine_learning_models?: SubscriptionResolver<Array<ResolversTypes['machine_learning_models']>, "machine_learning_models", ParentType, ContextType, RequireFields<Subscription_RootMachine_Learning_ModelsArgs, never>>;
  machine_learning_models_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['machine_learning_models']>, "machine_learning_models_by_pk", ParentType, ContextType, RequireFields<Subscription_RootMachine_Learning_Models_By_PkArgs, 'id'>>;
  machine_learning_models_stream?: SubscriptionResolver<Array<ResolversTypes['machine_learning_models']>, "machine_learning_models_stream", ParentType, ContextType, RequireFields<Subscription_RootMachine_Learning_Models_StreamArgs, 'batch_size' | 'cursor'>>;
  media?: SubscriptionResolver<Array<ResolversTypes['media']>, "media", ParentType, ContextType, RequireFields<Subscription_RootMediaArgs, never>>;
  media_aggregate?: SubscriptionResolver<ResolversTypes['media_aggregate'], "media_aggregate", ParentType, ContextType, RequireFields<Subscription_RootMedia_AggregateArgs, never>>;
  media_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['media']>, "media_by_pk", ParentType, ContextType, RequireFields<Subscription_RootMedia_By_PkArgs, 'id'>>;
  media_stream?: SubscriptionResolver<Array<ResolversTypes['media']>, "media_stream", ParentType, ContextType, RequireFields<Subscription_RootMedia_StreamArgs, 'batch_size' | 'cursor'>>;
  media_type?: SubscriptionResolver<Array<ResolversTypes['media_type']>, "media_type", ParentType, ContextType, RequireFields<Subscription_RootMedia_TypeArgs, never>>;
  media_type_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['media_type']>, "media_type_by_pk", ParentType, ContextType, RequireFields<Subscription_RootMedia_Type_By_PkArgs, 'id'>>;
  media_type_stream?: SubscriptionResolver<Array<ResolversTypes['media_type']>, "media_type_stream", ParentType, ContextType, RequireFields<Subscription_RootMedia_Type_StreamArgs, 'batch_size' | 'cursor'>>;
  mode_project?: SubscriptionResolver<Array<ResolversTypes['mode_project']>, "mode_project", ParentType, ContextType, RequireFields<Subscription_RootMode_ProjectArgs, never>>;
  mode_project_aggregate?: SubscriptionResolver<ResolversTypes['mode_project_aggregate'], "mode_project_aggregate", ParentType, ContextType, RequireFields<Subscription_RootMode_Project_AggregateArgs, never>>;
  mode_project_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['mode_project']>, "mode_project_by_pk", ParentType, ContextType, RequireFields<Subscription_RootMode_Project_By_PkArgs, 'mode_id' | 'project_id'>>;
  mode_project_stream?: SubscriptionResolver<Array<ResolversTypes['mode_project']>, "mode_project_stream", ParentType, ContextType, RequireFields<Subscription_RootMode_Project_StreamArgs, 'batch_size' | 'cursor'>>;
  model_name_hashes?: SubscriptionResolver<Array<ResolversTypes['model_name_hashes']>, "model_name_hashes", ParentType, ContextType, RequireFields<Subscription_RootModel_Name_HashesArgs, never>>;
  model_name_hashes_aggregate?: SubscriptionResolver<ResolversTypes['model_name_hashes_aggregate'], "model_name_hashes_aggregate", ParentType, ContextType, RequireFields<Subscription_RootModel_Name_Hashes_AggregateArgs, never>>;
  model_name_hashes_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['model_name_hashes']>, "model_name_hashes_by_pk", ParentType, ContextType, RequireFields<Subscription_RootModel_Name_Hashes_By_PkArgs, 'hash' | 'model_id'>>;
  model_name_hashes_stream?: SubscriptionResolver<Array<ResolversTypes['model_name_hashes']>, "model_name_hashes_stream", ParentType, ContextType, RequireFields<Subscription_RootModel_Name_Hashes_StreamArgs, 'batch_size' | 'cursor'>>;
  models?: SubscriptionResolver<Array<ResolversTypes['models']>, "models", ParentType, ContextType, RequireFields<Subscription_RootModelsArgs, never>>;
  models_aggregate?: SubscriptionResolver<ResolversTypes['models_aggregate'], "models_aggregate", ParentType, ContextType, RequireFields<Subscription_RootModels_AggregateArgs, never>>;
  models_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['models']>, "models_by_pk", ParentType, ContextType, RequireFields<Subscription_RootModels_By_PkArgs, 'id'>>;
  models_stream?: SubscriptionResolver<Array<ResolversTypes['models']>, "models_stream", ParentType, ContextType, RequireFields<Subscription_RootModels_StreamArgs, 'batch_size' | 'cursor'>>;
  opmode_profile?: SubscriptionResolver<Array<ResolversTypes['opmode_profile']>, "opmode_profile", ParentType, ContextType, RequireFields<Subscription_RootOpmode_ProfileArgs, never>>;
  opmode_profile_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['opmode_profile']>, "opmode_profile_by_pk", ParentType, ContextType, RequireFields<Subscription_RootOpmode_Profile_By_PkArgs, 'opmode_id' | 'profile_id'>>;
  opmode_profile_stream?: SubscriptionResolver<Array<ResolversTypes['opmode_profile']>, "opmode_profile_stream", ParentType, ContextType, RequireFields<Subscription_RootOpmode_Profile_StreamArgs, 'batch_size' | 'cursor'>>;
  opmode_project?: SubscriptionResolver<Array<ResolversTypes['opmode_project']>, "opmode_project", ParentType, ContextType, RequireFields<Subscription_RootOpmode_ProjectArgs, never>>;
  opmode_project_aggregate?: SubscriptionResolver<ResolversTypes['opmode_project_aggregate'], "opmode_project_aggregate", ParentType, ContextType, RequireFields<Subscription_RootOpmode_Project_AggregateArgs, never>>;
  opmode_project_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['opmode_project']>, "opmode_project_by_pk", ParentType, ContextType, RequireFields<Subscription_RootOpmode_Project_By_PkArgs, 'opmode_id' | 'project_id'>>;
  opmode_project_stream?: SubscriptionResolver<Array<ResolversTypes['opmode_project']>, "opmode_project_stream", ParentType, ContextType, RequireFields<Subscription_RootOpmode_Project_StreamArgs, 'batch_size' | 'cursor'>>;
  opmodes?: SubscriptionResolver<Array<ResolversTypes['opmodes']>, "opmodes", ParentType, ContextType, RequireFields<Subscription_RootOpmodesArgs, never>>;
  opmodes_aggregate?: SubscriptionResolver<ResolversTypes['opmodes_aggregate'], "opmodes_aggregate", ParentType, ContextType, RequireFields<Subscription_RootOpmodes_AggregateArgs, never>>;
  opmodes_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['opmodes']>, "opmodes_by_pk", ParentType, ContextType, RequireFields<Subscription_RootOpmodes_By_PkArgs, 'id'>>;
  opmodes_stream?: SubscriptionResolver<Array<ResolversTypes['opmodes']>, "opmodes_stream", ParentType, ContextType, RequireFields<Subscription_RootOpmodes_StreamArgs, 'batch_size' | 'cursor'>>;
  option_media?: SubscriptionResolver<Array<ResolversTypes['option_media']>, "option_media", ParentType, ContextType, RequireFields<Subscription_RootOption_MediaArgs, never>>;
  option_media_aggregate?: SubscriptionResolver<ResolversTypes['option_media_aggregate'], "option_media_aggregate", ParentType, ContextType, RequireFields<Subscription_RootOption_Media_AggregateArgs, never>>;
  option_media_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['option_media']>, "option_media_by_pk", ParentType, ContextType, RequireFields<Subscription_RootOption_Media_By_PkArgs, 'media_id' | 'option_id'>>;
  option_media_stream?: SubscriptionResolver<Array<ResolversTypes['option_media']>, "option_media_stream", ParentType, ContextType, RequireFields<Subscription_RootOption_Media_StreamArgs, 'batch_size' | 'cursor'>>;
  option_translation?: SubscriptionResolver<Array<ResolversTypes['option_translation']>, "option_translation", ParentType, ContextType, RequireFields<Subscription_RootOption_TranslationArgs, never>>;
  option_translation_aggregate?: SubscriptionResolver<ResolversTypes['option_translation_aggregate'], "option_translation_aggregate", ParentType, ContextType, RequireFields<Subscription_RootOption_Translation_AggregateArgs, never>>;
  option_translation_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['option_translation']>, "option_translation_by_pk", ParentType, ContextType, RequireFields<Subscription_RootOption_Translation_By_PkArgs, 'language' | 'option_id'>>;
  option_translation_stream?: SubscriptionResolver<Array<ResolversTypes['option_translation']>, "option_translation_stream", ParentType, ContextType, RequireFields<Subscription_RootOption_Translation_StreamArgs, 'batch_size' | 'cursor'>>;
  options?: SubscriptionResolver<Array<ResolversTypes['options']>, "options", ParentType, ContextType, RequireFields<Subscription_RootOptionsArgs, never>>;
  options_aggregate?: SubscriptionResolver<ResolversTypes['options_aggregate'], "options_aggregate", ParentType, ContextType, RequireFields<Subscription_RootOptions_AggregateArgs, never>>;
  options_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['options']>, "options_by_pk", ParentType, ContextType, RequireFields<Subscription_RootOptions_By_PkArgs, 'id'>>;
  options_stream?: SubscriptionResolver<Array<ResolversTypes['options']>, "options_stream", ParentType, ContextType, RequireFields<Subscription_RootOptions_StreamArgs, 'batch_size' | 'cursor'>>;
  payload_recording?: SubscriptionResolver<Array<ResolversTypes['payload_recording']>, "payload_recording", ParentType, ContextType, RequireFields<Subscription_RootPayload_RecordingArgs, never>>;
  payload_recording_aggregate?: SubscriptionResolver<ResolversTypes['payload_recording_aggregate'], "payload_recording_aggregate", ParentType, ContextType, RequireFields<Subscription_RootPayload_Recording_AggregateArgs, never>>;
  payload_recording_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['payload_recording']>, "payload_recording_by_pk", ParentType, ContextType, RequireFields<Subscription_RootPayload_Recording_By_PkArgs, 'payload_id' | 'recording_id'>>;
  payload_recording_stream?: SubscriptionResolver<Array<ResolversTypes['payload_recording']>, "payload_recording_stream", ParentType, ContextType, RequireFields<Subscription_RootPayload_Recording_StreamArgs, 'batch_size' | 'cursor'>>;
  payload_types?: SubscriptionResolver<Array<ResolversTypes['payload_types']>, "payload_types", ParentType, ContextType, RequireFields<Subscription_RootPayload_TypesArgs, never>>;
  payload_types_aggregate?: SubscriptionResolver<ResolversTypes['payload_types_aggregate'], "payload_types_aggregate", ParentType, ContextType, RequireFields<Subscription_RootPayload_Types_AggregateArgs, never>>;
  payload_types_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['payload_types']>, "payload_types_by_pk", ParentType, ContextType, RequireFields<Subscription_RootPayload_Types_By_PkArgs, 'id'>>;
  payload_types_stream?: SubscriptionResolver<Array<ResolversTypes['payload_types']>, "payload_types_stream", ParentType, ContextType, RequireFields<Subscription_RootPayload_Types_StreamArgs, 'batch_size' | 'cursor'>>;
  payloads?: SubscriptionResolver<Array<ResolversTypes['payloads']>, "payloads", ParentType, ContextType, RequireFields<Subscription_RootPayloadsArgs, never>>;
  payloads_aggregate?: SubscriptionResolver<ResolversTypes['payloads_aggregate'], "payloads_aggregate", ParentType, ContextType, RequireFields<Subscription_RootPayloads_AggregateArgs, never>>;
  payloads_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['payloads']>, "payloads_by_pk", ParentType, ContextType, RequireFields<Subscription_RootPayloads_By_PkArgs, 'id'>>;
  payloads_stream?: SubscriptionResolver<Array<ResolversTypes['payloads']>, "payloads_stream", ParentType, ContextType, RequireFields<Subscription_RootPayloads_StreamArgs, 'batch_size' | 'cursor'>>;
  performance_ratio_metric_contributions?: SubscriptionResolver<Array<ResolversTypes['performance_ratio_metric_contributions']>, "performance_ratio_metric_contributions", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Ratio_Metric_ContributionsArgs, never>>;
  performance_ratio_metric_contributions_aggregate?: SubscriptionResolver<ResolversTypes['performance_ratio_metric_contributions_aggregate'], "performance_ratio_metric_contributions_aggregate", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Ratio_Metric_Contributions_AggregateArgs, never>>;
  performance_ratio_metric_contributions_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['performance_ratio_metric_contributions']>, "performance_ratio_metric_contributions_by_pk", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Ratio_Metric_Contributions_By_PkArgs, 'engine_id' | 'ratio_metric_id' | 'recording_id'>>;
  performance_ratio_metric_contributions_stream?: SubscriptionResolver<Array<ResolversTypes['performance_ratio_metric_contributions']>, "performance_ratio_metric_contributions_stream", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Ratio_Metric_Contributions_StreamArgs, 'batch_size' | 'cursor'>>;
  performance_ratio_metrics?: SubscriptionResolver<Array<ResolversTypes['performance_ratio_metrics']>, "performance_ratio_metrics", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Ratio_MetricsArgs, never>>;
  performance_ratio_metrics_aggregate?: SubscriptionResolver<ResolversTypes['performance_ratio_metrics_aggregate'], "performance_ratio_metrics_aggregate", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Ratio_Metrics_AggregateArgs, never>>;
  performance_ratio_metrics_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['performance_ratio_metrics']>, "performance_ratio_metrics_by_pk", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Ratio_Metrics_By_PkArgs, 'id'>>;
  performance_ratio_metrics_stream?: SubscriptionResolver<Array<ResolversTypes['performance_ratio_metrics']>, "performance_ratio_metrics_stream", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Ratio_Metrics_StreamArgs, 'batch_size' | 'cursor'>>;
  performance_report_deliverable_template?: SubscriptionResolver<Array<ResolversTypes['performance_report_deliverable_template']>, "performance_report_deliverable_template", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Report_Deliverable_TemplateArgs, never>>;
  performance_report_deliverable_template_aggregate?: SubscriptionResolver<ResolversTypes['performance_report_deliverable_template_aggregate'], "performance_report_deliverable_template_aggregate", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Report_Deliverable_Template_AggregateArgs, never>>;
  performance_report_deliverable_template_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['performance_report_deliverable_template']>, "performance_report_deliverable_template_by_pk", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Report_Deliverable_Template_By_PkArgs, 'deliverable_id' | 'template_id'>>;
  performance_report_deliverable_template_stream?: SubscriptionResolver<Array<ResolversTypes['performance_report_deliverable_template']>, "performance_report_deliverable_template_stream", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Report_Deliverable_Template_StreamArgs, 'batch_size' | 'cursor'>>;
  performance_report_pivot_types?: SubscriptionResolver<Array<ResolversTypes['performance_report_pivot_types']>, "performance_report_pivot_types", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Report_Pivot_TypesArgs, never>>;
  performance_report_pivot_types_aggregate?: SubscriptionResolver<ResolversTypes['performance_report_pivot_types_aggregate'], "performance_report_pivot_types_aggregate", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Report_Pivot_Types_AggregateArgs, never>>;
  performance_report_pivot_types_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['performance_report_pivot_types']>, "performance_report_pivot_types_by_pk", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Report_Pivot_Types_By_PkArgs, 'id'>>;
  performance_report_pivot_types_stream?: SubscriptionResolver<Array<ResolversTypes['performance_report_pivot_types']>, "performance_report_pivot_types_stream", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Report_Pivot_Types_StreamArgs, 'batch_size' | 'cursor'>>;
  performance_report_section_pivot_field?: SubscriptionResolver<Array<ResolversTypes['performance_report_section_pivot_field']>, "performance_report_section_pivot_field", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Report_Section_Pivot_FieldArgs, never>>;
  performance_report_section_pivot_field_aggregate?: SubscriptionResolver<ResolversTypes['performance_report_section_pivot_field_aggregate'], "performance_report_section_pivot_field_aggregate", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Report_Section_Pivot_Field_AggregateArgs, never>>;
  performance_report_section_pivot_field_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['performance_report_section_pivot_field']>, "performance_report_section_pivot_field_by_pk", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Report_Section_Pivot_Field_By_PkArgs, 'field_id' | 'section_id'>>;
  performance_report_section_pivot_field_stream?: SubscriptionResolver<Array<ResolversTypes['performance_report_section_pivot_field']>, "performance_report_section_pivot_field_stream", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Report_Section_Pivot_Field_StreamArgs, 'batch_size' | 'cursor'>>;
  performance_report_section_ratio_metric?: SubscriptionResolver<Array<ResolversTypes['performance_report_section_ratio_metric']>, "performance_report_section_ratio_metric", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Report_Section_Ratio_MetricArgs, never>>;
  performance_report_section_ratio_metric_aggregate?: SubscriptionResolver<ResolversTypes['performance_report_section_ratio_metric_aggregate'], "performance_report_section_ratio_metric_aggregate", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Report_Section_Ratio_Metric_AggregateArgs, never>>;
  performance_report_section_ratio_metric_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['performance_report_section_ratio_metric']>, "performance_report_section_ratio_metric_by_pk", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Report_Section_Ratio_Metric_By_PkArgs, 'ratio_metric_id' | 'section_id'>>;
  performance_report_section_ratio_metric_stream?: SubscriptionResolver<Array<ResolversTypes['performance_report_section_ratio_metric']>, "performance_report_section_ratio_metric_stream", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Report_Section_Ratio_Metric_StreamArgs, 'batch_size' | 'cursor'>>;
  performance_report_section_task_blacklist?: SubscriptionResolver<Array<ResolversTypes['performance_report_section_task_blacklist']>, "performance_report_section_task_blacklist", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Report_Section_Task_BlacklistArgs, never>>;
  performance_report_section_task_blacklist_aggregate?: SubscriptionResolver<ResolversTypes['performance_report_section_task_blacklist_aggregate'], "performance_report_section_task_blacklist_aggregate", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Report_Section_Task_Blacklist_AggregateArgs, never>>;
  performance_report_section_task_blacklist_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['performance_report_section_task_blacklist']>, "performance_report_section_task_blacklist_by_pk", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Report_Section_Task_Blacklist_By_PkArgs, 'section_id' | 'task_id'>>;
  performance_report_section_task_blacklist_stream?: SubscriptionResolver<Array<ResolversTypes['performance_report_section_task_blacklist']>, "performance_report_section_task_blacklist_stream", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Report_Section_Task_Blacklist_StreamArgs, 'batch_size' | 'cursor'>>;
  performance_report_sections?: SubscriptionResolver<Array<ResolversTypes['performance_report_sections']>, "performance_report_sections", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Report_SectionsArgs, never>>;
  performance_report_sections_aggregate?: SubscriptionResolver<ResolversTypes['performance_report_sections_aggregate'], "performance_report_sections_aggregate", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Report_Sections_AggregateArgs, never>>;
  performance_report_sections_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['performance_report_sections']>, "performance_report_sections_by_pk", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Report_Sections_By_PkArgs, 'id'>>;
  performance_report_sections_stream?: SubscriptionResolver<Array<ResolversTypes['performance_report_sections']>, "performance_report_sections_stream", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Report_Sections_StreamArgs, 'batch_size' | 'cursor'>>;
  performance_report_templates?: SubscriptionResolver<Array<ResolversTypes['performance_report_templates']>, "performance_report_templates", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Report_TemplatesArgs, never>>;
  performance_report_templates_aggregate?: SubscriptionResolver<ResolversTypes['performance_report_templates_aggregate'], "performance_report_templates_aggregate", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Report_Templates_AggregateArgs, never>>;
  performance_report_templates_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['performance_report_templates']>, "performance_report_templates_by_pk", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Report_Templates_By_PkArgs, 'id'>>;
  performance_report_templates_stream?: SubscriptionResolver<Array<ResolversTypes['performance_report_templates']>, "performance_report_templates_stream", ParentType, ContextType, RequireFields<Subscription_RootPerformance_Report_Templates_StreamArgs, 'batch_size' | 'cursor'>>;
  peripherals?: SubscriptionResolver<Array<ResolversTypes['peripherals']>, "peripherals", ParentType, ContextType, RequireFields<Subscription_RootPeripheralsArgs, never>>;
  peripherals_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['peripherals']>, "peripherals_by_pk", ParentType, ContextType, RequireFields<Subscription_RootPeripherals_By_PkArgs, 'id'>>;
  peripherals_stream?: SubscriptionResolver<Array<ResolversTypes['peripherals']>, "peripherals_stream", ParentType, ContextType, RequireFields<Subscription_RootPeripherals_StreamArgs, 'batch_size' | 'cursor'>>;
  plan_hypertasks?: SubscriptionResolver<Array<ResolversTypes['plan_hypertasks']>, "plan_hypertasks", ParentType, ContextType, RequireFields<Subscription_RootPlan_HypertasksArgs, never>>;
  plan_hypertasks_aggregate?: SubscriptionResolver<ResolversTypes['plan_hypertasks_aggregate'], "plan_hypertasks_aggregate", ParentType, ContextType, RequireFields<Subscription_RootPlan_Hypertasks_AggregateArgs, never>>;
  plan_hypertasks_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['plan_hypertasks']>, "plan_hypertasks_by_pk", ParentType, ContextType, RequireFields<Subscription_RootPlan_Hypertasks_By_PkArgs, 'hypertask_id' | 'plan_id'>>;
  plan_hypertasks_stream?: SubscriptionResolver<Array<ResolversTypes['plan_hypertasks']>, "plan_hypertasks_stream", ParentType, ContextType, RequireFields<Subscription_RootPlan_Hypertasks_StreamArgs, 'batch_size' | 'cursor'>>;
  plan_tasks?: SubscriptionResolver<Array<ResolversTypes['plan_tasks']>, "plan_tasks", ParentType, ContextType, RequireFields<Subscription_RootPlan_TasksArgs, never>>;
  plan_tasks_aggregate?: SubscriptionResolver<ResolversTypes['plan_tasks_aggregate'], "plan_tasks_aggregate", ParentType, ContextType, RequireFields<Subscription_RootPlan_Tasks_AggregateArgs, never>>;
  plan_tasks_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['plan_tasks']>, "plan_tasks_by_pk", ParentType, ContextType, RequireFields<Subscription_RootPlan_Tasks_By_PkArgs, 'id'>>;
  plan_tasks_stream?: SubscriptionResolver<Array<ResolversTypes['plan_tasks']>, "plan_tasks_stream", ParentType, ContextType, RequireFields<Subscription_RootPlan_Tasks_StreamArgs, 'batch_size' | 'cursor'>>;
  plans?: SubscriptionResolver<Array<ResolversTypes['plans']>, "plans", ParentType, ContextType, RequireFields<Subscription_RootPlansArgs, never>>;
  plans_aggregate?: SubscriptionResolver<ResolversTypes['plans_aggregate'], "plans_aggregate", ParentType, ContextType, RequireFields<Subscription_RootPlans_AggregateArgs, never>>;
  plans_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['plans']>, "plans_by_pk", ParentType, ContextType, RequireFields<Subscription_RootPlans_By_PkArgs, 'id'>>;
  plans_stream?: SubscriptionResolver<Array<ResolversTypes['plans']>, "plans_stream", ParentType, ContextType, RequireFields<Subscription_RootPlans_StreamArgs, 'batch_size' | 'cursor'>>;
  profiles?: SubscriptionResolver<Array<ResolversTypes['profiles']>, "profiles", ParentType, ContextType, RequireFields<Subscription_RootProfilesArgs, never>>;
  profiles_aggregate?: SubscriptionResolver<ResolversTypes['profiles_aggregate'], "profiles_aggregate", ParentType, ContextType, RequireFields<Subscription_RootProfiles_AggregateArgs, never>>;
  profiles_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['profiles']>, "profiles_by_pk", ParentType, ContextType, RequireFields<Subscription_RootProfiles_By_PkArgs, 'id'>>;
  profiles_stream?: SubscriptionResolver<Array<ResolversTypes['profiles']>, "profiles_stream", ParentType, ContextType, RequireFields<Subscription_RootProfiles_StreamArgs, 'batch_size' | 'cursor'>>;
  project_combo?: SubscriptionResolver<Array<ResolversTypes['project_combo']>, "project_combo", ParentType, ContextType, RequireFields<Subscription_RootProject_ComboArgs, never>>;
  project_combo_aggregate?: SubscriptionResolver<ResolversTypes['project_combo_aggregate'], "project_combo_aggregate", ParentType, ContextType, RequireFields<Subscription_RootProject_Combo_AggregateArgs, never>>;
  project_combo_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['project_combo']>, "project_combo_by_pk", ParentType, ContextType, RequireFields<Subscription_RootProject_Combo_By_PkArgs, 'other_project_id' | 'project_id'>>;
  project_combo_stream?: SubscriptionResolver<Array<ResolversTypes['project_combo']>, "project_combo_stream", ParentType, ContextType, RequireFields<Subscription_RootProject_Combo_StreamArgs, 'batch_size' | 'cursor'>>;
  project_dataset?: SubscriptionResolver<Array<ResolversTypes['project_dataset']>, "project_dataset", ParentType, ContextType, RequireFields<Subscription_RootProject_DatasetArgs, never>>;
  project_dataset_aggregate?: SubscriptionResolver<ResolversTypes['project_dataset_aggregate'], "project_dataset_aggregate", ParentType, ContextType, RequireFields<Subscription_RootProject_Dataset_AggregateArgs, never>>;
  project_dataset_stream?: SubscriptionResolver<Array<ResolversTypes['project_dataset']>, "project_dataset_stream", ParentType, ContextType, RequireFields<Subscription_RootProject_Dataset_StreamArgs, 'batch_size' | 'cursor'>>;
  project_engine_metrics?: SubscriptionResolver<Array<ResolversTypes['schema_project_engine_metrics']>, "project_engine_metrics", ParentType, ContextType, RequireFields<Subscription_RootProject_Engine_MetricsArgs, 'args'>>;
  project_engine_ratio_metrics?: SubscriptionResolver<Array<ResolversTypes['schema_project_engine_ratio_metrics']>, "project_engine_ratio_metrics", ParentType, ContextType, RequireFields<Subscription_RootProject_Engine_Ratio_MetricsArgs, 'args'>>;
  project_engine_ratio_metrics_aggregate?: SubscriptionResolver<ResolversTypes['schema_project_engine_ratio_metrics_aggregate'], "project_engine_ratio_metrics_aggregate", ParentType, ContextType, RequireFields<Subscription_RootProject_Engine_Ratio_Metrics_AggregateArgs, 'args'>>;
  project_fields?: SubscriptionResolver<Array<ResolversTypes['fields']>, "project_fields", ParentType, ContextType, RequireFields<Subscription_RootProject_FieldsArgs, 'args'>>;
  project_fields_aggregate?: SubscriptionResolver<ResolversTypes['fields_aggregate'], "project_fields_aggregate", ParentType, ContextType, RequireFields<Subscription_RootProject_Fields_AggregateArgs, 'args'>>;
  project_hypertasks?: SubscriptionResolver<Array<ResolversTypes['project_hypertasks']>, "project_hypertasks", ParentType, ContextType, RequireFields<Subscription_RootProject_HypertasksArgs, never>>;
  project_hypertasks_aggregate?: SubscriptionResolver<ResolversTypes['project_hypertasks_aggregate'], "project_hypertasks_aggregate", ParentType, ContextType, RequireFields<Subscription_RootProject_Hypertasks_AggregateArgs, never>>;
  project_hypertasks_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['project_hypertasks']>, "project_hypertasks_by_pk", ParentType, ContextType, RequireFields<Subscription_RootProject_Hypertasks_By_PkArgs, 'hypertask_id' | 'project_id'>>;
  project_hypertasks_stream?: SubscriptionResolver<Array<ResolversTypes['project_hypertasks']>, "project_hypertasks_stream", ParentType, ContextType, RequireFields<Subscription_RootProject_Hypertasks_StreamArgs, 'batch_size' | 'cursor'>>;
  project_metrics?: SubscriptionResolver<Array<ResolversTypes['project_metrics']>, "project_metrics", ParentType, ContextType, RequireFields<Subscription_RootProject_MetricsArgs, never>>;
  project_metrics_aggregate?: SubscriptionResolver<ResolversTypes['project_metrics_aggregate'], "project_metrics_aggregate", ParentType, ContextType, RequireFields<Subscription_RootProject_Metrics_AggregateArgs, never>>;
  project_metrics_stream?: SubscriptionResolver<Array<ResolversTypes['project_metrics']>, "project_metrics_stream", ParentType, ContextType, RequireFields<Subscription_RootProject_Metrics_StreamArgs, 'batch_size' | 'cursor'>>;
  project_name?: SubscriptionResolver<Array<ResolversTypes['project_name']>, "project_name", ParentType, ContextType, RequireFields<Subscription_RootProject_NameArgs, never>>;
  project_name_stream?: SubscriptionResolver<Array<ResolversTypes['project_name']>, "project_name_stream", ParentType, ContextType, RequireFields<Subscription_RootProject_Name_StreamArgs, 'batch_size' | 'cursor'>>;
  project_rabbit_test_instances?: SubscriptionResolver<Array<ResolversTypes['project_rabbit_test_instances']>, "project_rabbit_test_instances", ParentType, ContextType, RequireFields<Subscription_RootProject_Rabbit_Test_InstancesArgs, never>>;
  project_rabbit_test_instances_aggregate?: SubscriptionResolver<ResolversTypes['project_rabbit_test_instances_aggregate'], "project_rabbit_test_instances_aggregate", ParentType, ContextType, RequireFields<Subscription_RootProject_Rabbit_Test_Instances_AggregateArgs, never>>;
  project_rabbit_test_instances_stream?: SubscriptionResolver<Array<ResolversTypes['project_rabbit_test_instances']>, "project_rabbit_test_instances_stream", ParentType, ContextType, RequireFields<Subscription_RootProject_Rabbit_Test_Instances_StreamArgs, 'batch_size' | 'cursor'>>;
  project_recordings_clean?: SubscriptionResolver<Array<ResolversTypes['recordings']>, "project_recordings_clean", ParentType, ContextType, RequireFields<Subscription_RootProject_Recordings_CleanArgs, 'args'>>;
  project_recordings_clean_aggregate?: SubscriptionResolver<ResolversTypes['recordings_aggregate'], "project_recordings_clean_aggregate", ParentType, ContextType, RequireFields<Subscription_RootProject_Recordings_Clean_AggregateArgs, 'args'>>;
  project_recordings_unreviewed?: SubscriptionResolver<Array<ResolversTypes['recordings']>, "project_recordings_unreviewed", ParentType, ContextType, RequireFields<Subscription_RootProject_Recordings_UnreviewedArgs, 'args'>>;
  project_recordings_unreviewed_aggregate?: SubscriptionResolver<ResolversTypes['recordings_aggregate'], "project_recordings_unreviewed_aggregate", ParentType, ContextType, RequireFields<Subscription_RootProject_Recordings_Unreviewed_AggregateArgs, 'args'>>;
  project_task_count?: SubscriptionResolver<Array<ResolversTypes['schema_project_task_count']>, "project_task_count", ParentType, ContextType, RequireFields<Subscription_RootProject_Task_CountArgs, 'args'>>;
  project_task_count_aggregate?: SubscriptionResolver<ResolversTypes['schema_project_task_count_aggregate'], "project_task_count_aggregate", ParentType, ContextType, RequireFields<Subscription_RootProject_Task_Count_AggregateArgs, 'args'>>;
  project_task_count_by_device?: SubscriptionResolver<Array<ResolversTypes['schema_project_task_count_by_device']>, "project_task_count_by_device", ParentType, ContextType, RequireFields<Subscription_RootProject_Task_Count_By_DeviceArgs, 'args'>>;
  project_task_count_by_device_aggregate?: SubscriptionResolver<ResolversTypes['schema_project_task_count_by_device_aggregate'], "project_task_count_by_device_aggregate", ParentType, ContextType, RequireFields<Subscription_RootProject_Task_Count_By_Device_AggregateArgs, 'args'>>;
  project_tasks?: SubscriptionResolver<Array<ResolversTypes['project_tasks']>, "project_tasks", ParentType, ContextType, RequireFields<Subscription_RootProject_TasksArgs, never>>;
  project_tasks_aggregate?: SubscriptionResolver<ResolversTypes['project_tasks_aggregate'], "project_tasks_aggregate", ParentType, ContextType, RequireFields<Subscription_RootProject_Tasks_AggregateArgs, never>>;
  project_tasks_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['project_tasks']>, "project_tasks_by_pk", ParentType, ContextType, RequireFields<Subscription_RootProject_Tasks_By_PkArgs, 'id'>>;
  project_tasks_stream?: SubscriptionResolver<Array<ResolversTypes['project_tasks']>, "project_tasks_stream", ParentType, ContextType, RequireFields<Subscription_RootProject_Tasks_StreamArgs, 'batch_size' | 'cursor'>>;
  project_user?: SubscriptionResolver<Array<ResolversTypes['project_user']>, "project_user", ParentType, ContextType, RequireFields<Subscription_RootProject_UserArgs, never>>;
  project_user_aggregate?: SubscriptionResolver<ResolversTypes['project_user_aggregate'], "project_user_aggregate", ParentType, ContextType, RequireFields<Subscription_RootProject_User_AggregateArgs, never>>;
  project_user_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['project_user']>, "project_user_by_pk", ParentType, ContextType, RequireFields<Subscription_RootProject_User_By_PkArgs, 'project_id' | 'user_id'>>;
  project_user_stream?: SubscriptionResolver<Array<ResolversTypes['project_user']>, "project_user_stream", ParentType, ContextType, RequireFields<Subscription_RootProject_User_StreamArgs, 'batch_size' | 'cursor'>>;
  projects?: SubscriptionResolver<Array<ResolversTypes['projects']>, "projects", ParentType, ContextType, RequireFields<Subscription_RootProjectsArgs, never>>;
  projects_aggregate?: SubscriptionResolver<ResolversTypes['projects_aggregate'], "projects_aggregate", ParentType, ContextType, RequireFields<Subscription_RootProjects_AggregateArgs, never>>;
  projects_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['projects']>, "projects_by_pk", ParentType, ContextType, RequireFields<Subscription_RootProjects_By_PkArgs, 'id'>>;
  projects_stream?: SubscriptionResolver<Array<ResolversTypes['projects']>, "projects_stream", ParentType, ContextType, RequireFields<Subscription_RootProjects_StreamArgs, 'batch_size' | 'cursor'>>;
  rabbit_test_plan_project?: SubscriptionResolver<Array<ResolversTypes['rabbit_test_plan_project']>, "rabbit_test_plan_project", ParentType, ContextType, RequireFields<Subscription_RootRabbit_Test_Plan_ProjectArgs, never>>;
  rabbit_test_plan_project_aggregate?: SubscriptionResolver<ResolversTypes['rabbit_test_plan_project_aggregate'], "rabbit_test_plan_project_aggregate", ParentType, ContextType, RequireFields<Subscription_RootRabbit_Test_Plan_Project_AggregateArgs, never>>;
  rabbit_test_plan_project_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['rabbit_test_plan_project']>, "rabbit_test_plan_project_by_pk", ParentType, ContextType, RequireFields<Subscription_RootRabbit_Test_Plan_Project_By_PkArgs, 'plan_id' | 'project_id'>>;
  rabbit_test_plan_project_stream?: SubscriptionResolver<Array<ResolversTypes['rabbit_test_plan_project']>, "rabbit_test_plan_project_stream", ParentType, ContextType, RequireFields<Subscription_RootRabbit_Test_Plan_Project_StreamArgs, 'batch_size' | 'cursor'>>;
  rabbit_test_plan_recording?: SubscriptionResolver<Array<ResolversTypes['rabbit_test_plan_recording']>, "rabbit_test_plan_recording", ParentType, ContextType, RequireFields<Subscription_RootRabbit_Test_Plan_RecordingArgs, never>>;
  rabbit_test_plan_recording_aggregate?: SubscriptionResolver<ResolversTypes['rabbit_test_plan_recording_aggregate'], "rabbit_test_plan_recording_aggregate", ParentType, ContextType, RequireFields<Subscription_RootRabbit_Test_Plan_Recording_AggregateArgs, never>>;
  rabbit_test_plan_recording_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['rabbit_test_plan_recording']>, "rabbit_test_plan_recording_by_pk", ParentType, ContextType, RequireFields<Subscription_RootRabbit_Test_Plan_Recording_By_PkArgs, 'recording_id'>>;
  rabbit_test_plan_recording_stream?: SubscriptionResolver<Array<ResolversTypes['rabbit_test_plan_recording']>, "rabbit_test_plan_recording_stream", ParentType, ContextType, RequireFields<Subscription_RootRabbit_Test_Plan_Recording_StreamArgs, 'batch_size' | 'cursor'>>;
  rabbit_test_plan_task?: SubscriptionResolver<Array<ResolversTypes['rabbit_test_plan_task']>, "rabbit_test_plan_task", ParentType, ContextType, RequireFields<Subscription_RootRabbit_Test_Plan_TaskArgs, never>>;
  rabbit_test_plan_task_aggregate?: SubscriptionResolver<ResolversTypes['rabbit_test_plan_task_aggregate'], "rabbit_test_plan_task_aggregate", ParentType, ContextType, RequireFields<Subscription_RootRabbit_Test_Plan_Task_AggregateArgs, never>>;
  rabbit_test_plan_task_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['rabbit_test_plan_task']>, "rabbit_test_plan_task_by_pk", ParentType, ContextType, RequireFields<Subscription_RootRabbit_Test_Plan_Task_By_PkArgs, 'plan_id' | 'task_id'>>;
  rabbit_test_plan_task_stream?: SubscriptionResolver<Array<ResolversTypes['rabbit_test_plan_task']>, "rabbit_test_plan_task_stream", ParentType, ContextType, RequireFields<Subscription_RootRabbit_Test_Plan_Task_StreamArgs, 'batch_size' | 'cursor'>>;
  rabbit_test_plans?: SubscriptionResolver<Array<ResolversTypes['rabbit_test_plans']>, "rabbit_test_plans", ParentType, ContextType, RequireFields<Subscription_RootRabbit_Test_PlansArgs, never>>;
  rabbit_test_plans_aggregate?: SubscriptionResolver<ResolversTypes['rabbit_test_plans_aggregate'], "rabbit_test_plans_aggregate", ParentType, ContextType, RequireFields<Subscription_RootRabbit_Test_Plans_AggregateArgs, never>>;
  rabbit_test_plans_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['rabbit_test_plans']>, "rabbit_test_plans_by_pk", ParentType, ContextType, RequireFields<Subscription_RootRabbit_Test_Plans_By_PkArgs, 'id'>>;
  rabbit_test_plans_stream?: SubscriptionResolver<Array<ResolversTypes['rabbit_test_plans']>, "rabbit_test_plans_stream", ParentType, ContextType, RequireFields<Subscription_RootRabbit_Test_Plans_StreamArgs, 'batch_size' | 'cursor'>>;
  recording_apps?: SubscriptionResolver<Array<ResolversTypes['recording_apps']>, "recording_apps", ParentType, ContextType, RequireFields<Subscription_RootRecording_AppsArgs, never>>;
  recording_apps_aggregate?: SubscriptionResolver<ResolversTypes['recording_apps_aggregate'], "recording_apps_aggregate", ParentType, ContextType, RequireFields<Subscription_RootRecording_Apps_AggregateArgs, never>>;
  recording_apps_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['recording_apps']>, "recording_apps_by_pk", ParentType, ContextType, RequireFields<Subscription_RootRecording_Apps_By_PkArgs, 'id'>>;
  recording_apps_stream?: SubscriptionResolver<Array<ResolversTypes['recording_apps']>, "recording_apps_stream", ParentType, ContextType, RequireFields<Subscription_RootRecording_Apps_StreamArgs, 'batch_size' | 'cursor'>>;
  recording_audio_driver?: SubscriptionResolver<Array<ResolversTypes['recording_audio_driver']>, "recording_audio_driver", ParentType, ContextType, RequireFields<Subscription_RootRecording_Audio_DriverArgs, never>>;
  recording_audio_driver_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['recording_audio_driver']>, "recording_audio_driver_by_pk", ParentType, ContextType, RequireFields<Subscription_RootRecording_Audio_Driver_By_PkArgs, 'audio_driver_id' | 'recording_id'>>;
  recording_audio_driver_stream?: SubscriptionResolver<Array<ResolversTypes['recording_audio_driver']>, "recording_audio_driver_stream", ParentType, ContextType, RequireFields<Subscription_RootRecording_Audio_Driver_StreamArgs, 'batch_size' | 'cursor'>>;
  recording_data_ground_truth?: SubscriptionResolver<Array<ResolversTypes['recording_data_ground_truth']>, "recording_data_ground_truth", ParentType, ContextType, RequireFields<Subscription_RootRecording_Data_Ground_TruthArgs, never>>;
  recording_data_ground_truth_aggregate?: SubscriptionResolver<ResolversTypes['recording_data_ground_truth_aggregate'], "recording_data_ground_truth_aggregate", ParentType, ContextType, RequireFields<Subscription_RootRecording_Data_Ground_Truth_AggregateArgs, never>>;
  recording_data_ground_truth_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['recording_data_ground_truth']>, "recording_data_ground_truth_by_pk", ParentType, ContextType, RequireFields<Subscription_RootRecording_Data_Ground_Truth_By_PkArgs, 'recording_id'>>;
  recording_data_ground_truth_stream?: SubscriptionResolver<Array<ResolversTypes['recording_data_ground_truth']>, "recording_data_ground_truth_stream", ParentType, ContextType, RequireFields<Subscription_RootRecording_Data_Ground_Truth_StreamArgs, 'batch_size' | 'cursor'>>;
  recording_data_labels?: SubscriptionResolver<Array<ResolversTypes['recording_data_labels']>, "recording_data_labels", ParentType, ContextType, RequireFields<Subscription_RootRecording_Data_LabelsArgs, never>>;
  recording_data_labels_aggregate?: SubscriptionResolver<ResolversTypes['recording_data_labels_aggregate'], "recording_data_labels_aggregate", ParentType, ContextType, RequireFields<Subscription_RootRecording_Data_Labels_AggregateArgs, never>>;
  recording_data_labels_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['recording_data_labels']>, "recording_data_labels_by_pk", ParentType, ContextType, RequireFields<Subscription_RootRecording_Data_Labels_By_PkArgs, 'mode_id' | 'recording_id'>>;
  recording_data_labels_stream?: SubscriptionResolver<Array<ResolversTypes['recording_data_labels']>, "recording_data_labels_stream", ParentType, ContextType, RequireFields<Subscription_RootRecording_Data_Labels_StreamArgs, 'batch_size' | 'cursor'>>;
  recording_data_mode_parameter?: SubscriptionResolver<Array<ResolversTypes['recording_data_mode_parameter']>, "recording_data_mode_parameter", ParentType, ContextType, RequireFields<Subscription_RootRecording_Data_Mode_ParameterArgs, never>>;
  recording_data_mode_parameter_aggregate?: SubscriptionResolver<ResolversTypes['recording_data_mode_parameter_aggregate'], "recording_data_mode_parameter_aggregate", ParentType, ContextType, RequireFields<Subscription_RootRecording_Data_Mode_Parameter_AggregateArgs, never>>;
  recording_data_mode_parameter_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['recording_data_mode_parameter']>, "recording_data_mode_parameter_by_pk", ParentType, ContextType, RequireFields<Subscription_RootRecording_Data_Mode_Parameter_By_PkArgs, 'mode_id' | 'parameter_id'>>;
  recording_data_mode_parameter_stream?: SubscriptionResolver<Array<ResolversTypes['recording_data_mode_parameter']>, "recording_data_mode_parameter_stream", ParentType, ContextType, RequireFields<Subscription_RootRecording_Data_Mode_Parameter_StreamArgs, 'batch_size' | 'cursor'>>;
  recording_data_modes?: SubscriptionResolver<Array<ResolversTypes['recording_data_modes']>, "recording_data_modes", ParentType, ContextType, RequireFields<Subscription_RootRecording_Data_ModesArgs, never>>;
  recording_data_modes_aggregate?: SubscriptionResolver<ResolversTypes['recording_data_modes_aggregate'], "recording_data_modes_aggregate", ParentType, ContextType, RequireFields<Subscription_RootRecording_Data_Modes_AggregateArgs, never>>;
  recording_data_modes_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['recording_data_modes']>, "recording_data_modes_by_pk", ParentType, ContextType, RequireFields<Subscription_RootRecording_Data_Modes_By_PkArgs, 'id'>>;
  recording_data_modes_stream?: SubscriptionResolver<Array<ResolversTypes['recording_data_modes']>, "recording_data_modes_stream", ParentType, ContextType, RequireFields<Subscription_RootRecording_Data_Modes_StreamArgs, 'batch_size' | 'cursor'>>;
  recording_data_parameters?: SubscriptionResolver<Array<ResolversTypes['recording_data_parameters']>, "recording_data_parameters", ParentType, ContextType, RequireFields<Subscription_RootRecording_Data_ParametersArgs, never>>;
  recording_data_parameters_aggregate?: SubscriptionResolver<ResolversTypes['recording_data_parameters_aggregate'], "recording_data_parameters_aggregate", ParentType, ContextType, RequireFields<Subscription_RootRecording_Data_Parameters_AggregateArgs, never>>;
  recording_data_parameters_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['recording_data_parameters']>, "recording_data_parameters_by_pk", ParentType, ContextType, RequireFields<Subscription_RootRecording_Data_Parameters_By_PkArgs, 'id'>>;
  recording_data_parameters_stream?: SubscriptionResolver<Array<ResolversTypes['recording_data_parameters']>, "recording_data_parameters_stream", ParentType, ContextType, RequireFields<Subscription_RootRecording_Data_Parameters_StreamArgs, 'batch_size' | 'cursor'>>;
  recording_data_payload_pair?: SubscriptionResolver<Array<ResolversTypes['recording_data_payload_pair']>, "recording_data_payload_pair", ParentType, ContextType, RequireFields<Subscription_RootRecording_Data_Payload_PairArgs, never>>;
  recording_data_payload_pair_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['recording_data_payload_pair']>, "recording_data_payload_pair_by_pk", ParentType, ContextType, RequireFields<Subscription_RootRecording_Data_Payload_Pair_By_PkArgs, 'payload_id'>>;
  recording_data_payload_pair_stream?: SubscriptionResolver<Array<ResolversTypes['recording_data_payload_pair']>, "recording_data_payload_pair_stream", ParentType, ContextType, RequireFields<Subscription_RootRecording_Data_Payload_Pair_StreamArgs, 'batch_size' | 'cursor'>>;
  recording_data_predictions?: SubscriptionResolver<Array<ResolversTypes['recording_data_predictions']>, "recording_data_predictions", ParentType, ContextType, RequireFields<Subscription_RootRecording_Data_PredictionsArgs, never>>;
  recording_data_predictions_aggregate?: SubscriptionResolver<ResolversTypes['recording_data_predictions_aggregate'], "recording_data_predictions_aggregate", ParentType, ContextType, RequireFields<Subscription_RootRecording_Data_Predictions_AggregateArgs, never>>;
  recording_data_predictions_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['recording_data_predictions']>, "recording_data_predictions_by_pk", ParentType, ContextType, RequireFields<Subscription_RootRecording_Data_Predictions_By_PkArgs, 'engine_id' | 'recording_id'>>;
  recording_data_predictions_stream?: SubscriptionResolver<Array<ResolversTypes['recording_data_predictions']>, "recording_data_predictions_stream", ParentType, ContextType, RequireFields<Subscription_RootRecording_Data_Predictions_StreamArgs, 'batch_size' | 'cursor'>>;
  recording_recording_tag?: SubscriptionResolver<Array<ResolversTypes['recording_recording_tag']>, "recording_recording_tag", ParentType, ContextType, RequireFields<Subscription_RootRecording_Recording_TagArgs, never>>;
  recording_recording_tag_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['recording_recording_tag']>, "recording_recording_tag_by_pk", ParentType, ContextType, RequireFields<Subscription_RootRecording_Recording_Tag_By_PkArgs, 'recording_id' | 'recording_tag_id'>>;
  recording_recording_tag_stream?: SubscriptionResolver<Array<ResolversTypes['recording_recording_tag']>, "recording_recording_tag_stream", ParentType, ContextType, RequireFields<Subscription_RootRecording_Recording_Tag_StreamArgs, 'batch_size' | 'cursor'>>;
  recording_tag_count_by_day?: SubscriptionResolver<Array<ResolversTypes['schema_recording_tag_count_by_day']>, "recording_tag_count_by_day", ParentType, ContextType, RequireFields<Subscription_RootRecording_Tag_Count_By_DayArgs, 'args'>>;
  recording_tag_count_by_day_aggregate?: SubscriptionResolver<ResolversTypes['schema_recording_tag_count_by_day_aggregate'], "recording_tag_count_by_day_aggregate", ParentType, ContextType, RequireFields<Subscription_RootRecording_Tag_Count_By_Day_AggregateArgs, 'args'>>;
  recording_tags?: SubscriptionResolver<Array<ResolversTypes['recording_tags']>, "recording_tags", ParentType, ContextType, RequireFields<Subscription_RootRecording_TagsArgs, never>>;
  recording_tags_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['recording_tags']>, "recording_tags_by_pk", ParentType, ContextType, RequireFields<Subscription_RootRecording_Tags_By_PkArgs, 'id'>>;
  recording_tags_stream?: SubscriptionResolver<Array<ResolversTypes['recording_tags']>, "recording_tags_stream", ParentType, ContextType, RequireFields<Subscription_RootRecording_Tags_StreamArgs, 'batch_size' | 'cursor'>>;
  recordings?: SubscriptionResolver<Array<ResolversTypes['recordings']>, "recordings", ParentType, ContextType, RequireFields<Subscription_RootRecordingsArgs, never>>;
  recordings_aggregate?: SubscriptionResolver<ResolversTypes['recordings_aggregate'], "recordings_aggregate", ParentType, ContextType, RequireFields<Subscription_RootRecordings_AggregateArgs, never>>;
  recordings_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['recordings']>, "recordings_by_pk", ParentType, ContextType, RequireFields<Subscription_RootRecordings_By_PkArgs, 'id'>>;
  recordings_stream?: SubscriptionResolver<Array<ResolversTypes['recordings']>, "recordings_stream", ParentType, ContextType, RequireFields<Subscription_RootRecordings_StreamArgs, 'batch_size' | 'cursor'>>;
  role_user?: SubscriptionResolver<Array<ResolversTypes['role_user']>, "role_user", ParentType, ContextType, RequireFields<Subscription_RootRole_UserArgs, never>>;
  role_user_aggregate?: SubscriptionResolver<ResolversTypes['role_user_aggregate'], "role_user_aggregate", ParentType, ContextType, RequireFields<Subscription_RootRole_User_AggregateArgs, never>>;
  role_user_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['role_user']>, "role_user_by_pk", ParentType, ContextType, RequireFields<Subscription_RootRole_User_By_PkArgs, 'role_id' | 'user_id'>>;
  role_user_stream?: SubscriptionResolver<Array<ResolversTypes['role_user']>, "role_user_stream", ParentType, ContextType, RequireFields<Subscription_RootRole_User_StreamArgs, 'batch_size' | 'cursor'>>;
  roles?: SubscriptionResolver<Array<ResolversTypes['roles']>, "roles", ParentType, ContextType, RequireFields<Subscription_RootRolesArgs, never>>;
  roles_aggregate?: SubscriptionResolver<ResolversTypes['roles_aggregate'], "roles_aggregate", ParentType, ContextType, RequireFields<Subscription_RootRoles_AggregateArgs, never>>;
  roles_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['roles']>, "roles_by_pk", ParentType, ContextType, RequireFields<Subscription_RootRoles_By_PkArgs, 'id'>>;
  roles_stream?: SubscriptionResolver<Array<ResolversTypes['roles']>, "roles_stream", ParentType, ContextType, RequireFields<Subscription_RootRoles_StreamArgs, 'batch_size' | 'cursor'>>;
  rooms?: SubscriptionResolver<Array<ResolversTypes['rooms']>, "rooms", ParentType, ContextType, RequireFields<Subscription_RootRoomsArgs, never>>;
  rooms_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['rooms']>, "rooms_by_pk", ParentType, ContextType, RequireFields<Subscription_RootRooms_By_PkArgs, 'id'>>;
  rooms_stream?: SubscriptionResolver<Array<ResolversTypes['rooms']>, "rooms_stream", ParentType, ContextType, RequireFields<Subscription_RootRooms_StreamArgs, 'batch_size' | 'cursor'>>;
  schema_project_engine_metrics?: SubscriptionResolver<Array<ResolversTypes['schema_project_engine_metrics']>, "schema_project_engine_metrics", ParentType, ContextType, RequireFields<Subscription_RootSchema_Project_Engine_MetricsArgs, never>>;
  schema_project_engine_metrics_stream?: SubscriptionResolver<Array<ResolversTypes['schema_project_engine_metrics']>, "schema_project_engine_metrics_stream", ParentType, ContextType, RequireFields<Subscription_RootSchema_Project_Engine_Metrics_StreamArgs, 'batch_size' | 'cursor'>>;
  schema_project_engine_ratio_metrics?: SubscriptionResolver<Array<ResolversTypes['schema_project_engine_ratio_metrics']>, "schema_project_engine_ratio_metrics", ParentType, ContextType, RequireFields<Subscription_RootSchema_Project_Engine_Ratio_MetricsArgs, never>>;
  schema_project_engine_ratio_metrics_aggregate?: SubscriptionResolver<ResolversTypes['schema_project_engine_ratio_metrics_aggregate'], "schema_project_engine_ratio_metrics_aggregate", ParentType, ContextType, RequireFields<Subscription_RootSchema_Project_Engine_Ratio_Metrics_AggregateArgs, never>>;
  schema_project_engine_ratio_metrics_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['schema_project_engine_ratio_metrics']>, "schema_project_engine_ratio_metrics_by_pk", ParentType, ContextType, RequireFields<Subscription_RootSchema_Project_Engine_Ratio_Metrics_By_PkArgs, 'engine_id' | 'project_id' | 'ratio_metric_id'>>;
  schema_project_engine_ratio_metrics_stream?: SubscriptionResolver<Array<ResolversTypes['schema_project_engine_ratio_metrics']>, "schema_project_engine_ratio_metrics_stream", ParentType, ContextType, RequireFields<Subscription_RootSchema_Project_Engine_Ratio_Metrics_StreamArgs, 'batch_size' | 'cursor'>>;
  schema_project_task_count?: SubscriptionResolver<Array<ResolversTypes['schema_project_task_count']>, "schema_project_task_count", ParentType, ContextType, RequireFields<Subscription_RootSchema_Project_Task_CountArgs, never>>;
  schema_project_task_count_aggregate?: SubscriptionResolver<ResolversTypes['schema_project_task_count_aggregate'], "schema_project_task_count_aggregate", ParentType, ContextType, RequireFields<Subscription_RootSchema_Project_Task_Count_AggregateArgs, never>>;
  schema_project_task_count_by_device?: SubscriptionResolver<Array<ResolversTypes['schema_project_task_count_by_device']>, "schema_project_task_count_by_device", ParentType, ContextType, RequireFields<Subscription_RootSchema_Project_Task_Count_By_DeviceArgs, never>>;
  schema_project_task_count_by_device_aggregate?: SubscriptionResolver<ResolversTypes['schema_project_task_count_by_device_aggregate'], "schema_project_task_count_by_device_aggregate", ParentType, ContextType, RequireFields<Subscription_RootSchema_Project_Task_Count_By_Device_AggregateArgs, never>>;
  schema_project_task_count_by_device_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['schema_project_task_count_by_device']>, "schema_project_task_count_by_device_by_pk", ParentType, ContextType, RequireFields<Subscription_RootSchema_Project_Task_Count_By_Device_By_PkArgs, 'device_id' | 'project_id' | 'task_id'>>;
  schema_project_task_count_by_device_stream?: SubscriptionResolver<Array<ResolversTypes['schema_project_task_count_by_device']>, "schema_project_task_count_by_device_stream", ParentType, ContextType, RequireFields<Subscription_RootSchema_Project_Task_Count_By_Device_StreamArgs, 'batch_size' | 'cursor'>>;
  schema_project_task_count_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['schema_project_task_count']>, "schema_project_task_count_by_pk", ParentType, ContextType, RequireFields<Subscription_RootSchema_Project_Task_Count_By_PkArgs, 'project_id' | 'task_id'>>;
  schema_project_task_count_stream?: SubscriptionResolver<Array<ResolversTypes['schema_project_task_count']>, "schema_project_task_count_stream", ParentType, ContextType, RequireFields<Subscription_RootSchema_Project_Task_Count_StreamArgs, 'batch_size' | 'cursor'>>;
  schema_recording_tag_count_by_day?: SubscriptionResolver<Array<ResolversTypes['schema_recording_tag_count_by_day']>, "schema_recording_tag_count_by_day", ParentType, ContextType, RequireFields<Subscription_RootSchema_Recording_Tag_Count_By_DayArgs, never>>;
  schema_recording_tag_count_by_day_aggregate?: SubscriptionResolver<ResolversTypes['schema_recording_tag_count_by_day_aggregate'], "schema_recording_tag_count_by_day_aggregate", ParentType, ContextType, RequireFields<Subscription_RootSchema_Recording_Tag_Count_By_Day_AggregateArgs, never>>;
  schema_recording_tag_count_by_day_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['schema_recording_tag_count_by_day']>, "schema_recording_tag_count_by_day_by_pk", ParentType, ContextType, RequireFields<Subscription_RootSchema_Recording_Tag_Count_By_Day_By_PkArgs, 'day' | 'project_id' | 'tag_id'>>;
  schema_recording_tag_count_by_day_stream?: SubscriptionResolver<Array<ResolversTypes['schema_recording_tag_count_by_day']>, "schema_recording_tag_count_by_day_stream", ParentType, ContextType, RequireFields<Subscription_RootSchema_Recording_Tag_Count_By_Day_StreamArgs, 'batch_size' | 'cursor'>>;
  simulations_scores?: SubscriptionResolver<Array<ResolversTypes['simulations_scores']>, "simulations_scores", ParentType, ContextType, RequireFields<Subscription_RootSimulations_ScoresArgs, never>>;
  simulations_scores_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['simulations_scores']>, "simulations_scores_by_pk", ParentType, ContextType, RequireFields<Subscription_RootSimulations_Scores_By_PkArgs, 'id'>>;
  simulations_scores_stream?: SubscriptionResolver<Array<ResolversTypes['simulations_scores']>, "simulations_scores_stream", ParentType, ContextType, RequireFields<Subscription_RootSimulations_Scores_StreamArgs, 'batch_size' | 'cursor'>>;
  task_field_option?: SubscriptionResolver<Array<ResolversTypes['task_field_option']>, "task_field_option", ParentType, ContextType, RequireFields<Subscription_RootTask_Field_OptionArgs, never>>;
  task_field_option_aggregate?: SubscriptionResolver<ResolversTypes['task_field_option_aggregate'], "task_field_option_aggregate", ParentType, ContextType, RequireFields<Subscription_RootTask_Field_Option_AggregateArgs, never>>;
  task_field_option_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['task_field_option']>, "task_field_option_by_pk", ParentType, ContextType, RequireFields<Subscription_RootTask_Field_Option_By_PkArgs, 'field_option_id' | 'task_id'>>;
  task_field_option_hashes?: SubscriptionResolver<Array<ResolversTypes['task_field_option_hashes']>, "task_field_option_hashes", ParentType, ContextType, RequireFields<Subscription_RootTask_Field_Option_HashesArgs, never>>;
  task_field_option_hashes_aggregate?: SubscriptionResolver<ResolversTypes['task_field_option_hashes_aggregate'], "task_field_option_hashes_aggregate", ParentType, ContextType, RequireFields<Subscription_RootTask_Field_Option_Hashes_AggregateArgs, never>>;
  task_field_option_hashes_stream?: SubscriptionResolver<Array<ResolversTypes['task_field_option_hashes']>, "task_field_option_hashes_stream", ParentType, ContextType, RequireFields<Subscription_RootTask_Field_Option_Hashes_StreamArgs, 'batch_size' | 'cursor'>>;
  task_field_option_stream?: SubscriptionResolver<Array<ResolversTypes['task_field_option']>, "task_field_option_stream", ParentType, ContextType, RequireFields<Subscription_RootTask_Field_Option_StreamArgs, 'batch_size' | 'cursor'>>;
  task_groups_group_fields?: SubscriptionResolver<Array<ResolversTypes['task_groups_group_fields']>, "task_groups_group_fields", ParentType, ContextType, RequireFields<Subscription_RootTask_Groups_Group_FieldsArgs, never>>;
  task_groups_group_fields_aggregate?: SubscriptionResolver<ResolversTypes['task_groups_group_fields_aggregate'], "task_groups_group_fields_aggregate", ParentType, ContextType, RequireFields<Subscription_RootTask_Groups_Group_Fields_AggregateArgs, never>>;
  task_groups_group_fields_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['task_groups_group_fields']>, "task_groups_group_fields_by_pk", ParentType, ContextType, RequireFields<Subscription_RootTask_Groups_Group_Fields_By_PkArgs, 'field_id' | 'group_id'>>;
  task_groups_group_fields_stream?: SubscriptionResolver<Array<ResolversTypes['task_groups_group_fields']>, "task_groups_group_fields_stream", ParentType, ContextType, RequireFields<Subscription_RootTask_Groups_Group_Fields_StreamArgs, 'batch_size' | 'cursor'>>;
  task_groups_groups?: SubscriptionResolver<Array<ResolversTypes['task_groups_groups']>, "task_groups_groups", ParentType, ContextType, RequireFields<Subscription_RootTask_Groups_GroupsArgs, never>>;
  task_groups_groups_aggregate?: SubscriptionResolver<ResolversTypes['task_groups_groups_aggregate'], "task_groups_groups_aggregate", ParentType, ContextType, RequireFields<Subscription_RootTask_Groups_Groups_AggregateArgs, never>>;
  task_groups_groups_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['task_groups_groups']>, "task_groups_groups_by_pk", ParentType, ContextType, RequireFields<Subscription_RootTask_Groups_Groups_By_PkArgs, 'id'>>;
  task_groups_groups_stream?: SubscriptionResolver<Array<ResolversTypes['task_groups_groups']>, "task_groups_groups_stream", ParentType, ContextType, RequireFields<Subscription_RootTask_Groups_Groups_StreamArgs, 'batch_size' | 'cursor'>>;
  task_groups_hierarchies?: SubscriptionResolver<Array<ResolversTypes['task_groups_hierarchies']>, "task_groups_hierarchies", ParentType, ContextType, RequireFields<Subscription_RootTask_Groups_HierarchiesArgs, never>>;
  task_groups_hierarchies_aggregate?: SubscriptionResolver<ResolversTypes['task_groups_hierarchies_aggregate'], "task_groups_hierarchies_aggregate", ParentType, ContextType, RequireFields<Subscription_RootTask_Groups_Hierarchies_AggregateArgs, never>>;
  task_groups_hierarchies_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['task_groups_hierarchies']>, "task_groups_hierarchies_by_pk", ParentType, ContextType, RequireFields<Subscription_RootTask_Groups_Hierarchies_By_PkArgs, 'id'>>;
  task_groups_hierarchies_stream?: SubscriptionResolver<Array<ResolversTypes['task_groups_hierarchies']>, "task_groups_hierarchies_stream", ParentType, ContextType, RequireFields<Subscription_RootTask_Groups_Hierarchies_StreamArgs, 'batch_size' | 'cursor'>>;
  task_groups_hierarchy_groups?: SubscriptionResolver<Array<ResolversTypes['task_groups_hierarchy_groups']>, "task_groups_hierarchy_groups", ParentType, ContextType, RequireFields<Subscription_RootTask_Groups_Hierarchy_GroupsArgs, never>>;
  task_groups_hierarchy_groups_aggregate?: SubscriptionResolver<ResolversTypes['task_groups_hierarchy_groups_aggregate'], "task_groups_hierarchy_groups_aggregate", ParentType, ContextType, RequireFields<Subscription_RootTask_Groups_Hierarchy_Groups_AggregateArgs, never>>;
  task_groups_hierarchy_groups_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['task_groups_hierarchy_groups']>, "task_groups_hierarchy_groups_by_pk", ParentType, ContextType, RequireFields<Subscription_RootTask_Groups_Hierarchy_Groups_By_PkArgs, 'group_id' | 'hierarchy_id'>>;
  task_groups_hierarchy_groups_stream?: SubscriptionResolver<Array<ResolversTypes['task_groups_hierarchy_groups']>, "task_groups_hierarchy_groups_stream", ParentType, ContextType, RequireFields<Subscription_RootTask_Groups_Hierarchy_Groups_StreamArgs, 'batch_size' | 'cursor'>>;
  task_instructions?: SubscriptionResolver<Array<ResolversTypes['task_instructions']>, "task_instructions", ParentType, ContextType, RequireFields<Subscription_RootTask_InstructionsArgs, never>>;
  task_instructions_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['task_instructions']>, "task_instructions_by_pk", ParentType, ContextType, RequireFields<Subscription_RootTask_Instructions_By_PkArgs, 'id'>>;
  task_instructions_stream?: SubscriptionResolver<Array<ResolversTypes['task_instructions']>, "task_instructions_stream", ParentType, ContextType, RequireFields<Subscription_RootTask_Instructions_StreamArgs, 'batch_size' | 'cursor'>>;
  task_media?: SubscriptionResolver<Array<ResolversTypes['task_media']>, "task_media", ParentType, ContextType, RequireFields<Subscription_RootTask_MediaArgs, never>>;
  task_media_aggregate?: SubscriptionResolver<ResolversTypes['task_media_aggregate'], "task_media_aggregate", ParentType, ContextType, RequireFields<Subscription_RootTask_Media_AggregateArgs, never>>;
  task_media_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['task_media']>, "task_media_by_pk", ParentType, ContextType, RequireFields<Subscription_RootTask_Media_By_PkArgs, 'media_id' | 'task_id'>>;
  task_media_stream?: SubscriptionResolver<Array<ResolversTypes['task_media']>, "task_media_stream", ParentType, ContextType, RequireFields<Subscription_RootTask_Media_StreamArgs, 'batch_size' | 'cursor'>>;
  task_statuses?: SubscriptionResolver<Array<ResolversTypes['task_statuses']>, "task_statuses", ParentType, ContextType, RequireFields<Subscription_RootTask_StatusesArgs, never>>;
  task_statuses_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['task_statuses']>, "task_statuses_by_pk", ParentType, ContextType, RequireFields<Subscription_RootTask_Statuses_By_PkArgs, 'id'>>;
  task_statuses_stream?: SubscriptionResolver<Array<ResolversTypes['task_statuses']>, "task_statuses_stream", ParentType, ContextType, RequireFields<Subscription_RootTask_Statuses_StreamArgs, 'batch_size' | 'cursor'>>;
  task_translation?: SubscriptionResolver<Array<ResolversTypes['task_translation']>, "task_translation", ParentType, ContextType, RequireFields<Subscription_RootTask_TranslationArgs, never>>;
  task_translation_aggregate?: SubscriptionResolver<ResolversTypes['task_translation_aggregate'], "task_translation_aggregate", ParentType, ContextType, RequireFields<Subscription_RootTask_Translation_AggregateArgs, never>>;
  task_translation_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['task_translation']>, "task_translation_by_pk", ParentType, ContextType, RequireFields<Subscription_RootTask_Translation_By_PkArgs, 'language' | 'task_id'>>;
  task_translation_stream?: SubscriptionResolver<Array<ResolversTypes['task_translation']>, "task_translation_stream", ParentType, ContextType, RequireFields<Subscription_RootTask_Translation_StreamArgs, 'batch_size' | 'cursor'>>;
  tasks?: SubscriptionResolver<Array<ResolversTypes['tasks']>, "tasks", ParentType, ContextType, RequireFields<Subscription_RootTasksArgs, never>>;
  tasks_aggregate?: SubscriptionResolver<ResolversTypes['tasks_aggregate'], "tasks_aggregate", ParentType, ContextType, RequireFields<Subscription_RootTasks_AggregateArgs, never>>;
  tasks_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['tasks']>, "tasks_by_pk", ParentType, ContextType, RequireFields<Subscription_RootTasks_By_PkArgs, 'id'>>;
  tasks_stream?: SubscriptionResolver<Array<ResolversTypes['tasks']>, "tasks_stream", ParentType, ContextType, RequireFields<Subscription_RootTasks_StreamArgs, 'batch_size' | 'cursor'>>;
  tool_versions?: SubscriptionResolver<Array<ResolversTypes['tool_versions']>, "tool_versions", ParentType, ContextType, RequireFields<Subscription_RootTool_VersionsArgs, never>>;
  tool_versions_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['tool_versions']>, "tool_versions_by_pk", ParentType, ContextType, RequireFields<Subscription_RootTool_Versions_By_PkArgs, 'id'>>;
  tool_versions_stream?: SubscriptionResolver<Array<ResolversTypes['tool_versions']>, "tool_versions_stream", ParentType, ContextType, RequireFields<Subscription_RootTool_Versions_StreamArgs, 'batch_size' | 'cursor'>>;
  tools?: SubscriptionResolver<Array<ResolversTypes['tools']>, "tools", ParentType, ContextType, RequireFields<Subscription_RootToolsArgs, never>>;
  tools_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['tools']>, "tools_by_pk", ParentType, ContextType, RequireFields<Subscription_RootTools_By_PkArgs, 'id'>>;
  tools_stream?: SubscriptionResolver<Array<ResolversTypes['tools']>, "tools_stream", ParentType, ContextType, RequireFields<Subscription_RootTools_StreamArgs, 'batch_size' | 'cursor'>>;
  types?: SubscriptionResolver<Array<ResolversTypes['types']>, "types", ParentType, ContextType, RequireFields<Subscription_RootTypesArgs, never>>;
  types_aggregate?: SubscriptionResolver<ResolversTypes['types_aggregate'], "types_aggregate", ParentType, ContextType, RequireFields<Subscription_RootTypes_AggregateArgs, never>>;
  types_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['types']>, "types_by_pk", ParentType, ContextType, RequireFields<Subscription_RootTypes_By_PkArgs, 'id'>>;
  types_stream?: SubscriptionResolver<Array<ResolversTypes['types']>, "types_stream", ParentType, ContextType, RequireFields<Subscription_RootTypes_StreamArgs, 'batch_size' | 'cursor'>>;
  user_recordings_by_day_stats?: SubscriptionResolver<Array<ResolversTypes['user_recordings_by_day_stats']>, "user_recordings_by_day_stats", ParentType, ContextType, RequireFields<Subscription_RootUser_Recordings_By_Day_StatsArgs, never>>;
  user_recordings_by_day_stats_aggregate?: SubscriptionResolver<ResolversTypes['user_recordings_by_day_stats_aggregate'], "user_recordings_by_day_stats_aggregate", ParentType, ContextType, RequireFields<Subscription_RootUser_Recordings_By_Day_Stats_AggregateArgs, never>>;
  user_recordings_by_day_stats_stream?: SubscriptionResolver<Array<ResolversTypes['user_recordings_by_day_stats']>, "user_recordings_by_day_stats_stream", ParentType, ContextType, RequireFields<Subscription_RootUser_Recordings_By_Day_Stats_StreamArgs, 'batch_size' | 'cursor'>>;
  users?: SubscriptionResolver<Array<ResolversTypes['users']>, "users", ParentType, ContextType, RequireFields<Subscription_RootUsersArgs, never>>;
  users_aggregate?: SubscriptionResolver<ResolversTypes['users_aggregate'], "users_aggregate", ParentType, ContextType, RequireFields<Subscription_RootUsers_AggregateArgs, never>>;
  users_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['users']>, "users_by_pk", ParentType, ContextType, RequireFields<Subscription_RootUsers_By_PkArgs, 'id'>>;
  users_stream?: SubscriptionResolver<Array<ResolversTypes['users']>, "users_stream", ParentType, ContextType, RequireFields<Subscription_RootUsers_StreamArgs, 'batch_size' | 'cursor'>>;
  xtensa_configurations?: SubscriptionResolver<Array<ResolversTypes['xtensa_configurations']>, "xtensa_configurations", ParentType, ContextType, RequireFields<Subscription_RootXtensa_ConfigurationsArgs, never>>;
  xtensa_configurations_aggregate?: SubscriptionResolver<ResolversTypes['xtensa_configurations_aggregate'], "xtensa_configurations_aggregate", ParentType, ContextType, RequireFields<Subscription_RootXtensa_Configurations_AggregateArgs, never>>;
  xtensa_configurations_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['xtensa_configurations']>, "xtensa_configurations_by_pk", ParentType, ContextType, RequireFields<Subscription_RootXtensa_Configurations_By_PkArgs, 'id'>>;
  xtensa_configurations_stream?: SubscriptionResolver<Array<ResolversTypes['xtensa_configurations']>, "xtensa_configurations_stream", ParentType, ContextType, RequireFields<Subscription_RootXtensa_Configurations_StreamArgs, 'batch_size' | 'cursor'>>;
};

export type Task_Field_OptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_field_option'] = ResolversParentTypes['task_field_option']> = {
  field_option?: Resolver<ResolversTypes['field_options'], ParentType, ContextType>;
  field_option_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  highlight?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  task?: Resolver<ResolversTypes['tasks'], ParentType, ContextType>;
  task_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Field_Option_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_field_option_aggregate'] = ResolversParentTypes['task_field_option_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['task_field_option_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['task_field_option']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Field_Option_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_field_option_aggregate_fields'] = ResolversParentTypes['task_field_option_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Task_Field_Option_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['task_field_option_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['task_field_option_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Field_Option_HashesResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_field_option_hashes'] = ResolversParentTypes['task_field_option_hashes']> = {
  md5?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  task?: Resolver<Maybe<ResolversTypes['tasks']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Field_Option_Hashes_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_field_option_hashes_aggregate'] = ResolversParentTypes['task_field_option_hashes_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['task_field_option_hashes_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['task_field_option_hashes']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Field_Option_Hashes_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_field_option_hashes_aggregate_fields'] = ResolversParentTypes['task_field_option_hashes_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Task_Field_Option_Hashes_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['task_field_option_hashes_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['task_field_option_hashes_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Field_Option_Hashes_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_field_option_hashes_max_fields'] = ResolversParentTypes['task_field_option_hashes_max_fields']> = {
  md5?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Field_Option_Hashes_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_field_option_hashes_min_fields'] = ResolversParentTypes['task_field_option_hashes_min_fields']> = {
  md5?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Field_Option_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_field_option_max_fields'] = ResolversParentTypes['task_field_option_max_fields']> = {
  field_option_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Field_Option_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_field_option_min_fields'] = ResolversParentTypes['task_field_option_min_fields']> = {
  field_option_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Field_Option_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_field_option_mutation_response'] = ResolversParentTypes['task_field_option_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['task_field_option']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Group_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_group_fields'] = ResolversParentTypes['task_groups_group_fields']> = {
  field?: Resolver<ResolversTypes['fields'], ParentType, ContextType>;
  field_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  group?: Resolver<ResolversTypes['task_groups_groups'], ParentType, ContextType>;
  group_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  position?: Resolver<ResolversTypes['smallint'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Group_Fields_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_group_fields_aggregate'] = ResolversParentTypes['task_groups_group_fields_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['task_groups_group_fields_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['task_groups_group_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Group_Fields_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_group_fields_aggregate_fields'] = ResolversParentTypes['task_groups_group_fields_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['task_groups_group_fields_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Task_Groups_Group_Fields_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['task_groups_group_fields_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['task_groups_group_fields_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['task_groups_group_fields_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['task_groups_group_fields_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['task_groups_group_fields_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['task_groups_group_fields_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['task_groups_group_fields_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['task_groups_group_fields_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['task_groups_group_fields_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Group_Fields_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_group_fields_avg_fields'] = ResolversParentTypes['task_groups_group_fields_avg_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Group_Fields_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_group_fields_max_fields'] = ResolversParentTypes['task_groups_group_fields_max_fields']> = {
  field_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  group_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Group_Fields_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_group_fields_min_fields'] = ResolversParentTypes['task_groups_group_fields_min_fields']> = {
  field_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  group_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Group_Fields_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_group_fields_mutation_response'] = ResolversParentTypes['task_groups_group_fields_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['task_groups_group_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Group_Fields_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_group_fields_stddev_fields'] = ResolversParentTypes['task_groups_group_fields_stddev_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Group_Fields_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_group_fields_stddev_pop_fields'] = ResolversParentTypes['task_groups_group_fields_stddev_pop_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Group_Fields_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_group_fields_stddev_samp_fields'] = ResolversParentTypes['task_groups_group_fields_stddev_samp_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Group_Fields_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_group_fields_sum_fields'] = ResolversParentTypes['task_groups_group_fields_sum_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Group_Fields_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_group_fields_var_pop_fields'] = ResolversParentTypes['task_groups_group_fields_var_pop_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Group_Fields_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_group_fields_var_samp_fields'] = ResolversParentTypes['task_groups_group_fields_var_samp_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Group_Fields_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_group_fields_variance_fields'] = ResolversParentTypes['task_groups_group_fields_variance_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_GroupsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_groups'] = ResolversParentTypes['task_groups_groups']> = {
  group_fields?: Resolver<Array<ResolversTypes['task_groups_group_fields']>, ParentType, ContextType, RequireFields<Task_Groups_GroupsGroup_FieldsArgs, never>>;
  group_fields_aggregate?: Resolver<ResolversTypes['task_groups_group_fields_aggregate'], ParentType, ContextType, RequireFields<Task_Groups_GroupsGroup_Fields_AggregateArgs, never>>;
  hierarchy_groups?: Resolver<Array<ResolversTypes['task_groups_hierarchy_groups']>, ParentType, ContextType, RequireFields<Task_Groups_GroupsHierarchy_GroupsArgs, never>>;
  hierarchy_groups_aggregate?: Resolver<ResolversTypes['task_groups_hierarchy_groups_aggregate'], ParentType, ContextType, RequireFields<Task_Groups_GroupsHierarchy_Groups_AggregateArgs, never>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Groups_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_groups_aggregate'] = ResolversParentTypes['task_groups_groups_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['task_groups_groups_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['task_groups_groups']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Groups_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_groups_aggregate_fields'] = ResolversParentTypes['task_groups_groups_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Task_Groups_Groups_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['task_groups_groups_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['task_groups_groups_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Groups_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_groups_max_fields'] = ResolversParentTypes['task_groups_groups_max_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Groups_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_groups_min_fields'] = ResolversParentTypes['task_groups_groups_min_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Groups_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_groups_mutation_response'] = ResolversParentTypes['task_groups_groups_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['task_groups_groups']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_HierarchiesResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_hierarchies'] = ResolversParentTypes['task_groups_hierarchies']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hierarchy_groups?: Resolver<Array<ResolversTypes['task_groups_hierarchy_groups']>, ParentType, ContextType, RequireFields<Task_Groups_HierarchiesHierarchy_GroupsArgs, never>>;
  hierarchy_groups_aggregate?: Resolver<ResolversTypes['task_groups_hierarchy_groups_aggregate'], ParentType, ContextType, RequireFields<Task_Groups_HierarchiesHierarchy_Groups_AggregateArgs, never>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Hierarchies_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_hierarchies_aggregate'] = ResolversParentTypes['task_groups_hierarchies_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['task_groups_hierarchies_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['task_groups_hierarchies']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Hierarchies_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_hierarchies_aggregate_fields'] = ResolversParentTypes['task_groups_hierarchies_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Task_Groups_Hierarchies_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['task_groups_hierarchies_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['task_groups_hierarchies_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Hierarchies_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_hierarchies_max_fields'] = ResolversParentTypes['task_groups_hierarchies_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Hierarchies_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_hierarchies_min_fields'] = ResolversParentTypes['task_groups_hierarchies_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Hierarchies_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_hierarchies_mutation_response'] = ResolversParentTypes['task_groups_hierarchies_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['task_groups_hierarchies']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Hierarchy_GroupsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_hierarchy_groups'] = ResolversParentTypes['task_groups_hierarchy_groups']> = {
  group?: Resolver<ResolversTypes['task_groups_groups'], ParentType, ContextType>;
  group_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  hierarchy?: Resolver<ResolversTypes['task_groups_hierarchies'], ParentType, ContextType>;
  hierarchy_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  position?: Resolver<ResolversTypes['smallint'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Hierarchy_Groups_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_hierarchy_groups_aggregate'] = ResolversParentTypes['task_groups_hierarchy_groups_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['task_groups_hierarchy_groups_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['task_groups_hierarchy_groups']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Hierarchy_Groups_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_hierarchy_groups_aggregate_fields'] = ResolversParentTypes['task_groups_hierarchy_groups_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['task_groups_hierarchy_groups_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Task_Groups_Hierarchy_Groups_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['task_groups_hierarchy_groups_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['task_groups_hierarchy_groups_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['task_groups_hierarchy_groups_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['task_groups_hierarchy_groups_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['task_groups_hierarchy_groups_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['task_groups_hierarchy_groups_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['task_groups_hierarchy_groups_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['task_groups_hierarchy_groups_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['task_groups_hierarchy_groups_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Hierarchy_Groups_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_hierarchy_groups_avg_fields'] = ResolversParentTypes['task_groups_hierarchy_groups_avg_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Hierarchy_Groups_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_hierarchy_groups_max_fields'] = ResolversParentTypes['task_groups_hierarchy_groups_max_fields']> = {
  group_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  hierarchy_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Hierarchy_Groups_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_hierarchy_groups_min_fields'] = ResolversParentTypes['task_groups_hierarchy_groups_min_fields']> = {
  group_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  hierarchy_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Hierarchy_Groups_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_hierarchy_groups_mutation_response'] = ResolversParentTypes['task_groups_hierarchy_groups_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['task_groups_hierarchy_groups']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Hierarchy_Groups_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_hierarchy_groups_stddev_fields'] = ResolversParentTypes['task_groups_hierarchy_groups_stddev_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Hierarchy_Groups_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_hierarchy_groups_stddev_pop_fields'] = ResolversParentTypes['task_groups_hierarchy_groups_stddev_pop_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Hierarchy_Groups_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_hierarchy_groups_stddev_samp_fields'] = ResolversParentTypes['task_groups_hierarchy_groups_stddev_samp_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Hierarchy_Groups_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_hierarchy_groups_sum_fields'] = ResolversParentTypes['task_groups_hierarchy_groups_sum_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Hierarchy_Groups_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_hierarchy_groups_var_pop_fields'] = ResolversParentTypes['task_groups_hierarchy_groups_var_pop_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Hierarchy_Groups_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_hierarchy_groups_var_samp_fields'] = ResolversParentTypes['task_groups_hierarchy_groups_var_samp_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Groups_Hierarchy_Groups_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_groups_hierarchy_groups_variance_fields'] = ResolversParentTypes['task_groups_hierarchy_groups_variance_fields']> = {
  position?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_InstructionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_instructions'] = ResolversParentTypes['task_instructions']> = {
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  instruction_translations?: Resolver<Array<ResolversTypes['instruction_translations']>, ParentType, ContextType, RequireFields<Task_InstructionsInstruction_TranslationsArgs, never>>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  task?: Resolver<ResolversTypes['tasks'], ParentType, ContextType>;
  task_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['users'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Instructions_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_instructions_mutation_response'] = ResolversParentTypes['task_instructions_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['task_instructions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_MediaResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_media'] = ResolversParentTypes['task_media']> = {
  media?: Resolver<ResolversTypes['media'], ParentType, ContextType>;
  media_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  task?: Resolver<ResolversTypes['tasks'], ParentType, ContextType>;
  task_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Media_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_media_aggregate'] = ResolversParentTypes['task_media_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['task_media_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['task_media']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Media_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_media_aggregate_fields'] = ResolversParentTypes['task_media_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Task_Media_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['task_media_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['task_media_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Media_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_media_max_fields'] = ResolversParentTypes['task_media_max_fields']> = {
  media_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Media_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_media_min_fields'] = ResolversParentTypes['task_media_min_fields']> = {
  media_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Media_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_media_mutation_response'] = ResolversParentTypes['task_media_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['task_media']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_StatusesResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_statuses'] = ResolversParentTypes['task_statuses']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_TranslationResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_translation'] = ResolversParentTypes['task_translation']> = {
  language?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  note?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  task?: Resolver<ResolversTypes['tasks'], ParentType, ContextType>;
  task_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  task_translation_language?: Resolver<ResolversTypes['languages'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Translation_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_translation_aggregate'] = ResolversParentTypes['task_translation_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['task_translation_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['task_translation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Translation_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_translation_aggregate_fields'] = ResolversParentTypes['task_translation_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Task_Translation_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['task_translation_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['task_translation_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Translation_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_translation_max_fields'] = ResolversParentTypes['task_translation_max_fields']> = {
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Translation_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_translation_min_fields'] = ResolversParentTypes['task_translation_min_fields']> = {
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_Translation_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_translation_mutation_response'] = ResolversParentTypes['task_translation_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['task_translation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TasksResolvers<ContextType = any, ParentType extends ResolversParentTypes['tasks'] = ResolversParentTypes['tasks']> = {
  audio_measurements_project_tasks?: Resolver<Array<ResolversTypes['audio_measurements_project_tasks']>, ParentType, ContextType, RequireFields<TasksAudio_Measurements_Project_TasksArgs, never>>;
  audio_measurements_project_tasks_aggregate?: Resolver<ResolversTypes['audio_measurements_project_tasks_aggregate'], ParentType, ContextType, RequireFields<TasksAudio_Measurements_Project_Tasks_AggregateArgs, never>>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  demo_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  force_ground_truth_to_negative?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hypertask_tasks?: Resolver<Array<ResolversTypes['hypertask_task']>, ParentType, ContextType, RequireFields<TasksHypertask_TasksArgs, never>>;
  hypertask_tasks_aggregate?: Resolver<ResolversTypes['hypertask_task_aggregate'], ParentType, ContextType, RequireFields<TasksHypertask_Tasks_AggregateArgs, never>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  instructions?: Resolver<Array<ResolversTypes['integration_test_instructions']>, ParentType, ContextType, RequireFields<TasksInstructionsArgs, never>>;
  instructions_aggregate?: Resolver<ResolversTypes['integration_test_instructions_aggregate'], ParentType, ContextType, RequireFields<TasksInstructions_AggregateArgs, never>>;
  is_template?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  is_test?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  legacy_template_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  plan_tasks?: Resolver<Array<ResolversTypes['plan_tasks']>, ParentType, ContextType, RequireFields<TasksPlan_TasksArgs, never>>;
  plan_tasks_aggregate?: Resolver<ResolversTypes['plan_tasks_aggregate'], ParentType, ContextType, RequireFields<TasksPlan_Tasks_AggregateArgs, never>>;
  project_dataset?: Resolver<Array<ResolversTypes['project_dataset']>, ParentType, ContextType, RequireFields<TasksProject_DatasetArgs, never>>;
  project_dataset_aggregate?: Resolver<ResolversTypes['project_dataset_aggregate'], ParentType, ContextType, RequireFields<TasksProject_Dataset_AggregateArgs, never>>;
  project_tasks?: Resolver<Array<ResolversTypes['project_tasks']>, ParentType, ContextType, RequireFields<TasksProject_TasksArgs, never>>;
  project_tasks_aggregate?: Resolver<ResolversTypes['project_tasks_aggregate'], ParentType, ContextType, RequireFields<TasksProject_Tasks_AggregateArgs, never>>;
  rabbit_test_tasks?: Resolver<Array<ResolversTypes['rabbit_test_plan_task']>, ParentType, ContextType, RequireFields<TasksRabbit_Test_TasksArgs, never>>;
  rabbit_test_tasks_aggregate?: Resolver<ResolversTypes['rabbit_test_plan_task_aggregate'], ParentType, ContextType, RequireFields<TasksRabbit_Test_Tasks_AggregateArgs, never>>;
  recordings?: Resolver<Array<ResolversTypes['recordings']>, ParentType, ContextType, RequireFields<TasksRecordingsArgs, never>>;
  recordings_aggregate?: Resolver<ResolversTypes['recordings_aggregate'], ParentType, ContextType, RequireFields<TasksRecordings_AggregateArgs, never>>;
  task_field_options?: Resolver<Array<ResolversTypes['task_field_option']>, ParentType, ContextType, RequireFields<TasksTask_Field_OptionsArgs, never>>;
  task_field_options_aggregate?: Resolver<ResolversTypes['task_field_option_aggregate'], ParentType, ContextType, RequireFields<TasksTask_Field_Options_AggregateArgs, never>>;
  task_instruction?: Resolver<Maybe<ResolversTypes['task_instructions']>, ParentType, ContextType>;
  task_media?: Resolver<Array<ResolversTypes['task_media']>, ParentType, ContextType, RequireFields<TasksTask_MediaArgs, never>>;
  task_media_aggregate?: Resolver<ResolversTypes['task_media_aggregate'], ParentType, ContextType, RequireFields<TasksTask_Media_AggregateArgs, never>>;
  translations?: Resolver<Array<ResolversTypes['task_translation']>, ParentType, ContextType, RequireFields<TasksTranslationsArgs, never>>;
  translations_aggregate?: Resolver<ResolversTypes['task_translation_aggregate'], ParentType, ContextType, RequireFields<TasksTranslations_AggregateArgs, never>>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tasks_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['tasks_aggregate'] = ResolversParentTypes['tasks_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['tasks_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['tasks']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tasks_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['tasks_aggregate_fields'] = ResolversParentTypes['tasks_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['tasks_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Tasks_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['tasks_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['tasks_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['tasks_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['tasks_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['tasks_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['tasks_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['tasks_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['tasks_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['tasks_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tasks_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['tasks_avg_fields'] = ResolversParentTypes['tasks_avg_fields']> = {
  legacy_template_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tasks_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['tasks_max_fields'] = ResolversParentTypes['tasks_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  demo_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  legacy_template_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tasks_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['tasks_min_fields'] = ResolversParentTypes['tasks_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  demo_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  legacy_template_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tasks_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['tasks_mutation_response'] = ResolversParentTypes['tasks_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['tasks']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tasks_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['tasks_stddev_fields'] = ResolversParentTypes['tasks_stddev_fields']> = {
  legacy_template_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tasks_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['tasks_stddev_pop_fields'] = ResolversParentTypes['tasks_stddev_pop_fields']> = {
  legacy_template_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tasks_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['tasks_stddev_samp_fields'] = ResolversParentTypes['tasks_stddev_samp_fields']> = {
  legacy_template_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tasks_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['tasks_sum_fields'] = ResolversParentTypes['tasks_sum_fields']> = {
  legacy_template_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tasks_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['tasks_var_pop_fields'] = ResolversParentTypes['tasks_var_pop_fields']> = {
  legacy_template_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tasks_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['tasks_var_samp_fields'] = ResolversParentTypes['tasks_var_samp_fields']> = {
  legacy_template_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tasks_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['tasks_variance_fields'] = ResolversParentTypes['tasks_variance_fields']> = {
  legacy_template_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface TimestampScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['timestamp'], any> {
  name: 'timestamp';
}

export interface TimestamptzScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['timestamptz'], any> {
  name: 'timestamptz';
}

export type Tool_VersionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['tool_versions'] = ResolversParentTypes['tool_versions']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  filename?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  released_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  storage_path?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tool?: Resolver<Maybe<ResolversTypes['tools']>, ParentType, ContextType>;
  tool_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tool_Versions_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['tool_versions_mutation_response'] = ResolversParentTypes['tool_versions_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['tool_versions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolsResolvers<ContextType = any, ParentType extends ResolversParentTypes['tools'] = ResolversParentTypes['tools']> = {
  asgard_only?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  documentation_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  sas?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  storage_account?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  storage_container?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tool_versions?: Resolver<Array<ResolversTypes['tool_versions']>, ParentType, ContextType, RequireFields<ToolsTool_VersionsArgs, never>>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tools_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['tools_mutation_response'] = ResolversParentTypes['tools_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['tools']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TypesResolvers<ContextType = any, ParentType extends ResolversParentTypes['types'] = ResolversParentTypes['types']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  field_options?: Resolver<Array<ResolversTypes['field_options']>, ParentType, ContextType, RequireFields<TypesField_OptionsArgs, never>>;
  field_options_aggregate?: Resolver<ResolversTypes['field_options_aggregate'], ParentType, ContextType, RequireFields<TypesField_Options_AggregateArgs, never>>;
  fields?: Resolver<Array<ResolversTypes['fields']>, ParentType, ContextType, RequireFields<TypesFieldsArgs, never>>;
  fields_aggregate?: Resolver<ResolversTypes['fields_aggregate'], ParentType, ContextType, RequireFields<TypesFields_AggregateArgs, never>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  options?: Resolver<Array<ResolversTypes['options']>, ParentType, ContextType, RequireFields<TypesOptionsArgs, never>>;
  options_aggregate?: Resolver<ResolversTypes['options_aggregate'], ParentType, ContextType, RequireFields<TypesOptions_AggregateArgs, never>>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Types_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['types_aggregate'] = ResolversParentTypes['types_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['types_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['types']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Types_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['types_aggregate_fields'] = ResolversParentTypes['types_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Types_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['types_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['types_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Types_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['types_max_fields'] = ResolversParentTypes['types_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Types_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['types_min_fields'] = ResolversParentTypes['types_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Types_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['types_mutation_response'] = ResolversParentTypes['types_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['types']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Recordings_By_Day_StatsResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_recordings_by_day_stats'] = ResolversParentTypes['user_recordings_by_day_stats']> = {
  clean_duration?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  clean_recordings?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  day?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  dirty_duration?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  dirty_recordings?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  project?: Resolver<Maybe<ResolversTypes['projects']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  total_duration?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  total_recordings?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  untagged_duration?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  untagged_recordings?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['users']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Recordings_By_Day_Stats_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_recordings_by_day_stats_aggregate'] = ResolversParentTypes['user_recordings_by_day_stats_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['user_recordings_by_day_stats_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['user_recordings_by_day_stats']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Recordings_By_Day_Stats_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_recordings_by_day_stats_aggregate_fields'] = ResolversParentTypes['user_recordings_by_day_stats_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['user_recordings_by_day_stats_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<User_Recordings_By_Day_Stats_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['user_recordings_by_day_stats_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['user_recordings_by_day_stats_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['user_recordings_by_day_stats_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['user_recordings_by_day_stats_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['user_recordings_by_day_stats_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['user_recordings_by_day_stats_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['user_recordings_by_day_stats_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['user_recordings_by_day_stats_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['user_recordings_by_day_stats_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Recordings_By_Day_Stats_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_recordings_by_day_stats_avg_fields'] = ResolversParentTypes['user_recordings_by_day_stats_avg_fields']> = {
  clean_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  clean_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dirty_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dirty_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  untagged_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  untagged_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Recordings_By_Day_Stats_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_recordings_by_day_stats_max_fields'] = ResolversParentTypes['user_recordings_by_day_stats_max_fields']> = {
  clean_duration?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  clean_recordings?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  day?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  dirty_duration?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  dirty_recordings?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  total_duration?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  total_recordings?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  untagged_duration?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  untagged_recordings?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Recordings_By_Day_Stats_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_recordings_by_day_stats_min_fields'] = ResolversParentTypes['user_recordings_by_day_stats_min_fields']> = {
  clean_duration?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  clean_recordings?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  day?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  dirty_duration?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  dirty_recordings?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  project_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  total_duration?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  total_recordings?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  untagged_duration?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  untagged_recordings?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Recordings_By_Day_Stats_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_recordings_by_day_stats_stddev_fields'] = ResolversParentTypes['user_recordings_by_day_stats_stddev_fields']> = {
  clean_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  clean_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dirty_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dirty_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  untagged_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  untagged_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Recordings_By_Day_Stats_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_recordings_by_day_stats_stddev_pop_fields'] = ResolversParentTypes['user_recordings_by_day_stats_stddev_pop_fields']> = {
  clean_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  clean_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dirty_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dirty_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  untagged_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  untagged_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Recordings_By_Day_Stats_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_recordings_by_day_stats_stddev_samp_fields'] = ResolversParentTypes['user_recordings_by_day_stats_stddev_samp_fields']> = {
  clean_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  clean_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dirty_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dirty_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  untagged_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  untagged_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Recordings_By_Day_Stats_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_recordings_by_day_stats_sum_fields'] = ResolversParentTypes['user_recordings_by_day_stats_sum_fields']> = {
  clean_duration?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  clean_recordings?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  dirty_duration?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  dirty_recordings?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  total_duration?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  total_recordings?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  untagged_duration?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  untagged_recordings?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Recordings_By_Day_Stats_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_recordings_by_day_stats_var_pop_fields'] = ResolversParentTypes['user_recordings_by_day_stats_var_pop_fields']> = {
  clean_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  clean_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dirty_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dirty_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  untagged_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  untagged_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Recordings_By_Day_Stats_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_recordings_by_day_stats_var_samp_fields'] = ResolversParentTypes['user_recordings_by_day_stats_var_samp_fields']> = {
  clean_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  clean_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dirty_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dirty_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  untagged_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  untagged_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Recordings_By_Day_Stats_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_recordings_by_day_stats_variance_fields'] = ResolversParentTypes['user_recordings_by_day_stats_variance_fields']> = {
  clean_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  clean_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dirty_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dirty_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  untagged_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  untagged_recordings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersResolvers<ContextType = any, ParentType extends ResolversParentTypes['users'] = ResolversParentTypes['users']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  observances?: Resolver<Array<ResolversTypes['integration_test_observances']>, ParentType, ContextType, RequireFields<UsersObservancesArgs, never>>;
  observances_aggregate?: Resolver<ResolversTypes['integration_test_observances_aggregate'], ParentType, ContextType, RequireFields<UsersObservances_AggregateArgs, never>>;
  option?: Resolver<Maybe<ResolversTypes['options']>, ParentType, ContextType>;
  project_dataset?: Resolver<Array<ResolversTypes['project_dataset']>, ParentType, ContextType, RequireFields<UsersProject_DatasetArgs, never>>;
  project_dataset_aggregate?: Resolver<ResolversTypes['project_dataset_aggregate'], ParentType, ContextType, RequireFields<UsersProject_Dataset_AggregateArgs, never>>;
  project_users?: Resolver<Array<ResolversTypes['project_user']>, ParentType, ContextType, RequireFields<UsersProject_UsersArgs, never>>;
  project_users_aggregate?: Resolver<ResolversTypes['project_user_aggregate'], ParentType, ContextType, RequireFields<UsersProject_Users_AggregateArgs, never>>;
  recording_recording_tags?: Resolver<Array<ResolversTypes['recording_recording_tag']>, ParentType, ContextType, RequireFields<UsersRecording_Recording_TagsArgs, never>>;
  recordings?: Resolver<Array<ResolversTypes['recordings']>, ParentType, ContextType, RequireFields<UsersRecordingsArgs, never>>;
  recordings_aggregate?: Resolver<ResolversTypes['recordings_aggregate'], ParentType, ContextType, RequireFields<UsersRecordings_AggregateArgs, never>>;
  role_users?: Resolver<Array<ResolversTypes['role_user']>, ParentType, ContextType, RequireFields<UsersRole_UsersArgs, never>>;
  role_users_aggregate?: Resolver<ResolversTypes['role_user_aggregate'], ParentType, ContextType, RequireFields<UsersRole_Users_AggregateArgs, never>>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_option_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['users_aggregate'] = ResolversParentTypes['users_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['users_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['users']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['users_aggregate_fields'] = ResolversParentTypes['users_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Users_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['users_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['users_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['users_max_fields'] = ResolversParentTypes['users_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_option_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['users_min_fields'] = ResolversParentTypes['users_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_option_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['users_mutation_response'] = ResolversParentTypes['users_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['users']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UuidScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['uuid'], any> {
  name: 'uuid';
}

export type Xtensa_ConfigurationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['xtensa_configurations'] = ResolversParentTypes['xtensa_configurations']> = {
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  project?: Resolver<Maybe<ResolversTypes['projects']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Xtensa_Configurations_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['xtensa_configurations_aggregate'] = ResolversParentTypes['xtensa_configurations_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['xtensa_configurations_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['xtensa_configurations']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Xtensa_Configurations_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['xtensa_configurations_aggregate_fields'] = ResolversParentTypes['xtensa_configurations_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Xtensa_Configurations_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['xtensa_configurations_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['xtensa_configurations_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Xtensa_Configurations_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['xtensa_configurations_max_fields'] = ResolversParentTypes['xtensa_configurations_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Xtensa_Configurations_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['xtensa_configurations_min_fields'] = ResolversParentTypes['xtensa_configurations_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Xtensa_Configurations_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['xtensa_configurations_mutation_response'] = ResolversParentTypes['xtensa_configurations_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['xtensa_configurations']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  ElapiSlackChannel?: ElapiSlackChannelResolvers<ContextType>;
  _int2?: GraphQLScalarType;
  _int4?: GraphQLScalarType;
  _text?: GraphQLScalarType;
  _uuid?: GraphQLScalarType;
  audio_driver?: Audio_DriverResolvers<ContextType>;
  audio_driver_mutation_response?: Audio_Driver_Mutation_ResponseResolvers<ContextType>;
  audio_measurements_components?: Audio_Measurements_ComponentsResolvers<ContextType>;
  audio_measurements_components_mutation_response?: Audio_Measurements_Components_Mutation_ResponseResolvers<ContextType>;
  audio_measurements_configurations?: Audio_Measurements_ConfigurationsResolvers<ContextType>;
  audio_measurements_configurations_mutation_response?: Audio_Measurements_Configurations_Mutation_ResponseResolvers<ContextType>;
  audio_measurements_data_types?: Audio_Measurements_Data_TypesResolvers<ContextType>;
  audio_measurements_data_types_aggregate?: Audio_Measurements_Data_Types_AggregateResolvers<ContextType>;
  audio_measurements_data_types_aggregate_fields?: Audio_Measurements_Data_Types_Aggregate_FieldsResolvers<ContextType>;
  audio_measurements_data_types_max_fields?: Audio_Measurements_Data_Types_Max_FieldsResolvers<ContextType>;
  audio_measurements_data_types_min_fields?: Audio_Measurements_Data_Types_Min_FieldsResolvers<ContextType>;
  audio_measurements_master_files?: Audio_Measurements_Master_FilesResolvers<ContextType>;
  audio_measurements_master_files_aggregate?: Audio_Measurements_Master_Files_AggregateResolvers<ContextType>;
  audio_measurements_master_files_aggregate_fields?: Audio_Measurements_Master_Files_Aggregate_FieldsResolvers<ContextType>;
  audio_measurements_master_files_avg_fields?: Audio_Measurements_Master_Files_Avg_FieldsResolvers<ContextType>;
  audio_measurements_master_files_max_fields?: Audio_Measurements_Master_Files_Max_FieldsResolvers<ContextType>;
  audio_measurements_master_files_min_fields?: Audio_Measurements_Master_Files_Min_FieldsResolvers<ContextType>;
  audio_measurements_master_files_mutation_response?: Audio_Measurements_Master_Files_Mutation_ResponseResolvers<ContextType>;
  audio_measurements_master_files_stddev_fields?: Audio_Measurements_Master_Files_Stddev_FieldsResolvers<ContextType>;
  audio_measurements_master_files_stddev_pop_fields?: Audio_Measurements_Master_Files_Stddev_Pop_FieldsResolvers<ContextType>;
  audio_measurements_master_files_stddev_samp_fields?: Audio_Measurements_Master_Files_Stddev_Samp_FieldsResolvers<ContextType>;
  audio_measurements_master_files_sum_fields?: Audio_Measurements_Master_Files_Sum_FieldsResolvers<ContextType>;
  audio_measurements_master_files_var_pop_fields?: Audio_Measurements_Master_Files_Var_Pop_FieldsResolvers<ContextType>;
  audio_measurements_master_files_var_samp_fields?: Audio_Measurements_Master_Files_Var_Samp_FieldsResolvers<ContextType>;
  audio_measurements_master_files_variance_fields?: Audio_Measurements_Master_Files_Variance_FieldsResolvers<ContextType>;
  audio_measurements_orientations?: Audio_Measurements_OrientationsResolvers<ContextType>;
  audio_measurements_orientations_mutation_response?: Audio_Measurements_Orientations_Mutation_ResponseResolvers<ContextType>;
  audio_measurements_payload_recording?: Audio_Measurements_Payload_RecordingResolvers<ContextType>;
  audio_measurements_payload_recording_aggregate?: Audio_Measurements_Payload_Recording_AggregateResolvers<ContextType>;
  audio_measurements_payload_recording_aggregate_fields?: Audio_Measurements_Payload_Recording_Aggregate_FieldsResolvers<ContextType>;
  audio_measurements_payload_recording_max_fields?: Audio_Measurements_Payload_Recording_Max_FieldsResolvers<ContextType>;
  audio_measurements_payload_recording_min_fields?: Audio_Measurements_Payload_Recording_Min_FieldsResolvers<ContextType>;
  audio_measurements_payload_recording_mutation_response?: Audio_Measurements_Payload_Recording_Mutation_ResponseResolvers<ContextType>;
  audio_measurements_placements?: Audio_Measurements_PlacementsResolvers<ContextType>;
  audio_measurements_placements_mutation_response?: Audio_Measurements_Placements_Mutation_ResponseResolvers<ContextType>;
  audio_measurements_project_tasks?: Audio_Measurements_Project_TasksResolvers<ContextType>;
  audio_measurements_project_tasks_aggregate?: Audio_Measurements_Project_Tasks_AggregateResolvers<ContextType>;
  audio_measurements_project_tasks_aggregate_fields?: Audio_Measurements_Project_Tasks_Aggregate_FieldsResolvers<ContextType>;
  audio_measurements_project_tasks_avg_fields?: Audio_Measurements_Project_Tasks_Avg_FieldsResolvers<ContextType>;
  audio_measurements_project_tasks_max_fields?: Audio_Measurements_Project_Tasks_Max_FieldsResolvers<ContextType>;
  audio_measurements_project_tasks_min_fields?: Audio_Measurements_Project_Tasks_Min_FieldsResolvers<ContextType>;
  audio_measurements_project_tasks_mutation_response?: Audio_Measurements_Project_Tasks_Mutation_ResponseResolvers<ContextType>;
  audio_measurements_project_tasks_stddev_fields?: Audio_Measurements_Project_Tasks_Stddev_FieldsResolvers<ContextType>;
  audio_measurements_project_tasks_stddev_pop_fields?: Audio_Measurements_Project_Tasks_Stddev_Pop_FieldsResolvers<ContextType>;
  audio_measurements_project_tasks_stddev_samp_fields?: Audio_Measurements_Project_Tasks_Stddev_Samp_FieldsResolvers<ContextType>;
  audio_measurements_project_tasks_sum_fields?: Audio_Measurements_Project_Tasks_Sum_FieldsResolvers<ContextType>;
  audio_measurements_project_tasks_var_pop_fields?: Audio_Measurements_Project_Tasks_Var_Pop_FieldsResolvers<ContextType>;
  audio_measurements_project_tasks_var_samp_fields?: Audio_Measurements_Project_Tasks_Var_Samp_FieldsResolvers<ContextType>;
  audio_measurements_project_tasks_variance_fields?: Audio_Measurements_Project_Tasks_Variance_FieldsResolvers<ContextType>;
  audio_measurements_recordings?: Audio_Measurements_RecordingsResolvers<ContextType>;
  audio_measurements_recordings_aggregate?: Audio_Measurements_Recordings_AggregateResolvers<ContextType>;
  audio_measurements_recordings_aggregate_fields?: Audio_Measurements_Recordings_Aggregate_FieldsResolvers<ContextType>;
  audio_measurements_recordings_avg_fields?: Audio_Measurements_Recordings_Avg_FieldsResolvers<ContextType>;
  audio_measurements_recordings_max_fields?: Audio_Measurements_Recordings_Max_FieldsResolvers<ContextType>;
  audio_measurements_recordings_min_fields?: Audio_Measurements_Recordings_Min_FieldsResolvers<ContextType>;
  audio_measurements_recordings_mutation_response?: Audio_Measurements_Recordings_Mutation_ResponseResolvers<ContextType>;
  audio_measurements_recordings_stddev_fields?: Audio_Measurements_Recordings_Stddev_FieldsResolvers<ContextType>;
  audio_measurements_recordings_stddev_pop_fields?: Audio_Measurements_Recordings_Stddev_Pop_FieldsResolvers<ContextType>;
  audio_measurements_recordings_stddev_samp_fields?: Audio_Measurements_Recordings_Stddev_Samp_FieldsResolvers<ContextType>;
  audio_measurements_recordings_sum_fields?: Audio_Measurements_Recordings_Sum_FieldsResolvers<ContextType>;
  audio_measurements_recordings_var_pop_fields?: Audio_Measurements_Recordings_Var_Pop_FieldsResolvers<ContextType>;
  audio_measurements_recordings_var_samp_fields?: Audio_Measurements_Recordings_Var_Samp_FieldsResolvers<ContextType>;
  audio_measurements_recordings_variance_fields?: Audio_Measurements_Recordings_Variance_FieldsResolvers<ContextType>;
  audio_measurements_stages?: Audio_Measurements_StagesResolvers<ContextType>;
  audio_measurements_stages_mutation_response?: Audio_Measurements_Stages_Mutation_ResponseResolvers<ContextType>;
  bigint?: GraphQLScalarType;
  bringup_checklist_deliverable?: Bringup_Checklist_DeliverableResolvers<ContextType>;
  bringup_checklist_deliverable_aggregate?: Bringup_Checklist_Deliverable_AggregateResolvers<ContextType>;
  bringup_checklist_deliverable_aggregate_fields?: Bringup_Checklist_Deliverable_Aggregate_FieldsResolvers<ContextType>;
  bringup_checklist_deliverable_max_fields?: Bringup_Checklist_Deliverable_Max_FieldsResolvers<ContextType>;
  bringup_checklist_deliverable_min_fields?: Bringup_Checklist_Deliverable_Min_FieldsResolvers<ContextType>;
  bringup_checklist_deliverable_mutation_response?: Bringup_Checklist_Deliverable_Mutation_ResponseResolvers<ContextType>;
  bringup_checklists?: Bringup_ChecklistsResolvers<ContextType>;
  bringup_checklists_aggregate?: Bringup_Checklists_AggregateResolvers<ContextType>;
  bringup_checklists_aggregate_fields?: Bringup_Checklists_Aggregate_FieldsResolvers<ContextType>;
  bringup_checklists_max_fields?: Bringup_Checklists_Max_FieldsResolvers<ContextType>;
  bringup_checklists_min_fields?: Bringup_Checklists_Min_FieldsResolvers<ContextType>;
  bringup_checklists_mutation_response?: Bringup_Checklists_Mutation_ResponseResolvers<ContextType>;
  bringup_endorsements?: Bringup_EndorsementsResolvers<ContextType>;
  bringup_endorsements_aggregate?: Bringup_Endorsements_AggregateResolvers<ContextType>;
  bringup_endorsements_aggregate_fields?: Bringup_Endorsements_Aggregate_FieldsResolvers<ContextType>;
  bringup_endorsements_max_fields?: Bringup_Endorsements_Max_FieldsResolvers<ContextType>;
  bringup_endorsements_min_fields?: Bringup_Endorsements_Min_FieldsResolvers<ContextType>;
  bringup_endorsements_mutation_response?: Bringup_Endorsements_Mutation_ResponseResolvers<ContextType>;
  bringup_items?: Bringup_ItemsResolvers<ContextType>;
  bringup_items_aggregate?: Bringup_Items_AggregateResolvers<ContextType>;
  bringup_items_aggregate_fields?: Bringup_Items_Aggregate_FieldsResolvers<ContextType>;
  bringup_items_avg_fields?: Bringup_Items_Avg_FieldsResolvers<ContextType>;
  bringup_items_max_fields?: Bringup_Items_Max_FieldsResolvers<ContextType>;
  bringup_items_min_fields?: Bringup_Items_Min_FieldsResolvers<ContextType>;
  bringup_items_mutation_response?: Bringup_Items_Mutation_ResponseResolvers<ContextType>;
  bringup_items_stddev_fields?: Bringup_Items_Stddev_FieldsResolvers<ContextType>;
  bringup_items_stddev_pop_fields?: Bringup_Items_Stddev_Pop_FieldsResolvers<ContextType>;
  bringup_items_stddev_samp_fields?: Bringup_Items_Stddev_Samp_FieldsResolvers<ContextType>;
  bringup_items_sum_fields?: Bringup_Items_Sum_FieldsResolvers<ContextType>;
  bringup_items_var_pop_fields?: Bringup_Items_Var_Pop_FieldsResolvers<ContextType>;
  bringup_items_var_samp_fields?: Bringup_Items_Var_Samp_FieldsResolvers<ContextType>;
  bringup_items_variance_fields?: Bringup_Items_Variance_FieldsResolvers<ContextType>;
  bringup_milestones?: Bringup_MilestonesResolvers<ContextType>;
  bringup_milestones_aggregate?: Bringup_Milestones_AggregateResolvers<ContextType>;
  bringup_milestones_aggregate_fields?: Bringup_Milestones_Aggregate_FieldsResolvers<ContextType>;
  bringup_milestones_max_fields?: Bringup_Milestones_Max_FieldsResolvers<ContextType>;
  bringup_milestones_min_fields?: Bringup_Milestones_Min_FieldsResolvers<ContextType>;
  bringup_milestones_mutation_response?: Bringup_Milestones_Mutation_ResponseResolvers<ContextType>;
  bringup_project_item_comments?: Bringup_Project_Item_CommentsResolvers<ContextType>;
  bringup_project_item_comments_aggregate?: Bringup_Project_Item_Comments_AggregateResolvers<ContextType>;
  bringup_project_item_comments_aggregate_fields?: Bringup_Project_Item_Comments_Aggregate_FieldsResolvers<ContextType>;
  bringup_project_item_comments_max_fields?: Bringup_Project_Item_Comments_Max_FieldsResolvers<ContextType>;
  bringup_project_item_comments_min_fields?: Bringup_Project_Item_Comments_Min_FieldsResolvers<ContextType>;
  bringup_project_item_comments_mutation_response?: Bringup_Project_Item_Comments_Mutation_ResponseResolvers<ContextType>;
  categories?: CategoriesResolvers<ContextType>;
  categories_aggregate?: Categories_AggregateResolvers<ContextType>;
  categories_aggregate_fields?: Categories_Aggregate_FieldsResolvers<ContextType>;
  categories_avg_fields?: Categories_Avg_FieldsResolvers<ContextType>;
  categories_max_fields?: Categories_Max_FieldsResolvers<ContextType>;
  categories_min_fields?: Categories_Min_FieldsResolvers<ContextType>;
  categories_mutation_response?: Categories_Mutation_ResponseResolvers<ContextType>;
  categories_stddev_fields?: Categories_Stddev_FieldsResolvers<ContextType>;
  categories_stddev_pop_fields?: Categories_Stddev_Pop_FieldsResolvers<ContextType>;
  categories_stddev_samp_fields?: Categories_Stddev_Samp_FieldsResolvers<ContextType>;
  categories_sum_fields?: Categories_Sum_FieldsResolvers<ContextType>;
  categories_var_pop_fields?: Categories_Var_Pop_FieldsResolvers<ContextType>;
  categories_var_samp_fields?: Categories_Var_Samp_FieldsResolvers<ContextType>;
  categories_variance_fields?: Categories_Variance_FieldsResolvers<ContextType>;
  context_display_field?: Context_Display_FieldResolvers<ContextType>;
  context_display_field_aggregate?: Context_Display_Field_AggregateResolvers<ContextType>;
  context_display_field_aggregate_fields?: Context_Display_Field_Aggregate_FieldsResolvers<ContextType>;
  context_display_field_avg_fields?: Context_Display_Field_Avg_FieldsResolvers<ContextType>;
  context_display_field_max_fields?: Context_Display_Field_Max_FieldsResolvers<ContextType>;
  context_display_field_min_fields?: Context_Display_Field_Min_FieldsResolvers<ContextType>;
  context_display_field_mutation_response?: Context_Display_Field_Mutation_ResponseResolvers<ContextType>;
  context_display_field_stddev_fields?: Context_Display_Field_Stddev_FieldsResolvers<ContextType>;
  context_display_field_stddev_pop_fields?: Context_Display_Field_Stddev_Pop_FieldsResolvers<ContextType>;
  context_display_field_stddev_samp_fields?: Context_Display_Field_Stddev_Samp_FieldsResolvers<ContextType>;
  context_display_field_sum_fields?: Context_Display_Field_Sum_FieldsResolvers<ContextType>;
  context_display_field_var_pop_fields?: Context_Display_Field_Var_Pop_FieldsResolvers<ContextType>;
  context_display_field_var_samp_fields?: Context_Display_Field_Var_Samp_FieldsResolvers<ContextType>;
  context_display_field_variance_fields?: Context_Display_Field_Variance_FieldsResolvers<ContextType>;
  context_displays?: Context_DisplaysResolvers<ContextType>;
  context_displays_mutation_response?: Context_Displays_Mutation_ResponseResolvers<ContextType>;
  contexts?: ContextsResolvers<ContextType>;
  contexts_aggregate?: Contexts_AggregateResolvers<ContextType>;
  contexts_aggregate_fields?: Contexts_Aggregate_FieldsResolvers<ContextType>;
  contexts_max_fields?: Contexts_Max_FieldsResolvers<ContextType>;
  contexts_min_fields?: Contexts_Min_FieldsResolvers<ContextType>;
  contexts_mutation_response?: Contexts_Mutation_ResponseResolvers<ContextType>;
  customers?: CustomersResolvers<ContextType>;
  customers_aggregate?: Customers_AggregateResolvers<ContextType>;
  customers_aggregate_fields?: Customers_Aggregate_FieldsResolvers<ContextType>;
  customers_max_fields?: Customers_Max_FieldsResolvers<ContextType>;
  customers_min_fields?: Customers_Min_FieldsResolvers<ContextType>;
  customers_mutation_response?: Customers_Mutation_ResponseResolvers<ContextType>;
  date?: GraphQLScalarType;
  deliverables?: DeliverablesResolvers<ContextType>;
  deliverables_aggregate?: Deliverables_AggregateResolvers<ContextType>;
  deliverables_aggregate_fields?: Deliverables_Aggregate_FieldsResolvers<ContextType>;
  deliverables_avg_fields?: Deliverables_Avg_FieldsResolvers<ContextType>;
  deliverables_max_fields?: Deliverables_Max_FieldsResolvers<ContextType>;
  deliverables_min_fields?: Deliverables_Min_FieldsResolvers<ContextType>;
  deliverables_mutation_response?: Deliverables_Mutation_ResponseResolvers<ContextType>;
  deliverables_stddev_fields?: Deliverables_Stddev_FieldsResolvers<ContextType>;
  deliverables_stddev_pop_fields?: Deliverables_Stddev_Pop_FieldsResolvers<ContextType>;
  deliverables_stddev_samp_fields?: Deliverables_Stddev_Samp_FieldsResolvers<ContextType>;
  deliverables_sum_fields?: Deliverables_Sum_FieldsResolvers<ContextType>;
  deliverables_var_pop_fields?: Deliverables_Var_Pop_FieldsResolvers<ContextType>;
  deliverables_var_samp_fields?: Deliverables_Var_Samp_FieldsResolvers<ContextType>;
  deliverables_variance_fields?: Deliverables_Variance_FieldsResolvers<ContextType>;
  deliveries_architectures?: Deliveries_ArchitecturesResolvers<ContextType>;
  deliveries_architectures_mutation_response?: Deliveries_Architectures_Mutation_ResponseResolvers<ContextType>;
  deliveries_artifact_microsoft_whql_submission?: Deliveries_Artifact_Microsoft_Whql_SubmissionResolvers<ContextType>;
  deliveries_artifact_microsoft_whql_submission_aggregate?: Deliveries_Artifact_Microsoft_Whql_Submission_AggregateResolvers<ContextType>;
  deliveries_artifact_microsoft_whql_submission_aggregate_fields?: Deliveries_Artifact_Microsoft_Whql_Submission_Aggregate_FieldsResolvers<ContextType>;
  deliveries_artifact_microsoft_whql_submission_max_fields?: Deliveries_Artifact_Microsoft_Whql_Submission_Max_FieldsResolvers<ContextType>;
  deliveries_artifact_microsoft_whql_submission_min_fields?: Deliveries_Artifact_Microsoft_Whql_Submission_Min_FieldsResolvers<ContextType>;
  deliveries_artifact_microsoft_whql_submission_mutation_response?: Deliveries_Artifact_Microsoft_Whql_Submission_Mutation_ResponseResolvers<ContextType>;
  deliveries_artifact_operating_system?: Deliveries_Artifact_Operating_SystemResolvers<ContextType>;
  deliveries_artifact_operating_system_mutation_response?: Deliveries_Artifact_Operating_System_Mutation_ResponseResolvers<ContextType>;
  deliveries_artifacts?: Deliveries_ArtifactsResolvers<ContextType>;
  deliveries_artifacts_aggregate?: Deliveries_Artifacts_AggregateResolvers<ContextType>;
  deliveries_artifacts_aggregate_fields?: Deliveries_Artifacts_Aggregate_FieldsResolvers<ContextType>;
  deliveries_artifacts_max_fields?: Deliveries_Artifacts_Max_FieldsResolvers<ContextType>;
  deliveries_artifacts_min_fields?: Deliveries_Artifacts_Min_FieldsResolvers<ContextType>;
  deliveries_artifacts_mutation_response?: Deliveries_Artifacts_Mutation_ResponseResolvers<ContextType>;
  deliveries_device_families?: Deliveries_Device_FamiliesResolvers<ContextType>;
  deliveries_device_families_mutation_response?: Deliveries_Device_Families_Mutation_ResponseResolvers<ContextType>;
  deliveries_driver_package?: Deliveries_Driver_PackageResolvers<ContextType>;
  deliveries_driver_package_aggregate?: Deliveries_Driver_Package_AggregateResolvers<ContextType>;
  deliveries_driver_package_aggregate_fields?: Deliveries_Driver_Package_Aggregate_FieldsResolvers<ContextType>;
  deliveries_driver_package_max_fields?: Deliveries_Driver_Package_Max_FieldsResolvers<ContextType>;
  deliveries_driver_package_min_fields?: Deliveries_Driver_Package_Min_FieldsResolvers<ContextType>;
  deliveries_driver_package_mutation_response?: Deliveries_Driver_Package_Mutation_ResponseResolvers<ContextType>;
  deliveries_driver_project?: Deliveries_Driver_ProjectResolvers<ContextType>;
  deliveries_driver_project_mutation_response?: Deliveries_Driver_Project_Mutation_ResponseResolvers<ContextType>;
  deliveries_driver_version_engine?: Deliveries_Driver_Version_EngineResolvers<ContextType>;
  deliveries_driver_version_engine_aggregate?: Deliveries_Driver_Version_Engine_AggregateResolvers<ContextType>;
  deliveries_driver_version_engine_aggregate_fields?: Deliveries_Driver_Version_Engine_Aggregate_FieldsResolvers<ContextType>;
  deliveries_driver_version_engine_max_fields?: Deliveries_Driver_Version_Engine_Max_FieldsResolvers<ContextType>;
  deliveries_driver_version_engine_min_fields?: Deliveries_Driver_Version_Engine_Min_FieldsResolvers<ContextType>;
  deliveries_driver_version_engine_mutation_response?: Deliveries_Driver_Version_Engine_Mutation_ResponseResolvers<ContextType>;
  deliveries_driver_version_package?: Deliveries_Driver_Version_PackageResolvers<ContextType>;
  deliveries_driver_version_package_aggregate?: Deliveries_Driver_Version_Package_AggregateResolvers<ContextType>;
  deliveries_driver_version_package_aggregate_fields?: Deliveries_Driver_Version_Package_Aggregate_FieldsResolvers<ContextType>;
  deliveries_driver_version_package_max_fields?: Deliveries_Driver_Version_Package_Max_FieldsResolvers<ContextType>;
  deliveries_driver_version_package_min_fields?: Deliveries_Driver_Version_Package_Min_FieldsResolvers<ContextType>;
  deliveries_driver_version_package_mutation_response?: Deliveries_Driver_Version_Package_Mutation_ResponseResolvers<ContextType>;
  deliveries_driver_versions?: Deliveries_Driver_VersionsResolvers<ContextType>;
  deliveries_driver_versions_aggregate?: Deliveries_Driver_Versions_AggregateResolvers<ContextType>;
  deliveries_driver_versions_aggregate_fields?: Deliveries_Driver_Versions_Aggregate_FieldsResolvers<ContextType>;
  deliveries_driver_versions_max_fields?: Deliveries_Driver_Versions_Max_FieldsResolvers<ContextType>;
  deliveries_driver_versions_min_fields?: Deliveries_Driver_Versions_Min_FieldsResolvers<ContextType>;
  deliveries_driver_versions_mutation_response?: Deliveries_Driver_Versions_Mutation_ResponseResolvers<ContextType>;
  deliveries_drivers?: Deliveries_DriversResolvers<ContextType>;
  deliveries_drivers_aggregate?: Deliveries_Drivers_AggregateResolvers<ContextType>;
  deliveries_drivers_aggregate_fields?: Deliveries_Drivers_Aggregate_FieldsResolvers<ContextType>;
  deliveries_drivers_max_fields?: Deliveries_Drivers_Max_FieldsResolvers<ContextType>;
  deliveries_drivers_min_fields?: Deliveries_Drivers_Min_FieldsResolvers<ContextType>;
  deliveries_drivers_mutation_response?: Deliveries_Drivers_Mutation_ResponseResolvers<ContextType>;
  deliveries_microsoft_hlk_artifacts?: Deliveries_Microsoft_Hlk_ArtifactsResolvers<ContextType>;
  deliveries_microsoft_hlk_artifacts_aggregate?: Deliveries_Microsoft_Hlk_Artifacts_AggregateResolvers<ContextType>;
  deliveries_microsoft_hlk_artifacts_aggregate_fields?: Deliveries_Microsoft_Hlk_Artifacts_Aggregate_FieldsResolvers<ContextType>;
  deliveries_microsoft_hlk_artifacts_max_fields?: Deliveries_Microsoft_Hlk_Artifacts_Max_FieldsResolvers<ContextType>;
  deliveries_microsoft_hlk_artifacts_min_fields?: Deliveries_Microsoft_Hlk_Artifacts_Min_FieldsResolvers<ContextType>;
  deliveries_microsoft_hlk_artifacts_mutation_response?: Deliveries_Microsoft_Hlk_Artifacts_Mutation_ResponseResolvers<ContextType>;
  deliveries_microsoft_shipping_label_reviews?: Deliveries_Microsoft_Shipping_Label_ReviewsResolvers<ContextType>;
  deliveries_microsoft_shipping_label_reviews_mutation_response?: Deliveries_Microsoft_Shipping_Label_Reviews_Mutation_ResponseResolvers<ContextType>;
  deliveries_microsoft_shipping_labels?: Deliveries_Microsoft_Shipping_LabelsResolvers<ContextType>;
  deliveries_microsoft_shipping_labels_aggregate?: Deliveries_Microsoft_Shipping_Labels_AggregateResolvers<ContextType>;
  deliveries_microsoft_shipping_labels_aggregate_fields?: Deliveries_Microsoft_Shipping_Labels_Aggregate_FieldsResolvers<ContextType>;
  deliveries_microsoft_shipping_labels_max_fields?: Deliveries_Microsoft_Shipping_Labels_Max_FieldsResolvers<ContextType>;
  deliveries_microsoft_shipping_labels_min_fields?: Deliveries_Microsoft_Shipping_Labels_Min_FieldsResolvers<ContextType>;
  deliveries_microsoft_shipping_labels_mutation_response?: Deliveries_Microsoft_Shipping_Labels_Mutation_ResponseResolvers<ContextType>;
  deliveries_microsoft_whql_submissions?: Deliveries_Microsoft_Whql_SubmissionsResolvers<ContextType>;
  deliveries_microsoft_whql_submissions_aggregate?: Deliveries_Microsoft_Whql_Submissions_AggregateResolvers<ContextType>;
  deliveries_microsoft_whql_submissions_aggregate_fields?: Deliveries_Microsoft_Whql_Submissions_Aggregate_FieldsResolvers<ContextType>;
  deliveries_microsoft_whql_submissions_max_fields?: Deliveries_Microsoft_Whql_Submissions_Max_FieldsResolvers<ContextType>;
  deliveries_microsoft_whql_submissions_min_fields?: Deliveries_Microsoft_Whql_Submissions_Min_FieldsResolvers<ContextType>;
  deliveries_microsoft_whql_submissions_mutation_response?: Deliveries_Microsoft_Whql_Submissions_Mutation_ResponseResolvers<ContextType>;
  deliveries_operating_systems?: Deliveries_Operating_SystemsResolvers<ContextType>;
  deliveries_operating_systems_mutation_response?: Deliveries_Operating_Systems_Mutation_ResponseResolvers<ContextType>;
  deliveries_package_operating_systems?: Deliveries_Package_Operating_SystemsResolvers<ContextType>;
  deliveries_package_operating_systems_mutation_response?: Deliveries_Package_Operating_Systems_Mutation_ResponseResolvers<ContextType>;
  deliveries_package_target?: Deliveries_Package_TargetResolvers<ContextType>;
  deliveries_package_target_aggregate?: Deliveries_Package_Target_AggregateResolvers<ContextType>;
  deliveries_package_target_aggregate_fields?: Deliveries_Package_Target_Aggregate_FieldsResolvers<ContextType>;
  deliveries_package_target_max_fields?: Deliveries_Package_Target_Max_FieldsResolvers<ContextType>;
  deliveries_package_target_min_fields?: Deliveries_Package_Target_Min_FieldsResolvers<ContextType>;
  deliveries_package_target_mutation_response?: Deliveries_Package_Target_Mutation_ResponseResolvers<ContextType>;
  deliveries_packages?: Deliveries_PackagesResolvers<ContextType>;
  deliveries_packages_aggregate?: Deliveries_Packages_AggregateResolvers<ContextType>;
  deliveries_packages_aggregate_fields?: Deliveries_Packages_Aggregate_FieldsResolvers<ContextType>;
  deliveries_packages_avg_fields?: Deliveries_Packages_Avg_FieldsResolvers<ContextType>;
  deliveries_packages_max_fields?: Deliveries_Packages_Max_FieldsResolvers<ContextType>;
  deliveries_packages_min_fields?: Deliveries_Packages_Min_FieldsResolvers<ContextType>;
  deliveries_packages_mutation_response?: Deliveries_Packages_Mutation_ResponseResolvers<ContextType>;
  deliveries_packages_stddev_fields?: Deliveries_Packages_Stddev_FieldsResolvers<ContextType>;
  deliveries_packages_stddev_pop_fields?: Deliveries_Packages_Stddev_Pop_FieldsResolvers<ContextType>;
  deliveries_packages_stddev_samp_fields?: Deliveries_Packages_Stddev_Samp_FieldsResolvers<ContextType>;
  deliveries_packages_sum_fields?: Deliveries_Packages_Sum_FieldsResolvers<ContextType>;
  deliveries_packages_var_pop_fields?: Deliveries_Packages_Var_Pop_FieldsResolvers<ContextType>;
  deliveries_packages_var_samp_fields?: Deliveries_Packages_Var_Samp_FieldsResolvers<ContextType>;
  deliveries_packages_variance_fields?: Deliveries_Packages_Variance_FieldsResolvers<ContextType>;
  deliveries_partners?: Deliveries_PartnersResolvers<ContextType>;
  deliveries_partners_mutation_response?: Deliveries_Partners_Mutation_ResponseResolvers<ContextType>;
  deliveries_platforms?: Deliveries_PlatformsResolvers<ContextType>;
  deliveries_platforms_mutation_response?: Deliveries_Platforms_Mutation_ResponseResolvers<ContextType>;
  deliveries_project_configs?: Deliveries_Project_ConfigsResolvers<ContextType>;
  deliveries_project_configs_aggregate?: Deliveries_Project_Configs_AggregateResolvers<ContextType>;
  deliveries_project_configs_aggregate_fields?: Deliveries_Project_Configs_Aggregate_FieldsResolvers<ContextType>;
  deliveries_project_configs_avg_fields?: Deliveries_Project_Configs_Avg_FieldsResolvers<ContextType>;
  deliveries_project_configs_max_fields?: Deliveries_Project_Configs_Max_FieldsResolvers<ContextType>;
  deliveries_project_configs_min_fields?: Deliveries_Project_Configs_Min_FieldsResolvers<ContextType>;
  deliveries_project_configs_mutation_response?: Deliveries_Project_Configs_Mutation_ResponseResolvers<ContextType>;
  deliveries_project_configs_stddev_fields?: Deliveries_Project_Configs_Stddev_FieldsResolvers<ContextType>;
  deliveries_project_configs_stddev_pop_fields?: Deliveries_Project_Configs_Stddev_Pop_FieldsResolvers<ContextType>;
  deliveries_project_configs_stddev_samp_fields?: Deliveries_Project_Configs_Stddev_Samp_FieldsResolvers<ContextType>;
  deliveries_project_configs_sum_fields?: Deliveries_Project_Configs_Sum_FieldsResolvers<ContextType>;
  deliveries_project_configs_var_pop_fields?: Deliveries_Project_Configs_Var_Pop_FieldsResolvers<ContextType>;
  deliveries_project_configs_var_samp_fields?: Deliveries_Project_Configs_Var_Samp_FieldsResolvers<ContextType>;
  deliveries_project_configs_variance_fields?: Deliveries_Project_Configs_Variance_FieldsResolvers<ContextType>;
  deliveries_target_partner?: Deliveries_Target_PartnerResolvers<ContextType>;
  deliveries_target_partner_mutation_response?: Deliveries_Target_Partner_Mutation_ResponseResolvers<ContextType>;
  deliveries_targets?: Deliveries_TargetsResolvers<ContextType>;
  deliveries_targets_aggregate?: Deliveries_Targets_AggregateResolvers<ContextType>;
  deliveries_targets_aggregate_fields?: Deliveries_Targets_Aggregate_FieldsResolvers<ContextType>;
  deliveries_targets_max_fields?: Deliveries_Targets_Max_FieldsResolvers<ContextType>;
  deliveries_targets_min_fields?: Deliveries_Targets_Min_FieldsResolvers<ContextType>;
  deliveries_targets_mutation_response?: Deliveries_Targets_Mutation_ResponseResolvers<ContextType>;
  device_firmware?: Device_FirmwareResolvers<ContextType>;
  device_firmware_mutation_response?: Device_Firmware_Mutation_ResponseResolvers<ContextType>;
  device_project?: Device_ProjectResolvers<ContextType>;
  device_project_aggregate?: Device_Project_AggregateResolvers<ContextType>;
  device_project_aggregate_fields?: Device_Project_Aggregate_FieldsResolvers<ContextType>;
  device_project_max_fields?: Device_Project_Max_FieldsResolvers<ContextType>;
  device_project_min_fields?: Device_Project_Min_FieldsResolvers<ContextType>;
  device_project_mutation_response?: Device_Project_Mutation_ResponseResolvers<ContextType>;
  devices?: DevicesResolvers<ContextType>;
  devices_aggregate?: Devices_AggregateResolvers<ContextType>;
  devices_aggregate_fields?: Devices_Aggregate_FieldsResolvers<ContextType>;
  devices_max_fields?: Devices_Max_FieldsResolvers<ContextType>;
  devices_min_fields?: Devices_Min_FieldsResolvers<ContextType>;
  devices_mutation_response?: Devices_Mutation_ResponseResolvers<ContextType>;
  dynamic_scaling?: Dynamic_ScalingResolvers<ContextType>;
  dynamic_scaling_mutation_response?: Dynamic_Scaling_Mutation_ResponseResolvers<ContextType>;
  engine_opmode?: Engine_OpmodeResolvers<ContextType>;
  engine_opmode_aggregate?: Engine_Opmode_AggregateResolvers<ContextType>;
  engine_opmode_aggregate_fields?: Engine_Opmode_Aggregate_FieldsResolvers<ContextType>;
  engine_opmode_max_fields?: Engine_Opmode_Max_FieldsResolvers<ContextType>;
  engine_opmode_min_fields?: Engine_Opmode_Min_FieldsResolvers<ContextType>;
  engine_opmode_mutation_response?: Engine_Opmode_Mutation_ResponseResolvers<ContextType>;
  engine_opmode_recording?: Engine_Opmode_RecordingResolvers<ContextType>;
  engine_opmode_recording_aggregate?: Engine_Opmode_Recording_AggregateResolvers<ContextType>;
  engine_opmode_recording_aggregate_fields?: Engine_Opmode_Recording_Aggregate_FieldsResolvers<ContextType>;
  engine_opmode_recording_max_fields?: Engine_Opmode_Recording_Max_FieldsResolvers<ContextType>;
  engine_opmode_recording_min_fields?: Engine_Opmode_Recording_Min_FieldsResolvers<ContextType>;
  engine_opmode_recording_mutation_response?: Engine_Opmode_Recording_Mutation_ResponseResolvers<ContextType>;
  engine_types?: Engine_TypesResolvers<ContextType>;
  engine_types_aggregate?: Engine_Types_AggregateResolvers<ContextType>;
  engine_types_aggregate_fields?: Engine_Types_Aggregate_FieldsResolvers<ContextType>;
  engine_types_max_fields?: Engine_Types_Max_FieldsResolvers<ContextType>;
  engine_types_min_fields?: Engine_Types_Min_FieldsResolvers<ContextType>;
  engine_types_mutation_response?: Engine_Types_Mutation_ResponseResolvers<ContextType>;
  engines?: EnginesResolvers<ContextType>;
  engines_aggregate?: Engines_AggregateResolvers<ContextType>;
  engines_aggregate_fields?: Engines_Aggregate_FieldsResolvers<ContextType>;
  engines_avg_fields?: Engines_Avg_FieldsResolvers<ContextType>;
  engines_max_fields?: Engines_Max_FieldsResolvers<ContextType>;
  engines_min_fields?: Engines_Min_FieldsResolvers<ContextType>;
  engines_mutation_response?: Engines_Mutation_ResponseResolvers<ContextType>;
  engines_stddev_fields?: Engines_Stddev_FieldsResolvers<ContextType>;
  engines_stddev_pop_fields?: Engines_Stddev_Pop_FieldsResolvers<ContextType>;
  engines_stddev_samp_fields?: Engines_Stddev_Samp_FieldsResolvers<ContextType>;
  engines_sum_fields?: Engines_Sum_FieldsResolvers<ContextType>;
  engines_var_pop_fields?: Engines_Var_Pop_FieldsResolvers<ContextType>;
  engines_var_samp_fields?: Engines_Var_Samp_FieldsResolvers<ContextType>;
  engines_variance_fields?: Engines_Variance_FieldsResolvers<ContextType>;
  field_option_media?: Field_Option_MediaResolvers<ContextType>;
  field_option_media_aggregate?: Field_Option_Media_AggregateResolvers<ContextType>;
  field_option_media_aggregate_fields?: Field_Option_Media_Aggregate_FieldsResolvers<ContextType>;
  field_option_media_max_fields?: Field_Option_Media_Max_FieldsResolvers<ContextType>;
  field_option_media_min_fields?: Field_Option_Media_Min_FieldsResolvers<ContextType>;
  field_option_media_mutation_response?: Field_Option_Media_Mutation_ResponseResolvers<ContextType>;
  field_options?: Field_OptionsResolvers<ContextType>;
  field_options_aggregate?: Field_Options_AggregateResolvers<ContextType>;
  field_options_aggregate_fields?: Field_Options_Aggregate_FieldsResolvers<ContextType>;
  field_options_max_fields?: Field_Options_Max_FieldsResolvers<ContextType>;
  field_options_min_fields?: Field_Options_Min_FieldsResolvers<ContextType>;
  field_options_mutation_response?: Field_Options_Mutation_ResponseResolvers<ContextType>;
  field_translation?: Field_TranslationResolvers<ContextType>;
  field_translation_aggregate?: Field_Translation_AggregateResolvers<ContextType>;
  field_translation_aggregate_fields?: Field_Translation_Aggregate_FieldsResolvers<ContextType>;
  field_translation_max_fields?: Field_Translation_Max_FieldsResolvers<ContextType>;
  field_translation_min_fields?: Field_Translation_Min_FieldsResolvers<ContextType>;
  field_translation_mutation_response?: Field_Translation_Mutation_ResponseResolvers<ContextType>;
  fields?: FieldsResolvers<ContextType>;
  fields_aggregate?: Fields_AggregateResolvers<ContextType>;
  fields_aggregate_fields?: Fields_Aggregate_FieldsResolvers<ContextType>;
  fields_max_fields?: Fields_Max_FieldsResolvers<ContextType>;
  fields_min_fields?: Fields_Min_FieldsResolvers<ContextType>;
  fields_mutation_response?: Fields_Mutation_ResponseResolvers<ContextType>;
  firmware?: FirmwareResolvers<ContextType>;
  firmware_aggregate?: Firmware_AggregateResolvers<ContextType>;
  firmware_aggregate_fields?: Firmware_Aggregate_FieldsResolvers<ContextType>;
  firmware_max_fields?: Firmware_Max_FieldsResolvers<ContextType>;
  firmware_min_fields?: Firmware_Min_FieldsResolvers<ContextType>;
  firmware_mutation_response?: Firmware_Mutation_ResponseResolvers<ContextType>;
  float8?: GraphQLScalarType;
  ground_truth_configurations?: Ground_Truth_ConfigurationsResolvers<ContextType>;
  ground_truth_configurations_aggregate?: Ground_Truth_Configurations_AggregateResolvers<ContextType>;
  ground_truth_configurations_aggregate_fields?: Ground_Truth_Configurations_Aggregate_FieldsResolvers<ContextType>;
  ground_truth_configurations_max_fields?: Ground_Truth_Configurations_Max_FieldsResolvers<ContextType>;
  ground_truth_configurations_min_fields?: Ground_Truth_Configurations_Min_FieldsResolvers<ContextType>;
  ground_truth_configurations_mutation_response?: Ground_Truth_Configurations_Mutation_ResponseResolvers<ContextType>;
  ground_truth_device_types?: Ground_Truth_Device_TypesResolvers<ContextType>;
  ground_truth_device_types_aggregate?: Ground_Truth_Device_Types_AggregateResolvers<ContextType>;
  ground_truth_device_types_aggregate_fields?: Ground_Truth_Device_Types_Aggregate_FieldsResolvers<ContextType>;
  ground_truth_device_types_max_fields?: Ground_Truth_Device_Types_Max_FieldsResolvers<ContextType>;
  ground_truth_device_types_min_fields?: Ground_Truth_Device_Types_Min_FieldsResolvers<ContextType>;
  ground_truth_device_types_mutation_response?: Ground_Truth_Device_Types_Mutation_ResponseResolvers<ContextType>;
  ground_truth_processing_modes?: Ground_Truth_Processing_ModesResolvers<ContextType>;
  ground_truth_processing_modes_aggregate?: Ground_Truth_Processing_Modes_AggregateResolvers<ContextType>;
  ground_truth_processing_modes_aggregate_fields?: Ground_Truth_Processing_Modes_Aggregate_FieldsResolvers<ContextType>;
  ground_truth_processing_modes_max_fields?: Ground_Truth_Processing_Modes_Max_FieldsResolvers<ContextType>;
  ground_truth_processing_modes_min_fields?: Ground_Truth_Processing_Modes_Min_FieldsResolvers<ContextType>;
  ground_truth_processing_modes_mutation_response?: Ground_Truth_Processing_Modes_Mutation_ResponseResolvers<ContextType>;
  ground_truth_recordings?: Ground_Truth_RecordingsResolvers<ContextType>;
  ground_truth_recordings_aggregate?: Ground_Truth_Recordings_AggregateResolvers<ContextType>;
  ground_truth_recordings_aggregate_fields?: Ground_Truth_Recordings_Aggregate_FieldsResolvers<ContextType>;
  ground_truth_recordings_avg_fields?: Ground_Truth_Recordings_Avg_FieldsResolvers<ContextType>;
  ground_truth_recordings_max_fields?: Ground_Truth_Recordings_Max_FieldsResolvers<ContextType>;
  ground_truth_recordings_min_fields?: Ground_Truth_Recordings_Min_FieldsResolvers<ContextType>;
  ground_truth_recordings_mutation_response?: Ground_Truth_Recordings_Mutation_ResponseResolvers<ContextType>;
  ground_truth_recordings_stddev_fields?: Ground_Truth_Recordings_Stddev_FieldsResolvers<ContextType>;
  ground_truth_recordings_stddev_pop_fields?: Ground_Truth_Recordings_Stddev_Pop_FieldsResolvers<ContextType>;
  ground_truth_recordings_stddev_samp_fields?: Ground_Truth_Recordings_Stddev_Samp_FieldsResolvers<ContextType>;
  ground_truth_recordings_sum_fields?: Ground_Truth_Recordings_Sum_FieldsResolvers<ContextType>;
  ground_truth_recordings_var_pop_fields?: Ground_Truth_Recordings_Var_Pop_FieldsResolvers<ContextType>;
  ground_truth_recordings_var_samp_fields?: Ground_Truth_Recordings_Var_Samp_FieldsResolvers<ContextType>;
  ground_truth_recordings_variance_fields?: Ground_Truth_Recordings_Variance_FieldsResolvers<ContextType>;
  ground_truth_types?: Ground_Truth_TypesResolvers<ContextType>;
  ground_truth_types_aggregate?: Ground_Truth_Types_AggregateResolvers<ContextType>;
  ground_truth_types_aggregate_fields?: Ground_Truth_Types_Aggregate_FieldsResolvers<ContextType>;
  ground_truth_types_avg_fields?: Ground_Truth_Types_Avg_FieldsResolvers<ContextType>;
  ground_truth_types_max_fields?: Ground_Truth_Types_Max_FieldsResolvers<ContextType>;
  ground_truth_types_min_fields?: Ground_Truth_Types_Min_FieldsResolvers<ContextType>;
  ground_truth_types_mutation_response?: Ground_Truth_Types_Mutation_ResponseResolvers<ContextType>;
  ground_truth_types_stddev_fields?: Ground_Truth_Types_Stddev_FieldsResolvers<ContextType>;
  ground_truth_types_stddev_pop_fields?: Ground_Truth_Types_Stddev_Pop_FieldsResolvers<ContextType>;
  ground_truth_types_stddev_samp_fields?: Ground_Truth_Types_Stddev_Samp_FieldsResolvers<ContextType>;
  ground_truth_types_sum_fields?: Ground_Truth_Types_Sum_FieldsResolvers<ContextType>;
  ground_truth_types_var_pop_fields?: Ground_Truth_Types_Var_Pop_FieldsResolvers<ContextType>;
  ground_truth_types_var_samp_fields?: Ground_Truth_Types_Var_Samp_FieldsResolvers<ContextType>;
  ground_truth_types_variance_fields?: Ground_Truth_Types_Variance_FieldsResolvers<ContextType>;
  hypertask_field_option?: Hypertask_Field_OptionResolvers<ContextType>;
  hypertask_field_option_aggregate?: Hypertask_Field_Option_AggregateResolvers<ContextType>;
  hypertask_field_option_aggregate_fields?: Hypertask_Field_Option_Aggregate_FieldsResolvers<ContextType>;
  hypertask_field_option_avg_fields?: Hypertask_Field_Option_Avg_FieldsResolvers<ContextType>;
  hypertask_field_option_max_fields?: Hypertask_Field_Option_Max_FieldsResolvers<ContextType>;
  hypertask_field_option_min_fields?: Hypertask_Field_Option_Min_FieldsResolvers<ContextType>;
  hypertask_field_option_mutation_response?: Hypertask_Field_Option_Mutation_ResponseResolvers<ContextType>;
  hypertask_field_option_stddev_fields?: Hypertask_Field_Option_Stddev_FieldsResolvers<ContextType>;
  hypertask_field_option_stddev_pop_fields?: Hypertask_Field_Option_Stddev_Pop_FieldsResolvers<ContextType>;
  hypertask_field_option_stddev_samp_fields?: Hypertask_Field_Option_Stddev_Samp_FieldsResolvers<ContextType>;
  hypertask_field_option_sum_fields?: Hypertask_Field_Option_Sum_FieldsResolvers<ContextType>;
  hypertask_field_option_var_pop_fields?: Hypertask_Field_Option_Var_Pop_FieldsResolvers<ContextType>;
  hypertask_field_option_var_samp_fields?: Hypertask_Field_Option_Var_Samp_FieldsResolvers<ContextType>;
  hypertask_field_option_variance_fields?: Hypertask_Field_Option_Variance_FieldsResolvers<ContextType>;
  hypertask_task?: Hypertask_TaskResolvers<ContextType>;
  hypertask_task_aggregate?: Hypertask_Task_AggregateResolvers<ContextType>;
  hypertask_task_aggregate_fields?: Hypertask_Task_Aggregate_FieldsResolvers<ContextType>;
  hypertask_task_max_fields?: Hypertask_Task_Max_FieldsResolvers<ContextType>;
  hypertask_task_min_fields?: Hypertask_Task_Min_FieldsResolvers<ContextType>;
  hypertask_task_mutation_response?: Hypertask_Task_Mutation_ResponseResolvers<ContextType>;
  hypertasks?: HypertasksResolvers<ContextType>;
  hypertasks_aggregate?: Hypertasks_AggregateResolvers<ContextType>;
  hypertasks_aggregate_fields?: Hypertasks_Aggregate_FieldsResolvers<ContextType>;
  hypertasks_avg_fields?: Hypertasks_Avg_FieldsResolvers<ContextType>;
  hypertasks_max_fields?: Hypertasks_Max_FieldsResolvers<ContextType>;
  hypertasks_min_fields?: Hypertasks_Min_FieldsResolvers<ContextType>;
  hypertasks_mutation_response?: Hypertasks_Mutation_ResponseResolvers<ContextType>;
  hypertasks_stddev_fields?: Hypertasks_Stddev_FieldsResolvers<ContextType>;
  hypertasks_stddev_pop_fields?: Hypertasks_Stddev_Pop_FieldsResolvers<ContextType>;
  hypertasks_stddev_samp_fields?: Hypertasks_Stddev_Samp_FieldsResolvers<ContextType>;
  hypertasks_sum_fields?: Hypertasks_Sum_FieldsResolvers<ContextType>;
  hypertasks_var_pop_fields?: Hypertasks_Var_Pop_FieldsResolvers<ContextType>;
  hypertasks_var_samp_fields?: Hypertasks_Var_Samp_FieldsResolvers<ContextType>;
  hypertasks_variance_fields?: Hypertasks_Variance_FieldsResolvers<ContextType>;
  instruction_translations?: Instruction_TranslationsResolvers<ContextType>;
  instruction_translations_mutation_response?: Instruction_Translations_Mutation_ResponseResolvers<ContextType>;
  integration_test_expectation_media?: Integration_Test_Expectation_MediaResolvers<ContextType>;
  integration_test_expectation_media_aggregate?: Integration_Test_Expectation_Media_AggregateResolvers<ContextType>;
  integration_test_expectation_media_aggregate_fields?: Integration_Test_Expectation_Media_Aggregate_FieldsResolvers<ContextType>;
  integration_test_expectation_media_max_fields?: Integration_Test_Expectation_Media_Max_FieldsResolvers<ContextType>;
  integration_test_expectation_media_min_fields?: Integration_Test_Expectation_Media_Min_FieldsResolvers<ContextType>;
  integration_test_expectation_media_mutation_response?: Integration_Test_Expectation_Media_Mutation_ResponseResolvers<ContextType>;
  integration_test_expectation_step?: Integration_Test_Expectation_StepResolvers<ContextType>;
  integration_test_expectation_step_aggregate?: Integration_Test_Expectation_Step_AggregateResolvers<ContextType>;
  integration_test_expectation_step_aggregate_fields?: Integration_Test_Expectation_Step_Aggregate_FieldsResolvers<ContextType>;
  integration_test_expectation_step_max_fields?: Integration_Test_Expectation_Step_Max_FieldsResolvers<ContextType>;
  integration_test_expectation_step_min_fields?: Integration_Test_Expectation_Step_Min_FieldsResolvers<ContextType>;
  integration_test_expectation_step_mutation_response?: Integration_Test_Expectation_Step_Mutation_ResponseResolvers<ContextType>;
  integration_test_expectation_translation?: Integration_Test_Expectation_TranslationResolvers<ContextType>;
  integration_test_expectation_translation_aggregate?: Integration_Test_Expectation_Translation_AggregateResolvers<ContextType>;
  integration_test_expectation_translation_aggregate_fields?: Integration_Test_Expectation_Translation_Aggregate_FieldsResolvers<ContextType>;
  integration_test_expectation_translation_max_fields?: Integration_Test_Expectation_Translation_Max_FieldsResolvers<ContextType>;
  integration_test_expectation_translation_min_fields?: Integration_Test_Expectation_Translation_Min_FieldsResolvers<ContextType>;
  integration_test_expectation_translation_mutation_response?: Integration_Test_Expectation_Translation_Mutation_ResponseResolvers<ContextType>;
  integration_test_expectations?: Integration_Test_ExpectationsResolvers<ContextType>;
  integration_test_expectations_aggregate?: Integration_Test_Expectations_AggregateResolvers<ContextType>;
  integration_test_expectations_aggregate_fields?: Integration_Test_Expectations_Aggregate_FieldsResolvers<ContextType>;
  integration_test_expectations_max_fields?: Integration_Test_Expectations_Max_FieldsResolvers<ContextType>;
  integration_test_expectations_min_fields?: Integration_Test_Expectations_Min_FieldsResolvers<ContextType>;
  integration_test_expectations_mutation_response?: Integration_Test_Expectations_Mutation_ResponseResolvers<ContextType>;
  integration_test_instruction_media?: Integration_Test_Instruction_MediaResolvers<ContextType>;
  integration_test_instruction_media_aggregate?: Integration_Test_Instruction_Media_AggregateResolvers<ContextType>;
  integration_test_instruction_media_aggregate_fields?: Integration_Test_Instruction_Media_Aggregate_FieldsResolvers<ContextType>;
  integration_test_instruction_media_max_fields?: Integration_Test_Instruction_Media_Max_FieldsResolvers<ContextType>;
  integration_test_instruction_media_min_fields?: Integration_Test_Instruction_Media_Min_FieldsResolvers<ContextType>;
  integration_test_instruction_media_mutation_response?: Integration_Test_Instruction_Media_Mutation_ResponseResolvers<ContextType>;
  integration_test_instruction_translation?: Integration_Test_Instruction_TranslationResolvers<ContextType>;
  integration_test_instruction_translation_aggregate?: Integration_Test_Instruction_Translation_AggregateResolvers<ContextType>;
  integration_test_instruction_translation_aggregate_fields?: Integration_Test_Instruction_Translation_Aggregate_FieldsResolvers<ContextType>;
  integration_test_instruction_translation_max_fields?: Integration_Test_Instruction_Translation_Max_FieldsResolvers<ContextType>;
  integration_test_instruction_translation_min_fields?: Integration_Test_Instruction_Translation_Min_FieldsResolvers<ContextType>;
  integration_test_instruction_translation_mutation_response?: Integration_Test_Instruction_Translation_Mutation_ResponseResolvers<ContextType>;
  integration_test_instructions?: Integration_Test_InstructionsResolvers<ContextType>;
  integration_test_instructions_aggregate?: Integration_Test_Instructions_AggregateResolvers<ContextType>;
  integration_test_instructions_aggregate_fields?: Integration_Test_Instructions_Aggregate_FieldsResolvers<ContextType>;
  integration_test_instructions_max_fields?: Integration_Test_Instructions_Max_FieldsResolvers<ContextType>;
  integration_test_instructions_min_fields?: Integration_Test_Instructions_Min_FieldsResolvers<ContextType>;
  integration_test_instructions_mutation_response?: Integration_Test_Instructions_Mutation_ResponseResolvers<ContextType>;
  integration_test_observances?: Integration_Test_ObservancesResolvers<ContextType>;
  integration_test_observances_aggregate?: Integration_Test_Observances_AggregateResolvers<ContextType>;
  integration_test_observances_aggregate_fields?: Integration_Test_Observances_Aggregate_FieldsResolvers<ContextType>;
  integration_test_observances_max_fields?: Integration_Test_Observances_Max_FieldsResolvers<ContextType>;
  integration_test_observances_min_fields?: Integration_Test_Observances_Min_FieldsResolvers<ContextType>;
  integration_test_observances_mutation_response?: Integration_Test_Observances_Mutation_ResponseResolvers<ContextType>;
  integration_test_observations?: Integration_Test_ObservationsResolvers<ContextType>;
  integration_test_observations_aggregate?: Integration_Test_Observations_AggregateResolvers<ContextType>;
  integration_test_observations_aggregate_fields?: Integration_Test_Observations_Aggregate_FieldsResolvers<ContextType>;
  integration_test_observations_max_fields?: Integration_Test_Observations_Max_FieldsResolvers<ContextType>;
  integration_test_observations_min_fields?: Integration_Test_Observations_Min_FieldsResolvers<ContextType>;
  integration_test_observations_mutation_response?: Integration_Test_Observations_Mutation_ResponseResolvers<ContextType>;
  integration_test_project_sequence?: Integration_Test_Project_SequenceResolvers<ContextType>;
  integration_test_project_sequence_aggregate?: Integration_Test_Project_Sequence_AggregateResolvers<ContextType>;
  integration_test_project_sequence_aggregate_fields?: Integration_Test_Project_Sequence_Aggregate_FieldsResolvers<ContextType>;
  integration_test_project_sequence_max_fields?: Integration_Test_Project_Sequence_Max_FieldsResolvers<ContextType>;
  integration_test_project_sequence_min_fields?: Integration_Test_Project_Sequence_Min_FieldsResolvers<ContextType>;
  integration_test_project_sequence_mutation_response?: Integration_Test_Project_Sequence_Mutation_ResponseResolvers<ContextType>;
  integration_test_sequences?: Integration_Test_SequencesResolvers<ContextType>;
  integration_test_sequences_aggregate?: Integration_Test_Sequences_AggregateResolvers<ContextType>;
  integration_test_sequences_aggregate_fields?: Integration_Test_Sequences_Aggregate_FieldsResolvers<ContextType>;
  integration_test_sequences_max_fields?: Integration_Test_Sequences_Max_FieldsResolvers<ContextType>;
  integration_test_sequences_min_fields?: Integration_Test_Sequences_Min_FieldsResolvers<ContextType>;
  integration_test_sequences_mutation_response?: Integration_Test_Sequences_Mutation_ResponseResolvers<ContextType>;
  integration_test_steps?: Integration_Test_StepsResolvers<ContextType>;
  integration_test_steps_aggregate?: Integration_Test_Steps_AggregateResolvers<ContextType>;
  integration_test_steps_aggregate_fields?: Integration_Test_Steps_Aggregate_FieldsResolvers<ContextType>;
  integration_test_steps_avg_fields?: Integration_Test_Steps_Avg_FieldsResolvers<ContextType>;
  integration_test_steps_max_fields?: Integration_Test_Steps_Max_FieldsResolvers<ContextType>;
  integration_test_steps_min_fields?: Integration_Test_Steps_Min_FieldsResolvers<ContextType>;
  integration_test_steps_mutation_response?: Integration_Test_Steps_Mutation_ResponseResolvers<ContextType>;
  integration_test_steps_stddev_fields?: Integration_Test_Steps_Stddev_FieldsResolvers<ContextType>;
  integration_test_steps_stddev_pop_fields?: Integration_Test_Steps_Stddev_Pop_FieldsResolvers<ContextType>;
  integration_test_steps_stddev_samp_fields?: Integration_Test_Steps_Stddev_Samp_FieldsResolvers<ContextType>;
  integration_test_steps_sum_fields?: Integration_Test_Steps_Sum_FieldsResolvers<ContextType>;
  integration_test_steps_var_pop_fields?: Integration_Test_Steps_Var_Pop_FieldsResolvers<ContextType>;
  integration_test_steps_var_samp_fields?: Integration_Test_Steps_Var_Samp_FieldsResolvers<ContextType>;
  integration_test_steps_variance_fields?: Integration_Test_Steps_Variance_FieldsResolvers<ContextType>;
  json?: GraphQLScalarType;
  jsonb?: GraphQLScalarType;
  languages?: LanguagesResolvers<ContextType>;
  languages_mutation_response?: Languages_Mutation_ResponseResolvers<ContextType>;
  machine_learning_blobs?: Machine_Learning_BlobsResolvers<ContextType>;
  machine_learning_blobs_aggregate?: Machine_Learning_Blobs_AggregateResolvers<ContextType>;
  machine_learning_blobs_aggregate_fields?: Machine_Learning_Blobs_Aggregate_FieldsResolvers<ContextType>;
  machine_learning_blobs_avg_fields?: Machine_Learning_Blobs_Avg_FieldsResolvers<ContextType>;
  machine_learning_blobs_max_fields?: Machine_Learning_Blobs_Max_FieldsResolvers<ContextType>;
  machine_learning_blobs_min_fields?: Machine_Learning_Blobs_Min_FieldsResolvers<ContextType>;
  machine_learning_blobs_mutation_response?: Machine_Learning_Blobs_Mutation_ResponseResolvers<ContextType>;
  machine_learning_blobs_stddev_fields?: Machine_Learning_Blobs_Stddev_FieldsResolvers<ContextType>;
  machine_learning_blobs_stddev_pop_fields?: Machine_Learning_Blobs_Stddev_Pop_FieldsResolvers<ContextType>;
  machine_learning_blobs_stddev_samp_fields?: Machine_Learning_Blobs_Stddev_Samp_FieldsResolvers<ContextType>;
  machine_learning_blobs_sum_fields?: Machine_Learning_Blobs_Sum_FieldsResolvers<ContextType>;
  machine_learning_blobs_var_pop_fields?: Machine_Learning_Blobs_Var_Pop_FieldsResolvers<ContextType>;
  machine_learning_blobs_var_samp_fields?: Machine_Learning_Blobs_Var_Samp_FieldsResolvers<ContextType>;
  machine_learning_blobs_variance_fields?: Machine_Learning_Blobs_Variance_FieldsResolvers<ContextType>;
  machine_learning_dataset_feature?: Machine_Learning_Dataset_FeatureResolvers<ContextType>;
  machine_learning_dataset_feature_aggregate?: Machine_Learning_Dataset_Feature_AggregateResolvers<ContextType>;
  machine_learning_dataset_feature_aggregate_fields?: Machine_Learning_Dataset_Feature_Aggregate_FieldsResolvers<ContextType>;
  machine_learning_dataset_feature_max_fields?: Machine_Learning_Dataset_Feature_Max_FieldsResolvers<ContextType>;
  machine_learning_dataset_feature_min_fields?: Machine_Learning_Dataset_Feature_Min_FieldsResolvers<ContextType>;
  machine_learning_dataset_feature_mutation_response?: Machine_Learning_Dataset_Feature_Mutation_ResponseResolvers<ContextType>;
  machine_learning_datasets?: Machine_Learning_DatasetsResolvers<ContextType>;
  machine_learning_datasets_aggregate?: Machine_Learning_Datasets_AggregateResolvers<ContextType>;
  machine_learning_datasets_aggregate_fields?: Machine_Learning_Datasets_Aggregate_FieldsResolvers<ContextType>;
  machine_learning_datasets_avg_fields?: Machine_Learning_Datasets_Avg_FieldsResolvers<ContextType>;
  machine_learning_datasets_max_fields?: Machine_Learning_Datasets_Max_FieldsResolvers<ContextType>;
  machine_learning_datasets_min_fields?: Machine_Learning_Datasets_Min_FieldsResolvers<ContextType>;
  machine_learning_datasets_mutation_response?: Machine_Learning_Datasets_Mutation_ResponseResolvers<ContextType>;
  machine_learning_datasets_stddev_fields?: Machine_Learning_Datasets_Stddev_FieldsResolvers<ContextType>;
  machine_learning_datasets_stddev_pop_fields?: Machine_Learning_Datasets_Stddev_Pop_FieldsResolvers<ContextType>;
  machine_learning_datasets_stddev_samp_fields?: Machine_Learning_Datasets_Stddev_Samp_FieldsResolvers<ContextType>;
  machine_learning_datasets_sum_fields?: Machine_Learning_Datasets_Sum_FieldsResolvers<ContextType>;
  machine_learning_datasets_var_pop_fields?: Machine_Learning_Datasets_Var_Pop_FieldsResolvers<ContextType>;
  machine_learning_datasets_var_samp_fields?: Machine_Learning_Datasets_Var_Samp_FieldsResolvers<ContextType>;
  machine_learning_datasets_variance_fields?: Machine_Learning_Datasets_Variance_FieldsResolvers<ContextType>;
  machine_learning_features?: Machine_Learning_FeaturesResolvers<ContextType>;
  machine_learning_features_mutation_response?: Machine_Learning_Features_Mutation_ResponseResolvers<ContextType>;
  machine_learning_model_blob?: Machine_Learning_Model_BlobResolvers<ContextType>;
  machine_learning_model_blob_mutation_response?: Machine_Learning_Model_Blob_Mutation_ResponseResolvers<ContextType>;
  machine_learning_model_dataset?: Machine_Learning_Model_DatasetResolvers<ContextType>;
  machine_learning_model_dataset_mutation_response?: Machine_Learning_Model_Dataset_Mutation_ResponseResolvers<ContextType>;
  machine_learning_model_recording?: Machine_Learning_Model_RecordingResolvers<ContextType>;
  machine_learning_model_recording_aggregate?: Machine_Learning_Model_Recording_AggregateResolvers<ContextType>;
  machine_learning_model_recording_aggregate_fields?: Machine_Learning_Model_Recording_Aggregate_FieldsResolvers<ContextType>;
  machine_learning_model_recording_max_fields?: Machine_Learning_Model_Recording_Max_FieldsResolvers<ContextType>;
  machine_learning_model_recording_min_fields?: Machine_Learning_Model_Recording_Min_FieldsResolvers<ContextType>;
  machine_learning_model_recording_mutation_response?: Machine_Learning_Model_Recording_Mutation_ResponseResolvers<ContextType>;
  machine_learning_model_types?: Machine_Learning_Model_TypesResolvers<ContextType>;
  machine_learning_models?: Machine_Learning_ModelsResolvers<ContextType>;
  machine_learning_models_mutation_response?: Machine_Learning_Models_Mutation_ResponseResolvers<ContextType>;
  media?: MediaResolvers<ContextType>;
  media_aggregate?: Media_AggregateResolvers<ContextType>;
  media_aggregate_fields?: Media_Aggregate_FieldsResolvers<ContextType>;
  media_max_fields?: Media_Max_FieldsResolvers<ContextType>;
  media_min_fields?: Media_Min_FieldsResolvers<ContextType>;
  media_mutation_response?: Media_Mutation_ResponseResolvers<ContextType>;
  media_type?: Media_TypeResolvers<ContextType>;
  mode_project?: Mode_ProjectResolvers<ContextType>;
  mode_project_aggregate?: Mode_Project_AggregateResolvers<ContextType>;
  mode_project_aggregate_fields?: Mode_Project_Aggregate_FieldsResolvers<ContextType>;
  mode_project_max_fields?: Mode_Project_Max_FieldsResolvers<ContextType>;
  mode_project_min_fields?: Mode_Project_Min_FieldsResolvers<ContextType>;
  mode_project_mutation_response?: Mode_Project_Mutation_ResponseResolvers<ContextType>;
  model_name_hashes?: Model_Name_HashesResolvers<ContextType>;
  model_name_hashes_aggregate?: Model_Name_Hashes_AggregateResolvers<ContextType>;
  model_name_hashes_aggregate_fields?: Model_Name_Hashes_Aggregate_FieldsResolvers<ContextType>;
  model_name_hashes_max_fields?: Model_Name_Hashes_Max_FieldsResolvers<ContextType>;
  model_name_hashes_min_fields?: Model_Name_Hashes_Min_FieldsResolvers<ContextType>;
  model_name_hashes_mutation_response?: Model_Name_Hashes_Mutation_ResponseResolvers<ContextType>;
  models?: ModelsResolvers<ContextType>;
  models_aggregate?: Models_AggregateResolvers<ContextType>;
  models_aggregate_fields?: Models_Aggregate_FieldsResolvers<ContextType>;
  models_max_fields?: Models_Max_FieldsResolvers<ContextType>;
  models_min_fields?: Models_Min_FieldsResolvers<ContextType>;
  models_mutation_response?: Models_Mutation_ResponseResolvers<ContextType>;
  mutation_root?: Mutation_RootResolvers<ContextType>;
  numeric?: GraphQLScalarType;
  opmode_profile?: Opmode_ProfileResolvers<ContextType>;
  opmode_profile_mutation_response?: Opmode_Profile_Mutation_ResponseResolvers<ContextType>;
  opmode_project?: Opmode_ProjectResolvers<ContextType>;
  opmode_project_aggregate?: Opmode_Project_AggregateResolvers<ContextType>;
  opmode_project_aggregate_fields?: Opmode_Project_Aggregate_FieldsResolvers<ContextType>;
  opmode_project_max_fields?: Opmode_Project_Max_FieldsResolvers<ContextType>;
  opmode_project_min_fields?: Opmode_Project_Min_FieldsResolvers<ContextType>;
  opmode_project_mutation_response?: Opmode_Project_Mutation_ResponseResolvers<ContextType>;
  opmodes?: OpmodesResolvers<ContextType>;
  opmodes_aggregate?: Opmodes_AggregateResolvers<ContextType>;
  opmodes_aggregate_fields?: Opmodes_Aggregate_FieldsResolvers<ContextType>;
  opmodes_avg_fields?: Opmodes_Avg_FieldsResolvers<ContextType>;
  opmodes_max_fields?: Opmodes_Max_FieldsResolvers<ContextType>;
  opmodes_min_fields?: Opmodes_Min_FieldsResolvers<ContextType>;
  opmodes_mutation_response?: Opmodes_Mutation_ResponseResolvers<ContextType>;
  opmodes_stddev_fields?: Opmodes_Stddev_FieldsResolvers<ContextType>;
  opmodes_stddev_pop_fields?: Opmodes_Stddev_Pop_FieldsResolvers<ContextType>;
  opmodes_stddev_samp_fields?: Opmodes_Stddev_Samp_FieldsResolvers<ContextType>;
  opmodes_sum_fields?: Opmodes_Sum_FieldsResolvers<ContextType>;
  opmodes_var_pop_fields?: Opmodes_Var_Pop_FieldsResolvers<ContextType>;
  opmodes_var_samp_fields?: Opmodes_Var_Samp_FieldsResolvers<ContextType>;
  opmodes_variance_fields?: Opmodes_Variance_FieldsResolvers<ContextType>;
  option_media?: Option_MediaResolvers<ContextType>;
  option_media_aggregate?: Option_Media_AggregateResolvers<ContextType>;
  option_media_aggregate_fields?: Option_Media_Aggregate_FieldsResolvers<ContextType>;
  option_media_max_fields?: Option_Media_Max_FieldsResolvers<ContextType>;
  option_media_min_fields?: Option_Media_Min_FieldsResolvers<ContextType>;
  option_media_mutation_response?: Option_Media_Mutation_ResponseResolvers<ContextType>;
  option_translation?: Option_TranslationResolvers<ContextType>;
  option_translation_aggregate?: Option_Translation_AggregateResolvers<ContextType>;
  option_translation_aggregate_fields?: Option_Translation_Aggregate_FieldsResolvers<ContextType>;
  option_translation_max_fields?: Option_Translation_Max_FieldsResolvers<ContextType>;
  option_translation_min_fields?: Option_Translation_Min_FieldsResolvers<ContextType>;
  option_translation_mutation_response?: Option_Translation_Mutation_ResponseResolvers<ContextType>;
  options?: OptionsResolvers<ContextType>;
  options_aggregate?: Options_AggregateResolvers<ContextType>;
  options_aggregate_fields?: Options_Aggregate_FieldsResolvers<ContextType>;
  options_max_fields?: Options_Max_FieldsResolvers<ContextType>;
  options_min_fields?: Options_Min_FieldsResolvers<ContextType>;
  options_mutation_response?: Options_Mutation_ResponseResolvers<ContextType>;
  payload_recording?: Payload_RecordingResolvers<ContextType>;
  payload_recording_aggregate?: Payload_Recording_AggregateResolvers<ContextType>;
  payload_recording_aggregate_fields?: Payload_Recording_Aggregate_FieldsResolvers<ContextType>;
  payload_recording_max_fields?: Payload_Recording_Max_FieldsResolvers<ContextType>;
  payload_recording_min_fields?: Payload_Recording_Min_FieldsResolvers<ContextType>;
  payload_recording_mutation_response?: Payload_Recording_Mutation_ResponseResolvers<ContextType>;
  payload_types?: Payload_TypesResolvers<ContextType>;
  payload_types_aggregate?: Payload_Types_AggregateResolvers<ContextType>;
  payload_types_aggregate_fields?: Payload_Types_Aggregate_FieldsResolvers<ContextType>;
  payload_types_max_fields?: Payload_Types_Max_FieldsResolvers<ContextType>;
  payload_types_min_fields?: Payload_Types_Min_FieldsResolvers<ContextType>;
  payload_types_mutation_response?: Payload_Types_Mutation_ResponseResolvers<ContextType>;
  payloads?: PayloadsResolvers<ContextType>;
  payloads_aggregate?: Payloads_AggregateResolvers<ContextType>;
  payloads_aggregate_fields?: Payloads_Aggregate_FieldsResolvers<ContextType>;
  payloads_avg_fields?: Payloads_Avg_FieldsResolvers<ContextType>;
  payloads_max_fields?: Payloads_Max_FieldsResolvers<ContextType>;
  payloads_min_fields?: Payloads_Min_FieldsResolvers<ContextType>;
  payloads_mutation_response?: Payloads_Mutation_ResponseResolvers<ContextType>;
  payloads_stddev_fields?: Payloads_Stddev_FieldsResolvers<ContextType>;
  payloads_stddev_pop_fields?: Payloads_Stddev_Pop_FieldsResolvers<ContextType>;
  payloads_stddev_samp_fields?: Payloads_Stddev_Samp_FieldsResolvers<ContextType>;
  payloads_sum_fields?: Payloads_Sum_FieldsResolvers<ContextType>;
  payloads_var_pop_fields?: Payloads_Var_Pop_FieldsResolvers<ContextType>;
  payloads_var_samp_fields?: Payloads_Var_Samp_FieldsResolvers<ContextType>;
  payloads_variance_fields?: Payloads_Variance_FieldsResolvers<ContextType>;
  performance_ratio_metric_contributions?: Performance_Ratio_Metric_ContributionsResolvers<ContextType>;
  performance_ratio_metric_contributions_aggregate?: Performance_Ratio_Metric_Contributions_AggregateResolvers<ContextType>;
  performance_ratio_metric_contributions_aggregate_fields?: Performance_Ratio_Metric_Contributions_Aggregate_FieldsResolvers<ContextType>;
  performance_ratio_metric_contributions_avg_fields?: Performance_Ratio_Metric_Contributions_Avg_FieldsResolvers<ContextType>;
  performance_ratio_metric_contributions_max_fields?: Performance_Ratio_Metric_Contributions_Max_FieldsResolvers<ContextType>;
  performance_ratio_metric_contributions_min_fields?: Performance_Ratio_Metric_Contributions_Min_FieldsResolvers<ContextType>;
  performance_ratio_metric_contributions_mutation_response?: Performance_Ratio_Metric_Contributions_Mutation_ResponseResolvers<ContextType>;
  performance_ratio_metric_contributions_stddev_fields?: Performance_Ratio_Metric_Contributions_Stddev_FieldsResolvers<ContextType>;
  performance_ratio_metric_contributions_stddev_pop_fields?: Performance_Ratio_Metric_Contributions_Stddev_Pop_FieldsResolvers<ContextType>;
  performance_ratio_metric_contributions_stddev_samp_fields?: Performance_Ratio_Metric_Contributions_Stddev_Samp_FieldsResolvers<ContextType>;
  performance_ratio_metric_contributions_sum_fields?: Performance_Ratio_Metric_Contributions_Sum_FieldsResolvers<ContextType>;
  performance_ratio_metric_contributions_var_pop_fields?: Performance_Ratio_Metric_Contributions_Var_Pop_FieldsResolvers<ContextType>;
  performance_ratio_metric_contributions_var_samp_fields?: Performance_Ratio_Metric_Contributions_Var_Samp_FieldsResolvers<ContextType>;
  performance_ratio_metric_contributions_variance_fields?: Performance_Ratio_Metric_Contributions_Variance_FieldsResolvers<ContextType>;
  performance_ratio_metrics?: Performance_Ratio_MetricsResolvers<ContextType>;
  performance_ratio_metrics_aggregate?: Performance_Ratio_Metrics_AggregateResolvers<ContextType>;
  performance_ratio_metrics_aggregate_fields?: Performance_Ratio_Metrics_Aggregate_FieldsResolvers<ContextType>;
  performance_ratio_metrics_avg_fields?: Performance_Ratio_Metrics_Avg_FieldsResolvers<ContextType>;
  performance_ratio_metrics_max_fields?: Performance_Ratio_Metrics_Max_FieldsResolvers<ContextType>;
  performance_ratio_metrics_min_fields?: Performance_Ratio_Metrics_Min_FieldsResolvers<ContextType>;
  performance_ratio_metrics_mutation_response?: Performance_Ratio_Metrics_Mutation_ResponseResolvers<ContextType>;
  performance_ratio_metrics_stddev_fields?: Performance_Ratio_Metrics_Stddev_FieldsResolvers<ContextType>;
  performance_ratio_metrics_stddev_pop_fields?: Performance_Ratio_Metrics_Stddev_Pop_FieldsResolvers<ContextType>;
  performance_ratio_metrics_stddev_samp_fields?: Performance_Ratio_Metrics_Stddev_Samp_FieldsResolvers<ContextType>;
  performance_ratio_metrics_sum_fields?: Performance_Ratio_Metrics_Sum_FieldsResolvers<ContextType>;
  performance_ratio_metrics_var_pop_fields?: Performance_Ratio_Metrics_Var_Pop_FieldsResolvers<ContextType>;
  performance_ratio_metrics_var_samp_fields?: Performance_Ratio_Metrics_Var_Samp_FieldsResolvers<ContextType>;
  performance_ratio_metrics_variance_fields?: Performance_Ratio_Metrics_Variance_FieldsResolvers<ContextType>;
  performance_report_deliverable_template?: Performance_Report_Deliverable_TemplateResolvers<ContextType>;
  performance_report_deliverable_template_aggregate?: Performance_Report_Deliverable_Template_AggregateResolvers<ContextType>;
  performance_report_deliverable_template_aggregate_fields?: Performance_Report_Deliverable_Template_Aggregate_FieldsResolvers<ContextType>;
  performance_report_deliverable_template_avg_fields?: Performance_Report_Deliverable_Template_Avg_FieldsResolvers<ContextType>;
  performance_report_deliverable_template_max_fields?: Performance_Report_Deliverable_Template_Max_FieldsResolvers<ContextType>;
  performance_report_deliverable_template_min_fields?: Performance_Report_Deliverable_Template_Min_FieldsResolvers<ContextType>;
  performance_report_deliverable_template_mutation_response?: Performance_Report_Deliverable_Template_Mutation_ResponseResolvers<ContextType>;
  performance_report_deliverable_template_stddev_fields?: Performance_Report_Deliverable_Template_Stddev_FieldsResolvers<ContextType>;
  performance_report_deliverable_template_stddev_pop_fields?: Performance_Report_Deliverable_Template_Stddev_Pop_FieldsResolvers<ContextType>;
  performance_report_deliverable_template_stddev_samp_fields?: Performance_Report_Deliverable_Template_Stddev_Samp_FieldsResolvers<ContextType>;
  performance_report_deliverable_template_sum_fields?: Performance_Report_Deliverable_Template_Sum_FieldsResolvers<ContextType>;
  performance_report_deliverable_template_var_pop_fields?: Performance_Report_Deliverable_Template_Var_Pop_FieldsResolvers<ContextType>;
  performance_report_deliverable_template_var_samp_fields?: Performance_Report_Deliverable_Template_Var_Samp_FieldsResolvers<ContextType>;
  performance_report_deliverable_template_variance_fields?: Performance_Report_Deliverable_Template_Variance_FieldsResolvers<ContextType>;
  performance_report_pivot_types?: Performance_Report_Pivot_TypesResolvers<ContextType>;
  performance_report_pivot_types_aggregate?: Performance_Report_Pivot_Types_AggregateResolvers<ContextType>;
  performance_report_pivot_types_aggregate_fields?: Performance_Report_Pivot_Types_Aggregate_FieldsResolvers<ContextType>;
  performance_report_pivot_types_max_fields?: Performance_Report_Pivot_Types_Max_FieldsResolvers<ContextType>;
  performance_report_pivot_types_min_fields?: Performance_Report_Pivot_Types_Min_FieldsResolvers<ContextType>;
  performance_report_pivot_types_mutation_response?: Performance_Report_Pivot_Types_Mutation_ResponseResolvers<ContextType>;
  performance_report_section_pivot_field?: Performance_Report_Section_Pivot_FieldResolvers<ContextType>;
  performance_report_section_pivot_field_aggregate?: Performance_Report_Section_Pivot_Field_AggregateResolvers<ContextType>;
  performance_report_section_pivot_field_aggregate_fields?: Performance_Report_Section_Pivot_Field_Aggregate_FieldsResolvers<ContextType>;
  performance_report_section_pivot_field_avg_fields?: Performance_Report_Section_Pivot_Field_Avg_FieldsResolvers<ContextType>;
  performance_report_section_pivot_field_max_fields?: Performance_Report_Section_Pivot_Field_Max_FieldsResolvers<ContextType>;
  performance_report_section_pivot_field_min_fields?: Performance_Report_Section_Pivot_Field_Min_FieldsResolvers<ContextType>;
  performance_report_section_pivot_field_mutation_response?: Performance_Report_Section_Pivot_Field_Mutation_ResponseResolvers<ContextType>;
  performance_report_section_pivot_field_stddev_fields?: Performance_Report_Section_Pivot_Field_Stddev_FieldsResolvers<ContextType>;
  performance_report_section_pivot_field_stddev_pop_fields?: Performance_Report_Section_Pivot_Field_Stddev_Pop_FieldsResolvers<ContextType>;
  performance_report_section_pivot_field_stddev_samp_fields?: Performance_Report_Section_Pivot_Field_Stddev_Samp_FieldsResolvers<ContextType>;
  performance_report_section_pivot_field_sum_fields?: Performance_Report_Section_Pivot_Field_Sum_FieldsResolvers<ContextType>;
  performance_report_section_pivot_field_var_pop_fields?: Performance_Report_Section_Pivot_Field_Var_Pop_FieldsResolvers<ContextType>;
  performance_report_section_pivot_field_var_samp_fields?: Performance_Report_Section_Pivot_Field_Var_Samp_FieldsResolvers<ContextType>;
  performance_report_section_pivot_field_variance_fields?: Performance_Report_Section_Pivot_Field_Variance_FieldsResolvers<ContextType>;
  performance_report_section_ratio_metric?: Performance_Report_Section_Ratio_MetricResolvers<ContextType>;
  performance_report_section_ratio_metric_aggregate?: Performance_Report_Section_Ratio_Metric_AggregateResolvers<ContextType>;
  performance_report_section_ratio_metric_aggregate_fields?: Performance_Report_Section_Ratio_Metric_Aggregate_FieldsResolvers<ContextType>;
  performance_report_section_ratio_metric_avg_fields?: Performance_Report_Section_Ratio_Metric_Avg_FieldsResolvers<ContextType>;
  performance_report_section_ratio_metric_max_fields?: Performance_Report_Section_Ratio_Metric_Max_FieldsResolvers<ContextType>;
  performance_report_section_ratio_metric_min_fields?: Performance_Report_Section_Ratio_Metric_Min_FieldsResolvers<ContextType>;
  performance_report_section_ratio_metric_mutation_response?: Performance_Report_Section_Ratio_Metric_Mutation_ResponseResolvers<ContextType>;
  performance_report_section_ratio_metric_stddev_fields?: Performance_Report_Section_Ratio_Metric_Stddev_FieldsResolvers<ContextType>;
  performance_report_section_ratio_metric_stddev_pop_fields?: Performance_Report_Section_Ratio_Metric_Stddev_Pop_FieldsResolvers<ContextType>;
  performance_report_section_ratio_metric_stddev_samp_fields?: Performance_Report_Section_Ratio_Metric_Stddev_Samp_FieldsResolvers<ContextType>;
  performance_report_section_ratio_metric_sum_fields?: Performance_Report_Section_Ratio_Metric_Sum_FieldsResolvers<ContextType>;
  performance_report_section_ratio_metric_var_pop_fields?: Performance_Report_Section_Ratio_Metric_Var_Pop_FieldsResolvers<ContextType>;
  performance_report_section_ratio_metric_var_samp_fields?: Performance_Report_Section_Ratio_Metric_Var_Samp_FieldsResolvers<ContextType>;
  performance_report_section_ratio_metric_variance_fields?: Performance_Report_Section_Ratio_Metric_Variance_FieldsResolvers<ContextType>;
  performance_report_section_task_blacklist?: Performance_Report_Section_Task_BlacklistResolvers<ContextType>;
  performance_report_section_task_blacklist_aggregate?: Performance_Report_Section_Task_Blacklist_AggregateResolvers<ContextType>;
  performance_report_section_task_blacklist_aggregate_fields?: Performance_Report_Section_Task_Blacklist_Aggregate_FieldsResolvers<ContextType>;
  performance_report_section_task_blacklist_max_fields?: Performance_Report_Section_Task_Blacklist_Max_FieldsResolvers<ContextType>;
  performance_report_section_task_blacklist_min_fields?: Performance_Report_Section_Task_Blacklist_Min_FieldsResolvers<ContextType>;
  performance_report_section_task_blacklist_mutation_response?: Performance_Report_Section_Task_Blacklist_Mutation_ResponseResolvers<ContextType>;
  performance_report_sections?: Performance_Report_SectionsResolvers<ContextType>;
  performance_report_sections_aggregate?: Performance_Report_Sections_AggregateResolvers<ContextType>;
  performance_report_sections_aggregate_fields?: Performance_Report_Sections_Aggregate_FieldsResolvers<ContextType>;
  performance_report_sections_avg_fields?: Performance_Report_Sections_Avg_FieldsResolvers<ContextType>;
  performance_report_sections_max_fields?: Performance_Report_Sections_Max_FieldsResolvers<ContextType>;
  performance_report_sections_min_fields?: Performance_Report_Sections_Min_FieldsResolvers<ContextType>;
  performance_report_sections_mutation_response?: Performance_Report_Sections_Mutation_ResponseResolvers<ContextType>;
  performance_report_sections_stddev_fields?: Performance_Report_Sections_Stddev_FieldsResolvers<ContextType>;
  performance_report_sections_stddev_pop_fields?: Performance_Report_Sections_Stddev_Pop_FieldsResolvers<ContextType>;
  performance_report_sections_stddev_samp_fields?: Performance_Report_Sections_Stddev_Samp_FieldsResolvers<ContextType>;
  performance_report_sections_sum_fields?: Performance_Report_Sections_Sum_FieldsResolvers<ContextType>;
  performance_report_sections_var_pop_fields?: Performance_Report_Sections_Var_Pop_FieldsResolvers<ContextType>;
  performance_report_sections_var_samp_fields?: Performance_Report_Sections_Var_Samp_FieldsResolvers<ContextType>;
  performance_report_sections_variance_fields?: Performance_Report_Sections_Variance_FieldsResolvers<ContextType>;
  performance_report_templates?: Performance_Report_TemplatesResolvers<ContextType>;
  performance_report_templates_aggregate?: Performance_Report_Templates_AggregateResolvers<ContextType>;
  performance_report_templates_aggregate_fields?: Performance_Report_Templates_Aggregate_FieldsResolvers<ContextType>;
  performance_report_templates_max_fields?: Performance_Report_Templates_Max_FieldsResolvers<ContextType>;
  performance_report_templates_min_fields?: Performance_Report_Templates_Min_FieldsResolvers<ContextType>;
  performance_report_templates_mutation_response?: Performance_Report_Templates_Mutation_ResponseResolvers<ContextType>;
  peripherals?: PeripheralsResolvers<ContextType>;
  peripherals_mutation_response?: Peripherals_Mutation_ResponseResolvers<ContextType>;
  plan_hypertasks?: Plan_HypertasksResolvers<ContextType>;
  plan_hypertasks_aggregate?: Plan_Hypertasks_AggregateResolvers<ContextType>;
  plan_hypertasks_aggregate_fields?: Plan_Hypertasks_Aggregate_FieldsResolvers<ContextType>;
  plan_hypertasks_avg_fields?: Plan_Hypertasks_Avg_FieldsResolvers<ContextType>;
  plan_hypertasks_max_fields?: Plan_Hypertasks_Max_FieldsResolvers<ContextType>;
  plan_hypertasks_min_fields?: Plan_Hypertasks_Min_FieldsResolvers<ContextType>;
  plan_hypertasks_mutation_response?: Plan_Hypertasks_Mutation_ResponseResolvers<ContextType>;
  plan_hypertasks_stddev_fields?: Plan_Hypertasks_Stddev_FieldsResolvers<ContextType>;
  plan_hypertasks_stddev_pop_fields?: Plan_Hypertasks_Stddev_Pop_FieldsResolvers<ContextType>;
  plan_hypertasks_stddev_samp_fields?: Plan_Hypertasks_Stddev_Samp_FieldsResolvers<ContextType>;
  plan_hypertasks_sum_fields?: Plan_Hypertasks_Sum_FieldsResolvers<ContextType>;
  plan_hypertasks_var_pop_fields?: Plan_Hypertasks_Var_Pop_FieldsResolvers<ContextType>;
  plan_hypertasks_var_samp_fields?: Plan_Hypertasks_Var_Samp_FieldsResolvers<ContextType>;
  plan_hypertasks_variance_fields?: Plan_Hypertasks_Variance_FieldsResolvers<ContextType>;
  plan_tasks?: Plan_TasksResolvers<ContextType>;
  plan_tasks_aggregate?: Plan_Tasks_AggregateResolvers<ContextType>;
  plan_tasks_aggregate_fields?: Plan_Tasks_Aggregate_FieldsResolvers<ContextType>;
  plan_tasks_avg_fields?: Plan_Tasks_Avg_FieldsResolvers<ContextType>;
  plan_tasks_max_fields?: Plan_Tasks_Max_FieldsResolvers<ContextType>;
  plan_tasks_min_fields?: Plan_Tasks_Min_FieldsResolvers<ContextType>;
  plan_tasks_mutation_response?: Plan_Tasks_Mutation_ResponseResolvers<ContextType>;
  plan_tasks_stddev_fields?: Plan_Tasks_Stddev_FieldsResolvers<ContextType>;
  plan_tasks_stddev_pop_fields?: Plan_Tasks_Stddev_Pop_FieldsResolvers<ContextType>;
  plan_tasks_stddev_samp_fields?: Plan_Tasks_Stddev_Samp_FieldsResolvers<ContextType>;
  plan_tasks_sum_fields?: Plan_Tasks_Sum_FieldsResolvers<ContextType>;
  plan_tasks_var_pop_fields?: Plan_Tasks_Var_Pop_FieldsResolvers<ContextType>;
  plan_tasks_var_samp_fields?: Plan_Tasks_Var_Samp_FieldsResolvers<ContextType>;
  plan_tasks_variance_fields?: Plan_Tasks_Variance_FieldsResolvers<ContextType>;
  plans?: PlansResolvers<ContextType>;
  plans_aggregate?: Plans_AggregateResolvers<ContextType>;
  plans_aggregate_fields?: Plans_Aggregate_FieldsResolvers<ContextType>;
  plans_max_fields?: Plans_Max_FieldsResolvers<ContextType>;
  plans_min_fields?: Plans_Min_FieldsResolvers<ContextType>;
  plans_mutation_response?: Plans_Mutation_ResponseResolvers<ContextType>;
  profiles?: ProfilesResolvers<ContextType>;
  profiles_aggregate?: Profiles_AggregateResolvers<ContextType>;
  profiles_aggregate_fields?: Profiles_Aggregate_FieldsResolvers<ContextType>;
  profiles_max_fields?: Profiles_Max_FieldsResolvers<ContextType>;
  profiles_min_fields?: Profiles_Min_FieldsResolvers<ContextType>;
  profiles_mutation_response?: Profiles_Mutation_ResponseResolvers<ContextType>;
  project_combo?: Project_ComboResolvers<ContextType>;
  project_combo_aggregate?: Project_Combo_AggregateResolvers<ContextType>;
  project_combo_aggregate_fields?: Project_Combo_Aggregate_FieldsResolvers<ContextType>;
  project_combo_max_fields?: Project_Combo_Max_FieldsResolvers<ContextType>;
  project_combo_min_fields?: Project_Combo_Min_FieldsResolvers<ContextType>;
  project_combo_mutation_response?: Project_Combo_Mutation_ResponseResolvers<ContextType>;
  project_dataset?: Project_DatasetResolvers<ContextType>;
  project_dataset_aggregate?: Project_Dataset_AggregateResolvers<ContextType>;
  project_dataset_aggregate_fields?: Project_Dataset_Aggregate_FieldsResolvers<ContextType>;
  project_dataset_max_fields?: Project_Dataset_Max_FieldsResolvers<ContextType>;
  project_dataset_min_fields?: Project_Dataset_Min_FieldsResolvers<ContextType>;
  project_hypertasks?: Project_HypertasksResolvers<ContextType>;
  project_hypertasks_aggregate?: Project_Hypertasks_AggregateResolvers<ContextType>;
  project_hypertasks_aggregate_fields?: Project_Hypertasks_Aggregate_FieldsResolvers<ContextType>;
  project_hypertasks_avg_fields?: Project_Hypertasks_Avg_FieldsResolvers<ContextType>;
  project_hypertasks_max_fields?: Project_Hypertasks_Max_FieldsResolvers<ContextType>;
  project_hypertasks_min_fields?: Project_Hypertasks_Min_FieldsResolvers<ContextType>;
  project_hypertasks_mutation_response?: Project_Hypertasks_Mutation_ResponseResolvers<ContextType>;
  project_hypertasks_stddev_fields?: Project_Hypertasks_Stddev_FieldsResolvers<ContextType>;
  project_hypertasks_stddev_pop_fields?: Project_Hypertasks_Stddev_Pop_FieldsResolvers<ContextType>;
  project_hypertasks_stddev_samp_fields?: Project_Hypertasks_Stddev_Samp_FieldsResolvers<ContextType>;
  project_hypertasks_sum_fields?: Project_Hypertasks_Sum_FieldsResolvers<ContextType>;
  project_hypertasks_var_pop_fields?: Project_Hypertasks_Var_Pop_FieldsResolvers<ContextType>;
  project_hypertasks_var_samp_fields?: Project_Hypertasks_Var_Samp_FieldsResolvers<ContextType>;
  project_hypertasks_variance_fields?: Project_Hypertasks_Variance_FieldsResolvers<ContextType>;
  project_metrics?: Project_MetricsResolvers<ContextType>;
  project_metrics_aggregate?: Project_Metrics_AggregateResolvers<ContextType>;
  project_metrics_aggregate_fields?: Project_Metrics_Aggregate_FieldsResolvers<ContextType>;
  project_metrics_max_fields?: Project_Metrics_Max_FieldsResolvers<ContextType>;
  project_metrics_min_fields?: Project_Metrics_Min_FieldsResolvers<ContextType>;
  project_name?: Project_NameResolvers<ContextType>;
  project_rabbit_test_instances?: Project_Rabbit_Test_InstancesResolvers<ContextType>;
  project_rabbit_test_instances_aggregate?: Project_Rabbit_Test_Instances_AggregateResolvers<ContextType>;
  project_rabbit_test_instances_aggregate_fields?: Project_Rabbit_Test_Instances_Aggregate_FieldsResolvers<ContextType>;
  project_rabbit_test_instances_avg_fields?: Project_Rabbit_Test_Instances_Avg_FieldsResolvers<ContextType>;
  project_rabbit_test_instances_max_fields?: Project_Rabbit_Test_Instances_Max_FieldsResolvers<ContextType>;
  project_rabbit_test_instances_min_fields?: Project_Rabbit_Test_Instances_Min_FieldsResolvers<ContextType>;
  project_rabbit_test_instances_stddev_fields?: Project_Rabbit_Test_Instances_Stddev_FieldsResolvers<ContextType>;
  project_rabbit_test_instances_stddev_pop_fields?: Project_Rabbit_Test_Instances_Stddev_Pop_FieldsResolvers<ContextType>;
  project_rabbit_test_instances_stddev_samp_fields?: Project_Rabbit_Test_Instances_Stddev_Samp_FieldsResolvers<ContextType>;
  project_rabbit_test_instances_sum_fields?: Project_Rabbit_Test_Instances_Sum_FieldsResolvers<ContextType>;
  project_rabbit_test_instances_var_pop_fields?: Project_Rabbit_Test_Instances_Var_Pop_FieldsResolvers<ContextType>;
  project_rabbit_test_instances_var_samp_fields?: Project_Rabbit_Test_Instances_Var_Samp_FieldsResolvers<ContextType>;
  project_rabbit_test_instances_variance_fields?: Project_Rabbit_Test_Instances_Variance_FieldsResolvers<ContextType>;
  project_tasks?: Project_TasksResolvers<ContextType>;
  project_tasks_aggregate?: Project_Tasks_AggregateResolvers<ContextType>;
  project_tasks_aggregate_fields?: Project_Tasks_Aggregate_FieldsResolvers<ContextType>;
  project_tasks_avg_fields?: Project_Tasks_Avg_FieldsResolvers<ContextType>;
  project_tasks_max_fields?: Project_Tasks_Max_FieldsResolvers<ContextType>;
  project_tasks_min_fields?: Project_Tasks_Min_FieldsResolvers<ContextType>;
  project_tasks_mutation_response?: Project_Tasks_Mutation_ResponseResolvers<ContextType>;
  project_tasks_stddev_fields?: Project_Tasks_Stddev_FieldsResolvers<ContextType>;
  project_tasks_stddev_pop_fields?: Project_Tasks_Stddev_Pop_FieldsResolvers<ContextType>;
  project_tasks_stddev_samp_fields?: Project_Tasks_Stddev_Samp_FieldsResolvers<ContextType>;
  project_tasks_sum_fields?: Project_Tasks_Sum_FieldsResolvers<ContextType>;
  project_tasks_var_pop_fields?: Project_Tasks_Var_Pop_FieldsResolvers<ContextType>;
  project_tasks_var_samp_fields?: Project_Tasks_Var_Samp_FieldsResolvers<ContextType>;
  project_tasks_variance_fields?: Project_Tasks_Variance_FieldsResolvers<ContextType>;
  project_user?: Project_UserResolvers<ContextType>;
  project_user_aggregate?: Project_User_AggregateResolvers<ContextType>;
  project_user_aggregate_fields?: Project_User_Aggregate_FieldsResolvers<ContextType>;
  project_user_max_fields?: Project_User_Max_FieldsResolvers<ContextType>;
  project_user_min_fields?: Project_User_Min_FieldsResolvers<ContextType>;
  project_user_mutation_response?: Project_User_Mutation_ResponseResolvers<ContextType>;
  projects?: ProjectsResolvers<ContextType>;
  projects_aggregate?: Projects_AggregateResolvers<ContextType>;
  projects_aggregate_fields?: Projects_Aggregate_FieldsResolvers<ContextType>;
  projects_avg_fields?: Projects_Avg_FieldsResolvers<ContextType>;
  projects_max_fields?: Projects_Max_FieldsResolvers<ContextType>;
  projects_min_fields?: Projects_Min_FieldsResolvers<ContextType>;
  projects_mutation_response?: Projects_Mutation_ResponseResolvers<ContextType>;
  projects_stddev_fields?: Projects_Stddev_FieldsResolvers<ContextType>;
  projects_stddev_pop_fields?: Projects_Stddev_Pop_FieldsResolvers<ContextType>;
  projects_stddev_samp_fields?: Projects_Stddev_Samp_FieldsResolvers<ContextType>;
  projects_sum_fields?: Projects_Sum_FieldsResolvers<ContextType>;
  projects_var_pop_fields?: Projects_Var_Pop_FieldsResolvers<ContextType>;
  projects_var_samp_fields?: Projects_Var_Samp_FieldsResolvers<ContextType>;
  projects_variance_fields?: Projects_Variance_FieldsResolvers<ContextType>;
  query_root?: Query_RootResolvers<ContextType>;
  rabbit_test_plan_project?: Rabbit_Test_Plan_ProjectResolvers<ContextType>;
  rabbit_test_plan_project_aggregate?: Rabbit_Test_Plan_Project_AggregateResolvers<ContextType>;
  rabbit_test_plan_project_aggregate_fields?: Rabbit_Test_Plan_Project_Aggregate_FieldsResolvers<ContextType>;
  rabbit_test_plan_project_max_fields?: Rabbit_Test_Plan_Project_Max_FieldsResolvers<ContextType>;
  rabbit_test_plan_project_min_fields?: Rabbit_Test_Plan_Project_Min_FieldsResolvers<ContextType>;
  rabbit_test_plan_project_mutation_response?: Rabbit_Test_Plan_Project_Mutation_ResponseResolvers<ContextType>;
  rabbit_test_plan_recording?: Rabbit_Test_Plan_RecordingResolvers<ContextType>;
  rabbit_test_plan_recording_aggregate?: Rabbit_Test_Plan_Recording_AggregateResolvers<ContextType>;
  rabbit_test_plan_recording_aggregate_fields?: Rabbit_Test_Plan_Recording_Aggregate_FieldsResolvers<ContextType>;
  rabbit_test_plan_recording_max_fields?: Rabbit_Test_Plan_Recording_Max_FieldsResolvers<ContextType>;
  rabbit_test_plan_recording_min_fields?: Rabbit_Test_Plan_Recording_Min_FieldsResolvers<ContextType>;
  rabbit_test_plan_recording_mutation_response?: Rabbit_Test_Plan_Recording_Mutation_ResponseResolvers<ContextType>;
  rabbit_test_plan_task?: Rabbit_Test_Plan_TaskResolvers<ContextType>;
  rabbit_test_plan_task_aggregate?: Rabbit_Test_Plan_Task_AggregateResolvers<ContextType>;
  rabbit_test_plan_task_aggregate_fields?: Rabbit_Test_Plan_Task_Aggregate_FieldsResolvers<ContextType>;
  rabbit_test_plan_task_max_fields?: Rabbit_Test_Plan_Task_Max_FieldsResolvers<ContextType>;
  rabbit_test_plan_task_min_fields?: Rabbit_Test_Plan_Task_Min_FieldsResolvers<ContextType>;
  rabbit_test_plan_task_mutation_response?: Rabbit_Test_Plan_Task_Mutation_ResponseResolvers<ContextType>;
  rabbit_test_plans?: Rabbit_Test_PlansResolvers<ContextType>;
  rabbit_test_plans_aggregate?: Rabbit_Test_Plans_AggregateResolvers<ContextType>;
  rabbit_test_plans_aggregate_fields?: Rabbit_Test_Plans_Aggregate_FieldsResolvers<ContextType>;
  rabbit_test_plans_max_fields?: Rabbit_Test_Plans_Max_FieldsResolvers<ContextType>;
  rabbit_test_plans_min_fields?: Rabbit_Test_Plans_Min_FieldsResolvers<ContextType>;
  rabbit_test_plans_mutation_response?: Rabbit_Test_Plans_Mutation_ResponseResolvers<ContextType>;
  recording_apps?: Recording_AppsResolvers<ContextType>;
  recording_apps_aggregate?: Recording_Apps_AggregateResolvers<ContextType>;
  recording_apps_aggregate_fields?: Recording_Apps_Aggregate_FieldsResolvers<ContextType>;
  recording_apps_max_fields?: Recording_Apps_Max_FieldsResolvers<ContextType>;
  recording_apps_min_fields?: Recording_Apps_Min_FieldsResolvers<ContextType>;
  recording_apps_mutation_response?: Recording_Apps_Mutation_ResponseResolvers<ContextType>;
  recording_audio_driver?: Recording_Audio_DriverResolvers<ContextType>;
  recording_audio_driver_mutation_response?: Recording_Audio_Driver_Mutation_ResponseResolvers<ContextType>;
  recording_data_ground_truth?: Recording_Data_Ground_TruthResolvers<ContextType>;
  recording_data_ground_truth_aggregate?: Recording_Data_Ground_Truth_AggregateResolvers<ContextType>;
  recording_data_ground_truth_aggregate_fields?: Recording_Data_Ground_Truth_Aggregate_FieldsResolvers<ContextType>;
  recording_data_ground_truth_avg_fields?: Recording_Data_Ground_Truth_Avg_FieldsResolvers<ContextType>;
  recording_data_ground_truth_max_fields?: Recording_Data_Ground_Truth_Max_FieldsResolvers<ContextType>;
  recording_data_ground_truth_min_fields?: Recording_Data_Ground_Truth_Min_FieldsResolvers<ContextType>;
  recording_data_ground_truth_mutation_response?: Recording_Data_Ground_Truth_Mutation_ResponseResolvers<ContextType>;
  recording_data_ground_truth_stddev_fields?: Recording_Data_Ground_Truth_Stddev_FieldsResolvers<ContextType>;
  recording_data_ground_truth_stddev_pop_fields?: Recording_Data_Ground_Truth_Stddev_Pop_FieldsResolvers<ContextType>;
  recording_data_ground_truth_stddev_samp_fields?: Recording_Data_Ground_Truth_Stddev_Samp_FieldsResolvers<ContextType>;
  recording_data_ground_truth_sum_fields?: Recording_Data_Ground_Truth_Sum_FieldsResolvers<ContextType>;
  recording_data_ground_truth_var_pop_fields?: Recording_Data_Ground_Truth_Var_Pop_FieldsResolvers<ContextType>;
  recording_data_ground_truth_var_samp_fields?: Recording_Data_Ground_Truth_Var_Samp_FieldsResolvers<ContextType>;
  recording_data_ground_truth_variance_fields?: Recording_Data_Ground_Truth_Variance_FieldsResolvers<ContextType>;
  recording_data_labels?: Recording_Data_LabelsResolvers<ContextType>;
  recording_data_labels_aggregate?: Recording_Data_Labels_AggregateResolvers<ContextType>;
  recording_data_labels_aggregate_fields?: Recording_Data_Labels_Aggregate_FieldsResolvers<ContextType>;
  recording_data_labels_avg_fields?: Recording_Data_Labels_Avg_FieldsResolvers<ContextType>;
  recording_data_labels_max_fields?: Recording_Data_Labels_Max_FieldsResolvers<ContextType>;
  recording_data_labels_min_fields?: Recording_Data_Labels_Min_FieldsResolvers<ContextType>;
  recording_data_labels_mutation_response?: Recording_Data_Labels_Mutation_ResponseResolvers<ContextType>;
  recording_data_labels_stddev_fields?: Recording_Data_Labels_Stddev_FieldsResolvers<ContextType>;
  recording_data_labels_stddev_pop_fields?: Recording_Data_Labels_Stddev_Pop_FieldsResolvers<ContextType>;
  recording_data_labels_stddev_samp_fields?: Recording_Data_Labels_Stddev_Samp_FieldsResolvers<ContextType>;
  recording_data_labels_sum_fields?: Recording_Data_Labels_Sum_FieldsResolvers<ContextType>;
  recording_data_labels_var_pop_fields?: Recording_Data_Labels_Var_Pop_FieldsResolvers<ContextType>;
  recording_data_labels_var_samp_fields?: Recording_Data_Labels_Var_Samp_FieldsResolvers<ContextType>;
  recording_data_labels_variance_fields?: Recording_Data_Labels_Variance_FieldsResolvers<ContextType>;
  recording_data_mode_parameter?: Recording_Data_Mode_ParameterResolvers<ContextType>;
  recording_data_mode_parameter_aggregate?: Recording_Data_Mode_Parameter_AggregateResolvers<ContextType>;
  recording_data_mode_parameter_aggregate_fields?: Recording_Data_Mode_Parameter_Aggregate_FieldsResolvers<ContextType>;
  recording_data_mode_parameter_max_fields?: Recording_Data_Mode_Parameter_Max_FieldsResolvers<ContextType>;
  recording_data_mode_parameter_min_fields?: Recording_Data_Mode_Parameter_Min_FieldsResolvers<ContextType>;
  recording_data_mode_parameter_mutation_response?: Recording_Data_Mode_Parameter_Mutation_ResponseResolvers<ContextType>;
  recording_data_modes?: Recording_Data_ModesResolvers<ContextType>;
  recording_data_modes_aggregate?: Recording_Data_Modes_AggregateResolvers<ContextType>;
  recording_data_modes_aggregate_fields?: Recording_Data_Modes_Aggregate_FieldsResolvers<ContextType>;
  recording_data_modes_max_fields?: Recording_Data_Modes_Max_FieldsResolvers<ContextType>;
  recording_data_modes_min_fields?: Recording_Data_Modes_Min_FieldsResolvers<ContextType>;
  recording_data_modes_mutation_response?: Recording_Data_Modes_Mutation_ResponseResolvers<ContextType>;
  recording_data_parameters?: Recording_Data_ParametersResolvers<ContextType>;
  recording_data_parameters_aggregate?: Recording_Data_Parameters_AggregateResolvers<ContextType>;
  recording_data_parameters_aggregate_fields?: Recording_Data_Parameters_Aggregate_FieldsResolvers<ContextType>;
  recording_data_parameters_max_fields?: Recording_Data_Parameters_Max_FieldsResolvers<ContextType>;
  recording_data_parameters_min_fields?: Recording_Data_Parameters_Min_FieldsResolvers<ContextType>;
  recording_data_parameters_mutation_response?: Recording_Data_Parameters_Mutation_ResponseResolvers<ContextType>;
  recording_data_payload_pair?: Recording_Data_Payload_PairResolvers<ContextType>;
  recording_data_payload_pair_mutation_response?: Recording_Data_Payload_Pair_Mutation_ResponseResolvers<ContextType>;
  recording_data_predictions?: Recording_Data_PredictionsResolvers<ContextType>;
  recording_data_predictions_aggregate?: Recording_Data_Predictions_AggregateResolvers<ContextType>;
  recording_data_predictions_aggregate_fields?: Recording_Data_Predictions_Aggregate_FieldsResolvers<ContextType>;
  recording_data_predictions_max_fields?: Recording_Data_Predictions_Max_FieldsResolvers<ContextType>;
  recording_data_predictions_min_fields?: Recording_Data_Predictions_Min_FieldsResolvers<ContextType>;
  recording_data_predictions_mutation_response?: Recording_Data_Predictions_Mutation_ResponseResolvers<ContextType>;
  recording_recording_tag?: Recording_Recording_TagResolvers<ContextType>;
  recording_recording_tag_mutation_response?: Recording_Recording_Tag_Mutation_ResponseResolvers<ContextType>;
  recording_tags?: Recording_TagsResolvers<ContextType>;
  recording_tags_mutation_response?: Recording_Tags_Mutation_ResponseResolvers<ContextType>;
  recordings?: RecordingsResolvers<ContextType>;
  recordings_aggregate?: Recordings_AggregateResolvers<ContextType>;
  recordings_aggregate_fields?: Recordings_Aggregate_FieldsResolvers<ContextType>;
  recordings_avg_fields?: Recordings_Avg_FieldsResolvers<ContextType>;
  recordings_max_fields?: Recordings_Max_FieldsResolvers<ContextType>;
  recordings_min_fields?: Recordings_Min_FieldsResolvers<ContextType>;
  recordings_mutation_response?: Recordings_Mutation_ResponseResolvers<ContextType>;
  recordings_stddev_fields?: Recordings_Stddev_FieldsResolvers<ContextType>;
  recordings_stddev_pop_fields?: Recordings_Stddev_Pop_FieldsResolvers<ContextType>;
  recordings_stddev_samp_fields?: Recordings_Stddev_Samp_FieldsResolvers<ContextType>;
  recordings_sum_fields?: Recordings_Sum_FieldsResolvers<ContextType>;
  recordings_var_pop_fields?: Recordings_Var_Pop_FieldsResolvers<ContextType>;
  recordings_var_samp_fields?: Recordings_Var_Samp_FieldsResolvers<ContextType>;
  recordings_variance_fields?: Recordings_Variance_FieldsResolvers<ContextType>;
  role_user?: Role_UserResolvers<ContextType>;
  role_user_aggregate?: Role_User_AggregateResolvers<ContextType>;
  role_user_aggregate_fields?: Role_User_Aggregate_FieldsResolvers<ContextType>;
  role_user_max_fields?: Role_User_Max_FieldsResolvers<ContextType>;
  role_user_min_fields?: Role_User_Min_FieldsResolvers<ContextType>;
  role_user_mutation_response?: Role_User_Mutation_ResponseResolvers<ContextType>;
  roles?: RolesResolvers<ContextType>;
  roles_aggregate?: Roles_AggregateResolvers<ContextType>;
  roles_aggregate_fields?: Roles_Aggregate_FieldsResolvers<ContextType>;
  roles_max_fields?: Roles_Max_FieldsResolvers<ContextType>;
  roles_min_fields?: Roles_Min_FieldsResolvers<ContextType>;
  roles_mutation_response?: Roles_Mutation_ResponseResolvers<ContextType>;
  rooms?: RoomsResolvers<ContextType>;
  rooms_mutation_response?: Rooms_Mutation_ResponseResolvers<ContextType>;
  schema_project_engine_metrics?: Schema_Project_Engine_MetricsResolvers<ContextType>;
  schema_project_engine_ratio_metrics?: Schema_Project_Engine_Ratio_MetricsResolvers<ContextType>;
  schema_project_engine_ratio_metrics_aggregate?: Schema_Project_Engine_Ratio_Metrics_AggregateResolvers<ContextType>;
  schema_project_engine_ratio_metrics_aggregate_fields?: Schema_Project_Engine_Ratio_Metrics_Aggregate_FieldsResolvers<ContextType>;
  schema_project_engine_ratio_metrics_avg_fields?: Schema_Project_Engine_Ratio_Metrics_Avg_FieldsResolvers<ContextType>;
  schema_project_engine_ratio_metrics_max_fields?: Schema_Project_Engine_Ratio_Metrics_Max_FieldsResolvers<ContextType>;
  schema_project_engine_ratio_metrics_min_fields?: Schema_Project_Engine_Ratio_Metrics_Min_FieldsResolvers<ContextType>;
  schema_project_engine_ratio_metrics_stddev_fields?: Schema_Project_Engine_Ratio_Metrics_Stddev_FieldsResolvers<ContextType>;
  schema_project_engine_ratio_metrics_stddev_pop_fields?: Schema_Project_Engine_Ratio_Metrics_Stddev_Pop_FieldsResolvers<ContextType>;
  schema_project_engine_ratio_metrics_stddev_samp_fields?: Schema_Project_Engine_Ratio_Metrics_Stddev_Samp_FieldsResolvers<ContextType>;
  schema_project_engine_ratio_metrics_sum_fields?: Schema_Project_Engine_Ratio_Metrics_Sum_FieldsResolvers<ContextType>;
  schema_project_engine_ratio_metrics_var_pop_fields?: Schema_Project_Engine_Ratio_Metrics_Var_Pop_FieldsResolvers<ContextType>;
  schema_project_engine_ratio_metrics_var_samp_fields?: Schema_Project_Engine_Ratio_Metrics_Var_Samp_FieldsResolvers<ContextType>;
  schema_project_engine_ratio_metrics_variance_fields?: Schema_Project_Engine_Ratio_Metrics_Variance_FieldsResolvers<ContextType>;
  schema_project_task_count?: Schema_Project_Task_CountResolvers<ContextType>;
  schema_project_task_count_aggregate?: Schema_Project_Task_Count_AggregateResolvers<ContextType>;
  schema_project_task_count_aggregate_fields?: Schema_Project_Task_Count_Aggregate_FieldsResolvers<ContextType>;
  schema_project_task_count_avg_fields?: Schema_Project_Task_Count_Avg_FieldsResolvers<ContextType>;
  schema_project_task_count_by_device?: Schema_Project_Task_Count_By_DeviceResolvers<ContextType>;
  schema_project_task_count_by_device_aggregate?: Schema_Project_Task_Count_By_Device_AggregateResolvers<ContextType>;
  schema_project_task_count_by_device_aggregate_fields?: Schema_Project_Task_Count_By_Device_Aggregate_FieldsResolvers<ContextType>;
  schema_project_task_count_by_device_avg_fields?: Schema_Project_Task_Count_By_Device_Avg_FieldsResolvers<ContextType>;
  schema_project_task_count_by_device_max_fields?: Schema_Project_Task_Count_By_Device_Max_FieldsResolvers<ContextType>;
  schema_project_task_count_by_device_min_fields?: Schema_Project_Task_Count_By_Device_Min_FieldsResolvers<ContextType>;
  schema_project_task_count_by_device_stddev_fields?: Schema_Project_Task_Count_By_Device_Stddev_FieldsResolvers<ContextType>;
  schema_project_task_count_by_device_stddev_pop_fields?: Schema_Project_Task_Count_By_Device_Stddev_Pop_FieldsResolvers<ContextType>;
  schema_project_task_count_by_device_stddev_samp_fields?: Schema_Project_Task_Count_By_Device_Stddev_Samp_FieldsResolvers<ContextType>;
  schema_project_task_count_by_device_sum_fields?: Schema_Project_Task_Count_By_Device_Sum_FieldsResolvers<ContextType>;
  schema_project_task_count_by_device_var_pop_fields?: Schema_Project_Task_Count_By_Device_Var_Pop_FieldsResolvers<ContextType>;
  schema_project_task_count_by_device_var_samp_fields?: Schema_Project_Task_Count_By_Device_Var_Samp_FieldsResolvers<ContextType>;
  schema_project_task_count_by_device_variance_fields?: Schema_Project_Task_Count_By_Device_Variance_FieldsResolvers<ContextType>;
  schema_project_task_count_max_fields?: Schema_Project_Task_Count_Max_FieldsResolvers<ContextType>;
  schema_project_task_count_min_fields?: Schema_Project_Task_Count_Min_FieldsResolvers<ContextType>;
  schema_project_task_count_stddev_fields?: Schema_Project_Task_Count_Stddev_FieldsResolvers<ContextType>;
  schema_project_task_count_stddev_pop_fields?: Schema_Project_Task_Count_Stddev_Pop_FieldsResolvers<ContextType>;
  schema_project_task_count_stddev_samp_fields?: Schema_Project_Task_Count_Stddev_Samp_FieldsResolvers<ContextType>;
  schema_project_task_count_sum_fields?: Schema_Project_Task_Count_Sum_FieldsResolvers<ContextType>;
  schema_project_task_count_var_pop_fields?: Schema_Project_Task_Count_Var_Pop_FieldsResolvers<ContextType>;
  schema_project_task_count_var_samp_fields?: Schema_Project_Task_Count_Var_Samp_FieldsResolvers<ContextType>;
  schema_project_task_count_variance_fields?: Schema_Project_Task_Count_Variance_FieldsResolvers<ContextType>;
  schema_recording_tag_count_by_day?: Schema_Recording_Tag_Count_By_DayResolvers<ContextType>;
  schema_recording_tag_count_by_day_aggregate?: Schema_Recording_Tag_Count_By_Day_AggregateResolvers<ContextType>;
  schema_recording_tag_count_by_day_aggregate_fields?: Schema_Recording_Tag_Count_By_Day_Aggregate_FieldsResolvers<ContextType>;
  schema_recording_tag_count_by_day_avg_fields?: Schema_Recording_Tag_Count_By_Day_Avg_FieldsResolvers<ContextType>;
  schema_recording_tag_count_by_day_max_fields?: Schema_Recording_Tag_Count_By_Day_Max_FieldsResolvers<ContextType>;
  schema_recording_tag_count_by_day_min_fields?: Schema_Recording_Tag_Count_By_Day_Min_FieldsResolvers<ContextType>;
  schema_recording_tag_count_by_day_stddev_fields?: Schema_Recording_Tag_Count_By_Day_Stddev_FieldsResolvers<ContextType>;
  schema_recording_tag_count_by_day_stddev_pop_fields?: Schema_Recording_Tag_Count_By_Day_Stddev_Pop_FieldsResolvers<ContextType>;
  schema_recording_tag_count_by_day_stddev_samp_fields?: Schema_Recording_Tag_Count_By_Day_Stddev_Samp_FieldsResolvers<ContextType>;
  schema_recording_tag_count_by_day_sum_fields?: Schema_Recording_Tag_Count_By_Day_Sum_FieldsResolvers<ContextType>;
  schema_recording_tag_count_by_day_var_pop_fields?: Schema_Recording_Tag_Count_By_Day_Var_Pop_FieldsResolvers<ContextType>;
  schema_recording_tag_count_by_day_var_samp_fields?: Schema_Recording_Tag_Count_By_Day_Var_Samp_FieldsResolvers<ContextType>;
  schema_recording_tag_count_by_day_variance_fields?: Schema_Recording_Tag_Count_By_Day_Variance_FieldsResolvers<ContextType>;
  schema_upsert_task?: Schema_Upsert_TaskResolvers<ContextType>;
  simulations_scores?: Simulations_ScoresResolvers<ContextType>;
  simulations_scores_mutation_response?: Simulations_Scores_Mutation_ResponseResolvers<ContextType>;
  smallint?: GraphQLScalarType;
  subscription_root?: Subscription_RootResolvers<ContextType>;
  task_field_option?: Task_Field_OptionResolvers<ContextType>;
  task_field_option_aggregate?: Task_Field_Option_AggregateResolvers<ContextType>;
  task_field_option_aggregate_fields?: Task_Field_Option_Aggregate_FieldsResolvers<ContextType>;
  task_field_option_hashes?: Task_Field_Option_HashesResolvers<ContextType>;
  task_field_option_hashes_aggregate?: Task_Field_Option_Hashes_AggregateResolvers<ContextType>;
  task_field_option_hashes_aggregate_fields?: Task_Field_Option_Hashes_Aggregate_FieldsResolvers<ContextType>;
  task_field_option_hashes_max_fields?: Task_Field_Option_Hashes_Max_FieldsResolvers<ContextType>;
  task_field_option_hashes_min_fields?: Task_Field_Option_Hashes_Min_FieldsResolvers<ContextType>;
  task_field_option_max_fields?: Task_Field_Option_Max_FieldsResolvers<ContextType>;
  task_field_option_min_fields?: Task_Field_Option_Min_FieldsResolvers<ContextType>;
  task_field_option_mutation_response?: Task_Field_Option_Mutation_ResponseResolvers<ContextType>;
  task_groups_group_fields?: Task_Groups_Group_FieldsResolvers<ContextType>;
  task_groups_group_fields_aggregate?: Task_Groups_Group_Fields_AggregateResolvers<ContextType>;
  task_groups_group_fields_aggregate_fields?: Task_Groups_Group_Fields_Aggregate_FieldsResolvers<ContextType>;
  task_groups_group_fields_avg_fields?: Task_Groups_Group_Fields_Avg_FieldsResolvers<ContextType>;
  task_groups_group_fields_max_fields?: Task_Groups_Group_Fields_Max_FieldsResolvers<ContextType>;
  task_groups_group_fields_min_fields?: Task_Groups_Group_Fields_Min_FieldsResolvers<ContextType>;
  task_groups_group_fields_mutation_response?: Task_Groups_Group_Fields_Mutation_ResponseResolvers<ContextType>;
  task_groups_group_fields_stddev_fields?: Task_Groups_Group_Fields_Stddev_FieldsResolvers<ContextType>;
  task_groups_group_fields_stddev_pop_fields?: Task_Groups_Group_Fields_Stddev_Pop_FieldsResolvers<ContextType>;
  task_groups_group_fields_stddev_samp_fields?: Task_Groups_Group_Fields_Stddev_Samp_FieldsResolvers<ContextType>;
  task_groups_group_fields_sum_fields?: Task_Groups_Group_Fields_Sum_FieldsResolvers<ContextType>;
  task_groups_group_fields_var_pop_fields?: Task_Groups_Group_Fields_Var_Pop_FieldsResolvers<ContextType>;
  task_groups_group_fields_var_samp_fields?: Task_Groups_Group_Fields_Var_Samp_FieldsResolvers<ContextType>;
  task_groups_group_fields_variance_fields?: Task_Groups_Group_Fields_Variance_FieldsResolvers<ContextType>;
  task_groups_groups?: Task_Groups_GroupsResolvers<ContextType>;
  task_groups_groups_aggregate?: Task_Groups_Groups_AggregateResolvers<ContextType>;
  task_groups_groups_aggregate_fields?: Task_Groups_Groups_Aggregate_FieldsResolvers<ContextType>;
  task_groups_groups_max_fields?: Task_Groups_Groups_Max_FieldsResolvers<ContextType>;
  task_groups_groups_min_fields?: Task_Groups_Groups_Min_FieldsResolvers<ContextType>;
  task_groups_groups_mutation_response?: Task_Groups_Groups_Mutation_ResponseResolvers<ContextType>;
  task_groups_hierarchies?: Task_Groups_HierarchiesResolvers<ContextType>;
  task_groups_hierarchies_aggregate?: Task_Groups_Hierarchies_AggregateResolvers<ContextType>;
  task_groups_hierarchies_aggregate_fields?: Task_Groups_Hierarchies_Aggregate_FieldsResolvers<ContextType>;
  task_groups_hierarchies_max_fields?: Task_Groups_Hierarchies_Max_FieldsResolvers<ContextType>;
  task_groups_hierarchies_min_fields?: Task_Groups_Hierarchies_Min_FieldsResolvers<ContextType>;
  task_groups_hierarchies_mutation_response?: Task_Groups_Hierarchies_Mutation_ResponseResolvers<ContextType>;
  task_groups_hierarchy_groups?: Task_Groups_Hierarchy_GroupsResolvers<ContextType>;
  task_groups_hierarchy_groups_aggregate?: Task_Groups_Hierarchy_Groups_AggregateResolvers<ContextType>;
  task_groups_hierarchy_groups_aggregate_fields?: Task_Groups_Hierarchy_Groups_Aggregate_FieldsResolvers<ContextType>;
  task_groups_hierarchy_groups_avg_fields?: Task_Groups_Hierarchy_Groups_Avg_FieldsResolvers<ContextType>;
  task_groups_hierarchy_groups_max_fields?: Task_Groups_Hierarchy_Groups_Max_FieldsResolvers<ContextType>;
  task_groups_hierarchy_groups_min_fields?: Task_Groups_Hierarchy_Groups_Min_FieldsResolvers<ContextType>;
  task_groups_hierarchy_groups_mutation_response?: Task_Groups_Hierarchy_Groups_Mutation_ResponseResolvers<ContextType>;
  task_groups_hierarchy_groups_stddev_fields?: Task_Groups_Hierarchy_Groups_Stddev_FieldsResolvers<ContextType>;
  task_groups_hierarchy_groups_stddev_pop_fields?: Task_Groups_Hierarchy_Groups_Stddev_Pop_FieldsResolvers<ContextType>;
  task_groups_hierarchy_groups_stddev_samp_fields?: Task_Groups_Hierarchy_Groups_Stddev_Samp_FieldsResolvers<ContextType>;
  task_groups_hierarchy_groups_sum_fields?: Task_Groups_Hierarchy_Groups_Sum_FieldsResolvers<ContextType>;
  task_groups_hierarchy_groups_var_pop_fields?: Task_Groups_Hierarchy_Groups_Var_Pop_FieldsResolvers<ContextType>;
  task_groups_hierarchy_groups_var_samp_fields?: Task_Groups_Hierarchy_Groups_Var_Samp_FieldsResolvers<ContextType>;
  task_groups_hierarchy_groups_variance_fields?: Task_Groups_Hierarchy_Groups_Variance_FieldsResolvers<ContextType>;
  task_instructions?: Task_InstructionsResolvers<ContextType>;
  task_instructions_mutation_response?: Task_Instructions_Mutation_ResponseResolvers<ContextType>;
  task_media?: Task_MediaResolvers<ContextType>;
  task_media_aggregate?: Task_Media_AggregateResolvers<ContextType>;
  task_media_aggregate_fields?: Task_Media_Aggregate_FieldsResolvers<ContextType>;
  task_media_max_fields?: Task_Media_Max_FieldsResolvers<ContextType>;
  task_media_min_fields?: Task_Media_Min_FieldsResolvers<ContextType>;
  task_media_mutation_response?: Task_Media_Mutation_ResponseResolvers<ContextType>;
  task_statuses?: Task_StatusesResolvers<ContextType>;
  task_translation?: Task_TranslationResolvers<ContextType>;
  task_translation_aggregate?: Task_Translation_AggregateResolvers<ContextType>;
  task_translation_aggregate_fields?: Task_Translation_Aggregate_FieldsResolvers<ContextType>;
  task_translation_max_fields?: Task_Translation_Max_FieldsResolvers<ContextType>;
  task_translation_min_fields?: Task_Translation_Min_FieldsResolvers<ContextType>;
  task_translation_mutation_response?: Task_Translation_Mutation_ResponseResolvers<ContextType>;
  tasks?: TasksResolvers<ContextType>;
  tasks_aggregate?: Tasks_AggregateResolvers<ContextType>;
  tasks_aggregate_fields?: Tasks_Aggregate_FieldsResolvers<ContextType>;
  tasks_avg_fields?: Tasks_Avg_FieldsResolvers<ContextType>;
  tasks_max_fields?: Tasks_Max_FieldsResolvers<ContextType>;
  tasks_min_fields?: Tasks_Min_FieldsResolvers<ContextType>;
  tasks_mutation_response?: Tasks_Mutation_ResponseResolvers<ContextType>;
  tasks_stddev_fields?: Tasks_Stddev_FieldsResolvers<ContextType>;
  tasks_stddev_pop_fields?: Tasks_Stddev_Pop_FieldsResolvers<ContextType>;
  tasks_stddev_samp_fields?: Tasks_Stddev_Samp_FieldsResolvers<ContextType>;
  tasks_sum_fields?: Tasks_Sum_FieldsResolvers<ContextType>;
  tasks_var_pop_fields?: Tasks_Var_Pop_FieldsResolvers<ContextType>;
  tasks_var_samp_fields?: Tasks_Var_Samp_FieldsResolvers<ContextType>;
  tasks_variance_fields?: Tasks_Variance_FieldsResolvers<ContextType>;
  timestamp?: GraphQLScalarType;
  timestamptz?: GraphQLScalarType;
  tool_versions?: Tool_VersionsResolvers<ContextType>;
  tool_versions_mutation_response?: Tool_Versions_Mutation_ResponseResolvers<ContextType>;
  tools?: ToolsResolvers<ContextType>;
  tools_mutation_response?: Tools_Mutation_ResponseResolvers<ContextType>;
  types?: TypesResolvers<ContextType>;
  types_aggregate?: Types_AggregateResolvers<ContextType>;
  types_aggregate_fields?: Types_Aggregate_FieldsResolvers<ContextType>;
  types_max_fields?: Types_Max_FieldsResolvers<ContextType>;
  types_min_fields?: Types_Min_FieldsResolvers<ContextType>;
  types_mutation_response?: Types_Mutation_ResponseResolvers<ContextType>;
  user_recordings_by_day_stats?: User_Recordings_By_Day_StatsResolvers<ContextType>;
  user_recordings_by_day_stats_aggregate?: User_Recordings_By_Day_Stats_AggregateResolvers<ContextType>;
  user_recordings_by_day_stats_aggregate_fields?: User_Recordings_By_Day_Stats_Aggregate_FieldsResolvers<ContextType>;
  user_recordings_by_day_stats_avg_fields?: User_Recordings_By_Day_Stats_Avg_FieldsResolvers<ContextType>;
  user_recordings_by_day_stats_max_fields?: User_Recordings_By_Day_Stats_Max_FieldsResolvers<ContextType>;
  user_recordings_by_day_stats_min_fields?: User_Recordings_By_Day_Stats_Min_FieldsResolvers<ContextType>;
  user_recordings_by_day_stats_stddev_fields?: User_Recordings_By_Day_Stats_Stddev_FieldsResolvers<ContextType>;
  user_recordings_by_day_stats_stddev_pop_fields?: User_Recordings_By_Day_Stats_Stddev_Pop_FieldsResolvers<ContextType>;
  user_recordings_by_day_stats_stddev_samp_fields?: User_Recordings_By_Day_Stats_Stddev_Samp_FieldsResolvers<ContextType>;
  user_recordings_by_day_stats_sum_fields?: User_Recordings_By_Day_Stats_Sum_FieldsResolvers<ContextType>;
  user_recordings_by_day_stats_var_pop_fields?: User_Recordings_By_Day_Stats_Var_Pop_FieldsResolvers<ContextType>;
  user_recordings_by_day_stats_var_samp_fields?: User_Recordings_By_Day_Stats_Var_Samp_FieldsResolvers<ContextType>;
  user_recordings_by_day_stats_variance_fields?: User_Recordings_By_Day_Stats_Variance_FieldsResolvers<ContextType>;
  users?: UsersResolvers<ContextType>;
  users_aggregate?: Users_AggregateResolvers<ContextType>;
  users_aggregate_fields?: Users_Aggregate_FieldsResolvers<ContextType>;
  users_max_fields?: Users_Max_FieldsResolvers<ContextType>;
  users_min_fields?: Users_Min_FieldsResolvers<ContextType>;
  users_mutation_response?: Users_Mutation_ResponseResolvers<ContextType>;
  uuid?: GraphQLScalarType;
  xtensa_configurations?: Xtensa_ConfigurationsResolvers<ContextType>;
  xtensa_configurations_aggregate?: Xtensa_Configurations_AggregateResolvers<ContextType>;
  xtensa_configurations_aggregate_fields?: Xtensa_Configurations_Aggregate_FieldsResolvers<ContextType>;
  xtensa_configurations_max_fields?: Xtensa_Configurations_Max_FieldsResolvers<ContextType>;
  xtensa_configurations_min_fields?: Xtensa_Configurations_Min_FieldsResolvers<ContextType>;
  xtensa_configurations_mutation_response?: Xtensa_Configurations_Mutation_ResponseResolvers<ContextType>;
};


/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = any> = Resolvers<ContextType>;
export type DirectiveResolvers<ContextType = any> = {
  cached?: CachedDirectiveResolver<any, any, ContextType>;
};


/**
 * @deprecated
 * Use "DirectiveResolvers" root object instead. If you wish to get "IDirectiveResolvers", add "typesPrefix: I" to your config.
 */
export type IDirectiveResolvers<ContextType = any> = DirectiveResolvers<ContextType>;
export const BenchmarkPlanFieldsFragmentDoc = gql`
    fragment BenchmarkPlanFields on rabbit_test_plans {
  id
  name
  description
  created_at
  updated_at
  deleted_at
}
    `;
export const ChecklistFieldsFragmentDoc = gql`
    fragment ChecklistFields on bringup_checklists {
  id
  name
  description
  created_at
  updated_at
  deleted_at
}
    `;
export const ChecklistItemFragmentDoc = gql`
    fragment ChecklistItem on bringup_items {
  id
  checklist_id
  position
  name
  description
  objective
  personnel
}
    `;
export const CategoryFieldsFragmentDoc = gql`
    fragment CategoryFields on categories {
  id
  name
  priority
  created_at
  updated_at
  deleted_at
}
    `;
export const ContextFieldsFragmentDoc = gql`
    fragment ContextFields on contexts {
  id
  created_at
  updated_at
  deleted_at
  name
  category {
    ...CategoryFields
  }
}
    ${CategoryFieldsFragmentDoc}`;
export const FieldFieldsFragmentDoc = gql`
    fragment FieldFields on fields {
  id
  display
  name
  description
  type_id
  created_at
  updated_at
  deleted_at
}
    `;
export const TypeFieldsFragmentDoc = gql`
    fragment TypeFields on types {
  id
  name
  created_at
  updated_at
  deleted_at
}
    `;
export const OptionFieldsFragmentDoc = gql`
    fragment OptionFields on options {
  id
  display
  value
  type {
    id
    name
  }
  created_at
  updated_at
  deleted_at
}
    `;
export const MediaFieldsFragmentDoc = gql`
    fragment MediaFields on media {
  id
  created_at
  updated_at
  url
  title
  description
  type
}
    `;
export const TaskMediaFragmentDoc = gql`
    fragment TaskMedia on task_media {
  task_id
  media_id
  media {
    ...MediaFields
  }
}
    ${MediaFieldsFragmentDoc}`;
export const TaskFieldsFragmentDoc = gql`
    fragment TaskFields on tasks {
  id
  note
  force_ground_truth_to_negative
  legacy_template_id
  is_template
  is_test
  description
  created_at
  updated_at
  deleted_at
  task_media {
    ...TaskMedia
  }
  task_instruction {
    id
    content
    status
  }
}
    ${TaskMediaFragmentDoc}`;
export const FieldOptionFieldsFragmentDoc = gql`
    fragment FieldOptionFields on field_options {
  id
  field {
    id
    display
    context {
      name
      category {
        name
      }
    }
  }
  option {
    id
    display
  }
  type {
    id
    name
  }
  created_at
  updated_at
  deleted_at
}
    `;
export const TaskFieldOptionFieldsFragmentDoc = gql`
    fragment TaskFieldOptionFields on task_field_option {
  task_id
  field_option_id
  highlight
  field_option {
    ...FieldOptionFields
  }
}
    ${FieldOptionFieldsFragmentDoc}`;
export const PlanFieldsFragmentDoc = gql`
    fragment PlanFields on plans {
  id
  name
  description
  created_at
  updated_at
  deleted_at
}
    `;
export const PlanTaskFieldsFragmentDoc = gql`
    fragment PlanTaskFields on plan_tasks {
  id
  priority
  min_per_device
  created_at
  updated_at
}
    `;
export const HypertaskFieldsFragmentDoc = gql`
    fragment HypertaskFields on hypertasks {
  id
  number
  description
  created_at
  updated_at
  deleted_at
}
    `;
export const CustomerFieldsFragmentDoc = gql`
    fragment CustomerFields on customers {
  id
  codename
  created_at
  updated_at
  deleted_at
}
    `;
export const DeliverableFieldsFragmentDoc = gql`
    fragment DeliverableFields on deliverables {
  id
  name
  description
  num_states
  ground_truth_type_id
  ground_truth_type {
    id
    name
    default_near_threshold
    default_far_threshold
    min_near_threshold
    max_near_threshold
    min_far_threshold
    max_far_threshold
  }
  deleted_at
}
    `;
export const EngineFieldsFragmentDoc = gql`
    fragment EngineFields on engines {
  id
  azure_build_ref
  azure_release_ref
  branch
  build_definition_id_azure
  build_number
  build_version
  graph_version
  elliptic_engine_commit_hash
  nickname
  release_definition_id_azure
  type
  engine_opmodes(order_by: {opmode: {number: asc}}) {
    opmode {
      id
      number
    }
  }
  build_ref
  release_number
}
    `;
export const DriverVersionFieldsFragmentDoc = gql`
    fragment DriverVersionFields on deliveries_driver_versions {
  id
  name
  version
  storage_blob_url
  sbom_blob_url
  created_at
  updated_at
  deleted_at
  driver_version_engine {
    engine {
      ...EngineFields
    }
  }
  driver {
    driver_project {
      project {
        project_name {
          name
        }
      }
    }
  }
}
    ${EngineFieldsFragmentDoc}`;
export const DriverFieldsFragmentDoc = gql`
    fragment DriverFields on deliveries_drivers {
  id
  name
  description
  driver_versions {
    ...DriverVersionFields
  }
  driver_project {
    project {
      id
      project_name {
        name
      }
    }
  }
  deleted_at
}
    ${DriverVersionFieldsFragmentDoc}`;
export const PackageFieldsFragmentDoc = gql`
    fragment PackageFields on deliveries_packages {
  id
  name
  version
  customer {
    id
    codename
  }
  spec_package_id
  external_package_name
  slack_channel
  sharepoint_upload_path
  sharepoint_release_notes_path
  bundle_struct_version
  url
  sbom_blob_url
  created_at
  updated_at
  deleted_at
  architecture {
    id
    name
  }
  platform {
    id
    name
  }
  major_version
  minor_version
}
    `;
export const ArtifactFieldsFragmentDoc = gql`
    fragment ArtifactFields on deliveries_artifacts {
  id
  package {
    id
    name
    version
  }
  artifact_operating_systems {
    operating_system {
      id
      fullname
    }
  }
  storage_blob_url
  sbom_url
  created_at
}
    `;
export const MicrosoftWhqlSubmissionFieldsFragmentDoc = gql`
    fragment MicrosoftWHQLSubmissionFields on deliveries_microsoft_whql_submissions {
  id
  artifact_microsoft_whql_submissions {
    artifact {
      id
      artifact_operating_systems {
        operating_system {
          id
          fullname
        }
      }
      package {
        version
        name
      }
    }
  }
  microsoft_partner_center_product_id
  microsoft_partner_center_product_name
  microsoft_partner_center_submission_id
  microsoft_partner_center_submission_name
  microsoft_partner_center_submission_type
  created_at
}
    `;
export const MicrosoftShippingLabelFieldsFragmentDoc = gql`
    fragment MicrosoftShippingLabelFields on deliveries_microsoft_shipping_labels {
  id
  microsoft_whql_submission_id
  microsoft_partner_center_shipping_label_id
  shipping_label_infos
  shipping_label_status
  shipping_label_name
  created_by
  target_id
  created_at
  updated_at
  updated_by
  user {
    name
  }
  user_update {
    name
  }
  target {
    customer {
      codename
    }
    model {
      codename
    }
  }
  microsoft_shipping_label_review {
    status
    reviewed_by
    created_at
    user {
      name
    }
  }
}
    `;
export const ProjectConfigFieldsFragmentDoc = gql`
    fragment ProjectConfigFields on deliveries_project_configs {
  project {
    id
    project_name {
      name
    }
  }
  id
  project_id
  config_id_type
  config_id
  chid_level
  controller_updates
}
    `;
export const TargetFieldsFragmentDoc = gql`
    fragment TargetFields on deliveries_targets {
  id
  model {
    id
    codename
  }
  customer {
    id
    codename
  }
  chids
  hardware_ids
  target_partners {
    partner {
      id
      name
    }
  }
}
    `;
export const ProjectCmdFragmentDoc = gql`
    fragment ProjectCMD on projects {
  customer {
    id
    codename
  }
  model {
    id
    codename
  }
  deliverable {
    id
    name
  }
  variant
}
    `;
export const ModelFieldsFragmentDoc = gql`
    fragment ModelFields on models {
  id
  codename
  description
  suspend_resume_at_transitions
  projects {
    id
    ...ProjectCMD
    project_name {
      name
      display_name
    }
  }
}
    ${ProjectCmdFragmentDoc}`;
export const DeviceFieldsFragmentDoc = gql`
    fragment DeviceFields on devices {
  id
  serialno
  description
  model {
    ...ModelFields
  }
  recordings_aggregate {
    aggregate {
      count
      max {
        recorded_at
      }
    }
  }
}
    ${ModelFieldsFragmentDoc}`;
export const GroundTruthTypeFieldsFragmentDoc = gql`
    fragment GroundTruthTypeFields on ground_truth_types {
  id
  name
  description
  default_near_threshold
  default_far_threshold
}
    `;
export const InstructionFieldsFragmentDoc = gql`
    fragment InstructionFields on integration_test_instructions {
  id
  description
  instruction_translations {
    language
    description
  }
  instruction_media {
    medium {
      id
      title
      description
      type
      url
    }
  }
}
    `;
export const ExpectationFieldsFragmentDoc = gql`
    fragment ExpectationFields on integration_test_expectations {
  id
  description
  expectation_translations {
    language
    description
  }
  expectation_media {
    medium {
      id
      title
      description
      type
      url
    }
  }
}
    `;
export const IntegrationTestSequenceFieldsFragmentDoc = gql`
    fragment IntegrationTestSequenceFields on integration_test_sequences {
  id
  description
}
    `;
export const ModeFieldsFragmentDoc = gql`
    fragment ModeFields on recording_data_modes {
  id
  name
  description
  created_at
  updated_at
}
    `;
export const OpmodeFieldsFragmentDoc = gql`
    fragment OpmodeFields on opmodes {
  id
  number
  created_at
  updated_at
  deleted_at
}
    `;
export const RatioMetricFieldsFragmentDoc = gql`
    fragment RatioMetricFields on performance_ratio_metrics {
  id
  name
  description
  numerator_unit
  denominator_unit
  display_as_percentage
  sample_unit
  min_num_samples
  source_version
  larger_is_better
  warning_limit
  critical_limit
}
    `;
export const OpmodeProjectFieldsFragmentDoc = gql`
    fragment OpmodeProjectFields on opmode_project {
  opmode_id
  project_id
  opmode {
    number
  }
}
    `;
export const ProjectFieldsFragmentDoc = gql`
    fragment ProjectFields on projects {
  id
  description
  calibration_opmode {
    id
    number
    opmode_profiles {
      profile {
        id
        name
      }
    }
  }
  created_at
  updated_at
  deleted_at
  completed_at
  min_devices_per_task
  slack_channel
  mongo_source_query
  enable_ground_truth_processing
  enable_greatness_processing
  enable_arcadia_processing
  azure_build_ref
  azure_release_ref
  near_threshold
  far_threshold
  firmware_engine_release_ref
  xtensa_configuration {
    id
    value
  }
  simulation_opmode {
    id
    number
    opmode_profiles {
      profile {
        id
        name
      }
    }
  }
  opmode_projects {
    opmode {
      id
      number
    }
  }
  deliverable {
    id
    name
    description
    num_states
    ground_truth_type {
      name
      default_near_threshold
      default_far_threshold
      min_near_threshold
      max_near_threshold
      min_far_threshold
      max_far_threshold
    }
  }
  project_dataset_aggregate {
    aggregate {
      count
    }
  }
  scores(limit: 1, order_by: {timestamp: desc}) {
    greatness
  }
  project_users(
    order_by: [{user: {deleted_at: asc_nulls_first}}, {user: {email: asc}}]
  ) {
    manager
    user {
      id
      email
      name
    }
  }
  variant
  project_name {
    name
    display_name
  }
  dynamic_scaling {
    min_value
    max_value
    benchmark_scaling
  }
  ...ProjectCMD
}
    ${ProjectCmdFragmentDoc}`;
export const SingleProjectFieldsFragmentDoc = gql`
    fragment SingleProjectFields on projects {
  ...ProjectFields
  mode_projects {
    mode_id
    mode {
      id
      name
      mode_parameters {
        value
        parameter {
          name
        }
      }
    }
  }
  recordings_aggregate {
    aggregate {
      count
    }
  }
  project_users(
    order_by: [{user: {deleted_at: asc_nulls_first}}, {user: {email: asc}}]
  ) {
    user_id
    project_id
    manager
    user {
      id
      email
      name
      project_dataset_aggregate(where: {project_id: {_eq: $id}}) {
        aggregate {
          count
        }
      }
      deleted_at
    }
  }
  project_tasks_aggregate {
    aggregate {
      count
    }
  }
  project_hypertasks_aggregate {
    aggregate {
      count
    }
  }
  project_sequences_aggregate {
    aggregate {
      count
    }
  }
  observances_aggregate {
    aggregate {
      count
    }
  }
}
    ${ProjectFieldsFragmentDoc}`;
export const XtensaConfigurationFieldsFragmentDoc = gql`
    fragment XtensaConfigurationFields on xtensa_configurations {
  id
  value
}
    `;
export const RecordingTagFieldsFragmentDoc = gql`
    fragment RecordingTagFields on recording_tags {
  id
  name
  description
  field
  dirty
}
    `;
export const RecordingFieldsFragmentDoc = gql`
    fragment RecordingFields on recordings {
  id
  recorded_at
  task {
    id
    note
    description
    legacy_template_id
  }
  device {
    id
    serialno
  }
  firmware {
    id
    properties
  }
  user {
    id
    email
  }
  project {
    id
    ...ProjectCMD
  }
  engine_opmode_recordings {
    engine {
      id
      branch
      build_ref
      build_version
    }
    opmode {
      id
      number
    }
  }
  tags {
    timestamp
    user {
      id
      name
    }
    recording_tag {
      ...RecordingTagFields
    }
  }
}
    ${ProjectCmdFragmentDoc}
${RecordingTagFieldsFragmentDoc}`;
export const TemplateFieldsFragmentDoc = gql`
    fragment TemplateFields on performance_report_templates {
  id
  name
  description
  user {
    id
    name
  }
  created_at
  updated_at
  deleted_at
}
    `;
export const TaskGroupsHierarchyFieldsFragmentDoc = gql`
    fragment TaskGroupsHierarchyFields on task_groups_hierarchies {
  id
  name
  description
  created_at
  updated_at
  deleted_at
}
    `;
export const UserRolesFieldFragmentDoc = gql`
    fragment UserRolesField on users {
  role_users {
    role_id
  }
}
    `;
export const UserFieldsFragmentDoc = gql`
    fragment UserFields on users {
  id
  email
  name
  description
  created_at
  updated_at
  ...UserRolesField
}
    ${UserRolesFieldFragmentDoc}`;
export const AllBenchmarkPlansDocument = gql`
    query AllBenchmarkPlans($limit: Int, $offset: Int, $where: rabbit_test_plans_bool_exp, $order_by: [rabbit_test_plans_order_by!]) {
  rabbit_test_plans_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  rabbit_test_plans(
    where: $where
    order_by: $order_by
    offset: $offset
    limit: $limit
  ) {
    ...BenchmarkPlanFields
    plan_projects {
      project {
        project_name {
          name
        }
      }
    }
  }
}
    ${BenchmarkPlanFieldsFragmentDoc}`;

/**
 * __useAllBenchmarkPlansQuery__
 *
 * To run a query within a React component, call `useAllBenchmarkPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllBenchmarkPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllBenchmarkPlansQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useAllBenchmarkPlansQuery(baseOptions?: Apollo.QueryHookOptions<AllBenchmarkPlansQuery, AllBenchmarkPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllBenchmarkPlansQuery, AllBenchmarkPlansQueryVariables>(AllBenchmarkPlansDocument, options);
      }
export function useAllBenchmarkPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllBenchmarkPlansQuery, AllBenchmarkPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllBenchmarkPlansQuery, AllBenchmarkPlansQueryVariables>(AllBenchmarkPlansDocument, options);
        }
export type AllBenchmarkPlansQueryHookResult = ReturnType<typeof useAllBenchmarkPlansQuery>;
export type AllBenchmarkPlansLazyQueryHookResult = ReturnType<typeof useAllBenchmarkPlansLazyQuery>;
export type AllBenchmarkPlansQueryResult = Apollo.QueryResult<AllBenchmarkPlansQuery, AllBenchmarkPlansQueryVariables>;
export const BenchmarkPlanDocument = gql`
    query BenchmarkPlan($id: uuid!) {
  rabbit_test_plan: rabbit_test_plans_by_pk(id: $id) {
    ...BenchmarkPlanFields
    plan_tasks {
      task {
        id
        legacy_template_id
      }
    }
  }
}
    ${BenchmarkPlanFieldsFragmentDoc}`;

/**
 * __useBenchmarkPlanQuery__
 *
 * To run a query within a React component, call `useBenchmarkPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenchmarkPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenchmarkPlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBenchmarkPlanQuery(baseOptions: Apollo.QueryHookOptions<BenchmarkPlanQuery, BenchmarkPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BenchmarkPlanQuery, BenchmarkPlanQueryVariables>(BenchmarkPlanDocument, options);
      }
export function useBenchmarkPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BenchmarkPlanQuery, BenchmarkPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BenchmarkPlanQuery, BenchmarkPlanQueryVariables>(BenchmarkPlanDocument, options);
        }
export type BenchmarkPlanQueryHookResult = ReturnType<typeof useBenchmarkPlanQuery>;
export type BenchmarkPlanLazyQueryHookResult = ReturnType<typeof useBenchmarkPlanLazyQuery>;
export type BenchmarkPlanQueryResult = Apollo.QueryResult<BenchmarkPlanQuery, BenchmarkPlanQueryVariables>;
export const CreateBenchmarkPlanDocument = gql`
    mutation CreateBenchmarkPlan($input: rabbit_test_plans_insert_input!) {
  rabbit_test_plan: insert_rabbit_test_plans_one(object: $input) {
    ...BenchmarkPlanFields
  }
}
    ${BenchmarkPlanFieldsFragmentDoc}`;
export type CreateBenchmarkPlanMutationFn = Apollo.MutationFunction<CreateBenchmarkPlanMutation, CreateBenchmarkPlanMutationVariables>;

/**
 * __useCreateBenchmarkPlanMutation__
 *
 * To run a mutation, you first call `useCreateBenchmarkPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBenchmarkPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBenchmarkPlanMutation, { data, loading, error }] = useCreateBenchmarkPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBenchmarkPlanMutation(baseOptions?: Apollo.MutationHookOptions<CreateBenchmarkPlanMutation, CreateBenchmarkPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBenchmarkPlanMutation, CreateBenchmarkPlanMutationVariables>(CreateBenchmarkPlanDocument, options);
      }
export type CreateBenchmarkPlanMutationHookResult = ReturnType<typeof useCreateBenchmarkPlanMutation>;
export type CreateBenchmarkPlanMutationResult = Apollo.MutationResult<CreateBenchmarkPlanMutation>;
export type CreateBenchmarkPlanMutationOptions = Apollo.BaseMutationOptions<CreateBenchmarkPlanMutation, CreateBenchmarkPlanMutationVariables>;
export const UpdateBenchmarkPlanDocument = gql`
    mutation UpdateBenchmarkPlan($id: uuid!, $input: rabbit_test_plans_set_input!, $tasks_to_add: [rabbit_test_plan_task_insert_input!]!, $task_ids_to_remove: [uuid!]!) {
  rabbit_test_plan: update_rabbit_test_plans_by_pk(
    pk_columns: {id: $id}
    _set: $input
  ) {
    ...BenchmarkPlanFields
  }
  add_tasks: insert_rabbit_test_plan_task(objects: $tasks_to_add) {
    affected_rows
  }
  delete_tasks: delete_rabbit_test_plan_task(
    where: {plan_id: {_eq: $id}, task_id: {_in: $task_ids_to_remove}}
  ) {
    affected_rows
  }
}
    ${BenchmarkPlanFieldsFragmentDoc}`;
export type UpdateBenchmarkPlanMutationFn = Apollo.MutationFunction<UpdateBenchmarkPlanMutation, UpdateBenchmarkPlanMutationVariables>;

/**
 * __useUpdateBenchmarkPlanMutation__
 *
 * To run a mutation, you first call `useUpdateBenchmarkPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBenchmarkPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBenchmarkPlanMutation, { data, loading, error }] = useUpdateBenchmarkPlanMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      tasks_to_add: // value for 'tasks_to_add'
 *      task_ids_to_remove: // value for 'task_ids_to_remove'
 *   },
 * });
 */
export function useUpdateBenchmarkPlanMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBenchmarkPlanMutation, UpdateBenchmarkPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBenchmarkPlanMutation, UpdateBenchmarkPlanMutationVariables>(UpdateBenchmarkPlanDocument, options);
      }
export type UpdateBenchmarkPlanMutationHookResult = ReturnType<typeof useUpdateBenchmarkPlanMutation>;
export type UpdateBenchmarkPlanMutationResult = Apollo.MutationResult<UpdateBenchmarkPlanMutation>;
export type UpdateBenchmarkPlanMutationOptions = Apollo.BaseMutationOptions<UpdateBenchmarkPlanMutation, UpdateBenchmarkPlanMutationVariables>;
export const AddProjectBenchmarkPlanDocument = gql`
    mutation AddProjectBenchmarkPlan($projectId: uuid!, $planId: uuid!) {
  insert_rabbit_test_plan_project(
    objects: [{project_id: $projectId, plan_id: $planId}]
  ) {
    affected_rows
  }
}
    `;
export type AddProjectBenchmarkPlanMutationFn = Apollo.MutationFunction<AddProjectBenchmarkPlanMutation, AddProjectBenchmarkPlanMutationVariables>;

/**
 * __useAddProjectBenchmarkPlanMutation__
 *
 * To run a mutation, you first call `useAddProjectBenchmarkPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProjectBenchmarkPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProjectBenchmarkPlanMutation, { data, loading, error }] = useAddProjectBenchmarkPlanMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useAddProjectBenchmarkPlanMutation(baseOptions?: Apollo.MutationHookOptions<AddProjectBenchmarkPlanMutation, AddProjectBenchmarkPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddProjectBenchmarkPlanMutation, AddProjectBenchmarkPlanMutationVariables>(AddProjectBenchmarkPlanDocument, options);
      }
export type AddProjectBenchmarkPlanMutationHookResult = ReturnType<typeof useAddProjectBenchmarkPlanMutation>;
export type AddProjectBenchmarkPlanMutationResult = Apollo.MutationResult<AddProjectBenchmarkPlanMutation>;
export type AddProjectBenchmarkPlanMutationOptions = Apollo.BaseMutationOptions<AddProjectBenchmarkPlanMutation, AddProjectBenchmarkPlanMutationVariables>;
export const RemoveProjectBenchmarkPlanDocument = gql`
    mutation RemoveProjectBenchmarkPlan($projectId: uuid!, $planId: uuid!) {
  delete_rabbit_test_plan_project(
    where: {project_id: {_eq: $projectId}, plan_id: {_eq: $planId}}
  ) {
    affected_rows
  }
}
    `;
export type RemoveProjectBenchmarkPlanMutationFn = Apollo.MutationFunction<RemoveProjectBenchmarkPlanMutation, RemoveProjectBenchmarkPlanMutationVariables>;

/**
 * __useRemoveProjectBenchmarkPlanMutation__
 *
 * To run a mutation, you first call `useRemoveProjectBenchmarkPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProjectBenchmarkPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProjectBenchmarkPlanMutation, { data, loading, error }] = useRemoveProjectBenchmarkPlanMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useRemoveProjectBenchmarkPlanMutation(baseOptions?: Apollo.MutationHookOptions<RemoveProjectBenchmarkPlanMutation, RemoveProjectBenchmarkPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveProjectBenchmarkPlanMutation, RemoveProjectBenchmarkPlanMutationVariables>(RemoveProjectBenchmarkPlanDocument, options);
      }
export type RemoveProjectBenchmarkPlanMutationHookResult = ReturnType<typeof useRemoveProjectBenchmarkPlanMutation>;
export type RemoveProjectBenchmarkPlanMutationResult = Apollo.MutationResult<RemoveProjectBenchmarkPlanMutation>;
export type RemoveProjectBenchmarkPlanMutationOptions = Apollo.BaseMutationOptions<RemoveProjectBenchmarkPlanMutation, RemoveProjectBenchmarkPlanMutationVariables>;
export const AllChecklistsDocument = gql`
    query AllChecklists($limit: Int, $offset: Int, $where: bringup_checklists_bool_exp, $order_by: [bringup_checklists_order_by!]) {
  checklists_aggregate: bringup_checklists_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  checklists: bringup_checklists(
    where: $where
    order_by: $order_by
    offset: $offset
    limit: $limit
  ) {
    ...ChecklistFields
    checklist_deliverables {
      deliverable {
        id
        name
      }
    }
  }
}
    ${ChecklistFieldsFragmentDoc}`;

/**
 * __useAllChecklistsQuery__
 *
 * To run a query within a React component, call `useAllChecklistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllChecklistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllChecklistsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useAllChecklistsQuery(baseOptions?: Apollo.QueryHookOptions<AllChecklistsQuery, AllChecklistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllChecklistsQuery, AllChecklistsQueryVariables>(AllChecklistsDocument, options);
      }
export function useAllChecklistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllChecklistsQuery, AllChecklistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllChecklistsQuery, AllChecklistsQueryVariables>(AllChecklistsDocument, options);
        }
export type AllChecklistsQueryHookResult = ReturnType<typeof useAllChecklistsQuery>;
export type AllChecklistsLazyQueryHookResult = ReturnType<typeof useAllChecklistsLazyQuery>;
export type AllChecklistsQueryResult = Apollo.QueryResult<AllChecklistsQuery, AllChecklistsQueryVariables>;
export const ChecklistDocument = gql`
    query Checklist($id: uuid!) {
  checklist: bringup_checklists_by_pk(id: $id) {
    ...ChecklistFields
    checklist_deliverables {
      deliverable {
        id
        name
      }
    }
    items(order_by: {position: asc}) {
      id
      position
      name
      description
      objective
      personnel
      depends_on_item {
        id
        name
      }
      milestone {
        name
        description
      }
      endorsements_aggregate {
        aggregate {
          count
        }
      }
    }
  }
}
    ${ChecklistFieldsFragmentDoc}`;

/**
 * __useChecklistQuery__
 *
 * To run a query within a React component, call `useChecklistQuery` and pass it any options that fit your needs.
 * When your component renders, `useChecklistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChecklistQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChecklistQuery(baseOptions: Apollo.QueryHookOptions<ChecklistQuery, ChecklistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChecklistQuery, ChecklistQueryVariables>(ChecklistDocument, options);
      }
export function useChecklistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChecklistQuery, ChecklistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChecklistQuery, ChecklistQueryVariables>(ChecklistDocument, options);
        }
export type ChecklistQueryHookResult = ReturnType<typeof useChecklistQuery>;
export type ChecklistLazyQueryHookResult = ReturnType<typeof useChecklistLazyQuery>;
export type ChecklistQueryResult = Apollo.QueryResult<ChecklistQuery, ChecklistQueryVariables>;
export const CreateChecklistDocument = gql`
    mutation CreateChecklist($input: bringup_checklists_insert_input!) {
  checklist: insert_bringup_checklists_one(object: $input) {
    ...ChecklistFields
  }
}
    ${ChecklistFieldsFragmentDoc}`;
export type CreateChecklistMutationFn = Apollo.MutationFunction<CreateChecklistMutation, CreateChecklistMutationVariables>;

/**
 * __useCreateChecklistMutation__
 *
 * To run a mutation, you first call `useCreateChecklistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChecklistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChecklistMutation, { data, loading, error }] = useCreateChecklistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChecklistMutation(baseOptions?: Apollo.MutationHookOptions<CreateChecklistMutation, CreateChecklistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChecklistMutation, CreateChecklistMutationVariables>(CreateChecklistDocument, options);
      }
export type CreateChecklistMutationHookResult = ReturnType<typeof useCreateChecklistMutation>;
export type CreateChecklistMutationResult = Apollo.MutationResult<CreateChecklistMutation>;
export type CreateChecklistMutationOptions = Apollo.BaseMutationOptions<CreateChecklistMutation, CreateChecklistMutationVariables>;
export const UpdateChecklistDocument = gql`
    mutation UpdateChecklist($id: uuid!, $columns: bringup_checklists_set_input!, $items: [bringup_items_insert_input!]!, $item_ids_to_delete: [uuid!]!, $item_ids_without_milestones: [uuid!]!, $milestones: [bringup_milestones_insert_input!]!) {
  delete_bringup_items(
    where: {checklist_id: {_eq: $id}, id: {_in: $item_ids_to_delete}}
  ) {
    affected_rows
  }
  update_bringup_items(where: {checklist_id: {_eq: $id}}, _inc: {position: 1000}) {
    affected_rows
  }
  items: insert_bringup_items(
    objects: $items
    on_conflict: {constraint: items_pkey, update_columns: [position, name, description, objective, personnel, depends_on]}
  ) {
    affected_rows
  }
  inserted_milestones: insert_bringup_milestones(
    objects: $milestones
    on_conflict: {constraint: milestones_pkey, update_columns: [name, description]}
  ) {
    affected_rows
  }
  deleted_milestones: delete_bringup_milestones(
    where: {item_id: {_in: $item_ids_without_milestones}}
  ) {
    affected_rows
  }
  checklist: update_bringup_checklists_by_pk(
    pk_columns: {id: $id}
    _set: $columns
  ) {
    ...ChecklistFields
  }
}
    ${ChecklistFieldsFragmentDoc}`;
export type UpdateChecklistMutationFn = Apollo.MutationFunction<UpdateChecklistMutation, UpdateChecklistMutationVariables>;

/**
 * __useUpdateChecklistMutation__
 *
 * To run a mutation, you first call `useUpdateChecklistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChecklistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChecklistMutation, { data, loading, error }] = useUpdateChecklistMutation({
 *   variables: {
 *      id: // value for 'id'
 *      columns: // value for 'columns'
 *      items: // value for 'items'
 *      item_ids_to_delete: // value for 'item_ids_to_delete'
 *      item_ids_without_milestones: // value for 'item_ids_without_milestones'
 *      milestones: // value for 'milestones'
 *   },
 * });
 */
export function useUpdateChecklistMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChecklistMutation, UpdateChecklistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChecklistMutation, UpdateChecklistMutationVariables>(UpdateChecklistDocument, options);
      }
export type UpdateChecklistMutationHookResult = ReturnType<typeof useUpdateChecklistMutation>;
export type UpdateChecklistMutationResult = Apollo.MutationResult<UpdateChecklistMutation>;
export type UpdateChecklistMutationOptions = Apollo.BaseMutationOptions<UpdateChecklistMutation, UpdateChecklistMutationVariables>;
export const InsertChecklistItemEndorsementDocument = gql`
    mutation InsertChecklistItemEndorsement($project_id: uuid!, $item_id: uuid!, $user_id: uuid!) {
  insert_bringup_endorsements_one(
    object: {project_id: $project_id, item_id: $item_id, created_by: $user_id}
  ) {
    project_id
    item_id
    created_by
  }
}
    `;
export type InsertChecklistItemEndorsementMutationFn = Apollo.MutationFunction<InsertChecklistItemEndorsementMutation, InsertChecklistItemEndorsementMutationVariables>;

/**
 * __useInsertChecklistItemEndorsementMutation__
 *
 * To run a mutation, you first call `useInsertChecklistItemEndorsementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertChecklistItemEndorsementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertChecklistItemEndorsementMutation, { data, loading, error }] = useInsertChecklistItemEndorsementMutation({
 *   variables: {
 *      project_id: // value for 'project_id'
 *      item_id: // value for 'item_id'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useInsertChecklistItemEndorsementMutation(baseOptions?: Apollo.MutationHookOptions<InsertChecklistItemEndorsementMutation, InsertChecklistItemEndorsementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertChecklistItemEndorsementMutation, InsertChecklistItemEndorsementMutationVariables>(InsertChecklistItemEndorsementDocument, options);
      }
export type InsertChecklistItemEndorsementMutationHookResult = ReturnType<typeof useInsertChecklistItemEndorsementMutation>;
export type InsertChecklistItemEndorsementMutationResult = Apollo.MutationResult<InsertChecklistItemEndorsementMutation>;
export type InsertChecklistItemEndorsementMutationOptions = Apollo.BaseMutationOptions<InsertChecklistItemEndorsementMutation, InsertChecklistItemEndorsementMutationVariables>;
export const DeleteChecklistItemEndorsementsDocument = gql`
    mutation DeleteChecklistItemEndorsements($project_id: uuid!, $item_id: uuid!) {
  delete_bringup_endorsements(
    where: {project_id: {_eq: $project_id}, item_id: {_eq: $item_id}}
  ) {
    returning {
      project_id
      item_id
    }
  }
}
    `;
export type DeleteChecklistItemEndorsementsMutationFn = Apollo.MutationFunction<DeleteChecklistItemEndorsementsMutation, DeleteChecklistItemEndorsementsMutationVariables>;

/**
 * __useDeleteChecklistItemEndorsementsMutation__
 *
 * To run a mutation, you first call `useDeleteChecklistItemEndorsementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChecklistItemEndorsementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChecklistItemEndorsementsMutation, { data, loading, error }] = useDeleteChecklistItemEndorsementsMutation({
 *   variables: {
 *      project_id: // value for 'project_id'
 *      item_id: // value for 'item_id'
 *   },
 * });
 */
export function useDeleteChecklistItemEndorsementsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChecklistItemEndorsementsMutation, DeleteChecklistItemEndorsementsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteChecklistItemEndorsementsMutation, DeleteChecklistItemEndorsementsMutationVariables>(DeleteChecklistItemEndorsementsDocument, options);
      }
export type DeleteChecklistItemEndorsementsMutationHookResult = ReturnType<typeof useDeleteChecklistItemEndorsementsMutation>;
export type DeleteChecklistItemEndorsementsMutationResult = Apollo.MutationResult<DeleteChecklistItemEndorsementsMutation>;
export type DeleteChecklistItemEndorsementsMutationOptions = Apollo.BaseMutationOptions<DeleteChecklistItemEndorsementsMutation, DeleteChecklistItemEndorsementsMutationVariables>;
export const DeleteChecklistItemUserEndorsementsDocument = gql`
    mutation DeleteChecklistItemUserEndorsements($project_id: uuid!, $item_id: uuid!, $user_id: uuid!) {
  delete_bringup_endorsements(
    where: {project_id: {_eq: $project_id}, item_id: {_eq: $item_id}, created_by: {_eq: $user_id}}
  ) {
    returning {
      project_id
      item_id
      created_by
    }
  }
}
    `;
export type DeleteChecklistItemUserEndorsementsMutationFn = Apollo.MutationFunction<DeleteChecklistItemUserEndorsementsMutation, DeleteChecklistItemUserEndorsementsMutationVariables>;

/**
 * __useDeleteChecklistItemUserEndorsementsMutation__
 *
 * To run a mutation, you first call `useDeleteChecklistItemUserEndorsementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChecklistItemUserEndorsementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChecklistItemUserEndorsementsMutation, { data, loading, error }] = useDeleteChecklistItemUserEndorsementsMutation({
 *   variables: {
 *      project_id: // value for 'project_id'
 *      item_id: // value for 'item_id'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useDeleteChecklistItemUserEndorsementsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChecklistItemUserEndorsementsMutation, DeleteChecklistItemUserEndorsementsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteChecklistItemUserEndorsementsMutation, DeleteChecklistItemUserEndorsementsMutationVariables>(DeleteChecklistItemUserEndorsementsDocument, options);
      }
export type DeleteChecklistItemUserEndorsementsMutationHookResult = ReturnType<typeof useDeleteChecklistItemUserEndorsementsMutation>;
export type DeleteChecklistItemUserEndorsementsMutationResult = Apollo.MutationResult<DeleteChecklistItemUserEndorsementsMutation>;
export type DeleteChecklistItemUserEndorsementsMutationOptions = Apollo.BaseMutationOptions<DeleteChecklistItemUserEndorsementsMutation, DeleteChecklistItemUserEndorsementsMutationVariables>;
export const InsertChecklistItemCommentDocument = gql`
    mutation InsertChecklistItemComment($obj: bringup_project_item_comments_insert_input!) {
  insert_bringup_project_item_comments_one(object: $obj) {
    project_id
    item_id
    created_at
  }
}
    `;
export type InsertChecklistItemCommentMutationFn = Apollo.MutationFunction<InsertChecklistItemCommentMutation, InsertChecklistItemCommentMutationVariables>;

/**
 * __useInsertChecklistItemCommentMutation__
 *
 * To run a mutation, you first call `useInsertChecklistItemCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertChecklistItemCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertChecklistItemCommentMutation, { data, loading, error }] = useInsertChecklistItemCommentMutation({
 *   variables: {
 *      obj: // value for 'obj'
 *   },
 * });
 */
export function useInsertChecklistItemCommentMutation(baseOptions?: Apollo.MutationHookOptions<InsertChecklistItemCommentMutation, InsertChecklistItemCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertChecklistItemCommentMutation, InsertChecklistItemCommentMutationVariables>(InsertChecklistItemCommentDocument, options);
      }
export type InsertChecklistItemCommentMutationHookResult = ReturnType<typeof useInsertChecklistItemCommentMutation>;
export type InsertChecklistItemCommentMutationResult = Apollo.MutationResult<InsertChecklistItemCommentMutation>;
export type InsertChecklistItemCommentMutationOptions = Apollo.BaseMutationOptions<InsertChecklistItemCommentMutation, InsertChecklistItemCommentMutationVariables>;
export const DeleteChecklistItemCommentDocument = gql`
    mutation DeleteChecklistItemComment($project_id: uuid!, $item_id: uuid!, $created_at: timestamptz!, $deleted_at: timestamp!) {
  update_bringup_project_item_comments_by_pk(
    pk_columns: {project_id: $project_id, item_id: $item_id, created_at: $created_at}
    _set: {deleted_at: $deleted_at}
  ) {
    project_id
    item_id
    created_at
    deleted_at
  }
}
    `;
export type DeleteChecklistItemCommentMutationFn = Apollo.MutationFunction<DeleteChecklistItemCommentMutation, DeleteChecklistItemCommentMutationVariables>;

/**
 * __useDeleteChecklistItemCommentMutation__
 *
 * To run a mutation, you first call `useDeleteChecklistItemCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChecklistItemCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChecklistItemCommentMutation, { data, loading, error }] = useDeleteChecklistItemCommentMutation({
 *   variables: {
 *      project_id: // value for 'project_id'
 *      item_id: // value for 'item_id'
 *      created_at: // value for 'created_at'
 *      deleted_at: // value for 'deleted_at'
 *   },
 * });
 */
export function useDeleteChecklistItemCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChecklistItemCommentMutation, DeleteChecklistItemCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteChecklistItemCommentMutation, DeleteChecklistItemCommentMutationVariables>(DeleteChecklistItemCommentDocument, options);
      }
export type DeleteChecklistItemCommentMutationHookResult = ReturnType<typeof useDeleteChecklistItemCommentMutation>;
export type DeleteChecklistItemCommentMutationResult = Apollo.MutationResult<DeleteChecklistItemCommentMutation>;
export type DeleteChecklistItemCommentMutationOptions = Apollo.BaseMutationOptions<DeleteChecklistItemCommentMutation, DeleteChecklistItemCommentMutationVariables>;
export const UpdateChecklistItemCommentDocument = gql`
    mutation UpdateChecklistItemComment($project_id: uuid!, $item_id: uuid!, $created_at: timestamptz!, $content: String!) {
  update_bringup_project_item_comments_by_pk(
    pk_columns: {project_id: $project_id, item_id: $item_id, created_at: $created_at}
    _set: {content: $content}
  ) {
    project_id
    item_id
    created_at
    content
  }
}
    `;
export type UpdateChecklistItemCommentMutationFn = Apollo.MutationFunction<UpdateChecklistItemCommentMutation, UpdateChecklistItemCommentMutationVariables>;

/**
 * __useUpdateChecklistItemCommentMutation__
 *
 * To run a mutation, you first call `useUpdateChecklistItemCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChecklistItemCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChecklistItemCommentMutation, { data, loading, error }] = useUpdateChecklistItemCommentMutation({
 *   variables: {
 *      project_id: // value for 'project_id'
 *      item_id: // value for 'item_id'
 *      created_at: // value for 'created_at'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useUpdateChecklistItemCommentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChecklistItemCommentMutation, UpdateChecklistItemCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChecklistItemCommentMutation, UpdateChecklistItemCommentMutationVariables>(UpdateChecklistItemCommentDocument, options);
      }
export type UpdateChecklistItemCommentMutationHookResult = ReturnType<typeof useUpdateChecklistItemCommentMutation>;
export type UpdateChecklistItemCommentMutationResult = Apollo.MutationResult<UpdateChecklistItemCommentMutation>;
export type UpdateChecklistItemCommentMutationOptions = Apollo.BaseMutationOptions<UpdateChecklistItemCommentMutation, UpdateChecklistItemCommentMutationVariables>;
export const AllCategoriesDocument = gql`
    query AllCategories($limit: Int, $offset: Int, $where: categories_bool_exp, $order_by: [categories_order_by!]) {
  categories_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  categories(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
    ...CategoryFields
    contexts_aggregate {
      aggregate {
        count
      }
    }
    contexts {
      ...ContextFields
    }
  }
}
    ${CategoryFieldsFragmentDoc}
${ContextFieldsFragmentDoc}`;

/**
 * __useAllCategoriesQuery__
 *
 * To run a query within a React component, call `useAllCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCategoriesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useAllCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<AllCategoriesQuery, AllCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCategoriesQuery, AllCategoriesQueryVariables>(AllCategoriesDocument, options);
      }
export function useAllCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCategoriesQuery, AllCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCategoriesQuery, AllCategoriesQueryVariables>(AllCategoriesDocument, options);
        }
export type AllCategoriesQueryHookResult = ReturnType<typeof useAllCategoriesQuery>;
export type AllCategoriesLazyQueryHookResult = ReturnType<typeof useAllCategoriesLazyQuery>;
export type AllCategoriesQueryResult = Apollo.QueryResult<AllCategoriesQuery, AllCategoriesQueryVariables>;
export const CategoryDocument = gql`
    query Category($id: uuid!) {
  category: categories_by_pk(id: $id) {
    ...CategoryFields
    contexts {
      ...ContextFields
    }
  }
}
    ${CategoryFieldsFragmentDoc}
${ContextFieldsFragmentDoc}`;

/**
 * __useCategoryQuery__
 *
 * To run a query within a React component, call `useCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCategoryQuery(baseOptions: Apollo.QueryHookOptions<CategoryQuery, CategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, options);
      }
export function useCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryQuery, CategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, options);
        }
export type CategoryQueryHookResult = ReturnType<typeof useCategoryQuery>;
export type CategoryLazyQueryHookResult = ReturnType<typeof useCategoryLazyQuery>;
export type CategoryQueryResult = Apollo.QueryResult<CategoryQuery, CategoryQueryVariables>;
export const CreateCategoryDocument = gql`
    mutation CreateCategory($input: categories_insert_input!) {
  category: insert_categories_one(object: $input) {
    ...CategoryFields
  }
}
    ${CategoryFieldsFragmentDoc}`;
export type CreateCategoryMutationFn = Apollo.MutationFunction<CreateCategoryMutation, CreateCategoryMutationVariables>;

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCategoryMutation, CreateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, options);
      }
export type CreateCategoryMutationHookResult = ReturnType<typeof useCreateCategoryMutation>;
export type CreateCategoryMutationResult = Apollo.MutationResult<CreateCategoryMutation>;
export type CreateCategoryMutationOptions = Apollo.BaseMutationOptions<CreateCategoryMutation, CreateCategoryMutationVariables>;
export const UpdateCategoryDocument = gql`
    mutation UpdateCategory($id: uuid!, $input: categories_set_input!) {
  category: update_categories_by_pk(pk_columns: {id: $id}, _set: $input) {
    ...CategoryFields
  }
}
    ${CategoryFieldsFragmentDoc}`;
export type UpdateCategoryMutationFn = Apollo.MutationFunction<UpdateCategoryMutation, UpdateCategoryMutationVariables>;

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryMutation, { data, loading, error }] = useUpdateCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(UpdateCategoryDocument, options);
      }
export type UpdateCategoryMutationHookResult = ReturnType<typeof useUpdateCategoryMutation>;
export type UpdateCategoryMutationResult = Apollo.MutationResult<UpdateCategoryMutation>;
export type UpdateCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>;
export const AllContextsDocument = gql`
    query AllContexts($limit: Int, $offset: Int, $where: contexts_bool_exp, $order_by: [contexts_order_by!]) {
  contexts_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  contexts(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
    ...ContextFields
    fields_aggregate {
      aggregate {
        count
      }
    }
  }
}
    ${ContextFieldsFragmentDoc}`;

/**
 * __useAllContextsQuery__
 *
 * To run a query within a React component, call `useAllContextsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllContextsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllContextsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useAllContextsQuery(baseOptions?: Apollo.QueryHookOptions<AllContextsQuery, AllContextsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllContextsQuery, AllContextsQueryVariables>(AllContextsDocument, options);
      }
export function useAllContextsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllContextsQuery, AllContextsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllContextsQuery, AllContextsQueryVariables>(AllContextsDocument, options);
        }
export type AllContextsQueryHookResult = ReturnType<typeof useAllContextsQuery>;
export type AllContextsLazyQueryHookResult = ReturnType<typeof useAllContextsLazyQuery>;
export type AllContextsQueryResult = Apollo.QueryResult<AllContextsQuery, AllContextsQueryVariables>;
export const ContextDocument = gql`
    query Context($id: uuid!) {
  context: contexts_by_pk(id: $id) {
    ...ContextFields
    fields {
      ...FieldFields
    }
    context_displays(order_by: {position: asc}) {
      id
      name
      position
      context_display_fields(order_by: {position: asc}) {
        field_id
        field {
          id
          display
        }
        position
      }
    }
  }
}
    ${ContextFieldsFragmentDoc}
${FieldFieldsFragmentDoc}`;

/**
 * __useContextQuery__
 *
 * To run a query within a React component, call `useContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContextQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContextQuery(baseOptions: Apollo.QueryHookOptions<ContextQuery, ContextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContextQuery, ContextQueryVariables>(ContextDocument, options);
      }
export function useContextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContextQuery, ContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContextQuery, ContextQueryVariables>(ContextDocument, options);
        }
export type ContextQueryHookResult = ReturnType<typeof useContextQuery>;
export type ContextLazyQueryHookResult = ReturnType<typeof useContextLazyQuery>;
export type ContextQueryResult = Apollo.QueryResult<ContextQuery, ContextQueryVariables>;
export const CreateContextDocument = gql`
    mutation CreateContext($input: contexts_insert_input!) {
  context: insert_contexts_one(object: $input) {
    ...ContextFields
  }
}
    ${ContextFieldsFragmentDoc}`;
export type CreateContextMutationFn = Apollo.MutationFunction<CreateContextMutation, CreateContextMutationVariables>;

/**
 * __useCreateContextMutation__
 *
 * To run a mutation, you first call `useCreateContextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContextMutation, { data, loading, error }] = useCreateContextMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContextMutation(baseOptions?: Apollo.MutationHookOptions<CreateContextMutation, CreateContextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContextMutation, CreateContextMutationVariables>(CreateContextDocument, options);
      }
export type CreateContextMutationHookResult = ReturnType<typeof useCreateContextMutation>;
export type CreateContextMutationResult = Apollo.MutationResult<CreateContextMutation>;
export type CreateContextMutationOptions = Apollo.BaseMutationOptions<CreateContextMutation, CreateContextMutationVariables>;
export const UpdateContextDocument = gql`
    mutation UpdateContext($id: uuid!, $input: contexts_set_input!) {
  context: update_contexts_by_pk(pk_columns: {id: $id}, _set: $input) {
    ...ContextFields
  }
}
    ${ContextFieldsFragmentDoc}`;
export type UpdateContextMutationFn = Apollo.MutationFunction<UpdateContextMutation, UpdateContextMutationVariables>;

/**
 * __useUpdateContextMutation__
 *
 * To run a mutation, you first call `useUpdateContextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContextMutation, { data, loading, error }] = useUpdateContextMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContextMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContextMutation, UpdateContextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContextMutation, UpdateContextMutationVariables>(UpdateContextDocument, options);
      }
export type UpdateContextMutationHookResult = ReturnType<typeof useUpdateContextMutation>;
export type UpdateContextMutationResult = Apollo.MutationResult<UpdateContextMutation>;
export type UpdateContextMutationOptions = Apollo.BaseMutationOptions<UpdateContextMutation, UpdateContextMutationVariables>;
export const UpdateContextDisplaysDocument = gql`
    mutation UpdateContextDisplays($context_id: uuid!, $insert_context_displays: [context_displays_insert_input!]!) {
  delete_context_display_field(where: {context_id: {_eq: $context_id}}) {
    affected_rows
  }
  delete_context_displays(where: {context_id: {_eq: $context_id}}) {
    affected_rows
  }
  insert_context_displays(objects: $insert_context_displays) {
    affected_rows
  }
}
    `;
export type UpdateContextDisplaysMutationFn = Apollo.MutationFunction<UpdateContextDisplaysMutation, UpdateContextDisplaysMutationVariables>;

/**
 * __useUpdateContextDisplaysMutation__
 *
 * To run a mutation, you first call `useUpdateContextDisplaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContextDisplaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContextDisplaysMutation, { data, loading, error }] = useUpdateContextDisplaysMutation({
 *   variables: {
 *      context_id: // value for 'context_id'
 *      insert_context_displays: // value for 'insert_context_displays'
 *   },
 * });
 */
export function useUpdateContextDisplaysMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContextDisplaysMutation, UpdateContextDisplaysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContextDisplaysMutation, UpdateContextDisplaysMutationVariables>(UpdateContextDisplaysDocument, options);
      }
export type UpdateContextDisplaysMutationHookResult = ReturnType<typeof useUpdateContextDisplaysMutation>;
export type UpdateContextDisplaysMutationResult = Apollo.MutationResult<UpdateContextDisplaysMutation>;
export type UpdateContextDisplaysMutationOptions = Apollo.BaseMutationOptions<UpdateContextDisplaysMutation, UpdateContextDisplaysMutationVariables>;
export const AllFieldsDocument = gql`
    query AllFields($limit: Int, $offset: Int, $order_by: [fields_order_by!], $where: fields_bool_exp) {
  fields_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  fields(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
    ...FieldFields
    field_options_aggregate {
      aggregate {
        count
      }
    }
    field_options {
      id
      option {
        id
        display
      }
    }
    context {
      ...ContextFields
      category {
        ...CategoryFields
      }
    }
    type {
      ...TypeFields
    }
  }
}
    ${FieldFieldsFragmentDoc}
${ContextFieldsFragmentDoc}
${CategoryFieldsFragmentDoc}
${TypeFieldsFragmentDoc}`;

/**
 * __useAllFieldsQuery__
 *
 * To run a query within a React component, call `useAllFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllFieldsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllFieldsQuery(baseOptions?: Apollo.QueryHookOptions<AllFieldsQuery, AllFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllFieldsQuery, AllFieldsQueryVariables>(AllFieldsDocument, options);
      }
export function useAllFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllFieldsQuery, AllFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllFieldsQuery, AllFieldsQueryVariables>(AllFieldsDocument, options);
        }
export type AllFieldsQueryHookResult = ReturnType<typeof useAllFieldsQuery>;
export type AllFieldsLazyQueryHookResult = ReturnType<typeof useAllFieldsLazyQuery>;
export type AllFieldsQueryResult = Apollo.QueryResult<AllFieldsQuery, AllFieldsQueryVariables>;
export const FieldDocument = gql`
    query Field($id: uuid!) {
  field: fields_by_pk(id: $id) {
    ...FieldFields
    context {
      ...ContextFields
      category {
        ...CategoryFields
      }
    }
    field_options {
      ...FieldOptionFields
    }
    type {
      ...TypeFields
    }
  }
}
    ${FieldFieldsFragmentDoc}
${ContextFieldsFragmentDoc}
${CategoryFieldsFragmentDoc}
${FieldOptionFieldsFragmentDoc}
${TypeFieldsFragmentDoc}`;

/**
 * __useFieldQuery__
 *
 * To run a query within a React component, call `useFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFieldQuery(baseOptions: Apollo.QueryHookOptions<FieldQuery, FieldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FieldQuery, FieldQueryVariables>(FieldDocument, options);
      }
export function useFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FieldQuery, FieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FieldQuery, FieldQueryVariables>(FieldDocument, options);
        }
export type FieldQueryHookResult = ReturnType<typeof useFieldQuery>;
export type FieldLazyQueryHookResult = ReturnType<typeof useFieldLazyQuery>;
export type FieldQueryResult = Apollo.QueryResult<FieldQuery, FieldQueryVariables>;
export const CreateFieldDocument = gql`
    mutation CreateField($input: fields_insert_input!) {
  field: insert_fields_one(object: $input) {
    ...FieldFields
  }
}
    ${FieldFieldsFragmentDoc}`;
export type CreateFieldMutationFn = Apollo.MutationFunction<CreateFieldMutation, CreateFieldMutationVariables>;

/**
 * __useCreateFieldMutation__
 *
 * To run a mutation, you first call `useCreateFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFieldMutation, { data, loading, error }] = useCreateFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFieldMutation(baseOptions?: Apollo.MutationHookOptions<CreateFieldMutation, CreateFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFieldMutation, CreateFieldMutationVariables>(CreateFieldDocument, options);
      }
export type CreateFieldMutationHookResult = ReturnType<typeof useCreateFieldMutation>;
export type CreateFieldMutationResult = Apollo.MutationResult<CreateFieldMutation>;
export type CreateFieldMutationOptions = Apollo.BaseMutationOptions<CreateFieldMutation, CreateFieldMutationVariables>;
export const UpdateFieldDocument = gql`
    mutation UpdateField($id: uuid!, $input: fields_set_input!) {
  field: update_fields_by_pk(pk_columns: {id: $id}, _set: $input) {
    ...FieldFields
  }
}
    ${FieldFieldsFragmentDoc}`;
export type UpdateFieldMutationFn = Apollo.MutationFunction<UpdateFieldMutation, UpdateFieldMutationVariables>;

/**
 * __useUpdateFieldMutation__
 *
 * To run a mutation, you first call `useUpdateFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFieldMutation, { data, loading, error }] = useUpdateFieldMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFieldMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFieldMutation, UpdateFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFieldMutation, UpdateFieldMutationVariables>(UpdateFieldDocument, options);
      }
export type UpdateFieldMutationHookResult = ReturnType<typeof useUpdateFieldMutation>;
export type UpdateFieldMutationResult = Apollo.MutationResult<UpdateFieldMutation>;
export type UpdateFieldMutationOptions = Apollo.BaseMutationOptions<UpdateFieldMutation, UpdateFieldMutationVariables>;
export const FieldTranslationsDocument = gql`
    subscription FieldTranslations($id: uuid!, $order_by: [field_translation_order_by!]) {
  translations: field_translation(
    where: {field_id: {_eq: $id}}
    order_by: $order_by
  ) {
    language: field_translation_language {
      id
      description
    }
    description
    display
  }
}
    `;

/**
 * __useFieldTranslationsSubscription__
 *
 * To run a query within a React component, call `useFieldTranslationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useFieldTranslationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldTranslationsSubscription({
 *   variables: {
 *      id: // value for 'id'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useFieldTranslationsSubscription(baseOptions: Apollo.SubscriptionHookOptions<FieldTranslationsSubscription, FieldTranslationsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<FieldTranslationsSubscription, FieldTranslationsSubscriptionVariables>(FieldTranslationsDocument, options);
      }
export type FieldTranslationsSubscriptionHookResult = ReturnType<typeof useFieldTranslationsSubscription>;
export type FieldTranslationsSubscriptionResult = Apollo.SubscriptionResult<FieldTranslationsSubscription>;
export const UpsertFieldTranslationDocument = gql`
    mutation UpsertFieldTranslation($object: field_translation_insert_input!) {
  translation: insert_field_translation_one(
    object: $object
    on_conflict: {constraint: field_translation_pkey, update_columns: [display, description]}
  ) {
    description
    display
    field_id
    language
  }
}
    `;
export type UpsertFieldTranslationMutationFn = Apollo.MutationFunction<UpsertFieldTranslationMutation, UpsertFieldTranslationMutationVariables>;

/**
 * __useUpsertFieldTranslationMutation__
 *
 * To run a mutation, you first call `useUpsertFieldTranslationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertFieldTranslationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertFieldTranslationMutation, { data, loading, error }] = useUpsertFieldTranslationMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpsertFieldTranslationMutation(baseOptions?: Apollo.MutationHookOptions<UpsertFieldTranslationMutation, UpsertFieldTranslationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertFieldTranslationMutation, UpsertFieldTranslationMutationVariables>(UpsertFieldTranslationDocument, options);
      }
export type UpsertFieldTranslationMutationHookResult = ReturnType<typeof useUpsertFieldTranslationMutation>;
export type UpsertFieldTranslationMutationResult = Apollo.MutationResult<UpsertFieldTranslationMutation>;
export type UpsertFieldTranslationMutationOptions = Apollo.BaseMutationOptions<UpsertFieldTranslationMutation, UpsertFieldTranslationMutationVariables>;
export const AllTypesDocument = gql`
    query AllTypes($limit: Int, $offset: Int, $order_by: [types_order_by!], $where: types_bool_exp) {
  types(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
    ...TypeFields
  }
  types_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    ${TypeFieldsFragmentDoc}`;

/**
 * __useAllTypesQuery__
 *
 * To run a query within a React component, call `useAllTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTypesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllTypesQuery(baseOptions?: Apollo.QueryHookOptions<AllTypesQuery, AllTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllTypesQuery, AllTypesQueryVariables>(AllTypesDocument, options);
      }
export function useAllTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTypesQuery, AllTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllTypesQuery, AllTypesQueryVariables>(AllTypesDocument, options);
        }
export type AllTypesQueryHookResult = ReturnType<typeof useAllTypesQuery>;
export type AllTypesLazyQueryHookResult = ReturnType<typeof useAllTypesLazyQuery>;
export type AllTypesQueryResult = Apollo.QueryResult<AllTypesQuery, AllTypesQueryVariables>;
export const AllOptionsDocument = gql`
    query AllOptions($limit: Int, $offset: Int, $order_by: [options_order_by!], $where: options_bool_exp) {
  options_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  options(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
    ...OptionFields
  }
}
    ${OptionFieldsFragmentDoc}`;

/**
 * __useAllOptionsQuery__
 *
 * To run a query within a React component, call `useAllOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllOptionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllOptionsQuery(baseOptions?: Apollo.QueryHookOptions<AllOptionsQuery, AllOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllOptionsQuery, AllOptionsQueryVariables>(AllOptionsDocument, options);
      }
export function useAllOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllOptionsQuery, AllOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllOptionsQuery, AllOptionsQueryVariables>(AllOptionsDocument, options);
        }
export type AllOptionsQueryHookResult = ReturnType<typeof useAllOptionsQuery>;
export type AllOptionsLazyQueryHookResult = ReturnType<typeof useAllOptionsLazyQuery>;
export type AllOptionsQueryResult = Apollo.QueryResult<AllOptionsQuery, AllOptionsQueryVariables>;
export const OptionsDocument = gql`
    query Options($order_by: [options_order_by!], $where: options_bool_exp) {
  options(order_by: $order_by, where: $where) {
    ...OptionFields
  }
}
    ${OptionFieldsFragmentDoc}`;

/**
 * __useOptionsQuery__
 *
 * To run a query within a React component, call `useOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptionsQuery({
 *   variables: {
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOptionsQuery(baseOptions?: Apollo.QueryHookOptions<OptionsQuery, OptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OptionsQuery, OptionsQueryVariables>(OptionsDocument, options);
      }
export function useOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OptionsQuery, OptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OptionsQuery, OptionsQueryVariables>(OptionsDocument, options);
        }
export type OptionsQueryHookResult = ReturnType<typeof useOptionsQuery>;
export type OptionsLazyQueryHookResult = ReturnType<typeof useOptionsLazyQuery>;
export type OptionsQueryResult = Apollo.QueryResult<OptionsQuery, OptionsQueryVariables>;
export const OptionDocument = gql`
    query Option($id: uuid!) {
  option: options_by_pk(id: $id) {
    ...OptionFields
    type {
      ...TypeFields
    }
  }
}
    ${OptionFieldsFragmentDoc}
${TypeFieldsFragmentDoc}`;

/**
 * __useOptionQuery__
 *
 * To run a query within a React component, call `useOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOptionQuery(baseOptions: Apollo.QueryHookOptions<OptionQuery, OptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OptionQuery, OptionQueryVariables>(OptionDocument, options);
      }
export function useOptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OptionQuery, OptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OptionQuery, OptionQueryVariables>(OptionDocument, options);
        }
export type OptionQueryHookResult = ReturnType<typeof useOptionQuery>;
export type OptionLazyQueryHookResult = ReturnType<typeof useOptionLazyQuery>;
export type OptionQueryResult = Apollo.QueryResult<OptionQuery, OptionQueryVariables>;
export const InsertOptionDocument = gql`
    mutation InsertOption($input: options_insert_input!) {
  option: insert_options_one(object: $input) {
    ...OptionFields
  }
}
    ${OptionFieldsFragmentDoc}`;
export type InsertOptionMutationFn = Apollo.MutationFunction<InsertOptionMutation, InsertOptionMutationVariables>;

/**
 * __useInsertOptionMutation__
 *
 * To run a mutation, you first call `useInsertOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertOptionMutation, { data, loading, error }] = useInsertOptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsertOptionMutation(baseOptions?: Apollo.MutationHookOptions<InsertOptionMutation, InsertOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertOptionMutation, InsertOptionMutationVariables>(InsertOptionDocument, options);
      }
export type InsertOptionMutationHookResult = ReturnType<typeof useInsertOptionMutation>;
export type InsertOptionMutationResult = Apollo.MutationResult<InsertOptionMutation>;
export type InsertOptionMutationOptions = Apollo.BaseMutationOptions<InsertOptionMutation, InsertOptionMutationVariables>;
export const CreateOptionDocument = gql`
    mutation CreateOption($input: options_insert_input!) {
  option: insert_options_one(object: $input) {
    ...OptionFields
  }
}
    ${OptionFieldsFragmentDoc}`;
export type CreateOptionMutationFn = Apollo.MutationFunction<CreateOptionMutation, CreateOptionMutationVariables>;

/**
 * __useCreateOptionMutation__
 *
 * To run a mutation, you first call `useCreateOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOptionMutation, { data, loading, error }] = useCreateOptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateOptionMutation, CreateOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOptionMutation, CreateOptionMutationVariables>(CreateOptionDocument, options);
      }
export type CreateOptionMutationHookResult = ReturnType<typeof useCreateOptionMutation>;
export type CreateOptionMutationResult = Apollo.MutationResult<CreateOptionMutation>;
export type CreateOptionMutationOptions = Apollo.BaseMutationOptions<CreateOptionMutation, CreateOptionMutationVariables>;
export const UpdateOptionDocument = gql`
    mutation UpdateOption($id: uuid!, $input: options_set_input!) {
  option: update_options_by_pk(pk_columns: {id: $id}, _set: $input) {
    ...OptionFields
  }
}
    ${OptionFieldsFragmentDoc}`;
export type UpdateOptionMutationFn = Apollo.MutationFunction<UpdateOptionMutation, UpdateOptionMutationVariables>;

/**
 * __useUpdateOptionMutation__
 *
 * To run a mutation, you first call `useUpdateOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOptionMutation, { data, loading, error }] = useUpdateOptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOptionMutation, UpdateOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOptionMutation, UpdateOptionMutationVariables>(UpdateOptionDocument, options);
      }
export type UpdateOptionMutationHookResult = ReturnType<typeof useUpdateOptionMutation>;
export type UpdateOptionMutationResult = Apollo.MutationResult<UpdateOptionMutation>;
export type UpdateOptionMutationOptions = Apollo.BaseMutationOptions<UpdateOptionMutation, UpdateOptionMutationVariables>;
export const OptionTranslationsDocument = gql`
    subscription OptionTranslations($id: uuid!, $order_by: [option_translation_order_by!]) {
  translations: option_translation(
    where: {option_id: {_eq: $id}}
    order_by: $order_by
  ) {
    language: option_translation_language {
      id
      description
    }
    display
  }
}
    `;

/**
 * __useOptionTranslationsSubscription__
 *
 * To run a query within a React component, call `useOptionTranslationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOptionTranslationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptionTranslationsSubscription({
 *   variables: {
 *      id: // value for 'id'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useOptionTranslationsSubscription(baseOptions: Apollo.SubscriptionHookOptions<OptionTranslationsSubscription, OptionTranslationsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OptionTranslationsSubscription, OptionTranslationsSubscriptionVariables>(OptionTranslationsDocument, options);
      }
export type OptionTranslationsSubscriptionHookResult = ReturnType<typeof useOptionTranslationsSubscription>;
export type OptionTranslationsSubscriptionResult = Apollo.SubscriptionResult<OptionTranslationsSubscription>;
export const UpsertOptionTranslationDocument = gql`
    mutation UpsertOptionTranslation($object: option_translation_insert_input!) {
  translation: insert_option_translation_one(
    object: $object
    on_conflict: {constraint: option_translation_pkey, update_columns: [display]}
  ) {
    option_id
    language
    display
  }
}
    `;
export type UpsertOptionTranslationMutationFn = Apollo.MutationFunction<UpsertOptionTranslationMutation, UpsertOptionTranslationMutationVariables>;

/**
 * __useUpsertOptionTranslationMutation__
 *
 * To run a mutation, you first call `useUpsertOptionTranslationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertOptionTranslationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertOptionTranslationMutation, { data, loading, error }] = useUpsertOptionTranslationMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpsertOptionTranslationMutation(baseOptions?: Apollo.MutationHookOptions<UpsertOptionTranslationMutation, UpsertOptionTranslationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertOptionTranslationMutation, UpsertOptionTranslationMutationVariables>(UpsertOptionTranslationDocument, options);
      }
export type UpsertOptionTranslationMutationHookResult = ReturnType<typeof useUpsertOptionTranslationMutation>;
export type UpsertOptionTranslationMutationResult = Apollo.MutationResult<UpsertOptionTranslationMutation>;
export type UpsertOptionTranslationMutationOptions = Apollo.BaseMutationOptions<UpsertOptionTranslationMutation, UpsertOptionTranslationMutationVariables>;
export const AllTasksDocument = gql`
    query AllTasks($limit: Int!, $offset: Int!, $order_by: [tasks_order_by!], $where: tasks_bool_exp) {
  tasks_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  tasks(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
    ...TaskFields
    task_field_options_aggregate {
      aggregate {
        count
      }
    }
    plan_tasks {
      plan_id
    }
    recordings_aggregate {
      aggregate {
        count
      }
    }
  }
}
    ${TaskFieldsFragmentDoc}`;

/**
 * __useAllTasksQuery__
 *
 * To run a query within a React component, call `useAllTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTasksQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllTasksQuery(baseOptions: Apollo.QueryHookOptions<AllTasksQuery, AllTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllTasksQuery, AllTasksQueryVariables>(AllTasksDocument, options);
      }
export function useAllTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTasksQuery, AllTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllTasksQuery, AllTasksQueryVariables>(AllTasksDocument, options);
        }
export type AllTasksQueryHookResult = ReturnType<typeof useAllTasksQuery>;
export type AllTasksLazyQueryHookResult = ReturnType<typeof useAllTasksLazyQuery>;
export type AllTasksQueryResult = Apollo.QueryResult<AllTasksQuery, AllTasksQueryVariables>;
export const TaskDocument = gql`
    query Task($id: uuid!, $order_field_options_by: [task_field_option_order_by!]) {
  task: tasks_by_pk(id: $id) {
    ...TaskFields
    task_field_options(order_by: $order_field_options_by) {
      task_id
      field_option_id
      highlight
      field_option {
        id
        field {
          id
          type_id
          name
          display
          context {
            id
            name
            category {
              id
              name
            }
          }
        }
        option {
          id
          display
        }
      }
    }
    project_tasks_aggregate {
      aggregate {
        count
      }
    }
    plan_tasks_aggregate {
      aggregate {
        count
      }
    }
    recordings_aggregate {
      aggregate {
        count
      }
    }
  }
}
    ${TaskFieldsFragmentDoc}`;

/**
 * __useTaskQuery__
 *
 * To run a query within a React component, call `useTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *      order_field_options_by: // value for 'order_field_options_by'
 *   },
 * });
 */
export function useTaskQuery(baseOptions: Apollo.QueryHookOptions<TaskQuery, TaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskQuery, TaskQueryVariables>(TaskDocument, options);
      }
export function useTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskQuery, TaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskQuery, TaskQueryVariables>(TaskDocument, options);
        }
export type TaskQueryHookResult = ReturnType<typeof useTaskQuery>;
export type TaskLazyQueryHookResult = ReturnType<typeof useTaskLazyQuery>;
export type TaskQueryResult = Apollo.QueryResult<TaskQuery, TaskQueryVariables>;
export const TaskSimpleDocument = gql`
    query TaskSimple($id: uuid!) {
  task: tasks_by_pk(id: $id) {
    ...TaskFields
  }
}
    ${TaskFieldsFragmentDoc}`;

/**
 * __useTaskSimpleQuery__
 *
 * To run a query within a React component, call `useTaskSimpleQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskSimpleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskSimpleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskSimpleQuery(baseOptions: Apollo.QueryHookOptions<TaskSimpleQuery, TaskSimpleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskSimpleQuery, TaskSimpleQueryVariables>(TaskSimpleDocument, options);
      }
export function useTaskSimpleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskSimpleQuery, TaskSimpleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskSimpleQuery, TaskSimpleQueryVariables>(TaskSimpleDocument, options);
        }
export type TaskSimpleQueryHookResult = ReturnType<typeof useTaskSimpleQuery>;
export type TaskSimpleLazyQueryHookResult = ReturnType<typeof useTaskSimpleLazyQuery>;
export type TaskSimpleQueryResult = Apollo.QueryResult<TaskSimpleQuery, TaskSimpleQueryVariables>;
export const TaskOptionDisplayDocument = gql`
    subscription TaskOptionDisplay($id: uuid!) {
  categories(
    where: {contexts: {fields: {field_options: {task_field_options: {task_id: {_eq: $id}}}}}}
    order_by: {priority: desc}
  ) {
    ...CategoryFields
    contexts(
      where: {fields: {field_options: {task_field_options: {task_id: {_eq: $id}}}}}
    ) {
      ...ContextFields
      context_displays(
        where: {context_display_fields: {field: {field_options: {task_field_options: {task_id: {_eq: $id}}}}}}
        order_by: {position: asc}
      ) {
        name
        position
        context_display_fields(order_by: {position: asc}) {
          field {
            ...FieldFields
            field_options(where: {task_field_options: {task_id: {_eq: $id}}}) {
              option {
                ...OptionFields
              }
            }
          }
        }
      }
    }
  }
}
    ${CategoryFieldsFragmentDoc}
${ContextFieldsFragmentDoc}
${FieldFieldsFragmentDoc}
${OptionFieldsFragmentDoc}`;

/**
 * __useTaskOptionDisplaySubscription__
 *
 * To run a query within a React component, call `useTaskOptionDisplaySubscription` and pass it any options that fit your needs.
 * When your component renders, `useTaskOptionDisplaySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskOptionDisplaySubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskOptionDisplaySubscription(baseOptions: Apollo.SubscriptionHookOptions<TaskOptionDisplaySubscription, TaskOptionDisplaySubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<TaskOptionDisplaySubscription, TaskOptionDisplaySubscriptionVariables>(TaskOptionDisplayDocument, options);
      }
export type TaskOptionDisplaySubscriptionHookResult = ReturnType<typeof useTaskOptionDisplaySubscription>;
export type TaskOptionDisplaySubscriptionResult = Apollo.SubscriptionResult<TaskOptionDisplaySubscription>;
export const TaskFieldOptionHashesDocument = gql`
    query TaskFieldOptionHashes($where: task_field_option_hashes_bool_exp) {
  task_field_option_hashes(where: $where) {
    task {
      ...TaskFields
    }
    md5
  }
  task_field_option_hashes_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    ${TaskFieldsFragmentDoc}`;

/**
 * __useTaskFieldOptionHashesQuery__
 *
 * To run a query within a React component, call `useTaskFieldOptionHashesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskFieldOptionHashesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskFieldOptionHashesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useTaskFieldOptionHashesQuery(baseOptions?: Apollo.QueryHookOptions<TaskFieldOptionHashesQuery, TaskFieldOptionHashesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskFieldOptionHashesQuery, TaskFieldOptionHashesQueryVariables>(TaskFieldOptionHashesDocument, options);
      }
export function useTaskFieldOptionHashesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskFieldOptionHashesQuery, TaskFieldOptionHashesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskFieldOptionHashesQuery, TaskFieldOptionHashesQueryVariables>(TaskFieldOptionHashesDocument, options);
        }
export type TaskFieldOptionHashesQueryHookResult = ReturnType<typeof useTaskFieldOptionHashesQuery>;
export type TaskFieldOptionHashesLazyQueryHookResult = ReturnType<typeof useTaskFieldOptionHashesLazyQuery>;
export type TaskFieldOptionHashesQueryResult = Apollo.QueryResult<TaskFieldOptionHashesQuery, TaskFieldOptionHashesQueryVariables>;
export const UpsertTaskDocument = gql`
    mutation UpsertTask($update_task: Boolean!, $task_id: uuid, $input: tasks_insert_input!, $field_option_ids_to_remove: [uuid!], $media_ids_to_remove: [uuid!]) {
  task: insert_tasks_one(
    object: $input
    on_conflict: {constraint: tasks_legacy_template_id_key, update_columns: [note, force_ground_truth_to_negative, is_template, is_test]}
  ) {
    ...TaskFields
  }
  remove_task_field_option: delete_task_field_option(
    where: {_and: {task_id: {_eq: $task_id}, field_option_id: {_in: $field_option_ids_to_remove}}}
  ) @include(if: $update_task) {
    returning {
      field_option_id
    }
  }
  remove_media: delete_task_media(
    where: {_and: {task_id: {_eq: $task_id}, media_id: {_in: $media_ids_to_remove}}}
  ) @include(if: $update_task) {
    returning {
      media_id
    }
  }
}
    ${TaskFieldsFragmentDoc}`;
export type UpsertTaskMutationFn = Apollo.MutationFunction<UpsertTaskMutation, UpsertTaskMutationVariables>;

/**
 * __useUpsertTaskMutation__
 *
 * To run a mutation, you first call `useUpsertTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertTaskMutation, { data, loading, error }] = useUpsertTaskMutation({
 *   variables: {
 *      update_task: // value for 'update_task'
 *      task_id: // value for 'task_id'
 *      input: // value for 'input'
 *      field_option_ids_to_remove: // value for 'field_option_ids_to_remove'
 *      media_ids_to_remove: // value for 'media_ids_to_remove'
 *   },
 * });
 */
export function useUpsertTaskMutation(baseOptions?: Apollo.MutationHookOptions<UpsertTaskMutation, UpsertTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertTaskMutation, UpsertTaskMutationVariables>(UpsertTaskDocument, options);
      }
export type UpsertTaskMutationHookResult = ReturnType<typeof useUpsertTaskMutation>;
export type UpsertTaskMutationResult = Apollo.MutationResult<UpsertTaskMutation>;
export type UpsertTaskMutationOptions = Apollo.BaseMutationOptions<UpsertTaskMutation, UpsertTaskMutationVariables>;
export const UpdateTaskDocument = gql`
    mutation UpdateTask($id: uuid!, $input: tasks_set_input!) {
  task: update_tasks_by_pk(pk_columns: {id: $id}, _set: $input) {
    ...TaskFields
  }
}
    ${TaskFieldsFragmentDoc}`;
export type UpdateTaskMutationFn = Apollo.MutationFunction<UpdateTaskMutation, UpdateTaskMutationVariables>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(UpdateTaskDocument, options);
      }
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<UpdateTaskMutation, UpdateTaskMutationVariables>;
export const InsertTasksDocument = gql`
    mutation InsertTasks($tasks: [tasks_insert_input!]!) {
  tasks: insert_tasks(objects: $tasks) {
    returning {
      ...TaskFields
    }
  }
}
    ${TaskFieldsFragmentDoc}`;
export type InsertTasksMutationFn = Apollo.MutationFunction<InsertTasksMutation, InsertTasksMutationVariables>;

/**
 * __useInsertTasksMutation__
 *
 * To run a mutation, you first call `useInsertTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTasksMutation, { data, loading, error }] = useInsertTasksMutation({
 *   variables: {
 *      tasks: // value for 'tasks'
 *   },
 * });
 */
export function useInsertTasksMutation(baseOptions?: Apollo.MutationHookOptions<InsertTasksMutation, InsertTasksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertTasksMutation, InsertTasksMutationVariables>(InsertTasksDocument, options);
      }
export type InsertTasksMutationHookResult = ReturnType<typeof useInsertTasksMutation>;
export type InsertTasksMutationResult = Apollo.MutationResult<InsertTasksMutation>;
export type InsertTasksMutationOptions = Apollo.BaseMutationOptions<InsertTasksMutation, InsertTasksMutationVariables>;
export const TaskTranslationsDocument = gql`
    subscription TaskTranslations($id: uuid!, $order_by: [task_translation_order_by!]) {
  translations: task_translation(
    where: {task_id: {_eq: $id}}
    order_by: $order_by
  ) {
    language: task_translation_language {
      id
      description
    }
    note
  }
}
    `;

/**
 * __useTaskTranslationsSubscription__
 *
 * To run a query within a React component, call `useTaskTranslationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTaskTranslationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskTranslationsSubscription({
 *   variables: {
 *      id: // value for 'id'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useTaskTranslationsSubscription(baseOptions: Apollo.SubscriptionHookOptions<TaskTranslationsSubscription, TaskTranslationsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<TaskTranslationsSubscription, TaskTranslationsSubscriptionVariables>(TaskTranslationsDocument, options);
      }
export type TaskTranslationsSubscriptionHookResult = ReturnType<typeof useTaskTranslationsSubscription>;
export type TaskTranslationsSubscriptionResult = Apollo.SubscriptionResult<TaskTranslationsSubscription>;
export const UpsertTaskTranslationDocument = gql`
    mutation UpsertTaskTranslation($object: task_translation_insert_input!) {
  translation: insert_task_translation_one(
    object: $object
    on_conflict: {constraint: task_translation_pkey, update_columns: [note]}
  ) {
    task_id
    language
    note
  }
}
    `;
export type UpsertTaskTranslationMutationFn = Apollo.MutationFunction<UpsertTaskTranslationMutation, UpsertTaskTranslationMutationVariables>;

/**
 * __useUpsertTaskTranslationMutation__
 *
 * To run a mutation, you first call `useUpsertTaskTranslationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertTaskTranslationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertTaskTranslationMutation, { data, loading, error }] = useUpsertTaskTranslationMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpsertTaskTranslationMutation(baseOptions?: Apollo.MutationHookOptions<UpsertTaskTranslationMutation, UpsertTaskTranslationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertTaskTranslationMutation, UpsertTaskTranslationMutationVariables>(UpsertTaskTranslationDocument, options);
      }
export type UpsertTaskTranslationMutationHookResult = ReturnType<typeof useUpsertTaskTranslationMutation>;
export type UpsertTaskTranslationMutationResult = Apollo.MutationResult<UpsertTaskTranslationMutation>;
export type UpsertTaskTranslationMutationOptions = Apollo.BaseMutationOptions<UpsertTaskTranslationMutation, UpsertTaskTranslationMutationVariables>;
export const AllFieldOptionsDocument = gql`
    query AllFieldOptions($limit: Int, $offset: Int, $order_by: [field_options_order_by!], $where: field_options_bool_exp) {
  fo_aggregate: field_options_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  fo: field_options(
    limit: $limit
    offset: $offset
    order_by: $order_by
    where: $where
  ) {
    ...FieldOptionFields
  }
}
    ${FieldOptionFieldsFragmentDoc}`;

/**
 * __useAllFieldOptionsQuery__
 *
 * To run a query within a React component, call `useAllFieldOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllFieldOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllFieldOptionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllFieldOptionsQuery(baseOptions?: Apollo.QueryHookOptions<AllFieldOptionsQuery, AllFieldOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllFieldOptionsQuery, AllFieldOptionsQueryVariables>(AllFieldOptionsDocument, options);
      }
export function useAllFieldOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllFieldOptionsQuery, AllFieldOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllFieldOptionsQuery, AllFieldOptionsQueryVariables>(AllFieldOptionsDocument, options);
        }
export type AllFieldOptionsQueryHookResult = ReturnType<typeof useAllFieldOptionsQuery>;
export type AllFieldOptionsLazyQueryHookResult = ReturnType<typeof useAllFieldOptionsLazyQuery>;
export type AllFieldOptionsQueryResult = Apollo.QueryResult<AllFieldOptionsQuery, AllFieldOptionsQueryVariables>;
export const InsertFieldOptionsDocument = gql`
    mutation InsertFieldOptions($input: [field_options_insert_input!]!) {
  fos: insert_field_options(objects: $input) {
    returning {
      ...FieldOptionFields
    }
  }
}
    ${FieldOptionFieldsFragmentDoc}`;
export type InsertFieldOptionsMutationFn = Apollo.MutationFunction<InsertFieldOptionsMutation, InsertFieldOptionsMutationVariables>;

/**
 * __useInsertFieldOptionsMutation__
 *
 * To run a mutation, you first call `useInsertFieldOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertFieldOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertFieldOptionsMutation, { data, loading, error }] = useInsertFieldOptionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsertFieldOptionsMutation(baseOptions?: Apollo.MutationHookOptions<InsertFieldOptionsMutation, InsertFieldOptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertFieldOptionsMutation, InsertFieldOptionsMutationVariables>(InsertFieldOptionsDocument, options);
      }
export type InsertFieldOptionsMutationHookResult = ReturnType<typeof useInsertFieldOptionsMutation>;
export type InsertFieldOptionsMutationResult = Apollo.MutationResult<InsertFieldOptionsMutation>;
export type InsertFieldOptionsMutationOptions = Apollo.BaseMutationOptions<InsertFieldOptionsMutation, InsertFieldOptionsMutationVariables>;
export const AllTaskFieldOptionsDocument = gql`
    query AllTaskFieldOptions($task_id: uuid!, $limit: Int, $offset: Int, $order_by: [task_field_option_order_by!], $where: task_field_option_bool_exp) {
  task: tasks_by_pk(id: $task_id) {
    task_field_options_aggregate(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      aggregate {
        count
      }
    }
    task_field_options(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...TaskFieldOptionFields
    }
  }
}
    ${TaskFieldOptionFieldsFragmentDoc}`;

/**
 * __useAllTaskFieldOptionsQuery__
 *
 * To run a query within a React component, call `useAllTaskFieldOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTaskFieldOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTaskFieldOptionsQuery({
 *   variables: {
 *      task_id: // value for 'task_id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllTaskFieldOptionsQuery(baseOptions: Apollo.QueryHookOptions<AllTaskFieldOptionsQuery, AllTaskFieldOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllTaskFieldOptionsQuery, AllTaskFieldOptionsQueryVariables>(AllTaskFieldOptionsDocument, options);
      }
export function useAllTaskFieldOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTaskFieldOptionsQuery, AllTaskFieldOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllTaskFieldOptionsQuery, AllTaskFieldOptionsQueryVariables>(AllTaskFieldOptionsDocument, options);
        }
export type AllTaskFieldOptionsQueryHookResult = ReturnType<typeof useAllTaskFieldOptionsQuery>;
export type AllTaskFieldOptionsLazyQueryHookResult = ReturnType<typeof useAllTaskFieldOptionsLazyQuery>;
export type AllTaskFieldOptionsQueryResult = Apollo.QueryResult<AllTaskFieldOptionsQuery, AllTaskFieldOptionsQueryVariables>;
export const SetTaskFieldOptionHighlightDocument = gql`
    mutation SetTaskFieldOptionHighlight($task_id: uuid!, $field_option_id: uuid!, $highlight: Boolean!) {
  tfo: update_task_field_option_by_pk(
    pk_columns: {task_id: $task_id, field_option_id: $field_option_id}
    _set: {highlight: $highlight}
  ) {
    task_id
    field_option_id
    highlight
    task {
      id
      description
    }
  }
}
    `;
export type SetTaskFieldOptionHighlightMutationFn = Apollo.MutationFunction<SetTaskFieldOptionHighlightMutation, SetTaskFieldOptionHighlightMutationVariables>;

/**
 * __useSetTaskFieldOptionHighlightMutation__
 *
 * To run a mutation, you first call `useSetTaskFieldOptionHighlightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTaskFieldOptionHighlightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTaskFieldOptionHighlightMutation, { data, loading, error }] = useSetTaskFieldOptionHighlightMutation({
 *   variables: {
 *      task_id: // value for 'task_id'
 *      field_option_id: // value for 'field_option_id'
 *      highlight: // value for 'highlight'
 *   },
 * });
 */
export function useSetTaskFieldOptionHighlightMutation(baseOptions?: Apollo.MutationHookOptions<SetTaskFieldOptionHighlightMutation, SetTaskFieldOptionHighlightMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTaskFieldOptionHighlightMutation, SetTaskFieldOptionHighlightMutationVariables>(SetTaskFieldOptionHighlightDocument, options);
      }
export type SetTaskFieldOptionHighlightMutationHookResult = ReturnType<typeof useSetTaskFieldOptionHighlightMutation>;
export type SetTaskFieldOptionHighlightMutationResult = Apollo.MutationResult<SetTaskFieldOptionHighlightMutation>;
export type SetTaskFieldOptionHighlightMutationOptions = Apollo.BaseMutationOptions<SetTaskFieldOptionHighlightMutation, SetTaskFieldOptionHighlightMutationVariables>;
export const AllPlansDocument = gql`
    query AllPlans($limit: Int, $offset: Int, $order_by: [plans_order_by!], $where: plans_bool_exp) {
  plans_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  plans(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
    ...PlanFields
    plan_tasks_aggregate(distinct_on: task_id) {
      aggregate {
        count
      }
    }
  }
}
    ${PlanFieldsFragmentDoc}`;

/**
 * __useAllPlansQuery__
 *
 * To run a query within a React component, call `useAllPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPlansQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllPlansQuery(baseOptions?: Apollo.QueryHookOptions<AllPlansQuery, AllPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllPlansQuery, AllPlansQueryVariables>(AllPlansDocument, options);
      }
export function useAllPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllPlansQuery, AllPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllPlansQuery, AllPlansQueryVariables>(AllPlansDocument, options);
        }
export type AllPlansQueryHookResult = ReturnType<typeof useAllPlansQuery>;
export type AllPlansLazyQueryHookResult = ReturnType<typeof useAllPlansLazyQuery>;
export type AllPlansQueryResult = Apollo.QueryResult<AllPlansQuery, AllPlansQueryVariables>;
export const PlanDocument = gql`
    query Plan($id: uuid!) {
  plan: plans_by_pk(id: $id) {
    ...PlanFields
    plan_tasks_aggregate {
      aggregate {
        count
      }
    }
  }
}
    ${PlanFieldsFragmentDoc}`;

/**
 * __usePlanQuery__
 *
 * To run a query within a React component, call `usePlanQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePlanQuery(baseOptions: Apollo.QueryHookOptions<PlanQuery, PlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanQuery, PlanQueryVariables>(PlanDocument, options);
      }
export function usePlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanQuery, PlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanQuery, PlanQueryVariables>(PlanDocument, options);
        }
export type PlanQueryHookResult = ReturnType<typeof usePlanQuery>;
export type PlanLazyQueryHookResult = ReturnType<typeof usePlanLazyQuery>;
export type PlanQueryResult = Apollo.QueryResult<PlanQuery, PlanQueryVariables>;
export const PlanOverviewDocument = gql`
    subscription PlanOverview($id: uuid!) {
  plan: plans_by_pk(id: $id) {
    ...PlanFields
    plan_tasks_aggregate {
      aggregate {
        count
      }
    }
  }
}
    ${PlanFieldsFragmentDoc}`;

/**
 * __usePlanOverviewSubscription__
 *
 * To run a query within a React component, call `usePlanOverviewSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePlanOverviewSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanOverviewSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePlanOverviewSubscription(baseOptions: Apollo.SubscriptionHookOptions<PlanOverviewSubscription, PlanOverviewSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<PlanOverviewSubscription, PlanOverviewSubscriptionVariables>(PlanOverviewDocument, options);
      }
export type PlanOverviewSubscriptionHookResult = ReturnType<typeof usePlanOverviewSubscription>;
export type PlanOverviewSubscriptionResult = Apollo.SubscriptionResult<PlanOverviewSubscription>;
export const CreatePlanDocument = gql`
    mutation CreatePlan($input: plans_insert_input!) {
  plan: insert_plans_one(object: $input) {
    ...PlanFields
  }
}
    ${PlanFieldsFragmentDoc}`;
export type CreatePlanMutationFn = Apollo.MutationFunction<CreatePlanMutation, CreatePlanMutationVariables>;

/**
 * __useCreatePlanMutation__
 *
 * To run a mutation, you first call `useCreatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlanMutation, { data, loading, error }] = useCreatePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlanMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlanMutation, CreatePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlanMutation, CreatePlanMutationVariables>(CreatePlanDocument, options);
      }
export type CreatePlanMutationHookResult = ReturnType<typeof useCreatePlanMutation>;
export type CreatePlanMutationResult = Apollo.MutationResult<CreatePlanMutation>;
export type CreatePlanMutationOptions = Apollo.BaseMutationOptions<CreatePlanMutation, CreatePlanMutationVariables>;
export const UpdatePlanDocument = gql`
    mutation UpdatePlan($id: uuid!, $plan: plans_set_input!, $add_plan_tasks: [plan_tasks_insert_input!]!, $remove_plan_task_ids: [uuid!]!) {
  plan: update_plans_by_pk(pk_columns: {id: $id}, _set: $plan) {
    ...PlanFields
  }
  added_plan_tasks: insert_plan_tasks(objects: $add_plan_tasks) {
    returning {
      ...PlanTaskFields
    }
  }
  removed_plan_tasks: delete_plan_tasks(where: {id: {_in: $remove_plan_task_ids}}) {
    returning {
      ...PlanTaskFields
    }
  }
}
    ${PlanFieldsFragmentDoc}
${PlanTaskFieldsFragmentDoc}`;
export type UpdatePlanMutationFn = Apollo.MutationFunction<UpdatePlanMutation, UpdatePlanMutationVariables>;

/**
 * __useUpdatePlanMutation__
 *
 * To run a mutation, you first call `useUpdatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanMutation, { data, loading, error }] = useUpdatePlanMutation({
 *   variables: {
 *      id: // value for 'id'
 *      plan: // value for 'plan'
 *      add_plan_tasks: // value for 'add_plan_tasks'
 *      remove_plan_task_ids: // value for 'remove_plan_task_ids'
 *   },
 * });
 */
export function useUpdatePlanMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlanMutation, UpdatePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlanMutation, UpdatePlanMutationVariables>(UpdatePlanDocument, options);
      }
export type UpdatePlanMutationHookResult = ReturnType<typeof useUpdatePlanMutation>;
export type UpdatePlanMutationResult = Apollo.MutationResult<UpdatePlanMutation>;
export type UpdatePlanMutationOptions = Apollo.BaseMutationOptions<UpdatePlanMutation, UpdatePlanMutationVariables>;
export const AllPlanTasksDocument = gql`
    query AllPlanTasks($limit: Int, $offset: Int, $order_by: [plan_tasks_order_by!], $where: plan_tasks_bool_exp) {
  plan_tasks_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  plan_tasks(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
    ...PlanTaskFields
    task {
      ...TaskFields
      task_field_options_aggregate {
        aggregate {
          count
        }
      }
    }
  }
}
    ${PlanTaskFieldsFragmentDoc}
${TaskFieldsFragmentDoc}`;

/**
 * __useAllPlanTasksQuery__
 *
 * To run a query within a React component, call `useAllPlanTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPlanTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPlanTasksQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllPlanTasksQuery(baseOptions?: Apollo.QueryHookOptions<AllPlanTasksQuery, AllPlanTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllPlanTasksQuery, AllPlanTasksQueryVariables>(AllPlanTasksDocument, options);
      }
export function useAllPlanTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllPlanTasksQuery, AllPlanTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllPlanTasksQuery, AllPlanTasksQueryVariables>(AllPlanTasksDocument, options);
        }
export type AllPlanTasksQueryHookResult = ReturnType<typeof useAllPlanTasksQuery>;
export type AllPlanTasksLazyQueryHookResult = ReturnType<typeof useAllPlanTasksLazyQuery>;
export type AllPlanTasksQueryResult = Apollo.QueryResult<AllPlanTasksQuery, AllPlanTasksQueryVariables>;
export const UpdatePlanTaskDocument = gql`
    mutation UpdatePlanTask($id: uuid!, $input: plan_tasks_set_input!) {
  plan_task: update_plan_tasks_by_pk(pk_columns: {id: $id}, _set: $input) {
    ...PlanTaskFields
  }
}
    ${PlanTaskFieldsFragmentDoc}`;
export type UpdatePlanTaskMutationFn = Apollo.MutationFunction<UpdatePlanTaskMutation, UpdatePlanTaskMutationVariables>;

/**
 * __useUpdatePlanTaskMutation__
 *
 * To run a mutation, you first call `useUpdatePlanTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanTaskMutation, { data, loading, error }] = useUpdatePlanTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlanTaskMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlanTaskMutation, UpdatePlanTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlanTaskMutation, UpdatePlanTaskMutationVariables>(UpdatePlanTaskDocument, options);
      }
export type UpdatePlanTaskMutationHookResult = ReturnType<typeof useUpdatePlanTaskMutation>;
export type UpdatePlanTaskMutationResult = Apollo.MutationResult<UpdatePlanTaskMutation>;
export type UpdatePlanTaskMutationOptions = Apollo.BaseMutationOptions<UpdatePlanTaskMutation, UpdatePlanTaskMutationVariables>;
export const AllMediaDocument = gql`
    query AllMedia($limit: Int, $offset: Int, $where: media_bool_exp, $order_by: [media_order_by!]) {
  media_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  media(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
    ...MediaFields
  }
}
    ${MediaFieldsFragmentDoc}`;

/**
 * __useAllMediaQuery__
 *
 * To run a query within a React component, call `useAllMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMediaQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useAllMediaQuery(baseOptions?: Apollo.QueryHookOptions<AllMediaQuery, AllMediaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllMediaQuery, AllMediaQueryVariables>(AllMediaDocument, options);
      }
export function useAllMediaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllMediaQuery, AllMediaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllMediaQuery, AllMediaQueryVariables>(AllMediaDocument, options);
        }
export type AllMediaQueryHookResult = ReturnType<typeof useAllMediaQuery>;
export type AllMediaLazyQueryHookResult = ReturnType<typeof useAllMediaLazyQuery>;
export type AllMediaQueryResult = Apollo.QueryResult<AllMediaQuery, AllMediaQueryVariables>;
export const MediaDocument = gql`
    query Media($id: uuid!) {
  media: media_by_pk(id: $id) {
    ...MediaFields
  }
}
    ${MediaFieldsFragmentDoc}`;

/**
 * __useMediaQuery__
 *
 * To run a query within a React component, call `useMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMediaQuery(baseOptions: Apollo.QueryHookOptions<MediaQuery, MediaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MediaQuery, MediaQueryVariables>(MediaDocument, options);
      }
export function useMediaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MediaQuery, MediaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MediaQuery, MediaQueryVariables>(MediaDocument, options);
        }
export type MediaQueryHookResult = ReturnType<typeof useMediaQuery>;
export type MediaLazyQueryHookResult = ReturnType<typeof useMediaLazyQuery>;
export type MediaQueryResult = Apollo.QueryResult<MediaQuery, MediaQueryVariables>;
export const CreateMediaDocument = gql`
    mutation CreateMedia($input: media_insert_input!) {
  media: insert_media_one(object: $input) {
    ...MediaFields
  }
}
    ${MediaFieldsFragmentDoc}`;
export type CreateMediaMutationFn = Apollo.MutationFunction<CreateMediaMutation, CreateMediaMutationVariables>;

/**
 * __useCreateMediaMutation__
 *
 * To run a mutation, you first call `useCreateMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMediaMutation, { data, loading, error }] = useCreateMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMediaMutation(baseOptions?: Apollo.MutationHookOptions<CreateMediaMutation, CreateMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMediaMutation, CreateMediaMutationVariables>(CreateMediaDocument, options);
      }
export type CreateMediaMutationHookResult = ReturnType<typeof useCreateMediaMutation>;
export type CreateMediaMutationResult = Apollo.MutationResult<CreateMediaMutation>;
export type CreateMediaMutationOptions = Apollo.BaseMutationOptions<CreateMediaMutation, CreateMediaMutationVariables>;
export const UpdateMediaDocument = gql`
    mutation UpdateMedia($id: uuid!, $input: media_set_input!) {
  media: update_media_by_pk(pk_columns: {id: $id}, _set: $input) {
    ...MediaFields
  }
}
    ${MediaFieldsFragmentDoc}`;
export type UpdateMediaMutationFn = Apollo.MutationFunction<UpdateMediaMutation, UpdateMediaMutationVariables>;

/**
 * __useUpdateMediaMutation__
 *
 * To run a mutation, you first call `useUpdateMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaMutation, { data, loading, error }] = useUpdateMediaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMediaMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMediaMutation, UpdateMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMediaMutation, UpdateMediaMutationVariables>(UpdateMediaDocument, options);
      }
export type UpdateMediaMutationHookResult = ReturnType<typeof useUpdateMediaMutation>;
export type UpdateMediaMutationResult = Apollo.MutationResult<UpdateMediaMutation>;
export type UpdateMediaMutationOptions = Apollo.BaseMutationOptions<UpdateMediaMutation, UpdateMediaMutationVariables>;
export const AllTaskMediaDocument = gql`
    query AllTaskMedia($limit: Int, $offset: Int, $order_by: [task_media_order_by!], $where: task_media_bool_exp) {
  tm_aggregate: task_media_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  tm: task_media(
    limit: $limit
    offset: $offset
    order_by: $order_by
    where: $where
  ) {
    ...TaskMedia
  }
}
    ${TaskMediaFragmentDoc}`;

/**
 * __useAllTaskMediaQuery__
 *
 * To run a query within a React component, call `useAllTaskMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTaskMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTaskMediaQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllTaskMediaQuery(baseOptions?: Apollo.QueryHookOptions<AllTaskMediaQuery, AllTaskMediaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllTaskMediaQuery, AllTaskMediaQueryVariables>(AllTaskMediaDocument, options);
      }
export function useAllTaskMediaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTaskMediaQuery, AllTaskMediaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllTaskMediaQuery, AllTaskMediaQueryVariables>(AllTaskMediaDocument, options);
        }
export type AllTaskMediaQueryHookResult = ReturnType<typeof useAllTaskMediaQuery>;
export type AllTaskMediaLazyQueryHookResult = ReturnType<typeof useAllTaskMediaLazyQuery>;
export type AllTaskMediaQueryResult = Apollo.QueryResult<AllTaskMediaQuery, AllTaskMediaQueryVariables>;
export const AllHypertasksDocument = gql`
    query AllHypertasks($limit: Int, $offset: Int, $where: hypertasks_bool_exp, $order_by: [hypertasks_order_by!]) {
  hypertasks_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  hypertasks(where: $where, order_by: $order_by, offset: $offset, limit: $limit) {
    ...HypertaskFields
    recordings_aggregate {
      aggregate {
        count
      }
    }
  }
}
    ${HypertaskFieldsFragmentDoc}`;

/**
 * __useAllHypertasksQuery__
 *
 * To run a query within a React component, call `useAllHypertasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllHypertasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllHypertasksQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useAllHypertasksQuery(baseOptions?: Apollo.QueryHookOptions<AllHypertasksQuery, AllHypertasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllHypertasksQuery, AllHypertasksQueryVariables>(AllHypertasksDocument, options);
      }
export function useAllHypertasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllHypertasksQuery, AllHypertasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllHypertasksQuery, AllHypertasksQueryVariables>(AllHypertasksDocument, options);
        }
export type AllHypertasksQueryHookResult = ReturnType<typeof useAllHypertasksQuery>;
export type AllHypertasksLazyQueryHookResult = ReturnType<typeof useAllHypertasksLazyQuery>;
export type AllHypertasksQueryResult = Apollo.QueryResult<AllHypertasksQuery, AllHypertasksQueryVariables>;
export const HypertaskDocument = gql`
    query Hypertask($id: uuid!) {
  hypertask: hypertasks_by_pk(id: $id) {
    ...HypertaskFields
    hypertask_field_options {
      field {
        id
        name
        type_id
        context {
          id
          name
          category {
            id
            name
          }
        }
      }
      option {
        id
        display
      }
    }
  }
}
    ${HypertaskFieldsFragmentDoc}`;

/**
 * __useHypertaskQuery__
 *
 * To run a query within a React component, call `useHypertaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useHypertaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHypertaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHypertaskQuery(baseOptions: Apollo.QueryHookOptions<HypertaskQuery, HypertaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HypertaskQuery, HypertaskQueryVariables>(HypertaskDocument, options);
      }
export function useHypertaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HypertaskQuery, HypertaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HypertaskQuery, HypertaskQueryVariables>(HypertaskDocument, options);
        }
export type HypertaskQueryHookResult = ReturnType<typeof useHypertaskQuery>;
export type HypertaskLazyQueryHookResult = ReturnType<typeof useHypertaskLazyQuery>;
export type HypertaskQueryResult = Apollo.QueryResult<HypertaskQuery, HypertaskQueryVariables>;
export const CreateHypertaskDocument = gql`
    mutation CreateHypertask($input: hypertasks_insert_input!) {
  hypertask: insert_hypertasks_one(object: $input) {
    ...HypertaskFields
  }
}
    ${HypertaskFieldsFragmentDoc}`;
export type CreateHypertaskMutationFn = Apollo.MutationFunction<CreateHypertaskMutation, CreateHypertaskMutationVariables>;

/**
 * __useCreateHypertaskMutation__
 *
 * To run a mutation, you first call `useCreateHypertaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHypertaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHypertaskMutation, { data, loading, error }] = useCreateHypertaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateHypertaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateHypertaskMutation, CreateHypertaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateHypertaskMutation, CreateHypertaskMutationVariables>(CreateHypertaskDocument, options);
      }
export type CreateHypertaskMutationHookResult = ReturnType<typeof useCreateHypertaskMutation>;
export type CreateHypertaskMutationResult = Apollo.MutationResult<CreateHypertaskMutation>;
export type CreateHypertaskMutationOptions = Apollo.BaseMutationOptions<CreateHypertaskMutation, CreateHypertaskMutationVariables>;
export const UpdateHypertaskDocument = gql`
    mutation UpdateHypertask($id: uuid!, $columns: hypertasks_set_input!, $fieldOptions: [hypertask_field_option_insert_input!]!) {
  hypertask: update_hypertasks_by_pk(pk_columns: {id: $id}, _set: $columns) {
    ...HypertaskFields
  }
  insert_hypertask_field_option(objects: $fieldOptions) {
    affected_rows
  }
}
    ${HypertaskFieldsFragmentDoc}`;
export type UpdateHypertaskMutationFn = Apollo.MutationFunction<UpdateHypertaskMutation, UpdateHypertaskMutationVariables>;

/**
 * __useUpdateHypertaskMutation__
 *
 * To run a mutation, you first call `useUpdateHypertaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHypertaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHypertaskMutation, { data, loading, error }] = useUpdateHypertaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      columns: // value for 'columns'
 *      fieldOptions: // value for 'fieldOptions'
 *   },
 * });
 */
export function useUpdateHypertaskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHypertaskMutation, UpdateHypertaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHypertaskMutation, UpdateHypertaskMutationVariables>(UpdateHypertaskDocument, options);
      }
export type UpdateHypertaskMutationHookResult = ReturnType<typeof useUpdateHypertaskMutation>;
export type UpdateHypertaskMutationResult = Apollo.MutationResult<UpdateHypertaskMutation>;
export type UpdateHypertaskMutationOptions = Apollo.BaseMutationOptions<UpdateHypertaskMutation, UpdateHypertaskMutationVariables>;
export const AllCustomersDocument = gql`
    query AllCustomers($limit: Int, $offset: Int, $order_by: [customers_order_by!], $where: customers_bool_exp) {
  customers_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  customers(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
    ...CustomerFields
    projects {
      id
      project_name {
        display_name
      }
    }
  }
}
    ${CustomerFieldsFragmentDoc}`;

/**
 * __useAllCustomersQuery__
 *
 * To run a query within a React component, call `useAllCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCustomersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllCustomersQuery(baseOptions?: Apollo.QueryHookOptions<AllCustomersQuery, AllCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCustomersQuery, AllCustomersQueryVariables>(AllCustomersDocument, options);
      }
export function useAllCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCustomersQuery, AllCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCustomersQuery, AllCustomersQueryVariables>(AllCustomersDocument, options);
        }
export type AllCustomersQueryHookResult = ReturnType<typeof useAllCustomersQuery>;
export type AllCustomersLazyQueryHookResult = ReturnType<typeof useAllCustomersLazyQuery>;
export type AllCustomersQueryResult = Apollo.QueryResult<AllCustomersQuery, AllCustomersQueryVariables>;
export const CustomerDocument = gql`
    query Customer($id: uuid!) {
  customer: customers_by_pk(id: $id) {
    ...CustomerFields
  }
}
    ${CustomerFieldsFragmentDoc}`;

/**
 * __useCustomerQuery__
 *
 * To run a query within a React component, call `useCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerQuery(baseOptions: Apollo.QueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, options);
      }
export function useCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, options);
        }
export type CustomerQueryHookResult = ReturnType<typeof useCustomerQuery>;
export type CustomerLazyQueryHookResult = ReturnType<typeof useCustomerLazyQuery>;
export type CustomerQueryResult = Apollo.QueryResult<CustomerQuery, CustomerQueryVariables>;
export const CreateCustomerDocument = gql`
    mutation CreateCustomer($codename: String!) {
  customer: insert_customers_one(object: {codename: $codename}) {
    ...CustomerFields
  }
}
    ${CustomerFieldsFragmentDoc}`;
export type CreateCustomerMutationFn = Apollo.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      codename: // value for 'codename'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, options);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = Apollo.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const UpdateCustomerDocument = gql`
    mutation UpdateCustomer($id: uuid!, $input: customers_set_input!) {
  customer: update_customers_by_pk(pk_columns: {id: $id}, _set: $input) {
    ...CustomerFields
  }
}
    ${CustomerFieldsFragmentDoc}`;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, options);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const AllDeliverablesDocument = gql`
    query AllDeliverables($limit: Int, $offset: Int, $order_by: [deliverables_order_by!], $where: deliverables_bool_exp) {
  deliverables_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  deliverables(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
    ...DeliverableFields
    projects {
      id
      ...ProjectCMD
    }
  }
}
    ${DeliverableFieldsFragmentDoc}
${ProjectCmdFragmentDoc}`;

/**
 * __useAllDeliverablesQuery__
 *
 * To run a query within a React component, call `useAllDeliverablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDeliverablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDeliverablesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllDeliverablesQuery(baseOptions?: Apollo.QueryHookOptions<AllDeliverablesQuery, AllDeliverablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllDeliverablesQuery, AllDeliverablesQueryVariables>(AllDeliverablesDocument, options);
      }
export function useAllDeliverablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllDeliverablesQuery, AllDeliverablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllDeliverablesQuery, AllDeliverablesQueryVariables>(AllDeliverablesDocument, options);
        }
export type AllDeliverablesQueryHookResult = ReturnType<typeof useAllDeliverablesQuery>;
export type AllDeliverablesLazyQueryHookResult = ReturnType<typeof useAllDeliverablesLazyQuery>;
export type AllDeliverablesQueryResult = Apollo.QueryResult<AllDeliverablesQuery, AllDeliverablesQueryVariables>;
export const DeliverableDocument = gql`
    query Deliverable($id: uuid!) {
  deliverable: deliverables_by_pk(id: $id) {
    ...DeliverableFields
    projects {
      id
      ...ProjectCMD
    }
  }
}
    ${DeliverableFieldsFragmentDoc}
${ProjectCmdFragmentDoc}`;

/**
 * __useDeliverableQuery__
 *
 * To run a query within a React component, call `useDeliverableQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliverableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliverableQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeliverableQuery(baseOptions: Apollo.QueryHookOptions<DeliverableQuery, DeliverableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeliverableQuery, DeliverableQueryVariables>(DeliverableDocument, options);
      }
export function useDeliverableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeliverableQuery, DeliverableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeliverableQuery, DeliverableQueryVariables>(DeliverableDocument, options);
        }
export type DeliverableQueryHookResult = ReturnType<typeof useDeliverableQuery>;
export type DeliverableLazyQueryHookResult = ReturnType<typeof useDeliverableLazyQuery>;
export type DeliverableQueryResult = Apollo.QueryResult<DeliverableQuery, DeliverableQueryVariables>;
export const CreateDeliverableDocument = gql`
    mutation CreateDeliverable($input: deliverables_insert_input!) {
  deliverable: insert_deliverables_one(object: $input) {
    ...DeliverableFields
  }
}
    ${DeliverableFieldsFragmentDoc}`;
export type CreateDeliverableMutationFn = Apollo.MutationFunction<CreateDeliverableMutation, CreateDeliverableMutationVariables>;

/**
 * __useCreateDeliverableMutation__
 *
 * To run a mutation, you first call `useCreateDeliverableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeliverableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeliverableMutation, { data, loading, error }] = useCreateDeliverableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDeliverableMutation(baseOptions?: Apollo.MutationHookOptions<CreateDeliverableMutation, CreateDeliverableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDeliverableMutation, CreateDeliverableMutationVariables>(CreateDeliverableDocument, options);
      }
export type CreateDeliverableMutationHookResult = ReturnType<typeof useCreateDeliverableMutation>;
export type CreateDeliverableMutationResult = Apollo.MutationResult<CreateDeliverableMutation>;
export type CreateDeliverableMutationOptions = Apollo.BaseMutationOptions<CreateDeliverableMutation, CreateDeliverableMutationVariables>;
export const UpdateDeliverableDocument = gql`
    mutation UpdateDeliverable($id: uuid!, $input: deliverables_set_input!) {
  deliverable: update_deliverables_by_pk(pk_columns: {id: $id}, _set: $input) {
    ...DeliverableFields
  }
}
    ${DeliverableFieldsFragmentDoc}`;
export type UpdateDeliverableMutationFn = Apollo.MutationFunction<UpdateDeliverableMutation, UpdateDeliverableMutationVariables>;

/**
 * __useUpdateDeliverableMutation__
 *
 * To run a mutation, you first call `useUpdateDeliverableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeliverableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeliverableMutation, { data, loading, error }] = useUpdateDeliverableMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDeliverableMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeliverableMutation, UpdateDeliverableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeliverableMutation, UpdateDeliverableMutationVariables>(UpdateDeliverableDocument, options);
      }
export type UpdateDeliverableMutationHookResult = ReturnType<typeof useUpdateDeliverableMutation>;
export type UpdateDeliverableMutationResult = Apollo.MutationResult<UpdateDeliverableMutation>;
export type UpdateDeliverableMutationOptions = Apollo.BaseMutationOptions<UpdateDeliverableMutation, UpdateDeliverableMutationVariables>;
export const AllDriverVersionsDocument = gql`
    query AllDriverVersions($limit: Int, $offset: Int, $where: deliveries_driver_versions_bool_exp, $order_by: [deliveries_driver_versions_order_by!]) {
  driver_versions_aggregate: deliveries_driver_versions_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  driver_versions: deliveries_driver_versions(
    where: $where
    order_by: $order_by
    offset: $offset
    limit: $limit
  ) {
    ...DriverVersionFields
    driver {
      name
    }
  }
}
    ${DriverVersionFieldsFragmentDoc}`;

/**
 * __useAllDriverVersionsQuery__
 *
 * To run a query within a React component, call `useAllDriverVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDriverVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDriverVersionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useAllDriverVersionsQuery(baseOptions?: Apollo.QueryHookOptions<AllDriverVersionsQuery, AllDriverVersionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllDriverVersionsQuery, AllDriverVersionsQueryVariables>(AllDriverVersionsDocument, options);
      }
export function useAllDriverVersionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllDriverVersionsQuery, AllDriverVersionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllDriverVersionsQuery, AllDriverVersionsQueryVariables>(AllDriverVersionsDocument, options);
        }
export type AllDriverVersionsQueryHookResult = ReturnType<typeof useAllDriverVersionsQuery>;
export type AllDriverVersionsLazyQueryHookResult = ReturnType<typeof useAllDriverVersionsLazyQuery>;
export type AllDriverVersionsQueryResult = Apollo.QueryResult<AllDriverVersionsQuery, AllDriverVersionsQueryVariables>;
export const DriverVersionDocument = gql`
    query DriverVersion($id: uuid!) {
  driver_version: deliveries_driver_versions_by_pk(id: $id) {
    ...DriverVersionFields
  }
}
    ${DriverVersionFieldsFragmentDoc}`;

/**
 * __useDriverVersionQuery__
 *
 * To run a query within a React component, call `useDriverVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useDriverVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDriverVersionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDriverVersionQuery(baseOptions: Apollo.QueryHookOptions<DriverVersionQuery, DriverVersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DriverVersionQuery, DriverVersionQueryVariables>(DriverVersionDocument, options);
      }
export function useDriverVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DriverVersionQuery, DriverVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DriverVersionQuery, DriverVersionQueryVariables>(DriverVersionDocument, options);
        }
export type DriverVersionQueryHookResult = ReturnType<typeof useDriverVersionQuery>;
export type DriverVersionLazyQueryHookResult = ReturnType<typeof useDriverVersionLazyQuery>;
export type DriverVersionQueryResult = Apollo.QueryResult<DriverVersionQuery, DriverVersionQueryVariables>;
export const CreateDriverVersionDocument = gql`
    mutation CreateDriverVersion($input: deliveries_driver_versions_insert_input!) {
  driver_version: insert_deliveries_driver_versions_one(object: $input) {
    ...DriverVersionFields
  }
}
    ${DriverVersionFieldsFragmentDoc}`;
export type CreateDriverVersionMutationFn = Apollo.MutationFunction<CreateDriverVersionMutation, CreateDriverVersionMutationVariables>;

/**
 * __useCreateDriverVersionMutation__
 *
 * To run a mutation, you first call `useCreateDriverVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDriverVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDriverVersionMutation, { data, loading, error }] = useCreateDriverVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDriverVersionMutation(baseOptions?: Apollo.MutationHookOptions<CreateDriverVersionMutation, CreateDriverVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDriverVersionMutation, CreateDriverVersionMutationVariables>(CreateDriverVersionDocument, options);
      }
export type CreateDriverVersionMutationHookResult = ReturnType<typeof useCreateDriverVersionMutation>;
export type CreateDriverVersionMutationResult = Apollo.MutationResult<CreateDriverVersionMutation>;
export type CreateDriverVersionMutationOptions = Apollo.BaseMutationOptions<CreateDriverVersionMutation, CreateDriverVersionMutationVariables>;
export const UpdateDriverVersionDocument = gql`
    mutation UpdateDriverVersion($id: uuid!, $input: deliveries_driver_versions_set_input!) {
  driver_version: update_deliveries_driver_versions_by_pk(
    pk_columns: {id: $id}
    _set: $input
  ) {
    ...DriverVersionFields
  }
}
    ${DriverVersionFieldsFragmentDoc}`;
export type UpdateDriverVersionMutationFn = Apollo.MutationFunction<UpdateDriverVersionMutation, UpdateDriverVersionMutationVariables>;

/**
 * __useUpdateDriverVersionMutation__
 *
 * To run a mutation, you first call `useUpdateDriverVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriverVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriverVersionMutation, { data, loading, error }] = useUpdateDriverVersionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDriverVersionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDriverVersionMutation, UpdateDriverVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDriverVersionMutation, UpdateDriverVersionMutationVariables>(UpdateDriverVersionDocument, options);
      }
export type UpdateDriverVersionMutationHookResult = ReturnType<typeof useUpdateDriverVersionMutation>;
export type UpdateDriverVersionMutationResult = Apollo.MutationResult<UpdateDriverVersionMutation>;
export type UpdateDriverVersionMutationOptions = Apollo.BaseMutationOptions<UpdateDriverVersionMutation, UpdateDriverVersionMutationVariables>;
export const AllDriversDocument = gql`
    query AllDrivers($limit: Int, $offset: Int, $where: deliveries_drivers_bool_exp, $order_by: [deliveries_drivers_order_by!]) {
  drivers_aggregate: deliveries_drivers_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  drivers: deliveries_drivers(
    where: $where
    order_by: $order_by
    offset: $offset
    limit: $limit
  ) {
    ...DriverFields
  }
}
    ${DriverFieldsFragmentDoc}`;

/**
 * __useAllDriversQuery__
 *
 * To run a query within a React component, call `useAllDriversQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDriversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDriversQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useAllDriversQuery(baseOptions?: Apollo.QueryHookOptions<AllDriversQuery, AllDriversQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllDriversQuery, AllDriversQueryVariables>(AllDriversDocument, options);
      }
export function useAllDriversLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllDriversQuery, AllDriversQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllDriversQuery, AllDriversQueryVariables>(AllDriversDocument, options);
        }
export type AllDriversQueryHookResult = ReturnType<typeof useAllDriversQuery>;
export type AllDriversLazyQueryHookResult = ReturnType<typeof useAllDriversLazyQuery>;
export type AllDriversQueryResult = Apollo.QueryResult<AllDriversQuery, AllDriversQueryVariables>;
export const DriverDocument = gql`
    query Driver($id: uuid!) {
  driver: deliveries_drivers_by_pk(id: $id) {
    ...DriverFields
  }
}
    ${DriverFieldsFragmentDoc}`;

/**
 * __useDriverQuery__
 *
 * To run a query within a React component, call `useDriverQuery` and pass it any options that fit your needs.
 * When your component renders, `useDriverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDriverQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDriverQuery(baseOptions: Apollo.QueryHookOptions<DriverQuery, DriverQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DriverQuery, DriverQueryVariables>(DriverDocument, options);
      }
export function useDriverLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DriverQuery, DriverQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DriverQuery, DriverQueryVariables>(DriverDocument, options);
        }
export type DriverQueryHookResult = ReturnType<typeof useDriverQuery>;
export type DriverLazyQueryHookResult = ReturnType<typeof useDriverLazyQuery>;
export type DriverQueryResult = Apollo.QueryResult<DriverQuery, DriverQueryVariables>;
export const CreateDriverDocument = gql`
    mutation CreateDriver($input: deliveries_drivers_insert_input!) {
  driver: insert_deliveries_drivers_one(object: $input) {
    ...DriverFields
  }
}
    ${DriverFieldsFragmentDoc}`;
export type CreateDriverMutationFn = Apollo.MutationFunction<CreateDriverMutation, CreateDriverMutationVariables>;

/**
 * __useCreateDriverMutation__
 *
 * To run a mutation, you first call `useCreateDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDriverMutation, { data, loading, error }] = useCreateDriverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDriverMutation(baseOptions?: Apollo.MutationHookOptions<CreateDriverMutation, CreateDriverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDriverMutation, CreateDriverMutationVariables>(CreateDriverDocument, options);
      }
export type CreateDriverMutationHookResult = ReturnType<typeof useCreateDriverMutation>;
export type CreateDriverMutationResult = Apollo.MutationResult<CreateDriverMutation>;
export type CreateDriverMutationOptions = Apollo.BaseMutationOptions<CreateDriverMutation, CreateDriverMutationVariables>;
export const UpdateDriverDocument = gql`
    mutation UpdateDriver($id: uuid!, $columns: deliveries_drivers_set_input!, $add_driver_projects: [deliveries_driver_project_insert_input!]!, $delete_driver_project_ids: [uuid!]!) {
  driver: update_deliveries_drivers_by_pk(pk_columns: {id: $id}, _set: $columns) {
    ...DriverFields
  }
  delete_driver_project: delete_deliveries_driver_project(
    where: {driver_id: {_eq: $id}, project_id: {_in: $delete_driver_project_ids}}
  ) {
    affected_rows
  }
  add_driver_projects: insert_deliveries_driver_project(
    objects: $add_driver_projects
    on_conflict: {constraint: driver_project_pkey, update_columns: [project_id]}
  ) {
    affected_rows
  }
}
    ${DriverFieldsFragmentDoc}`;
export type UpdateDriverMutationFn = Apollo.MutationFunction<UpdateDriverMutation, UpdateDriverMutationVariables>;

/**
 * __useUpdateDriverMutation__
 *
 * To run a mutation, you first call `useUpdateDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriverMutation, { data, loading, error }] = useUpdateDriverMutation({
 *   variables: {
 *      id: // value for 'id'
 *      columns: // value for 'columns'
 *      add_driver_projects: // value for 'add_driver_projects'
 *      delete_driver_project_ids: // value for 'delete_driver_project_ids'
 *   },
 * });
 */
export function useUpdateDriverMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDriverMutation, UpdateDriverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDriverMutation, UpdateDriverMutationVariables>(UpdateDriverDocument, options);
      }
export type UpdateDriverMutationHookResult = ReturnType<typeof useUpdateDriverMutation>;
export type UpdateDriverMutationResult = Apollo.MutationResult<UpdateDriverMutation>;
export type UpdateDriverMutationOptions = Apollo.BaseMutationOptions<UpdateDriverMutation, UpdateDriverMutationVariables>;
export const AllArchitecturesDocument = gql`
    query AllArchitectures($limit: Int, $offset: Int, $where: deliveries_architectures_bool_exp, $order_by: [deliveries_architectures_order_by!]) {
  architectures: deliveries_architectures(
    where: $where
    order_by: $order_by
    offset: $offset
    limit: $limit
  ) {
    id
    name
  }
}
    `;

/**
 * __useAllArchitecturesQuery__
 *
 * To run a query within a React component, call `useAllArchitecturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllArchitecturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllArchitecturesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useAllArchitecturesQuery(baseOptions?: Apollo.QueryHookOptions<AllArchitecturesQuery, AllArchitecturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllArchitecturesQuery, AllArchitecturesQueryVariables>(AllArchitecturesDocument, options);
      }
export function useAllArchitecturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllArchitecturesQuery, AllArchitecturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllArchitecturesQuery, AllArchitecturesQueryVariables>(AllArchitecturesDocument, options);
        }
export type AllArchitecturesQueryHookResult = ReturnType<typeof useAllArchitecturesQuery>;
export type AllArchitecturesLazyQueryHookResult = ReturnType<typeof useAllArchitecturesLazyQuery>;
export type AllArchitecturesQueryResult = Apollo.QueryResult<AllArchitecturesQuery, AllArchitecturesQueryVariables>;
export const AllPlatformsDocument = gql`
    query AllPlatforms($limit: Int, $offset: Int, $where: deliveries_platforms_bool_exp, $order_by: [deliveries_platforms_order_by!]) {
  platforms: deliveries_platforms(
    where: $where
    order_by: $order_by
    offset: $offset
    limit: $limit
  ) {
    id
    name
  }
}
    `;

/**
 * __useAllPlatformsQuery__
 *
 * To run a query within a React component, call `useAllPlatformsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPlatformsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPlatformsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useAllPlatformsQuery(baseOptions?: Apollo.QueryHookOptions<AllPlatformsQuery, AllPlatformsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllPlatformsQuery, AllPlatformsQueryVariables>(AllPlatformsDocument, options);
      }
export function useAllPlatformsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllPlatformsQuery, AllPlatformsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllPlatformsQuery, AllPlatformsQueryVariables>(AllPlatformsDocument, options);
        }
export type AllPlatformsQueryHookResult = ReturnType<typeof useAllPlatformsQuery>;
export type AllPlatformsLazyQueryHookResult = ReturnType<typeof useAllPlatformsLazyQuery>;
export type AllPlatformsQueryResult = Apollo.QueryResult<AllPlatformsQuery, AllPlatformsQueryVariables>;
export const AllPackagesDocument = gql`
    query AllPackages($limit: Int, $offset: Int, $where: deliveries_packages_bool_exp, $order_by: [deliveries_packages_order_by!]) {
  packages_aggregate: deliveries_packages_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  packages: deliveries_packages(
    where: $where
    order_by: $order_by
    offset: $offset
    limit: $limit
  ) {
    ...PackageFields
    released_packages {
      version
    }
  }
}
    ${PackageFieldsFragmentDoc}`;

/**
 * __useAllPackagesQuery__
 *
 * To run a query within a React component, call `useAllPackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPackagesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useAllPackagesQuery(baseOptions?: Apollo.QueryHookOptions<AllPackagesQuery, AllPackagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllPackagesQuery, AllPackagesQueryVariables>(AllPackagesDocument, options);
      }
export function useAllPackagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllPackagesQuery, AllPackagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllPackagesQuery, AllPackagesQueryVariables>(AllPackagesDocument, options);
        }
export type AllPackagesQueryHookResult = ReturnType<typeof useAllPackagesQuery>;
export type AllPackagesLazyQueryHookResult = ReturnType<typeof useAllPackagesLazyQuery>;
export type AllPackagesQueryResult = Apollo.QueryResult<AllPackagesQuery, AllPackagesQueryVariables>;
export const PackageDocument = gql`
    query Package($id: uuid!) {
  package: deliveries_packages_by_pk(id: $id) {
    ...PackageFields
    driver_packages {
      driver {
        ...DriverFields
      }
    }
    driver_version_packages {
      driver_version {
        id
        name
        version
        storage_blob_url
        sbom_blob_url
        driver_version_engine {
          engine {
            ...EngineFields
          }
        }
        driver {
          id
          name
          driver_project {
            project {
              id
              project_name {
                name
              }
            }
          }
        }
      }
    }
    package_operating_systems {
      os_id
      is_tested
    }
    package_targets {
      target {
        id
        model {
          codename
        }
        customer {
          codename
        }
      }
    }
    targets: driver_version_packages(
      where: {_and: [{driver_version: {driver_version_engine: {}}}, {driver_version: {driver: {driver_project: {project: {}}}}}]}
      order_by: [{driver_version: {driver: {driver_project: {project: {id: asc}}}}}, {driver_version: {driver_version_engine: {engine: {build_number: asc}}}}]
    ) {
      driver_version {
        id
        name
        version
        driver_version_engine {
          engine {
            id
            build_number
          }
        }
        driver {
          driver_project {
            project {
              id
              project_name {
                name
              }
            }
          }
        }
      }
    }
  }
}
    ${PackageFieldsFragmentDoc}
${DriverFieldsFragmentDoc}
${EngineFieldsFragmentDoc}`;

/**
 * __usePackageQuery__
 *
 * To run a query within a React component, call `usePackageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePackageQuery(baseOptions: Apollo.QueryHookOptions<PackageQuery, PackageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PackageQuery, PackageQueryVariables>(PackageDocument, options);
      }
export function usePackageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PackageQuery, PackageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PackageQuery, PackageQueryVariables>(PackageDocument, options);
        }
export type PackageQueryHookResult = ReturnType<typeof usePackageQuery>;
export type PackageLazyQueryHookResult = ReturnType<typeof usePackageLazyQuery>;
export type PackageQueryResult = Apollo.QueryResult<PackageQuery, PackageQueryVariables>;
export const CreatePackageDocument = gql`
    mutation CreatePackage($input: deliveries_packages_insert_input!) {
  package: insert_deliveries_packages_one(object: $input) {
    ...PackageFields
  }
}
    ${PackageFieldsFragmentDoc}`;
export type CreatePackageMutationFn = Apollo.MutationFunction<CreatePackageMutation, CreatePackageMutationVariables>;

/**
 * __useCreatePackageMutation__
 *
 * To run a mutation, you first call `useCreatePackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPackageMutation, { data, loading, error }] = useCreatePackageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePackageMutation(baseOptions?: Apollo.MutationHookOptions<CreatePackageMutation, CreatePackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePackageMutation, CreatePackageMutationVariables>(CreatePackageDocument, options);
      }
export type CreatePackageMutationHookResult = ReturnType<typeof useCreatePackageMutation>;
export type CreatePackageMutationResult = Apollo.MutationResult<CreatePackageMutation>;
export type CreatePackageMutationOptions = Apollo.BaseMutationOptions<CreatePackageMutation, CreatePackageMutationVariables>;
export const UpdatePackageDocument = gql`
    mutation UpdatePackage($id: uuid!, $input: deliveries_packages_set_input!, $delete_driver_version_ids: [uuid!]!, $delete_driver_ids: [uuid!]!, $add_driver_versions: [deliveries_driver_version_package_insert_input!]!, $add_drivers: [deliveries_driver_package_insert_input!]!, $package_os_tests: [deliveries_package_operating_systems_insert_input!]!, $delete_target_ids: [uuid!]!, $add_targets: [deliveries_package_target_insert_input!]!) {
  package: update_deliveries_packages_by_pk(pk_columns: {id: $id}, _set: $input) {
    ...PackageFields
  }
  delete_driver_versions: delete_deliveries_driver_version_package(
    where: {package_id: {_eq: $id}, driver_version_id: {_in: $delete_driver_version_ids}}
  ) {
    affected_rows
  }
  delete_drivers: delete_deliveries_driver_package(
    where: {package_id: {_eq: $id}, driver_id: {_in: $delete_driver_ids}}
  ) {
    affected_rows
  }
  add_driver_versions: insert_deliveries_driver_version_package(
    objects: $add_driver_versions
  ) {
    affected_rows
  }
  add_drivers: insert_deliveries_driver_package(objects: $add_drivers) {
    affected_rows
  }
  upsert_package_os_tests: insert_deliveries_package_operating_systems(
    objects: $package_os_tests
    on_conflict: {constraint: package_operating_systems_pkey, update_columns: [is_tested]}
  ) {
    affected_rows
  }
  delete_targets: delete_deliveries_package_target(
    where: {package_id: {_eq: $id}, target_id: {_in: $delete_target_ids}}
  ) {
    affected_rows
  }
  add_targets: insert_deliveries_package_target(objects: $add_targets) {
    affected_rows
  }
}
    ${PackageFieldsFragmentDoc}`;
export type UpdatePackageMutationFn = Apollo.MutationFunction<UpdatePackageMutation, UpdatePackageMutationVariables>;

/**
 * __useUpdatePackageMutation__
 *
 * To run a mutation, you first call `useUpdatePackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePackageMutation, { data, loading, error }] = useUpdatePackageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      delete_driver_version_ids: // value for 'delete_driver_version_ids'
 *      delete_driver_ids: // value for 'delete_driver_ids'
 *      add_driver_versions: // value for 'add_driver_versions'
 *      add_drivers: // value for 'add_drivers'
 *      package_os_tests: // value for 'package_os_tests'
 *      delete_target_ids: // value for 'delete_target_ids'
 *      add_targets: // value for 'add_targets'
 *   },
 * });
 */
export function useUpdatePackageMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePackageMutation, UpdatePackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePackageMutation, UpdatePackageMutationVariables>(UpdatePackageDocument, options);
      }
export type UpdatePackageMutationHookResult = ReturnType<typeof useUpdatePackageMutation>;
export type UpdatePackageMutationResult = Apollo.MutationResult<UpdatePackageMutation>;
export type UpdatePackageMutationOptions = Apollo.BaseMutationOptions<UpdatePackageMutation, UpdatePackageMutationVariables>;
export const ArchivePackageDocument = gql`
    mutation ArchivePackage($id: uuid!, $deleted_at: timestamptz) {
  update_deliveries_packages_by_pk(
    pk_columns: {id: $id}
    _set: {deleted_at: $deleted_at}
  ) {
    id
  }
}
    `;
export type ArchivePackageMutationFn = Apollo.MutationFunction<ArchivePackageMutation, ArchivePackageMutationVariables>;

/**
 * __useArchivePackageMutation__
 *
 * To run a mutation, you first call `useArchivePackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchivePackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archivePackageMutation, { data, loading, error }] = useArchivePackageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deleted_at: // value for 'deleted_at'
 *   },
 * });
 */
export function useArchivePackageMutation(baseOptions?: Apollo.MutationHookOptions<ArchivePackageMutation, ArchivePackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchivePackageMutation, ArchivePackageMutationVariables>(ArchivePackageDocument, options);
      }
export type ArchivePackageMutationHookResult = ReturnType<typeof useArchivePackageMutation>;
export type ArchivePackageMutationResult = Apollo.MutationResult<ArchivePackageMutation>;
export type ArchivePackageMutationOptions = Apollo.BaseMutationOptions<ArchivePackageMutation, ArchivePackageMutationVariables>;
export const ArchiveDriverDocument = gql`
    mutation ArchiveDriver($id: uuid!, $deleted_at: timestamptz) {
  update_deliveries_drivers_by_pk(
    pk_columns: {id: $id}
    _set: {deleted_at: $deleted_at}
  ) {
    id
  }
}
    `;
export type ArchiveDriverMutationFn = Apollo.MutationFunction<ArchiveDriverMutation, ArchiveDriverMutationVariables>;

/**
 * __useArchiveDriverMutation__
 *
 * To run a mutation, you first call `useArchiveDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveDriverMutation, { data, loading, error }] = useArchiveDriverMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deleted_at: // value for 'deleted_at'
 *   },
 * });
 */
export function useArchiveDriverMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveDriverMutation, ArchiveDriverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveDriverMutation, ArchiveDriverMutationVariables>(ArchiveDriverDocument, options);
      }
export type ArchiveDriverMutationHookResult = ReturnType<typeof useArchiveDriverMutation>;
export type ArchiveDriverMutationResult = Apollo.MutationResult<ArchiveDriverMutation>;
export type ArchiveDriverMutationOptions = Apollo.BaseMutationOptions<ArchiveDriverMutation, ArchiveDriverMutationVariables>;
export const CheckPackageHasVersionDocument = gql`
    query CheckPackageHasVersion($spec_package_id: uuid!) {
  versioned_packages: deliveries_packages(
    where: {spec_package_id: {_eq: $spec_package_id}}
  ) {
    id
  }
}
    `;

/**
 * __useCheckPackageHasVersionQuery__
 *
 * To run a query within a React component, call `useCheckPackageHasVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckPackageHasVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckPackageHasVersionQuery({
 *   variables: {
 *      spec_package_id: // value for 'spec_package_id'
 *   },
 * });
 */
export function useCheckPackageHasVersionQuery(baseOptions: Apollo.QueryHookOptions<CheckPackageHasVersionQuery, CheckPackageHasVersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckPackageHasVersionQuery, CheckPackageHasVersionQueryVariables>(CheckPackageHasVersionDocument, options);
      }
export function useCheckPackageHasVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckPackageHasVersionQuery, CheckPackageHasVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckPackageHasVersionQuery, CheckPackageHasVersionQueryVariables>(CheckPackageHasVersionDocument, options);
        }
export type CheckPackageHasVersionQueryHookResult = ReturnType<typeof useCheckPackageHasVersionQuery>;
export type CheckPackageHasVersionLazyQueryHookResult = ReturnType<typeof useCheckPackageHasVersionLazyQuery>;
export type CheckPackageHasVersionQueryResult = Apollo.QueryResult<CheckPackageHasVersionQuery, CheckPackageHasVersionQueryVariables>;
export const CheckDriverHasVersionDocument = gql`
    query CheckDriverHasVersion($driver_id: uuid!) {
  versioned_drivers: deliveries_driver_versions(
    where: {driver_id: {_eq: $driver_id}}
  ) {
    id
  }
}
    `;

/**
 * __useCheckDriverHasVersionQuery__
 *
 * To run a query within a React component, call `useCheckDriverHasVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckDriverHasVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckDriverHasVersionQuery({
 *   variables: {
 *      driver_id: // value for 'driver_id'
 *   },
 * });
 */
export function useCheckDriverHasVersionQuery(baseOptions: Apollo.QueryHookOptions<CheckDriverHasVersionQuery, CheckDriverHasVersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckDriverHasVersionQuery, CheckDriverHasVersionQueryVariables>(CheckDriverHasVersionDocument, options);
      }
export function useCheckDriverHasVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckDriverHasVersionQuery, CheckDriverHasVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckDriverHasVersionQuery, CheckDriverHasVersionQueryVariables>(CheckDriverHasVersionDocument, options);
        }
export type CheckDriverHasVersionQueryHookResult = ReturnType<typeof useCheckDriverHasVersionQuery>;
export type CheckDriverHasVersionLazyQueryHookResult = ReturnType<typeof useCheckDriverHasVersionLazyQuery>;
export type CheckDriverHasVersionQueryResult = Apollo.QueryResult<CheckDriverHasVersionQuery, CheckDriverHasVersionQueryVariables>;
export const AllArtifactsDocument = gql`
    query AllArtifacts($limit: Int, $offset: Int, $where: deliveries_artifacts_bool_exp, $order_by: [deliveries_artifacts_order_by!]) {
  artifacts_aggregate: deliveries_artifacts_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  artifacts: deliveries_artifacts(
    where: $where
    order_by: $order_by
    offset: $offset
    limit: $limit
  ) {
    ...ArtifactFields
  }
}
    ${ArtifactFieldsFragmentDoc}`;

/**
 * __useAllArtifactsQuery__
 *
 * To run a query within a React component, call `useAllArtifactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllArtifactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllArtifactsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useAllArtifactsQuery(baseOptions?: Apollo.QueryHookOptions<AllArtifactsQuery, AllArtifactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllArtifactsQuery, AllArtifactsQueryVariables>(AllArtifactsDocument, options);
      }
export function useAllArtifactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllArtifactsQuery, AllArtifactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllArtifactsQuery, AllArtifactsQueryVariables>(AllArtifactsDocument, options);
        }
export type AllArtifactsQueryHookResult = ReturnType<typeof useAllArtifactsQuery>;
export type AllArtifactsLazyQueryHookResult = ReturnType<typeof useAllArtifactsLazyQuery>;
export type AllArtifactsQueryResult = Apollo.QueryResult<AllArtifactsQuery, AllArtifactsQueryVariables>;
export const CreateMicrosoftWhqlSubmissionDocument = gql`
    mutation CreateMicrosoftWHQLSubmission($input: deliveries_microsoft_whql_submissions_insert_input!) {
  microsoft_whql_submission: insert_deliveries_microsoft_whql_submissions_one(
    object: $input
  ) {
    ...MicrosoftWHQLSubmissionFields
  }
}
    ${MicrosoftWhqlSubmissionFieldsFragmentDoc}`;
export type CreateMicrosoftWhqlSubmissionMutationFn = Apollo.MutationFunction<CreateMicrosoftWhqlSubmissionMutation, CreateMicrosoftWhqlSubmissionMutationVariables>;

/**
 * __useCreateMicrosoftWhqlSubmissionMutation__
 *
 * To run a mutation, you first call `useCreateMicrosoftWhqlSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMicrosoftWhqlSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMicrosoftWhqlSubmissionMutation, { data, loading, error }] = useCreateMicrosoftWhqlSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMicrosoftWhqlSubmissionMutation(baseOptions?: Apollo.MutationHookOptions<CreateMicrosoftWhqlSubmissionMutation, CreateMicrosoftWhqlSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMicrosoftWhqlSubmissionMutation, CreateMicrosoftWhqlSubmissionMutationVariables>(CreateMicrosoftWhqlSubmissionDocument, options);
      }
export type CreateMicrosoftWhqlSubmissionMutationHookResult = ReturnType<typeof useCreateMicrosoftWhqlSubmissionMutation>;
export type CreateMicrosoftWhqlSubmissionMutationResult = Apollo.MutationResult<CreateMicrosoftWhqlSubmissionMutation>;
export type CreateMicrosoftWhqlSubmissionMutationOptions = Apollo.BaseMutationOptions<CreateMicrosoftWhqlSubmissionMutation, CreateMicrosoftWhqlSubmissionMutationVariables>;
export const AllMicrosoftWhqlSubmissionsDocument = gql`
    query AllMicrosoftWHQLSubmissions($limit: Int, $offset: Int, $where: deliveries_microsoft_whql_submissions_bool_exp, $order_by: [deliveries_microsoft_whql_submissions_order_by!]) {
  microsoft_whql_submissions_aggregate: deliveries_microsoft_whql_submissions_aggregate(
    where: $where
  ) {
    aggregate {
      count
    }
  }
  microsoft_whql_submissions: deliveries_microsoft_whql_submissions(
    where: $where
    order_by: $order_by
    offset: $offset
    limit: $limit
  ) {
    ...MicrosoftWHQLSubmissionFields
  }
}
    ${MicrosoftWhqlSubmissionFieldsFragmentDoc}`;

/**
 * __useAllMicrosoftWhqlSubmissionsQuery__
 *
 * To run a query within a React component, call `useAllMicrosoftWhqlSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMicrosoftWhqlSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMicrosoftWhqlSubmissionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useAllMicrosoftWhqlSubmissionsQuery(baseOptions?: Apollo.QueryHookOptions<AllMicrosoftWhqlSubmissionsQuery, AllMicrosoftWhqlSubmissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllMicrosoftWhqlSubmissionsQuery, AllMicrosoftWhqlSubmissionsQueryVariables>(AllMicrosoftWhqlSubmissionsDocument, options);
      }
export function useAllMicrosoftWhqlSubmissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllMicrosoftWhqlSubmissionsQuery, AllMicrosoftWhqlSubmissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllMicrosoftWhqlSubmissionsQuery, AllMicrosoftWhqlSubmissionsQueryVariables>(AllMicrosoftWhqlSubmissionsDocument, options);
        }
export type AllMicrosoftWhqlSubmissionsQueryHookResult = ReturnType<typeof useAllMicrosoftWhqlSubmissionsQuery>;
export type AllMicrosoftWhqlSubmissionsLazyQueryHookResult = ReturnType<typeof useAllMicrosoftWhqlSubmissionsLazyQuery>;
export type AllMicrosoftWhqlSubmissionsQueryResult = Apollo.QueryResult<AllMicrosoftWhqlSubmissionsQuery, AllMicrosoftWhqlSubmissionsQueryVariables>;
export const MicrosoftWhqlSubmissionDocument = gql`
    query MicrosoftWHQLSubmission($id: uuid!) {
  microsoft_whql_submission: deliveries_microsoft_whql_submissions_by_pk(id: $id) {
    ...MicrosoftWHQLSubmissionFields
  }
}
    ${MicrosoftWhqlSubmissionFieldsFragmentDoc}`;

/**
 * __useMicrosoftWhqlSubmissionQuery__
 *
 * To run a query within a React component, call `useMicrosoftWhqlSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useMicrosoftWhqlSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMicrosoftWhqlSubmissionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMicrosoftWhqlSubmissionQuery(baseOptions: Apollo.QueryHookOptions<MicrosoftWhqlSubmissionQuery, MicrosoftWhqlSubmissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MicrosoftWhqlSubmissionQuery, MicrosoftWhqlSubmissionQueryVariables>(MicrosoftWhqlSubmissionDocument, options);
      }
export function useMicrosoftWhqlSubmissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MicrosoftWhqlSubmissionQuery, MicrosoftWhqlSubmissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MicrosoftWhqlSubmissionQuery, MicrosoftWhqlSubmissionQueryVariables>(MicrosoftWhqlSubmissionDocument, options);
        }
export type MicrosoftWhqlSubmissionQueryHookResult = ReturnType<typeof useMicrosoftWhqlSubmissionQuery>;
export type MicrosoftWhqlSubmissionLazyQueryHookResult = ReturnType<typeof useMicrosoftWhqlSubmissionLazyQuery>;
export type MicrosoftWhqlSubmissionQueryResult = Apollo.QueryResult<MicrosoftWhqlSubmissionQuery, MicrosoftWhqlSubmissionQueryVariables>;
export const PackageMicrosoftWhqlSubmissionsDocument = gql`
    query PackageMicrosoftWHQLSubmissions($limit: Int, $offset: Int, $package_id: uuid!) {
  packageMicrosoftWHQLSubmissions_aggregate: deliveries_microsoft_whql_submissions_aggregate(
    where: {artifact_microsoft_whql_submissions: {artifact: {package_id: {_eq: $package_id}}}}
  ) {
    aggregate {
      count
    }
  }
  packageMicrosoftWHQLSubmissions: deliveries_microsoft_whql_submissions(
    where: {artifact_microsoft_whql_submissions: {artifact: {package_id: {_eq: $package_id}}}}
    offset: $offset
    limit: $limit
  ) {
    id
    artifact_microsoft_whql_submissions {
      artifact {
        id
        artifact_operating_systems {
          operating_system {
            fullname
          }
        }
      }
    }
    microsoft_partner_center_product_id
    microsoft_partner_center_product_name
    microsoft_partner_center_submission_id
    microsoft_partner_center_submission_name
    microsoft_partner_center_submission_type
    created_at
  }
}
    `;

/**
 * __usePackageMicrosoftWhqlSubmissionsQuery__
 *
 * To run a query within a React component, call `usePackageMicrosoftWhqlSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackageMicrosoftWhqlSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackageMicrosoftWhqlSubmissionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      package_id: // value for 'package_id'
 *   },
 * });
 */
export function usePackageMicrosoftWhqlSubmissionsQuery(baseOptions: Apollo.QueryHookOptions<PackageMicrosoftWhqlSubmissionsQuery, PackageMicrosoftWhqlSubmissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PackageMicrosoftWhqlSubmissionsQuery, PackageMicrosoftWhqlSubmissionsQueryVariables>(PackageMicrosoftWhqlSubmissionsDocument, options);
      }
export function usePackageMicrosoftWhqlSubmissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PackageMicrosoftWhqlSubmissionsQuery, PackageMicrosoftWhqlSubmissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PackageMicrosoftWhqlSubmissionsQuery, PackageMicrosoftWhqlSubmissionsQueryVariables>(PackageMicrosoftWhqlSubmissionsDocument, options);
        }
export type PackageMicrosoftWhqlSubmissionsQueryHookResult = ReturnType<typeof usePackageMicrosoftWhqlSubmissionsQuery>;
export type PackageMicrosoftWhqlSubmissionsLazyQueryHookResult = ReturnType<typeof usePackageMicrosoftWhqlSubmissionsLazyQuery>;
export type PackageMicrosoftWhqlSubmissionsQueryResult = Apollo.QueryResult<PackageMicrosoftWhqlSubmissionsQuery, PackageMicrosoftWhqlSubmissionsQueryVariables>;
export const UpdateMicrosoftWhqlSubmissionDocument = gql`
    mutation UpdateMicrosoftWHQLSubmission($id: uuid!, $columns: deliveries_microsoft_whql_submissions_set_input!) {
  microsoft_whql_submission: update_deliveries_microsoft_whql_submissions_by_pk(
    pk_columns: {id: $id}
    _set: $columns
  ) {
    ...MicrosoftWHQLSubmissionFields
  }
}
    ${MicrosoftWhqlSubmissionFieldsFragmentDoc}`;
export type UpdateMicrosoftWhqlSubmissionMutationFn = Apollo.MutationFunction<UpdateMicrosoftWhqlSubmissionMutation, UpdateMicrosoftWhqlSubmissionMutationVariables>;

/**
 * __useUpdateMicrosoftWhqlSubmissionMutation__
 *
 * To run a mutation, you first call `useUpdateMicrosoftWhqlSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMicrosoftWhqlSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMicrosoftWhqlSubmissionMutation, { data, loading, error }] = useUpdateMicrosoftWhqlSubmissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      columns: // value for 'columns'
 *   },
 * });
 */
export function useUpdateMicrosoftWhqlSubmissionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMicrosoftWhqlSubmissionMutation, UpdateMicrosoftWhqlSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMicrosoftWhqlSubmissionMutation, UpdateMicrosoftWhqlSubmissionMutationVariables>(UpdateMicrosoftWhqlSubmissionDocument, options);
      }
export type UpdateMicrosoftWhqlSubmissionMutationHookResult = ReturnType<typeof useUpdateMicrosoftWhqlSubmissionMutation>;
export type UpdateMicrosoftWhqlSubmissionMutationResult = Apollo.MutationResult<UpdateMicrosoftWhqlSubmissionMutation>;
export type UpdateMicrosoftWhqlSubmissionMutationOptions = Apollo.BaseMutationOptions<UpdateMicrosoftWhqlSubmissionMutation, UpdateMicrosoftWhqlSubmissionMutationVariables>;
export const CreateMicrosoftShippingLabelDocument = gql`
    mutation CreateMicrosoftShippingLabel($input: deliveries_microsoft_shipping_labels_insert_input!) {
  microsoft_shipping_label: insert_deliveries_microsoft_shipping_labels_one(
    object: $input
  ) {
    ...MicrosoftShippingLabelFields
  }
}
    ${MicrosoftShippingLabelFieldsFragmentDoc}`;
export type CreateMicrosoftShippingLabelMutationFn = Apollo.MutationFunction<CreateMicrosoftShippingLabelMutation, CreateMicrosoftShippingLabelMutationVariables>;

/**
 * __useCreateMicrosoftShippingLabelMutation__
 *
 * To run a mutation, you first call `useCreateMicrosoftShippingLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMicrosoftShippingLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMicrosoftShippingLabelMutation, { data, loading, error }] = useCreateMicrosoftShippingLabelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMicrosoftShippingLabelMutation(baseOptions?: Apollo.MutationHookOptions<CreateMicrosoftShippingLabelMutation, CreateMicrosoftShippingLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMicrosoftShippingLabelMutation, CreateMicrosoftShippingLabelMutationVariables>(CreateMicrosoftShippingLabelDocument, options);
      }
export type CreateMicrosoftShippingLabelMutationHookResult = ReturnType<typeof useCreateMicrosoftShippingLabelMutation>;
export type CreateMicrosoftShippingLabelMutationResult = Apollo.MutationResult<CreateMicrosoftShippingLabelMutation>;
export type CreateMicrosoftShippingLabelMutationOptions = Apollo.BaseMutationOptions<CreateMicrosoftShippingLabelMutation, CreateMicrosoftShippingLabelMutationVariables>;
export const MicrosoftWhqlSubmissionsShippingLabelsDocument = gql`
    query MicrosoftWHQLSubmissionsShippingLabels($limit: Int, $offset: Int, $microsoft_whql_submission_id: uuid!) {
  packageMicrosoftShippingLabels_aggregate: deliveries_microsoft_shipping_labels_aggregate(
    where: {microsoft_whql_submission_id: {_eq: $microsoft_whql_submission_id}}
  ) {
    aggregate {
      count
    }
  }
  packageMicrosoftShippingLabels: deliveries_microsoft_shipping_labels(
    where: {microsoft_whql_submission_id: {_eq: $microsoft_whql_submission_id}}
    offset: $offset
    limit: $limit
  ) {
    id
    microsoft_partner_center_shipping_label_id
    shipping_label_infos
    shipping_label_status
    shipping_label_name
    created_at
    user {
      name
    }
    updated_at
    user_update {
      name
    }
    target {
      id
      model {
        codename
      }
      customer {
        codename
      }
    }
    microsoft_shipping_label_review {
      status
      user {
        name
      }
    }
  }
}
    `;

/**
 * __useMicrosoftWhqlSubmissionsShippingLabelsQuery__
 *
 * To run a query within a React component, call `useMicrosoftWhqlSubmissionsShippingLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMicrosoftWhqlSubmissionsShippingLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMicrosoftWhqlSubmissionsShippingLabelsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      microsoft_whql_submission_id: // value for 'microsoft_whql_submission_id'
 *   },
 * });
 */
export function useMicrosoftWhqlSubmissionsShippingLabelsQuery(baseOptions: Apollo.QueryHookOptions<MicrosoftWhqlSubmissionsShippingLabelsQuery, MicrosoftWhqlSubmissionsShippingLabelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MicrosoftWhqlSubmissionsShippingLabelsQuery, MicrosoftWhqlSubmissionsShippingLabelsQueryVariables>(MicrosoftWhqlSubmissionsShippingLabelsDocument, options);
      }
export function useMicrosoftWhqlSubmissionsShippingLabelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MicrosoftWhqlSubmissionsShippingLabelsQuery, MicrosoftWhqlSubmissionsShippingLabelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MicrosoftWhqlSubmissionsShippingLabelsQuery, MicrosoftWhqlSubmissionsShippingLabelsQueryVariables>(MicrosoftWhqlSubmissionsShippingLabelsDocument, options);
        }
export type MicrosoftWhqlSubmissionsShippingLabelsQueryHookResult = ReturnType<typeof useMicrosoftWhqlSubmissionsShippingLabelsQuery>;
export type MicrosoftWhqlSubmissionsShippingLabelsLazyQueryHookResult = ReturnType<typeof useMicrosoftWhqlSubmissionsShippingLabelsLazyQuery>;
export type MicrosoftWhqlSubmissionsShippingLabelsQueryResult = Apollo.QueryResult<MicrosoftWhqlSubmissionsShippingLabelsQuery, MicrosoftWhqlSubmissionsShippingLabelsQueryVariables>;
export const AllMicrosoftShippingLabelsDocument = gql`
    query AllMicrosoftShippingLabels($limit: Int, $offset: Int, $where: deliveries_microsoft_shipping_labels_bool_exp, $order_by: [deliveries_microsoft_shipping_labels_order_by!]) {
  microsoft_shipping_labels_aggregate: deliveries_microsoft_shipping_labels_aggregate(
    where: $where
  ) {
    aggregate {
      count
    }
  }
  microsoft_shipping_labels: deliveries_microsoft_shipping_labels(
    where: $where
    order_by: $order_by
    offset: $offset
    limit: $limit
  ) {
    ...MicrosoftShippingLabelFields
  }
}
    ${MicrosoftShippingLabelFieldsFragmentDoc}`;

/**
 * __useAllMicrosoftShippingLabelsQuery__
 *
 * To run a query within a React component, call `useAllMicrosoftShippingLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMicrosoftShippingLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMicrosoftShippingLabelsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useAllMicrosoftShippingLabelsQuery(baseOptions?: Apollo.QueryHookOptions<AllMicrosoftShippingLabelsQuery, AllMicrosoftShippingLabelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllMicrosoftShippingLabelsQuery, AllMicrosoftShippingLabelsQueryVariables>(AllMicrosoftShippingLabelsDocument, options);
      }
export function useAllMicrosoftShippingLabelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllMicrosoftShippingLabelsQuery, AllMicrosoftShippingLabelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllMicrosoftShippingLabelsQuery, AllMicrosoftShippingLabelsQueryVariables>(AllMicrosoftShippingLabelsDocument, options);
        }
export type AllMicrosoftShippingLabelsQueryHookResult = ReturnType<typeof useAllMicrosoftShippingLabelsQuery>;
export type AllMicrosoftShippingLabelsLazyQueryHookResult = ReturnType<typeof useAllMicrosoftShippingLabelsLazyQuery>;
export type AllMicrosoftShippingLabelsQueryResult = Apollo.QueryResult<AllMicrosoftShippingLabelsQuery, AllMicrosoftShippingLabelsQueryVariables>;
export const MicrosoftShippingLabelDocument = gql`
    query MicrosoftShippingLabel($id: uuid!) {
  microsoft_shipping_label: deliveries_microsoft_shipping_labels_by_pk(id: $id) {
    ...MicrosoftShippingLabelFields
  }
}
    ${MicrosoftShippingLabelFieldsFragmentDoc}`;

/**
 * __useMicrosoftShippingLabelQuery__
 *
 * To run a query within a React component, call `useMicrosoftShippingLabelQuery` and pass it any options that fit your needs.
 * When your component renders, `useMicrosoftShippingLabelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMicrosoftShippingLabelQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMicrosoftShippingLabelQuery(baseOptions: Apollo.QueryHookOptions<MicrosoftShippingLabelQuery, MicrosoftShippingLabelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MicrosoftShippingLabelQuery, MicrosoftShippingLabelQueryVariables>(MicrosoftShippingLabelDocument, options);
      }
export function useMicrosoftShippingLabelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MicrosoftShippingLabelQuery, MicrosoftShippingLabelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MicrosoftShippingLabelQuery, MicrosoftShippingLabelQueryVariables>(MicrosoftShippingLabelDocument, options);
        }
export type MicrosoftShippingLabelQueryHookResult = ReturnType<typeof useMicrosoftShippingLabelQuery>;
export type MicrosoftShippingLabelLazyQueryHookResult = ReturnType<typeof useMicrosoftShippingLabelLazyQuery>;
export type MicrosoftShippingLabelQueryResult = Apollo.QueryResult<MicrosoftShippingLabelQuery, MicrosoftShippingLabelQueryVariables>;
export const UpdateMicrosoftShippingLabelDocument = gql`
    mutation UpdateMicrosoftShippingLabel($id: uuid!, $columns: deliveries_microsoft_shipping_labels_set_input!) {
  microsoft_shipping_label: update_deliveries_microsoft_shipping_labels_by_pk(
    pk_columns: {id: $id}
    _set: $columns
  ) {
    ...MicrosoftShippingLabelFields
  }
}
    ${MicrosoftShippingLabelFieldsFragmentDoc}`;
export type UpdateMicrosoftShippingLabelMutationFn = Apollo.MutationFunction<UpdateMicrosoftShippingLabelMutation, UpdateMicrosoftShippingLabelMutationVariables>;

/**
 * __useUpdateMicrosoftShippingLabelMutation__
 *
 * To run a mutation, you first call `useUpdateMicrosoftShippingLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMicrosoftShippingLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMicrosoftShippingLabelMutation, { data, loading, error }] = useUpdateMicrosoftShippingLabelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      columns: // value for 'columns'
 *   },
 * });
 */
export function useUpdateMicrosoftShippingLabelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMicrosoftShippingLabelMutation, UpdateMicrosoftShippingLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMicrosoftShippingLabelMutation, UpdateMicrosoftShippingLabelMutationVariables>(UpdateMicrosoftShippingLabelDocument, options);
      }
export type UpdateMicrosoftShippingLabelMutationHookResult = ReturnType<typeof useUpdateMicrosoftShippingLabelMutation>;
export type UpdateMicrosoftShippingLabelMutationResult = Apollo.MutationResult<UpdateMicrosoftShippingLabelMutation>;
export type UpdateMicrosoftShippingLabelMutationOptions = Apollo.BaseMutationOptions<UpdateMicrosoftShippingLabelMutation, UpdateMicrosoftShippingLabelMutationVariables>;
export const AllMicrosoftHlkArtifactsDocument = gql`
    query AllMicrosoftHlkArtifacts($limit: Int, $where: deliveries_microsoft_hlk_artifacts_bool_exp, $offset: Int, $order_by: [deliveries_microsoft_hlk_artifacts_order_by!]) {
  microsoft_hlk_artifacts_aggregate: deliveries_microsoft_hlk_artifacts_aggregate(
    where: $where
  ) {
    aggregate {
      count
    }
  }
  microsoft_hlk_artifacts: deliveries_microsoft_hlk_artifacts(
    where: $where
    order_by: $order_by
    limit: $limit
    offset: $offset
  ) {
    id
    storage_blob_url
    operating_system {
      fullname
    }
    device_family {
      codename
    }
    base_driver_version
    created_at
  }
}
    `;

/**
 * __useAllMicrosoftHlkArtifactsQuery__
 *
 * To run a query within a React component, call `useAllMicrosoftHlkArtifactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMicrosoftHlkArtifactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMicrosoftHlkArtifactsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useAllMicrosoftHlkArtifactsQuery(baseOptions?: Apollo.QueryHookOptions<AllMicrosoftHlkArtifactsQuery, AllMicrosoftHlkArtifactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllMicrosoftHlkArtifactsQuery, AllMicrosoftHlkArtifactsQueryVariables>(AllMicrosoftHlkArtifactsDocument, options);
      }
export function useAllMicrosoftHlkArtifactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllMicrosoftHlkArtifactsQuery, AllMicrosoftHlkArtifactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllMicrosoftHlkArtifactsQuery, AllMicrosoftHlkArtifactsQueryVariables>(AllMicrosoftHlkArtifactsDocument, options);
        }
export type AllMicrosoftHlkArtifactsQueryHookResult = ReturnType<typeof useAllMicrosoftHlkArtifactsQuery>;
export type AllMicrosoftHlkArtifactsLazyQueryHookResult = ReturnType<typeof useAllMicrosoftHlkArtifactsLazyQuery>;
export type AllMicrosoftHlkArtifactsQueryResult = Apollo.QueryResult<AllMicrosoftHlkArtifactsQuery, AllMicrosoftHlkArtifactsQueryVariables>;
export const OperatingSystemsDocument = gql`
    query OperatingSystems {
  operating_systems: deliveries_operating_systems {
    id
    codename
    fullname
  }
}
    `;

/**
 * __useOperatingSystemsQuery__
 *
 * To run a query within a React component, call `useOperatingSystemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperatingSystemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperatingSystemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOperatingSystemsQuery(baseOptions?: Apollo.QueryHookOptions<OperatingSystemsQuery, OperatingSystemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OperatingSystemsQuery, OperatingSystemsQueryVariables>(OperatingSystemsDocument, options);
      }
export function useOperatingSystemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OperatingSystemsQuery, OperatingSystemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OperatingSystemsQuery, OperatingSystemsQueryVariables>(OperatingSystemsDocument, options);
        }
export type OperatingSystemsQueryHookResult = ReturnType<typeof useOperatingSystemsQuery>;
export type OperatingSystemsLazyQueryHookResult = ReturnType<typeof useOperatingSystemsLazyQuery>;
export type OperatingSystemsQueryResult = Apollo.QueryResult<OperatingSystemsQuery, OperatingSystemsQueryVariables>;
export const AllProjectConfigsDocument = gql`
    query AllProjectConfigs($limit: Int, $offset: Int, $where: deliveries_project_configs_bool_exp, $order_by: [deliveries_project_configs_order_by!]) {
  project_configs_aggregate: deliveries_project_configs_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  project_configs: deliveries_project_configs(
    where: $where
    order_by: $order_by
    offset: $offset
    limit: $limit
  ) {
    ...ProjectConfigFields
  }
}
    ${ProjectConfigFieldsFragmentDoc}`;

/**
 * __useAllProjectConfigsQuery__
 *
 * To run a query within a React component, call `useAllProjectConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProjectConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProjectConfigsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useAllProjectConfigsQuery(baseOptions?: Apollo.QueryHookOptions<AllProjectConfigsQuery, AllProjectConfigsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllProjectConfigsQuery, AllProjectConfigsQueryVariables>(AllProjectConfigsDocument, options);
      }
export function useAllProjectConfigsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllProjectConfigsQuery, AllProjectConfigsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllProjectConfigsQuery, AllProjectConfigsQueryVariables>(AllProjectConfigsDocument, options);
        }
export type AllProjectConfigsQueryHookResult = ReturnType<typeof useAllProjectConfigsQuery>;
export type AllProjectConfigsLazyQueryHookResult = ReturnType<typeof useAllProjectConfigsLazyQuery>;
export type AllProjectConfigsQueryResult = Apollo.QueryResult<AllProjectConfigsQuery, AllProjectConfigsQueryVariables>;
export const ProjectConfigDocument = gql`
    query ProjectConfig($id: uuid!) {
  project_config: deliveries_project_configs_by_pk(id: $id) {
    ...ProjectConfigFields
  }
}
    ${ProjectConfigFieldsFragmentDoc}`;

/**
 * __useProjectConfigQuery__
 *
 * To run a query within a React component, call `useProjectConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectConfigQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectConfigQuery(baseOptions: Apollo.QueryHookOptions<ProjectConfigQuery, ProjectConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectConfigQuery, ProjectConfigQueryVariables>(ProjectConfigDocument, options);
      }
export function useProjectConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectConfigQuery, ProjectConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectConfigQuery, ProjectConfigQueryVariables>(ProjectConfigDocument, options);
        }
export type ProjectConfigQueryHookResult = ReturnType<typeof useProjectConfigQuery>;
export type ProjectConfigLazyQueryHookResult = ReturnType<typeof useProjectConfigLazyQuery>;
export type ProjectConfigQueryResult = Apollo.QueryResult<ProjectConfigQuery, ProjectConfigQueryVariables>;
export const CreateProjectConfigDocument = gql`
    mutation CreateProjectConfig($input: deliveries_project_configs_insert_input!) {
  project_config: insert_deliveries_project_configs_one(object: $input) {
    ...ProjectConfigFields
  }
}
    ${ProjectConfigFieldsFragmentDoc}`;
export type CreateProjectConfigMutationFn = Apollo.MutationFunction<CreateProjectConfigMutation, CreateProjectConfigMutationVariables>;

/**
 * __useCreateProjectConfigMutation__
 *
 * To run a mutation, you first call `useCreateProjectConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectConfigMutation, { data, loading, error }] = useCreateProjectConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectConfigMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectConfigMutation, CreateProjectConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectConfigMutation, CreateProjectConfigMutationVariables>(CreateProjectConfigDocument, options);
      }
export type CreateProjectConfigMutationHookResult = ReturnType<typeof useCreateProjectConfigMutation>;
export type CreateProjectConfigMutationResult = Apollo.MutationResult<CreateProjectConfigMutation>;
export type CreateProjectConfigMutationOptions = Apollo.BaseMutationOptions<CreateProjectConfigMutation, CreateProjectConfigMutationVariables>;
export const UpdateProjectConfigDocument = gql`
    mutation UpdateProjectConfig($id: uuid!, $columns: deliveries_project_configs_set_input!) {
  project_config: update_deliveries_project_configs_by_pk(
    pk_columns: {id: $id}
    _set: $columns
  ) {
    ...ProjectConfigFields
  }
}
    ${ProjectConfigFieldsFragmentDoc}`;
export type UpdateProjectConfigMutationFn = Apollo.MutationFunction<UpdateProjectConfigMutation, UpdateProjectConfigMutationVariables>;

/**
 * __useUpdateProjectConfigMutation__
 *
 * To run a mutation, you first call `useUpdateProjectConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectConfigMutation, { data, loading, error }] = useUpdateProjectConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *      columns: // value for 'columns'
 *   },
 * });
 */
export function useUpdateProjectConfigMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectConfigMutation, UpdateProjectConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectConfigMutation, UpdateProjectConfigMutationVariables>(UpdateProjectConfigDocument, options);
      }
export type UpdateProjectConfigMutationHookResult = ReturnType<typeof useUpdateProjectConfigMutation>;
export type UpdateProjectConfigMutationResult = Apollo.MutationResult<UpdateProjectConfigMutation>;
export type UpdateProjectConfigMutationOptions = Apollo.BaseMutationOptions<UpdateProjectConfigMutation, UpdateProjectConfigMutationVariables>;
export const TargetDocument = gql`
    query Target($id: uuid!) {
  target: deliveries_targets_by_pk(id: $id) {
    ...TargetFields
  }
}
    ${TargetFieldsFragmentDoc}`;

/**
 * __useTargetQuery__
 *
 * To run a query within a React component, call `useTargetQuery` and pass it any options that fit your needs.
 * When your component renders, `useTargetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTargetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTargetQuery(baseOptions: Apollo.QueryHookOptions<TargetQuery, TargetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TargetQuery, TargetQueryVariables>(TargetDocument, options);
      }
export function useTargetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TargetQuery, TargetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TargetQuery, TargetQueryVariables>(TargetDocument, options);
        }
export type TargetQueryHookResult = ReturnType<typeof useTargetQuery>;
export type TargetLazyQueryHookResult = ReturnType<typeof useTargetLazyQuery>;
export type TargetQueryResult = Apollo.QueryResult<TargetQuery, TargetQueryVariables>;
export const AllTargetsDocument = gql`
    query AllTargets($limit: Int, $offset: Int, $where: deliveries_targets_bool_exp, $order_by: [deliveries_targets_order_by!]) {
  targets_aggregate: deliveries_targets_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  targets: deliveries_targets(
    where: $where
    order_by: $order_by
    offset: $offset
    limit: $limit
  ) {
    ...TargetFields
  }
}
    ${TargetFieldsFragmentDoc}`;

/**
 * __useAllTargetsQuery__
 *
 * To run a query within a React component, call `useAllTargetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTargetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTargetsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useAllTargetsQuery(baseOptions?: Apollo.QueryHookOptions<AllTargetsQuery, AllTargetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllTargetsQuery, AllTargetsQueryVariables>(AllTargetsDocument, options);
      }
export function useAllTargetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTargetsQuery, AllTargetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllTargetsQuery, AllTargetsQueryVariables>(AllTargetsDocument, options);
        }
export type AllTargetsQueryHookResult = ReturnType<typeof useAllTargetsQuery>;
export type AllTargetsLazyQueryHookResult = ReturnType<typeof useAllTargetsLazyQuery>;
export type AllTargetsQueryResult = Apollo.QueryResult<AllTargetsQuery, AllTargetsQueryVariables>;
export const CreateTargetDocument = gql`
    mutation CreateTarget($input: deliveries_targets_insert_input!) {
  target: insert_deliveries_targets_one(object: $input) {
    ...TargetFields
  }
}
    ${TargetFieldsFragmentDoc}`;
export type CreateTargetMutationFn = Apollo.MutationFunction<CreateTargetMutation, CreateTargetMutationVariables>;

/**
 * __useCreateTargetMutation__
 *
 * To run a mutation, you first call `useCreateTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTargetMutation, { data, loading, error }] = useCreateTargetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTargetMutation(baseOptions?: Apollo.MutationHookOptions<CreateTargetMutation, CreateTargetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTargetMutation, CreateTargetMutationVariables>(CreateTargetDocument, options);
      }
export type CreateTargetMutationHookResult = ReturnType<typeof useCreateTargetMutation>;
export type CreateTargetMutationResult = Apollo.MutationResult<CreateTargetMutation>;
export type CreateTargetMutationOptions = Apollo.BaseMutationOptions<CreateTargetMutation, CreateTargetMutationVariables>;
export const UpdateTargetDocument = gql`
    mutation UpdateTarget($id: uuid!, $columns: deliveries_targets_set_input!, $delete_partner_ids: [uuid!]!, $add_partners: [deliveries_target_partner_insert_input!]!) {
  target: update_deliveries_targets_by_pk(pk_columns: {id: $id}, _set: $columns) {
    ...TargetFields
  }
  delete_target_partners: delete_deliveries_target_partner(
    where: {target_id: {_eq: $id}, partner_id: {_in: $delete_partner_ids}}
  ) {
    affected_rows
  }
  add_partners: insert_deliveries_target_partner(objects: $add_partners) {
    affected_rows
  }
}
    ${TargetFieldsFragmentDoc}`;
export type UpdateTargetMutationFn = Apollo.MutationFunction<UpdateTargetMutation, UpdateTargetMutationVariables>;

/**
 * __useUpdateTargetMutation__
 *
 * To run a mutation, you first call `useUpdateTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTargetMutation, { data, loading, error }] = useUpdateTargetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      columns: // value for 'columns'
 *      delete_partner_ids: // value for 'delete_partner_ids'
 *      add_partners: // value for 'add_partners'
 *   },
 * });
 */
export function useUpdateTargetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTargetMutation, UpdateTargetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTargetMutation, UpdateTargetMutationVariables>(UpdateTargetDocument, options);
      }
export type UpdateTargetMutationHookResult = ReturnType<typeof useUpdateTargetMutation>;
export type UpdateTargetMutationResult = Apollo.MutationResult<UpdateTargetMutation>;
export type UpdateTargetMutationOptions = Apollo.BaseMutationOptions<UpdateTargetMutation, UpdateTargetMutationVariables>;
export const DeleteTargetDocument = gql`
    mutation DeleteTarget($id: uuid!) {
  delete_deliveries_targets_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteTargetMutationFn = Apollo.MutationFunction<DeleteTargetMutation, DeleteTargetMutationVariables>;

/**
 * __useDeleteTargetMutation__
 *
 * To run a mutation, you first call `useDeleteTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTargetMutation, { data, loading, error }] = useDeleteTargetMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTargetMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTargetMutation, DeleteTargetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTargetMutation, DeleteTargetMutationVariables>(DeleteTargetDocument, options);
      }
export type DeleteTargetMutationHookResult = ReturnType<typeof useDeleteTargetMutation>;
export type DeleteTargetMutationResult = Apollo.MutationResult<DeleteTargetMutation>;
export type DeleteTargetMutationOptions = Apollo.BaseMutationOptions<DeleteTargetMutation, DeleteTargetMutationVariables>;
export const AllPartnersDocument = gql`
    query AllPartners($limit: Int, $offset: Int, $where: deliveries_partners_bool_exp, $order_by: [deliveries_partners_order_by!]) {
  partners: deliveries_partners(
    where: $where
    order_by: $order_by
    offset: $offset
    limit: $limit
  ) {
    id
    name
  }
}
    `;

/**
 * __useAllPartnersQuery__
 *
 * To run a query within a React component, call `useAllPartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPartnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPartnersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useAllPartnersQuery(baseOptions?: Apollo.QueryHookOptions<AllPartnersQuery, AllPartnersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllPartnersQuery, AllPartnersQueryVariables>(AllPartnersDocument, options);
      }
export function useAllPartnersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllPartnersQuery, AllPartnersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllPartnersQuery, AllPartnersQueryVariables>(AllPartnersDocument, options);
        }
export type AllPartnersQueryHookResult = ReturnType<typeof useAllPartnersQuery>;
export type AllPartnersLazyQueryHookResult = ReturnType<typeof useAllPartnersLazyQuery>;
export type AllPartnersQueryResult = Apollo.QueryResult<AllPartnersQuery, AllPartnersQueryVariables>;
export const AllDevicesDocument = gql`
    query AllDevices($where: devices_bool_exp, $order_by: [devices_order_by!]) {
  devices_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  devices(where: $where, order_by: $order_by) {
    ...DeviceFields
  }
}
    ${DeviceFieldsFragmentDoc}`;

/**
 * __useAllDevicesQuery__
 *
 * To run a query within a React component, call `useAllDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDevicesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useAllDevicesQuery(baseOptions?: Apollo.QueryHookOptions<AllDevicesQuery, AllDevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllDevicesQuery, AllDevicesQueryVariables>(AllDevicesDocument, options);
      }
export function useAllDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllDevicesQuery, AllDevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllDevicesQuery, AllDevicesQueryVariables>(AllDevicesDocument, options);
        }
export type AllDevicesQueryHookResult = ReturnType<typeof useAllDevicesQuery>;
export type AllDevicesLazyQueryHookResult = ReturnType<typeof useAllDevicesLazyQuery>;
export type AllDevicesQueryResult = Apollo.QueryResult<AllDevicesQuery, AllDevicesQueryVariables>;
export const DeviceDocument = gql`
    query Device($id: uuid!) {
  device: devices_by_pk(id: $id) {
    ...DeviceFields
  }
}
    ${DeviceFieldsFragmentDoc}`;

/**
 * __useDeviceQuery__
 *
 * To run a query within a React component, call `useDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeviceQuery(baseOptions: Apollo.QueryHookOptions<DeviceQuery, DeviceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceQuery, DeviceQueryVariables>(DeviceDocument, options);
      }
export function useDeviceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceQuery, DeviceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceQuery, DeviceQueryVariables>(DeviceDocument, options);
        }
export type DeviceQueryHookResult = ReturnType<typeof useDeviceQuery>;
export type DeviceLazyQueryHookResult = ReturnType<typeof useDeviceLazyQuery>;
export type DeviceQueryResult = Apollo.QueryResult<DeviceQuery, DeviceQueryVariables>;
export const CreateDeviceDocument = gql`
    mutation CreateDevice($input: devices_insert_input!) {
  device: insert_devices_one(object: $input) {
    ...DeviceFields
  }
}
    ${DeviceFieldsFragmentDoc}`;
export type CreateDeviceMutationFn = Apollo.MutationFunction<CreateDeviceMutation, CreateDeviceMutationVariables>;

/**
 * __useCreateDeviceMutation__
 *
 * To run a mutation, you first call `useCreateDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeviceMutation, { data, loading, error }] = useCreateDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDeviceMutation(baseOptions?: Apollo.MutationHookOptions<CreateDeviceMutation, CreateDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDeviceMutation, CreateDeviceMutationVariables>(CreateDeviceDocument, options);
      }
export type CreateDeviceMutationHookResult = ReturnType<typeof useCreateDeviceMutation>;
export type CreateDeviceMutationResult = Apollo.MutationResult<CreateDeviceMutation>;
export type CreateDeviceMutationOptions = Apollo.BaseMutationOptions<CreateDeviceMutation, CreateDeviceMutationVariables>;
export const UpdateDeviceDocument = gql`
    mutation UpdateDevice($id: uuid!, $input: devices_set_input!) {
  device: update_devices_by_pk(pk_columns: {id: $id}, _set: $input) {
    ...DeviceFields
  }
}
    ${DeviceFieldsFragmentDoc}`;
export type UpdateDeviceMutationFn = Apollo.MutationFunction<UpdateDeviceMutation, UpdateDeviceMutationVariables>;

/**
 * __useUpdateDeviceMutation__
 *
 * To run a mutation, you first call `useUpdateDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeviceMutation, { data, loading, error }] = useUpdateDeviceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDeviceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeviceMutation, UpdateDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeviceMutation, UpdateDeviceMutationVariables>(UpdateDeviceDocument, options);
      }
export type UpdateDeviceMutationHookResult = ReturnType<typeof useUpdateDeviceMutation>;
export type UpdateDeviceMutationResult = Apollo.MutationResult<UpdateDeviceMutation>;
export type UpdateDeviceMutationOptions = Apollo.BaseMutationOptions<UpdateDeviceMutation, UpdateDeviceMutationVariables>;
export const AggDevicesByProjectDocument = gql`
    query AggDevicesByProject($project_id: uuid!) {
  project: projects_by_pk(id: $project_id) {
    project_dataset(distinct_on: device_id) {
      device {
        id
        serialno
        description
        clean: project_dataset_aggregate(
          where: {project_id: {_eq: $project_id}, dirty: {_eq: false}}
        ) {
          aggregate {
            count
          }
        }
        dirty: project_dataset_aggregate(
          where: {project_id: {_eq: $project_id}, dirty: {_eq: true}}
        ) {
          aggregate {
            count
          }
        }
        untagged: project_dataset_aggregate(
          where: {project_id: {_eq: $project_id}, dirty: {_is_null: true}}
        ) {
          aggregate {
            count
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAggDevicesByProjectQuery__
 *
 * To run a query within a React component, call `useAggDevicesByProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggDevicesByProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggDevicesByProjectQuery({
 *   variables: {
 *      project_id: // value for 'project_id'
 *   },
 * });
 */
export function useAggDevicesByProjectQuery(baseOptions: Apollo.QueryHookOptions<AggDevicesByProjectQuery, AggDevicesByProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggDevicesByProjectQuery, AggDevicesByProjectQueryVariables>(AggDevicesByProjectDocument, options);
      }
export function useAggDevicesByProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggDevicesByProjectQuery, AggDevicesByProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggDevicesByProjectQuery, AggDevicesByProjectQueryVariables>(AggDevicesByProjectDocument, options);
        }
export type AggDevicesByProjectQueryHookResult = ReturnType<typeof useAggDevicesByProjectQuery>;
export type AggDevicesByProjectLazyQueryHookResult = ReturnType<typeof useAggDevicesByProjectLazyQuery>;
export type AggDevicesByProjectQueryResult = Apollo.QueryResult<AggDevicesByProjectQuery, AggDevicesByProjectQueryVariables>;
export const AllEnginesDocument = gql`
    query AllEngines($limit: Int, $offset: Int, $where: engines_bool_exp, $order_by: [engines_order_by!]) {
  engines_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  engines(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
    ...EngineFields
  }
}
    ${EngineFieldsFragmentDoc}`;

/**
 * __useAllEnginesQuery__
 *
 * To run a query within a React component, call `useAllEnginesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEnginesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEnginesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useAllEnginesQuery(baseOptions?: Apollo.QueryHookOptions<AllEnginesQuery, AllEnginesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllEnginesQuery, AllEnginesQueryVariables>(AllEnginesDocument, options);
      }
export function useAllEnginesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllEnginesQuery, AllEnginesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllEnginesQuery, AllEnginesQueryVariables>(AllEnginesDocument, options);
        }
export type AllEnginesQueryHookResult = ReturnType<typeof useAllEnginesQuery>;
export type AllEnginesLazyQueryHookResult = ReturnType<typeof useAllEnginesLazyQuery>;
export type AllEnginesQueryResult = Apollo.QueryResult<AllEnginesQuery, AllEnginesQueryVariables>;
export const CreateEngineDocument = gql`
    mutation CreateEngine($input: engines_insert_input!) {
  insert_engines_one(object: $input) {
    ...EngineFields
  }
}
    ${EngineFieldsFragmentDoc}`;
export type CreateEngineMutationFn = Apollo.MutationFunction<CreateEngineMutation, CreateEngineMutationVariables>;

/**
 * __useCreateEngineMutation__
 *
 * To run a mutation, you first call `useCreateEngineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEngineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEngineMutation, { data, loading, error }] = useCreateEngineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEngineMutation(baseOptions?: Apollo.MutationHookOptions<CreateEngineMutation, CreateEngineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEngineMutation, CreateEngineMutationVariables>(CreateEngineDocument, options);
      }
export type CreateEngineMutationHookResult = ReturnType<typeof useCreateEngineMutation>;
export type CreateEngineMutationResult = Apollo.MutationResult<CreateEngineMutation>;
export type CreateEngineMutationOptions = Apollo.BaseMutationOptions<CreateEngineMutation, CreateEngineMutationVariables>;
export const AllGroundTruthTypesDocument = gql`
    query AllGroundTruthTypes($where: ground_truth_types_bool_exp, $order_by: [ground_truth_types_order_by!], $limit: Int, $offset: Int) {
  ground_truth_types_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  ground_truth_types(
    where: $where
    order_by: $order_by
    limit: $limit
    offset: $offset
  ) {
    ...GroundTruthTypeFields
  }
}
    ${GroundTruthTypeFieldsFragmentDoc}`;

/**
 * __useAllGroundTruthTypesQuery__
 *
 * To run a query within a React component, call `useAllGroundTruthTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllGroundTruthTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllGroundTruthTypesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllGroundTruthTypesQuery(baseOptions?: Apollo.QueryHookOptions<AllGroundTruthTypesQuery, AllGroundTruthTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllGroundTruthTypesQuery, AllGroundTruthTypesQueryVariables>(AllGroundTruthTypesDocument, options);
      }
export function useAllGroundTruthTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllGroundTruthTypesQuery, AllGroundTruthTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllGroundTruthTypesQuery, AllGroundTruthTypesQueryVariables>(AllGroundTruthTypesDocument, options);
        }
export type AllGroundTruthTypesQueryHookResult = ReturnType<typeof useAllGroundTruthTypesQuery>;
export type AllGroundTruthTypesLazyQueryHookResult = ReturnType<typeof useAllGroundTruthTypesLazyQuery>;
export type AllGroundTruthTypesQueryResult = Apollo.QueryResult<AllGroundTruthTypesQuery, AllGroundTruthTypesQueryVariables>;
export const GroundTruthTypeDocument = gql`
    query GroundTruthType($id: uuid!) {
  ground_truth_type: ground_truth_types_by_pk(id: $id) {
    ...GroundTruthTypeFields
  }
}
    ${GroundTruthTypeFieldsFragmentDoc}`;

/**
 * __useGroundTruthTypeQuery__
 *
 * To run a query within a React component, call `useGroundTruthTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroundTruthTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroundTruthTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGroundTruthTypeQuery(baseOptions: Apollo.QueryHookOptions<GroundTruthTypeQuery, GroundTruthTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroundTruthTypeQuery, GroundTruthTypeQueryVariables>(GroundTruthTypeDocument, options);
      }
export function useGroundTruthTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroundTruthTypeQuery, GroundTruthTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroundTruthTypeQuery, GroundTruthTypeQueryVariables>(GroundTruthTypeDocument, options);
        }
export type GroundTruthTypeQueryHookResult = ReturnType<typeof useGroundTruthTypeQuery>;
export type GroundTruthTypeLazyQueryHookResult = ReturnType<typeof useGroundTruthTypeLazyQuery>;
export type GroundTruthTypeQueryResult = Apollo.QueryResult<GroundTruthTypeQuery, GroundTruthTypeQueryVariables>;
export const CreateGroundTruthTypeDocument = gql`
    mutation CreateGroundTruthType($input: ground_truth_types_insert_input!) {
  ground_truth_type: insert_ground_truth_types_one(object: $input) {
    ...GroundTruthTypeFields
  }
}
    ${GroundTruthTypeFieldsFragmentDoc}`;
export type CreateGroundTruthTypeMutationFn = Apollo.MutationFunction<CreateGroundTruthTypeMutation, CreateGroundTruthTypeMutationVariables>;

/**
 * __useCreateGroundTruthTypeMutation__
 *
 * To run a mutation, you first call `useCreateGroundTruthTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroundTruthTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroundTruthTypeMutation, { data, loading, error }] = useCreateGroundTruthTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGroundTruthTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateGroundTruthTypeMutation, CreateGroundTruthTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGroundTruthTypeMutation, CreateGroundTruthTypeMutationVariables>(CreateGroundTruthTypeDocument, options);
      }
export type CreateGroundTruthTypeMutationHookResult = ReturnType<typeof useCreateGroundTruthTypeMutation>;
export type CreateGroundTruthTypeMutationResult = Apollo.MutationResult<CreateGroundTruthTypeMutation>;
export type CreateGroundTruthTypeMutationOptions = Apollo.BaseMutationOptions<CreateGroundTruthTypeMutation, CreateGroundTruthTypeMutationVariables>;
export const UpdateGroundTruthTypeDocument = gql`
    mutation UpdateGroundTruthType($id: uuid!, $input: ground_truth_types_set_input!) {
  ground_truth_type: update_ground_truth_types_by_pk(
    pk_columns: {id: $id}
    _set: $input
  ) {
    ...GroundTruthTypeFields
  }
}
    ${GroundTruthTypeFieldsFragmentDoc}`;
export type UpdateGroundTruthTypeMutationFn = Apollo.MutationFunction<UpdateGroundTruthTypeMutation, UpdateGroundTruthTypeMutationVariables>;

/**
 * __useUpdateGroundTruthTypeMutation__
 *
 * To run a mutation, you first call `useUpdateGroundTruthTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroundTruthTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroundTruthTypeMutation, { data, loading, error }] = useUpdateGroundTruthTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGroundTruthTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGroundTruthTypeMutation, UpdateGroundTruthTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGroundTruthTypeMutation, UpdateGroundTruthTypeMutationVariables>(UpdateGroundTruthTypeDocument, options);
      }
export type UpdateGroundTruthTypeMutationHookResult = ReturnType<typeof useUpdateGroundTruthTypeMutation>;
export type UpdateGroundTruthTypeMutationResult = Apollo.MutationResult<UpdateGroundTruthTypeMutation>;
export type UpdateGroundTruthTypeMutationOptions = Apollo.BaseMutationOptions<UpdateGroundTruthTypeMutation, UpdateGroundTruthTypeMutationVariables>;
export const AllIntegrationTestInstructionsDocument = gql`
    query AllIntegrationTestInstructions($limit: Int, $offset: Int, $order_by: [integration_test_instructions_order_by!], $where: integration_test_instructions_bool_exp) {
  instructions_aggregate: integration_test_instructions_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  instructions: integration_test_instructions(
    limit: $limit
    offset: $offset
    order_by: $order_by
    where: $where
  ) {
    ...InstructionFields
  }
}
    ${InstructionFieldsFragmentDoc}`;

/**
 * __useAllIntegrationTestInstructionsQuery__
 *
 * To run a query within a React component, call `useAllIntegrationTestInstructionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllIntegrationTestInstructionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllIntegrationTestInstructionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllIntegrationTestInstructionsQuery(baseOptions?: Apollo.QueryHookOptions<AllIntegrationTestInstructionsQuery, AllIntegrationTestInstructionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllIntegrationTestInstructionsQuery, AllIntegrationTestInstructionsQueryVariables>(AllIntegrationTestInstructionsDocument, options);
      }
export function useAllIntegrationTestInstructionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllIntegrationTestInstructionsQuery, AllIntegrationTestInstructionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllIntegrationTestInstructionsQuery, AllIntegrationTestInstructionsQueryVariables>(AllIntegrationTestInstructionsDocument, options);
        }
export type AllIntegrationTestInstructionsQueryHookResult = ReturnType<typeof useAllIntegrationTestInstructionsQuery>;
export type AllIntegrationTestInstructionsLazyQueryHookResult = ReturnType<typeof useAllIntegrationTestInstructionsLazyQuery>;
export type AllIntegrationTestInstructionsQueryResult = Apollo.QueryResult<AllIntegrationTestInstructionsQuery, AllIntegrationTestInstructionsQueryVariables>;
export const IntegrationTestInstructionDocument = gql`
    query IntegrationTestInstruction($id: uuid!) {
  instruction: integration_test_instructions_by_pk(id: $id) {
    ...InstructionFields
  }
}
    ${InstructionFieldsFragmentDoc}`;

/**
 * __useIntegrationTestInstructionQuery__
 *
 * To run a query within a React component, call `useIntegrationTestInstructionQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationTestInstructionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationTestInstructionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIntegrationTestInstructionQuery(baseOptions: Apollo.QueryHookOptions<IntegrationTestInstructionQuery, IntegrationTestInstructionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IntegrationTestInstructionQuery, IntegrationTestInstructionQueryVariables>(IntegrationTestInstructionDocument, options);
      }
export function useIntegrationTestInstructionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IntegrationTestInstructionQuery, IntegrationTestInstructionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IntegrationTestInstructionQuery, IntegrationTestInstructionQueryVariables>(IntegrationTestInstructionDocument, options);
        }
export type IntegrationTestInstructionQueryHookResult = ReturnType<typeof useIntegrationTestInstructionQuery>;
export type IntegrationTestInstructionLazyQueryHookResult = ReturnType<typeof useIntegrationTestInstructionLazyQuery>;
export type IntegrationTestInstructionQueryResult = Apollo.QueryResult<IntegrationTestInstructionQuery, IntegrationTestInstructionQueryVariables>;
export const IntegrationTestInstructionTranslationsDocument = gql`
    subscription IntegrationTestInstructionTranslations($id: uuid!, $order_by: [integration_test_instruction_translation_order_by!]) {
  translations: integration_test_instruction_translation(
    where: {instruction_id: {_eq: $id}}
    order_by: $order_by
  ) {
    language: instruction_translation_language {
      id
      description
    }
    description
  }
}
    `;

/**
 * __useIntegrationTestInstructionTranslationsSubscription__
 *
 * To run a query within a React component, call `useIntegrationTestInstructionTranslationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationTestInstructionTranslationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationTestInstructionTranslationsSubscription({
 *   variables: {
 *      id: // value for 'id'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useIntegrationTestInstructionTranslationsSubscription(baseOptions: Apollo.SubscriptionHookOptions<IntegrationTestInstructionTranslationsSubscription, IntegrationTestInstructionTranslationsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<IntegrationTestInstructionTranslationsSubscription, IntegrationTestInstructionTranslationsSubscriptionVariables>(IntegrationTestInstructionTranslationsDocument, options);
      }
export type IntegrationTestInstructionTranslationsSubscriptionHookResult = ReturnType<typeof useIntegrationTestInstructionTranslationsSubscription>;
export type IntegrationTestInstructionTranslationsSubscriptionResult = Apollo.SubscriptionResult<IntegrationTestInstructionTranslationsSubscription>;
export const UpsertIntegrationTestInstructionTranslationDocument = gql`
    mutation UpsertIntegrationTestInstructionTranslation($object: integration_test_instruction_translation_insert_input!) {
  translation: insert_integration_test_instruction_translation_one(
    object: $object
    on_conflict: {constraint: instruction_translation_pkey, update_columns: [description]}
  ) {
    description
    instruction_id
    language
  }
}
    `;
export type UpsertIntegrationTestInstructionTranslationMutationFn = Apollo.MutationFunction<UpsertIntegrationTestInstructionTranslationMutation, UpsertIntegrationTestInstructionTranslationMutationVariables>;

/**
 * __useUpsertIntegrationTestInstructionTranslationMutation__
 *
 * To run a mutation, you first call `useUpsertIntegrationTestInstructionTranslationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertIntegrationTestInstructionTranslationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertIntegrationTestInstructionTranslationMutation, { data, loading, error }] = useUpsertIntegrationTestInstructionTranslationMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpsertIntegrationTestInstructionTranslationMutation(baseOptions?: Apollo.MutationHookOptions<UpsertIntegrationTestInstructionTranslationMutation, UpsertIntegrationTestInstructionTranslationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertIntegrationTestInstructionTranslationMutation, UpsertIntegrationTestInstructionTranslationMutationVariables>(UpsertIntegrationTestInstructionTranslationDocument, options);
      }
export type UpsertIntegrationTestInstructionTranslationMutationHookResult = ReturnType<typeof useUpsertIntegrationTestInstructionTranslationMutation>;
export type UpsertIntegrationTestInstructionTranslationMutationResult = Apollo.MutationResult<UpsertIntegrationTestInstructionTranslationMutation>;
export type UpsertIntegrationTestInstructionTranslationMutationOptions = Apollo.BaseMutationOptions<UpsertIntegrationTestInstructionTranslationMutation, UpsertIntegrationTestInstructionTranslationMutationVariables>;
export const UpdateIntegrationTestInstructionDocument = gql`
    mutation UpdateIntegrationTestInstruction($id: uuid!, $input: integration_test_instructions_set_input!) {
  instruction: update_integration_test_instructions_by_pk(
    pk_columns: {id: $id}
    _set: $input
  ) {
    id
  }
}
    `;
export type UpdateIntegrationTestInstructionMutationFn = Apollo.MutationFunction<UpdateIntegrationTestInstructionMutation, UpdateIntegrationTestInstructionMutationVariables>;

/**
 * __useUpdateIntegrationTestInstructionMutation__
 *
 * To run a mutation, you first call `useUpdateIntegrationTestInstructionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIntegrationTestInstructionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIntegrationTestInstructionMutation, { data, loading, error }] = useUpdateIntegrationTestInstructionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIntegrationTestInstructionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIntegrationTestInstructionMutation, UpdateIntegrationTestInstructionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIntegrationTestInstructionMutation, UpdateIntegrationTestInstructionMutationVariables>(UpdateIntegrationTestInstructionDocument, options);
      }
export type UpdateIntegrationTestInstructionMutationHookResult = ReturnType<typeof useUpdateIntegrationTestInstructionMutation>;
export type UpdateIntegrationTestInstructionMutationResult = Apollo.MutationResult<UpdateIntegrationTestInstructionMutation>;
export type UpdateIntegrationTestInstructionMutationOptions = Apollo.BaseMutationOptions<UpdateIntegrationTestInstructionMutation, UpdateIntegrationTestInstructionMutationVariables>;
export const AllIntegrationTestExpectationsDocument = gql`
    query AllIntegrationTestExpectations($limit: Int, $offset: Int, $order_by: [integration_test_expectations_order_by!], $where: integration_test_expectations_bool_exp) {
  expectations_aggregate: integration_test_expectations_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  expectations: integration_test_expectations(
    limit: $limit
    offset: $offset
    order_by: $order_by
    where: $where
  ) {
    ...ExpectationFields
  }
}
    ${ExpectationFieldsFragmentDoc}`;

/**
 * __useAllIntegrationTestExpectationsQuery__
 *
 * To run a query within a React component, call `useAllIntegrationTestExpectationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllIntegrationTestExpectationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllIntegrationTestExpectationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllIntegrationTestExpectationsQuery(baseOptions?: Apollo.QueryHookOptions<AllIntegrationTestExpectationsQuery, AllIntegrationTestExpectationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllIntegrationTestExpectationsQuery, AllIntegrationTestExpectationsQueryVariables>(AllIntegrationTestExpectationsDocument, options);
      }
export function useAllIntegrationTestExpectationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllIntegrationTestExpectationsQuery, AllIntegrationTestExpectationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllIntegrationTestExpectationsQuery, AllIntegrationTestExpectationsQueryVariables>(AllIntegrationTestExpectationsDocument, options);
        }
export type AllIntegrationTestExpectationsQueryHookResult = ReturnType<typeof useAllIntegrationTestExpectationsQuery>;
export type AllIntegrationTestExpectationsLazyQueryHookResult = ReturnType<typeof useAllIntegrationTestExpectationsLazyQuery>;
export type AllIntegrationTestExpectationsQueryResult = Apollo.QueryResult<AllIntegrationTestExpectationsQuery, AllIntegrationTestExpectationsQueryVariables>;
export const IntegrationTestExpectationDocument = gql`
    query IntegrationTestExpectation($id: uuid!) {
  expectation: integration_test_expectations_by_pk(id: $id) {
    ...ExpectationFields
  }
}
    ${ExpectationFieldsFragmentDoc}`;

/**
 * __useIntegrationTestExpectationQuery__
 *
 * To run a query within a React component, call `useIntegrationTestExpectationQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationTestExpectationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationTestExpectationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIntegrationTestExpectationQuery(baseOptions: Apollo.QueryHookOptions<IntegrationTestExpectationQuery, IntegrationTestExpectationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IntegrationTestExpectationQuery, IntegrationTestExpectationQueryVariables>(IntegrationTestExpectationDocument, options);
      }
export function useIntegrationTestExpectationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IntegrationTestExpectationQuery, IntegrationTestExpectationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IntegrationTestExpectationQuery, IntegrationTestExpectationQueryVariables>(IntegrationTestExpectationDocument, options);
        }
export type IntegrationTestExpectationQueryHookResult = ReturnType<typeof useIntegrationTestExpectationQuery>;
export type IntegrationTestExpectationLazyQueryHookResult = ReturnType<typeof useIntegrationTestExpectationLazyQuery>;
export type IntegrationTestExpectationQueryResult = Apollo.QueryResult<IntegrationTestExpectationQuery, IntegrationTestExpectationQueryVariables>;
export const IntegrationTestExpectationTranslationsDocument = gql`
    subscription IntegrationTestExpectationTranslations($id: uuid!, $order_by: [integration_test_expectation_translation_order_by!]) {
  translations: integration_test_expectation_translation(
    where: {expectation_id: {_eq: $id}}
    order_by: $order_by
  ) {
    language: expectation_translation_language {
      id
      description
    }
    description
  }
}
    `;

/**
 * __useIntegrationTestExpectationTranslationsSubscription__
 *
 * To run a query within a React component, call `useIntegrationTestExpectationTranslationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationTestExpectationTranslationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationTestExpectationTranslationsSubscription({
 *   variables: {
 *      id: // value for 'id'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useIntegrationTestExpectationTranslationsSubscription(baseOptions: Apollo.SubscriptionHookOptions<IntegrationTestExpectationTranslationsSubscription, IntegrationTestExpectationTranslationsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<IntegrationTestExpectationTranslationsSubscription, IntegrationTestExpectationTranslationsSubscriptionVariables>(IntegrationTestExpectationTranslationsDocument, options);
      }
export type IntegrationTestExpectationTranslationsSubscriptionHookResult = ReturnType<typeof useIntegrationTestExpectationTranslationsSubscription>;
export type IntegrationTestExpectationTranslationsSubscriptionResult = Apollo.SubscriptionResult<IntegrationTestExpectationTranslationsSubscription>;
export const UpsertIntegrationTestExpectationTranslationDocument = gql`
    mutation UpsertIntegrationTestExpectationTranslation($object: integration_test_expectation_translation_insert_input!) {
  translation: insert_integration_test_expectation_translation_one(
    object: $object
    on_conflict: {constraint: expectation_translation_pkey, update_columns: [description]}
  ) {
    description
    expectation_id
    language
  }
}
    `;
export type UpsertIntegrationTestExpectationTranslationMutationFn = Apollo.MutationFunction<UpsertIntegrationTestExpectationTranslationMutation, UpsertIntegrationTestExpectationTranslationMutationVariables>;

/**
 * __useUpsertIntegrationTestExpectationTranslationMutation__
 *
 * To run a mutation, you first call `useUpsertIntegrationTestExpectationTranslationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertIntegrationTestExpectationTranslationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertIntegrationTestExpectationTranslationMutation, { data, loading, error }] = useUpsertIntegrationTestExpectationTranslationMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpsertIntegrationTestExpectationTranslationMutation(baseOptions?: Apollo.MutationHookOptions<UpsertIntegrationTestExpectationTranslationMutation, UpsertIntegrationTestExpectationTranslationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertIntegrationTestExpectationTranslationMutation, UpsertIntegrationTestExpectationTranslationMutationVariables>(UpsertIntegrationTestExpectationTranslationDocument, options);
      }
export type UpsertIntegrationTestExpectationTranslationMutationHookResult = ReturnType<typeof useUpsertIntegrationTestExpectationTranslationMutation>;
export type UpsertIntegrationTestExpectationTranslationMutationResult = Apollo.MutationResult<UpsertIntegrationTestExpectationTranslationMutation>;
export type UpsertIntegrationTestExpectationTranslationMutationOptions = Apollo.BaseMutationOptions<UpsertIntegrationTestExpectationTranslationMutation, UpsertIntegrationTestExpectationTranslationMutationVariables>;
export const UpdateIntegrationTestExpectationDocument = gql`
    mutation UpdateIntegrationTestExpectation($id: uuid!, $input: integration_test_expectations_set_input!) {
  expectation: update_integration_test_expectations_by_pk(
    pk_columns: {id: $id}
    _set: $input
  ) {
    id
  }
}
    `;
export type UpdateIntegrationTestExpectationMutationFn = Apollo.MutationFunction<UpdateIntegrationTestExpectationMutation, UpdateIntegrationTestExpectationMutationVariables>;

/**
 * __useUpdateIntegrationTestExpectationMutation__
 *
 * To run a mutation, you first call `useUpdateIntegrationTestExpectationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIntegrationTestExpectationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIntegrationTestExpectationMutation, { data, loading, error }] = useUpdateIntegrationTestExpectationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIntegrationTestExpectationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIntegrationTestExpectationMutation, UpdateIntegrationTestExpectationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIntegrationTestExpectationMutation, UpdateIntegrationTestExpectationMutationVariables>(UpdateIntegrationTestExpectationDocument, options);
      }
export type UpdateIntegrationTestExpectationMutationHookResult = ReturnType<typeof useUpdateIntegrationTestExpectationMutation>;
export type UpdateIntegrationTestExpectationMutationResult = Apollo.MutationResult<UpdateIntegrationTestExpectationMutation>;
export type UpdateIntegrationTestExpectationMutationOptions = Apollo.BaseMutationOptions<UpdateIntegrationTestExpectationMutation, UpdateIntegrationTestExpectationMutationVariables>;
export const AllIntegrationTestSequencesDocument = gql`
    query AllIntegrationTestSequences($limit: Int, $offset: Int, $order_by: [integration_test_sequences_order_by!], $where: integration_test_sequences_bool_exp) {
  sequences_aggregate: integration_test_sequences_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  sequences: integration_test_sequences(
    limit: $limit
    offset: $offset
    order_by: $order_by
    where: $where
  ) {
    ...IntegrationTestSequenceFields
    steps_aggregate {
      aggregate {
        count
      }
    }
  }
}
    ${IntegrationTestSequenceFieldsFragmentDoc}`;

/**
 * __useAllIntegrationTestSequencesQuery__
 *
 * To run a query within a React component, call `useAllIntegrationTestSequencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllIntegrationTestSequencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllIntegrationTestSequencesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllIntegrationTestSequencesQuery(baseOptions?: Apollo.QueryHookOptions<AllIntegrationTestSequencesQuery, AllIntegrationTestSequencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllIntegrationTestSequencesQuery, AllIntegrationTestSequencesQueryVariables>(AllIntegrationTestSequencesDocument, options);
      }
export function useAllIntegrationTestSequencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllIntegrationTestSequencesQuery, AllIntegrationTestSequencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllIntegrationTestSequencesQuery, AllIntegrationTestSequencesQueryVariables>(AllIntegrationTestSequencesDocument, options);
        }
export type AllIntegrationTestSequencesQueryHookResult = ReturnType<typeof useAllIntegrationTestSequencesQuery>;
export type AllIntegrationTestSequencesLazyQueryHookResult = ReturnType<typeof useAllIntegrationTestSequencesLazyQuery>;
export type AllIntegrationTestSequencesQueryResult = Apollo.QueryResult<AllIntegrationTestSequencesQuery, AllIntegrationTestSequencesQueryVariables>;
export const IntegrationTestSequenceDocument = gql`
    query IntegrationTestSequence($id: uuid!) {
  sequence: integration_test_sequences_by_pk(id: $id) {
    ...IntegrationTestSequenceFields
    steps {
      id
      position
      instruction {
        ...InstructionFields
      }
      expectation_steps {
        expectation {
          ...ExpectationFields
        }
      }
    }
  }
}
    ${IntegrationTestSequenceFieldsFragmentDoc}
${InstructionFieldsFragmentDoc}
${ExpectationFieldsFragmentDoc}`;

/**
 * __useIntegrationTestSequenceQuery__
 *
 * To run a query within a React component, call `useIntegrationTestSequenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationTestSequenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationTestSequenceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIntegrationTestSequenceQuery(baseOptions: Apollo.QueryHookOptions<IntegrationTestSequenceQuery, IntegrationTestSequenceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IntegrationTestSequenceQuery, IntegrationTestSequenceQueryVariables>(IntegrationTestSequenceDocument, options);
      }
export function useIntegrationTestSequenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IntegrationTestSequenceQuery, IntegrationTestSequenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IntegrationTestSequenceQuery, IntegrationTestSequenceQueryVariables>(IntegrationTestSequenceDocument, options);
        }
export type IntegrationTestSequenceQueryHookResult = ReturnType<typeof useIntegrationTestSequenceQuery>;
export type IntegrationTestSequenceLazyQueryHookResult = ReturnType<typeof useIntegrationTestSequenceLazyQuery>;
export type IntegrationTestSequenceQueryResult = Apollo.QueryResult<IntegrationTestSequenceQuery, IntegrationTestSequenceQueryVariables>;
export const AllIntegrationTestStepsDocument = gql`
    query AllIntegrationTestSteps($limit: Int, $offset: Int, $where: integration_test_steps_bool_exp, $order_by: [integration_test_steps_order_by!]) {
  steps: integration_test_steps(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    id
    position
    instruction {
      id
      description
    }
    expectation_steps {
      expectation {
        description
      }
    }
    expectation_steps_aggregate {
      aggregate {
        count
      }
    }
  }
  steps_aggregate: integration_test_steps_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useAllIntegrationTestStepsQuery__
 *
 * To run a query within a React component, call `useAllIntegrationTestStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllIntegrationTestStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllIntegrationTestStepsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useAllIntegrationTestStepsQuery(baseOptions?: Apollo.QueryHookOptions<AllIntegrationTestStepsQuery, AllIntegrationTestStepsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllIntegrationTestStepsQuery, AllIntegrationTestStepsQueryVariables>(AllIntegrationTestStepsDocument, options);
      }
export function useAllIntegrationTestStepsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllIntegrationTestStepsQuery, AllIntegrationTestStepsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllIntegrationTestStepsQuery, AllIntegrationTestStepsQueryVariables>(AllIntegrationTestStepsDocument, options);
        }
export type AllIntegrationTestStepsQueryHookResult = ReturnType<typeof useAllIntegrationTestStepsQuery>;
export type AllIntegrationTestStepsLazyQueryHookResult = ReturnType<typeof useAllIntegrationTestStepsLazyQuery>;
export type AllIntegrationTestStepsQueryResult = Apollo.QueryResult<AllIntegrationTestStepsQuery, AllIntegrationTestStepsQueryVariables>;
export const InsertIntegrationTestSequenceDocument = gql`
    mutation InsertIntegrationTestSequence($input: integration_test_sequences_insert_input!) {
  sequence: insert_integration_test_sequences_one(object: $input) {
    ...IntegrationTestSequenceFields
  }
}
    ${IntegrationTestSequenceFieldsFragmentDoc}`;
export type InsertIntegrationTestSequenceMutationFn = Apollo.MutationFunction<InsertIntegrationTestSequenceMutation, InsertIntegrationTestSequenceMutationVariables>;

/**
 * __useInsertIntegrationTestSequenceMutation__
 *
 * To run a mutation, you first call `useInsertIntegrationTestSequenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertIntegrationTestSequenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertIntegrationTestSequenceMutation, { data, loading, error }] = useInsertIntegrationTestSequenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsertIntegrationTestSequenceMutation(baseOptions?: Apollo.MutationHookOptions<InsertIntegrationTestSequenceMutation, InsertIntegrationTestSequenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertIntegrationTestSequenceMutation, InsertIntegrationTestSequenceMutationVariables>(InsertIntegrationTestSequenceDocument, options);
      }
export type InsertIntegrationTestSequenceMutationHookResult = ReturnType<typeof useInsertIntegrationTestSequenceMutation>;
export type InsertIntegrationTestSequenceMutationResult = Apollo.MutationResult<InsertIntegrationTestSequenceMutation>;
export type InsertIntegrationTestSequenceMutationOptions = Apollo.BaseMutationOptions<InsertIntegrationTestSequenceMutation, InsertIntegrationTestSequenceMutationVariables>;
export const UpdateIntegrationTestSequenceDocument = gql`
    mutation UpdateIntegrationTestSequence($id: uuid!, $sequence: integration_test_sequences_set_input!, $steps: [integration_test_steps_insert_input!]!) {
  sequence: update_integration_test_sequences_by_pk(
    pk_columns: {id: $id}
    _set: $sequence
  ) {
    ...IntegrationTestSequenceFields
  }
  deleted_steps: delete_integration_test_steps(where: {sequence_id: {_eq: $id}}) {
    affected_rows
  }
  inserted_steps: insert_integration_test_steps(objects: $steps) {
    affected_rows
  }
}
    ${IntegrationTestSequenceFieldsFragmentDoc}`;
export type UpdateIntegrationTestSequenceMutationFn = Apollo.MutationFunction<UpdateIntegrationTestSequenceMutation, UpdateIntegrationTestSequenceMutationVariables>;

/**
 * __useUpdateIntegrationTestSequenceMutation__
 *
 * To run a mutation, you first call `useUpdateIntegrationTestSequenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIntegrationTestSequenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIntegrationTestSequenceMutation, { data, loading, error }] = useUpdateIntegrationTestSequenceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sequence: // value for 'sequence'
 *      steps: // value for 'steps'
 *   },
 * });
 */
export function useUpdateIntegrationTestSequenceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIntegrationTestSequenceMutation, UpdateIntegrationTestSequenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIntegrationTestSequenceMutation, UpdateIntegrationTestSequenceMutationVariables>(UpdateIntegrationTestSequenceDocument, options);
      }
export type UpdateIntegrationTestSequenceMutationHookResult = ReturnType<typeof useUpdateIntegrationTestSequenceMutation>;
export type UpdateIntegrationTestSequenceMutationResult = Apollo.MutationResult<UpdateIntegrationTestSequenceMutation>;
export type UpdateIntegrationTestSequenceMutationOptions = Apollo.BaseMutationOptions<UpdateIntegrationTestSequenceMutation, UpdateIntegrationTestSequenceMutationVariables>;
export const IntegrationTestProjectSequencesDocument = gql`
    subscription IntegrationTestProjectSequences($project_id: uuid!, $order_by: [integration_test_sequences_order_by!]) {
  sequences: integration_test_sequences(
    where: {project_sequences: {project_id: {_eq: $project_id}}}
    order_by: $order_by
  ) {
    id
    description
    observances_aggregate(where: {project_id: {_eq: $project_id}}) {
      aggregate {
        count
        max {
          created_at
        }
      }
    }
    observances(
      where: {project_id: {_eq: $project_id}}
      order_by: {created_at: desc}
    ) {
      id
      user {
        name
      }
      engine {
        build_version
        release_number
      }
      created_at
      observations {
        result
      }
    }
  }
}
    `;

/**
 * __useIntegrationTestProjectSequencesSubscription__
 *
 * To run a query within a React component, call `useIntegrationTestProjectSequencesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationTestProjectSequencesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationTestProjectSequencesSubscription({
 *   variables: {
 *      project_id: // value for 'project_id'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useIntegrationTestProjectSequencesSubscription(baseOptions: Apollo.SubscriptionHookOptions<IntegrationTestProjectSequencesSubscription, IntegrationTestProjectSequencesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<IntegrationTestProjectSequencesSubscription, IntegrationTestProjectSequencesSubscriptionVariables>(IntegrationTestProjectSequencesDocument, options);
      }
export type IntegrationTestProjectSequencesSubscriptionHookResult = ReturnType<typeof useIntegrationTestProjectSequencesSubscription>;
export type IntegrationTestProjectSequencesSubscriptionResult = Apollo.SubscriptionResult<IntegrationTestProjectSequencesSubscription>;
export const InsertIntegrationTestObservanceDocument = gql`
    mutation InsertIntegrationTestObservance($input: integration_test_observances_insert_input!) {
  observance: insert_integration_test_observances_one(object: $input) {
    id
  }
}
    `;
export type InsertIntegrationTestObservanceMutationFn = Apollo.MutationFunction<InsertIntegrationTestObservanceMutation, InsertIntegrationTestObservanceMutationVariables>;

/**
 * __useInsertIntegrationTestObservanceMutation__
 *
 * To run a mutation, you first call `useInsertIntegrationTestObservanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertIntegrationTestObservanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertIntegrationTestObservanceMutation, { data, loading, error }] = useInsertIntegrationTestObservanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsertIntegrationTestObservanceMutation(baseOptions?: Apollo.MutationHookOptions<InsertIntegrationTestObservanceMutation, InsertIntegrationTestObservanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertIntegrationTestObservanceMutation, InsertIntegrationTestObservanceMutationVariables>(InsertIntegrationTestObservanceDocument, options);
      }
export type InsertIntegrationTestObservanceMutationHookResult = ReturnType<typeof useInsertIntegrationTestObservanceMutation>;
export type InsertIntegrationTestObservanceMutationResult = Apollo.MutationResult<InsertIntegrationTestObservanceMutation>;
export type InsertIntegrationTestObservanceMutationOptions = Apollo.BaseMutationOptions<InsertIntegrationTestObservanceMutation, InsertIntegrationTestObservanceMutationVariables>;
export const IntegrationTestObservanceResultsDocument = gql`
    subscription IntegrationTestObservanceResults($id: uuid!) {
  observance: integration_test_observances_by_pk(id: $id) {
    id
    observations {
      result
      expectation_step {
        step {
          id
        }
        expectation {
          id
          description
        }
      }
    }
    sequence {
      steps(order_by: {position: asc}) {
        id
        instruction {
          id
          description
        }
      }
    }
  }
}
    `;

/**
 * __useIntegrationTestObservanceResultsSubscription__
 *
 * To run a query within a React component, call `useIntegrationTestObservanceResultsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationTestObservanceResultsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationTestObservanceResultsSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIntegrationTestObservanceResultsSubscription(baseOptions: Apollo.SubscriptionHookOptions<IntegrationTestObservanceResultsSubscription, IntegrationTestObservanceResultsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<IntegrationTestObservanceResultsSubscription, IntegrationTestObservanceResultsSubscriptionVariables>(IntegrationTestObservanceResultsDocument, options);
      }
export type IntegrationTestObservanceResultsSubscriptionHookResult = ReturnType<typeof useIntegrationTestObservanceResultsSubscription>;
export type IntegrationTestObservanceResultsSubscriptionResult = Apollo.SubscriptionResult<IntegrationTestObservanceResultsSubscription>;
export const AllModesDocument = gql`
    query AllModes($limit: Int, $offset: Int, $where: recording_data_modes_bool_exp, $order_by: [recording_data_modes_order_by!]) {
  modes_aggregate: recording_data_modes_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  modes: recording_data_modes(
    where: $where
    order_by: $order_by
    offset: $offset
    limit: $limit
  ) {
    ...ModeFields
    mode_parameters {
      value
      parameter {
        name
      }
    }
  }
}
    ${ModeFieldsFragmentDoc}`;

/**
 * __useAllModesQuery__
 *
 * To run a query within a React component, call `useAllModesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllModesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllModesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useAllModesQuery(baseOptions?: Apollo.QueryHookOptions<AllModesQuery, AllModesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllModesQuery, AllModesQueryVariables>(AllModesDocument, options);
      }
export function useAllModesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllModesQuery, AllModesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllModesQuery, AllModesQueryVariables>(AllModesDocument, options);
        }
export type AllModesQueryHookResult = ReturnType<typeof useAllModesQuery>;
export type AllModesLazyQueryHookResult = ReturnType<typeof useAllModesLazyQuery>;
export type AllModesQueryResult = Apollo.QueryResult<AllModesQuery, AllModesQueryVariables>;
export const ModeDocument = gql`
    query Mode($id: uuid!) {
  mode: recording_data_modes_by_pk(id: $id) {
    ...ModeFields
  }
}
    ${ModeFieldsFragmentDoc}`;

/**
 * __useModeQuery__
 *
 * To run a query within a React component, call `useModeQuery` and pass it any options that fit your needs.
 * When your component renders, `useModeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useModeQuery(baseOptions: Apollo.QueryHookOptions<ModeQuery, ModeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModeQuery, ModeQueryVariables>(ModeDocument, options);
      }
export function useModeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModeQuery, ModeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModeQuery, ModeQueryVariables>(ModeDocument, options);
        }
export type ModeQueryHookResult = ReturnType<typeof useModeQuery>;
export type ModeLazyQueryHookResult = ReturnType<typeof useModeLazyQuery>;
export type ModeQueryResult = Apollo.QueryResult<ModeQuery, ModeQueryVariables>;
export const CreateModeDocument = gql`
    mutation CreateMode($input: recording_data_modes_insert_input!) {
  mode: insert_recording_data_modes_one(object: $input) {
    ...ModeFields
  }
}
    ${ModeFieldsFragmentDoc}`;
export type CreateModeMutationFn = Apollo.MutationFunction<CreateModeMutation, CreateModeMutationVariables>;

/**
 * __useCreateModeMutation__
 *
 * To run a mutation, you first call `useCreateModeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModeMutation, { data, loading, error }] = useCreateModeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateModeMutation(baseOptions?: Apollo.MutationHookOptions<CreateModeMutation, CreateModeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateModeMutation, CreateModeMutationVariables>(CreateModeDocument, options);
      }
export type CreateModeMutationHookResult = ReturnType<typeof useCreateModeMutation>;
export type CreateModeMutationResult = Apollo.MutationResult<CreateModeMutation>;
export type CreateModeMutationOptions = Apollo.BaseMutationOptions<CreateModeMutation, CreateModeMutationVariables>;
export const UpdateModeDocument = gql`
    mutation UpdateMode($id: uuid!, $input: recording_data_modes_set_input!) {
  mode: update_recording_data_modes_by_pk(pk_columns: {id: $id}, _set: $input) {
    ...ModeFields
  }
}
    ${ModeFieldsFragmentDoc}`;
export type UpdateModeMutationFn = Apollo.MutationFunction<UpdateModeMutation, UpdateModeMutationVariables>;

/**
 * __useUpdateModeMutation__
 *
 * To run a mutation, you first call `useUpdateModeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModeMutation, { data, loading, error }] = useUpdateModeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateModeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateModeMutation, UpdateModeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateModeMutation, UpdateModeMutationVariables>(UpdateModeDocument, options);
      }
export type UpdateModeMutationHookResult = ReturnType<typeof useUpdateModeMutation>;
export type UpdateModeMutationResult = Apollo.MutationResult<UpdateModeMutation>;
export type UpdateModeMutationOptions = Apollo.BaseMutationOptions<UpdateModeMutation, UpdateModeMutationVariables>;
export const AllModelsDocument = gql`
    query AllModels($limit: Int, $offset: Int, $order_by: [models_order_by!], $where: models_bool_exp) {
  models_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  models(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
    ...ModelFields
  }
}
    ${ModelFieldsFragmentDoc}`;

/**
 * __useAllModelsQuery__
 *
 * To run a query within a React component, call `useAllModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllModelsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllModelsQuery(baseOptions?: Apollo.QueryHookOptions<AllModelsQuery, AllModelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllModelsQuery, AllModelsQueryVariables>(AllModelsDocument, options);
      }
export function useAllModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllModelsQuery, AllModelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllModelsQuery, AllModelsQueryVariables>(AllModelsDocument, options);
        }
export type AllModelsQueryHookResult = ReturnType<typeof useAllModelsQuery>;
export type AllModelsLazyQueryHookResult = ReturnType<typeof useAllModelsLazyQuery>;
export type AllModelsQueryResult = Apollo.QueryResult<AllModelsQuery, AllModelsQueryVariables>;
export const ModelDocument = gql`
    query Model($id: uuid!) {
  model: models_by_pk(id: $id) {
    ...ModelFields
  }
}
    ${ModelFieldsFragmentDoc}`;

/**
 * __useModelQuery__
 *
 * To run a query within a React component, call `useModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModelQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useModelQuery(baseOptions: Apollo.QueryHookOptions<ModelQuery, ModelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModelQuery, ModelQueryVariables>(ModelDocument, options);
      }
export function useModelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModelQuery, ModelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModelQuery, ModelQueryVariables>(ModelDocument, options);
        }
export type ModelQueryHookResult = ReturnType<typeof useModelQuery>;
export type ModelLazyQueryHookResult = ReturnType<typeof useModelLazyQuery>;
export type ModelQueryResult = Apollo.QueryResult<ModelQuery, ModelQueryVariables>;
export const CreateModelDocument = gql`
    mutation CreateModel($input: models_insert_input!) {
  model: insert_models_one(object: $input) {
    ...ModelFields
  }
}
    ${ModelFieldsFragmentDoc}`;
export type CreateModelMutationFn = Apollo.MutationFunction<CreateModelMutation, CreateModelMutationVariables>;

/**
 * __useCreateModelMutation__
 *
 * To run a mutation, you first call `useCreateModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModelMutation, { data, loading, error }] = useCreateModelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateModelMutation(baseOptions?: Apollo.MutationHookOptions<CreateModelMutation, CreateModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateModelMutation, CreateModelMutationVariables>(CreateModelDocument, options);
      }
export type CreateModelMutationHookResult = ReturnType<typeof useCreateModelMutation>;
export type CreateModelMutationResult = Apollo.MutationResult<CreateModelMutation>;
export type CreateModelMutationOptions = Apollo.BaseMutationOptions<CreateModelMutation, CreateModelMutationVariables>;
export const UpdateModelDocument = gql`
    mutation UpdateModel($id: uuid!, $input: models_set_input!) {
  model: update_models_by_pk(pk_columns: {id: $id}, _set: $input) {
    ...ModelFields
  }
}
    ${ModelFieldsFragmentDoc}`;
export type UpdateModelMutationFn = Apollo.MutationFunction<UpdateModelMutation, UpdateModelMutationVariables>;

/**
 * __useUpdateModelMutation__
 *
 * To run a mutation, you first call `useUpdateModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModelMutation, { data, loading, error }] = useUpdateModelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateModelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateModelMutation, UpdateModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateModelMutation, UpdateModelMutationVariables>(UpdateModelDocument, options);
      }
export type UpdateModelMutationHookResult = ReturnType<typeof useUpdateModelMutation>;
export type UpdateModelMutationResult = Apollo.MutationResult<UpdateModelMutation>;
export type UpdateModelMutationOptions = Apollo.BaseMutationOptions<UpdateModelMutation, UpdateModelMutationVariables>;
export const AvailableOpmodesDocument = gql`
    query AvailableOpmodes {
  opmodes {
    id
    number
  }
}
    `;

/**
 * __useAvailableOpmodesQuery__
 *
 * To run a query within a React component, call `useAvailableOpmodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableOpmodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableOpmodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableOpmodesQuery(baseOptions?: Apollo.QueryHookOptions<AvailableOpmodesQuery, AvailableOpmodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableOpmodesQuery, AvailableOpmodesQueryVariables>(AvailableOpmodesDocument, options);
      }
export function useAvailableOpmodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableOpmodesQuery, AvailableOpmodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableOpmodesQuery, AvailableOpmodesQueryVariables>(AvailableOpmodesDocument, options);
        }
export type AvailableOpmodesQueryHookResult = ReturnType<typeof useAvailableOpmodesQuery>;
export type AvailableOpmodesLazyQueryHookResult = ReturnType<typeof useAvailableOpmodesLazyQuery>;
export type AvailableOpmodesQueryResult = Apollo.QueryResult<AvailableOpmodesQuery, AvailableOpmodesQueryVariables>;
export const AllOpmodesDocument = gql`
    query AllOpmodes($where: opmodes_bool_exp, $order_by: [opmodes_order_by!], $limit: Int, $offset: Int) {
  opmodes_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  opmodes(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
    ...OpmodeFields
  }
}
    ${OpmodeFieldsFragmentDoc}`;

/**
 * __useAllOpmodesQuery__
 *
 * To run a query within a React component, call `useAllOpmodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllOpmodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllOpmodesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllOpmodesQuery(baseOptions?: Apollo.QueryHookOptions<AllOpmodesQuery, AllOpmodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllOpmodesQuery, AllOpmodesQueryVariables>(AllOpmodesDocument, options);
      }
export function useAllOpmodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllOpmodesQuery, AllOpmodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllOpmodesQuery, AllOpmodesQueryVariables>(AllOpmodesDocument, options);
        }
export type AllOpmodesQueryHookResult = ReturnType<typeof useAllOpmodesQuery>;
export type AllOpmodesLazyQueryHookResult = ReturnType<typeof useAllOpmodesLazyQuery>;
export type AllOpmodesQueryResult = Apollo.QueryResult<AllOpmodesQuery, AllOpmodesQueryVariables>;
export const OpmodeDocument = gql`
    query Opmode($id: uuid!) {
  opmode: opmodes_by_pk(id: $id) {
    ...OpmodeFields
  }
}
    ${OpmodeFieldsFragmentDoc}`;

/**
 * __useOpmodeQuery__
 *
 * To run a query within a React component, call `useOpmodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpmodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpmodeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOpmodeQuery(baseOptions: Apollo.QueryHookOptions<OpmodeQuery, OpmodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OpmodeQuery, OpmodeQueryVariables>(OpmodeDocument, options);
      }
export function useOpmodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OpmodeQuery, OpmodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OpmodeQuery, OpmodeQueryVariables>(OpmodeDocument, options);
        }
export type OpmodeQueryHookResult = ReturnType<typeof useOpmodeQuery>;
export type OpmodeLazyQueryHookResult = ReturnType<typeof useOpmodeLazyQuery>;
export type OpmodeQueryResult = Apollo.QueryResult<OpmodeQuery, OpmodeQueryVariables>;
export const CreateOpmodeDocument = gql`
    mutation CreateOpmode($input: opmodes_insert_input!) {
  opmode: insert_opmodes_one(object: $input) {
    ...OpmodeFields
  }
}
    ${OpmodeFieldsFragmentDoc}`;
export type CreateOpmodeMutationFn = Apollo.MutationFunction<CreateOpmodeMutation, CreateOpmodeMutationVariables>;

/**
 * __useCreateOpmodeMutation__
 *
 * To run a mutation, you first call `useCreateOpmodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOpmodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOpmodeMutation, { data, loading, error }] = useCreateOpmodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOpmodeMutation(baseOptions?: Apollo.MutationHookOptions<CreateOpmodeMutation, CreateOpmodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOpmodeMutation, CreateOpmodeMutationVariables>(CreateOpmodeDocument, options);
      }
export type CreateOpmodeMutationHookResult = ReturnType<typeof useCreateOpmodeMutation>;
export type CreateOpmodeMutationResult = Apollo.MutationResult<CreateOpmodeMutation>;
export type CreateOpmodeMutationOptions = Apollo.BaseMutationOptions<CreateOpmodeMutation, CreateOpmodeMutationVariables>;
export const UpdateOpmodeDocument = gql`
    mutation UpdateOpmode($id: uuid!, $input: opmodes_set_input!) {
  opmode: update_opmodes_by_pk(pk_columns: {id: $id}, _set: $input) {
    ...OpmodeFields
  }
}
    ${OpmodeFieldsFragmentDoc}`;
export type UpdateOpmodeMutationFn = Apollo.MutationFunction<UpdateOpmodeMutation, UpdateOpmodeMutationVariables>;

/**
 * __useUpdateOpmodeMutation__
 *
 * To run a mutation, you first call `useUpdateOpmodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOpmodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOpmodeMutation, { data, loading, error }] = useUpdateOpmodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOpmodeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOpmodeMutation, UpdateOpmodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOpmodeMutation, UpdateOpmodeMutationVariables>(UpdateOpmodeDocument, options);
      }
export type UpdateOpmodeMutationHookResult = ReturnType<typeof useUpdateOpmodeMutation>;
export type UpdateOpmodeMutationResult = Apollo.MutationResult<UpdateOpmodeMutation>;
export type UpdateOpmodeMutationOptions = Apollo.BaseMutationOptions<UpdateOpmodeMutation, UpdateOpmodeMutationVariables>;
export const AllRatioMetricsDocument = gql`
    query AllRatioMetrics($limit: Int, $offset: Int, $where: performance_ratio_metrics_bool_exp, $order_by: [performance_ratio_metrics_order_by!]) {
  ratio_metrics_aggregate: performance_ratio_metrics_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  ratio_metrics: performance_ratio_metrics(
    where: $where
    order_by: $order_by
    offset: $offset
    limit: $limit
  ) {
    ...RatioMetricFields
  }
}
    ${RatioMetricFieldsFragmentDoc}`;

/**
 * __useAllRatioMetricsQuery__
 *
 * To run a query within a React component, call `useAllRatioMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllRatioMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllRatioMetricsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useAllRatioMetricsQuery(baseOptions?: Apollo.QueryHookOptions<AllRatioMetricsQuery, AllRatioMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllRatioMetricsQuery, AllRatioMetricsQueryVariables>(AllRatioMetricsDocument, options);
      }
export function useAllRatioMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllRatioMetricsQuery, AllRatioMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllRatioMetricsQuery, AllRatioMetricsQueryVariables>(AllRatioMetricsDocument, options);
        }
export type AllRatioMetricsQueryHookResult = ReturnType<typeof useAllRatioMetricsQuery>;
export type AllRatioMetricsLazyQueryHookResult = ReturnType<typeof useAllRatioMetricsLazyQuery>;
export type AllRatioMetricsQueryResult = Apollo.QueryResult<AllRatioMetricsQuery, AllRatioMetricsQueryVariables>;
export const ProjectEngineRatioMetricsDocument = gql`
    query ProjectEngineRatioMetrics($project_id: uuid!, $metric_id: uuid!, $engine_ids: _uuid!, $task_ids: _uuid!) {
  project_engine_ratio_metrics(
    args: {project_id: $project_id, metric_id: $metric_id, engine_ids: $engine_ids, task_ids: $task_ids}
  ) {
    ratio_metric {
      ...RatioMetricFields
    }
    score
    engine_id
    num_samples
    num_recordings
  }
}
    ${RatioMetricFieldsFragmentDoc}`;

/**
 * __useProjectEngineRatioMetricsQuery__
 *
 * To run a query within a React component, call `useProjectEngineRatioMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectEngineRatioMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectEngineRatioMetricsQuery({
 *   variables: {
 *      project_id: // value for 'project_id'
 *      metric_id: // value for 'metric_id'
 *      engine_ids: // value for 'engine_ids'
 *      task_ids: // value for 'task_ids'
 *   },
 * });
 */
export function useProjectEngineRatioMetricsQuery(baseOptions: Apollo.QueryHookOptions<ProjectEngineRatioMetricsQuery, ProjectEngineRatioMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectEngineRatioMetricsQuery, ProjectEngineRatioMetricsQueryVariables>(ProjectEngineRatioMetricsDocument, options);
      }
export function useProjectEngineRatioMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectEngineRatioMetricsQuery, ProjectEngineRatioMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectEngineRatioMetricsQuery, ProjectEngineRatioMetricsQueryVariables>(ProjectEngineRatioMetricsDocument, options);
        }
export type ProjectEngineRatioMetricsQueryHookResult = ReturnType<typeof useProjectEngineRatioMetricsQuery>;
export type ProjectEngineRatioMetricsLazyQueryHookResult = ReturnType<typeof useProjectEngineRatioMetricsLazyQuery>;
export type ProjectEngineRatioMetricsQueryResult = Apollo.QueryResult<ProjectEngineRatioMetricsQuery, ProjectEngineRatioMetricsQueryVariables>;
export const ProjectPerformanceDataDocument = gql`
    query ProjectPerformanceData($project_id: uuid!, $engine_ids: _uuid!) {
  project_engine_metrics(args: {project_id: $project_id, engine_ids: $engine_ids}) {
    project_id
    metric_id
    engine_id
    score
    num_samples
    num_recordings
    numerator
    denominator
    task_id
    task {
      legacy_template_id
    }
    ratio_metric {
      name
      display_as_percentage
      numerator_unit
      denominator_unit
      larger_is_better
      warning_limit
      critical_limit
    }
  }
}
    `;

/**
 * __useProjectPerformanceDataQuery__
 *
 * To run a query within a React component, call `useProjectPerformanceDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectPerformanceDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectPerformanceDataQuery({
 *   variables: {
 *      project_id: // value for 'project_id'
 *      engine_ids: // value for 'engine_ids'
 *   },
 * });
 */
export function useProjectPerformanceDataQuery(baseOptions: Apollo.QueryHookOptions<ProjectPerformanceDataQuery, ProjectPerformanceDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectPerformanceDataQuery, ProjectPerformanceDataQueryVariables>(ProjectPerformanceDataDocument, options);
      }
export function useProjectPerformanceDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectPerformanceDataQuery, ProjectPerformanceDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectPerformanceDataQuery, ProjectPerformanceDataQueryVariables>(ProjectPerformanceDataDocument, options);
        }
export type ProjectPerformanceDataQueryHookResult = ReturnType<typeof useProjectPerformanceDataQuery>;
export type ProjectPerformanceDataLazyQueryHookResult = ReturnType<typeof useProjectPerformanceDataLazyQuery>;
export type ProjectPerformanceDataQueryResult = Apollo.QueryResult<ProjectPerformanceDataQuery, ProjectPerformanceDataQueryVariables>;
export const Ag_UpsertProjectDocument = gql`
    mutation AG_UpsertProject($input: projects_insert_input!, $on_conflict: projects_on_conflict) {
  project: insert_projects_one(object: $input, on_conflict: $on_conflict) {
    ...ProjectFields
  }
}
    ${ProjectFieldsFragmentDoc}`;
export type Ag_UpsertProjectMutationFn = Apollo.MutationFunction<Ag_UpsertProjectMutation, Ag_UpsertProjectMutationVariables>;

/**
 * __useAg_UpsertProjectMutation__
 *
 * To run a mutation, you first call `useAg_UpsertProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAg_UpsertProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [agUpsertProjectMutation, { data, loading, error }] = useAg_UpsertProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *      on_conflict: // value for 'on_conflict'
 *   },
 * });
 */
export function useAg_UpsertProjectMutation(baseOptions?: Apollo.MutationHookOptions<Ag_UpsertProjectMutation, Ag_UpsertProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Ag_UpsertProjectMutation, Ag_UpsertProjectMutationVariables>(Ag_UpsertProjectDocument, options);
      }
export type Ag_UpsertProjectMutationHookResult = ReturnType<typeof useAg_UpsertProjectMutation>;
export type Ag_UpsertProjectMutationResult = Apollo.MutationResult<Ag_UpsertProjectMutation>;
export type Ag_UpsertProjectMutationOptions = Apollo.BaseMutationOptions<Ag_UpsertProjectMutation, Ag_UpsertProjectMutationVariables>;
export const Ag_CreateProjectDocument = gql`
    mutation AG_CreateProject($input: projects_insert_input!) {
  project: insert_projects_one(object: $input) {
    ...ProjectFields
  }
}
    ${ProjectFieldsFragmentDoc}`;
export type Ag_CreateProjectMutationFn = Apollo.MutationFunction<Ag_CreateProjectMutation, Ag_CreateProjectMutationVariables>;

/**
 * __useAg_CreateProjectMutation__
 *
 * To run a mutation, you first call `useAg_CreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAg_CreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [agCreateProjectMutation, { data, loading, error }] = useAg_CreateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAg_CreateProjectMutation(baseOptions?: Apollo.MutationHookOptions<Ag_CreateProjectMutation, Ag_CreateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Ag_CreateProjectMutation, Ag_CreateProjectMutationVariables>(Ag_CreateProjectDocument, options);
      }
export type Ag_CreateProjectMutationHookResult = ReturnType<typeof useAg_CreateProjectMutation>;
export type Ag_CreateProjectMutationResult = Apollo.MutationResult<Ag_CreateProjectMutation>;
export type Ag_CreateProjectMutationOptions = Apollo.BaseMutationOptions<Ag_CreateProjectMutation, Ag_CreateProjectMutationVariables>;
export const Ag_UpdateProjectDocument = gql`
    mutation AG_UpdateProject($id: uuid!, $input: projects_set_input!, $add_opmodes: [opmode_project_insert_input!]!, $remove_opmode_project_ids: [uuid!], $add_combo_projects: [project_combo_insert_input!]!, $remove_combo_project_ids: [uuid!]!, $add_modes: [mode_project_insert_input!]!, $remove_mode_ids: [uuid!]!) {
  project: update_projects_by_pk(pk_columns: {id: $id}, _set: $input) {
    ...ProjectFields
  }
  add_opmodes: insert_opmode_project(objects: $add_opmodes) {
    returning {
      ...OpmodeProjectFields
    }
  }
  remove_opmodes: delete_opmode_project(
    where: {opmode_id: {_in: $remove_opmode_project_ids}, project_id: {_eq: $id}}
  ) {
    returning {
      ...OpmodeProjectFields
    }
  }
  add_combo_projects: insert_project_combo(objects: $add_combo_projects) {
    affected_rows
  }
  remove_combo_projects: delete_project_combo(
    where: {project_id: {_eq: $id}, other_project_id: {_in: $remove_combo_project_ids}}
  ) {
    affected_rows
  }
  add_modes: insert_mode_project(objects: $add_modes) {
    affected_rows
  }
  remove_modes: delete_mode_project(
    where: {project_id: {_eq: $id}, mode_id: {_in: $remove_mode_ids}}
  ) {
    affected_rows
  }
}
    ${ProjectFieldsFragmentDoc}
${OpmodeProjectFieldsFragmentDoc}`;
export type Ag_UpdateProjectMutationFn = Apollo.MutationFunction<Ag_UpdateProjectMutation, Ag_UpdateProjectMutationVariables>;

/**
 * __useAg_UpdateProjectMutation__
 *
 * To run a mutation, you first call `useAg_UpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAg_UpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [agUpdateProjectMutation, { data, loading, error }] = useAg_UpdateProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      add_opmodes: // value for 'add_opmodes'
 *      remove_opmode_project_ids: // value for 'remove_opmode_project_ids'
 *      add_combo_projects: // value for 'add_combo_projects'
 *      remove_combo_project_ids: // value for 'remove_combo_project_ids'
 *      add_modes: // value for 'add_modes'
 *      remove_mode_ids: // value for 'remove_mode_ids'
 *   },
 * });
 */
export function useAg_UpdateProjectMutation(baseOptions?: Apollo.MutationHookOptions<Ag_UpdateProjectMutation, Ag_UpdateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Ag_UpdateProjectMutation, Ag_UpdateProjectMutationVariables>(Ag_UpdateProjectDocument, options);
      }
export type Ag_UpdateProjectMutationHookResult = ReturnType<typeof useAg_UpdateProjectMutation>;
export type Ag_UpdateProjectMutationResult = Apollo.MutationResult<Ag_UpdateProjectMutation>;
export type Ag_UpdateProjectMutationOptions = Apollo.BaseMutationOptions<Ag_UpdateProjectMutation, Ag_UpdateProjectMutationVariables>;
export const UpdateSlackChannelDocument = gql`
    mutation UpdateSlackChannel($project_id: uuid!, $slack_channel: String!) {
  update_projects_by_pk(
    pk_columns: {id: $project_id}
    _set: {slack_channel: $slack_channel}
  ) {
    id
    slack_channel
  }
}
    `;
export type UpdateSlackChannelMutationFn = Apollo.MutationFunction<UpdateSlackChannelMutation, UpdateSlackChannelMutationVariables>;

/**
 * __useUpdateSlackChannelMutation__
 *
 * To run a mutation, you first call `useUpdateSlackChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSlackChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSlackChannelMutation, { data, loading, error }] = useUpdateSlackChannelMutation({
 *   variables: {
 *      project_id: // value for 'project_id'
 *      slack_channel: // value for 'slack_channel'
 *   },
 * });
 */
export function useUpdateSlackChannelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSlackChannelMutation, UpdateSlackChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSlackChannelMutation, UpdateSlackChannelMutationVariables>(UpdateSlackChannelDocument, options);
      }
export type UpdateSlackChannelMutationHookResult = ReturnType<typeof useUpdateSlackChannelMutation>;
export type UpdateSlackChannelMutationResult = Apollo.MutationResult<UpdateSlackChannelMutation>;
export type UpdateSlackChannelMutationOptions = Apollo.BaseMutationOptions<UpdateSlackChannelMutation, UpdateSlackChannelMutationVariables>;
export const UpdateProjectTasksDocument = gql`
    mutation UpdateProjectTasks($upsert_project_tasks: [project_tasks_insert_input!]!, $remove_project_task_ids: [uuid!]!) {
  insert_project_tasks(
    objects: $upsert_project_tasks
    on_conflict: {constraint: project_tasks_project_id_task_id_key, update_columns: [min_per_device, priority]}
  ) {
    returning {
      id
    }
  }
  delete_project_tasks(where: {id: {_in: $remove_project_task_ids}}) {
    returning {
      id
    }
  }
}
    `;
export type UpdateProjectTasksMutationFn = Apollo.MutationFunction<UpdateProjectTasksMutation, UpdateProjectTasksMutationVariables>;

/**
 * __useUpdateProjectTasksMutation__
 *
 * To run a mutation, you first call `useUpdateProjectTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectTasksMutation, { data, loading, error }] = useUpdateProjectTasksMutation({
 *   variables: {
 *      upsert_project_tasks: // value for 'upsert_project_tasks'
 *      remove_project_task_ids: // value for 'remove_project_task_ids'
 *   },
 * });
 */
export function useUpdateProjectTasksMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectTasksMutation, UpdateProjectTasksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectTasksMutation, UpdateProjectTasksMutationVariables>(UpdateProjectTasksDocument, options);
      }
export type UpdateProjectTasksMutationHookResult = ReturnType<typeof useUpdateProjectTasksMutation>;
export type UpdateProjectTasksMutationResult = Apollo.MutationResult<UpdateProjectTasksMutation>;
export type UpdateProjectTasksMutationOptions = Apollo.BaseMutationOptions<UpdateProjectTasksMutation, UpdateProjectTasksMutationVariables>;
export const PlanProjectTasksDocument = gql`
    query PlanProjectTasks($limit: Int, $offset: Int, $order_by: [tasks_order_by!], $tasks_where: tasks_bool_exp, $plan_tasks_where: plan_tasks_bool_exp, $project_tasks_where: project_tasks_bool_exp) {
  tasks(limit: $limit, offset: $offset, where: $tasks_where, order_by: $order_by) {
    id
    note
    force_ground_truth_to_negative
    legacy_template_id
    description
    plan_tasks(where: $plan_tasks_where) {
      id
      min_per_device
      priority
    }
    project_tasks(where: $project_tasks_where) {
      id
      min_per_device
      priority
    }
  }
  tasks_aggregate(where: $tasks_where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __usePlanProjectTasksQuery__
 *
 * To run a query within a React component, call `usePlanProjectTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanProjectTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanProjectTasksQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      tasks_where: // value for 'tasks_where'
 *      plan_tasks_where: // value for 'plan_tasks_where'
 *      project_tasks_where: // value for 'project_tasks_where'
 *   },
 * });
 */
export function usePlanProjectTasksQuery(baseOptions?: Apollo.QueryHookOptions<PlanProjectTasksQuery, PlanProjectTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanProjectTasksQuery, PlanProjectTasksQueryVariables>(PlanProjectTasksDocument, options);
      }
export function usePlanProjectTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanProjectTasksQuery, PlanProjectTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanProjectTasksQuery, PlanProjectTasksQueryVariables>(PlanProjectTasksDocument, options);
        }
export type PlanProjectTasksQueryHookResult = ReturnType<typeof usePlanProjectTasksQuery>;
export type PlanProjectTasksLazyQueryHookResult = ReturnType<typeof usePlanProjectTasksLazyQuery>;
export type PlanProjectTasksQueryResult = Apollo.QueryResult<PlanProjectTasksQuery, PlanProjectTasksQueryVariables>;
export const UpdateProjectHypertasksDocument = gql`
    mutation UpdateProjectHypertasks($upsert_project_hypertasks: [project_hypertasks_insert_input!]!, $project_id: uuid!, $remove_hypertask_ids: [uuid!]!) {
  insert_project_hypertasks(
    objects: $upsert_project_hypertasks
    on_conflict: {constraint: project_hypertasks_pkey, update_columns: [min_per_device, priority]}
  ) {
    returning {
      project_id
      hypertask_id
    }
  }
  delete_project_hypertasks(
    where: {project_id: {_eq: $project_id}, hypertask_id: {_in: $remove_hypertask_ids}}
  ) {
    returning {
      project_id
      hypertask_id
    }
  }
}
    `;
export type UpdateProjectHypertasksMutationFn = Apollo.MutationFunction<UpdateProjectHypertasksMutation, UpdateProjectHypertasksMutationVariables>;

/**
 * __useUpdateProjectHypertasksMutation__
 *
 * To run a mutation, you first call `useUpdateProjectHypertasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectHypertasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectHypertasksMutation, { data, loading, error }] = useUpdateProjectHypertasksMutation({
 *   variables: {
 *      upsert_project_hypertasks: // value for 'upsert_project_hypertasks'
 *      project_id: // value for 'project_id'
 *      remove_hypertask_ids: // value for 'remove_hypertask_ids'
 *   },
 * });
 */
export function useUpdateProjectHypertasksMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectHypertasksMutation, UpdateProjectHypertasksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectHypertasksMutation, UpdateProjectHypertasksMutationVariables>(UpdateProjectHypertasksDocument, options);
      }
export type UpdateProjectHypertasksMutationHookResult = ReturnType<typeof useUpdateProjectHypertasksMutation>;
export type UpdateProjectHypertasksMutationResult = Apollo.MutationResult<UpdateProjectHypertasksMutation>;
export type UpdateProjectHypertasksMutationOptions = Apollo.BaseMutationOptions<UpdateProjectHypertasksMutation, UpdateProjectHypertasksMutationVariables>;
export const PlanProjectHypertasksDocument = gql`
    query PlanProjectHypertasks($limit: Int, $offset: Int, $order_by: [hypertasks_order_by!], $hypertasks_where: hypertasks_bool_exp, $plan_hypertasks_where: plan_hypertasks_bool_exp, $project_hypertasks_where: project_hypertasks_bool_exp) {
  hypertasks(
    limit: $limit
    offset: $offset
    where: $hypertasks_where
    order_by: $order_by
  ) {
    id
    number
    description
    plan_hypertasks(where: $plan_hypertasks_where) {
      plan_id
      hypertask_id
      min_per_device
      priority
    }
    project_hypertasks(where: $project_hypertasks_where) {
      project_id
      hypertask_id
      min_per_device
      priority
    }
  }
  hypertasks_aggregate(where: $hypertasks_where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __usePlanProjectHypertasksQuery__
 *
 * To run a query within a React component, call `usePlanProjectHypertasksQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanProjectHypertasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanProjectHypertasksQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      hypertasks_where: // value for 'hypertasks_where'
 *      plan_hypertasks_where: // value for 'plan_hypertasks_where'
 *      project_hypertasks_where: // value for 'project_hypertasks_where'
 *   },
 * });
 */
export function usePlanProjectHypertasksQuery(baseOptions?: Apollo.QueryHookOptions<PlanProjectHypertasksQuery, PlanProjectHypertasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanProjectHypertasksQuery, PlanProjectHypertasksQueryVariables>(PlanProjectHypertasksDocument, options);
      }
export function usePlanProjectHypertasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanProjectHypertasksQuery, PlanProjectHypertasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanProjectHypertasksQuery, PlanProjectHypertasksQueryVariables>(PlanProjectHypertasksDocument, options);
        }
export type PlanProjectHypertasksQueryHookResult = ReturnType<typeof usePlanProjectHypertasksQuery>;
export type PlanProjectHypertasksLazyQueryHookResult = ReturnType<typeof usePlanProjectHypertasksLazyQuery>;
export type PlanProjectHypertasksQueryResult = Apollo.QueryResult<PlanProjectHypertasksQuery, PlanProjectHypertasksQueryVariables>;
export const SetProjectGreatnessEnabledDocument = gql`
    mutation SetProjectGreatnessEnabled($project_id: uuid!, $enabled: Boolean!) {
  update_projects_by_pk(
    pk_columns: {id: $project_id}
    _set: {enable_greatness_processing: $enabled}
  ) {
    id
  }
}
    `;
export type SetProjectGreatnessEnabledMutationFn = Apollo.MutationFunction<SetProjectGreatnessEnabledMutation, SetProjectGreatnessEnabledMutationVariables>;

/**
 * __useSetProjectGreatnessEnabledMutation__
 *
 * To run a mutation, you first call `useSetProjectGreatnessEnabledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetProjectGreatnessEnabledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setProjectGreatnessEnabledMutation, { data, loading, error }] = useSetProjectGreatnessEnabledMutation({
 *   variables: {
 *      project_id: // value for 'project_id'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useSetProjectGreatnessEnabledMutation(baseOptions?: Apollo.MutationHookOptions<SetProjectGreatnessEnabledMutation, SetProjectGreatnessEnabledMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetProjectGreatnessEnabledMutation, SetProjectGreatnessEnabledMutationVariables>(SetProjectGreatnessEnabledDocument, options);
      }
export type SetProjectGreatnessEnabledMutationHookResult = ReturnType<typeof useSetProjectGreatnessEnabledMutation>;
export type SetProjectGreatnessEnabledMutationResult = Apollo.MutationResult<SetProjectGreatnessEnabledMutation>;
export type SetProjectGreatnessEnabledMutationOptions = Apollo.BaseMutationOptions<SetProjectGreatnessEnabledMutation, SetProjectGreatnessEnabledMutationVariables>;
export const SetProjectTagGroundTruthEnabledDocument = gql`
    mutation SetProjectTagGroundTruthEnabled($project_id: uuid!, $enabled: Boolean!) {
  update_projects_by_pk(
    pk_columns: {id: $project_id}
    _set: {enable_ground_truth_processing: $enabled}
  ) {
    id
  }
}
    `;
export type SetProjectTagGroundTruthEnabledMutationFn = Apollo.MutationFunction<SetProjectTagGroundTruthEnabledMutation, SetProjectTagGroundTruthEnabledMutationVariables>;

/**
 * __useSetProjectTagGroundTruthEnabledMutation__
 *
 * To run a mutation, you first call `useSetProjectTagGroundTruthEnabledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetProjectTagGroundTruthEnabledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setProjectTagGroundTruthEnabledMutation, { data, loading, error }] = useSetProjectTagGroundTruthEnabledMutation({
 *   variables: {
 *      project_id: // value for 'project_id'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useSetProjectTagGroundTruthEnabledMutation(baseOptions?: Apollo.MutationHookOptions<SetProjectTagGroundTruthEnabledMutation, SetProjectTagGroundTruthEnabledMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetProjectTagGroundTruthEnabledMutation, SetProjectTagGroundTruthEnabledMutationVariables>(SetProjectTagGroundTruthEnabledDocument, options);
      }
export type SetProjectTagGroundTruthEnabledMutationHookResult = ReturnType<typeof useSetProjectTagGroundTruthEnabledMutation>;
export type SetProjectTagGroundTruthEnabledMutationResult = Apollo.MutationResult<SetProjectTagGroundTruthEnabledMutation>;
export type SetProjectTagGroundTruthEnabledMutationOptions = Apollo.BaseMutationOptions<SetProjectTagGroundTruthEnabledMutation, SetProjectTagGroundTruthEnabledMutationVariables>;
export const SetProjectArcadiaEnabledDocument = gql`
    mutation SetProjectArcadiaEnabled($project_id: uuid!, $enabled: Boolean!) {
  update_projects_by_pk(
    pk_columns: {id: $project_id}
    _set: {enable_arcadia_processing: $enabled}
  ) {
    id
  }
}
    `;
export type SetProjectArcadiaEnabledMutationFn = Apollo.MutationFunction<SetProjectArcadiaEnabledMutation, SetProjectArcadiaEnabledMutationVariables>;

/**
 * __useSetProjectArcadiaEnabledMutation__
 *
 * To run a mutation, you first call `useSetProjectArcadiaEnabledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetProjectArcadiaEnabledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setProjectArcadiaEnabledMutation, { data, loading, error }] = useSetProjectArcadiaEnabledMutation({
 *   variables: {
 *      project_id: // value for 'project_id'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useSetProjectArcadiaEnabledMutation(baseOptions?: Apollo.MutationHookOptions<SetProjectArcadiaEnabledMutation, SetProjectArcadiaEnabledMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetProjectArcadiaEnabledMutation, SetProjectArcadiaEnabledMutationVariables>(SetProjectArcadiaEnabledDocument, options);
      }
export type SetProjectArcadiaEnabledMutationHookResult = ReturnType<typeof useSetProjectArcadiaEnabledMutation>;
export type SetProjectArcadiaEnabledMutationResult = Apollo.MutationResult<SetProjectArcadiaEnabledMutation>;
export type SetProjectArcadiaEnabledMutationOptions = Apollo.BaseMutationOptions<SetProjectArcadiaEnabledMutation, SetProjectArcadiaEnabledMutationVariables>;
export const ArchiveProjectDocument = gql`
    mutation ArchiveProject($id: uuid!, $deleted_at: timestamptz) {
  update_projects_by_pk(pk_columns: {id: $id}, _set: {deleted_at: $deleted_at}) {
    id
  }
}
    `;
export type ArchiveProjectMutationFn = Apollo.MutationFunction<ArchiveProjectMutation, ArchiveProjectMutationVariables>;

/**
 * __useArchiveProjectMutation__
 *
 * To run a mutation, you first call `useArchiveProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveProjectMutation, { data, loading, error }] = useArchiveProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deleted_at: // value for 'deleted_at'
 *   },
 * });
 */
export function useArchiveProjectMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveProjectMutation, ArchiveProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveProjectMutation, ArchiveProjectMutationVariables>(ArchiveProjectDocument, options);
      }
export type ArchiveProjectMutationHookResult = ReturnType<typeof useArchiveProjectMutation>;
export type ArchiveProjectMutationResult = Apollo.MutationResult<ArchiveProjectMutation>;
export type ArchiveProjectMutationOptions = Apollo.BaseMutationOptions<ArchiveProjectMutation, ArchiveProjectMutationVariables>;
export const UpsertDynamicScalingDocument = gql`
    mutation UpsertDynamicScaling($min_value: Int!, $max_value: Int!, $benchmark_scaling: Int!) {
  insert_dynamic_scaling(
    objects: {min_value: $min_value, max_value: $max_value, benchmark_scaling: $benchmark_scaling}
    on_conflict: {constraint: dynamic_scaling_min_value_max_value_benchmark_scaling_key, update_columns: [min_value, max_value, benchmark_scaling]}
  ) {
    returning {
      id
    }
  }
}
    `;
export type UpsertDynamicScalingMutationFn = Apollo.MutationFunction<UpsertDynamicScalingMutation, UpsertDynamicScalingMutationVariables>;

/**
 * __useUpsertDynamicScalingMutation__
 *
 * To run a mutation, you first call `useUpsertDynamicScalingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertDynamicScalingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertDynamicScalingMutation, { data, loading, error }] = useUpsertDynamicScalingMutation({
 *   variables: {
 *      min_value: // value for 'min_value'
 *      max_value: // value for 'max_value'
 *      benchmark_scaling: // value for 'benchmark_scaling'
 *   },
 * });
 */
export function useUpsertDynamicScalingMutation(baseOptions?: Apollo.MutationHookOptions<UpsertDynamicScalingMutation, UpsertDynamicScalingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertDynamicScalingMutation, UpsertDynamicScalingMutationVariables>(UpsertDynamicScalingDocument, options);
      }
export type UpsertDynamicScalingMutationHookResult = ReturnType<typeof useUpsertDynamicScalingMutation>;
export type UpsertDynamicScalingMutationResult = Apollo.MutationResult<UpsertDynamicScalingMutation>;
export type UpsertDynamicScalingMutationOptions = Apollo.BaseMutationOptions<UpsertDynamicScalingMutation, UpsertDynamicScalingMutationVariables>;
export const AllProjectsDocument = gql`
    query AllProjects($limit: Int, $offset: Int, $order_by: [projects_order_by!], $where: projects_bool_exp) {
  projects_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  projects(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
    ...ProjectFields
    project_name {
      name
    }
    project_sequences_aggregate {
      aggregate {
        count
      }
    }
  }
}
    ${ProjectFieldsFragmentDoc}`;

/**
 * __useAllProjectsQuery__
 *
 * To run a query within a React component, call `useAllProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProjectsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllProjectsQuery(baseOptions?: Apollo.QueryHookOptions<AllProjectsQuery, AllProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllProjectsQuery, AllProjectsQueryVariables>(AllProjectsDocument, options);
      }
export function useAllProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllProjectsQuery, AllProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllProjectsQuery, AllProjectsQueryVariables>(AllProjectsDocument, options);
        }
export type AllProjectsQueryHookResult = ReturnType<typeof useAllProjectsQuery>;
export type AllProjectsLazyQueryHookResult = ReturnType<typeof useAllProjectsLazyQuery>;
export type AllProjectsQueryResult = Apollo.QueryResult<AllProjectsQuery, AllProjectsQueryVariables>;
export const AllSearchableProjectsDocument = gql`
    query AllSearchableProjects {
  projects(order_by: [{deleted_at: desc_nulls_first}, {created_at: desc}]) {
    id
    created_at
    deleted_at
    project_users(where: {manager: {_eq: true}}) {
      project_id
      user_id
      user {
        id
        name
        email
      }
    }
    project_name {
      name
      display_name
    }
    scores(limit: 1, order_by: {timestamp: desc}) {
      id
      greatness
    }
    variant
    simulation_opmode {
      id
      number
    }
    calibration_opmode {
      id
      number
    }
    opmode_projects {
      opmode {
        id
        number
      }
    }
    deliverable {
      id
      name
    }
  }
}
    `;

/**
 * __useAllSearchableProjectsQuery__
 *
 * To run a query within a React component, call `useAllSearchableProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSearchableProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSearchableProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllSearchableProjectsQuery(baseOptions?: Apollo.QueryHookOptions<AllSearchableProjectsQuery, AllSearchableProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllSearchableProjectsQuery, AllSearchableProjectsQueryVariables>(AllSearchableProjectsDocument, options);
      }
export function useAllSearchableProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllSearchableProjectsQuery, AllSearchableProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllSearchableProjectsQuery, AllSearchableProjectsQueryVariables>(AllSearchableProjectsDocument, options);
        }
export type AllSearchableProjectsQueryHookResult = ReturnType<typeof useAllSearchableProjectsQuery>;
export type AllSearchableProjectsLazyQueryHookResult = ReturnType<typeof useAllSearchableProjectsLazyQuery>;
export type AllSearchableProjectsQueryResult = Apollo.QueryResult<AllSearchableProjectsQuery, AllSearchableProjectsQueryVariables>;
export const ProjectCompareDocument = gql`
    subscription ProjectCompare($where: projects_bool_exp) {
  projects(where: $where) {
    id
    scores(limit: 1, order_by: {timestamp: desc}) {
      id
      greatness
    }
    project_name {
      display_name
    }
  }
}
    `;

/**
 * __useProjectCompareSubscription__
 *
 * To run a query within a React component, call `useProjectCompareSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProjectCompareSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCompareSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useProjectCompareSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ProjectCompareSubscription, ProjectCompareSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ProjectCompareSubscription, ProjectCompareSubscriptionVariables>(ProjectCompareDocument, options);
      }
export type ProjectCompareSubscriptionHookResult = ReturnType<typeof useProjectCompareSubscription>;
export type ProjectCompareSubscriptionResult = Apollo.SubscriptionResult<ProjectCompareSubscription>;
export const ProjectDocument = gql`
    query Project($id: uuid!) {
  project: projects_by_pk(id: $id) {
    ...SingleProjectFields
    project_name {
      name
    }
    project_combos {
      other_project {
        id
        project_name {
          name
        }
      }
    }
  }
}
    ${SingleProjectFieldsFragmentDoc}`;

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectQuery(baseOptions: Apollo.QueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
      }
export function useProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
        }
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>;
export type ProjectQueryResult = Apollo.QueryResult<ProjectQuery, ProjectQueryVariables>;
export const ProjectCollectionTasksDocument = gql`
    query ProjectCollectionTasks($project_id: uuid!) {
  tasks(
    where: {_or: [{project_tasks: {project_id: {_eq: $project_id}}}, {hypertask_tasks: {hypertask: {project_hypertasks: {project_id: {_eq: $project_id}}}}}]}
    order_by: {legacy_template_id: asc}
  ) {
    id
    legacy_template_id
    hypertask_tasks {
      hypertask {
        id
        number
      }
    }
  }
}
    `;

/**
 * __useProjectCollectionTasksQuery__
 *
 * To run a query within a React component, call `useProjectCollectionTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectCollectionTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCollectionTasksQuery({
 *   variables: {
 *      project_id: // value for 'project_id'
 *   },
 * });
 */
export function useProjectCollectionTasksQuery(baseOptions: Apollo.QueryHookOptions<ProjectCollectionTasksQuery, ProjectCollectionTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectCollectionTasksQuery, ProjectCollectionTasksQueryVariables>(ProjectCollectionTasksDocument, options);
      }
export function useProjectCollectionTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectCollectionTasksQuery, ProjectCollectionTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectCollectionTasksQuery, ProjectCollectionTasksQueryVariables>(ProjectCollectionTasksDocument, options);
        }
export type ProjectCollectionTasksQueryHookResult = ReturnType<typeof useProjectCollectionTasksQuery>;
export type ProjectCollectionTasksLazyQueryHookResult = ReturnType<typeof useProjectCollectionTasksLazyQuery>;
export type ProjectCollectionTasksQueryResult = Apollo.QueryResult<ProjectCollectionTasksQuery, ProjectCollectionTasksQueryVariables>;
export const ProjectBenchmarkTasksDocument = gql`
    query ProjectBenchmarkTasks($project_id: uuid!) {
  tasks(
    where: {rabbit_test_tasks: {plan: {plan_projects: {project_id: {_eq: $project_id}}}}}
    distinct_on: [legacy_template_id]
    order_by: {legacy_template_id: asc}
  ) {
    id
    legacy_template_id
  }
}
    `;

/**
 * __useProjectBenchmarkTasksQuery__
 *
 * To run a query within a React component, call `useProjectBenchmarkTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectBenchmarkTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectBenchmarkTasksQuery({
 *   variables: {
 *      project_id: // value for 'project_id'
 *   },
 * });
 */
export function useProjectBenchmarkTasksQuery(baseOptions: Apollo.QueryHookOptions<ProjectBenchmarkTasksQuery, ProjectBenchmarkTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectBenchmarkTasksQuery, ProjectBenchmarkTasksQueryVariables>(ProjectBenchmarkTasksDocument, options);
      }
export function useProjectBenchmarkTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectBenchmarkTasksQuery, ProjectBenchmarkTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectBenchmarkTasksQuery, ProjectBenchmarkTasksQueryVariables>(ProjectBenchmarkTasksDocument, options);
        }
export type ProjectBenchmarkTasksQueryHookResult = ReturnType<typeof useProjectBenchmarkTasksQuery>;
export type ProjectBenchmarkTasksLazyQueryHookResult = ReturnType<typeof useProjectBenchmarkTasksLazyQuery>;
export type ProjectBenchmarkTasksQueryResult = Apollo.QueryResult<ProjectBenchmarkTasksQuery, ProjectBenchmarkTasksQueryVariables>;
export const ProjectTaskFieldOptionsDocument = gql`
    query ProjectTaskFieldOptions($project_id: uuid!) {
  tasks(
    where: {_or: [{project_tasks: {project_id: {_eq: $project_id}}}, {rabbit_test_tasks: {plan: {plan_projects: {project_id: {_eq: $project_id}}}}}, {hypertask_tasks: {hypertask: {project_hypertasks: {project_id: {_eq: $project_id}}}}}]}
  ) {
    id
    hypertask_tasks {
      hypertask {
        id
        number
      }
    }
    legacy_template_id
    task_field_options {
      task_id
      field_option_id
      field_option {
        field {
          id
          name
        }
        option {
          id
          display
        }
      }
    }
  }
}
    `;

/**
 * __useProjectTaskFieldOptionsQuery__
 *
 * To run a query within a React component, call `useProjectTaskFieldOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTaskFieldOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTaskFieldOptionsQuery({
 *   variables: {
 *      project_id: // value for 'project_id'
 *   },
 * });
 */
export function useProjectTaskFieldOptionsQuery(baseOptions: Apollo.QueryHookOptions<ProjectTaskFieldOptionsQuery, ProjectTaskFieldOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectTaskFieldOptionsQuery, ProjectTaskFieldOptionsQueryVariables>(ProjectTaskFieldOptionsDocument, options);
      }
export function useProjectTaskFieldOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectTaskFieldOptionsQuery, ProjectTaskFieldOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectTaskFieldOptionsQuery, ProjectTaskFieldOptionsQueryVariables>(ProjectTaskFieldOptionsDocument, options);
        }
export type ProjectTaskFieldOptionsQueryHookResult = ReturnType<typeof useProjectTaskFieldOptionsQuery>;
export type ProjectTaskFieldOptionsLazyQueryHookResult = ReturnType<typeof useProjectTaskFieldOptionsLazyQuery>;
export type ProjectTaskFieldOptionsQueryResult = Apollo.QueryResult<ProjectTaskFieldOptionsQuery, ProjectTaskFieldOptionsQueryVariables>;
export const ProjectDatasetAggregateDocument = gql`
    query ProjectDatasetAggregate($ids: [uuid!] = []) {
  projects(where: {id: {_in: $ids}}) {
    id
    project_dataset_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useProjectDatasetAggregateQuery__
 *
 * To run a query within a React component, call `useProjectDatasetAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectDatasetAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectDatasetAggregateQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useProjectDatasetAggregateQuery(baseOptions?: Apollo.QueryHookOptions<ProjectDatasetAggregateQuery, ProjectDatasetAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectDatasetAggregateQuery, ProjectDatasetAggregateQueryVariables>(ProjectDatasetAggregateDocument, options);
      }
export function useProjectDatasetAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectDatasetAggregateQuery, ProjectDatasetAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectDatasetAggregateQuery, ProjectDatasetAggregateQueryVariables>(ProjectDatasetAggregateDocument, options);
        }
export type ProjectDatasetAggregateQueryHookResult = ReturnType<typeof useProjectDatasetAggregateQuery>;
export type ProjectDatasetAggregateLazyQueryHookResult = ReturnType<typeof useProjectDatasetAggregateLazyQuery>;
export type ProjectDatasetAggregateQueryResult = Apollo.QueryResult<ProjectDatasetAggregateQuery, ProjectDatasetAggregateQueryVariables>;
export const ProjectCollectionStatusDocument = gql`
    query ProjectCollectionStatus($project_id: uuid!, $tasks_where: tasks_bool_exp = {}, $order_by: [schema_project_task_count_order_by!], $translation_language: String) {
  project: projects_by_pk(id: $project_id) {
    id
    min_devices_per_task
  }
  status: project_task_count(
    args: {input_project_id: $project_id}
    where: {task: $tasks_where}
    order_by: $order_by
  ) {
    clean_count
    dirty_count
    required_total
    min_per_device
    priority
    progress
    task {
      id
      legacy_template_id
      note
      description
      task_media {
        task_id
        media_id
        media {
          id
          title
          description
          url
          type
        }
      }
      task_instruction {
        content
        instruction_translations(where: {language: {_eq: $translation_language}}) {
          language
          content
        }
      }
    }
  }
}
    `;

/**
 * __useProjectCollectionStatusQuery__
 *
 * To run a query within a React component, call `useProjectCollectionStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectCollectionStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCollectionStatusQuery({
 *   variables: {
 *      project_id: // value for 'project_id'
 *      tasks_where: // value for 'tasks_where'
 *      order_by: // value for 'order_by'
 *      translation_language: // value for 'translation_language'
 *   },
 * });
 */
export function useProjectCollectionStatusQuery(baseOptions: Apollo.QueryHookOptions<ProjectCollectionStatusQuery, ProjectCollectionStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectCollectionStatusQuery, ProjectCollectionStatusQueryVariables>(ProjectCollectionStatusDocument, options);
      }
export function useProjectCollectionStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectCollectionStatusQuery, ProjectCollectionStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectCollectionStatusQuery, ProjectCollectionStatusQueryVariables>(ProjectCollectionStatusDocument, options);
        }
export type ProjectCollectionStatusQueryHookResult = ReturnType<typeof useProjectCollectionStatusQuery>;
export type ProjectCollectionStatusLazyQueryHookResult = ReturnType<typeof useProjectCollectionStatusLazyQuery>;
export type ProjectCollectionStatusQueryResult = Apollo.QueryResult<ProjectCollectionStatusQuery, ProjectCollectionStatusQueryVariables>;
export const ListLanguagesDocument = gql`
    query ListLanguages {
  languages {
    id
    description
  }
}
    `;

/**
 * __useListLanguagesQuery__
 *
 * To run a query within a React component, call `useListLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListLanguagesQuery(baseOptions?: Apollo.QueryHookOptions<ListLanguagesQuery, ListLanguagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListLanguagesQuery, ListLanguagesQueryVariables>(ListLanguagesDocument, options);
      }
export function useListLanguagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListLanguagesQuery, ListLanguagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListLanguagesQuery, ListLanguagesQueryVariables>(ListLanguagesDocument, options);
        }
export type ListLanguagesQueryHookResult = ReturnType<typeof useListLanguagesQuery>;
export type ListLanguagesLazyQueryHookResult = ReturnType<typeof useListLanguagesLazyQuery>;
export type ListLanguagesQueryResult = Apollo.QueryResult<ListLanguagesQuery, ListLanguagesQueryVariables>;
export const ProjectOverviewDocument = gql`
    query ProjectOverview($id: uuid!) {
  project: projects_by_pk(id: $id) {
    ...SingleProjectFields
    dirty_dataset_aggregate: project_dataset_aggregate(where: {dirty: {_eq: true}}) {
      aggregate {
        count
      }
    }
    clean_dataset_aggregate: project_dataset_aggregate(where: {dirty: {_eq: false}}) {
      aggregate {
        count
      }
    }
    untagged_dataset_aggregate: project_dataset_aggregate(
      where: {dirty: {_is_null: true}}
    ) {
      aggregate {
        count
      }
    }
    rabbit_test_instances_aggregate {
      aggregate {
        count
      }
    }
  }
}
    ${SingleProjectFieldsFragmentDoc}`;

/**
 * __useProjectOverviewQuery__
 *
 * To run a query within a React component, call `useProjectOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectOverviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectOverviewQuery(baseOptions: Apollo.QueryHookOptions<ProjectOverviewQuery, ProjectOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectOverviewQuery, ProjectOverviewQueryVariables>(ProjectOverviewDocument, options);
      }
export function useProjectOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectOverviewQuery, ProjectOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectOverviewQuery, ProjectOverviewQueryVariables>(ProjectOverviewDocument, options);
        }
export type ProjectOverviewQueryHookResult = ReturnType<typeof useProjectOverviewQuery>;
export type ProjectOverviewLazyQueryHookResult = ReturnType<typeof useProjectOverviewLazyQuery>;
export type ProjectOverviewQueryResult = Apollo.QueryResult<ProjectOverviewQuery, ProjectOverviewQueryVariables>;
export const ProjectDataCollectionProgressDocument = gql`
    query ProjectDataCollectionProgress($project_id: uuid!) {
  project_task_count_aggregate(args: {input_project_id: $project_id}) {
    aggregate {
      sum {
        clean_count
        clean_count_constrained
        untagged_count
        required_total
      }
    }
  }
}
    `;

/**
 * __useProjectDataCollectionProgressQuery__
 *
 * To run a query within a React component, call `useProjectDataCollectionProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectDataCollectionProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectDataCollectionProgressQuery({
 *   variables: {
 *      project_id: // value for 'project_id'
 *   },
 * });
 */
export function useProjectDataCollectionProgressQuery(baseOptions: Apollo.QueryHookOptions<ProjectDataCollectionProgressQuery, ProjectDataCollectionProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectDataCollectionProgressQuery, ProjectDataCollectionProgressQueryVariables>(ProjectDataCollectionProgressDocument, options);
      }
export function useProjectDataCollectionProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectDataCollectionProgressQuery, ProjectDataCollectionProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectDataCollectionProgressQuery, ProjectDataCollectionProgressQueryVariables>(ProjectDataCollectionProgressDocument, options);
        }
export type ProjectDataCollectionProgressQueryHookResult = ReturnType<typeof useProjectDataCollectionProgressQuery>;
export type ProjectDataCollectionProgressLazyQueryHookResult = ReturnType<typeof useProjectDataCollectionProgressLazyQuery>;
export type ProjectDataCollectionProgressQueryResult = Apollo.QueryResult<ProjectDataCollectionProgressQuery, ProjectDataCollectionProgressQueryVariables>;
export const IsProjectManagerDocument = gql`
    subscription IsProjectManager($project_id: uuid!, $user_id: uuid!) {
  user: project_user_by_pk(project_id: $project_id, user_id: $user_id) {
    manager
  }
}
    `;

/**
 * __useIsProjectManagerSubscription__
 *
 * To run a query within a React component, call `useIsProjectManagerSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIsProjectManagerSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsProjectManagerSubscription({
 *   variables: {
 *      project_id: // value for 'project_id'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useIsProjectManagerSubscription(baseOptions: Apollo.SubscriptionHookOptions<IsProjectManagerSubscription, IsProjectManagerSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<IsProjectManagerSubscription, IsProjectManagerSubscriptionVariables>(IsProjectManagerDocument, options);
      }
export type IsProjectManagerSubscriptionHookResult = ReturnType<typeof useIsProjectManagerSubscription>;
export type IsProjectManagerSubscriptionResult = Apollo.SubscriptionResult<IsProjectManagerSubscription>;
export const UserRecordingsByDayDocument = gql`
    query UserRecordingsByDay($project_id: uuid!, $order_by: [user_recordings_by_day_stats_order_by!]) {
  project: projects_by_pk(id: $project_id) {
    user_recordings_by_day_stats(order_by: $order_by) {
      clean_recordings
      dirty_recordings
      untagged_recordings
      clean_duration
      dirty_duration
      untagged_duration
      day
      project {
        id
      }
      user {
        id
        name
        email
      }
    }
  }
}
    `;

/**
 * __useUserRecordingsByDayQuery__
 *
 * To run a query within a React component, call `useUserRecordingsByDayQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRecordingsByDayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRecordingsByDayQuery({
 *   variables: {
 *      project_id: // value for 'project_id'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useUserRecordingsByDayQuery(baseOptions: Apollo.QueryHookOptions<UserRecordingsByDayQuery, UserRecordingsByDayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserRecordingsByDayQuery, UserRecordingsByDayQueryVariables>(UserRecordingsByDayDocument, options);
      }
export function useUserRecordingsByDayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserRecordingsByDayQuery, UserRecordingsByDayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserRecordingsByDayQuery, UserRecordingsByDayQueryVariables>(UserRecordingsByDayDocument, options);
        }
export type UserRecordingsByDayQueryHookResult = ReturnType<typeof useUserRecordingsByDayQuery>;
export type UserRecordingsByDayLazyQueryHookResult = ReturnType<typeof useUserRecordingsByDayLazyQuery>;
export type UserRecordingsByDayQueryResult = Apollo.QueryResult<UserRecordingsByDayQuery, UserRecordingsByDayQueryVariables>;
export const RecordingCountByOpmodeDocument = gql`
    query RecordingCountByOpmode($opmodes: [Int!]!) {
  recordings_aggregate(
    where: {engine_opmode_recordings: {opmode: {number: {_in: $opmodes}}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useRecordingCountByOpmodeQuery__
 *
 * To run a query within a React component, call `useRecordingCountByOpmodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecordingCountByOpmodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecordingCountByOpmodeQuery({
 *   variables: {
 *      opmodes: // value for 'opmodes'
 *   },
 * });
 */
export function useRecordingCountByOpmodeQuery(baseOptions: Apollo.QueryHookOptions<RecordingCountByOpmodeQuery, RecordingCountByOpmodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecordingCountByOpmodeQuery, RecordingCountByOpmodeQueryVariables>(RecordingCountByOpmodeDocument, options);
      }
export function useRecordingCountByOpmodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecordingCountByOpmodeQuery, RecordingCountByOpmodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecordingCountByOpmodeQuery, RecordingCountByOpmodeQueryVariables>(RecordingCountByOpmodeDocument, options);
        }
export type RecordingCountByOpmodeQueryHookResult = ReturnType<typeof useRecordingCountByOpmodeQuery>;
export type RecordingCountByOpmodeLazyQueryHookResult = ReturnType<typeof useRecordingCountByOpmodeLazyQuery>;
export type RecordingCountByOpmodeQueryResult = Apollo.QueryResult<RecordingCountByOpmodeQuery, RecordingCountByOpmodeQueryVariables>;
export const RecordingTagCountByDayDocument = gql`
    query RecordingTagCountByDay($exclude_tag_names: _text!, $project_ids: _uuid!) {
  recording_tag_count_by_day(
    args: {exclude_tag_names: $exclude_tag_names, project_ids: $project_ids}
    order_by: {day: asc}
  ) {
    count
    day
    project_id
    tag_description
    tag_field
    tag_id
    tag_is_dirty
    tag_is_highlighted
    tag_name
  }
}
    `;

/**
 * __useRecordingTagCountByDayQuery__
 *
 * To run a query within a React component, call `useRecordingTagCountByDayQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecordingTagCountByDayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecordingTagCountByDayQuery({
 *   variables: {
 *      exclude_tag_names: // value for 'exclude_tag_names'
 *      project_ids: // value for 'project_ids'
 *   },
 * });
 */
export function useRecordingTagCountByDayQuery(baseOptions: Apollo.QueryHookOptions<RecordingTagCountByDayQuery, RecordingTagCountByDayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecordingTagCountByDayQuery, RecordingTagCountByDayQueryVariables>(RecordingTagCountByDayDocument, options);
      }
export function useRecordingTagCountByDayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecordingTagCountByDayQuery, RecordingTagCountByDayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecordingTagCountByDayQuery, RecordingTagCountByDayQueryVariables>(RecordingTagCountByDayDocument, options);
        }
export type RecordingTagCountByDayQueryHookResult = ReturnType<typeof useRecordingTagCountByDayQuery>;
export type RecordingTagCountByDayLazyQueryHookResult = ReturnType<typeof useRecordingTagCountByDayLazyQuery>;
export type RecordingTagCountByDayQueryResult = Apollo.QueryResult<RecordingTagCountByDayQuery, RecordingTagCountByDayQueryVariables>;
export const RecordingTagsDocument = gql`
    query RecordingTags {
  recording_tags {
    id
    name
    field
    dirty
    description
  }
}
    `;

/**
 * __useRecordingTagsQuery__
 *
 * To run a query within a React component, call `useRecordingTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecordingTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecordingTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecordingTagsQuery(baseOptions?: Apollo.QueryHookOptions<RecordingTagsQuery, RecordingTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecordingTagsQuery, RecordingTagsQueryVariables>(RecordingTagsDocument, options);
      }
export function useRecordingTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecordingTagsQuery, RecordingTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecordingTagsQuery, RecordingTagsQueryVariables>(RecordingTagsDocument, options);
        }
export type RecordingTagsQueryHookResult = ReturnType<typeof useRecordingTagsQuery>;
export type RecordingTagsLazyQueryHookResult = ReturnType<typeof useRecordingTagsLazyQuery>;
export type RecordingTagsQueryResult = Apollo.QueryResult<RecordingTagsQuery, RecordingTagsQueryVariables>;
export const Agmg_AddUserToProjectDocument = gql`
    mutation AGMG_AddUserToProject($object: project_user_insert_input!) {
  insert_project_user_one(object: $object) {
    project_id
    user_id
    manager
    project {
      id
      project_users {
        user_id
        project_id
        manager
        user {
          id
          email
        }
      }
    }
  }
}
    `;
export type Agmg_AddUserToProjectMutationFn = Apollo.MutationFunction<Agmg_AddUserToProjectMutation, Agmg_AddUserToProjectMutationVariables>;

/**
 * __useAgmg_AddUserToProjectMutation__
 *
 * To run a mutation, you first call `useAgmg_AddUserToProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAgmg_AddUserToProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [agmgAddUserToProjectMutation, { data, loading, error }] = useAgmg_AddUserToProjectMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useAgmg_AddUserToProjectMutation(baseOptions?: Apollo.MutationHookOptions<Agmg_AddUserToProjectMutation, Agmg_AddUserToProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Agmg_AddUserToProjectMutation, Agmg_AddUserToProjectMutationVariables>(Agmg_AddUserToProjectDocument, options);
      }
export type Agmg_AddUserToProjectMutationHookResult = ReturnType<typeof useAgmg_AddUserToProjectMutation>;
export type Agmg_AddUserToProjectMutationResult = Apollo.MutationResult<Agmg_AddUserToProjectMutation>;
export type Agmg_AddUserToProjectMutationOptions = Apollo.BaseMutationOptions<Agmg_AddUserToProjectMutation, Agmg_AddUserToProjectMutationVariables>;
export const SlackChannelsDocument = gql`
    query SlackChannels {
  slack_channels: elapi_slack_channels {
    id
    name
  }
}
    `;

/**
 * __useSlackChannelsQuery__
 *
 * To run a query within a React component, call `useSlackChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSlackChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSlackChannelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSlackChannelsQuery(baseOptions?: Apollo.QueryHookOptions<SlackChannelsQuery, SlackChannelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SlackChannelsQuery, SlackChannelsQueryVariables>(SlackChannelsDocument, options);
      }
export function useSlackChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SlackChannelsQuery, SlackChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SlackChannelsQuery, SlackChannelsQueryVariables>(SlackChannelsDocument, options);
        }
export type SlackChannelsQueryHookResult = ReturnType<typeof useSlackChannelsQuery>;
export type SlackChannelsLazyQueryHookResult = ReturnType<typeof useSlackChannelsLazyQuery>;
export type SlackChannelsQueryResult = Apollo.QueryResult<SlackChannelsQuery, SlackChannelsQueryVariables>;
export const Agmg_RemoveUserFromProjectDocument = gql`
    mutation AGMG_RemoveUserFromProject($project_id: uuid!, $user_id: uuid!) {
  delete_project_user_by_pk(project_id: $project_id, user_id: $user_id) {
    project_id
    user_id
    manager
    project {
      id
      project_users {
        user_id
        project_id
        manager
        user {
          id
          email
        }
      }
    }
  }
}
    `;
export type Agmg_RemoveUserFromProjectMutationFn = Apollo.MutationFunction<Agmg_RemoveUserFromProjectMutation, Agmg_RemoveUserFromProjectMutationVariables>;

/**
 * __useAgmg_RemoveUserFromProjectMutation__
 *
 * To run a mutation, you first call `useAgmg_RemoveUserFromProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAgmg_RemoveUserFromProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [agmgRemoveUserFromProjectMutation, { data, loading, error }] = useAgmg_RemoveUserFromProjectMutation({
 *   variables: {
 *      project_id: // value for 'project_id'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useAgmg_RemoveUserFromProjectMutation(baseOptions?: Apollo.MutationHookOptions<Agmg_RemoveUserFromProjectMutation, Agmg_RemoveUserFromProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Agmg_RemoveUserFromProjectMutation, Agmg_RemoveUserFromProjectMutationVariables>(Agmg_RemoveUserFromProjectDocument, options);
      }
export type Agmg_RemoveUserFromProjectMutationHookResult = ReturnType<typeof useAgmg_RemoveUserFromProjectMutation>;
export type Agmg_RemoveUserFromProjectMutationResult = Apollo.MutationResult<Agmg_RemoveUserFromProjectMutation>;
export type Agmg_RemoveUserFromProjectMutationOptions = Apollo.BaseMutationOptions<Agmg_RemoveUserFromProjectMutation, Agmg_RemoveUserFromProjectMutationVariables>;
export const CompareProjectSequencesDocument = gql`
    query CompareProjectSequences($sequences_where: integration_test_sequences_bool_exp!, $source_project_sequence_where: integration_test_project_sequence_bool_exp!, $dest_project_sequence_where: integration_test_project_sequence_bool_exp!) {
  sequences: integration_test_sequences(where: $sequences_where) {
    id
    description
    source_project_sequences: project_sequences(
      where: $source_project_sequence_where
    ) {
      created_at
    }
    dest_project_sequences: project_sequences(where: $dest_project_sequence_where) {
      created_at
    }
  }
}
    `;

/**
 * __useCompareProjectSequencesQuery__
 *
 * To run a query within a React component, call `useCompareProjectSequencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompareProjectSequencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompareProjectSequencesQuery({
 *   variables: {
 *      sequences_where: // value for 'sequences_where'
 *      source_project_sequence_where: // value for 'source_project_sequence_where'
 *      dest_project_sequence_where: // value for 'dest_project_sequence_where'
 *   },
 * });
 */
export function useCompareProjectSequencesQuery(baseOptions: Apollo.QueryHookOptions<CompareProjectSequencesQuery, CompareProjectSequencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompareProjectSequencesQuery, CompareProjectSequencesQueryVariables>(CompareProjectSequencesDocument, options);
      }
export function useCompareProjectSequencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompareProjectSequencesQuery, CompareProjectSequencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompareProjectSequencesQuery, CompareProjectSequencesQueryVariables>(CompareProjectSequencesDocument, options);
        }
export type CompareProjectSequencesQueryHookResult = ReturnType<typeof useCompareProjectSequencesQuery>;
export type CompareProjectSequencesLazyQueryHookResult = ReturnType<typeof useCompareProjectSequencesLazyQuery>;
export type CompareProjectSequencesQueryResult = Apollo.QueryResult<CompareProjectSequencesQuery, CompareProjectSequencesQueryVariables>;
export const UpdateProjectSequencesDocument = gql`
    mutation UpdateProjectSequences($upsert_project_sequences: [integration_test_project_sequence_insert_input!]!, $remove_project_sequences: [integration_test_project_sequence_bool_exp!]) {
  insert: insert_integration_test_project_sequence(
    objects: $upsert_project_sequences
    on_conflict: {constraint: project_sequence_pkey, update_columns: [project_id, sequence_id]}
  ) {
    returning {
      project_id
      sequence_id
      created_at
      updated_at
    }
  }
  delete: delete_integration_test_project_sequence(
    where: {_or: $remove_project_sequences}
  ) {
    returning {
      project_id
      sequence_id
    }
  }
}
    `;
export type UpdateProjectSequencesMutationFn = Apollo.MutationFunction<UpdateProjectSequencesMutation, UpdateProjectSequencesMutationVariables>;

/**
 * __useUpdateProjectSequencesMutation__
 *
 * To run a mutation, you first call `useUpdateProjectSequencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectSequencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectSequencesMutation, { data, loading, error }] = useUpdateProjectSequencesMutation({
 *   variables: {
 *      upsert_project_sequences: // value for 'upsert_project_sequences'
 *      remove_project_sequences: // value for 'remove_project_sequences'
 *   },
 * });
 */
export function useUpdateProjectSequencesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectSequencesMutation, UpdateProjectSequencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectSequencesMutation, UpdateProjectSequencesMutationVariables>(UpdateProjectSequencesDocument, options);
      }
export type UpdateProjectSequencesMutationHookResult = ReturnType<typeof useUpdateProjectSequencesMutation>;
export type UpdateProjectSequencesMutationResult = Apollo.MutationResult<UpdateProjectSequencesMutation>;
export type UpdateProjectSequencesMutationOptions = Apollo.BaseMutationOptions<UpdateProjectSequencesMutation, UpdateProjectSequencesMutationVariables>;
export const ProjectTaskCountByDeviceDocument = gql`
    query ProjectTaskCountByDevice($project_id: uuid!, $task_id: uuid!, $order_by: [schema_project_task_count_order_by!]) {
  project_task_count(
    args: {input_project_id: $project_id, input_task_id: $task_id}
    order_by: $order_by
  ) {
    device_id
    clean_count
    dirty_count
    required_total
    min_per_device
    priority
    progress
    device {
      id
      serialno
    }
  }
}
    `;

/**
 * __useProjectTaskCountByDeviceQuery__
 *
 * To run a query within a React component, call `useProjectTaskCountByDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTaskCountByDeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTaskCountByDeviceQuery({
 *   variables: {
 *      project_id: // value for 'project_id'
 *      task_id: // value for 'task_id'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useProjectTaskCountByDeviceQuery(baseOptions: Apollo.QueryHookOptions<ProjectTaskCountByDeviceQuery, ProjectTaskCountByDeviceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectTaskCountByDeviceQuery, ProjectTaskCountByDeviceQueryVariables>(ProjectTaskCountByDeviceDocument, options);
      }
export function useProjectTaskCountByDeviceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectTaskCountByDeviceQuery, ProjectTaskCountByDeviceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectTaskCountByDeviceQuery, ProjectTaskCountByDeviceQueryVariables>(ProjectTaskCountByDeviceDocument, options);
        }
export type ProjectTaskCountByDeviceQueryHookResult = ReturnType<typeof useProjectTaskCountByDeviceQuery>;
export type ProjectTaskCountByDeviceLazyQueryHookResult = ReturnType<typeof useProjectTaskCountByDeviceLazyQuery>;
export type ProjectTaskCountByDeviceQueryResult = Apollo.QueryResult<ProjectTaskCountByDeviceQuery, ProjectTaskCountByDeviceQueryVariables>;
export const AllXtensaConfigurationsDocument = gql`
    query AllXtensaConfigurations($where: xtensa_configurations_bool_exp, $order_by: [xtensa_configurations_order_by!], $limit: Int, $offset: Int) {
  xtensa_configurations(
    where: $where
    order_by: $order_by
    limit: $limit
    offset: $offset
  ) {
    ...XtensaConfigurationFields
  }
}
    ${XtensaConfigurationFieldsFragmentDoc}`;

/**
 * __useAllXtensaConfigurationsQuery__
 *
 * To run a query within a React component, call `useAllXtensaConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllXtensaConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllXtensaConfigurationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllXtensaConfigurationsQuery(baseOptions?: Apollo.QueryHookOptions<AllXtensaConfigurationsQuery, AllXtensaConfigurationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllXtensaConfigurationsQuery, AllXtensaConfigurationsQueryVariables>(AllXtensaConfigurationsDocument, options);
      }
export function useAllXtensaConfigurationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllXtensaConfigurationsQuery, AllXtensaConfigurationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllXtensaConfigurationsQuery, AllXtensaConfigurationsQueryVariables>(AllXtensaConfigurationsDocument, options);
        }
export type AllXtensaConfigurationsQueryHookResult = ReturnType<typeof useAllXtensaConfigurationsQuery>;
export type AllXtensaConfigurationsLazyQueryHookResult = ReturnType<typeof useAllXtensaConfigurationsLazyQuery>;
export type AllXtensaConfigurationsQueryResult = Apollo.QueryResult<AllXtensaConfigurationsQuery, AllXtensaConfigurationsQueryVariables>;
export const ProjectBenchmarkPlansDocument = gql`
    query ProjectBenchmarkPlans($projectId: uuid!) {
  rabbit_test_plans(where: {plan_projects: {project_id: {_eq: $projectId}}}) {
    id
    name
    description
  }
}
    `;

/**
 * __useProjectBenchmarkPlansQuery__
 *
 * To run a query within a React component, call `useProjectBenchmarkPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectBenchmarkPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectBenchmarkPlansQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectBenchmarkPlansQuery(baseOptions: Apollo.QueryHookOptions<ProjectBenchmarkPlansQuery, ProjectBenchmarkPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectBenchmarkPlansQuery, ProjectBenchmarkPlansQueryVariables>(ProjectBenchmarkPlansDocument, options);
      }
export function useProjectBenchmarkPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectBenchmarkPlansQuery, ProjectBenchmarkPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectBenchmarkPlansQuery, ProjectBenchmarkPlansQueryVariables>(ProjectBenchmarkPlansDocument, options);
        }
export type ProjectBenchmarkPlansQueryHookResult = ReturnType<typeof useProjectBenchmarkPlansQuery>;
export type ProjectBenchmarkPlansLazyQueryHookResult = ReturnType<typeof useProjectBenchmarkPlansLazyQuery>;
export type ProjectBenchmarkPlansQueryResult = Apollo.QueryResult<ProjectBenchmarkPlansQuery, ProjectBenchmarkPlansQueryVariables>;
export const ProjectBenchmarkInstancesDocument = gql`
    query ProjectBenchmarkInstances($projectId: uuid!) {
  project_rabbit_test_instances(where: {project_id: {_eq: $projectId}}) {
    started_at
    project_id
    user_id
    user {
      id
      name
    }
    device_id
    device {
      id
      serialno
    }
    engine_id
    engine {
      id
      build_number
    }
    opmode_id
    opmode {
      id
      number
    }
    plan_id
    rabbit_test_plan {
      id
      name
      description
      plan_tasks_aggregate {
        aggregate {
          count
        }
      }
      plan_tasks {
        task {
          id
          legacy_template_id
        }
      }
    }
    num_recordings
    num_distinct_tasks
  }
}
    `;

/**
 * __useProjectBenchmarkInstancesQuery__
 *
 * To run a query within a React component, call `useProjectBenchmarkInstancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectBenchmarkInstancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectBenchmarkInstancesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectBenchmarkInstancesQuery(baseOptions: Apollo.QueryHookOptions<ProjectBenchmarkInstancesQuery, ProjectBenchmarkInstancesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectBenchmarkInstancesQuery, ProjectBenchmarkInstancesQueryVariables>(ProjectBenchmarkInstancesDocument, options);
      }
export function useProjectBenchmarkInstancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectBenchmarkInstancesQuery, ProjectBenchmarkInstancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectBenchmarkInstancesQuery, ProjectBenchmarkInstancesQueryVariables>(ProjectBenchmarkInstancesDocument, options);
        }
export type ProjectBenchmarkInstancesQueryHookResult = ReturnType<typeof useProjectBenchmarkInstancesQuery>;
export type ProjectBenchmarkInstancesLazyQueryHookResult = ReturnType<typeof useProjectBenchmarkInstancesLazyQuery>;
export type ProjectBenchmarkInstancesQueryResult = Apollo.QueryResult<ProjectBenchmarkInstancesQuery, ProjectBenchmarkInstancesQueryVariables>;
export const BenchmarkInstanceRecordingsDocument = gql`
    query BenchmarkInstanceRecordings($project_id: uuid!, $device_id: uuid!, $engine_id: uuid!, $opmode_id: uuid!, $plan_id: uuid!, $user_id: uuid!) {
  recordings(
    where: {project_id: {_eq: $project_id}, device_id: {_eq: $device_id}, engine_opmode_recordings: {engine_id: {_eq: $engine_id}, opmode_id: {_eq: $opmode_id}}, rabbit_test: {plan_id: {_eq: $plan_id}}, user_id: {_eq: $user_id}}
  ) {
    id
    task {
      id
      legacy_template_id
    }
    recorded_at
  }
}
    `;

/**
 * __useBenchmarkInstanceRecordingsQuery__
 *
 * To run a query within a React component, call `useBenchmarkInstanceRecordingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenchmarkInstanceRecordingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenchmarkInstanceRecordingsQuery({
 *   variables: {
 *      project_id: // value for 'project_id'
 *      device_id: // value for 'device_id'
 *      engine_id: // value for 'engine_id'
 *      opmode_id: // value for 'opmode_id'
 *      plan_id: // value for 'plan_id'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useBenchmarkInstanceRecordingsQuery(baseOptions: Apollo.QueryHookOptions<BenchmarkInstanceRecordingsQuery, BenchmarkInstanceRecordingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BenchmarkInstanceRecordingsQuery, BenchmarkInstanceRecordingsQueryVariables>(BenchmarkInstanceRecordingsDocument, options);
      }
export function useBenchmarkInstanceRecordingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BenchmarkInstanceRecordingsQuery, BenchmarkInstanceRecordingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BenchmarkInstanceRecordingsQuery, BenchmarkInstanceRecordingsQueryVariables>(BenchmarkInstanceRecordingsDocument, options);
        }
export type BenchmarkInstanceRecordingsQueryHookResult = ReturnType<typeof useBenchmarkInstanceRecordingsQuery>;
export type BenchmarkInstanceRecordingsLazyQueryHookResult = ReturnType<typeof useBenchmarkInstanceRecordingsLazyQuery>;
export type BenchmarkInstanceRecordingsQueryResult = Apollo.QueryResult<BenchmarkInstanceRecordingsQuery, BenchmarkInstanceRecordingsQueryVariables>;
export const ProjectTemplatesDocument = gql`
    query ProjectTemplates($project_id: uuid!) {
  project: projects_by_pk(id: $project_id) {
    deliverable {
      deliverable_templates(
        order_by: [{priority: desc}, {template: {updated_at: desc}}]
      ) {
        template {
          id
          name
          description
        }
      }
    }
  }
}
    `;

/**
 * __useProjectTemplatesQuery__
 *
 * To run a query within a React component, call `useProjectTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTemplatesQuery({
 *   variables: {
 *      project_id: // value for 'project_id'
 *   },
 * });
 */
export function useProjectTemplatesQuery(baseOptions: Apollo.QueryHookOptions<ProjectTemplatesQuery, ProjectTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectTemplatesQuery, ProjectTemplatesQueryVariables>(ProjectTemplatesDocument, options);
      }
export function useProjectTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectTemplatesQuery, ProjectTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectTemplatesQuery, ProjectTemplatesQueryVariables>(ProjectTemplatesDocument, options);
        }
export type ProjectTemplatesQueryHookResult = ReturnType<typeof useProjectTemplatesQuery>;
export type ProjectTemplatesLazyQueryHookResult = ReturnType<typeof useProjectTemplatesLazyQuery>;
export type ProjectTemplatesQueryResult = Apollo.QueryResult<ProjectTemplatesQuery, ProjectTemplatesQueryVariables>;
export const TaskInstructionAndTranslationByPkDocument = gql`
    query TaskInstructionAndTranslationByPK($id: uuid = "") {
  tasks_by_pk(id: $id) {
    task_instruction {
      id
      content
      created_at
      id
      status
      updated_at
      user {
        name
      }
      instruction_translations {
        id
        content
        created_at
        language
        status
        updated_at
        user {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useTaskInstructionAndTranslationByPkQuery__
 *
 * To run a query within a React component, call `useTaskInstructionAndTranslationByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskInstructionAndTranslationByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskInstructionAndTranslationByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskInstructionAndTranslationByPkQuery(baseOptions?: Apollo.QueryHookOptions<TaskInstructionAndTranslationByPkQuery, TaskInstructionAndTranslationByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskInstructionAndTranslationByPkQuery, TaskInstructionAndTranslationByPkQueryVariables>(TaskInstructionAndTranslationByPkDocument, options);
      }
export function useTaskInstructionAndTranslationByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskInstructionAndTranslationByPkQuery, TaskInstructionAndTranslationByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskInstructionAndTranslationByPkQuery, TaskInstructionAndTranslationByPkQueryVariables>(TaskInstructionAndTranslationByPkDocument, options);
        }
export type TaskInstructionAndTranslationByPkQueryHookResult = ReturnType<typeof useTaskInstructionAndTranslationByPkQuery>;
export type TaskInstructionAndTranslationByPkLazyQueryHookResult = ReturnType<typeof useTaskInstructionAndTranslationByPkLazyQuery>;
export type TaskInstructionAndTranslationByPkQueryResult = Apollo.QueryResult<TaskInstructionAndTranslationByPkQuery, TaskInstructionAndTranslationByPkQueryVariables>;
export const ProjectChecklistProgressDocument = gql`
    query ProjectChecklistProgress($project_id: uuid!) {
  all_items_aggregate: bringup_items_aggregate(
    where: {checklist: {checklist_deliverables: {deliverable: {projects: {id: {_eq: $project_id}}}}}}
  ) {
    aggregate {
      count
    }
  }
  endorsed_items_aggregate: bringup_endorsements_aggregate(
    where: {project_id: {_eq: $project_id}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useProjectChecklistProgressQuery__
 *
 * To run a query within a React component, call `useProjectChecklistProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectChecklistProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectChecklistProgressQuery({
 *   variables: {
 *      project_id: // value for 'project_id'
 *   },
 * });
 */
export function useProjectChecklistProgressQuery(baseOptions: Apollo.QueryHookOptions<ProjectChecklistProgressQuery, ProjectChecklistProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectChecklistProgressQuery, ProjectChecklistProgressQueryVariables>(ProjectChecklistProgressDocument, options);
      }
export function useProjectChecklistProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectChecklistProgressQuery, ProjectChecklistProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectChecklistProgressQuery, ProjectChecklistProgressQueryVariables>(ProjectChecklistProgressDocument, options);
        }
export type ProjectChecklistProgressQueryHookResult = ReturnType<typeof useProjectChecklistProgressQuery>;
export type ProjectChecklistProgressLazyQueryHookResult = ReturnType<typeof useProjectChecklistProgressLazyQuery>;
export type ProjectChecklistProgressQueryResult = Apollo.QueryResult<ProjectChecklistProgressQuery, ProjectChecklistProgressQueryVariables>;
export const ProjectChecklistsDocument = gql`
    query ProjectChecklists($project_id: uuid!) {
  projects_by_pk(id: $project_id) {
    deliverable {
      checklist_deliverables {
        checklist {
          id
          name
          description
          items(order_by: {position: asc}) {
            ...ChecklistItem
            dependent_items {
              ...ChecklistItem
              checklist {
                name
              }
              endorsements_aggregate(
                where: {project_id: {_eq: $project_id}, deleted_at: {_is_null: true}}
              ) {
                aggregate {
                  count
                }
              }
            }
            depends_on_item {
              ...ChecklistItem
              checklist {
                name
              }
              endorsements_aggregate(
                where: {project_id: {_eq: $project_id}, deleted_at: {_is_null: true}}
              ) {
                aggregate {
                  count
                }
              }
            }
            endorsements(
              where: {project_id: {_eq: $project_id}, deleted_at: {_is_null: true}}
            ) {
              user {
                id
                name
              }
              created_at
            }
            milestone {
              name
              description
            }
          }
          all_items_aggregate: items_aggregate {
            aggregate {
              count
            }
          }
          completed_items_aggregate: items_aggregate(
            where: {endorsements: {project_id: {_eq: $project_id}, deleted_at: {_is_null: true}}}
          ) {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
}
    ${ChecklistItemFragmentDoc}`;

/**
 * __useProjectChecklistsQuery__
 *
 * To run a query within a React component, call `useProjectChecklistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectChecklistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectChecklistsQuery({
 *   variables: {
 *      project_id: // value for 'project_id'
 *   },
 * });
 */
export function useProjectChecklistsQuery(baseOptions: Apollo.QueryHookOptions<ProjectChecklistsQuery, ProjectChecklistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectChecklistsQuery, ProjectChecklistsQueryVariables>(ProjectChecklistsDocument, options);
      }
export function useProjectChecklistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectChecklistsQuery, ProjectChecklistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectChecklistsQuery, ProjectChecklistsQueryVariables>(ProjectChecklistsDocument, options);
        }
export type ProjectChecklistsQueryHookResult = ReturnType<typeof useProjectChecklistsQuery>;
export type ProjectChecklistsLazyQueryHookResult = ReturnType<typeof useProjectChecklistsLazyQuery>;
export type ProjectChecklistsQueryResult = Apollo.QueryResult<ProjectChecklistsQuery, ProjectChecklistsQueryVariables>;
export const UpsertTaskInstructionContentDocument = gql`
    mutation UpsertTaskInstructionContent($task_id: uuid!, $status: String!, $created_by: uuid!, $content: String!) {
  insert_task_instructions_one(
    object: {task_id: $task_id, status: $status, created_by: $created_by, content: $content}
    on_conflict: {constraint: task_instructions_task_id_key, update_columns: [content, status, created_by]}
  ) {
    id
  }
}
    `;
export type UpsertTaskInstructionContentMutationFn = Apollo.MutationFunction<UpsertTaskInstructionContentMutation, UpsertTaskInstructionContentMutationVariables>;

/**
 * __useUpsertTaskInstructionContentMutation__
 *
 * To run a mutation, you first call `useUpsertTaskInstructionContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertTaskInstructionContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertTaskInstructionContentMutation, { data, loading, error }] = useUpsertTaskInstructionContentMutation({
 *   variables: {
 *      task_id: // value for 'task_id'
 *      status: // value for 'status'
 *      created_by: // value for 'created_by'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useUpsertTaskInstructionContentMutation(baseOptions?: Apollo.MutationHookOptions<UpsertTaskInstructionContentMutation, UpsertTaskInstructionContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertTaskInstructionContentMutation, UpsertTaskInstructionContentMutationVariables>(UpsertTaskInstructionContentDocument, options);
      }
export type UpsertTaskInstructionContentMutationHookResult = ReturnType<typeof useUpsertTaskInstructionContentMutation>;
export type UpsertTaskInstructionContentMutationResult = Apollo.MutationResult<UpsertTaskInstructionContentMutation>;
export type UpsertTaskInstructionContentMutationOptions = Apollo.BaseMutationOptions<UpsertTaskInstructionContentMutation, UpsertTaskInstructionContentMutationVariables>;
export const UpsertTaskInstructionStatusDocument = gql`
    mutation UpsertTaskInstructionStatus($task_id: uuid!, $status: String!, $created_by: uuid!) {
  insert_task_instructions_one(
    object: {task_id: $task_id, status: $status, created_by: $created_by}
    on_conflict: {constraint: task_instructions_task_id_key, update_columns: [status, created_by]}
  ) {
    id
  }
}
    `;
export type UpsertTaskInstructionStatusMutationFn = Apollo.MutationFunction<UpsertTaskInstructionStatusMutation, UpsertTaskInstructionStatusMutationVariables>;

/**
 * __useUpsertTaskInstructionStatusMutation__
 *
 * To run a mutation, you first call `useUpsertTaskInstructionStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertTaskInstructionStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertTaskInstructionStatusMutation, { data, loading, error }] = useUpsertTaskInstructionStatusMutation({
 *   variables: {
 *      task_id: // value for 'task_id'
 *      status: // value for 'status'
 *      created_by: // value for 'created_by'
 *   },
 * });
 */
export function useUpsertTaskInstructionStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpsertTaskInstructionStatusMutation, UpsertTaskInstructionStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertTaskInstructionStatusMutation, UpsertTaskInstructionStatusMutationVariables>(UpsertTaskInstructionStatusDocument, options);
      }
export type UpsertTaskInstructionStatusMutationHookResult = ReturnType<typeof useUpsertTaskInstructionStatusMutation>;
export type UpsertTaskInstructionStatusMutationResult = Apollo.MutationResult<UpsertTaskInstructionStatusMutation>;
export type UpsertTaskInstructionStatusMutationOptions = Apollo.BaseMutationOptions<UpsertTaskInstructionStatusMutation, UpsertTaskInstructionStatusMutationVariables>;
export const UpsertInstructionTranslationMutationDocument = gql`
    mutation UpsertInstructionTranslationMutation($content: String!, $created_by: uuid!, $language: String!, $status: String!, $task_instruction_id: uuid!) {
  insert_instruction_translations_one(
    object: {content: $content, created_by: $created_by, task_instruction_id: $task_instruction_id, language: $language, status: $status}
    on_conflict: {constraint: instruction_translation_task_instruction_id_language_key, update_columns: [content, status, created_by]}
  ) {
    id
  }
}
    `;
export type UpsertInstructionTranslationMutationMutationFn = Apollo.MutationFunction<UpsertInstructionTranslationMutationMutation, UpsertInstructionTranslationMutationMutationVariables>;

/**
 * __useUpsertInstructionTranslationMutationMutation__
 *
 * To run a mutation, you first call `useUpsertInstructionTranslationMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertInstructionTranslationMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertInstructionTranslationMutationMutation, { data, loading, error }] = useUpsertInstructionTranslationMutationMutation({
 *   variables: {
 *      content: // value for 'content'
 *      created_by: // value for 'created_by'
 *      language: // value for 'language'
 *      status: // value for 'status'
 *      task_instruction_id: // value for 'task_instruction_id'
 *   },
 * });
 */
export function useUpsertInstructionTranslationMutationMutation(baseOptions?: Apollo.MutationHookOptions<UpsertInstructionTranslationMutationMutation, UpsertInstructionTranslationMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertInstructionTranslationMutationMutation, UpsertInstructionTranslationMutationMutationVariables>(UpsertInstructionTranslationMutationDocument, options);
      }
export type UpsertInstructionTranslationMutationMutationHookResult = ReturnType<typeof useUpsertInstructionTranslationMutationMutation>;
export type UpsertInstructionTranslationMutationMutationResult = Apollo.MutationResult<UpsertInstructionTranslationMutationMutation>;
export type UpsertInstructionTranslationMutationMutationOptions = Apollo.BaseMutationOptions<UpsertInstructionTranslationMutationMutation, UpsertInstructionTranslationMutationMutationVariables>;
export const ProjectChecklistItemCommentsDocument = gql`
    subscription ProjectChecklistItemComments($project_id: uuid!, $item_id: uuid!) {
  comments: bringup_project_item_comments(
    where: {project_id: {_eq: $project_id}, item_id: {_eq: $item_id}, deleted_at: {_is_null: true}}
    order_by: {created_at: asc}
  ) {
    item_id
    project_id
    created_at
    updated_at
    deleted_at
    created_by
    content
    user {
      name
    }
  }
}
    `;

/**
 * __useProjectChecklistItemCommentsSubscription__
 *
 * To run a query within a React component, call `useProjectChecklistItemCommentsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProjectChecklistItemCommentsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectChecklistItemCommentsSubscription({
 *   variables: {
 *      project_id: // value for 'project_id'
 *      item_id: // value for 'item_id'
 *   },
 * });
 */
export function useProjectChecklistItemCommentsSubscription(baseOptions: Apollo.SubscriptionHookOptions<ProjectChecklistItemCommentsSubscription, ProjectChecklistItemCommentsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ProjectChecklistItemCommentsSubscription, ProjectChecklistItemCommentsSubscriptionVariables>(ProjectChecklistItemCommentsDocument, options);
      }
export type ProjectChecklistItemCommentsSubscriptionHookResult = ReturnType<typeof useProjectChecklistItemCommentsSubscription>;
export type ProjectChecklistItemCommentsSubscriptionResult = Apollo.SubscriptionResult<ProjectChecklistItemCommentsSubscription>;
export const ProjectDatasetDocument = gql`
    query ProjectDataset($project_id: uuid!, $limit: Int!, $offset: Int!, $order_by: [project_dataset_order_by!], $where: project_dataset_bool_exp) {
  project: projects_by_pk(id: $project_id) {
    project_dataset_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    project_dataset(
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      recording {
        ...RecordingFields
      }
    }
  }
}
    ${RecordingFieldsFragmentDoc}`;

/**
 * __useProjectDatasetQuery__
 *
 * To run a query within a React component, call `useProjectDatasetQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectDatasetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectDatasetQuery({
 *   variables: {
 *      project_id: // value for 'project_id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useProjectDatasetQuery(baseOptions: Apollo.QueryHookOptions<ProjectDatasetQuery, ProjectDatasetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectDatasetQuery, ProjectDatasetQueryVariables>(ProjectDatasetDocument, options);
      }
export function useProjectDatasetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectDatasetQuery, ProjectDatasetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectDatasetQuery, ProjectDatasetQueryVariables>(ProjectDatasetDocument, options);
        }
export type ProjectDatasetQueryHookResult = ReturnType<typeof useProjectDatasetQuery>;
export type ProjectDatasetLazyQueryHookResult = ReturnType<typeof useProjectDatasetLazyQuery>;
export type ProjectDatasetQueryResult = Apollo.QueryResult<ProjectDatasetQuery, ProjectDatasetQueryVariables>;
export const AllRecordingTagsDocument = gql`
    query AllRecordingTags($limit: Int, $order_by: [recording_tags_order_by!], $where: recording_tags_bool_exp) {
  recording_tags(
    where: $where
    order_by: $order_by
    limit: $limit
    offset: $limit
  ) {
    ...RecordingTagFields
  }
}
    ${RecordingTagFieldsFragmentDoc}`;

/**
 * __useAllRecordingTagsQuery__
 *
 * To run a query within a React component, call `useAllRecordingTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllRecordingTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllRecordingTagsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllRecordingTagsQuery(baseOptions?: Apollo.QueryHookOptions<AllRecordingTagsQuery, AllRecordingTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllRecordingTagsQuery, AllRecordingTagsQueryVariables>(AllRecordingTagsDocument, options);
      }
export function useAllRecordingTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllRecordingTagsQuery, AllRecordingTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllRecordingTagsQuery, AllRecordingTagsQueryVariables>(AllRecordingTagsDocument, options);
        }
export type AllRecordingTagsQueryHookResult = ReturnType<typeof useAllRecordingTagsQuery>;
export type AllRecordingTagsLazyQueryHookResult = ReturnType<typeof useAllRecordingTagsLazyQuery>;
export type AllRecordingTagsQueryResult = Apollo.QueryResult<AllRecordingTagsQuery, AllRecordingTagsQueryVariables>;
export const AllTemplatesDocument = gql`
    query AllTemplates($limit: Int, $offset: Int, $where: performance_report_templates_bool_exp, $order_by: [performance_report_templates_order_by!]) {
  templates_aggregate: performance_report_templates_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  templates: performance_report_templates(
    where: $where
    order_by: $order_by
    offset: $offset
    limit: $limit
  ) {
    ...TemplateFields
  }
}
    ${TemplateFieldsFragmentDoc}`;

/**
 * __useAllTemplatesQuery__
 *
 * To run a query within a React component, call `useAllTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTemplatesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useAllTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<AllTemplatesQuery, AllTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllTemplatesQuery, AllTemplatesQueryVariables>(AllTemplatesDocument, options);
      }
export function useAllTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTemplatesQuery, AllTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllTemplatesQuery, AllTemplatesQueryVariables>(AllTemplatesDocument, options);
        }
export type AllTemplatesQueryHookResult = ReturnType<typeof useAllTemplatesQuery>;
export type AllTemplatesLazyQueryHookResult = ReturnType<typeof useAllTemplatesLazyQuery>;
export type AllTemplatesQueryResult = Apollo.QueryResult<AllTemplatesQuery, AllTemplatesQueryVariables>;
export const TemplateDocument = gql`
    query Template($id: uuid!) {
  template: performance_report_templates_by_pk(id: $id) {
    ...TemplateFields
    sections(order_by: {position: asc}) {
      id
      position
      title
      include_missing_pivot_values
      pivot_type_id
      section_ratio_metrics(order_by: {position: asc}) {
        position
        ratio_metric {
          id
          name
          description
        }
      }
      pivot_fields(order_by: {position: asc}) {
        position
        field {
          id
          name
          display
        }
      }
    }
  }
}
    ${TemplateFieldsFragmentDoc}`;

/**
 * __useTemplateQuery__
 *
 * To run a query within a React component, call `useTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTemplateQuery(baseOptions: Apollo.QueryHookOptions<TemplateQuery, TemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateQuery, TemplateQueryVariables>(TemplateDocument, options);
      }
export function useTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateQuery, TemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateQuery, TemplateQueryVariables>(TemplateDocument, options);
        }
export type TemplateQueryHookResult = ReturnType<typeof useTemplateQuery>;
export type TemplateLazyQueryHookResult = ReturnType<typeof useTemplateLazyQuery>;
export type TemplateQueryResult = Apollo.QueryResult<TemplateQuery, TemplateQueryVariables>;
export const CreateTemplateDocument = gql`
    mutation CreateTemplate($input: performance_report_templates_insert_input!) {
  template: insert_performance_report_templates_one(object: $input) {
    id
  }
}
    `;
export type CreateTemplateMutationFn = Apollo.MutationFunction<CreateTemplateMutation, CreateTemplateMutationVariables>;

/**
 * __useCreateTemplateMutation__
 *
 * To run a mutation, you first call `useCreateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemplateMutation, { data, loading, error }] = useCreateTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateTemplateMutation, CreateTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTemplateMutation, CreateTemplateMutationVariables>(CreateTemplateDocument, options);
      }
export type CreateTemplateMutationHookResult = ReturnType<typeof useCreateTemplateMutation>;
export type CreateTemplateMutationResult = Apollo.MutationResult<CreateTemplateMutation>;
export type CreateTemplateMutationOptions = Apollo.BaseMutationOptions<CreateTemplateMutation, CreateTemplateMutationVariables>;
export const UpdateTemplateDocument = gql`
    mutation UpdateTemplate($template_id: uuid!, $columns: performance_report_templates_set_input!, $sections: [performance_report_sections_insert_input!]!) {
  delete_sections: delete_performance_report_sections(
    where: {template_id: {_eq: $template_id}}
  ) {
    affected_rows
  }
  template: update_performance_report_templates_by_pk(
    pk_columns: {id: $template_id}
    _set: $columns
  ) {
    id
  }
  insert_sections: insert_performance_report_sections(objects: $sections) {
    affected_rows
  }
}
    `;
export type UpdateTemplateMutationFn = Apollo.MutationFunction<UpdateTemplateMutation, UpdateTemplateMutationVariables>;

/**
 * __useUpdateTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplateMutation, { data, loading, error }] = useUpdateTemplateMutation({
 *   variables: {
 *      template_id: // value for 'template_id'
 *      columns: // value for 'columns'
 *      sections: // value for 'sections'
 *   },
 * });
 */
export function useUpdateTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTemplateMutation, UpdateTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTemplateMutation, UpdateTemplateMutationVariables>(UpdateTemplateDocument, options);
      }
export type UpdateTemplateMutationHookResult = ReturnType<typeof useUpdateTemplateMutation>;
export type UpdateTemplateMutationResult = Apollo.MutationResult<UpdateTemplateMutation>;
export type UpdateTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateTemplateMutation, UpdateTemplateMutationVariables>;
export const AggRoomByProjectDocument = gql`
    query AggRoomByProject($project_id: uuid!) {
  project: projects_by_pk(id: $project_id) {
    project_dataset(distinct_on: room_id) {
      room {
        id
        name
        building
        city
        clean: project_dataset_aggregate(
          where: {project_id: {_eq: $project_id}, dirty: {_eq: false}}
        ) {
          aggregate {
            count
          }
        }
        dirty: project_dataset_aggregate(
          where: {project_id: {_eq: $project_id}, dirty: {_eq: true}}
        ) {
          aggregate {
            count
          }
        }
        untagged: project_dataset_aggregate(
          where: {project_id: {_eq: $project_id}, dirty: {_is_null: true}}
        ) {
          aggregate {
            count
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAggRoomByProjectQuery__
 *
 * To run a query within a React component, call `useAggRoomByProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggRoomByProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggRoomByProjectQuery({
 *   variables: {
 *      project_id: // value for 'project_id'
 *   },
 * });
 */
export function useAggRoomByProjectQuery(baseOptions: Apollo.QueryHookOptions<AggRoomByProjectQuery, AggRoomByProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggRoomByProjectQuery, AggRoomByProjectQueryVariables>(AggRoomByProjectDocument, options);
      }
export function useAggRoomByProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggRoomByProjectQuery, AggRoomByProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggRoomByProjectQuery, AggRoomByProjectQueryVariables>(AggRoomByProjectDocument, options);
        }
export type AggRoomByProjectQueryHookResult = ReturnType<typeof useAggRoomByProjectQuery>;
export type AggRoomByProjectLazyQueryHookResult = ReturnType<typeof useAggRoomByProjectLazyQuery>;
export type AggRoomByProjectQueryResult = Apollo.QueryResult<AggRoomByProjectQuery, AggRoomByProjectQueryVariables>;
export const AllTaskGroupsHierarchiesDocument = gql`
    query AllTaskGroupsHierarchies($limit: Int, $offset: Int, $where: task_groups_hierarchies_bool_exp, $order_by: [task_groups_hierarchies_order_by!]) {
  hierarchies_aggregate: task_groups_hierarchies_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  hierarchies: task_groups_hierarchies(
    where: $where
    order_by: $order_by
    limit: $limit
    offset: $offset
  ) {
    ...TaskGroupsHierarchyFields
  }
}
    ${TaskGroupsHierarchyFieldsFragmentDoc}`;

/**
 * __useAllTaskGroupsHierarchiesQuery__
 *
 * To run a query within a React component, call `useAllTaskGroupsHierarchiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTaskGroupsHierarchiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTaskGroupsHierarchiesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useAllTaskGroupsHierarchiesQuery(baseOptions?: Apollo.QueryHookOptions<AllTaskGroupsHierarchiesQuery, AllTaskGroupsHierarchiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllTaskGroupsHierarchiesQuery, AllTaskGroupsHierarchiesQueryVariables>(AllTaskGroupsHierarchiesDocument, options);
      }
export function useAllTaskGroupsHierarchiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTaskGroupsHierarchiesQuery, AllTaskGroupsHierarchiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllTaskGroupsHierarchiesQuery, AllTaskGroupsHierarchiesQueryVariables>(AllTaskGroupsHierarchiesDocument, options);
        }
export type AllTaskGroupsHierarchiesQueryHookResult = ReturnType<typeof useAllTaskGroupsHierarchiesQuery>;
export type AllTaskGroupsHierarchiesLazyQueryHookResult = ReturnType<typeof useAllTaskGroupsHierarchiesLazyQuery>;
export type AllTaskGroupsHierarchiesQueryResult = Apollo.QueryResult<AllTaskGroupsHierarchiesQuery, AllTaskGroupsHierarchiesQueryVariables>;
export const TaskGroupsHierarchyDocument = gql`
    query TaskGroupsHierarchy($id: uuid!) {
  hierarchy: task_groups_hierarchies_by_pk(id: $id) {
    ...TaskGroupsHierarchyFields
    hierarchy_groups(order_by: {position: asc}) {
      group_id
      position
      group {
        id
        name
        group_fields(order_by: {position: asc}) {
          field_id
          field {
            id
            name
            display
            description
          }
        }
      }
    }
  }
}
    ${TaskGroupsHierarchyFieldsFragmentDoc}`;

/**
 * __useTaskGroupsHierarchyQuery__
 *
 * To run a query within a React component, call `useTaskGroupsHierarchyQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskGroupsHierarchyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskGroupsHierarchyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskGroupsHierarchyQuery(baseOptions: Apollo.QueryHookOptions<TaskGroupsHierarchyQuery, TaskGroupsHierarchyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskGroupsHierarchyQuery, TaskGroupsHierarchyQueryVariables>(TaskGroupsHierarchyDocument, options);
      }
export function useTaskGroupsHierarchyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskGroupsHierarchyQuery, TaskGroupsHierarchyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskGroupsHierarchyQuery, TaskGroupsHierarchyQueryVariables>(TaskGroupsHierarchyDocument, options);
        }
export type TaskGroupsHierarchyQueryHookResult = ReturnType<typeof useTaskGroupsHierarchyQuery>;
export type TaskGroupsHierarchyLazyQueryHookResult = ReturnType<typeof useTaskGroupsHierarchyLazyQuery>;
export type TaskGroupsHierarchyQueryResult = Apollo.QueryResult<TaskGroupsHierarchyQuery, TaskGroupsHierarchyQueryVariables>;
export const CreateTaskGroupsHierarchyDocument = gql`
    mutation CreateTaskGroupsHierarchy($input: task_groups_hierarchies_insert_input!) {
  hierarchy: insert_task_groups_hierarchies_one(object: $input) {
    ...TaskGroupsHierarchyFields
  }
}
    ${TaskGroupsHierarchyFieldsFragmentDoc}`;
export type CreateTaskGroupsHierarchyMutationFn = Apollo.MutationFunction<CreateTaskGroupsHierarchyMutation, CreateTaskGroupsHierarchyMutationVariables>;

/**
 * __useCreateTaskGroupsHierarchyMutation__
 *
 * To run a mutation, you first call `useCreateTaskGroupsHierarchyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskGroupsHierarchyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskGroupsHierarchyMutation, { data, loading, error }] = useCreateTaskGroupsHierarchyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTaskGroupsHierarchyMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaskGroupsHierarchyMutation, CreateTaskGroupsHierarchyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaskGroupsHierarchyMutation, CreateTaskGroupsHierarchyMutationVariables>(CreateTaskGroupsHierarchyDocument, options);
      }
export type CreateTaskGroupsHierarchyMutationHookResult = ReturnType<typeof useCreateTaskGroupsHierarchyMutation>;
export type CreateTaskGroupsHierarchyMutationResult = Apollo.MutationResult<CreateTaskGroupsHierarchyMutation>;
export type CreateTaskGroupsHierarchyMutationOptions = Apollo.BaseMutationOptions<CreateTaskGroupsHierarchyMutation, CreateTaskGroupsHierarchyMutationVariables>;
export const UpdateTaskGroupsHierarchyDocument = gql`
    mutation UpdateTaskGroupsHierarchy($hierarchy_id: uuid!, $hierarchy_fields: task_groups_hierarchies_set_input!, $hierarchy_groups: [task_groups_hierarchy_groups_insert_input!]!) {
  hierarchy: update_task_groups_hierarchies_by_pk(
    pk_columns: {id: $hierarchy_id}
    _set: $hierarchy_fields
  ) {
    ...TaskGroupsHierarchyFields
  }
  deleted_groups: delete_task_groups_groups(
    where: {hierarchy_groups: {hierarchy_id: {_eq: $hierarchy_id}}}
  ) {
    affected_rows
  }
  inserted_hierarchy_groups: insert_task_groups_hierarchy_groups(
    objects: $hierarchy_groups
  ) {
    affected_rows
  }
}
    ${TaskGroupsHierarchyFieldsFragmentDoc}`;
export type UpdateTaskGroupsHierarchyMutationFn = Apollo.MutationFunction<UpdateTaskGroupsHierarchyMutation, UpdateTaskGroupsHierarchyMutationVariables>;

/**
 * __useUpdateTaskGroupsHierarchyMutation__
 *
 * To run a mutation, you first call `useUpdateTaskGroupsHierarchyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskGroupsHierarchyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskGroupsHierarchyMutation, { data, loading, error }] = useUpdateTaskGroupsHierarchyMutation({
 *   variables: {
 *      hierarchy_id: // value for 'hierarchy_id'
 *      hierarchy_fields: // value for 'hierarchy_fields'
 *      hierarchy_groups: // value for 'hierarchy_groups'
 *   },
 * });
 */
export function useUpdateTaskGroupsHierarchyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskGroupsHierarchyMutation, UpdateTaskGroupsHierarchyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskGroupsHierarchyMutation, UpdateTaskGroupsHierarchyMutationVariables>(UpdateTaskGroupsHierarchyDocument, options);
      }
export type UpdateTaskGroupsHierarchyMutationHookResult = ReturnType<typeof useUpdateTaskGroupsHierarchyMutation>;
export type UpdateTaskGroupsHierarchyMutationResult = Apollo.MutationResult<UpdateTaskGroupsHierarchyMutation>;
export type UpdateTaskGroupsHierarchyMutationOptions = Apollo.BaseMutationOptions<UpdateTaskGroupsHierarchyMutation, UpdateTaskGroupsHierarchyMutationVariables>;
export const GroupedProjectTasksDocument = gql`
    query GroupedProjectTasks($hierarchy_id: uuid!, $project_id: uuid!) {
  hierarchy: task_groups_hierarchies_by_pk(id: $hierarchy_id) {
    ...TaskGroupsHierarchyFields
    hierarchy_groups(order_by: {position: asc}) {
      group_id
      position
      group {
        id
        name
        group_fields(order_by: {position: asc}) {
          position
          field_id
          field {
            id
            name
            display
            description
            field_options(
              where: {task_field_options: {task: {project_tasks: {project_id: {_eq: $project_id}}}}}
              order_by: {}
            ) {
              option_id
              option {
                ...OptionFields
              }
              task_field_options {
                task_id
                task {
                  ...TaskFields
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${TaskGroupsHierarchyFieldsFragmentDoc}
${OptionFieldsFragmentDoc}
${TaskFieldsFragmentDoc}`;

/**
 * __useGroupedProjectTasksQuery__
 *
 * To run a query within a React component, call `useGroupedProjectTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupedProjectTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupedProjectTasksQuery({
 *   variables: {
 *      hierarchy_id: // value for 'hierarchy_id'
 *      project_id: // value for 'project_id'
 *   },
 * });
 */
export function useGroupedProjectTasksQuery(baseOptions: Apollo.QueryHookOptions<GroupedProjectTasksQuery, GroupedProjectTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupedProjectTasksQuery, GroupedProjectTasksQueryVariables>(GroupedProjectTasksDocument, options);
      }
export function useGroupedProjectTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupedProjectTasksQuery, GroupedProjectTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupedProjectTasksQuery, GroupedProjectTasksQueryVariables>(GroupedProjectTasksDocument, options);
        }
export type GroupedProjectTasksQueryHookResult = ReturnType<typeof useGroupedProjectTasksQuery>;
export type GroupedProjectTasksLazyQueryHookResult = ReturnType<typeof useGroupedProjectTasksLazyQuery>;
export type GroupedProjectTasksQueryResult = Apollo.QueryResult<GroupedProjectTasksQuery, GroupedProjectTasksQueryVariables>;
export const ToolsDocument = gql`
    query Tools {
  tools {
    id
    title
    description
    storage_container
    storage_account
    documentation_url
    url
    sas
    tool_versions(order_by: {released_at: desc}) {
      id
      filename
      name
      storage_path
      released_at
    }
  }
}
    `;

/**
 * __useToolsQuery__
 *
 * To run a query within a React component, call `useToolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useToolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useToolsQuery({
 *   variables: {
 *   },
 * });
 */
export function useToolsQuery(baseOptions?: Apollo.QueryHookOptions<ToolsQuery, ToolsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ToolsQuery, ToolsQueryVariables>(ToolsDocument, options);
      }
export function useToolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ToolsQuery, ToolsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ToolsQuery, ToolsQueryVariables>(ToolsDocument, options);
        }
export type ToolsQueryHookResult = ReturnType<typeof useToolsQuery>;
export type ToolsLazyQueryHookResult = ReturnType<typeof useToolsLazyQuery>;
export type ToolsQueryResult = Apollo.QueryResult<ToolsQuery, ToolsQueryVariables>;
export const AllLanguagesDocument = gql`
    query AllLanguages($where: languages_bool_exp) {
  languages(where: $where) {
    id
    description
  }
}
    `;

/**
 * __useAllLanguagesQuery__
 *
 * To run a query within a React component, call `useAllLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllLanguagesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllLanguagesQuery(baseOptions?: Apollo.QueryHookOptions<AllLanguagesQuery, AllLanguagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllLanguagesQuery, AllLanguagesQueryVariables>(AllLanguagesDocument, options);
      }
export function useAllLanguagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllLanguagesQuery, AllLanguagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllLanguagesQuery, AllLanguagesQueryVariables>(AllLanguagesDocument, options);
        }
export type AllLanguagesQueryHookResult = ReturnType<typeof useAllLanguagesQuery>;
export type AllLanguagesLazyQueryHookResult = ReturnType<typeof useAllLanguagesLazyQuery>;
export type AllLanguagesQueryResult = Apollo.QueryResult<AllLanguagesQuery, AllLanguagesQueryVariables>;
export const AllUsersDocument = gql`
    query AllUsers($limit: Int, $offset: Int, $order_by: [users_order_by!], $where: users_bool_exp) {
  users_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  users(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
    ...UserFields
    ...UserRolesField
    project_users_aggregate {
      aggregate {
        count
      }
    }
  }
}
    ${UserFieldsFragmentDoc}
${UserRolesFieldFragmentDoc}`;

/**
 * __useAllUsersQuery__
 *
 * To run a query within a React component, call `useAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<AllUsersQuery, AllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options);
      }
export function useAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllUsersQuery, AllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options);
        }
export type AllUsersQueryHookResult = ReturnType<typeof useAllUsersQuery>;
export type AllUsersLazyQueryHookResult = ReturnType<typeof useAllUsersLazyQuery>;
export type AllUsersQueryResult = Apollo.QueryResult<AllUsersQuery, AllUsersQueryVariables>;
export const UserDocument = gql`
    query User($id: uuid!) {
  users_by_pk(id: $id) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($input: users_insert_input!, $on_conflict: users_on_conflict) {
  insert_users_one(object: $input, on_conflict: $on_conflict) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *      on_conflict: // value for 'on_conflict'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;