import { useState } from "react";
import {
  Button,
  Card,
  Chip,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import * as Yup from "yup";
import { SearchBar } from "src/components/filters";
import {
  DialogSelect,
  TextFieldArray,
  FormContainer,
  FormHeader,
  MultiSelect,
} from "src/components/Form";
import { Resource, useResourceNav } from "src/components/Resource";
import {
  createTable,
  EllipsisColumn,
  TableOptionals,
  TextColumn,
} from "src/components/table";
import {
  usePagination,
  like,
  strArrToPostgresLiteral,
} from "src/resources/Utils";
import {
  Breadcrumb,
  Breadcrumbs,
  ShowResourceView,
  TableView,
  TableViewHeader,
} from "src/Layout";
import { ResourceForm, FormAction } from "src/components/ResourceForm";
import {
  Deliveries_Targets as Target,
  Deliveries_Targets_Bool_Exp as Target_Bool_Exp,
  useAllTargetsQuery,
  useTargetQuery,
  useAllPartnersQuery,
  useCreateTargetMutation,
  useUpdateTargetMutation,
  Order_By,
} from "src/generated/asgard/graphql";
import { CustomerList } from "../Customer";
import { AllModelsTable } from "../Model";

// Config table columns from Target fields
export const TargetTable = createTable<Target>()({
  keys: (target) => target.id ?? "",
  title: "Target",
  headers: {
    id: { display: "ID" },
    model: { display: "Model" },
    customer: { display: "Customer" },
  },
  columns: (target) => ({
    id: <EllipsisColumn value={target.id} />,
    model: <TextColumn value={target.model?.codename} />,
    customer: <TextColumn value={target.customer?.codename ?? ""} />,
  }),
});

// Define a new table component for Targets
type AllTargetsTableProps = TableOptionals<typeof TargetTable> & {
  where?: Target_Bool_Exp[];
};

export const AllTargetTable = (props: AllTargetsTableProps) => {
  const [pageVars, pageController] = usePagination();
  const [search, setSearch] = useState("");
  const searchFilters: Target_Bool_Exp[] = [];
  // Add search terms for individual fields.
  const term = like(search);
  searchFilters.push({ model: { codename: { _ilike: term } } });
  const { data } = useAllTargetsQuery({
    variables: {
      ...pageVars,
      where: { _and: [{ _and: props.where }, { _or: searchFilters }] },
      order_by: [{ model: { codename: Order_By.Asc } }],
    },
    fetchPolicy: "network-only",
  });

  return (
    <TargetTable
      {...props}
      {...pageController}
      total={data?.targets_aggregate?.aggregate?.count}
      data={data?.targets}
      tools={
        <Grid item xs={12}>
          <SearchBar onChange={setSearch} />
        </Grid>
      }
    />
  );
};

// Define content to display in the main Target resource page
type TargetIndexProps = {
  onAddNew: () => void;
} & TableOptionals<typeof TargetTable>;

export const TargetIndex = (props: TargetIndexProps) => {
  return (
    <TableView>
      <TableViewHeader title={<Typography variant="h5">Targets</Typography>}>
        <Button
          onClick={props.onAddNew}
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutline />}
          disableElevation
        >
          New Target
        </Button>
      </TableViewHeader>
      <Card>
        <AllTargetTable {...props} selectable="none" />
      </Card>
    </TableView>
  );
};

// Define form for creating and editing Targets
const targetSchema = Yup.object({
  model: Yup.object({
    id: Yup.string(),
    codename: Yup.string(),
  }).required("required"),
  customer: Yup.object({
    id: Yup.string(),
    codename: Yup.string(),
  }).required("required"),
  chids: Yup.array(
    Yup.string().matches(
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/,
      "CHIDs must be a UUID",
    ),
  ).required("required"),
  hardware_ids: Yup.array(
    Yup.string().matches(/.+/, "Hardware ID must be string"),
  ),
  target_partners: Yup.array(
    Yup.object({
      id: Yup.string().required("Partner ID is required"),
      name: Yup.string().required("Partner name is required"),
    }),
  ).required("At least one partner is required"),
}).required();

export type TargetFormProps = {
  action: FormAction;
  target?: { id: string };
  onSuccess?: (id: string) => void;
};

export const TargetForm = (props: TargetFormProps) => {
  const [createTarget] = useCreateTargetMutation();
  const [updateTarget] = useUpdateTargetMutation();

  const allTargets = useAllTargetsQuery({
    variables: {
      where: {
        model: {
          id: {
            _nin: props.target?.id ? [props.target.id] : [],
          },
        },
        customer: {
          id: {
            _nin: props.target?.id ? [props.target.id] : [],
          },
        },
      },
    },
    fetchPolicy: "network-only",
  });

  const allPartners = useAllPartnersQuery({
    fetchPolicy: "network-only",
  });

  const existingTarget = useTargetQuery({
    variables: { id: props.target?.id ?? "" },
    fetchPolicy: "network-only",
    skip: !props.target,
  });

  return (
    <ResourceForm
      action={props.action}
      schema={targetSchema}
      resourceToUpdate={existingTarget.data?.target}
      transform={(target) => ({
        model: {
          id: target.model?.id ?? "",
          codename: target.model?.codename ?? "",
        },
        customer: {
          id: target.customer?.id ?? "",
          codename: target.customer?.codename ?? "",
        },
        chids: target.chids ?? [],
        hardware_ids: target.hardware_ids ?? [],
        target_partners:
          target.target_partners?.map((tp) => ({
            id: tp.partner.id,
            name: tp.partner.name,
          })) ?? [],
      })}
      initialValues={{
        model: {
          id: "",
          codename: "",
        },
        customer: {
          id: "",
          codename: "",
        },
        chids: [],
        hardware_ids: [],
        target_partners: [],
      }}
      customValidator={(values, errors) => {
        for (let existing of allTargets.data?.targets ?? []) {
          if (props.target?.id && existing.id === props.target.id) {
            continue;
          }
          if (
            existing.customer.id === values.customer.id &&
            existing.model.id === values.model.id
          ) {
            errors.model = {
              codename: `There is already a target for model ${values.model?.codename} and customer ${values.customer?.codename}`,
            };
          }

          if (errors.model) {
            break;
          }
        }
      }}
      onUpdate={async (values) => {
        const {
          model,
          customer,
          chids = [],
          hardware_ids = [],
          target_partners = [],
          ...cols
        } = values;
        const formattedCHIDs = strArrToPostgresLiteral(
          chids.filter(
            (item: string | undefined): item is string => item !== undefined,
          ),
          true,
          true,
        );
        const formattedHardwareIds = strArrToPostgresLiteral(
          hardware_ids.filter(
            (item: string | undefined): item is string => item !== undefined,
          ),
          true,
          true,
        );
        const initialPartnerIds = new Set(
          (existingTarget.data?.target?.target_partners ?? []).map(
            (tp: any) => tp.partner.id,
          ),
        );
        const updatedPartnerIds = new Set(
          target_partners.map((tp: any) => tp.id),
        );

        // Calculate partners to add and partners to remove
        const partnersToAdd = target_partners
          .filter((tp: any) => tp?.id && !initialPartnerIds.has(tp.id))
          .map((tp: any) => ({
            partner_id: tp.id,
            target_id: props.target!.id,
          }));

        const partnersToRemove = Array.from(initialPartnerIds).filter(
          (id) => !updatedPartnerIds.has(id),
        );
        const columns = {
          ...cols,
          customer_id: customer.id,
          model_id: model.id,
          chids: formattedCHIDs,
          hardware_ids: formattedHardwareIds,
        };
        const result = await (async () => {
          if (props.target?.id)
            return await updateTarget({
              variables: {
                id: props.target.id,
                columns: { ...columns },
                add_partners: partnersToAdd,
                delete_partner_ids: partnersToRemove,
              },
            });
        })();
        props.onSuccess && props.onSuccess(result?.data?.target?.id ?? "");
      }}
      onInsert={async (values) => {
        const {
          model,
          customer,
          chids = [],
          hardware_ids = [],
          target_partners = [],
          ...cols
        } = values;
        const formattedCHIDs = strArrToPostgresLiteral(
          chids.filter(
            (item: string | undefined): item is string => item !== undefined,
          ),
          true,
          true,
        );
        const formattedHardwareIds = strArrToPostgresLiteral(
          hardware_ids.filter(
            (item: string | undefined): item is string => item !== undefined,
          ),
          true,
          true,
        );
        const columns = {
          ...cols,
          customer_id: customer.id,
          model_id: model.id,
          chids: formattedCHIDs,
          hardware_ids: formattedHardwareIds,
          target_partners: {
            data: target_partners.map((tp: any) => ({ partner_id: tp.id })),
          },
        };
        const result = await (async () => {
          return await createTarget({
            variables: {
              input: { ...columns },
            },
          });
        })();
        props.onSuccess && props.onSuccess(result.data?.target?.id ?? "");
      }}
      render={({ formik, path }) => (
        <>
          <FormHeader>
            {props.target ? "Update Target" : "Create New Target"}
          </FormHeader>
          <FormContainer>
            <DialogSelect
              bp={{ xs: 12, sm: 6 }}
              name={path.customer.codename._}
              disabled={props.action === "update"}
              label="Customer"
              onReset={() =>
                formik.setValues({
                  ...formik.values,
                  customer: formik.initialValues.customer,
                })
              }
              content={(close) => (
                <CustomerList
                  selectable="single"
                  onSelect={(customer) => {
                    formik.setValues({
                      ...formik.values,
                      customer: {
                        id: customer.id ?? "",
                        codename: customer.codename ?? "",
                      },
                    });
                    close();
                  }}
                />
              )}
            />
            <DialogSelect
              bp={{ xs: 12, sm: 6 }}
              name={path.model.codename._}
              disabled={props.action === "update"}
              label="Model"
              onReset={() =>
                formik.setValues({
                  ...formik.values,
                  model: formik.initialValues.model,
                })
              }
              content={(close) => (
                <AllModelsTable
                  selectable="single"
                  onSelect={(model) => {
                    formik.setValues({
                      ...formik.values,
                      model: {
                        id: model.id ?? "",
                        codename: model.codename ?? "",
                      },
                    });
                    close();
                  }}
                />
              )}
            />
            <Grid container item spacing={4}>
              <TextFieldArray
                name="chids"
                label="CHID"
                bp={{ xs: 12, sm: 12 }}
              />
              <Grid item xs={12}>
                <Divider />
              </Grid>

              <TextFieldArray
                name="hardware_ids"
                label="Hardware ID"
                bp={{ xs: 12, sm: 12 }}
              />
              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <MultiSelect
                  bp={{ sm: 9, xs: 12 }}
                  name="target_partners"
                  multiple={true}
                  label="Partners"
                  onChange={(e: any) =>
                    formik.setFieldValue(
                      "target_partners",
                      e.map((partner: { id: string }) => ({
                        partner_id: partner.id,
                      })),
                    )
                  }
                  options={allPartners.data?.partners.map((partner) => ({
                    id: partner.id,
                    name: partner.name,
                  }))}
                  value={
                    formik.values.target_partners
                      ?.filter(
                        (tp): tp is { id: string; name: string } =>
                          tp !== undefined,
                      )
                      .map((tp) => tp.id) ?? []
                  }
                  getOptionLabel={(option) => option.name ?? "Unknown"}
                  getOptionSelected={(option, selected) =>
                    option.id === selected.id
                  }
                />
              </Grid>
            </Grid>
          </FormContainer>
        </>
      )}
    />
  );
};

// Create a detailed 'show' page.
type TargetShowProps = {
  id: string;
  onEditAction?: (item: DeepPartial<Target>) => void;
};

const TargetShow = (props: TargetShowProps) => {
  const targetNav = useTargetNav();
  const targetQuery = useTargetQuery({
    variables: { id: props.id },
    fetchPolicy: "network-only",
  });
  const target = targetQuery.data?.target;
  if (!target) return null;

  const properties = [
    { label: "ID", value: target.id },
    { label: "Model", value: target.model?.codename ?? "None" },
    { label: "Customer", value: target.customer?.codename ?? "None" },
  ];

  const chids = target?.chids ?? [];
  const hardwareIds = target?.hardware_ids ?? [];

  return (
    <ShowResourceView
      title={`${target.customer?.codename ?? ""} - ${
        target.model?.codename ?? ""
      }`}
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb label="Target" onClick={() => targetNav.list()} />
          <Breadcrumb
            label={`${target.customer?.codename ?? ""} - ${
              target.model?.codename ?? ""
            }`}
          />
        </Breadcrumbs>
      }
      onEditAction={() => props.onEditAction && props.onEditAction(target)}
      properties={properties}
    >
      <Grid container spacing={2}>
        {/* CHIDs Section */}
        <Grid item xs={12} sm={4}>
          <Paper elevation={3} style={{ padding: "16px", marginBottom: "8px" }}>
            <Typography variant="h6" gutterBottom>
              CHIDs
            </Typography>
            <Divider style={{ marginBottom: "8px" }} />
            <Grid container spacing={1}>
              {chids.map((chid: any, index: any) => (
                <Grid item key={index}>
                  <Chip label={chid} variant="outlined" />
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>

        {/* Hardware IDs Section */}
        <Grid item xs={12} sm={4}>
          <Paper elevation={3} style={{ padding: "16px", marginBottom: "8px" }}>
            <Typography variant="h6" gutterBottom>
              Hardware IDs
            </Typography>
            <Divider style={{ marginBottom: "8px" }} />
            <Grid container spacing={1}>
              {hardwareIds.map((id: any, index: any) => (
                <Grid item key={index}>
                  <Chip label={id} variant="outlined" />
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>

        {/* Customer Codes Section */}
        <Grid item xs={12} sm={4}>
          <Paper elevation={3} style={{ padding: "16px", marginBottom: "8px" }}>
            <Typography variant="h6" gutterBottom>
              Partners
            </Typography>
            <Divider style={{ marginBottom: "8px" }} />
            <Grid container spacing={1}>
              {target.target_partners.map((tp: any, index: any) => (
                <Grid item key={index}>
                  <Chip label={tp.partner.name} variant="outlined" />
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </ShowResourceView>
  );
};

// Finally, combine into full resource UI.
const path = "targets";
export const useTargetNav = () => useResourceNav(path);
export const TargetResource = () => (
  <Resource
    path={path}
    list={(nav) => (
      <TargetIndex
        editUrl={(item) => item.id && nav.editUrl(item.id)}
        showUrl={(item) => item.id && nav.showUrl(item.id)}
        onAddNew={() => nav.create()}
      />
    )}
    show={(nav, id) => (
      <TargetShow
        id={id ?? ""}
        onEditAction={(item) => item.id && nav.edit(item.id)}
      />
    )}
    create={(nav) => (
      <TargetForm action="insert" onSuccess={(id) => nav.show(id)} />
    )}
    edit={(nav, id) => (
      <TargetForm
        action="update"
        onSuccess={(id) => nav.show(id)}
        target={{ id: id ?? "" }}
      />
    )}
  />
);
